import {Component, OnInit} from '@angular/core';
import {Tcolumn} from '../../../grid/tcolumn.model';
import {Grid} from '../../../grid/grid.model';
import {KeyValue} from '../../../grid/key-value.model';
import {ActivatedRoute} from '@angular/router';
import {Validators} from '@angular/forms';
import {defaultValue} from "../../../grid/defaultValue.model";

@Component({
  selector: 'app-chatofaccountdetails',
  templateUrl: './chatofaccountdetails.component.html',
  styleUrls: ['./chatofaccountdetails.component.css']
})
export class ChatofaccountdetailsComponent implements OnInit {

  columns: Map<string, Tcolumn>;
  grid: Grid;
  groupList: KeyValue[] = [];
  coacode: string = '';

  constructor(private router: ActivatedRoute) {
    this.router.params.subscribe(params => {
      this.coacode = params['id'];
    });
  }

  ngOnInit() {
    this.getColumns();
    this.grid = new Grid(this.columns, true, true, true, true, 'accountCode', 'chartOfAccountDetails/search/findByCoaCodeOrderByIdDesc?coaCode=' + this.coacode, '', 'Chart of Account', 'coaCode', '');


  }

  public getColumns() {
    this.columns = new Map();
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('coaCode', new Tcolumn('coaCode', 'COA Code', 'LB', 1, false, undefined, false, this.coacode));
    this.columns.set('accountCode', new Tcolumn('accountCode', 'Account Code', 'T', 2, false, undefined, true, '', 'B', [Validators.required]));
    this.columns.set('accountName', new Tcolumn('accountName', 'Account Name', 'T', 3, true, undefined, true, new defaultValue('', 'accountDescription'), 'B', [Validators.required]));
    this.columns.set('accountDescription', new Tcolumn('accountDescription', 'Account Description', 'TA', 4));
    this.columns.set('accountClass', new Tcolumn('accountClass', 'Account Class', 'L', 5, true, this.getToIncrease()));
    this.columns.set('accountLevel', new Tcolumn('accountLevel', 'Account Level', 'L', 6, true, this.getToIncrease()));
    this.columns.set('toIncrease', new Tcolumn('toIncrease', 'To Increase', 'L', 7, true, this.getToIncrease()));
    this.columns.set('parentGroup', new Tcolumn('parentGroup', 'Parent Group', 'L', 8, true, this.getToIncrease()));
  }

  getToIncrease() {
    var obj = [];
    obj.push(new KeyValue('Credit', 'credit'));
    obj.push(new KeyValue('Debit', 'debit'));
    return obj;
  }
}
