import {Component, OnInit} from '@angular/core';
import {Tcolumn} from '../../../grid/tcolumn.model';
import {Grid} from '../../../grid/grid.model';
import {Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Action} from '../../../grid/actions.model';
import {environment} from '../../../../environments/environment';
import {MasterService} from "../../services/MasterService";
import {filter} from "../../services/filter.model";
import {entities} from "../../../services/common/entities";

@Component({
  selector: 'app-profit-center-details',
  templateUrl: './profit-center-details.component.html'
})
export class ProfitCenterDetailsComponent implements OnInit {
  columns: Map<string, Tcolumn>;
  grid: Grid;
  id: string = '';

  constructor(private router: ActivatedRoute, public commonService: MasterService) {
    this.router.params.subscribe(params => {
      this.id = params['id'];
    });
  }

  ngOnInit() {
    this.getColumns();
    this.grid = new Grid(this.columns, true, true, true, true, 'commodityCode', 'profitCenterConfig/search/findByProfitCenterCodeOrderByIdDesc?profitCenterCode=' + this.id, '', 'Profit Centre Configuration', 'profitCenterCode');
    this.grid.iconName = 'profitCenter';
    this.grid.setFirstLevelDataUrl('/profitCenters');
  }

  public getColumns() {
    let actionone: Action = new Action(environment.base_url + 'filter', '', '');
    actionone.setToExtra('entityName', entities.commodity);
    actionone.setToExtra('key', 'commodityCode');
    actionone.addFieldToUpdate('commodityName', 'commodityName');
    actionone.addFieldToUpdate('commodityDescription', 'commodityDescription');
    let filterForComm = new filter(environment.base_url + 'filter', entities.commodity);
    filterForComm.addCriteria('commodityStatus', true);
    this.columns = new Map();
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('profitCenterCode', new Tcolumn('profitCenterCode', 'Profit Center Code', 'LB', 1, false, undefined, false, this.id, 'B', [Validators.required, Validators.maxLength(15)]));
    this.columns.set('commodityCode', new Tcolumn('commodityCode', 'Commodity Code', 'L', 2, true, this.commonService.getListFromObject(filterForComm, 'commodityCode', 'commodityCode'), true, '', 'B', [Validators.required, Validators.maxLength(15)], 'text', 1, undefined, undefined, undefined, actionone));
    this.columns.set('commodityName', new Tcolumn('commodityName', 'Commodity Code Name', 'LB', 3, true, undefined, true, '', 'B', [Validators.required, Validators.maxLength(100)]));
    this.columns.set('commodityDescription', new Tcolumn('commodityDescription', 'Commodity Code Description', 'LB', 4, true, undefined, true, '', 'B', [Validators.maxLength(200)]));
    this.columns.set('priceRounding', new Tcolumn('priceRounding', 'Price Rounding', 'N', 5, true, undefined, true, 4, 'B', [Validators.min(0), Validators.maxLength(2)], undefined, undefined, undefined, undefined, undefined, undefined));
    this.columns.set('quantityRounding', new Tcolumn('quantityRounding', 'Quantity Rounding', 'N', 6, true, undefined, true, 3, 'B', [Validators.min(0), Validators.maxLength(2)]));
    this.columns.set('fxRounding', new Tcolumn('fxRounding', 'FX Rounding', 'N', 7, true, undefined, true, 4, 'B', [Validators.min(0), Validators.maxLength(2)]));
    this.columns.set('invoiceAmountRounding', new Tcolumn('invoiceAmountRounding', 'Invoice Amount Rounding', 'N', 8, true, undefined, true, 2, 'B', [Validators.min(0), Validators.maxLength(2)]));
  }

}
