<!--<ctrm-grid [refreshGrid]="refreshGrid" [grid]="grid"></ctrm-grid>-->
<p-dialog (onHide)="closeModal()" [(visible)]="modalState" [baseZIndex]="9999"
          [closeOnEscape]="true"
          [closable]="false"
          [contentStyle]="" [draggable]="false" [maximizable]="false" [modal]="true"
          [positionTop]="96" [style]="{ width: '80%',height: 'auto',maxHeight:'80%', padding:'0px'}">
    <p-header [style.color]="'#000'">
        {{header}}
        <i (click)="closeModal()" class="material-icons closable">clear</i>
    </p-header>
    <div class="content">
        <div style="display: inline-flex;width: 100%">
            <div style="width: 50%">
                <pdf-viewer [src]="pdfbytes"
                            [zoom]="0.75"
                ></pdf-viewer>
            </div>
            <div style="width: 50%">
                <div class="container">
                    <div>
                        <h2 style="color:black; text-align: center;">Document Details</h2>

                        <p-table  [value]=[response] responsiveLayout="scroll">
                            <ng-template pTemplate="body" let-response>
                                <tr >
                                    <th style="padding-left: 10px"></th>
                                    <td style="padding-left: 10px">{{response['Attachment_ID'] }}</td>
                                </tr>
                                <tr style="padding: 2px">
                                    <th style="padding-left: 10px">Accuracy</th>
                                    <td style="padding-left: 10px">{{response['accuracy'] }}</td>
                                </tr>
                                <tr style="padding: 2px">
                                    <th style="padding-left: 10px">Execution Time</th>
                                    <td style="padding-left: 10px">{{response['response_time'] }} sec</td>
                                </tr>
                                <tr style="padding: 2px">
                                    <th style="padding-left: 10px">Document Type</th>
                                    <td style="padding-left: 10px">{{response['document_type'] }}</td>
                                </tr>
                                <tr style="padding: 2px">
                                    <th style="padding-left: 10px">Sender Email</th>
                                    <td style="padding-left: 10px">{{response['sender_mail'] }}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div style="margin-top:5vw;">
                            <h2 style="color:black; text-align: center;text-transform: capitalize;">{{response['document_type'] }} Data </h2>
                            <p-table [value]="getKeyList()"  responsiveLayout="scroll">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="padding-left: 10px">Field Name</th>
                                        <th style="padding-left: 10px">Values</th>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-product>
                                    <tr>
                                        <th style="padding-left: 10px">{{product}}</th>
                                        <td style="padding-left: 10px">{{response['response'][product]}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</p-dialog>
