
<div *ngIf="(menuItem['childs'] !== null && menuItem['childs'] !== undefined && menuItem['childs'].length > 0)">
    <a [id]="menuItem['key']+'_head'" href="#{{menuItem['key']}}"
       class="list-group-item" data-toggle="collapse" data-parent="#sidebar" style="background: #ffffff;border:none; padding-left:inherit;"
       (click)="onClickMenuHead(menuItem)" *ngIf="menuItem['isAccess']">
                <span class="hidden-sm-down" style="width: 100%;display: inline-flex;align-items: center">
                    <i style="padding:0;margin:0;margin-right: 5px" [ngClass]="selectedMenuHeader === menuItem['key']?'fa fa-caret-down':'fa fa-caret-right'"></i>
                    <p class="menuHeader">{{menuItem['header']}}</p>
                </span>
    </a>
    <div class="collapse" id="{{menuItem['key']}}" style="border:none;margin-left: 30px;">
        <ng-container  *ngFor="let child of menuItem['childs']">
            <a
               href="#{{child['key']}}" class="list-group-item" data-parent="#menu4" style="border:none;"
               [ngClass]="selectMenuItem===child['key']?'selectedItem':''"
               (click)="onClickMenuHead(child)" *ngIf="child['isAccess']">
                <p *ngIf="(child.childs == undefined || child.childs==null || child.childs.length==0);else childmenu"  class="menuHeader">{{child['header']}}</p>
                <ng-template #childmenu>
                    <app-childsidemenu
                            [menuItem]="child"
                            (onClickMenuItem)="onClickMenuHead($event)">
                    </app-childsidemenu>
                </ng-template>
            </a>
        </ng-container>
    </div>
</div>
