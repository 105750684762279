<div class="sectionCardTop">
    <div class="breadcrumbPane">
        <ctrm-breadcrumb [collapsabel]="false"
                         [items]="breadCrumbService.getBreadCrumb()"
                         [showIcon]="false"></ctrm-breadcrumb>
    </div>
    <div class="actionPane">
        <div class="screenDetails">
            <img [src]="'../../../assets/images/svg/tradePlanning.svg'"/>
            <p>Trade Planning</p>
        </div>
        <div class="autocomplete-box">
            <input (input)="getFilterValue()" placeholder="Search" type="text">
            <mat-icon style="color:#cbcbcb">search</mat-icon>
        </div>
        <div *ngIf="tabValue == 'planned'" class="rightInfo">
            Total {{totalElements}} Plans
        </div>
    </div>
</div>


<div class="sectionCardTop" style="max-height: 85% !important;">

    <div class="selectButtonClass">
        <p-selectButton (onChange)="onTabChange($event)" [(ngModel)]="tabValue" [options]="tabOptions"></p-selectButton>
    </div>

    <div *ngIf="tabValue !== 'planned'" style="margin-left: 10px;">
        <ctrm-button (onClick)="buyObligationRefresh()" icon="fas fa-redo" label="Refresh"></ctrm-button>
    </div>

    <div [hidden]="tabValue === 'planned'">
        <!--Buy Sell Grid Section-->
        <div #buySell class="buySellContainer">
            <ctrm-planning-grid (onClickClickable)="onClickable($event)" (onUniqueKeyClick)="onUniqueClick($event)"
                                (selectedRowsValue)="onRowSelect($event,'Buy')"
                                (total)="onTotalChange($event,'buy')" [balanceCalculationFunction]="balanceFunc"
                                [clickableColumns]="['tradeId']" [cols]="buyCols"
                                [filterValue]="globalFilterValue"
                                [noDataLabel]="'No Confirmed Trade available to Plan'"
                                [noDataRoute]="'/trade'"
                                [primaryKeyClickable]="false" [primaryKey]="'tradeObligationId'"
                                [routeToLable]="'Create/Confirm Physical Trade'"
                                [value]="buyValue" style="width: 49.9%"
                                [negativeValueError]="'Match Amount is greater than Buy Balance Quantity'"
                                (disableEmitter)="disableButton($event)"
                                [filterOptionsList]="filterListBuy"
                                (onFilterApply)="filterForBuy($event)"
                                [refreshGrid]="onRefreshBuy"
                                [balanceRoundOff]="commonService.getRoundingFormat('quantityRounding',3)"
                                [matchRoundOff]="commonService.getRoundingFormat('quantityRounding',3)"
                                (onRefreshComplete)="onRefreshBuy = false"
                                (clearOnFilter)="clearFilter('Buy')"
                                [totalElements]="totalElementsBuy" [totalPages]="totalPagesBuy" [pageSize]="pageSizeBuy"
                                (onChangePage)="onChangePageBuy($event)" [mode]="'Left'" [reset]="resetBuy"
                                [heading]="'Buy Obligations'" [totalHeading]="'Total Obligation : '"
                                [totalMatchHeading]="'Buy Total Match Qty'"
                                [totalMatch]="buyTotal">
            </ctrm-planning-grid>
            <div class="dividerLine"></div>
            <ctrm-planning-grid (onClickClickable)="onClickable($event,'left')"
                                (onUniqueKeyClick)="onUniqueClick($event)"
                                (selectedRowsValue)="onRowSelect($event,'Sell')"
                                (total)="onTotalChange($event,'sell')" [balanceCalculationFunction]="balanceFunc"
                                [clickableColumns]="['tradeId']" [cols]="sellCols"
                                [filterValue]="globalFilterValue"
                                [gridPosition]="'right'" [noDataLabel]="'No Confirmed Trade available to Plan'"
                                [noDataRoute]="'/trade'" [primaryKeyClickable]="false"
                                [primaryKey]="'tradeObligationId'" [reset]="resetSell"
                                [routeToLable]="'Create/Confirm Physical Trade'"
                                [matchRoundOff]="commonService.getRoundingFormat('quantityRounding',3)"
                                [balanceRoundOff]="commonService.getRoundingFormat('quantityRounding',3)"
                                [value]="sellValue" style="width: 49.9%"
                                [negativeValueError]="'Match Amount is greater than Sell Balance Quantity'"
                                (disableEmitter)="disableButton($event)"
                                [filterOptionsList]="filterListSell" [refreshGrid]="onRefreshSell"
                                (clearOnFilter)="clearFilter('Sell')" [mode]="'Right'"
                                (onFilterApply)="filterForSell($event)"
                                [totalElements]="totalElementsSell" [totalPages]="totalPagesSell"
                                [pageSize]="pageSizeSell"
                                (onRefreshComplete)="onRefreshSell = false"
                                (onChangePage)="onChangePageSell($event)"
                                [heading]="'Sell Obligations'" [totalHeading]="'Total Obligation : '"
                                [totalMatchHeading]="'Sell Total Match Qty'"
                                [totalMatch]="sellTotal">
            </ctrm-planning-grid>

        </div>

        <div class="matchPlanButtonDiv">
            <button
                    *ngIf="!isLoading"
                    (click)="checkMatch()"
                    [disabled]="!canAccess || (((buyValue === undefined || buyValue === null || buyValue.length === 0 ||
        sellValue === undefined || sellValue === null || sellValue.length === 0 ||
        buyTotal === 0 || sellTotal === 0) && btnDisable) || (selectedBuyRows.length>=2 && selectedSellRows.length>=2))"
                    [ngClass]="btnDisable?'planScreenBtnsdisable':'planScreenBtns'">Match Trade Planning
            </button>
            <div *ngIf="isLoading" class="loadingbutton">
                <div class="loader"></div>
                <p>Match Plan</p>
            </div>

        </div>
    </div>


    <!--Planned table-->
    <div [hidden]="tabValue !== 'planned'">
        <ctrm-grid (onClickUniqueKey)="onUniqueClick($event)"
                   [refreshGrid]="refreshGrid"
                   [breadcrumbMapKey]="commonService.OPERATION_MAP_KEY"
                   [currentRoute]="'/planning'"
                   [customViewTemplated]="[matchTypeTemplate,statusTemplate]"
                   [fieldsWithCustomView]="['matchType','status']"
                   [grid]="grid"
                   [gridFilterDropdownList]="gridFilters"
                   [selectedRow]="selectedRows"
                   (selectedRows)="onSelectCB($event)"
                   [showAddButtonInNoData]="false"
                   [showSectionTop]="false"
                   (onDropdownFilterValueChange)="onFilterChange($event)"
                   (onRefreshComplete)="onRefreshGridComplete()"
                   (onClickedRefresh)="planRefresh()"
                   [autoCalculateGridWidth]="false"
                   [autoLayout]="true"
                   [onApplyAdvancedFiltervalue]=""
                   [headerWidth]="'130px'"
                   [showFilterChips]="false"
                   [openFormForNewEntry]="false"
                   [showSortIcon]="false"
                   [showStandardFilter]="true">
        </ctrm-grid>
    </div>
</div>

<p-sidebar [(visible)]="deleteDisplay" [baseZIndex]="25" [modal]="false" [showCloseIcon]="false"
           [styleClass]="'bottomPane'" position="bottom">
    <div class="bottomSidebarContainer">
        <div class="leftSection">
            <i (click)="cancel()" class="material-icons closable">clear</i>
        </div>
        <div class="rightSection">
            <button (click)="cancel()" class="cancelBtn">Cancel</button>
            <button [disabled]="deleteDisable" (click)="onRowDelete()" class="priceBtn">Delete</button>
            <button [disabled]="allocateDisable" (click)="onAllocateTransport()" class="priceBtn">Allocate Transport</button>
            <button *ngIf="showPlanCondition" (click)="onShowPlan()" class="priceBtn">Show Plan</button>
            <button [disabled]="deAllocateDisable" (click)="onDeAllocateTransport()" class="priceBtn">DeAllocate
                Transport
            </button>
        </div>
    </div>
</p-sidebar>
<allocate-vessel *ngIf="opendialog"
                 [planId]="selectedRowData['planId']"
                 (onDone)="onAllocationDone()"
                 (onCancel)="onFormCancel()"
                 [packageType]="'Container'"
                 [visible]="opendialog"></allocate-vessel>
<app-trade-info (onClose)="onClose()" *ngIf="display" [detailsOf]="detailsOf"
                [display]="true" [idValue]="plannedObligationId" [rowData]="oblRow" [sidebarPosition]="sidebarPosition"
                [tradeId]="tradeId"></app-trade-info>


<p-dialog (onHide)="closeMatchDialog()" [(visible)]="matchDisplay" [draggable]="false" [modal]="true"
          [resizable]="false" [style]="{width: '35vw'}">
    <p-header>
        <div style="display: flex; padding-left: 16px">
            <i class="far fa-check-square headerIconStyle"></i>
            <p class="matchHeader">SELECT MATCH TYPE</p>
        </div>

    </p-header>
    <div class="infoContainer">
        <!--    Warning section-->
        <div *ngIf="warningMessage.length > 0" style="padding-bottom: 8px">
            <div class="warningClass">
                <i class="fas fa-exclamation-circle"></i>
                <p>WARNING</p>
            </div>
            <p *ngFor="let msg of warningMessage" class="infoMessageClass">{{msg}}</p>
        </div>

        <!--    Error Section-->
        <div *ngIf="errorMessage.length > 0">
            <div class="errorClass">
                <i class="fas fa-times-circle"></i>
                <p>ERROR</p>
            </div>
            <p *ngFor="let msg of errorMessage" class="infoMessageClass">{{msg}}</p>
        </div>

        <!--    select availaible algo-->
        <p *ngIf="warningMessage.length == 0 && errorMessage.length == 0" class="infoMessageClass">Selected trade
            confirms
            to following Planning Type, select the desired planning type and press Continue. to create Trade Plan, to go
            back
            press Cancel</p>
    </div>


    <!--  algoType buttons-->
    <div *ngIf="errorMessage.length === 0" class="algoBtnsContainer ui-g">
        <div (click)="selectAlgo(btn)" *ngFor="let btn of algoBtnList; index as i" [ngClass]="getAlgoClass(btn)">
            <p>{{btn.title}}</p> <i *ngIf="selectedAlgo === btn.title" class="fas fa-check-circle algoTickClass"></i>
            <i *ngIf="btn['warnIcon']" class="fas fa-exclamation-triangle warnIcon"></i>
            <i *ngIf="btn['errIcon']" class="fas fa-ban errIcon"></i>
        </div>
        <div *ngIf="messages !== undefined && messages.length > 0" class="messageBox">
            <div *ngFor="let message of messages" class="messageLine">{{message}}</div>
        </div>
    </div>

    <p-footer>
        <div>
            <button (click)="closeMatchDialog()" class="cancelBtn">Cancel</button>
            <button (click)="continuePlanning()" [class.planScreenBtnsDisabled]="getContPlanValidation()"
                    [disabled]="getContPlanValidation()" class="planScreenBtns">Continue
                Planning
            </button>
        </div>

    </p-footer>
</p-dialog>

<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<circle-planning-info *ngIf="circleVisible && !showPlan && !showPlanDocByPass" [visible]="true" [buyObligation]="buyObligation"
                      [sellObligation]="sellObligation" [title]="formTitle" [showPlan]="showPlan"
                      (onComplete)="onCompleteCircleString($event)"
                      (onCancel)="onCancelCircleForm()"></circle-planning-info>

<circle-planning-info *ngIf="circleVisible && showPlan && !showPlanDocByPass" [visible]="true" [buyObligation]="buyObligation"
                      [sellObligation]="sellObligation" [title]="formTitle" [showPlan]="showPlan"
                      [planId]="selectedRowData['planId']" [matchType]="selectedRowData['matchType']"
                      [showCloseIcon]="true"
                      (onComplete)="onCompleteCircleString($event)"
                      (onCancel)="onCancelCircleForm()"></circle-planning-info>

<circle-planning-info *ngIf="circleVisible && showPlanDocByPass && !showPlan " [showPlanDocByPass]="showPlanDocByPass"
                      [visible]="true" [buyObligationRequired]="buyObligationRequired" [buyObligation]="buyObligation"
                      [sellObligationRequired]="sellObligationRequired" [sellObligation]="sellObligation" [title]="'Doc Bypass'"
                      [planId]="selectedRowData['planId']" [matchType]="selectedRowData['matchType']"
                      (onComplete)="onCompleteDocByPass($event)"
                      (onCancel)="onCancelDocByPass()"
                      [showVesselBox]="false"
></circle-planning-info>

<p-dialog
        [(visible)]="opendialogfordatepicker"
        [draggable]="false"
        [closeOnEscape]="false"
        [closable]="false"
        [resizable]="false"
        [modal]="true"
        [style]="{width: '25vw'}">
    <p-header>
        <div class="divBox">
            <p class="header" style="color:black">Select Washout Establishment Date </p>
            <p class="description"></p>
        </div>
    </p-header>
    <div class="inputboxwashout">
        <input [(ngModel)]="washoutEstablishment"
               [bsConfig]="bsConfig"
               [minDate]="minDate"
               [maxDate]="todayDate"
               autocomplete="off"
               bsDatepicker class="form-control form-control-sm"
               placeholder="Select Date"
               type="text">
    </div>

    <p-footer>
        <div style="display: flex;justify-content: center;align-items: center; column-gap: 10px;  ">
            <button class="cancelBtn" (click)="cancelWashoutdialog()">Cancel</button>
            <button  class="continuebutton" [disabled]="" (click)="selectWashout()">continue</button>
        </div>
    </p-footer>
</p-dialog>





<ng-template #matchTypeTemplate let-rowData='rowData'>
    <p class="matchAlgoStyle">
        {{rowData['matchType']}}
    </p>
</ng-template>

<ng-template #statusTemplate let-rowData='rowData'>
        <div class="planStatusField">
            <img alt="" src="{{getIconForStatus(rowData)}}">
            <p [ngClass]="getClassForStatus(rowData)">{{getPlannedStatus(rowData)}}</p>
        </div>
</ng-template>
