<p-dialog (onHide)="closeCircle()" [(visible)]="visible" [draggable]="false" [modal]="true"
          [closeOnEscape]="false"
          [closable]="false"
          [resizable]="false" [style]="{width: '70%'}">
    <p-header>
        <div style="display: flex; padding-left: 16px">
            <i (click)="closeCircle()" class="fas fa-angle-left headerIconStyle"></i>
            <div class="divBox">
                <p *ngIf="!showPlan" class="header">{{title}}</p>
                <p *ngIf="showPlan" class="header">{{matchType}}</p>
                <p class="description">{{description}}</p>
            </div>
            <i *ngIf="showCloseIcon" (click)="closeCircle()" class="fas fa-times closeIconStyle"></i>
        </div>
    </p-header>
    <div class="contentBox">
        <div *ngIf="!showValidate" class="sectionBox">
            <div style="display: inline-flex">
                <div class="staticDatabox">
                    <p class="infoHeader">Commodity</p>
                    <p class="infoValue">{{showPlan?commodityShowPlan:getCommonField('commodity')}}</p>
                </div>
                <div class="staticDatabox">
                    <p class="infoHeader">Quantity</p>
                    <p *ngIf="!showPlan" class="infoValue">{{getCommonField(title === 'Doc Bypass'?"plannedQuantity":"matchQuantity")}} {{getCommonField('quantityUOM')}}</p>
                    <p *ngIf="showPlan" class="infoValue">{{quantityShowPlan}}</p>
                </div>
                <div class="staticDatabox">
                    <p class="infoHeader">Contract Month</p>
                    <p class="infoValue">{{showPlan?contractMonthShowPlan:contractMonth}}</p>
                </div>
            </div>
            <div class="divider"></div>
            <div style="display: inline-flex">
                <div class="staticDatabox" style="padding-right: 0px">
                    <p class="infoHeader">No. of Entries</p>
                    <div class="inputbox">
                        <input class="form-control form-control-sm inputClass" type="number" [disabled]="showPlan || showPlanDocByPass" [(ngModel)]="noOfEnteries" pInputText>
                        <button (click)="addEntries()" class="addEntryButton" [disabled]="showPlan || showPlanDocByPass">Add Entries</button>
                    </div>
                </div>
                <div class="staticDatabox" style="padding-right: 0px">
                    <p class="infoHeader">{{showPlan?'':title}} Settlement Price</p>
                    <div class="inputbox">
                        <input class="form-control form-control-sm inputClass" [disabled]="showPlan || showPlanDocByPass"
                               type="number" [(ngModel)]="settlementPrice" (ngModelChange)="onPriceValueChange(null,0,false)" pInputText>
                    </div>
                </div>
                <div class="staticDatabox" style="padding-right: 0px">
                    <p class="infoHeader">Settlement Currency</p>
                    <div class="inputbox">
                        <p-dropdown
                                [(ngModel)]="settlementCurrency"
                                (ngModelChange)="onSettlementCurrencyChange()"
                                [options]="currencyList"
                                appendTo="body" [disabled]="showPlan || showPlanDocByPass"
                                placeholder="Settlement Currency">
                        </p-dropdown>
                    </div>
                </div>
                <div class="staticDatabox" style="padding-right: 0px">
                    <p class="infoHeader">Established On</p>
                    <div class="inputbox">
                        <input  [bsConfig]="{adaptivePosition:true,dateInputFormat:dateFormat,useUtc:false}"
                                [minDate]="establishMinDate"
                                bsDatepicker [disabled]="showPlan || showPlanDocByPass"
                                [(ngModel)]="establishedOn"
                                class="form-control form-control-sm pickerInput"
                                placeholder="Select Date" type="text">
                    </div>
                </div>
            </div>
            <div class="tablediv">
                <p-table #dt
                         [columns]="columns"
                         [metaKeySelection]="true"
                         [responsive]="true"
                         [rowHover]="true"
                         [style]="{maxHeight:gridHeight,minHeight:gridHeight}"
                         [value]="values"
                         autoLayout="true"
                         inViewport (inViewportAction)="calculateHeight(dt)"
                         class="style-4 table-responsive">
                    <ng-template pTemplate="header">
                        <tr class="sticky-top">
                            <th style="width:3rem" class="headerStyle stk"></th>
                            <th *ngFor="let col of columns;index as i" [id]="'header'+i"
                                class="headerStyle stk">
                                <div class="headerDiv">
                                    <p class="headerTitle">{{col.getHeader()}}</p>
                                </div>
                                <div *ngIf="i !== (columns.length -1)" class="actionPipe"></div>
                            </th>
                            <th class="headerStyle stk">
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template let-columns="columns" let-ri="rowIndex" let-rowData pTemplate="body">
                        <tr [pReorderableRow]="ri" [pSelectableRow]="getSelectableRow(rowData,ri)" >
                            <td>
                                <div style="margin-left: 5px;display: inline-flex;align-items: center">
<!--                                    <i class="pi pi-bars" style="font-size: 16px" pReorderableRowHandle></i>-->
                                    <i class="fas fa-caret-up" [ngClass]="getUpOrderClassIcon(ri)" (click)="(title === 'Doc Bypass' && !rowData['newEntry'])?'':(['String','Circle'].includes(title)?moveUpTag(ri,rowData):moveUp(ri,rowData))"></i>
                                    <i class="fas fa-caret-down" [ngClass]="getDownOrderClassIcon(ri)" (click)="(title === 'Doc Bypass' && !rowData['newEntry'])?'':(['String','Circle'].includes(title)?moveDownTag(ri,rowData):moveDown(ri,rowData))"></i>
                                </div>
                            </td>
                            <td class="tdStyle" pEditableColumn>
                                <div class="inputbox">
                                    <input [(ngModel)]="rowData['sequenceNumber']"
                                           class="form-control form-control-sm" disabled
                                           placeholder="Sequence Number"
                                           tooltipZIndex="1000" type="number">
                                </div>
                            </td>
                            <td class="tdStyle" pEditableColumn>
                                <div class="inputbox">
                                    <input [(ngModel)]="rowData['referenceNumber']"
                                           class="form-control form-control-sm"
                                           [disabled]="!rowData['newEntry']"
                                           placeholder="Reference Number"
                                           tooltipZIndex="1000" type="text">
                                </div>
                            </td>
                            <td class="tdStyle" pEditableColumn>
                                <div class="inputbox">
                                    <input *ngIf="!rowData['newEntry']" [(ngModel)]="rowData['seller']"
                                           class="form-control form-control-sm" disabled
                                           placeholder="Seller"
                                           tooltipZIndex="1000">
                                    <p-dropdown
                                            *ngIf="rowData['newEntry']"
                                            [(ngModel)]="rowData['seller']"
                                            (ngModelChange)="onSellerChange($event,ri)"
                                            [options]="counterpartyList"
                                            appendTo="body"
                                            placeholder="Seller">
                                    </p-dropdown>
                                </div>
                            </td>
                            <td class="tdStyle" pEditableColumn>
                                <div class="inputbox">
                                    <input *ngIf="canShowLast(rowData,ri)"
                                           [(ngModel)]="rowData['buyer']"
                                           class="form-control form-control-sm" disabled
                                           placeholder="Buyer"
                                           tooltipZIndex="1000">
                                    <p-dropdown
                                            *ngIf="!canShowLast(rowData,ri)"
                                            [(ngModel)]="rowData['buyer']"
                                            [options]="counterpartyList"
                                            appendTo="body"
                                            placeholder="Buyer">
                                    </p-dropdown>
                                </div>
                            </td>
                            <td class="tdStyle" pEditableColumn>
                                <div class="inputbox">
                                    <input [(ngModel)]="rowData['price']"
                                           (ngModelChange)="onPriceValueChange(rowData,ri)"
                                           (focusout)="formatPrice(rowData)"
                                           class="form-control form-control-sm"
                                           placeholder="Price" [disabled]="!rowData['newEntry']"
                                           tooltipZIndex="1000" type="text">
                                </div>
                            </td>
                            <td class="tdStyle" pEditableColumn>
                                <div class="inputbox">
                                    <input *ngIf="!rowData['newEntry']" [(ngModel)]="rowData['currency']"
                                           class="form-control form-control-sm" disabled
                                           placeholder="Currency"
                                           tooltipZIndex="1000">
                                    <p-dropdown
                                            *ngIf="rowData['newEntry']"
                                            [(ngModel)]="rowData['currency']"
                                            (ngModelChange)="onPriceValueChange(rowData,ri)"
                                            [options]="currencyList"
                                            appendTo="body"
                                            placeholder="Currency">
                                    </p-dropdown>
                                </div>
                            </td>
                            <td class="tdStyle" pEditableColumn>
                                <div class="inputbox">
                                    <input [(ngModel)]="rowData['amount']"
                                           class="form-control form-control-sm"
                                           placeholder="Amount Calculated" disabled
                                           tooltipZIndex="1000" type="text">
                                </div>
                            </td>
                            <td class="tdStyle">
                                <i (click)="rowData['newEntry']?rowDelete(ri):''" [pTooltip]="rowData['newEntry']?'Delete':''" [ngClass]="rowData['newEntry']?'iconClassDelete':'iconClassDeleteDisabled'" class="far fa-times-circle "></i>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <div *ngIf="showValidate" style="height: 60vh">
            <div class="validBox">
                <i [ngClass]="getValidBoxClass()"></i>
                <p>{{validityMessage}}</p>
            </div>
           <flowchart [nodeData]="nodeData" [linkData]="linkData" [width]="getScreenWidth()" [height]="getScreenHeight()"></flowchart>
        </div>
        <div  *ngIf="!showPlan && !showPlanDocByPass" [hidden]="showValidate" class="staticDatabox commentBox">
            <div *ngIf="showVesselBox">
            <div class="row" style="display: flex;margin: 1rem 0">
                <div class="col-md-3 fieldBox">
                    <p class="fieldName">Voyage Id</p>
                    <div class="inputbox">
                        <p-dropdown
                                [(ngModel)]="voyageId"
                                [options]="voyageList"
                                (ngModelChange)="onChangeVoyage()"
                                appendTo="body"
                                placeholder="Load Port">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-md-3 fieldBox">
                    <p class="fieldName">Vessel Name</p>
                    <div class="inputbox">
                        <p-dropdown
                                [(ngModel)]="vesselName"
                                [options]="vesselList"
                                appendTo="body"
                                placeholder="Load Port">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-md-3 fieldBox">
                    <p class="fieldName">Load Port</p>
                    <div class="inputbox">
                        <p-dropdown
                                [(ngModel)]="loadPort"
                                [options]="portList"
                                appendTo="body"
                                placeholder="Load Port">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-md-3 fieldBox">
                    <p class="fieldName">Discharge Port</p>
                    <div class="inputbox">
                        <p-dropdown
                                [(ngModel)]="dischargePort"
                                [options]="portList"
                                appendTo="body"
                                placeholder="Discharge Port">
                        </p-dropdown>
                    </div>
                </div>
            </div>
            </div>
            <p class="infoHeader">Comments/Notes</p>
            <div class="commentInputbox">
                <textarea class="form-control form-control-sm commentInputClass"
                          placeholder="Type your Comments/Notes here" [(ngModel)]="comment" pInputTextarea></textarea>
            </div>

        </div>
        <!--        Content Here-->
    </div>
    <p-footer>
        <div>
            <button *ngIf="!showPlan && !showPlanDocByPass" (click)="validate()" class="planScreenBtns" style="float: left">{{!showValidate?'Validate':'Back to Data View'}}</button>
            <button (click)="closeCircle()" class="cancelBtn">Cancel</button>
            <button (click)="continuePlanning()" [disabled]="disableButton || showPlan || showPlanDocByPass" class="planScreenBtns">Continue Planning
            </button>
        </div>
    </p-footer>
</p-dialog>
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>
