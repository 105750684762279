<div style="display: inline-flex;width: 100%">
    <masters-sidenav></masters-sidenav>
    <div id="pinwidth" style="width: 100%;margin-left: 80px">

        <ctrm-grid (onClickUniqueKey)="openSidebar($event)" (editAllowedOut)="isEditAllowed($event)"
           [breadcrumbMapKey]="commonService.MASTER_MAP_KEY" [currentRoute]="'/rolemaster'"
           [grid]="grid"
           [openInputForm]="inputData">
        </ctrm-grid>
        <app-accesspolicy (onClose)="changeDisplay()" [canEdit]="canEdit" (onEditClick)="onClickEdit($event)" *ngIf="display" [data]="file"
                  [display]="display"
                  [selectedRowData]="selectedRowData">
        </app-accesspolicy>
    </div>
</div>