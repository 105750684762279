import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'ctrm-chips',
  templateUrl: './ctrm-chips.component.html',
  styleUrls: ['./ctrm-chips.component.css']
})
export class CtrmChipsComponent implements OnInit {
  @Input() chip: any = {};
  @Input() filter: any = {};
  @Input() position: number;
  @Input() removable: boolean = true;
  @Output() OnRemove = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  onClose() {
    this.OnRemove.emit({f: this.filter, i: this.position});
  }
}
