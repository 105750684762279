import {MenuItem} from 'primeng/api';
import { ApprovalFunctionParams } from 'src/app/Components/approval-context-menu/model/approval-function.params';
import {Button} from '../../grid/buttons.model';
import {tradeOptionsButtons} from '../../services/common/entities';

export const ObligationDashboardApprovalFunctions: { [key: string]: Function } = {
    loadMenuItems: (param: ApprovalFunctionParams): MenuItem[] => {
        let items: MenuItem[] = [];
        let tradeObligationstate = param.rowData['tradeObligationState'];
        let plannedObligationState = param.rowData['obligationState'];
        let generateProformaInvoiceFunction = function() {
            param.context.onMenuItemClick.emit({title: tradeOptionsButtons.generateProFormaInvoice,data:param.rowData});
        };

        let tradePlan = function() {
            param.context.onMenuItemClick.emit({title: "Trade Plan",data:param.rowData});
        };

        let tradePlanning = function() {
            param.context.onMenuItemClick.emit({title: "Trade Planning",data:param.rowData});
        };

        let allocatePrice = function() {
            param.context.onMenuItemClick.emit({title: "Allocate Price",data:param.rowData});
        };

        let transportAllocation = function() {
            param.context.onMenuItemClick.emit({title: "Transport Allocation",data:param.rowData});
        };

        let actualization = function() {
            param.context.onMenuItemClick.emit({title: "Actualization",data:param.rowData});
        };

        let settlementClick = function() {
            param.context.onMenuItemClick.emit({title: "Settlement",data:param.rowData});
        };

        let invoice = function() {
            param.context.onMenuItemClick.emit({title: "Invoice",data:param.rowData});
        };

        let tradePlanningButton = new Button("Trade Planning", 'F', '', tradePlanning, undefined, undefined, undefined, undefined,true);
        let tradePlanButton = new Button("Trade Plan", 'F', '', tradePlan, undefined, undefined, undefined, undefined, true);
        let priceAllocationButton = new Button("Allocate Price", 'F', '', allocatePrice, undefined, undefined, undefined, undefined, true);
        let transportAllocationButton = new Button("Transport Allocation", 'F', '', transportAllocation, undefined, undefined, undefined, undefined, true);
        let actualizationButton = new Button("Actualization", 'F', '', actualization, undefined, undefined, undefined, undefined, true);
        let settlementButton = new Button("Settlement", 'F', '', settlementClick, undefined, undefined, undefined, undefined, true);
        let invoicingButton = new Button("Invoice", 'F', '', invoice, undefined, undefined, undefined, undefined, true);
        if(plannedObligationState !== undefined && plannedObligationState !== null && Object.keys(plannedObligationState).length > 0) {
            if(plannedObligationState['PLANNED']) {
                tradePlanButton.disabled = false;
            }
            if((param.rowData['priceType'].toLowerCase() === 'ptbf' || param.rowData['priceType'].toLowerCase() === 'basis') && !plannedObligationState['PRICED']) {
                priceAllocationButton.disabled = false;
            }
            if(param.rowData['planMatchType'] !== null && param.rowData['planMatchType'] !== undefined && param.rowData['planMatchType'].toLowerCase() !== 'washout') {
                transportAllocationButton.disabled = false;

            }
            if(!plannedObligationState['ACTUALIZED']) {
                actualizationButton.disabled = false;
            } else {
                settlementButton.disabled = param.rowData['invoiceDone'];
                invoicingButton.disabled = !param.rowData['invoiceDone'];
            }

        } else {
            if (tradeObligationstate !== null && tradeObligationstate !== undefined && tradeObligationstate === 'UNPLANNED') {
                tradePlanningButton.disabled = false;
            }
        }
        items.push(param.commonService.getMenuItemFromButton(tradePlanningButton));
        items.push(param.commonService.getMenuItemFromButton(tradePlanButton));
        items.push(param.commonService.getMenuItemFromButton(priceAllocationButton));
        items.push(param.commonService.getMenuItemFromButton(transportAllocationButton));
        items.push(param.commonService.getMenuItemFromButton(actualizationButton));
        items.push(param.commonService.getMenuItemFromButton(settlementButton));
        items.push(param.commonService.getMenuItemFromButton(invoicingButton));
        items.push(param.commonService.getMenuItemFromButton(new Button(tradeOptionsButtons.generateProFormaInvoice, 'F', '', generateProformaInvoiceFunction, undefined, undefined, undefined, undefined, false)));
        return items;
    },
    onDone: (param: ApprovalFunctionParams) => {
        param.commonService.postMessage('', `${param.currentApprovalObject.step['actionName']} ${param.currentApprovalObject.buttonName} Successfully.`,'success');
        param.onUpdateDone.emit({step: param.currentApprovalObject.buttonName});
    }
};
