import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Tab} from "../../../tab-grid/Tab.model";
import {MasterService} from "../../../masters/services/MasterService";
import {CommonService} from "../../../services/common.service";
import {ActivatedRoute} from "@angular/router";
import {filter} from "../../../masters/services/filter.model";
import {environment} from "../../../../environments/environment";
import {InventoryComponent} from "../../inventory/inventory.component";
import {FormBuilder} from '@angular/forms';
import {MessageService} from 'primeng';

@Component({
  selector: 'app-storage-info',
  templateUrl: './storage-info.component.html',
  styleUrls: ['./storage-info.component.css']
})
export class StorageInfoComponent implements OnInit, OnChanges {

  @Input() display: boolean = false;
  @Input() storageId: string = '';
  @Input() sidebarPosition: string = 'right';
  @Input() rowData: any = {};
  @Output() onClose = new EventEmitter();
  tabs: Tab[] = [];
  inventory: InventoryComponent;
  storageObject: any = {};
  editUrl: string;
  inventoryUrl: string;
  docUrl: string;
  docName: string;
  showDoc: boolean;


  constructor(private  masterCommonService: MasterService, private commonService: CommonService, private router: ActivatedRoute,private formBuilder: FormBuilder,private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.initTabs();
  }


  initTabs() {
    this.inventory = new InventoryComponent(this.masterCommonService, this.commonService, this.router,this.formBuilder,this.messageService);
    this.inventory.ngOnInit();
    setTimeout(()=>{
      this.updateTabs();
      this.fetchstorage(this.storageId);
    },1000)
  }

  updateTabs() {
    let _this = this;
    this.tabs = [];
    this.editUrl = this.getEditUrl();
    let tab = new Tab(this.inventory.grid.getTitle(), this.inventory.grid, 'storageId', undefined, true, this.inventoryUrl, {minHeight: '86.6vh'});
    tab.getGrid().setTabs([]);
    tab.getGrid().setGridConfiguration(false, false, false);
    tab.formOnModal = true;
    this.tabs.push(tab);


  }

  close() {
    this.display = false;
    this.onClose.emit();
  }

  fetchstorage(storageId: string) {
    this.commonService.getJSONByURL(environment.base_url + '/api/storageInventory/v1/getstorageinventorybyuuid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&uuid=' + storageId).subscribe((next: any[]) => {
      if (next !== undefined && next !== null) {
        this.storageObject = next;
        this.updateTabs();
      }
    });
  }

  onIndexChange(value: number) {
  }

  private getEditUrl() {
    let id = this.storageObject['uuid'];
    return environment.base_url + '/api/storageInventory/v1/getstorageinventorybyuuid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&uuid=' + id;
  }

  onCloseDocViewer(value: any) {
    this.docUrl = '';
    this.showDoc = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['storageId']) {
      this.fetchstorage(changes['storageId'].currentValue);
    }
  }


}
