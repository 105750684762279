<div >
    <input *ngFor="let item of keys" (change)="onChange"
           (touch)="onTouched"
           [name]="name"
           [disabled]="disabled"
           [ngClass]="'form-control-normal'"
           [type]="inputType"
           [placeholder]="item"
           [(ngModel)]="map[item]"
           style= "margin-bottom: 10px;"
           (ngModelChange)="convertIntoOutputForm()"
           class="form-control form-control-sm" pInputText
           tooltipEvent="focus"
           tooltipPosition="bottom" tooltipZIndex="1000">
</div>
