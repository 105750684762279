import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Grid} from "../../../grid/grid.model";
import {Tcolumn} from "../../../grid/tcolumn.model";
import {CommonService} from "../../../services/common.service";
import {environment} from "../../../../environments/environment";
import {ctrmValidators} from "../../../services/validators/ctrmValidators";
import {HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {defaultValue} from "../../../grid/defaultValue.model";
import {TradeComponent} from "../../../physicals/contract/trade/trade.component";
import {Action} from "../../../grid/actions.model";
import {FormGroup, Validators} from "@angular/forms";
import {MasterService} from "../../../masters/services/MasterService";
import {ConfirmationService, MessageService} from "primeng";
import {ActivatedRoute, Router} from '@angular/router';
import {KeyValue} from "../../../grid/key-value.model";
import {Table} from "primeng/table";
import {billOfLadingFunctions} from './bill-of-lading-functions';
import {FormControlServiceService} from '../../../services/form-control-service.service';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
import {DatePipe} from '@angular/common';

export class SearchCriteria {
    fieldName!: string;
    condition!: string;
    value!: string[];
    secondValue!: string;

    constructor(fieldName: string, value: any, condition?: any) {
        this.fieldName = fieldName;
        this.condition = condition ? condition : 'in';
        this.value = value;
        this.secondValue = '';
    }
}

@Component({
  selector: 'actualization-form',
  templateUrl: './actualization-form.component.html',
  styleUrls: ['./actualization-form.component.css']
})
export class ActualizationFormComponent implements OnInit,OnChanges {

  @Input() dialogDisplay: boolean = false;
  @Output() onClose = new EventEmitter();
  @Input() vesselId = '';
  @Input() voyageNo = '';  
  @Input() planId = '';
  @Input() claimAccess:boolean = false;
  @Input() undoClaimAccess:boolean= false;
  @Input() obligationId = '';
  @Input() vesselPop:boolean = false;
  tabs: any[] =[];
  isConfirmBL: boolean = false;
  selectedTab: string = "Quantity";
  expandedBlrow:any[] = [];
  quantityGrid: Grid;
  splitedPlannedObligationId = '';
  private splitRows:any[] = [];
  isBuy: boolean;
  warningClaim:boolean = false;
  warningFOSFA:boolean = false;
  buyValue: boolean = true;
  sellValue: boolean = true;
  @Input() mode: string = '';
  private box:HTMLDivElement;
  private table:Table;
  disableCardList:any[] =[];
  canClose: boolean = true;
  contextSelectedRow:any = {};
  billOfLandingFormGroups:Map<string,FormGroup> = new Map<string, FormGroup>();
  plannedObligations:any[] = [];
  gridHeight: any;
  selectedPlannedObligations:any[] =[];
  quantityValues: any[] =[];
  searchValue:any= '';
  refreshCardLibrary: boolean = false;
  allselected: boolean = true;
  isallselected: boolean = true;
  costGrid: Grid;
  expandedBlrowIndex:number = -1;
  qualitySpecGrid: Grid;
  billOfLandingGrid: Grid;
  attachmentGrid: Grid;
  costValue: any[] = [];
  fileSelected:boolean =false;
  attachmentValues: any[] = [];
  selectedCost: any[] =[];
  hiddenCard:any[] =[];
  quantityRoundOff:number=3;
  priceRoundOff:number=2;
  refreshingCost: boolean = false;
  selectedRowData: any;
  popupDisplay: boolean = false;
  refreshGrid: boolean = false;
  toolBarConfig: any = {
    add: false,
    edit: false,
    copy: false,
    delete: false,
    refresh: true,
    export: true,
    exportAll: false,
    import: false,
    audit: false,
    print: false,
    prefrences: true
  };
  toolBarConfigBol: any = {
    add: false,
    edit: false,
    copy: false,
    delete: false,
    refresh: false,
    export: true,
    exportAll: false,
    import: false,
    audit: false,
    print: false,
    prefrences: false
  };
  toolBarConfigAttachment: any = {
    add: true,
    edit: false,
    copy: false,
    delete: false,
    refresh: true,
    export: true,
    exportAll: false,
    import: false,
    audit: false,
    print: false,
    prefrences: true
  };
  checkBoxFunction:Function;
  qualityValue: any[] =[];
  refreshingQualitySpec: boolean = false;
  selectedQuality: any[] =[];
  selectedbilloflanding: any[] =[];
  refreshingAttachment: boolean = false;
  displayBottomPane: boolean = false;
  vesselObj:any= {};
  planObj:any= {};
  tradeIds:any = {};
  actualizeButtonHeader: any = "Actualize Quantity";
    displayTradeInfo: boolean = false;
  rowData: any;
  allcheckboxvalue: any = 'all';
  loading: boolean = false;
  gridFilters:any[] = [];
  planIds = [];
  commodity = [];
  plannedObligationsIds = [];
  quantityDisabledRows: any[] = [];
  costDisabledRows: any[]= [];
  qualityDisabledRows: any[]= [];
  isActualizeButtonDisable: boolean = false;
  billOfLandingValues: any[] = [];
  refreshingBillOfLanding: boolean = false;
  isSplitQuantityButtonDisable: boolean = true;
  isUndoSplitQuantityButtonDisable: boolean = true;
  splitDisplay: boolean = false;
  splitInValue: number = 1;
  splitButtonDisable: boolean = false;
  isSplitDisable: boolean = false;
  showUploadForm: boolean = false
  showGenerateForm: boolean = false
  uploading: boolean = true
  addBlButtonDisable: boolean = false;
  surrenderBlShow: boolean = false;
  showMergeDisplay: boolean = false;
  mergeInValue: number = 1;
  isSwitchBlDisabled: boolean = true;
  isConfirmBlDisabled: boolean = true;
  isMergeBlDisabled: boolean = true;
  switchChecked: boolean = false;
  surrenderValues: any[] = [];
  confirmedBLRow:any;
  checkedBlValues:any[] =[];
  isClaimButtonDisable: boolean = true;
  confirmDialogMessage: any = "";
  uploadFileName:string = '';
  uploadObject:any = {};
  blfields:any[] = [
    {field:'blNumber',header:'BL Number'},
    {field:'blDate',header:'BL Date'},
    {field:'consignee',header:'Consignee'},
    {field:'contractRef',header:'Contract Reference Number'},
    {field:'selfToOrder',header:'Self/To Order'},
    {field:'shipper',header:'Shipper'}];
  documentType: any;
  documentTypeList: any[] = [];
  warningBoxVisible: boolean = false;
  warningMessage: any[] = [];
  errorMessage: any[] =[];
  bsConfig: Partial<BsDatepickerConfig>;
  @HostListener("window:resize", [])
  public onResize() {
    this.calcaulateListHeight();
    this.calculateGridHeight(this.box);
  }
  openFilterForm: boolean = false;
  actualizeFilterForm: FormControlServiceService = new FormControlServiceService();
  filterDataTradeId: any[];
  filterDataCommodity: any[];
  filterDataLoadPort: any[];
  filterDataUnloadPort: any[];
  tradeId: any[] = [];
  date: any;
  loadPort: any[] = [];
  unloadPort: any[] = [];
  commodities: any[] = [];
  searchCriteriaList: SearchCriteria[] = [];

  datePipe: DatePipe;
  qualityEditableFunctionField: Function;
  showSettlementPrice: boolean = false;
  settlementPrice: number = 0;
  undoClaimApi:Function = null;
  unclaimDisable: boolean = false;

  constructor(public commonService:CommonService, public masterCommonService:MasterService, private messageService:MessageService, private  route:ActivatedRoute,
              private confirmationService: ConfirmationService, private router :Router) { }

    ngOnInit(): void {
        let _this = this;
      let tenantConfig=JSON.parse(_this.commonService.getFromStorage("tenantConfig"));
      if(tenantConfig && tenantConfig.roundingFormat){
        _this.quantityRoundOff=tenantConfig.roundingFormat.quantityRounding;
        _this.priceRoundOff=tenantConfig.roundingFormat.priceRounding;
      }
      else{
        _this.quantityRoundOff=3;
        _this.priceRoundOff=2;
      }
        this.checkBoxFunction = function(value: any) {
            return (value['actualizedStatus'] === 'Actualized');
        };
        this.tabs.push({name: 'Quantity'});
        this.tabs.push({name: 'Bill of Lading'});
        this.tabs.push({name: 'Cost', icon: 'fas fa-usd'});
        this.tabs.push({name: 'Quality'});
        this.tabs.push({name: 'Document Upload'});
        this.createQuantityGrid();
        this.getGrids();
        this.createBillOfLandingGrid();
        this.getDocumenttypeList();

    this.bsConfig = new BsDatepickerConfig();
    this.bsConfig.dateInputFormat = environment.dateFormat.toUpperCase();
    this.bsConfig.adaptivePosition = true;
    this.bsConfig.useUtc = false;
    this.undoClaimApi = function(payload: any) {
      let row = _this.quantityValues[0];
      if(row !== null && row !== undefined) {
        payload['url'] = `${environment.base_url}/api/actualization/v1/unclaim-quantity?obligationId=${row['plannedObligationId']}&splitNumber=${row['splitSequenceNumber']}`;
        payload['type'] = 'get';
      }
      return payload;
    };
    this.route.queryParams.subscribe( params => {
      if (params !== undefined && params !== null && Object.keys(params).length > 0) {
        let tabValue = params['tabValue'];
        this.selectedTab = tabValue == 'documents'?'Document Upload': 'Quantity';
      }
    });
    this.actualizeFilterForm.addControl('tradeId', [])
        .addControl('date', '')
        .addControl('loadPort', [])
        .addControl('unloadPort', [])
        .addControl('commodities', []).build();
  }

  getDocumenttypeList() {
    let _this = this;
    let url = `${environment.base_url_new}/api-bm/api/doctemplateservice/v1/getdocumentbybusinessname?tenantId=${this.commonService.getTenantId()}&businessApplicationName=Bill Of Lading`;
    _this.commonService.getJSONByURL(url).subscribe((next: any) => {
      _this.documentTypeList = this.masterCommonService.getListFromArray(next.map(item => item['docTemplateName']));
    });
  }

  private loadVesselInfo() {
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url+'/api/transportallocationv2/v1/get-sea-freight-details-by-vessel-id-and-plan-id-v2?tenantId='+this.commonService.getFromStorage('tenantId')+'&vesselId='+this.vesselId+'&planId='+this.planId).subscribe((next:any) => {
      _this.vesselObj = next;
    });
  }

  private loadPlanInfo() {
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url+'/api/physicaltradeplanning/v1/getplaninfobyplanid?tenantId='+this.commonService.getFromStorage('tenantId')+'&planId='+this.planId).subscribe((next:any) => {
      _this.planObj = next;
    });
  }

  getGrids() {
    let _this = this;
    let statusFunction = function(value: any[]) {
      let html: any;
      if (value[0] === 'Actualized') {
        html = _this.commonService.getSafeHtml('<div style="display: inline-flex">' +
            '<img src="../../../assets/images/svg/purpleTick.svg" alt="" width="16px" height="16px">' +
            '<p style="color:#000000;margin: 0;padding: 0;font-size: 14px;margin-left: 5px;white-space: nowrap !important;">Actualized</p></div>');
      } else {
        html = _this.commonService.getSafeHtml('<div style="display: inline-flex">' +
            '<img src="../../../assets/images/svg/estimate.svg" alt="" width="16px" height="16px">' +
            '<p style="color:#000000;margin: 0;padding: 0;font-size: 14px;margin-left: 5px;white-space: nowrap !important;">Estimate</p></div>');
      }
      return html;
    };
    let qualitystatusFunction = function(value: any[]) {
      let html: any;
      if (value[0] === 'Actualized') {
        html = _this.commonService.getSafeHtml('<div style="display: inline-flex">' +
            '<img src="../../../assets/images/svg/purpleTick.svg" alt="" width="16px" height="16px">' +
            '<p style="color:#000000;margin: 0;padding: 0;font-size: 14px;margin-left: 5px;white-space: nowrap !important;">Actualized</p></div>');
      } else {
        html = _this.commonService.getSafeHtml('<div style="display: inline-flex">' +
            '<img src="../../../assets/images/svg/estimate.svg" alt="" width="16px" height="16px">' +
            '<p style="color:#000000;margin: 0;padding: 0;font-size: 14px;margin-left: 5px;white-space: nowrap !important;">Contracted</p></div>');
      }
      return html;
    };

    let tradeComponent = new TradeComponent(this.masterCommonService,this.commonService, this.messageService, this.route,null);
    tradeComponent.ngOnInit();
    let valueFunc = function (value:any[]) {
      return _this.tradeIds[value[0]];
    }



    let actionPlannedObligations = new Action();
    actionPlannedObligations.addFieldToUpdate('tradeId',undefined,valueFunc,['plannedObligationId'],undefined,false,'defaultValue');

    let plannedObligationIdValue = function (value:any[]) {
      if(value[1] !== 0) {
        return value[0]+' ('+value[1]+')'
      }
      return value[0];
    }

    this.costGrid = this.getCostGrid();
    this.costGrid.setPrimaryKey('name');
    this.costGrid.getColumn().get('quantityOption').setDefaultValue('scheduleQty');
    this.costGrid.getColumn().get('quantityOption').setExtra({meta: {disabled: true}});
    this.costGrid.addColumn(new Tcolumn('plannedObligationId', 'Planned Obligation Id', 'L', 3, false, undefined, true,new defaultValue('',undefined,undefined,undefined,false,true,false,plannedObligationIdValue,['plannedObligationId', 'splitSequenceNumber']),'B',[],'text',1,undefined,undefined,undefined,actionPlannedObligations));
    this.costGrid.addColumn(new Tcolumn('tradeId', 'Trade Id', 'LB', 4, false, undefined, true));
    this.costGrid.getColumn().set('actualizedStatus', new Tcolumn('actualizedStatus', 'Actualized Status', 'LB', 5, false, undefined, true, new defaultValue('Estimate', undefined, undefined, undefined, false, false, false, statusFunction)));
    this.costGrid.getColumn().set('actualizedCostId', new Tcolumn('actualizedCostId', 'Actualized Cost Id', 'LB', 5, true, undefined, false, ''));
      this.costGrid.openSidebarOnUniqueClick = false;
    this.costGrid.showCheckbox = true;
    this.costGrid.primaryKeyClickable = false;
    this.costGrid.addClickable('tradeId');
    this.costGrid.setGridConfiguration(false,false,false,true,false);
    this.costGrid.getColumn().get('costId').setVisibility(false);
    this.costGrid.addNewRowInline = false;
    this.costGrid.actionFunc.newCopyValueFunc = function (value:any) {
      value['actualizedCostId'] = '';
      value['uuid'] = '';
      return value;
    }
    this.costGrid.getColumn().get('name').setEditable(false);
    this.costGrid.getColumn().get('type').setEditable(false);
    this.costGrid.getColumn().get('counterparty').setEditable(false);
    this.costGrid.getColumn().get('costGroup').setEditable(false);
    this.costGrid.getColumn().get('costType').setEditable(false);
    this.costGrid.getColumn().get('costCurrency').setEditable(false);
    this.costGrid.getColumn().get('uom').setEditable(false);
    this.costGrid.getColumn().get('quantityOption').setEditable(false);
    this.costGrid.getColumn().get('percentageComponent').setEditable(false);
    this.costGrid.getColumn().get('description').setEditable(false);
    this.costGrid.getColumn().get('inputDate').setEditable(false);
    this.costGrid.getColumn().get('paymentType').setEditable(false);
    this.costGrid.getColumn().get('paymentTerm').setEditable(false);
    this.costGrid.getColumn().get('paymentDueDate').setEditable(false);
    this.costGrid.getColumn().get('percentagePayable').setEditable(false);
    this.costGrid.getColumn().get('taxApplicable').setEditable(false);
    this.costGrid.getColumn().get('additionalNote').setEditable(false);

    this.costGrid.gridFetchUrlType='LOCAL';
    let premiumDiscountPostFunction = function (value:any) {
      let premiumdiscount = parseFloat(value['premiumDiscount']);
      if(premiumdiscount > 0) {
        return 'Premium';
      } else {
        return 'Discount';
      }
    }
    this.qualitySpecGrid = tradeComponent.getQualitySpecification(false,false).getGrid();
    this.qualitySpecGrid.addColumn(new Tcolumn('plannedObligationId', 'Planned Obligation Id', 'L', 4, false, undefined, true,'','B',[],'text',1,undefined,undefined,undefined,actionPlannedObligations));
    this.qualitySpecGrid.addColumn(new Tcolumn('tradeId', 'Trade Id', 'LB', 5, false, undefined, true));
    this.qualitySpecGrid.addColumn(new Tcolumn('claimedBasis', 'Unload Basis', 'N', 10, true, undefined, true));
    this.qualitySpecGrid.addColumn(new Tcolumn('premiumDiscount', 'Premium/Discount', 'N', 10, true, undefined, true,new defaultValue(0,undefined,undefined,undefined,false,true,false,undefined,undefined,false,undefined,false,true,premiumDiscountPostFunction)));
    this.qualitySpecGrid.addColumn(new Tcolumn('settlementType', 'Settlement Type', 'L', 11, true, undefined, true));
    this.qualitySpecGrid.getColumn().set('actualizedQualityId', new Tcolumn('actualizedQualityId', 'Actualized Quality Id', 'LB', 6, false, undefined, false, ''));
    this.qualitySpecGrid.getColumn().set('actualizedStatus', new Tcolumn('actualizedStatus', 'Actualized Status', 'LB', 7, false, undefined, true, new defaultValue('Estimate', undefined, undefined, undefined, false, false, false, qualitystatusFunction)));
    this.qualitySpecGrid.openSidebarOnUniqueClick = false;
    this.qualitySpecGrid.addNewRowInline = false;
    this.qualitySpecGrid.showCheckbox = true;
    this.qualitySpecGrid.primaryKeyClickable = false;
    this.qualitySpecGrid.addClickable('tradeId');
    this.qualitySpecGrid.setGridConfiguration(false,false,false,true,false);
    this.qualitySpecGrid.getColumn().get('name').setEditable(false);
    this.qualitySpecGrid.getColumn().get('description').setEditable(false);
    this.qualitySpecGrid.getColumn().get('min').setEditable(false);
    this.qualitySpecGrid.getColumn().get('max').setEditable(false);
    this.qualitySpecGrid.getColumn().get('typical').setEditable(false);
    this.qualitySpecGrid.getColumn().get('unit').setEditable(false);
    this.qualitySpecGrid.getColumn().get('basis').setColumnType('N')
    this.qualitySpecGrid.gridFetchUrlType='LOCAL';
    this.qualitySpecGrid.actionFunc.newCopyValueFunc = function (value:any) {
      value['actualizedQualityId'] = '';
      value['uuid'] = '';
      return value;
    }
    this.qualityEditableFunctionField = (rowData:any,field:string) =>  {
      let invoiceGenerated = rowData['finalInvoicedQuantity'];
      let unit = rowData['unit'];
      if(field === 'basis' || field === 'premiumDiscount') {
        return invoiceGenerated;
      } else if(field === 'claimedBasis') {
        return !invoiceGenerated;
      } else if(field === 'settlementType' && invoiceGenerated) {
        return unit !== '%';
      }
      return false;
    }
    this.loadCostAndQualitySpecData();

    this.attachmentGrid = tradeComponent.getAttachment().getGrid();
    this.attachmentGrid.setSaveUrl(environment.base_url + '/api/actualization/v1/saveDocument?tenantId=' +this.commonService.getFromStorage('tenantId'));
    this.attachmentGrid.setDataURL('');
    this.attachmentGrid.setGridConfiguration(true,true,false,true);
    this.attachmentGrid.showCheckbox = false;
    let updateAttachmentName = function updatename(value: any = []) {
      let file = value[0];
      return file['name'];
    };
    let updateAttachmentType = function updatetype(value: any = []) {
      let file = value[0];
      return file['type'];
    };

    let updateFileSize = function updatetype(value: any = []) {
      let file: File = value[0];
      return _this.commonService.fileSizeConvert(file.size, true);
    };
    let attachmentName= function (value:any[] =[]) {
      return value[1] + '_' + value[0];
    };

    let date = function (value:any) {
      return _this.commonService.getFormattedDateTime(value[0],'yyyy-MM-ddTHH:mm:ss');
    }


    let action: Action = new Action();
    action.addFieldToUpdate("attachmentFileName", '', updateAttachmentName, undefined, undefined, false);
    action.addFieldToUpdate("attachmentFileType", '', updateAttachmentType, undefined, undefined, false);
    // action.addFieldToUpdate("fileSize", '', updateFileSize, undefined, undefined, false);

    this.attachmentGrid.getColumn().set('actualizationId', new Tcolumn('actualizationId', 'Actualization Id', 'LB', 20, false, undefined, false, ''));
    this.attachmentGrid.addColumn(new Tcolumn('plannedObligationId', 'Planned Obligation Id', 'L', 2, true, undefined, true,'','B',[Validators.required],'text',1,undefined,undefined,undefined,actionPlannedObligations));
    this.attachmentGrid.addColumn(new Tcolumn('shipmentDocument', 'Document Name', 'T', 3, true, undefined, true,'','B',[],'text',1,undefined,undefined,undefined,undefined));
    this.attachmentGrid.addColumn(new Tcolumn('tradeId', 'Trade Id', 'LB', 4, true, undefined, true));
    this.attachmentGrid.getColumn().set('attachment', new Tcolumn('attachment', 'Attachment', 'F', 5, true, undefined, true, new defaultValue(undefined, undefined, undefined, undefined, undefined, undefined, undefined, attachmentName, ['plannedObligationId', 'shipmentDocument']), 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, action, {
          downloadUrl: {
            url: environment.base_url + '/actualizedDocument/download?tenantId='+this.commonService.getFromStorage('tenantId')+
                '&attachmentName=',
            para: ['attachmentFileName']
          }
        }
    ));
    this.attachmentGrid.getColumn().set('uploadDate', new Tcolumn('uploadDate', 'Upload Date', 'T', 4, true, undefined, true, new defaultValue(undefined, undefined, undefined, undefined, undefined, undefined, undefined, date,undefined), 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.attachmentGrid.getColumn().set('attachmentFileName', new Tcolumn('attachmentFileName', 'File Name', 'LB', 6, false, undefined, false, '', 'G'));
    this.attachmentGrid.getColumn().set('attachmentFileType', new Tcolumn('attachmentFileType', 'File Type', 'LB', 7, false, undefined, false, '', 'G'));
    this.attachmentGrid.addNewRowInline = true;
    this.attachmentGrid.addClickable('tradeId');
    this.attachmentGrid.primaryKeyClickable = false;
    this.attachmentGrid.getColumn().get('uploadedBy').setEditable(true);
    //this.attachmentGrid.getColumn().get('fileSize').setEditable(true);
    this.attachmentGrid.actionFunc.newCopyValueFunc = function (value:any) {
      value['actualizationId'] = '';
      value['uuid'] = '';
      return value;
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['vesselId'] && this.vesselPop) {
      this.loadPlannedObligationForVessel();
      this.loadVesselInfo();
    }
    if(changes['planId'] && (!this.vesselPop)) {
      this.loadPlannedObligationForPlan();
      this.loadPlanInfo();
    }
  }


  private createQuantityGrid() {
    let index = 0;
    let _this = this;
    let df=this.quantityRoundOff;
    let statusFunction = function (value:any[]) {
      let html: any;
      if (value[0] === 'Pending') {
        html = _this.commonService.getSafeHtml('<div style="display: inline-flex;align-items: center">' +
            '<i class="fas fa-clock" style="color:#bf0000; padding-top: 3px"></i>'+
            '<p style="color:#bf0000; padding: 0;font-size: 14px;margin: 0 0 0 10px;white-space: nowrap !important;">'+value[0] +'</p></div>');
      } else {
        html = _this.commonService.getSafeHtml('<div style="display: inline-flex;align-items: center">' +
            '<i class="fas fa-check-circle" style="color:#000000; padding-top: 3px"></i>'+
            '<p style="color:#000000;padding: 0;font-size: 14px;margin: 0 0 0 10px;white-space: nowrap !important;">'+value[0]+'</p></div>');
      }
      return html;
    }

    let quantityFunction = function (value:any[]) {
      return _this.commonService.formatNumberWithoutComma(value[0],df)+' '+value[1];
    }
    let actualQuantityFunction= function(value:any[]){
      return _this.commonService.formatNumberWithoutComma(value[0],df);
    }

    let internalPackage = function (value:any[]) {
      if(value[0] === 'Unit') {
        return _this.commonService.formatNumberWithoutComma(value[1],df)+' of '+value[2]+' '+value[3]+' Bags';
      }
      return '';
    }

    let externalPackage = function (value:any[]) {
      if(value[0] === 'Container') {
        return _this.commonService.formatNumberWithoutComma(value[1],df)+' of '+value[2]+' '+value[3]+' Bags';
      }
      return '';
    }

    let postTextFunctionActualQuantity = function (value:any) {
      return value['uom'];
    }

    let actualExternalUnit = function (value:any) {
      if(value['packageType'] === 'Container') {
        return 'of ' + value['packageCapacity'] + ' ' + value['packageUom'] + ' Bags'
      }
      return '';
    }

    let actualInternalUnit = function (value:any) {
      if(value['packageType'] === 'Unit') {
        return 'of ' + value['packageCapacity'] + ' ' + value['packageUom'] + ' Bags'
      }
      return '';
    }

    let disbaleFunctionInternal = function (value:any) {
        return (value['plannedInternalPackage'] === null || value['plannedInternalPackage'] === undefined)
    }

    let disbaleFunctionExternal = function (value:any) {
      return (value['plannedExternalPackage'] === null || value['plannedExternalPackage'] === undefined)
    }
    let plannedObligationIdValue = function (value:any[]) {
      if(value[1] !== 0) {
        return value[0]+' ('+value[1]+')'
      }
      return value[0];
    }

        let columns: Map<string, Tcolumn> = new Map();
        columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', index++, false, undefined, false, '', 'N'));
        columns.set('status', new Tcolumn('status', 'Status', 'LB', index++, false, undefined, true, new defaultValue('', undefined, undefined, undefined, false, true, false, statusFunction), 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        columns.set('tradeId', new Tcolumn('tradeId', 'Trade Id', 'LB', index++, false, undefined, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined));
        columns.set('plannedObligationId', new Tcolumn('plannedObligationId', 'Trade Obligation ID', 'LB', index++, false, undefined, true, new defaultValue('', undefined, undefined, undefined, false, true, false, plannedObligationIdValue, ['plannedObligationId', 'splitSequenceNumber']), 'G', [], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        columns.set('plannedQuantity', new Tcolumn('plannedQuantity', '# Planned Quantity', 'LB', index++, false, undefined, true, new defaultValue('', undefined, undefined, undefined, false, true, false, quantityFunction, ['plannedQuantity', 'uom']), 'B', [], undefined, undefined, undefined, undefined, undefined));
        columns.set('actualQuantity', new Tcolumn('actualQuantity', '# Actual Quantity', 'N', index++, true, undefined, true, new defaultValue('', undefined, undefined, undefined, false, true, false, actualQuantityFunction, ['actualQuantity'], false, undefined, true, true, postTextFunctionActualQuantity), 'B', [], undefined, undefined, undefined, undefined, undefined));
        columns.set('dischargeDate', new Tcolumn('dischargeDate', 'Discharge Date', 'D', index++, true, undefined, true, undefined, 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: !(this.isActualizeButtonDisable && !this.isClaimButtonDisable)}}));
        columns.set('plannedInternalPackage', new Tcolumn('plannedInternalPackage', '# Planned Internal Package', 'LB', index++, false, undefined, true, new defaultValue('', undefined, undefined, undefined, false, true, false, internalPackage, ['packageType', 'noOfUnits', 'packageCapacity', 'packageUom']), 'B', []));
        columns.set('actualInternalPackage', new Tcolumn('actualInternalPackage', '# Actual Internal Package', 'N', index++, true, undefined, true, new defaultValue('', undefined, undefined, undefined, false, true, false, undefined, undefined, false, '', false, true, actualInternalUnit), 'B', [], 'text', 1, undefined, undefined, undefined, undefined, undefined, false, disbaleFunctionInternal, false));
        columns.set('plannedExternalPackage', new Tcolumn('plannedExternalPackage', '# Planned External Package', 'LB', index++, false, undefined, true, new defaultValue('', undefined, undefined, undefined, false, true, false, externalPackage, ['packageType', 'noOfUnits', 'packageCapacity', 'packageUom']), 'B', []));
        columns.set('actualExternalPackage', new Tcolumn('actualExternalPackage', '# Actual External Package', 'N', index++, true, undefined, true, new defaultValue('', undefined, undefined, undefined, false, true, false, undefined, undefined, false, '', false, true, actualExternalUnit), 'B', [], 'text', 1, undefined, undefined, undefined, undefined, undefined, false, disbaleFunctionExternal, false));
        columns.set('planId', new Tcolumn('planId', 'Plan ID', 'LB', index++, false, undefined, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined));
        columns.get('actualQuantity').roundOffTo=this.commonService.getFromTenantConfig(3,"roundingFormat","quantityRounding");
        this.quantityGrid = new Grid(columns, true, true, true, true, '', '/api/manualPricingService/v1/getPriceAllocatedByTradeId?tradeId=', '', 'Price Allocated', '', '', undefined, undefined, false);
        this.quantityGrid.setGridConfiguration(false, false, false, false, false, false, false, false, false, false);
        this.quantityGrid.showCheckbox = false;
        this.quantityGrid.gridFetchUrlType='LOCAL';
    }

  private createBillOfLandingGrid() {
    let index = 0;
    let df=this.quantityRoundOff;
    let _this = this;
    let quantityFunction = function (value:any[]) {
      return _this.commonService.formatNumberWithoutComma(value[0],df)+' '+value[1];
    }
    let plannedObligationIdValue = function (value:any[]) {
      if(value[1] !== 0) {
        return value[0]+' ('+value[1]+')'
      }
      return value[0];
    }
    let columns: Map<string, Tcolumn> = new Map();
    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', index++, false, undefined, false, '', 'N'));
    columns.set('plannedObligationId', new Tcolumn('plannedObligationId', 'Planned Obligation ID', 'LB', index++, false, undefined, true, new defaultValue('', undefined, undefined, undefined, false, true, false, plannedObligationIdValue, ['plannedObligationId', 'splitSequenceNumber']), 'B', [], undefined, undefined, undefined, undefined, undefined));
    columns.set('blDate', new Tcolumn('blDate', 'BL Date', 'D', index++, false, undefined, true, undefined, 'B', [], undefined, undefined, undefined, new Date(), undefined, undefined, {meta: {disabled: true}}));
    columns.set('blNumber', new Tcolumn('blNumber', 'BL Number', 'LB', index++, false, undefined, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined));
    columns.set('stowageNo', new Tcolumn('stowageNo', 'Stowage #', 'LB', index++, true, undefined, true, '', 'B', [],'text',undefined,undefined,undefined,undefined));
    columns.set('quantity', new Tcolumn('quantity', 'Quantity', 'LB', index++, false, undefined, true, new defaultValue('',undefined,undefined,undefined,false,true,false,quantityFunction,['actualQuantity','uom']), 'G', [], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('shipOnboard', new Tcolumn('shipOnboard', 'Ship-Onboard', 'D', index++, false, undefined, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined));
    columns.set('contractRef', new Tcolumn('contractRef', 'Contract Ref. No.', 'LB', index++, true, undefined, true, undefined, 'B', [],'text',1,undefined,undefined,undefined,undefined,undefined,false,undefined,false));
    columns.set('shipper', new Tcolumn('shipper', 'Shipper', 'LB', index++, true, undefined, true, undefined, 'B', [], undefined, undefined, undefined, undefined, undefined));
    columns.set('consignee', new Tcolumn('consignee', 'Consignee', 'LB', index++, false, undefined, true, undefined, 'B', [], undefined, undefined, undefined, undefined, undefined));
    columns.set('notifyParty', new Tcolumn('notifyParty', 'Notify Party', 'T', index++, false, undefined, true, undefined, 'B', []));
    columns.set('remarks', new Tcolumn('remarks', 'Remarks', 'LB', index++, true, undefined, true, undefined, 'B', [],'text',1,undefined,undefined,undefined,undefined,undefined,false,undefined,false));
    columns.set('forwardingAgent', new Tcolumn('forwardingAgent', 'Forwarding Agent', 'T', index++, true, undefined, true, undefined, 'B', [],'text',1,undefined,undefined,undefined,undefined,undefined,false,undefined,false));
    columns.set('selfToOrder', new Tcolumn('selfToOrder', 'Self/To Order', 'LB', index++, false, undefined, true, undefined, 'B', []));
    columns.set('status', new Tcolumn('status', 'Status', 'CUSTOM', index++, true, undefined, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined));
    this.billOfLandingGrid = new Grid(columns, true, true, true, true, '', '', '', 'Bill of Lading', '', '', undefined, undefined, false);
    this.billOfLandingGrid.setGridConfiguration(false, false, false, false, false, false, false, false, false, false);
    this.billOfLandingGrid.showCheckbox = true;
    this.billOfLandingGrid.setWidth("100%")
    this.billOfLandingGrid.isHaveExtraButtons = true;
    this.billOfLandingGrid.contextMenuConfig.functionFile = billOfLadingFunctions;
    this.billOfLandingGrid.contextMenuConfig.triggerEvent = '';
    this.billOfLandingGrid.contextMenuConfig.nameFieldId = 'plannedObligationId';
    this.billOfLandingGrid.contextMenuConfig.referenceIdField = 'plannedObligationId';
    this.billOfLandingGrid.contextMenuConfig.screenName = 'Bill of Lading';
    this.billOfLandingGrid.gridFetchUrlType='LOCAL';
    this.billOfLandingGrid.actionFunc.canEditFunction = function (value) {
      if (value !== undefined && value !== null && value['status'] !== 'Draft') {
        return false;
      }
      return true;
    };
  }

  private getRowForQuantityTab(plannedObligation:any,splitRow) {
    let obj = {};
    obj['planId'] = plannedObligation['planId'];
    obj['toleranceValue'] = plannedObligation['toleranceValue'];
    obj['settlementToleranceValue'] = plannedObligation['settlementToleranceValue'];
    obj['quantityToleranceType'] = plannedObligation['quantityToleranceType'];
    obj['plannedObligationId'] = plannedObligation['plannedObligationId'];
    obj['dischargeDate'] = plannedObligation['dischargeDate'];
    obj['tradeId'] = plannedObligation['tradeId'];
    obj['uom'] = plannedObligation['quantityUOM'];
    obj['plannedQuantity'] = plannedObligation['plannedQuantity'];
    obj['packageType'] = plannedObligation['packageType'];
    obj['packageCapacity'] = plannedObligation['packageCapacity'];
    obj['packageUom'] = plannedObligation['packageUom'];
    obj['noOfUnits'] = plannedObligation['noOfUnits'];
    obj['finalInvoiced'] = plannedObligation['finalInvoiced'];
    obj['contractTerm'] = plannedObligation['contractTerm'];
    if(plannedObligation['obligationState']['ACTUALIZED'] && (plannedObligation['actualizedQuantity'] > 0 || (splitRow !== null && splitRow !== undefined))) {
      obj['status'] = 'Actualized';
      this.isSplitDisable = true;
      this.isUndoSplitQuantityButtonDisable = true;
      if(splitRow !== null && splitRow !== undefined) {
        obj['isClaimed'] = !(splitRow['claimedQuantity'] === null || splitRow['claimedQuantity'] === undefined || splitRow['claimedQuantity'] === 0);
        obj['claimedQuantity'] = splitRow['claimedQuantity'];
        obj['originalActualQuantity'] = plannedObligation['tradeTransactionType'] === 'BUY'?splitRow['actualizedQuantityLoad'] : splitRow['actualizedQuantityUnload'];
        obj['actualQuantity'] = (obj['isClaimed']?obj['claimedQuantity']:splitRow['loadQuantity']);
        obj['splitSequenceNumber'] = splitRow['splitSequenceNumber'];
        obj['finalInvoiced'] = splitRow['finalInvoiced'];
        obj['dischargeDate'] = splitRow['dischargeDate'];
        if(plannedObligation['packageType'] === 'Container') {
          obj['actualExternalPackage'] = splitRow['externalPackageUnit'];
          obj['internalPackage'] = plannedObligation['packageName'];
          obj['plannedExternalPackage'] = plannedObligation['noOfUnits'];
        } else if(plannedObligation['packageType'] === 'Unit') {
          obj['actualInternalPackage'] = splitRow['internalPackageUnit'];
          obj['externalPackage'] = plannedObligation['packageName'];
          obj['plannedInternalPackage'] = plannedObligation['noOfUnits'];
        }
      } else {
        obj['actualQuantity'] = plannedObligation['actualizedQuantity'];
        obj['originalActualQuantity'] = plannedObligation['actualizedQuantity'] +'';
        if(plannedObligation['packageType'] === 'Container') {
          obj['actualExternalPackage'] = plannedObligation['actualizedPackageUnit'];
          obj['internalPackage'] = plannedObligation['packageName'];
          obj['plannedExternalPackage'] = plannedObligation['noOfUnits'];
        } else if(plannedObligation['packageType'] === 'Unit') {
          obj['actualInternalPackage'] = plannedObligation['actualizedPackageUnit'];
          obj['externalPackage'] = plannedObligation['packageName'];
          obj['plannedInternalPackage'] = plannedObligation['noOfUnits'];
        }
      }
    } else {
      obj['status'] = 'Pending';
      obj['actualQuantity'] = (plannedObligation['splitQuantity'] !== null && plannedObligation['splitQuantity'] !== undefined && plannedObligation['splitQuantity'] !==0)?plannedObligation['splitQuantity']:plannedObligation['plannedQuantity'];
      obj['splitQuantity'] = this.commonService.formatNumberWithoutComma(obj['actualQuantity'],this.quantityRoundOff);
      obj['splitSequenceNumber'] = plannedObligation['splitSequenceNumber'];
      if(plannedObligation['packageType'] === 'Container') {
        obj['actualExternalPackage'] = plannedObligation['noOfUnits'];
        obj['internalPackage'] = plannedObligation['packageName'];
        obj['plannedExternalPackage'] = plannedObligation['noOfUnits'];
      } else if(plannedObligation['packageType'] === 'Unit') {
        obj['actualInternalPackage'] = plannedObligation['noOfUnits'];
        obj['externalPackage'] = plannedObligation['packageName'];
        obj['plannedInternalPackage'] = plannedObligation['noOfUnits'];
      }
    }
    return obj;
  }

  onFormCancel() {
    this.onClose.emit();
  }

  onTabChange(tab: any) {
    this.selectedTab = tab['name'];
    this.loadValues();
  }

  private loadValues() {
    this.displayBottomPane = false;
    if(this.selectedTab === 'Cost') {
      this.canClose = true;
      if(this.costValue === null || this.costValue === undefined || this.costValue.length == 0) {
        this.getCost();
      }
      if(this.selectedCost.length > 0) {
        this.displayBottomPane = true;
        this.isActualizeButtonDisable = false;
        this.actualizeButtonHeader = "Actualize Cost";
      }
      this.updateCostDisableIndex(this.costValue);
    } else if(this.selectedTab === 'Document Upload') {
      this.canClose = true;
      this.getAttachements();
    }else if(this.selectedTab === 'Bill of Lading') {
      this.canClose = false;
      this.displayBottomPane = (this.expandedBlrowIndex !== -1);
      this.actualizeButtonHeader = "Save BL";
      this.isActualizeButtonDisable = false;
    }  else if(this.selectedTab === 'Quality') {
      this.canClose = true;
      this.getQualitySpecs();
      if(this.selectedQuality.length > 0) {
        this.displayBottomPane = true;
        this.isActualizeButtonDisable = false;
        this.actualizeButtonHeader = "Actualize Quality";
      }
    } else {
      this.canClose = false;
      if(this.selectedPlannedObligations.length > 0) {
        this.displayBottomPane = true;
        this.isActualizeButtonDisable = false;
        this.actualizeButtonHeader = "Actualize Quantity";
        this.isActualizeButtonDisable = (this.quantityValues.filter(e => { return (e['status'] === 'Actualized')}).length > 0);
        this.isClaimButtonDisable = (this.quantityValues.filter(e => { return ( !e['finalInvoiced'])}).length > 0);
        this.updateDisableIndex(this.quantityValues);
      }
    }
  }

  getIcon(tab: any) {
    if(tab['icon'] === null || tab['icon'] === undefined) {
      return 'fas fa-star';
    }
    return tab['icon']
  }

  calculateGridHeight(divbox: HTMLDivElement) {
    if(divbox !== null && divbox !== undefined) {
        this.box = divbox;
        let viewportHeight = window.innerHeight;
        let offsetTop  = divbox.offsetTop;
        this.gridHeight = viewportHeight - offsetTop - 200;
    }
  }

  filterLibrary() {

  }

  refreshLibrary() {
    this.refreshData(false)
    this.onSelectionChangeAll(this.allselected);
    this.buyValue = true;
    this.sellValue = true;
  }

  calcaulateListHeight() {
    let el = document.getElementById('list');
    if(el !== null && el !== undefined)  {
      let viewportHeight = window.innerHeight;
      let offsetTop = el['offsetTop'];
      let height = viewportHeight - offsetTop - 150;
      el.style.maxHeight = height + 'px';
      el.style.minHeight = height + 'px';
    }
  }

  private loadPlannedObligationForVessel() {
    this.refreshCardLibrary = true;
    this.qualityValue = [];
    this.costValue = [];
    this.hiddenCard = [];
    this.qualityValue = [];
    this.plannedObligations = [];
    this.selectedPlannedObligations = [];
    if(this.vesselId !== null && this.vesselId !== undefined && this.vesselId.length > 0 && this.planId !== null && this.planId !== undefined && this.planId.length > 0) {
      let _this = this;
      this.commonService.getJSONByURL(environment.base_url+'/api/transportallocationv2/v1/get-allocated-sea-freight-planned-obligation-for-transport-V2?tenantId='+ this.commonService.getFromStorage('tenantId')+'&vesselId='+this.vesselId+"&planId="+this.planId).subscribe((next:any) => {
        _this.plannedObligations = next;
        _this.filterDataTradeId = _this.masterCommonService.getListFromArray(_this.commonService.converttoLabelValue(next, 'tradeId'), false, false, 'tradeId', 'tradeId');
        _this.filterDataCommodity = _this.masterCommonService.getListFromArray(_this.commonService.converttoLabelValue(next, 'commodity'), false, false, 'commodity', 'commodity');
        _this.filterDataLoadPort = _this.masterCommonService.getListFromArray(_this.commonService.converttoLabelValue(next, 'loadLocation'), false, false, 'loadLocation', 'loadLocation');
        _this.filterDataUnloadPort = _this.masterCommonService.getListFromArray(_this.commonService.converttoLabelValue(next, 'unloadLocation'), false, false, 'unloadLocation', 'unloadLocation');
        _this.selectedPlannedObligations = _this.handleMode([..._this.plannedObligations]);
        _this.createRowsAndSort(_this.selectedPlannedObligations, true, true, false);
        _this.refreshCardLibrary = false;
      },(error:HttpErrorResponse) => {

      })
    }
  }

    private loadPlannedObligationForPlan() {
        this.refreshCardLibrary = true;
        this.qualityValue = [];
        this.costValue = [];
        this.hiddenCard = [];
        this.qualityValue = [];
        this.plannedObligations = [];
        if (this.planId !== null && this.planId !== undefined && this.planId.length > 0) {
            let _this = this;
            this.commonService.getJSONByURL(environment.base_url + '/api/plannedObligation/v1/getplannedobligationforactaulization?tenantId=' + this.commonService.getFromStorage('tenantId') + '&planId=' + this.planId).subscribe((next: any) => {
                _this.plannedObligations = next;
                _this.filterDataTradeId = _this.masterCommonService.getListFromArray(_this.commonService.converttoLabelValue(next, 'tradeId'), false, false, 'tradeId', 'tradeId');
                _this.filterDataCommodity = _this.masterCommonService.getListFromArray(_this.commonService.converttoLabelValue(next, 'commodity'), false, false, 'commodity', 'commodity');
                _this.filterDataLoadPort = _this.masterCommonService.getListFromArray(_this.commonService.converttoLabelValue(next, 'loadLocation'), false, false, 'loadLocation', 'loadLocation');
                _this.filterDataUnloadPort = _this.masterCommonService.getListFromArray(_this.commonService.converttoLabelValue(next, 'unloadLocation'), false, false, 'unloadLocation', 'unloadLocation');
                _this.selectedPlannedObligations = _this.handleMode([..._this.plannedObligations]);
                _this.createRowsAndSort(_this.selectedPlannedObligations, true, true, false);
                _this.refreshCardLibrary = false;
            }, (error: HttpErrorResponse) => {

            });
        }
    }

    onSelectionChangeAll(value: any) {
        this.allselected = !this.allselected;
        this.selectedPlannedObligations = [];
        if (this.allselected) {
            this.selectedPlannedObligations = [...this.plannedObligations];
            this.isallselected = true;
        }
        this.createRowsAndSort(this.selectedPlannedObligations);
    }

    onunselect(value:any){
        this.buyValue = false;
    }

    onCheckboxChange(buyValue:boolean,sellValue:boolean) {
        let buyList=[];
        let sellList =[];
        this.allselected = buyValue && sellValue
        this.selectedPlannedObligations = [];
        if(buyValue){
            buyList = this.plannedObligations.filter(e=>e.tradeTransactionType=='BUY')
        }
        if(sellValue){
            sellList = this.plannedObligations.filter(e=>e.tradeTransactionType=='SELL')
        }
        this.selectedPlannedObligations = Object.assign([],buyList).concat(sellList);
        this.createRowsAndSort(this.selectedPlannedObligations);

    }


  onSelectPlannedCard(obligation: any) {
    this.billOfLandingValues = [];
    this.surrenderValues = [];
    this.isUndoSplitQuantityButtonDisable = true;
    this.selectedPlannedObligations.push(obligation);
    if(this.selectedPlannedObligations.length === this.plannedObligations.length) {
      this.allselected = true;
      this.isallselected = true;
    }
    this.createRowsAndSort(this.selectedPlannedObligations);
  }

  onUnselectPlannedObligation(obligation:any) {
    this.billOfLandingValues = [];
    this.isUndoSplitQuantityButtonDisable = true;
    this.selectedPlannedObligations.splice(this.selectedPlannedObligations.indexOf(obligation),1);
    this.allselected = false;
    this.createRowsAndSort(this.selectedPlannedObligations);
  }

    private passedFilters(plannedObligation: any) {
        if (this.planIds.length > 0) {
            return this.planIds.includes(plannedObligation['planId']);
        }
        if (this.plannedObligationsIds.length > 0) {
            return this.plannedObligationsIds.includes(plannedObligation['plannedObligationId']);
        }
        if (this.commodity.length > 0) {
            return this.commodity.includes(plannedObligation['commodity']);
        }
        return true;
    }

  addBLRowForObligation(plannedObligation,addBlRows:boolean = true) {
    let _this = this;
    if(plannedObligation['blSplitRows'] !== null && plannedObligation['blSplitRows'] !== undefined && plannedObligation['blSplitRows'].length > 0) {
      if(addBlRows) {
        plannedObligation['blSplitRows'].forEach(function (splitRow:any,i:number) {
          _this.billOfLandingValues.push(splitRow);
        })
      }
    } else {
      let obj = {};
      obj['blDate'] = "";
      obj['shipOnboard'] = "";
      obj['blNumber'] = "";
      obj['selfToOrder'] = "";
      if(plannedObligation['tradeTransactionType'] === 'SELL') {
        obj['shipper'] = plannedObligation['company'];
        obj['consignee'] = plannedObligation['counterparty'];
      } else {
        obj['shipper'] = "";
        obj['consignee'] = "";
      }
      obj['contractRef'] = plannedObligation['tradeId'];
      obj['remarks'] = "";
      obj['splitSequenceNumber'] = 1;
      obj['notifyParty'] = [];
      obj['forwardingAgent'] = [];
      obj['actualizationId'] = '';
      obj['plannedObligationId'] = plannedObligation['plannedObligationId'];
      obj['surrendered'] = false;
      obj['replacementBl'] = false;
      obj['actualQuantity'] = _this.commonService.formatNumberWithoutComma(plannedObligation['plannedQuantity'],this.quantityRoundOff);
      obj['uom'] = plannedObligation['quantityUOM'];
      this.billOfLandingValues.push(obj);

    }
    this.expandTableRow();
  }


  addBL() {
    this.billOfLandingValues = [];
    this.surrenderValues = [];
    let _this = this;
    this.selectedPlannedObligations.forEach(function (plannedObligation:any) {
      _this.addBLRowForObligation(plannedObligation);
      if(plannedObligation['blSplitSurrenderedRows'] !== null && plannedObligation['blSplitSurrenderedRows'] !== undefined && plannedObligation['blSplitSurrenderedRows'].length > 0) {
        plannedObligation['blSplitSurrenderedRows'].forEach(function (splitRow:any) {
          _this.surrenderValues.push(splitRow);
        })
      }
    });
    this.actualizeButtonHeader = "Save BL"
    this.selectedTab = "Bill of Lading";
    this.displayBottomPane = false;
    this.isActualizeButtonDisable = false;
  }

    createRowsAndSort(list: any[], resetGridFilters: boolean = true, updateButtonState: boolean = true, restBLValues: boolean = true) {
        let _this = this;
        this.quantityValues = [];
        this.tradeIds = {};
        this.unclaimDisable = true;
        this.surrenderValues = [];
        if (restBLValues) {
            this.billOfLandingValues = [];
        }
        if (list.length > 0) {
            this.displayBottomPane = true;
            this.isActualizeButtonDisable = false;
            let plannedObligationList: any[] = [];
            plannedObligationList.push(new KeyValue());
            let planIds: any[] = [];
            let plannedObligationsIds: any[] = [];
            let commodityList: any[] = [];
            if (resetGridFilters) {
                this.resetFilters();
            } else {
                this.disableCardList = [];
                this.plannedObligations.forEach(function(data) {
                    if (!_this.passedFilters(data)) {
                        _this.disableCardList.push(data['plannedObligationId']);
                    }
                });
            }
            if (updateButtonState) {
                _this.isSplitDisable = false;
                _this.isUndoSplitQuantityButtonDisable = true;
            }
            list.forEach(function(rowData, index) {
                if (_this.passedFilters(rowData)) {
                    if (_this.splitedPlannedObligationId !== null
                        && _this.splitedPlannedObligationId !== undefined && _this.splitedPlannedObligationId === rowData['plannedObligationId']) {
                        _this.splitRows.forEach(function(splitRow: any) {
                            let row = _this.getRowForQuantityTab(splitRow, null);
                            _this.quantityValues.push(row);
                        });
                    } else {
                        if (rowData['actualizeQuantityRows'] !== null && rowData['actualizeQuantityRows'] !== undefined && rowData['actualizeQuantityRows'].length > 0) {
                            rowData['actualizeQuantityRows'].forEach(function(splitRow: any) {
                                let row = _this.getRowForQuantityTab(rowData, splitRow);
                                _this.quantityValues.push(row);
                            });
                        } else {
                            let row = _this.getRowForQuantityTab(rowData, null);
                            _this.quantityValues.push(row);
                        }
                    }
                    if (rowData['blSplitRows'] !== null && rowData['blSplitRows'] !== undefined && rowData['blSplitRows'].length > 0) {
                        rowData['blSplitRows'].forEach(function(splitRow: any, i: number) {
                            splitRow['tradeDateTime'] = rowData['tradeDateTime'];
                            _this.billOfLandingValues.push(splitRow);
                        });
                        _this.expandTableRow();
                    }
                    if (rowData['blSplitSurrenderedRows'] !== null && rowData['blSplitSurrenderedRows'] !== undefined && rowData['blSplitSurrenderedRows'].length > 0) {
                        rowData['blSplitSurrenderedRows'].forEach(function(splitRow: any) {
                            _this.surrenderValues.push(splitRow);
                        });
                    }
                    _this.tradeIds[rowData['plannedObligationId']] = rowData['tradeId'];
                    plannedObligationList.push(new KeyValue(rowData['plannedObligationId'], rowData['plannedObligationId']));
                    if (!planIds.includes(rowData['planId'])) {
                        planIds.push(rowData['planId']);
                    }
                    if (!plannedObligationsIds.includes(rowData['plannedObligationId'])) {
                        plannedObligationsIds.push(rowData['plannedObligationId']);
                    }
                    if (!commodityList.includes(rowData['commodity'])) {
                        commodityList.push(rowData['commodity']);
                    }
                }
            });
            this.quantityValues.sort(function(a, b) {
                return a['planId'] > b['planId'] ? 1 : a['planId'] < b['planId'] ? -1 : 0;
            });
            this.updateDisableIndex(this.quantityValues);
            this.unclaimDisable = true;
            if(this.quantityValues.length > 0) {
                this.unclaimDisable = !(this.quantityValues[0]['claimedQuantity'] !== null && this.quantityValues[0]['claimedQuantity'] !== undefined && this.quantityValues[0]['claimedQuantity'] !== 0);
            }
            if (resetGridFilters) {
                this.gridFilters = [];
                this.gridFilters.push({
                    title: 'PlannedObligation Id',
                    list: this.masterCommonService.getListFromArray(plannedObligationsIds, false, false)
                });
                this.gridFilters.push({title: 'Commodity', list: this.masterCommonService.getListFromArray(commodityList, false, false)});
            }
            this.costGrid.getColumn().get('plannedObligationId').setListofValues(plannedObligationList);
            this.qualitySpecGrid.getColumn().get('plannedObligationId').setListofValues(plannedObligationList);
            this.attachmentGrid.getColumn().get('plannedObligationId').setListofValues(plannedObligationList);
            this.loadValues();

    } else {
      this.displayBottomPane = false;
      this.attachmentValues = [];
      this.costValue = [];
      this.unclaimDisable = true;
      this.resetFilters();
      this.qualityValue = [];
    }
        this.quantityGrid.getColumn().get('dischargeDate').setExtra({meta: {disabled: !(this.isActualizeButtonDisable && !this.isClaimButtonDisable)}});
  }

  private createBillOfLandingFormGroup(rowData:any,splitRow) {
    if(splitRow !== null && splitRow !== undefined) {
      rowData['blDate'] = splitRow['blDate'];
      rowData['blNumber'] = splitRow['blNumber'];
      rowData['selfToOrder'] = splitRow['selfToOrder'];
      rowData['shipper'] = splitRow['shipper'];
      rowData['consignee'] = splitRow['consignee'];
      rowData['notifyParty'] = splitRow['notifyParty'];
      rowData['forwardingAgent'] = splitRow['forwardingAgent'];
      rowData['contractRef'] = splitRow['contractRef'];
    } else {
      rowData['blDate'] = '';
      rowData['blNumber'] = '';
      rowData['selfToOrder'] = '';
      rowData['shipper'] = '';
      rowData['consignee'] = '';
      rowData['notifyParty'] = [];
      rowData['forwardingAgent'] = [];
      rowData['contractRef'] = '';
    }
    rowData['forwardingAgentTemp'] = '';
    rowData['notifyPartyTemp'] = '';
    rowData['showNotifyParty'] = false;
    rowData['showForwardingAgent'] = false;
  }


  getCost() {
    this.refreshingCost = true;
    this.costValue = [];
    this.selectedCost = [];
    let payload = {};
    let _this = this;
    payload['plannedObligationIds']  = Object.keys(this.tradeIds);
    this.commonService.post(environment.base_url + '/api/actualization/v1/getcostfortransportactulization?tenantId='+ this.commonService.getTenantId(),payload).subscribe((data: any) => {
      _this.costValue = data;
      _this.costDisabledRows = [];
      this.updateCostDisableIndex(_this.costValue);
      this.refreshingCost = false;
    });
  }

  private isClaimed(row:any,key:string) {
    return (row[key] !== null && row[key] !== undefined && row[key] !== 0);
  }
  delete(value) {
    let rows = value['selectedRows'];
    let allDocument = [];
    allDocument = rows.map(i => i.uuid);
    this.commonService.post(environment.base_url + "/api/actualization/v1/delete-actualized-documents",allDocument).subscribe((data: any) => {
          this.showToast("Document Deleted Successfully");
          this.popupDisplay = false;
          this.refreshGrid = true;
        },
        err => {
          this.showToast("Document cannot be Deleted", 'error');
          this.popupDisplay = false;
          this.refreshGrid = true;
        })
  }
  getAttachements() {
    this.refreshingAttachment = true;
    this.attachmentValues = [];
    let payload = {};
    payload['plannedObligationIds']  = Object.keys(this.tradeIds);
    this.commonService.post(environment.base_url + '/api/actualization/v1/getallattacheddocfortransportactualization?tenantId='+ this.commonService.getTenantId(),payload).subscribe((data: any) => {
      this.attachmentValues = data;
      this.refreshingAttachment = false;
    });
  }

  getQualitySpecs() {
    this.refreshingQualitySpec = true;
    this.qualityValue = [];
    this.selectedQuality = [];
    this.displayBottomPane = false;
    let payload = {};
    let _this = this;
    payload['plannedObligationIds']  = Object.keys(this.tradeIds);
    this.commonService.post(environment.base_url + '/api/actualization/v1/getqualityfortransportactualization?tenantId='+ this.commonService.getTenantId(),payload).subscribe((data: any) => {
      _this.qualityValue = data;
      _this.qualityDisabledRows = [];
      _this.qualityValue.forEach(function (row:any,index) {
        row['originalBasis'] = parseFloat(row['claimedBasis'] + '');
        row['originalSettlementType'] = row['settlementType'] + '';
        if(_this.isClaimed(row,'claimedPremiumDiscount')) {
          row['premiumDiscount'] = row['claimedPremiumDiscount'];
        }
        if((row['actualizedStatus'] === 'Actualized') && !row['finalInvoicedQuantity']) {
          _this.qualityDisabledRows.push(index);
        }
      });
      _this.refreshingQualitySpec = false;
    });
  }

  onSaveCost(rows:any[]) {
    let url = environment.base_url + '/api/actualization/v1/actualizecostfortranportactualization?tenantId=' + this.commonService.getTenantId();
    let payloadObj = {
      costs: rows
    };
    this.commonService.post(url, payloadObj).subscribe(next => {
      this.showToast('Cost Actualization Successful');
      this.isActualizeButtonDisable = false;
      this.getCost();
    }, (error: HttpErrorResponse) => {
      this.showToast(this.commonService.getHttpErrorMessage(error,'Transport Actualization'), 'error');
      this.isActualizeButtonDisable = false;
    });
  }

  showToast(msg, severity: string = 'success',heading:string = '') {
    this.messageService.add({
      detail: heading,
      severity: severity,
      summary: msg
    });
  }

  updateSelectedRows(rows: any[], screen: string) {
    if (screen === 'cost') {
      this.selectedCost = rows;
      if(rows.length > 0) {
        this.displayBottomPane = true;
        this.isActualizeButtonDisable = false;
        this.actualizeButtonHeader = "Actualize Cost";
        let quantityActualizedAll:boolean = true;
        let finalInvoiceDoneAll:boolean = true;
        this.selectedCost.forEach(function (obj:any) {
          if(!obj['finalInvoiced']) {
            finalInvoiceDoneAll = false;
          }
          if((obj['actualizedStatus'] === null || obj['actualizedStatus'] === undefined || obj['actualizedStatus'] !== 'Actualized') || !obj['quantityActualized']) {
            quantityActualizedAll = false;
          }
        });
        this.isActualizeButtonDisable = quantityActualizedAll;
        this.isClaimButtonDisable = !finalInvoiceDoneAll;
      } else {
        this.displayBottomPane = false;
      }
    } else if (screen === 'qualitySpec') {
      this.selectedQuality = rows;
      if(rows.length > 0) {
        this.displayBottomPane = true;
        this.isActualizeButtonDisable = false;
        this.actualizeButtonHeader = "Actualize Quality";
        let qualityContracterAll:boolean = false;
        let finalInvoiceQuantityDoneAll:boolean = true;
        let finalInvoiceQualityDoneAll:boolean = true;
        this.selectedQuality.forEach(function (obj:any) {
          if(!obj['finalInvoicedQuantity']) {
            finalInvoiceQuantityDoneAll = false;
          }
          if(!obj['finalInvoicedQuality']) {
            finalInvoiceQualityDoneAll = false;
          }
          if(obj['actualizedStatus'].toLowerCase() === 'contracted') {
            qualityContracterAll = true;
          }
        });
        this.isActualizeButtonDisable = !qualityContracterAll;
        this.isClaimButtonDisable = !finalInvoiceQuantityDoneAll;
        // this.isClaimButtonDisable = true;
      } else {
        this.displayBottomPane = false;
      }
    }
  }

  onGetRowData(value: any) {

  }

  onButtonClick(value: any) {
    if (value['screenName'] === 'Attachment') {
      let headers = new HttpHeaders();
      let name = value['rowData']['attachmentFileName'];
      let url = environment.base_url +  value['rowData']['attachmentUrl'];
      if (value['buttonHeader'] === 'Download') {
        this.commonService.downloadFile(url,name);
      } else if (value['buttonHeader'] === 'Print') {
        let printurl = 'https://docs.google.com/viewerng/viewer?url=' + url;
        window.open(printurl, '_blank');
      } else {
        // this.docUrl = url;
        // this.docName = name;
        // this.showDoc = true;
      }
    }
  }

  onRefreshComplete() {
    this.refreshingAttachment = false;
  }

  private loadCostAndQualitySpecData() {
    let _this = this;
    _this.commonService.getLoadData('commodity', ['data','data','data','data','data','data','data','data','data','config','config','config'],
        [[{key: 'name', value: 'cost_detail_name_ind'}],
          [{key: 'name', value: 'cost_detail_type_ind'}],
          [{key: 'name', value: 'cost_group_ind'}],
          [{key: 'name', value: 'cost_type_ind'}],
          [{key: 'name', value: 'quantity_option_ind'}],
          [{key: 'name', value: 'percentage_component_ind'}],
          [{key: 'name', value: 'cost_payment_type_ind'}],
          [{key: 'name', value: 'quality_parameter_ind'}],[{key:'name',value:'qualitysettlementtype_grp_ind'}],'counterparty','paymentterm','unitofmeasurement']).subscribe((next: any) => {
      _this.costGrid.getColumn().get('name').setListofValues(_this.masterCommonService.getListFromArray(next['cost_detail_name_ind']));
      _this.costGrid.getColumn().get('type').setListofValues(_this.masterCommonService.getListFromArray(next['cost_detail_type_ind']));
      _this.costGrid.getColumn().get('costGroup').setListofValues(_this.masterCommonService.getListFromArray(next['cost_group_ind']));
      _this.costGrid.getColumn().get('costType').setListofValues(_this.masterCommonService.getListFromArray(next['cost_type_ind']));
      _this.costGrid.getColumn().get('quantityOption').setListofValues(_this.masterCommonService.getListFromArray(next['quantity_option_ind']));
      _this.costGrid.getColumn().get('percentageComponent').setListofValues(_this.masterCommonService.getListFromArray(next['percentage_component_ind']));
      _this.costGrid.getColumn().get('paymentType').setListofValues(_this.masterCommonService.getListFromArray(next['cost_payment_type_ind']));
      _this.costGrid.getColumn().get('counterparty').setListofValues(_this.masterCommonService.getListFromArray(next['counterparty']));
      _this.costGrid.getColumn().get('paymentTerm').setListofValues(_this.masterCommonService.getListFromArray(next['paymentterm']));
      _this.costGrid.getColumn().get('uom').setListofValues(_this.masterCommonService.getListFromArray(next['unitofmeasurement']));
      _this.qualitySpecGrid.getColumn().get('name').setListofValues(_this.masterCommonService.getListFromArray(next['quality_parameter_ind']));
      _this.qualitySpecGrid.getColumn().get('settlementType').setListofValues(_this.masterCommonService.getListFromArray(next['qualitysettlementtype_grp_ind']));
      _this.qualitySpecGrid.getColumn().get('settlementType').setDefaultValue('NO/Lumpsum');
    });
  }

  onCloseBottomPane() {
    this.displayBottomPane = false;
    if(this.selectedTab === 'Cost') {
      this.selectedCost = [];
    } else if(this.selectedTab === 'Quality') {
      this.selectedQuality = [];
    } else if(this.selectedTab === 'Bill of Lading') {
      this.selectedbilloflanding = [];
    }
  }

  onSaveAttachment(value: any,fromContext:boolean = false) {
    this.uploading = true;
    this.commonService.post(environment.base_url + '/api/actualization/v1/saveDocument?tenantId=' +this.commonService.getFromStorage('tenantId'), value['data']).subscribe(next => {
      this.showToast('Document uploaded Successful');
      this.uploading = false;
      if(fromContext) {
        this.showUploadForm = false;
        this.fileSelected = false;
        this.uploadFileName = '';
        this.uploadObject = {};
        if(this.isConfirmBL) {
          this.finalizeBL(this.checkedBlValues[0]);
        }
      }
      this.getAttachements();
    }, (error: HttpErrorResponse) => {
      this.uploading = false;
      this.showToast(this.commonService.getHttpErrorMessage(error,'Transport Actualization'), 'error');
    });
  }

  onSaveQualitySpec(rows: any[]) {
    let payloadObj = {
      qualitySpecs: rows
    };
    this.commonService.post(environment.base_url + '/api/actualization/v1/actualizequalityfortransportactualization?tenantId=' +this.commonService.getFromStorage('tenantId'), payloadObj).subscribe(next => {
      this.showToast('Quality Spec actualized successfully.');
      this.isActualizeButtonDisable = false;
      this.getQualitySpecs();
    }, (error: HttpErrorResponse) => {
      this.showToast(this.commonService.getHttpErrorMessage(error,'Transport Actualization'), 'error');
      this.isActualizeButtonDisable = false;
    });
  }

  actualize(i:number) {
    this.isActualizeButtonDisable = true;
    if(this.selectedTab === 'Quantity') {
      this.actualizeQuantity();
    } else if(this.selectedTab === 'Cost') {
      this.onSaveCost(this.selectedCost);
    } else if(this.selectedTab === 'Bill of Lading') {
      this.saveBL(i);
    } else if(this.selectedTab === 'Quality') {
      this.onSaveQualitySpec(this.selectedQuality);
    }
  }

  getBottomVisible() {
    if(this.selectedTab === 'Cost' && this.selectedCost.length > 0) {
      return true;
    } else if(this.selectedTab === 'Quality' && this.selectedQuality.length > 0) {
      return true;
    }  else if(this.selectedTab === 'Quantity' && this.selectedPlannedObligations.length > 0) {
      return true;
    }
    return false;
  }

  onCloseTradeInfo(value: any) {
    this.displayTradeInfo = false;
  }

  onClickUniqueKey(value: any) {
    if(!value['editMode']) {
      if(value['column']['field'] === 'tradeId') {
        this.rowData = value['rowData'];
        this.displayTradeInfo = true;
      }
    }
  }

    private resetFilters() {
        this.planIds = [];
        this.plannedObligationsIds = [];
        this.disableCardList = [];
        this.commodity = [];
        this.gridFilters = [];
        this.gridFilters.push({title: 'PlannedObligation Id', list: []});
        this.gridFilters.push({title: 'Commodity', list: []});

    }

    onFilterChange(value: any) {
        if (value['title'] === 'PlannedObligation Id') {
            this.plannedObligationsIds = value['selected'];
        } else if (value['title'] === 'Commodity') {
            this.commodity = value['selected'];
        }
        this.createRowsAndSort(this.selectedPlannedObligations, false);
    }

  private refreshData(loadPlanInfo:boolean = true) {
    this.splitedPlannedObligationId = '';
    this.splitRows = [];
    this.billOfLandingValues = [];
    this.checkedBlValues = [];
    this.surrenderValues = [];
    this.selectedbilloflanding = [];
    this.allselected = true;
    this.isallselected = true;
    if(this.vesselId !== null && this.vesselId !== undefined && this.vesselId.length > 0 && this.vesselPop) {
      this.loadPlannedObligationForVessel();
      if(loadPlanInfo) {
        this.loadVesselInfo();
      }
    } else if(this.planId !== null && this.planId !== undefined && this.planId.length > 0) {
      this.loadPlannedObligationForPlan();
      if(loadPlanInfo) {
        this.loadPlanInfo();
      }
    }
  }



  private actualizeQuantity() {
    let _this = this;
    if(this.checkPass(this.quantityValues)) {
      this.onContinueActualization();
    }
  }

  checkPass(quantityValues: any[],claim:boolean = false,fosfa:boolean = false) {
    this.errorMessage = [];
    this.warningMessage = [];
    this.warningFOSFA = false;
    this.warningClaim = false;
    let toleranceField = "toleranceValue";
    if(claim) {
      toleranceField = "settlementToleranceValue"
    }
    let checkDoneList = [];
    quantityValues.forEach(item => {
      if(!checkDoneList.includes(item.plannedObligationId)) {
        checkDoneList.push(item.plannedObligationId);
        let tolerance = JSON.parse(item[toleranceField]);
        if(tolerance !== null && tolerance !== undefined) {
          let toleranceType = item['quantityToleranceType'];
          let sumQuantity = 0;
          if(quantityValues.length > 1) {
            quantityValues.forEach(item1 => {
              if(item1.plannedObligationId === item.plannedObligationId)
                sumQuantity += parseFloat(item1['actualQuantity']);
            })
          }
          let actualQuantity = (sumQuantity != 0)?sumQuantity:item['actualQuantity'];
          let plannedQuantity = parseFloat(item['plannedQuantity']);
          let min = tolerance['min'];
          let max = tolerance['max'];
          let minimumTolerance = 0;
          let maximumTolerance = 0;
          if (toleranceType === 'weight') {
            minimumTolerance = (plannedQuantity - min);
            maximumTolerance = (plannedQuantity + max);
          } else {
            minimumTolerance = (plannedQuantity - ((min / 100) * plannedQuantity));
            maximumTolerance = (plannedQuantity + ((max / 100) * plannedQuantity));
          }
          minimumTolerance = parseFloat(this.commonService.formatNumberWithoutComma(minimumTolerance,this.quantityRoundOff));
          maximumTolerance = parseFloat(this.commonService.formatNumberWithoutComma(maximumTolerance,this.quantityRoundOff));

          if (!(actualQuantity >= minimumTolerance && actualQuantity <= maximumTolerance)) {
            if (actualQuantity < minimumTolerance) {
              this.warningMessage.push((claim?'Claim':'Actual')+' Quantity is below from minimum tolerance (Minimum Tolerance : ' + minimumTolerance + ').');
              item['tolerance'] = minimumTolerance;
            } else if (actualQuantity > maximumTolerance) {
              this.warningMessage.push((claim?'Claim':'Actual')+' Quantity is above from maximum tolerance (Maximum Tolerance : ' + maximumTolerance + ').');
              item['tolerance'] = maximumTolerance;
            }
            this.warningClaim = claim;
            this.warningFOSFA = fosfa;
          }
        }
      }
    })
    if(this.warningMessage.length > 0 || this.errorMessage.length > 0) {
      this.warningBoxVisible = true;
      return false;
    }
    return true;
  }

  onFocusOutSearchBox(value: FocusEvent) {
    this.hiddenCard = [];
    this.searchValue = value.target['value'];
    this.selectedPlannedObligations = [];
    this.quantityValues = [];
    this.costValue = [];
    this.qualityValue = [];
    if(this.searchValue.length === 0) {
      this.allselected = true;
      if(this.allselected) {
        this.selectedPlannedObligations = [...this.plannedObligations];
      }
      this.createRowsAndSort(this.selectedPlannedObligations);
    }
  }

  onNotFoundInSearch(value: any) {
    this.hiddenCard.push(value['plannedObligationId']);
  }

  onFoundInSearch(value: any) {
    this.onSelectPlannedCard(value);
  }

    addNewInList(rowData: any,inputfield,rowDatafield,resetValue:boolean = true) {
      if(rowData[inputfield] !== null && rowData[inputfield] !== undefined && rowData[inputfield].length > 0) {
        if(rowData[rowDatafield] === null || rowData[rowDatafield] === undefined) {
          rowData[rowDatafield] = [rowData[inputfield]];
        } else {
          if(!rowData[rowDatafield].includes(rowData[inputfield])) {
            rowData[rowDatafield].push(rowData[inputfield]);
          }
        }
       }
      if(resetValue) {
        rowData[inputfield] = '';
      }
    }

  getListCount(rowData: any, fieldName: string) {
    return (rowData[fieldName] !== null && rowData[fieldName] !== undefined)?rowData[fieldName].length:0;
  }

  removeFromList(rowData: any, key: string, item: any,togglevariable) {
    if(rowData[key] !== null && rowData[key] !== undefined) {
      rowData[key].splice(rowData[key].indexOf(item),1);
      if(rowData[key].length === 0) {
        rowData[togglevariable] = false;
      }
    }
  }

  toggleVisiblity(rowData: any, listKey: any, key: any, div: string) {
    rowData[key] = false;
    if(rowData[listKey] !== null && rowData[listKey] !== undefined && rowData[listKey].length > 0) {
      rowData[key] = true;
      document.getElementById(div).focus();
    }
  }

  hideVisiblity(rowData: any,key: string) {
    rowData[key] = false;
  }

  splitQuantity() {
    this.splitDisplay = true;
  }

  undoSplit() {
    this.billOfLandingValues = [];
    this.splitedPlannedObligationId = '';
    this.splitRows = [];
    this.isUndoSplitQuantityButtonDisable = true;
    this.isSplitDisable = false;
    this.selectedPlannedObligations[0]['blSplitRows'] = [];
    this.createRowsAndSort(this.selectedPlannedObligations);
  }

  private saveBL(i:number) {
    this.isActualizeButtonDisable = true;
    if(this.expandedBlrow[i] !== null && this.expandedBlrow[i] !== undefined) {
      let _this = this;
      let payload = this.expandedBlrow[i];
      payload['blDate'] = this.commonService.convertDatetoUTC(payload['blDate'],true)
      payload['shipOnboard'] = this.commonService.convertDatetoUTC(payload['shipOnboard'],true)
      this.commonService.post(environment.base_url+'/api/actualization/v1/saveblinfo?tenantId='+this.commonService.getTenantId(),payload).subscribe((next:any) => {
        _this.table.expandedRowKeys = {};
        let savedRow = next;
        let copied:any[] = Object.assign([],_this.billOfLandingValues);
        _this.billOfLandingValues = [];
        copied.splice(i,1);
        copied.splice(i,0,savedRow);
        _this.billOfLandingValues = copied;
        _this.expandedBlrow[i] = null;
        _this.displayBottomPane = false;
        _this.isActualizeButtonDisable = false;
        _this.expandedBlrowIndex = -1;
        _this.showToast('BL Info Saved Successfully','success');
        _this.table.selectionKeys = {};
        _this.checkedBlValues = [];
        _this.selectedbilloflanding = [];
        this.refreshData(true);
      },(error:HttpErrorResponse) => {
        _this.showToast(_this.commonService.getHttpErrorMessage(error,'Bill of Lading'),'error');
        _this.isActualizeButtonDisable = false;
      })
    }
  }

  onSplitDisplayClose() {
    this.splitDisplay = false;
  }

  split() {
    let _this = this;
    let obligation  = this.selectedPlannedObligations[0];
    let payload = {};
    payload['plannedObligationId'] = obligation['plannedObligationId'];
    payload['noOfRows'] = this.splitInValue;
    this.splitButtonDisable = true;
    this.commonService.post(environment.base_url+'/api/transportactualization/v1/getplannedobligationsplitrows?tenantId='+this.commonService.getTenantId(),payload).subscribe((next:any[]) => {
      _this.splitRows = next['plannedObligationSplitRows'];
      obligation['blSplitRows'] = next['blSplitRows'];
      _this.splitedPlannedObligationId = obligation['plannedObligationId'];
      _this.splitButtonDisable = false;
      _this.splitDisplay = false;
      _this.isSplitDisable = true;
      _this.isUndoSplitQuantityButtonDisable = false;
      _this.addBlButtonDisable = false;
      _this.createRowsAndSort(_this.selectedPlannedObligations,false);
    },(error:HttpErrorResponse) => {
      _this.showToast(_this.commonService.getHttpErrorMessage(error,'Transport Actualization'),'error');
      _this.splitButtonDisable = false;
    });
  }

    private expand(value, index: number) {
        if (value['data']['blDate'] !== null && value['data']['blDate'] !== undefined && value['data']['blDate'].length > 0) {
            this.expandedBlrow[index]['blDate'] = moment(value['data']['blDate']).utc(false).toDate();
        } else {
            this.expandedBlrow[index]['blDate'] = '';
        }
        if (value['data']['shipOnboard'] !== null && value['data']['shipOnboard'] !== undefined && value['data']['shipOnboard'].length > 0) {
            this.expandedBlrow[index]['shipOnboard'] = new Date(value['data']['shipOnboard']);
        } else {
            this.expandedBlrow[index]['shipOnboard'] = '';
        }
        this.expandedBlrowIndex = value['data']['rowNum'];
        // this.displayBottomPane = true;
        this.canClose = false;
        this.collpaseOther();
    }

  onExpandRow(value: any) {
    this.canClose = true;
    let event = value['event'];
    if(event === 'Expand') {
      this.expandedBlrow[value['data']['rowNum']-1] = JSON.parse(JSON.stringify(value['data']));
      if(this.expandedBlrowIndex !== value['data']['rowNum']) {
          this.expand(value,value['data']['rowNum'] -1 );
      } else {
        this.expandedBlrowIndex = -1;
        this.canClose = true;
        // this.displayBottomPane = false;
        this.collpaseOther();
      }
    } else {
      this.canClose = true;
      this.expandedBlrowIndex = -1;
      // this.displayBottomPane = false;
    }
  }

  private collpaseOther() {
    if(this.table !== null && this.table !== undefined && this.expandedBlrowIndex !== null
        && this.expandedBlrowIndex !== undefined && this.expandedBlrowIndex !== -1) {
      let obj = {};
      obj[this.expandedBlrowIndex] = true;
      this.table.expandedRowKeys = obj;
    }
  }

  onTableCreated(table: Table) {
    this.table = table;
  }

  deleteRow(rowData: any,index:number) {
    let copyValues:any[] = [...this.quantityValues];
    this.quantityValues = [];
    copyValues.splice(index,1);
    this.quantityValues = copyValues;
    this.isActualizeButtonDisable = (this.quantityValues.filter(e => { return (e['status'] === 'Actualized')}).length > 0)
    this.isClaimButtonDisable = (this.quantityValues.filter(e => { return ( !e['finalInvoiced'])}).length > 0)
    this.billOfLandingValues = [];
  }

  onSurrenderBLDisplayClose() {
    this.surrenderBlShow = false;
  }

  surrender() {
    let _this = this;
    this.commonService.post(environment.base_url+'/api/actualization/v1/surrenderbl?tenantId='+this.commonService.getTenantId(),this.checkedBlValues).subscribe((next:any) => {
      _this.surrenderBlShow = false;
      _this.showToast('Bl Surrendered Successfully');
      _this.reloadBlRows();
    },(error:HttpErrorResponse) => {
      _this.showToast(_this.commonService.getHttpErrorMessage(error,'Actualization'),'error');
    })
  }

  reloadBlRows(updateQuantityTabValue:boolean = false) {
    let _this = this;
    let plannedObligationList:any[] = [];
    this.selectedPlannedObligations.forEach(function (obligation:any) {
      plannedObligationList.push(obligation['plannedObligationId']);
    });
    this.commonService.post(environment.base_url+'/api/actualization/v1/getblrowlistbyplannedobligationid?tenantId='+this.commonService.getTenantId(),plannedObligationList).subscribe((next:any) => {
      _this.surrenderValues = next['surrenderRows'];
      _this.billOfLandingValues = next['blRows'];
      _this.selectedbilloflanding = [];
    },(error:HttpErrorResponse) => {
      _this.showToast(_this.commonService.getHttpErrorMessage(error,'Actualization'),'error');
    })
  }

  onMergeDisplayClose() {
    this.showMergeDisplay = false;
  }

  merge() {
    let _this = this;
    this.billOfLandingValues = [];
    this.commonService.post(environment.base_url+'/api/actualization/v1/merge?tenantId='+this.commonService.getTenantId()+'&mergeCount='+this.mergeInValue,this.checkedBlValues).subscribe((next) => {
      _this.showMergeDisplay = false;
      _this.checkedBlValues = [];
      _this.selectedbilloflanding = [];
      _this.isMergeBlDisabled = true;
      _this.isSwitchBlDisabled = true;
      _this.reloadBlRows(true);
    },(error:HttpErrorResponse) => {
      _this.showToast(_this.commonService.getHttpErrorMessage(error,'Actualization'),'error');
    });
  }

  onRowSelectedBL(value: any[]) {
    this.checkedBlValues = value;
    if(value !== null && value !== undefined && value.length > 0) {
      let _this = this;
      this.displayBottomPane = true;
      this.isSwitchBlDisabled = false;
      this.isMergeBlDisabled = false;
      this.isConfirmBlDisabled = false;
      value.forEach(function (row:any) {
        if(row['blNumber'] === null || row['blNumber'] === undefined || row['blNumber'].length === 0) {
          _this.isSwitchBlDisabled = true
          _this.isConfirmBlDisabled = true
          _this.isMergeBlDisabled = true;
        }
        if(row['status'] !== null && row['status'] !== undefined && row['status'] !== 'Draft') {
          _this.isConfirmBlDisabled = true;
        }
      })
      if(value.length > 1) {
        _this.isConfirmBlDisabled = true;
      }
      if(value.length < 2) {
        _this.isMergeBlDisabled = true;
      }
      if(!_this.isConfirmBlDisabled) {
        if(_this.checkedBlValues[0]['tradeTransactionType'] === 'BUY') {
          _this.isConfirmBlDisabled = true;
        } else{
          this.confirmedBLRow = _this.checkedBlValues[0];
        }
      }
    } else {
      this.displayBottomPane = false;
    }
  }

  mergeBL() {
    this.showMergeDisplay = true;

  }

  switchBl() {
    this.surrenderBlShow = true;
  }

  switchMode(value: any) {
    this.billOfLandingGrid.showCheckbox = !value['checked'];
    this.billOfLandingGrid.isHaveExtraButtons = !value['checked'];;
  }

  claim() {

    if(this.selectedTab === 'Quantity') {
      this.claimQuantity(this.quantityValues);
    } else if(this.selectedTab === 'Cost') {
      this.claimCost(this.selectedCost);
    } else if(this.selectedTab === 'Quality') {
      if(this.canClaims(this.selectedQuality)) {
        this.claimQuality(this.selectedQuality);
      } else {
        let _this = this;
        this.confirmationService.confirm({
          message: "No Change in Delivered Quality Specification, nothing to Claim. Click OK to Proceed",
          acceptLabel:'OK',
          rejectLabel:'Cancel',
          accept: () => {
            this.updateSelectedRows([],'qualitySpec')
          },
          reject: () => {
          }
        });
      }
    }
  }

  canClaims(rows:any[]) {
    let unchangedRows:any[] = [];
    rows.forEach(function (row:any,index:number) {
      if (row['claimedBasis'] === row['originalBasis'] && row['settlementType'] === row['originalSettlementType']) {
        unchangedRows.push(index);
      }
    });
    return unchangedRows.length == 0;

  }

  claimCost(rows:any[]) {
    this.isClaimButtonDisable = true;
    let _this = this;
    let url = environment.base_url + '/api/actualization/v1/claimcostfortransportactualization?tenantId=' + this.commonService.getTenantId();
    let payloadObj = {
      costs: rows
    };
    this.commonService.post(url, payloadObj).subscribe(next => {
      this.showToast('Cost Claimed Successfully.');
      this.isClaimButtonDisable = false;
      _this.displayBottomPane = false;
      this.getCost();
    }, (error: HttpErrorResponse) => {
      this.showToast(this.commonService.getHttpErrorMessage(error,'Transport Actualization'), 'error');
      this.isClaimButtonDisable = false;
    });
  }

  claimQuality(rows:any[]) {
    this.isClaimButtonDisable = true;
    let _this = this;
    let url = environment.base_url + '/api/actualization/v1/claimquality?tenantId=' + this.commonService.getTenantId();
    let payloadObj = {
      qualitySpecs: rows
    };
    this.commonService.post(url, payloadObj).subscribe(next => {
      this.showToast('Quality Claimed Successfully.');
      this.isClaimButtonDisable = false;
      _this.displayBottomPane = false;
      this.getQualitySpecs();
    }, (error: HttpErrorResponse) => {
      this.showToast(this.commonService.getHttpErrorMessage(error,'Transport Actualization'), 'error');
      this.isClaimButtonDisable = false;
    });
  }

  private claimQuantity(rows: any[]) {
    this.isClaimButtonDisable = true;
    let allObligations = rows.map(item => item.plannedObligationId).filter((value, index, array) => array.indexOf(value) === index);
    let row = rows[0];
    let contractTerm = row['contractTerm'];
    if(allObligations.length > 1 || (contractTerm === null || contractTerm === undefined || contractTerm.length === 0)) {
      this.continueClaim(rows);
      return;
    }
    let fosfaList = ['FOSFA 80','FOSFA 81','FOSFA 54']
    if(this.checkPass(rows,true,fosfaList.includes(contractTerm.toUpperCase()))) {
      this.continueClaim(rows)
    }
  }

  continueClaim(rows: any[],toleranceBreached:boolean = false) {
    let _this = this;
    this.warningBoxVisible = false;
    this.showSettlementPrice = false;
    let url = environment.base_url + '/api/actualization/v1/claimquantityfortransportactualization?tenantId=' + this.commonService.getTenantId();
    rows = rows.map(item => {
      item['settlementPrice'] = this.settlementPrice;
      item['toleranceBreached'] = toleranceBreached;
      return item;
    });
    let payloadObj = {
      quantityRows: rows
    };
    this.commonService.post(url, payloadObj).subscribe(next => {
      this.showToast('Quantity Claimed Successfully.');
      this.isClaimButtonDisable = false;
      _this.displayBottomPane = false;
      this.refreshData(false);
    }, (error: HttpErrorResponse) => {
      this.showToast(this.commonService.getHttpErrorMessage(error,'Transport Actualization'), 'error');
      this.isClaimButtonDisable = false;
    });
  }

  getCostGrid() {
    let _this = this;
    let functionPercentageComponentList = function (value: any[] = []) {
      let list: KeyValue[] = [new KeyValue('Select', '')];
      if (value[1] === 'percentageMultiple') {
        list = value[0];
      }
      list.splice(1, 0, new KeyValue('Final CF Value', 'finalCFValue'));
      list.splice(2, 0, new KeyValue('Provisional CF Value', 'provisionalCFValue'));
      return list;
    };
    let postObjFunction = function (value: any[] = []) {
      let obj = {
        entityName: 'Cost',
        criteria: [{key: 'tradeId', operation: 'equal', value: value[0]}],
        sortBy: ['name']
      };
      return obj;
    };
    let functionCostValueValidators = function (value: any[] = []) {
      let validators: Validators[] = [Validators.required];
      if (value[0] === 'percentageSimple' || value[0] === 'percentageMultiple') {
        validators.push(Validators.min(-100));
        validators.push(Validators.max(100));
      } else {
        validators.push(ctrmValidators.decimalLengthValidator(10, 2));
      }
      return validators;
    };

    let functionQuantityOptionList = function (value: any[] = []) {
      let quantityOption = '';
      if (value[0] === 'percentageSimple') {
        quantityOption = 'finalCFValue';
      }
      return quantityOption;
    };
    let actionCostType = new Action();
    actionCostType.addFieldToUpdate('costValue', undefined, functionCostValueValidators, undefined, undefined, true, 'validators');
    // actionCostType.addFieldToUpdate('percentageComponent', new KeyValue('name', 'name'), functionPercentageComponentList, ['L', 'costType'], environment.base_url + '/filter', true, 'listvalues', undefined, true, undefined, undefined, postObjFunction, ['tradeId']);
    actionCostType.addFieldToUpdate('percentageComponent', undefined, functionQuantityOptionList, undefined, undefined, true, 'defaultValue');
    let actionStartDate = new Action();
    actionStartDate.addFieldToUpdate('timeBaseEndDate', undefined, undefined, undefined, undefined, true, 'minDate');

    let paymentDueDateFunc = function (value: any[]) {
      let obj = {meta: {disabled: false}};
      if (value[0].toString().toLowerCase() === 'calculated' || value[0].toString().toLowerCase() === 'relative') {
        obj = {meta: {disabled: true}};
      }
      return obj;
    };

    let paymentTermFunc = function (value: any[]) {
      let obj = {meta: {disabled: true}};
      if (value[0].toString().toLowerCase() === 'calculated' || value[0].toString().toLowerCase() === 'relative') {
        obj = {meta: {disabled: false}};
      }
      return obj;
    };
    let actionPaymentType = new Action();
    actionPaymentType.addFieldToUpdate('paymentDueDate', undefined, paymentDueDateFunc, undefined, undefined, true, 'extra');
    actionPaymentType.addFieldToUpdate('paymentTerm', undefined, paymentTermFunc, undefined, undefined, true, 'extra');
    let columns: Map<string, Tcolumn> = new Map();
    /*****************section 1 *********************/
    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    columns.set('costFor', new Tcolumn('costFor', 'Cost For', 'LB', 1, true, undefined, false, '', 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('linkTo', new Tcolumn('linkTo', 'Link To', 'LB', 1, true, undefined, false, '', 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('costId', new Tcolumn('costId', 'Cost ID', 'LB', 2, true, undefined, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('name', new Tcolumn('name', 'Name', 'LB', 3, true, undefined, true, '', 'B', [Validators.required]));
    columns.set('type', new Tcolumn('type', 'Type', 'LB', 4, true, undefined, true, '', 'B', [Validators.required]));
    columns.set('counterparty', new Tcolumn('counterparty', 'Counterparty', 'LB', 5, true, undefined, true, undefined, 'B', [Validators.required]));
    columns.set('costGroup', new Tcolumn('costGroup', 'Cost Group', 'LB', 6, true, undefined, true, '', 'B', [Validators.required]));
    columns.set('costType', new Tcolumn('costType', 'Cost Type', 'LB', 7, true, undefined,true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionCostType));
    columns.set('costValue', new Tcolumn('costValue', 'Cost Value', 'N', 8, true, undefined, true, '', 'B', [Validators.required]));
    columns.set('costChargesType',new Tcolumn('costChargesType', 'Cost/Charges', 'LB', 9, false, undefined, true,'','B',[],'text',1,undefined,undefined,undefined));
    columns.set('costCurrency', new Tcolumn('costCurrency', 'Cost Currency', 'LB', 10, true, undefined, true, '', 'B', [Validators.required]));
    columns.set('uom', new Tcolumn('uom', 'Unit of Measurement', 'LB', 11, true, undefined, true, '', 'B'));
    columns.set('quantityOption', new Tcolumn('quantityOption', 'Quantity Option', 'LB', 11, true, undefined, true, '', 'B', [Validators.required]));
    columns.set('percentageComponent', new Tcolumn('percentageComponent', 'Percentage Component', 'LB', 12, true, undefined, true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('description', new Tcolumn('description', 'Description', 'TA', 13, true, undefined, true, '', 'B', []));
    /***************section 2 *********************/
    columns.set('inputDate', new Tcolumn('inputDate', 'Input Date', 'D', 14, true, undefined, true, new Date(), 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined));
    columns.set('paymentType', new Tcolumn('paymentType', 'Payment Option Type', 'LB', 17, true, undefined, true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionPaymentType));
    columns.set('paymentTerm', new Tcolumn('paymentTerm', 'Payment Term', 'LB', 18, true, undefined, true, '', 'B', [Validators.required]));
    columns.set('paymentDueDate', new Tcolumn('paymentDueDate', 'Payment Due Date', 'D', 19, true, undefined, true, '', 'B', [Validators.required]));
    columns.set('percentagePayable', new Tcolumn('percentagePayable', 'Percentage Payable', 'N', 20, true, undefined, true, 100, 'B', [Validators.required, Validators.max(100), Validators.min(0)], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('taxApplicable', new Tcolumn('taxApplicable', 'Tax Applicable', 'B', 21, true, this.masterCommonService.getBooleanFromLabel('Yes', 'No'), false, true, 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    /************** section 3 *****************/
    columns.set('additionalNote', new Tcolumn('additionalNote', 'Additional Note', 'TA', 22, true, undefined, true, '', 'B', [], undefined, 3));
    let grid = new Grid(columns, true, true, true, true, 'costId', '/api/cost/v1/getAllCostByTradeUuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&tradeUuid=', '', 'Cost', '', '', undefined, undefined, false);
    grid.setGridConfiguration(true, true, true, false);
    grid.addSection('Cost Details', ['costFor','linkTo', 'name', 'type', 'counterparty', 'costGroup', 'costType', 'costValue', 'costCurrency', 'uom', 'quantityOption', 'percentageComponent', 'description']);
    grid.addSection('Cost Payment & Schedule Details', ['inputDate', 'paymentType', 'paymentTerm', 'paymentDueDate', 'percentagePayable']);
    grid.addSection('Others', ['additionalNote']);
    grid.setSaveUrl(environment.base_url + '/api/cost/v1/savecost?tenantId='+this.commonService.getFromStorage('tenantId'));
    grid.setUpdateUrl(environment.base_url +'/api/cost/v1/update?tenantId='+this.commonService.getFromStorage('tenantId'));
    grid.hrefUrl = environment.base_url + '/api/cost/v1/getbycostuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
    grid.importUrl = environment.base_url + '/api/cost/v1/import?tenantId='+this.commonService.getFromStorage('tenantId');
    grid.ignoreFieldListForExport = ['costId'];
    return grid;
  }

  clickNavigate(Value:any){
    this.router.navigate(['/operationsDashboard'],{queryParams:{value:Value['plannedObligationId']}});
  }

  onContextItemClick(value: any) {
    let title = value['title'];
    this.contextSelectedRow = value['data'];
    if(title === 'Upload Document') {
        this.showUploadForm = true;
    } else if(title === 'Copy BL') {
        this.copyBL();
    } else if(title === 'Generate BL Document') {
        this.isConfirmBL = true;
        this.confirmedBLRow = this.contextSelectedRow;
        this.showGenerateForm = true;
    }
  }

  uploadForm() {
    this.onSaveAttachment({data:this.uploadObject},true);
  }
  private expandTableRow(){
    if(this.table!==null && this.table!==undefined){
      let _this=this;
      this.table.expandedRowKeys={};
      let obj={};
      _this.expandedBlrow=[];
      this.billOfLandingValues.forEach((data:any,i:number)=>{
        let copyRow=Object.assign({},data);
        if(copyRow['blDate'] !== null && copyRow['blDate'] !== undefined && copyRow['blDate'].length > 0) {
          copyRow['blDate'] = new Date(copyRow['blDate']);
        } else {
          copyRow['blDate'] = '';
        }
        if(copyRow['shipOnboard'] !== null && copyRow['shipOnboard'] !== undefined && copyRow['shipOnboard'].length > 0) {
          copyRow['shipOnboard'] = new Date(copyRow['shipOnboard']);
        } else {
          copyRow['shipOnboard'] = '';
        }
        _this.expandedBlrow.push(copyRow)
        if(data['blNumber']==null || data['blNumber']==undefined || data['blNumber'].length==0 ){
         obj[i+1]=true;
        }
      })
      this.table.expandedRowKeys=obj;
    }
  }

  onFileUpload(event: Event) {
    let fileList: FileList = event['target']['files'];
    if(fileList.length > 0) {
      let _this = this;
      let reader = new FileReader();
      reader.readAsDataURL(fileList[0]);
      reader.onload = (event2) => {
        const uploadData = new FormData();
        uploadData.append('file', fileList[0], fileList[0].name);
        this.uploading = true;
        _this.commonService.getByteArray(environment.base_url + '/api/company/v1/upload/logo', uploadData).subscribe(function (data: any) {
          let bytes:any = data.response;
          _this.uploadObject['actualizationId'] = '';
          _this.uploadObject['attachment'] = bytes;
          _this.uploadObject['attachmentFileName'] = fileList[0].name;
          _this.uploadObject['attachmentFileType'] = fileList[0].type;
          _this.uploadObject['plannedObligationId'] = _this.contextSelectedRow['plannedObligationId'];
          _this.uploadObject['tenantId'] = _this.commonService.getTenantId();
          _this.uploadObject['uploadedBy'] = _this.commonService.getFromStorage('userName');
          _this.uploadObject['uploadDate'] = new Date();
          let obligation = _this.plannedObligations.find(e => e.plannedObligationId == _this.contextSelectedRow['plannedObligationId']);
          if(obligation != null && obligation !== undefined) {
            _this.uploadObject['tradeId'] = obligation['tradeId'];
          }
          _this.uploadFileName = fileList[0].name;
          _this.fileSelected = true;
          _this.uploading = false;
        },(error:HttpErrorResponse) => {
          _this.fileSelected = false;
          _this.showToast("Failed to upload file","error");
        })

      };

    }
  }

  handleMode(selectedObligations:any[]) {
    let _this = this;
    this.billOfLandingValues = [];
    let hiddenObjects:any[] =[];
    let selectedObjects:any[] =[];
    if(this.mode === 'manageBL') {
      if(this.obligationId !== null && this.obligationId !== undefined && this.obligationId.length > 0) {
        let obligationObject = selectedObligations.find(e => e['plannedObligationId'] === this.obligationId);
        hiddenObjects = [...selectedObligations.filter(e => e['plannedObligationId'] !== this.obligationId).map(e => e['plannedObligationId'])];
        if(obligationObject !== null && obligationObject !== undefined) {
          this.isBuy = obligationObject.tradeTransactionType === 'BUY';
          selectedObjects.push(obligationObject);
          _this.addBLRowForObligation(obligationObject,false);
        }
      } else {
        selectedObjects = [...selectedObligations];
      }
      _this.selectedTab = 'Bill of Lading';
    } else {
      selectedObjects = [...selectedObligations];
    }
    this.hiddenCard = Object.assign(this.hiddenCard,hiddenObjects);
    return selectedObjects;
  }

  private copyBL() {
    let _this = this;
    this.billOfLandingValues = [];
    this.refreshCardLibrary = true;
    this.displayBottomPane = false;
    this.commonService.getJSONByURL(environment.base_url + '/api/plannedObligation/v1/getcopyblrows?tenantId='+this.commonService.getTenantId()+'&plannedObligationId='+this.contextSelectedRow['plannedObligationId']+"&splitNumber="+this.contextSelectedRow['splitSequenceNumber']).subscribe((next) => {
      let hiddenObjects:string[] =[];
      let selectedObjects:any[] = [];
      _this.billOfLandingValues = Object.assign([],next);
      let obligationIds = _this.billOfLandingValues.map(e => e['plannedObligationId']);
      selectedObjects = _this.plannedObligations.filter(e => obligationIds.includes(e['plannedObligationId']));
      hiddenObjects = [..._this.plannedObligations.filter(e => !obligationIds.includes(e['plannedObligationId'])).map(e => e['plannedObligationId'])]
      _this.isBuy = !_this.isBuy;
      _this.hiddenCard = Object.assign([],hiddenObjects);
      _this.selectedPlannedObligations = selectedObjects;
      _this.table.selectionKeys = {};
      _this.checkedBlValues = [];
      _this.selectedbilloflanding = [];
      _this.refreshCardLibrary = false;
    },(error:HttpErrorResponse) => {
      _this.showToast("Failed to load copy BL.","error");
    });
  }


  confirmBL() {
    this.confirmedBLRow = this.contextSelectedRow;
    this.isConfirmBL = true;
    this.showGenerateForm = true;
  }

  private finalizeBL(row:any) {
    let _this = this;
    this.commonService.getJSONByURL(`${environment.base_url}/api/actualization/v1/confirmBL?tenantId=${this.commonService.getTenantId()}&plannedObligationId=${row['plannedObligationId']}&blNumber=${row['blNumber']}`).subscribe((next) => {
      let updatedRow = next;
      _this.isConfirmBL = false;
      _this.table.expandedRowKeys = {};
      let copied:any[] = Object.assign([],_this.billOfLandingValues);
      _this.billOfLandingValues = [];
      copied.splice(_this.expandedBlrowIndex - 1,1);
      copied.splice(_this.expandedBlrowIndex - 1,0,updatedRow);
      _this.billOfLandingValues = copied;
      _this.expandedBlrow = null;
      _this.displayBottomPane = false;
      _this.isActualizeButtonDisable = false;
      _this.expandedBlrowIndex = -1;
      _this.showToast("BL Confirmed Successfully !",'info');
      _this.table.selectionKeys = {};
      _this.checkedBlValues = [];
      _this.selectedbilloflanding = [];
    },(error:HttpErrorResponse) => {
      this.showToast(this.commonService.getHttpErrorMessage(error,'BL Rows',false));
    })
  }

  generateDoc(documentType: any) {
    this.uploading = true;
    this.commonService.getJSONByURL(environment.base_url + '/api/plannedObligation/v1/generateshipmentdocument?plannedObligationId=' + this.confirmedBLRow['plannedObligationId']+"&tenantId="+this.commonService.getFromStorage('tenantId')+'&shipmentDocument=Bill Of Lading&documentType='+documentType['value']+"&splitSequenceNumber="+this.confirmedBLRow['splitSequenceNumber']).subscribe(next => {
      this.uploading = false;
      this.showGenerateForm = false;
      this.showToast("Generated Successfully !",'info');
      if(this.isConfirmBL) {
        this.showGenerateForm = false;
        this.finalizeBL(this.confirmedBLRow);
      } else {
        this.refreshLibrary();
      }
    },(error: HttpErrorResponse) => {
      this.uploading = false;
      this.showToast(this.commonService.getHttpErrorMessage(error,'Failed To Generate Document..! '), 'error');
    });
  }

  onChangeDocType(event: any) {
    if (event.value.length > 0) {
      this.uploading = false;
    }
  }

  onContinueActualization() {
    let _this = this;
    let payload = {
      quantityRows: this.quantityValues
    };
    let selectedPlannedobligationIds= [];
    this.quantityValues.forEach(function (obj:any) {
      selectedPlannedobligationIds.push(obj['plannedObligationId']);
    });
    this.warningBoxVisible = false;
    this.commonService.post(environment.base_url+'/api/actualization/v1/checkqualityactualization?tenantId='+this.commonService.getTenantId(),selectedPlannedobligationIds).subscribe((next:any) => {
      if(next['status'] === 'Quality is Actaulized') {
        _this.commonService.post(environment.base_url+'/api/actualization/v1/actualizequantityfortransportactualization?tenantId='+this.commonService.getTenantId(),payload).subscribe((next) => {
          _this.displayBottomPane = false;
          _this.showToast("Quantity Actualized Successfully");
          _this.refreshData(true);
          _this.isActualizeButtonDisable = false;
        },(error:HttpErrorResponse) => {
          _this.showToast(_this.commonService.getHttpErrorMessage(error,"Transport Actualization"),'error');
          _this.isActualizeButtonDisable = false;
        });
      } else {
        _this.isActualizeButtonDisable = false;
        _this.confirmationService.confirm({
          message: "Quality is not actualized, system will default with contract quality press Yes to continue, No to Actualize Quality.",
          accept: () => {
            _this.isActualizeButtonDisable = true;
            _this.commonService.post(environment.base_url+'/api/actualization/v1/actualizequantityfortransportactualization?tenantId='+this.commonService.getTenantId(),payload).subscribe((next) => {
              _this.displayBottomPane = false;
              _this.showToast("Quantity Actualized Successfully");
              _this.refreshData(true);
              _this.isActualizeButtonDisable = false;
            },(error:HttpErrorResponse) => {
              _this.showToast(_this.commonService.getHttpErrorMessage(error,"Transport Actualization"),'error');
              _this.isActualizeButtonDisable = false;
            });
          },
          reject: () => {
            _this.selectedTab = 'Quality';
            _this.loadValues();
          }
        });
      }
    },(error:HttpErrorResponse) => {
      _this.showToast(_this.commonService.getHttpErrorMessage(error,"Actualization"));
      _this.isActualizeButtonDisable = false;
    })
  }

  onCloseWarning(popupClose:boolean) {
    if(this.warningClaim && !popupClose) {
      this.continueClaim(this.quantityValues)
    }
    this.warningBoxVisible = false;
    this.isActualizeButtonDisable = false;
  }
  resetFormFilter() {
    this.refreshData(true);
    this.openFilterForm = false;
    this.actualizeFilterForm.reset();
    this.searchCriteriaList = [];
  }
  onClickAway(value){
    if(value.id !='buttonFilter') {
      this.openFilterForm = false;
    }
  }

  onApplyFilter() {
    let planId = [];
    planId.push(this.planId);
    let vesselId =[];
    vesselId.push(this.vesselId);
    this.searchCriteriaList.push(new SearchCriteria('tenantId', this.commonService.getFromStorage('tenantId'), 'equals'));
    // this.searchCriteriaList.push(new SearchCriteria('planId', planId, 'equals'));
    if(this.planId!=null && this.planId!=''){
      this.searchCriteriaList.push(new SearchCriteria('planId', planId, 'equals'));
    }
    let tradeIds = this.actualizeFilterForm.getValue('tradeId');
    if (tradeIds != null && tradeIds != undefined && tradeIds.length > 0) {
      this.searchCriteriaList.push(new SearchCriteria('tradeId', tradeIds, 'in'));
    }
    let date = this.actualizeFilterForm.getValue('date');
    if (date !== null && date != undefined && date != '') {
      let dates = this.commonService.convertDatetoUTC(date);
      this.searchCriteriaList.push(new SearchCriteria('deliveryEndDate', dates, 'equalsdate'));
    }
    let loadPorts = this.actualizeFilterForm.getValue('loadPort');
    if (loadPorts != null && loadPorts != undefined && loadPorts.length > 0) {
      this.searchCriteriaList.push(new SearchCriteria('loadLocation', loadPorts, 'in'));
    }
    let unloadPorts = this.actualizeFilterForm.getValue('unloadPort');
    if (unloadPorts != null && unloadPorts != undefined && unloadPorts.length > 0) {
      this.searchCriteriaList.push(new SearchCriteria('unloadLocation', unloadPorts, 'in'));
    }

    let commoditiess = this.actualizeFilterForm.getValue('commodities');
    if (commoditiess != null && commoditiess != undefined && commoditiess.length > 0) {
      this.searchCriteriaList.push(new SearchCriteria('commodity', commoditiess, 'in'));
    }
    this.commonService.post(environment.base_url + '/api/transportallocationv2/v1/getActualizedPlanByCriteriaV2?tenantId=' + this.commonService.getFromStorage('tenantId'), this.searchCriteriaList).subscribe((data: any) => {
      let _this = this;
      _this.actualizeFilterForm.reset();
      _this.openFilterForm = false;
      _this.searchCriteriaList = [];
      _this.plannedObligations = data;
      _this.selectedPlannedObligations = _this.handleMode([..._this.plannedObligations]);
      _this.createRowsAndSort(_this.selectedPlannedObligations, true, true, false);
      _this.refreshCardLibrary = false;
      _this.getCost();
    });
  }

  onErrorUnclaim(error: HttpErrorResponse) {
    this.showToast(this.commonService.getHttpErrorMessage(error,'Claim Undo'),'error');
  }

  onUnclaimDone(resp: any) {
    this.showToast("Claim Reverted Successfully");
    this.isClaimButtonDisable = false;
    this.displayBottomPane = false;
    this.refreshData(false);
  }

  onPageChange(data: any[]) {
    this.updateDisableIndex(data);
    this.updateCostDisableIndex(data);
  }

  private updateDisableIndex(data:any[]) {
    this.quantityDisabledRows = [];
    let _this = this;
    data.forEach(function(row: any, index) {
      if ( (row['status'] === 'Actualized' && !row['finalInvoiced'])) {
        _this.quantityDisabledRows.push(index);
      }
    });
  }
  private updateCostDisableIndex(data:any[]) {
    this.costDisabledRows = [];
    let _this = this;
    data.forEach(function(row: any, index) {
      if(_this.isClaimed(row,'claimedCostValue')) {
        row['costValue'] = row['claimedCostValue']
      }
      if(_this.isClaimed(row,'claimedCostValue') || (row['actualizedStatus'] === 'Actualized' && !row['finalInvoiced'])) {
        _this.costDisabledRows.push(index);
      }
    });
  }
}
