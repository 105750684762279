<p-sidebar (onHide)="close()" [(visible)]="visible"
           [baseZIndex]="baseZindex"
           [showCloseIcon]="false" [style]="{minWidth:'60%',maxWidth:'60%',height:'100%', marginTop:'4.6%'}" position="right">
    <div class="header">
        <i (click)="close()" class="material-icons closable">clear</i>
        <div class="headerTitle">{{header}}</div>
    </div>
    <div class="content style-4" id="sectionForm" inViewport (inViewportAction)="calculateSectionHeight()" style="overflow: auto">
        <div class="formBox">
            <ng-container *ngTemplateOutlet="formContent"></ng-container>
        </div>
        <div class="validBox">
            <ng-container *ngTemplateOutlet="validations"></ng-container>
        </div>
    </div>
    <p-footer [style]="{overflow:'hidden'}">
        <div class="footerclass" id = "footer">

            <button class="mybuttonClose" (click)="close()">Cancel</button>
            <button class="planScreenBtns" (click)="Save()" style="width: 100px !important;"
                    [disabled]="!formGroup.valid || saveButtonDisabled">{{saveButtonLabel}}
            </button>
            <button *ngFor="let extrabutton of extraButtons"
                    class="planScreenBtns"
                    [disabled]="!formGroup.valid"
                    style="margin-left: 0"
                    (click)="onClickExtButton(extrabutton)">{{extrabutton}}</button>
        </div>
    </p-footer>
</p-sidebar>
<ng-template #validations>
    <div class="validation">
        <div *ngFor="let key of getKeys()" class="section bottomGap">
            <div (click)="goToField(key,key)" class="sectionTitle">
                <i [ngClass]="getIconFromValidations(formGroup,this.validationSections[key])"></i>
                <p class="{{focusedSection === key?'focused':''}}">{{key}}</p>
            </div>
            <div>
                <div *ngFor="let field of this.validationSections[key]"
                     class="leftShift">
                    <div *ngIf="field['grid'] && hasErrors(field)" class="section">
                        <div *ngFor="let error of getKeysFromObject(getErrors(field))" class="sectionTitle">
                            <i class="fas fa-angle-right iconClassValidation"></i>
                            <p [ngClass]="currentlyEditingField(field)">{{commonService.getValidationErrorMessage(formGroup.controls[field['field']]['controls'][error], getHeader(field,error))}}</p>
                        </div>
                    </div>
                    <div *ngIf="(field['grid'] === null || field['grid'] === undefined || !field['grid']) && !isHidden(field)" class="section">
                        <div *ngIf="field['field'] !== '' " class="sectionTitle">
                            <i class="fas fa-angle-right iconClassValidation"></i>
                            <p (click)="goToField(field,key)"
                               [ngClass]="currentlyEditingField(field)">{{commonService.getValidationErrorMessage(formGroup.controls[field['field']], field['header'])}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
