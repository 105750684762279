import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PivotServiceService {

  constructor() {
  }

  sum(list: any[], field) {
    let sum = 0;
    list.forEach(function (item) {
      if (typeof (item[field]) !== 'string') {
        sum += parseInt(item[field]);
      }
    });
    return sum;
  }

  average(list: any[], field) {
    let sum = this.sum(list, field);
    let avg = sum / list.length;
    return avg;
  }

}
