import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MessageService} from 'primeng';

@Component({
  selector: 'widget-accordian',
  templateUrl: './widget-accordian.component.html',
  styleUrls: ['./widget-accordian.component.css']
})
export class WidgetAccordianComponent implements OnInit {

  @Input() optionItem: any[] = [];
  @Output() onClickWidgetOption = new EventEmitter();


  expanded: boolean = false;

  constructor(public messageService: MessageService) { }

  ngOnInit(): void {
  }

  toggleAccordion() {
    this.expanded  = !this.expanded;
  }
  selectOption(item: any, index: number) {
    this.onClickWidgetOption.emit(this.optionItem['widgets'][index]);
  }
}
