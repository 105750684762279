<p-sidebar (onHide)="checkForChanges()" [(visible)]="display" [baseZIndex]="5"
           [modal]="true" [style]="{minWidth:'60%', maxWidth:'60%'}"
           position="right">
  <div class="div0">
    <div class="div1">
      <div>
        <img alt="" class="avatarImg"
             src="https://www.klrealty.com.au/wp-content/uploads/2018/11/user-image-.png">
      </div>
      <div class="div2">
        <div class="userInfoContent">
          <div class="div3">
            <p class="userName">{{data !== undefined ? data.userName : ''}}</p>
            <p class="emailAddress">{{data !== undefined ? data.emailAddress : ''}}</p>
          </div>
          <div>
            <div class="div4">
              <div class="iconTextContainer">
                <i class="fas fa-key fab fa-lg userIcons"></i>
                <p class="iconName">Authorize</p>
              </div>

              <div class="iconTextContainer">
                <i class="fas fa-lock fab fa-lg userIcons"></i>
                <p class="iconName">Lock</p>
              </div>

              <div class="iconTextContainer">
                <i class="fas fa-trash-alt fab fa-lg userIcons"></i>
                <p class="iconName">Delete</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="screentype !== null" (click) = 'opendailog()'  class="audittrail">
        <p class="audittrailtnText">Audit Trail</p>
      </div>
    </div>
  </div>
  <div (inViewportAction)="calculateHeight()" [id]="'container'" [style]="{minHeight:contentHeight,maxHeight:contentHeight}" class="multiselectContainer"
       inViewport>
    <div>
      <p-tabView (activeIndexChange)="hideBtns($event)" [activeIndex]="activeIndex">
        <p-tabPanel [id]="'uD'" [style]="{maxHeight:getMaxHeight('uD')}" header="User Details">
          <div class="detailsContainer">
            <ctrm-input-form (OnApply)="onInfoUpdate($event)"
                             (OnCancel)="close()"
                             [columnsMap]="grid.getColumn()"
                             [editEnabled]="editEnabled"
                             [editUrl]="getUserData()"
                             [gridConfig]="grid.getGridConfig()"
                             [gridTitle]="grid.getTitle()"
                             [heading]="grid.getTitle()" [modalState]="true"
                             [noOfColumns]="3"
                             [readOnly]="false"
                             [saveUrl]="data !== undefined? data['href']: ''"
                             [sections]="grid.getSections()"
                             [showFooter]="true"
                             [showOnModal]="false" [uniqueKey]="grid.getPrimaryKey()">
            </ctrm-input-form>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Legal Entity">
          <p-listbox [(ngModel)]="selectedLegalEntities" [multiple]="true" [options]="legalEntitiesOptions"
                     checkbox="checkbox"
                     class="style-4"
                     filter="filter"></p-listbox>
          <div *ngIf="legalEntitiesOptions.length==0" class="noDataDiv">
            <p class="noData">No Data Available</p>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Profit Centre">
          <p-listbox [(ngModel)]="selectedProfitCenters" [multiple]="true" [options]="profitCenterOptions"
                     checkbox="checkbox"
                     filter="filter"></p-listbox>
          <div *ngIf="profitCenterOptions.length==0" class="noDataDiv">
            <p class="noData">No Data Available</p>
          </div>
        </p-tabPanel>

        <p-tabPanel header="Counterparty">
          <p-listbox [(ngModel)]="selectedCounterParty" [multiple]="true" [options]="counterPartyOptions"
                     checkbox="checkbox"
                     filter="filter"></p-listbox>
          <div *ngIf="counterPartyOptions.length==0" class="noDataDiv">
            <p class="noData">No Data Available</p>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Commodity">
          <p-listbox [(ngModel)]="selectedCommodity" [multiple]="true" [options]="commodityOptions" checkbox="checkbox"
                     filter="filter"></p-listbox>
          <div *ngIf="commodityOptions.length==0" class="noDataDiv">
            <p class="noData">No Data Available</p>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
  <div *ngIf="isBtnsVisible" class="buttonContainer">
    <button (click)="checkForChanges()" class="cancelBtn">Cancel</button>
    <button (click)="onSave()" [disabled]="!editEnabled" [hidden]="isLoading" class="saveBtn">Save</button>
    <div [hidden]="!isLoading" class="loadingbutton">
      <div class="loader"></div>
      <p>Saving</p>
    </div>
  </div>
</p-sidebar>
<p-confirmDialog [baseZIndex]="25" [closable]="false" [visible]="showConfirmTab" header="Confirmation"
                 icon="pi pi-exclamation-triangle"></p-confirmDialog>

<app-ctrm-audittrail *ngIf="displayaudit"
                     [heading]="data !== undefined ? data.userName : ''"
                     [entityId]="data.uuid !== undefined ?data.uuid : ''"
                     [entityName]="screentype"
                     [display]="true"
                     (onclose)="closeaudittrail()"  >
</app-ctrm-audittrail>
