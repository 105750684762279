import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {Tab} from "../../../tab-grid/Tab.model";
import {MasterService} from "../../../masters/services/MasterService";
import {CommonService} from "../../../services/common.service";
import {environment} from "../../../../environments/environment";
import {MessageService} from "primeng";
import {FutureTradeComponent} from '../../../physicals/future-trade/future-trade.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-future-info',
  templateUrl: './future-info.component.html',
  styleUrls: ['./future-info.component.css']
})
export class FutureInfoComponent implements OnInit, OnChanges,AfterViewInit {


  @Input() display: boolean = false;
  @Input() futuretradeId: string = '';
  @Input() sidebarPosition: string = 'right';
  @Input() rowData: any = {};
  @Output() onClose = new EventEmitter();
  tabs: Tab[] = [];
  future: FutureTradeComponent;
  futureObject: any = {};
  editUrl: string;
  futureUrl: string;
  docUrl: string;
  docName: string;
  showDoc: boolean;


  constructor(private changeDetectorRef:ChangeDetectorRef,
              private  masterCommonService: MasterService,
              private commonService: CommonService,
              private router: Router,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.initTabs();

  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }


  initTabs() {
    this.future = new FutureTradeComponent(this.masterCommonService, this.commonService,
        this.router,this.messageService,null,null,null,null,null);
    this.future.ngOnInit();
    this.updateTabs();
    this.fetchTrade(this.futuretradeId);
  }

  updateTabs() {
    let _this = this;
    this.tabs = [];
    this.editUrl = this.getEditUrl();
    let tab = new Tab(this.future.grid.getTitle(), this.future.grid, 'tradeId', undefined, true, this.futureUrl, {minHeight: '86.6vh'});
    tab.getGrid().setTabs([]);
    tab.getGrid().setGridConfiguration(false, false, false);
    tab.formOnModal = true;
    this.tabs.push(tab);
  }

  close() {
    this.display = false;
    this.onClose.emit();
  }

  fetchTrade(value) {
    let url = environment.base_url + "/api/futureTrade/v1/getFutureTradeByTradeUuid?uuid=" +this.rowData['uuid']+"&tenantId="+this.commonService.getFromStorage('tenantId');
    this.commonService.getJSONByURL(url).subscribe((next: any[]) => {
      if (next !== undefined && next !== null) {
        this.futureObject = next;
        this.updateTabs();
      }
    });
  }

  onIndexChange(value: number) {
  }

  onClickButton($event: any) {
  }

  onCloseDocViewer(value: any) {
    this.docUrl = '';
    this.showDoc = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['uuid']) {
      //this.fetchTrade(changes['uuid'].currentValue);
    }
  }

  private getEditUrl() {
    let id = this.futureObject['uuid'];
    return environment.base_url + "/api/futureTrade/v1/getFutureTradeByTradeUuid?uuid=" +id+"&tenantId="+this.commonService.getFromStorage('tenantId');
  }


}
