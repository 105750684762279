import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Tcolumn} from "../../../grid/tcolumn.model";
import {MessageService, Table} from "primeng";
import {Subject} from "rxjs";
import {CommonService} from "../../../services/common.service";
import {MasterService} from "../../../masters/services/MasterService";
import {environment} from "../../../../environments/environment";
import {HttpErrorResponse} from "@angular/common/http";
import {
  SearchCriteria
} from "../../../Operations/transport-actualization/actualization-form/actualization-form.component";

@Component({
  selector: 'circle-planning-info',
  templateUrl: './circle-planning-info.component.html',
  styleUrls: ['./circle-planning-info.component.css']
})
export class CirclePlanningInfoComponent implements OnInit {
  @Output() onCancel = new EventEmitter();
  @Output() onComplete = new EventEmitter();
  @Input() planId?: string;
  @Input() docByPassId?: string;
  @Input() matchType?: string;
  @Input() visible:boolean = false;
  @Input() showPlan:boolean = false;
  @Input() showPlanDocByPass:boolean=false;
  @Input() showCloseIcon:boolean = false;
  @Input() showVesselBox:boolean =true;
  @ViewChild('dt', {static: true}) table: Table;
  @Input() buyObligation:any;
  @Input() sellObligation:any;
  @Input() buyObligationRequired:boolean=true;
  @Input() sellObligationRequired:boolean=true;
  defaultCurrency:string = 'USD';
  commodityShowPlan:string;
  quantityShowPlan:string;
  counterpartyList:any[] = [];
  noOfEnteries: number = 1;
  comment: any ='';
  isCircle:boolean = false;
  isString:boolean = false;
  columns: Tcolumn[] = [];
  values: any[] = [];
  private currencyFactors:any = {};
  lastSequenceNumber:number = 0;
  gridHeight: string;
  settlementPrice: number = 0.0;
  @Input() title: any = 'Circle';
  @Input() description: any = '';

  showValidate: boolean = false;
  nodeData: any[] = [];
  linkData: any[] = [];
  validityMessage: any = 'Invalid ';
  voyageDetailList:any[] = [];
  settlementCurrency: any = 'USD';
  contractMonth: any;
  contractMonthShowPlan:any;
  disableButton:boolean = false;
  currencyList: any[] =[];
  portList: any[] =[];
  voyageId: any = '';
  vesselName: any = '';
  loadPort: any = '';
  dischargePort: any = '';
  voyageList: any[] = [];
  vesselList: any[] = [];
  establishMinDate: any = '';
  dateFormat: string = environment.dateFormat.toUpperCase();
  establishedOn: any = new Date();
  constructor(private messageService: MessageService,public commonService:CommonService,private masterCommonService:MasterService) { }

  ngOnInit(): void {
    this.currencyList = this.commonService.getCurrencyList();
    this.contractMonth = this.commonService.getFormattedDate(this.getCommonField('deliveryEndDate'), environment.dateMonthFormat)
    this.getPortList();
    this.getCounterpartyList();
    if (this.showPlan) {
      this.commonService.getJSONByURL(environment.base_url + '/api/physicaltradeplanning/v1/getTradePlanningByPlanId?tenantId=' + this.commonService.getFromStorage('tenantId') + "&planId=" + this.planId).subscribe((resp: any) => {
        this.settlementPrice = resp.settlementPrice;
        this.settlementCurrency = resp.settlementCurrency;
        this.establishedOn = new Date(resp.establishedOn);
        this.commonService.getJSONByURL(environment.base_url + '/api/plannedObligation/v1/getplannedobligationsbyplanid?tenantId=' + this.commonService.getFromStorage('tenantId') + "&planId=" + this.planId).subscribe((next: any) => {
          next.forEach((record: any) => {
            this.commodityShowPlan = record.commodity;
            this.quantityShowPlan = record.plannedQuantity + ' ' + record.quantityUOM;
            this.contractMonthShowPlan = this.commonService.getFormattedDate(record.deliveryEndDate, environment.dateMonthFormat);
            this.settlementCurrency = record.tradePriceCurrency;
            if (record.circleStringSeq == 1 || record.tradeTransactionType == 'BUY') {
              let ref = record.externalReference == null ? record.plannedObligationId : record.externalReference;
              this.values[record.circleStringSeq - 1] = {
                sequenceNumber: record.circleStringSeq,
                newEntry: false,
                referenceNumber: ref,
                buyer: record.company,
                seller: record.counterparty,
                price: record.tradePrice,
                currency: record.tradePriceCurrency
              };
            } else {
              let ref = record.externalReference == null ? record.plannedObligationId : record.externalReference;
              this.values[record.circleStringSeq - 1] = {
                sequenceNumber: record.circleStringSeq,
                newEntry: false,
                referenceNumber: ref,
                seller: record.company,
                buyer: record.counterparty,
                price: record.tradePrice,
                currency: record.tradePriceCurrency
              };
            }

            this.onPriceValueChange(this.values[0], 1);
          })
        })
      })
    }
    if (this.showPlanDocByPass) {
      this.values = [];
      this.commonService.getJSONByURL(environment.base_url + '/api/physicaltradeplanning/v1/getTradePlanningByPlanId?tenantId=' + this.commonService.getFromStorage('tenantId') + "&planId=" + this.planId).subscribe((resp: any) => {
        this.settlementPrice = resp.settlementPrice;
        this.settlementCurrency = resp.settlementCurrency;
        this.establishedOn = new Date(resp.establishedOn);
        this.commonService.getJSONByURL(environment.base_url + '/api/plannedObligation/v1/getplannedobligationsbydocbypassid?tenantId=' + this.commonService.getFromStorage('tenantId') + "&docByPassId=" + this.planId).subscribe((next: any) => {
          next.forEach((record: any) => {
            if (!record.externalRecord && record.tradeTransactionType == 'SELL') {
              let ref = record.externalReference == null ? record.plannedObligationId : record.externalReference;
              this.values.push(this.getSingleEmptyRow(1, false, ref, record.counterparty, record.company, record.tradePrice, record.tradePriceCurrency));
            }
          })
          next.forEach((extRec: any) => {
            if (extRec.externalRecord) {
              this.commodityShowPlan = extRec.commodity;
              this.quantityShowPlan = extRec.plannedQuantity + ' ' + extRec.quantityUOM;
              this.contractMonthShowPlan = this.commonService.getFormattedDate(extRec.deliveryEndDate, environment.dateMonthFormat);
              this.settlementCurrency = extRec.tradePriceCurrency;
              this.establishedOn = this.commonService.getFormattedDate(extRec.createdTimestamp, environment.dateMonthFormat);
              if (extRec.tradeTransactionType == 'SELL') {
                this.values.push(this.getSingleEmptyRow(this.lastSequenceNumber + 1, false, extRec.plannedObligationId, extRec.counterparty, extRec.company, extRec.tradePrice, extRec.tradePriceCurrency));
              } else {
                this.values.push(this.getSingleEmptyRow(this.lastSequenceNumber + 1, false, extRec.plannedObligationId, extRec.company, extRec.counterparty, extRec.tradePrice, extRec.tradePriceCurrency));
              }
            }
          })
          next.forEach((rec: any) => {
            if (!rec.externalRecord && rec.tradeTransactionType == 'BUY') {
              let ref = rec.externalReference == null ? rec.plannedObligationId : rec.externalReference;
              this.values.push(this.getSingleEmptyRow(this.lastSequenceNumber + 1, false, ref, rec.company, rec.counterparty, rec.tradePrice, rec.tradePriceCurrency));
            }
          })
          this.onPriceValueChange(this.values[0], 0);
        })
      })
    }
  }

  private getCounterpartyList() {
    let _this = this;
    this.commonService.getLoadData('counterparty',['config'],[ [{key: 'code', value: 'counterparty'}]]).subscribe((next :any) => {
      _this.counterpartyList = _this.masterCommonService.getListFromArray(next['counterparty']);
    });
  }

  private getPortList() {
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url + '/api/location/v1/getallbylocationtype?tenantId=' + this.commonService.getFromStorage('tenantId') + '&locationType=Port').subscribe((next: any) => {
      _this.portList = _this.commonService.getListFromArray(next,'name','name')
      let searchCriteriaList = [];
      searchCriteriaList.push(new SearchCriteria('tenantId', this.commonService.getFromStorage('tenantId'), 'equals'));
      _this.commonService.post(environment.base_url + '/api/transportallocationv2/v1/get-vessel-by-criteria?vesselPlanning='+true+'&tenantId=' + this.commonService.getFromStorage('tenantId'),searchCriteriaList).subscribe((data: any) => {
        _this.voyageDetailList = data;
        _this.voyageList = _this.commonService.getListFromArray(data,'bookingNumber','bookingNumber');
      });
      _this.commonService.getJSONByURL(environment.base_url_new + '/api-bm/api/vessel-master').subscribe((data:any) => {
        _this.vesselList = this.commonService.createListFromObject(data,'vesselName', 'vesselName',true);
      });
      _this.initGrid();
    });
  }

  closeCircle() {
          if(this.showValidate) {
            this.showValidate = !this.showValidate;
          }else {
            this.visible = false;
            this.onCancel.emit();
          }
  }

  continuePlanning() {
    let sameReferences = this.isAnySame(this.values);
    if(sameReferences !== null && sameReferences.length > 0) {
      this.showToast("Found Duplicate External Reference ["+sameReferences.join(",")+"].",'error')
      return;
    }
    let planType = '';
    if(this.title === 'Circle') {
      planType = 'Circle';
    } else if(this.title === 'String') {
      planType = 'String';
    }else if(this.title === 'Doc Bypass'){
      planType = 'DocByPass'
    }

    this.disableButton = true;
    let matchQuantity= this.buyObligation['matchQuantity'];
      if(this.title==='Doc Bypass'){
        matchQuantity= (this.buyObligationRequired? this.buyObligation['plannedQuantity']: this.sellObligation['plannedQuantity']);
      }
    let docBypass= false;
      docBypass=(this.title === "Doc Bypass");

      let payload ={
        "planId": "",
        "planUuid" : "",
        "buyObl": [],
        "sellObl": [],
        "tradeLinks": [],
        "matchType": "DocBypass",
        "circleStringPlanDTO": {
          planType:planType,
          docByPass:docBypass,
          commodity:this.buyObligation['commodity'],
          quantity:matchQuantity,
          contractMonth: this.buyObligation[''],
          settlementPrice:this.settlementPrice,
          settlementCurrency:this.settlementCurrency,
          planLines:this.values,
          establishedOn: this.commonService.convertDatetoUTC(this.establishedOn,true),
          comments:this.comment,
          buyTradeObligation: (this.sellObligationRequired && !this.buyObligationRequired)?this.sellObligation:this.buyObligation,
          voyageId:this.voyageId,
          vesselName:this.vesselName,
          loadPort:this.loadPort,
          dischargePort:this.dischargePort
        }
      }
    let _this = this;
    this.commonService.post( environment.base_url +'/api/plannedObligation/v1/save-plan-v2',payload).subscribe((next:any) => {
      _this.disableButton = false;
      _this.onComplete.emit(next);
    },(error:HttpErrorResponse) => {
      _this.disableButton = false;
      let message = _this.commonService.getHttpErrorMessage(error,"Physical Trade Planning",true);
      message = message.replace(new RegExp("\\["),'');
      message = message.replace(new RegExp(']'),'');
      message = message.replace(new RegExp(','),'\n');
      _this.showToast(message,'error');
    });
  }

  private updateLastValue(resetBuyerValue:boolean = false) {
    if(this.title === 'Circle') {
      if(this.values[this.values.length - 1]['newEntry']) {
        this.values[this.values.length - 1]['buyer'] = this.values[0]['seller'];
        if(resetBuyerValue && this.values[this.values.length - 2]['newEntry']) {
          this.values[this.values.length - 2]['buyer'] = '';
        }
      }
    }
    if(this.title === 'Doc Bypass'){
      if(!this.buyObligationRequired) {
        if(this.values[1]['newEntry']) {
          this.values[1]['seller'] = this.values[0]['buyer'];
        }
      } else {
        if(this.values[this.values.length - 2]['newEntry']) {
          this.values[this.values.length - 2]['buyer'] = this.values[this.values.length - 1]['seller'];
        }
      }

    }
  }

  addEntries() {
    for(let i=0;i<this.noOfEnteries;i++) {
      if(this.sellObligationRequired){
        this.values.push(this.getSingleEmptyRow(this.lastSequenceNumber+1,true,'','','',0,this.defaultCurrency));
      } else if (this.buyObligationRequired){
        this.values.unshift(this.getSingleEmptyRow(this.lastSequenceNumber+1,true,'','','',0,this.defaultCurrency));
      }
    }
    this.updateLastValue();
    this.calculateHeight(this.table);
  }

  private initGrid() {
    this.columns.push(new Tcolumn('sequenceNumber','S.No','LB',1));
    this.columns.push(new Tcolumn('referenceNumber','Reference Number','T',2));
    this.columns.push(new Tcolumn('seller','Seller','L',3));
    this.columns.push(new Tcolumn('buyer','Buyer','L',4));
    this.columns.push(new Tcolumn('price','Price ','N',5));
    this.columns.push(new Tcolumn('currency','Currency','L',6));
    this.columns.push(new Tcolumn('amount','Amount Calculated','T',7));
    this.values = [];
    this.defaultCurrency = (this.sellObligationRequired?this.sellObligation['tradePriceCurrency']:this.buyObligation['tradePriceCurrency']);
    if(!this.showPlan){this.settlementCurrency = this.defaultCurrency;}
    let combine = (this.title === "String" || this.title === "Circle")
    if(this.buyObligationRequired && !this.showPlan && !this.showPlanDocByPass){
      this.values.push(this.getSingleEmptyRow(1,false,this.getRef(this.buyObligation),this.buyObligation['company'],this.buyObligation['counterparty'],
          this.commonService.formatNumberWithoutComma(this.buyObligation['tradePrice'],this.commonService.getFromTenantConfig(2,"roundingFormat","priceRounding")),this.buyObligation['tradePriceCurrency'],this.buyObligation,combine));
    }
    if(this.sellObligationRequired && !this.showPlan && !this.showPlanDocByPass){
      this.values.push(this.getSingleEmptyRow(2,false,this.getRef(this.sellObligation),this.sellObligation['counterparty'],this.sellObligation['company'],
          this.commonService.formatNumberWithoutComma(this.sellObligation['tradePrice'],this.commonService.getFromTenantConfig(2,"roundingFormat","priceRounding")),this.sellObligation['tradePriceCurrency'], this.sellObligation,combine));
    }
    this.onPriceValueChange(this.values[0],0);
  }

  private getRef(obligation) {
    if(obligation['referenceNumber'] !== null && obligation['referenceNumber'] !== undefined && obligation['referenceNumber'].length > 0) {
      return obligation['referenceNumber'];
    }
    return obligation['tradeId'];
  }

  getSingleEmptyRow(seqNo:number = 1,isNewEntry:boolean = true,referenceNumber = '',buyer = '',seller = '',price:any = 0,currency = '',obligation:any = null,combineTag:boolean = false) {
    let obj = {
      tag: (isNewEntry?'external'+new Date().getTime():(combineTag?'internal':'internal' + new Date().getTime())),
      sequenceNumber:seqNo,
      referenceNumber:referenceNumber,
      buyer:buyer,
      seller:seller,
      price:this.commonService.formatNumberWithoutComma(price,this.commonService.getRoundingFormat('priceRounding',2)),
      currency:currency,
      newEntry:isNewEntry,
      tradeObligation:obligation,
      amount:this.commonService.formatNumberWithoutComma(this.getConvertedPrice(price,this.settlementCurrency,currency,this.settlementPrice,seqNo),this.commonService.getFromTenantConfig(3,"roundingFormat","priceRounding"))
    };
    this.lastSequenceNumber = seqNo;
    return obj;
  }

  private getConvertedPrice(price:number,toCurrency:string,fromCurrency:string,settlementPrice:number,seqNumber:number) {
    let fxRate:number = 1;
    if(fromCurrency !== null && fromCurrency !== undefined && toCurrency !== null && toCurrency !== undefined && toCurrency.length > 0 && fromCurrency.length > 0) {
      if(fromCurrency !== toCurrency) {
        let currencyPair = fromCurrency+"/"+toCurrency;
        if(Object.keys(this.currencyFactors).includes(currencyPair)) {
          fxRate = this.currencyFactors[currencyPair];
        } else {
          currencyPair = toCurrency+"/"+fromCurrency;
          if(Object.keys(this.currencyFactors).includes(currencyPair)) {
            fxRate = (1/this.currencyFactors[currencyPair]);
          }
        }
      }
    }
    price = price * fxRate;
    if(settlementPrice !== null && settlementPrice !== undefined) {
      price = price - settlementPrice;
    }
    return price;
  }


  @HostListener("window:resize", [])
  public onResize() {
    this.calculateHeight(this.table);
  }

  calculateHeight(dt: Table) {
    if(this.table === undefined || this.table === null)  {
      this.table = dt;
    }
    if(this.table !== undefined && this.table !== null) {
      let offsetTop = this.table.el.nativeElement['offsetTop'];
      let offsetHeight = 0;
      if(this.table.el.nativeElement['offsetParent'] !== undefined && this.table.el.nativeElement['offsetParent'] !== null) {
        offsetHeight = this.table.el.nativeElement['offsetParent']['offsetHeight'];
      }
      this.gridHeight =  (!this.showPlan && !this.showPlanDocByPass)? offsetHeight - (offsetTop + 230) + 'px':offsetHeight - offsetTop +'px';
    }
  }

  rowDelete(ri: any) {
    this.values.splice(ri,1);
  }

  moveUp(ri: any, rowData: any) {
    if(![((this.title === 'Doc Bypass' && !this.buyObligationRequired)?1:0),0].includes(ri) ) {
      this.values.splice(ri,1);
      this.values.splice(ri-1,0,rowData);
      if(ri === (this.values.length-1)) {
        this.updateLastValue(true);
      } else {
        this.updateLastValue();
      }
      if(ri === 1) {
        if(this.title === 'String') {
          this.onPriceValueChange(this.values[0],0);
        }
      }
    }
    this.changeAllAmount();

  }

  moveUpTag(ri: any, rowData: any) {
    let cssClass = this.getUpOrderClassIcon(ri);
    if(cssClass.toLowerCase().includes("disabled")) {
      return;
    }
    let tag = rowData['tag'];
    let tagValues = this.values.filter(item => item['tag'] == tag);
    if(!rowData['newEntry']) {
      if(rowData.tradeObligation.tradeTransactionType === "BUY") {
        this.moveUp(ri,rowData);
        this.moveUp(ri+1,tagValues[1]);
      } else if(rowData.tradeObligation.tradeTransactionType==="SELL" && (ri-2>0)) {
        this.moveUp(ri-1,tagValues[0]);
        this.moveUp(ri,rowData);
      }
    } else {
      if(ri - 1 > 0) {
        let aboveRow = this.values[ri-1];
        if(!aboveRow.newEntry) {
          this.moveDownTag(ri -1,aboveRow);
        } else {
          this.moveUp(ri,rowData);
        }
      } else {
        this.moveUp(ri,rowData);
      }
    }
  }

  moveDownTag(ri: any, rowData: any) {
    let cssClass = this.getDownOrderClassIcon(ri);
    if(cssClass.toLowerCase().includes("disabled")) {
      return;
    }
    let tag = rowData['tag'];
    let tagValues = this.values.filter(item => item['tag'] == tag);
    if(!rowData['newEntry']) {
      if(rowData.tradeObligation.tradeTransactionType === "BUY" && (ri+2<this.values.length)) {
        this.moveDown(ri+1,tagValues[1]);
        this.moveDown(ri,rowData);
      } else if(rowData.tradeObligation.tradeTransactionType === "SELL"){
        this.moveDown(ri,rowData);
        this.moveDown(ri-1,tagValues[0]);
      }
    } else {
      if(ri + 1 < this.values.length - 1) {
        let aboveRow = this.values[ri + 1];
        if(!aboveRow.newEntry) {
          this.moveUpTag(ri +1,aboveRow);
        } else {
          this.moveDown(ri,rowData);
        }
      } else {
        this.moveDown(ri,rowData);
      }
    }
  }

  moveDown(ri: any, rowData: any) {
    if(![(this.values.length - 1),((this.title === 'Doc Bypass' && !this.sellObligationRequired)?this.values.length - 2:this.values.length - 1)].includes(ri)) {
      this.values.splice(ri,1);
      this.values.splice(ri+1,0,rowData);
      this.updateLastValue();
      if(ri === (this.values.length-2)) {
        this.updateLastValue(true);
        if(this.title === 'String') {
          this.onPriceValueChange(this.values[0],0);
        }
      } else {
        this.updateLastValue();
      }
    }
  }

  getUpOrderClassIcon(ri: any) {
    if(ri === 0) {
      return 'orderIconClassDisabled';
    }
    else if(this.title === 'Doc Bypass' && ri==1){
      return 'orderIconClassDisabled';
    }
    else if (this.values.length === 0) {
      return 'orderIconClassDisabled';
    } else {
      return 'orderIconClass';
    }
  }

  getDownOrderClassIcon(ri: any) {
    if(ri === (this.values.length - 1)) {
      return 'orderIconClassDisabled';
    }
    else if (this.values.length === 0) {
      return 'orderIconClassDisabled';
    } else {
      return 'orderIconClass';
    }

  }

  getSelectableRow(rowData: any, ri: any) {
    rowData['sequenceNumber'] = (ri+1);
    return rowData;
  }

  validate() {
    this.showValidate = !this.showValidate;
    this.createFlowChartData();
  }

  createFlowChartData() {
    let _this = this;
    this.isCircle = false;
    this.isString = false;
    this.nodeData = [];
    this.linkData = [];
    let isLinked:boolean = true;
    let lastKey = '';
    let firstKey = '';
    let addedKeys:any[] =[];
    this.values.forEach(function (entry:any,index:number){
      if(entry['seller'] !== undefined && entry['seller'] !== null && entry['seller'].length > 0 ){
        if(index === 0) {
          firstKey = entry['seller'];
        }
        if(!addedKeys.includes(entry['seller'])) {
          _this.nodeData.push({id:entry['seller'],label:entry['seller']});
          addedKeys.push(entry['seller'])
        }
        if(isLinked && index > 0) {
          if(entry['seller'] !== lastKey) {
            isLinked = false;
          }
        }
      } else {
        _this.showToast("Invalid Seller for Sequence Number : "+entry['sequenceNumber'],'error');
        _this.showValidate = false;
      }
      if(entry['buyer'] !== undefined && entry['buyer'] !== null && entry['buyer'].length > 0 ){
        lastKey = entry['buyer'];
        if(!addedKeys.includes(entry['buyer'])) {
          _this.nodeData.push({id:entry['buyer'],label:entry['buyer']})
          addedKeys.push(entry['buyer'])
        }
      } else {
        _this.showToast("Invalid Buyer for Sequence Number : "+entry['sequenceNumber'],'error');
        _this.showValidate = false;
      }
      _this.linkData.push({id:entry['sequenceNumber'],source:entry['seller'],target:entry['buyer'],label:entry['seller']+' TO '+entry['buyer']});
    });
    if(isLinked) {
      if(lastKey !== firstKey) {
        this.isString = true;
      } else {
        this.isCircle = true;
      }
    }
    if(this.title === 'Circle') {
      if(this.isCircle) {
        this.validityMessage = "Valid Circle Plan";
      } else {
        this.validityMessage = "Invalid Circle Plan";
      }
    } else if(this.title === 'String' || this.title == 'Doc Bypass') {
      if(this.isString) {
        this.validityMessage = "Valid "+this.title+" Plan";
      } else {
        this.validityMessage = "Invalid  "+this.title+" Plan";
      }
    }
  }

  getScreenWidth() {
    let screenWidth = 0.70 * window.screen.width;
    return screenWidth;
  }

  getScreenHeight() {
    return window.screen.height - 400;
  }

  showToast(message:any,type = 'info') {
    this.messageService.add({
      severity: type,
      summary: message
    });
  }

  getValidBoxClass() {
    if(this.title === 'Circle') {
      if(this.isCircle) {
        return 'fas fa-check validplanbox';
      } else {
        return 'fas fa-times invalidplanbox';
      }
    } else if(this.title === 'String') {
      if(this.isString) {
        return 'fas fa-check validplanbox';
      } else {
        return 'fas fa-times invalidplanbox';
      }
    }
  }

  canShowLast(rowData: any,ri:number) {
    if(rowData['newEntry']) {
      if(this.title === 'Circle') {
        if(ri === (this.values.length - 1)) {
          return false;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
    return true;
  }

  onSellerChange(value: any, ri: any) {
    if(ri === 0) {
      this.updateLastValue();
    }
  }

  private getNumber(val:any) {
    if(val === null || val === undefined || val.length === 0) {
      return 0;
    }
    return parseFloat(val);
  }

  onPriceValueChange(rowData: any, index: number, updateSettlementPrice: boolean = true) {
    let _this = this;
    if (!this.showPlan && !this.showPlanDocByPass) {
      if (this.title === 'Circle') {
        if (updateSettlementPrice) {
          let prices = this.values.map(i => i.price).filter(i => i != 0);
          this.settlementPrice = Math.min(...prices);
        }

      } else {
        if (updateSettlementPrice) {
          this.settlementPrice = this.values[0].price;
        }
      }
    }
    this.values.forEach((item, i) => {
      item['amount'] = _this.commonService.formatNumberWithoutComma(_this.getConvertedPrice(_this.getNumber(item['price']), _this.settlementCurrency, item['currency'], _this.settlementPrice, i), _this.commonService.getFromTenantConfig(3, 'roundingFormat', 'priceRounding'));
    })
  }

  changeAllAmount() {
    let _this = this;
    this.values.forEach(function (value:any,index:number) {
       _this.onPriceValueChange(value,index);
    });
  }

  onSettlementCurrencyChange() {
    let _this = this;
    if(this.settlementCurrency !== null && this.settlementCurrency !== undefined && this.settlementCurrency.length > 0) {
      this.commonService.getJSONByURL(environment.base_url+'/api/fxirprice/v1/getallcurrenyfactorbycurrency?tenantId='+this.commonService.getFromStorage('tenantId')+'&currencyCode='+this.settlementCurrency).subscribe(next => {
        _this.currencyFactors = next;
        _this.columns[6].setHeader("Amount Calculated ( "+_this.settlementCurrency+" )")
        _this.changeAllAmount();
      });
    } else {
      _this.columns[6].setHeader("Amount Calculated");
    }
  }


  formatPrice(rowData: any) {
    rowData['price'] = this.commonService.formatNumberWithoutComma(this.getNumber(rowData['price']),this.commonService.getFromTenantConfig(2,'roundingFormat','priceRounding'));
  }

  onChangeVoyage() {
      let obj = this.voyageDetailList.find(i => i['bookingNumber'] == this.voyageId);
      if(obj !== null && obj !== undefined) {
        this.vesselName = obj['vesselName'];
      }
  }

    getCommonField(fieldName:string) {
      if(this.buyObligation !== null && this.buyObligation !== undefined && Object.keys(this.buyObligation).length > 0) {
        return this.buyObligation[fieldName]
      }
      return this.sellObligation[fieldName]
    }

  private isAnySame(list:any[] =[]) {
    let arr = list.map(i=> i.referenceNumber)
    const distinct = new Set(arr);
    const filtered = arr.filter(item => {
      if (distinct.has(item)) {
        distinct.delete(item);
      }
      else {
        return item;
      }
    });

    return [...new Set(filtered)]
  }

  onPriceValueChangeDrag(param, number: number, b: boolean, eventVal: any) {
      let dragIndex = eventVal.dragIndex;
      let dropIndex = eventVal.dropIndex;
      let aboveValue = this.values[dropIndex - 1];
      let belowValue = this.values[dropIndex + 1];
      if(['String','Circle'].includes(this.title)) {
        if(aboveValue != null && aboveValue !== undefined && belowValue !== null && belowValue !== undefined && !aboveValue.newEntry && !belowValue.newEntry) {
          let dropIndexRow  = this.values[dropIndex];
          this.values.splice(dropIndex,1);
          this.values.splice(dragIndex,0,dropIndexRow);
          this.showToast("Can not drag in between internal Obligations",'error');
          return;
        }
        this.onPriceValueChange(param,number,b);
      } else {

      }

  }
}
