import {Component, HostListener, Input, OnInit} from '@angular/core';
import {CommonService} from '../services/common.service';
import {Breadcrumb} from "../Components/ctrm-breadcrumb/breadcrumb.model";
import {BreadcrumbService} from "../Components/ctrm-breadcrumb/breadcrumb.service";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../environments/environment";
import {MessageService} from 'primeng';
import {HttpErrorResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {AccessPolicyService} from "../services/accesspolicy.service";


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  card: any = [];
  visible: boolean = true;
  breadcrumbchilds: Breadcrumb[] = [];
  subcards: any = [];
  title: any;
  widgetDialog: boolean = false;
  display: boolean =  true;
  widgetOption:any[]=[];
  preferenceData:any[] = [];
  allNotifications: any[] = [];
  notifPanelDisplay: boolean = false;
  notificationLoadingFlag: boolean = false;
  newNotif: boolean = false;
  isLive: boolean = true;
  widgetMenuJSON = [
    {
      "title": "Trader Dashboard",
      "expanded":true,
      "widgets": [
        {
          "code": "openPosByCommodity",
          "name": "Open Pos by Commodity",
            "chartType":"stackedBar",
          "url":{
            graphUrl:"/api/dashboardWidget/v1/allopenposition",
            dataViewUrl:[
              {viewName:"Physical Open Position", url:"/api/dashboardWidget/v1/physicalopenposition"},
              {viewName:"Future Open Position", url:"/api/dashboardWidget/v1/futureopenposition"}]
          },
          "selected": true,
          "canLive": true
        },
        {
          "code": "openPosByCompany",
          "name": "Open Pos by Company",
          "chartType":"stackedBar",
          "url":{
            graphUrl:"/api/dashboardWidget/v1/allopenposition",
            dataViewUrl:[
              {viewName:"Physical Open Position", url:"/api/dashboardWidget/v1/physicalopenposition"},
              {viewName:"Future Open Position", url:"/api/dashboardWidget/v1/futureopenposition"}]
          },
          "selected": true,
          "canLive": true
        },
        {
          "code": "openPosByProfitcenter",
          "name": "Open Pos by Profit Center",
          "chartType":"stackedBar",
          "url":{
            graphUrl:"/api/dashboardWidget/v1/allopenposition",
            dataViewUrl:[
              {viewName:"Physical Open Position", url:"/api/dashboardWidget/v1/physicalopenposition"},
              {viewName:"Future Open Position", url:"/api/dashboardWidget/v1/futureopenposition"}]
          },
          "selected": true,
          "canLive": true
        },
        {
          "code": "openPosByCounterparty",
          "name": "Open Pos by Counterparty",
          "chartType":"stackedBar",
          "url":{
            graphUrl:"/api/dashboardWidget/v1/allopenposition",
            dataViewUrl:[
              {viewName:"Physical Open Position", url:"/api/dashboardWidget/v1/physicalopenposition"},
              {viewName:"Future Open Position", url:"/api/dashboardWidget/v1/futureopenposition"}]
          },
          "selected": true,
          "canLive": true
        },
        {
          "code": "openPosByTrader",
          "name": "Open Pos by Trader",
          "chartType":"stackedBar",
          "url":{
            graphUrl:"/api/dashboardWidget/v1/allopenposition",
            dataViewUrl:[
              {viewName:"Physical Open Position", url:"/api/dashboardWidget/v1/physicalopenposition"},
              {viewName:"Future Open Position", url:"/api/dashboardWidget/v1/futureopenposition"}]
          },
          "selected": true,
          "canLive": true
        },
        {
          "code": "m2mRealizedPnl",
          "name": "MTM/Realized PnL",
            "chartType":"line",
            "url":{
                graphUrl:[
                    "/api/physicalm2mrealizedpnl/v1/getphysicalm2mpnl",
                    "/api/physicalm2mrealizedpnl/v1/getphysicalrealizedpnl"
                ],
                dataViewUrl:[
                    {viewName:"M2M/Realized Pnl", url:"/api/physicalm2mrealizedpnl/v1/getphysicalm2mrealizedpnl"},
                ]
            },
          "selected": true,
          "canLive": false
        },
        {
          "code": "openTrades",
          "name": "Open Trades",
          "url":{
            graphUrl:[
              "/api/openTrades/v1/getopentradesgraphdata"
            ],
            dataViewUrl:[
              {viewName:"Open Trades", url:"/api/openTrades/v1/getopentrades"},
            ]
          },
          "selected": false,
          "canLive": false
        },
        {
          "code": "revenueSplit",
          "name": "Revenue Split",
          "url":{
            graphUrl:[
              "/api/revenuesplit/v1/getrevenuesplitgraphdata"
            ],
            dataViewUrl:[
              {viewName:"Revenue Split", url:"/api/revenuesplit/v1/getrevenuesplit"},
            ]
          },
          "selected": false,
          "canLive": false
        },
        {
          "code": "unadjustedFutureTrades",
          "name": "Unadjusted Future Trades",
          "chartType":"stackedBar",
          "url":{
            graphUrl:[
              "/api/unadjustedfuturetrades/v1/getunadjustedfuturegraphdata"
            ],
            dataViewUrl:[
              {viewName:"Revenue Split", url:"/api/revenuesplit/v1/getrevenuesplit"},
            ]
          },
          "selected": false,
          "canLive": false
        }
      ]
    },
    {
      "title": "Operation Dashboard",
      "expanded":true,
      "widgets": [
        {
          "code": "plannedUnplannedTrades",
          "name": "Planned Trades",
          "url":{
            graphUrl:[
              "/api/plannedunplannedqty/v1/getplannedunplannedqtygraphdata"
            ],
            dataViewUrl:[
              {viewName:"Planned/Unplanned", url:"/api/plannedunplannedqty/v1/getplannedunplannedqty"},
            ]
          },
          "selected": false,
          "canLive": false
        },
        {
          "code": "deliveryStartedTrades",
          "name": "Delivery Started Trades",
          "url":{
            graphUrl:[
              "/api/deliveryStarted/v1/getdeliverystartedgraphdata"
            ],
            dataViewUrl:[
              {viewName:"Delivery Started", url:"/api/deliveryStarted/v1/getdeliverystarted"},
            ]
          },
          "selected": false,
          "canLive": false

        },
        {
          "code": "pnlDrawdown",
          "name": "PnL Draw-Down",
          "url":{
            graphUrl:[
              "/api/pnldrawdown/v1/getdailypnldrawdownreport"
            ],
            dataViewUrl:[
              {viewName:"PnL Draw-Down", url:"/api/pnldrawdown/v1/getdailypnldrawdownreport"},
            ]
          },
          "selected": false,
          "canLive": false
        }
      ]
    },
    {
      "title": "Risk Dashboard",
      "expanded":true,
      "widgets": [

      ]
    }
  ];
  lastRunDate: any = '';
  count: number = 0;
  private sub: any;
  private myDate: Date;
  constructor(private router: ActivatedRoute,private accessPolicyService:AccessPolicyService,private breadcrumbService: BreadcrumbService, private commonService: CommonService, private messageService: MessageService) {
    let _this = this;
    this.router.queryParams.subscribe(params => {
      if (params['id'] !== undefined) {
        _this.visible = false;
        _this.updateChildAndTitle(params['id']);
      }
    });
  }

  ngOnInit() {
    this.getUserPreferenceData();
    this.getNotificationsFromDB();
    let _this = this;
    this.getDashBoard().subscribe((value) => {
      _this.card = value;
    });
    this.getLastRunDate();
    this.breadcrumbService.clearAll();
  }

  //get the Customized Dashboard JSON
  getDashBoard() {
    return this.commonService.getJSONByFile('dashboard.json');
  }

  updateChildAndTitle(id) {
    let _this = this;
    this.getDashBoard().subscribe((dashCard) => {
      dashCard.forEach(function (card) {
        if (card['id'] === id) {
          _this.title = card['title'];
          _this.subcards = card['childs'];
        }
      });
    })
  }


  getFeedUrl() {
    return environment.feedUrl;
  }

  openWidgetDialog() {
    this.widgetDialog = true;
  }

  //Close the Widget Customization Dialog
  closeWidgetDialog() {
    this.widgetDialog = false;
  }

  saveJson() {
    let preferencePayload;
    if (this.preferenceData.length > 0){
      preferencePayload = this.preferenceData[0];
      preferencePayload['preferencejson'] = JSON.stringify(this.widgetMenuJSON);
    }
    else {
      preferencePayload ={
        modulename:'dashboard',
        submodulename:'widget',
        preferencetype:'json',
        preferencejson:JSON.stringify(this.widgetMenuJSON),
        preferencename: this.commonService.getFromStorage('userName') +'_dashboardservice_customwidget_json',
        status:true,
        tenantId:this.commonService.getFromStorage('tenantId'),
        userid:this.commonService.getFromStorage('userid')
      }
    }


    this.commonService.getJSONByObject(environment.base_url_new + '/api-iam/api/userpreference/v1/saveuserpreference?tenantId=' + this.commonService.getTenantId(), preferencePayload).subscribe(next => {
      this.messageService.add({
        key: 'toastMessage',
        severity: 'info',
        summary: 'Preference Saved'
      });
      this.closeWidgetDialog();
    });

  }

  //To refresh the Dashboard Graphs
  refreshDashboard() {
    this.getLastRunDate();
    //TODO: Apply rest of the refresh logic
  }

  //sets refreshTime as Current Date and Time when called.
  getLastRunDate() {
    this.commonService.getJSONByURL(environment.base_url + '/api/eodTemplate/v1/getlatesteodrundate?tenantId='+this.commonService.getFromStorage('tenantId')).subscribe( (date:any)=>{
      this.lastRunDate = this.commonService.getFormattedDate(date, environment.full_date_format);
    });
  }

    closeWidget(widgetToClose: any) {
        this.widgetMenuJSON.forEach( (obj) =>{
          obj['widgets'].forEach(widget =>{
            if (widget['code'] === widgetToClose['code']){
              widget['selected'] = false;
            }
          });
        });
    }


  onWidgetOptionClick(option) {
    if (option['selected']){
      this.widgetOption[this.widgetOption.findIndex(x => x.code ===option['code'])]['selected'] = !this.widgetOption[this.widgetOption.findIndex(x => x.code ===option['code'])]['selected'];
    }else{
      this.count = this.widgetOption.filter((obj) => obj.selected === true).length;
      if (this.count >= 6){
        this.messageService.add({
          severity: 'error',
          summary:'Max number of selected Widget reached',
          detail: 'Cannot select more than 6 Widgets'
        });
      }else {
        this.widgetOption[this.widgetOption.findIndex(x => x.code ===option['code'])]['selected'] = !this.widgetOption[this.widgetOption.findIndex(x => x.code ===option['code'])]['selected'];
      }
    }
  }

  getUserPreferenceData() {
    this.commonService.getJSONByURL(environment.base_url_new + '/api-iam/api/userpreference/v1/getuserpreference' + '?tenantId=' + this.commonService.getTenantId() + '&preferenceName=' + this.commonService.getFromStorage('userName') + '_dashboardservice_customwidget_json').subscribe(preferenceData => {
      if ((preferenceData !== undefined && preferenceData !== null && typeof preferenceData !== 'string')) {
        this.preferenceData.push(preferenceData);
        this.compareWithLocalMenuJson(JSON.parse(preferenceData['preferencejson']));
      } else {
        this.widgetMenuJSON.forEach(obj => {
          this.widgetOption.push(...obj['widgets']);
        });
      }
    }, error => {
      this.widgetMenuJSON.forEach(obj => {
        this.widgetOption.push(...obj['widgets']);
      });
    });
  }

  compareWithLocalMenuJson(json:any[]) {
    let finalJson:any[] = [];
    let menuObject:any = {};
    let widgets:any[] = [];
    let prefwidgets:any[] = [];
    json.forEach(function(menu:any){
      menuObject[menu['title']] = menu['widgets'];
    })
    let found = true;
    let menutemp = {};
    this.widgetMenuJSON.forEach(function(menu:any) {
      if(Object.keys(menuObject).includes(menu['title'])) {
        menutemp = JSON.parse(JSON.stringify(menu));
        menutemp['widgets'] = [];
        widgets = menu['widgets'];
        prefwidgets = menuObject[menu['title']];
        widgets.forEach(function(widget:any){
          found = false;
          prefwidgets.forEach(function(widget2) {
            if(widget2['code'] === widget['code']) {
              if(!found) {
                found = true;
                menutemp['widgets'].push(widget2);
              }
            }
          })
          if(!found) {
            menutemp['widgets'].push(widget);
          }
        });
        finalJson.push(menutemp);
      } else {
        finalJson.push(menu);
      }
    });
    this.widgetMenuJSON = JSON.parse(JSON.stringify(finalJson));
    this.widgetMenuJSON.forEach(obj => {this.widgetOption.push(...obj['widgets']);});
  }

  //Added the listner to detect the clicking the outside of notification div to close it.
  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    this.notifPanelDisplay = false;
  }

  //Open Notification Panel and call the API to fetch all the notifications.
  //API call will not happen if the allNotifications array is not Empty.
  openNotificationsPanel(event) {
    this.newNotif = false;
    event.stopPropagation();
    this.notifPanelDisplay = !this.notifPanelDisplay;
    this.getNotificationsFromDB();
  }

  getNotificationsFromDB() {
    this.commonService.getFromUrl(environment.base_url + '/api/notification/v1/getallnotifications?tenantId='+this.commonService.getTenantId()).subscribe((notif: any) => {
      this.allNotifications = notif;
      this.notificationLoadingFlag = false;
    });
  }

  applyNewNotifications(notifications: any) {
    this.newNotif = true;
  }

  onActionClick(notif: any) {
    this.notifPanelDisplay = false;
    this.commonService.setNotificationViewStatus(notif);
  }

  onInterval(){
    this.myDate = new Date();
    this.lastRunDate=this.commonService.getFormattedDate(this.myDate, environment.full_date_format);
  }

  onToggleDataView(dataviewVisibility){
    if(dataviewVisibility){
      if(this.sub !== undefined && this.sub !== null) {
        this.sub.unsubscribe();
      }
    }else{
      this.liveSubscribe();
    }
  }

  onToggleChange() {
    this.isLive = !this.isLive;
    if(this.isLive===true){
      this.onInterval();
      this.liveSubscribe();
    }else{
      this.lastRunDate='';
      this.getLastRunDate();
      this.sub.unsubscribe();
    }
  }

  liveSubscribe(){
    //period for polling 5 min (5*60*1000=300000)
    this.sub = Observable.interval(300000)
        .subscribe((val) => {
          let _this=this;
          _this.onInterval();
          this.commonService.getJSONByURL(environment.base_url_new + '/api-iam/api/userpreference/v1/getuserpreference' + '?tenantId=' + this.commonService.getTenantId() + '&preferenceName=' + this.commonService.getFromStorage('userName') + '_dashboardservice_customwidget_json').subscribe(preferenceData => {
            if ((preferenceData !== undefined && preferenceData !== null && typeof preferenceData !== 'string')) {
              this.preferenceData.push(preferenceData);
              this.widgetMenuJSON = JSON.parse(preferenceData['preferencejson']);
              this.widgetOption = [];
              this.widgetMenuJSON.forEach(obj => {
                this.widgetOption.push(...obj['widgets']);
              });
            } else {
              this.widgetOption = [];
              this.widgetMenuJSON.forEach(obj => {
                this.widgetOption.push(...obj['widgets']);
              });
            }
          }, error => {
            this.widgetOption=[];
            this.widgetMenuJSON.forEach(obj => {this.widgetOption.push(...obj['widgets']);});
          });
        });
  }
}
