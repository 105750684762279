import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'report-sidemenu',
  templateUrl: './report-sidemenu.component.html',
  styleUrls: ['./report-sidemenu.component.css']
})
export class ReportSidemenuComponent implements OnInit {

  @Input() menuItems:any[] = [];
  @Output() onClickMenuItem= new EventEmitter();
  @Input() selectMenuItem:any= '';
  selectedMenuHeader:any = '';
  constructor() { }

  ngOnInit(): void {
  }

  onClickMenu(menuItem: any) {
    this.selectMenuItem = menuItem['key'];
   this.onClickMenuItem.emit(menuItem['key']);
  }

  onClickMenuHead(menuItem: any) {
    if(this.selectedMenuHeader === menuItem['key']) {
      this.selectedMenuHeader = '';
      this.selectMenuItem = '';
    } else {
      this.collapsePrevious();
      this.selectedMenuHeader = menuItem['key'];
      this.onClickMenu(menuItem['childs'][0]);
    }
  }

  private collapsePrevious() {
    if(this.selectedMenuHeader !== null && this.selectedMenuHeader !== undefined && this.selectedMenuHeader.length > 0) {
      let el:HTMLElement = document.getElementById(this.selectedMenuHeader+"_head");
      el.classList.add('collapsed');
      el.setAttribute('aria-expanded','false');
      document.getElementById(this.selectedMenuHeader).classList.remove('show');
    }
  }
}
