import {Component, OnInit} from '@angular/core';
import {Grid} from '../grid/grid.model';
import {environment} from '../../environments/environment';
import {Tcolumn} from '../grid/tcolumn.model';
import {defaultValue} from '../grid/defaultValue.model';
import {Validators} from '@angular/forms';
import {CommonService} from '../services/common.service';
import {BreadcrumbService} from '../Components/ctrm-breadcrumb/breadcrumb.service';
import {KeyValue} from '../grid/key-value.model';
import {MessageService} from 'primeng';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'app-pdf-rpa',
    templateUrl: './pdf-rpa.component.html',
    styleUrls: ['./pdf-rpa.component.css']
})
export class PdfRpaComponent implements OnInit {
    grid: Grid;
    columns: Map<string, Tcolumn>;
    modalState: boolean = false;
    refreshGrid: boolean = false;
    response: any;
    selectedRows: any[] = [];
    popupDisplay: boolean = false;
    displayReview: boolean = false;
    height: string = '';
    tradeid: string = '';
    tradeidList: any[] = [];
    obligationid: string = '';
    obligationidList: any[] = [];
    tradeidselected: any[] = [];
    obligationidselected: any[] = [];
    displayInvoiceForm: boolean = false;
    invoiceProFormaObject: any;


    addedFilter: any[] = ['PLANNED', 'PRICED', 'DELIVERYSTARTED', 'INVOICED'];

    constructor(public commonService: CommonService, private messageService: MessageService) {
    }

    ngOnInit(): void {
        this.getColumns();
        this.grid = new Grid(this.columns, true, true, true, true, 'Name', '/get_and_update_result_status', '', 'PDF RPA', undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, false);
        this.grid.setToolbarConfig(false, false, false, false);
        this.grid.setGridConfiguration(false, false, false, false);
        this.grid.addClickable('View');
        this.grid.iconName = 'location';
        this.grid.baseUrl = environment.service_url;
    }

    private getColumns() {

        let _this = this;
        let viewFuntion = function(value: any[]) {
            let html: any;
            html = _this.commonService.getSafeHtml('<div style="display: inline-flex">' +
                '<p style="font-size: 14px;font-weight: 600;color: #0b51c5;text-decoration: underline;cursor: pointer;">View</p></div>');
            return html;
        };
        this.columns = new Map();
        this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
        this.columns.set('Accuracy', new Tcolumn('accuracy', 'Accuracy', 'LB', 6, true, undefined, true, '', 'B', [Validators.maxLength(200)]));
        this.columns.set('Attachment_ID', new Tcolumn('Attachment_ID', 'Attachment_ID', 'LB', 1, true, undefined, true, '', 'B', [Validators.maxLength(200)]));
        this.columns.set('Sender Email', new Tcolumn('sender_mail', 'Sender Email', 'LB', 2, false, undefined, true, '', 'B', [Validators.maxLength(200)]));
        this.columns.set('Created_At', new Tcolumn('received_on', 'Created At', 'D', 7, true, undefined, true, '', 'B', [Validators.maxLength(200)]));
        this.columns.set('Execution_Time', new Tcolumn('response_time', 'Execution Time (Sec)', 'LB', 5, true, undefined, true, '', 'B', [Validators.maxLength(200)]));
        this.columns.set('View', new Tcolumn('View', 'View', 'pdfrpa-view', 4, false, undefined, true, new defaultValue('', '', undefined, undefined, false, false, false, viewFuntion), 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, null));
        this.columns.set('Document Type', new Tcolumn('document_type', 'Document Type', 'LB', 3, false, undefined, true, '', 'B', [Validators.maxLength(200)]));
        this.columns.set('Button', new Tcolumn('button', 'Generate Invoice', 'CUSTOM', 3, true, undefined, true, '', 'B'));

    }

    onRowSelectionChange(value: any) {

        if (value !== null && value !== undefined && value.length == 1) {
            this.popupDisplay = true;
            this.selectedRows = value;
        } else {
            this.popupDisplay = false;
        }
    }

    onClose() {
        this.popupDisplay = false;
        this.selectedRows = [];
        this.tradeidList = [];
        this.obligationidList = [];
        this.tradeid = '';

    }

    onSidebarClose() {
        this.selectedRows = [];
        this.displayReview = false;
        this.tradeidList = [];
        this.obligationidList = [];
        this.tradeid = '';


    }


    approve() {
        if (this.obligationidselected.length == 1) {
            this.refreshGrid = true;
            let approvedindex = this.selectedRows;
            approvedindex[0]['Status'] = 'Approved';
            this.commonService.post(environment.service_url + '/get_and_update_result_status', approvedindex).subscribe((next: any) => {
                this.popupDisplay = false;
                this.displayReview = false;
            });
            this.selectedRows = [];
            this.tradeidList = [];
            this.obligationidselected = [];
        }
    }


    reject() {
        if (this.obligationidselected.length == 1) {
            this.refreshGrid = true;
            let rejectedindex = this.selectedRows;
            rejectedindex[0]['Status'] = 'Approved';
            this.commonService.post(environment.service_url + '/get_and_update_result_status', rejectedindex).subscribe((next: any) => {
                this.popupDisplay = false;
                this.displayReview = false;
            });
            this.selectedRows = [];
            this.tradeidList = [];
            this.obligationidselected = [];
        }

    }


    review() {
        this.displayReview = true;
        this.popupDisplay = false;
        this.getTradeId();
    }

    generateDoc(rowData) {
        this.commonService.getJSONByURL(environment.service_url + '/return_response?aid=' + rowData.Attachment_ID).subscribe((response: any) => {
            this.invoiceProFormaObject = {};
            this.invoiceProFormaObject['invoiceType'] = 'Pro Forma';
            this.invoiceProFormaObject['notifyPartyName'] = '';
            this.invoiceProFormaObject['notifyPartyAddress'] = '';
            this.invoiceProFormaObject['counterparty'] = response['response']['NotifyParty'];
            this.invoiceProFormaObject['counterpartyAddress'] = response['response']['NotifyPartyAdd'];
            this.invoiceProFormaObject['commodity'] = response['response']['commodity'];
            this.invoiceProFormaObject['invoiceDate'] = '';
            this.invoiceProFormaObject['paymentDueDate'] = '';
            this.invoiceProFormaObject['contractNumber'] = 'S/D2521/8798/OLMSG/001090/0722';
            this.invoiceProFormaObject['termsOfPayment'] = 'DAP';
            this.invoiceProFormaObject['company'] = 'Reliance';
            this.invoiceProFormaObject['companyAddress'] = 'hyd';
            this.invoiceProFormaObject['blNumber'] = response['response']['blNo'];
            this.invoiceProFormaObject['blDate'] = '';
            this.invoiceProFormaObject['vesselName'] = response['response']['vessel'];
            this.invoiceProFormaObject['quantity'] = response['response']['PackageQuantity'];
            this.invoiceProFormaObject['unitPrice'] = '';
            this.invoiceProFormaObject['tolerance'] = '';
            this.invoiceProFormaObject['deliveryTerms'] = '';
            this.invoiceProFormaObject['remitTo'] = 'EMAMI AGROTECH LTD.';
            this.invoiceProFormaObject['bankName'] = 'mnbBan';
            this.invoiceProFormaObject['bankAddress'] = 'sdsfdsfs';
            this.invoiceProFormaObject['accountNumber'] = '777879776890';
            this.invoiceProFormaObject['swiftCode'] = '';
            this.invoiceProFormaObject['currency'] = 'USD';
            this.invoiceProFormaObject['fxRate'] = '';
            this.invoiceProFormaObject['paymentRefNumber'] = '';
            this.invoiceProFormaObject['paymentDate'] = '';
            this.invoiceProFormaObject['paymentAmount'] = '1200000000';
            this.invoiceProFormaObject['settlementAmount'] = '1200000000';
            this.invoiceProFormaObject['purpose'] = '';
            this.invoiceProFormaObject['traderName'] = '';
            this.invoiceProFormaObject['invoiceGeneratedBy'] = '';
            this.invoiceProFormaObject['counterpartyRefNumber'] = '';
            this.invoiceProFormaObject['settlementCurrency'] = 'USD';
            this.invoiceProFormaObject['tradeId'] = 'S/D2521/8798/OLMSG/001090/0722';
            let invoiceItemsLines = [];
            let object = {
                quantity: response['response']['PackageQuantity'],
                tradePrice: 1822,
                plannedObligationId: '',
                tradeId: 'S/D2521/8798/OLMSG/001090/0722',
                cashflowType: 'Trade',
                currency: 'USD',
                settlementAmount: '1200000000',
                narration: response['response']['commodity']
            };
            invoiceItemsLines.push(object);
            this.invoiceProFormaObject['invoiceLineItemList'] = invoiceItemsLines;

            this.displayInvoiceForm = true;
        }, (error: HttpErrorResponse) => {

        });


    }


    onClickClickable(value) {
        this.response = value['rowData'];
        this.modalState = true;
        this.popupDisplay = false;
        this.selectedRows = [];
        this.tradeidList = [];

    }

    onModalClose() {
        this.modalState = false;
        this.tradeidList = [];

    }

    getKeyList() {
        return Object.keys(this.selectedRows[0]['Response']);

    }

    getTradeId() {
        let _this = this;
        this.commonService.getJSONByURL(environment.base_url + '/api/trade/v1/getphysicaltradebyidfordeliverymonth?tenantId=' + this.commonService.getFromStorage('tenantId') + '&bldate=' + this.selectedRows[0]['Response']['blDate']).subscribe((next: any) => {

            _this.tradeidList = _this.commonService.createListFromObject(next, 'tradeId', 'tradeId', false);
        });


    }

    onSelectTradeID(value: any[]) {
        let _this = this;
        this.tradeidselected[0] = value;
        this.commonService.getJSONByURL(environment.base_url + '/api/plannedObligation/v1/getplannedobligationbytradeidandstate?tenantId=' + this.commonService.getFromStorage('tenantId') +
            '&tradeId=' + value).subscribe((next: any) => {
            _this.obligationidList = _this.commonService.createListFromObject(next, 'plannedObligationId', 'plannedObligationId', false);
            if (_this.obligationidList.length === 0) {
                _this.showToast('Successful');
            }
        });

    }

    showToast(msg, severity: string = 'success') {
        this.messageService.add({
            severity: severity,
            summary: msg
        });
    }

    onSelectObligationID(value: any[]) {
        this.obligationidselected[0] = value;
    }


    onDraftClose() {
        this.displayInvoiceForm = false;
    }

    onGenerateDone() {
        this.displayInvoiceForm = false;
    }
}
