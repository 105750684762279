import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CommonService} from '../../services/common.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ConfirmationService} from "primeng";

@Component({
  selector: 'xceler-progress-button',
  templateUrl: './xceler-progress-button.component.html',
  styleUrls: ['./xceler-progress-button.component.css']
})
export class XcelerProgressButtonComponent implements OnInit,OnChanges {

  @Input() callApiObjectFunction :Function;
  @Input() disabled:boolean = false;
  @Output() onError = new EventEmitter<HttpErrorResponse>();
  @Output() onClick = new EventEmitter();
  @Output() onLoadDone = new EventEmitter();
  @Input() isLoading: boolean = false;
  @Input() buttonIdleTitle:string = '';
  @Input() buttonLoadingTitle: string = '';
  @Input() buttonStyle: any = {margin: '25px', width: '210px', minHeight: '35px', padding: '8px 8px 8px 8px'};
  @Input() updateParamsAndClick: any = {};
  @Input() confirmMessage:string = '';
  constructor(private commonService:CommonService,private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
  }

  onButtonClick() {
    if(this.confirmMessage !== null && this.confirmMessage !== undefined && this.confirmMessage.length > 0) {
      this.confirmationService.confirm({
        message: this.confirmMessage,
        accept: () => {
          this.onclick()
        }, reject: () => {

        }
      });
    }
    else {
      this.onclick();
    }
  }



  private onclick() {
    let _this =this;
      this.isLoading = true;
      if(this.callApiObjectFunction !== null && this.callApiObjectFunction !== undefined) {
        let obj:{url:'',type:'GET',payload:{},params:{}} = this.commonService.runFunction({url:'',type:'GET',payload:{},params:this.updateParamsAndClick},this.callApiObjectFunction);
        if(obj.url !== null && obj.url !== undefined && obj.url.length > 0) {
          if(obj.type === null || obj.type === undefined || obj.type.toLowerCase() === 'get') {
            this.commonService.getJSONByURL(obj.url).subscribe((next:any) =>{
              _this.onLoadDone.emit(next);
              _this.isLoading = false;
            },error => {
              _this.onError.emit(error);
              _this.isLoading = false;
            });
          } else {
            this.commonService.post(obj.url,obj.payload).subscribe((next:any) =>{
              _this.onLoadDone.emit(next);
              _this.isLoading = false;
            },error => {
              _this.onError.emit(error);
              _this.isLoading = false;
            });
          }
        } else {
          _this.isLoading = false;
          this.onClick.emit();
        }
      } else {
        this.onClick.emit();
      }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['updateParamsAndClick'] && changes['updateParamsAndClick'].currentValue !== null && changes['updateParamsAndClick'].currentValue !== undefined && Object.keys(changes['updateParamsAndClick'].currentValue).length > 0) {
      this.onclick();
    }
  }
}
