<div class="containerSignup">
  <div class="background">
    <div class="ctrmLabel">
      <div class="animated fadeInLeft">
        <p>CTRM</p>
      </div>
    </div>
  </div>
  <div class="signupBox">
    <div class="welcomeDiv">
      <p class="welcome">Welcome to <span class="atlas">CTRM</span></p>
    </div>
    <p class="createAccountClass">Create new account</p>
    <div>
      <form (ngSubmit)="createAccount(formgroup)" [formGroup]="formgroup">
        <p-steps (activeIndexChange)="changeIndex($event)" [activeIndex]="activeIndex" [model]="items"
                 [readonly]="false"></p-steps>
        <p [ngClass]="submitted?'show':'hide'"
           class="messageText">{{message}}</p>
        <div class="formBox">
          <div *ngIf="activeIndex===0;else addinfo" class="formDiv">
            <div>
              <label class="label">Name</label>
              <div class="box">
                <input class="inputbox" formControlName="fullname" type="text">
                <i class="fas fa-file-signature iconClass"></i>
              </div>
            </div>
            <div>
              <label class="label">Email ID</label>
              <div class="box">
                <input class="inputbox" formControlName="email" type="text">
                <i class="fas fa-envelope iconClass"></i>
              </div>
            </div>
            <div>
              <label class="label">Contact Number</label>
              <div class="box">
                <input class="inputbox" formControlName="contact" type="text">
                <i class="fas fa-phone iconClass"></i>
              </div>
            </div>
            <div>
              <label class="label">Username</label>
              <div class="box">
                <input class="inputbox" formControlName="username" type="text">
                <i class="fas fa-user iconClass"></i>
              </div>
            </div>
            <div>
              <label class="label">Password</label>
              <div class="box">
                <input [type]="showPassword?'text':'password'" class="inputbox" formControlName="password">
                <i (click)="togglePasswordView(0)" [ngClass]="showPassword?'fas fa-eye-slash':'fas fa-eye'"
                   class="icon iconClass"></i>
              </div>
            </div>
            <div>
              <label class="label">Confirm Password</label>
              <div class="box">
                <input [type]="showCPassword?'text':'password'" class="inputbox" formControlName="confirmpassword">
                <i (click)="togglePasswordView(1)" [ngClass]="showCPassword?'fas fa-eye-slash':'fas fa-eye'"
                   class="icon iconClass"></i>
              </div>
            </div>
            <div>
              <label class="label">Company Documents</label>
              <div class="boxFP">
                <p-progressSpinner *ngIf="isConverting" [style]="{width: '30px', height: '30px', margin:'2px'}"
                                   animationDuration=".5s" fill="#EEEEEE" strokeWidth="4"></p-progressSpinner>
                <input (change)="handleFileInput($event)" [disabled]="isConverting"
                       id="file" type="file">
                <i class="fas fa-hashtag iconClassHashtag"></i>
              </div>
            </div>
          </div>
          <ng-template #addinfo>
            <div>
              <label class="label">Company Name</label>
              <div class="box">
                <input class="inputbox" formControlName="companyname" type="text">
                <i class="fas fa-building" style="padding: 5px"></i>
              </div>
            </div>
            <div>
              <label class="label">Company/Account Alias</label>
              <div class="box">
                <input class="inputbox" formControlName="companynamealias" type="text">
                <i class="fas fa-building" style="padding: 5px"></i>
              </div>
            </div>
            <div>
              <label class="label">Country</label>
              <div class="box">

                <ctrm-dropdown (onChangeValue)="updateCUI1($event)" [field]="commonService.getCountryList()"
                               [minWidth]="'370px'"
                               [selectstyle]="{'font-weight': 'bold','font-size': '12px','min-width':'300px'}"
                               [showBorder]="false"
                               formControlName="country"></ctrm-dropdown>
                <i class="fas fa-globe" style="padding: 5px"></i>
              </div>
            </div>
            <div>
              <label class="label">Default Currency</label>
              <div class="box">
                <ctrm-dropdown (onChangeValue)="updateCUI($event)" [field]="commonService.getCurrencyList()"
                               [minWidth]="'370px'"
                               [selectstyle]="{'font-weight': 'bold','font-size': '12px','min-width':'300px'}"
                               [showBorder]="false"
                               formControlName="currency"></ctrm-dropdown>
                <i class="fas fa-dollar-sign" style="padding: 5px"></i>
              </div>
            </div>
            <div>
              <label class="label">TimeZone</label>
              <div class="box">
                <ctrm-dropdown (onChangeValue)="updateUI($event)" [field]="commonService.getTimeZoneList()"
                               [minWidth]="'370px'"
                               [selectstyle]="{'font-weight': 'bold','font-size': '12px','min-width':'300px'}"
                               [showBorder]="false"
                               formControlName="timezone"></ctrm-dropdown>
                <i class="fas fa-clock" style="padding: 5px;"></i>
              </div>
            </div>
            <div [hidden]="!isDayLight" style="padding: 10px">
              <p-checkbox [binary]="true" [formControl]="formgroup.controls['dst']" [label]="'Enable day light saving'"
                          [ngStyle]="{'font-weight': 'bold','font-size': '14px'}"></p-checkbox>
            </div>
            <div>
              <label class="label">Company Registration Number</label>
              <div class="box">
                <input class="inputbox" formControlName="companyRegistrationNumber" type="text">
                <i class="fas fa-hashtag" style="padding: 5px;"></i>
              </div>
            </div>

            <div>
              <label class="label">Referral Code</label>
              <div class="box">
                <input class="inputbox" formControlName="refcode" type="text">
                <i class="fas fa-lock" style="padding: 5px"></i>
              </div>
            </div>

          </ng-template>
        </div>
        <div class="buttonBox">
          <div *ngIf="activeIndex===0;else another">
            <button class="button fifth" type="submitt">Next</button>
          </div>
          <ng-template #another>
            <button [hidden]="isCreating" class="button fifth" type="submit">Create new
              account
            </button>
            <div [hidden]="!isCreating" class="button sixth">
              <div class="loadingBox">
                <div class="loader"></div>
                <p>Creating...</p>
              </div>

            </div>
          </ng-template>
          <p class="alreadyHaveMessage">Already have
            an account !</p>
          <button (click)="openlogin()" class="button fifth" type="button fifth">Continue to
            login
          </button>
        </div>
      </form>
    </div>
  </div>
</div>


<div class="modal" id="myModal">

  <!-- Modal content -->
  <div class="modal-content">
    <i class="fas fa-spinner fa-pulse fa-4x" style="font-size: 50px"></i>
    <p style="font-size: 24px;margin-top: 15px">Account is being created</p>
    <p style="font-size: 24px;margin-top: 15px">Redirecting to Sign In page.</p>
    <p style="font-size: 22px">Please Wait...</p>
  </div>

</div>



