<p-dialog [(visible)]="display"
          (onHide)="onCancel()"
          [closeOnEscape]="false"
          [contentStyle]="{ overflow: 'scroll', overflowX:'hidden'}"
          [draggable]="false"
          [maximizable]="false" [modal]="true" [showHeader]="false"
          [style]="{width: '30%',padding:'0px',background:'#ffffff'}">

    <div class="dialogHeading">
        <div style="display: inline-flex;width: 100%;border-bottom: 1px solid rgba(211,211,211,0.6)">
            <p class="dialogLabel">Ticker Configuration</p>
            <i (click)="onCancel()" class="material-icons closable" style="float: right;align-self: center">clear</i>
        </div>
        <div class="sectionBoxMain">
            <div>
                <div class="row fieldCont">
                    <div class="col-md-6 fieldBox">
                        <p-dropdown [disabled]="false"
                                    [id]="'commodity'"
                                    (ngModelChange)="onChangeCommodity($event)"
                                    [options]="commodityList"
                                    [(ngModel)]="commodity"
                                    appendTo="body" [placeholder]="'Select Commodity'"
                                    tooltipEvent="focus" tooltipPosition="bottom"
                                    tooltipZIndex="1000">
                        </p-dropdown>
                    </div>

                    <div class="col-md-6 fieldBox">
                        <p-autoComplete appendTo="body" [placeholder]="'Search Ticker'"
                                        [id]="'ticker'"
                                        [dropdown]="true"
                                        [suggestions]="suggestionlistTicker"
                                        (completeMethod)="searchForTicker($event)"
                                        [(ngModel)]="ticker" baseZIndex="9999"></p-autoComplete>
                    </div>
                </div>
            </div>
        </div>
        <p-footer>
            <div style=" border-top: 2px solid #f0e5ea;">
                <div style="float: right;">
                    <button (click)="onCancel()" class="cancelBtn">Cancel</button>
                    <button (click)="onTickerSave()" [disabled]="(ticker === undefined || ticker === null || ticker.length === 0 ||
                commodity === undefined || commodity === null || commodity.length === 0 )" class="save">Confirm
                    </button>
                </div>
            </div>

        </p-footer>
    </div>
</p-dialog>

<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>

