import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {CommonService} from '../../../services/common.service';
import {environment} from '../../../../environments/environment';
import {MasterService} from '../../../masters/services/MasterService';
import {KeyValue} from '../../../grid/key-value.model';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {HttpErrorResponse} from '@angular/common/http';
import {ContextMenu, MenuItem, MessageService} from 'primeng';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormControlServiceService} from '../../../services/form-control-service.service';
import {Tcolumn} from "../../../grid/tcolumn.model";

@Component({
    selector: 'amend-modify-form',
    templateUrl: './amend-modify-form.component.html',
    styleUrls: ['./amend-modify-form.component.css']
})
export class AmendModifyFormComponent implements OnInit {

    @Input() dialogDisplay: boolean = false;
    @Input() declarePort:boolean = false;
    @Output() onClose = new EventEmitter();
    @Input() planObligationValue: any;
    @Input() ammendRowdata: any;
    showTrade: boolean = true;
    showCommodity: boolean = true;
    showDelivery: boolean = true;
    showLc: boolean = true;
    showProvisional: boolean = true;
    amendConfirmDialog: boolean = false;
    isDisable: boolean = false;
    showForAmend: boolean = false;
    includedCharacters: any[] = ['_', '/', ' ','.'];
    profitcenterList: any[] = [];
    brandList: any[] = [];
    gradeList: any[] = [];
    incotermList: any[] = [];
    paymentTermList: any[] = [];
    settlementCurrencyList: any[] = [];
    seasonList: any[] = [];
    loadLocationList: any[] = [];
    dischangeLoacationList: any[] = [];
    provisionalPriceList: any[] = [];
    cropOriginList: any[] = [];
    counterpartyList: any[] = [];
    commodityList: any[] = [];
    provisionalPriceCurrenyType: any[] = [];
    brokerList: any[] = [];
    bsConfig: Partial<BsDatepickerConfig>;
    amendForm: FormGroup;
    contextMenuList: MenuItem[] = [];
    UomList: any[] = [];
    loadingDialogDisplay: any;
    disableSaveButton: boolean = true;
    //Addendum Document
    generateAddendumDocuement: boolean = false;
    documentTemplateList:any[] = [];
    documentTemplate:any[] = [];
    //LC Details
    refreshAddNewRow: boolean = true;
    newRows: any[] = [];
    refreshFormGroup: boolean = false;
    lcDetailsCols: Tcolumn[] = [];
    lcValue: any[] = [];
    formGroup: FormGroup;
    lcDisplayColumns: Tcolumn[] = [];
    lcDisplayData: any[];

    documentFormatList:any[] = [];
    documentFormat:String='';
    allowFormatEnable:boolean = false;
      minRange:any=-100;
      maxRange:any=100;



    private formControlService: FormControlServiceService = new FormControlServiceService();

    constructor(public commonService: CommonService,
                private masterCommonService: MasterService,
                private messageService: MessageService) {
    }

    ngOnInit(): void {
        this.initForm();
        this.bsConfig = new BsDatepickerConfig();
        this.bsConfig.dateInputFormat = environment.dateFormat.toUpperCase();
        this.bsConfig.adaptivePosition = true;
        this.bsConfig.useUtc = false;
        this.contextMenuList.push({label: 'Send Amendment for approval'});
        this.contextMenuList.push({label: 'Withdraw/Recall from approval'});
        this.contextMenuList.push({label: 'Approve Amendment'});
        this.contextMenuList.push({label: 'Reject Amendment'});
        this.continueLoad();
        this.lcDisplayGrid();
        this.lcDisplayData = this.planObligationValue['lcDetails'];

        this.commonService.getJSONByURL(environment.base_url + '/api/unitofmeasurement/v1/getuomdatabyuomtype?tenantId='
            +this.commonService.getFromStorage('tenantId') + '&uomType=' + 'mass').subscribe((next: any) => {
            this.UomList=this.commonService.createListFromObject(next,'name','name',true);
            this.commonService.getJSONByURL(environment.base_url + '/api/unitofmeasurement/v1/getuomdatabyuomtype?tenantId='
                +this.commonService.getFromStorage('tenantId') + '&uomType=' + 'volume').subscribe((next: any) => {
                let x=this.commonService.createListFromObject(next,'name','name',true);
                x.forEach(e=>{ if(e.label!='Select'){ this.UomList.push(e); }  });
            });
        });

        if(this.declarePort){
            this.openAmendForm(this.declarePort);
            this.formControlService.disable('brokerReference');
            this.formControlService.disable('tradeDateTime');
            this.formControlService.disable('plannedQuantity');
            this.formControlService.disable('quantityTermsClause');
            this.formControlService.disable('qualityTermsClause');
            this.formControlService.disable('deliveryStartDate');
            this.formControlService.disable('deliveryEndDate');
            this.formControlService.disable('deliveryTermClause');
            this.formControlService.disable('tradePrice');
            this.formControlService.disable('provisionalPrice');
            this.formControlService.disable('paymentTermsClause');
            this.formControlService.disable('provisionalPricePercentage');
            this.formControlService.disable('fxRate');
            this.formControlService.disable('premiunCost');
            this.formControlService.disable('discountCost');
            this.formControlService.disable('pricingTermsClause');
            this.formControlService.disable('remarks');
            this.formControlService.disable('comments');
        }

    }


    initForm() {
        let temp:FormGroup = new FormGroup({
            min:new FormControl("2"),
            max:new FormControl("2")
        });
        this.amendForm = this.formControlService
            .addControl('tradeDateTime', this.getValidValue('tradeDateTime', true), [])
            .addControl('profitcenter', this.getValidValue('profitcenter'), [])
            .addControl('counterparty', this.getValidValue('counterparty'), [])
            .addControl('incoterm', this.getValidValue('incoterm'), [])
            .addControl('broker', this.getValidValue('broker'), [])
            .addControl('commodity', this.getValidValue('commodity'), [])
            .addControl('plannedQuantity', this.getValidValue('plannedQuantity'), [])
            .addControl('brand', this.getValidValue('brand'), [])
            .addControl('grade', this.getValidValue('grade'), [])
            .addControl('season', this.getValidValue('season'), [])
            .addControl('cropOrigin', this.getValidValue('cropOrigin'), [])
            .addControl('loadLocation', this.getValidValue('loadLocation'), [])
            .addControl('unloadLocation', this.getValidValue('unloadLocation'), [])
            .addControl('deliveryStartDate', this.getValidValue('deliveryStartDate', true), [])
            .addControl('deliveryEndDate', this.getValidValue('deliveryEndDate', true), [])
            .addControl('tradePrice', this.getValidValue('tradePrice'), [])
            .addControl('provisionalPriceType', this.getValidValue('provisionalPriceType'), [])
            .addControl('provisionalPrice', this.getValidValue('provisionalPrice'), [])
            .addControl('provisionalPricePercentage', this.getValidValue('provisionalPricePercentage'), [])
            .addControl('tradeSettlementCurrency', this.getValidValue('tradeSettlementCurrency'), [])
            .addControl('fxRate', this.getValidValue('fxRate'), [])
            .addControl('paymentTerm', this.getValidValue('paymentTerm'), [])
            .addControl('discountCost', this.getValidValue('discountCost'), [])
            .addControl('premiunCost', this.getValidValue('premiunCost'), [])
            .addControl('remarks', this.getValidValue('remarks'),[])
            .addControl('deliveryTermClause', this.getValidValue('deliveryTermClause'),[])
            .addControl('paymentTermsClause', this.getValidValue('paymentTermsClause'),[])
            .addControl('quantityTermsClause', this.getValidValue('quantityTermsClause'),[])
            .addControl('qualityTermsClause', this.getValidValue('qualityTermsClause'),[])
            .addControl('provisionalPriceCurrency', this.getValidValue('provisionalPriceCurrency'),[])
            .addControl('provisionalPriceUom', this.getValidValue('provisionalPriceUom'),[])
            .addControl('pricingTermsClause', this.getValidValue('pricingTermsClause'),[])
            .addControl('brokerReference', this.getValidValue('brokerReference'),[])
            .addControl('comments', this.getValidValue('comments'),[])
            .addControl('quantityUOM', this.getValidValue('quantityUOM'),[])
            .addControl('tradePriceUom', this.getValidValue('tradePriceUom'),[])
            .addControl('tradePriceCurrency', this.getValidValue('tradePriceCurrency'),[])
            .build();
            let settlementToleranceValues = this.getValidValue('settlementToleranceValue')
            if(settlementToleranceValues!= null){
                temp.patchValue(JSON.parse(settlementToleranceValues))
            }
            this.amendForm.addControl('settlementToleranceValue',temp);

    }

    private getValidValue(key: any, isDate: boolean = false) {
        if (this.planObligationValue !== undefined && this.planObligationValue !== null) {
            if (isDate) {
                let value = this.planObligationValue[key];
                if (value !== undefined && value !== null && value.length > 0) {
                    return this.commonService.convertUTCtoDate(value);
                }
                return '';
            }
            return this.planObligationValue[key];
        }
        return '';
    }

    onFormCancel() {
        this.onClose.emit();
    }

    tradeToggle() {
        this.showTrade = !this.showTrade;
    }

    commodityToggle() {
        this.showCommodity = !this.showCommodity;
    }

    DeliveryToggle() {
        this.showDelivery = !this.showDelivery;
    }

    lcToggle() {
        this.showLc = !this.showLc;
    }

    ProvisionalToggle() {
        this.showProvisional = !this.showProvisional;
    }

    openDialogBox() {
        if(!this.ammendRowdata.obligationState.ACTUALIZED){
            this.amendConfirmDialog = true;
        }else{
            this.showToast(`Unable to ammend. Planned Obligation with Id:${this.ammendRowdata.plannedObligationId} is already Actualized.`,'error' );
        }
    }

    lcDisplayGrid() {
        this.lcDisplayColumns.push(new Tcolumn('lcDate', 'LC Date','D',0,false,undefined));
        this.lcDisplayColumns.push(new Tcolumn('lcNumber', 'LC Number','T',1,false,undefined));
        this.lcDisplayColumns.push(new Tcolumn('lcBank', 'LC Issuing Bank','LB',2,false,undefined));
    }

    onCloseDialog() {
        this.amendConfirmDialog = false;
        this.showForAmend = false;
        this.showTrade = true;
        this.showCommodity = true;
        this.showDelivery = true;
        this.showLc = true;
        this.showProvisional = true;
        this.isDisable = false;
        this.formControlService.reset();
    }

    openAmendForm(declarePort:boolean = false) {
        this.showTrade = false;
        this.showCommodity = false;
        this.showDelivery = false;
        this.showLc = false;
        this.showProvisional = false;
        this.isDisable = true;
        this.declarePort = declarePort;
        this.amendConfirmDialog = false;
        this.showForAmend = true;
        if (this.planObligationValue['priceType'] === "Fixed") {
            this.formControlService.disable('provisionalPriceType');
            this.formControlService.disable('provisionalPrice');
            this.formControlService.disable('provisionalPriceUom');
            this.formControlService.disable('provisionalPriceCurrency');
            this.formControlService.disable('provisionalPricePercentage');
        } else {
            this.formControlService.disable('tradePrice');
            this.formControlService.enable('provisionalPriceType');
            if(this.planObligationValue['provisionalPriceType'] === "Fixed") {
                this.formControlService.enable('provisionalPrice');
                this.formControlService.enable('provisionalPriceUom');
                this.formControlService.enable('provisionalPriceCurrency');
                this.formControlService.disable('provisionalPricePercentage');
            }
        }
        if(this.amendForm['value']['remarks'] !== "" && this.amendForm['value']['remarks'] !== null && this.amendForm['value']['remarks'] !== undefined) {
            this.disableSaveButton = false;
        }
        this.lcDetailsGrid();
        this.lcValue = [];
        this.lcValue = this.planObligationValue['lcDetails'];
    }

    onChangeProvisionalPrice(value: any) {
        if (value === "Fixed") {
            this.formControlService.disable('provisionalPricePercentage');
            this.formControlService.enable('provisionalPrice');
            this.formControlService.enable('provisionalPriceUom');
            this.formControlService.enable('provisionalPriceCurrency');
        } else {
            this.formControlService.enable('provisionalPricePercentage');
            this.formControlService.disable('provisionalPrice');
            this.formControlService.disable('provisionalPriceUom');
            this.formControlService.disable('provisionalPriceCurrency');
        }
    }

    continueLoad() {
        let _this = this;
        this.commonService.getLoadData('commodity', ['config', 'config', 'config','config','config','config','config','config','data'],
            ['profitcenter', 'brand', 'cropseason','incoterm','grade','paymentterm','location','commodity',
                [{key: 'name', value: 'provisional_price_type_ind'}]]).subscribe((next: any) => {
            _this.brandList = _this.masterCommonService.getListFromArray(next['brand'], false, true);
            _this.gradeList = _this.masterCommonService.getListFromArray(next['grade'], false, true);
            _this.incotermList = _this.masterCommonService.getListFromArray(next['incoterm'], false, true);
            _this.paymentTermList = _this.masterCommonService.getListFromArray(next['paymentterm'], false, true);
            _this.settlementCurrencyList = this.commonService.getCurrencyList();
            _this.seasonList = _this.masterCommonService.getListFromArray(next['cropseason'], false, true);
            _this.provisionalPriceList = _this.masterCommonService.getListFromArray(next['provisional_price_type_ind'], false, true);
            _this.cropOriginList = _this.masterCommonService.getListFromArray(next['location'], false, true);
            _this.commodityList = _this.masterCommonService.getListFromArray(next['commodity'], false, true);
            _this.provisionalPriceCurrenyType = _this.commonService.getCurrencyList();
        });
        this.commonService.getJSONByURL(environment.base_url_new + '/api-iam/api/userProfile/v1/getUserDetails?userId=' + this.commonService.getFromStorage('userName') + '&tenantId=' + this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
            _this.counterpartyList = _this.getDropDownValues(next['userInfoData']['Counterparty']);
            _this.profitcenterList = _this.getDropDownValues(next['userInfoData']['Profit Center']);
        });
        this.commonService.getJSONByURL(environment.base_url + '/api/location/v1/getallbylocationtype?tenantId=' +
            this.commonService.getFromStorage('tenantId') + '&locationType=Port').subscribe((next: any[]) => {
            _this.dischangeLoacationList = _this.commonService.createListFromObject(next, 'name', 'name', true);
            _this.loadLocationList = _this.commonService.createListFromObject(next, 'name', 'name', true);
            let loadLocationValue = _this.formControlService.getValue('loadLocation');
            if (loadLocationValue.includes(',')) {
                _this.loadLocationList.push(new KeyValue(loadLocationValue, loadLocationValue))
            }
            let dischargeLocationValue = _this.formControlService.getValue('unloadLocation');
            if (dischargeLocationValue.includes(',')) {
                _this.dischangeLoacationList.push(new KeyValue(dischargeLocationValue, dischargeLocationValue));
            }
        });
        this.commonService.getJSONByURL(environment.base_url + '/api/counterparty/v1/getcounterpartybycounterpartytypecode?' +
            'counterpartytype='+ ['Bank','Floor Broker','Clearing Broker','Cash Broker','Physical Broker'] + '&tenantId=' + this.commonService.getTenantId()).subscribe((data:any) => {
            _this.brokerList = _this.commonService.getListFromArray(data, 'name','name',false,true);
        })
    }

    getDropDownValues(property:any){
        let array = [{label:'Select', value:''}];
        if (property !== undefined && property !== null){
            property.forEach(data => {
                array.push({label:data['masterTypeValue'], value:data['masterTypeValue']});
            });
        }
        return array;
    }

    saveAmendDetails() {
        let _this = this;
        let payload:{[key:string]:any}  = this.amendForm.value;
        payload['plannedObligationId'] = this.planObligationValue['plannedObligationId'];
        payload['lcDetails'] = this.lcValue;
        let mode = "ammend";
        if(!this.declarePort){
            payload['deliveryStartDate'] = this.commonService.fixDateForDayIssue(this.amendForm.value['deliveryStartDate'])
            payload['deliveryEndDate'] = this.commonService.fixDateForDayIssue(this.amendForm.value['deliveryEndDate'])
            payload['tradeDateTime'] = this.commonService.fixDateForDayIssue(this.amendForm.value['tradeDateTime'])
        }else{
            mode = "portDeclare";
            payload['deliveryStartDate']=this.planObligationValue['deliveryStartDate'];
            payload['deliveryEndDate']=this.planObligationValue['deliveryEndDate'];
            payload['tradeDateTime']=this.planObligationValue['tradeDateTime'];
            payload['plannedQuantity']=this.planObligationValue['plannedQuantity'];
            payload['tradePrice']=this.planObligationValue['tradePrice'];
            payload['fxRate']=this.planObligationValue['fxRate'];
            payload['brokerReference']=this.planObligationValue['brokerReference'];
            payload['provisionalPrice']=this.planObligationValue['provisionalPrice'];
            payload['qualityTermsClause']=this.planObligationValue['qualityTermsClause'];
            payload['paymentTermsClause']=this.planObligationValue['paymentTermsClause'];
            payload['quantityTermsClause']=this.planObligationValue['quantityTermsClause'];
            payload['provisionalPricePercentage']=this.planObligationValue['provisionalPricePercentage'];
            payload['settlementToleranceValue'] = JSON.stringify(this.amendForm.value['settlementToleranceValue'])
        }
        this.commonService.post(environment.base_url + '/api/plannedObligation/v1/updateplanobligationonamend?tenantId=' + this.commonService.getFromStorage('tenantId')+"&mode="+mode, payload).subscribe((next: any) => {
            this.showForAmend = false;
            this.showTrade = true;
            this.showCommodity = true;
            this.showDelivery = true;
            this.showProvisional = true;
            this.isDisable = false;
            this.planObligationValue = next;
            this.initForm();
            _this.showToast('Amend/Modify Successfull');
        }, (error: HttpErrorResponse) => {
            _this.showToast(_this.commonService.getHttpErrorMessage(error, 'Amend Form'), 'error');
        });

    }

    showToast(summary, severity: string = 'info') {
        this.messageService.add({
            severity: severity,
            summary: summary
        });
    }

    @HostListener('window:resize', [])
    public onResize() {
        this.calculateHeight();
    }

    calculateHeight() {
        if (document.getElementById('sectionForm') !== null) {
            let viewportHeight = window.innerHeight;
            let offsetTop = document.getElementById('sectionForm')['offsetTop'];
            let height = viewportHeight - offsetTop - 100;
            document.getElementById('sectionForm').style.maxHeight = height + 'px';
            document.getElementById('sectionForm').style.minHeight = height + 'px';
        }
    }

    getProvisionalPriceValue() {
        let provisionalPrice;
        if(this.planObligationValue['provisionalPrice'] !== undefined && this.planObligationValue['provisionalPrice'] !== null && this.planObligationValue['provisionalPrice'] !== "") {
            if(this.planObligationValue['provisionalPriceType'] === 'Fixed') {
                provisionalPrice = this.planObligationValue['provisionalPrice'] +  ' ' + this.planObligationValue['provisionalPriceCurrency']+  '/' + this.planObligationValue['provisionalPriceUom'];
            }
            else {
                provisionalPrice = this.planObligationValue['provisionalPricePercentage']!==null && this.planObligationValue['provisionalPricePercentage']  !== undefined ? this.planObligationValue['provisionalPricePercentage']:0 +  ' ' + '%';
            }
        } else {
            provisionalPrice = '-';
        }
        return provisionalPrice;
    }

    onChangeRemarks() {
        if(this.amendForm['value']['remarks'] === "" || this.amendForm['value']['remarks'] === null || this.amendForm['value']['remarks'] === undefined) {
             this.disableSaveButton = true;
        } else {
            this.disableSaveButton = false;
        }
    }

    onChangeDeliveryTermClause(){
        if(this.amendForm['value']['deliveryTermClause'] === "" || this.amendForm['value']['deliveryTermClause'] === null || this.amendForm['value']['deliveryTermClause'] === undefined) {
            this.disableSaveButton = true;
        } else {
            this.disableSaveButton = false;
        }
    }

    closeModal() {
        this.documentTemplate=[];
        this.generateAddendumDocuement = false;
    }

    disableGenerate() {
        if(this.documentTemplate.length>0){
            return false;
        } else {
            return true;
        }
    }

    openGenerateDocumentBox() {
        this.getDocumenttypeList();
        this.generateAddendumDocuement = true;
    }

    getDocumenttypeList() {
        let _this = this;
        _this.commonService.getJSONByURL(environment.base_url_new + '/api-bm/api/doctemplateservice/v1/getdocumentbybusinessname?tenantId='+this.commonService.getFromStorage('tenantId') + '&businessApplicationName=Addendum Document').subscribe((next: any) => {
            _this.documentTemplateList = _this.commonService.createListFromObject(next, 'docTemplateName', 'docTemplateName', false);
        });
    }

    generateDocument() {
        let _this = this;
        this.generateAddendumDocuement = false;
        this.loadingDialogDisplay = true;
        this.commonService.getJSONByObject(environment.base_url + '/api/plannedObligation/v1/generateaddendumdocuement?tenantId='+
            this.commonService.getTenantId()+ "&outputFormat="+ this.documentFormat + '&documentType=' +this.documentTemplate,this.planObligationValue).subscribe((next:any) =>{
            _this.loadingDialogDisplay = false;
            window.open(environment.base_url + next['status']);
        },(error) => {
            _this.loadingDialogDisplay = false;
            _this.messageService.add({severity:'error', summary:error['error']['status'], detail:'Failed to generate document'});
        });
    }

    //LC Details
    onNewRowAddComplete() {
        this.refreshAddNewRow = false;
        this.newRows = [];
    }

    onRefreshFromGroupComplete() {
        this.refreshFormGroup = false;
    }

    onFormGroupCreated(form: FormGroup) {
        this.amendForm = this.formControlService.addFormGroupControl('lcDetails', form).build();
    }

    lcDetailsGrid() {
        this.lcDetailsCols = [];
        this.lcDetailsCols.push(new Tcolumn('lcDate', 'LC Date', 'D', 0, true, undefined, true, '', 'B', [Validators.required]));
        this.lcDetailsCols.push(new Tcolumn('lcNumber', 'LC Number', 'T', 1, true, undefined, true));
        this.lcDetailsCols.push(new Tcolumn('lcBank', 'LC Issuing Bank', 'T', 2, true, undefined, true, '', 'B', [Validators.required]));
    }

    onChangeCounterParty(){
        this.commonService.getJSONByURL(environment.base_url + '/api/counterpartypaymentterm/v1/getpaymenttermbycunterpartyname?tenantId='+this.commonService.getFromStorage('tenantId')+'&name='+this.amendForm['value']['counterparty']).subscribe((next:any)=>{
            if(next !== undefined && next !== null && next.length > 0){
                this.amendForm.controls['paymentTerm'].setValue(next[0].approvedPaymentTermName);
            }
        })
    }

    onChangePaymentTerm(){
        this.commonService.getJSONByURL(environment.base_url + '/api/paymentterm/v1/getpaymenttermbyname?tenantId='+this.commonService.getFromStorage('tenantId')+'&paymentTerm='+this.amendForm['value']['paymentTerm']).subscribe((next:any)=>{
            if(next !== undefined && next !== null && next.length > 0){
                this.amendForm.controls['paymentTermsClause'].setValue(next[0].description);
            }
        })
    }

    onChangeCommodity(){
        this.commonService.getJSONByURL(environment.base_url + '/api/commodity/v1/getcommoditydefaultproperties?tenantId='+this.commonService.getFromStorage('tenantId')+'&commodityName='+this.amendForm['value']['commodity']).subscribe((next:any)=>{
            if(next !== undefined && next !== null){
                this.brandList = this.commonService.addListOTT([])
                this.gradeList = this.commonService.addListOTT([])
                this.seasonList = this.commonService.addListOTT([])
                this.cropOriginList = this.commonService.addListOTT([])
                if(next.CommodityBrand.length > 0){
                    this.amendForm.controls['brand'].setValue(next.CommodityBrand[0].value);

                }else{
                    this.amendForm.controls['brand'].setValue('');
                }
                if(next.CommodityGrade.length > 0){
                    this.amendForm.controls['grade'].setValue(next.CommodityGrade[0].value);

                }else{
                    this.amendForm.controls['grade'].setValue('');
                }
                if(next.CommoditySeason.length > 0){
                    this.amendForm.controls['season'].setValue(next.CommoditySeason[0].value);

                }else{
                    this.amendForm.controls['season'].setValue('');
                }
                if(next.CommodityOrigin.length > 0){
                    this.amendForm.controls['cropOrigin'].setValue(next.CommodityOrigin[0].value);

                }else{
                    this.amendForm.controls['cropOrigin'].setValue('');
                }
                this.brandList = this.commonService.addListOTT(next.CommodityBrand)
                this.gradeList = this.commonService.addListOTT(next.CommodityGrade)
                this.seasonList = this.commonService.addListOTT(next.CommoditySeason)
                this.cropOriginList = this.commonService.addListOTT(next.CommodityOrigin)
            }
        })
    }
    getDocFormatList(){
        if(this.documentTemplate !== null && this.documentTemplate !== undefined && this.documentTemplate.length > 0){
            let _this = this;
            let payload = [];
            payload.push({fieldName:"tenantId",condition:"equals",value:this.commonService.getTenantId()});
            payload.push({fieldName:"docTemplateName",condition:"equals",value:this.documentTemplate});
            payload.push({fieldName:"status",condition:"equals",value:true});
            _this.commonService.post(environment.base_url_new + '/api-bm/api/doctemplateservice/v1/getDocumentTemplateFormat?tenantId='+this.commonService.getFromStorage('tenantId'),payload).subscribe((next:any)=>{
                const DocFormat = _this.commonService.createListFromObject(next, 'allowedOutputFormats', 'allowedOutputFormats', false);
                if(DocFormat.length > 0){
                let tempFormat=JSON.parse(DocFormat[0].value);
                _this.allowFormatEnable = tempFormat.selected.length < 2;
                this.documentFormatList = [];
                tempFormat['selected'].forEach(e=>{
                    this.documentFormatList.push({label:e,value:e})
                })
                this.documentFormat=tempFormat['default']
                }
            });
        }

    }
}

