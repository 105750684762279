import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'ticker-info-box',
  templateUrl: './ticker-info-box.component.html',
  styleUrls: ['./ticker-info-box.component.css']
})
export class TickerInfoBoxComponent implements OnInit {

  @Input() infoDisplay: boolean;
  @Output() onClose = new EventEmitter();
  @Input() tickerHeader:any;
  @Input() tickerInfoValue:any;
  @Input() commodityInfoValue:any;
  @Input() graphValue:any;

  constructor() { }

  ngOnInit(): void {
  }

  onCancel() {
    this.onClose.emit();
  }
}
