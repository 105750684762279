import {Component, OnInit} from '@angular/core';
import {Tcolumn} from '../../grid/tcolumn.model';
import {Grid} from '../../grid/grid.model';
import {Validators} from '@angular/forms';
import {MasterService} from '../services/MasterService';
import {environment} from "../../../environments/environment";
import {CommonService} from "../../services/common.service";
import {ChildAction} from "../../grid/ChildAction.model";
import {Tab} from "../../tab-grid/Tab.model";
import {Action} from "../../grid/actions.model";
import {repos} from "../../services/common/entities";
import {defaultValue} from "../../grid/defaultValue.model";

@Component({
  selector: 'app-brandmaster',
  templateUrl: './brandmaster.component.html'
})
export class BrandmasterComponent implements OnInit {
  columns: Map<string, Tcolumn>;
  grid: Grid;
  tabs: Tab[] = [];
  currentHref: string = '';
  loadDataList: any[];

  constructor(public commonService: CommonService, private masterCommonService: MasterService) {
  }


  ngOnInit() {
    this.loadGrid();
  }

  public async loadGrid(){
    this.loadDataList = await this.commonService.getLoadDataAsync('brand',['data','data'],[[{key:'name', value:'quality_parameter_ind'}],[{key:'name', value:'quality_spec_unit_ind'}]]).then( (next:any) => {
      return next;
    });
    this.getColumns();
    let copy = new ChildAction('brandUuid');
    copy.addRepo('xceler_configservice_brandqualityspec', repos.brandQualitySpec);
    copy.setPrimaryKeyCode('uuid');
    this.tabs.push(this.getBrandQualitySpec());
    this.grid = new Grid(this.columns, true, true, true, true, 'name', '/api/brand/v1/getall?tenantId='+this.commonService.getFromStorage('tenantId'), '', 'Brand Master', '');
    this.grid.setSaveUrl(environment.base_url + '/api/brand/v1/save');
    this.grid.setUpdateUrl(environment.base_url + '/api/brand/v1/update');
    this.grid.hrefUrl = environment.base_url + '/api/brand/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
    this.grid.importUrl = environment.base_url + '/api/brand/v1/import';
    this.grid.setChildCopyDelete(copy);
    this.grid.iconName='brandMaster';
    this.grid.setTabs(this.tabs);
    this.grid.addChildTabs(this.getBrandQualitySpec());
  }

  public getColumns() {
    this.columns = new Map();
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('code', new Tcolumn('code', 'Brand Code', 'T', 1, false, undefined, true
        , new defaultValue('',undefined,['-','_'],undefined,false,true,false,undefined,undefined,true), 'B', [Validators.required, Validators.maxLength(8)], undefined, undefined, undefined, undefined, [], undefined, undefined, true));
    this.columns.set('name', new Tcolumn('name', 'Brand Name', 'T', 2, false, undefined, true, '', 'B', [Validators.required, Validators.maxLength(100)], undefined, undefined, undefined, undefined, [], undefined, undefined, true));
    this.columns.set('description', new Tcolumn('description', 'Brand Description', 'TA', 3, true, undefined, true, '', 'B', [Validators.maxLength(200)]));
    this.columns.set('status', new Tcolumn('status', 'Status', 'B', 4, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));
  }

  private getBrandQualitySpec() {
    let columns: Map<string, Tcolumn> = new Map();
    let minVal = function minVali(value: any[] = []) {
      let current = [Validators.min(parseInt(value[0]))];
      return current;
    };
    let maxVal = function minVali(value: any[] = []) {
      let current = [Validators.max(parseInt(value[0]))];
      return current;
    };
    let action = new Action();
    action.addFieldToUpdate('max', undefined, minVal, undefined, undefined, false, 'validators');
    action.addFieldToUpdate('min', undefined, maxVal, undefined, undefined, false, 'validators')
    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    columns.set('name', new Tcolumn('name', 'Quality Parameter Name', 'L', 2, false, this.masterCommonService.getListFromArray(this.loadDataList['quality_parameter_ind']), true, '', 'B', [Validators.required], undefined, 1, undefined, undefined, undefined));
    columns.set('min', new Tcolumn('min', 'Min', 'N', 5, true, undefined, true, 0, 'B', [Validators.min(0)], undefined, 1, undefined, undefined, undefined, action));
    columns.set('max', new Tcolumn('max', 'Max', 'N', 6, true, undefined, true, '', 'B', [Validators.min(0)],undefined, 1, undefined, undefined, undefined, action));
    columns.set('typical', new Tcolumn('typical', 'Typical', 'T', 7, true, undefined, true, '', 'B', [Validators.min(0), Validators.maxLength(50)]));
    columns.set('basis', new Tcolumn('basis', 'Basis', 'T', 8, true, undefined, true, '', 'B', [Validators.min(0),Validators.maxLength(50)]));
    columns.set('unit', new Tcolumn('unit', 'Unit', 'EL', 9, true,  this.masterCommonService.getListFromArray(this.loadDataList['quality_spec_unit_ind']), true, '', 'B', [Validators.required]));
    columns.set('status', new Tcolumn('status', 'Status', 'B', 10, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));
    let grid = new Grid(columns, true, true, true, true, 'name', '/api/branddetails/v1/getbybranduuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&brandUuid=', '', 'Brand Quality Specification', 'name');
    let tab = new Tab(grid.getTitle(), grid, '');
    tab.idFieldValueName = 'brandUuid';
    grid.setSaveUrl(environment.base_url + '/api/branddetails/v1/save');
    grid.setUpdateUrl(environment.base_url + '/api/branddetails/v1/update');
    grid.hrefUrl = environment.base_url + '/api/branddetails/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
    grid.importUrl = environment.base_url + '/api/branddetails/v1/importbrandquality';
    return tab;
  }
}

