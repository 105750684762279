<app-input-form [header]="header"
                [formContent]="formcontorl"
                [saveButtonLabel]="saveButtonLabel"
                [formGroup]="formGroup"
                [baseZindex]="4"
                [validationSections]="validationSections"
                [visible]="visible"
                (onSave)="onSave($event)"
                (onClose)="close()"></app-input-form>
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>
<ng-template #formcontorl>
    <div *ngIf="!showLoading">
        <form [formGroup]="formGroup">
            <div class="mainbox">
                <div class="sectionbox">
                    <p class="sectionTitle">Sea Transport Details</p>
                    <div class="divider"></div>
                    <div class="contentbox">
                        <label class="heading ng-star-inserted">Charterparty</label>
                        <p-selectButton [options]="charterList"
                                        formControlName="charterparty"
                                        [formControl]="formGroup.controls['charterparty']">
                            <ng-template let-item>
                                <div class="optionButton">
                                    <i [ngClass]="formGroup.controls['charterparty'].value === item.value?'fas fa-check-circle':'far fa-circle'"></i>
                                    <span>{{item.label}}</span>
                                </div>
                            </ng-template>
                        </p-selectButton>
                    </div>
                </div>
                <div class="sectionbox">
                    <p class="sectionTitle">Vessel Information</p>
                    <div class="divider"></div>
                    <div class="row rowClass">
                        <div class="col-md-4 controlGap contentbox">
                            <label class="heading ng-star-inserted">Vessel Name</label>
                            <input id="vesselName" type="text"
                                   class="form-control form-control-sm input"
                                   pInputText placeholder="Vessel Name"
                                   formControlName="vesselName">
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <label class="heading ng-star-inserted">Vessel Capacity (MT)</label>
                            <input id="vesselCapacity" type="number"
                                   (focusout)="onChangeVesselCapacity()"
                                   class="form-control form-control-sm input"
                                   pInputText placeholder="Vessel Capacity"
                                   formControlName="vesselCapacity">
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <label class="heading ng-star-inserted">Freight Capacity (MT)</label>
                            <input id="freightCapacity" type="number"
                                   class="form-control form-control-sm input"
                                   pInputText placeholder="Freight Capacity"
                                   formControlName="freightCapacity">
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <label class="heading ng-star-inserted">IMO</label>
                            <input id="imo" type="text"
                                   class="form-control form-control-sm input"
                                   pInputText placeholder="IMO"
                                   formControlName="imo">
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <label class="heading ng-star-inserted">Vessel Country Flag</label>
                            <p-dropdown [disabled]="false"
                                        [id]="'vesselFlag'"
                                        [options]="commonService.getCountryList()"
                                        appendTo="body"
                                        tooltipEvent="focus" tooltipPosition="bottom"
                                        tooltipZIndex="1000"
                                        formControlName="vesselFlag">
                            </p-dropdown>
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <label class="heading ng-star-inserted">Year</label>
                            <div class="div-control">
                                <input #dp="bsDatepicker"
                                       [bsConfig]="{dateInputFormat:('yyyy').toUpperCase(),adaptivePosition:true}"
                                       [id]="'year'"
                                       [minMode]="'year'"
                                       formControlName="year"
                                       autocomplete="off"
                                       bsDatepicker class="form-control form-control-sm pickerInput"
                                       placeholder="Select Year"
                                       style="border-right: none;min-width: calc(100% - 30px)"
                                       tooltipEvent="focus"
                                       tooltipPosition="bottom" tooltipZIndex="1000" type="text">
                                <div (click)="dp.toggle()" [attr.aria-expanded]="dp.isOpen" class="calIcon calIconEnabled">
                                    <i class="fas fa-calendar" style="margin-left: 7px;margin-top: 8px"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <label class="heading ng-star-inserted">Connecting Vessel</label>
                            <input id="connectingVessel" type="text"
                                   class="form-control form-control-sm input"
                                   pInputText placeholder="Connecting Vessel"
                                   formControlName="connectingVessel">
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <label class="heading ng-star-inserted">Barge</label>
                            <input id="barge" type="text"
                                   class="form-control form-control-sm input"
                                   pInputText placeholder="Barge"
                                   formControlName="barge">
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <label class="heading ng-star-inserted">Tugboat</label>
                            <input id="tugboat" type="text"
                                   class="form-control form-control-sm input"
                                   pInputText placeholder="Tugboat"
                                   formControlName="tugboat">
                        </div>
                    </div>
                </div>
                <div class="sectionbox">
                    <p class="sectionTitle">Load Port Details</p>
                    <div class="divider"></div>
                    <div class="storageGrid">
                        <app-ctrm-price-table
                                (onAddNewRowComplete)="onNewRowAddCompleteLoadPort()"
                                [minHeight]="'auto'"
                                [maxHeight]="'auto'"
                                (onRefreshFormGroupComplete)="onRefreshFromGroupCompleteLoadPort()"
                                [columns]="loadPortColumns" [gridDisplay]="true"
                                [refreshFormGroup]="refreshLoadFormGroup"
                                (onFormGroupCreated)="onFormGroupCreatedLoad($event)"
                                (formGroupStatus)="onFromGroupStatusChangeLoadPort($event)"
                                [newRows]="newLoadPortRows" [refreshNewRows]="refreshAddNewRowLoadPort"
                                [value]="loadPortValues"
                                [showNewEntryIcon]="true"
                                [checkValidityBeforeAddingNewRow]="true"
                                (onChangeFieldValue)="onChangeValueLoadPort($event)">
                        </app-ctrm-price-table>
                    </div>
                </div>
                <div class="sectionbox">
                    <p class="sectionTitle">Unload Port Details</p>
                    <div class="divider"></div>
                    <div class="storageGrid">
                        <app-ctrm-price-table
                                (onAddNewRowComplete)="onNewRowAddCompleteUnloadPort()"
                                [minHeight]="'auto'"
                                [maxHeight]="'auto'"
                                (onRefreshFormGroupComplete)="onRefreshFromGroupCompleteUnloadPort()"
                                [columns]="unloadPortColumns" [gridDisplay]="true"
                                [refreshFormGroup]="refreshUnloadFormGroup"
                                (onFormGroupCreated)="onFormGroupCreatedUnload($event)"
                                (formGroupStatus)="onFromGroupStatusChangeUnloadPort($event)"
                                [newRows]="newUnloadPortRows" [refreshNewRows]="refreshAddNewRowUnloadPort"
                                [value]="unloadPortValues"
                                [showNewEntryIcon]="true"
                                [checkValidityBeforeAddingNewRow]="true"
                                (onChangeFieldValue)="onChangeValueUnloadPort($event)">
                        </app-ctrm-price-table>
                    </div>
                </div>
                <div class="sectionbox">
                    <p class="sectionTitle">Others</p>
                    <div class="divider"></div>
                    <div class="row rowClass">
                        <div class="col-md-12 controlGap contentbox">
                            <label class="heading ng-star-inserted">Notes/Comment</label>
                            <textarea [autoResize]="true"
                                      formControlName="notes"
                                      [id]="'notes'"
                                      [rows]="2"
                                      class="form-control form-control-sm input"
                                      pInputTextarea
                                      placeholder="Notes/Comment"
                                      tooltipEvent="focus"
                                      tooltipPosition="bottom" tooltipZIndex="1000"
                                      type="text"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div *ngIf="showLoading">
        <div class="fetchingDataOnForm">
            <i class="fas fa-spinner fa-pulse fa-2x animated" style="color: rgba(0,0,0,0.51);"></i>
            <p style="font-size: 30px;font-weight: bold;text-align: center;color: rgba(212,212,212,0.58);margin-top: 15px;padding: 0">Please wait</p>
        </div>
    </div>
</ng-template>
