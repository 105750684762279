<p-dialog #af [(visible)]="display" [baseZIndex]="25"
          [closeOnEscape]="false"
          [contentStyle]="{height: formHeight, overflow: 'scroll', overflowX:'hidden'}"
          [draggable]="false" [maximizable]="false" [modal]="true"
          [showHeader]="false"
          [style]="{width: '85%' , padding:'0px', background:'#ffffff'}">
    <div *ngIf="loading || !uomFeteched" style="margin: 20px;">
        <div class="loadingDiv">
            <img class="loadingIconClass" src="./assets/images/doubleRingLoading.gif" alt="">
        </div>
    </div>
    <div *ngIf="!loading && uomFeteched">
        <div class="formHeader">
            <p class="formHeading">Generate {{getInvoiceTypeHeader(data['invoiceType'])}} Invoice</p>
        </div>
        <form [formGroup]="invoiceForm">
            <div class="formWrapper">
                <div style="padding-top: 20px">
                    <div class="row fieldCont">
                        <div class="col-md-3 fieldBox">
                            <p class="fieldName">Invoice Date</p>
                            <div class="inputbox">
                                <div class="div-control">
                                    <input
                                            [attr.disabled]="readOnly && !approved?'':null"
                                            [bsConfig]="bsConfig"
                                            [minDate]="blDate"
                                            bsDatepicker
                                            class="form-control form-control-sm pickerInput"
                                            formControlName="invoiceDate" placeholder="Select Date" type="text">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 fieldBox">
                            <p class="fieldName">Payment Due Date</p>
                            <div class="inputbox">
                                <div class="div-control">
                                    <input
                                            [attr.disabled]="readOnly && !approved?'':null"
                                            [bsConfig]="bsConfig"
                                            bsDatepicker
                                            [minDate]="paymentDueMinDate"
                                            class="form-control form-control-sm pickerInput"
                                            formControlName="paymentDueDate" placeholder="Select Date" type="text">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 fieldBox">
                            <p class="fieldName">Posting Date</p>
                            <div class="inputbox">
                                <div class="div-control">
                                    <input
                                            [attr.disabled]="readOnly && !approved?'':null"
                                            [bsConfig]="bsConfig"
                                            bsDatepicker
                                            [minDate]="getPostingMinDate()"
                                            [maxDate]="getTodayDate()"
                                            class="form-control form-control-sm pickerInput"
                                            formControlName="postingDate" placeholder="Select Date" type="text">
                                </div>
                            </div>
                        </div>

                        <div *ngIf="!sell && readOnly" class="col-md-3 fieldBox">
                            <p class="fieldName">Invoice Number</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" disabled formControlName="invoiceNumber"
                                       pInputText>
                            </div>
                        </div>
                        <div *ngIf="!sell" class="col-md-3 fieldBox">
                            <p class="fieldName">Counterparty Ref No.</p>
                            <div class="inputbox">
                                <input [attr.disabled]="readOnly && !approved?'':null"
                                       class="form-control form-control-sm"
                                       formControlName="counterpartyRefNumber" pInputText>
                            </div>

                        </div>

                    </div>

                </div>
                <!--    COUNTERPARTY DETAILS-->
                <div class="fieldSection">
                    <p class="secHeader">Counterparty Details</p>
                    <div class="row fieldCont" style="display: flex">

                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Counterparty</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" disabled formControlName="counterparty"
                                       pInputText>
                            </div>
                        </div>

                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Address</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" formControlName="counterpartyAddress"
                                       pInputText>
                            </div>
                        </div>


                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Contract No.</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" disabled formControlName="contractNo"
                                       pInputText>
                            </div>
                        </div>

                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Commodity</p>
                            <p-dropdown [disabled]="false"
                                        [id]="'commodity'"
                                        [options]="commodityList"
                                        tooltipEvent="focus" tooltipPosition="bottom"
                                        tooltipZIndex="1000"
                                        formControlName="commodity">
                            </p-dropdown>
                        </div>

                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Payment Term</p>
                            <p-autoComplete [placeholder]="'Enter Payment Term'"
                                            [disabled]="readOnly" [id]="'termsOfPayment'"
                                            [dropdown]="true"
                                            [suggestions]="suggestionListPaymentTerm"
                                            (completeMethod)="searchForPaymentTerm($event)"
                                            formControlName="termsOfPayment" baseZIndex="9999"></p-autoComplete>
                        </div>
                    </div>

                </div>
                <!--      Notify Party Details-->
                <div class="fieldSection">
                    <p class="secHeader">Notify Party Details</p>
                    <div class="row fieldCont" style="display: flex">
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Notify Party Name</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" [attr.disabled]="readOnly?'':null"
                                       formControlName="notifyPartyName" pInputText>
                            </div>
                        </div>
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Notify Party Address</p>
                            <div class="inputbox">
                                <textarea class="form-control form-control-sm" [attr.disabled]="readOnly?'':null"
                                          formControlName="notifyPartyAddress" pInputTextarea></textarea>
                            </div>
                        </div>
                    </div>

                </div>

                <!--      Company Details-->
                <div class="fieldSection">
                    <p class="secHeader">Company Details</p>
                    <div class="row fieldCont" style="display: flex">
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Company</p>
                            <p-dropdown [disabled]="false"
                                        [id]="'company'"
                                        [options]="companyList"
                                        (ngModelChange)="onSelectCompany($event)"
                                        tooltipEvent="focus" tooltipPosition="bottom"
                                        tooltipZIndex="1000"
                                        formControlName="company">
                            </p-dropdown>
                        </div>
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Company Address</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" formControlName="companyAddress" pInputText>
                            </div>
                        </div>
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">External Reference</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" formControlName="externalReference"
                                       pInputText>
                            </div>
                        </div>
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Account Code</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" formControlName="accountCode" pInputText>
                            </div>
                        </div>
                    </div>

                </div>
                <!--        shipment Details-->
                <div class="fieldSection">
                    <p class="secHeader">Shipment Details</p>

                    <div class="row fieldCont" style="display: flex">
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Delivery End Date</p>
                            <div class="inputbox">
                                <div class="div-control">
                                    <input [disabled]=false
                                           [bsConfig]="bsConfig"
                                           bsDatepicker
                                           class="form-control form-control-sm pickerInput"
                                           formControlName="deliveryEndDate" placeholder="Select Date" type="text">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Port of Loading</p>
                            <div class="inputbox">
                                <p-dropdown [disabled]="false"
                                            [id]="'loading'" [filter]="true"
                                            [options]="loadPortList"
                                            tooltipEvent="focus" tooltipPosition="bottom"
                                            tooltipZIndex="1000"
                                            formControlName="portLoading">
                                </p-dropdown>
<!--                                <p-autoComplete [placeholder]="'Enter Port of Loading'"-->
<!--                                                [disabled]=false [id]="'loading'" [dropdown]="true"-->
<!--                                                [suggestions]="suggestionListLoading"-->
<!--                                                (completeMethod)="searchForLoading($event)"-->
<!--                                                formControlName="portLoading" baseZIndex="9999"></p-autoComplete>-->
                            </div>
                        </div>
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Port of Discharge</p>
                            <div class="inputbox">
                                <p-dropdown [disabled]="false" [filter]="true"
                                            [id]="'unloading'"
                                            [options]="unloadPortList"
                                            tooltipEvent="focus" tooltipPosition="bottom"
                                            tooltipZIndex="1000"
                                            formControlName="portDischarge">
                                </p-dropdown>
<!--                                <p-autoComplete [placeholder]="'Enter Port of Discharge'"-->
<!--                                                [disabled]=false [id]="'unloading'" [dropdown]="true"-->
<!--                                                [suggestions]="suggestionListUnloading"-->
<!--                                                (completeMethod)="searchForUnloading($event)"-->
<!--                                                formControlName="portDischarge" baseZIndex="9999"></p-autoComplete>-->
                            </div>
                        </div>
                    </div>
                </div>

                <!--      B/L Details-->
                <div class="fieldSection">
                    <p class="secHeader">B/L Details</p>

                    <div class="row fieldCont" style="display: flex">
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Vessel</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm"
                                       [attr.disabled]="readOnly && !approved?'':null"
                                       formControlName="vesselName" pInputText>
                            </div>

                        </div>

                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Voyage ID</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm"
                                       [attr.disabled]="readOnly && !approved?'':null"
                                       formControlName="vesselId" pInputText>
                            </div>

                        </div>
                    </div>
                </div>
                <!--        Quantity Details-->
                <!--        Quantity Details-->
                <div class="fieldSection">
                    <p class="secHeader">Quantity Details</p>

                    <div class="row fieldCont" style="display: flex">
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Quantity</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" disabled formControlName="quantity"
                                       pInputText>
                            </div>
                        </div>
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Unit Price</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" disabled formControlName="unitPrice"
                                       pInputText>
                            </div>
                        </div>
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Tolerance</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" disabled formControlName="tolerance"
                                       pInputText>
                            </div>
                        </div>
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Delivery Terms</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" disabled formControlName="deliveryTerms"
                                       pInputText>
                            </div>
                        </div>
                    </div>
                </div>

                <!--        BANK DETAILS-->
                <div class="fieldSection">
                    <p class="secHeader">Bank Details</p>
                    <div class="row fieldCont" style="display: flex">

                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Remit To</p>
                            <p-dropdown (ngModelChange)="throwRemitToChanges()" [disabled]="readOnly || !sell"
                                        [options]="remitList" formControlName="remitTo"></p-dropdown>
                        </div>
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Bank Name</p>
                            <p-dropdown (ngModelChange)="throwChanges($event)" [disabled]="readOnly"
                                        [options]="banksDropdown"
                                        formControlName="bankName"></p-dropdown>
                        </div>

                        <div class="col-md-4 fieldBox">
                        </div>

                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Beneficiary Account No.</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" disabled formControlName="beneficiaryAccNo"
                                       pInputText>
                            </div>
                        </div>

                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Bank Address</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" disabled formControlName="bankAddress"
                                       pInputText>
                            </div>
                        </div>

                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Swift Code</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" disabled formControlName="swiftCode"
                                       pInputText>
                            </div>
                        </div>

                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Invoice Currency</p>
                            <div class="dropdown">
                                <select (ngModelChange)="throwCurrencyChanges($event)"
                                        [attr.disabled]="readOnly?'':null"
                                        class="dropdown-text" formControlName="invoiceCurrency">
                                    <option *ngFor="let item of currencyList"
                                            [value]="item['value']">{{item['label']}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">FX Rate</p>
                            <div class="inputbox">
                                <input (focusout)="updateTotalForFxRate()" (ngModelChange)="onFXRateChange($event)"
                                       [step]="'0.001'" [type]="'number'" class="form-control form-control-sm"
                                       formControlName="fxRate"
                                       pInputText>
                            </div>
                        </div>
                    </div>

                </div>

                <!--      PaymentDetails-->
                <div class="fieldSection">
                    <p class="secHeader">Payment Details</p>
                    <div class="row fieldCont" style="display: flex">
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Payment Ref No.</p>
                            <div class="inputbox">
                                <input [step]="'0.001'"
                                       type="text"
                                       pattern="^[a-zA-Z0-9_]*$"
                                       class="form-control form-control-sm"
                                       formControlName="paymentRefNumber" pInputText>
                            </div>
                        </div>
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Payment Date</p>
                            <div class="inputbox">
                                <div class="div-control">
                                    <input
                                            [bsConfig]="bsConfig"
                                            bsDatepicker
                                            class="form-control form-control-sm pickerInput"
                                            formControlName="paymentDate" placeholder="Select Date" type="text">
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Payment Amount</p>
                            <div class="inputbox">
                                <div class="div-control" style="width:100%">
                                    <input *ngIf="(readOnly && !approved);else formatted"
                                           [type]="'number'" class="form-control form-control-sm"
                                           formControlName="paymentAmount"
                                           style="width: inherit;min-width: auto !important;border-top-right-radius: 0;border-bottom-right-radius: 0"
                                           pInputText>
                                    <ng-template #formatted>
                                        <input class="form-control form-control-sm" [disabled]="true"
                                               [value]="formatNumber(data['paymentAmount'],priceRoundOff)"
                                               style="width: inherit;min-width: auto !important;border-top-right-radius: 0;border-bottom-right-radius: 0"
                                               pInputText>
                                    </ng-template>
                                    <p class="cfInnerTableField currencyBox" style="white-space: nowrap">
                                        {{getCurrency()}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--        TABLE FOR CASH FLOWS-->
                <div class="fieldSection">
                    <div class="cfToolWrapper">
                        <button (click)="addNewLineItem()" *ngIf="!readOnly" class="adjustmntBtn">+ Line Item</button>
                    </div>
                    <div style="width: 100%">

                        <p-table #cashFlowGrid [columns]="cfCols" [value]="lineItems">
                            <ng-template let-columns pTemplate="header">
                                <tr>
                                    <th *ngFor="let col of columns" [style.width]="col.width">
                                        <p class="cfInnerTableHeader">{{col.header}}</p>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template let-columns="columns" let-rowData let-ri="rowIndex" pTemplate="body">
                                <tr>
                                    <td *ngFor="let col of columns" [style.width]="col.width">
                                        <div style="display: inline-flex;width: 100%">
                                            <div *ngIf="(col['editable'] === null || col['editable'] === undefined || !col['editable'] || runFunction(col,rowData,ri,'disabled',null,false)); else editable"
                                                 style="display: inline-flex;width: 100%">
                                                <p class="cfInnerTableField" style="width: inherit;"
                                                   [style.white-space]="col.type === 'number'?'nowrap':''"
                                                   [style.text-align]="col.type === 'number'?'right':'left'">{{getColValue(rowData, col, ri)}}</p>
                                            </div>
                                            <ng-template #editable style="display: inline-flex">
                                                <input *ngIf="col.type === 'number'" [(ngModel)]="rowData[col['field']]"
                                                       [ngModelOptions]="{standalone: true}"
                                                       [min]="col.min" [max]="col.max"
                                                       (focusout)="runFunction(col,rowData,ri,'onFocusOut',$event)"
                                                       [id]="'row_'+ri"
                                                       [disabled]="runFunction(col,rowData,ri,'disabled',null,false)"
                                                       type="number"
                                                       style="text-align: right;width: 100%;" pInputText
                                                       (ngModelChange)="runFunction(col,rowData,ri,'onChangeValue',$event)">
                                                <p-autoComplete *ngIf="col.type === 'autocomplete'"
                                                                [ngModelOptions]="{standalone: true}"
                                                                (ngModelChange)="runFunction(col,rowData,ri,'onChangeValue',$event)"
                                                                [(ngModel)]="rowData[col['field']]" [multiple]="false"
                                                                (focusout)="runFunction(col,rowData,ri,'onFocusOut',$event)"
                                                                [disabled]="runFunction(col,rowData,ri,'disabled',null,false)"
                                                                [dropdown]="true" (onDropdownClick)="query = rowData[col['field']]"
                                                                [suggestions]="col.list | search : query" (completeMethod)="search($event)"></p-autoComplete>
                                                <input *ngIf="col.type === 'text'" [(ngModel)]="rowData[col['field']]"
                                                       [ngModelOptions]="{standalone: true}"
                                                       (focusout)="runFunction(col,rowData,ri,'onFocusOut',$event)"
                                                       [id]="'row_'+ri"
                                                       [disabled]="runFunction(col,rowData,ri,'disabled',null,false)"
                                                       style="width: 100%;" pInputText
                                                       (ngModelChange)="runFunction(col,rowData,ri,'onChangeValue',$event)">
                                                <textarea *ngIf="col.type === 'textarea'"
                                                          [(ngModel)]="rowData[col['field']]"
                                                          [ngModelOptions]="{standalone: true}"
                                                          (focusout)="runFunction(col,rowData,ri,'onFocusOut',$event)"
                                                          [id]="'row_'+ri" [autoResize]="true"
                                                          [disabled]="runFunction(col,rowData,ri,'disabled',null,false)"
                                                          style="width: 100%;" pInputTextarea
                                                          (ngModelChange)="runFunction(col,rowData,ri,'onChangeValue',$event)"></textarea>

                                                <select *ngIf="col.type === 'list'"
                                                        (change)="runFunction(col,rowData,ri,'onChangeValue',$event)"
                                                        [disabled]="runFunction(col,rowData,ri,'disabled',null,false)"
                                                        [attr.disabled]="readOnly?'':null"
                                                        [ngModelOptions]="{standalone: true}"
                                                        class="dropdown-text" [(ngModel)]="rowData[col['field']]">
                                                    <option *ngFor="let item of col.list"
                                                            [value]="item['value']">{{item['label']}}</option>
                                                </select>
                                            </ng-template>
                                            <p *ngIf="col['suffixFunc'] !== null && col['suffixFunc'] !== undefined"
                                               class="cfInnerTableField"
                                               style="white-space: nowrap">{{col.suffixFunc(rowData, ri)}}</p>
                                            <i style="cursor:pointer;margin-left: 5px;margin-right: 9px;align-self: center"
                                               *ngIf="col['field'] === 'settlementValue' && !readOnly && isRemovable(col,rowData)"
                                               class="fas fa-times"
                                               (click)="deleteLineItem(ri)"></i>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="footer">
                                <tr *ngIf="adjustmentRow">
                                    <td>
                                        <p class="cfInnerTableField">Adjustment Amount</p>
                                    </td>
                                    <td></td>
                                    <td>
                                        <div *ngIf="!readOnly" class="adjustmentBox">
                                            <input [(ngModel)]="adjustmentAmountDescription"
                                                   [ngModelOptions]="{standalone: true}"
                                                   class="form-control form-control-sm"
                                                   pInputText>
                                        </div>
                                        <div>
                                            <p *ngIf="readOnly" class="cfInnerTableField">
                                                {{adjustmentAmountDescription}}
                                            </p>
                                        </div>
                                    </td>
                                    <td>
                                        <div *ngIf="!readOnly" class="adjustmentBox">
                                            <input (focusout)="updateTotal()" (ngModelChange)="checkEmpty($event)"
                                                   [(ngModel)]="adjustAmount" [ngModelOptions]="{standalone: true}"
                                                   inputmode="number"
                                                   class="form-control form-control-sm"
                                                   style="min-width: auto !important;text-align: right"
                                                   pInputText>
                                            <p class="cfInnerTableField" style="white-space: nowrap">
                                                {{getCurrency()}}
                                            </p>
                                        </div>
                                        <div>
                                            <p *ngIf="readOnly" class="cfInnerTableField">
                                                {{commonService.formatNumberWithoutComma(data['adjustmentAmount'], priceRoundOff)}} {{getCurrency()}}
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>

                        <div class="totalAmountContainer">
                            <div class="totalClass">Total</div>
                            <div class="totalAmount">{{commonService.formatNumber(totalAmount, '', 'left', 'en-Us', priceRoundOff)}} {{getCurrency()}}</div>
                        </div>
                        <div class="totalAmountContainer">
                            <div class="totalClass">Total In Words</div>
                            <div class="totalAmount">{{totalInWords}}</div>
                        </div>
                    </div>
                </div>

                <div class="fieldSection">
                    <div class="row fieldCont" style="display: flex">
                        <div class="col-md-12 fieldBox">
                            <p class="fieldName">Purpose</p>
                            <textarea [attr.disabled]="readOnly?'':null"
                                      [autoResize]="false"
                                      [rows]="1"
                                      class="form-control form-control-sm textAreaClass" formControlName="purpose"
                                      pInputTextarea
                                      placeholder="Enter Purpose"
                                      type="text"></textarea>
                        </div>
                        <div *ngIf="!sell && !readOnly" class="col-md-4 fieldBox">
                            <p class="fieldName">Attachments</p>
                            <div class="inputbox">
                                <ctrm-file-picker
                                        (onFileSelected)="onFileSelect($event)"
                                        [attr.disabled]="readOnly?'':null"></ctrm-file-picker>
                            </div>
                        </div>
                        <div *ngIf="readOnly" class="col-md-4 fieldBox">
                            <p class="fieldName">Invoice Document</p>
                            <p (click)="openDocViewer()" class="pdfReadOnly">{{data['attachmentName']}}</p>
                        </div>
                        <div *ngIf="readOnly && !sell" class="col-md-4 fieldBox">
                            <p class="fieldName">Counterparty Invoice Document</p>
                            <p (click)="openDocViewer()" class="pdfReadOnly">{{data['attachmentName']}}</p>
                        </div>
                    </div>
                </div>
                <div style="border-top: 1px solid #e8e8e8; height: 20px"></div>
                <div class="row fieldCont" style="display: flex">
                    <div class="col-md-4 fieldBox">
                        <p class="fieldName">Trader</p>
                        <div class="inputbox">
                            <input class="form-control form-control-sm" disabled formControlName="traderName"
                                   pInputText>
                        </div>
                        <p class="dateLabel">{{data['createdTimestamp'] | date: 'dd MMM yyyy'}}</p>
                    </div>

                    <div class="col-md-4 fieldBox">
                        <p class="fieldName">{{ 'Invoice ' + invoiceStausLabel + ' By'}}</p>
                        <div class="inputbox">
                            <input class="form-control form-control-sm" disabled formControlName="invoiceGeneratedBy"
                                   pInputText>
                        </div>
                        <p class="dateLabel">{{invoiceDate | date: 'dd MMM yyyy'}}</p>
                    </div>

                    <div *ngIf="readOnly && this.approved" class="col-md-4 fieldBox">
                        <p class="fieldName">Finance Approved By</p>
                        <div class="inputbox">
                            <input class="form-control form-control-sm" disabled formControlName="financeApprovedBy"
                                   pInputText>
                        </div>
                        <p class="dateLabel">{{financeAppDate | date: 'dd MMM yyyy'}}</p>
                    </div>
                </div>
            </div>
        </form>
    </div>


    <p-footer *ngIf="!readOnly && !approved">
        <div style="display: inline-flex;align-items: center">
            <ng-container [ngTemplateOutlet]="outputFormat"></ng-container>
            <button (click)="preview()" [disabled]="loading" class="planScreenBtns">Preview</button>
            <button (click)="onFormCancel()" class="cancelBtn">Cancel</button>
            <xceler-progress-button
                    [isLoading]="working"
                    (onClick)="checkForPlannedStatus()"
                    (onLoadDone)="onGenerateInvoiceDone()"
                    [buttonStyle]="{margin:'0px',minHeight:'35px',padding:'0px',paddingLeft:'10px',paddingRight:'10px'}"
                    (onError)="onFailedToGenerate($event)"
                    [disabled]="loading || working"
                    [buttonLoadingTitle]="'Generating'"
                    [buttonIdleTitle]="'Generate'">
            </xceler-progress-button>
        </div>
    </p-footer>
    <p-footer *ngIf="readOnly && !approved">
        <div style="display: inline-flex;">
            <button (click)="onFormCancel()" class="cancelBtn">Cancel</button>
            <xceler-progress-button
                    [disabled]="approvalDisable('trader') && approvalDisable('finance')"
                    (onLoadDone)="onSubmit($event)"
                    (onError)="onSubmitFail($event)"
                    [buttonStyle]="{margin:'0px',minHeight:'35px',padding:'0px',paddingLeft:'10px',paddingRight:'10px'}"
                    [callApiObjectFunction]="submitApiFunction"
                    [buttonIdleTitle]="'Submit'"
                    [buttonLoadingTitle]="'Submitting'">
            </xceler-progress-button>
        </div>
    </p-footer>
    <p-footer *ngIf="approved ">
        <div style="display: inline-flex;">
            <button (click)="onFormCancel()" class="cancelBtn">Cancel</button>
            <xceler-progress-button *ngIf="buttonValue==='Settle'"
                                    (onLoadDone)="onSettleDone($event)"
                                    (onError)="onSubmitFail($event)"
                                    [buttonStyle]="{margin:'0px',minHeight:'35px',padding:'0px',paddingLeft:'10px',paddingRight:'10px'}"
                                    [callApiObjectFunction]="settleApiFunction"
                                    [buttonIdleTitle]="'Settle'"
                                    [buttonLoadingTitle]="'Settle'">
            </xceler-progress-button>

            <xceler-progress-button *ngIf="buttonValue==='Post Invoice'"
                                    [buttonIdleTitle]="'Post'"
                                    [buttonLoadingTitle]="'Post'"
                                    [callApiObjectFunction]="callApiFunc"
                                    [buttonStyle]="{margin:'0px',minHeight:'35px',padding:'0px',paddingLeft:'10px',paddingRight:'10px'}"
                                    (onLoadDone)="onPostingSuccess($event)"
                                    (onError)="onErrorPostinginvoice($event)" class="saveBtn">
            </xceler-progress-button>
            <xceler-progress-button *ngIf="buttonValue==='Unpost'"
                                    [buttonIdleTitle]="'Unpost'"
                                    [buttonLoadingTitle]="'Unpost'"
                                    [callApiObjectFunction]="callApiFuncUnpost"
                                    [buttonStyle]="{margin:'0px',minHeight:'35px',padding:'0px',paddingLeft:'10px',paddingRight:'10px'}"
                                    (onLoadDone)="onUnPostingSuccess($event)"
                                    (onError)="onErrorPostinginvoice($event)" class="saveBtn">
            </xceler-progress-button>
            <xceler-progress-button *ngIf="buttonValue==='Update'"
                                    [buttonIdleTitle]="'Update'"
                                    [buttonLoadingTitle]="'Update'"
                                    [callApiObjectFunction]="callApiFunc"
                                    [buttonStyle]="{margin:'0px',minHeight:'35px',padding:'0px',paddingLeft:'10px',paddingRight:'10px'}"
                                    (onLoadDone)="onPostingSuccess($event)"
                                    (onError)="onErrorPostinginvoice($event)"
                                    class="saveBtn">
            </xceler-progress-button>
        </div>
    </p-footer>
</p-dialog>

<doc-viewer (OnClose)="onCloseDocViewer()" [docUrl]="docUrl" [fileName]="docName"
            [modalState]="showDoc"></doc-viewer>
<xceler-pdf-viewer (onViewerClose)="onViewerClose()"
                   [fileName]="'Preview Invoice Document'"
                   [base64src]="previewBytes"
                   [errorMessage]="errorMessage"
                   [modalState]="viewerState"></xceler-pdf-viewer>
<p-confirmDialog [baseZIndex]="9999" header="Confirmation"
                 icon="pi pi-exclamation-triangle"></p-confirmDialog>

<ng-template #outputFormat>
    <div class="item-container">
        <p class="fieldName">File Format <span class="mandatory-span"></span></p>
        <p-dropdown [disabled]="allowFormatEnable" [(ngModel)]="documentFormat" [placeholder]="'Select Document Format'"
                    [appendTo]="'body'" style="padding: 10px"
                    [options]="documentFormatList"></p-dropdown>
    </div>
</ng-template>
