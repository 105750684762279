import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';

import {environment} from '../../environments/environment';
import {Router} from "@angular/router";
import {catchError, map} from 'rxjs/operators';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          localStorage.clear();
            if (window.location.origin.includes('localhost')) {
                this.router.navigate(['/login']);
            } else {
                window.location.href =  '/auth/';
            }
        }

        if (!environment.production) {
          console.error(err);
        }
        return throwError(err);
      })
    );
  }
}
