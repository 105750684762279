<div *ngIf="eodHistorydisplay">
  <div class="sectionCardTop">
    <div class="breadcrumbPane">
      <ctrm-breadcrumb [collapsabel]="false"
                       [items]="breadCrumbService.getBreadCrumb()"
                       [showIcon]="false"></ctrm-breadcrumb>
    </div>
    <div class="actionPane">
      <div class="screenDetails">
        <i (click)="closeEod()" class="fas fa-chevron-left backIcon"></i>
        <p>History Activites</p>
      </div>
    </div>
  </div>

  <div *ngIf="!secondLevel" class="planningPanel">
    <div class="toolbar">
      <div>
        <ctrm-button (onClick)="planRefresh()" icon="fas fa-redo" label="Refresh"></ctrm-button>
        <div class="float-right">
          <ctrm-button icon="fas fa-download" label="Download History"></ctrm-button>
        </div>
      </div>
    </div>
    <!--History Details-->
    <p-table #historyTable
             (inViewportAction)="calculatehistoryHeight(historyTable)"
             (onRowExpand)="fetchChildData($event)"
             [(selection)]="selectedEod"
             [expandedRowKeys]="expandedRows"
             [columns]="historyCols" [responsive]="true"
             [style]="{width: '100%', maxHeight: historyHeight}" [style]="{maxHeight:historyHeight,minHeight:historyHeight}" [value]="eodHistoryData" autoLayout="true"
             class="style-4 table-responsive" columnResizeMode="fit"
             dataKey="taskRunName"
             editMode="row" inViewport selectionMode="single" sortMode="multiple">
      <ng-template let-columns pTemplate="header">
        <tr>
          <th style="width: 3em">
            <p
              style="background-color: #D9DBDE; padding: 5px;padding-top: 11px; padding-bottom: 10px; text-align: center; margin: 0">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </p>
          </th>
          <th *ngFor="let col of columns;index as i">
            <div style="background-color: #D9DBDE; padding: 5px;padding-top: 13px; padding-bottom: 14px">
              <p [ngClass]="getClassForHeading(i)">{{col.header}}</p>
            </div>
          </th>
          <th></th>

        </tr>
      </ng-template>

      <ng-template let-columns="columns" let-expanded="expanded" let-ri="rowIndex" let-rowData pTemplate="body">
        <tr [pSelectableRow]="rowData">
          <td class="customRow" style="width:3em;display: inline-flex">
            <p class="plannedGridChkBox">
              <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
            </p>
          </td>
          <td *ngFor="let col of columns" class="customRow">
            <p *ngIf="col.field !== 'status'" class="plannedGrid" style="padding-top: 2px">
              {{getFormattedData(col, rowData)}}
            </p>

            <div *ngIf="col.field === 'status'" style="display: flex">
              <p class="statusLabel" [ngClass]="getClassForStatus(rowData[col.field])">{{rowData[col.field]}}</p>

            </div>
          </td>
          <td>
            <p style="margin:0">
              <a [pRowToggler]="rowData" href="#">
                <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" class="togglerIcon"></i>
              </a>
            </p>
          </td>
        </tr>
      </ng-template>

      <ng-template let-columns="columns" let-ri="rowIndex" let-rowData pTemplate="rowexpansion">
        <tr>
          <td [colSpan]="columns.length + 1">
            <div class="planningInfoContainer">
              <app-history-expand [columns]="historyDetailsCols" [eodJobName]="data['eodTemplateName']" [showChkBox]="false"
                                  [value]="rowData['stepStatus']"></app-history-expand>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <div *ngIf="eodHistoryData.length === undefined || eodHistoryData.length == null || eodHistoryData.length == 0"
         style="text-align: center;">
      <div class="noPlans">No History Recorded</div>
      <!--    <div>-->
      <!--      <button (click)="onCreateEOD()" class="planScreenBtns">Create Plan</button>-->
      <!--    </div>-->
    </div>

  </div>
</div>
