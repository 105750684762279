<div style="display: inline-flex;width: 100%">
    <masters-sidenav></masters-sidenav>
    <div id="pinwidth" style="width: 100%;margin-left: 80px">
<ctrm-grid
        [breadcrumbMapKey]="commonService.OPERATION_MAP_KEY"
        [currentRoute]="'/notificationsettings'"
        [grid]="grid"
        [refreshGrid]="refreshGrid"
        (onRefreshComplete)="refreshGrid = false"
        [defaultEdit]="false"
        [showActions]="false"
        (onClickAdd)="onClickAdd()"
        (onClickEdit)="onClickUnique($event)"
        [saveOnSave]="false">
</ctrm-grid>

<!--Add Notification -->
<app-input-form [header]="header"
                [formContent]="formcontorl"
                [saveButtonLabel]="saveButtonLabel"
                [formGroup]="notificationForm"
                [validationSections]="validationSections"
                (onSave)="onSave($event)"
                [visible]="displayOnAdd"
                (onClose)="onClose()"></app-input-form>
<ng-template #formcontorl>
    <form [formGroup]="notificationForm">
        <div class="mainbox">
            <div class="sectionBoxMain">
                <div class="formSectionTitle">
                    <div class="headerTitle">Notification Configuration</div>
                </div>
                <div class="row rowClass">
                    <div class="col-md-4 controlGap contentbox">
                        <div class="fieldBox">
                            <label class="label ng-star-inserted">Description</label>
                            <div class="fieldBox">

                                <div class="inputbox">
                                    <textarea id="desc" class="form-control form-control-sm"
                                              formControlName="description"
                                              pInputTextarea style="border: 1px solid #333333">
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 controlGap contentbox">
                        <div class="fieldBox">
                            <label class="label ng-star-inserted">Channel</label>
                            <ctrm-multiselect [allowSearch]="true"
                                              [id]="'channel'"
                                              [(ngModel)]="notificationForm.value['channel']"
                                              formControlName="channel"
                                              [list]="channelList"
                                              [mclass]="'form-control form-control-sm'"
                                              [msClass]="'form-control-normal'"
                                              [multiple]="true"
                                              [outputFormat]="'string'"
                                              (onChangeValue)="changechannel($event)"
                                              (onSelectAll)="changechannel($event)"
                                              [placeholder]="'Select Channel'">
                            </ctrm-multiselect>
                        </div>

                    </div>
                    <div class="col-md-4 controlGap contentbox">
                        <div class="fieldBox">
                            <label class="label ng-star-inserted">Recipient Type</label>
                            <ctrm-multiselect [allowSearch]="true"
                                              [id]="'recipientType'"
                                              [formControl]="notificationForm.controls['recipientType']"
                                              formControlName="recipientType"
                                              [list]="recipientList"
                                              [mclass]="'form-control form-control-sm'"
                                              [msClass]="'form-control-normal'"
                                              [multiple]="true"
                                              [outputFormat]="'string'"
                                              (ngModelChange)="changerecipienttype($event)"
                                              [placeholder]="'Select Recipient Type'">
                            </ctrm-multiselect>
                        </div>
                    </div>
                    <div class="col-md-4 controlGap contentbox">
                        <div class="fieldBox">
                            <label class="label ng-star-inserted">Email</label>

                                <input id="email" type="text"
                                       class="form-control form-control-sm input"
                                       pInputText placeholder="Email"
                                       formControlName="email">
                        </div>
                    </div>
                    <div class="col-md-4 controlGap contentbox">
                        <div class="fieldBox">
                            <label class="label ng-star-inserted">Phone</label>
                            <input id="phone" type="text"
                                   maxlength="10"
                                   (input)="commonService.updateInputValidation($event,includedCharacters,true)"
                                   class="form-control form-control-sm input"
                                   pInputText placeholder="Phone"
                                   formControlName="phone">
                        </div>
                    </div>
                    <div class="col-md-4 controlGap contentbox">
                        <div class="fieldBox">
                            <label class="label ng-star-inserted">Role</label>
                            <p-dropdown [disabled]="false"
                                        [id]="'role'"
                                        [options]="roleList"
                                        appendTo="body"
                                        formControlName="role">
                            </p-dropdown>
                        </div>
                    </div>

                </div>
                <div class="col-md-6 controlGap contentbox">
                    <div class="fieldBox">
                        <label class="label ng-star-inserted">XQL (Optional Type in)</label>
                        <div class="fieldBox" style="width:300px;">

                            <div class="inputbox" >
                                <textarea id="query" class="form-control form-control-sm" rows="5" cols="60" formControlName="query" pInputTextarea style="border: 1px solid #333333"></textarea>
                            </div>
                        </div>
                    </div>
                </div>

                <div class=" col-md-8">
                    <label class="label ng-star-inserted">Subject Line</label>
                    <input id="subjectLine" type="text"
                           class="form-control form-control-sm input"
                           pInputText placeholder="Subject Line"
                           formControlName="subjectLine">
                </div>

                <div class=" col-md-12 controlGap">
                    <label class="label ng-star-inserted">Notification Message</label>
                    <div class="fieldBox" style="width:100%;">

                        <div class="inputbox" >
                            <textarea id="description" class="form-control form-control-sm" rows="6" cols="150"
                                      formControlName="notificationdescription" pInputTextarea style="border: 1px solid #333333">

                            </textarea>
                        </div>
                    </div>
                </div>
            </div>

<!--            Notification Rule Builder-->
<!--            <div class="sectionBoxMain" >-->
<!--                <div class="formSectionTitle">-->
<!--                    <div class="headerTitle">Notification Rule Builder</div>-->
<!--                </div>-->
<!--                <div class="row rowClass">-->
<!--                    <div class="col-md-6 controlGap contentbox">-->
<!--                        <table style="border: 0px;">-->
<!--                            <tr class="trofnotificationsettings">-->
<!--                                <td style="width: 105px;" ><label class="label ng-star-inserted">Object Name</label></td>-->
<!--                                <td>-->
<!--                                    <input id="abc" type="text"-->
<!--                                           (input)="commonService.updateInputValidation($event,includedCharacters,true)"-->
<!--                                           class="form-control form-control-sm input"-->
<!--                                           pInputText placeholder="Object Name"-->
<!--                                           formControlName="storageName">-->
<!--                                </td>-->
<!--                            </tr>-->
<!--                            <tr class="trofnotificationsettings">-->
<!--                                <td ><label class="label ng-star-inserted">Field Name</label></td>-->
<!--                                <td>-->
<!--                                    <input id="xyz" type="text"-->
<!--                                           (input)="commonService.updateInputValidation($event,includedCharacters,true)"-->
<!--                                           class="form-control form-control-sm input"-->
<!--                                           pInputText placeholder="Field Name"-->
<!--                                           formControlName="storageName">-->
<!--                                </td>-->
<!--                            </tr>-->

<!--                            <tr class="trofnotificationsettings">-->
<!--                                <td ><label class="label ng-star-inserted">Operator</label></td>-->
<!--                                <td>-->
<!--                                    <p-dropdown [disabled]="false"-->
<!--                                                [id]="'Company'"-->
<!--                                                [options]="recipientList"-->
<!--                                                appendTo="body"-->
<!--                                                tooltipEvent="focus" tooltipPosition="bottom"-->
<!--                                                tooltipZIndex="1000"-->
<!--                                                formControlName="company">-->
<!--                                    </p-dropdown>-->
<!--                                </td>-->
<!--                            </tr>-->
<!--                            <tr class="trofnotificationsettings">-->
<!--                                <td ><label class="label ng-star-inserted">Value</label></td>-->
<!--                                <td>-->
<!--                                    <input id="storageName" type="text"-->
<!--                                           (input)="commonService.updateInputValidation($event,includedCharacters,true)"-->
<!--                                           class="form-control form-control-sm input"-->
<!--                                           pInputText placeholder="Value"-->
<!--                                           formControlName="storageName">-->
<!--                                </td>-->
<!--                            </tr>-->

<!--                            </table>-->
<!--                    </div>-->
<!--                    <div class="col-md-6 controlGap contentbox">-->
<!--                        <div class="fieldBox">-->
<!--                            <label class="label ng-star-inserted">XQL (Optional Type in)</label>-->
<!--                            <div class="fieldBox" style="width:300px;">-->

<!--                                <div class="inputbox" >-->
<!--                                    <textarea id="notidesp" class="form-control form-control-sm" rows="5" cols="60" formControlName="notificationdescription" pInputTextarea style="border: 1px solid #333333"></textarea>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                </div>-->

<!--            </div>-->




        </div>
    </form>
</ng-template>

<!--Toaster Message-->
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body">
</p-toast>
    </div>
</div>
