import {Component, OnInit, ViewChild} from '@angular/core';
import {Grid} from "../../grid/grid.model";
import {environment} from "../../../environments/environment";
import {Tcolumn} from "../../grid/tcolumn.model";
import {MasterService} from "../../masters/services/MasterService";
import {Filter} from "../../grid/preference/filter/filter.model";
import {defaultValue} from "../../grid/defaultValue.model";
import {CommonService} from "../../services/common.service";
import {tradeOptionsButtons} from "../../services/common/entities";
import {Button} from "../../grid/buttons.model";
import {ActivatedRoute, Router} from "@angular/router";
import {OverlayPanel} from 'primeng/overlaypanel';

import {HttpErrorResponse} from "@angular/common/http";
import {MessageService, SortEvent} from "primeng";
import {BreadcrumbService} from "../../Components/ctrm-breadcrumb/breadcrumb.service";
import {OperationDashboardApprovalFunctions} from './operation-dashboard-approval-functions';
import * as moment from "moment/moment";
import {KeyValue} from "../../grid/key-value.model";

@Component({
  selector: 'app-operations-dashboard',
  templateUrl: './operations-dashboard.component.html',
  styleUrls: ['./operations-dashboard.component.css']
})
export class OperationsDashboardComponent implements OnInit {
    columns: Map<string, Tcolumn>;
    grid: Grid;
    tradeId: string = '';
    proFormaInvoiceRow: any = {};
    selectedRows: any[] = [];
    display: boolean = false;
    rowData: any = {};
    overlayPanelTemp: OverlayPanel;
    selectedPreset: any = {};
    refreshFilter: boolean = false;
    documentTypeDisabled: boolean = false;
    addedFilter: any[] = ['PLANNED', 'PRICED', 'DELIVERYSTARTED', 'INVOICED'];
    showDateRange: boolean = false;
    presetList: any[] = [];
    gridFilters: any[] = [];
    @ViewChild('overlayPanel', {static: true}) overlayPanel: OverlayPanel;
    hoverPanelData: any;
    filterValue: any;
    totalElements: any = 0;
    tradeIdList: any[] = [];
    planIdList: any[] = [];
    tradeTransactionTypeList: any[] = [];
    counterpartyList: any[] = [];
    overlayPanelRowData: any;
    plannedObligationIdList: any[] = [];
    deliveryDateList: any[] = [];
    shipmentMonthList: any[] = [];
    commodityList: any[] = [];
    profitcenterList: any[] = [];
    traderList: any[] = [];
    loadPortList: any[] = [];
    unloadPortList: any[] = [];
    refreshGrid: boolean = false;
    displayInvoiceForm: boolean = false;
    invoiceProFormaObject: any;
    sc: any[] = [];
    displayBasic: boolean = false;
    sortFunction: Function;
    gridHeightRecalculate: boolean = false;
    documents: any[] = [];
    search: any;
    tradeLoadData: any[] = [];
    preferencesList: Tcolumn[] = [];
    onApplyAdvancedFiltervalue: any[] = [];
    title: string = '';
    tradePricingLoadApiFunction: Function
    transportAllocationLoadApiFunction: Function
    actualizationLoadApiFunction: Function
    invoiceLoadapi: Function
    showBookAVessel: boolean = false;
    actualizationVesselBooking: boolean = false;
    dateFormat = environment.dateFormat;
    hoverPlannedObligation: any;
    hoverIcon: any;
    fxAllocationLoadApiFunction: Function;
    //Amend-modify
    displayAmendForm: boolean = false;
    declarePort: boolean = false;
    planObligationValue: any;
    addingCost: any;
    showAddCost: boolean = false;
    costFor: string = "";
    linkTo: string = "";
    planId: string = "";
    fxnotapplicable: boolean = false;
    ammendRowdata: any;
    generateDocument: boolean = false;
    washoutPlanning: boolean = false;
    interCompanyTrade: boolean = false;
    obligation: any = {};
    buyObligation: any = {};
    buyObligationRequired: boolean = true;
    sellObligation: any = {};
    sellObligationRequired: boolean = true;
    documentType: String = '';
    documentTemplate: any[] = [];
    shippingFormButtonLabel = "Generate";
    documentTypeList: any[] = []
    documentTemplateList: any[] = [];
    selectedContextData: any = {};
    quantityRoundOff: number = 3;
    priceRoundOff: number = 2;

    documentFormatList: any[] = [];
    documentFormat: String = '';
    showSplitPopup: boolean = false;
    showInterCompanySplitPopUp: boolean = false;
    splitQuantityValue: number = 0;
    splitApiFunction: Function;

    constructor(private masterCommonService: MasterService, public commonService: CommonService,
                private router: Router, private messageService: MessageService,
                public breadCrumbService: BreadcrumbService, private route: ActivatedRoute) {
    }


    ngOnInit(): void {
        let _this = this;
        this.commonService.getLoadData('commodity', ['config', 'config', 'config', 'config', 'config', 'config', 'data'], ['location', 'counterparty', 'profitcenter', 'commodity', 'incoterm', 'unitofmeasurement', [{
            key: 'name',
            value: 'price_type_ind'
        }]]).subscribe((next: any) => {
            _this.tradeLoadData = next;
        });
        this.breadCrumbService.makeBreadcrumbTo(this.commonService.OPERATION_MAP_KEY, '/operationsDashboard')
        this.generateDefaultPreset();
        this.createGridFilters();
        this.getColumns();
        this.grid = new Grid(this.columns, true, true, true, true, 'tradeId', '/api/tradeobligation/v1/getoperationsgriddata?tenantId=' + this.commonService.getFromStorage('tenantId'), undefined, 'Operations Dashboard', undefined, undefined);
        this.grid.setWidth('5%');
        this.grid.setGridConfiguration(false, false, false, false, false, false, true, false, false, false);
        this.grid.addClickable('plannedObligationId');
        this.grid.openSidebarOnUniqueClick = false;
        this.grid.addClickable('tradeId');
        this.grid.iconName = 'obligationDashboard';
        this.grid.gridFetchUrlType = "POST";
        this.grid.isHaveExtraButtons = true;
        this.grid.contextMenuConfig.functionFile = OperationDashboardApprovalFunctions;
        this.grid.contextMenuConfig.triggerEvent = '';
        this.grid.contextMenuConfig.nameFieldId = '';
        this.grid.contextMenuConfig.referenceIdField = '';
        this.grid.contextMenuConfig.screenName = 'Operational Dashboard';
        this.grid.gridAdvacedFilterURL = '/api/tradeobligation/v1/getoperationsfilterdatabycriteria?tenantId=' + this.commonService.getFromStorage('tenantId');
        this.grid.fetchPostPayloadFuction = function (value: any[] = []) {
            return _this.getCommonFilterPayload(true, true);
        };
        this.sortFunction = function (event: SortEvent) {
            event.data.sort((data1, data2) => {
                let field = event.multiSortMeta[0].field;
                let order = event.multiSortMeta[0].order;
                let value1 = data1[field];
                let value2 = data2[field];
                if (field === 'deliveryEndDate') {
                    value1 = new Date(value1);
                    value2 = new Date(value2);
                }
                let result = null;
                if (value1 == null && value2 != null) {
                    result = -1;
                } else if (value1 != null && value2 == null) {
                    result = 1;
                } else if (value1 == null && value2 == null) {
                    result = 0;
                } else if (typeof value1 === 'string' && typeof value2 === 'string') {
                    result = value1.localeCompare(value2);
                } else {
                    result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
                }
                return (order * result);
            });
        };

        if (this.filterValue !== undefined && this.filterValue !== null && this.filterValue !== '') {
            this.grid.addInFilter(new Filter('Operations Dashboard', 'name', 'equalsto', this.filterValue));
        }
        this.createLoadFunctions();
        let tenantConfig = JSON.parse(this.commonService.getFromStorage("tenantConfig"));
        if (tenantConfig && tenantConfig.roundingFormat) {
            _this.quantityRoundOff = tenantConfig.roundingFormat.quantityRounding;
            _this.priceRoundOff = tenantConfig.roundingFormat.priceRounding;
        } else {
            _this.quantityRoundOff = 3;
            _this.priceRoundOff = 2;
        }
        this.route.queryParams.subscribe(params => {
            let value = params['value'];
            if (value !== undefined && value !== null && value.length > 0) {
                this.commonService.getJSONByURL(environment.base_url + '/api/plannedObligation/v1/getplannedobligationsbyplannedobligationid?tenantId=' +
                    this.commonService.getFromStorage('tenantId') + '&plannedObligationId=' + value).subscribe(next => {
                })
            }
        });
        this.grid.filterHiddenFields = ['fxAllocationStatus', 'priceAllocationStatus']
        this.splitApiFunction = function (payload: any) {
            let plannedQuantity = parseFloat(_this.selectedContextData['plannedQuantity']);
            if (_this.splitQuantityValue >= plannedQuantity) {
                _this.showToast('Split Quantity can not be equal or greater than ' + plannedQuantity, 'error');
            } else {
                let plannedObligationId = _this.selectedContextData['plannedObligationId'];
                payload['url'] = `${environment.base_url}/api/plannedObligation/v1/splitPlannedObligation?plannedObligationId=${plannedObligationId}&splitQuantity=${_this.splitQuantityValue}`;
                payload['type'] = 'post';
                payload['payload'] = {};
            }
            return payload;
        }
    }

    /**
     * preset Date configuration
     */
    private generateDefaultPreset() {
        this.presetList.push({
            title: 'Forward 1 month',
            startDate: moment(new Date).startOf('month').toDate(),
            endDate: moment(new Date).endOf('month').toDate()
        });
        this.presetList.push({
            title: 'Forward 2 months',
            startDate: new Date(),
            endDate: this.commonService.addMonthsInDate(new Date(), 2)
        });
        this.presetList.push({
            title: 'Forward 3 months',
            startDate: new Date(),
            endDate: this.commonService.addMonthsInDate(new Date(), 3)
        });
        this.presetList.push({
            title: 'Forward 6 months',
            startDate: new Date(),
            endDate: this.commonService.addMonthsInDate(new Date(), 6)
        });
        this.presetList.push({
            title: 'Forward 1 year',
            startDate: new Date(),
            endDate: this.commonService.addYearsInDate(new Date(), 1)
        });
        this.selectedPreset = this.presetList[0];
    }

    /**
     * creation of filter payload
     * @param addCardInfo
     * @param addFilterInfo
     */
    private getCommonFilterPayload(addCardInfo: boolean = false, addFilterInfo: boolean = false) {
        let payload = {
            fromDate: this.commonService.addDaysInDate(this.selectedPreset['startDate'], 0),
            toDate: this.selectedPreset['endDate']
        };
        if (addCardInfo) {
            payload['planned'] = this.addedFilter.includes("PLANNED");
            payload['priced'] = this.addedFilter.includes("PRICED");
            payload['deliveryStarted'] = this.addedFilter.includes("DELIVERYSTARTED");
            payload['invoiceInitiated'] = this.addedFilter.includes("INVOICED");
        }
        if (addFilterInfo) {
            payload['planId'] = this.planIdList;
            payload['tradeId'] = this.tradeIdList;
            payload['plannedObligationId'] = this.plannedObligationIdList;
            payload['deliveryDate'] = this.deliveryDateList;
            payload['shipmentMonth'] = this.shipmentMonthList;
            payload['commodity'] = this.commodityList;
            payload['profitcenter'] = this.profitcenterList;
            payload['counterparty'] = this.counterpartyList;
            payload['trader'] = this.traderList;
            payload['loadPort'] = this.loadPortList;
            payload['dischargePort'] = this.unloadPortList;
            payload['tradeTransactionType'] = this.tradeTransactionTypeList;
        }
        return payload;
    }

    /**
     * reset filter configuration
     */

    private resetFilters() {
        this.planIdList = [];
        this.tradeIdList = [];
        this.tradeTransactionTypeList = [];
        this.plannedObligationIdList = [];
        this.deliveryDateList = [];
        this.shipmentMonthList = [];
        this.profitcenterList = [];
        this.counterpartyList = []
        this.traderList = [];
        this.loadPortList = [];
        this.unloadPortList = [];
        this.gridFilters = [];
        this.gridFilters.push({title: 'Plan Id', list: []});
        this.gridFilters.push({title: 'Trade Id', list: []});
        this.gridFilters.push({title: 'Buy/Sell', list: []});
        this.gridFilters.push({title: 'Obligation Id', list: []});
        this.gridFilters.push({title: 'Delivery End Date', list: []});
        this.gridFilters.push({title: 'Shipment Month', list: []});
        this.gridFilters.push({title: 'Commodity', list: []});
        this.gridFilters.push({title: 'Profitcenter', list: []});
        this.gridFilters.push({title: 'Counter Party', list: []})
        this.gridFilters.push({title: 'Trader', list: []});
        this.gridFilters.push({title: 'Load Port', list: []});
        this.gridFilters.push({title: 'Discharge Port', list: []});
    }

    /**
     * creation of grid filter
     */
    private createGridFilters() {
        let payload = this.getCommonFilterPayload(true);
        let _this = this;
        this.resetFilters();
        this.commonService.post(environment.base_url + '/api/tradeobligation/v1/getoperationsfilterdata?tenantId=' + this.commonService.getFromStorage('tenantId'), payload).subscribe((next) => {
            let uniquetradeTransactionType = next['tradeTransactionType'].filter((c, index) => {
                return next['tradeTransactionType'].indexOf(c) === index;
            });
            _this.gridFilters = [];
            _this.gridFilters.push({
                title: 'Plan Id',
                list: _this.masterCommonService.getListFromArray(next['planId'], false, false)
            });
            _this.gridFilters.push({
                title: 'Trade Id',
                list: _this.masterCommonService.getListFromArray(next['tradeId'], false, false)
            });
            _this.gridFilters.push({
                title: 'Buy/Sell',
                list: _this.masterCommonService.getListFromArray(uniquetradeTransactionType, false, false)
            });
            _this.gridFilters.push({
                title: 'Obligation Id',
                list: _this.masterCommonService.getListFromArray(next['plannedObligationId'], false, false)
            });
            _this.gridFilters.push({
                title: 'Delivery End Date',
                list: _this.masterCommonService.getListFromDateArray(next['deliveryDate'], false, false)
            });
            _this.gridFilters.push({
                title: 'Commodity',
                list: _this.masterCommonService.getListFromArray(next['commodity'], false, false)
            });
            _this.gridFilters.push({
                title: 'Profitcenter',
                list: _this.masterCommonService.getListFromArray(next['profitcenter'], false, false)
            });
            _this.gridFilters.push({
                title: 'Counter Party',
                list: _this.masterCommonService.getListFromArray(next['counterparty'], false, false)
            });
            _this.gridFilters.push({
                title: 'Trader',
                list: _this.masterCommonService.getListFromArray(next['trader'], false, false)
            });
            _this.gridFilters.push({
                title: 'Load Port',
                list: _this.masterCommonService.getListFromArray(next['loadPort'], false, false)
            });
            _this.gridFilters.push({
                title: 'Discharge Port',
                list: _this.masterCommonService.getListFromArray(next['dischargePort'], false, false)
            });
            _this.gridFilters.push({
                title: 'Shipment Month',
                list: _this.masterCommonService.getListFromArray(next['shipmentMonth'], false, false)
            });
        });
    }


    /**
     * Recieve data from ops-preference on change of columns list
     * @param colChange
     */
    opsReceive(colChange: any) {
        if (!colChange['mouseEvent']) {
            this.columns.clear();
        }
        this.preferencesList = [];
        colChange['columns'].forEach((col: any, index) => {
            this.preferencesList.push(col);
        });
    }

    /**
     * Recieve data from ops-preference advanced filter
     * @param value
     */
    onApplyAdvancedFilter(value: any[]) {
        this.onApplyAdvancedFiltervalue = JSON.parse(JSON.stringify(value));
        let fromDate = new Date(this.commonService.addDaysInDate(this.selectedPreset['startDate'], 0));
        let firstValue = fromDate.toISOString().replace('Z', '');
        let toDate = new Date(this.selectedPreset['endDate']);
        let secondValue = toDate.toISOString().replace('Z', '');
        let filter: Filter = new Filter('Delivery End Date', 'deliveryEndDate', 'betweendates',
            firstValue, secondValue);
        this.onApplyAdvancedFiltervalue.push(filter);
    }


    /**
     * Refresh grid emitter
     * @param value
     */
    refreshGridEmit(value: String) {
        if (value === 'filter') {
            this.refreshGrid = true;
        }
        this.refreshGrid = false;
    }

    private getIndex(obj: any, afterValues: any[] = []) {
        let index = 0;
        afterValues.forEach(function (value) {
            if (obj[value] !== 0) {
                index += 2;
            }
        });
        return index;
    }


    onClose(value: any) {
        this.tradeId = '';
        this.display = false;
    }

    /**
     * fetch trade id from rowData ehen clicked on uniquew click
     * @param value
     */
    onUniqueClick(value: any) {
        if (value['column']['field'] === 'plannedObligationId') {
        } else {
            if (!value['editMode']) {
                this.tradeId = value['rowData']['tradeId'];
                this.rowData = value['rowData'];
                this.display = true;
            }
        }
    }


    removeFilterFromAddedList(type) {
        this.addedFilter.splice(this.addedFilter.indexOf(type), 1);
        if (type === 'Confirmed' || type === 'Unconfirmed') {
            this.grid.removeFilter('tradeStatus');
        } else {
            this.grid.removeFilter('tradeObligationState');
        }
    }


    onRefreshComplete(value: any) {
        this.refreshGrid = false;
        if (value === 'filter') {
            this.refreshFilter = false;
        }
    }

    getTypeLabel(type: any) {
        if (type === 'PLANNED') {
            return 'Planned Trade';
        } else if (type === 'PRICED') {
            return 'Priced Allocated';
        } else if (type === 'DELIVERYSTARTED') {
            return 'Delivery Started';
        } else if (type === 'INVOICED') {
            return 'Invoice Initiated';
        }
    }

    sendTotalElement(value: any) {
        this.totalElements = value;
    }

    /**
     * get grid columns
     */

    private getColumns() {
        let _this = this;
        let df = this.quantityRoundOff;
        let tradeQuantityOutputFunc = function (value: any[] = []) {
            return _this.commonService.formatNumberWithoutComma(value[0], df) + ' ' + value[1];
        };
        let viewStateFunction = function (value: any[] = []) {
            if (value[0] !== undefined && value[0] !== null) {
                if (_this.addedFilter.includes('CONFIRMED')) {
                    return "Confirmed";
                }
            } else {
                if (value[1] !== undefined && value[1] !== null) {
                    if (value[1]['DELIVERY_STARTED'] && _this.addedFilter.includes('DELIVERYSTARTED')) {
                        return 'Delivery Started';
                    } else if (value[1]['PRICED'] && _this.addedFilter.includes('PRICED')) {
                        return 'Priced';
                    } else if (value[1]['PLANNED'] && _this.addedFilter.includes('PLANNED')) {
                        return 'Planned';
                    } else if (value[1]['ACTUALIZED'] && _this.addedFilter.includes('ACTUALIZED')) {
                        return 'Actualized';
                    } else if (value[1]['SETTLED'] && _this.addedFilter.includes('SETTLED')) {
                        return 'Settled';
                    } else if (value[1]['PARTIALLY_PRICED'] && _this.addedFilter.includes('PARTIALLYPRICED')) {
                        return 'Partially Priced';
                    } else if (value[1]['STOCKED'] && _this.addedFilter.includes('STOCKED')) {
                        return 'Stocked';
                    } else if (value[1]['FX_ALLOCATED'] && _this.addedFilter.includes('FXALLOCATED')) {
                        return 'Fx Allocated';
                    } else if (value[1]['PARTIALLY_SETTLED'] && _this.addedFilter.includes('PARTIALLYSETTLED')) {
                        return 'Partially Setteled';
                    } else if (value[1]['PARTIALLY_FX_ALLOCATED'] && _this.addedFilter.includes('PARTIALLYFXALLOCATED')) {
                        return 'Partially Fx Allocated';
                    } else {
                        return 'Invoice Initiated';
                    }
                }
            }
        };


        this.columns = new Map();
        this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
        this.columns.set('tradeId', new Tcolumn('tradeId', 'Trade ID', 'LB', 1, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('planId', new Tcolumn('planId', 'Plan ID', 'LB', 2, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('plannedObligationId', new Tcolumn('plannedObligationId', 'Obligation ID', 'LB', 3, true, undefined, true, new defaultValue('', undefined, undefined, undefined, false, false, false), 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('tradeTransactionType', new Tcolumn('tradeTransactionType', 'Buy/Sell', 'OB', 4, true, this.masterCommonService.getBooleanFromLabel('Buy', 'Sell', false, 'BUY', 'SELL'), true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('tradeDateTime', new Tcolumn('tradeDateTime', 'Trade Date', 'D', 5, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('commodity', new Tcolumn('commodity', 'Commodity', 'LB', 6, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('counterparty', new Tcolumn('counterparty', 'Counter Party', 'LB', 7, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('profitcenter', new Tcolumn('profitcenter', 'Profit Center', 'LB', 8, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('deliveryStartDate', new Tcolumn('deliveryStartDate', 'Delivery Start Date', 'D', 9, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('deliveryEndDate', new Tcolumn('deliveryEndDate', 'Delivery End Date', 'D', 10, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('shipmentMonth', new Tcolumn('shipmentMonth', 'Shipment Month', 'LB', 11, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('loadLocation', new Tcolumn('loadLocation', 'Load Port', 'LB', 12, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('unloadLocation', new Tcolumn('unloadLocation', 'Unload Port', 'LB', 13, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('incoterm', new Tcolumn('incoterm', 'Incoterm', 'LB', 14, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('plannedQuantity', new Tcolumn('plannedQuantity', 'Quantity', 'N', 15, false, undefined, true, new defaultValue('', undefined, undefined, undefined, false, true, false, tradeQuantityOutputFunc, ['plannedQuantity', 'quantityUOM']), 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('tradePricingStatus', new Tcolumn('tradePricingStatus', 'Trade Pricing Status', 'OB', 16, true, [new KeyValue(), new KeyValue('Not Priced', 'Not Priced'), new KeyValue('Partially Priced', 'Partially Priced'), new KeyValue('Priced', 'Priced')], true, new defaultValue('', undefined, undefined, undefined, false, false, false), 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('priceAllocationStatus', new Tcolumn('priceAllocationStatus', 'Price Allocation Status', 'LB', 17, true, undefined, true, new defaultValue('', undefined, undefined, undefined, false, false, false), 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('transportAllocationStatus', new Tcolumn('transportAllocationStatus', 'Transport Allocation Status', 'OB', 18, true, [new KeyValue(), new KeyValue("Allocated", true), new KeyValue("Not Allocated", false)], true, new defaultValue('', undefined, undefined, undefined, false, false, false), 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('actualizationStatus', new Tcolumn('actualizationStatus', 'Actualization Status', 'OB', 19, true, [new KeyValue(), new KeyValue('Not Actualized', false), new KeyValue('Actualized', true)], true, new defaultValue('', undefined, undefined, undefined, false, false, false), 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('invoicingStatus', new Tcolumn('invoicingStatus', 'Invoicing Status', 'OB', 20, true, [new KeyValue(), new KeyValue('Not Invoiced', false), new KeyValue('Invoiced', true)], true, new defaultValue('', undefined, undefined, undefined, false, false, false), 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('fxAllocationStatus', new Tcolumn('fxAllocationStatus', 'FX Allocation Status', 'N', 20, true, undefined, true, new defaultValue('', undefined, undefined, undefined, false, false, false), 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('tradeType', new Tcolumn('tradeType', 'Trade Type', 'LB', 21, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('company', new Tcolumn('company', 'Company', 'LB', 22, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('trader', new Tcolumn('trader', 'Trader Name', 'LB', 23, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('cropOrigin', new Tcolumn('cropOrigin', 'Origin', 'LB', 24, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('grade', new Tcolumn('grade', 'Grade', 'LB', 25, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('priceType', new Tcolumn('priceType', 'Price Type', 'LB', 26, false, undefined, true, new defaultValue('', undefined, undefined, undefined, false, true, false, undefined, ['tradeObligationState', 'obligationState']), 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('tradePrice', new Tcolumn('tradePrice', 'Price', 'N', 27, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('packaging', new Tcolumn('packaging', 'Packaging', 'LB', 29, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    }

    toggleDateRange() {
        this.showDateRange = !this.showDateRange;
    }

    onPresetChange(preset: any) {
        this.refreshGrid = false;
        this.selectedPreset = preset;
        this.toggleDateRange();
        this.createGridFilters();
        this.refreshGrid = true;
    }

    /**
     * on column preference change rename
     * @param value
     */
    onFilterChange(value: any) {
        this.refreshGrid = false;
        let type = value['title'];
        let selected = value['selected'];
        if (type === 'Plan Id') {
            this.planIdList = selected;
        } else if (type === 'Trade Id') {
            this.tradeIdList = selected;
        } else if (type === 'Obligation Id') {
            this.plannedObligationIdList = selected;
        } else if (type === 'Delivery End Date') {
            this.deliveryDateList = selected;
        } else if (type === 'Commodity') {
            this.commodityList = selected;
        } else if (type === 'Profitcenter') {
            this.profitcenterList = selected;
        } else if (type === 'Counter Party') {
            this.counterpartyList = selected;
        } else if (type === 'Trader') {
            this.traderList = selected;
        } else if (type === 'Load Port') {
            this.loadPortList = selected;
        } else if (type === 'Discharge Port') {
            this.unloadPortList = selected;
        } else if (type === 'Shipment Month') {
            this.shipmentMonthList = selected;
        } else if (type === 'Buy/Sell') {
            this.tradeTransactionTypeList = selected;
        }
        this.refreshGrid = true;
    }

    onCancel(value) {
        this.showDateRange = false;
    }

    getPresetTitle() {
        if (this.selectedPreset['title'] === 'Date Range') {
            return (this.commonService.getFormattedDate(this.selectedPreset['startDate'], 'dd MMMM yyyy') + '-' + this.commonService.getFormattedDate(this.selectedPreset['endDate'], 'dd MMMM yyyy'));
        }
        return this.selectedPreset['title']
    }

    generateInvoice() {
        let _this = this;
        this.commonService.getJSONByURL(environment.base_url + '/api/trade/v1/createinvoiceobjectbytrade?tradeId=' + this.proFormaInvoiceRow['tradeId'] + '&tenantId=' + this.commonService.getFromStorage('tenantId') + '&username=' + this.commonService.getFromStorage('userName') + '&advanceInvoice=' + false).subscribe((next: any) => {
            _this.invoiceProFormaObject = next;
            _this.displayInvoiceForm = true;
        }, (error: HttpErrorResponse) => {
            _this.showToast(_this.commonService.getHttpErrorMessage(error, "Trade"), 'error');
        });
    }

    onDraftClose() {
        this.selectedRows = [];
        this.displayInvoiceForm = false;
    }

    onGenerateDone() {
        this.selectedRows = [];
        this.displayInvoiceForm = false;
        this.messageService.add({severity: 'info', summary: 'Invoice Generated Successfully'});
    }

    onHeightRefreshDone() {
        this.gridHeightRecalculate = false;
    }

    onMouseOverPlannedObligationIcon(event: MouseEvent, plannedObligationoverlayPanel: OverlayPanel, rowData: any) {
        this.documents = [];
        this.overlayPanelRowData = rowData;
        this.displayBasic = true;
        plannedObligationoverlayPanel.toggle(event);
        this.commonService.getJSONByURL(environment.base_url_new + '/api-bm/api/doctemplateservice/v1/getattachmentbybusinessid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&businessId=' + this.overlayPanelRowData['plannedObligationId'] + "&skipDocument=false").subscribe((next: any) => {
            if (next.length === 0) {
                this.documents.push({label: 'No Attachment'});
            } else {
                for (var document of next) {
                    this.documents.push({
                        label: document.businessApplicationName + '_' + document.businessApplicationId,
                        value: document.businessApplicationName,
                        mimeType: document['mimeType'],
                        document: document
                    });
                }
            }
        });
    }


    downloadFile(data, mimeType, fileName) {
        let base64 = "data:" + mimeType + ";base64," + data;
        const linkSource = base64;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    openAttachment(value: any) {
        if (value.label !== 'No Attachment') {
            this.downloadFile(value.document.document, value.mimeType, value.label);
        } else
            this.showToast('No Attachment.', 'error');
    }

    onMouseOverPriceAllocationStatusIcon(event: MouseEvent, priceAllocationStatusoverlayPanel: OverlayPanel, rowData: any) {
        this.overlayPanelRowData = rowData;
        this.displayBasic = true;
        priceAllocationStatusoverlayPanel.toggle(event);
    }

    onMouseActualizationStatusIcon(event: MouseEvent, actualizationStatusoverlayPanel: OverlayPanel, rowData: any) {
        this.overlayPanelRowData = rowData;
        this.displayBasic = true;
        actualizationStatusoverlayPanel.toggle(event);
    }

    onMouseInvoicingStatusIcon(event: MouseEvent, invoicingStatusoverlayPanel: OverlayPanel, rowData: any) {
        this.overlayPanelRowData = rowData;
        this.displayBasic = true;
        invoicingStatusoverlayPanel.toggle(event);
    }

    clearAllFilter() {
        let _this = this;
        let filterAdded = [...this.addedFilter];
        filterAdded.forEach(function (type) {
            _this.removeFilterFromAddedList(type);
        });
        this.refreshFilter = true;
        this.refreshGrid = false;
        this.createGridFilters();
        this.refreshGrid = true;
        this.addedFilter = ['PLANNED', 'PRICED', 'DELIVERYSTARTED', 'INVOICED'];
    }

    //Price Fixation Hover Icon
    //To show Respective ICONS based on the criteria of Pricing Status .!
    iconPath(rowdata) {
        if (rowdata.priceType == 'Basis' || rowdata.priceType == 'PTBF') {
            if (rowdata['pricingStatus'] !== undefined && rowdata['pricingStatus'] !== null) {
                if (rowdata.pricingStatus === 'Not Priced') {
                    if (!(rowdata.provisionalPriceType === null || rowdata.provisionalPriceType === undefined || rowdata.provisionalPriceType.length === 0) && rowdata.pricingStatus === 'Not Priced') {
                        return "Provisional Not Priced";
                    } else {
                        return "Not Priced";
                    }
                } else if (rowdata.pricingStatus === 'Partially Priced') {
                    if (rowdata.provisionalPricing === true && rowdata.pricingStatus === 'Partially Priced') {
                        return "Provisional Partially Priced";
                    } else {
                        return "Partially Priced";
                    }

                } else if (rowdata.pricingStatus === 'Fully Priced') {
                    if (rowdata.provisionalPricing === true && rowdata.pricingStatus === 'Fully Priced') {
                        return "Provisional Fully Priced";
                    } else {
                        return "Fully Priced";
                    }
                }
            } else {
                return rowdata.provisionalPricing ? "Provisional Not Priced" : "Not Priced";
            }
        } else
            return "Fixed Priced";

    }

    private createLoadFunctions() {
        let _this = this;
        this.tradePricingLoadApiFunction = function (value) {
            let iconName = value['iconName'];
            let obj = {};
            if (iconName !== 'Not Priced' && iconName !== 'Provisional Not Priced') {
                obj['type'] = "GET";
                obj['url'] = environment.base_url + '/api/advance-pricing/v1/get-trade-by-tradeid-v2?tenantId=' + _this.commonService.getFromStorage('tenantId') + '&tradeId=' + value['rowData']['plannedObligationId'];
                obj['payload'] = {};
            }
            return obj;
        }
        this.transportAllocationLoadApiFunction = function (value) {
            let iconName = value['iconName'];
            let obj = {};
            if (iconName === 'Transport Allocation Done') {
                obj['type'] = "GET";
                obj['url'] = environment.base_url + '/api/transportallocationv2/v1/getvesseldetailsbyplanobligationid?tenantId=' + _this.commonService.getFromStorage('tenantId') + '&plannedObligationId=' + value['rowData']['plannedObligationId'];
                obj['payload'] = {};
            }
            return obj;
        }
        this.actualizationLoadApiFunction = function (value) {
            let iconName = value['iconName'];
            let obj = {};
            if (iconName === 'Actualization Done') {
                obj['type'] = "GET";
                obj['url'] = environment.base_url + '/api/actualization/v1/getactualizedqtybyplannedobligationid?tenantId=' + _this.commonService.getFromStorage('tenantId') + '&plannedObligationId=' + value['rowData']['plannedObligationId'];
                obj['payload'] = {};
            }
            return obj;
        }

        //getinvoiceinfobyplannedobligationid
        this.invoiceLoadapi = function (value) {
            let iconName = value['iconName'];
            let obj = {};
            if (iconName === 'Final invoiced' || iconName === 'Final Invoiced_provisonal' || iconName === 'Provisional invoiced only') {
                obj['type'] = "GET";
                obj['url'] = environment.base_url + '/api/invoice/v1/getinvoiceinfoforoperationDashboard?tenantId=' + _this.commonService.getFromStorage('tenantId') + '&plannedObligationId=' + value['rowData']['plannedObligationId'];
                obj['payload'] = {};
            }
            return obj;
        }

        this.fxAllocationLoadApiFunction = function (value) {
            let iconName = value['iconName'];
            let obj = {};
            if (iconName !== 'FX Allocation Pending') {
                obj['type'] = "GET";
                obj['url'] = environment.base_url + '/api/tradeobligation/v1/getfxallocationhoverdetails?tenantId=' + _this.commonService.getFromStorage('tenantId') + '&tradeId='
                    + value['rowData']['tradeId'] + '&plannedObligationId='
                    + value['rowData']['plannedObligationId'];
                obj['payload'] = {};
            }
            return obj;
        }

    }

    //To open Price fixation Tab
    openPriceTab(tradeId: any = '') {
        this.router.navigate(['/priceFixing'], {queryParams: {value: tradeId, buttonName: 'Price Fixation'}});
    }

    //To open pricefixation Details
    openPriceInfo(tradeId: any = '') {
        this.router.navigate(['/priceFixing'], {
            queryParams: {
                value: tradeId,
                buttonName: 'View Trade Pricing Details'
            }
        });
    }

    //To open Price Allocation Tab
    openPriceAllocationTab(tradeId: any = '') {
        this.router.navigate(['/priceAllocation'], {queryParams: {value: tradeId, buttonName: 'Price Allocation'}});
    }

    //To show Price Allocation Details
    openPriceAllocationInfo(tradeId: any = '') {
        this.router.navigate(['/priceAllocation'], {
            queryParams: {
                value: tradeId,
                buttonName: 'See All PriceAllocation Details'
            }
        });
    }


    onButtonClick(value: any) {
        let buttonName = value['name'];
        let iconName = value['iconName'];
        let rowData = value['rowData'];
        let dbData = value['dbData'];
        if (buttonName === 'Price Fixation') {
            this.openPriceTab(rowData['tradeId']);
        }
        if (buttonName === 'View Trade Pricing Details') {
            this.openPriceInfo(rowData['tradeId'])
        }
        if (buttonName === 'Price Allocation') {
            this.openPriceAllocationTab(rowData['tradeId']);
        }
        if (buttonName === 'See All PriceAllocation Details') {
            this.openPriceAllocationInfo(rowData['tradeId']);
        }
        if (buttonName === 'Request for Trade Pricing') {
            let _this = this;
            this.commonService.sendNotfication('Price Fixation Pending for Trade Id : ' + rowData['tradeId']).subscribe((next) => {
                _this.showToast('Notification Sent Successfully');
            }, (error: HttpErrorResponse) => {
                _this.showToast('Failed to send Notification.', 'error');
            });
        }
    }

    onButtonClickInvoice(value: any) {
        let buttonName = value['name'];
        let iconName = value['iconName'];
        let rowData = value['rowData'];
        let dbData = value['dbData'];
        if (buttonName === 'View Invoice Details') {
            let tab = "pending"
            let invoiceStage = dbData['invoiceStage'].toLowerCase();
            if (invoiceStage === 'approved') {
                tab = 'approved';
            } else if (invoiceStage === 'settled') {
                tab = 'settled';
            }
            this.router.navigate(['/invoices'], {queryParams: {mode: tab, invoiceNumber: dbData['invoiceNumber']}});
        } else if (buttonName === 'Final Invoicing' || buttonName === 'Provisional Invoicing') {
            this.openSettlementPage(rowData);
        }

    }

    showToast(msg, severity: string = 'success') {
        this.messageService.add({
            severity: severity,
            summary: msg
        });
    }

    onHover(value: any) {
        let panel: OverlayPanel = value['panel'];
        let planObligationId: any = value['rowData']['plannedObligationId'];
        let icon = value['name'];
        if (value['rowData']['tradePriceCurrency'] === value['rowData']['settlementCurrency']) {
            this.fxnotapplicable = true
        } else {
            this.fxnotapplicable = false
        }
        if (this.hoverPlannedObligation !== planObligationId || this.hoverIcon !== icon) {
            if (this.overlayPanelTemp !== null && this.overlayPanelTemp !== undefined) {
                this.overlayPanelTemp.hide();
            }
        }
        this.hoverPlannedObligation = planObligationId;
        this.hoverIcon = icon;
        this.showBookAVessel = false;
        this.actualizationVesselBooking = false;
        this.overlayPanelTemp = panel;
    }

    //Transport Allocation Hover Icon
    transportHeadingFunction(value) {
        if (value['rowData']['planMatchType'] !== 'Washout') {
            if (value['rowData']['obligationState']['DELIVERY_STARTED'] !== false) {
                return "Done"
            } else {
                return "Pending"
            }
        }
    }

    deliveryIconPath(rowData) {
        if (rowData['planMatchType'] !== 'Washout') {
            if (rowData['obligationState']['DELIVERY_STARTED'] !== false) {
                return "Transport Allocation Done"
            } else {
                return "Transport Allocation Pending"
            }
        } else {
            return "Washout Plan"
        }

    }

    transportButtonClick(rowData: any) {
        // this.router.navigate(['/transportAllocation'],{queryParams:{mode:'allocated'}});
    }

    checkAllocation(rowData: any) {
        this.showBookAVessel = false;
        this.commonService.getJSONByURL(environment.base_url + '/api/transportallocationv2/v1/getvesseldetailsbyplanobligationid?tenantId=' + this.commonService.getFromStorage('tenantId') +
            '&plannedObligationId=' + rowData['plannedObligationId']).subscribe((next: any) => {
            if (next['status'] !== undefined && next['status'] !== null && next['status'] === 'Not Allocated to any Vessel.') {
                // this.router.navigate(['/transportAllocation'],{queryParams:{mode:'filter',loadport:rowData['loadLocation'],
                //     unloadport:rowData['unloadLocation']}});
            }
        }, error => {
            this.showBookAVessel = true;
        });

    }

    bookAVessel() {
        this.router.navigate(['/transportAllocation'], {queryParams: {mode: 'new'}});
    }


    //To show Respective ICONS based on the criteria of PriceAllocationStatus .!
    priceAllocationiconPath(rowData) {
        if (rowData.priceType == 'Basis' || rowData.priceType == 'PTBF') {
            if ((rowData.pricingStatus === 'Not Priced' && rowData.priceAllocationstatus === 'Not Allocated')) {
                return "Not Priced";
            }
            return rowData.priceAllocationstatus;
            // else if(rowData.priceAllocationstatus==='Not Allocated'){
            //   return "Not Allocated";
            // }
            // else if(rowData.priceAllocationstatus==='Partially Allocated'){
            //   return "Partially Allocated";
            // }
            // else{
            //   return "Fully Allocated";
            // }
        } else
            return "Fixed Priced";
    }


    //Transport Actualization Hover Icon

    actualizationIconPath(rowData) {
        if (rowData['obligationState']['ACTUALIZED'] !== false) {
            return "Actualization Done"
        } else {
            return "Actualization Pending"
        }
    }

    actualizationHeadingFunction(value) {
        if (value['rowData']['obligationState']['ACTUALIZED'] !== false) {
            return "Done"
        } else {
            return "Pending"
        }
    }

    actualizationtButtonClick(rowData: any) {
        this.actualizationVesselBooking = false
        if (rowData['planMatchType'] !== 'Washout') {
            this.commonService.getJSONByURL(environment.base_url + '/api/transportallocationv2/v1/getvesseldetailsbyplanobligationid?tenantId=' + this.commonService.getFromStorage('tenantId') +
                '&plannedObligationId=' + rowData['plannedObligationId']).subscribe((next: any) => {
                if (next['status'] !== undefined && next['status'] !== null && next['status'] === 'Not Allocated to any Vessel.') {
                    this.router.navigate(['/transportAllocation'], {
                        queryParams: {
                            mode: 'filter', loadport: rowData['loadLocation'],
                            unloadport: rowData['unloadLocation']
                        }
                    });
                } else {
                    this.router.navigate(['/transportActualization'], {queryParams: {vesselId: next['vesselId']}});
                }
            }, error => {
                this.actualizationVesselBooking = true;
            });
        } else {
            this.router.navigate(['/tradeActualization'], {queryParams: {value: rowData['planId'], openForm: true}});
        }
    }

    onActualizationDoneClick(value: any) {
        if (value['name'] === 'View Actualization Details') {
            this.actualizationtButtonClick(value['rowData']);
        }
    }

    //invoice hover icon
    invoiceIconPath(rowData) {
        if (rowData['invoiceStatus'].toLowerCase() === 'invoice generated') {
            return "Final invoiced"
        } else if (rowData['invoiceStatus'].toLowerCase() === 'invoice final provisional') {
            return "Final Invoiced_provisonal";
        } else if (rowData['invoiceStatus'].toLowerCase() === 'invoice provisional') {
            return "Provisional invoiced only"
        } else if (rowData['invoiceStatus'].toLowerCase() === 'invoice pending final' || rowData['invoiceStatus'].toLowerCase() === 'invoice pending provisional') {
            return "Pending invoice"
        } else if (rowData['invoiceStatus'].toLowerCase() === 'invoice proforma') {
            return "Proforma invoiced"
        }
    }

    invoiceheaderfunction(rowData) {
        if (rowData['rowData']['invoiceStatus'].toLowerCase() === 'invoice generated' || rowData['rowData']['invoiceStatus'].toLowerCase() === 'invoice final provisional') {
            return "Final Invoiced"
        } else if (rowData['rowData']['invoiceStatus'].toLowerCase() === 'invoice provisional') {
            return "Provisional Invoiced"
        } else if (rowData['rowData']['invoiceStatus'].toLowerCase() === 'invoice pending final' || rowData['rowData']['invoiceStatus'].toLowerCase() === 'invoice pending provisional') {
            return "Not Invoiced"
        }

    }

    // FX Allocation Hover
    //To show Respective ICONS based on the criteria of FX Allocation Status .!
    iconPathFXAllocation(rowdata) {
        if ((!rowdata['obligationState']['FX_ALLOCATED'] && !rowdata['obligationState']['PARTIALLY_FX_ALLOCATED'])
            && rowdata['settlementCurrency'] == rowdata['tradePriceCurrency']) {
            return 'FX Allocation Pending';
        } else if (!rowdata['obligationState']['FX_ALLOCATED'] && !rowdata['obligationState']['PARTIALLY_FX_ALLOCATED']
            && rowdata['settlementCurrency'] != rowdata['tradePriceCurrency']) {
            return 'FX Allocation Pending';

        } else if (rowdata['obligationState']['PARTIALLY_FX_ALLOCATED'] && !rowdata['obligationState']['FX_ALLOCATED']
            && rowdata['settlementCurrency'] != rowdata['tradePriceCurrency']) {
            return 'FX Partially Allocated';
        } else {
            return 'FX Allocation Done';
        }
    }

    fxHeadingFunction(value) {
        if (value['rowData']['tradePriceCurrency'] === value['rowData']['settlementCurrency']) {
            return "FX Allocation Not Applicable"
        } else if (value['rowData']['obligationState']['FX_ALLOCATED'] === false &&
            value['rowData']['obligationState']['PARTIALLY_FX_ALLOCATED'] === false) {
            return "Pending"
        } else {
            return "FX Allocation Details"
        }
    }

    onButtonClickFXAllocation(value) {
        let buttonName = value['name'];
        if (buttonName === 'FX Allocation') {
            this.router.navigate(['/fxPlanning']);
        }
        if (buttonName === 'View FX Allocation') {
            this.router.navigate(['/fxPlanning'], {queryParams: {mode: 'allocated'}});

        }
    }

    hyperLinkFxAllocation(value: any) {
        this.router.navigate(['/fxPlanning'], {queryParams: {mode: 'allocated'}});
    }

    openSettlementPage(rowData: any) {
        this.router.navigate(['/settlement'], {
            queryParams: {
                plannedObligationId: rowData['plannedObligationId'],
                expand: true
            }
        });
    }

    //Amend-Modfiy Form
    onCloseForm() {
        this.refreshGrid = true;
        this.displayAmendForm = false;
        this.declarePort = false;
    }

    openAmendForm(value: any) {
        if (value !== undefined && value !== null) {
            this.commonService.getJSONByURL(environment.base_url + '/api/plannedObligation/v1/getplannedobligationsbyplannedobligationid?tenantId=' +
                this.commonService.getFromStorage('tenantId') + '&plannedObligationId=' + value['plannedObligationId']).subscribe((next: any) => {
                this.planObligationValue = next;
                this.declarePort = false;
                this.displayAmendForm = true;
                this.ammendRowdata = value;
            });
        }
    }

    onCloseCost() {
        this.showAddCost = false;
    }

    onclickRouter() {
        this.router.navigate(['/tradeObligation']);
    }

    onClickMenuItem(item: any) {
        let title = item.title;
        let rowData = item.data;
        this.selectedContextData = rowData;
        let _this = this;
        if (title === 'Trade Planning') {
            let tradeObligationId = rowData['tradeObligationId'];
            _this.router.navigate(['/planning'], {queryParams: {value: tradeObligationId, mode: 'planning'}});
        } else if (title === 'Actualization') {
            let planId = rowData['planId'];
            if (planId !== null && planId !== undefined && planId.length > 0) {

                let url = `/actualization/${encodeURIComponent(rowData.planId)}`;
                window.location.href = '/ctrm-v2/#/transportActualization?tabId=actulization'
                // _this.router.navigate(['/tradeActualization'],{ queryParams: { value:planId}});
            }
        } else if (title === 'Settlement') {
            let plannedObligationId = rowData['plannedObligationId'];
            if (plannedObligationId !== null && plannedObligationId !== undefined && plannedObligationId.length > 0) {
                _this.router.navigate(['/settlement'], {queryParams: {value: plannedObligationId}});
            }
        } else if (title === tradeOptionsButtons.generateProFormaInvoice) {
            _this.proFormaInvoiceRow = rowData
            _this.generateInvoice();
        } else if (title === 'Add Cost') {
            let plannedObligationId = rowData['plannedObligationId'];
            if (plannedObligationId !== null && plannedObligationId !== undefined && plannedObligationId.length > 0) {
                _this.linkTo = plannedObligationId;
                _this.showAddCost = true;
            }
        } else if (title === 'Washout Planning (Doc ByPass)') {
            let plannedObligationId = rowData['plannedObligationId'];
            if (plannedObligationId !== null && plannedObligationId !== undefined && plannedObligationId.length > 0) {
                _this.linkTo = plannedObligationId;
                _this.planId = rowData['planId'];
                this.washoutPlanning = true;
                if (rowData['tradeTransactionType'] == "BUY") {
                    this.sellObligationRequired = false;
                    this.buyObligationRequired = true;
                    this.buyObligation = rowData;
                    this.sellObligation = {};
                } else {
                    this.buyObligationRequired = false;
                    this.sellObligationRequired = true;
                    this.sellObligation = rowData;
                    this.buyObligation = {};
                }
            }
        } else if (title === 'Inter-company trade') {
            let plannedObligationId = rowData['plannedObligationId'];
            if (plannedObligationId !== null && plannedObligationId !== undefined && plannedObligationId.length > 0) {
                _this.linkTo = plannedObligationId;
                _this.planId = rowData['planId'];
                this.interCompanyTrade = true;
                this.obligation = rowData;
            }
        } else if (title == 'Declare Port') {
            this.planObligationValue = rowData;
            this.displayAmendForm = true;
            this.ammendRowdata = rowData;
            this.declarePort = true;
        } else if (title === 'Generate Operation Documents') {
            let plannedObligationId = rowData['plannedObligationId'];
            if (plannedObligationId !== null && plannedObligationId !== undefined && plannedObligationId.length > 0) {
                _this.linkTo = plannedObligationId;
                _this.planId = rowData['planId'];
                this.generateDocument = true;
                this.continueLoad();
            }
        } else if (title === 'Merge Obligations') {
            this.refreshGrid = true;
        } else if (title === "Split Obligations") {
            this.splitQuantityValue = this.selectedContextData['plannedQuantity'];
            if (rowData.tradeSettlementReference != null && !rowData.obligationState['PLANNED'] && !rowData.obligationState['PARTIALLY_PRICED'] && !rowData.obligationState['PRICED']) {
                this.showInterCompanySplitPopUp = true;
            } else {
                this.showSplitPopup = true;
            }


        }
    }

    closeModal() {
        this.documentType = '';
        this.documentFormat = '';
        this.documentTemplate = [];
        this.generateDocument = false;
    }

    continueLoad() {
        let _this = this;
        this.commonService.getLoadData('commodity', ['data'], [{
            key: 'name',
            value: 'doc_template_business_application'
        }]).subscribe((next: any) => {
            _this.documentTypeList = _this.masterCommonService.getListFromArray(next['doc_template_business_application'], false, true);
        });
    }


    onChangeInstruction(value: any) {
        this.documentFormatList = [];
        this.getDocFormatList();
        if (value === 'Bill Of Lading') {
            this.documentTypeDisabled = true;
            this.documentTemplate = [];
            this.shippingFormButtonLabel = 'Manage BL';
        } else {
            this.getDocumenttypeList(value);
            this.documentTypeDisabled = false;
            this.shippingFormButtonLabel = 'Generate';
        }
    }

    getDocumenttypeList(value) {
        let _this = this;
        _this.commonService.getJSONByURL(environment.base_url_new + '/api-bm/api/doctemplateservice/v1/getdocumentbybusinessname?tenantId=' + this.commonService.getFromStorage('tenantId') + '&businessApplicationName=' + value).subscribe((next: any) => {
            _this.documentTemplateList = _this.commonService.createListFromObject(next, 'docTemplateName', 'docTemplateName', false);
        });
    }

    generateDocumentFunc() {
        if (this.shippingFormButtonLabel === 'Manage BL') {
            window.location.href = '/ctrm-v2/#/actualization/' + this.selectedContextData['planId'] + '?tabId=BL';
        } else {
            this.commonService.getJSONByURL(environment.base_url + '/api/plannedObligation/v1/generateshipmentdocument?plannedObligationId=' + this.linkTo + "&tenantId=" + this.commonService.getFromStorage('tenantId') + '&shipmentDocument=' + this.documentType + "&outputFormat=" + this.documentFormat + '&documentType=' + this.documentTemplate.map(u => u.value).join(',')).subscribe(next => {
                this.showToast(this.documentType + ' generated Successfully,To view the document navigate to Document tab');
                this.closeModal();
            }, (error: HttpErrorResponse) => {
                this.showToast(this.commonService.getHttpErrorMessage(error, 'Operation Dashboard '), 'error');
            });
        }
    }

    onCompleteDocByPass(value: any) {
        if(value.status=== 'Failed to save plan...!')   
        {
            this.messageService.add({
                severity: 'error',
                summary: 'Failed to save plan...!',
                detail: 'Failed to Create DocByPass'
            });
        }
       else
        this.messageService.add({
            severity: 'info',
            summary: 'Plan Created Successfully',
            detail: 'DocByPass Plan created successfully '
        });
        this.washoutPlanning = false;

    }

    onCancelDocByPass() {
        this.washoutPlanning = false;
    }

    onCompleteInterCompanyTrade(value: any) {
        this.interCompanyTrade = false;
    }

    onCancelInterCompanyTrade() {
        this.interCompanyTrade = false;
    }


    disableGenerate() {
        if (this.documentType == 'Bill Of Lading') {
            return false;
        } else if (this.documentType != '' && !(this.documentTemplate.length > 0)) {
            return true;
        } else if (this.documentType != '' && this.documentTemplate.length > 0) {
            return false;
        } else {
            return true;
        }
    }

    getDocFormatList() {
        if (this.documentType !== null && this.documentTemplate !== null && this.documentType !== undefined && this.documentTemplate !== undefined && this.documentType !== '' && this.documentTemplate.length > 0) {
            let _this = this;
            let payload = [];
            payload.push({fieldName: "tenantId", condition: "equals", value: this.commonService.getTenantId()});
            payload.push({fieldName: "businessApplication", condition: "equals", value: this.documentType});
            payload.push({fieldName: "docTemplateName", condition: "equals", value: this.documentTemplate[0].value});
            payload.push({fieldName: "status", condition: "equals", value: true});
            _this.commonService.post(environment.base_url_new + '/api-bm/api/doctemplateservice/v1/getDocumentTemplateFormat?tenantId=' + this.commonService.getFromStorage('tenantId'), payload).subscribe((next: any) => {
                const DocFormat = _this.commonService.createListFromObject(next, 'allowedOutputFormats', 'allowedOutputFormats', false);
                let tempFormat = JSON.parse(DocFormat[0].value);
                tempFormat['selected'].forEach(e => {
                    this.documentFormatList.push({label: e, value: e})
                })
                this.documentFormat = tempFormat['default']
            });
        }
    }

    closeSplitModal() {
        this.showSplitPopup = false;
    }

    closeInterCompanySplitModal() {
        this.showInterCompanySplitPopUp = false;
    }

    deleteInterCompanyForSplit(selectedData: any) {
        this.commonService.getJSONByObject(environment.base_url+"/api/interCompanyTrades/v1/deleteInterCompanyForSplitandMerge?uuid="+selectedData.tradeSettlementReference,selectedData).subscribe(next=>{
            this.showSplitPopup= true;
            this.showInterCompanySplitPopUp=false;
        })
    }

    splitQuantity() {
        let plannedQuantity = parseFloat(this.selectedContextData['plannedQuantity']);
        if (this.splitQuantityValue >= plannedQuantity) {
            this.showToast('Split Quantity can not be equal or greater than ' + plannedQuantity, 'error');
        } else {
            let plannedObligationId = this.selectedContextData['plannedObligationId'];
            this.commonService.post(`${environment.base_url}/api/plannedObligation/v1/splitPlannedObligation?plannedObligationId=${plannedObligationId}&splitQuantity=${this.splitQuantityValue}`, {}).subscribe((next) => {
                if (next['errorInstance']) {
                    this.showToast(next['status'], 'error');
                } else {
                    this.commonService.postMessage('', `Successfully Splitted obligation '${plannedObligationId}'`, 'success');
                    this.showSplitPopup = false;
                    this.refreshGrid = true;
                }
            }, (error: HttpErrorResponse) => {
                this.commonService.postMessage('Error while merge', this.commonService.getHttpErrorMessage(error), 'error');
            });
        }
    }

    OnSplitDone(next) {
        if (next['errorInstance']) {
            this.showToast(next['status'], 'error');
        } else {
            this.commonService.postMessage('', `Successfully Splitted obligation '${this.selectedContextData['plannedObligationId']}'`, 'success');
            this.showSplitPopup = false;
            this.refreshGrid = true;
        }
    }

    onErrorSplit(error) {
        this.commonService.postMessage('Error while merge', this.commonService.getHttpErrorMessage(error), 'error');
    }
}


