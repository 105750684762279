import {Component, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {Grid} from "../../grid/grid.model";
import {Tcolumn} from "../../grid/tcolumn.model";
import {KeyValue} from "../../grid/key-value.model";
import {environment} from "../../../environments/environment";
import {CommonService} from "../../services/common.service";
import {MasterService} from "../services/MasterService";
import {defaultValue} from "../../grid/defaultValue.model";


@Component({
  selector: 'app-incoterm',
  templateUrl: './incoterm.component.html',
  styleUrls: []
})
export class IncotermComponent implements OnInit {

  columns: Map<string, Tcolumn>;
  grid: Grid;
  groupList: KeyValue[] = [];
  loadDatalist:any[] = [];

  constructor(public commonService: CommonService, private masterCommonService: MasterService) {
  }

  ngOnInit() {
      let _this = this;
    this.commonService.getLoadData('incoterm',['data', 'data', 'data', 'data'],[[{key:'name', value:'incoterm_code_ind'}],[{key:'name', value:'transport_responsibility_ind'}],[{key:'name', value:'insurance_responsibility_ind'}],[{key:'name', value:'title_transfer_event_ind'}]]).subscribe( (next:any)=>{
     this.loadDatalist  = next;
      this.getColumns();
      this.grid = new Grid(this.columns, true, true, true, true, 'name', '/api/incoterm/v1/getall?tenantId='+this.commonService.getFromStorage('tenantId'), '', 'Incoterm');
      this.grid.setSaveUrl(environment.base_url + '/api/incoterm/v1/save');
        this.grid.hrefUrl = environment.base_url + '';
        this.grid.defineHrefFunction = function(value:any) {
            return _this.grid.hrefUrl = environment.base_url + '/api/incoterm/v1/getbyuuid?tenantId='+_this.commonService.getFromStorage('tenantId') + '&name=' + value['name'];
        }
      this.grid.importUrl = environment.base_url + '/api/incoterm/v1/import';
      this.grid.setGridConfiguration(false,false,false,false,false);
      this.grid.iconName='incoterm';
    });
  }

  public getColumns() {
    this.columns = new Map();
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('code', new Tcolumn('code', 'Incoterm Code', 'T', 1, false, undefined, true
          , new defaultValue('',undefined,['-','_'],undefined,false,true,false,undefined,undefined,true), 'B', [Validators.required, Validators.maxLength(8)], undefined, undefined, undefined, undefined, [], undefined, undefined, true));
    this.columns.set('name', new Tcolumn('name', 'Incoterm Name', 'L', 1, false, this.masterCommonService.getListFromArray(this.loadDatalist['incoterm_code_ind']), true, '', 'B', [Validators.required],undefined,undefined,undefined,undefined,undefined));
    this.columns.set('description', new Tcolumn('description', 'Incoterm Description', 'TA', 3, true, undefined, true, '', 'B', []));
    this.columns.set('transportResponsibility', new Tcolumn('transportResponsibility', 'Transport Responsibility', 'LB', 4, true, this.masterCommonService.getListFromArray(this.loadDatalist['transport_responsibility_ind']), true, '', 'B', [Validators.required]));
    this.columns.set('insuranceResponsibility', new Tcolumn('insuranceResponsibility', 'Insurance Responsibility', 'LB', 5, true, this.masterCommonService.getListFromArray(this.loadDatalist['insurance_responsibility_ind']), true, '', 'B', [Validators.required]));
    this.columns.set('titleTransferEvent', new Tcolumn('titleTransferEvent', 'Title Transfer Event', 'LB', 6, true,this.masterCommonService.getListFromArray(this.loadDatalist['title_transfer_event_ind']), true, '', 'B', [Validators.required]));
    this.columns.set('optionForInvoice', new Tcolumn('optionForInvoice', 'Option For Invoice', 'LB', 7, true, this.getOptionforInvoice(), true, '', 'B', [Validators.required]));
    this.columns.set('status', new Tcolumn('status', 'Status', 'B', 8, true, this.masterCommonService.getActiveInactive(), true, true, "B", [Validators.required]));
  }

  private getOptionforInvoice() {
    let groupList = [];
    groupList.push(new KeyValue("Select", ''));
    groupList.push(new KeyValue("Load Quantity", 'Load Quantity'));
    groupList.push(new KeyValue("Discharge Quantity", 'Discharge Quantity'));
    return groupList;
  }
}
