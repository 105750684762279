import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CommonService} from 'src/app/services/common.service';
import {environment} from 'src/environments/environment';
import {FileUpload} from 'primeng/fileupload';
import {AjaxResponse} from 'rxjs/Rx';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Tcolumn} from "../../tcolumn.model";
import {MessageService, Table} from "primeng";
import {ExcelServiceService} from "../../../services/excel-service.service";
import * as XLSX from "xlsx";
import {Tab} from "../../../tab-grid/Tab.model";
import {MasterService} from "../../../masters/services/MasterService";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-import-grid',
  templateUrl: './import-grid.component.html',
  styleUrls: ['./import-grid.component.css']
})
export class ImportGridComponent implements OnInit {

  @Input() display: boolean = false;
  @Output() onClose = new EventEmitter();
  @Output() onImport = new EventEmitter();
  @Input() importTableName: string = '';
  @Input() parentKeyValue: string = '';
  @Input() preImportFunction:Function = null;
  @Input() parentRowData: any;
  @Input() parentUuidFieldName: string = '';
  @Input() ignoreFieldList: string[] = [];
  @Input() directSave: boolean = true;
  @Input() tabs: Tab[] = [];
  @Input() importUrl: string = '';
  @Input() acceptedFileTypes: string[] = ['xlsx'];
  @Output() onClickImport = new EventEmitter();
  fileZile: number = environment.importFileSize;
  @Input() gridColumns: Tcolumn[];
  @Input() showPreview: boolean = false;
  @Input() importChildData: boolean = true;
  rowData: any[] = [];
  sanitizerURL: SafeUrl = undefined;
  public formData = new FormData();
  public tabRowData: Map<string, XLSX.WorkSheet> = new Map<string, XLSX.WorkSheet>();
  file: File;
  sheets: any[] = [];
  importFile: any[] = [];
  @ViewChild('fileupload') fileupload: FileUpload;
  invalidFile: boolean = false;
  GridHeight: any;
  @ViewChild('table') table: Table;
  switchDisplay: boolean = false;
  dataDisplay: boolean = false;
  showBrowser: boolean = true;
  currentIndex: number = 0;
  private id;
  private tenantId;
  private mainWorksheet: XLSX.WorkSheet;
  private columnHeaders: any[] = [];
  isimporting: boolean = false;
  importDisabled:boolean = true;
  splitFieldList = ['costsToAdd', 'costsToNet'];

  constructor(public commonService: CommonService, private sanitizer: DomSanitizer,private messageService: MessageService, private excelService: ExcelServiceService, private masterCommonService: MasterService) {
  }

  ngOnInit(): void {
    this.display = false;
    this.id = this.masterCommonService.getUserId();
    this.tenantId = this.commonService.getFromStorage('tenantId');
    this.refreshPreview();
  }

  uploadFiles(file: File[]) {

    var fileType = '';
    var fileName = '';
    var _this = this;
    this.invalidFile = false;
    this.file = undefined;
    this.sanitizerURL = undefined;
    for (let i = 0; i < file.length; i++) {
      fileName = file[i].name;
      fileType = fileName.substr(fileName.lastIndexOf('.') + 1);
      if (this.acceptedFileTypes.includes(fileType) && file[i].size <= _this.fileZile) {
        _this.file = file[i];
      }
    }
    if (this.file == undefined) {
      this.importDisabled = true;
      this.invalidFile = true;
    } else {
      this.switchDisplay = true;
      this.importDisabled = false;
      if (!this.invalidFile) {
        this.importFileIntoTable(this.file);
      }
    }
    return false;
  }

  closeImportPartial(status: boolean) {
    this.onImport.emit();
  }

  closeImport(status: boolean) {
    this.display = false;
    this.tabRowData = new Map();
    this.mainWorksheet = null;
    this.dataDisplay = false;
    this.rowData = [];
    this.switchDisplay = false;
    this.file = undefined;
    this.invalidFile = false;
    this.fileupload.clear();
    this.formData = new FormData();
    this.onImport.emit(status);
  }

  import() {
    if (this.directSave) {
      this.isimporting = true;
      let rowData = this.getRowDataFromWorkSheet(this.mainWorksheet, this.ignoreFieldList, this.gridColumns);
      this.importData(this.importUrl, rowData);
    } else {
      this.onClickImport.emit({file: this.file});
    }
  }

  close() {
    this.refreshPreview();
    this.display = false;
    this.file = undefined;
    this.invalidFile = false;
    this.fileupload.clear();
    this.formData = new FormData();
    this.onClose.emit();
  }

  ngOnChanges(changes: any) {
    if (changes['display']) {
      this.sanitizerURL = undefined;
      this.display = changes.display.currentValue;
    }
  }

  getRowValue(value: any, col: Tcolumn) {
    if (col.getColumnType() === 'N') {
      return parseFloat(value);
    }
    return value;

  }

  @HostListener("window:resize", [])
  public onResize() {
    this.calculateHeight();
  }
  calculateHeight() {
    if (this.table !== undefined && this.table !== null) {
      let offsetTop = this.table.el.nativeElement['offsetTop'];
      let offsetHeight = this.table.el.nativeElement['offsetParent']['offsetHeight'];
      this.GridHeight = (offsetHeight - (offsetTop + 100)) + 'px';
    }
  }

  refreshPreview() {
    this.dataDisplay = false;
    this.rowData = [];
    this.switchDisplay = false;
  }

  showPreviewGrid(value: any) {
    if (value['checked'] && !this.invalidFile) {
      this.importFileIntoTable(this.file, true);
    }
  }

  export(importTableName: string, exe: string) {
    this.excelService.ignoreFieldList = this.ignoreFieldList;
    this.excelService.exportTemplate(importTableName, this.gridColumns, exe, this.tabs, this.importChildData);
  }

  getAcceptedList() {
    let accepted = '';
    let _this = this;
    this.acceptedFileTypes.forEach(function (type, index) {
      accepted += '.' + type;
      if (index !== _this.acceptedFileTypes.length - 1) {
        accepted += ',';
      }
    });
    return accepted;
  }

  onIndexChange($event: number) {

  }

  getContext(columns: Tcolumn[], rowData: any[]) {
    return {columns: columns, value: rowData};
  }

  getTabColumns(tab: Tab) {
    let list: Tcolumn[] = [];
    let _this = this;
    if(tab !== undefined && tab !== null){
      _this.masterCommonService.getGridField(tab.getGrid()).forEach(function (column: Tcolumn) {
        if (!tab.getGrid().ignoreFieldListForExport.includes(column.getField()) && column.getColumnType() !== 'F' && column.getField() !== 'rowNum') {
          list.push(column);
        }
      });
    }

    return list;
  }

  getRowDataList(tab: Tab) {
    if(tab !== undefined && tab !== null) {
      let columnList: Tcolumn[] = Array.from(tab.getGrid().getColumn().values());
      return this.getRowDataFromWorkSheet(this.tabRowData.get(tab.getLabel()), tab.getGrid().ignoreFieldListForExport, columnList);
    }
  }

  private importTabData() {
    let _this = this;
    let importUrl = '';
    let rowData: any[] = [];
    this.tabs.forEach(function (tab: Tab) {
      if(tab !== undefined && tab !== null){
        importUrl = tab.getGrid().importUrl;
        if (importUrl !== undefined && importUrl !== null && importUrl.length > 0) {
          rowData = _this.getRowDataList(tab);
          if (rowData !== undefined && rowData !== null && rowData.length > 0) {
            if(tab.getGrid().actionFunc.preImportFunction !== null && tab.getGrid().actionFunc.preImportFunction !== undefined) {
              rowData = _this.commonService.runFunction(rowData,tab.getGrid().actionFunc.preImportFunction);
            }
            _this.commonService.post(importUrl, rowData).subscribe((next:any) => {
            }, error => {
                _this.isimporting = false;
            });
          }
        }
      }
    });
    _this.isimporting = false;
    this.closeImport(true);
  }


  private importData(importUrl: string, rowData: any[] = []) {
    let _this = this;
    if (importUrl !== undefined && importUrl !== null && importUrl.length > 0 && rowData !== undefined && rowData !== null && rowData.length > 0) {
      if(this.preImportFunction !== null && this.preImportFunction !== undefined) {
        rowData = _this.commonService.runFunction(rowData,this.preImportFunction);
      }
      _this.commonService.post(importUrl, rowData).subscribe((next:any) => {
        if (_this.tabs !== undefined && _this.tabs !== null && _this.tabs.length > 0) {
          _this.importTabData();
        } else {
          _this.isimporting = false;
          const rowCount = [...new Set(next.filter(item=> item.row != null && item.row !==undefined).map(item => item.row))].length;
          if(rowData.length == rowCount){
            this.messageService.add({severity: 'error', summary:'Failed to import futures trades.'})
            let errCount = '';
            next.forEach(er=>{
              errCount = errCount + 'Row: ' + er['row'] + ' : ' + er['Error'] + '\n'
            })
            this.commonService.downloadTextFile(errCount, "futures_trade_upload_error_log"+new Date().getMilliseconds()+".txt");
            return;
          }
          else if(next.length > 0 && next.length != rowData.length){
            this.messageService.add({severity: 'error', summary: "The futures import was partially successful, please check the error reports downloaded for details of failure."});
            let errCount = '';
            next.forEach(er=>{
              errCount = errCount + 'Row: ' + er['row'] + ' : ' + er['Error'] + '\n'
            })
            this.commonService.downloadTextFile(errCount, "futures_trade_upload_error_log"+new Date().getMilliseconds()+".txt");
            _this.closeImportPartial(true);
            return;
          }
          _this.closeImport(true);
        }
      }, (error: HttpErrorResponse) => {
        _this.isimporting = false;
        let errorMessage = _this.commonService.getHttpErrorMessage(error,_this.importTableName);
        _this.messageService.add({severity: 'error', detail:errorMessage});
      });
    } else {
      if (_this.tabs !== undefined && _this.tabs !== null && _this.tabs.length > 0) {
        _this.importTabData();
      }
    }
  }

  private getHeaderList(columns: Tcolumn[]) {
    let list = [];
    let _this = this;
    columns.forEach(function (column: Tcolumn) {
      list.push(column.getHeader());
    });
    return list;
  }

  private addInfoNeeded(obj) {
    obj['createdBy'] = this.id;
    obj['updatedBy'] = this.id;
    obj['tenantId'] = this.tenantId;

    if(this.parentUuidFieldName !== null && this.parentUuidFieldName !== undefined && this.parentUuidFieldName.length > 0){
      obj[this.parentUuidFieldName] = this.parentRowData['uuid'];
    }
    return obj;
  }

  private importFileIntoTable(currentValue: File, convert: boolean = false) {
    if (this.columnHeaders === undefined || this.columnHeaders === null || this.columnHeaders.length === 0) {
      this.getHeaderObjets(this.gridColumns);
    }
    if (currentValue !== undefined && currentValue !== null) {
      let _this = this;
      this.rowData = [];
      let worksheet: XLSX.WorkSheet;
      let dataTemp: any[];
      var reader = new FileReader();
      reader.onload = function (e) {
        // @ts-ignore
        let data = new Uint8Array(e.target.result);
        let workbook = XLSX.read(data, {type: 'array', cellDates: true});
        _this.sheets = workbook.SheetNames;
        _this.sheets.forEach(function (sheetname, index) {
          if (sheetname === _this.importTableName) {
            worksheet = workbook.Sheets[sheetname];
            _this.mainWorksheet = worksheet;
            if (convert) {
              let column: Tcolumn;
              let jsonOpts ={
                raw :false,
                dateNF: 'yyyy-mm-dd'
              }
              dataTemp = XLSX.utils.sheet_to_json(worksheet, jsonOpts);
              dataTemp.forEach(function (row) {
                let obj = _this.getObjectWithDefaultValue(_this.gridColumns,row,_this.ignoreFieldList);
                _this.rowData.push(_this.addInfoNeeded(obj));
              });
            }
          } else {
            if (_this.importChildData) {
              worksheet = workbook.Sheets[sheetname];
              _this.tabRowData.set(sheetname, worksheet);
            }
          }
        });
      };
      reader.readAsArrayBuffer(currentValue);
    }

  }

  getDefaultValue(value: any) {
    let defaultVa: any;
    if (value === undefined || value === null) {
      defaultVa = '';
    } else if (typeof (value) === 'object') {
      defaultVa = value.defaultValue;
    } else {
      defaultVa = value;
    }
    return defaultVa;
  }

  private getObjectWithDefaultValue(columns:Tcolumn[],row:any,ignoreFieldList:any[]) {
    let obj = {};
    let value:any = '';
    let defValue = '';
    let _this = this;
    let convertedDate:Date = undefined;
    columns.forEach(function (column:Tcolumn) {
      if(column !== null && column !== undefined) {
        if (!ignoreFieldList.includes(column.getField())) {
          value = row[column.getHeader()];
          if (value === null || value === undefined || value.length === 0) {
            defValue = _this.getDefaultValue(column.getDefaultValue());
            if(defValue !== null && defValue !== undefined && defValue.toString().length > 0) {
              value = defValue;
            } else{
              if(column.getColumnType() === 'B') {
                value = false;
              }
              value = '';
            }
          } else {

          }
          if (column.getColumnType() !== 'F') {
            if (column.getColumnType() === 'D') {
              obj[column.getField()] = _this.commonService.convertDatetoUTC(new Date(value),true);
            }  else {
              obj[column.getField()] = value;
            }
          }
        }
      }
    });
    return obj;
  }

  convertToValidDate(date:Date) {
    return new Date(date.getFullYear(),date.getMonth(),date.getDate(),date.getHours(),date.getMinutes(),date.getSeconds(),date.getMilliseconds());
  }

  private getHeaderObjets(tcolumnArray: Tcolumn[]) {
    let headerList = {};
    let _this = this;
    tcolumnArray.forEach(function (column: Tcolumn) {
      _this.columnHeaders.push(column.getHeader());
    });
    return [headerList];
  }

  private getRowDataFromWorkSheet(worksheet: XLSX.WorkSheet, ignoreFieldList: any[] = [], columnList: Tcolumn[]) {
    let _this = this;
    let dataTemp = XLSX.utils.sheet_to_json(worksheet, {raw:false,dateNF: 'yyyy-mm-dd'});
    let rowData: any[] = [];
    dataTemp.forEach(function (row) {
      columnList.forEach(function (column){
        if(row[column.getHeader()] && _this.splitFieldList.includes(column.getField())){
          row[column.getHeader()] =row[column.getHeader()].split(',');
        }
      })
      let obj = _this.getObjectWithDefaultValue(columnList,row,ignoreFieldList);
      rowData.push(_this.addInfoNeeded(obj));
    });
    return rowData;
  }
}

