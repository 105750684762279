import {Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private appConfig;
  constructor(private injector:Injector) { }

  loadAppConfig() {
    let http = this.injector.get(HttpClient);
    return http.get('./assets/config/config.json').toPromise().then(data => this.loadEnvironment(data));
  }

  loadEnvironment(data: any) {
    this.appConfig = data;
    if(data !== null && data !== undefined) {
      Object.keys(data).forEach(function(key) {
        environment[key] = data[key];
      });
    }
  }

  getConfig() {
    return this.appConfig;
  }
}
