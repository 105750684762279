import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CommonService} from '../../services/common.service';
import {MessageService} from 'primeng';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {HttpErrorResponse} from '@angular/common/http';
import {MasterService} from '../../masters/services/MasterService';
import {Tcolumn} from '../../grid/tcolumn.model';

@Component({
    selector: 'app-bl-management',
    templateUrl: './bl-management.component.html',
    styleUrls: ['./bl-management.component.css']
})
export class BlManagementComponent implements OnInit, OnChanges {

    @Input() display: boolean = false;
    formHeight: string = '';
    blform: FormGroup;
    includedCharacters: any[] = ['_', '/', ' '];
    forwardingAgentList: any[] = [];
    quantityUomList: any[] = [];
    @Input() data: any;
    loadData: any[] = [];
    @Output() onClose = new EventEmitter();
    refreshAddNewRow: boolean = true;
    newRows: any[] = [];
    refreshFormGroup: boolean = false;
    notifyCols: Tcolumn[] = [];
    notifyData: any[] = [];
    loadListData: any[];

    constructor(public commonService: CommonService, private messageService: MessageService, private formBuilder: FormBuilder,
                private masterCommonService: MasterService) {
    }

    ngOnInit(): void {
        this.commonService.getLoadData('commodity', ['config'], ['unitofmeasurement']).subscribe((next: any) => {
            this.loadListData = next;
        this.getforwardingAgent();
        this.getNotifyCols();
        });
        this.initInternalForm();
    }

    getNotifyCols() {
        this.notifyCols.push(new Tcolumn('notifyParty', 'Notify Party', 'T', 0, true, undefined, true));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['data']) {
            this.data = changes['data'].currentValue;
        }
    }

    getforwardingAgent() {
        let _this = this;
        this.quantityUomList = this.masterCommonService.getListFromArray(this.loadListData['unitofmeasurement']);
        this.commonService.getJSONByURL(environment.base_url + '/api/counterparty/v1/getcounterpartybycounterpartytypecode?tenantId='
            + this.commonService.getTenantId() + '&counterpartytype=' + ['Forwarding Agent']).subscribe((next: any) => {
            _this.forwardingAgentList = _this.commonService.createListFromObject(next, 'name', 'name', true);
        });
    }

    onCancel() {
        this.onClose.emit();
    }

    getIconFromValidations(formGroup: FormGroup, field: string) {
        let icon = 'pi pi-chevron-right iconCls2';
        if (field !== '') {
            if (formGroup.controls[field].valid && icon !== 'pi pi-times colorCross iconCls') {
                icon = 'pi pi-check colorTick iconCls';
            } else {
                if (!formGroup.controls[field].disabled) {
                    icon = 'pi pi-times colorCross iconCls';
                }
            }
        }
        return icon;
    }

    initInternalForm() {
        const inventoryObj = {};
        inventoryObj['blNumber'] = ['', [Validators.required]];
        inventoryObj['shipper'] = [''];
        inventoryObj['consignee'] = [''];
        inventoryObj['shipTo'] = [''];
        inventoryObj['exportReference'] = [''];
        inventoryObj['forwardingAgent'] = [''];
        inventoryObj['assignContract'] = [''];
        inventoryObj['quantity'] = [''];
        inventoryObj['quantityUom'] = [''];
        this.blform = this.formBuilder.group(inventoryObj);
    }

    notifyPartyList() {
        let list = [];
        this.notifyData.forEach(function(obj){
            list.push(obj['notifyParty'])
        });
        return list;
    }

    onCreate() {
        let url = '';
        let _this = this;
        let blpayload: {};
        blpayload = {
            blNumber: this.blform.controls['blNumber'].value,
            shipper: this.blform.controls['shipper'].value,
            consignee: this.blform.controls['consignee'].value,
            shipTo: this.blform.controls['shipTo'].value,
            exportReference: this.blform.controls['exportReference'].value,
            forwardingAgent: this.blform.controls['forwardingAgent'].value,
            assignContract: this.blform.controls['assignContract'].value,
            quantity: this.blform.controls['quantity'].value,
            quantityUom: this.blform.controls['quantityUom'].value,
            blRecordType: 'Final',
            plannedObligationId: this.data['plannedObligationId'],
            planId: this.data['planId'],
            updatedBy: this.commonService.getFromStorage('userName'),
            createdBy: this.commonService.getFromStorage('userName'),
            tenantId: this.commonService.getFromStorage('tenantId'),
            notifyParty: this.notifyPartyList()
        };
        url = environment.base_url + '/api/blrecord/v1/createblrecord';
        this.commonService.post(url, blpayload).subscribe(next => {
            this.onClose.emit();
            _this.display = false;
            _this.showToast('Row Successfully Saved.');
        }, (error: HttpErrorResponse) => {
            this.messageService.add({severity: 'error', summary: this.commonService.getHttpErrorMessage(error, undefined)});
        });
    }

    showToast(msg, severity: string = 'success') {
        this.messageService.add({
            severity: 'info',
            summary: msg
        });
    }

    //SubTable in the sideBar
    onNewRowAddComplete() {
        this.refreshAddNewRow = false;
        this.newRows = [];
    }

    onRefreshFromGroupComplete() {
        this.refreshFormGroup = false;
    }
}
