<div *ngIf="!detailPageToggle">
    <div class="sectionCardTop">
        <div class="breadcrumbPane">
            <ctrm-breadcrumb [collapsabel]="false"
                             [items]="breadCrumbService.getBreadCrumb()"
                             [showIcon]="false"></ctrm-breadcrumb>
        </div>
        <div class="actionPane">
            <div class="screenDetails">
                <img [src]="'../../../assets/images/svg/tradePlanning.svg'"/>
                <p>Transport Management</p>
            </div>
        </div>
    </div>

    <div class="mainDiv">
        <div class="leftSection">
            <div class="cardContainer style-4">
                <ng-container *ngTemplateOutlet="noDataBox"></ng-container>
                <div class="row w-100 m-0">
                    <app-booking-item (onCardClick)="onItemCardClick($event)"
                                      (onDelete)="deleteBookingDetails($event)"
                                      *ngFor="let bookingValue of bookingValue"
                                      [bookingValue]="bookingValue"
                                      class="col-6 p-1 m-0"></app-booking-item>
                </div>
            </div>
        </div>
        <!--    Filer Section-->
        <div class="rightSection">
            <div class="filterWrapper">
                <div (click)="onClickAddNewTransport()"
                     class="btnClassOrange mb-2">Add New Transport
                </div>

                <div class="brkLine"></div>

<!--                <div class="searchDiv">-->
<!--                    <i class="fas fa-filter iconFunnel" style="padding: 15px;"></i>-->
<!--                    <input (focusout)="getFilteredValue()" [(ngModel)]="searchCommon" class="searchInput" pInputText-->
<!--                           placeholder="Search" type="text"/>-->
<!--                    <i class="fas fa-times iconCross"></i>-->
<!--                </div>-->

                <div class="chkBoxFilterDiv">
                    <p class="chkHeading">Freight Type</p>
                    <div class="chkBoxFilters" *ngFor="let option of chkBoxOptions, index as i" >
                        <div class="chkOption">
                            <p-checkbox [(ngModel)]="option.active" binary="true" (ngModelChange)="onCheckFilterClick(option,i,true)"></p-checkbox>
                            <div style="display: inline-flex" (click)="onCheckFilterClick(option,i)">
                                <img class="chkFilterIcon" alt="" src="../../../../assets/images/svg/TransportBookingManagement/{{option.icon}}.svg">
                                <p class="chkFilterText">{{option.name}}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="brkLine"></div>

                <div class="radioDiv d-flex">
                    <p-radioButton (ngModelChange)="getRadioValue($event)"
                                   [(ngModel)]="radioValue"
                                   class="flex-fill align-self-start text-left"
                                   id="bookingDate" label="Booking Date"
                                   name="filterRadio"
                                   value="bookingDate"></p-radioButton>
                    <div style="display: inline-flex;gap: 5px">
                        <i (click)="getFilteredValue()" class="fas fa-filter iconFunnel"></i>
                        <i (click)="removeFilter()" class="fas fa-times iconCross" aria-hidden="true"></i>
                    </div>
<!--                    <p-radioButton (ngModelChange)="getRadioValue($event)"-->
<!--                                   [(ngModel)]="radioValue"-->
<!--                                   class="flex-fill align-self-start text-left"-->
<!--                                   id="eta" label="ETA"-->
<!--                                   name="filterRadio"-->
<!--                                   value="eta"></p-radioButton>-->
                </div>

                <div class="calDiv d-flex align-items-center mt-2" style="flex-direction: column;gap: 5px">
                    <div class="flex-fill d-inline-flex align-items-center pr-2 w-100">
                        <div class="flex-grow-1 d-inline-flex">
                            <input #dp="bsDatepicker" [bsConfig]="bsConfig"
                                   bsDatepicker
                                   [(ngModel)]="fromDate"
                                   class="dateInput w-50"
                                   style="font-size: 12px;cursor: pointer"
                                   placeholder="Select From Date" type="text">
                            <div (click)="dp.toggle()" [attr.aria-expanded]="dp.isOpen" style="width:3rem;">
                                <img class="chkFilterIcon pl-1 mt-0 align-self-center" alt="" src="../../../assets/images/svg/calendar.svg">
                            </div>

                        </div>
                    </div>
                    <div class="flex-fill d-inline-flex align-items-center w-100">
                        <div class="flex-grow-1 d-inline-flex pr-2">
                            <input #dp1="bsDatepicker" [bsConfig]="bsConfig"
                                   bsDatepicker
                                   [(ngModel)]="toDate"
                                   class="dateInput w-50"
                                   style="font-size: 12px;cursor: pointer"
                                   placeholder="Select To Date" type="text">
                            <div (click)="dp1.toggle()" [attr.aria-expanded]="dp1.isOpen" style="width:3rem;">
                                <img alt="" class="chkFilterIcon pl-1 mt-0 align-self-center"
                                     src="../../../assets/images/svg/calendar.svg">
                            </div>

                        </div>
                    </div>

                </div>

<!--                <div class="brkLine"></div>-->

<!--                <div class="mt-2">-->
<!--                    <p class="chkHeading">Allocation Status</p>-->
<!--                    <div>-->
<!--                        <div (click)="onAllocFilterClick(statusOpt,i)"-->
<!--                             *ngFor="let statusOpt of allocStatusOption, index as i"-->
<!--                             class="chkBoxFilters">-->
<!--                            <div class="chkOption">-->
<!--                                <p-checkbox [(ngModel)]="statusOpt.active" binary="true"></p-checkbox>-->
<!--                                <img alt="" class="chkFilterIcon"-->
<!--                                     src="../../../../assets/images/svg/TransportBookingManagement/{{statusOpt.icon}}.svg">-->
<!--                                <p class="chkFilterText">{{statusOpt.name}}</p>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
        </div>
    </div>
</div>


<!--Transport Details Page-->
<div *ngIf="detailPageToggle">
    <app-transport-detail (onBackEvent)="onClickBackEvent()"
                          [bookingNumber]="bookingNumber"
                          [editing]="editing"
                          [resetForm]="resetForm"
                          (onFormResetComplete)="onResetComplete()"
                          [showEdit]="showEdit"
                          [showSave]="showSave"></app-transport-detail>
</div>


<ng-template #noDataBox>
    <div *ngIf="(bookingValue === undefined || bookingValue === null || bookingValue.length === 0)"
         style="height: 100%">
        <div class="placeHolder d-flex">
            <div [id]="'noDataBoxId'" class="noDataBox align-self-center">
                <div style="height: 100%">
                    <i class="far fa-check-square" style="font-size: 25px"></i>
                    <p style="font-size: 20px; text-align: center;">Add Booking Details</p>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!--Toaster Message-->
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body">
</p-toast>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
