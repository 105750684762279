import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {Grid} from '../../../grid/grid.model';
import {BreadcrumbService} from '../../../Components/ctrm-breadcrumb/breadcrumb.service';
import {Breadcrumb} from '../../../Components/ctrm-breadcrumb/breadcrumb.model';
import {Router} from '@angular/router';
import {CommonService} from '../../../services/common.service';
import {Tab} from '../../../tab-grid/Tab.model';
import {AccessPolicyService} from '../../../services/accesspolicy.service';

@Component({
  selector: 'app-ctrm-grid-with-tabs',
  templateUrl: './ctrm-grid-with-tabs.component.html',
  styleUrls: ['./ctrm-grid-with-tabs.component.css']
})
export class CtrmGridWithTabsComponent implements OnInit,OnChanges,AfterViewInit {
  showTabs: boolean = false;
  @Input() refreshGrid: boolean = false;
  @Output() onClickUniqueKey = new EventEmitter();
  @Output() onRowDataSave = new EventEmitter();
  @Input() grid: Grid;
  @Input() extraViewTemplate: TemplateRef<any>;
  @Input() showDealSlip: boolean = false;
  @Input() extraButtons: any;
  @Input() showSectionTop: boolean = true;
  @Input() refreshTab:any = '';
  @Input() includeFieldsListForFormGroup:string[] =[];
  @Input() showMasterInfo: boolean = false;
  @Input() showBreadcrumb: boolean = true;
  @Input() loadData: any[] = [];
  @Output() modalChanged = new EventEmitter();
  @Output() onChangeObjectCreated = new EventEmitter();
  @Output() onChangeValue = new EventEmitter();
  @Output() selectedRows = new EventEmitter();
  @Output() onChangeIndex = new EventEmitter();
  @Output() onTabChange = new EventEmitter();
  @Output() onChangeFieldValue = new EventEmitter();
  @Output() onRowDataDelete = new EventEmitter();
  @Output() onButtonClick = new EventEmitter();
  @Output() onClickAdd = new EventEmitter();
  @Output() onTabRefreshComplete = new EventEmitter();
  @Output() dealSlipEmitter = new EventEmitter();
  @Output() heirarchyEmitter = new EventEmitter();
  @Output() onContextMenuItemClick = new EventEmitter();
  @Output() onClickBackIcon = new EventEmitter();
  @Output() onGoToNext = new EventEmitter();
  @Output() totalNumberElements = new EventEmitter();
  currentRow: any = {};
  listValues: any;
  currentIndex:number = 0;
  @Input() currentRoute: any;
  @Input() breadcrumbMapKey: any;
  @Input() showStandardFilter: any;
  @Output() onInlineEditClick = new EventEmitter();
  @Output() onClickEdit  = new EventEmitter();
  @Input() search: any;
  @Input() updatePayloadFunction: Function;
  @ViewChild('inputGrid', {static: true}) inputGrid: Grid;
  @Input() openTabScreen:boolean = false;
  @Output() onFormOpenDone = new EventEmitter();
  @Input() openFormForNewEntry:boolean = false;
  @Input() openTabForRow:any;
  @Input() selectedTab:string;
  @Output() onTabScreenOpened = new EventEmitter();
  @Input() showFilterChips: boolean = true;
  @Input() viewCreatedBy:boolean = true;
  @Output() onGridRefresComplete = new EventEmitter();
  @Input() fieldsWithCustomView:any[] =[];
  @Input() customViewTemplated:TemplateRef<any>[] =[];
  @Input() filterOptionsList: any[] = [];
  @Output() onFilterApply = new EventEmitter();

  constructor(private changeDetectorRef:ChangeDetectorRef,private accessPolicy:AccessPolicyService,private breadCrumbService: BreadcrumbService, public route: Router, private commonService: CommonService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    let _this = this;
    if(changes['openTabScreen'] && changes['openTabScreen'].currentValue) {
      _this.currentIndex = 0;
      if(this.selectedTab !== null && this.selectedTab !== undefined) {
        this.grid.getTabs().forEach(function(tab:Tab,index) {
          if(tab.getLabel() === _this.selectedTab) {
            _this.currentIndex = index;
          }
        });
      }
      this.onNext(this.openTabForRow);
      this.onTabScreenOpened.emit();
    }
  }

  onUniqueClick(value: any) {
    this.onClickUniqueKey.emit(value);
  }

  onRowSave(value: any) {
    this.onRowDataSave.emit(value);
  }

  heirarchyEmit(value: any) {
    this.heirarchyEmitter.emit(value);
  }

  onRouteBack() {
    this.showTabs = false;
    this.breadCrumbService.popBreadcrumb();
    this.refreshGrid = true;
    this.onClickBackIcon.emit();
  }

  onRowDelete(value: any) {
    this.onRowDataDelete.emit(value);
  }

  onNext(value: any) {
    this.refreshGrid = false;
    this.showTabs = true;
    this.currentRow = value;
    this.breadCrumbService.pushBreadcrumb(new Breadcrumb('', this.currentRow[this.grid.getPrimaryKey()], ''));
    this.onGoToNext.emit();
  }

  onClickButton(value: any) {
    this.onButtonClick.emit(value);
  }

  onAddClick(value: any) {
    this.onClickAdd.emit(value);
  }

  onListEmmiter(value: any) {
    this.listValues = value;
  }

  onUpdateMain(value: any) {
    let mode = 'save';
    if (value['isUpdate']) {
      mode = 'update';
    }
    this.onRowDataSave.emit({
      result: mode === 'update' ? {
        newData: value['data'],
        oldData: this.currentRow
      } : value['data'], mode: mode, label: this.grid.getTitle()
    });
    this.currentRow = {...value['data']};
  }

  getEditEnabled() {
    return this.accessPolicy.currentGridAccess['edit'];
  }

  onContextClick(value: any) {
    this.onContextMenuItemClick.emit(value);
  }

  onChangeTab(value: any) {
    this.onTabChange.emit(value);
  }

  onClickEditEmit(value: any) {
    this.onClickEdit.emit(value);
  }

  onGridTotalChange(value: any) {
    this.totalNumberElements.emit(value);
  }

  onRowSelect(value: any,title:any) {
    this.selectedRows.emit({title:title,data:value});
  }

  onInlineEditClickEmit(value) {
    this.onInlineEditClick.emit(value);
  }

  onTabRowSelected(value: any) {
    this.selectedRows.emit(value);
  }

  onFormopned() {
    this.onFormOpenDone.emit();
  }

  onCompleteRefresh(value: any) {
    this.onTabRefreshComplete.emit(value);
  }

  getSecondLevelEdit() {
    return this.grid.getGridConfig()['secondLevelEdit'];
  }

  onRefreshGridRefresh($event: any) {
    this.onGridRefresComplete.emit($event);
  }

  onFilterApplyValue($event: any) {
    this.onFilterApply.emit($event);
  }

  onEditClick(event: any) {
    this.onClickEdit.emit(event);
  }
}
