<div class="cardBox" [style.width]="width" [ngClass]="disabled?'disabled':''">
    <div class="innerbox" [ngClass]="isBuy()?'buyBorder':'sellBorder'">
        <div class="topline">
            <p-checkbox [disabled]="disabled"
                        (onChange)="onSelectionChange($event)"
                        [binary]="true"  [ngModel]="disabled?false:selected"></p-checkbox>
            <p [ngClass]="disabled?'idclassdisabled':'idclass'" (click)="emitsomevalue()">{{plannedObligation['plannedObligationId']}}</p>
            <div style="display: inline-flex;padding-left: 5px">
                <mat-icon *ngIf="plannedObligation['tradeTransactionType'] === 'BUY'" class="buyIcon">call_made</mat-icon>
                <mat-icon *ngIf="plannedObligation['tradeTransactionType'] === 'SELL'" class="sellIcon">call_received</mat-icon>
                <p class="tradetypeclass" style="font-weight: 600;margin-left: 2px">{{plannedObligation['tradeTransactionType']}}</p>
            </div>
        </div>
        <div>
            <div class="row rowClass">
                <div class="col-md-12 contentbox">
                    <label class="heading ng-star-inserted">Trade ID : </label>
                    <p class="fieldvalue" pTooltip="{{plannedObligation['tradeId']}}" tooltipZIndex="9999">{{plannedObligation['tradeId']}}</p>
                </div>
                <div class="col-md-6 contentbox">
                    <label class="heading ng-star-inserted">Counterparty : </label>
                    <p class="fieldvalue" pTooltip="{{plannedObligation['counterparty']}}" tooltipZIndex="9999">{{plannedObligation['counterparty']}}</p>
                </div>
                <div class="col-md-6 contentbox">
                    <label class="heading ng-star-inserted">Commodity : </label>
                    <p class="fieldvalue" pTooltip="{{plannedObligation['commodity']}}" tooltipZIndex="9999">{{plannedObligation['commodity']}}</p>
                </div>
                <div class="col-md-6 contentbox">
                    <label class="heading ng-star-inserted">Load Port : </label>
                    <p class="fieldvalue" pTooltip="{{plannedObligation['loadLocation']}}" tooltipZIndex="9999">{{plannedObligation['loadLocation']}}</p>
                </div>
                <div class="col-md-6 contentbox">
                    <label class="heading ng-star-inserted">Unload Port : </label>
                    <p class="fieldvalue" pTooltip="{{plannedObligation['unloadLocation']}}" tooltipZIndex="9999">{{plannedObligation['unloadLocation']}}</p>
                </div>
                <div class="col-md-6 contentbox">
                    <label class="heading ng-star-inserted">Delivery Start Date : </label>
                    <p class="fieldvalue" pTooltip="{{getFormattedDate(plannedObligation['deliveryStartDate'])}}" tooltipZIndex="9999">{{getFormattedDate(plannedObligation['deliveryStartDate'])}}</p>
                </div>
                <div class="col-md-6 contentbox">
                    <label class="heading ng-star-inserted">Delivery End Date : </label>
                    <p class="fieldvalue" pTooltip="{{getFormattedDate(plannedObligation['deliveryEndDate'])}}" tooltipZIndex="9999">{{getFormattedDate(plannedObligation['deliveryEndDate'])}}</p>
                </div>
            </div>
            <i class="fas fa-arrows-alt-v expandIcon"></i>
        </div>
    </div>
</div>
