<ctrm-grid #inputGrid (goToNext)="onNext($event)"
           (heirarchyEmitter)="heirarchyEmit($event)"
           (listOfValuesEmitter)="onListEmmiter($event)"
           (onButtonClick)="onClickButton($event)"
           (onClickAdd)="onAddClick($event)"
           (onClickUniqueKey)="onUniqueClick($event)"
           (onContextMenuItemClick)="onContextClick($event)"
           (onRowDataDelete)="onRowDelete($event)"
           (onRowDataSave)="onRowSave($event)"
           [breadcrumbMapKey]="breadcrumbMapKey"
           [showStandardFilter]="showStandardFilter"
           [currentRoute]="currentRoute"
           [extraButtons]="extraButtons"
           [extraViewTemplate]="extraViewTemplate"
           [grid]="grid"
           (onChangeObjectCreated)="onChangeObjectCreated.emit($event)"
           [includeFieldsListForFormGroup]="includeFieldsListForFormGroup"
           [hidden]="showTabs"
           [canGoNext]="true"
           (selectedRows)="onRowSelect($event,grid.getTitle())"
           [refreshGrid]="refreshGrid"
           [showBreadcrumb]="showBreadcrumb"
           [showDealSlip]="showDealSlip"
           [showMasterInfo]="showMasterInfo"
           [showSectionTop]="showSectionTop"
           (totalNumberElements)="onGridTotalChange($event)"
           (onClickEdit)="onClickEditEmit($event)"
           (onInlineEditClick)="onInlineEditClickEmit($event)"
           [search]="search"
           [loadData] = "loadData"
           [updatePayloadFunction]="updatePayloadFunction"
           [showFilterChips]="showFilterChips"
           [viewCreatedBy]="viewCreatedBy"
           (onRefreshComplete)="onRefreshGridRefresh($event)"
           [fieldsWithCustomView]="fieldsWithCustomView"
           [customViewTemplated]="customViewTemplated"
           [filterOptionsList]="filterOptionsList"
           (onFilterApply) = "onFilterApplyValue($event)">
</ctrm-grid>
<app-tab-grid (onButtonClick)="onClickButton($event)" (onClickAdd)="onAddClick($event)" (onMainUpdate)="onUpdateMain($event)"
              (onRouteBackClick)="onRouteBack()"
              (onRowDataDelete)="onRowDelete($event)"
              (onRowDataSave)="onRowSave($event)"
              (onTabChange)="onChangeTab($event)"
              [openFormForNewEntry]="openFormForNewEntry"
              (onFormOpenDone)="onFormopned()"
              [activeIndex]="currentIndex"
              *ngIf="showTabs"
              [refreshTabs]="refreshTab"
              (onRefreshComplete)="onCompleteRefresh($event)"
              [currentRow]="currentRow"
              [editEnabled]="getEditEnabled()"
              (onClickEdit)="onEditClick($event)"
              [secondLevelEdit]="getSecondLevelEdit()"
              (selectedRows)="onTabRowSelected($event)"
              [grid]="grid"
              [heading]="currentRow[grid.getPrimaryKey()]"
              [listOfValues]="listValues"
              [parentKeyValue]="currentRow[grid.getPrimaryKey()]"
              [urlIdFieldValue]="currentRow[grid.urlIdField]"
              [tabs]="grid.getTabs()"
              [screentype] = "grid.auditEntityName">
</app-tab-grid>
