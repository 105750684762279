import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.css']
})
export class DashboardCardComponent implements OnInit,OnChanges {

  @Input() cardId = "";
  @Input() cardHeader = "";
  @Output() onCardSelected = new EventEmitter();
  @Output() onCardUnselected = new EventEmitter();
  @Input() isSelected: boolean = false;
  @Input() autoCalculateTotal:boolean = true;
  @Input() total = 0.0;
  @Input() cardBackgroundColor:any = '#ffffff';
  @Input() cardBorderColor:any = '#000000';
  @Input() count1 = 0;
  @Input() count2 = 0;
  @Input() disable:boolean = false;
  @Input() stat1 = 'Buy';
  @Input() stat2 = 'Sell';
  @Input() statHeaders:any[] = ['Buy','Sell'];
  @Input() statValues:any[] = [0,0];
  @Input() showCustoms: boolean = false;
  @Input() showStats: boolean = true;
  @Input() disableClick: boolean = false;
  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    let _this = this;
    if(changes['statValues'] || changes['count1'] || changes['count2'] || changes['showCustoms']) {
      _this.reSum();
    }
  }

  private reSum() {
    if(this.autoCalculateTotal) {
      let _this = this;
      this.total = 0;
      if(this.showCustoms) {
        if(this.statValues !== null && this.statValues !== undefined) {
          this.statValues.forEach(function (val) {
            if(val !== null && val !== undefined) {
              _this.total += val;
            }
          });
        }
      } else {
        this.total = this.count1 + this.count2;
      }
    }

  }

  ngOnInit(): void {
  }

    addAlpha(color: string, opacity: number): string {
        const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
        return color + _opacity.toString(16).toUpperCase();
    }

  onClickCard() {
    if (!this.disableClick && !this.disable) {
      if (this.isSelected) {
        this.isSelected = false;
        this.onCardUnselected.emit(this.cardId);
      } else {
        this.isSelected = true;
        this.onCardSelected.emit(this.cardId);
      }
    }
  }
}
