<div>
    <div class="sectionCardTop">
        <div class="breadcrumbPane">
            <ctrm-breadcrumb [collapsabel]="false"
                             [items]="breadCrumbService.getBreadCrumb()"
                             [showIcon]="false"></ctrm-breadcrumb>
        </div>
        <div class="actionPane">
            <div class="screenDetails">
                <img [src]="'../../../assets/images/svg/futureExchange.svg'"/>
                <p>Futures</p>
            </div>
<!--            <div class="autocomplete-box">-->
<!--                <input [(ngModel)]="search" placeholder="Search" type="text">-->
<!--                <mat-icon style="color:#cbcbcb">search</mat-icon>-->
<!--            </div>-->

            <div class="rightInfo">
                Total {{this.futureValues.length}}
            </div>
        </div>
    </div>
</div>

<div class="sectionCardTop" style="max-height: 100% !important;">
    <!-- Filter Section-->
    <div class="tableWrapper">
        <div [formGroup]="filterForms">
            <div class="viewByWrapper">
                <p class="tradeFilter"> Filter by:</p>
                <p class="viewby viewbyLabels">Trade ID : </p>
                <div style="margin-left: 10px">
                    <p-dropdown [disabled]="false"
                                [id]="'tradeId'"
                                appendTo="body"
                                tooltipEvent="focus" tooltipPosition="bottom"
                                tooltipZIndex="1000"
                                formControlName="tradeIdList"
                                [itemSize]="31"
                                [virtualScroll]="true"
                                [filter]="true"
                                (onChange)="onFilterValueChange($event)"
                                [options]="filterTradeIdList"
                                optionLabel="tradeId"
                                optionValue="tradeId">
                    </p-dropdown>
                </div>
                <p class="viewby viewbyLabels">Future Index : </p>
                <div style="margin-left: 10px">
                    <p-dropdown [disabled]="false"
                                [id]="'futureIndex'"
                                appendTo="body"
                                tooltipEvent="focus" tooltipPosition="bottom"
                                tooltipZIndex="1000"
                                formControlName="futureIndexList"
                                (onChange)="onFilterValueChange($event)"
                                [options]="filterFutureIndexList">
                    </p-dropdown>
                </div>
                <p class="viewby viewbyLabels">Broker : </p>
                <div style="margin-left: 10px">
                    <p-dropdown [disabled]="false"
                                [id]="'allBrokerList'"
                                (onChange)="onFilterValueChange($event)"
                                [options]="filterBrokerList"
                                appendTo="body"
                                tooltipEvent="focus" tooltipPosition="bottom"
                                tooltipZIndex="1000"
                                formControlName="brokerList">
                    </p-dropdown>
                </div>
                <p class="viewby viewbyLabels">Profit center : </p>
                <div style="margin-left: 10px">
                    <p-dropdown [disabled]="false"
                                [id]="'profitCenter'"
                                appendTo="body"
                                tooltipEvent="focus" tooltipPosition="bottom"
                                tooltipZIndex="1000"
                                formControlName="profitCenterList"
                                (onChange)="onFilterValueChange($event)"
                                [options]="filterProfitcenterList">
                    </p-dropdown>
                </div>
                <p class="viewby viewbyLabels">Status : </p>
                <div style="margin-left: 10px">
                    <p-multiSelect appendTo="body"
                                   [options]="nettingStatusValues"
                                   [(ngModel)]="nettingStatus"
                                   [ngModelOptions]="{standalone: true}"
                                   (ngModelChange)="onNettingStatusChange($event)">
                    </p-multiSelect>
                </div>
                <div class="calenderCont">
                    <div class="calender calenderfix" (click)="toggleDateRange()">
                        <i class="fa fa-calendar"
                           style="margin-left: 5px;align-self: center;font-size: 12px;margin-left: 6px;user-select: none;"></i>
                        <p>Foward Contract Month</p>
                    </div>
                    <ctrm-date-range *ngIf="showDateRange"
                                     [selectedPresetObject]="selectedPreset"
                                     (onCancel)="onCancel()"
                                     [preDefinedRanges]="ranges"
                                     (onPresetSelected)="onPresetChange($event)">
                    </ctrm-date-range>
                </div>
                <div class="calenderCont">
                    <div class="calender calenderfix" (click)="toggleTradeDateRange()">
                        <i class="fa fa-calendar"
                           style="margin-left: 5px;align-self: center;font-size: 12px;margin-left: 6px;user-select: none;"></i>
                        <p>Trade Date</p>
                    </div>
                    <ctrm-date-range *ngIf="showTradeDateRange"
                                     [selectedPresetObject]="selectedTradePresent"
                                     [selectedPreset]="'Date Range'"
                                     (onCancel)="onTradeDateCancel()"
                                     [preDefinedRanges]="[]"
                                     (onPresetSelected)="onPresetTradeDateChange($event)">
                    </ctrm-date-range>
                </div>
                <div>
                    <button (click)="clearAllFilter()" class="clearAll">
                        Clear All
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!--  Card Section and Grid Section-->
    <div class="content">
        <div class="leftbox">
            <div class="leftcard">
                <!--    Left side Card panel-->
                <div class="contentSection">
                    <div class="switchDiv">
                        <p class="text">Product Symbol</p>
                        <ui-switch [checkedTextColor]="'#fff'" [size]="'small'"
                                   (change)="toggleChange($event)" [checked]="toggleButton"
                                   [defaultBgColor]="'#d8d8d8'">
                        </ui-switch>
                        <p class="text">Expiry Month</p>
                    </div>
                    <div class="list style-4" id="list" inViewport (inViewportAction)="calcaulateListHeight()">
                        <future-position-card *ngFor="let futureTrade of futureTrade"
                                              [selected]="isallselected"
                                              [hidden]="hiddenCard.includes(futureTrade['tradeId'])"
                                              [disabled]="disableCardList.includes(futureTrade['tradeId'])"
                                              (onSelected)="onSelectCard($event)"
                                              (onUnselected)="onUnselectCard($event)"
                                              [futurePosition]="futureTrade"
                                              [expiryMonthFilter]="expiryMonthFilter">
                        </future-position-card>
                    </div>
                </div>
            </div>
        </div>
        <!--    Grid Panel-->
        <div class="rightbox">
            <div class="gridbox" #divbox inViewport (inViewportAction)="calculateGridHeight(divbox)">
                <div>
                    <ctrm-grid *ngIf="giList !== undefined && giList.length != 0"
                               [showSectionTop]="false"
                               [isOnSidebar]="true"
                               [sortable]="false"
                               [showActions]="false"
                               [defaultEdit]="false"
                               [saveOnSave]="false"
                               [autoCalculateHeight]="false"
                               [getFromUrl]="false"
                               [autoCalculateGridWidth]="false"
                               [autoLayout]="true"
                               (selectedRows)="onSelectCB($event)"
                               [showSortIcon]="false"
                               [rowValues]="futureValues"
                               [toolbarConfig]="toolBarConfig"
                               [gridHeight]="gridHeight+'px'"
                               [customViewTemplated]=[overlayTemplate]
                               [fieldsWithCustomView]="['status']"
                               [checkAccessPolicy]="false"
                               [getFromUrl]="true"
                               [defaultCopy]="false"
                               (onClickAdd)="onClickAdd(true)"
                               (onSave)="onSave()"
                               (onClickEdit)="openClickEdit($event,true)"
                               (onClickDuplicate)="onDuplicate($event)"
                               (onClickUniqueKey)="openClickEdit($event,false)"
                               [grid]="grid"
                               [selectedRow]="selectedRows"                               
                               [noDataTemplate]="noData"
                               [refreshGrid]="refreshGrid"
                               (onRefreshComplete)="refreshGrid = false"
                               (onClickedRefresh)="getFuture($event)">
                    </ctrm-grid>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #overlayTemplate let-rowData='rowData'>
    <div style="display: inline-flex;align-items: center;font-size: 15px; ">
        <p class="primaryKey" #ptag (click)="onClickStatus($event,displayPanel,rowData,ptag)">{{rowData['status']}}</p>
    </div>
</ng-template>

<p-overlayPanel #displayPanel [style]="{width: '20%',top:top}">
    <p-table [value]="documents" [scrollable]="true" scrollHeight="200px">
        <ng-template style="width:15%;" pTemplate="header">
            <tr>
                <th style="min-width:100px">Trade Linked to Future</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-documents>
            <tr>
                <td class="statusClass" (click)="onClickOfTradeId($event)">{{documents}}</td>
            </tr>
        </ng-template>
    </p-table>
</p-overlayPanel>
<ng-template #noData>
    <div (inViewportAction)="calculateLeft()" [id]="'noDataBoxId'" [style]="{left:noDataBoxLeft}" class="noDataBox"
         inViewport>
        <img [src]="commonService.getIconPath('emptyStateIcon')" class="iconClass"/>
        <p class="noDataHeader">{{showFetching || isError ? loadMessage : noDataLabel}}</p>
        <div class="buttonsBox">
            <div (click)="onClickAdd(true)" *ngIf="grid.getGridConfig().canAdd" class="noDataButton">
                <p class="noDataButtonLabel">{{'Add ' + grid.getTitle()}}</p>
            </div>
        </div>
    </div>
</ng-template>
<app-input-form *ngIf="displayOnAdd" [header]="header"
                [formGroup]="futureTradeForm"
                [validationSections]="validationSections"
                [visible]="true"
                (onSave)="onSave()"
                [saveButtonLabel]="saveButtonLabel"
                [formContent]="formcontorl"
                (onClose)="onClose()">
</app-input-form>
<ng-template #formcontorl>
    <form [formGroup]="futureTradeForm">
        <div class="mainbox">
            <div class="sectionBoxMain">
                <div class="formSectionTitle">
                    <div class="headerTitle">Basic Details</div>
                </div>
                <div class="row rowClass">
                    <div class="col-md-4 controlGap contentbox">
                        <div class="fieldBox">
                            <label class="label ng-star-inserted">Trader Name</label>
                            <p-dropdown [disabled]="false"
                                        [id]="'tradeName'"
                                        formControlName="traderName"
                                        [options]="traderNameList"
                                        appendTo="body"
                                        (onChange)="onchangeformfield($event)"
                                        (ngModelChange)="onTraderNameChange($event)"
                                        pTooltip="{{getTooltipMessage(formControlAIO,field)}}" tooltipEvent="focus"
                                        tooltipPosition="bottom"
                                        tooltipZIndex="1000">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-md-4 controlGap contentbox">
                        <div class="fieldBox">
                            <label class="label ng-star-inserted">Company</label>
                            <p-dropdown [disabled]="false"
                                        formControlName="company"
                                        [id]="'company'"
                                        [options]="companyList"
                                        (onChange)="onchangeformfield($event)"
                                        appendTo="body"
                                        pTooltip="{{getTooltipMessage(formControlAIO,field)}}"
                                        tooltipEvent="focus" tooltipPosition="bottom" tooltipZIndex="1000">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-md-4 controlGap contentbox">
                        <div class="fieldBox">
                            <label class="label ng-star-inserted">Profit Center</label>
                            <p-dropdown [disabled]="false"
                                        formControlName="profitcenter"
                                        [id]="'profitcenter'"
                                        [options]="profitcenterList"
                                        (onChange)="onchangeformfield($event)"
                                        appendTo="body"
                                        pTooltip="{{getTooltipMessage(formControlAIO,field)}}"
                                        tooltipEvent="focus" tooltipPosition="bottom" tooltipZIndex="1000">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-md-4 controlGap contentbox">
                        <div class="fieldBox">
                            <label class="label ng-star-inserted">Future Index</label>
                            <p-dropdown [disabled]="false"
                                        [id]="'futureIndex'"
                                        formControlName="futureIndex"
                                        [options]="futureIndexList"
                                        (onChange)="onchangeformfield($event)"
                                        appendTo="body"
                                        pTooltip="{{getTooltipMessage(formControlAIO,field)}}"
                                        tooltipEvent="focus" tooltipPosition="bottom" tooltipZIndex="1000" (onChange)="onChangeFutureIndex($event)">
                            </p-dropdown>

                        </div>
                    </div>
                    <div class="col-md-4 controlGap contentbox">
                        <div class="fieldBox">
                            <label class="label ng-star-inserted">Ticker</label>
                            <p-dropdown [disabled]="false"
                                        [id]="'ticker'"
                                        formControlName="ticker"
                                        [options]="tickerList"
                                        (onChange)="onchangeformfield($event)"
                                        appendTo="body"
                                        pTooltip="{{getTooltipMessage(formControlAIO,field)}}"
                                        tooltipEvent="focus" tooltipPosition="bottom" tooltipZIndex="1000">
                            </p-dropdown>

                        </div>
                    </div>
                    <div class="col-md-4 controlGap contentbox">
                        <div class="fieldBox">
                            <label class="label ng-star-inserted">No. of Entries</label>
                            <input formControlName="entries"
                                   [id]="'entries'"
                                   class="form-control form-control-sm pickerInput"
                                   placeholder="Add Entries"
                                   type="text">
                        </div>
                    </div>
                    <div class="col-md-4 controlGap contentbox">
                        <div class="fieldBox">
                            <button [disabled]="addButtonDisable"
                                    (click)="addEntities()" class="addbtn">Add

                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sectionBoxMain" style="width: 134% !important;" [hidden]="!visible">
                <div class="formSectionTitle" style="width: 68%;margin-bottom: 10px">
                    <div class="headerTitle">Future Details</div>
                </div>
                <div (inViewportAction)="calculateHeight('contentBox')" [style]="{maxHeight:dataHeight}" class="style-4"
                     id="contentBox"
                     inViewport style="overflow-y: auto;margin-right: 10px">
                    <div *ngFor="let combination of combinationAdded"
                         style="margin-right: 30px;margin-bottom: 30px;">
                        <div class="heading">
                            <i (click)="onExpand(combination)"
                               [ngClass]="columnMaps.get(combination).expanded?'fas fa-chevron-down':'fas fa-chevron-right'"
                               class="expandClass"></i>
                            <div class="headingOnGrid">
                                <p class="bodyHeader">Future Index:</p>
                                <p class="bodyContent">{{columnMaps.get(combination).futureIndex}}</p>
                            </div>
                            <div class="headingOnGrid">
                                <p class="bodyHeader">Company:</p>
                                <p class="bodyContent">{{columnMaps.get(combination).company}}</p>
                            </div>
                            <div class="headingOnGrid">
                                <p class="bodyHeader">Profit Center:</p>
                                <p class="bodyContent">{{columnMaps.get(combination).profitcenter}}</p>
                            </div>
                            <div class="headingOnGrid">
                                <p class="bodyHeader">Trader Name:</p>
                                <p class="bodyContent">{{columnMaps.get(combination).traderName}}</p>
                            </div>
                            <div>
                                <i (click)="onClickInfo(columnMaps.get(combination).futureIndex)" [pTooltip]="'Info'"
                                   class="fas fa-info-circle infoIcon"></i>
                            </div>
                        </div>
                        {{editMode}}
                        <app-ctrm-price-table *ngIf="displayOnAdd" (onAddNewRowComplete)="onAddNewRowComplete($event,combination)"
                                              (onChangeFieldValue)="onChangeValue($event,combination)"
                                              (onRefreshFormGroupComplete)="onRefreshFromGroupComplete(combination)"
                                              [columns]="columnMaps.get(combination).metadata"
                                              [gridDisplay]="true" [showReadOnly]="!editMode" [showCrossIcon]="!editMode"
                                              [hiddenColumns]="['tradeId','futureIndex','company','profitcenter',
                                              'tradeName','nettingStatus','expiredStatus','status','tradeType','exchange',
                                              'commodity','lotSize','balanceLot',  'traderName']"
                                              [hidden]="!this.columnMaps.get(combination).expanded"
                                              [newRows]="columnMaps.get(combination).newRows"
                                              [refreshFormGroup]="columnMaps.get(combination).refreshFormGroup"
                                              [refreshNewRows]="columnMaps.get(combination).refreshAddNewRow"
                                              [value]="columnMaps.get(combination).values"
                                              (onFormGroupCreated)="onformGroupCreated($event)">
                        </app-ctrm-price-table>
                    </div>
                </div>
            </div>

        </div>
    </form>
</ng-template>

<!--Toaster Message-->
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body">
</p-toast>

<future-trade-info-box (onClose)="onCloseForm()"
                       *ngIf="futureInfoDisplay"
                       [futureInfoDisplay]="futureInfoDisplay"
                       [header]="valueForInfoHeader"
                       [value]="valueForInfo">
</future-trade-info-box>

<p-sidebar [(visible)]="deleteDisplay && canDelete" [baseZIndex]="25" [modal]="false" [showCloseIcon]="false"
           [styleClass]="'bottomPane'" position="bottom">
    <div class="bottomSidebarContainer">
        <div class="leftSection">
            <i (click)="cancel()" class="material-icons closable">clear</i>
        </div>
        <div class="rightSection">
            <button (click)="cancel()" class="cancelBtn">Cancel</button>
            <button [disabled]="deleteDisable" (click)="onRowDelete()" class="priceBtn">Void</button>
        </div>
    </div>
</p-sidebar>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>



