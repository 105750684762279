import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {Router} from '@angular/router';
import {Breadcrumb} from './breadcrumb.model';
import {BreadcrumbService} from './breadcrumb.service';
import {browserRefresh} from "../../app.component";
import {CommonService} from "../../services/common.service";

@Component({
  selector: 'ctrm-breadcrumb',
  templateUrl: './ctrm-breadcrumb.component.html',
  styleUrls: ['./ctrm-breadcrumb.component.css']
})
export class CtrmBreadcrumbComponent implements OnInit,AfterViewInit {
  @Input() items: Breadcrumb[];
  @Input() collapsabel: boolean = false;
  @Input() showLabel: boolean = true;
  @Input() showIcon: boolean = true;
  @Output() OnDropChange = new EventEmitter();
  parentList: number[] = [];
  lastList: number[] = [];

  constructor(private changeDetectorRef:ChangeDetectorRef,private route: Router, public breadCrumbService: BreadcrumbService, private commonService: CommonService) {
  }

  @HostListener('window:popstate', ['$event'])
  onBackClick(event) {
    //this.breadCrumbService.popBreadcrumb();
  }

  @HostListener('window:beforeunload')
  updateLocalBreadcrumb() {
    //this.breadCrumbService.updateBreadCrumb(this.items);
  }

  ngOnInit() {
    if (browserRefresh) {
      let lastroute = this.commonService.getFromStorage("lastRoute");
      if (lastroute !== null && lastroute !== undefined && lastroute['routeTo'] !== null && lastroute['routeTo'] !== undefined) {
        this.route.navigate([lastroute['routeTo']], {
          skipLocationChange: lastroute['skip'],
          queryParams: lastroute['params']
        });
      }
    }
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  scroll(id) {
    document.getElementById(id).scrollIntoView();
  }

  changeRouteMain(i: number, link: string, params?: any, skipLocationChange: boolean = false) {
    if (link !== undefined && link.length > 0) {
      this.popAllBackChilds(i);
      this.breadCrumbService.updateBreadCrumb(this.items);
      this.commonService.saveToStorage("lastRoute", {routeTo: link, skip: false, params: params});
      this.route.navigate([link], {skipLocationChange: skipLocationChange, queryParams: params});
    }
  }

  popAllBackChilds(i) {
    for (let j = i + 1; j < this.items.length; i++) {
      this.breadCrumbService.popBreadcrumb();
    }
  }

  changeRouteChild(j: number, i: number, routeTo: any) {
    let parent = this.items[i];
    let child = parent['childs'][j];
    parent.setIsSelected(true);
    this.commonService.saveToStorage("lastRoute", {
      routeTo: routeTo,
      skip: child['skipLocationChange'],
      params: child['params']
    });
    this.route.navigate([routeTo], {skipLocationChange: child['skipLocationChange'], queryParams: child['params']});
  }

  isSelected(citem: Breadcrumb, j, i) {
    let isSelected = false;
    if (citem.isSelected === true) {
      let parent = this.items[i];
      let child = parent['childs'][j];
      this.updateLastSelected(i, j);
      this.items[i]['label'] = child['label'];
      this.items[i]['icon'] = child['icon'];
      this.items[i]['routeTo'] = child['routeTo'];
      isSelected = true;
    }
    return isSelected;
  }

  updateLastSelected(i, j) {
    if (this.parentList.includes(i)) {
      let pos = this.parentList.indexOf(i);
      this.lastList[pos] = j;
    } else {
      this.parentList.push(i);
      this.lastList.push(j);
    }
  }

  getLastSelectedforParentCode(i) {
    let pos = this.parentList.indexOf(i);
    return this.lastList[pos];
  }

  isLast(i) {
    if (this.items.length - 1 === i) {
      return true
    }
    return false
  }

  formatLabel(label: string) {
    label = label.replace(new RegExp('%2F', 'g'), '/');
    return label;
  }

  goToDashboard() {
    this.route.navigate(['/dashboard']);
  }
}
