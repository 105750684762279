import {AfterViewInit, Component, OnInit} from '@angular/core';
import {MasterService} from '../../masters/services/MasterService';
import {CommonService} from '../../services/common.service';
import {ActivatedRoute} from '@angular/router';
import {MessageService} from 'primeng';
import {Grid} from '../../grid/grid.model';
import {environment} from '../../../environments/environment';
import {Tcolumn} from '../../grid/tcolumn.model';
import {FormGroup, Validators} from '@angular/forms';
import {FormControlServiceService} from '../../services/form-control-service.service';
import {messages} from '../../services/common/messages';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-future-commodity-price-v1',
  templateUrl: './future-commodity-price-v1.component.html',
  styleUrls: ['./future-commodity-price-v1.component.css']
})
export class FutureCommodityPriceV1Component implements OnInit, AfterViewInit {
  //Grid
  grid: Grid;
  refreshGrid: boolean = false;
  toolbarConfig: any = {
    add: true,
    edit: false,
    copy: false,
    refresh: true,
    export: true,
    exportAll: true,
    import: true,
    audit: true,
    print: true,
    prefrences: true
  };
  columns: Map<string, Tcolumn>;
  //sidebar
  header: any = 'New Future Commodity Price';
  saveButtonLabel: string = 'Save';
  priceForm: FormGroup;
  validationSections:any = {};
  displayOnAdd: boolean = false;
  private uuid: any='';
  tickerList: any[] = [];
  private formControlService:FormControlServiceService = new FormControlServiceService();
  //import
  importingFromForm: boolean = false;
  displayImport: boolean = false;
  importTableName: string = 'FutureCommodityPrice';
  parentKeyValue: any;
  file: File;
  gridDisplay: boolean = false;
  priceColumns: Tcolumn[] = [];
  //Table
  refreshAddNewRow: boolean = false;
  newRows: any[] = [];
  refreshFormGroup: boolean = false;
  priceValue: any[] = [];

  constructor(private masterCommonService: MasterService,
              public commonService: CommonService,
              private router: ActivatedRoute, private messageService: MessageService,private route:ActivatedRoute)  {
    this.commonService.getFromStorage('policy');
  }

  ngOnInit(): void {
    this.getColumns();
    this.grid = new Grid(this.columns, true, true, true, true, '', '/api/futurecommodityprice/v1/getallcommoditypricetickerbytenantid?tenantId='+this.commonService.getFromStorage('tenantId'), '', 'Future Commodity Price', undefined, undefined);
    this.priceColumns = [...this.masterCommonService.getGridField(this.grid)];
    this.grid.setUpdateUrl(environment.base_url + '/api/futurecommodityprice/v1/update?tenantId=' + this.commonService.getFromStorage('tenantId'));
    this.grid.iconName = 'priceFixing';
    this.grid.urlIdField = 'uuid';
    this.grid.openSidebarOnUniqueClick = false;
    this.grid.openDefaultInputForm = false;
    this.grid.setGridConfiguration(true, true, false, false);
    this.grid.importUrl = environment.base_url + '/api/futurecommodityprice/v1/importfutureprice';
    this.initInternalForm();
    this.loadValidationSection();

  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params !== undefined && params !== null && Object.keys(params).length > 0) {
        let keys: any[] = Object.keys(params);
        let value = params['mode'];
        if (value !== null && value !== undefined && value === 'import') {
          this.displayImport = true;
        }
      }
    });
  }

  getColumns() {
    this.columns = new Map();
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('ticker', new Tcolumn('ticker', 'Ticker', 'T', 1, true, undefined, true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('futureCommodityPriceDate', new Tcolumn('futureCommodityPriceDate', 'Price Date', 'D', 2, true, undefined, true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('settlementPrice', new Tcolumn('settlementPrice', 'Settlement Price', 'N', 3, true, undefined, true, '', 'B', [Validators.required]));
    this.columns.set('pxOpen', new Tcolumn('pxOpen', 'Open', 'N', 4, true, undefined, true, '', 'B', [Validators.required]));
    this.columns.set('pxHigh', new Tcolumn('pxHigh', 'High', 'N', 5, true, undefined, true, '', 'B', [Validators.required]));
    this.columns.set('pxLow', new Tcolumn('pxLow', 'Low', 'N', 6, true, undefined, true, '', 'B', [Validators.required]));
  }

  //On CLick of Add button
  onClickAdd() {
    this.priceForm.controls['ticker'].setValue('');
    this.priceForm.controls['futureCommodityPriceDate'].setValue('');
    this.refreshGrid = false;
    this.priceValue = [];
    this.gridDisplay = false;
    this.displayOnAdd = true;
    this.getTickerValue();
  }

  getTickerValue() {
    let _this = this;
    _this.commonService.getJSONByURL(environment.base_url + '/api/futurecommodityprice/v1/gettickerlist?tenantId=' + this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
      _this.tickerList = _this.commonService.createListFromObject(next, 'tickerName', 'tickerName', true);
    });
  }

  onFormGroupCreatedPackage(form: FormGroup) {
    this.priceForm = this.formControlService.addFormGroupControl('priceDetails', form).build();
  }

  //Create Form Control
  initInternalForm() {
    this.priceForm = this.formControlService.addControl('ticker', '', [Validators.required])
        .addControl('futureCommodityPriceDate', '', [Validators.required])
        .build();
  }

  //Check for Validation
  private loadValidationSection() {
    let priceDetailsFields = {};
    priceDetailsFields['ticker'] = 'Ticker';
    priceDetailsFields['futureCommodityPriceDate'] = 'Price Date';
    priceDetailsFields['settlementPrice'] = 'Settlement Price';
    priceDetailsFields['pxOpen'] = 'Open';
    priceDetailsFields['pxHigh'] = 'High';
    priceDetailsFields['pxLow'] = 'Low';

    this.validationSections = {};
    this.validationSections['Ticker Details'] = [
      {field: 'ticker', header: 'Ticker'},
      {field: 'futureCommodityPriceDate', header: 'Price Date'}
    ];
    this.validationSections['Price Details'] = [{field: 'priceDetails', header: 'Price Details', grid: true, fields: priceDetailsFields}];
  }

  //On Click of Add Price Button
  addPrice() {
    let _this = this;
    if (this.priceValue === undefined || this.priceValue === null || this.priceValue.length === 0) {
      this.priceValue = [];
    }
    this.gridDisplay = true;
    this.refreshAddNewRow = false;
    this.refreshFormGroup = false;
    let futurepayload = {
      ticker: this.priceForm.controls['ticker'].value,
      priceDate: new Date(this.priceForm.controls['futureCommodityPriceDate'].value+'UTC')
    };
    this.commonService.getJSONByObject(environment.base_url + '/api/futurecommodityprice/v1/getpricetabletemplate?tenantId='+this.commonService.getFromStorage('tenantId'), futurepayload).subscribe((next: any[]) => {
      _this.newRows = next;
      _this.refreshFormGroup = true;
      _this.refreshAddNewRow = true;
    })
  }

  showToast(msg, severity: string = 'success') {
    this.messageService.add({
      severity: 'info',
      summary: msg
    });
  }

  //On save Future Commodity Price
  onSaveFuture(value:any) {
    let _this = this;
    let obj = value;
    let high:any;
    let open:any;
    let low:any;
    let settlementPrice:any;
    obj['priceDetails'] = this.priceValue;
    obj['priceDetails'].forEach(function(row:any) {
      settlementPrice = row['settlementPrice'];
      open = row['pxOpen'];
      high = row['pxHigh'];
      low = row['pxLow'];
    });
      let pricepayloadObj = {
        priceDate: _this.commonService.convertDatetoUTC(this.priceForm.controls['futureCommodityPriceDate'].value),
        ticker: this.priceForm.controls['ticker'].value,
        tenantId: this.commonService.getFromStorage('tenantId'),
        createdBy: this.commonService.getFromStorage('userName'),
        updatedBy: this.commonService.getFromStorage('userName'),
        commodityPriceList: this.priceValue
      };
      this.commonService.post(environment.base_url + '/api/futurecommodityprice/v1/saveMarketPriceforticker?tenantId='+this.commonService.getFromStorage('tenantId'), pricepayloadObj).subscribe(next => {
        this.displayOnAdd = false;
        this.refreshGrid = true;
        this.showToast('Future Price saved successfully');
        _this.formControlService.reset();
      }, (error: HttpErrorResponse) => {
        this.messageService.add({severity: 'error', summary: this.commonService.getHttpErrorMessage(error,undefined)});
      });
  }

  //on close the sidebar
  onClose() {
    this.displayOnAdd = false;
    // this.storagePackagingList = [];
    this.refreshGrid = true;
    this.uuid = '';
    this.initInternalForm();
  }

  //On Update Future Commodity Price
  onSave(value:any) {
    this.commonService.post(environment.base_url + '/api/futurecommodityprice/v1/updatecommoditypriceticker?tenantId='+this.commonService.getFromStorage('tenantId'),value['data']).subscribe(next => {
      this.showToast('Future Price updated successfully');
    }, (error: HttpErrorResponse) => {
      this.messageService.add({severity: 'error', summary: this.commonService.getHttpErrorMessage(error,undefined)});
    });
  }

  getDateFormat() {
    return environment.dateFormat.toUpperCase();
  }

  //Import Section Start
  onImportClick(importingFromForm: boolean = false) {
    this.displayOnAdd = false;
    this.importingFromForm = importingFromForm;
    this.displayImport = true;
    this.parentKeyValue = this.grid.getDataURL().substr(this.grid.getDataURL().lastIndexOf('=') + 1);
  }

  //close import
  closeImport() {
    this.displayImport = false;
    if (this.importingFromForm) {
      this.displayOnAdd = true;
      this.gridDisplay = true;
    }
  }

  importDone(success: boolean) {
    this.displayImport = false;
    if (success) {
      this.messageService.add({
        severity: 'info', summary: messages.updated['summary'],
        detail: messages.importCompleted['message'],
        life: 10000
      });
    }
  }

  onImport(value: any) {
    this.displayOnAdd = true;
    this.displayImport = false;
    this.file = value['file'];
  }

  onImportComplete() {
    this.file = undefined;
  }

  // Table Section
  onNewRowAddComplete() {
    this.refreshAddNewRow = false;
    this.newRows = [];
  }

  onRefreshFromGroupComplete() {
    this.refreshFormGroup = false;
  }
}
