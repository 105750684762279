/* tslint:disable:whitespace */
import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {CommonService} from '../services/common.service';
import {AuthService} from '../services/auth.service';
import {environment} from '../../environments/environment';
import {mailMessages} from '../services/common/messages';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ConfirmationService, MessageService} from 'primeng';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';


@Component({
  selector: 'app-loginpage',
  templateUrl: './loginpage.component.html',
  styleUrls: ['./loginpage.component.css']
})
export class LoginpageComponent implements OnInit {
  @Output() OnLoginSucessfull = new EventEmitter();
  formgroup: FormGroup;
  confirmEmailGroup: FormGroup;
  submitted: boolean = false;
  showPassword: boolean = false;
  message: string = 'Please enter correct login ID / Password';
  isverified: boolean = true;
  isOpen: boolean = false;
  showTenantId: boolean = true;
  confirmEmail: boolean = false;
  submittedEmail: boolean = false;
  resetEmail: string = '';
  userName: string = '';
  userType: string = '';
  tenantId: string = '';
  emailControl = new FormControl('', [Validators.required, Validators.email]);
  aliasControl = new FormControl('', [Validators.required]);
  pleaseWaitBtn: boolean = false;
  selectedValue: string = 'BusinessUser';
  loginUrl: string = environment.base_url + 'userMaster/login';
  alertdisplay:boolean =false;


  constructor(private formBuilder: FormBuilder,
              private route: Router,
              private router: Router,
              private commonService: CommonService,
              private auth: AuthService,
              private http: HttpClient,
              private messageService: MessageService,
              private confirmationservice: ConfirmationService) {
  }

  ngOnInit() {
    this.checkLoginStatus();
    const obj = {};
    obj['username'] = ['', [Validators.required]];
    obj['password'] = ['', [Validators.required, Validators.minLength(6)]];
    obj['tenantId'] = ['', []];
    this.formgroup = this.formBuilder.group(obj);
    this.emailControl.valueChanges.subscribe(next => {
      this.resetEmail = next;
    });
  }

  checkLoginStatus() {
    if (this.commonService.getFromStorage('userid') !== null &&this.commonService.getFromStorage('userid') !== undefined &&this.commonService.getFromStorage('userid').length > 0) {
        this.route.navigate(['/dashboard']);
    }
  }

  logout() {
    localStorage.clear();
    this.commonService.clearStorage();
    this.route.navigate(['/login']);
  }

  checkFromUserMaster(userName: string, password: string) {
    let _this = this;
    this.commonService.post(environment.base_url + 'User/login', {
      userid: userName,
      password: password,
      userType: this.selectedValue
    }).subscribe((response) => {
      if (response['success'] === true) {
        let info = response['userProfile'];
        if (_this.isverified === true) {
          _this.router.navigate(['/dashboard']);
          _this.commonService.saveUserMaster(info);
        }
      }
    }, (error) => {
      _this.submitted = true;
      let mess = error['error']['message'];
      if (mess === 'Password mismatchs') {
        _this.message = 'Incorrect password !';
      } else {
        _this.message = 'User not found ! please create an account first ...';
      }
    });
  }

  checklogin(form: FormGroup) {
    let value = form.value;
    if (form.valid) {
      this.submitted = false;
      this.login(value);
      this.userName = value['username'];
    } else {
      this.submitted = true;
      this.message = 'Please enter correct login ID / Password';
    }
  }

  togglePasswordView() {
    this.showPassword = !this.showPassword;
  }

  openSignup() {
    this.router.navigate(['/signup'])
  }

  forgotPassword() {
    this.isOpen = !this.isOpen;

  }

  postEmail(value) {
    this.pleaseWaitBtn = true;
    let _this = this;
    let obj = {
                "companyAlias" : this.aliasControl.value,
                "emailId" : this.emailControl.value
              };
    if (this.emailControl.valid) {
      this.commonService.getJSONByObject(environment.base_url_new + '/api-iam/api/password-reset/v1/email-verification?email=' + this.resetEmail, obj).subscribe(next => {
        this.pleaseWaitBtn = false;
        _this.submittedEmail = true;
        _this.confirmEmail = true;
        localStorage.setItem('token', next['status']);
        _this.commonService.saveToStorage('tenantId', next['statusType']);
        _this.confirmEmailFormBuilder();
      }, error => {
        this.pleaseWaitBtn = false;
        _this.submittedEmail = true;
        _this.confirmEmail = false;
        this.messageService.add({summary:error["error"]["status"],severity:'error'})
      });
    }
  }

  toggleForgetEmail() {
    this.isOpen = !this.isOpen;
    this.confirmEmail = false;
  }

  onChange(value: any) {
    if (value === 'BusinessUser') {
      this.loginUrl = environment.base_url + 'userMaster/login';
      this.showTenantId = true;
    } else {
      this.loginUrl = environment.base_url + 'auth/login';
      this.showTenantId = false;
    }
  }

  setCheckboxValue(type: string) {
    this.selectedValue = type;
    this.onChange(type);
  }



  //This function will continue login process
  login(value) {
    let object = {};
    let _this = this;
    if (this.selectedValue === 'BusinessAdmin') {
      object = {username: value['username'], password: value['password'], loginType: this.selectedValue};
      //Login API Call for Business Admin
      this.http.post(environment.base_url_new + '/api-iam/api/tenant-management/v1/bussinessadminauth', object).subscribe((next: any) => {
        localStorage.setItem('token', next['rbacdto']['authToken']);
        this.commonService.saveToStorage('loginType', this.selectedValue);
        _this.commonService.saveUserProfile(next);
        _this.OnLoginSucessfull.emit(next);
        _this.router.navigate(['/dashboard']);
      }, (error: HttpErrorResponse) => {
        _this.message = _this.commonService.getHttpErrorMessage(error, 'Login');
        if (error['status'] === 412) {
          _this.message = 'Session is active. Please click Yes to clear the session and login';
          this.tenantId = error['error']['status'];
          _this.submitted = false;
          _this.alertdisplay = true;
        } else {
          _this.submitted = true;
          _this.alertdisplay = false;
        }
      });
    } else {
      object = {username: value['username'], password: value['password'], loginType : this.selectedValue, tenantId: value['tenantId']};
      //Login API Call Business User
      this.commonService.post(environment.base_url_new + '/api-iam/api/usermanagementservice/v1/userauth', object).subscribe((next: any) => {
        localStorage.setItem('token', next['rbacdto']['authToken']);
        this.commonService.saveToStorage('loginType', this.selectedValue);
        _this.commonService.saveUserProfile(next);
        _this.OnLoginSucessfull.emit(next);
        _this.router.navigate(['/dashboard']);
      }, (error: HttpErrorResponse) => {
        _this.message = _this.commonService.getHttpErrorMessage(error, 'Login');
        if (error['status'] === 412) {
          _this.message = 'Session is active. Please click Yes to clear the session and login';
          this.tenantId = error['error']['status'];
          _this.submitted = false;
          _this.alertdisplay = true;
        } else {
          _this.submitted = true;
          _this.alertdisplay = false;
        }
      });
    }
  }
onokclick(form:FormGroup){
    let _value = form.value;
    let _this = this;
    let _obj = {
      "username" : this.userName,
      "loginType" :  this.selectedValue
    };

  this.commonService.post(environment.base_url_new + '/api-iam/api/user-session/v1/logout?tenantId=' + this.tenantId, _obj).subscribe(next => {
    _this.commonService.clearStorage();
    _this.login(_value);
  }, error => {
    this.messageService.add({summary: error['error']['status'], severity: 'error'});
  });


}



  confirmEmailFormBuilder(){
    const obj = {};
    obj['otp'] = ['', [Validators.required]];
    obj['password'] = ['', [Validators.required, Validators.minLength(6)]];
    obj['confirmPassword'] = ['', [Validators.required, Validators.minLength(6)]];
    this.confirmEmailGroup = this.formBuilder.group(obj);
  }

  verifyOtp() {

    let payload =  {
      "emailId" : this.emailControl.value,
      "password" : this.confirmEmailGroup.controls['password'].value,
      "otp" : this.confirmEmailGroup.controls['otp'].value,
      "companyAlias" : this.aliasControl.value
    }
    if (this.confirmEmailGroup.controls['password'].value === this.confirmEmailGroup.controls['confirmPassword'].value){
      this.commonService.post(environment.base_url_new + '/api-iam/api/password-reset/v1/otp-verification?tenantId=' + this.commonService.getTenantId(), payload).subscribe((next: any) => {
        this.confirmEmailGroup.reset();
        this.messageService.add({summary: 'Password reset successful', severity: 'info'});
        this.isOpen = false;
        this.confirmEmail = false;
      }, error => {
        this.confirmEmailGroup.reset();
        this.messageService.add({summary: 'Password reset failure', detail: error['error']['status'], severity: 'error'});
      });

    }else {
      this.messageService.add({summary: 'Password Mismatch', severity: 'error'});
    }
  }

}
