import {Component, OnInit} from '@angular/core';
import {Tcolumn} from '../../../grid/tcolumn.model';
import {Grid} from '../../../grid/grid.model';
import {Tab} from '../../../tab-grid/Tab.model';
import {environment} from '../../../../environments/environment';
import {Validators} from '@angular/forms';
import {CommonService} from '../../../services/common.service';
import {KeyValue} from '../../../grid/key-value.model';
import {Action} from '../../../grid/actions.model';
import {defaultValue} from '../../../grid/defaultValue.model';
import {MasterService} from '../../../masters/services/MasterService';

@Component({
  selector: 'app-mtm-mapping',
  templateUrl: './mtm-mapping.component.html',
  styleUrls: ['./mtm-mapping.component.css']
})
export class MtmMappingComponent implements OnInit {

  columns: Map<string, Tcolumn> = new Map<string, Tcolumn>();
  grid: Grid;
  tabs: Tab[] = [];
  dropDownMaster: any[] = [];
  physicalPriceIndexList: any[] = [];
  basisPriceIndexList: any[] = [];
  fxPriceIndexList: any[] = [];
  futurePriceIndexList: any[] = [];
  commodityListForFuture: any[] = [];
  commodityList: any[] = [];

  constructor(public commonService: CommonService, private masterCommonService: MasterService) {
  }

  ngOnInit(): void {
    this.getDDResponse();
  }

  public getColumns() {
    let _this = this;
    this.columns = new Map();
    let originFunc = function (value: any[]) {
      return value[0]['CommodityOrigin'] !== undefined && value[0]['CommodityOrigin'] !== null && value[0]['CommodityOrigin'].length > 0 ? _this.commonService.addListOTT(value[0]['CommodityOrigin'], 'All', 'all') : [new KeyValue('All', 'all')];
    };
    let gradeFunc = function (value: any[]) {
      return value[0]['CommodityGrade'] !== undefined && value[0]['CommodityGrade'] !== null && value[0]['CommodityGrade'].length > 0 ? _this.commonService.addListOTT(value[0]['CommodityGrade'], 'All', 'all') : [new KeyValue('All', 'all')];
    };
    let brandFunc = function (value: any[]) {
      return value[0]['CommodityBrand'] !== undefined && value[0]['CommodityBrand'] !== null && value[0]['CommodityBrand'].length > 0 ? _this.commonService.addListOTT(value[0]['CommodityBrand'], 'All', 'all') : [new KeyValue('All', 'all')];
    };
    let seasonFunc = function (value: any[]) {
      return value[0]['CommoditySeason'] !== undefined && value[0]['CommoditySeason'] !== null && value[0]['CommoditySeason'].length > 0 ? _this.commonService.addListOTT(value[0]['CommoditySeason'], 'All', 'all') : [new KeyValue('All', 'all')];
    };

    let commodityAction = new Action(environment.base_url + '/api/commodity/v1/getcommoditydefaultproperties?tenantId='+this.commonService.getFromStorage('tenantId')+'&commodityName=');
    commodityAction.setCovertToListRequired(false);
    commodityAction.addFieldToUpdate('origin', undefined, originFunc, ['DB'], undefined, true, 'listvalues');
    commodityAction.addFieldToUpdate('grade', undefined, gradeFunc, ['DB'], undefined, true, 'listvalues');
    commodityAction.addFieldToUpdate('brand', undefined, brandFunc, ['DB'], undefined, true, 'listvalues');
    commodityAction.addFieldToUpdate('season', undefined, seasonFunc, ['DB'], undefined, true, 'listvalues');
    let transactionListExtra = (value: any[] = []) => {
      let object = _this.grid.getColumn().get('transactionType').getExtra();
      object['meta'] = {disabled: false};
      if (value[0].toString().toLowerCase() === 'futures' || value[0].toString().toLowerCase() === 'inventory position') {
        object['meta'] = {disabled: true};
      } 
      return object;
    };

    let commodityExtra = (value: any[] = []) => {
      let object = _this.grid.getColumn().get('commodity').getExtra();
      object['meta'] = {disabled: false};
      if (value[0].toString().toLowerCase() === 'forex') {
        object['meta'] = {disabled: true};
      }
      return object;
    };

    let basisMtmIndexExtra = (value: any[] = []) => {
      let object = _this.grid.getColumn().get('basisMtmIndex').getExtra();
      object['meta'] = {disabled: false};
      if (value[0].toString().toLowerCase() === 'futures' || value[0].toString().toLowerCase() === 'forex') {
        object['meta'] = {disabled: true};
      }
      return object;
    };

    let basisMtmIndexTradeTypeExtra = (value: any[] = []) => {
      let object = _this.grid.getColumn().get('basisMtmIndex').getExtra();
      object['meta'] = {disabled: false};
      if (value[0].toString().toLowerCase() === 'ptbf' || value[0].toString().toLowerCase() === 'fixed') {
        object['meta'] = {disabled: true};
      }
      return object;
    };

    let transactionbasisMtmIndexTradeTypeExtra = (value: any[] = []) => {
      let object = _this.grid.getColumn().get('basisMtmIndexPeriod').getExtra();
      object['meta'] = {disabled: false};
      if ((value[0].toString().toLowerCase() === 'ptbf' || value[0].toString().toLowerCase() === 'fixed')) {
        object['meta'] = {disabled: true};
      }
      return object;
    };
    let trasnsactionBasismtmIndexPeriodDefault = (value: any[] = []) => {
      if(value !== undefined && value !== null) {
        if(value[1].toString().toLowerCase() === 'inventory position') {
          return "Forward Month";
        }
      }
    };

    let basisMtmIndexPeriodExtra = (value: any[] = []) => {
      let object = _this.grid.getColumn().get('basisMtmIndexPeriod').getExtra();
      object['meta'] = {disabled: false};
      if (value[0].toString().toLowerCase() === 'futures' || value[0].toString().toLowerCase() === 'forex'||value[0].toString().toLowerCase() === 'inventory position') {
        object['meta'] = {disabled: true};
      }
      return object;
    };

    let costNetAction = (value: any[] = []) => {
      let object = _this.grid.getColumn().get('costsToNet').getExtra();
      object['meta'] = {disabled: false};
      if (value[0].toString().toLowerCase() === 'futures') {
        object['meta'] = {disabled: true};
      }
      return object;
    };

    let costAddAction = (value: any[] = []) => {
      let object = _this.grid.getColumn().get('costsToAdd').getExtra();
      object['meta'] = {disabled: false};
      if (value[0].toString().toLowerCase() === 'futures') {
        object['meta'] = {disabled: true};
      }
      return object;
    };

    let transactionList = function (value:any[]) {
      let list:KeyValue[] = [new KeyValue()];
      if(value !== undefined && value !== null) {
        if(value[0].toString().toLowerCase() === 'forex') {
          list.push(new KeyValue('Cash','Cash'));
          list.push(new KeyValue('Forward','Forward'));
        } else {
          _this.dropDownMaster['price_type_ind'].forEach(function (obj) {
            list.push(new KeyValue(obj,obj));
          });
        }
        return list;
      }
    }

    let mtmIndexList = function (value:any[]) {
      if (value !== undefined && value !== null) {
        if (value[0].toString().toLowerCase() === 'futures') {
          return _this.futurePriceIndexList;
        } else if (value[0].toString().toLowerCase() === 'forex') {
          return _this.fxPriceIndexList;
        } else {
          return _this.physicalPriceIndexList;
        }
      }
    }

    let commodityList = function(value: any[]) {
      if (value !== undefined && value !== null) {
        if (value[0].toString().toLowerCase() === 'futures') {
          return _this.commodityListForFuture;
        } else {
          return _this.commodityList;
        }
      }
    };

    let tradeTransactiontypeAction = (value: any[] = []) => {
      let object = _this.grid.getColumn().get('tradeTransactionType').getExtra();
      object['meta'] = {disabled: false};
      if (value[0].toString().toLowerCase() === 'inventory position') {
        object['meta'] = {disabled: true};
      }
      return object;
    };

    let transactionTypeDefault = (value: any[] = []) => {
      let object = _this.grid.getColumn().get('transactionType').getExtra();
      if(value !== undefined && value !== null) {
        if(value[0].toString().toLowerCase() === 'inventory position') {
          return "Fixed";
        }
        return "";
      }
    };

    let mtmIndexPeriodDefault = (value: any[] = []) => {
      let object = _this.grid.getColumn().get('transactionType');
      if(value !== undefined && value !== null) {
          return "Forward Month";
      }
    };

    let BasismtmIndexPeriodDefault = (value: any[] = []) => {
      if(value !== undefined && value !== null) {
          return "Forward Month";
      }
    };


    let mtmIndexPeriodExtra = (value: any[] = []) => {
      let object = _this.grid.getColumn().get('mtmIndexPeriod').getExtra();
      object['meta'] = {disabled: false};
      if (value[0].toString().toLowerCase() === 'inventory position') {
        object['meta'] = {disabled: true};
      }
      return object;
    };

    let mtmIndexPeriodList = function(value:any[]){
      let val:KeyValue[] = [new KeyValue()];
      if (value !== undefined && value !== null) {
         _this.dropDownMaster['mtm_index_price_period_ind'].forEach(function (obj) {
           val.push(new KeyValue(obj,obj));
         });
          return val;
      }
    }

    let tradeTypeAction = new Action();
    tradeTypeAction.addFieldToUpdate('transactionType', undefined, transactionList, undefined, undefined, true, 'listvalues');
    tradeTypeAction.addFieldToUpdate('mtmIndex', undefined, mtmIndexList, undefined, undefined, true, 'listvalues');
    tradeTypeAction.addFieldToUpdate('mtmIndexPeriod', undefined, mtmIndexPeriodList, undefined, undefined, true, 'listvalues');
    tradeTypeAction.addFieldToUpdate('basisMtmIndexPeriod', undefined, mtmIndexPeriodList, undefined, undefined, true, 'listvalues');
    tradeTypeAction.addFieldToUpdate('commodity', undefined, commodityList, undefined, undefined, true, 'listvalues');
    tradeTypeAction.addFieldToUpdate('transactionType', undefined, transactionListExtra, undefined, undefined, true, 'extra');
    tradeTypeAction.addFieldToUpdate('commodity', undefined, commodityExtra, undefined, undefined, true, 'extra');
    tradeTypeAction.addFieldToUpdate('basisMtmIndex', undefined, basisMtmIndexExtra, undefined, undefined, true, 'extra');
    tradeTypeAction.addFieldToUpdate('costsToNet', undefined, costNetAction, undefined, undefined, true, 'extra');
    tradeTypeAction.addFieldToUpdate('costsToAdd', undefined, costAddAction, undefined, undefined, true, 'extra');
    tradeTypeAction.addFieldToUpdate('tradeTransactionType', undefined, tradeTransactiontypeAction, undefined, undefined, true, 'extra');
    tradeTypeAction.addFieldToUpdate('transactionType', undefined, transactionTypeDefault, undefined, undefined, true, 'defaultValue');
    // tradeTypeAction.addFieldToUpdate('mtmIndexPeriod', undefined, mtmIndexPeriodExtra, undefined, undefined, true, 'extra');
    tradeTypeAction.addFieldToUpdate('mtmIndexPeriod', undefined, mtmIndexPeriodDefault, undefined, undefined, true, 'defaultValue');
    tradeTypeAction.addFieldToUpdate('basisMtmIndexPeriod', undefined, BasismtmIndexPeriodDefault, ['transactionType','tradeType'], undefined, true, 'defaultValue');
    tradeTypeAction.addFieldToUpdate('basisMtmIndexPeriod', undefined, basisMtmIndexPeriodExtra, undefined, undefined, true, 'extra');

    let validationFunc = function(value: any[]) {
      let validators: Validators[] = [];
      if (value[0] !== undefined && value[0] !== null && value[1] !== undefined && value[1] !== null && value[0].toString().toLowerCase() === 'basis' && value[1].toString().toLowerCase() === 'physical') {
        validators.push(Validators.required);
      }
      return validators;
    };
    let transactionTypeAction = new Action();
    transactionTypeAction.addFieldToUpdate('basisMtmIndex',undefined,validationFunc,['transactionType','tradeType'],undefined,true,'validators')
    transactionTypeAction.addFieldToUpdate('basisMtmIndex', undefined, basisMtmIndexTradeTypeExtra, undefined, undefined, true, 'extra');
    transactionTypeAction.addFieldToUpdate('basisMtmIndexPeriod',undefined,validationFunc,['transactionType','tradeType'],undefined,true,'validators')
    transactionTypeAction.addFieldToUpdate('basisMtmIndexPeriod', undefined, transactionbasisMtmIndexTradeTypeExtra, ['transactionType','tradeType'], undefined, true, 'extra');
    transactionTypeAction.addFieldToUpdate('basisMtmIndexPeriod', undefined, trasnsactionBasismtmIndexPeriodDefault, ['transactionType','tradeType'], undefined, true, 'defaultValue');


    let outputTransactionFunc = function (value:any[]) {
      if (value[0] === 'Cash' || value[0] === 'Forward') {
        return value[0];
      } else {
        return _this.commonService.applyTitleCaseFormatting(value[0]);
      }
    }
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('tradeType', new Tcolumn('tradeType', 'Trade Type', 'L', 1, true, this.masterCommonService.getListFromArray(this.dropDownMaster['trade_type_ind'], false, true), true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, [], tradeTypeAction, undefined, true));
    this.columns.set('transactionType', new Tcolumn('transactionType', 'Transaction Type', 'L', 2, true, [], true, new defaultValue('', undefined, undefined, undefined, false, true, false, outputTransactionFunc), 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, transactionTypeAction));
    this.columns.set('tradeTransactionType', new Tcolumn('tradeTransactionType', 'Buy/Sell', 'L', 3, true, this.getBuySellList(), true, 'ALL', 'B'));
    this.columns.set('company', new Tcolumn('company', 'Company', 'L', 4, true, this.masterCommonService.getListFromArray(this.dropDownMaster['company'], false, true, 'All', 'all'), true, 'all', 'B', [Validators.required]));
    this.columns.set('commodity', new Tcolumn('commodity', 'Commodity', 'L', 5, true, this.commodityList, true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, commodityAction));
    this.columns.set('origin', new Tcolumn('origin', 'Origin', 'L', 6, true, undefined, true, 'all', 'B', []));
    this.columns.set('grade', new Tcolumn('grade', 'Grade', 'L', 7, true, undefined, true, 'all', 'B', []));
    this.columns.set('brand', new Tcolumn('brand', 'Brand', 'L', 8, true, undefined, true, 'all', 'B', []));
    this.columns.set('season', new Tcolumn('season', 'Season', 'L', 9, true, undefined, true, 'all', 'B', []));
    this.columns.set('location', new Tcolumn('location', 'Location', 'L', 10, true, this.masterCommonService.getListFromArray(this.dropDownMaster['location'], false, true, 'All', 'all'), true, 'all', 'B', [Validators.required]));
    this.columns.set('loadlocation', new Tcolumn('loadlocation', 'Load', 'L', 11, true, this.masterCommonService.getListFromArray(this.dropDownMaster['location'], false, true, 'All', 'all'), true, 'all', 'B', [Validators.required]));
    this.columns.set('dischargelocation', new Tcolumn('dischargelocation', 'Discharge', 'L', 12, true, this.masterCommonService.getListFromArray(this.dropDownMaster['location'], false, true, 'All', 'all'), true, 'all', 'B', [Validators.required]));
    this.columns.set('incoterm', new Tcolumn('incoterm', 'Incoterm', 'L', 13, true, this.masterCommonService.getListFromArray(this.dropDownMaster['incoterm'], false, true, 'All', 'all'), true, 'all', 'B', [Validators.required]));
    this.columns.set('costsToNet', new Tcolumn('costsToNet', 'Costs To Net', 'ML', 13, true, this.masterCommonService.getListFromArray(this.dropDownMaster['cost_detail_name_ind'], false, false), true, new defaultValue([]), 'B', [],'text',1,undefined,undefined,undefined,undefined,{outputFormat:"array", placeHolder:"None"}));
    this.columns.set('costsToAdd', new Tcolumn('costsToAdd', 'Costs To Add', 'ML', 13, true, this.masterCommonService.getListFromArray(this.dropDownMaster['cost_detail_name_ind'], false, false), true, new defaultValue([]), 'B', [],'text',1,undefined,undefined,undefined,undefined,{outputFormat:"array", placeHolder:"None"}));
    this.columns.set('premiumDiscount', new Tcolumn('premiumDiscount', 'Prem/Disc', 'T', 14, true, undefined, true, '', 'B', []));
    this.columns.set('premCurrency', new Tcolumn('premCurrency', 'Prem/Currency', 'L', 15, true, this.commonService.getCurrencyList(), true, 'USD', 'B', []));
    this.columns.set('premUom', new Tcolumn('premUom', 'Prem UOM', 'L', 16, true, this.masterCommonService.getListFromArray(this.dropDownMaster['unitofmeasurement'], false, true, 'Select', ''), true, 'MT', 'B', [Validators.required]));
    this.columns.set('mtmIndex', new Tcolumn('mtmIndex', 'MTM Index', 'L', 17, true, this.physicalPriceIndexList, true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined));
    this.columns.set('mtmIndexPeriod', new Tcolumn('mtmIndexPeriod', 'MTM Index Period', 'L', 18, true, [], true, '', 'B', [], undefined, undefined, undefined, undefined, undefined))
    this.columns.set('basisMtmIndex', new Tcolumn('basisMtmIndex', 'Basis MTM Index', 'L', 19, true, this.basisPriceIndexList, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined));
    this.columns.set('basisMtmIndexPeriod', new Tcolumn('basisMtmIndexPeriod', 'Basis MTM Index Period', 'L', 20, true, [], true, '', 'B', [], undefined, undefined, undefined, undefined, undefined));


    this.grid = new Grid(this.columns, true, true, true, true, '', '/api/mtmindexmapping/v1/getAllMTMIndexMap?tenantId=' + this.commonService.getFromStorage('tenantId'), '', 'MTM Index', '');
    this.grid.iconName = 'brandMaster';
    this.grid.updateCode = 'uuid';
    this.grid.hrefUrl = environment.base_url + '/api/mtmindexmapping/v1/getMTMIndexMap?tenantId=' + this.commonService.getFromStorage('tenantId') + '&uuid=';
    this.grid.setSaveUrl(environment.base_url + '/api/mtmindexmapping/v1/createIndexMap?tenantId=' + this.commonService.getFromStorage('tenantId'));
    this.grid.setUpdateUrl(environment.base_url + '/api/mtmindexmapping/v1/update?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=');
    this.grid.addSection('Transactional Details', ['tradeType','transactionType', 'tradeTransactionType', 'company', 'commodity', 'origin', 'grade', 'brand', 'season', 'location', 'loadlocation', 'dischargelocation', 'incoterm', 'costsToNet', 'costsToAdd']);
    this.grid.addSection('MTM Index Details', ['premDisc', 'premCurr', 'premUom','mtmIndex','basisMtmIndex']);
    this.grid.addNewRowInline = true;
  }


  getDDResponse() {
    let _this = this;
    this.commonService.getFromUrl(environment.base_url + '/api/priceIndex/v1/getpriceindexbycategory?tenantId=' + this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
      _this.basisPriceIndexList = _this.masterCommonService.getListFromArray(next['basis']);
      _this.physicalPriceIndexList = _this.masterCommonService.getListFromArray(next['physical']);
      _this.fxPriceIndexList = _this.masterCommonService.getListFromArray(next['fx']);
      //Future Price Index
      _this.commonService.getJSONByURL(environment.base_url + '/api/futurecommodityprice/v1/gettickerlist?tenantId=' + this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
        _this.futurePriceIndexList = _this.commonService.createListFromObject(next, 'tickerName', 'tickerName', true);
      });
      _this.commonService.getJSONByURL(environment.base_url + '/api/commodity/v1/getcommodityfromfuturemetadata?tenantId=' + this.commonService.getTenantId()).subscribe((next: any) => {
        _this.commodityListForFuture = _this.masterCommonService.getListFromArray(next, false, true);
      });
      _this.commonService.getLoadData('commodity', ['config', 'config', 'config', 'config', 'config', 'config', 'config', 'data', 'data', 'data','data'],
          ['commodity', 'location', 'unitofmeasurement', 'company', 'incoterm', 'futureoptionsindex'
            , 'physicalbasisindex',
            [{key: 'name', value: 'trade_type_ind'}],
            [{key: 'name', value: 'price_type_ind'}],
            [{key: 'name', value: 'mtm_index_price_period_ind'}],
            [{key: 'name', value: 'cost_detail_name_ind'}]]).subscribe((next: any) => {
        _this.dropDownMaster = next;
        _this.commodityList = this.masterCommonService.getListFromArray(this.dropDownMaster['commodity']);
        _this.getColumns();
      });
    });

  }

  getBuySellList() {
    let buySellList = [];
    buySellList.push(new KeyValue('All', 'ALL'));
    buySellList.push(new KeyValue('Buy', 'BUY'));
    buySellList.push(new KeyValue('Sell', 'SELL'));
    return buySellList;
  }

}

