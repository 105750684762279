import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {CommonService} from '../../../services/common.service';
import {environment} from '../../../../environments/environment';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {ConfirmationService, MessageService} from 'primeng';
import {FilterUtils} from 'primeng/utils';
import {HttpErrorResponse} from '@angular/common/http';
import {MasterService} from '../../../masters/services/MasterService';
import {KeyValue} from '../../../grid/key-value.model';
import {AccessPolicyService} from '../../../services/accesspolicy.service';
import * as moment from 'moment';

@Component({
    selector: 'app-generate-invoice',
    templateUrl: './generate-invoice.component.html',
    styleUrls: ['./generate-invoice.component.css'],
    providers: [ConfirmationService]
})
export class GenerateInvoiceComponent implements OnInit, OnChanges {

    @Input() loading: boolean = false;
    @Input() adjustmentAmountDescription: any = '';
    @Input() isApproving:boolean = false;
    @Input() sell: boolean = true;
    @Input() data: any[] = [];
    @Input() readOnly: boolean = false;
    @Input() approved: boolean = false;
    @Input() plannedObligation: any;
    @Input() buttonValue: any;
    query: any = null;

    @Input() generateGroupInvoice: boolean=false;

    @Output() onDraftClose = new EventEmitter();
    @Output() onSendForApproval = new EventEmitter();
    @Output() onClickSettle = new EventEmitter();
    @Output() onApproved = new EventEmitter();
    @Output() onPostingDone = new EventEmitter();
    @ViewChild('af', {static: true}) formElement;
    @ViewChild('cashFlowGrid', {static: true}) table;

    formHeight: string = '';
    invoiceForm: FormGroup;
    working: boolean = false;
    allBanks: any [] = [];
    plannedObligationObject:any;
    banksDropdown: any [] = [];
    buttonDisabled:boolean = false;
    currencyList: any[] = [];
    adjustmentRow: boolean = false;
    totalAmount: number = 0;
    viewerState: boolean = false;
    totalTemp: number = 0;
    adjustAmount: any = 0;
    uomFeteched:boolean = false;
    display: boolean = true;
    totalInWords: any;
    submitApiFunction: Function;
    settleApiFunction: Function;
    bsConfig: Partial<BsDatepickerConfig>;
    financialAmountType:any = '';
    docUrl: string = '';
    docName: string = '';
    showDoc: boolean = false;
    paymentDueMinDate: any = '';
    previewBytes: any;
    fxRate: number = 1.0000;
    paymentConfirmedBy: any = '';
    conversionObject:any = null;
    financialAmtList: any[] = [];
    blDate: any = '';
    advanceFinancialAmountList: any[] = [];
    invoiceCurrency: any;
    remitList: any[] = [];
    invoiceAppDate: any = '';
    invoiceStausLabel: any = '';
    financeAppDate: any = '';
    invoiceDate: any = '';
    postingDate: any;
    paymentDate: any;
    fileName: string = '';
    fileToUpload: any;
    cfCols: any[] = [];
    errorMessage: any;
    commodityList: any[] = [];
    paymentTermList:KeyValue[] = [];
    companyList:any[] = [];
    loadPortList:any[] = [];
    unloadPortList:any[] = [];
    companyListnext:any[] = [];
    suggestionListPaymentTerm: any[] = [];
    suggestionListLoading: any[] = [];
    suggestionListUnloading: any[] = [];
    isUpdatingBankDetails:boolean = false;
    bankStatus:boolean ;
    lineItems: any[];
    quantityRoundOff:number=3;
    priceRoundOff:number=2;
    private removableLineItemTypes:string[] =['trade reversal','cost reversal','cost','charges'];
    private removableLineItemBooleanFields:string[] =['advancePayment','paidInvoice','premiumdiscount'];

    documentFormat: any;
    documentFormatList: any[] = [];
    allowFormatEnable:boolean = false;


    dateFormat: string = environment.dateFormat.toUpperCase();
    callApiFunc:Function;
    callApiFuncUnpost:Function;
    initDone = false;

    constructor(public commonService: CommonService,private accessService:AccessPolicyService,private messageService: MessageService, private confirmationService: ConfirmationService, private masterCommonService: MasterService) {
    }

    @HostListener('window:resize', [])
    public onResize() {
        this.calculateHeight();
    }

    private calculateHeight() {
        let offsetHeightAF = this.formElement.el.nativeElement['offsetParent']['offsetHeight'];
        this.formHeight = (offsetHeightAF - 200) + 'px';
    }


    ngOnInit() {
        this.init();
    }

    init() {
        if(this.initDone) return;
        this.initDone = true;
        let _this = this;
        let tenantConfig=JSON.parse(this.commonService.getFromStorage("tenantConfig"));
        if(tenantConfig && tenantConfig.roundingFormat){
            _this.priceRoundOff=tenantConfig.roundingFormat.priceRounding;
            _this.quantityRoundOff=tenantConfig.roundingFormat.quantityRounding;
        }
        else{
            _this.priceRoundOff=2;
            _this.quantityRoundOff=3;
        }

        FilterUtils['notContainsValue'] = function gte(value: any, filter: any[]): boolean {
            if (filter === undefined || filter === null) {
                return false;
            }
            if (value === undefined || value === null || value.length === 0) {
                return false;
            }
            return !filter.includes(value);
        };
        FilterUtils['containsValue'] = function gte(value: any, filter: any[]): boolean {
            if (filter === undefined || filter === null) {
                return false;
            }
            if (value === undefined || value === null || value.length === 0) {
                return false;
            }
            return filter.includes(value);
        };
        this.bsConfig = new BsDatepickerConfig();
        this.bsConfig.dateInputFormat = this.dateFormat;
        this.bsConfig.adaptivePosition = true;
        this.bsConfig.useUtc = false;
        this.currencyList = this.commonService.getCurrencyList();
        this.submitApiFunction =  function(payload: any) {
            let updateInvoicePayload: {};
            updateInvoicePayload = _this.getPayload(_this.totalAmount,_this.data['invoiceType']);
            updateInvoicePayload['invoiceApprovedBy'] = _this.commonService.getFromStorage('userName');
            updateInvoicePayload['financeApprovedBy'] = _this.commonService.getFromStorage('userName');
            updateInvoicePayload['financeApprovedDate'] = _this.commonService.convertDatetoUTC(new Date());
            updateInvoicePayload['traderApprovalDate'] = _this.commonService.convertDatetoUTC(new Date());
            payload['url'] = environment.base_url + '/api/invoice/v1/update?tenantId='+_this.commonService.getFromStorage('tenantId')+'&stage='+'APPROVE';
            payload['type'] = 'post';
            payload['payload'] = updateInvoicePayload;
            return payload;
        };

        this.settleApiFunction =  function(payload: any) {
            let updateInvoicePayload: {};
            if(_this.isDataValid()) {
                updateInvoicePayload = _this.getPayload(_this.totalAmount,_this.data['invoiceType']);
                updateInvoicePayload['invoiceApprovedBy'] = _this.commonService.getFromStorage('userName');
                updateInvoicePayload['financeApprovedBy'] = _this.commonService.getFromStorage('userName');
                updateInvoicePayload['financeApprovedDate'] = _this.commonService.convertDatetoUTC(new Date());
                updateInvoicePayload['traderApprovalDate'] = _this.commonService.convertDatetoUTC(new Date());
                payload['url'] = environment.base_url + '/api/invoice/v1/update?tenantId='+_this.commonService.getFromStorage('tenantId')+'&stage='+'SETTLE';
                payload['type'] = 'post';
                payload['payload'] = updateInvoicePayload;
            }
            return payload;
        };
        let settlementViewFunc = (rowData:any,index:number) => {
            if(rowData['advancePayment'] !== null && rowData['advancePayment'] !== undefined && rowData['advancePayment']) {
                if(_this.isApproving) {
                    return _this.formatNumber(_this.getFinancialAmount(rowData['settlementValue']), _this.priceRoundOff);
                }
                return _this.formatNumber(rowData['settlementValue'], _this.priceRoundOff);
            } else {
                return _this.formatNumber(_this.getFinancialAmount(rowData['settlementValue']), _this.priceRoundOff);
            }
        }

        let disableFunc = (rowData:any,index:number) => {
            return rowData['paidInvoice'];
        }

        let disableFuncDeliveryTerm = (rowData:any,index:number) => {
            return rowData['itemCategory'].toLowerCase() === 'text'?true:rowData['paidInvoice'];
        }

        let disableFuncAdvance = (rowData:any,index:number) => {
            if(rowData['itemCategory'].toLowerCase() === 'text') {
                return true
            }
            return rowData['paidInvoice'] || (rowData['tradePrice'] === null || rowData['tradePrice'] === undefined ||  parseFloat(rowData['tradePrice']) === 0);
        }
        let disableFuncPrice = (rowData:any,index:number) => {
            return rowData['itemCategory'].toLowerCase() === 'text'?true:rowData['paidInvoice'] || (rowData['newLineItem'] === null || rowData['newLineItem'] === undefined || !rowData['newLineItem']);
        }
        this.cfCols = [];
        this.cfCols.push({field: 'itemNumber', header: '#S.No.',width:'5%',viewFunc:(rowData:any,ri:number) => { return rowData['itemNumber']}});
        this.cfCols.push({field: 'itemCategory', header: 'Item Category',width:'auto',editable:!this.readOnly,type:'list',list:[new KeyValue("Sales","Sales"),new KeyValue("Purchase","Purchase")],
            disabled: disableFunc,
            onChangeValue: (rowData:any,ri:number) => {
                if(rowData['newLineItem']) {
                    rowData['narration'] = rowData['itemCategory'];
                } else {
                    rowData['glCode'] = '';
                    if(rowData['originalItemCategory'] === rowData['itemCategory']){
                        rowData['glCode'] = rowData['originalGlCode'];
                    } 
                }
            }});
        if(this.readOnly) {
            let type = "autocomplete";
            let list = [];
            let editable = false;
            if (this.buttonValue === 'Post Invoice' ){
                let tenantConfig = this.commonService.getFromTenantConfig('[]','invoiceConfig','glMapping');
                if(tenantConfig !== null && tenantConfig !== undefined && tenantConfig.length !== 0) {
                    let val = JSON.parse(tenantConfig);
                    if(val !== null && val !== undefined) {
                        val.forEach(item => {
                            if(!list.includes(item['value'])) {
                                list.push(item['value']);
                            }
                        })
                    }
                }
                editable = true;
            }
            this.cfCols.push({field: 'glCode', header: 'GL Code',width:'auto',editable:editable,type:type,list:list});
        }
        this.cfCols.push({field: 'deliveryTerm', header: 'Delivery Term',width:'auto',editable:!this.readOnly,type:'text',disabled: disableFuncDeliveryTerm});
        this.cfCols.push({field: 'commodity', header: 'Commodity',width:'auto'});
        this.cfCols.push({field: 'quantity', header: 'Units',width:'8%',type:'number', suffixFunc:(rowData:any,index:number) =>{
                if(rowData['newLineItem']){
                    return '';
                }
                return rowData['itemCategory'].toLowerCase() === 'text'?"":(rowData['quantity'] === null || rowData['quantity'] === undefined || rowData['quantity'] === '' || rowData['quantity'] == 0)?'':rowData['uom']
            }});
        this.cfCols.push({field: 'tradePrice', header: 'Price',width:'12%',type:'number',disabled: disableFuncPrice,editable:!this.readOnly,
            onFocusOut: (rowData:any,ri:number) => {
                rowData['tradePrice'] = _this.commonService.getFormattedNumberWithoutComma(parseFloat(rowData['tradePrice']), _this.priceRoundOff);
            },
            onChangeValue: (rowData:any,ri:number) => {
                let newLineItem = rowData['newLineItem'];
                if(newLineItem === null || newLineItem === undefined || !newLineItem) {
                    return;
                }
                let percentage = parseFloat(rowData['advanceInPercent']);
                let settlementValue  = _this.commonService.getFormattedNumberWithoutComma(parseFloat(rowData['tradePrice']), _this.priceRoundOff);
                if(percentage > 0 && percentage <= 100) {
                    rowData['settlementValue'] = _this.commonService.getFormattedNumberWithoutComma(((Number(settlementValue)* percentage)/100),_this.priceRoundOff);
                } else {
                    rowData['advanceInPercent'] = 0;
                    rowData['settlementValue'] = _this.commonService.getFormattedNumberWithoutComma(settlementValue, _this.priceRoundOff);
                }
                _this.getTotalAmount(false);
            },
            suffixFunc:(rowData:any,index:number) =>{
                if(rowData['tradePrice'] === null  || rowData['tradePrice'] === undefined || parseFloat(rowData['tradePrice']) === 0) {
                    return '';
                }
                if(rowData['newLineItem']){
                    return  rowData['itemCategory'].toLowerCase() === 'text'?"":_this.getCurrency();
                }
                return  rowData['itemCategory'].toLowerCase() === 'text'?"":rowData['currency']+"/"+rowData['priceCurrencyUom'];
        }});
        this.cfCols.push({field: 'narration', header: 'Description',editable:!this.readOnly,disabled: disableFunc,width:'20%',type:'textarea'});
        this.cfCols.push({field: 'shipDocNumber', header: 'Ship Doc No.',width:'auto',type:'text',visible:true});
        this.cfCols.push({field: 'advanceInPercent',min:0,max:100,header: 'Percentage',width:'auto',editable:!this.readOnly,disabled: disableFuncAdvance,type:'number',suffixFunc:(rowData:any,index:number) =>{
                if(rowData['advanceInPercent'] == 0)  {
                    return '';
                }
                return rowData['itemCategory'].toLowerCase() === 'text'?"":"%";
            },
            onFocusOut: (rowData:any,ri:number) => {
                rowData['advanceInPercent'] = _this.commonService.getFormattedNumberWithoutComma(parseFloat(rowData['advanceInPercent']), _this.priceRoundOff);
            },
            onChangeValue: (rowData:any,index:number) => {
                let isNewEntry = rowData['newLineItem'];
                let percentage = parseFloat(rowData['advanceInPercent']);
                let settlementValue = '';
                if(!isNewEntry){
                    settlementValue = (parseFloat(rowData['quantity']) * parseFloat(rowData['tradePrice'])).toString();
                }else{
                    settlementValue = _this.commonService.getFormattedNumberWithoutComma(parseFloat(rowData['tradePrice']), _this.priceRoundOff);
                }

                if(percentage >= 0 && percentage <= 100) {
                    let calcValue = ((Number(settlementValue)* percentage)/100);
                    rowData['settlementValue'] = calcValue.toFixed(_this.priceRoundOff);
                } else {
                    rowData['advanceInPercent'] = 1;
                    rowData['settlementValue'] = _this.commonService.getFormattedNumberWithoutComma(settlementValue, _this.priceRoundOff);
                }
                _this.updateNarration(rowData,rowData['advanceInPercent']);
                _this.getTotalAmount(false);
            }});
        this.cfCols.push({field: 'settlementValue', header: 'Amount',width:'auto',type:'number',editable:!this.readOnly,disabled: disableFuncAdvance,viewFunc:settlementViewFunc,
                onFocusOut: (rowData:any,ri:number) => {
                    _this.getTotalAmount(false)
                    rowData['settlementValue'] = _this.commonService.getFormattedNumberWithoutComma(parseFloat(rowData['settlementValue']), _this.priceRoundOff);
                },
                onChangeValue: (rowData:any,ri:number) => { _this.onChangeSettelemtValue(rowData,ri)},
                suffixFunc:(rowData:any,index:number) =>{ return  rowData['itemCategory'].toLowerCase() === 'text'?"":_this.getCurrency()} });
        this.callApiFunc = function(payload:any) {
            let updateInvoicePayload: {};
            if(_this.isDataValid()) {
                updateInvoicePayload = _this.getPayload(_this.totalAmount,_this.data['invoiceType']);
                updateInvoicePayload['invoiceApprovedBy'] = _this.commonService.getFromStorage('userName');
                updateInvoicePayload['financeApprovedBy'] = _this.commonService.getFromStorage('userName');
                updateInvoicePayload['financeApprovedDate'] = _this.commonService.convertDatetoUTC(new Date());
                updateInvoicePayload['traderApprovalDate'] = _this.commonService.convertDatetoUTC(new Date());
                payload['url'] = environment.base_url + '/api/invoice/v2/post-invoice';
                payload['type'] = 'post';
                payload['payload'] = updateInvoicePayload;
            }
            return payload;
        }
        this.callApiFuncUnpost = function(payload:any) {
            let updateInvoicePayload: {};
            if(_this.isDataValid()) {
                updateInvoicePayload = _this.getPayload(_this.totalAmount,_this.data['invoiceType']);
                payload['url'] = environment.base_url + '/api/invoice/v2/unpost-invoice';
                payload['type'] = 'post';
                payload['payload'] = updateInvoicePayload;
            }
            return payload;
        }
    }

    private updateNarration(rowData,percentage) {
        let desc:string = rowData['narration'];
        if(desc.toLowerCase().startsWith("sales of") || desc.toLowerCase().startsWith("purchase of")) {
            let splits:string[] = desc.split("@");
            splits.splice(splits.length -1,1);
            splits.push(" "+this.commonService.getFormattedNumberWithoutComma(percentage,this.priceRoundOff)+"%");
            rowData['narration'] = splits.join("@");
        }
    }


    ngAfterViewInit() {
        this.calculateHeight();
    }
    ngOnChanges(changes: SimpleChanges): void {
        this.init();
        if (changes['data'] && changes['data'].currentValue !== null && changes['data'].currentValue !== undefined) {
            let _this = this;
            this.commonService.getLoadData('company', ['data'], [[{key: 'name', value: 'invoice_category_ind'}]]).subscribe((next) => {
                _this.cfCols[1].list = _this.masterCommonService.getListFromArray(next['invoice_category_ind'], false, false);
                this.fetchUOMObject(changes['data'].currentValue);
            })

        }
    }


    private loadInvoice(invoiceObject:{[key:string]:any}) {
        this.commonService.getJSONByURL(environment.base_url + '/api/plannedObligation/v1/getplannedobligationsbyplannedobligationid?plannedObligationId=' + this.plannedObligation.plannedObligationId +'&tenantId='+this.commonService.getFromStorage('tenantId')).subscribe(next => {
            this.plannedObligationObject = next;
        });
        this.buttonDisabled = false;
        this.uomFeteched = true;
        this.invoiceAppDate = invoiceObject['traderApprovalDate'];
        this.financeAppDate = invoiceObject['financeApprovedDate'];
        let isEditingForm = ['Refresh Invoice'].includes(this.buttonValue) || (this.buttonValue === null || this.buttonValue === undefined || this.buttonValue === '');
        this.initForm(isEditingForm);
        this.fillLineItems(invoiceObject,isEditingForm);
        this.getTotalAmount();
        this.continueLoad();
        this.getDocFormatList();
        if(invoiceObject.status === 'POSTED' || invoiceObject.status === 'EXTERNAL POSTED'){
            this.invoiceStausLabel = 'Posted';
            this.invoiceDate = invoiceObject['updatedTimestamp'];
        }else{
             this.invoiceStausLabel = 'generated';
             this.invoiceDate = invoiceObject['invoiceDate'];
        }
    }

    private initForm(isEditingForm: boolean) {
             let _this=this;
        this.invoiceForm = new FormGroup({
            counterparty: new FormControl(this.data['counterparty']),
            counterpartyAddress: new FormControl(this.data['counterpartyAddress']),
            invoiceNumber: new FormControl(this.data['invoiceNumber']),
            address: new FormControl(this.data['address']),
            deliveryTerms: new FormControl(this.data['deliveryTerms']),
            portLoading: new FormControl(this.data['portOfLoading']),
            portDischarge: new FormControl((this.data['portOfDischarging']===null || this.data['portOfDischarging']===undefined || this.data['portOfDischarging']==="")
                ?this.data['portOfLoading']:this.data['portOfDischarging'],Validators.required),
            blNumber: new FormControl(this.data['blNumber']),
            blDate: new FormControl( this.data['blDate'] !== null ?this.commonService.getLocalDateString(this.data['blDate']) : ''),
            vesselName: new FormControl(this.data['vesselName']),
            vesselId:new FormControl(this.data['vesselId']),
            traderName: new FormControl(this.data['traderName']),
            tolerance: new FormControl(this.commonService.getDualSliderValueGeneral(this.data['limitsOrTolerance'], this.data['uom'])),
            termsOfPayment: new FormControl(this.data['termsOfPayment']),
            company: new FormControl(this.data['company']),
            companyAddress: new FormControl(this.data['companyAddress']),
            externalReference: new FormControl(this.data['externalReference']),
            accountCode: new FormControl(this.data['accountCode']),
            commodity: new FormControl(this.data['commodity']),
            deliveryEndDate: new FormControl(this.commonService.getLocalDateString(this.data['deliveryEndDate'])),
            bankName: new FormControl(),
            bankAddress: new FormControl(this.data['bankAddress']),
            beneficiaryAccNo: new FormControl(this.data['accountNumber']),
            swiftCode: new FormControl(this.data['swiftCode']),
            origin: new FormControl(this.data['origin']),
            cropYear: new FormControl(),
            invoiceGeneratedBy: new FormControl(this.data['invoiceGeneratedBy']),
            financeApprovedBy: new FormControl(this.data['updatedBy']),
            paymentRefNumber: new FormControl(this.data['paymentRefNumber']),
            paymentDate: new FormControl( this.data['paymentDate']==null?new Date(): this.commonService.getLocalDateString(this.data['paymentDate'])),
            paymentAmount: new FormControl(_this.commonService.getFormattedNumberWithoutComma(this.totalAmount,this.priceRoundOff)),
            paymentConfirmedBy: new FormControl(),
            counterpartyRefNumber: new FormControl(this.data['counterpartyRefNumber']),
            purpose: new FormControl(this.data['purpose']),
            invoiceDate: new FormControl((this.data['invoiceDate']==null || this.data['invoiceDate'].toString().length == 0)?this.commonService.getLocalDateString(new Date()):this.commonService.getLocalDateString(this.data['invoiceDate']),[Validators.required]),
            paymentDueDate: new FormControl(this.commonService.getLocalDateString(this.data['paymentDueDate'])),
            postingDate: new FormControl(this.commonService.getLocalDateString(this.data['postingDate'])),
            remitTo: new FormControl(this.getDefaultRemitTo()),
            contractNo: new FormControl(this.data['contractNumber']),
            quantity: new FormControl(this.getQty(this.commonService.getFormattedNumberWithoutComma(this.data['quantity'],this.quantityRoundOff))),
            unitPrice: new FormControl(this.getUnitPrice(this.commonService.getFormattedNumberWithoutComma(this.data['unitPrice'],this.priceRoundOff))),
            invoiceCurrency: new FormControl(this.data['currency']),
            notifyPartyName: new FormControl(this.data['counterparty']),
            notifyPartyAddress: new FormControl(this.data['counterpartyAddress']),
            fxRate: new FormControl(this.data['fxRate']),
            invoiceApprovedBy: new FormControl(this.data['invoiceApprovedBy']),
        });
        this.invoiceForm.controls['fxRate'];
        this.financialAmountType = this.data['financialAmountType'];
        this.invoiceCurrency = this.data['currency'];
        this.invoiceForm.controls['postingDate'].disable();
        this.invoiceForm.controls['vesselId'].disable();
        this.invoiceForm.controls['vesselName'].disable();
        if(this.readOnly && this.approved) {
            this.invoiceForm.disable();
            if(this.buttonValue === 'Update') {
                this.invoiceForm.controls['paymentDueDate'].enable();
                this.invoiceForm.controls['counterpartyRefNumber'].enable();
                if(this.data['postingDate'] == null) {
                    this.invoiceForm.controls['postingDate'].setValue(moment().toDate());
                }
            }
            if (this.buttonValue === 'Post Invoice' ){
                this.invoiceForm.controls['postingDate'].enable();
                this.invoiceForm.controls['counterpartyRefNumber'].enable();
                this.invoiceForm.controls['postingDate'].setValue(moment().toDate());
            }
            if (this.buttonValue === 'Unpost' ){
                this.invoiceForm.controls['postingDate'].enable();
                var postingDate = this.invoiceForm.controls['postingDate'].value;
                if(postingDate !== null && postingDate !== undefined && postingDate.toString().length !== 0) {
                    let month = this.commonService.moment().month();
                    let postingMonth = this.commonService.moment(postingDate).month();
                    if(postingMonth !== month) {
                        this.invoiceForm.controls['postingDate'].setValue(new Date());
                    }
                }
            }
        }
        this.invoiceForm.controls['paymentRefNumber'].enable();
        this.invoiceForm.controls['paymentDate'].enable();
        if(!this.data['invoiceType'].toLowerCase().includes("pro forma")) {
            this.invoiceForm.controls['paymentDueDate'].setValidators([Validators.required]);
        }
        this.invoiceForm.controls['invoiceDate'].valueChanges.subscribe((next) => {
            this.onInvoiceDateChange(next);
        })
        this.continueValidationSetup(isEditingForm);
    }

    generateLineItems() {
        let invoiceItemsLines = [];
        let _this = this;
        this.lineItems.forEach(function(lineItem) {
            let object = {
                itemNumber: lineItem['itemNumber'],
                tradePrice: _this.commonService.getFormattedNumberWithoutComma(lineItem['tradePrice'],_this.priceRoundOff),
                plannedObligationId: lineItem['plannedObligationId'],
                cashflowId: lineItem['cashflowId'],
                cashflowIds: lineItem['cashflowIds'],
                tradeId: lineItem['tradeId'],
                cashflowType: lineItem['type'],
                costValue: lineItem['costValue'],
                currency: _this.getCurrency(),
                itemCategory: lineItem['itemCategory'],
                deliveryTerm: lineItem['deliveryTerm'],
                commodity: lineItem['commodity'],
                quantity: lineItem['quantity'],
                uom: lineItem['uom'],
                priceCurrencyUom: lineItem['priceCurrencyUom'],
                splitNumber: lineItem['splitNumber'],
                shipDocNumber: lineItem['shipDocNumber'],
                glCode: lineItem['glCode'],
                advanceInPercent: lineItem['advanceInPercent'],
                invoiceNumber:(lineItem['advancePayment'] !== null && lineItem['advancePayment'] !== undefined)?lineItem['invoiceNumber']:'',
                uuid:(lineItem['advancePayment'] !== null && lineItem['advancePayment'] !== undefined)?lineItem['uuid']:'',
                advancePayment:(lineItem['advancePayment'] !== null && lineItem['advancePayment'] !== undefined)?lineItem['advancePayment']:false,
                paidInvoice:(lineItem['paidInvoice'] !== null && lineItem['paidInvoice'] !== undefined)?lineItem['paidInvoice']:false,
                qualitySpecs:(lineItem['qualitySpecs'] !== null && lineItem['qualitySpecs'] !== undefined)?lineItem['qualitySpecs']:false,
                settlementAmount: _this.commonService.getFormattedNumberWithoutComma(lineItem['settlementValue'],_this.priceRoundOff),
                narration: (lineItem['narration'] === undefined || lineItem['narration'] === null)? _this.getDescription(lineItem): lineItem['narration'],
            };
            invoiceItemsLines.push(object);
        });
        if (this.adjustmentRow && this.adjustAmount > 0) {
            let object = {
                quantity: 0,
                tradePrice: 0,
                plannedObligationId: '',
                cashflowId: 'ADJUSTMENT_AMOUNT',
                cashflowType: '',
                costValue: 0,
                currency: this.getCurrency(),
                settlementAmount: _this.commonService.getFormattedNumberWithoutComma(this.adjustAmount, this.priceRoundOff),
                narration: this.adjustmentAmountDescription
            }
            invoiceItemsLines.push(object);
        }
        return invoiceItemsLines;
    }

    private getUomConversionRate() {
        let conversionRate = 1;
        if(this.conversionObject !== null && this.conversionObject !== undefined) {
            conversionRate = this.conversionObject['conversionFactor']
        }
        return conversionRate;
    }

    private getSettlementAmount(item: any, useFxRate: boolean) {
        return this.commonService.getFormattedNumberWithoutComma((parseFloat(item['settlementAmount'])),this.priceRoundOff)
    }

    private fetchUOMObject(data:any) {
        if(data !== null && data !== undefined ) {
            if(Array.isArray(data) && data.length === 0) {
                return;
            }
            let fromUom = data['uom'];
            let toUom = data['tradePriceUom'];
            let payload = {
                "fromUom":fromUom,
                "toUom": toUom,
                "commodity": data['commodity'],
                "tenantId": this.commonService.getTenantId()
            }
            let _this = this;
            this.commonService.post(environment.base_url_new + '/api-bm/api/unitofmeasurement/v1/convertUom',payload).subscribe((next) => {
                _this.conversionObject = next;
                _this.loadInvoice(data);
            },(error) => {
                _this.loadInvoice(data);
            });
        }
    }

    private fillLineItems(invoiceObject:any,useFxRate:boolean) {
        let _this = this;
        this.lineItems = [];
        invoiceObject['invoiceLineItemList'].forEach(function (item: any,index) {
            let itemObject = {
                itemNumber: (index + 1),
                uuid: item['uuid'],
                cashflowId: item['cashflowId'],
                cashflowIds: item['cashflowIds'],
                plannedObligationId: item['plannedObligationId'],
                tradeId: item['tradeId'],
                type: item['cashflowType'],
                narration: item['narration'],
                tradePrice: _this.commonService.getFormattedNumberWithoutComma(item['tradePrice'],_this.priceRoundOff),
                lineType: item['lineType'],
                priceType: item['priceType'],
                settlementValue: _this.getSettlementAmount(item,useFxRate),
                originalSettlementValue: item['settlementAmount'],
                costValue: item['costValue'],
                itemCategory: item['itemCategory'],
                originalItemCategory: item['itemCategory'],
                deliveryTerm: item['deliveryTerm'],
                commodity: item['commodity'],
                quantity: item['quantity'],
                uom: item['uom'],
                priceCurrencyUom: item['priceCurrencyUom'],
                currency: item['tradePriceCurrency'],
                glCode: item['glCode'],
                splitNumber: item['splitNumber'],
                originalGlCode: item['glCode'],
                shipDocNumber: item['shipDocNumber'],
                advanceInPercent: item['advanceInPercent'],
                invoiceNumber: item['invoiceNumber'],
                financialAmountType: item['financialAmountType']
            };
            itemObject['advancePayment'] = false;
            itemObject['paidInvoice'] = false;
            itemObject['premiumdiscount'] = false;
            itemObject['qualitySpecs'] = false;
            if(item['advancePayment'] !== null && item['advancePayment'] !== undefined && item['advancePayment']) {
                itemObject['advancePayment'] = true;
            }
            if(item['paidInvoice'] !== null && item['paidInvoice'] !== undefined && item['paidInvoice']) {
                itemObject['paidInvoice'] = true;
            }
            if(item['premiumdiscount'] !== null && item['premiumdiscount'] !== undefined && item['premiumdiscount']) {
                itemObject['premiumdiscount'] = true;
            }
            if(item['qualitySpecs'] !== null && item['qualitySpecs'] !== undefined && item['qualitySpecs']) {
                itemObject['qualitySpecs'] = true;
                itemObject['removable'] = true;
            }
            if(item['deletable'] !== null && item['deletable'] !== undefined && item['deletable']) {
                itemObject['removable'] = true;
            }
            _this.lineItems.push(itemObject);
        })
        this.setTotalAmount(this.data['settlementAmount']);
        this.updateTotal();
    }
    getTotalAmount(updateBankDetails:boolean = true) {
        let _this=this;
        let amount = 0;
        this.lineItems.forEach((line) => {
            amount += Number(this.commonService.getFormattedNumberWithoutComma(line['settlementValue'], this.priceRoundOff));
        });
        this.setTotalAmount(amount);
        if(this.adjustAmount === null || this.adjustAmount === undefined) {
            this.adjustAmount = 0;
        }
        this.totalTemp = parseFloat(this.adjustAmount);

        this.setTotalAmount(this.totalAmount + this.totalTemp);
        this.invoiceForm.patchValue({paymentAmount:_this.commonService.getFormattedNumberWithoutComma(this.totalAmount,this.priceRoundOff)});
        this.updateTotalInWords();
        if(updateBankDetails) {
            this.updateBankDetails();
        }
        let invoiceType = this.data['invoiceType'];
        let fullInvoiceType = this.data['fullInvoiceType'];
        if(['credit note','debit note'].includes(invoiceType.toLowerCase()) && fullInvoiceType.toLowerCase().includes("staggered")) {
            this.reCalculateInvoiceType();
        }
    }

    private setTotalAmount(amount:number){
        let invoiceType = this.data['invoiceType'];
        if(['sell','purchase','purchase provisional','sell provisional'].includes(invoiceType.toLowerCase())) {
            this.totalAmount = amount;
        } else {
            this.totalAmount=Math.abs(amount);
        }
    }

    private getFxRate() {
        let fxRate = parseFloat(this.invoiceForm.value['fxRate']);
        if(fxRate === null || isNaN(fxRate)) {
            fxRate = parseFloat(this.data['fxRate']);
        }
        return fxRate;
    }
    private continueLoad() {
        let _this = this;
        let hierarchyData: any;
        let hierarchyDataResult: any;
        this.commonService.getLoadData('commodity', ['config', 'config', 'config', 'config'], ['counterparty','commodity', 'company', 'paymentterm']).subscribe((next: any) => {
            this.commonService.post(environment.base_url + '/treeStructure/parentHierrarchy', this.getHierarchyObjects(this.data['company'])).subscribe(next => {
                hierarchyData = [next];
                hierarchyDataResult=hierarchyData.filter( item => {
                    this.iterateObject(item);
                });
                _this.companyList = [];
                _this.companyListnext =  _this.commonService.createListFromObject(_this.companyListnext, 'name', 'name', true);
                _this.companyList  = [..._this.companyList, ..._this.companyListnext];
            });
            _this.paymentTermList= _this.masterCommonService.getListFromArray(next['paymentterm'], false, true);
            _this.commodityList = _this.masterCommonService.getListFromArray(next['commodity'], false, true);
        });
        this.commonService.getJSONByURL( environment.base_url_new + '/api-bm/api/location/v1/getallbylocationtype?tenantId=' + this.commonService.getFromStorage('tenantId') + '&locationType=Port').subscribe((next:any[]) => {
            let list:any[] =[];
            list.push(new KeyValue());
            next.forEach(function (obj) {
                list.push(new KeyValue(obj['name'],obj['name']));
            });
            this.loadPortList = list;
            this.unloadPortList = list;
        });
    }
    private updateBankDetails(frmRemitTo:boolean = false) {
        let _this = this;
        if (!this.isUpdatingBankDetails) {
            this.isUpdatingBankDetails = true;
            if (this.data['financialAmountType']!== null && this.data['financialAmountType']!== undefined ) {
                if (this.data['financialAmountType'] === 'payable') {
                    // Setting counterparty bank details
                    if (this.data['counterparty'] !== undefined && this.data['counterparty'] !== null) {
                        this.commonService.getJSONByURL(environment.base_url_new + '/api-bm/api/counterpartybankaccount/v1/getallbyname?name=' + (frmRemitTo ? this.invoiceForm.controls['remitTo'].value : this.data['counterparty']) + '&tenantId=' + this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
                            _this.allBanks = next;
                            _this.banksDropdown = _this.getBankList(next);
                            _this.updateBankInfos(frmRemitTo);
                            _this.isUpdatingBankDetails = false;
                        },(error:HttpErrorResponse) => {
                            _this.isUpdatingBankDetails = false;
                        });
                    }
                } else {
                    // setting company bank details
                    if (this.data['company'] !== undefined && this.data['company'] !== null) {
                        this.commonService.getJSONByURL(environment.base_url_new + '/api-bm/api/companybankaccount/v1/getallbyname?name=' + (frmRemitTo ? this.invoiceForm.controls['remitTo'].value : this.data['company']) + '&tenantId=' + this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
                            _this.allBanks = next;
                            _this.banksDropdown = _this.getBankList(next);
                            _this.updateBankInfos(frmRemitTo);
                            _this.isUpdatingBankDetails = false;
                        },(error:HttpErrorResponse) => {
                            _this.isUpdatingBankDetails = false;
                        });
                    }
                }
            }
        }
    }

    getBankList(bankRecords:any){
        let list = [];
        bankRecords.forEach(function (bankObj) {
            list.push(new KeyValue(bankObj['bankName'] + ' (A/C: '+ bankObj['accountNumber']+')',bankObj['accountNumber']));
        });
        return list;
    }

    updateTotal() {
        let _this=this;
        if (this.adjustAmount === 0 || this.adjustAmount.toString() === '') {
            this.adjustmentRow = false;
        }
        this.setTotalAmount(this.totalAmount - this.totalTemp);
        this.totalTemp = parseFloat(_this.commonService.getFormattedNumberWithoutComma(this.adjustAmount, this.priceRoundOff));
        this.setTotalAmount(this.totalAmount + parseFloat(_this.commonService.getFormattedNumberWithoutComma(this.adjustAmount, this.priceRoundOff)));
        this.invoiceForm.controls['paymentAmount'].setValue(_this.commonService.getFormattedNumberWithoutComma(this.totalAmount,this.priceRoundOff));
    }

    checkForPlannedStatus() {
        let _this = this;
        this.working = true;
        if(this.data['invoiceType'].toLowerCase().includes("advance") || this.data['invoiceType'].toLowerCase().includes("pro forma") || this.plannedObligation.plannedObligationId === null || this.plannedObligation.plannedObligationId === undefined ||this.plannedObligation.plannedObligationId.length === 0) {
            _this.sendForApproval();
        } else {
            this.commonService.getJSONByURL(environment.base_url + '/api/plannedObligation/v1/getplannedobligationsbyplannedobligationid?plannedObligationId=' + this.plannedObligation.plannedObligationId +'&tenantId='+this.commonService.getFromStorage('tenantId')).subscribe(next => {
                if (next['obligationState']['FX_ALLOCATED']) {
                    _this.confirmationService.confirm({
                        message: 'FX is allocated, Continue to deallocate Fx and generate invoice',
                        accept: () => {
                            _this.autoGenerateApplicable(next,'createinvoiceAndDeallocateFx');
                        }, reject: () => {
                            _this.onFormCancel();
                        }
                    });
                } else {
                    if(this.generateGroupInvoice){
                        _this.autoGenerateApplicable(next,'createGroupInvoice');
                    }else{
                        _this.autoGenerateApplicable(next);
                    }

                }
            },(error) => {
                _this.working = false;
                _this.showToast(_this.commonService.getHttpErrorMessage(error,"Invoice"),"error")
            });
        }

    }

    private getPayload(settlementAmount,invoiceType) {
        let postingDate = moment(this.invoiceForm.controls['postingDate'].value).toDate();
        return {
            uuid:this.data['uuid'],
            settlementAmount: settlementAmount,
            invoiceNumber: this.data['invoiceNumber'],
            invoiceLineItemList: this.generateLineItems(),
            total: this.totalAmount,
            totalInWords: this.totalInWords,
            finalInvoiceType: this.data['finalInvoiceType'],
            counterpartyType: this.data['counterpartyType'],
            fullInvoiceType: this.data['fullInvoiceType'],
            invoiceDate: this.getDateFieldValue('invoiceDate'),
            tradeId: this.data['tradeId'],
            paymentDueDate: this.commonService.getISOString(this.invoiceForm.controls['paymentDueDate'].value),
            counterpartyRefNumber: this.getValueForPayload('counterpartyRefNumber'),
            counterparty: this.data['counterparty'],
            contractNumber: this.data['contractNumber'],
            commodity: this.data['commodity'],
            termsOfPayment: this.invoiceForm.controls['termsOfPayment'].value,
            company: this.data['company'],
            counterpartyAddress: this.data['counterpartyAddress'],
            companyAddress: this.data['companyAddress'],
            externalReference: this.invoiceForm.controls['externalReference'].value,
            accountCode: this.invoiceForm.controls['accountCode'].value,
            deliveryEndDate: this.getDateFieldValue('deliveryEndDate'),
            postingDate: this.commonService.getISOString(postingDate),
            portOfLoading: this.invoiceForm.controls['portLoading'].value,
            portOfDischarging: this.invoiceForm.controls['portDischarge'].value,
            blNumber: this.invoiceForm.controls['blNumber'].value,
            blDate: this.getDateFieldValue('blDate'),
            vesselName:this.invoiceForm.controls['vesselName'].value,
            vesselId:this.invoiceForm.controls['vesselId'].value,
            quantity: this.data['quantity'],
            unitPrice: this.data['unitPrice'],
            limitsOrTolerance: this.data['limitsOrTolerance'],
            bankAddress: this.invoiceForm.controls['bankAddress'].value,
            bankName: this.invoiceForm.controls['bankName'].value,
            accountNumber: this.invoiceForm.controls['beneficiaryAccNo'].value,
            swiftCode: this.invoiceForm.controls['swiftCode'].value,
            remitTo: this.invoiceForm.controls['remitTo'].value,
            currency: this.invoiceForm.controls['invoiceCurrency'].value,
            paymentRefNumber: this.invoiceForm.controls['paymentRefNumber'].value,
            paymentDate: this.commonService.getISOString(this.invoiceForm.controls['paymentDate'].value),
            paymentAmount: this.commonService.getFormattedNumberWithoutComma(this.getValueForPayload('paymentAmount'),this.priceRoundOff),
            purpose: this.getValueForPayload('purpose'),
            paymentConfirmedBy: this.paymentConfirmedBy,
            traderName: this.invoiceForm.controls['traderName'].value,
            invoiceGeneratedBy: this.getUserName(),
            attachmentName: this.fileName,
            invoiceDocument: this.fileToUpload,
            uom: this.data['uom'],
            fxRate: this.invoiceForm.controls['fxRate'].value,
            notifyPartyName: this.invoiceForm.controls['notifyPartyName'].value,
            notifyPartyAddress: this.invoiceForm.controls['notifyPartyAddress'].value,
            deliveryTerms: this.data['deliveryTerms'],
            adjustmentAmount: this.getAdjustmentAmount(),
            invoiceType: invoiceType,
            incoterm: this.data['incoterm'],
            splitNumber: this.data['splitNumber'],
            tradePriceUom: this.data['tradePriceUom'],
            financialAmountType: this.financialAmountType,
            plannedObligationId: this.readOnly?this.data['plannedObligationId']:this.plannedObligation.plannedObligationId
        };
    }
    getAdjustmentAmount() {
        return this.readOnly ? this.commonService.getFormattedNumberWithoutComma(this.data['adjustmentAmount'], 2) : parseInt(this.commonService.getFormattedNumberWithoutComma(this.adjustAmount, this.priceRoundOff));
    }
    private updateBankInfos(fromRemitTo:boolean) {
        if(!fromRemitTo) {
            this.getRemitList();
        }
        if(this.allBanks !== null && this.allBanks !== undefined && this.allBanks.length > 0) {
            if (this.data['accountNumber']) {
                const selectedAccNum = this.data['accountNumber'];
                const bankAccNumKeyVal = this.banksDropdown.find(e => e.value === selectedAccNum);
                if (bankAccNumKeyVal) {
                    this.invoiceForm.controls['bankName'].setValue(bankAccNumKeyVal['value']);
                    this.throwChanges(bankAccNumKeyVal['value']);
                } else {
                    this.invoiceForm.controls['bankName'].setValue(this.banksDropdown[0]['value']);
                    this.throwChanges(this.banksDropdown[0]['value']);
                }
            } else {
                this.invoiceForm.controls['bankName'].setValue(this.banksDropdown[0]['value']);
                this.throwChanges(this.banksDropdown[0]['value']);
            }
        }
    }
    throwChanges(val) {
        let selectedBank = this.allBanks.filter(bankItem => bankItem.accountNumber === val);
        this.bankStatus = selectedBank[0]['status'];
        this.invoiceForm.controls['bankAddress'].setValue(selectedBank[0]['bankAddress']);
        this.invoiceForm.controls['beneficiaryAccNo'].setValue(selectedBank[0]['accountNumber']);
        this.invoiceForm.controls['swiftCode'].setValue(selectedBank[0]['swiftCode']);
    }
    private getUserName() {
        if (this.readOnly) {
            return this.data['invoiceGeneratedBy'];
        } else {
            return this.commonService.getFromStorage('userName');
        }
    }
    private getfinanceApproverName() {
            return this.commonService.getFromStorage('userName');
    }

    checkEmpty(value: any) {
        if (value === '') {
            this.adjustAmount = 0;
        }
    }

    addAdjustmentClick() {
        this.adjustmentRow = !this.adjustmentRow;
        if (!this.adjustmentRow) {
            this.setTotalAmount(this.totalAmount - this.totalTemp);
            this.adjustAmount = 0;
            this.totalTemp = 0;
            this.updateTotalInWords();
        }
    }

    onFileSelect(files: any[]) {
        let _this = this;
        let reader = new FileReader();
        if (files !== undefined && files.length > 0) {
            files.forEach(function(file) {
                reader.readAsDataURL(file);
                reader.onload = () => {
                    const uploadData = new FormData();
                    uploadData.append('file', file, file.name);
                    _this.commonService.getByteArray(environment.base_url + '/api/company/v1/upload/logo', uploadData).subscribe(function(data: any) {
                        _this.fileToUpload = data.response;
                        _this.fileName = file.name;
                    });
                };
            });
        }
    }


    onCloseDocViewer() {
        this.showDoc = false;
    }

    openDocViewer() {
        this.docName = this.data['attachmentName'];
        this.docUrl = environment.base_url + this.data['invoiceDocumentUrl'];
        this.showDoc = true;
    }

    throwCurrencyChanges(currency: any) {
        if (currency !== this.data['currency']) {
            this.invoiceForm.controls['fxRate'];
            this.invoiceCurrency = currency;
            this.updateTotalInWords();
        } else {
            this.fxRate = this.data['fxRate'];
            this.invoiceForm.controls['fxRate'].setValue(this.data['fxRate']);
            this.invoiceForm.controls['fxRate'];
            this.invoiceCurrency = currency;
            this.updateTotalForFxRate();
        }
    }

    getFinancialAmount(amt: any) {
        return parseFloat(amt) * this.fxRate;
    }

    onFXRateChange(fxRate: any) {
        if (fxRate != '') {
            this.fxRate = parseFloat(fxRate);
        }
    }

    updateTotalForFxRate() {
        if (this.fxRate !== 0 && !isNaN(this.fxRate) && this.fxRate !== undefined) {
            this.lineItems.forEach((line) => {
                if(!line['newLineItem']) {
                    line['originalSettlementValue'] = (parseFloat(line['quantity']) * parseFloat(line['tradePrice']) * this.getUomConversionRate());
                    line['settlementValue'] = Number(this.commonService.getFormattedNumberWithoutComma(( line['originalSettlementValue'] *  this.getFxRate()), this.priceRoundOff));
                }
            });
            this.getTotalAmount();
        }
    }

    onSettleDone(event:any) {
        this.onClickSettle.emit('Inv398');
    }

    getDateFormat(dateToConvert) {
        let year, month, date, dt;
        date = new Date(dateToConvert.toDateString());
        year = date.getFullYear();
        month = date.getMonth() + 1;
        dt = date.getDate();

        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }
        return year + '-' + month + '-' + dt;
    }

    getRemitList() {
        if (this.financialAmountType === 'receivable') {
            let companyList = [{label: this.data['company'], value: this.data['company']}];
            this.commonService.getJSONByURL(environment.base_url + '/api/company/v1/getsibling?name=' + this.data['company']+"&tenantId="+this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
                let parentList = this.commonService.getListFromArray(next, 'parent', 'parent', false, false);
                this.remitList = [...companyList,...parentList];
            });
        } else {
            this.remitList = [{label: this.data['counterparty'], value: this.data['counterparty']}];
            this.invoiceForm.controls['remitTo'].setValue(this.data['counterparty']);
        }
        if(this.remitList !== null && this.remitList !== undefined && this.remitList.length > 0 && !this.readOnly) {
            this.invoiceForm.controls['remitTo'].setValue(this.remitList[0]['value']);
        }
    }

    throwRemitToChanges() {
        this.updateBankDetails(true);
    }

    getValueForPayload(fieldName: string) {
        if (this.readOnly && !this.approved) {
            return this.data[fieldName];
        } else {
            return this.invoiceForm.controls[fieldName].value;
        }
    }

    checkRole(role) {
        return this.accessService.canAccess('/invoices','Action',role);
    }

    getDateFieldValue(dateField) {
        if (this.readOnly && !this.approved) {
            return this.commonService.getISOString(this.data[dateField]);
        } else {
            return this.commonService.getISOString(this.invoiceForm.controls[dateField].value);
        }
    }

    getQty(qty: any) {
        return this.commonService.getFormattedNumberWithoutComma(qty,this.quantityRoundOff) + ' ' + this.data['uom'];
    }

    getUnitPrice(price: any) {
        return this.commonService.getFormattedNumberWithoutComma(price,this.priceRoundOff) + ' ' + this.data['tradePriceCurrency'] + '/' + this.data['tradePriceUom'];
    }

    getDefaultRemitTo() {
        let remitTo =  this.sell ? this.data['company'] : this.data['counterparty'];
        return remitTo;
    }

    approvalDisable(role: string) {
        if(this.buttonDisabled) {
            return true;
        }
        if (this.data && this.data.length > 0) {
            if (role === 'trader') {
                return this.data['traderApprovalDate'] !== null;
            }
            if (role === 'finance') {
                return this.data['financeApprovedDate'] !== null;
            }
        }
    }


    formatNumber(num:any,roundOff) {
        return this.commonService.formatNumber(num,'','left','en-US', roundOff)
    }



    /**
     *
     * @param rowData
     * @param col
     * @param ri
     */
    getColValue(rowData, col: any,ri:number) {
        let val = '';
        if(rowData['itemCategory'].toLowerCase() === 'text' && !['itemNumber','itemCategory','narration'].includes(col['field'])) {
            return '';
        }
        if(this.approved || this.readOnly) {
            if(rowData.quantity){
                rowData.quantity=this.commonService.getFormattedNumberWithoutComma(rowData.quantity, this.quantityRoundOff)
            }
            if(col.field == 'settlementValue'){
                rowData.settlementValue=this.commonService.getFormattedNumberWithoutComma(rowData.settlementValue, this.priceRoundOff)
            }
        }
        if(col['viewFunc'] !== null && col['viewFunc'] !== undefined) {
            val= col['viewFunc'](rowData,ri);
        } else {
            if(col.field === 'tradePrice' && (rowData['tradePrice'] === null  || rowData['tradePrice'] === undefined || parseFloat(rowData['tradePrice']) === 0)) {
                return '';
            } else if(col.field === 'advanceInPercent' && (rowData['advanceInPercent'] === null  || rowData['advanceInPercent'] === undefined || parseFloat(rowData['advanceInPercent']) === 0)) {
                return '';
            }
            val = rowData[col.field];
            if('tradePrice' === col.field) {
                val = this.formatNumber(val,this.priceRoundOff);
            }
            if('quantity' === col.field && parseFloat(val) !== 0) {
                val = this.formatNumber(val,this.quantityRoundOff);
            }
        }
        if(val === null || val === undefined) {
            return '';
        }
        if(col.field === "quantity" && parseFloat(val) === 0) {
            return '';
        }
        return val;
    }

    getDescription(rowData) {
        let desc = '';
        let _this = this;
        let commodityFields: any[] = ['origin', 'brand', 'grade', 'season'];
        if (rowData['type'] === 'cost') {
            desc = this.commonService.applyTitleCaseFormatting(rowData['costName']) + ' Cost';
        } else {
            desc = rowData['commodity'];
            commodityFields.forEach(function(field) {
                if (rowData[field] !== undefined && rowData[field] !== null && rowData[field].length > 0) {
                    desc += '-' + _this.commonService.applyTitleCaseFormatting(rowData[field]);
                }
            });

        }
        return desc;
    }

    preview() {
        let _this = this;
        let settlementAmount = this.totalAmount;
        if (this.adjustAmount !== undefined && this.adjustAmount !== null && this.adjustAmount !== 0) {
            settlementAmount = settlementAmount - this.adjustAmount;
        }
        let payload = this.getPayload(settlementAmount,this.data['invoiceType']);
        this.viewerState = true;
        let previewDocUrl = environment.base_url + '/api/invoice/v1/generatepreviewdocument?tenantId=' + this.commonService.getFromStorage('tenantId') + "&outputFormat=pdf";
        if (this.generateGroupInvoice) {
            previewDocUrl += '&type=GROUP'
        }
        this.commonService.post(previewDocUrl, payload).subscribe((next: any) => {
            let status = next['status'];
            if(status.toLowerCase().includes("no template found")) {
                _this.previewBytes = status;
            } else {
                _this.previewBytes = next['response'];
            }

        },(error:HttpErrorResponse) => {
            _this.errorMessage = _this.commonService.getHttpErrorMessage(error,"Invoice");
        });
    }

    onViewerClose() {
        this.previewBytes = null;
        this.viewerState = false;
    }

    changeNarrationValue(rowData: any, col: any, value: Event) {
        rowData['narration'] = value['target']['value'];
    }





    onSelectCompany(value:any[]) {
        this.commonService.getJSONByURL(environment.base_url +
            '/api/companyaddressdetails/v1/getcompanyaddressdetailbycompanyanddefaultvalue?company=' + value
            + '&addressDefault=true' + '&tenantId=' + this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
            this.invoiceForm.controls['companyAddress'].setValue(next[0]['address']);
        }, () => {
            this.messageService.add({severity: 'error', summary: 'Default address does not exist for selected ' + value});
            this.invoiceForm.controls['companyAddress'].setValue('');
        });
    }

    //JSON payload to get HierarchyObjects
    public getHierarchyObjects(code) {
        return {
            "criteria": [
                {
                    "key": "parent",
                    "operation": "equal",
                    "value": code
                },
                {
                    "key": "tenantId",
                    "operation": "equal",
                    "value": this.commonService.getFromStorage('tenantId')
                }
            ],
            "entityName": 'xceler_configservice_company',
            "nameField": 'name',
            "codeField": 'name'
        };
    }

    //metthod to flatten the HierarchyObjects
     public iterateObject(obj) {
        for(let prop in obj) {
            if(typeof(obj[prop]) == "object"){
                this.iterateObject(obj[prop]);
            } else {
                if(prop == "label") {
                    this.companyListnext.push({name:obj[prop]});
                }
            }
        }
    }

    deleteLineItem(ri: any) {
        this.lineItems.splice(ri,1);
        this.getTotalAmount();
    }

    searchForPaymentTerm(value: any) {
       this.suggestionListPaymentTerm = [];
        let _this = this;
        _this.paymentTermList.forEach(function (obj:KeyValue) {
            if(obj.getValue().length > 0  && obj.getLabel().toString().includes(value['query'])) {
                _this.suggestionListPaymentTerm.push(obj.getValue());
            }
        });
    }

   searchForLoading(value: any) {
        const searchTerm = value['query'].toLowerCase(); // Convert the search term to lowercase

        this.suggestionListLoading = this.loadPortList
            .filter(obj =>
                obj.getValue().length > 0 && obj.getLabel().toString().toLowerCase().includes(searchTerm)
            )
            .map(obj => obj.getValue());
    }


    searchForUnloading(value: any) {
        const searchTerm = value['query'].toLowerCase(); // Convert the search term to lowercase
        this.suggestionListUnloading = [];
        let _this = this;

        this.suggestionListUnloading = this.unloadPortList
            .filter(obj =>
                obj.getValue().length > 0 && obj.getLabel().toString().toLowerCase().includes(searchTerm)
            )
            .map(obj => obj.getValue());
    }


    onChangeSettelemtValue(rowData: any, index: number) {
        if (rowData['advancePayment'] !== undefined && rowData['advancePayment'] !== null && rowData['advancePayment'] === true) {
            if (Math.abs(rowData.originalSettlementValue) < Math.abs(rowData.settlementValue)) {
                this.showToast('Value cannot be greater than the Advance invoice value. Resetting to original settlement value [ '+rowData.originalSettlementValue+' ].', 'error');
                rowData.settlementValue = rowData.originalSettlementValue;
                let inputEle:any = document.getElementById('row_'+index);
                inputEle.value = rowData.settlementValue;
            }
        }
    }


    private updateTotalInWords() {
        this.totalInWords = this.commonService.convertMoneyToWordsByCurrencyCode(Math.abs(this.totalAmount),this.getCurrency());
    }

    private isDataValid() {
        if(!this.invoiceForm.valid) {
            let errors:any[] = [];
            let _this = this;
            let controls:any = this.invoiceForm.controls;
            if(controls !== null && controls !== undefined) {
                Object.keys(controls).forEach(key => {
                    const controlErrors: ValidationErrors = _this.invoiceForm.controls[key].errors;
                    if (controlErrors !== null) {
                        Object.keys(controlErrors).forEach(keyError => {
                            errors.push({
                                control_name: _this.commonService.camelToTitleCase(key),
                                error_name: keyError,
                                error_value: controlErrors[keyError]
                            });
                        });
                    }
                })
                if(errors.length > 0) {
                    this.showToast("Error",this.commonService.extractErrors(errors),"error");
                    this.working = false;
                    return false;
                }
            }
        }
        return true;
    }

    private getName(value) {
        return value.replace(/([A-Z])/g, function(match) {
                return " " + match;
            }).replace(/^./, function(match) {
                return match.toUpperCase();
            });
    }

    private showAutoInvoiceConfirmation(obligation:any,docbypass:boolean = false,url: string = 'createinvoice') {
        let message = `Do you want to Generate Invoice for ${obligation['tradeTransactionType'] == 'SELL'?'Purchase':'Sell'} ?`;
        if(docbypass) {
            message = `Do you want to Generate CN/DN Invoice for this obligation ?`;
        }
        this.confirmationService.confirm({
            message: message,
            accept: () => {
                this.sendForApproval(url,true);
            }, reject: () => {
                this.sendForApproval(url);
            }
        });
    }

    private autoGenerateApplicable(obligation:any,url: string = 'createinvoice') {
        let matchType = obligation['matchType'].toLowerCase();
        let docBypass = (obligation['docByPassId'] !== null && obligation['docByPassId'] !== undefined && obligation['docByPassId'] !== '');
        if((docBypass && !['credit note','debit note'].includes(this.data['invoiceType'].toLowerCase())) || (matchType.toLowerCase().includes("washout") && matchType !== 'washout')) {
            let autoGenerateUrl = environment.base_url + "/api/invoice/v1/canautogenerateinvoice?obligationId="+ obligation['plannedObligationId']
            this.commonService.getJSONByURL(autoGenerateUrl).subscribe((next) => {
                let canGenerateAuto = next['response'];
                if(canGenerateAuto) {
                    this.showAutoInvoiceConfirmation(obligation,docBypass,url);
                } else {
                    this.sendForApproval(url);
                }
            },(error:HttpErrorResponse) => {
                this.sendForApproval(url);
            })
        } else {
           this.sendForApproval(url)
        }
    }

    private sendForApproval(url: string = 'createinvoice',autoGenerateInvoice:boolean = false) {
        if(this.bankStatus == false){
            this.working = false;
            this.messageService.add({severity:'error', summary:'CounterParty and Company Bank Details are Inactive'});
            return;
        }
        let _this = this;
        if(this.isDataValid()) {
            let actualPayload: {};
            let settlementAmount = this.totalAmount;
            if (this.adjustAmount !== undefined && this.adjustAmount !== null && this.adjustAmount !== 0) {
                settlementAmount = settlementAmount - this.adjustAmount;
            }
            actualPayload = this.getPayload(settlementAmount,this.data['invoiceType']);
            let isRefreshing = this.buttonValue === "Refresh Invoice";
            let postUrl = environment.base_url + '/api/invoice/v1/' + url+'?tenantId='+this.commonService.getFromStorage('tenantId') + "&outputFormat=" + this.documentFormat;
            if(this.data['invoiceType'].toLowerCase().includes('advance')) {
                postUrl = environment.base_url + '/api/invoice/v1/generateadvanceinvoice?tenantId=' + this.commonService.getTenantId() + "&outputFormat=" + this.documentFormat;
            } else if(this.data['invoiceType'].toLowerCase().includes('pro forma')) {
                postUrl = environment.base_url + '/api/tradeattachment/v1/generateproformainvoice?tenantId=' + this.commonService.getFromStorage('tenantId') + "&outputFormat=" + this.documentFormat;
            }
            postUrl += "&refresh="+isRefreshing;
            actualPayload['autoInvoice'] = autoGenerateInvoice;
            this.commonService.getJSONByObject(postUrl, actualPayload).subscribe((next:any) => {
                _this.onSendForApproval.emit(false);
                let statusType = next['statusType'];
                if(statusType === 'warning') {
                    _this.showToast('Warning :',next['status'],'warn');
                }
                _this.working = false;
            },error => {
                _this.working = false;
                _this.showToast("Error While Generating Invoice",this.commonService.getHttpErrorMessage(error,"Invoice"),'error');
            });
        } else {
            _this.working = false;
        }
    }

    onFormCancel() {
        this.working = false;
        this.onDraftClose.emit(false);
    }

    private showToast(heading:string,msg, severity: string = 'success') {
        this.messageService.add({
            severity: severity,
            summary: heading,
            detail:msg
        });
    }

    addNewLineItem() {
        let row:{[key:string]:any} = {};
        row['itemNumber'] = (this.lineItems.length + 1);
        row['itemCategory'] = this.lineItems[0]['itemCategory'];
        row['glCode'] = '';
        row['deliveryTerm'] = "";
        row['commodity'] = this.lineItems[0]['commodity'];
        row['quantity'] = 1;
        row['tradePrice'] = 0.0;
        row['narration'] = this.lineItems[0]['itemCategory'];
        row['uom'] = this.lineItems[0]['uom'];
        row['shipDocNumber'] = "";
        row['advanceInPercent'] = 100.0;
        row['settlementValue'] = 0.0;
        row['removable'] = true;
        row['newLineItem'] = true;
        row['paidInvoice'] = false;
        row['cashflowId'] = "ADJUSTMENT_AMOUNT";
        this.lineItems.push(row);
    }

    runFunction(col: any, rowData: any, ri: any, functionName: string, value: any,defValue:any = '') {
        if(col[functionName] !== null && col[functionName] !== undefined) {
            return col[functionName](rowData,ri);
        }
        return defValue;
    }

    getCurrency() {
        return this.invoiceCurrency;
    }

    isRemovable(col: any,rowData:any) {
        let isInFields = this.removableLineItemBooleanFields.map(i => (rowData[i] !== null && rowData[i] !== undefined)?rowData[i]:false);
        let isInTypes = false;
        let type = rowData['type'];
        if(type !== null && type !== undefined) {
            isInTypes = this.removableLineItemTypes.includes(rowData['type'].toLowerCase());
        }
        if(isInFields.includes(true) || isInTypes){
            return true;
        } else if(rowData['removable']) {
            return true;
        }
        return false;
    }

    onSubmit($event: any) {
        this.onDraftClose.emit(false);
        this.onApproved.emit('trader');
    }

    onSubmitFail(error: HttpErrorResponse) {
        this.showToast("Failed",this.commonService.getHttpErrorMessage(error,"Invoice"),'error');
    }

    onPostingSuccess(value: any) {
        let status = value['status'];
        let response:any[] = value['response'];
        if((response !== null && response !== undefined && Array.isArray(response) && response.length > 0) || status.toLowerCase().includes("error")) {
            this.showToast("Error","Errors while post : \n"+response.join("\n"),'error');
        } else {
            this.showToast("Success",(status.length != 0)?status:"Invoice Posted Successfully.");
            this.onPostingDone.emit();
            this.onFormCancel();
        }
    }
    onUnPostingSuccess(value: any) {
        let response:any[] = value['response'];
        if(response !== null && response !== undefined && Array.isArray(response) && response.length > 0) {
            this.showToast("Errors while unpost : \n"+response.join("\n"),'error');
        } else {
            this.showToast("Invoice Unposted Successfully",'success');
            this.onPostingDone.emit();
            this.onFormCancel();
        }
    }
    onErrorPostinginvoice(error: HttpErrorResponse) {
        let response = error.error['response'];
        if(response !== null && response !== undefined) {
            let errorMessage = 'Error while posting invoice : \n';
            if(response instanceof Array) {
                errorMessage = response.join('\n');
            }
            this.messageService.add({
                severity: 'error', summary: 'Error while posting invoice :',
                detail: errorMessage
            });
        } else {
            this.showToast(this.commonService.getHttpErrorMessage(error,'Invoice & Payment'),'','error');
        }
    }

    onChangePostingDate(date: Date) {
        if(date !== null && date !== undefined) {
            let todayDate = moment(new Date()).toDate();
            date = moment(date).toDate();
            if(date.getMonth() != todayDate.getMonth()) {
                this.invoiceForm.controls['postingDate'].setValue(this.commonService.getDateWithLocalTime(moment(date).endOf('month').toDate()))
            } else {
                if(date.getFullYear() != todayDate.getFullYear()) {
                    this.invoiceForm.controls['postingDate'].setValue(this.commonService.getDateWithLocalTime(moment(date).endOf('month').toDate()))
                } else {
                    this.invoiceForm.controls['postingDate'].setValue(this.commonService.getDateWithLocalTime(date))
                }
            }
        }
    }

    onFailedToGenerate(error: HttpErrorResponse) {
        this.working = false;
        this.showToast(this.commonService.getHttpErrorMessage(error,"Invoice"),"error")
    }

    onGenerateInvoiceDone() {
        this.onSendForApproval.emit(false);
        this.working = false;
    }


    getTodayDate() {
        return moment().toDate();
    }

    onInvoiceDateChange(date: Date) {
        this.paymentDueMinDate = this.commonService.getDateWithLocalTime(date);
        this.invoiceForm.controls['paymentDueDate'].setValue(this.commonService.getDateWithLocalTime(date));
    }

    getInvoiceTypeHeader(invoiceType: any) {
        let finalInvoiceType = this.data['finalInvoiceType'].toLowerCase();
        if(finalInvoiceType.includes("claim")) {
            return "Final"
        }
        return invoiceType;
    }

    private continueValidationSetup(isEditingForm: boolean) {
        let paymentDueDate = this.invoiceForm.controls['paymentDueDate'].value;
        let updatePaymentDueDate = (paymentDueDate === null || paymentDueDate === undefined || paymentDueDate.toString().length == 0)
        let blDate = this.data['blDate'];
        let extra = this.data['extraInfo'];
        if(blDate !== null && blDate !== undefined) {
            this.blDate = new Date(blDate);
            if(isEditingForm) {
                this.invoiceForm.controls['invoiceDate'].setValue(this.blDate);
            }
        } else {
            if(extra !== null && extra !== undefined) {
                let tradeConfirmationDate = extra['tradeConfirmationDate'];
                if(tradeConfirmationDate !== null && tradeConfirmationDate !== undefined && tradeConfirmationDate.length > 0) {
                    this.paymentDueMinDate = new Date(tradeConfirmationDate);
                    this.blDate = new Date(tradeConfirmationDate);
                }
            }
        }
        this.paymentDueMinDate = this.invoiceForm.controls['invoiceDate'].value;
        if(updatePaymentDueDate && isEditingForm) {
            this.invoiceForm.controls['paymentDueDate'].setValue(this.paymentDueMinDate);
        }
        if(extra !== null && extra !== undefined) {
            let matchType = extra['matchType'];
            if(matchType !== null && matchType !== undefined) {
                matchType = matchType.toLowerCase();
            }
            let approvedInvoiceDate = extra['approvedInvoiceDate'];
            let tradeConfirmationDate = extra['tradeConfirmationDate'];
            if(this.data['invoiceType'].toLowerCase().includes("credit") || this.data['invoiceType'].toLowerCase().includes("debit")) {
                if(matchType.toLowerCase().includes("string") || matchType.toLowerCase().includes("circle") || matchType.toLowerCase().includes("docbypass")) {
                    if(tradeConfirmationDate !== null && tradeConfirmationDate !== undefined && tradeConfirmationDate.length > 0) {
                        this.paymentDueMinDate = new Date(tradeConfirmationDate);
                        this.blDate = new Date(tradeConfirmationDate);
                    }
                } else {
                    if(approvedInvoiceDate !== null && approvedInvoiceDate !== undefined && approvedInvoiceDate.length > 0) {
                        this.paymentDueMinDate = new Date(approvedInvoiceDate);
                    } else {
                        this.paymentDueMinDate = new Date(tradeConfirmationDate);
                        this.blDate = new Date(tradeConfirmationDate);
                    }
                    if(updatePaymentDueDate && isEditingForm) {
                        this.invoiceForm.controls['paymentDueDate'].setValue(this.paymentDueMinDate);
                    }
                }

            }

            let establishedOn = extra['establishedOn'];
            let daysToAdd = this.getIfNotNull(extra['daysToAdd'],0);
            let applyEstablishedCriteria = this.getIfNotNull(extra['applyEstablishedCriteria'],false);
            if(applyEstablishedCriteria) {
                if(establishedOn !== null && establishedOn !== undefined && establishedOn.length > 0 && updatePaymentDueDate) {
                    this.invoiceForm.controls['paymentDueDate'].setValue(this.commonService.addDaysInDate(new Date(establishedOn),daysToAdd));
                }
            }
        }
    }

    getDocFormatList() {
        let _this = this;
        let payload = [];
        payload.push({fieldName: "tenantId", condition: "equals", value: this.commonService.getTenantId()});
        payload.push({fieldName: "businessApplication", condition: "equals", value: this.data['invoiceType'] + ' Invoice'});
        payload.push({fieldName: "company", condition: "equals", value: this.data['company']});
        payload.push({fieldName: "commodity", condition: "in", value: [this.data['commodity'],'']});
        payload.push({fieldName: "counterparty", condition: "in", value: [this.data['counterparty'],'']});
        payload.push({fieldName: "incoterm", condition: "in", value: [this.data['incoterm'],'']});
        payload.push({fieldName: "loadPort", condition: "in", value: [this.data['portOfLoading'],'']});
        payload.push({fieldName: "dischargePort", condition: "in", value: [this.data['portOfDischarging'],'']});
        payload.push({fieldName: "status", condition: "equals", value: true});
        _this.commonService.post(environment.base_url_new + '/api-bm/api/doctemplateservice/v1/getDocumentTemplateFormat?tenantId=' + this.commonService.getFromStorage('tenantId'), payload).subscribe((next: any) => {
            const DocFormat = _this.commonService.createListFromObject(next, 'allowedOutputFormats', 'allowedOutputFormats', false);
            let tempFormat = JSON.parse(DocFormat[0].value);
            _this.allowFormatEnable = tempFormat.selected.length < 2;
            this.documentFormatList = [];
            tempFormat['selected'].forEach(e => {
                this.documentFormatList.push({label: e, value: e})
            })
            this.documentFormat = tempFormat['default']
        });

    }

    private getIfNotNull(value,defaultValue:any) {
        if(value !== null && value !== undefined) {
            return value;
        }
        return defaultValue;
    }

    search(event: any) {
        this.query = event['query'];
    }

    getPostingMinDate() {
        if(this.data !== null && this.data !== undefined) {
            if (this.buttonValue === 'Unpost' && this.data['postingDate'] !== null && this.data['postingDate'] !== undefined) {
                const date1 = new Date(this.data['postingDate']);
                const date2 = new Date(this.data['invoiceDate']);
                const time1 = date1.getTime();
                const time2 = date2.getTime();
                var minDateReference =time1<time2 ?this.data['postingDate']:this.data['invoiceDate'];
                return this.commonService.moment(minDateReference).toDate();
            }
            return null;
        }
        return null;
    }

    private reCalculateInvoiceType(){
        const lines = this.lineItems;
        if(lines && lines.length > 1) {
            let payableList = lines.filter(item => item.financialAmountType ==="payable").map(item => parseFloat(item.settlementValue))
            let receivableList = lines.filter(item => item.financialAmountType !=="payable").map(item => parseFloat(item.settlementValue))
            if(payableList.length > 0 && receivableList.length > 0) {
                this.decideIfBoth(payableList,receivableList);
            } else if(receivableList.length > 0) {
                this.decideIfOne(receivableList)
            } else {
                this.decideIfOne(payableList)
            }
        }
    }

    private decideIfBoth(payableList: any[], receivableList: any[]) {
        const sumReceivable = !this.sell?receivableList.reduce((a, b) => Math.abs(a) + Math.abs(b), 0):receivableList.reduce((a, b) => a + b, 0);
        const sumPayable = !this.sell?payableList.reduce((a, b) => a + b, 0):payableList.reduce((a, b) => Math.abs(a) + Math.abs(b), 0);
        let amount = sumReceivable - sumPayable;
        let finalAmount = !this.sell?(amount * -1):amount;
        this.decideCNOrDN(finalAmount);
    }

    private decideCNOrDN(amount: number) {
        if(!this.sell) {
            if(amount < 0) {
                this.data["invoiceType"] = "Debit Note";
            } else {
                this.data["invoiceType"] = "Credit Note";
            }
        } else {
            if(amount < 0) {
                this.data["invoiceType"] = "Credit Note";
            } else {
                this.data["invoiceType"] = "Debit Note";
            }
        }
    }

    private decideIfOne(amountList: any[]) {
        const sumAmount = amountList.reduce((a, b) => a + b, 0);
        this.decideCNOrDN(sumAmount);
    }
}

