<p-dialog [(visible)]="futureInfoDisplay"
          (onHide)="onCancel()"
          [closeOnEscape]="false"
          [contentStyle]="{ overflow: 'scroll', overflowX:'hidden'}"
          [draggable]="false"
          [maximizable]="false" [modal]="true" [showHeader]="false" appendTo="body"
          [style]="{width: '40%',padding:'0px',background:'#ffffff'}">
    <div class="dialogHeading">
        <div style="display: inline-flex;width: 100%;border-bottom: 1px solid rgba(211,211,211,0.6)">
            <p class="dialogLabel">{{header}}</p>
            <i (click)="onCancel()" class="material-icons closable" style="float: right;align-self: center">clear</i>
        </div>
        <div class="sectionBoxMain">
            <div class="row rowClass">
                <div class="col-md-3 controlGap contentbox">
                    <div class="fieldBox">
                        <p class="fieldName">Trade Type</p>
                        <p class="bodyName">Exchange</p>
                    </div>
                </div>
                <div class="col-md-3 controlGap contentbox">
                    <div class="fieldBox">
                        <p class="fieldName">Exchange</p>
                        <p class="bodyName">{{value['tickerDetails'][0]['exchnname']}}</p>
                    </div>
                </div>
                <div class="col-md-3 controlGap contentbox">
                    <div class="fieldBox">
                        <p class="fieldName">Commodity</p>
                        <p class="bodyName">{{value['CommodityDetails'][0]['commodityName']}}</p>
                    </div>
                </div>
                <div class="col-md-3 controlGap contentbox">
                    <div class="fieldBox">
                        <p class="fieldName">Expiry Month</p>
                        <p class="bodyName">{{value['expiryDetails'][0]['productcode']}}</p>
                    </div>
                </div>
                <div class="col-md-3 controlGap contentbox">
                    <div class="fieldBox">
                        <p class="fieldName">Expiry Date</p>
                        <p class="bodyName">{{value['expiryDetails'][0]['expirydate'] | date:'dd MMM yyyy'}}</p>
                    </div>
                </div>
                <div class="col-md-3 controlGap contentbox">
                    <div class="fieldBox">
                        <p class="fieldName">Lot Size</p>
                        <p class="bodyName">{{value['CommodityDetails'][0]['lotSize']}}</p>
                    </div>
                </div>
            </div>
        </div>
        <p-footer>
            <div style=" border-top: 2px solid #f0e5ea;">
                <div style="float: right;">
                    <button (click)="onCancel()" class="cancelBtn">Cancel</button>
                </div>
            </div>
        </p-footer>
    </div>
</p-dialog>
