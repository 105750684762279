import {Tcolumn} from "../../tcolumn.model";

export class Filter {

  constructor(
    private header: string,
    private columnName: string,
    private condition: any = 'equalsto|notequalsto|lessthan|lessthanequals|greaterthan|greaterthanequals|between|contains|startWith|endWith|is|isNot',
    private firstValue: any = '',
    private secondValue: any = '',
    private type: string = 'T',
    private field?: Tcolumn
  ) {
    if(type === 'D') {
      if(firstValue !== undefined && firstValue !== null && firstValue.toString().length > 0) {
        this.firstValue = new Date(firstValue);
      } else {
        this.firstValue = '';
      }
    }
  };

  public getHeader() {
    return this.header;
  }

  public getColumnName() {
    return this.columnName;
  }

  public getCondition() {
    return this.condition;
  }

  public getType() {
    return this.type;
  }

  public setHeader(newValue: string) {
    this.header = newValue;
  }

  public setColumnName(newValue: string) {
    this.columnName = newValue;
  }

  public setCondition(newValue: string) {
    this.condition = newValue;
  }

  public setFirstValue(newValue: any) {
    this.firstValue = newValue;
  }

  public setSecondValue(newValue: any) {
    this.secondValue = newValue;
  }

  public getFirstValue() {
    return this.firstValue;
  }

  public getSecondValue() {
    return this.secondValue;
  }

  public getField() {
    return this.field;
  }

  public setField(field: Tcolumn) {
    this.field = field;
    this.setType(field.getColumnType());
  }

  public copy() {
    return new Filter(this.header, this.columnName, this.condition, this.firstValue, this.secondValue, this.type, this.field);
  }

  getCombineValue() {
    return {firstValue: this.firstValue, secondValue: this.secondValue};
  }

  private setType(newValue: string) {
    this.type = newValue;
  }
}
