<div class="d-flex flex-column">
    <div>
        <div class="sectionCardTop">
            <div class="breadcrumbPane">
                <ctrm-breadcrumb [collapsabel]="false"
                                 [items]="breadCrumbService.getBreadCrumb()"
                                 [showIcon]="false"></ctrm-breadcrumb>
            </div>
            <div class="actionPane">
                <div class="screenDetails">
                    <img [src]="'../../../assets/images/svg/papers.svg'"/>
                    <p>Future Market Dashboard</p>
                </div>
            </div>
        </div>
    </div>
    <div class="p-1">
        <div class="d-flex align-items-center">
            <div class="flex-grow-1">
                <p class="IndexHeading">
                    Index Widget Catalogue
                </p>
            </div>
            <div class="d-inline-flex">
                <div class="addTickerButton" (click)="onClickAddTicker()">
                    <p>Add Ticker</p>
                </div>
                <div class="importButton" (click)="onClickOfImport()">
                    <img [src]="'../../../assets/images/svg/file_import.svg'"/>
                    <p class="exporttitle" [innerHTML]="'Import Market \n Prices'"></p>
                </div>
            </div>
        </div>
    </div>
    <div class="sectionCardTop m-0 d-flex"
         style="max-height: 100% !important;width: 100%;height: 100vh;padding: 0px 5px 5px 5px;">
        <!--Left Section-->
        <div class="dashLeftDiv flex-grow-1" [ngClass]="showTickerPannel?'w-75':'w-auto'">
            <ng-container *ngTemplateOutlet="noDataBox"></ng-container>
            <div class="row w-100 m-0 style-4" style="overflow: scroll;height: 70%;">
                <future-ticker-graph *ngFor="let selectedTicker of selectedTickers"
                                     [display]="displayGraph" class="col-4 p-0 m-0"
                                     (onClose)="onCloseGraph()"
                                     [tickerValue]="selectedTicker">
                </future-ticker-graph>
            </div>
        </div>
        <!--Right Section-->
        <div class="dashRightDiv" [ngClass]="showTickerPannel?'w-25':'w-fit'">
            <div style="height:100%;" class="d-flex">
                <div *ngIf="showTickerPannel" class="tickerPanel flex-grow-1">
                    <!--            Ticker Data Section-->
                    <future-ticker-list (onSelectTicker)="onSelectTicker($event)"
                    [tickerValue]="tickerValue" [isLoading]="isLoading"></future-ticker-list>
                </div>
                <!--            Click on ticker Pannel-->
                <div class="tickerClickPanel">
                    <div>
                        <p class="listPannel"> List Pannel</p>
                    </div>
                    <div (click)="onClickTickerList()">
                        <i class="fas fa-list btnIcon"></i>
                        <p class="tickerList">Ticker List</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>


<ng-template #noDataBox>
    <div *ngIf="(selectedTickers === undefined || selectedTickers === null || selectedTickers.length === 0)" style="height: 100%">
        <div class="placeHolder d-flex">
            <div [id]="'noDataBoxId'" class="noDataBox align-self-center">
                <div style="height: 100%">
                    <i style="font-size: 25px" class="far fa-check-square"></i>
                    <p style="font-size: 20px; text-align: center;">No index added. To view index Widgets please add and
                        bookmark it in the index List</p>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ticker-dialog-box *ngIf="displayTickerDialog"
                   [display]="displayTickerDialog"
                   (onClose)="onCloseForm()">
</ticker-dialog-box>

