<p-dialog (onHide)="onFormCancel()"
          [(visible)]="dialogDisplay"
          [closeOnEscape]="false"
          [draggable]="false"
          [maximizable]="false" [modal]="true" [showHeader]="false"
          [style]="{zIndex:1008,width: '95%',height: '95%',minHeight:'95%',maxHeight:'95%', padding:'0px', background:'#ffffff'}">
    <div class="dialogHeading" >
        <div style="display: inline-flex;width: 100%;border-bottom: 1px solid rgba(211,211,211,0.6)">
            <p class="dialogLabel">Actualization</p>
            <i (click)="onFormCancel()" class="material-icons closable" style="float: right;align-self: center">clear</i>
        </div>
        <div class="transportInfo" *ngIf="vesselId !== null && vesselId !== undefined && vesselId.length > 0 && vesselPop">
            <div class="infobox">
                <p class="sectionheading">Voyage ID : </p>
                <p class="sectionValue">{{voyageNo}}</p>
            </div>
            <div class="infobox">
                <p class="sectionheading">Vessel Name : </p>
                <p class="sectionValue">{{vesselObj['vesselName']}}</p>
            </div>
            <div class="infobox">
                <p class="sectionheading">Vessel Capacity : </p>
                <p class="sectionValue">{{vesselObj['vesselCapacity']}} MT</p>
            </div>
            <div class="infobox">
                <p class="sectionheading">Vessel Status : </p>
                <p class="sectionValue">{{vesselObj['status']}}</p>
            </div>
            <div class="infobox">
                <p class="sectionheading">Purchase contract Actualized Quantity : </p>
                <p class="sectionValue">{{commonService.formatNumberWithoutComma(vesselObj['loadQuantity'],quantityRoundOff)}} MT</p>
            </div>
            <div class="infobox">
                <p class="sectionheading">Sell contract Actualized Quantity : </p>
                <p class="sectionValue">{{commonService.formatNumberWithoutComma(vesselObj['unloadQuantity'],quantityRoundOff)}} MT</p>
            </div>

        </div>
        <div class="transportInfo" *ngIf="planId !== null && planId !== undefined && planId.length > 0 && !vesselPop">
            <div class="infobox">
                <p class="sectionheading">Plan ID : </p>
                <p class="sectionValue">{{planId}}</p>
            </div>
            <div class="infobox">
                <p class="sectionheading">Buy Planned Quantity : </p>
                <p class="sectionValue">{{(this.planObj !== null && this.planObj !== undefined)?planObj['buyPlannedQuantity']:''}}</p>
            </div>
            <div class="infobox">
                <p class="sectionheading">Sell Planned Quantity : </p>
                <p class="sectionValue">{{(this.planObj !== null && this.planObj !== undefined)?planObj['sellPlannedQuantity']:''}}</p>
            </div>
            <div class="infobox">
                <p class="sectionheading">Match Type : </p>
                <p class="sectionValue">{{(this.planObj !== null && this.planObj !== undefined)?planObj['matchType']:''}}</p>
            </div>
            <div class="infobox">
                <p class="sectionheading">Status : </p>
                <p class="sectionValue">{{(this.planObj !== null && this.planObj !== undefined)?planObj['status']:''}}</p>
            </div>
        </div>
    </div>
    <div *ngIf="loading">
        <div class="fetchingDataOnForm">
            <i class="fas fa-spinner fa-pulse fa-2x animated" style="color: rgba(0,0,0,0.51);"></i>
            <p style="font-size: 30px;font-weight: bold;text-align: center;color: rgba(212,212,212,0.58);margin-top: 15px;padding: 0">
                Please wait</p>
        </div>
    </div>
    <div *ngIf="openFilterForm" class="filter-screen" >
        <div style="position:fixed;z-index:-1;left:0;top:0;width: 100%;height: 100%;background: transparent" (click)="openFilterForm = false"></div>
        <div>
            <div class="filter-buttons">
                <button class="reset-button" (click)="resetFormFilter()" >Reset</button>
                <button class="apply-button" (click)="onApplyFilter()" >Apply</button>
            </div>
            <form [formGroup]="actualizeFilterForm.getFormGroup()" >
                <div style="display: flex; flex-direction: column;gap: 1rem">
                    <div>
                        <p-multiSelect [options]="filterDataTradeId"  formControlName="tradeId"   [defaultLabel]="'Trade Id'" ></p-multiSelect>
                    </div>
                    <div>
                        <input [bsConfig]="bsConfig" formControlName="date"autocomplete="off" bsDatepicker class="form-control form-control-normal" placeholder="Select Date" type="text">
                    </div>
                    <div>
                        <p-multiSelect  [options]="filterDataLoadPort"  formControlName="loadPort"  [defaultLabel]="'Load Port'" ></p-multiSelect>
                    </div>
                    <div>
                        <p-multiSelect [options]="filterDataUnloadPort"  formControlName="unloadPort"  [defaultLabel]="'Unload Port'" ></p-multiSelect>
                    </div>
                    <div>
                        <p-multiSelect  [options]="filterDataCommodity" formControlName="commodities"  [defaultLabel]="'Commodity'" ></p-multiSelect>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div *ngIf="!loading" class="content" >
        <div class="leftbox">
            <div class="leftcard">
                <div class="topSection">
                    <ctrm-button (onClick)="refreshLibrary()" [icon]="refreshCardLibrary?'fas fa-redo fa-spin':'fas fa-redo'" [label]="'Refresh'" style="margin-left: 5px"></ctrm-button>
                    <div class="filter-container"(click)="openFilterForm = !openFilterForm">
                        <svg id = "buttonFilter" width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.6276 10.6667H10.0443V8.88889H6.6276V10.6667ZM0.648438 0V1.77778H16.0234V0H0.648438ZM3.21094 6.22222H13.4609V4.44444H3.21094V6.22222Z" fill="#FF8200"/>
                        </svg>
                    </div>
                </div>
                <div class="contentSection">
                    <div class="checbox">
                        <p-checkbox (click)="onSelectionChangeAll(allselected)"
                                    [value]="allselected"
                                    [ngModel]="allselected" [binary]="true" [label]="'Select All Planned Obligations'"></p-checkbox>

                        <p-checkbox (ngModelChange)="onCheckboxChange($event,sellValue)" style="margin-left: 10px" [styleClass]="'buycheckbox'" [labelStyleClass]="'buycheckboxlabel'"
                                    [value]="buyValue" [(ngModel)]="buyValue"
                                    [binary]="true" [label]="'Buy'" >

                        </p-checkbox>

                        <p-checkbox (ngModelChange)="onCheckboxChange(buyValue,$event)" style="margin-left: 10px" [styleClass]="'sellcheckbox'" [labelStyleClass]="'sellcheckboxlabel'"
                                    [value]="sellValue" [(ngModel)]="sellValue"
                                    [binary]="true" [label]="'Sell'">

                        </p-checkbox>
                    </div>
                    <div class="list style-4" id="list" inViewport (inViewportAction)="calcaulateListHeight()">
                        <planned-card *ngFor="let plannedobligation of plannedObligations"
                                      [selected]="selectedPlannedObligations.includes(plannedobligation)"
                                      [search]="searchValue"
                                      [hidden]="hiddenCard.includes(plannedobligation['plannedObligationId'])"
                                      [searchFields]="['tradeTransactionType','commodity','counterparty','plannedObligationId','loadPort','unloadPort']"
                                      (onNotFounInSearch)="onNotFoundInSearch($event)"
                                      (onFounInSearch)="onFoundInSearch($event)"
                                      [disabled]="disableCardList.includes(plannedobligation['plannedObligationId'])"
                                      (onSelected)="onSelectPlannedCard($event)"
                                      (onUnselected)="onUnselectPlannedObligation($event)"
                                      (onClickId)="clickNavigate($event)"
                                      [plannedObligation]="plannedobligation"></planned-card>
                    </div>
                </div>
            </div>
        </div>
        <div class="rightbox">
            <div class="tabMenu">
                <div *ngFor="let tab of tabs" (click)="onTabChange(tab)" [ngClass]="selectedTab === tab['name']?'tabSelected':'tab'">
                    <div style="display: inline-flex;width: fit-content;align-items: center">
                        <div style="position: relative">
                            <i class="fas fa-circle" style="display: block" [ngClass]="selectedTab === tab['name']?'circleSelected':'circle'"></i>
                            <i class="{{getIcon(tab)}}"  [ngClass]="selectedTab === tab['name']?'circle innericon':'innericon circleSelected'"></i>
                        </div>
                        <p>{{tab['name']}}</p>
                    </div>
                </div>
            </div>
            <div class="gridbox" #divbox inViewport (inViewportAction)="calculateGridHeight(divbox)">
                <div [hidden]="selectedTab !== 'Quantity'">
                    <ctrm-grid [showSectionTop]="false"
                               [isOnSidebar]="true"
                               (onClickedRefresh)="createRowsAndSort(selectedPlannedObligations)"
                               [sortable]="false"
                               [showActions]="false"
                               [showSortIcon]="false"
                               [customWidthColumnNames]="['delete']"
                               [customWidthForColumn]="['50px']"
                               [fieldsWithCustomView]="['delete']"
                               [autoCalculateHeight]="false"
                               [gridFilterDropdownList]="gridFilters"
                               [rightAlignNumberFields]="true"
                               [disabledRows]="quantityDisabledRows"
                               (currentPageData)="onPageChange($event)"
                               [headerWidth]="'131px'"
                               [showFilterInToolbar]="true"
                               [numberboxwidth]="'100px'"
                               (onDropdownFilterValueChange)="onFilterChange($event)"
                               [getFromUrl]="false"
                               [rowValues]="quantityValues"
                               [toolbarConfig]="toolBarConfig"
                               [gridHeight]="gridHeight+'px'"
                               [openInEditMode]="true"
                               [grid]="quantityGrid"></ctrm-grid>
                    <!--                        [customViewTemplated]="[deleteTemplate]"-->
                </div>
                <div [hidden]="selectedTab !== 'Bill of Lading'">
                    <ctrm-grid [autoCalculateHeight]="false"
                               [getFromUrl]="false"
                               [expandRowCustomTemplate]="expandBillOfLanding"
                               [gridHeight]="gridHeight+'px'"
                               [grid]="billOfLandingGrid"
                               [autoCalculateGridWidth]="false"
                               [showExpandGridIcon]="!switchChecked"
                               [marginLeftExpandData]="'0px'"
                               [expandButtonIcon]="'fas fa-pen editicon actionIcon'"
                               [collapseButtonIcon]="'fas fa-pen editicon actionIcon'"
                               [showSortIcon]="false"
                               [rowValues]="switchChecked?surrenderValues:billOfLandingValues"
                               (onClickUniqueKey)="onClickUniqueKey($event)"
                               [saveOnSave]="false"
                               [sortable]="false"
                               (onContextMenuItemClick)="onContextItemClick($event)"
                               (onTableCreated)="onTableCreated($event)"
                               [deleteOnDelete]="false"
                               (onClickExpandKey)="onExpandRow($event)"
                               (selectedRows)="onRowSelectedBL($event)"
                               [selectedRow]="selectedbilloflanding"
                               [showSectionTop]="false"
                               [showToolbar]="true"
                               [customViewInToolbarRight]="toolbarright"
                               [showheaderCheckbox]="true"
                               [toolbarConfig]="toolBarConfigBol">
                    </ctrm-grid>
                </div>
                <div [hidden]="selectedTab !== 'Cost'">
                    <ctrm-grid (selectedRows)="updateSelectedRows($event,'cost')"
                               [autoCalculateHeight]="false"
                               [getFromUrl]="false"
                               [gridHeight]="gridHeight+'px'"
                               (onClickedRefresh)="getCost()"
                               [grid]="costGrid"
                               [showSortIcon]="false"
                               [numberboxwidth]="'100px'"
                               [oldEditableFields]="['costValue']"
                               [onlyNewEditableMode]="true"
                               (currentPageData)="onPageChange($event)"
                               [sortable]="false"
                               [disabledRows]="costDisabledRows"
                               [rowValues]="costValue"
                               (onClickUniqueKey)="onClickUniqueKey($event)"
                               [saveOnSave]="false"
                               [openInEditMode]="true"
                               [selectedRow]="selectedCost"
                               [deleteOnDelete]="false"
                               [showFetching]="refreshingCost"
                               [showSectionTop]="false"
                               [showToolbar]="false"
                               [showheaderCheckbox]="true" [toolbarConfig]="toolBarConfig">
                    </ctrm-grid>
                </div>
                <div [hidden]="selectedTab !== 'Quality'">
                    <ctrm-grid (onClickedRefresh)="getQualitySpecs()"
                               (selectedRows)="updateSelectedRows($event,'qualitySpec')"
                               [autoCalculateHeight]="false"
                               [openInEditMode]="true"
                               [getFromUrl]="false"
                               [gridHeight]="gridHeight+'px'"
                               [grid]="qualitySpecGrid"
                               [disabledRows]="qualityDisabledRows"
                               [oldEditableFieldsFunction]="qualityEditableFunctionField"
                               [showSortIcon]="false"
                               [numberboxwidth]="'100px'"
                               [onlyNewEditableMode]="true"
                               [rowValues]="qualityValue"
                               (onClickUniqueKey)="onClickUniqueKey($event)"
                               [saveOnSave]="false"
                               [sortable]="false"
                               [deleteOnDelete]="false"
                               [selectedRow]="selectedQuality"
                               [showFetching]="refreshingQualitySpec"
                               [showSectionTop]="false"
                               [showToolbar]="false"
                               [showheaderCheckbox]="true"
                               [toolbarConfig]="toolBarConfig">
                    </ctrm-grid>
                </div>

                <div *ngIf="selectedTab === 'Document Upload'">
                    <ctrm-grid (onClickedRefresh)="getAttachements()"
                               [getFromUrl]="false"
                               [saveOnSave]="false"
                               [sortable]="false"
                               [deleteOnDelete]="false"
                               (onDelete)="delete($event)"
                               [fieldsWithCustomView]="['status']"
                               [customViewTemplated]="[blstatus]"
                               (onSave)="onSaveAttachment($event)"
                               [rowValues]="attachmentValues"
                               (onButtonClick)="onButtonClick($event)"
                               (onRefreshComplete)="onRefreshComplete()"
                               [autoCalculateHeight]="false"
                               (onClickUniqueKey)="onClickUniqueKey($event)"
                               [gridHeight]="gridHeight+'px'"
                               [grid]="attachmentGrid"
                               [isOnSidebar]="true"
                               [showSectionTop]="false"
                               [showToolbar]="true"
                               [showheaderCheckbox]="false"
                               [toolbarConfig]="toolBarConfigAttachment">
                    </ctrm-grid>
                </div>
            </div>
        </div>
        <xceler-bottom-pane [canClose]="canClose"
                            [rightViewTemplate]="right"
                            [leftViewTemplate]="left"
                            [styleClass]="'bottomPaneactualization'"
                            [popupDisplay]="displayBottomPane" (onClose)="onCloseBottomPane()"></xceler-bottom-pane>
         <ng-template #left>
            <div class="loadInfo">
                <div class="footerInfobox">
                    <p class="sectionheading">Created By : </p>
                    <p class="sectionValue">{{vesselObj['createdBy']}}</p>
                </div>
                <div class="footerInfobox">
                    <p class="sectionheading">Created Date : </p>
                    <p class="sectionValue">{{vesselObj['createdTimestamp'] | date : 'dd MMM yyyy'}}</p>
                 </div>

                <div class="footerInfobox">
                    <p class="sectionheading">Updated By : </p>
                    <p class="sectionValue">{{vesselObj['updatedBy']}}</p>
                </div>
                <div class="footerInfobox">
                    <p class="sectionheading">Updated Date : </p>
                    <p class="sectionValue">{{vesselObj['updatedTimestamp'] | date : 'dd MMM yyyy'}}</p>
                </div>
            </div>
         </ng-template>                   
        <ng-template #right>
            <div style="display: inline-flex">
                <button *ngIf="selectedTab === 'Quantity'" (click)="addBL()" [disabled]="addBlButtonDisable" class="borderButtons" style="margin: 0; margin-right: 15px; width: auto;white-space:nowrap;">Add BL</button>
                <button *ngIf="selectedTab === 'Quantity'" (click)="undoSplit()" [disabled]="(splitedPlannedObligationId === null || splitedPlannedObligationId === undefined || splitedPlannedObligationId.length === 0)" class="borderButtons" style="margin: 0; margin-right: 15px; width: auto;white-space:nowrap;">Undo Split</button>
                <button *ngIf="selectedTab === 'Quantity'" (click)="splitQuantity()" [disabled]="isSplitDisable" class="borderButtons" style="margin: 0; margin-right: 15px; width: auto;white-space:nowrap;">Split Quantity</button>
                <button *ngIf="selectedTab === 'Bill of Lading'" (click)="confirmBL()" [disabled]="isConfirmBlDisabled" class="borderButtons" style="margin: 0; margin-right: 15px; width: auto;white-space:nowrap;">Confirm BL</button>
                <button *ngIf="selectedTab === 'Bill of Lading'" (click)="switchBl()" [disabled]="isSwitchBlDisabled" class="borderButtons" style="margin: 0; margin-right: 15px; width: auto;white-space:nowrap;">Switch BL</button>
                <button *ngIf="selectedTab === 'Bill of Lading'" (click)="mergeBL()" [disabled]="isMergeBlDisabled" class="borderButtons" style="margin: 0; margin-right: 15px; width: auto;white-space:nowrap;">Merge BL</button>
                <button (click)="actualize(0)" *ngIf="selectedTab !== 'Bill of Lading'"  [disabled]="isActualizeButtonDisable" class="planScreenBtns" style="margin: 0; margin-right: 15px; width: auto;white-space:nowrap;color: white !important;">{{actualizeButtonHeader}}</button>
                <button *ngIf="selectedTab !== 'Bill of Lading' && selectedTab !== 'Document Upload'" (click)="claim()" [disabled]="(!claimAccess || !isActualizeButtonDisable || isClaimButtonDisable)" class="planScreenBtns" style="margin: 0; margin-right: 15px; width: auto;white-space:nowrap;color: white !important;">Claim</button>
                <xceler-progress-button
                        *ngIf="selectedTab === 'Quantity'"
                        [disabled]="(!undoClaimAccess || unclaimDisable || this.quantityValues.length != 1)"
                        (onLoadDone)="onUnclaimDone($event)"
                        (onError)="onErrorUnclaim($event)"
                        [confirmMessage]="'Are you sure you want to unclaim.\n (NOTE : If the contract has split BLs it will unclaim for all BLs.)'"
                        [callApiObjectFunction]="undoClaimApi"
                        [buttonStyle]="{margin:'0px',minHeight:'35px',padding:'0px',paddingLeft:'10px',paddingRight:'10px'}"
                        [buttonIdleTitle]="'Undo Claim'"
                        [buttonLoadingTitle]="'Undo Claim'"></xceler-progress-button>
            </div>
        </ng-template>
    </div>
</p-dialog>
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>
<app-trade-info (onClose)="onCloseTradeInfo($event)" [baseZIndex]="9999" [marginTop]="'0px'" [detailsOf]="'trade'" *ngIf="displayTradeInfo" [display]="true" [rowData]="rowData"
                [tradeId]="rowData['tradeId']"></app-trade-info>
<ng-template #expandBillOfLanding let-index="index">
    <div>
        <div style="display: inline-flex;width: 100%;align-items: center">
            <p class="editHeading">Edit BL Details</p>
            <p class="obligationid">Trade ID :{{(expandedBlrow[index] !== null && expandedBlrow[index] !== undefined)?expandedBlrow[index]['tradeId']:''}}</p>
<!--            -->
            <button (click)="actualize(index)" [disabled]="!((expandedBlrow[index]['blDate'] !== null)&&(expandedBlrow[index]['blDate'] !== undefined) &&
             (expandedBlrow[index]['blNumber'] !== undefined) && (expandedBlrow[index]['blNumber'] !== null) && (expandedBlrow[index]['blDate'].length !== 0) && (expandedBlrow[index]['blNumber'].length !== 0))" class="planScreenBtns" style="margin: 0;padding: 5px; margin-left: 15px; width: auto;white-space:nowrap;color: white !important;">{{actualizeButtonHeader}}</button>
        </div>
        <div style="width: 87%">
            <div class="row rowClass">
                <div class="col-md-2 controlGap contentbox">
                    <label class="heading ng-star-inserted">BL Date<span style="padding: 4px"><i class="fas fa-star" style="font-size: 6px; color: #e70b0b;"></i></span></label>
                    <div class="div-control">
                        <input #dp="bsDatepicker"
                               [bsConfig]="bsConfig"
                               [id]="'bldate'"
                               [(ngModel)]="expandedBlrow[index]['blDate']"
                               autocomplete="off"
                               [minDate]="commonService.moment(expandedBlrow[index]['tradeDateTime']).toDate()"
                               bsDatepicker class="form-control form-control-sm pickerInput"
                               placeholder="Select BL Date"
                               style="border-right: none;min-width: calc(100% - 30px)"
                               tooltipEvent="focus"
                               tooltipPosition="bottom" tooltipZIndex="1000" type="text">
                        <div (click)="dp.toggle()" [attr.aria-expanded]="dp.isOpen" class="calIcon calIconEnabled">
                            <i class="fas fa-calendar" style="margin-left: 7px;margin-top: 8px"></i>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 controlGap contentbox">
                    <label class="heading ng-star-inserted">BL Number<span style="padding: 4px"><i class="fas fa-star" style="font-size: 6px; color: #e70b0b;"></i></span></label>
                    <input id="blnumber" type="text"
                           class="form-control form-control-sm input"
                           pInputText placeholder="BL Number"
                           [(ngModel)]="expandedBlrow[index]['blNumber']">
                </div>
                <div class="col-md-2 controlGap contentbox">
                    <label class="heading ng-star-inserted">Stowage #<span style="padding: 4px"></span></label>
                    <input id="stowageno" type="text"
                           class="form-control form-control-sm input"
                           pInputText placeholder="Stowage Number"
                           [(ngModel)]="expandedBlrow[index]['stowageNo']">
                </div>
                <div class="col-md-2 controlGap contentbox">
                    <label class="heading ng-star-inserted">Quantity</label>
                    <input id="quantity" type="text"
                           class="form-control form-control-sm input"
                           pInputText placeholder="Quantity" disabled
                           [(ngModel)]="expandedBlrow[index]['actualQuantity']+expandedBlrow[index]['uom']">
                </div>
                <div class="col-md-2 controlGap contentbox">
                    <label class="heading ng-star-inserted">Shipper</label>
                    <input id="shipper" type="text"
                           class="form-control form-control-sm input"
                           pInputText placeholder="Shipper"
                           [(ngModel)]="expandedBlrow[index]['shipper']">
                </div>
                <div class="col-md-2 controlGap contentbox">
                    <label class="heading ng-star-inserted">Consignee</label>
                    <input id="consignee" type="text"
                           class="form-control form-control-sm input"
                           pInputText placeholder="Consignee"
                           [(ngModel)]="expandedBlrow[index]['consignee']">
                </div>
                <div class="col-md-2 controlGap contentbox">
                    <div style="display: inline-flex;align-items: center;width: 100%">
                        <div style="display: inline-flex;align-items: center">
                            <label class="heading ng-star-inserted">Notify Party</label>
                            <p class="count" (click)="toggleVisiblity(expandedBlrow[index],'notifyParty','showNotifyParty','notifyPartyDiv'+index)">({{getListCount(expandedBlrow[index],'notifyParty')}})</p>
                        </div>
                        <p class="addnew" (click)="addNewInList(expandedBlrow[index],'notifyPartyTemp','notifyParty')">+ Add New</p>
                    </div>
                    <div>
                        <input id="notifyparty" type="text"
                               class="form-control form-control-sm input"
                               [(ngModel)]="expandedBlrow[index]['notifyPartyTemp']"
                               (keydown.enter)="addNewInList(expandedBlrow[index],'notifyPartyTemp','notifyParty')"
                               pInputText placeholder="Notify Party">
                        <div class="dropbox" [id]="'notifyPartyDiv'+index"  tabindex="1" (focusout)="hideVisiblity(expandedBlrow[index],'showNotifyParty')">
                            <div class="style-4 dropheight" *ngIf="expandedBlrow[index]['showNotifyParty']">
                                <div *ngFor="let item of expandedBlrow[index]['notifyParty']" class="dditem">
                                    <p>{{item}}</p>
                                    <i (click)="removeFromList(expandedBlrow[index],'notifyParty',item,'showNotifyParty')" class="fas fa-times-circle"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 controlGap contentbox">
                    <label class="heading ng-star-inserted">External Ref. Number</label>
                    <input id="contract" type="text"
                           class="form-control form-control-sm input"
                           pInputText placeholder="Contract ref. no."
                           [(ngModel)]="expandedBlrow[index]['contractRef']">
                </div>
                <div class="col-md-2 controlGap contentbox">
                    <div style="display: inline-flex;align-items: center;width: 100%">
                        <div style="display: inline-flex;align-items: center">
                            <label class="heading ng-star-inserted">Forwarding Agent</label>
                            <p class="count" (click)="toggleVisiblity(expandedBlrow[index],'forwardingAgent','showForwardingAgent','forwardingAgentDiv'+index)">({{getListCount(expandedBlrow[index],'forwardingAgent')}})</p>
                        </div>
                        <p class="addnew" (click)="addNewInList(expandedBlrow[index],'forwardingAgentTemp','forwardingAgent')">+ Add New</p>
                    </div>
                    <div>
                        <input id="forwardingagent" type="text"
                               class="form-control form-control-sm input"
                               [(ngModel)]="expandedBlrow[index]['forwardingAgentTemp']"
                               (keydown.enter)="addNewInList(expandedBlrow[index],'forwardingAgentTemp','forwardingAgent')"
                               pInputText placeholder="Forwarding Agent">
                        <div class="dropbox" [id]="'forwardingAgentDiv'+index" tabindex="1" (focusout)="hideVisiblity(expandedBlrow[index],'showForwardingAgent')">
                            <div class="style-4 dropheight"  *ngIf="expandedBlrow[index]['showForwardingAgent']">
                                <div *ngFor="let item of expandedBlrow[index]['forwardingAgent']" class="dditem">
                                    <p>{{item}}</p>
                                    <i (click)="removeFromList(expandedBlrow[index],'forwardingAgent',item,'showForwardingAgent')" class="fas fa-times-circle"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-2 controlGap contentbox">
                    <label class="heading ng-star-inserted">Ship-Onboard</label>
                    <div class="div-control">
                        <input #dp1="bsDatepicker"
                               [bsConfig]="{dateInputFormat:('dd MMM yyyy').toUpperCase(),adaptivePosition:true,useUtc:false}"
                               [id]="'shipOnboard'"
                               [(ngModel)]="expandedBlrow[index]['shipOnboard']"
                               autocomplete="off"
                               bsDatepicker class="form-control form-control-sm pickerInput"
                               placeholder="Select Ship-Onboard"
                               style="border-right: none;min-width: calc(100% - 30px)"
                               tooltipEvent="focus"
                               tooltipPosition="bottom" tooltipZIndex="1000" type="text">
                        <div (click)="dp1.toggle()" [attr.aria-expanded]="dp1.isOpen" class="calIcon calIconEnabled">
                            <i class="fas fa-calendar" style="margin-left: 7px;margin-top: 8px"></i>
                        </div>
                    </div>
                </div>

                <div class="col-md-2 controlGap contentbox">
                    <label class="heading ng-star-inserted">Self/To Order</label>
                    <input id="selftoorder" type="text"
                           class="form-control form-control-sm input"
                           pInputText placeholder="Self/To Order"
                           [(ngModel)]="expandedBlrow[index]['selfToOrder']">
                </div>

                <div class="col-md-2 controlGap contentbox">
                    <label class="heading ng-star-inserted">Remarks</label>
                    <input id="remarks" type="text"
                           class="form-control form-control-sm input"
                           pInputText placeholder="Remarks"
                           [(ngModel)]="expandedBlrow[index]['remarks']">
                </div>

            </div>
            <div class="row rowClass">
                <div *ngIf="expandedBlrow[index]['replacementBl']" class="col-md-2 controlGap contentbox">
                    <label class="heading ng-star-inserted">Planned Obligation Id</label>
                    <div class="dropdownStyle">
                        <p-dropdown [(ngModel)]="expandedBlrow[index]['plannedObligationId']"
                                    [options]="masterCommonService.getListFromArray(expandedBlrow[index]['plannedObligationList'])"></p-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<p-dialog [(visible)]="splitDisplay"
          [closeOnEscape]="false"
          [draggable]="false"
          (onHide)="onSplitDisplayClose()"
          [maximizable]="false" [modal]="true" [showHeader]="false"
          [style]="{zIndex:1000,width: '20%',height: 'auto', padding:'0px', background:'#ffffff', position:'left'}">
    <div style="display: flex; padding-left: 3%; padding-top: 1%;" class="formSectionTitle">
        <p style="font-size: 20px; font-weight: bold; color: #150d4e">Split Quantity</p>
        <i class="material-icons closableDialog" pTooltip="Close" tooltipZIndex="436" tooltipPosition="left"
           (click)="onSplitDisplayClose()">clear</i>
    </div>
    <div class="sectionBoxMain">
        <div style="display: inline-flex;width: auto;margin-top: 54px">
            <p style="color: #150d4e; font-size: 15px;font-weight: bold;white-space: nowrap;padding-right: 10%;">Total # of Splits </p>
            <input type="number"
                   style="width: 100px !important;min-width: 100px !important;"
                   class="form-control form-control-sm input"
                   pInputText placeholder="Self/To Order"
                   [(ngModel)]="splitInValue">
        </div>
    </div>
    <p-footer>
        <div style="border-top: 1px solid #ededed;padding: 10px">
            <button type="button" [disabled]="(splitInValue === null || splitInValue === undefined || splitInValue <= 0) || splitButtonDisable" pButton label="Split Quantity" class="btnDiv btnOrange" (click)="split()"
                    style="float: right;"></button>
        </div>
    </p-footer>
</p-dialog>

<p-dialog [(visible)]="showMergeDisplay"
          [closeOnEscape]="false"
          [draggable]="false"
          (onHide)="onMergeDisplayClose()"
          [maximizable]="false" [modal]="true" [showHeader]="false"
          [style]="{zIndex:1000,width: '20%',height: 'auto', padding:'0px', background:'#ffffff', position:'left'}">
    <div style="display: flex; padding-left: 3%; padding-top: 1%;" class="formSectionTitle">
        <p style="font-size: 20px; font-weight: bold; color: #150d4e">Merge BL</p>
        <i class="material-icons closableDialog" pTooltip="Close" tooltipZIndex="436" tooltipPosition="left"
           (click)="onMergeDisplayClose()">clear</i>
    </div>
    <div class="sectionBoxMain">
        <div style="display: inline-flex;width: auto;margin-top: 54px">
            <p style="color: #150d4e; font-size: 15px;font-weight: bold;white-space: nowrap;padding-right: 10%;">Total # of BL Merge </p>
            <input type="number"
                   style="width: 100px !important;min-width: 100px !important;"
                   class="form-control form-control-sm input"
                   pInputText placeholder="Self/To Order"
                   [(ngModel)]="mergeInValue">
        </div>
    </div>
    <p-footer>
        <div  style="border-top: 1px solid #ededed;padding: 10px;display:inline-flex;justify-content: center;width: 100%">
            <button type="button" pButton label="Cancel" class="borderButtons" (click)="onMergeDisplayClose()"
                    style="float: right;"></button>
            <button type="button" pButton label="Continue" class="btnDiv btnOrange" (click)="merge()"
                    style="float: right;margin-left: 10px"></button>
        </div>
    </p-footer>
</p-dialog>


<p-dialog [(visible)]="surrenderBlShow"
          [closeOnEscape]="false"
          [draggable]="false"
          (onHide)="onSurrenderBLDisplayClose()"
          [maximizable]="false" [modal]="true" [showHeader]="false"
          [style]="{zIndex:1000,width: '20%',height: 'auto', padding:'0px', background:'#ffffff', position:'left'}">
    <div style="display: flex; padding-left: 3%; padding-top: 1%;" class="formSectionTitle">
        <p style="font-size: 20px; font-weight: bold; color: #150d4e">Surrender BL</p>
        <i class="material-icons closableDialog" pTooltip="Close" tooltipZIndex="436" tooltipPosition="left"
           (click)="onSurrenderBLDisplayClose()">clear</i>
    </div>
    <div class="sectionBoxMain">
        <div style="display: inline-flex;width: 100%;margin-top: 54px">
            <p style="color: #150d4e; font-size: 15px;font-weight: bold;white-space: nowrap;width: 100%;text-align: center">Are you sure to surrender the master/original BL? </p>
        </div>
    </div>
    <p-footer>
        <div style="border-top: 1px solid #ededed;padding: 10px;display:inline-flex;justify-content: center;width: 100%">
            <button type="button" pButton label="No" class="borderButtons" (click)="onSurrenderBLDisplayClose()"
                    style="float: right;"></button>
            <button type="button" pButton label="Yes" class="btnDiv btnOrange" (click)="surrender()"
                    style="float: right;margin-left: 10px"></button>
        </div>
    </p-footer>
</p-dialog>
<ng-template #toolbarright>
    <div style="display: inline-flex;align-items: center">
        <p class="surrenblBltext">Surrender BL</p>
        <p-inputSwitch [(ngModel)]="switchChecked" style="margin-top: 5px" (onChange)="switchMode($event)"></p-inputSwitch>
        <p class="surrenblBltextoff">{{switchChecked?'On':'Off'}}</p>
    </div>
</ng-template>
<p-dialog [(visible)]="showUploadForm"
          [closable]="true"
          [draggable]="false"
          [modal]="true"
          [maximizable]="false"
          [showHeader]="false"
          [style]="{ width: '30%',maxHeight:'350px', padding:'0px', background:'#ffffff'}">
    <div style="padding: 10px 10px;border-bottom: 1px solid rgba(0,0,0,0.09)">
        <p style="font-size: 14px; font-weight: bold; color: #16144E">Upload BL Document</p>
    </div>
    <div style="padding: 20px;margin-bottom: 10px">
        <p style="font-family: Raleway, sans-serif;font-size: 13px;color: #333333">Upload Doc</p>
        <div class="dropzone" [style.border]="fileSelected?'1px solid #737373':'dashed 1px #0495EA'">
            <input type="file" (change)="onFileUpload($event)">
            <div *ngIf="fileSelected">
                <p class="uploadMessage">1 file selected successfully!</p>
                <p class="fileName">{{uploadFileName}}</p>
            </div>
            <div *ngIf="!fileSelected" style="display: flex;flex-direction: column">
                <img src="./assets/images/upload.png" alt="uploadIcon">
                <label>Upload Doc</label>
            </div>
        </div>
    </div>
    <p-footer>
        <button class="cancelBtn" (click)="showUploadForm = false">Cancel</button>
        <button class="planScreenBtns" style="color: white" [disabled]="uploading" (click)="uploadForm()">Upload</button>
    </p-footer>
</p-dialog>

<p-dialog [(visible)]="showGenerateForm"
          [closable]="true"
          [draggable]="false"
          [modal]="true"
          [maximizable]="false"
          [showHeader]="false"
          [style]="{ width: '250px',maxHeight:'250px', padding:'0px', background:'#ffffff'}">
    <div style="padding: 10px 10px;border-bottom: 1px solid rgba(0,0,0,0.09)">
        <p style="font-size: 14px; font-weight: bold; color: #16144E">Generate BL Document</p>
    </div>
    <div style="padding: 20px;margin-bottom: 10px">
        <div class="form-content">
            <div class="item-container">
                <p class="fieldName">Document Type <span class="mandatory-span">*</span></p>
                <p-dropdown [options]="documentTypeList"
                               optionLabel="label" [appendTo]="'body'" [(ngModel)]="documentType" (ngModelChange)="onChangeDocType($event)"></p-dropdown>
            </div>
        </div>
    </div>
    <p-footer>
        <button class="cancelBtn" (click)="showGenerateForm = false">Cancel</button>
        <button class="planScreenBtns" style="color: white" [disabled]="uploading" (click)="generateDoc(documentType)">Generate</button>
    </p-footer>
</p-dialog>

<warning-box (onClose)="onCloseWarning($event)"
             (onContinue)="warningClaim && warningFOSFA?(showSettlementPrice = true):(warningClaim?continueClaim(this.quantityValues):onContinueActualization())"
             [continueButtonHeader]="warningClaim && warningFOSFA?'Yes':'Continue Actualization'"
             [errorMessages]="errorMessage"
             [bodyMessage]="warningClaim && warningFOSFA?'Quantity Tolerance Breached. Want To Settle A Different Price':''"
             [cancelButtonHeader]="warningClaim && warningFOSFA?'No':'Cancel'"
             [heading]="warningClaim?'Quantity Claim':'Quantity Actualization'"
             [visible]="warningBoxVisible"
             [warningMessages]="warningMessage"></warning-box>

<p-dialog [(visible)]="showSettlementPrice"
          [closable]="true"
          [draggable]="false"
          [modal]="true"
          [maximizable]="false"
          [showHeader]="false"
          [style]="{ width: '250px',maxHeight:'250px', padding:'0px', background:'#ffffff'}">
    <div style="padding: 10px 10px;border-bottom: 1px solid rgba(0,0,0,0.09)">
        <p style="font-size: 14px; font-weight: bold; color: #16144E">Enter Settlement Price</p>
    </div>
    <div style="padding: 20px;margin-bottom: 10px">
        <div class="form-content">
            <div class="item-container">
                <p class="fieldName">Settlement Price <span class="mandatory-span">*</span></p>
                <input type="number"
                       style="width: 100px !important;min-width: 100px !important;"
                       class="form-control form-control-sm input"
                       pInputText placeholder="Settlement Price"
                       [(ngModel)]="settlementPrice">
            </div>
        </div>
    </div>
    <p-footer>
        <button class="cancelBtn" (click)="showSettlementPrice = false">Cancel</button>
        <button class="planScreenBtns" style="color: white" [disabled]="settlementPrice == null || settlementPrice === undefined || settlementPrice.toString().length == 0 ||settlementPrice <= 0" (click)="continueClaim(quantityValues,true)">Continue Claim</button>
    </p-footer>
</p-dialog>
<ng-template #blstatus let-rowData="rowData">
    <div style="font-size: 14px;font-family: Raleway, sans-serif;">{{rowData['status']}}</div>
</ng-template>
