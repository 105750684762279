import {Component, OnInit} from '@angular/core';
import {QuickbookService} from './Service/quickbook.service';
import {ActivatedRoute} from '@angular/router';
import {BreadcrumbService} from '../../Components/ctrm-breadcrumb/breadcrumb.service';
import {CommonService} from '../../services/common.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-tenant-configuration',
  templateUrl: './tenant-configuration.component.html',
  styleUrls: ['./tenant-configuration.component.css']
})
export class TenantConfigurationComponent implements OnInit {
  addSectionState: boolean = false;
  sectionName: any = '';
  description: any = '';

  sections: any[] = [];
  finalJson: any = {};

  constructor(private quickbook: QuickbookService, public breadcrumbService: BreadcrumbService, private commonService: CommonService) {
  }

  ngOnInit(): void {
    let savedjson = this.commonService.getFromStorage('tenantConfig');
    this.finalJson = {};
    if (savedjson !== null && savedjson !== undefined && savedjson.length > 0) {
      this.finalJson = JSON.parse(savedjson);
    }
    this.breadcrumbService.makeBreadcrumbTo(this.commonService.MASTER_MAP_KEY, '/tenantconfig');
    this.breadcrumbService.pushInBreadcrumb('Tenant.svg', 'Tenant Configuration', '/tenantconfig', true);
    let _this = this;
    let obj = {
      sectionName: 'Rounding Format', key: 'roundingFormat',
      description: 'Description about the rounding format section',
      props: [this.getPropObject('priceRounding', 'Price Rounding', 3, 'number'),
        this.getPropObject('quantityRounding', 'Quantity Rounding', 4, 'number'),
        this.getPropObject('fxRounding', 'Fx Rounding', 8, 'number')], newEntry: false, useForFinalJson: true
    };
    let obj1 = {
      sectionName: 'API Config', description: 'Descripiton about the api config section', key: 'apiConfig', props: [
        this.getPropObject('quickbook', 'Connect to Quickbook', 'Connect To Quickbook', 'imagebutton', (section: any) => {
          _this.connectQuickbook(section);
        }, 'quickbook.svg', {
          background: '#2CA01C',
          color: '#fff'
        }), this.getPropObject('xero', 'Connect to Xero', 'Connect To Xero', 'imagebutton', (section: any) => {
          _this.connectXero(section);
        }, 'xero.svg', {background: '#13B5EA', color: '#fff'})], newEntry: false, useForFinalJson: false
    };
    let obj2 = {
      sectionName: 'KYC Renewal Config', key: 'kycConfig',
      description: 'Descripiton about the KYC Renewal',
      props: [this.getPropObject('kycNotify', 'KYC Renewal Notification before Due Date (in Days)', 30, 'number', undefined, undefined, undefined, 0, 30)],
      newEntry: false, useForFinalJson: true
    };
    this.sections.push(obj);
    this.sections.push(obj1);
    this.sections.push(obj2);

  }

  connectQuickbook(section: any){
    this.quickbook.connectQuickbook();
  }

  connectXero(section: any){
    this.quickbook.connectXero();
  }

  addNewSection() {
    this.addSectionState = true;
  }

  closeModal() {
    this.addSectionState = false;
  }

  addSection() {
    this.addSectionState = false;
    let obj = {};
    obj['sectionName'] = this.sectionName;
    obj['description'] = this.description;
    obj['props'] = [this.getPropObject('priceRounding', 'Price Rounding', 3, 'number'),
      this.getPropObject('quantityRounding', 'Quantity Rounding', 4, 'number'),
      this.getPropObject('fxRounding', 'Fx Rounding', 8, 'number')];
    this.sections.push(obj);
    this.sectionName = '';
    this.description = '';
  }

  private getPropObject(key: string, propName: string, propValue: any, type: string, func?: Function, assestPath?: string, style?: any, min?: number, max?: number) {
    let obj = {};
    obj['key'] = key;
    obj['name'] = propName;
    obj['value'] = propValue;
    obj['type'] = type;
    obj['path'] = assestPath;
    obj['function'] = func;
    obj['style'] = style;
    obj['min'] = min;
    obj['max'] = max;
    obj['deletable'] = false;
    return obj;
  }

  onUpdateSection(event: any) {
    this.commonService.post(environment.base_url + `/api/tenant-configuration/v1/save-tenant-config?tenantId=${this.commonService.getTenantId()}`, this.finalJson).subscribe((next) => {
      this.commonService.postMessage('', 'Tenant Config Updated Successfully', 'success');
      this.commonService.saveToStorage('tenantConfig', JSON.stringify(this.finalJson));
    }, error => {
      this.commonService.postMessage('', this.commonService.getHttpErrorMessage(error, 'Tenant Config'), 'error');
    });
  }

}
