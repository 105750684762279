<div style="display: inline-flex;width: 100%">
    <masters-sidenav></masters-sidenav>
    <div id="pinwidth" style="width: 100%;margin-left: 70px">

        <ctrm-grid *ngIf="loadDataList !== undefined && loadDataList.length != 0"
           (onRowDataSave)="onRowSave($event)"
           [breadcrumbMapKey]="commonService.MASTER_MAP_KEY"
           [currentRoute]="'/transactionReference'"
           [grid]="grid"
           [refreshGrid]="refreshGrid">
</ctrm-grid>
    </div>
</div>