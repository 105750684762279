import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FilterService} from '../../grid/preference/filter/filter.service';
import {Tcolumn} from '../../grid/tcolumn.model';
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
import {Filter} from "../../grid/preference/filter/filter.model";

@Component({
  selector: 'ctrm-autocomplete',
  templateUrl: './ctrm-autocomplete.component.html',
  styleUrls: ['./ctrm-autocomplete.component.css'],

})
export class CtrmAutocompleteComponent implements OnInit, OnChanges,AfterViewInit {
  @Input() placeholder: string = 'Enter....';
  @Input() showSuggestionAfterLengthCount: number = 1;
  @Input() suggestionLists: any = [];
  @Input() startCharacter: string = '';
  @Input() endCharacter: string = '';
  @Input() showResults: boolean = false;
  @Output() onChangeValue = new EventEmitter();
  @Input() columns: Tcolumn[] = [];
  @Input() text: any = '';
  @Input() id: string = 'inputBox';
  @Output() value = new EventEmitter();
  @Output() filters = new EventEmitter();
  @ViewChild('suggestionBox', {static: true}) box: HTMLDivElement;
  showSuggestion: boolean = false;
  showSuggestionforced: boolean = false;
  inputvalue: string = '';
  isStarted: boolean = false;
  selectingValue: any = '';
  currentFocus: number = -1;
  conditionList: any = [];
  currentList: any[] = [];
  currentCode: any = '';
  currentCondition: any = {};
  temp: any = '';
  currentOption: any = {};
  optionList: any[] = [];
  currentColumn: Tcolumn;
  no: number = 0;
  isFocused: boolean = false;
  @Input() minWidth: string = '300px';
  private scrollpos: number = 0;

  constructor(private service: FilterService,private changeDetectorRef:ChangeDetectorRef) {
  }

  ngOnInit() {
    this.conditionList = this.service.getConditionSuggestionList();
    this.currentList = this.suggestionLists;
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  outFilters(filter: Filter) {
    this.filters.emit(filter);
  }

  onChangevalue(value: any) {
    if (value === this.startCharacter) {
      this.isStarted = true;
      this.no = 0;
      this.showSuggestion = true;
    }
    this.value.emit(value);
    this.onChangeValue.emit();
  }

  getTcolumnbyHeader(header): Tcolumn {
    let object;
    this.columns.forEach(function (column) {
      if (column.getHeader() === header) {
        object = column;
      }
    });
    return object;
  }

  updateValue(value) {
    if (value !== undefined) {
      this.currentCode = value;
      this.no = 1;
      this.currentColumn = this.getTcolumnbyHeader(this.currentCode);
      this.conditionList = this.service.getConditionSuggestionList(this.currentColumn.getColumnType());
      this.inputvalue = this.startCharacter + '' + value + ' ';
      this.temp = this.inputvalue;
      this.currentFocus = -1;
      this.showSuggestion = true;
      this.currentList = this.conditionList;
      this.setInputToFocus();
    }
  }

  updateCondition(value) {
    this.currentCondition = value;
    this.no = 2;
    this.inputvalue = this.inputvalue + '' + value['label'] + ' ';
    this.temp = this.inputvalue;
    this.currentFocus = -1;
    this.currentList = this.suggestionLists;
    this.optionList = [];
    if (this.currentColumn.getColumnType() === 'L' || this.currentColumn.getColumnType() === 'B') {
      if (this.currentColumn.getListOfValues()[0]['value'] === '' && this.currentColumn.getListOfValues()[0]['value'] === 'none') {
        this.optionList = this.currentColumn.getListOfValues().splice(0, 1);
      } else {
        this.optionList = this.currentColumn.getListOfValues();
      }
      this.currentList = this.optionList;
    } else {
      this.showSuggestion = false;
    }
    this.setInputToFocus();
  }

  ishidden(suggestion: string) {
    if (this.showSuggestion === true) {
      let ipvalue = this.inputvalue;
      if (ipvalue.charAt(0) === this.startCharacter) {
        ipvalue = ipvalue.substring(1);
      }
      if (suggestion.toUpperCase().startsWith(ipvalue.toUpperCase())) {
        return true;
      }
    }
    return false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showResults']) {
      this.showSuggestion = changes['showResults'].currentValue;
      this.showSuggestionforced = changes['showResults'].currentValue;
    }
    if (changes['text']) {
      this.inputvalue = this.text;
    }
  }

  createObject(value) {
    let usertype1 = value.replace(this.temp, '');
    if (usertype1.length > 0) {
      let usertype2 = '';
      let error = '';
      if (this.currentCondition['label'] === 'Between') {
        let sp = usertype1.split(',');
        usertype1 = sp[0];
        usertype2 = sp[1];
        if (usertype1.length === 0) {
          error = 'Enter first value';
        }
        if (usertype2.length === 0) {
          error = 'Enter Second value';
        }
        if (usertype1 >= usertype2) {
          error = 'Second value must be grater than first value';
        }
      }
      if (this.currentColumn.getColumnType() === 'L' || this.currentColumn.getColumnType() === 'B') {
        usertype1 = this.currentOption;
      }

      if (error.length <= 0) {
        let column = this.getTcolumnbyHeader(this.currentCode);
        let obj = {
          columnName: column.getField(),
          condition: this.currentCondition['value'],
          header: column.getHeader(),
          type: column.getColumnType(),
          filterValue: {firstValue: usertype1, secondValue: usertype2}
        };
        let filter = new Filter(column.getHeader(), column.getField(), this.currentCondition['value']);
        filter.setField(column);
        filter.setFirstValue(usertype1);
        filter.setSecondValue(usertype2);
        this.showSuggestion = false;
        this.inputvalue = '';
        this.isStarted = false;
        this.outFilters(filter);
      }


    }
  }

  onKeyPressed(value: KeyboardEvent) {
    let ch = value.key;
    if (ch === 'Enter') {
      let valuetc = this.selectingValue;
      if (typeof (this.selectingValue) === 'object') {
        valuetc = this.selectingValue['label'];
      }
      if (valuetc.length > 0) {
        if (this.no === 0) {
          this.updateValue(this.selectingValue);
        } else if (this.no === 1) {
          this.updateCondition(this.selectingValue);
        } else if (this.no === 2) {
          this.updateOption(this.selectingValue);
        }
        this.selectingValue = '';
        this.currentFocus = -1;
      } else {
        if (this.isStarted === true) {
          this.createObject(this.inputvalue);
        }
      }
    } else if (ch === 'ArrowDown') {
      if (this.showSuggestion) {
        this.currentFocus++;
        if (this.currentFocus >= this.currentList.length) {
          this.currentFocus = 0;
        }
        this.selectingValue = this.currentList[this.currentFocus];
        this.scroll(this.currentFocus, false);
      }
    } else if (ch === 'ArrowUp') {
      if (this.showSuggestion) {
        this.currentFocus--;
        if (this.currentFocus < 0) {
          this.currentFocus = this.currentList.length - 1;
        }
        this.selectingValue = this.currentList[this.currentFocus];
        this.scroll(this.currentFocus, true);
      }
    } else if (ch === 'Backspace') {
      if (this.inputvalue.length > 1) {
        if (this.currentCondition !== {}) {
          if (this.inputvalue === this.startCharacter + '' + this.currentCode + ' ' + this.currentCondition['label']) {
            this.currentCondition = {};
            this.currentList = this.conditionList;
            this.inputvalue = this.startCharacter + '' + this.currentCode + ' ';
            this.temp = this.inputvalue;
          } else if (this.inputvalue === this.startCharacter + '' + this.currentCode) {
            this.currentCode = '';
            this.no = 0;
            this.showSuggestion = true;
            this.currentList = this.suggestionLists;
            this.inputvalue = this.startCharacter;
            this.temp = this.inputvalue;
          }
        }
      } else {
        this.currentCode = '';
        this.currentCondition = {};
        this.currentList = this.suggestionLists;
        this.showSuggestion = false;
        this.selectingValue = '';
        this.isStarted = false;
      }
    }
  }

  scroll(number, downup) {
    smoothScrollIntoView(document.getElementById(number + "option"));
  }

  updateOption(value: any) {
    this.currentOption = value;
    this.showSuggestion = false;
    this.inputvalue = this.inputvalue + '' + value['label'] + ' ';
    this.no = 0;
    this.currentList = this.suggestionLists;
    this.setInputToFocus();
    this.currentFocus = -1;
  }

  setInputToFocus() {
    document.getElementById(this.id).focus();
  }

  update(suggestion: any) {
    if (this.no === 0) {
      this.updateValue(suggestion);
    } else if (this.no === 1) {
      this.updateCondition(suggestion);
    } else {
      this.updateOption(suggestion);
    }
  }

  onFocusIn() {
    this.isFocused = true;
    this.setInputToFocus();
  }

  onFocusOut(value) {
    if (value === undefined || value.length === 0) {
      this.isFocused = false;
    }
  }

  clearAndClose() {
    this.isFocused = false;
    this.inputvalue = '';
    this.onChangevalue(this.inputvalue);
  }
}
