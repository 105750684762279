import {Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {Tcolumn} from '../../grid/tcolumn.model';
import {Grid} from '../../grid/grid.model';
import {ConfirmationService, MessageService, OverlayPanel} from 'primeng';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormControlServiceService} from '../../services/form-control-service.service';
import {MasterService} from '../../masters/services/MasterService';
import {CommonService} from '../../services/common.service';
import {Router} from '@angular/router';
import {BreadcrumbService} from '../../Components/ctrm-breadcrumb/breadcrumb.service';
import {environment} from '../../../environments/environment';
import {defaultValue} from '../../grid/defaultValue.model';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {KeyValue} from "../../grid/key-value.model";
import { DatePipe } from '@angular/common';
import {tradeApprovalFunctions} from "../contract/trade/trade-approval-functions";
import { AccessPolicyService } from 'src/app/services/accesspolicy.service';


@Component({
  selector: 'app-future',
  templateUrl: './future-trade.component.html',
  styleUrls: ['./future-trade.component.css']
})
export class FutureTradeComponent implements OnInit {

  columns: Map<string, Tcolumn>;
  grid: Grid;
  refreshGrid: boolean = false;
  @ViewChild('physical') eod: ElementRef;
  giList: any[];
  //NODATA BOX
  noDataBoxLeft: any;
  @Input() showFetching: boolean = false;
  isError: boolean = false;
  loadMessage: string = 'Loading ..';
  @Input() noDataLabel: string = 'No Data Available';
  @Input() showTooltipOnValidationError: boolean = false;
  gridColumns: Tcolumn[] = [];
  //new screen
  search: any;
  filterBrokerList: any[] = [];
  filterExecutionBrokerList: any[] = [];
  filterExpiryMonthList: any[] = [];
  filterFutureIndexList: any[] = [];
  filterTradeIdList:any[]=[];
  filterProfitcenterList: any[] = [];
  //Calender
  showDateRange: boolean = false;
  showTradeDateRange:boolean = false;
  selectedPreset: any = {};
  selectedTradePresent:any ={};
  ranges: any[] = [];
  tradeRange:any[]= [];
  //calculate Height
  private box: HTMLDivElement;
  gridHeight: any;
  //card selection
  isallselected: boolean = true;
  allselected: boolean = true;
  futureTrade: any[] = [];
  hiddenCard: any[] = [];
  disableCardList: any[] = [];
  futureValues: any[] = [];
  toolBarConfig: any = {
    add: true,
    edit: true,
    copy: true,
    delete: false,
    refresh: true,
    export: true,
    exportAll: false,
    import: true,
    audit: false,
    print: false,
    prefrences: false
  };
  @ViewChild('overlayPanel', {static: true}) overlayPanel: OverlayPanel;
  overlayPanelRowData: any;
  documents: any[] = [];
  displayBasic: boolean = false;
  top: any = '0px';
  selectedFuturePosition: any[] = [];
  // Sidebar
  header: any = 'New Future Trade';
  futureTradeForm: FormGroup;
  saveButtonLabel: string = 'Save';
  validationSections: any = {};
  displayOnAdd: boolean = false;
  createdBy: string = '';
  quantityRoundOff:number=3;
  priceRoundOff:number=2;
  @Input() formControlAIO: FormControl;
  @Input() field: Tcolumn;
  traderNameList: any[] = [];
  companyList: any[] = [];
  profitcenterList: any[] = [];
  futureIndexList: any[] = [];
  tickerList:any[]=[];
  editMode:boolean = true;
  private formControlService: FormControlServiceService = new FormControlServiceService();
  //Future Details Table
  visible: boolean = false;
  combinationAdded: any[] = [];
  dataHeight: any = '';
  addButtonDisable: boolean = false;
  columnMaps: Map<string, {
    futureIndex: any,
    ticker:any,
    company: any,
    profitcenter: any,
    traderName: any,
    metadata: Tcolumn[],
    values: any[],
    expanded: boolean,
    newRows: any[],
    refreshAddNewRow: boolean,
    refreshFormGroup: boolean
  }> = new Map<string, {
    futureIndex: any,ticker:any, company: any,
    profitcenter: any, traderName: any, count: number, metadata: Tcolumn[],
    values: any[], expanded: boolean, newRows: any[],
    refreshAddNewRow: boolean, refreshFormGroup: boolean
  }>();
  values: any[] = [];
  refreshAddNewRow: boolean = false;
  refreshFormGroup: boolean = false;
  gridDisplay: boolean = false;
  futureFormGroup: FormGroup;
  refreshValidationBox: boolean = false;
  expiryMonthFilter: boolean = false;
  toggleButton: boolean = false;
  futureInfoDisplay: boolean = false;
  valueForInfo: any;
  valueForInfoHeader: any;
  nettingStatusValues:any[]= [];
  nettingStatus:any[] =['Not Netted', 'Partially Netted', 'Fully Netted'];
  datePipe: DatePipe;
  deleteDisplay: boolean = false;
  deleteDisable: boolean = false;
  selectedRows:any[] = [];  
  canDelete: boolean = false;
  onclickEditUnique:boolean = false;


  constructor(private masterCommonService: MasterService,
              public commonService: CommonService,
              private router: Router,
              private messageService: MessageService,
              public breadCrumbService: BreadcrumbService,
              private fb: FormBuilder,
              private http: HttpClient,
              private confirmationService: ConfirmationService,
              private accessSerivce:AccessPolicyService) {
  }

  ngOnInit(): void {
    let tenantConfig=JSON.parse(this.commonService.getFromStorage("tenantConfig"));
    if(tenantConfig && tenantConfig.roundingFormat){
      this.quantityRoundOff=tenantConfig.roundingFormat.quantityRounding;
      this.priceRoundOff=tenantConfig.roundingFormat.priceRounding;
    }
    else{
      this.quantityRoundOff=3;
      this.priceRoundOff=2;
    }
    this.breadCrumbService.makeBreadcrumbTo(this.commonService.TRADE_MAP_KEY, '/futuretrade');
    this.commonService.getLoadData('commodity', ['data'], [[{key: 'name', value: 'exchange_for_ind'}]]).subscribe((next: any) => {
      this.giList = next;
      this.getColumns();
      this.grid = new Grid(this.columns, true, true, true, true, 'tradeId', '', '', 'Futures', undefined, undefined);
      this.gridColumns = this.masterCommonService.getGridField(this.grid);
      this.grid.setUpdateUrl(environment.base_url + '/futureTrade/');
      this.grid.openDefaultInputForm = false;
      this.grid.setGridConfiguration(true, true, true, false);
      this.grid.setWidth('110%');
      this.grid.gridFetchUrlType='LOCAL';
      this.grid.ignoreFieldListForExport = ['tradeId','status','exchange','commodity','lotSize','uom','balanceLot'];
      this.grid.importUrl = environment.base_url + '/api/futureTrade/v1/import?tenantId=' + this.commonService.getFromStorage('tenantId');
      this.grid.actionFunc.preImportFunction = (next:any[]) => {
        const formatContractMonth = (fut: any) => {
          return {
            ...fut,
            contractMonth: this.masterCommonService.getFormattedDate(fut.contractMonth, 'MMM yyyy')
          };
        };
        const future = next.map(formatContractMonth);
        return future;
      }
      this.getFuture(true);
    });
    this.initInternalForm();
    let array= ['Not Netted','Partially Netted','Fully Netted','Void'];
    this.nettingStatusValues=  this.masterCommonService.getListFromArray(array, false, false, '','');
    this.datePipe = new DatePipe('en-US');
    this.canDelete = this.accessSerivce.canAccess(this.accessSerivce.currentRoute,'Grid Access','delete');    
  }



  getColumns() {
    let _this = this;
    let df=this.quantityRoundOff;
    let index = 0;
    this.columns = new Map();
    let uomNameFun = function(value: any) {
      return value[0];
    };

    let priceFunc = function(value) {
      return value[0] + ' ' + value[1];
    };
    let QuantityLotUom = function(value) {
      return _this.commonService.formatNumberWithoutComma((value[1] * value[0]),df )+ value[2]
    };

    let balanceFunc = (value:any[]) => {
      return value[0] - value[1];
    }
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', index++, false, undefined, false, '', 'N'));
    // this.columns.set('status', new Tcolumn('status', 'Hedge Status', 'LB', index++, true, undefined, true, '', 'B', undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    this.columns.set('tradeId', new Tcolumn('tradeId', 'Trade Id ', 'LB', index++, true, undefined, true, '', 'B', undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    this.columns.set('tradeTransactionType', new Tcolumn('tradeTransactionType', 'Buy/Sell', 'L', index++, true, this.masterCommonService.getBooleanFromLabel('Buy', 'Sell', false, 'BUY', 'SELL'), true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    this.columns.set('tradeType', new Tcolumn('tradeType', 'Trade Type', 'T', index++, true, undefined, true, 'Exchange', 'F', undefined, undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('tradeDate', new Tcolumn('tradeDate', 'Trade Date ', 'D', index++, true, undefined, true, '', 'B', undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    this.columns.set('futureIndex', new Tcolumn('futureIndex', 'Future Index', 'LB', index++, true, undefined, true, '', 'F', undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    this.columns.set('exchange', new Tcolumn('exchange', 'Exchange', 'T', index++, true, undefined, true, '', 'F', undefined, undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('commodity', new Tcolumn('commodity', 'Commodity', 'T', index++, true, undefined, true, '', 'F', undefined, undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('lot', new Tcolumn('lot', 'Lot', 'N', index++, true, undefined, true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    this.columns.set('balanceLot', new Tcolumn('balanceLot', 'Remaining Lot', 'N', index++, true, undefined, true, new defaultValue('',undefined,undefined,undefined,false,true,false,balanceFunc,['lot','matchLot']), 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    this.columns.set('tradePrice', new Tcolumn('tradePrice', 'Price', 'N', index++, true, undefined, true, new defaultValue(undefined, undefined, undefined, undefined, undefined, undefined, undefined, priceFunc, ['tradePrice', 'currency']), 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    this.columns.set('expiryMonth', new Tcolumn('expiryMonth', 'Ticker', 'LB', index++, true, undefined, true, '', 'B', undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    this.columns.set('futureIndex', new Tcolumn('futureIndex', 'Future Index', 'LB', index++, true, undefined, true, '', 'B', undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    this.columns.set('contractMonth', new Tcolumn('contractMonth', 'Contract  Month', 'T', index++, true, undefined, true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('expiryDate', new Tcolumn('expiryDate', 'Expiry Date', 'D', index++, true, undefined, true, '', 'B', undefined, undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('lotSize', new Tcolumn('lotSize', 'Quantity', 'N', index++, true, undefined, true, new defaultValue(undefined, undefined, undefined, undefined, undefined, undefined, undefined, QuantityLotUom, ['lotSize', 'lot','uom']), 'B', undefined, undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('currency', new Tcolumn('currency', 'Currency', 'T', index++, true, undefined, true, '', 'F', undefined, undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('uom', new Tcolumn('uom', 'Lot UOM', 'T', index++, true, undefined, true, new defaultValue('', undefined, undefined, undefined, undefined, undefined, undefined, uomNameFun, ['uomName']), 'F', undefined, undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('company', new Tcolumn('company', 'Company', 'T', index++, true, undefined, true, '', 'B', undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    this.columns.set('profitcenter', new Tcolumn('profitcenter', 'Profit Center', 'T', index++, true, undefined, true, '', 'B', undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    this.columns.set('traderName', new Tcolumn('traderName', 'Trader Name', 'T', index++, true, undefined, true, '', 'F', undefined, undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('clearingBroker', new Tcolumn('clearingBroker', 'Clearing Broker', 'L', index++, true, undefined, true, '', 'B',undefined , undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    this.columns.set('executionBroker', new Tcolumn('executionBroker', 'Exceution Broker', 'L', index++, true, undefined, true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    this.columns.set('clearingBrokerAccount', new Tcolumn('clearingBrokerAccount', 'Clearing Broker Account', 'T', index++, true, undefined, true, '', 'F', undefined, undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    //this.columns.set('clearingFee', new Tcolumn('clearingFee', 'Clearing Fee', 'N', index++, true, undefined, true, '', 'B', undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    //this.columns.set('brokerageFee', new Tcolumn('brokerageFee', 'Brokerage Fee', 'N', index++, true, undefined, true, '', 'B', undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    this.columns.set('exchangeFor', new Tcolumn('exchangeFor', 'Exchange For', 'L', index++, true, this.masterCommonService.getListFromArray(this.giList['exchange_for_ind']), true, '', 'F', [], undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    this.columns.set('tag', new Tcolumn('tag', 'Tag', 'T', index++, true, undefined, true, '', 'F', undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    this.columns.set('nettingStatus', new Tcolumn('nettingStatus', 'Netting Status', 'LB', index++, true, undefined, true, '', 'H', undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    this.columns.set('expiredStatus', new Tcolumn('expiredStatus', 'Expired Status', 'LB', index++, true, undefined, true, '', 'H', undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
  }

  @HostListener('window:resize', [])
  public onResize() {
    this.calculateHeight('contentBoxole.lo');
    this.calcaulateListHeight();
    this.calculateGridHeight(this.box);

  }

  showToast(msg, severity: string = 'info') {
    this.messageService.add({
      severity: severity,
      summary: msg
    });
  }

//ON LOAD SCREEN DISPLAY
  filterForms = this.fb.group({
    brokerList: new FormControl(),
    tradeIdList: new FormControl(),
    futureIndexList: new FormControl(),
    tickerList:new FormControl(),
    profitCenterList: new FormControl(),
  });

  resetFilterForm() {
    this.filterForms.controls['brokerList'].setValue('all');
    this.filterForms.controls['futureIndexList'].setValue('all');
    this.filterForms.controls['profitCenterList'].setValue('all');
    this.filterForms.controls['tickerList'].setValue('all');

  }

  //Show Calendar
  toggleDateRange() {
    this.showDateRange = !this.showDateRange;
  }
  toggleTradeDateRange(){
    this.showTradeDateRange = !this.showTradeDateRange;
  }

  onCancel() {
    this.showDateRange = false;
  }
  onTradeDateCancel(){
    this.showTradeDateRange = false;

  }

  onPresetChange(preset: any) {
    this.selectedPreset = preset;
    this.getFutureTrade();
    this.toggleDateRange();
  }
  onPresetTradeDateChange(preset:any){
    this.selectedTradePresent = preset;
    this.getFutureTrade();
    this.toggleTradeDateRange();
  }

  //Get Calendar values for Drop down
  private createPreset() {
    this.ranges = [];
    this.ranges.push({title: 'Expiry Month', type: 'L', list: this.filterExpiryMonthList});
    this.tradeRange=[];
    this.selectedPreset = this.ranges[0];

  }

  //Get values in drop down
  getFuture(fetch:boolean) {
    let _this=this;
    if(!this.isFilterApplied()){
      this.futureTrade = [];
      this.commonService.getJSONByURL(environment.base_url + '/api/futureTrade/v1/getfuturetradefilterdata' + '?tenantId=' + this.commonService.getTenantId()).subscribe((next: any) => {
        this.filterBrokerList = this.masterCommonService.getListFromArray(next['Clearingbroker'], false, true, 'All', 'all');
        this.filterExecutionBrokerList = this.masterCommonService.getListFromArray(next['Executionbroker'], false, true, 'All', 'all');
        this.filterProfitcenterList = this.masterCommonService.getListFromArray(next['Profitcenter'], false, true, 'All', 'all');
        this.filterFutureIndexList = this.masterCommonService.getListFromArray(next['Futureindex'], false, true, 'All', 'all');
        this.filterExpiryMonthList = this.masterCommonService.getListFromArray(next['Expirymonth'], false, true, 'Select', '');
        this.createPreset();
        this.resetFilterForm();
        this.getFutureTrade();
      });
      this.commonService.getJSONByURL(environment.base_url+'/api/futureTrade/v1/getallFutureTradeId'+'?tenantId=' + this.commonService.getTenantId()).subscribe((data:any)=>{
        const all = [{"tradeId":'All'}];
        this.filterTradeIdList = [...all, ...data];
      })
    }
    else{
      let expiryMonth = 'all';
      let fromDate = '';
      let toDate = '';
      let fromTradeDate = '';
      let toTradeDate = '';
      if (this.selectedPreset['type'] !== undefined && this.selectedPreset['type'] !== null && this.selectedPreset['type'] === 'L') {
        expiryMonth = this.selectedPreset['selectedValue'];
      } else {
        fromDate = this.selectedPreset['startDate'];
        toDate = this.selectedPreset['endDate'];
      }

      if(this.selectedTradePresent!== null && this.selectedTradePresent!== undefined && Object.keys(this.selectedTradePresent).length!=0){
        fromTradeDate = this.datePipe.transform(this.selectedTradePresent['startDate'],"yyyy-MM-ddTHH:mm:ss.SSS",Intl.DateTimeFormat().resolvedOptions().timeZone+'Z');
        toTradeDate = this.datePipe.transform(this.selectedTradePresent['endDate'],"yyyy-MM-ddTHH:mm:ss.SSS",Intl.DateTimeFormat().resolvedOptions().timeZone+'Z');
      }

      let filterPayload = {
        clearingBroker: this.filterForms.controls['brokerList'].value,
        futureIndex: this.filterForms.controls['futureIndexList'].value,
        profitcenter: this.filterForms.controls['profitCenterList'].value,
        tradeId:this.filterForms.controls['tradeIdList'].value==null?'All':this.filterForms.controls['tradeIdList'].value.tradeId,
        status:this.nettingStatus,
        expirymonth: expiryMonth,
        fromDate: fromDate,
        toDate: toDate,
        fromTradeDate:fromTradeDate,
        toTradeDate:toTradeDate
      };
      this.futureTrade = [];
      this.futureValues = [];
      this.commonService.post(environment.base_url + '/api/futureTrade/v1/getfuturetradebyfutureindex' + '?tenantId=' + this.commonService.getTenantId() + '&isExpiryMonth=false', filterPayload).subscribe((next: any) => {
        _this.futureTrade = next;
        _this.toggleButton = false;
        _this.futureTrade.forEach(function(futurePos: any) {
          _this.futureValues = [..._this.futureValues, ...futurePos['futureTradesList']];
        });
      });
    }
  }

  //Get Card and Grid data
  getFutureTrade() {
    let _this = this;
    let url = '/api/futureTrade/v1/getfuturetradebyfutureindex' + '?tenantId=' + this.commonService.getTenantId() + '&isExpiryMonth=false';
    let filterPayload = {};
    let expiryMonth = 'all';
    let fromDate = '';
    let toDate = '';
    let fromTradeDate = '';
    let toTradeDate = '';
    if (this.selectedPreset['type'] !== undefined && this.selectedPreset['type'] !== null && this.selectedPreset['type'] === 'L') {
      expiryMonth = this.selectedPreset['selectedValue'];
    } else {
        fromDate = this.selectedPreset['startDate'];
        toDate = this.selectedPreset['endDate'];
    }

    if(this.selectedTradePresent!== null && this.selectedTradePresent!== undefined && Object.keys(this.selectedTradePresent).length!=0){
      fromTradeDate = this.datePipe.transform(this.selectedTradePresent['startDate'],"yyyy-MM-ddTHH:mm:ss.SSS",Intl.DateTimeFormat().resolvedOptions().timeZone+'Z');
      toTradeDate = this.datePipe.transform(this.selectedTradePresent['endDate'],"yyyy-MM-ddTHH:mm:ss.SSS",Intl.DateTimeFormat().resolvedOptions().timeZone+'Z');
    }

    filterPayload = {
      clearingBroker: this.filterForms.controls['brokerList'].value,
      futureIndex: this.filterForms.controls['futureIndexList'].value,
      profitcenter: this.filterForms.controls['profitCenterList'].value,
      tradeId:this.filterForms.controls['tradeIdList'].value==null?'All':this.filterForms.controls['tradeIdList'].value.tradeId,
      status:this.nettingStatus,
      expirymonth: expiryMonth,
      fromDate: fromDate,
      toDate: toDate,
      fromTradeDate:fromTradeDate,
      toTradeDate:toTradeDate
    };
    this.futureTrade = [];
    this.futureValues = [];
    let futures = [];
    this.commonService.post(environment.base_url + url, filterPayload).subscribe((next: any) => {
      if(next!= null && next!= undefined && next.length>0){
        _this.futureTrade = next;
        next.forEach(data=> futures=[...futures,...data.futureTradesList]);
        _this.futureValues=futures;
      }else {
        _this.futureTrade=[];
        _this.futureValues=[];
        this.showToast("No Future Trade Found for the TradeDate Selected","error");
      }
    });
    
  }

  clearAllFilter() {
    let _this=this;
    let filterPayload = {};
    this.filterForms.controls['brokerList'].setValue('all');
    this.filterForms.controls['futureIndexList'].setValue('all');
    this.filterForms.controls['profitCenterList'].setValue('all');
    this.filterForms.controls['tradeIdList'].setValue('All')
    this.selectedPreset['selectedValue']='all';
    this.selectedPreset['startDate']='';
    this.selectedPreset['endDate']='';
    this.selectedTradePresent['startDate'] ='';
    this.selectedTradePresent['endDate'] ='';
    // this.nettingStatus = [];

    filterPayload = {
      executionBroker: 'all',
      tradeId:'All',
      futureIndex: 'all',
      profitcenter: 'all',
      expirymonth: 'all',
      fromDate: '',
      toDate: '',
      status:["Not Netted","Partially Netted", "Fully Netted"],
      fromTradeDate:'',
      toTradeDate:''
    };
    this.futureTrade = [];
    this.futureValues = [];
    let futures = [];
    this.commonService.post(environment.base_url + '/api/futureTrade/v1/getfuturetradebyfutureindex' + '?tenantId=' + this.commonService.getTenantId() + '&isExpiryMonth=false', filterPayload).subscribe((next: any) => {
      _this.futureTrade = next;
      next.forEach(data=> futures=[...futures,...data.futureTradesList]);
      _this.futureValues=futures;
    });
   this.refreshGrid = true;
  }

  private isEmptyList(list:any[]){
    return list===null || list=== undefined || list.length===0;
  }
  isFilterApplied(){
        return !this.isEmptyList(this.filterForms.controls['brokerList'].value) || !this.isEmptyList(this.filterForms.controls['futureIndexList'].value)
    || !this.isEmptyList(this.filterForms.controls['profitCenterList'].value);
  }
  //On change of filter Values (Broker/ProfitCenter/Future Index/Expiry Month)
  onFilterValueChange(value: any) {
    let _this = this;
    this.refreshGrid = false;
    if (value['value'] !== undefined && value['value'] !== null && value['value'] !== 'all') {
      this.commonService.getJSONByURL(environment.base_url + '/api/priceIndex/v1/getTickerList?name=' + value['value'] + '&tenantId=' + this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
        // this.filterExpiryMonthList = [{label: 'Select', value: ''}];
        // this.filterExpiryMonthList = [...this.filterExpiryMonthList, ...next];
        // this.createPreset();
      });
    } else {
      this.commonService.getJSONByURL(environment.base_url + '/api/futureTrade/v1/getfuturetradefilterdata' + '?tenantId=' + this.commonService.getTenantId()).subscribe((next: any) => {
        this.filterExpiryMonthList = this.masterCommonService.getListFromArray(next['expirymonth'], false, true, 'Select', '');
        this.createPreset();
      });
    }
    this.refreshGrid=true;
  }

  onSelectionChangeAll(value: any) {
    this.allselected = !this.allselected;
    this.isallselected = this.allselected;
    this.selectedFuturePosition = [];
    if (this.allselected) {
      this.selectedFuturePosition = [...this.futureTrade];
    }
  }

  onSelectCard(value: any) {
    let _this = this;
    _this.selectedFuturePosition.push(value);
    this.futureValues = Object.assign(this.futureValues, value['futureTradesList']);
    if (this.selectedFuturePosition.length === this.futureTrade.length) {
      this.allselected = true;
      this.isallselected = true;
    }
  }

  onUnselectCard(value: any) {
    let _this = this;
    let oldList: any[] = Object.assign([],this.futureValues);
    this.futureValues = [];
    value['futureTradesList'].forEach(val => {
      oldList.splice(oldList.indexOf(val), 1);
    });
    this.futureValues = Object.assign([],oldList);
    if (this.futureValues.length === 0) {
      this.allselected = false;
      this.isallselected = false;
    } else {
      this.isallselected = true;
      this.allselected = false;
    }
  }

  onClickStatus(event: MouseEvent, displayPanel: OverlayPanel, rowData: any, ptag: HTMLParagraphElement) {
    this.documents = [];
    this.overlayPanelRowData = rowData;
    this.displayBasic = true;
    let status = rowData['status'];
    let tag = rowData['tag'];
    if (status !== undefined || status !== null) {
      if (status === 'Physical Hedge') {
        if (tag !== undefined && tag !== null) {
          this.top = event.screenY - 600 + 'px';
          displayPanel.toggle(event);
          this.documents.push(tag);
        } else {
          this.showToast('No Trade is linked to the Future Trade.');
        }
      }
    }
  }

  onClickOfTradeId(value: any) {
    this.router.navigate(['/trade'], {queryParams: {value: value['srcElement']['innerText']}});
  }

  //calculate Card height
  calcaulateListHeight() {
    let el = document.getElementById('list');
    if (el !== null && el !== undefined) {
      let viewportHeight = window.innerHeight;
      let offsetTop = el['offsetTop'];
      let height = viewportHeight - offsetTop - 40;
      el.style.maxHeight = height + 'px';
      el.style.minHeight = height + 'px';
    }
  }

  //Calculate Grid Height
  calculateGridHeight(divbox: HTMLDivElement) {
    if (divbox !== null && divbox !== undefined) {
      this.box = divbox;
      let viewportHeight = window.innerHeight;
      let offsetTop = divbox.offsetTop;
      this.gridHeight = viewportHeight - offsetTop - 90;
    }
  }

  //Calculate Future Tarde Grid Height
  calculateHeight(id) {
    let el: HTMLElement = document.getElementById(id);
    if (el !== undefined && el !== null) {
      let offsetTop = el.offsetTop;
      let offsetHeight = el.offsetParent.clientHeight;
      this.dataHeight = (offsetHeight - (offsetTop + 80)) + 'px';
    }
  }

  //nodata box
  calculateLeft() {
    this.noDataBoxLeft = ((window['visualViewport'].width - 500) / 2) + 'px';
  }

  getTooltipMessage(control: AbstractControl, col) {
    return this.showTooltipOnValidationError ? this.commonService.getValidationErrorMessage(control, col.getHeader()) : '';
  }

  //ON CLICK ADD ON GRID
  //Create Form Control
  initInternalForm() {
    this.futureTradeForm = this.formControlService
        .addControl('traderName', '', [Validators.required])
        .addControl('company', '', [Validators.required])
        .addControl('profitcenter', '', [Validators.required])
        .addControl('futureIndex', '', [Validators.required])
        .addControl('ticker','',[Validators.required])
        .addControl('entries', '', [Validators.required])
        .build();
    this.loadValidationSection();
  }

  private loadValidationSection() {
    this.validationSections = {};
    this.validationSections['Basic Details'] = [
      {field: 'traderName', header: 'Trader Name'},
      {field: 'company', header: 'Company'},
      {field: 'profitcenter', header: 'Profitcenter'},
      {field: 'futureIndex', header: 'Future Index'},
      {field:'ticker',header:'Ticker'},
      {field: 'entries', header: 'Entries'}
    ];
  }

  onformGroupCreated(formgroup: FormGroup) {
    this.futureFormGroup = formgroup;
  }

  onchangeformfield(value:any){
   this.futureTradeForm.valueChanges.subscribe(values => {
     if(values.company != '' && values.traderName != '' && values.futureIndex !=''&& values.profitcenter !='' && values.ticker != '' &&  this.onclickEditUnique == false ){
       this.addButtonDisable = false;
     }
     if(this.onclickEditUnique == true){
       this.addButtonDisable= true
     }
   })
  }

  //On Click Add SideBar
  onClickAdd(value) {
    if(value){
      this.futureTradeForm.controls['traderName'].enable();
      this.futureTradeForm.controls['company'].enable();
      this.futureTradeForm.controls['profitcenter'].enable();
      this.futureTradeForm.controls['futureIndex'].enable();
      this.futureTradeForm.controls['ticker'].enable();
      this.futureTradeForm.controls['entries'].enable();
      this.addButtonDisable= true
    }
    if(this.onclickEditUnique == true){
      this.addButtonDisable= true
    }
    this.futureTradeForm.controls['traderName'].setValue('');
    this.futureTradeForm.controls['company'].setValue('');
    this.futureTradeForm.controls['profitcenter'].setValue('');
    this.futureTradeForm.controls['futureIndex'].setValue('');
    this.futureTradeForm.controls['ticker'].setValue('');
    this.futureTradeForm.controls['entries'].setValue(5);
    this.createdBy = this.commonService.getFromStorage('id');
    this.refreshGrid = false;
    this.displayOnAdd = true;
    this.gridDisplay = false;
    this.visible = false;
    this.combinationAdded = [];
    this.columnMaps = new Map<string, any>();
    this.getTraderAndFutureIndex();
  }

  //show Trader Name and Future Index in the sideBar
  getTraderAndFutureIndex() {
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url_new + '/api-iam/api/usermanagementservice/v1/getListByUserType?tenantId='
        + this.commonService.getFromStorage('tenantId') + '&userType=Trader').subscribe((next: any) => {
      _this.traderNameList = _this.commonService.createListFromObject(next, 'value', 'value', true);
    });
    // _this.commonService.getJSONByURL(environment.base_url + '/api/futurecommodityprice/v1/gettickerlist?tenantId=' + this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
    //   _this.futureIndexList = _this.commonService.createListFromObject(next, 'tickerName', 'tickerName', true);
    // });
    // /api/futureTrade/v1/getallfutureexchangecodevalue?tenantId=d7408d31-d720-4173-b76e-0595ce2679b4
    _this.commonService.getJSONByURL(environment.base_url + '/api/futureTrade/v1/getallfutureexchangecodevalue?tenantId=' + this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
      _this.futureIndexList= _this.commonService.createListFromObject(next, '', next, true);
      let list:any[] =[];
      list.push(new KeyValue());
      next.forEach(function (obj) {
        list.push(new KeyValue(obj,obj));
      });
      _this.futureIndexList=list;
    });

  }
  


  //get the data on select Trader Name(company,profitCenter)
  onTraderNameChange(traderName: any) {
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url_new + '/api-iam/api/userProfile/v1/getUserDetails?userId=' + this.commonService.getFromStorage('userName') + '&tenantId=' + this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
      _this.companyList = _this.commonService.createListFromObject(next['userInfoData']['Company'], 'masterTypeValue', 'masterTypeValue', true);
      _this.profitcenterList = _this.commonService.createListFromObject(next['userInfoData']['Profit Center'], 'masterTypeValue', 'masterTypeValue', true);
    });
  }

  //Add Entities and show the Price Grid
  addEntities() {
    this.refreshAddNewRow = false;
    this.refreshFormGroup = false;
    let futureIndexPayload= this.futureTradeForm.controls['futureIndex'].value;
    let _this = this;
    let futurepayload = {
      futureIndex: futureIndexPayload,
      company: this.futureTradeForm.controls['company'].value,
      ticker: this.futureTradeForm.controls['ticker'].value,
      profitcenter: this.futureTradeForm.controls['profitcenter'].value,
      traderName: this.futureTradeForm.controls['traderName'].value,
      count: this.futureTradeForm.controls['entries'].value
    };
    // this.addButtonDisable = true;
    this.commonService.getJSONByObject(environment.base_url + '/api/futureTrade/v1/getFutureTradeDataTemplate?tenantId=' + this.commonService.getFromStorage('tenantId'), futurepayload).subscribe((next: any[]) => {
      _this.addContentBox(futurepayload['futureIndex'],futurepayload['ticker'], futurepayload['company'], futurepayload['profitcenter'], futurepayload['traderName'], next,true);
    });
  }

  addContentBox(futureIndex,ticker, company, profitcenter, traderName, next: any[] = [],mode?:boolean) {
    this.formControlService.setValue('traderName', traderName);
    this.formControlService.setValue('profitcenter', profitcenter);
    this.formControlService.setValue('company', company);
    this.formControlService.setValue('futureIndex', futureIndex);
    this.formControlService.setValue('ticker',ticker);
    this.onTraderNameChange(traderName);
    let _this = this;
    let combination = futureIndex + company + profitcenter + traderName;
    if (!_this.combinationAdded.includes(combination)) {
      let metaData: Tcolumn[] = []
      metaData = this.masterCommonService.getGridField(_this.grid);
      metaData[10].setValidators([Validators.required]); //lot
      metaData[12].setValidators([Validators.required]); //tradePrice
      metaData[18].setValidators([Validators.required]);  //clearingBroker
      metaData[19].setValidators([Validators.required]);  //executionBroker
      let obj: {
        futureIndex: any,
        company: any,
        profitcenter: any,
        traderName: any,
        metadata: Tcolumn[],
        values: any[],
        expanded: boolean,
        newRows: any[],
        refreshAddNewRow: boolean,
        refreshFormGroup: boolean
      } = {
        futureIndex: futureIndex,
        company: company,
        profitcenter: profitcenter,
        traderName: traderName,
        metadata: metaData,
        values: [],
        expanded: true,
        newRows: next,
        refreshAddNewRow: true,
        refreshFormGroup: false
      };
      _this.combinationAdded.push(combination);
      // @ts-ignore
      _this.columnMaps.set(combination, obj);
    } else {
      _this.columnMaps.get(combination).newRows = next;
      _this.columnMaps.get(combination).refreshAddNewRow = true;
      _this.columnMaps.get(combination).refreshFormGroup = true;
    }
    _this.addExpiryMonth(futureIndex, combination);
    // _this.addButtonDisable = !mode;
    _this.visible = true;
  }

  // on select of future Index get expiry Month
  addExpiryMonth(value, combination) {
    if (!this.commonService.isEmpty(value)) {
      let _this = this;
      this.commonService.getJSONByURL(environment.base_url_new + '/api-bm/api/counterparty/v1/getbrokercounterpartylistfrommetadata?tenantId=' + this.commonService.getFromStorage('tenantId') + '&futureIndex=' + value).subscribe((next: any) => {
        let clearingBrokerList  = [new KeyValue()];
        let executionBrokerList  = [new KeyValue()];
        if(next['Clearing Broker'] !== null && next['Clearing Broker'] !== undefined) {
          clearingBrokerList = _this.commonService.createListFromObject(next['Clearing Broker'], 'code', 'code', true);
        }
        if(next['Execution Broker'] !== null && next['Execution Broker'] !== undefined) {
          executionBrokerList = _this.commonService.createListFromObject(next['Execution Broker'], 'code', 'code', true);
        }
        _this.columnMaps.get(combination).metadata[19].setListofValues(clearingBrokerList);
        _this.columnMaps.get(combination).metadata[20].setListofValues(executionBrokerList);
      });
    }
  }

  //on select of expiry month get Expiry Date
  onChangeValue(value: any, combination: any) {
    let _this = this;
    let field = value['col'];
    let index = value['rowIndex'];
    let lmeValues = value['rowData']['expiryMonth'];
    if ((field === 'expiryMonth') && !this.commonService.isEmpty(value['rowData'][field])) {
      this.commonService.getJSONByURL(environment.base_url + '/api/futureoptionperiodcalendar/v1/getbynameandticker?name=' + value['rowData']['futureIndex'] + '&ticker=' + value['rowData']['expiryMonth'] + '&tenantId=' + this.commonService.getFromStorage('tenantId')).subscribe(next => {
        if (lmeValues === 'Cash' || lmeValues === '3-Month' || lmeValues === '6-Month') {
          let date = this.getExpiryDate(value);
          value['rowData']['expiryDate'] = new Date(date);
          if (this.futureFormGroup !== undefined && this.futureFormGroup !== null) {
            this.futureFormGroup.controls['expiryDate' + index].enable();
          }
        } else {
          value['rowData']['expiryDate'] = new Date(next['expiryDate']);
          if (this.futureFormGroup !== undefined && this.futureFormGroup !== null) {
            this.futureFormGroup.controls['expiryDate' + index].disable();
          }
        }
      });
    }
    if ((field === 'clearingBroker' || field === 'executionBroker') && !this.commonService.isEmpty(value['rowData'][field])) {
      this.commonService.getJSONByURL(environment.base_url + '/api/counterparty/v1/getbrokerageandankdetails?tenantId=' + this.commonService.getFromStorage('tenantId') + '&name=' + value['rowData'][field] + '&priceIndex=' + value['rowData']['futureIndex']).subscribe(next => {
        if (next['brokerType'] === 'Clearing Broker') {
          value['rowData']['clearingBrokerAccount'] = next['bankAccountNumber'];
          //value['rowData']['clearingFee'] = next['fee'];
        } else {
          //value['rowData']['brokerageFee'] = next['fee'];
        }
      });
    }
  }

  getExpiryDate(value: any) {
    let expiryMonth = value['rowData']['expiryMonth'];
    let date = value['rowData']['tradeDate'];
    if (expiryMonth !== undefined || expiryMonth !== null) {
      if (expiryMonth === '3-Month') {
        date = this.commonService.addDaysInDate(date, 90);
      } else if (expiryMonth === '6-Month') {
        date = this.commonService.addDaysInDate(date, 180);
      } else if (expiryMonth === 'Cash') {
        date = this.commonService.addDaysInDate(date, 1);
      }
    }
    return date;
  }

  onExpand(combination: any) {
    this.columnMaps.get(combination).expanded = !this.columnMaps.get(combination).expanded;
  }

  onAddNewRowComplete(value: any, combination: any) {
    this.columnMaps.get(combination).refreshAddNewRow = false;
  }

  onRefreshFromGroupComplete(combination: any) {
    this.columnMaps.get(combination).refreshFormGroup = false;
  }

  //Save Future Trade
  onSave() {
    let _this = this;
    let val: any[] = [];
    this.combinationAdded.forEach(function(combination) {
      _this.columnMaps.get(combination).values.forEach(function(rowData) {
        if (rowData['tradeDate'] !== undefined && rowData['tradeDate'] !== null &&
            rowData['lot'] !== undefined && rowData['lot'] !== null && rowData['lot'].toString().length > 0 &&
            rowData['tradePrice'] !== undefined && rowData['tradePrice'] !== null && rowData['tradePrice'].toString().length > 0) {
          val.push(rowData);
        }
      });
    });
    const updatedVal:any = val.map(item => {
      const [futureIndex, ...rest] = item.futureIndex.split('-');
      const key = futureIndex[0];
      return { ...item, futureIndex, key };
    });
    if (updatedVal.length > 0) {
      let valid:boolean = true;
      if (updatedVal.some(item => item.clearingBroker === null)) {
        this.showToast('Clearing Broker is mandatory');
        return;
      }
      updatedVal[0].matchLot=0.00;
      updatedVal[0].parentRowId='';
      updatedVal[0].rolloverStatus=false;
      updatedVal[0].rolloverSpread=0.00;
      updatedVal[0].tradeId='';
      updatedVal[0].nettingStatus="Not Netted";
      updatedVal[0].expiredStatus="No";
      let futureSavepayload = {
        tenantId: this.commonService.getFromStorage('tenantId'),
        updatedBy: this.commonService.getFromStorage('userName'),
        createdBy: this.commonService.getFromStorage('userName'),
        futureTradeList: updatedVal
      };
      futureSavepayload.futureTradeList.forEach(data=>{
        if(data.tradeDate){
          data.tradeDate = this.datePipe.transform(data.tradeDate,"yyyy-MM-ddTHH:mm:ss.SSS",Intl.DateTimeFormat().resolvedOptions().timeZone+'Z');
        }
        if(data.expiryDate){
          data.expiryDate = this.commonService.convertDatetoUTC(data.expiryDate,true);
        }
      })
      this.commonService.post(environment.base_url + '/api/futureTrade/v1/saveFutureTrade?tenantId=' + this.commonService.getFromStorage('tenantId'), futureSavepayload).subscribe(next => {
        this.displayOnAdd = false;
        this.visible = false;
        this.editMode = true;
        this.refreshGrid = true;
        this.showToast('Futures Trade saved successfully.');
      }, (error: HttpErrorResponse) => {
        this.messageService.add({severity: 'error', summary: error.error});
      });
    } else {
      this.showToast('Required Fields are blank');
    }
  }

  //Click of Edit button
  openClickEdit(value,edit:boolean = false){
    this.onclickEditUnique = true;
    this.addButtonDisable = true;
    let rowData = value['rowData'];
    let canEditStatus;
    if(this.editMode) {
      let status = rowData.nettingStatus;
      if(edit){
        canEditStatus = ['not netted'].includes(status.toLowerCase());
      }else {
        canEditStatus = false;
      }
      this.editMode = edit?canEditStatus:edit;
    }
    this.displayOnAdd = false;
    if(!this.editMode){
      this.futureTradeForm.controls['traderName'].disable();
      this.futureTradeForm.controls['company'].disable();
      this.futureTradeForm.controls['profitcenter'].disable();
      this.futureTradeForm.controls['futureIndex'].disable();
      this.futureTradeForm.controls['ticker'].disable();
      this.futureTradeForm.controls['entries'].disable();
    }else{
      this.futureTradeForm.controls['traderName'].enable();
      this.futureTradeForm.controls['company'].enable();
      this.futureTradeForm.controls['profitcenter'].enable();
      this.futureTradeForm.controls['futureIndex'].enable();
      this.futureTradeForm.controls['ticker'].enable();
      this.futureTradeForm.controls['entries'].enable();
    }
    this.onClickAdd(false);
    this.header = 'Trade Id : ' + rowData['tradeId'];
    this.saveButtonLabel = 'Update';
    this.formControlService.setValue('entries', 1);
    this.addContentBox(rowData['futureIndex'],rowData['expiryMonth'], rowData['company'], rowData['profitcenter'], rowData['traderName'], [rowData],true);
  }

  //Click of Duplicate Button
  onDuplicate(value: any) {
    let rowData = value;
    rowData['tradeId'] = '';
    rowData['uuid'] = '';
    this.onClickAdd(false);
    this.header = 'New Futures Trade';
    this.saveButtonLabel = 'Save';
    this.formControlService.setValue('entries', 1);
    this.addContentBox(rowData['futureIndex'],rowData['expiryMonth'], rowData['company'], rowData['profitcenter'], rowData['traderName'], [rowData],false);
  }

  onClose() {
    this.displayOnAdd = false;
    this.editMode = true;
    this.visible = false;
  }

  toggleChange(value: any) {
    if(value !== undefined && value !== null){
      if(value) {
        this.expiryMonthFilter = false;
        this.getcardByExpiryMonth();
      } else {
        this.expiryMonthFilter = true;
        this.getFutureTrade();
      }
    }
  }

  getcardByExpiryMonth() {
    let _this = this;
    let url = '/api/futureTrade/v1/getfuturetradebyfutureindex' + '?tenantId=' + this.commonService.getTenantId() + '&isExpiryMonth=true';
    let filterPayload = {};
    let expiryMonth = '';
    let fromDate = '';
    let toDate = '';
    if (this.selectedPreset['type'] !== undefined && this.selectedPreset['type'] !== null && this.selectedPreset['type'] === 'L') {
      expiryMonth = this.selectedPreset['selectedValue'];
    } else {
      fromDate = this.selectedPreset['startDate'];
      toDate = this.selectedPreset['endDate'];
    }
    filterPayload = {
      clearingBroker: this.filterForms.controls['brokerList'].value,
      futureIndex: this.filterForms.controls['futureIndexList'].value,
      profitcenter: this.filterForms.controls['profitCenterList'].value,
      status:this.nettingStatus,
      expirymonth: expiryMonth,
      tradeId:this.filterForms.controls['tradeIdList'].value==null?'All':this.filterForms.controls['tradeIdList'].value.tradeId,
      fromDate: fromDate,
      toDate: toDate
    };
    this.futureTrade = [];
    this.futureValues = [];
    this.commonService.post(environment.base_url + url, filterPayload).subscribe((next: any) => {
      _this.futureTrade = next;
      _this.futureTrade.forEach(function(futurePos: any) {
        _this.futureValues = [..._this.futureValues, ...futurePos['futureTradesList']];
      });
    });
  }

  onClickInfo(value: any) {
    this.commonService.getJSONByURL(environment.base_url + '/api/futureTrade/v1/getfuturedetailsbyfutureindex?tenantId=' +
        this.commonService.getTenantId() + '&futureIndex=' + value).subscribe((next: any) => {
      this.valueForInfo = next;
      this.valueForInfoHeader = value;
      this.futureInfoDisplay = true;
    });
  }

  onCloseForm() {
    this.futureInfoDisplay = false;
  }

  onChangeFutureIndex(value:any) {
    let selectedFutureIndex=this.futureTradeForm.get('futureIndex').value;
    this.commonService.getJSONByURL(environment.base_url+'/api/futureTrade/v1/getfutureproductcodevalueforexchangecode?tenantId='+this.commonService.getFromStorage('tenantId')+'&exchangeCode='+selectedFutureIndex).subscribe((next:any)=>{
      let list:any[] =[];
      list.push(new KeyValue());
      next.forEach(function (obj) {
        list.push(new KeyValue(obj,obj));
      });
      this.tickerList=list;
    })
  }


  onNettingStatusChange(value:any) {
    if(value){
      this.refreshGrid= true;
    }
  }
  cancel() {    
    this.deleteDisplay = false;
  }

  onRowDelete() {    
    if(this.selectedRows[0].nettingStatus != "Not Netted"){
      this.showToast('Cannot Delete the Selected Trade because it is already Netted', 'error');
      return;
    }
    let _this = this; 
    this.confirmationService.confirm({
      message: 'Are you sure Do you want to Void the Future Trade?',
      accept: () => {
        this.http.delete(environment.base_url + `/api/futureTrade/v1/deleteFutureTrade?tradeId=${this.selectedRows[0].tradeId}`).subscribe(data => {
              this.showToast('Future Trade Void successfully for the Trade Id- ' + this.selectedRows[0].tradeId);              
              this.deleteDisplay = false;
              this.getFuture(true);
              // this.refreshGrid = true;
          }, (error: HttpErrorResponse) => {
              _this.messageService.add({
                  severity: 'error',
                  summary: _this.commonService.getHttpErrorMessage(error, 'Future Trade')
              });              
          });
      }, reject: () => {
          this.deleteDisplay = true;
      }
  });
  }

  onSelectCB(rowData) {   
    if(rowData.length == 1){      
      this.deleteDisable = rowData[0].nettingStatus == 'Void';
      this.deleteDisplay = !this.deleteDisable;
    }else{
      this.deleteDisplay = false;
    }  
    this.selectedRows = rowData;
  }

}
