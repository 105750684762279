<div class="sectionCardTop">
    <div class="actionPane">
        <div class="screenDetails">
            <img [src]="'../../../assets/images/svg/openFutureDashboard.svg'"/>
            <p class="screenTitle" >Future's Position Dashboard</p>
        </div>

        <div *ngIf="tabValue == 'planned'" class="rightInfo">
            Total {{planData.length}} Plans
        </div>
    </div>

</div>
<div>
        <div class="sectionCard borderRadius">
            <div class="summaryRowWrapper">
                <div class="summaryTitleDiv"><p class="filterBoxHeading">Futures Summary</p></div>
                <div [formGroup]="filterForms" class="dropdownRow">
                    <p-dropdown  style="margin-left: 240px; margin-right: 3px;" placeholder="All Broker List" formControlName="allBrokerList" (onChange)="onFilterValueChange()" [options]="allBrokerList"></p-dropdown>
                    <p-dropdown  style=" margin-right: 3px;" placeholder="Future Index"  (onChange)="onIndexChange($event)" formControlName="futureIndex" [options]="futureIndex"></p-dropdown>
                    <p-dropdown  style=" margin-right: 3px;" placeholder="Expiry Month" formControlName="expiryMonth" (onChange)="onFilterValueChange()" [options]="expiryMonth"></p-dropdown>
                    <p-dropdown  style=" margin-right: 3px; margin-right: 6px;" placeholder="Profit Center" formControlName="profitCenter" (onChange)="onFilterValueChange()" [options]="profitCenter"></p-dropdown>
                    <div style="margin-right: -29px;border: 1px solid #a6a6a6;border-radius: 5px;  margin-right: 3px;" class="calenderCont">
                        <div class="calender calenderfix" (click)="toggleDateRange()">
                            <i class="fa fa-calendar" style="margin-left: 5px;align-self: center;font-size: 12px;margin-left: 6px;user-select: none;"></i>
                            <p>{{getPresetTitle()}}</p>
                        </div>
                        <obligation-date-range *ngIf="showDateRange"
                                               [preDefinedRanges]="presetList"
                                               [selectedPresetObject]="selectedPreset"
                                               [selectedPreset]="selectedPreset['title']"
                                               (onCancel)="onCancel($event)"
                                               (onPresetSelected)="onPresetChange($event)">

                        </obligation-date-range>
                    </div>

                </div>
            </div>


            <div class="content">
                <div class="cardlist row">

                    <app-dashboard-card
                            [cardId]="'LONG_FUTURE_POSITION'"
                            style="margin-right: 0.5%;width: 24.5%; margin-left: 15px;padding-top: 8px;"
                            [cardHeader]="'Long Future Position'"
                            [cardBorderColor]="'#fd5e7e'"
                            [showCustoms]="false"
                            [disableClick]="true"
                            [showStats]="false" [count1]="summaryData['totalLongLotSize']">

                    </app-dashboard-card>
                    <app-dashboard-card
                            [cardId]="'SHORT_FUTURE_POSITION'"
                            style="margin-right: 0.5%;width: 24.5%;padding-top: 8px"
                            [cardHeader]="'Short Future Position'"
                            [cardBorderColor]="'#63c630'"
                            [showCustoms]="false"
                            [disableClick]="true"
                            [showStats]="false" [count1]="summaryData['totalShortLotSize']">

                    </app-dashboard-card>
                    <app-dashboard-card
                            style="margin-right: 0.5%;width: 24.5%;padding-top: 8px"
                            [cardId]="'NET_OPEN_FUTURE_POSITION'"
                            [cardHeader]="'Net Open Future Position'"
                            [cardBorderColor]="'#f19202'"
                            [showCustoms]="false"
                            [disableClick]="true"
                            [showStats]="false" [count1]="summaryData['totalDifference']">

                    </app-dashboard-card>
                </div>
            </div>
        </div>

</div>


<div class="sectionCard borderRadius">
    <div class="toolbarDiv">
        <ctrm-button (onClick)="refreshFutureBuyAndSell()" icon="fas fa-redo" label="Refresh"></ctrm-button>
        <div class="float-right">
        <ctrm-button [disabled]="selectedPlans.length === 0 || selectedPlans.length > 1" icon="fas fa-file-export"
                     label="Export"></ctrm-button>
        <ctrm-button [disabled]="selectedPlans.length === 0 || selectedPlans.length > 1" icon="fas fa-print"
                     label="Print"></ctrm-button>
        <ctrm-button icon="fas fa-sliders-h" (click)="display = true" label="Preferences"></ctrm-button>

        </div>
    </div>
    <div>
            <div style="display: flex; margin-top:0;">
                <div style="width: 50%">

                    <div style="display: flex">
                        <p class="tableHeading">Long</p>
                        <p class="totalObl">Total Trades {{buyValue.length}}</p>
                    </div>
                </div>
                <div style="width: 50%">
                    <div style="display: flex; margin-left: -8px">
                        <p class="tableHeading">Short</p>
                        <p class="totalObl">Total Trades {{sellValue.length}}</p>
                    </div>
                </div>
            </div>


        <!--Buy Sell Grid Section-->
        <div class="buySellContainer">
            <ctrm-planning-grid [cols]="sc"
                                [filterValue]="globalFilterValue"
                                [noDataLabel]="'No Open Future Trade available'"
                                [reset]="reset"
                                [routeToLable]="'Create/Confirm Physical Trade'"
                                [value]="buyValue" style="width: 49.9%" [heightToAdd]="20"></ctrm-planning-grid>
            <div class="dividerLine"></div>
            <ctrm-planning-grid [cols]="sc"
                                [filterValue]="globalFilterValue"
                                [gridPosition]="'right'" [noDataLabel]="'No Open Future Trade available'"
                                [reset]="reset"
                                [routeToLable]="'Create/Confirm Physical Trade'"
                                [value]="sellValue" style="width: 49.9%" [heightToAdd]="20"></ctrm-planning-grid>

        </div>
    </div>
</div>





<p-sidebar (onHide)="toggleDisplay()" [(visible)]="display"
           [baseZIndex]="25"
           [style]="{minWidth:'40%',maxHeight:'100%',maxWidth:'100%',marginTop:'4.5%'}" appendTo="body"
           position="right">
    <div class="divBox">
        <div>
            <p-tabView>
                <p-tabPanel *ngIf="showColumns" header="Columns">
                    <p-listbox (ngModelChange)="onChange($event)"  [(ngModel)]="sc" [multiple]="true"
                                [options]="columns" optionLabel="field" checkbox="checkbox"></p-listbox>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
</p-sidebar>
