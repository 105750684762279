import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NG_VALUE_ACCESSOR} from "@angular/forms";

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NumberSpinnerComponent),
  multi: true
};
@Component({
  selector: 'app-number-spinner',
  templateUrl: './number-spinner.component.html',
  styleUrls: ['./number-spinner.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class NumberSpinnerComponent implements OnInit,OnChanges {

  @Input('value') val: number = 0;
  @Input() min:number = 0;
  @Input() max:number = 10;
  @Input() disabled:boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  get value() {
    return this.val;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  onTouched: any = () => {
  };

  onChange(val) {
    this.val = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
    }
  }

  increase() {
    if(this.max === null || this.max === undefined || (this.value+1) > this.max) {
      return;
    }
    this.value = this.value + 1;
  }

  decrease() {
    if(this.min === null || this.min === undefined || (this.value-1) < this.min) {
      return;
    }
    this.value = this.value - 1;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['min']) {
      this.min = changes['min'].currentValue;
      if(this.min === null || this.min === undefined) {
        this.min = 0;
      }
    }
    if(changes['val']) {
      this.value = changes['val'].currentValue;
      if(this.value === null || this.value === undefined) {
        this.value = 0;
      }
    }
    if(changes['max']) {
      this.max = changes['max'].currentValue;
      if(this.max === null || this.max === undefined) {
        this.max = 10;
      }
    }
  }

}
