import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Tcolumn} from "../../grid/tcolumn.model";
import {MessageService} from "primeng";

@Component({
  selector: 'xceler-expandable-planning-grid',
  templateUrl: './xceler-expandable-planning-grid.component.html',
  styleUrls: ['./xceler-expandable-planning-grid.component.css']
})
export class XcelerExpandablePlanningGridComponent implements OnInit, OnChanges,AfterViewInit {

  @Input() leftTableMetadata: Tcolumn[] = [];
  @Input() rightTableMetadata: Tcolumn[] = [];
  @Input() leftValues: any[] = [];
  @Input() leftTableHeader: string = 'Table Left';
  @Input() leftMatchHeader: string = 'Match Left';
  @Input() rightMatchHeader: string = 'Match Right';
  @Input() rightTableHeader: string = 'Table Right';
  @Input() leftValueTotalFormat: string = 'Total {{length}} Data.';
  @Input() rightValueTotalFormat: string = 'Total {{length}} Data.';
  @Input() rightValueTotalLots: string = 'Total {{length}} Lots.';
  @Input() leftValueTotalLots: string = 'Total {{length}} Lots.';
  @Input() rightValues: any[] = [];
  @Input() leftCategorizeBy: any;
  @Input() rightCategorizeBy: any;
  @Input() matchingButton: any[];
  leftCategorizedValueArrays: Map<string, { values: any[], selectedValues: any[] }> = new Map<string, { values: any[], selectedValues: any[] }>();
  rightCategorizedValueArrays: Map<string, { values: any[], selectedValues: any[] }> = new Map<string, { values: any[], selectedValues: any[] }>();
  @Input() leftMatchFieldName: any;
  @Input() leftBalanceFieldName: any;
  @Input() rightMatchFieldName: any;
  @Input() rightBalanceFieldName: any;
  @Output() onClickSubmit = new EventEmitter();
  boxHeight: string = '';
  @ViewChild('mainBox') mainBox: HTMLDivElement;
  leftSingleSelection: boolean = false;
  rightSingleSelection: boolean = false;
  @Input() checkBoxSelection: boolean = false;
  @Input() readOnly: boolean = false;
  private leftSelectedCount = 0;
  private rightSelectedCount = 0;
  private leftSelectedCombination: any = '';
  private rightSelectedCombination: any = '';
  matchedLeftLot=0;
  matchedRightLot=0;
  selectedCombinationsLeft:any[]=[];
  selectedCombinationsRight:any[]=[];
  nettingbuttonDisbaled:boolean=true;
  @Input() matchLotTotalUpdate:boolean;
  @Output()triggerMatchedupdateLot = new EventEmitter();
  @Output() balanceExceedError = new EventEmitter();
  matchExceed:any;



  constructor(public messageService: MessageService,
              private changeDetectorRef:ChangeDetectorRef) {
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
  }

  categorizeLeftNow(values: any[], categorizeBy: any) {
    let _this = this;
    this.leftCategorizedValueArrays = new Map<string, { values: any[], selectedValues: any[] }>();
    values.forEach(function (value) {
      let combination: string = _this.getCombination(value, categorizeBy);
      if (_this.leftCategorizedValueArrays.has(combination)) {
        _this.leftCategorizedValueArrays.get(combination).values.push(value);
      } else {
        _this.leftCategorizedValueArrays.set(combination, {values: [value], selectedValues: []});
      }
    });
    this.resetSelection();

  }

  categorizeRightNow(values: any[], categorizeBy: any) {
    let _this = this;
    this.rightCategorizedValueArrays = new Map<string, { values: any[], selectedValues: any[] }>();
    values.forEach(function (value) {
      let combination: string = _this.getCombination(value, categorizeBy);
      if (_this.rightCategorizedValueArrays.has(combination)) {
        _this.rightCategorizedValueArrays.get(combination).values.push(value);
      } else {
        _this.rightCategorizedValueArrays.set(combination, {values: [value], selectedValues: []});
      }
    });
    this.resetSelection();

  }

  getCombination(rowData, categorizeBy: any) {
    let combination: string = '';
    Object.keys(categorizeBy).forEach(function (category) {
      combination += rowData[category] + '_';
    });
    return combination;
  }

  resetSelection() {
    this.leftSelectedCombination = '';
    this.leftSelectedCount = 0;
    this.rightSingleSelection = false;
    this.rightSelectedCombination = '';
    this.rightSelectedCount = 0;
    this.leftSingleSelection = false;

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['leftValues']) {
      this.categorizeLeftNow(changes['leftValues'].currentValue, this.leftCategorizeBy);
    }

    if (changes['rightValues']) {
      this.categorizeRightNow(changes['rightValues'].currentValue, this.rightCategorizeBy);
    }
    if(changes['matchLotTotalUpdate'] && changes['matchLotTotalUpdate'].currentValue){
      this.matchedLeftLot = 0;
      this.matchedRightLot = 0;
      this.leftCategorizedValueArrays = new Map<string, { values: any[], selectedValues: any[] }>();
      this.rightCategorizedValueArrays = new Map<string, { values: any[], selectedValues: any[] }>();
      this.selectedCombinationsRight =[];
      this.selectedCombinationsLeft = [];
      this.triggerMatchedupdateLot.emit();

    }
  }

  getLeftValue() {
    let str = '';
    str = this.leftValueTotalFormat;
    str = str.replace(new RegExp('{{length}}', 'g'), this.leftValues.length + '');
    return str;
  }

   getLeftValueLots() {
     let lots=0;
     this.leftValues.forEach(data=>{
       lots+=data.balanceLot
     })
     let str = '';
     str=this.leftValueTotalLots;
     str = str.replace(new RegExp('{{length}}', 'g'), lots + '');
     return str;
  }

  getRightValueLots(){
    let lots=0;
    this.rightValues.forEach(data=>{
      lots+=data.balanceLot
    })
    let str = '';
    str=this.rightValueTotalLots;
    str = str.replace(new RegExp('{{length}}', 'g'), lots + '');
    return str;
  }

  getRightValue() {
    let str = '';
    str = this.rightValueTotalFormat;
    str = str.replace(new RegExp('{{length}}', 'g'), this.rightValues.length + '');
    return str;
  }

  @HostListener('window:resize', [])
  onResize() {
    this.calculateHeight(this.mainBox);
  }

  calculateHeight(mainBox: HTMLDivElement) {
    if (mainBox !== undefined && mainBox !== null) {
      let offsetTop = mainBox.offsetTop;
      let offsetHeight = window['visualViewport'].height;
      this.boxHeight = (offsetHeight - (offsetTop + 100)) + 'px';
    }
  }

  submitNow() {
    let leftList = this.getSelectedList(this.leftCategorizedValueArrays);
    let rightList = this.getSelectedList(this.rightCategorizedValueArrays);
    this.onClickSubmit.emit({leftSelectedList: leftList, rightSelectedList: rightList,visible:true});
  }

  getSelectedList(map: Map<string, { values: any[], selectedValues: any[] }>) {
    let keys: any[] = Array.from(map.keys());
    let list = [];
    keys.forEach(function (key) {
      list = list.concat(map.get(key).selectedValues);
    });
    return list;
  }

  onRowSelectionChange(values: any, position: string = 'left', combination: string = '') {
    if (position === 'left') {
      this.leftCategorizedValueArrays.get(combination).selectedValues = values['rows'];
      if(values['rows'].length > 0 && !this.selectedCombinationsLeft.includes(combination)) {
        this.selectedCombinationsLeft.push(combination);
      } else if(values['rows'].length == 0 && this.selectedCombinationsLeft.includes(combination)) {
        this.selectedCombinationsLeft.splice(this.selectedCombinationsLeft.indexOf(combination),1);
      }
    } else {
      this.rightCategorizedValueArrays.get(combination).selectedValues = values['rows'];
      if(values['rows'].length > 0 && !this.selectedCombinationsRight.includes(combination)) {
        this.selectedCombinationsRight.push(combination);
      } else if(values['rows'].length == 0 && this.selectedCombinationsRight.includes(combination)) {
        this.selectedCombinationsRight.splice(this.selectedCombinationsRight.indexOf(combination),1);
      }
    }
    if (this.checkBoxSelection === true) {
      this.updateSelectionMethod(values, position, combination);
    }
    this.updateTotal(position);
  }

  updateSelectionMethod(value: any, position: any = 'left', combination: string = '') {
    if (position === 'left') {
      if (value['event'] === 'select') {
        this.leftSelectedCount++;
      } else {
        this.leftSelectedCount--;
      }
      if (this.leftSelectedCount === 1) {
        this.leftSelectedCombination = combination;
        this.rightSingleSelection = false;
      } else if (this.leftSelectedCount > 1) {
        if (!this.leftSingleSelection) {
          this.rightSingleSelection = true;
        } else {
          this.leftSelectedCount--;
          this.revertLastSelected(combination, position);
          this.showToast();
        }
      } else {
        this.rightSingleSelection = false;
      }
    } else {
      if (value['event'] === 'select') {
        this.rightSelectedCount++;
      } else {
        this.rightSelectedCount--;
      }
      if (this.rightSelectedCount === 1) {
        this.rightSelectedCombination = combination;
        this.leftSingleSelection = false;
      } else if (this.rightSelectedCount > 1) {
        if (!this.rightSingleSelection) {
          this.leftSingleSelection = true;
        } else {
          this.rightSelectedCount--;
          this.revertLastSelected(combination, position);
          this.showToast();
        }
      } else {
        this.leftSingleSelection = false;
      }
    }
  }

  revertLastSelected(combination: string, position: any) {
    if (position === 'left') {
      if (combination === this.leftSelectedCombination) {
        this.leftCategorizedValueArrays.get(combination).selectedValues.splice(1, 1);
      } else {
        this.leftCategorizedValueArrays.get(combination).selectedValues.splice(0, 1);
      }
    } else {
      if (combination === this.rightSelectedCombination) {
        this.rightCategorizedValueArrays.get(combination).selectedValues.splice(1, 1);
      } else {
        this.rightCategorizedValueArrays.get(combination).selectedValues.splice(0, 1);
      }
    }
  }

  showToast() {
    this.messageService.add({
      severity: 'warn',
      summary: 'You cannot match multiple long to multiple short or multiple short to multiple long'
    });
  }

  updateTotal(dir:any) {
    if(dir === 'left') {
      this.matchedLeftLot = this.calculateTotoal(this.selectedCombinationsLeft,this.leftCategorizedValueArrays);
    } else {
      this.matchedRightLot = this.calculateTotoal(this.selectedCombinationsRight,this.rightCategorizedValueArrays);
    }
     if(this.matchedRightLot == this.matchedLeftLot){
       this.nettingbuttonDisbaled = false;
     }else {
       this.nettingbuttonDisbaled = true;
     }
  }
  calculateTotoal(combList:any[],map:Map<string, { values: any[], selectedValues: any[] }>){
    let matchedLot=0;
    combList.forEach(combination=>{
      matchedLot += map.get(combination).selectedValues.map(item => item.matchLot).reduce((sum,current) => sum + current,0);
    })
    return matchedLot;
  }

  onExceed(value: any) {
    this.matchExceed = value
    this.balanceExceedError.emit(value);
  }
}
