<p-dialog (onHide)="closeCircle()" [(visible)]="visible" [draggable]="false" [modal]="true"
          [closeOnEscape]="false"
          [closable]="false"
          [resizable]="false" [style]="{width: '65%'}">
    <p-header>
        <div style="display: flex; padding-left: 16px;">
            <i class="fas fa-angle-left headerIconStyle"></i>
            <div class="divBox">
                <p class="header">Inter Company Trade</p>
                <p class="description"></p>
            </div>
            <i (click)="closeCircle()" class="fas fa-times closeIconStyle"></i>
        </div>
    </p-header>
    <div style="box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.160784);padding-top:5%;padding-bottom: 5%;">
        <div class="contentBox">
            <div style="display: inline-flex">
                <div class="staticDatabox">
                    <p class="infoHeader">Trade ID</p>
                    <p class="infoValue">{{obligation['tradeId']}}</p>
                </div>
                <div class="staticDatabox">
                    <p class="infoHeader">Counterparty</p>
                    <p class="infoValue">{{obligation['counterparty']}}</p>
                </div>
                <div class="staticDatabox">
                    <p class="infoHeader">Quantity</p>
                    <p class="infoValue">{{obligation['tradeQuantity']}}</p>
                </div>
                <div class="staticDatabox">
                    <p class="infoHeader">Shipment Month</p>
                    <p class="infoValue">{{commonService.getFormattedDate(obligation['deliveryEndDate'],environment.dateMonthFormat)}}</p>
                </div>
                <div class="staticDatabox">
                    <p class="infoHeader">Commodity</p>
                    <p class="infoValue">{{obligation['commodity']}}</p>
                </div>
            </div>
        </div>
        <div class="contentBox">
            <div style="display: inline-flex">
                <div class="staticDatabox">
                    <p class="infoHeader">Incoterm</p>
                    <p class="infoValue">{{obligation['incoterm']}}</p>
                </div>
                <div class="staticDatabox">
                    <p class="infoHeader">Payment Term</p>
                    <p class="infoValue">{{obligation['paymentTerm']}}</p>
                </div>
                <div class="staticDatabox">
                    <p class="infoHeader">Load Port</p>
                    <p class="infoValue">{{obligation['loadLocation']}}</p>
                </div>
                <div class="staticDatabox">
                    <p class="infoHeader">Unload Port</p>
                    <p class="infoValue">{{obligation['unloadLocation']}}</p>
                </div>
                <div class="staticDatabox">
                    <p class="infoHeader">Trader Name</p>
                    <p class="infoValue">{{obligation['trader']}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="valueBox">
        <div class="valueDatabox" >
            <p class="infoHeader">InterCompany Name</p>
            <div class="inputbox">
                <p-dropdown
                        [(ngModel)]="company" (ngModelChange)="checkFields()"
                        [options]="listOfInterCompany"
                        appendTo="body"
                        placeholder="Select">
                </p-dropdown>
            </div>
        </div>
        <div class="valueDatabox">
            <p class="infoHeader">Margin</p>
            <div class="inputbox">
                <input class="form-control form-control-sm inputClass"
                       type="number"
                       [(ngModel)]="transferPrice"
                       (ngModelChange)="checkFields()"
                       placeholder="Enter Margin"
                       pInputText>
            </div>
        </div>
        <div class="valueDatabox" >
            <p class="infoHeader">Incoterm</p>
            <div class="inputbox">
                <p-dropdown
                        [(ngModel)]="incoterm"
                        [options]="listOfIncoterm"
                        appendTo="body"
                        (ngModelChange)="checkFields()">
                </p-dropdown>
            </div>
        </div>
        <div class="valueDatabox" >
            <p class="infoHeader">Transfer Price Currency</p>
            <div class="inputbox">
                <p-dropdown
                        [(ngModel)]="intercompanyCurrency"
                        [options]="commonService.getCurrencyList()"
                        appendTo="body"
                        (ngModelChange)="checkFields()"
                        placeholder="Select Currency">
                </p-dropdown>
            </div>
        </div>

    </div>

    <p-footer>
        <div style="display: inline-flex">
            <button class="cancelBtn" (click)="closeCircle()">Cancel</button>
            <xceler-progress-button
                    (onError)="showError($event)"
                    [disabled]="!allFieldsFilled"
                    (onLoadDone)="onSubmit($event)"
                    [buttonIdleTitle]="'Apply'"
                    [buttonLoadingTitle]="'Processing'"
                    [buttonStyle]="{margin:'0px',minHeight:'35px',padding:'0px',paddingLeft:'10px',paddingRight:'10px'}"
                    [callApiObjectFunction]="CreateApiFunction">
            </xceler-progress-button>
        </div>
    </p-footer>
</p-dialog>
