import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CommonService} from '../../services/common.service';
import {environment} from '../../../environments/environment';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'doc-viewer',
  templateUrl: './doc-viewer.component.html',
  styleUrls: ['./doc-viewer.component.css']
})
export class DocViewerComponent implements OnInit, OnChanges {

  @Input() docUrl: string = '';
  @Input() modalState: boolean = false;
  @Output() OnClose = new EventEmitter();
  @Input() fileName: string = '';
  @Input() viewer: string = 'google';
  officeFormats: string[] = ['xls', 'xlsx'];
  isLoading: boolean = false;
  @Input() errorMessage:string;
  previewbytes: string;

  constructor(private commonService:CommonService) {
  }

  ngOnInit() {
  }

  closeModal() {
    this.modalState = false;
    this.OnClose.emit(this.modalState);
  }

  ngOnChanges(changes: SimpleChanges): void {
    let _this =this;
    if (changes['docUrl']) {
      if (changes['docUrl'].currentValue !== undefined && changes['docUrl'].currentValue.length > 0) {
        let serverDoc = changes['docUrl'].currentValue;
        this.previewbytes = serverDoc;
      }
    }
  }
}
