<div style="display: inline-flex;width: 100%">
  <div class="headerBoxPlanning stk">
    <div style="display: flex">
      <p class="tableHeading">{{leftTableHeader}}</p>
      <p class="totalText">{{getLeftValue()}}</p>
      <p class="totalText">{{getLeftValueLots()}}</p>
    </div>
    <div *ngIf="!readOnly" class="titleBox leftTitleBox" style="display: flex;flex-direction: column;">
      <div>
        {{leftMatchHeader}}
      </div>
      <div style="text-align: center">
        {{matchedLeftLot}}
      </div>

    </div>
  </div>
  <div class="headerBoxPlanning stk">
    <div *ngIf="!readOnly" class="titleBox rightTitleBox" style="display: flex;flex-direction: column;">
      <div>
        {{rightMatchHeader}}
      </div>
      <div style="text-align: center">
        {{matchedRightLot}}
      </div>
    </div>
    <div style="display: flex">
      <p class="tableHeading">{{rightTableHeader}}</p>
      <p class="totalText">{{getRightValue()}}</p>
      <p class="totalText">{{getRightValueLots()}}</p>
    </div>
  </div>
</div>
<div #mainBox (inViewportAction)="calculateHeight(mainBox)" [style]="{maxHeight:boxHeight, minHeight:boxHeight}" class="mainBox style-4"
     inViewport>
  <div class="box">
    <xceler-expandable-grid (onRowSelectionChange)="onRowSelectionChange($event,'left',key)"
                            *ngFor="let key of leftCategorizedValueArrays.keys()"
                            [balanceFieldName]="leftBalanceFieldName"
                            [readOnly]="readOnly"
                            [columns]="leftTableMetadata"
                            [headerFields]="leftCategorizeBy"
                            [matchFieldName]="leftMatchFieldName"
                            (onDataUpdate)="updateTotal('left')"
                            [selectedRows]="leftCategorizedValueArrays.get(key).selectedValues"
                            [singleSelectionMode]="leftSingleSelection"
                            [values]="leftCategorizedValueArrays.get(key).values">
    </xceler-expandable-grid>
  </div>
  <div class="divide stk"></div>
  <div class="box">
    <xceler-expandable-grid (onRowSelectionChange)="onRowSelectionChange($event,'right',key)"
                            *ngFor="let key of rightCategorizedValueArrays.keys()"
                            [balanceFieldName]="rightBalanceFieldName"
                            [readOnly]="readOnly"
                            [columns]="rightTableMetadata"
                            (onDataUpdate)="updateTotal('right')"
                            [headerFields]="rightCategorizeBy"
                            [matchFieldName]="rightMatchFieldName"
                            [position]="'right'"
                            [selectedRows]="rightCategorizedValueArrays.get(key).selectedValues"
                            [singleSelectionMode]="rightSingleSelection"
                            [values]="rightCategorizedValueArrays.get(key).values">
    </xceler-expandable-grid>
  </div>
</div>
<div style="text-align: -webkit-center;">
  <button (click)="submitNow()" [disabled]="nettingbuttonDisbaled" class="planScreenBtns">{{matchingButton}}</button>
</div>
