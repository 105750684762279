<div style="margin-top: 10px">
  <form [formGroup]="gridFrmGroup">
    <p-table #dt1 [(selection)]="selectedRow"
             [columns]="columns"
             [reorderableColumns]="true"
             [responsive]="true" [value]="data"
             autoLayout="true" dataKey="rowNum" editMode="row"
             selectionMode="multiple" sortMode="multiple">
      <ng-template let-columns pTemplate="header">
        <tr class="theadS">
          <th class="actionColumn" id="" scope="col" style="background-color: #637484;color: white">
          </th>
          <th *ngFor="let column of columns;index as i" [hidden]="!column['visible']"
              [pSortableColumn]="column['field']" style="background-color: #637484;color: white;min-width: 250px">
            <div (click)="toggleExpand()" *ngIf="i===0" style="display: inline-flex;cursor: pointer;">
              <i [ngClass]="expanded?'fas fa-chevron-circle-down':'fas fa-chevron-circle-right'"
                 style="cursor: pointer;margin-right: 10px;margin-top: 2px"></i>
              <p style="margin: 0;padding: 0;color: rgb(253,255,248);font-size: 12px">{{tableTitle}}</p>
            </div>
            <div *ngIf="getFormula(column.getField()).toLowerCase()==='sum'"> Sum
              :  {{ps.sum(data, column.getField())}}</div>
            <div *ngIf="getFormula(column.getField()).toLowerCase()==='avg'"> Avg
              :  {{ps.average(data, column.getField())}}</div>
          </th>
        </tr>
      </ng-template>
      <ng-template let-columns="columns" let-editing="editing" let-i="rowIndex" let-ri="rowIndex" let-row
                   pTemplate="body">
        <tr (click)="outSelectedRow()" *ngIf="expanded" [pEditableRow]="row" [pSelectableRow]="row" class="otherData">
          <td class="actionData" scope="row" style="padding: 0;min-width: 112px">
            <div>
              <i (click)="onRowEditInit(row)" *ngIf="!editing" class="fas fa-pen editicon" pInitEditableRow
                 pTooltip="Edit"
                 tooltipPosition="bottom">
              </i>
              <i (click)="onRowCopy(row,true)" *ngIf="!editing" class="fas fa-copy copyicon" pTooltip="Copy"
                 tooltipPosition="bottom"></i>
              <i (click)="onRowSave(row, ri)" *ngIf="editing" class="fas fa-save p-1" pSaveEditableRow
                 pTooltip="Save"
                 tooltipPosition="bottom"></i>
              <i (click)="onRowEditCancel(row, ri)" *ngIf="editing" class="fas fa-undo p-1" pCancelEditableRow
                 pTooltip="Undo"
                 tooltipPosition="bottom"></i>
              <i (click)="onRowDelete(row,ri,true)" class="fas fa-minus-circle p-1 deleteicon" pDeleteEditableRow
                 pTooltip="Delete"
                 tooltipPosition="bottom"></i>
            </div>
          </td>
          <td *ngFor="let column of columns" [hidden]="!column['visible']" style="padding: 0;min-width: 250px">
            <p-cellEditor *ngIf="column.isEditable()">
              <ng-template pTemplate="input">
                <input (change)="changeField(ri,column.field,gridFrmGroup.get(column.field+ri).value )"
                       *ngIf="column.getColumnType() == 'T'" formControlName="{{column.field}}{{ri}}"
                       pInputText type="text">
                <input (change)="changeField(ri,column.field,gridFrmGroup.get(column.field+ri).value )"
                       *ngIf="column.getColumnType() == 'N'" formControlName="{{column.field}}{{ri}}"
                       pInputText type="number">
                <input (change)="changeField(ri,column.field,gridFrmGroup.get(column.field+ri).value )"
                       *ngIf="column.getColumnType() == 'P'" formControlName="{{column.field}}{{ri}}"
                       pInputText type="password">
                <p-fileUpload *ngIf="column.getColumnType() == 'F'" mode="basic" name="myfile[]" url="./upload.php">
                </p-fileUpload>
                <p-dropdown (onChange)="changeField(ri,column.field,gridFrmGroup.get(column.field+ri).value )"
                            *ngIf="column.getColumnType() == 'L'"
                            [options]="column.getListOfValues()"
                            formControlName="{{column.field}}{{ri}}">
                </p-dropdown>

                <div>
                  <p-calendar (onChange)="changeField(ri,column.field,gridFrmGroup.get(column.field+ri).value )"
                              *ngIf="column.getColumnType() == 'D'"
                              [appendTo]=""
                              dateFormat="dd/mm/yy"
                              formControlName="{{column.field}}{{ri}}"></p-calendar>
                </div>
                <!--                        <div class="invaliddata">-->
                <!--                          <div>{{getErrorMessage(gridFrmGroup.controls[column.getField()+''+ri],column)}}</div>-->
                <!--                        </div>-->
              </ng-template>
              <ng-template *ngIf="column.getColumnType() == 'L'" pTemplate="output">
                {{getColumnValue(column, row[column.getField()])}}
              </ng-template>
              <ng-template *ngIf="column.getColumnType() == 'T'||column.getColumnType() == 'N'" pTemplate="output">
                {{row[column.getField()]}}
              </ng-template>
              <ng-template *ngIf="column.getColumnType() == 'D' " pTemplate="output">
                {{row[column.getField()]|date:'dd/MM/yy'}}
              </ng-template>
            </p-cellEditor>
            <span (click)="changeRoute(column,row)" *ngIf="!column.isEditable()"
                  [ngClass]="grid.getPrimaryKey()===column.getField()?'link':''">
            {{row[column.getField()]}}
          </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </form>
</div>
