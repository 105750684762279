<div *ngIf="matchingScreenOpen">
  <div class="sectionCardTop">
    <div class="breadcrumbPane">
      <ctrm-breadcrumb [collapsabel]="false"
                       [items]="breadCrumbService.getBreadCrumb()"
                       [showIcon]="false"></ctrm-breadcrumb>
    </div>
    <div class="actionPane">
      <div class="screenDetails">
        <i (click)="openAllocationMain()" class="fas fa-chevron-left backIcon"></i>
        <p>Allocate Price</p>
      </div>
    </div>
  </div>
</div>
<div class="sectionCardTop" style="max-height: 85% !important;margin-bottom: 0 !important;">

  <div>
      <ctrm-button (onClick)="resetData()" [disabled]="isRefreshing" style="margin: 10px"
                   icon="{{isRefreshing?'fas fa-redo fa-spin':'fas fa-redo'}}" label="Refresh"></ctrm-button>
    <div class="allocationContainer">
      <ctrm-planning-grid (selectedRowsValue)="onRowSelect($event,'price')" (total)="onTotalChange($event,'price')"
                          [balanceCalculationFunction]="balanceFunc"
                          [balanceFieldName]="'balanceQuantity'"
                          [cols]="priceCols"
                          [refreshGrid]="reset" (onRefreshComplete)="reset = false"
                          [filterValue]="globalFilterValue" [gridPosition]="'left'"
                          [key]="'_rowNum'"
                          [mainQuantityField]="'pricedQuantity'" [matchBalanceColumnsWidth]="120"
                          [matchFieldName]="'matchQuantity'"
                          [noDataLabel]="noDataLabel"
                          [balanceRoundOff]="quantityRoundOff"
                          [matchRoundOff]="quantityRoundOff"
                          [value]="priceValue" style="width: 49.9%"
                          [negativeValueError]="'Match Amount is greater than Balance Amount'"
                          [heading]="'Price Lines'" [totalHeading]="'Total : '"
                          [totalMatchHeading]="'Total Match Quantity'"
                          [totalMatch]="priceTotal"
                          [hideFilter]="true"></ctrm-planning-grid>
      <div class="dividerLine"></div>
      <ctrm-planning-grid (selectedRowsValue)="onRowSelect($event,'obligation')" (total)="onTotalChange($event,'obligation')"
                          [balanceCalculationFunction]="balanceRightFunc"
                          [balanceFieldName]="'balanceQuantity'"
                          [checkedSingleRow]="true"
                          [balanceRoundOff]="quantityRoundOff"
                          [refreshGrid]="resetObligation" (onRefreshComplete)="resetObligation = false"
                          [matchRoundOff]="quantityRoundOff"
                          [cols]="obligationCols"
                          [filterValue]="globalFilterValue" [gridPosition]="'right'"
                          [key]="'ObligationId'"
                          [mainQuantityField]="'quantityInPriceUom'" [matchBalanceColumnsWidth]="120"
                          [matchFieldName]="'matchQuantity'" [noDataLabel]="noDataLabel"
                          [primaryKey]="'obligationId'" [primaryKeyClickable]="false" [value]="obligationValue"
                          style="width: 49.9%"
                          [matchLimit]="priceTotal"
                          [matchLimitRequired]="true"
                          [negativeValueError]="'Match Amount is greater than Balance Amount'"
                          [heading]="'Planned Obligations'" [totalHeading]="'Total : '"
                          [totalMatchHeading]="'Total Allocated Quantity'"
                          [totalMatch]="obligationTotal"
                          [hideFilter]="true"></ctrm-planning-grid>

    </div>


    <div class="matchPlanButtonDiv">
          <xceler-progress-button
                  (onError)="onError($event)"
                  (onLoadDone)="OnLoadDone($event)"
                  [buttonIdleTitle]="'Confirm Price Allocation'"
                  [isLoading]="isLoading"
                  [disabled]="priceValue === undefined || priceValue === null || priceValue.length === 0 ||obligationValue === undefined || obligationValue === null || obligationValue.length === 0"
                  [buttonLoadingTitle]="'Confirm Price Allocation'"
                  (onClick)="allocatePrice()">
          </xceler-progress-button>
    </div>
  </div>
</div>
<p-confirmDialog [baseZIndex]="25" header="Confirmation"
                 icon="pi pi-exclamation-triangle"></p-confirmDialog>

