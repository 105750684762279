import {Component, Input} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

import {NodesListService} from '../services/nodesList.service';
import {TreeDiagramNode} from '../classes/node.class';
import {TreeDiagramNodeMaker} from '../classes/node-maker.class';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tree-diagram-node',
  styleUrls: ['./node.component.css'],
  templateUrl: './node.component.html'
})
export class NodeComponent {
  public node: TreeDiagramNode | TreeDiagramNodeMaker;
  public childrenTransform;
  private readonly isRtl: boolean;

  constructor(
    private nodesSrv: NodesListService,
    private sanitizer: DomSanitizer
  ) {
    this.isRtl = document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl';
  }

  @Input() set nodeId(guid) {
    this.node = this.nodesSrv.getNode(guid);

    let calculation = `translate(calc(-50% + ${Math.round(
      this.node.width / 2
    )}px), 45px)`;

    if (this.isRtl) {
      calculation = `translate(calc(50% - ${Math.round(
        this.node.width / 2
      )}px), 45px)`;
    }

    this.childrenTransform = this.sanitizer.bypassSecurityTrustStyle(
      calculation
    );
  }

  onNodeBlur(event, nodeId) {
    const node = this.nodesSrv.getNode(nodeId);

    node.displayName = event.target.innerText;
    this.changeEditing(false, nodeId);
  }

  getName(displayName: any) {
    if (displayName.length > 20) {
      displayName = displayName.substr(0, 20) + '...';
    }
    return displayName;
  }

  changeEditing(b: boolean, guid: string) {
    this.nodesSrv.getNode(guid).editing = b;
  }

  dragenter($event: DragEvent, node: TreeDiagramNode | TreeDiagramNodeMaker) {
    $event.dataTransfer.dropEffect = 'move';
    node.toggle();
  }
}
