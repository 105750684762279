import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {MessageService, Table} from "primeng";
import {environment} from "../../../environments/environment";
import {CommonService} from "../../services/common.service";
import {Router} from "@angular/router";
import {MasterService} from '../../masters/services/MasterService';
import { Observable, Subscription } from 'rxjs';
import {OverlayPanel} from "primeng/overlaypanel";
import {FilterPipePipe} from "../../services/pipe/filter-pipe.pipe";

@Component({
  selector: ' ctrm-planning-grid',
  templateUrl: './ctrm-planning-grid.component.html',
  styleUrls: ['./ctrm-planning-grid.component.css']
})
export class CtrmPlanningGridComponent implements OnInit, OnChanges,AfterViewInit {
  @Input() cols: any[] = [];
  @Input() canSelectMultiple: boolean = true;
  @Input() reset: boolean = false;
  @Input() balanceCalculationFunction: Function;
  @Input() balanceFieldName: string = 'balanceQuantity';
  @Input() balanceRoundOff: number = 3;
  @Input() matchRoundOff: number = 3;
  @Input() mainQuantityField: string = 'tradeQuantity';
  @Input() matchFieldName: string = 'matchQuantity';
  @Input() primaryKeyClickable: boolean = true;
  currentValue: number = 0;
  @Input() value: any[] = [];
  quantityHolder: Map<string, number> = new Map<string, number>();
  selectedRows: any[] = [];
  checkedRows: any[] = [];
  @Input() key: string = 'tradeObligationId';
  @Input() val: string = 'balanceQuantity';
  @Input() filterValue: string;
  @Input() checkedSingleRow: boolean = false;
  @Input() matchLimit: number = 0;
  @Input() matchLimitRequired: boolean = false;
  @Input() heightToAdd: number = 100;

  @Output() total = new EventEmitter();
  @Output() selectedRowsValue = new EventEmitter();
  @Output() onUniqueKeyClick = new EventEmitter();
  @Output() onClickClickable = new EventEmitter();
  @Input() clickableColumns: any[] = [];
  @Output() rowSelect = new EventEmitter();
  @Output() onRefreshComplete = new EventEmitter();

  matchArray: Map<number,any> = new Map<number, any>();
  actualBalanceArray: any[] = [];
  // @ViewChild('tb', {static: true}) table: Table;
  @ViewChild('tb') table: Table;
  count: number = 0;
  balQty: number = 0;
  planningGridHeight: string = '';
  placeHolderHeight: string = '';
  @Input() showPlaceHolder: boolean = true;
  @Input() noDataTemplate: TemplateRef<any>;
  @Input() noDataRoute: string = '';
  @Input() routeToLable: string = '';
  @Input() noDataLabel: string = 'No Data Available';
  loadingDataLabel: string = 'No Data Available';
  @Input() stickyColumnList: any[] = [];
  @Input() gridPosition: string = 'left';
  @Input() primaryKey: string;
  noDataBoxLeft: string = '';
  @Input() lastField: string;
  lastSelectedIndex = -1;
  selectedIndexArrays: any[] = [];
  @Input() matchBalanceColumnsWidth: number = 100;
  private totalValue: number = 0;
  private scrolled: boolean = false;
  @Input() negativeValueError: String = '';
  @Output() disableEmitter = new EventEmitter();
  @Input() pageSize:number = 0;
  @Input() totalElements: number = 0;
  @Input() totalPages:number = 0;
  @Input() showDropDown: any;
  @Output() onChangePage = new EventEmitter();
  mouseEntered:boolean = false;
  top: string = '0px';
  left: string = '';
  right: string = '';
  @Input() filterOptionsList: any[] = [];
  @Input() filterSelected: any = {};
  @Output() onFilterApply = new EventEmitter();
  bsInlineValue = '';
  filterApplied:boolean = false;
  filterList:any[] = [];
  @Input() refreshGrid:boolean = false;
  @Output() clearOnFilter = new EventEmitter();
  @Input() mode:string = '';
  @Input() heading:string = '';
  @Input() totalHeading:string = '';
  @Input() totalMatchHeading:string = '';
  @Input() totalMatch: any;
  filterValues:{} = {};
  filterSearch:{} = {};
  pdisable:boolean = true;
  public eventsSubscription: Subscription;
  @Input()refreshevent:Observable<void>;
  loadingValues:boolean = false;
  col:any={};
  @Input() hideFilter: boolean = false;


  constructor(private changeDetectionRef:ChangeDetectorRef,public commonService: CommonService,
              private messageService: MessageService,public route: Router,
              public masterCommonServer:MasterService) {
  }

  ngOnInit() {
    if(this.refreshevent !== undefined && this.refreshevent !== null) {
      this.eventsSubscription = this.refreshevent.subscribe(next=>{
        this.clearFilter();
        this.resetDataOnfilterChange();
        this.lastSelectedIndex = -1;
        this.selectedIndexArrays = [];
        this.value=[];
      });
    }
  }

  //Throw the Total to parent Component
  throwTotal() {
    this.total.emit(this.totalValue);
  }

  //get The red color bg class when Match Qty exceeds Balance Qty
  getClass(col) {
    let classStyle = '';

    if (col.header === 'CB') {
      if (this.gridPosition === 'left') {
        classStyle += ' StickRightHeaderCheckbox';
      } else {
        classStyle += ' StickLeftHeaderCheckbox';
      }
    } else if (col.field === this.matchFieldName || col.field === this.balanceFieldName) {
      classStyle += "whiteRow";
      if (this.gridPosition === 'right') {
        if (col.field === this.matchFieldName) {
          classStyle += ' stickmatchLeftHeader'
        } else {
          classStyle += ' stickbalanceLeftHeader';
        }
      } else {
        if (col.field === this.matchFieldName) {
          classStyle += ' stickmatchRightHeader'
        } else {
          classStyle += ' stickbalanceRightHeader';
        }
      }
    } else {
      classStyle += 'normalRow';
      classStyle += this.getPrimaryKeyClass(col.field);
    }
    return classStyle
  }

  getClassForBody(col) {
    let classStyle = '';

    if (col.header === 'CB') {
      if (this.gridPosition === 'left') {
        classStyle += ' StickRightBodyCheckbox';
      } else {
        classStyle += ' StickLeftBodyCheckbox';
      }
    } else if (col.field === this.matchFieldName || col.field === this.balanceFieldName) {
      classStyle += "whiteRow";
      if (this.gridPosition === 'right') {
        if (col.field === this.matchFieldName) {
          classStyle += ' stickmatchLeftBody'
        } else {
          classStyle += ' stickbalanceLeftBody';
        }
      } else {
        if (col.field === this.matchFieldName) {
          classStyle += ' stickmatchRightBody'
        } else {
          classStyle += ' stickbalanceRightBody';
        }
      }
    } else {
      classStyle += 'normalRow';
      classStyle += this.getPrimaryKeyBodyClass(col.field);
    }
    return classStyle
  }

  //get the Quantity of Selected Row on the basis of Key
  getQuantity(rowData) {
    if (this.quantityHolder.has(rowData[this.key])) {
      return this.quantityHolder.get(rowData[this.key]);
    } else {
      this.quantityHolder.set(rowData[this.key], rowData[this.val]);
      return rowData[this.val];
    }
  }

  //This function is used to update the Balance Qty after the focus is out from Match Qty Input
  updateBalanceQty(rowData, index, value) {
    if (rowData[this.matchFieldName] == null) {
      rowData[this.matchFieldName] = this.commonService.formatNumberWithoutComma(0,this.matchRoundOff);
    }
    if(value > this.actualBalanceArray[index]) {
      rowData[this.balanceFieldName] = this.commonService.formatNumberWithoutComma(this.actualBalanceArray[index] - value,this.balanceRoundOff);
      this.disableEmitter.emit(true);
       this.showToast(this.negativeValueError,'error');
    } else if (value >= 0) {
      rowData[this.balanceFieldName] = this.commonService.formatNumberWithoutComma(this.actualBalanceArray[index] - value,this.balanceRoundOff);
      this.disableEmitter.emit(false);
    }
  }

  showToast(msg, severity: string = 'success') {
    this.messageService.add({
      severity: severity,
      summary: msg
    });
  }

  onFocusOut(index, rowData) {
    this.updateAndSum(index, rowData[this.matchFieldName]);
    rowData[this.matchFieldName] = this.commonService.formatNumberWithoutComma(parseFloat(rowData[this.matchFieldName]),this.matchRoundOff);
  }

  updateAndSum(index, value) {

    this.matchArray.set(index,value)
    this.totalValue = this.sumArray(this.matchArray.values());
    this.throwTotal();
    this.selectedRowsValue.emit(this.selectedRows);
  }

  updateBalance() {
    let _this = this;
    this.selectedIndexArrays.forEach(function (index) {
      _this.onClickCheckbox(_this.value[index], index, false);
    });
  }

  private showMatchLimitExceedPopup(balanceValue,matchLimit,value:any) {
    this.messageService.add({
      severity: 'error', summary: " You can't Select this Row.",
      detail: "Balance Value ("+balanceValue+") is less then Match Limit ("+matchLimit+")"
    });
    if(this.checkedRows.includes(value[this.key])) {
      this.checkedRows.splice(this.checkedRows.indexOf(value[this.key]),1);
    }
    if(this.selectedRows.includes(value)) {
      this.table.selectionKeys[this.selectedRows.indexOf(value)] = 0;
      this.selectedRows.splice(this.selectedRows.indexOf(value),1);
    }
  }

  matchLimitCheck(value, index, updateLocalList: boolean) {
    if (this.checkedSingleRow) {
      if (updateLocalList) {
        this.selectedIndexArrays = [];
      }
      if (this.lastSelectedIndex !== -1) {
        let oldValue = parseFloat(this.matchArray.get(this.lastSelectedIndex));
        if(oldValue != undefined){
          this.value[this.lastSelectedIndex][this.matchFieldName] = this.commonService.formatNumberWithoutComma(0,this.matchRoundOff);
          this.value[this.lastSelectedIndex][this.balanceFieldName] = this.commonService.formatNumberWithoutComma(parseFloat(this.value[this.lastSelectedIndex][this.balanceFieldName]) + oldValue,this.balanceRoundOff);
          this.totalValue = 0;
        }
        this.matchArray.clear();
        this.lastSelectedIndex=-1;
        this.selectedRows = [];
        this.checkedRows = [];
        this.selectedIndexArrays = [];
        this.updateAndSum(index, parseFloat(value[this.matchFieldName]));
        return;
      }
      if (this.lastSelectedIndex !== index) {
        if (parseFloat(value[this.balanceFieldName]) >= this.matchLimit) {
          let canAddAmount = this.matchLimit - this.totalValue;
          if (parseFloat(value[this.balanceFieldName]) < canAddAmount) {
            value[this.matchFieldName] = this.commonService.formatNumberWithoutComma(parseFloat(value[this.balanceFieldName]),this.matchRoundOff);
            value[this.balanceFieldName] = this.commonService.formatNumberWithoutComma(0,this.balanceRoundOff);
          } else {
            value[this.matchFieldName] = this.commonService.formatNumberWithoutComma(canAddAmount,this.matchRoundOff);
            value[this.balanceFieldName] = this.commonService.formatNumberWithoutComma(parseFloat(value[this.balanceFieldName]) - canAddAmount,this.balanceRoundOff);
          }
          this.lastSelectedIndex = index;
          this.selectedRows = [];
          this.checkedRows = [];
          this.checkedRows.push(value[this.key]);
          this.selectedRows.push(value);
          if (updateLocalList) {
            this.selectedIndexArrays.push(index);
          }
        } else {
          this.showMatchLimitExceedPopup(parseFloat(value[this.balanceFieldName]),this.matchLimit,value);
        }

      } else {
        this.lastSelectedIndex = -1;
        this.selectedRows = [];
        this.checkedRows = [];
        this.matchArray.clear();
        if (updateLocalList) {
          this.selectedIndexArrays = [];
        }
      }
      this.updateAndSum(index, parseFloat(value[this.matchFieldName]));
    } else {
      if (!this.checkedRows.includes(value[this.key])) {
        if (parseFloat(value[this.balanceFieldName]) >= this.matchLimit) {
          let canAddAmount = this.matchLimit - this.totalValue;
          if (parseFloat(value[this.balanceFieldName]) < canAddAmount) {
            value[this.matchFieldName] = this.commonService.formatNumberWithoutComma(parseFloat(value[this.balanceFieldName]),this.matchRoundOff);
            value[this.balanceFieldName] = this.commonService.formatNumberWithoutComma(0,this.balanceRoundOff);
          } else {
            value[this.matchFieldName] = this.commonService.formatNumberWithoutComma(canAddAmount,this.matchRoundOff);
            value[this.balanceFieldName] = this.commonService.formatNumberWithoutComma(parseFloat(value[this.balanceFieldName]) - canAddAmount,this.balanceRoundOff);
          }
          this.checkedRows.push(value[this.key]);
        } else {
          this.showMatchLimitExceedPopup(parseFloat(value[this.balanceFieldName]),this.matchLimit,value);
        }
      } else {
        this.checkedRows.splice(this.checkedRows.indexOf(value[this.key]), 1);
        let oldValue = parseFloat(this.matchArray.get(index));
        value[this.matchFieldName] = this.commonService.formatNumberWithoutComma(0,this.matchRoundOff);
        value[this.balanceFieldName] = this.commonService.formatNumberWithoutComma(parseFloat(value[this.balanceFieldName]) + oldValue,this.balanceRoundOff);
      }
      this.updateAndSum(index, parseFloat(value[this.matchFieldName]));
    }
  }

  updateIndexArray(index, updateLocalList) {
    if (updateLocalList) {
      if (this.selectedIndexArrays.includes(index)) {
        this.selectedIndexArrays.splice(this.selectedIndexArrays.indexOf(index), 1);
      } else {
        this.selectedIndexArrays.push(index);
      }
    }
  }

  //On selecting Checkbox the Total Qty gets Updated, Balance Qty becomes Zero and Match Qty becomes Bal Qty
  onClickCheckbox(value, index, updateLocalList: boolean = true) {
    this.updateIndexArray(index, updateLocalList);
    if (this.matchLimitRequired) {
      this.matchLimitCheck(value, index, updateLocalList);
    } else {
      if (this.checkedSingleRow) {
        if (updateLocalList) {
          this.selectedIndexArrays = [];
        }
        if (this.lastSelectedIndex !== -1) {
          let oldValue = parseFloat(this.matchArray.get(this.lastSelectedIndex));
          this.matchArray.set(this.lastSelectedIndex,0);
          this.value[this.lastSelectedIndex][this.matchFieldName] = this.commonService.formatNumberWithoutComma(0,this.matchRoundOff);
          this.value[this.lastSelectedIndex][this.balanceFieldName] = this.commonService.formatNumberWithoutComma(parseFloat(this.value[this.lastSelectedIndex][this.balanceFieldName]) + oldValue,this.balanceRoundOff);
        }
        if (this.lastSelectedIndex !== index) {
          this.lastSelectedIndex = index;
          this.selectedRows = [];
          this.checkedRows = [];
          this.checkedRows.push(value);
          this.selectedRows.push(value);
          if (updateLocalList) {
            this.selectedIndexArrays.push(index);
          }
        } else {
          this.lastSelectedIndex = -1;
          if (updateLocalList) {
            this.selectedIndexArrays = [];
          }
        }
        this.onCheckboxCalculation(value, index);
      } else {
        this.onCheckboxCalculation(value, index);
      }
    }
  }

  onCheckboxCalculation(value, index) {
    if (!this.checkedRows.includes(value[this.key])) {
      this.value[index][this.matchFieldName] = this.commonService.formatNumberWithoutComma(parseFloat(this.value[index][this.balanceFieldName]),this.matchRoundOff);
      this.value[index][this.balanceFieldName] = this.commonService.formatNumberWithoutComma(0,this.balanceRoundOff);
      this.checkedRows.push(value[this.key]);
    } else {
      this.checkedRows.splice(this.checkedRows.indexOf(value[this.key]), 1);
      let oldValue = parseFloat(this.matchArray.get(index));
      this.value[index][this.matchFieldName] = this.commonService.formatNumberWithoutComma(0,this.matchRoundOff);
      this.value[index][this.balanceFieldName] = this.commonService.formatNumberWithoutComma(parseFloat(this.value[index][this.balanceFieldName]) + oldValue,this.balanceRoundOff);
    }
    this.updateAndSum(index, parseFloat(value[this.matchFieldName]));
  }

  sumArray(array?: IterableIterator<any>) {
    return Array.from(array).reduce(function (acc, cur) {
      return parseFloat(acc) + parseFloat(cur);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    let _this = this;
    if (changes['value']) {
      if(changes['value'].currentValue !== null && changes['value'].currentValue !== undefined && Array.isArray(changes['value'].currentValue)) {
        this.loadingValues = true;
        this.actualBalanceArray = [];
        let value:any[] = changes['value'].currentValue;
        value.forEach(data => {
          data[_this.matchFieldName] = this.commonService.formatNumberWithoutComma(0,this.matchRoundOff);
          data[_this.balanceFieldName] = this.commonService.formatNumberWithoutComma(_this.commonService.runFunction(data, _this.balanceCalculationFunction),this.balanceRoundOff);
          _this.actualBalanceArray.push(data[_this.balanceFieldName]);
        });
        this.calculateHeight();
        this.loadingValues = false;
        this.loadingDataLabel = 'No Data Available';
      } else {
        this.value = [];
        this.loadingDataLabel = 'No Data Available';
      }
      if(changes['refreshGrid']) {
        this.value = [];
        this.checkedRows = [];
        this.matchArray = new Map<number, any>();
        this.selectedIndexArrays = [];
        this.selectedRows = [];
        this.lastSelectedIndex = -1;
        this.loadingDataLabel = 'Loading Please Wait';
        this.clearFilter();
        this.resetDataOnfilterChange();
        this.totalValue = 0;
        this.throwTotal();
        this.onRefreshComplete.emit();
      }
    }

    if (changes['filterValue']) {
      if (this.table != undefined) {
        this.table.filterGlobal(this.filterValue, 'contains');
      }
    }

    if (changes['reset']) {
      if (changes['reset'].currentValue) {
        this.resetDataOnfilterChange();
        this.totalValue = 0;
        this.throwTotal();
      }
    }

    if (changes['matchLimit'] && this.matchLimitRequired) {
      this.updateBalance();
    }
  }

  getChkBoxBrder(rowData: any) {
    return rowData['buySell'] === false ? 'rightChkBoxBorder' : 'leftChkBoxBorder';
  }

  getHeaderClass(header, field) {
    let classStyle = this.getPrimaryKeyClass(field);
    if (header === 'CB') {
      if (this.gridPosition === 'left') {
        classStyle += ' StickRightHeaderCheckbox';
      } else {
        classStyle += ' StickLeftHeaderCheckbox';
      }
    } else if (field === this.matchFieldName || field === this.balanceFieldName) {
      classStyle += ' matchBalCss';
      if (this.gridPosition === 'right') {
        if (field === this.matchFieldName) {
          classStyle += ' stickmatchLeftHeader'
        } else {
          classStyle += ' stickbalanceLeftHeader';
        }
      } else {
        if (field === this.matchFieldName) {
          classStyle += ' stickmatchRightHeader'
        } else {
          classStyle += ' stickbalanceRightHeader';
        }
      }
    }

    return classStyle;
  }

  getClassForHeading(index) {
    return index > 0 ? 'headerNormal' : 'noPipe';
  }


  ngAfterViewInit() {
    this.calculateHeight();
    this.changeDetectionRef.detectChanges();
  }

  @HostListener("window:resize", [])
  public onResize() {
    this.calculateHeight();
  }

  calculateHeight() {
    if(this.table !== undefined && this.table !== null) {
      let offsetTop = this.table.el.nativeElement['offsetTop'];
      let offsetHeight = 0;
      if(this.table.el.nativeElement['offsetParent'] !== undefined && this.table.el.nativeElement['offsetParent'] !== null) {
        offsetHeight = this.table.el.nativeElement['offsetParent']['offsetHeight'];
      }
      if(this.getPagination()) {
        offsetHeight = offsetHeight - 32;
      }
      this.planningGridHeight = (offsetHeight - (offsetTop + this.heightToAdd)) + 'px';
      this.calculateLeft();
    }
  }

  onClickPrimary(rowData) {
    this.onUniqueKeyClick.emit(rowData);
  }

  calculateLeft() {
    this.noDataBoxLeft = (this.table.el.nativeElement['offsetLeft'] + ((this.table.el.nativeElement['offsetWidth'] - 500) / 2)) + 'px';
  }

  changeRouteNoData() {
    this.route.navigate([this.noDataRoute], {
      skipLocationChange: true
    });
  }

  getPrimaryKeyClass(field: any) {
    let classStyle = '';
    if (field === this.primaryKey) {
      if (this.gridPosition === 'right') {
        classStyle += ' stickRightHeader';
      } else {
        classStyle += ' stickLeftHeader';
      }

    }
    return classStyle;
  }

  getPrimaryKeyBodyClass(field: any) {
    let classStyle = '';
    if (field === this.primaryKey) {
      if (this.gridPosition === 'right') {
        classStyle += ' stickRightBody';
      } else {
        classStyle += ' stickLeftBody';
      }

    }
    return classStyle;
  }

  autoScroll() {
    if (!this.scrolled) {
      this.scrolled = true;
    }

  }

  getRowValue(rowData, col: any) {
    if (col.valueFunction !== undefined && col.valueFunction !== null) {
      return this.commonService.runFunction(rowData, col.valueFunction);
    }
    if (col.type !== undefined && col.type === 'date') {
      return this.commonService.getDateForOutput(rowData[col.field])
    }
    return (rowData[col.field] === undefined || rowData[col.field] === null || rowData[col.field].length === 0) ? '-' : rowData[col.field];
  }

  onRowSelect() {
    this.rowSelect.emit(this.selectedRows);
  }

  onClickable(field: any, rowData) {
    this.onClickClickable.emit({fieldName: field, rowData: rowData});
  }

  getStickStyle(col: any) {
    let style: any = {};
    if (col.field === this.balanceFieldName) {
      if (this.gridPosition === 'left') {
        style['right'] = '0px';
      } else if (this.gridPosition === 'right') {
        style['left'] = '0px';
      }
    } else if (col.field === this.matchFieldName) {
      if (this.gridPosition === 'left') {
        style['right'] = this.matchBalanceColumnsWidth + 'px';
      } else if (this.gridPosition === 'right') {
        style['left'] = this.matchBalanceColumnsWidth + 'px';
      }
    } else if (col.header === 'CB') {
      if (this.gridPosition === 'left') {
        style['right'] = ((this.matchBalanceColumnsWidth) * 2) + 'px';
      } else if (this.gridPosition === 'right') {
        style['left'] = ((this.matchBalanceColumnsWidth) * 2) + 'px';
      }
    }
    return style;
  }

  updateIdAndGetRowData(rowData, ri) {
    rowData['_rowNum'] = ri;
    return rowData;
  }

  isDisabled(rowData: any) {
    return this.loadingValues || (this.checkedSingleRow && this.matchLimitRequired && rowData[this.balanceFieldName] < this.matchLimit);
  }

  getPagination() {
    return this.totalElements > this.pageSize;
  }

  onPageChange(value: any) {
    this.loadingDataLabel = 'Loading Please Wait';
    this.value = [];
    this.onChangePage.emit(value);
  }

  resetDataOnfilterChange() {
    this.matchArray.clear();
    this.totalValue = 0;
    this.selectedRows.length = 0;
    this.checkedRows.length = 0;
    this.actualBalanceArray.length = 0;
  }

  showFilters(field, op: OverlayPanel, event: MouseEvent) {
    this.col = field;
    op.toggle(event);
    if(this.gridPosition === 'left') {
      this.left = '0px';
    } else {
      this.right = '0px'
    }
  }

  onApply(col: any) {
    if(this.filterValues[col.field] !== undefined && this.filterValues[col.field] !== null && this.filterValues[col.field].length > 0){
      if(!this.filterList.includes(col.field)) {
        this.filterList.push(col.field);
      }
    } else {
      this.filterList.splice(this.filterList.indexOf(col.field),1);
    }
    this.onFilterApply.emit(this.filterValues);
    this.afterClearFilter();
  }

  pDisable(col) {
    if(this.filterValues[col.field] !== undefined && this.filterValues[col.field] !== null && this.filterValues[col.field].length > 0){
      this.pdisable = false;
      return 'applyText';
    } else {
      return 'applyTextdiable';
    }
  }

  updatevalue(value: any, field: any,isdate:boolean = false) {
    if(isdate) {
      let date:Date = new Date(value+'UTC')
      let dt = this.commonService.getFormattedDateTime(date,'yyyy-MM-ddTHH:mm:ss');
      this.filterValues[field] =  dt.toString().replace('Z','');
    }
  }

  getDateFormat() {
    let format = environment.dateFormat;
    return format.toString().toUpperCase();
  }

  showiconOnFilter(field: any) {
    if(this.filterList.includes(field)) {
      return "Grid Filte Applied.svg";
    } else {
      return "Grid Filter.svg";
    }
  }

  iconClass(field: any) {
    if(this.filterList.includes(field)) {
      return "iconClassOnfilter";
    } else {
      return "iconClassNotFilter";
    }
  }

  getFilterClass() {
    let filterClassObj = {};
    filterClassObj['icon'] = this.filterList.length > 0?"fas fa-check-circle":"fas fa-minus-circle";
    filterClassObj['colour'] = this.filterList.length > 0?"filterTitleApplied":"filterTitle";
    filterClassObj['header'] = this.filterList.length > 0?"Filter Applied":"No filter Applied";
    return filterClassObj;
  }

  clearFilter() {
    if(this.filterList.length > 0) {
      this.filterValues={};
      this.filterList = [];
      this.lastSelectedIndex = -1;
      this.bsInlineValue = '';
      this.afterClearFilter();
      this.clearOnFilter.emit(this.refreshGrid = true);
    }
  }

  private afterClearFilter() {
    this.resetDataOnfilterChange();
  }

  getFilterHeading() {
    if(this.mode === 'Left') {
      return 'filterheading';
    } else {
      return 'filterheadingRight';
    }

  }

  clearfilter(field: any) {
    delete this.filterValues[field]
    delete this.filterSearch[field];
    this.filterList.splice(this.filterList.indexOf(field),1);
    this.onFilterApply.emit(this.filterValues);
    this.afterClearFilter();
  }
}
