import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CtrmFilePickerComponent),
  multi: true
};

@Component({
  selector: 'ctrm-file-picker',
  templateUrl: './ctrm-file-picker.component.html',
  styleUrls: ['./ctrm-file-picker.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class CtrmFilePickerComponent implements OnInit, ControlValueAccessor, OnChanges,AfterViewInit {

  @Input('value') val: any;
  @Input('name') name: string = '';
  @Input() disable: boolean = false;
  @Input() fpClass: any;
  @Input() fileName: string = 'No File Chosen';
  @Input() multiple: boolean = false;
  filesName: string = '';
  @Output() onFileSelected = new EventEmitter<File[]>();
  files: File[] = [];

  constructor(private changeDetectorRef:ChangeDetectorRef) {
  }

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fileName']) {
      if (changes['fileName'].currentValue === undefined || changes['fileName'].currentValue === null || changes['fileName'].currentValue.toString().length == 0) {
        this.fileName = 'No File Chosen';
      }
      this.fileName = this.refractorFileName()
    }
  }

  onTouched: any = () => {
  };

  onChange(val) {
    this.val = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
    }
  }

  refractorFileName(filename: string = this.fileName, len = 20) {
    if (filename.length > len) {
      filename = filename.substring(0, len) + '...';
    }
    return filename;
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  handleFileInput(value: Event) {
    let filelist: FileList = value['target']['files'];
    this.files = [];
    if (filelist.length > 0) {
      this.fileName = '';
      this.filesName = '';
      for (let i = 0; i < filelist.length; i++) {
        this.files.push(filelist.item(i));
        if (i !== 0) {
          this.fileName += ',';
        }
        this.fileName += filelist.item(i).name;
        this.filesName += filelist.item(i).name + '\n';
      }
      this.fileName = this.refractorFileName();
      this.onFileSelected.emit(this.files);
    }
  }
}
