import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {CommonService} from "../../../services/common.service";
import {environment} from "../../../../environments/environment";
import {interval, Subscription} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class QuickbookService {

  queryString = require('querystring');

  private QUICKBOOKS_CLIENT_ID = 'ABavz7kfaTKoG3S64xQc805W0W1aecoVPNR4iLe6etko0ly8uS';
  private QUICKBOOKS_REDIRECT_URI = '/integration-service/get-refresh-token-quickbook';

  private XERO_CLIENT_ID = '97C62FF3B0C7417393BC515F35A0554E';
  private XERO_REDIRECT_URI = '/integration-service/get-refresh-token-xero';

  constructor(private commonService:CommonService) {
  }

  public connectQuickbook() {
    this.openWindow(this.getAuthUri());
  }

  public connectXero() {
    this.openWindow(this.getAuthUriXero());
  }

  private openWindow(url:string) {
    let w = 600;
    let h = 800;
    let left = (screen.width/2)-(w/2);
    let top = (screen.height/2)-(h/2);
    window.open(url, "_blank", 'location=yes width='+w+', height='+h+', top='+top+', left='+left);
  }


  private getAuthUri() {
    return `https://appcenter.intuit.com/connect/oauth2?${this.queryString.stringify({
      response_type: 'code',
      redirect_uri: environment.base_url_new + this.QUICKBOOKS_REDIRECT_URI,
      client_id: this.QUICKBOOKS_CLIENT_ID,
      scope: ['com.intuit.quickbooks.accounting'].join(' '),
      state: this.commonService.getTenantId()
    })}`;
  }

  private getAuthUriXero() {
    return `https://login.xero.com/identity/connect/authorize?${this.queryString.stringify({
      response_type: 'code',
      redirect_uri: environment.base_url_new + this.XERO_REDIRECT_URI,
      client_id: this.XERO_CLIENT_ID,
      scope: ['openid','profile', 'email', 'offline_access','accounting.settings','accounting.transactions','accounting.reports.read','accounting.attachments','accounting.journals.read','accounting.contacts'].join(' '),
      state: this.commonService.getTenantId()
    })}`;
  }
}
