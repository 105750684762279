import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CommonService} from '../../services/common.service';
import {KeyValue} from '../../grid/key-value.model';
import {Tcolumn} from '../../grid/tcolumn.model';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Label, SingleDataSet} from 'ng2-charts';
import {MessageService, SelectItem} from 'primeng';
import {environment} from 'src/environments/environment';
import {MasterService} from '../../masters/services/MasterService';
import {defaultValue} from '../../grid/defaultValue.model';
import {HttpErrorResponse} from '@angular/common/http';
import {Filter} from '../../grid/preference/filter/filter.model';
import {ExcelServiceService} from '../../services/excel-service.service';

@Component({
  selector: 'app-cash-flow',
  templateUrl: './cash-flow.component.html',
  styleUrls: ['./cash-flow.component.css']
})
export class CashFlowComponent implements OnInit, AfterViewInit {
  legalEntityList: any[] = [];
  showCostTradeValue: boolean = true;
  obligationList: any[] = [];
  selectedProfitCenter: string = '';
  currentPageNumber: number = 0;
  selectedLegalEntity: string = '';
  selectedCommodity: string = '';
  selectedCounterparty: string = '';
  columns: Tcolumn[] = [];
  value: any[] = [];
  pieData: any;
  receivableCost = 0;
  receivableTrade = 0;
  payableCost = 0;
  payableTrade = 0;
  margin = 0;
  totalReceive = 0;
  totalPay = 0;
  startDate: any;
  endDate: any;
  receivableCostHeight: string = '0%';
  receivableTradeHeight: string = '0%';
  addedFilter:any[] =[];
  filter:Filter[] =[] ;
  payableCostHeight: string = '0%';
  payableTradeHeight: string = '0%';
  plannedTradeCount: number = 0;
  unplannedTradeCount: number = 0;
  ActualizedTradeCount: number = 0;
  showDateRange: boolean = false;
  presetList: any[] = [];
  selectedPreset:any= {};
  gridFilters:any[] = [];
  summaryObject = {}

  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  public pieChartLabels: Label[] = ['Margin ', 'Payable ', 'Receivable '];
  public pieChartData: SingleDataSet = [0, 0, 0];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = false;
  public pieChartPlugins = [];
  public pieChartColors = [];
  public barChartColors = [];
  quantityFunc;
  priceFunction;
  currencyFunc;

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {xAxes: [{}], yAxes: [{}]},
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartLabels: Label[] = ['Mon', 'Tue', 'Wed'];
  graphView: boolean = false;
  public barChartType: ChartType = 'bar';
  public barChartLegend = false;
  public barChartData: ChartDataSets[] = [
    {data: [65, 59, 80], label: 'Series A', backgroundColor: '#2ae8d2', hoverBackgroundColor: '#2ae8d2'},
    {data: [28, 48, 40], label: 'Series B', backgroundColor: '#5a66dc', hoverBackgroundColor: '#5a66dc'},
    {data: [8, 18, 60], label: 'Series C', backgroundColor: '#ffd62c', hoverBackgroundColor: '#ffd62c'}
  ];
  tradeCashflowColumns: Tcolumn[] = [];
  fxCashflowColumns: Tcolumn[] = [];
  tradeCashflowValues: any = [];
  fxCashflowValues: any = [];
  selectedbutton = 'Today';
  tabOptions: SelectItem[];
  tradeRefreshing: boolean = false;
  fxRefreshing: boolean = false;
  tabValue: string = 'trade';
  private lasteSelectedButton:string = "Today";
  tradeInfoDisplay: boolean = false;
  selctedTradeId: any = '';
  plannedObligationId: any = '';
  rowData: any;
  operationDisplay: boolean = false;
  selectedCashflows: any[] = [];
  sellDisable: boolean = false;
  tradeId: any;
  mode: any;
  draftForm: any;
  draftData: any;
  plannedCostCFCount: number = 0;
  plannedTradeCFCount: number = 0;
  unplannedCount: number = 0;
  unplannedCostCFCount: number = 0;
  unplannedTradeCFCount: number = 0;
  actualizedCount: number = 0;
  actualizedTradeCFCount: number = 0;
  actualizedCostCFCount: number = 0;
  filterArray:any[] = [];

  tradeIdList = [];
  transactionTypeList = [];
  settlementTypeList = [];
  profitcenterList = [];
  counterparty = [];
  currencyList = [];
  // commodityList = [];
  matchTypeList = [];


  private tradeStage:Tcolumn;
  private fxStage:Tcolumn;
  refreshFilter: boolean = false;
  selctedFXTradeId: any;
  fxrowData: any = {};
  filterDrpdwnData: any[] = [];
  fxInfoDisplay:boolean = false;
  showCalendar:boolean = false;
  isLoadingInvoice: boolean = false;
  selectedObject: any;
  quantityRoundOff:number;
  priceRoundOff:number;
  constructor(private changeDetectionRef:ChangeDetectorRef,public commonService: CommonService, private masterCommonService: MasterService, private excelService: ExcelServiceService,private messageService: MessageService) {
  }

  ngOnInit(): void {
    let _this=this;
    let tenantConfig=JSON.parse(this.commonService.getFromStorage("tenantConfig"));
    if(tenantConfig && tenantConfig.roundingFormat){
      _this.priceRoundOff=tenantConfig.roundingFormat.priceRounding;
      _this.quantityRoundOff= tenantConfig.roundingFormat.quantityRounding;
    }
    else{
      _this.priceRoundOff=2;
      _this.quantityRoundOff=3;
    }
    this.generateDefaultPreset();
    this.getSummary();
    this.tabOptions = [
      {label: 'Trade/Cost', value: 'trade'},
      {label: 'FX Cash/Forward', value: 'fx'},
    ];
    this.quantityFunc = function (value) {
      return _this.commonService.formatNumberWithoutComma(value[0],_this.quantityRoundOff) + ' ' + value[1];
    };
    this.priceFunction=function(value){
      return _this.commonService.formatNumberWithoutComma(value[0],_this.priceRoundOff)+ ' ' + value[1];
    }

    this.currencyFunc = function (value) {
      return _this.commonService.formatNumberWithoutComma(value[0],_this.priceRoundOff) + ' ' + value[1] + '/' + value[2];
    };
    this.startDate = new Date();
    this.endDate = new Date();
    this.updateBarGraph();
    this.getTcolumns();
    this.createCahflowColumns();
    this.createFXCahflowColumns();
    this.onCardSelect('Estimated');

    this.getLists();
    this.pieChartColors = [{backgroundColor: ['#ff9ad5', '#5a66dc', '#2ae8d2']}];
  }

  ngAfterViewInit() {
    this.changeDetectionRef.detectChanges();
  }

  resetGraph() {
    this.startDate = new Date();
    this.endDate = new Date();
    this.selectedbutton = 'Today';
    let _this = this;
    this.commonService.post(environment.base_url + '/api/cashflow/v1/getCashAggregator', {
      profitCenter: this.selectedProfitCenter,
      commodity: this.selectedCommodity,
      legalEntity: this.selectedLegalEntity,
      counterParty: this.selectedCounterparty,
      startDate: this.startDate,
      endDate: this.endDate
    }).subscribe((next) => {
      _this.receivableTrade = next['tradeReceivable'];
      _this.receivableCost = next['costReceivable'];
      _this.payableTrade = next['tradePayable'];
      _this.payableCost = next['costPayable'];
      _this.updateBarGraph();
    });
  }

  calculateCostBarHeight(tradeNumber: number, costNumber: number) {
    if (costNumber > 0) {
      return (costNumber / (tradeNumber+costNumber)) * 100;
    } else {
      return 0;
    }
  }

  calculateTradeBarHeight(tradeNumber: number, costNumber: number) {
    if (tradeNumber > 0) {
      return (tradeNumber / (tradeNumber+costNumber)) * 100;
    } else {
      return 0;
    }
  }

  createCahflowColumns() {
    let obligationIdFunc = (value:any[] =[]) => {
      let splitNumber = parseFloat(value[1] !== null && value[1] !== undefined?value[1]:0);
      if(splitNumber > 0) {
        return value[0] + ' ('+splitNumber+')';
      }
      return value[0];
    }
    this.tradeCashflowColumns = [];
    this.tradeCashflowColumns.push(new Tcolumn('type', 'Cashflow Type', 'LB', 1, false, undefined, true));
    this.tradeCashflowColumns.push(new Tcolumn('tradeId', 'Trade Id', 'LB', 2, false, undefined, true));
    this.tradeCashflowColumns.push(new Tcolumn('quantityStatus', 'Quantity Status', 'LB', 3, false, undefined, true));
    this.tradeCashflowColumns.push(new Tcolumn('priceStatus', 'Price Status', 'LB', 4, false, undefined, true));
    this.tradeCashflowColumns.push(new Tcolumn('invoiceStatus', 'Invoice Status', 'LB', 4, false, undefined, true));
    this.tradeCashflowColumns.push(new Tcolumn('tradePriceValue', 'Amount', 'LB', 5, false, undefined, true, new defaultValue(undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.priceFunction, ['tradePriceValue', 'tradePriceCurrency'])));
    this.tradeCashflowColumns.push(new Tcolumn('settlementValue', 'Settlement Amount', 'LB', 6, false, undefined, true, new defaultValue(undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.priceFunction, ['settlementValue', 'settlementCurrency'])));
    this.tradeCashflowColumns.push(new Tcolumn('financialAmountType', 'Amount Type', 'LB', 7, false, undefined, true));
    this.tradeCashflowColumns.push(new Tcolumn('paymentDueDate', 'Payment Due Date', 'D', 8, false, undefined, true));
    this.tradeCashflowColumns.push(new Tcolumn('tradeDateTime', 'Trade Date', 'D', 9, false, undefined, true));
    this.tradeCashflowColumns.push(new Tcolumn('plannedObligationId', 'Obligation Id', 'LB', 10, false, undefined, true,new defaultValue(undefined, undefined, undefined, undefined, undefined, undefined, undefined, obligationIdFunc, ['plannedObligationId', 'splitSequenceNumber'])));
    this.tradeCashflowColumns.push(new Tcolumn('quantity', 'Quantity', 'LB', 11, false, undefined, true, new defaultValue(undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.quantityFunc, ['quantity', 'quantityUom'])));
    this.tradeCashflowColumns.push(new Tcolumn('commodity', 'Commodity', 'LB', 12, false, undefined, true));
    this.tradeCashflowColumns.push(new Tcolumn('priceType', 'Price Type', 'LB', 13, false, undefined, true));
    this.tradeCashflowColumns.push(new Tcolumn('tradePrice', 'Trade Price', 'LB', 14, false, undefined, true, new defaultValue(undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.currencyFunc, ['tradePrice', 'tradePriceCurrency', 'priceCurrencyUom'])));
    this.tradeCashflowColumns.push(new Tcolumn('profitcenter', 'Profit Center', 'LB', 15, false, undefined, true));
    this.tradeCashflowColumns.push(new Tcolumn('counterparty', 'Counterparty', 'LB', 16, false, undefined, true));
  }

  createFXCahflowColumns() {
    this.fxCashflowColumns = [];
    this.fxCashflowColumns.push(new Tcolumn('type', 'Cashflow Type', 'LB', 1, false, undefined, true));
    this.fxCashflowColumns.push(new Tcolumn('fxTradeId', 'FX Trade Id', 'LB', 2, false, undefined, true));
    this.fxCashflowColumns.push(new Tcolumn('fxType', 'FX Trade Type', 'L', 4, false, this.masterCommonService.getBooleanFromLabel('Cash', 'Forward', false, 'Cash', 'Forward'), true));
    this.fxCashflowColumns.push(new Tcolumn('invoiceStatus', 'Invoice Status', 'LB', 4, false, undefined, true));
    this.fxCashflowColumns.push(new Tcolumn('tradePriceValue', 'Amount', 'LB', 5, false, undefined, true, new defaultValue(undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.priceFunction, ['tradePriceValue', 'tradePriceCurrency'])));
    this.fxCashflowColumns.push(new Tcolumn('settlementValue', 'Settlement Amount', 'LB', 5, false, undefined, true, new defaultValue(undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.priceFunction, ['settlementValue', 'settlementCurrency'])));
    this.fxCashflowColumns.push(new Tcolumn('financialAmountType', 'Amount Type', 'LB', 6, false, undefined, true));
    this.fxCashflowColumns.push(new Tcolumn('paymentDueDate', 'Expiry Date', 'D', 7, false, undefined, true));
    this.fxCashflowColumns.push(new Tcolumn('tradeDateTime', 'Trade Date', 'D', 8, false, undefined, true));
    this.fxCashflowColumns.push(new Tcolumn('plannedObligationId', 'Planned Obligation Id', 'LB', 9, false, undefined, true));
    this.fxCashflowColumns.push(new Tcolumn('allocationId', 'Allocation Id', 'LB', 10, false, undefined, true,));
    this.fxCashflowColumns.push(new Tcolumn('broker', 'Broker', 'LB', 11, false, undefined, true));
    this.fxCashflowColumns.push(new Tcolumn('profitcenter', 'Profit Center', 'LB', 12, false, undefined, true));
    this.fxCashflowColumns.push(new Tcolumn('counterparty', 'Counterparty', 'LB', 13, false, undefined, true));
    this.fxStage = new Tcolumn('stage', 'Stage', 'LB', 14, false, undefined, true);
    this.fxCashflowColumns.push(this.fxStage);

  }

  updateBarGraph() {
    this.receivableTradeHeight = this.calculateTradeBarHeight(this.receivableTrade,this.receivableCost)+'%';
    this.receivableCostHeight = this.calculateCostBarHeight(this.receivableTrade, this.receivableCost) + '%';
    this.payableTradeHeight = this.calculateTradeBarHeight(this.payableTrade,this.payableCost)+'%';
    this.payableCostHeight = this.calculateCostBarHeight(this.payableTrade, this.payableCost) + '%';
    this.totalReceive = this.receivableTrade + this.receivableCost;
    this.totalPay = this.payableTrade + this.payableCost;
    this.margin = Math.abs(this.totalReceive - this.totalPay);
    this.pieChartData = [this.margin, this.totalPay, this.totalReceive];
  }

  getData() {
    let _this = this;
    this.value = [];
    let obj = {
      profitCenter: this.selectedProfitCenter,
      commodity: this.selectedCommodity,
      legalEntity: this.selectedLegalEntity,
      counterParty: this.selectedCounterparty,
      startDate: this.startDate,
      endDate: this.endDate
    };
    // this.upadteGraphAndData(obj);
  }

  getLists() {
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url_new + '/api-iam/api/userProfile/v1/getUserDetails?tenantId=' + this.commonService.getFromStorage('tenantId') + '&userId=' + this.commonService.getFromStorage('userName')).subscribe((next: any) => {
      // _this.legalEntityList = _this.commonService.createListFromObject(next['userInfoData']['Company'],'masterTypeValue','masterTypeValue',true,'Legal Entity');
      // _this.counterpartyList = _this.commonService.createListFromObject(next['userInfoData']['Counterparty'],'masterTypeValue','masterTypeValue',true,'Counterparty');
      // _this.profitCenterList = _this.commonService.createListFromObject(next['userInfoData']['Profit Center'],'masterTypeValue','masterTypeValue',true,'Profit Center');
      // _this.commodityList = _this.commonService.createListFromObject(next['userInfoData']['Commodity'],'masterTypeValue','masterTypeValue',true,'Commodity');

      _this.getData();
    });
  }

  resetValue(type, value) {
    if (type === 'profitCenter') {
      this.selectedCommodity = '';
      this.selectedLegalEntity = '';
      this.selectedCounterparty = '';
      this.selectedProfitCenter = value;
    } else if (type === 'legalEntity') {
      this.selectedCommodity = '';
      this.selectedProfitCenter = '';
      this.selectedCounterparty = '';
      this.selectedLegalEntity = value;
    } else if (type === 'commodity') {
      this.selectedProfitCenter = '';
      this.selectedLegalEntity = '';
      this.selectedCounterparty = '';
      this.selectedCommodity = value;
    } else if (type === 'counterparty') {
      this.selectedCommodity = '';
      this.selectedLegalEntity = '';
      this.selectedProfitCenter = '';
      this.selectedCounterparty = value;
    }
    this.getData();
  }

  getTcolumns() {
    this.columns = [];
    let sum = function (value: any[]) {
      let sum = value[0] + value[1];
      return Number(sum).toLocaleString('en-US');
    };
    if (this.selectedProfitCenter.length === 0) {
      this.columns.push(new Tcolumn('profitCenter', 'Profit Center', 'LB', 3, false));
    }
    if (this.selectedLegalEntity.length === 0) {
      this.columns.push(new Tcolumn('legalEntity', 'Legal Entity', 'LB', 1, false));
    }
    if (this.selectedCommodity.length === 0) {
      this.columns.push(new Tcolumn('commodity', 'Commodity', 'LB', 2, false));
    }
    if (this.selectedCounterparty.length === 0) {
      this.columns.push(new Tcolumn('counterParty', 'Counterparty', 'LB', 3, false));
    }
    this.columns.push(new Tcolumn('receivable', 'Account Receivable', 'LB', 4, false, undefined, true, new defaultValue('', undefined, undefined, undefined, false, true, false, sum, ['costReceivable', 'tradeReceivable'])));
    this.columns.push(new Tcolumn('payable', 'Account Payable', 'LB', 5, false, undefined, true, new defaultValue('', undefined, undefined, undefined, false, true, false, sum, ['costPayable', 'tradePayable'])));
  }

  onClickButton(buttonName: any) {
    let now = new Date();
    let quarter = Math.floor((now.getMonth() / 3));
    let halfyearly = Math.floor((now.getMonth() / 6));
    this.lasteSelectedButton = buttonName;
    if (buttonName === 'Today') {
      this.startDate = new Date();
      this.endDate = new Date();
    } else if (buttonName === 'Monthly') {
      this.startDate = new Date(now.getFullYear(), now.getMonth(), 1);
      this.endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    } else if (buttonName === 'Quaterly') {
      this.startDate = new Date(now.getFullYear(), quarter * 3, 1);
      this.endDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth() + 3, 0);
    } else if (buttonName === 'Half yearly') {
      if (now.getMonth() >= 3 && now.getMonth() <= 9) {
        this.startDate = new Date(now.getFullYear(), 3, 1);
      } else {
        this.startDate = new Date(now.getFullYear(), 10, 1);
      }
      this.endDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth() + 6, 0);
    } else if (buttonName === 'Yearly') {
      this.startDate = new Date(now.getFullYear(), 2, 1);
      this.endDate = new Date(this.startDate.getFullYear() + 1, 4, 1);
    }
    let obj = {
      profitCenter: this.selectedProfitCenter,
      commodity: this.selectedCommodity,
      legalEntity: this.selectedLegalEntity,
      counterParty: this.selectedCounterparty,
      startDate: this.startDate,
      endDate: this.endDate
    };
    // this.upadteGraphAndData(obj);
  }

  private upadteGraphAndData(object:any) {
    let _this = this;
    this.commonService.post(environment.base_url + "/api/cashflow/v1/getdashboarddata?tenantId="+ this.commonService.getFromStorage('tenantId'), object).subscribe((next: any[]) => {
      _this.value = next['data'];
      _this.receivableTrade = next['graphData']['tradeReceivable'];
      _this.receivableCost = next['graphData']['costReceivable'];
      _this.payableTrade = next['graphData']['tradePayable'];
      _this.payableCost = next['graphData']['costPayable'];
      _this.updateBarGraph();
    });
  }

  getNumber(number: number) {
    return this.commonService.formatNumber(number, '$', 'left', 'en-US');
  }

  updateColumns(type: string, value: any) {
    this.resetValue(type, value);
    this.getTcolumns();
  }

  onChangeTab(value: any) {
    this.clearAllFilter();
    this.resetFilters();
    this.operationDisplay = false;
    this.selectedCashflows = [];
    this.currentPageNumber = 0;
    this.showCalendar = !this.showCalendar;
    if(this.tabValue === 'fx') {
      this.fxCashflowValues = [];
      this.getCashflowByFilters(value['value']);
    } else {
      this.tradeCashflowValues = [];
      this.getCashflowByFilters(value['value']);
    }
  }

  onToolbarAction(value: any) {
    if (value['button'] === 'refresh') {
      this.getCashflowByFilters(this.tabValue);
    }
  }

  onClickable(value: any) {
    if (value['fieldName'] === 'tradeId') {
      let rowdata = value['data'];
      this.selctedTradeId = rowdata['tradeId'];
      this.tradeInfoDisplay = true;
      this.rowData = rowdata;
    }
  }

  onCloseTradeInfo(value) {
    this.tradeInfoDisplay = false;
  }

  onClickableFX(value: any) {
    if (value['fieldName'] === 'fxTradeId') {
      let rowdata = value['data'];
      this.selctedFXTradeId = rowdata['fxTradeId'];
      this.fxInfoDisplay = true;
      this.fxrowData = rowdata;
    }
  }

  onFxClose(value: any) {
    this.fxInfoDisplay = false;
  }

  private isInvoiceDone(cashflow) {
    return !(cashflow['invoiceStatus'] === null || cashflow['invoiceStatus'] === undefined || cashflow['invoiceStatus'].length === 0);
  }

  onRowSelectChange(value: any) {
    this.selectedCashflows = value;
    this.operationDisplay = false;
    if (this.selectedCashflows.length == 1) {
      let _this = this;
      this.operationDisplay = this.selectedCashflows.filter(cashflow => ((cashflow['quantityStatus'].toLowerCase() === 'estimated') || _this.isInvoiceDone(cashflow) || cashflow['type'].toLowerCase().includes("reversal"))).length <= 0;
    }
  }

  cashFlowCheck() {
    let firstCF = {};
    this.plannedObligationId = this.selectedCashflows[0]['plannedObligationId'];
    this.tradeId = this.selectedCashflows[0]['tradeId'];
    firstCF = this.selectedCashflows[0];
    this.selectedCashflows.forEach((cf, index) => {
      if (cf['counterparty'] === firstCF['counterparty'] && cf['financialAmountType'] === firstCF['financialAmountType'] && cf['settlementCurrency'] === firstCF['settlementCurrency']) {
        if (index === (this.selectedCashflows.length - 1)) {
          if (firstCF['financialAmountType'] === 'payable') {
            this.generateInvoice(false);
          } else {
            this.generateInvoice(true);
          }
        }
        if (firstCF['plannedObligationId'] !== cf['plannedObligationId']) {
          this.plannedObligationId = '';
        }
        if (firstCF['tradeId'] !== cf['tradeId']) {
          this.tradeId = '';
        }
        return;
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Selected cashflows are not eligible.'
        });
      }
    });
  }

  //ON CLICK OF BOTTOM TOOLBAR BUTTON TO OPEN GENERATE INVOICE FORM
  generateInvoice(mode) {
    this.mode = mode;
    this.openDialog();
  }

  //Opening Dialog and filling Invoice form with default values
  openDialog() {
    let _this = this;
    let cashflowType:string = this.selectedCashflows[0]['type'];
    let type = '';
    let typeCheck = false;
    let draftInvoicePayload: {},
        invoiceType: string;
    invoiceType = this.mode ? 'sell' : 'purchase';
    if(cashflowType.toLowerCase().includes('premium')) {
      invoiceType = invoiceType + ' ' + 'Premium'
    } else if(cashflowType.toLowerCase().includes('discount')) {
      invoiceType = invoiceType + ' ' + 'Discount'
    }
    draftInvoicePayload = {
      invoiceType: invoiceType,
      cashFlowList: this.selectedCashflows,
      counterPartyName: this.selectedCashflows[0]['counterparty'],
      company: this.selectedCashflows[0]['company']
    };
    this.commonService.getJSONByObject(environment.base_url + '/api/invoice/v1/generatedraftinvoice?tenantId=' + this.commonService.getTenantId(), draftInvoicePayload).subscribe((next: any) => {
      this.draftData = next;
      this.draftForm = true;
      this.operationDisplay = false;
    }, error => {
        this.messageService.add({summary:error['error'], severity:'error'});
    });
    if ((this.selectedCashflows[0]['type'] == 'Trade Debit Note') || ((this.selectedCashflows[0]['type'] == 'Trade Credit Note'))) {
      type = 'Trade Reversal';
      typeCheck = true;
    }
    if ((this.selectedCashflows[0]['type'] == 'Cost Debit Note') || ((this.selectedCashflows[0]['type'] == 'Cost Credit Note'))) {
      type = 'Cost Reversal';
      typeCheck = true;
    }
    if (type == 'Trade Reversal' && typeCheck) {
      let cashflowCriteria:any[] = [];
      cashflowCriteria.push({fieldName:'tenantId',condition:'equals',value:this.commonService.getFromStorage('tenantId')});
      cashflowCriteria.push({fieldName:'plannedObligationId',condition:'equals',value:this.selectedCashflows[0]['plannedObligationId']});
      cashflowCriteria.push({fieldName:'quantityStatus',condition:'equals',value:this.selectedCashflows[0]['quantityStatus']});
      cashflowCriteria.push({fieldName:'stage',condition:'equals',value:this.selectedCashflows[0]['stage']});
      cashflowCriteria.push({fieldName:'type',condition:'equals',value:type});
      cashflowCriteria.push({fieldName:'cashflowStatus',condition:'equals',value:'active'});
      this.commonService.post(environment.base_url+'/api/cashflow/v1/getcashflowbycriteria',cashflowCriteria).subscribe((next:any[]) => {
        if(next !== null && next !== undefined && next.length >0) {
          _this.selectedCashflows.push(next[0]);
        }
      });
    }
    if (type == 'Cost Reversal' && typeCheck) {
      let cashflowCriteria:any[] = [];
      cashflowCriteria.push({fieldName:'tenantId',condition:'equals',value:this.commonService.getFromStorage('tenantId')});
      cashflowCriteria.push({fieldName:'costId',condition:'equals',value:this.selectedCashflows[0]['costId']});
      cashflowCriteria.push({fieldName:'quantityStatus',condition:'equals',value:this.selectedCashflows[0]['quantityStatus']});
      cashflowCriteria.push({fieldName:'stage',condition:'equals',value:this.selectedCashflows[0]['stage']});
      cashflowCriteria.push({fieldName:'type',condition:'equals',value:type});
      cashflowCriteria.push({fieldName:'cashflowStatus',condition:'equals',value:'active'});
      this.commonService.post(environment.base_url+'/api/cashflow/v1/getcashflowbycriteria',cashflowCriteria).subscribe((next:any[]) => {
        if(next !== null && next !== undefined && next.length >0) {
          _this.selectedCashflows.push(next[0]);
        }
      });
    }
  }

  showToast(msg, severity: string = 'success') {
    this.messageService.add({
      severity: severity,
      summary: msg
    });
  }

  //WHEN THE USER SENDS THE DRAFT INVOICE FOR APPROVAL
  onSendForApproval() {
    this.messageService.add({
      severity: 'info',
      summary: 'Success',
      detail: 'Invoice has been generated!'
    });
    this.selectedCashflows = [];
    this.refreshSettlement();
    this.draftForm = false;
  }

  //TO REFRESH THE SETTLEMENT TABLE
  refreshSettlement() {
    this.getCashflowByFilters(this.tabValue);
    this.getData();
  }

  onDraftClose() {
    this.operationDisplay = true;
    this.draftForm = false;
  }

  onClickRemove(type: string) {
    this.refreshFilter = false;
    this.filter =[];
    this.removeFilterFromAddedList(type);
    this.refreshFilter = true;
  }

  clearAllFilter() {
    this.refreshFilter = false;
    let _this = this;
    this.filter = [];
    let filterAdded = [...this.addedFilter];
    filterAdded.forEach(function (type) {
      _this.removeFilterFromAddedList(type);
    });
    this.refreshFilter = true;
  }

  getTypeLabel(type: any) {
    return type;
  }


  onClickCard(type: string) {
    let filter:Filter;
    this.filter = [];
    if (this.addedFilter.includes(type)) {
      this.addedFilter = [];
    } else {
      this.addedFilter = [];
      filter = new Filter('Stage','stage','equalsto');
      filter.setFirstValue(type);
      filter.setField(this.fxStage);
      this.filter.push(filter);
      this.addedFilter.push(type);
    }
  }

  removeFilterFromAddedList(type) {
    this.addedFilter.splice(this.addedFilter.indexOf(type), 1);
  }



  getSelectedClass(type: string) {
    let classSelected = '';
    if (type === 'Planned') {
      classSelected = 'cardSelectedUnplanned';
    } else if (type === 'Unplanned') {
      classSelected = 'cardSelectedDeliveryStarted';
    } else if (type === 'Actualized') {
      classSelected = 'cardSelectedDeliveryCompleted';
    }
    return classSelected;
  }

  private getTradeCostStats() {
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url+'/api/cashflow/v1/gettradecoststats?tenantId=' + this.commonService.getFromStorage('tenantId')).subscribe((next:any) => {
      // _this.updateValueCount(next);
    });
  }

  private getFxStats() {
    let _this =this;
    this.commonService.getJSONByURL(environment.base_url+'/api/cashflow/v1/getfxcashforwardstats?tenantId='+ this.commonService.getFromStorage('tenantId')).subscribe((next:any) => {
      _this.updateValueCountFx(next);
    });
  }


  getCountValue(cardName, countOf = 'tradeType'){
    if(this.summaryObject !== null &&
        this.summaryObject !== undefined && this.summaryObject !== {} &&
        this.summaryObject[cardName] !== null &&
        this.summaryObject[cardName] !== undefined &&
        this.summaryObject[cardName] !== {}) {
      return this.summaryObject[cardName][countOf];
    } else {
      return 0;
    }
  }

  private updateValueCountFx(value:any) {
    this.plannedTradeCount = value['planned']['total'];
    this.plannedCostCFCount = value['planned']['receivableCount'];
    this.plannedTradeCFCount = value['planned']['payableCount'];

    this.unplannedCount = value['unplanned']['total'];
    this.unplannedCostCFCount = value['unplanned']['receivableCount'];
    this.unplannedTradeCFCount = value['unplanned']['payableCount'];

    this.actualizedCount = value['actualized']['total'];
    this.actualizedCostCFCount = value['actualized']['receivableCount'];
    this.actualizedTradeCFCount = value['actualized']['payableCount'];
  }

  onFilterRefresh() {
    this.refreshFilter = false;
  }

  getSummary() {
    let _this = this;
    this.summaryObject = {};
    let payload = this.getCommonFilterPayload(false,false);
    this.commonService.post(environment.base_url + '/api/cashflow/v1/gettradecoststats?tenantId=' +
        this.commonService.getFromStorage('tenantId'),payload).subscribe((next: any) => {
      _this.summaryObject = next;
    });
  }

  onCardSelect(cardId: any) {
    // if (cardId === 'Final' || cardId === 'Provisional' || this.addedFilter.includes('Final') || this.addedFilter.includes('Provisional')){
    //   this.addedFilter = [];
    // }
    this.operationDisplay = false;
    this.addedFilter.push(cardId);
    this.currentPageNumber = 0;
    this.getCashflowByFilters();
  }

  onCardUnselect(cardId: any) {
    this.operationDisplay = false;
    this.addedFilter.splice(this.addedFilter.indexOf(cardId),1);
    this.currentPageNumber = 0;
    this.getCashflowByFilters();
  }

  private generateDefaultPreset() {
    this.presetList.push({title:'Forward 1 month',startDate:new Date(),endDate:this.commonService.addMonthsInDate(new Date(),1)});
    this.presetList.push({title:'Forward 2 months',startDate:new Date(),endDate:this.commonService.addMonthsInDate(new Date(),2)});
    this.presetList.push({title:'Forward 3 months',startDate:new Date(),endDate:this.commonService.addMonthsInDate(new Date(),3)});
    this.presetList.push({title:'Forward 6 months',startDate:new Date(),endDate:this.commonService.addMonthsInDate  (new Date(),6)});
    this.selectedPreset = this.presetList[0];
  }

  toggleDateRange() {
    this.showDateRange = !this.showDateRange;
  }

  getPresetTitle() {
    if(this.selectedPreset['title'] === 'Date Range') {
      return (this.commonService.getFormattedDate(this.selectedPreset['startDate'],'dd MMMM yyyy')+'-'+this.commonService.getFormattedDate(this.selectedPreset['endDate'],'dd MMMM yyyy'));
    }
    return this.selectedPreset['title']
  }

  onCancel(value) {
    this.showDateRange = false;
  }

  onPresetChange(preset: any) {
    this.selectedPreset = preset;
    this.currentPageNumber = 0;
    if(this.tabValue === 'fx') {
      this.getCashflowByFilters('fx');
    } else {
      this.getCashflowByFilters();
    }
    this.toggleDateRange();
    this.getSummary();
  }

  private createGridFilters() {
    let payload = this.getCommonFilterPayload(true);
    let _this = this;
    this.resetFilters();
    this.gridFilters = [];
    this.commonService.getJSONByObject(environment.base_url + '/api/cashflow/v1/getcashflowdashboardfilterdata?tenantId='+
        this.commonService.getTenantId()+'&cashflowType=' + this.tabValue, payload).subscribe((data:any) =>{
          if(this.tabValue === 'trade') {
            this.gridFilters.push({title:'Trade Id',list:this.masterCommonService.getListFromArray(data['tradeId'],false,false)});
            this.gridFilters.push({title:'Obligation Id',list:this.masterCommonService.getListFromArray(data['obligationId'],false,false)});
            this.gridFilters.push({title:'Transaction Type',list:[new KeyValue('Payable', 'payable'), new KeyValue('Receivable', 'receivable')]});
            this.gridFilters.push({title:'Settlement Type',list:[new KeyValue('Cost', 'Cost'), new KeyValue('Trade', 'Trade')]});
            this.gridFilters.push({title:'Currency',list:this.commonService.getCurrencyList(false)});
          } else if (this.tabValue === 'fx') {
            this.gridFilters.push({title:'Trade Id',list:this.masterCommonService.getListFromArray(data['tradeId'],false,false)});
            this.gridFilters.push({title:'Transaction Type',list:[new KeyValue('Payable', 'payable'), new KeyValue('Receivable', 'receivable')]});
            this.gridFilters.push({title:'Currency',list:this.commonService.getCurrencyList(false)});
          }

    });
  }

  private getCommonFilterPayload(addCardInfo:boolean = true,addFilterInfo:boolean = true) {
    let payload={};
    payload = {
      fromDate:this.selectedPreset['startDate'],
      toDate:this.selectedPreset['endDate']
    };
    if(addCardInfo) {
      if(this.tabValue === 'fx') {
        payload['estimated']=true;
        payload['accrued']=true;
        payload['invoicedFinal']=true;
        payload['invoicedProvisional']=true;
        payload['dnCn']=true;
      } else {
        payload['estimated']=this.addedFilter.includes("Estimated");
        payload['accrued']=this.addedFilter.includes("Accrued");
        payload['invoicedFinal']=this.addedFilter.includes("Final");
        payload['invoicedProvisional']=this.addedFilter.includes("Provisional");
        payload['dnCn']=this.addedFilter.includes("DNCN");
      }

    }
    if(addFilterInfo) {
      payload['tradeId'] = this.tradeIdList;
      payload['obligationId'] = this.obligationList;
      payload['transactionType'] = this.transactionTypeList;
      payload['settlementType'] = this.settlementTypeList;
      payload['currency'] = this.currencyList;
    }
    return payload;
  }

  private resetFilters() {
    this.tradeIdList = [];
    this.transactionTypeList = [];
    this.settlementTypeList = [];
    this.obligationList = [];
    this.currencyList = [];
    this.gridFilters = [];
    this.gridFilters.push({title:'Trade Id',list:[]});
    this.gridFilters.push({title:'Transaction Type',list:[]});
    this.gridFilters.push({title:'Settlement Type',list:[]});
    this.gridFilters.push({title:'Obligation Id',list:[]});
    this.gridFilters.push({title:'Currency',list:[]});
  }

  onFilterChange(value: any, dropdown) {
    let type = dropdown['title'];
    let selected = value['value'];
    if(type === 'Trade Id') {
      this.tradeIdList = selected;
    } else if(type === 'Transaction Type') {
      this.transactionTypeList = selected;
    }  else if(type === 'Settlement Type') {
      this.settlementTypeList = selected;
    } else if(type === 'Obligation Id') {
      this.obligationList = selected;
    } else if(type === 'Currency') {
      this.currencyList = selected;
    }
    this.currentPageNumber = 0;
    this.getCashflowByFilters(this.tabValue,false);
  }


  getCashflowByFilters(type:string = 'trade',loadFilters:boolean =true){
    if(loadFilters) {
      this.createGridFilters();
    }
    let payload = this.getCommonFilterPayload(true);
    let _this = this;
    this.selectedCashflows = [];
    if (this.tabValue === 'fx'){
      this.fxCashflowValues = [];
      this.fxRefreshing = true;
    } else {
      this.tradeCashflowValues = [];
      this.tradeRefreshing = true;
    }
    this.commonService.getJSONByObject(environment.base_url + '/api/cashflow/v1/getcashflowdashboardgriddata?tenantId='
        + this.commonService.getTenantId()+"&cashflowType=" + type+"&page="+this.currentPageNumber+"&size=20", payload)
        .subscribe((data:any) => {
          if (this.tabValue === 'fx'){
            _this.fxRefreshing = false;
            _this.fxCashflowValues = data;
          } else {
            _this.tradeRefreshing = false;
            _this.tradeCashflowValues = data;
          }
    },(error:HttpErrorResponse) => {
          _this.fxRefreshing = false;
          _this.tradeRefreshing = false;
        });
  }

  hideBottom() {
    this.operationDisplay = false;
    this.selectedCashflows = [];
  }

  onPageChangeTrade(pageNo: any) {
    this.currentPageNumber = pageNo;
    this.getCashflowByFilters('trade');
  }

  onPageChangeFX(pageNo: any) {
    this.currentPageNumber = pageNo;
    this.getCashflowByFilters('fx');
  }

  getPayload() {
    let _this = this;
    this.isLoadingInvoice = true;
    this.draftForm = true;
    this.operationDisplay = false;
    let plannedObligationId = this.selectedCashflows[0].plannedObligationId;
    let costIds = this.selectedCashflows.map(i => i.costId);
    this.selectedObject = this.selectedCashflows[0];
    let type = this.selectedObject['type'];
    let payload = {
      plannedObligationIds: [{plannedObligationId: plannedObligationId,splitSequenceNumber: this.selectedCashflows[0].splitSequenceNumber}],
      costIds: costIds,
      tradeUuid: '',
      tradeId: this.selectedCashflows[0].tradeId,
      advance : false,
      proforma : false,
      costOnly : (type.toLowerCase().includes("cost")),
      chargesOnly: (type.toLowerCase().includes("charges")),
      attachCost : this.commonService.getFromTenantConfig(false,"invoiceConfig","attachCost"),
      attachCharges: this.commonService.getFromTenantConfig(false, "invoiceConfig", "attachCharge"),
    };
    this.commonService.post(environment.base_url + '/api/invoice/v1/getdraftinvoicepayload',payload).subscribe((next: any) => {
      _this.draftData = next;
      _this.isLoadingInvoice = false;
    },(error:HttpErrorResponse) => {
      _this.isLoadingInvoice = false;
      _this.showToast(_this.commonService.getHttpErrorMessage(error,"Settlement"),'error');
    });
  }
}
