<p-multiSelect [(ngModel)]="selected" [options]="options" [disabled]="disabled" (ngModelChange)="convertIntoOutputFormat()">
    <ng-template  pTemplate="selectedItems">
        <div *ngIf="selected.length > 0">
            <span *ngFor="let selectedOption of selected">{{selectedOption}} {{(selected.indexOf(selectedOption) !== (selected.length -1))?' , ':' '}}</span>
        </div>
        <span *ngIf="selected.length === 0">Select</span>
    </ng-template>
    <ng-template let-option let-i="index" pTemplate="item">
        <div class="d-flex w-100">
            <p class="flex-grow-1 label">{{option.label}}</p>
            <label class="deflabel">Default</label>
            <input type="radio" value="{{option.value}}" [(ngModel)]="defvalue"  (ngModelChange)="onChangeDefault($event)">
        </div>
    </ng-template>
</p-multiSelect>
