import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Grid} from "../../../grid/grid.model";
import {MasterService} from "../../../masters/services/MasterService";
import {CostComponent} from "../cost.component";
import {CommonService} from "../../../services/common.service";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {nextMonthDisabled} from "@ng-bootstrap/ng-bootstrap/datepicker/datepicker-tools";
import {KeyValue} from "../../../grid/key-value.model";
import {MessageService} from 'primeng';
import {Tcolumn} from "../../../grid/tcolumn.model";
import {Validators} from "@angular/forms";

@Component({
  selector: 'addcost',
  templateUrl: './addcost.component.html',
  styleUrls: ['./addcost.component.css']
})
export class AddcostComponent implements OnInit {

  grid:Grid;

  constructor( public commonService: CommonService,private messageService: MessageService, private masterCommonService: MasterService,private route:ActivatedRoute) { }

  @Output() OnApply=new EventEmitter();
  @Output() OnCancel=new EventEmitter();
  @Input() costFor;
  @Input() linkTo;
  @Input() currency;
  @Input() uom;
  @Input() paymentDueDate;
  @Input() costObject:any = null;
  updatepayloadfunction: Function;
  editUrl: string = '';
  updateUrl:string=environment.base_url + '/api/cost/v1/update?tenantId=' + this.commonService.getFromStorage('tenantId')

  ngOnInit(): void {
    let costComponent:CostComponent = new CostComponent(this.commonService, this.messageService,this.masterCommonService,this.route) ;
    costComponent.ngOnInit();
    this.grid=costComponent.getgrid();
    this.grid.getColumn().get('costFor').setDefaultValue(this.costFor);
    this.grid.getColumn().get('linkTo').setDefaultValue(this.linkTo);
    this.grid.getColumn().get('paymentDueDate').setDefaultValue(this.paymentDueDate);
    this.grid.getColumn().get('costCurrency').setDefaultValue(this.currency);
    this.grid.getColumn().get('uom').setDefaultValue(this.uom);
    this.grid.getColumn().get('type').setDefaultValue('Secondary');
    this.grid.getColumn().get('costType').setDefaultValue('Per Unit');
    this.grid.getColumn().get('quantityOption').setDefaultValue('Schedule Quantity');
    this.grid.getColumn().get('costFor').setColumnType('LB');
    this.grid.getColumn().get('linkTo').setColumnType('LB');
    this.grid.getColumn().get('paymentType').setDefaultValue('Actual');
    this.grid.getColumn().get('taxApplicable').setDefaultValue(false);

    this.updatepayloadfunction =  function(value) {
      let data = value['data'];
      data['costChargesType']='cost';
      return data;
    }

    if(this.costObject !== null && this.costObject !== undefined && this.costObject !== '' && this.costObject !== {}){
       this.editUrl = environment.base_url + '/api/cost/v1/getbycostuuid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&uuid='+this.costObject.uuid;
    } else {
      this.editUrl = '';
    }
  }

  canceledNew() {
    this.OnCancel.emit();
  }

  OnFormClose(value:any){
    this.OnApply.emit();
  }
}
