<p-dialog #af [(visible)]="display" [baseZIndex]="25"
          [closeOnEscape]="false"
          [contentStyle]="{height: formHeight, overflow: 'scroll', overflowX:'hidden'}"
          [draggable]="false" [maximizable]="false" [modal]="true"
          [showHeader]="false"
          [style]="{width: '85%' , padding:'0px', background:'#ffffff'}">
    <div *ngIf="loading" style="margin: 20px;">
        <div class="loadingDiv">
            <p style="margin: 0;padding: 0;font-family: Raleway, sans-serif;font-size: 18px">Loading</p>
            <img class="loadingIconClass" src="../../../assets/images/doubleRingLoading.gif" alt="">
        </div>
    </div>
    <div *ngIf="!loading">
        <div class="formHeader">
            <p class="formHeading">{{'Generate '+invoiceObject['invoiceType']+' Invoice'}}</p>
        </div>
        <form [formGroup]="invoiceForm">
            <div class="formWrapper">
                <div style="padding-top: 20px">
                    <div class="row fieldCont">
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Invoice Date</p>
                            <div class="inputbox">
                                <div class="div-control">
                                    <input [bsConfig]="bsConfig"
                                           bsDatepicker
                                           autocomplete="off"
                                           class="form-control form-control-sm pickerInput"
                                           formControlName="invoiceDate" placeholder="Select Date" type="text" >
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Payment Due Date</p>
                            <div class="inputbox">
                                <div class="div-control">
                                    <input [bsConfig]="bsConfig"
                                           bsDatepicker
                                           class="form-control form-control-sm pickerInput"
                                           formControlName="paymentDueDate" placeholder="Select Date" type="text">
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!sell" class="col-md-4 fieldBox">
                            <p class="fieldName">Invoice Number</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" disabled formControlName="invoiceNumber" pInputText>
                            </div>
                        </div>
                        <div *ngIf="!sell" class="col-md-4 fieldBox">
                            <p class="fieldName">Counterparty Ref No.</p>
                            <div class="inputbox">
                                <input [attr.disabled]="!sell?'':null" class="form-control form-control-sm"
                                       formControlName="counterpartyRefNumber" pInputText>
                            </div>
                        </div>
                    </div>

                </div>
                <!--    COUNTERPARTY DETAILS-->
                <div class="fieldSection">
                    <p class="secHeader">Counterparty Details</p>
                    <div class="row fieldCont" style="display: flex">
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Counterparty</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" disabled formControlName="counterparty" pInputText>
                            </div>
                        </div>
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Address</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" disabled formControlName="counterpartyAddress" pInputText >
                            </div>
                        </div>


                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Contract No.</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" disabled formControlName="contractNumber" pInputText>
                            </div>
                        </div>

                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Commodity</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" disabled formControlName="commodity" pInputText>
                            </div>
                        </div>

                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Terms of Payment</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" disabled formControlName="termsOfPayment" pInputText>
                            </div>
                        </div>
                    </div>

                </div>
                <!--      Notify Party Details-->
                <div class="fieldSection">
                    <p class="secHeader">Notify Party Details</p>
                    <div class="row fieldCont" style="display: flex">
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Notify Party Name</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" formControlName="notifyPartyName" pInputText>
                            </div>
                        </div>
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Notify Party Address</p>
                            <div class="inputbox">
                                <textarea class="form-control form-control-sm" formControlName="notifyPartyAddress" pInputTextarea></textarea>
                            </div>
                        </div>
                    </div>

                </div>

                <!--      Company Details-->
                <div class="fieldSection">
                    <p class="secHeader">Company Details</p>
                    <div class="row fieldCont" style="display: flex">
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Company</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" disabled formControlName="company" pInputText>
                            </div>
                        </div>
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Company Address</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" disabled formControlName="companyAddress" pInputText>
                            </div>
                        </div>
                    </div>

                </div>
                <!--        shipment Details-->
                <div *ngIf="checkForShipment()" class="fieldSection">
                    <p class="secHeader">Shipment Details</p>

                    <div class="row fieldCont" style="display: flex">
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Shipment</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" disabled formControlName="shipment" pInputText>
                            </div>
                        </div>
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Port of Loading</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" disabled formControlName="portOfLoading" pInputText>
                            </div>
                        </div>
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Port of Discharge</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" disabled formControlName="portOfDischarging" pInputText>
                            </div>
                        </div>
                    </div>
                </div>

                <!--      B/L Details-->
                <div class="fieldSection">
                    <p class="secHeader">B/L Details</p>

                    <div class="row fieldCont" style="display: flex">
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">B/L Number</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm"
                                       formControlName="blNumber" pInputText>
                            </div>
                        </div>
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">B/L Date</p>
                            <div class="inputbox">
                                <div class="div-control">
                                    <input [bsConfig]="bsConfig"
                                           bsDatepicker
                                           class="form-control form-control-sm pickerInput"
                                           formControlName="blDate" placeholder="Select Date" type="text">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Vessel</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm"
                                       formControlName="vesselName" pInputText>
                            </div>
                        </div>
                    </div>
                </div>
                <!--        Quantity Details-->
                <div class="fieldSection">
                    <p class="secHeader">Quantity Details</p>

                    <div class="row fieldCont" style="display: flex">
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Quantity</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" disabled formControlName="quantity" pInputText>
                            </div>
                        </div>
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Unit Price</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" disabled formControlName="unitPrice" pInputText>
                            </div>
                        </div>
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Tolerance</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" disabled formControlName="tolerance" pInputText>
                            </div>
                        </div>
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Delivery Terms</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" disabled formControlName="deliveryTerms" pInputText>
                            </div>
                        </div>
                    </div>
                </div>

                <!--        BANK DETAILS-->
                <div class="fieldSection">
                    <p *ngIf="!sell" class="secHeader">Counterparty Bank Details</p>
                    <p *ngIf="sell" class="secHeader">Bank Details</p>
                    <div class="row fieldCont" style="display: flex">

                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Remit To</p>
                            <p-dropdown (ngModelChange)="throwRemitToChanges($event)" [disabled]="!sell"
                                        [options]="remitList" formControlName="remitTo"></p-dropdown>
                        </div>
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Bank Name</p>
                            <p-dropdown (ngModelChange)="throwChanges($event)" [options]="allBanks"
                                        formControlName="bankName"></p-dropdown>
                        </div>

                        <div class="col-md-4 fieldBox">
                        </div>

                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Beneficiary Account No.</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" disabled formControlName="beneficiaryAccNo"
                                       pInputText>
                            </div>
                        </div>

                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Bank Address</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" disabled formControlName="bankAddress" pInputText>
                            </div>
                        </div>

                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Swift Code</p>
                            <div class="inputbox">
                                <input class="form-control form-control-sm" disabled formControlName="swiftCode" pInputText>
                            </div>
                        </div>

                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Invoice Currency</p>
                            <p-dropdown (ngModelChange)="throwCurrencyChanges($event)"
                                        formControlName="currency"
                                        [options]="currencyList">
                            </p-dropdown>
                        </div>
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">FX Rate</p>
                            <div class="inputbox">
                                <input (focusout)="updateTotalForFxRate()" (ngModelChange)="onFXRateChange($event)"
                                       [step]="'0.001'" [type]="'number'" class="form-control form-control-sm" formControlName="fxRate"
                                       pInputText>
                            </div>
                        </div>
                    </div>

                </div>

                <!--      PaymentDetails-->
                <div class="fieldSection">
                    <p class="secHeader">Payment Details</p>
                    <div class="row fieldCont" style="display: flex">
                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Payment Ref No.</p>
                            <div class="inputbox">
                                <input [step]="'0.001'"
                                       [type]="'number'" class="form-control form-control-sm"
                                       formControlName="paymentRefNumber" pInputText>
                            </div>
                        </div>

                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Payment Date</p>
                            <div class="inputbox">
                                <div class="div-control">
                                    <input [bsConfig]="bsConfig"
                                           autocomplete="off"
                                           bsDatepicker class="form-control form-control-sm pickerInput"
                                           formControlName="paymentDate" placeholder="Select Date" type="text">
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4 fieldBox">
                            <p class="fieldName">Payment Amount</p>
                            <div class="inputbox">
                                <div class="div-control" style="width:100%">
                                    <input [type]="'number'" class="form-control form-control-sm"
                                           formControlName="paymentAmount" style="width: inherit;min-width: auto !important;border-top-right-radius: 0;border-bottom-right-radius: 0" pInputText>
                                    <p class="cfInnerTableField currencyBox" style="white-space: nowrap">
                                        {{getCurrency()}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--        TABLE FOR CASH FLOWS-->
                <div class="fieldSection">
                    <div class = "cfToolWrapper">
                        <button (click)="addAdjustmentClick()" class="adjustmntBtn">{{adjustmentRow?'Remove Adjustment':'Add Adjustment'}}</button>
                    </div>
                    <p-table #cashFlowGrid [columns]="cfCols" [value]="lineItems" >
                        <ng-template let-columns pTemplate="header">
                            <tr>
                                <th *ngFor="let col of columns">
                                    <p class="cfInnerTableHeader">{{col.header}}</p>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template let-columns="columns" let-rowData let-ri="rowIndex" pTemplate="body">
                            <tr>
                                <td *ngFor="let col of columns">

                                    <div *ngIf="(col['field'] !== 'settlementValue' && !col['editable'])">
                                        <p class="cfInnerTableField">
                                            {{getColValue(rowData,col)}}
                                        </p>
                                    </div>

                                    <div *ngIf="col['editable']">
                                        <input  [value]="getColValue(rowData,col)"
                                                (change)="changeNarrationValue(rowData,col,$event)" pInputText>
                                    </div>

                                    <div *ngIf="col['field'] === 'settlementValue'">
                                        <div *ngIf="isAdvance()" style="display: inline-flex;width: 100%">
                                            <input [value]="rowData[col['field']]"
                                                   style="width: inherit;text-align: right"
                                                   type="number"
                                                   min="0"
                                                   onkeypress="return event.charCode >= 48"
                                                   (change)="changeSettlementValue(rowData,col,$event,ri)" pInputText>
                                            <p class="cfInnerTableField" style="white-space: nowrap">
                                                {{getCurrency()}}
                                            </p>
                                        </div>
                                        <div *ngIf="!isAdvance()">
                                            <p class="cfInnerTableField" style="text-align: right">
                                                {{getColValue(rowData,col)}}
                                            </p>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr *ngIf="adjustmentRow">
                                <td>
                                    <p class="cfInnerTableField">Adjustment Amount</p>
                                </td>
                                <td></td>
                                <td>
                                    <div  class="adjustmentBox">
                                        <input [(ngModel)]="adjustmentAmountDescription" [ngModelOptions]="{standalone: true}"
                                               class="form-control form-control-sm" style="text-align: right"
                                               pInputText>
                                    </div>
                                </td>
                                <td>
                                    <p class="cfInnerTableField" style="text-align: right">{{adjustmentPrice}}</p>
                                </td>
                                <td>
                                    <div class="adjustmentBox">
                                        <input (focusout)="updateTotal()" (ngModelChange)="checkEmpty($event)"
                                               [(ngModel)]="adjustAmount" [ngModelOptions]="{standalone: true}"
                                               type="number"
                                               class="form-control form-control-sm"
                                               style="text-align: right;min-width: auto !important;"
                                               pInputText>
                                        <p class="cfInnerTableField" style="white-space: nowrap">
                                            {{getCurrency()}}
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>



                    <div class="totalAmountContainer">
                        <div class="totalClass">Total</div>
                        <div class="totalAmount">{{commonService.getFormatedNumber(totalAmount)}} {{getCurrency()}}</div>
                    </div>
                    <div class="totalAmountContainer">
                        <div class="totalClass">Total In Words</div>
                        <div class="totalAmount">{{totalInWords}}</div>
                    </div>
                </div>

                <div class="fieldSection">
                    <div class="row fieldCont" style="display: flex">
                        <div class="col-md-12 fieldBox">
                            <p class="fieldName">Purpose</p>
                            <textarea [autoResize]="false"
                                      [rows]="1"
                                      class="form-control form-control-sm textAreaClass" formControlName="purpose"
                                      pInputTextarea
                                      placeholder="Enter Purpose"
                                      type="text"></textarea>
                        </div>
                        <div *ngIf="!sell" class="col-md-4 fieldBox">
                            <p class="fieldName">Attachments</p>
                            <div class="inputbox">
                                <ctrm-file-picker
                                        (onFileSelected)="onChange($event)"></ctrm-file-picker>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="border-top: 1px solid #e8e8e8; height: 20px"></div>
                <div class="row fieldCont" style="display: flex">
                    <div class="col-md-4 fieldBox">
                        <p class="fieldName">Trader</p>
                        <div class="inputbox">
                            <input class="form-control form-control-sm" disabled formControlName="traderName" pInputText>
                        </div>
                    </div>

                    <div class="col-md-4 fieldBox">
                        <p class="fieldName">Invoice Generated By</p>
                        <div class="inputbox">
                            <input class="form-control form-control-sm" disabled formControlName="invoiceGeneratedBy" pInputText>
                        </div>
                        <p class="dateLabel">{{invoiceDate | date: 'dd MMM yyyy'}}</p>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <button (click)="preview()" [disabled]="loading" class="planScreenBtns">Preview</button>
        <button (click)="onFormCancel()" class="cancelBtn">Cancel</button>
        <button (click)="generateInvoice()" [disabled]="loading || generating" class="planScreenBtns">Generate</button>
    </p-footer>
</p-dialog>

<doc-viewer (OnClose)="onCloseDocViewer()" [docUrl]="docUrl" [fileName]="docName"
            [modalState]="showDoc"></doc-viewer>
<xceler-pdf-viewer (onViewerClose)="onViewerClose()"
                   [fileName]="'Preview Invoice Document'"
                   [base64src]="previewBytes"
                   [errorMessage]="errorMessage"
                   [modalState]="viewerState"></xceler-pdf-viewer>
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}" appendTo="body">
</p-toast>
<p-confirmDialog [baseZIndex]="9999" header="Confirmation"
                 icon="pi pi-exclamation-triangle"></p-confirmDialog>
