<div class="sectionCardTop">
    <div class="breadcrumbPane">
        <ctrm-breadcrumb [collapsabel]="false"
                         [items]="breadCrumbService.getBreadCrumb()"
                         [showIcon]="false"></ctrm-breadcrumb>
    </div>
    <div class="actionPane">
        <div class="screenDetails">
            <img [src]="'../../../assets/images/svg/globalIndicator.svg'"/>
            <p>Chart Of Accounts</p>
        </div>
        <div class="rightInfo">

        </div>
    </div>
</div>

<div class="bothSection">
<!--    Left Section-->
    <div class="leftSection">
        <div class="glSection" (click)="GLStructureGrid()" [ngClass]="selected === 'glStructure'?'selectedClass':''">
            <p>GL STRUCTURE MAINTENANCE</p>
        </div>
        <div class="glSection" style="margin-top: 10px !important;"
             (click)="GlAccountsGrid()" [ngClass]="selected === 'glAccount'?'selectedClass':''">
            <p>GL ACCOUNT MAINTENANCE</p>
        </div>
    </div>
<!--    Right Section-->
    <div class="backgroud">
        <div class="rightSection">
            <!--    GL Structure-->
            <div *ngIf="showGlStructure">
                <ctrm-grid *ngIf="loadDataList !== undefined && loadDataList.length != 0"
                           [grid]="glStructureGrid"
                           [showSectionTop]="false"
                           [showBreadcrumb]="false">
                </ctrm-grid>
            </div>

            <!--    GL Accounts-->
            <div *ngIf="showGlAccounts">
                <ctrm-grid *ngIf="loadDataList !== undefined && loadDataList.length != 0"
                           [grid]="glAccountsGrid"
                           [showSectionTop]="false"
                           [showBreadcrumb]="false">
                </ctrm-grid>
            </div>

        </div>
    </div>


</div>


