<div class="matchPlanButtonDiv">
    <button *ngIf="!isLoading"
            (click)="onButtonClick()"
            [ngStyle]="buttonStyle"
            [disabled]="disabled" class="progressbutton">{{buttonIdleTitle}}
    </button>
    <div *ngIf="isLoading" class="loadingbutton" [ngStyle]="buttonStyle">
        <div class="loader"></div>
        <p>{{buttonLoadingTitle}}</p>
    </div>
</div>
