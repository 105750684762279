<p-contextMenu  #cm [autoZIndex]="true"></p-contextMenu>
<div class="d-inline-flex" [ngClass]="getButtonClass()"
     style="user-select: none" (click)="showPanel($event,cm)">
    <img *ngIf="img !== null && img !== undefined && img.length > 0" src="{{img}}" class="iconMenu ml-1"
         alt="Context Menu">
    <i *ngIf="!(img !== null && img !== undefined && img.length > 0)" class="{{icon}} ml-1"></i>
    <p *ngIf="title !== null && title !== undefined && title.length > 0" class="p-0 m-0 ml-1">{{title}}</p>
</div>
<p-dialog (onHide)="closeModal()" *ngIf="modalState" [(visible)]="modalState" [autoZIndex]="true"
          [closable]="false" [appendTo]="'body'"
          [closeOnEscape]="true"
          [contentStyle]="" [draggable]="false" [maximizable]="false" [modal]="true"
          [positionTop]="96" [style]="{ height: 'auto',minWidth:'500px',width:'auto',padding:'0px'}">
    <p-header>
        <p class="formheader">{{currentApprovalObject.buttonName}} Confirmation</p>
    </p-header>
    <div class="d-flex flex-column p-2">
        <p class="subhead">Do you want
            to {{currentApprovalObject.buttonName}} {{screenName}} “{{nameField}}”?</p>
        <textarea [(ngModel)]="note" class="inputbox" pInputTextarea
                  placeholder="Note" rows="4"></textarea>
        <div class="d-flex flex-row-reverse">
            <xceler-progress-button
                    (onError)="onError($event)"
                    (onLoadDone)="onDone($event)"
                    [buttonIdleTitle]="currentApprovalObject.buttonName"
                    [buttonLoadingTitle]="currentApprovalObject.buttonName"
                    [buttonStyle]="{margin:'0px',minHeight:'35px',padding:'0px',paddingLeft:'10px',paddingRight:'10px'}"
                    [callApiObjectFunction]="apiFunc">
            </xceler-progress-button>
            <button (click)="closeModal()" class="cancelBtn">Cancel</button>
        </div>
    </div>
</p-dialog>
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body">
</p-toast>
