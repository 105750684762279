import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {multiSelectPojo} from "./multiSelectPojo.model";

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CtrmMultiselectComponent),
  multi: true
};

@Component({
  selector: 'ctrm-multiselect',
  templateUrl: './ctrm-multiselect.component.html',
  styleUrls: ['./ctrm-multiselect.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class CtrmMultiselectComponent implements OnInit, ControlValueAccessor, OnChanges,AfterViewInit {
  @Input('value') val: any;
  @Input('name') name: string = '';
  @Input() list: any;
  @Input() msClass: any;
  @Input() mclass: any;
  @Output() onSelectAll = new EventEmitter();
  selectedItems: any[] = [];
  @Input() outputFormat: any = 'array';
  @Input() outputSeprator: any = ',';
  @Input() placeholder: any = 'Select ';
  @Input() disabled: boolean = false;
  @Output() onChangeValue = new EventEmitter();
  output: any = '';
  @Input() allowSearch: boolean = false;
  @Input() multiple: boolean = false;
  dropDownList: multiSelectPojo[] = [];

  constructor(private changeDetectorRef:ChangeDetectorRef) {
  }

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  onTouched: any = () => {
  };

  onChange(val) {
    this.val = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
      this.convertIntoInputFormat(obj);
      this.value = obj;

  }

  /**
   * To Convert the selected Values form MultiSelect list into Output Format, which is
   * Comma Separated values
   */
  convertIntoOutputFormat() {
    let _this = this;
    this.output = '';
    if (this.outputFormat === 'string') {
      this.selectedItems.forEach(function (item, i) {
        _this.output += item['code'];
        if (i < _this.selectedItems.length - 1) {
          _this.output += _this.outputSeprator
        }
      });
      this.value = this.output;
    } else {
      this.value = this.selectedItems.map(item => item.code);
    }
    if(this.selectedItems.length === this.dropDownList.length) {
      this.onSelectAll.emit();
    } else {
      this.onChangeValue.emit(this.value);
    }
  }

  /**
   * Convert into input Format in which Multi Select Component requires
   * @param value  //value to format
   */
  convertIntoInputFormat(value) {
    let _this = this;
    let split = [];
    this.selectedItems = [];
    if (this.outputFormat === 'string') {
      split = value.split(_this.outputSeprator);
      _this.dropDownList.forEach(function (item: multiSelectPojo) {
        if (split.includes(item.getCode())) {
          _this.selectedItems.push(item['value']);
        }
      });
    } else {
      _this.dropDownList.forEach(function (item: multiSelectPojo) {
        if (value?.includes(item.getCode())) {
          _this.selectedItems.push(item['value']);
        }
      });
    }
  }

  /**
   * Populating DropDown List for multi select component
   */
  ngOnInit() {
    this.updateList();
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  updateList() {
    let _this = this;
    this.dropDownList = [];
    this.list.forEach(function (pair: any, i) {
      _this.dropDownList.splice(i, 0, new multiSelectPojo(pair['label'], {
        id: (i + 1),
        name: pair['label'],
        code: pair['value']
      }));
    });
  }

  onItemSelect(item: any) {
    this.convertIntoOutputFormat();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['list']) {
      this.list = changes['list'].currentValue;
      this.updateList();
    }

  }

}
