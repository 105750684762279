<div class="accordianBox">
  <div [ngClass]="contentHeight !== '0px'?'headerSelected':''" class="headerBox">
    <div (click)="expandContent()" class="clickable">
      <i [ngClass]="contentHeight !== '0px'?'fa-chevron-up':'fa-chevron-down'" class="iconClass fas"></i>
      <p class="heading">{{data['info']['screenName']}}</p>
    </div>
    <div class="contentHeader">
      <div *ngFor="let name of childKeys" class="childData">
        <p [ngClass]="true?'nameEnable':'nameDisable'"
           class="nameHeading">{{name}}</p>
        <ui-switch (ngModelChange)="onChange($event,name)"
                   [(ngModel)]="toggleConfig[name].checked"
                   [checkedLabel]=""
                   [checkedTextColor]="'#fff'"
                   [color]="toggleConfig[name].color"
                   [defaultBgColor]="'#d8d8d8'"
                   [disabled]="disable"
                   [size]="'small'"></ui-switch>
      </div>
    </div>
  </div>
  <div [style.height]="contentHeight" class="contentBox style-4">
    <div [hidden]="contentHeight !== 'fit-content'" class="content">
      <div *ngFor="let child of childKeys;index as index" class="childData">
        <div *ngFor="let cdata of getKeys(child)" class="checkBoxList">
          <p-checkbox (onChange)="checkAndThrow(child)" [(ngModel)]="data[child][cdata]"
                      [disabled]="!toggleConfig[child].checked  || disable" binary="true"
                      label="{{decamelize(cdata,' ') | titlecase}}" value="{{data[child][cdata]}}"></p-checkbox>
        </div>
      </div>
    </div>
  </div>
</div>
