<ctrm-grid (onClickUniqueKey)="onUniqueClick($event)" (onRefreshComplete)="onRefreshComplete($event)" [refreshGrid]="refreshGrid"
           [breadcrumbMapKey]="commonService.OPERATION_MAP_KEY" [currentRoute]="'/transportActualization'" [extraViewTemplate]="cardinfo"
           [grid]="grid" [refreshFilter]="refreshFilter" [gridFilterDropdownList]="gridFilters"
           [selectedRow]="selectedRows"
           [defaultCopy]="false"
           (onClickDuplicate)="onCopy($event)"
           (selectedRows)="onRowSelectionChange($event)"
           (onExpandRowSelectionChange)="onExpandRowSelectionChange($event)"
           (onInlineEditClick)="onEditClick($event)"
           [toolbarConfig]="toolbarConfig"
           [fieldsWithCustomViewInExpand]="['priceAllocationStatus', 'actualizationStatus']"
           [customViewTemplatedInExpand]="[priceAllocationStatusTemplate]"
           (onDropdownFilterValueChange)="onFilterChange($event)"
           [showFilterChips]="false"></ctrm-grid>



<!--Price allocation Template-->
<ng-template #priceAllocationStatusTemplate let-rowData="rowData">
    <ctrm-hover-icon [buttonNameList]="[[],
                                       ['Request for Trade Pricing'],['Price Allocation']
                                       ,['See All PriceAllocation Details'],['Price Allocation']]"
                     [iconNameList]="['Fixed Priced','Not Priced','Not Allocated','Fully Allocated','Partially Allocated']"
                     [iconList]="['Fixed Price.svg','reddollar.svg','reddollar.svg','greendollar.svg','yellow.svg']"
                     [templateList]="[null,notPriced,notAllocated,partiallyAllocated,partiallyAllocated]"
                     [selectedIconName]="'Fixed Priced'"
                     [iconDecidingFunction]="priceAllocationiconPath"
                     [header]="'Trade Pricing Details'"
                     (onHover)="onHover($event)"
                     (onButtonClick)="onButtonClick($event)"
                     [loadApiFunction]="tradePricingLoadApiFunction"
                     [hideForIcons]="['Fixed Priced']"
                     [rowData]="rowData" ></ctrm-hover-icon>
</ng-template>

<ng-template #notPriced>
    <div>
        <div  style="background-color: #F0F0F0;padding-left: 41px;padding-right: 4px;padding-top:21px;padding-bottom: 15px;">
            <i class="fa fa-warning" style="font-size:17px;">Trade Pricing and Price Allocation Not Available</i>
        </div>
    </div>
</ng-template >

<ng-template #notAllocated let-dbResponse="dbResponse">
    <div>
        <div class="priceAdding">
            <div class="fieldBox">
                <p class="fieldName">Priced Quantity</p>
                <p class="bodyContent"> {{commonService.getFormatedNumber(dbResponse[0]['pricedQuantity'])+' '+dbResponse[0]['priceUomName']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Average Pirce</p>
                <p class="bodyContent"> {{commonService.getFormatedNumber(dbResponse[0]['averagePrice']) + ' ' + dbResponse[0]['priceCurrency'] + '/' + dbResponse[0]['priceUomName']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Pricing Status</p>
                <p class="bodyContent"> {{dbResponse[0]['priceStatus']}}</p>
            </div>

        </div>
    </div>
    <hr>
    <p style=" color: #15104E;  font-weight: 600; margin-left: 10px;">Price Allocation</p>
    <div>
        <div  style="background-color: #F0F0F0;padding-left: 41px;padding-right: 4px;padding-top:21px;padding-bottom: 15px; margin: 10px">
            <i class="fa fa-warning" style="font-size:17px;"> Price Allocation Details Not Available</i>
        </div>
    </div>

</ng-template >

<ng-template #partiallyAllocated let-dbResponse="dbResponse" let-rowData="rowData">
    <div>
        <div class="priceAdding">
            <div class="fieldBox">
                <p class="fieldName">Priced Quantity</p>
                <p class="bodyContent"> {{commonService.getFormatedNumber(dbResponse[0]['pricedQuantity'])+' '+dbResponse[0]['priceUomName']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Average Pirce</p>
                <p class="bodyContent"> {{commonService.getFormatedNumber(dbResponse[0]['averagePrice']) + ' ' + dbResponse[0]['priceCurrency'] + '/' + dbResponse[0]['priceUomName']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Pricing Status</p>
                <p class="bodyContent"> {{dbResponse[0]['priceStatus']}}</p>
            </div>

        </div>
    </div>
    <hr>
    <p style=" color: #15104E;  font-weight: 600; margin-left: 10px;">Price Allocation</p>
    <div>
        <div class="priceAdding">
            <div class="fieldBox">
                <p class="fieldName"> Quantity</p>
                <p class="bodyContent"> {{commonService.getFormatedNumber(dbResponse[0]['allocationQunaity'])+' '+dbResponse[0]['priceUomName']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName"> Price</p>
                <p class="bodyContent"> {{commonService.getFormatedNumber(dbResponse[0]['allocationPrice']) + ' ' + dbResponse[0]['priceCurrency'] + '/' + dbResponse[0]['priceUomName']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName"> Status</p>
                <p class="bodyContent"> {{rowData['priceAllocationstatus']}}</p>
            </div>

        </div>
    </div>
</ng-template >





<ng-template #cardinfo>
    <div class="statsbox">
        <div style="display: inline-flex">
            <p class="statHeader">Partially Actualized</p>
            <p class="statValue">{{statObj['partiallyActualized']}}</p>
        </div>
        <div style="display: inline-flex">
            <p class="statHeader">Actualized</p>
            <p class="statValue">{{statObj['actualized']}}</p>
        </div>
        <div style="display: inline-flex">
            <p class="statHeader">Delivery Started</p>
            <p class="statValue">{{statObj['deliveryStarted']}}</p>
        </div>
    </div>
</ng-template>
<xceler-bottom-pane *ngIf="bottomPaneDisplay" [popupDisplay]="bottomPaneDisplay" [rightViewTemplate]="right" (onClose)="onCloseBottomPane()"></xceler-bottom-pane>
<ng-template #right>
    <button *ngIf="!selectedChildRow" (click)="actualizePlan()" [disabled]="!actualizeDisabled" class="planScreenBtns" style="margin: 0; margin-right: 15px; width: 100px !important;color: white !important;">Actualize</button>
    <button *ngIf="selectedChildRow" [disabled]="checksettel() && !deactualizeDisabled" (click)="deactualizePlan()" class="planScreenBtns" style="margin: 0; margin-right: 15px; width: 100px !important;color: white !important;">De-Actualize</button>
</ng-template>
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>
<actualization-form *ngIf="displayForm"
                    [vesselPop]="true"
                    [vesselId]="selectedVesselObj['vesselId']"
                    [planId]="selectedVesselObj['planId']"
                    [voyageNo]="selectedVesselObj['voyageNo']"
                    [mode]="formMode"
                    [claimAccess]="claimAccess"
                    [undoClaimAccess]="undoClaimAccess"
                    [dialogDisplay]="displayForm"
                    (onClose)="onCloseForm()"></actualization-form>
<p-confirmDialog [baseZIndex]="25" header="Confirmation"
                 icon="pi pi-exclamation-triangle"></p-confirmDialog>
