<ngx-intl-tel-input
  (ngModelChange)="onChangeValue($event)"
  [(ngModel)]="value"
  [cssClass]="telClass"
  [disabled]="disabled"
  [enableAutoCountrySelect]="false"
  [enablePlaceholder]="true"
  [maxLength]="18"
  [phoneValidation]="true"
  [preferredCountries]="preferredCountries"
  [searchCountryFlag]="false"
  [searchCountryPlaceholder]="'Search Country'"
  [selectFirstCountry]="false"
  [selectedCountryISO]="selectedCountry"
  [separateDialCode]="separateDialCode"
  class="{{tClass}}">
</ngx-intl-tel-input>
