import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonService} from '../services/common.service';
import {Router} from '@angular/router';

@Component({
  selector: 'notification-and-alert',
  templateUrl: './notification-and-alert.component.html',
  styleUrls: ['./notification-and-alert.component.css']
})
export class NotificationAndAlertComponent implements OnInit {

  @Input() notifications: any[] = [];
  @Input() showHeader: boolean = true;

  @Output() onActionClick = new EventEmitter();
  notificatonList: any[] = [];
  alertList: any[] = [];
  constructor(private commonService: CommonService, private router: Router) { }

  ngOnInit(): void {

  }

  getNotifTime(timestamp) {
    return this.timeDifference(new Date(new Date().toUTCString()), timestamp);
  }


  timeDifference(date1,date2) {
    let difference = date1.getTime() - new Date(date2).getTime();
    let daysDifference = Math.floor(difference/1000/60/60/24);
    difference -= daysDifference*1000*60*60*24

    let hoursDifference = Math.floor(difference/1000/60/60);
    difference -= hoursDifference*1000*60*60

    let minutesDifference = Math.floor(difference/1000/60);
    difference -= minutesDifference*1000*60

    let secondsDifference = Math.floor(difference/1000);


    if (daysDifference !== 0){
      return daysDifference + ' day(s) ago'
    }
    if (hoursDifference !== 0){
      return hoursDifference + ' hour(s) ago'
    }
    if (minutesDifference !== 0){
      return minutesDifference + ' minute(s) ago'
    }
    if (secondsDifference !== 0){
      if (secondsDifference < 60){
        return 'Just Now'
      }
      return secondsDifference + ' second(s) ago'
    }
  }

  switchContext(notif: any) {
    if (notif['url'] !== undefined && notif['url'] !== null) {
      let notifUrlInfo = notif['url'].split('/');
      let targetUrl = ''
      notifUrlInfo.forEach((info, index) =>{
        if (index > 0){
          if (index !== notifUrlInfo.length - 1){
            targetUrl = targetUrl + info + '/';
          }else{
            targetUrl = targetUrl + info;
          }
        }
      });
      // this.router.navigate(['/'+notifUrlInfo[0]],{ queryParams: { value: targetUrl } });
      window.location.href = notif['url'];
      this.onActionClick.emit(notif);
    }
  }
}
