import {Component, OnInit} from '@angular/core';
import {Tcolumn} from '../../../grid/tcolumn.model';
import {Grid} from '../../../grid/grid.model';
import {MasterService} from '../../services/MasterService';
import {ActivatedRoute} from '@angular/router';
import {CommonService} from '../../../services/common.service';
import {Validators} from '@angular/forms';
import {defaultValue} from '../../../grid/defaultValue.model';


@Component({
    selector: 'app-paymentevent',
    templateUrl: './paymentevent.component.html',
    styleUrls: []
})
export class PaymenteventComponent implements OnInit {
    id: string = '';
    columns: Map<string, Tcolumn>;
    grid: Grid;


    constructor(private router: ActivatedRoute, private commonService: CommonService, private masterCommService: MasterService) {
        this.router.params.subscribe(params => {
            this.id = params['id'];
        });
    }

    ngOnInit() {
        this.getColumns();
        this.grid = new Grid(this.columns, true, true, true, true, 'paymentTermCode', 'paymentEvent/search/findByPaymentTermCodeOrderByIdDesc?paymentTermCode=' + this.id, '', 'Payment Event', 'paymentTermCode');
        this.grid.iconName = 'paymentTerm';
        this.grid.setFirstLevelDataUrl('/paymentTerm');
    }

    public getColumns() {       //setting columns in Grid Component
        this.columns = new Map();
        this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
        this.columns.set('name', new Tcolumn('name', 'Payment Term Name', 'LB', 1, false, undefined, false, this.id));
        this.columns.set('paymentDueOffsetDays', new Tcolumn('paymentDueOffsetDays', 'Payment Offset Due Days', 'N', 2, true, undefined, true, new defaultValue('', '', []), 'B', [Validators.min(0)]));
        this.columns.set('paymentDueEvent', new Tcolumn('paymentDueEvent', 'Payment Due Event', 'L', 3, true, this.masterCommService.getListFromUrl('globalIndicatorDetails/gid/event_type_ind', 'globalIndicatorCode', 'globalIndicatorCode'), true, '', 'B', [Validators.required]));
        this.columns.set('period', new Tcolumn('period', 'Period', 'L', 4, true, this.masterCommService.getListFromUrl('globalIndicatorDetails/gid/payment_period_ind', 'globalIndicatorCode', 'globalIndicatorCode'), true, '', 'B', [Validators.required]));
        this.columns.set('offsetBefore', new Tcolumn('offsetBefore', 'Offset Before', 'SL', 5, true, undefined, true, 10, 'B', [Validators.min(0)], undefined, 1, 0, 90));
        this.columns.set('offsetAfter', new Tcolumn('offsetAfter', 'Offset After', 'SL', 6, true, undefined, true, 10, 'B', [Validators.min(0)], undefined, 1, 0, 90));
        this.columns.set('inclusiveOfEventDay', new Tcolumn('inclusiveOfEventDay', 'Inclusive of Event Day', 'B', 7, true, this.masterCommService.getYesNo(), true, false, 'B', [Validators.required]));
    }
}
