import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => XcelerNumberSpinnerComponent),
  multi: true
};

@Component({
  selector: 'xceler-number-spinner',
  templateUrl: './xceler-number-spinner.component.html',
  styleUrls: ['./xceler-number-spinner.component.css'],
  providers:[CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class XcelerNumberSpinnerComponent implements OnInit {

  @Input() maxNumber:number = 365;
  @Input() minNumber:number = 0;
  @Input() disabled:boolean = false;
  @Input('value') val: number = 0;
  @Input('name') name: string = '';
  viewEditable:boolean = false;

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  onTouched: any = () => {
  };

  onChange(val) {
    this.val = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

  down() {
    if(this.minNumber !== null && this.minNumber !== undefined) {
      if(this.value > this.minNumber) {
        this.value--;
      }
    } else {
      this.value--;
    }
  }

  up() {
    if(this.maxNumber !== null && this.maxNumber !== undefined) {
      if (this.value < this.maxNumber) {
        this.value++;
      }
    } else {
      this.value++;
    }
  }

}
