import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CommonService} from "../../services/common.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-rpa-result',
  templateUrl: './rpa-result.component.html',
  styleUrls: ['./rpa-result.component.css']
})
export class RpaResultComponent implements OnInit,OnChanges {

  @Input()  modalState:boolean = false;
  @Input()  header:string = '';
  @Input() response:any;
  @Output() onCloseModal = new EventEmitter();
  pdfbytes:any;
  products: any[]=[
    {
      code:"zuku",
      name:"zuku",
      quantity:"cdsaf",
      category:"asdad"
    }
  ];
  constructor(private commonService:CommonService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['modalState']) {
      if(changes['modalState'].currentValue) {
        this.reloadPage();
      }
    }
  }

  private reloadPage() {
    this.getPdfFile();
  }

  private getPdfFile() {
    let _this = this;
    this.commonService.getJSONByURL(environment.service_url+'/return_pdf?aid='+this.response['Attachment_ID']).subscribe(next => {
      _this.pdfbytes = next;
    })
  }

  closeModal() {
    this.onCloseModal.emit();
  }


  getKeyList() {
    if(this.response['response'] !== undefined && this.response['response'] !== null){
      return  Object.keys(this.response['response']);
    }
  }


}
