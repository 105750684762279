<div>
  <div class="headerBox">
    <div>
      <p>+ Add Filter</p>
      <ctrm-dropdown (onChangeValue)="addfilter($event)" [addValue]="add" [field]="allcolumns" [fieldname]="'filters'"
                     [removeValue]="remove"></ctrm-dropdown>
    </div>
  </div>
  <div class="brk"></div>
  <div class="bodyBox">
    <div *ngFor="let filter of filterList;index as i" class="filterBox">
      <div class="item">
        <p class="itemLabel">{{filter.getHeader()}}</p>
        <div class="itemContent">
          <ctrm-dropdown (onChangeValue)="updateOptions($event,i)" [addSelectOption]="true"
                         [field]="getOptions(filter.getType())"
                         [selectedValue]="filter.getCondition()"
                         class="contentMargin"></ctrm-dropdown>
          <div *ngIf="showBoxes(filter)">
            <div *ngIf="isListType(filter)" class="contentBox">
              <ctrm-dropdown (onChangeValue)="addListOption($event,i)" [addSelectOption]="false"
                             [field]="filter.getField().getListOfValues()"
                             [selectedValue]="filter.getFirstValue()['value']"
                             class="contentMargin"></ctrm-dropdown>
            </div>
            <div *ngIf="isTextType(filter);" class="contentBox">
              <ctrm-input (onChangeValue)="addTextOption($event,i)"
                          [defaultValue]="filter.getFirstValue()"></ctrm-input>
            </div>
            <div *ngIf="isNumberType(filter)" class="contentBox">
              <ctrm-input (onChangeValue)="addNumberOption($event,i,true)" [defaultValue]="filter.getFirstValue()"
                          [type]="'number'" class="contentMargin"></ctrm-input>
              <ctrm-input (onChangeValue)="addNumberOption($event,i,false)"
                          *ngIf="isSecondRequired(filter)" [defaultValue]="filter.getSecondValue()"
                          [type]="'number'" class="contentMargin"></ctrm-input>
            </div>
            <div *ngIf="isDateType(filter)" class="contentBox">
              <input (bsValueChange)="addDateOption($event,filter,true)"
                     [bsConfig]="{dateInputFormat : getDateFormat(filter['column'])}"
                     [maxDate]="isValidDate(filter['column'].getMaxDate())?filter['column'].getMaxDate():''"
                     [minDate]="isValidDate(filter['column'].getMinDate())?filter['column'].getMinDate():''"
                     [minMode]="filter['column'].getFromExtra('date')['view']"
                     bsDatepicker
                     class="form-control"
                     placeholder="select date" type="text">
              <input (bsValueChange)="addDateOption($event,filter,false)"
                     *ngIf="isSecondRequired(filter)"
                     [bsConfig]="{dateInputFormat : getDateFormat(filter['column'])}"
                     [maxDate]="isValidDate(filter['column'].getMaxDate())?filter['column'].getMaxDate():''"
                     [minDate]="isValidDate(filter['column'].getMinDate())?filter['column'].getMinDate():''"
                     [minMode]="filter['column'].getFromExtra('date')['view']"
                     bsDatepicker
                     class="form-control"
                     placeholder="select date"
                     style="margin-left: 6px" type="text">
            </div>
          </div>
        </div>
        <i (click)="removeFilter(i)" class="fas fa-times removefilter"></i>
      </div>
      <div class="brk"></div>
    </div>
  </div>
</div>
