import {Component, Input, OnInit} from '@angular/core';
import {BreadcrumbService} from '../../Components/ctrm-breadcrumb/breadcrumb.service';
import {CommonService} from '../../services/common.service';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';

@Component({
    selector: 'app-future-management-dashboard',
    templateUrl: './future-management-dashboard.component.html',
    styleUrls: ['./future-management-dashboard.component.css']
})
export class FutureManagementDashboardComponent implements OnInit {

    showTickerPannel: boolean = false;
    @Input() values: any[] = [];
    //Ticker dialog
    displayTickerDialog: boolean = false;
    //Graph
    displayGraph: boolean = false;
    selectedTickers:any[];
    tickerValue:any[] = [];
    isLoading: boolean = false;

    constructor(public commonService: CommonService,
                public breadCrumbService: BreadcrumbService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.breadCrumbService.makeBreadcrumbTo(this.commonService.RISK_MAP_KEY, '/futureManagementDashboard');
        this.getAllTickerRecords();
        this.showTickerPannel = true;
    }

    onClickTickerList() {
        this.showTickerPannel = !this.showTickerPannel;
    }

    //On click Add Ticker
    onClickAddTicker() {
        this.displayTickerDialog = true;
    }

    onCloseForm() {
        this.displayTickerDialog = false;
        this.getAllTickerRecords();
    }

    onCloseGraph() {
        this.displayGraph = false;
    }

    onSelectTicker(value: any) {
        if(value !== undefined && value !== null) {
            this.selectedTickers = value;
        }
    }

    getAllTickerRecords() {
        this.isLoading = true;
        this.commonService.getJSONByURL(environment.base_url + '/api/futuremarketdashboard/v1/getfuturetickervalue?tenantId=' + this.commonService.getTenantId()).subscribe((next: any) => {
            this.isLoading = false;
            this.tickerValue = next;
        });
    }

    onClickOfImport() {
        this.router.navigate(['/futurePrice'],{queryParams:{mode:'import'}});
    }
}
