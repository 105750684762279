import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {CommonService} from '../../services/common.service';
import {environment} from '../../../environments/environment';
import {ConfirmationService, MessageService, SelectItem, Table} from 'primeng';
import {BreadcrumbService} from '../../Components/ctrm-breadcrumb/breadcrumb.service';
import {ActivatedRoute} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {Tcolumn} from '../../grid/tcolumn.model';
import {Grid} from '../../grid/grid.model';
import {defaultValue} from '../../grid/defaultValue.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormControlServiceService} from '../../services/form-control-service.service';
import {ctrmValidators} from '../../services/validators/ctrmValidators';
import {MasterService} from "../../masters/services/MasterService";
import {SearchCriteria} from "../../Operations/transport-actualization/actualization-form/actualization-form.component";
import {AccessPolicyService} from "../../services/accesspolicy.service";

@Component({
    selector: 'app-planning',
    templateUrl: './planning.component.html',
    styleUrls: ['./planning.component.css'],
    providers: [ConfirmationService]
})
export class PlanningComponent implements OnInit {
    buyCols: any[];
    sellCols: any[];
    planCols: any[];
    buyValue: any[] = [];
    private isFiltered = false;
    private specificId = '';
    private queryMode = '';
    sellValue: any[] = [];
    selectedBuyRows: any[] = [];
    isLoading: boolean = false;
    selectedSellRows: any[] = [];
    oblCols: any[];
    circleStringoblCols: any[];
    buyTotal: number = 0;
    sellTotal: number = 0;
    planData: any[] = [];
    selectedPlans: any[] = [];
    tabOptions: SelectItem[] = [];
    tabValue: string;
    matchDisplay: boolean = false;
    tradeId: any = '';
    display: boolean = false;
    algoBtnList: any[] = [];
    selectedAlgo: string = '';
    warningMessage: any[] = [];
    errorMessage: any[] = [];
    oblRow: any;
    globalFilterValue: string = '';
    buySellHeight: string;
    formTitle: any = 'Circle';
    balanceFunc: any;
    resetBuy: boolean = false;
    resetSell: boolean = false;
    expandedRows = {};
    sidebarPosition: any = 'right';
    planTableHeight: string = '';
    circleVisible: boolean = false;
    showPlan:boolean = false;
    showPlanDocByPass:boolean = false;
    buyObligationRequired:boolean=true;
    sellObligationRequired:boolean=true;
    washoutEstablishment= new Date();
    opendialogfordatepicker:boolean=false;
    todayDate = this.commonService.convertUTCtoDate(new Date());
    minDate:any;

    @ViewChild('planTable') planTable;
    @ViewChild('mainTable') mainTable: Table;
    @ViewChild('buySell') buySell: ElementRef;
    selectedAlgoButton: any = {};
    messages: any[] = [];
    plannedObligationId: any = '';
    detailsOf: any = 'obligation';
    noDataBoxLeft: any = '';
    loadingMessage: string = 'No Data Available';
    isRefreshing: boolean = false;
    gridHeight: string = '';
    deleteDisplay: boolean = false;
    selectedRowData: any;
    divEl: HTMLDivElement;
    buyObligation: any = {};
    sellObligation: any = {};
    planGridHeight: any = '300px';
    btnDisable: boolean = false;
    currentPageNumber: number = 0;
    currentPageNumberBuy: number = 0;
    currentPageNumberSell: number = 0;
    pageSize: number = 0;
    totalElements: number = 0;
    totalPages: number = 0;
    pageSizeBuy: number = 0;
    totalElementsBuy: number = 0;
    totalPagesBuy: number = 0;
    pageSizeSell: number = 0;
    totalElementsSell: number = 0;
    totalPagesSell: number = 0;
    filterListBuy: any[] = [];
    filterListSell: any[] = [];
    mode: any;
    onRefreshBuy: boolean = false;
    onRefreshSell: boolean = false;
    buyFilters: any = {};
    sellFilters: any = {};
    opendialog: boolean = false;
    interProfitCenterCheck:boolean = false;



    bsConfig: Partial<BsDatepickerConfig>;
    columns: Tcolumn[] = [];
    grid: Grid;
    toolBarConfig: boolean = false;

    locationList: any[];

    lastUnselectedCheckbox: any;
    deAllocateDisable: boolean = false;
    allocateDisable: boolean = false;
    deleteDisable: boolean = false;
    refreshGrid: boolean = false;
    gridFilters:any[] = [];
    selectedRows:any[] = [];
    columnsMAP: Map<string, Tcolumn>;
    plannedObligationIdList:any[] = [];
    tradeIdList:any[] = [];
    planIdList:any[] = [];
    commodityList:any[] = [];
    counterpartyList:any[] = [];
    profitCenterList:any[] = [];
    loadLocationList:any[] = [];
    unloadLocationList:any[] = [];
    matchTypeList:any[] = [];
    showPlanCondition:any;

    canAccess: boolean = false;

    constructor(public commonService: CommonService,
                public masterCommonService: MasterService,
                private messageService: MessageService,
                private route: ActivatedRoute,
                public breadCrumbService: BreadcrumbService,
                private confirmationService: ConfirmationService,
                private accessPolicyService: AccessPolicyService) {
    }

    @HostListener('window:resize', [])
    public onResize() {
        if (this.tabValue === 'planned') {
            this.calculatePlannedHeight();
        } else {
            this.calculateHeight();
        }
    }

    ngOnInit(): void {
        this.canAccess = this.accessPolicyService.canAccessWithCurrentRoute('Action', 'Match Plan');
        this.createGridFilters();
        this.getColumnsForPlanned();

        this.grid = new Grid(this.columnsMAP, true, true, true, true, 'tradeId', '/api/actualization/v2/find-trade-planning-planned-trades?tenantId='+ this.commonService.getFromStorage('tenantId'), undefined, 'Trade Actualization', undefined, undefined);
        this.grid.setWidth('5%');
        this.grid.setGridConfiguration(false, false, false, false, false, false, true, false, false, false);
        this.grid.gridFetchUrlType = "POST";
        this.grid.iconName = 'loadUnload';
        this.grid.fetchPostPayloadFuction = function (value:any[] = []) {
            return _this.getCommonFilterPayload(true,true);
        };
        this.breadCrumbService.makeBreadcrumbTo(this.commonService.OPERATION_MAP_KEY, '/planning');
        this.balanceFunc = function(data) {
            return data['plannedQuantity'];
        };


        this.bsConfig = new BsDatepickerConfig();
        this.bsConfig.dateInputFormat = environment.dateFormat.toUpperCase();
        this.bsConfig.adaptivePosition = true;
        this.bsConfig.useUtc = true;
        this.algoBtnList = [
            {title: 'Washout', isDisable: false, warnIcon: false, errIcon: false, message: []},
            {title: 'Back to Back', isDisable: false, warnIcon: false, errIcon: false, message: []},
            {title: 'String', isDisable: false, warnIcon: false, errIcon: false, message: []},
            {title: 'Circle', isDisable: false, warnIcon: false, errIcon: false, message: []},
            {title: 'DocByPass', isDisable: true, warnIcon: false, errIcon: false, message: []},
            {title: 'Blending', isDisable: false, warnIcon: false, errIcon: false, message: []}];
        this.tabOptions = [{label: 'Unplanned Trade', value: 'unplanned'},
            {label: 'Planned Trade', value: 'planned'}];
        let _this = this;
        this.route.queryParams.subscribe(params => {
            if (params !== undefined && params !== null && Object.keys(params).length > 0) {
                let mode = params['mode'];
                let value = params['value'];
                _this.isFiltered = true;
                _this.specificId = value;
                _this.queryMode = mode;
                if (mode === 'planning') {
                    _this.tabValue = 'unplanned';
                    _this.getBuySellForSpecific(value);
                } else {
                    _this.tabValue = 'planned';
                    _this.getPlansForSpecific(value);
                    _this.reloadPlanningData();
                }
            } else {
                _this.tabValue = 'unplanned';
                _this.reloadPlanningData();
            }
        });
        let viewQuantityFunction = function(value: any) {
            return _this.commonService.formatNumberWithoutComma(value['tradeQuantity'],_this.commonService.getRoundingFormat("quantityRounding",3)) + ' ' + value['quantityUOM'];
        };
        let viewPlannedQuantityFunction = function(value: any) {
            return _this.commonService.formatNumber(value['plannedQuantity'],'','',_this.commonService.getRoundingFormat("quantityRounding",3)) + ' ' + value['quantityUOM'];
        };
        let viewStatusFunction = function(value: any) {
            return _this.commonService.getObligationStateString(value['obligationState']);
        };

        let viewPriceFunction = function(value: any) {
            return _this.commonService.formatNumberWithoutComma(value['tradePrice'],_this.commonService.getRoundingFormat("priceRounding",2)) + ' ' + value['tradePriceCurrency'] + '/' + value['tradePriceUom'];
        };

        let viewBuyQuantity = function(value: any) {
            if (value['']) {
                return _this.commonService.getFormatedNumber(value['buyObligationQuantity']) + ' ' + value['uomName'];
            } else if (value['']) {
                return _this.commonService.getFormatedNumber(value['buyObligationQuantity']) + ' ' + value['uomName'];
            }
            return _this.commonService.getFormatedNumber(value['buyObligationQuantity']) + ' ' + value['uomName'];
        };

        let viewSellQuantity = function(value: any) {
            return _this.commonService.getFormatedNumber(value['sellObligationQuantity']) + ' ' + value['uomName'];
        };


        this.sellCols = [
            {field: 'balanceQuantity', header: 'Balance Qty'},
            {field: 'matchQuantity', header: 'Match Qty', editable: true},
            {field: 'CB', header: 'CB'},
            {field: 'tradeQuantity', header: 'Trade Qty', valueFunction: viewQuantityFunction},
            {field: 'broker', header: 'Broker',showFilter: true},
            {field: 'cropOrigin', header: 'Origin', showFilter: true},
            {field: 'brand', header: 'Brand', showFilter: true},
            {field: 'grade', header: 'Grade', showFilter: true},
            {field: 'profitcenter', header: 'Profit Center', showFilter: true},
            {field: 'tradePrice', header: 'Price', valueFunction: viewPriceFunction},
            {field: 'priceType', header: 'Price Type', showFilter: true},
            {field: 'deliveryStartDate', header: 'Delivery Start Date', type: 'date', showFilter: true},
            {field: 'deliveryEndDate', header: 'Delivery End Date', type: 'date', showFilter: true},
            {field: 'loadLocation', header: 'Location (LOAD)', showFilter: true},
            {field: 'incoterm', header: 'Incoterm', showFilter: true},
            {field: 'counterparty', header: 'Counterparty', showFilter: true},
            {field: 'commodity', header: 'Commodity', showFilter: true},
            {field: 'tradeId', header: 'Trade Id', showFilter: true},
            {field: 'tradeObligationId', header: 'Obligation Id', primaryKey: true, showFilter: true},
        ];

        this.buyCols = [
            {field: 'tradeObligationId', header: 'Obligation Id', showFilter: true},
            {field: 'tradeId', header: 'Trade Id', showFilter: true},
            {field: 'commodity', header: 'Commodity', showFilter: true},
            {field: 'counterparty', header: 'Counterparty', showFilter: true},
            {field: 'incoterm', header: 'Incoterm', showFilter: true},
            {field: 'loadLocation', header: 'Location (LOAD)',showFilter: true},
            {field: 'deliveryStartDate', header: 'Delivery Start Date', type: 'date', showFilter: true},
            {field: 'deliveryEndDate', header: 'Delivery End Date', type: 'date', showFilter: true},
            {field: 'priceType', header: 'Price Type', showFilter: true},
            {field: 'tradePrice', header: 'Price', valueFunction: viewPriceFunction},
            {field: 'profitcenter', header: 'Profit Center', showFilter: true},
            {field: 'grade', header: 'Grade', showFilter: true},
            {field: 'brand', header: 'Brand', showFilter: true},
            {field: 'cropOrigin', header: 'Origin', showFilter: true},
            {field: 'broker', header: 'Broker',showFilter: true},
            {field: 'tradeQuantity', header: 'Trade Qty', valueFunction: viewQuantityFunction},
            {field: 'CB', header: 'CB'},
            {field: 'matchQuantity', header: 'Match Qty', editable: true},
            {field: 'balanceQuantity', header: 'Balance Qty'},
        ];

        this.planCols = [
            {field: 'planningId', header: 'Trade Plan Id'},
            {field: 'buyquantity', header: 'Buy Planned Qty', valueFunction: viewBuyQuantity},
            {field: 'sellquantity', header: 'Sell Planned Qty', valueFunction: viewSellQuantity},
            {field: 'matchType', header: 'Planned Matching'},
            {field: 'status', header: 'Status'}
        ];

        //   {field: 'tradeQuantity', header: 'Quantity', valueFunction: viewQuantityFunction},
        this.oblCols = [
            {field: 'plannedObligationId', header: 'Planned Obligation Id', primaryKey: true},
            {field: 'tradeId', header: 'Trade Id'},
            {field: 'tradeTransactionType', header: 'Trade Type'},
            {field: 'tradeDateTime', header: 'Trade Date', type: 'date'},
            {field: 'commodity', header: 'Commodity'},
            {field: 'company', header: 'Company'},
            {field: 'counterparty', header: 'Counterparty'},
            {field: 'deliveryStartDate', header: 'Delivery Start Date', type: 'date'},
            {field: 'deliveryEndDate', header: 'Delivery End Date', type: 'date'},
            {field: 'incoterm', header: 'Incoterm'},
            {field: 'loadLocation', header: 'Load Location'},
            {field: 'unloadLocation', header: 'UnLoad Location'},
            {field: 'cropOrigin', header: 'Origin'},
            {field: 'grade', header: 'Grade'},
            {field: 'broker', header: 'Broker'},
            {field: 'priceType', header: 'Price Type'},
            {field: 'tradePrice', header: 'Trade Price', valueFunction: viewPriceFunction},
            {field: 'profitcenter', header: 'Profit Center'},
            {field: 'plannedQuantity', header: 'Planned Qty', valueFunction: viewPlannedQuantityFunction},
            {field: 'obligationState', header: 'Status', valueFunction: viewStatusFunction}];



        this.circleStringoblCols = [
            {field: 'circleStringSeq', header: 'Sequence Number', primaryKey: true},
            {field: 'plannedObligationId', header: 'Planned Obligation Id', primaryKey: true},
            {field: 'tradeId', header: 'Trade Id'},
            {field: 'tradeTransactionType', header: 'Trade Type'},
            {field: 'tradeDateTime', header: 'Trade Date', type: 'date'},
            {field: 'commodity', header: 'Commodity'},
            {field: 'company', header: 'Company'},
            {field: 'counterparty', header: 'Counterparty'},
            {field: 'deliveryStartDate', header: 'Delivery Start Date', type: 'date'},
            {field: 'deliveryEndDate', header: 'Delivery End Date', type: 'date'},
            {field: 'incoterm', header: 'Incoterm'},
            {field: 'location', header: 'Location'},
            {field: 'cropOrigin', header: 'Origin'},
            {field: 'grade', header: 'Grade'},
            {field: 'broker', header: 'Broker'},
            {field: 'priceType', header: 'Price Type'},
            {field: 'tradePrice', header: 'Trade Price', valueFunction: viewPriceFunction},
            {field: 'profitcenter', header: 'Profit Center'},
            {field: 'plannedQuantity', header: 'Planned Qty', valueFunction: viewPlannedQuantityFunction},
            {field: 'obligationState', header: 'Status', valueFunction: viewStatusFunction}];
        setTimeout(() => {
            var scrollContainer = $('.style-4.table-responsive.background.right');
            var maxScrollLeft = $(scrollContainer)[0].scrollWidth - $(scrollContainer).width();
            $('.style-4.table-responsive.background.right').scrollLeft(maxScrollLeft);
        }, 500);

    }

    private reloadPlanningData() {
        this.getBuyData();
        this.getSellData();
    }

    private createGridFilters() {
        let payload = this.getCommonFilterPayload(true,true);
        let _this = this;
        this.resetFilters();
        this.commonService.getJSONByURL(environment.base_url+'/api/actualization/v2/find-filter-criteria-for-actualization-container').subscribe((next:any) => {
            // let uniquetradeTransactionType = next['tradeTransactionType'].filter((c, index) => {
            //     return next['tradeTransactionType'].indexOf(c) === index;
            // });
            _this.gridFilters = [];
            _this.gridFilters.push({title:'Obligation Id',list:_this.masterCommonService.getListFromArray(next['planned_obligation_id'],false,false)});
            _this.gridFilters.push({title:'Trade ID',list:_this.masterCommonService.getListFromArray(next['trade_id'],false,false)});
            _this.gridFilters.push({title:'Plan ID',list:_this.masterCommonService.getListFromArray(next['plan_id'],false,false)});
            _this.gridFilters.push({title:'Commodity',list:_this.masterCommonService.getListFromArray(next['commodity'],false,false)});
            _this.gridFilters.push({title:'Counter Party',list:_this.masterCommonService.getListFromArray(next['counterparty'],false,false)});
            _this.gridFilters.push({title:'Profitcenter',list:_this.masterCommonService.getListFromArray(next['profit_center'],false,false)});
            _this.gridFilters.push({title:'Load Port',list:_this.masterCommonService.getListFromArray(next['load_location'],false,false)});
            _this.gridFilters.push({title:'Discharge Port',list:_this.masterCommonService.getListFromArray(next['unload_location'],false,false)});
            _this.gridFilters.push({title:'Match Type',list:_this.masterCommonService.getListFromArray(next['match_type'],false,false)});
        });
    }

    private resetFilters() {
        this.plannedObligationIdList = [];
        this.tradeIdList = [];
        this.planIdList = [];
        this.commodityList = [];
        this.counterpartyList = [];
        this.profitCenterList = [];
        this.loadLocationList = [];
        this.unloadLocationList = [];
        this.matchTypeList = [];

    }

    private getCommonFilterPayload(addCardInfo:boolean = false,addFilterInfo:boolean = false) {
        if(addFilterInfo) {
            let payload:any = [{key:'matchType',operation:'notEquals',value:'Vessel Plan'},{key:'matchType',operation:'notEquals',value:'BUILD'},
            {key:'matchType',operation:'notEquals',value:'DRAW'}];
            this.plannedObligationIdList.length > 0 ? payload.push({key:'plannedObligationId',operation:'in',value:this.plannedObligationIdList}):null;
            this.tradeIdList.length > 0 ? payload.push({key:'tradeId',operation:'in',value:this.tradeIdList}):null;
            this.planIdList.length > 0 ? payload.push({key:'planId',operation:'in',value:this.planIdList}):null;
            this.commodityList.length > 0 ? payload.push({key:'commodity',operation:'in',value:this.commodityList}):null;
            this.counterpartyList.length > 0 ? payload.push({key:'counterparty',operation:'in',value:this.counterpartyList}):null;
            this.profitCenterList.length > 0 ? payload.push({key:'profitCenter',operation:'in',value:this.profitCenterList}):null;
            this.loadLocationList.length > 0 ? payload.push({key:'loadLocation',operation:'in',value:this.loadLocationList}):null;
            this.unloadLocationList.length > 0 ? payload.push({key:'unloadLocation',operation:'in',value:this.unloadLocationList}):null;
            this.matchTypeList.length > 0 ? payload.push({key:'matchType',operation:'in',value:this.matchTypeList}):null;
            return payload;
        }
    }



    private getColumnsForPlanned() {
        let _this = this;
        let tradeQuantityOutputFunc = function (value:any[] =[]) {
            return _this.commonService.formatNumberWithoutComma(value[0],_this.commonService.getRoundingFormat("quantityRounding",3))+' '+value[1];
        };
        let showStatus = function(value:any){
          if(value[0] == null){
              return "Transport Not Allocated "
          }
          else{
              return "Transport Allocated"
          }
        };


        this.columnsMAP = new Map();
        this.columnsMAP.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
        this.columnsMAP.set('plannedObligationId', new Tcolumn('plannedObligationId', 'Obligation ID', 'LB', 1, true, undefined, true, new defaultValue('', undefined, undefined, undefined, false, false, false),'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.set('tradeId', new Tcolumn('tradeId', 'Trade ID', 'LB', 2, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.set('planId', new Tcolumn('planId', 'Plan ID', 'LB', 3, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.set('tradeTransactionType', new Tcolumn('tradeTransactionType', 'Buy/Sell', 'OB', 4, true, this.masterCommonService.getBooleanFromLabel('Buy','Sell',false,'BUY','SELL'), true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.set('matchType', new Tcolumn('matchType', 'Match Type', 'CUSTOM', 5, true, undefined, true, '' , 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.set('status', new Tcolumn('status', 'Status', 'CUSTOM', 6, true, undefined, true, '' , 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.set('vesselId', new Tcolumn('vesselId', 'Transport Allocation  ', 'T', 6, true, undefined, true, new defaultValue('', undefined, undefined, undefined, false, false, false, showStatus)));
        this.columnsMAP.set('tradeDateTime', new Tcolumn('tradeDateTime', 'Trade Date', 'D', 6, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.set('commodity', new Tcolumn('commodity', 'Commodity', 'LB', 7, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.set('counterparty', new Tcolumn('counterparty', 'Counter Party', 'LB', 8, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.set('plannedQuantity', new Tcolumn('plannedQuantity', 'Planned Quantity', 'N', 9, false, undefined, true, new defaultValue('',undefined,undefined,undefined,false,true,false,tradeQuantityOutputFunc,['plannedQuantity','quantityuom']), 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.set('deliveryStartDate', new Tcolumn('deliveryStartDate', 'Delivery Start Date', 'D', 10, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.set('deliveryEndDate', new Tcolumn('deliveryEndDate', 'Delivery End Date', 'D', 11, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.set('incoterm', new Tcolumn('incoterm', 'Incoterm', 'LB', 12, false,undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.set('profitCenter', new Tcolumn('profitCenter', 'Profit Center', 'LB', 13, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.set('priceAllocationStatus', new Tcolumn('priceAllocationStatus', 'Price Allocation Status', 'LB', 14, true, undefined, true, new defaultValue('', undefined, undefined, undefined, false, false, false),'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    }



    onDeAllocateTransport() {
        //***** Things to Note *****
        // currently iam passing the allocationtType as harcode value as 'vessel' .!
        // though its backend api require the the paramter but its not using the anywhere in the deallocation api
        // either we need to remove it in backend or pass it from frontend ..
        // not removing in backedn in future may use this value that time we can modify our front value

        this.deleteDisplay = false;
        let _this = this;
        this.confirmationService.confirm({
            message: 'Are you sure that you want to De-Allocate the complete Plan?',
            accept: () => {
                this.commonService.post(environment.base_url + `/api/transportallocationv2/v1/deallocatevesselforplanning?planId=${this.selectedRowData.planId}&allocationType=${'vessel'}&tenantId=${localStorage.getItem('tenantId')}`, {}).subscribe(data => {
                    this.showToast('Transport is successfully De-Allocated for the PlanId ' + this.selectedRowData.planId);
                    this.createGridFilters()
                    this.refreshGrid = true;
                }, (error: HttpErrorResponse) => {
                    _this.messageService.add({
                        severity: 'error',
                        summary: _this.commonService.getHttpErrorMessage(error, 'Trade Planning')
                    });
                    _this.selectedPlans = [];
                });
            }, reject: () => {
                this.deleteDisplay = true;
            }
        });
    }

    onRefreshGridComplete(){
        this.refreshGrid= false;
        this.deleteDisplay = false;
    }

    onFilterChange(value: any) {
        this.refreshGrid = false;
        let type = value['title'];
        let selected = value['selected'];
        if(type === 'Obligation Id') {
            this.plannedObligationIdList = selected;
        }else if(type === 'Trade ID') {
            this.tradeIdList = selected;
        } else if(type === 'Plan ID') {
            this.planIdList = selected;
        }  else if(type === 'Commodity') {
            this.commodityList = selected;
        }  else if(type === 'Counter Party') {
            this.counterpartyList = selected;
        }  else if(type === 'Profitcenter') {
            this.profitCenterList = selected;
        } else if(type === 'Load Port') {
            this.loadLocationList = selected;
        } else if(type === 'Discharge Port') {
            this.unloadLocationList = selected;
        } else if(type === 'Match Type') {
            this.matchTypeList = selected;
        }
        this.refreshGrid = true;
    }

    // To get All the Plans and nest all the respective Obligation Objects in each Plan
    public getPlans(newPlanId: string = '') {
        let _this = this;
        this.planData = [];
        this.expandedRows = {};
        if (newPlanId !== undefined && newPlanId !== null && newPlanId.length > 0) {
            // _this.expandRowOnSave(newPlanId);
        } else {
            this.createGridFilters()
            this.refreshGrid = true;
        }
    }

    // To get All the Plans and nest all the respective Obligation Objects in each Plan
    public getBuyData(resetValues: boolean = true) {
        let _this = this;
        this.resetBuy = false;
        this.buyValue = [];
        if (resetValues) {
            this.buyTotal = 0;
            this.resetBuy = true;
            this.totalElementsBuy = 0;
            this.totalPagesBuy = 0;
        }
        this.commonService.getJSONByURL(environment.base_url + '/api/tradeobligation/v1/getalltradeobligationsbytransactiontype?tenantId='
            + this.commonService.getFromStorage('tenantId') + '&transactionType=BUY&page=' + this.currentPageNumberBuy + '&size=20').subscribe((next: any) => {
            _this.buyValue = next['content'];
            _this.pageSizeBuy = next['pageable']['pageSize'];
            _this.totalElementsBuy = next['totalElements'];
            _this.totalPagesBuy = next['totalPages'];
        });
        this.getFilterValuesForBuy();
    }


    public getSellData(resetValues: boolean = true) {
        let _this = this;
        this.resetSell = false;
        this.sellValue = [];
        if (resetValues) {
            this.resetSell = true;
            this.sellTotal = 0;
            this.totalElementsSell = 0;
            this.totalPagesSell = 0;
        }
        this.commonService.getJSONByURL(environment.base_url + '/api/tradeobligation/v1/getalltradeobligationsbytransactiontype?tenantId='
            + this.commonService.getFromStorage('tenantId') + '&transactionType=SELL&page=' + this.currentPageNumberSell + '&size=20').subscribe((next: any) => {
            _this.sellValue = next['content'];
            _this.pageSizeSell = next['pageable']['pageSize'];
            _this.totalElementsSell = next['totalElements'];
            _this.totalPagesSell = next['totalPages'];
        });
        this.getFilterValuesForSell();
    }

    //to set total on the basis of Sell Obligation or Buy Obligation
    onTotalChange(value: any, mode) {
        if (mode === 'buy') {
            this.buyTotal = value;
        } else {
            this.sellTotal = value;
        }
    }


    //Function to run Desired Algorithm for matching and Show Toast Message accordingly
    runAlgo(mode: string) {
        let matchType = [];
        matchType.push(mode);
        this.resetBuy = false;
        this.resetSell = false;
        this.isLoading = true;
        if (this.selectedSellRows.length > 0 && this.selectedBuyRows.length > 0) {
            this.commonService.post(environment.base_url + '/api/physicaltradeplanning/v1/savePlan?tenantId=' + this.commonService.getFromStorage('tenantId') + '&vesselPlanning=false', {
                tradeBuyObligations: this.selectedBuyRows,
                tradeSellObligations: this.selectedSellRows,
                matchType: matchType,
                matchQuantity: this.buyTotal,
                establishedOn:(matchType[0] == 'Washout')?this.commonService.convertUTCtoDate(new Date(this.washoutEstablishment)):this.commonService.convertUTCtoDate(new Date())
            }).subscribe(next => {
                this.resetBuy = true;
                this.resetSell = true;
                this.showToast('Plan Created Successfully', 'info');
                this.showToast('Plan ' + next['planningId'] + ' created successfully.');
                this.isLoading = false;
                this.tabValue = 'planned';
                this.getPlans(next['uuid']);
                this.reloadPlanningData();
                this.createGridFilters();
                this.refreshGrid = true;
            }, (error: HttpErrorResponse) => {
                this.isLoading = false;
                this.messageService.add({
                    severity: 'error',
                    summary: this.commonService.getHttpErrorMessage(error, 'Physical Planning'),
                });
            });
        }
    }

    //Populate array of selected Buy or Sell array
    onRowSelect(value: any, mode) {
        if (mode == 'Buy') {
            this.selectedBuyRows = value;
        } else {
            this.selectedSellRows = value;
        }
    }

    //Func to delete the Plan
    onRowDelete() {
        let deletePayload = [];
        deletePayload.push(this.selectedRowData.planUuid);

        //calling Delete REST
        this.deleteDisplay = false;
        let _this = this;
        this.confirmationService.confirm({
            message: 'Are you sure that you want to delete the complete Plan?',
            accept: () => {
                if(this.selectedRowData['matchType'] !== 'DocByPass'){
                    this.commonService.post(environment.base_url + '/api/physicaltradeplanning/v1/deletePlan?tenantId=' + this.commonService.getFromStorage('tenantId'), deletePayload).subscribe(next => {
                        _this.messageService.add({severity: 'info', summary: 'Plan Deleted'});
                        _this.selectedPlans = [];
                        _this.getPlans();
                        _this.reloadPlanningData();
                        this.createGridFilters();
                        this.refreshGrid=true;
                    }, (error: HttpErrorResponse) => {
                        _this.messageService.add({
                            severity: 'error',
                            summary: _this.commonService.getHttpErrorMessage(error, 'Trade Planning')
                        });
                        _this.selectedPlans = [];
                    });
                }else{
                    this.commonService.post(environment.base_url + '/api/physicaltradeplanning/v1/deleteDocByPassPlan?tenantId=' + this.commonService.getFromStorage('tenantId'), deletePayload).subscribe(next => {
                        _this.messageService.add({severity: 'info', summary: 'Doc By Pass Plan Deleted'});
                        _this.selectedPlans = [];
                        _this.getPlans();
                        _this.reloadPlanningData();
                        this.createGridFilters();
                        this.refreshGrid=true;
                    }, (error: HttpErrorResponse) => {
                        _this.messageService.add({
                            severity: 'error',
                            summary: _this.commonService.getHttpErrorMessage(error, 'Trade Planning')
                        });
                        _this.selectedPlans = [];
                    });

                }



            }, reject: () => {
                this.deleteDisplay = true;
            }
        });

    }

    //Get the Date From TimeStamp
    getDate(rowDatum: any) {
        return rowDatum.substring(0, 10);
    }

    //Go back to Dashboard
    goBack() {
        this.commonService.navigateTo('/dashboard');
    }

    getClassForHeading(index) {
        return index > 0 ? 'headerNormal' : 'noPipe';
    }

    /**
     * fetch trade id from rowData ehen clicked on uniquew click
     * @param value
     */
    onUniqueClick(value: any, detailsOf = 'obligation') {
        this.detailsOf = detailsOf;
        value = value['rowData'];
        this.plannedObligationId = value['plannedObligationId'];
        if (!value['tradeStatus']) {
            this.tradeId = value['tradeId'];
            this.oblRow = value;
            this.sidebarPosition = 'right';
            this.display = true;
        } else {
            this.tradeId = value['tradeId'];
            this.oblRow = value;
            this.sidebarPosition = value['buySell'] ? 'right' : 'left';
            this.display = true;
        }

    }

    onClose() {
        this.tradeId = '';
        this.display = false;
    }

    openMatchDialog() {
        this.matchDisplay = !this.matchDisplay;
    }

    getAlgoClass(btn) {
        if (btn['isDisable']) {
            return 'algoBtnDisabled';
        } else {
            if (this.selectedAlgo == btn['title']) {
                return 'algoBtnSelected';
            } else {
                return 'algoBtn';
            }
        }
    }

    selectAlgo(btn) {
        if (!btn['isDisable']) {
            this.messages = [];
            this.selectedAlgoButton = btn;
            this.selectedAlgo = btn['title'];
        }
    }

    closeMatchDialog() {
        this.errorMessage = [];
        this.warningMessage = [];
        this.messages = [];
        this.selectedAlgoButton = '';
        this.selectedAlgo = '';
        this.matchDisplay = false;
    }

    checkMatch() {
        let buyList = this.selectedBuyRows.filter((item) => parseFloat(item['matchQuantity']) !== parseFloat(item['plannedQuantity'])).map(item => item.plannedObligationId);
        let sellList = [...this.selectedSellRows.filter((item) => parseFloat(item['matchQuantity']) !== parseFloat(item['plannedQuantity'])).map(item => item.plannedObligationId)];
        let finalList = buyList.concat(sellList);
        if(finalList.length == 0) {
            this.continueCheck();
        } else {
            this.commonService.post(`${environment.base_url}/api/plannedObligation/v1/can-split-obligations`, finalList).subscribe((resp) => {
                if(resp['successInstance']) {
                    this.continueCheck();
                } else {
                    this.showToast(resp['status'],'error');
                }
            },(error:HttpErrorResponse) => {
                this.showToast(this.commonService.getHttpErrorMessage(error),'error');
            })
        }

    }

    private continueCheck() {
        let algoCheckPayload = {
            tradeBuyObligations: this.selectedBuyRows,
            tradeSellObligations: this.selectedSellRows,
            matchType: ['Washout', 'Back to Back', 'String', 'Circle', 'Blending']
        };
        if (((this.buyTotal === this.sellTotal) && this.buyTotal !== 0 && this.sellTotal !== 0)) {
            this.commonService.post(environment.base_url + '/api/physicaltradeplanning/v1/checksuitablealgorithm', algoCheckPayload).subscribe((next: any) => {
                this.washoutCheck(next);
                this.back2backCheck(next);
                this.blendingCheck(next);
                this.cirlceStringCheck(next, 'Circle', 2);
                this.cirlceStringCheck(next, 'String', 3);
                this.openMatchDialog();
            });
        } else {
            this.messageService.add({severity: 'info', summary: 'Total Buy Qty and Total Sell Qty are not equal or Zero'});
        }
    }

    //Toast Message
    // showToast(planObj) {
    //     this.messageService.add({
    //         severity: 'info',
    //         summary: 'Plan Created Successfully',
    //         detail: 'Plan ' + planObj['planId'] + ' created successfully.'
    //     });
    // }
    showToast(msg, severity: string = 'success') {
        this.messageService.add({
            severity: severity,
            summary: msg
        });
    }

       selectWashout(){
        this.opendialogfordatepicker=false;
        this.runAlgo('Washout');
    }
    cancelWashoutdialog(){
        this.opendialogfordatepicker=false;
        this.washoutEstablishment=new Date();
    }

    continuePlanning() {
        if (this.selectedAlgo === 'Washout') {
            this.minDate=this.commonService.convertUTCtoDate(new Date(this.selectedBuyRows[0]['tradeDateTime']));
            this.opendialogfordatepicker=true;
            this.closeMatchDialog();
        } else if (this.selectedAlgo === 'Back to Back') {
            this.runAlgo('Back to Back');
            this.closeMatchDialog();
        } else if (this.selectedAlgo === 'Circle') {
            this.onSelectCircle();
        } else if (this.selectedAlgo === 'String') {
            this.onSelectString();
        } else if (this.selectedAlgo === 'DocByPass') {
            //this.runAlgo('Washout');
        } else if (this.selectedAlgo === 'Blending') {
            this.runAlgo('Blending');
            this.closeMatchDialog();
        }

    }

    getContPlanValidation() {
        this.messages = this.selectedAlgoButton['message'];
        if (this.errorMessage.length != 0 || (this.buyTotal == 0 && this.sellTotal == 0) || (this.buyTotal !== this.sellTotal) || this.selectedAlgo == '' || this.selectedAlgoButton['errIcon']) {
            return true;
        }
    }


    getFilterValue() {
        this.globalFilterValue = event.target['value'];
        this.mainTable.filterGlobal(event.target['value'], 'contains');
    }

    planRefresh() {
        this.planData = [];
        this.selectedPlans = [];
        this.deleteDisplay = false;
        if (this.isFiltered) {
            if (this.queryMode === 'planned') {
                this.getPlansForSpecific(this.specificId);
            } else {
                this.getPlans();
            }
        } else {
            this.getPlans();
        }
    }

    buyObligationRefresh() {
        this.buyValue = [];
        this.sellValue = [];
        this.errorMessage = [];
        this.warningMessage = [];
        this.messages = [];
        this.selectedAlgoButton = '';
        this.selectedAlgo = '';
        if (this.isFiltered) {
            if (this.queryMode === 'planning') {
                this.getBuySellForSpecific(this.specificId);
            } else {
                this.reloadPlanningData();
            }
        } else {
            this.reloadPlanningData();
        }
        this.onRefreshBuy = true;
        this.onRefreshSell = true;
    }


    calculateHeight() {
        if (this.buySell !== undefined && this.buySell !== null) {
            let offsetTopBS = this.buySell.nativeElement['offsetTop'];
            let offsetHeightBS = 0;
            if (this.buySell.nativeElement['offsetParent'] !== undefined && this.buySell.nativeElement['offsetParent'] !== null) {
                offsetHeightBS = this.buySell.nativeElement['offsetParent']['offsetHeight'];
            }
            this.buySellHeight = offsetHeightBS - (offsetTopBS) + 'px';
        }
    }

    ngAfterViewInit() {
        this.calculateHeight();
    }

    switchTab(tabName) {
        this.tabValue = tabName;
    }

    getMessage(message: any) {
        let msg = '';
        message.forEach(data => {
            msg = msg + data + '\n';
        });
        return msg;
    }

    findMessage(message: any,searchString:any) {
        let searchStringData:Boolean[] = message.map(data => {
            if(data.includes(searchString)){
                return true;
            }
            else{
                return false;
            }
        });
     return searchStringData.includes(true);

    }


    washoutCheck(next: any) {
        this.algoBtnList[0]['errIcon'] = false;
        this.algoBtnList[0]['warnIcon'] = false;
        this.algoBtnList[0]['isDisable'] = false;
        this.algoBtnList[0]['message'] = '';
        if (next['Washout']['resultType'] === 0) {
            this.algoBtnList[0]['errIcon'] = true;
            this.algoBtnList[0]['warnIcon'] = false;
            this.algoBtnList[0]['isDisable'] = false;
            this.algoBtnList[0]['message'] = next['Washout']['messages'];
        } else if (next['Washout']['resultType'] === 1) {
            this.algoBtnList[0]['warnIcon'] = true;
            this.algoBtnList[0]['errIcon'] = false;
            this.algoBtnList[0]['isDisable'] = false;
            this.algoBtnList[0]['message'] = next['Washout']['messages'];
        }
    }

    back2backCheck(next: any) {
        this.algoBtnList[1]['errIcon'] = false;
        this.algoBtnList[1]['warnIcon'] = false;
        this.algoBtnList[1]['isDisable'] = false;
        this.algoBtnList[1]['message'] = '';
        this.interProfitCenterCheck  = this.commonService.getFromTenantConfig(false,"invoiceConfig","interProfitCenterCheck");
        if (next['Back to Back']['resultType'] == 0) {
            this.algoBtnList[1]['errIcon'] = true;
            this.algoBtnList[1]['warnIcon'] = false;
            this.algoBtnList[1]['isDisable'] = false;
            this.algoBtnList[1]['message'] = next['Back to Back']['messages'];
        }
        if(this.interProfitCenterCheck==false && this.findMessage(next['Back to Back']['messages'],"ProfitCenters")){
            if (next['Back to Back']['resultType'] == 1) {
                this.algoBtnList[1]['errIcon'] = true;
                this.algoBtnList[1]['warnIcon'] = false;
                this.algoBtnList[1]['isDisable'] = false;
                this.algoBtnList[1]['message'] = next['Back to Back']['messages'];
            }
        }else {
            if (next['Back to Back']['resultType'] == 1) {
                this.algoBtnList[1]['warnIcon'] = true;
                this.algoBtnList[1]['errIcon'] = false;
                this.algoBtnList[1]['isDisable'] = false;
                this.algoBtnList[1]['message'] = next['Back to Back']['messages'];
            }
        }
    }

    //blending

    blendingCheck(next: any) {
        this.algoBtnList[5]['errIcon'] = false;
        this.algoBtnList[5]['warnIcon'] = false;
        this.algoBtnList[5]['isDisable'] = false;
        this.algoBtnList[5]['message'] = '';
        let blendingAllowed  = this.commonService.getFromTenantConfig(false,'invoiceConfig','blendingAllowed');
        if(blendingAllowed){
            this.algoBtnList[5]['warnIcon'] = false;
            this.algoBtnList[5]['isDisable'] = false;
            this.algoBtnList[5]['message'] = next['Blending']['messages'];
        }else{
            if (next['Blending']['resultType'] == 1) {
                this.algoBtnList[5]['warnIcon'] = true;
                this.algoBtnList[5]['errIcon'] = false;
                this.algoBtnList[5]['isDisable'] = false;
                this.algoBtnList[5]['message'] = next['Blending']['messages'];
            }else if (next['Blending']['resultType'] == 0) {
                this.algoBtnList[5]['errIcon'] = true;
                this.algoBtnList[5]['warnIcon'] = false;
                this.algoBtnList[5]['isDisable'] = false;
                this.algoBtnList[5]['message'] = next['Blending']['messages'];
            }
        }
    }

    cirlceStringCheck(next: any, code, buttonNumber) {
        this.algoBtnList[buttonNumber]['errIcon'] = false;
        this.algoBtnList[buttonNumber]['warnIcon'] = false;
        this.algoBtnList[buttonNumber]['isDisable'] = false;
        this.algoBtnList[buttonNumber]['message'] = '';
        if (this.selectedBuyRows.length === 1 && this.selectedSellRows.length === 1) {
            this.buyObligation = this.selectedBuyRows[0];
            this.sellObligation = this.selectedSellRows[0];
        }
        if (next[code]['resultType'] == 0) {
            this.algoBtnList[buttonNumber]['errIcon'] = true;
            this.algoBtnList[buttonNumber]['warnIcon'] = false;
            this.algoBtnList[buttonNumber]['isDisable'] = false;
            this.algoBtnList[buttonNumber]['message'] = next[code]['messages'];
        }
        if (next[code]['resultType'] == 1) {
            this.algoBtnList[buttonNumber]['warnIcon'] = true;
            this.algoBtnList[buttonNumber]['errIcon'] = false;
            this.algoBtnList[buttonNumber]['isDisable'] = false;
            this.algoBtnList[buttonNumber]['message'] = next[code]['messages'];
        }
    }

    expandRowOnSave(planId) {
        let _this = this;
        this.commonService.getJSONByURL(environment.base_url + '/api/plannedObligation/v1/getphysicaltradeplandetails?tenantId=' + this.commonService.getFromStorage('tenantId') + '&planUuid=' + planId).subscribe((childRows: any[]) => {
            this.commonService.getJSONByURL(environment.base_url + '/api/plannedObligation/v1/getphysicaltradeplanninglist?tenantId='
                + this.commonService.getFromStorage('tenantId') + '&page=' + this.currentPageNumber + '&size=2000').subscribe((next: any[]) => {
                _this.planData = next['content'];
                _this.pageSize = next['pageable']['pageSize'];
                _this.totalElements = next['totalElements'];
                _this.totalPages = next['totalPages'];
                _this.planData[0]['plannedObligationList'] = childRows;
                this.calculateHeight();
                _this.expandedRows[planId] = true;
            });
        });

    }

    onTabChange(value: any) {
        this.calculateHeight();
        if (value['value'] === 'planned') {
            if (this.isFiltered) {
                if (this.queryMode === 'planned') {
                    this.getPlansForSpecific(this.specificId);
                } else {
                    this.getPlans();
                }
            } else {
                this.getPlans();
            }
        }
    }

    onClickable(value: any, position = 'right') {
        if (value['fieldName'] === 'tradeId') {
            this.sidebarPosition = position;
            this.detailsOf = 'trade';
            this.oblRow = value['rowData'];
            this.tradeId = this.oblRow['tradeId'];
            this.display = true;
        }
    }

    calculateLeft() {
        this.noDataBoxLeft = ((window['visualViewport'].width - 500) / 2) + 'px';
    }

    calculatePlannedHeight() {
        if (this.mainTable !== undefined && this.mainTable !== null) {
            let offsetTop = this.mainTable.el.nativeElement['offsetTop'];
            let offsetHeight = 0;
            if (this.mainTable.el.nativeElement['offsetParent'] !== undefined && this.mainTable.el.nativeElement['offsetParent'] !== null) {
                offsetHeight = this.mainTable.el.nativeElement['offsetParent']['offsetHeight'];
            }
            this.gridHeight = (offsetHeight - offsetTop - 40) + 'px';
        }
        if (this.divEl !== undefined && this.divEl !== null) {
            this.calculateGridHeight(this.divEl);
        }
    }

    calculateGridHeight(divElement: HTMLDivElement) {
        this.planGridHeight = 350 + 'px';
        this.divEl = divElement;
    }

    onRowExpand(value: any) {
        this.commonService.getJSONByURL(environment.base_url + '/api/plannedObligation/v1/getphysicaltradeplandetails?tenantId=' + this.commonService.getFromStorage('tenantId') + '&planUuid=' + value['data']['uuid']).subscribe((next: any[]) => {
            value['data']['plannedObligationList'] = next;
        });
    }

    cancel() {
        this.selectedPlans = [];
        this.deleteDisplay = false;
    }

    onSelectCB(rowData) {
        if(rowData.length === 1){
            this.selectedRowData = rowData[0];
            this.showPlanCondition=this.selectedRowData['matchType'] === 'Circle' || this.selectedRowData['matchType'] === 'String' || this.selectedRowData['matchType'] === 'DocByPass';
            if (this.selectedRowData.vesselId != null ) {
                if(this.selectedRowData['obligationState']['ACTUALIZED']== true){
                    this.deAllocateDisable = true;
                }else{
                    this.deAllocateDisable = false;
                }
                this.allocateDisable = true;
                this.deleteDisable = true;
            }else{
                this.deAllocateDisable = true;
                this.allocateDisable = false;
                this.deleteDisable = false;
            }
            this.deleteDisplay = true;
        }
        else if(rowData.length>1){
            this.deleteDisplay = false;
        }else{
            this.deleteDisplay = false;
        }
    }

    selectedPlansLength() {
        if (this.selectedPlans !== undefined && this.selectedPlans !== null) {
            if (this.selectedPlans.length === 0) {
                return true;
            }
            return false;
        }
        return true;
    }

    private onSelectCircle() {
        this.matchDisplay = false;
        this.circleVisible = true;
        this.formTitle = 'Circle';
    }

    private onSelectString() {
        this.matchDisplay = false;
        this.circleVisible = true;
        this.formTitle = 'String';
    }

    onCancelCircleForm() {
        if(this.showPlan){
            this.circleVisible=false;
            this.matchDisplay=false;
        }else{
            this.circleVisible = false;
            this.matchDisplay = true;
        }
    }
    onCancelDocByPass(){
        this.circleVisible=false;
        this.matchDisplay=false;
    }

    onCompleteCircleString(value: any) {
        this.closeMatchDialog();
        this.circleVisible = false;
        this.resetBuy = true;
        this.resetSell = true;
        this.messageService.add({
            severity: 'info',
            summary: 'Plan Created Successfully',
            detail: value['matchType'] + ' Plan created successfully with plan id : ' + value['planningId']
        });
        this.tabValue = 'planned';
        this.getPlans(value['uuid']);
        this.reloadPlanningData();
        this.createGridFilters();
        this.refreshGrid = true;
    }
    onCompleteDocByPass(value:any){
        this.closeMatchDialog();
        this.circleVisible = false;
        this.refreshGrid =true;
    }

    getColumns(rowData: any) {
        if (rowData['matchType'] === 'Circle' || rowData['matchType'] === 'String') {
            return this.circleStringoblCols;
        }
        return this.oblCols;
    }

    private getBuySellForSpecific(value: any) {
        let _this = this;
        this.resetBuy = false;
        this.resetSell = false;
        this.sellTotal = 0;
        this.buyTotal = 0;
        this.buyValue = [];
        this.sellValue = [];
        this.commonService.getJSONByURL(environment.base_url + '/api/tradeobligation/v1/getallbuyselltradeobligationsbyobligationid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&tradeObligationId=' + value).subscribe((next: any) => {
            _this.buyValue = next['buyList'];
            _this.sellValue = next['sellList'];
        });
    }

    private getPlansForSpecific(value: any) {
        let _this = this;
        this.planData = [];
        this.expandedRows = {};
        this.commonService.getJSONByURL(environment.base_url + '/api/plannedObligation/v1/getphysicaltradeplanninglistbyplannedobligationid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&plannedObligationId=' + value).subscribe((next: any[]) => {
            _this.planData = next;
            this.calculateHeight();
        });
    }

    disableButton(isDisable) {
        this.btnDisable = isDisable;
    }

    getPlannedStatus(rowData: any) {
        if (rowData['matchType'] === 'Back to Back' && rowData['status'] === 'Delivery Started') {
            return 'Delivery Started';
        } else if ((rowData['matchType'] === 'Washout' && rowData['status'] === 'Delivery Started') ||
            (rowData['matchType'] === 'Washout' || rowData['matchType'] === 'Back to Back') && rowData['status'] === 'Planned') {
            return 'Planned';
        } else if ((rowData['matchType'] === 'Washout' || rowData['matchType'] === 'Back to Back') && rowData['status'] === 'Settled') {
            return 'Settled';
        } else {
            return 'Planned';
        }
    }

    getIconForStatus(rowData: any) {
        if (rowData['matchType'] === 'Back to Back' && rowData['status'] === 'Delivery Started') {
            return '../../../assets/images/svg/truck.svg';
        } else if ((rowData['matchType'] === 'Washout' && rowData['status'] === 'Delivery Started') ||
            (rowData['matchType'] === 'Washout' || rowData['matchType'] === 'Back to Back') && rowData['status'] === 'Planned') {
            return '../../../assets/images/svg/event.svg';
        } else if ((rowData['matchType'] === 'Washout' || rowData['matchType'] === 'Back to Back') && rowData['status'] === 'Settled') {
            return '../../../assets/images/svg/truck.svg';
        } else {
            return '../../../assets/images/svg/event.svg';
        }
    }

    getClassForStatus(rowData: any) {
        if (rowData['matchType'] === 'Back to Back' && rowData['status'] === 'Delivery Started') {
            return 'statusDS';
        } else if ((rowData['matchType'] === 'Washout' && rowData['status'] === 'Delivery Started') ||
            (rowData['matchType'] === 'Washout' || rowData['matchType'] === 'Back to Back') && rowData['status'] === 'Planned') {
            return 'statusPlanned';
        } else if ((rowData['matchType'] === 'Washout' || rowData['matchType'] === 'Back to Back') && rowData['status'] === 'Settled') {
            return 'statusDS';
        } else {
            return 'statusPlanned';
        }
    }

    onPageChange(value: any) {
        // if(this.buyFilters.keys.length > 0){
        //   this.getFilterdObligation(this.buyFilters,'BUY',this.currentPageNumberBuy)
        // }
        //  if(this.sellFilters.keys.length > 0){
        //   this.getFilterdObligation(this.sellFilters,'SELL',this.currentPageNumberSell)
        // }
        this.currentPageNumber = value['page'];
        this.getPlans();
    }

    getPagination() {
        return this.totalElements >= this.pageSize;
    }

    onChangePageBuy(value: any) {
        this.currentPageNumberBuy = value['page'];
        // this.getBuyData(false);
        this.getFilterdObligation(this.buyFilters, 'BUY', this.currentPageNumberBuy);
    }

    onChangePageSell(value: any) {
        this.currentPageNumberSell = value['page'];
        // this.getSellData(false);
        this.getFilterdObligation(this.sellFilters, 'SELL', this.currentPageNumberSell);
    }

    getFilterValuesForBuy() {
        this.commonService.getJSONByURL(environment.base_url + '/api/tradeobligation/v1/gettradeobligationsforplanning?tenantId='
            + this.commonService.getFromStorage('tenantId') + '&transactionType=BUY').subscribe((next: any[]) => {
            this.filterListBuy = next;
        });
    }

    getFilterValuesForSell() {
        this.commonService.getJSONByURL(environment.base_url + '/api/tradeobligation/v1/gettradeobligationsforplanning?tenantId='
            + this.commonService.getFromStorage('tenantId') + '&transactionType=SELL').subscribe((next: any[]) => {
            this.filterListSell = next;
        });
        // setTimeout(() => {
        //   var scrollContainer = $('.style-4.table-responsive.background.right');
        //   var maxScrollLeft = $(scrollContainer)[0].scrollWidth - $(scrollContainer).width();
        //   $(".style-4.table-responsive.background.right").scrollLeft(maxScrollLeft);
        // }, 500)
    }

    filterForBuy(payload) {
        this.buyFilters = payload;
        let _this = this;
        this.buyTotal = 0;
        this.buyValue = [];
        this.getFilterdObligation(payload, 'BUY');

    }

    filterForSell(payload) {
        this.sellFilters = payload;
        let _this = this;
        this.sellTotal = 0;
        this.sellValue = [];
        this.getFilterdObligation(payload, 'SELL');
    }

    getFilterdObligation(payload: any, tranType: string, pageNumber: number = 0) {
        let _this = this;
        this.commonService.post(environment.base_url + '/api/tradeobligation/v1/getplanningdatabyfilter?tenantId='
            + this.commonService.getFromStorage('tenantId') + '&transactionType=' + tranType + '&page=' + pageNumber + '&size=20', payload).subscribe((next: any) => {
            if (tranType === 'BUY') {
                _this.buyValue = next['content'];
                _this.pageSizeBuy = next['pageable']['pageSize'];
                _this.totalElementsBuy = next['totalElements'];
                _this.totalPagesBuy = next['totalPages'];
            } else {
                _this.sellValue = next['content'];
                _this.pageSizeSell = next['pageable']['pageSize'];
                _this.totalElementsSell = next['totalElements'];
                _this.totalPagesSell = next['totalPages'];
            }

        });
    }

    clearFilter(mode) {
        if (mode === 'Buy') {
            this.buyValue = [];
            this.buyFilters = {};
            this.getBuyData();
        } else {
            this.sellValue = [];
            this.sellFilters = {};
            this.getSellData();
        }
    }

    onRefreshComplete() {
        this.onRefreshBuy = false;
        this.onRefreshSell = false;
    }

    onAllocateTransport() {
        this.opendialog = true;
        this.selectedPlans = [];
        this.deleteDisplay = false;
    }

    onShowPlan(){
        this.showPlanCondition=this.selectedRowData['matchType'] === 'Circle' || this.selectedRowData['matchType'] === 'String' || this.selectedRowData['matchType'] === 'DocByPass';
        if(this.selectedRowData['matchType'] === 'Circle' || this.selectedRowData['matchType'] === 'String' || this.selectedRowData['matchType'] === 'DocByPass'){
            this.circleVisible=true;
            if(this.selectedRowData['matchType'] === 'DocByPass'){
                this.showPlanDocByPass = true;
                if(this.selectedRowData['tradeTransactionType']=="BUY"){
                    this.sellObligationRequired=false;
                    this.buyObligationRequired=true;
                    this.buyObligation=this.selectedRowData;
                    this.sellObligation={};
                }
                else{
                    this.buyObligationRequired=false;
                    this.sellObligationRequired=true;
                    this.sellObligation=this.selectedRowData;
                    this.buyObligation={};
                }
            }else{
                this.showPlan= true;
            }
            this.deleteDisplay=false;
        }
    }

    onFormCancel() {
        this.opendialog = false;
        this.selectedPlans = [];
        this.deleteDisplay = false;
    }

    onAllocationDone() {
        this.showToast('Transport is successfully Allocated for the PlanId ' + this.selectedRowData.planId);
        this.opendialog = false;
        this.planRefresh();
    }
}
