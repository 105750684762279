  <div class="sectionCardTop">
    <div class="breadcrumbPane">
      <ctrm-breadcrumb [collapsabel]="false"
                       [items]="breadCrumbService.getBreadCrumb()"
                       [showIcon]="false"></ctrm-breadcrumb>
    </div>
    <div class="actionPane">
      <div class="screenDetails">
        <img [src]="'../../../assets/images/svg/priceFixing.svg'"/>
        <p>Price Allocation</p>
      </div>
      <sta-filter
              [columnListObject]="tradeCols"
              [pTable]="priceTable"
              [columns]="columns"
              [columnNameFix]="fixColumnNames"
              (onFilterApply)="onApplyFilter($event)"
              [moduleName]="'Price Allocation'"></sta-filter>
    </div>
  </div>

  <div>
    <div class="planningPanel">
      <div class="toolbar">
        <div>
          <ctrm-button (onClick)="allocationRefresh()" [disabled]="isRefreshing"
                       icon="{{isRefreshing?'fas fa-redo fa-spin':'fas fa-redo'}}" label="Refresh"></ctrm-button>
          <div class="float-right">


            <ctrm-button (click)="onClickExport()"
                         icon="fas fa-file-export" label="Export"></ctrm-button>
            <ctrm-button [disabled]="this.selectedPlans.length === 0 || this.selectedPlans.length > 1"
                         icon="fas fa-print"
                         label="Print"></ctrm-button>
<!--            <ctrm-button icon="fas fa-sliders-h" label="Preferences"></ctrm-button>-->
          </div>
        </div>
      </div>

      <!--    Trade Details in Price Fixation-->
      <div class="tableClass">
        <p-table #priceTable (inViewportAction)="calculateHeight(priceTable)"
                 (onRowExpand)="openPriceRow($event)"
                 [(selection)]="selectedPlans" [autoLayout]="true"
                 (onRowSelect)="onRowSelectionChange($event)"
                 (onRowUnselect)="onRowSelectionChange($event)"
                 [columns]="tradeCols"
                 [expandedRowKeys]="expandedRows"
                 [style.minHeight]="allocationData.length>0?priceTableHeight:''"
                 [style]="{width: '100%',minHeight:priceTableHeight, maxHeight: priceTableHeight}"
                 [value]="allocationData"
                 class="style-4 table-responsive"
                 dataKey="tradeId" inViewport
                 selectionMode="multiple">

          <ng-template let-columns pTemplate="header">
            <tr class="sticky-top" style="background-color: #D9DBDE;">
              <th class="checkBoxCol stickLeftHeader" style="width: 4em">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th *ngFor="let col of columns;index as i" [id]="'header'+i"
                  [ngStyle]="getStyle(col,i,'1001', 'header' + i)"
                  class="stk"
                  style="background-color: #D9DBDE;height: 50px;z-index: 1000">

                <div style="background-color: #D9DBDE; padding: 3px;">
                  <p [ngClass]="getClassForHeading(i)">{{col.header}}</p>
                </div>
              </th>
            </tr>
          </ng-template>

          <ng-template let-columns="columns" let-expanded="expanded" let-ri="rowIndex" let-rowData pTemplate="body">
            <tr>
              <td class="plannedGridChkBox checkBoxCol stickLeftBody">
                <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
              </td>
              <td *ngFor="let col of columns; index as i" [id]="'body' + i + '_' +  ri"
                  [ngStyle]="getStyle(col,i,'389', 'body' + i + '_' +  ri)"
                  class="customRow">
                <p
                        *ngIf="col.field !== 'matchType' && col.field !== 'status' && col.field !== 'tradeType' && col.field !== 'tradeId'"
                        class="plannedGrid">
                  {{commonService.getColValue(rowData, col)}}
                </p>
                <p *ngIf="col.field === 'matchType'" class="matchAlgoStyle">
                  {{commonService.getColValue(rowData, col)}}
                </p>
                <div *ngIf="col.field === 'tradeType'">
                  <div>
                    <p class="plannedGrid">{{getTradeTypeValue(rowData[col.field])}}</p>
                  </div>
                </div>
                <div *ngIf="col.field === 'tradeId'" style="display: flex">
                  <p style="margin:0;margin-left: 5px">
                    <a [pRowToggler]="rowData" href="#">
                      <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" class="togglerIcon"></i>
                    </a>
                  </p>
                  <p (click)="onUniqueClick(rowData)" class="plannedGrid primaryKey"
                     style="padding-top: 2px">{{commonService.getRowValue(rowData, col)}}</p>
                </div>
              </td>
            </tr>
          </ng-template>
          <ng-template let-columns="columns" let-rowData pTemplate="rowexpansion">
            <tr>
              <td [colSpan]="columns.length + 1">
                <div class="planningInfoContainer">
                  <app-planning-info (onClickDelete)="onRowDelete($event)"
                                     [columns]="priceCols"
                                     [primaryKeyClickable]="false"
                                     [selectedRows]="currentSelectedRow === rowData['index']? rowData['selectedChild']: []"
                                     [showChkBox]="false" [showDeleteIcon]="true"
                                     [value]="rowData['pricedChilds']">
                  </app-planning-info>

                </div>

              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr *ngIf="(allocationData === undefined ||allocationData === null || allocationData.length === 0)">
              <td [colSpan]="tradeCols.length + 2">
                <div class="placeHolder">
                  <div (inViewportAction)="calculateLeft()" [style]="{left:noDataBoxLeft}" class="noDataBox" inViewport>
                    <img [src]="commonService.getIconPath('emptyStateIcon')" class="iconClass"/>
                    <div class="noPlans">{{loadingMessage}}</div>
                    <div *ngIf="!isRefreshing">
                      <button (click)="goTo()" class="planScreenBtns" style="margin-top: 0px !important;">Go to Trade
                      </button>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <p-paginator *ngIf="getPagination()" [rows]="20" [totalRecords]="totalElements" (onPageChange)="onPageChange($event)"></p-paginator>
      </div>
    </div>
  </div>

  <!--side bar for price fixation-->
  <p-sidebar [(visible)]="allocationDisplay" [baseZIndex]="25" [modal]="false" [showCloseIcon]="false"
             [styleClass]="'bottomPane'" position="bottom">
    <div class="bottomSidebarContainer">
      <div class="leftSection">
        <i (click)="onFormCancel()" class="material-icons closable">clear</i>
      </div>
      <div class="totalContainer">
        <p class="tradeTotal">{{selectedPlans.length}}</p>
        <p class="total">Trade Selected</p>
      </div>

      <div class="rightSection">
        <button (click)="onFormCancel()" class="cancelBtn">Cancel</button>
        <button (click)="openAllocationScreen()" class="priceBtn">Allocate Price</button>
        <button [disabled]="deletePrice" (click)="onDeleteAll()" class="priceBtn">Delete Price </button>
      </div>
    </div>
  </p-sidebar>

<app-allocation-matching (matchingScreenClose)="showAllocationMain($event)" *ngIf="matchingScreenOpen"
                         [priceUom]="priceUom" [quantityUom]="quantityUom"
                         [tradeId]="tradeId"></app-allocation-matching>

<app-trade-info (onClose)="onClose($event)" *ngIf="tradedisplay" [detailsOf]="'trade'" [display]="true"
                [rowData]="rowData" [tradeId]="tradeId"></app-trade-info>
<p-confirmDialog [baseZIndex]="25" [styleClass]="'confirmationDialog'" header="Confirmation"
                 icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body">
</p-toast>
