import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControlServiceService} from '../../../../services/form-control-service.service';
import {FormGroup, Validators} from '@angular/forms';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {environment} from '../../../../../environments/environment';
import {CommonService} from '../../../../services/common.service';

@Component({
  selector: 'detail-card',
  templateUrl: './detail-card.component.html',
  styleUrls: ['./detail-card.component.css']
})
export class DetailCardComponent implements OnInit, OnChanges {
  @Input() heading: any = 'Freight Details';
  @Input() fields: any[] = [];
  @Input() editing: boolean = true;
  @Input() mandatory: boolean = false;
  @Input() formControlService: FormControlServiceService;
  formGroup: FormGroup;
  bsConfig: Partial<BsDatepickerConfig>;
  loading: boolean = true;
  @Input() bookingValue: any[] = [];
  @Output() confirmFormBuild = new EventEmitter();

  constructor(public commonService: CommonService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.bsConfig = new BsDatepickerConfig();
    this.bsConfig.dateInputFormat = environment.dateFormat.toUpperCase();
    this.bsConfig.adaptivePosition = true;
    this.bsConfig.useUtc = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fields']) {
      this.updateFormGroup();
    }
    if (changes['bookingValue']) {
      this.updateFormGroup();
    }
  }

  updateFormGroup() {
    let _this = this;
    this.loading = true;
    this.fields.forEach((row) => {
      if (!_this.formControlService.hasControl(row['field'])) {
        _this.formControlService.addControl(row['field'], _this.getValue(row), row['mandatory']?[Validators.required]:null);
      } else {
        _this.formControlService.setValue(row['field'], _this.getValue(row));
      }
      if (row['type'] === 'date'){
        _this.formControlService.setValue(row['field'],_this.getValue(row));
      }
    });
    _this.formGroup = _this.formControlService.build();
    _this.confirmFormBuild.emit();
    _this.loading = false;
  }

  getValue(row: any): any {
    if (this.bookingValue !== null && this.bookingValue !== undefined) {
      if (row['type'] === 'date') {
        let val = this.bookingValue[row['field']];
        if(val !== null && val !== undefined && val.length > 0) {
          return this.commonService.moment(val).toDate();
        }
        return '';
      }
      return this.bookingValue[row['field']];
    }
    return '';
  }


  getViewValue(bookingValue: any[], field: any) {
    let value = bookingValue[field];
    return value === null || value === undefined || value.length === 0?"-":value;
  }
}
