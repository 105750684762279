import {Component, OnInit} from '@angular/core';
import {Grid} from '../../grid/grid.model';
import {Tcolumn} from '../../grid/tcolumn.model';
import {KeyValue} from '../../grid/key-value.model';
import {Validators} from '@angular/forms';
import {defaultValue} from '../../grid/defaultValue.model';
import {environment} from '../../../environments/environment';
import {CommonService} from '../../services/common.service';
import {Tab} from '../../tab-grid/Tab.model';
import {Action} from '../../grid/actions.model';
import {MasterService} from '../services/MasterService';
import {TabList} from '../../tab-grid/TabList.model';
import {ChildAction} from '../../grid/ChildAction.model';
import {TreeNode} from 'primeng';
import {repos} from '../../services/common/entities';
import {Button} from '../../grid/buttons.model';

@Component({
    selector: 'app-company',
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.css'],
    providers: []
})
export class CompanyComponent implements OnInit {

    columns: Map<string, Tcolumn>;
    grid: Grid;
    rootName: string;
    modalStateHierarchy: boolean = false;
    hierarchyData: TreeNode[];
    groupList: KeyValue[] = [];
    tabs: Tab[] = [];
    yeartfun: Function;
    monthto: Function;
    tablist: TabList = new TabList();
    modalStat: boolean = false;
    saveUrl: string = '';
    refreshGrid: boolean = false;
    private currentHref: string = '';
    private bankAccount:Tab;
    private address:Tab;
    selectedRowData: any;
    loadDataList: any[];
    openFormForNewEntry: boolean = false;
    openTabScreen: boolean = false;
    selectedTab:string = '';
    tabRow:any = null;
    constructor(public commonService: CommonService, public masterCommonService: MasterService) {
    }

    ngOnInit() {
        this.commonService.getLoadData('company', ['config','data', 'data'], ['unitofmeasurement',[{key: 'name', value: 'payment_method_ind'}], [{
            key: 'name',
            value: 'address_type_ind'
        }]]).subscribe((next: any) => {
            this.loadDataList = next;
            this.initFunctions();
            this.getColumns();
            let copy = new ChildAction('companyUuid');
            copy.setPrimaryKeyCode('uuid');
            copy.addRepo('xceler_configservice_companybankaccount', repos.companyBankAccount);
            copy.addRepo('xceler_configservice_companycalendardetail','companyCalendarDetailRepo');
            copy.addRepo('xceler_configservice_companyaddressdetails','companyAddressDetailsRepo');
            this.initiateTabs();
            this.tabs.push(this.getAccountingCalendarDetails());
            this.tabs.push(this.bankAccount);
            this.tabs.push(this.address);
            this.grid = new Grid(this.columns, true, true, true, true, 'name', '/api/company/v1/getall?tenantId=' + this.commonService.getFromStorage('tenantId'), '', 'Company', '', '', undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true);
            this.grid.setSaveUrl(environment.base_url + '/api/company/v1/save');
            this.grid.setUpdateUrl(environment.base_url + '/api/company/v1/update');
            this.grid.hrefUrl = environment.base_url + '/api/company/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
            this.grid.importUrl = environment.base_url + '/api/company/v1/import';
            this.grid.setChildCopyDelete(copy);
            this.grid.onEditViewThrowUnique = true;
            this.grid.auditEntityName = 'Company';
            this.grid.iconName = 'company';
            this.grid.setInfoFields(['name', 'description', 'currency', 'website', 'telephone', 'fax', 'address']);
            this.grid.addSection('Company Information', ['code','name', 'description', 'parent', 'currency', 'baseuom','logo']);
            this.grid.addSection('Contacts', ['website', 'telephone', 'fax', 'address', 'timeZone', 'dayLightSaving']);
            this.grid.addSection('Other', ['isTradeable', 'status']);
            this.grid.isHaveExtraButtons =true;
            this.grid.addChildTabs(this.getAccountingCalendarDetails());
            this.grid.addChildTabs(this.bankAccount);
            this.grid.addChildTabs(this.address);
            this.grid.setTabs(this.tabs);
            this.grid.showExtraButtons=false;
            this.contextmenu();
        });
    }

  initiateTabs() {
    this.bankAccount = this.getBankAccounts();
    this.address = this.getAddressDetails();
  }

  private updateTabDefault(tab,mapDefault:any) {
    if(tab === 'bankAccount') {
      this.bankAccount.getGrid().getColumn().get('defaultValue').setDefaultValue(!this.commonService.getDefaultValue(mapDefault,tab));
    } else if(tab === 'address') {
      this.address.getGrid().getColumn().get('defaultValue').setDefaultValue(!this.commonService.getDefaultValue(mapDefault,tab));
    }
  }

    public getAccountingCalendarDetails() {
        let actionone: Action = new Action();
        actionone.addFieldToUpdate('accountingYearTo', undefined, this.yeartfun, ['accountingYearFrom'], undefined, true);
        actionone.addFieldToUpdate('accountingMonthTo', undefined, this.monthto, ['accountingYearFrom', 'accountingMonthFrom', 'accountingYearTo'], undefined, true);
        let actiontwo: Action = new Action();
        actiontwo.addFieldToUpdate('accountingMonthTo', undefined, this.monthto, ['accountingYearFrom', 'accountingMonthFrom', 'accountingYearTo'], undefined, true);
        let columns: Map<string, Tcolumn> = new Map();
        columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
        columns.set('name', new Tcolumn('name', 'Company Name', 'LB', 1, false, undefined, false, '', 'B', [Validators.required]));
        columns.set('calendarName', new Tcolumn('calendarName', 'Calendar Name', 'T', 1, false, undefined, true, '', 'B', [Validators.required, Validators.maxLength(50)]));
        columns.set('accountingYearFrom', new Tcolumn('accountingYearFrom', 'Accounting Year From', 'L', 2, true, this.getYearList(), true, new Date().getFullYear(), 'B', [Validators.required, Validators.maxLength(6)], undefined, 1, undefined, undefined, undefined, actionone));
        columns.set('accountingMonthFrom', new Tcolumn('accountingMonthFrom', 'Accounting Month From', 'L', 3, true, this.commonService.getMonthList(), true, new Date().getMonth() + 1, 'B', [Validators.required, Validators.maxLength(10)], undefined, 1, undefined, undefined, undefined, actiontwo));
        columns.set('accountingYearTo', new Tcolumn('accountingYearTo', 'Accounting Year To', 'L', 4, true, this.getYearTo(), true, new Date().getFullYear(), 'B', [Validators.required, Validators.maxLength(6)], undefined, 1, undefined, undefined, undefined, actiontwo));
        columns.set('accountingMonthTo', new Tcolumn('accountingMonthTo', 'Accounting Month To', 'L', 5, true, this.getMonthTo(), true, new Date().getMonth() + 1, 'B', [Validators.required]));
        columns.set('periodStatus', new Tcolumn('periodStatus', 'Period Status', 'B', 6, true, this.masterCommonService.getBooleanFromLabel('Open', 'Close'), true, true, 'B', [Validators.required]));
        columns.set('status', new Tcolumn('status', 'Calendar Status', 'B', 7, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));
        let grid = new Grid(columns, true, true, true, true, 'calendarName', '/api/companycalendardetail/v1/getbycompanyuuid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&companyUuid=', '', 'Accounting Calendar Details', 'name', '', undefined, undefined, false);
        grid.setSaveUrl(environment.base_url + '/api/companycalendardetail/v1/overlapcheck');
        grid.setUpdateUrl(environment.base_url + '/api/companycalendardetail/v1/update');
        grid.hrefUrl = environment.base_url + '/api/companycalendardetail/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
        grid.importUrl = environment.base_url + '/api/companycalendardetail/import';
        let tab = new Tab(grid.getTitle(), grid, 'name');
        tab.idFieldValueName = 'companyUuid';
        return tab;
    }

    public getBankAccounts() {
        let columns: Map<string, Tcolumn> = new Map();
        columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
        columns.set('name', new Tcolumn('name', 'Company Name', 'LB', 1, false, undefined, false, '', 'B', [Validators.required]));
        columns.set('accountNumber', new Tcolumn('accountNumber', 'Account Number', 'T', 2, false, undefined, true, '', 'B', [Validators.required, Validators.maxLength(100)]));
        columns.set('bankName', new Tcolumn('bankName', 'Bank Name', 'T', 3, true, undefined, true, '', 'B', [Validators.required, Validators.maxLength(128)]));
        columns.set('bankAddress', new Tcolumn('bankAddress', 'Bank Address', 'TA', 4, true, undefined, true, '', 'B', [Validators.required, Validators.maxLength(254)]));
        columns.set('accountCurrency', new Tcolumn('accountCurrency', 'Account Currency', 'L', 5, true, this.commonService.getCurrencyList(), true, '', 'B', [Validators.required, Validators.maxLength(128)]));
        columns.set('swiftCode', new Tcolumn('swiftCode', 'Swift Code', 'T', 6, true, undefined, true, new defaultValue('', undefined, ['']), 'B', [Validators.maxLength(15)]));
        columns.set('remitToDescription', new Tcolumn('remitToDescription', 'Remit To Description', 'TA', 7, true, undefined, true, '', 'B', [Validators.maxLength(254)]));
        columns.set('noticeOfAssignment', new Tcolumn('noticeOfAssignment', 'Notice Of Assignment', 'TA', 8, true, undefined, true, '', 'B', [Validators.maxLength(254)]));
        columns.set('paymentMethod', new Tcolumn('paymentMethod', 'Payment Method', 'ML', 9, true, this.masterCommonService.getListFromArray(this.loadDataList['payment_method_ind'],false,false), true, '', 'B', [Validators.maxLength(254)]));
        columns.set('defaultValue', new Tcolumn('defaultValue', 'Default', 'B', 8, true, this.masterCommonService.getBooleanFromLabel('Yes', 'No'), true, true, 'B', [Validators.required]));
        columns.set('status', new Tcolumn('status', 'Status', 'B', 10, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));
        let grid = new Grid(columns, true, true, true, true, 'accountNumber', '/api/companybankaccount/v1/getbycompanyuuid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&companyUuid=', '', 'Bank Account', 'name', '', undefined, undefined, false);
        grid.setSaveUrl(environment.base_url + '/api/companybankaccount/v1/save');
        grid.setUpdateUrl(environment.base_url + '/api/companybankaccount/v1/update');
        grid.hrefUrl = environment.base_url + '/api/companybankaccount/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
        grid.addSection('Bank Detail', ['name', 'accountNumber', 'bankName', 'bankAddress', 'accountCurrency']);
        grid.addSection('Transaction Preference', ['swiftCode', 'remitToDescription', 'noticeOfAssignment', 'paymentMethod']);
        grid.addSection('Other', ['defaultValue', 'status']);
        grid.importUrl = environment.base_url + '/api/companybankaccount/v1/import';
        let tab = new Tab(grid.getTitle(), grid, 'name');
        tab.idFieldValueName = 'companyUuid';
        return tab;
    }

    public getAddressDetails() {
        let _this = this;
        let countryFunc = function(value: string[] = []) {
            let countryCode = value[0];
            let country = _this.commonService.getFromCountryMap(countryCode);
            let extra = {};
            extra['selectedCountry'] = _this.commonService.getFromCountryMap(_this.commonService.getFromStorage('country'))['alpha2Code'];
            if (country !== undefined) {
                extra['selectedCountry'] = country['alpha2Code'];
            }
            return extra;
        };

        let actionCountry = new Action();
        actionCountry.addFieldToUpdate('telephone', undefined, countryFunc, ['country', 'addressType'], undefined, true, 'extra');
        actionCountry.addFieldToUpdate('fax', undefined, countryFunc, undefined, undefined, true, 'extra');
        let columns: Map<string, Tcolumn> = new Map();
        columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
        columns.set('name', new Tcolumn('name', 'Name Code', 'LB', 1, false, undefined, false, '', 'B', [Validators.required]));
        columns.set('addressType', new Tcolumn('addressType', 'Address Type', 'L', 2, true, this.masterCommonService.getListFromArray(this.loadDataList['address_type_ind']), true, '', 'B', [Validators.required]));
        columns.set('country', new Tcolumn('country', 'Country', 'L', 3, true, this.commonService.getCountryList(), true, '', 'B', [Validators.required, Validators.maxLength(56)], undefined, undefined, undefined, undefined, undefined, actionCountry));
        columns.set('telephone', new Tcolumn('telephone', 'Telephone', 'TN', 4, true, undefined, true, '', 'B', [Validators.maxLength(15)]));
        columns.set('fax', new Tcolumn('fax', 'Fax', 'TN', 5, true, undefined, true, '', 'B', [Validators.maxLength(15)]));
        columns.set('address', new Tcolumn('address', 'Address', 'TA', 6, true, undefined, true, '', 'B', [Validators.required, Validators.maxLength(256)]));
        columns.set('tag', new Tcolumn('tag', 'Tag', 'T', 7, true, undefined, true, '', 'B', [Validators.maxLength(256)]));
        columns.set('defaultValue', new Tcolumn('defaultValue', 'Default', 'B', 8, true, this.masterCommonService.getBooleanFromLabel('Yes', 'No'), true, true, 'B', [Validators.required]));
        let grid = new Grid(columns, true, true, true, true, 'addressType', '/api/companyaddressdetails/v1/getbycompanyuuid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&companyUuid=', '', 'Address Details', 'name', '', undefined, undefined, false,);
        grid.setSaveUrl(environment.base_url + '/api/companyaddressdetails/v1/save');
        grid.setUpdateUrl(environment.base_url + '/api/companyaddressdetails/v1/update');
        grid.hrefUrl = environment.base_url + '/api/companyaddressdetails/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
        grid.importUrl = environment.base_url + '/api/companyaddressdetails/v1/import';
        let tab = new Tab(grid.getTitle(), grid, 'name');
        tab.idFieldValueName = 'companyUuid';
        return tab;
    }

    public getColumns() {
        let updatelogoname = function updatename(value: any = []) {
            let file = value[0];
            return file['name'];
        };
        let updatelogotype = function updatetype(value: any = []) {
            let file = value[0];
            return file['type'];
        };

        let listCompParentFunc = function (value: any[] = []) {
            let list:any[] = value[0];
            if(list !== null && list !== undefined && list.length > 0) {
                list.splice(0,1);
            }
            list.splice(0,0,new KeyValue('None','none'));
            return list;
        };
        let actionforParentComp: Action = new Action(environment.base_url + '/api/company/v1/getparentlist?tenantId=' + this.commonService.getFromStorage('tenantId') + '&name=', 'name', 'name');
        actionforParentComp.setCovertToListRequired(true);
        actionforParentComp.addFieldToUpdate("parent", undefined, listCompParentFunc, ['L'], undefined, true, 'listvalues', false, false);


        let action: Action = new Action();
        action.addFieldToUpdate('logoFileName', '', updatelogoname, undefined, undefined, false);
        action.addFieldToUpdate('logoFileType', '', updatelogotype, undefined, undefined, false);
        this.columns = new Map();
        this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
        this.columns.set('code', new Tcolumn('code', 'Company Code', 'T', 1, false, undefined, true,new defaultValue('',undefined,['-','_'],undefined,false,true,false,undefined,undefined,true), 'B', [Validators.required, Validators.maxLength(8)], undefined, undefined, undefined, undefined, [], undefined, undefined, true));
        this.columns.set('name', new Tcolumn('name', 'Company Name', 'T', 1, false, undefined, true, new defaultValue('', undefined, environment.primaryKeyIncludedCharacters), 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, actionforParentComp, {style: {width: '5%'}}));
        this.columns.set('description', new Tcolumn('description', 'Company Description', 'TA', 4, true, undefined, true, undefined, 'B', [Validators.maxLength(254)], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '10%'}}));
        this.columns.set('parent', new Tcolumn('parent', 'Parent Company', 'L', 4, true, this.getParent(), true, 'none', 'B', [Validators.required]));
        this.columns.set('timeZone', new Tcolumn('timeZone', 'Timezone', 'L', 5, true, this.commonService.getTimeZoneList(), true, this.commonService.getFromStorage('timezone'), 'B', [Validators.required, Validators.maxLength(100)], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '12%'}}));
        this.columns.set('dayLightSaving', new Tcolumn('dayLightSaving', 'Day Light Saving', 'B', 12, true, this.masterCommonService.getEnableDisable(), true, false, 'B', [Validators.required, Validators.maxLength(5)], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '8%'}}));
        this.columns.set('currency', new Tcolumn('currency', 'Base/Pnl Currency', 'L', 6, true, this.commonService.getCurrencyList(), true, this.commonService.getFromStorage('currency'), 'B', [Validators.required, Validators.maxLength(5)], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '7%'}}));
        this.columns.set('baseuom', new Tcolumn('baseuom', 'Base/Pnl UOM', 'L', 6, true, this.masterCommonService.getListFromArray(this.loadDataList['unitofmeasurement'],false,true), true, this.commonService.getFromStorage('currency'), 'B', [Validators.required, Validators.maxLength(5)], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '7%'}}));
        this.columns.set('website', new Tcolumn('website', 'Website', 'T', 7, true, undefined, true, '', 'B', [Validators.maxLength(100), Validators.pattern('^((https?|ftp|smtp):\\/\\/)?(www.)?[a-z0-9]+(\\.[a-z]{2,}){1,3}(#?\\/?[a-zA-Z0-9#]+)*\\/?(\\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$')], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '10%'}}));
        this.columns.set('telephone', new Tcolumn('telephone', 'Telephone', 'LB', 8, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '7%'}}));
        this.columns.set('fax', new Tcolumn('fax', 'Fax', 'LB', 9, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '7%'}}));
        this.columns.set('address', new Tcolumn('address', 'Address', 'LB', 10, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '20%'}}));
        this.columns.set('logo', new Tcolumn('logo', 'Logo', 'F', 11, true, undefined, true, '', 'B', [], 'text', 1, undefined, undefined, undefined, action, {
            style: {width: '10%'},
            downloadUrl: {url: environment.base_url + '/companyMasters/download/', para: ['companyCode']}
        }));
        this.columns.set('isTradeable', new Tcolumn('isTradeable', 'Tradeable', 'B', 12, true, this.masterCommonService.getYesNo(), true, true, 'B', [Validators.required]));
        this.columns.set('status', new Tcolumn('status', 'Status', 'B', 13, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));
        this.columns.set('logoFileName', new Tcolumn('logoFileName', 'Logo Name', 'LB', 14, false, undefined, false, '', 'H'));
        this.columns.set('logoFileType', new Tcolumn('logoFileType', 'Logo Type', 'LB', 15, false, undefined, false, '', 'H'));

    }

    getMonthTo(yearFrom: any = new Date().getFullYear(), monthFrom: any = new Date().getMonth(), yearTo: any = new Date().getFullYear()) {
        let monthList = this.commonService.getMonthList();
        let list = [];
        if (parseInt(yearFrom) === parseInt(yearTo)) {
            monthList.forEach(function(obj: KeyValue) {
                if (obj.getValue() >= monthFrom) {
                    list.push(obj);
                }
            });
            return list;
        } else {
            return monthList;
        }
    }

    getYearTo(from: any = new Date().getFullYear()) {
        let list = [];
        let to = parseInt(from) + 10;
        for (let i = from; i <= to; i++) {
            list.push(new KeyValue(i + '', i));
        }
        return list;
    }

    initFunctions() {

        let this1 = this;
        this.yeartfun = function getYearTo(value: any = []) {
            let from = value[0];
            return this1.getYearTo(from);

        };
        this.monthto = function getMonthTo(value: any = []) {
            let yearFrom = value[0];
            let monthFrom = value[1];
            let yearTo = value[2];
            let monthList = this1.commonService.getMonthList();
            if (yearFrom !== undefined && monthFrom !== undefined && yearTo !== undefined) {
                return this1.getMonthTo(yearFrom, monthFrom, yearTo);
            } else {
                return monthList;
            }
        };
    }

    onRowDataSave(value: any) {
        let _this = this;
        if (value['data']) {
            value = value['data'];
        }
        let data = value['result'];
        let mode = value['mode'];
        if (data['newData']) {
            data = data['newData'];
        }
        if (value['label'] === 'Address Details' && (mode === 'save' || mode === 'update' || mode === 'copy')) {
            if (data['defaultValue'] === true) {
                let _this = this;
                this.refreshGrid = false;
                let def = new ChildAction();
                def.setMode('makedefault');
                def.setCommonService(this.commonService);
                def.addCriteria('addressType', data['addressType'], 'notEqual');
                def.addCriteria('companyUuid', data['companyUuid']);
                def.addCriteria('tenantId',this.commonService.getFromStorage('tenantId'));
                def.addRepo('xceler_configservice_companyaddressdetails', 'companyAddressDetailsRepo', 'defaultValue');
                def.perform().subscribe(next => {
                    _this.address.getGrid().getColumn().get('defaultValue').setDefaultValue(false);
                    let obj = {
                        companyCode: data['name'],
                        telephone: data['telephone'],
                        fax: data['fax'],
                        address: data['address']
                    };
                    _this.commonService.patch(_this.currentHref, obj).subscribe(next => {
                        _this.refreshGrid = true;
                    });
                });
            }else {
                if(mode === 'update') {
                    _this.updateDefaultOnUpdate(value,_this.address,'defaultValue');
                }
            }
        } else if (value['label'] === 'Bank Account') {
            if (data['defaultValue'] === true) {
                let _this = this;
                let def = new ChildAction();
                def.setMode('makedefault');
                def.setCommonService(this.commonService);
                def.addCriteria('accountNumber', data['accountNumber'], 'notEqual');
                def.addCriteria('companyUuid', data['companyUuid']);
                def.addCriteria('tenantId',this.commonService.getFromStorage('tenantId'));
                def.addRepo('xceler_configservice_companybankaccount', repos.companyBankAccount, 'defaultValue');
                def.perform().subscribe(next => {
                    _this.bankAccount.getGrid().getColumn().get('defaultValue').setDefaultValue(false);
                    _this.refreshGrid = true;
                });
            }else {
                if(mode === 'update') {
                    _this.updateDefaultOnUpdate(value,_this.bankAccount,'defaultValue');
                }
            }
        }

    }
    private updateDefaultOnUpdate(value:any,tab:Tab,defaultFieldName:string) {
        let oldData = value['result']['oldData'];
        if(oldData[defaultFieldName]) {
            tab.getGrid().getColumn().get(defaultFieldName).setDefaultValue(true);
        }
    }

  onUniqueClick(value: any) {
    /**
     * work to perform when click on unique key in grid
     *
     **/
    // this.currentHref = value['rowData']['href'].toString().replace('http://localhost:8080/ctrm-api/', environment.base_url);
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url+'/api/company/v1/isdefaultavailable?tenantId='+this.commonService.getFromStorage('tenantId')+'&companyUuid='+value['rowData']['uuid']).subscribe((next:any) => {
      _this.updateTabDefault('bankAccount',next);
      _this.updateTabDefault('address',next);
    });
  }

    openHeirarcy(selectedRow: any) {
        this.selectedRowData = selectedRow[0];
        this.modalStateHierarchy = true;
    }

    closeBtn() {
        this.modalStateHierarchy = false;
    }

    getEntityName() {
        return 'xceler_configservice_company';
    }

    private getParent() {
        let parentList = [];
        parentList.push(new KeyValue('None', 'none'));
        return parentList;
    }

    private getYearList() {
        let year = new Date().getFullYear();
        let list = [];
        for (let i = year - 10; i <= year + 10; i++) {
            list.push(new KeyValue(i + '', i));
        }
        return list;
    }

    contextmenu(){
        this.grid.removeAllExtraButtons()
        let _this = this;
        let accountingcalaendardetails = function (value: any) {

            _this.openTabForContextMenu(value['data'],'Accounting Calendar Details');
        };
        let bankAccount = function (value: any) {
            _this.openTabForContextMenu(value['data'],'Bank Account');
        };
        let adressdetails = function (value: any) {
            _this.openTabForContextMenu(value['data'],'Address Details');
        };

        let AccountingCalendarDetails = new Button("Accounting Calendar Details", 'F', '', accountingcalaendardetails, undefined, undefined, undefined, undefined, false);
        this.grid.addExtraButton(AccountingCalendarDetails);
        let BankAccount = new Button("Bank Account", 'F', '', bankAccount, undefined, undefined, undefined, undefined, false);
        this.grid.addExtraButton(BankAccount);
        let AddressDetails = new Button("Address Details", 'F', '', adressdetails, undefined, undefined, undefined, undefined, false);
        this.grid.addExtraButton(AddressDetails);
    }

    private openTabForContextMenu(value,tabCode) {
        this.openFormForNewEntry = false;
        let _this = this;
        this.commonService.getJSONByURL(environment.base_url+'/api/company/v1/isdefaultavailable?tenantId='+this.commonService.getFromStorage('tenantId')+'&companyUuid='+value['uuid']).subscribe((next:any) => {
            _this.updateTabDefault('bankAccount',next);
            _this.updateTabDefault('address',next);
            _this.updateTabDefault('addressdetails',next);
            _this.selectedTab = tabCode;
            _this.tabRow = value;
            _this.openFormForNewEntry = true;
            _this.openTabScreen = true;
        });

    }
    onTabScreenOpened(){
        this.openTabScreen = false;
    }

    onFormOpned(){
        this.openFormForNewEntry = false;
    }
}
