<div style="width: 100%;height: 100%;display: flex;flex-direction:column;background-color: white">
  <div class="headerImg">
    <img alt="LOGO" src="../../assets/images/xcelerLogo.png" width="100px">
  </div>
  <div style="display: inline-flex">
    <div class="sidebox">
      <div>
        <form (ngSubmit)="checklogin(formgroup)" [formGroup]="formgroup">

          <div class="loginBox">
            <p class="signin">Sign in</p>
            <div class="checkboxGroup">
              <div class="userTypeBox" [ngClass]="selectedValue === 'BusinessAdmin'?'userTypeBoxSelected':''" (click)="setCheckboxValue('BusinessAdmin')">
                <p-radioButton (onClick)="onChange('BusinessAdmin')" [(ngModel)]="selectedValue" [label]="'Business Admin'" [ngModelOptions]="{standalone: true}"
                               name="userSelect"
                               value="BusinessAdmin"></p-radioButton>
                <p class="descriptionUserType">Account owner that performs tasks requiring business setup and application admininistration. <span class="learnMore">Learn more</span></p>
              </div>
              <div class="userTypeBox" style="margin-top: 5px" [ngClass]="selectedValue === 'BusinessUser'?'userTypeBoxSelected':''"  (click)="setCheckboxValue('BusinessUser')">
                <p-radioButton (onClick)="onChange('BusinessUser')" [(ngModel)]="selectedValue" [label]="'Business User'" [ngModelOptions]="{standalone: true}"
                               name="userSelect" value="BusinessUser"></p-radioButton>
                <p class="descriptionUserType">User who performs the daily Tasks. <span class="learnMore">Learn more</span></p>
              </div>
            </div>
            <div *ngIf="showTenantId">
              <label class="label">Company Alias</label>
              <div class="box">
                <input class="inputbox" formControlName="tenantId" type="text">
                <i class="fas fa-userProfile" style="padding: 5px"></i>
              </div>
            </div>
            <div>
              <label class="label">{{selectedValue === 'BusinessAdmin'?'Email Id':'Username'}}</label>
              <div class="box">
                <input class="inputbox" formControlName="username" type="text">
                <i class="fas fa-userProfile" style="padding: 5px"></i>
              </div>
            </div>
            <div>
              <label class="label">Password</label>
              <div class="box">
                <input [type]="showPassword?'text':'password'" class="inputbox" formControlName="password">
                <i (click)="togglePasswordView()" [ngClass]="showPassword?'fas fa-eye-slash':'fas fa-eye'" class="icon"
                   style="padding: 5px"></i>
              </div>
            </div>
            <div>
              <p (click)="forgotPassword()" class="forgotPassword">Change Password?</p>
            </div>
          </div>
          <div style="display: flex; margin-top: 40px;">
            <button class="buttonLogin" style="width: 50%;" >Login</button>
            <button (click)="openSignup()" style="width: 50%;" class="buttonLogin" type="button">Sign Up</button>
          </div>
        </form>
      </div>
      <div *ngIf="submitted" class="errorMsgBox animated fadeIn">
        <div><i class="fas fa-exclamation-triangle errorIcon"></i></div>
        <div><p class="errorMsg">{{message}}</p></div>
      </div>
    </div>
    <div class="mainSection">
      <div class="rightSideBanner  animate__animated animate__fadeIn">
        <div class="laptopImg">
          <img src="../../assets/icons/laptop_screen.svg" alt="Laptop_Screen" width="100%">
        </div>
      </div>
    </div>
  </div>
  <div>
    <p class="about">About Xceler.ai Sign In<br/>
      Xceler.ai uses information from your Xceler business account to identify you and allow access to Xceler.ai. Your
      use of this site is governed by our Terms of Use and Privacy Policy linked below. Your use of Xceler products and
      services is governed by the Xceler.ai Customer Agreement linked below unless you have entered into a separate
      agreement with Xceler Pte Ltd or an Xceler Value Added Reseller to purchase these products and services. The
      Xceler.ai Customer Agreement was updated on June 01, 2017. For more information about these updates, see Recent
      Changes.
    </p>
    <p class="copyright">© 2020, Xceler Pte Ltd or its affiliates. All rights reserved. Terms of Use | Privacy Policy |
      Xceler.ai Customer Agreement</p>
  </div>
</div>
<div *ngIf="isOpen" class="modal" id="myModal">

  <!-- Modal content -->
  <div class="modal-content">
    <div style="margin-left: 700px; cursor: pointer;"><i (click)="toggleForgetEmail()" class="fas fa-times"></i></div>
    <div *ngIf="!confirmEmail">
      <h3 style="text-align: center">Enter Your Email Address</h3>
      <input *ngIf="showTenantId" [formControl]="aliasControl" class="inputForgetPassword" placeholder="Company Alias"
             type="text">
      <input [(ngModel)]="resetEmail" [formControl]="emailControl" class="inputForgetPassword" placeholder="Email"
             type="text">
      <p *ngIf="!emailControl.valid" style="color: red; margin-left: 40px;">Enter valid Email</p>
    </div>
    <div *ngIf="confirmEmail">
      <h3 style="text-align: center">Reset Password</h3>
      <form [formGroup]="confirmEmailGroup">
        <input formControlName="otp" class="inputForgetPassword" placeholder="OTP"
               type="text">
        <input formControlName="password" class="inputForgetPassword" placeholder="New Password"
                type="password"
               pTooltip="'Minimum 12 characters. Atleast one upper case letter, one digit and a special character is required.'" tooltipEvent="focus" tooltipPosition="bottom"
               tooltipZIndex="1000">
<!--        <span class="tooltiptext"></span>-->
        <input formControlName="confirmPassword" class="inputForgetPassword" placeholder="Confirm Password"
               type="password">
        <button (click)="verifyOtp()" *ngIf="!pleaseWaitBtn" class="submitBtn" type="button">SUBMIT</button>
      </form>

    </div>
    <button (click)="postEmail($event)" *ngIf="!pleaseWaitBtn && !confirmEmail" class="submitBtn" type="button">SUBMIT</button>
    <button *ngIf="pleaseWaitBtn" class="submitBtn" disabled type="button"><i class="pi pi-spin pi-spinner"
                                                                              style="font-size: 2em; color: white"></i>
    </button>

  </div>

</div>

<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>



<p-dialog [(visible)]="alertdisplay" [draggable]="false" [modal]="true" [closable]="false"
          [resizable]="false" [style]="{width: '50vw'}">
<h1>{{message}}</h1>
  <p-footer>
    <div>

      <p-button icon="pi pi-check" (click)="onokclick(formgroup)"[formGroup]="formgroup"  label="Yes" styleClass="p-button-text"></p-button>
      <p-button icon="pi pi-check" (click)="alertdisplay=false" label="No" styleClass="p-button-text"></p-button>
    </div>
  </p-footer>
</p-dialog>

