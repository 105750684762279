import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Tcolumn} from "../../grid/tcolumn.model";
import {Grid} from "../../grid/grid.model";
import {AbstractControl, FormControl, Validators} from "@angular/forms";
import {KeyValue} from "../../grid/key-value.model";
import {MasterService} from "../../masters/services/MasterService";
import {CommonService} from "../../services/common.service";
import {ActivatedRoute} from "@angular/router";
import {MessageService} from "primeng";
import {environment} from "../../../environments/environment";
import {HttpErrorResponse} from "@angular/common/http";
import {messages} from "../../services/common/messages";

@Component({
  selector: 'app-basis-commodity-price',
  templateUrl: './basis-commodity-price.component.html',
  styleUrls: ['./basis-commodity-price.component.css']
})
export class BasisCommodityPriceComponent implements OnInit {
  columns: Map<string, Tcolumn>;
  grid: Grid;
  refreshGrid: boolean = false;
  @ViewChild('basis') eod: ElementRef;
  //NODATA BOX
  noDataBoxLeft: any;
  @Input() showFetching: boolean = false;
  isError: boolean = false;
  loadMessage: string = 'Loading ..';
  @Input() noDataLabel: string = 'No Data Available';
  //IMPORT
  displayImport: boolean = false;
  importTableName: string = '';
  parentKeyValue: any;
  //SIDEBAR
  display: boolean = false;
  basisHeight: string = '';
  basisIndexList: any[] = [];
  basisIndex: string = '';
  date: string = '';
  shipmentMonthList: any[] = [];
  shipmentMonth: string = '';
  //GRID
  gridDisplay: boolean = true;
  gridColumns: Tcolumn[] = [];
  values: any[] = [];
  refreshFormGroup: boolean = false;
  addedValues: any[] = [];

  @Input() showTooltipOnValidationError: boolean = false;
  @Input() formControlAIO: FormControl;
  @Input() field: Tcolumn;
  @Input() listOfValues: KeyValue[] = [];
  @Input() readOnly: boolean = false;
  list: any;
  refreshAddNewRow: boolean = false;
  newRows: any[] = [];
  file: File;

  constructor(private masterCommonService: MasterService, public commonService: CommonService, private router: ActivatedRoute, private messageService: MessageService,) {
// this.commonService.getMyRoles();
    this.commonService.getFromStorage('policy');  }

  ngOnInit(): void {
    this.getColumns();
    this.grid = new Grid(this.columns, true, true, true, true, '', '/api/priceIndex/v1/getMarketPrice/basis', '', 'Basis Commodity Price', undefined, undefined);
    this.gridColumns = [...this.masterCommonService.getGridField(this.grid)];
    this.grid.setUpdateUrl(environment.base_url + 'basisCommodityPrice/');
    this.grid.iconName = 'priceFixing';
    this.grid.openDefaultInputForm = false;
    this.grid.setGridConfiguration(true, true, false, false);
    this.grid.setToolbarConfig(true,false,false,false);
  }

  getColumns() {
    this.columns = new Map();
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('priceIndexCode', new Tcolumn('priceIndexCode', 'Price Index', 'LB', 1, true, undefined, true, '', 'B', undefined, undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('shipmentMonth', new Tcolumn('shipmentMonth', 'Shipment Month', 'T', 2, true, undefined, true, '', 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('physicalCommStartDate', new Tcolumn('physicalCommStartDate', 'Start Date', 'D', 3, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('physicalCommEndDate', new Tcolumn('physicalCommEndDate', 'End Date', 'D', 4, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('physicalCommPriceDate', new Tcolumn('physicalCommPriceDate', 'Price Date', 'D', 5, true, undefined, true, '', 'B', undefined, undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('settlementPrice', new Tcolumn('settlementPrice', 'Settlement Price', 'N', 6, true, undefined, true, '', 'B'));
    this.columns.set('pxOpen', new Tcolumn('pxOpen', 'Open', 'N', 7, true, undefined, true, '', 'B'));
    this.columns.set('pxHigh', new Tcolumn('pxHigh', 'High', 'N', 8, true, undefined, true, '', 'B'));
    this.columns.set('pxLow', new Tcolumn('pxLow', 'Low', 'N', 9, true, undefined, true, '', 'B'));
  }

//close the sidebar
  closebasis() {
    this.display = false;
    this.gridDisplay = false;
  }

  //Add the grid
  onClickAddbasis(value: any) {
    this.basisIndex = '';
    this.date = '';
    this.shipmentMonth = '';
    this.refreshGrid = false;
    this.values = [];
    this.addedValues = [];
    this.display = true;
    this.gridDisplay = false;
    this.showbasisList()
  }

  //load the basis Index price
  showbasisList() {
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url + 'api/priceIndex/v1/getFutureIndexList/basis').subscribe((next: any) => {
      _this.basisIndexList = _this.commonService.addListOTT(next);
    });
  }

  onChangebasisIndex(value: any) {
    this.getShipmentMonthList(value);
  }

//get shilpment Month
  getShipmentMonthList(basisindex) {
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url + 'api/priceIndex/v1//getShipmentMonthList?futureIndex=' + basisindex).subscribe((next: any) => {
      _this.shipmentMonthList = _this.commonService.addListOTT(next, 'All', 'all');
      _this.shipmentMonth = 'all';
    })
  }

  addPrice() {
    if (this.values === undefined || this.values === null || this.values.length === 0) {
      this.values = [];
    }
    this.gridDisplay = true;
    this.refreshFormGroup = false;
    let _this = this;
    let basispayload = {
      futureIndex: this.basisIndex,
      shipmentMonth: this.shipmentMonth,
      priceDate: this.date
    };
    this.commonService.getJSONByObject(environment.base_url + 'api/priceIndex/v1/getDataTemplate/basis', basispayload).subscribe((next: any) => {
      _this.values = [...next];
      _this.refreshFormGroup = true;
    })

  }

//Save the basis form
  savebasis(value: any[], isUpdate: boolean = false) {
    let _this = this;
    let basispayloadObj = {
      futureIndex: this.basisIndex,
      priceDate: this.date,
      shipmentMonth: this.shipmentMonth,
      tenantId: this.commonService.getFromStorage('tenantId'),
      updatedBy: this.commonService.getFromStorage('id'),
      commodityPriceList: value

    };
    if (!isUpdate) {
      basispayloadObj['createdBy'] = this.commonService.getFromStorage('id');
    }
    this.commonService.post(environment.base_url + '/api/priceIndex/v1/saveMarketPrice/basis', basispayloadObj).subscribe(next => {
      this.display = false;
      this.refreshGrid = true;
      this.showToast('basis Price is saved');
    }, (error: HttpErrorResponse) => {
      this.messageService.add({severity: 'error', summary: error.error});
    });
  }

  showToast(msg, severity: string = 'success') {
    this.messageService.add({
      severity: 'info',
      summary: msg
    });
  }

  onRefreshFromGroupComplete() {
    this.refreshFormGroup = false;
  }

  //Import the future Commodity Price
  onImportClick() {
    this.display = false;
    this.displayImport = true;
    this.parentKeyValue = this.grid.getDataURL().substr(this.grid.getDataURL().lastIndexOf('=') + 1);
  }

  //close import
  closeImport() {
    this.displayImport = false;
  }

  importDone(success: boolean) {
    this.displayImport = false;
    if (success) {
      this.messageService.add({
        severity: 'info', summary: messages.updated['summary'],
        detail: messages.importCompleted['message'],
        life: 10000
      });

    }
  }

//update
  onSave(value: any) {
    if (value['mode'] === 'update') {
      this.savebasis([value['data']], true);
    }
  }

  //nodata box
  calculateLeft() {
    this.noDataBoxLeft = ((window['visualViewport'].width - 500) / 2) + 'px';
  }

  getTooltipMessage(control: AbstractControl, col) {
    return this.showTooltipOnValidationError ? this.commonService.getValidationErrorMessage(control, col.getHeader()) : '';
  }

  getDateFormat() {
    return environment.dateFormat.toUpperCase();
  }


  onNewRowAddComplete() {
    this.refreshAddNewRow = false;
    this.newRows = [];
  }

  onImport(value: any) {
    this.display = true;
    this.displayImport = false;
    this.file = value['file'];
  }

  onImportComplete() {
    this.file = undefined;
  }

}
