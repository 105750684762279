<p-sidebar (onHide)="toggleDisplay()" [(visible)]="display"
           [baseZIndex]="25"
           [style]="{minWidth:'40%',maxHeight:'100%',maxWidth:'100%',marginTop:'4.5%'}" appendTo="body"
           position="right">
  <div class="divBox">
    <button (click)="onSavewhattodo()"
            style="color: white; max-width: 80px;fborder-radius: 2px; background-color: #009CDF; border-style: none;"
            type="button"><i
      class="fas fa-save"></i> Save
    </button>
    <div>
      <p-tabView>
        <p-tabPanel *ngIf="showFilter" header="Filters">
          <app-filter (FilterList)="updateFilter($event)" [appliedFilters]="gridFilters"
                      [columns]="columns"></app-filter>
        </p-tabPanel>
        <p-tabPanel *ngIf="showColumns" header="Columns">
          <p-listbox (ngModelChange)="onChange($event)" (onClick)="onClick($event)" [(ngModel)]="sc" [multiple]="true"
                     [options]="hc" checkbox="checkbox"
                     filter="filter"></p-listbox>
        </p-tabPanel>
        <p-tabPanel *ngIf="showSorting" header="Sorting">
          <app-sort (onSort)="updateSort($event)" [columns]="columns"></app-sort>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</p-sidebar>
