import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {CommonService} from "../../services/common.service";
import {MessageService, Table} from "primeng";
import {BreadcrumbService} from "../../Components/ctrm-breadcrumb/breadcrumb.service";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-trade-cash-flow',
  templateUrl: './trade-cash-flow.component.html',
  styleUrls: ['./trade-cash-flow.component.css']
})
export class TradeCashFlowComponent implements OnInit {
  tradeCols: any[] = [];
  cashFlowCols: any[] = [];
  tradeData: any[] = [];
  @ViewChild('planTable', {static: true}) planTable;
  @ViewChild('mainTable') tradeTable: Table;
  planTableHeight: string = '';
  display: boolean = false;
  tradeId: string = '';
  rowData: any;
  currentPageNumber:number = 0;
  pageSize:number = 20;
  totalElements: number = 0;
  totalPages:number = 0;

  constructor(private commonService: CommonService, private messageService: MessageService, public breadCrumbService: BreadcrumbService, private router: Router) {
  }

  ngOnInit(): void {
    this.breadCrumbService.makeBreadcrumbTo(this.commonService.FINANCE_MAP_KEY, '/debitcreditnote');

    let valueFunctionforTradePrice = function (value:any) {
      return value['tradePriceValue']+' '+value['tradePriceCurrency']
    };
    this.tradeCols = [
      {field: 'tradeId', header: 'Trade Id'},
      {field: 'commodity', header: 'Commodity'},
      {field: 'counterparty', header: 'Counterparty'},
      {field: 'profitcenter', header: 'Profit Center'},
      {field: 'tradePrice', header: 'Price'},
      {field: 'quantity', header: 'Trade Quantity'}
    ];
    this.cashFlowCols = [
      {field: 'cashflowId', header: 'Cash Flow Id'},
      {field: 'type', header: 'Cashflow Stage'},
      {field: 'paymentDueDate', header: 'Payment Due Date', type: 'date'},
      {field: 'cashflowStatus', header: 'Status'},
      {field: 'financialAmountType', header: 'Cashflow Type'},
      {field: 'tradePriceValue', header: 'Amount',valueFunction:valueFunctionforTradePrice}
    ];
    this.getTrade();
  }

  @HostListener("window:resize", [])
  public onResize() {
    this.calculateHeight();
  }


  tradeRefresh() {
    this.getTrade();
  }

  getClassForHeading(index) {
    return index > 0 ? 'headerNormal' : 'noPipe';
  }

  gotoTrade() {
    this.router.navigate(['/trade']);
  }

  getTrade() {
    let _this = this;
    this.tradeData = [];
    this.commonService.getFromUrl(environment.base_url + '/api/cashflow/v1/getAllCnDnCashFlows?tenantId='+this.commonService.getFromStorage('tenantId')+'&page='+this.currentPageNumber+'&size=20').subscribe((next: any[]) => {
      _this.tradeData = next['content'];
      _this.pageSize = next['pageable']['pageSize'];
      _this.totalElements = next['totalElements'];
      _this.totalPages = next['totalPages'];
      this.calculateHeight();
    });
  }

  openCashflowRow(value: any) {
    if (value['data'] !== undefined && value['data'] !== null) {
      let _this = this;
      let tradeId = value['data']['tradeId'];
      this.commonService.getJSONByURL(environment.base_url + '/api/cashflow/v1/getallcndncashflowsbytradeId?tenantId=' + _this.commonService.getFromStorage('tenantId') +'&tradeId='+ tradeId).subscribe((next: any) => {
        value['data']['cashFlowBaseMasterList'] = next['cashFlowBaseMasterList'];
      });
    }
  }

  onPageChange(value: any) {
    this.currentPageNumber = value['page'];
    this.getTrade();
  }

  getPagination() {
    return this.totalElements > this.pageSize;
  }

  calculateHeight() {
    let offsetTop = this.tradeTable.el.nativeElement['offsetTop'];
    let offsetHeight = this.tradeTable.el.nativeElement['offsetParent']['offsetHeight'];
    this.planTableHeight = (offsetHeight - offsetTop) - 20 + 'px';
    if(this.getPagination()) {
      offsetHeight = offsetHeight - 30;
    }
    this.planTableHeight = (offsetHeight - offsetTop - 20) + 'px';
  }

  ngAfterViewInit() {
    this.calculateHeight();
  }

  getFilterValue($event: Event) {
    this.tradeTable.filterGlobal(event.target['value'], 'contains');

  }

  openTradeInfo(rowData) {
    this.tradeId = rowData['tradeId'];
    this.rowData = rowData;
    this.display = true;
  }

  closeSidePanel() {
    this.tradeId = '';
    this.display = false;
  }
}

