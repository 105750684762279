import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'ctrm-toggle-button',
  templateUrl: './ctrm-toggle-button.component.html',
  styleUrls: ['./ctrm-toggle-button.component.css']
})
export class CtrmToggleButtonComponent implements OnInit {

  @Input() checkLabel: string = "ON";
  @Input() unCheckLabel: string = "OFF";
  @Output() changed = new EventEmitter<boolean>();
  value: any;

  constructor() {
  }

  ngOnInit() {
  }

  onChange(checked: any) {

  }
}
