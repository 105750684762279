<p-sidebar (onHide)="closeBtn()" [(visible)]="visible" [baseZIndex]="25" [closeOnEscape]="true"
           [fullScreen]="true" [modal]="true" [showCloseIcon]="false" [style]="{minWidth:'60%',maxWidth:'60%'}">
  <div class="rootName">
    <div>{{titlePrefix + ' - ' + rootName}}</div>
    <i (click)="closeBtn()" class="material-icons closable">clear</i>
  </div>
  <ctrm-hierarchy [selectedKey]="rowData[nameField]" [value]="hierarchyData"></ctrm-hierarchy>
  <div class="closeBtnContainer">
    <button (click)="closeBtn()" class="mybuttonClose">Close</button>
  </div>
</p-sidebar>
