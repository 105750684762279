

<div class="ui-g">
  <div *ngFor="let widget of widgetJson">

      <div *ngIf="widget['selected']">
        <app-widget  [isLive]="isLive" [traderList]="traderList"
                     [tradeTypeList]="tradeTypeList"
                     [futureIndexList]="futureIndexList"
                     [userDetails]="userDetails"
                     [loadData]="loadData"
                     [widgetProp]="widget"
                     (closeWidget)="throwCloseWidget($event)"
                     (dataView)="openTableDialog($event)"></app-widget>
      </div>

  </div>
</div>
<div *ngIf="userDetails.length === 0" class="loadingWidgetsWrapper">
    <img class="loadingIco" src="../assets/images/doubleRingLoading.gif" alt="">
    <p class="widLoadingText">Loading Widgets...</p>
</div>
