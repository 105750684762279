<div  style="display: inline-flex;align-items: center;font-size: 12px !important; " >
    <img *ngIf="!textMode" src="../../../assets/icons/{{iconList[iconNameList.indexOf(selectedIconName)]}}"
         (mouseenter)="onMouseHoverIcon($event,overlayPanel)" (mouseleave)="overlayPanel.hide()">
    <p *ngIf="textMode" [ngStyle]="getTextCss()" (mouseenter)="onMouseHoverIcon($event,overlayPanel)">{{selectedIconName}}</p>

</div>

<p-overlayPanel #overlayPanel [dismissable]="true" [appendTo]="'body'" [style]="{width:'fit-content'}" >
    <div (mouseleave)="overlayPanel.hide()">
        <p style=" color: #15104E;  font-weight: 600;margin: 10px;">{{header}}</p>
        <div *ngIf="showLoading">
            <div class="loadingbutton">
                <div *ngIf="(errorMessage === null || errorMessage === undefined || errorMessage.length === 0)" class="loader"></div>
                <p>{{(errorMessage !== null && errorMessage !== undefined && errorMessage.length > 0)?errorMessage:'Loading'}}</p>
            </div>
        </div>
        <div *ngIf="!showLoading">
            <ng-container [ngTemplateOutlet]="templateList[iconNameList.indexOf(selectedIconName)]"
                          [ngTemplateOutletContext]="{rowData:rowData,dbResponse:dbResponse}"></ng-container>
        </div>

        <div *ngIf="buttonNameList[iconNameList.indexOf(selectedIconName)] !== null &&
              buttonNameList[iconNameList.indexOf(selectedIconName)] !== undefined &&
               buttonNameList[iconNameList.indexOf(selectedIconName)].length > 0"
             style="padding: 5px; background: #e3e3e3; margin: 0px;text-align: center">
            <button *ngFor="let button of buttonNameList[iconNameList.indexOf(selectedIconName)]" [disabled]="showLoading"
                    class="priceBtn" (click)="onCLickButton(button,overlayPanel)" >{{button}}</button>
        </div>
    </div>
</p-overlayPanel>
