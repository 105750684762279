import {Component, OnInit, ViewChild} from '@angular/core';
import {Tcolumn} from '../../grid/tcolumn.model';
import {Grid} from '../../grid/grid.model';
import {CommonService} from '../../services/common.service';
import {MasterService} from '../services/MasterService';
import {Action} from '../../grid/actions.model';
import {Validators} from '@angular/forms';
import {defaultValue} from '../../grid/defaultValue.model';
import {KeyValue} from '../../grid/key-value.model';
import {environment} from '../../../environments/environment';
import {Tab} from '../../tab-grid/Tab.model';
import {BreadcrumbService} from '../../Components/ctrm-breadcrumb/breadcrumb.service';
import {SelectItem, Table} from 'primeng';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-priceindex',
  templateUrl: './priceindex.component.html',
  styleUrls: ['./priceindex.component.css']
})
export class PriceindexComponent implements OnInit {

  physicalcolumns: Map<string, Tcolumn>;
  fxcolumns: Map<string, Tcolumn>;
  futurecolumns: Map<string, Tcolumn>;
  physicalgrid: Grid;
  fxgrid: Grid;
  futuregrid: Grid;
  getTickerForFuture: Function;
  getTicker: Function;
  tabs: Tab[] = [];
  private minDate: Function;
  private maxDate: Function;
  globalFilterValue: string = '';
  @ViewChild('priceTable', {static: true}) priceTable: Table;
  tabValue: string;
  data: any[] = [];
  tabOptions: SelectItem[] = [];
  futureTab: Tab[] = [];
  fxTab: Tab[] = [];
  hideChild: boolean = false;
  size: number = 0;
  priceIndexLoadData:any[]= [];
  search: any;
  datePipe: DatePipe;

  constructor(public commonService: CommonService, private masterCommonService: MasterService,public breadCrumbService: BreadcrumbService) {
    this.datePipe = new DatePipe('en-US');
  }

  getTabLable(tabValue) {
    if (tabValue === 'physical') {
      return 'Physical/Basis Index';
    } else {
      return 'FX Index';
    }
  }

  //FX, IR, Basis, Physica, Futures, Typical, Freight and etc are Global Indicator Values coming from index_type_ind
  ngOnInit() {
    this.breadCrumbService.makeBreadcrumbTo(this.commonService.MASTER_MAP_KEY, '/priceIndex');
    this.tabOptions = [
      {label: 'Physical/Basis Index', value: 'physical'},
      {label: 'FX Index', value: 'fx'}];

      let _this = this;
    this.getTickerForFuture = function ticker(value: any[] = []) {
      if (value[0] !== undefined && value[0] !== null && value[0].length > 0) {
        let month = _this.getFormattedDateMonthYear(new Date(value[0]), 'MMMM');
        let year = _this.getFormattedDateMonthYear(new Date(value[0]), 'yy');
        if(month === null || month === undefined || month.length === 0) {
          return value[0];
        }
        return _this.masterCommonService.getTickerForMonth(month.toLowerCase()) + year;
      }
      return '';
    };

    this.minDate = function (value: any[] = []) {
      let minDate = new Date();
      if (value[0].length > 0) {
        let split = value[0].split('-');
        let year = parseInt(split[1]);
        let month = split[0];
        minDate = _this.commonService.getFistDateOfMonth(_this.commonService.getMonthNumber(month), year);
      }
      return minDate.toDateString();
    };
    this.maxDate = function (value: any[] = []) {
      let maxDate = new Date();
      if (value[0].length > 0) {
        let split = value[0].split('-');
        let year = parseInt(split[1]);
        let month = split[0];
        maxDate = _this.commonService.getLastDateOfMonth(_this.commonService.getMonthNumber(month), year);
      }
      return maxDate.toDateString();
    };

      this.commonService.getLoadData('physicalbasisindex', ['config', 'config', 'config', 'data', 'data', 'data', 'data', 'data', 'data','data'],
          ['commodity', 'location', 'unitofmeasurement',
      [{key:'name', value:'physical_index_type_ind'}],
      [{key:'name', value:'location_type_ind'}],
      [{key:'name', value:'fx_index_type_ind'}],
      [{key:'name', value:'fx_pair_trade_ind'}],
      [{key:'name', value:'future_index_type_ind'}],
      [{key:'name', value:'fx_tenor_period_ind'}],
      [{key:'name', value:'lme_metal_future_period_ind'}]]).subscribe( (next:any) => {
          this.priceIndexLoadData = next;

      //Physical/Basis Grid
      this.getPhysicalColumns();
          this.physicalgrid = new Grid(this.physicalcolumns, true, true, true, true, 'name', '/api/physicalbasisindex/v1/getall?tenantId=' + this.commonService.getFromStorage('tenantId'), '', 'Physical Price Index', '', '', undefined, undefined, undefined);
      this.physicalgrid.setTabs(this.tabs);
      this.physicalgrid.onEditViewThrowUnique = true;
      this.physicalgrid.iconName = 'priceIndex';
      this.physicalgrid.addSection('Index Detail', ['code','name', 'description', 'indexType', 'commodity','uom']);
      this.physicalgrid.addSection('Trade Cycle', ['currency','tradingMonth','exchangeName','locationType','location']);
      this.physicalgrid.addSection('Other', ['rollingMonth','status']);
      this.physicalgrid.addChildTabs(this.getPeriodCalendarPhysicalBasisFP([], '', 'Physical or Basis'));
      this.physicalgrid.setSaveUrl(environment.base_url + '/api/physicalbasisindex/v1/save');
      this.physicalgrid.setUpdateUrl(environment.base_url + '/api/physicalbasisindex/v1/update');
      this.physicalgrid.hrefUrl = environment.base_url + '/api/physicalbasisindex/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
      this.physicalgrid.importUrl = environment.base_url + '/api/physicalbasisindex/v1/importphysicalbasisindex';

      //Future Grid
      this.getFutureColumns();
          this.futuregrid = new Grid(this.futurecolumns, true, true, true, true, 'name', '/api/futureoptionindex/v1/getall?tenantId=' + this.commonService.getFromStorage('tenantId'), '', 'Future Price Index', '', '', undefined, undefined, undefined);
      this.futuregrid.setTabs(this.futureTab);
      this.futuregrid.onEditViewThrowUnique = true;
      this.futuregrid.iconName = 'priceIndex';
      this.futuregrid.addSection('Index Detail', ['code','name', 'description', 'indexType', 'commodity']);
      this.futuregrid.addSection('Commodity Exchange', ['uom','lotSize','exchangeName','location','tradingMonth','currency']);
      this.futuregrid.addSection('Other', ['rollingMonth','status']);
      this.futuregrid.addChildTabs(this.getPeriodCalendarFutureOption(null, [], '', 'Future or Option'));
      this.futuregrid.setSaveUrl(environment.base_url + '/api/futureoptionindex/v1/save');
      this.futuregrid.setUpdateUrl(environment.base_url + '/api/futureoptionindex/v1/update');
      this.futuregrid.hrefUrl = environment.base_url + '/api/futureoptionindex/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
      this.futuregrid.importUrl = environment.base_url + '/api/futureoptionindex/v1/import';

      //Fx Grid
          this.getFxColumns();
          this.fxgrid = new Grid(this.fxcolumns, true, true, true, true, 'name', '/api/fxirindex/v1/getall?tenantId=' + this.commonService.getFromStorage('tenantId'), '', 'FX Price Index', '', '', undefined, undefined, undefined);
      this.fxgrid.setTabs(this.fxTab);
      this.fxgrid.onEditViewThrowUnique = true;
      this.fxgrid.iconName = 'priceIndex';
      this.fxgrid.addSection('Index Detail', ['code','name', 'description', 'indexType']);
      this.fxgrid.addSection('FX Cycle', ['currencyPair','exchangeName','termStructure']);
      this.fxgrid.addSection('Other', ['status']);
      this.fxgrid.addChildTabs(this.getPeriodCalendarFXIR('', 'FX or IR'));
      this.fxgrid.setSaveUrl(environment.base_url + '/api/fxirindex/v1/save');
      this.fxgrid.setUpdateUrl(environment.base_url + '/api/fxirindex/v1/update');
      this.fxgrid.hrefUrl = environment.base_url + '/api/fxirindex/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
      this.fxgrid.importUrl = environment.base_url + '/api/fxirindex/v1/importfxirindex';
    });
    this.tabValue = 'physical';

  }

  public getPeriodCalendarPhysicalBasisFP(stdatelist: any[], indexType: string, tabLabel: string = 'default') {
    if (tabLabel === 'default') {
      tabLabel = this.commonService.applyTitleCaseFormatting(indexType) + '-Period Calendar';
    }
    let action = new Action();
    action.addFieldToUpdate('periodCalendarEndDate', undefined, undefined, undefined, undefined, false, 'minDate');
    let actionTerminalDate = new Action();
    actionTerminalDate.addFieldToUpdate('periodCalendarStartDate', undefined, this.minDate, undefined, undefined, true, 'minDate');
    actionTerminalDate.addFieldToUpdate('periodCalendarStartDate', undefined, this.maxDate, undefined, undefined, true, 'maxDate');
    actionTerminalDate.addFieldToUpdate('periodCalendarEndDate', undefined, this.minDate, undefined, undefined, true, 'minDate');
    actionTerminalDate.addFieldToUpdate('periodCalendarEndDate', undefined, this.maxDate, undefined, undefined, true, 'maxDate');
    let columns: Map<string, Tcolumn> = new Map();
    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
      columns.set('name', new Tcolumn('name', 'Price Index Name', 'LB', 1, false, undefined, false, '', 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '5%'}}));
      columns.set('shipmentTerminalMonth', new Tcolumn('shipmentTerminalMonth', 'Shipment/Terminal Month', 'L', 2, true, stdatelist, true, '', 'B', [Validators.required, Validators.maxLength(15)], undefined, 1, undefined, undefined, undefined, actionTerminalDate));
    columns.set('periodCalendarStartDate', new Tcolumn('periodCalendarStartDate', 'Start Date', 'D', 3, true, undefined, true, '', 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, action));
    columns.set('periodCalendarEndDate', new Tcolumn('periodCalendarEndDate', 'End Date', 'D', 4, true, undefined, true, '', 'B', [Validators.required]));
      columns.set('status', new Tcolumn('status', 'Status', 'B', 5, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));
      let grid = new Grid(columns, true, true, true, true, 'shipmentTerminalMonth', '/api/physicalbasisperiodcalendar/v1/getbyphysicalbasisindexuuid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&physicalBasisIndexUuid=', '', tabLabel, 'name', '', undefined, undefined, false);
    grid.importUrl = environment.base_url + '/api/physicalbasisperiodcalendar/v1/importphysicalbasisperiodcalendar';
    grid.setSaveUrl(environment.base_url + '/api/physicalbasisperiodcalendar/v1/save');
    grid.setUpdateUrl(environment.base_url + '/api/physicalbasisperiodcalendar/v1/update');
    grid.hrefUrl = environment.base_url + '/api/physicalbasisperiodcalendar/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
    let tab = new Tab(grid.getTitle(), grid, 'name');
      tab.idFieldValueName = 'physicalBasisIndexUuid';
    return tab;
  }

    public getPeriodCalendarFutureOption(tickerValidators: any, shipmentTerminalMonthListforfuture: any[], indexType: string, tabLabel: string = 'default') {
    if (tabLabel === 'default') {
      tabLabel = this.commonService.applyTitleCaseFormatting(indexType) + '-Period Calendar';
    }

      let startdate = function (value: any[] = []) {
        if(value !== undefined && value !== null){
          let date = new Date(value[0]);
          date.setDate(date.getDate()+1)
          return date;
        }
        return '';
      };

    let actionsd = new Action();
    actionsd.addFieldToUpdate('expiryDate', undefined, startdate, undefined, undefined, true, 'minDate');
    actionsd.addFieldToUpdate('firstNotificationDate', undefined, undefined, undefined, undefined, true, 'minDate');
    actionsd.addFieldToUpdate('lastNotificationDate', undefined, undefined, undefined, undefined, true, 'minDate');
    actionsd.addFieldToUpdate('firstDeliveryDate', undefined, undefined, undefined, undefined, true, 'minDate');
    actionsd.addFieldToUpdate('lastDeliveryDate', undefined, undefined, undefined, undefined, true, 'minDate');

      let disableExtra = (value: any[] = []) => {
        let obj = {meta: {disabled: false}};
        if (value[0] === '3-Month' || value[0] === '6-Month' || value[0] === 'Cash') {
          obj = {meta: {disabled: true}};
        }
        return obj;
      };

    let actionForTicker = new Action();
        actionForTicker.addFieldToUpdate('ticker', undefined, this.getTickerForFuture, undefined, undefined, true, 'defaultValue');
        actionForTicker.addFieldToUpdate('tradeStartDate', undefined, disableExtra, undefined, undefined, true, 'extra');
        actionForTicker.addFieldToUpdate('expiryDate', undefined, disableExtra, undefined, undefined, true, 'extra');
        actionForTicker.addFieldToUpdate('firstNotificationDate', undefined, disableExtra, undefined, undefined, true, 'extra');
        actionForTicker.addFieldToUpdate('lastNotificationDate', undefined, disableExtra, undefined, undefined, true, 'extra');
        actionForTicker.addFieldToUpdate('firstDeliveryDate', undefined, disableExtra, undefined, undefined, true, 'extra');
        actionForTicker.addFieldToUpdate('lastDeliveryDate', undefined, disableExtra, undefined, undefined, true, 'extra');

      let columns: Map<string, Tcolumn> = new Map();
    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    columns.set('name', new Tcolumn('name', 'Price Index Name', 'LB', 1, false, undefined, false, '', 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '5%'}}));
    columns.set('shipmentTerminalMonth', new Tcolumn('shipmentTerminalMonth', 'Shipment/Terminal Date', 'L', 2, false, shipmentTerminalMonthListforfuture, true, '', 'B', [Validators.required, Validators.maxLength(15)], undefined, 1, undefined, undefined, undefined, actionForTicker));
    columns.set('ticker', new Tcolumn('ticker', 'Ticker', 'LB', 5, true, undefined, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, undefined, true));
    columns.set('tradeStartDate', new Tcolumn('tradeStartDate', 'Trade Start Date', 'D', 5, true, undefined, true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionsd,{meta: {disabled: false}}));
    columns.set('expiryDate', new Tcolumn('expiryDate', 'Expiry Date', 'D', 6, true, undefined, true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined,undefined,{meta: {disabled: false}}));
    columns.set('firstNotificationDate', new Tcolumn('firstNotificationDate', 'First Notification Date', 'D', 7, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined,undefined,{meta: {disabled: false}}));
    columns.set('lastNotificationDate', new Tcolumn('lastNotificationDate', 'Last Notification Date', 'D', 8, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined,undefined,{meta: {disabled: false}}));
    columns.set('firstDeliveryDate', new Tcolumn('firstDeliveryDate', 'First Delivery Date', 'D', 9, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined,undefined,{meta: {disabled: false}}));
    columns.set('lastDeliveryDate', new Tcolumn('lastDeliveryDate', 'Last Delivery Date', 'D', 10, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined,undefined,{meta: {disabled: false}}));
    columns.set('status', new Tcolumn('status', 'Status', 'B', 11, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));

    let grid = new Grid(columns, true, true, true, true, 'shipmentTerminalMonth', '/api/futureoptionperiodcalendar/v1/getbyfutureindexuuid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&futureIndexUuid=', '', tabLabel, 'name', '', undefined, undefined, false);
    grid.importUrl = environment.base_url + '/api/futureoptionperiodcalendar/v1/importfutureoptionsperiodcalendar';
    grid.setSaveUrl(environment.base_url + '/api/futureoptionperiodcalendar/v1/save');
    grid.setUpdateUrl(environment.base_url + '/api/futureoptionperiodcalendar/v1/update');
    grid.hrefUrl = environment.base_url + '/api/futureoptionperiodcalendar/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
    let tab = new Tab(grid.getTitle(), grid, 'name');
    tab.idFieldValueName = 'futureIndexUuid';
    return tab;
  }

  public getPeriodCalendarFXIR(giCode, tabLabel: string = 'Period Calendar') {
    let _this = this;
    let exipryFunc: Function = function expiryFunction(values: any[] = []) {
      let date: Date = new Date();
      if (values[0].toUpperCase() !== 'SPOT') {
        let obj = _this.commonService.splitDateAndType(values[0]);
        if (obj.type === 'week') {
          date.setDate(date.getDate() + (7 * obj.count));
        } else if (obj.type === 'month') {
          date.setMonth(new Date().getMonth() + obj.count);
          date.setFullYear(new Date().getFullYear());
        } else if (obj.type === 'year') {
          date.setFullYear(new Date().getFullYear() + obj.count);
          date.setMonth(new Date().getMonth());
        }
      }
      return date;
    };
    let action = new Action();
    action.addFieldToUpdate('valueExpiry', undefined, exipryFunc);
    let columns: Map<string, Tcolumn> = new Map();
    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
      columns.set('name', new Tcolumn('name', 'Price Index Name', 'LB', 1, false, undefined, false, '', 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '5%'}}));
    columns.set('termStructure', new Tcolumn('termStructure', 'Term Structure', 'L', 2, true, this.masterCommonService.getListFromArray(this.priceIndexLoadData[giCode]), true, '', 'B', [Validators.required, Validators.maxLength(15)], undefined, 1, undefined, undefined, undefined, action));
    columns.set('valueExpiry', new Tcolumn('valueExpiry', 'Value Expiry', 'D', 3, true, undefined, true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
      columns.set('status', new Tcolumn('status', 'Status', 'B', 4, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));
      let grid = new Grid(columns, true, true, true, true, 'termStructure', '/api/fxirperiodcalendar/v1/getbyfxindexuuid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&fxIndexUuid=', '', tabLabel, 'name', '', undefined, undefined, false);
    grid.importUrl = environment.base_url + '/api/fxirperiodcalendar/v1/importfxirperiodcalendar';
    grid.setSaveUrl(environment.base_url + '/api/fxirperiodcalendar/v1/save');
    grid.setUpdateUrl(environment.base_url + '/api/fxirperiodcalendar/v1/update');
    grid.hrefUrl = environment.base_url + '/api/fxirperiodcalendar/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
    let tab = new Tab(grid.getTitle(), grid, 'name');
      tab.idFieldValueName = 'fxIndexUuid';
    return tab;
  }

    //Physical
  public getPhysicalColumns(){
      let locationFunction = function (value: any[]) {
          return value[0];
    };
      let locationType: Action = new Action(environment.base_url + '/api/location/v1/getallbylocationtype?tenantId=' + this.commonService.getFromStorage('tenantId') + '&locationType=', 'name', 'name');
      locationType.setCovertToListRequired(true);
      locationType.addFieldToUpdate("location", undefined, locationFunction, ['L'], undefined, true, 'listvalues');

    this.physicalcolumns = new Map();
    this.physicalcolumns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.physicalcolumns.set('code', new Tcolumn('code', 'Index Code', 'T', 1, false, undefined, true,new defaultValue('',undefined,['-','_'],undefined,false,true,false,undefined,undefined,true), 'B', [Validators.required, Validators.maxLength(8)], undefined, undefined, undefined, undefined, [], undefined, undefined, true));
    this.physicalcolumns.set('name', new Tcolumn('name', 'Index Name', 'T', 2, true, undefined, true, '', 'B', [Validators.required, Validators.maxLength(100)], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '7%'}}, true));
    this.physicalcolumns.set('description', new Tcolumn('description', 'Index Description', 'T', 3, true, undefined, true, '', 'B', [Validators.maxLength(200)], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '7%'}}));
    this.physicalcolumns.set('indexType', new Tcolumn('indexType', 'Type', 'L', 4, true, this.masterCommonService.getListFromArray(this.priceIndexLoadData['physical_index_type_ind']), true, undefined, 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '10%'}}));
    this.physicalcolumns.set('commodity', new Tcolumn('commodity', 'Commodity', 'L', 5, true, this.masterCommonService.getListFromArray(this.priceIndexLoadData['commodity']), true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '12%'}}));
      this.physicalcolumns.set('uom', new Tcolumn('uom', 'UOM', 'L', 6, true, this.masterCommonService.getListFromArray(this.priceIndexLoadData['unitofmeasurement']), true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '12%'}}));
    this.physicalcolumns.set('currency', new Tcolumn('currency', 'Currency', 'L', 7, true, this.commonService.getCurrencyList(), true, undefined, 'B', [Validators.required]));
    this.physicalcolumns.set('tradingMonth', new Tcolumn('tradingMonth', 'Trading Month', 'SL', 9, true, undefined, true, new defaultValue(1, '', []), 'B', [Validators.required, Validators.maxLength(2), Validators.min(1), Validators.max(36)], undefined, 1, 1, 36, undefined, undefined, {style: {width: '8%'}}));
      this.physicalcolumns.set('exchangeName', new Tcolumn('exchangeName', 'Exchange Name', 'T', 11, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '7%'}}, true));
    this.physicalcolumns.set('rollingMonth', new Tcolumn('rollingMonth', 'Rolling Month', 'B', 12, true, this.masterCommonService.getYesNo(), true, false, 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '7%'}}));
    this.physicalcolumns.set('locationType', new Tcolumn('locationType', 'Location Type', 'L', 13, true, this.masterCommonService.getListFromArray(this.priceIndexLoadData['location_type_ind']), true, '', 'B', undefined, undefined, 1, undefined, undefined, undefined, locationType, {style: {width: '7%'}}, true));
    this.physicalcolumns.set('location', new Tcolumn('location', 'Location', 'L', 14, true,[new KeyValue('Select','')], true, '', 'B', undefined, undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '7%'}}, true));
    this.physicalcolumns.set('status', new Tcolumn('status', 'Status', 'B', 15, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '20%'}}));
  }

    //Fx
  public getFxColumns(){
    this.fxcolumns = new Map();
    this.fxcolumns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.fxcolumns.set('code', new Tcolumn('code', 'Index Code', 'T', 1, false, undefined, true,new defaultValue('',undefined,['-','_'],undefined,false,true,false,undefined,undefined,true), 'B', [Validators.required, Validators.maxLength(8)], undefined, undefined, undefined, undefined, [], undefined, undefined, true));
    this.fxcolumns.set('name', new Tcolumn('name', 'Index Name', 'T', 1, true, undefined, true, '', 'B', [Validators.required, Validators.maxLength(100)], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '7%'}}, true));
      this.fxcolumns.set('description', new Tcolumn('description', 'Index Description', 'T', 2, true, undefined, true, '', 'B', [Validators.maxLength(200)], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '7%'}}));
      this.fxcolumns.set('indexType', new Tcolumn('indexType', 'Type', 'L', 3, true, this.masterCommonService.getListFromArray(this.priceIndexLoadData['fx_index_type_ind']), true, undefined, 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '10%'}}));
      this.fxcolumns.set('currencyPair', new Tcolumn('currencyPair', 'FX Pair', 'L', 4, true, this.masterCommonService.getListFromArray(this.priceIndexLoadData['fx_pair_trade_ind']), true, undefined, 'B', [Validators.required]));
      this.fxcolumns.set('exchangeName', new Tcolumn('exchangeName', 'Exchange Name', 'T', 5, true, undefined, true, '', 'B', [Validators.required, Validators.maxLength(100)], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '7%'}}, true));
      this.fxcolumns.set('termStructure', new Tcolumn('termStructure', 'Term Structure', 'SL', 6, true, undefined, true, new defaultValue(1, '', []), 'B', [Validators.required, Validators.maxLength(2), Validators.min(1), Validators.max(36)], undefined, 1, 1, 36, undefined, undefined, {style: {width: '8%'}}));
      this.fxcolumns.set('status', new Tcolumn('status', 'Status', 'B', 7, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '20%'}}));
  }

    //Future
  public getFutureColumns(){
    this.futurecolumns = new Map();
    this.futurecolumns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.futurecolumns.set('code', new Tcolumn('code', 'Index Code', 'T', 1, false, undefined, true,new defaultValue('',undefined,['-','_'],undefined,false,true,false,undefined,undefined,true), 'B', [Validators.required, Validators.maxLength(8)], undefined, undefined, undefined, undefined, [], undefined, undefined, true));
    this.futurecolumns.set('name', new Tcolumn('name', 'Index Name', 'T', 2, true, undefined, true, '', 'B', [Validators.required, Validators.maxLength(100)], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '7%'}}, true));
    this.futurecolumns.set('description', new Tcolumn('description', 'Index Description', 'T', 3, true, undefined, true, '', 'B', [Validators.maxLength(200)], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '7%'}}));
    this.futurecolumns.set('indexType', new Tcolumn('indexType', 'Type', 'L', 4, true, this.masterCommonService.getListFromArray(this.priceIndexLoadData['future_index_type_ind']), true, undefined, 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '10%'}}));
    this.futurecolumns.set('commodity', new Tcolumn('commodity', 'Commodity', 'L', 5, true, this.masterCommonService.getListFromArray(this.priceIndexLoadData['commodity']), true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '12%'}}));
      this.futurecolumns.set('uom', new Tcolumn('uom', 'UOM', 'L', 6, true, this.masterCommonService.getListFromArray(this.priceIndexLoadData['unitofmeasurement']), true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '12%'}}));
    this.futurecolumns.set('lotSize', new Tcolumn('lotSize', 'Lot Size', 'N', 7, true, undefined, true, new defaultValue('', '', ['.']), 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '7%'}}));
    this.futurecolumns.set('exchangeName', new Tcolumn('exchangeName', 'Exchange Name', 'T', 8, true, undefined, true, '', 'B', [Validators.required, Validators.maxLength(100)], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '7%'}}, true));
    this.futurecolumns.set('location', new Tcolumn('location', 'Location', 'L', 9, true, this.masterCommonService.getListFromArray(this.priceIndexLoadData['location']), true, '', 'B', undefined, undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '7%'}}, true));
    this.futurecolumns.set('tradingMonth', new Tcolumn('tradingMonth', 'Trading Month', 'SL', 10, true, undefined, true, new defaultValue(1, '', []), 'B', [Validators.required, Validators.maxLength(2), Validators.min(1), Validators.max(36)], undefined, 1, 1, 36, undefined, undefined, {style: {width: '8%'}}));
    this.futurecolumns.set('currency', new Tcolumn('currency', 'Currency', 'L', 11, true, this.commonService.getCurrencyList(), true, undefined, 'B', [Validators.required]));
    this.futurecolumns.set('rollingMonth', new Tcolumn('rollingMonth', 'Rolling Month', 'B', 12, true, this.masterCommonService.getYesNo(), true, false, 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '7%'}}));
    this.futurecolumns.set('status', new Tcolumn('status', 'Status', 'B', 13, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {style: {width: '20%'}}));
  }


    getshipmentTerminalMonthList(tradingmonth: any) {
    let list: any[] = [];
    list.push(new KeyValue('Select', ''));
    let currentMonthNumber = new Date().getMonth();
    let yearcode = new Date().getFullYear() -1;
    for (let i = 1; i <= tradingmonth; i++) {
      list.push(new KeyValue(this.commonService.getMonthsList()[currentMonthNumber] + '-' + yearcode, this.commonService.getMonthsList()[currentMonthNumber] + '-' + yearcode));
      currentMonthNumber++;
      if (currentMonthNumber > 11) {
        currentMonthNumber = 0;
        yearcode++;
      }
    }
    return list;
  }

  getListFromMonthAndYear(list, currentMonthNumber, yearcode, tradingmonth) {
    for (let i = 1; i <= tradingmonth; i++) {
      list.push(new KeyValue(this.commonService.getMonthsList()[currentMonthNumber] + '-' + yearcode, this.commonService.getMonthsList()[currentMonthNumber] + '-' + yearcode));
      currentMonthNumber++;
      if (currentMonthNumber > 11) {
        currentMonthNumber = 0;
        yearcode++;
      }
    }
    return list;
  }

  getListFromMonthAndYearAndGI(list, currentMonthNumber, yearcode, tradingmonth,indexType) {
    let type = indexType.toLowerCase().trim();
    type = type.replace(/ /g,"_");
    let gilist: any[] = this.priceIndexLoadData[type + '_period_ind'];
    let lastitem = gilist[gilist.length -1]
    gilist.splice(gilist.length - 1,1);
    gilist.splice(0,0,lastitem);
     gilist.forEach(function(item:any) {
        list.push(new KeyValue(item,item));
      })
    for (let i = 1; i <= tradingmonth; i++) {
      list.push(new KeyValue(this.commonService.getMonthsList()[currentMonthNumber] + '-' + yearcode, this.commonService.getMonthsList()[currentMonthNumber] + '-' + yearcode));
      currentMonthNumber++;
      if (currentMonthNumber > 11) {
        currentMonthNumber = 0;
        yearcode++;
      }
    }
    return list;
  }

    getshipmentTerminalMonthListforfuture(tradingmonth: any, indexType: any, isSerial: boolean) {
    let list: any[] = [];
    list.push(new KeyValue('Select', ''));
    let currentMonthNumber = new Date().getMonth();
    let yearcode = new Date().getFullYear();
    if (indexType.toLowerCase() === 'futures') {
      list = this.getListFromMonthAndYear(list, currentMonthNumber, yearcode-1, tradingmonth+12);
    } else if (indexType.toLowerCase() === 'options') {
      if (isSerial) {
        list = this.getListFromMonthAndYear(list, currentMonthNumber, yearcode-1, tradingmonth+12);
      } else {
        list = this.getListFromMonthAndYear(list, currentMonthNumber, yearcode-1, 24);
      }
    } else {
      list = this.getListFromMonthAndYearAndGI(list, currentMonthNumber, yearcode-1, tradingmonth+12,indexType);
    }
    return list;
  }

  /**
   * this function is to do some work on click on unique key in the grid.
   * working of this function here compare the index type on currently selected row and create tabs for that
   * @param value
   */
  uniqueClick(value: any,tabName: string) {
    this.updateTabs(value['rowData'],tabName);
  }

  updateTabs(rowData: any,tabName: string) {
    if(tabName === 'physical'){
        let pctab: Tab = this.getPeriodCalendarPhysicalBasisFP(this.getshipmentTerminalMonthList(rowData['tradingMonth']+12), rowData['indexType']);
      this.tabs = [];
      this.tabs.push(pctab);
      this.physicalgrid.setTabs(this.tabs);
    } else if(tabName === 'future') {
      let validator: any[] = [];
      if (rowData['indexType'].toUpperCase() === 'OPTIONS') {
        validator = [Validators.required];
      }
        let pctab = this.getPeriodCalendarFutureOption(validator, this.getshipmentTerminalMonthListforfuture(rowData['tradingMonth'], rowData['indexType'], rowData['serial']), rowData['indexType']);
      this.futureTab = [];
      this.futureTab.push(pctab);
      this.futuregrid.setTabs(this.futureTab);
    } else {
      this.fxTab = [];
      if (rowData['indexType'].toUpperCase() === 'FX') {
       let pctab = this.getPeriodCalendarFXIR('fx_tenor_period_ind');
        this.fxTab.push(pctab);
      } else if (rowData['indexType'].toUpperCase() === 'IR') {
       let pctab = this.getPeriodCalendarFXIR('fx_tenor_period_ind');
        this.fxTab.push(pctab);
      }
      this.fxgrid.setTabs(this.fxTab);
    }
  }

  onRowSave(value: any,tabName:string) {
    if (value['data']) {
      value = value['data'];
    }
    if (value['label'] === 'Price Index') {
      let data = value['result'];
      if (data['newData']) {
        data = data['newData'];
      }
      this.updateTabs(data,tabName);
      this.showMainScreen();
    }
  }

  showMainScreen() {
    this.hideChild = false;
  }

  showTopSection() {
    this.hideChild = true;
  }

  updateDataSize(value: any) {
    this.size = value;
  }

  onTabChange() {
    this.search = "";
  }

  getFormattedDateMonthYear(date, format = environment.dateFormat) {
    if (date !== undefined && date !== null && date.toString() !== 'Invalid Date') {
      return this.datePipe.transform(date, format);
    }
    return '';
  }
}
