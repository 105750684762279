<!--<ctrm-grid (onClickUniqueKey)="onChange($event)" (onRowDataSave)="onRowSave($event)" [grid]="grid" (heirarchyEmitter)="openHeirarcy($event)"></ctrm-grid>-->
<app-ctrm-grid-with-tabs (heirarchyEmitter)="openHeirarcy($event)"
                         (onClickUniqueKey)="onChange($event)"
                         [refreshTab]="refreshTab"
                         (onTabRefreshComplete)="onTabRefreshed()"
                         (onRowDataSave)="onRowSave($event)"
                         [breadcrumbMapKey]="commonService.MASTER_MAP_KEY"
                         [currentRoute]="'/commodity'"
                         *ngIf="loadListData != undefined && loadListData.length != 0"
                         [grid]="grid"
                         [showStandardFilter]="true">
</app-ctrm-grid-with-tabs>
<heirarchy-view (onClose)="closeBtn()"
                *ngIf="modalStateHierarchy"
                [entityName]="getEntityName()"
                [nameField]="'name'"
                [primaryKey]="'name'"
                [rowData]="selectedRowData"
                [titlePrefix]="'Commodity Hierarchy'"
                [visible]="modalStateHierarchy">
</heirarchy-view>
