<div style="display: flex; flex-direction: column;width: 100%;height: 100%;">
    <!--  <div class="sections fonts" style="padding-bottom: 1%; margin-top: -5px">-->
    <!--    <div>-->
    <!--      <p class="secHeading" style="margin-left: 2%; margin-bottom:2px; padding-top: 8px;">Dashboard</p>-->
    <!--    </div>-->
    <!--    <div *ngIf="visible" style="margin-left: 2%">-->
    <!--      <app-dash-card (childs)="changevisibility($event)" (title)="forTitle($event)" [card]="card"></app-dash-card>-->
    <!--    </div>-->
    <!--    <div *ngIf="!visible" style="margin-left: 2%">-->
    <!--      <app-dash-sub-card (onBackPress)=goback($event) [childs]="getBreadCrumbChilds(title)" [subCards]="subcards"-->
    <!--                         [title]="title"></app-dash-sub-card>-->
    <!--    </div>-->
    <!--  </div>-->
    <!--  <div class="brk"></div>-->
    <div class="dashMainDiv">


        <div class="dashLeftDiv">

            <div style="display: flex; width: 100%">
                <div class="dashboardHeadingWrapper">
                    <p class="dashHeading">Dashboard</p>
                    <p class="refreshDate" *ngIf="lastRunDate != undefined && lastRunDate != null && lastRunDate.length !== 0">
                        Last Refresh : {{lastRunDate}}
                    </p>
                    <div>
                        <ui-switch (change)="onToggleChange()"
                                   [ngClass]="isLive?'liveBtnLive':'liveBtn'"
                                   [checkedLabel]="'EOD'" [uncheckedLabel]="'Live'" [checkedTextColor]="'#fff'"
                                   [color]="'#3D9F00'"
                                   [uncheckedTextColor]="'#fff'"
                                   [defaultBgColor]="'#FB2C00'"
                                   [size]="'small'">

                        </ui-switch>
                    </div>
                </div>


                <div style="display: flex; width: 50%; margin-left: 42%">
                    <button class="xclButton" (click)="openWidgetDialog()">Customize</button>
                </div>

            </div>


            <div class="widgetsWrapper style-4">
                <app-widgets [isLive]="isLive" [widgetJson]="widgetOption" (closeWidget)="closeWidget($event)" (tableDialog)="onToggleDataView($event)"></app-widgets>
            </div>
        </div>


        <div class="dashRightDiv">
            <div class="notificationPanel">
                <div>
                    <!--NOTIFICATION PANEL-->
                    <div class="notificationContainer style-4">
                        <!--  Notifications-->
                        <div *ngIf="!notificationLoadingFlag">
                            <notification-and-alert (onActionClick)="onActionClick($event)" [showHeader]="false"
                                                    [notifications]="allNotifications"></notification-and-alert>
                        </div>
                        <div class="notifLoadingWrapper" *ngIf="notificationLoadingFlag">
                            <p-progressSpinner></p-progressSpinner>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>


</div>


<!--widget Customization-->
<p-dialog [(visible)]="display" *ngIf="widgetDialog"
          [closeOnEscape]="true"
          [resizable]="false"
          [draggable]="false"
          (onHide)="closeWidgetDialog()"
          [maximizable]="false" [modal]="true" [showHeader]="true" [closable]="false"
          [style]="{zIndex:1000,width: '580px',height: 'auto',minHeight:'65%',maxHeight:'65%', padding:'0px', background:'#ffffff'}">

    <p-header>
        <div class="widgetFormTop">
            <p class="formTitle">Widget Customization</p>
            <i (click)="closeWidgetDialog()" class="fas fa-times crossIcon"></i>
        </div>
    </p-header>

    <div class="dialogWrapper">
        <p class="widgetDesc">Choose Dashboard widgets as per your needs.</p>

        <!--    <div *ngFor="let item of widgetMenuJSON" class="widgetWrap">-->
        <!--      <widget-accordian [optionItem]="item" (onClickWidgetOption)="onWidgetOptionClick($event)"></widget-accordian>-->
        <!--    </div>-->

      <div class="ui-g">
        <div (click)="onWidgetOptionClick(option)" *ngFor="let option of widgetOption; index as i"
             [ngClass]="option['selected']?'optionWrapperSelected':'optionWrapper'">
          <div class="itemIconWrapper">
            <i [ngClass]="option['selected']?'far fa-check-circle circleTickIconClass':'far fa-circle circleIconClass'"></i>
            <!--        <i class="far fa-check-circle"></i>-->
          </div>
          <div class="optionBody">
            <i class="fas fa-chart-pie" [ngClass]="option['selected']?'iconSelected':'iconClass'"></i>
            <p [ngClass]="option['selected']?'optionTitleSelected':'optionTitle'">{{option['name']}}</p>
          </div>
        </div>
      </div>


        <div class="bottomMargin">
        </div>
    </div>
    <p-footer>
        <div class="xwrMain">
            <p class="orUse">or use</p>

            <div class="xwrWrapper">
                <p class="xwrClass">Xceler Widget Recommendation</p>
                <ui-switch
                        [checkedLabel]=""
                        [checkedTextColor]="'#fff'"
                        [color]="'#3D9F00'"
                        [defaultBgColor]="'#d8d8d8'"
                        [size]="'small'"></ui-switch>
            </div>
        </div>
        <button (click)="closeWidgetDialog()" class="cancelBtn">Cancel</button>
        <button (click)="saveJson()" class="planScreenBtns">Save</button>
    </p-footer>
</p-dialog>


<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>
