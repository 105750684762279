import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import {CommonService} from '../../../services/common.service';

@Component({
  selector: 'app-planning-info',
  templateUrl: './planning-info.component.html',
  styleUrls: ['./planning-info.component.css']
})
export class PlanningInfoComponent implements OnInit, OnChanges,AfterViewInit {
  @Input() value: any[] = [];
  @Input() columns: any[] = [];
  @Input() icon: boolean = false;
  @Input() expandRow: any;
  @Output() onKeyExpand =  new EventEmitter();
  @Input() showChkBox: boolean = true;
  @Input() selectedRows: any[] = [];
  @Output() onSelectedRows = new EventEmitter();
  @Output() onUnselectedRows = new EventEmitter();
  @Output() onAttachmentClick = new EventEmitter();
  @Output() onClickUniqueKey = new EventEmitter();
  @Output() onClickClickable = new EventEmitter();
  @Input() clickableFields: any[] = [];
  @Input() columnMinWidth: string = '150px';
  @Input() primaryKeyClickable: boolean = true;
  incoterms: any[] = [];
  @Input() showPlaceHolder: boolean = true;
  @Input() noDataTemplate: TemplateRef<any>;
  @Input() throwUnselectSeperate: boolean = false;
  @Input() showDeleteIcon: boolean = false;
  @Input() deleteIcon: string = 'far fa-times-circle';
  @Output() onClickDelete = new EventEmitter();
  @Input() fieldsWithCustomView:any[] =[];
  @Input() customViewTemplated:TemplateRef<any>[] =[];
  @Input() loadingData:boolean = false;

  constructor(private changeDetectorRef:ChangeDetectorRef,public commonService: CommonService) {
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  //To get the Class of Buy (Red Color Pill) or Sell (Green Color Pill)
  getBuySellClass(rowDatum: any) {
    return rowDatum ? 'buyClass' : 'sellClass';
  }

  //To get the value of Trade Type since the field is Boolean
  getTradeTypeValue(rowDatum: any) {
    return rowDatum === 'BUY' ? 'Buy' : 'Sell';
  }

  getClassForHeading(index) {
    return index > 0 ? 'headerNormal' : 'noPipe';
  }


  throwUniqueKey(rowData, field) {
    this.onClickUniqueKey.emit(rowData);
    // this.onClickClickable.emit(field);

  }

  // getValue(rowDatum) {
  //   const elementsIndex = this.incoterms.findIndex(element => element.incotermCode == rowDatum);
  //   if (this.incoterms[elementsIndex]) {
  //     return this.incoterms[elementsIndex]['incotermName'];
  //   }
  // }


  ngOnChanges(changes: SimpleChanges): void {
      if(changes['expandRow'] && changes['expandRow'].currentValue !== undefined && changes['expandRow'].currentValue !== null) {
        this.onKeyExpand.emit(this.expandRow)
      }
  }

  onSelectedChange(row,unselect = false) {
    if(!unselect || !this.throwUnselectSeperate && unselect) {
      this.onSelectedRows.emit(this.selectedRows);
    }
    if(unselect) {
      this.onUnselectedRows.emit(row.data);
    }
  }

  getStyleIfPossible(col: any) {
    let styleOBJ = {};
    if (col['style']) {
      styleOBJ = col['style'];
      return styleOBJ;
    } else {
      return {};
    }

  }

  onClickAttachment(rowData) {
    this.onAttachmentClick.emit(rowData);
  }

  isNotLast(index: number) {
    return (index < Object.keys(this.columns).length - 1);
  }

  throwClickable(rowData, fieldName) {
    if (this.clickableFields.includes(fieldName)) {
      this.onClickClickable.emit({rowData: rowData, fieldName: fieldName});
    }
  }

  onRowDelete(selectedPlans: any) {
    this.onClickDelete.emit({rowData: selectedPlans});
  }
}
