import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input, OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {environment} from "../../../environments/environment";
import {MessageService} from "primeng";
import {CommonService} from "../../services/common.service";

@Component({
  selector: 'ctrm-uploadfile',
  templateUrl: './ctrm-uploadfile.component.html',
  styleUrls: ['./ctrm-uploadfile.component.css']
})
export class CtrmUploadfileComponent implements OnInit,OnChanges,AfterViewInit {
  error: string;
  dragAreaClass: string;
  @Input() display:boolean;
  @Output() onClose = new EventEmitter();
  @Output() attachmentDocument = new EventEmitter();
  fileName: string = 'No File Chosen';
  fileToUpload: any;
  fileSize: any;
  fileType:any;
  refreshAddNewRow: boolean = false;
  refreshFormGroup: boolean = false;
  files: File;
  onFileChange(event: any) {
    let files: FileList = event.target.files;
    this.saveFiles(files);
  }
  constructor(public commonService: CommonService,private messageService: MessageService,private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.dragAreaClass = "dragarea";
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fileName']) {
      if (changes['fileName'].currentValue === undefined || changes['fileName'].currentValue === null || changes['fileName'].currentValue.toString().length == 0) {
        this.fileName = 'No File Chosen';
      }
      this.fileName = this.refractorFileName();
    }
  }

  refractorFileName(filename: string = this.fileName, len = 20) {
    if (filename.length > len) {
      filename = filename.substring(0, len) + '...';
    }
    return filename;
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      let files: FileList = event.dataTransfer.files;
      this.saveFiles(files);
    }
  }

  saveFiles(filelist: FileList) {
    if (filelist.length > 1) this.error = "Only one file at time allow";
    else {
      let _this = this;
      Object.values(filelist).forEach((file: File) => {
        _this.files = file;
      });
      this.onFileSelected(this.files);
    }
  }

  onFileSelected(files: File) {
    let _this = this;
    this.fileToUpload = '';
    this.fileName = '';
    this.fileSize = '';
    this.fileType= '';
    let reader = new FileReader();
    if (files !== undefined) {
      reader.readAsDataURL(files);
      reader.onload = () => {
        let str: any = reader.result;
        let split: any[] = str.split(',');
        _this.fileToUpload = split[1];
        _this.fileSize = files.size;
        _this.fileName = files.name;
        _this.fileType = files.type;
      };
    }
  }

  private getPayload() {
    return {
      documentSize: this.fileSize,
      document: this.fileToUpload,
      attachmentName: this.fileName,
      attachmentFileType:this.fileType,
      attachmentFileExtension: this.fileName.split('.').pop(),
      createdBy:this.commonService.getFromStorage('userName'),
      createdTimestamp:this.commonService.convertDatetoUTC(new Date())
    };
  }

  openUploadFile() {
    if(this.fileToUpload !== undefined && this.fileToUpload !== null && this.fileToUpload !== '') {
      this.attachmentDocument.emit(this.getPayload());
        this.fileName = 'No File Chosen';
        this.refreshFormGroup = true;
        this.refreshAddNewRow = true;
        this.fileToUpload = '';
        this.onCancel();
    } else {
      this.showToast('No File found to upload.');
    }
  }

  showToast(msg, severity: string = 'success') {
    this.messageService.add({
      severity: severity,
      summary: msg
    });
  }

  onCancel() {
    this.onClose.emit();
  }
}
