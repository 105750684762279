import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Grid} from "../../grid/grid.model";
import {environment} from "../../../environments/environment";
import {Tcolumn} from "../../grid/tcolumn.model";
import {MasterService} from "../services/MasterService";
import {CommonService} from "../../services/common.service";
import {MessageService} from "primeng";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {defaultValue} from "../../grid/defaultValue.model";
import {HttpErrorResponse} from "@angular/common/http";
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-internal-packaging',
  templateUrl: './internal-packaging.component.html',
  styleUrls: ['./internal-packaging.component.css']
})
export class InternalPackagingComponent implements OnInit {
  columns: Map<string, Tcolumn>;
  grid: Grid;
  refreshGrid: boolean = false;
  @ViewChild('physical') eod: ElementRef;
  //NODATA BOX
  noDataBoxLeft: any;
  @Input() showFetching: boolean = false;
  isError: boolean = false;
  loadMessage: string = 'Loading ..';
  @Input() noDataLabel: string = 'No Data Available';
  gridColumns: Tcolumn[] = [];
  //Side bar
  internalPackDisplay: boolean = false;
  height: string = '';
  includedCharacters: any[] = ['_', '/', ' '];
  name: string = '';
  capacity: string = '';
  capacityUom: string = '';
  capacityUomList: any[] = [];
  status: any;
  values: any[] = [];
  //subTable
  refreshAddNewRow: boolean = true;
  newRows: any[] = [];
  refreshFormGroup: boolean = false;
  externalCols: Tcolumn[] = [];
  externalValue: any[] = [];
  loadListData: any[];
  internalForm: FormGroup;
  createdBy: any;
  createdDate: any;
  updatedBy: any;
  updatedDate: any;
  datePipe: DatePipe;
  infoDisplay: boolean = false;
  isUpdating: boolean = false;

    constructor(public commonService: CommonService, private masterCommonService: MasterService, private messageService: MessageService,private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
      this.commonService.getLoadData('internalpackage', ['config', 'config'], ['externalpackage', 'unitofmeasurement']).subscribe((next: any) => {
          this.loadListData = next;
          this.getColumns();
          this.grid = new Grid(this.columns, true, true, true, true, 'name', '/api/internalpackage/v1/getallinternaldata?tenantId=' + this.commonService.getFromStorage('tenantId'), '', 'Internal Package', undefined, undefined);
          this.gridColumns = this.masterCommonService.getGridField(this.grid);
          this.grid.iconName = 'priceIndex';
          this.grid.importUrl =  environment.base_url + '/api/internalpackage/v1/import';
          this.grid.openDefaultInputForm = false;
          this.grid.setGridConfiguration(true, true, true, false);
          this.grid.setToolbarConfig(true,true,true,false,true,false,false,false,false,false);
          this.capacityUomList = this.masterCommonService.getListFromArray(this.loadListData['unitofmeasurement']);
          this.initInternalForm();
      });
  }

  getColumns() {
    this.columns = new Map();
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('name', new Tcolumn('name', 'Internal Package Name', 'T', 2, true, undefined, true, new defaultValue('', '', ['-', '_', '/',' '], undefined, false, true, false, undefined, undefined, true), 'B', [Validators.required, Validators.maxLength(100)], undefined, undefined, undefined, undefined, [], undefined, undefined, true));
    this.columns.set('capacity', new Tcolumn('capacity', 'Capacity', 'T', 3, true, undefined, true, '', 'B', []));
    this.columns.set('capacityUom', new Tcolumn('capacityUom', 'Capacity Uom', 'T', 4, true, undefined, true, '', 'B', []));
    this.columns.set('status', new Tcolumn('status', 'Status', 'B', 5, true,  this.masterCommonService.getActiveInactive(), true, '', 'B', []));
    this.columns.set('applicableCommodity', new Tcolumn('applicableCommodity', 'Applicable Commodity', 'T', 6, true, undefined, false, '', 'H', []));
    this.getExternalPackagingColumns();
  }

  getExternalPackagingColumns() {
      this.externalCols.push(new Tcolumn('externalPackageName', 'External Packaging Name', 'L', 0, true, this.masterCommonService.getListFromArray(this.loadListData['externalpackage']), true));
      this.externalCols.push(new Tcolumn('internalPackagingUnit','Internal Packaging Unit','N',1,true,undefined,true));
  }

  //On click of the Add button  Sidebar opens
  onClickAdd() {
    this.name = '';
    this.capacity = '';
    this.capacityUom = '';
    this.status = true;
    this.refreshGrid = false;
    this.values = [];
    this.externalValue = [{name: '', internalPackagingUnit: ''}];
    this.internalPackDisplay = true;
    this.infoDisplay = false;
    this.isUpdating = false;
  }

  //close the SideBar
  close() {
    this.internalPackDisplay = false;
    this.initInternalForm();
  }

  //on click of Save Button to save the internal Packaging
  save(){
      let url = '';
    let savepayload = {
      name: this.internalForm.controls['name'].value,
      status: this.internalForm.controls['status'].value,
      capacity: this.internalForm.controls['capacity'].value,
      capacityUom: this.internalForm.controls['capacityUom'].value,
      tenantId: this.commonService.getFromStorage('tenantId'),
      internalExternalPackageList: this.externalValue,
      updatedBy: this.commonService.getFromStorage('userName')
    };
    if (!this.isUpdating) {
      url = environment.base_url + '/api/internalpackage/v1/saveInternalPackaging';
      savepayload['createdBy'] = this.commonService.getFromStorage('userName');
    } else {
      url = environment.base_url + '/api/internalpackage/v1/updateInternalPackaging';
    }
      this.commonService.post(url, savepayload).subscribe(next => {
      this.internalPackDisplay = false;
      this.refreshGrid = true;
      this.externalValue = [];
      this.showToast('Row Successfully' + (this.isUpdating?' Updated.': ' Saved.'));
    }, (error: HttpErrorResponse) => {
      this.messageService.add({severity: 'error', summary: this.commonService.getHttpErrorMessage(error,'internal package')});
    });
  }

  openInUpdateMode(value: any) {
    let rowData = value['rowData'];
    this.onClickAdd();
    this.isUpdating = true;
    this.infoDisplay = true;
      this.commonService.getJSONByURL(environment.base_url + '/api/internalpackage/v1/loadInternalData?tenantId=' + this.commonService.getFromStorage('tenantId') + '&name=' + rowData['name']).subscribe((next: any) => {
          this.addContentBox(next['name'], next['status'], next['capacity'], next['capacityUom'],next['createdTimestamp'],next['updatedTimestamp'],next['internalExternalPackageList']);
          this.updateUserInfo(next['createdBy'], next['createdTimestamp'], 'create');
          this.updateUserInfo(next['updatedBy'], next['updatedTimestamp'], 'update');
          this.internalForm.controls['name'].disable();
    });
  }

  onDuplicate(value: any) {
    let rowData = value;
    this.onClickAdd();
    this.isUpdating = false;
    this.infoDisplay = true;
      this.commonService.getJSONByURL(environment.base_url + '/api/internalpackage/v1/loadInternalData?tenantId=' + this.commonService.getFromStorage('tenantId') + '&name=' + rowData['name']).subscribe((next: any) => {
        this.addContentBox('', next['status'], next['capacity'], next['capacityUom'],next['createdTimestamp'],next['updatedTimestamp'],next['internalExternalPackageList']);
        this.updateUserInfo(next['createdBy'],next['createdTimestamp'], 'create');
        this.updateUserInfo(next['updatedBy'], next['updatedTimestamp'], 'update');
    });
  }

    addContentBox(name, status, capacity, capacityUom,createdTimestamp,updatedTimestamp, next: any[] = []) {
    this.name = name;
    this.status = status;
    this.capacity = capacity;
    this.capacityUom = capacityUom;
    this.createdDate = createdTimestamp;
    this.updatedDate = updatedTimestamp;
    this.externalValue = next;
    let _this = this;
      let metaData = [];
      metaData = this.masterCommonService.getGridField(_this.grid);
      let obj: {
        name: any,
        status: any,
        capacity: any,
        capacityUom: any,
        metadata: Tcolumn[],
        values: any[],
        expanded: boolean,
        newRows: any[],
        refreshAddNewRow: boolean,
        refreshFormGroup: boolean
      } = {
        name: name,
        status: status,
        capacity: capacity,
        capacityUom: capacityUom,
        metadata: metaData,
        values: next,
        expanded: true,
        newRows: next,
        refreshAddNewRow: true,
        refreshFormGroup: false
      };

  }

  //SubTable in the sideBar
  onNewRowAddComplete() {
    this.refreshAddNewRow = false;
    this.newRows = [];
  }

  onRefreshFromGroupComplete() {
    this.refreshFormGroup = false;
  }

  showToast(msg, severity: string = 'info') {
    this.messageService.add({
      severity: severity,
      summary: msg
    });
  }

  //update
  onSave(value: any) {
    if (value['mode'] === 'update') {
      this.save();
    }
  }

  //nodata box
  calculateLeft() {
    this.noDataBoxLeft = ((window['visualViewport'].width - 500) / 2) + 'px';
  }

  initInternalForm() {
    const internalObj = {};
    internalObj['name'] = ['', [Validators.required]];
    internalObj['capacity'] = ['', [Validators.required]];
    internalObj['capacityUom'] = ['', [Validators.required]];
    internalObj['status'] = ['', []];
    this.internalForm = this.formBuilder.group(internalObj);
  }


  nameValid() {
    if (this.internalForm.get('name').valid) {
      return true;
    }
  }
  capacityValid() {
    if (this.internalForm.get('capacity').valid) {
      return true;
    }
  }
  capacityUomValid() {
    if (this.internalForm.get('capacityUom').valid) {
      return true;
    }
  }

  updateUserInfo(id, date, mode = 'create') {
    if (id !== null) {
      this.commonService.getJSONByURL(environment.base_url_new + '/api-iam/api/usermanagementservice/v1/getbyuserid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&userId=' + id).subscribe((next: any[]) => {
        if (next !== undefined && next !== null) {

          if (mode === 'create') {
            this.createdBy = next['value'];
            this.createdDate = this.commonService.getFormattedDate(date, environment.full_date_format);
          } else {
            this.updatedBy = next['value'];
            this.updatedDate = this.commonService.getFormattedDate(date, environment.full_date_format);
          }
        }
      });
    }
  }

}
