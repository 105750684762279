export class Action {
  private _runAlways:boolean = false;
  constructor(
    private fetchUrl?: string,
    private keyField?: string,
    private valueField?: string,
    private fieldsToUpdate: any[] = [],
    private addDefault: any[] = [],
    private extra: any = {},
    private postObjGeneratorFunc: Function = null,
    private throwAtGridRefresh: boolean = false,
    private convertToListRequired: boolean = true,
    private _apiDecideFunction: Function = null
  ) {
  }

  public getFetchUrl(rowData:any = null) {
    if(this._apiDecideFunction !== null && this._apiDecideFunction !== undefined) {
       this.fetchUrl = this._apiDecideFunction(rowData);
    }
    return this.fetchUrl;
  }

  public setFetchUrl(fetchUrl: string = '') {
    this.fetchUrl = fetchUrl;
  }

  public getKeyField() {
    return this.keyField;
  }

  public getValueField() {
    return this.valueField;
  }


  get apiDecideFunction(): Function {
    return this._apiDecideFunction;
  }

  set apiDecideFunction(value: Function) {
    this._apiDecideFunction = value;
  }

  getValueFrom(url: string, key: string, value?: string, entityName?: string, entityKey?: string, criteria?: any, operation: string = 'equal') {
    let obj = {
      url: url,
      key: key,
      value: value,
      entityKey: entityKey,
      entityName: entityName,
      criteria: criteria,
      operation: operation
    };
    return obj;
  }

  /**
   *
   * @param fieldName
   * @param key here key can be a string or a KeyValue() Object
   * @param func
   * @param para
   * @param fetchUrl
   * @param throwAtGridRefresh
   * @param metaToUpdate
   * @param updateToDatabase
   * @param throwAtTabRefresh
   */
  public addFieldToUpdate(fieldName: string, key?: any, func?: Function, para?: any[], fetchUrl?: string, throwAtGridRefresh: boolean = false, metaToUpdate?: string, updateToDatabase: boolean = false, throwAtTabRefresh: boolean = false, extra?: any, getValueFrom?: any, postObjectFunction?: Function, postObjectFunctionPara?: any[],canRunParentFunction?:Function,canRunParentFunctionPara:any[] =[],updateChangeValue:Boolean = false) {
    var obj = {
      fieldName: fieldName,
      key: key,
      func: func,
      para: para,
      fetchUrl: fetchUrl,
      metaToUpdate: metaToUpdate,
      throw: throwAtGridRefresh,
      updateToDatabase: updateToDatabase,
      throwAtTabRefresh: throwAtTabRefresh,
      extra: extra,
      getValueFrom: getValueFrom,
      postObjectFunction: postObjectFunction,
      postObjectFunctionPara: postObjectFunctionPara,
      canRunParentFunction:canRunParentFunction,
      canRunParentFunctionPara:canRunParentFunctionPara,
      updateChangeValue:updateChangeValue
    };
    this.fieldsToUpdate.push(obj);
  }

  public getFieldsToUpdate() {
    return this.fieldsToUpdate;
  }

  public addDefaultExtra(item: any) {
    this.addDefault.push(item);
  }

  public getAddDefault() {
    return this.addDefault;
  }

  public getFromExtra(key: any) {
    return this.extra[key];
  }

  public setToExtra(key: any, value: any) {
    this.extra[key] = value;
  }

  public getPostObjGeneratorFunc() {
    return this.postObjGeneratorFunc;
  }

  public setPostObjGeneratorFuncs(func: Function) {
    this.postObjGeneratorFunc = func;
  }

  public getThrowAtGridRefresh() {
    return this.throwAtGridRefresh;
  }

  public setThrowAtGridRefresh(throwAtGridRefresh: boolean = true) {
    this.throwAtGridRefresh = throwAtGridRefresh;
  }

  public getCovertToListRequired() {
    return this.convertToListRequired;
  }

  public setCovertToListRequired(convertToListRequired: boolean = true) {
    this.convertToListRequired = convertToListRequired;
  }


  get runAlways(): boolean {
    return this._runAlways;
  }

  set runAlways(value: boolean) {
    this._runAlways = value;
  }
}
