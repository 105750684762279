import {MenuItem} from 'primeng/api';
import {ApprovalFunctionParams} from '../../../Components/approval-context-menu/model/approval-function.params';
import {Button} from '../../../grid/buttons.model';
import {tradeOptionsButtons} from '../../../services/common/entities';
import {environment} from '../../../../environments/environment';
import {HttpErrorResponse} from '@angular/common/http';

export const tradeApprovalFunctions: { [key: string]: Function } = {
    loadMenuItems: (param: ApprovalFunctionParams): MenuItem[] => {
        let items: MenuItem[] = [];
        let tradeStatus = param.rowData['tradeStatus'].toLowerCase();
        if (param.accessService.canAccessWithCurrentRoute('Action', 'sendForApproval')) {
            let sendToApprovalFunction = (value: any) => {
                let requiredArrayList: string[] = ['company', 'counterpartyCode', 'profitcenter', 'commodity', 'incoterm',
                    'quantity', 'quantityUom', 'quantityPeriodicity', 'periodStartDate', 'periodEndDate', 'priceType', 'tradePrice', 'tradeSettlementCurrency', 'paymentterm'];
                let priceType = param.rowData['priceType'].toLowerCase();
                if (priceType !== 'ptbf') {
                    requiredArrayList.push('tradePriceUom');
                } else {
                    requiredArrayList.push('tradePriceUom');
                    requiredArrayList.push('tradePriceCurrency');
                }
                let isAllAvailable: boolean = true;
                let notAvailableFields: string = '';
                requiredArrayList.forEach(function(fieldName) {
                    if (param.rowData[fieldName] === undefined || param.rowData[fieldName] === null || param.rowData[fieldName].length === 0) {
                        notAvailableFields += fieldName + '\n';
                        isAllAvailable = false;
                    }
                });
                if (isAllAvailable) {
                    let tenantId = param.commonService.getFromStorage('tenantId');
                    let userName = param.commonService.getFromStorage('userName');
                    param.commonService.getJSONByURL(environment.base_url + '/api/trade/v1/physical-trade-start-workflow?triggerEvent=' + param.triggerEvent +
                        '&referenceId=' + param.commonService.getEncodedURI(param.referenceId) +
                        '&tenantId=' + tenantId +
                        '&userName=' + userName).subscribe((next: any) => {
                        param.commonService.postMessage('', 'Sent For Approval');
                        param.context.onApprovalUpdate.emit({step: 'Send For Approval'});
                    }, (error: HttpErrorResponse) => {
                            let message = param.commonService.getHttpErrorMessage(error, 'Trade');
                            if(message === 'Workflow configuration not found') {
                                param.commonService.postMessage('', 'Sent For Approval');
                                param.context.onApprovalUpdate.emit({step: 'Send For Approval'});
                            } else {
                                param.commonService.postMessage('', message, 'error');
                            }
                    });
                } else {
                    param.commonService.postMessage('', 'Below Information required for Trade confirmation. Please edit form mode and add details.\n\n' + notAvailableFields, 'error');
                }
            };
            items.push(param.commonService.getMenuItemFromButton(new Button('Send For Approval', 'F', '', sendToApprovalFunction, undefined, undefined, undefined, undefined, (tradeStatus !== 'draft'))));
        }
        if (param.response.length > 0 && tradeStatus !== 'draft') {
            let steps = param.response[0]['workflowSteps'].sort((a, b) => parseFloat(a.sequence) - parseFloat(b.sequence));
            steps.forEach(step => {
                let role = step.role;
                if(role === null) {
                    let sublist: Button[] = [];
                    let keys: any[] = Object.keys(step.desiredEndStatus);
                    keys.forEach((key) => {
                        sublist.push(new Button(key, 'F', '', param.callbackFunction, '', 'left', {
                            step: step,
                            pressed: key
                        }, undefined, false));
                    });
                    let button = new Button(step.actionName, 'F', '', undefined, undefined, undefined, undefined, undefined, !(param.response[0].currentStep + 1 === step.sequence), sublist);
                    items.push(param.commonService.getMenuItem(button,step,param.response[0]));
                } else {
                    let myroles:string[] = param.commonService.getFromStorage('roles').split(",");
                    if (myroles.includes(role)) {
                        let sublist: Button[] = [];
                        let keys: any[] = Object.keys(step.desiredEndStatus);
                        keys.forEach((key) => {
                            sublist.push(new Button(key, 'F', '', param.callbackFunction, '', 'left', {
                                step: step,
                                pressed: key
                            }, undefined, false));
                        });
                        let button = new Button(step.actionName, 'F', '', undefined, undefined, undefined, undefined, undefined, !(param.response[0].currentStep + 1 === step.sequence), sublist);
                        items.push(param.commonService.getMenuItem(button, step, param.response[0]));
                    }
                }

            });
        }
        //Add Other Than Approval Button
        if (param.accessService.canAccessWithCurrentRoute('Action', 'void')) {
            let voidFunction = function(value) {
                param.currentApprovalObject.workflow['workflowName'] = 'Trade Approval';
                param.currentApprovalObject.buttonName = 'Void';
                param.currentApprovalObject.step = {};
                param.currentApprovalObject.isInApproval = false;
                param.currentApprovalObject.apiConfig.url = environment.base_url + '/api/trade/v1/physicaltradeapprovals?tenantId='
                    + param.commonService.getFromStorage('tenantId') + '&action=void';
                param.currentApprovalObject.apiConfig.type = 'post';
                param.currentApprovalObject.apiConfig.payloadFunction = (paramPayload: ApprovalFunctionParams) => {
                    return {
                        tenantId: paramPayload.commonService.getFromStorage('tenantId'),
                        tradeId: paramPayload.rowData['tradeId'],
                        userName: paramPayload.commonService.getFromStorage('userName'),
                        reason: paramPayload.note,
                        canFinanceApprove: (paramPayload.accessService.canAccessWithCurrentRoute('Action', 'financeApproval') || paramPayload.accessService.canAccessWithCurrentRoute('Action', 'financeApprovalWithException'))
                    };
                };
                param.context.showPopup();
            };
            items.push(param.commonService.getMenuItemFromButton(new Button(tradeOptionsButtons.void, 'F', '', voidFunction, undefined, undefined, undefined, undefined,false)));
        }
        let generateProformaInvoiceFunction = function() {
            param.context.onMenuItemClick.emit({title: tradeOptionsButtons.generateProFormaInvoice,data:param.rowData});
        };

        let generateAdvancedInvoiceFunction = function() {
            param.context.onMenuItemClick.emit({title: tradeOptionsButtons.generateAdvancedInvoice,data:param.rowData});
        };

        let generateDocumentFunc = function() {
            param.context.onMenuItemClick.emit({title: tradeOptionsButtons.generateDocument,data:param.rowData});
        };

        let addCostAction = function() {
            param.context.onMenuItemClick.emit({title: tradeOptionsButtons.addCost,data:param.rowData});
        };

        if (param.accessService.canAccessWithCurrentRoute('Action', 'generateProFormaInvoice')) {
            items.push(param.commonService.getMenuItemFromButton(new Button(tradeOptionsButtons.generateProFormaInvoice, 'F', '', generateProformaInvoiceFunction, undefined, undefined, undefined, undefined, false)));
        }
        if (param.accessService.canAccessWithCurrentRoute('Action', 'generateAdvancedInvoice')) {
            items.push(param.commonService.getMenuItemFromButton(new Button(tradeOptionsButtons.generateAdvancedInvoice, 'F', '', generateAdvancedInvoiceFunction, undefined, undefined, undefined, undefined, false)));
        }
        if (param.accessService.canAccessWithCurrentRoute('Action', 'generateDocument')) {
            items.push(param.commonService.getMenuItemFromButton(new Button(tradeOptionsButtons.generateDocument, 'F', '', generateDocumentFunc, undefined, undefined, undefined, undefined, false)));
        }
        if (param.accessService.canAccessWithCurrentRoute('Action', 'addCost')) {
            items.push(param.commonService.getMenuItemFromButton(new Button(tradeOptionsButtons.addCost, 'F', '', addCostAction, undefined, undefined, undefined, undefined, false)));
        }

        return items;
    },
    onDone: (param: ApprovalFunctionParams) => {
        if(param.currentApprovalObject.buttonName=="Void"){
            param.commonService.postMessage('', `'${param.rowData.tradeId}' ${param.currentApprovalObject.buttonName} Successfully.`,'success');
        }
        else{
            param.commonService.postMessage('', `${param.currentApprovalObject.step['actionName']} ${param.currentApprovalObject.buttonName} Successfully.`,'success');
        }
        param.onUpdateDone.emit({step: param.currentApprovalObject.buttonName});
    },
    disableCondition: (param:ApprovalFunctionParams) => {
        return (param.rowData['tradeStatus'].toLowerCase() === 'void' || (param.rowData['tradeId'] === null || param.rowData['tradeId'] === undefined ||param.rowData['tradeId'].length === 0))
    }
};
