import {Component, forwardRef, Input, OnChanges, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from "@angular/forms";

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CtrmMultiInputComponent),
  multi: true
};

@Component({
  selector: 'ctrm-multi-input',
  templateUrl: './ctrm-multi-input.component.html',
  styleUrls: ['./ctrm-multi-input.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class CtrmMultiInputComponent implements OnInit,OnChanges {


  @Input('value') val: any;
  @Input('name') name: string = '';
  @Input() inputType: string = 'text';
  @Input() keys:any[] = [];
  @Input() separator:string = ',';
  @Input() disabled:boolean = false;

  map:any = {};

  constructor() { }

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  onTouched: any = () => {
  };

  onChange(val) {
    this.val = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if(changes['keys'] && (changes['keys'].currentValue.length !== changes['keys'].previousValue.length)) {
      let _this = this;
      this.map = {};
      this.keys.forEach(item => {
        _this.map[item]='';
      });
    }
  }

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
    }
  }

  convertIntoOutputForm() {
    let values = Object.values(this.map);
    this.value = values.join(this.separator);
  }

}
