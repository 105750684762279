import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {AuthBean} from '../services/models/Authentication.model';
import {CommonService} from '../services/common.service';
import {MenuItem, MessageService} from 'primeng/api';
import {environment} from "../../environments/environment";
import {Location} from '@angular/common';
import {filter} from "../masters/services/filter.model";
import {entities} from "../services/common/entities";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
  providers: [MessageService]
})
export class SignupComponent implements OnInit {
  formgroup: FormGroup;
  message: any = '';
  submitted: boolean = false;
  showPassword: boolean = false;
  showCPassword: boolean = false;
  isCreating: boolean = false;
  isDayLight: boolean = false;
  selectedTimezone: any = {label: 'Select', value: 'select'};
  style: any = {};
  items: MenuItem[];
  activeIndex: number = 0;
  controlname: any[] = [];
  tenantId: any;
  value: any = 50;
  isConverting: boolean = false;
  private fileType: string;

  constructor(private formBuilder: FormBuilder, public router: Router, public auth: AuthService, public commonService: CommonService, public messageService: MessageService, public location: Location) {
  }

  /**
   * Creating the Form Control for Sign up form
   */
  ngOnInit() {
    this.items = [
      {label: 'Personal Information'},
      {label: 'Additional Information'}
    ];

    this.controlname.push({cname: 'fullname', oname: 'Full Name', index: 0});
    this.controlname.push({cname: 'email', oname: 'Email', index: 0});
    this.controlname.push({cname: 'username', oname: 'Username', index: 0});
    this.controlname.push({cname: 'password', oname: 'Password', index: 0});
    this.controlname.push({cname: 'confirmpassword', oname: 'Confirm Password', index: 0});
    this.controlname.push({cname: 'companyname', oname: 'Company Name', index: 1});
    this.controlname.push({cname: 'companynamealias', oname: 'Company/Account Alias', index: 1});
    this.controlname.push({cname: 'country', oname: 'Country', index: 1});
    this.controlname.push({cname: 'timezone', oname: 'Time Zone', index: 1});
    this.controlname.push({cname: 'currency', oname: 'Currency', index: 1});
    this.controlname.push({cname: 'dst', oname: 'Day light saving', index: 1});
    this.controlname.push({cname: 'contact', oname: 'Contact', index: 0});
    this.controlname.push({cname: 'refcode', oname: 'Referral Code', index: 1});
    this.controlname.push({cname: 'companyRegistrationNumber', oname: 'Company Registration Number', index: 1});
    this.controlname.push({cname: 'companyDocuments', oname: 'Company Documents', index: 0});
    const obj = {};
    obj['fullname'] = ['', [Validators.required]];
    obj['email'] = ['', [Validators.required, Validators.email]];
    obj['username'] = ['', [Validators.required]];
    obj['password'] = ['', [Validators.required, Validators.minLength(6)]];
    obj['confirmpassword'] = ['', [Validators.required, Validators.minLength(6)]];
    obj['companyname'] = ['', [Validators.required]];
    obj['companynamealias'] = ['', [Validators.required]];
    obj['country'] = ['', []];
    obj['timezone'] = ['', []];
    obj['currency'] = ['', []];
    obj['dst'] = [false, []];
    obj['contact'] = ['', [Validators.required, Validators.minLength(10)]];
    obj['refcode'] = ['', [Validators.required, Validators.minLength(5)]];
    obj['companyRegistrationNumber'] = ['', [Validators.required, Validators.minLength(3), Validators.maxLength(56)]];
    obj['companyDocuments'] = ['', []];
    this.formgroup = this.formBuilder.group(obj);
  }

  /**
   * Function to make sure that the selected Value is "Select" or Blank
   * @param value // Value to Check
   */
  isnotSelectandblank(value) {
    let iserror = false;
    if (value === 'select' || value.length <= 0 || value === undefined || value === null) {
      iserror = true;
    }
    return iserror;
  }

  /**
   * Function to check the status of Login by Validating the Form
   * @param form //Form
   */
  createAccount(form: FormGroup) {
    if (this.activeIndex === 1) {
      let value = form.value;
      if (value['refcode'] === 'At20@Be21#Ca22%De24') {
        let _this = this;
        let filterObject;
        this.submitted = true;
        if (!form.valid) {
          this.getErrorMessage(form.controls);
        } else {
          if (value['confirmpassword'] === value['password']) {
            if (this.isnotSelectandblank(value['country']) === false && this.isnotSelectandblank(value['timezone']) === false && this.isnotSelectandblank(value['currency']) === false) {
              this.submitted = false;
              let signupPayload = {
                tenantAlias: value['companynamealias'],
                userName: value['username'],
                firstName: value['fullname'],
                emailAddress:value['email'],
                telephone: value['contact'],
                companyName: value['companyname'],
                country: value['country'],
                timeZone:value['timezone'],
                currency: value['currency'],
                companyRegistrationNumber:value['companyRegistrationNumber'],
                companyDocuments: value['companyDocuments'],
                dayLightSaving: value['dst'],
                password: value['confirmpassword']
              };
              // let user = new AuthBean(value['username'], value['fullname'], value['email'], value['password'], value['contact'], value['companyname'], value['country'], value['timezone'], value['currency'], value['companyRegistrationNumber'], value['companyDocuments'], value['dst'],value['companynamealias']);

              let _this = this;
              this.isCreating = true;
              //SignUp API Call
              this.commonService.post(environment.base_url_new + '/api-iam/api/tenant-management/v1/signup', signupPayload).subscribe((next: any) => {
                _this.isCreating = false;
                _this.showMessage();

              }, (error: HttpErrorResponse) => {
                _this.isCreating = false;
                _this.submitted = true;
                _this.message = _this.commonService.getHttpErrorMessage(error, 'Signup');
              });
            } else {

              _this.submitted = true;
              _this.message = 'Please select country/currency and timezone';
            }

          } else {
            this.submitted = true;
            this.message = 'Confirm password is not same !';
            form.controls['confirmpassword'].setValue('');
          }
        }
      } else {
        this.submitted = true;
        this.message = 'Please check the referral code and try again !';
      }

    } else {
      if (!this.checkErrorforIndex(0)) {
        this.submitted = false;
        this.activeIndex = 1;
      }
    }
  }

  /**
   * Function to toggle View of Password Field
   * @param num
   */
  togglePasswordView(num) {
    if (num === 0) {
      this.showPassword = !this.showPassword;
    } else {
      this.showCPassword = !this.showCPassword;
    }
  }

  /**
   * Check Error for Index
   * @param index // We have two step Form for Signing up, so each Index refers to each Step
   */
  checkErrorforIndex(index = 0) {
    let invalid = false;
    let _this = this;
    this.controlname.forEach(function (object) {
      if (object['index'] === index) {
        let control = _this.formgroup.controls[object['cname']];
        let inv = _this.getControlError(control, object['cname'], _this);
        if (inv === true) {
          invalid = true;
        }
      }
    });
    return invalid;
  }

  /**
   * Functiom to navigate to Login Page
   */
  openlogin() {
    if (window.location.origin.includes('localhost')) {
      this.router.navigate(['/login']);
    } else {
      window.location.href =  '/auth/';
    }
  }

  getOname(cname) {
    let oname = '';
    this.controlname.forEach(function (object) {
      if (object['cname'] === cname) {
        oname = object['oname'];
      }
    });
    return oname;
  }

  /**
   * to Check for error in Form Control
   * @param control // name of Control
   * @param name
   * @param _this
   */
  getControlError(control, name, _this) {
    _this.submitted = true;
    let isError = false;
    if (control.status === 'INVALID') {
      isError = true;
      let err = control.errors;
      let type = Object.keys(err)[0];
      if (type === 'minlength') {
        _this.message = this.getOname(name) + ' length should be greater than or equal to ' + err[type].requiredLength + ' !';
      } else if (type === 'email') {
        _this.message = 'Provide a valid Email ID !'
      } else if (type === 'required') {
        _this.message = this.getOname(name) + ' is required !';
      }
    }
    return isError;
  }

  updateUI(value: any) {
    this.selectedTimezone = value;
    this.isDayLight = false;
    if (value['data'] !== undefined) {
      if (value['data']['dst'] === '1') {
        this.isDayLight = true;
      }
    }
  }

  updateCUI(value: any) {
  }

  updateCUI1(value: any) {
  }

  showConfirmation() {
    this.messageService.add({severity: 'success', summary: 'Success', detail: 'Account has been created'});

  }

  /**
   * To change the Index
   * @param value
   */
  changeIndex(value) {
    if (!this.checkErrorforIndex(1 - value)) {
      this.submitted = false;
      this.activeIndex = value;
    }
  }

  /**
   * To show the Message on Successful signup and Navigate to Dashboard
   */
  showMessage() {
    let modal = document.getElementById('myModal');
    let _this = this;
    modal.style.display = 'block';
    setTimeout(function () {
      modal.style.display = 'none';
      _this.openlogin();
    }, 2000);
  }

  /**
   * close the Modal
   */
  closeModal() {
    let _this = this;
    let modal = document.getElementById('myModal');
    modal.style.display = 'none';
    setTimeout(function () {
      _this.router.navigate(['/dashboard']);
    }, 2000);

  }

  /**
   * To handle the File Input and convert the file into Byte Array to store in DB
   * @param event
   */
  handleFileInput(event: any) {
    let _this = this;
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      this.isConverting = true;
      let file = event.target.files[0];
      this.fileType = file['type'].split('/')[0];
      reader.readAsDataURL(file);
      _this.message = '';
      reader.onload = (event2) => {
        let str = reader.result;
        const uploadData = new FormData();
        uploadData.append('file', file);
        _this.commonService.getByteArray(environment.base_url + '/api/company/v1/upload/logo', uploadData).subscribe(function (data: any) {

          _this.formgroup.controls['companyDocuments'].setValue(data.response);
          _this.isConverting = false;
        }, (error: any) => {
          _this.submitted = true;
          _this.message = 'Error in reading document !';
          _this.isConverting = false;
        })
      };
    }
  }

  /**
   * Function to show the Error message of Signup Form
   * @param controls //Form control of Sign up Form
   */
  private getErrorMessage(controls: any) {
    let keys = Object.keys(controls);
    for (let i = 0; i < keys.length; i++) {
      let control = controls[keys[i]];
      this.getControlError(control, keys[i], this);
    }
  }
}
