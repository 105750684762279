import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'column-filter',
  templateUrl: './column-filter.component.html',
  styleUrls: ['./column-filter.component.css']
})
export class ColumnFilterComponent implements OnInit {

  showOption: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  onClick(value: MouseEvent) {
    this.showOption = !this.showOption;
  }
}
