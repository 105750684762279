<div *ngIf="showSectionTop" class="sectionCardTop">
  <div class="breadcrumbPane">
    <ctrm-breadcrumb [collapsabel]="false"
                     [items]="breadCrumbService.getBreadCrumb()"
                     [showIcon]="false"></ctrm-breadcrumb>
  </div>
  <div class="actionPane">
    <div class="screenDetails">
      <img *ngIf="!showBack()" [src]="commonService.getIconPath(grid.iconName)"/>
      <i (click)="changeRouteToBack()" *ngIf="showBack()" class="fas fa-chevron-left backIcon"></i>
      <p>{{title}}</p>
      <sta-filter
              *ngIf="showStandardFilter"
              [pTable]="dt"
              [columns]="filterColumns"
              [hiddenFields]="grid.filterHiddenFields"
              [hiddenFieldsToShowFromTcolumn]="grid.hiddenFieldsToShowInStandardFilters"
              [preselectedFilter]="grid.getFilters()"
              [defaultSelectedColumns]="defaultColumnsForStdFilter"
              (onFilterApply)="triggerAdvancedFilterAPI($event)"
              [moduleName]="grid.getTitle()"></sta-filter>
    </div>
  <div *ngIf="grid.gridFilterURL !== null && grid.gridFilterURL !== undefined" class="prefrenceWrapper">
    <div>
      <p-dropdown
              [options]="preferenceList" [(ngModel)]="selectedPreference" (ngModelChange)="onPrefSelection($event)"
              appendTo="body">
      </p-dropdown>
    </div>
    <div style="margin-left: 10px">
      <p-multiSelect [options]="columnsList" optionLabel="label" (ngModelChange)="onPrefColChange($event)" [defaultLabel]="'Columns'" [(ngModel)]="selectedPrefCols"></p-multiSelect>
    </div>

    <div style="margin-left: 10px">
      <div (click)="toggleFilterPanel($event)" class="filterBtnWrapper">
        <i class="fas fa-filter funnelIcon"></i>
        <p class="filterBtnText">Filter</p>
        <i class="fas fa-chevron-down filterDownArrowIcon"></i>
      </div>
    </div>

    <div style="margin-left: 10px">
      <button class="prefSaveBtn" (click)="openSavePrefDialog()"
              type="button"><i
              class="fas fa-save"></i> Save
      </button>
    </div>

    <div style="margin-left: 10px; display: flex; margin-top: 5px">
      <p-checkbox (onChange)="onMakeDefaultChange($event)" [(ngModel)]="prefDefault" [binary]="true"></p-checkbox>
      <p style="font-size: 14px; margin-top: -2px">Default</p>
    </div>

<!--    Filter section dropdown div-->


<div (click)="$event.stopPropagation()">
    <div class="filterSecDiv" *ngIf="filterPanelDisplay">
      <div class="filterSecWrapper">
       <div style="display: flex">
         <div class="filterFlexBox">
           <p-listbox [options]="filterColumnList" (onChange)="addfilter($event)"  optionLabel="label" [(ngModel)]="selectedFilterCol"></p-listbox>
         </div>
         <div class="filterFlexBox" *ngIf="filterActionList.length !== 0">
           <p-listbox (onChange)="updateOptions($event)" [options]="filterActionList" optionLabel="label" [(ngModel)]="selectedFilterAction"></p-listbox>
         </div>
         <div style="flex: 1" *ngIf="selectedFilterAction.length !== 0 && selectedFilterCol.length !==0">
           <div *ngIf="filterList[0] !== undefined && filterList[0] !== null &&
           (filterList[0]['type'] === 'T' || filterList[0]['type'] === 'TA' || filterList[0]['type'] === 'LB')" style="padding: 5px">
             <ctrm-input (onChangeValue)="addTextOption($event)" class="contentMargin"></ctrm-input>
           </div>

           <div *ngIf="filterList[0] !== undefined && filterList[0] !== null && (filterList[0]['type'] === 'L' || filterList[0]['type'] === 'B' || filterList[0]['type'] === 'OB')" class="dropdown">

             <ctrm-dropdown (onChangeValue)="addListOption($event)" [addSelectOption]="false"
                            [field]="filterDropDown"
                            [selectedValue]="filterList[0].getCondition()"
                            class="contentMargin"></ctrm-dropdown>

           </div>

           <div *ngIf="filterList[0] !== undefined && filterList[0] !== null && filterList[0]['type'] === 'N'" class="contentBox">
             <ctrm-input (onChangeValue)="addNumberOption($event,true)" [defaultValue]="filterList[0]['firstValue']"
                         [type]="'number'" class="contentMargin"></ctrm-input>
             <ctrm-input (onChangeValue)="addNumberOption($event,false)"
                         *ngIf="isSecondRequired(filterList[0])" [defaultValue]="filterList[0]['secondValue']"
                         [type]="'number'" class="contentMargin"></ctrm-input>
           </div>

           <div *ngIf="filterList[0] !== undefined && filterList[0] !== null && filterList[0]['type'] === 'D'" class="contentBox">
             <input (bsValueChange)="addNumberOption($event,true)"
                    [bsConfig]="{dateInputFormat : getDateFormat(filterList[0]['field'])}"
                    [maxDate]="isValidDate(filterList[0]['field'].getMaxDate())?filterList[0]['field'].getMaxDate():''"
                    [minDate]="isValidDate(filterList[0]['field'].getMinDate())?filterList[0]['field'].getMinDate():''"
                    [minMode]="filterList[0]['field'].getFromExtra('date')['view']"
                    bsDatepicker
                    autocomplete="off"
                    class="form-control"
                    placeholder="select date" type="text">
             <input (bsValueChange)="addNumberOption($event, false)"
                    *ngIf="isSecondRequired(filterList[0])"
                    [bsConfig]="{dateInputFormat : getDateFormat(filterList[0]['field'])}"
                    [maxDate]="isValidDate(filterList[0]['field'].getMaxDate())?filterList[0]['field'].getMaxDate():''"
                    [minDate]="isValidDate(filterList[0]['field'].getMinDate())?filterList[0]['field'].getMinDate():''"
                    [minMode]="filterList[0]['field'].getFromExtra('date')['view']"
                    bsDatepicker
                    autocomplete="off"
                    class="form-control"
                    placeholder="select date"
                    style="margin-left: 6px" type="text">
           </div>
         </div>
       </div>

        <button *ngIf="selectedFilterAction.length !== 0 && selectedFilterCol.length !==0" (click)="onApplyFilter()" class="planScreenBtns customFilterBtn">Apply Filter</button>

      </div>
    </div>
</div>

<!--    DIalog for preference Save Name input-->

    <p-dialog [(visible)]="preferenceSaveDialog"
              [closable]="true"
              [draggable]="false"
              [modal]="true"
              [maximizable]="false"
              [showHeader]="false"
              [style]="{ width: '30%',height: '15%',maxHeight:'15%', padding:'0px', background:'#ffffff'}">
      <div style="padding: 5px 10px">
        <p style="font-size: 14px; font-weight: bold; color: #16144E">Save Preference</p>
      </div>
      <div style="padding: 20px">
        <ctrm-input [placeholder]="'Preference Name'" [defaultValue]="preferenceName" [(ngModel)]="preferenceName" (onChangeValue)="onPrefNameChange($event)" class="contentMargin"></ctrm-input>
      </div>
      <p-footer>
        <button class="cancelBtn" (click)="preferenceSaveDialog = false">Cancel</button>
        <button class="planScreenBtns" style="color: white" (click)="savePreferences()">Save</button>
      </p-footer>
    </p-dialog>
  </div>
    <div class="rightInfo">
      <p>Total {{totalElements}} {{title}}</p>
    </div>
  </div>
</div>
<div *ngIf="extraViewTemplate !== undefined">
  <ng-container *ngTemplateOutlet="extraViewTemplate"></ng-container>
</div>
<div [ngClass]="getClassForGrid()">
  <div class="toolbar" *ngIf ="showToolbar">
    <div style="display: inline-flex;align-items: center">
      <ctrm-button (onClick)="openModal()"
                   *ngIf="grid.getGridConfig().canAdd && toolbarConfig['add']"
                   [accessKey]="'n'"
                   [icon]="'fas fa-plus'"
                   [access]="'Grid Access'" [action]="'create'"
                   [label]="'Add'"></ctrm-button>
      <ctrm-button (onClick)="openInModal(selectedRow, true)"
                   *ngIf="(grid.getGridConfig().canEdit || grid.getGridConfig().canView) && toolbarConfig['edit']"
                   [disabled]="isDisable('edit')" [icon]="getEditIcon()" [label]="getEditLabel()"></ctrm-button>
      <ctrm-button (onClick)="multipleRowCopy()" *ngIf="grid.getGridConfig().canCopy && toolbarConfig['copy']"
                   [disabled]="isDisable('copy')" [icon]="'fas fa-copy'" [label]="'Duplicate'"></ctrm-button>
      <ctrm-button (onClick)="multipleRowDelete()" *ngIf="grid.getGridConfig().canDelete && toolbarConfig['delete']"
                   [disabled]="(selectedRow.length===0 && ! accessPolicyService.currentGridAccess['delete'])" [icon]="'fas fa-minus-circle'" [label]="'Delete'"></ctrm-button>
      <ctrm-button (onClick)="openHierarchy(selectedRow)" *ngIf="this.grid.getHierarchy()" [disabled]="isDisable()"
                   [icon]="'fas fa-sitemap'" label="Hierarchy"></ctrm-button>
      <ctrm-button (onClick)="refreshGridData()" *ngIf="toolbarConfig['refresh']"
                   [disabled]="showFetching"
                   icon="{{showFetching?'fas fa-redo fa-spin':'fas fa-redo'}}" label="Refresh"></ctrm-button>
      <ctrm-button (onClick)="openDealSlip()" *ngIf="this.route.url == '/contractMain' && showDealSlip" icon="fas fa-slip"
                   label="DelaSlip"></ctrm-button>
      <div *ngIf="showFilterInToolbar" style="margin-left: 5px">
        <ng-container *ngTemplateOutlet="dropdownfilters"></ng-container>
      </div>
      <div class="float-right">
        <div (click)="increaseFontSize()" class="clickBox">
          <img class="fontImage" src="../assets/icons/fontIncrease.png"/>
        </div>
        <div (click)="decreaseFontSize()" class="clickBox">
          <img class="fontImage" src="../assets/icons/fontDecrease.png"/>
        </div>
        <ctrm-button (onClick)="import()" *ngIf="grid.getGridConfig().canImport && toolbarConfig['import']"
                     [access]="'Toolbar'" [action]="'import'"
                     icon="fas fa-file-import" label="Import"></ctrm-button>
        <ctrm-button (onClick)=" downloadSelected()"
                     *ngIf="grid.getGridConfig().canDownloadSelected && toolbarConfig['export']"
                     [disabled]="selectedRow.length===0 || !accessPolicyService.currentGridAccess['export']"  icon="fas fa-file-export"
                     label="Export"></ctrm-button>
        <ctrm-button (onClick)="comingSoon('print')" *ngIf="grid.getGridConfig().canPrint && toolbarConfig['print']"
                     [access]="'Toolbar'" [action]="'print'"
                     icon="fas fa-print" label="Print"></ctrm-button>
        <ctrm-button (onClick)="openAudit()"
                     [disabled]="accessPolicyService.currentGridAccess['audittrail']?(selectedRow.length !== 1 || grid.auditEntityName === null || grid.auditEntityName === undefined || grid.auditEntityName.length === 0 ): true"
                     *ngIf="grid.getGridConfig().canAudit && toolbarConfig['audit']"
                     icon="fas fa-history" label="Audit Trail"></ctrm-button>
        <div *ngIf="customViewInToolbarRight !== null && customViewInToolbarRight !== undefined">
          <ng-container *ngTemplateOutlet="customViewInToolbarRight"></ng-container>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!showFilterInToolbar">
    <ng-container *ngTemplateOutlet="dropdownfilters"></ng-container>
  </div>
  <div class="gridView" id="grid">
    <div *ngIf="filters.length>0 && showFilterChips" class="ui-g filterBox">
      <ctrm-chips (OnRemove)="removeFilter($event,i)" *ngFor="let chip of filters;index as i"
                  [chip]="getChipValue(chip)"
                  [filter]="chip"
                  [position]="i"></ctrm-chips>
    </div>
    <p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
             appendTo="body"></p-toast>
    <p-contextMenu #cm appendTo="body" styleClass="menucus"></p-contextMenu>
    <form #gridForm="ngForm" [formGroup]="gridFrmGroup">
      <div class="tableDiv">
        <p-table #dt
                 [(contextMenuSelection)]="selectedValue"
                 [(selection)]="selectedRow"
                 [columns]="columns"
                 [dataKey]="showPivot?getCategorizedBy(grid.getPivotConfig()):'rowNum'"
                 [metaKeySelection]="false"
                 [reorderableColumns]="true"
                 [resizableColumns]="true"
                 [rowHover]="true"
                 [showLoader]="false"
                 (onRowSelect)="onRowSelect()"
                 (onRowUnselect)="onRowSelect()"
                 [loading]="showFetching"
                 [loadingIcon]="'fas fa-spinner'"
                 [customSort]="useCustomSort"
                 (onSort)="onSortDone()"
                 (onRowExpand)="onRowExpand($event)"
                 (onRowCollapse)="onRowCollapse($event)"
                 (sortFunction)="sortCustom($event)"
                 [responsive]="true" [style]="{maxHeight:gridHeight,minHeight:setMinHeight?gridHeight:'',width:grid.getWidth()}"
                 [value]="this.rowDataValues"
                 dataKey="rowNum"
                 autoLayout="{{autoLayout}}" class="style-4 table-responsive" columnResizeMode="expand" editMode="row"
                 selectionMode="multiple" sortMode="multiple">
          <ng-template let-columns pTemplate="header">
            <tr class="sticky-top" style="background: #d9dbde !important;">
              <th *ngIf="grid.showCheckbox" class="checkBoxCol stickLeftHeader">
                <p-tableHeaderCheckbox (click)="onRowSelect()" *ngIf="showheaderCheckbox"></p-tableHeaderCheckbox>
                <div class="actionPipe"></div>
              </th>
              <th *ngIf="isExpandGridAvailable()" [ngClass]="getExpandHeaderClass()" class="expandColumn" >
              </th>
              <th *ngIf="showActionColumn()" [ngClass]="getActionHeaderClass()" class="actionColumn"  id="" scope="col">
                <div class="headerDiv">
                  <div [style.fontSize]="dataFontSize +'px'" class="headerTitle">Actions</div>
                </div>
                <div class="actionPipe"></div>
              </th>
              <th *ngFor="let col of columns;index as i" [hidden]="(col !== null && col !== undefined)?!col['visible']:false" [id]="'header' + i"
                  [ngClass]="getClassForFixHeader(col)"
                  [ngStyle]="getStyle(col,i,'400', 'header' + i)"
                  [pReorderableColumnDisabled]="col.getField()===grid.getPrimaryKey() || leftFixedColumns.includes(col.getField) || rightFixedColumns.includes(col.getField())"
                  [pSortableColumn]="sortable?col['field']:''" class="otherColumn stk" pReorderableColumn pResizableColumn>
                <div class="headerDiv" [style.width]="customWidthColumnNames.includes(col.getField())?customWidthForColumn[customWidthColumnNames.indexOf(col.getField())]:headerWidth">
                  <div [style.fontSize]="dataFontSize +'px'" class="headerTitle">{{col['header']}}</div>
                  <p-sortIcon *ngIf="showSortIcon" #srtIcon [field]="col['field']" ariaLabel="Activate to sort"
                              ariaLabelAsc="Activate to sort in ascending order"
                              ariaLabelDesc="Activate to sort in descending order"></p-sortIcon>
                  <img [id]="(col.field)" *ngIf="col.showFilter"
                       [ngClass]="iconClass(col.field)" (click)="showFilters(col,op,$event)" src="../../../assets/icons/{{showiconOnFilter(col.field)}}">
                  <div *ngIf="isNotLast(i)" class="pipe"></div>
                </div>
              </th>
            </tr>
          </ng-template>
          <ng-template let-columns="columns" let-editing="editing" let-expanded="expanded" let-i="rowIndex"
                       let-ri="rowIndex" let-rowData
                       pTemplate="body">
            <tr *ngIf="!showPivot" [id]="'tr'+ri" [pContextMenuRow]="rowData" [pEditableRow]="rowData"
                class="otherData">
              <td *ngIf="grid.showCheckbox" class="checkBoxCol stickLeftBody">
                <p-tableCheckbox [disabled]="actionCheckboxStateFunction !== undefined && actionCheckboxStateFunction !== null?commonService.runFunction(rowData,actionCheckboxStateFunction):false"
                                 [value]="rowData"></p-tableCheckbox>
              </td>
              <td *ngIf="isExpandGridAvailable()" [ngClass]="getExpandBodyClass()"  class="expandData">
                <p *ngIf="canShowExpandGrid(rowData)" style="margin: 0;text-align: center">
                  <a [pRowToggler]="rowData" href="#">
                    <i [ngClass]="expanded ? collapseButtonIcon :expandButtonIcon" class="togglerIcon"></i>
                  </a>
                </p>
              </td>
              <td *ngIf="showActionColumn()" [id]="'td'+ri" [ngClass]="getActionBodyClass()" class="actionData"
                  scope="row" style="padding: 0;">
                <div class="actionDiv">
                  <approval-context-menu *ngIf="grid.isHaveExtraButtons" [contextMenu]="cm"
                                         [img]="commonService.getIconPath('RightClick')"
                                         [functions]="grid.contextMenuConfig.functionFile" [rowData]="rowData"
                                         [nameField]="rowData[grid.contextMenuConfig.nameFieldId]"
                                         [screenName]="grid.contextMenuConfig.screenName"
                                         [referenceId]="rowData[grid.contextMenuConfig.referenceIdField]"
                                         (onMenuItemClick)="onClickContextMenuItem($event)"
                                         (onApprovalUpdate)="onUpdate($event)"
                                         [triggerEvent]="grid.contextMenuConfig.triggerEvent"></approval-context-menu>
                  <i (click)="onRowEditInit(rowData,ri)"
                     *ngIf="!editing && grid.getGridConfig().canEdit"
                     [ngClass]="getRowClass('edit',rowData)"
                     pInitEditableRow
                     pTooltip="Inline Edit"
                     tooltipZIndex="436">
                  </i>
                  <i (click)="onRowCopy(rowData,true)" *ngIf="!editing && grid.getGridConfig().canCopy"
                     [ngClass]="getRowClass('copy',rowData)"
                     pTooltip="Copy"
                     tooltipZIndex="436"></i>
                  <i (click)="onRowSave(rowData, ri)" *ngIf="editing && grid.getGridConfig().canEdit" accesskey="s" class="fas fa-save p-1 actionIcon"
                     pSaveEditableRow
                     pTooltip="Save"
                     tooltipZIndex="436"></i>
                  <i (click)="onRowEditCancel(rowData, ri)" *ngIf="editing && grid.getGridConfig().canEdit" class="fas fa-undo p-1 actionIcon"
                     pCancelEditableRow
                     pTooltip="Undo"
                     tooltipZIndex="436"></i>
                  <i (click)="onRowDelete(rowData , ri,true)"
                     *ngIf="grid.getGridConfig().canDelete && showActionDelete"
                     [ngClass]="getRowClass('delete',rowData)" pDeleteEditableRow
                     pTooltip="Delete"
                     tooltipZIndex="436"></i>
                </div>
              </td>
              <td *ngFor="let col of columns;index as pos" [hidden]="!col.isVisible()" [id]="'body' + pos + '_' +  ri"
                  [ngClass]="getClassForFixBody(col)"
                  [ngStyle]="getStyle(col,pos,'389', 'body' + pos + '_' +  ri)"
                  class="{{col.getField()===grid.getPrimaryKey()?'ui-resizable-column stickLeftBody':'ui-resizable-column'}}"
                  style="padding-left: 6px">
                <p-cellEditor *ngIf="col.isEditable()  || newRow.get(ri)">
                  <ng-template *ngIf="!fieldsWithCustomView.includes(col.getField())" pTemplate="input">
                    <div style="display: inline-flex">
                      <aio-controls (addBtnValue)="addBttnValue($event)"
                                    (change)="changeField(ri,col,gridFrmGroup.get(col.field+ri).value,false)"
                                    (ngModelChange)="isModalChange(col)?changeField(ri,col,gridFrmGroup.get(col.field+ri).value,false):''"
                                    (onButtonClick)="onClick($event)"
                                    (onFileSelected)="handleFileInput($event,ri,col,gridFrmGroup.get(col.field+ri).value)"
                                    (onUpdateValue)="changeField(ri,col,gridFrmGroup.get(col.field+ri).value,false)"
                                    [field]="col"
                                    [appendTo]="'body'"
                                    [numberboxwith]="numberboxwidth"
                                    [rightAlignText]="rightAlignNumberFields"
                                    [fileName]="getFileName(col,ri)"
                                    [formControlAIO]="gridFrmGroup.controls[col.getField()+ri]"
                                    [formControl]="gridFrmGroup.controls[col.getField()+ri]"
                                    [isOnForm]="false"
                                    [listOfValues]="getListOfValues(col,ri)"
                                    [readOnly]="gridFrmGroup.get(col.field+ri).disabled || disabledRows.includes(ri) || runDisableFunction(col,rowData)"
                                    [showLabel]="false"
                                    [showTooltipOnValidationError]="true"
                                    [value]="getFormControl(col.getField()+ri).value"></aio-controls>
                      <p *ngIf="canShowUnit(col)"
                         [style.fontSize]="dataFontSize +'px'" class="unittext" pTooltip="{{getValueForPostText(col,rowData,gridFrmGroup.get(col.field + ri).value)}}"
                         tooltipZIndex="9999">{{getValueForPostText(col,rowData,gridFrmGroup.get(col.field + ri).value)}}</p>
                    </div>
                  </ng-template>
                  <ng-template *ngIf="fieldsWithCustomView.includes(col.getField())" pTemplate="input">
                    <div>
                      <ng-container
                              [ngTemplateOutlet]="customViewTemplated[fieldsWithCustomView.indexOf(col.getField())]"
                              [ngTemplateOutletContext]="{rowData:rowData,formControl:gridFrmGroup.controls[col.getField()+ri],column:col,index:ri,readOnly:!editing, fromGrid:true,formControlName:col.getField()+ri}"></ng-container>
                    </div>
                  </ng-template>
                  <ng-template *ngIf="col.getColumnType() !=='BT' && col.getColumnType !== 'P' && !fieldsWithCustomView.includes(col.getField())" pTemplate="output">
                    <div (click)="onClickOutput(col,rowData,ri)"
                         [innerHTML]="getOutputValue(col,ri,rowData)"
                         [ngClass]="getClass(col,rowData)"
                         [style.fontSize]="dataFontSize +'px'"
                         pTooltip="{{getToolTip(col,rowData,ri)}}"
                         tooltipPosition="bottom" tooltipZIndex="1000">
                    </div>
                  </ng-template>
                  <ng-template *ngIf="col.getColumnType()==='BT'" pTemplate="output">
                    <div>
                      <p-button (onClick)="handleButtonClick($event,col,rowData)" [label]="col.getHeader()"
                                type="button"></p-button>
                    </div>
                  </ng-template>
                  <ng-template *ngIf="col.getColumnType()==='P'" pTemplate="output">
                    <div>
                      <input [style.fontSize]="dataFontSize +'px'" [value]="getOutputValue(col,ri,rowData)"
                             class="typeLabel" disabled type="password">
                    </div>
                  </ng-template>
                  <ng-template *ngIf="fieldsWithCustomView.includes(col.getField())" pTemplate="output">
                    <div>
                      <ng-container
                              [ngTemplateOutlet]="customViewTemplated[fieldsWithCustomView.indexOf(col.getField())]"
                              [ngTemplateOutletContext]="{rowData:rowData,formControl:gridFrmGroup.controls[col.getField()+ri],column:col,index:ri, readOnly:!editing, fromGrid:true,formControlName:col.getField()+ri}"></ng-container>
                    </div>
                  </ng-template>
                </p-cellEditor>
                <span (click)="changeRoute(col,rowData,rowData[col.getField()],ri)"
                      *ngIf="!col.isEditable()"
                      [style.textAlign]="getOutputValue(col,ri,rowData)=== '-'?'center':'left'"
                      [hidden]="(newRow.get(ri)&&isAdding)?true:false" [ngClass]="getSpanClass(col)"
                      class="spanClass">
                      <div [innerHTML]="getOutputValue(col,ri,rowData)" [style.fontSize]="dataFontSize +'px'"></div>
                    </span>
              </td>
            </tr>
          </ng-template>
          <ng-template let-columns="columns" let-rowData let-i="rowIndex" pTemplate="rowexpansion">
            <tr *ngIf="isExpandGridAvailable()">
              <td [colSpan]="getColSpanExpand(columns.length)">
                <div [style.marginLeft]="marginLeftExpandData" *ngIf="(expandRowCustomTemplate === null || expandRowCustomTemplate === undefined)">
                  <xceler-grid [expandedRowdata]="rowData"
                               [expandedGrid]="grid.expandGrid"
                               [showPlaceHolder]="true"
                               [fieldsWithCustomView]="fieldsWithCustomViewInExpand"
                               [customViewTemplated]="customViewTemplatedInExpand"
                               (onExpandRowSelectionChange)="onExpandedGridChildSelection($event)"
                               [selectedRow]="grid.expandGrid.selectedRows"
                               (onSelectionChange)="onExpandChildSelection($event,grid.expandGrid,rowData)"
                               [primaryKey]="grid.expandGrid.getPrimaryKey()"
                               [showCheckbox]="grid.expandGrid.showCheckbox"
                               [setHeight]="false"
                               [columns]="convertList(grid.expandGrid.getColumn().values())">
                  </xceler-grid>
                </div>
                <div [style.marginLeft]="marginLeftExpandData"  *ngIf="expandRowCustomTemplate !== null && expandRowCustomTemplate !== undefined">
                  <ng-container [ngTemplateOutlet]="expandRowCustomTemplate"
                                [ngTemplateOutletContext]="{rowData:rowData,index:i}"></ng-container>
                </div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr>
              <td [colSpan]="columns.length + 2">
                <div
                  *ngIf="showPlaceHolder && (rowDataValues === undefined ||rowDataValues === null || rowDataValues.length === 0)"
                  [style]="{maxHeight:placeHolderHeight}" class="placeHolder">
                  <ng-container
                    *ngTemplateOutlet="noDataTemplate !== undefined?noDataTemplate:defualtTemplateNoData"></ng-container>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <p-paginator *ngIf="getPagination()" [rows]="20" [totalRecords]="totalElements" (onPageChange)="onPageChange($event)"></p-paginator>
        <ng-template #defualtTemplateNoData>
          <div (inViewportAction)="calculateLeft()" [id]="'noDataBoxId'" [style]="{left:noDataBoxLeft}" class="noDataBox"
               inViewport>
            <img [src]="commonService.getIconPath('emptyStateIcon')" class="iconClass"/>
            <p class="noDataHeader">{{showFetching || isError ? loadMessage : noDataLabel}}</p>
            <div [hidden]="showFetching" class="buttonsBox" *ngIf="grid.getTitle() != 'Inter Company Trade'">
              <div (click)="openModal()" *ngIf="showAddButtonInNoData && !isError && grid.getGridConfig().canAdd"
                   class="noDataButton">
                <p class="noDataButtonLabel" >{{'Add ' + grid.getTitle()}}</p>
              </div>
              <div (click)="changeRouteNoData()"
                   *ngIf="noDataRoute !== undefined && noDataRoute !== null && noDataRoute.length > 0 && !isError" class="noDataButton">
                <p class="noDataButtonLabel">{{routeToLable}}</p>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </form>
  </div>
  <app-import-grid (onClose)="closeImport()" (onImport)="importDone($event)"
                   [parentRowData]="parentRowValue"
                   [preImportFunction]="grid.actionFunc.preImportFunction"
                   [parentUuidFieldName]="parentUuidFieldName"
                   [display]="displayImport" [gridColumns]="masterCommonService.getExportGridColumns(grid)" [ignoreFieldList]="grid.ignoreFieldListForExport"
                   [importTableName]="importTableName" [importUrl]="grid.importUrl" [parentKeyValue]="parentKeyValue"
                   [tabs]="grid.childTabs"></app-import-grid>
  <ctrm-input-form (OnApply)="changeState($event)" (OnCancel)="canceled()"
                   #gridinputform
                   (addButtonValue)="addBttnValue($event)"
                   (onButtonClick)="onClick($event)"
                   (onClickNext)="onNavigationClick($event)"
                   (onClickPrevious)="onNavigationClick($event)"
                   (onRowDataDelete)="onRowDataDelete.emit($event)"
                   (onChangeObjectCreated)="onChangeObjectCreated.emit($event)"
                   (onRowDataSave)="onRowDataSave.emit($event)"
                   *ngIf="modalState && !switchTabGrid"
                   [bulkUrl]="bulkUrl"
                   [columnsMap]="grid.getColumn()"
                   [editUrl]="editUrl"
                   [extraButtons]="grid.getExtraButtons()"
                   [getUrl]="getUrl"
                   [gridConfig]="grid.getGridConfig()"
                   [gridTabs]="grid.getTabs()"
                   [gridTitle]="grid.getTitle()"
                   [heading]="grid.getTitle()"
                   [noOfColumns]="getNumberOfColumns()"
                   [readOnly]="readonly"
                   [refreshForm]="refreshForm"
                   [saveUrl]="saveUrl"
                   [sections]="grid.getSections()" [showExtraButtons]="grid.showExtraButtons"
                   [showOnModal]="true" [uniqueKey]="grid.getPrimaryKey()" [updateButtonDisableFunction]="grid.formUpdateButtonDisableFuncton"
                   [updateUrlType]="grid.updateUrlType"
                   [updateUrl]="rowDataUpdateUrl"
                   [extraObjectToSave]="extraObjectToSave"
                   [updatePayloadFunction]="updatePayloadFunction"
                   [viewCreatedBy]="viewCreatedBy"
                    [fieldsWithCustomView]="fieldsWithCustomView"
                    [customViewTemplated]="customViewTemplated" [fieldRequiresContinuesChangeDetection]="grid.fieldRequiresContinuesChangeDetection">
  </ctrm-input-form>
  <app-tab-grid *ngIf="switchTabGrid" [onModal]="false"
                [tabs]="grid.getTabs()"></app-tab-grid>
<!--  <app-preference (changeDisplay)="changeDisplay($event)" (onSave)="reloadData($event)" [columns]="columns"-->
<!--                  [gridTitle]="this.grid.getTitle()"-->
<!--                  [display]="display"-->
<!--                  [selectColumn]="selectColumn"-->
<!--                  [gridFilters]="gridFilter"-->
<!--                  [headerColumns]="headerColumns" [primaryKey]="grid.getPrimaryKey()"></app-preference>-->
  <p-confirmDialog [baseZIndex]="25" header="Confirmation"
                   icon="pi pi-exclamation-triangle"></p-confirmDialog>
  <app-tab-grid (onChangeIndex)="throwChangeIndex($event)" (onModalCancel)="cancelTabModal()"
                (onModalClose)="closeTabModal()"
                *ngIf="(grid.getTabs()!==undefined&&showTabs)" [heading]="tabHeading"
                [modalState]="tabModalState" [onModal]="true" [parentKeyValue]="parentKeyValue"
                [tabs]="grid.getTabs()"></app-tab-grid>
  <ctrm-input-form (OnApply)="OnFormClose($event,this.addButtonValue)"
                   (OnCancel)="canceledNew()"
                   (onButtonClick)="onClick($event)"
                   *ngIf="addNewModalState"
                   [closeAfterSave]="true"
                   [columnsMap]="masterCommonService.getGrid(this.addButtonValue).getColumn()"
                   [gridConfig]="masterCommonService.getGrid(this.addButtonValue).getGridConfig()"
                   [gridTitle]="masterCommonService.getGrid(addButtonValue).getTitle()"
                   [heading]="masterCommonService.getGrid(this.addButtonValue).getTitle()"
                   [noOfColumns]="3"
                   [readOnly]="true"
                   [saveUrl]="masterCommonService.getGrid(addButtonValue).getSaveUrl()" [sections]="masterCommonService.getGrid(this.addButtonValue).getSections()"
                   [showOnModal]="true"
                   [uniqueKey]="masterCommonService.getGrid(this.addButtonValue).getPrimaryKey()">
  </ctrm-input-form>
</div>
<ng-template #dropdownfilters>
  <div class="tradeFilter" *ngIf="gridFilterDropdownList.length > 0"> Filter by:
    <div class="dropdownpanel">
      <div  *ngFor="let dropdown of gridFilterDropdownList" style="margin-left: 10px">
        <p-multiSelect *ngIf="(dropdown['type'] === null || dropdown['type']=== undefined || dropdown['type'] === 'L')"
                       (onChange)="onChangeFilter($event,dropdown)" [options]="dropdown['list']" [maxSelectedLabels]="1" [defaultLabel]="dropdown['title']"
                       [(ngModel)]="dropdown['selected']"></p-multiSelect>
        <div *ngIf="dropdown['type'] === 'D'" class="div-control">
          <input #dp="bsDatepicker"
                 (bsValueChange)="onChangeFilter($event,dropdown)"
                 [bsConfig]="{dateInputFormat:(dropdown['format']).toUpperCase(),adaptivePosition:true}"
                 [minMode]="dropdown['minMode']"
                 appendTo="body"
                 autocomplete="off"
                 bsDatepicker class="form-control form-control-sm pickerInput"
                 placeholder="{{dropdown['placeholder']}}"
                 style="border-right: none;min-width: calc(100% - 30px);z-index: 11000 !important;" type="text">
          <div (click)="dp.toggle()" [attr.aria-expanded]="dp.isOpen" class="calIcon calIconEnabled">
            <i class="fas fa-calendar" style="margin-left: 7px;margin-top: 8px"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<app-ctrm-audittrail *ngIf="displayAudit"
                     [heading]="selectedRow[0][grid.getPrimaryKey()]"
                     [entityId]="selectedRow[0][grid.urlIdField]"
                     [entityName]="grid.auditEntityName"
                     [display]="true"
                     (onclose)="closeaudittrail()">
</app-ctrm-audittrail>

<p-overlayPanel #op>
  <ng-template pTemplate >
    <div >
      <div class="filterContext" appendTo="body">
        <div style="width: 100%;display: inline-flex;padding: 10px">
          <p [ngClass]=pDisable(filterSelectCol)
             (click)="triggerAdvancedFilterAPI(filterOnPageChange,true)">Apply</p>
          <p class="cancelBtn" (click)="clearfilter(filterSelectCol['field'])">Clear</p>
        </div>
        <div class=dateCalendar *ngIf="filterSelectCol['columnType'] === 'D';else notDate">
          <bs-datepicker-inline (bsValueChange)="updatevalue($event,filterSelectCol['field'],true)"
                                [bsConfig]="{dateInputFormat : getDateFormat(filterSelectCol)}"></bs-datepicker-inline>
        </div>
        <ng-template #notDate class="style-4 table-responsive">
          <p-listbox [options]="masterCommonService.getListFromArray(getList(filterSelectCol),false,false)"
                     [metaKeySelection]="false"
                     [checkbox]="true" [filter]="true"
                     [multiple]="true" [(ngModel)]="filterValues[filterSelectCol['field']]"
                     [listStyle]="{'max-height':'250px'}" [style]="{'width':'fit-content'}" ></p-listbox>
        </ng-template>
      </div>
    </div>

  </ng-template>
</p-overlayPanel>
