import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TreeNode} from "primeng";

@Component({
  selector: 'ctrm-hierarchy',
  templateUrl: './ctrm-hierarchy.component.html',
  styleUrls: ['./ctrm-hierarchy.component.css']
})
export class CtrmHierarchyComponent implements OnInit, OnChanges {
  @Input() value: TreeNode[];
  @Input() selectedKey: string = '';
  data: TreeNode[];
  isScrolled: boolean = false;

  constructor() {
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  isSame(label: string) {
    if (this.selectedKey === label) {
      if (!this.isScrolled) {
        document.getElementById(label).scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
        this.isScrolled = true;
      }
      return true;
    }
    return false;
  }
}
