import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {CommonService} from '../common.service';
import {MessageService} from 'primeng/api';
import {messages} from "../common/messages";

@Injectable({
  providedIn: 'root'
})
export class RbacService implements CanActivate {

  readAccessRouter: string[] = [];

  constructor(public commonService: CommonService, private messageService: MessageService) {


  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.readAccessRouter = this.commonService.getFromStorage('readAccessRouter');
    var url = "/" + route.url.toString();

    if (this.readAccessRouter.includes(url)) {
      return true;
    } else {
      this.messageService.add({
        severity: 'info', summary: messages.save_all['summary'],
        detail: messages.save_all['message']
      });
      this.commonService.postMessage('Access Not Allowed', messages.access_not_allowed['message']);
    }
    return false;
  }
}
