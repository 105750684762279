import {Component, Input, OnInit, ViewChildren} from '@angular/core';
import {Tcolumn} from "../../grid/tcolumn.model";
import {Grid} from "../../grid/grid.model";

import {CommonService} from "../../services/common.service";
import {MasterService} from "../services/MasterService";
import {environment} from "../../../environments/environment";
import {Tab} from "../../tab-grid/Tab.model";
import {ChildAction} from "../../grid/ChildAction.model";
import {repos} from "../../services/common/entities";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Action} from "../../grid/actions.model";
import {DatePipe} from "@angular/common";
import {FormControlServiceService} from "../../services/form-control-service.service";
import {ActivatedRoute} from "@angular/router";
import {MessageService} from "primeng";
import {HttpErrorResponse} from "@angular/common/http";
import {KeyValue} from "../../grid/key-value.model";

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.css']
})
export class NotificationSettingsComponent implements OnInit {
  columns: Map<string, Tcolumn>;
  grid: Grid;
  refreshGrid: boolean = false;
  displayOnAdd: boolean = false;
  includedCharacters: any[] = ['_', '/', ' '];


  @Input() selectedRowData: any;
  values: any[] = [];
  channelList:any[] = [];
  recipientList:any[] = [];
  phonedisable:boolean = true;
  emaildisable:boolean = true
  saveButtonLabel: string = 'Save';
  notificationForm: FormGroup;
  validationSections:any = {};
  private uuid: any='';
  isUpdate:boolean = false;
  roleList: any[] = [];



  header: any = 'New Notification Settings';
  private formControlService:FormControlServiceService = new FormControlServiceService();
  loadTableValid:boolean = false;

  constructor(private masterCommonService: MasterService, public commonService: CommonService, private router: ActivatedRoute,
              private formBuilder: FormBuilder, private messageService: MessageService) {
    this.commonService.getFromStorage('policy');
  }

  ngOnInit(): void {
    this.getRolesList();
    this.channelList = [
      new KeyValue('SMS','sms'),
      new KeyValue('Email','email'),
      new KeyValue('Notification','notification')
    ];
    this.recipientList = [
      new KeyValue('Individual','individual'),
      new KeyValue('Trader','trader'),
      new KeyValue('Created By','createdby'),
      new KeyValue('Modified By','modifiedby'),
      new KeyValue('Role','role'),
    ];



    this.getColumns();
    this.grid = new Grid(this.columns, true, true, true, true, 'notidesp', '/api/notification/v1/getNotificationService?tenantId=' + this.commonService.getFromStorage('tenantId'), '', 'Notification Settings', undefined, undefined);
    // this.grid.importUrl = environment.base_url +'/api/storageInventory/v1/importstorageinventory?tenantId='+this.commonService.getFromStorage('tenantId');
    this.grid.urlIdField = 'uuid';
    this.grid.onEditViewThrowUnique = true;
    this.grid.openSidebarOnUniqueClick = false;
    this.grid.openDefaultInputForm = false;
    this.grid.iconName = 'Inventory';
    this.grid.setGridConfiguration(true, true, false, false);
    this.initInternalForm();
    this.loadValidationSection();

  }

  changechannel(value){
    var str_array =this.formControlService.getValue('channel').split(',');

    str_array.forEach(data =>{
      if(data === 'sms'){
        this.formControlService.enable('phone');
        this.formControlService.updateValidators('phone',[Validators.required])
        this.formControlService.disable('subjectLine');
      }else if(data === 'email'){
        this.formControlService.updateValidators('email',[Validators.required])
        this.formControlService.updateValidators('subjectLine',[Validators.required])
        this.formControlService.enable('email');
        this.formControlService.enable('subjectLine');
      }else if(data === 'notification'){
        this.formControlService.updateValidators('phone',[Validators.required])
        this.formControlService.updateValidators('email',[Validators.required])
        this.formControlService.enable('phone');
        this.formControlService.enable('email');
      }else{
        this.formControlService.updateValidators('phone',null)
        this.formControlService.updateValidators('email',null)
        this.formControlService.disable('phone');
        this.formControlService.disable('email');
        this.formControlService.disable('subjectLine');
      }
    });
  }
  changerecipienttype(value:any){
    this.formControlService.getValue('recipientType')

    if(this.formControlService.getValue('recipientType') === null){
      return;
    }
    var str_array = this.formControlService.getValue('recipientType').split(',');

    str_array.forEach(data =>{
      if(data === 'role'){
        this.formControlService.enable('role')
        this.formControlService.updateValidators('role',[Validators.required])
      }else{
        this.formControlService.disable('role')
        this.formControlService.updateValidators('role',null)
      }
    });


  }

  getColumns() {
    this.columns = new Map();
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    /***** Section 1 : Basic Details **********/
    this.columns.set('description', new Tcolumn('description', 'Description', 'T', 1, true, undefined, true, '', 'B'));
    this.columns.set('notificationOption', new Tcolumn('notificationOption', 'Channel', 'T', 3, true, undefined, true, '', 'B'));
    this.columns.set('recipientType', new Tcolumn('recipientType', 'Recipient Type', 'T', 3, true, undefined, true, '', 'B'));
    this.columns.set('emailId', new Tcolumn('emailId', 'Email', 'T', 3, true, undefined, true, '', 'B'));
    this.columns.set('phone', new Tcolumn('phone', 'Phone', 'T', 3, true, undefined, true, '', 'B'));
    this.columns.set('role', new Tcolumn('role', 'Role', 'L', 3, true, undefined,true, '', 'B'));
    this.columns.set('bodyText', new Tcolumn('bodyText', 'Notification Message', 'T', 3, true, undefined, true, '', 'B'));
    this.columns.set('subject', new Tcolumn('subject', 'Subject Line', 'T', 3, true, undefined, true, '', 'B'));
  }

  private loadValidationSection() {
    this.validationSections = {};
    this.validationSections['Notification Configuration'] = [
      {field:'description',header:'Description'},
      {field:'channel',header:'Channel'},
      {field:'recipientType',header:'Recipient Type'},
      {field:'email',header:'Email'},
      {field:'phone',header:'Phone'},
      {field:'role',header:'Role'},
      {field:'notificationdescription',header:'Description'},
      {field:'subjectLine',header:'Subject Line'},
      {field:'query',header:'XQL (Optional Type in)'}
    ];

  }

  getRolesList() {
    this.roleList = this.masterCommonService.getListFromUrl('/api/rolemaster/v1/getallactiveroles?tenantId=' + this.commonService.getFromStorage('tenantId'), 'roleName', 'roleName', false, true, undefined, 'Select', '', environment.base_url_new + '/api-iam');
  }

  initInternalForm() {
    this.notificationForm = this.formControlService.addControl('description','', [Validators.required])
        .addControl('channel','',[Validators.required])
        .addControl('recipientType','',[Validators.required])
        .addControl('email','',[],true)
        .addControl('phone','',[Validators.maxLength(10)],true)
        .addControl('role','',[], true)
        .addControl('notificationdescription','')
        .addControl('subjectLine','')
        .addControl('query','')
        .build();
    this.createValidationSection();
  }

  private createValidationSection() {
    this.validationSections = {};
    this.validationSections['Notification'] = [
      {field:'description',header:'Description'},
      {field:'channel',header:'Channel'},
      {field:'recipientType',header:'Recipient Type'},
      {field:'email',header:'Email'},
      {field:'phone',header:'Phone'},
      {field:'role',header:'Role'},
      {field:'subjectLine',header:'Subject Line'},
    ];
  }



//On Click of Add button
  onClickAdd() {
    this.formControlService.reset();
    this.values = [];
    this.refreshGrid = false;
    this.displayOnAdd = true;
    this.formControlService.disable('phone');
    this.formControlService.disable('email');
    this.formControlService.disable('role');
  }



  onSave(value:any) {
    let _this = this;
    this.saveButtonLabel = 'Save';
    _this.displayOnAdd = false;
    var mquery = value['query'];
    let res = mquery.search(/where/i);
    var condition = '';
    mquery = mquery.replace(";", "");
    if(res === -1){
      condition = `${mquery} where tenant_id = "${this.commonService.getFromStorage('tenantId')}";`;
    }else{
      condition = `${mquery} and tenant_id = "${this.commonService.getFromStorage('tenantId')}";`;
    }

    let notificationpayload: {};
    notificationpayload = {
      tenantId:this.commonService.getFromStorage('tenantId'),
      createdBy:this.commonService.getFromStorage('userName'),
      description: value['description'],
      notificationOption: value['channel'],
      recipientType: value['recipientType'],
      emailId: value['email'],
      phone: value['phone'],
      role: value['role'],
      bodyText:value['notificationdescription'],
      query: condition,
      subject: value['subjectLine'],
      uuid:this.uuid,

    };
    let url = '';


    if(this.isUpdate === true) {
      url = environment.base_url + '/api/notification/v1/updatenotificationservice?tenantId=' + this.commonService.getFromStorage('tenantId');
      this.commonService.post(url, notificationpayload).subscribe(next => {
        _this.refreshGrid = true;
        _this.showToast('Row Successfully Updated.');
      }, (error: HttpErrorResponse) => {
        this.messageService.add({severity: 'error', summary: this.commonService.getHttpErrorMessage(error, undefined)});
      });
    } else {
      url = environment.base_url + '/api/notification/v1/savenotificationservice?tenantId=' + this.commonService.getFromStorage('tenantId');
      this.commonService.post(url, notificationpayload).subscribe(next => {
        _this.refreshGrid = true;

        _this.showToast('Row Successfully Saved.');
      }, (error: HttpErrorResponse) => {
        this.messageService.add({severity: 'error', summary: this.commonService.getHttpErrorMessage(error, undefined)});
      });
    }
  }

  showToast(msg, severity: string = 'success') {
    this.messageService.add({
      severity: 'info',
      summary: msg
    });
  }

  onClose() {
    this.displayOnAdd = false
    this.refreshGrid = true;
    this.values = [];
    // this.notificationForm.reset();
    this.formControlService.reset();
    this.notificationForm = this.formControlService.build();

  }

  onClickUnique(value: any) {
    let _this = this;
    this.selectedRowData = value['rowData'];
    this.onClickAdd();
    this.saveButtonLabel='Update'
    this.isUpdate = true;
    this.uuid= value['rowData']['uuid'];
    this.displayOnAdd = true;
    this.refreshGrid = false;
    // return;
    this.commonService.getJSONByURL(environment.base_url + '/api/notification/v1/getnotificationservicebyuuid?tenantId='
        + this.commonService.getFromStorage('tenantId') + '&uuid=' + value['rowData']['uuid']).subscribe((next: any) => {

      this.formControlService.setValue('description',next['description']);
      this.formControlService.setValue('channel',next['notificationOption']);
      this.formControlService.setValue('recipientType',next['recipientType']);
      this.formControlService.setValue('email',next['emailId']);
      this.formControlService.setValue('phone',next['phone']);
      this.formControlService.setValue('role',next['role']);
      this.formControlService.setValue('notificationdescription',next['bodyText']);
      this.formControlService.setValue('subjectLine',next['subject']);
      this.formControlService.setValue('query',next['query']);
      if(next['role']){
        this.formControlService.enable('role');
      }else{
        this.formControlService.disable('role');
      }
      var str_array = next['notificationOption'].split(',');

      str_array.forEach(data =>{
        if(data === 'sms'){
          this.formControlService.enable('phone');
        }else if(data === 'email'){

          this.formControlService.enable('email');
        }else if(data === 'notification'){
          this.formControlService.enable('phone');
          this.formControlService.enable('email');
        }else{
          this.formControlService.disable('phone');
          this.formControlService.disable('email');
        }
      });

    });
  }
}

