import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterPipe'
})
export class FilterPipePipe implements PipeTransform {

  transform(value: any[],args?: any,fieldname?:any): any[] {
    if(!value)return null;
    args = args.toLowerCase();
    if(args === null || args === undefined || args.length === 0) {
      return [];
    }
    return value.filter(function(item){
      return  (fieldname !== null && fieldname !== undefined && fieldname.length > 0)?item[fieldname].toLowerCase().includes(args):JSON.stringify(item).toLowerCase().includes(args);
    }).map(function(item){
      return {label:item,value:item};
    });
  }

}
