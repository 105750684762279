import {Directive, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {AccessPolicyService} from "../accesspolicy.service";

@Directive({
  selector: '[access]'
})
export class AccessDirective implements OnChanges{

  @Input('access') public access:string;
  @Input('action') action:string;
  @Input('entity') entity:{} = {};
  @Output() onAccessGranted = new EventEmitter();
  @Output() onAccessRejected = new EventEmitter();
  private element:ElementRef;

  constructor(private el:ElementRef,private accessService:AccessPolicyService) {
    this.element = el;
  }

  private setDisabled(newState:boolean) {
    this.element.nativeElement.disabled = newState;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(!this.isNull(this.access) && !this.isNull(this.action)) {
      if(this.accessService.canAccessWithCurrentRoute(this.access,this.action)) {
        this.setDisabled(false);
        this.onAccessGranted.emit({route:this.accessService.currentRoute,access:this.access,action:this.action});
        return;
      } else {
        this.setDisabled(true);
        this.onAccessRejected.emit({route:this.accessService.currentRoute,access:this.access,action:this.action});
      }
    }
  }

  private isNull(value:string):boolean {
    return (value === null || value ===undefined || value.length === 0);
  }

}
