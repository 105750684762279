import {Component, OnInit} from '@angular/core';
import {Grid} from '../../grid/grid.model';
import {Tcolumn} from '../../grid/tcolumn.model';
import {KeyValue} from '../../grid/key-value.model';
import {Breadcrumb} from '../../Components/ctrm-breadcrumb/breadcrumb.model';
import {Validators} from '@angular/forms';
import {defaultValue} from '../../grid/defaultValue.model';
import {MasterService} from '../services/MasterService';
import {ChildAction} from "../../grid/ChildAction.model";
import {CommonService} from "../../services/common.service";
import {entities} from "../../services/common/entities";
import {environment} from "../../../environments/environment";
import {Tab} from "../../tab-grid/Tab.model";

@Component({
  selector: 'app-global-indicators',
  templateUrl: './global-indicators.component.html',
  styleUrls: ['./global-indicators.component.css']
})
export class GlobalIndicatorsComponent implements OnInit {
  grid: Grid;
  fields: any = [];
  columns: Map<string, Tcolumn>;
  groupList: KeyValue[] = [];
  breadcrumb: Breadcrumb;
  tabs: Tab[] = [];
  currentHref: string = '';
  groupName: string = '';

  constructor(public commonService: CommonService, private masterCommonService: MasterService) {
  }

  ngOnInit() {
    let _this = this;
    this.getColumns();
    let copy = new ChildAction('groupName');
    copy.addRepo(entities.globalIndicatorDetails, 'globalIndicatorDetailRepo');
    this.tabs.push(this.getGlobalIndicatorDetails());
    this.grid = new Grid(this.columns, true, true, true, true, 'groupName', '/api/globalindicator/v1/getall?tenantId='+this.commonService.getFromStorage('tenantId'), '', 'Global Indicators', '');
    this.grid.setSaveUrl(environment.base_url + '/api/globalindicator/v1/save');
    this.grid.setUpdateUrl(environment.base_url + '/api/globalindicator/v1/update');
    this.grid.hrefUrl = environment.base_url + '';
    this.grid.defineHrefFunction = function(value:any) {
      return _this.grid.hrefUrl = environment.base_url +  '/api/globalindicator/v1/getbyuuid?tenantId='+ _this.commonService.getFromStorage('tenantId')+'&groupName=' + value['groupName'];
    }
    this.grid.importUrl = environment.base_url + '/api/globalindicator/v1/import';
    this.grid.iconName= 'globalIndicator';
    this.grid.gridAdvacedFilterURL='/api/globalindicator/v1/getglobalindicatorbycriteria?tenantId='+ this.commonService.getFromStorage('tenantId');
    this.grid.setChildCopyDelete(copy);
    this.grid.setTabs(this.tabs);
    this.grid.setGridConfiguration(false, false, false, false,false);
    this.grid.addChildTabs(this.getGlobalIndicatorDetails());
    this.grid.urlIdField = 'groupName';
  }

  public getColumns() {
    this.columns = new Map();
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('groupName', new Tcolumn('groupName', 'Global Indicator Name', 'T', 0, false, undefined, true, new defaultValue('', '', ['-', '_'],undefined,false,true,false,undefined,undefined,false), 'B', [Validators.required, Validators.maxLength(56)], undefined, undefined, undefined, undefined, [], undefined, undefined, true));
    this.columns.set('description', new Tcolumn('description', 'Global Indicator Description', 'TA', 2, true, undefined, true, '', 'B', [Validators.maxLength(256)]));
    this.columns.set('indicatorType', new Tcolumn('indicatorType', 'Indicator Type', 'L', 3, true, this.getIndicatorType(), true, 0, 'B', [Validators.required]));
    this.columns.set('status', new Tcolumn('status', 'Status', 'B', 4, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));
  }

  private getIndicatorType() {
    let list = [];
    list.push(new KeyValue('Select', ''));
    list.push(new KeyValue('System', 0));
    list.push(new KeyValue('User', 1));
    return list;
  }

  private getGlobalIndicatorDetails(){
    let _this = this;
    let columns: Map<string, Tcolumn> = new Map();
    columns.set('groupName', new Tcolumn('groupName', 'Group Name', 'LB', 1, false, undefined, false,'', 'B', [Validators.required]));
    columns.set('name', new Tcolumn('name', 'Global Indicator Details Name', 'T', 2, false, undefined, true, new defaultValue('', '', ['-', ' ', '_', '\\','/', '(', ')', '.',  '"',"'",'%'],undefined,false,true,false,undefined,undefined,false), 'B', [Validators.required, Validators.maxLength(100)]));
    columns.set('description', new Tcolumn('description', 'Global Indicator Details Description', 'TA', 3, true, undefined, true, new defaultValue('', '', ['-', ' ', '_', '\\', '/','(', ')', '.',  '"',"'",'%']), 'B', [Validators.maxLength(254)]));
    columns.set('status', new Tcolumn('status', 'Status', 'B', 4, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));
    let grid = new Grid(columns, true, true, true, true, 'name', '/api/globalindicatordetails/v1/getbygroupname?tenantId='+this.commonService.getFromStorage('tenantId')+'&groupName=', '', 'Global Indicator Details', 'groupName','', undefined, undefined, false);
    grid.setSaveUrl(environment.base_url + '/api/globalindicatordetails/v1/save');
    grid.setUpdateUrl(environment.base_url + '/api/globalindicatordetails/v1/update');
    grid.urlIdField = "groupName";
    grid.hrefUrl = environment.base_url + '';
    grid.defineHrefFunction = function(value:any) {
      return grid.hrefUrl = environment.base_url +  '/api/globalindicatordetails/v1/getbyuuid?tenantId='+ _this.commonService.getFromStorage('tenantId')+'&name=' + value['name'];
    }
    grid.importUrl = environment.base_url + '/api/globalindicatordetails/v1/import';
    let tab = new Tab(grid.getTitle(), grid, 'groupName');
    grid.setGridConfiguration(false, false, false, false,false);
    tab.idFieldValueName = 'groupName';
    return tab;
  }
}
