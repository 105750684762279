import {Component, Input, OnInit} from '@angular/core';
import {Tcolumn} from 'src/app/grid/tcolumn.model';
import {Grid} from 'src/app/grid/grid.model';
import {KeyValue} from 'src/app/grid/key-value.model';
import {CommonService} from '../../services/common.service';
import {Validators} from '@angular/forms';
import {defaultValue} from "../../grid/defaultValue.model";
import {MasterService} from "../services/MasterService";
import {Action} from "../../grid/actions.model";
import {environment} from '../../../environments/environment';


@Component({
  selector: 'app-usermaster',
  templateUrl: './usermaster.component.html'

})
export class UsermasterComponent implements OnInit {

  columns: Map<string, Tcolumn>;
  grid: Grid;
  groupList: KeyValue[] = [];
  passwordGenerator: Function;
  display: boolean = false;
  data: any;
  gridRefresh: boolean = false;
  editAllowed: boolean = true;
  roleList: any[] = [];
  loadDatalist:any[] = [];
  @Input() screentype: string = null;
  products: any[] = [];

  constructor(private masterCommonService: MasterService, public commonService: CommonService) {
  }

  ngOnInit() {
    let _this = this;
    this.products.push({label: 'Xceler', value: 'xceler', default: true});
    this.products.push({label: 'Treasury', value: 'treasury', default: false});
    this.products.push({label: 'O.I.S', value: 'ois', default: false});
    this.passwordGenerator = function randomPasswordGenerator(value: any = []) {
      let randomPass = Math.random().toString(36).slice(-8);
      return randomPass;

    };
    this.getColumns();
    this.grid = new Grid(this.columns, true, true, true, true, 'userName', '/api/usermanagementservice/v1/getallusers?tenantId=' + this.commonService.getFromStorage('tenantId'), '', 'User Master', undefined, undefined);
    this.grid.addClickable('userName');
    this.grid.setSaveUrl(environment.base_url_new + '/api-iam/api/usermanagementservice/v1/createuser');
    this.grid.setUpdateUrl(environment.base_url_new + '/api-iam/api/usermanagementservice/v1/update');
    this.grid.hrefUrl = environment.base_url_new + '/api-iam/api/usermanagementservice/v1/getbyuuid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&uuid=';
    this.grid.iconName = 'userMaster';
    this.grid.openSidebarOnUniqueClick = false;
    this.grid.baseUrl = environment.base_url_new + '/api-iam';
    this.grid.auditEntityName = 'UserMaster';
    this.grid.addSection('Personal Information', ['userName', 'firstName', 'middleName', 'lastName', 'emailAddress', 'language']);
    this.grid.addSection('Contact Information', ['country', 'city', 'timeZone', 'address', 'telephone', 'fax']);
    this.grid.addSection('Access & Security Information', ['role', 'userType', 'productSubscription', 'password', 'userMasterStatus', 'passwordExpiryDate', 'isLock', 'startDate', 'endDate',]);
    this.grid.importUrl = environment.base_url_new + '/api-iam/api/usermanagementservice/v1/import';
    this.getAllActiveUser();
    this.commonService.getLoadData('commodity', ['data', 'data'], [[{key: 'name', value: 'job_title_ind'}], [{
      key: 'name',
      value: 'supported_lang_ind'
    }]]).subscribe((next: any) => {
      _this.loadDatalist = next;
      _this.grid.getColumn().get('userType').setListofValues(_this.masterCommonService.getListFromArray(next['job_title_ind']));
      _this.grid.getColumn().get('language').setListofValues(_this.masterCommonService.getListFromArray(next['supported_lang_ind']));
    });

  }

  public getColumns() {
    let action: Action = new Action();
    action.addFieldToUpdate('password', '', this.passwordGenerator, undefined, undefined, true);
    let actionStartDate = new Action();
    actionStartDate.addFieldToUpdate('endDate', undefined, undefined, undefined, undefined, true, 'minDate');
    let startDate = function minVali(value: any[] = []) {
      let obj: any = {meta: {disabled: true}};
      if (value[0] === true) {
        obj = {meta: {disabled: false}};
      }
      return obj;
    };
    let actionlock = new Action();
    actionlock.addFieldToUpdate('startDate', undefined, startDate, undefined, undefined, false, 'extra');
    actionlock.addFieldToUpdate('endDate', undefined, startDate, undefined, undefined, false, 'extra');
    let _this = this;
    let countryFunc = function (value: string[] = []) {
      let countryCode = value[0];
      let country = _this.commonService.getFromCountryMap(countryCode);
      return country['callingCodes'][0];
    };
    let countryExtraFunc = function (value: string[] = []) {
      let countryCode = value[0];
      let country = _this.commonService.getFromCountryMap(countryCode);
      let extra = _this.grid.getColumn().get('telephone').getExtra();
      extra['selectedCountry'] = 'in';
      if (_this.commonService.getFromCountryMap(_this.commonService.getFromStorage('country')) !== undefined) {
        extra['selectedCountry'] = _this.commonService.getFromCountryMap(_this.commonService.getFromStorage('country'))['alpha2Code'];
      }
      if (country !== undefined) {
        extra['selectedCountry'] = country['alpha2Code'];
      }
      return extra;
    };
    let faxExtraFunc = function (value: string[] = []) {
      let countryCode = value[0];
      let country = _this.commonService.getFromCountryMap(countryCode);
      let extra = _this.grid.getColumn().get('fax').getExtra();
      extra['selectedCountry'] = 'in';
      if (_this.commonService.getFromCountryMap(_this.commonService.getFromStorage('country')) !== undefined) {
        extra['selectedCountry'] = _this.commonService.getFromCountryMap(_this.commonService.getFromStorage('country'))['alpha2Code'];
      }
      if (country !== undefined) {
        extra['selectedCountry'] = country['alpha2Code'];
      }
      return extra;
    };
    let actionCountry = new Action();
    actionCountry.addFieldToUpdate('telephone', undefined, countryExtraFunc, undefined, undefined, true, 'extra');
    actionCountry.addFieldToUpdate('fax', undefined, faxExtraFunc, undefined, undefined, true, 'extra');
    this.columns = new Map();
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('userName', new Tcolumn('userName', 'User Name', 'T', 1, false, undefined, true, new defaultValue('', '', []), 'B', [Validators.required, Validators.maxLength(16)]));
    this.columns.set('firstName', new Tcolumn('firstName', 'First  Name', 'T', 2, true, undefined, true, new defaultValue('', '', [" "], undefined, false, false), 'B', [Validators.required, Validators.maxLength(128)]));
    this.columns.set('middleName', new Tcolumn('middleName', 'Middle Name', 'T', 2, true, undefined, true, new defaultValue('', '', [" "], undefined, false, false), 'B', [Validators.maxLength(128)]));
    this.columns.set('lastName', new Tcolumn('lastName', 'Last Name', 'T', 3, true, undefined, true, new defaultValue('', '', [" "], undefined, false, false), 'B', [Validators.required, Validators.maxLength(128)]));
    this.columns.set('emailAddress', new Tcolumn('emailAddress', 'Email', 'T', 4, true, undefined, true, '', 'B', [Validators.required, Validators.maxLength(128), Validators.email, Validators.pattern('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$')]));
    this.columns.set('language', new Tcolumn('language', 'Language', 'L', 6, true, this.masterCommonService.getListFromArray(this.loadDatalist['supported_lang_ind']), true, 'english', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('country', new Tcolumn('country', 'Country', 'L', 7, true, this.commonService.getCountryList(), true, this.commonService.getFromStorage('country'), 'B', [Validators.required, Validators.maxLength(56)], undefined, undefined, undefined, undefined, undefined, actionCountry));
    this.columns.set('city', new Tcolumn('city', 'City', 'T', 8, true, undefined, true, '', 'B', [Validators.maxLength(128)]));
    this.columns.set('timeZone', new Tcolumn('timeZone', 'Time Zone', 'L', 9, true, this.commonService.getTimeZoneList(), true, '', 'B', [Validators.maxLength(256)]));
    this.columns.set('userMasterStatus', new Tcolumn('userMasterStatus', 'Status', 'B', 10, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required], undefined, 2));
    this.columns.set('address', new Tcolumn('address', 'Address', 'TA', 11, true, undefined, true, '', 'B', [Validators.maxLength(512)]));
    this.columns.set('telephone', new Tcolumn('telephone', 'Telephone', 'TN', 12, true, undefined, true, '', 'B', []));
    this.columns.set('fax', new Tcolumn('fax', 'Fax', 'TN', 13, true, undefined, true, '', 'B', [Validators.maxLength(56)]));
    this.columns.set('password', new Tcolumn('password', 'Password', 'P', 14, true, undefined, true, new defaultValue('', undefined, undefined, undefined, undefined, true, true), 'B', [Validators.required,Validators.minLength(6), Validators.maxLength(56)], 'password', 1, undefined, undefined, undefined, action));
    this.columns.set('passwordExpiryDate', new Tcolumn('passwordExpiryDate', 'Password Expiry Date', 'D', 15, true, undefined, true, '', 'B', [Validators.required], undefined, undefined, new Date()));
    this.columns.set('role', new Tcolumn('role', 'Role', 'ML', 16, true, this.roleList, true, '', 'B', [Validators.required], undefined, 1));
    this.columns.set('userType', new Tcolumn('userType', 'User Type', 'L', 17, true, [], true, '', 'B', [Validators.required]));
    this.columns.set('productSubscription', new Tcolumn('productSubscription', 'Product Subscription', 'CUSTOM', 18, true, [], true, '{"selected":["treasury","ois","xceler"],"default":"xceler"}', 'B', [Validators.required]));
    this.columns.set('isLock', new Tcolumn('isLock', 'Block Login', 'B', 19, true, this.masterCommonService.getYesNo(), true, false, 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, actionlock));
    this.columns.set('startDate', new Tcolumn('startDate', 'Start Date', 'D', 20, true, undefined, true, '', 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, actionStartDate, {meta: {disabled: true}}));
    this.columns.set('endDate', new Tcolumn('endDate', 'End Date', 'D', 21, true, undefined, true, '', 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
  }

  openSidebar(info) {
    this.gridRefresh = false;
    if (!info['editMode']) {
      this.data = info.rowData;
      this.display = true;
    }
  }


  changeDisplay() {
    this.display = false;
  }

  oninfoUpdate(value) {
    this.gridRefresh = true;
  }

  editUpdate(edit: boolean) {
    this.editAllowed = edit;
  }

  getAllActiveUser() {
    let _this = this;
    _this.commonService.getJSONByURL(environment.base_url_new + '/api-iam/api/rolemaster/v1/getallactiveroles?tenantId=' + this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
      _this.grid.getColumn().get('role').setListofValues(_this.commonService.createListFromObject(next, 'roleName', 'roleName'));
    });
  }

  getProductSubText(value) {
    if (value !== null && value !== undefined && value.length > 0) {
      let json = JSON.parse(value);
      let str = json['selected'].join(', ');
      str = str.replace(json['default'], '<u>' + json['default'] + '</u>');
      return str;
    }
    return '-';
  }
}
