import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Action} from '../../grid/actions.model';
import {ChildAction} from '../../grid/ChildAction.model';
import {Grid} from '../../grid/grid.model';
import {Tcolumn} from '../../grid/tcolumn.model';
import {FormBuilder, Validators} from '@angular/forms';
import {Tab} from '../../tab-grid/Tab.model';
import {environment} from '../../../environments/environment';
import {defaultValue} from '../../grid/defaultValue.model';
import {KeyValue} from '../../grid/key-value.model';
import {CommodityComponent} from '../commodity/commodity.component';
import {CommonService} from '../../services/common.service';
import {MasterService} from '../services/MasterService';
import {TabList} from '../../tab-grid/TabList.model';
import {repos} from '../../services/common/entities';
import {MessageService} from 'primeng';
import {Filter} from '../../grid/preference/filter/filter.model';
import {ActivatedRoute} from '@angular/router';
import {Button} from '../../grid/buttons.model';
import {HttpErrorResponse} from '@angular/common/http';
import {ctrmValidators} from '../../services/validators/ctrmValidators';


@Component({
  selector: 'app-counterparty',
  templateUrl: './counterparty.component.html',
  styleUrls: ['./counterparty.component.css']
})
export class CounterpartyComponent implements OnInit {


  columns: Map<string, Tcolumn>;
  grid: Grid;
  openFormForNewEntry:boolean = false;
  groupList: KeyValue[] = [];
  tabs: Tab[] = [];
  yeartfun: Function;
  monthto: Function;
  tablist: TabList = new TabList();
  currentHref: string = '';
  refreshGrid: boolean = false;
  modalStateHierarchy: boolean = false;
  @ViewChild('testTemplate', { read: TemplateRef }) testTemplate:TemplateRef<any>;

  selectedRowData: any;
  priceIndexList: any[];
  openTabScreen:boolean = false;
  tabRow:any = null;
  loadDataList:any[] = [];
  financingAgentList = [];
  bankAccount:Tab;
  incidentReport:Tab;
  templateTab:Tab;
  address:Tab;
  contact:Tab;
  payment:Tab;
  filterValue: any;
  selectedTab:string = '';
  popupDisplay: boolean = false;
  selectedRow:any;
  auditdata: any[] = [];
  visible: boolean = false;



  constructor(public commonService: CommonService, public masterCommonService: MasterService,
              public messageService: MessageService, private route: ActivatedRoute, public formBuilder: FormBuilder,
              ) {
  }

  ngOnInit() {
    this.commonService.getLoadData('counterparty', ['config', 'config', 'config', 'data', 'data', 'data', 'data', 'data', 'data'], ['commodity', 'incoterm', 'paymentterm', [{
      key: 'name',
      value: 'payment_method_ind'
    }], [{key: 'name', value: 'transaction_type_ind'}], [{key: 'name', value: 'trade_type_ind'}], [{
      key: 'name',
      value: 'address_type_ind'
    }], [{key: 'name', value: 'contact_type_ind'}], [{
      key: 'name',
      value: 'company_type_ind'
    }]]).subscribe((next: any) => {
      this.loadDataList = next;
      this.initiateTabs();
      this.initfunctions();
      this.getColumns();
      let copy = new ChildAction('counterpartyUuid');
      copy.addRepo('xceler_configservice_counterpartyattachments', repos.counterpartyAttachments);
      copy.addRepo('xceler_configservice_counterpartypaymentterm', repos.counterpartyPaymentTerm);
      copy.addRepo('xceler_configservice_counterpartycommodity', repos.counterpartyCommodity);
      copy.addRepo('xceler_configservice_counterpartybankaccount', repos.counterpartyBankAccount);
      copy.addRepo('xceler_configservice_counterpartyaddressdetails', repos.counterpartyAddressDetails);
      copy.addRepo('xceler_configservice_counterpartycontactdetails', repos.counterpartyContactDetails);
      copy.setPrimaryKeyCode('uuid');
      this.grid = new Grid(this.columns, true, true, true, true, 'name', '/api/counterparty/v1/getall?tenantId=' + this.commonService.getFromStorage('tenantId'), '', 'Counterparty', '', '', undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true);
      this.grid.setSaveUrl(environment.base_url + '/api/counterparty/v1/savecounterparty');
      this.grid.setUpdateUrl(environment.base_url + '/api/counterparty/v1/update');
      this.grid.importUrl = environment.base_url + '/api/counterparty/v1/import';
      this.grid.hrefUrl = environment.base_url + '/api/counterparty/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
      this.grid.setChildCopyDelete(copy);
      this.grid.setTabs(this.tabs);
      this.grid.onEditViewThrowUnique = true;
      this.grid.iconName = 'counterparty';
      this.grid.addChildTabs(this.payment);
      this.grid.isHaveExtraButtons =true;
      this.grid.addChildTabs(this.bankAccount);
      this.grid.addChildTabs(this.templateTab);
      this.grid.addChildTabs(this.getCommodities());
      this.grid.addChildTabs(this.address);
      this.grid.addChildTabs(this.contact);
      this.grid.showExtraButtons=false;
      this.grid.auditEntityName = "Counterparty";
      this.grid.addChildTabs(this.getBrokerageExchange('Clearing Broker', true));
      this.grid.addChildTabs(this.getBrokerageExchange('Execution Broker', true));
      this.grid.gridAdvacedFilterURL = '/api/counterparty/v1/getcounterpartybycriteria?tenantId=' + this.commonService.getTenantId();
      this.applyNotifFilter();
     // this.contextmenu();
      this.grid.addSection('Counterparty Detail', ['code','name', 'counterpartyTypeCode', 'description', 'parent','countryOfRegistration','timeZone','dayLightSaving','currency','website','telephone','fax',
        'address','logo','financingAgent','financeControllerApproval','isTradeable','status','kycStatus','logoFileName','logoFileType']);
    });
  }

  private initiateTabs() {
    this.templateTab = this.getTestTemplate();
    this.bankAccount = this.getBankAccounts();
    this.address = this.getAddressDetails();
    this.contact = this.getContactDetails();
    this.payment = this.getPaymentTerm();
    this.incidentReport = this.getIncidentReport();
  }

  public getTestTemplate() {
    let tab = new Tab('KYC', null, 'name');
    tab.setTabTemplate(this.testTemplate);
    return tab;
  }

  public getBankAccounts() {
    let columns: Map<string, Tcolumn> = new Map();
    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    columns.set('name', new Tcolumn('name', 'Counterparty Name', 'LB', 1, false, undefined, false, '', 'B', [Validators.required]));
    columns.set('countryOfBank', new Tcolumn('countryOfBank', 'Country of Bank', 'L', 3, true, this.commonService.getCountryList(), true, '', 'B', [Validators.required, Validators.maxLength(56)],undefined,undefined,undefined,undefined,undefined));
    columns.set('bankName', new Tcolumn('bankName', 'Bank Name', 'T', 3, true, undefined, true, '', 'B', [Validators.required, Validators.maxLength(128)]));
    columns.set('bankAddress', new Tcolumn('bankAddress', 'Bank Address', 'TA', 4, true, undefined, true, '', 'B', [Validators.required, Validators.maxLength(254)]));
    columns.set('accountNumber', new Tcolumn('accountNumber', 'Account Number / IBAN', 'N', 1, false, undefined, true, '', 'B', [Validators.required, Validators.maxLength(35)]));
    columns.set('accountCurrency', new Tcolumn('accountCurrency', 'Account Currency', 'L', 7, true, this.commonService.getCurrencyList(), true, '', 'B', [Validators.required, Validators.maxLength(128)]));
    columns.set('swiftCode', new Tcolumn('swiftCode', 'Swift / ABA / Sort code', 'T', 9, true, undefined, true, new defaultValue('', undefined, ['']), 'B', [Validators.maxLength(15)]));
    columns.set('paymentMethod', new Tcolumn('paymentMethod', 'Payment Method', 'ML', 12, true, this.masterCommonService.getListFromArray(this.loadDataList['payment_method_ind'], false, false), true, '', 'B', [Validators.maxLength(254)]));
    columns.set('remitToDescription', new Tcolumn('remitToDescription', 'Remit To Description', 'TA', 10, true, undefined, true, '', 'B', [Validators.maxLength(254)]));
    columns.set('noticeOfAssignment', new Tcolumn('noticeOfAssignment', 'Notice Of Assignment', 'TA', 11, true, undefined, true, '', 'B', [Validators.maxLength(254)]));
    columns.set('defaultValue', new Tcolumn('defaultValue', 'Default', 'B', 13, true, this.masterCommonService.getYesNo(), true, true, 'B', [Validators.required]));
    columns.set('status', new Tcolumn('status', 'Status', 'B', 14, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));
    let grid = new Grid(columns, true, true, true, true, 'accountNumber', '/api/counterpartybankaccount/v1/getbycounterpartyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&counterpartyUuid=', '', 'Bank Account', 'name', '', undefined, undefined, false);
    grid.setSaveUrl(environment.base_url + '/api/counterpartybankaccount/v1/save');
    grid.setUpdateUrl(environment.base_url + '/api/counterpartybankaccount/v1/update');
    grid.importUrl = environment.base_url + '/api/counterpartybankaccount/v1/import';
    grid.hrefUrl = environment.base_url + '/api/counterpartybankaccount/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
    let tab = new Tab(grid.getTitle(), grid, 'name');
    tab.idFieldValueName = 'counterpartyUuid';
    return tab;
  }

  public getIncidentReport() {
    let columns: Map<string, Tcolumn> = new Map();
    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    columns.set('name', new Tcolumn('name', 'Counterparty Name', 'LB', 1, false, undefined, false, '', 'B', [Validators.required]));
    columns.set('dateOfReporting', new Tcolumn('dateOfReporting', 'Date Of Reporting', 'D', 1, true, undefined, true, '', 'B', [Validators.required]));
    columns.set('summary', new Tcolumn('summary', 'Summary', 'TA', 3, true, undefined, true, '', 'B', [Validators.required, Validators.maxLength(56)],undefined,undefined,undefined,undefined,undefined));
    let grid = new Grid(columns, true, true, true, true, 'dateOfReporting', '/api/counterincidentreport/v1/getbycounterpartyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&counterpartyUuid=', '', 'Incident Report Details', 'name', '', undefined, undefined, false);
    grid.setSaveUrl(environment.base_url + '/api/counterincidentreport/v1/save');
    grid.setUpdateUrl(environment.base_url + '/api/counterincidentreport/v1/update');
    grid.importUrl = environment.base_url + '/api/counterincidentreport/v1/import';
    grid.hrefUrl = environment.base_url + '/api/counterincidentreport/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
    let tab = new Tab(grid.getTitle(), grid, 'name');
    tab.idFieldValueName = 'counterpartyUuid';
    return tab;
  }

  public getCommodities() {
    let columns: Map<string, Tcolumn> = new Map();
    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    columns.set('name', new Tcolumn('name', 'Counterparty Name', 'LB', 1, false, undefined, false, '', 'B', [Validators.required]));
    columns.set('commodity', new Tcolumn('commodity', 'Commodity', 'L', 2, false, this.masterCommonService.getListFromArray(this.loadDataList['commodity']), true, '', 'B', [Validators.required, Validators.maxLength(100)], '', undefined, undefined, undefined, undefined));
    columns.set('tradeType', new Tcolumn('tradeType', 'Trade Type', 'ML', 3, true, this.masterCommonService.getListFromArray(this.loadDataList['trade_type_ind'], false, false), true, 'All', 'B', [Validators.required, Validators.maxLength(100)]));
    columns.set('incoterm', new Tcolumn('incoterm', 'Incoterm', 'ML', 3, true, this.masterCommonService.getListFromArray(this.loadDataList['incoterm'],false,false), true, 'All', 'B', [Validators.required, Validators.maxLength(100)]));
    columns.set('transactionType', new Tcolumn('transactionType', 'Transaction Type', 'ML', 3, true, this.masterCommonService.getListFromArray(this.loadDataList['transaction_type_ind'], false, false), true, '', 'B', [Validators.required, Validators.maxLength(100)]));
    columns.set('status', new Tcolumn('status', 'Status', 'B', 7, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));
    let grid = new Grid(columns, true, true, true, true, 'commodity', '/api/counterpartycommodity/v1/getbycounterpartyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&counterpartyUuid=', '', 'Commodities', '', '', undefined, undefined, false);
    grid.setSaveUrl(environment.base_url + '/api/counterpartycommodity/v1/save');
    grid.setUpdateUrl(environment.base_url + '/api/counterpartycommodity/v1/update');
    grid.importUrl = environment.base_url + '/api/counterpartycommodity/v1/import';
    grid.hrefUrl = environment.base_url + '/api/counterpartycommodity/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
    let tab = new Tab(grid.getTitle(), grid, 'name');
    tab.idFieldValueName = 'counterpartyUuid';
    return tab;
  }

  public getPaymentTerm() {
    let columns: Map<string, Tcolumn> = new Map();
    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    columns.set('name', new Tcolumn('name', 'Counterparty Name', 'LB', 1, false, undefined, false, '', 'B', [Validators.required]));
    columns.set('approvedPaymentTermName', new Tcolumn('approvedPaymentTermName', 'Approved Payment Term Name', 'L', 2, true, this.masterCommonService.getListFromArray(this.loadDataList['paymentterm']), true, '', 'B', [Validators.required, Validators.maxLength(254)]));
    columns.set('defaultValue', new Tcolumn('defaultValue', 'Default', 'B', 3, true, this.masterCommonService.getBooleanFromLabel('Yes', 'No'), true, true, 'B', [Validators.required]));
    columns.set('status', new Tcolumn('status', 'Status', 'B', 4, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));
    let grid = new Grid(columns, true, true, true, true, 'approvedPaymentTermName', '/api/counterpartypaymentterm/v1/getbycounterpartyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&counterpartyUuid=', '', 'Payment Term', '', '', undefined, undefined, false);
    grid.setSaveUrl(environment.base_url + '/api/counterpartypaymentterm/v1/save');
    grid.setUpdateUrl(environment.base_url + '/api/counterpartypaymentterm/v1/update');
    grid.importUrl = environment.base_url + '/api/counterpartypaymentterm/v1/import';
    grid.hrefUrl = environment.base_url + '/api/counterpartypaymentterm/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
    let tab = new Tab(grid.getTitle(), grid, 'name');
    tab.idFieldValueName = 'counterpartyUuid';
    return tab;
  }

  public getAttachment() {
    let updateAttachmentname = function updatename(value: any = []) {
      return value[0]['name'];
    };
    let updateAttachmenttype = function updatetype(value: any = []) {
      return value[0]['type'];
    };
    let action: Action = new Action();
    action.addFieldToUpdate("attachmentFileName", '', updateAttachmentname, undefined, undefined, false);
    action.addFieldToUpdate("attachmentFileType", '', updateAttachmenttype, undefined, undefined, false);
    let columns: Map<string, Tcolumn> = new Map();
    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    columns.set('name', new Tcolumn('name', 'Counterparty Name', 'LB', 1, false, undefined, false, '', 'B', [Validators.required]));
    columns.set('attachmentName', new Tcolumn('attachmentName', 'Attachment Name', 'T', 2, true, undefined, true, '', 'B', [Validators.required, Validators.maxLength(254)]));
    columns.set('attachment', new Tcolumn('attachment', 'Attachment', 'F', 3, true, undefined, true, '', 'B', [Validators.required,], undefined, 1, undefined, undefined, undefined, action, {
      downloadUrl: {
        url: environment.base_url,
        para: ['attachmentUrl']
      }
    }));
    columns.set('attachmentFileName', new Tcolumn('attachmentFileName', 'File Name', 'LB', 4, false, undefined, false, '', 'H'));
    columns.set('attachmentFileType', new Tcolumn('attachmentFileType', 'File Type', 'LB', 5, false, undefined, false, '', 'H'));
    let grid = new Grid(columns, true, true, true, true, 'attachmentName', '/api/counterpartyattachments/v1/getbycounterpartyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&counterpartyUuid=', '', 'Attachment', '', '', undefined, undefined, false);
    grid.setSaveUrl(environment.base_url + '/api/counterpartyattachments/v1/saveDocument');
    grid.setUpdateUrl(environment.base_url + '/api/counterpartyattachments/v1/update');
    grid.hrefUrl = environment.base_url + '/api/counterpartyattachments/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
    let tab = new Tab(grid.getTitle(), grid, 'name');
    tab.idFieldValueName = 'counterpartyUuid';
    return tab;
  }

  public getAddressDetails() {
    let _this = this;
    let countryFunc = function (value: string[] = []) {
      let countryCode = value[0];
      let country = _this.commonService.getFromCountryMap(countryCode);
      let extra = {};
      extra['selectedCountry'] = _this.commonService.getFromCountryMap(_this.commonService.getFromStorage('country'))['alpha2Code'];
      if (country !== undefined) {
        extra['selectedCountry'] = country['alpha2Code'];
      }
      return extra;
    };
    let actionCountry = new Action();
    actionCountry.addFieldToUpdate('telephone', undefined, countryFunc, undefined, undefined, true, 'extra');
    actionCountry.addFieldToUpdate('fax', undefined, countryFunc, undefined, undefined, true, 'extra');
    let columns: Map<string, Tcolumn> = new Map();
    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    columns.set('name', new Tcolumn('name', 'Counterparty Name', 'LB', 1, false, undefined, false, '', 'B', [Validators.required]));
    columns.set('addressType', new Tcolumn('addressType', 'Address Type', 'L', 2, false, this.masterCommonService.getListFromArray(this.loadDataList['address_type_ind']), true, '', 'B', [Validators.required]));
    columns.set('country', new Tcolumn('country', 'Country', 'L', 3, true, this.commonService.getCountryList(), true, '', 'B', [Validators.required, Validators.maxLength(56)],undefined,undefined,undefined,undefined,undefined,actionCountry));
    columns.set('telephone', new Tcolumn('telephone', 'Telephone', 'TN', 4, true, undefined, true, '', 'B',[Validators.maxLength(18),ctrmValidators.phoneNumberValidator()]));
    columns.set('fax', new Tcolumn('fax', 'Fax', 'TN', 5, true, undefined, true, '', 'B', [Validators.maxLength(16), Validators.pattern('^(\\+?\\d{1,}(\\s?|\\-?)\\d*(\\s?|\\-?)\\(?\\d{2,}\\)?(\\s?|\\-?)\\d{3,}\\s?\\d{3,})$')]));
    columns.set('address', new Tcolumn('address', 'Address', 'TA', 6, true, undefined, true, '', 'B', [Validators.required, Validators.maxLength(256)]));
    columns.set('tag', new Tcolumn('tag', 'Tag', 'T', 7, true, undefined, true, '', 'B', [Validators.maxLength(256)]));
    columns.set('defaultValue', new Tcolumn('defaultValue', 'Default', 'B', 8, true, this.masterCommonService.getBooleanFromLabel('Yes', 'No'), true, true, 'B', [Validators.required]));
    let grid = new Grid(columns, true, true, true, true, 'addressType', '/api/counterpartyaddressdetails/v1/getbycounterpartyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&counterpartyUuid=', '', 'Address Details', '', '', undefined, undefined, false);
    grid.setSaveUrl(environment.base_url + '/api/counterpartyaddressdetails/v1/save');
    grid.setUpdateUrl(environment.base_url + '/api/counterpartyaddressdetails/v1/update');
    grid.importUrl = environment.base_url + '/api/counterpartyaddressdetails/v1/import';
    grid.hrefUrl = environment.base_url + '/api/counterpartyaddressdetails/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
    let tab = new Tab(grid.getTitle(), grid, 'name');
    tab.idFieldValueName = 'counterpartyUuid';
    return tab;
  }

  public getContactDetails() {
    let _this = this;
    let countryFunc = function (value: string[] = []) {
      let countryCode = value[0];
      let country = _this.commonService.getFromCountryMap(countryCode);
      let extra = {};
      extra['selectedCountry'] = _this.commonService.getFromCountryMap(_this.commonService.getFromStorage('country'))['alpha2Code'];
      if (country !== undefined) {
        extra['selectedCountry'] = country['alpha2Code'];
      }
      return extra;
    };
    let actionCountry = new Action();
    actionCountry.addFieldToUpdate('contactPhone', undefined, countryFunc, undefined, undefined, false, 'extra');
    actionCountry.addFieldToUpdate('fax', undefined, countryFunc, undefined, undefined, true, 'extra');
    let columns: Map<string, Tcolumn> = new Map();
    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    columns.set('name', new Tcolumn('name', 'Counterparty Name', 'LB', 1, false, undefined, false, '', 'B', [Validators.required]));
    columns.set('contactType', new Tcolumn('contactType', 'Contact Type', 'L', 1, false, this.masterCommonService.getListFromArray(this.loadDataList['contact_type_ind']), true, '', 'B', [Validators.required]));
    columns.set('contactName', new Tcolumn('contactName', 'Contact Name', 'T', 2, true, undefined, true, '', 'B', [Validators.required, Validators.maxLength(128)]));
    columns.set('country', new Tcolumn('country', 'Country', 'L', 3, true, this.commonService.getCountryList(), true, '', 'B', [Validators.required, Validators.maxLength(56)], undefined, undefined, undefined, undefined, undefined, actionCountry));
    columns.set('contactPhone', new Tcolumn('contactPhone', 'Contact Phone', 'TN', 4, true, undefined, true, '', 'B', [Validators.required, Validators.maxLength(15)]));
    columns.set('fax', new Tcolumn('fax', 'Fax', 'TN', 5, true, undefined, true, '', 'B', [Validators.required,Validators.maxLength(16), Validators.pattern('^(\\+?\\d{1,}(\\s?|\\-?)\\d*(\\s?|\\-?)\\(?\\d{2,}\\)?(\\s?|\\-?)\\d{3,}\\s?\\d{3,})$')]));
    columns.set('contactAddress', new Tcolumn('contactAddress', 'Contact Address', 'TA', 6, true, undefined, true, '', 'B', [Validators.maxLength(254)]));
    columns.set('email', new Tcolumn('email', 'Email', 'T', 7, true, undefined, true, '', 'B', [Validators.required, Validators.email, Validators.pattern('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$')]));
    columns.set('defaultValue', new Tcolumn('defaultValue', 'Default', 'B', 8, true, this.masterCommonService.getBooleanFromLabel('Yes', 'No'), true, true, 'B', [Validators.required]));
    let grid = new Grid(columns, true, true, true, true, 'contactType', '/api/counterpartycontactdetails/v1/getbycounterpartyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&counterpartyUuid=', '', 'Contact Details', '', '', undefined, undefined, false);
    grid.setSaveUrl(environment.base_url + '/api/counterpartycontactdetails/v1/save');
    grid.setUpdateUrl(environment.base_url + '/api/counterpartycontactdetails/v1/update');
    grid.importUrl = environment.base_url + '/api/counterpartycontactdetails/v1/import';
    grid.hrefUrl = environment.base_url + '/api/counterpartycontactdetails/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
    let tab = new Tab(grid.getTitle(), grid, 'name');
    tab.idFieldValueName = 'counterpartyUuid';
    return tab;
  }

  public getBrokerageExchange(typeCode: string, forChildTab: boolean = false) {
    let columns: Map<string, Tcolumn> = new Map();
    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    columns.set('name', new Tcolumn('name', 'Counterparty name', 'LB', 1, false, undefined, false, '', 'B', []));
    columns.set('priceIndexName', new Tcolumn('priceIndexName', 'Price Index Name', 'L', 2, false, [], true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined));
    if(typeCode === 'clearing broker') {
      columns.set('brokerageFee', new Tcolumn('brokerageFee', 'Clearing Fee', 'N', 5, true, undefined, true, '', 'B', [Validators.required]));
    } else {
      columns.set('brokerageFee', new Tcolumn('brokerageFee', 'Brokerage Fee', 'N', 5, true, undefined, true, '', 'B', [Validators.required]));
    }

    let grid = new Grid(columns, true, true, true, true, 'priceIndexName', '/api/counterpartybrokerage/v1/getbycounterpartyuuid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&counterpartyUuid=', '', 'Exchange/Brokerage Details', '', '', undefined, undefined, false);
    grid.importUrl = environment.base_url + '/api/counterpartybrokerage/v1/import';
    grid.setSaveUrl(environment.base_url + '/api/counterpartybrokerage/v1/save');
    grid.setUpdateUrl(environment.base_url + '/api/counterpartybrokerage/v1/update');
    grid.hrefUrl = environment.base_url + '/api/counterpartybrokerage/v1/getbyuuid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&uuid=';
    let tab = new Tab(grid.getTitle(), grid, 'name');
    tab.idFieldValueName = 'counterpartyUuid';
    return tab;
  }

  getMetadata(grid: Grid) {
    let _this = this;
    _this.commonService.getJSONByURL(environment.base_url + '/api/counterparty/v1/getexchangenamefromfuturemetadata?tenantId=' + this.commonService.getTenantId()).subscribe((next: any) => {
      grid.getColumn().get('priceIndexName').setListofValues(this.masterCommonService.getListFromArray(next, false, true));
    });
  }

  public getKycapplicable(typeCode: string, forChildTab: boolean = false) {
    let commodityFun = function(value: any[]) {
      return value[0]['commodity'];
    };
    let actionpriceIndex = new Action(environment.base_url + '/api/futureoptionindex/v1/getByName?tenantId=' + this.commonService.getFromStorage('tenantId') + '&name=');
    actionpriceIndex.addFieldToUpdate('commodity', undefined, commodityFun, ['DB'], undefined, false, 'defaultValue');
    let columns: Map<string, Tcolumn> = new Map();
    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    columns.set('name', new Tcolumn('name', 'Counterparty name', 'LB', 1, false, undefined, false, '', 'B', []));
    columns.set('priceIndexName', new Tcolumn('priceIndexName', 'Price Index Name', 'L', 2, false, this.masterCommonService.getListFromArray(this.loadDataList['priceIndex']), true, '', 'B', [Validators.required],undefined,undefined,undefined,undefined,undefined,actionpriceIndex));
    columns.set('commodity', new Tcolumn('commodity', 'Commodity', 'LB', 4, true, undefined, true, '', 'B', [],undefined,1,undefined,undefined,undefined,undefined,undefined,true));
    if(typeCode === 'clearing broker') {
      columns.set('brokerageFee', new Tcolumn('brokerageFee', 'Clearing Fee', 'N', 5, true, undefined, true, '', 'B', [Validators.required]));
    } else {
      columns.set('brokerageFee', new Tcolumn('brokerageFee', 'Brokerage Fee', 'N', 5, true, undefined, true, '', 'B', [Validators.required]));
    }

    let grid = new Grid(columns, true, true, true, true, 'priceIndexName', '/api/counterpartybrokerage/v1/getbycounterpartyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&counterpartyUuid=', '', 'KYC', '', '', undefined, undefined, false);
    grid.importUrl = environment.base_url + '/api/counterpartybrokerage/v1/import';
    grid.setSaveUrl(environment.base_url + '/api/counterpartybrokerage/v1/save');
    grid.setUpdateUrl(environment.base_url + '/api/counterpartybrokerage/v1/update');
    grid.hrefUrl = environment.base_url + '/api/counterpartybrokerage/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
    let tab = new Tab(grid.getTitle(), grid, 'name');
    tab.idFieldValueName = 'counterpartyUuid';
    return tab;
  }

  public getColumns() {
    let updatelogoname = function(value: any = []) {
      let file = value[0];
      return file['name'];
    };
    let updatelogotype = function(value: any = []) {
      let file = value[0];
      return file['type'];
    };

      let listCPParentFunc = function (value: any[] = []) {
          let list:any[] = value[0];
          if(list !== null && list !== undefined && list.length > 0) {
            list.splice(0,1);
          }
          list.splice(0,0,new KeyValue('None','none'));
          return list;
      };
    let _this = this;
    let countryFuncMain = function (value: string[] = []) {
      let countryCode = value[0];
      let country = _this.commonService.getFromCountryMap(countryCode);
      let extra = {};
      extra['selectedCountry'] = _this.commonService.getFromCountryMap(_this.commonService.getFromStorage('country'))['alpha2Code'];
      if (country !== undefined) {
        extra['selectedCountry'] = country['alpha2Code'];
      }
      return extra;
    };


    let timezonechange = function (value: string[] = []) {
      let countryCode = value[0];
      let timezonelist = [];
      let _this = this;

      timezonelist.push(new KeyValue('Select', ''));
      fetch('../../assets/json/countrycodetimezone.json').then(res => res.json())
          .then(data => {
            data.forEach(function (timezone) {
              if(countryCode === timezone.alpha3Code){
                timezonelist.push(new KeyValue(timezone['TIMEZONE'], timezone['TIMEZONE']));
              }
            })
          });
      return timezonelist;
    };

    let countrychange = function (value: string[] = []) {
      let timezonevalue = value[0];
      let countryList = [];
      countryList.push(new KeyValue('Select', ''));
      fetch('../../assets/json/countrycodetimezone.json').then(res => res.json())
          .then(data => {
            data.forEach(function (timezone) {
              if(timezonevalue === timezone.TIMEZONE){
                countryList.push(new KeyValue(timezone['COUNTRY'], timezone['COUNTRYCODE']));
              }
            })
          });
      return countryList;
    };

      let actionone: Action = new Action(environment.base_url + '/api/counterparty/v1/getparentlist?tenantId=' + this.commonService.getFromStorage('tenantId') + '&name=', 'name', 'name');
      actionone.setCovertToListRequired(true);
      actionone.addFieldToUpdate("parent", undefined, listCPParentFunc, ['L'], undefined, true, 'listvalues', false, false);

      let action: Action = new Action();
    action.addFieldToUpdate("logoFileName", '', updatelogoname, undefined, undefined, false);
    action.addFieldToUpdate("logoFileType", '', updatelogotype, undefined, undefined, false);

    let actionCountryMain = new Action();
    actionCountryMain.addFieldToUpdate('telephone', undefined, countryFuncMain, undefined, undefined, false, 'extra');
    actionCountryMain.addFieldToUpdate('fax', undefined, countryFuncMain, undefined, undefined, false, 'extra');
    actionCountryMain.addFieldToUpdate('timeZone', undefined, timezonechange, undefined, undefined, false, 'listvalues');


    this.columns = new Map();

    //Counterparty Section
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('code', new Tcolumn('code', 'Counterparty Code', 'T', 1, false, undefined, true,new defaultValue('',undefined,['-','_'],undefined,false,true,false,undefined,undefined,true), 'B', [Validators.required, Validators.maxLength(8)], undefined, undefined, undefined, undefined, [], undefined, undefined, true));
    this.columns.set('name', new Tcolumn('name', 'Counterparty Name', 'T', 1, false, undefined, true, new defaultValue('', undefined, environment.primaryKeyIncludedCharacters), 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, actionone, {style: {width: '5%'}}));
    this.columns.set('counterpartyTypeCode', new Tcolumn('counterpartyTypeCode', 'Counterparty Type Code', 'L', 2, true, this.masterCommonService.getListFromArray(this.loadDataList['company_type_ind']), true, '', 'B', [Validators.required]));
    this.columns.set('description', new Tcolumn('description', 'Counterparty Description', 'TA', 4, true, undefined, true, undefined, 'B', [Validators.maxLength(254)]));
    this.columns.set('parent', new Tcolumn('parent', 'Parent Counterparty', 'L', 5, true, this.getParent(), true, 'none', 'B', []));
    this.columns.set('countryOfRegistration', new Tcolumn('countryOfRegistration', 'Country Of Registration', 'L', 6, true, this.commonService.getCountryList(), true, '', 'B', [Validators.required, Validators.maxLength(100)],undefined,undefined, undefined,undefined,undefined,actionCountryMain));

    this.columns.set('timeZone', new Tcolumn('timeZone', 'Timezone', 'L', 6, true, this.commonService.getTimeZoneListforalpha2(), true, this.commonService.getFromStorage('timezone'), 'B', [Validators.required, Validators.maxLength(100)],undefined,undefined,undefined,undefined,undefined));
    this.columns.set('dayLightSaving', new Tcolumn('dayLightSaving', 'Day Light Saving', 'B', 12, true, this.masterCommonService.getEnableDisable(), true, false, 'B', [Validators.required, Validators.maxLength(5)]));
    this.columns.set('currency', new Tcolumn('currency', 'Currency', 'L', 7, true, this.commonService.getCurrencyList(), true, this.commonService.getFromStorage('currency'), 'B', [Validators.required, Validators.maxLength(5)]));
    this.columns.set('website', new Tcolumn('website', 'Website', 'T', 8, true, undefined, true, '', 'B', [Validators.maxLength(100), Validators.pattern('^((https?|ftp|smtp):\\/\\/)?(www.)?[a-z0-9]+(\\.[a-z]{2,}){1,3}(#?\\/?[a-zA-Z0-9#]+)*\\/?(\\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$')]));
    this.columns.set('telephone', new Tcolumn('telephone', 'Telephone', 'TN', 4, true, undefined, true, '', 'B',[Validators.maxLength(18),ctrmValidators.phoneNumberValidator()]));
    this.columns.set('fax', new Tcolumn('fax', 'Fax', 'TN', 5, true, undefined, true, '', 'B', [Validators.maxLength(16), Validators.pattern('^(\\+?\\d{1,}(\\s?|\\-?)\\d*(\\s?|\\-?)\\(?\\d{2,}\\)?(\\s?|\\-?)\\d{3,}\\s?\\d{3,})$')]));
    this.columns.set('address', new Tcolumn('address', 'Address', 'LB', 11, true, undefined, true, '', 'B', [Validators.maxLength(254)]));
    this.columns.set('logo', new Tcolumn('logo', 'Logo', 'F', 12, true, undefined, true, '', 'B', [], 'file', 1, undefined, undefined, undefined, action, {
      downloadUrl: {url: environment.base_url + '/company/download/', para: ['name']}
    }));
    this.columns.set('financingAgent', new Tcolumn('financingAgent', 'Financing Agent', 'ML', 13, true, this.getFinancingAgentList(), true, '', 'B', []));
    this.columns.set('financeControllerApproval', new Tcolumn('financeControllerApproval', 'Finance Controller Approval', 'B', 14, true, this.masterCommonService.getYesNo(), true, false, 'B', [Validators.required]));
    this.columns.set('isTradeable', new Tcolumn('isTradeable', 'Tradeable', 'B', 15, true, this.masterCommonService.getYesNo(), true, false, 'B', [Validators.required]));
    this.columns.set('status', new Tcolumn('status', 'Status', 'B', 16, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));
    this.columns.set('kycStatus', new Tcolumn('kycStatus', 'KYC Applicable', 'B', 16, true, this.masterCommonService.getBooleanFromLabel('Yes','No'), true, false, 'B', [Validators.required]));
    this.columns.set('logoFileName', new Tcolumn('logoFileName', 'Logo Name', 'LB', 17, false, undefined, false, '', 'H'));
    this.columns.set('logoFileType', new Tcolumn('logoFileType', 'Logo Type', 'LB', 18, false, undefined, false, '', 'H'));
  }


  /**
   * return month to list
   * @param yearFrom
   * @param monthFrom
   * @param yearTo
   */
  getMonthTo(yearFrom: any = new Date().getFullYear(), monthFrom: any = new Date().getMonth(), yearTo: any = new Date().getFullYear()) {
    let monthList = this.getMonthList();
    let list = [];
    if (parseInt(yearFrom) === parseInt(yearTo)) {
      monthList.forEach(function (obj: KeyValue) {
        if (obj.getValue() >= monthFrom) {
          list.push(obj);
        }
      })
    } else {
      list = monthList;
    }
    return list;
  }

  /**
   * calculate and return year to list
   * @param from
   */
  getYearTo(from: any = new Date().getFullYear()) {
    let list = [];
    let to = parseInt(from) + 10;
    for (let i = from; i <= to; i++) {
      list.push(new KeyValue(i + '', i));
    }
    return list;
  }

  initfunctions() {
    let this1 = this;
    this.yeartfun = function getYearTo(value: any = []) {
      let from = value[0];
      return this1.getYearTo(from);
    };
    this.monthto = function getMonthTo(value: any = []) {
      let yearFrom = value[0];
      let monthFrom = value[1];
      let yearTo = value[2];
      let mlist = this1.getMonthList();
      if (yearFrom !== undefined && monthFrom !== undefined && yearTo !== undefined) {
        return this1.getMonthTo(yearFrom, monthFrom, yearTo);
      } else {
        return mlist;
      }
    }
  }

  private updateTabDefault(tab,mapDefault:any) {
    if(tab === 'bankAccount') {
      this.bankAccount.getGrid().getColumn().get('defaultValue').setDefaultValue(!this.commonService.getDefaultValue(mapDefault,tab));
    } else if(tab === 'address') {
      this.address.getGrid().getColumn().get('defaultValue').setDefaultValue(!this.commonService.getDefaultValue(mapDefault,tab));
    } else if(tab === 'contact') {
      this.contact.getGrid().getColumn().get('defaultValue').setDefaultValue(!this.commonService.getDefaultValue(mapDefault,tab));
    } else if(tab === 'payment') {
      this.payment.getGrid().getColumn().get('defaultValue').setDefaultValue(!this.commonService.getDefaultValue(mapDefault,tab));
    }
  }

  /**
   * this is the function used to call when user save ,update or copy any row in grid
   * @param value  this is value of the row user edit
   */
  onRowDataSave(value: any) {
    let data = {};
    let mode = '';
    let _this = this;
    this.refreshGrid = false;
    this.grid.getColumn().get('financingAgent').setListofValues(this.getFinancingAgentList());
    if (value['data']) {
      data = value['data']['result'];
      mode = value['data']['mode'];
      if (data['newData']) {
        data = data['newData'];
      }
      if (value['label'] === 'Payment Term') {
        let _this = this;
        if (data['defaultValue'] === true) {
          let def = new ChildAction();
          def.setMode('makedefault');
          def.setCommonService(this.commonService);
          def.addCriteria('approvedPaymentTermName', data['approvedPaymentTermName'], 'notEqual');
          def.addCriteria('name', data['name']);
          def.addRepo('xceler_configservice_counterpartypaymentterm', 'counterpartyPaymentTermRepo', 'defaultValue');
          def.perform().subscribe(next => {
            _this.payment.getGrid().getColumn().get('defaultValue').setDefaultValue(false);
            _this.refreshGrid = true;
          });
        }else {
          if(mode === 'update') {
            _this.updateDefaultOnUpdate(value['data'],_this.payment,'defaultValue');
          }
        }

      } else if (value['label'] === 'Address Details' && (mode === 'save' || mode === 'copy' || mode === 'update')) {
        if (data['defaultValue'] === true) {
          let _this = this;
          let def = new ChildAction();
          def.setMode('makedefault');
          def.setCommonService(this.commonService);
          def.addCriteria('name', data['name']);
          def.addCriteria('tag', data['tag'], 'notEqual');
          def.addRepo('xceler_configservice_counterpartyaddressdetails', 'counterpartyAddressDetailsRepo', 'defaultValue');
          def.perform().subscribe(next => {
            _this.address.getGrid().getColumn().get('defaultValue').setDefaultValue(false);
            let obj = {
              name: data['name'],
              telephone: data['telephone'],
              fax: data['fax'],
              address: data['address']
            };
            _this.commonService.patch(_this.currentHref, obj).subscribe(next => {
              _this.refreshGrid = true;
            });
          });
        }else {
          if(mode === 'update') {
            _this.updateDefaultOnUpdate(value['data'],_this.address,'defaultValue');
          }
        }
      } else if (value['label'] === 'Bank Account' && (mode === 'save' || mode === 'copy' || mode === 'update')) {
        if (data['defaultValue'] === true) {
          let _this = this;
          let def = new ChildAction();
          def.setMode('makedefault');
          def.setCommonService(this.commonService);
          def.addCriteria('name', data['name']);
          def.addCriteria('accountNumber', data['accountNumber'],'notEqual');
          def.addRepo('xceler_configservice_counterpartybankaccount', 'counterpartyBankAccountRepo', 'defaultValue');
          def.perform().subscribe(next => {
            _this.bankAccount.getGrid().getColumn().get('defaultValue').setDefaultValue(false);
            _this.refreshGrid = true;
          });
        } else {
          if(mode === 'update') {
            _this.updateDefaultOnUpdate(value['data'],_this.bankAccount,'defaultValue');
          }
        }
      } else if (value['label'] === 'Contact Details' && (mode === 'save' || mode === 'copy' || mode === 'update')) {
        if (data['defaultValue'] === true) {
          let _this = this;
          let def = new ChildAction();
          def.setMode('makedefault');
          def.setCommonService(this.commonService);
          def.addCriteria('contactName', data['contactName'], 'notEqual');
          def.addCriteria('name', data['name']);
          def.addRepo('xceler_configservice_counterpartycontactdetails', 'counterpartyContactDetailsRepo', 'defaultValue');
          def.perform().subscribe(next => {
            _this.contact.getGrid().getColumn().get('defaultValue').setDefaultValue(false);
            _this.refreshGrid = true;
          });
        } else {
          if(mode === 'update') {
            _this.updateDefaultOnUpdate(value['data'],_this.contact,'defaultValue');
          }
        }
      }
    }
  }

  private updateDefaultOnUpdate(value:any,tab:Tab,defaultFieldName:string) {
    let oldData = value['result']['oldData'];
    if(oldData[defaultFieldName]) {
      tab.getGrid().getColumn().get(defaultFieldName).setDefaultValue(true);
    }
  }

  /**
   * get the commodity columns from commodity master and return to grid
   */
  public getCommodityColumns() {
    let commodityGrid = new CommodityComponent(this.commonService, this.masterCommonService, this.messageService, this.formBuilder);
    commodityGrid.ngOnInit();
    return commodityGrid.grid;
  }

  onUniqueKey(value: any) {
    // this.currentHref = value['rowData']['href'].toString().replace('http://localhost:8080/', environment.base_url);
    let payload = [{
      tenantId: this.commonService.getFromStorage('tenantId'),
      serviceName: 'xceler_configservice',
      model:'counterparty',
      requiredField:['name'],
      filter: [{key:'counterpartyTypeCode', value:'Financing Agent'}]
    }];
    this.commonService.getJSONByObject(environment.base_url + '/api/'+ 'counterparty' + '/v1/load', payload).subscribe((data:any) =>{
      this.financingAgentList = data;
    });
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url+'/api/counterparty/v1/isdefaultavailable?tenantId='+this.commonService.getFromStorage('tenantId')+'&counterpartyUuid='+value['rowData']['uuid']).subscribe((next:any) => {
      _this.updateTabs(value['rowData'],next);
    });
  }

  getFinancingAgentList() {
    return this.masterCommonService.getListFromArray(this.financingAgentList['counterparty'], false, false);
  }
  updateTabs(rowData: any,mapDefault) {
    this.tabs = [];
    this.tabs.push(this.payment);
    this.tabs.push(this.bankAccount);
    this.tabs.push(this.getCommodities());
    this.tabs.push(this.address);
    this.tabs.push(this.contact);
    this.tabs.push(this.getAttachment());
    this.tabs.push(this.incidentReport);
    let typeCode = rowData['counterpartyTypeCode'].toString().toLowerCase();
    if (typeCode === 'clearing broker' || typeCode === 'execution broker') {
      let tab = this.getBrokerageExchange(typeCode);
      this.tabs.push(tab);
      // this.getPriceIndexList(tab.getGrid())
      this.getMetadata(tab.getGrid());
    }

    if (rowData['kycStatus']) {
      let tab = this.getTestTemplate();
      this.tabs.splice(5, 0, tab);
      this.tabs.join()
    }
    this.grid.setTabs(this.tabs);
    this.updateTabDefault('address',mapDefault);
    this.updateTabDefault('contact',mapDefault);
    this.updateTabDefault('bankAccount',mapDefault);
    this.updateTabDefault('payment',mapDefault);
    this.updateTabDefault('incidentReport',mapDefault);
  }

  openHeirarcy(selectedRow: any) {
    this.onClose();
    this.selectedRowData = selectedRow[0];
    this.modalStateHierarchy = true;
  }

  closeBtn() {
    this.modalStateHierarchy = false;
  }

  getEntityName() {
    return 'xceler_configservice_counterparty';
  }

  private getParent() {
    let parentList = [];
    parentList.push(new KeyValue('None', 'none'));
    return parentList;
  }

  /**
   * return month list from jan to dec with value 1 - 12
   */
  private getMonthList() {
    let list = [];
    list.push(new KeyValue('Jan', 1));
    list.push(new KeyValue('Feb', 2));
    list.push(new KeyValue('Mar', 3));
    list.push(new KeyValue('Apr', 4));
    list.push(new KeyValue('May', 5));
    list.push(new KeyValue('Jun', 6));
    list.push(new KeyValue('Jul', 7));
    list.push(new KeyValue('Aug', 8));
    list.push(new KeyValue('Sep', 9));
    list.push(new KeyValue('Oct', 10));
    list.push(new KeyValue('Nov', 11));
    list.push(new KeyValue('Dec', 12));
    return list;
  }

  getPriceIndexList(grid:Grid){
    let _this = this;
    this.priceIndexList = [];
    this.commonService.getJSONByURL(environment.base_url + '/api/priceIndex/v1/getIndexList?indexTypeList=futures&tenantId='+this.commonService.getFromStorage('tenantId')).subscribe(function (data: any) {
      _this.priceIndexList = _this.commonService.addListOTT(data);
      grid.getColumn().get('priceIndexName').setListofValues(_this.priceIndexList);
    });
  }

  applyNotifFilter(){
     this.route
        .queryParams
        .subscribe(params => {
          this.filterValue = params['value'];
          if(this.filterValue !==undefined && this.filterValue !== null && this.filterValue !== '') {
            this.grid.addInFilter(new Filter('Counterparty Name','name','equals',this.filterValue,'','T',this.columns.get('name')));
          }
        });
  }

contextmenu(){
    this.grid.removeAllExtraButtons()
  let _this = this;
  let paymentterm = function (value: any) {
    _this.openTabForContextMenu(value['data'],'Payment Term');
  };
  let bankAccount = function (value: any) {
    _this.openTabForContextMenu(value['data'],'Bank Account');
  };
  let commodities = function (value: any) {
    _this.openTabForContextMenu(value['data'],'Commodities');
  };
  let addressdetails = function (value: any) {
    _this.openTabForContextMenu(value['data'],'Address Details');
  };
  let contactdetails = function (value: any) {
    _this.openTabForContextMenu(value['data'],'Contact Details');
  };
  let attachment = function (value: any) {
    _this.openTabForContextMenu(value['data'],'Attachment');
  };
  let incidentReport = function (value: any) {
    _this.openTabForContextMenu(value['data'],'Incident Report');
  };
  let Paymentterm = new Button("Payment Term", 'F', '', paymentterm, undefined, undefined, undefined, undefined, false);
  this.grid.addExtraButton(Paymentterm);
  let BankAccount = new Button("Bank Account", 'F', '', bankAccount, undefined, undefined, undefined, undefined, false);
  this.grid.addExtraButton(BankAccount);
  let Commodities = new Button("Commodities", 'F', '', commodities, undefined, undefined, undefined, undefined, false);
  this.grid.addExtraButton(Commodities);
  let Addressdetails = new Button("Address Details", 'F', '', addressdetails, undefined, undefined, undefined, undefined, false);
  this.grid.addExtraButton(Addressdetails);
  let Contactdetails = new Button("Contact Details", 'F', '', contactdetails, undefined, undefined, undefined, undefined, false);
  this.grid.addExtraButton(Contactdetails);
  let Attachment = new Button("Attachment", 'F', '', attachment, undefined, undefined, undefined, undefined, false);
  this.grid.addExtraButton(Attachment);
  let IncidentReport = new Button("Incident Report", 'F', '', incidentReport, undefined, undefined, undefined, undefined, false);
  this.grid.addExtraButton(Attachment);

}


    private openTabForContextMenu(value,tabCode) {
      this.openFormForNewEntry = false;
      let payload = [{
        tenantId: this.commonService.getFromStorage('tenantId'),
        serviceName: 'xceler_configservice',
        model:'counterparty',
        requiredField:['name'],
        filter: [{key:'counterpartyTypeCode', value:'Financing Agent'}]
      }];
      this.commonService.getJSONByObject(environment.base_url + '/api/'+ 'counterparty' + '/v1/load', payload).subscribe((data:any) =>{
        this.financingAgentList = data;
      });
      let _this = this;
      this.commonService.getJSONByURL(environment.base_url+'/api/counterparty/v1/isdefaultavailable?tenantId='+this.commonService.getFromStorage('tenantId')+'&counterpartyUuid='+value['uuid']).subscribe((next:any) => {
        _this.updateTabs(value,next);
        _this.selectedTab = tabCode;
        _this.tabRow = value;
        _this.openFormForNewEntry = true;
        _this.openTabScreen = true;
      });
    }

  onTabScreenOpened() {
    this.openTabScreen = false;
  }

  onFormOpned() {
    this.openFormForNewEntry = false;
  }

  onRowSelectionChange(value: any[]) {
    if(value['data'] !== undefined && value['data'] !== null && value['data'].length ===  1) {
      this.selectedRow= value['data'][0];
      if(this.selectedRow['financeControllerApproval'] === undefined || this.selectedRow['financeControllerApproval'] === null || !this.selectedRow['financeControllerApproval']) {
        this.popupDisplay = true;
      }
    } else {
      this.popupDisplay = false;
    }
  }

  onClose() {
    this.popupDisplay = false;
  }

  approve() {
    this.refreshGrid = false;
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url+'/api/counterparty/v1/counterpartyFinanceControllerApproval?tenantId='+this.commonService.getTenantId()+'&name='+this.selectedRow['name']).subscribe((next) => {
      _this.showToast("Counterparty "+_this.selectedRow['name']+" approved successfully.");
      _this.onClose()
      _this.refreshGrid = true;
    },(error:HttpErrorResponse) =>{
      _this.showToast(_this.commonService.getHttpErrorMessage(error,'Counterparty',false),'error');
    });
  }

  showToast(msg, severity: string = 'info') {
    this.messageService.add({
      severity: severity,
      summary: msg
    });
  }
  onclickaddcounterparty() {
    this.visible = true;
  }
}
