import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {KeyValue} from '../../grid/key-value.model';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CtrmDropdownComponent),
  multi: true
};

@Component({
  selector: 'ctrm-dropdown',
  templateUrl: './ctrm-dropdown.component.html',
  styleUrls: ['./ctrm-dropdown.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class CtrmDropdownComponent implements OnInit, OnChanges {
  @Input() field: any[] = [];
  @Input() selectedValue: any = '';
  @Input() addSelectOption: boolean = false;
  @Input() fieldname: any;
  @Input('value') val: any;
  @Input() selectstyle: any = {};
  @Input() minWidth: string = 'max-content';
  @Input() width: string = '';
  @Input() maxWidth: string = '';
  @Input('name') name: string = '';
  @Output() onChangeValue = new EventEmitter();
  @Input() showBorder: boolean = true;
  @Input() removeValue: boolean = true;
  @Input() addValue: boolean = true;
  @Input() selectValue: string = 'Select';
  ignoreList: any[] = [];

  constructor() {
  }

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  onTouched: any = () => {
  };

  ngOnInit() {
    if (this.selectedValue !== undefined && this.selectedValue.length > 0) {
      this.value = this.selectedValue;
    } else {
      if (this.addSelectOption) {
        this.value = 'select';
      } else {
        this.value = this.field[0]['value'];
      }
    }
  }

  selectFirstValue() {
    if (this.addSelectOption) {
      this.value = 'select';
    } else {
      this.value = this.field[0]['value'];
    }
  }

  onChange(val) {
    this.val = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
   this.value = obj;
  }

  outValue(field: any) {
    let obj = this.getObject(field, this.value);
    if (this.value === 'select') {
      obj = new KeyValue('Select', 'select');
    }
    this.onChangeValue.emit(obj);
  }

  getObject(field, value) {
    let ret = {};
    field.forEach(function (list) {
      if (list['value'] + '' === value + '') {
        ret = list;
      }
    });
    return ret;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedValue']) {
      this.value = changes['selectedValue'].currentValue;
    }
    if (changes['removeValue']) {
      this.selectFirstValue();
      this.ignoreList.push(changes['removeValue'].currentValue);
    }
    if (changes['addValue']) {
      let val = changes['addValue'].currentValue;
      if (this.ignoreList.includes(val)) {
        let index = this.ignoreList.indexOf(val);
        this.ignoreList.splice(index, 1);
      }
    }
  }

}
