import {Component, OnInit} from '@angular/core';
import {Tcolumn} from '../../../grid/tcolumn.model';
import {Grid} from '../../../grid/grid.model';
import {Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {MasterService} from "../../services/MasterService";
import {Action} from "../../../grid/actions.model";
import {environment} from "../../../../environments/environment";
import {entities} from "../../../services/common/entities";

@Component({
  selector: 'app-gradequalityspec',
  templateUrl: './gradequalityspec.component.html',
  styleUrls: []
})
export class GradequalityspecComponent implements OnInit {

  columns: Map<string, Tcolumn>;
  grid: Grid;
  id: string = '';

  constructor(private router: ActivatedRoute, private masterCommService: MasterService) {
    this.router.params.subscribe(params => {
      this.id = params['id'];
    });
  }

  ngOnInit() {
    this.getColumns();
    this.grid = new Grid(this.columns, true, true, true, true, 'qualityParameterCode', 'gradeQualitySpec/search/findByGradeCodeOrderByIdDesc?gradeCode=' + this.id, '', 'Grade Quality Specification', 'gradeCode');
    this.grid.setFirstLevelDataUrl('/grademaster');
    this.grid.iconName = 'gradeMaster';
  }

  public getColumns() {
    this.columns = new Map();
    let minVal = function minVali(value: any[] = []) {
      let current = [Validators.min(parseInt(value[0]))];
      return current;
    };
    let action = new Action();
    action.addFieldToUpdate('max', undefined, minVal, undefined, undefined, true, 'validators');
    let actionforName: Action = new Action(environment.base_url + "filter");
    actionforName.addFieldToUpdate("qualityParameterName", "codeName");
    actionforName.setToExtra('entityName', entities.globalIndicatorDetails);
    actionforName.setToExtra('key', 'globalIndicatorCode');
    this.columns.set('gradeCode', new Tcolumn('gradeCode', 'Grade Code', 'LB', 1, false, undefined, false, this.id));
    this.columns.set('qualityParameterCode', new Tcolumn('qualityParameterCode', 'Quality Parameter Code', 'L', 1, true, this.masterCommService.getFromGlobalIndication('quality_parameter_ind', 'codeName', 'globalIndicatorCode'), true, '', 'B', [Validators.required], 'text', 1, undefined, undefined, undefined, actionforName));
    this.columns.set('qualityParameterName', new Tcolumn('qualityParameterName', 'Quality Parameter Name', 'LB', 2, false, undefined, true, '', 'B', [Validators.required, Validators.maxLength(100)], undefined, undefined, undefined, undefined, [], undefined, undefined, true));
    this.columns.set('min', new Tcolumn('min', 'Min', 'N', 3, true, undefined, true, '', 'B', [Validators.min(0)], undefined, 1, undefined, undefined, undefined, action));
    this.columns.set('max', new Tcolumn('max', 'Max', 'N', 4, true, undefined, true, '', 'B', [Validators.min(0)]));
    this.columns.set('typical', new Tcolumn('typical', 'Typical', 'T', 5, true, undefined, true, '', 'B', [Validators.min(0), Validators.maxLength(50)]));
    this.columns.set('basis', new Tcolumn('basis', 'Basis', 'T', 6, true, undefined, true, '', 'B', [Validators.min(0), Validators.maxLength(50)]));
    this.columns.set('unit', new Tcolumn('unit', 'Unit', 'T', 7, true, undefined, true, '', 'B', [Validators.maxLength(50)]));
    this.columns.set('gradeStatus', new Tcolumn('gradeStatus', 'Status', 'B', 8, true, this.masterCommService.getActiveInactive(), true, true, 'B', [Validators.required]));
  }
}
