import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {CountryISO, SearchCountryField} from 'ngx-intl-tel-input';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CtrmTelInputComponent),
  multi: true
};

@Component({
  selector: 'ctrm-tel-input',
  templateUrl: './ctrm-tel-input.component.html',
  styleUrls: ['./ctrm-tel-input.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class CtrmTelInputComponent implements OnInit, ControlValueAccessor, OnChanges,AfterViewInit {

  @Input('value') val: any;
  @Input('name') name: string = '';
  @Input() selectedCountry: string = '';
  @Input() disabled: boolean = false;
  @Input() telClass: string = '';
  @Input() tClass: string = '';
  @Output() telephoneValueChange = new EventEmitter();
  separateDialCode = false;
  oldDialingCode: string = '+91';
  telephoneVal: any;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  splitPattern: any;


  constructor(private changeDetectorRef:ChangeDetectorRef) {
  }

  @Input()
  get telephoneValue() {
    return this.telephoneVal;
  }

  set telephoneValue(val) {
    this.telephoneVal = val;
    this.telephoneValueChange.emit(this.telephoneVal);
  }

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  onTouched: any = () => {
  };


  onChange(val) {
    this.val = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    if (obj) {
      this.convertIntoInputFormat(obj);
      this.value = obj;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedCountry']) {
      if (typeof (changes['selectedCountry'].currentValue) !== 'string') {
        this.selectedCountry = '';
      }

    }
    if (changes['telephoneValue'] && changes['telephoneValue'].currentValue !== undefined && changes['telephoneValue'].currentValue.length > 0) {
      this.convertIntoInputFormat(changes['telephoneValue'].currentValue);
    }
  }

  onChangeValue(value: any) {
    this.convertIntoOutPutFormat(value);
  }

  private convertIntoInputFormat(obj: any) {
    this.value = obj;
  }

  private convertIntoOutPutFormat(obj: any) {
    if (obj !== undefined && obj !== null) {
      if (obj['dialCode'] !== this.oldDialingCode) {
        if (this.telephoneValue !== undefined && this.telephoneValue.length > 0) {
          this.telephoneValue  = this.telephoneValue.replace(this.oldDialingCode, obj['dialCode']);
          obj['number'] = this.telephoneValue;
          obj['internationalNumber'] = this.telephoneValue;
          this.value = obj;
        }
        this.oldDialingCode = obj['dialCode'];
      }
      this.telephoneValue = obj['internationalNumber'];
    }
  }
}
