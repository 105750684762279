<p-sidebar [(visible)]="display" [modal]="false" [showCloseIcon]="false"
           [style]="{minWidth:'70%', maxWidth:'70%'}"
           position="right">
  <div class="headerClass">
    <i (click)="close()" class="material-icons closable">clear</i>
  </div>
  <div class="importLbl" style="margin-top: 20px;height: 81%">
    <div class="row importTxt ">
      <div class="col-12">
        <p>To import the existing data you have to download Xcelar.ai import template.
          Fill in the details and re - upload the document here</p>
        <div style="display: inline-flex;margin-top: 5px">
          <div (click)="export(importTableName,exe)" *ngFor="let exe of acceptedFileTypes" class="downloadButton">
            <i class="fas fa-download downloadIcon"></i>
            <p class="filename">{{importTableName}}.{{exe}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div lass="col-12">
        <p class="upload"> Upload Files </p>
      </div>
    </div>
    <div *ngIf="showBrowser" class="row imageBorder">
      <div class="col-12 divCentre">
         <span style="width: 80%;">
          <p-fileUpload #fileupload (onRemove)="refreshPreview()" (onSelect)="uploadFiles($event.files)"
                        [accept]="getAcceptedList()"
                        [auto]="true" [files]="importFile" [maxFileSize]="fileZile"
                        chooseLabel="Browse"></p-fileUpload>
         </span>
        <p>Drag and Drop data files here or Browse
          supported {{acceptedFileTypes.toString()}} File for import </p>
      </div>
    </div>
    <div [hidden]="!switchDisplay" style="display: flex; padding-left: 30px; padding-top: 20px;">
      <label class="label ng-star-inserted" style="padding-right:20px;">Show Preview</label>
      <p-inputSwitch (onChange)="showPreviewGrid($event)" [(ngModel)]="dataDisplay"></p-inputSwitch>
    </div>
    <div *ngIf="dataDisplay">
      <div *ngIf="importChildData && tabs !== undefined && tabs !== null && tabs.length > 0">
        <p-tabView (activeIndexChange)="onIndexChange($event)">
          <p-tabPanel header="{{importTableName}}">
            <ng-container [ngTemplateOutletContext]="getContext(gridColumns,rowData)"
                          [ngTemplateOutlet]="tableTemplate"></ng-container>
          </p-tabPanel>
          <p-tabPanel *ngFor="let tab of tabs;let i=index" header="{{tab.getLabel()}}">
            <div>
              <div>
                <ng-container [ngTemplateOutletContext]="getContext(getTabColumns(tab),getRowDataList(tab))"
                              [ngTemplateOutlet]="tableTemplate"></ng-container>
              </div>
            </div>
          </p-tabPanel>
        </p-tabView>
      </div>
      <div *ngIf="!importChildData || tabs === undefined || tabs === null || tabs.length === 0">
        <ng-container [ngTemplateOutletContext]="getContext(gridColumns,rowData)"
                      [ngTemplateOutlet]="tableTemplate"></ng-container>
      </div>
    </div>
  </div>
  <div *ngIf="sanitizerURL != undefined" class="row importError ">
    <div class="col-12">
      <p style="color: red;"> Data validation failure noticed. Please refer the csv for column "Reason For Error"</p>
      <a [href]="sanitizerURL" download="Error_{{importTableName}}" style="color:red">
        <i aria-hidden="true" class="fa fa-download"></i> Error_{{importTableName}}
      </a>
    </div>
  </div>
  <div *ngIf="invalidFile" class="row importError ">
    <div class="col-12">
      <p style="color: red;"> Please browse valid file to import</p>
    </div>
  </div>
  <p-footer>
    <div class="buttonContainer">
      <button (click)="close()" class="cancelBtn">Close</button>
      <button *ngIf="!isimporting"
              (click)="import()"
              [disabled]="importDisabled || isimporting" [ngClass]="(importDisabled || isimporting)?'planScreenBtnsdisable':'planScreenBtns'">Import
      </button>
      <div *ngIf="isimporting" class="loadingbutton">
        <div class="loader"></div>
        <p>Importing</p>
      </div>
    </div>
  </p-footer>
</p-sidebar>

<ng-template #tableTemplate let-gridColumns="columns" let-rowData="value">
  <div class="table">
    <p-table #table (inViewportAction)="calculateHeight()"
             [columns]="gridColumns"
             [responsive]="true" [style]="{maxHeight:GridHeight}"
             [value]="rowData"
             autoLayout="true" class="style-4 table-responsive background"
             inViewport>
      <ng-template pTemplate="header">
        <tr class="sticky-top" style="background-color: #D9DBDE">
          <th *ngFor="let col of gridColumns;index as i" [id]="col.field" [pSortableColumn]="col.field"
              class="otherColumn stk" style="white-space: nowrap; background-color: #d9dbde">
            <div class="headerDiv">
              <div class="headerTitle">{{col['header']}}</div>
              <p-sortIcon [field]="col['field']" ariaLabel="Activate to sort"
                          ariaLabelAsc="Activate to sort in ascending order"
                          ariaLabelDesc="Activate to sort in descending order"></p-sortIcon>
              <div class="pipe"></div>
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template let-columns="columns" let-ri="rowIndex" let-rowData pTemplate="body">
        <tr [pSelectableRow]="rowData">
          <td *ngFor="let col of columns" style="padding-top: 15px !important;">
            <p>
              {{commonService.getRowValueFromTcolumn(rowData, col)}}
            </p>
          </td>

        </tr>
      </ng-template>
    </p-table>
  </div>
</ng-template>
