<div style="display: inline-flex;width: 100%">
    <masters-sidenav></masters-sidenav>
    <div id="pinwidth" style="width: 100%;margin-left: 70px">
        <div class="sectionCardTop">
            <div class="breadcrumbPane">
                <ctrm-breadcrumb [collapsabel]="false"
                                 [items]="breadcrumbService.getBreadCrumb()"
                                 [showIcon]="false">
                </ctrm-breadcrumb>
            </div>
            <div class="actionPane">
                <div class="screenDetails">
                    <img [src]="'../../../assets/images/svg/Tenant.svg'"/>
                    <p>Tenant Configuration</p>
                </div>
            </div>
        </div>
        <div style="padding: 10px">
            <div class="sectiondiv" *ngFor="let section of sections">
                <app-tenant-section [section]="section"
                                    [finalJson]="finalJson"
                                    (onSave)="onUpdateSection($event)">
                </app-tenant-section>
            </div>
            <div class="dashborder" (click)="addNewSection()">
                <p>Add New Section</p>
            </div>
        </div>
    </div>
</div>

<p-dialog *ngIf="addSectionState" (onHide)="closeModal()" [(visible)]="addSectionState" [baseZIndex]="25"
          [closable]="false"
          [showHeader]="false"
          [closeOnEscape]="true"
          [draggable]="false" [maximizable]="false" [modal]="true"
          [ngClass]="'dialogClass'">
    <div class="content">
        <div style="padding: 10px">
            <p class="sectiontitle">New Section</p>
        </div>
        <div class="form-content">
            <div class="sectionbox">
                <p>Section Name</p>
                <input [(ngModel)]="sectionName"
                       [placeholder]="'Enter Name'"
                       pInputText>
            </div>
            <div class="sectionbox">
                <p>Description</p>
                <textarea [(ngModel)]="description"
                          [rows]="3"
                          style="height: auto"
                          [placeholder]="'Enter Description'"
                          pInputTextarea></textarea>
            </div>
            <div class="sectionbox" style="display: inline-flex">
                <div style="margin-right: 5px">
                    <p> Property</p>
                    <input disabled pInputText>
                </div>
                <div style="margin-left: 5px">
                    <p>Value</p>
                    <input disabled pInputText>
                </div>
            </div>
            <p class="addnewporp">+ Add New Property</p>
        </div>
    </div>
    <p-footer>
        <div>
            <button class="cancelBtn" (click)="closeModal()">Cancel</button>
            <button class="planScreenBtns" style="padding-left: 20px;padding-right: 20px" (click)="addSection()">Add
            </button>
        </div>
    </p-footer>
</p-dialog>
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>
