


<div class="dashboard">
  <div *ngIf="graphView">
    <div class="screenTitle">Cashflow Dashboard</div>
    <xceler-button-groups (onClick)="onClickButton($event)"
                          [buttonNames]="['Today','Monthly','Quaterly','Half yearly','Yearly']" [selected]="selectedbutton"></xceler-button-groups>
    <div class="graphs">
      <div class="graphCard">
        <div class="topSection">
          <div class="charTextData">
            <p class="chartTitle">Receivable</p>
            <p class="chartData">{{getNumber(totalReceive)}}</p>
            <p class="charSubTitle">Total Trade</p>
            <p class="chartData">{{getNumber(receivableTrade)}}</p>
            <p class="charSubTitle">Total Cost</p>
            <p class="chartData">{{getNumber(receivableCost)}}</p>
          </div>
          <div class="barGraphBox">
            <div [style]="{height:receivableCostHeight}" class="costBar"
                 pTooltip="{{'Cost : '+getNumber(receivableCost)}}" tooltipZIndex="436"></div>
            <div [style]="{height:receivableTradeHeight}" class="tradeBar"
                 pTooltip="{{'Trade : '+getNumber(receivableTrade)}}" tooltipZIndex="436"></div>
          </div>
        </div>
        <div class="bottomSection">
          <div class="legendBox">
            <div class="legend">
              <div class="legendDot tradeLegend"></div>
              <p>Trade</p>
            </div>
            <div class="legend">
              <div class="legendDot costLegend"></div>
              <p>Cost</p>
            </div>
          </div>
        </div>
      </div>
      <div class="graphCard">
        <div class="topSection">
          <div class="charTextData">
            <p class="chartTitle">Payable</p>
            <p class="chartData">{{getNumber(totalPay)}}</p>
            <p class="charSubTitle">Total Trade</p>
            <p class="chartData">{{getNumber(payableTrade)}}</p>
            <p class="charSubTitle">Total Cost</p>
            <p class="chartData">{{getNumber(payableCost)}}</p>
          </div>
          <div class="barGraphBox">
            <div [style]="{height:payableCostHeight}" class="costBar" pTooltip="{{'Cost : '+getNumber(payableCost)}}"
                 tooltipZIndex="436"></div>
            <div [style]="{height:payableTradeHeight}" class="tradeBar"
                 pTooltip="{{'Trade : '+getNumber(payableTrade)}}" tooltipZIndex="436"></div>
          </div>
        </div>
        <div class="bottomSection">
          <div class="legendBox">
            <div class="legend">
              <div class="legendDot tradeLegend"></div>
              <p>Trade</p>
            </div>
            <div class="legend">
              <div class="legendDot costLegend"></div>
              <p>Cost</p>
            </div>
          </div>
        </div>
      </div>
      <div class="graphCard">
        <div class="topSectionPie">
          <div class="charTextData">
            <p class="chartTitle">Cashflow Ratio</p>
          </div>
          <div>
            <canvas [chartType]="pieChartType"
                    [colors]="pieChartColors"
                    [data]="pieChartData"
                    [labels]="pieChartLabels"
                    [legend]="pieChartLegend"
                    [options]="pieChartOptions"
                    [plugins]="pieChartPlugins"
                    baseChart>
            </canvas>
          </div>
        </div>
        <div class="bottomSection">
          <div class="lagenBoxTop">
            <div class="legendBoxMargin">
              <div class="legend">
                <div class="legendDot payableLegend"></div>
                <p>Payable</p>
              </div>
              <div class="legend">
                <div class="legendDot receivableLegend"></div>
                <p>Receivable</p>
              </div>
              <div class="legend">
                <div class="legendDot marginLegend"></div>
                <p>Margin</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="graphCard">
        <div class="topSectionPie">
          <div class="charTextData">
            <p class="chartTitle">Total Revenue</p>
          </div>
          <canvas [chartType]="barChartType"
                  [colors]="barChartColors"
                  [datasets]="barChartData"
                  [labels]="barChartLabels"
                  [legend]="false"
                  [ngClass]="'barChartStyle'"
                  [options]="barChartOptions"
                  baseChart>
          </canvas>
        </div>
      </div>
    </div>
  </div>

  <div [hidden]="!graphView" class="grid">
    <xceler-grid [columns]="columns" [data]="value" [noDataTemplate]="defualtTemplateNoData"
                 [tableStyle]="{backgroundColor:'#15104E'}"></xceler-grid>
  </div>
  <div [hidden]="graphView" class="grid">
    <p-selectButton (onChange)="onChangeTab($event)" [(ngModel)]="tabValue" [options]="tabOptions"></p-selectButton>
    <div *ngIf="tabValue === 'trade'" class="sectionCard borderRadius">
      <div class="header">
        Cashflow Summary
        <div class="ui-g filterBox">
          <div *ngFor="let type of addedFilter" class="filterChip">
            <div class="filterText">{{getTypeLabel(type)}}</div>
            <mat-icon (click)="onClickRemove(type)" class="closeIcon">clear</mat-icon>
          </div>
          <div *ngIf="addedFilter.length > 0" (click)="clearAllFilter()" class="clearAll">Clear all</div>
        </div>

<!--        Date Rangle-->
        <ng-container *ngTemplateOutlet="calendar"></ng-container>
      </div>
<!--      Cashflow Cards-->
      <div class="content">
        <app-dashboard-card
                [cardId]="'Estimated'"
                style="margin-right: 0.5%;width: 19.6%;"
                [cardHeader]="'Estimated'"
                [cardBorderColor]="'#0495ea'"
                [isSelected]="addedFilter.includes('Estimated')"
                (onCardSelected)="onCardSelect($event)"
                (onCardUnselected)="onCardUnselect($event)"
                [stat1]="'Trade'"
                [stat2]="'Cost'"
                [count1]="getCountValue('estimated')"
                [count2]="getCountValue('estimated','costType')">
        </app-dashboard-card>
        <app-dashboard-card
                [cardId]="'Accrued'"
                style="margin-right: 0.5%;width: 19.6%;"
                [cardHeader]="'Accrued'"
                [cardBorderColor]="'#fd5e7e'"
                [isSelected]="addedFilter.includes('Accrued')"
                (onCardSelected)="onCardSelect($event)"
                (onCardUnselected)="onCardUnselect($event)"
                [stat1]="'Trade'"
                [stat2]="'Cost'"
                [count1]="getCountValue('accrued')"
                [count2]="getCountValue('accrued','costType')">
        </app-dashboard-card>
        <app-dashboard-card
                [cardId]="'Provisional'"
                style="margin-right: 0.5%;width: 19.6%;"
                [autoCalculateTotal]="false"
                [total]="getCountValue('invoicedProvisional','total')"
                [cardHeader]="'Invoiced: Provisional'"
                [cardBorderColor]="'#63c630'"
                [isSelected]="addedFilter.includes('Provisional')"
                (onCardSelected)="onCardSelect($event)"
                (onCardUnselected)="onCardUnselect($event)"
                [showStats]="false">
        </app-dashboard-card>
        <app-dashboard-card
                [cardId]="'Final'"
                style="margin-right: 0.5%;width: 19.6%;"
                [cardHeader]="'Invoiced: Final'"
                [cardBorderColor]="'#f19202'"
                [isSelected]="addedFilter.includes('Final')"
                (onCardSelected)="onCardSelect($event)"
                (onCardUnselected)="onCardUnselect($event)"
                [stat1]="'Trade'"
                [stat2]="'Cost'"
                [count1]="getCountValue('invoicedFinal')"
                [count2]="getCountValue('invoicedFinal','costType')">
        </app-dashboard-card>
        <app-dashboard-card
                [cardId]="'DNCN'"
                style="margin-right: 0.5%;width: 19.6%;"
                [cardHeader]="'DN/CN'"
                [cardBorderColor]="'#15104e'"
                [isSelected]="addedFilter.includes('DNCN')"
                (onCardSelected)="onCardSelect($event)"
                (onCardUnselected)="onCardUnselect($event)"
                [stat1]="'Trade'"
                [stat2]="'Cost'"
                [count1]="getCountValue('dncn')"
                [count2]="getCountValue('dncn','costType')">
        </app-dashboard-card>
      </div>
    </div>

    <div *ngIf="showCalendar">
      <ng-container *ngTemplateOutlet="calendar"></ng-container>
    </div>
<!--    Filter Values-->
    <div class="tradeFilter"> Filter by:
      <div class="dropdownpanel" *ngFor="let dropdown of gridFilters">
        <p-multiSelect  (onChange)="onFilterChange($event,dropdown)" [options]="dropdown['list']" [maxSelectedLabels]="1"
                        [defaultLabel]="dropdown['title']"></p-multiSelect>
      </div>
    </div>
    <xceler-grid [filter]="filter" [refreshFilter]="refreshFilter" (onFilterRefreshComplete)="onFilterRefresh()" (onClickClickable)="onClickable($event)"
                 (onClickToolbarButton)="onToolbarAction($event)"
                 (onSelectionChange)="onRowSelectChange($event)" [addSearch]="true" [clickableColumns]="['tradeId']"
                 [columns]="tradeCashflowColumns" [data]="tradeCashflowValues"
                 (onPageChange)="onPageChangeTrade($event)"
                 [selectedRow]="selectedCashflows"
                 [fetching]="tradeRefreshing" [hidden]="tabValue !== 'trade'" [leftFixedColumns]="['type','tradeId','quantityStatus']"
                 [showPlaceHolder]="true"
                 [noDataTemplate]="defualtTemplateNoData" [showCheckbox]="true"
                 [showToolbar]="true" [tableStyle]=" {backgroundColor:'#D9DBDE'}" [leftFixedColumnsWidth]="[200,400,200]">
    </xceler-grid>

    <xceler-grid [filter]="filter" [refreshFilter]="refreshFilter" (onFilterRefreshComplete)="onFilterRefresh()"
                 (onClickToolbarButton)="onToolbarAction($event)" [addSearch]="true"
                 [columns]="fxCashflowColumns" [data]="fxCashflowValues"
                 [clickableColumns]="['fxTradeId']" [fetching]="fxRefreshing" [hidden]="tabValue !== 'fx'"
                 [leftFixedColumns]="['type','fxTradeId','fxType']"
                 [selectedRow]="selectedCashflows"
                 [showPlaceHolder]="true"
                 (onPageChange)="onPageChangeFX($event)"
                 [noDataTemplate]="defualtTemplateNoData" [showCheckbox]="true"
                 (onClickClickable)="onClickableFX($event)"
                 [showToolbar]="true" [tableStyle]=" {backgroundColor:'#D9DBDE'}">
    </xceler-grid>
  </div>
</div>


<ng-template #calendar>
  <div class="calenderCont">
    <div class="calender" (click)="toggleDateRange()">
      <i class="fa fa-calendar" style="margin-left: 5px;align-self: center;font-size: 14px;margin-left: 6px;user-select: none"></i>
      <p>{{getPresetTitle()}}</p>
    </div>
    <obligation-date-range *ngIf="showDateRange"
                           [preDefinedRanges]="presetList"
                           [selectedPresetObject]="selectedPreset"
                           [selectedPreset]="selectedPreset['title']"
                           (onCancel)="onCancel($event)"
                           (onPresetSelected)="onPresetChange($event)">
    </obligation-date-range>
  </div>
</ng-template>

<ng-template #defualtTemplateNoData>
  <div class="noDataBox">
    <img [src]="commonService.getIconPath('emptyStateIcon')" alt="No Data Available" class="iconClass"/>
    <p class="noDataHeader">Cashflow data is not available</p>
  </div>
</ng-template>
<app-trade-info (onClose)="onCloseTradeInfo($event)" *ngIf="tradeInfoDisplay" [detailsOf]="'trade'" [display]="true"
                [rowData]="rowData" [tradeId]="selctedTradeId"></app-trade-info>
<app-fx-planning-info (onClose)="onFxClose($event)" *ngIf="fxInfoDisplay" [display]="true" [fxTradeId]="selctedFXTradeId"
                      [rowData]="fxrowData"></app-fx-planning-info>
<p-sidebar *ngIf="operationDisplay" [(visible)]="operationDisplay" [baseZIndex]="25" [modal]="false"
           [showCloseIcon]="false" [styleClass]="'bottomPane'" position="bottom">
  <div class="bottomSidebarContainer">
    <div class="leftSection">
      <i (click)="hideBottom()" class="material-icons closable">clear</i>
    </div>
    <div class="totalCFContainer">
      <p class="cashflowTotal">{{selectedCashflows.length}}</p>
      <p class="totalCF">Cashflows Selected</p>
    </div>
    <div class="middleSection">
      <i class="fas fa-download middleIcons" pTooltip="Download" tooltipPosition="top" tooltipZIndex="9999"></i>
      <i class="fas fa-file-export middleIcons" pTooltip="Export" tooltipPosition="top" tooltipZIndex="9999"></i>
      <i class="fas fa-print middleIcons" pTooltip="Print" tooltipPosition="top" tooltipZIndex="9999"></i>
    </div>
    <div class="rightSection">
      <button (click)="hideBottom()" class="cancelBtn">Cancel</button>
      <button (click)="getPayload()" [disabled]="sellDisable" class="saveBtn">Generate Invoice</button>
    </div>
  </div>
</p-sidebar>
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>
<app-generate-invoice (onDraftClose)="onDraftClose()"
                      (onSendForApproval)="onSendForApproval()"
                      *ngIf="draftForm"
                      [loading]="isLoadingInvoice"
                      [data]="draftData"
                      [plannedObligation]="selectedObject"
                      [sell]="mode"></app-generate-invoice>
