<div class="sectionCardTop">
  <div class="breadcrumbPane">
    <ctrm-breadcrumb [collapsabel]="false"
                     [items]="breadCrumbService.getBreadCrumb()"
                     [showIcon]="false"></ctrm-breadcrumb>
  </div>
  <div class="actionPane">
    <div class="screenDetails">
      <img [src]="'../../../assets/images/svg/tradePlanning.svg'"/>
      <p>DN/CN dashboard</p>
    </div>
    <div class="autocomplete-box">
      <input (input)="getFilterValue($event)" placeholder="Search" type="text">
      <mat-icon style="color:#cbcbcb">search</mat-icon>
      <!--      <mat-icon *ngIf="isFocused" class="closable" (click)="clearAndClose()">clear</mat-icon>-->
    </div>
    <!--    <input type="text" placeholder="Search" (input)="getFilterValue($event)">-->
    <div class="rightInfo">
      Total {{tradeData.length}} Trades
    </div>
  </div>
</div>


<div class="planningPanel">
  <div class="toolbar">
    <div>
      <ctrm-button (onClick)="tradeRefresh()" icon="fas fa-redo" label="Refresh"></ctrm-button>
      <!--      <div class="float-right">-->
      <!--        <ctrm-button (onClick)="onRowDelete(selectedPlans)" [disabled]="selectedPlans.length === 0 || selectedPlans.length > 1" [label]="'Delete'" icon="fas fa-trash"></ctrm-button>-->
      <!--        <ctrm-button [disabled]="selectedPlans.length === 0 || selectedPlans.length > 1" icon="fas fa-file-export" label="Export"></ctrm-button>-->
      <!--        <ctrm-button [disabled]="selectedPlans.length === 0 || selectedPlans.length > 1" icon="fas fa-print" label="Print"></ctrm-button>-->
      <!--      </div>-->
    </div>
  </div>


  <div class="tableClass">
      <p-table #mainTable [columns]="tradeCols"
               [value]="tradeData"
               (onRowExpand)="openCashflowRow($event)"
               [style.minHeight]="planTableHeight"
               [style]="{width: '100%', maxHeight: planTableHeight}"
               class="style-4 table-responsive"
               dataKey="tradeId">
        <ng-template let-columns pTemplate="header">
          <tr>
            <th *ngFor="let col of columns;index as i">
              <div style="background-color: #D9DBDE; padding: 5px;padding-top: 13px; padding-bottom: 14px">
                <p [ngClass]="getClassForHeading(i)">{{col.header}}</p>
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template let-columns="columns" let-expanded="expanded" let-ri="rowIndex" let-rowData pTemplate="body">
          <tr [pSelectableRow]="rowData">
            <td *ngFor="let col of columns" class="customRow">
              <p *ngIf="col.field !== 'tradeId' && col.field !== 'tradePrice' && col.field !== 'quantity'"
                 class="plannedGrid">
                {{rowData[col.field]}}
              </p>
              <div *ngIf="col.field === 'tradeId'" style="display: flex">
                <p style="margin-left: 5px">
                  <a [pRowToggler]="rowData" href="#">
                    <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" class="togglerIcon"></i>
                  </a>
                </p>
                <p (click)="openTradeInfo(rowData)" class="plannedGrid clickLink"
                   style="padding-top: 2px">{{rowData[col.field]}}</p>
              </div>
              <div *ngIf="col.field === 'tradePrice'" style="display: flex">
                <p class="plannedGrid" style="padding-top: 2px">{{rowData[col.field]}} {{rowData['tradePriceCurrency']}}/{{rowData['tradePriceUom']}}</p>
              </div>
              <div *ngIf="col.field === 'quantity'" style="display: flex">
                <p class="plannedGrid" style="padding-top: 2px">{{rowData[col.field]}} {{rowData['quantityUom']}}</p>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template let-columns="columns" let-rowData pTemplate="rowexpansion">
          <tr>
            <td [colSpan]="columns.length">
              <div class="planningInfoContainer">
                <app-planning-info [columns]="cashFlowCols" [showChkBox]="false"
                                   [value]="rowData['cashFlowBaseMasterList']"></app-planning-info>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr>
            <td [colSpan]="tradeCols.length">
              <div *ngIf="tradeData.length == 0 || tradeData.length == null" style="text-align: center;">
                <div class="noPlans">No Trade Available</div>
                <div>
                  <button (click)="gotoTrade()" class="planScreenBtns">Create Trade</button>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    <p-paginator *ngIf="getPagination()" [rows]="20" [totalRecords]="totalElements" (onPageChange)="onPageChange($event)"></p-paginator>
  </div>
</div>

<app-trade-info (onClose)="closeSidePanel()" *ngIf="display" [detailsOf]="'trade'" [display]="true" [rowData]="rowData"
                [tradeId]="tradeId"></app-trade-info>
