import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {CommonService} from '../../../services/common.service';

@Component({
    selector: 'future-position-card',
    templateUrl: './future-position-card.component.html',
    styleUrls: ['./future-position-card.component.css']
})
export class FuturePositionCardComponent implements OnInit {

    @Input() selected: boolean = false;
    @Input() disabled: boolean = false;
    @Input() futurePosition: any = null;
    @Output() onSelected = new EventEmitter();
    @Output() onUnselected = new EventEmitter();
    @Input() width: any = '100%';
    @Input() expiryMonthFilter: boolean = false;

    constructor(private commonService: CommonService) {
    }

    ngOnInit(): void {
    }

    onSelectionEmit() {
        this.onSelected.emit(this.futurePosition);
    }

    onUnselectionEmit() {
        this.onUnselected.emit(this.futurePosition);
    }

    onSelectionChange(event: any) {
        if (event.target.checked) {
            this.onSelectionEmit();
        } else {
            this.onUnselectionEmit();
        }
    }

}
