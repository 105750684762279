<ctrm-grid [defaultCopy]="false"
           (onClickAdd)="onClickAdd()"
           (onClickEdit)="openInUpdateMode($event)"
            [autoLayout]="true"
           [autoCalculateGridWidth]="false"
           (onSave)="onSave($event)"
           [noDataTemplate]="noData"
           [breadcrumbMapKey]="commonService.RISK_MAP_KEY"
           [currentRoute]="'/cpLimits'"
           [defaultEdit]="false"
           [grid]="grid"
           [refreshGrid]="refreshGrid"
           [saveOnSave]="false"
           [showActions]="false"
           (onClickUniqueKey)="onClickunique($event)"
           (selectedRows)="showApprovalButton($event)">
</ctrm-grid>

<app-input-form [header]="header"
                [formContent]="formcontrol"
                [saveButtonLabel]="saveButtonLabel"
                [formGroup]="formGroup"
                [validationSections]="validationSections"
                [visible]="visible"
                (onSave)="onSaveDone($event)"
                (onClose)="oncloseinputform()"></app-input-form>
<ng-template #formcontrol>
    <form [formGroup]="formGroup">
        <div class="sectionForm">
            <div class="sectionBoxMain">
                <div class="formSectionTitle">
                    <div class="headerTitle">Counterparty Limit Information</div>
                </div>
                <div class="firstLine">

                        <div class="fieldBox">
                            <label class="label ng-star-inserted">Counterparty</label>
                            <p-dropdown
                                    formControlName="counterparty"
                                    [disabled]="DisableCounterParty"
                                    [id]="'counterpartyName'"
                                    [options]="counterpartyCodeList"
                                    appendTo="body"
                                    tooltipEvent="focus" tooltipPosition="bottom"
                                    tooltipZIndex="1000">
                            </p-dropdown>
                        </div>



                    <div class="fieldBox">
                        <label class="label ng-star-inserted">Description</label>
                        <textarea  [rows]="1" pInputTextarea autoResize="autoResize"
                                   id="description" type="text"
                                   formControlName="description"
                                   (input)="commonService.updateInputValidation($event,includedCharacters,true)"
                                   class="form-control form-control-sm textAreaClass"
                                   pInputText placeholder="Description">
                    </textarea>
                    </div>

                    <div class="fieldBox">
                        <label class="label ng-star-inserted">Notification</label>
                        <ctrm-multiselect [allowSearch]="true"
                                          [id]="'notification'"
                                          [disabled]="false"
                                          formControlName="notification"
                                          [list]="notificationList"
                                          [mclass]="'form-control form-control-sm'"
                                          [msClass]="'form-control-normal'"
                                          [multiple]="true"
                                          [outputFormat]="'string'"
                                          [placeholder]="'Select notification'">
                        </ctrm-multiselect>
                    </div>
                </div>
            </div>

            <!--        Volume Limit-->
            <div class="sectionBoxMain">
                <div class="formSectionTitle">
                    <div class="headerTitle">Volume Limit Information</div>
                </div>
                <div class="limitGrid">
                    <app-ctrm-price-table (onAddNewRowComplete)="onNewRowAddComplete()" (onRefreshFormGroupComplete)="onRefreshFromGroupComplete()"
                                          [columns]="volumeLimitCols" [gridDisplay]="true"
                                          (onFormGroupCreated)="onFormGroupCreatedvolume($event)"
                                          [checkValidityBeforeAddingNewRow]="true"
                                          [refreshFormGroup]="refreshFormGroup"
                                          [newRows]="newRows" [refreshNewRows]="refreshAddNewRow"
                                          [value]="volumeValue"
                                          [showNewEntryIcon]="true">
                    </app-ctrm-price-table>
                </div>
            </div>

            <!--        credit Limit-->
            <div class="sectionBoxMain">
                <div class="formSectionTitle">
                    <div class="headerTitle">Credit Limit Information</div>
                </div>
                <div class="limitGrid">
                    <app-ctrm-price-table (onAddNewRowComplete)="onNewRowAddComplete()" (onRefreshFormGroupComplete)="onRefreshFromGroupComplete()"
                                          [columns]="creditLimitCols" [gridDisplay]="true"
                                          [refreshFormGroup]="refreshFormGroup"
                                          (onFormGroupCreated)="onFormGroupCreatedcredit($event)"
                                          [checkValidityBeforeAddingNewRow]="true"
                                          [newRows]="newRows" [refreshNewRows]="refreshAddNewRow"
                                          [value]="creditValue"
                                          [showNewEntryIcon]="true">
                    </app-ctrm-price-table>
                </div>
            </div>

<!--        Tenor Limit-->
        <div class="sectionBoxMain">
            <div class="formSectionTitle">
                <div class="headerTitle">Tenor Limit Information</div>
            </div>
            <div class="limitGrid">
                <app-ctrm-price-table (onAddNewRowComplete)="onNewRowAddComplete()"
                                      (onRefreshFormGroupComplete)="onRefreshFromGroupComplete()"
                                      [columns]="tenorLimitCols"
                                      [gridDisplay]="true"
                                      [refreshFormGroup]="refreshFormGroup"
                                      (onFormGroupCreated)="onFormGroupCreatedtenor($event)"
                                      [checkValidityBeforeAddingNewRow]="true"
                                      [newRows]="newRows"
                                      [maintainRowConfig]="true"
                                      [refreshNewRows]="refreshAddNewRow"
                                      [value]="tenorValue"
                                      [showNewEntryIcon]="true"
                                      (onChangeFieldValue)="onValueChangeTenorFields($event)">
                </app-ctrm-price-table>
            </div>
        </div>

        </div>
    </form>
</ng-template>

<ng-template #noData>
    <div (inViewportAction)="calculateLeft()" [id]="'noDataBoxId'" [style]="{left:noDataBoxLeft}" class="noDataBox"
         inViewport>
        <img [src]="commonService.getIconPath('emptyStateIcon')" class="iconClass"/>
        <p class="noDataHeader">{{showFetching || isError ? loadMessage : noDataLabel}}</p>
        <div class="buttonsBox">
            <div (click)="onClickAdd()" *ngIf="grid.getGridConfig().canAdd" class="noDataButton">
                <p class="noDataButtonLabel">{{'Add ' + grid.getTitle()}}</p>
            </div>
        </div>
    </div>
</ng-template>

<!--Volume Dialog Display-->
<p-dialog (onHide)="onCancelVolume()"
          [(visible)]="volumeDisplay"
          [closeOnEscape]="false"
          [draggable]="false"
          [maximizable]="false" [modal]="true" [showHeader]="false"
          [style]="{zIndex:1000,width: '45%',height: 'auto',minHeight:'70%',maxHeight:'95%', padding:'0px', background:'#ffffff'}">

    <div class="dialogHeading">
        <div>
            <p class="dialogLabel">Volume Limit Information</p>
        </div>
        <div class="formCloseIcon">
            <i (click)="onCancelVolume()" class="material-icons closable" style="float: right;">clear</i>
        </div>
    </div>
    <div class="sectionCardTop" style="max-height: 84% !important;">
        <div class="sectionBody">
        <xceler-grid [columns]="volumeColumns" [data]="volumeData" [noDataTemplate]="defualtTemplateNoData"></xceler-grid>
        </div>
    </div>
</p-dialog>

<!--Credit Dialog Display-->
<p-dialog (onHide)="onCancelLimit()"
          [(visible)]="creditDisplay"
          [closeOnEscape]="false"
          [draggable]="false"
          [maximizable]="false" [modal]="true" [showHeader]="false"
          [style]="{zIndex:1000,width: '46%',height: 'auto',minHeight:'70%',maxHeight:'95%', padding:'0px', background:'#ffffff'}">

    <div class="dialogHeading">
        <div>
            <p class="dialogLabel">Credit Limit Information</p>
        </div>
        <div class="formCloseIcon">
            <i (click)="onCancelLimit()" class="material-icons closable" style="float: right;">clear</i>
        </div>
    </div>
    <div class="sectionCardTop" style="max-height: 84% !important;">
    <div class="sectionBody">
        <xceler-grid [columns]="creditColumns" [data]="creditData" [noDataTemplate]="defualtTemplateNoData"></xceler-grid>
    </div>
    </div>
</p-dialog>

<!--Tenor Dialog Display-->
<p-dialog (onHide)="onCancelTenor()"
          [(visible)]="tenorDisplay"
          [closeOnEscape]="false"
          [draggable]="false"
          [maximizable]="false" [modal]="true" [showHeader]="false"
          [style]="{zIndex:1000,width: '40%',height: 'auto',minHeight:'70%',maxHeight:'95%', padding:'0px', background:'#ffffff'}">

    <div class="dialogHeading">
        <div>
            <p class="dialogLabel">Tenor Limit Information</p>
        </div>
        <div class="formCloseIcon">
            <i (click)="onCancelTenor()" class="material-icons closable" style="float: right;">clear</i>
        </div>
    </div>
    <div class="sectionCardTop" style="max-height: 84% !important;">
    <div class="sectionBody" style="padding-left: 13px;">
        <xceler-grid [columns]="tenorColumns" [data]="tenorData" [noDataTemplate]="defualtTemplateNoData"></xceler-grid>
    </div>
    </div>

</p-dialog>

<ng-template #defualtTemplateNoData>
    <div class="noDataBox">
        <img [src]="commonService.getIconPath('emptyStateIcon')" class="iconClass"/>
        <p class="noDataHeader">No data available </p>
    </div>
</ng-template>

<xceler-bottom-pane [popupDisplay]="approvalDisplay" [rightViewTemplate]="right" (onClose)="onClose()"></xceler-bottom-pane>

<ng-template #right>
    <button (click)="rejectLimit()" class="planScreenBtns" style="margin: 0; margin-right: 15px;width: 100px !important;">Reject</button>
    <button (click)="approveLimit()" class="planScreenBtns" style="margin: 0; margin-right: 15px; width: 100px !important;">Approve</button>
</ng-template>

<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body">
</p-toast>
