import {Component, OnInit} from '@angular/core';
import {Tcolumn} from '../../grid/tcolumn.model';
import {Validators} from '@angular/forms';
import {KeyValue} from '../../grid/key-value.model';
import {Grid} from '../../grid/grid.model';
import {CommonService} from '../../services/common.service';
import {environment} from '../../../environments/environment';
import {Action} from '../../grid/actions.model';
import {MasterService} from '../services/MasterService';
import {defaultValue} from "../../grid/defaultValue.model";
import {TreeNode} from "primeng";

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {

  columns: Map<string, Tcolumn>;
  grid: Grid;
  groupList: KeyValue[] = [];
  hierarchyData: TreeNode[];
  modalStateHierarchy: boolean = false;
  rootName: string;
  selectedRowData: any;
  loadDataList: any[];

  constructor(public commonService: CommonService, private masterCommonService: MasterService) {
  }

  ngOnInit() {
    this.loadGrid();
  }

  public async loadGrid() {
    this.loadDataList = await this.commonService.getLoadDataAsync('location',['data'], [[{key:'name', value:'location_type_ind'}]]).then((next:any) => {
      return next;
    });
    this.getColumns();
    this.grid = new Grid(this.columns, true, true, true, true, 'name', '/api/location/v1/getall?tenantId='+this.commonService.getFromStorage('tenantId'), '', 'Location', undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true);
    this.grid.setSaveUrl(environment.base_url + '/api/location/v1/save');
    this.grid.setUpdateUrl(environment.base_url + '/api/location/v1/update');
    this.grid.hrefUrl = environment.base_url + '/api/location/v1/getbyuuid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&uuid=';
    this.grid.importUrl = environment.base_url + '/api/location/v1/import';
    this.grid.iconName='location';
    this.grid.gridAdvacedFilterURL = '/api/location/v1/getlocationbycriteria?tenantId='+this.commonService.getTenantId();
  }

  public getColumns() {

    let listParentFunc = function (value: any[] = []) {
      let arr: any[] = value[0];
      if (arr.length > 0) {
        arr.splice(0, 1);
        arr.splice(0, 0, new KeyValue('None', 'none'));
      }
      return arr;
    };
    let actionforParentLocation: Action = new Action(environment.base_url + '/api/location/v1/getparentlist?tenantId=' + this.commonService.getFromStorage('tenantId') + '&name=', 'name', 'name');
    actionforParentLocation.setCovertToListRequired(true);
    actionforParentLocation.addFieldToUpdate("parent", undefined, listParentFunc, ['L'], undefined, true, 'listvalues', false, false);

    this.columns = new Map();
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('code', new Tcolumn('code', 'Location Code', 'T', 1, false, undefined, true,new defaultValue('',undefined,['-','_'],undefined,false,true,false,undefined,undefined,true), 'B', [Validators.required, Validators.maxLength(8)], undefined, undefined, undefined, undefined, [], undefined, undefined, true));
    this.columns.set('name', new Tcolumn('name', 'Location Name', 'T', 1, false, undefined, true, new defaultValue('', '', environment.primaryKeyIncludedCharacters), 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionforParentLocation));
    this.columns.set('description', new Tcolumn('description', 'Location Description', 'TA', 3, true, undefined, true, '', 'B', [Validators.maxLength(200)]));
    this.columns.set('parent', new Tcolumn('parent', 'Parent Location', 'L', 4, true, this.getParent(), true, 'none', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined));
    this.columns.set('locationType', new Tcolumn('locationType', 'Location Type', 'L', 5, true, this.masterCommonService.getListFromArray(this.loadDataList['location_type_ind']), true, '', 'B', [Validators.required]));
    this.columns.set('timeZone', new Tcolumn('timeZone', 'Time Zone', 'L', 6, true, this.commonService.getTimeZoneList(), true, this.commonService.getFromStorage('timezone'), 'B', [Validators.required]));
    this.columns.set('isTradeable', new Tcolumn('isTradeable', 'Tradeable', 'B', 7, true, this.masterCommonService.getYesNo(true), true, false, 'B', [Validators.required]));
    this.columns.set('status', new Tcolumn('status', 'Status', 'B', 9, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));
    this.columns.set('origin', new Tcolumn('origin', 'Origin', 'B', 8, true, this.masterCommonService.getYesNo(true), true, false, 'B'));
  }

  private getParent() {
    let parentList = [];
    parentList.push(new KeyValue('None', 'none'));
    return parentList;
  }

  //Hierarchy Code
  openHeirarcy(selectedRow: any) {
    this.selectedRowData = selectedRow[0];
    this.modalStateHierarchy = true;
  }

  closeBtn() {
    this.modalStateHierarchy = false;
  }

  getEntityName() {
    return 'xceler_configservice_location';
  }
}
