export class KeyValue {

  constructor(
    private label: any='Select',
    private value: any=''
  ) {
  }

  public getLabel() {
    return this.label;
  }

  public getValue() {
    return this.value;
  }

  public setLabel(newlabel: any) {
    this.label = newlabel;
  }

  public setValue(newvalue: any) {
    this.value = newvalue;
  }
}

