<app-ctrm-grid-with-tabs *ngIf="loadDataList !== undefined && loadDataList.length != 0"
                         (heirarchyEmitter)="openHeirarcy($event)"
                         (onClickUniqueKey)="onUniqueClick($event)"
                         (onRowDataSave)="onRowDataSave($event)"
                         (onContextMenuIconClick)="contextmenu()"
                         [extraButtons]="grid.getExtraButtons()"
                         [openTabScreen]="openTabScreen"
                         (onTabScreenOpened)="onTabScreenOpened()"
                         [openTabForRow]="tabRow"
                         [openFormForNewEntry]="openFormForNewEntry"
                         (onFormOpenDone)="onFormOpned()"
                         [selectedTab]="selectedTab"
                         [breadcrumbMapKey]="commonService.MASTER_MAP_KEY"
                         [currentRoute]="'/company'"
                         [grid]="grid">
</app-ctrm-grid-with-tabs>
<heirarchy-view (onClose)="closeBtn()"
                *ngIf="modalStateHierarchy"
                [entityName]="getEntityName()"
                [nameField]="'name'"
                [primaryKey]="'name'"
                [rowData]="selectedRowData"
                [titlePrefix]="'Company Hierarchy'"
                [visible]="modalStateHierarchy">
</heirarchy-view>
