<p-table [(selection)]="selectedRows" [autoLayout]="true" [columns]="columns" [responsive]="true"
         [style]="{width: '100%'}"
         [value]="value"
         class="table-responsive"
         selectionMode="multiple">
  <ng-template let-columns pTemplate="header">
    <tr class="sticky-top">
      <th *ngIf="showChkBox" style="width: 3em; background-color: #D9DBDE">
        <p style="background: #D9DBDE; height: 36px;margin: 0;"></p>
      </th>
      <th *ngFor="let col of columns; index as i" [ngStyle]="getStyleIfPossible(col)" class="otherColumn">
        <div class="headerDiv">
          <div class="headerTitle">{{col['header']}}</div>
          <div *ngIf="isNotLast(i)" class="pipe"></div>
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template let-columns="columns" let-ri="rowIndex" let-rowData pTemplate="body">
    <tr>
      <td *ngIf="showChkBox" class="customRow" style="width:3em;padding-left: 8px">
        <p class="plannedGridChkBox">
          <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
        </p>
      </td>
      <td *ngFor="let col of columns" [style]="{minWidth:columnMinWidth}" class="customRow">
        <p *ngIf="col.header === 'Execution Process'" class="plannedGrid"
           style="padding-right: 5%">{{rowData[col.field]}}</p>
        <p *ngIf="col.header !== 'Execution Process' && col.field !== 'status'"
           class="plannedGrid">{{getFormattedData(col, rowData)}}</p>

        <div style="display: inline-flex" *ngIf="col.field === 'status'">
          <p *ngIf="rowData[col.field] === 'COMPLETED'" class="plannedGrid" style="color: green; font-weight: bold">
            Success</p>
          <p *ngIf="rowData[col.field].toUpperCase() === 'COMPLETED WITH WARNING(S)'" class="plannedGrid" style="color: #FFBF00; font-weight: bold">
            Completed with warning</p>
          <p *ngIf="rowData[col.field] === 'FAILED'" class="plannedGrid" style="color: red; font-weight: bold">
            Failed</p>
          <p *ngIf="rowData[col.field] === 'Inprogress'" class="plannedGrid" style="color: grey; font-weight: bold">In
            Progress...</p>
          <i *ngIf="rowData['step'].includes('mtmValidation')" (click)="downloadValidationResult(rowData)" pTooltip="Download Validation Result" tooltipZIndex="9999" class="fas fa-download downloadicon"></i>
          <i *ngIf="!rowData['step'].includes('mtmValidation') && rowData[col.field] === 'FAILED'" pTooltip="{{rowData['message']}}" tooltipPosition="top" tooltipZIndex="9999" class="fas fa-exclamation-circle errorIcon"></i>
        </div>
      </td>

    </tr>
  </ng-template>
</p-table>
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body">
  <ng-template let-message pTemplate="message">
    <div class="p-flex p-flex-column" style="flex: 1">
      <div class="p-text-center">
        <p [innerHTML]="commonService.getSafeHtml(message.summary)"></p>
      </div>
    </div>
  </ng-template>
</p-toast>
