<div class="d-flex flex-column">
    <div class="sectionCardTop flex-fill">
        <div class="d-flex">
            <div class="actionPane">
                <div class="screenDetails">
                    <i (click)="changeRouteToBack()" class="fas fa-chevron-left backIcon"></i>
                    <img [src]="'../../../assets/images/svg/tradePlanning.svg'"/>
                    <p>Transport Details</p>
                </div>
            </div>
            <!--        Delivery progress-->
            <div class="progressDiv align-self-center">
                <span class="dot"></span>
                <div class="progLine"></div>
            </div>
        </div>
    </div>
    <div class="mainWrap flex-fill d-flex" style="height: 85vh">
        <div class="sectionNavDiv flex-fill p-2 ml-3 mt-5">
            <h4 class="secNavHeader text-center">Section Navigation</h4>
            <div class="sectionMenuDiv d-flex flex-column">
                <div *ngFor="let navOption of navigationMenuOption, index as i"
                     [ngClass]="getNavClass(navOption)"
                     (click)="navOptionClick(navOption, i)" class="pt-2 pb-2 d-flex flex-fill align-items-center w-100">
                    <img alt="" class="chkFilterIcon pl-1"
                         src="../../../../assets/images/svg/TransportBookingManagement/{{navOption.icon}}.svg">
                    <p class="secMenuText text-nowrap flex-fill w-100">{{navOption.name}}</p>
                </div>
            </div>
        </div>
        <div class="flex-grow-1 d-flex flex-column p-2" style="max-height: 100%; max-width: 65%">
            <div class="formBtnDiv align-items-center d-flex flex-row-reverse pb-2 flex-shrink-0">
                <div class="dotMenu">
                    <i class="fas fa-ellipsis-h"></i>
                </div>
                <div *ngIf="showSave">
<!--                    <button (click)=onSave() [disabled]="!formControlService.getFormGroup().valid"-->
<!--                            class="planScreenBtns m-0 pl-2 pr-2">Save-->
<!--                    </button>  -->
                    <button (click)=onSave()
                            class="planScreenBtns m-0 pl-2 pr-2">Save
                    </button>
                    <button (click)="changeRouteToBack()" class="cancelButton mr-2">Cancel</button>
                </div>
                <div *ngIf="showEdit">
                    <button (click)=onEdit()
                            class="editScreenBtns m-0 pl-2 pr-2">Edit
                    </button>
                </div>
                <div *ngIf="showUpdate">
                    <button (click)=onUpdate()
                            class="planScreenBtns m-0 pl-2 pr-2">Update
                    </button>
                    <button (click)="changeRouteToBack()" class="cancelButton mr-2">Cancel</button>
                </div>

            </div>
            <div class="formWrap d-flex flex-column overflow-auto">
                <detail-card [bookingValue]="bookingValue"
                             [editing]="editing"
                             [fields]="freightsMeta"
                             [formControlService]="formControlService"
                             [heading]="'Freight Details'"
                             [mandatory]="mandatory"
                             [id]="'Freight Details'"></detail-card>
                <detail-card [hidden]="!isBulk" [bookingValue]="bookingValue" [editing]="editing"
                             [fields]="voyageMeta"
                             [formControlService]="formControlService"
                             [heading]="'Voyage Details'"
                             [id]="'Voyage Details'"></detail-card>
                <detail-card [hidden]="isBulk" [bookingValue]="bookingValue" [editing]="editing"
                             [fields]="receiptsMeta"
                             [formControlService]="formControlService"
                             [heading]="'Receipt Details'"
                             [id]="'Receipt Details'"></detail-card>
                <detail-card [bookingValue]="bookingValue" [editing]="editing"
                             [fields]="vesselMeta"
                             [formControlService]="formControlService"
                             [heading]="'Vessel Details'" (confirmFormBuild)="onFormBuildComplete($event)"
                             [id]="'Vessel Details'"></detail-card>

                <div [id]="'Connecting Vessel Details'" class="d-flex flex-column card-details m-0 mt-3">
                    <div class="p-2 bar">
                        <p class="m-0 ml-2 p-0 heading">Connecting Ports</p>
                    </div>

                    <div class="pl-4 pr-4 pb-2 pt-2">
                        <p class="m-0 ml-2 p-0 heading">Load Port Details</p>
                        <app-ctrm-price-table
                                (onAddNewRowComplete)="onNewRowAddCompleteLoadPort()"
                                [addIntitialRow]="false"
                                [minHeight]="'auto'"
                                [maxHeight]="'auto'"
                                [showReadOnly]="!editing"
                                (onRefreshFormGroupComplete)="onRefreshFromGroupCompleteLoadPort()"
                                [columns]="loadPortColumns" [gridDisplay]="true"
                                [refreshFormGroup]="refreshLoadFormGroup"
                                (onFormGroupCreated)="onFormGroupCreatedLoad($event)"
                                (formGroupStatus)="onFromGroupStatusChangeLoadPort($event)"
                                [newRows]="newLoadPortRows" [refreshNewRows]="refreshAddNewRowLoadPort"
                                [value]="loadPortValues"
                                [showNewEntryIcon]="editing"
                                [showCrossIcon]="editing"
                                [checkValidityBeforeAddingNewRow]="true"
                                (onChangeFieldValue)="onChangeValueLoadPort($event)">
                        </app-ctrm-price-table>
                    </div>
                    <div class="pl-4 pr-4 pb-2 pt-2">
                        <p class="m-0 ml-2 p-0 heading">Unload Port Details</p>
                        <app-ctrm-price-table
                                (onAddNewRowComplete)="onNewRowAddCompleteUnloadPort()"
                                [addIntitialRow]="false"
                                [minHeight]="'auto'"
                                [maxHeight]="'auto'"
                                [showReadOnly]="!editing"
                                (onRefreshFormGroupComplete)="onRefreshFromGroupCompleteUnloadPort()"
                                [columns]="unloadPortColumns" [gridDisplay]="true"
                                [refreshFormGroup]="refreshUnloadFormGroup"
                                (onFormGroupCreated)="onFormGroupCreatedUnload($event)"
                                (formGroupStatus)="onFromGroupStatusChangeUnloadPort($event)"
                                [newRows]="newUnloadPortRows" [refreshNewRows]="refreshAddNewRowUnloadPort"
                                [value]="unloadPortValues"
                                [showNewEntryIcon]="editing"
                                [showCrossIcon]="editing"
                                [checkValidityBeforeAddingNewRow]="true"
                                (onChangeFieldValue)="onChangeValueUnloadPort($event)">
                        </app-ctrm-price-table>
                    </div>
                </div>

                <div [id]="'Attachment Details'" class="d-flex flex-column card-details m-0 mt-3">
                    <div class="p-2 bar">
                        <p class="m-0 ml-2 p-0 heading">Attachment Details</p>
                    </div>
                    <div class="pl-4 pr-4 pb-2 pt-2">

                        <div class="d-flex align-self-start">
                            <ctrm-button (click)="openUploadFile()" icon="fas fa-file-import"
                                         label="Upload New"></ctrm-button>
                            <ctrm-button icon="fas fa-download" [disabled]="selectedAttachment.length !== 1"  (click)="openAttachment($event)"
                                         label="Download"></ctrm-button>
                        </div>
                        <div>
                            <xceler-grid [clickableColumns]="['attachmentName']"
                                         [columns]="attachmentCols" [data]="attachmentData"
                                         [fetching]="attachmentDataFetch"
                                         [selectedRow]="selectedAttachment"
                                         (onSelectionChange)="onRowSelect($event)"
                                         [setHeight]="false"
                                         [showCheckbox]="true"
                                         [showToolbar]="false" [tableStyle]=" {backgroundColor:'#D9DBDE'}">
                            </xceler-grid>
                        </div>
                    </div>
                    </div>

                </div>
            </div>
        <div class="flex-grow-1 d-flex flex-column p-2">
            <div class="auditDiv">
                <div class="auditHeading">
                    <p>Activity Log</p>
                </div>

                <div class="itemContainer">
                    <div class="auditItemDiv d-flex flex-column">
                        <div class="d-flex">
                            <div>
                                <img class="auditItemImage" alt="" src="../../../../assets/images/svg/TransportBookingManagement/container_booked.svg">
                            </div>
                            <div class="flex-grow-1">
                                <div class="d-flex me-auto">
                                    <p class="itemTitle flex-fill m-0">Container Booked</p>
                                    <p class="itemTime flex-fill text-right m-0">2 days ago</p>
                                </div>
                                <p class="itemDesc m-0">Trader Name</p>
                            </div>
                        </div>
                        <div class="auditLine"></div>
                    </div>
                    <div class="auditItemDiv d-flex flex-column">
                        <div class="d-flex">
                            <div>
                                <img class="auditItemImage" alt="" src="../../../../assets/images/svg/TransportBookingManagement/container_booked.svg">
                            </div>
                            <div class="flex-grow-1">
                                <div class="d-flex me-auto">
                                    <p class="itemTitle flex-fill m-0">Container Booked</p>
                                    <p class="itemTime flex-fill text-right m-0">2 days ago</p>
                                </div>
                                <p class="itemDesc m-0">Trader Name</p>
                            </div>
                        </div>
                        <div class="auditLine"></div>
                    </div>
                    <div class="auditItemDiv d-flex flex-column">
                        <div class="d-flex">
                            <div>
                                <img class="auditItemImage" alt="" src="../../../../assets/images/svg/TransportBookingManagement/container_booked.svg">
                            </div>
                            <div class="flex-grow-1">
                                <div class="d-flex me-auto">
                                    <p class="itemTitle flex-fill m-0">Container Booked</p>
                                    <p class="itemTime flex-fill text-right m-0">2 days ago</p>
                                </div>
                                <p class="itemDesc m-0">Trader Name</p>
                            </div>
                        </div>
                        <div class="auditLine"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #noDataTemplate>
    <div class="noDataBox">
        <img [src]="commonService.getIconPath('emptyStateIcon')" alt="No Data Available" class="iconClass"/>
        <p class="noDataHeader">No Attachment Found</p>
    </div>
</ng-template>

<ctrm-uploadfile (onClose)="onCloseFile()" (attachmentDocument)="onClickAttachment($event)" [display]="displayFile"></ctrm-uploadfile>

<!--Toaster Message-->
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body">
</p-toast>













