<ctrm-grid [breadcrumbMapKey]="commonService.RISK_MAP_KEY"
           [currentRoute]="'/futurePrice'"
           [grid]="grid"
           (onSave)="onSave($event)"
           [refreshGrid]="refreshGrid"
           (onRefreshComplete)="refreshGrid = false"
           (onClickAdd)="onClickAdd()"
           [saveOnSave]="false"
           [toolbarConfig]="toolbarConfig">
</ctrm-grid>
<!--Add Future Commodity Sidebar-->
<app-input-form [header]="header"
                [formContent]="formcontorl"
                [saveButtonLabel]="saveButtonLabel"
                [formGroup]="priceForm"
                [validationSections]="validationSections"
                (onSave)="onSaveFuture($event)"
                [visible]="displayOnAdd"
                (onClose)="onClose()">
</app-input-form>

<ng-template #formcontorl>
    <form [formGroup]="priceForm">
        <div class="mainbox">
<!--            Ticker Section-->
            <div class="sectionBoxMain">
                <div class="formSectionTitle">
                    <div class="headerTitle">Ticker Details</div>
                </div>
                <div class="row rowClass">
                    <div class="col-md-4 controlGap contentbox">
                        <div class="fieldBox">
                            <label class="label ng-star-inserted">Ticker</label>
                            <p-dropdown [disabled]="false"
                                        [id]="'ticker'"
                                        [options]="tickerList"
                                        appendTo="body"
                                        tooltipEvent="focus" tooltipPosition="bottom"
                                        tooltipZIndex="1000"
                                        formControlName="ticker">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-md-4 controlGap contentbox">
                        <div class="fieldBox">
                            <label class="label ng-star-inserted">Price Date</label>
                            <input id="futureCommodityPriceDate" type="text"
                                   [bsConfig]="{dateInputFormat:getDateFormat(),adaptivePosition:true}"
                                   class="form-control form-control-sm pickerInput"
                                   bsDatepicker
                                   autocomplete="off"
                                   placeholder="Select Date"
                                   formControlName="futureCommodityPriceDate"
                                   style="border-bottom-right-radius: 5px;border-top-right-radius: 5px;"
                                   tooltipEvent="focus"
                                   tooltipPosition="bottom" tooltipZIndex="1000">
                        </div>
                    </div>
                    <div class="col-md-4 controlGap addImportButton">
                        <div style="padding-top: 15px">
                            <button (click)="addPrice()" [disabled]="!priceForm.valid"
                                    class="addbtn">Add Price
                            </button>
                        </div>
                        <div style="padding-top: 15px">
                            <button (click)="onImportClick(true)" *ngIf="grid.getGridConfig().canImport"
                                    class="importbtn">Import
                            </button>
                        </div>
                    </div>
                </div>
            </div>
<!--            Table Section-->
            <div class="sectionBoxMain">
                <div>
                    <app-ctrm-price-table (onAddNewRowComplete)="onNewRowAddComplete()"
                                          (onImportFileComplete)="onImportComplete()"
                                          (onRefreshFormGroupComplete)="onRefreshFromGroupComplete()"
                                          [columns]="priceColumns"
                                          [combinationColumns]="['ticker','futureCommodityPriceDate']"
                                          [newRows]="newRows"
                                          [refreshNewRows]="refreshAddNewRow"
                                          [fileToImport]="file"
                                          [gridDisplay]="gridDisplay"
                                          [refreshFormGroup]="refreshFormGroup"
                                          [useCustomSort]="true"
                                          (onFormGroupCreated)="onFormGroupCreatedPackage($event)"
                                          [value]="priceValue">
                    </app-ctrm-price-table>
                </div>
            </div>

        </div>
    </form>
</ng-template>

<app-import-grid (onClickImport)="onImport($event)" (onClose)="closeImport()" (onImport)="importDone($event)"
                 [directSave]="false" [display]="displayImport" [gridColumns]="priceColumns"
                 [importTableName]="importTableName" [parentKeyValue]="parentKeyValue"></app-import-grid>

<!--Toaster Message-->
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body">
</p-toast>
