import {Component, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {CommonService} from "../../services/common.service";
import {AbstractControl, FormGroup} from "@angular/forms";
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
import {Tcolumn} from "../../grid/tcolumn.model";
import {NaoFormStatic} from '@naologic/forms';

@Component({
  selector: 'app-input-form',
  templateUrl: './input-form.component.html',
  styleUrls: ['./input-form.component.css']
})
export class InputFormComponent implements OnInit {
   @Input() visible: boolean = false;
   @Output() onClose=  new EventEmitter();
   @Output() onSave =  new EventEmitter();
  @Input() header: any = '';
  @Input() saveButtonLabel = "Save";
  @Input() formGroup:FormGroup;
  @Input() extraButtons:any[] = [];
  focusedSection: string =  '';
  @Input() showValidationBoxBySections: boolean = true;
  @Input() validationSections: any = {};
  @Output() onClickExtraButton = new EventEmitter();
  columnsMap: Map<string, Tcolumn>
  focuedField: string;
  sectionKeys: any[] =[];
  private currentlyFocuedField: string;
  @Input() formContent: TemplateRef<any>;
  @Input() baseZindex: any = 5;
  @Input() saveButtonDisabled: boolean = false

  constructor(public commonService:CommonService) { }

  @HostListener('window:resize', [])
  public onResize() {
    this.calculateSectionHeight();
  }

  ngOnInit(): void {
  }

  close() {
    this.onClose.emit();
  }

  onModalInView() {

  }

  goToField(field: string, section: string) {
    this.focusedSection = section;
    this.focuedField = field;
    smoothScrollIntoView(document.getElementById(field));
    document.getElementById(field).focus();
  }

  isFormGroup(control: AbstractControl): control is FormGroup {
    return !!(<FormGroup>control).controls;
  }

  collectErrors(control: AbstractControl){
    if (this.isFormGroup(control)) {
      return Object.entries(control.controls)
          .reduce(
              (acc, [key, childControl]) => {
                const childErrors = this.collectErrors(childControl);
                if (childErrors) {
                  acc = {...acc, [key]: childErrors};
                }
                return acc;
              },
              null
          );
    } else {
      return control.errors;
    }
  }

  getIconFromValidations(formGroup: FormGroup, fields: any[]) {
    let icon = 'pi pi-check colorTick iconCls';
    let _this = this;
    if(formGroup !== null && formGroup !== undefined) {
      fields.forEach(function (field: any) {
        if(field['grid'] !== null && field['grid'] !== undefined && field['grid']) {
          if(field['field'] !== ''){
            let frmgroup:any = formGroup.controls[field['field']];
            if(Object.keys(NaoFormStatic.getAllErrorsFlat(frmgroup)).length > 0) {
              icon = 'pi pi-times colorCross iconCls';
            } else {
              icon = 'pi pi-check colorTick iconCls';
            }
          }
        } else {
          if (field['field'] !== '') {
            if (formGroup.controls[field['field']].valid && icon !== 'pi pi-times colorCross iconCls') {
              icon = 'pi pi-check colorTick iconCls';
            } else {
              if (!formGroup.controls[field['field']].disabled) {
                icon = 'pi pi-times colorCross iconCls';
              }
            }
          }
        }
      });
    }
    return icon;
  }

  isErrorInField(formGroup: FormGroup, field: any) {
    if(formGroup !== null && formGroup !== undefined) {
      return !formGroup.controls[field].valid;
    }
    return false;
  }

  currentlyEditing(fields: string[]) {
    return fields.includes(this.currentlyFocuedField) ? 'focused' : ''
  }

  currentlyEditingField(field: any) {
    return (field === this.currentlyFocuedField) ? 'focused' : '';
  }

  getKeys() {
    return Object.keys(this.validationSections);
  }

  Save() {
    this.onSave.emit(this.formGroup.value);

  }

  calculateSectionHeight() {
    if (document.getElementById('sectionForm') !== null) {
      let viewportHeight = window.innerHeight;
      let offsetTop = document.getElementById('sectionForm')['offsetTop'];
      let height = viewportHeight - offsetTop - 170;
      document.getElementById('sectionForm').style.maxHeight = height + 'px';
      document.getElementById('sectionForm').style.minHeight = height + 'px';
    }
  }

  hasErrors(field: any) {
    if(Object.keys(field).length > 0) {
      return true;
    }
    return false;
  }

  getErrors(field:any) {
    return NaoFormStatic.getAllErrorsFlat(this.formGroup.controls[field['field']]);
  }

  getKeysFromObject(obj:any) {
    return Object.keys(obj);
  }

  getHeader(field: any, error: string) {
    let num:any[] = new RegExp(/\d+$/).exec(error);
    error = error.replace(/\d+$/, "")
    return field['fields'][error]+' in row '+(parseInt(num[0])+1);
  }

  isHidden(field) {
    return field !== ''?(!this.isErrorInField(this.formGroup,field['field']) || (this.commonService.getValidationErrorMessage(this.formGroup.controls[field['field']], field['header']).length === 0)):true;
  }

  onClickExtButton(extrabutton: any) {
    this.onClickExtraButton.emit({button:extrabutton,values:this.formGroup.value});
  }
}
