import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {environment} from "../../../environments/environment";
import {CommonService} from "../../services/common.service";

@Component({
  selector: 'heirarchy-view',
  templateUrl: './heirarchy-view.component.html',
  styleUrls: ['./heirarchy-view.component.css']
})
export class HeirarchyViewComponent implements OnInit, OnChanges {


  @Input() visible: boolean = false;
  @Input() titlePrefix: string = '';
  @Input() rowData: any;
  @Input() primaryKey: string = '';
  @Input() nameField: string = '';
  @Input() entityName: string = '';
  @Output() onClose = new EventEmitter();
  rootName: string = '';
  hierarchyData: any;

  constructor(private commonService: CommonService) {
  }

  ngOnInit(): void {
  }

  closeBtn() {
    this.onClose.emit();
  }


  getHeirarchy(rowData) {
    let _this = this;
    this.rootName = rowData[this.nameField];
    this.commonService.post(environment.base_url + '/treeStructure/parentHierrarchy', this.commonService.getHierarchyObject(rowData, this.primaryKey, this.entityName,this.nameField)).subscribe(next => {
      _this.hierarchyData = [next];
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['rowData'] && changes['rowData'].currentValue !== undefined && changes['rowData'].currentValue !== null) {
      this.getHeirarchy(changes['rowData'].currentValue);
    }
  }
}
