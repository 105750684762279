<div style="display: inline-flex;width: 100%">
    <masters-sidenav></masters-sidenav>
    <div id="pinwidth" style="width: 100%;margin-left: 80px">
<div class="sectionCardTop">
    <div class="breadcrumbPane">
        <ctrm-breadcrumb [collapsabel]="false"
                         [items]="breadCrumbService.getBreadCrumb()"
                         [showIcon]="false"></ctrm-breadcrumb>
    </div>
    <div class="actionPane">
        <div class="screenDetails">
            <img [src]="'../../../assets/images/svg/transactionIdRef.svg'"/>

            <p>EAI Configuration</p>
        </div>
    </div>
</div>

<!--Display New EOD Report and Run buttons-->
<div class="buttonsContainer">

    <div class="leftBtns">
        <div class="btnDiv btnOrange" (click)="openForm()">
            <i class="fas fa-plus btnIcon"></i>
            <p>New EAI Config</p>
        </div>
    </div>

</div>
<div class="sectionCardTop">
    <div class="tableDiv">
        <p-table #jobTable inViewport (inViewportAction)="calculateJobTableHeight()"
                 [style]="{width: '100%', maxHeight: tableHeight}"
                 [style.minHeight]="apiConfigData.length>0?tableHeight:'66vh'"
                 [(selection)]="selectedConfigs"
                 selectionMode="multiple"
                 [autoLayout]="true"
                 [columns]="apiConfigCols" [value]="apiConfigData" dataKey="url"
                 class="style-4 table-responsive">

            <ng-template let-columns pTemplate="header">
                <tr class="stk">
                    <th class="stk" style="width: 3em">
                        <p style="background-color: #D9DBDE; padding-top: 11px; text-align: center;">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </p>
                    </th>
                    <th class="stk" *ngFor="let col of columns; index as i">
                        <div style="background-color: #D9DBDE; padding: 5px;padding-top: 13px; padding-bottom: 14px">
                            <p [ngClass]="getClassForHeading(i)">{{col.header}}</p>
                        </div>
                    </th>
                </tr>
            </ng-template>

            <ng-template let-columns="columns" let-expanded="expanded" let-ri="rowIndex" let-rowData pTemplate="body">
                <tr>
                    <td class="customRow" style="width:3em; border: none">
                        <p class="plannedGridChkBox">
                            <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                        </p>
                    </td>

                    <td *ngFor="let col of columns; index as i" class="customRow" style="border: none">
                        <p class="plannedGrid">
                            {{commonService.getRowValue(rowData, col)}}
                        </p>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr>
                    <td [colSpan]="apiConfigCols.length + 3">
                        <div *ngIf="apiConfigData.length === undefined || apiConfigData.length === null || apiConfigData.length === 0"
                             class="placeHolder">
                            <div class="noPlans">No APIs Available</div>
                            <div>
                                <button (click)="onCreateAPIConfigs()" class="planScreenBtns">Create EAI Config</button>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

</div>
<p-sidebar *ngIf="display" [(visible)]="display" [showCloseIcon]="false"
           [style]="{minWidth:'50%',maxWidth:'60%',height:'100%', marginTop:'61px'}"
           position="right" [baseZIndex]="5" [ngStyle]="{maxHeight: dialogHeight}"
           autoLayout="true" class="style-4 table-responsive">

    <div class="headerClass">
        New EAI Config
        <i class="material-icons closable" (click)="closeForm()">clear</i>
    </div>
    <div class="formBox"></div>

    <div class="sectionForm style-4" [id]="'sectionForm'">
        <div class="sectionBoxMain">

            <div class="firstLine">

                <div class="fieldBox">
                    <label class="label ng-star-inserted">Business Application</label>
                    <p-dropdown [(ngModel)]="businessApplication"
                                [disabled]="false"
                                [id]="'authorization'"
                                [options]="businessApplicationType"
                                appendTo="body"
                                tooltipEvent="focus" tooltipPosition="bottom"
                                tooltipZIndex="1000">
                    </p-dropdown>
                </div>

                <div class="fieldBox">
                    <label class="label ng-star-inserted">Trigger Action</label>
                    <p-dropdown [(ngModel)]="triggerAction"
                                [disabled]="false"
                                [id]="'authorization'"
                                [options]="triggerActionType"
                                appendTo="body"
                                tooltipEvent="focus" tooltipPosition="bottom"
                                tooltipZIndex="1000">
                    </p-dropdown>
                </div>


            </div>
            //second Line
            <div class="secondLine">

                <div class="fieldBox">
                    <label class="label ng-star-inserted">API Type</label>
                    <p-dropdown [(ngModel)]="apiType"
                                [disabled]="false"
                                [id]="'apiType'"
                                [options]="apiTypeList"
                                appendTo="body"
                                tooltipEvent="focus" tooltipPosition="bottom"
                                tooltipZIndex="1000">
                    </p-dropdown>
                </div>

                <div class="fieldBox">
                    <label class="label ng-star-inserted">Authorization</label>
                    <p-dropdown [(ngModel)]="authorization"
                                [disabled]="false"
                                [id]="'authorization'"
                                [options]="authorizationType"
                                appendTo="body"
                                tooltipEvent="focus" tooltipPosition="bottom"
                                tooltipZIndex="1000">
                    </p-dropdown>
                </div>

            </div>


            <div class="secondLine">
                <div class="fieldBox" style="width: 420px">
                    <label class="label ng-star-inserted">URL</label>
                    <input [(ngModel)]="url"
                           type="text"
                           class="form-control form-control-sm input"
                           pInputText placeholder="url">
                </div>
            </div>

            <p-tabView>
                <p-tabPanel header="Params">
                    <div class="formTableDiv">
                        <p-table [value]="paramsData" dataKey="key">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="plannedGrid">
                                        <p class="noPipe">Key</p>
                                    </th>
                                    <th>
                                        <p class="headerNormal">Value</p>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-params>
                                <tr>
                                    <td pEditableColumn>
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input pInputText class="form-control form-control-sm input" type="text"
                                                       [(ngModel)]="params.key"
                                                       placeholder="'Key'">
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <p class="plannedGrid">{{params.key}}</p>
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td pEditableColumn>
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input pInputText class="form-control form-control-sm input" type="text"
                                                       [(ngModel)]="params.value"
                                                       (keydown)="onEditChange('param')"
                                                       placeholder="'Value'">
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <p class="plannedGrid">{{params.value}}</p>
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </p-tabPanel>

                <p-tabPanel header="Headers">
                    <div class="formTableDiv">
                        <p-table [value]="headersData" dataKey="key">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="plannedGrid">
                                        <p class="noPipe">Key</p>
                                    </th>
                                    <th>
                                        <p class="headerNormal">Value</p>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-params>
                                <tr>
                                    <td pEditableColumn>
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input pInputText class="form-control form-control-sm input" type="text"
                                                       [(ngModel)]="params.key"
                                                       placeholder="'Key'">
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <p class="plannedGrid">{{params.key}}</p>
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td pEditableColumn>
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input pInputText class="form-control form-control-sm input" type="text"
                                                       [(ngModel)]="params.value"
                                                       (keydown)="onEditChange('header')"
                                                       placeholder="'Value'">
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <p class="plannedGrid">{{params.value}}</p>
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
    <p-footer>
        <div class="footerClass" style="border-top: 1px solid #ededed; padding-left: 2%">
            <div>
                <button pButton type="button" label="Cancel" class="cancelBtn" (click)="closeForm()"></button>
                <button (click)="saveConfig()" class="save">Save</button>
                <!--          <button pButton type="submit" label="Save" (click)="saveEod()" class="savebutton" style="margin-right: 1%;"></button>-->
            </div>

        </div>
    </p-footer>

</p-sidebar>
    </div>
</div>

