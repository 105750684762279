<ctrm-grid [refreshGrid]="refreshGrid" [grid]="grid" (onClickUniqueKey)="onClickClickable($event)"
           [selectedRow]="selectedRows"
           [breadcrumbMapKey]="commonService.OPERATION_MAP_KEY"
           [fieldsWithCustomView]="['button']"
           [customViewTemplated]="[generatebutton]"
           [currentRoute]="'/pdfrpa'"
           (selectedRows)="onRowSelectionChange($event)"></ctrm-grid>
<xceler-bottom-pane [popupDisplay]="popupDisplay" [rightViewTemplate]="right"
                    (onClose)="onClose()"></xceler-bottom-pane>
<ng-template #right>
    <button (click)="review()" class="planScreenBtns">Review</button>
</ng-template>
<app-rpa-result *ngIf="(response !== null && response !== undefined)" [modalState]="modalState"
                [header]="(response !== null && response !== undefined)?'Attachment id : '+response['Attachment_ID']:''"
                [response]="response" (onCloseModal)="onModalClose()"></app-rpa-result>

<p-sidebar *ngIf="displayReview" [(visible)]="displayReview" [showCloseIcon]="false"
           [style]="{minWidth:'50%',maxWidth:'65%',height:'100%', marginTop:'100px'}"
           position="right" [baseZIndex]="5" [ngStyle]="{maxHeight: height}"
           (onHide)="onClose()"
           autoLayout="true" class="style-4 table-responsive">
    <div class="headerClass" style="padding-top: 20px !important; padding-bottom: 30px;">
        Results Review
        <i class="material-icons closable" (click)="onSidebarClose()">clear</i>
    </div>
    <div class="row">
        <div class="fieldBox sidebarIdMultiselect sidebaridheader">
            <label class="label ng-star-inserted">Trade id</label>

            <p-dropdown
                    [options]="tradeidList"
                    (ngModelChange)="onSelectTradeID($event)"
                    [(ngModel)]="tradeid"
                    [id]="'tradeid'"
                    [disabled]="false"
                    [filter]="true"
                    filterBy="label"
                    [placeholder]="'Trade Id'">
            </p-dropdown>
        </div>
        <div class="fieldBox sidebarIdMultiselect sidebaridheader">
            <label class="label ng-star-inserted">Obligation id</label>
            <p-dropdown
                    [options]="obligationidList"
                    (ngModelChange)="onSelectObligationID($event)"
                    [(ngModel)]="obligationid"
                    [id]="'obligationid'"
                    [disabled]="false"
                    [filter]="true"
                    filterBy="label"
                    [placeholder]="'Obligation Id'">
            </p-dropdown>
        </div>
    </div>
    <div>
        <div class="infoBox">
            <table class="resultsReviewTable">
                <tbody>
                <tr *ngFor="let key of getKeyList()">
                    <th class="resultsReviewTableHead" style="padding-left: 10px !important;">{{key}}</th>
                    <td class="resultsReviewTablebody"><input [(ngModel)]="selectedRows[0]['Response'][key]"
                                                              [type]="'text'" class="form-control form-control-sm"
                                                              pInputText placeholder="value"></td>

                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <p-footer>
        <div class="footerClass footerClassSection">
            <div style="display: inline-flex">
                <button (click)="approve()" [disabled]="tradeid === undefined || tradeid === null ||
                 obligationid === undefined || obligationid === null" class="planScreenBtns" style="margin-left: 10%;">
                    Approve
                </button>
                <button (click)="reject()" [disabled]="tradeid === undefined || tradeid === null ||
                 obligationid === undefined || obligationid === null" class="planScreenBtns" style="margin-top: 10%;">
                    Reject
                </button>
            </div>
        </div>
    </p-footer>


</p-sidebar>

<ng-template #generatebutton let-rowData="rowData">
    <button (click)="generateDoc(rowData)">Generate Invoice (AID : {{rowData.Attachment_ID}} )</button>
</ng-template>

<app-generate-proforma-invoice
        *ngIf="displayInvoiceForm"
        [invoiceObject]="invoiceProFormaObject"
        [isPurchaseInvoice]="true"
        (onDraftClose)="onDraftClose()"
        (onGenerateDone)="onGenerateDone()"
        [tradeId]=""
        [sell]="true"></app-generate-proforma-invoice>
