import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
  name: 'safe',
})
export class SafePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }
  transform(url: string,type:string = 'url'): any {
    if(type === 'html') {
      return this.sanitizer.bypassSecurityTrustHtml(url);
    } else {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }
}
