import {Component, OnInit} from '@angular/core';
import {Tcolumn} from 'src/app/grid/tcolumn.model';
import {Grid} from 'src/app/grid/grid.model';
import {Validators} from '@angular/forms';
import {MasterService} from "../services/MasterService";


@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: []
})
export class PortfolioComponent implements OnInit {
  columns: Map<string, Tcolumn>;
  grid: Grid;


  constructor(private masterCommService: MasterService) {

  }

  ngOnInit() {
    this.getColumns();
    this.grid = new Grid(this.columns, true, true, true, true, 'portfolioCode', 'portfolios', '', 'Portfolio');
  }

  public getColumns() {
    this.columns = new Map();
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('portfolioCode', new Tcolumn('portfolioCode', 'Portfolio Code', 'T', 1, false, undefined, true, '', 'B', [Validators.required]));
    this.columns.set('portfolioName', new Tcolumn('portfolioName', 'Portfolio Name', 'T', 2));
    this.columns.set('portfolioGroup', new Tcolumn('portfolioGroup', 'Portfolio Group', 'T', 3));
    this.columns.set('portfolioDescription', new Tcolumn('portfolioDescription', 'Portfolio Description', 'TA', 4));
    this.columns.set('timeZone', new Tcolumn('timeZone', 'Time Zone', 'T', 5));
    this.columns.set('status', new Tcolumn('status', 'Status', 'B', 6, true, this.masterCommService.getActiveInactive(), true, true, 'B', [Validators.required]));

  }

}
