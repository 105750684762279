import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {MessageService, Table} from "primeng";
import {CommonService} from "../../../services/common.service";
import {BreadcrumbService} from "../../../Components/ctrm-breadcrumb/breadcrumb.service";

@Component({
  selector: 'app-eod-history',
  templateUrl: './eod-history.component.html',
  styleUrls: ['./eod-history.component.css']
})
export class EodHistoryComponent implements OnInit, OnChanges {
  secondLevel: boolean = false;
  secondLevelTitle: string = '';
  eodHistoryData: any[] = [];
  selectedEod: any[] = [];
  historyCols: any[];
  historyDetailsCols: any[];
  @Output() onInProgressEventFound = new EventEmitter();
  @Output() eodhistoryClose = new EventEmitter();
  @Output() onRefreshComplete = new EventEmitter();
  @ViewChild('historyTable', {static: true}) table: Table;
  historyHeight: string = '';
  @Input() jobName: string = '';
  @Input() data: any[] = [];
  @Input() refresh:boolean = false;
  eodHistorydisplay: boolean = true;
  expandedRows: any = {};

  constructor(public commonService: CommonService, private messageService: MessageService, public breadCrumbService: BreadcrumbService) {
  }

  @HostListener("window:resize", [])
  public onResize() {
    this.calculatehistoryHeight(this.table);
  }

  ngOnInit(): void {

    this.historyCols = [
      {field: 'taskRunName', header: 'Job Execution Id'},
      {field: 'runByUser', header: 'Executed By'},
      {field: 'startTime', header: 'Run Date', type: 'date'},
      {field: 'valueDate', header: 'Value Date', type: 'date'},
      {field: 'startTime', header: 'Start Time', type: 'time'},
      {field: 'endTime', header: 'End Time', type: 'time'},
      {field: 'duration', header: 'Duration',type:'duration'},
      {field: 'status', header: 'Status'}
    ];

    this.historyDetailsCols = [
      {field: 'step', header: 'Execution Process'},
      {field: 'startDateTime', header: 'Start Time', type: 'time'},
      {field: 'endDateTime', header: 'End Time', type: 'time'},
      {field: 'duration', header: 'Duration',type:'duration'},
      {field: 'status', header: 'Status'}
    ];
  }


  fetchChildData($event: any) {

  }


  getClassForHeading(index) {
    return index > 0 ? 'headerNormal' : 'noPipe';
  }

  onClickClickable($event: any) {

  }

  onUniqueClick($event: any) {

  }

  closeEod() {
    this.eodhistoryClose.emit(false);
  }

  calculatehistoryHeight(table: Table) {
    if (this.table === undefined || this.table === null) {
      this.table = table;
    }
    let offsetTop = table.el.nativeElement['offsetTop'];
    let offsetHeight = table.el.nativeElement['offsetParent']['offsetHeight'];
    this.historyHeight = offsetHeight - (offsetTop + 100) + 'px';
  }

  planRefresh() {
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url + '/api/eodTemplate/v1/getEODRunHistory?eodJobName=' + this.data['eodTemplateName']+'&tenantId='+this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
      _this.eodHistoryData = next;
      if(_this.eodHistoryData !== null && _this.eodHistoryData !== undefined && _this.eodHistoryData.length > 0) {
        if(_this.eodHistoryData[0]['status'].toString().toUpperCase() === 'IN PROGRESS') {
          _this.onInProgressEventFound.emit(_this.eodHistoryData[0]);
          _this.expandRow(_this.eodHistoryData[0]);
        }
      }
      _this.onRefreshComplete.emit();
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    let _this = this;
    if (changes['data']) {
      this.planRefresh();
    }
    if(changes['refresh']) {
      if(changes['refresh'].currentValue) {
        this.planRefresh();
      }
    }
  }

  private expandRow(rowData) {
    this.expandedRows[rowData['taskRunName']] = true;
  }

  getFormattedData(col: any, data: any) {
    if (data !== undefined && data !== null) {
      if (col['type'] === 'date') {
        return this.getDate(data[col['field']]);
      } else if (col['type'] === 'time') {
        return this.getTime(data[col['field']]);
        // return data.toString().substr(11,data.toString().length);
      } else if(col['type'] === 'duration' && data['startTime'] !== null && data['endTime'] !== null) {
        return this.getDuration(data['startTime'],data['endTime'])
      } else {
        return data[col['field']];
      }
    }
  }

  getDate(date) {
    let options = {day: 'numeric', month: 'long', year: 'numeric'};
    let newDate = new Date(date);
    return newDate.toLocaleDateString("en-US", options);
  }

  getTime(time) {
    return this.commonService.getFormattedDate(new Date(time),"hh:mm:ss a");
  }

  getDuration(timeStart:string,timeEnd:string) {
    let startDate = new Date(timeStart);
    let endDate = new Date(timeEnd);
    let diff = (endDate.getTime() - startDate.getTime()) / 1000;
    return this.commonService.getDurationStringV2(diff);
  }

  getClassForStatus(value: any) {
    if(value === 'Completed') {
      return 'successLabel';
    } else if(value === 'Failed') {
      return 'failedLabel';
    } else if(value.toUpperCase() === 'COMPLETED WITH WARNING(S)') {
      return 'completeWithWarningLabel';
    } else {
      return 'publishedLabel';
    }
  }
}
