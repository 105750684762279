
<p-dialog (onHide)="closeModal()" [(visible)]="modalState" [baseZIndex]="9999"
          [closeOnEscape]="true"
          [closable]="false"
          [contentStyle]="" [draggable]="false" [maximizable]="false" [modal]="true"
          [positionTop]="96" [style]="{ width: '50%',height: 'auto',maxHeight:'80%', padding:'0px'}">
    <p-header [style.color]="'#000'">
        {{fileName}}
        <i (click)="closeModal()" class="material-icons closable">clear</i>
    </p-header>
    <div class="content">
        <div *ngIf="isLoading && !showError">
            <div class="fetchingDataOnForm">
                <i class="fas fa-spinner fa-pulse fa-2x animated" style="color: rgba(0,0,0,0.51);"></i>
                <p style="font-size: 30px;font-weight: bold;text-align: center;color: rgba(212,212,212,0.58);margin-top: 15px;padding: 0">
                    Please wait</p>
            </div>
        </div>
        <div *ngIf="showError">
            <div class="fetchingDataOnForm">
                <p style="font-size: 30px;font-weight: bold;text-align: center;color: rgba(105,105,105,0.58);margin-top: 15px;padding: 0">
                    Error</p>
                <p style="font-size: 20px;font-weight: bold;text-align: center;color: rgba(105,105,105,0.58);margin-top: 15px;padding: 10%" [innerHTML]="errorMessage"></p>
            </div>
        </div>
        <iframe  *ngIf="!isLoading && !showError"
                 title="pdf_viewer"
                 [src]="pdfUrl | safe"
                 width="100%" style="min-height: 69vh"
                 height="100%" ></iframe>
    </div>
</p-dialog>
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>
