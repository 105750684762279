import {AfterViewInit, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {MasterService} from '../../masters/services/MasterService';
import {ConfirmationService, MessageService, Table} from 'primeng';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CommonService} from '../../services/common.service';
import {BreadcrumbService} from '../../Components/ctrm-breadcrumb/breadcrumb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {HttpErrorResponse} from '@angular/common/http';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {messages} from '../../services/common/messages';
import {ExcelServiceService} from '../../services/excel-service.service';
import {Tcolumn} from '../../grid/tcolumn.model';
import {Filter} from '../../grid/preference/filter/filter.model';
import {KeyValue} from '../../grid/key-value.model';
import {FormControlServiceService} from "../../services/form-control-service.service";
import {AccessPolicyService} from "../../services/accesspolicy.service";


@Component({
  selector: 'app-price-fixation',
  templateUrl: './price-fixation.component.html',
  styleUrls: ['./price-fixation.component.css'],
  providers: [ConfirmationService]
})
export class PriceFixationComponent implements OnInit,AfterViewInit {

  tabValue: string;
  priceData: any[] = [];
  isRefreshing: boolean = false;
  selectedPlans: any[] = [];
  quantityRoundOff:number=3;
  priceRoundOff:number=2;
  isChildSelected:boolean = false;
  matchingScreenOpen: boolean = false;
  @ViewChild('priceTable', {static: true}) priceTable: Table;
  priceTableHeight: string = '';
  divEl: HTMLDivElement;
  filters:Filter[] = [];
  pageSize:number = 0;
  totalElements: number = 0;
  totalPages:number = 0;
  gridHeight: any = '300px';
  columns:Tcolumn[] =[];
  expandedRows = {};
  //Labels
  private selectedChilds: any[] = [];
  tradeCols: any[] = [];
  priceCols: any[];
  currentSelectedRow: number = -1;
//sidebar
  priceDisplay: boolean = false;
  obligationId: string = '';
//dialog bar
  dialogDisplay: boolean = false;
  //Data to display on side-bar
  commodity: string = '';
  uomName: string = '';
  canChangeAutoAllocation: boolean = false;
  totalQuantity: number = 0;
  currentPageNumber:number = 0;
  pricedQuantity: number = 0;
  unpricedQuantity: number = 0;
  averagePrice: any = 0;
  loadingMessage: string = "Loading ...";
  fixedPrice: number = 0;
  uom: string = '';
  currencyType: string = '';
  //sticky Header
  leftFixedColumns: any[] = ['obligationId','tradeId','tradeDate', 'tradeTransactionType', 'priceType', 'priceStatus'];
  leftFixedColumnsWidth: number[] = [];
  rightFixedColumns: any[] = [];
  rightFixedColumnsWidth: number[] = [];
  fixColumnsWidth: number = 200;
  //trade details sidebar
  rowData: any = {};
  tradedisplay: boolean = false;
  noDataBoxLeft: any = '';
  //Future Table
  displayFuture: boolean = false;
  autoAllocate:boolean=true;
  futureGridHeight: string = '';
  @ViewChild('table') table: Table;
  futureCols: any[];
  futureData: any[] = [];
  priceLineType = 'Physical';
  disableSave: boolean = false;
  errorDisplay: boolean = false;
  lastSelectedRow: any = {};
  bsConfig: Partial<BsDatepickerConfig>;
  deleteall:boolean=true;
  tradeIdNumber: any;
  fixColumnNames: Map<string,string>;
  routingParams:any;
  priceValue:any;
  minPriceDate: any = '';
  savePriceLineApiFunc: Function;
  addPricePolicy:boolean=true;
  deletePolicy:boolean=true;
  tradeId: string = '';
  priceUom :string;
  quantityUom:string;
  allocatePriceDisabled:boolean=false;
  deAllocatePriceDisabled:boolean=false;
  priceAutoAllocation:boolean=true;


  constructor(public masterCommonService: MasterService, private confirmationService: ConfirmationService,
              private formBuilder: FormBuilder, public commonService: CommonService, private messageService: MessageService,
              public breadCrumbService: BreadcrumbService, private router: Router,private excelService: ExcelServiceService,
              private route: ActivatedRoute,private accessPolicyService:AccessPolicyService) {
  }
   formControlService: FormControlServiceService = new FormControlServiceService();

  ngAfterViewInit(): void {
    this.calculateHeight(this.priceTable);
  }

  ngOnInit(): void {
    let _this=this;
    this.addPricePolicy=this.accessPolicyService.canAccessWithCurrentRoute('Action','Add Price');
    this.deletePolicy=this.accessPolicyService.canAccessWithCurrentRoute('Grid Access','delete');
    this.allocatePriceDisabled=!this.accessPolicyService.canAccessWithCurrentRoute('Action', 'allocatePrice');
    this.deAllocatePriceDisabled=!this.accessPolicyService.canAccessWithCurrentRoute('Action', 'deAllocatePrice');
    this.priceAutoAllocation=_this.commonService.getFromTenantConfig(false, "invoiceConfig", "priceAutoAllocation");
    this.autoAllocate=_this.priceAutoAllocation;
    this.canChangeAutoAllocation = this.accessPolicyService.canAccessWithCurrentRoute('Action', 'autoAllocate');
    this.breadCrumbService.makeBreadcrumbTo(this.commonService.OPERATION_MAP_KEY, '/priceFixing');
    this.bsConfig = new BsDatepickerConfig();
    this.bsConfig.dateInputFormat = environment.dateFormat.toUpperCase();
    this.bsConfig.maxDate=new Date();
    this.bsConfig.adaptivePosition = true;

    let df=this.quantityRoundOff;

    let tradeQuantityFunction = function (value: any) {
      return _this.commonService.formatNumberWithoutComma(value['tradeQuantity'],df) + ' ' + value['tradeQuantityUom'];
    };

    let priceQuantityFunction = function (value: any) {
      return _this.commonService.formatNumberWithoutComma(value['pricedQuantity'],df) + ' ' + value['tradePriceUom'];
    };

    let priceQuantityUomFunction = function (value: any) {
      return _this.commonService.formatNumberWithoutComma(value['pricedQuantity'],df) + ' ' + value['tradePriceUom'];
    };
    let allocatedQuantityFunction = function (value: any) {
      return _this.commonService.formatNumberWithoutComma(parseFloat(value['pricedQuantity']) - parseFloat(value['balanceQuantity']),df) + ' ' + value['tradePriceUom'];
    };


    let unpriceQuantityFunction = function (value: any) {
      return _this.commonService.formatNumberWithoutComma(value['balanceQuantity'],df) + ' ' + value['tradePriceUom'];
    };

    let averagePriceFunction = function (value: any) {

      return  _this.commonService.formatNumber(value['averagePrice'],'','','en-US',_this.priceRoundOff) + ' ' + value['tradePriceCurrency'] + '/' + value['tradePriceUom'];
    };
    let provisionalPriceFunction = function (value: any) {
      return  _this.commonService.formatNumber(value['provisionalPrice'],'','','en-US',_this.priceRoundOff) + ' ' + value['tradePriceCurrency'] + '/' + value['tradePriceUom'];
    };
    let tradePriceFunction = function (value: any) {

      return  _this.commonService.formatNumber(value['tradePrice'],'','','en-US',_this.priceRoundOff) + ' ' + value['tradePriceCurrency'] + '/' + value['tradePriceUom'];
    };

    let totalPriceFunction = function (value: any) {
      return _this.commonService.formatNumber(value['total'],'','','en-US',_this.priceRoundOff) + ' ' + value['tradePriceCurrency'] + '/' + value['tradePriceUom'];
    };

    let basisFunction = function (value: any) {
      return _this.commonService.formatNumber(value['basis'],'','','en-US',_this.priceRoundOff) + ' ' + value['tradePriceCurrency'] + '/' + value['tradePriceUom'];
    };

    let basePriceFunction = function (value: any) {
      return _this.commonService.formatNumber(value['basePrice'],'','','en-US',_this.priceRoundOff) + ' ' + value['tradePriceCurrency'] + '/' + value['tradePriceUom'];
    };

    let freightFunction = function (value: any) {
      return _this.commonService.formatNumber(value['freight'],'','','en-US',_this.priceRoundOff) + ' ' + value['tradePriceCurrency'] + '/' + value['tradePriceUom'];
    };

    let insurancetFunction = function (value: any) {
      return _this.commonService.formatNumber(value['insurance'],'','','en-US',_this.priceRoundOff) + ' ' + value['tradePriceCurrency'] + '/' + value['tradePriceUom'];
    };

    let commissionFunction = function (value: any) {
      return _this.commonService.formatNumber(value['commission'],'','','en-US',_this.priceRoundOff) + ' ' + value['tradePriceCurrency'] + '/' + value['tradePriceUom'];
    };

    let premiumFunction = function (value: any) {
      return _this.commonService.formatNumber(value['premium'],'','','en-US',_this.priceRoundOff) + ' ' + value['tradePriceCurrency'] + '/' + value['tradePriceUom'];
    };

    let rolloverFunction = function (value: any) {
      return _this.commonService.formatNumber(value['rollover'],'','','en-US',_this.priceRoundOff) + ' ' + value['tradePriceCurrency'] + '/' + value['tradePriceUom'];
    };

    let othersFunction = function (value: any) {
      return _this.commonService.formatNumber(value['others'],'','','en-US',_this.priceRoundOff) + ' ' + value['tradePriceCurrency'] + '/' + value['tradePriceUom'];
    };

//Display Labels of the Trade details(Parent table)
    this.tradeCols = [
      {field: 'obligationId', header: 'Obligation ID'},
      {field: 'tradeDate', header: 'Trade Date', type: 'date'},
      {field: 'tradeTransactionType', header: 'Trade Transaction Type'},
      {field: 'tradeType', header: 'Trade Type'},
      {field: 'tradePriceType', header: 'Price Type'},
      {field: 'commodity', header: 'Commodity'},
      {field: 'periodStartDate', header: ' Period start date', type: 'date'},
      {field: 'periodEndDate', header: 'Period end date', type: 'date'},
      {field: 'incoterm', header: 'Incoterm'},
      {field: 'location', header: 'Location'},
      {field: 'origin', header: 'Origin'},
      {field: 'traderName', header: 'Trader Name'},
      {field: 'counterparty', header: 'Counterparty'},
      {field: 'profitCenter', header: 'Profit Center'},
      {field: 'priceStatus', header: 'Price status'},
      {field: 'tradeQuantity', header: 'Trade Quantity', valueFunction: tradeQuantityFunction},
      {field: 'pricedQuantity', header: 'Priced Quantity', valueFunction: priceQuantityFunction},
      {field: 'unpricedQuantity', header: 'Unpriced Quantity', valueFunction: unpriceQuantityFunction},
      {field: 'tradePrice', header: 'Trade Price', valueFunction: tradePriceFunction},
      {field: 'provisionalPrice', header: 'Provisional Price', valueFunction: provisionalPriceFunction},
      {field: 'averagePrice', header: 'Average Price', valueFunction: averagePriceFunction},
      {field: 'fxRate' ,header: 'FX Rate'}
    ];

    this.fixColumnNames = new Map<string, string>();
    this.fixColumnNames.set('unpricedQuantity','balanceQuantity');
    // this.fixColumnNames.set('profitCenter','profitcenter');
    this.columns = [];
    this.columns.push(new Tcolumn('obligationId','Obligation Id','T',1));
    this.columns.push(new Tcolumn('tradeDate','Trade Date','D',1));
    this.columns.push(new Tcolumn('tradeTransactionType','Trade Transaction Type','OB',1,true,this.getBuySell()));
    this.columns.push(new Tcolumn('tradeType','Trade Type','T',1,true,this.getBuySell()));
    this.columns.push(new Tcolumn('tradePriceType','Price Type','T',1));
    this.columns.push(new Tcolumn('commodity','Commodity','T',1));
    this.columns.push(new Tcolumn('periodStartDate','Period Start Date','D',1));
    this.columns.push(new Tcolumn('periodEndDate','Period End Date','D',1));
    this.columns.push(new Tcolumn('incoterm','Incoterm','T',1));
    this.columns.push(new Tcolumn('origin','Origin','T',1));
    this.columns.push(new Tcolumn('traderName','Trader Name','T',1));
    this.columns.push(new Tcolumn('counterparty','Counterparty','T',1));
    this.columns.push(new Tcolumn('profitCenter','Profit Center','T',1));
    this.columns.push(new Tcolumn('priceStatus','Price Status','OB',1,true,this.getPriceStatusList()));
    this.columns.push(new Tcolumn('pricedQuantity','Price Quantity','N',1));
    this.columns.push(new Tcolumn('balanceQuantity','Unpriced Quantity','N',1));
    this.columns.push(new Tcolumn('averagePrice','Average Price','N',1));
    this.columns.push(new Tcolumn('tradePrice','Trade Price','N',1));
    this.columns.push(new Tcolumn('provisionalPrice','Provisional Price','N',1))
    this.columns.push(new Tcolumn('fxRate','FX Rate','N',1));
    this.columns.push(new Tcolumn('tradeQuantity','Trade Quantity','N',1));

    this.route.queryParams.subscribe(params => {
      this.routingParams=params;
    })

    let tenantConfig=JSON.parse(this.commonService.getFromStorage("tenantConfig"));
    if(tenantConfig && tenantConfig.roundingFormat){
       _this.priceRoundOff=tenantConfig.roundingFormat.priceRounding;
       _this.quantityRoundOff= tenantConfig.roundingFormat.quantityRounding;
    }
    else{
      _this.priceRoundOff=2;
      _this.quantityRoundOff=3;
    }

//Display Labels of the trade price(Expanded table)
    this.priceCols = [
      {field: 'pricingDate', header: 'Pricing Date', type: 'date'},
      {field: 'basePrice', header: 'Base Price', valueFunction: basePriceFunction},
      {field: 'freight', header: 'Freight', valueFunction: freightFunction},
      {field: 'insurance', header: 'Insurance', valueFunction: insurancetFunction},
      {field: 'commission', header: 'Commission', valueFunction: commissionFunction},
      {field: 'premium', header: 'Premium', valueFunction: premiumFunction},
      {field: 'rollover', header: 'Rollover', valueFunction: rolloverFunction},
      {field: 'others', header: 'Others', valueFunction: othersFunction},
      {field: 'basis', header: 'Basis',valueFunction: basisFunction},
      {field: 'pricedQuantity', header: 'Priced Quantity',valueFunction: priceQuantityUomFunction},
      {field: 'total', header: 'Price Fixed', valueFunction:totalPriceFunction},
      {field: 'allocatedQuantity', header: 'Allocated Quantity', valueFunction:allocatedQuantityFunction},

    ];

    // Displays Headers of the Future Price Table
    this.futureCols = [
      {field: 'futureTradeId', header: 'Trade ID'},
      {field: 'futureTradeDate', header: 'Trade Date', type: 'date'},
      {field: 'futureExpiryDate', header: 'Expiry Date', type: 'date'},
      {field: 'profitCenter', header: 'Profit Center'},
      {field: 'broker', header: 'Broker'},
      {field: 'lots', header: 'Lots'},
      {field: 'futurePrice', header: 'Price'},
      {field: 'futurePriceLots', header: 'Pricing Lots', editable: true},
    ];
    this.initForm();
    this.savePriceLineApiFunc = function(payload: any) {
      let emptyValue: any[] = [];
      if (_this.priceLineType === 'Future') {
        _this.futureData.forEach(function (value) {
          if (value['futurePriceLots'] > 0) {
            emptyValue.push(value)
          }
        });
      } else if (parseFloat(_this.formControlService.getValue('quantityMT')) > 0) {
        let tradeIdData = (_this.tradeIdNumber === null || _this.tradeIdNumber === undefined || _this.tradeIdNumber === '') ? _this.selectedPlans[0]['obligationId'] : _this.tradeIdNumber;
        let pricePayloadObj = _this.formControlService.getFormGroup().value;
        pricePayloadObj['tradeId'] = tradeIdData;
        pricePayloadObj['obligationId'] = _this.obligationId;
        pricePayloadObj['pricingDate'] = _this.commonService.getISOString(pricePayloadObj['pricingDate']);
        pricePayloadObj['uomName'] = _this.uom;
        pricePayloadObj['pricedQuantity'] = _this.formControlService.getFormGroup().value['quantityMT'];
        pricePayloadObj['totalQuantity'] = _this.totalQuantity;
        pricePayloadObj['unpricedQuantity'] = _this.unpricedQuantity;
        pricePayloadObj['averagePrice'] = _this.commonService.formatNumberWithoutComma(_this.averagePrice, _this.priceRoundOff);
        pricePayloadObj['priceCurrency'] = _this.currencyType;
        pricePayloadObj['uom'] = _this.uom;
        pricePayloadObj['priceLineType'] = _this.priceLineType;
        pricePayloadObj['futureTradeList'] = [];
        payload['url'] = environment.base_url + '/api/advance-pricing/v1/create-pricing?autoAllocate='+_this.autoAllocate;
        payload['type'] = 'post';
        payload['payload'] = pricePayloadObj;
      } else {
        _this.messageService.add({severity: 'info', summary: 'No Data in Quantity'});
      }

      return payload;
    };
  }

  //To show Price Details and navigate to PriceTab on button Click [context switching]
  priceRouteInfo(params:any){
    if(params !== undefined && params !== null && Object.keys(params).length > 0){
      this.tradeIdNumber=params['value'];
      if(params['buttonName']=='Price Fixation'){
        this.openPriceDetails( params['value']);
      }
      if(params['buttonName']=='View Trade Pricing Details'){
        this.priceData.forEach( (rowData: any,index)=> {
        if (rowData['obligationId'] ===  params['value']) {
            this.commonService.getJSONByURL(environment.base_url + '/api/manualPricingService/v1/gettradepricinglist?tradeId=' + params['value']+'&tenantId='+this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
            this.priceData[index]['pricedChilds'] = next;
            this.expandedRows = {};
            this.expandedRows[params['value']] = true;
          });
        }
        });
      }
    }
  }

  private getBuySell() {
    return this.masterCommonService.getBooleanFromLabel('Buy','Sell',false,'BUY','SELL');
  }

  private getPriceStatusList() {
    let list:KeyValue[] = [];
    list.push(new KeyValue());
    list.push(new KeyValue('Fully Priced','Fully Priced'));
    list.push(new KeyValue('Partially Priced','Partially Priced'));
    list.push(new KeyValue('Not Priced','Not Priced'));
    return list;
  }


  //get all the trade details (1st table)
  getTradeData() {
    let _this = this;
    this.isRefreshing = true;
    this.priceData = [];
    _this.loadingMessage = "Loading ..";
    this.commonService.getJSONByURL(environment.base_url + '/api/advance-pricing/v1/get-all-pricing?page='+this.currentPageNumber+'&size=20').subscribe((next: any) => {
      _this.priceData = next['content'];
      _this.pageSize = next['pageable']['pageSize'];
      _this.totalElements = next['totalElements'];
      _this.totalPages = next['totalPages'];
      _this.isRefreshing = false;
      _this.loadingMessage = "No Trade for Price Fixation";
      _this.expandLastRow(_this.priceData);
      _this.calculateHeight(this.priceTable);
      _this.priceRouteInfo(_this.routingParams)
    }, (error: HttpErrorResponse) => {
      _this.isRefreshing = false;
      _this.loadingMessage = _this.commonService.getHttpErrorMessage(error,'Price Fixation');
    });
  }

  expandLastRow(newData: any[] = []) {
    let _this = this;
    let tradeId = this.lastSelectedRow !== undefined && this.lastSelectedRow !== null ? this.lastSelectedRow['obligationId'] : '';
    if (newData !== null && newData !== undefined && tradeId !== null && tradeId !== undefined && newData.length > 0 && tradeId.length > 0) {
      newData.forEach(function (rowData: any) {
        if (rowData['obligationId'] === tradeId) {
          _this.openPriceRow({data: rowData}, true);
        }
      });
    }
  }

  //Refresh button on the table
  priceRefresh() {
    this.isChildSelected=false;
    this.selectedChilds=[];
    this.priceDisplay = false;
    this.priceData = [];
    this.priceTable.expandedRowKeys = {};
    this.lastSelectedRow = {};
    this.expandedRows = {};
    this.selectedPlans = [];
    this.currentSelectedRow = -1;
    if(this.filters !== undefined && this.filters !== null && this.filters.length > 0) {
      this.onApplyFilter(this.filters,false);
    } else {
      this.getTradeData();
    }
  }

  //delete the trade
  onRowDelete(value: any[]) {
    let rowData = value['rowData'];
    let _this = this;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this Price?',
      accept: () => {
        this.commonService.getJSONByURL(environment.base_url + '/api/advance-pricing/v1/delete-price-lines-by-price-line-id?priceLineId=' + rowData['priceLineId']+'&obligationId='+ rowData['obligationId']).subscribe(next => {
          _this.messageService.add({severity: 'info', summary: 'Price Deleted'});
          _this.priceTable.expandedRowKeys = {};
          _this.priceTable.reset();
          _this.priceRefresh();
        },(error:HttpErrorResponse) => {
          _this.showToast(_this.commonService.getHttpErrorMessage(error,'Price Fixation'),'error');
        })
      }
    });
  }

  //Calculate the height of the table
  calculateHeight(priceTable?: Table) {
    if(this.priceTable === undefined || this.priceTable === null) {
      this.priceTable = priceTable;
    }
    if(this.priceTable === null || this.priceTable === undefined) {
      return;
    }
    let offsetTop = this.priceTable.el.nativeElement['offsetTop'];
    let offsetHeight = this.priceTable.el.nativeElement['offsetParent']['offsetHeight'];
    if(this.getPagination()) {
      offsetHeight = offsetHeight - 30;
    }
    this.priceTableHeight = (offsetHeight - offsetTop - 22) + 'px';
    if (this.divEl !== undefined && this.divEl !== null) {
      this.calculateGridHeight(this.divEl);
    }
  }

  calculateGridHeight(divElement: HTMLDivElement) {
    this.gridHeight = 350 + 'px';
    this.divEl = divElement;
  }


//Child Form API(expanded row)
  openPriceRow(value: any, expandRow: boolean = false) {
    if (value['data'] !== undefined && value['data'] !== null) {
      this.obligationId =typeof value == 'object'? value['data']['obligationId']:value;
      let _this = this;
      this.commonService.getJSONByURL(environment.base_url + '/api/advance-pricing/v1/get-staggered-pricing-lines?obligationId=' + this.obligationId).subscribe((next: any) => {
        value['data']['pricedChilds'] = next;
        if (expandRow) {
          _this.expandedRows = {};
          _this.expandedRows[value['data']['obligationId']] = true;
        }
      });
    }
  }

  getClassForHeading(index) {
    return index > 0 ? 'headerNormal' : 'noPipe';
  }

//Navigate to the trade screen
  goTo() {
    this.router.navigate(['/trade']);
  }

  initForm() {
    let _this = this;
    this.formControlService
        .addControl('pricingDate', this.getValidValue('pricingDate', true), [])
        .addControl('quantityMT', this.commonService.formatNumberWithoutComma(this.getValidValue('quantityMT'),this.quantityRoundOff), [Validators.min(0),Validators.required])
        .addControl('basis', this.getValidValue('basis'), [])
        .addControl('basePrice', this.getValidValue('basePrice'), [Validators.min(1),Validators.required])
        .addControl('freight', this.getValidValue('freight'), [Validators.min(0)])
        .addControl('insurance', this.getValidValue('insurance'), [Validators.min(0)])
        .addControl('commission', this.getValidValue('commission'), [])
        .addControl('premium', this.getValidValue('premium'), [])
        .addControl('rollover', this.getValidValue('rollover'), [])
        .addControl('others', this.getValidValue('others'), [Validators.min(0)])
        .addControl('fixedPrice', this.getValidValue('fixedPrice'), [])
        .build();

    let onChangeValue = (value: any, formGroup: FormGroup, control: FormControl) => {
      let fixedPrice = parseFloat(_this.formControlService.getValue('basis')) +
          parseFloat(_this.formControlService.getValue('basePrice')) +
          parseFloat(_this.formControlService.getValue('freight')) +
          parseFloat(_this.formControlService.getValue('insurance')) +
          parseFloat(_this.formControlService.getValue('commission')) +
          parseFloat(_this.formControlService.getValue('premium')) +
          parseFloat(_this.formControlService.getValue('rollover')) +
          parseFloat(_this.formControlService.getValue('others'));
      _this.formControlService.setValue('fixedPrice', _this.commonService.formatNumberWithoutComma(fixedPrice,_this.priceRoundOff));
    }

    this.formControlService.attachChangeListener('basis',onChangeValue)
    this.formControlService.attachChangeListener('freight',onChangeValue)
    this.formControlService.attachChangeListener('insurance',onChangeValue)
    this.formControlService.attachChangeListener('commission',onChangeValue)
    this.formControlService.attachChangeListener('premium',onChangeValue)
    this.formControlService.attachChangeListener('rollover',onChangeValue)
    this.formControlService.attachChangeListener('others',onChangeValue)
    this.formControlService.attachChangeListener('basePrice',onChangeValue)
    this.updateRounding('quantityMT',this.quantityRoundOff);
    this.updateRounding('basis',this.priceRoundOff);
  }

  private getValidValue(key: any, isDate: boolean = false,defaultValue:any = '') {
    if (this.priceValue !== undefined && this.priceValue !== null) {
      if (isDate) {
        let value = this.priceValue[key];
        if (value !== undefined && value !== null && value.length > 0) {
          return this.commonService.convertUTCtoDate(value);
        }
        return defaultValue;
      }
      return this.priceValue[key];
    }
    return defaultValue;
  }

  updateRounding(field,roundTo= this.priceRoundOff) {
    this.formControlService.setValue(field, this.commonService.formatNumberWithoutComma(this.formControlService.getValue(field),roundTo));
  }

//Open Price Details Dialog box
  openPriceDetails(tradeId:string='') {
    this.displayFuture = false;
    this.priceLineType = 'Physical';
    let obligationId = tradeId===''? this.selectedPlans[0]['obligationId']:tradeId;
    let _this = this;
    this.lastSelectedRow = {...this.selectedPlans[0]};
    let payloadObj = {
      tradeId:obligationId
    };

    this.commonService.getJSONByURL(environment.base_url + '/api/plannedObligation/v1/getplannedobligationsbyplannedobligationid?tenantId='+_this.commonService.getFromStorage('tenantId')+'&plannedObligationId='+this.selectedPlans[0]['obligationId']).subscribe((next:any) => {
      this.minPriceDate=this.commonService.convertUTCtoDate(next.tradeDate);
    });

    this.commonService.getJSONByURL(environment.base_url + '/api/advance-pricing/v1/load-for-manual-pricing?obligationId='+obligationId).subscribe(next => {
      this.priceDisplay = false;
      this.dialogDisplay = true;
      this.priceValue = next;
      this.formControlService.setValue('pricingDate',this.getValidValue('pricingDate',true,this.commonService.getLocalDateString(new Date())));
      this.formControlService.setValue('quantityMT',this.commonService.formatNumberWithoutComma(this.getValidValue('quantityMT'),this.quantityRoundOff));
      this.formControlService.setValue('basePrice',this.getValidValue('basePrice',false,0));
      this.formControlService.setValue('basis',this.getValidValue('basis',false,0));
      this.formControlService.setValue('freight',this.getValidValue('freight',false,0));
      this.formControlService.setValue('insurance',this.getValidValue('insurance',false,0));
      this.formControlService.setValue('commission',this.getValidValue('commission',false,0));
      this.formControlService.setValue('premium',this.getValidValue('premium',false,0));
      this.formControlService.setValue('rollover',this.getValidValue('rollover',false,0));
      this.formControlService.setValue('others',this.getValidValue('others',false,0));
      this.formControlService.setValue('fixedPrice',this.getValidValue('fixedPrice',false,0));
      this.uomName = next['priceUom'];
      this.commodity = next['commodity'];
      this.obligationId = next['obligationId'];
      this.totalQuantity = next['plannedQuantity'];
      this.pricedQuantity = next['pricedQuantity'];
      this.unpricedQuantity = next['unpricedQuantity'];
      this.averagePrice = this.commonService.formatNumberWithoutComma(next['averagePrice'], this.priceRoundOff);
      this.uom = next['priceUom'];
      this.currencyType = next['priceCurrency'];
    },(error: HttpErrorResponse) => {
        this.messageService.add({severity: 'error', summary: _this.commonService.getHttpErrorMessage(error, 'Price Fixation')});
    });
  }

  onFormCancel() {
    this.selectedPlans = [];
    this.autoAllocate = this.priceAutoAllocation;
    this.priceDisplay = false;
    this.dialogDisplay = false;
  }

//close the dialog box
  onDialogCancel(value) {
    this.displayFuture = false;
    this.autoAllocate = this.priceAutoAllocation;
    this.dialogDisplay = false;
  }

  showToast(msg, severity: string = 'success') {
    this.messageService.add({
      severity: severity,
      summary: msg
    });
  }

//save the price from dialog box
  onSubmitPrice() {
    let emptyValue: any[] = [];
    if (this.priceLineType === 'Future') {
      this.futureData.forEach(function (value) {
        if (value['futurePriceLots'] > 0) {
          emptyValue.push(value)
        }
      });
    } else {
      if (parseFloat(this.formControlService.getValue('quantityMT')) > 0) {
        this.savePriceLine();
      } else {
        this.messageService.add({severity: 'info', summary: 'No Data in Quantity'});
      }
    }
  }
  //Payload object
  savePriceLine(futureTradeList: any[] = []) {
    let _this = this;
    let tradeIddata=  this.tradeIdNumber===('' ||null||undefined)?_this.selectedPlans[0]['obligationId']:this.tradeIdNumber;
    let pricepayloadObj = this.formControlService.getFormGroup().value;
    pricepayloadObj['tradeId'] = tradeIddata;
    pricepayloadObj['obligationId'] = this.obligationId;
    pricepayloadObj['uomName'] = this.uom;
    pricepayloadObj['totalPrice'] = this.formControlService.getFormGroup().value['fixedPrice'];
    pricepayloadObj['pricedQuantity'] = this.pricedQuantity;
    pricepayloadObj['totalQuantity'] = this.totalQuantity;
    pricepayloadObj['unpricedQuantity'] = this.unpricedQuantity;
    pricepayloadObj['averagePrice'] = this.commonService.formatNumberWithoutComma(this.averagePrice,this.priceRoundOff);
    pricepayloadObj['priceCurrency'] = this.currencyType;
    pricepayloadObj['uom'] = this.uom;
    pricepayloadObj['priceLineType'] = this.priceLineType;
    pricepayloadObj['futureTradeList'] = futureTradeList;
    this.commonService.post(environment.base_url + '/api/advance-pricing/v1/create-pricing?autoAllocate='+this.autoAllocate, pricepayloadObj).subscribe(next => {
      this.dialogDisplay = false;
      this.showToast('Price fixed for the given Trade: ' + this.obligationId);
      this.priceRefresh();
    }, (error: HttpErrorResponse) => {
      if (error.error['status'] !== undefined && error.error['status'] !== null) {
        this.messageService.add({severity: 'error', summary: error.error['status']});
      } else {
        this.messageService.add({severity: 'error', summary: error.error.toString()});
      }
    });
  }

  getStyle(col: any, index, zIndex = '400', id) {
    let style: any;
    let width;
    let element: HTMLElement;
    let colWidth: number = 0;
    let _this = this;
    element = document.getElementById(id);
    if (element !== undefined && element !== null) {
      colWidth = element.offsetWidth;
    }
    if (this.leftFixedColumns.includes(col['field'])) {
      this.leftFixedColumnsWidth[this.leftFixedColumns.indexOf(col['field'])] = colWidth;
      width = this.getFixColumnWidth(index, col);
      style = {
        position: 'sticky',
        left: width + 'px',
        zIndex: zIndex
      };
    } else if (this.rightFixedColumns.includes(col['field'])) {
      this.rightFixedColumnsWidth[this.rightFixedColumns.indexOf(col['field'])] = colWidth;
      width = this.getFixColumnWidth(index, col, 'right');
      if (width !== 0) {
        width -= 1.5;
      }

      style = {
        position: 'sticky',
        right: width + 'px',
        zIndex: zIndex
      }
    }
    return style;
  }

  getFixColumnWidth(index, col: any, direction: string = 'left') {
    let _this = this;
    let width = (index * this.fixColumnsWidth) + 50;
    if (direction === 'left') {
      width = (index * this.fixColumnsWidth) + 50;
      if (this.leftFixedColumnsWidth !== undefined && this.leftFixedColumnsWidth !== null && this.leftFixedColumnsWidth.length > 0) {
        width = 50;
        if (index > 0) {
          this.leftFixedColumnsWidth.forEach(function (columnWidth: number, widthIndex) {
            if (widthIndex < index) {
              width += columnWidth;
            }
          });
        }
      }
    } else {
      let rightIndex = this.rightFixedColumns.indexOf(col['field']);
      width = ((this.rightFixedColumns.length - 1) - rightIndex) * this.fixColumnsWidth;
      if (this.rightFixedColumnsWidth !== undefined && this.rightFixedColumnsWidth !== null && this.rightFixedColumnsWidth.length > 0) {
        width = 0;
        this.rightFixedColumnsWidth.forEach(function (columnWidth: number, widthIndex) {
          if (widthIndex > rightIndex) {
            width += columnWidth;
          }
        });
      }
    }
    return width;
  }

  onClose(value: any) {
    this.obligationId = '';
    this.tradedisplay = false;
  }

  onUniqueClick(value: any) {
    this.obligationId = value['obligationId'];
    this.rowData = value;
    this.tradedisplay = true;
  }

  calculateLeft() {
    this.noDataBoxLeft = ((window['visualViewport'].width - 500) / 2) + 'px';
  }

  @HostListener("window:resize", [])
  public onResize() {
    this.calculateFutureHeight();
    this.calculateHeight(this.priceTable);
  }

  //calculate Future Price Table
  calculateFutureHeight() {
    if (this.table !== undefined && this.table !== null) {
      let offsetTop = this.table.el.nativeElement['offsetTop'];
      if(this.table.el.nativeElement['offsetParent'] !== null && this.table.el.nativeElement['offsetParent'] !== undefined){
        let offsetHeight = this.table.el.nativeElement['offsetParent']['offsetHeight'];
        this.futureGridHeight = (offsetHeight - (offsetTop + 150)) + 'px';
      }
    }
  }

  public getfutures() {
    let _this = this;
    this.futureData = [];
    this.commonService.getJSONByURL(environment.base_url + '/api/manualPricingService/v1/getFutureTradeAvailable?tradeId=' + this.selectedPlans[0]['obligationId']+'&tenantId='+this.commonService.getFromStorage('tenantId')).subscribe((next: any[]) => {
      _this.futureData = next;
      this.calculateFutureHeight();
    });
  }

  switchPrice(value) {
      this.displayFuture = false;
      this.disableSave = false;
      this.errorDisplay = false;
      this.priceLineType = 'Physical';
  }
  autoAllocation(value){
    this.autoAllocate=value.checked;
  }

  onRowSelectionChange(value: any) {
    if(this.canHideBottomPane()) {
      this.priceDisplay = false;
      this.dialogDisplay = false;
    } else {
      let priceType = this.selectedPlans[0].tradePriceType;
      if(priceType.toLowerCase() !== 'fixed'){
        this.priceDisplay = true;
      }
    }
    if(this.selectedPlans!== null && this.selectedPlans!== undefined && this.selectedPlans.length > 0) {
      this.selectedPlans.forEach(data=>{
        this.deleteall = data.priceStatus == "Not Priced";
      })
    }
  }

  // deleting all pricelist under Trade on single click
  onDeleteAll() {
    let _this = this;
    let tradeid = this.selectedPlans.map(data=>{
      return  data.tradeId;
    })
    let obligationId = this.selectedPlans.map(data=>{
      return  data.obligationId;
    })
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete All the Price?',
      accept: () => {
        this.deleteall = true;
        if(this.isChildSelected) {
          let payload = this.selectedChilds.map(item => item.priceLineId);
          obligationId = this.selectedChilds.map(data=>{
            return data.obligationId;
          })[0];
          this.commonService.post(environment.base_url + '/api/advance-pricing/v1/deallocate-and-delete-lines-by-priceline-id?obligationId='+obligationId,payload).subscribe(next => {
            _this.messageService.add({severity: 'info', summary: 'Price lines Deleted'});
            this.deleteall = false;
            _this.priceRefresh();
          },(error:HttpErrorResponse) => {
            _this.showToast(_this.commonService.getHttpErrorMessage(error,'Price Fixation'),'error');
            this.deleteall = false;
          })
        } else {
          this.commonService.getJSONByURL(environment.base_url + '/api/advance-pricing/v1/deallocate-and-delete-all-allocation-lines-by-trade-id-and-obligation-id?tradeId='+tradeid+'&obligationId='+obligationId).subscribe(next => {
            _this.messageService.add({severity: 'info', summary: 'Price lines Deleted'});
            this.deleteall = false;
            _this.priceRefresh();
          },(error:HttpErrorResponse) => {
            _this.showToast(_this.commonService.getHttpErrorMessage(error,'Price Fixation'),'error');
            this.deleteall = false;
          })
        }
      }
    });
  }

  //click on Exports icon it downloads the excel sheet
  onClickExport(){
    if (this.selectedPlans !== undefined && this.selectedPlans.length > 0) {
      this.excelService.exportDashboardDataView(this.tradeCols, this.selectedPlans, 'PriceList', 'PriceList')
    } else {
      this.messageService.add({
        severity: 'info', summary: messages.no_row_selected['summary'],
        detail: messages.no_row_selected['message']
      });
    }
  }

    onApplyFilter(value: Filter[],resetInfo:boolean = true) {
      let _this = this;
      this.isRefreshing = true;
      this.priceData = [];
      this.priceTable.expandedRowKeys = {};
      if(resetInfo) {
        this.currentPageNumber = 0;
      }
      let payload = this.commonService.getFilterApiPayload(value);
      if(payload.length > 1) {
        this.filters = value;
        let url = environment.base_url+'/api/advance-pricing/v1/getpricelinebycriteria?tenantId='+this.commonService.getTenantId()+'&page='+this.currentPageNumber+'&size=20'
        this.commonService.post(url,payload).subscribe((next:any) => {
          _this.isRefreshing = false;
          _this.priceData = next['content'];
          _this.pageSize = next['pageable']['pageSize'];
          _this.totalElements = next['totalElements'];
          _this.totalPages = next['totalPages'];
          _this.calculateHeight(this.priceTable);
        },(error:HttpErrorResponse) => {
          _this.isRefreshing = false;
          _this.showToast(_this.commonService.getHttpErrorMessage(error,'Price Fixation'),'error');
        });
      } else {
        this.filters = [];
        this.getTradeData();
      }
    }

  onPageChange(value: any) {
    this.currentPageNumber = value['page'];
    if (this.filters !== null && this.filters !== undefined && this.filters.length > 0) {
      this.onApplyFilter(this.filters, false);
    } else {
      this.getTradeData();
    }
  }

  getPagination() {
    return this.totalElements > this.pageSize;
  }

  onPriceLineSave(next: any) {
    this.dialogDisplay = false;
    this.showToast('Price fixed for the given Trade: ' + this.obligationId);
    this.priceRefresh();
  }
  openAllocationScreen() {
    this.priceValue = [];
    this.tradeId = this.selectedPlans[0]['obligationId'];
    this.priceUom = this.selectedPlans[0]['tradePriceUom'];
    this.quantityUom = this.selectedPlans[0]['tradeQuantityUom'];
    this.matchingScreenOpen = true;
    this.selectedPlans = [];
  }
  closeAllocationScreen(value: any) {
    let type = value.type;
    let message = value.message;
    this.matchingScreenOpen = false;
    if(type === 'success') {
      this.showToast(message);
    }
    this.priceRefresh();

  }
  onPriceLineSaveFail(error: HttpErrorResponse) {
    if (error.error['errorMessage'] !== undefined && error.error['errorMessage'] !== null) {
      this.messageService.add({severity: 'error', summary: error.error['errorMessage']});
    } else {
      this.messageService.add({severity: 'error', summary: error.error.toString()});
    }
  }

  deleteAllocation() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to De-allocate the Price?',
      accept: () => {
        this.priceValue = [];
        this.deAllocatePriceDisabled = true;
        let payload = [];
        if(this.isChildSelected) {
          let url = environment.base_url + '/api/allocation/v1/deallocate-by-price-lines';
          payload = this.selectedChilds.map(item => item.priceLineId);
          this.commonService.post(url,payload).subscribe(next => {
            this.showToast("Price Deallocated Successfully");
            this.deAllocatePriceDisabled = false;
            this.priceRefresh();
          },(error:HttpErrorResponse) => {
            this.showToast(this.commonService.getHttpErrorMessage(error,'Price Fixation'),'error');
            this.deAllocatePriceDisabled = false;
          });
        } else {
          this.tradeId = this.selectedPlans[0]['obligationId'];
          this.commonService.getJSONByURL(environment.base_url + '/api/allocation/v1/deallocate-price-lines?obligationId=' + this.tradeId).subscribe(next => {
            this.showToast("Price Deallocated Successfully");
            this.deAllocatePriceDisabled = false;
            this.priceRefresh();
          },(error:HttpErrorResponse) => {
            this.showToast(this.commonService.getHttpErrorMessage(error,'Price Fixation'),'error');
            this.deAllocatePriceDisabled = false;
          });
        }
      }
    });
  }

  private getDistinctList(list:any[]) {
    let distinctList = [];
    list.forEach(item => {
      if(distinctList.filter(item1 => (item1=== item)).length ===0) {
        distinctList.push(item);
      }
    });
    return distinctList;
  }

  onSelectChilds(value: any, rowData: any) {
    this.selectedChilds = this.selectedChilds.concat(value);
    if(this.canHideBottomPane(value)) {
      this.priceDisplay = false;
      this.dialogDisplay = false;
    } else {
      this.deleteall = false;
      this.continueDecision(rowData);
    }
  }

  private continueDecision(rowData: any) {
    let obligationIds = this.selectedChilds.map(item => item.obligationId);
    if(obligationIds.length === 0 ) {
      this.selectedChilds = [];
      this.isChildSelected = false;
      this.priceDisplay = false;
      this.onRowSelectionChange(null);
      return;
    }
    let isAllSame = true;
    let firstId = obligationIds[0];
    obligationIds.forEach(id => {
      if (firstId !== id && isAllSame) {
        isAllSame = false;
      }
    });
    if (!isAllSame) {
      this.isChildSelected = false;
      this.priceDisplay = false;
      this.onRowSelectionChange(null);
      return;
    }
    this.isChildSelected = false;
    this.priceDisplay = false;
    let priceType = rowData.tradePriceType;
    if (this.selectedChilds.length > 0) {
      this.isChildSelected = true;
    }
    if (priceType.toLowerCase() !== 'fixed') {
      this.priceDisplay = this.isChildSelected || this.selectedPlans.length === 1;
    }
  }

  onunselect(value: any, rowData: any) {
    this.selectedChilds = this.selectedChilds.filter(item => item.priceLineId !== value.priceLineId);
    if(this.canHideBottomPane()) {
      if(this.selectedPlans.length !== 1) {
        this.priceDisplay = false;
        this.dialogDisplay = false;
      }
    } else {
      if(this.selectedChilds.length === 0) {
        this.deleteall = true;
      }
      this.continueDecision(rowData);
    }
  }

  private canHideBottomPane(value:any[] =[]) {
    let merged = [...this.selectedPlans, ...this.selectedChilds,...value];
    let obligationIds = this.getDistinctList(merged.map(item => item.obligationId));
    return obligationIds.length != 1;
  }
}
