export class Button {
  get formGroupValidation(): boolean {
    return this._formGroupValidation;
  }

  set formGroupValidation(value: boolean) {
    this._formGroupValidation = value;
  }
  constructor(
    private _title: string,
    private _scope: string = "form (F) | grid (G)",
    private _toolTip?: string,
    private _onClick?: Function,
    private _icon?: string,
    private _iconPosition: string = 'left',  //can be left /right/top/bottom
    private _extra: any = {},
    private _fieldValuesToUpdate: any[] = [],
    private _disabled: boolean = true,
    private _sublist:Button[] =[],
    private _formGroupValidation:boolean = false
  ) {
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  get scope(): string {
    return this._scope;
  }

  set scope(value: string) {
    this._scope = value;
  }

  get toolTip(): string {
    return this._toolTip;
  }

  set toolTip(value: string) {
    this._toolTip = value;
  }

  get onClick(): Function {
    return this._onClick;
  }

  set onClick(value: Function) {
    this._onClick = value;
  }

  get icon(): string {
    return this._icon;
  }

  set icon(value: string) {
    this._icon = value;
  }

  get extra(): any {
    return this._extra;
  }

  set extra(value: any) {
    this._extra = value;
  }

  get iconPosition(): string {
    return this._iconPosition;
  }

  set iconPosition(value: string) {
    this._iconPosition = value;
  }


  get fieldValuesToUpdate(): any[] {
    return this._fieldValuesToUpdate;
  }

  set fieldValuesToUpdate(value: any[]) {
    this._fieldValuesToUpdate = value;
  }


  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
  }


  get sublist(): Button[] {
    return this._sublist;
  }

  set sublist(value: Button[]) {
    this._sublist = value;
  }
}
