<p-dialog [(visible)]="visible"
          [closeOnEscape]="false"
          [draggable]="false"
          [maximizable]="false" [modal]="true" [showHeader]="true" [closable]="false"
          [style]="{zIndex:1000,width: '75%',height: 'auto',maxHeight:'80%', padding:'0px', background:'#ffffff'}">
    <p-header>
        <div class="dialogHeading">
            <div>
                <p class="dialogLabel">Vessel Allocation</p>
            </div>
            <div class="formCloseIcon">
                <i (click)="onFormCancel()" class="material-icons closable" style="float: right;">clear</i>
            </div>
        </div>
    </p-header>

    <div class="vesselallocationtable">
        <p-table #table (inViewportAction)="calculatevesselHeight()"
                 [columns]="vesselData"
                 [responsive]="true"
                 [autoLayout]="true"
                 [(selection)]="rowselectvessel"
                 (onRowSelect)="onrowSelect($event)"
                 (onRowUnselect)="onRowUnselect($event,table)"
                 [style]="{width: '100%', maxHeight: vesselGridHeight,minHeight:vesselGridHeight}"
                 [value]="vesselAllocationData"
                 autoLayout="true"
                 class="style-4 table-responsive background"
                 columnResizeMode="fit" inViewport style="height: 28rem;">
            <ng-template let-columns pTemplate="header">
                <tr class="sticky-top" style="background-color: #D9DBDE">
                    <th style="width: 3em">
                        <p style="margin:0; padding: 12px 5px 9px;text-align: center;">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </p>
                    </th>
                    <th *ngFor="let col of columns;index as i" [id]="col.field" [pSortableColumn]="col.field"
                        class="otherColumn stk" style="white-space: nowrap; background-color: #d9dbde">
                        <div class="headerDiv">
                            <div class="headerTitle">{{col['header']}}</div>
                            <div class="pipe"></div>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template let-columns="columns" let-ri="rowIndex" let-rowData pTemplate="body">
                <tr [pSelectableRow]="rowData">
                    <td class="customRow" style="width:3em;">
                        <p class="plannedGridChkBox">
                            <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                        </p>
                    </td>
                    <td *ngFor="let col of columns">
                        <p *ngIf="!col['editable']" style="font-size: 14px;">
                            {{commonService.getRowValue(rowData, col)}}
                        </p>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div style="display:inline-flex;width: 100%; height: 2px; background-color: #D9DBDE">
    </div>
    <div class="vesselsubstitute" [formGroup]="substituteVesselForm.getFormGroup()">
        <div class="fieldBox">
            <p class="fieldName">Booking Number</p>
            <div class="inputbox">
                <input formControlName="bookingNumber" class="form-control form-control-sm">
            </div>
        </div>
        <div class="fieldBox">
            <p class="fieldName">Vessel Name</p>
            <div class="inputbox">
                <p-dropdown appendTo="body" [filter]="true" formControlName="vesselName" [options]="vesselName">
                </p-dropdown>
            </div>
        </div>
        <div class="fieldBox">
            <p class="fieldName">Load Port</p>
            <p-dropdown appendTo="body" formControlName="loadPort" [options]="locationList">
            </p-dropdown>
        </div>
        <div class="fieldBox">
            <p class="fieldName">Lycan</p>
            <input formControlName="lycan" [bsConfig]="bsConfig" autocomplete="off" bsDatepicker
                   class="form-control form-control-sm"
                   placeholder="Select Date" type="text">
        </div>
        <div class="fieldBox">
            <p class="fieldName">Unload-Port</p>
            <p-dropdown appendTo="body" formControlName="unloadPort" [options]="locationList">
            </p-dropdown>
        </div>
        <div class="fieldBox">
            <p class="fieldName">Vessel Capacity</p>
            <div class="inputbox">
                <input formControlName="vesselCapacity" type="number" class="form-control form-control-sm">
            </div>
        </div>
        <div class="fieldBox">
            <p class="fieldName">Substitute</p>
            <ui-switch [checkedTextColor]="'#fff'" [size]="'small'" [formControlName]="'substitute'"
                       [checkedLabel]="'Yes'" [uncheckedLabel]="'No'"
                       (change)="toggleChange($event)" [checked]="false"
                       [defaultBgColor]="'#d8d8d8'">
            </ui-switch>
        </div>
    </div>


    <p-footer>
        <div class="allocate-transport-footer">
            <button class="cancel-button" (click)="onFormCancel()">Cancel</button>
            <button [disabled]="rowselectvessel.length==0 ?!this.substituteVesselForm.getFormGroup().valid: false"

                    (click)="onAllocateTransportVessel()" class="allocate-button">Allocate
            </button>
        </div>
    </p-footer>

</p-dialog>
