import {Component, Input, OnInit, Output, TemplateRef, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {OverlayPanel} from 'primeng/overlaypanel';
import {CommonService} from '../../services/common.service';

@Component({
  selector: 'ctrm-hover-icon',
  templateUrl: './ctrm-hover-icon.component.html',
  styleUrls: ['./ctrm-hover-icon.component.css']
})
export class CtrmHoverIconComponent implements OnInit,OnChanges {

  @Input() iconNameList:any[] = [];
  @Input() iconList:any[] = [];
  @Input() templateList: TemplateRef<any>[] =[];
  @Input() header: string;
  @Input() buttonNameList:any[] = [];
  @Output() onButtonClick= new EventEmitter();
  @Input() rowData:any;
  @Input() iconDecidingFunction: Function;
  @Input() selectedIconName:string= '';
  @Input() hideForIcons: any[] = [];
  errorMessage:string = '';
  @Output() onHover = new EventEmitter();
  @Input() loadApiFunction: Function;
  dbResponse: any;
  @Input() headingFunction: Function;
  showLoading: boolean = true;
  @Input() textMode: boolean= false;
  @Input() cssClasses: any[]= [];

  constructor(private commonService:CommonService) { }

  ngOnInit(): void {
  }
  getTextCss(){
    if(this.cssClasses!==null && this.cssClasses!==undefined && this.cssClasses.length>0 ){
      if((this.cssClasses.length - 1) >=this.iconNameList.indexOf(this.selectedIconName)){
        return this.cssClasses[this.iconNameList.indexOf(this.selectedIconName)];
      }
    }
    return {
      margin: 0,
      padding: 0,
      fontSize: '14px',
      fontFamily: 'Open Sans',
      color:'#000000',
      cursor: 'pointer'
    };
  }
onCLickButton(name,overlayPanel:OverlayPanel) {
    overlayPanel.hide();
    this.onButtonClick.emit({
      name:name,
      iconName:this.selectedIconName,
      rowData: this.rowData,
      dbData: this.dbResponse
    })
  };

  ngOnChanges(changes: SimpleChanges) {
    if(changes['rowData']) {
      if(this.iconDecidingFunction !== undefined && this.iconDecidingFunction !== null) {
        this.selectedIconName = this.commonService.runFunction(this.rowData,this.iconDecidingFunction);
      }
    }
    if(changes['iconDecidingFunction']) {
      if(this.iconDecidingFunction !== undefined && this.iconDecidingFunction !== null) {
        this.selectedIconName = this.commonService.runFunction(this.rowData,this.iconDecidingFunction);
      }
    }
  }


  onMouseHoverIcon(event: MouseEvent, overlayPanel: OverlayPanel) {
    this.dbResponse = null;
    this.errorMessage = '';
    let _this = this;
    if(!this.hideForIcons.includes(this.selectedIconName)) {
      overlayPanel.toggle(event);
      if(this.loadApiFunction !== undefined && this.loadApiFunction !== null) {
        let obj = this.commonService.runFunction({rowData:this.rowData,iconName: this.selectedIconName},this.loadApiFunction);
        if(obj !== undefined && obj !== null && Object.keys(obj).length > 0 && obj['url'] !== null  && obj['url'] !== undefined  && obj['url'].length > 0) {
          _this.showLoading = true;
          if (obj['type'].toUpperCase() === 'GET') {
            this.commonService.getJSONByURL(obj['url']).subscribe((next:any)=>{
              _this.dbResponse = next;
              _this.showLoading = false;
              this.onHover.emit({panel:overlayPanel,rowData:_this.rowData,name:_this.selectedIconName,dbresponse:next});
              this.updateHeader(this.rowData,next);
            },error => {
              _this.errorMessage = _this.commonService.getHttpErrorMessage(error,'Hover Icon');
            })
          } else if (obj['type'].toUpperCase() === 'POST') {
            this.commonService.post(obj['url'],obj['payload']).subscribe((next:any)=>{
              _this.dbResponse = next;
              _this.showLoading = false;
              this.onHover.emit({panel:overlayPanel,rowData:_this.rowData,name:_this.selectedIconName,dbresponse:next});
              this.updateHeader(this.rowData,next);
            },error => {
              _this.errorMessage = _this.commonService.getHttpErrorMessage(error,'Hover Icon');
            })
          }
        } else {
          this.showLoading = false;
          this.onHover.emit({panel:overlayPanel,rowData:_this.rowData,name:_this.selectedIconName});
          this.updateHeader(this.rowData,null);
        }
      } else {
        this.showLoading = false;
        this.onHover.emit({panel:overlayPanel,rowData:_this.rowData,name:_this.selectedIconName});
        this.updateHeader(this.rowData,null);
      }
    }
  }

  onMouseLeave(event: MouseEvent, overlayPanel: OverlayPanel) {
    overlayPanel.toggle(event);
  }

  private updateHeader(rowData,dbData) {
    if(this.headingFunction !== undefined && this.headingFunction !== null) {
      this.header = this.commonService.runFunction({
        rowData:rowData,
        dbData:dbData
      },this.headingFunction)
    };
  }
}
