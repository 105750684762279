import {Component, OnInit} from '@angular/core';
import {Tcolumn} from '../../grid/tcolumn.model';
import {Grid} from '../../grid/grid.model';
import {Validators} from '@angular/forms';
import {MasterService} from '../services/MasterService';
import {defaultValue} from '../../grid/defaultValue.model';
import {environment} from '../../../environments/environment';
import {CommonService} from '../../services/common.service';

@Component({
    selector: 'app-secondarycost',
    templateUrl: './secondarycost.component.html',
    styleUrls: []
})
export class SecondarycostComponent implements OnInit {

    columns: Map<string, Tcolumn>;
    grid: Grid;
    loadDataList: any[] = [];

    constructor(private masterCommService: MasterService, public commonService: CommonService) {
    }

    ngOnInit() {
        this.commonService.getLoadData('secondarycost', ['data', 'data', 'data'], [[{
            key: 'name',
            value: 'secondary_cost_type_ind'
        }], [{key: 'name', value: 'settlement_type_ind'}], [{key: 'name', value: 'calculation_basis_ind'}]]).subscribe((next: any) => {
            this.loadDataList = next;
            this.getColumns();
            this.grid = new Grid(this.columns, true, true, true, true, 'name', 'api/secondarycost/v1/getall?tenantId=' + this.commonService.getFromStorage('tenantId'), '', 'Secondary Cost');
            this.grid.setSaveUrl(environment.base_url + 'api/secondarycost/v1/save');
            this.grid.setUpdateUrl(environment.base_url + 'api/secondarycost/v1/update');
            this.grid.iconName = 'secondaryCost';
        });
    }

    public getColumns() {
        this.columns = new Map();
        this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
        this.columns.set('name', new Tcolumn('name', 'Cost Name', 'T', 1, false, undefined, true, new defaultValue('', '', environment.primaryKeyIncludedCharacters), 'B', [Validators.required, Validators.maxLength(100)]));
        this.columns.set('description', new Tcolumn('description', 'Cost Description', 'TA', 3, true, undefined, true, '', 'B', [Validators.maxLength(200)]));
        this.columns.set('costType', new Tcolumn('costType', 'Cost Group ', 'L', 4, true, this.masterCommService.getListFromArray(this.loadDataList['secondary_cost_type_ind']), true, '', 'B', [Validators.required]));
        this.columns.set('settlementType', new Tcolumn('settlementType', 'Settlement Type ', 'ML', 5, true, this.masterCommService.getListFromArray(this.loadDataList['settlement_type_ind'], false, false), true, '', 'B', [Validators.required]));
        this.columns.set('calculationBasis', new Tcolumn('calculationBasis', 'Calculation Basis ', 'L', 6, true, this.masterCommService.getListFromArray(['calculation_basis_ind']), true, '', 'B', [Validators.required]));
        this.columns.set('status', new Tcolumn('status', 'Status', 'B', 7, true, this.masterCommService.getActiveInactive(), true, true, 'B', [Validators.required]));
    }
}
