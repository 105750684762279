<!--<p class="operationdashboard"> <img class="imageNotifyClass" src="../../../assets/icons/bell.svg" alt="">Switch To: Trade Obligation Dashboard - <button class="btnNav success" (click)="onclickRouter()">Obligation Dashboard</button></p>-->
<ctrm-grid (onClickUniqueKey)="onUniqueClick($event)"
           (onRefreshComplete)="onRefreshComplete($event)"
           [refreshGrid]="refreshGrid"
           [breadcrumbMapKey]="commonService.OPERATION_MAP_KEY"
           [currentRoute]="'/operationsDashboard'"
           [grid]="grid"
           [noDataRoute]="'/trade'"
           [refreshFilter]="refreshFilter"
           [gridFilterDropdownList]="gridFilters"
           [extraViewTemplate]="operationsInfo"
           [extraButtons]="grid.getExtraButtons()"
           [selectedRow]="selectedRows"
           [fieldsWithCustomView]="['plannedObligationId','tradePricingStatus','priceAllocationStatus','transportAllocationStatus','actualizationStatus','invoicingStatus','fxAllocationStatus']"
           [customViewTemplated]="[plannedObligationTemplate,tradePricingStatusTemplate,priceAllocationStatusTemplate,transportAllocationStatusTemplate,actualizationStatusTemplate,invoicingStatusTemplate,fxAllocationStatusTemplate]"
           [recalculateGridHeight]="gridHeightRecalculate"
           (onHeightCalculationDone)="onHeightRefreshDone()"
           (onContextMenuItemClick)="onClickMenuItem($event)"
           [routeToLable]="'Create new trade'"
           [showAddButtonInNoData]="false"
           (onDropdownFilterValueChange)="onFilterChange($event)"
           [useCustomSort]="true"
           [customSortFunction]="sortFunction"
           [autoCalculateGridWidth]="false"
           [autoLayout]="true"
           [headerWidth]="'130px'"
           [showFilterChips]="false"
           [showSortIcon]="false"
           [showStandardFilter]="true"
           [defaultColumnsForStdFilter]="['tradeId','plannedObligationId','tradeTransactionType','commodity','counterparty','shipmentMonth','plannedQuantity',
                   'loadLocation','unloadLocation','incoterm','tradePricingStatus','priceAllocationStatus','transportAllocationStatus','actualizationStatus','invoicingStatus','fxAllocationStatus']"
           (totalNumberElements)="sendTotalElement($event)"
           [onApplyAdvancedFiltervalue]="onApplyAdvancedFiltervalue">
</ctrm-grid>

<ng-template #operationsInfo>
    <div class="sectionSummary borderRadius">
        <div class="header">
            Operations Summary
        </div>
        <div class="headerControl">
            <div class="calenderCont">
                <div class="calender" (click)="toggleDateRange()">
                    <i class="fa fa-calendar"
                       style="margin-left: 5px;align-self: center;font-size: 14px;margin-left: 6px;user-select: none"></i>
                    <p>{{getPresetTitle()}}</p>
                </div>
                <obligation-date-range *ngIf="showDateRange"
                                       [preDefinedRanges]="presetList"
                                       [selectedPresetObject]="selectedPreset"
                                       [selectedPreset]="selectedPreset['title']"
                                       (onCancel)="onCancel($event)"
                                       (onPresetSelected)="onPresetChange($event)">
                </obligation-date-range>
            </div>
            <div>
                <button (click)="clearAllFilter()" class="clearAll">Clear All
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #plannedObligationTemplate let-rowData="rowData">
    <div style="display: inline-flex;align-items: center;font-size: 12px !important; ">
        <p class="plannedObligationId" (click)="openAmendForm(rowData)">{{rowData['plannedObligationId']}}</p>
        <img src="../../../assets/icons/attachments.svg"   width="20px" height="20px" style="padding-left:5px;" (click)="onMouseOverPlannedObligationIcon($event,plannedObligationoverlayPanel,rowData)">
    </div>
</ng-template>

<!--Amend/Modify Form-->
<amend-modify-form *ngIf="displayAmendForm"
                    [dialogDisplay]="displayAmendForm"
                    [declarePort]="declarePort"
                    (onClose)="onCloseForm()"
                    [ammendRowdata] = "ammendRowdata"
                    [planObligationValue]="planObligationValue">
</amend-modify-form>


<p-overlayPanel  #plannedObligationoverlayPanel  [style]="{width: '12%'}">
    <p-table   [value]="documents" [scrollable]="true" scrollHeight="200px">
        <ng-template style="width:15%;" pTemplate="header">
            <tr>
                <th style="min-width:100px">Document Links</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-documents>
            <tr>
                <td style="min-width:100px; text-decoration: underline; text-decoration-color: blue; color:blue;"
                    (click)="openAttachment(documents)">{{documents.label}}</td>
            </tr>
        </ng-template>
    </p-table>
</p-overlayPanel>

<!--Pricing OverlayPanel Templetes-->
<ng-template #tradePricingStatusTemplate let-rowData="rowData">
   <ctrm-hover-icon
                    [iconNameList]="['Fixed Priced','Not Priced','Partially Priced','Fully Priced','Provisional Not Priced','Provisional Partially Priced','Provisional Fully Priced']"
                    [iconList]="['Fixed Price.svg','reddollar.svg','yellow.svg','greendollar.svg','Provisional Not Priced.svg','Provisional Partially Priced.svg','Provisional Fully Priced.svg']"
                    [templateList]="[null,notPriced,fullyPriced,fullyPriced,provNotPriced,provisionalPartialPriced,provisionalPartialPriced]"
                    [selectedIconName]="'Fixed Priced'"
                    [iconDecidingFunction]="iconPath"
                    [header]="'Trade Pricing Details'"
                    (onHover)="onHover($event)"
                    (onButtonClick)="onButtonClick($event)"
                    [loadApiFunction]="tradePricingLoadApiFunction"
                    [hideForIcons]="['Fixed Priced']"
                    [rowData]="rowData" ></ctrm-hover-icon>
</ng-template>
<ng-template #notPriced>
    <div>
        <div style="display: inline-flex; margin: 10px;background: #fef5e9;padding: 10px;">
            <i class='fas fa-exclamation-triangle' style='font-size:17px;color:#ff8400;padding: 5px'></i>
            <p>Trade Pricing Details Not Available.</p>
        </div>
    </div>
</ng-template>
<ng-template #fullyPriced let-dbResponse="dbResponse" >
    <div>
        <div class="priceAdding">
            <div class="fieldBox">
                <p class="fieldName">Priced Quantity</p>
                <p class="bodyContent"> {{commonService.getFormatedNumber(dbResponse[0]['pricedQuantity'])+' '+dbResponse[0]['priceUomName']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Average Pirce</p>
                <p class="bodyContent"> {{commonService.getFormatedNumber(dbResponse[0]['averagePrice']) + ' ' + dbResponse[0]['priceCurrency'] + '/' + dbResponse[0]['priceUomName']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Pricing Status</p>
                <p class="bodyContent"> {{dbResponse[0]['priceStatus']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Priced By</p>
                <p class="bodyContent"> {{dbResponse[0]['createdBy']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Priced Date</p>
                <p class="bodyContent"> {{dbResponse[0]['createdTimestamp'] == null || dbResponse[0]['createdTimestamp'] == undefined ?'':(dbResponse[0]['createdTimestamp'] | date : 'dd MMM yyyy')}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Updated Date</p>
                <p class="bodyContent"> {{dbResponse[0]['updatedTimestamp'] == null || dbResponse[0]['updatedTimestamp'] == undefined ?'':(dbResponse[0]['updatedTimestamp'] | date : 'dd MMM yyyy')}}</p>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #provNotPriced let-rowData="rowData">
    <div>
        <div class="priceAdding">
            <div class="fieldBox">
                <p class="fieldName">Provisional Price</p>
                <p class="bodyContent"> {{rowData['provisionalPrice'] + ' '+rowData['provisionalPriceCurrency']+'/'+rowData['provisionalPriceUom']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Pricing Status</p>
                <p class="bodyContent">Not Priced</p>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #provisionalPartialPriced let-rowData="rowData" let-dbResponse="dbResponse">
    <div>
        <div class="priceAdding">
            <div class="fieldBox">
                <p class="fieldName">Provisional Price</p>
                <p class="bodyContent"> {{rowData['provisionalPrice'] + ' '+rowData['provisionalPriceCurrency']+'/'+rowData['provisionalPriceUom']}}</p>
            </div>
        </div>
        <div class="priceAdding">
            <div class="fieldBox">
                <p class="fieldName">Priced Quantity</p>
                <p class="bodyContent"> {{commonService.getFormatedNumber(dbResponse[0]['pricedQuantity'])+' '+dbResponse[0]['priceUomName']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Average Pirce</p>
                <p class="bodyContent"> {{commonService.getFormatedNumber(dbResponse[0]['averagePrice']) + ' ' + dbResponse[0]['priceCurrency'] + '/' + dbResponse[0]['priceUomName']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Pricing Status</p>
                <p class="bodyContent"> {{dbResponse[0]['priceStatus']}}</p>
            </div>

        </div>
    </div>
</ng-template>

<!--Price allocation Template-->
<ng-template #priceAllocationStatusTemplate let-rowData="rowData">
    <ctrm-hover-icon
                     [iconNameList]="['Fixed Priced','Not Priced','Not Allocated','Fully Allocated','Partially Allocated']"
                     [iconList]="['Fixed Price.svg','reddollar.svg','reddollar.svg','greendollar.svg','yellow.svg']"
                     [templateList]="[null,notPriced,notAllocated,partiallyAllocated,partiallyAllocated]"
                     [selectedIconName]="'Fixed Priced'"
                     [iconDecidingFunction]="priceAllocationiconPath"
                     [header]="'Trade Pricing Details'"
                     (onHover)="onHover($event)"
                     (onButtonClick)="onButtonClick($event)"
                     [loadApiFunction]="tradePricingLoadApiFunction"
                     [hideForIcons]="['Fixed Priced']"
                     [rowData]="rowData" ></ctrm-hover-icon>
</ng-template>

<ng-template #notPriced>
    <div>
        <div style="display: inline-flex; margin: 10px;background: #fef5e9;padding: 10px;">
            <i class='fas fa-exclamation-triangle' style='font-size:17px;color:#ff8400;padding: 5px'></i>
            <p>Trade Pricing and Price Allocation Not Available.</p>
        </div>
    </div>
</ng-template >

<ng-template #notAllocated let-dbResponse="dbResponse">
    <div>
        <div class="priceAdding">
            <div class="fieldBox">
                <p class="fieldName">Priced Quantity</p>
                <p class="bodyContent"> {{commonService.getFormatedNumber(dbResponse[0]['pricedQuantity'])+' '+dbResponse[0]['priceUomName']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Average Pirce</p>
                <p class="bodyContent"> {{commonService.getFormatedNumber(dbResponse[0]['averagePrice']) + ' ' + dbResponse[0]['priceCurrency'] + '/' + dbResponse[0]['priceUomName']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Pricing Status</p>
                <p class="bodyContent"> {{dbResponse[0]['priceStatus']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Allocated By</p>
                <p class="bodyContent"> {{dbResponse[0]['createdBy']}}</p>
            </div>

        </div>
    </div>
    <hr>
    <p style=" color: #15104E;  font-weight: 600; margin-left: 10px;">Price Allocation</p>
    <div>
        <div style="display: inline-flex; margin: 10px;background: #fef5e9;padding: 10px;">
            <i class='fas fa-exclamation-triangle' style='font-size:17px;color:#ff8400;padding: 5px'></i>
            <p>Price Allocation Details Not Available.</p>
        </div>
    </div>
</ng-template >

<ng-template #partiallyAllocated let-dbResponse="dbResponse" let-rowData="rowData">
    <div>
        <div class="priceAdding">
            <div class="fieldBox">
                <p class="fieldName">Priced Quantity</p>
                <p class="bodyContent"> {{commonService.getFormatedNumber(dbResponse[0]['pricedQuantity'])+' '+dbResponse[0]['priceUomName']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Average Pirce</p>
                <p class="bodyContent"> {{commonService.getFormatedNumber(dbResponse[0]['averagePrice']) + ' ' + dbResponse[0]['priceCurrency'] + '/' + dbResponse[0]['priceUomName']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Pricing Status</p>
                <p class="bodyContent"> {{dbResponse[0]['priceStatus']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Allocated By</p>
                <p class="bodyContent"> {{dbResponse[0]['createdBy']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Allocated Date</p>
                <p class="bodyContent"> {{dbResponse[0]['createdTimestamp'] == null || dbResponse[0]['createdTimestamp'] == undefined ?'':(dbResponse[0]['createdTimestamp']| date: 'dd MMM yyyy')}}</p>
            </div>
        </div>
    </div>
    <hr>
    <p style=" color: #15104E;  font-weight: 600; margin-left: 10px;">Price Allocation</p>
    <div>
        <div class="priceAdding">
            <div class="fieldBox">
                <p class="fieldName"> Quantity</p>
                <p class="bodyContent"> {{commonService.getFormatedNumber(dbResponse[0]['allocationQunaity'])+' '+dbResponse[0]['priceUomName']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName"> Price</p>
                <p class="bodyContent"> {{commonService.getFormatedNumber(dbResponse[0]['allocationPrice']) + ' ' + dbResponse[0]['priceCurrency'] + '/' + dbResponse[0]['priceUomName']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName"> Status</p>
                <p class="bodyContent"> {{rowData['priceAllocationstatus']}}</p>
            </div>

        </div>
    </div>
</ng-template >

<!--Transport Allocation Template-->
<ng-template #transportAllocationStatusTemplate let-rowData="rowData">
    <ctrm-hover-icon
                     [iconNameList]="['Transport Allocation Done','Transport Allocation Pending','Washout Plan']"
                     [iconList]="['deliveryStarted.svg','deliveryPending.svg','deliveryNotAllowed.svg']"
                     [templateList]="[deliveryStarted,deliveryPending,null]"
                     [iconDecidingFunction]="deliveryIconPath"
                     [headingFunction]="transportHeadingFunction"
                     (onHover)="onHover($event)"
                     [hideForIcons]="['Washout Plan']"
                     (onButtonClick)="transportButtonClick($event)"
                     [loadApiFunction]="transportAllocationLoadApiFunction"
                     [rowData]="rowData">
    </ctrm-hover-icon>
</ng-template>

<ng-template #deliveryStarted let-dbResponse="dbResponse">
    <div>
        <div class="priceAdding">
            <div style = "width:100px;"  class="fieldBox">
                <p class="fieldName">Vessel Name</p>
                <p class="bodyContent"> {{dbResponse['vesselName']}}</p>
            </div>
            <div style = "width:100px;"  class="fieldBox">
                <p class="fieldName">Allocated By</p>
                <p class="bodyContent"> {{dbResponse['createdBy']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Allocated Date</p>
                <p class="bodyContent"> {{dbResponse['createdTimestamp'] == null || dbResponse['createdTimestamp'] == undefined ?'':dbResponse['createdTimestamp'] | date: 'dd MMM yyyy'}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Updated Date</p>
                <p class="bodyContent"> {{dbResponse['updatedTimestamp'] == null || dbResponse['updatedTimestamp'] == undefined ?'':dbResponse['updatedTimestamp'] | date: 'dd MMM yyyy'}}</p>
            </div>
        </div>
        <div class="priceAdding">
            <div style = "width:100px;" class="fieldBox">
                <p class="fieldName">Laycan Date</p>
                <p class="bodyContent"> {{commonService.getFormattedDate(dbResponse['laycanDate'],dateFormat)}}</p>
            </div>
            <div style = "width:100px;"  class="fieldBox">
                <p class="fieldName">Sailing Date</p>
                <p class="bodyContent"> {{commonService.getFormattedDate(dbResponse['sailingDate'],dateFormat)}}</p>
            </div>
        </div>
        <div class="priceAdding">
            <div  style = "width:100px;" class="fieldBox">
                <p class="fieldName">ETA From</p>
                <p class="bodyContent"> {{commonService.getFormattedDate(dbResponse['etaFrom'],dateFormat)}}</p>
            </div>
            <div style = "width:100px;"  class="fieldBox">
                <p class="fieldName">ETA To</p>
                <p class="bodyContent"> {{commonService.getFormattedDate(dbResponse['etaTo'],dateFormat)}}</p>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #deliveryPending let-rowData = 'rowData'>

    <div *ngIf="showBookAVessel">
        <div style="display: inline-flex; margin: 10px;background: #fef5e9;padding: 10px;">
            <i class='fas fa-exclamation-triangle' style='font-size:17px;color:#ff8400;padding: 5px'></i>
                <p>No Vessel found for required <br> Load/Unload Port.</p>
        </div>

        <div style="padding: 5px; background: #e3e3e3; margin: 0px;text-align: center">
            <button class="priceBtn" (click)="bookAVessel()">Book a Vessel</button>
        </div>
    </div>
</ng-template>

<!--Transport Actualization Template-->
<ng-template #actualizationStatusTemplate let-rowData="rowData">
    <ctrm-hover-icon
                     [iconNameList]="['Actualization Done','Actualization Pending']"
                     [iconList]="['actualizationDone.svg','actualizationPending.svg']"
                     [templateList]="[actualizationDone,actualizationPending]"
                     [iconDecidingFunction]="actualizationIconPath"
                     [headingFunction]="actualizationHeadingFunction"
                     (onHover)="onHover($event)"
                     (onButtonClick)="onActualizationDoneClick($event)"
                     [loadApiFunction]="actualizationLoadApiFunction"
                     [rowData]="rowData">
    </ctrm-hover-icon>
</ng-template>

<ng-template #actualizationDone let-dbResponse="dbResponse">
    <div class="priceAdding">
        <div style = "width:100px;"  class="fieldBox">
            <p class="fieldName">#Actual Quantity</p>
            <p class="bodyContent"> {{commonService.getFormatedNumber(dbResponse['loadQuantity'])+' '+dbResponse['quantityUom']}}</p>
        </div>
        <div style = "width:100px;"  class="fieldBox">
            <p class="fieldName">Actualized By</p>
            <p class="bodyContent"> {{dbResponse['createdBy']}}</p>
        </div>
        <div class="fieldBox">
            <p class="fieldName">Actualized Date</p>
            <p class="bodyContent"> {{dbResponse['createdTimestamp'] == null || dbResponse['createdTimestamp'] == undefined ?'':dbResponse['createdTimestamp'] | date : 'dd MMM yyyy'}}</p>
        </div>
        <div class="fieldBox">
            <p class="fieldName">Updated Date</p>
            <p class="bodyContent"> {{dbResponse['updatedTimestamp'] == null || dbResponse['updatedTimestamp'] == undefined ?'':dbResponse['updatedTimestamp'] | date : 'dd MMM yyyy'}}</p>
        </div>
    </div>
</ng-template>



<ng-template #actualizationPending let-rowData = 'rowData'>

    <div *ngIf="actualizationVesselBooking">
        <div style="display: inline-flex; margin: 10px;background: #fef5e9;padding: 10px;">
            <i class='fas fa-exclamation-triangle' style='font-size:17px;color:#ff8400;padding: 5px'></i>
            <p>No Vessel found for required <br> Load/Unload Port.</p>
        </div>

        <div style="padding: 5px; background: #e3e3e3; margin: 0px;text-align: center">
            <button class="priceBtn" (click)="bookAVessel()">Book a Vessel</button>
        </div>
    </div>
</ng-template>

<!--Invoice Template-->
<ng-template #Pending let-rowData="rowData" let-dbResponse="dbResponse">
    <div >
        <p style="margin: 10px; font-size: 14px">Pending to generate Invoice </p>
    </div>
</ng-template>
<ng-template #invoicingStatusTemplate let-rowData="rowData">
    <ctrm-hover-icon
                     [iconNameList]="['Proforma invoiced','Provisional invoiced only','Pending invoice','Final Invoiced_provisonal','Final invoiced']"
                     [iconList]="['Proforma invoiced.svg','Provisional Invoiced Only.svg','Pending Invoice.svg','Final_Invoiced_provisonal.svg','Final Invoiced.svg']"
                     [templateList]="[null,Provisionalinvoicedonly,Pending,Finalinvoicedprovisional,Finalinvoiced]"
                     [iconDecidingFunction]="invoiceIconPath"
                     [headingFunction]="invoiceheaderfunction"
                     (onHover)="onHover($event)"
                     (onButtonClick)="onButtonClickInvoice($event)"
                     [loadApiFunction]="invoiceLoadapi"
                     [hideForIcons]="['Proforma invoiced']"
                     [rowData]="rowData" ></ctrm-hover-icon>
</ng-template>

<ng-template #Provisionalinvoicedonly let-rowData="rowData" let-dbResponse="dbResponse">
    <div>
        <div class="priceAdding">
            <div class="fieldBox">
                <p class="fieldName">Provisional Invoice No</p>
<!--                <p class="bodyContent"> {{commonService.getFormatedNumber(dbResponse[0]['pricedQuantity'])+' '+dbResponse[0]['priceUomName']}}</p>-->
                <p class="bodyContent"> {{(dbResponse['provisionalInvoiceNumber'] !== null && dbResponse['provisionalInvoiceNumber'] !== undefined && dbResponse['provisionalInvoiceNumber'].length > 0)?dbResponse['provisionalInvoiceNumber']:'-'}} </p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Provisional Invoice Amount</p>
<!--                <p class="bodyContent"> {{commonService.getFormatedNumber(dbResponse[0]['averagePrice']) + ' ' + dbResponse[0]['priceCurrency'] + '/' + dbResponse[0]['priceUomName']}}</p>-->
                <p class="bodyContent">{{(dbResponse['provisionalInvoiceNumber'] !== null && dbResponse['provisionalInvoiceNumber'] !== undefined && dbResponse['provisionalInvoiceNumber'].length > 0)?commonService.getFormatedNumber(dbResponse['provisionalInvoiceAmount']) + ' '+ dbResponse['provisionalInvoiceCurrency']:'-'}}</p>
            </div>
        </div>
        <div class="priceAdding">
            <div class="fieldBox">
                <p class="fieldName">Provisional Invoice Date</p>
<!--                <p class="bodyContent"> {{dbResponse[0]['priceStatus']}}</p>-->
                <p class="bodyContent">{{commonService.getFormattedDate(dbResponse['provisionalInvoiceDate'],dateFormat)}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Invoiced By</p>
                <p class="bodyContent">{{dbResponse['createdBy']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Invoiced Date</p>
                <p class="bodyContent"> {{dbResponse['createdTimestamp'] == null || dbResponse['createdTimestamp'] == undefined ?'':(dbResponse['createdTimestamp']| date: 'dd MMM yyyy')}}</p>
            </div>
        </div>
    </div>
</ng-template>



<ng-template #Finalinvoiced let-rowData="rowData" let-dbResponse="dbResponse">
    <div>
        <div class="priceAdding">
            <div class="fieldBox">
                <p class="fieldName">Invoiced By</p>
                <p class="bodyContent">{{dbResponse['createdBy']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Invoiced Date</p>
                <p class="bodyContent">{{dbResponse['createdTimestamp'] == null || dbResponse['createdTimestamp'] == undefined ?'':dbResponse['createdTimestamp'] | date: 'dd MMM yyyy'}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Updated Date</p>
                <p class="bodyContent"> {{dbResponse['updatedTimestamp'] == null || dbResponse['updatedTimestamp'] == undefined ?'':dbResponse['updatedTimestamp'] | date : 'dd MMM yyyy'}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Final Invoice No</p>
                <p class="bodyContent">{{dbResponse['finalInvoiceNumber']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Final Invoice Amount</p>
                <p class="bodyContent">{{commonService.getFormatedNumber(dbResponse['finalInvoiceAmount']) + ' '+ dbResponse['finalInvoiceCurrency']}}</p>
            </div>

        </div>
        <div class="priceAdding">
            <div class="fieldBox">
                <p class="fieldName">Final Invoice Date</p>
                <p class="bodyContent">{{commonService.getFormattedDate(dbResponse['finalInvoiceDate'],dateFormat)}}</p>
            </div>

        </div>
    </div>
</ng-template>

<ng-template #Finalinvoicedprovisional let-rowData="rowData" let-dbResponse="dbResponse">
    <div>
        <div class="priceAdding">
            <div class="fieldBox">
                <p class="fieldName">Provisional Invoice No</p>
                <p class="bodyContent">{{(dbResponse['provisionalInvoiceNumber'] !== null && dbResponse['provisionalInvoiceNumber'] !== undefined && dbResponse['provisionalInvoiceNumber'].length > 0)?dbResponse['provisionalInvoiceNumber']:'-'}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Provisional Invoice Amount</p>
                <p class="bodyContent">{{(dbResponse['provisionalInvoiceNumber'] !== null && dbResponse['provisionalInvoiceNumber'] !== undefined && dbResponse['provisionalInvoiceNumber'].length > 0)?commonService.getFormatedNumber(dbResponse['provisionalInvoiceAmount']) + ' '+ dbResponse['provisionalInvoiceCurrency']:'-'}}</p>
            </div>
        </div>
        <div class="priceAdding">
            <div class="fieldBox">
                <p class="fieldName">Final Invoice No</p>
                <p class="bodyContent">{{dbResponse['finalInvoiceNumber']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Final Invoice Amount</p>
                <p class="bodyContent">{{commonService.getFormatedNumber(dbResponse['finalInvoiceAmount']) + ' '+ dbResponse['finalInvoiceCurrency']}}</p>
            </div>
        </div>
        <div class="priceAdding">
            <div class="fieldBox">
                <p class="fieldName">Final Invoice Date</p>
                <p class="bodyContent">{{commonService.getFormattedDate(dbResponse['finalInvoiceDate'],dateFormat)}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Invoiced By</p>
                <p class="bodyContent">{{dbResponse['createdBy']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Invoiced Date</p>
                <p class="bodyContent"> {{dbResponse[0]['createdTimestamp'] == null || dbResponse[0]['createdTimestamp'] == undefined ?'':(dbResponse[0]['createdTimestamp']| date: 'dd MMM yyyy')}}</p>
            </div>
        </div>
    </div>
</ng-template>

<p-dialog [(visible)]="generateDocument"
          [closeOnEscape]="false"
          (onHide)="closeModal()"
          [contentStyle]="{ overflow: 'scroll', overflowX:'hidden',height:'100%'}"
          [draggable]="false"
          [maximizable]="false" [modal]="true" [showHeader]="false" [appendTo]="'body'" [autoZIndex]="true"
          [style]="{width: '15%',padding:'0px',background:'#ffffff',borderRadius:'4px'}">

        <div style="padding: 10px">
            <p class="sectiontitle">Operation Document</p>
        </div>
        <div class="form-content">
            <div class="item-container">
                <p class="fieldName">Document Type <span class="mandatory-span">*</span></p>
                <p-dropdown [(ngModel)]="documentType" [placeholder]="'Select Document Type'" [appendTo]="'body'"
                            [options]="documentTypeList" (ngModelChange)="onChangeInstruction($event)"></p-dropdown>
            </div>
            <div class="item-container">
                <p class="fieldName">Document Template <span class="mandatory-span">*</span></p>
                <p-multiSelect [options]="documentTemplateList" [disabled]="documentTypeDisabled"
                               optionLabel="label" [defaultLabel]="'Select Document Template'" [appendTo]="'body'" [(ngModel)]="documentTemplate" (ngModelChange)="getDocFormatList()"></p-multiSelect>
            </div>
            <div class="item-container">
                <p class="fieldName">File Format <span class="mandatory-span">*</span></p>
                <p-dropdown [(ngModel)]="documentFormat" [placeholder]="'Select Document Format'" [appendTo]="'body'"
                            [options]="documentFormatList" ></p-dropdown>
            </div>
        </div>


        <div class="generate-doc-footer">
            <button class="cancelBtn" (click)="closeModal()">Cancel</button>
            <button class="generateBtn" [disabled]="disableGenerate()" style="padding-left: 20px;padding-right: 20px" (click)="generateDocumentFunc()">{{shippingFormButtonLabel}}</button>
        </div>

</p-dialog>

<p-dialog [(visible)]="showSplitPopup"
          [closeOnEscape]="false"
          (onHide)="closeSplitModal()"
          [contentStyle]="{ overflow: 'scroll', overflowX:'hidden',height:'100%'}"
          [draggable]="false"
          [maximizable]="false" [modal]="true" [showHeader]="false" [appendTo]="'body'" [autoZIndex]="true"
          [style]="{width: '15%',padding:'0px',background:'#ffffff',borderRadius:'4px'}">

    <div style="padding: 10px">
        <p class="sectiontitle">Split Obligation {{selectedContextData['plannedObligationId']}}</p>
    </div>
    <div class="form-content">
        <div class="item-container">
            <p class="fieldName">Enter quantity want to split<span class="mandatory-span">*</span></p>
            <input pInputText class="form-control form-control-sm input" [(ngModel)]="splitQuantityValue" type="number">
        </div>
    </div>


    <div class="generate-doc-footer">
        <button class="cancelBtn" (click)="closeSplitModal()">Cancel</button>
        <xceler-progress-button
                [callApiObjectFunction]="splitApiFunction"
                (onLoadDone)="OnSplitDone($event)"
                (onError)="onErrorSplit($event)"
                [buttonStyle]="{margin:'0px',minHeight:'35px',padding:'0px',paddingLeft:'10px',paddingRight:'10px'}"
                [buttonLoadingTitle]="'Splitting'"
                [buttonIdleTitle]="'Split Quantity'">
        </xceler-progress-button>
    </div>

</p-dialog>

<p-dialog [(visible)]="showInterCompanySplitPopUp"
          [closeOnEscape]="false"
          (onHide)="closeInterCompanySplitModal()"
          [contentStyle]="{ overflow: 'scroll', overflowX:'hidden',height:'100%'}"
          [draggable]="false"
          [maximizable]="true" [modal]="true" [showHeader]="false" [appendTo]="'body'" [autoZIndex]="true"
          [style]="{width: '50%',padding:'0px',background:'#ffffff',borderRadius:'4px'}">

    <div style="padding: 10px">
        <p class="sectiontitle">Split Obligation {{selectedContextData['plannedObligationId']}}</p>
    </div>
    <div class="form-content">
        <div class="item-container">
            <p style="font-size: 14px;">
                This obligation {{selectedContextData['plannedObligationId']}} is linked to Inter Company trades. Splitting or merging it will reset the Inter Company trade data and user has to enter Inter Company data from Operation Dashboard again.
                Do you want to continue?
            </p>
        </div>
        <div class="generate-doc-footer">
            <button  class="interCompanySplitBtn" (click)="deleteInterCompanyForSplit(selectedContextData)">Continue</button>
            <button class="interCompanySplitCancelBtn" (click)="closeInterCompanySplitModal()">Cancel</button>
        </div>
    </div>

</p-dialog>



<!--FX ALlocation Template-->
<ng-template #fxAllocationStatusTemplate let-rowData="rowData">
    <ctrm-hover-icon 
                     [iconNameList]="['FX Allocation Pending','FX Allocation Done','FX Partially Allocated']"
                     [iconList]="['fx_allocation_pending.svg','fx_allocation_done.svg','fx_allocation_yellow.svg']"
                     [templateList]="[notFXAllocated,partiallyFXAllocated,partiallyFXAllocated]"
                     [iconDecidingFunction]="iconPathFXAllocation"
                     [headingFunction]="fxHeadingFunction"
                     (onHover)="onHover($event)"
                     (onButtonClick)="onButtonClickFXAllocation($event)"
                     [loadApiFunction]="fxAllocationLoadApiFunction"
                     [rowData]="rowData">
    </ctrm-hover-icon>
</ng-template>

<ng-template #notFXAllocated>
    <div *ngIf="!fxnotapplicable">
        <div style="display: inline-flex; margin: 10px;background: #fef5e9;padding: 10px;">
            <i class='fas fa-exclamation-triangle' style='font-size:17px;color:#ff8400;padding: 5px'></i>
            <p>FX not allocated. Click the button to Allocate.</p>
        </div>
        <div style="padding: 5px; background: #e3e3e3; margin: 0px;text-align: center">
            <button class="priceBtn" (click)="bookAVessel()">FX Allocation</button>
        </div>
    </div>


</ng-template>

<ng-template #partiallyFXAllocated let-dbResponse="dbResponse" let-rowData="rowData">
    <div>
        <div class="priceAdding">
            <div class="fieldBox">
                <p class="fieldName">FX Allocated Value (in {{rowData['settlementCurrency']}})</p>
                <p class="bodyContent hyperLinkClass" (click)="hyperLinkFxAllocation(dbResponse)"> {{dbResponse['fxAllocatedValue']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">% FX Allocated</p>
                <p class="bodyContent">{{dbResponse['fxAllocatedPercentage']| number:'1.0-2'}}<span>%</span></p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Allocated By </p>
                <p class="bodyContent">{{dbResponse['createdBy']}}</p>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Allocated Date</p>
                <p class="bodyContent"> {{dbResponse[0]['createdTimestamp'] == null || dbResponse[0]['createdTimestamp'] == undefined ?'':(dbResponse[0]['createdTimestamp']| date: 'dd MMM yyyy')}}</p>
            </div>
        </div>
    </div>
</ng-template>

<app-trade-info (onClose)="onClose($event)" *ngIf="display" [display]="true" [rowData]="rowData"
                [tradeId]="tradeId" [detailsOf]="'plannedObligation'" [idValue]="rowData['plannedObligationId']"></app-trade-info>

<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body">
</p-toast>

<addcost *ngIf="showAddCost"
(OnApply)="onCloseCost()"
(OnCancel)="onCloseCost()"
[costFor]="'OBLIGATION'"
[linkTo]="linkTo"
></addcost>

<circle-planning-info *ngIf="washoutPlanning"
                      [visible]="true" [buyObligationRequired]="buyObligationRequired" [buyObligation]="buyObligation"
                      [sellObligationRequired]="sellObligationRequired" [sellObligation]="sellObligation" [title]="'Doc Bypass'"
                      (onComplete)="onCompleteDocByPass($event)"
                      (onCancel)="onCancelDocByPass()"
                      [showVesselBox]="false"
></circle-planning-info>


  <app-inter-company-trade *ngIf="interCompanyTrade" [visible]="true" [obligation]="obligation"
                       (onComplete)="onCompleteInterCompanyTrade($event)" (onCancel)="onCancelInterCompanyTrade()"
  ></app-inter-company-trade>


<app-generate-proforma-invoice
        [showToastMessage]="false"
        *ngIf="displayInvoiceForm"
        [invoiceObject]="invoiceProFormaObject"
        (onDraftClose)="onDraftClose()"
        [obligationObject]="proFormaInvoiceRow"
        (onGenerateDone)="onGenerateDone()"
        [tradeId]="proFormaInvoiceRow['tradeId']"
        [sell]="proFormaInvoiceRow['tradeTransactionType'] === 'SELL'">

</app-generate-proforma-invoice>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>




