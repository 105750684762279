import {Tcolumn} from './tcolumn.model';
import {pconfig} from '../Components/ctrm-pivot-table/pconfig.model';
import {Tab} from '../tab-grid/Tab.model';
import {ChildAction} from './ChildAction.model';
import {environment} from '../../environments/environment';
import {Button} from './buttons.model';
import {Filter} from './preference/filter/filter.model';

export class Grid {
  get filterHiddenFields(): any[] {
    return this._filterHiddenFields;
  }

  set filterHiddenFields(value: any[]) {
    this._filterHiddenFields = value;
  }


  private addedFilter: any[] = [];
  private _deleteFunction: Function = null;
  private _baseUrl:string = environment.base_url;
  private _auditEntityName:string = '';
  private _hiddenFieldsToShowInStandardFilters:any[] =[];
  private _fieldRequiresContinuesChangeDetection: any[] = [];
  private _gridColumnFilterAPI: string;
  private _useDefaultInlineEditFunction:boolean = true;
  private _filterHiddenFields:any[] =[];
  private _urlModifier?:Function;
  contextMenuConfig: { referenceIdField: string, triggerEvent: string, nameFieldId: string, screenName: string, functionFile: { [key: string]: Function } } = {
    referenceIdField: '',
    triggerEvent: '',
    nameFieldId: '',
    screenName: '',
    functionFile: {}
  };

  constructor(
    private columns: Map<string, Tcolumn>,
    private isGlobalExport: boolean,
    private isSelectedColumnExport: boolean,
    private isGlobalSearch ?: boolean,
    private isColumnSearch ?: boolean,
    private primaryKey ?: string,
    private dataURL ?: string,
    private infoUrl ?: string,
    private title ?: string,
    private previousLevelPrimaryKey ?: string,
    private firstLevelDataUrl ?: string,
    private pivotConfig ?: pconfig,
    private tabs?: Tab[],
    private showTabOnModal?: boolean,
    private childCopyDelete ?: ChildAction,
    private clickable: string[] = [],
    private width: string = '150%',
    private extra: any = {istabs: false},
    private gridConfiguration ?: any,
    private haveHierarchy ?: boolean,
    private saveUrl?: string,
    private queryParams: string[] = [],
    private extraButtons: Button[] = [],
    private filters: Filter[] = [],
    private _onEditViewThrowUnique: boolean = false,
    private _iconName: string = '',
    private infoFields: string[] = [],
    private sectionMap: Map<string, any> = new Map<string, any>(),
    private _openSidebarOnUniqueClick: boolean = true,
    private _updateUrl?: string,
    private _formUpdateButtonDisableFuncton?: Function,
    private _isHaveExtraButtons: boolean = false,
    private _updateCode: string = 'uuid',
    private _updateUrlType: string = 'post',
    private _hrefUrl: string = '',
    private _addNewRowInline: boolean = false,
    private _openDefaultInputForm: boolean = true,
    private _showExtraButtons: boolean = true,
    private _actionFunc: { canEditFunction: Function, newCopyValueFunc: Function,canGoToNextFunction:Function, preSaveFunc:Function,preImportFunction:Function,resetFunction:Function } = {
      canEditFunction: null,
      newCopyValueFunc: null,
      canGoToNextFunction:null,
      preSaveFunc:null,
      preImportFunction:null,
      resetFunction:null
    },
    private _ignoreFieldListForExport: string[] = ['id'],
    private _childTabs: Tab[] = [],
    private _importUrl: string = '',
    private _urlIdField: string = 'uuid',
    private _toolbarConfig: {
      add: boolean,
      edit: boolean,
      copy: boolean,
      delete: boolean,
      refresh: boolean,
      export: boolean,
      import: boolean,
      audit: boolean,
      print: boolean,
      prefrences: boolean
    } = {
      add: true,
      edit: true,
      copy: true,
      delete: true,
      refresh: true,
      export: true,
      import: true,
      audit: true,
      print: true,
      prefrences: true
    },private _gridAdvacedFilterURL?: string,
    private _gridFilterURL?: string,
    private _gridFetchUrlType:string = 'GET',
    private _fetchPostPayloadFuction:Function = null,
    private _deleteUrl?:string,
    private _showCheckbox:boolean = true,
    private _expandGrid?:Grid,
    private _canShowExpandGrid?:Function,
    private _useDefaultEditFunction:boolean = true,
    private _useDefaultDuplicateFunction:boolean = true,
    private _primaryKeyClickable:boolean = true,
    private _selectedRows:any[] = [],
    private _defineHrefFunction?: Function){
    this.setGridConfiguration();
  };

  get baseUrl(): string {
    return this._baseUrl;
  }

  set baseUrl(value: string) {
    this._baseUrl = value;
  }
  get gridFetchUrlType(): string {
    return this._gridFetchUrlType;
  }

  set gridFetchUrlType(value: string) {
    this._gridFetchUrlType = value;
  }

  get fetchPostPayloadFuction(): Function {
    return this._fetchPostPayloadFuction;
  }


  get urlModifier(): Function {
    return this._urlModifier;
  }

  set urlModifier(value: Function) {
    this._urlModifier = value;
  }

  set fetchPostPayloadFuction(value: Function) {
    this._fetchPostPayloadFuction = value;
  }

  get ignoreFieldListForExport(): string[] {
    return this._ignoreFieldListForExport;
  }

  set ignoreFieldListForExport(value: string[]) {
    this._ignoreFieldListForExport = value;
  }

  get onEditViewThrowUnique(): boolean {
    return this._onEditViewThrowUnique;
  }

  set onEditViewThrowUnique(value: boolean) {
    this._onEditViewThrowUnique = value;
  }

  get iconName(): string {
    return this._iconName;
  }

  set iconName(value: string) {
    this._iconName = value;
  }

  get openSidebarOnUniqueClick(): boolean {
    return this._openSidebarOnUniqueClick;
  }

  set openSidebarOnUniqueClick(value: boolean) {
    this._openSidebarOnUniqueClick = value;
  }

  get updateUrl(): string {
    return this._updateUrl;
  }

  set updateUrl(value: string) {
    this._updateUrl = value;
  }

  get updateCode(): string {
    return this._updateCode;
  }

  set updateCode(value: string) {
    this._updateCode = value;
  }

  get formUpdateButtonDisableFuncton(): Function {
    return this._formUpdateButtonDisableFuncton;
  }

  set formUpdateButtonDisableFuncton(value: Function) {
    this._formUpdateButtonDisableFuncton = value;
  }

  get isHaveExtraButtons(): boolean {
    return this._isHaveExtraButtons;
  }

  set isHaveExtraButtons(value: boolean) {
    this._isHaveExtraButtons = value;
  }

  get updateUrlType(): string {
    return this._updateUrlType;
  }

  set updateUrlType(value: string) {
    this._updateUrlType = value;
  }

  get hrefUrl(): string {
    return this._hrefUrl;
  }

  set hrefUrl(value: string) {
    this._hrefUrl = value;
  }

  get addNewRowInline(): boolean {
    return this._addNewRowInline;
  }

  set addNewRowInline(value: boolean) {
    this._addNewRowInline = value;
  }

  get openDefaultInputForm(): boolean {
    return this._openDefaultInputForm;
  }

  set openDefaultInputForm(value: boolean) {
    this._openDefaultInputForm = value;
  }

  get showExtraButtons(): boolean {
    return this._showExtraButtons;
  }

  set showExtraButtons(value: boolean) {
    this._showExtraButtons = value;
  }


  get actionFunc(): { canEditFunction: Function; newCopyValueFunc: Function; canGoToNextFunction: Function, preSaveFunc: Function,preImportFunction:Function,resetFunction:Function} {
    return this._actionFunc;
  }

  set actionFunc(value: { canEditFunction: Function; newCopyValueFunc: Function; canGoToNextFunction: Function, preSaveFunc: Function,preImportFunction:Function,resetFunction:Function}) {
    this._actionFunc = value;
  }

  get childTabs(): Tab[] {
    return this._childTabs;
  }

  set childTabs(value: Tab[]) {
    this._childTabs = value;
  }

  get importUrl(): string {
    return this._importUrl;
  }

  set importUrl(value: string) {
    this._importUrl = value;
  }


  get toolbarConfig(): { add: boolean; edit: boolean; copy: boolean; delete: boolean; refresh: boolean; export: boolean; import: boolean; audit: boolean; print: boolean; prefrences: boolean } {
    return this._toolbarConfig;
  }

  setToolbarConfig(add: boolean = true, edit: boolean = true, copy: boolean = true, canDelete: boolean = true, refresh: boolean = true, canExport: boolean = true, canImport: boolean = true, audit: boolean = true, print: boolean = true, prefrences: boolean = true) {
    this._toolbarConfig = {
      add:add,
      edit: edit,
      copy: copy,
      delete: canDelete,
      refresh: refresh,
      export: canExport,
      import: canImport,
      audit: audit,
      print: print,
      prefrences: prefrences
    }
  }


  public getHierarchy() {
    return this.haveHierarchy;
  }

  public setHierarchy(hierarchy: boolean) {
    return this.haveHierarchy = hierarchy;
  }

  public getColumn() {
    return this.columns;
  }

  public getGlobalExport() {
    return this.isGlobalExport;
  }

  public getPrimaryKey() {
    return this.primaryKey;
  }

  public setPrimaryKey(primaryKey: string) {
    return this.primaryKey = primaryKey;
  }

  public getPreviousLevelPrimaryKey() {
    return this.previousLevelPrimaryKey;
  }

  public setPreviousLevelPrimaryKey(primaryKey: string) {
    return this.previousLevelPrimaryKey = primaryKey;
  }

  public getFirstLevelDataUrl() {
    return this.firstLevelDataUrl;
  }

  public setFirstLevelDataUrl(url: string) {
    return this.firstLevelDataUrl = url;
  }

  public getChildCopyDelete() {
    return this.childCopyDelete;
  }

  public setChildCopyDelete(copyDeleteChild: ChildAction) {
    return this.childCopyDelete = copyDeleteChild;
  }

  public getDataURL() {
    return this.dataURL;
  }

  public setDataURL(newUrl) {
    this.dataURL = newUrl;
  }

  public getInfoUrl() {
    return this.infoUrl;
  }

  public setTitle(newTitle) {
    this.title = newTitle;
  }

  public getTitle() {
    return this.title;
  }

  public setColumn(newcolumn) {
    this.columns = newcolumn;
  }

  public setPivotConfig(newconfig: pconfig) {
    this.pivotConfig = newconfig;
  }

  public getPivotConfig() {
    return this.pivotConfig;
  }

  public getTabs() {
    return this.tabs;
  }

  public addTab(tab: Tab) {
    this.tabs.push(tab);
  }

  public ShowTabOnModal() {
    return this.showTabOnModal;
  }

  public getClickable() {
    return this.clickable;
  }

  public addClickable(clickable: string) {
    this.clickable.push(clickable)
  }

  public setTabs(tabs: Tab[]) {
    this.tabs = tabs;
  }

  public setTabsAtIndex(tab: Tab, index: number) {
    this.tabs.splice(index, 1);
    this.tabs.splice(index, 0, tab);
  }

  public getWidth() {
    return this.width;
  }

  public setWidth(width) {
    this.width = width;
  }

  public getFromExtra(key: any) {
    if (this.extra !== undefined && this.extra[key] !== undefined) {
      return this.extra[key];
    } else {
      return {};
    }
  }

  public setToExtra(key: any, value: any) {
    this.extra[key] = value;
  }

  public getExtra() {
    return this.extra;
  }

  public setExtra(extra: any) {
    this.extra = extra;
  }

  public getGridConfig() {
    return this.gridConfiguration;
  }

  public setSaveUrl(url) {
    this.saveUrl = url;
  }

  public setUpdateUrl(url) {
    this._updateUrl = url;
  }


  get urlIdField(): string {
    return this._urlIdField;
  }

  set urlIdField(value: string) {
    this._urlIdField = value;
  }

  public getSaveUrl() {
    if (this.saveUrl !== undefined && this.saveUrl.length > 0) {
      return this.saveUrl;
    }
    return environment.base_url + this.dataURL.split('/')[0];
  }

  public setGridConfiguration(canAdd: boolean = true, canEdit: boolean = true, canCopy: boolean = true, canDelete: boolean = false,
                              canImport: boolean = true, canExport: boolean = true, canDownloadSelected: boolean = true, canPrint: boolean = true,
                              canAudit: boolean = true, canView: boolean = true, secondLevelEdit = true) {
    this.gridConfiguration = {
      canAdd: canAdd,
      canEdit: canEdit,
      canCopy: canCopy,
      canDelete: canDelete,
      canImport: canImport,
      canExport: canExport,
      canDownloadSelected: canDownloadSelected,
      canPrint: canPrint,
      canAudit: canAudit,
      canView: canView,
      secondLevelEdit: secondLevelEdit
    };
  }

  public getQueryParams() {
    return this.queryParams;
  }

  public addQueryParamsField(queryParamsField: string) {
    this.queryParams.push(queryParamsField);
  }

  public addExtraButton(button: Button) {
    this.extraButtons.push(button);
  }

  public removeExtraButton(title: string) {
    let _this = this;
    this.extraButtons.forEach(function (button: Button, index: number) {
      if (button.title === title) {
        _this.extraButtons.splice(index, 1);
      }
    });
  }

  public disableButton(title: string) {
    let _this = this;
    this.extraButtons.forEach(function (button: Button, index: number) {
      if (button.title === title) {
        _this.extraButtons[index].disabled = true;
      }
    });
  }

  public disableAllExtraButtons() {
    let _this = this;
    this.extraButtons.forEach(function (button: Button, index: number) {
      _this.extraButtons[index].disabled = true;
    });
  }

  public enableButton(title: string) {
    let _this = this;
    this.extraButtons.forEach(function (button: Button, index: number) {
      if (button.title === title) {
        _this.extraButtons[index].disabled = false;
      }
    });
  }

  public removeAllExtraButtons() {
    this.extraButtons = [];
  }

  public getExtraButtons() {
    return this.extraButtons;
  }

  public addInFilter(filter: Filter) {
    this.removeFilter(filter.getColumnName());
    this.filters.push(filter);
    this.addedFilter.push(filter.getColumnName());
  }

  public removeFilter(columnName: string) {
    if (this.addedFilter.includes(columnName)) {
      let index = this.addedFilter.indexOf(columnName);
      this.filters.splice(index, 1);
      this.addedFilter.splice(index, 1);
    }
  }

  getAddedList() {
    return this.addedFilter;
  }

  public getFilters() {
    return this.filters;
  }

  /**
   * set array of default filters
   * @param filters
   */
  setFilters(filters: Filter[]) {
    this.filters = filters;
  }

  public getInfoFields() {
    return this.infoFields;
  }

  public setInfoFields(fields: string[]) {
    this.infoFields = fields;
  }

  public addInfoField(fieldName: string) {
    this.infoFields.push(fieldName);
  }


  get gridFilterURL(): string {
    return this._gridFilterURL;
  }

  set gridFilterURL(value: string) {
    this._gridFilterURL = value;
  }
  get gridAdvacedFilterURL(): string {
    return this._gridAdvacedFilterURL;
  }

  set gridAdvacedFilterURL(value: string) {
    this._gridAdvacedFilterURL = value;
  }

  public getSections() {
    if (this.sectionMap === undefined || this.sectionMap === null || this.sectionMap.size === 0) {
      this.createDefaultSections();
    }
    return this.sectionMap;
  }

  createDefaultSections() {
    let _this = this;
    this.columns.forEach(function (col: Tcolumn) {
      if (col.getField() !== 'rowNum') {
        _this.addInSection(_this.title, col.getField());
      }
    });
  }

  public getSectionsLength() {
    return Array.from(this.sectionMap.keys()).length;
  }

  public addSection(sectionName: string, fieldsArray: string[]) {
    this.sectionMap.set(sectionName, fieldsArray);
  }

  public addInSection(sectionName: string, fieldname: string) {
    if (!this.sectionMap.has(sectionName)) {
      this.sectionMap.set(sectionName, [fieldname]);
    } else {
      let old: string[] = this.sectionMap.get(sectionName);
      old.push(fieldname);
      this.sectionMap.set(sectionName, old);
    }
  }

  public getSectionKeys() {
    return this.sectionMap.keys();
  }

  getUpdateUrl(data: any) {
    let newUrl = this.updateUrl;
    if (this.updateUrl !== undefined && this.updateUrl !== null && this.updateUrl.length > 0) {
      if (this.updateUrl.charAt(this.updateUrl.length - 1) === '=' || this.updateUrl.charAt(this.updateUrl.length - 1) === '/') {
        newUrl += data[this.updateCode];
      }
    }
    return newUrl;
  }

  getHrefUrl(data) {
    let href = '';
    let _this = this;
    if (_this.hrefUrl !== undefined && _this.hrefUrl !== null && _this.hrefUrl.length > 0) {
      if(_this.defineHrefFunction !== undefined && _this.defineHrefFunction !== null) {
        href = _this.defineHrefFunction(data);
      } else {
        href = this.hrefUrl + data['uuid'];
      }
    } else {
      href = (data['_links'] !== undefined && data['_links'] !== null) ? data._links.self.href : (this.updateUrl !== undefined && this.updateUrl !== null) ? this.updateUrl + data['uuid'] : '';
    }
    return href;
  }

  public addChildTabs(tab: Tab) {
    this._childTabs.push(tab);
  }


  get deleteUrl(): string {
    return this._deleteUrl;
  }

  set deleteUrl(value: string) {
    this._deleteUrl = value;
  }


  get showCheckbox(): boolean {
    return this._showCheckbox;
  }

  set showCheckbox(value: boolean) {
    this._showCheckbox = value;
  }


  get expandGrid(): Grid {
    return this._expandGrid;
  }

  set expandGrid(value: Grid) {
    this._expandGrid = value;
  }


  get canShowExpandGrid(): Function {
    return this._canShowExpandGrid;
  }

  set canShowExpandGrid(value: Function) {
    this._canShowExpandGrid = value;
  }


  get useDefaultDuplicateFunction(): boolean {
    return this._useDefaultDuplicateFunction;
  }

  set useDefaultDuplicateFunction(value: boolean) {
    this._useDefaultDuplicateFunction = value;
  }


  get useDefaultEditFunction(): boolean {
    return this._useDefaultEditFunction;
  }

  set useDefaultEditFunction(value: boolean) {
    this._useDefaultEditFunction = value;
  }

  addColumn(column:Tcolumn) {
    this.columns.set(column.getField(),column);
  }


  get primaryKeyClickable(): boolean {
    return this._primaryKeyClickable;
  }

  set primaryKeyClickable(value: boolean) {
    this._primaryKeyClickable = value;
  }


  get selectedRows(): any[] {
    return this._selectedRows;
  }

  set selectedRows(value: any[]) {
    this._selectedRows = value;
  }


  get defineHrefFunction(): Function {
    return this._defineHrefFunction;
  }

  set defineHrefFunction(value: Function) {
    this._defineHrefFunction = value;
  }


  get auditEntityName(): string {
    return this._auditEntityName;
  }

  set auditEntityName(value: string) {
    this._auditEntityName = value;
  }


  get hiddenFieldsToShowInStandardFilters(): any[] {
    return this._hiddenFieldsToShowInStandardFilters;
  }

  set hiddenFieldsToShowInStandardFilters(value: any[]) {
    this._hiddenFieldsToShowInStandardFilters = value;
  }


  get fieldRequiresContinuesChangeDetection(): any[] {
    return this._fieldRequiresContinuesChangeDetection;
  }

  set fieldRequiresContinuesChangeDetection(value: any[]) {
    this._fieldRequiresContinuesChangeDetection = value;
  }


  get gridColumnFilterAPI() {
    return this._gridColumnFilterAPI;
  }

  set gridColumnFilterAPI(value: any) {
    this._gridColumnFilterAPI = value;
  }

  isCustomDelete() {
    return this._deleteFunction !== null && this._deleteFunction !== undefined;
  }


  get deleteFunction(): Function {
    return this._deleteFunction;
  }

  set deleteFunction(value: Function) {
    this._deleteFunction = value;
  }


  get useDefaultInlineEditFunction(): boolean {
    return this._useDefaultInlineEditFunction;
  }

  set useDefaultInlineEditFunction(value: boolean) {
    this._useDefaultInlineEditFunction = value;
  }
}
