import {CommonService} from '../../../services/common.service';
import {EventEmitter} from '@angular/core';
import {ApprovalContextMenuComponent} from '../approval-context-menu.component';
import {AccessPolicyService} from '../../../services/accesspolicy.service';

export class ApprovalFunctionParams {
    rowData: any;
    response: any;
    triggerEvent: string;
    referenceId: string;
    note: string;
    context: ApprovalContextMenuComponent;
    currentApprovalObject: { workflow: { [key: string]: any }, buttonName: string, step: { [key: string]: any }, isInApproval: boolean, apiConfig: { url: string, type: string, payloadFunction: Function } };
    commonService: CommonService;
    accessService: AccessPolicyService;
    onUpdateDone: EventEmitter<any>;
    callbackFunction: Function;
}
