import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ChartOptions} from 'chart.js';
import {CommonService} from '../../services/common.service';
import {environment} from '../../../environments/environment';
import {BaseChartDirective} from 'ng2-charts';
import {KeyValue} from '../../grid/key-value.model';
import {ExcelServiceService} from '../../services/excel-service.service';
import {Subscription} from "rxjs";
import {MasterService} from "../../masters/services/MasterService";


@Component({
    selector: 'app-widget',
    templateUrl: './widget.component.html',
    styleUrls: ['./widget.component.css']
})
export class WidgetComponent implements OnInit {
    nothingToShow: boolean = false;
    private subscriber: Subscription;

    constructor(public commonService: CommonService,
                private excelService: ExcelServiceService,
                private masterCommonService: MasterService) {
    }

    @Input() widgetProp: any;
    @Input() userDetails: any[] = [];
    @Input() loadData: any[] = [];
    @Input()  traderList: any[] = [];
    @Input() tradeTypeList: any[] = [];
    @Input() futureIndexList: any[] = [];
    @Input()  isLive: boolean = false;
    @Output() dataView = new EventEmitter();
    @Output() closeWidget = new EventEmitter();
    @ViewChild(BaseChartDirective) public chart: BaseChartDirective;
    letters = '0123456789ABCDEF'.split('');
    chartType: string = 'line';
    display: boolean = true;
    tableDialog: boolean = false;
    openPosData: any[] = [];
    m2mRealizedPnlData: any[] = [];
    revenueSplitView: any[] = [];
    openTradesView: any[] = [];
    plannedView: any[] = [];
    deliveryStartedTradesView: any[] = [];
    pnlDrawdownView: any[] = [];
    tableCols: any[];
    m2mRealizedCols: any[];
    deliveryStartedCols: any[];
    plannedCols: any[];
    pnlDrawdownCols: any[];
    basisDataset: any[] = [];
    futureDataset: any[] [];
    ptbfDataset: any[] = [];
    fixedDataset: any[] = [];
    revenueSplitCols: any;
    openTradesCols: any;
    //DropDown lists for Open Pos Graph Dropdowns
    companyList: any[] = [];
    counterpartyList: any[] = [];
    profitcenterList: any[] = [];
    commodityList: any[] = [];
    drpValue: any;
    //Dropdown for DataView Filter

    companyDataViewList:any[] = [];
    counterpartyDataViewList:any[] = [];
    profitcenterDataViewList:any[] = [];
    commodityDataViewList:any[] = [];
    tradeTypeDataViewList:any[] = [];
    loadDataList: any[];
    traderDataViewList:any[] = [{label:'All', value:''}];


    //Data View Filter Dropdown
    pcDataViewValue: any[]= [];
    cpDataViewValue: any[]= [];
    companyDataViewValue: any[]= [];
    commodityDataViewValue: any[]= [];
    traderDataViewValue: any[]= [];
    tradeTypeDataViewValue: any[]= [];

    //future position
    displayForm: boolean = false;
    futureDropdownValue: any;


    getStackChartOption(){
        return
    }

    public stackedBarChartOptions: ChartOptions = {
        responsive: true,
        // We use these empty structures as placeholders for dynamic theming.
        scales: {
            xAxes: [{
                stacked: true,
                gridLines: {display: false},
                ticks:{
                    callback: function(value:any, index, values) {
                        let monthShort = value.toString().substr(0,3);
                        let yearShort = value.toString().substr(value.toString().length - 2);
                        return monthShort + ' ' + yearShort;
                    }
                }
            }], yAxes: [{
                stacked: true,
                ticks:{
                    callback: function(value:any, index, values) {
                        if(Math.abs(parseInt(value)) >= 1000){
                            return  Math.abs(value) > 999 ? Math.sign(value)*(Math.round(Math.abs(value)/100)/10) + 'K' : Math.sign(value)*Math.abs(value)
                            // return '$' + value.toString() + 'K';
                        } else {
                            return value;
                        }
                    }
                }
            }],
        },
        legend: {
            display: true, labels: {
                usePointStyle: true,
                boxWidth: 8
            }
        },
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'end',
            }
        }
    };


    public lineChartOptions: ChartOptions = {
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }
        },
        responsive: true,
        // We use these empty structures as placeholders for dynamic theming.
        scales: {
            xAxes: [{
                gridLines: {display: false},
                ticks:{
                    callback: function(value:any, index, values) {
                        let monthShort = value.toString().substr(0,3);
                        let yearShort = value.toString().substr(value.toString().length - 2);
                        return monthShort + ' ' + yearShort;
                    }
                }
            }]
            , yAxes: [{gridLines: {display: true}}],
        },
        legend: {
            display: true, labels: {
                usePointStyle: true,
                boxWidth: 8
            }
        },
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'end',
            }
        }
    };

    public pnlLineChartOptions: ChartOptions = {
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }
        },
        responsive: true,
        // We use these empty structures as placeholders for dynamic theming.
        scales: {
            xAxes: [{
                gridLines: {display: false},
                ticks:{
                    callback: function(value:any, index, values) {
                        let monthShort = value.toString().substr(0,5);
                        let yearShort = value.toString().substr(value.toString().length - 2);
                        return monthShort + ' ' + yearShort;
                    }
                }
            }]
            , yAxes: [{gridLines: {display: true},
                ticks:{
                    callback: function(value:any, index, values) {
                        if(Math.abs(parseInt(value)) >= 1000){
                            return  Math.abs(value) > 999 ? Math.sign(value)*(Math.round(Math.abs(value)/100)/10) + 'K' : Math.sign(value)*Math.abs(value)
                        } else {
                            return value;
                        }
                    }
                }
            }],
        },
        legend: {
            display: true, labels: {
                usePointStyle: true,
                boxWidth: 8
            }
        },
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'end',
            }
        }
    };


    stackedBarChartData = [
        {
            label: 'Basis Buy',
            data: [],
            backgroundColor: 'rgba(55, 160, 225, 0.7)',
            hoverBackgroundColor: 'rgba(55, 160, 225, 0.7)',
            hoverBorderWidth: 1,
            hoverBorderColor: 'lightgrey'
        },
        {
            label: 'Future Buy',
            data: [],
            backgroundColor: 'rgba(225, 58, 55, 0.7)',
            hoverBackgroundColor: 'rgba(225, 58, 55, 0.7)',
            hoverBorderWidth: 1,
            hoverBorderColor: 'lightgrey'
        },
        {
            label: 'PTBF Buy',
            data: [],
            backgroundColor: 'rgba(225,219,10,0.7)',
            hoverBackgroundColor: 'rgba(225,219,10,0.7)',
            hoverBorderWidth: 1,
            hoverBorderColor: 'lightgrey'
        },
        {
            label: 'Fixed Buy',
            data: [],
            backgroundColor: 'rgba(69,225,215,0.7)',
            hoverBackgroundColor: 'rgba(69,225,215,0.7)',
            hoverBorderWidth: 1,
            hoverBorderColor: 'lightgrey'
        },
        {
            label: 'Basis Sell',
            data: [],
            backgroundColor: 'rgb(54,105,169)',
            hoverBackgroundColor: 'rgb(54,105,169)',
            hoverBorderWidth: 1,
            hoverBorderColor: 'lightgrey'
        },
        {
            label: 'Future Sell',
            data: [],
            backgroundColor: 'rgba(142,43,41,0.7)',
            hoverBackgroundColor: 'rgba(142,43,41,0.7)',
            hoverBorderWidth: 1,
            hoverBorderColor: 'lightgrey'
        },
        {
            label: 'PTBF Sell',
            data: [],
            backgroundColor: 'rgba(125,119,10,0.7)',
            hoverBackgroundColor: 'rgba(125,119,10,0.7)',
            hoverBorderWidth: 1,
            hoverBorderColor: 'lightgrey'
        },
        {
            label: 'Fixed Sell',
            data: [],
            backgroundColor: 'rgba(36,129,119,0.7)',
            hoverBackgroundColor: 'rgba(36,129,119,0.7)',
            hoverBorderWidth: 1,
            hoverBorderColor: 'lightgrey'
        }];

    openFutureStackedBarData = [
        {
            label: 'Long',
            data: [],
            backgroundColor: 'rgba(55, 160, 225, 0.7)',
            hoverBackgroundColor: 'rgba(55, 160, 225, 0.7)',
            hoverBorderWidth: 1,
            hoverBorderColor: 'lightgrey'
        },
        {
            label: 'Short',
            data: [],
            backgroundColor: 'rgba(225, 58, 55, 0.7)',
            hoverBackgroundColor: 'rgba(225, 58, 55, 0.7)',
            hoverBorderWidth: 1,
            hoverBorderColor: 'lightgrey'
        },
        {
            label: 'Lot Difference',
            data: [],
            backgroundColor: 'rgba(225,219,10,0.7)',
            hoverBackgroundColor: 'rgba(225,219,10,0.7)',
            hoverBorderWidth: 1,
            hoverBorderColor: 'lightgrey'
        }
    ];

    lineChartData = [
        {
            label: 'Basis',
            data: this.basisDataset,
            backgroundColor: 'rgba(55, 160, 225, 0.7)',
            borderColor: 'rgba(55, 160, 225, 0.7)',
            hoverBackgroundColor: 'rgba(55, 160, 225, 0.7)',
            pointBorderColor: 'rgba(55, 160, 225, 0.7)',
            pointHoverBorderColor: 'rgba(55, 160, 225, 0.7)',
            pointBackgroundColor: 'rgba(55, 160, 225, 0.7)',
            pointHoverBackgroundColor: 'rgba(55, 160, 225, 0.7)',
            hoverBorderWidth: 2,
            hoverBorderColor: 'lightgrey'
        },
        {
            label: 'PTBF',
            data: this.ptbfDataset,
            backgroundColor: 'rgba(225,219,10,0.7)',
            borderColor: 'rgba(225,219,10,0.7)',
            hoverBackgroundColor: 'rgba(225,219,10,0.7)',
            pointBorderColor: 'rgba(225,219,10,0.7)',
            pointHoverBorderColor: 'rgba(225,219,10,0.7)',
            pointBackgroundColor: 'rgba(225,219,10,0.7)',
            pointHoverBackgroundColor: 'rgba(225,219,10,0.7)',
            hoverBorderWidth: 2,
            hoverBorderColor: 'lightgrey'
        },
        {
            label: 'Fixed',
            data: this.fixedDataset,
            backgroundColor: 'rgba(69,225,215,0.7)',
            borderColor: 'rgba(69,225,215,0.7)',
            hoverBackgroundColor: 'rgba(69,225,215,0.7)',
            pointBorderColor: 'rgba(69,225,215,0.7)',
            pointHoverBorderColor: 'rgba(69,225,215,0.7)',
            pointBackgroundColor: 'rgba(69,225,215,0.7)',
            pointHoverBackgroundColor: 'rgba(69,225,215,0.7)',
            hoverBorderWidth: 2,
            hoverBorderColor: 'lightgrey'
        },
        {
            label: 'Future',
            data: this.fixedDataset,
            backgroundColor: 'rgba(225, 58, 55, 0.7)',
            borderColor: 'rgba(225, 58, 55, 0.7)',
            hoverBackgroundColor: 'rgba(225, 58, 55, 0.7)',
            pointBorderColor: 'rgba(225, 58, 55, 0.7)',
            pointHoverBorderColor: 'rgba(225, 58, 55, 0.7)',
            pointBackgroundColor: 'rgba(225, 58, 55, 0.7)',
            pointHoverBackgroundColor: 'rgba(225, 58, 55, 0.7)',
            hoverBorderWidth: 2,
            hoverBorderColor: 'lightgrey'
        }];

    revenueSplitData = [];
    deliveryStartedTradesData = [];
    plannedUnplannedData = [{
        label: 'Buy',
        fill: false,
        backgroundColor: '#FF6384',
        borderColor: '#FF6384', // The main line color
        data: [],
        spanGaps: true,
    },
        {
            label: 'Sell',
            fill: false,
            backgroundColor: '#297eea',
            borderColor: '#297eea', // The main line color
            data: [],
            spanGaps: true,
        }];

    pnlDrawdownData = [{
        label: 'Max Pnl',
        fill: false,
        lineTension: 0,
        backgroundColor: '#FF6384',
        borderColor: '#FF6384', // The main line color
        data: [],
        spanGaps: true,
    },
        {
            label: 'Pnl Change',
            fill: false,
            lineTension: 0,
            backgroundColor: '#297eea',
            borderColor: '#297eea', // The main line color
            data: [],
            spanGaps: true,
        },
        {
            label: 'Limit',
            fill: false,
            lineTension: 0,
            backgroundColor: '#fff314',
            borderColor: '#fff314', // The main line color
            data: [],
            spanGaps: true,
        }];


    openTradesData = [{
        label: 'Buy',
        lineTension: 0,
        fill: false,
        backgroundColor: '#FF6384',
        borderColor: '#FF6384', // The main line color
        data: [],
        spanGaps: true,
    },
        {
            label: 'Sell',
            fill: false,
            lineTension: 0,
            backgroundColor: '#297eea',
            borderColor: '#297eea', // The main line color
            data: [],
            spanGaps: true,
        }];
    months = [];



    ngOnInit(): void {
        let _this = this;

        let viewOpenPosition = function (value: any) {
            if(value['tradetype'] === 'Future') {
                return  _this.commonService.getFormatedNumber(value['openposition']) + ' ' + value['futureuom'];
            } else {
                return  _this.commonService.getFormatedNumber(value['openposition']) + ' ' + value['uom'];
            }
        };

        this.tableCols = [
            {field: 'tradeid', header: 'Obligation Id'},
            {field: 'trantype', header: 'Transaction Type', type: 'buySell'},
            {field: 'tradetype', header: 'Trade Type'},
            {field: 'openposition', header: 'Open Pos',valueFunction: viewOpenPosition},
            {field: 'deliveryenddate', header: 'Shipment Month', type: 'date'},
            {field: 'commodity', header: 'Commodity'},
            {field: 'profitcenter', header: 'Profit Center'},
            {field: 'counterparty', header: 'Counterparty'},
            {field: 'company', header: 'Company'},
            {field: 'trader', header: 'Trader'},
            {field: 'eodRunDate', header: 'Last Run Date',type: 'date'}
        ];

        let viewQuantity = function (value: any) {
            if(value['tradetype'] === 'Future') {
                return  _this.commonService.getFormatedNumber(value['quantity']) + ' ' + value['futureuom'];
            } else if(value['obligationstatus'] === 'actualized') {
                return  _this.commonService.getFormatedNumber(parseInt(value['actualloadquantity']))  + ' ' + value['quantityuom'];
            }else{
                return  _this.commonService.getFormatedNumber(value['obligationquantity']) + ' ' + value['quantityuom'];
            }
        };

        let viewRealizedPnl = function (value: any) {
            if(value['realizedpnl'] === 0) {
                return  _this.commonService.getFormatedNumber(value['realizedpnl'])
            }  else {
                return  _this.commonService.getFormatedNumber(value['realizedpnl']) + ' ' + value['currency'];
            }
        };

        let viewM2mPnl = function (value: any) {
            if(value['m2mpnl'] === 0) {
                return  _this.commonService.getFormatedNumber(value['m2mpnl'])
            }  else {
                return  _this.commonService.getFormatedNumber(value['m2mpnl']) + ' ' + value['currency'];
            }
        };

        this.m2mRealizedCols = [
            {field: 'tradeid', header: 'Obligation Id'},
            {field: 'trantype', header: 'Transaction Type', type: 'buySell'},
            {field: 'tradetype', header: 'Trade Type'},
            {field: 'obligationquantity', header: 'Quantity',valueFunction: viewQuantity},
            {field: 'realizeddate', header: 'Realized Date', type: 'date'},
            {field: 'realizedpnl', header: 'Realized PnL',valueFunction: viewRealizedPnl},
            {field: 'realizedposition', header: 'Realized Position'},
            {field: 'm2mpnl', header: 'M2M PnL',valueFunction: viewM2mPnl},
            {field: 'rundate', header: 'Run Date',type:'date'},
            {field: 'commodity', header: 'Commodity'},
            {field: 'profitcenter', header: 'Profit Center'},
            {field: 'counterparty', header: 'Counterparty'},
            {field: 'company', header: 'Company'}
        ];

        this.revenueSplitCols = [
            {field: 'tradeid', header: 'Obligation Id'},
            {field: 'trantype', header: 'Transaction Type', type: 'buySell'},
            {field: 'quantity', header: 'Quantity'},
            {field: 'revenue', header: 'Revenue'},
            {field: 'commodity', header: 'Commodity'},
            {field: 'profitcenter', header: 'Profit Center'},
            {field: 'counterparty', header: 'Counterparty'},
            {field: 'company', header: 'Company'}
        ];

        this.openTradesCols = [
            {field: 'tradeid', header: 'Obligation Id'},
            {field: 'trantype', header: 'Transaction Type', type: 'buySell'},
            {field: 'quantity', header: 'Quantity'},
            {field: 'commodity', header: 'Commodity'},
            {field: 'profitcenter', header: 'Profit Center'},
            {field: 'counterparty', header: 'Counterparty'},
            {field: 'company', header: 'Company'}
        ];

        this.deliveryStartedCols = [
            {field: 'tradeid', header: 'Trade Obligation Id'},
            {field: 'trantype', header: 'Transaction Type', type: 'buySell'},
            {field: 'quantity', header: 'Quantity'},
            {field: 'shipmentmonth', header: 'Shipment Month'},
            {field: 'commodity', header: 'Commodity'},
            {field: 'profitcenter', header: 'Profit Center'},
            {field: 'counterparty', header: 'Counterparty'},
            {field: 'company', header: 'Company'}
        ];
        this.plannedCols = this.deliveryStartedCols;

        let viewLimitFunction = function (value: any) {
            return _this.commonService.getFormatedNumber(value['limit']) + ' ' + 'MT';
        };
        let viewMaxPnlFunction = function (value: any) {
            return _this.commonService.getFormatedNumber(value['maxPnl']);
        };
        let viewtodaysPnlFunction = function (value: any) {
            return _this.commonService.getFormatedNumber(value['todaysPnl']);
        };
        let viewpnlChangeFunction = function (value: any) {
            return _this.commonService.getFormatedNumber(value['pnlChange']);
        };

        this.pnlDrawdownCols = [
            {field: 'eodvaluedate', header: 'Eod Value Date'},
            {field: 'traderName', header: 'Trade Name'},
            {field: 'profitcenter', header: 'Profit Center'},
            {field: 'tradeType', header: 'Trade Type'},
            {field: 'limit', header: 'Limit', valueFunction: viewLimitFunction},
            {field: 'maxPnl', header: 'Max Pnl',valueFunction: viewMaxPnlFunction},
            {field: 'maxPnlDate', header: 'Max Pnl Date'},
            {field: 'todaysPnl', header: 'Todays Pnl',valueFunction: viewtodaysPnlFunction},
            {field: 'pnlChange', header: 'Pnl Change',valueFunction: viewpnlChangeFunction}
        ]
    };

    getRowValue(rowData, col: any) {
        if (col.valueFunction !== undefined && col.valueFunction !== null) {
            return this.commonService.runFunction(rowData, col.valueFunction);
        }
        if (col.type !== undefined && col.type === 'date') {
            return this.commonService.getFormattedDateTime(rowData[col.field], environment.dateFormat);
        }
        return (rowData[col.field] === undefined || rowData[col.field] === null || rowData[col.field].length === 0) ? '-' : rowData[col.field];
    }

    getStyle(value) {
        return value >= 0?'color:black':'color:red'
    }


    closeTableDialog() {
        this.tableDialog = false;
        this.dataView.emit(this.tableDialog);
        this.pcDataViewValue = [];
        this.companyDataViewValue = [];
        this.commodityDataViewValue = [];
        this.cpDataViewValue = [];
        this.traderDataViewValue = [];
    }

    isNotLast(index: number) {
        return (index < this.tableCols.length - 1);
    }

    getGraphData() {
        let userDetailsTest = {};
        let pnlDetails = {};
        if (!this.userDetails['error'] && this.userDetails.length !== 0) {
            userDetailsTest = {                       //Hardcoded for testing purpose, Later needed to get it from UserDetails Table.
                // "company":this.getArrayFromUserData(this.userDetails['Company']),
                'company': this.getArrayFromUserData(this.userDetails['Company']),
                'counterparty': this.getArrayFromUserData(this.userDetails['Counterparty']),
                'profitcenter': this.getArrayFromUserData(this.userDetails['Profit Center']),
                'tenantId': this.commonService.getFromStorage('tenantId'),
                'commodity': this.getArrayFromUserData(this.userDetails['Commodity'])
            };
            pnlDetails = {
                'tenantId': this.commonService.getFromStorage('tenantId'),
            }
            this.nothingToShow = false;
        } else {
            this.nothingToShow = true;
        }
        if (Object.keys(userDetailsTest).length !== 0) {
            if (this.dropDownCheck()) {
                // this.populateOpenPositionGraph();
                // this.commonService.getJSONByObject(environment.base_url + this.widgetProp['url']['graphUrl'], userDetailsTest).subscribe((data: any) => {
                //     this.months = data['months'];
                //     this.populateOpenPosStackedBarGraph(data);
                // });
                // this.populateDefaultOpenPositionGraph();
            } else if (this.widgetProp['code'] === 'm2mRealizedPnl') {
                this.populateM2MPnlLineBarGraph(userDetailsTest);
            } else if (this.widgetProp['code'] === 'revenueSplit') {
                this.populateRevenueSplitBarGraph(userDetailsTest);
            } else if (this.widgetProp['code'] === 'openTrades') {
                this.populateOpenTradesLineGraph(userDetailsTest);
            } else if (this.widgetProp['code'] === 'deliveryStartedTrades') {
                this.populateDeliveryStartedBarGraph(userDetailsTest);
            } else if (this.widgetProp['code'] === 'plannedUnplannedTrades') {
                this.populatePlannedUnplannedLineGraph(userDetailsTest);
            }else if (this.widgetProp['code'] === 'unadjustedFutureTrades') {
                this.populateUnadjustedOpenFuture(userDetailsTest);
            }
        }
        if(Object.keys(pnlDetails).length !== 0) {
            if(this.widgetProp['code'] === 'pnlDrawdown') {
                this.populatePnlDrawdownLineGraph();
            }
        }
    }

    getSorted(values: any[], months: any[]) {
        let array: any[] = [];
        let obj = {};
        months.forEach(function(month, index) {
            obj = {x:month,y:0};
            if (values !== undefined && values !== null) {
                values.forEach(function(val) {
                    if (val['x'] === month) {
                        obj['y'] += val['y'];
                    }
                });
            }
            array.push(obj);
        });
        return array;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['userDetails'] && changes['userDetails'].currentValue !== undefined && changes['userDetails'].currentValue !== null) {
            this.chartType = this.widgetProp['chartType'];
            this.getGraphData();
            this.populateDropDownList();
            this.tradeTypeDataViewList = this.tradeTypeList;
        }
        if (changes['traderList'] && changes['traderList'].currentValue !== undefined && changes['traderList'].currentValue !== null){
            let tradeDVList = [{label:'All', value:''}];
            this.traderDataViewList = [...tradeDVList, ...this.traderList];
        }
        if (changes['isLive']){
            this.chartType = this.widgetProp['chartType'];
            this.getGraphData();
            this.populateDropDownList();
        }
    }

    getTradeType(value) {
        if(value == true) {
            return value == true ? 'Buy' : 'Sell';
        } else {
            return value == 'buy' ? 'Buy' : 'Sell';
        }
    }

    getArrayFromUserData(property: any[]) {
        let array = [];
        property.forEach(data => {
            array.push(data['masterTypeValue']);
        });
        return array;
    }

    populateOpenPosStackedBarGraph(data: any) {
        this.stackedBarChartData[0]['data'] = data['BASIS'] == undefined ? [] : this.getSorted(data['BASIS']['true'], data['months']);
        this.stackedBarChartData[1]['data'] = data['FUTURE'] == undefined ? [] : this.getSorted(data['FUTURE']['true'], data['months']);
        this.stackedBarChartData[2]['data'] = data['PTBF'] == undefined ? [] : this.getSorted(data['PTBF']['true'], data['months']);
        this.stackedBarChartData[3]['data'] = data['FIXED'] == undefined ? [] : this.getSorted(data['FIXED']['true'], data['months']);

        this.stackedBarChartData[4]['data'] = data['BASIS'] == undefined ? [] : this.getSorted(data['BASIS']['false'], data['months']);
        this.stackedBarChartData[5]['data'] = data['FUTURE'] == undefined ? [] : this.getSorted(data['FUTURE']['false'], data['months']);
        this.stackedBarChartData[6]['data'] = data['PTBF'] == undefined ? [] : this.getSorted(data['PTBF']['false'], data['months']);
        this.stackedBarChartData[7]['data'] = data['FIXED'] == undefined ? [] : this.getSorted(data['FIXED']['false'], data['months']);
    }

    populateM2MPnlLineBarGraph(userDetails: any) {
        this.commonService.getJSONByObject(environment.base_url + this.widgetProp['url']['graphUrl'][0]+"?tenantId="+ this.commonService.getFromStorage('tenantId'), userDetails).subscribe((data: any) => {
            if (Object.keys(data).length !== 0){
                this.nothingToShow = false;
                this.months = data['months'];
                this.lineChartData[0]['data'] = data['Basis'] == undefined ? [] : this.getSorted(data['Basis']['true'], data['months']);
                this.lineChartData[1]['data'] = data['PTBF'] == undefined ? [] : this.getSorted(data['PTBF']['true'], data['months']);
                this.lineChartData[2]['data'] = data['Fixed'] == undefined ? [] : this.getSorted(data['Fixed']['true'], data['months']);
                this.lineChartData[3]['data'] = data['Future'] == undefined ? [] : this.getSorted(data['Future']['true'], data['months']);
            }else {
                this.nothingToShow = true;
            }
        });

    }

    populateRealizedPnlLineBarGraph(userDetails: any) {
        this.commonService.getJSONByObject(environment.base_url + this.widgetProp['url']['graphUrl'][1]+"?tenantId="+ this.commonService.getFromStorage('tenantId'), userDetails).subscribe((data: any) => {
            if (Object.keys(data).length !== 0) {
                this.nothingToShow = false;
                this.months = data['months'];
                this.lineChartData[0]['data'] = data['Basis'] == undefined ? [] : this.getSorted(data['Basis']['true'], data['months']);
                this.lineChartData[1]['data'] = data['PTBF'] == undefined ? [] : this.getSorted(data['PTBF']['true'], data['months']);
                this.lineChartData[2]['data'] = data['Fixed'] == undefined ? [] : this.getSorted(data['Fixed']['true'], data['months']);
            }else {
                this.nothingToShow = true;
            }
        });
    }

    populateRevenueSplitBarGraph(userDetails: any) {
        let color;
        let _this = this;
        this.commonService.getJSONByObject(environment.base_url + this.widgetProp['url']['graphUrl'][0]+"?tenantId="+ this.commonService.getFromStorage('tenantId'), userDetails).subscribe((data: any) => {
            _this.revenueSplitData = [];
            if (Object.keys(data).length !== 0) {
            Object.keys(data).forEach(function (prop) {
                if (prop !== undefined && prop !== null) {
                    if (prop !== 'months') {
                        color = _this.GetRandomColor();
                        _this.revenueSplitData.push({
                            label: prop,
                            data: _this.getSorted(data[prop], data['months']),
                            backgroundColor: color,
                            hoverBackgroundColor: color,
                            hoverBorderWidth: 2,
                            hoverBorderColor: 'lightgrey'
                        });
                    } else {
                        _this.months = data[prop];
                    }
                }
            });
        }else {this.nothingToShow = true;}
        });
    }

    populateOpenTradesLineGraph(userDetails: any) {
        this.commonService.getJSONByObject(environment.base_url + this.widgetProp['url']['graphUrl'][0]+"?tenantId="+ this.commonService.getFromStorage('tenantId'), userDetails).subscribe((data: any) => {
            for (let prop in data) {
                if (prop !== 'months') {
                    if (prop === 'buy') {
                        this.openTradesData[0]['data'] = this.getSorted(data[prop], data['months']);
                    }
                    if (prop === 'sell') {
                        this.openTradesData[1]['data'] = this.getSorted(data[prop], data['months']);
                    }
                } else {
                    this.months = data[prop];
                }
            }
        });
    }

    populateDeliveryStartedBarGraph(userDetails: any) {
        let color;
        let _this = this;
        this.commonService.getJSONByObject(environment.base_url + this.widgetProp['url']['graphUrl'][0]+"?tenantId="+ this.commonService.getFromStorage('tenantId'), userDetails).subscribe((data: any) => {
            _this.deliveryStartedTradesData = [];
            if (Object.keys(data).length !== 0) {
                this.nothingToShow = false;
                Object.keys(data).forEach(function(prop) {
                    if (prop !== undefined && prop !== null) {
                        if (prop !== 'months') {
                            color = _this.GetRandomColor();
                            _this.deliveryStartedTradesData.push({
                                label: prop,
                                data: _this.getSorted(data[prop], data['months']),
                                maxBarThickness: 60,
                                backgroundColor: color,
                                hoverBackgroundColor: color,
                                hoverBorderWidth: 2,
                                hoverBorderColor: 'lightgrey'
                            });
                        } else {
                            _this.months = data[prop];
                        }
                    }
                });
            }else {
                this.nothingToShow = true;
            }
        });
    }


    populatePlannedUnplannedLineGraph(userDetails: any) {
        this.commonService.getJSONByObject(environment.base_url + this.widgetProp['url']['graphUrl'][0]+"?tenantId="+ this.commonService.getFromStorage('tenantId'), userDetails).subscribe((data: any) => {
            for (let prop in data) {
                if (prop !== 'months') {
                    if (prop.toLowerCase() === 'buy') {
                        this.plannedUnplannedData[0]['data'] = this.getSorted(data[prop], data['months']);
                    }
                    if (prop.toLowerCase() === 'sell') {
                        this.plannedUnplannedData[1]['data'] = this.getSorted(data[prop], data['months']);
                    }
                } else {
                    this.months = data[prop];
                }
            }
        });
    }

    populateUnadjustedOpenFuture(userDetails: any) {
        this.commonService.getJSONByObject(environment.base_url + this.widgetProp['url']['graphUrl'][0]+"?tenantId="+ this.commonService.getFromStorage('tenantId'), userDetails).subscribe((data: any) => {
            if (data['LONG'] != undefined && data['LONG'].length === 0 && data['SHORT'] != undefined && data['SHORT'].length === 0){
                this.nothingToShow = true;
            }else {
                this.openFutureStackedBarData[0]['data'] = data['LONG'] == undefined ? [] : this.getSorted(data['LONG'], data['months']);
                this.openFutureStackedBarData[1]['data'] = data['SHORT'] == undefined ? [] : this.getSorted(data['SHORT'], data['months']);
                this.openFutureStackedBarData[2]['data'] = data['lotdifference'] == undefined ? [] :this.getSorted( data['lotdifference'], data['months']);
                this.months = data['months'];
            }
        });
    }

    populatePnlDrawdownLineGraph() {
        let pnlFilter = {
            profitcenter: '',
            commodity: '',
            trader:'',
            tradeType:''
        }
        this.commonService.post(environment.base_url + this.widgetProp['url']['dataViewUrl'][0]['url']+"?tenantId="+ this.commonService.getFromStorage('tenantId'), pnlFilter).subscribe((data: any) => {
            data.forEach((row:any) => {
                this.months.push(row['eodvaluedate'])
                this.pnlDrawdownData[0]['data'].push({x:row['eodvaluedate'],
                    y:row['maxPnl']})
                this.pnlDrawdownData[1]['data'].push({x:row['eodvaluedate'],
                    y:row['pnlChange']})
                this.pnlDrawdownData[2]['data'].push({x:row['eodvaluedate'],
                    y:row['limit']})
            })
        });
    }


    onToggleChange(value: any) {
        let userDetails = {
            'company': this.getArrayFromUserData(this.userDetails['Company']),
            'counterparty': this.getArrayFromUserData(this.userDetails['Counterparty']),
            'profitcenter': this.getArrayFromUserData(this.userDetails['Profit Center']),
            'tenantId': this.commonService.getFromStorage('tenantId'),
            'commodity': this.getArrayFromUserData(this.userDetails['Commodity'])
        };
        value ? this.populateRealizedPnlLineBarGraph(userDetails):this.populateM2MPnlLineBarGraph(userDetails);
    }

    AddDigitToColor(limit, color) {
        color += this.letters[Math.round(Math.random() * limit)];
        return color;
    }

    GetRandomColor() {
        let color = '#';
        color = this.AddDigitToColor(5, color);
        for (var i = 0; i < 5; i++) {
            color = this.AddDigitToColor(15, color);
        }
        return color;
    }

    onClickClose() {
        this.closeWidget.emit(this.widgetProp);
    }

    getDropDownList() {
        if (this.widgetProp['code'] === 'openPosByCommodity') {
            return this.commodityList;
        } else if (this.widgetProp['code'] === 'openPosByCounterparty') {
            return this.counterpartyList;
        } else if (this.widgetProp['code'] === 'openPosByProfitcenter') {
            return this.profitcenterList;
        } else if (this.widgetProp['code'] === 'openPosByCompany') {
            return this.companyList;
        } else if (this.widgetProp['code'] === 'openPosByTrader') {
            return this.traderList;
        } else if (this.widgetProp['code'] === 'unadjustedFutureTrades') {
            return this.futureIndexList;
        }
        return new KeyValue('Select', '');
    }

    dropDownCheck() {
        return this.widgetProp['code'] === 'openPosByCommodity' || this.widgetProp['code'] === 'openPosByCounterparty' ||
            this.widgetProp['code'] === 'openPosByProfitcenter' || this.widgetProp['code'] === 'openPosByCompany' ||
            this.widgetProp['code'] === 'openPosByTrader' || this.widgetProp['code'] === 'unadjustedFutureTrades';
    }

    getDropDownValues(property:any){
        let array = [{label:'All', value:''}];
        if (property !== undefined && property !== null){
            property.forEach(data => {
                array.push({label:data['masterTypeValue'], value:data['masterTypeValue']});
            });
        }
        return array;
    }
    getListFromArray(arr:any){
        let array = [{label:'All', value:''}];
        if (arr !== undefined && arr !== null){
            arr.forEach(data => {
                array.push({label:data, value:data});
            });
        }
        return array;
    }


    populateDropDownList() {
        this.companyList = this.getListFromArray(this.loadData['company']);
        this.counterpartyList = this.getListFromArray(this.loadData['counterparty']);
        this.commodityList = this.getDropDownValues(this.userDetails['Commodity']);
        this.profitcenterList = this.getListFromArray(this.loadData['profitcenter']);
        this.populateDefaultOpenPositionGraph();
    }

    onDrpValueChange(drpDownName, value) {
        //Call Graph Api here on dropdown value change
        let reqObj = {
            searchField:drpDownName, searchValue:value
        }
        this.commonService.getJSONByObject(environment.base_url + this.widgetProp['url']['graphUrl']+"?tenantId="+ this.commonService.getFromStorage('tenantId') + '&isLive=' + this.isLive, reqObj).subscribe((data: any) => {
            if (data['months'].length !== 0) {
                this.nothingToShow = false;
                this.months = data['months'];
                this.populateOpenPosStackedBarGraph(data);
            }else{
                this.nothingToShow = true;
            }
        });
        if(this.widgetProp['code'] === 'unadjustedFutureTrades') {
            this.futureDropdownValue = value;
            let futureDetailsTest = {
                searchField:drpDownName, searchValue:value
            };
            this.populateUnadjustedOpenFuture(futureDetailsTest);
        }
    }

    getDropDownName(){
        if (this.widgetProp['code'] === 'openPosByCommodity') {
            return 'COMMODITY';
        } else if (this.widgetProp['code'] === 'openPosByCounterparty') {
            return 'COUNTERPARTY';
        } else if (this.widgetProp['code'] === 'openPosByProfitcenter') {
            return 'PROFIT_CENTER';
        } else if (this.widgetProp['code'] === 'openPosByCompany') {
            return 'COMPANY';
        } else if (this.widgetProp['code'] === 'openPosByTrader') {
            return 'TRADER';
        } else if(this.widgetProp['code'] === 'unadjustedFutureTrades') {
            return 'FUTURE_INDEX';
        }
    }

    populateDefaultOpenPositionGraph() {
        let defaultPayload = {}
        if (this.traderList !== undefined && this.traderList !== null && this.traderList.length !==0) {

            if (this.widgetProp['code'] === 'openPosByCommodity') {
                defaultPayload = {searchField: 'COMMODITY', searchValue: this.commodityList[0]['value']}
                this.getDefaultOpenPosGraph(defaultPayload);
            } else if (this.widgetProp['code'] === 'openPosByCounterparty') {
                defaultPayload = {searchField: 'COUNTERPARTY', searchValue: this.counterpartyList[0]['value']}
                this.getDefaultOpenPosGraph(defaultPayload);
            } else if (this.widgetProp['code'] === 'openPosByProfitcenter') {
                defaultPayload = {searchField: 'PROFIT_CENTER', searchValue: this.profitcenterList[0]['value']}
                this.getDefaultOpenPosGraph(defaultPayload);
            } else if (this.widgetProp['code'] === 'openPosByCompany') {
                defaultPayload = {searchField: 'COMPANY', searchValue: this.companyList[0]['value']}
                this.getDefaultOpenPosGraph(defaultPayload);
            } else if (this.widgetProp['code'] === 'openPosByTrader') {
                defaultPayload = {searchField: 'TRADER', searchValue: this.traderList[0]['value']}
                this.getDefaultOpenPosGraph(defaultPayload);
            } else if(this.widgetProp['code'] === 'unadjustedFutureTrades'){
                defaultPayload = {searchField: 'FUTURE_INDEX', searchValue: this.futureIndexList[0]['value']}
                this.populateUnadjustedOpenFuture(defaultPayload);
            }
        }
    }

    getDefaultOpenPosGraph(defaultPayload){
        this.months=[];
        if(this.subscriber!==null&&this.subscriber!==undefined){this.subscriber.unsubscribe()}
        this.subscriber=this.commonService.getJSONByObject(environment.base_url + this.widgetProp['url']['graphUrl']+'?tenantId='+this.commonService.getFromStorage('tenantId')+'&isLive=' + this.isLive , defaultPayload).subscribe((data: any) => {
            if (data['months'].length !== 0) {
                this.nothingToShow = false;
                this.months = data['months'];
                this.populateOpenPosStackedBarGraph(data);
            }
            else {
                this.nothingToShow = true;
            }
        });
    }

    populateDataViewDropDownList() {
        this.companyList = this.getListFromArray(this.loadData['company']);
        this.companyDataViewList = this.getListFromArray(this.loadData['company']);
        this.counterpartyDataViewList = this.getListFromArray(this.loadData['counterparty']);
        this.commodityDataViewList = this.getDropDownValues(this.userDetails['Commodity']);
        this.profitcenterDataViewList = this.getListFromArray(this.loadData['profitcenter']);
    }

    applyDataViewFilter(filterValue) {
        this.populateDataViewDropDownList();
        let filterPayload = {
            counterparty: this.cpDataViewValue.length === 0?this.counterpartyDataViewList[0]['value']:this.cpDataViewValue,
            profitcenter: this.pcDataViewValue.length === 0?this.profitcenterDataViewList[0]['value']:this.pcDataViewValue,
            commodity: this.commodityDataViewValue.length === 0?this.commodityDataViewList[0]['value']:this.commodityDataViewValue,
            company: this.companyDataViewValue.length === 0?this.companyDataViewList[0]['value']:this.companyDataViewValue,
            trader: this.traderDataViewValue.length === 0?this.traderDataViewList[0]['value']:this.traderDataViewValue
        }
        let pnlPayload = {
            profitcenter: this.pcDataViewValue.length === 0?this.profitcenterDataViewList[0]['value']:this.pcDataViewValue,
            commodity: this.commodityDataViewValue.length === 0?this.commodityDataViewList[0]['value']:this.commodityDataViewValue,
            trader: this.traderDataViewValue.length === 0?this.traderDataViewList[0]['value']:this.traderDataViewValue,
            tradeType: this.tradeTypeDataViewValue.length === 0?this.tradeTypeDataViewList[0]['value']:this.tradeTypeDataViewValue
        }
        this.openDataViewWithFilters(filterPayload,pnlPayload);
    }

    openDefaultOpenPosDataView() {
        this.populateDataViewDropDownList();
        if (this.widgetProp['code'] === 'unadjustedFutureTrades'){
            this.routeToUnadjustedFuturesScreen();
        }else{
            let defaultFilterPayload = {
                counterparty: this.getValueFromObj(this.counterpartyDataViewList[0],'value'),
                profitcenter: this.getValueFromObj(this.profitcenterDataViewList[0],'value'),
                commodity: this.getValueFromObj(this.commodityDataViewList[0],'value'),
                company:this.getValueFromObj(this.companyDataViewList[0],'value'),
                trader:this.getValueFromObj(this.traderDataViewList[0],'value')
            }
            let defaultPnlPayload = {
                profitcenter: this.getValueFromObj(this.profitcenterDataViewList[0],'value'),
                commodity: this.getValueFromObj(this.commodityDataViewList[0],'value'),
                trader:this.getValueFromObj(this.traderDataViewList[0],'value'),
                tradeType:this.getValueFromObj(this.tradeTypeDataViewList[0],'value')
            }
            this.openDataViewWithFilters(defaultFilterPayload,defaultPnlPayload);
        }

    }

    private getValueFromObj(obj:any,key:any) {
        if(obj !== null && obj !== undefined) {
            return obj[key];
        }
        return '';
    }

    openDataViewWithFilters(filter:any,pnlFilter:any){
        this.tableDialog = true;
        this.dataView.emit(this.tableDialog);
        if (this.dropDownCheck()) {
            this.commonService.post(environment.base_url + '/api/dashboardWidget/v1/getallopenpositiondata'+"?tenantId="+ this.commonService.getFromStorage('tenantId') + '&isLive=' + this.isLive, filter).subscribe((data: any) => {
                this.openPosData = data;
            });
        }else if (this.widgetProp['code'] === 'm2mRealizedPnl') {
            this.commonService.post(environment.base_url + this.widgetProp['url']['dataViewUrl'][0]['url']+"?tenantId="+ this.commonService.getFromStorage('tenantId'), filter).subscribe((data: any) => {
                this.m2mRealizedPnlData = data;
            });
        } else if (this.widgetProp['code'] === 'revenueSplit') {
            this.commonService.post(environment.base_url + this.widgetProp['url']['dataViewUrl'][0]['url']+"?tenantId="+ this.commonService.getFromStorage('tenantId'), filter).subscribe((data: any) => {
                this.revenueSplitView = data;
            });
        } else if (this.widgetProp['code'] === 'openTrades') {
            this.commonService.post(environment.base_url + this.widgetProp['url']['dataViewUrl'][0]['url']+"?tenantId="+ this.commonService.getFromStorage('tenantId'), filter).subscribe((data: any) => {
                this.openTradesView = data;
            });
        } else if (this.widgetProp['code'] === 'deliveryStartedTrades') {
            this.commonService.post(environment.base_url + this.widgetProp['url']['dataViewUrl'][0]['url']+"?tenantId="+ this.commonService.getFromStorage('tenantId'), filter).subscribe((data: any) => {
                this.deliveryStartedTradesView = data;
            });
        } else if (this.widgetProp['code'] === 'plannedUnplannedTrades') {
            this.commonService.post(environment.base_url + this.widgetProp['url']['dataViewUrl'][0]['url']+"?tenantId="+ this.commonService.getFromStorage('tenantId'), filter).subscribe((data: any) => {
                this.plannedView = data;
            });
        } else if (this.widgetProp['code'] === 'pnlDrawdown') {
            this.commonService.post(environment.base_url + this.widgetProp['url']['dataViewUrl'][0]['url']+"?tenantId="+ this.commonService.getFromStorage('tenantId'), pnlFilter).subscribe((data: any) => {
                this.pnlDrawdownView = data;
            });
        }
    }


    getDeliveryEndDate(rowDatum) {
        return this.commonService.getFormattedDate(rowDatum, environment.dateFormat);
    }

    exportExcel() {
        if (this.dropDownCheck()) {
            this.excelService.exportDashboardDataView(this.tableCols, this.openPosData, 'Open_Position', 'Open Position')
        }else if (this.widgetProp['code'] === 'm2mRealizedPnl') {
            this.excelService.exportDashboardDataView(this.m2mRealizedCols, this.m2mRealizedPnlData, 'MTM_Realized_Pnl', 'MTM_RealizedPnl')
        } else if (this.widgetProp['code'] === 'revenueSplit') {
            this.excelService.exportDashboardDataView(this.revenueSplitCols, this.revenueSplitView, 'Revenue_split', 'Revenue Split')
        } else if (this.widgetProp['code'] === 'openTrades') {
            this.excelService.exportDashboardDataView(this.openTradesCols, this.openTradesView, 'Open_Trades', 'Open Trades')
        } else if (this.widgetProp['code'] === 'deliveryStartedTrades') {
            this.excelService.exportDashboardDataView(this.deliveryStartedCols, this.deliveryStartedTradesView, 'Delivery_Started_Trades', 'Deliv Started')
        } else if (this.widgetProp['code'] === 'plannedUnplannedTrades') {
            this.excelService.exportDashboardDataView(this.plannedCols, this.plannedView, 'Planned_Trades', 'Planned Trades')
        } else if (this.widgetProp['code'] === 'pnlDrawdown') {
            this.excelService.exportDashboardDataView(this.pnlDrawdownCols, this.pnlDrawdownView,'Pnl_Drawdown','Pnl Drawdown')
        }
    }

    routeToUnadjustedFuturesScreen() {
        this.displayForm = true;
    }

    onCloseForm() {
        this.displayForm = false;
    }

}
