import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Tcolumn} from "../../grid/tcolumn.model";
import {Grid} from "../../grid/grid.model";
import {CommonService} from "../../services/common.service";
import {ActivatedRoute} from "@angular/router";
import {MasterService} from "../../masters/services/MasterService";
import {environment} from "../../../environments/environment";
import {AbstractControl, FormControl} from "@angular/forms";
import {KeyValue} from "../../grid/key-value.model";
import {messages} from "../../services/common/messages";
import {MessageService, SortEvent} from "primeng";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-future-commodity-price',
  templateUrl: './future-commodity-price.component.html',
  styleUrls: ['./future-commodity-price.component.css']
})
export class FutureCommodityPriceComponent implements OnInit {

  columns: Map<string, Tcolumn>;
  grid: Grid;
  refreshGrid: boolean = false;
  @ViewChild('future') eod: ElementRef;
  //SIDEBAR
  display: boolean = false;
  futureHeight: string = '';
  futureIndex: string = '';
  futureIndexList: any[] = [];
  date: string = '';
  ticker: string = '';
  tickerList: any[] = [];
  @Input() showTooltipOnValidationError: boolean = false;
  @Input() formControlAIO: FormControl;
  @Input() field: Tcolumn;
  @Input() listOfValues: KeyValue[] = [];
  @Input() readOnly: boolean = false;
  list: any;
  importingFromForm: boolean = false;
  //NO DATABOX
  noDataBoxLeft: any;
  @Input() showFetching: boolean = false;
  isError: boolean = false;
  loadMessage: string = 'Loading ..';
  @Input() noDataLabel: string = 'No Data Available';
  //import
  displayImport: boolean = false;
  importTableName: string = 'FutureCommodityPrice';
  parentKeyValue: any;
  gridDisplay: boolean = false;
  gridColumns: Tcolumn[] = [];
  values: any[] = [];
  refreshFormGroup: boolean = false;
  file: File;
  toolbarConfig: any = {
    add: true,
    edit: false,
    copy: false,
    refresh: true,
    export: true,
    exportAll: true,
    import: true,
    audit: true,
    print: true,
    prefrences: true
  };
  refreshAddNewRow: boolean = false;
  newRows: any[] = [];
  sortFunction: Function;

  constructor(private masterCommonService: MasterService, public commonService: CommonService, private router: ActivatedRoute, private messageService: MessageService,) {
// this.commonService.getMyRoles();
    this.commonService.getFromStorage('policy');  }

  ngOnInit(): void {
    this.getColumns();
    this.grid = new Grid(this.columns, true, true, true, true, '', '/api/futurecommodityprice/v1/getallcommoditypricebytenantid?tenantId='+this.commonService.getFromStorage('tenantId'), '', 'Future Commodity Price', undefined, undefined);
    this.gridColumns = [...this.masterCommonService.getGridField(this.grid)];
    this.grid.setUpdateUrl(environment.base_url + '/api/futurecommodityprice/v1/update?tenantId='+this.commonService.getFromStorage('tenantId'));
    this.grid.iconName = 'priceFixing';
    this.grid.openDefaultInputForm = false;
    this.grid.setGridConfiguration(true, true, false, false);
    let _this = this;
    this.sortFunction = function (event:SortEvent) {
      event.data.sort((data1, data2) => {
        let field = event.multiSortMeta[0].field;
        let order = event.multiSortMeta[0].order;
        let value1 = data1[field];
        let value2 = data2[field];
        if(field === 'ticker') {
          value1 = new Date(_this.masterCommonService.convertTickerToDate(value1));
          value2 = new Date(_this.masterCommonService.convertTickerToDate(value2));
        }
        let result = null;
        if (value1 == null && value2 != null) {
          result = -1;
        } else if (value1 != null && value2 == null) {
          result = 1;
        } else if (value1 == null && value2 == null){
          result = 0;
        } else if (typeof value1 === 'string' && typeof value2 === 'string') {
          result = value1.localeCompare(value2);
        } else {
          result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (order * result);
      });
    };
  }

  getColumns() {
    this.columns = new Map();
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('name', new Tcolumn('name', 'Price Index', 'LB', 1, true, undefined, true, '', 'B', undefined, undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('ticker', new Tcolumn('ticker', 'Ticker', 'T', 2, true, undefined, true, '', 'B', undefined, undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('futureCommodityPriceDate', new Tcolumn('futureCommodityPriceDate', 'Price Date', 'D', 3, true, undefined, true, '', 'B', undefined, undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('settlementPrice', new Tcolumn('settlementPrice', 'Settlement Price', 'N', 4, true, undefined, true, '', 'B'));
    this.columns.set('pxOpen', new Tcolumn('pxOpen', 'Open', 'N', 5, true, undefined, true, '', 'B'));
    this.columns.set('pxHigh', new Tcolumn('pxHigh', 'High', 'N', 6, true, undefined, true, '', 'B'));
    this.columns.set('pxLow', new Tcolumn('pxLow', 'Low', 'N', 7, true, undefined, true, '', 'B'));
  }

  //close the sidebar
  closeFuture() {
    this.display = false;
    this.gridDisplay = false;
  }

  //No data add button for display of sidebar
  onClickAdd(value: any) {
    this.futureIndex = '';
    this.date = '';
    this.ticker = '';
    this.refreshGrid = false;
    this.values = [];
    this.gridDisplay = false;
    this.display = true;
    this.showFutureList();
  }

  //load the future price and ticker
  showFutureList() {
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url + '/api/priceIndex/v1/getIndexList?indexTypeList=futures&tenantId='+this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
      _this.futureIndexList = _this.commonService.addListOTT(next);
    });
  }

  getTickerList(futureindex) {
    let _this = this;
    _this.tickerList = [];
    _this.ticker = '';
    this.commonService.getJSONByURL(environment.base_url + '/api/priceIndex/v1/getTickerList?name=' + futureindex+'&tenantId='+this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
      if(next.length > 0) {
        _this.tickerList = _this.commonService.addListOTT(next, 'All', 'all');
        _this.ticker = 'all';
      }
    })
  }

  //Add the Price Grid to add open, low, high
  addPrice() {
    if (this.values === undefined || this.values === null || this.values.length === 0) {
      this.values = [];
    }
    this.gridDisplay = true;
    this.refreshAddNewRow = false;
    this.refreshFormGroup = false;
    let _this = this;
    let futurepayload = {
      name: this.futureIndex,
      ticker: this.ticker,
      priceDate: new Date(this.date+'UTC')
    };
    this.commonService.getJSONByObject(environment.base_url + '/api/futurecommodityprice/v1/getdatatemplate?tenantId='+this.commonService.getFromStorage('tenantId'), futurepayload).subscribe((next: any[]) => {
      _this.newRows = next;
      _this.refreshFormGroup = true;
      _this.refreshAddNewRow = true;
    })
  }

  //save the details of the p-table added price
  saveFuture(value: any[], isUpdate: boolean = false) {
    let _this = this;
    value.forEach(function (row:any) {
      row['futureCommodityPriceDate'] = _this.commonService.convertDatetoUTC(row['futureCommodityPriceDate']);
    });
    let pricepayloadObj = {
      name: this.futureIndex,
      priceDate: _this.commonService.convertDatetoUTC(this.date),
      ticker: this.ticker,
      tenantId: this.commonService.getFromStorage('tenantId'),
      createdBy: this.commonService.getFromStorage('userName'),
      updatedBy: this.commonService.getFromStorage('userName'),
      commodityPriceList: value
    };
    this.commonService.post(environment.base_url + '/api/futurecommodityprice/v1/saveMarketPrice?tenantId='+this.commonService.getFromStorage('tenantId'), pricepayloadObj).subscribe(next => {
      this.display = false;
      this.refreshGrid = true;
      this.showToast('Future Price saved successfully');
    }, (error: HttpErrorResponse) => {
      this.messageService.add({severity: 'error', summary: this.commonService.getHttpErrorMessage(error,undefined)});
    });
  }

  onSave(value: any) {
    this.commonService.post(environment.base_url + '/api/futurecommodityprice/v1/update?tenantId='+this.commonService.getFromStorage('tenantId'),value['data']).subscribe(next => {
      this.showToast('Future Price updated successfully');
    }, (error: HttpErrorResponse) => {
      this.messageService.add({severity: 'error', summary: this.commonService.getHttpErrorMessage(error,undefined)});
    });
  }

  showToast(msg, severity: string = 'info') {
    this.messageService.add({
      severity: severity,
      summary: msg
    });
  }

  calculateLeft() {
    this.noDataBoxLeft = ((window['visualViewport'].width - 500) / 2) + 'px';
  }

  getTooltipMessage(control: AbstractControl, col) {
    return this.showTooltipOnValidationError ? this.commonService.getValidationErrorMessage(control, col.getHeader()) : '';
  }

  //Import the future Commodity Price
  onImportClick(importingFromForm: boolean = false) {
    this.display = false;
    this.importingFromForm = importingFromForm;
    this.displayImport = true;
    this.parentKeyValue = this.grid.getDataURL().substr(this.grid.getDataURL().lastIndexOf('=') + 1);
  }

  //close import
  closeImport() {
    this.displayImport = false;
    if (this.importingFromForm) {
      this.display = true;
      this.gridDisplay = true;
    }
  }

  importDone(success: boolean) {
    this.displayImport = false;
    if (success) {
      this.messageService.add({
        severity: 'info', summary: messages.updated['summary'],
        detail: messages.importCompleted['message'],
        life: 10000
      });

    }
  }


  onChangeFutureIndex(value: any) {
    this.getTickerList(value);
  }

  getDateFormat() {
    return environment.dateFormat.toUpperCase();
  }

  onNewRowAddComplete() {
    this.refreshAddNewRow = false;
    this.newRows = [];
  }

  onRefreshFromGroupComplete() {
    this.refreshFormGroup = false;
  }

  onImport(value: any) {
    this.display = true;
    this.displayImport = false;
    this.file = value['file'];
  }

  onImportComplete() {
    this.file = undefined;
  }
}
