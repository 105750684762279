import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Grid} from "../../grid/grid.model";
import {environment} from "../../../environments/environment";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {CommonService} from "../../services/common.service";
import {MasterService} from "../../masters/services/MasterService";
import {SelectItem, Table} from "primeng";
import {Filter} from "../../grid/preference/filter/filter.model";
import {FilterService} from "../../grid/preference/filter/filter.service";
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";

@Component({
  selector: 'app-open-future-dashboard',
  templateUrl: './open-future-dashboard.component.html',
  styleUrls: ['./open-future-dashboard.component.css']
})
export class OpenFutureDashboardComponent implements OnInit {
  @Input('grid') grid: Grid;
  onSave = new EventEmitter();
  showFilter: boolean = true;
  showColumns: boolean = true;
  gridFilters: Filter[] = [];
  filterForm: FormGroup;
  globalFilterValue: string = '';
  tabValue: string;
  planData: any[] = [];
  summaryData: any[] = [];
  tabOptions: SelectItem[] = [];
  buyTotal: number = 0;
  storageTotal: number = 0;
  buyValue: any[] = [];
  storageValue: any[];
  balanceFunc: any;
  balanceRightFunc: any;
  inventoryHeight: string = '';
  selectedBuyRows: any[] = [];
  selectedInventoryRows: any[] = [];
  buyCols: any[];
  inventoryCols: any[];
  planInventoryCols: any[];
  expandPlannedCols: any[];
  display: boolean = false;
  inventorydisplay: boolean = false;
  matchDisplay: boolean = false;
  selectedAlgo: string = '';
  errorMessage: any[] = [];
  selectedPlans: any[] = [];
  oblRow: any;
  rowData: any = {};
  inventoryRow: any;
  sidebarPosition: any = 'right';
  tradeId: any = '';
  storageId: any = '';

  addedFilter: any[] = [];
  refreshFilter: boolean = false;
  planningGridHeight: string = '';
  reset: boolean = false;
  //draw
  sellTotal: number = 0;
  stockTotal: number = 0;
  stockValue: any[];
  sellValue: any[] = [];
  selectedStockedRows: any[] = [];
  sellCols: any[];
  stockedCols: any[];
  balanceStockFunc: any;
  childRowData: any;
  displaySideBar: boolean = false;
  gridFilter: Filter[];
  columns: any[] = [
    {field: 'tradeid', header: 'Trade Id'},
    {field: 'tradedate', header: 'Trade Date'},
    {field: 'commodity', header: 'Commodity'},
    {field: 'expirymonth', header: 'Expiry Month'},


    {field: 'profitcenter', header: 'Profit Center'},
    {field: 'futureindex', header: 'Future Index'},
    {field: 'broker', header: 'Broker'},
    {field: 'expirydate', header: 'Expiry Date'},
    {field: 'daystoexpiry', header: 'Days To Expiry'},
    {field: 'lotsize', header: 'Lot Size'},
    {field: 'price', header: 'Price'},
    {field: 'mtm', header: 'MTM'},
    {field: 'pnlchange', header: 'PnL Change'}




  ];
  headerColumns: any[];
  allBrokerList: any[] = [];
  expiryMonth: any[] = [];
  futureIndex: any[] = [];
  profitCenter: any[] = [];
  bsConfig: Partial<BsDatepickerConfig>;

  planTableHeight: string = '';
  @ViewChild('stockPlanningTable', {static: true}) stockPlanningTable: Table;
  @ViewChild('inventory') inventory: ElementRef;
  available: number = 0;
  fullyPlanned: number = 0;
  tradeInfoPos: any;


//try
  @Input() showCheckBox: boolean = true;
  @Input() showheaderCheckbox: boolean = true;
  @Input() primaryKey: string;
  //Sending the Post data (mapping obligstions to storage)


  //nodata box
  noDataBoxLeft: any = '';
  loadingMessage: string = "No Data Available";
  isRefreshing: boolean = false;
  showDateRange: boolean = false;
  selectedPreset:any= {};
  presetList: any[] = [];
  filters: any[] = [];
  loadDataList: any[];


  sc: any[] =[]

  primaryObject: any = {};
  data: any;
  private sort: any[] = [];



  constructor(private masterCommonService: MasterService, public commonService: CommonService,private fb: FormBuilder, private service: FilterService) {
  }
  @HostListener("window:resize", [])
  public onResize() {
    this.calculateHeight();
    this.calculatePlanHeight(this.stockPlanningTable);
  }

  ngOnInit(): void {
    let _this = this;
    let pnlFormatFunc: Function = function (value){
      let html: any;
      if (value['pnlchange'] < 0) {
        html = _this.commonService.getSafeHtml('<div style="display: inline-flex">' +
            '<i class="fas fa-chevron-down" style="color:#bf0000; padding-top: 3px"></i>'+
            '<p style="color:#bf0000; padding: 0;font-size: 12px;margin: 0 0 0 10px;white-space: nowrap !important;">'+ value['pnlchange'] +'</p></div>');
      }else if(value['pnlchange'] > 0) {
        html = _this.commonService.getSafeHtml('<div style="display: inline-flex">' +
            '<i class="fas fa-chevron-up" style="color:#39a400; padding-top: 3px"></i>'+
            '<p style="color:#39a400; padding: 0;font-size: 12px;margin: 0 0 0 10px;white-space: nowrap !important;">'+ "+" +value['pnlchange'] +'</p></div>');
      } else {
        html = _this.commonService.getSafeHtml('<div style="display: inline-flex">' +
            '<p style="color:#000000; padding: 0;font-size: 12px;margin: 0 0 0 10px;white-space: nowrap !important;">'+ value['pnlchange'] +'</p></div>');
      }
      return html;
    }
    this.sc = [{field: 'profitcenter', header: 'Profit Center'},
      {field: 'futureindex', header: 'Future Index'},
      {field: 'broker', header: 'Broker'},
      {field: 'expirydate', header: 'Expiry Date', type: 'date'},
      {field: 'daystoexpiry', header: 'Days To Expiry'},
      {field: 'lotsize', header: 'Lot Size'},
      {field: 'price', header: 'Price'},
      {field: 'mtm_pnl', header: 'MTM'},
      {field: 'pnlchange', header: 'PnL Change', valueFunction: pnlFormatFunc}]
    this.getGridData();
    this.getSummary();
    this.getBrokerList();
    this.getIndexList();
    this.generateDefaultPreset();

    //BUY Column Heading
    this.buyCols = this.columns;

    //Sell Obligation Column Heading
    this.sellCols = this.columns;

  }

  filterForms=this.fb.group({
    allBrokerList:new FormControl(),
    expiryMonth:new FormControl(),
    futureIndex:new FormControl(),
    profitCenter:new FormControl(),
    daterang:new FormControl(),
  });

  toggleDateRange() {
    this.showDateRange = !this.showDateRange;
  }
  onCancel(value) {
    this.showDateRange = false;
  }

  getPresetTitle() {
    if(this.selectedPreset['title'] === 'Date Range') {
      return (this.commonService.getFormattedDate(this.selectedPreset['startDate'],'dd MMMM yyyy')+'-'+this.commonService.getFormattedDate(this.selectedPreset['endDate'],'dd MMMM yyyy'));
    }
    return this.selectedPreset['title']
  }

  refreshFutureBuyAndSell(){
    this.getGridData();
  }

  private generateDefaultPreset() {
    this.presetList.push({title:'Forward 3 months dates',startDate:new Date(),endDate:this.commonService.addMonthsInDate(new Date(),3)});
    this.presetList.push({title:'Forward 6 months dates',startDate:new Date(),endDate:this.commonService.addMonthsInDate(new Date(),6)});
    this.presetList.push({title:'Forward 1 year dates',startDate:new Date(),endDate:this.commonService.addYearsInDate(new Date(),1)});
    this.selectedPreset = this.presetList[0];
  }

  onPresetChange(preset: any) {
    this.selectedPreset = preset;
    this.getFilteredGridData();
    this.toggleDateRange();
  }

  onFilterValueChange(){
    this.getFilteredGridData();
  }
  getFilteredGridData(){
    let filterPayload: {};
    let _this = this;
    filterPayload = {
      broker: this.filterForms.controls['allBrokerList'].value,
      expiryMonth: this.filterForms.controls['expiryMonth'].value,
      futureIndex: this.filterForms.controls['futureIndex'].value,
      profitCenter: this.filterForms.controls['profitCenter'].value,
      fromDate: this.selectedPreset['startDate'],
      toDate: this.selectedPreset['endDate']
    }
    _this.buyValue = [];
    _this.sellValue = [];
    //CALL API to get filtered Data
    this.commonService.getJSONByObject(environment.base_url + '/api/unadjustedfuturetrades/v1/getunadjustedfuturefiltereddata?tenantId='+
                                  this.commonService.getFromStorage('tenantId'),filterPayload).subscribe((next:any)=>{
      next.forEach(data =>{
        if (data['trantype'] === 'LONG'){
          _this.buyValue.push(data);
        }else{
          _this.sellValue.push(data);
        }
      });
    });
  }

  //Get the Date From TimeStamp
  getDate(rowDatum: any) {
    return rowDatum.substring(0, 10);
  }

  //Go back to Dashboard
  goBack() {
    this.commonService.navigateTo('/dashboard');
  }

  getClassForHeading(index) {
    return index > 0 ? 'headerNormal' : 'noPipe';
  }


  refreshTabs() {
    this.buyValue = [];
    this.sellValue = [];
  }

  changeDisplay(value: any) {
    this.display = value;
  }


  getFilterValue($event) {
    this.globalFilterValue = event.target['value'];
  }

  planRefresh() {
    this.planData = [];
  }

  calculateHeight() {
    let offsetTop = this.inventory.nativeElement['offsetTop'];
    let offsetHeight = this.inventory.nativeElement['offsetParent']['offsetHeight'];
    this.inventoryHeight = offsetHeight - (offsetTop + 100) + 'px';
  }

  calculatePlanHeight(stockPlanningTable: Table) {
    if (stockPlanningTable !== undefined && stockPlanningTable !== null) {
      let offsetTop = stockPlanningTable.el.nativeElement['offsetTop'];
      let offsetHeight = stockPlanningTable.el.nativeElement['offsetParent']['offsetHeight'];
      this.planningGridHeight = offsetHeight - (offsetTop + 23) + 'px';
    }
  }


  cancel() {
    this.displaySideBar= false;
  }



  toggleDisplay() {
    this.service.resetPrevious();
    this.display = false;

  }


  onChange(value: any = []) {
    if (value.length == 0) {
      this.sc = value;
    }
  }

  private getBrokerList() {
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url + '/api/counterparty/v1/getcounterpartybycounterpartytypecode?' +
        'counterpartytype=' + ['Clearing Broker'] + '&tenantId=' + this.commonService.getTenantId()).subscribe((data: any) => {
      _this.allBrokerList = _this.commonService.getListFromArray(data, 'name', 'name', false, false);
    });
  }
  private getIndexList() {
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url + '/api/futureoptionindex/v1/getallfutureoptionindex?tenantId=' + this.commonService.getTenantId()).subscribe((data:any) => {
      _this.futureIndex = _this.commonService.getListFromArray(data, 'name','name',false,false);
    });
  }
  onIndexChange(index) {
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url + '/api/futureoptionperiodcalendar/v1/getfutureoptionperiodcalendarbyname?tenantId=' +
        _this.commonService.getTenantId()+ '&name='+ index['value']).subscribe((data:any) => {
      _this.expiryMonth = _this.commonService.getListFromArray(data, 'ticker','ticker',false,false);
    });
  }

  private getSummary() {
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url + '/api/unadjustedfuturetrades/v1/getunadjustedfuturesummary' + '?tenantId=' + this.commonService.getTenantId()).subscribe((summary:any) =>{
      _this.summaryData = summary;
    })
  }

  private getGridData() {
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url + '/api/unadjustedfuturetrades/v1/getallunadjustedfuturetrades' + '?tenantId=' + this.commonService.getTenantId()).subscribe((next:any) =>{
      _this.buyValue = [];
      _this.sellValue = [];
      next.forEach(data =>{
        if (data['trantype'] === 'LONG'){
          _this.buyValue.push(data);
        }else{
          _this.sellValue.push(data);
        }
      });
    })
  }
}

