import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CommonService} from '../../services/common.service';
import {MasterService} from '../services/MasterService';
import {Grid} from '../../grid/grid.model';
import {environment} from '../../../environments/environment';
import {Tcolumn} from '../../grid/tcolumn.model';
import {BreadcrumbService} from '../../Components/ctrm-breadcrumb/breadcrumb.service';
import {defaultValue} from '../../grid/defaultValue.model';
import {Validators} from '@angular/forms';
import {Router} from '@angular/router';

@Component({
  selector: 'app-approval-configuration',
  templateUrl: './approval-configuration.component.html',
  styleUrls: ['./approval-configuration.component.css']
})
export class ApprovalConfigurationComponent implements OnInit {
  loadDataList: any;
  columns: Map<string, Tcolumn>;
  grid: Grid;
  toolBarConfig:boolean=false;



  constructor(public commonService: CommonService, private masterCommonService: MasterService,
              public breadCrumbService: BreadcrumbService,
              private route: Router) { }

  ngOnInit(): void {
    this.loadDataList={};
    this.breadCrumbService.makeBreadcrumbTo(this.commonService.MASTER_MAP_KEY, '/approvalconfiguration');
    this.getColumns();
    this.grid = new Grid(this.columns, false, false, false, false, 'workflowName', '/approval-workflow/api/workflow-config', '', 'New Approval', undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, false);
    this.grid.baseUrl= environment.base_url_new;
    this.grid.openDefaultInputForm=false;
    this.grid.openSidebarOnUniqueClick=false;
  }
  public getColumns(){
    this.columns = new Map();
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('delete', new Tcolumn('delete', 'Actions', 'LB', 1, true, undefined, true, undefined, 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('workflowName', new Tcolumn('workflowName', 'WorkFlowName', 'T', 2, false, undefined, true, new defaultValue('', '', environment.primaryKeyIncludedCharacters), 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, ));
    this.columns.set('triggerEvent', new Tcolumn('triggerEvent', 'Trigger Event', 'TA', 3, true, undefined, true, '', 'B', [Validators.maxLength(200)]));
    this.columns.set('approvalSpan', new Tcolumn('approvalSpan', 'Approval Span', 'L', 5, true, this.masterCommonService.getListFromArray(this.loadDataList['location_type_ind']), true, '', 'B', [Validators.required]));
    this.columns.set('postAction', new Tcolumn('postAction', 'Post Action', 'L', 6, true, this.commonService.getTimeZoneList(), true, this.commonService.getFromStorage('timezone'), 'B', [Validators.required]));
    this.columns.set('negativePostAction', new Tcolumn('negativePostAction', 'Negative Post Action', 'L', 6, true, this.commonService.getTimeZoneList(), true, this.commonService.getFromStorage('timezone'), 'B', [Validators.required]));
    this.columns.set('isSystem', new Tcolumn('isSystem', 'Is System', 'B', 7, true, this.masterCommonService.getYesNo(true), true, false, 'B', [Validators.required]));
    this.columns.set('isActive', new Tcolumn('isActive', 'Is Active', 'B', 9, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));

  }
  onclick(){
    this.route.navigate(['/approvalupdate']);
  }

  onUniqueClick(value: any) {
    this.route.navigate(['/approverworkflow'],{ queryParams: { value:value.rowData.uuid}});
  }

  onRowEditInit(rowdata:any) {
    if(!rowdata['isSystem']) {
      this.route.navigate(['/approvalupdate'],{ queryParams: { value:rowdata.uuid}});
    }
  }

}
