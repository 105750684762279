<div class="d-flex flex-column p-1">
  <div *ngIf="showSectionTop" class="sectionCardTop d-flex flex-column">
    <div class="breadcrumbPane">
      <ctrm-breadcrumb [collapsabel]="false"
                       [items]="breadCrumbService.getBreadCrumb()"
                       [showIcon]="false"></ctrm-breadcrumb>
    </div>
    <div class="actionPane d-flex">
      <div class="flex-fill d-flex">
        <div class="screenDetails align-items-center">
          <i (click)="changeRouteToBack()" class="fas fa-chevron-left backIcon"></i>
          <p class="m-0">{{heading}}</p>
        </div>
        <ctrm-autocomplete
                [endCharacter]="'Enter'"
                [placeholder]="'Global search'"
                [placeholder]="'Search '+heading"
                [startCharacter]="'@'">
        </ctrm-autocomplete>
      </div>
      <div (click)='opendailog()' *ngIf="screentype !== null" class="audittrail align-content-end">
        <p class="audittrailtnText">Audit Trail</p>
      </div>
    </div>
  </div>
  <div *ngIf="showSectionInfo" class="d-flex flex-column" id="sectionDiv">
    <div class="sectionInfo d-flex flex-column">
      <div class="header">
        <p>{{grid.getTitle()}} Details</p>
        <ctrm-button (onClick)="toggleModal()" *ngIf="secondLevelEdit" [disabled]="!editEnabled" [icon]="getEditIcon()"
                     [label]="getEditLabel()"></ctrm-button>
      </div>
      <div (inViewportAction)="calculateInfoHeight()" *ngIf="canShow()" [id]="'infoBox'"
           [style]="{maxHeight:infoHeight}"
           class="completeInfo style-4" inViewport>
        <div *ngIf="hasSections(grid)" class="ui-g">
          <div *ngFor="let section of grid.getSectionKeys()" class="sectionBox">
            <div class="sectionTitle">{{section}}</div>
            <div class="sectionContent">
              <div *ngFor="let field of grid.getSections().get(section);let i= index" class="sectonFieldValue">
                <div [hidden]="!(i<=1 || viewMore)" class="infoSection">
                  <div class="infoHeading">{{grid.getColumn().get(field).getHeader()}}</div>
                  <div [innerHTML]="getRowValue(grid.getColumn().get(field),currentRow)" class="infoContent"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!hasSections(grid)" class="ui-g">
          <div *ngFor="let field of masterService.getGridField(grid);let i = index">
            <div *ngIf="i<numberOfColumns || viewMore" class="infoCard">
              <div class="infoHeading">{{field.getHeader()}}</div>
              <div [innerHTML]="getRowValue(field,currentRow)" class="infoContent"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div (click)="toggleViewMore()" *ngIf="masterService.getGridField(grid).length > 5"
         class="card cardShape align-self-center">
      <p class="p-0 m-0 mx-2">{{getExpandLabel()}}</p>
    </div>
  </div>
  <div *ngIf="!onModal" class="tabBox">
    <div class="elementText">Total {{totalElements}} </div>
    <p-tabView (activeIndexChange)="onIndexChange($event)" [(activeIndex)]="currentIndex">
      <p-tabPanel *ngFor="let tab of tabsNew;let i=index" header="{{isValidTab(tab)?tab.getLabel():''}}">
        <div *ngIf="tab.getTabTemplate() !== undefined && tab.getTabTemplate() !== null">
          <ng-container [ngTemplateOutletContext]="{rowData:currentRow}"
                        [ngTemplateOutlet]="tab.getTabTemplate()"></ng-container>
        </div>
        <div *ngIf="currentIndex === i && isValidTab(tab) && !(tab.getTabTemplate() !== undefined && tab.getTabTemplate() !== null)">
          <div *ngIf="!tab.openInForm">
            <ctrm-grid (addButtonValue)="addbtnValue($event)"
                       (onButtonClick)="onBttonClick($event)"
                       [defaultEdit]="tab.getGrid().useDefaultEditFunction"
                       (onFormOpenDone)="onFormOpenDoneFn()"
                       (onRefreshComplete)="onCompleteTabRefresh(tab.getLabel())"
                       (onRowDataDelete)="onDelete($event,tab.getLabel())"
                       (onRowDataSave)="onSave($event,tab.getLabel())"
                       (selectedRows)="onRowSelect($event,tab.getLabel())"
                       (onClickAdd)="onAddClick($event)"
                       [updatePayloadFunction]="tab.getGrid().actionFunc.preSaveFunc"
                       (onClickEdit)="onEditClick($event,tab)"
                       (onRowDataDelete)="onDelete($event,tab.getLabel())"
                       (totalNumberElements)="totalPage($event,i)"
                       [autoCalculateHeight]="autoCalculateHeight"
                       [extraObjectToSave]="getExtraObject(tab)"
                       [grid]="tab.getGrid()"
                       [isTabGrid]="true"
                       [deleteOnDelete]="!tab.getGrid().isCustomDelete()"
                       (onDelete)="callDelete($event,tab)"
                       [openFormForNewEntry]="openFormForNewEntry"
                       [parentRowValue]="currentRow"
                       [parentUuidFieldName]="tab.idFieldValueName"
                       [performAction]="currentIndex===i?true:false"
                       [refreshGrid]="refreshGrid || (refreshTabs === tab.getLabel())"
                       [showBreadcrumb]="showBreadcrumb"
                       [showFormOnModal]="tab.formOnModal"
                       [showSectionTop]="false"
                       [showTitle]="showTitle"
                       [updateBreadcrumb]="false"></ctrm-grid>
          </div>
          <div *ngIf="tab.openInForm">
            <ctrm-input-form [editUrl]="tab.formFetchUrl"
                             [modalState]="true" [noOfColumns]="3" [showFooter]="false"
                             [showOnModal]="false"></ctrm-input-form>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
  <div *ngIf="onModal">
    <p-dialog (onHide)="cancelModal()" [(visible)]="modalState" [baseZIndex]="25"
              [closeOnEscape]="true" [draggable]="false" [maximizable]="true" [modal]="true"
              [style]="{ width: '80%',minHeight: '79vh'}" appendTo="body">
      <p-header [style.color]="'#009CDF'">{{heading}}</p-header>
      <div class="content">
        <div class="padding">
          <p-tabView (activeIndexChange)="onIndexChange($event)">
            <p-tabPanel *ngFor="let tab of tabsNew;index as i" [selected]="i===0?true:false"
                        header="{{tab.getLabel()}}">
              <ctrm-grid (addButtonValue)="addbtnValue($event)"
                         (onButtonClick)="onBttonClick($event)"
                         (onRowDataDelete)="onDelete($event,tab.getLabel())"
                         (onRowDataSave)="onSave($event,tab.getLabel())"
                         (selectedRows)="onRowSelect($event,tab.getLabel())"
                         [extraObjectToSave]="getExtraObject(tab)"
                         [grid]="tab.getGrid()"
                         [isTabGrid]="true"
                         [parentRowValue]="currentRow"
                         [parentUuidFieldName]="tab.idFieldValueName"
                         [refreshGrid]="refreshGrid"
                         [showBreadcrumb]="showBreadcrumb"
                         [showFormOnModal]="true"
                         [showTitle]="showTitle"
                         [updateBreadcrumb]="false"></ctrm-grid>
            </p-tabPanel>
          </p-tabView>
        </div>
      </div>
    </p-dialog>
  </div>
</div>
<ctrm-input-form (OnApply)="OnFormClose($event,this.addButtonValue)"
                 (OnCancel)="OnCancel()"
                 (onButtonClick)="onClick($event)"
                 *ngIf="editModalState"
                 [closeAfterSave]="true"
                 [columnsMap]="grid.getColumn()"
                 [editUrl]="grid.getHrefUrl(currentRow)"
                 [extraButtons]="grid.getExtraButtons()"
                 [gridConfig]="grid.getGridConfig()"
                 [gridTitle]="grid.getTitle()"
                 [heading]="grid.getTitle()"
                 [noOfColumns]="3"
                 [readOnly]="true"
                 [saveUrl]="grid.getSaveUrl()"
                 [sections]="grid.getSections()"
                 [showExtraButtons]="grid.showExtraButtons" [showOnModal]="true"
                 [uniqueKey]="grid.getPrimaryKey()"
                 [updateUrl]="grid.getUpdateUrl(currentRow)"
                 [updateUrlType]="grid.updateUrlType"
                 [updateButtonDisableFunction]="grid.formUpdateButtonDisableFuncton">
</ctrm-input-form>
<app-ctrm-audittrail *ngIf="display"
                     [heading]="heading"
                     [entityId]="urlIdFieldValue"
                     [entityName]="screentype"
                     [display]="true"
                     (onclose)="closeaudittrail()"  >
</app-ctrm-audittrail>
