import {Component, OnInit} from '@angular/core';
import {Tcolumn} from '../../grid/tcolumn.model';
import {Grid} from '../../grid/grid.model';
import {KeyValue} from '../../grid/key-value.model';
import {Validators} from '@angular/forms';
import {CommonService} from '../../services/common.service';
import {MasterService} from '../services/MasterService';
import {defaultValue} from "../../grid/defaultValue.model";
import {filter} from '../services/filter.model';
import {environment} from '../../../environments/environment';
import {entities} from "../../services/common/entities";

@Component({
  selector: 'app-contractbook',
  templateUrl: './contractbook.component.html',
  styleUrls: []
})
export class ContractbookComponent implements OnInit {
  columns: Map<string, Tcolumn>;
  grid: Grid;
  groupList: KeyValue[] = [];

  constructor(private commonService: CommonService, private masterCommonService: MasterService) {
  }

  ngOnInit() {
    this.getColumns();
    this.grid = new Grid(this.columns, true, true, true, true, 'contractBookCode', 'contractBookMaster?sort=id,desc', '', 'Contract Book');
  }

  public getColumns() {
    this.columns = new Map();
    let filter1 = new filter(environment.base_url + 'filter', entities.company);
    filter1.addCriteria('companyTypeCode', "Internal Company");
    let filterForProfitCenter = new filter(environment.base_url + 'filter', entities.profitCenter);
    filterForProfitCenter.addCriteria('profitCenterStatus', true);
    let filterForCounterParty = new filter(environment.base_url + 'filter', entities.company);
    filterForCounterParty.addCriteria('companyTypeCode', "Counterparty");
    let filterForCommodity = new filter(environment.base_url + 'filter', entities.commodity);
    filterForCommodity.addCriteria('commodityStatus', true);
    let filterUserMaster = new filter(environment.base_url + 'filter', entities.userMaster);
    filterUserMaster.addCriteria('userMasterStatus', true);
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('contractBookCode', new Tcolumn('contractBookCode', 'Contract Book Code', 'T', 1, false, undefined, true, new defaultValue('', '', environment.primaryKeyIncludedCharacters), 'B', [Validators.required, Validators.maxLength(10)]));
    this.columns.set('contractBookName', new Tcolumn('contractBookName', 'Contract Book Name', 'T', 2, true, undefined, true, new defaultValue('', '', environment.primaryKeyIncludedCharacters), 'B', [Validators.required, Validators.maxLength(50)], undefined, undefined, undefined, undefined, [], undefined, undefined, true));
    this.columns.set('contractBookCurrency', new Tcolumn('contractBookCurrency', 'Currency', 'L', 3, true, this.commonService.getCurrencyList(), true, '', 'B', [Validators.required, Validators.maxLength(256)]));
    this.columns.set('timeZone', new Tcolumn('timeZone', 'Time Zone', 'L', 4, true, this.commonService.getTimeZoneList(), true, '', 'B', [Validators.required, Validators.maxLength(100)]));
    this.columns.set('assignedLegalEntity', new Tcolumn('assignedLegalEntity', 'Assigned Legal Entity', 'L', 5, true, this.masterCommonService.getListFromObject(filter1, 'companyName', 'companyCode')));
    this.columns.set('assignedProfitCenter', new Tcolumn('assignedProfitCenter', 'Assigned Profit Center', 'L', 6, true, this.masterCommonService.getListFromObject(filterForProfitCenter, 'profitCenterName', 'profitCenterCode')));
    this.columns.set('assignedCounterParty', new Tcolumn('assignedCounterParty', 'Assigned Counterparty', 'L', 7, true, this.masterCommonService.getListFromObject(filterForCounterParty, 'companyName', 'companyCode')));
    this.columns.set('assignedCommodity', new Tcolumn('assignedCommodity', 'Assigned Commodity', 'L', 8, true, this.masterCommonService.getListFromObject(filterForCommodity, 'commodityName', 'commodityCode')));
    this.columns.set('assignedUser', new Tcolumn('assignedUser', 'Assigned User', 'L', 9, true, this.masterCommonService.getListFromObject(filterUserMaster, 'userName', 'userName')));
    this.columns.set('contractBookStatus', new Tcolumn('contractBookStatus', 'Status', 'B', 10, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));
  }
}
