import {Component, OnInit} from '@angular/core';
import {Action} from "../../grid/actions.model";
import {filter} from "../../masters/services/filter.model";
import {environment} from "../../../environments/environment";
import {Tcolumn} from "../../grid/tcolumn.model";
import {Validators} from "@angular/forms";
import {Grid} from "../../grid/grid.model";
import {MasterService} from "../../masters/services/MasterService";
import {CommonService} from "../../services/common.service";
import {Tab} from "../../tab-grid/Tab.model";
import {KeyValue} from "../../grid/key-value.model";
import {ctrmValidators} from "../../services/validators/ctrmValidators";
import {entities} from "../../services/common/entities";

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.css']
})
export class ContractComponent implements OnInit {

  columns: Map<string, Tcolumn>;
  grid: Grid;
  dealGrid: Grid;
  commodity: string = '';
  counterparty: string = '';
  legalEntity: string = '';
  tabs: Tab[] = [];
  filterLegalEntity: filter;
  filterCounterparty: filter;
  filterUserMaster: filter;
  filterCommodity: filter;
  paymentTermFilter: filter;
  fieldToUpdate: Map<string, any> = new Map<string, any>();
  incotermFilter: filter;
  contractFilter: filter;
  uomFilter: filter;
  traderName: string = '';
  modalStateDeal = false;
  gridRefresh: boolean = false;

  constructor(public masterCommonService: MasterService, public commonService: CommonService) {
  }

  ngOnInit() {
    this.initFilters();
    this.getColumns();
    this.getDealSlip();
    this.grid = new Grid(this.columns, true, true, true, true, 'contractId', 'contract?sort=id,desc', '/trade', 'Contract', undefined, undefined);
    this.grid.setSaveUrl(environment.base_url + 'contract/post');
    this.grid.addQueryParamsField('commodity');
    this.grid.addQueryParamsField('traderName');
    this.grid.addQueryParamsField('paymentTerm');
    this.grid.addQueryParamsField('operationResponsibleFor');
    this.grid.setGridConfiguration(true, true, false, false);
    this.getContractList();
  }

  initFilters() {
    this.filterLegalEntity = new filter(environment.base_url + 'filter', entities.company);
    this.filterLegalEntity.addCriteria('companyStatus', true);
    this.filterCounterparty = new filter(environment.base_url + 'filter', entities.counterparty);
    this.filterCounterparty.addCriteria('counterPartyCode', 'none', 'notEqual');
    this.filterUserMaster = new filter(environment.base_url + 'filter', entities.userMaster);
    this.filterUserMaster.addCriteria('userMasterStatus', true);
    this.uomFilter = new filter(environment.base_url + 'filter', entities.uom);
    this.uomFilter.addCriteria('uomStatus', true);
    this.filterCommodity = new filter(environment.base_url + 'filter', entities.commodity);
    this.filterCommodity.addCriteria('commodityStatus', true);
    this.filterCommodity.addCriteria('isTradeable', false);
    this.paymentTermFilter = new filter(environment.base_url + 'filter', entities.paymentTerm);
    this.paymentTermFilter.addCriteria('status', true);
    this.incotermFilter = new filter(environment.base_url + 'filter', entities.incoterm);
    this.incotermFilter.addCriteria('incotermStatus', true);
  }

  public getColumns() {
    let actionStartDate = new Action();
    actionStartDate.addFieldToUpdate('endDate', undefined, undefined, undefined, undefined, false, 'minDate');
    this.columns = new Map();
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('contractId', new Tcolumn('contractId', 'Contract ID', 'LB', 1, false, undefined, true, '', 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('startDate', new Tcolumn('startDate', 'Start Date', 'D', 2, true, undefined, true, new Date(), 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, actionStartDate, {meta: {disabled: false}}));
    this.columns.set('endDate', new Tcolumn('endDate', 'End Date', 'D', 3, true, undefined, true, '', 'B', [Validators.required], undefined, 1, new Date()));
    this.columns.set('commodity', new Tcolumn('commodity', 'Commodity', 'L', 4, true, this.commonService.getListFromPost(environment.base_url + 'contract/commodity', {traderName: this.commonService.getFromStorage('userid')}, 'commodityName', 'commodityCode'), true, '', 'B', [Validators.required], undefined, 1, undefined, undefined, undefined));
    this.columns.set('counterParty', new Tcolumn('counterParty', 'Counter Party', 'L', 5, true, this.masterCommonService.getListFromObject(this.filterCounterparty, 'counterPartyName', 'counterPartyName'), true, '', 'B', [Validators.required], undefined, 1, undefined, undefined, undefined));
    this.columns.set('legalEntity', new Tcolumn('legalEntity', 'Legal Entity', 'L', 6, true, this.masterCommonService.getListFromObject(this.filterLegalEntity, 'companyName', 'companyName'), true, '', 'B', [Validators.required], undefined, 1, undefined, undefined, undefined));
    this.columns.set('traderName', new Tcolumn('traderName', 'Trader Name', 'L', 7, true, this.masterCommonService.getListFromObject(this.filterUserMaster, 'firstName', 'userName'), true, '', 'B', [Validators.required]));
    this.columns.set('operationResponsibleFor', new Tcolumn('operationResponsibleFor', 'Operation Responsible For', 'L', 8, true, this.masterCommonService.getListFromObject(this.filterUserMaster, 'firstName', 'userName'), true, '', 'B', [Validators.required]));
  }

  getDealSlip() {
    let actionStartDate = new Action();
    actionStartDate.addFieldToUpdate('endDate', undefined, undefined, undefined, undefined, false, 'minDate');
    let priceValidators = (value: any[] = []) => {
      let validators = [Validators.min(0)];
      if (value[0].toString().toLowerCase() === 'fixed' || value[0].toString().toLowerCase() === 'basis') {
        validators.push(Validators.required);
      }
      return validators;
    };
    let actionPriceType = new Action();
    actionPriceType.addFieldToUpdate('price', undefined, priceValidators, undefined, undefined, false, 'validators');
    let actionCommodity: Action = new Action();
    actionCommodity.addFieldToUpdate('cropOrigin', new KeyValue('originName', 'originCode'), undefined, undefined, environment.base_url + 'filter', true, undefined, undefined, undefined,
      {
        entityName: entities.commodityOrigin,
        criteria: [{key: 'commodityCode', operation: 'equal'}, {key: 'originStatus', operation: 'equal', value: true}]
      });
    this.contractFilter = new filter(environment.base_url + 'filter', entities.contract);
    this.contractFilter.addCriteria('contractId', "", "notNull");
    let columns: Map<string, Tcolumn> = new Map();
    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 1, false, undefined, false, '', 'N'));
    columns.set('contractId', new Tcolumn('contractId', 'Contract ID', 'L', 0, true, this.getContractList(), true, '', 'B'));
    columns.set('buySell', new Tcolumn('buySell', 'Buy/Sell', 'B', 2, true, this.masterCommonService.getBooleanFromLabel('Buy', 'Sell'), true, false, 'B', [Validators.required]));
    columns.set('tradeDate', new Tcolumn('tradeDate', 'Trade Date', 'D', 3, false, undefined, true, new Date(), 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionStartDate));
    columns.set('endDate', new Tcolumn('endDate', 'End Date', 'D', 3, false, undefined, true, '', 'B', [Validators.required], undefined, undefined, new Date(), undefined, undefined, undefined));
    columns.set('commodity', new Tcolumn('commodity', 'Commodity', 'L', 4, true, this.commonService.getListFromPost(environment.base_url + '/contract/commodity', {traderName: this.commonService.getFromStorage('userid')}, 'commodityName', 'commodityCode'), true, '', 'B', [Validators.required], undefined, 1, new Date(), undefined, undefined, actionCommodity));
    columns.set('counterParty', new Tcolumn('counterParty', 'Counter Party', 'L', 5, false, this.masterCommonService.getListFromObject(this.filterCounterparty, 'counterPartyName', 'counterPartyCode'), true, '', 'B', [Validators.required]));
    columns.set('quantity', new Tcolumn('quantity', 'Quantity', 'N', 6, true, undefined, true, '', 'B', [Validators.min(0), ctrmValidators.decimalLengthValidator(10, 2), Validators.required]));
    columns.set('quantityPeriodicity', new Tcolumn('quantityPeriodicity', 'Quantity Periodicity', 'L', 7, true, this.masterCommonService.getFromGlobalIndication('quantity_periodicity_ind', 'codeName', 'globalIndicatorCode'), true, '', 'B', [Validators.required]));
    columns.set('paymentTerm', new Tcolumn('paymentTerm', 'Payment Term', 'L', 8, true, this.masterCommonService.getListFromObject(this.paymentTermFilter, 'paymentTermName', 'paymentTermCode'), true, '', 'B', [Validators.required]));
    columns.set('incoterm', new Tcolumn('incoterm', 'Incoterm', 'L', 9, true, this.masterCommonService.getFromGlobalIndication('incoterm_code_ind', 'codeName', 'globalIndicatorCode'), true, '', 'B', [Validators.required]));
    columns.set('priceType', new Tcolumn('priceType', 'Price Type', 'L', 10, true, this.masterCommonService.getFromGlobalIndication('price_type_ind', 'codeName', 'globalIndicatorCode'), true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionPriceType));
    columns.set('price', new Tcolumn('price', 'Price', 'N', 11, true, undefined, true, '', 'B', [Validators.min(0)]));
    columns.set('location', new Tcolumn('location', 'Location', 'L', 12, true, this.commonService.getListFromPost(environment.base_url + 'location/getAllLocationType', [0, 1, 2], 'locationName', 'locationCode'), true, '', 'B', [Validators.required]));
    columns.set('cropOrigin', new Tcolumn('cropOrigin', 'Crop Origin', 'L', 13, true, [new KeyValue('Select', '')], true, '', 'B', []));
    this.dealGrid = new Grid(columns, true, true, true, true, '', 'dealSlip/contract', '', 'Deal Slip', '', '', undefined, undefined, false);
    this.dealGrid.setSaveUrl(environment.base_url + 'dealSlip/contract');
  }

  /**
   * open deal slip for creat contract and trade
   * @param value
   */
  openDealSlip(value: any) {
    this.gridRefresh = false;
    this.modalStateDeal = value;
  }

  canceled() {
    this.modalStateDeal = false;
  }

  onChange(object: any) {
    let _this = this;
    let currentValue = object['currentValue'];
    let validators: Validators[] = [];
    if (object['field']['field'] === 'commodity') {
      let cropOrigin = new filter(environment.base_url + 'filter', entities.commodityOrigin);
      cropOrigin.addCriteria('commodityCode', currentValue);
      cropOrigin.addCriteria('originStatus', true);
      let list = [new KeyValue('Select', '')];
      this.commonService.getJSONByObject(cropOrigin.getUrl(), cropOrigin).subscribe(function (data: any) {
        data.forEach(function (result) {
          list.push(new KeyValue(result['originName'], result['originCode']));
        });
        _this.fieldToUpdate.set('cropOrigin', [{field: 'cropOrigin', value: list, type: 'L'}]);
      });
    } else if (object['field']['field'] === 'priceType') {
      validators = [];
      if (currentValue !== undefined && currentValue.length > 0 && currentValue.toLowerCase() === 'fixed') {
        validators.push(Validators.required);
      }
      this.fieldToUpdate.set('price', [{field: 'price', value: validators, metaname: 'validators', type: 'M'}]);
    } else if (object['field']['field'] === 'tradeDate') {
      this.fieldToUpdate.set('endDate', [{field: 'endDate', value: currentValue, metaname: 'minDate', type: 'M'}]);
    }
  }

  getContractList() {
    let list = this.masterCommonService.getListFromObject(this.contractFilter, 'contractId', 'contractId', false, false);
    list.push(new KeyValue('New Contract', ''));
    return list;
  }

  onComplete(value: any) {
    this.fieldToUpdate = new Map<string, any>();
  }

  onDealSlipSave() {
    this.gridRefresh = true;
    this.modalStateDeal = false;
  }
}
