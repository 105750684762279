import { Component, OnInit } from '@angular/core';
import {CommonService} from "../../../../services/common.service";
import {environment} from "../../../../../environments/environment";
import {Action} from "../../../../grid/actions.model";
import {Tcolumn} from "../../../../grid/tcolumn.model";
import {FormGroup, FormControl} from "@angular/forms";
import {MasterService} from "../../../../masters/services/MasterService";
import {HttpErrorResponse} from "@angular/common/http";
import {MessageService} from "primeng";
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";
import {Filter} from "../../../../grid/preference/filter/filter.model";

@Component({
  selector: 'app-mpob-generation',
  templateUrl: './mpob-generation.component.html',
  styleUrls: ['./mpob-generation.component.css']
})
export class MpobGenerationComponent implements OnInit {
  private currentPageNumber: any=0;

  constructor(public commonService: CommonService, private masterCommonService: MasterService,private messageService: MessageService) { }

  rowData:any[]=[];
  tradeId: any[];
  tradeColumns: Tcolumn[] = [];
  tradeData: any[]=[];
  tradeDisplay: boolean = false;
  todayDate=new Date().toISOString().substr(0, 10);
  companyMaster: any[]=[];
  filterApiFunction: Function;
  selectedRow: any[]=[];
  totalCount: number=0;
  bsConfig: Partial<BsDatepickerConfig>;
  filterTradeCriteria:any[] = [];
  filterSection=new FormGroup({
    selectedDateFrom:new FormControl(new Date().toISOString().substr(0, 10)),
    selectedDateTo:new FormControl(new Date().toISOString().substr(0, 10)),
    selectedCompany:new FormControl()
  })
  ngOnInit(): void {
    this.loadDropdownData();
    this.tradeDisplayGrid();
    this.filterFunction();
    let _this=this;
    this.filterApiFunction =  function(payload: any) {

      let updateFilterPayload= [];
      updateFilterPayload.push({fieldName: 'tenantId',condition: 'equals',value: _this.commonService.getFromStorage('tenantId')});
      updateFilterPayload.push({fieldName: 'tradeType', condition: 'in', value: ["Physical","Paper"]});
      updateFilterPayload.push({fieldName:'externalRecord',condition:'equals',value:false})
      if(_this.filterSection.get('selectedCompany').value!=null && _this.filterSection.get('selectedCompany').value!=""){
        updateFilterPayload.push({fieldName: 'company', condition: 'equals', value: _this.filterSection.get('selectedCompany').value});
      }

      let firstValue = new Date(_this.filterSection.get('selectedDateFrom').value);
      let secondValue =new Date(_this.filterSection.get('selectedDateTo').value);
      firstValue.setUTCHours(0, 0, 0, 0);
      secondValue.setUTCHours(23, 59, 59, 999);

      updateFilterPayload.push({fieldName:'tradeDateTime',condition:'betweendates2',value:firstValue.toISOString(),secondValue:secondValue.toISOString()});

      payload['url'] = environment.base_url + '/api/mpobtradereportroot/v1/getallmpobtradebycriteria?page=0&size=1000&tenantId='+_this.commonService.getFromStorage('tenantId');
      payload['type'] = 'post';
      payload['payload'] = updateFilterPayload;
      return payload;


    };
  }
  generateMPOB(value: any[]) {
   // http://localhost:8083/api-bm/api/unitofmeasurement/v1/getMPOBReport?tenantId=d7408d31-d720-4173-b76e-0595ce2679b4
    let mpobString: any[] = [];
    let _this = this;
    this.commonService.post(environment.base_url_new+"/api-bm/api/unitofmeasurement/v1/getMPOBReport?tenantId="+this.commonService.getFromStorage('tenantId') ,value).subscribe((next:any[]) => {
       let finalString='';
       next.forEach((e:string)=>{
         finalString+=e;
       })
      this.commonService.downloadTextFile(finalString.trimEnd(), "MPOB_"+new Date().getMilliseconds()+".txt");
    })

  }

  async getMPOBRequiredData() {
      let commodity = await this.commonService.getHttpClient().get("").toPromise().then(next => next);
      let counterparty = await this.commonService.getHttpClient().get("").toPromise().then(next => next);
      let company = await this.commonService.getHttpClient().get("").toPromise().then(next => next);
  }

  tradeDisplayGrid() {
    this.tradeColumns.push(new Tcolumn('tradeId', 'Trade ID', 'T', 0, false, undefined));
    this.tradeColumns.push(new Tcolumn('tradeTransactionType', 'Transaction Type', 'T', 3, false, undefined));
    this.tradeColumns.push(new Tcolumn('tradeDateTime', 'Trade Date', 'D', 4, false, undefined));
    this.tradeColumns.push(new Tcolumn('updatedTimestamp', 'Trade Approval Date', 'D', 4, false, undefined));
    this.tradeColumns.push(new Tcolumn('tradeType', 'Trade Type', 'T', 4, false, undefined));
    this.tradeColumns.push(new Tcolumn('counterpartyName', 'Counterparty', 'T', 1, false, undefined));
    this.tradeColumns.push(new Tcolumn('company', 'Company', 'T', 2, false, undefined));
    this.tradeColumns.push(new Tcolumn('commodity', 'Commodity', 'T', 2, false, undefined));
    this.tradeColumns.push(new Tcolumn('tradePrice', 'Trade Price', 'T', 5, false, undefined));
    this.tradeColumns.push(new Tcolumn('provisionalPrice','Provisional Price','T',2,false,undefined));
    this.tradeColumns.push(new Tcolumn('totalTradeQty', 'Quantity', 'T', 5, false, undefined));
  }

  filterFunction(){

    this.filterTradeCriteria=[];
    this.filterTradeCriteria.push({fieldName: 'tenantId',condition: 'equals',value: this.commonService.getFromStorage('tenantId')});
    // this.filterTradeCriteria.push({fieldName: 'tradeStatus', condition: 'equals', value: "Confirmed"});
    this.filterTradeCriteria.push({fieldName: 'tradeType', condition: 'in', value: ["Physical","Paper"]});
    this.filterTradeCriteria.push({fieldName:'externalRecord',condition:'equals',value:false})
    if(this.filterSection.get('selectedCompany').value!=null && this.filterSection.get('selectedCompany').value!=""){
      this.filterTradeCriteria.push({fieldName: 'company', condition: 'equals', value: this.filterSection.get('selectedCompany').value});
    }
    let firstValue = new Date(this.filterSection.get('selectedDateFrom').value);
    let secondValue =new Date(this.filterSection.get('selectedDateTo').value);
    firstValue.setUTCHours(0, 0, 0, 0);
    secondValue.setUTCHours(23, 59, 59, 999);

     this.filterTradeCriteria.push({fieldName: 'tradeDateTime',condition: 'betweendates2',value:firstValue.toISOString(),secondValue:secondValue.toISOString()});
    this.commonService.post(environment.base_url + '/api/mpobtradereportroot/v1/getallmpobtradebycriteria?page='+this.currentPageNumber+'&size=1000'+'&tenantId='+this.commonService.getFromStorage('tenantId'), this.filterTradeCriteria).subscribe((next: any) => {
      this.tradeData=next;
      this.selectedRow=next['content'];
      this.totalCount=0;
      next['content'].forEach((data:any)=>{
        this.totalCount++;
      })
    });
  }

  checkValue(value:any):any{
   if(value===undefined || value==null){ return 0;}
    else {return value; }

  }

  loadDropdownData() {
    this.commonService.getJSONByURL(environment.base_url_new + '/api-bm/api/company/v1/getall?tenantId=' + this.commonService.getFromStorage('tenantId') +
        '&page=0' + '&size=2000').subscribe((result: any) => {
      result.content.forEach((e: any) => {
        this.companyMaster.push(e.name);
      })
    });
  }

  onSubmit(next: any) {
    this.totalCount=0;
    this.tradeData=next;
    this.selectedRow=next['content'];
    next['content'].forEach((data:any)=>{
      this.totalCount++;
    })
  }
  onPageChange(event) {
    const page = event.page;
    this.currentPageNumber = page;
    this.filterFunction();
  }


}
