import {Injectable} from '@angular/core';
import {FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {CommonService} from "./common.service";

@Injectable({
  providedIn: 'root'
})
export class FormControlServiceService {

  private formBuilder: FormBuilder;
  private formGroup: FormGroup;
  private formGroupObject: any;
  private newformGroupObject: any;
  private fields: any[] = [];
  private defaultValues: any = {};

  constructor() {
    this.formBuilder = new FormBuilder();
    if (this.formGroupObject === null || this.formGroupObject === undefined) {
      this.formGroupObject = {};
      this.newformGroupObject = {};
    }
  }

  addControl(field:string,value:any = '',validators:Validators = [],disabled:boolean = false) {
    this.defaultValues[field] = value;
    this.addinfield(field);
    this.formGroupObject[field] = [{value:value,disabled:disabled},validators];
    this.newformGroupObject[field] =this.formGroupObject[field];
    return this;
  }

  build() {
    let _this = this;
    if(this.formGroup !== null && this.formGroup !== undefined) {
      let config = { ...this.formGroup.controls};
      Object.keys(this.newformGroupObject).forEach(function (key) {
        config[key] = _this.newformGroupObject[key];
      });
      this.formGroup = this.formBuilder.group(config);
    } else {
      this.formGroup = this.formBuilder.group(this.formGroupObject);
    }
    this.newformGroupObject = {};
    return this.formGroup;
  }

  addFormGroupControl(field:string,formGroup:FormGroup) {
    this.defaultValues[field] = formGroup;
    this.addinfield(field);
    this.formGroupObject[field] = formGroup;
    this.newformGroupObject[field] =this.formGroupObject[field];
    return this;
  }

  removeFormGroupControl(field:string) {
    this.defaultValues[field] = {};
    this.addinfield(field);
    this.formGroupObject[field] = {};
    this.newformGroupObject[field] =this.formGroupObject[field];
    return this;
  }

  private addinfield(field) {
    if(!this.fields.includes(field)) {
      this.fields.push(field);
    }
  }

  updateValidators(field:string,validators:any =[]) {
    this.formGroup.controls[field].setValidators(validators);
    this.formGroup.controls[field].updateValueAndValidity();
  }

  disable(field:string) {
    this.formGroup.controls[field].disable();
  }

  enable(field:string) {
    this.formGroup.controls[field].enable();
  }

  getValue(field: string) {
    return this.formGroup.controls[field].value;
  }


  reset() {
    this.formGroup.reset(this.defaultValues);
  }


  setValue(field: string, newvalue: any) {
    if (this.formGroup.controls[field] !== null && this.formGroup.controls[field] !== undefined) {
      this.formGroup.controls[field].setValue(newvalue);
      this.formGroup.controls[field].updateValueAndValidity();
    }
  }

  getFields() {
    return this.fields;
  }

  getFormGroup() {
    return this.formGroup;
  }

  getErrors(commonService:CommonService) {
    let errorMessage:any = '';
    let _this = this;
    if(this.formGroup !== null && this.formGroup !== undefined) {
      let errors: any[] = [];
      let controls:any = this.formGroup.controls;
      if(controls !== null && controls !== undefined) {
        Object.keys(controls).forEach(key => {
          const controlErrors: ValidationErrors = _this.formGroup.controls[key].errors;
          if (controlErrors !== null) {
            Object.keys(controlErrors).forEach(keyError => {
              errors.push({
                control_name: commonService.camelToTitleCase(key),
                error_name: keyError,
                error_value: controlErrors[keyError]
              });
            });
          }
        })
      }
      errorMessage = commonService.extractErrors(errors);
    }
    return errorMessage;
  }

  hasControl(controlName: string): boolean {
    if (this.formGroup !== null && this.formGroup !== undefined) {
      return this.formGroup.contains(controlName);
    }
    return false;
  }

  attachChangeListener(field:string,functionToRun:Function) {
    if(this.formGroup.contains(field)) {
      this.formGroup.controls[field].valueChanges.subscribe(next => functionToRun(next,this.formGroup,this.formGroup.controls[field]));
    }
  }
}
