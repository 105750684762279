import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormControl, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";
import {CommonService} from "../../services/common.service";
import {MessageService} from "primeng";
import {FilterUtils} from "primeng/utils";
import {environment} from "../../../environments/environment";
import {HttpErrorResponse} from "@angular/common/http";
import {AllValidationErrors} from "../../grid/grid.component";
import {AccessPolicyService} from "../../services/accesspolicy.service";

@Component({
  selector: 'app-generate-proforma-invoice',
  templateUrl: './generate-proforma-invoice.component.html',
  styleUrls: ['./generate-proforma-invoice.component.css']
})
export class GenerateProformaInvoiceComponent implements OnInit,OnChanges {
  formHeight: string = '';
  invoiceForm: FormGroup;
  adjustmentPrice: number = 1;
  allBanks: any [] = [];
  currencyList: any[] = [];
  adjustmentRow: boolean = false;
  lineItems: any[] =[];
  totalAmount: number = 0;
  viewerState: boolean = false;
  totalTemp: number = 0;
  adjustAmount: any = 0;
  display: boolean = true;
  totalInWords: any;
  bsConfig: Partial<BsDatepickerConfig>;
  docUrl: string = '';
  docName: string = '';
  showDoc: boolean = false;
  previewBytes: any;
  fxRate: number = 1.0000;
  @Input() isPurchaseInvoice:boolean = false;
  @Input() useGivenLineItems:boolean = false;
  @Input() showToastMessage:boolean = true;
  @Input() adjustmentAmountDescription: any = '';
  @Input() isApproving:boolean = false;
  @Input() invoiceObject:any;
  paymentConfirmedBy: any = '';
  invoiceCurrency: any;
  remitList: any[] = [];
  tradeObject:any = null;
  invoiceDate: any = '';
  postingDate: any;
  data:any = {};
  paymentDate: any;
  fileName: string = '';
  fileToUpload: any;
  @Input() sell: boolean = true;
  @Input() tradeId: any;
  @Input() obligationObject;
  @Output() onDraftClose = new EventEmitter();
  @Output() onGenerateDone = new EventEmitter();
  @ViewChild('af', {static: true}) formElement;
  @ViewChild('cashFlowGrid', {static: true}) table;
  cfCols: any[] = [];
  remitTo: string = '';
  errorMessage: any;
  isAdvanceInvoiceMode:boolean = false;
  mandatoryFields:any[]=['invoiceDate','paymentDueDate','counterpartyAddress','companyAddress','remitTo','bankName',]
  @Input() loading: boolean = false;
  generating:boolean = false;

  constructor(public commonService: CommonService,private accessService:AccessPolicyService,private messageService: MessageService) {
  }

  @HostListener('window:resize', [])
  public onResize() {
    this.calculateHeight();
  }

  private calculateHeight() {
    let offsetHeightAF = this.formElement.el.nativeElement['offsetParent']['offsetHeight'];
    this.formHeight = (offsetHeightAF - 200) + 'px';
  }
  private showToast(msg, severity: string = 'success') {
    this.messageService.add({
      severity: severity,
      summary: msg
    });
  }




  ngOnInit() {
    FilterUtils['notContainsValue'] = function gte(value: any, filter: any[]): boolean {
      if (filter === undefined || filter === null) {
        return false;
      }
      if (value === undefined || value === null || value.length === 0) {
        return false;
      }
      return !filter.includes(value);
    };
    FilterUtils['containsValue'] = function gte(value: any, filter: any[]): boolean {
      if (filter === undefined || filter === null) {
        return false;
      }
      if (value === undefined || value === null || value.length === 0) {
        return false;
      }
      return filter.includes(value);
    };
    this.bsConfig = new BsDatepickerConfig();
    this.bsConfig.dateInputFormat = environment.dateFormat.toUpperCase();
    this.bsConfig.adaptivePosition = true;
    this.bsConfig.useUtc = false;
    this.currencyList = this.commonService.getCurrencyList();
    this.invoiceForm = null;
    this.cfCols = [
      {field: 'tradeId', header: 'Trade Id'},
      {field: 'type', header: 'Trade Type'},
      {field: 'narration', header: 'Description',editable:true},
      {field: 'price', header: 'Price'},
      {field: 'settlementValue', header: 'Settlement Amount'}
    ];
  }
  ngAfterViewInit() {
    this.calculateHeight();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['invoiceObject'] && changes['invoiceObject'].currentValue !== undefined && changes['invoiceObject'].currentValue !== null && Object.keys(changes['invoiceObject'].currentValue).length > 0) {
      this.continueInit(changes['invoiceObject'].currentValue);
    }
  }

  private checkSettlementValueValidation(): boolean {
    let isValid = true;
    let list = this.lineItems.filter((o) => o['settlementValue'] > o['originalSettlementValue']);
    if (list.length > 0) {
      let msg = '';
      list.forEach((item) => {
        msg += 'Settlement Value [' + item['settlementValue'] + '] can not be greater than ' + item['originalSettlementValue'] + '\n';
      });
      this.showToast(msg, 'error');
      isValid = false;
    }
    return isValid;
  }
  private getFormValidationErrors(): boolean {
    let keys= Object.keys(this.invoiceForm.value)
    let errors: AllValidationErrors[] = [];
    let _this = this;
    keys.forEach(function (key:any) {
      if(_this.invoiceForm.contains(key)) {
        const controlErrors: ValidationErrors = _this.invoiceForm.get(key).errors;
        if (controlErrors !== null) {
          Object.keys(controlErrors).forEach(keyError => {
            errors.push({
              control_name:_this.commonService.applyTitleCaseFormatting(key),
              error_name: keyError,
              error_value: controlErrors[keyError]
            });
          });
        }
      }
    })
    let text = this.commonService.extractErrors(errors);
    if (text.toString().length > 0) {
      _this.messageService.add({
        severity: 'error', summary: 'Please find below validation errors \n',
        detail: text, life: 5000
      });
      return false;
    }
    return true;
  }


  private continueInit(invoiceObject: any) {
    this.isAdvanceInvoiceMode = invoiceObject.invoiceType.toLowerCase().includes("advance");
    this.data = invoiceObject;
    this.remitTo = this.data['company'];
    this.initForm(invoiceObject);
    this.fillLineItems(invoiceObject);
    this.getRemitList();
    this.getAllBanks();
    this.getTotalAmount();
    this.invoiceCurrency = invoiceObject['currency'];
    this.onCheckBoxClick({checked: false});
  }

  private initForm(invoiceObject:any) {
    let controls:any = {};
    let value ='';
    let _this = this;
    controls['beneficiaryAccNo'] = new FormControl('');
    controls["companyAddress"] = new FormControl(invoiceObject['companyAddress']);
    controls["invoiceNumber"] = new FormControl(invoiceObject['invoiceNumber']);
    Object.keys(invoiceObject).forEach(function (key) {
      if(key === 'limitsOrTolerance') {
        value = _this.commonService.getDualSliderValueGeneral(invoiceObject[key], invoiceObject['quantityUom'])
        key = 'tolerance';
      } else {
        value = invoiceObject[key];
      }
      if(value === undefined || value === null) {
        value = '';
      }
      if(_this.mandatoryFields.includes(key)){
        controls[key]= new FormControl(value,[Validators.required])
      }else {
        controls[key] = new FormControl(value);
      }
    });
    this.invoiceForm = new FormGroup(controls);
    if(this.invoiceForm.controls['fxRate']!== null && this.invoiceForm.controls['fxRate']!== undefined) {
      this.invoiceForm.controls['fxRate'].disable();
    }
    if(this.invoiceForm.controls['postingDate']!== null && this.invoiceForm.controls['postingDate']!==undefined) {
      this.invoiceForm.controls['postingDate'].setValue(this.getValueFromForm('postingDate'));
    }
  }
  private fillLineItems(invoiceObject:any) {
    let _this = this;
    if (this.lineItems === undefined || this.lineItems === null) {
      this.lineItems = [];
    }
    invoiceObject['invoiceLineItemList'].forEach(function (item: any) {
      _this.lineItems.push(
          {
            quantity: item['quantity'],
            tradeId: item['tradeId'],
            type: item['cashflowType'],
            narration: item['narration'],
            price: item['tradePrice'],
            settlementValue: item['settlementAmount'],
            originalSettlementValue: item['settlementAmount'],
            itemCategory: item['itemCategory']
          });
    })
    this.totalAmount = this.invoiceObject['settlementAmount'];
    this.updateTotal();
  }
  private getRemitList() {
    if (this.sell) {
      let companyList = [{label: this.data['company'], value: this.data['company']}];
      this.commonService.getJSONByURL(environment.base_url + '/api/company/v1/getsibling?name=' + this.data['company']+"&tenantId="+this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
        let parentList = this.commonService.getListFromArray(next, 'parent', 'parent', false, false);
        this.remitList = [...companyList,...parentList];
        this.invoiceForm.controls['remitTo'].setValue(this.remitList[0]['value']);
      });
    } else {
      this.remitList = [{label: this.data['counterparty'], value: this.data['counterparty']}];
      this.invoiceForm.controls['remitTo'].setValue(this.data['counterparty']);
    }
  }
  private getAllBanks() {
    if (this.sell) {
      if (this.data['company'] !== undefined && this.data['company'] !== null) {
        this.commonService.getJSONByURL(environment.base_url + '/api/companybankaccount/v1/getallbyname?name=' + this.remitTo+"&tenantId="+this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
          this.allBanks = this.commonService.getListFromArray(next, 'bankName', 'bankName', false, false);
          if(this.allBanks !== null && this.allBanks !== undefined && this.allBanks.length > 0) {
            this.invoiceForm.controls['bankName'].setValue(this.allBanks[0]['value']);
            this.throwChanges(this.invoiceForm.controls['bankName'].value);
          }
        });
      }
    } else {
      if (this.data['counterparty'] !== undefined && this.data['counterparty'] !== null) {
        this.commonService.getJSONByURL(environment.base_url + '/api/counterpartybankaccount/v1/getallbyname?name=' + this.data['counterparty']+"&tenantId="+this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
          this.allBanks = this.commonService.getListFromArray(next, 'bankName', 'bankName', false, true);
          this.invoiceForm.controls['bankName'].setValue(this.data['bankName']);
        });
      }
    }
  }
  private getTotalAmount() {
    this.invoiceForm.controls['paymentAmount'].setValue(this.totalAmount);
    this.updateTotalInWords();
  }
  private onCheckBoxClick(value: any) {
    this.applyFilter(value['checked'] || [{
      columnName: 'type',
      firstValue: ['Cost Reversal', 'Trade Reversal'],
      matchType: 'notContainsValue'
    }])
  }

  private getValueFromForm(key:string,defaultValue:any = "") {
    if(this.invoiceForm.contains(key)) {
        return this.invoiceForm.controls[key].value;
    }
    return defaultValue;
  }
  private getPayload(settlementAmount,invoiceType,financialAmountType) {
    return {
      settlementAmount: settlementAmount,
      invoiceLineItemList: this.generateLineItems(),
      total: this.totalAmount,
      finalInvoiceType: this.data['finalInvoiceType'],
      counterpartyType: this.data['counterpartyType'],
      fullInvoiceType: this.data['fullInvoiceType'],
      financialAmountType: financialAmountType,
      totalInWords: this.totalInWords,
      invoiceDate: this.getValueFromForm('invoiceDate'),
      tradeId: this.data['tradeId'],
      paymentDueDate: this.getValueFromForm('paymentDueDate'),
      counterpartyRefNumber: this.getValueFromForm('counterpartyRefNumber'),
      counterparty: this.data['counterparty'],
      contractNumber: this.data['contractNumber'],
      commodity: this.data['commodity'],
      termsOfPayment: this.data['termsOfPayment'],
      company: this.data['company'],
      counterpartyAddress: this.data['counterpartyAddress'],
      companyAddress: this.data['companyAddress'],
      shipment: this.data['shipment'],
      portOfLoading: this.data['portOfLoading'],
      portOfDischarging: this.data['portOfDischarging'],
      blNumber: this.getValueFromForm('blNumber'),
      blDate: this.getValueFromForm('blDate'),
      vesselName: this.getValueFromForm('vesselName'),
      quantity: this.data['quantity'],
      unitPrice: this.data['unitPrice'],
      limitsOrTolerance: this.data['limitsOrTolerance'],
      bankAddress: this.getValueFromForm('bankAddress'),
      bankName: this.getValueFromForm('bankName'),
      accountNumber: this.getValueFromForm('beneficiaryAccNo'),
      swiftCode: this.getValueFromForm('swiftCode'),
      remitTo: this.getValueFromForm('remitTo'),
      currency: this.getValueFromForm('currency'),
      paymentRefNumber: this.getValueFromForm('paymentRefNumber'),
      paymentDate: this.getValueFromForm('paymentDate'),
      paymentAmount: this.getValueFromForm('paymentAmount'),
      purpose: this.getValueFromForm('purpose'),
      paymentConfirmedBy: this.paymentConfirmedBy,
      traderName: this.getValueFromForm('traderName'),
      invoiceGeneratedBy: this.getUserName(),
      attachmentName: this.fileName,
      invoiceDocument: this.fileToUpload,
      uom: this.data['uom'],
      fxRate: this.fxRate,
      notifyPartyName: this.getValueFromForm('notifyPartyName'),
      notifyPartyAddress: this.getValueFromForm('notifyPartyAddress'),
      deliveryTerms: this.data['deliveryTerms'],
      adjustmentAmount: this.getAdjustmentAmount(),
      invoiceType: invoiceType,
      incoterm: this.data['incoterm']
    };
  }

  generateInvoice() {
    this.generating = true;
    if (this.checkSettlementValueValidation()) {
      let invoiceType = this.invoiceObject['invoiceType'];
      let validation: boolean = this.getFormValidationErrors();
      if (!validation) {
        this.generating = false;
        return;
      }
      let _this = this;
      let actualPayload: {};
      let settlementAmount = this.totalAmount;
      if (this.adjustAmount !== undefined && this.adjustAmount !== null && this.adjustAmount !== 0) {
        settlementAmount = settlementAmount - this.adjustAmount;
      }
      actualPayload = this.getPayload(settlementAmount, this.data['invoiceType'],this.sell?'receivable':'payable');
      let uploadUrl = environment.base_url + '/api/tradeattachment/v1/generateproformainvoice?tenantId=' + this.commonService.getFromStorage('tenantId');
      if (invoiceType.toLowerCase().includes("advance")) {
        uploadUrl = environment.base_url + '/api/invoice/v1/generateadvanceinvoice?tenantId=' + this.commonService.getTenantId();
      }
      this.commonService.post(uploadUrl, actualPayload).subscribe(() => {
        _this.generating = false;
        if (_this.showToastMessage) {
          _this.messageService.add({severity: 'info', summary: invoiceType+' Invoice Generated Successfully'});
        }
        _this.onGenerateDone.emit();
      }, (error: HttpErrorResponse) => {
        _this.generating = false;
        if (_this.showToastMessage) {
          _this.messageService.add({
            severity: 'error',
            summary: 'Failed to generate '+invoiceType+' invoice',
            detail: _this.commonService.getHttpErrorMessage(error, 'Pro Forma Invoice')
          });
        }
      });
    } else {
      this.generating = false;
    }
  }

  onFormCancel() {
    this.onDraftClose.emit(false);
  }

  getAdjustmentAmount() {
    return parseInt(this.adjustAmount);
  }

  throwChanges(val) {
    let _this = this;
    let url = environment.base_url;
    if (this.sell) {
      url+='/api/companybankaccount/v1/getallbybanknameandtenantid?tenantId='+this.commonService.getFromStorage('tenantId')+'&companyName='+this.data['company']+'&bankName='+val;
    } else {
      url+='/api/counterpartybankaccount/v1/getallbybanknameandtenantid?tenantId='+this.commonService.getFromStorage('tenantId')+'&counterpartyName='+this.data['counterparty']+'&bankName='+val;
    }
    this.commonService.getJSONByURL(url).subscribe(next => {
      if(next !== null && next !== undefined) {
        _this.invoiceForm.controls['bankAddress'].setValue(next['bankAddress']);
        _this.invoiceForm.controls['beneficiaryAccNo'].setValue(next['accountNumber']);
        _this.invoiceForm.controls['swiftCode'].setValue(next['swiftCode']);
      }
    });
  }

  updateTotal() {
    if (this.adjustAmount === 0 || this.adjustAmount.toString() === '') {
      this.adjustmentRow = false;
    }
    this.totalAmount = this.totalAmount - this.totalTemp;
    this.totalTemp = parseInt(this.adjustAmount);
    this.totalAmount = this.totalAmount + parseInt(this.adjustAmount);
    this.invoiceForm.controls['paymentAmount'].setValue(this.totalAmount);
    this.updateTotalInWords();
  }

  getUserName() {
    return this.commonService.getFromStorage('userName');
  }

  checkEmpty(value: any) {
    if (value === '') {
      this.adjustAmount = 0;
    }
  }

  addAdjustmentClick() {
    this.adjustmentRow = !this.adjustmentRow;
    if (!this.adjustmentRow) {
      this.totalAmount = this.totalAmount - this.totalTemp;
      this.adjustAmount = 0;
      this.totalTemp = 0;
      this.updateTotalInWords();
    }
  }

  onChange(files: any[]) {
    let _this = this;
    let reader = new FileReader();
    if (files !== undefined && files.length > 0) {
      files.forEach(function(file) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          const uploadData = new FormData();
          uploadData.append('file', file, file.name);
          _this.commonService.getByteArray(environment.base_url + '/api/company/v1/upload/logo', uploadData).subscribe(function(data: any) {
            _this.fileToUpload = data.response;
            _this.fileName = file.name;
          });
        };
      });
    }
  }


  onCloseDocViewer() {
    this.showDoc = false;
  }

  throwCurrencyChanges(currency: any) {
    if (currency !== this.data['currency']) {                       //only for PROTO , later put condition check with settlement currency
      this.invoiceForm.controls['fxRate'].enable();
      this.invoiceCurrency = currency;
    } else {
      this.fxRate = 1.0;
      this.invoiceForm.controls['fxRate'].setValue(1.0);
      this.invoiceForm.controls['fxRate'].disable();
      this.invoiceCurrency = currency;
      this.updateTotalForFxRate();
    }
    this.updateTotalInWords();
  }

  getFinancialAmount(amt: any) {
    return this.commonService.getFormatedNumber(parseFloat(parseFloat(amt).toFixed(4)) * this.fxRate);
  }

  onFXRateChange(fxRate: any) {
    if (fxRate != '') {
      this.fxRate = parseFloat(fxRate);
    }
  }

  updateTotalForFxRate() {
    if (this.fxRate !== 0 && !isNaN(this.fxRate) && this.fxRate !== undefined) {
      this.totalAmount = 0;
      this.invoiceObject['invoiceLineItemList'].forEach(row => {
        this.totalAmount = this.totalAmount + (parseFloat(row['settlementAmount']) * this.fxRate);
      });
      this.totalAmount = this.totalAmount + parseFloat(this.adjustAmount);
      this.invoiceForm.controls['paymentAmount'].setValue(this.totalAmount);
      this.updateTotalInWords();
    }
  }

  getCurrency() {
    return this.invoiceCurrency;
  }


  getDateFormat(dateToConvert) {
    let year, month, date, dt;
    date = new Date(dateToConvert.toDateString());
    year = date.getFullYear();
    month = date.getMonth() + 1;
    dt = date.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return year + '-' + month + '-' + dt;
  }



  checkForShipment() {
    return this.data['shipment'] != null;
  }

  throwRemitToChanges(remitTo: any) {
    this.remitTo = remitTo;
    this.getAllBanks();
  }


  private applyFilter(currentValue: any[]) {
    let _this = this;
    if(this.table != null && this.table != undefined) {
      this.table.reset();
      currentValue.forEach(function(value: any) {
        _this.table.filter(value['firstValue'], value['columnName'], value['matchType'])
      })
    }
  }

  generateLineItems() {
    let invoiceItemsLines = [];
    let _this = this;
    if(this.obligationObject !== null && this.obligationObject !== undefined) {
      this.lineItems.forEach(function (obj) {
        let object = {
          quantity: _this.obligationObject['tradeQuantity'],
          tradePrice: _this.data['unitPrice'],
          plannedObligationId: '',
          tradeId: obj['tradeId'],
          cashflowType: obj['type'],
          currency: _this.getCurrency(),
          settlementAmount: obj['settlementValue'],
          narration: obj['narration'],
          itemCategory: obj['itemCategory'],
          advanceInPercent: _this.getAdvanceInPercent(obj['settlementValue'],obj['originalSettlementValue']),
        };
        invoiceItemsLines.push(object);
      });
    } else {
      this.lineItems.forEach(function (obj) {
        let object = {
          quantity: (_this.tradeObject !== null && _this.tradeObject !== undefined)?_this.tradeObject['totalTradeQty']:obj['quantity'],
          tradePrice: _this.data['unitPrice'],
          plannedObligationId: '',
          tradeId: obj['tradeId'],
          cashflowType: obj['type'],
          currency: _this.getCurrency(),
          settlementAmount: obj['settlementValue'],
          narration: obj['narration'],
          itemCategory: obj['itemCategory'],
          advanceInPercent: _this.getAdvanceInPercent(obj['settlementValue'],obj['originalSettlementValue']),
        };
        invoiceItemsLines.push(object);
      });
    }
    if (this.adjustmentRow && this.adjustAmount > 0) {
      let object = {
        quantity: 0,
        tradePrice: 1,
        plannedObligationId: '',
        cashflowId: 'ADJUSTMENT_AMOUNT',
        cashflowType: '',
        costValue: 0,
        currency: this.getCurrency(),
        settlementAmount: this.adjustAmount,
        narration: this.adjustmentAmountDescription
      }
      invoiceItemsLines.push(object);
    }
    return invoiceItemsLines;
  }

  private getAdvanceInPercent(newAmount: any,fullAmount:number){
      return ((newAmount / fullAmount) * 100);
  }

  getColValue(rowData, col: any) {
    if (col.field === 'settlementValue') {
      return this.getFinancialAmount(rowData[col.field]) + ' ' + this.getCurrency();
    } else {
      return rowData[col.field];
    }
  }

  preview() {
    let _this = this;
    let settlementAmount = this.totalAmount;
    if (this.adjustAmount !== undefined && this.adjustAmount !== null && this.adjustAmount !== 0) {
      settlementAmount = settlementAmount - this.adjustAmount;
    }
    let invoiceType = this.sell ? 'sell' : 'purchase';
    let payload = this.getPayload(settlementAmount,this.data['invoiceType'],this.sell?'receivable':'payable');
    this.viewerState = true;
    this.commonService.post(environment.base_url+'/api/invoice/v1/generatepreviewdocument?tenantId='+this.commonService.getFromStorage('tenantId'),payload).subscribe((next:any) =>{
      let status = next['status'];
      if(status.toLowerCase().includes("no template found")) {
          _this.previewBytes = status;
      } else {
           _this.previewBytes = next['response'];
      }
    },(error:HttpErrorResponse) => {
      _this.errorMessage = _this.commonService.getHttpErrorMessage(error,'Generate Invoice',false);
    });
  }


  onViewerClose() {
    this.previewBytes = null;
    this.viewerState = false;
  }

  changeNarrationValue(rowData: any, col: any, value: Event) {
    rowData['narration'] = value['target']['value'];
  }

  changeSettlementValue(rowData: any, col: any, value: Event,index:number) {
    let settlementValue: number = parseFloat(value['target']['value']);
    let originalSettlementValue = rowData['originalSettlementValue'];
    rowData['settlementValue'] = settlementValue;
    if (originalSettlementValue < settlementValue) {
      this.showToast('Settlement Value [' + settlementValue + '] can not be greater than ' + originalSettlementValue, 'error');
    } else {
      if (this.adjustAmount === 0 || this.adjustAmount.toString() === '') {
        this.adjustmentRow = false;
      }
      this.totalTemp = parseInt(this.adjustAmount);
      this.totalAmount = settlementValue + parseInt(this.adjustAmount);
      this.invoiceForm.controls['paymentAmount'].setValue(this.totalAmount);
      this.updateTotalInWords();
    }
    this.updateNarration(index,settlementValue,originalSettlementValue)
  }

  private updateTotalInWords() {
    this.totalInWords = this.commonService.convertMoneyToWordsByCurrencyCode(this.totalAmount, this.getCurrency());
  }

  isAdvance() {
    return this.invoiceObject.invoiceType.toLowerCase().includes("advance");
  }

  private updateNarration(index:number,settlementValue: number, originalSettlementValue: number) {
    let desc:string = this.lineItems[index]['narration'];
    if(desc.toLowerCase().startsWith("sale of")) {
       let splits:string[] = desc.split("@");
       splits.splice(splits.length -1,1);
       let percent = parseFloat(String(((settlementValue / originalSettlementValue) * 100))).toFixed(2);
       splits.push(" "+percent+"%");
      this.lineItems[index]['narration'] = splits.join("@");
    }
  }
}
