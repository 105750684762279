import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {CommonService} from "../../../services/common.service";
import {environment} from "../../../../environments/environment";
import {FilterUtils} from 'primeng/utils';

@Component({
  selector: 'app-cashflow-grid',
  templateUrl: './cashflow-grid.component.html',
  styleUrls: ['./cashflow-grid.component.css']
})
export class CashflowGridComponent implements OnInit, OnChanges {

  @Input() value: any[] = [];
  @Input() columns: any[] = [];
  @Input() icon: boolean = false;
  @Input() showChkBox: boolean = true;
  @Input() selectedRows: any[] = [];
  @Input() action: boolean = false;
  @Input() filters: any[] = [];
  @Output() onSelectedRows = new EventEmitter();
  @Output() onAttachmentClick = new EventEmitter();
  @Output() onClickUniqueKey = new EventEmitter();
  @ViewChild('cashFlowGrid', {static: true}) table;

  constructor(public commonService: CommonService) {
  }

  ngOnInit() {
    FilterUtils['notContainsValue'] = function gte(value: any, filter: any[]): boolean {
      if (filter === undefined || filter === null) {
        return false;
      }
      if (value === undefined || value === null || value.length === 0) {
        return false;
      }
      return !filter.includes(value);
    };
    if(this.filters !== undefined && this.filters !== null && this.filters.length > 0){
      this.applyFilter(this.filters);
    }
  }

  //To get the Class of Buy (Red Color Pill) or Sell (Green Color Pill)
  getBuySellClass(rowDatum: any) {
    return rowDatum ? 'buyClass' : 'sellClass';
  }

  //To get the value of Trade Type since the field is Boolean
  getTradeTypeValue(rowDatum: any) {
    return rowDatum ? 'Buy' : 'Sell';
  }

  getClassForHeading(index) {
    return index > 0 ? 'headerNormal' : 'noPipe';
  }


  throwUniqueKey(rowData) {
    this.onClickUniqueKey.emit(rowData);
  }


  ngOnChanges(changes: SimpleChanges): void {
    if(changes['filters']){
      this.applyFilter(changes['filters'].currentValue);
    }
  }

  onSelectedChange(row,unselected) {
    // this.onSelectedRows.emit(this.selectedRows);
    row['unselected'] = unselected;
    this.onSelectedRows.emit(row);
  }

  getStyleIfPossible(col: any) {
    let styleOBJ = {};
    if (col['style']) {
      styleOBJ = col['style'];
      return styleOBJ;
    } else {
      return {};
    }

  }

  onClickAttachment(rowData) {
    this.onAttachmentClick.emit(rowData);
  }

  getAmount(rowData) {
    return rowData['financialAmount'] + ' ' + rowData['settlementCurrency'];
  }

  getColValue(rowData, col: any) {
    if (col.valueFunction !== undefined && col.valueFunction !== null) {
      return this.commonService.runFunction(rowData, col.valueFunction);
    }
    if (col.type !== undefined && col.type === 'date') {
      return this.commonService.getDateForOutput(rowData[col.field])
    }
    return rowData[col.field];
  }

  private applyFilter(currentValue: any[]) {
    let _this = this;
    currentValue.forEach(function(value: any) {
      _this.table.filter(value['firstValue'],value['columnName'],value['matchType'])
    })
  }
}
