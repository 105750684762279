<div *ngIf="showToolbar" class="toolbar">
  <div style="display: inline-flex">
    <ctrm-button (onClick)="refreshGridData()" [disabled]="fetching" *ngIf="showRefresh"
                 icon="{{fetching?'fas fa-redo fa-spin':'fas fa-redo'}}" label="Refresh"></ctrm-button>
    <ctrm-autocomplete
      (filters)="applyFilterGlobal($event)"
      (value)="updateValue($event)"
      *ngIf="addSearch" [columns]="columns"
      [endCharacter]="'Enter'"
      [placeholder]="'Global search'"
      [placeholder]="'Search '"
      [startCharacter]="'@'"
      style="margin-left: 5px">
    </ctrm-autocomplete>
    <div class="float-right">
      <ctrm-button (onClick)="export()" [disabled]="selectedRow.length===0" icon="fas fa-file-export"
                   label="Export"></ctrm-button>
    </div>
  </div>
</div>
<p-table #dt (onRowSelect)="onSelectionChangeRow()"
         (onRowUnselect)="onSelectionChangeRow()"
         [(selection)]="selectedRow"
         [columns]="columns"
         [dataKey]="primaryKey"
         [metaKeySelection]="true"
         [reorderableColumns]="reorderableGrid"
         [responsive]="true"
         [rowHover]="true"
         [style]="setHeight?{maxHeight:gridHeight,minHeight:gridHeight}:{}"
         [value]="data"
         autoLayout="true"
         class="style-4 table-responsive">
  <ng-template pTemplate="header">
    <tr [style.backgroundColor]="tableStyle.backgroundColor" class="sticky-top">
      <th *ngIf="showCheckbox" [style.backgroundColor]="tableStyle.backgroundColor" class="checkBoxCol stickLeftHeader">
        <p-tableHeaderCheckbox (click)="onSelectionChangeRow()"></p-tableHeaderCheckbox>
      </th>
      <th *ngIf="canShowExpandedGrid()">

      </th>
      <th *ngFor="let col of columns;index as i" [id]="'header'+i" [ngStyle]="getStyle(col,i,'400')"
          [pReorderableColumnDisabled]="leftFixedColumns.includes(col.getField()) || rightFixedColumns.includes(col.getField())"
          [pSortableColumn]="col.getField()"
          [style.backgroundColor]="tableStyle.backgroundColor"
          class="headerStyle stk" pReorderableColumn>
        <div class="headerDiv">
          <p class="headerLabel">{{col.getHeader()}}</p>
          <p-sortIcon [field]="col['field']" [style]="{marginTop:'4px'}" ariaLabel="Activate to sort"
                      ariaLabelAsc="Activate to sort in ascending order"
                      ariaLabelDesc="Activate to sort in descending order"></p-sortIcon>
        </div>
      </th>
      <th *ngIf="canDeleteRow"></th>
    </tr>
  </ng-template>
  <ng-template let-expanded="expanded" let-ri="rowIndex" let-rowData pTemplate="body">
    <tr [pSelectableRow]="rowData">
      <td *ngIf="showCheckbox" class="checkBoxCol stickLeftBody">
        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
      </td>
      <td *ngIf="canShowExpandedGrid()">
        <p style="margin: 0">
          <a [pRowToggler]="rowData" href="#">
            <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" class="togglerIcon"></i>
          </a>
        </p>
      </td>
      <td *ngFor="let col of columns;index as pos" [hidden]="!col.isVisible()" [id]="'body'+pos"
          [ngClass]="getFixedClass(col)" [ngStyle]="getStyle(col,pos,'390')" [pReorderableRow]="ri"
          class="ui-resizable-column tdStyle">
        <div style="margin-left: 50px" *ngIf="fieldsWithCustomView.includes(col.getField())">
          <ng-container [ngTemplateOutlet]="customViewTemplated[fieldsWithCustomView.indexOf(col.getField())]"
                        [ngTemplateOutletContext]="{rowData:rowData,column:col,index:ri,expandedRowData:expandedRowdata}"></ng-container>
        </div>
        <div *ngIf="!fieldsWithCustomView.includes(col.getField())">
          <span *ngIf="!col.isEditable()"
                class="spanClass">
          <div (click)="clickableColumns.includes(col['field'])?clickable(col,rowData):''"
               [innerHTML]="getOutputValue(col,ri,rowData)" [ngClass]="clickableColumns.includes(col.getField())?'primaryKey':''"
               style="white-space: nowrap;"></div>
        </span>
          <span *ngIf="col.isEditable()"
                class="spanClass">
          <div *ngIf="col.getColumnType().toUpperCase()==='N'">
            <div class="inputbox">
              <input [(ngModel)]="rowData[col.getField()]"
                     [ngClass]="'form-control-normal'"
                     [type]="'number'"
                     (focusout)="onModelChange(rowData,col)"
                     class="form-control form-control-sm noSpinner" ctrmRestrictDecimal
                     onkeydown="return event.keyCode !== 69"
                     pInputText
                     placeholder="{{col.getHeader()}}">
            </div>
          </div>
             <div *ngIf="col.getColumnType().toUpperCase()==='T'">
            <div class="inputbox">
              <input [(ngModel)]="rowData[col.getField()]"
                     [ngClass]="'form-control-normal'"
                     [type]="'text'"
                     (focusout)="onModelChange(rowData,col)"s
                     class="form-control form-control-sm noSpinner"
                     pInputText
                     placeholder="{{col.getHeader()}}">
            </div>
          </div>
              <div *ngIf="col.getColumnType().toUpperCase()==='B'">
            <div class="inputbox">
             <button (click)="onbuttonClick(rowData,col)">{{col.getHeader()}} </button>
            </div>
          </div>
        </span>
        </div>
      </td>
      <td *ngIf="canDeleteRow">
        <i (click)="rowDelete(ri)" [pTooltip]="'Delete'" class="far fa-times-circle iconClassDelete"></i>
      </td>
    </tr>
  </ng-template>
  <ng-template let-columns="columns" let-rowData pTemplate="rowexpansion">
    <tr *ngIf="canShowExpandedGrid()">
      <td [colSpan]="getColSpanExpand(columns.length)">
        <div class="planningInfoContainer" style="margin-left: 30px">
          <xceler-grid [expandedRowdata]="rowData"
                       [expandedGrid]="expandedGrid.expandGrid"
                       [selectedRow]="expandedGrid.expandGrid.selectedRows"
                       [showPlaceHolder]="true"
                       (onSelectionChange)="onExandexRowSelected($event,expandedGrid.expandGrid)"
                       [primaryKey]="expandedGrid.expandGrid.getPrimaryKey()"
                       [showCheckbox]="expandedGrid.expandGrid.showCheckbox"
                       [setHeight]="false"
                       [columns]="convertList(expandedGrid.expandGrid.getColumn().values())">
          </xceler-grid>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="footer">
    <tr class="sticky-bottom">
      <td *ngFor="let col of footerColumns" [colSpan]="col['colSpan']" >
        <span class="spanClass">
          <div [innerHTML]="getOutputValueForFooter(col)" style="white-space: nowrap;"></div>
        </span>
      </td>
    </tr>
  </ng-template>
</p-table>
<p-paginator *ngIf="getPagination()" [rows]="pageSize" [totalRecords]="totalElements" (onPageChange)="onPageSwitch($event)"></p-paginator>

<div #ph *ngIf="showPlaceHolder && (data === undefined ||data === null || data.length === 0)" [style]="{maxHeight:placeHolderHeight}"
     class="placeHolder">
  <ng-container *ngTemplateOutlet="noDataTemplate !== undefined?noDataTemplate:defualtTemplateNoData"></ng-container>
</div>
<ng-template #defualtTemplateNoData>
  <div class="noDataBox">
    <img [src]="commonService.getIconPath('emptyStateIcon')" class="iconClass"/>
    <p class="noDataHeader">Data is not available</p>
  </div>
</ng-template>
