import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'future-trade-info-box',
  templateUrl: './future-trade-info-box.component.html',
  styleUrls: ['./future-trade-info-box.component.css']
})
export class FutureTradeInfoBoxComponent implements OnInit {

  @Input() futureInfoDisplay: boolean;
  @Output() onClose = new EventEmitter();
  @Input() value:any;
  @Input() header:any;

  constructor() { }

  ngOnInit(): void {
  }

  onCancel() {
    this.onClose.emit();
  }
}
