import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'role-accordian',
  templateUrl: './role-accordian.component.html',
  styleUrls: ['./role-accordian.component.css']
})
export class RoleAccordianComponent implements OnInit, OnChanges,AfterViewInit {
  @Input() disable: boolean = false;
  @Output() screenObjectOutput = new EventEmitter();
  @Input() expand: boolean = false;
  @Input() maxHeight: string = 'fit-content';
  @Output() onExpand = new EventEmitter();
  @Output() onCollapse = new EventEmitter();
  @Output() onCollapseExpandDone = new EventEmitter();
  contentHeight: string = '0px';
  namesList: string[] = [];
  colorList: any = {};
  @Input() searchValue: string = '';
  @Input() data: any = {};
  expanded:boolean = false;
  childKeys: any[] =[];
  toggleConfig:any = {};

  constructor(private changeDetectorRef:ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  getAccesEnableCount(child):number {
    let _this = this;
    let keys = Object.keys(this.data[child]);
    let accessEnableCount = 0;
    keys.forEach(function (key) {
      if (_this.data[child][key]) {
        accessEnableCount++;
      }
    });
    return accessEnableCount;
  }


  getColorCode(name) {
    let keys = Object.keys(this.data[name]);
    let accessEnableCount = this.getAccesEnableCount(name);
    let colorCode = '';
    if (accessEnableCount === keys.length) {
      colorCode = '#64BD63'; // All are selected
    } else if (accessEnableCount > 0 && accessEnableCount < keys.length) {
      colorCode = '#ffd95d'; // partially Selected
    }
    return colorCode;
  }


  throwValue() {
    this.screenObjectOutput.emit(this.data);
  }

  expandContent() {
    if (this.contentHeight === '0px') {
      this.expand = true;
      this.contentHeight = this.maxHeight;
      this.onExpand.emit();
    } else {
      this.expand = false;
      this.contentHeight = '0px';
      this.onCollapse.emit();
    }
    this.throwValue();
  }

  decamelize(str, separator) {
    separator = typeof separator === 'undefined' ? '_' : separator;

    return str
      .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
      .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
      .toLowerCase();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['expand'] && changes['expand'].currentValue !== undefined && changes['expand'].currentValue !== null) {
      if (changes['expand'].currentValue) {
        this.contentHeight = this.maxHeight;
        this.expand= true;
      } else {
        this.expand = false;
        this.contentHeight = '0px';
      }
      this.onCollapseExpandDone.emit();
      this.throwValue();
    }
    if (changes['data']) {
      let _this = this;
      this.childKeys = Object.keys(changes['data'].currentValue);
      let tempKeys:any[] = [...this.childKeys];
      this.childKeys.forEach(function (childKey) {
        if(childKey !== 'info') {
          _this.toggleConfig[childKey] = {color:_this.getColorCode(childKey),checked:(_this.getAccesEnableCount(childKey) > 0)}
        }
        if(Object.keys(_this.data[childKey]).length === 0) {
          tempKeys.splice(tempKeys.indexOf(childKey),1);
        }
      });
      tempKeys.splice(tempKeys.indexOf("info"),1);
      this.childKeys = tempKeys;
    }
  }

  getKeys(childElement: any) {
    let keys: any[] = Object.keys(this.data[childElement]);
    return keys;
  }

  getChecked(name: string) {
    return true;
  }

  onChange(value: any, ckey: any) {
    let _this = this;
    let keys = Object.keys(this.data[ckey]);
    keys.forEach(function (key) {
      _this.data[ckey][key] = value;
    });
  }

  checkAndThrow(childKey) {
   this.toggleConfig[childKey] = {color:this.getColorCode(childKey),checked:(this.getAccesEnableCount(childKey) > 0)}
    this.throwValue();
  }

  contains(key, searchValue: string) {
    let contains: boolean = false;
    // list.forEach(function (name: string) {
    //   if (!contains && name.toLowerCase().includes(searchValue.toLowerCase())) {
    //     contains = true;
    //   }
    // });
    return !contains;
  }
}
