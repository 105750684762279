import {Grid} from '../grid/grid.model';
import {TemplateRef} from '@angular/core';

export class Tab {

  mainUrl: string;

  private _tabTemplate: TemplateRef<any>;


  public constructor(
    private label?: string,
    private grid?: Grid,
    private parentPrimaryKey?: any,
    private extra?: any,
    private _openInForm: boolean = false,
    private _formFetchUrl: string = '',
    private _formContentStyle: any = {},
    private _formOnModal: boolean = true,
    private _noDataLabel: string = "No Data Available",
    private _idFieldValueName: string = ''
  ) {
    if (grid !== undefined && grid !== null) {
      this.mainUrl = grid.getDataURL();
    }
  };


  get openInForm(): boolean {
    return this._openInForm;
  }

  set openInForm(value: boolean) {
    this._openInForm = value;
  }

  get formFetchUrl(): string {
    return this._formFetchUrl;
  }

  set formFetchUrl(value: string) {
    this._formFetchUrl = value;
  }

  get formContentStyle(): any {
    return this._formContentStyle;
  }

  set formContentStyle(value: any) {
    this._formContentStyle = value;
  }

  get formOnModal(): boolean {
    return this._formOnModal;
  }

  set formOnModal(value: boolean) {
    this._formOnModal = value;
  }

  get noDataLabel(): string {
    return this._noDataLabel;
  }

  set noDataLabel(value: string) {
    this._noDataLabel = value;
  }

  public getLabel() {
    return this.label;
  }

  public getGrid() {
    return this.grid;
  }

  public getParentPrimaryKey() {
    return this.parentPrimaryKey;
  }

  public updateDataUrl(newid) {
    this.grid.setDataURL(this.mainUrl + newid);
  }

  public getFromExtra(key: any) {
    return this.extra[key];
  }

  public setToExtra(key: any, value: any) {
    this.extra[key] = value;
  }


  get idFieldValueName(): string {
    return this._idFieldValueName;
  }

  set idFieldValueName(value: string) {
    this._idFieldValueName = value;
  }

  public getTabTemplate() {
    return this._tabTemplate;
  }
  public setTabTemplate(template: TemplateRef<any>) {
    return this._tabTemplate = template;
  }
}
