<ctrm-grid *ngIf="loadListData !== undefined && loadListData.length != 0"
           [defaultCopy]="false" (onClickAdd)="onClickAdd()" (onClickEdit)="openInUpdateMode($event)"
           (onSave)="onSave($event)"
           (onClickDuplicate)="onDuplicate($event)" [noDataTemplate]="noData"
           [breadcrumbMapKey]="commonService.MASTER_MAP_KEY" [currentRoute]="'/internalPackaging'" [defaultEdit]="false" [grid]="grid"
           [refreshGrid]="refreshGrid" [saveOnSave]="false" [showActions]="false"></ctrm-grid>

<p-sidebar *ngIf="internalPackDisplay" [(visible)]="internalPackDisplay" [showCloseIcon]="false"
           [style]="{minWidth:'50%',maxWidth:'65%',height:'100%', marginTop:'61px'}"
           position="right" [baseZIndex]="5" [ngStyle]="{maxHeight: height}"
           autoLayout="true" class="style-4 table-responsive">
  <form [formGroup]="internalForm">
  <div class="headerClass">
    Internal Packaging
    <i class="material-icons closable" (click)="close()">clear</i>
  </div>
  <div class="formBox"></div>

  <div class="sectionForm style-4" [id]="'sectionForm'">
    <div class="validContain">
      <div class="sectionBoxMain">
        <div class="formSectionTitle">
          <div class="headerTitle">Internal Packaging Information</div>
        </div>
        <div class="firstLine">
          <div class="fieldBox" style="margin-right: 20px;">
            <label class="label ng-star-inserted">Name</label>
            <input id="name" type="text"
                   (input)="commonService.updateInputValidation($event,includedCharacters,true)"
                   [(ngModel)]="name"
                   class="form-control form-control-sm input"
                   pInputText placeholder="Internal Package Name"
                   formControlName="name">
          </div>
          <div class="fieldBox">
            <label class="label ng-star-inserted">Capacity</label>
            <input id="unitSize" type="number"
                   (input)="commonService.updateInputValidation($event,includedCharacters,true)"
                   [(ngModel)]="capacity"
                   class="form-control form-control-sm input"
                   pInputText placeholder="Capacity"
                   formControlName="capacity">
          </div>
        </div>

        <div class="secondLine">
          <div class="fieldBox" style="margin-right: 20px;">
            <label class="label ng-star-inserted">Capacity Uom</label>
            <p-dropdown [(ngModel)]="capacityUom"
                        [disabled]="false"
                        [id]="'capacityUom'"
                        [options]="capacityUomList"
                        appendTo="body"
                        tooltipEvent="focus" tooltipPosition="bottom"
                        tooltipZIndex="1000"
                        formControlName="capacityUom">
            </p-dropdown>
          </div>
          <div class="fieldBox">
            <label class="label ng-star-inserted">Status</label>
            <div>
              <ui-switch [(ngModel)]="status"
                         [checkedLabel]="'Active'"
                         [checkedTextColor]="'#fff'"
                         [defaultBgColor]="'#d8d8d8'"
                         [uncheckedLabel]="'Inactive'"
                         [checked]=true formControlName="status"></ui-switch>
            </div>
          </div>
        </div>
      </div>

      <div class="valContainer">
        <div class="validation">
          <div style="display: flex; padding-bottom: 10px;">
            <img *ngIf="nameValid()" alt="" src="../../../assets/images/svg/validTick.svg">
            <img *ngIf="!nameValid()" alt="" src="../../../assets/images/svg/invalidCross.svg">
            <p class="valPoints">Internal Package Name</p>
          </div>
          <div style="display: flex; padding-bottom: 10px;">
            <img *ngIf="capacityValid()" alt="" src="../../../assets/images/svg/validTick.svg">
            <img *ngIf="!capacityValid()" alt="" src="../../../assets/images/svg/invalidCross.svg">
            <p class="valPoints">Capacity</p>
          </div>
          <div style="display: flex; padding-bottom: 10px;">
            <img *ngIf="capacityUomValid()" alt="" src="../../../assets/images/svg/validTick.svg">
            <img *ngIf="!capacityUomValid()" alt="" src="../../../assets/images/svg/invalidCross.svg">
            <p class="valPoints">Capacity Uom</p>
          </div>
        </div>
      </div>
    </div>


  <div class="sectionBoxMain">
      <div class="formSectionTitle">
        <div class="subHeading">Add Internal - External Mapping </div>
      </div>
    <div class="externalGrid">
      <app-ctrm-price-table *ngIf="loadListData !== undefined && loadListData.length != 0"
                            (onAddNewRowComplete)="onNewRowAddComplete()"
                            (onRefreshFormGroupComplete)="onRefreshFromGroupComplete()"
                            [columns]="externalCols" [gridDisplay]="true"
                            [refreshFormGroup]="refreshFormGroup"
                            [newRows]="newRows" [refreshNewRows]="refreshAddNewRow"
                            [value]="externalValue"
                            [showNewEntryIcon]="true">
      </app-ctrm-price-table>
    </div>

  </div>
  </div>


  </form>
  <p-footer>
    <div class="footerClass saveSection">
      <div style="display: inline-flex">
        <button (click)="close()" class="cancelBtn" label="Cancel" pButton type="button"></button>
        <button (click)="save()" [disabled]="!internalForm.valid" class="save">{{isUpdating?'Update':'Save'}}</button>
        <ng-container *ngTemplateOutlet="infoUpdate"></ng-container>
      </div>
    </div>
  </p-footer>
</p-sidebar>

<ng-template #infoUpdate>
  <div *ngIf="infoDisplay">
    <div class="lastUpdateInfoOnForm">
      <div style="display: flex;flex-direction: row">
        <p>Created By : {{createdBy}}</p>
        <p>Created On : {{createdDate}}</p>
      </div>
      <div style="display: flex;flex-direction: row">
        <p>Last Modified By : {{updatedBy}}</p>
        <p>Last Modified On : {{updatedDate}}</p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #noData>
  <div (inViewportAction)="calculateLeft()" [id]="'noDataBoxId'" [style]="{left:noDataBoxLeft}" class="noDataBox"
       inViewport>
    <img [src]="commonService.getIconPath('emptyStateIcon')" class="iconClass"/>
    <p class="noDataHeader">{{showFetching || isError ? loadMessage : noDataLabel}}</p>
    <div class="buttonsBox">
      <div (click)="onClickAdd()" *ngIf="grid.getGridConfig().canAdd" class="noDataButton">
        <p class="noDataButtonLabel">{{'Add ' + grid.getTitle()}}</p>
      </div>
    </div>
  </div>
</ng-template>

<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body">
</p-toast>
