import {Inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {Observable} from 'rxjs';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class Interceptor implements HttpInterceptor {


  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {

  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // add JWT auth header if a user is logged in for API requests
    const accessToken = localStorage.getItem('token');
    //const isApiUrl = request.url.startsWith(environment.base_url);
    if (accessToken ) {
      request = request.clone({
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' +  accessToken
        })
      });
    }
  // request = this.handleSpecialCharURL(request);
    if(window.location.origin.includes('localhost')) {
      request = this.reRoute(request);
    }
    return next.handle(this.handleSpecialCharURL(request));
  }

  private handleSpecialCharURL(request:HttpRequest<any>) {
    let url = request.url;
    let pattern = url.split("?");
    if(pattern.length > 0 && pattern[1] !== null && pattern[1] !== undefined) {
      let splitPart = pattern[1].split("&");
      let newSplit = [];
      splitPart.forEach((i,index) => {
        if(!i.includes("=") && index > 0) {
          newSplit[newSplit.length-1] += "&"+ i;
        } else {
          newSplit[newSplit.length] = i;
        }
      })
      let url = pattern[0]+"?";
      newSplit.forEach((splitPart,index) => {
        let spl = splitPart.split("=");
        url += ((index != 0)?"&":"") +spl[0]+"="+ encodeURIComponent(spl[1]);
      })
      return request.clone({'url': url});
    }
    return request;
  }


  private reRoute(request:HttpRequest<any>):HttpRequest<any> {
    let route = request.url;
    if(route.includes("//localhost/")) {
      let json:{[key:string]:any} = {
        'ctrm-api':'http://localhost:8080',
        'api-iam':'http://localhost:8082',
        'api-bm':'http://localhost:8083',
        'integration-service':'http://localhost:8084',
        'approval-workflow':'http://localhost:8085'
      }
      let url = route.replace("http://localhost","");
      let split = url.split("/");
      if(Object.keys(json).includes(split[1])) {
        route = json[split[1]]+url;
      } else {
        route = "http://localhost:8080"+url;
      }
    }
    return request.clone({'url': route})
  }
}
