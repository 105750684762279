<div class="sectionCardTop">
  <div class="breadcrumbPane">
    <ctrm-breadcrumb [collapsabel]="false"
                     [items]="breadCrumbService.getBreadCrumb()"
                     [showIcon]="false"></ctrm-breadcrumb>
  </div>
  <div class="actionPane">
    <div class="screenDetails">
      <img [src]="'../../../assets/images/svg/tradePlanning.svg'"/>
      <p>Future Netting</p>
    </div>
<!--    <div class="autocomplete-box">-->
<!--      <input (input)="getFilterValue($event)" placeholder="Search" type="text">-->
<!--      <mat-icon style="color:#cbcbcb">search</mat-icon>-->
<!--    </div>-->
  </div>
</div>
<div class="sectionCardTop" style="max-height: 100% !important;" [formGroup]="filterForm">
  <div class="selectButtonClass">
<!--    <p-selectButton (onOptionClick)="onTabChange($event)" [(ngModel)]="tabValue"-->
<!--                    [options]="tabOptions"></p-selectButton>-->
    <div  class="fieldSection">
      <div class="row fieldCont">

        <div class="col-md-2   fieldBox">
          <p class="fieldName">Broker</p>
          <p-dropdown  formControlName="clearingBroker" [options]="brokerList"></p-dropdown>
        </div>
        <div class="col-md-2 fieldBox">
          <p class="fieldName">Future Index</p>
          <p-dropdown [options]="indexList" formControlName="futureIndex" (onChange)="onChangeFutureIndex($event)"></p-dropdown>
        </div>
        <div class="col-md-2 fieldBox">
          <p class="fieldName">Ticker</p>
          <p-dropdown [options]="tickerList" formControlName="ticker"></p-dropdown>
        </div>
        <div class="col-md-2 fieldBox">
          <p class="fieldName">Profitcenter</p>
          <p-dropdown [options]="profitCenterList" formControlName="profitcenter"></p-dropdown>
        </div>
        <div >
          <button (click)="clearAllFilter()" class="clearAll" style="padding-top: 20px">
            Clear All
          </button>
        </div>

    </div>
    </div>

    <div class="autoNettingSwitchDiv">
      <ui-switch [checkedTextColor]="'#fff'" [size]="'small'"
                 (change)="onAutoNettingToggleChange($event)"
                 [defaultBgColor]="'#d8d8d8'">
      </ui-switch>
      <p class="autoNettingText">Enable auto future netting option</p>
    </div>
  </div>

    <p-dialog
            [(visible)]="opendialogfordatepicker"
            [draggable]="false"
            [closeOnEscape]="false"
            [closable]="false"
            [resizable]="false"
            [modal]="true"
            [baseZIndex]="1001"
            [style]="{width: '25vw'}">
      <p-header>
          <div class="divBox">
            <p class="header">Select Netting-Date </p>
            <p class="description"></p>
          </div>
      </p-header>
      <div class="inputbox">
        <input [(bsValue)]="nettedDate"
               [bsConfig]="bsConfig"
               [maxDate]="date"
               [minDate]="minDate"
               autocomplete="off"
               bsDatepicker class="form-control form-control-sm"
               placeholder="Select Date"
               type="text">
      </div>

      <p-footer>
        <div style="display: flex;justify-content: center;align-items: center; column-gap: 10px;  ">
          <button class="cancelBtn" (click)="canceldialog()">Cancel</button>
<!--          <button  class="continuebutton" [disabled]="" (click)="continue()">continue</button>-->
          <xceler-progress-button
                  (onError)="onError($event)"
                  (onLoadDone)="onSubmit($event)"
                  [buttonIdleTitle]="'Continue'"
                  [buttonLoadingTitle]="'Loading'"
                  [buttonStyle]="{    borderRadius: '0.2rem',marginTop:'0.5rem',justifyContent: 'center', padding: '0.6rem 0.9rem',
                                        color:'#fff', border:'#fff',  backgroundColor:'#ffa500'}"
                  [callApiObjectFunction]="matchingApiFunction">
          </xceler-progress-button>
        </div>
      </p-footer>
    </p-dialog>


  <div *ngIf="tabValue === 'future'">
    <div style="width: 98%;margin-left: 1%">
      <xceler-expandable-planning-grid
        (onClickSubmit)="onclick($event)"
        [readOnly]="readOnly"
        [leftBalanceFieldName]="'balanceLot'"
        [leftCategorizeBy]="categorizedBy"
        [leftMatchFieldName]="'matchLot'"
        [leftMatchHeader]="'Match Lots'"
        [leftTableHeader]="'Long'"
        [leftTableMetadata]="leftTabelMeta"
        [leftValueTotalFormat]="leftFormat"
        [leftValueTotalLots]="leftFormatLots"
        [rightValueTotalLots]="leftFormatLots"
        [leftValues]="leftValues"
        [matchLotTotalUpdate] = "matchTotalValueSetZero"
        (triggerMatchedupdateLot)="matchTotalValueSetZero=false"
        [matchingButton]="'Future Netting'"
        [rightBalanceFieldName]="'balanceLot'"
        [rightCategorizeBy]="categorizedBy"
        [rightMatchFieldName]="'matchLot'"
        [rightMatchHeader]="'Match Lots'"
        [rightTableHeader]="'Short'"
        [rightTableMetadata]="rightTableMeta"
        [rightValueTotalFormat]="leftFormat"
        [rightValues]="rightValues"></xceler-expandable-planning-grid>
    </div>
  </div>
  <div *ngIf="tabValue === 'netting'">
    <div class="planningPanel">
      <div class="toolbar">
        <div>
          <ctrm-button (onClick)="refresh()" [disabled]="isRefreshing"
                       icon="{{isRefreshing?'fas fa-redo fa-spin':'fas fa-redo'}}" label="Refresh"></ctrm-button>
          <div class="float-right">

            <ctrm-button
              [disabled]="selectedFutures === null || selectedFutures.length === 0 || selectedFutures.length > 1"
              [label]="'Delete'"
              icon="fas fa-trash"></ctrm-button>
            <ctrm-button
              [disabled]="selectedFutures === null || this.selectedFutures.length === 0 || this.selectedFutures.length > 1"
              icon="fas fa-file-export" label="Export"></ctrm-button>
            <ctrm-button
              [disabled]="selectedFutures === null || this.selectedFutures.length === 0 || this.selectedFutures.length > 1"
              icon="fas fa-print"
              label="Print"></ctrm-button>
            <ctrm-button icon="fas fa-sliders-h" label="Preferences"></ctrm-button>
          </div>
        </div>
      </div>
      <div>
        <p-table #netting (inViewportAction)="calculateHeight(netting)"
                 (onRowExpand)="openNettingRow($event)" [(selection)]="selectedFutures"
                 [autoLayout]="true"
                 [columns]="nettingColumns"
                 [expandedRowKeys]="expandedRows"
                 [style]="{width:'100%',minHeight:futureHeight, maxHeight:futureHeight}"
                 [value]="nettingData"
                 class="style-4 table-responsive"
                 dataKey="uuid" inViewport selectionMode="single">

          <ng-template let-columns pTemplate="header">
            <tr>
              <th class="stk" style="width: 3em">
                <p
                  style="margin:0;background: #D9DBDE;padding: 5px;padding-top: 11px; padding-bottom: 10px; text-align: center;">
                </p>
              </th>
              <th *ngFor="let col of columns;index as i" class="stk">
                <div style="background-color: #D9DBDE; padding: 7px">
                  <p [ngClass]="getClassForHeading(i)">{{col.header}}</p>
                </div>
              </th>
            </tr>
          </ng-template>
          <ng-template let-columns="columns" let-expanded="expanded" let-ri="rowIndex" let-rowData pTemplate="body">
            <tr [pSelectableRow]="rowData">
              <td class="customRow" style="width:3em;" style="display:inline-flex">
                <p class="plannedGridChkBox">
                  <p-tableCheckbox (click)="selectRow()" [value]="rowData"></p-tableCheckbox>
                </p>
                <p style="margin:0;margin-left: 5px;margin-top:10px">
                  <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" [pRowToggler]="rowData"
                     class="togglerIcon"></i>
                </p>
              </td>

              <td *ngFor="let col of columns; index as i" class="customRow">
                <p *ngIf="col.field !== 'matchType'" class="plannedGrid">
                  {{commonService.getRowValue(rowData, col)}}
                </p>


                <p *ngIf="col.field === 'matchType'" class="matchAlgoStyle">
                  {{commonService.getRowValue(rowData, col)}}
                </p>

          <div *ngIf="col.field === 'nettingId'" style="display: flex">
            <p  class="plannedGrid" style="padding-top: 2px">{{commonService.getRowValue(rowData,col)}}</p>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template let-columns="columns" let-rowData pTemplate="rowexpansion">
      <tr>
        <td [colSpan]="columns.length + 1">
          <div class="planningInfoContainer">
            <app-planning-info [columns]="futureTradeColumns"
                               [primaryKeyClickable]="false" [showChkBox] = "false"
                               [value]="rowData['netting']"
                               (onClickClickable)="onClickInfo($event)"
                               [clickableFields]="['tradeId']"></app-planning-info>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr *ngIf="(nettingData === undefined ||nettingData === null || nettingData.length === 0)">
        <td [colSpan]="nettingColumns.length + 2">
          <div class="placeHolder">
            <div (inViewportAction)="calculateLeft()" [style]="{left:noDataBoxLeft}" class="noDataBox" inViewport>
              <img [src]="commonService.getIconPath('emptyStateIcon')" class="iconClass"/>
              <div class="noPlans">{{loadingMessage}}</div>
              <div *ngIf="!isRefreshing">
                <!--                <button (click)="goTo()" class="planScreenBtns" style="margin-top: 0px !important;">-->
                <!--                </button>-->
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>

        </p-table>
      </div>
    </div>
  </div>
</div>
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>
<app-future-info (onClose)="futureClose($event)" *ngIf="futuredisplay" [display]="true" [futuretradeId]="tradeId"
                 [sidebarPosition]="'right'"></app-future-info>

<!--SUMMARY DIALOG-->
<p-dialog [(visible)]="summaryDisplay" [draggable]="false" [modal]="true" [closable]="false"
          [resizable]="false" [style]="{width: '35vw'}">
  <p-header>
    <div style="display: flex; padding-left: 16px">
<!--      <i class="far fa-check-square headerIconStyle"></i>-->
      <p class="matchHeader">Overall PnL</p>
    </div>
  </p-header>
  <div style="margin: 20px; margin-left: 40px; height: 250px ">
<!--    Loading DIV-->
    <div class="loadingDiv" *ngIf="summaryDetails.length === 0">
      <img class="loadingIconClass" src="../../../assets/images/doubleRingLoading.gif" alt="">
      <p class="loadingMsg">This may take a while if there are high number of futures being netted</p>
    </div>

    <div *ngIf="summaryDetails.length > 0">
      <div>
        <p-table [columns]="summaryCols" [value]="summaryDetails">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns">
                {{col.header}}
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td *ngFor="let col of columns">
                <p [ngClass]="getSummaryGridClass(col.field, rowData)">
                  {{rowData[col.field]}}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <div class="summaryFooter">
        <div class="summaryBtnContainer">
            <button (click)="toggleSummaryDialog('confirm')" class="planScreenBtns">Confirm</button>
            <button (click)="toggleSummaryDialog('cancel')" class="cancelBtn">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
