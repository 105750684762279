<ng-container>
    <form [formGroup]="filterSection" style="display: flex;column-gap:2rem; align-content: center" >
        <div> Filter By :</div>
        <div>
            <label>Company</label>
            <select formControlName="selectedCompany" class="dropDown-select">
                <option value="" [selected]="true">Select Company</option>
                <option *ngFor="let list of companyMaster" style="padding: 1rem;">{{list}}</option>
            </select>

        </div>
        <div>
            <label>From Trade Date</label>
             <input type="date" formControlName="selectedDateFrom" class="dropDown-select" [max]="todayDate">
        </div>
        <div>
            <label>To Trade Date</label>
             <input type="date" formControlName="selectedDateTo" class="dropDown-select" [min]="filterSection.get('selectedDateFrom').value" [max]="todayDate">
        </div>
        <div>
            <xceler-progress-button
                    (onLoadDone)="onSubmit($event)"
                    [buttonIdleTitle]="'Apply'"
                    [buttonLoadingTitle]="'Processing'"
                    [buttonStyle]="{    borderRadius: '0.2rem',marginTop:'2.5rem',justifyContent: 'center', padding: '0.8rem 1rem',
                                        color:'#ff8300', border:'1px solid #ff8300',  backgroundColor:' #fff1e6'}"
                    [callApiObjectFunction]="filterApiFunction">
            </xceler-progress-button>
<!--            <button (click)="filterFunction()" class="filter-button">Apply</button>-->
        </div>
        <div style="margin-left: 25%">
            <label>Total Count : </label>
            <span>{{totalCount}}</span>
        </div>
    </form>

</ng-container>

<xceler-grid [columns]="tradeColumns"
             [data]="tradeData"
             [selectedRow]="selectedRow"
             [noDataTemplate]="defualtTemplateNoData"
             [showToolbar]="true" [showCheckbox]="true"
             (onPageChange)="onPageChange($event)"
             [useDefaultExport]="false"
             (onExportClick)="generateMPOB($event)"
             [showRefresh]="false"
></xceler-grid>


<ng-template #defualtTemplateNoData>
    <div class="noDataBox">
        <img [src]="commonService.getIconPath('emptyStateIcon')" class="iconClass"/>
        <p class="noDataHeader">No data available </p>
    </div>
</ng-template>
