<div class="sliderDiv">
  <ctrm-slider (sliderValueChange)="onChangeLeft($event)"
               [(sliderValue)]="output['min']"
               [ceil]="ceilLeft"
               [floor]="floorLeft"
               [labelHighFormat]="labelHighFormatLeft" [labelLowFormat]="labelLowFormatLeft"
               [readOnly]="disabled"
               [rightToLeft]="true"
               [translateLabelInForm]="labelFormatLeft"
               class="leftSlider"
               style="width:50%">
  </ctrm-slider>
  <div class="divider"></div>
  <ctrm-slider (sliderValueChange)="onChangeRight($event)"
               [(sliderValue)]="output['max']"
               [ceil]="ceilRight"
               [floor]="floorRight"
               [labelHighFormat]="labelHighFormatRight" [labelLowFormat]="labelLowFormatRight"
               [readOnly]="disabled"
               [translateLabelInForm]="labelFormatRight"
               class="rightSlider"
               style="width:50%">
  </ctrm-slider>
</div>
