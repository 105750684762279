<div class="calenderpopup" appendTo="body">
    <div class="horizoantalsplit">
        <div class="leftSection">
            <div class="textbutton" *ngFor="let preset of preDefinedRanges" [ngClass]="selectedPreset === preset['title']?'selected':''" (click)="onSelectPreset(preset)">
                <p *ngIf="preset['type'] === null || preset['type'] === undefined || preset['type']=== 'DR'" class="presetTitle">{{preset['title']}}</p>
                <div *ngIf="preset['type']=== 'L'">
                    <p class="presetTitle">{{preset['title']}}</p>
                    <p-dropdown appendTo="body"
                                [(ngModel)]="preset['selectedValue']"
                                tooltipEvent="focus" tooltipPosition="bottom"
                                tooltipZIndex="1000"
                                [options]="preset['list']">
                    </p-dropdown>
                </div>

            </div>
            <div class="textbutton" [ngClass]="selectedPreset === dateRangePresetTitle?'selected':''" (click)="onSelectDateRange()">
                {{dateRangePresetTitle}}
            </div>
        </div>
        <div class="rightSection">
            <div class="topSection">
                <div class="selectedRange" *ngIf="getDateRangeDisplay()!=='null - null'" >{{getDateRangeDisplay()}}</div>
                <div class="selectedRange" *ngIf="getDateRangeDisplay()=='null - null'" >{{'Please select the Trade Date'}}</div>
            </div>
            <div class="bottomSection">
                <bs-daterangepicker-inline [(bsValue)]="rangeDates" (bsValueChange)="onDateRangeChange($event)"></bs-daterangepicker-inline>
                <div style="display: inline-flex;padding: 6px;justify-self: right">
                    <button (click)="cancel()"class="ui-button-raised mybuttonClose" label="Close"
                            pButton
                            style="margin-right: 15px;"
                            type="button"></button>
                    <button (click)="onClickApply()"
                            [tabIndex]="1"
                            [disabled]=buttondisable
                            class="ui-button-raised savebutton"
                            style="margin-right: 15px;" type="submit">Apply</button>
                </div>
            </div>
        </div>
    </div>
</div>

