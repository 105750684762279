import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[ctrmRestrictDecimal]'
})
export class RestrictDecimalDirective {
  constructor(element: ElementRef) {
    let input: HTMLInputElement = element.nativeElement;
    input.onkeypress = function (event) {
      if (event.keyCode === 46 && input.value.indexOf('.') >= 0) {
        event.preventDefault();
        return false;
      }
    }
  };


}
