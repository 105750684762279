export class multiSelectPojo {
  constructor(
    private label: string,
    private value: { id: '', name: '', code: '' },
  ) {
  }

  public getLabel() {
    return this.label;
  }

  public getValue() {
    return this.value;
  }

  public setLabel(newLabel: string) {
    this.label = newLabel;
  }

  public setValue(newValue: { id: '', name: '', code: '' }) {
    this.value = newValue;
  }

  public getId() {
    return this.value['id'];
  }

  public getName() {
    return this.value['name'];
  }

  public getCode() {
    return this.value['code'];
  }

  public setId(value) {
    this.value['id'] = value;
  }

  public setName(value) {
    this.value['name'] = value;
  }

  public setCode(value) {
    this.value['code'] = value;
  }

}
