<app-ctrm-grid-with-tabs *ngIf="loadDataList !== undefined && loadDataList.length != 0"
                         (heirarchyEmitter)="openHeirarcy($event)"
                         (onClickUniqueKey)="onUniqueKey($event)"
                         (onRowDataSave)="onRowDataSave($event)"
                         (onContextMenuIconClick)="contextmenu()"
                         [extraButtons]="grid.getExtraButtons()"
                         [breadcrumbMapKey]="commonService.MASTER_MAP_KEY"
                         [currentRoute]="'/counterparty'"
                         [openTabScreen]="openTabScreen"
                         (onTabScreenOpened)="onTabScreenOpened()"
                         [openTabForRow]="tabRow"
                         [openFormForNewEntry]="openFormForNewEntry"
                         (onFormOpenDone)="onFormOpned()"
                         [selectedTab]="selectedTab"
                         [grid]="grid"
                         (selectedRows)="onRowSelectionChange($event)"
                         [refreshGrid]="refreshGrid"
                         [showStandardFilter]="true"
                         [showFilterChips]=false>
</app-ctrm-grid-with-tabs>
<xceler-bottom-pane [popupDisplay]="popupDisplay" [rightViewTemplate]="right" (onClose)="onClose()"></xceler-bottom-pane>
<ng-template #right>
    <button (click)="approve()" class="planScreenBtns" style="margin: 0">Finance Controller Approval</button>
</ng-template>
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body">
</p-toast>
<heirarchy-view (onClose)="closeBtn()"
                *ngIf="modalStateHierarchy"
                [entityName]="getEntityName()"
                [nameField]="'name'"
                [primaryKey]="'name'"
                [rowData]="selectedRowData"
                [titlePrefix]="'Counterparty Hierarchy'"
                [visible]="modalStateHierarchy">
</heirarchy-view>

<ng-template #testTemplate let-rowData= "rowData">
    <app-kyc [counterpartyId]="rowData['uuid']" [counterpartyName]="rowData['name']">
    </app-kyc>
</ng-template>
