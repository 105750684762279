import {Component, Input, OnInit} from '@angular/core';
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
import {FormGroup} from "@angular/forms";
import {NaoFormStatic} from "@naologic/forms";
import {CommonService} from "../../../services/common.service";

@Component({
  selector: 'app-validation-box',
  templateUrl: './validation-box.component.html',
  styleUrls: ['./validation-box.component.css']
})
export class ValidationBoxComponent implements OnInit {

  @Input() validationSections: any = {};
  focusedSection: string =  '';
  focuedField: string;
  @Input() formGroup:FormGroup;
  private currentlyFocuedField: string;

  constructor(public commonService:CommonService) { }

  ngOnInit(): void {
  }

  getKeys() {
    return Object.keys(this.validationSections);
  }

  goToField(field: string, section: string) {
    this.focusedSection = section;
    this.focuedField = field;
    smoothScrollIntoView(document.getElementById(field));
    document.getElementById(field).focus();
  }

  getIconFromValidations(formGroup: FormGroup, fields: any[]) {
    let icon = 'pi pi-check colorTick iconCls';
    let _this = this;
    if(formGroup !== null && formGroup !== undefined) {
      fields.forEach(function (field: any) {
        if(field['grid'] !== null && field['grid'] !== undefined && field['grid']) {
          if(field['field'] !== ''){
            let frmgroup:any = formGroup.controls[field['field']];
            if(Object.keys(NaoFormStatic.getAllErrorsFlat(frmgroup)).length > 0) {
              icon = 'pi pi-times colorCross iconCls';
            } else {
              icon = 'pi pi-check colorTick iconCls';
            }
          }
        } else {
          if (field['field'] !== '') {
            if (formGroup.controls[field['field']].valid && icon !== 'pi pi-times colorCross iconCls') {
              icon = 'pi pi-check colorTick iconCls';
            } else {
              if (!formGroup.controls[field['field']].disabled) {
                icon = 'pi pi-times colorCross iconCls';
              }
            }
          }
        }
      });
    }
    return icon;
  }

  hasErrors(field: any) {
    if(Object.keys(field).length > 0) {
      return true;
    }
    return false;
  }

  getErrors(field:any) {
    return NaoFormStatic.getAllErrorsFlat(this.formGroup.controls[field['field']]);
  }

  getKeysFromObject(obj:any) {
    return Object.keys(obj);
  }

  currentlyEditingField(field: any) {
    return (field === this.currentlyFocuedField) ? 'focused' : '';
  }
  getHeader(field: any, error: string) {
    let num:any[] = new RegExp(/\d+$/).exec(error);
    error = error.replace(/\d+$/, "")
    return field['fields'][error]+' in row '+(parseInt(num[0])+1);
  }

  isHidden(field) {
    return field !== ''?(!this.isErrorInField(this.formGroup,field['field']) || (this.commonService.getValidationErrorMessage(this.formGroup.controls[field['field']], field['header']).length === 0)):true;
  }
  isErrorInField(formGroup: FormGroup, field: any) {
    if(formGroup !== null && formGroup !== undefined) {
      return !formGroup.controls[field].valid;
    }
    return false;
  }

}
