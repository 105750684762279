<p-table (onRowSelect)="onSelectedChange($event)"
         (onRowUnselect)="onSelectedChange($event,true)"
         [(selection)]="selectedRows" [autoLayout]="true"
         [columns]="columns" [responsive]="true"
         [style]="{width: '100%'}"
         [value]="value"
         class="table-responsive"
         selectionMode="multiple">
  <ng-template let-columns pTemplate="header">
    <tr class="sticky-top">
      <th *ngIf="showChkBox" style="width: 3em; background-color: #D9DBDE">
        <p style="background: #D9DBDE; height: 36px;margin: 0;"></p>
      </th>
      <th *ngFor="let col of columns; index as i" [ngStyle]="getStyleIfPossible(col)" class="otherColumn">
        <div class="headerDiv">
          <div class="headerTitle">{{col['header']}}</div>
          <div *ngIf="isNotLast(i)" class="pipe"></div>
        </div>
      </th>
      <th *ngIf="showDeleteIcon">
      </th>
    </tr>
  </ng-template>
  <ng-template let-columns="columns" let-ri="rowIndex" let-rowData pTemplate="body">
    <tr>
      <td *ngIf="showChkBox" class="customRow" style="width:3em;padding-left: 8px">
        <p class="plannedGridChkBox">
          <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
        </p>
      </td>
      <td *ngFor="let col of columns" [style]="{minWidth:columnMinWidth}" class="customRow">
        <div style = "margin-left: 50px" *ngIf="fieldsWithCustomView.includes(col['field'])">
          <ng-container [ngTemplateOutlet]="customViewTemplated[fieldsWithCustomView.indexOf(col['field'])]"
                        [ngTemplateOutletContext]="{rowData:rowData,column:col,index:ri,expandedRowData:expandRow}"></ng-container>
        </div>
        <div *ngIf="!fieldsWithCustomView.includes(col['field'])" >
          <div *ngIf="col.field === 'tradeTransactionType'">
            <div *ngIf="rowData[col.field]=== 'BUY'" style="display: flex; padding-left: 2%">
              <img alt="" src="../../../../assets/images/svg/buyArrow.svg">
              <p class="plannedGrid">{{getTradeTypeValue(rowData[col.field])}}</p>
            </div>
            <div *ngIf="rowData[col.field]=== 'SELL'" style="display: flex; padding-left: 2%">
              <img alt="" src="../../../../assets/images/svg/sellArrow.svg">
              <p class="plannedGrid">{{getTradeTypeValue(rowData[col.field])}}</p>
            </div>
          </div>
          <div *ngIf="col.primaryKey === true">
            <p (click)="primaryKeyClickable?throwUniqueKey(rowData,col):''"
               [ngClass]="primaryKeyClickable?'primaryKey':'text'">{{commonService.getColValue(rowData, col)}}</p>
          </div>

          <div *ngIf="col.field !== 'tradeTransactionType' && col.primaryKey !== true"
               style="display: flex">
            <p (click)="throwClickable(rowData,col.field)"
               [ngClass]="clickableFields.includes(col.field)?'primaryKey':'text'"
               class="plannedGrid">{{commonService.getColValue(rowData, col)}}</p>
            <i (click)="onClickAttachment(rowData)" *ngIf="col.field == 'profitCenter' && icon"
               class="fas fa-paperclip paperClipClass"></i>
          </div>
        </div>

      </td>
      <td *ngIf="showDeleteIcon">
        <p (click)="onRowDelete(rowData)"
           style="text-align: right; right: 0; cursor:pointer; display: flex; padding-top:10px">
          <i [ngClass]="deleteIcon" pTooltip="Delete" style="color:red" tooltipPosition="bottom"
             tooltipZIndex="436"></i>
        </p>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="footer">
    <tr *ngIf="showPlaceHolder && (value === undefined ||value === null || value.length === 0)">
      <td [colSpan]="columns.length + 2">
        <div class="placeHolder">
          <ng-container
            *ngTemplateOutlet="noDataTemplate !== undefined?noDataTemplate:defualtTemplateNoData"></ng-container>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
<ng-template #defualtTemplateNoData>
  <div *ngIf="loadingData">
    <div class="noDataBox">
      <i class="fas fa-spinner fa-pulse fa-2x animated" style="color: rgba(0,0,0,0.51);"></i>
      <p style="font-size: 30px;font-weight: bold;text-align: center;color: rgba(212,212,212,0.58);margin-top: 15px;padding: 0">Please wait</p>
    </div>
  </div>
  <div *ngIf="!loadingData" class="noDataBox">
    <img [src]="commonService.getIconPath('emptyStateIcon')" class="iconClass"/>
    <p class="noDataHeader">Data is not available</p>
  </div>
</ng-template>
