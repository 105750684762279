<div (click)="outChilds(item['childs'], item['title'])" *ngFor="let item of card;index as k">
  <div class="card card-1 fonts">
    <div>
      <p
        style="margin: 8px 8px; font-weight: bold; font-family: 'Arimo', sans-serif; font-size: 14px;">{{item['title']}}</p>
    </div>
    <div class="brk"></div>
    <div class="statContainer ">
      <div *ngFor="let stat of item['stats'];index as i" class="statview">
        <h4>{{stat['name']}} </h4>
        <h6>{{stat['value']}}</h6>
      </div>
    </div>
  </div>
</div>
