<div [ngClass]="expanded? 'accordionWrapper':'accordionClosed'">
<div [ngClass]="expanded? 'accordionWrapper':'accordionClosed'">

  <div class="accHeadingWrapper">
    <p class="optionMenuTitle">{{optionItem['title']}}</p>
    <ui-switch (click)="toggleAccordion()"
               [checkedLabel]=""
               [checkedTextColor]="'#fff'"
               [color]="'#3D9F00'"
               [defaultBgColor]="'#d8d8d8'"
               [size]="'small'"></ui-switch>
  </div>

  <div style="display: flex" *ngIf="expanded">
    <div (click)="selectOption(item, i)" *ngFor="let item of optionItem['widgets']; index as i" [ngClass]="item['selected']?'optionWrapperSelected':'optionWrapper'">
      <div class="itemIconWrapper">
        <i [ngClass]="item['selected']?'far fa-check-circle circleTickIconClass':'far fa-circle circleIconClass'"></i>
<!--        <i class="far fa-check-circle"></i>-->
      </div>
      <div class="optionBody">
        <i  class="fas fa-chart-pie" [ngClass]="item['selected']?'iconSelected':'iconClass'"></i>
        <p [ngClass]="item['selected']?'optionTitleSelected':'optionTitle'">{{item['name']}}</p>
      </div>
    </div>
  </div>
  <p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}" appendTo="body"
           key="toastMessage"></p-toast>
</div>
