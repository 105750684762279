import {environment} from "../../environments/environment";
import {CommonService} from "../services/common.service";

export class ChildAction {
  newKey = '';
  oldKey = '';
  private copyObject: any;
  private mode: any = 'copy';
  private operation: any = 'equal';
  private commonService: CommonService;
  private url: any = environment.base_url + '/api/config-common/api/';

  constructor(
    private fieldName?: string,
    private primaryKeyCode: any = '',
    private entityRepos: any[] = [],
    private criteria: any[] = []
  ) {
  }

  public getFieldName() {
    return this.fieldName;
  }

  public setFieldName(fieldName: any) {
    this.fieldName = fieldName;
  }

  public setCommonService(service: CommonService) {
    this.commonService = service;
  }

  public setMode(mode: any = 'copy|delete|makedefault') {
    this.mode = mode;
  }

  public getPrimaryKeyCode() {
    return (this.primaryKeyCode !== undefined && this.primaryKeyCode.toString().length > 0) ? this.primaryKeyCode : this.fieldName;
  }

  public setPrimaryKeyCode(code: any) {
    this.primaryKeyCode = code;
  }

  public perform() {
    this.createObject();
    let mode = this.mode == 'copy'?'copychildren':this.mode;
    return this.commonService.post(this.url + mode, this.copyObject);
  }

  public setUrl(url) {
    this.url = url;
  }

  public addRepo(entityName: any, repoName: any, fieldToUpdate?: string, statusField?: string) {
    this.entityRepos.push({
      entityName: entityName,
      repoName: repoName,
      fieldToUpDate: fieldToUpdate,
      statusField: statusField
    });
  }

  public setNewOldKey(newKey: any, oldKey: any) {
    this.newKey = newKey;
    this.oldKey = oldKey;
  }

  public addCriteria(key, value, operation = 'equal') {
    this.criteria.push({
      key: key,
      operation: operation,
      value: value
    })
  }

  getCriteria() {
    if (this.criteria === undefined || this.criteria === null || this.criteria.length === 0) {
      this.addCriteria(this.fieldName, this.oldKey, this.operation)
    }
    return this.criteria;
  }

  private createObject() {
    this.copyObject = {
      newKey: this.newKey,
      filter: [{
        criteria: this.getCriteria()
      }],
      copyChildrenRequest: this.entityRepos
    };
  }
}
