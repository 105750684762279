import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {Tab} from "../../../tab-grid/Tab.model";
import {MasterService} from "../../../masters/services/MasterService";
import {ActivatedRoute} from "@angular/router";
import {CommonService} from "../../../services/common.service";
import {environment} from "../../../../environments/environment";
import {filter} from "../../../masters/services/filter.model";
import {InventoryComponent} from "../../inventory/inventory.component";
import {FormBuilder} from '@angular/forms';
import {MessageService} from 'primeng';


@Component({
  selector: 'app-inventory-info',
  templateUrl: './inventory-info.component.html',
  styleUrls: ['./inventory-info.component.css']
})
export class InventoryInfoComponent implements OnInit {
  @Input() display: boolean = false;
  @Input() storageId: string = '';
  @Input() sidebarPosition: string = 'right';
  @Input() rowData: any = {};
  @Output() onClose = new EventEmitter();
  tabs: Tab[] = [];
  inventory: InventoryComponent;
  inventoryObject: any = {};
  editUrl: string;
  storageInventoryUrl: string;
  docUrl: string;
  docName: string;
  showDoc: boolean;

  constructor(private  masterCommonService: MasterService, private commonService: CommonService, private router: ActivatedRoute,private formBuilder: FormBuilder,private messageService: MessageService) {

  }

  ngOnInit(): void {
    this.initTabs();
  }

  initTabs() {
    this.inventory = new InventoryComponent(this.masterCommonService, this.commonService, this.router,this.formBuilder,this.messageService);
    this.inventory.ngOnInit();
    this.tabs = [];
    this.editUrl = this.getEditUrl();
    let tab = new Tab(this.inventory.grid.getTitle(), this.inventory.grid, 'storageId', undefined, true, this.storageInventoryUrl, {minHeight: '86.6vh'});
    tab.getGrid().setTabs([]);
    tab.getGrid().setGridConfiguration(false, false, false);
    tab.formOnModal = true;
    this.tabs.push(tab);
    this.fetchTrade(this.storageId);
  }

  updateTabs() {
    this.editUrl = this.getEditUrl();
  }


  close() {
    this.display = false;
    this.onClose.emit();
  }

  fetchTrade(inventoryId: string) {
    this.commonService.getJSONByURL(environment.base_url+'/api/storageInventory/v1/getstorageinventorybyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid='+this.storageId).subscribe((next: any[]) => {
      if (next !== undefined && next.length > 0) {
        this.inventoryObject = next[0];
        this.updateTabs();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['storageId']) {
      this.fetchTrade(changes['storageId'].currentValue);
    }
  }

  onIndexChange(value: number) {
  }

  onClickButton($event: any) {
  }

  onCloseDocViewer(value: any) {
    this.docUrl = '';
    this.showDoc = false;
  }

  private getEditUrl() {
    return environment.base_url+'/api/storageInventory/v1/getstorageinventorybyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid='+this.storageId;
  }


}












