import {MenuItem} from 'primeng/api';
import {ApprovalFunctionParams} from '../../../Components/approval-context-menu/model/approval-function.params';
import {Button} from '../../../grid/buttons.model';

export const billOfLadingFunctions: { [key: string]: Function } = {
    loadMenuItems: (param: ApprovalFunctionParams): MenuItem[] => {
        let items: MenuItem[] = [];
        let uploadDocument = function() {
            param.context.onMenuItemClick.emit({title: "Upload Document",data:param.rowData});
        };

        let generateBLDocument = function() {
            param.context.onMenuItemClick.emit({title: "Generate BL Document",data:param.rowData});
        };

        let copyBL = function() {
            param.context.onMenuItemClick.emit({title: 'Copy BL',data:param.rowData});
        };
        let transactionType = param.rowData['tradeTransactionType'];
        if(transactionType !== 'SELL') {
            items.push(param.commonService.getMenuItemFromButton(new Button("Upload BL Document", 'F', '', uploadDocument, undefined, undefined, undefined, undefined, false)));
            items.push(param.commonService.getMenuItemFromButton(new Button("Copy BL", 'F', '', copyBL, undefined, undefined, undefined, undefined, false)));
        } else {
            items.push(param.commonService.getMenuItemFromButton(new Button("Generate BL Document", 'F', '', generateBLDocument, undefined, undefined, undefined, undefined, false)));
        }
        return items;
    },
    onDone: (param: ApprovalFunctionParams) => {
        param.commonService.postMessage('', `${param.currentApprovalObject.step['actionName']} ${param.currentApprovalObject.buttonName} Successfully.`,'success');
        param.onUpdateDone.emit({step: param.currentApprovalObject.buttonName});
    },
    disableCondition: (param:ApprovalFunctionParams) => {
        return (param.rowData['blNumber'] === null || param.rowData['blNumber'] === undefined ||param.rowData['blNumber'].length === 0)
    }
};
