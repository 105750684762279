export class filter {
  constructor(
    private url: string,
    private entityName: any,
    private criteria: any[] = [],
    private sortBy: string[] = []) {
  }

  getUrl() {
    return this.url;
  }

  setUrl(url: any) {
    this.url = url;
  }

  getEntityName() {
    return this.entityName;
  }

  setEntityName(name: any) {
    this.entityName = name;
  }

  addSortBy(sortBy: string) {
    if (!this.sortBy.includes(sortBy)) {
      this.sortBy.push(sortBy);
    }
  }

  getSortBy() {
    return this.sortBy;
  }

  addCriteria(key: any, value: any, operation: any = 'equal') {
    let obj = {key: key, value: value, operation: operation};
    this.criteria.push(obj);
  }

  addCriteriaWithOperation(key: any, operation: any) {
    let obj = {key: key, operation: operation};
    this.criteria.push(obj);
  }
}
