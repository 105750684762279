<div class="sectionCardTop">
  <div class="breadcrumbPane">
    <ctrm-breadcrumb [collapsabel]="false"
                     [items]="breadCrumbService.getBreadCrumb()"
                     [showIcon]="false"></ctrm-breadcrumb>
  </div>
  <div class="actionPane">
    <div class="screenDetails">
      <img [src]="'../../../assets/images/svg/tradePlanning.svg'"/>
      <p>Settlement (Cash & Forward)</p>
    </div>
    <sta-filter
            [columnListObject]="planCols"
            [pTable]="planTable"
            [columns]="columns"
            (onFilterApply)="onApplyFilter($event)"
            [moduleName]="'Settlement'">
    </sta-filter>
  </div>
</div>

<div class="selectButtonClass">
<!--  <p-selectButton  (onChange)="selectFilter($event)" [(ngModel)]="tabValue" [options]="tabOptions"></p-selectButton>-->
  <p-selectButton  (onChange)="onTabChange($event)" [(ngModel)]="tabValue2" [options]="tabOptions2"></p-selectButton>
</div>
<div class="planningPanel">
  <div class="toolbar">
    <div class="h-100" style="padding: 3px">
      <ctrm-button (onClick)="refreshSettlement()" icon="fas fa-redo" label="Refresh"></ctrm-button>
      <div class="float-right">
        <ctrm-button icon="fas fa-file-export" (click)="onClickExport()" label="Export"></ctrm-button>
        <ctrm-button icon="fas fa-print" label="Print"></ctrm-button>
      </div>
    </div>
  </div>


  <div>

    <p-table #planTable (onRowSelect)="onRowSelectionChange($event,false)"
             (onRowUnselect)="onRowSelectionChange($event,true)"
             [expandedRowKeys]="expandedRows"
             (onRowExpand)="onExpandRow($event)"
             [(selection)]="selectedPlans" [autoLayout]="true" [columns]="planCols"
             [responsive]="true" [style]="{maxHeight:planTableHeight,minHeight:planTableHeight, borderStyle:'solid', borderWidth: '1px', borderColor:'#e6e6e6'}" [value]="planData" class="style-4 table-responsive"
             dataKey="uuid" sortMode="multiple">
      <ng-template let-columns pTemplate="header">
        <tr>
          <th style="width: 3em">
            <p
              style="margin:0; padding: 12px 5px 9px;text-align: center;">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </p>
          </th>
          <th class="expandColumn"></th>
          <th *ngFor="let col of columns;index as i" [pSortableColumn]="col['field']">
            <div style="background-color: #D9DBDE; padding: 5px;padding-top: 13px; padding-bottom: 14px">
              <p [ngClass]="getClassForHeading(i)">{{col.header}}</p>
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template let-columns="columns" let-ri="rowIndex" let-expanded="expanded" let-rowData pTemplate="body">
        <tr [pSelectableRow]="rowData">
          <td class="customRow" style="width:3em;">
            <p class="plannedGridChkBox">
              <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
            </p>
          </td>
          <td  class="expandData customRow">
            <p style="margin: 0;text-align: center">
              <a [pRowToggler]="rowData['splitted']?rowData:''" href="#">
                <i [ngClass]="expanded?'pi pi-chevron-down':(!rowData['splitted']?'pi pi-chevron-right expand_disabled':'pi pi-chevron-right')" class="togglerIcon"></i>
              </a>
            </p>
          </td>
          <td *ngFor="let col of columns" class="customRow">
            <p
              *ngIf="col.field !== 'matchType' && col.field !== 'plannedObligationId' && col.field !== 'obligationState'
              && col.field !== 'tradeTransactionType' && col.field !== 'tradeQuantity' && col.field !== 'plannedQuantity'
              && col.field !== 'tradePrice' && col.field !== 'tradeId'"
              class="plannedGrid">
              {{getRowValue(rowData, col)}}
            </p>
            <p *ngIf="col.field === 'tradeId'" style="margin: 0">
              <a class="spanClass linkplan" (click)="tradeIdclick(rowData)">
                {{rowData[col.field]}}
              </a>
            </p>
            <p *ngIf="col.field === 'matchType'" class="matchAlgoStyle">
              {{docByPassIdPresent(rowData)?'Washout - DocByPass':rowData[col.field]}}
            </p>

            <p *ngIf="col.field === 'tradeQuantity'" class="plannedGrid">
              {{getTradeQty(rowData)}}
            </p>

            <p *ngIf="col.field === 'plannedQuantity'" class="plannedGrid">
              {{commonService.formatNumberWithoutComma(rowData[col.field],quantityRoundOff)}} {{rowData['quantityUOM']}}
            </p>

            <p *ngIf="col.field === 'tradePrice'" class="plannedGrid">
              {{getTradePrice(rowData)}}
            </p>

            <div *ngIf="col.field === 'tradeTransactionType'">
              <div *ngIf="rowData[col.field]=== 'BUY'" style="display: flex; padding-left: 2%">
                <img alt="" src="../../../assets/images/svg/buyArrow.svg">
                <p class="plannedGrid">{{getTradeTypeValue(rowData[col.field])}}</p>
              </div>
              <div *ngIf="rowData[col.field]=== 'SELL'" style="display: flex; padding-left: 2%">
                <img alt="" src="../../../assets/images/svg/sellArrow.svg">
                <p class="plannedGrid">{{getTradeTypeValue(rowData[col.field])}}</p>
              </div>
            </div>

            <div *ngIf="col.field === 'plannedObligationId'" style="display: flex">
              <p class="plannedGrid linkplan" (click)="plannedObligationclick(rowData['plannedObligationId'],rowData)">{{commonService.getRowValue(rowData, col)}} {{rowData['splitSequenceNumber'] !== 0?'('+rowData['splitSequenceNumber']+')':''}}</p>
            </div>
            <div *ngIf="col.field === 'obligationState'">
              <div *ngIf="rowData[col.field]['ACTUALIZED']" class="planStatusField">
                <img alt="" src="../../../assets/images/svg/purpleTick.svg">
                <p class="statusActualized">Actualized</p>
              </div>
              <div *ngIf="!rowData[col.field]['ACTUALIZED']" class="planStatusField">
                <img alt="" src="../../../assets/images/svg/event.svg">
                <p class="statusActualized">Planned</p>
              </div>
            </div>
          </td>


        </tr>
      </ng-template>
      <ng-template let-columns="columns" let-rowData pTemplate="rowexpansion">
        <tr>
          <td [colSpan]="columns.length + 2">
            <div class="planningInfoContainer">
              <app-cashflow-grid (onClickUniqueKey)="onUniqueClick($event)"
                                 (onSelectedRows)="onRowSelectionChange($event,false,true)"
                                 [columns]="planColsChild"
                                 [icon]="false"
                                 [selectedRows]="rowData['selectedChild']"
                                 [showChkBox]="true"
                                 [value]="rowData['splitList']">
              </app-cashflow-grid>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template let-columns pTemplate="footer">
        <tr>
          <td [colSpan]="columns.length + 1">
            <div *ngIf="planData.length == 0 || planData.length == null" style="text-align: center;">
              <div class="noPlans">{{loadingMessage}}</div>
              <div *ngIf="!isRefreshing">
                <button (click)="goTo()" class="planScreenBtns">Create Plan</button>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <p-paginator *ngIf="getPagination()" [rows]="20" [totalRecords]="totalElements" (onPageChange)="onPageChange($event)"></p-paginator>
  </div>
</div>


<app-generate-invoice (onDraftClose)="onDraftClose()"
                      (onSendForApproval)="onSendForApproval()"
                      *ngIf="draftForm"
                      [loading]="isLoadingInvoice"
                      [data]="draftData"
                      [plannedObligation]="plannedObligation"
                      [sell]="mode"
                      [generateGroupInvoice]="groupBtnCheck"></app-generate-invoice>

<p-sidebar *ngIf="operationDisplay" [(visible)]="operationDisplay" [baseZIndex]="25" [modal]="false" [showCloseIcon]="false" position="bottom">
  <div class="bottomSidebarContainer">
    <div class="leftSection">
      <i (click)="operationDisplay = !operationDisplay" class="material-icons closable">clear</i>
    </div>
    <div class="totalCFContainer">
      <p class="cashflowTotal">{{selectedPlans.length}}</p>
      <p class="totalCF">Obligation(s) Selected</p>
    </div>
    <div class="middleSection">
      <i class="fas fa-download middleIcons" pTooltip="Download" tooltipPosition="top" tooltipZIndex="9999"></i>
      <i class="fas fa-file-export middleIcons" pTooltip="Export" tooltipPosition="top" tooltipZIndex="9999"></i>
      <i class="fas fa-print middleIcons" pTooltip="Print" tooltipPosition="top" tooltipZIndex="9999"></i>
    </div>
    <div class="rightSection">
      <button (click)="operationDisplay = !operationDisplay" class="cancelBtn">Cancel</button>


        <button *ngFor="let button of bottom_buttons" (click)="getPayload(button)" [disabled]="((button ==='Invoice' && docByPassIdPresent(selectedPlans[0])) || groupBtn) ? true : null" class="saveBtn">Generate {{button}}</button>
        <button  (click)="getGroupPayload('generateGroupInvoice')" *ngIf="groupBtn" class="saveBtn">Generate Group Invoice</button>
        <p-splitButton *ngIf="selectedPlans.length > 0 && docByPassIdPresent(selectedPlans[0])"
                     [model]="items" label="Generate DocByPass"
                     (onClick)="getPayload('Doc ByPass')"
                     style="padding: 1rem"
                     [styleClass]="'splitButton'"
        ></p-splitButton>

    </div>
  </div>
</p-sidebar>

<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>


<app-trade-info (onClose)="onClose($event)" *ngIf="displayidinfo" [display]="true" [rowData]="infoRowdata"
                [tradeId]="infotradeId"
                [idValue]="infoRowdata.plannedObligationId"
                [detailsOf]="detailsOf"
></app-trade-info>
