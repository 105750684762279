import {Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {MasterService} from '../../masters/services/MasterService';
import {ConfirmationService, MessageService, Table} from 'primeng';
import {FormBuilder} from '@angular/forms';
import {CommonService} from '../../services/common.service';
import {BreadcrumbService} from '../../Components/ctrm-breadcrumb/breadcrumb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {filter} from '../../masters/services/filter.model';
import {entities} from '../../services/common/entities';
import {HttpErrorResponse} from '@angular/common/http';
import {messages} from '../../services/common/messages';
import {ExcelServiceService} from '../../services/excel-service.service';
import {Filter} from '../../grid/preference/filter/filter.model';
import {Tcolumn} from '../../grid/tcolumn.model';
import {KeyValue} from '../../grid/key-value.model';

@Component({
  selector: 'app-price-allocation',
  templateUrl: './price-allocation.component.html',
  styleUrls: ['./price-allocation.component.css']
})
export class PriceAllocationComponent implements OnInit {

  tabValue: string;
  allocationData: any[] = [];
  isRefreshing: boolean = false;
  selectedPlans: any[] = [];
  @ViewChild('priceTable', {static: true}) priceTable: Table;
  priceTableHeight: string = '';
  divEl: HTMLDivElement;
  quantityRoundOff:number=3;
  priceRoundOff:number=2;
  gridHeight: any = '300px';
  expandedRows = {};
  //Labels
  tradeCols: any[] = [];
  priceCols: any[];
  currentSelectedRow: number = -1;
//sidebar
  allocationDisplay: boolean = false;
  priceValue: any[] = [];
  obligationValue: any[] = [];
  tradeId: string = '';
  //sticky Header
  leftFixedColumns: any[] = ['tradeId','tradeDate','tradeType', 'priceType', 'priceStatus'];
  leftFixedColumnsWidth: number[] = [];
  rightFixedColumns: any[] = [];
  rightFixedColumnsWidth: number[] = [];
  fixColumnsWidth: number = 200;
  noDataBoxLeft: any = '';
  loadingMessage: string = "Loading ...";
  //matchingScreen
  @Input() display: boolean = false;
  matchingScreenOpen: boolean = false;
  rowData: any;
  tradedisplay: boolean = false;
  deleteAll:boolean=true;
  deletePrice:boolean;
  allocationPriceData:any[];

  filters:Filter[] = [];
  pageSize:number = 0;
  totalElements: number = 0;
  totalPages:number = 0;
  columns:Tcolumn[] =[];
  currentPageNumber:number = 0;
  fixColumnNames: Map<string,string>;
  routingParams:any;
  priceUom:any = '';
  quantityUom:any = '';

  constructor(public masterCommonService: MasterService,
              private route: ActivatedRoute,
              private confirmationService: ConfirmationService,
              private formBuilder: FormBuilder, public commonService: CommonService,
              private messageService: MessageService,
              public breadCrumbService: BreadcrumbService, private router: Router,private excelService: ExcelServiceService) {
  }

  ngOnInit(): void {
    let _this =this;
    let df=_this.quantityRoundOff;
    this.breadCrumbService.makeBreadcrumbTo(this.commonService.OPERATION_MAP_KEY, '/priceAllocation');
    let tradeQuantityFunction = function (value: any) {
      return _this.commonService.formatNumberWithoutComma(value['tradeQuantity'],df) + ' ' + value['tradeQuantityUomName'];
    };

    let priceQuantityFunction = function (value: any) {
      return _this.commonService.formatNumberWithoutComma(value['pricedQuantity'],df) + ' ' + value['priceUomName'];
    };

    let unpriceQuantityFunction = function (value: any) {
      return _this.commonService.formatNumberWithoutComma(value['unpricedQuantity'],df) + ' ' + value['priceUomName'];
    };

    let averagePriceFunction = function (value: any) {
      return _this.commonService.formatNumber(value['averagePrice'],'','','en-US',_this.priceRoundOff) + ' ' + value['priceCurrency'] + '/' + value['priceUomName'];
    };

    let incotermFilter = new filter(environment.base_url + 'filter', entities.incoterm);
    incotermFilter.addCriteria('incotermCode', null, 'notEqual');

//Display Labels of the Trade details(Parent table)
    this.tradeCols = [
      {field: 'tradeId', header: 'Trade ID'},
      {field: 'tradeDate', header: 'Trade Date', type: 'date'},
      {field: 'tradeType', header: 'Trade Type'},
      {field: 'priceType', header: 'Price Type'},
      {field: 'priceStatus', header: ' Trade Pricing Status'},
      {field: 'commodity', header: 'Commodity'},
      {field: 'tradeQuantity', header: 'Total trade Quantity',valueFunction: tradeQuantityFunction},
      {field: 'pricedQuantity', header: 'Priced Quantity',valueFunction: priceQuantityFunction},
      {field: 'unpricedQuantity', header: 'Unpriced Quantity',valueFunction: unpriceQuantityFunction},
      {field: 'averagePrice', header: 'Average Price',valueFunction: averagePriceFunction},
      {field: 'periodStartDate', header: ' Period start date', type: 'date'},
      {field: 'periodEndDate', header: 'Period end date', type: 'date'},
      {field: 'incoterm', header: 'Incoterm'},
      {field: 'location', header: 'Location'},
      {field: 'origin', header: 'Origin'},
      {field: 'traderName', header: 'Trader Name'},
      {field: 'counterparty', header: 'Counterparty'},
      {field: 'profitCenter', header: 'Profit Center'}
    ];
    this.fixColumnNames = new Map<string, string>();
    this.fixColumnNames.set('tradeDate','tradeDateTime');
    this.fixColumnNames.set('tradeQuantity','totalTradeQty');
    this.fixColumnNames.set('tradeType','tradeTransactionType');
    this.fixColumnNames.set('unpricedQuantity','balanceQuantity');
    this.fixColumnNames.set('profitCenter','profitcenter');
    this.columns = [];
    this.columns.push(new Tcolumn('tradeId','Trade Id','T',1));
    this.columns.push(new Tcolumn('tradeDateTime','Trade Date','D',1));
    this.columns.push(new Tcolumn('tradeTransactionType','Trade Type','OB',1,true,this.getBuySell()));
    this.columns.push(new Tcolumn('priceType','Price Type','T',1));
    this.columns.push(new Tcolumn('priceStatus','Trade Pricing Status','OB',1,true,this.getPriceStatusList()));
    this.columns.push(new Tcolumn('commodity','Commodity','T',1));
    this.columns.push(new Tcolumn('totalTradeQty','Total Trade Quantity','N',1));
    this.columns.push(new Tcolumn('pricedQuantity','Price Quantity','N',1));
    this.columns.push(new Tcolumn('balanceQuantity','Unpriced Quantity','N',1));
    this.columns.push(new Tcolumn('averagePrice','Average Price','N',1));
    this.columns.push(new Tcolumn('periodStartDate','Period Start Date','D',1));
    this.columns.push(new Tcolumn('periodEndDate','Period End Date','D',1));
    this.columns.push(new Tcolumn('incoterm','Incoterm','T',1));
    this.columns.push(new Tcolumn('location','Location','T',1));
    this.columns.push(new Tcolumn('origin','Origin','T',1));
    this.columns.push(new Tcolumn('traderName','Trader Name','T',1));
    this.columns.push(new Tcolumn('counterparty','Counterparty','T',1));
    this.columns.push(new Tcolumn('profitcenter','Profit Center','T',1));

    this.route.queryParams.subscribe(params => {
      if(params !== undefined && params !== null && Object.keys(params).length > 0) {
        this.routingParams=params;
      }
    });

    let tenantConfig=JSON.parse(this.commonService.getFromStorage("tenantConfig"));
    if(tenantConfig && tenantConfig.roundingFormat){
      _this.priceRoundOff=tenantConfig.roundingFormat.priceRounding;
      _this.quantityRoundOff=tenantConfig.roundingFormat.quantityRounding;
    }
    else{
      _this.priceRoundOff=2;
      _this.quantityRoundOff=3;
    }

    let quantityValue = function (value: any) {
      return _this.commonService.getFormatedNumber(value['quantity']) + ' ' + value['quantityUom'] ;
    };
    let Priceunits = function (value: any) {
      return _this.commonService.getFormatedNumber(value['price']) + ' ' + value['priceCurrency'] + '/' + value['currencyUom'];
    };

    this.priceCols = [
      {field: 'obligationId', header: 'Obligation Id'},
      {field: 'quantity', header: 'Quantity',valueFunction: quantityValue},
      {field: 'price', header: 'Price',valueFunction: Priceunits},
    ];

  }

  private getBuySell() {
    return this.masterCommonService.getBooleanFromLabel('Buy','Sell',false,'BUY','SELL');
  }

  private getPriceStatusList() {
    let list:KeyValue[] = [];
    list.push(new KeyValue());
    list.push(new KeyValue('Fully Priced','Fully Priced'));
    list.push(new KeyValue('Partially Priced','Partially Priced'));
    return list;
  }

  //get all the trade details (1st table)
  getTradeData() {
    let _this = this;
    this.isRefreshing = true;
    this.allocationData = [];
    _this.loadingMessage = "Loading ..";
    this.commonService.getJSONByURL(environment.base_url + '/api/manualPricingService/v1/get-price-allocation-data-list-v2?tenantId='+_this.commonService.getFromStorage('tenantId')+'&page='+this.currentPageNumber+'&size=20').subscribe((next: any) => {
      _this.allocationData = next['content'];
      _this.pageSize = next['pageable']['pageSize'];
      _this.totalElements = next['totalElements'];
      _this.totalPages = next['totalPages'];
      _this.calculateHeight(_this.priceTable);
      _this.priceAllocateRoute(_this.routingParams)
      _this.isRefreshing = false;
      _this.loadingMessage = "No Trade for Price Allocation";
    }, (error: HttpErrorResponse) => {
      _this.isRefreshing = false;
      _this.loadingMessage = _this.commonService.getHttpErrorMessage(error,'Price Allocation');
    });
  }

  //To show price Allocation Details and open Allocation Tab [context Switching]
  priceAllocateRoute(params:any){
    if(params != undefined && params !=null && Object.keys(params).length > 0 ){
      if(params['buttonName']=='Price Allocation'){
        this.tradeId = params['value'];
        this.matchingScreenOpen = true;
      }
      if(params['buttonName']=='See All PriceAllocation Details'){
        this.allocationData.forEach((rowData: any,index)=>{
          if (rowData['tradeId'] ===  params['value']){
            this.commonService.getJSONByURL(environment.base_url + '/api/manualPricingService/v1/getPriceAllocatedByTradeId?tradeId=' + params['value'] +'&tenantId='+this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
              this.allocationData[index]['pricedChilds'] = next;
              this.expandedRows = {};
              this.expandedRows[params['value']] = true;
              this.allocationPriceData=next;
            });
          }
        })
      }
    }
  }

//Refresh Button
  allocationRefresh() {
    this.allocationDisplay = false;
    this.allocationData = [];
    this.selectedPlans = [];
    this.currentSelectedRow = -1;
    if(this.filters !== undefined && this.filters !== null && this.filters.length > 0) {
      this.onApplyFilter(this.filters,false);
    } else {
      this.getTradeData();
    }
  }


//Delete the  single Price Allocation
  onRowDelete(value:any) {
    let priceline = value['rowData'];
    let payload ={
      priceAllocationLists :value['rowData']
    }
    let _this = this;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this Price Allocation ?',
      accept: () => {
          this.commonService.post(environment.base_url + '/api/manualPricingService/v1/deleteallocationpriceline?tenantId='+_this.commonService.getFromStorage('tenantId'),[priceline]).subscribe(next => {
          _this.showToast('Successful');
          _this.priceTable.reset();
          _this.allocationRefresh();
        })
      }
    });

  }

  // deleting All the price allocation under the trade row on single click
  onDeleteAll(){
    let _this = this;
    let tradeId = this.selectedPlans.map(data=>{
      return data.tradeId;
    })
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete All the Price?',
      accept: () => {
        this.commonService.getJSONByObject(environment.base_url + '/api/manualPricingService/v1/deleteallocationpriceline?tenantId='+_this.commonService.getFromStorage('tenantId'),this.allocationPriceData).subscribe(next => {
          _this.showToast('Successful');
          _this.priceTable.reset();
          _this.allocationRefresh();
        },(error:HttpErrorResponse) => {
          _this.showToast(_this.commonService.getHttpErrorMessage(error,'Price Allocation'),'error');
        })
      }
    });


  }

  showToast(msg, severity: string = 'success') {
    this.messageService.add({
      severity: 'info',
      summary: msg
    });
  }

  //Child Form API(expanded row)
  openPriceRow(value: any) {
    if (value['data'] !== undefined && value['data'] !== null) {
      let _this = this;
      let tradeId = value['data']['tradeId'];
      this.commonService.getJSONByURL(environment.base_url + '/api/manualPricingService/v1/getPriceAllocatedByTradeId?tradeId=' + tradeId +'&tenantId='+_this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
        value['data']['pricedChilds'] = next;
        this.allocationPriceData=next;

      });
    }
  }

  //click on Exports icon it downloads the excel sheet
  onClickExport(){
    if (this.selectedPlans !== undefined && this.selectedPlans.length > 0) {
      this.excelService.exportDashboardDataView(this.tradeCols, this.allocationData, 'PriceList', 'PriceList')
    } else {
      this.messageService.add({
        severity: 'info', summary: messages.no_row_selected['summary'],
        detail: messages.no_row_selected['message']
      });
    }
  }

  @HostListener("window:resize", [])
  public onResize() {
    this.calculateHeight(this.priceTable);
  }

  //Calculate the height of the table
  calculateHeight(priceTable: Table) {
    if (priceTable !== undefined && priceTable !== null) {
      this.priceTable = priceTable;
      let offsetTop = priceTable.el.nativeElement['offsetTop'];
      let offsetHeight = priceTable.el.nativeElement['offsetParent']['offsetHeight'];
      if(this.getPagination()) {
        offsetHeight = offsetHeight - 30;
      }
      this.priceTableHeight = (offsetHeight - offsetTop - 22) + 'px';
      if (this.divEl !== undefined && this.divEl !== null) {
        this.calculateGridHeight(this.divEl);
      }
    }
  }

  calculateGridHeight(divElement: HTMLDivElement) {
    this.gridHeight = 350 + 'px';
    this.divEl = divElement;
  }


  //cancel button on sidebar
  onFormCancel() {
    this.selectedPlans = [];
    this.allocationDisplay = false;
    if(this.priceTable !== undefined && this.priceTable !== null) {
      this.priceTable.selection = {};
    }
  }

//Open the matching Screen
  openAllocationScreen() {
    this.priceValue = [];
    this.obligationValue = [];
    this.tradeId = this.selectedPlans[0]['tradeId'];
    this.priceUom = this.selectedPlans[0]['priceUomName'];
    this.quantityUom = this.selectedPlans[0]['tradeQuantityUomName'];
    this.matchingScreenOpen = true;
    this.allocationDisplay = false;
    this.selectedPlans = [];
  }

  //To get the value of Trade Type since the field is Boolean
  getTradeTypeValue(rowDatum: any) {
    return rowDatum ? 'Buy' : 'Sell';
  }

//NOData box
  calculateLeft() {
    this.noDataBoxLeft = ((window['visualViewport'].width - 500) / 2) + 'px';
  }

  getClassForHeading(index) {
    return index > 0 ? 'headerNormal' : 'noPipe';
  }

//Navigate to the trade screen
  goTo() {
    this.router.navigate(['/trade']);
  }

  getStyle(col: any, index, zIndex = '400', id) {
    let style: any;
    let width;
    let element: HTMLElement;
    let colWidth: number = 0;
    let _this = this;
    element = document.getElementById(id);
    if (element !== undefined && element !== null) {
      colWidth = element.offsetWidth;
    }
    if(col !== undefined && col !== null) {
      if (this.leftFixedColumns.includes(col['field'])) {
        this.leftFixedColumnsWidth[this.leftFixedColumns.indexOf(col['field'])] = colWidth;
        width = this.getFixColumnWidth(index, col);
        style = {
          position: 'sticky',
          left: width + 'px',
          zIndex: zIndex
        };
      } else if (this.rightFixedColumns.includes(col['field'])) {
        this.rightFixedColumnsWidth[this.rightFixedColumns.indexOf(col['field'])] = colWidth;
        width = this.getFixColumnWidth(index, col, 'right');
        if (width !== 0) {
          width -= 1.5;
        }

        style = {
          position: 'sticky',
          right: width + 'px',
          zIndex: zIndex
        }
      }
    }
    return style;
  }

  getFixColumnWidth(index, col: any, direction: string = 'left') {
    let _this = this;
    let width = (index * this.fixColumnsWidth) + 50;
    if (direction === 'left') {
      width = (index * this.fixColumnsWidth) + 50;
      if (this.leftFixedColumnsWidth !== undefined && this.leftFixedColumnsWidth !== null && this.leftFixedColumnsWidth.length > 0) {
        width = 50;
        if (index > 0) {
          this.leftFixedColumnsWidth.forEach(function (columnWidth: number, widthIndex) {
            if (widthIndex < index) {
              width += columnWidth;
            }
          });
        }
      }
    } else {
      let rightIndex = this.rightFixedColumns.indexOf(col['field']);
      width = ((this.rightFixedColumns.length - 1) - rightIndex) * this.fixColumnsWidth;
      if (this.rightFixedColumnsWidth !== undefined && this.rightFixedColumnsWidth !== null && this.rightFixedColumnsWidth.length > 0) {
        width = 0;
        this.rightFixedColumnsWidth.forEach(function (columnWidth: number, widthIndex) {
          if (widthIndex > rightIndex) {
            width += columnWidth;
          }
        });
      }
    }
    return width;
  }


  showAllocationMain(value: any) {
    this.matchingScreenOpen = false;
    if(this.allocationData === null || this.allocationData === undefined || this.allocationData.length === 0) {
      this.allocationRefresh();
    }
  }

  onClose(value: any) {
    this.tradeId = '';
    this.tradedisplay = false;
  }

  onUniqueClick(value: any) {
    this.tradeId = value['tradeId'];
    this.rowData = value;
    this.tradedisplay = true;
  }

  onApplyFilter(value: Filter[],resetInfo:boolean = true) {
    let _this = this;
    this.isRefreshing = true;
    this.allocationData = [];
    if(resetInfo) {
      this.currentPageNumber = 0;
    }
    let payload = this.commonService.getFilterApiPayload(value);
    if(payload.length > 1) {
      this.filters = value;
      let url = environment.base_url + '/api/manualPricingService/v1/getpriceallocationbycriteria?tenantId=' + this.commonService.getTenantId() + '&page=' + this.currentPageNumber + '&size=20'
      this.commonService.post(url, payload).subscribe((next: any) => {
        _this.isRefreshing = false;
        _this.allocationData = next['content'];
        _this.pageSize = next['pageable']['pageSize'];
        _this.totalElements = next['totalElements'];
        _this.totalPages = next['totalPages'];
        _this.calculateHeight(_this.priceTable);
      }, (error: HttpErrorResponse) => {
        _this.isRefreshing = false;
        _this.showToast(_this.commonService.getHttpErrorMessage(error, 'Price Allocation'), 'error');
      });
    } else {
      this.filters = [];
      this.getTradeData();
    }

  }

  onPageChange(value: any) {
    this.currentPageNumber = value['page'];
    if(this.filters !== null && this.filters !== undefined && this.filters.length > 0) {
      this.onApplyFilter(this.filters,false);
    } else {
      this.getTradeData();
    }
  }

  getPagination() {
    return this.totalElements > this.pageSize;
  }

  onRowSelectionChange(value: any) {
    let tradeId= value.data.tradeId;
    if(this.selectedPlans === null || this.selectedPlans === undefined || this.selectedPlans.length !==1) {
      this.allocationDisplay = false;
    } else {
      this.allocationDisplay = true;
      this.commonService.getJSONByURL(environment.base_url + '/api/manualPricingService/v1/getPriceAllocatedByTradeId?tradeId=' + tradeId + '&tenantId=' + this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
        if (next.length <= 0 || next.status === 'No Price Allocation found for given trade id' || next === undefined || next === null) {
          this.deletePrice = true;
        } else {
          this.deletePrice = false;
        }
      });
  }
}
}
