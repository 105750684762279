export const entities = {
  brandMain: 'brandMain',
  gradeMain: 'gradeMain',
  cropSeason: 'cropSeason',
  brandQualitySpec: 'BrandQualitySpec',
  gradeQualitySpec: 'GradeQualitySpec',
  globalIndicatorDetails: 'globalIndicatorDetails',//'xceler_dataconfigservice_globalindicatordetails',
  calendarHolidays: 'CalendarHolidays',
  calendarWorkingDays: 'CalendarWorkingDays',
  commodity: 'commodity',
  commodityPackage: 'CommodityPackage',
  commodityBrand: 'CommodityBrand',
  commodityGrade: 'CommodityGrade',
  commodityOrigin: 'CommodityOrigin',
  commodityQualitySpec: 'CommodityQualitySpec',
  commodityCropSeason: 'CommodityCropSeason',
  internalPackage: 'InternalPackage',
  externalPackage: 'ExternalPackage',
  location: 'Location',
  uom: 'unitOfMeasurement',
  incoterm: 'incoterm',
  company: 'company',
  companyBankAccount: 'companyBankAccount',
  counterparty: 'counterPartyMaster',
  userMaster: 'userMaster',
  userInfo: 'UserInfo',
  paymentTerm: 'PaymentTermMain',
  contract: 'Contract',
  priceIndex: 'PriceIndexMain',
  trade: 'Trade',
  tradeObligation: 'TradeObligation',
  companyCalendarDetails: 'CompanyCalendarDetail',
  companyAddressDetails: 'AddressDetails',
  profitCenter: 'profitCenter',
  counterpartyAttachments: 'Attachments',
  counterpartyPaymentTerm: 'CPaymentTerm',
  counterpartyCommodity: 'Commodity',
  counterpartyBankAccount: 'BankAccountCp',
  counterpartyAddressDetails: 'AddressDetailsCp',
  counterpartyContactDetails: 'ContactDetails',
  paymentEvent: 'PaymentEvent',
  profitCenterConfig: 'ProfitCenterConfiguration',
  futureOptionPeriodCalendar: 'FutureOptionPeriodCalendar',
  fxIrPeriodCalendar: 'FxIrPeriodCalendar',
  fxIrPrice: 'FxIrPrice',
  periodCalendar: 'PeriodCalendar',
  physicalCommodityPrice: 'PhysicalCommodityPrice',
  futureCommodityPrice: 'FutureCommodityPrice',
  roleMaster: 'RoleMaster',
  cashforward: 'CashForward',
  inventory: 'StorageInventory',
  tradeAttachments: 'Attachment',
  fxTrade: 'fxTrade'
};

export const repos = {
  brandQualitySpec: 'brandQualitySpecRepo',  //veriy
  gradeQualitySpec: 'gradeQualitySpecRepo',
  calendarHolidays: 'calendarHolidaysRepo',
  calendarWorkingDays: 'calendarWorkingDaysRepo',
  commodityPackage: 'commodityPackageRepo',
  commodityExternalPackage: 'commodityExternalPackageRepo',
  commodityBrand: 'commodityBrandRepo',
  commodityGrade: 'commodityGradeRepo',
  commodityOrigin: 'commodityOriginRepo',
  commodityQualitySpec: 'commodityQualitySpecRepo',
  commodityCropSeason: 'commodityCropSeasonRepo',
  companyCalendarDetails: 'companyCalenderDetailRepo',
  companyAddressDetails: 'addressDetailsRepo',
  companyBankAccount: 'companyBankAccountRepo',
  counterpartyAttachments: 'attachmentsRepo',
  counterpartyPaymentTerm: 'cpaymentTermRepo',
  counterpartyCommodity: 'commodityRepo',
  counterpartyBankAccount: 'bankAccountCpRepo',
  counterpartyAddressDetails: 'addressDetailsCpRepo',
  counterpartyContactDetails: 'contactDetailsRepo',
  paymentEvent: 'paymentEventRepo',
  profitCenterConfig: 'profitCenterConfigurationRepo',
  futureOptionPeriodCalendar: 'futureOptionsPeriodCalendarRepo',
  fxIrPeriodCalendar: 'fxIrPeriodCalendarRepo',
  fxIrPrice: 'fxIrPriceRepo',
  periodCalendar: 'fxIrPeriodCalendarRepo',
  physicalCommodityPrice: 'physicalCommodityPriceRepo',
  futureCommodityPrice: 'futureCommodityPriceRepo'

};

export const tradeOptionsButtons = {
  sendToApproval: "Send To Approval",
  approve: 'Approve',
  approveForCounterparty: 'Approve for Counterparty',
  approveBoth: 'Approve both',
  approveForFinance: 'Approve For Finance',
  approveWithException: 'Approve With Exception',
  ammend: 'Ammend',
  unConfirm: 'Unconfirm',
  void: 'Void',
    generateProFormaInvoice: "Generate Pro Forma Invoice",
    generateAdvancedInvoice: "Generate Advanced Invoice",
    addCost: "Add Cost",
    generateDocument: "Generate Document",
};
