import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CtrmInputComponent),
  multi: true
};

@Component({
  selector: 'ctrm-input',
  templateUrl: './ctrm-input.component.html',
  styleUrls: ['./ctrm-input.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class CtrmInputComponent implements OnInit, OnChanges {
  @Input() type: string = 'text';
  @Input() placeholder: string = 'type your search ...';
  @Input() defaultValue: any;
  @Input() newValue: string = '';
  @Input('value') val: any;
  @Input() minWidth: string = '180px';
  @Output() onChangeValue = new EventEmitter();

  constructor() {
  }

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  onTouched: any = () => {
  };

  ngOnInit() {
    this.value = this.defaultValue;
  }

  onChange(val) {
    this.val = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
    }
  }

  outValue(value) {
    this.onChangeValue.emit(value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['newValue']) {
      this.value = changes['newValue'].currentValue;
    }
  }
}
