<div [hidden]="hideChild">
    <div class="sectionCardTop">
        <div class="breadcrumbPane">
            <ctrm-breadcrumb [collapsabel]="false"
                             [items]="breadCrumbService.getBreadCrumb()"
                             [showIcon]="false"></ctrm-breadcrumb>
        </div>
        <div class="actionPane">
            <div class="screenDetails">
                <img [src]="'../../../assets/images/svg/priceIndex.svg'"/>
                <p>Price Index</p>
            </div>
            <div class="autocomplete-box">
                <input [(ngModel)]="search" placeholder="Search" type="text">
                <mat-icon style="color:#cbcbcb">search</mat-icon>
            </div>

            <div class="rightInfo">
                Total {{size}} {{getTabLable(tabValue)}}
            </div>
        </div>
    </div>
    <div style="margin-left: 10px;">
        <div class="selectButtonClass">
            <p-selectButton [(ngModel)]="tabValue"
                            [options]="tabOptions" (onChange)="onTabChange()"></p-selectButton>
        </div>
    </div>
</div>
<div class="sectionCardTop" style="max-height: 84% !important;">
    <div *ngIf="tabValue === 'physical'">
        <app-ctrm-grid-with-tabs *ngIf="priceIndexLoadData !== undefined && priceIndexLoadData.length != 0" (onClickUniqueKey)="uniqueClick($event,'physical')"
                                 (onRowDataSave)="onRowSave($event,'physical')"
                                 [showSectionTop]="false"
                                 [showBreadcrumb]="false"
                                 (onClickBackIcon)="showMainScreen()"
                                 (onGoToNext)="showTopSection()"
                                 (totalNumberElements)="updateDataSize($event)"
                                 [grid]="physicalgrid" [search]="search"></app-ctrm-grid-with-tabs>
    </div>

    <div *ngIf="tabValue === 'future'">
        <app-ctrm-grid-with-tabs *ngIf="priceIndexLoadData !== undefined && priceIndexLoadData.length != 0" (onClickUniqueKey)="uniqueClick($event,'future')"
                                 (onRowDataSave)="onRowSave($event,'future')"
                                 [showSectionTop]="false"
                                 [showBreadcrumb]="false"
                                 (onClickBackIcon)="showMainScreen()"
                                 (onGoToNext)="showTopSection()"
                                 (totalNumberElements)="updateDataSize($event)"
                                 [grid]="futuregrid" [search]="search"></app-ctrm-grid-with-tabs>
    </div>

    <div *ngIf="tabValue === 'fx'">
        <app-ctrm-grid-with-tabs *ngIf="priceIndexLoadData !== undefined && priceIndexLoadData.length != 0" (onClickUniqueKey)="uniqueClick($event,'fx')"
                                 (onRowDataSave)="onRowSave($event,'fx')"
                                 [showSectionTop]="false"
                                 [showBreadcrumb]="false"
                                 (onClickBackIcon)="showMainScreen()"
                                 (onGoToNext)="showTopSection()"
                                 (totalNumberElements)="updateDataSize($event)"
                                 [grid]="fxgrid" [search]="search"></app-ctrm-grid-with-tabs>
    </div>

</div>

