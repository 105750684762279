import {Component, OnInit} from '@angular/core';
import {Tcolumn} from "../../grid/tcolumn.model";
import {Grid} from "../../grid/grid.model";
import {KeyValue} from "../../grid/key-value.model";
import {CommonService} from "../../services/common.service";
import {MasterService} from "../services/MasterService";
import {Validators} from "@angular/forms";
import {defaultValue} from "../../grid/defaultValue.model";
import {ChildAction} from "../../grid/ChildAction.model";
import {environment} from '../../../environments/environment';
import {Action} from "../../grid/actions.model";
import {ctrmValidators} from "../../services/validators/ctrmValidators";
import {entities} from "../../services/common/entities";
import {copyArrayItem} from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-transactionreference',
  templateUrl: './transactionreference.component.html',
  styleUrls: []
})
export class TransactionreferenceComponent implements OnInit {

  columns: Map<string, Tcolumn>;
  grid: Grid;
  groupList: KeyValue[] = [];
  refreshGrid:boolean =false;
  loadDataList: any[] = [];
  constructor(public commonService: CommonService, private masterCommonService: MasterService) {
  }

  ngOnInit() {
    this.commonService.getLoadData('commodity', ['data', 'data'], [[{key: 'name', value: 'transaction_ref_type_ind'
    }], [{key: 'name', value: 'transactional_reset_value_ind'}]]).subscribe((next: any) => {
      this.loadDataList = next;
      this.getColumns();
      this.grid = new Grid(this.columns, true, true, true, true, 'name', '/api/tranidformatmaster/v1/getalltransactionidformats?tenantID='+this.commonService.getFromStorage('tenantId'), '', 'Transaction ID Reference');
      this.grid.iconName = 'transactionIdRef';
      this.grid.hrefUrl =environment.base_url + '/api/tranidformatmaster/v1/getTransactionIDFormat?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
      this.grid.setSaveUrl(environment.base_url + '/api/tranidformatmaster/v1/createTransactionIDFormat?tenantId='+this.commonService.getFromStorage('tenantId'));
      this.grid.setUpdateUrl(environment.base_url + '/api/tranidformatmaster/v1/updateTransactionIDFormat?tenantId='+this.commonService.getFromStorage('tenantId'));
      this.grid.importUrl = environment.base_url + '/api/tranidformatmaster/v1/import?tenantId='+this.commonService.getFromStorage('tenantId')
    });
  }


  public getColumns() {
    let _this = this;
    let evergreenFunc = function evergreenFunction(value: any[] = []) {
      let obj: any = {meta: {disabled: false}};
      if (value[0].toString().toLowerCase() === 'evergreen') {
        obj = {meta: {disabled: true}};
      }
      return obj;
    };
    let startVal = function (value: any[] = []) {
      let num = parseInt(value[0]);
      let current = [Validators.required, Validators.maxLength(9)];
      if (num >= 4 && num <= 10) {
        let min = Math.pow(10, num - 1);
        current.push(Validators.min(min));
        current.push(Validators.max((min * 10) - 1));
      } else {
        current.push(Validators.min(4));
      }
      return current;
    };
    let defaultValS = function defaultValue(value: any[] = []) {
      let num = parseInt(value[0]);
      let current = 1000;
      if (num >= 4 && num <= 10) {
        current = Math.pow(10, num - 1);
      }
      return current;
    };
    let defaultValE = function defaultValue(value: any[] = []) {
      let num = parseInt(value[0]);
      let current = 9999;
      if (num >= 4 && num <= 10) {
        current = Math.pow(10, num) - 1;
      }
      return current;
    };
    let endVal = function (value: any[] = []) {
      let num = parseInt(value[0]);
      let current = [Validators.required, Validators.maxLength(9)];
      if (num >= 4 && num <= 10) {
        let max = Math.pow(10, num);
        current.push(Validators.max(max - 1));
        current.push(Validators.min(max / 10));
      } else {
        current.push(Validators.max(10));
      }
      return current;
    };
    let evergreenAction = new Action();
    evergreenAction.addFieldToUpdate('endValue', undefined, evergreenFunc, undefined, undefined, true, 'extra');
    evergreenAction.addFieldToUpdate('numberOfDigits', undefined, evergreenFunc, undefined, undefined, true, 'extra');

    let action = new Action();
    action.addFieldToUpdate('startValue', undefined, startVal, undefined, undefined, false, 'validators');
    action.addFieldToUpdate('startValue', undefined, defaultValS, undefined, undefined, false, 'defaultValue');
    action.addFieldToUpdate('endValue', undefined, defaultValE, undefined, undefined, false, 'defaultValue');
    action.addFieldToUpdate('endValue', undefined, endVal, undefined, undefined, false, 'validators');
    this.columns = new Map();
    let refFormat = function (value: any) {
      return _this.masterCommonService.getListFromArray(value[0][value[1]+'_transref_variable_grp'],false,false);
    };
    let generatePostObj = function (value:any) {
      let code =value[0]+'_transref_variable_grp';
      let payload:any[] = [];
      payload.push({
        tenantId: _this.commonService.getFromStorage('tenantId'),
        serviceName: 'xceler_configservice',
        model:'globalindicatordetails',
        requiredField:['name'],
        filter: [{key: 'name', value: code}]
      })
      return payload;
    }
    let actionTransactionType = new Action();
    actionTransactionType.addFieldToUpdate('referenceFormat', undefined, refFormat, ['DB','transactionType'], environment.base_url + '/api/commodity/v1/load', false, 'listvalues',false,false,undefined,undefined,generatePostObj);
    actionTransactionType.setCovertToListRequired(false);
    let actionRef = new Action();
    let refFormatSequence = function refFormat(value: any[] = []) {
      if (!value[0].includes('@Sequencenumber')) {
        if (value[0].length > 0 && value[0].charAt(value[0].length - 1) !== '_' && value[0].charAt(value[0].length - 1) !== '-' && value[0].charAt(value[0].length - 1) !== ',') {
          value[0] += '_';
        }
        value[0] += '@Sequencenumber'
      }
      return value[0];
    };
    actionRef.addFieldToUpdate('referenceFormat', undefined, refFormatSequence, undefined, undefined, false, 'defaultValue');
    actionRef.setToExtra('canPass', true);

    let startValue = function minVali(value: any[] = []) {
      let current = [Validators.min(parseInt(value[0]))];
      return current;
    };
    let startValaction = new Action();
    startValaction.addFieldToUpdate('endValue', undefined, startValue, undefined, undefined, false, 'validators');

    let endValue = function minVali(value: any[] = []) {
      let current = [Validators.max(parseInt(value[0]))];
      return current;
    };
    let endValidation = new Action();
    endValidation.addFieldToUpdate('startValue', undefined, endValue, undefined, undefined, false, 'validators');


    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('name', new Tcolumn('name', 'Transaction Reference Name', 'T', 1, false, undefined, true, new defaultValue('', '', environment.primaryKeyIncludedCharacters), 'B', [Validators.required, Validators.minLength(5)]));
    this.columns.set('transactionReferenceDescription', new Tcolumn('transactionReferenceDescription', 'Transaction Description', 'TA', 3, true, undefined, true, '', 'B', [Validators.maxLength(200)]));
    this.columns.set('transactionType', new Tcolumn('transactionType', 'Transaction Type', 'L', 4, true, this.masterCommonService.getListFromArray(this.loadDataList['transaction_ref_type_ind'],false,true), true, '', 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, actionTransactionType));
    this.columns.set('referenceFormat', new Tcolumn('referenceFormat', 'Reference Format', 'S', 5, true, undefined, true, new defaultValue('@Sequencenumber', undefined, ['-','_',',','@','/'],undefined,false,false), 'B', [Validators.required, ctrmValidators.referenceFormatValidator([',','_','-'])], undefined, 1, undefined, undefined, undefined, actionRef));
    this.columns.set('resetValue', new Tcolumn('resetValue', 'Reset Value', 'L', 6, true, this.masterCommonService.getListFromArray(this.loadDataList['transactional_reset_value_ind'],false,true), true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, evergreenAction));
    this.columns.set('numberOfDigits', new Tcolumn('numberOfDigits', 'Number of Digits', 'N', 7, true, undefined, true, 4, 'B', [Validators.required, ctrmValidators.maxLength(9), Validators.max(10), Validators.min(4)], undefined, 1, undefined, undefined, undefined, action, {meta: {disabled: false}}));
    this.columns.set('startValue', new Tcolumn('startValue', 'Start Value', 'N', 8, true, undefined, true, 1000, 'B', [Validators.required, ctrmValidators.maxLength(9), Validators.min(0)],undefined,1,undefined,undefined,undefined,startValaction));
    this.columns.set('endValue', new Tcolumn('endValue', 'End Value', 'N', 9, true, undefined, true, 9999, 'B', [Validators.required, ctrmValidators.maxLength(9), Validators.min(0)], undefined, 1, undefined, undefined, undefined, endValidation, {meta: {disabled: false}}));
    this.columns.set('transactionStatus', new Tcolumn('transactionStatus', 'Status', 'OB', 10, true, this.masterCommonService.getBooleanFromLabel('Active','Inactive',false,'Active','Inactive'), true, 'Active', 'B', [Validators.required]));

  }
  onRowSave(value: any) {
    let rowData = {};
    let result;
    let _this=this;
    this.refreshGrid = false;
    if(value['mode'] === 'update' || value['mode'] === 'copy') {
      rowData = value['result']['newData']['body'];
      } else if(value['mode'] === 'save') {
         rowData = value['result'];
       }
      if(rowData['status']){
        let def = new ChildAction();
        def.setMode('makeDefault');
        def.setCommonService(this.commonService);
        def.addCriteria('transactionType', rowData['transactionType']);
        def.addCriteria('name', rowData['name'], 'notEqual');
        def.addRepo('TransactionReferenceMaster', 'transactionReferenceRepo', 'status','status');
        def.perform().subscribe(next => {
          _this.refreshGrid=true;
        });
      }
     }
}
