<p-dialog (onHide)="closeModal()" [(visible)]="display" [baseZIndex]="9999"
          [closeOnEscape]="true"
          [contentStyle]="" [draggable]="false" [maximizable]="false" [modal]="true"
          [positionTop]="96"
          [style]="{ width: '40%',height: dialogHeight+'em',maxHeight:'80%', padding:'0px',overflowY:'inherit',backgroundColor:'#ffffff'}">
    <p-header style="font-size: 17px;color: #333333;">{{heading}}</p-header>
    <div class="rounded shadow" style="user-select: none">
        <div class="relative mb-2 bg-slate-300 pl-5 pding">
                <span class="-top-1.5 grid w-full grid-cols-3">
                  <span class="actionColumn" style="justify-self: left">Audit Event</span>
                  <span class="actionColumn" style="justify-self: left">User</span>
                  <span class="actionColumn" style="justify-self: left">Time Stamp</span>
                </span>
        </div>

        <div class="style-4 valuebox" [style.height]="(dialogHeight - 6)+'em'">
            <div *ngFor="let audit of dailogdata,index as i"
                 [class]=" i !== dailogdata.length -1 ?'log relative border-l-2 pdgrid' : 'log relative pdgrid'">
                <span class="absolute -top-1.5 grid w-full grid-cols-3 row-head">
                  <span>{{audit.entityName | camel}} {{audit.first ? "Added" : "Updated"  }}</span>
                  <span>{{audit.first ? audit.payload.createdBy : audit.payload.updatedBy}}</span>
                  <span>{{ commonService.convertDatetoLocaltimzoeDate(audit.createdTimestamp) | date : 'short'}}</span>
                </span>
                <span class="mt-1">
                  <div class="h-2"></div>
                  <span *ngIf="!audit.expanded" style="display: flex;flex-flow: wrap;">
                    <div *ngFor="let singlechange of audit.summary,index as i" class="infoCard ng-star-inserted"
                         style="padding-right: 1em;padding-bottom: 1em;width: 25%;">
                      <div class="infoHeading">
                          {{singlechange['label'] | camel }}
                      </div>
                      <div class="infoContent">
                          <ng-container [ngTemplateOutlet]="audit.first?firstsection:notfirstsection"
                                        [ngTemplateOutletContext]="{singlechange:singlechange}"></ng-container>

                      </div>
                  </div>
                </span>


                    <span *ngIf="audit.expanded" style="display: flex;flex-flow: wrap;" >
                        <div *ngFor="let singlechange of audit.differenceList,index as i" class="infoCard ng-star-inserted" style="padding-right: 1em;padding-bottom: 1em;width: 25%;overflow-wrap: anywhere;">
                          <div class="infoHeading">
                              {{singlechange['label'] | camel }}
                          </div>
                          <div class="infoContent">
                          <ng-container [ngTemplateOutlet]="audit.first?firstsection:notfirstsection"
                                        [ngTemplateOutletContext]="{singlechange:singlechange}"></ng-container>
                          </div>
                      </div>
                    </span>
                    <div *ngIf="audit.differenceList.length > 4" (click)="toggleViewMore(audit)">
                        <p class="moreless">{{getExpandLabel(audit) }}</p>
                      </div>
                </span>
                <span layout="row"><hr flex/></span>
            </div>
        </div>
    </div>
</p-dialog>

<ng-template #firstsection let-singlechange="singlechange">{{singlechange['newValue'] }}</ng-template>
<ng-template #notfirstsection let-singlechange="singlechange">
    {{(singlechange['oldValue']?singlechange['oldValue']:'●')}} &#8594; {{singlechange['newValue']?singlechange['newValue']:'-' }}
</ng-template>
