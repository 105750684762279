import {Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {CommonService} from "../../services/common.service";
import {ConfirmationService, MenuItem, MessageService, SelectItem, Table} from "primeng";
import {BreadcrumbService} from "../../Components/ctrm-breadcrumb/breadcrumb.service";
import {environment} from "../../../environments/environment";
import {HttpErrorResponse} from "@angular/common/http";
import {Router} from "@angular/router";
import { Subject } from 'rxjs';

@Component({
  selector: 'app-stock-planning',
  templateUrl: './stock-planning.component.html',
  styleUrls: ['./stock-planning.component.css']
})
export class StockPlanningComponent implements OnInit {
  globalFilterValue: string = '';
  tabValue: string;
  planData: any[] = [];
  tabOptions: SelectItem[] = [];
  buyTotal: number = 0;
  storageTotal: number = 0;
  buyValue: any[];
  storageValue: any[];
  balanceFunc: any;
  balanceRightFunc: any;
  inventoryHeight: string = '';
  selectedBuyRows: any[] = [];
  selectedInventoryRows: any[] = [];
  buyCols: any[];
  inventoryCols: any[];
  planInventoryCols: any[];
  expandPlannedCols: any[];
  display: boolean = false;
  inventorydisplay: boolean = false;
  matchDisplay: boolean = false;
  selectedAlgo: string = '';
  errorMessage: any[] = [];
  selectedPlans: any[] = [];
  oblRow: any;
  rowData: any = {};
  inventoryRow: any;
  sidebarPosition: any = 'right';
  tradeId: any = '';
  storageId: any = '';
  storageUuid: any = '';
  visibleVesselAllocation: boolean = false;
  addedFilter: any[] = [];
  refreshFilter: boolean = false;
  planningGridHeight: string = '';
  reset: boolean = false;
  //draw
  sellTotal: number = 0;
  stockTotal: number = 0;
  stockValue: any[];
  sellValue: any[];
  inventoryBuildValue: any[];
  inventoryStockValue: any[];
  selectedSellRows: any[] = [];
  selectedStockedRows: any[] = [];
  sellCols: any[];
  stockedCols: any[];
  balanceSellFunc: any;
  balanceStockFunc: any;
  childRowData: any;
  displaySideBar: boolean = false;
  //pagination
  currentPageNumber:number = 0;
  currentPageNumberBuy:number = 0;
  currentPageNumberSell:number = 0;
  currentPageInventoryBuild:number = 0;
  currentPageInventoryStock:number = 0;
  pageSize:number = 0;
  resetBuy: boolean = false;
  resetSell: boolean = false;
  workingDraw: boolean = false;
  totalElementsBuy: number = 0;
  totalPagesBuy:number = 0;
  pageSizeSell:number = 0;
  pageSizeBuy:number = 0;
  totalElementsSell: number = 0;
  totalPagesSell:number = 0;
  pageSizePlan:number = 0;
  totalElementsPlan: number = 0;
  totalPagesPlan:number = 0;
  filterListBuy: any[] = [];
  filterListInventoryBuild: any[] = [];
  filterListInventoryStock: any[] = [];
  filterListSell: any[] = [];
  totalElements: number = 0;
  totalPages:number = 0;
  onRefresh:boolean = false;
  pageSizeInventoryBuild:number = 0;
  pageSizeInventoryStock:number = 0;
  totalElementsInventoryBuild:number = 0;
  totalElementsInventoryStock:number = 0;
  totalPagesInventoryBuild:number = 0;
  totalPagesInventoryStock:number = 0;
  resetInventorybuild: boolean = false;
  resetInventorystock: boolean = false;
  inventoryBuildtotal:number = 0;
  inventoryStocktotal:number = 0;
  currentPageNumberPlan:number = 0;
  quantityRoundOff:number=3;


  planTableHeight: string = '';
  @ViewChild('stockPlanningTable', {static: true}) stockPlanningTable!: Table;
  @ViewChild('inventory') inventory: ElementRef;
  available: number = 0;
  partiallyPlanned: number = 0;
  fullyPlanned: number = 0;
  tradeInfoPos: any;
  inventoryInfoPos: any;
  buildApiFunc:Function;
  stockApiFunc:Function;

//try
  @Input() showCheckBox: boolean = true;
  @Input() showheaderCheckbox: boolean = true;
  @Input() primaryKey: string;
  //Sending the Post data (mapping obligstions to storage)
  storageInventoryId: any[] = [];
  selectedBuyObl: any[] = [];
  selectedSellObl: any[] = [];
  errorMessagesList: any[] = [];
  warningMessageList: any[] = [];
  displayWarningBox: boolean = false;
  warningHeading: any = '';
  //nodata box
  noDataBoxLeft: any = '';
  loadingMessage: string = "No Data Available";
  isRefreshing: boolean = false;
  selectedChildRowData: any;
  buildBuyFilter:any = {};
  buildSellFilter:any = {};
  buildInventory:any = {};
  stockInventory:any ={};
  onRefreshInventory: boolean = false;
  onRefreshScreen: Subject<void> = new Subject<void>();
  params:any = {};

  constructor(public commonService: CommonService, private messageService: MessageService, public breadCrumbService: BreadcrumbService, private router: Router,private confirmationService: ConfirmationService) {
}
  @HostListener('window:resize', [])
  public onResize() {
    this.calculateHeight();
    this.calculatePlanHeight(this.stockPlanningTable);
  }

  private draw() {
    this.workingDraw = true;
    if(this.checkDraw(this.selectedSellRows, this.selectedStockedRows, this.stockTotal, this.sellTotal)) {
      this.continueDraw();
    }
  }

  private continueDraw() {
    let _this = this;
    let stockDrawPayLoad: {};
    stockDrawPayLoad = {
      planType: 'draw',
      stockPlanMap: {}
    };
    stockDrawPayLoad['stockPlanMap'][this.storageInventoryId] = this.selectedSellObl;
    this.commonService.post(environment.base_url+ '/api/stockPlanning/v1/createStockPlan?tenantId='+this.commonService.getFromStorage('tenantId'),stockDrawPayLoad).subscribe((resp) => {
      _this.onStockDone(resp);
    })
  }

  allocate(draw:boolean = false) {
    this.displaySideBar= false;
    this.visibleVesselAllocation = true;
  }

  ngOnInit(): void {
    let _this = this;
    this.breadCrumbService.makeBreadcrumbTo(this.commonService.OPERATION_MAP_KEY, '/stockPlanning');
    this.tabOptions = [
      {label: 'Build Stock', value: 'build'},
      {label: 'Draw Stock', value: 'draw'},
      {label: 'Storage Inventory Details', value: 'storageInventory'}];

    //Build Stock
    this.tabValue = 'build';
    this.balanceFunc = function (data) {
      // return data['tradeQuantity'] - data['plannedQuantity'] //backend
      return data['plannedQuantity'];
    };
    this.balanceRightFunc = function (data) {
      return data['availableCapacity'];
    };
    let df=this.quantityRoundOff;

    //Build

    this.getbuyTradeData();
    this.getinventoryData();
    this.getPlans();
    this.buyValue = [];
    this.storageValue = [];

    //Draw Stock
    this.balanceSellFunc = function (data) {
      // return data['tradeQuantity'] - data['plannedQuantity'] //backend
      return data['tradeQuantity'];
    };
    this.balanceStockFunc = function (data) {
      return data['availableCapacity'];
    };
    this.stockValue = [];
    this.sellValue = [];


    let viewPriceFunction = function (value: any) {
      return _this.commonService.getFormatedNumber(value['tradePrice']) + ' ' + value['tradePriceCurrency'] + '/' + value['tradePriceUom'];
    };

    let viewBuySellQuantity = function (value: any) {
      return _this.commonService.getFormatedNumber(value['tradeQuantity']) + ' ' + value['quantityUOM'];
    };

    //BUY Column Heading
    this.buyCols = [
      {field: 'tradeObligationId', header: 'Obligation Id', primaryKey: true,showFilter: true},
      {field: 'loadLocation', header: 'Load Location',showFilter: true},
      {field: 'unloadLocation', header: 'Discharge Location',showFilter: true},
      {field: 'incoterm', header: 'Incoterm',showFilter: true},
      {field: 'profitcenter', header: 'Profit Center',showFilter: true},
      {field: 'company', header: 'Company',showFilter: true},
      {field: 'counterparty', header: 'Counterparty',showFilter: true},
      {field: 'tradePrice', header: 'Price' , valueFunction:viewPriceFunction},
      {field: 'commodity', header: 'Commodity',showFilter: true},
      {field: 'tradeQuantity', header: 'Quantity',valueFunction: viewBuySellQuantity},
      {field: 'CB', header: 'CB'},
      {field: 'matchQuantity', header: 'Match Quantity',editable: true},
      {field: 'balanceQuantity', header: 'Balance Quantity'}
    ];

    //Sell Obligation Column Heading
    this.sellCols = [
      {field: 'balanceQuantity', header: 'Balance Quantity'},
      {field: 'matchQuantity', header: 'Match Quantity',editable: true},
      {field: 'CB', header: 'CB'},
      {field: 'tradeQuantity', header: 'Quantity',valueFunction: viewBuySellQuantity},
      {field: 'commodity', header: 'Commodity',showFilter: true},
      {field: 'tradePrice', header: 'Price',valueFunction:viewPriceFunction},
      {field: 'counterparty', header: 'Counterparty',showFilter: true},
      {field: 'company', header: 'Company',showFilter: true},
      {field: 'profitcenter', header: 'Profit Center',showFilter: true},
      {field: 'incoterm', header: 'Incoterm',showFilter: true},
      {field: 'location', header: 'Location',showFilter: true},
      {field: 'tradeObligationId', header: 'Obligation Id', primaryKey: true,showFilter: true}
    ];

    //storage Inventory Column Heading
    this.inventoryCols = [
      {field: 'balanceCapacity', header: 'Balance Capacity'},
      {field: 'matchCapacity', header: 'Match Capacity'},
      {field: 'CB', header: 'CB'},
      {field: 'totalStorageCapacity', header: 'Total Capacity',numberFormat:true},
      {field: 'availableCapacity', header: 'Avaialble Capacity',numberFormat:true},
      {field: 'stockedQuantity', header: 'Stocked Quantity',numberFormat:true},
      {field: 'storageUom', header: 'Storage UOM'},
      {field: 'storageCurrency', header: 'Storage Currency'},
      {field: 'profitcentre', header: 'Profit Center',showFilter: true},
      {field: 'company', header: 'Company',showFilter: true},
      {field: 'commodity', header: 'Commodity',showFilter: true},
      {field: 'comingle', header: 'Comingle'},
      {field: 'virtualStorage', header: 'Virtual Storage',numberFormat:true},
      {field: 'type', header: 'Type',showFilter: true},
      {field: 'location', header: 'Location',showFilter: true},
      {field: 'storageName', header: 'Name',showFilter: true},
      {field: 'storageId', header: 'Storage Id', primaryKey: true,showFilter: true}
    ];

    //Storage Inventory Column Heading for Draw
    this.stockedCols = [
      {field: 'storageId', header: 'Storage Id', primaryKey: true,showFilter: true},
      {field: 'storageName', header: 'Name',showFilter: true},
      {field: 'location', header: 'Location',showFilter: true},
      {field: 'type', header: 'Type',showFilter: true},
      {field: 'virtualStorage', header: 'Virtual Storage',numberFormat:true},
      {field: 'comingle', header: 'Comingle'},
      {field: 'commodity', header: 'Commodity',showFilter: true},
      {field: 'company', header: 'Company',showFilter: true},
      {field: 'profitcentre', header: 'Profit Center',showFilter: true},
      {field: 'drawPrice', header: 'Draw Price'},
      {field: 'storageCurrency', header: 'Storage Currency'},
      {field: 'storageUom', header: 'Storage UOM'},
      {field: 'availableCapacity', header: 'Available Capacity'},
      {field: 'totalStorageCapacity', header: 'Total Capacity'},
      {field: 'stockedQuantity', header: 'Stocked Quantity',numberFormat:true},
      {field: 'CB', header: 'CB'},
      {field: 'matchCapacity', header: 'Match Capacity'},
      {field: 'balanceCapacity', header: 'Balance Capacity'}
    ];

    let viewComingle = function (value) {
      if (value['comingle']) {
        return 'Comingle'
      } else {
        return 'Non-Comingle'
      }
    };

    let viewVirtualStorage = function (value) {
      if (value['physical']) {
        return 'Physical'
      } else {
        return 'Virtual'
      }
    };

    let tenantConfig=JSON.parse(this.commonService.getFromStorage("tenantConfig"));
    if(tenantConfig && tenantConfig.roundingFormat){
      _this.quantityRoundOff= tenantConfig.roundingFormat.quantityRounding;
    }
    else{
      _this.quantityRoundOff=3;
    }

    let stockedQuantityFunction = function (value: any) {
      return _this.commonService.formatNumberWithoutComma(value['stockedQuantity'],df);
    };

    //Planning Screen Storage Inventory Column Heading
    this.planInventoryCols = [
      {field: 'storageId', header: 'Storage Id'},
      {field: 'storageName', header: 'Name'},
      {field: 'ownerName', header: 'Storage Owner'},
      {field: 'type', header: 'Type'},
      {field: 'totalStorageCapacity', header: 'Total Capacity',numberFormat:true},
      {field: 'stockedQuantity', header: 'Stocked Quantity',numberFormat:true,valueFunction:stockedQuantityFunction},
      {field: 'storageUom', header: 'Storage UOM'},
      {field: 'storageCurrency', header: 'Storage Currency'},
      {field: 'location', header: 'Location'},
      {field: 'comingle', header: 'comingle', valueFunction: viewComingle},
      {field: 'company', header: 'company'},
      {field: 'profitcentre', header: 'Profit Center'},
      {field: 'commodity', header: 'Commodity'},
      {field: 'virtualStorage', header: 'Virtual Storage', valueFunction: viewVirtualStorage},
      {field: 'valuationMethod', header: 'Valuation Method'},
      {field: 'eodCurrency', header: 'EoD Currency'},
      {field: 'eodUom', header: 'EoD UoM'},
      {field: 'status', header: 'Storage Status'},
    ];

    let viewBuildDrawQuantity = function (value: any) {
      return _this.commonService.getFormatedNumber(value['quantity']) + ' ' + value['quantityUom'];
    };

    let viewstockPrice = function (value: any) {
      return _this.commonService.getFormatedNumber(value['stockPrice']) + ' ' + value['priceCurrency'] + '/' + value['priceUom'];
    };

    //Expaned Row Column Heading
    this.expandPlannedCols = [
      {field: 'stockId', header: 'Stock Id'},
      {field: 'tradeId', header: 'Trade Id'},
      {field: 'plannedObligationId', header: 'Planned Obligation Id'},
      {field: 'planningId', header: 'stock Plan'},
      {field: 'stockStatus', header: 'Stock Status'},
      {field: 'commodity', header: 'Commodity'},
      {field: 'quantity', header: 'Build/Draw Quantity',valueFunction:viewBuildDrawQuantity},
      {field: 'stockPrice', header: 'Stock Price',numberFormat: true,valueFunction:viewstockPrice},
      {field: 'stockPlanType', header: 'Build/Draw'},
      {field: 'stockCreatedOn', header: 'CreatedOn',type:'date'},
      {field: 'counterparty', header: 'Counterparty'},
      {field: 'profitcenter', header: 'Profit Center'},
      {field: 'incoterm', header: 'Incoterm'},
      {field: 'location', header: 'Trade Location'},
      {field: 'cropOrigin', header: 'CommodityOrigin'},
      {field: 'grade', header: 'Grade'},
      {field: 'broker', header: 'Broker'},
      {field: 'deliveryStartDate', header: 'Delivery Start Date',type:'date'},
      {field: 'deliveryEndDate', header: 'Delivery End Date',type:'date'},
    ];

    setTimeout(() => {
      var scrollContainer = $('.style-4.table-responsive.background.right');
      var maxScrollLeft = $(scrollContainer)[0].scrollWidth - $(scrollContainer).width();
      $(".style-4.table-responsive.background.right").scrollLeft(maxScrollLeft);
    }, 500)
    this.buildApiFunc = function(postbuttonapiobj) {  //Build API Function
      let params:any = postbuttonapiobj.params;
      if((params !== null && params !== undefined && Object.keys(params).length > 0 && params['continueBuild'] !== null && params['continueBuild'] !== undefined && params['continueBuild'])
          || _this.checkBuild(_this.selectedBuyRows, _this.selectedInventoryRows, _this.buyTotal, _this.storageTotal)) {
        let stockBuildPayLoad: {};
        stockBuildPayLoad = {
          planType: 'build',
          stockPlanMap: {}
        };
        _this.reset = false;
        stockBuildPayLoad['stockPlanMap'][_this.storageInventoryId] = _this.selectedBuyObl;
        postbuttonapiobj['url'] = environment.base_url + '/api/stockPlanning/v1/createStockPlan?tenantId='+_this.commonService.getFromStorage('tenantId');
        postbuttonapiobj['type'] = 'post';
        postbuttonapiobj['payload'] = stockBuildPayLoad;
        return postbuttonapiobj;
      }
      return postbuttonapiobj;
    }

    this.stockApiFunc = function(postbuttonapiobj) { //Stock API Function
      let params:any = postbuttonapiobj.params;
      if((params !== null && params !== undefined && Object.keys(params).length > 0 &&  params['continueStock'] !== null && params['continueStock'] !== undefined && params['continueStock'])
          || _this.checkDraw(_this.selectedSellRows, _this.selectedStockedRows, _this.stockTotal, _this.sellTotal)) {
        let stockDrawPayLoad: {};
        stockDrawPayLoad = {
          planType: 'draw',
          stockPlanMap: {}
        };
        stockDrawPayLoad['stockPlanMap'][_this.storageInventoryId] = _this.selectedSellObl;
        postbuttonapiobj['url'] = environment.base_url+ '/api/stockPlanning/v1/createStockPlan?tenantId='+_this.commonService.getFromStorage('tenantId');
        postbuttonapiobj['type'] = 'post';
        postbuttonapiobj['payload'] = stockDrawPayLoad;
        return postbuttonapiobj;
      }
      return postbuttonapiobj;
    }

  }

  public getPlans() {
    let _this = this;
    if(this.stockPlanningTable !== null && this.stockPlanningTable !== undefined) {
      this.stockPlanningTable.expandedRowKeys = {};
    }
    this.planData = [];
    this.commonService.getJSONByURL(environment.base_url + '/api/storageInventory/v1/getAllStorageInventory?tenantId='+this.commonService.getFromStorage('tenantId')+
        '&page='+this.currentPageNumberPlan+'&size=20').subscribe((next: any[]) => {
      _this.planData = next['content'];
      _this.pageSizePlan = next['pageable']['pageSize'];
      _this.totalElementsPlan = next['totalElements'];
      _this.totalPagesPlan = next['totalPages'];
      _this.available = 0;
      _this.partiallyPlanned = 0;
      _this.fullyPlanned = 0;
      next['content'].forEach(function (value) {
        if (value['status'] === 'AVAILABLE') {
          _this.available++;
        } else if (value['status'] === 'PARTIALLY_AVAILABLE') {
          _this.partiallyPlanned++;
        } else if (value['status'] === 'FULL') {
          _this.fullyPlanned++;
        }
      });
    });
  }

  getTypeLabel(type: any) {
    if (type === 'Available') {
      return 'Inventory Available';
    } else if (type === 'Partially_Available') {
      return 'Inventory Partially Available';
    } else if (type === 'Full') {
      return 'Inventory Fully Planned';
    }
  }

//to set total on the basis of buy Trade or storage
  onTotalChange(value: any, mode) {
    if (mode === 'buyTrade') {
      this.buyTotal = value;
    } else {
      this.storageTotal = value;
    }
  }

//to set total on basis of sell trade or stock
  onTotal(value: any, mode) {
    if (mode === 'stocked') {
      this.stockTotal = value;
    } else {
      this.sellTotal = value;
    }
  }

  //Populate array of selected buy Trade or inventory array
  onRowSelect(value: any, mode) {
    if (mode == 'buyTrade') {
      this.selectedBuyRows = value;
    } else {
      this.selectedInventoryRows = value;
    }
  }

  //Populate array of selected sell Trade or stock
  onRowSelectStock(value: any, mode) {
    if (mode == 'stocked') {
      this.selectedStockedRows = value;
    } else {
      this.selectedSellRows = value;
    }
  }

  //Func to delete the Plan
  onRowDelete(rowData) {
    //calling Delete REST
    let _this = this;
    this.commonService.post(environment.base_url + '', rowData).subscribe(next => {
      _this.refreshTabs();
      _this.showToast('Storage Inventory Plan has been successfully deleted !');
    });
  }

  //Get the Date From TimeStamp
  getDate(rowDatum: any) {
    return rowDatum.substring(0, 10);
  }

  //Go back to Dashboard
  goBack() {
    this.commonService.navigateTo('/dashboard');
  }

  getClassForHeading(index) {
    return index > 0 ? 'headerNormal' : 'noPipe';
  }

  onInventoryClose(value: any) {
    this.storageId = '';
    this.inventoryRow = null;
    this.inventorydisplay = false;
  }

  onClose(value: any) {
    this.tradeId = '';
    this.oblRow = null;
    this.display = false;
  }

  openMatchDialog() {
    this.matchDisplay = !this.matchDisplay;
  }

  getAlgoClass(btn) {
    if (btn['isDisable'] === false) {
      if (this.selectedAlgo == btn['title']) {
        return 'algoBtnSelected';
      } else {
        return 'algoBtn';
      }
    } else {
      return 'algoBtnDisabled';
    }
  }

  closeMatchDialog() {
    this.errorMessage = [];
    this.selectedAlgo = '';
    this.matchDisplay = false;
  }

  showMessage(msg, severity: string = 'info') {
    this.messageService.add({
      severity: severity,
      summary: msg
    });
  }

  refreshTabs() {
    this.getPlans();
  }

// ***Inventory Status Label*****
  onClicklabel(type: string, table) {
    this.addedFilter = [];
    this.addedFilter.push(type);
    table.filter(type, 'status', 'equals');
  }


  onClickRemove(table: Table) {
    table.reset();
    this.addedFilter = [];
  }

  //Toast Message for Build Stock
  showToast(stockPlanId) {
    this.messageService.add({
      severity: 'success',
      summary: 'SuccessFully created StockPlan with Id ' + stockPlanId[0]
    });
  }


  continuePlanning() {
    this.closeMatchDialog();
  }

  getContPlanValidation() {
    if (this.errorMessage.length != 0 || (this.buyTotal == 0 && this.storageTotal == 0) || (this.buyTotal !== this.storageTotal) || (this.sellTotal == 0 && this.stockTotal == 0) || (this.sellTotal !== this.stockTotal)) {
      return true;
    }
  }

  getFilterValue($event) {
    this.globalFilterValue = event.target['value'];
  }

  getPagination() {
    return this.totalElementsPlan > this.pageSizePlan;
  }

  planRefresh() {
    this.planData = [];
    this.getPlans();
  }

  calculateHeight() {
    let offsetTop = this.inventory.nativeElement['offsetTop'];
    let offsetHeight = this.inventory.nativeElement['offsetParent']['offsetHeight'];
    this.inventoryHeight = offsetHeight - (offsetTop + 100) + 'px';
  }

  calculatePlanHeight(stockPlanningTable: Table) {
    if (stockPlanningTable !== undefined && stockPlanningTable !== null) {
      this.stockPlanningTable = stockPlanningTable;
      let offsetTop = stockPlanningTable.el.nativeElement['offsetTop'];
      let offsetHeight = stockPlanningTable.el.nativeElement['offsetParent']['offsetHeight'];
      this.planningGridHeight = offsetHeight - (offsetTop + 23) + 'px';
    }
  }

  ngAfterViewInit() {
    this.calculateHeight();
  }

  switchTab(tabName) {
    this.tabValue = tabName;
  }


  getHeaderClass(header, field) {
    let classStyle = '';
    classStyle = this.getPrimaryKeyClass(field);
    if (header === 'CB') {
      classStyle += ' StickHeaderCheckbox';
    }
    return classStyle;
  }

  getPrimaryKeyClass(field: any) {
    let classStyle = '';
    if (field === this.primaryKey) {
      classStyle += ' stickHeader';
    }
    return classStyle;
  }

  selectedStorageRows(rowData: any) {
    this.storageInventoryId = rowData[0]['uuid'];
  }

  selectBuyRows(rowData: any) {
    this.selectedBuyObl = rowData;

  }

  selectSellRows(rowData: any) {
    this.selectedSellObl = rowData;
  }

  onTabChange(tabData: any) {
    let tabName = tabData['option']['value'];
    this.reset = false;
    this.displaySideBar = false;
    if (tabName === 'build') {
      this.getbuyTradeData();
      this.getinventoryData();
    } else {
      this.getsellTradeData();
      this.getstockData();
    }
  }

  fetchChildData(value: any) {
    let storageId = value['data']['uuid'];
    this.commonService.getJSONByURL(environment.base_url + '/api/stockPlanning/v1/getStockByStorageUuid?storageUuid='+storageId+'&tenantId='+this.commonService.getFromStorage('tenantId')).subscribe((response: any) => {
      value['data']['stock'] = response;
    });
  }

  onUniqueClick(value: any) {
    this.display = true;
    this.childRowData = value;
  }


  openTradeInfo(value: any, pos) {
    this.tradeId = value['tradeId'];
    this.oblRow = value;
    this.tradeInfoPos = pos;
    this.display = true;
  }

  openInventoryInfo(value: any, pos) {
    this.storageId = value['storageId'];
    this.storageUuid = value['uuid'];
    this.inventoryRow = value;
    this.inventorydisplay = true;
    this.inventoryInfoPos = pos;
  }

  onClickStorageId(value: any){
    this.storageId = value['storageId'];
    this.storageUuid = value['storageUuid'];
    this.inventoryRow = value;
    this.inventoryInfoPos = "right";
    this.inventorydisplay = true;
  }

  onClickTradeId(value:any){
    this.tradeId = value['tradeId'];
    this.oblRow = value;
    this.tradeInfoPos = "right";
    this.display = true;
  }

  onPrimaryClickInfo(col: any) {
    if (col['fieldName'] === 'storageId') {
      this.onClickStorageId(col['rowData']);
    } else {
      this.onClickTradeId(col['rowData']);
    }
  }

  resetErrorAndWarnignList() {
    this.errorMessagesList = [];
    this.warningMessageList = [];
    this.displayWarningBox = false;
  }

  checkBuild(selectedBuyRows: any[], selectedInventoryRows: any[], buyTotal: number, storageTotal: number) {
    let isPassed = true;
    let _this = this;
    let commodityList: any[] = [];
    this.resetErrorAndWarnignList();
    this.warningHeading = 'Build Stock Planning';
    if (buyTotal !== storageTotal) {
      isPassed = false;
      this.errorMessagesList.push("Match quantity for Buy Obligation and Storage Inventory is not equal.")
    }
    selectedBuyRows.forEach(function (buyObligation: any) {
      commodityList = selectedInventoryRows[0]['commodity'].split(',');
      if (buyObligation['profitcenter'] !== selectedInventoryRows[0]['profitcentre']) {
        isPassed = false;
        _this.warningMessageList.push("Profit Center for Buy obligation '" + buyObligation['tradeObligationId'] + "' and for Storage Inventory '" + selectedInventoryRows[0]['storageId'] + "' is not same.");
      }
      if (!commodityList.includes(buyObligation['commodity'])) {
        isPassed = false;
        _this.warningMessageList.push("Commodity for Buy obligation '" + buyObligation['tradeObligationId'] + "' and for Storage Inventory '" + selectedInventoryRows[0]['storageId'] + "' is not same.");
      }
      if (buyObligation['quantityUOM'] !== selectedInventoryRows[0]['storageUom']) {
        isPassed = false;
        _this.errorMessagesList.push("Uom for Buy obligation '" + buyObligation['tradeObligationId'] + "' and for Storage Inventory '" + selectedInventoryRows[0]['storageId'] + "' is not same.");
      }
    });
    if (!isPassed) {
      this.displayWarningBox = true;
    }
    return isPassed;
  }

  checkDraw(selectedSellRows: any[], selectedStocks: any[], stockTotal: number, sellTotal: number) {
    let isPassed = true;
    let _this = this;
    let commodityList: any[] = [];
    this.resetErrorAndWarnignList();
    this.warningHeading = 'Draw Stock Planning';
    if (sellTotal !== stockTotal) {
      isPassed = false;
      this.errorMessagesList.push("Match quantity for Stock and Sell obligation is not equal.")
    }
    selectedSellRows.forEach(function (sellObligation: any) {
      commodityList = selectedStocks[0]['commodity'].split(',');
      if (sellObligation['profitcenter'] !== selectedStocks[0]['profitcentre']) {
        isPassed = false;
        _this.errorMessagesList.push("Profit Center for Sell obligation '" + sellObligation['tradeObligationId'] + "' and for Storage Inventory '" + selectedStocks[0]['storageId'] + "' is not same.");
      }
      if (!commodityList.includes(sellObligation['commodity'])) {
        isPassed = false;
        _this.errorMessagesList.push("Commodity for Sell obligation '" + sellObligation['tradeObligationId'] + "' and for Storage Inventory '" + selectedStocks[0]['storageId'] + "' is not same.");
      }
    });
    if (!isPassed) {
      this.displayWarningBox = true;
    }
    return isPassed;
  }

  onCloseWarningBox() {
    this.displayWarningBox = false;
  }

  onContinue(value: any) {
    this.displayWarningBox = false;
    if (this.warningHeading === 'Build Stock Planning') {
      this.params = {continueBuild:true};
    } else if (this.warningHeading === 'Draw Stock Planning') {
      this.params = {continueStock:true};
    }
  }


  getFilterValuesForBuy() {
    this.commonService.getJSONByURL(environment.base_url + '/api/stockPlanning/v1/getobligationforstockplanningfilter?tenantId='
        +this.commonService.getFromStorage('tenantId')).subscribe((next: any[]) => {
      this.filterListBuy = next;
    });
  }

  getFilterValuesForSell() {
    this.commonService.getJSONByURL(environment.base_url + '/api/tradeobligation/v1/gettradeobligationsforplanning?tenantId='
        +this.commonService.getFromStorage('tenantId')+'&transactionType=SELL').subscribe((next: any[]) => {
      this.filterListSell = next;
    });
  }

  getfilterForInventoryBuild() {
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url + '/api/storageInventory/v1/getfilterdataforinventorybuild?tenantId='+this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
      _this.filterListInventoryBuild = next;
    });
  }

  getfilterForInventoryStock() {
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url + '/api/storageInventory/v1/getfilterdataforinventorystock?tenantId='+this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
      _this.filterListInventoryStock = next;
    });
  }


  //get BuySell Trade Obligation(FOR BUY)
  private getbuyTradeData(resetValues:boolean = true) {
    let _this = this;
    this.reset = false;
    this.buyValue = [];
    if(resetValues) {
      this.buyTotal = 0;
      this.resetBuy = true
      this.totalElementsBuy = 0;
      this.totalPagesBuy = 0;
    }
      this.commonService.getJSONByURL(environment.base_url + '/api/stockPlanning/v1/getallbuyobligationforstock?tenantId='
          + this.commonService.getFromStorage('tenantId') + '&page=' + this.currentPageNumberBuy + '&size=20').subscribe((response: any[]) => {
      this.reset = true;
      _this.buyValue = response['content'];
      _this.pageSizeBuy = response['pageable']['pageSize'];
      _this.totalElementsBuy = response['totalElements'];
      _this.totalPagesBuy = response['totalPages'];
    });
    this.getFilterValuesForBuy();
  }

  //get BuySell Trade Obligation(FOR SELL)
  private getsellTradeData(resetValues:boolean = true) {
    let _this=this;
    this.reset = false;
    this.sellValue = [];
    if(resetValues) {
      this.resetSell = true;
      this.sellTotal = 0;
      this.totalElementsSell = 0;
      this.totalPagesSell = 0;
    }
    this.commonService.getJSONByURL(environment.base_url + '/api/tradeobligation/v1/getalltradeobligationsbytransactiontype?tenantId='
        +this.commonService.getFromStorage('tenantId')+'&transactionType=SELL&page='+this.currentPageNumberSell+'&size=20').subscribe((response: any) => {
      this.reset = true;
      _this.sellValue = response['content'];
      _this.pageSizeSell = response['pageable']['pageSize'];
      _this.totalElementsSell = response['totalElements'];
      _this.totalPagesSell = response['totalPages'];
    })
    this.getFilterValuesForSell();
  }

  //get Inventory(Right)
  private getinventoryData(resetValues:boolean = true) {
    this.reset = false;
    let _this = this;
    this.storageValue = [];
    if(resetValues) {
      this.resetInventorybuild = true;
      this.inventoryBuildtotal = 0;
      this.totalElementsInventoryBuild = 0;
      this.totalPagesInventoryBuild = 0;
    }
    this.commonService.getJSONByURL(environment.base_url + '/api/storageInventory/v1/getBuildStorageInventory?tenantId='+this.commonService.getFromStorage('tenantId')+
        '&page='+this.currentPageInventoryBuild+'&size=20').subscribe((data: any[]) => {
      this.reset = true;
      _this.storageValue = data['content'];
      _this.pageSizeInventoryBuild = data['pageable']['pageSize'];
      _this.totalElementsInventoryBuild = data['totalElements'];
      _this.totalPagesInventoryBuild = data['totalPages'];
    });

    this.getfilterForInventoryBuild();
    setTimeout(() => {
      var scrollContainer = $('.style-4.table-responsive.background.right');
      var maxScrollLeft = $(scrollContainer)[0].scrollWidth - $(scrollContainer).width();
      $(".style-4.table-responsive.background.right").scrollLeft(maxScrollLeft);
    }, 500)
  }

  //get Stock(Left)
  private getstockData(resetValues:boolean = true) {
    this.reset = false;
    let _this = this;
    this.stockValue = [];
    if(resetValues) {
      this.resetInventorystock = true;
      this.inventoryStocktotal = 0;
      this.totalElementsInventoryStock = 0;
      this.totalPagesInventoryStock = 0;
    }
    this.commonService.getJSONByURL(environment.base_url + '/api/storageInventory/v1/getDrawStorageInventory?tenantId='+this.commonService.getFromStorage('tenantId')+
        '&page='+this.currentPageInventoryStock+'&size=20').subscribe((next: any[]) => {
      this.reset = true;
      _this.stockValue = next['content'];
      _this.pageSizeInventoryStock = next['pageable']['pageSize'];
      _this.totalElementsInventoryStock = next['totalElements'];
      _this.totalPagesInventoryStock = next['totalPages'];
    });
    this.getfilterForInventoryStock();
  }

  onChangePageBuy(value: any) {
    this.currentPageNumberBuy = value['page'];
    this.filterForBuy(this.buildBuyFilter);
  }

  onChangePageSell(value: any) {
    this.currentPageNumberSell = value['page'];
    this.filterForSell(this.buildSellFilter);
  }

  onChangePageInventorybuild(value: any) {
    this.currentPageInventoryBuild = value['page'];
    this.getinventoryData(false);
  }

  onChangePageInventorystock(value: any) {
    this.currentPageInventoryStock = value['page'];
    this.filterForInventoryStock(this.stockInventory,this.currentPageInventoryStock);
  }
  onChangePagePlan(value: any) {
    this.currentPageNumberPlan = value['page'];
    this.getPlans();
  }



  filterForBuy(payload) {
    this.buildBuyFilter=payload;
    let _this = this;
    this.buyValue=[];
    this.buyTotal = 0;
    this.commonService.post(environment.base_url + '/api/stockPlanning/v1/getfilterobligationforstockplanning?tenantId='
        +this.commonService.getFromStorage('tenantId')+'&page='+this.currentPageNumberBuy+'&size=20',payload).subscribe((next: any) => {
      _this.buyValue = next['content'];
      _this.pageSizeBuy = next['pageable']['pageSize'];
      _this.totalElementsBuy = next['totalElements'];
      _this.totalPagesBuy = next['totalPages'];
    });
  }

  filterForSell(payload) {
    this.buildSellFilter= payload;
    let _this = this;
    _this.sellValue=[];
    this.sellTotal = 0;
    this.commonService.post(environment.base_url + '/api/tradeobligation/v1/getplanningdatabyfilter?tenantId='
        +this.commonService.getFromStorage('tenantId')+'&transactionType=SELL&page='+this.currentPageNumberSell+'&size=20',payload).subscribe((next: any) => {
      _this.sellValue = next['content'];
      _this.pageSizeSell = next['pageable']['pageSize'];
      _this.totalElementsSell = next['totalElements'];
      _this.totalPagesSell = next['totalPages'];
    });
  }

  filterForInventoryBuild(payload,pageNumber:number=0) {
    this.buildInventory= payload;
    let _this = this;
    _this.storageValue=[];
    this.inventoryBuildtotal = 0;
    this.commonService.post(environment.base_url + '/api/storageInventory/v1/getfiltereddataforinventorybuild?tenantId='
        +this.commonService.getFromStorage('tenantId')+'&page='+pageNumber+'&size=20',payload).subscribe((next: any) => {
      _this.storageValue = next['content'];
      _this.pageSizeInventoryBuild = next['pageable']['pageSize'];
      _this.totalElementsInventoryBuild = next['totalElements'];
      _this.totalPagesInventoryBuild = next['totalPages'];
    });

  }

  filterForInventoryStock(payload,pageNumber:number=0) {
    this.stockInventory=payload;
    let _this = this;
    _this.stockValue=[];
    this.inventoryStocktotal = 0;
    this.commonService.post(environment.base_url + '/api/storageInventory/v1/getfiltereddataforinventorystock?tenantId='
        +this.commonService.getFromStorage('tenantId')+'&page='+pageNumber+'&size=20',payload).subscribe((next: any) => {
      _this.stockValue = next['content'];
      _this.pageSizeInventoryStock = next['pageable']['pageSize'];
      _this.totalElementsInventoryStock = next['totalElements'];
      _this.totalPagesInventoryStock = next['totalPages'];
    });

  }

  clearFilter(mode) {
    if(mode === 'Buy') {
      this.buyValue = [];
      this.buildBuyFilter={};
      this.getbuyTradeData()
    }else if(mode ==='Build'){
      this.inventoryBuildValue = [];
      this.buildInventory={};
      this.getinventoryData()
    }
    else if(mode ==='Stock'){
      this.inventoryStockValue = [];
      this.stockInventory={};
      this.getstockData()
    }
    else {
      this.sellValue = [];
      this.buildSellFilter={};
      this.getsellTradeData();
    }
  }

  calculateLeft() {
    this.noDataBoxLeft = ((window['visualViewport'].width - 500) / 2) + 'px';
  }

  goTo() {
    this.router.navigate(['/storageInventory']);
  }

  openChildSelected(rowData) {
    this.selectedChildRowData = rowData[0];
    this.displaySideBar = this.selectedChildRowData !== undefined;
  }

  cancel() {
    this.displaySideBar= false;
  }

  onMoveToInventory() {
    if(this.selectedChildRowData !== null && this.selectedChildRowData !== undefined){
      this.commonService.getJSONByURL(environment.base_url + '/api/stockPlanning/v1/updatecommodityinventory?tenantId='+this.commonService.getFromStorage('tenantId')+'&stockId='+this.selectedChildRowData['stockId']+'&storageUuid='+this.selectedChildRowData['storageUuid']).subscribe((next: any) => {
        this.showToastForActualizeStock(next);
        this.refreshTabs();
        this.displaySideBar= false;
      }, (error: HttpErrorResponse) => {
        this.messageService.add({severity: 'error', summary: error.error});
      });
    }
  }

  showToastForActualizeStock(message) {
    this.messageService.add({
      severity: 'success',
      summary: message['status']
    });
  }

  refreshTab(tabValue) {
    if (tabValue === 'build') {
      this.onRefreshScreen.next();
      this.buyValue = [];
      this.storageValue = [];
      this.buildBuyFilter={};
      this.buildInventory={};
      this.getbuyTradeData();
      this.getinventoryData();
      this.onRefresh = true;
    } else if (tabValue === 'draw') {
      this.onRefreshScreen.next();
      this.sellValue = [];
      this.stockValue = [];
      this.buildSellFilter = {};
      this.stockInventory = {};
      this.getsellTradeData();
      this.getstockData();
      this.onRefresh = true;
    }
  }

  onError(error: HttpErrorResponse) {
    this.params = {};
    this.messageService.add({severity: 'error', summary: this.commonService.getHttpErrorMessage(error,'Stock Build')});
  }

  onBuildDone(value: any) {
    this.params = {};
    this.showToast(value);
    this.getbuyTradeData();
    this.getinventoryData();
    this.storageTotal = 0;
    this.buyTotal = 0;
    this.reset = true;
  }

  onStockDone(value:any) {
    this.params = {};
    this.showToast(value);
    this.getsellTradeData();
    this.getstockData();
    this.stockTotal = 0;
    this.sellTotal = 0;
    this.workingDraw = false;
  }

  onVesselAllocationCancel() {
    this.visibleVesselAllocation = false;
  }

  onAllocationDone() {
    this.visibleVesselAllocation = false;
    this.getstockData();
    this.getPlans();
  }

  getDisable(buttonType:any) {
    if (this.selectedChildRowData) {
      const hasVesselId = this.selectedChildRowData['vesselId'] !== null;
      return buttonType === 'allocate' ? hasVesselId : !hasVesselId;
    }
  }
  onDeAllocateTransport() {
    let _this = this;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to De-Allocate Transport?',
      accept: () => {
        this.commonService.post(environment.base_url + `/api/transportallocationv2/v1/deallocatevesselforplanning?planId=${this.selectedChildRowData.tradePlanId}&allocationType=${'vessel'}&tenantId=${localStorage.getItem('tenantId')}`, {}).subscribe(data => {
          this.messageService.add({
            severity: 'success',
            summary: 'Transport is successfully De-Allocated for the PlanId ' + this.selectedChildRowData.planningId
          });
          this.displaySideBar= false;
          this.getPlans();
        }, (error: HttpErrorResponse) => {
          _this.messageService.add({
            severity: 'error',
            summary: _this.commonService.getHttpErrorMessage(error, 'Trade Planning')
          });
        });
      }, reject: () => {
        this.displaySideBar= false;
      }
    });
  }

  goToVessel() {
    let url = window.location.origin +'/ctrm-v2/#/trade-planning/'+ this.selectedChildRowData.tradePlanUuid + '/' + this.selectedChildRowData.tradePlanId +'/Plan';
    window.open(url, '_blank');
  }
}

