<p-dialog [(visible)]="infoDisplay"
          (onHide)="onCancel()"
          [closeOnEscape]="false"
          [contentStyle]="{ overflow: 'scroll', overflowX:'hidden'}"
          [draggable]="false"
          [maximizable]="false" [modal]="true" [showHeader]="false"
          [style]="{width: '50%',padding:'0px',background:'#ffffff'}">

    <div class="dialogHeading">
        <div style="display: inline-flex;width: 100%;border-bottom: 1px solid rgba(211,211,211,0.6)">
            <p class="dialogLabel">{{tickerHeader}}</p>
            <i (click)="onCancel()" class="material-icons closable" style="float: right;align-self: center">clear</i>
        </div>
        <div class="sectionBoxMain">
            <div class="row rowClass">
                <div class="col-md-3 controlGap contentbox">
                    <div class="fieldBox">
                        <p class="fieldName">Commodity Name</p>
                        <p class="bodyName">{{commodityInfoValue['commodityName'] === null || commodityInfoValue['commodityName'] === ""
                            || commodityInfoValue['commodityName'] === undefined ? '-' : commodityInfoValue['commodityName']}}</p>
                    </div>
                </div>
                <div class="col-md-3 controlGap contentbox">
                    <div class="fieldBox">
                        <p class="fieldName">Symbol</p>
                        <p class="bodyName">{{commodityInfoValue['symbol'] === null || commodityInfoValue['symbol'] === ""
                        || commodityInfoValue['symbol'] === undefined ? '-' : commodityInfoValue['symbol']}}</p>
                    </div>
                </div>
                <div class="col-md-3 controlGap contentbox">
                    <div class="fieldBox">
                        <p class="fieldName">Lot Size</p>
                        <p class="bodyName">{{commodityInfoValue['lotSize'] === null || commodityInfoValue['lotSize'] === ""
                        || commodityInfoValue['lotSize'] === undefined ? '-' : commodityInfoValue['lotSize'] +  ' ' + commodityInfoValue['lotUom']}}</p>
                    </div>
                </div>
                <div class="col-md-3 controlGap contentbox">
                    <div class="fieldBox">
                        <p class="fieldName">Product Code</p>
                        <p class="bodyName">{{tickerInfoValue['productcode']}}</p>
                    </div>
                </div>
                <div class="col-md-3 controlGap contentbox">
                    <div class="fieldBox">
                        <p class="fieldName">Contract Month</p>
                        <p class="bodyName">{{tickerInfoValue['contractmonth']}}</p>
                    </div>
                </div>
                <div class="col-md-3 controlGap contentbox">
                    <div class="fieldBox">
                        <p class="fieldName">First Trade Date</p>
                        <p class="bodyName">{{tickerInfoValue['firsttrade'] | date:'dd MMM yyyy'}}</p>
                    </div>
                </div>
                <div class="col-md-3 controlGap contentbox">
                    <div class="fieldBox">
                        <p class="fieldName">Last Trade Date</p>
                        <p class="bodyName">{{tickerInfoValue['lasttrade'] | date:'dd MMM yyyy'}}</p>
                    </div>
                </div>
                <div class="col-md-3 controlGap contentbox">
                    <div class="fieldBox">
                        <p class="fieldName">Settlement Date</p>
                        <p class="bodyName">{{tickerInfoValue['expirydate'] | date:'dd MMM yyyy'}}</p>
                    </div>
                </div>
                <div class="col-md-3 controlGap contentbox">
                    <div class="fieldBox">
                        <p class="fieldName">Price Date</p>
                        <p class="bodyName">{{graphValue['futureCommodityPriceDate'] | date:'dd MMM yyyy'}}</p>
                    </div>
                </div>
                <div class="col-md-3 controlGap contentbox">
                    <div class="fieldBox">
                        <p class="fieldName">Settlement Price</p>
                        <p class="bodyName">{{graphValue['settlementPrice']}}</p>
                    </div>
                </div>
                <div class="col-md-3 controlGap contentbox">
                    <div class="fieldBox">
                        <p class="fieldName">Open</p>
                        <p class="bodyName">{{graphValue['pxOpen']}}</p>
                    </div>
                </div>
                <div class="col-md-3 controlGap contentbox">
                    <div class="fieldBox">
                        <p class="fieldName">High</p>
                        <p class="bodyName">{{graphValue['pxHigh']}}</p>
                    </div>
                </div>
                <div class="col-md-3 controlGap contentbox">
                    <div class="fieldBox">
                        <p class="fieldName">Low</p>
                        <p class="bodyName">{{graphValue['pxLow']}}</p>
                    </div>
                </div>
            </div>
        </div>
        <p-footer>
            <div style=" border-top: 2px solid #f0e5ea;">
                <div style="float: right;">
                    <button (click)="onCancel()" class="cancelBtn">Cancel</button>
                </div>
            </div>

        </p-footer>
    </div>
</p-dialog>
