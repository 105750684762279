<ctrm-grid [breadcrumbMapKey]="commonService.MASTER_MAP_KEY"
           [currentRoute]="'/externalPackaging'"
           (onClickUniqueKey)="onClickView($event)"
           [grid]="grid" [refreshGrid]="refreshGrid"
           (onClickEdit)="onClickView($event)"
           [defaultEdit]="false"
           [showActions]="false">
</ctrm-grid>

<p-sidebar *ngIf="editScreenDisplay" [(visible)]="editScreenDisplay" [baseZIndex]="false"
           [closeOnEscape]="false"
           [ngStyle]="{maxHeight: tableheight}"
           [showCloseIcon]="false" [style]="{minWidth:'70%',maxWidth:'70%',zIndex:'1006'}" autoLayout="true"
           class="style-4 table-responsive" position="right">
  <div class="header">
    <div style="display: inline-flex">
      <p class="name">{{selectedRowData['name']}}</p>
<!--      <ctrm-button (onClick)="onClickEdit()" *ngIf="pageDisable && canEdit" [label]="'Edit'" icon="fas fa-pen"></ctrm-button>-->
    </div>
    <i (click)="close()" class="material-icons closable">clear</i>
  </div>

  <div class="section">
    <div class="sectionHeader">
      <p class="sectionTitle">External Packaging Infromation</p>
    </div>
    <div class="sectionBody">
      <div class="infoBox">
        <p class="infoHeader">Dimension ( l * b * h )</p>
        <p class="infoInfo" style="white-space: nowrap;">
                            {{selectedRowData['length'] + ' ' + selectedRowData['lengthUom'] + ' * '}}
                            {{selectedRowData['width'] + ' ' + selectedRowData['widthUom'] + ' * '}}
                            {{selectedRowData['height'] + ' ' + selectedRowData['heightUom']}}
        </p>
      </div>
      <div class="infoBox">
        <p class="infoHeader">External Indentifier</p>
        <p *ngIf="pageDisable" class="infoInfo">{{selectedRowData['externalIdentifier']}}</p>
                <div *ngIf="!pageDisable" class="inputbox">
                  <input [(ngModel)]="externalIdentifier"
                         [type]="'text'" class="form-control form-control-sm"
                         pInputText placeholder="Enter External Indentifier">
                </div>
      </div>

      <div class="infoBox">
        <p class="infoHeader">Status</p>
        <p *ngIf="pageDisable" [ngClass]="selectedRowData['status']?'activeBox':'inactiveBox'"
           class="infoInfo">{{selectedRowData['status'] ? 'Active' : 'Inactive'}}</p>
        <div *ngIf="!pageDisable">
          <ui-switch [(ngModel)]="status"
                     [checkedLabel]="'Active'"
                     [checkedTextColor]="'#fff'"
                     [defaultBgColor]="'#d8d8d8'"
                     [uncheckedLabel]="'Inactive'"></ui-switch>
        </div>
      </div>
    </div>
  </div>

  <p-footer>
    <div class="footerClass saveSection">
      <div style="display: inline-flex">
        <button (click)="close()" class="cancelBtn" label="Cancel" pButton type="button"></button>
<!--        <div *ngIf="canEdit" style="display: inline-flex; margin-top: 1%;">-->
<!--          <button (click)="UpdateUom()"-->
<!--                  [disabled]="pageDisable"-->
<!--                  [hidden]="isLoading"-->
<!--                  class="savebutton" icon="pi pi-check"-->
<!--                  iconPos="left" label="Update" pButton></button>-->
<!--          <div [hidden]="!isLoading" class="loadingbutton">-->
<!--            <div class="loader"></div>-->
<!--            <p>{{'Update'}}</p>-->
<!--          </div>-->
<!--        </div>-->
<!--        <ng-container *ngTemplateOutlet="infoUpdate"></ng-container>-->
      </div>
    </div>
  </p-footer>
</p-sidebar>

<ng-template #infoUpdate>
  <div class="lastUpdateInfoOnForm">
    <div style="display: flex;flex-direction: row">
      <p>Created By : {{createdBy}}</p>
      <p>Created On : {{createdTimestamp}}</p>
    </div>
    <div style="display: flex;flex-direction: row">
      <p>Last Modified By : {{updatedBy}}</p>
      <p>Last Modified On : {{updatedTimestamp}}</p>
    </div>
  </div>
</ng-template>
<!--Toaster Message-->
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body">
</p-toast>
