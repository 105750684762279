import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import 'rxjs/Rx';
import {Grid} from './grid.model';
import {filter} from "../masters/services/filter.model";

@Injectable({
  providedIn: 'root'
})
export class GridService {
  placeHolder: number = 0;
  base_url = environment.base_url;
  grid: Grid;

  constructor(private http: HttpClient) {
  }

  getData(href: string): Observable<any[]> {
    return this.http.get<any[]>(  href);
  }

  getDataFromFilter(url: any, filterObject: filter) {
    return this.http.post(url, filterObject);
  }

  modifyGridRow(rowData: any, href: string): Observable<any[]> {
    return this.http
      .patch<any[]>(
        href,
        rowData
      )
      .map((response: any[]) => {
        return response;
      });

  }

  deleteGridRow(href: string): Observable<any> {

    return this.http
      .delete<any[]>(
        href
      )
      .map((response: any) => {
        return response;
      });

  }

  saveGridRow(rowData: any, href: string): Observable<any[]> {
    return this.http
      .post<any[]>(
        href,
        rowData
      )
      .map((response: any[]) => {
        return response;
      });

  }

  GetNextPlaceholder() {
    return this.placeHolder++;
  }

  convertPrimaryKey(key: string) {
    var newkey = '';
    for (var i = 0; i < key.length; i++) {
      if (i == 0) {
        newkey += key[i].toUpperCase();
      } else {
        newkey += key[i];
      }
    }
    return newkey;
  }

  deleteAllChilds(href) {
    var url = this.base_url + href;
    return this.http.get<any[]>(url);
  }

  deleteDataOnUrl(href) {
    return this.http.get<any[]>(href);
  }

  modifyGridRowWithResponse(rowData: any, href: string): Observable<{}> {
    return this.http
      .patch<any[]>(
        href,
        rowData
        , {observe: 'response'}
      )
      ;

  }

}
