import {Injectable} from '@angular/core';
import 'rxjs/Rx';
import {environment} from '../../../environments/environment';
import {KeyValue} from '../../grid/key-value.model';
import {filter} from '../services/filter.model';
import {Grid} from "../../grid/grid.model";
import {UtilService} from "../../ctrm-input-form/services/util.service";
import {ActivatedRoute} from "@angular/router";
import {Tcolumn} from "../../grid/tcolumn.model";
import {HttpClient} from '@angular/common/http';
import {DatePipe} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  gidGrid: Grid;
  private _addbtnField: any[] = [];
  private modelStat: boolean;
  datePipe: DatePipe;
  private gridMap: Map<string, any> = new Map();
  private addBtnValue: string;

  private _system_sidebar_expanded:boolean = false;
  private _system_sidebar_selected:string = "";
  tickerMap: Map<string, string> = new Map<string, string>();

  constructor(private util: UtilService, private router: ActivatedRoute, private http: HttpClient) {
    this.datePipe = new DatePipe('en-US');
    this.getTickers();
  }

  public getAddbtnField() {
    return this._addbtnField;
  }


  get system_sidebar_expanded(): boolean {
    return this._system_sidebar_expanded;
  }

  set system_sidebar_expanded(value: boolean) {
    this._system_sidebar_expanded = value;
  }

  get system_sidebar_selected(): string {
    return this._system_sidebar_selected;
  }

  set system_sidebar_selected(value: string) {
    this._system_sidebar_selected = value;
  }

  /**
   * This function is used to add "ADD Button" on specific Fields
   */
  public setAddbtnField(value: any, grid: Grid, actionToPerformAfterDone) {
    this._addbtnField.push(value);
    if (this.gridMap === undefined) {
      this.gridMap = new Map<string, any>();
    }
    this.gridMap.set(value, {grid: grid, action: actionToPerformAfterDone});
  }

  /**
   * get Model State
   */
  public getModelStat() {
    return this.modelStat;
  }

  /**
   * set Model State
   */
  public setModelStat(state: boolean = false) {
    this.modelStat = state;
  }

  /**
   * get add button Value
   */
  public getAddBtnValue() {
    return this.addBtnValue;
  }

  /**
   * Set add button Value
   */
  public setAddBtnValue(value: string) {
    this.addBtnValue = value;
  }

  /**
   * get Grid
   */
  public getGrid(tableName: string): Grid {
    return this.gridMap.get(tableName)['grid'];
  }

  /**
   * get Action for Field
   */
  public getFieldAction(tableName: string) {
    return this.gridMap.get(tableName)['action'];
  }

  /**
   * This function is used to get List of Values from given URL
   * @param url   //URL from where values will be retrieved
   * @param key   //Key for KeyValue pair
   * @param value //Value for KeyValue pair
   * @param addNone //to Add "NONE" on top of List as an option
   * @param masterName // Name of Master (Optional Field)
   */
  getListFromUrl(url, key, value, addNone = false, addSelect = true, masterName?: any, selectLabel = 'Select', selectValue = '', baseUrl: string = environment.base_url) {
    let list = [];
    if (addSelect) {
      list.push(new KeyValue(selectLabel, selectValue));
    }
    if (addNone) {
      list = [];
      list.push(new KeyValue('None', 'none'));
    }
    this.getJSONByURL(baseUrl + url).subscribe(function(data: any) {
      if (data['_embedded']) {
        data['_embedded'][Object.keys(data['_embedded'])[0]].forEach(function(result) {
          list.push(new KeyValue(result[key], result[value]));
        });
      } else {
        data.forEach(function(result) {
          list.push(new KeyValue(result[key], result[value]));
        });
      }
    });
    return list;
  }


  getListFromArray(array: any[],addNone = false, addSelect = true,selectLabel:string = 'Select',selectValue:string = ''){
    let list = [];
    if (addSelect) {
      list.push(new KeyValue(selectLabel, selectValue));
    }
    if (addNone) {
      list = [];
      list.push(new KeyValue('None', 'none'));
    }

    if (array !== undefined && array !== null){
      array.forEach(value => {
        if (value !== undefined && value !== null){
          list.push(new KeyValue(value, value));
        }
      });
    }
    return list;
  }

  getFormattedDate(value, format) {
    return this.datePipe.transform(value, format);
  }

  getListFromDateArray(array: any[],addNone = false, addSelect = true,selectLabel:string = 'Select',selectValue:string = ''){
    let list = [];
    if (addSelect) {
      list.push(new KeyValue(selectLabel, selectValue));
    }
    if (addNone) {
      list = [];
      list.push(new KeyValue('None', 'none'));
    }
    let _this = this;
    if (array !== undefined && array !== null){
      array.forEach(value => {
        list.push(new KeyValue(_this.getFormattedDate(value,"dd MMMM yyyy"), value));
      });
    }
    return list;
  }



  /**
   * To get Grid Fields which are only visible
   * @param grid
   */
  getGridField(grid: Grid, fieldValueArray: string[] = ['F', 'B', 'G']): Tcolumn[] {
    let fields: Tcolumn[] = [];
    grid.getColumn().forEach(function (data: Tcolumn) {
      if (fieldValueArray.includes(data.getVisibilityArea())) {
        let json = JSON.parse(JSON.stringify(data));
        let copy: Tcolumn = new Tcolumn(json['field'], json['header'], json['columnType'], json['placeholder'], json['editable'], json['listvalues'],
          json['visible'], data.getDefaultValue(), json['visibilityArea'], data.getValidators(), json['valueType'], json['colSpan'], json['minDate'],
          json['maxDate'], json['tableMetadata'], data.getActionToDo(), json['extra'], json['isUnique']);
        fields.push(copy);
      }
    });
    this.util.sortByProperty(fields, 'placeholder', 'ASC');
    return fields;
  }

  /**
   * Function to get List of Values from Object
   * @param object //Filter
   * @param key   //Key for KeyValue Pair
   * @param value  //Value for KeyValue Pair
   * @param addNone //To add "NONE" as Option
   */
  getListFromObject(object: filter, key, value, addNone = false, addSelect = true, selectLabel: string = 'Select'): KeyValue[] {
    let list: KeyValue[] = [];
    if (addSelect) {
      list.push(new KeyValue(selectLabel, ''));
    }
    if (addNone) {
      list = [];
      list.push(new KeyValue('None', 'none'));
    }
    if (object.getUrl() === environment.base_url + 'filter') {
      object.addSortBy(key);
    }
    this.getJSONByObject(object.getUrl(), object).subscribe(function (data: any) {
      if (data['_embedded']) {
        data['_embedded'][Object.keys(data['_embedded'])[0]].forEach(function (result) {
          list.push(new KeyValue(result[key], result[value]));
        });
      } else {
        data.forEach(function (result) {
          list.push(new KeyValue(result[key], result[value]));
        });
      }
    });
    return list;
  }

  /**
   * Function to get Yes/No options for Toggle Button
   */
  public getYesNo(addSelect: boolean = false) {
    const list = [];
    if (addSelect) {
      list.push(new KeyValue('Select', ''));
    }
    list.push(new KeyValue('Yes', true));
    list.push(new KeyValue('No', false));
    return list;
  }

  /**
   * Function to get Custom boolean option
   * @param forTrue  //Value for True
   * @param forFalse //Value for False
   */
  public getBooleanFromLabel(forTrue: any, forFalse: any, addSelect: boolean = false,forTrueValue:any = true,forFalseValue:any = false) {
    const list = [];
    if (addSelect) {
      list.push(new KeyValue('Select', ''));
    }
    list.push(new KeyValue(forTrue, forTrueValue));
    list.push(new KeyValue(forFalse, forFalseValue));
    return list;
  }

  /**
   * Function to get Active/Inactive option for toggle button
   */
  public getActiveInactive() {
    const list = [];
    list.push(new KeyValue('Select', ''));
    list.push(new KeyValue('Active', true));
    list.push(new KeyValue('Inactive', false));
    return list;
  }

  /**
   * Function to get Enable/Disable option for toggle button
   */
  public getEnableDisable() {
    const list = [];
    list.push(new KeyValue('Select', ''));
    list.push(new KeyValue('Enable', true));
    list.push(new KeyValue('Disable', false));
    return list;
  }

  /**
   * Function to data from Global Indicators
   * @param gcode //Code to get Data From
   * @param key //Key for KeyValue Pair
   * @param value //Value for KeyValue Pair
   * @param addNone // to Add "None" as option
   */
  getFromGlobalIndication(gcode, key = 'name', value = 'name', addNone = false, addSelect = true) {
    let list = [];
    if (addSelect) {
      list.push(new KeyValue('Select', ''));
    }
    if (addNone) {
      list = [];
      list.push(new KeyValue('None', 'none'));
    }
    if(gcode !== undefined && gcode !== null && gcode.toString().length >0) {
      this.getJSONByURL(environment.base_url + '/globalIndicatorDetails/gidasc/' + gcode).subscribe(function (data: any) {
        data.forEach(function (result) {

          list.push(new KeyValue(result[key].toString(), result[value].toString()));

        });
      });
    }
    return list;
  }

  /**
   * Function to create Post Object for Filter
   * @param key //Key for KeyValue pair
   * @param value  //Value for KeyValue pair
   * @param entityname //Name of Entity
   * @param operation //Name of Operation (Default: Equal)
   */
  getPostObject(key: any, value: any, entityname: any, operation: any = "equal") {
    var object = {};
    object['criteria'] = [{
      key: key,
      operation: operation,
      value: value
    }];
    object['entityName'] = entityname;
    return object;
  }

  setPrimaryKeyValue(primaryKeyField: any, value: any, grid: Grid) {
    return grid.getColumn().get(primaryKeyField).setDefaultValue(value);
  }

  /**
   * Function to get Logged in User
   */
  public getUser() {
    let user: string = localStorage.getItem('userName');
    user = user.replace(new RegExp('"','g'), '');
    return user;
  }

  /**
   * Function to get Current logged in user
   */
  public getUserId() {
    let userId: string = localStorage.getItem('userName');
    userId = userId.replace(new RegExp('"','g'), '');
    return userId;
  }

  /**
   * Function to get Current Date
   */
  public getCurrentDate() {
    return new Date();
  }

  createDefaultSections(grid: Grid, columns: Tcolumn[]) {
    let _this = this;
    if (grid.getSections() === undefined || grid.getSections().size === 0) {
      columns.forEach(function (col: Tcolumn) {
        grid.addInSection(grid.getTitle(), col.getField());
      });
    }
    return grid;
  }

  public getJSONByURL(url: string) {

    url = url.replace(' ', '%20');
    return this.http.get(url);
  }

  public getJSONByObject(url: string, object: any) {
    return this.http.post(url, object);
  }

  getExportGridColumns(grid: Grid) {
    let fields: Tcolumn[] = [];
    let fieldValueArray: string[] = ['F', 'B', 'G'];
    grid.getColumn().forEach(function (data: Tcolumn) {
      if (fieldValueArray.includes(data.getVisibilityArea())) {
        if (!grid.ignoreFieldListForExport.includes(data.getField()) && data.getColumnType() !== 'F') {
          let json = JSON.parse(JSON.stringify(data));
          let copy: Tcolumn = new Tcolumn(json['field'], json['header'], json['columnType'], json['placeholder'], json['editable'], json['listvalues'],
            json['visible'], data.getDefaultValue(), json['visibilityArea'], data.getValidators(), json['valueType'], json['colSpan'], json['minDate'],
            json['maxDate'], json['tableMetadata'], data.getActionToDo(), json['extra'], json['isUnique']);
          fields.push(copy);
        }
      }
    });
    this.util.sortByProperty(fields, 'placeholder', 'ASC');
    return fields;
  }

  private getTickers() {
    let _this = this;
    this.getJSONByURL(environment.base_url + '/api/globalindicatordetails/v1/gid/future_ticker_ind?tenantId='+ localStorage.getItem('tenantId')).subscribe(function (next: any[]) {
      next.forEach(function (obj) {
        _this.tickerMap.set(obj['description'].toLowerCase(), obj['name']);
      });
    });
  }

  public getTickerForMonth(month: string) {
    return this.tickerMap.get(month);
  }


  public convertTickerToDate(ticker: string){
    let code = ticker[0];
    let valueList = Array.from(this.tickerMap.values());
    let keyList = Array.from(this.tickerMap.keys());
    if(valueList !== undefined && valueList !== null && valueList.includes(code)){
      let month = keyList[valueList.indexOf(code)];
      ticker = ticker.replace(code,month + '-20');
    }
    return ticker;
  }
}
