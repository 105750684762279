import { Component, OnInit } from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {CommonService} from "../../../services/common.service";
import {BreadcrumbService} from "../../../Components/ctrm-breadcrumb/breadcrumb.service";
import {Tcolumn} from "../../../grid/tcolumn.model";
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Grid} from "../../../grid/grid.model";
import {MasterService} from "../../services/MasterService";
import {defaultValue} from '../../../grid/defaultValue.model';
import {ctrmValidators} from '../../../services/validators/ctrmValidators';
import {environment} from '../../../../environments/environment';
import {KeyValue} from '../../../grid/key-value.model';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng';
import {HttpErrorResponse} from '@angular/common/http';


@Component({
  selector: 'app-approval-edit',
  templateUrl: './approval-edit.component.html',
  styleUrls: ['./approval-edit.component.css']
})
export class ApprovalEditComponent implements OnInit {

  approvalrolecolumns: Tcolumn[] = [];
  grid:Grid;
  gridData:any;
  formValues: any;
  formGroup:FormGroup
  values:any[]=[];
  display:boolean=false;
  rowdata:any;
  workFlow:FormGroup;
  approverSpanList:any[]=[];
  formStatus:boolean=true;

  constructor(public sanitizer: DomSanitizer, public commonService:CommonService,public breadCrumbService: BreadcrumbService,
              private masterCommonService: MasterService,private fb: FormBuilder,private router: ActivatedRoute,
              private messageService: MessageService, private route: Router) { }

  ngOnInit(): void {
    this.breadCrumbService.makeBreadcrumbTo(this.commonService.MASTER_MAP_KEY, '/approvalupdate');
    this.workFlow = this.fb.group({
      uuid:new FormControl(),
      workflowName: new FormControl('', [Validators.required]),
      triggerEvent: new FormControl('', [Validators.required]),
      approvalSpan: new FormControl('', [Validators.required]),
      postAction: new FormControl('', [Validators.required]),
      negativePostAction: new FormControl('', [Validators.required]),
      isSystem: new FormControl(false),
      isActive: new FormControl(false)
    })
    this.getapprovalrolecolumns([]);
    this.commonService.getJSONByURL(environment.base_url_new + '/api-iam/api/rolemaster/v1/getallactiveroles?tenantId=' +
        this.commonService.getFromStorage('tenantId')).subscribe((next:any[]) => {
      let list:any[] =[];
      list.push(new KeyValue());
      next.forEach(function (obj) {
        list.push(new KeyValue(obj['roleName'],obj['roleName']));
      });
      this.approvalrolecolumns[0].setListofValues(list);
    });

    let array= ['Company','ProfitCenter','WorkGroup'];
    this.approverSpanList=  this.masterCommonService.getListFromArray(array, false, false, 'select','');

    this.router.queryParams.subscribe(params=>{
      if(params.value!=null || params.value!=undefined){
        this.values.length = 0;
        this.commonService.getJSONByURL(environment.base_url_new+`/approval-workflow/api/workflow-config/${params.value}`).subscribe((next:any)=>{
          this.workFlow.setValue({
            uuid:next['uuid'],
            workflowName:next['workflowName'],
            triggerEvent:next['triggerEvent'],
            approvalSpan:next['approvalSpan'],
            postAction:next['postAction'],
            negativePostAction:next['negativePostAction'],
            isSystem:next['isSystem'],
            isActive:next['isActive']
          })
          this.values = next['workflowSteps'].sort((a, b) => parseFloat(a.sequence) - parseFloat(b.sequence));
        })
      }
    })
  }

//Toast Message
  showToast(msg, severity: string = 'success') {
    this.messageService.add({
      severity: severity,
      summary: msg
    });
  }

  resetTab(){
    this.workFlow.reset();
    this.values=[];
  }

  sequenceNumber(){
    let _this = this;
    for(let  i = 0 ;i<this.values.length -1;i++ ){
      if (parseInt(this.values[i]['sequence'])+1  !== parseInt(this.values[i+1]['sequence'])) {
            return false;
          }
    }
    return true;
    }

  getapprovalrolecolumns(roleList:any[]) {
    this.approvalrolecolumns.push(new Tcolumn('role', 'Role', 'L', 0, true, roleList, true,'','B',[Validators.required]));
    this.approvalrolecolumns.push(new Tcolumn('actionName', 'Action Name', 'T', 1, true, undefined, true,'','B',[Validators.required]));
    this.approvalrolecolumns.push(new Tcolumn('sequence', 'Sequence', 'NS', 2, true, undefined, true,'','B',[Validators.required]));
    this.approvalrolecolumns.push(new Tcolumn('mandatory', 'Mandatory', 'B', 4, true, this.masterCommonService.getYesNo(), true,true,'B',[Validators.required]));
    this.approvalrolecolumns.push(new Tcolumn('sla', 'SLA', 'T', 4, true, undefined,true,new defaultValue('',undefined,['d',' ','m','w'],undefined,false,true,false),'B',[ctrmValidators.regexValidator(new RegExp(/^[0-9]+[mwd]{1}(?:\ [0-9]+[mwd]{1}){0,2}$/)," Format, Required Format : 1m 1w 1d"),Validators.required]));
    this.approvalrolecolumns.push(new Tcolumn('desiredEndStatus', 'Post Actions', 'CUSTOM', 3, true, undefined, true, new defaultValue('', '', ['@'],'B',undefined),'',[Validators.required]));
  }

  onSave() {
    if(this.sequenceNumber()) {
      let payload = this.workFlow.value;
      payload['tenantId'] = this.commonService.getFromStorage('tenantId');
      payload['workflowSteps'] = this.values;
      this.commonService.post(environment.base_url_new + '/approval-workflow/api/workflow-config', payload).subscribe(next => {
        this.resetTab();
        this.showToast('workflow saved Succesfully');
      }, (error: HttpErrorResponse) => {
        this.showToast(this.commonService.getHttpErrorMessage(error, 'Approval configuration'), 'error');
      })
      this.route.navigate(['/approvalconfiguration']);
    }
    else{
      this.showToast('sequence is incorrect.','error');
    }
  }

  updateFormGroup(value: FormGroup) {
    this.formGroup= value;
  }

  getKeys(index: any) {
    return this.formGroup.value['desiredEndStatus' + index].split(',');
  }

  onClick(index:number) {
    this.rowdata=this.values[index];
    this.display=true;
  }
  onclose(){
    this.display=false;
  }

  onStatussave(value: any) {
    this.display=false;
  }

  oncancel() {
    this.resetTab();
    this.route.navigate(['/approvalconfiguration']);
  }
}




