import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {Table} from 'primeng';
import {CommonService} from '../../services/common.service';
import {Tcolumn} from '../../grid/tcolumn.model';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-ctrm-price-table',
  templateUrl: './ctrm-price-table.component.html',
  styleUrls: ['./ctrm-price-table.component.css']
})
export class CtrmPriceTableComponent implements OnInit, OnChanges {
  @Input() gridDisplay: boolean = false;
  @Input() refreshFormGroup: boolean = false;
  @Input() columns: Tcolumn[] = [];
  @Input() newRows: any[] = [];
  @Input() combinationColumns: any[] = [];
  @Output() onAddNewRowComplete = new EventEmitter();
  @Input() refreshNewRows: boolean = false;
  @Input() addIntitialRow:boolean = false;
  @Input() hiddenColumns: any[] = [];
  @Output() formGroupStatus = new EventEmitter();
  @Input() useCustomSort: boolean = false;
  @Input() customSortFunction:Function;
  @Input() priceGridHeight: string = '';
  @ViewChild('table') table: Table;
  @Input() value: any[] = [];
  @Output() onButtonClick = new EventEmitter();
  @Input() customTemplateFields:any[] = [];
  @Input() customTemplate:TemplateRef<any>[] =[];
  @Input() clickableFields:string[] = [];
  addButtonValue: any;
  addNewModalState: boolean = false;
  listOfValues: Map<any, any> = new Map();
  addInline: boolean = false;
  gridFrmGroup: FormGroup = new FormGroup({});
  @Input() checkValidityBeforeAddingNewRow:boolean = false;
  onChangeFields: string[] = ['B', 'D', 'ML', 'SL', 'LB', 'S', 'TN', 'L'];
  @Output() onRefreshFormGroupComplete = new EventEmitter();
  @Input() fileToImport: File;
  @Output() onImportFileComplete = new EventEmitter();
  @Output() onChangeFieldValue = new EventEmitter();
  @Input() maintainRowConfig:boolean = false;
  importing: boolean = false;
  private addedCombination: any[] = [];
  rowWiseConfig: any[] = [];
  @Input() showNewEntryIcon: boolean = false;
  @Input() showCrossIcon: boolean = true;
  @Input() noDataLabel: string = 'No Data Available';
  noDataBoxLeft: string = '';
  @Input() noDataTemplate: TemplateRef<any>;
  @Input() showPlaceHolder: boolean = false;
  placeHolderHeight: string = '';
  @Input() disable: boolean = false;
  @Output() onFormGroupCreated = new EventEmitter();
  gridValue: any[] = [];
  @Output() formvalues = new EventEmitter();
  @Input() minHeight: string = '45px';
  @Input() maxHeight: string = '';
  @Output() onrowdelete = new EventEmitter();
  @Input() tableStyle = {backgroundColor: '#D9DBDE'};
  @Input() showReadOnly: boolean = false;
  @Input() showCheckbox: boolean = false;
  @Output() onSelectCheckbox = new EventEmitter();
  @Input() autoCalculateHeight:boolean = true;
  @Output() onSelectionChange = new EventEmitter();
  @Input() selectedRows : any[]=[];
  @Output() onClickClickable = new EventEmitter();


  constructor(public commonService: CommonService) {
  }

  ngOnInit(): void {
    this.onFormGroupCreated.emit(this.gridFrmGroup);
  }

  @HostListener('window:resize', [])
  public onResize() {
    this.calculateHeight();
  }

  calculateHeight() {
    if(this.autoCalculateHeight) {
      if (this.table !== undefined && this.table !== null) {
        let offsetTop = this.table.el.nativeElement['offsetTop'];
        let offsetHeight = this.table.el.nativeElement['offsetParent']['offsetHeight'];
        this.priceGridHeight = (offsetHeight - (offsetTop + 100)) + 'px';
      }
    }
  }

  //aioControls
  onClick(value) {
    this.onButtonClick.emit(value);
  }

  addBttnValue(value: any) {
    this.addButtonValue = value;
    this.addNewModalState = true;
  }


  // returns dropdown list values for a particular row


  getListOfValues(col: Tcolumn, ri) {
    let lov = col.getListOfValues();
    if (this.addInline && ri > 0) {
      ri--;
    }
    if (this.listOfValues.has(ri)) {
      let fieldname = col.getField();
      let obj: Map<string, any> = this.listOfValues.get(ri);
      if (obj.has(fieldname)) {
        lov = obj.get(fieldname);
      }
    }
    return lov;
  }

  changeField(ri, col: any, value: any) {

  }

  getFormControl(formName: any) {
    return this.gridFrmGroup.controls[formName];
  }

  isNotLast(index: number) {
    return (index < this.columns.length - 1);
  }

  isModalChange(col: Tcolumn) {
    return (this.onChangeFields.includes(col.getColumnType()));
  }

  getDefaultValue(value) {
    let defaultVa: any;
    if (value === undefined || value === null) {
      defaultVa = '';
    } else if (typeof (value) === 'object') {
      defaultVa = value.defaultValue;
    } else {
      defaultVa = value;
    }
    return defaultVa;
  }

  addNewRow() {
    let canAdd:boolean = true
    if(this.checkValidityBeforeAddingNewRow && this.value!== undefined && this.value == null && this.value.length > 0 && !this.gridFrmGroup.valid) {
      canAdd = false;
    }
    if(canAdd) {
      if(this.value === undefined || this.value === null){
        this.value = [];
      }
      let _this = this;
      let lineNumber = this.value.length;
      let rowData = {};
      this.columns.forEach(function (column:Tcolumn) {
        let control:FormControl = new FormControl({value: _this.getDefaultValue(column.getDefaultValue()),disabled:_this.isDisabled(column)},column.getValidators());
        _this.gridFrmGroup.addControl(column.getField()+lineNumber,control);
        rowData[column.getField()] = control.value;
      });
      if(this.value.length === 0) {
        this.onFormGroupCreated.emit(this.gridFrmGroup);
      }
      this.value.push(rowData);
      this.onFormGroupCreated.emit(this.gridFrmGroup);
      if(this.maintainRowConfig && !Object.keys(this.rowWiseConfig).includes(lineNumber+"")) {
        this.rowWiseConfig[lineNumber] = {};
      }
    }
    //this.formvalues.emit(this.value);
  }

  isDisabled(column: Tcolumn) {
    if (column.getFromExtra('meta')['disabled'] !== undefined) {
      return column.getFromExtra('meta')['disabled'];
    }
    return false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      this.recreateFormGroup();
    }

    if (changes['refreshFormGroup'] && changes['refreshFormGroup'].currentValue) {
      this.recreateFormGroup();
    }

    if (changes['refreshNewRows'] && changes['refreshNewRows'].currentValue) {
      this.addNewRows(this.newRows);
    }
    if (changes['fileToImport']) {
      this.importFile(changes['fileToImport'].currentValue);
    }

    if(changes['addIntitialRow'] && changes['addIntitialRow'].currentValue) {
      this.addNewRow();
    }
  }

  recreateFormGroup() {
    let _this = this;
    let gridFRomGroupTemp: FormGroup = new FormGroup({});
    if (this.value !== null && this.value !== undefined && this.value.length > 0) {
      this.value.forEach(function(rowData: any, lineNumber: number) {
        _this.columns.forEach(function(column: Tcolumn) {
          if (column !== undefined && column !== null) {
            if (column.getColumnType() === 'D' && rowData[column.getField()] !== undefined && rowData[column.getField()] !== null) {
              rowData[column.getField()] = new Date(rowData[column.getField()]);
            }
            let control: FormControl = new FormControl({
              value: rowData[column.getField()],
              disabled: _this.isDisabled(column)
            }, column.getValidators());
            gridFRomGroupTemp.addControl(column.getField() + lineNumber, control);
          }
        });
      });
      this.gridFrmGroup = gridFRomGroupTemp;
      this.importing = false;
    }
    this.onFormGroupCreated.emit(this.gridFrmGroup);
    this.onRefreshFormGroupComplete.emit();
  }

  rowDelete(ri, rowData) {
    this.addedCombination.splice(this.addedCombination.indexOf(this.getCombinationString(this.value[ri])), 1);
    this.value.splice(ri, 1);
    this.recreateFormGroup();
    this.onrowdelete.emit({value: this.value, rowIndex: ri, rowData: rowData});
  }

  getRowValue(value: any, col: Tcolumn) {
    if (col.getColumnType() === 'N') {
      return parseFloat(value);
    }
    return value;

  }

  onFieldValueChange(rowData, col: Tcolumn, ri) {
    if(this.maintainRowConfig && !Object.keys(this.rowWiseConfig).includes(ri+"")) {
      this.rowWiseConfig[ri] = {};
    }
    this.onChangeFieldValue.emit({rowData: rowData, col: col.getField(), rowIndex: ri,rowConfig:this.rowWiseConfig[ri]});
    this.emitFormGroupStatus();
  }

  emitFormGroupStatus() {
    this.formGroupStatus.emit({
      status: this.gridFrmGroup.valid,
      errors: this.gridFrmGroup.errors,
      formGroup: this.gridFrmGroup
    });
  }

  private getCombinationString(rowData: any) {
    let str = '';
    this.combinationColumns.forEach(function (col) {
      str += rowData[col];
    });
    return str;
  }

  private addNewRows(newRows: any []) {
    let _this = this;
    let combinationString = '';
    newRows.forEach(function (newRow) {
      if (_this.combinationColumns !== undefined && _this.combinationColumns !== null && _this.combinationColumns.length > 0) {
        combinationString = _this.getCombinationString(newRow);
        if (!_this.addedCombination.includes(combinationString)) {
          _this.value.push(newRow);
          _this.addedCombination.push(combinationString);
        }
      } else {
        _this.value.push(newRow);
      }
    });
    this.recreateFormGroup();
    this.onAddNewRowComplete.emit();
  }

  private importFile(currentValue: File) {
    if (currentValue !== undefined && currentValue !== null) {
      let _this = this;
      this.importing = true;
      this.gridDisplay = true;
      let reader: FileReader = new FileReader();
      reader.readAsText(currentValue);
      reader.onload = (e) => {
        let csv: any = reader.result;
        let lines = csv.split(/\r|\n|\r/);
        let headers = lines[0].split(',');
        let newLines = [];
        for (let i = 1; i < lines.length; i++) {
          if (lines[i] !== undefined && lines[i] !== null && lines[i].length > 0) {
            let data = lines[i].split(',');
            let rowData = {};
            _this.columns.forEach(function (col: Tcolumn) {
              let index = 0;
              if (headers.includes('"' + col.getHeader() + '"') || headers.includes('"')) {
                index = headers.indexOf('"' + col.getHeader() + '"');
              } else {
                index = headers.indexOf(col.getHeader());
              }
              rowData[col.getField()] = _this.getRowValue(data[index].replace(new RegExp('"', 'g'), ''), col);
            });
            newLines.push(rowData);
          }
        }
        _this.addNewRows(newLines);
      };
      this.onImportFileComplete.emit();
    }

  }

  sortCustom(value: any) {
    if(this.customSortFunction !== undefined && this.customSortFunction !== undefined) {
      return this.commonService.runFunction(value,this.customSortFunction);
    }
  }

  getRowData(rowData: any, ri: any) {
    rowData['rowNum'] = ri;
    return rowData;
  }

  onSort(value: any) {
    this.recreateFormGroup();
  }

  getValueForRow(index:number,field:string,key:string) {
    if(this.maintainRowConfig && Object.keys(this.rowWiseConfig).includes(index+"") && Object.keys(this.rowWiseConfig[index]).includes(field)) {
      return this.rowWiseConfig[index][field][key];
    }
    return null;
  }

  getFormGroup(col: any, ri: any) {
    return this.gridFrmGroup.controls[col.getField()+ri]
  }

  onClickCheckBox(rowData: any) {
    this.onSelectCheckbox.emit(rowData);
  }

  onRowSelectionChange($event: any) {
    this.onSelectionChange.emit(this.selectedRows)
  }

  onClickClickableField(col: Tcolumn, rowData: any) {
    return this.onClickClickable.emit({col:col,data:rowData});
  }
}
