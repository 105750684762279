import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Tcolumn} from '../../../grid/tcolumn.model';
import {Grid} from '../../../grid/grid.model';
import {Validators} from '@angular/forms';
import {defaultValue} from '../../../grid/defaultValue.model';
import {MasterService} from '../../services/MasterService';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-global-indicator-details',
  templateUrl: './global-indicator-details.component.html',
  styleUrls: ['./global-indicator-details.component.css']
})
export class GlobalIndicatorDetailsComponent implements OnInit {

  id: string = '';
  grid: Grid;
  columns: Map<string, Tcolumn>;

  constructor(private router: ActivatedRoute, private masterCommonService: MasterService) {
    this.router.params.subscribe(params => {
      this.id = params['id'];
    });
  }

  ngOnInit() {
    this.getColumns();
    this.grid = new Grid(this.columns, true, true, true, true, 'globalIndicatorCode', '/globalIndicatorDetails/search/findByCodeGroupNameOrderByIdDesc?giCodeGroup=' + this.id, '', 'Global Indicator Details', 'codeGroupName');
    this.grid.setFirstLevelDataUrl('/globalIndicator');
    this.grid.iconName = 'globalIndicator';
    this.grid.setSaveUrl(environment.base_url + '/api/globalindicatordetails/v1/save');
  }

  public getColumns() {
    this.columns = new Map();
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('groupName', new Tcolumn('groupName', 'Global Indicator Code group', 'LB', 1, false, undefined, false, this.id, 'B', [Validators.required]));
    this.columns.set('name', new Tcolumn('name', 'Global Indicator Details Name', 'T', 3, true, undefined, true, new defaultValue('', '', ['-', ' ', '_', '/', '\\', '(', ')', '.', '&', '"', ';', ':', "'"]), 'B', [Validators.required, Validators.maxLength(100)]));
    this.columns.set('description', new Tcolumn('description', 'Global Indicator Details Description', 'TA', 4, true, undefined, true, new defaultValue('', '', ['-', ' ', '_', '/', '\\', '(', ')', '.', '&', '"', ';', ':', "'"]), 'B', [Validators.maxLength(254)]));
    this.columns.set('status', new Tcolumn('status', 'Status', 'B', 5, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));
  }

}
