<div class="container">
  <div>
    <div class="header1">{{sourceHeader}}</div>
    <select #list1dd (ngModelChange)="rowSelected()" [(ngModel)]="selctednumber" multiple>
      <option (select)="addselectoflist1(option)" *ngFor="let option of list1"
              value="{{option.field}}">{{option.header}}</option>
    </select>
  </div>
  <div class="buttonsdiv">
    <button (click)="addAll()">Add All</button>
    <button (click)="addSelected()">Add Selected</button>
    <button (click)="removeAll()">Remove All</button>
    <button (click)="removeSelected()">Remove Selected</button>
  </div>
  <div>
    <div class="header1">{{targetHeader}}</div>
    <select #list2dd multiple>
      <option (select)="addselectoflist2(option)" *ngFor="let option of list2"
              value="{{option.field}}">{{option.header}}</option>
    </select>
  </div>
</div>
