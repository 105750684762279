import { Component, OnInit } from '@angular/core';
import {CommonService} from '../../services/common.service';
import {MasterService} from '../services/MasterService';
import {BreadcrumbService} from '../../Components/ctrm-breadcrumb/breadcrumb.service';
import {Tcolumn} from '../../grid/tcolumn.model';
import {FormGroup} from '@angular/forms';
import {Grid} from '../../grid/grid.model';
import {FormControlServiceService} from '../../services/form-control-service.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-approver-workflow',
  templateUrl: './approver-workflow.component.html',
  styleUrls: ['./approver-workflow.component.css']
})
export class ApproverWorkflowComponent implements OnInit {
  approverWorkFlow: Tcolumn[] = [];
  approvalSpan:any;
  workflowName:any;
  triggerEvent:any;
  postAction:any;
  negativePostAction:any;
  isSystem:any;
  isActive:any;
  data:object;
  value:any[]=[];
  rowdata: any;
  display: boolean= false;
  uuid: any;
  constructor(public commonService: CommonService, private masterCommonService: MasterService,
              public breadCrumbService: BreadcrumbService,private router: ActivatedRoute, private route: Router) { }

  ngOnInit(): void {
    this.breadCrumbService.makeBreadcrumbTo(this.commonService.MASTER_MAP_KEY, '/approverworkflow');
    this.router.queryParams.subscribe(params=>{
      if(params.value!=undefined || params.value!=null){
        this.commonService.getJSONByURL(environment.base_url_new+`/approval-workflow/api/workflow-config/${params.value}`).subscribe((next:any)=>{
          this.value= next['workflowSteps'];
          this.workflowName=next['workflowName'];
          this.triggerEvent=next['triggerEvent'];
          this.approvalSpan=next['approvalSpan'];
          this.postAction=next['postAction'];
          this.negativePostAction=next['negativePostAction'];
          this.isSystem=next['isSystem'];
          this.isActive=next['isActive'];
          this.uuid=next['uuid'];
          this.getapproverWorkflowcoulmns();
        })
      }
    })
  }
  getapproverWorkflowcoulmns() {
    if(!this.isSystem) {
      this.approverWorkFlow.push(new Tcolumn('delete', 'Actions', 'LB', 1, true, undefined, true, undefined, 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    }
    this.approverWorkFlow.push(new Tcolumn('role', 'Role', 'T', 0, false, undefined, true,'','B',undefined));
    this.approverWorkFlow.push(new Tcolumn('actionName', 'Action Name', 'T', 1, false, undefined, true,'','B',undefined));
    this.approverWorkFlow.push(new Tcolumn('sequence', 'Sequence', 'N', 2, false, undefined, true,'','B',undefined));
    this.approverWorkFlow.push(new Tcolumn('mandatory', 'Mandatory', 'T', 4, false, this.masterCommonService.getBooleanFromLabel('Yes', 'No'), true,'Yes','B',undefined));
    this.approverWorkFlow.push(new Tcolumn('sla', 'SLA', 'T', 4, false, this.masterCommonService.getBooleanFromLabel('Yes', 'No'), true,'Yes','B',undefined));
    this.approverWorkFlow.push(new Tcolumn('desiredEndStatus', 'Post Actions', 'B', 3, true, [], true,'','B',undefined));
  }

  onclickButton(value: any) {
    this.rowdata = value.rowData;
    this.display=true;
  }

  onclose() {
    this.display=false;
  }

  onclickEdit() {
    this.route.navigate(['/approvalupdate'],{queryParams:{value:this.uuid}});
  }
}
