import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {MessageService, Table} from 'primeng';
import {CommonService} from '../../services/common.service';
import {BreadcrumbService} from '../../Components/ctrm-breadcrumb/breadcrumb.service';
import {environment} from '../../../environments/environment';
import {KeyValue} from '../../grid/key-value.model';
import {Tcolumn} from '../../grid/tcolumn.model';
import {FilterUtils} from 'primeng/utils';
import {Router} from '@angular/router';
import {MasterService} from '../../masters/services/MasterService';
import {HttpErrorResponse} from '@angular/common/http';
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";

@Component({
    selector: 'app-point-of-sale',
    templateUrl: './point-of-sale.component.html',
    styleUrls: ['./point-of-sale.component.css']
})
export class PointOfSaleComponent implements OnInit,AfterViewInit {

    @ViewChild('posTable', {static: true}) posTable: Table;
    formDisplay: boolean = false;
    addFormDisplay: boolean = false;
    posForm: FormGroup;
    addCpForm: FormGroup;
    storageCols: any[];
    storageId: string = '';
    commodity: string = '';
    location: string = '';
    selectedStorage: any[] = [];
    counterpartyList: any[];
    counterpartyDefaultBank: any[] = [];
    storageInventoryOptions: any[] = [{label: 'Select', value: ''}];
    inventoryLocationOptions: any[] = [{label: 'Select', value: ''}];
    inventoryCommodityOptions: any[] = [{label: 'Select', value: ''}];
    storageData: any[] = [];
    recentPos: any[] = [];
    loadData: any[] = [];
    deliveryDate: any;
    posTableHeight: string = '400px';
    @Input() showTooltipOnValidationError: boolean = false;
    @Input() formControlAIO: FormControl;
    @Input() field: Tcolumn;
    @Input() listOfValues: KeyValue[] = [];
    @Input() readOnly: boolean = false;
    paymentTermList: any[];
    includedCharacters: any[] = ['_', '/', ' '];
    bsConfig: Partial<BsDatepickerConfig>;

    constructor(private changeDetectorRef:ChangeDetectorRef,private masterCommonService: MasterService, public breadCrumbService: BreadcrumbService, public commonService: CommonService, private messageService: MessageService, private router: Router) {
    }

    ngAfterViewInit() {
        this.changeDetectorRef.detectChanges();
    }

    ngOnInit(): void {
        this.initForm();
        this.breadCrumbService.makeBreadcrumbTo(this.commonService.OPERATION_MAP_KEY, '/pos');
        this.storageCols = [
            {field: 'storageId', header: 'Storage Id'},
            {field: 'location', header: 'Storage Location'},
            {field: 'commodity', header: 'Commodity'},
            {field: 'availableCapacity', header: 'Available Capacity'},
            {field: 'stockedQuantity', header: 'Stocked Quantity'},
            {field: 'storageUom', header: 'Storage Uom'},
            {field: 'drawPrice', header: 'Draw Price'}
        ];
        this.getInventoryData();
        this.getPosData();
        FilterUtils['matchEquals'] = function gte(value: any, filter: any): boolean {
            if (filter === undefined || filter === null) {
                return false;
            }
            if (value === undefined || value === null || value.length === 0) {
                return false;
            }
            if (value === filter) {
                return true;
            }
            return false;
        };
        this.bsConfig = new BsDatepickerConfig();
        this.bsConfig.dateInputFormat = (environment.dateFormat).toUpperCase();
        this.bsConfig.adaptivePosition = true;
    }

    getClassForHeading(index) {
        return index > 0 ? 'headerNormal' : 'noPipe';
    }

    onCreatePos() {
        this.commonService.getLoadData('paymentterm', ['config', 'config'], ['paymentterm', 'counterparty']).subscribe((next: any) => {
            this.loadData = next;
            this.getCounterpartyList();
            this.getPaymentTermList();
            this.initForm();
            if (this.selectedStorage.length > 0 && this.selectedStorage.length < 2) {
                this.formDisplay = true;
            } else {
                this.showToast("Select the storage to create POS. You’ve not selected any storage.");
            }
        });
    }

    showToast(msg, severity: string = 'info') {
        this.messageService.add({
            severity: severity,
            summary: msg
        });
    }

    onClose() {
        this.formDisplay = false;
    }

    calculateHeight() {
    }

    getInventoryData() {
        this.commonService.getJSONByURL(environment.base_url + '/api/storageInventory/v1/getstorageinventorybytenantid?tenantId=' + this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
            this.storageData = next['inventoryObject'];
            this.getFilterList(next);
        });

    }

    getFilterList(data) {
        let _this = this;
        data['inventoryIds'].forEach(data => {
            _this.storageInventoryOptions.push(new KeyValue(data, data));
        });

        data['inventoryLocation'].forEach(data => {
            _this.inventoryLocationOptions.push(new KeyValue(data, data));
        });

        data['inventoryCommodity'].forEach(data => {
            _this.inventoryCommodityOptions.push(new KeyValue(data, data));
        });
    }

    getPosData() {
        this.commonService.getJSONByURL(environment.base_url + '/api/pointofsale/v1/getall?tenantId=' + this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
            this.recentPos = next;
        });
    }

    initForm() {
        this.posForm = new FormGroup({
            commodity: new FormControl(this.selectedStorage[0] == undefined ? '' : this.selectedStorage[0]['commodity'], [Validators.required]),
            saleCurrency: new FormControl(this.selectedStorage[0] == undefined ? '' : this.selectedStorage[0]['storageCurrency'], [Validators.required]),
            drawPrice: new FormControl(this.selectedStorage[0] == undefined ? '' : this.selectedStorage[0]['drawPrice']),
            deliveryDate: new FormControl(this.selectedStorage[0] == undefined ? '' : new Date()),
            saleQuantity: new FormControl('', [Validators.required]),
            storageUom: new FormControl(this.selectedStorage[0] == undefined ? '' : this.selectedStorage[0]['storageUom'], [Validators.required]),
            noOfBags: new FormControl('', [Validators.required]),
            salePrice: new FormControl('', [Validators.required]),
            primaryAccountNumber: new FormControl('', [Validators.required]),
            address: new FormControl('', [Validators.required]),
            paymentTerm: new FormControl('', [Validators.required]),
            counterparty: new FormControl('', [Validators.required]),
            specialInstructions: new FormControl('', [])
        });

        this.addCpForm = new FormGroup({
            name: new FormControl('', [Validators.required]),
            primaryAccountNumber: new FormControl('', [Validators.required]),
            counterpartyAddress: new FormControl('', [Validators.required]),
            bankName: new FormControl('', [Validators.required]),
            swiftCode: new FormControl('', [Validators.required]),
        });
    }

    onCreate() {
        if (this.posForm.controls['commodity'].value !== '' &&
            this.posForm.controls['saleQuantity'].value !== '' &&
            this.posForm.controls['storageUom'].value !== '' &&
            this.posForm.controls['deliveryDate'].value !== '' &&
            this.posForm.controls['salePrice'].value !== '' &&
            this.posForm.controls['saleCurrency'].value !== '' &&
            this.posForm.controls['counterparty'].value !== '') {
            if (this.posForm.controls['paymentTerm'].value !== '') {
                let payload: {};
                let deliverySchedule: any[];
                deliverySchedule = [{
                    internalPackage: '',
                    noOfUnits: 1,
                    periodEndDate: this.posForm.controls['deliveryDate'].value,
                    periodStartDate: this.posForm.controls['deliveryDate'].value,
                    quantity: this.posForm.controls['saleQuantity'].value,
                    uuid: ''
            }];
                payload = {
                    commodity: this.posForm.controls['commodity'].value,
                    createdBy: this.commonService.getFromStorage('userName'),
                    updatedBy: this.commonService.getFromStorage('userName'),
                    tenantId: this.commonService.getFromStorage('tenantId'),
                    quantity: this.posForm.controls['saleQuantity'].value,
                    quantityUom: this.selectedStorage[0]['storageUom'],
                    salePrize: this.posForm.controls['salePrice'].value,
                    saleCurrency: this.posForm.controls['saleCurrency'].value,
                    storageId: this.selectedStorage[0]['uuid'],
                    deliveryDate: this.posForm.controls['deliveryDate'].value,
                    specialInstructions: this.posForm.controls['specialInstructions'].value,
                    counterparty: this.posForm.controls['counterparty'].value,
                    paymentTerm: this.posForm.controls['paymentTerm'].value,
                    deliverySchedule: deliverySchedule
                };
                this.commonService.post(environment.base_url + '/api/pointofsale/v1/save', payload).subscribe((next: any) => {
                    this.onClose();
                    this.getPosData();
                    this.getInventoryData();
                    this.showToast('POS created Successfully');
                }, (error: HttpErrorResponse) => {
                    // this.messageService.add({severity: 'error', summary: JSON.parse(error.error)['status']});
                    this.showToast(this.commonService.getHttpErrorMessage(error, undefined), 'error');
                });
            } else {
                this.messageService.add({
                    severity: 'info', summary: 'All the fields are mandatory'
                });
            }
        } else {
            this.messageService.add({
                severity: 'info', summary: 'All the fields are mandatory'
            });
        }
    }

    getCounterpartyList() {
        this.counterpartyList = this.masterCommonService.getListFromArray(this.loadData['counterparty']);
    }

    throwCounterpartyChanges(commodity: any) {
        this.commonService.getJSONByURL(environment.base_url + '/api/counterpartybankaccount/v1/getcounterpartybankdetailbycompanyanddefaultvalue?counterparty=' + commodity + '&tenantId=' + this.commonService.getFromStorage('tenantId') + '&addressDefault=true').subscribe((next: any) => {
            this.posForm.controls['primaryAccountNumber'].setValue(next.length == 0 ? '' : next[0]['accountNumber']);
            this.posForm.controls['address'].setValue(next.length == 0 ? '' : next[0]['bankAddress']);
        }, (error: HttpErrorResponse) => {
            this.messageService.add({severity: 'error', summary: 'Default Bank account does not exist for selected counterparty'});
            this.posForm.controls['primaryAccountNumber'].setValue('');
            this.posForm.controls['address'].setValue('');
        });
    }

    getIconFromValidations(formGroup: FormGroup, field: string) {
        let icon = 'pi pi-chevron-right iconCls2';
        if (field !== '') {
            if (formGroup.controls[field].valid && icon !== 'pi pi-times colorCross iconCls') {
                icon = 'pi pi-check colorTick iconCls';
            } else {
                if (!formGroup.controls[field].disabled) {
                    icon = 'pi pi-times colorCross iconCls';
                }
            }
        }
        return icon;
    }

    getTooltipMessage(control: AbstractControl, col) {
        return this.showTooltipOnValidationError ? this.commonService.getValidationErrorMessage(control, col.getHeader()) : '';
    }

    onClickAdd() {
        this.addFormDisplay = true;
    }

    onAddClose() {
        this.addCpForm['name'] = [];
        this.addFormDisplay = false;
    }

    onAddCp() {
        if (this.addCpForm.controls['name'].value !== '' &&
            this.addCpForm.controls['primaryAccountNumber'].value !== '' &&
            this.addCpForm.controls['bankName'].value !== '' &&
            this.addCpForm.controls['swiftCode'].value !== '' &&
            this.addCpForm.controls['counterpartyAddress'].value !== '') {
            let payload: {};
            payload = {
                name: this.addCpForm.controls['name'].value,
                description: this.addCpForm.controls['name'].value,
                bankName: this.addCpForm.controls['bankName'].value,
                accountNumber: this.addCpForm.controls['primaryAccountNumber'].value,
                bankAddress: this.addCpForm.controls['counterpartyAddress'].value,
                swiftCode: this.addCpForm.controls['swiftCode'].value,
                accountCurrency: this.selectedStorage[0]['storageCurrency'],
                counterpartyTypeCode: 'Counterparty',
                currency: this.selectedStorage[0]['storageCurrency'],
                timeZone: this.commonService.getFromStorage('timezone'),
                parent: 'none',
                dayLightSaving: false,
                financeControllerApproval: false,
                isTradeable: true,
                kycStatus: false,
                status: true,
                counterpartyUuid: '',
                defaultValue: true,
                createdBy: this.commonService.getFromStorage('userName'),
                updatedBy: this.commonService.getFromStorage('userName'),
                tenantId: this.commonService.getFromStorage('tenantId'),
            };

            this.commonService.post(environment.base_url + '/api/counterpartybankaccount/v1/savecpandbankdetails', payload).subscribe((next: any) => {
                this.addFormDisplay = false;
                this.showToast("Counterparty bank details created successfully");
                this.setBankDetails(this.addCpForm.controls['name'].value, this.addCpForm.controls['counterpartyAddress'].value, this.addCpForm.controls['primaryAccountNumber'].value);
            }, (error: HttpErrorResponse) => {
                this.showToast(this.commonService.getHttpErrorMessage(error, undefined), 'error');
            });
        } else {
            this.messageService.add({
                severity: 'info', summary: 'All the fields are mandatory'
            });
        }
    }


    setBankDetails(counterparty: string, address: string, accountNumber: string) {
        this.posForm.controls['primaryAccountNumber'].setValue(accountNumber);
        this.posForm.controls['address'].setValue(address);
        this.counterpartyList.push({label: counterparty, value: counterparty});
        this.posForm.controls['counterparty'].setValue(counterparty);
    }

    applyFilter(dropDownName: string, dropDownValue: string) {
        this.posTable.filter(dropDownValue, dropDownName, 'matchEquals');
    }

    getPaymentTermList() {
        this.paymentTermList = this.masterCommonService.getListFromArray(this.loadData['paymentterm']);
    }

    goTo() {
        this.router.navigate(['/storageInventory']);
    }
}
