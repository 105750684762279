import {MenuItem} from 'primeng/api';
import { ApprovalFunctionParams } from 'src/app/Components/approval-context-menu/model/approval-function.params';
import {Button} from '../../grid/buttons.model';
import {environment} from "../../../environments/environment";
import {HttpErrorResponse} from "@angular/common/http";

export const OperationDashboardApprovalFunctions: { [key: string]: Function } = {
    loadMenuItems: (param: ApprovalFunctionParams): MenuItem[] => {
        let items: MenuItem[] = [];
        let tradeObligationstate = param.rowData['tradeObligationState'];
        let plannedObligationState = param.rowData['obligationState'];
        let parentId= param.rowData['parentPlannedObligation'];

        let tradePlanning = function() {
            param.context.onMenuItemClick.emit({title: "Trade Planning",data:param.rowData});
        };
        let actualization = function() {
            param.context.onMenuItemClick.emit({title: "Actualization",data:param.rowData});
        };

        let settlementClick = function() {
            param.context.onMenuItemClick.emit({title: "Settlement",data:param.rowData});
        };

        let addCostAction = function() {
            param.context.onMenuItemClick.emit({title: "Add Cost",data:param.rowData});
        };
        let generateShippingDocuments = function() {
            param.context.onMenuItemClick.emit({title: "Generate Operation Documents",data:param.rowData});
        };
        let washoutPlanning = function (){
            param.context.onMenuItemClick.emit({title:"Washout Planning (Doc ByPass)",data:param.rowData});
        }
        let interCompanyTrade =function(){
            param.context.onMenuItemClick.emit({title:"Inter-company trade",data:param.rowData});
        }
        let declarePort = function(){
            param.context.onMenuItemClick.emit({title:"Declare Port",data:param.rowData});
        }

        let mergeObligation = () => {
            var showintercompanyMsg: Object = false;
            param.commonService.getJSONByURL(environment.base_url+"/api/plannedObligation/v1/show-intercompany-message-merge-obligation?obligationId="+ parentId).subscribe(item=>{

                showintercompanyMsg= item;
                var interCompanyCheck = 'Some obligation are linked to Inter Company trades. Splitting or merging it will reset the Inter Company trade data and user has to enter Inter Company data from Operation Dashboard again. '+
                    '\n'+' Do you want to Continue?'

                var message= "This action will result in merging of all the split obligations under "+param.rowData['parentPlannedObligation'] +'.\n' +(showintercompanyMsg?interCompanyCheck:"");
                param.commonService.confirmDialog(message,() => {
                    let parentId = param.rowData['parentPlannedObligation'];
                    param.commonService.getJSONByURL( `${environment.base_url}/api/plannedObligation/v1/merge-obligations?parentObligationId=${parentId}`).subscribe((next) => {
                        if(next['errorInstance']){
                            param.commonService.postMessage(next['status'],next['response'].join(","),'error');
                        }
                        else {
                            param.commonService.postMessage('',next['status'],'success');
                            param.context.onMenuItemClick.emit({title:"Merge Obligations",data:param.rowData});
                        }
                    },(error:HttpErrorResponse) => {
                        param.commonService.postMessage('Error while merge',param.commonService.getHttpErrorMessage(error),'error');
                    });
                })
            })



        }

        let splitRemainingQuantityV1 = () => {
            param.commonService.confirmDialog('You are trying to split the remaining quantity. Do you want to continue?',() => {
                let plannedObligationId = param.rowData['plannedObligationId'];
                let planId = param.rowData['planId'];
                param.commonService.post( `${environment.base_url}/api/plannedObligation/v1/splitRemainingQuantityInObligation?planId=${planId}&plannedObligationId=${plannedObligationId}`,{}).subscribe((next) => {
                    if(next['errorInstance']){
                        param.commonService.postMessage(next['status'],next['response'].join(","),'error');
                    } else {
                        param.commonService.postMessage('',next['status'],'success');
                        param.context.onMenuItemClick.emit({title:"Merge Obligations",data:param.rowData});
                    }
                },(error:HttpErrorResponse) => {
                    param.commonService.postMessage('Error while merge',param.commonService.getHttpErrorMessage(error),'error');
                });
            })

        }
        let splitObligation = () => {
            param.context.onMenuItemClick.emit({title:"Split Obligations",data:param.rowData});
        }
        let tradePlanningButton = new Button("Trade Planning", 'F', '', tradePlanning, undefined, undefined, undefined, undefined,true);
        let actualizationButton = new Button("Actualization", 'F', '', actualization, undefined, undefined, undefined, undefined, true);
        let settlementButton = new Button("Settlement", 'F', '', settlementClick, undefined, undefined, undefined, undefined, true);
        let docByPassButton = new Button("Washout Planning (Doc ByPass)",'F','',washoutPlanning,undefined,undefined,undefined, undefined, true);
        let interCompanyButton = new Button("Inter-company trade",'F','',interCompanyTrade,undefined,undefined,undefined,undefined,true);
        let declarePortButton = new Button("Declare Port",'F','',declarePort,undefined,undefined,undefined,undefined,false);
        let splitObligations = new Button("Split Obligation",'F','',splitObligation,undefined,undefined,undefined,undefined,false);
        let mergeObligations = new Button("Merge Obligations",'F','',mergeObligation,undefined,undefined,undefined,undefined,true);
        let splitRemainingQuantity = new Button("Split Remaining Quantity",'F','',splitRemainingQuantityV1,undefined,undefined,undefined,undefined,true);
        if(plannedObligationState !== undefined && plannedObligationState !== null && Object.keys(plannedObligationState).length > 0) {
            if(param.rowData['tradeSettlementReference'] === null){
                interCompanyButton.disabled =false;
            }
            if((param.rowData['docByPassId'] === null || param.rowData['docByPassId']==="")){
                docByPassButton.disabled= false;
            }
            if(((param.rowData['balanceQuantity']>0) &&
                (param.rowData['planId'] != null || param.rowData['planId']!="") &&
                (param.rowData['plannedQuantity']-param.rowData['balanceQuantity']) >= 0) &&
                (!param.rowData['obligationState']['ACTUALIZED']) &&
                !(param.rowData['priceType'] ==='PTBF' && (param.rowData['obligationState']['PRICED'] || param.rowData['obligationState']['PARTIALLY_PRICED']))
            ){
                splitRemainingQuantity.disabled= false;
            }
            if(!plannedObligationState['ACTUALIZED']) {
                actualizationButton.disabled = false;
            } else {
                settlementButton.disabled = param.rowData['invoiceDone'];
            }
        } else {
            if (tradeObligationstate !== null && tradeObligationstate !== undefined && tradeObligationstate === 'UNPLANNED') {
                tradePlanningButton.disabled = false;
            }
        }
        let parentObligationId = param.rowData['parentPlannedObligation'];
        if(parentObligationId !== null && parentObligationId !== undefined && parentObligationId !== ''){
            mergeObligations.disabled = false;
        }

      if(param.accessService.canAccessWithCurrentRoute('Action','tradePlanning')){
        items.push(param.commonService.getMenuItemFromButton(tradePlanningButton));
       }
      if(param.accessService.canAccessWithCurrentRoute('Action','actualization')){
        items.push(param.commonService.getMenuItemFromButton(actualizationButton));
      }
      if(param.accessService.canAccessWithCurrentRoute('Action','settlement')) {
          items.push(param.commonService.getMenuItemFromButton(settlementButton));
      }
      if(param.accessService.canAccessWithCurrentRoute('Action','addCost')) {
          items.push(param.commonService.getMenuItemFromButton(new Button("Add Cost", 'F', '', addCostAction, undefined, undefined, undefined, undefined, false)));
      }
      if(param.accessService.canAccessWithCurrentRoute('Action','generateDocument')) {
          items.push(param.commonService.getMenuItemFromButton(new Button("Generate Operation Documents", 'F', '', generateShippingDocuments, undefined, undefined, undefined, undefined, false)));
      }
      if(param.accessService.canAccessWithCurrentRoute('Action','docByPassPlanning')) {
          items.push(param.commonService.getMenuItemFromButton(docByPassButton))
      }
      if(param.accessService.canAccessWithCurrentRoute('Action','interCompanyTrade')) {
          items.push(param.commonService.getMenuItemFromButton(interCompanyButton))
      }
      if(param.accessService.canAccessWithCurrentRoute('Action','declarePort')){
        items.push(param.commonService.getMenuItemFromButton(declarePortButton))
      }
      if(param.accessService.canAccessWithCurrentRoute('Action','splitObligation')){
        items.push(param.commonService.getMenuItemFromButton(splitObligations))
      }
      if(param.accessService.canAccessWithCurrentRoute('Action','mergeObligation')) {
          items.push(param.commonService.getMenuItemFromButton(mergeObligations))
      }
      if(param.accessService.canAccessWithCurrentRoute('Action','splitRemainingQuantityV1')){
        items.push(param.commonService.getMenuItemFromButton(splitRemainingQuantity))
      }
        items.push(param.commonService.getMenuItemFromButton(splitRemainingQuantity))
        return items;
    },
    onDone: (param: ApprovalFunctionParams) => {
        param.commonService.postMessage('', `${param.currentApprovalObject.step['actionName']} ${param.currentApprovalObject.buttonName} Successfully.`,'success');
        param.onUpdateDone.emit({step: param.currentApprovalObject.buttonName});
    }
};
