import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {GridService} from '../../../grid/grid.service';
import {AuthService} from '../../../services/auth.service';
import {UserMasterInfoBean} from '../../../services/models/userMasterInfo.model';
import {environment} from '../../../../environments/environment';
import {CommonService} from "../../../services/common.service";
import {MasterService} from "../../services/MasterService";
import {Grid} from "../../../grid/grid.model";
import {entities} from "../../../services/common/entities";
import {messages} from "../../../services/common/messages";
import {ConfirmationService} from "primeng";


@Component({
  selector: 'app-userinfo',
  templateUrl: './userinfo.component.html',
  styleUrls: ['./userinfo.component.css'],
  providers: [ConfirmationService]
})
export class UserinfoComponent implements OnInit, OnChanges {
  @Input() display: boolean = false;
  @Input() grid: Grid;
  @Input() data: any;
  @Output() onClose = new EventEmitter();
  @Output() onUserInfoUpdated = new EventEmitter();
  selectedProfitCenters: any[] = [];
  selectedLegalEntities: any[] = [];
  selectedCounterParty: any[] = [];
  lastActiveIndex: number = 0;
  selectedCommodity: any[] = [];
  profitCenterOptions: any[] = [];
  legalEntitiesOptions: any[] = [];
  counterPartyOptions: any[] = [];
  commodityOptions: any[] = [];
  allProfitCenter: any[] = [];
  allLegalEntities: any[] = [];
  allCounterParty: any[] = [];
  allCommodity: any[] = [];
  unselectedPC: any[] = [];
  unselectedLE: any[] = [];
  unselectedCP: any[] = [];
  unselectedCD: any[] = [];
  listPC: any[] = [];
  listLE: any[] = [];
  listCP: any[] = [];
  listCD: any[] = [];
  isBtnsVisible: boolean = false;
  @Input() editEnabled: boolean = true;
  showConfirmTab: boolean = false;
  originalLegalEnities: any[] = [];
  originalProfitCenter: any[] = [];
  originalCounterparty: any[] = [];
  originalCommodity: any[] = [];
  contentHeight: string = '';
  isLoading: boolean = false;
  activeIndex: number = 0;
  loadDatalist: any[] = [];
  @Input() screentype: string = null;
  displayaudit: boolean = false;
  @Input() heading: string = '';
  @Input() urlIdFieldValue: string = '';


  constructor(private gridService: GridService, private auth: AuthService, private commonService: CommonService, public masterCommonService: MasterService, private confirmationService: ConfirmationService) {
  }

  ngOnInit() {
    let _this = this;
    this.commonService.getLoadData('commodity', ['config','config','config','config'], ['company','profitcenter','commodity','counterparty']).subscribe((next: any) => {
      _this.legalEntitiesOptions = _this.masterCommonService.getListFromArray(next['company'],false,false);
      _this.profitCenterOptions = _this.masterCommonService.getListFromArray(next['profitcenter'],false,false);
      _this.commodityOptions = _this.masterCommonService.getListFromArray(next['commodity'],false,false);
      _this.counterPartyOptions = _this.masterCommonService.getListFromArray(next['counterparty'],false,false);
    });
  }

  getAll() {
    // this.resultLE();
    // this.resultPC();
    // this.resultCD();
    // this.resultCP();
    this.getUserInfo(this.data.userName)
  }

  /**
   * To get All the Counterparty
   */
  getCounterParty() {
    let _this = this;
    this.commonService.getJSONByObject(environment.base_url + 'filter', _this.commonService.getPostObject("counterPartyCode", 'none', "CounterPartyMaster", 'notEqual')).subscribe((data: any) => {
      data.forEach(function(row: any, index) {
        let listValue = row.counterPartyCode;
        _this.listCP.push(row.counterPartyCode);
        if (_this.allCounterParty.includes(row.counterPartyCode)) {
          _this.counterPartyOptions.push({
            label: row.counterPartyName,
            value: {id: index, name: row.counterPartyName, code: row.counterPartyCode}
          });
          _this.selectedCounterParty.push({id: index, name: row.counterPartyName, code: row.counterPartyCode});
          _this.originalCounterparty.push({id: index, name: row.counterPartyName, code: row.counterPartyCode});
        } else {
          _this.counterPartyOptions.push({
            label: row.counterPartyName,
            value: {id: index, name: row.counterPartyName, code: row.counterPartyCode}
          });
        }
      });
    });
    return _this.counterPartyOptions;
  }

  /**
   * To get all Commodity
   */
  getCommodity() {
    let _this = this;
    this.commonService.getJSONByObject(environment.base_url + 'filter', _this.commonService.getPostObject("commodityStatus", true, "commodityMain")).subscribe((data: any) => {
      data.forEach(function(row: any, index) {
        let listValue = row.commodityCode;
        _this.listCD.push(row.commodityCode);
        if (_this.allCommodity.includes(row.commodityCode)) {
          _this.commodityOptions.push({
            label: row.commodityName,
            value: {id: index, name: row.commodityName, code: row.commodityCode}
          });
          _this.selectedCommodity.push({id: index, name: row.commodityName, code: row.commodityCode});
          _this.originalCommodity.push({id: index, name: row.commodityName, code: row.commodityCode});
        } else {
          _this.commodityOptions.push({
            label: row.commodityName,
            value: {id: index, name: row.companyName, code: row.commodityCode}
          });
        }
      });
    });
    return _this.commodityOptions;
  }

  /**
   * To get All the Legal Entity
   */
  getLegalEntity() {
    let _this = this;
    this.commonService.getJSONByObject(environment.base_url + 'filter', _this.commonService.getPostObject("companyStatus", true, entities.company)).subscribe((data: any) => {
      data.forEach(function(row: any, index) {
        _this.listLE.push(row.companyCode);
        if (_this.allLegalEntities.includes(row.companyCode)) {
          _this.legalEntitiesOptions.push({
            label: row.companyName,
            value: {id: index, name: row.companyName, code: row.companyCode}
          });
          _this.selectedLegalEntities.push({id: index, name: row.companyName, code: row.companyCode});
          _this.originalLegalEnities.push({id: index, name: row.companyName, code: row.companyCode});
        } else {
          _this.legalEntitiesOptions.push({
            label: row.companyName,
            value: {id: index, name: row.companyName, code: row.companyCode}
          });
        }
      });
    });
    return _this.legalEntitiesOptions;
  }

  /**
   * To get All the Profit Centre
   */
  getProfitCenter() {
    let _this = this;
    this.commonService.getJSONByObject(environment.base_url + 'filter', _this.commonService.getPostObject("profitCenterStatus", true, "ProfitCenter")).subscribe((data: any) => {
      data.forEach(function(row: any, index) {
        let n = _this.allProfitCenter.includes(row.profitCenterCode);
        let listValue = row.profitCenterCode;
        _this.listPC.push(row.profitCenterCode);
        if (n === true) {

          let option = {
            label: row.profitCenterName,
            value: {id: index, name: row.profitCenterName, code: row.profitCenterCode}
          };
          _this.profitCenterOptions.push(option);
          _this.selectedProfitCenters.push({id: index, name: row.profitCenterName, code: row.profitCenterCode});
          _this.originalProfitCenter.push({id: index, name: row.profitCenterName, code: row.profitCenterCode});
        } else {
          let option = {
            label: row.profitCenterName,
            value: {id: index, name: row.profitCenterName, code: row.profitCenterCode}
          };
          _this.profitCenterOptions.push(option);
        }
      });
    });
    return _this.profitCenterOptions;
  }

  /**
   * To save the selected Values
   */
  onSave() {
    this.checkForChanges(true)
  }


  /**
   * Save Commodity
   */
  saveCD() {
    let _this = this;
    if (_this.selectedCommodity.length !== 0) {
      _this.selectedCommodity.forEach(function(value: any, index) {
        if (_this.allCommodity.includes(value['code'])) {
        } else {
          let info = new UserMasterInfoBean(_this.data.userName, 'Commodity', value['code']);
          _this.auth.postUserInfo(info).subscribe(function(response) {
          }, error => {
          })
        }
      });

    }
    this.reflectedChangesCD();
  }

  /**
   * save counterparty
   */
  saveCP() {
    let _this = this;
    if (_this.selectedCounterParty.length !== 0) {
      _this.selectedCounterParty.forEach(function(value: any, index) {
        if (_this.allCounterParty.includes(value['code'])) {
        } else {
          let info = new UserMasterInfoBean(_this.data.userName, 'Counterparty', value['code']);
          _this.auth.postUserInfo(info).subscribe(function(response) {
          }, error => {
          })
        }
      });

    }
    this.reflectedChangesCP();
  }

  /**
   * save profit center
   */
  savePC() {
    let _this = this;
    if (_this.selectedProfitCenters.length !== 0) {
      _this.selectedProfitCenters.forEach(function(value: any, index) {
        let n = _this.allProfitCenter.includes(value['code']);
        if (n === true) {
        } else {
          let info = new UserMasterInfoBean(_this.data.userName, 'Profit Center', value['code']);
          _this.auth.postUserInfo(info).subscribe(function(response) {
          }, error => {
          })
        }
      });

    }
    this.reflectedChangesPC();
  }

  /**
   * save legal entity
   */
  saveLE() {
    let _this = this;
    if (_this.selectedLegalEntities.length !== 0) {
      _this.selectedLegalEntities.forEach(function(value: any, index) {
        let n = _this.allLegalEntities.includes(value['code']);
        if (n === true) {
        } else {
          let info = new UserMasterInfoBean(_this.data.userName, 'Legal Entity', value['code']);
          _this.auth.postUserInfo(info).subscribe(function(response) {
          }, error => {
          })
        }
      });

    }
    this.reflectedChangesLE();
  }

  getObject(selectedList: any[], masterType) {
    let obj = {
      masterType: masterType,
      userId: this.data['userName'],
      masterTypeValues: selectedList,
      tenantId: this.commonService.getFromStorage('tenantId')
    };
    return obj;
  }

  saveList(selectedList: any[], masterType: string) {
    this.commonService.post(environment.base_url_new + '/api-iam/api/usermanagementservice/v1/saveUserInfo', [this.getObject(selectedList, masterType)]).subscribe((next) => {
    });
    this.updateOriginal(masterType, selectedList);
  }

  updateOriginal(masterType, selectedList) {
    if (masterType === 'Legal Entity') {
      this.originalLegalEnities = [];
      this.originalLegalEnities = [...selectedList];
    } else if (masterType === 'Profit Center') {
      this.originalProfitCenter = [];
      this.originalProfitCenter = [...selectedList];
    } else if (masterType === 'Counterparty') {
      this.originalCounterparty = [];
      this.originalCounterparty = [...selectedList];
    } else if (masterType === 'Commodity') {
      this.originalCommodity = [];
      this.originalCommodity = [...selectedList];
    }
  }

  close() {
    this.display = false;
    this.onClose.emit();
  }


  /**
   * To get saved Countrerparty
   */
  resultCP() {
    let _this = this;
    this.commonService.getJSONByObject(environment.base_url + 'filter', _this.commonService.getPostObject("userId", this.data.userName, "UserInfo")).subscribe((data: any) => {
      data.forEach(function(row: any, index) {
        if (row.masterType === 'Counterparty') {
          _this.allCounterParty.push(row.masterTypeValue);
        }
      });
      _this.getCounterParty();
    });
  }

  /**
   * To get saved Commodity
   */
  resultCD() {
    let _this = this;
    this.commonService.getJSONByObject(environment.base_url + 'filter', _this.commonService.getPostObject("userId", this.data.userName, "UserInfo")).subscribe((data: any) => {
      data.forEach(function(row: any, index) {
        if (row.masterType === 'Commodity') {
          _this.allCommodity.push(row.masterTypeValue);
        }
      });
      _this.getCommodity();
    });
  }

  /**
   * To get saved Profit Centre
   */
  resultPC() {
    let _this = this;
    this.commonService.getJSONByObject(environment.base_url + 'filter', _this.commonService.getPostObject("userId", this.data.userName, "UserInfo")).subscribe((data: any) => {
      data.forEach(function(row: any, index) {
        if (row.masterType === 'Profit Center') {
          _this.allProfitCenter.push(row.masterTypeValue);
        }
      });
      _this.getProfitCenter();
    });
  }

  /**
   * To get saved Legal Entities
   */
  resultLE() {
    let _this = this;
    this.commonService.getJSONByObject(environment.base_url + 'filter', _this.commonService.getPostObject("userId", this.data.userName, "UserInfo")).subscribe((data: any) => {
      data.forEach(function(row: any, index) {
        if (row.masterType === 'Legal Entity') {
          _this.allLegalEntities.push(row.masterTypeValue);
        }
      });
      _this.getLegalEntity();
    });
  }


  /**
   * To get the unselected Values of Counterparty
   */
  reflectedChangesCP() {
    let _this = this;
    let newlist = [];
    this.selectedCounterParty.forEach(function(object) {
      newlist.push(object['code']);
    });
    _this.listCP.forEach(function(value, index) {
      if (newlist.includes(value)) {
      } else {
        _this.unselectedCP.push(value);
      }
    });
    _this.deleteData(_this.unselectedCP, _this.allCounterParty);

  }

  /**
   * To get the unselected Values of Commodity
   */
  reflectedChangesCD() {
    let _this = this;
    let newlist = [];
    this.selectedCommodity.forEach(function(object) {
      newlist.push(object['code']);
    });
    _this.listCD.forEach(function(value, index) {
      if (newlist.includes(value)) {
      } else {
        _this.unselectedCD.push(value);
      }
    });
    _this.deleteData(_this.unselectedCD, _this.allCommodity);

  }

  /**
   * To get the unselected Values of Profit Centre
   */
  reflectedChangesPC() {
    let _this = this;
    let newlist = [];
    this.selectedProfitCenters.forEach(function(object) {
      newlist.push(object['code']);
    });
    _this.listPC.forEach(function(value, index) {
      if (newlist.includes(value)) {
      } else {
        _this.unselectedPC.push(value);
      }
    });
    _this.deleteData(_this.unselectedPC, _this.allProfitCenter);

  }

  /**
   * To get the unselected Values of Legal Entities
   */
  reflectedChangesLE() {
    let _this = this;
    let newlist = [];
    this.selectedLegalEntities.forEach(function(object) {
      newlist.push(object['code']);
    });
    _this.listLE.forEach(function(value, index) {
      if (newlist.includes(value)) {
      } else {
        _this.unselectedLE.push(value);
      }
    });

    _this.deleteData(_this.unselectedLE, _this.allLegalEntities);

  }

  /**
   * to Delete unselected Data from database
   * @param toDelete
   * @param allListName
   */
  deleteData(toDelete: any[], allListName: any[]) {       //to Delete unselected Data from database
    let _this = this;
    let userid = _this.data['userName'];
    toDelete.forEach(function(to) {
      if (allListName.includes(to)) {
        let url = environment.base_url + '/userInfo/search/deleteAllByUserIdEqualsAndMasterTypeValueEquals?userId=' + userid + '&masterTypeValue=' + to + '';
        _this.gridService.deleteDataOnUrl(url).subscribe(next => {
        })
      } else {
      }
    });
  }

  getUnique(array) {
    var uniqueArray = [];

    // Loop through array values
    for (let i = 0; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  }

  /**
   * To Make every array empty
   */
  refreshLists() {
    this.selectedProfitCenters = [];
    this.selectedLegalEntities = [];
    this.selectedCounterParty = [];
    this.selectedCommodity = [];
    this.profitCenterOptions = [];
    this.legalEntitiesOptions = [];
    this.counterPartyOptions = [];
    this.commodityOptions = [];
    this.allProfitCenter = [];
    this.allLegalEntities = [];
    this.allCounterParty = [];
    this.allCommodity = [];
    this.unselectedPC = [];
    this.unselectedLE = [];
    this.unselectedCP = [];
    this.unselectedCD = [];
    this.listPC = [];
    this.listLE = [];
    this.listCP = [];
    this.listCD = [];
  }

  ngOnChanges(changes: SimpleChanges): void {       //Triggers when 'display' changes
    if (changes['display']) {
      if (this.display === true) {
        this.getAll();
      } else {
        this.refreshLists();
      }
    }
  }

  OnFormClose($event: any, addButtonValue: any) {

  }

  hideBtns(index) {
    this.activeIndex = index;
    if (index != 0) {
      this.isBtnsVisible = true;
    } else {
      this.isBtnsVisible = false;
    }
    if (this.lastActiveIndex !== 0) {
      this.showConfirmdialog(this.lastActiveIndex);
    }
    this.lastActiveIndex = index;
  }

  compareList(selected: any[], original: any[]) {
    let isEqual: boolean = true;
    let originalCode: any[] = [];
    if (selected.length !== original.length) {
      isEqual = false;
    } else {
      original.forEach(function(obj) {
        originalCode.push(obj['code']);
      });
      selected.forEach(function(selectedObject: any) {
        if (!originalCode.includes(selectedObject['code'])) {
          isEqual = false;
        }
      });
    }
    return isEqual;
  }


  showConfirmdialog(lastIndex) {
    let _this = this;
    let screenName = '';
    let isEqual: boolean = false;
    if (lastIndex === 1) {
      screenName = 'Legal Entity';
      isEqual = this.compareList([...this.selectedLegalEntities], [...this.originalLegalEnities]);
    } else if (lastIndex === 2) {
      screenName = 'Profit Center';
      isEqual = this.compareList([...this.selectedProfitCenters], [...this.originalProfitCenter]);
    } else if (lastIndex === 3) {
      screenName = 'Counterparty';
      isEqual = this.compareList([...this.selectedCounterParty], [...this.originalCounterparty]);
    } else if (lastIndex === 4) {
      screenName = 'Commodity';
      isEqual = this.compareList([...this.selectedCommodity], [...this.originalCommodity]);
    }
    if (!isEqual) {
      this.confirmationService.confirm({
        message: this.commonService.getMailMessage(messages.continueMessageUserInfo.message, [screenName]),
        accept: () => {
          _this.revert(lastIndex);
        },
        reject: () => {
          _this.activeIndex = lastIndex;
          _this.lastActiveIndex = _this.activeIndex;
        }
      });
    }
  }

  revert(lastIndex) {
    if (lastIndex === 1) {
      this.selectedLegalEntities = [];
      this.selectedLegalEntities = [...this.originalLegalEnities];
    } else if (lastIndex === 2) {
      this.selectedProfitCenters = [];
      this.selectedProfitCenters = [...this.originalProfitCenter];
    } else if (lastIndex === 3) {
      this.selectedCounterParty = [];
      this.selectedCounterParty = [...this.originalCounterparty];
    } else if (lastIndex === 4) {
      this.selectedCommodity = [];
      this.selectedCommodity = [...this.originalCommodity];
    }
  }

  getMaxHeight(id: string) {
    return '100px'
  }

  onInfoUpdate(value) {
    this.onUserInfoUpdated.emit(value);
    this.close();
  }

  checkForChanges(isSaving: boolean = false) {
    let _this = this;
    let objectList: any[] = [];
    let isEqualLE = this.compareList([...this.selectedLegalEntities], [...this.originalLegalEnities]);
    let isEqualPC = this.compareList([...this.selectedProfitCenters], [...this.originalProfitCenter]);
    let isEqualCP = this.compareList([...this.selectedCounterParty], [...this.originalCounterparty]);
    let isEqualCM = this.compareList([...this.selectedCommodity], [...this.originalCommodity]);
    if (isEqualLE && isEqualPC && isEqualCP && isEqualCM) {
      this.close();
    } else {
      if (!isSaving) {
        let listName = '';
        if (!isEqualLE) {
          objectList.push(_this.getObject(_this.selectedLegalEntities, 'Legal Entity'));
          listName += 'Legal Entity';
        }
        if (!isEqualPC) {
          objectList.push(_this.getObject(_this.selectedProfitCenters, 'Profit Center'));
          if (listName.length > 0) {
            listName += ', ';
          }
          listName += 'Profit Center';
        }
        if (!isEqualCP) {
          objectList.push(_this.getObject(_this.selectedCounterParty, 'Counterparty'));
          if (listName.length > 0) {
            listName += ', ';
          }
          listName += 'Counterparty';
        }
        if (!isEqualCM) {
          objectList.push(_this.getObject(_this.selectedCommodity, 'Commodity'));
          if (listName.length > 0) {
            listName += ', ';
          }
          listName += 'Commodity';
        }
        this.confirmationService.confirm({
          message: this.commonService.getMailMessage(messages.continueMessageUserInfo.message, [listName]),
          accept: () => {
            _this.close();
          },
          reject: () => {
            _this.display = true;
          }
        });
      } else {
        this.isLoading = true;
        if (!isEqualLE) {
          objectList.push(_this.getObject(this.selectedLegalEntities, 'Legal Entity'));
        }
        if (!isEqualPC) {
          objectList.push(_this.getObject(this.selectedProfitCenters, 'Profit Center'));
        }
        if (!isEqualCP) {
          objectList.push(_this.getObject(this.selectedCounterParty, 'Counterparty'));
        }
        if (!isEqualCM) {
          objectList.push(_this.getObject(this.selectedCommodity, 'Commodity'));
        }
        _this.commonService.post(environment.base_url_new + '/api-iam/api/usermanagementservice/v1/saveUserInfo', objectList).subscribe((next) => {
          _this.isLoading = false;
          if (!isEqualLE) {
            _this.updateOriginal('Legal Entity', _this.selectedLegalEntities);
          }
          if (!isEqualPC) {
            _this.updateOriginal('Profit Center', _this.selectedProfitCenters);
          }
          if (!isEqualCP) {
            _this.updateOriginal('Counterparty', _this.selectedCounterParty);
          }
          if (!isEqualCM) {
            _this.updateOriginal('Commodity', _this.selectedCommodity);
          }
        })
      }
    }
  }

  @HostListener("window:resize", [])
  public onResize() {
    this.calculateHeight();
  }

  calculateHeight() {
    this.contentHeight = ((window['visualViewport'].height - document.getElementById('container').offsetTop) - 140) + 'px';
  }

  getUserInfo(userId: string) {
    let _this = this;
    _this.selectedLegalEntities = [];
    _this.selectedProfitCenters = [];
    _this.selectedCommodity = [];
    _this.selectedCounterParty = [];
    _this.originalLegalEnities = [];
    _this.originalProfitCenter = [];
    _this.originalCommodity = [];
    _this.originalCounterparty = [];
    this.commonService.getJSONByURL(environment.base_url_new + '/api-iam/api/usermanagementservice/v1/getbyuserinfobyid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&userId=' + userId).subscribe((data: any) => {
      _this.selectedLegalEntities = data['company'];
      _this.selectedProfitCenters = data['profitcenter'];
      _this.selectedCommodity = data['commodity'];
      _this.selectedCounterParty = data['counterparty'];
      _this.originalLegalEnities = data['company'];
      _this.originalProfitCenter = data['profitcenter'];
      _this.originalCommodity = data['commodity'];
      _this.originalCounterparty = data['counterparty'];
    });
  }

  getUserData() {
    return environment.base_url_new + '/api-iam/api/usermanagementservice/v1/getbyuuid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&uuid=' + this.data.uuid;
  }

  opendailog() {
    this.displayaudit = !this.displayaudit;
  }

  closeaudittrail() {
    this.displayaudit = false;
  }
}
