import {Component, Input, OnInit} from '@angular/core';
import {CommonService} from "../../services/common.service";
import {Grid} from "../../grid/grid.model";
import {environment} from "../../../environments/environment";
import {Tcolumn} from "../../grid/tcolumn.model";
import {defaultValue} from "../../grid/defaultValue.model";
import {Validators} from "@angular/forms";
import {MasterService} from "../services/MasterService";
import {DatePipe} from "@angular/common";
import {MessageService} from "primeng";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-external-packaging',
  templateUrl: './external-packaging.component.html',
  styleUrls: ['./external-packaging.component.css']
})
export class ExternalPackagingComponent implements OnInit {

  columns: Map<string, Tcolumn>;
  grid: Grid;
  refreshGrid: boolean = false;
  toolbarConfig: any = {
    add: false,
    edit: true,
    copy: false,
    refresh: true,
    export: false,
    exportAll: false,
    import: false,
    audit: true,
    print: true,
    prefrences: true
  };
  //SideBar
  editScreenDisplay: boolean = false;
  tableheight: string = '';
  pageDisable: boolean = true;
  @Input() canEdit:boolean = true;
  @Input() selectedRowData: any;
  name: any;
  dimension: any;
  length: any;
  lengthUom: any;
  width: any;
  widthUom: any;
  height: any;
  heightUom: any;
  externalIdentifier: any;
  status: any;
  isLoading: boolean = false;
  createdBy: any;
  createdTimestamp: any;
  updatedBy: any;
  updatedTimestamp: any;
  tenantId: any;
  uuid: any;
  datePipe: DatePipe;

    constructor(public  commonService: CommonService, public masterCommonService: MasterService, public messageService: MessageService) {
  }

  ngOnInit(): void {
    this.getColumns();
    this.grid = new Grid(this.columns, true, true, true, true, 'name', '/api/externalpackage/v1/getall?tenantId=' + this.commonService.getFromStorage('tenantId'), '', 'External Package');
    this.grid.iconName = 'priceIndex';
    this.grid.openSidebarOnUniqueClick = false;
    this.grid.setSaveUrl(environment.base_url + '/api/externalpackage/v1/save');
    this.grid.setGridConfiguration(false, false, false, false,false);
    this.datePipe = new DatePipe('en-US');
  }

  public getColumns() {

      let dimensionValue = function(value:any[]){
        return value[0] + ' ' + value[1] + ' * ' + value[2] + ' ' + value[3] + ' * ' + value[4] + ' ' + value[5];
      };

    this.columns = new Map();
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('name', new Tcolumn('name', 'External Package Name', 'T', 2, true, undefined, true, new defaultValue('', '', ['-', '_', '/'], undefined, false, true, false, undefined, undefined, true), 'B', [Validators.required, Validators.maxLength(100)], undefined, undefined, undefined, undefined, [], undefined, undefined, true));
    this.columns.set('dimension', new Tcolumn('dimension', 'Dimension ( l * b * h )', 'T', 3, true, undefined, true, new defaultValue('',undefined,[],undefined,false,true,false,dimensionValue,['length','lengthUom','width','widthUom','height','heightUom']), 'B', [Validators.maxLength(200)]));
    this.columns.set('externalIdentifier', new Tcolumn('externalIdentifier', 'External Identifier', 'T', 6, true, undefined, true, '', 'B', []));
    this.columns.set('status', new Tcolumn('status', 'Status', 'B', 7, true,  this.masterCommonService.getActiveInactive(), true, '', 'B', []));
    this.columns.set('length', new Tcolumn('length', 'Length', 'LB', 8, true, undefined, false, '', 'H', []));
    this.columns.set('lengthUom', new Tcolumn('lengthUom', 'lenghtUom', 'LB', 9, true, undefined, false, '', 'H', []));
    this.columns.set('width', new Tcolumn('width', 'width', 'LB', 10, true, undefined, false, '', 'H', []));
    this.columns.set('widthUom', new Tcolumn('widthUom', 'widthUom', 'LB', 11, true, undefined, false, '', 'H', []));
    this.columns.set('height', new Tcolumn('height', 'height', 'LB', 12, true, undefined, false, '', 'H', []));
    this.columns.set('heightUom', new Tcolumn('heightUom', 'heightUom', 'LB', 13, true, undefined, false, '', 'H', []));
  }



  onClickUnique(value: any) {
    this.selectedRowData = value['rowData'];
    this.length = value['rowData']['length'];
    this.lengthUom = value['rowData']['lengthUom'];
    this.width = value['rowData']['width'];
    this.widthUom = value['rowData']['widthUom'];
    this.height = value['rowData']['height'];
    this.heightUom = value['rowData']['heightUom'];
    this.externalIdentifier = value['rowData']['externalIdentifier'];
    this.status = value['rowData']['status'];
    this.createdBy = value['rowData']['createdBy'];
    this.createdTimestamp = value['rowData']['createdTimestamp'];
    this.updatedBy = value['rowData']['updatedBy'];
    this.updatedTimestamp = value['rowData']['updatedTimestamp'];
    this.editScreenDisplay = true;
    this.refreshGrid = false;
    this.commonService.getJSONByURL(environment.base_url + '/api/externalpackage/v1/getbyuuid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&uuid=' + value['rowData']['uuid']).subscribe((next: any) => {
      this.selectedRowData = next;
      this.updateUserInfo(this.createdBy, this.createdTimestamp, 'create');
      this.updateUserInfo(this.updatedBy, this.updatedTimestamp, 'update');
    })
  }

  onClickView(value: any) {
    this.selectedRowData = value['rowData'];
    this.editScreenDisplay = true;
    this.refreshGrid = false;
  }

  close() {
    this.editScreenDisplay = false;
    this.pageDisable = true;
    this.refreshGrid = true;
  }

  onClickEdit() {
    this.pageDisable = !this.pageDisable;
  }

  UpdateUom() {
      let externalpayload = {
        name: this.selectedRowData['name'],
        length: this.selectedRowData['length'],
        lengthUom: this.selectedRowData['lengthUom'],
        width: this.selectedRowData['width'],
        widthUom: this.selectedRowData['widthUom'],
        height: this.selectedRowData['height'],
        heightUom: this.selectedRowData['heightUom'],
        externalIdentifier: this.externalIdentifier,
        status: this.status,
        createdBy: this.selectedRowData['createdBy'],
        createdTimestamp: this.selectedRowData['createdTimestamp'],
        updatedBy: this.selectedRowData['updatedBy'],
        updatedTimestamp: this.selectedRowData['updatedTimestamp'],
        tenantId: this.commonService.getFromStorage('tenantId'),
        uuid: this.selectedRowData['uuid']
      };
    this.commonService.post(environment.base_url + '/api/externalpackage/v1/update', externalpayload).subscribe((next: any) => {
        this.editScreenDisplay = false;
        this.pageDisable = true;
        this.isLoading = false;
        this.refreshGrid = true;
        this.showToast('Row updated Successfully');
      }, (error: HttpErrorResponse) => {
        this.messageService.add({severity: 'error', summary: error.error});
      });
  }

  showToast(msg, severity: string = 'success') {
    this.messageService.add({
      severity: 'info',
      summary: msg
    });
  }

  updateUserInfo(id, date, mode = 'create') {
    if (id !== null) {
      let _this = this;
      this.commonService.getJSONByURL(environment.base_url_new + '/api-iam/api/usermanagementservice/v1/getbyuserid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&userId=' + id).subscribe((next: any[]) => {
        if (next !== undefined && next !== null) {

          if (mode === 'create') {
            _this.createdBy = next['value'];
            _this.createdTimestamp = _this.datePipe.transform(date, environment.full_date_format);
          } else {
            _this.updatedBy = next['value'];
            _this.updatedTimestamp = _this.datePipe.transform(date, environment.full_date_format);
          }
        }
      });
    }
  }
}


