

    <ctrm-grid (onClickUniqueKey)="onUniqueClick($event)"
               [refreshGrid]="refreshGrid"
               [breadcrumbMapKey]="commonService.OPERATION_MAP_KEY"
               [currentRoute]="'/tradeActualization'"
               [grid]="grid"
               [refreshFilter]="refreshFilter"
               [customViewTemplated]="[matchTypeTemplate,priceAllocationStatusTemplate]"
               [fieldsWithCustomView]="['matchType','priceAllocationStatus']"
               [gridFilterDropdownList]="gridFilters"
               [selectedRow]="selectedRows"
               (selectedRows)="onChildSelected($event,selectedRowData)"
               [showAddButtonInNoData]="false"
               (onDropdownFilterValueChange)="onFilterChange($event)"
               (onRefreshComplete)="onRefreshGridComplete()"
               (onClickedRefresh)="planRefresh()"
               [autoCalculateGridWidth]="false"
               [autoLayout]="true"
               [onApplyAdvancedFiltervalue]=""
               [headerWidth]="'130px'"
               [showFilterChips]="false"
               [openFormForNewEntry]="false"
               [showSortIcon]="false"
               [showStandardFilter]="true">
    </ctrm-grid>

<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>
<p-confirmDialog [baseZIndex]="25" header="Confirmation"
                 icon="pi pi-exclamation-triangle"></p-confirmDialog>
<ctrm-input-form (OnApply)="onApply($event,currentGrid.getTitle())"
                 (OnCancel)="onCancel()"
                 *ngIf="addNew"
                 [baseZIndex]="30"
                 [closeAfterSave]="true"
                 [columnsMap]="currentGrid.getColumn()"
                 [dontSaveMode]="true"
                 [gridConfig]="currentGrid.getGridConfig()"
                 [gridTitle]="currentGrid.getTitle()"
                 [heading]="currentGrid.getTitle()"
                 [noOfColumns]="3"
                 [readOnly]="true"
                 [saveUrl]="currentGrid.getSaveUrl()" [sections]="currentGrid.getSections()"
                 [showOnModal]="true"
                 [uniqueKey]="currentGrid.getPrimaryKey()">
</ctrm-input-form>
<app-preference (changeDisplay)="changeDisplay($event)" (onSave)="reloadData($event,currentTable)" *ngIf="displayPrefrences"
                [columns]="currentGrid.getColumn()" [display]="displayPrefrences"
                [gridFilters]="currentGrid.getFilters()"
                [headerColumns]="masterCommonService.getGridField(currentGrid)"
                [primaryKey]="currentGrid.getPrimaryKey()"
                [showColumns]="false"></app-preference>
<doc-viewer (OnClose)="onDocClose($event)" [docUrl]="docUrl" [fileName]="docName" [modalState]="showDoc"></doc-viewer>
<warning-box (onClose)="onCloseWarning()" (onContinue)="onContinue()" [continueButtonHeader]="'Continue Actualization'"
             [errorMessages]="errorMessage" [heading]="'Quantity Actualization'" [visible]="warningVisible"
             [warningMessages]="warningMessage"></warning-box>
<app-trade-info (onClose)="onCloseTradeInfo($event)" *ngIf="tradeInfoDisplay" [detailsOf]="'trade'" [display]="true"
                [idValue]="plannedObligationId" [rowData]="oblRow" [sidebarPosition]="'right'"
                [tradeId]="selctedTradeId"></app-trade-info>
<app-bl-management *ngIf="bldisplay" [display]="bldisplay" [data]="blOutput" (onClose)="onCloseBlForm()"></app-bl-management>
<xceler-bottom-pane *ngIf="showBottomPane" [popupDisplay]="showBottomPane" (onClose)="onClosebottomPane()" [rightViewTemplate]="right"></xceler-bottom-pane>
<ng-template #right>
  <button (click)="actualizeFormOpen()" class="planScreenBtns" [disabled]="!actualizeDisabled"
          style="margin: 0; margin-right: 15px; width: 100px !important;color: white !important;">Actualize</button>
  <button (click)="deActualize()" [disabled]="checksettel() && !deactualizeDisabled" class="planScreenBtns" style="margin: 0; margin-right: 15px; width: 100px !important;color: white !important;">De-Actualize</button>
</ng-template>
<actualization-form *ngIf="displayForm" [mode]="formMode" [obligationId]="planedObligationId"
                    [planId]="selectedRowDataObj['planId']"
                    [claimAccess]="claimDisabled"
                    [undoClaimAccess]="undoClaimDisabled"
                    [dialogDisplay]="displayForm"
                    (onClose)="onCloseForm()"></actualization-form>


<!--Price allocation Template-->
<ng-template #priceAllocationStatusTemplate let-rowData="rowData">
  <ctrm-hover-icon [buttonNameList]="[[],
                                       ['Request for Trade Pricing'],['Price Allocation']
                                       ,['See All PriceAllocation Details'],['Price Allocation']]"
                   [iconNameList]="['Fixed Priced','Not Priced','Not Allocated','Fully Allocated','Partially Allocated']"
                   [iconList]="['Fixed Price.svg','reddollar.svg','reddollar.svg','greendollar.svg','yellow.svg']"
                   [templateList]="[null,notPriced,notAllocated,partiallyAllocated,partiallyAllocated]"
                   [selectedIconName]="'Fixed Priced'"
                   [iconDecidingFunction]="priceAllocationiconPath"
                   [header]="'Trade Pricing Details'"
                   (onHover)="onHover($event)"
                   (onButtonClick)="onButtonClicks($event)"
                   [loadApiFunction]="tradePricingLoadApiFunction"
                   [hideForIcons]="['Fixed Priced']"
                   [rowData]="rowData" ></ctrm-hover-icon>
</ng-template>

<ng-template #notPriced>
  <div>
    <div  style="background-color: #F0F0F0;padding-left: 41px;padding-right: 4px;padding-top:21px;padding-bottom: 15px;">
      <i class="fa fa-warning" style="font-size:17px;">Trade Pricing and Price Allocation Not Available</i>
    </div>
  </div>
</ng-template >

<ng-template #notAllocated let-dbResponse="dbResponse">
  <div>
    <div class="priceAdding">
      <div class="fieldBox">
        <p class="fieldName">Priced Quantity</p>
        <p class="bodyContent"> {{commonService.getFormatedNumber(dbResponse[0]['pricedQuantity'])+' '+dbResponse[0]['priceUomName']}}</p>
      </div>
      <div class="fieldBox">
        <p class="fieldName">Average Pirce</p>
        <p class="bodyContent"> {{commonService.getFormatedNumber(dbResponse[0]['averagePrice']) + ' ' + dbResponse[0]['priceCurrency'] + '/' + dbResponse[0]['priceUomName']}}</p>
      </div>
      <div class="fieldBox">
        <p class="fieldName">Pricing Status</p>
        <p class="bodyContent"> {{dbResponse[0]['priceStatus']}}</p>
      </div>

    </div>
  </div>
  <hr>
  <p style=" color: #15104E;  font-weight: 600; margin-left: 10px;">Price Allocation</p>
  <div>
    <div  style="background-color: #F0F0F0;padding-left: 41px;padding-right: 4px;padding-top:21px;padding-bottom: 15px; margin: 10px">
      <i class="fa fa-warning" style="font-size:17px;"> Price Allocation Details Not Available</i>
    </div>
  </div>

</ng-template >

<ng-template #partiallyAllocated let-dbResponse="dbResponse" let-rowData="rowData">
  <div>
    <div class="priceAdding">
      <div class="fieldBox">
        <p class="fieldName">Priced Quantity</p>
        <p class="bodyContent"> {{commonService.getFormatedNumber(dbResponse[0]['pricedQuantity'])+' '+dbResponse[0]['priceUomName']}}</p>
      </div>
      <div class="fieldBox">
        <p class="fieldName">Average Pirce</p>
        <p class="bodyContent"> {{commonService.getFormatedNumber(dbResponse[0]['averagePrice']) + ' ' + dbResponse[0]['priceCurrency'] + '/' + dbResponse[0]['priceUomName']}}</p>
      </div>
      <div class="fieldBox">
        <p class="fieldName">Pricing Status</p>
        <p class="bodyContent"> {{dbResponse[0]['priceStatus']}}</p>
      </div>

    </div>
  </div>
  <hr>
  <p style=" color: #15104E;  font-weight: 600; margin-left: 10px;">Price Allocation</p>
  <div>
    <div class="priceAdding">
      <div class="fieldBox">
        <p class="fieldName"> Quantity</p>
        <p class="bodyContent"> {{commonService.getFormatedNumber(dbResponse[0]['allocationQunaity'])+' '+dbResponse[0]['priceUomName']}}</p>
      </div>
      <div class="fieldBox">
        <p class="fieldName"> Price</p>
        <p class="bodyContent"> {{commonService.getFormatedNumber(dbResponse[0]['allocationPrice']) + ' ' + dbResponse[0]['priceCurrency'] + '/' + dbResponse[0]['priceUomName']}}</p>
      </div>
      <div class="fieldBox">
        <p class="fieldName"> Status</p>
        <p class="bodyContent"> {{rowData['priceAllocationstatus']}}</p>
      </div>

    </div>
  </div>
</ng-template >

<ng-template #matchTypeTemplate let-rowData='rowData'>
  <p class="matchAlgoStyle">
    {{rowData['matchType']}}
  </p>
</ng-template>



