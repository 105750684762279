<!--<div *ngIf="grid.gridFilterURL !== null && grid.gridFilterURL !== undefined" class="prefrenceWrapper">-->

<div class="sectionCardTop">
    <div class="breadcrumbPane">
        <ctrm-breadcrumb [collapsabel]="false"
                         [items]="breadCrumbService.getBreadCrumb()"
                         [showIcon]="false"></ctrm-breadcrumb>
    </div>
    <div class="actionPane">
        <div class="screenDetails">
            <img *ngIf="!showBack()" [src]="commonService.getIconPath(grid.iconName)"/>
            <i (click)="changeRouteToBack()" *ngIf="showBack()" class="fas fa-chevron-left backIcon"></i>
            <p>{{title}}</p>
        </div>

        <div class="prefrenceWrapper">
            <div>
                <p-dropdown
                        [options]="preferenceList" [(ngModel)]="selectedPreference" (ngModelChange)="onPrefSelection($event)"
                        appendTo="body">
                </p-dropdown>
            </div>
            <div style="margin-left: 10px">
                <p-multiSelect [options]="columnsList"
                               optionLabel="label" (ngModelChange)="onPrefColChange($event, true)" [defaultLabel]="'Columns'" [(ngModel)]="selectedPrefCols"></p-multiSelect>
            </div>

            <div style="margin-left: 10px">
                <div (click)="toggleFilterPanel($event)" class="filterBtnWrapper">
                    <i class="fas fa-filter funnelIcon"></i>
                    <p class="filterBtnText">Advanced Filter</p>
                </div>
            </div>

            <div style="margin-left: 10px">
                <button class="prefSaveBtn" (click)="openSavePrefDialog()"
                        type="button">Save View</button>
            </div>

            <div style="margin-left: 10px; display: flex; margin-top: 5px">
                <p-checkbox (onChange)="onMakeDefaultChange($event)"
                            style="display: inline-flex;width: 170px"
                            [(ngModel)]="prefDefault" [binary]="true" [label]="'Mark as Default view'"></p-checkbox>
            </div>

            <!--    Filter section dropdown div-->

            <div (click)="$event.stopPropagation()">
                <div class="filterSecDiv" *ngIf="filterPanelDisplay">
                    <div class="advacedFilterWrapper">
                        <div style="display: inline-flex">
                        <div class="filterSecWrapper">
                            <div>
                                <div>
                                    <p-listbox style="max-height: 35vh;!important"[options]="filterColumnList" (onChange)="addfilter($event)"  optionLabel="label" [(ngModel)]="selectedFilterCol"></p-listbox>
                                </div>
                            </div>
                        </div>
                            <div class="filterConfigBoxOne">
                                <div class="div">
                                    <div>
                                        <p class="fontStylingBasic">Filter Rule Configuration</p>
                                            <div style="display:inline-flex;">
                                                <div *ngIf="filterActionList.length === 0">
                                                    <div style="padding: 16px;padding-left: 40px;padding-bottom: 32px;">
                                                        <div style="padding-left: 180px;">
                                                            <i class="far fa-check-square"></i>
                                                        </div>
                                                        <p style="font-size: 12px; text-align: center; margin-right:20px !important;">Please select the filter trigger from the left library to view and configure rule</p>
                                                    </div>
                                                 </div>
                                                <div *ngIf="filterActionList.length !== 0">
                                                    <div>
                                                        <div *ngFor="let key of filterActionList">
                                                            <p-radioButton [inputId]="key.key" name="key" (ngModelChange)="updateOptions($event)"  [value]="key" [(ngModel)]="selectedFilterAction"></p-radioButton>
                                                            <label  style="padding-left: 5px;" [for]="key.key">{{key.label}}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="selectedFilterAction.length !== 0 && selectedFilterCol.length !==0">
                                                    <div style="display:inline-flex">
                                                    <div class="div" *ngIf="filterList[0] !== undefined && filterList[0] !== null &&
                                                            (filterList[0]['type'] === 'T' || filterList[0]['type'] === 'TA' || filterList[0]['type'] === 'LB'|| filterList[0]['type'] === 'L' || filterList[0]['type'] === 'B')" style="padding: 5px">
                                                            <ctrm-input (onChangeValue)="addTextOption($event)" class="contentMargin"></ctrm-input>
                                                    </div>
                                                    <div class="div" style="width: 215px;margin-left: 40px;  margin-bottom: 50px;"*ngIf="filterList[0] !== undefined && filterList[0] !== null && filterList[0]['type'] === 'OB'" class="dropdown">
                                                        <ctrm-dropdown (onChangeValue)="addListOption($event)" [addSelectOption]="false"
                                                                            [field]="filterDropDown"
                                                                            [selectedValue]="filterList[0].getCondition()"></ctrm-dropdown>
                                                    </div>
                                                    <div class="div" *ngIf="filterList[0] !== undefined && filterList[0] !== null && filterList[0]['type'] === 'N'" class="contentBox">
                                                        <ctrm-input (onChangeValue)="addNumberOption($event,true)" [defaultValue]="filterList[0]['firstValue']" [type]="'number'" class="contentMargin">
                                                        </ctrm-input>
                                                        <ctrm-input (onChangeValue)="addNumberOption($event,false)" *ngIf="isSecondRequired(filterList[0])" [defaultValue]="filterList[0]['secondValue']" [type]="'number'" class="contentMargin">
                                                        </ctrm-input>
                                                    </div>
                                                    <div class="div" *ngIf="filterList[0] !== undefined && filterList[0] !== null && filterList[0]['type'] === 'D'" class="contentBox">
                                                        <input (bsValueChange)="addNumberOption($event,true)" [bsConfig]="{dateInputFormat : getDateFormat(filterList[0]['field'])}"
                                                                [maxDate]="isValidDate(filterList[0]['field'].getMaxDate())?filterList[0]['field'].getMaxDate():''"
                                                                [minDate]="isValidDate(filterList[0]['field'].getMinDate())?filterList[0]['field'].getMinDate():''"
                                                                [minMode]="filterList[0]['field'].getFromExtra('date')['view']"
                                                                bsDatepicker
                                                                class="form-control"
                                                                placeholder="select date" type="text">
                                                        <input (bsValueChange)="addNumberOption($event, false)" *ngIf="isSecondRequired(filterList[0])"
                                                                [bsConfig]="{dateInputFormat : getDateFormat(filterList[0]['field'])}"
                                                                [maxDate]="isValidDate(filterList[0]['field'].getMaxDate())?filterList[0]['field'].getMaxDate():''"
                                                                [minDate]="isValidDate(filterList[0]['field'].getMinDate())?filterList[0]['field'].getMinDate():''"
                                                                [minMode]="filterList[0]['field'].getFromExtra('date')['view']"
                                                                bsDatepicker
                                                                class="form-control"
                                                                placeholder="select date"
                                                                style="margin-left: 6px" type="text">
                                                    </div>

                                                </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="selectedFilterAction.length !== 0 && selectedFilterCol.length !==0">
                                <button (click)="addFieldValue()" class="applyFilterAddRulesButton">+ Add to List</button>
                            </div>
                            <div *ngIf="selectedFilterAction.length === 0 || selectedFilterCol.length ===0">
                                <button [disabled] class="applyFilterAddRulesButton">+ Add to List</button>
                            </div>
                            <div class="filterConfigBoxTwo div">
                                <div>
                                    <p class="fontStylingBasic">Configured Filter List</p>
                                    <div style="display: inline-flex">
                                        <div *ngIf="filterActionList.length === 0 && filterConfigarationList.length === 0">
                                            <div>
                                                <div style="padding-left: 80%;">
                                                    <i class="far fa-check-square"></i>
                                                </div>
                                                <p style="font-size: 12px;padding-left: 180px; padding-right: 9px; background-color: #fff;padding-bottom: 86px; text-align: center;">No filter configured</p>
                                            </div>
                                        </div>
                                        <div *ngIf="filterActionList.length !== 0 && filterConfigarationList.length === 0">
                                            <div>
                                                <div style="padding-left: 80%;">
                                                    <i class="far fa-check-square"></i>
                                                </div>
                                                <p style="font-size: 12px;padding-left: 180px; padding-right: 9px; background-color: #fff;padding-bottom: 86px; text-align: center;">No filter configured</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                                    <div class="filterConfigBoxFour div" *ngIf="filterActionList.length === 0 && filterConfigarationList.length !== 0 || filterConfigarationList.length>=0 || filterConfigarationList.length<=6">
                                        <div><p class="fontStylingBasic">Configured Filter List</p>
                                            <div style="height: 150px; width: 474px; overflow-y: scroll;">
                                                <table style="border: white!important;">
                                                    <tbody>
                                                    <tr *ngFor="let header of filterConfigarationList; index as idx">
                                                        <td style="padding-right: 20px;">{{header.header}}</td>
                                                        <td style="padding-right: 20px;">{{header.condition}}</td>
                                                        <td style="padding-right: 20px;">{{getFormattedValue(header)}}</td>
                                                        <td>
                                                            <a (click)="deleteFieldValue(idx)" class="buttonClass" style="margin-bottom: 15px;margin-left: 130px;" >x</a>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                            <button (click)="onClose()" class="applyCloseButton">Close</button>
                            <button (click)="onApplyFilter()" class="applyFilterButton planScreenBtns customFilterBtn">Apply Filter</button>
                        </div>
                    </div>

                </div>
            </div>

            <!--    Dialog for preference Save Name input-->

            <p-dialog [(visible)]="preferenceSaveDialog"
                      [closable]="true"
                      [draggable]="false"
                      [modal]="true"
                      [maximizable]="false"
                      [showHeader]="false"
                      [style]="{ width: '30%',height: '15%',maxHeight:'15%', padding:'0px', background:'#ffffff'}">
                <div style="padding: 5px 10px">
                    <p style="font-size: 14px; font-weight: bold; color: #16144E">Save Preference</p>
                </div>
                <div style="padding: 20px">
                    <ctrm-input style="width: 480px;!important" [placeholder]="'Preference Name'" [defaultValue]="preferenceName" [(ngModel)]="preferenceName" (onChangeValue)="onPrefNameChange($event)"></ctrm-input>
                </div>
                <p-footer>
                    <button class="cancelBtn" (click)="preferenceSaveDialog = false">Cancel</button>
                    <button class="planScreenBtns" style="color: white" (click)="savePreferences()">Save</button>
                </p-footer>
            </p-dialog>
        </div>
        <div class="rightInfo">
            <p>Total {{totalElements}} {{title}}</p>
        </div>
    </div>

</div>
