<p-dialog #schedule [(visible)]="display" [baseZIndex]="25"
          [closeOnEscape]="false"
          [contentStyle]="{height: formHeight, overflow: 'scroll', overflowX:'hidden'}"
          [draggable]="false"
          [maximizable]="false" [modal]="true" [showHeader]="false" [baseZIndex]="10000"
          [style]="{width: '50%',padding:'0px',background:'#ffffff'}">
    <div class="dialogHeading">
        <div class="deliveryClass">
            <div style="display: inline-flex;">
                <p class="dialogLabel">Delivery Schedule</p>
                <div>
<!--                    <i *ngIf="showEdit" (click)="onClickEdit()" class="fa fa-edit displaysave"></i>-->
                    <div *ngIf="displaySave" style="margin: 5px;">
                        <button (click)="onSaveUnit()" class="priceBtn">Save</button>
                        <button (click)="onClickCancel()" class="cancelBtn">Cancel</button>
                    </div>
                </div>
            </div>
            <div class="formCloseIcon">
                <i (click)="onCancel()" class="material-icons closable" style="float: right;">clear</i>
            </div>
        </div>
    </div>
<!--  <div class="sectionCardTop">-->
        <div class="sectionBody style-4"  inViewport (inViewportAction)="calculateHeight()">
            <xceler-grid [columns]="columns" (onValueChange)="onValueChange($event)" [footerColumns]="footerColumns" [data]="value" [noDataTemplate]="defualtTemplateNoData"></xceler-grid>
        </div>
<!--    </div>-->
</p-dialog>

<ng-template #defualtTemplateNoData>
    <div class="noDataBox">
        <img [src]="commonService.getIconPath('emptyStateIcon')" class="iconClass"/>
        <p class="noDataHeader">No data available </p>
    </div>
</ng-template>

<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>
