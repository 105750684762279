import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {MessageService} from 'primeng';
import {Table} from 'primeng/table';
import {Filter} from '../../grid/preference/filter/filter.model';
import {Tcolumn} from 'src/app/grid/tcolumn.model';
import {CommonService} from '../../services/common.service';
import {FilterService} from '../../grid/preference/filter/filter.service';
import {environment} from '../../../environments/environment';
import {KeyValue} from '../../grid/key-value.model';

@Component({
  selector: 'sta-filter',
  templateUrl: './sta-filter.component.html',
  styleUrls: ['./sta-filter.component.css']
})
export class StaFilterComponent implements OnInit,OnChanges {
  @Input() moduleName:string = '';
  @Input() pTable:Table;
  @Input() columns:Tcolumn[] = [];
  @Input() hiddenFieldsToShowFromTcolumn:any[] =[];
  preferenceList: any[] =[];
  columnsList:any[]= [];
  filtercolumnsList:any[]= [];
  filters: Filter[] = [];
  @Input() defaultSelectedColumns:any[] =[];
  @Input() selectedPreference: any;
  @Input() columnListObject:any[] =[];
  @Input() hiddenFields:any[] =[]
  private originalColumnlist:Tcolumn[] = [];
  selectedPrefCols: any[] =[];
  defaultPrefrences:any[] = [];
  prefDefault: boolean = false;
  filterPanelDisplay: boolean = false;
  filterObject: Filter = null;
  selectedFilterCol: any = {};
  filterActionList: any[] = [];
  filterConfigarationList: any[] = [];
  appliedFilters:any[] =[];
  selectedFilterAction: any[] = [];
  mainFilterArray: any[] = [];
  preferenceSaveDialog: boolean = false;
  preferenceName: any = '';
  private colMap:Map<string,Tcolumn> = new Map<string, Tcolumn>();
  newdefault: boolean = false;
  @Output() onFilterApply = new EventEmitter();
  @Input() columnNameFix:Map<string,string>;
  @Input() preselectedFilter:any[] = [];
  constructor(public commonService:CommonService,private service: FilterService,private messageService: MessageService) { }

  ngOnInit(): void {
  }

  private isFixedNameAvaialable(key:string) {
    if(this.columnNameFix !== null && this.columnNameFix !== undefined && this.columnNameFix.has(key)) {
      return this.columnNameFix.get(key);
    }
    return key;
  }

  private loadColumns(columns:any[]) {
    let _this = this;
    if(this.hiddenFields === null || this.hiddenFields === undefined) {
      this.hiddenFields = [];
    }
    if(_this.defaultSelectedColumns === null || _this.defaultSelectedColumns === undefined || _this.defaultSelectedColumns.length === 0) {
      columns.forEach(function (col:Tcolumn) {
        if (!_this.service.exculedTypes.includes(col.getColumnType()) && col.isVisible()) {
          _this.defaultSelectedColumns.push(col.getField());
        }
      });
    }
    if(this.colMap === null || this.colMap === undefined || Object.keys(this.colMap).length === 0) {
      this.originalColumnlist = [];
      let defaultAvail:boolean = false;
      if(_this.defaultSelectedColumns !== null && _this.defaultSelectedColumns !== undefined && _this.defaultSelectedColumns.length > 0) {
        defaultAvail = true;
      }
      columns.forEach(function (col: Tcolumn) {
        if (!_this.service.exculedTypes.includes(col.getColumnType()) && col.isVisible()) {
          if(!_this.hiddenFieldsToShowFromTcolumn.includes(col.getField())) {
            _this.columnsList.push({label: col.getHeader(), value: col.getField()});
          }
          if(!_this.hiddenFields.includes(col.getField())) {
            _this.filtercolumnsList.push({label:col.getHeader(),value:col.getField()});
          }
          if(defaultAvail) {
            if(_this.defaultSelectedColumns.includes(col.getField())) {
              _this.defaultPrefrences.push({label: col.getHeader(), value: col.getField()});
            }
          } else {
            _this.defaultPrefrences.push({label: col.getHeader(), value: col.getField()});
          }
          _this.colMap.set(col.getField(),col);
        }
        _this.originalColumnlist.push(_this.covertJSONtoTcolumn(col));
      })
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    let _this = this;
    if(changes['moduleName']) {
      this.loadPrefrenceValues();
    }
    if(changes['columns']) {
      this.loadColumns(changes['columns'].currentValue);
    }
  }

  private loadPrefrenceValues() {
    let _this = this;
    this.selectedPreference = null;
    this.preferenceList= [];
    this.selectedPrefCols = [];
    this.commonService.getJSONByURL(environment.base_url_new + '/api-iam/api/userpreference/v1/getgriduserpreference?tenantId=' + this.commonService.getTenantId() +
        '&userId=' + this.commonService.getFromStorage('userid') + '&moduleName=' + this.moduleName).subscribe((next:any) => {
          if(next !== null && next !== undefined && next.length > 0) {
            next.forEach(function (result, index) {
              _this.preferenceList.push({
                label: result['preferencename'],
                value: result['preferencename'],
                pref: result
              });
              if (result['preferencedefault']) {
                _this.prefDefault = true;
                _this.selectedPreference = result['preferencename'];
                _this.onPrefSelection(_this.selectedPreference);
              }
            });
          } else {
              this.preferenceName = 'System';
              this.newdefault = true;
              this.selectedPrefCols = this.defaultPrefrences;
              //this.savePreferences(true,false,true);
          }
    },(error:HttpErrorResponse)=> {
          if(_this.commonService.getHttpErrorMessage(error,'Standard Filter') === 'No user preference found under Module: '+_this.moduleName) {
            this.preferenceName = 'System';
            this.newdefault = true;
            this.selectedPrefCols = this.defaultPrefrences;
          //  this.savePreferences(true,false,true);
          }
    });
  }

  onPrefSelection(value: any) {
    this.preferenceName = value;
    let obj = this.preferenceList.find(function (pref, index) {
      if (pref['pref']['preferencename'] == value)
        return true;
    });
    let JSONData = JSON.parse(obj['pref']['preferencejson']);
    if(this.preferenceName.toLowerCase() === 'system') {
      if(this.defaultSelectedColumns !== null && this.defaultSelectedColumns !== undefined && this.defaultSelectedColumns.length > 0) {
        let _this = this;
        let colList:any[] =[];
        this.columns.forEach(function (col: Tcolumn) {
          if(_this.defaultSelectedColumns.includes(col.getField())) {
            colList.push({label: col.getHeader(), value: col.getField()});
          }
        })
        JSONData.columns = colList;
      }
    }
    let columnsData: any[] = JSONData.columns;
    this.filterConfigarationList = JSONData['filters'];
    this.prefDefault = obj['pref']['preferencedefault'];
    this.updateSelectedPrefList(columnsData);
    this.loadTableWithSelctedPreset(columnsData);
    if(this.preselectedFilter !== undefined && this.preselectedFilter !== null && this.preselectedFilter.length >0) {
      this.filterConfigarationList = [...this.preselectedFilter];
      this.preselectedFilter.length = 0;
    }
    this.onApplyFilter();
  }

  private loadTableWithSelctedPreset(data:any[] =[]) {
    let _this = this;
    if(data !== null && data !== undefined && data.length > 0&& this.pTable !== null && this.pTable !== undefined) {
      if(_this.columnListObject !== null && _this.columnListObject !== undefined && _this.columnListObject.length > 0) {
        let columns:any[] = [];
        data.forEach(function (row:any) {
          columns.push(_this.columnListObject.find(function (pref, index) {
            if (_this.isFixedNameAvaialable(pref['field']) === row['value'])
              return true;
          }));
        })
        this.pTable.columns = columns;
      } else {
        let columns:Tcolumn[] = [];
        data.forEach(function (row:any) {
          if(!_this.hiddenFieldsToShowFromTcolumn.includes(row['value'])) {
            columns.push(_this.colMap.get(row['value']));
          }
        })
        this.pTable.columns = columns;
      }
    }
  }

  updateSelectedPrefList(column: any[]) {
    this.selectedPrefCols = column;
    // this.onPrefColChange(this.selectedPrefCols);
  }

  private covertJSONtoTcolumn(data: Tcolumn) {
    let json = JSON.parse(JSON.stringify(data));
    let copy: Tcolumn = new Tcolumn(json['field'], json['header'], json['columnType'], json['placeholder'], json['editable'], json['listvalues'],
        json['visible'], data.getDefaultValue(), json['visibilityArea'], data.getValidators(), json['valueType'], json['colSpan'], json['minDate'],
        json['maxDate'], json['tableMetadata'], data.getActionToDo(), json['extra'], json['isUnique']);
    return copy;
  }

  onPrefColChange(value: any, mouseEvent: boolean) {
    this.columns = [];
    let emitObj = {mouseEvent: mouseEvent}
    let _this = this;
    let gridCols:any[] = [];
    this.originalColumnlist.forEach(function (col:Tcolumn) {
      if (value.some(el => el.value === col.getField())) {
        _this.columns.push(col);
        gridCols.push({label:col.getHeader(),value:col.getField()});
      }
    });
    _this.columns.sort((a, b) => a['placeholder'] < b['placeholder'] ? -1 : a['placeholder'] > b['placeholder'] ? 1 : 0);
    emitObj['columns'] = _this.columns;
    this.loadTableWithSelctedPreset(gridCols);
  }

  toggleFilterPanel(event: MouseEvent) {
    event.stopPropagation();
    this.filterPanelDisplay = !this.filterPanelDisplay;
    this.filterObject = null;
    this.selectedFilterAction = [];
    this.selectedFilterCol = [];
  }

  openSavePrefDialog() {
    this.newdefault = this.prefDefault;
    this.preferenceSaveDialog = true;
  }

  savePreferences(reload:boolean = false,showNotification:boolean = true,makeDefault:boolean = false) {
    if (this.preferenceName.length > 0 && this.preferenceName.toLowerCase() !== 'system') {
      let postObj;
      let obj = {
        filters: this.mainFilterArray,
        columns: this.selectedPrefCols,
      }
      postObj = {
        tenantId: this.commonService.getTenantId(),
        moduleName: this.moduleName,
        preferencejson: JSON.stringify(obj),
        preferencename: this.preferenceName,
        preferencetype: 'grid',
        preferencedefault: makeDefault,
        status: true,
        userid: this.commonService.getFromStorage('userid')
      };
      this.commonService.post(environment.base_url_new + '/api-iam/api/userpreference/v1/saveuserpreference?tenantId=' + this.commonService.getTenantId(), postObj).subscribe(next => {
        if (showNotification) {
          this.messageService.add({severity: 'info', summary: 'Preference saved successfully'});
        }
        this.loadPrefrenceValues();
      }, error => {
        if (showNotification) {
          this.messageService.add({severity: 'error', summary: 'Failed to save preference.'});
        }
      });
    } else {
      if(this.preferenceName !== null && this.preferenceName !== undefined && this.preferenceName.toLowerCase() === 'system') {
        if(showNotification) {
          this.messageService.add({
            severity: 'error',
            summary: 'Cannot Save With Prefrence Name : System',
            detail: 'Please enter different preference name.'
          })
        }
      } else {
        if(showNotification) {
          this.messageService.add({
            severity: 'error',
            summary: 'Preference name is empty',
            detail: 'Please enter preference name.'
          })
        }
      }
    }
    this.preferenceSaveDialog = false;
  }

  onMakeDefaultChange() {
    this.commonService.getJSONByURL(environment.base_url_new + '/api-iam/api/userpreference/v1/makedefaultpreference?moduleName=' + this.moduleName
        + '&tenantId=' + this.commonService.getTenantId() + '&userId=' + this.commonService.getFromStorage('userid') + '&preferenceName=' + this.selectedPreference
        + '&makeDefault=' + this.prefDefault).subscribe((next:any) => {
      this.showToast("Default status Updated");
      this.loadPrefrenceValues();
    },error => {
      this.showToast(this.commonService.getHttpErrorMessage(error,this.moduleName),'error');
      this.prefDefault = false;
    });
  }


  showToast(msg, severity: string = 'success') {
    this.messageService.add({
      severity: 'info',
      summary: msg
    });
  }

  onPrefNameChange(value: any) {

  }

  onApplyFilter(FilterArryDB = []) {
    if (this.filterConfigarationList.length > 0) {
      this.fixDateValues(this.filterConfigarationList);
      this.mainFilterArray = JSON.parse(JSON.stringify(this.filterConfigarationList));
    } else {
      this.fixDateValues(FilterArryDB);
      this.mainFilterArray = JSON.parse(JSON.stringify(FilterArryDB));
    }
    let filters = JSON.parse(JSON.stringify(this.mainFilterArray));
    this.appliedFilters = [...this.filterConfigarationList];
    this.onFilterApply.emit(filters);
    this.filterObject = null;
    this.filterPanelDisplay = false;
    this.filterActionList = [];
  }

  getFormattedValue(value: any) {
    if (value['type'] === 'OB' || value['type'] === 'B') {
      let header = '';
      let list:any[] = this.colMap.get(value['columnName']).getListOfValues();
      if(list !== null && list !== undefined && list.length > 0) {
        list.forEach(function (keyval:KeyValue) {
          if(keyval.getValue() === value['firstValue']) {
            header = keyval.getLabel();
          }
        })
      }
      return header;
    } else if (value['type'] === 'D') {
      return this.commonService.getDateForOutput(value['firstValue']);
    } else {
      return value['firstValue'];
    }
  }

  addFieldValue() {
    let _this = this;
    if (this.filterObject['firstValue'] !== null && this.filterObject['firstValue'] !== undefined && this.filterObject['firstValue'] !== '') {
      if ((this.filterConfigarationList.length >= 0) && (this.filterConfigarationList.length <= 5)) {
        let found: boolean = false;
        this.filterConfigarationList.forEach(function(filter: Filter, index: number) {
          if (filter['columnName'] === _this.filterObject['columnName']) {
            _this.filterConfigarationList.splice(index, 1);
            _this.filterConfigarationList.splice(index, 0, _this.filterObject);
            found = true;
          }
        });
        if (!found) {
          _this.filterConfigarationList.push(_this.filterObject);
        }
      }
      this.selectedFilterCol = [];
      this.filterActionList = [];
      this.filterObject = null;
    }
  }

  deleteFieldValue(index: number) {
    if (index >= 0) {
      this.filterConfigarationList.splice(index, 1);
    }
  }

  private fixDateValues(filters: any[]) {
    let _this = this;
    filters.forEach(function(filter: any) {
      if (filter['type'] === 'D') {
        filter['firstValue'] = _this.commonService.convertDatetoUTC(filter['firstValue']);
      }
    });
  }

  onClose() {
    this.filterPanelDisplay = false;
    this.filterConfigarationList = JSON.parse(JSON.stringify(this.mainFilterArray));
    this.selectedFilterCol = [];
    this.filterActionList = [];
    this.filterObject = null;
  }

  addfilter(value: any) {
    this.updateFilterUI(value['value']['value'],value['value']);
  }

  private updateFilterUI(columnName:string,value) {
    let _this = this;
    this.filterObject = null;
    this.filterActionList = [];
    this.selectedFilterAction = [];
    let found = false;
    this.filterConfigarationList.forEach(function (filter:Filter,index:number) {
      if(filter['columnName'] === columnName) {
        _this.filterObject = new Filter(filter['header'],filter['columnName'],filter['condition'],filter['firstValue'],filter['secondValue'],filter['type'],_this.colMap.get(filter['columnName']));
        _this.selectedFilterAction = _this.service.getOptionObj(_this.filterObject.getCondition(),_this.filterObject.getType());
        found = true;
      }
    })
    if(!found || this.filterObject === null) {
      this.filterObject = this.service.getFilterObject(this.getValueObj(value));
    }
    this.filterActionList = this.service.getOptions(this.filterObject['type']);
  }

  getValueObj(field) {
    let obj = JSON.parse(JSON.stringify(field));
    obj['column'] = this.colMap.get(field['value']);
    return obj;
  }


  updateOptions(value: any) {
    this.filterObject.setCondition(value['value']);
  }

  addTextOption(value: any) {
    this.filterObject.setFirstValue(value);
  }

  addNumberOption(value: any, isFirst: boolean) {
    if (isFirst) {
      this.filterObject.setFirstValue(value);
    } else {
      this.filterObject.setSecondValue(value);
    }
  }

  isValidDate(value: any) {
    if (value === undefined) {
      return false;
    }
    return true;
  }

  getDateFormat(col: Tcolumn) {
    let format = environment.dateFormat;
    if (col.getFromExtra('date')['format'] !== undefined) {
      format = col.getFromExtra('date')['format'];
    }
    return format.toString().toUpperCase();
  }

  isSecondRequired(filter: Filter,condition:string = 'between') {
    return (filter.getCondition() === condition);
  }


  getListOfValues(filter: Filter) {
    if(filter !== null && filter !== undefined && filter.getField() !== null && filter.getField() !== undefined) {
      if(filter.getField().getListOfValues() !== null && filter.getField().getListOfValues() !== undefined && filter.getField().getListOfValues().length > 0) {
        let list:KeyValue[] = JSON.parse(JSON.stringify(filter.getField().getListOfValues()));
        if(list !== null && list !== undefined && list.length > 0) {
          if(list[0]['value'] !== '') {
            list.splice(0,0,new KeyValue());
          }
        }
        return list;
      }
    }
    return [new KeyValue()];
  }

  onChangeFromList(value: any) {
    this.filterObject.setFirstValue(value['value']);
  }

  getConditionTitle(header: any) {
    return this.service.getConditionLabel(header['condition'],header['type']);
  }

  selectRow(header: any) {
    this.selectedFilterCol = {label:header['header'],value:header['columnName']};
    this.updateFilterUI(header['columnName'],{label:header['header'],value:header['columnName']});
  }

  onClearfilter() {
    this.filterConfigarationList = [];
    this.filterPanelDisplay = false;
    this.selectedFilterCol = [];
    this.filterActionList = [];
    this.filterObject = null;
    this.onApplyFilter();
  }
}
