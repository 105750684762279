<div class="box">
  <div [style.minWidth]="minWidth" class="autocomplete-box">
    <input (focusin)="onFocusIn()" (focusout)="onFocusOut(inputvalue)" (keydown)="onKeyPressed($event)" (ngModelChange)="onChangevalue($event)"
           [(ngModel)]="inputvalue"
           autocomplete="off"
           id="{{id}}" placeholder="{{placeholder}}" tabindex="-1">
    <mat-icon *ngIf="!isFocused" style="color:#cbcbcb">search</mat-icon>
    <mat-icon (click)="clearAndClose()" *ngIf="isFocused" class="closable">clear</mat-icon>
  </div>
  <div #suggestionBox *ngIf="showSuggestion" class="suggestionBox style-1">
    <option (click)="update(suggestion)"
            *ngFor="let suggestion of currentList,index as i"
            [hidden]="no===0?!ishidden(suggestion):(suggestion['value']===''||suggestion['value']==='none')"
            [id]="i+'option'"
            [ngClass]="currentFocus===i?'active':''"
            class="option">{{no === 0 ? suggestion : suggestion['label']}}</option>
  </div>
</div>
