import { Component, OnInit } from '@angular/core';
import {Grid} from "../../grid/grid.model";
import {Tcolumn} from "../../grid/tcolumn.model";
import {FormGroup} from "@angular/forms";
import {CommonService} from "../../services/common.service";
import {MasterService} from "../../masters/services/MasterService";
import {MessageService} from "primeng";
import {defaultValue} from "../../grid/defaultValue.model";
import {environment} from "../../../environments/environment";
import {HttpErrorResponse} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {OverlayPanel} from "primeng/overlaypanel";
import {AccessPolicyService} from "../../services/accesspolicy.service";

@Component({
  selector: 'app-transport-actualization',
  templateUrl: './transport-actualization.component.html',
  styleUrls: ['./transport-actualization.component.css']
})
export class TransportActualizationComponent implements OnInit {
  refreshGrid: boolean = false;
  quantityRoundOff:number=3;
  priceRoundOff:number=2;
  refreshFilter: boolean = false;
  gridFilters: any = [];
  selectedRows: any[] =[];
  grid: Grid;
  columns: Map<string, Tcolumn>;
  vesselName:any[] = [];
  vesselId:any[] = [];
  loadPort:any[] = [];
  unloadPort:any[] = [];
  selectedVesselObj:any = {};
  totalElements: number = 0;
  tradePricingLoadApiFunction:Function;
  overlayPanelTemp:OverlayPanel;
  showFetching: boolean = true;
  formGroup: FormGroup;
  statObj:any=  {actualized:0,partiallyActualized:0,deliveryStarted:0};
  unplannedCardValues: any[] = [];
  toolbarConfig: any = {
    add: false,
    edit: false,
    copy: false,
    refresh: true,
    export: true,
    exportAll: true,
    import: false,
    audit: false,
    print: true,
    prefrences: true
  };
  toolbarConfigPlannedGrid: any = {
    add: false,
    edit: false,
    copy: false,
    refresh: true,
    export: true,
    exportAll: true,
    import: false,
    audit: false,
    print: true,
    prefrences: true
  };
  bottomPaneDisplay: boolean = false;
  display: boolean = false;
  gridHeight: any = '580px';

  mode: string = 'create';
  rowData: any = {};
    displayForm: boolean = false;
  formMode:string = '';
  selectedChildRow:any = null;
  selectedCashflowData: any[];
  actualizeDisabled:boolean=true;
  deactualizeDisabled:boolean=true;
  undoClaimAccess: boolean = false;
  claimAccess: boolean = false;

  constructor(public commonService:CommonService,private masterCommonService:MasterService,
              private messageService: MessageService, private accessPolicyService : AccessPolicyService,
              private route: ActivatedRoute,private router:Router) { }

  ngOnInit(): void {
    let _this = this;
    let tenantConfig=JSON.parse(_this.commonService.getFromStorage("tenantConfig"));
    if(tenantConfig && tenantConfig.roundingFormat){
      _this.quantityRoundOff=tenantConfig.roundingFormat.quantityRounding;
      _this.priceRoundOff=tenantConfig.roundingFormat.priceRounding;
    }
    else{
      _this.quantityRoundOff=3;
      _this.priceRoundOff=2;
    }

    this.actualizeDisabled = this.accessPolicyService.canAccessWithCurrentRoute('Action','allocate')
    this.deactualizeDisabled= this.accessPolicyService.canAccessWithCurrentRoute('Action','deallocate')
    this.calculateHeight();
    this.getColumns();
    this.grid = new Grid(this.columns, true, true, true, true, 'vesselId', '/api/transportallocation/v2/transport-allocation-for-actualization?tenantId='+this.commonService.getFromStorage('tenantId'), undefined, 'Transport Actualization', undefined, undefined);
    this.grid.setGridConfiguration(false, false, false, false, false, false, true, true, false, false);
    this.grid.openSidebarOnUniqueClick = false;
    this.grid.addClickable('vesselId');
    this.grid.setToolbarConfig(false,false,false,false);
    this.grid.iconName = 'obligationDashboard';
    this.grid.useDefaultDuplicateFunction = false;
    this.grid.useDefaultEditFunction = false;
    this.createGridFilters();
    this.grid.gridFetchUrlType = "POST";
    this.grid.fetchPostPayloadFuction = function (value:any[] = []) {
      return _this.getCommonFilterPayload();
    };
    this.claimAccess=this.accessPolicyService.canAccessWithCurrentRoute('Action','claim')
    this.undoClaimAccess=this.accessPolicyService.canAccessWithCurrentRoute('Action','undoclaim')
    this.createExpandgrid();
    this.getStats();

    this.route.queryParams.subscribe(params => {
      let keys = Object.keys(params);
      if(params !== undefined && params !== null && keys.length > 0){
        let vesselId = params['vesselId']
        this.selectedVesselObj = {};
        this.selectedVesselObj['vesselId'] = vesselId;
        if(keys.includes("mode")) {
          this.formMode = params["mode"];
        }
        this.displayForm = true;
      }
    })
    this.createLoadFunctions();
  }

  private createLoadFunctions() {
    let _this = this;
    this.tradePricingLoadApiFunction = function (value) {
      let iconName = value['iconName'];
      let obj = {};
      if(iconName !== 'Not Priced' && iconName !== 'Provisional Not Priced' ) {
        obj['type'] = "GET";
        obj['url'] = environment.base_url+'/api/manualPricingService/v1/get-trade-by-tradeid-v2?tenantId='+ _this.commonService.getFromStorage('tenantId')+'&tradeId='+value['rowData']['tradeId'];
        obj['payload'] = {};
      }
      return obj;
    }
  }

  private getStats() {
    let _this = this;
    _this.unplannedCardValues = [];
    this.commonService.getJSONByURL(environment.base_url+'/api/transportactualization/v1/getstats?tenantId='+this.commonService.getFromStorage('tenantId')).subscribe((next) => {
      _this.statObj = next;
    });
  }


  private createExpandgrid() {
    let grid = new Grid(this.getExpandColumns(), true, true, true, true, 'plannedObligationId', '/api/transportallocationv2/v1/get-allocated-sea-freight-planned-obligation-by-plan-id?tenantId='+ this.commonService.getFromStorage('tenantId')+'&vesselId=', undefined, 'Obligations Dashboard', undefined, undefined);
    grid.setPreviousLevelPrimaryKey('vesselId');
    grid.showCheckbox = true;
    this.grid.expandGrid = grid;
    this.grid.expandGrid.urlModifier = function (url: string,rowData:any) {
      return url + "&planId="+rowData['planId']
    };
  }


  private getCommonFilterPayload() {
    let obj = {};
      obj['vesselName'] = this.vesselName;
      obj['vesselId'] = this.vesselId;
      obj['loadPort'] = this.loadPort;
      obj['unloadPort'] = this.unloadPort;
    return obj;
  }

  private getColumns() {
    this.columns = new Map();
    let _this = this;
    let df=this.quantityRoundOff;
      let statusFormatFunction = function (value:any[]) {
      let html: any;
      if (value && value[0] && value[0].toLowerCase() === 'pending actualization') {
        html = _this.commonService.getSafeHtml('<div style="display: inline-flex;align-items: center">' +
            '<img src="../../../assets/images/svg/calendar.svg" alt="" width="15px" height="15px">' +
            '<p style="color:#767676;margin: 0;padding: 0;font-size: 14px;margin-left: 10px;margin-top:3px;white-space: nowrap !important;">Pending Actualization</p></div>');
      } else if (value && value[0] &&  value[0].toLowerCase() === 'partially actualized') {
        html = _this.commonService.getSafeHtml('<div style="display: inline-flex;align-items: center">' +
            '<img src="../../../assets/images/svg/calendar.svg" alt="" width="15px" height="15px">' +
            '<p style="color:#767676;margin: 0;padding: 0;font-size: 14px;margin-left: 10px;margin-top:3px;white-space: nowrap !important;">Partially Actualized</p></div>');
      } else {
        html = _this.commonService.getSafeHtml('<div style="display: inline-flex;align-items: center">' +
            '<img src="../../../assets/images/svg/purpleTick.svg" alt="" width="15px" height="15px">' +
            '<p style="color:#14054e;margin: 0;padding: 0;font-size: 14px;margin-left: 10px;margin-top:3px;white-space: nowrap !important;">Actualized</p></div>');
      }
      return html;
    }

    let quantityValue = function (value:any[]) {
      return _this.commonService.formatNumberWithoutComma(value[0],df)+' MT';
    }

    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('vesselName', new Tcolumn('vesselName', 'Vessel Name', 'LB', 1, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('vesselId', new Tcolumn('voyageNo', 'Voyage ID', 'LB', 2, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('buyLoadQuantity', new Tcolumn('buyLoadQuantity', 'Buy Load Quantity', 'LB', 3, false, undefined, true, new defaultValue('',undefined,undefined,undefined,false,true,false,quantityValue), 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('buyUnloadQuantity', new Tcolumn('buyUnloadQuantity', 'Buy Unload Quantity', 'LB', 4, true, undefined, true,  new defaultValue('',undefined,undefined,undefined,false,true,false,quantityValue), 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('sellLoadQuantity', new Tcolumn('sellLoadQuantity', 'Sell Load Quantity', 'LB', 3, false, undefined, true, new defaultValue('',undefined,undefined,undefined,false,true,false,quantityValue), 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('sellUnloadQuantity', new Tcolumn('sellUnloadQuantity', 'sel lUnloadQuantity', 'LB', 4, true, undefined, true,  new defaultValue('',undefined,undefined,undefined,false,true,false,quantityValue), 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    // this.columns.set('status', new Tcolumn('status', 'Status', 'LB', 5, true, undefined, true, new defaultValue('',undefined,undefined,undefined,false,true,false,statusFormatFunction), 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
  }

  private getExpandColumns() {
    let _this = this;
    let columns: Map<string, Tcolumn> = new Map();
    let index= 1;
    let df=this.quantityRoundOff;
    let plannedQuantityFunction = function (value:any[]) {
      return _this.commonService.formatNumberWithoutComma(value[0],df)+' '+value[1];
    }
    let tradeTransactionType = function(value: any[]) {
      let html: any;
      if (value[0] === 'BUY') {
        html = _this.commonService.getSafeHtml('<div style="display: inline-flex">' +
            '<img src="../../../assets/images/svg/buyArrow.svg" alt="" width="14px" height="14px">' +
            '<p style="color:#000000;margin: 0;padding: 0;font-size: 14px;margin-left: 10px;white-space: nowrap !important;">Buy</p></div>');
      } else {
        html = _this.commonService.getSafeHtml('<div style="display: inline-flex">' +
            '<img src="../../../assets/images/svg/sellArrow.svg" alt="" width="14px" height="14px">' +
            '<p style="color:#000000;margin: 0;padding: 0;font-size: 14px;margin-left: 10px;white-space: nowrap !important;">Sell</p></div>');
      }
      return html;
    };

    let priceActualizationStatus = function (value:any[]){
      let html: any;
      if (value[0].ACTUALIZED == true) {
        html = _this.commonService.getSafeHtml('<div style="display: inline-flex;align-items: center">' +
            '<img src="../../../assets/images/svg/purpleTick.svg" alt="" width="15px" height="15px">' +
            '<p style="color:#14054e;margin: 0;padding: 0;font-size: 14px;margin-left: 10px;margin-top:3px;white-space: nowrap !important;">Actualized</p></div>');
      }else {
        html = _this.commonService.getSafeHtml('<div style="display: inline-flex;align-items: center">' +
             '<i class="fas fa-clock" style="color:#bf0000; padding-top: 3px"></i>' +
             '<p style="color:#767676;margin: 0;padding: 0;font-size: 14px;margin-left: 10px;margin-top:3px;white-space: nowrap !important;">Pending</p></div>');
      }
      return html;
    }

    columns.set('planId', new Tcolumn('planId', 'Planned ID', 'LB', index++, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('plannedObligationId', new Tcolumn('plannedObligationId', 'Planned Obligation Id', 'LB', index++, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('priceActualizationStatus', new Tcolumn('priceActualizationStatus', 'Actualization Status', 'LB', index++, false, undefined, true, new defaultValue('',undefined,undefined,undefined,false,true,false,priceActualizationStatus,['obligationState']), 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('priceAllocationStatus', new Tcolumn('priceAllocationStatus', 'Price Allocation Status', 'LB', index++, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('tradeTransactionType', new Tcolumn('tradeTransactionType', 'Trade Type', 'LB', index++, false, undefined, true, new defaultValue('',undefined,undefined,undefined,false,true,false,tradeTransactionType), 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('tradeDateTime', new Tcolumn('tradeDateTime', 'Trade Date', 'D', index++, false,undefined,true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('commodity', new Tcolumn('commodity', 'Commodity', 'LB', index++, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('counterparty', new Tcolumn('counterparty', 'Counterparty', 'LB', index++, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('plannedQuantity', new Tcolumn('plannedQuantity', 'Planned Quantity', 'LB', index++, false, undefined, true, new defaultValue('',undefined,undefined,undefined,false,true,false,plannedQuantityFunction,['plannedQuantity','quantityUOM']), 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('deliveryEndDate', new Tcolumn('deliveryEndDate', 'Delivery Date', 'D', index++, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('incoterm', new Tcolumn('incoterm', 'Incoterm', 'LB', index++, false, undefined, true, '', 'H', [], undefined, 1, undefined, undefined, undefined, undefined));
    columns.set('location', new Tcolumn('location', 'Location (Load/Unload Port)', 'LB', index++, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('cropOrigin', new Tcolumn('cropOrigin', 'Crop Origin', 'LB', index++, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('grade', new Tcolumn('grade', 'Grade', 'LB', index++, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('profitcenter', new Tcolumn('profitcenter', 'Profit Center', 'LB', index++, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    return columns;
  }

  onUniqueClick(value: any) {

  }

    onRefreshComplete(value: any) {
        this.refreshGrid = false;
        this.getStats();
        this.createGridFilters();
        if(this.selectedChildRow){
            this.bottomPaneDisplay = false;
            this.createExpandgrid();
        }
    }


  onFilterChange(value: any) {
    if(value['title'] === 'Voyage ID') {
      this.vesselId = value['selected'];
    } else if(value['title'] === 'Vessel Name') {
      this.vesselName = value['selected'];
    } else if(value['title'] === 'Load Port') {
      this.loadPort = value['selected'];
    } else if(value['title'] === 'Unload Port') {
      this.unloadPort = value['selected'];
    }
    this.refreshGrid = true;
  }

  getTypeLabel(type: any) {
    if (type === 'VESSEL_UNPLANNED') {
      return 'Vessel Unplanned';
    } else if (type === 'VESSEL_PARTIALLY_PLANNED') {
      return 'Vessel Partially Planned';
    } else if (type === 'VESSEL_PLANNED') {
      return 'Vessel Planned';
    } else if (type === 'VESSEL_ACTUALIZED') {
      return 'Vessel Actualized';
    } else if (type === 'VESSEL_SETTLED') {
      return 'Vessel Settled';
    }
  }


  private createGridFilters() {
    let _this = this;
    this.resetFilters();
    this.commonService.getJSONByURL(environment.base_url+'/api/transportallocation/v2/transport-allocation-for-actualization-criteria?tenantId='+this.commonService.getFromStorage('tenantId')).subscribe((next) => {
      _this.gridFilters = [];
      _this.gridFilters.push({title:'Vessel Name',list:_this.masterCommonService.getListFromArray(next['vesselName'],false,false)});
      _this.gridFilters.push({title:'Voyage ID',list:_this.masterCommonService.getListFromArray(next['voyageNo'],false,false)});
      _this.gridFilters.push({title:'Load Port',list:_this.masterCommonService.getListFromArray(next['loadPort'],false,false)});
      _this.gridFilters.push({title:'Unload Port',list:_this.masterCommonService.getListFromArray(next['unloadPort'],false,false)});
    });
  }


  private resetFilters() {
    this.vesselName = [];
    this.vesselId = [];
    this.loadPort = [];
    this.unloadPort = [];
    this.gridFilters = [];
    this.gridFilters.push({title:'Vessel Name',list:[]});
    this.gridFilters.push({title:'Voyage ID',list:[]});
    this.gridFilters.push({title:'Load Port',list:[]});
    this.gridFilters.push({title:'Unload Port',list:[]});
  }

  onSaveComplete() {
    this.refreshGrid = true;
  }

  onCloseBottomPane() {
    this.selectedRows = [];
    this.bottomPaneDisplay = false;
  }

  onRowSelectionChange(value: any) {
    if(value !== undefined && value !== null && value.length ===  1) {
      this.selectedVesselObj = value[0];
      this.bottomPaneDisplay = true;
    } else {
      this.selectedVesselObj = {};
      this.bottomPaneDisplay = false;
    }
  }

  closeForm() {
    this.display = false;
    this.selectedRows = [];
  }

  calculateHeight() {
    let usableHeight = 0.8 *  window['visualViewport'].height;
    this.gridHeight = (usableHeight - 180) + 'px';
  }

  showToast(msg, severity: string = 'success') {
    this.messageService.add({
      severity: severity,
      summary: msg
    });
  }

  onEditClick(value: any) {
    this.selectedRows = [];
    this.bottomPaneDisplay = false;
    this.mode = 'update';
    this.rowData = value;
  }

  onCopy(value: any) {
    this.bottomPaneDisplay = false;
    this.selectedRows = [];
    this.mode = 'copy';
    this.rowData = value;
  }

  actualizePlan() {
    if(this.selectedVesselObj.allocationType ==='Substitute'){
      this.showToast('You have Allocated Substitute Vessel for the Plan,\n  Please Allocate Voyage to Actualize','error');
      return;
    }
    this.displayForm = true;
    this.bottomPaneDisplay = false;
  }

  onCloseForm() {
    this.displayForm = false;
    this.selectedRows = [];
    this.refreshGrid = true;
  }

  priceAllocationiconPath(rowData){
    if(rowData.priceType=='Basis'|| rowData.priceType=='PTBF') {
      if ((rowData.pricingStatus === 'Not Priced' && rowData.priceAllocationstatus === 'Not Allocated')) {
        return "Not Priced";
      }
      return rowData.priceAllocationstatus;
    }
    else
      return "Fixed Priced";
  }


  //To open Price Allocation Tab
  openPriceAllocationTab(tradeId:any =''){
    this.router.navigate(['/priceAllocation'],{queryParams:{value:tradeId,buttonName:'Price Allocation'}});
  }

  //To show Price Allocation Details
  openPriceAllocationInfo(tradeId:any =''){
    this.router.navigate(['/priceAllocation'],{queryParams:{value:tradeId,buttonName:'See All PriceAllocation Details'}});
  }


  onHover(panel: OverlayPanel) {
    if(this.overlayPanelTemp !== null && this.overlayPanelTemp !== undefined) {
      this.overlayPanelTemp.hide();
    }
    this.overlayPanelTemp = panel;
  }

  onButtonClick(value: any) {
    let buttonName = value['name'];
    let iconName = value['iconName'];
    let rowData = value['rowData'];
    let dbData = value['dbData'];
    if(buttonName==='Price Allocation'){
      this.openPriceAllocationTab(rowData['tradeId']);
    }
    if(buttonName==='See All PriceAllocation Details'){
      this.openPriceAllocationInfo(rowData['tradeId']) ;
    }
    if(buttonName === 'Request for Trade Pricing') {
      let _this = this;
      this.commonService.sendNotfication('Price Fixation Pending for Trade Id : '+rowData['tradeId']).subscribe((next) => {
        _this.showToast('Notification Sent Successfuly');
      },(error:HttpErrorResponse) => {
        _this.showToast('Failed to send Notification.','error');
      });
    }
  }

  onExpandRowSelectionChange(rowData:any) {
    this.selectedChildRow = null;
    if(rowData.value.length == 1) {
      this.selectedChildRow = rowData.value[0];
      this.bottomPaneDisplay = true;
    }else{
      this.bottomPaneDisplay = false;
    }
  }

    deactualizePlan() {
        let _this=this;
        let payload = [];
        payload.push(this.selectedChildRow);
        _this.commonService.post(environment.base_url + '/api/actualization/v1/deactualize?tenantId='+_this.commonService.getTenantId(), payload).subscribe(next => {
          _this.showToast('De - Actualization of ' + _this.selectedChildRow['plannedObligationId'] + ' is successful');
          _this.selectedChildRow.obligationState.ACTUALIZED = false;
          _this.bottomPaneDisplay = false;
          _this.selectedChildRow= {};
          _this.refreshGrid = true;
          _this.createExpandgrid();
        },error =>{
          _this.showToast(_this.commonService.getHttpErrorMessage(error),'error');
        });
    }

  checksettel() {
    if(this.selectedChildRow){
      return this.selectedChildRow.obligationState.SETTLED || !this.selectedChildRow.obligationState.ACTUALIZED ;
    }
  }
}
