import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Action} from "../../grid/actions.model";
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {KeyValue} from "../../grid/key-value.model";
import {environment} from "../../../environments/environment";
import {Tcolumn} from "../../grid/tcolumn.model";
import {ctrmValidators} from "../../services/validators/ctrmValidators";
import {Grid} from "../../grid/grid.model";
import {MasterService} from "../../masters/services/MasterService";
import {CommonService} from "../../services/common.service";
import {Router} from "@angular/router";
import {defaultValue} from "../../grid/defaultValue.model";
import {MessageService} from 'primeng';


@Component({
  selector: 'deal-slip',
  templateUrl: './deal-slip.component.html',
  styleUrls: ['./deal-slip.component.css']
})
export class DealSlipComponent implements OnInit {
  @Input() modalStateDeal: boolean = false;
  @Output() closeEvent = new EventEmitter();
  @Output() onSave = new EventEmitter();
  @ViewChild('ds', {static: true}) dealSlip;
  @ViewChild('dealSlipForm', {static: true}) dealSlipForm:DealSlipComponent;

  gridRefresh: boolean = false;
  private changeObject:any;
  dealSlipHeight = '';
  columns: Map<string, Tcolumn>;
  grid: Grid;
  startDate: Date = new Date();
  tradeDateTime: Date = new Date();
  private listCompany: any[] = [];
  private listCounterparty: any[] = [];
  private listProfitCenter: any[] = [];
  scheduleForm: boolean = false;
  scheduleRows:any[] = [];
  updatepayloadfunction: Function;
  formRowData: any;
  deliveryRows:any[] = [];
  constructor(private formBuilder: FormBuilder, public masterCommonService: MasterService, public commonService: CommonService,
              private router: Router,private messageService: MessageService) {
  }

  @HostListener("window:resize", [])
  public onResize() {
    this.calculateHeight();
  }

  ngOnInit() {
    let _this = this;
    this.getColumns();
    this.calculateHeight();
    this.grid = new Grid(this.columns, true, true, true, true, '', 'trade/post', '', 'Deal Slip', '', '', undefined, undefined, false);
    this.grid.setSaveUrl(environment.base_url + '/api/trade/v1/createphysicaltrade');
    this.grid.setGridConfiguration(false, true, false, false);
    this.grid.addSection('Trade Counterparty and Company Details', ['tradeDateTime', 'isInternalTrade', 'tradeTransactionType',
                                    'company', 'counterparty', 'profitcenter', 'internalProfitCenter', 'traderName', 'broker']);
    this.grid.addSection('Commodity and Origin Details', ['commodity', 'origin']);
    this.grid.addSection('Quantity and Delivery Details', ['incoterm','locationType', 'location','modeOfTransport',
                                    'loadLocationType','loadLocation','unloadLocationType','dischargeLocation', 'packageType','weightBasis',
                                    'quantityUom','quantity','internalPackage','internalPackageUnit','','externalPackage','externalPackageUnit','','quantityPeriodicity','periodStartDate','periodEndDate',
                                    'deliverySchedule','totalUnits','totalTradeQty','capacity']);
    this.grid.addSection('Commodity Price Details', ['priceType', 'futureIndex', 'futureContractMonth', 'tradePrice',
                                    'tradePriceCurrency', 'tradePriceUom']);
    this.grid.addSection('Payment and Settlement Details', ['tradeSettlementCurrency', 'paymentterm', 'fxrate', 'comments']);

    //Function to open the Delivery Schedule Form
    this.updatepayloadfunction =  function(value) {
      if(_this.scheduleRows === null || _this.scheduleRows === undefined || _this.scheduleRows.length === 0) {
        value['deliverySchedule'] = _this.generatePreviewObligation(value);
      } else {
        value['deliverySchedule'] = _this.scheduleRows;
      }
      value['periodEndDate'] = value['deliverySchedule'][value['deliverySchedule'].length-1]['periodEndDate']
      return value;
    }

  }
   generatePreviewObligation(data){
    let _this = this;
     let rows:any[] = [];
    let total = this.commonService.calculateTotalContractQuantity(1,data['quantityPeriodicity'],data['periodStartDate'],data['periodEndDate']);
    let quantity = (data['totalTradeQty'] / total);
    for(let i=0;i < total;i++){
      if(i === 0) {
        rows.push(_this.commonService.getObligationObject(_this.commonService.calculateDeliveryStartDate(data['periodStartDate'],data['quantityPeriodicity']),quantity,data));
      } else {
        rows.push(_this.commonService.getObligationObject(_this.commonService.addDaysInDate(new Date(rows[i-1]['periodEndDate']),1),quantity,data));
      }
    }
    return rows;
  }

   quantityCalculation(packageUnit,data){
    let unitObj = {};
    if(data['packageType'] === 'Container') {
      unitObj['packageType'] = data['packageType']
      unitObj['externalPackageUnit']= packageUnit;
      unitObj['commodity']= data['commodity'];
      unitObj['calculateFrom'] = data['externalpackage'];
      unitObj['quantityUom']= data['quantityUom'];
      unitObj['externalPackageName']= data['externalPackage'];
    } else if(data['packageType'] === 'Unit') {
      unitObj['packageType'] = data['packageType']
      unitObj['internalPackageUnit']= packageUnit;
      unitObj['commodity']= data['commodity'];
      unitObj['quantityUom']= data['quantityUom'];
      unitObj['internalPackageName']= data['internalPackage'];
    }
    let next =  this.commonService.post(environment.base_url + '/api/externalpackage/v1/quantityandunitconversion?tenantId='+this.commonService.getFromStorage('tenantId'),unitObj).subscribe((next) => {
      return next;
    });
    return next['quantity'];
  }

  getUserInfoList() {
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url_new + '/api-iam/api/userProfile/v1/getUserDetails?userId=' + this.commonService.getFromStorage('userName') + '&tenantId=' + this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
      _this.listCompany = _this.getDropDownValues(next['userInfoData']['Legal Entity']);
      _this.listCounterparty = _this.getDropDownValues(next['userInfoData']['Counterparty']);
      _this.listProfitCenter = _this.getDropDownValues(next['userInfoData']['Profit Center']);
      _this.grid.getColumn().get('company').setListofValues(_this.listCompany);
      _this.grid.getColumn().get('counterparty').setListofValues(_this.listCounterparty);
      _this.grid.getColumn().get('profitcenter').setListofValues(_this.listProfitCenter);
      _this.grid.getColumn().get('internalProfitCenter').setListofValues(_this.listProfitCenter);
    });
  }

  getDropDownValues(property:any){
    let array = [{label:'Select', value:''}];
    if (property !== undefined && property !== null){
      property.forEach(data => {
        array.push({label:data['masterTypeValue'], value:data['masterTypeValue']});
      });
    }
    return array;
  }

  getColumns() {
    let _this = this;
    // to fetch the data in commoditydropdown when it is selected as internaltrade as well as external trade
    let postFunction = function (value: any[] = []) {
      let obj = {};
      if (value !== undefined && value.length > 0 && value[0] !== undefined && value[0].length > 0 && value[1] !== undefined && value[1].length > 0 && value[2] !== undefined && value[2].length > 0) {
        if(value[3] !== undefined && value[3] !== null && value[3] === '') {
          obj = {
            userId: value[0],
            profitCenterName: value[1],
            counterpartyName: value[2],
            tenantId: _this.commonService.getTenantId()
          };
        } else {
          obj = {
            userId: value[0],
            profitCenterName: value[1],
            sellProfitCenter: value[3],
            tenantId: _this.commonService.getTenantId()
          };
        }
      }
      return obj;
    };

    let resetValue = (value: any[] = []) => {
      return '';
    };

    let actionTraderName = new Action();
    actionTraderName.addFieldToUpdate('commodity', new KeyValue('name', 'name'), undefined, undefined, environment.base_url + '/api/trade/v1/loadcommoditydata', true, undefined, false, true, undefined, undefined, postFunction, ['traderName', 'profitcenter', 'counterparty','internalProfitCenter']);

    let actionProfitCenter = new Action();
    actionProfitCenter.addFieldToUpdate('commodity', new KeyValue('name', 'name'), undefined, undefined, environment.base_url + '/api/trade/v1/loadcommoditydata', true, undefined, false, false, undefined, undefined, postFunction, ['traderName', 'profitcenter', 'counterparty','internalProfitCenter']);

    let actionInternalProfitCenter = new Action();
    actionInternalProfitCenter.addFieldToUpdate('commodity', new KeyValue('name', 'name'), undefined, undefined, environment.base_url + '/api/trade/v1/loadcommoditydata', true, undefined, false, false, undefined, undefined, postFunction, ['traderName', 'profitcenter', 'counterparty','internalProfitCenter']);



    let defPaymentTermFunc = function (value:any[] =[]) {
      if(value[0] !== null && value[0] !== undefined) {
        return value['0']['approvedPaymentTermName'];
      }
      return '';
    };

    let actionCounterparty: Action = new Action();
    actionCounterparty.addFieldToUpdate('commodity', new KeyValue('name', 'name'), undefined, undefined, environment.base_url + '/api/trade/v1/loadcommoditydata', true, undefined, false, true, undefined, undefined, postFunction, ['traderName', 'profitcenter', 'counterparty','internalProfitCenter']);
    actionCounterparty.addFieldToUpdate('paymentterm', new KeyValue('approvedPaymentTermName', 'approvedPaymentTermName'), undefined, undefined, environment.base_url + '/api/counterpartypaymentterm/v1/getpaymenttermbycunterpartyname?tenantId='+this.commonService.getFromStorage('tenantId') + '&name=', true, undefined, undefined, undefined);
    actionCounterparty.addFieldToUpdate('paymentterm', undefined, defPaymentTermFunc, ['DB'], environment.base_url + '/api/counterpartypaymentterm/v1/getdefaultpaymenttermbycounterpartyname?tenantId='+this.commonService.getFromStorage('tenantId') + '&name=', true, 'defaultValue', undefined, undefined);

    let currencyFunction = function (value: any[]) {
      if (value[0] !== undefined && value[0] !== null) {
        return value[0]['currency']
      }
      return '';
    };

    let actionCompany: Action = new Action(environment.base_url + '/api/company/v1/getcompanybyname?tenantId=' + this.commonService.getFromStorage('tenantId') + '&name=');
    actionCompany.setCovertToListRequired(false);
    actionCompany.addFieldToUpdate('tradePriceCurrency', undefined, currencyFunction, ['DB'], undefined, false, 'defaultValue');
    actionCompany.addFieldToUpdate('tradeSettlementCurrency', undefined, currencyFunction, ['DB'], undefined, false, 'defaultValue');

    let originListFunc = function (value: any[]) {
      return _this.commonService.addListOTT(value[0]['CommodityOrigin']);
    };

    let originDefaultFunc = function (value: any[]) {
      return _this.commonService.getDefaultValueFromList(value[0]['CommodityOrigin']);
    };

    let quantityUomFunction = function(value:any[]) {
      if (value[0] !== undefined && value[0] !== null && value[0]['Commodity'] !== undefined && value[0]['Commodity'] !== null) {
        return value[0]['Commodity']['uom'];
      }
      return '';
    };

    let packageListFunc = function (value: any[]) {
      let list:any[]  = value[0]['CommodityPackage'];
      return _this.commonService.addListOTT(list);
    };

    let externalPackageListFunc = function (value: any[]) {
      return _this.commonService.addListOTT(value[0]['CommodityExternalPackage']);
    };

    let actionCommodity: Action = new Action(environment.base_url + '/api/commodity/v1/getcommoditydefaultproperties?tenantId=' + this.commonService.getFromStorage('tenantId') + '&commodityName=');
    actionCommodity.setCovertToListRequired(false);
    actionCommodity.addFieldToUpdate('origin', undefined, originListFunc, ['DB'], undefined, true, 'listvalues');
    actionCommodity.addFieldToUpdate('origin', undefined, originDefaultFunc, ['DB'], undefined, false, 'defaultValue');
    actionCommodity.addFieldToUpdate('quantityUom', undefined, quantityUomFunction, ['DB'], undefined, false, 'defaultValue');
    actionCommodity.addFieldToUpdate('tradePriceUom', undefined, quantityUomFunction, ['DB'], undefined, false, 'defaultValue');
    actionCommodity.addFieldToUpdate('internalPackage',undefined,packageListFunc,['DB'],undefined,false,'listvalues');
    actionCommodity.addFieldToUpdate('externalPackage',undefined,externalPackageListFunc,['DB'],undefined,false,'listvalues');



    //PriceUom List to show for price fixation
    let priceUomListFunction = function(value:any[] = []){
      let list = [new KeyValue()];
      if (value[0] !== null && value[0] !== undefined && value[2] !== null && value[2] !== undefined) {
        if (value[0] === value[2]) {
          list.push(new KeyValue(value[0], value[0]));
        }else{
          if (value[0].length > 0) {
            list.push(new KeyValue(value[0], value[0]));
          }
          if (value[2].length > 0) {
            list.push(new KeyValue(value[2], value[2]))
          }
        }
      }
      return list;
    };


    let priceCurrencyFunction = function(value:any[]) {
      if(value[0] !== undefined && value[0] !== null && value[0]['futureIndex'] !==  undefined && value[0]['futureIndex'] !== null) {
        return value[0]['futureIndex']['currency']
      }
      return '';
    };

    let priceUomFunction = function(value:any[]) {
      if(value[0] !== undefined && value[0] !== null && value[0]['futureIndex'] !==  undefined && value[0]['futureIndex'] !== null) {
        return value[0]['futureIndex']['uom']
      }
      return '';
    };

    let priceTickerFunction = function(value:any[]) {
      if(value[0] !== undefined && value[0] !== null) {
        return _this.commonService.addListOTT(value[0]['tickers'])
      }
      return '';
    };

    let actionFutureIndex = new Action(environment.base_url + '/api/priceIndex/v1/getfutureindexdetails?tenantId=' + this.commonService.getFromStorage('tenantId') + '&name=');
    actionFutureIndex.setCovertToListRequired(false);
    actionFutureIndex.addFieldToUpdate('futureContractMonth', undefined, priceTickerFunction, ['DB'], undefined, true,'listvalues');
    actionFutureIndex.addFieldToUpdate('tradePriceCurrency', undefined, priceCurrencyFunction, ['DB'], undefined, true, 'defaultValue');
    actionFutureIndex.addFieldToUpdate('tradePriceUom', undefined, priceUomFunction, ['DB'], undefined, true, 'defaultValue');
    actionFutureIndex.addFieldToUpdate('tradePriceUom', undefined, priceUomListFunction, ['tradePriceUom', 'priceType', 'quantityUom'], undefined, true, 'listvalues');

    let totalContractQuantityFunction = function (value: any[] = []) {
      return _this.commonService.calculateTotalContractQuantity(value[0], value[1], value[2], value[3]);
    };

    //TotalUnits Calculation for External Package
    let totalUnitsFunction = function (value: any[] = []) {
      if (value[3] !== undefined && value[3] !== null && value[3] !== ''){
        if(value[5] === 'Bulk'){
          return _this.commonService.calculateTotalContractQuantity(1, value[1], value[2], value[3]);
        }
        if(value[0] !== null && value[0] !== undefined && value[0] > 0) {
          return (value[0] * _this.commonService.calculateTotalContractQuantity(1, value[1], value[2], value[3]));
        } else {
          return (value[4] * _this.commonService.calculateTotalContractQuantity(1, value[1], value[2], value[3]));
        }
      }
      return  0;
    }

    let updateQuantity = function(value:any[]){
      let totalQuantity:any;
      if (value[3] !== undefined && value[3] !== null && value[3] !== ''){
        if (value[7] === 'Bulk'){
          totalQuantity =  _this.commonService.calculateTotalContractQuantity(value[5], value[1], value[2], value[3]);
        } else if (value[7].toLowerCase() === 'unit'){
          totalQuantity = value[6] * _this.commonService.calculateTotalContractQuantity(value[4], value[1], value[2], value[3]);
        } else{
          totalQuantity = value[6] * _this.commonService.calculateTotalContractQuantity(value[0], value[1], value[2], value[3]);
        }
        return parseFloat(parseFloat(totalQuantity).toFixed(4));
      }

      return 0;
    };

    let updateStartDateFormatting = function (value: any[] = []) {
      let obj = _this.grid.getColumn().get('periodStartDate').getExtra();
      return _this.getDateFormat(value[0], obj);
    };
    let updateEndDateFormatting = function (value: any[] = []) {
      let obj = _this.grid.getColumn().get('periodEndDate').getExtra();
      return _this.getDateFormat(value[0], obj, true);
    };
    let startFunction = function (value: any[]) {
      return _this.commonService.calculateDeliveryStartDate(value[0], value[1]);
    };
    let endFunction = function (value: any[]) {
      return _this.commonService.calculateDeliveryEndDate(value[0], value[1]);
    };

    let addQualityUom =  (value: any[] = []) => {
      let header = ("Total Quantity ( "+value+" )")
      return header;
    };

    let resetValueDate = (value: any[] = []) => {
      return '';
    };

    let actionQuantityUOM = new Action();
    actionQuantityUOM.addFieldToUpdate('tradePriceUom', undefined, priceUomListFunction, ['quantityUom', 'priceType', 'tradePriceUom'], undefined, true, 'listvalues');
    actionQuantityUOM.addFieldToUpdate('totalTradeQty',undefined,addQualityUom,undefined,undefined,false,'header');
    actionQuantityUOM.addFieldToUpdate('periodStartDate', undefined, resetValue, undefined, undefined, true, 'defaultValue');
    actionQuantityUOM.addFieldToUpdate('periodEndDate', undefined, resetValue, undefined, undefined, true, 'defaultValue');

    let actionQuantity = new Action();
    actionQuantity.setCovertToListRequired(true);
    actionQuantity.addFieldToUpdate('periodStartDate', undefined, resetValue, undefined, undefined, true, 'defaultValue');
    actionQuantity.addFieldToUpdate('periodEndDate', undefined, resetValue,undefined, undefined, true, 'defaultValue');



    let actionQuantityPeriodicity = new Action();
    actionQuantityPeriodicity.addFieldToUpdate('periodStartDate', undefined, updateStartDateFormatting, undefined, undefined, false, 'extra');
    actionQuantityPeriodicity.addFieldToUpdate('periodEndDate', undefined, updateEndDateFormatting, undefined, undefined, false, 'extra');
    actionQuantityPeriodicity.addFieldToUpdate('periodStartDate', undefined, resetValue, undefined, undefined, true, 'defaultValue');
    actionQuantityPeriodicity.addFieldToUpdate('periodEndDate', undefined, resetValue, undefined, undefined, true, 'defaultValue');


    let actionEndDate = new Action();
    actionEndDate.addFieldToUpdate('deliveryEndDate', undefined, endFunction, ['periodEndDate', 'quantityPeriodicity']);
    actionEndDate.addFieldToUpdate('totalUnits', undefined, totalUnitsFunction, ['externalPackageUnit', 'quantityPeriodicity', 'periodStartDate', 'periodEndDate','internalPackageUnit','packageType'],undefined,false,'defaultValue');
    actionEndDate.addFieldToUpdate('totalTradeQty', undefined, updateQuantity, ['externalPackageUnit', 'quantityPeriodicity', 'periodStartDate', 'periodEndDate','internalPackageUnit','quantity','capacity','packageType'],undefined,false,'defaultValue');

    let actionStartPeriod = new Action();
    actionStartPeriod.addFieldToUpdate('periodEndDate', undefined, undefined, undefined, undefined, false, 'minDate');
    actionStartPeriod.addFieldToUpdate('periodEndDate', undefined, function (value:any[]) {
      return '';
    }, undefined,undefined,false,'defaultValue');

    // Internal Trade Action and Functions
    let setHeaderCP = (value: any[] = []) => {
      let header = 'Counterparty';
      if (value[0]) {
        header = 'Sell Company'
      }
      return header;
    };

    let setHeaderCompany = (value: any[] = []) => {
      let header = 'Company';
      if (value[0]) {
        header = 'Buy Company'
      }
      return header;
    };
    let setHeaderPC = (value: any[] = []) => {
      let header = 'Profit Center';
      if (value[0]) {
        header = 'Buy Profit Center'
      }
      return header;
    };

    let setBuySellDefault = (value: any[] = []) => {
      let defVal = 'BUY';
      if (value[0]) {
        defVal = 'SELL';
      }
      return defVal;
    };

    let setBuySellExtra = (value: any[] = []) => {
      let object = _this.grid.getColumn().get('tradeTransactionType').getExtra();
      object['meta'] = {disabled: false};

      if (value[0]) {
        object['meta'] = {disabled: true};
      }
      return object;
    };

    let internalProfitCenterExtra = (value: any[] = []) => {
      let object = _this.grid.getColumn().get('internalProfitCenter').getExtra();
      if (value[0]) {
        object['meta'] = {disabled: false};
      } else {
        object['meta'] = {disabled: true};
      }
      return object;
    };

    let setIntTradeListCP = (value: any[] = []) => {
      let list: KeyValue[] = _this.listCounterparty;
      if (value[0]) {
        list = _this.listCompany;

      }
      return list;
    };

    let priceTypeFixed = (value: any [] = []) => {
      if (value[0]) {
        return 'Fixed';
      } else {
        return '';
      }
    };

    let priceTypeDisable = (value: any [] = []) => {
      let object = _this.grid.getColumn().get('priceType').getExtra();
      if (value[0]) {
        object['meta'] = {disabled: true};
      } else {
        object['meta'] = {disabled: false};
      }
      return object;
    };


    let actionInternalTrade = new Action();
    actionInternalTrade.addFieldToUpdate('counterparty', undefined, setHeaderCP, undefined, undefined, false, 'header');
    actionInternalTrade.addFieldToUpdate('counterparty', undefined, setIntTradeListCP, undefined, undefined, false, 'listvalues');
    actionInternalTrade.addFieldToUpdate('company', undefined, setHeaderCompany, undefined, undefined, false, 'header');
    actionInternalTrade.addFieldToUpdate('profitcenter', undefined, setHeaderPC, undefined, undefined, false, 'header');
    actionInternalTrade.addFieldToUpdate('tradeTransactionType', undefined, setBuySellDefault, undefined, undefined, false, 'defaultValue');
    actionInternalTrade.addFieldToUpdate('tradeTransactionType', undefined, setBuySellExtra, undefined, undefined, false, 'extra');
    actionInternalTrade.addFieldToUpdate('internalProfitCenter', undefined, internalProfitCenterExtra, undefined, undefined, false, 'extra');
    // actionInternalTrade.addFieldToUpdate('priceType', undefined, priceTypeFixed, undefined, undefined, false, 'defaultValue');
    // actionInternalTrade.addFieldToUpdate('priceType', undefined, priceTypeDisable, undefined, undefined, false, 'extra');

    let priceExtra = (value: any[] = []) => {
      let object = _this.grid.getColumn().get('tradePrice').getExtra();
      object['meta'] = {disabled: true};
      if (value[0] !== undefined) {
        if (value[0].toString().toLowerCase() === 'fixed' || value[0].toString().toLowerCase() === 'basis') {
          object['meta'] = {disabled: false};
        }
      }
      return object;
    };

    let priceCurrencyExtra = (value: any[] = []) => {
      let object = _this.grid.getColumn().get('tradePriceCurrency').getExtra();
      object['meta'] = {disabled: true};
      if (value[0] !== undefined) {
        if (value[0].toString().toLowerCase() === 'fixed' || value[0].toString().toLowerCase() === 'basis') {
          object['meta'] = {disabled: false};
        }
      }
      return object;
    };

    let priceUomExtra = (value: any[] = []) => {
      let object = _this.grid.getColumn().get('tradePriceUom').getExtra();
      object['meta'] = {disabled: true};
      if (value[0] !== undefined) {
        if (value[0].toString().toLowerCase() === 'fixed' || value[0].toString().toLowerCase() === 'basis') {
          object['meta'] = {disabled: false};
        }
      }
      return object;
    };

    let futureExtra = (value: any[] = []) => {
      let obj = {meta: {disabled: false}};
      if (value[0] !== undefined) {
        if (value[0].toString().toLowerCase() === 'fixed') {
          obj = {meta: {disabled: true}};
        }
      }
      return obj;
    };

    let actionPriceType = new Action();
    actionPriceType.addFieldToUpdate('tradePrice', undefined, priceExtra, undefined, undefined, true, 'extra');
    actionPriceType.addFieldToUpdate('tradePriceCurrency', undefined, priceCurrencyExtra, undefined, undefined, true, 'extra');
    actionPriceType.addFieldToUpdate('tradePriceUom', undefined, priceUomExtra, undefined, undefined, true, 'extra');
    actionPriceType.addFieldToUpdate('futureIndex', undefined, futureExtra, undefined, undefined, true, 'extra');
    actionPriceType.addFieldToUpdate('futureContractMonth', undefined, futureExtra, undefined, undefined, true, 'extra');
    actionPriceType.addFieldToUpdate('futureIndex', undefined, resetValue, ['futureIndexField'], undefined, true, 'defaultValue');
    actionPriceType.addFieldToUpdate('futureContractMonth', undefined, resetValue, ['futureContractMonthField'], undefined, true, 'defaultValue');
    actionPriceType.addFieldToUpdate('tradePrice', undefined, resetValue, ['tradePriceField'], undefined, true, 'defaultValue');
    actionPriceType.addFieldToUpdate('tradePriceUom', undefined, undefined, ['tradePriceUomField'], undefined, true, 'defaultValue');
    actionPriceType.addFieldToUpdate('tradePriceCurrency', undefined, resetValue, ['tradePriceCurrencyField'], undefined, true, 'defaultValue');

    let FXRateMetaUpdate = function (value: any[] = []) {
      let validators: Validators[] = [];
      if (value[0] !== undefined && value[1] !== undefined && value[0] !== null && value[1] !== null && value[0].length > 0 && value[1].length > 0) {
        if (value[0] !== value[1]) {
          validators.push(Validators.required);
        }
      }
      return validators;
    };

    let FXRateObjectUpdate = function (value: any[] = []) {
      let obj = {meta: {disabled: false}};
      if (value[0] !== undefined && value[1] !== undefined && value[0] !== null && value[1] !== null && value[0].length > 0 && value[1].length > 0) {
        if (value[0] === value[1]) {
          obj = {meta: {disabled: true}};
        }
      }
      return obj;
    };
    let fxRateDefaultValueFunction = function (value: any[] = []) {
      let defaultValue = value[2];
      if (value[0] !== undefined && value[1] !== undefined && value[0] !== null && value[1] !== null && value[0].length > 0 && value[1].length > 0) {
        if (value[0] === value[1]) {
          defaultValue = 1;
        }
      }
      return defaultValue;
    };

    let actionPriceCurrency = new Action();
    actionPriceCurrency.addFieldToUpdate('fxrate', undefined, FXRateMetaUpdate, ['tradePriceCurrency', 'tradeSettlementCurrency'], undefined, true, 'validators');
    actionPriceCurrency.addFieldToUpdate('fxrate', undefined, FXRateObjectUpdate, ['tradePriceCurrency', 'tradeSettlementCurrency'], undefined, true, 'extra');
    actionPriceCurrency.addFieldToUpdate('fxrate', undefined, fxRateDefaultValueFunction, ['tradePriceCurrency', 'tradeSettlementCurrency', 'fxrate'], undefined, true, 'defaultValue');


    let actionSettlementCurrency = new Action();
    actionSettlementCurrency.addFieldToUpdate('fxrate', undefined, FXRateMetaUpdate, ['tradePriceCurrency', 'tradeSettlementCurrency'], undefined, true, 'validators');
    actionSettlementCurrency.addFieldToUpdate('fxrate', undefined, FXRateObjectUpdate, ['tradePriceCurrency', 'tradeSettlementCurrency'], undefined, true, 'extra');
    actionSettlementCurrency.addFieldToUpdate('fxrate', undefined, fxRateDefaultValueFunction, ['tradePriceCurrency', 'tradeSettlementCurrency', 'fxrate'], undefined, true, 'defaultValue');

    let quantityDisable = (value: any[] = []) => {
      let obj: any = {meta: {disabled: false}};
      if(value[0] !== undefined && value[0] !== null) {
        if (value[0] === 'Container' || value[0] === 'Unit') {
          obj = {meta: {disabled: true}};
        }
      }
      return obj;
    };

    let externalpackageDisable = (value: any[] = []) => {
      let obj: any = {meta: {disabled: false}};
      if(value[0] !== undefined && value[0] !== null) {
        if (value[0] === 'Bulk' || value[0] === 'Unit') {
          obj = {meta: {disabled: true}};
        }
      }
      return obj;
    };

    let internalpackageDisable = (value: any[] = []) => {
      let obj: any = {meta: {disabled: false}};
      if(value[0] !== undefined && value[0] !== null) {
        if (value[0] === 'Bulk' || value[0] === 'Container') {
          obj = {meta: {disabled: true}};
        }
      }
      return obj;
    };

    let actionpackageType = new Action();
    actionpackageType.addFieldToUpdate('externalPackage', undefined, externalpackageDisable, undefined, undefined, true, 'extra');
    actionpackageType.addFieldToUpdate('externalPackageUnit', undefined, externalpackageDisable, undefined, undefined, true, 'extra');
    actionpackageType.addFieldToUpdate('internalPackage', undefined, internalpackageDisable, undefined, undefined, true, 'extra');
    actionpackageType.addFieldToUpdate('internalPackageUnit', undefined, internalpackageDisable, undefined, undefined, true, 'extra');
    actionpackageType.addFieldToUpdate('quantity', undefined, quantityDisable, undefined, undefined, true, 'extra');
    //Reset All Values when change Package Type
    actionpackageType.addFieldToUpdate('quantity', undefined, resetValue, undefined, undefined, true, 'defaultValue');
    actionpackageType.addFieldToUpdate('externalPackage', undefined, resetValue, undefined, undefined, true, 'defaultValue');
    actionpackageType.addFieldToUpdate('externalPackageUnit', undefined, resetValue, undefined, undefined, true, 'defaultValue');
    actionpackageType.addFieldToUpdate('internalPackage', undefined, resetValue, undefined, undefined, true, 'defaultValue');
    actionpackageType.addFieldToUpdate('internalPackageUnit', undefined, resetValue, undefined, undefined, true, 'defaultValue');
    actionpackageType.addFieldToUpdate('periodStartDate', undefined, resetValue, undefined, undefined, true, 'defaultValue');
    actionpackageType.addFieldToUpdate('periodEndDate', undefined, resetValue, undefined, undefined, true, 'defaultValue');
    actionpackageType.addFieldToUpdate('totalUnits', undefined, resetValue, undefined, undefined, true, 'defaultValue');
    actionpackageType.addFieldToUpdate('totalTradeQty', undefined, resetValue, undefined, undefined, true, 'defaultValue');
    actionpackageType.addFieldToUpdate('deliverySchedule', undefined, resetValue, undefined, undefined, true, 'defaultValue');

    let postExternalCalculation = function(value:any){
      let payload = null;
      let canSend = false;
      if(value !== undefined && value !== null &&
          value['commodity'] !== null && value['commodity'] !== undefined && value['commodity'].length > 0 &&
          value['quantityUom'] !== null && value['quantityUom'] !== undefined && value['quantityUom'].length > 0 ) {
        if (value['packageType'] === 'Container' && value['externalPackage'] !== null && value['externalPackage'] !== undefined && value['externalPackage'].length > 0) {
          if (value['externalPackageUnit'] !== undefined && value['externalPackageUnit'] !== null && value['externalPackageUnit'] !== 0) {
            canSend = true;
          }
        }
      }
      if(canSend) {
        payload = {};
        payload['packageType'] = value['packageType']
        payload['externalPackageUnit']= value['externalPackageUnit'];
        payload['commodity']= value['commodity'];
        payload['calculateFrom'] = 'externalpackage';
        payload['quantityUom']= value['quantityUom'];
        payload['externalPackageName']= value['externalPackage'];
      }
      return payload;
    };

    let postInternalCalculation = function(value:any){
      let payload = null;
      let canSend = false;
      if(value !== undefined && value !== null &&
          value['commodity'] !== null && value['commodity'] !== undefined && value['commodity'].length > 0 &&
          value['quantityUom'] !== null && value['quantityUom'] !== undefined && value['quantityUom'].length > 0 ) {
        canSend = true;
      }
      if(canSend) {
        payload = {};
        payload['packageType'] = value['packageType']
        payload['internalPackageUnit']= value['internalPackageUnit'];
        payload['commodity']= value['commodity'];
        payload['calculateFrom'] = 'internalpackage';
        payload['quantityUom']= value['quantityUom'];
        payload['internalPackageName']= value['internalPackage'];
      }
      return payload;
    };

    let quantityexample = function (value: any[]) {
      return value[0]['capacity'];
    };

    //Action For Internal Package Unit
    let actionInternalpackageUnit = new Action(environment.base_url + '/api/externalpackage/v1/quantityandunitconversion?tenantId='+this.commonService.getFromStorage('tenantId'),undefined,undefined,[],undefined,undefined,postInternalCalculation,false);
    actionInternalpackageUnit.setCovertToListRequired(false);
    actionInternalpackageUnit.addFieldToUpdate('periodStartDate', undefined, resetValue, undefined, undefined, true, 'defaultValue');
    actionInternalpackageUnit.addFieldToUpdate('periodEndDate', undefined, resetValue, undefined, undefined, true, 'defaultValue');
    actionInternalpackageUnit.addFieldToUpdate('capacity',undefined, quantityexample, ['DB'], undefined, false,'defaultValue');
    actionInternalpackageUnit.addFieldToUpdate('totalTradeQty',undefined, updateQuantity, ['externalPackageUnit', 'quantityPeriodicity', 'periodStartDate', 'periodEndDate','internalPackageUnit','quantity','capacity'], undefined, false,'defaultValue');
    actionInternalpackageUnit.addFieldToUpdate('totalUnits', undefined, totalUnitsFunction, ['externalPackageUnit', 'quantityPeriodicity', 'periodStartDate', 'periodEndDate','internalPackageUnit','packageType'],undefined,false,'defaultValue');

    //Action For External Package Unit
    let actionExternalpackageUnit = new Action(environment.base_url + '/api/externalpackage/v1/quantityandunitconversion?tenantId='+this.commonService.getFromStorage('tenantId'),undefined,undefined,[],undefined,undefined,postExternalCalculation,false);
    actionExternalpackageUnit.setCovertToListRequired(false);
    actionExternalpackageUnit.addFieldToUpdate('periodStartDate', undefined, resetValue, undefined, undefined, true, 'defaultValue');
    actionExternalpackageUnit.addFieldToUpdate('periodEndDate', undefined, resetValue, undefined, undefined, true, 'defaultValue');
    actionExternalpackageUnit.addFieldToUpdate('capacity',undefined, quantityexample, ['DB'], undefined, false,'defaultValue');
    actionExternalpackageUnit.addFieldToUpdate('totalTradeQty',undefined, updateQuantity, ['externalPackageUnit', 'quantityPeriodicity', 'periodStartDate', 'periodEndDate','internalPackageUnit','quantity','capacity'], undefined, false,'defaultValue');
    actionExternalpackageUnit.addFieldToUpdate('totalUnits', undefined, totalUnitsFunction, ['externalPackageUnit', 'quantityPeriodicity', 'periodStartDate', 'periodEndDate','internalPackageUnit','packageType'],undefined,false,'defaultValue');

    let actionExternalPackage = new Action(environment.base_url + '/api/externalpackage/v1/quantityandunitconversion?tenantId='+this.commonService.getFromStorage('tenantId'),undefined,undefined,[],undefined,undefined,postExternalCalculation,false);
    actionExternalPackage.setCovertToListRequired(false);
    actionExternalPackage.addFieldToUpdate('capacity',undefined, quantityexample, ['DB'], undefined, false,'defaultValue');
    actionExternalPackage.addFieldToUpdate('totalUnits', undefined, totalUnitsFunction, ['externalPackageUnit', 'quantityPeriodicity', 'periodStartDate', 'periodEndDate','internalPackageUnit','packageType'],undefined,false,'defaultValue');
    actionExternalPackage.addFieldToUpdate('totalTradeQty', undefined, updateQuantity, ['externalPackageUnit', 'quantityPeriodicity', 'periodStartDate', 'periodEndDate','internalPackageUnit','quantity','capacity'],undefined,false,'defaultValue');
    actionExternalPackage.addFieldToUpdate('periodStartDate', undefined, resetValue, undefined, undefined, true, 'defaultValue');
    actionExternalPackage.addFieldToUpdate('periodEndDate', undefined, resetValue, undefined, undefined, true, 'defaultValue');


    let actionInternalPackage = new Action(environment.base_url + '/api/externalpackage/v1/quantityandunitconversion?tenantId='+this.commonService.getFromStorage('tenantId'),undefined,undefined,[],undefined,undefined,postInternalCalculation,false);
    actionInternalPackage.setCovertToListRequired(false);
    actionInternalPackage.addFieldToUpdate('capacity',undefined, quantityexample, ['DB'], undefined, false,'defaultValue');
    actionInternalPackage.addFieldToUpdate('totalUnits', undefined, totalUnitsFunction, ['externalPackageUnit', 'quantityPeriodicity', 'periodStartDate', 'periodEndDate','internalPackageUnit','packageType'],undefined,false,'defaultValue');
    actionInternalPackage.addFieldToUpdate('totalTradeQty', undefined, updateQuantity, ['externalPackageUnit', 'quantityPeriodicity', 'periodStartDate', 'periodEndDate','internalPackageUnit','quantity','capacity'],undefined,false,'defaultValue');
    actionInternalPackage.addFieldToUpdate('periodStartDate', undefined, resetValue, undefined, undefined, true, 'defaultValue');
    actionInternalPackage.addFieldToUpdate('periodEndDate', undefined, resetValue, undefined, undefined, true, 'defaultValue');



    let locationFunction = function (value: any[]) {
      return value[0];
    };
    let locationType: Action = new Action(environment.base_url + '/api/location/v1/getallbylocationtype?tenantId=' + this.commonService.getFromStorage('tenantId') + '&locationType=', 'name', 'name');
    locationType.setCovertToListRequired(true);
    locationType.addFieldToUpdate("location", undefined, locationFunction, ['L'], undefined, true, 'listvalues');

    let loadLocationType: Action = new Action(environment.base_url + '/api/location/v1/getallbylocationtype?tenantId=' + this.commonService.getFromStorage('tenantId') + '&locationType=', 'name', 'name');
    loadLocationType.setCovertToListRequired(true);
    loadLocationType.addFieldToUpdate("loadLocation", undefined, locationFunction, ['L'], undefined, true, 'listvalues');

    let unloadLocationType: Action = new Action(environment.base_url + '/api/location/v1/getallbylocationtype?tenantId=' + this.commonService.getFromStorage('tenantId') + '&locationType=', 'name', 'name');
    unloadLocationType.setCovertToListRequired(true);
    unloadLocationType.addFieldToUpdate("dischargeLocation", undefined, locationFunction, ['L'], undefined, true, 'listvalues');

    this.columns = new Map();
    // Section 1: Trade Counterparty and Company Details
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('tradeDateTime', new Tcolumn('tradeDateTime', 'Trade Date', 'D', 1, false, undefined, true, new Date(), 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined));
    this.columns.set('isInternalTrade', new Tcolumn('isInternalTrade', 'Internal Trade', 'OB', 2, true, this.getIntExt(), true, false, 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionInternalTrade));
    this.columns.set('tradeTransactionType', new Tcolumn('tradeTransactionType', 'Buy/Sell', 'OB', 3, true, this.getBuySellList(), true, 'BUY', 'B', [Validators.required]));
    this.columns.set('company', new Tcolumn('company', 'Company', 'L', 4, true, [], true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionCompany));
    this.columns.set('counterparty', new Tcolumn('counterparty', 'Counterparty', 'L', 5, false, [], true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionCounterparty));
    this.columns.set('profitcenter', new Tcolumn('profitcenter', 'Profit Center', 'L', 6, false, [], true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionProfitCenter));
    this.columns.set('internalProfitCenter', new Tcolumn('internalProfitCenter', 'Sell Profit Center', 'L', 7, false, [], true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionInternalProfitCenter, {
      meta: {disabled: true},
      infoIcon: {summary: 'Enables only for Internal Trade'}
    }));
    this.columns.set('traderName', new Tcolumn('traderName', 'Trader Name', 'L', 8, false, this.masterCommonService.getListFromUrl('/api/trade/v1/getuser?tenantId='+this.commonService.getFromStorage('tenantId')+'&userType=trader', 'value', 'value'), true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionTraderName));
    this.columns.set('broker', new Tcolumn('broker', 'Broker', 'L', 9, false, [], true, '', 'B', []));
    // Section 2: Commodity and Origin Details
    this.columns.set('commodity', new Tcolumn('commodity', 'Commodity', 'L', 10, true, [], true, '', 'B', [Validators.required], undefined, 1, new Date(), undefined, undefined, actionCommodity));
    this.columns.set('origin', new Tcolumn('origin', 'Origin', 'L', 11, true, [], true, '', 'B', []));
    //Section 3: Quantity and Delivery Details
    this.columns.set('incoterm', new Tcolumn('incoterm', 'Incoterm', 'L', 12, true, [], true, '', 'B', [Validators.required]));
    this.columns.set('locationType', new Tcolumn('locationType', 'Location Type', 'L', 13, true, [], true, 'Country', 'B', [],undefined,undefined,undefined,undefined,undefined,locationType));
    this.columns.set('location', new Tcolumn('location', 'Location', 'L', 14, true, [], true, '', 'B', []));
    this.columns.set('modeOfTransport', new Tcolumn('modeOfTransport', 'Mode Of Transport', 'L', 15, true, [], true, 'Ocean', 'B', []));
    this.columns.set('loadLocationType', new Tcolumn('loadLocationType', 'Load Location Type', 'L', 16, true, [], true, 'Terminal', 'B', [],undefined,undefined,undefined,undefined,undefined,loadLocationType));
    this.columns.set('loadLocation', new Tcolumn('loadLocation', 'Load Location', 'L', 17, true, [], true, '', 'B', []));
    this.columns.set('unloadLocationType', new Tcolumn('unloadLocationType', 'Unload Location Type', 'L', 18, true, [], true, 'Terminal', 'B', [],undefined,undefined,undefined,undefined,undefined,unloadLocationType));
    this.columns.set('dischargeLocation', new Tcolumn('dischargeLocation', 'UnLoad Location', 'L', 19, true, [], true, '', 'B', []));
    this.columns.set('packageType', new Tcolumn('packageType', 'Package Type', 'OB', 20, true, this.getInternalPackageType(), true, 'Bulk', 'B', [Validators.required],undefined,3,undefined,undefined,undefined,actionpackageType));
    this.columns.set('weightBasis', new Tcolumn('weightBasis', 'Weight Basis', 'L', 21, true, [], true, 'Shipped Weights', 'B', []));
    this.columns.set('quantityUom', new Tcolumn('quantityUom', 'Quantity Uom', 'L', 22, true, [], true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionQuantityUOM));
    this.columns.set('quantity', new Tcolumn('quantity', 'Quantity/Period', 'N', 23, true, [], true, '', 'B', [Validators.min(0), ctrmValidators.decimalLengthValidator(10, 2), Validators.required], undefined, undefined, undefined, undefined, undefined, actionQuantity, {action: {runOnParentChange: false}}));
    this.columns.set('internalPackage', new Tcolumn('internalPackage', 'Internal Package', 'L', 27, true, [], true, '', 'B', [], undefined, undefined, undefined, undefined, undefined, actionInternalPackage, {meta: {disabled: true}}));
    this.columns.set('internalPackageUnit', new Tcolumn('internalPackageUnit', '# of Units/Period', 'N', 28, true, undefined, true, '', 'B', [Validators.required],undefined,undefined,undefined,undefined,undefined,actionInternalpackageUnit,{meta: {disabled: true},action:{runOnParentChange: false}}));
    this.columns.set('externalPackage', new Tcolumn('externalPackage', 'External Package', 'L', 25, true, [], true, '', 'B', [], undefined, undefined, undefined, undefined, undefined,actionExternalPackage, {meta: {visible:false}}));
    this.columns.set('externalPackageUnit', new Tcolumn('externalPackageUnit', '# of External Packages', 'N', 26, true, undefined, true, '', 'B', [Validators.required],undefined,undefined,undefined,undefined,undefined,actionExternalpackageUnit,{meta: {disabled: true},action:{runOnParentChange: false}}));
    this.columns.set('quantityPeriodicity', new Tcolumn('quantityPeriodicity', 'Quantity Periodicity', 'L', 24, true, [], true, 'Fixed', 'B', [], undefined, undefined, undefined, undefined, undefined, actionQuantityPeriodicity));
    this.columns.set('periodStartDate', new Tcolumn('periodStartDate', 'Period Start Date', 'D', 29, true, undefined, true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionStartPeriod));
    this.columns.set('periodEndDate', new Tcolumn('periodEndDate', 'Period End Date', 'D', 30, true, undefined, true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionEndDate));
    this.columns.set('deliverySchedule', new Tcolumn('deliverySchedule', 'View Delivery Schedule', 'LINK', 33, true, undefined, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined));
    this.columns.set('totalUnits', new Tcolumn('totalUnits', 'Total Units', 'N', 34, true, [], true, 1, 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('totalTradeQty', new Tcolumn('totalTradeQty', 'Total Quantity', 'N', 35, true, [], true, '', 'B', [Validators.min(0), ctrmValidators.decimalLengthValidator(10, 2), Validators.required], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('capacity', new Tcolumn('capacity', 'Capacity', 'N', 46, false, undefined, false, '', 'H'));
    // Section 4: Commodity Price Details
    this.columns.set('priceType', new Tcolumn('priceType', 'Price Type', 'L', 36, true, [], true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionPriceType));
    this.columns.set('futureIndex', new Tcolumn('futureIndex', 'Future Index', 'L', 37, true, [], true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionFutureIndex));
    this.columns.set('futureContractMonth', new Tcolumn('futureContractMonth', 'Future Contract Month', 'L', 38, true, [new KeyValue()], true, '', 'B', [Validators.required]));
    this.columns.set('tradePrice', new Tcolumn('tradePrice', 'Price', 'N', 39, true, undefined, true, '', 'B', [Validators.required, Validators.min(0)]));
    this.columns.set('tradePriceCurrency', new Tcolumn('tradePriceCurrency', 'Price Currency', 'L', 40, true, this.commonService.getCurrencyList(), true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionPriceCurrency));
    this.columns.set('tradePriceUom', new Tcolumn('tradePriceUom', 'Price Uom', 'L', 41, true, [], true, '', 'B', [Validators.required]));
    // Section 5: Payment and Settlement Details
    this.columns.set('tradeSettlementCurrency', new Tcolumn('tradeSettlementCurrency', 'Settlement Currency', 'L', 42, true, this.commonService.getCurrencyList(), true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionSettlementCurrency));
    this.columns.set('paymentterm', new Tcolumn('paymentterm', 'Payment Term', 'L', 43, true, [], true, '', 'B', [Validators.required]));
    this.columns.set('fxrate', new Tcolumn('fxrate', 'FX Rate', 'T', 44, true, undefined, true, new defaultValue('', undefined, ['.', '/']), 'B', []));
    this.columns.set('comments', new Tcolumn('comments', 'Comments', 'TA', 45, true, undefined, true, '', 'B', [], undefined, 3));

    this.getDataOnLoad();
    this.getUserInfoList();
  }

  getDataOnLoad() {
    let _this = this;
    _this.commonService.getJSONByURL(environment.base_url + '/api/trade/v1/loadconfigdata?tenantId=' + this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
      _this.populateValues(next);
    });
  }

  //populate values on load of the screen from next
  populateValues(next) {
    this.grid.getColumn().get('company').setListofValues(this.masterCommonService.getListFromArray(next['company']));
    this.grid.getColumn().get('broker').setListofValues(this.masterCommonService.getListFromArray(next['broker']));
    this.grid.getColumn().get('futureIndex').setListofValues(this.masterCommonService.getListFromArray(next['futureoptionsindex']));
    this.grid.getColumn().get('incoterm').setListofValues(this.masterCommonService.getListFromArray(next['incoterm']));
    this.grid.getColumn().get('paymentterm').setListofValues(this.masterCommonService.getListFromArray(next['paymentterm']));
    this.grid.getColumn().get('priceType').setListofValues(this.masterCommonService.getListFromArray(next['price_type_ind']));
    this.grid.getColumn().get('profitcenter').setListofValues(this.masterCommonService.getListFromArray(next['profitcenter']));
    this.grid.getColumn().get('internalProfitCenter').setListofValues(this.masterCommonService.getListFromArray(next['profitcenter']));
    this.grid.getColumn().get('quantityPeriodicity').setListofValues(this.masterCommonService.getListFromArray(next['quantity_periodicity_ind']));
    this.grid.getColumn().get('quantityUom').setListofValues(this.masterCommonService.getListFromArray(next['unitofmeasurement']));
    this.grid.getColumn().get('tradePriceUom').setListofValues(this.masterCommonService.getListFromArray(next['unitofmeasurement']));
    this.grid.getColumn().get('locationType').setListofValues(this.masterCommonService.getListFromArray(next['location_type_ind']));
    this.grid.getColumn().get('loadLocationType').setListofValues(this.masterCommonService.getListFromArray(next['location_type_ind']));
    this.grid.getColumn().get('unloadLocationType').setListofValues(this.masterCommonService.getListFromArray(next['location_type_ind']));
    this.grid.getColumn().get('weightBasis').setListofValues(this.masterCommonService.getListFromArray(next['weight_basis_ind']));
    this.grid.getColumn().get('modeOfTransport').setListofValues(this.masterCommonService.getListFromArray(next['mode_of_transport_ind']));
  }


  closeModal(value: any) {
    this.modalStateDeal = false;
    this.onSave.emit(value);
  }


  onDealSlipSave() {
    this.gridRefresh = true;
    this.modalStateDeal = false;
  }

  private getInternalPackageType() {
    return [new KeyValue('Bulk Break','Bulk'),new KeyValue('Container','Container'),new KeyValue('Unit','Unit')];
  }


  canceled() {
    this.modalStateDeal = false;
    this.closeEvent.emit(this.modalStateDeal);
  }


  getDateFormat(value: any, obj: any, isEndDate: boolean = false) {
    value = value.toLowerCase().replace(new RegExp(' ', 'g'), '');
    obj['meta'] = {disabled: false};
    if (value === 'monthly' || value === 'quarterly' || value === 'halfyearly') {
      obj['date'] = {view: 'month', format: environment.dateMonthFormat};
    } else if (value === 'yearly') {
      obj['date'] = {view: 'year', format: environment.dateYearFormat};
    } else {
      obj['date'] = {view: 'day'};
      // if(value === 'fixed' && isEndDate) {
      //   obj['meta'] = {disabled:true};
      // }
    }
    return obj;
  }

  calculateHeight() {
    let offsetTop = this.dealSlip.el.nativeElement['offsetTop'];
    let offsetHeight = this.dealSlip.el.nativeElement['offsetParent']['offsetHeight'];
    this.dealSlipHeight = offsetHeight - 217 + 'px';
  }

  private getBuySellList() {
    return this.masterCommonService.getBooleanFromLabel('Buy', 'Sell', false, 'BUY', 'SELL');
  }

  private getIntExt() {
    return [new KeyValue('Yes', true), new KeyValue('No', false)];
  }


   onClickLink(value:any) {
    this.formRowData = null;
    if(value['rowData']['packageType'] === 'Bulk') {
      if(value['rowData']['quantity'] !== undefined && value['rowData']['quantity'] !== null && value['rowData']['quantity'].length > 0 &&
          value['rowData']['quantityPeriodicity'] !== undefined && value['rowData']['quantityPeriodicity'] !== null &&
          value['rowData']['periodEndDate'] !== undefined && value['rowData']['periodEndDate'] !== null && value['rowData']['periodEndDate'] !== '' &&
          value['rowData']['periodStartDate'] !== undefined && value['rowData']['periodStartDate'] !== null && value['rowData']['periodStartDate'] !== '') {
        this.formRowData = value['rowData'];
        this.deliveryRows = value['rowData']['deliverySchedule'];
        this.scheduleForm = true;
      } else {
        this.showToast('Details required are missing','error');
      }
    } else if(value['rowData']['packageType'] === 'Container'){
      if(value['rowData']['externalPackageUnit'] !== undefined && value['rowData']['externalPackageUnit'] !== null && value['rowData']['externalPackageUnit'].length > 0 &&
          value['rowData']['externalPackage'] !== undefined && value['rowData']['externalPackage'] !== null &&
          value['rowData']['quantityPeriodicity'] !== undefined && value['rowData']['quantityPeriodicity'] !== null  &&
          value['rowData']['periodEndDate'] !== undefined && value['rowData']['periodEndDate'] !== null && value['rowData']['periodEndDate'] !== '' &&
          value['rowData']['periodStartDate'] !== undefined && value['rowData']['periodStartDate'] !== null && value['rowData']['periodStartDate'] !== '') {
        this.formRowData = value['rowData'];
        this.deliveryRows = value['rowData']['deliverySchedule'];
        this.scheduleForm = true;
      } else {
        this.showToast('Details required are missing','error');
      }
    } else if(value['rowData']['packageType'] === 'Unit'){
      if(value['rowData']['internalPackageUnit'] !== undefined && value['rowData']['internalPackageUnit'] !== null && value['rowData']['internalPackageUnit'].length > 0 &&
          value['rowData']['internalPackage'] !== undefined && value['rowData']['internalPackage'] !== null &&
          value['rowData']['quantityPeriodicity'] !== undefined && value['rowData']['quantityPeriodicity'] !== null &&
          value['rowData']['periodEndDate'] !== undefined && value['rowData']['periodEndDate'] !== null && value['rowData']['periodEndDate'] !== '' &&
          value['rowData']['periodStartDate'] !== undefined && value['rowData']['periodStartDate'] !== null && value['rowData']['periodStartDate'] !== '') {
        this.formRowData = value['rowData'];
        this.deliveryRows = value['rowData']['deliverySchedule'];

        this.scheduleForm = true;
      } else {
        this.showToast('Details required are missing','error');
      }
    }
  }

  onCloseDeliveryScheduleForm(value:any) {
    this.scheduleForm = false;
    this.scheduleRows = value['value'];
    let changedTotalQuantity = value['totalquantity'];
    let changedTotalUnit = value['totalUnit'];
    let control:FormControl = this.dealSlipForm['formGroup']['controls']['totalTradeQty'];
    control.setValue(changedTotalQuantity);
    let unitcontrol:FormControl = this.dealSlipForm['formGroup']['controls']['totalUnits'];
    unitcontrol.setValue(changedTotalUnit);
  }

  showToast(msg, severity: string = 'info') {
    this.messageService.add({
      severity: severity,
      summary: msg
    });
  }

  onFormClose() {
    this.scheduleForm = false;
  }
}



