import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {filter} from '../../masters/services/filter.model';
import {KeyValue} from '../../grid/key-value.model';
import {MasterService} from '../../masters/services/MasterService';
import {CommonService} from '../../services/common.service';
import {ConfirmationService, MessageService, SelectItem, SortEvent, Table} from 'primeng';
import {BreadcrumbService} from '../../Components/ctrm-breadcrumb/breadcrumb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {entities} from '../../services/common/entities';
import {DatePipe} from '@angular/common';
import {Grid} from '../../grid/grid.model';
import {TradeComponent} from '../../physicals/contract/trade/trade.component';
import {Tcolumn} from '../../grid/tcolumn.model';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {defaultValue} from '../../grid/defaultValue.model';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {Action} from "../../grid/actions.model";
import {OverlayPanel} from 'primeng/overlaypanel';
import {Filter} from '../../grid/preference/filter/filter.model';
import {AccessPolicyService} from "../../services/accesspolicy.service";

@Component({
    selector: 'app-trade-actualization',
    templateUrl: './trade-actualization.component.html',
    styleUrls: ['./trade-actualization.component.css'],
    providers: [ConfirmationService]
})
export class TradeActualizationComponent implements OnInit {

    planCols: any[];
    oblCols: any[];
    costCols: any[];
    listOptions: any[];
    qualityCols: any[];
    quantityCols: any[];
    planData: any[] = [];
    allocDetails: any[] = [];
    showBottomPane: boolean = false;
    selectedRowDataObj:any;
    selectedPlans: any[] = [];
    tabValue: string;
    warningMessage: any[] = [];
    errorMessage: any[] = [];
    actualizationDocId: any = '';
    tradeId: any = '';
    display: boolean = false;
    loadingPlanobligationForPlanid = '';
    planTableHeight: string = '';
    allocationDisplay: boolean = false;
    items: any[] = [];
    claimItems: any[] = [];
    formDisplay: boolean = false;
    quantityActualized: boolean = false;
    isClaim: boolean = false;
    bsConfig: Partial<BsDatepickerConfig>;
    currentSelectedRow: number = -1;
    allocatedObl: any[] = [];
    getObject = {};
    filterLocation: filter;
    costValue: any[] = [];
    warningMessageString: string = '';
    selectedCost: any[] = [];
    dualSliderObj: any;
    qualityValue: any[] = [];
    toolBarConfig: any = {
        add: true,
        edit: false,
        copy: true,
        delete: false,
        refresh: true,
        export: false,
        exportAll: false,
        import: false,
        audit: false,
        print: false,
        prefrences: true
    };
    selectedQuality: any[] = [];
    quantityValue: any[] = [];
    datePipe: DatePipe;
    lastSelected: any;
    selectedRowData: any;
    @ViewChild('planTable', {static: true}) planTable: Table;
    @ViewChild('quality', {static: true}) qualityTable: Table;
    @ViewChild('quantity', {static: true}) quantityTable: Table;
    @ViewChild('cost', {static: true}) costTable: Table;
    partiallyActualized: number = 0;
    actualized: number = 0;
    delStarted: number = 0;
    brand: any;
    grade: any;
    deliveryStartDate: any;
    deliveryEndDate: any;
    origin: any;
    port: any;
    divEl: HTMLDivElement;
    loadQuantity: any;
    unloadQuantity: any;
    purpose: any;
    tradeObject: any;
    locationList: KeyValue[] = [];
    tabOptions: SelectItem[];
    displayForm: boolean = false;
    isHeightCalculated: boolean = false;
    dummySelectedChild: any[] = [];
    seletedPlannedObl: string;
    expandedRows = {};
    noDataBoxLeft: any = '';
    refreshingQualitySpec: boolean = false;
    gridHeight: any = '300px';
    refreshingCost: boolean = false;
    costGrid: Grid;
    qualitySpecGrid: Grid;
    tradeComponent: any;
    addNew: boolean = false;
    currentGrid: Grid;
    isRefreshing: boolean = false;
    private filteredPlanId:any = '';
    displayPrefrences: boolean = false;
    currentTable: Table;
    selectedAttachment: any[] = [];
    attachmentCols: any;
    refreshingAttachment: boolean = false;
    gradeList: KeyValue[] = [];
    tooltipmessage: any = '';
    originList: KeyValue[] = [];
    brandList: KeyValue[] = [];
    addingInlineCost: boolean = false;
    attachmentGrid: Grid;
    docUrl: string;
    docName: any;
    showDoc: boolean = false;
    commodityName: string = '';
    tolerance: any = JSON.stringify({min: 0, max: 0});
    paymentRowData: any[] = [];
    warningVisible: boolean = false;
    checkBoxFunction: any;
    headerCheckBoxFunction: Function;
    plannedObligationId: any = '';
    oblRow: any;
    tradeInfoDisplay: boolean = false;
    selctedTradeId: any = '';
    canShowInternalExternalBlock:boolean = false;
    cashflow: any[] = [];
    private commodityCode: any;
    private isFiltered:boolean = false;
    private quantityUom: any;
    private plannedQuantity: any;
    private costActualized: boolean = false;
    private qualityActualized: boolean = false;
    qualitySpecData: any[] = [];
    costData: any[] =[];
    packageType:string = '';
    externalPackage:string = '';
    internalPackage:string = '';
    internalPackageUnit:number = 0.0;
    externalPackageUnit:number = 0.0;
    includedCharacters: any[] = ['_', '/', ' '];
    selectedCashflowData: any[];

    //BL MANAGEMENT
    bldisplay:boolean = false;
    blOutput: any;
    disableDeActualize:boolean = true;
    tradePricingLoadApiFunction:Function;
    overlayPanelTemp:OverlayPanel
    columns:Tcolumn[] =[];
    filters: Filter[] = [];
    currentPageNumber:number = 0;
    pageSize:number = 0;
    totalElements: number = 0;
    totalPages:number = 0;
    formMode:string = '';
    planedObligationId:string = '';
    gridFilters:any[] = [];
    refreshFilter: boolean = false;
    selectedRows:any[] = [];
    sortFunction: Function;
    grid: Grid;
    columnsMAP: Map<string, Tcolumn>;
    plannedObligationIdList:any[] = [];
    tradeIdList:any[] = [];
    planIdList:any[] = [];
    commodityList:any[] = [];
    counterpartyList:any[] = [];
    profitCenterList:any[] = [];
    loadLocationList:any[] = [];
    unloadLocationList:any[] = [];
    refreshGrid: boolean = false;
    quantityRoundOff:number=3;
    priceRoundOff:number=2;
    actualizeDisabled:boolean=true;
    deactualizeDisabled:boolean=true;
    claimDisabled:boolean=true;
    undoClaimDisabled:boolean=true;

    constructor(public masterCommonService: MasterService, private accessPolicyService : AccessPolicyService,private confirmationService: ConfirmationService, private formBuilder: FormBuilder, public commonService: CommonService, private messageService: MessageService, public breadCrumbService: BreadcrumbService, private router: Router, private route: ActivatedRoute) {
    }



    ngOnInit() {
        this.createGridFilters();
        this.getColumns();
        this.grid = new Grid(this.columnsMAP, true, true, true, true, 'tradeId', '/api/actualization/v2/find-obligations-for-actualization?tenantId='+ this.commonService.getFromStorage('tenantId'), undefined, 'Trade Actualization', undefined, undefined);
        this.grid.setWidth('5%');
        this.grid.setGridConfiguration(false, false, false, false, false, false, true, false, false, false);
        this.grid.gridFetchUrlType = "POST";
        this.grid.iconName = 'loadUnload';
        this.grid.gridAdvacedFilterURL =  '/api/actualization/v2/find-obligations-for-actualization-by-criteria?tenantId='+ this.commonService.getFromStorage('tenantId');
        this.grid.openSidebarOnUniqueClick = false;
        this.grid.openDefaultInputForm = false;
        this.grid.fetchPostPayloadFuction = function (value:any[] = []) {
            return _this.getCommonFilterPayload(true,true);
        };
        this.actualizeDisabled = this.accessPolicyService.canAccessWithCurrentRoute('Action','actualize')
        this.deactualizeDisabled= this.accessPolicyService.canAccessWithCurrentRoute('Action','deactualize')
        this.claimDisabled=this.accessPolicyService.canAccessWithCurrentRoute('Action','claim')
        this.undoClaimDisabled=this.accessPolicyService.canAccessWithCurrentRoute('Action','undoclaim')
        this.breadCrumbService.makeBreadcrumbTo(this.commonService.OPERATION_MAP_KEY, '/tradeActualization');
        this.dualSliderObj = {
            labelFormatLeft: '- <value> %',
            labelLowFormatLeft: '- <value> %',
            labelHighFormatLeft: '- <value> %',
            labelFormatRight: '<value> %',
            labelLowFormatRight: '<value> %',
            labelHighFormatRight: '<value> %',
            floorLeft: 0,
            floorRight: 0,
            ceilLeft: 100,
            ceilRight: 100
        };
        let _this = this;
        let tenantConfig=JSON.parse(_this.commonService.getFromStorage("tenantConfig"));
        if(tenantConfig && tenantConfig.roundingFormat){
            _this.quantityRoundOff=tenantConfig.roundingFormat.quantityRounding;
            _this.priceRoundOff=tenantConfig.roundingFormat.priceRounding;
        }
        else{
            _this.quantityRoundOff=3;
            _this.priceRoundOff=2;
        }
        this.checkBoxFunction = function(value: any) {
            return value['actualizedStatus'] === 'Actualized';
        };

        this.headerCheckBoxFunction = function(value: any[]) {
            let selectedValues: any[] = [];
            value.forEach( value => {
                if (value['actualizedStatus'] === 'Actualized'){
                    selectedValues.push(value);
                }
            })
            return selectedValues;
        };

        this.datePipe = new DatePipe('en-US');
        this.initSetters();
        this.getGrids();
        this.createLoadFunctions();
        this.route.queryParams.subscribe(params => {
            let keys = Object.keys(params);
            if(params !== undefined && params !== null && keys.length > 0) {
                let openForm = params['openForm'];
                _this.filteredPlanId = params['value'];
                _this.isFiltered = true;
                if(keys.includes("mode")) {
                    this.formMode = params["mode"];
                }
                if(keys.includes("plannedObligationId")) {
                    this.planedObligationId = params["plannedObligationId"];
                }
                _this.getPlansByFilters();
                if (openForm !== undefined && openForm !== null && openForm) {
                    let planId = [];
                    planId.push({planId: _this.filteredPlanId});
                    _this.selectedRowDataObj = planId[0];
                    _this.actualizeFormOpen();
                }
            }
        });
        this.sortFunction = function (event:SortEvent) {
            event.data.sort((data1, data2) => {
                let field = event.multiSortMeta[0].field;
                let order = event.multiSortMeta[0].order;
                let value1 = data1[field];
                let value2 = data2[field];
                let result = null;
                if (value1 == null && value2 != null) {
                    result = -1;
                } else if (value1 != null && value2 == null) {
                    result = 1;
                } else if (value1 == null && value2 == null){
                    result = 0;
                } else if (typeof value1 === 'string' && typeof value2 === 'string') {
                    result = value1.localeCompare(value2);
                } else {
                    result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
                }
                return (order * result);
            });
        };
    }

    checksettel() {
        if(this.selectedRowDataObj){
            return this.selectedRowDataObj.obligationState.SETTLED || !this.selectedRowDataObj.obligationState.ACTUALIZED ;
        }
    }

    private createGridFilters() {
        let payload = this.getCommonFilterPayload(true,true);
        let _this = this;
        this.resetFilters();
        this.commonService.getJSONByURL(environment.base_url+'/api/actualization/v2/find-filter-criteria-for-actualization').subscribe((next:any) => {
            // let uniquetradeTransactionType = next['tradeTransactionType'].filter((c, index) => {
            //     return next['tradeTransactionType'].indexOf(c) === index;
            // });
            _this.gridFilters = [];
            _this.gridFilters.push({title:'Obligation Id',list:_this.masterCommonService.getListFromArray(next['planned_obligation_id'],false,false)});
            _this.gridFilters.push({title:'Trade ID',list:_this.masterCommonService.getListFromArray(next['trade_id'],false,false)});
            _this.gridFilters.push({title:'Plan ID',list:_this.masterCommonService.getListFromArray(next['plan_id'],false,false)});
            _this.gridFilters.push({title:'Commodity',list:_this.masterCommonService.getListFromArray(next['commodity'],false,false)});
            _this.gridFilters.push({title:'Counter Party',list:_this.masterCommonService.getListFromArray(next['counterparty'],false,false)});
            _this.gridFilters.push({title:'Profitcenter',list:_this.masterCommonService.getListFromArray(next['profit_center'],false,false)});
            _this.gridFilters.push({title:'Load Port',list:_this.masterCommonService.getListFromArray(next['load_location'],false,false)});
            _this.gridFilters.push({title:'Discharge Port',list:_this.masterCommonService.getListFromArray(next['unload_location'],false,false)});
        });
    }
    private resetFilters() {
        this.plannedObligationIdList = [];
        this.tradeIdList = [];
        this.planIdList = [];
        this.commodityList = [];
        this.counterpartyList = [];
        this.profitCenterList = [];
        this.loadLocationList = [];
        this.unloadLocationList = [];
    }

    private getCommonFilterPayload(addCardInfo:boolean = false,addFilterInfo:boolean = false) {
        if(addFilterInfo) {
            let payload:any = [{key:'matchType',operation:'in',value:['BUILD','Back to Back','Blending','DRAW','Washout - DocByPass','Washout - String']},
                {key:'tradeType',operation:'notEquals',value:'InterCompany'},
                {key:'externalRecord',operation:'notEquals',value:true}
            ];
            this.plannedObligationIdList.length > 0 ? payload.push({key:'plannedObligationId',operation:'in',value:this.plannedObligationIdList}):null;
            this.tradeIdList.length > 0 ? payload.push({key:'tradeId',operation:'in',value:this.tradeIdList}):null;
            this.planIdList.length > 0 ? payload.push({key:'planId',operation:'in',value:this.planIdList}):null;
            this.commodityList.length > 0 ? payload.push({key:'commodity',operation:'in',value:this.commodityList}):null;
            this.counterpartyList.length > 0 ? payload.push({key:'counterparty',operation:'in',value:this.counterpartyList}):null;
            this.profitCenterList.length > 0 ? payload.push({key:'profitCenter',operation:'in',value:this.profitCenterList}):null;
            this.loadLocationList.length > 0 ? payload.push({key:'loadLocation',operation:'in',value:this.loadLocationList}):null;
            this.unloadLocationList.length > 0 ? payload.push({key:'unloadLocation',operation:'in',value:this.unloadLocationList}):null;
            return payload;
        }
    }


    private getColumns() {
        let _this = this;
        let df=this.quantityRoundOff;
        let tradeQuantityOutputFunc = function (value:any[] =[]) {
            return _this.commonService.formatNumberWithoutComma(value[0],df)+' '+value[1];
        };
        let actualizationStatusOutputFunc=function(value:any[]=[]){
            let actualizedCount = 0;
            let settledCount = 0;
            let actualizedState = value[0].ACTUALIZED;
            let settleState = value[0].SETTLED;
            if (actualizedState) {actualizedCount++; }
            if (settleState) {settledCount++;}
            if (settledCount == 0 && actualizedCount == 0) {
                return "Pending";
            } else {
                return "Actualized";
            }
        }

        this.columnsMAP = new Map();
        this.columnsMAP.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
        this.columnsMAP.set('plannedObligationId', new Tcolumn('plannedObligationId', 'Obligation ID', 'LB', 1, true, undefined, true, new defaultValue('', undefined, undefined, undefined, false, false, false),'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.set('tradeId', new Tcolumn('tradeId', 'Trade ID', 'LB', 2, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.set('planId', new Tcolumn('planId', 'Plan ID', 'LB', 3, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.set('status', new Tcolumn('status', 'Status', 'T', 15, false, undefined, true, new defaultValue('',undefined,undefined,undefined,false,true,false,actualizationStatusOutputFunc,['obligationState']), 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.set('priceAllocationStatus', new Tcolumn('priceAllocationStatus', 'Price Allocation Status', 'LB', 17, true, undefined, true, new defaultValue('', undefined, undefined, undefined, false, false, false),'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.set('tradeTransactionType', new Tcolumn('tradeTransactionType', 'Buy/Sell', 'OB', 4, true, this.masterCommonService.getBooleanFromLabel('Buy','Sell',false,'BUY','SELL'), true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.set('matchType', new Tcolumn('matchType', 'Match Type', 'CUSTOM', 5, true, undefined, true, '' , 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.set('tradeDateTime', new Tcolumn('tradeDateTime', 'Trade Date', 'D', 5, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.set('commodity', new Tcolumn('commodity', 'Commodity', 'LB', 6, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.set('counterparty', new Tcolumn('counterparty', 'Counter Party', 'LB', 7, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.set('plannedQuantity', new Tcolumn('plannedQuantity', 'Planned Quantity', 'N', 15, false, undefined, true, new defaultValue('',undefined,undefined,undefined,false,true,false,tradeQuantityOutputFunc,['plannedQuantity','quantityuom']), 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.set('deliveryStartDate', new Tcolumn('deliveryStartDate', 'Delivery Start Date', 'D', 9, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.set('deliveryEndDate', new Tcolumn('deliveryEndDate', 'Delivery End Date', 'D', 10, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.set('incoterm', new Tcolumn('incoterm', 'Incoterm', 'LB', 14, false,undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.set('profitCenter', new Tcolumn('profitCenter', 'Profit Center', 'LB', 8, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columnsMAP.get('matchType').metaFunctionForFilter = () => {
            return "T";
        }

    }

    onFilterChange(value: any) {
        this.refreshGrid = false;
        let type = value['title'];
        let selected = value['selected'];
        if(type === 'Obligation Id') {
            this.plannedObligationIdList = selected;
        }else if(type === 'Trade ID') {
            this.tradeIdList = selected;
        } else if(type === 'Plan ID') {
            this.planIdList = selected;
        }  else if(type === 'Commodity') {
            this.commodityList = selected;
        }  else if(type === 'Counter Party') {
            this.counterpartyList = selected;
        }  else if(type === 'Profitcenter') {
            this.profitCenterList = selected;
        } else if(type === 'Load Port') {
            this.loadLocationList = selected;
        } else if(type === 'Discharge Port') {
            this.unloadLocationList = selected;
        }
        this.refreshGrid = true;
    }
    getGrids() {
        let _this = this;
        let statusFunction = function(value: any[]) {
            let html: any;
            if (value[0] === 'Actualized') {
                html = _this.commonService.getSafeHtml('<div style="display: inline-flex">' +
                    '<img src="../../../assets/images/svg/purpleTick.svg" alt="" width="16px" height="16px">' +
                    '<p style="color:#000000;margin: 0;padding: 0;font-size: 14px;margin-left: 5px;white-space: nowrap !important;">Actualized</p></div>');
            } else {
                html = _this.commonService.getSafeHtml('<div style="display: inline-flex">' +
                    '<img src="../../../assets/images/svg/estimate.svg" alt="" width="16px" height="16px">' +
                    '<p style="color:#000000;margin: 0;padding: 0;font-size: 14px;margin-left: 5px;white-space: nowrap !important;">Estimate</p></div>');
            }
            return html;
        };
        this.tradeComponent = new TradeComponent(this.masterCommonService, this.commonService, this.messageService, this.route,null);
        this.tradeComponent.ngOnInit();

        this.costGrid = this.tradeComponent.getCost('','',false).getGrid();
        this.costGrid.setPrimaryKey('name');
        this.costGrid.getColumn().get('quantityOption').setDefaultValue('scheduleQty');
        this.costGrid.getColumn().get('quantityOption').setExtra({meta: {disabled: true}});
        this.costGrid.getColumn().set('actualizedStatus', new Tcolumn('actualizedStatus', 'Actualized Status', 'LB', 3, true, undefined, true, new defaultValue('Estimate', undefined, undefined, undefined, false, false, false, statusFunction)));
        this.costGrid.getColumn().set('actualizedCostId', new Tcolumn('actualizedCostId', 'Actualized Cost Id', 'LB', 3, false, undefined, false, ''));
        this.costGrid.openSidebarOnUniqueClick = false;
        this.costGrid.getColumn().get('costId').setVisibility(false);
        this.costGrid.addNewRowInline = true;
        this.costGrid.actionFunc.newCopyValueFunc = function (value) {
            let obj:any = JSON.parse(JSON.stringify(value));
            obj['actualizedStatus'] = "Estimate";
            obj['actualizedCostId'] = "";
            obj['uuid'] = "";
            return obj;
        }

        this.qualitySpecGrid = this.tradeComponent.getQualitySpecification(false,false).getGrid();
        this.qualitySpecGrid.getColumn().set('actualizedQualityId', new Tcolumn('actualizedQualityId', 'Actualized Quality Id', 'LB', 3, true, undefined, false, ''));
        this.qualitySpecGrid.getColumn().set('actualizedStatus', new Tcolumn('actualizedStatus', 'Actualized Status', 'LB', 3, true, undefined, true, new defaultValue('Estimate', undefined, undefined, undefined, false, false, false, statusFunction)));
        this.qualitySpecGrid.getColumn().get('name').setEditable(true);
        this.qualitySpecGrid.getColumn().get('description').setEditable(true);
        this.qualitySpecGrid.openSidebarOnUniqueClick = false;
        this.qualitySpecGrid.addNewRowInline = true;
        this.qualitySpecGrid.actionFunc.newCopyValueFunc = function (value) {
            let obj:any = JSON.parse(JSON.stringify(value));
            obj['actualizedStatus'] = "Estimate";
            obj['actualizedQualityId'] = "";
            obj['uuid'] = "";
            return obj;
        }

        this.attachmentGrid = this.tradeComponent.getAttachment().getGrid();
        this.attachmentGrid.setSaveUrl(environment.base_url + '/api/actualization/v1/saveDocument?tenantId=' +this.commonService.getFromStorage('tenantId'));
        this.attachmentGrid.setDataURL('');
        let updateAttachmentName = function updatename(value: any = []) {
            let file = value[0];
            return file['name'];
        };
        let updateAttachmentType = function updatetype(value: any = []) {
            let file = value[0];
            return file['type'];
        };

        let updateFileSize = function updatetype(value: any = []) {
            let file: File = value[0];
            return _this.commonService.fileSizeConvert(file.size, true);
        };
        let action: Action = new Action();
        action.addFieldToUpdate("attachmentFileName", '', updateAttachmentName, undefined, undefined, false);
        action.addFieldToUpdate("attachmentFileType", '', updateAttachmentType, undefined, undefined, false);
        action.addFieldToUpdate("fileSize", '', updateFileSize, undefined, undefined, false);

        this.attachmentGrid.getColumn().set('actualizationId', new Tcolumn('actualizationId', 'Actualization Id', 'LB', 20, false, undefined, false, ''));
        this.attachmentGrid.getColumn().set('plannedObligationId', new Tcolumn('plannedObligationId', 'Planned Obligation Id', 'LB', 21, false, undefined, false, ''));
        this.attachmentGrid.getColumn().set('attachment', new Tcolumn('attachment', 'Attachment', 'F', 2, true, undefined, true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, action, {
                downloadUrl: {
                    url: environment.base_url,
                    para: ['attachmentUrl']
                }
            }
        ));
        this.attachmentGrid.getColumn().set('attachmentFileName', new Tcolumn('attachmentFileName', 'File Name', 'LB', 4, false, undefined, false, '', 'G'));
        this.attachmentGrid.getColumn().set('attachmentFileType', new Tcolumn('attachmentFileType', 'File Type', 'LB', 5, false, undefined, false, '', 'G'));
        this.attachmentGrid.addNewRowInline = true;
    }

    getList(object: any[], key, value) {
        let list: KeyValue[] = [new KeyValue('Select', '')];
        object.forEach(function(obj) {
            list.push(new KeyValue(obj[key], obj[value]));
        });
        return list;
    }

    //TODO: check this code name dependency
    getCommodityChildList(commodityName) {
        let _this = this;
        this.commonService.getJSONByURL(environment.base_url + '/api/commodity/v1/getBrandGradeOrigin?tenantId='+this.commonService.getTenantId() + '&commodityName=' + commodityName).subscribe((next) => {
            _this.commodityName = next['commodity']['name'];
            _this.brandList = _this.getList(next['brands'], 'brandName', 'brandName');
            _this.gradeList = _this.getList(next['grades'], 'gradeName', 'gradeName');
            _this.originList = _this.getList(next['origin'], 'originName', 'originName');
        });
    }

    // To get All the Plans and nest all the respective Obligation Objects in each Plan
    public getPlans() {
        this.isRefreshing = true;
        this.planData = [];
        this.partiallyActualized = 0;
        this.actualized = 0;
        this.delStarted = 0;
        let _this = this;
        this.commonService.getJSONByURL(environment.base_url + '/api/actualization/v1/getPlans?tenantId=' + this.commonService.getFromStorage('tenantId')+'&page='+this.currentPageNumber+'&size=20').subscribe((next: any) => {
            next.content.forEach((data,index)=>{
                       if (data['status'] === 'PARTIALLY ACTUALIZED') {
                            _this.partiallyActualized++;
                        } else if (data['status'] === 'ACTUALIZED') {
                            _this.actualized++;
                        } else if (data['status'] === 'SETTLED') {
                            _this.delStarted++;
                        }
                     data['selectedChild'] = [];
                     data['index'] = index;
                    _this.expandedRows[data['planId']] = false;
                    _this.planData.push(data);

            })
            _this.pageSize = next['pageable']['pageSize'];
            _this.totalElements = next['totalElements'];
            _this.totalPages = next['totalPages'];
            _this.isRefreshing = false;
            // _this.calculateHeight();
        });
    }

    public getPlansByFilters() {
        this.isRefreshing = true;
        this.planData = [];
        this.partiallyActualized = 0;
        this.actualized = 0;
        this.delStarted = 0;
        let _this = this;
        this.commonService.getJSONByURL(environment.base_url + '/api/actualization/v1/getPlanByPlanId?tenantId=' + this.commonService.getFromStorage('tenantId')+'&planId='+this.filteredPlanId).subscribe((next: any) => {
            next.forEach((data, index) => {
                if (data['status'] === 'PARTIALLY ACTUALIZED') {
                    _this.partiallyActualized++;
                } else if (data['status'] === 'ACTUALIZED') {
                    _this.actualized++;
                } else if (data['status'] === 'SETTLED') {
                    _this.delStarted++;
                }
                data['selectedChild'] = [];
                data['index'] = index;
                _this.expandedRows[data['planId']] = false;
                _this.planData.push(data);
            });
            _this.isRefreshing = false;
            // _this.calculateHeight();
        });
    }

    onClose(value: any) {
        this.tradeId = '';
        this.display = false;
    }

    planRefresh() {
        this.selectedRowDataObj = [];
        this.selectedRowDataObj = -1;
        this.refreshGrid = true;
    }

    getClassForHeading(index) {
        return index > 0 ? 'headerNormal' : 'noPipe';
    }

    goTo() {
        this.router.navigate(['/tradeAllocation']);
    }

    expandRow(rowData) {
        this.allocationDisplay = false;
        if (this.lastSelected !== undefined) {
            this.planData[this.lastSelected['data']['index']]['selectedChild'] = [];
        }
        if (this.lastSelected !== undefined && this.lastSelected !== null) {
            this.expandedRows[this.lastSelected['data']['planId']] = false;
            this.planTable.selectionKeys = {};
            this.planTable.selectionKeys[rowData['data']['planId']] = 1;
            if (this.planTable.selection.length > 1) {
                this.planTable.selection.splice(0, 1);
            }
        }
        this.currentSelectedRow = rowData['data']['index'];
        this.expandedRows[rowData['data']['planId']] = true;
        this.lastSelected = rowData;
        this.onExpandRow(rowData);
        if(this.selectedPlans !== null && this.selectedPlans !== undefined && this.selectedPlans.length === 1) {
            this.showBottomPane = true;
        } else {
            this.showBottomPane = false;
        }
    }


    onChildSelected(value: any[], rowData) {
        let _this = this;
        this.selectedRowDataObj = value[0];
        this.cashflow = [];
        this.showBottomPane = true;
        if (this.selectedRowDataObj !== undefined){
            let cashflowCriteria:any[] = [];
            cashflowCriteria.push({fieldName:'tenantId',condition:'equals',value:this.commonService.getFromStorage('tenantId')});
            cashflowCriteria.push({fieldName:'plannedObligationId',condition:'equals',value:this.selectedRowDataObj['plannedObligationId']});
            cashflowCriteria.push({fieldName:'quantityStatus',condition:'equals',value:'Actual'});
            cashflowCriteria.push({fieldName:'stage',condition:'in',value:['Accrued','Invoice Final','Accrued Provisional']});
            cashflowCriteria.push({fieldName:'type',condition:'in',value:['Trade','Trade Reversal','Provisional','Trade Debit Note','Trade Credit Note']});
            cashflowCriteria.push({fieldName:'cashflowStatus',condition:'equals',value:'active'});
            _this.commonService.post(environment.base_url+'/api/cashflow/v1/getcashflowbycriteria',cashflowCriteria).subscribe((next:any[]) => {
                _this.selectedCashflowData = next;
                if(next !== null && next !== undefined && next.length >0) {
                    _this.cashflow.push(next[0]);
                }
            });
        }else{
            _this.allocationDisplay = false;
            this.showBottomPane = false;
            _this.refreshGrid= false;
        }
    }

    clearChild(rowData) {
        this.showBottomPane = false;
        this.selectedRowDataObj = null;
        this.planData[rowData['data']['index']]['selectedChild'] = [];
        if (this.selectedPlans.length === 1) {
            this.allocationDisplay = true;
        } else {
            this.allocationDisplay = false;
        }
        this.expandedRows[rowData['data']['planId']] = false;
    }

    calculateHeight() {
        let offsetTop = this.planTable.el.nativeElement['offsetTop'];
        let offsetHeight = this.planTable.el.nativeElement['offsetParent']['offsetHeight'];
        this.planTableHeight = (offsetHeight - offsetTop) - 37 + 'px';
        if (this.divEl !== undefined && this.divEl !== null) {
            this.calculateGridHeight(this.divEl);
        }
    }

    openForm(tabValue) {
        this.isClaim = false;
        if (this.planData[this.currentSelectedRow]['selectedChild'].length < 2 && this.planData[this.currentSelectedRow]['selectedChild'].length !== 0) {
            this.selectedRowData = this.planData[this.currentSelectedRow]['selectedChild'][0];
            this.tabValue = tabValue;
            this.paymentRowData = [];
            this.seletedPlannedObl = this.planData[this.currentSelectedRow]['selectedChild'][0]['plannedObligationId'];
            this.formDisplay = !this.formDisplay;
            this.warningMessageString = '';
            this.allocationDisplay = false;
            this.getDetails();
        } else {
            this.messageService.add({
                severity: 'info',
                summary: 'Please select any one obligation'
            });
        }
    }

    openClainForm(tabValue) {
        this.isClaim = true;

        if (this.planData[this.currentSelectedRow]['selectedChild'].length < 2 && this.planData[this.currentSelectedRow]['selectedChild'].length !== 0) {
            this.selectedRowData = this.planData[this.currentSelectedRow]['selectedChild'][0];
            this.tabValue = tabValue;
            this.paymentRowData = [];
            this.seletedPlannedObl = this.planData[this.currentSelectedRow]['selectedChild'][0]['plannedObligationId'];
            this.formDisplay = !this.formDisplay;
            this.warningMessageString = '';
            this.allocationDisplay = false;
            this.getDetails();
        } else {
            this.messageService.add({
                severity: 'info',
                summary: 'Please select any one obligation'
            });
        }
    }

    onFormCancel() {
        this.isHeightCalculated = false;
        this.formDisplay = false;
        this.quantityActualized = false;
        this.costValue = [];
        this.planData[this.currentSelectedRow]['selectedChild'] = [];
        this.qualityValue = [];
        this.quantityValue = [];
        this.selectedCost = [];
        this.selectedQuality = [];
        this.allocationDisplay = false;
        this.allocatedObl = [];
        this.getObject = {};
    }

    checkPass() {
        this.warningMessage = [];
        this.errorMessage = [];
        let passed: boolean = false;
        let tolerance = JSON.parse(this.tradeObject['toleranceValue']);
        let plannedQuantity = this.selectedRowData['plannedQuantity'];
        let min = tolerance['min'];
        let max = tolerance['max'];
        let minimumTolerance = 0;
        let maximumTolerance = 0;
        if (this.tradeObject['quantityToleranceType'] === 'weight') {
            minimumTolerance = (plannedQuantity - min);
            maximumTolerance = (plannedQuantity + max);
        } else {
            minimumTolerance = (plannedQuantity - ((min / 100) * plannedQuantity));
            maximumTolerance = (plannedQuantity + ((max / 100) * plannedQuantity));
        }
        if (this.loadQuantity >= this.unloadQuantity && this.loadQuantity >= minimumTolerance && this.loadQuantity <= maximumTolerance && this.unloadQuantity >= minimumTolerance && this.unloadQuantity <= maximumTolerance) {
            passed = true;
        } else {
            passed = false;
            if (this.loadQuantity < this.unloadQuantity) {
                this.errorMessage.push('Load Quantity can never be less than unload Quantity.');
            }
            if (this.loadQuantity < minimumTolerance) {
                this.warningMessage.push('Load Quantity is below from minimum tolerance (Minimum Tolerance : ' + minimumTolerance + ').');
            } else if (this.loadQuantity > maximumTolerance) {
                this.warningMessage.push('Load Quantity is above from maximum tolerance (Maximum Tolerance : ' + maximumTolerance + ').');
            }
            if (this.unloadQuantity < minimumTolerance) {
                this.warningMessage.push('Unload Quantity is below from minimum tolerance (Minimum Tolerance : ' + minimumTolerance + ').');
            } else if (this.unloadQuantity > maximumTolerance) {
                this.warningMessage.push('Unload Quantity is above from maximum tolerance (Maximum Tolerance : ' + maximumTolerance + ').');
            }
        }
        return passed;
    }

    onActualize(tabValue) {
        if (tabValue === 'quantity') {
            let url = '';
            if (this.isClaim) {
                url = environment.base_url + '/api/actualization/v1/claimquantity?tenantId=' + this.commonService.getTenantId();
            } else {
                url = environment.base_url + '/api/actualization/v1/actualizeQuantity?tenantId='+this.commonService.getTenantId();
            }
            if (this.checkPass()) {
                this.actualizeQuantity(url);
            } else {
                this.warningVisible = true;
            }
        } else if (tabValue === 'cost') {
            let url = '';
            if (this.isClaim) {
                url = environment.base_url + '/api/actualization/v1/claimcost?tenantId=' + this.commonService.getTenantId();
            } else {
                url = environment.base_url + '/api/actualization/v1/actualizeCost?tenantId=' + this.commonService.getTenantId();
            }
            this.actualizeCost(url);
        } else if (tabValue === 'quality') {
            this.actualizeQuality();
        }
    }


    doneActualization() {
        let payloadObj = {
            plannedObligation: this.planData[this.currentSelectedRow]['selectedChild'][0],
            costs: this.selectedCost,
            qualitySpecs: this.selectedQuality,
            quantity: {
                loadQuantity: this.loadQuantity,
                unloadQuantity: this.unloadQuantity,
                brand: this.brand,
                grade: this.grade,
                origin: this.origin,
                commodity: this.commodityName,
                quantityUom: this.quantityUom,
                plannedQuantity: this.plannedQuantity
            }
        };
        this.commonService.post(environment.base_url + '/api/actualization/v1/actualize', payloadObj).subscribe(next => {
            this.showToast('Actualization Successful for Planned Obligation Id: ' + this.seletedPlannedObl);
            this.formDisplay = false;
            this.planRefresh();
        });
    }



    showToast(msg, severity: string = 'success') {
        this.messageService.add({
            severity: severity,
            summary: msg
        });
    }

    deActualize() {
        let _this = this;
        let payload = [];
        let canDeactualize: boolean = false;
        this.cashflow.forEach((cashflow) => {
           if(['Accrued','Accrued Provisional'].includes(cashflow['stage']) && ((cashflow['invoiceStatus'] === null || cashflow['invoiceStatus'] === ''))){
               canDeactualize = true;
               return;
           }
        });
        payload.push(this.selectedRowDataObj);
        if(canDeactualize) {
            this.commonService.post(environment.base_url + '/api/actualization/v1/deactualize?tenantId='+this.commonService.getTenantId(), payload).subscribe(next => {
                _this.showToast('De - Actualization of ' + this.selectedRowDataObj.plannedObligationId + ' is successful');
                this.showBottomPane = false;
                this.refreshGrid= true;
            });
        } else {
            this.showToast('Invoicing is done for Given Planned Obligation. Failed to De-Actualize.','error');
        }
    }



    getCost() {
        this.refreshingCost = true;
        this.costGrid.getColumn().get('tradeId').setDefaultValue(this.selectedRowData['tradeId']);
        this.commonService.getJSONByURL(environment.base_url + '/api/actualization/v1/getCost?plannedObligationId=' + this.selectedRowData['plannedObligationId']+'&tenantId='+ this.commonService.getTenantId()).subscribe((data: any) => {
            this.costValue = data;
            this.refreshingCost = false;
            this.getCostOnLoad()
        });
    }

    getCostOnLoad() {
        let _this = this;
        _this.commonService.getLoadData('commodity', ['data','data','data','data','data','data','data','config','config','config'],
            [[{key: 'name', value: 'cost_detail_name_ind'}],
                [{key: 'name', value: 'cost_detail_type_ind'}],
                [{key: 'name', value: 'cost_group_ind'}],
                [{key: 'name', value: 'cost_type_ind'}],
                [{key: 'name', value: 'quantity_option_ind'}],
                [{key: 'name', value: 'percentage_component_ind'}],
                [{key: 'name', value: 'cost_payment_type_ind'}],'counterparty','paymentterm','unitofmeasurement']).subscribe((next: any) => {
            _this.costData = next;
            _this.costGrid.getColumn().get('name').setListofValues(_this.masterCommonService.getListFromArray(next['cost_detail_name_ind']));
            _this.costGrid.getColumn().get('type').setListofValues(_this.masterCommonService.getListFromArray(next['cost_detail_type_ind']));
            _this.costGrid.getColumn().get('costGroup').setListofValues(_this.masterCommonService.getListFromArray(next['cost_group_ind']));
            _this.costGrid.getColumn().get('costType').setListofValues(_this.masterCommonService.getListFromArray(next['cost_type_ind']));
            _this.costGrid.getColumn().get('quantityOption').setListofValues(_this.masterCommonService.getListFromArray(next['quantity_option_ind']));
            _this.costGrid.getColumn().get('percentageComponent').setListofValues(_this.masterCommonService.getListFromArray(next['percentage_component_ind']));
            _this.costGrid.getColumn().get('paymentType').setListofValues(_this.masterCommonService.getListFromArray(next['cost_payment_type_ind']));
            _this.costGrid.getColumn().get('counterparty').setListofValues(_this.masterCommonService.getListFromArray(next['counterparty']));
            _this.costGrid.getColumn().get('paymentTerm').setListofValues(_this.masterCommonService.getListFromArray(next['paymentterm']));
            _this.costGrid.getColumn().get('uom').setListofValues(_this.masterCommonService.getListFromArray(next['unitofmeasurement']));
        });
    }

    getQuantity() {
        let _this = this;
        this.canShowInternalExternalBlock = false;
        this.commonService.getJSONByURL(environment.base_url + '/api/actualization/v1/getactualizedqtybyplannedobligationid?plannedObligationId=' + this.selectedRowData['plannedObligationId'] + '&tenantId=' + this.commonService.getTenantId()).subscribe((quantity: any) => {
            _this.brand = quantity['brand'];
            _this.grade = quantity['grade'];
            _this.origin = quantity['origin'];
            _this.deliveryStartDate = quantity['deliveryStartDate'];
            _this.deliveryEndDate = quantity['deliveryEndDate'];
            _this.plannedQuantity = quantity['plannedQuantity'];
            _this.loadQuantity = quantity['loadQuantity'];
            _this.unloadQuantity = quantity['unloadQuantity'];
            _this.commodityName = quantity['commodity'];
            _this.quantityUom = quantity['quantityUom'];
            _this.tolerance = quantity['tolerance'];
            _this.paymentRowData = quantity['actualizationEventMapping'];
            _this.packageType = quantity['packageType'];
            _this.externalPackage = quantity['externalPackage'];
            _this.externalPackageUnit = quantity['externalPackageUnit'];
            _this.internalPackage = quantity['internalPackage'];
            _this.internalPackageUnit = quantity['internalPackageUnit'];
            _this.purpose = quantity['purpose'];
            if(_this.packageType !== 'Bulk') {
                _this.canShowInternalExternalBlock = true;
            }
        });
    }

    getQuality() {
        let _this = this;
        this.refreshingQualitySpec = true;
        this.qualitySpecGrid.getColumn().get('tradeId').setDefaultValue(this.selectedRowData['tradeId']);
        this.commonService.getJSONByURL(environment.base_url + '/api/actualization/v1/getQuality?plannedObligationId=' + this.selectedRowData['plannedObligationId']+'&tenantId='+this.commonService.getTenantId()).subscribe((data: any) => {
            this.qualityValue = data;
            this.refreshingQualitySpec = false;
            this.loadquality();
        });
    }

    loadquality(){
        this.commonService.getLoadData('commodity', ['data'], [[{key: 'name', value: 'quality_parameter_ind'}]]).subscribe((next: any) => {
            this.qualitySpecData = next;
            this.qualitySpecGrid.getColumn().get('name').setListofValues(this.masterCommonService.getListFromArray(next['quality_parameter_ind']));
        });
    }

    getPreviousValues(plannedObligationId) {
        let costs: any[] = [];
        let quality: any[] = [];
        let quantity: any = {};
        let _this = this;
        this.paymentRowData = [];
        this.commonService.getJSONByURL(environment.base_url + '/api/actualization/v1/getAllActualized?plannedObligationId=' + plannedObligationId).subscribe((next: any) => {
            costs = next['costs'];
            quality = next['qualitySpecs'];
            quantity = next['quantity'];
            if (quantity !== undefined && quantity !== null) {
                _this.brand = quantity['brand'];
                _this.grade = quantity['grade'];
                _this.origin = quantity['origin'];
                _this.plannedQuantity = quantity['plannedQuantity'];
                _this.loadQuantity = quantity['loadQuantity'];
                _this.unloadQuantity = quantity['unloadQuantity'];
                //TODO: Check code name dependency
                _this.commodityName = quantity['commodity'];
                _this.quantityUom = quantity['quantityUom'];
                _this.paymentRowData = _this.createPaymentRowData(quantity['actualizationEventMapping']);
            }
        });
    }

    createPaymentRowData(data) {
        let rowData: any[] = [];
        Object.keys(data).forEach(function(key) {
            rowData.push({eventType: key, date: data[key]});
        });
        return rowData;
    }

    getAttachements() {
        this.attachmentGrid.getColumn().get('tradeId').setDefaultValue(this.selectedRowData['tradeId']);
        this.attachmentGrid.getColumn().get('plannedObligationId').setDefaultValue(this.selectedRowData['plannedObligationId']);
        this.attachmentGrid.setDataURL('/api/actualization/v1/getallattacheddoc?tenantId='+this.commonService.getFromStorage('tenantId')+'&plannedObligationId=' + this.selectedRowData['plannedObligationId']);
        this.refreshingAttachment = true;
    }

    // TO GET THE COST, QUALITY and QUANTITY Details of selected Obligation
    getDetails() {
        let _this = this;
        this.selectedRowData = this.planData[this.currentSelectedRow]['selectedChild'][0];
        this.commonService.getJSONByURL(environment.base_url + '/api/trade/v1/getphysicaltradebyuuid?tenantId=' +
            this.commonService.getTenantId() + '&uuid=' + this.selectedRowData['tradeUuid']).subscribe((next:any) =>{
            _this.tradeObject = next;
            _this.getOtherDetails();
        });
        // let filterTrade = new filter(environment.base_url + '/filter', entities.trade);
        // filterTrade.addCriteria('tradeId', this.selectedRowData['tradeId']);
        // let _this = this;
        // this.commonService.post(filterTrade.getUrl(), filterTrade).subscribe((next) => {
        //     _this.tradeObject = next[0];
        //     _this.getOtherDetails();
        // });
    }

    getOtherDetails() {
        this.getCommodityChildList(this.selectedRowData['commodity']);
        this.quantityValue = this.planData[this.currentSelectedRow]['selectedChild'];
        this.getQuantity();
        this.getCost();
        this.getQuality();
        this.getAttachements();
        // if(this.selectedRowData['obligationState']['ACTUALIZED']) {
        //   this.getPreviousValues(this.selectedRowData['plannedObligationId']);
        // }
    }

    initSetters() {
        let _this = this;
        this.tabOptions = [
            {label: 'Quantity', value: 'quantity', icon: 'fas fa-th-large'},
            {label: 'Cost', value: 'cost', icon: 'fas fa-dollar-sign'},
            {label: 'Quality', value: 'quality', icon: 'far fa-star'},
            {label: 'Document Upload', value: 'documents', icon: 'far fa-file'},
        ];
        this.listOptions = [
            {
                field: 'eventType',
                header: 'Payment Event Type',
                type: 'list',
                list:  this.commonService.getLoadData('commodity',['data'], [[{key:'name', value:'event_type_ind'}]]).subscribe( (next:any) => {
                    return next;
                })
            },
            {field: 'date', header: 'Date', type: 'date'},
        ];
        this.tabValue = 'quantity';
        this.bsConfig = new BsDatepickerConfig();
        this.bsConfig.dateInputFormat = (environment.dateFormat).toUpperCase();
        this.bsConfig.adaptivePosition = true;
        this.items = [
            {
                label: 'Cost', icon: 'fas fa-dollar-sign', command: () => {
                    this.openForm('cost');
                }
            },
            {
                label: 'Quality', icon: 'fas fa-th-large', command: () => {
                    this.openForm('quality');
                }
            },
            {
                label: 'Quantity', icon: 'fas fa-star', command: () => {
                    this.openForm('quantity');
                }
            }
        ];
        this.claimItems = [
            {
                label: 'Cost', icon: 'fas fa-dollar-sign', command: () => {
                    this.openClainForm('cost');
                }
            },
            {
                label: 'Quality', icon: 'fas fa-th-large', command: () => {
                    this.openClainForm('quality');
                }
            },
            {
                label: 'Quantity', icon: 'fas fa-star', command: () => {
                    this.openClainForm('quantity');
                }
            }
        ];
        let planType = function(value) {
            if (value['matchType'] === 'BUILD') {
                return 'Stock - Build';
            } else if (value['matchType'] === 'DRAW') {
                return 'Stock - Draw';
            } else {
                return value['matchType'];
            }
        };
        this.planCols = [
            {field: 'planId', header: 'Plan Id'},
            {field: 'buyPlannedQuantity', header: 'Buy Planned Qty'},
            {field: 'sellPlannedQuantity', header: 'Sell Planned Qty'},
            {field: 'matchType', header: 'Planned Matching', valueFunction: planType},
            {field: 'status', header: 'Status'}
        ];
        let statusList:KeyValue[] = [new KeyValue()];
        statusList.push(new KeyValue('Planned','PENDING'));
        statusList.push(new KeyValue('Partially Actualized','PARTIALLY ACTUALIZED'));
        statusList.push(new KeyValue('Settled','SETTLED'));
        statusList.push(new KeyValue('Actualized','ACTUALIZED'));

        this.columns = [];
        this.columns.push(new Tcolumn('planId','Plan Id','T',1));
        this.columns.push(new Tcolumn('buyPlannedQuantity','Buy Planned Quantity','N',1));
        this.columns.push(new Tcolumn('sellPlannedQuantity','Sell Planned Quantity','N',1));
        this.columns.push(new Tcolumn('matchType','Planned Matching','T',1));
        this.columns.push(new Tcolumn('status','Status','OB',1,true,statusList));

        this.allocDetails = [
            {field: 'shippingFreightAllocationId', header: 'Shipment Id'},
            {field: 'vesselName', header: 'Vessel Name'},
            {field: 'vesselFlag', header: 'Vessel Flag'},
            {field: 'imo', header: 'IMO Number'},
            {field: 'city', header: 'City'},
            {field: 'age', header: 'Age'},
            {field: 'loadPort', header: 'Load Port'},
            {field: 'loadRate', header: 'Load Rate'},
            {field: 'laycan', header: 'Laycan'},
            {field: 'dischargePort', header: 'Discharge Port'},
            {field: 'dischargeRate', header: 'Discharge Rate'},
            {field: 'eta', header: 'ETA'},
        ];
        let viewStatusFunction = function(value: any) {
             if(value['obligationState']['ACTUALIZED'] === true) {
                 return 'Actualized';
            }
            return 'Pending Actualization';
        };
        let viewQuantityFunction = function(value: any) {
            return _this.commonService.getFormatedNumber(value['plannedQuantity']) + ' ' + value['quantityUOM'];
        };
        let viewPriceFunction = function(value: any) {
            return _this.commonService.getFormatedNumber(value['tradePrice']) + ' ' + value['tradePriceCurrency'] + '/' + value['tradePriceUom'];
        };
        let viewPlannedQuantityFunction = function(value: any) {
            return _this.commonService.getFormatedNumber(value['plannedQuantity']) + ' ' + value['uomName'];
        };
        this.oblCols = [
            {field: 'plannedObligationId', header: 'Planned Obligation Id', primaryKey: true},
            {field: 'tradeId', header: 'Trade Id'},
            {field: 'priceAllocationStatus', header: 'Price Allocation Status'},
            {field: 'tradeTransactionType', header: 'Trade Type'},
            {field: 'tradeDateTime', header: 'Trade Date', type: 'date'},
            {field: 'commodity', header: 'Commodity'},
            {field: 'counterparty', header: 'Counterparty'},
            {field:'status',header:'Status',valueFunction:viewStatusFunction},
            {field: 'plannedQuantity', header: 'Planned Quantity', valueFunction: viewQuantityFunction},
            {field: 'deliveryStartDate', header: 'Delivery Start Date', type: 'date'},
            {field: 'deliveryEndDate', header: 'Delivery End Date', type: 'date'},
            {field: 'incoterm', header: 'Incoterm'},
            {field: 'location', header: 'Location'},
            {field: 'cropOrigin', header: 'Origin'},
            {field: 'grade', header: 'Grade'},
            {field: 'broker', header: 'Broker'},
            {field: 'priceType', header: 'Price Type'},
            {field: 'tradePrice', header: 'Trade Price', valueFunction: viewPriceFunction},
            {field: 'profitcenter', header: 'Profit Center'},
            {field: 'obligationState', header: 'Status', valueFunction: viewStatusFunction}
        ];

        this.qualityCols = [
            //TODO: remove code
            {field: 'name', header: 'Parameter Name', inlineEditable: true},
            {field: 'description', header: 'Description', inlineEditable: true},
            {field: 'unit', header: 'Unit', inlineEditable: true},
            {field: 'actualizedValue', header: 'Actualized Value', editable: true}
        ];

        this.quantityCols = [
            {field: 'commodity', header: 'Commodity'},
            {field: 'plannedQuantity', header: 'Quantity'},
            {field: 'quantityUom', header: 'Quantity UOM'},
            {field: 'loadQuantity', header: 'Load Quantity', editable: true},
            {field: 'unloadQuantity', header: 'Unload Quantity', editable: true},
            {field: 'grade', header: 'Grade', editable: true},
            {field: 'brand', header: 'Brand', editable: true},
            {field: 'port', header: 'Port', editable: true},
            {field: 'origin', header: 'Origin', editable: true},
        ];

        this.costCols = [{field: 'tradeId', header: 'Trade Id'},
            {field: 'name', header: 'Name', inlineEditable: true},
            {field: 'type', header: 'Type', inlineEditable: true},
            {field: 'counterparty', header: 'Counterparty', inlineEditable: true},
            {field: 'costGroup', header: 'Cost Group', inlineEditable: true},
            {field: 'costType', header: 'Cost Type', inlineEditable: true},
            {field: 'costValue', header: 'Cost Value', editable: true, fieldType: 'number'},
            {field: 'costCurrency', header: 'Currency', inlineEditable: true},
            {field: 'uom', header: 'uom', inlineEditable: true},
            {field: 'quantityOption', header: 'Qty Option', inlineEditable: true},
            {field: 'percentageComponent', header: '% Component', inlineEditable: true},
            {field: 'description', header: 'Description', inlineEditable: true},
            {field: 'inputDate', header: 'Input Date', fieldType: 'date', inlineEditable: true},
            {field: 'effectiveDate', header: 'Effective Date', fieldType: 'date', inlineEditable: true},
            {field: 'maturityDate', header: 'Maturity Date', editable: true, fieldType: 'date', inlineEditable: true},
            {field: 'paymentType', header: 'Payment Type', inlineEditable: true},
            {field: 'paymentTerm', header: 'Payment Term', inlineEditable: true},
            {field: 'paymentDueDate', header: 'Payment Due Date', fieldType: 'date', inlineEditable: true},
            {field: 'percentagePayable', header: 'Percentage Payable', inlineEditable: true},
            {field: 'additionalNote', header: 'Additional Note', editable: true, fieldType: 'text', inlineEditable: true}
        ];

        this.attachmentCols = [
            {field: 'tradeId', header: 'Trade Id'},
            {field: 'attachment', header: 'Attachment', fieldType: 'filePicker'},
            {field: 'uploadedBy', header: 'Uploaded By'},
            {field: 'uploadedDate', header: 'Uploaded Date'},
            {field: 'view', header: 'View', fieldType: 'button'},
        ];
    }

    getOutPutValue(rowDatum: any, fieldType: string) {
        if (fieldType === 'date') {
            return this.datePipe.transform(rowDatum, environment.dateFormat);
        } else {
            return rowDatum;
        }
    }



    getValue(rowData) {
        return rowData['obligations'];
    }


    isDisable() {
        return false;
    }

    onCancel() {
        this.addNew = false;
    }

    onApply(value: any, gridTitle) {
        this.addNew = false;
        if (gridTitle === 'Quality Specification') {
            this.qualityValue.splice(0, 0, value['data']);
        } else {
            this.costValue.splice(0, 0, value['data']);
        }

    }

    changeDisplay(value: any) {
        this.displayPrefrences = false;
    }

    reloadData(value: any, currentTable: Table) {
        this.currentGrid.setFilters(value.filters);
        this.commonService.applyFilters(currentTable, this.currentGrid, value.filters);
        this.commonService.applySorting(value.sort, currentTable, this.currentGrid.getPrimaryKey());
    }

    openPrefrences(table: Table, grid: Grid) {
        this.currentGrid = grid;
        this.currentTable = table;
        this.displayPrefrences = true;
    }

    getQuantityValue(code) {
        return this.commonService.getFormatedNumber(this.selectedRowData[code]);
    }


    updateSelectedRows(rows: any[], screen: string) {
        let _this = this;
        this.warningMessageString = '';
        if (screen === 'cost') {
            this.selectedCost = rows;
            _this.costActualized = false;
        } else if (screen === 'qualitySpec') {
            this.selectedQuality = rows;
        }
    }

    onSaveCost(value: any) {
        let oldData = JSON.parse(JSON.stringify(this.costValue));
        this.costValue = [];
        this.selectedCost = [];
        if (value['mode'] === 'save') {
            oldData.splice(0, 0, value['data']);
        } else if (value['mode'] === 'update') {
            oldData.splice(value['index'], 1);
            oldData.splice(value['index'], 0, value['data']);
        }
        this.costActualized = false;
        this.costValue = oldData;
    }

    onSaveQuality(value: any) {
        let oldData = [...this.qualityValue];
        this.qualityValue = [];
        this.selectedQuality = [];
        if (value['mode'] === 'save') {
            oldData.splice(0, 0, value['data']);
        } else if (value['mode'] === 'update') {
            oldData.splice(value['index'], 1);
            oldData.splice(value['index'], 0, value['data']);
        }
        this.qualityActualized = false;
        this.qualityValue = oldData;
    }

    onButtonClick(value: any) {
        if (value['screenName'] === 'Attachment') {
            let name = value['rowData']['attachmentFileName'];
            let url = environment.base_url + value['rowData']['attachmentUrl'];
            if (value['buttonHeader'] === 'Download') {
                this.commonService.downloadFile(url,name);
            } else if (value['buttonHeader'] === 'Print') {
                let printurl = 'https://docs.google.com/viewerng/viewer?url=' + url;
                window.open(printurl, '_blank');
            } else {
                this.docUrl = url;
                this.docName = name;
                this.showDoc = true;
            }
        }
    }

    onDocClose(value: any) {
        this.docUrl = '';
        this.showDoc = false;
    }

    calculateGridHeight(divElement: HTMLDivElement) {
        this.gridHeight = 350 + 'px';
        this.divEl = divElement;
    }

    getFormat() {
        return environment.dateFormat;
    }

    getToleranceValue() {
        let value = '';
        if (this.tradeObject !== undefined && this.tradeObject !== null && this.tradeObject['toleranceValue'] !== undefined && this.tradeObject['toleranceValue'] !== null) {
            let tolerance = JSON.parse(this.tradeObject['toleranceValue']);
            let symbol = '%';
            if (this.tradeObject['quantityToleranceType'] === 'weight') {
                symbol = this.tradeObject['quantityUom'];
            }
            if (tolerance !== undefined && tolerance !== null) {
                value = '-' + tolerance['min'] + ' ' + symbol + ' - +' + tolerance['max'] + ' ' + symbol;
            }
        }
        return value;
    }

    onContinue() {
        this.warningVisible = false;
        this.actualizeQuantity(environment.base_url + '/api/actualization/v1/actualizeQuantity?tenantId='+this.commonService.getTenantId());
    }

    onCloseWarning() {
        this.warningVisible = false;
    }

    getButtonName(tabValue: string) {
        if (tabValue === 'cost') {
            if (this.isClaim) {
                return 'Claim Cost';
            }
            return 'Actualize Cost';
        } else if (tabValue === 'quantity') {
            if (this.isClaim) {
                return 'Claim Quantity';
            }
            return 'Actualize Quantity';
        } else if (tabValue === 'quality') {
            return 'Actualize Quality Spec';
        } else {
            return 'Actualize';
        }
    }

    setTabValue(tabValue) {
        this.tabValue = tabValue;
    }

    isDisabled(tabValue) {
        if (tabValue === 'documents') {
            return true;
        } else if (tabValue === 'quantity') {
            if (this.isClaim) {
                // if(this.selectedCashflowData[0] != undefined || this.selectedCashflowData[0] != null)
                // {
                //   if(((this.selectedCashflowData[0]['invoiceStatus']==='invoice generated') ||
                //       (this.selectedCashflowData[0]['invoiceStatus']==='Approved')||
                //       (this.selectedCashflowData[0]['invoiceStatus']==='Settled'))&&(this.selectedCashflowData[0]['type']==='Trade')) {
                //       return false;
                //    }
                //   return true;
                // }
                //return true;

                let qtyButton = this.selectedCashflowData.map(data=>{
                    if(data!= undefined || data!= null ){
                        if(((data.invoiceStatus==='invoice generated')||(data.invoiceStatus==='Approved')||(data.invoiceStatus==='Settled'))&&(data.type==='Trade')) {
                            return  false;
                        }
                        if((data.invoiceStatus==='')&&(data.type==='Trade Reversal')){
                            return  true;
                        }
                        return true;
                    }

                    })


                if(qtyButton.length==0){
                    return true;
                }else
                return qtyButton[0];
            } else {
                if ((this.selectedRowData['obligationState']['ACTUALIZED'] == true) || (this.selectedRowData['obligationState']['SETTLED'] == true)) {
                    return true;
                }
                return this.quantityActualized;
            }

        } else if (tabValue === 'cost') {
            return this.selectedCost.length === 0 || (this.warningMessageString !== undefined && this.warningMessageString !== null && this.warningMessageString.length > 0);
        } else if (tabValue === 'quality') {
            return this.selectedQuality.length === 0;
        }
    }

    actualizeQuantity(url) {
        let payloadObj = {
            plannedObligation: this.selectedRowData,
            qualitySpecs: [],
            costs: [],
            quantity: {
                loadQuantity: this.loadQuantity,
                unloadQuantity: this.unloadQuantity,
                brand: this.brand,
                grade: this.grade,
                origin: this.origin,
                commodity: this.commodityName,
                quantityUom: this.quantityUom,
                plannedQuantity: this.plannedQuantity,
                packageType: this.packageType,
                externalPackage:this.externalPackage,
                internalPackage:this.internalPackage,
                externalPackageUnit:this.externalPackageUnit,
                internalPackageUnit:this.internalPackageUnit,
                purpose:this.purpose,
                actualizationEventMapping: this.paymentRowData
            }
        };
        this.commonService.post(url, payloadObj).subscribe(next => {
            if (this.isClaim) {
                this.showToast('Quantity Claimed Successful for Planned Obligation Id: ' + this.seletedPlannedObl);
            } else {
                this.showToast('Quantity Actualization Successful for Planned Obligation Id: ' + this.seletedPlannedObl);
            }
            this.isClaim = false;
            this.formDisplay = false;
            this.planRefresh();
        }, (error: HttpErrorResponse) => {
            this.showToast('Error : ' + error.error['status'], 'error');
        });
    }

    onRefreshComplete() {
        this.refreshingAttachment = false;
    }
    onRefreshGridComplete(){
        this.refreshGrid= false;
    }

    onGetRowData(data: any[]) {
        if (data.length > 0) {
            this.attachmentGrid.getColumn().get('actualizationId').setDefaultValue(data[0]['actualizationId']);
        }
    }

    cancelForm() {
        this.planData[this.currentSelectedRow]['selectedChild'] = [];
        this.allocationDisplay = false;
    }

    onExpandRow(value: any) {
        let _this = this;
        this.loadingPlanobligationForPlanid = value['data']['planId'];
        this.commonService.getJSONByURL(environment.base_url + '/api/actualization/v1/getPlanObligations?planId=' +
            value['data']['planId'] + '&tenantId=' + this.commonService.getFromStorage('tenantId')).subscribe((next: any[]) => {
            value['data']['obligations'] = next;
            _this.loadingPlanobligationForPlanid = '';
        });
    }

    onCloseTradeInfo(value) {
        this.tradeInfoDisplay = false;
    }

    onUniqueClick(value: any) {
        this.plannedObligationId = value['rowData']['plannedObligationId'];
        this.selctedTradeId = value['rowData']['tradeId'];
        this.tradeInfoDisplay = true;
        this.oblRow = value;
    }

    onClickable(value: any) {
        if (value['fieldName'] === 'tradeId') {
            this.plannedObligationId = value['rowData']['plannedObligationId'];
            this.selctedTradeId = value['rowData']['tradeId'];
            this.oblRow = value['rowData'];
        }
    }

    private updateCostRows() {
        this.selectedCost.forEach(function (row:any) {
            row['effectiveDate'] = new Date(row['effectiveDate']);
            row['inputDate'] = new Date(row['inputDate']);
            row['maturityDate'] = new Date(row['maturityDate']);
            row['paymentDueDate'] = new Date(row['paymentDueDate']);
        });
    }

    private actualizeCost(url) {
        this.updateCostRows();
        let payloadObj = {
            plannedObligation: this.selectedRowData,
            costs: this.selectedCost,
            qualitySpecs: [],
            quantity: {}
        };
        this.commonService.post(url, payloadObj).subscribe(next => {
            if (this.isClaim) {
                this.showToast('Claimed cost Successfully for Planned Obligation Id: ' + this.seletedPlannedObl);
            } else {
                this.showToast('Cost Actualization Successful for Planned Obligation Id: ' + this.seletedPlannedObl);
            }
            this.isClaim = false;
            this.formDisplay = false;
            this.planRefresh();
        }, (error: HttpErrorResponse) => {
            this.showToast('Error : ' + error['error']['status'], 'error');
        });
    }

    private actualizeQuality() {
        let payloadObj = {
            plannedObligation: this.selectedRowData,
            qualitySpecs: this.selectedQuality,
            costs: [],
            quantity: {}
        };
        this.commonService.post(environment.base_url + '/api/actualization/v1/actualizeQuality?tenantId=' + this.commonService.getTenantId(), payloadObj).subscribe(next => {
            this.showToast('Quality Actualization Successful for Planned Obligation Id: ' + this.seletedPlannedObl);
            this.formDisplay = false;
            this.planRefresh();
        }, (error: HttpErrorResponse) => {
            this.showToast('Error : ' + error.status + ' ' + error.message, 'error');
        });
    }

    deActualizeCheck() {
        this.tooltipmessage = '';
        let disableValue: boolean = true;
        for (let i=0; i < this.cashflow.length; i++){
                if (this.cashflow[i]['stage'] === 'Accrued' && (this.cashflow[i]['invoiceStatus'] === null || this.cashflow[i]['invoiceStatus'].length === 0)) {
                    disableValue = false;
                    break;
                } else {
                    disableValue = true;
                    this.tooltipmessage = 'Invoicing is done for this planned obligation. Not able to Deactulize'
                }
        }
        return  disableValue;
    }

    isEmpty(value: string) {
        if(value === undefined || value === null || value.length <= 0) {
            return true;
        }
        return false;
    }

    public updateInternalExternalQuantity(from:string) {
        let canSend = true;
        let _this = this;
        let calculatedFrom = from;
        if(this.packageType === undefined || this.packageType === null || this.packageType.length === 0 || this.packageType === 'Bulk') {
            canSend = false;
        }
        if(this.commodityName === undefined || this.commodityName === null || this.commodityName.length === 0) {
            canSend = false;
        }
        if(this.quantityUom === undefined || this.quantityUom === null || this.quantityUom.length === 0) {
            canSend = false;
        }
        if(this.packageType === 'Container' && (this.internalPackage === undefined || this.internalPackage === null || this.internalPackage.length === 0|| this.internalPackageUnit === undefined || this.internalPackageUnit === null || this.internalPackageUnit === 0)) {
            canSend = false;
        }
        if(canSend) {
            let payload = {};
            payload['quantity']= this.loadQuantity;
            payload['packageType'] = this.packageType;
            payload['calculateFrom'] = calculatedFrom;
            payload['internalPackageUnit']= this.internalPackageUnit;
            payload['externalPackageUnit']= this.externalPackageUnit;
            payload['commodity']= this.commodityName;
            payload['quantityUom']= this.selectedRowData['quantityUOM'];
            payload['externalPackageName']= this.externalPackage;
            payload['internalPackageName']= this.internalPackage;
            this.commonService.post(environment.base_url + '/api/externalpackage/v1/quantityandunitconversion?tenantId='+this.commonService.getFromStorage('tenantId'),payload).subscribe((next) => {
                _this.loadQuantity = next['quantity'];
                _this.unloadQuantity = next['quantity'];
            },(error:HttpErrorResponse) =>{
                _this.showToast(_this.commonService.getHttpErrorMessage(error,'Actualization'),'error');
            });
        }
    }

    onClickBl() {

        this.bldisplay = true;
        this.selectedRowData = this.planData[this.currentSelectedRow]['selectedChild'][0];
        this.blOutput = this.selectedRowData;
        this.allocationDisplay = false;
        this.planData[this.currentSelectedRow]['selectedChild'] = [];
    }

    onCloseBlForm() {
        this.bldisplay = false;
    }

    onClosebottomPane() {
        this.showBottomPane = false;
        this.selectedPlans =[];
    }

    onCloseForm() {
        this.displayForm = false;
        this.selectedPlans = [];
        this.planRefresh();
    }

    actualizeFormOpen() {
        if(this.selectedRowDataObj.allocationType ==='Substitute'){
            this.showToast('You have Allocated Substitute Vessel for the Plan,\n  Please Allocate Voyage to Actualize','error');
            return;
        }
        this.showBottomPane = false;
        this.displayForm = true;
    }

    private createLoadFunctions() {
        let _this = this;
        this.tradePricingLoadApiFunction = function (value) {
            let iconName = value['iconName'];
            let obj = {};
            if(iconName !== 'Not Priced' && iconName !== 'Provisional Not Priced' ) {
                obj['type'] = "GET";
                obj['url'] = environment.base_url+'/api/manualPricingService/v1/get-trade-by-tradeid-v2?tenantId='+ _this.commonService.getFromStorage('tenantId')+'&tradeId='+value['rowData']['tradeId'];
                obj['payload'] = {};
            }
            return obj;
        }
    }
    priceAllocationiconPath(rowData){
        if(rowData.priceType=='Basis'|| rowData.priceType=='PTBF') {
            if ((rowData.pricingStatus === 'Not Priced' && rowData.priceAllocationstatus === 'Not Allocated')) {
                return "Not Priced";
            }
            else if(rowData.priceAllocationstatus==='Not Allocated'){
              return "Not Allocated";
            }
            else if(rowData.priceAllocationstatus==='Partially Allocated'){
              return "Partially Allocated";
            }
            else{
              return "Fully Allocated";
            }
            return rowData.priceAllocationstatus;
        }
        else
            return "Fixed Priced";
    }
    onHover(panel: OverlayPanel){
        if(this.overlayPanelTemp !== null && this.overlayPanelTemp !== undefined) {
            this.overlayPanelTemp.hide();
        }
        this.overlayPanelTemp = panel;
    }

    //To open Price Allocation Tab
    openPriceAllocationTab(tradeId:any =''){
        this.router.navigate(['/priceAllocation'],{queryParams:{value:tradeId,buttonName:'Price Allocation'}});
    }

    //To show Price Allocation Details
    openPriceAllocationInfo(tradeId:any =''){
        this.router.navigate(['/priceAllocation'],{queryParams:{value:tradeId,buttonName:'See All PriceAllocation Details'}});
    }

    onButtonClicks(value: any){
        let buttonName = value['name'];
        let iconName = value['iconName'];
        let rowData = value['rowData'];
        let dbData = value['dbData'];
        if(buttonName==='Price Allocation'){
            this.openPriceAllocationTab(rowData['tradeId']);
        }
        if(buttonName==='See All PriceAllocation Details'){
            this.openPriceAllocationInfo(rowData['tradeId']) ;
        }
        if(buttonName === 'Request for Trade Pricing') {
            let _this = this;
            this.commonService.sendNotfication('Price Fixation Pending for Trade Id : '+rowData['tradeId']).subscribe((next) => {
                _this.showToast('Notification Sent Successfuly');
            },(error:HttpErrorResponse) => {
                _this.showToast('Failed to send Notification.','error');
            });
        }
    }

    onApplyFilter(value: Filter[],resetInfo:boolean = true){
        let _this = this;
        if (!this.isFiltered) {
            this.isRefreshing = true;
            this.planData = [];
            if(resetInfo) {
                this.currentPageNumber = 0;
            }
            let payload = this.commonService.getFilterApiPayload(value);
            if (payload.length > 1) {
                this.filters = value;
                let url =  environment.base_url+'/api/actualization/v1/getactualizationbycriteria?tenantId='+this.commonService.getTenantId()+'&page='+this.currentPageNumber+'&size=20'
                this.commonService.post(url, payload).subscribe((next: any) => {
                    _this.isRefreshing = false;
                    _this.planData = next['content'];
                    _this.pageSize = next['pageable']['pageSize'];
                    _this.totalElements = next['totalElements'];
                    _this.totalPages = next['totalPages'];
                    // _this.calculateHeight();
                }, (error: HttpErrorResponse) => {
                    _this.isRefreshing = false;
                    _this.showToast(_this.commonService.getHttpErrorMessage(error, 'Trade Actualization'), 'error');
                });
            } else {
                this.filters = [];
                this.getPlans();
            }
        }
    }
    onPageChange(value: any) {
        this.currentPageNumber = value['page'];
        if(this.filters !== null && this.filters !== undefined && this.filters.length > 0) {
            this.onApplyFilter(this.filters,false);
        } else {
            this.getPlans();
        }
    }

    getPagination() {
        return this.totalElements > this.pageSize;
    }

    getPlannedStatus(rowData: any) {
        if(rowData['obligationState']['ACTUALIZED'] === true) {
            return 'Actualized';
        }
        return 'Pending Actualization';
    }

    getIconForStatus(rowData: any) {
        if (rowData['matchType'] === 'Back to Back' && rowData['status'] === 'Delivery Started') {
            return '../../../assets/images/svg/truck.svg';
        } else if ((rowData['matchType'] === 'Washout' && rowData['status'] === 'Delivery Started') ||
            (rowData['matchType'] === 'Washout' || rowData['matchType'] === 'Back to Back') && rowData['status'] === 'Planned') {
            return '../../../assets/images/svg/event.svg';
        } else if ((rowData['matchType'] === 'Washout' || rowData['matchType'] === 'Back to Back') && rowData['status'] === 'Settled') {
            return '../../../assets/images/svg/truck.svg';
        } else {
            return '../../../assets/images/svg/event.svg';
        }
    }

    getClassForStatus(rowData: any) {
        if (rowData['matchType'] === 'Back to Back' && rowData['status'] === 'Delivery Started') {
            return 'statusDS';
        } else if ((rowData['matchType'] === 'Washout' && rowData['status'] === 'Delivery Started') ||
            (rowData['matchType'] === 'Washout' || rowData['matchType'] === 'Back to Back') && rowData['status'] === 'Planned') {
            return 'statusPlanned';
        } else if ((rowData['matchType'] === 'Washout' || rowData['matchType'] === 'Back to Back') && rowData['status'] === 'Settled') {
            return 'statusDS';
        } else {
            return 'statusPlanned';
        }
    }
}
