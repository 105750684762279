<p-table #mainTable [columns]="cols" [value]="value" dataKey="profitCenter">
  <ng-template let-columns pTemplate="header">
    <tr>

      <th style="width: 3em">
      </th>
      <th></th>
      <th *ngFor="let col of columns;index as i">
      </th>
    </tr>
  </ng-template>
  <ng-template let-columns="columns" let-expanded="expanded" let-ri="rowIndex" let-rowData pTemplate="body">

    <tr>
      <td style="width: 3em">
      </td>
      <td></td>
      <td *ngFor="let col of columns" class="customRow">
        <p *ngIf="col.field !== 'commodity' && !col.curr && !col.qty" class="plannedGrid">{{getRowDataValue(rowData,col)}}</p>
        <p *ngIf="col.field === 'commodity'" class="plannedGrid leftBrdr">{{getCommaUpdatedValue(rowData[col.field])}}</p>
        <p *ngIf="col.curr" class="plannedGrid">{{getCurrency(rowData,col)}}</p>
        <p *ngIf="col.qty" class="plannedGrid">{{getUom(rowData,col)}}</p>
      </td>
    </tr>

  </ng-template>
</p-table>
