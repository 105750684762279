import {Injectable} from '@angular/core';
import {Tcolumn} from '../../tcolumn.model';
import {Filter} from "./filter.model";
import {CommonService} from '../../../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  lastScreen: String = '';
  currentScreen: String = '';
  private _tempfilters: any[] = [];
  private _tempfilterListadded: any[] = [];
  private _tempconditionList: any[] = [];
  private _tempnumberList: any[] = [];
  private _tempfirstList: any[] = [];
  private _tempsecondList: any[] = [];

  constructor(private commonSerivce:CommonService) {
  }

  private _filterList: any[] = [];

  get filterList(): any[] {
    return this._filterList;
  }

  set filterList(value: any[]) {
    this._filterList = value;
  }

  private _filterListadded: any[] = [];

  get filterListadded(): any[] {
    return this._filterListadded;
  }

  set filterListadded(value: any[]) {
    this._filterListadded = value;
  }

  private _maxFilters: number = 0;

  get maxFilters(): number {
    return this._maxFilters;
  }

  set maxFilters(value: number) {
    this._maxFilters = value;
  }

  private _allfilters: any[] = [];

  get allfilters(): any[] {
    return this._allfilters;
  }

  set allfilters(value: any[]) {
    this._allfilters = value;
  }

  private _conditionList: any[] = [];

  get conditionList(): any[] {
    return this._conditionList;
  }

  set conditionList(value: any[]) {
    this._conditionList = value;
  }

  private _numberList: any[] = [];

  get numberList(): any[] {
    return this._numberList;
  }

  set numberList(value: any[]) {
    this._numberList = value;
  }

  private _firstList: any[] = [];

  get firstList(): any[] {
    return this._firstList;
  }

  set firstList(value: any[]) {
    this._firstList = value;
  }

  private _secondList: any[] = [];

  get secondList(): any[] {
    return this._secondList;
  }

  set secondList(value: any[]) {
    this._secondList = value;
  }

  private _fieldlists: any[] = [];

  get fieldlists(): any[] {
    return this._fieldlists;
  }

  set fieldlists(value: any[]) {
    this._fieldlists = value;
  }

  private _allcolumns: any[] = [];

  get allcolumns(): any[] {
    return this._allcolumns;
  }

  set allcolumns(value: any[]) {
    this._allcolumns = value;
  }

  private _columns: Tcolumn[] = [];

  get columns(): Tcolumn[] {
    return this._columns;
  }

  set columns(value: Tcolumn[]) {
    this._columns = value;
  }

  private _issaved: boolean = false;

  get issaved(): boolean {
    return this._issaved;
  }

  set issaved(value: boolean) {
    this._issaved = value;
  }

  private _tempfilterList: any[] = [];

  get tempfilterList(): any[] {
    return this._tempfilterList;
  }

  set tempfilterList(value: any[]) {
    this._tempfilterList = value;
  }

  private _exculedTypes: any[] = ['TB', 'F'];

  get exculedTypes(): any[] {
    return this._exculedTypes;
  }

  set exculedTypes(value: any[]) {
    this._exculedTypes = value;
  }

  private _options: any[] = [
    {label: 'Equals', value: 'equals', type: ['N', 'T','EL','ML', 'LB', 'TA']},
    {label: 'Not Equals', value: 'notequals', type: ['N', 'T','EL','ML', 'LB', 'TA']},
    {label: 'Less than', value: 'lessthan', type: ['N']},
    // {label: 'Less than equals', value: 'lessthanequals', type: ['N', 'D']},
    {label: 'Greater than', value: 'greaterthan', type: ['N']},
    // {label: 'Greater than equals', value: 'greaterthanequals', type: ['N', 'D']},
    // {label: 'Between', value: 'between', type: ['N']},
    {label: 'Contains', value: 'contains', type: ['T','EL','ML', 'LB', 'TA']},
    // {label: 'Start With', value: 'startWith', type: ['T', 'LB', 'TA']},
    // {label: 'End With', value: 'endWith', type: ['T', 'LB', 'TA']},
    {label: 'is', value: 'equals', type: ['L', 'B', 'OB']},
    {label: 'is not', value: 'notequals', type: ['L','B', 'OB']},
    {label: 'Equals', value: 'equalsdate', type: ['D']},
    {label: 'Not Equals', value: 'notequalsdate', type: ['D']},
    {label: 'Less than', value: 'datelessthanequal', type: ['D']},
    {label: 'Greater than', value: 'dategreaterthanequal', type: ['D']}
  ];

  get options(): any[] {
    return this._options;
  }

  set options(value: any[]) {
    this._options = value;
  }

  getConditionSuggestionList(type = 'T' ||'EL') {
    var list = [];
    this.options.forEach(function (option) {
      if (option['type'].includes(type)) {
        list.push(option);
      }
    });
    return list;
  }

  getSelectedCondition(columnCode) {
    var cond = '';
    this.allfilters.forEach(function (filter) {
      if (filter['columnName'] === columnCode) {
        cond = filter['condition'];
      }
    });
    return cond;
  }

  getValues(columnCode) {
    var cond = '';
    this.allfilters.forEach(function (filter) {
      if (filter['columnName'] === columnCode) {
        cond = filter['filterValue'];
      }
    });
    return cond;
  }

  getConditionLabel(condition,columnType) {
    var cond = '';
    if(condition !== null && condition !== undefined) {
      this.options.forEach(function (filter) {
        if (filter !== null && filter !== undefined && filter['value'] === condition && filter['type'].includes(columnType)) {
          cond = filter['label'];
        }
      });
    }
    return cond;
  }

  applyFilter() {
    this._allfilters = [...this._tempfilters];
    this._filterList = [...this._tempfilterList];
    this._filterListadded = [...this._tempfilterListadded];
    this._conditionList = [...this._tempconditionList];
    this._numberList = [...this._tempnumberList];
    this._firstList = [...this._tempfirstList];
    this._secondList = [...this._tempsecondList];
  }

  reset() {
    this.filterList = [];
    this.filterListadded = [];
    this.maxFilters = 0;
    this.allfilters = [];
    this._tempfilters = [];
    this.conditionList = [];
    this.numberList = [];
    this.firstList = [];
    this.secondList = [];
    this.fieldlists = [];
    this.allcolumns = [];
    this.columns = [];
  }

  resetPrevious() {
    this._tempfilters = [...this.allfilters];
    this._tempfilterList = [...this.filterList];
    this._tempfilterListadded = [...this.filterListadded];
    this._tempconditionList = [...this.conditionList];
    this._tempnumberList = [...this.numberList];
    this._tempfirstList = [...this.firstList];
    this._tempsecondList = [...this.secondList];
  }

  setCurrentScreen(screen) {
    if (screen !== this.lastScreen) {
      this.reset();
    }
  }

  getOptions(type) {
    var obj = [];
    this._options.forEach(function (data) {
      if (data['type'] === 'all' || data['type'].includes(type)) {
        obj.push(data);
      }
    });
    return obj;
  }

  getOptionObj(condition:string,type) {
    var obj = null;
    this._options.forEach(function (data) {
      if (data['type'] === 'all' || data['type'].includes(type)) {
        if (data['value'] === condition) {
          obj = data;
        }
      }
    });
    return obj;
  }

  createObject(header, columnName, condition, firstValue, secondValue: any = '', columnType = 'T') {
    var obj = {
      header: header,
      columnName: columnName,
      condition: condition,
      filterValue: {firstValue: firstValue, secondValue: secondValue},
      type: columnType
    };
    return obj;
  }

  createAddFilterObject(label) {
    var column = this.getTcolumnbyHeader(label);
    var obj = {column: column, label: column.getHeader(), value: column.getField()};
    return obj;
  }

  createAddFilterObjectwithColumn(column: Tcolumn) {
    var obj = {column: column, label: column.getHeader(), value: column.getField()};
    return obj;
  }

  getTcolumnbyHeader(header): Tcolumn {
    var object;
    this.columns.forEach(function (column) {
      if (column.getHeader() === header) {
        object = column;
      }
    });
    return object;
  }

  addfilter(value: any) {
    if (this._tempfilterList.length < this._maxFilters || this._maxFilters === -1) {
      var column = value['column'];
      var label = value['label'];
      var value1 = value['value'];
      if (value1 !== 'select') {
        if (!this._tempfilterListadded.includes(value1)) {
          this._tempfilterListadded.push(value1);
          this._tempfilterList.push({label: label, value: value1, column: column, name: value['name']});
        }
      }
    }
  }

  removeFilter(f: any, i: number) {
    this._tempfilterListadded.splice(i, 1);
    this._tempfilterList.splice(i, 1);
    var this1 = this;
    this._tempfilters.forEach(function (child, i) {
      if (f['value'] === child['columnName']) {
        this1._tempfilters.splice(i, 1);
      }
    });
    this._tempconditionList.forEach(function (cond, i) {
      if (cond['label'] === f['value']) {
        this1._tempconditionList.splice(i, 1);
      }
    });
  }

  pushInAllFilters(object) {
    var found = false;
    var this1 = this;
    this._tempfilters.forEach(function (obj, i) {
      if (obj['columnName'] === object['columnName']) {
        found = true;
        this1._tempfilters.splice(i, 1);
        this1._tempfilters.splice(i, 0, object);
      }
    });
    if (!found) {
      this._tempfilters.push(object);
    }
    return this._tempfilters;
  }

  getCondition(value) {
    var data = '';
    this._tempconditionList.forEach(function (dat) {
      if (dat['label'] === value) {
        data = dat['value'];
      }
    });
    return data;
  }

  updateOptions(value: any, f: any) {
    if (value['value'] !== 'select') {
      var this1 = this;
      var found = false;
      this._tempconditionList.forEach(function (cond, i) {
        if (cond['label'] === f['value']) {
          found = true;
          var temp = {label: cond['label'], value: value['value']};
          this1._tempconditionList.splice(i, 1);
          this1._tempconditionList.splice(i, 0, temp);
        }
      });
      if (!found) {
        var temp = {label: f['value'], value: value['value']};
        this._tempconditionList.push(temp);
      }
      this.updateAllFilter(f['value']);
    }
  }

  updateAllFilter(columnname) {
    var this1 = this;
    this._tempfilters.forEach(function (filter, i) {
      if (filter['columnName'] === columnname) {
        var tempobj = {
          header: filter['header'],
          columnName: filter['columnName'],
          condition: this1.getCondition(filter['columnName']),
          filterValue: filter['filterValue'],
          type: filter['type'],
        };
        this1._tempfilters.splice(i, 1);
        this1._tempfilters.splice(i, 0, tempobj);
      }
    });
  }

  addTextOption(value: any, f: any) {
    if (value.length > 0) {
      var condition = this.getCondition(f['value']);
      this.pushInAllFilters(this.createObject(f['label'], f['value'], condition, value));
    }

  }

  addListOption(value: any, f: any) {
    if (value['value'] !== 'select') {
      var condition = this.getCondition(f['value']);
      this.pushInAllFilters(this.createObject(f['label'], f['value'], condition, value, '', 'L'));
    }
  }

  addNumberOption(value: any, f: any, isFirst: boolean = true) {
    var first = value;
    var second = value;
    if (this._tempnumberList.includes(f['value'])) {
      if (isFirst === true) {
        first = value;
        second = this._tempsecondList[this._tempnumberList.indexOf(f['value'])];
        this._tempfirstList.splice(this._tempnumberList.indexOf(f['value']), 1);
        this._tempfirstList.splice(this._tempnumberList.indexOf(f['value']), 0, value);
      } else {
        second = value;
        first = this._tempfirstList[this._tempnumberList.indexOf(f['value'])];
        this._tempsecondList.splice(this._tempnumberList.indexOf(f['value']), 1);
        this._tempsecondList.splice(this._tempnumberList.indexOf(f['value']), 0, value);
      }
    } else {
      this._tempnumberList.push(f['value']);
      if (isFirst === true) {
        this._tempfirstList.push(value);
        this._tempsecondList.push(0);
      } else {
        this._tempfirstList.push(0);
        this._tempsecondList.push(value);
      }

    }
    var condition = this.getCondition(f['value']);
    this.pushInAllFilters(this.createObject(f['label'], f['value'], condition, first, second, 'N'));
  }

  addDateOption(value: Date, f: any, isFirst: boolean) {
    let first = value;
    let second = value;
    if (this._tempnumberList.includes(f['value'])) {
      if (isFirst === true) {
        first = value;
        second = this._tempsecondList[this._tempnumberList.indexOf(f['value'])];
        this._tempfirstList.splice(this._tempnumberList.indexOf(f['value']), 1);
        this._tempfirstList.splice(this._tempnumberList.indexOf(f['value']), 0, value);
      } else {
        second = value;
        first = this._tempfirstList[this._tempnumberList.indexOf(f['value'])];
        this._tempsecondList.splice(this._tempnumberList.indexOf(f['value']), 1);
        this._tempsecondList.splice(this._tempnumberList.indexOf(f['value']), 0, value);
      }
    } else {
      this._tempnumberList.push(f['value']);
      if (isFirst === true) {
        this._tempfirstList.push(value);
        this._tempsecondList.push(0);
      } else {
        this._tempfirstList.push(0);
        this._tempsecondList.push(value);
      }

    }
    var condition = this.getCondition(f['value']);
    this.pushInAllFilters(this.createObject(f['label'], f['value'], condition, first, second, 'D'));
  }

  /**
   *
   *todays work
   * @param value
   */
  getFilterObject(value: any) {
    let type = value['column']['columnType'];
    if(value['column']['metaFunctionForFilter'] !== null && value['column']['metaFunctionForFilter'] !== undefined) {
      type = this.commonSerivce.runFunction(value,value['column']['metaFunctionForFilter']);
    }
    return new Filter(value['label'], value['value'], '', '', '',type , value['column']);
  }
}
