import {Component, OnInit} from '@angular/core';
import {Tcolumn} from '../../grid/tcolumn.model';
import {Grid} from '../../grid/grid.model';
import {KeyValue} from '../../grid/key-value.model';
import {CommonService} from '../../services/common.service';
import {FormBuilder, Validators} from '@angular/forms';
import {Tab} from '../../tab-grid/Tab.model';
import {environment} from '../../../environments/environment';
import {Action} from '../../grid/actions.model';
import {defaultValue} from '../../grid/defaultValue.model';
import {MasterService} from '../services/MasterService';
import {ChildAction} from '../../grid/ChildAction.model';
import {MessageService, TreeNode} from 'primeng';
import {repos} from '../../services/common/entities';

@Component({
  selector: 'app-commodity',
  templateUrl: './commodity.component.html',
  styleUrls: ['./commodity.component.css']
})
export class CommodityComponent implements OnInit {
  columns: Map<string, Tcolumn>;
  grid: Grid;
  tabs: Tab[] = [];
  refreshTab: any = '';
  groupList: KeyValue[] = [];
  tabToUpdate: any;
  hierarchyData: TreeNode[];
  modalStateHierarchy: boolean = false;
  rootName: string;
  selectedRowData: any;
  loadListData: any[];
  private brand:Tab;
  private grade:Tab;
  private origin:Tab;
  private season:Tab;
  private packaging:Tab;
  private containerPackaging:Tab;

  constructor(public commonService: CommonService, private masterCommonService: MasterService, public messageService: MessageService, public formBuilder: FormBuilder) {
  }

   initiateTabs() {
    this.brand = this.getCommodityBrand();
    this.grade = this.getCommodityGrade();
    this.season = this.getCommodityCropSeason();
    this.origin = this.getCommodityOrigin();
    this.packaging = this.getCommodityPackaging();
    this.containerPackaging = this.getCommodityContainerPackaging();
  }

  ngOnInit() {
    this.commonService.getLoadData('commodity',['config', 'config', 'config', 'config','config','config','config', 'data', 'data', 'data','data','data'],['grade','brand','incoterm', 'cropseason', 'location','internalpackage','externalpackage', [{key:'name', value:'quality_parameter_ind'}], [{key:'name', value:'bulk_pack_ind'}], [{key:'name', value:'trade_type_ind'}], [{key:'name', value:'uom_type_ind'}],[{key:'description', value:'quality_parameter_ind'}]]).subscribe( (next:any) => {
      this.loadListData = next;
      this.getColumns();
      this.getMetadata();
      this.grid = new Grid(this.columns, true, true, true, true, 'name', '/api/commodity/v1/getall?tenantId=' + this.commonService.getFromStorage('tenantId'), '', 'Commodity', '', '', undefined, this.tabs, true, undefined, undefined, undefined, undefined, undefined, true);
      this.grid.setSaveUrl(environment.base_url + '/api/commodity/v1/save');
      this.grid.setUpdateUrl(environment.base_url + '/api/commodity/v1/update');
      this.grid.hrefUrl = environment.base_url + '/api/commodity/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
      this.grid.importUrl = environment.base_url + '/api/commodity/v1/import';
      let copy = new ChildAction('commodityUuid');
      copy.setPrimaryKeyCode('uuid');
      copy.addRepo('xceler_configservice_commoditypackage', repos.commodityPackage);
      copy.addRepo('xceler_configservice_commoditybrand', repos.commodityBrand);
      copy.addRepo('xceler_configservice_commoditygrade', repos.commodityGrade);
      copy.addRepo('xceler_configservice_commodityorigin', repos.commodityOrigin);
      copy.addRepo('xceler_configservice_commodityqualityspec', repos.commodityQualitySpec);
      copy.addRepo('xceler_configservice_commoditycropseason', repos.commodityCropSeason);
      this.initiateTabs();
      this.tabs.push(this.getCommodityQualitySpecification());
      this.tabs.push(this.brand);
      this.tabs.push(this.grade);
      this.tabs.push(this.season);
      this.tabs.push(this.origin);
      this.tabs.push(this.packaging);
      this.tabs.push(this.containerPackaging);
      this.grid.setChildCopyDelete(copy);
      this.grid.onEditViewThrowUnique = true;
      this.grid.iconName = 'commodity';
      this.grid.gridAdvacedFilterURL='/api/commodity/v1/getcommoditybycriteria?tenantId='+ this.commonService.getFromStorage('tenantId');
      this.grid.addSection('Commodity Detail', ['code','name', 'description', 'parent', 'hsCode', 'tradeType','linkTo', 'incoterm']);
      this.grid.addSection('UOM', ['uomType', 'uom', 'uomToMassConversion']);
      this.grid.addSection('Options', ['packaging', 'brand', 'grade', 'isTradeable', 'season', 'origin']);
      this.grid.addSection('Other', ['status']);
      this.grid.addChildTabs(this.getCommodityQualitySpecification());
      this.grid.addChildTabs(this.packaging);
      this.grid.addChildTabs(this.containerPackaging);
      this.grid.addChildTabs(this.brand);
      this.grid.addChildTabs(this.grade);
      this.grid.addChildTabs(this.season);
      this.grid.addChildTabs(this.origin);
    });
  }

  onChange(value: any) {
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url+'/api/commodity/v1/isdefaultavailable?tenantId='+this.commonService.getFromStorage('tenantId')+'&commodityUuid='+value['rowData']['uuid']).subscribe((next:any) => {
      _this.tabs = [];
      _this.tabs = this.getAllTabs(value['rowData'],next);
      _this.grid.setTabs(this.tabs);
    });
  }

  getAllTabs(value,mapdefault:any) {
    let tabs = [];
    let packaging: boolean = value['packaging'];
    let brand: boolean = value['brand'];
    let grade: boolean = value['grade'];
    let season: boolean = value['season'];
    let origin: boolean = value['origin'];
    tabs.push(this.getCommodityQualitySpecification());
    if (brand) {
      tabs.push(this.brand);
    }
    if (grade) {
      tabs.push(this.grade);
    }
    if (season) {
      tabs.push(this.season);
    }
    if (origin) {
      tabs.push(this.origin);
    }
    if (packaging) {
      tabs.push(this.packaging);
      tabs.push(this.containerPackaging);
    }
    this.updateTabDefault('internalPackage',mapdefault);
    this.updateTabDefault('externalPackage',mapdefault);
    this.updateTabDefault('brand',mapdefault);
    this.updateTabDefault('grade',mapdefault);
    this.updateTabDefault('season',mapdefault);
    this.updateTabDefault('origin',mapdefault);
    return tabs;
  }

  private updateTabDefault(tab,mapDefault:any) {
    if(tab === 'brand') {
      this.brand.getGrid().getColumn().get('defaultValue').setDefaultValue(!this.commonService.getDefaultValue(mapDefault,tab));
    } else if(tab === 'grade') {
      this.grade.getGrid().getColumn().get('defaultValue').setDefaultValue(!this.commonService.getDefaultValue(mapDefault,tab));
    } else if(tab === 'season') {
      this.season.getGrid().getColumn().get('defaultValue').setDefaultValue(!this.commonService.getDefaultValue(mapDefault,tab));
    } else if(tab === 'origin') {
      this.origin.getGrid().getColumn().get('defaultValue').setDefaultValue(!this.commonService.getDefaultValue(mapDefault,tab));
    } else if(tab === 'internalPackage') {
      this.packaging.getGrid().getColumn().get('defaultValue').setDefaultValue(!this.commonService.getDefaultValue(mapDefault,tab));
    }else if(tab === 'externalPackage') {
      this.containerPackaging.getGrid().getColumn().get('defaultValue').setDefaultValue(!this.commonService.getDefaultValue(mapDefault,tab));
    }
  }

  /**
   * Work to do on row save like default and other functionality
   *
   **/
  onRowSave(value: any) {
    let data;
    let result;
    let mode = '';
    let _this =this;
    if (value['label'] === 'Commodity') {
      data = value['result'];
      this.tabs = [];
      this.tabs = this.getAllTabs(data,null);
      this.grid.setTabs(this.tabs);
    } else {
      data = value['data'];
      result = data['result'];
      mode = data['mode'];
      if (result['newData']) {
        result = result['newData'];
      }
      if(data['label'] === 'Brand') {
        if (result['defaultValue'] === true) {
          let def = new ChildAction();
          def.setMode('makedefault');
          def.setCommonService(this.commonService);
          def.addCriteria('brandName', result['brandName'], 'notEqual');
          def.addCriteria('tenantId', this.commonService.getFromStorage('tenantId'), 'equal');
          def.addCriteria('commodityUuid', result['commodityUuid'], 'equal');
          def.addRepo('xceler_configservice_commoditybrand', repos.commodityBrand, 'defaultValue','status');
          def.perform().subscribe(next => {
            _this.brand.getGrid().getColumn().get('defaultValue').setDefaultValue(false);
            _this.refreshTab = data['label'];
          });
        }else {
          if(mode === 'update') {
            _this.updateDefaultOnUpdate(data,_this.brand,'defaultValue');
          }
        }
      } else if(data['label'] === 'Grade') {
        if (result['defaultValue'] === true) {
          let def = new ChildAction();
          def.setMode('makedefault');
          def.setCommonService(this.commonService);
          def.addCriteria('gradeName', result['gradeName'], 'notEqual');
          def.addCriteria('tenantId', this.commonService.getFromStorage('tenantId'), 'equal');
          def.addCriteria('commodityUuid', result['commodityUuid'], 'equal');
          def.addRepo('xceler_configservice_commoditygrade', repos.commodityGrade, 'defaultValue','status');
          def.perform().subscribe(next => {
            _this.grade.getGrid().getColumn().get('defaultValue').setDefaultValue(false);
            _this.refreshTab = data['label'];
          });
        }else {
          if(mode === 'update') {
            _this.updateDefaultOnUpdate(data,_this.grade,'defaultValue');
          }
        }
      } else if(data['label'] === 'Origin') {
        if (result['defaultValue'] === true) {
          let def = new ChildAction();
          def.setMode('makedefault');
          def.setCommonService(this.commonService);
          // def.addCriteria('name', result['name']);
          def.addCriteria('originName', result['originName'], 'notEqual');
          def.addCriteria('tenantId', this.commonService.getFromStorage('tenantId'), 'equal');
          def.addCriteria('commodityUuid', result['commodityUuid'], 'equal');
          def.addRepo('xceler_configservice_commodityorigin', repos.commodityOrigin, 'defaultValue','status');
          def.perform().subscribe(next => {
            _this.origin.getGrid().getColumn().get('defaultValue').setDefaultValue(false);
            _this.refreshTab = data['label'];
          });
        }else {
          if(mode === 'update') {
            _this.updateDefaultOnUpdate(data,_this.origin,'defaultValue');
          }
        }
      }  else if(data['label'] === 'Crop Season') {
        if (result['defaultValue'] === true) {
          let def = new ChildAction();
          def.setMode('makedefault');
          def.setCommonService(this.commonService);
          def.addCriteria('cropSeasonName', result['cropSeasonName'], 'notEqual');
          def.addCriteria('tenantId', this.commonService.getFromStorage('tenantId'), 'equal');
          def.addCriteria('commodityUuid', result['commodityUuid'], 'equal');
          def.addRepo('xceler_configservice_commoditycropseason', repos.commodityCropSeason, 'defaultValue','status');
          def.perform().subscribe(next => {
            _this.season.getGrid().getColumn().get('defaultValue').setDefaultValue(false);
            _this.refreshTab = data['label'];
          });
        } else {
          if(mode === 'update') {
            _this.updateDefaultOnUpdate(data,_this.season,'defaultValue');
          }
        }
      } else if(data['label'] === 'Packaging') {
        if (result['defaultValue'] === true) {
          let def = new ChildAction();
          def.setMode('makedefault');
          def.setCommonService(this.commonService);
          def.addCriteria('internalPackageName', result['internalPackageName'], 'notEqual');
          def.addCriteria('tenantId', this.commonService.getFromStorage('tenantId'), 'equal');
          def.addCriteria('commodityUuid', result['commodityUuid'], 'equal');
          def.addRepo('xceler_configservice_commoditypackage', repos.commodityPackage, 'defaultValue', 'status');
          def.perform().subscribe(next => {
            _this.packaging.getGrid().getColumn().get('defaultValue').setDefaultValue(false);
            _this.refreshTab = data['label'];
          });
        }else {
          if(mode === 'update') {
            _this.updateDefaultOnUpdate(data,_this.packaging,'defaultValue');
          }
        }
      } else if(data['label'] === 'External Packaging') {
        if (result['defaultValue'] === true) {
          let def = new ChildAction();
          def.setMode('makedefault');
          def.setCommonService(this.commonService);
          def.addCriteria('externalPackageName', result['externalPackageName'], 'notEqual');
          def.addCriteria('tenantId', this.commonService.getFromStorage('tenantId'), 'equal');
          def.addCriteria('commodityUuid', result['commodityUuid'], 'equal');
          def.addRepo('xceler_configservice_commodityexternalpackage', repos.commodityExternalPackage, 'defaultValue', 'status');
          def.perform().subscribe(next => {
            _this.containerPackaging.getGrid().getColumn().get('defaultValue').setDefaultValue(false);
            _this.refreshTab = data['label'];
          });
        }else {
          if(mode === 'update') {
            _this.updateDefaultOnUpdate(data,_this.containerPackaging,'defaultValue');
          }
        }
      }
    }
  }

  private updateDefaultOnUpdate(value:any,tab:Tab,defaultFieldName:string) {
    let oldData = value['result']['oldData'];
    if(oldData[defaultFieldName]) {
      tab.getGrid().getColumn().get(defaultFieldName).setDefaultValue(true);
    }
  }
  private getCommodityQualitySpecification() {
    //this.masterCommonService.setAddbtnField('name', this.commonService.getGIDColumns('quality_parameter_ind', this.masterCommonService), new Action('', 'name', 'name'));
    let minVal = function minVali(value: any[] = []) {
      let current = [Validators.min(parseInt(value[0]))];
      return current;
    };
    let action = new Action();
    action.addFieldToUpdate('max', undefined, minVal, undefined, undefined, false, 'validators');

    let actionforqualityParameterCode: Action = new Action(environment.base_url + '/api/globalindicatordetails/v1/getgidetailsdatabyname?tenantId='+this.commonService.getFromStorage('tenantId')+'&name=');
    actionforqualityParameterCode.addFieldToUpdate('description', 'description',undefined,undefined, undefined, false, 'defaultValue');

    let columns: Map<string, Tcolumn> = new Map();
    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    columns.set('commodityName', new Tcolumn('commodityName', 'Commodity Name', 'LB', 1, false, undefined, false, '', 'H', []));
    columns.set('name', new Tcolumn('name', 'Quality Parameter Name', 'L', 2, false, this.masterCommonService.getListFromArray(this.loadListData['quality_parameter_ind']), true, '', 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, actionforqualityParameterCode));
    columns.set('description', new Tcolumn('description', 'Quality Parameter Description', 'LB', 3, true, undefined, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, undefined, true));
    columns.set('min', new Tcolumn('min', 'Min', 'N', 3, true, undefined, true, '', 'B', [Validators.min(0)], undefined, 1, undefined, undefined, undefined, action));
    columns.set('max', new Tcolumn('max', 'Max', 'N', 3, true, undefined, true, '', 'B', [Validators.min(0)]));
    columns.set('typical', new Tcolumn('typical', 'Typical', 'T', 3, true, undefined, true, '', 'B', [Validators.maxLength(50)]));
    columns.set('basis', new Tcolumn('basis', 'Basis', 'T', 3, true, undefined, true, '', 'B', [Validators.maxLength(50)]));
    columns.set('unit', new Tcolumn('unit', 'Unit', 'T', 3, true, undefined, true, '', 'B', [Validators.maxLength(50)]));
    columns.set('status', new Tcolumn('status', 'Status', 'B', 3, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));
    let grid = new Grid(columns, true, true, true, true, "name", '/api/commodityqualityspec/v1/getbycommodityuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&commodityUuid=', "", "Quality Specification", "commodityName", "", undefined, undefined, false);
    grid.setSaveUrl(environment.base_url + '/api/commodityqualityspec/v1/save');
    grid.setUpdateUrl(environment.base_url + '/api/commodityqualityspec/v1/update');
    grid.hrefUrl = environment.base_url + '/api/commodityqualityspec/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
    grid.importUrl = environment.base_url + '/api/commodityqualityspec/v1/importcommodityqualityspec';
    grid.addSection('Quality Spec',['commodityName','name','description']);
    grid.addSection('Measures',['min','max','typical','basis','unit']);
    grid.addSection('Other',['status']);
    let tab = new Tab(grid.getTitle(), grid, "commodityName");
    tab.idFieldValueName = 'commodityUuid';
    return tab;

  }

  private getCommodityBrand() {
    let actionforbrandname: Action = new Action(environment.base_url + '/api/brand/v1/getbranddetailsdatabyname?tenantId='+this.commonService.getFromStorage('tenantId')+'&name=');
    actionforbrandname.addFieldToUpdate('description', 'description',undefined,undefined, undefined, false, 'defaultValue');

    let columns: Map<string, Tcolumn> = new Map();
    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    columns.set('name', new Tcolumn('name', 'Commodity Name', 'LB', 1, false, undefined, false, '', 'B', [Validators.required]));
    columns.set('brandName', new Tcolumn('brandName', 'Brand Name', 'L', 3, false, this.masterCommonService.getListFromArray(this.loadListData['brand']), true, '', 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, actionforbrandname));
    columns.set('description', new Tcolumn('description', 'Brand Description', 'LB', 5, true, undefined, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, undefined, true));
    columns.set('defaultValue', new Tcolumn('defaultValue', 'Brand Default', 'B', 6, true, this.masterCommonService.getYesNo(), true, false, 'B', [Validators.required]));
    columns.set('status', new Tcolumn('status', 'Status', 'B', 7, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));
    let grid = new Grid(columns, true, true, true, true, 'brandName', '/api/commoditybrand/v1/getbycommodityuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&commodityUuid=', '', 'Brand', 'name', '', undefined, undefined, false);
    grid.setSaveUrl(environment.base_url + '/api/commoditybrand/v1/save');
    grid.setUpdateUrl(environment.base_url + '/api/commoditybrand/v1/update');
    grid.hrefUrl = environment.base_url + '/api/commoditybrand/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
    grid.importUrl = environment.base_url + '/api/commoditybrand/v1/import';
    let tab = new Tab(grid.getTitle(), grid, 'name');
    tab.idFieldValueName = 'commodityUuid';
    return tab;
  }

  private getCommodityGrade() {
    let actionforgradename: Action = new Action(environment.base_url + '/api/grade/v1/getgranddetailsdatabyname?tenantId='+this.commonService.getFromStorage('tenantId')+'&name=');
    actionforgradename.addFieldToUpdate('description', 'description',undefined,undefined, undefined, false, 'defaultValue');

    let columns: Map<string, Tcolumn> = new Map();
    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    columns.set('name', new Tcolumn('name', 'Commodity Name', 'LB', 1, false, undefined, false, '', 'B', [Validators.required]));
    columns.set('gradeName', new Tcolumn('gradeName', 'Grade  Name', 'L', 3, false, this.masterCommonService.getListFromArray(this.loadListData['grade']), true, '', 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, actionforgradename));
    columns.set('description', new Tcolumn('description', 'Grade Description', 'LB', 5, true, undefined, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, undefined, true));
    columns.set('defaultValue', new Tcolumn('defaultValue', 'Default', 'B', 6, true, this.masterCommonService.getYesNo(), true, false, 'B', [Validators.required]));
    columns.set('status', new Tcolumn('status', 'Status', 'B', 7, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));
    let grid = new Grid(columns, true, true, true, true, 'gradeName', '/api/commoditygrade/v1/getbycommodityuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&commodityUuid=', '', 'Grade', 'name', '', undefined, undefined, false);
    grid.setSaveUrl(environment.base_url + '/api/commoditygrade/v1/save');
    grid.setUpdateUrl(environment.base_url + '/api/commoditygrade/v1/update');
    grid.hrefUrl = environment.base_url + '/api/commoditygrade/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
    grid.importUrl = environment.base_url + '/api/commoditygrade/v1/import';
    let tab = new Tab(grid.getTitle(), grid, 'name');
    tab.idFieldValueName = 'commodityUuid';
    return tab;
  }

  private getCommodityCropSeason() {

    let actionforcropname: Action = new Action(environment.base_url + '/api/cropseason/v1/getcropseasondatabyname?tenantId='+this.commonService.getFromStorage('tenantId')+'&name=');
    actionforcropname.addFieldToUpdate('description', 'description',undefined,undefined, undefined, false, 'defaultValue');

    let columns: Map<string, Tcolumn> = new Map();
    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    columns.set('name', new Tcolumn('name', 'Commodity Code', 'LB', 1, false, undefined, false, '', 'B', [Validators.required], undefined, 1, undefined, undefined, undefined));
    columns.set('cropSeasonName', new Tcolumn('cropSeasonName', 'Crop Season Code', 'L', 3, false, this.masterCommonService.getListFromArray(this.loadListData['cropseason']), true, '', 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, actionforcropname));
    columns.set('description', new Tcolumn('description', 'Crop Season Description', 'LB', 5, true, undefined, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, undefined, true));
    columns.set('defaultValue', new Tcolumn('defaultValue', 'Default', 'B', 6, true, this.masterCommonService.getYesNo(), true, false, 'B', [Validators.required]));
    columns.set('status', new Tcolumn('status', 'Status', 'B', 7, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));
    let grid = new Grid(columns, true, true, true, true, 'cropSeasonName', '/api/commoditycropseason/v1/getbycommodityuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&commodityUuid=', '', 'Crop Season', 'name', '', undefined, undefined, false);
    grid.setSaveUrl(environment.base_url + '/api/commoditycropseason/v1/save');
    grid.setUpdateUrl(environment.base_url + '/api/commoditycropseason/v1/update');
    grid.hrefUrl = environment.base_url + '/api/commoditycropseason/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
    grid.importUrl = environment.base_url + '/api/commoditycropseason/v1/import';
    let tab = new Tab(grid.getTitle(), grid, 'name');
    tab.idFieldValueName = 'commodityUuid';
    return tab;
  }

  private getCommodityOrigin() {

    let actionforOriginname: Action = new Action(environment.base_url + '/api/location/v1/getlocationdetailsbyname?tenantId=' + this.commonService.getFromStorage('tenantId') + '&name=');
    actionforOriginname.addFieldToUpdate('description', 'description', undefined, undefined, undefined, false, 'defaultValue');

    let columns: Map<string, Tcolumn> = new Map();
    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    columns.set('name', new Tcolumn('name', 'Commodity Name', 'LB', 1, false, undefined, false, '', 'B', [Validators.required]));
    columns.set('originName', new Tcolumn('originName', 'Origin  Name', 'L', 3, false, this.masterCommonService.getListFromArray(this.loadListData['location']), true, '', 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, actionforOriginname));
    columns.set('description', new Tcolumn('description', 'Origin Description', 'LB', 5, true, undefined, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, undefined, true));
    columns.set('defaultValue', new Tcolumn('defaultValue', 'Default', 'B', 6, true, this.masterCommonService.getYesNo(), true, false, 'B', [Validators.required]));
    columns.set('status', new Tcolumn('status', 'Status', 'B', 7, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));
    let grid = new Grid(columns, true, true, true, true, 'originName', '/api/commodityorigin/v1/getbycommodityuuid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&commodityUuid=', '', 'Origin', 'name', '', undefined, undefined, false);
    grid.setSaveUrl(environment.base_url + '/api/commodityorigin/v1/save');
    grid.setUpdateUrl(environment.base_url + '/api/commodityorigin/v1/update');
    grid.hrefUrl = environment.base_url + '/api/commodityorigin/v1/getbyuuid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&uuid=';
    grid.importUrl = environment.base_url + '/api/commodityorigin/v1/import';
    let tab = new Tab(grid.getTitle(), grid, 'name');
    tab.idFieldValueName = 'commodityUuid';
    return tab;
  }

  //Internal Packaging
  private getCommodityPackaging() {
    let columns: Map<string, Tcolumn> = new Map();
    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    columns.set('name', new Tcolumn('name', 'Commodity Name', 'LB', 1, false, undefined, false, '', 'B', [Validators.required]));
    columns.set('internalPackageName', new Tcolumn('internalPackageName', 'Internal Package Name', 'L', 4, false, this.masterCommonService.getListFromArray(this.loadListData['internalpackage']), true, '', 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, undefined, true));
    columns.set('capacity', new Tcolumn('capacity', 'Capacity', 'N', 5, true, undefined, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, undefined, true));
    columns.set('capacityUom', new Tcolumn('capacityUom', 'Capacity Uom', 'LB', 6, false, this.masterCommonService.getListFromArray(this.loadListData['uom_type_ind']), true, 'MT', 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, undefined, true));
    columns.set('defaultValue', new Tcolumn('defaultValue', 'Default', 'B', 7, true, this.masterCommonService.getYesNo(), true, false, 'B', [Validators.required]));
    columns.set('status', new Tcolumn('status', 'Status', 'B', 8, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));
    let grid = new Grid(columns, true, true, true, true, 'internalPackageName', '/api/commoditypackage/v1/getbycommodityuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&commodityUuid=', '', 'Packaging', 'name', '', undefined, undefined, false);
    grid.setSaveUrl(environment.base_url + '/api/commoditypackage/v1/save');
    grid.setUpdateUrl(environment.base_url + '/api/commoditypackage/v1/update');
    grid.hrefUrl = environment.base_url + '/api/commoditypackage/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
    grid.importUrl = environment.base_url + '/api/commoditypackage/v1/import';
    let tab = new Tab(grid.getTitle(), grid, 'name');
    tab.idFieldValueName = 'commodityUuid';
    return tab;
  }

//External Packaging
  private getCommodityContainerPackaging() {
    let columns: Map<string, Tcolumn> = new Map();
    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    columns.set('name', new Tcolumn('name', 'Commodity Name', 'LB', 1, false, undefined, false, '', 'B', [Validators.required]));
    columns.set('externalPackageName', new Tcolumn('externalPackageName', 'External Package Name', 'L', 4, false, this.masterCommonService.getListFromArray(this.loadListData['externalpackage']), true, '', 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, undefined, true));
    columns.set('capacity', new Tcolumn('capacity', 'Capacity', 'N', 5, true, undefined, true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined, undefined, true));
    columns.set('capacityUom', new Tcolumn('capacityUom', 'Capacity Uom', 'LB', 6, false, this.masterCommonService.getListFromArray(this.loadListData['uom_type_ind']), true, 'MT', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined, undefined, true));
    columns.set('defaultValue', new Tcolumn('defaultValue', 'Default', 'B', 7, true, this.masterCommonService.getYesNo(), true, false, 'B', [Validators.required]));
    columns.set('status', new Tcolumn('status', 'Status', 'B', 8, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));
    let grid = new Grid(columns, true, true, true, true, 'externalPackageName', '/api/commodityexternalpackage/v1/getbycommodityuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&commodityUuid=', '', 'External Packaging', 'name', '', undefined, undefined, false);
    grid.setSaveUrl(environment.base_url + '/api/commodityexternalpackage/v1/save');
    grid.setUpdateUrl(environment.base_url + '/api/commodityexternalpackage/v1/update');
    grid.hrefUrl = environment.base_url + '/api/commodityexternalpackage/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
    grid.importUrl = environment.base_url + '/api/commodityexternalpackage/v1/import';
    let tab = new Tab(grid.getTitle(), grid, 'name');
    tab.idFieldValueName = 'commodityUuid';
    return tab;
  }

  private getColumns() {

    let uomType = function (value: any[]){
      return value[0];
    };

    let actionforuomtype: Action = new Action(environment.base_url + '/api/unitofmeasurement/v1/getuomdatabyuomtype?tenantId='+this.commonService.getFromStorage('tenantId')+'&uomType=','name','name');
    actionforuomtype.setCovertToListRequired(true);
    actionforuomtype.addFieldToUpdate("uom",undefined, uomType, ['L'], undefined, true,'listvalues');

    let listValueFunc = function (value:any[] = [] ) {
      let list:any[] = value[0];
      if(list !== null && list !== undefined && list.length > 0) {
        list.splice(0,1);
      }
      list.splice(0,0,new KeyValue('None','none'));
      return list;
    };
    let actionforcommodityName: Action = new Action(environment.base_url + '/api/commodity/v1/getparentlist?tenantId=' +this.commonService.getFromStorage('tenantId')+'&name=','name','name');
    actionforcommodityName.setCovertToListRequired(true);
    actionforcommodityName.addFieldToUpdate('parent', undefined, listValueFunc, ['L'], undefined, true, 'listvalues', false, false);

    this.columns = new Map();
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('code', new Tcolumn('code', 'Commodity Code', 'T', 1, false, undefined, true, new defaultValue('', undefined, ['-', '_'], undefined, false, true, false, undefined, undefined, true), 'B', [Validators.required, Validators.maxLength(8)], undefined, undefined, undefined, undefined, [], undefined, undefined, true));
    this.columns.set('name', new Tcolumn('name', 'Commodity Name', 'T', 1, false, undefined, true, new defaultValue('', '', environment.primaryKeyIncludedCharacters), 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, actionforcommodityName));
    this.columns.set('description', new Tcolumn('description', 'Commodity Description', 'TA', 3, true, undefined, true, '', 'B', [Validators.maxLength(200)]));
    this.columns.set('parent', new Tcolumn('parent', 'Parent Commodity', 'L', 4, true, this.getParent(), true, 'none', 'B', [Validators.required]));
    this.columns.set('hsCode', new Tcolumn('hsCode', 'HS Code', 'T', 5, true, undefined, true, '', 'B', [Validators.maxLength(200)]));
    this.columns.set('tradeType', new Tcolumn('tradeType', 'Trade Type', 'ML', 6, true, this.masterCommonService.getListFromArray(this.loadListData['trade_type_ind'], false, false), true, '', 'B', []));
    this.columns.set('linkTo', new Tcolumn('linkTo', 'Future Commodity Symbol', 'L', 7, true, [], true, '', 'B', []));
    this.columns.set('incoterm', new Tcolumn('incoterm', 'Incoterm', 'ML', 7, true, this.masterCommonService.getListFromArray(this.loadListData['incoterm'], false, false), true, '', 'B', []));
    this.columns.set('uomType', new Tcolumn('uomType', 'UOM Type', 'L', 8, true, this.masterCommonService.getListFromArray(this.loadListData['uom_type_ind']), true, '', 'B', [Validators.required], 'number', 1, undefined, undefined, undefined, actionforuomtype));
    this.columns.set('uom', new Tcolumn('uom', 'UOM', 'L', 9, true, undefined, true, '', 'B', [Validators.required]));
    this.columns.set('uomToMassConversion', new Tcolumn('uomToMassConversion', 'UOM Volume To Mass (MT) Conversion Factor', 'N', 10, true, undefined, true, new defaultValue('', undefined, undefined), 'B', []));
    this.columns.set('status', new Tcolumn('status', 'Status', 'B', 11, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));
    this.columns.set('packaging', new Tcolumn('packaging', 'Packaging', 'B', 12, true, this.masterCommonService.getYesNo(true), true, false, 'B', [Validators.required]));
    this.columns.set('brand', new Tcolumn('brand', 'Brand', 'B', 13, true, this.masterCommonService.getYesNo(true), true, false, 'B', [Validators.required]));
    this.columns.set('grade', new Tcolumn('grade', 'Grade', 'B', 14, true, this.masterCommonService.getYesNo(true), true, false, 'B', [Validators.required]));
    this.columns.set('isTradeable', new Tcolumn('isTradeable', 'Tradeable', 'B', 15, true, this.masterCommonService.getYesNo(true), true, true, 'B', [Validators.required]));
    this.columns.set('season', new Tcolumn('season', 'Season', 'B', 16, true, this.masterCommonService.getYesNo(true), true, false, 'B', [Validators.required]));
    this.columns.set('origin', new Tcolumn('origin', 'Origin', 'B', 17, true, this.masterCommonService.getYesNo(true), true, true, 'B', [Validators.required]));
  }

  getMetadata() {
    let _this = this;
    _this.commonService.getJSONByURL(environment.base_url + '/api/commodity/v1/getcommodityfromfuturemetadata?tenantId='+this.commonService.getTenantId()).subscribe((next: any) => {
    _this.columns.get('linkTo').setListofValues(this.masterCommonService.getListFromArray(next,false,true));
    });
  }

  private getParent() {
    let parentList = [];
    parentList.push(new KeyValue("None", "none"));
    return parentList;
  }

  //Hierarchy Code
  openHeirarcy(selectedRow: any) {
    this.selectedRowData = selectedRow[0];
    this.modalStateHierarchy = true;
  }

  closeBtn() {
    this.modalStateHierarchy = false;
  }

  getEntityName() {
    return 'xceler_configservice_commodity';
  }

    onTabRefreshed() {
        this.refreshTab = '';
    }
}
