<p-table #table
         [columns]="columns"
         [dataKey]="'rowNum'"
         [style]="{maxHeight:height,minHeight:height}"
         [value]="rowData"
         class="style-4 table-responsive"
         editMode="row">
  <ng-template let-columns pTemplate="header">
    <tr>
      <th *ngFor="let col of columns" style="padding: 10px !important;">
        {{col.header}}
      </th>
      <th style="width: 50px;text-align: center;">
        <i (click)="addNew(table)" class="fas fa-plus iconClass"></i>
      </th>
    </tr>
  </ng-template>
  <ng-template let-columns="columns" let-editing="editing" let-ri="rowIndex" let-rowData pTemplate="body">
    <tr [pEditableRow]="getRowData(rowData,ri)">
      <td *ngFor="let col of columns" style="padding-left: 10px !important;">
        <p-cellEditor *ngIf="currentEditingRow === ri">
          <ng-template pTemplate="input">
            <p-dropdown *ngIf="col.type === 'list'" [(ngModel)]="rowData[col.field]" [options]="col.list"
                        appendTo="body"></p-dropdown>
            <input *ngIf="col.type === 'date'"
                   [(ngModel)]="rowData[col.field]"
                   bsDatepicker
                   class="form-control form-control-sm pickerInput" placeholder="Select Date" type="text">
          </ng-template>
          <ng-template pTemplate="output">
            <p class="outClass">{{getOutputValue(rowData, col)}}</p>
          </ng-template>
        </p-cellEditor>
        <div *ngIf="currentEditingRow !== ri"><p class="outClass">{{getOutputValue(rowData, col)}}</p></div>
      </td>
      <td style="width: 50px;text-align: center;">
        <i (click)="onEdit(ri,rowData)" *ngIf="!editing" class="fas fa-pen iconClass iconPadding" pInitEditableRow></i>
        <i (click)="onSave(ri,rowData,table)" *ngIf="editing" class="fas fa-save iconClass iconPadding"
           pSaveEditableRow></i>
        <i (click)="deleteRow(ri,rowData)" class="fas fa-times iconClass"></i>
      </td>
    </tr>
  </ng-template>
</p-table>
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>
