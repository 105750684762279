import {Component, Input, OnInit} from '@angular/core';
import * as shape from 'd3-shape';
import { Subject } from 'rxjs';

@Component({
  selector: 'flowchart',
  templateUrl: './flowchart.component.html',
  styleUrls: ['./flowchart.component.css']
})
export class FlowchartComponent implements OnInit {
  @Input() nodeData: any[] = [];
  @Input() linkData: any[] = [];
  zoomToFit$: Subject<boolean> = new Subject();
  center$: Subject<boolean> = new Subject();
  @Input() width: number = 0;
  @Input() height: number = 0;
  curve: any = shape.curveLinear;


  centerGraph() {
    this.center$.next(true)
  }

  fitGraph() {
    this.zoomToFit$.next(true)
  }


  constructor() { }

  ngOnInit(): void {
    this.fitGraph();
    this.centerGraph();
  }

}
