<style type="text/css">
</style>
<div class="sectionCardTop">
  <div class="breadcrumbPane">
    <ctrm-breadcrumb [collapsabel]="false"
                     [items]="breadCrumbService.getBreadCrumb()"
                     [showIcon]="false"></ctrm-breadcrumb>
  </div>
  <div class="actionPane">
    <div class="screenDetails">
      <img [src]="'../../../assets/images/svg/loadUnload.svg'"/>
      <p>Transport Allocation</p>
    </div>
    <div class="autocomplete-box">
      <input placeholder="Search" type="text">
      <mat-icon style="color:#cbcbcb">search</mat-icon>
      <!--      <mat-icon *ngIf="isFocused" class="closable" (click)="clearAndClose()">clear</mat-icon>-->
    </div>
    <!--    <input type="text" placeholder="Search" (input)="getFilterValue($event)">-->
    <div *ngIf="tabValue == 'planned'" class="rightInfo">
      Total {{planData.length}} Plans
    </div>
  </div>
</div>

<!--planned Records-->
<div class="planStatsContainer">
  <!--  <div class="statsContainer">-->
  <!--    <p class="statTitle">Partially Allocated</p>-->
  <!--    <p class="statNumber">{{partiallyAllocated}}</p>-->
  <!--  </div>-->
  <div class="statsContainer">
    <p class="statTitle">Planned</p>
    <p class="statNumber">{{planned}}</p>
  </div>
  <div class="statsContainer">
    <p class="statTitle">Allocated</p>
    <p class="statNumber">{{allocated}}</p>
  </div>
</div>

<!--Planned table-->
<div>
  <div class="planningPanel">
    <div class="toolbar">
      <div>
        <ctrm-button (onClick)="planRefresh()" icon="fas fa-redo" label="Refresh"></ctrm-button>
        <div class="float-right">
          <ctrm-button
            [disabled]="this.selectedPlans !== null && (this.selectedPlans.length === 0 || this.selectedPlans.length > 1)"
            [label]="'Download Shipment Instructions'" icon="fas fa-download" (click)="downloadExcel()"></ctrm-button>
          <!--          <ctrm-button [disabled]="this.selectedPlans.length === 0 || this.selectedPlans.length > 1" icon="fas fa-file-export" label="Export"></ctrm-button>-->
          <!--          <ctrm-button [disabled]="this.selectedPlans.length === 0 || this.selectedPlans.length > 1" icon="fas fa-print" label="Print"></ctrm-button>-->
          <!--          <ctrm-button icon="fas fa-sliders-h" label="Preferences"></ctrm-button>-->
        </div>
      </div>
    </div>


    <div class="tableClass">
      <p-table #planTable (inViewportAction)="calculateHeight()"
               (onRowSelect)="expandRow($event)" (onRowUnselect)="clearChild($event)"
               [(selection)]="selectedPlans"
               [autoLayout]="true"
               [columns]="planCols"
               [expandedRowKeys]="expandedRows"
               [style.minHeight]="planData.length>0?planTableHeight:''"
               [style]="{width: '100%', maxHeight: planTableHeight}"
               [value]="planData"
               class="style-4 table-responsive" dataKey="planningId"
               inViewport
               selectionMode="multiple">
        <ng-template let-columns pTemplate="header">
          <tr>
            <th style="width: 3em">
              <p style="background: #D9DBDE; height: 47px;margin: 0"></p>
            </th>
            <th *ngFor="let col of columns;index as i">
              <div style="background-color: #D9DBDE; padding: 5px;padding-top: 13px; padding-bottom: 14px">
                <p [ngClass]="getClassForHeading(i)">{{col.header}}</p>
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template let-columns="columns" let-expanded="expanded" let-ri="rowIndex" let-rowData pTemplate="body">
          <tr>
            <td class="customRow" style="width:3em;">
              <div style="display: inline-flex">
                <p class="plannedGridChkBox">
                  <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                </p>
                <p class="rowTogglerStyle">
                  <a [pRowToggler]="rowData" href="#">
                    <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" class="togglerIcon"></i>
                  </a>
                </p>
              </div>
            </td>
            <td *ngFor="let col of columns; index as i" class="customRow">
              <p *ngIf="col.field !== 'matchType' && col.field !== 'status'" class="plannedGrid">
                {{commonService.getRowValue(rowData, col)}}
              </p>
              <p *ngIf="col.field === 'matchType'" class="matchAlgoStyle">
                {{commonService.getRowValue(rowData, col)}}
              </p>
              <div *ngIf="col.field === 'status'" style="display: flex;">
                <div *ngIf="rowData[col.field] === 'Planned'" class="planStatusField">
                  <img alt="" src="../../../assets/images/svg/event.svg">
                  <p class="statusPlanned">Planned</p>
                </div>
                <div *ngIf="rowData[col.field] === 'DELIVERY_STARTED'" class="planStatusField">
                  <img alt="" src="../../../assets/images/svg/truck.svg">
                  <p class="statusDS">Delivery Started</p>
                </div>
                <div *ngIf="rowData[col.field] === 'ACTUALIZED'" class="planStatusField">
                  <img alt="" src="../../../assets/images/svg/purpleTick.svg">
                  <p class="statusAC">Actualized</p>
                </div>
              </div>
            </td>


          </tr>
        </ng-template>
        <ng-template let-columns="columns" let-rowData pTemplate="rowexpansion">
          <tr>
            <td [colSpan]="columns.length + 1">
              <div class="planningInfoContainer animated fadeIn">
                <app-planning-info (onAttachmentClick)="openReadOnlyForm($event)"
                                   (onClickClickable)="onClickable($event)"
                                   (onSelectedRows)="onChildSelected($event, rowData)"
                                   [clickableFields]="['tradeId']"
                                   [columns]="oblCols"
                                   [primaryKeyClickable]="false"
                                   [selectedRows]="currentSelectedRow === rowData['index']? rowData['selectedChild']: []"
                                   [showChkBox]="false"
                                   [value]="rowData['plannedObligationList']"></app-planning-info>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr>
            <td [colSpan]="planCols.length">
              <div *ngIf="planData.length == 0 || planData.length == null" style="text-align: center;">
                <div class="noPlans">Nothing to Allocate</div>
                <div>
                  <button (click)="goTo()" class="planScreenBtns">Go to Trade Planning</button>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <p-paginator *ngIf="getPagination()" [rows]="20" [totalRecords]="totalElements" (onPageChange)="onPageChange($event)"></p-paginator>
    </div>
  </div>
</div>

<!--Form-->
<p-dialog #af [(visible)]="formDisplay" [baseZIndex]="25"
          [closeOnEscape]="false"
          [draggable]="false"
          [maximizable]="false" [modal]="false" [showHeader]="false" [style]="{ width: '65%',height: 'auto',maxHeight:'95%', padding:'0px', background:'#ffffff'}">
  <div>
    <form [formGroup]="shipForm">
      <div class="formContent">
        <div class="formHeaderWrapper">
          <div class="formHeader">
            <img alt="" src="../../../assets/images/svg/ship.svg">
            <p *ngIf="!allocatedObl['plannedId']" class="formHeading">Sea Transport Form</p>
            <p *ngIf="allocatedObl['plannedId']" class="formHeading">{{allocatedObl['plannedObligationId']}}</p>
            <div class="formCloseIcon">
              <i (click)="onFormCancel()" class="material-icons closable">clear</i>
            </div>
          </div>
        </div>

        <div *ngIf="!allocatedObl['plannedId']" class="form">
          <div class="fields">
<!--            B/L Information-->
            <div class="fieldSection">
              <p class="secHeader">B/L Information</p>
              <div class="fieldCont ui-g">

                <div class="fieldBox">
                  <p class="fieldName">B/L Number</p>
                  <div class="inputbox">
                    <input class="form-control form-control-sm" formControlName="blNumber" pInputText>
                  </div>
                </div>

                <div class="fieldBox">
                  <p class="fieldName">B/L Date</p>
                  <div class="inputbox">
                    <div class="div-control">
                      <input [bsConfig]="bsConfig" bsDatepicker
                             class="form-control form-control-sm pickerInput"
                             formControlName="blDate" placeholder="Select Date" type="text">
                    </div>
                  </div>
                </div>
              </div>
            </div>

<!--            Vessel Information Section-->
            <div class="fieldSection">
              <p class="secHeader">Vessel Information</p>
              <div class="fieldCont ui-g">

                <div class="fieldBox">
                  <p class="fieldName">Vessel Name</p>
                  <div class="inputbox">
                    <input class="form-control form-control-sm" formControlName="vesselName" pInputText>
                  </div>
                </div>
                <div class="fieldBox">
                  <p class="fieldName">Vessel Flag (optional)</p>
                  <div class="inputbox">
                    <p-dropdown [options]="commonService.getCountryList()" formControlName="vesselFlag"></p-dropdown>
                  </div>
                </div>
                <div class="fieldBox">
                  <p class="fieldName">IMO Number (optional)</p>
                  <div class="inputbox">
                    <input class="form-control form-control-sm" formControlName="imo" pInputText type="number">
                  </div>
                </div>
                <div class="fieldBox">
                  <p class="fieldName">City (optional)</p>
                  <div class="inputbox">
                    <input class="form-control form-control-sm" formControlName="city" pInputText>
                  </div>
                </div>
                <div class="fieldBox">
                  <p class="fieldName">Age of Vessel (optional)</p>
                  <div class="inputbox">
                    <input class="form-control form-control-sm" formControlName="age" pInputText type="number">
                  </div>
                </div>
              </div>
            </div>

            <!--            Load Information Section-->
            <div *ngIf="loadSection" class="fieldSection">
              <p class="secHeader">Load Information</p>
              <div class="fieldCont ui-g">

                <div class="fieldBox">
                  <p class="fieldName">Load Port</p>
                  <div class="dropdown">
                    <select class="dropdown-text" formControlName="loadPort">
                      <option *ngFor="let listitem of locationList"
                              [value]="listitem.getValue()">{{listitem.getLabel()}}</option>
                    </select>
                  </div>
                </div>
                <div class="fieldBox">
                  <p class="fieldName">Load Rate (MT/Day)</p>
                  <div class="inputbox">
                    <input class="form-control form-control-sm" formControlName="loadRate" pInputText type="number">
                  </div>
                </div>
                <div class="fieldBox">
                  <p class="fieldName">Laycan From</p>
                  <div class="inputbox">
                    <div class="div-control">
                      <input [bsConfig]="bsConfig" bsDatepicker
                             class="form-control form-control-sm pickerInput"
                             formControlName="laycanFrom" placeholder="Select Date" type="text">
                    </div>
                  </div>
                </div>
                <div class="fieldBox">
                  <p class="fieldName">Laycan To</p>
                  <div class="inputbox">
                    <div class="div-control">
                      <input [bsConfig]="bsConfig" bsDatepicker
                             class="form-control form-control-sm pickerInput"
                             formControlName="laycanTo" placeholder="Select Date" type="text">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--            Unload Information Section-->
            <div *ngIf="unloadSection" class="fieldSection">
              <p class="secHeader">Unload Information</p>
              <div class="fieldCont ui-g">

                <div class="fieldBox">
                  <p class="fieldName">Discharge Port</p>
                  <div class="dropdown">
                    <select class="dropdown-text" formControlName="dischargePort">
                      <option *ngFor="let listitem of locationList"
                              [value]="listitem.getValue()">{{listitem.getLabel()}}</option>
                    </select>
                  </div>
                </div>
                <div class="fieldBox">
                  <p class="fieldName">Discharge Rate (MT/Day)</p>
                  <div class="inputbox">
                    <input class="form-control form-control-sm" formControlName="dischargeRate" pInputText
                           type="number">
                  </div>
                </div>
                <div class="fieldBox">
                  <p class="fieldName">ETA From</p>
                  <div class="inputbox">
                    <div class="div-control">
                      <input [bsConfig]="bsConfig" bsDatepicker
                             class="form-control form-control-sm pickerInput"
                             formControlName="etaFrom" placeholder="Select Date" type="text">
                    </div>
                  </div>
                </div>
                <div class="fieldBox">
                  <p class="fieldName">ETA To</p>
                  <div class="inputbox">
                    <div class="div-control">
                      <input [bsConfig]="bsConfig" bsDatepicker
                             class="form-control form-control-sm pickerInput"
                             formControlName="etaTo" placeholder="Select Date" type="text">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="fieldSection">
              <div class="fieldCont ui-g">

                <div class="fieldBox" style="width: 100%">
                  <p class="fieldName">Note</p>
                  <div class="inputbox">
                    <input class="form-control form-control-sm" formControlName="dischargePort" pInputText>
                  </div>
                </div>

              </div>
            </div>
          </div>


          <div class="valContainer">
            <div class="validation">
              <div style="display: flex; padding-bottom: 10px;">
                <img *ngIf="vesselInfoValid()" alt="" src="../../../assets/images/svg/validTick.svg">
                <img *ngIf="!vesselInfoValid()" alt="" src="../../../assets/images/svg/invalidCross.svg">
                <p class="valPoints">Vessel Details</p>
              </div>
              <div *ngIf="loadSection" style="display: flex; padding-bottom: 10px">
                <img *ngIf="loadInfoValid()" alt="" src="../../../assets/images/svg/validTick.svg">
                <img *ngIf="!loadInfoValid()" alt="" src="../../../assets/images/svg/invalidCross.svg">
                <p class="valPoints">Load Information</p>
              </div>
              <div *ngIf="unloadSection" style="display: flex; padding-bottom: 10px;">
                <img *ngIf="unloadInfoValid()" alt="" src="../../../assets/images/svg/validTick.svg">
                <img *ngIf="!unloadInfoValid()" alt="" src="../../../assets/images/svg/invalidCross.svg">
                <p class="valPoints">Unload Information</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>


    <div *ngIf="allocatedObl['plannedId']">
      <div class="ui-g" style="display: flex">
        <div *ngFor="let cols of allocDetails; index as i" class="valueContainer">
          <div class="readOnlyLabel">{{cols.header}}</div>
          <div class="readOnlyValue">{{getObject[cols.field]}}</div>
        </div>
      </div>
    </div>
  </div>

  <p-footer *ngIf="!allocatedObl['plannedId']">
    <button (click)="onFormCancel()" class="cancelBtn">Cancel</button>
    <button (click)="onFormSubmit()" [disabled]="!shipForm.valid" class="planScreenBtns">Allocate</button>
  </p-footer>
  <p-footer *ngIf="allocatedObl['plannedId']">
    <ctrm-button [label]="'Download Shipment Instructions'" icon="fas fa-download"></ctrm-button>
  </p-footer>
</p-dialog>
<p-sidebar [(visible)]="allocationDisplay" [baseZIndex]="25" [modal]="false" [showCloseIcon]="false"
           [styleClass]="'bottomPane'" position="bottom">
  <div class="bottomSidebarContainer">
    <div class="leftSection">
      <i (click)="onCancelBottomForm()" class="material-icons closable">clear</i>
    </div>
    <div class="middleSection">
      <i class="fas fa-download middleIcons" pTooltip="Download" tooltipPosition="top" tooltipZIndex="9999" (click)="downloadExcel()"></i>
      <i class="fas fa-file-export middleIcons" pTooltip="Export" tooltipPosition="top" tooltipZIndex="9999"></i>
      <i class="fas fa-print middleIcons" pTooltip="Print" tooltipPosition="top" tooltipZIndex="9999"></i>
    </div>
    <div class="rightSection">
      <button (click)="onCancelBottomForm()" class="cancelBtn">Cancel</button>
      <button (click)="openAttachDialog()" class="attachment">Attach Document</button>
      <button (click)="deallocate()" *ngIf="deallocationCheck()" class="cancelBtn">Deallocate</button>
      <p-splitButton (onClick)="openForm()" [model]="items" label="Allocate"></p-splitButton>
    </div>
  </div>
</p-sidebar>

<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>

<app-trade-info (onClose)="onCloseTradeInfo($event)" *ngIf="tradeInfoDisplay" [detailsOf]="'trade'"
                [display]="true" [rowData]="oblRow" [sidebarPosition]="'right'"
                [tradeId]="selctedTradeId"></app-trade-info>


<p-dialog (onHide)="onCancel()" *ngIf="attachmentDisplay"
          [closeOnEscape]="false"
          [draggable]="false"
          [maximizable]="false"
          [modal]="true" [showHeader]="false" [style]="{zIndex:1000,width: '98%',height: 'auto',minHeight:'70%',maxHeight:'95%', padding:'0px', background:'#ffffff'}"
          [visible]="true">
  <div #divElement (inViewportAction)="calculateGridHeight(divElement)" inViewport>
    <div class="dialogContents">
      <div style="display:inline-flex;">
        <i class="far fa-file" style = "font-size: 24px;padding-left: 15px;"></i>
        <div class="headerClass"  style="padding-left: 5px;">
          Upload Document
        </div>
    </div>
      <div class="formCloseIcon">
        <i (click)="onCancel()" class="material-icons closable" style="float: right;">clear</i>
      </div>
    </div>

    <div>
      <div class="formTabelContainer">
        <ctrm-grid (onButtonClick)="onButtonClick($event)"
                   [autoCalculateHeight]="false"
                   [gridHeight]="gridHeight"
                   [grid]="attachmentGrid"
                   [isOnSidebar]="true"
                   [showSectionTop]="false"
                   [showToolbar]="false"
                   [showheaderCheckbox]="false"
                   [toolbarConfig]="toolBarConfig">
        </ctrm-grid>
      </div>
    </div>
  </div>
  <p-footer *ngIf="!allocatedObl['plannedId']">
    <div class="footerClass">
      <button (click)="onCancel()" class="cancelBtn">Close</button>
    </div>
  </p-footer>
</p-dialog>
