<div (click)="onClickCard()"
     [ngClass]="isSelected?'':''"
     class="card mainCard" [style.cursor]="disable?'not-allowed':'pointer'" [style]="{backgroundColor:(isSelected || disableClick?addAlpha(cardBorderColor,0.1):cardBackgroundColor),border:cardBorderColor+' 1px solid'}">
    <div *ngIf="isSelected" class="checkBox">
        <mat-icon class="tickClass" [style]="{color:cardBorderColor}">check</mat-icon>
    </div>
    <div class="totalCount">{{total}}</div>
    <div class="stats" *ngIf="showCustoms">
        <p class="header">{{cardHeader}}</p>
        <div style="position: absolute;right: 0" *ngIf="showStats">
            <div class="buySellStat" *ngFor="let stat of statHeaders;index as i">{{stat}}<div>{{statValues[i]}}</div></div>
        </div>
    </div>
    <div class="stats" *ngIf="!showCustoms">
        <p class="header">{{cardHeader}}</p>
        <div style="position: absolute;right: 0" *ngIf="showStats">
            <div class="buySellStat">{{stat1}}<div>{{count1}}</div></div>
            <div class="buySellStat">{{stat2}}<div>{{count2}}</div></div>
        </div>
    </div>
</div>
