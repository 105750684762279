import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {FeedEntry} from './modals/feed-entry';
import {FeedService} from './feed.service';
import {CommonService} from "../services/common.service";
import {environment} from "../../environments/environment";

@Component({
  selector: 'ctrm-feeds',
  templateUrl: './ctrm-feeds.component.html',
  styleUrls: ['./ctrm-feeds.component.css']
})
export class CtrmFeedsComponent implements OnInit, OnChanges {

  @Input() feedurl: any;
  @Input() play: boolean = true;
  newfeeds: any = [];
  @ViewChild('marq', {static: true}) marquee: HTMLMarqueeElement;
  feeds: Array<FeedEntry> = [];
  feedsIncluded: string[] = [];

  constructor(private feedservice: FeedService, public commonService: CommonService) {
  }

  encodeUrls() {
    for (var i = 0; i < this.feedurl.length; i++) {
      var url = this.feedurl[i];
      var encoded = encodeURIComponent(url);
      this.newfeeds.push(encoded);
    }
  }

  ngOnInit() {
    // this.doPlayPause();
    this.encodeUrls();
    // this.loadFeeds();
    // var this1 = this;
    // setInterval(function () {
    //   this1.loadFeeds();
    // }, 10000);
  }

  loadFeeds() {
    for (var i = 0; i < this.newfeeds.length; i++) {
      this.refreshFeed(this.newfeeds[i]);
    }
  }

  doPlayPause() {
    if (this.play === true) {
      this.marquee.start();
    } else {
      this.marquee.stop();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['play']) {
      this.doPlayPause();
    }
  }

  getDateFormat() {
    return environment.publisherDateFormat;
  }

  private refreshFeed(url) {
    var this1 = this;
    this.feedservice.getFeedContent(url)
      .subscribe(
        feed => {
          feed.items.forEach(function (feeditem) {
            if (!this1.feedsIncluded.includes(feeditem.title)) {
              this1.feedsIncluded.push(feeditem.title);
              this1.feeds.push(feeditem);
            }
          })
        },
        error => {
        });
  }
}
