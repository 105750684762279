<div class="maindiv">
    <div *ngIf="!(draftdata === null || draftdata === undefined) && !editmode" class="toolbar">

        <div style="display: inline-flex; align-items: center;">
            <approval-context-menu [title]="'Action'" [functions]="functionFile" [rowData]="draftdata"
                                   [nameField]="counterpartyName"
                                   [screenName]="'Counterparty'"
                                   [referenceId]="counterpartyId" (onApprovalUpdate)="onUpdate($event)"
                                   [triggerEvent]="'KYCApproval'"></approval-context-menu>
            <ctrm-button (onClick)="oneditclick()"
                         [disabled]="(draftdata !== null && draftdata !== undefined && draftdata['counterpartyBusinessProfile']['kycStatus'].toLowerCase() !== 'pending')?(draftdata['counterpartyBusinessProfile']['createdBy'] !== commonService.getFromStorage('userName')):(!editmode && !draftmode)"
                         [icon]="'fas fa-pen'"
                         [label]="'Edit'"></ctrm-button>
            <ctrm-button (onClick)="startWorkFlow(true)"
                         *ngIf="(draftdata !== null && draftdata !== undefined && draftdata['counterpartyBusinessProfile']['kycStatus'].toLowerCase() === 'approved')
                         && commonService.numberOfDaysBetween(getTodayDate(),draftdata['counterpartyBusinessProfile']['kycRenewalDetails'][0]['kycDueDate']) <= 30"
                         [icon]="'fas fa-sync'"
                         [label]="'Renew'"></ctrm-button>
            <div class="float-right">
                <div style="display: inline-flex;">
                    <p>Status:</p>
                    <p [ngClass]="(draftdata === null || draftdata === undefined)?'draft':draftdata['counterpartyBusinessProfile']['kycStatus'].toLowerCase()"
                       class="statustext">{{(draftdata === null || draftdata === undefined) ? 'Draft' : draftdata['counterpartyBusinessProfile']['kycStatus']}}</p>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="(draftdata === null || draftdata === undefined) && !editmode" class="placeHolder">
        <div class="noDataBox " id="noDataBoxId" inviewport="" style="left: 700px;">
            <img class="iconClass" src="../assets/images/svg/emptyStateIcon.svg">
            <p class="noDataHeader">No Data Available
            </p>
            <div class="buttonsBox">
                <div (click)="editmode = true" class="noDataButton">
                    <p class="noDataButtonLabel">Add KYC
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="editmode" class="editbackground">
        <div class="" style="width: 80%;display: inline-grid;">

            <div class="">
                <div class="row">
                    <div class="col-md-12">
                        <div class="wrapper-progressBar">
                            <ul class="progressBar">
                                <li (click)="tab1click()"
                                    [class.active]="viewMode == 'tab1'"
                                    [class.complete]="viewMode == 'tab2' || viewMode == 'tab3'"
                                    class="active" rel="tab1">KYC Renewal, Business and Risk Profile
                                </li>
                                <li (click)="tab2click()" [class.active]="viewMode == 'tab2'"
                                    [class.complete]="viewMode =='tab3'"
                                    class="" rel="tab2">Ultimate Beneficial Ownership
                                </li>
                                <li (click)="tab3click()" [class.active]="viewMode == 'tab3'" rel="tab3">Top Buyer &
                                    Supplier
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="maincontainer">
                    <div [ngSwitch]="viewMode" class="tab-slider--container">
                        <div *ngSwitchCase="'tab1'" class="tab-slider--body" id="tab1">
                            <form [formGroup]="kycForm">
                                <div style="width: 100%;background-color: #fbfcfd;padding: 10px;margin: 10px;">
                                    <div class="formSectionTitle">
                                        <div class="headerTitle">Business Profile Details
                                        </div>
                                    </div>
                                    <div class="" style="display: flex;padding-top: 15px;">
                                        <div class="infoCardedit">
                                            <div class="infoHeadingedit">Trade License number
                                            </div>
                                            <div class="inputbox">
                                                <input class="form-control form-control-sm"
                                                       formControlName="tradeLicenseNumber" pInputText>
                                            </div>
                                        </div>
                                        <div class="infoCardedit">
                                            <div class="infoHeadingedit">Trade Expiry Date
                                            </div>
                                            <div class="inputbox">
                                                <div class="div-control">
                                                    <input
                                                            [attr.disabled]="null"
                                                            [bsConfig]="bsConfig"
                                                            bsDatepicker
                                                            class="form-control form-control-sm pickerInput"
                                                            formControlName="tradeExpiryDate" placeholder="Select Date"
                                                            type="text">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="infoCardedit">
                                            <div class="infoHeadingedit">Trade License Authority
                                            </div>
                                            <div class="inputbox">
                                                <input class="form-control form-control-sm"
                                                       formControlName="tradeLicenseAuthority" pInputText>
                                            </div>
                                        </div>
                                        <div class="infoCardedit">
                                            <div class="infoHeadingedit">Nature of Business
                                            </div>
                                            <div class="inputbox">
                                                <input class="form-control form-control-sm"
                                                       formControlName="natureOfBusiness" pInputText>
                                            </div>
                                        </div>
                                        <div class="infoCardedit">
                                            <div class="infoHeadingedit">Year of Incorporation
                                            </div>
                                            <p-dropdown [disabled]="false"
                                                        [id]="'role'"
                                                        [options]="yearlist"
                                                        appendTo="body"
                                                        formControlName="yearOfIncorporation">
                                            </p-dropdown>
                                        </div>
                                        <div class="infoCardedit">
                                            <div class="infoHeadingedit">Company type
                                            </div>
                                            <p-dropdown [disabled]="false"
                                                        [id]="'role'"
                                                        [options]="companyType"
                                                        appendTo="body"
                                                        formControlName="companyType">
                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div style="width: 100%;display: flex">
                                    <div style="width: 50%;background-color: #fbfcfd;padding: 10px;margin: 10px;">

                                        <div class="formSectionTitle">
                                            <div class="headerTitle">Risk Profile Details
                                            </div>
                                        </div>

                                        <div class="" style="display: flex;padding-top: 15px;">
                                            <div class="infoCardedit">
                                                <div class="infoHeadingedit">Risk Level
                                                </div>
                                                <p-dropdown [disabled]="false"
                                                            [id]="'role'"
                                                            [options]="riskList"
                                                            appendTo="body"
                                                            formControlName="riskLevel">
                                                </p-dropdown>
                                            </div>

                                        </div>
                                    </div>
                                    <div style="width: 50%;background-color: #fbfcfd;padding: 10px;margin: 10px;">
                                        <div class="formSectionTitle">
                                            <div class="headerTitle">KYC Renewal Details
                                            </div>
                                        </div>
                                        <div class="" style="display: flex;">
                                            <div class="" style="display: flex;padding-top: 15px;">
                                                <div class="infoCardedit">
                                                    <div class="infoHeadingedit">KYC Renewal due date
                                                    </div>
                                                    <div class="inputbox">
                                                        <div class="div-control">
                                                            <input
                                                                    [attr.disabled]="null"
                                                                    [bsConfig]="bsConfig"
                                                                    bsDatepicker
                                                                    class="form-control form-control-sm pickerInput"
                                                                    formControlName="kycDueDate"
                                                                    placeholder="Select Date" type="text">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="" style="display: flex;padding-top: 15px;">
                                                <div class="infoCardedit">
                                                    <div class="infoHeadingedit">Comments
                                                    </div>
                                                    <div class="inputbox">
                                                        <textarea class="form-control form-control-sm"
                                                                  formControlName="kycRenewalComments" pInputTextarea
                                                                  style="height: 30px"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div *ngSwitchCase="'tab2'" class="tab-slider--body" id="tab2">
                            <div style="max-width: 100%;background-color: #fbfcfd;padding: 10px;margin: 10px;width: 105em;">
                                <div class="formSectionTitle">
                                    <div class="headerTitle">Ultimate Beneficial Owners Details
                                    </div>
                                </div>
                                <app-ctrm-price-table
                                        (onFormGroupCreated)="updateFormGroupUltimate($event)"
                                        (onrowdelete)="ondeleteultimate($event)"
                                        [checkValidityBeforeAddingNewRow]="true"
                                        [columns]="kycUltimateColumns"
                                        [customTemplateFields]="['personInCharge']"
                                        [customTemplate]="[personincharge]"
                                        [gridDisplay]="true"
                                        [showNewEntryIcon]="true"
                                        [value]="ultimateValues">
                                </app-ctrm-price-table>
                            </div>
                        </div>
                        <div *ngSwitchCase="'tab3'" class="tab-slider--body" id="tab3">
                            <div style="max-width: 100%;background-color: #fbfcfd;padding: 10px;margin: 10px;width: 105em;">

                                <div class="formSectionTitle">
                                    <div class="headerTitle">Top Customers/Supplier Details
                                    </div>
                                </div>
                                <app-ctrm-price-table
                                        (onFormGroupCreated)="updateFormGroupTopBuyer($event)"
                                        [checkValidityBeforeAddingNewRow]="true"
                                        [columns]="kycTopBuyerAndSellerColumns"
                                        [gridDisplay]="true"
                                        [showNewEntryIcon]="true"
                                        [value]="topBuyerValues">
                                </app-ctrm-price-table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="validBox">
                <app-validation-box
                        [formGroup]="kycForm"
                        [validationSections]="validationSections">
                </app-validation-box>
            </div>
        </div>
    </div>
    <div *ngIf="!editmode && (draftdata !== null && draftdata !== undefined)"
         class="draftbackground overflow-hidden ml-0">
        <div class="d-flex w-100" style="margin-top: 5px">
            <div class="flex-grow-1 style-4 mr-2"
                 style="display: inline-grid;width: 81%;height: 35em;overflow: auto;">
                <div style="width: 99%;background-color: #fbfcfd;padding: 10px;margin: 10px;">

                    <div class="formSectionTitle">
                        <div class="headerTitle">Business Profile Details
                        </div>
                    </div>

                    <div class="" style="display: flex;padding-top: 15px;">
                        <div class="infoCarddraft">
                            <div class="infoHeadingdraft">Trade License number
                            </div>
                            <div class="infoContentdraft">{{draftdata.counterpartyBusinessProfile.tradeLicenseNumber }}
                            </div>
                        </div>
                        <div class="infoCarddraft">
                            <div class="infoHeadingdraft">Trade Expiry Date
                            </div>
                            <div class="infoContentdraft">{{draftdata.counterpartyBusinessProfile.tradeExpiryDate | date: 'dd/MM/yyyy' }}
                            </div>
                        </div>
                        <div class="infoCarddraft">
                            <div class="infoHeadingdraft">Trade License Authority
                            </div>
                            <div class="infoContentdraft">{{draftdata.counterpartyBusinessProfile.tradeLicenseAuthority}}
                            </div>
                        </div>

                        <div class="infoCarddraft">
                            <div class="infoHeadingdraft">Nature of Business
                            </div>
                            <div class="infoContentdraft">{{draftdata.counterpartyBusinessProfile.natureOfBusiness}}
                            </div>
                        </div>
                        <div class="infoCarddraft">
                            <div class="infoHeadingdraft">Year of Incorporation
                            </div>
                            <div class="infoContentdraft">{{draftdata.counterpartyBusinessProfile.yearOfIncorporation}}
                            </div>
                        </div>
                        <div class="infoCarddraft">
                            <div class="infoHeadingdraft">Company type
                            </div>
                            <div class="infoContentdraft">{{draftdata.counterpartyBusinessProfile.companyType}}
                            </div>
                        </div>

                    </div>
                </div>
                <div style="width: 99%;background-color: #fbfcfd;padding: 10px;margin: 10px;">
                    <div class="formSectionTitle">
                        <div class="headerTitle">Risk Profile Details
                        </div>
                    </div>
                    <div class="" style="display: flex;padding-top: 15px;">
                        <div class="infoCarddraft">
                            <div class="infoHeadingdraft">Risk Level
                            </div>
                            <div class="infoContentdraft">{{draftdata.counterpartyBusinessProfile.kycRenewalDetails[0].riskLevel}}
                            </div>
                        </div>

                    </div>
                </div>
                <div style="width: 99%;background-color: #fbfcfd;padding: 10px;margin: 10px;">
                    <div class="formSectionTitle">
                        <div class="headerTitle">KYC Review and Renewal dates
                        </div>
                    </div>
                    <div class="" style="display: flex;padding-top: 15px;">
                        <div class="infoCarddraft">
                            <div class="infoHeadingdraft">KYC Renewal due date
                            </div>
                            <div class="infoContentdraft">
                                {{draftdata.counterpartyBusinessProfile.kycRenewalDetails[0].kycDueDate | date:'dd/MM/yyyy'}}
                            </div>
                        </div>
                        <div class="infoCarddraft">
                            <div class="infoHeadingdraft">KYC Created On
                            </div>
                            <div class="infoContentdraft">
                                {{draftdata.counterpartyBusinessProfile.createdTimestamp| date: 'dd/MM/yyyy hh:mm a'}}
                            </div>
                        </div>
                        <div class="infoCarddraft">
                            <div class="infoHeadingdraft">KYC Last Updated On
                            </div>
                            <div class="infoContentdraft">{{draftdata.counterpartyBusinessProfile.updatedTimestamp| date: 'dd/MM/yyyy hh:mm a'}}
                            </div>
                        </div>
                        <div class="infoCarddraft">
                            <div class="infoHeadingdraft">Current KYC Status
                            </div>
                            <div class="infoContentdraft">{{draftdata.counterpartyBusinessProfile.kycStatus}}
                            </div>
                        </div>
                        <div class="infoCarddraft">
                            <div class="infoHeadingdraft">Comments
                            </div>
                            <div class="infoContentdraft">
                                {{draftdata.counterpartyBusinessProfile.kycRenewalDetails[0].kycRenewalComments}}
                            </div>
                        </div>
                    </div>
                </div>
                <div style="width: 99%;background-color: #fbfcfd;padding: 10px;margin: 10px;">
                    <div class="formSectionTitle">
                        <div class="headerTitle">Ultimate Beneficial Owners Details
                        </div>
                    </div>
                    <div class="" style="display: flex;padding-top: 15px;">
                        <table class="table table-bordered">
                            <thead>
                            <tr style="background: #d9dbde !important;">
                                <th class="drafttable_th">Owner name</th>
                                <th class="drafttable_th">Nationality'</th>
                                <th class="drafttable_th">Identification type</th>
                                <th class="drafttable_th">Identification number</th>
                                <th class="drafttable_th">Ownership Percentage</th>
                                <th class="drafttable_th">Role in the Company</th>
                                <th class="drafttable_th">Address of Residence</th>
                                <th class="drafttable_th">Date of Appointment</th>
                                <th class="drafttable_th">Registered Phone number</th>
                                <th class="drafttable_th">Email ID</th>
                                <th class="drafttable_th">Person In Charge</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let ultimate of draftdata.ultimateBeneficialOwners">
                                <td class="drafttable_td">{{ultimate.ownerName}}</td>
                                <td class="drafttable_td">{{ultimate.nationality}}</td>
                                <td class="drafttable_td">{{ultimate.identificationType}}</td>
                                <td class="drafttable_td">{{ultimate.identificationNumber}}</td>
                                <td class="drafttable_td">{{ultimate.ownershipPercentage}}</td>
                                <td class="drafttable_td">{{ultimate.roleInTheCompany}}</td>
                                <td class="drafttable_td">{{ultimate.addressOfResidence}}</td>
                                <td class="drafttable_td">{{ultimate.dateOfAppointment | date:'dd/MM/yyyy'}}</td>
                                <td class="drafttable_td">{{ultimate.registeredPhoneNumber}}</td>
                                <td class="drafttable_td">{{ultimate.emailID}}</td>
                                <td class="drafttable_td">{{ultimate.personInCharge ? 'Yes' : 'No'}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div style="width: 99%;background-color: #fbfcfd;padding: 10px;margin: 10px;">
                    <div class="formSectionTitle">
                        <div class="headerTitle">Ultimate Beneficial Owners Details
                        </div>
                    </div>
                    <div class="" style="display: flex;padding-top: 15px;">
                        <table class="table table-bordered">
                            <thead>
                            <tr style="background: #d9dbde !important;">
                                <th class="drafttable_th">Full Name</th>
                                <th class="drafttable_th">Country</th>
                                <th class="drafttable_th">Line of Business</th>
                                <th class="drafttable_th">Buyer/Supplier/Both</th>
                                <th class="drafttable_th">Website link</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let topBuyer of draftdata.topBuyersAndSuppliers">
                                <td class="drafttable_td">{{topBuyer.fullName}}</td>
                                <td class="drafttable_td">{{topBuyer.country}}</td>
                                <td class="drafttable_td">{{topBuyer.lineOfBusiness}}</td>
                                <td class="drafttable_td">{{topBuyer.traderType}}</td>
                                <td class="drafttable_td">{{topBuyer.websiteLink}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <div>
                <div class="validationdraft">
                    <div class="section bottomGap">
                        <div>
                            <div class="sectionHeader">
                                <p class="sectionHeaderText">Approvers Comments
                                </p>
                            </div>
                            <span layout="row"><hr flex/></span>
                            <div class="placeHolder">
                                <div class="noDataBoxcomments " inviewport="">
                                    <img class="iconClass" src="../assets/images/svg/nocomments.svg">
                                    <p class="noDataHeadercomments">No Comments
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<p-sidebar [(visible)]="editmode" [baseZIndex]="25" [modal]="false" [showCloseIcon]="false"
           [styleClass]="'bottomPane'" position="bottom">
    <div class="bottomSidebarContainer">
        <div class="leftSection">
            <div class="attentionicon">
                <img [src]="'../../../assets/images/svg/attentionblue.svg'" style="inline-size: min-content;"/>
            </div>
            <div class="infoCard">
                <div class="infoHeading">KYC Created On
                </div>
                <div class="infoContent">
                    {{!updatemode ? 'NA' : draftdata.counterpartyBusinessProfile.createdTimestamp | date: 'dd/MM/yyyy hh:mm a'}}
                </div>
            </div>
            <div class="infoCard">
                <div class="infoHeading">KYC Last Updated On
                </div>
                <div class="infoContent">
                    {{!updatemode ? 'NA' : draftdata.counterpartyBusinessProfile.updatedTimestamp | date: 'dd/MM/yyyy hh:mm a'}}
                </div>
            </div>
            <div class="infoCard">
                <div class="infoHeading">Current KYC Status
                </div>
                <div class="infoContent">
                    {{!updatemode ? 'Draft' : draftdata.counterpartyBusinessProfile.kycStatus}}
                </div>
            </div>
        </div>

        <div class="rightSection">
            <button (click)="getkycfunc()" class="cancelBtn">Cancel</button>
            <button (click)="savekyc()" [disabled]="!kycForm.valid"
                    class="priceBtn">{{updatemode ? 'Update' : 'Save'}}</button>
        </div>

    </div>
</p-sidebar>

<ng-template #personincharge let-formControl="formControl" style="text-align: center;">
    <p-checkbox [binary]="true"
                [disabled]="false"></p-checkbox>
</ng-template>
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>

