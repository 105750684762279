<button (click)="onPressClick($event)"
        (onAccessRejected)="onReject($event)"
        [accessKey]="accessKey"
        [access]="access"
        [action]="action"
        [disabled]="disabled"
        [iconPos]="iconPos"
        [label]="label"
        class="labelButton"
        icon="{{icon}}"
        pButton
        type="{{type}}">
</button>
