import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CommonService} from "../../../../services/common.service";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'eod-reports-sidebar',
  templateUrl: './eod-reports-sidebar.component.html',
  styleUrls: ['./eod-reports-sidebar.component.css']
})
export class EodReportsSidebarComponent implements OnInit,OnChanges {

  @Input() eodJobName:string = '';
  @Input() eodRunDate:string = '';
  @Input() isCurrentPublished:boolean = false;
  @Output() onClickTemplate = new EventEmitter();
  expanded: boolean = false;
  selected:string = '';
  links: any[] = [];

  constructor(public commonService:CommonService) { }

  ngOnInit(): void {
  }

  toggleExpanded() {
    this.expanded = !this.expanded;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['eodJobName']) {
      this.loadEodPublishedList(changes['eodJobName'].currentValue);
    }
  }

  private loadEodPublishedList(eodJobName:string) {
    if(eodJobName !== null && eodJobName !== undefined && eodJobName.length > 0) {
      this.links = [];
      this.selected = this.eodRunDate;
      let _this = this;
      this.commonService.getJSONByURL(environment.base_url+'/api/eodDashboard/v1/publishedeodlist?tenantId='+this.commonService.getFromStorage('tenantId')+'&eodJobName='+eodJobName).subscribe((next:any) => {
        _this.links = next;
        if(!_this.isCurrentPublished) {
          _this.links.splice(0,0,{eodJobName:_this.eodJobName,eodRunDate:_this.eodRunDate});
        }
      });
    }
  }

  onClick(link: any) {
    this.selected = link['eodRunDate'];
    this.expanded = false;
    this.onClickTemplate.emit(link);
  }

  getTemplateName(link: any, i: number) {
    if( i === 0) {
      if(!this.isCurrentPublished) {
        return link['eodJobName']+"_"+this.commonService.getFormattedDate(link['eodRunDate'],'ddMMyyyy')+" ( Unpublished )"
      }
    }
    return link['eodJobName']+"_"+this.commonService.getFormattedDate(link['eodRunDate'],'ddMMyyyy');
  }
}
