import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'ctrm-note-editor',
  templateUrl: './ctrm-note-editor.component.html',
  styleUrls: ['./ctrm-note-editor.component.css']
})
export class CtrmNoteEditorComponent implements OnInit, OnChanges {
  @Input() text: any;
  @Input() optional: boolean = true;
  @Output() onClickSave = new EventEmitter();
  @Output() onClickClose = new EventEmitter();
  val: string = '';
  @Input() modalState: boolean = false;
  @Input() heading: string = "Note";

  constructor() {
  }

  ngOnInit(): void {
    this.val = this.text;
  }

  throwOutput() {
    this.onClickSave.emit(this.val);
    this.val = '';
  }

  closeModal() {
    this.modalState = false;
    this.val = '';
    this.onClickClose.emit(this.heading);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['text']) {
      this.val = changes['text'].currentValue;
    }
  }

  isThereAnyValue() {
    if (this.optional) {
      return false;
    }
    return !(this.val !== undefined && this.val.length > 0);
  }

  onValueChange(value: any) {
    if (value === null || value === undefined || value.length === 0) {
      this.val = '';
    }
  }

}
