import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'xceler-slider',
  templateUrl: './xceler-slider.component.html',
  styleUrls: ['./xceler-slider.component.css']
})
export class XcelerSliderComponent implements OnInit {

  @Input() min:number;
  @Input() max:number;
  @Input() minlabel:string;
  @Input() maxlabel: string;
  disabled = true;
  @Input() value: any;
  vertical = false;

  constructor() { }

  ngOnInit(): void {
  }

}
