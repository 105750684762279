import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { CommonService } from "../../services/common.service";
import { MessageService, SelectItem, Table } from "primeng";
import { BreadcrumbService } from "../../Components/ctrm-breadcrumb/breadcrumb.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup } from "@angular/forms";
import { environment } from "../../../environments/environment";
import { FilterUtils } from "primeng/utils";
import { ExcelServiceService } from "../../services/excel-service.service";
import { Filter } from "../../grid/preference/filter/filter.model";
import { Tcolumn } from "../../grid/tcolumn.model";
import { HttpErrorResponse } from "@angular/common/http";
import { KeyValue } from "../../grid/key-value.model";

@Component({
  selector: "app-settlement",
  templateUrl: "./settlement.component.html",
  styleUrls: ["./settlement.component.css"],
})
export class SettlementComponent implements OnInit {
  selectedPlans: any[] = [];
  quantityRoundOff: number = 3;
  priceRoundOff: number = 2;
  selectedCashflows: any[] = [];
  planCols: any[] = [];
  newPlanCols: any[] = [];
  planColsChild:any[] =[];
  planData: any[] = [];
  private selectedTrade: any;
  cashFlowCols: any[];
  private isFiltered: boolean = false;
  expandedRows: any = {};
  isLoadingInvoice: boolean = false;
  plannedObligation: any;
  private filteresPlannedObligationId: any = "";
  draftData: any[] = [];
  planTableHeight: string = "";
  tabOptions: SelectItem[] = [];
  tabOptions2: SelectItem[] = [];
  tabValue: string;
  tabValue2: string;
  paymentInsFG: FormGroup;
  formDisplay: boolean = false;
  filterArray: any[] = ["ACTUALIZED", "PARTIALLY_SETTLED"];
  formStyle = {
    width: "70%",
    height: "auto",
    maxHeight: "95%",
    padding: "0px",
    background: "#ffffff",
  };
  currentSelectedRow: number = -1;
  mode: boolean;
  formHeight: string = "500px";
  paymentInstruction: boolean = false;
  applyingFilter: boolean = false;
  plannedObligationId: string = "";
  tradeId: string = "";
  globalFilterValue: string = "";
  items: any[] = [];

  groupBtn: any = false;

  groupBtnCheck = false;

  @ViewChild("planTable", { static: true }) planTable: Table;
  @ViewChild("mainTable") tradeTable: Table;
  operationDisplay: boolean = false;
  sellDisable: boolean = false;
  purchaseDisable: boolean = false;
  draftForm: boolean = false;
  dummyData: any[] = [];

  columns: Tcolumn[] = [];
  isRefreshing: boolean = false;
  loadingMessage: string = "Nothing to Settle ...";
  filters: Filter[] = [];
  queryExpandedPlannedObligationId = "";
  currentPageNumber: number = 0;
  pageSize: number = 0;
  totalElements: number = 0;
  totalPages: number = 0;
  matchType: string = "";
  displayidinfo: boolean = false;
  infoRowdata: any;
  infotradeId: string = "";
  detailsOf: string = "";
  selectedTradePrice: any;
  bottom_buttons: any[] = [];

  constructor(
    public commonService: CommonService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    public breadCrumbService: BreadcrumbService,
    private router: Router,
    private excelService: ExcelServiceService
  ) {}

  ngOnInit(): void {
    FilterUtils["notContainsValue"] = function gte(
      value: any,
      filter: any[]
    ): boolean {
      if (filter === undefined || filter === null) {
        return false;
      }
      if (value === undefined || value === null || value.length === 0) {
        return false;
      }
      return !filter.includes(value);
    };
    let _this = this;
    this.items = [
      {
        label: "Generate CN/DN",
        command: () => {
          _this.getPayload("Doc ByPass CN/DN");
        },
      },
    ];

    let tenantConfig = JSON.parse(
      _this.commonService.getFromStorage("tenantConfig")
    );
    if (tenantConfig && tenantConfig.roundingFormat) {
      _this.quantityRoundOff = tenantConfig.roundingFormat.quantityRounding;
      _this.priceRoundOff = tenantConfig.roundingFormat.priceRounding;
    } else {
      _this.quantityRoundOff = 3;
      _this.priceRoundOff = 2;
    }
    this.breadCrumbService.makeBreadcrumbTo(
      this.commonService.FINANCE_MAP_KEY,
      "/settlement"
    );
    this.tabOptions = [
      { label: "Pending Trade", value: "pending" },
      { label: "Settled Trade", value: "settled" },
    ];
    this.tabOptions2 = [
      { label: "Buy", value: "BUY" },
      { label: "Sell", value: "SELL" },
    ];
    this.tabValue = "pending";
    this.tabValue2 = "BUY";
    let statusList: KeyValue[] = [new KeyValue()];
    statusList.push(new KeyValue("Actualized", "ACTUALIZED"));
    statusList.push(
      new KeyValue("Partially Actualized", "PARTIALLY ACTUALIZED")
    );
    statusList.push(new KeyValue("Settled", "SETTLED"));

    let tradeTypeList: KeyValue[] = [new KeyValue()];
    tradeTypeList.push(new KeyValue("Buy", "BUY"));
    tradeTypeList.push(new KeyValue("Sell", "SELL"));
    this.planCols = [
      { field: "plannedObligationId", header: "Planned Obligation" },
      { field: "tradeId", header: "Trade Id" },
      {
        field: "tradeTransactionType",
        header: "Trade Type",
        filterColumn: new Tcolumn(
          "tradeTransactionType",
          "Trade Type",
          "OB",
          1,
          true,
          tradeTypeList
        ),
      },
      {
        field: "matchType",
        header: "Match Type",
        filterColumn: new Tcolumn("matchType", "Match Type", "T", 1, true),
      },
      { field: "vesselName", header: "Vessel Name" },
      { field: "vesselId", header: "Voyage Number" },
      { field: "tradeDateTime", header: "Trade Date", type: "date" },
      { field: "plannedQuantity", header: "Planned Qty" },
      {
        field: "deliveryStartDate",
        header: "Delivery Start Date",
        type: "date",
      },
      { field: "deliveryEndDate", header: "Delivery End Date", type: "date" },
      { field: "incoterm", header: "Incoterm" },
      { field: "location", header: "Location" },
      { field: "cropOrigin", header: "Origin" },
      { field: "grade", header: "Grade" },
      { field: "tradeQuantity", header: "Trade Qty" },
      { field: "priceType", header: "Price Type" },
      { field: "tradePrice", header: "Trade Price" },
      { field: "profitcenter", header: "Profit Center" },
      { field: "counterparty", header: "Counter Party" }
    ];
    this.planColsChild = [
      { field: "plannedObligationId", header: "Planned Obligation" ,valueFunction: (rowData) => {
          return rowData["plannedObligationId"] + " (" + rowData["splitSequenceNumber"] + ")"
        }},
      { field: "tradeDateTime", header: "Trade Date", type: "date" },
      { field: "plannedQuantity", header: "Planned Qty" ,valueFunction: (rowData) => {
          return _this.commonService.formatNumberWithoutComma(rowData['plannedQuantity'],_this.quantityRoundOff) +" "+ rowData['quantityUOM'];
        }},
      { field: "deliveryStartDate", header: "Delivery Start Date", type: "date" },
      { field: "deliveryEndDate", header: "Delivery End Date", type: "date" },
      { field: "incoterm", header: "Incoterm" },
      { field: "location", header: "Location" },
      { field: "cropOrigin", header: "Origin" },
      { field: "grade", header: "Grade" },
      { field: "tradeQuantity", header: "Trade Qty",valueFunction: (rowData) => {
          return _this.commonService.formatNumberWithoutComma(rowData['tradeQuantity'],_this.quantityRoundOff) +" "+ rowData['quantityUOM'];
        } },
      { field: "priceType", header: "Price Type" },
      { field: "tradePrice", header: "Trade Price",valueFunction:(rowData) => {
        return _this.commonService.formatNumberWithoutComma(rowData['tradePrice'],_this.priceRoundOff) + " " + rowData['tradePriceCurrency']+"/"+rowData['tradePriceUom']
        }},
      { field: "profitcenter", header: "Profit Center" },
      { field: "counterparty", header: "Counter Party" }
    ];
    this.columns = this.commonService.convertJsonIntoTcolumnsArray(
      this.planCols
    );

    let settlementValueFunction = function (value: any) {
      return (
        _this.commonService.getFormatedNumber(value["settlementValue"]) +
        " " +
        value["settlementCurrency"]
      );
    };

    this.cashFlowCols = [
      { field: "cashflowId", header: "Cashflow ID" },
      { field: "type", header: "Cashflow Type" },
      { field: "paymentDueDate", header: "Payment Due Date", type: "date" },
      {
        field: "settlementValue",
        header: "Settlement Amount",
        valueFunction: settlementValueFunction,
      },
      { field: "financialAmountType", header: "Amount Type" },
      { field: "quantityStatus", header: "Qty Status" },
      { field: "priceStatus", header: "Price Status" },
      { field: "counterparty", header: "Counterparty" },
      { field: "commodity", header: "Commodity" },
    ];
    this.route.queryParams.subscribe((params) => {
      if (
        params !== undefined &&
        params !== null &&
        Object.keys(params).length > 0
      ) {
        let keys: any[] = Object.keys(params);
        if (keys.includes("value")) {
          let value = params["value"];
          _this.isFiltered = true;
          _this.filteresPlannedObligationId = value;
          this.getData();
        } else if (keys.includes("expand")) {
          _this.queryExpandedPlannedObligationId =
            params["plannedObligationId"];
          _this.planTable.expandedRowKeys[
            this.queryExpandedPlannedObligationId
          ] = true;
        }
      }
    });
  }

  getClassForHeading(index) {
    return index > 0 ? "headerNormal" : "noPipe";
  }

  onUniqueClick(value) {}

  getFilterValue($event: Event) {
    this.globalFilterValue = event.target["value"];
    this.planTable.filterGlobal(event.target["value"], "contains");
  }

  goTo() {
    this.router.navigate(["/tradeActualization"]);
  }

  calculateHeight() {
    let offsetTop = this.planTable.el.nativeElement["offsetTop"];
    let offsetHeight =
      this.planTable.el.nativeElement["offsetParent"]["offsetHeight"];
    if (this.getPagination()) {
      offsetHeight = offsetHeight - 30;
    }
    this.planTableHeight = offsetHeight - offsetTop - 22 + "px";
  }

  ngAfterViewInit() {
    this.calculateHeight();
  }

  onFormCancel() {
    this.formDisplay = false;
  }

  @HostListener("window:resize", [])
  public onResize() {
    this.calculateHeight();
  }

  sendForApproval() {}

  openPaymentIns() {
    this.paymentInstruction = true;
    this.formStyle = {
      width: "35%",
      height: "auto",
      maxHeight: "95%",
      padding: "0px",
      background: "#ffffff",
    };
    this.formDisplay = true;
  }

  openGenInvoice() {
    this.paymentInstruction = false;
    this.formStyle = {
      width: "70%",
      height: "auto",
      maxHeight: "95%",
      padding: "0px",
      background: "#ffffff",
    };
    this.formDisplay = true;
  }

  //To get the value of Trade Type since the field is Boolean
  getTradeTypeValue(rowDatum: any) {
    return rowDatum === "BUY" ? "Buy" : "Sell";
  }

  getStyle(selected: string) {
    if (this.filterArray.includes(selected)) {
      return "statShadow";
    } else {
      return "";
    }
  }

  onTabChange(value:any){
    this.tabValue2=value.value;
    if(this.filters && this.filters.length>0){
      this.onApplyFilter(this.filters, true);
      return;
    }
    this.getData();

  }

  selectFilter(filterName: string) {
    const elementsIndex = this.filterArray.findIndex(
      (element) => element == filterName
    );
    if (!this.filterArray.includes(filterName)) {
      this.filterArray.push(filterName);
    } else {
      if (this.filterArray.length > 1) {
        this.filterArray.splice(elementsIndex, 1);
      } else {
        this.filterArray = ["ACTUALIZED", "PARTIALLY_SETTLED"];
      }
    }
    this.getData();
  }

  //ON EXPANDING A ROW, API IS CALLED TO GET ALL CASH FLOWS FOR THE EXPANDED PLANNED OBLIGATIONS (WHEN CLICKED ON ROW EXPAND ARROW)
  onRowExpand(plannedObligationId: any, rowIndex, expand: boolean = false) {
    this.commonService
      .getJSONByURL(
        environment.base_url +
          "/api/settlement/v1/getplannedobligationcashflow?plannedobligationid=" +
          plannedObligationId +
          "&tenantId=" +
          this.commonService.getFromStorage("tenantId")
      )
      .subscribe((next) => {
        this.planData[rowIndex]["cashflowList"] = next;
        this.matchType = this.planData[rowIndex]["matchType"];
        this.selectedTradePrice = this.planData[rowIndex];

        if (expand) {
          this.expandedRows = {};
          this.expandedRows[this.queryExpandedPlannedObligationId] = true;
        }
      });
  }

  //TO GET THE DATA FOR SETTLEMENT GRID (ACCORDING TO THE FILTER PROVIDED)
  getData() {
    let _this = this;
    this.planData = [];
    let payload = {};
    this.planTable.selectionKeys = {};
    this.planTable.expandedRowKeys = {};
    payload = {
      statusFilter: this.filterArray,
      isFiltered: this.isFiltered,
      plannedObligationId: this.filteresPlannedObligationId,
    };
    let rowIndex = 0;
    this.commonService
      .getJSONByObject(
        environment.base_url +
          "/api/settlement/v1/loadsettelmentgrid?tenantId=" +
          this.commonService.getFromStorage("tenantId") +"&tradeTransactionType="+this.tabValue2+
          "&page=" +
          this.currentPageNumber +
          "&size=20",
        payload
      )
      .subscribe((next: any) => {
        next["content"].forEach((data, index) => {
          data["selectedChild"] = [];
          data["index"] = index;
          if (
            _this.queryExpandedPlannedObligationId ===
            data["plannedObligationId"]
          ) {
            rowIndex = index;
          }
          _this.planData.push(data);
        });
        _this.planData = next["content"];
        _this.pageSize = next["pageable"]["pageSize"];
        _this.totalElements = next["totalElements"];
        _this.totalPages = next["totalPages"];
        if (
          _this.queryExpandedPlannedObligationId !== null &&
          _this.queryExpandedPlannedObligationId !== undefined &&
          _this.queryExpandedPlannedObligationId.length > 0
        ) {
          _this.onRowExpand(
            _this.queryExpandedPlannedObligationId,
            rowIndex,
            true
          );
        }
        this.calculateHeight();
      });
  }

  onApplyFilter(value: Filter[], resetInfo: boolean = true) {
    let _this = this;
    if (!this.isFiltered) {
      this.isRefreshing = true;
      this.planData = [];
      let payload = this.commonService.getFilterApiPayload(value)
       if(payload.filter(record=>record.fieldName=="tradeTransactionType").length==0){
         payload.push({"fieldName": "tradeTransactionType","condition": "equals","value": this.tabValue2,"secondValue": ""})
       }else{
         const updatedPayload = payload.filter(condition => condition.fieldName !== "tradeTransactionType");
         payload=updatedPayload;
         payload.push({"fieldName": "tradeTransactionType","condition": "equals","value": this.tabValue2,"secondValue": ""})
       }
      if (payload.length > 1) {
        this.filters = value;
        let url =
          environment.base_url +
          "/api/settlement/v1/getsettlementbycriteria?statusFilter=" +
          this.filterArray +
          "&tenantId=" +
          this.commonService.getTenantId() +
          "&page=" +
          this.currentPageNumber +
          "&size=20";
        this.commonService.post(url, payload).subscribe(
          (next: any) => {
            _this.isRefreshing = false;
            _this.planData = next["content"];
            _this.pageSize = next["pageable"]["pageSize"];
            _this.totalElements = next["totalElements"];
            _this.totalPages = next["totalPages"];
            _this.calculateHeight();
          },
          (error: HttpErrorResponse) => {
            _this.isRefreshing = false;
            _this.showToast(
              _this.commonService.getHttpErrorMessage(error, "Settlement"),
              "error"
            );
          }
        );
      } else {
        this.filters = [];
        this.getData();
      }
    }
  }

  onPageChange(value: any) {
    this.currentPageNumber = value["page"];
    if (
      this.filters !== null &&
      this.filters !== undefined &&
      this.filters.length > 0
    ) {
      this.onApplyFilter(this.filters, false);
    } else {
      this.getData();
    }
  }

  getPagination() {
    return this.totalElements > this.pageSize;
  }

  //ON SELECTING A CASHFLOW
  onChildSelected(value: any, rowData) {
    this.selectedTrade = rowData;
    // this.planData[rowData['index']]['selectedChild'] = value;
    if (value.length !== 0) {
      if (
        !this.selectedCashflows.some(
          (item) => item.cashflowId == value["data"]["cashflowId"]
        )
      ) {
        this.selectedCashflows.push(value["data"]);
      } else {
        this.selectedCashflows = this.selectedCashflows.filter(
          ({ cashflowId }) => cashflowId !== value["data"]["cashflowId"]
        );
      }
    }

    if (this.selectedCashflows.length === 0) {
      this.operationDisplay = false;
    } else {
      this.operationDisplay = true; //Toggling Bulk Operation Bottom Bar
    }
  }

  clearChild(rowData) {
    this.planData[rowData["data"]["index"]]["selectedChild"] = [];
  }

  expandRow(rowData) {}

  getTradeQty(rowData: any) {
    return (
      this.commonService.formatNumberWithoutComma(
        rowData["tradeQuantity"],
        this.quantityRoundOff
      ) +
      " " +
      rowData["quantityUOM"]
    );
  }

  getTradePrice(rowData: any) {
    return (
      this.commonService.getFormatedNumber(rowData["tradePrice"]) +
      " " +
      rowData["tradePriceCurrency"] +
      "/" +
      rowData["tradePriceUom"]
    );
  }

  //ON CLICK OF BOTTOM TOOLBAR BUTTON TO OPEN GENERATE INVOICE FORM
  generateInvoice(mode) {
    this.mode = mode;
    this.openDialog();
  }

  //Opening Dialog and filling Invoice form with default values
  openDialog() {
    let _this = this;
    let cashflowType: string = this.selectedCashflows[0]["type"];
    let type = "";
    let typeCheck = false;
    let draftInvoicePayload: {}, invoiceType: string;
    invoiceType = this.mode ? "sell" : "purchase";
    if (cashflowType.toLowerCase().includes("premium")) {
      invoiceType = invoiceType + " " + "Premium";
    } else if (cashflowType.toLowerCase().includes("discount")) {
      invoiceType = invoiceType + " " + "Discount";
    }
    draftInvoicePayload = {
      invoiceType: invoiceType,
      cashFlowList: this.selectedCashflows,
      counterPartyName: this.selectedCashflows[0]["counterparty"],
      company: this.selectedCashflows[0]["company"],
      planMatchType: this.matchType,
    };
    this.commonService
      .getJSONByObject(
        environment.base_url +
          "/api/invoice/v1/generatedraftinvoice?tenantId=" +
          this.commonService.getFromStorage("tenantId"),
        draftInvoicePayload
      )
      .subscribe(
        (next: any) => {
          this.draftData = next;
          this.draftForm = true;
          this.operationDisplay = false;
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: this.commonService.getHttpErrorMessage(error,"Settlement"),
          });
        }
      );
    if (
      this.selectedCashflows[0]["type"] == "Trade Debit Note" ||
      this.selectedCashflows[0]["type"] == "Trade Credit Note"
    ) {
      type = "Trade Reversal";
      typeCheck = true;
    }
    if (
      this.selectedCashflows[0]["type"] == "Cost Debit Note" ||
      this.selectedCashflows[0]["type"] == "Cost Credit Note"
    ) {
      type = "Cost Reversal";
      typeCheck = true;
    }
    if (
      this.matchType.toLowerCase() === "washout" &&
      (this.selectedCashflows[0]["type"] == "Trade Debit Note" ||
        this.selectedCashflows[0]["type"] == "Trade Credit Note")
    ) {
      let cashflowCriteria: any[] = [];
      let planIds = [];
      let stages = [];
      let quantityStatuses = [];
      this.selectedCashflows.forEach((cashflow) => {
        if (!planIds.includes(cashflow["planId"])) {
          planIds.push(cashflow["planId"]);
        }
        if (!stages.includes(cashflow["stage"])) {
          stages.push(cashflow["stage"]);
        }
        if (!quantityStatuses.includes(cashflow["quantityStatus"])) {
          quantityStatuses.push(cashflow["quantityStatus"]);
        }
      });
      cashflowCriteria.push({
        fieldName: "tenantId",
        condition: "equals",
        value: this.commonService.getFromStorage("tenantId"),
      });
      cashflowCriteria.push({
        fieldName: "planId",
        condition: "in",
        value: planIds,
      });
      cashflowCriteria.push({
        fieldName: "quantityStatus",
        condition: "in",
        value: quantityStatuses,
      });
      cashflowCriteria.push({
        fieldName: "stage",
        condition: "in",
        value: stages,
      });
      cashflowCriteria.push({
        fieldName: "type",
        condition: "in",
        value: ["Trade Debit Note", "Trade Credit Note"],
      });
      cashflowCriteria.push({
        fieldName: "cashflowStatus",
        condition: "equals",
        value: "active",
      });
      this.commonService
        .post(
          environment.base_url + "/api/cashflow/v1/getcashflowbycriteria",
          cashflowCriteria
        )
        .subscribe((next: any[]) => {
          if (next !== null && next !== undefined && next.length > 0) {
            _this.selectedCashflows = [];
            next.forEach(function (cashflow) {
              if (!_this.isFullyInvoiced(cashflow)) {
                cashflow["settlementValue"] =
                  cashflow["settlementValue"] -
                  cashflow["invoicedSettlementValue"];
                _this.selectedCashflows.push(cashflow);
              }
            });
          }
        });
    }
    if (type == "Trade Reversal" && typeCheck) {
      let cashflowCriteria: any[] = [];
      let plannedOblIds = [];
      let stages = [];
      let quantityStatuses = [];
      this.selectedCashflows.forEach((cashflow) => {
        if (!plannedOblIds.includes(cashflow["plannedObligationId"])) {
          plannedOblIds.push(cashflow["plannedObligationId"]);
        }
        if (!stages.includes(cashflow["stage"])) {
          stages.push(cashflow["stage"]);
        }
        if (!quantityStatuses.includes(cashflow["quantityStatus"])) {
          quantityStatuses.push(cashflow["quantityStatus"]);
        }
      });
      cashflowCriteria.push({
        fieldName: "tenantId",
        condition: "equals",
        value: this.commonService.getFromStorage("tenantId"),
      });
      cashflowCriteria.push({
        fieldName: "plannedObligationId",
        condition: "in",
        value: plannedOblIds,
      });
      cashflowCriteria.push({
        fieldName: "quantityStatus",
        condition: "in",
        value: quantityStatuses,
      });
      cashflowCriteria.push({
        fieldName: "stage",
        condition: "in",
        value: stages,
      });
      cashflowCriteria.push({
        fieldName: "type",
        condition: "equals",
        value: type,
      });
      cashflowCriteria.push({
        fieldName: "cashflowStatus",
        condition: "equals",
        value: "active",
      });
      this.commonService
        .post(
          environment.base_url + "/api/cashflow/v1/getcashflowbycriteria",
          cashflowCriteria
        )
        .subscribe((next: any[]) => {
          if (next !== null && next !== undefined && next.length > 0) {
            _this.selectedCashflows.push(next[0]);
          }
        });
    }
    if (type == "Cost Reversal" && typeCheck) {
      let cashflowCriteria: any[] = [];
      let costIds = [];
      let stages = [];
      let quantityStatuses = [];
      this.selectedCashflows.forEach((cashflow) => {
        if (!costIds.includes(cashflow["costId"])) {
          costIds.push(cashflow["costId"]);
        }
        if (!stages.includes(cashflow["stage"])) {
          stages.push(cashflow["stage"]);
        }
        if (!quantityStatuses.includes(cashflow["quantityStatus"])) {
          quantityStatuses.push(cashflow["quantityStatus"]);
        }
      });
      cashflowCriteria.push({
        fieldName: "tenantId",
        condition: "equals",
        value: this.commonService.getFromStorage("tenantId"),
      });
      cashflowCriteria.push({
        fieldName: "costId",
        condition: "in",
        value: costIds,
      });
      cashflowCriteria.push({
        fieldName: "quantityStatus",
        condition: "in",
        value: quantityStatuses,
      });
      cashflowCriteria.push({
        fieldName: "stage",
        condition: "in",
        value: stages,
      });
      cashflowCriteria.push({
        fieldName: "type",
        condition: "equals",
        value: type,
      });
      cashflowCriteria.push({
        fieldName: "cashflowStatus",
        condition: "equals",
        value: "active",
      });
      this.commonService
        .post(
          environment.base_url + "/api/cashflow/v1/getcashflowbycriteria",
          cashflowCriteria
        )
        .subscribe((next: any[]) => {
          if (next !== null && next !== undefined && next.length > 0) {
            _this.selectedCashflows.push(next[0]);
          }
        });
    }
  }

  isFullyInvoiced(cashflow) {
    if (
      cashflow["invoicedSettlementValue"] - cashflow["settlementValue"] ==
      0
    ) {
      return true;
    }
    return false;
  }

  // Checking selected Cash flows to ensure it can go for Generation of Invoice operation
  // Checks are for Same FINANCIAL AMOUNT, same COUNTER PARTY and same SETTLEMENT CURRENCY
  // param Mode (true for sell invoice , False for purchase invoice) required for triggering generateInvoice method if condition is successful
  cashFlowCheck() {
    let firstCF = {};
    let matchFailFlag: boolean = false;
    this.plannedObligationId = this.selectedCashflows[0]["plannedObligationId"];
    this.tradeId = this.selectedCashflows[0]["tradeId"];
    firstCF = this.selectedCashflows[0];
    this.selectedCashflows.forEach((cf, index) => {
      //TODO: Check for CP in response
      if (
        cf["counterparty"] === firstCF["counterparty"] &&
        cf["financialAmountType"] === firstCF["financialAmountType"] &&
        cf["type"] === firstCF["type"] &&
        cf["settlementCurrency"] === firstCF["settlementCurrency"] &&
        cf["tradeId"] === firstCF["tradeId"]
      ) {
        if (index === this.selectedCashflows.length - 1) {
          if (
            this.selectedTrade["tradeTransactionType"].toLowerCase() === "buy"
          ) {
            this.generateInvoice(false);
          } else {
            this.generateInvoice(true);
          }
        }
        if (firstCF["plannedObligationId"] !== cf["plannedObligationId"]) {
          this.plannedObligationId = "";
        }
        if (firstCF["tradeId"] !== cf["tradeId"]) {
          this.tradeId = "";
        }
        return;
      } else {
        if (!matchFailFlag) {
          let errorMessage = "Selected cashflows are not eligible. ";
          //TODO: Check for CP in response
          if (cf["tradeId"] !== firstCF["tradeId"]) {
            errorMessage +=
              "\nTradeId not matched for cashflow " + cf["cashflowId"];
          }
          if (cf["counterparty"] !== firstCF["counterparty"]) {
            errorMessage +=
              "\nCounterparty not matched for cashflow " + cf["cashflowId"];
          }
          if (cf["financialAmountType"] !== firstCF["financialAmountType"]) {
            errorMessage +=
              "\nFinancial Amount Type not matched for cashflow " +
              cf["cashflowId"];
          }
          if (cf["type"] !== firstCF["type"]) {
            errorMessage +=
              "\nCashflow Type not matched for cashflow " + cf["cashflowId"];
          }
          if (cf["settlementCurrency"] !== firstCF["settlementCurrency"]) {
            errorMessage +=
              "\nSettlement Currency not matched for cashflow " +
              cf["cashflowId"];
          }
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: errorMessage,
          });
          matchFailFlag = true;
        }
      }
    });
  }

  onDraftClose() {
    this.selectedCashflows = [];
    this.selectedPlans = [];
    this.planTable.selectionKeys = {};
    this.planTable.expandedRowKeys = {};
    this.operationDisplay = false;
    this.draftForm = false;
  }

  //WHEN THE USER SENDS THE DRAFT INVOICE FOR APPROVAL
  onSendForApproval() {
    this.messageService.add({
      severity: "info",
      summary: "Success",
      detail: "Invoice has been generated!",
    });
    this.selectedCashflows = [];
    this.selectedPlans = [];
    this.planTable.selectionKeys = {};
    this.planTable.expandedRowKeys = {};
    this.refreshSettlement();
    this.draftForm = false;
  }

  //TO REFRESH THE SETTLEMENT TABLE;
  refreshSettlement() {
    if (
      this.filters !== undefined &&
      this.filters !== null &&
      this.filters.length > 0
    ) {
      this.onApplyFilter(this.filters, false);
    } else {
      this.getData();
    }
  }

  getFilter(dropDownName: string, dropDownValue: any) {
    return {
      firstValue: dropDownValue,
      columnName: dropDownName,
      matchType: "notContainsValue",
    };
  }

  onClickExport() {
    this.excelService.exportDashboardDataView(
      this.planCols,
      this.planData,
      "Settlement",
      "Settlement"
    );
  }

  showToast(msg, severity: string = "success") {
    this.messageService.add({
      severity: severity,
      summary: msg,
    });
  }

  getSelectedCashflows() {
    return this.selectedCashflows;
  }

  onClose(value: any) {
    this.displayidinfo = false;
  }

  plannedObligationclick(value: any, rowData: any) {
    this.infotradeId = rowData.tradeId;
    let _this = this;
    this.commonService
      .getJSONByURL(
        environment.base_url +
          "/api/plannedObligation/v1/getplannedobligationsbyplannedobligationid?plannedObligationId=" +
          value +
          "&tenantId=" +
          this.commonService.getFromStorage("tenantId")
      )
      .subscribe((next) => {
        _this.infotradeId = next["tradeId"];
        _this.infoRowdata = next;
        _this.detailsOf = "plannedObligation";
        _this.displayidinfo = true;
      });
  }

  tradeIdclick(value: any) {
    this.infoRowdata = { tradeId: value.tradeId };
    this.infotradeId = value.tradeId;
    this.detailsOf = "tradeId";
    this.displayidinfo = true;
  }

  getRowValue(rowData, col: any) {
    if (col.valueFunction !== undefined && col.valueFunction !== null) {
      return this.commonService.runFunction(rowData, col.valueFunction);
    }
    if (col.type !== undefined && col.type === "date") {
      return this.commonService.getDateForOutput(rowData[col.field]);
    }
    return rowData[col.field] === undefined ||
      rowData[col.field] === null ||
      rowData[col.field].length === 0
      ? "-"
      : rowData[col.field];
  }

  onRowSelectionChange(event: any,unselected:boolean,fromChild:boolean = false) {

    if(event != null) {
      let row = event.data;
      if(row['splitted']) {
        this.operationDisplay = false;
        this.onExpandRow(event,true);
        return;
      }
      if(fromChild) {
        let unselected = event.unselected;
        if(!unselected) {
          this.selectedPlans.push(row);
        } else {
          this.selectedPlans.splice(this.selectedPlans.indexOf(row),1);
        }
      }
    }
    if(unselected) {
      this.selectedPlans = this.selectedPlans.filter(item => item.plannedObligationId == event.data.plannedObligationId)
    }
    this.bottom_buttons = [];
    if (this.selectedPlans.length > 0) {
      if (
        this.selectedPlans[0]["tradeTransactionType"].toLowerCase() === "buy"
      ) {
        this.mode = false;
      } else {
        this.mode = true;
      }
      this.bottom_buttons.push("Cost Invoice");
      this.bottom_buttons.push("Invoice");
    }
    this.operationDisplay = this.canGenerateForMultipleSelection(this.selectedPlans) || this.selectedPlans.length == 1;
  }

  public canGenerateForMultipleSelection(list: any[] = []) {
    const isAllSameObligation = this.selectedPlans.every(
      (e) => e.plannedObligationId == this.selectedPlans[0].plannedObligationId
    );
    const validGroupInvoice = this.selectedPlans.every(
      (e) =>
        e.company == this.selectedPlans[0].company &&
        e.counterparty == this.selectedPlans[0].counterparty &&
        e.vesselId == this.selectedPlans[0].vesselId &&
        e.tradePriceCurrency == this.selectedPlans[0].tradePriceCurrency
    );

    if ((list?.length ?? 0) > 1 && validGroupInvoice && !isAllSameObligation) {
      this.showGroupInvoiceBtn();
    } else {
      this.hideGroupInvoiceBtn();
    }
    return (isAllSameObligation || validGroupInvoice) && list.length > 0;
  }
  hideGroupInvoiceBtn() {
    this.groupBtn = false;
  }

  showGroupInvoiceBtn() {
    this.groupBtn = true;
  }

  getGroupPayload(generateGroupInvoice: any) {
    let _this = this;
    let groupPayload = [];
    this.isLoadingInvoice = true;
    this.draftForm = true;
    this.operationDisplay = false;
    for (const element of this.selectedPlans) {
      let plannedObligationIds = [
        {
          plannedObligationId: element.plannedObligationId,
          splitSequenceNumber: element.splitSequenceNumber,
        },
      ];

      let tradeId = element.tradeUuid;
      this.plannedObligation = element;
      let isAdvance = generateGroupInvoice === "Advance Invoice";
      let costOnly = generateGroupInvoice === "Cost Invoice";
      let docbypass =
        generateGroupInvoice === "Doc ByPass" ||
        generateGroupInvoice === "Doc ByPass CN/DN";
      let docbypassCnDn = generateGroupInvoice === "Doc ByPass CN/DN";
      let payload = {
        plannedObligationIds: plannedObligationIds,
        tradeUuid: tradeId,
        advance: isAdvance,
        proforma: false,
        costOnly: costOnly,
        docbypass: docbypass,
        docbypassCnDn: docbypassCnDn,
        attachCost: this.commonService.getFromTenantConfig(
            false,
            "invoiceConfig",
            "attachCost"
        ),
        attachCharges: this.commonService.getFromTenantConfig(
            false,
            "invoiceConfig",
            "attachCharge"
        ),
      };
      groupPayload.push(payload);
    }

    this.commonService
      .post(
        `${environment.base_url}/api/invoice/v1/getDraftGroupInvoicePayload`,
        groupPayload
      )
      .subscribe(
        (next: any) => {
          _this.groupBtnCheck = true;
          _this.draftData = next;
          _this.isLoadingInvoice = false;
          _this.draftForm = true;
        },
        (error: HttpErrorResponse) => {
          _this.isLoadingInvoice = false;
          _this.draftForm = false;
          _this.operationDisplay = true;
          _this.showToast(
            _this.commonService.getHttpErrorMessage(error, "Settlement"),
            "error"
          );
        }
      );
  }

  getPayload(button: any) {
    let _this = this;
    this.isLoadingInvoice = true;
    this.draftForm = true;
    this.operationDisplay = false;
    let plannedObligationIds = this.selectedPlans.map((item) => {
      return {
        plannedObligationId: item.plannedObligationId,
        splitSequenceNumber: item.splitSequenceNumber,
      };
    });
    let tradeId = this.selectedPlans[0].tradeUuid;
    this.plannedObligation = this.selectedPlans[0];
    let isAdvance = button === "Advance Invoice";
    let costOnly = button === "Cost Invoice";
    let docbypass = button === "Doc ByPass" || button === "Doc ByPass CN/DN";
    let docbypassCnDn = button === "Doc ByPass CN/DN";
    let payload = {
      plannedObligationIds: plannedObligationIds,
      tradeUuid: tradeId,
      advance: isAdvance,
      proforma: false,
      costOnly: costOnly,
      chargesOnly: false,
      docbypass: docbypass,
      docbypassCnDn: docbypassCnDn,
      attachCost: this.commonService.getFromTenantConfig(
        false,
        "invoiceConfig",
        "attachCost"
      ),
      attachCharges: this.commonService.getFromTenantConfig(
          false,
          "invoiceConfig",
          "attachCharge"
      ),
    };
    this.commonService
      .post(
        `${environment.base_url}/api/invoice/v1/getdraftinvoicepayload`,
        payload
      )
      .subscribe(
        (next: any) => {
          _this.draftData = next;
          _this.isLoadingInvoice = false;
        },
        (error: HttpErrorResponse) => {
          _this.isLoadingInvoice = false;
          _this.draftForm = false;
          _this.operationDisplay = true;
          _this.showToast(
            _this.commonService.getHttpErrorMessage(error, "Settlement"),
            "error"
          );
        }
      );
  }

  docByPassIdPresent(rowData: any) {
    return rowData['docByPassId'] !== null && rowData['docByPassId'] !== undefined && rowData['docByPassId'].length > 0;
  }

    getExpandBodyClass(rowData: any) {
        return rowData['splitted']?'expand_disabled':'';
    }

  onExpandRow(event: any,fromParent:boolean = false) {
    let rowData = event.data;
    if(rowData['splitList'] === null || rowData['splitList'] === undefined || rowData['splitList'].length === 0) {
      let url = environment.base_url + "/api/settlement/v1/get-expand-row-for-split?plannedObligationId=" + event.data.plannedObligationId;
      this.commonService.getJSONByURL(url).subscribe((next:any[]) => {
        rowData['splitList'] = next;
        if(fromParent) {
          rowData['selectedChild'] = next;
          this.expandedRows[rowData['uuid']] = true;
          this.planTable.expandedRowKeys[rowData['uuid']] = true;
          next.forEach(item => this.selectedPlans.push(item));
          this.onRowSelectionChange(null,false,true);
        }
      });
    } else {
      if(fromParent) {
        rowData['selectedChild'] = rowData['splitList'];
        this.expandedRows[rowData['uuid']] = true;
        this.planTable.expandedRowKeys[rowData['uuid']] = true;
        Object.assign(this.selectedPlans,rowData['splitList']);
        this.onRowSelectionChange(null,false,true);
      }
    }

  }
}
