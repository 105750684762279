import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonService} from "../../services/common.service";
import {environment} from "../../../environments/environment";
import {MessageService, Table} from "primeng";
import {messages} from "../../services/common/messages";

@Component({
  selector: 'xceler-list',
  templateUrl: './xceler-list.component.html',
  styleUrls: ['./xceler-list.component.css'],
  providers: [MessageService]
})
export class XcelerListComponent implements OnInit {
  @Input() heading: string = 'Heading Box';
  @Input() columns: any;
  @Output() rows = new EventEmitter();
  @Input() rowData: any[] = [];
  @Input() height: any = '300px';
  editingKey: string = '';
  currentEditingRow: number = -1;
  @Input() primaryKey: any = '';
  private primaryKeyAdded: any[] = [];

  constructor(private commonService: CommonService, private messageService: MessageService,) {
  }

  ngOnInit(): void {
  }

  getOutputValue(rowData, col: any) {
    if (col.type === 'date') {
      return this.commonService.getFormattedDate(rowData[col.field], environment.dateFormat);
    } else if (col.type === 'list') {
      return this.getLableFromList(col.list, rowData[col.field]);
    }
  }

  getLableFromList(list: any[], value) {
    let label = '';
    list.forEach(function (keyValue) {
      if (keyValue['value'] === value) {
        label = keyValue['label'];
      }
    });
    return label;
  }

  addNew(table: Table) {
    if (this.currentEditingRow === -1) {
      let newJson = {};
      newJson['rowNum'] = 0;
      this.columns.forEach(function (col) {
        newJson[col.field] = '';
      });
      this.rowData.splice(0, 0, newJson);
      this.currentEditingRow = 0;
      table.initRowEdit(newJson);
    } else {
      this.showMessage(messages.save_all['summary'], messages.save_all['message']);
    }
  }

  deleteRow(ri, rowData) {
    if (this.currentEditingRow === -1) {
      this.primaryKeyAdded.splice(this.primaryKeyAdded.indexOf(rowData[this.primaryKey]), 1);
      this.rowData.splice(ri, 1);
    } else {
      this.showMessage(messages.save_all['summary'], messages.save_all['message']);
    }
  }

  showMessage(summary, detail, severity: string = 'info') {
    this.messageService.add({
      severity: severity, summary: summary,
      detail: detail
    });
  }


  onEdit(ri, rowData) {
    this.currentEditingRow = ri;
    this.editingKey = rowData[this.primaryKey];
  }

  onSave(ri, rowData, table: Table) {
    if (this.editingKey !== undefined && this.editingKey !== null && this.editingKey.length > 0) {
      let temp = [...this.primaryKeyAdded];
      let index = this.primaryKeyAdded.indexOf(this.editingKey);
      temp.splice(index, 1);
      if (!temp.includes(rowData[this.primaryKey])) {
        this.currentEditingRow = -1;
        this.primaryKeyAdded.splice(index, 1);
        this.primaryKeyAdded.splice(index, 0, rowData[this.primaryKey]);
      } else {
        table.editingRowKeys[ri] = true;
        this.showMessage('Duplicate Entry', 'Event Code : ' + rowData[this.primaryKey] + ' already Exist !');
      }
    } else {
      if (!this.primaryKeyAdded.includes(rowData[this.primaryKey])) {
        this.currentEditingRow = -1;
        this.primaryKeyAdded.push(rowData[this.primaryKey]);
      } else {
        table.editingRowKeys[ri] = true;
        this.showMessage('Duplicate Entry', 'Event Code : ' + rowData[this.primaryKey] + ' already Exist !');
      }
    }
  }

  getRowData(rowData, ri) {
    rowData['rowNum'] = ri;
    return rowData;
  }
}
