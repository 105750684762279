<p-table #cashFlowGrid
         (onRowSelect)="onSelectedChange($event,false)"
         (onRowUnselect)="onSelectedChange($event,true)"
         [(selection)]="selectedRows"
         [columns]="columns"
         [responsive]="true"
         [value]="value"
         class="style-4 table-responsive"
         selectionMode="multiple">
  <ng-template let-columns pTemplate="header">
    <tr>
      <th *ngIf="showChkBox" style="width: 3em; background-color: #D9DBDE">
        <p style="background: #D9DBDE; height: 36px;"></p>
      </th>
      <th *ngFor="let col of columns; index as i" [ngStyle]="getStyleIfPossible(col)">
        <div style="background-color: #D9DBDE; padding-top: 8px; padding-bottom: 8px">
          <p [ngClass]="getClassForHeading(i)">{{col.header}}</p>
        </div>
      </th>
      <th *ngIf="action">
        <p class="headerNormal"></p>
      </th>
    </tr>
  </ng-template>
  <ng-template let-columns="columns" let-ri="rowIndex" let-rowData pTemplate="body">
    <tr>
      <td *ngIf="showChkBox" class="customRow" style="width:3em;">
        <p class="plannedGridChkBox">
          <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
        </p>
      </td>
      <td *ngFor="let col of columns" class="customRow">
        <div *ngIf="col.field === 'tradeTransactionType'">
          <div *ngIf="rowData[col.field]=== 'BUY'" style="display: flex; padding-left: 2%">
            <img alt="" src="../../../../assets/images/svg/buyArrow.svg">
            <p class="plannedGrid">Buy</p>
          </div>
          <div *ngIf="rowData[col.field]=== 'SELL'" style="display: flex; padding-left: 2%">
            <img alt="" src="../../../../assets/images/svg/sellArrow.svg">
            <p class="plannedGrid">Sell</p>
          </div>
        </div>
        <div *ngIf="col.field === 'financialAmount'">
          <div class="plannedGrid">{{getAmount(rowData)}}</div>
        </div>
        <div *ngIf="col.primaryKey === true">
          <p (click)="throwUniqueKey(rowData)" class="primaryKey">{{rowData[col.field]}}</p>
        </div>

        <div *ngIf="col.field !== 'tradeTransactionType' && col.primaryKey !== true && col.field !== 'financialAmount'"
          style="display: flex">
          <p class="plannedGrid">{{getColValue(rowData, col)}}</p>
          <i (click)="onClickAttachment(rowData)" *ngIf="col.field == 'profitcenter' && icon"
             class="fas fa-paperclip paperClipClass"></i>
        </div>
      </td>

    </tr>
  </ng-template>
</p-table>
