import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import {AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Tcolumn} from '../../../grid/tcolumn.model';
import {Action} from '../../../grid/actions.model';
import {CommonService} from '../../../services/common.service';
import {KeyValue} from '../../../grid/key-value.model';
import {defaultValue} from '../../../grid/defaultValue.model';
import {environment} from '../../../../environments/environment';
import {MasterService} from '../../../masters/services/MasterService';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';


export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AiocontrolsComponent),
  multi: true
};

@Component({
  selector: 'aio-controls',
  templateUrl: './aiocontrols.component.html',
  styleUrls: ['./aiocontrols.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class AiocontrolsComponent implements OnInit, ControlValueAccessor, OnChanges ,AfterViewInit {

  @Input() iserror: boolean = false;
  @Input() field: Tcolumn;
  @Input() rightAlignText:boolean = false;
  @Input() showLabel: boolean = true;
  @Input() readOnly: boolean = false;
  @Input() clearValue: boolean = false;
  @Input() showErrorMessage: boolean = false;
  @Input('value') val: any;
  @Input('name') name: string = '';
  @Input() formControlAIO: FormControl;
  @Input() isUpdating: boolean = false;
  @Input() viewOnly: boolean = false;
  @Input() saveUrl: string = "";
  @Input() appendTo: string = "";
  @Input() submitted: boolean = false;
  @Input() isOnForm: boolean = true;
  @Input() isFocused: boolean = false;
  @Input() listOfValues: KeyValue[] = [];
  @Input() showTooltipOnValidationError: boolean = false;
  @Input() inputFilter: RegExp = /^[^<>*!]+$/;
  @Input() fileName: string;
  @Output() onChangeValue = new EventEmitter();
  @Output() addBtnValue = new EventEmitter();
  @Output() onButtonClick = new EventEmitter();
  @Output() onFileSelected = new EventEmitter();
  @Output() onUpdateValue = new EventEmitter();
  @Input() showAddButtons: boolean = true;
  @Output() controlValueChange = new EventEmitter();
  @Input() dateMinMode;
  @Input() dateInputFormat;
  @Input() customTemplate:TemplateRef<any>;
  @Input()index:Number=0;
  type: string = 'T';
  action: Action;
  isObject: boolean = false;
  dvObject: defaultValue;
  displaySuggestion: boolean = false;
  pattern: any;
  dateFormatDefualt: any = environment.dateFormat;
  pattern2: any;
  hasnone: boolean = false;
  bsConfig: Partial<BsDatepickerConfig>;
  src: any;
  isOpen: boolean = false;
  selectedOptions: any [] = [];
  optionsToSave: string = '';
  dropdownSettings = {};
  copyMessage: string = "Copy";
  passwordToggle: boolean = false;
  private filetype: string;
  @Input() requiresContinuesChangeDetection:boolean = false;

  constructor(public commonService: CommonService,private changeDetectorRef:ChangeDetectorRef, public masterCommonService: MasterService) {
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  onTouched: any = () => {
  };
  @Input() numberboxwith: any = '';
  suggestionlist: any[] = [];


  getDefaultValue(value: any) {
    let defaultVa: any;
    if (typeof (value) === 'object') {
      defaultVa = value.defaultValue;
    } else {
      defaultVa = value;
    }
    return defaultVa;
  }

  setDefaultValue(newvalue: any) {
    let value = this.field.getDefaultValue();
    if (typeof (value) === 'object') {
      this.field.setDefaultValue(new defaultValue('', undefined, undefined, undefined))
    } else {
      this.field.setDefaultValue(newvalue);
    }
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'label',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      closeDropDownOnSelection: false
    };
    this.type = this.field.getColumnType();
    this.dvObject = undefined;
    if (typeof (this.field.getDefaultValue()) === 'object') {
      this.isObject = true;
      this.dvObject = this.field.getDefaultValue();
      if(this.dvObject !== null && this.dvObject !== undefined) {
        this.val = this.dvObject.defaultValue;
        if (this.dvObject.includedCharacters !== undefined) {
          this.generateRegex(this.dvObject.includedCharacters, this.dvObject.isNumericAllowed);
        }
        if (this.dvObject.pattern !== undefined) {
          this.pattern = this.dvObject.pattern;
          this.pattern2 == new RegExp(this.pattern, 'g');
        }
      }
    } else {
      this.val = this.field.getDefaultValue();
    }
    this.action = this.field.getActionToDo();

    if (this.action !== undefined) {
      let _this = this;
      this.action.getFieldsToUpdate().forEach(function (field) {
        if (field['throw']) {
          if ((field['fieldName'] === _this.field.getField() && !_this.viewOnly) || field['fieldName'] !== _this.field.getField()) {
            _this.throwChanges(_this.formControlAIO.value);
          }
        }
      });
    }
    if (this.type === 'D') {
      this.bsConfig = new BsDatepickerConfig();
      this.bsConfig.dateInputFormat = (this.field.getFromExtra('date')['format'] !== undefined ? this.field.getFromExtra('date')['format'] : environment.dateFormat).toUpperCase();
      this.bsConfig.adaptivePosition = true;
      this.bsConfig.useUtc = false;
    }
  }

  onChange(val) {
    this.val = val;
  }

  isValidDate(value) {
    if (value === undefined) {
      return false;
    }
    return true;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    this.value = obj;
    this.updateRoundOffValue(obj);
  }

  handleFileInput(files: File[], multiple: boolean = false) {
    let _this = this;
    if (multiple === true) {
      this.value = [];
    }
    let reader = new FileReader();
    if (files !== undefined && files.length > 0) {
      files.forEach(function (file) {
        _this.filetype = file['type'].split('/')[0];
        reader.readAsDataURL(file);
        reader.onload = (event2) => {
          const uploadData = new FormData();
          uploadData.append('file', file, file.name);
          _this.commonService.getByteArray(environment.base_url + '/api/company/v1/upload/logo', uploadData).subscribe(function (data: any) {
            if (!multiple) {
              _this.value = data.response;
              _this.onFileSelected.emit(file);
              _this.throwChanges(file);
            }
          })
        };
      });
      _this.onFileSelected.emit(files);
      _this.throwChanges(files);
    }
  }


  ngOnChanges(changes: SimpleChanges): void {
    let _this = this;
    if (changes['clearValue']) {
      if (this.clearValue == true) {
        this.value = '';
      }
    }
    if (changes['val']) {
      this.updatingChange(this.val);

    }
    if (changes['isFocused'] && changes['isFocused'].currentValue === true) {
      document.getElementById(this.field.getField()).focus();
    }
    if(changes['listOfValues'] && changes['listOfValues'].currentValue !== null && changes['listOfValues'].currentValue !== undefined &&  this.field.addIfSelectedNotInList) {
      if(this.val !== null && this.val !== undefined && this.val !== '' ) {
        let list:any[] = changes['listOfValues'].currentValue;
        let found:boolean = false;
        list.forEach(function (obj:any) {
          if(obj['value'] === _this.val) {
            found = true;
          }
        });
        if(!found) {
          this.listOfValues.push(new KeyValue(this.val,this.val));
        }
      }
    }

  }

  updatingChange(value) {
    if (this.isUpdating) {
      if (this.field.getColumnType() === 'LB' || this.field.isEditable() === false) {
        this.throwChanges(value);
      }
    }
  }

  parseType(valueType: string) {
    valueType = valueType.toUpperCase();
    let type = 'text';
    if (valueType === 'N') {
      type = 'number';
    } else if (valueType === 'P') {
      type = this.passwordToggle ? 'text' : 'password';
    }
    return type;
  }

  public inputValidator(event: any) {
    if (this.pattern !== undefined) {
      let value = event.target.value;
      if (value === undefined) {
        value = '';
      }
      let passed = this.pattern.test(value);
      if (!passed) {
        event.target.value = event.target.value.replace(this.pattern2, '');
        this.formControlAIO.setValue(event.target.value);
        this.val = event.target.value;
      }
    }
  }

  generateRegex(includedList: any[], isNumericAllowed: boolean) {
    let reg = '^[A-Za-z';
    let reg1 = '[^A-Za-z';
    if (isNumericAllowed) {
      reg += '0-9';
      reg1 += '0-9';
    }
    includedList.forEach(function (ch) {
      if (ch === '\\') {
        reg += '\\';
      }
      reg += ch;
      reg1 += ch;
    });
    reg += ']+$';
    reg1 += ']';
    this.pattern = new RegExp(reg);
    this.pattern2 = new RegExp(reg1, 'g');
  }

  private updateRoundOffValue(value) {
    if( ['N'].includes(this.field.getColumnType()) && this.field.roundOffTo !== null && this.field.roundOffTo !== undefined && this.field.roundOffTo > 0) {
      this.value = this.commonService.formatNumberWithoutComma(value,this.field.roundOffTo);
    }
  }

  throwChanges(value) {
    this.updateRoundOffValue(value);
    this.onUpdateValue.emit(value);
    let canpass = true;
    if (this.field.getColumnType() === 'L' || this.field.getColumnType() === 'B') {
      this.value = value;
      canpass = false;
    } else if (this.field.getColumnType() === 'OB') {
      value = this.formControlAIO.value;
      this.value = value;
    }
    if (this.field.getActionToDo() !== undefined) {
      let obj = {
        field: this.field,
        currentValue: value,
        action: this.field.getActionToDo(),
        type: 'action',
        canPass: canpass
      };
      this.onChangeValue.emit(obj);
    }
    if (this.isObject && this.dvObject !== null && this.dvObject !== undefined) {
      if (this.dvObject.toField !== undefined && this.dvObject.toField.length > 0) {
        let obj2 = {from: this.field.getField(), to: this.dvObject.toField, value: value, type: 'toField'};
        this.onChangeValue.emit(obj2);
      }
    }
  }

  getLabel(label: any) {
    let lab = '';
    this.field.getListOfValues().forEach(function (par: KeyValue) {
      if (par.getValue() === label) {
        lab = par.getLabel();
      }
    });
    return lab;
  }

  getValue(value: any) {
    if (value === 'Invalid Date') {
      return 'Selected Date';
    }
    return value;
  }

  /**
   * chek for which key is pressed in input suggestion field
   * @param value
   */
  checkForKey(value: string) {
    if (value !== undefined && value.charAt(value.length - 1) === '@') {
      this.displaySuggestion = true;
    }
  }

  getOptions(arr: any = []) {
    let options = [];
    arr.forEach(function (data, index) {
      options.push(
        {value: data.value, label: data.label}
      )
    });
    options.splice(0, 1);
    return options
  }

  handleClick(value: any) {
    this.addBtnValue.emit(value);
    this.masterCommonService.setModelStat(true);
  }

  onFocusOut() {
    this.throwChanges(this.value);
  }

  /**
   * handle click event for ui element type button
   * @param value
   */
  handleButtonClick(value: any, field: Tcolumn) {
    this.onButtonClick.emit({buttonHeader: field.getHeader()});
  }

  isMultiple(field: Tcolumn) {
    return typeof (field.getFromExtra('multiple')) === 'object' ? false : field.getFromExtra('multiple');
  }

  getFormClass() {
    let className = (this.submitted && this.formControlAIO.errors != null) ? 'form-control-error' : this.dvObject !== undefined ? this.dvObject.canCopy ? 'form-control-normal_copy' : 'form-control-normal' : 'form-control-normal';
    return className;
  }

  isUppercase() {
    if(this.dvObject !== undefined && this.dvObject.alwaysCapitalValue) {
      return true;
    }
    return false;
  }

  showCopy() {
    return this.dvObject !== undefined ? this.dvObject.canCopy : false;
  }

  addCopyClass() {
    return this.showCopy() ? 'copyClass' : '';
  }

  changeMessage() {
    this.copyMessage = "Copied";
  }

  focusAndErrorCheck() {
    let className = '';
    if (this.isFocused && !this.showErrorMessage) {
      if (!this.formControlAIO.valid) {
        className = 'invalidValue'
      }
    }
    return className;
  }

  getTooltipMessage(control: AbstractControl, col) {
    return this.showTooltipOnValidationError ? this.commonService.getValidationErrorMessage(control, col.getHeader()) : '';
  }

  getControlClass() {
    return this.isOnForm || this.field.getColumnType() === 'SL' ? 'controlBoxOnForm' : this.field.getColumnType() !== 'L' ? 'controlBox' : 'controlBoxWithList';
  }

  enableAutoResize() {
    if (this.value !== undefined && this.value !== null && this.value.length > 0) {
      return true;
    }
    return false;
  }

  getLabelFormat(field: Tcolumn, labelType = 'labelFormat') {
    if (field.getFromExtra('dualSliderOption') !== undefined) {
      if (field.getFromExtra('dualSliderOption')[labelType] !== undefined && field.getFromExtra('dualSliderOption')[labelType].toString().length > 0) {
        return field.getFromExtra('dualSliderOption')[labelType];
      } else {
        if (labelType === 'floorLeft' || labelType === 'floorRight' || labelType === 'ceilLeft' || labelType === 'ceilRight') {
          return 0;
        }
        return '<value>';
      }
    }
  }

  getInfoVisibility() {
    if (this.field.getFromExtra('infoIcon')['summary']) {
      return true;
    }
    return false;
  }

  togglePassword() {
    this.passwordToggle = !this.passwordToggle;
  }

    search(value: any) {
        this.suggestionlist = [];
        let _this = this;
        this.listOfValues.forEach(function (obj:KeyValue) {
          if(obj.getValue().length > 0 && obj.getLabel().toString().includes(value['query'])) {
            _this.suggestionlist.push(obj.getValue());
          }
        });

    }

}
