<p-dialog [(visible)]="display" [baseZIndex]="25"
          [closeOnEscape]="false"
          [contentStyle]="{height: formHeight, overflow: 'scroll', overflowX:'hidden'}"
          [draggable]="false"
          [maximizable]="false" [modal]="true" [showHeader]="false" [baseZIndex]="10000"
          [style]="{ width: '60%',height: '80%',maxHeight:'95%', padding:'0px', background:'#ffffff'}">

    <form [formGroup]="blform">
        <div class="dialogHeading">
                <p class="dialogLabel">Create BL</p>
        </div>

        <div class="sectionForm style-4" [id]="'sectionForm'">
            <div class="bothSection">
                <div class="blsection">
                    <div class="sectionBoxMain">
                        <div class="formSectionTitle">
                            <div class="headerTitle">BL Information</div>
                        </div>
                    </div>
                    <div class="row rowClass">
                        <div class="col-md-4 controlGap contentbox">
                            <div class="fieldBox">
                                <label class="label ng-star-inserted">BL Number</label>
                                <input id="blNumber"
                                       (input)="commonService.updateInputValidation($event,includedCharacters,true)"
                                       class="form-control form-control-sm input"
                                       pInputText placeholder="BL Number"
                                       formControlName="blNumber">
                            </div>
                        </div>
                        <div class="col-md-8 controlGap contentbox">
                            <div class="fieldBox">
                                <label class="label ng-star-inserted">Shipper</label>
                                <input id="shipper"
                                       (input)="commonService.updateInputValidation($event,includedCharacters,true)"
                                       class="form-control form-control-sm input"
                                       pInputText placeholder="Shipper"
                                       formControlName="shipper">
                            </div>
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <div class="fieldBox">
                                <label class="label ng-star-inserted">Export Reference</label>
                                <input id="exportReference"
                                       (input)="commonService.updateInputValidation($event,includedCharacters,true)"
                                       class="form-control form-control-sm input"
                                       pInputText placeholder="Export Reference"
                                       formControlName="exportReference">
                            </div>
                        </div>
                        <div class="col-md-8 controlGap contentbox">
                            <div class="fieldBox">
                                <label class="label ng-star-inserted">Consignee</label>
                                <input id="consignee"
                                       (input)="commonService.updateInputValidation($event,includedCharacters,true)"
                                       class="form-control form-control-sm input"
                                       pInputText placeholder="Consignee"
                                       formControlName="consignee">
                            </div>
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <div class="fieldBox">
                                <label class="label ng-star-inserted">Assign Contract</label>
                                <input id="assignContract" type="number"
                                       (input)="commonService.updateInputValidation($event,includedCharacters,true)"
                                       class="form-control form-control-sm input"
                                       pInputText placeholder="Assign Contract"
                                       formControlName="assignContract">
                            </div>
                        </div>
                        <div class="col-md-8 controlGap contentbox">
                            <div class="fieldBox">
                                <label class="label ng-star-inserted">Ship To</label>
                                <input id="shipTo"
                                       (input)="commonService.updateInputValidation($event,includedCharacters,true)"
                                       class="form-control form-control-sm input"
                                       pInputTextarea placeholder="Ship To"
                                       formControlName="shipTo">
                            </div>
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <div class="fieldBox">
                                <label class="label ng-star-inserted">Quantity</label>
                                <input id="quantity" type="number"
                                       (input)="commonService.updateInputValidation($event,includedCharacters,true)"
                                       class="form-control form-control-sm input"
                                       pInputText placeholder="Quantity"
                                       formControlName="quantity">
                            </div>
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <div class="fieldBox">
                                <label class="label ng-star-inserted">Quantity UOM</label>
                                <p-dropdown [disabled]="false"
                                            [id]="'quantityUom'"
                                            [options]="quantityUomList"
                                            formControlName="quantityUom"
                                            tooltipEvent="focus" tooltipPosition="bottom"
                                            tooltipZIndex="1000">
                                </p-dropdown>
                            </div>
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <div class="fieldBox">
                                <label class="label ng-star-inserted">Forwarding Agent</label>
                                <p-dropdown [disabled]="false"
                                            [id]="'forwardingAgent'"
                                            [options]="forwardingAgentList"
                                            formControlName="forwardingAgent"
                                            tooltipEvent="focus" tooltipPosition="bottom"
                                            tooltipZIndex="1000">
                                </p-dropdown>
                            </div>
                        </div>
                        <div style="margin-left: 15px;width: 60%;height: 100px">
                            <app-ctrm-price-table
                                    (onAddNewRowComplete)="onNewRowAddComplete()"
                                    (onRefreshFormGroupComplete)="onRefreshFromGroupComplete()"
                                    [columns]="notifyCols" [gridDisplay]="true"
                                    [refreshFormGroup]="refreshFormGroup"
                                    [newRows]="newRows" [refreshNewRows]="refreshAddNewRow"
                                    [value]="notifyData"
                                    [showNewEntryIcon]="true">
                            </app-ctrm-price-table>
                        </div>
                    </div>
                </div>
                <div class="validContain">
                    <!--                Validation-->
                    <div class="validation">
                        <div style="display: flex; padding-bottom: 10px">
                            <i [ngClass]="getIconFromValidations(blform,'blNumber')"></i>
                            <p class="valPoints">BL Number</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </form>
    <p-footer>
        <button class="cancelBtn" (click)="onCancel()">Cancel</button>
        <button class="planScreenBtns" [disabled]="!blform.valid" (click)="onCreate()">Create</button>
    </p-footer>
</p-dialog>

<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>
