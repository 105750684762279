<div class="sectionCardTop">
  <div class="breadcrumbPane">
    <ctrm-breadcrumb [collapsabel]="false"
                     [items]="breadCrumbService.getBreadCrumb()"
                     [showIcon]="false"></ctrm-breadcrumb>
  </div>
  <div class="actionPane">
    <div class="screenDetails">
      <img [src]="'../../../assets/images/svg/tradePlanning.svg'"/>
      <p>Invoices & Payments</p>
    </div>
    <sta-filter
            [columnListObject]="invoiceCols"
            [pTable]="planTable"
            [columns]="columns"
            [preselectedFilter]="queryFilter"
            (onFilterApply)="onApplyFilter($event)"
            [moduleName]="'Invoices'"></sta-filter>
    <div *ngIf="tabValue == 'planned'" class="rightInfo">
      Total {{invoiceData.length}} Plans
    </div>
  </div>
</div>


<div style="display: flex">
  <div class="selectButtonClass">
    <p-selectButton (onChange)="onTabChange($event)" [(ngModel)]="tabValue" [options]="tabOptions"></p-selectButton>
  </div>
</div>


<!--Pending table-->
<div>
  <div class="planningPanel">
    <div class="toolbar">
      <div>
        <ctrm-button *ngIf="tabValue == 'pending' "
                     [disabled]="selectedData.length !== 1 || ['posted','external posted','settle'].includes(selectedData[0]['status'].toLowerCase())"
                     (onClick)="refreshInvoice()" icon="fas fa-pen" label="Edit"></ctrm-button>
        <ctrm-button *ngIf="tabValue == 'approved' && canEdit"
                       [disabled]="selectedData.length !== 1 || ['posted','external posted','settle'].includes(selectedData[0]['status'].toLowerCase())"
                       (onClick)="rejectInvoice()" icon="fas fa-times" label="Reject"></ctrm-button>
        <ctrm-button (onClick)="planRefresh()" icon="fas fa-redo" label="Refresh"></ctrm-button>
        <div class="float-right">
          <ctrm-button *ngIf="deleteBtnCheck()" (click)="deleteInvoice()" [disabled]="selectedData.length !== 1" [label]="'Delete'"
                       icon="fas fa-trash"></ctrm-button>
          <ctrm-button [disabled]="selectedData.length !== 1" (click)="onClickExport()" icon="fas fa-file-export" label="Export"></ctrm-button>
          <ctrm-button [disabled]="selectedData.length !== 1" icon="fas fa-print" label="Print"></ctrm-button>
          <ctrm-button [disabled]="selectedData.length !== 1" (onClick)="onClickAuditButton($event)"
                       icon="fas fa-history" label="Audit Trail"></ctrm-button>
        </div>
      </div>
    </div>


    <div>
      <p-table #planTable (inViewportAction)="calculateHeight()"
               (onRowSelect)="onRowSelectionChange($event)"
               (onRowUnselect)="onRowSelectionChange($event)"
               [(selection)]="selectedData" [columns]="invoiceCols"
               [responsive]="true" [style]="{minHeight: planTableHeight, maxHeight: planTableHeight,borderStyle:'solid', borderWidth: '1px', borderColor:'#e6e6e6'}"
               [value]="invoiceData" [autoLayout]="true"
               class="style-4 table-responsive" dataKey="invoiceNumber" inViewport>
        <ng-template let-columns pTemplate="header">
          <tr>

            <th style="width: 3em">
              <p
                style="background-color: #D9DBDE; padding: 5px;padding-top: 15px; padding-bottom: 10px; text-align: center;">
              </p>
            </th>
            <th *ngFor="let col of columns;index as i">
              <div style="background-color: #D9DBDE; padding: 5px;padding-top: 13px; padding-bottom: 14px">
                <p [ngClass]="getClassForHeading(i)">{{col.header}}</p>
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template let-columns="columns" let-ri="rowIndex" let-rowData pTemplate="body">
          <tr [pSelectableRow]="rowData">
            <td class="customRow" style="width:3em;">
              <p class="plannedGridChkBox">
                <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
              </p>
            </td>
            <td *ngFor="let col of columns" class="customRow">
              <p
                *ngIf="col.field !== 'invoiceType' && col.field !== 'quantity' && col.field !== 'total' && col.field !== 'planId' && col.field !== 'attachmentName' && col.field !== 'status' && col.field !== 'plannedObligationIds' && col.field !== 'tradeId'  && !col.primary"
                class="plannedGrid">
                {{getColValue(rowData, col)}}
              </p>
              <p *ngIf="col.field === 'tradeId'" class="tradeIdLink">
                <a class="spanClass linkplan"  (click)="tradeIdclick(rowData)">
                  {{rowData[col.field]}}
                </a>
              </p>
              <p *ngIf="col.field === 'plannedObligationIds'" class="tradeIdLink">
                <a class="spanClass linkplan" *ngFor="let plannedobligation of plannedobligation(rowData[col.field]);index as i;" (click)="plannedObligationclick(plannedobligation,rowData)">
                {{plannedobligation}} {{rowData['splitNumber'] !== 0?'('+rowData['splitNumber']+')':''}}
                  <span *ngIf="i < (rowData[col.field].length-1)"> , </span>
                </a>
              </p>
              <p *ngIf="col.field === 'invoiceType'" class="typeStyle">
                {{rowData[col.field]}}
              </p>
              <p *ngIf="col.field === 'total'" class="plannedGrid">
                {{commonService.formatNumber(rowData[col.field],'','right','en-US',priceRoundOff)}} {{rowData['currency']}}
              </p>
              <p *ngIf="col.field === 'quantity'" class="plannedGrid">
                {{commonService.formatNumberWithoutComma(rowData[col.field],quantityRoundOff)}} {{rowData['uom']}}
              </p>
              <p (click)="onPrimaryClick()" *ngIf="col.primary" class="plannedGrid primaryField">
                {{rowData[col.field]}}
              </p>
              <p *ngIf="col.field === 'status'" class="statusStyle">
                {{rowData[col.field]}}
              </p>
              <div *ngIf="col.field === 'attachmentName'">
                <div *ngIf="rowData[col.field]" class="attDiv">
                  <i class="fas fa-paperclip atticonClass"></i>
                  <p class="plannedGrid" style="padding-left: 5px !important;"><a href="#" (click)="openAttachment(getAttachmentUrl(rowData['invoiceDocumentUrl']), $event)">{{rowData[col.field]}}</a></p>
                </div>

                <div *ngIf="rowData[col.field] === undefined || rowData[col.field] === null  || rowData[col.field] === ''"  class="attDiv">
                  <i class="fas fa-exclamation-triangle atticonClass warnIcon" pTooltip="{{rowData['invoiceDocumentUrl']}}" tooltipPosition="bottom" tooltipZIndex="9999"></i>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template let-columns pTemplate="footer">
          <tr>
            <td [colSpan]="columns.length + 2">
              <div *ngIf="invoiceData.length == 0 || invoiceData.length == null" style="text-align: center;">
                <div class="noPlans">{{isRefreshing ? 'Please wait ...' : 'No Invoice Availaible'}}</div>
                <div *ngIf="!isRefreshing">
                  <button (click)="goTo()" class="planScreenBtns">Go to Settlement</button>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <p-paginator *ngIf="getPagination()" [rows]="20" [totalRecords]="this.totalElements" (onPageChange)="onPageChange($event)"></p-paginator>
    </div>
  </div>
</div>
<!--Approved-->
<!--<div [hidden]="tabValue === 'pending'">-->
<!--</div>-->

<p-sidebar [(visible)]="operationDisplay" [baseZIndex]="25" [modal]="false" [showCloseIcon]="false" position="bottom">
  <div class="bottomSidebarContainer">
    <div class="leftSection">
      <i (click)="onCloseBottom()" class="material-icons closable">clear</i>
    </div>
    <div class="totalCFContainer">
      <p class="cashflowTotal">{{selectedData.length}}</p>
      <p class="totalCF">Invoice Selected</p>
    </div>
    <div class="middleSection">
      <i class="fas fa-download middleIcons" pTooltip="Download" tooltipPosition="top" tooltipZIndex="9999"></i>
      <i class="fas fa-file-export middleIcons" pTooltip="Export" tooltipPosition="top" tooltipZIndex="9999"></i>
      <i class="fas fa-print middleIcons" pTooltip="Print" tooltipPosition="top" tooltipZIndex="9999"></i>
    </div>
    <div class="rightSection">
      <button (click)="onCloseBottom()" class="cancelBtn">Cancel</button>
      <ng-container *ngIf="tabValue === 'approved'" >
        <button *ngIf="buttonValue === 'Post Invoice'" [action]="'financeApproval'" [access]="'Action'" (click)="onApproval('trader',buttonValue)" class="saveBtn">Post</button>
        <button *ngIf="buttonValue === 'Update'" [access]="'Action'"
                [action]="'updateDueDate'" (click)="onApproval('trader',buttonValue)" class="saveBtn">Update</button>
      </ng-container>
      <button *ngIf="selectedData[0] !== null && selectedData[0] !== undefined && tabValue !== 'pending' && ['external posted','posted'].includes(selectedData[0].status.toLowerCase())"
              [disabled]="!unpostAccess"
            class="planScreenBtns" style="width: inherit;margin: 0 10px" (click)="onApproval('trader','Unpost')">Unpost
      </button>
      <ng-container *ngIf="tabValue === 'pending'">
        <button (click)="refreshInvoice()" class="saveBtn" >Regenerate Invoice</button>
        <button (click)="onApproval('trader','Submit')" class="saveBtn"  [disabled]="approvalDisable('trader') && approvalDisable('finance') ">Submit</button>
      </ng-container>
      <button (click)="onSettleAction()" *ngIf="tabValue === 'approved'" class="saveBtn">Settle</button>
    </div>
  </div>
</p-sidebar>

<app-generate-invoice *ngIf="draftForm"
                      [readOnly]="this.selectedButton != 'Edit Invoice' && this.selectedButton != 'Refresh Invoice'"
                      [data]="draftData"
                      [loading]="loading"
                      [approved]="approved"
                      [plannedObligation]="{plannedObligationId:draftData['plannedObligationId']}"
                      [isApproving]="true"
                      [buttonValue]="selectedButton"
                      (onSendForApproval)="onUpdate($event)"
                      (onDraftClose)="onDraftClose()"
                      (onClickSettle)="onSettled($event)"
                      (onApproved)="onApproved($event)"
                      (onPostingDone)="planRefresh()"
                      [sell]="mode"></app-generate-invoice>
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>
<p-confirmDialog [baseZIndex]="25" header="Confirmation"
                 icon="pi pi-exclamation-triangle"></p-confirmDialog>
<app-ctrm-audittrail *ngIf="displayAudit"
                     [heading]="selectedData[0]['invoiceNumber']"
                     [entityId]="selectedData[0]['uuid']"
                     [entityName]="'Invoice'"
                     [display]="true"
                     (onclose)="closeaudittrail()">
</app-ctrm-audittrail>

<app-trade-info (onClose)="onClose($event)" *ngIf="displayidinfo" [display]="true" [rowData]="infoRowdata"
                [tradeId]="infotradeId"
                [idValue]="infoRowdata.plannedObligationId"
                [detailsOf]="detailsOf"
></app-trade-info>

<p-dialog (onHide)="closeUnpostModal()" *ngIf="unpostModal" [(visible)]="unpostModal" [autoZIndex]="true"
          [closable]="false" [appendTo]="'body'"
          [closeOnEscape]="true"
          [contentStyle]="" [draggable]="false" [maximizable]="false" [modal]="true"
          [positionTop]="96" [style]="{ height: 'auto',minWidth:'500px',width:'auto',padding:'0px'}">
  <p-header>
    <p class="formheader">Reason for Unpost</p>
  </p-header>
  <div class="d-flex flex-column p-2">
    <textarea [(ngModel)]="unpostNote" class="inputbox" pInputTextarea placeholder="Note" rows="4"></textarea>
    <div class="d-flex flex-row-reverse">
      <xceler-progress-button
              (onError)="onUnpostFail($event)"
              (onLoadDone)="onUnpostDone($event)"
              [buttonIdleTitle]="'Unpost'"
              [buttonLoadingTitle]="'Working'"
              [buttonStyle]="{margin:'0px',minHeight:'35px',padding:'0px',paddingLeft:'10px',paddingRight:'10px'}"
              [callApiObjectFunction]="unpostApiFunction">
      </xceler-progress-button>
      <button (click)="closeUnpostModal()" class="cancelBtn" style="margin: 0 10px">Cancel</button>
    </div>
  </div>
</p-dialog>
