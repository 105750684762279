import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Tcolumn} from '../../../grid/tcolumn.model';
import {CommonService} from '../../../services/common.service';
import {MessageService} from 'primeng';
import {environment} from '../../../../environments/environment';
import {HttpErrorResponse} from '@angular/common/http';
import {Action} from '../../../grid/actions.model';
import {defaultValue} from '../../../grid/defaultValue.model';

@Component({
  selector: 'app-delivery-schedule-dialog',
  templateUrl: './delivery-schedule-dialog.component.html',
  styleUrls: ['./delivery-schedule-dialog.component.css']
})
export class DeliveryScheduleDialogComponent implements OnInit,OnChanges {

  @Input() display: boolean = false;
  columns: Tcolumn[] = [];
  footerColumns:any[] = [];
  value: any[];
  @Input() periodStartDate: any;
  @Input() periodEndDate: any;
  @Input() internalPackage: any;
  @Input() externalPackage: any;
  @Input() quantity: any;
  @Input() loadRecords:any[] = [];
  @Input() quantityPeriodicity: any;
  @Input() data: any;
  formHeight: string = '';
  @ViewChild('schedule', {static: true}) formElement;
  @Output() onFormClose = new EventEmitter();
  @Output() onSave = new EventEmitter();
  @Output() quantityData = new EventEmitter();
  @Output() unitData = new EventEmitter();
  displaySave:boolean = true;
  noOfUnits: number = 0;
  qualityPerPeriod: number = 0;
  total: number = 0;
  totalUnit: number = 0;
  oldData:any = null;
  totalQuantity: any;
  profitCenter: any;
  private savedRows:any[] =[];


  constructor(public commonService: CommonService,private messageService: MessageService) { }

  @HostListener('window:resize', [])
  public onResize() {
    this.calculateHeight();
  }

  ngOnChanges(changes: SimpleChanges) {
    let _this = this;
    if(changes['data'] || (changes['display'] && changes['display'].currentValue)) {
      if(this.data !== null  && this.data !== undefined) {
        this.commonService.getJSONByURL(environment.base_url + '/api/profitcenterconfig/v1/getprofitcenterconfigbyprofitcenternameandcommodity?tenantId='+this.commonService.getTenantId()
            +'&profitcenterName=' + changes['data'].currentValue['profitcenter']
            + '&commodity=' + changes['data'].currentValue['commodity']).subscribe((next:any) => {
          _this.profitCenter = next;
          _this.loadObligationOnCheck(_this.data);
        });
      }
    }
    if(changes['loadRecords']) {
      let rows = changes['loadRecords'].currentValue;
      if(rows !== null && rows !== undefined && rows.length > 0) {
        this.value = rows;
        this.savedRows = JSON.parse(JSON.stringify(this.value));
        this.oldData = JSON.parse(JSON.stringify(this.data));
      }
    }
  }

  onSaveUnit() {
    this.savedRows = JSON.parse(JSON.stringify(this.value));
    this.oldData = JSON.parse(JSON.stringify(this.data));
    this.onSave.emit({value:this.savedRows,totalquantity:this.totalQuantity,totalUnit:this.totalUnit});
  }

  private loadObligationOnCheck(data:any) {
    let requiredFields:any[] = ['quantityPeriodicity','periodStartDate','periodEndDate'];
    let dateFields = ['periodStartDate','periodEndDate'];
    if(data['packageType'] === 'Bulk') {
      requiredFields.push("quantity")
    } else if(data['packageType'] === 'Container'){
      requiredFields.push("externalPackageUnit")
    } else if(data['packageType'] === 'Unit'){
      requiredFields.push("internalPackageUnit")
    }
    let canPass = true;
    let _this = this;
    requiredFields.forEach(function(field) {
      if(_this.isBlank(data[field])) {
        canPass = false;
      }
    })
    if(canPass) {
      if(this.oldData === null || this.oldData === undefined ) {
        this.generatePreviewObligation(data);
      } else {
        let isSame = true;
        requiredFields.forEach(function(field) {
            if(dateFields.includes(field)) {
            if(_this.commonService.getFormattedDate(data[field],"dd-MM-yyyy") !== _this.commonService.getFormattedDate(_this.oldData[field],"dd-MM-yyyy")) {
              isSame = false;
            }
          } else {
            if(data[field] !== _this.oldData[field]) {
              isSame = false;
            }
          }
        })
        if(!isSame) {
          this.generatePreviewObligation(data);
        } else {
          this.value = JSON.parse(JSON.stringify(this.savedRows));
        }
      }
    }
  }

  private isBlank(value:any) {
    return (value === null || value === undefined || value.toString().length === 0);
  }

  ngOnInit(): void {
    this.getDeliverySchedule();
    // if(this.data !== undefined && this.data !== null){
    //   this.loadObligationOnCheck(this.data);
    // }
  }

  getDeliverySchedule() {
    let _this = this;
    this.columns.push(new Tcolumn('shipDelMonth','Ship/Del Month','LB',0,false));
    this.columns.push(new Tcolumn('periodStartDate','Start Date','D',0,false));
    this.columns.push(new Tcolumn('periodEndDate','End Date','D',0,false));
    this.columns.push(new Tcolumn('noOfUnits','# of Units/Period','N',0,false,undefined, true));
    this.columns.push(new Tcolumn('quantity','Quantity/Period','N',0,true));
    // this.columns.push(new Tcolumn('packageName','Package','LB',0,false));

    let totalRecords = function(value:any[]) {
      return _this.total+' Records';
    };

    let totalQuantity = function(value:any[]) {
      let rows = value[0];
      let total:any = 0;
      if(rows !== null && rows !== undefined) {
        rows.forEach(function(row) {
          if(row['quantity'] !== null && row['quantity'] !== undefined && row['quantity'] !== '') {
            total += parseFloat(row['quantity']);
          }
        })
      }
      total = (_this.profitCenter !== null && _this.profitCenter !== undefined)?parseFloat(total.toString()).toFixed(_this.profitCenter['quantityRounding']):total;
      _this.totalQuantity = total;
      return total;
    };

    let totalUnit = function(value:any[]) {
      let rows = value[0];
      let totalUnit = 0;
      if(rows !== null && rows !== undefined) {
        rows.forEach(function(row) {
          if(row['noOfUnits'] !== null && row['noOfUnits'] !== undefined && row['noOfUnits'] !== '') {
            totalUnit += Number.parseInt(row['noOfUnits']);
          }
        })
      }
      _this.totalUnit = totalUnit;
      return totalUnit;
    };

    this.footerColumns.push({field:'',colSpan:2});
    this.footerColumns.push({field:'Total',colSpan:1});
    this.footerColumns.push({field:'0',colSpan:1,valueFunction:totalUnit});
    this.footerColumns.push({field:'0',colSpan:1,valueFunction:totalQuantity});
  }

  onCancel() {
  this.display = false;
  this.onFormClose.emit();
  }

  ngAfterViewInit() {
    this.calculateHeight();
  }

  calculateHeight() {
    let offsetHeightAF = this.formElement.el.nativeElement['offsetParent']['offsetHeight'];
    if((offsetHeightAF - 400) < 550) {
      this.formHeight = '550px';
    } else {
      this.formHeight = (offsetHeightAF - 400) + 'px';
    }
  }

  onClickCancel() {
    this.displaySave = false;
  }

  showToast(msg, severity: string = 'info') {
    this.messageService.add({
      severity: severity,
      summary: msg
    });
  }

  async generatePreviewObligation(data){
    let _this = this
    this.total = this.getTotalObligations(this.data['quantityPeriodicity'],this.data['periodStartDate'],this.data['periodEndDate']);
    this.value = [];
    let quantity = null;
    if(this.data['packageType'] === 'Container'){
      quantity = await this.quantityCalculation(this.data['externalPackageUnit']);
    } else if(this.data['packageType'] === 'Unit') {
      quantity = await this.quantityCalculation(this.data['internalPackageUnit']);
    }
    for(let i=0;i<this.total;i++){
      if(i === 0) {
        _this.value.push(_this.commonService.getObligationObject(_this.commonService.calculateDeliveryStartDate(_this.data['periodStartDate'],_this.data['quantityPeriodicity']),quantity,_this.data, _this.profitCenter));
      } else {
        _this.value.push(_this.commonService.getObligationObject(_this.commonService.addDaysInDate(new Date(_this.value[i-1]['periodEndDate']),1),quantity,_this.data, _this.profitCenter));
      }
    }
  }

   async quantityCalculation(packageUnit){
    let unitObj = {};
    if(this.data['packageType'] === 'Container') {
      unitObj['packageType'] = this.data['packageType']
      unitObj['externalPackageUnit']= packageUnit;
      unitObj['commodity']= this.data['commodity'];
      unitObj['calculateFrom'] = this.data['externalpackage'];
      unitObj['quantityUom']= this.data['quantityUom'];
      unitObj['externalPackageName']= this.data['externalPackage'];
    } else if(this.data['packageType'] === 'Unit') {
      unitObj['packageType'] = this.data['packageType']
      unitObj['internalPackageUnit']= packageUnit;
      unitObj['commodity']= this.data['commodity'];
      unitObj['quantityUom']= this.data['quantityUom'];
      unitObj['internalPackageName']= this.data['internalPackage'];
    }
    let next = await this.commonService.getHttpClient().post(environment.base_url + '/api/externalpackage/v1/quantityandunitconversion?tenantId='+this.commonService.getFromStorage('tenantId'),unitObj).toPromise().then((next) => {
      return next;
    });
    return parseFloat(parseFloat(next['quantity']).toFixed(4)) ;
  }


  getTotalObligations(qtyPeriodicity: string, deliveryStartDate: string, deliveryEndDate: string) {
    let tradeObligationCount = 0;
    tradeObligationCount = this.commonService.calculateTotalContractQuantity(1,qtyPeriodicity,deliveryStartDate,deliveryEndDate);
    return tradeObligationCount;
  }

  async onValueChange(value: any) {
    if(value['col']['field'] === 'noOfUnits') {
      value['row']['quantity'] = await this.quantityCalculation(value['value']);
    }
  }
}
