import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {ChangeContext, LabelType, Options} from "ng5-slider";

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CtrmSliderComponent),
  multi: true
};


@Component({
  selector: 'ctrm-slider',
  templateUrl: './ctrm-slider.component.html',
  styleUrls: ['./ctrm-slider.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class CtrmSliderComponent implements OnInit, ControlValueAccessor, OnChanges,AfterViewInit {

  @Input('value') val: any;
  @Input('name') name: string = '';
  @Input() floor: any;
  @Input() ceil: any;
  @Input() minValue: any;
  @Input() maxValue: any;
  @Input() minLimit: any;
  @Input() disabled: boolean = false;
  @Input() maxLimit: any;
  @Input() minRange: any;
  @Input() maxRange: any;
  @Input() pushRange: any;
  @Input() step: number;
  @Input() stepsArray: any[];
  @Input() tickStep: number;
  @Input() ticksArray: any[];
  @Input() showTicks: boolean;
  @Input() readOnly: boolean = false;
  @Input() vertical: boolean;
  @Input() showTickValue: boolean;
  @Input() noSwitching: boolean;
  @Input() showSelectionBar: boolean = true;
  @Input() showSelectionBarAtEnd: boolean;
  @Input() draggableRange: boolean;
  @Input() draggableRangeOnly: boolean;
  @Input() showSelectionBarFromValue: any;
  @Input() selectionBarGradient: any = {from: undefined, to: undefined};
  @Input() rightToLeft: boolean = false;
  @Input() translateLabelInForm: string = '<value>';
  @Input() combineLabelsInForm: string = '<minValue> - <maxValue>';
  @Input() legenLabelsInForm: string = '<value>';
  @Input() tickTooltipMessage: string = 'Value is <value>';
  @Input() sliderOptions: Options;
  @Input() labelLowFormat: string = '<value>';
  @Input() labelHighFormat: string = '<value>';
  @Input() isDateSlider: boolean = false;
  @Output() sliderValueChange = new EventEmitter();
  options: any = {};
  sliderVal: any;
  dateRange: Date[];

  constructor(private changeDetectorRef:ChangeDetectorRef) {
  }

  @Input()
  get sliderValue() {
    return this.sliderVal;
  }

  set sliderValue(val) {
    this.sliderVal = val;
    this.sliderValueChange.emit(this.sliderVal);
  }

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.sliderVal = val;
    this.onChange(val);
    this.onTouched();
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  getNumberOFDays(date1: Date, date2: Date) {
    var diffrence = (date2.getTime() - date1.getTime()) / (1000 * 3600 * 24);
    return diffrence;
  }

  createDateRange(): Date[] {
    const dates: Date[] = [];
    let no = this.getNumberOFDays(this.floor, this.ceil);
    for (let i: number = 1; i <= no; i++) {
      dates.push(this.floor + 1);
    }
    return dates;
  }

  onTouched: any = () => {
  };

  onChange(val) {
    this.val = val;
    this.sliderVal = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
    }
  }

  updateOptions() {
    if (!this.isDateSlider) {
      this.addInOption('floor', this.floor);
      this.addInOption('ceil', this.ceil);
      this.addInOption('step', this.step);
      this.addInOption('stepsArray', this.stepsArray);
      this.addInOption('showTicks', this.showTicks);
      this.addInOption('showTicksValues', this.showTickValue);
      this.addInOption('tickStep', this.tickStep);
      this.addInOption('minLimit', this.minLimit);
      this.addInOption('maxLimit', this.maxLimit);
      this.addInOption('minRange', this.minRange);
      this.addInOption('maxRange', this.maxRange);
      this.addInOption('pushRange', this.pushRange);
      this.addInOption('noSwitching', this.noSwitching);
      this.addInOption('showSelectionBar', this.showSelectionBar);
      this.addInOption('showSelectionBarEnd', this.showSelectionBarAtEnd);
      this.addInOption('showSelectionBarFromValue', this.showSelectionBarFromValue);
      this.addInOption('rightToLeft', this.rightToLeft);
      this.addInOption('readOnly', this.readOnly);
      this.addInOption('vertical', this.vertical);
      this.addInOption('ticksArray', this.ticksArray);
      this.addInOption('draggableRange', this.draggableRange);
      this.addInOption('draggableRangeOnly', this.draggableRangeOnly);
      this.addInOption('translate', (value: any, label: LabelType): string => {
        switch (label) {
          case LabelType.Low:
            return this.labelLowFormat.replace(new RegExp('<value>', 'g'), value);
          case LabelType.High:
            return this.labelHighFormat.replace(new RegExp('<value>', 'g'), value);
          default:
            return this.translateLabelInForm.replace(new RegExp('<value>', 'g'), value);
        }
      });
      this.addInOption('getLegend', (value: any): string => {
        return this.legenLabelsInForm.replace(new RegExp('<value>', 'g'), value);
      });
      this.addInOption('ticksTooltip', (value: any): string => {
        return this.tickTooltipMessage.replace(new RegExp('<value>', 'g'), value);
      });
      this.addInOption('combineLabels', (minValue: any, maxValue: any): string => {
        let str = this.combineLabelsInForm.replace(new RegExp('<minValue>', 'g'), minValue);
        str = str.replace(new RegExp('<maxValue>', 'g'), maxValue);
        return str;
      });
    }
  }

  addInOption(key, value) {
    if (value !== undefined && value !== null) {
      this.options[key] = value;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sliderOptions']) {
      this.options = {};
      this.options = changes['sliderOptions'].currentValue;
    } else {
      if (changes['sliderValue']) {
        let currentValue = changes['sliderValue'].currentValue;
        if (currentValue['value']) {
          this.value = currentValue['value']
        }
        if (currentValue['highValue']) {
          this.maxValue = currentValue['highValue']
        }
        if (!currentValue['value'] && !currentValue['highValue']) {
          this.value = currentValue;
        }
      } else {
        this.options = {};
      }
      this.updateOptions();
      if (changes['minValue']) {
        this.value = changes['minValue'].currentValue;
      }
      if (changes['disabled']) {
        this.options = Object.assign({}, this.options, {disabled: this.disabled})
      }
      if (changes['selectionBarGradient']) {
        this.options['selectionBarGradient'] = changes['selectionBarGradient'].currentValue;
      }
      if (changes['isDateSlider']) {
        if (changes['isDateSlider'].currentValue === true) {
          this.dateRange = this.createDateRange();
          this.addInOption('stepsArray', this.dateRange.map((date: Date) => {
            return {value: date.getTime()};
          }));
          this.addInOption('translate', (value: number, label: LabelType): string => {
            return this.translateLabelInForm.replace(new RegExp('<value>', 'g'), new Date(value).toDateString());
          })
        }
      }
    }
  }

  changeFormat(value) {
    let val = value['value'];
    let highVal = value['highValue'];
    if (highVal !== undefined) {
      this.sliderValue = {value: val, highValue: highVal};
    } else {
      this.sliderValue = val;
    }
  }

  onUserChange(value: ChangeContext) {
    this.changeFormat(value);
  }

  onUserChangeEnd(value: ChangeContext) {
    this.changeFormat(value);
  }

  onUserChangeStart(value: ChangeContext) {
    this.changeFormat(value);
  }
}
