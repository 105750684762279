<div *ngIf="gridDisplay">
  <form #gridForm="ngForm" [formGroup]="gridFrmGroup">
    <div class="priceGrid">
      <p-table #table (inViewportAction)="calculateHeight()"
               [columns]="columns"
               [responsive]="true" [style]="{maxHeight:(maxHeight !== null && maxHeight !== undefined && maxHeight.length > 0)?maxHeight:priceGridHeight,minHeight:minHeight}"
               [value]="value"
               [customSort]="useCustomSort"
               (onSort)="onSort($event)"
               (sortFunction)="sortCustom($event)"
               (onRowSelect)="onRowSelectionChange($event)"
               (onRowUnselect)="onRowSelectionChange($event)"
               [(selection)]="selectedRows"
               selectionMode ="multiple"
               editMode="row"
               sortMode="multiple"
               autoLayout="true" class="style-4 table-responsive background"
               inViewport>
        <ng-template pTemplate="header">
          <tr class="sticky-top" style="background-color: #D9DBDE">
            <th *ngIf="showCheckbox" [style.backgroundColor]="tableStyle.backgroundColor" class="checkBoxColHeader stickLeftHeader">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th *ngFor="let col of columns;index as i" [hidden]="hiddenColumns.includes(col.getField())"
                [id]="col.field"
                class="otherColumn stk" style="white-space: nowrap; background-color: #d9dbde" [pSortableColumn]="col['field']">
              <div class="headerDiv">
                <div class="headerTitle">{{col['header']}}</div>
                <p-sortIcon [field]="col['field']" ariaLabel="Activate to sort"
                            ariaLabelAsc="Activate to sort in ascending order"
                            ariaLabelDesc="Activate to sort in descending order"></p-sortIcon>
                <div class="pipe"></div>
              </div>
            </th>
            <th *ngIf="showCrossIcon" class="stk"></th>
          </tr>
        </ng-template>
        <ng-template let-columns="columns" let-ri="rowIndex" let-rowData pTemplate="body">
          <tr [pEditableRow]="rowData">
            <td *ngIf="showCheckbox" class="checkBoxCol stickLeftBody">
              <p-tableCheckbox [value]="rowData" (click)="onClickCheckBox(rowData)"></p-tableCheckbox>
            </td>
            <td *ngFor="let col of columns" [hidden]="hiddenColumns.includes(col.getField())">
              <p-cellEditor>
                <ng-template pTemplate="output">
                  <div *ngIf="!showReadOnly">
                    <aio-controls (addBtnValue)="addBttnValue($event)"
                                  (ngModelChange)="isModalChange(col)?onFieldValueChange(rowData,col,ri):''"
                                  (onButtonClick)="onClick($event)"
                                  [(ngModel)]="rowData[col.getField()]"
                                  [index]="ri"
                                  [field]="col"
                                  [dateMinMode]="getValueForRow(ri,col.getField(),'minMode')"
                                  [dateInputFormat]="getValueForRow(ri,col.getField(),'format')"
                                  [appendTo]="'body'"
                                  [formControlAIO]="getFormGroup(col,ri)"
                                  [formControl]="getFormGroup(col,ri)"
                                  [isOnForm]="false"
                                  [listOfValues]="getListOfValues(col,ri)"
                                  [readOnly]="isDisabled(col)"
                                  [showLabel]="false"
                                  [showTooltipOnValidationError]="true"
                                  [customTemplate]="customTemplateFields.includes(col.getField())?customTemplate[customTemplateFields.indexOf(col.getField())]:null"
                                  [value]="rowData[col.getField()]">
                    </aio-controls>
                  </div>
                  <div *ngIf="showReadOnly">
                    <p class="editable" [ngClass]="clickableFields.includes(col.getField())?'clickableField':''"
                       (click)="clickableFields.includes(col.getField())?onClickClickableField(col,rowData):''">
                      {{commonService.getRowValueFromTcolumn(rowData, col)}}
                    </p>
                  </div>
                </ng-template>
              </p-cellEditor>
            </td>
            <td *ngIf="showCrossIcon">
              <i (click)="rowDelete(ri,rowData)" [pTooltip]="'Delete'" class="far fa-times-circle iconClassDelete"></i>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr>
            <td [colSpan]="columns.length + 2">
              <div *ngIf="showPlaceHolder && (value === undefined ||value === null || value.length === 0)"
                      [style]="{maxHeight:placeHolderHeight}" class="placeHolder">
                <ng-container *ngTemplateOutlet="noDataTemplate !== undefined?noDataTemplate:defualtTemplateNoData"></ng-container>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </form>
  <div *ngIf="showNewEntryIcon">
    <div class="duplicateField">
      <i class="fas fa-plus" style="padding-top: 3px;"></i>
      <p (click)="addNewRow()">New Entry</p>
    </div>
  </div>
</div>

<ng-template #defualtTemplateNoData>
  <div class="noDataBox">
    <img [src]="commonService.getIconPath('emptyStateIcon')" class="iconClass"/>
    <p class="noDataHeader">Data is not available</p>
  </div>
</ng-template>


