<div class="line-to"></div>
<div
  [ngClass]="{
    'tree-element-container': !0,
    'tree-element-has-children': node.hasChildren()
  }"
  [style.height.px]="node.height"
  [style.width.px]="node.width"
  id="tree-node-{{ node.guid }}">
  <div
    (dragend)="node.dragend()"
    (dragenter)="dragenter($event,node)"
    (dragleave)="node.dragleave($event)"
    (dragover)="node.dragover($event)"
    (dragstart)="node.dragstart($event)"
    (drop)="node.drop($event)"
    [attr.draggable]="node.isMaker ? null : 'true'"
    [ngClass]="{
      'tree-element-main': !0,
      dragover: node.isDragover,
      expanded: node.isExpanded,
      dragging: node.isDragging,
      'tree-new-node': node.isMaker
    }"
    [style.height.px]="node.height"
    [style.width.px]="node.width"
  >
    <div
      [style.height.px]="node.height"
      [style.width.px]="node.width"
      class="rect tooltipsss"
    >
      <div class="buttons">
        <i (click)="node.destroy()" class="fas fa-times-circle fa-lg delete"></i>
        <div (click)="node.toggle()" class="toggler"></div>
        <i (click)="node.addChild()" class="fas fa-plus-circle fa-lg add"></i>
      </div>
      <div class="tree-text tree-text-non-editable">
        <span>{{node.displayName }}</span>
      </div>
      <div class="tree-text tree-text-editable">
        <span
          (blur)="onNodeBlur($event, node.guid)"
          (click)="changeEditing(true,node.guid)"
          [innerHtml]="node.editing?node.displayName:getName(node.displayName)"
          contenteditable
        ></span>

        <div [style.right.px]="node.width" [style.top.px]="node.height" class="tooltiptext">
          <div class="infobox">
            <p class="title">Name </p>
            <p class="info">{{node.getDisplayName()}}</p>
          </div>
          <div class="infobox">
            <p class="title">Children Count </p>
            <p class="info">{{node.childrenCount()}}</p>
          </div>
        </div>


        <!--        <span class="children-count"> ({{ node.childrenCount() }})</span>-->
      </div>
    </div>
  </div>
  <div
    *ngIf="node.isExpanded"
    [style.transform]="childrenTransform"
    class="tree-children"
  >
    <div class="tree-elements-group">
      <tree-diagram-node
        *ngFor="let child of node.children"
        [nodeId]="child"
        class="tree-node tree-child"
      ></tree-diagram-node>
    </div>
  </div>
</div>
