import {Component, OnInit, ViewChild} from '@angular/core';
import {Grid} from '../../grid/grid.model';
import {environment} from '../../../environments/environment';
import {Tcolumn} from '../../grid/tcolumn.model';
import {MasterService} from '../../masters/services/MasterService';
import {Filter} from '../../grid/preference/filter/filter.model';
import {defaultValue} from '../../grid/defaultValue.model';
import {CommonService} from '../../services/common.service';
import {tradeOptionsButtons} from '../../services/common/entities';
import {Button} from '../../grid/buttons.model';
import {Router} from '@angular/router';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexGrid,
  ApexLegend,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent
} from 'ng-apexcharts';
import {HttpErrorResponse} from '@angular/common/http';
import {MessageService} from 'primeng';
import {ObligationDashboardApprovalFunctions} from './obligation-dashboard-approval-functions';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  tooltip: ApexTooltip;
  legend: ApexLegend;
};

@Component({
  selector: 'app-trade-obligation',
  templateUrl: './trade-obligation.component.html',
  styleUrls: ['./trade-obligation.component.css']
})
export class TradeObligationComponent implements OnInit {

  columns: Map<string, Tcolumn>;
  grid: Grid;
  tradeId: string = '';
  proFormaInvoiceRow:any = {};
  selectedRows:any[] = [];
  summaryObject:any = {};
  graphData:any = {};
  display: boolean = false;
  filterObligationState: Filter;
  rowData: any = {};
  selectedPreset:any= {};
  refreshFilter: boolean = false;
  addedFilter: any[] = ['CONFIRMED'];
  showDateRange: boolean = false;
  presetList: any[] = [];
  gridFilters:any[] = [];


  tradeIdList:any[] = [];
  obligationIdList:any[] = [];
  deliveryDateList:any[] = [];
  commodityList:any[] = [];
  profitcenterList:any[] = [];
  traderList:any[] = [];
  loadPortList:any[] = [];
  unloadPortList:any[] = [];
  refreshGrid: boolean = false;
    obligationExtraButtons: any[] = [];
    displayInvoiceForm: boolean = false;
  invoiceProFormaObject: any;
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  graphError: boolean = false;
  graphLoading: boolean = true;
  showGraph: boolean = true;
    gridHeightRecalculate: boolean = false;

  constructor(private masterCommonService: MasterService, public commonService: CommonService,private router: Router,private messageService: MessageService) {
  }

  private generateDefaultPreset() {
    this.presetList.push({title:'Forward 3 months',startDate:new Date(),endDate:this.commonService.addMonthsInDate(new Date(),3)});
    this.presetList.push({title:'Forward 6 months',startDate:new Date(),endDate:this.commonService.addMonthsInDate(new Date(),6)});
    this.presetList.push({title:'Forward 1 year',startDate:new Date(),endDate:this.commonService.addYearsInDate(new Date(),1)});
    this.selectedPreset = this.presetList[0];
  }

  private getCommonFilterPayload(addCardInfo:boolean = false,addFilterInfo:boolean = false) {
    let payload = {
      fromDate: this.commonService.convertDatetoUTC(this.selectedPreset['startDate']),
      toDate: this.commonService.convertDatetoUTC(this.selectedPreset['endDate'])
    };
    if (addCardInfo) {
      payload['confirmed'] = this.addedFilter.includes('CONFIRMED');
      payload['planned'] = this.addedFilter.includes('PLANNED');
      payload['priced'] = this.addedFilter.includes('PRICED');
      payload['deliveryStarted'] = this.addedFilter.includes('DELIVERYSTARTED');
      payload['invoiceInitiated'] = this.addedFilter.includes('INVOICED');
    }
    if (addFilterInfo) {
      payload['tradeId'] = this.tradeIdList;
      payload['obligationId'] = this.obligationIdList;
      payload['deliveryDate'] = this.deliveryDateList;
      payload['commodity'] = this.commodityList;
      payload['profitcenter'] = this.profitcenterList;
      payload['trader'] = this.traderList;
      payload['loadPort'] = this.loadPortList;
      payload['dischargePort'] = this.unloadPortList;
    }
    return payload;
  }

  private resetFilters() {
      this.tradeIdList = [];
      this.obligationIdList = [];
      this.deliveryDateList = [];
      this.profitcenterList = [];
      this.traderList = [];
      this.loadPortList = [];
      this.unloadPortList = [];
      this.gridFilters = [];
      this.gridFilters.push({title:'Trade Id',list:[]});
      this.gridFilters.push({title:'Obligation Id',list:[]});
      this.gridFilters.push({title:'Delivery Date',list:[]});
      this.gridFilters.push({title:'Commodity',list:[]});
      this.gridFilters.push({title:'Profitcenter',list:[]});
      this.gridFilters.push({title:'Trader',list:[]});
      this.gridFilters.push({title:'Load Port',list:[]});
      this.gridFilters.push({title:'Discharge Port',list:[]});
  }

  private createGridFilters() {
    this.graphData = null;
    let payload = this.getCommonFilterPayload(true);
    let _this = this;
    this.resetFilters();
    if(this.showGraph && (this.graphData === null || this.graphData === undefined)) {
      this.getGraphData();
    }
    this.commonService.post(environment.base_url+'/api/tradeobligation/v1/getobligationfilterdata?tenantId='+this.commonService.getFromStorage('tenantId'),payload).subscribe((next) => {
      _this.gridFilters = [];
      var uniqueobligationId = next['obligationId'].filter(function(elem, index, self) {
        return index === self.indexOf(elem);})
      _this.gridFilters.push({title:'Trade Id',list:_this.masterCommonService.getListFromArray(next['tradeId'],false,false)});
      _this.gridFilters.push({title:'Obligation Id',list:_this.masterCommonService.getListFromArray(uniqueobligationId,false,false)});
      _this.gridFilters.push({title:'Delivery Date',list:_this.masterCommonService.getListFromDateArray(next['deliveryDate'],false,false)});
      _this.gridFilters.push({title:'Commodity',list:_this.masterCommonService.getListFromArray(next['commodity'],false,false)});
      _this.gridFilters.push({title:'Profitcenter',list:_this.masterCommonService.getListFromArray(next['profitcenter'],false,false)});
      _this.gridFilters.push({title:'Trader',list:_this.masterCommonService.getListFromArray(next['trader'],false,false)});
      _this.gridFilters.push({title:'Load Port',list:_this.masterCommonService.getListFromArray(next['loadPort'],false,false)});
      _this.gridFilters.push({title:'Discharge Port',list:_this.masterCommonService.getListFromArray(next['dischargePort'],false,false)});
    });
  }

  ngOnInit(): void {
    let _this = this;
    this.generateDefaultPreset();
    this.getSummary();
    this.createGridFilters();
    this.getColumns();
    this.grid = new Grid(this.columns, true, true, true, true, 'tradeId', '/api/tradeobligation/v1/getobligationgriddata?tenantId='+ this.commonService.getFromStorage('tenantId'), undefined, 'Obligations Dashboard', undefined, undefined);
    this.grid.setGridConfiguration(false, false, false, false, false, false, true, true, false, false);
    this.grid.openSidebarOnUniqueClick = false;
    this.grid.addClickable('tradeId');
    this.grid.iconName = 'obligationDashboard';
    this.grid.gridFetchUrlType = "POST";
    this.grid.isHaveExtraButtons =true;
    this.grid.fetchPostPayloadFuction = function (value:any[] = []) {
      return _this.getCommonFilterPayload(true,true);
    };
    this.grid.contextMenuConfig.functionFile = ObligationDashboardApprovalFunctions;
    this.grid.contextMenuConfig.triggerEvent = '';
    this.grid.contextMenuConfig.nameFieldId = '';
    this.grid.contextMenuConfig.referenceIdField = '';
    this.grid.contextMenuConfig.screenName = 'Obligation Dashboard';
    this.initGraph();
  }

  private getDataFromDots(label:string) {
    let obj = {};
    obj['name'] = label;
    obj['data'] = [];
    if(label === 'Confirmed') {
      obj['color'] = '#0495ea'
    } else if(label === 'Planned') {
      obj['color'] = '#fd5e7e'
    } else if(label === 'Price Allocated') {
      obj['color'] = '#f19202'
    } else if(label === 'Delivery Started') {
      obj['color'] = '#63c630'
    } else if(label === 'Invoice Initiated') {
      obj['color'] = '#15104e'
    }
    return obj;
  }


  private getValue(val) {
    let obj = this.graphData[this.commonService.getFormattedDate(new Date(val['x']),'dd MMM yyyy')];
    if(obj !== null && obj !== undefined) {
      return obj[val['meta']];
    }
    return 0;
  }

  private initGraph() {
    let _this = this;
    this.chartOptions = {
      series: [],
      chart: { height:130,type: "scatter",animations:{ enabled:false }, zoom: { enabled: true,type:'xy'}, toolbar:{ show : true , tools:{download:false,pan:false,selection:false,zoom:false,reset:false}} },
      dataLabels: { enabled: true,formatter:function (val: string | number | number[], opts?: any): string | number {
          return _this.getValue(opts['w']['config']['series'][opts['seriesIndex']]['data'][opts['dataPointIndex']]);
       },background:{borderRadius:10}
      },
      grid: { xaxis: { lines: { show: false } }, yaxis: { lines: { show: false } }},
      xaxis: { type: "numeric",tickAmount:'dataPoints',labels:{show:true,rotate:-45,rotateAlways:false,formatter(value: string, timestamp?: number, opts?: any): string | string[] {
            return _this.commonService.getFormattedDate(value,"MMM dd");
          }},tooltip:{enabled:true,formatter:function (value: string, opts?: object): string {
        return _this.commonService.getFormattedDate(new Date(value),"dd MMMM yyyy");
      }}},
      yaxis: { show: true ,max:6, min:0,tooltip:{enabled:false}}
    };
  }

  private getDateTime(date:any) {
    return new Date(date).getTime();
  }


  private getIndex(obj:any,afterValues:any[] =[]) {
    let index = 0;
    afterValues.forEach(function (value) {
      if(obj[value] !== 0) {
        index+= 2;
      }
    });
    return index;
  }

  private loadGraph() {
    let confirmed:any = this.getDataFromDots('Confirmed');
    let planned:any = this.getDataFromDots('Planned');
    let priceAllocated:any= this.getDataFromDots('Price Allocated');
    let deliveryStarted:any = this.getDataFromDots('Delivery Started');
    let invoiceInitiated:any = this.getDataFromDots('Invoice Initiated');
    if(this.graphData !== null && this.graphData !== undefined && Object.keys(this.graphData).length > 0) {
      let dates = Object.keys(this.graphData);
      dates.sort(function (a, b) {
        return new Date(a).getTime() - new Date(b).getTime()
      });
      let _this = this;
      this.chartOptions = null;
      let dateTime = 0;
      // let categories:any[] = [];
      dates.forEach(function (date) {
        dateTime = _this.getDateTime(date);
        // categories.push(_this.commonService.getFormattedDate(date,"MMM dd"));
        if(!_this.isZero(date,'confirmed')) {
          confirmed['data'].push({x:dateTime,y:_this.getIndex(_this.graphData[date],['invoiceInitiated','deliveryStarted','priceAllocated','planned']),meta:'confirmed'});
        }
        if(!_this.isZero(date,'planned')) {
          planned['data'].push({x:dateTime,y:_this.getIndex(_this.graphData[date],['invoiceInitiated','deliveryStarted','priceAllocated']),meta:'planned'});
        }
        if(!_this.isZero(date,'priceAllocated')) {
          priceAllocated['data'].push({x:dateTime,y:_this.getIndex(_this.graphData[date],['invoiceInitiated','deliveryStarted']),meta:'priceAllocated'});
        }
        if(!_this.isZero(date,'deliveryStarted')) {
          deliveryStarted['data'].push({x:dateTime,y:_this.getIndex(_this.graphData[date],['invoiceInitiated']),meta:'deliveryStarted'});
        }
        if(!_this.isZero(date,'invoiceInitiated')) {
          invoiceInitiated['data'].push({x:dateTime,y:_this.getIndex(_this.graphData[date],[]),meta:'invoiceInitiated'});
        }
      })
      this.initGraph();
      // this.chartOptions.xaxis.categories = categories;
      // this.chartOptions.xaxis.overwriteCategories = categories;
      if(this.addedFilter.includes("CONFIRMED")) {
        this.chartOptions.series.push(confirmed);
      }
      if(this.addedFilter.includes("PLANNED")) {
        this.chartOptions.series.push(planned);
      }
      if(this.addedFilter.includes("PRICED")) {
        this.chartOptions.series.push(priceAllocated);
      }
      if(this.addedFilter.includes("DELIVERYSTARTED")) {
        this.chartOptions.series.push(deliveryStarted);
      }
      if(this.addedFilter.includes("INVOICED")) {
        this.chartOptions.series.push(invoiceInitiated);
      }
    } else {
      this.graphError = true;
    }
    this.graphLoading = false;
  }

  private isZero(date,code) {
    return (this.graphData[date][code] === null || this.graphData[date][code] === undefined || this.graphData[date][code] === 0);
  }

  onClose(value: any) {
    this.tradeId = '';
    this.display = false;
  }

  /**
   * fetch trade id from rowData ehen clicked on uniquew click
   * @param value
   */
  onUniqueClick(value: any) {
    if (!value['editMode']) {
      this.tradeId = value['rowData']['tradeId'];
      this.rowData = value['rowData'];
      this.display = true;
    }
  }


  removeFilterFromAddedList(type) {
    this.addedFilter.splice(this.addedFilter.indexOf(type), 1);
    if (type === 'Confirmed' || type === 'Unconfirmed') {
      this.grid.removeFilter('tradeStatus');
    } else {
      this.grid.removeFilter('tradeObligationState');
    }
  }

  getSummary() {
    let _this = this;
    this.summaryObject = {};
    this.commonService.post(environment.base_url + '/api/tradeobligation/v1/getobligationsummary?tenantId=' + this.commonService.getFromStorage('tenantId'),this.getCommonFilterPayload()).subscribe((next: any) => {
      _this.summaryObject = next;
    });
  }

  getGraphData() {
    let _this = this;
    this.graphLoading = true;
    this.graphError = false;
    this.graphData = {};
    this.commonService.post(environment.base_url + '/api/tradeobligation/v1/getobligationgraphdata?tenantId=' + this.commonService.getFromStorage('tenantId'),this.getCommonFilterPayload(true)).subscribe((next: any) => {
      _this.graphData = next;
      _this.loadGraph();
    },(error:HttpErrorResponse) => {
      this.graphError = true;
      this.graphLoading = false;
    });
  }

  onRefreshComplete(value: any) {
    this.refreshGrid = false;
    if (value === 'filter') {
      this.refreshFilter = false;
    }
  }

  onClickRemove(type: string) {
    this.removeFilterFromAddedList(type);
    this.refreshFilter = true;
  }

  getTypeLabel(type: any) {
    if (type === 'CONFIRMED') {
      return 'Confirmed Trade';
    } else if (type === 'PLANNED') {
      return 'Planned Trade';
    } else if (type === 'PRICED') {
      return 'Priced Allocated';
    } else if (type === 'DELIVERYSTARTED') {
      return 'Delivery Started';
    } else if (type === 'INVOICED') {
      return 'Invoice Initiated';
    }
  }

  clearAllFilter() {
    let _this = this;
    let filterAdded = [...this.addedFilter];
    filterAdded.forEach(function (type) {
      _this.removeFilterFromAddedList(type);
    });
    this.refreshFilter = true;
    this.refreshGrid =false;
    this.createGridFilters();
    this.refreshGrid = true;
  }

  private getColumns() {
    let _this = this;
    let tradeQuantityOutputFunc = function (value:any[] =[]) {
      return _this.commonService.getFormatedNumber(value[0])+' '+value[1];
    };
    let viewStateFunction = function (value: any[] = []) {
      if(value[0] !== undefined && value[0] !== null) {
        if(_this.addedFilter.includes('CONFIRMED')) {
          return "Confirmed";
        }
      } else {
        if(value[1]['DELIVERY_STARTED'] && _this.addedFilter.includes('DELIVERYSTARTED') ) {
          return 'Delivery Started';
        } else if(value[1]['PRICED'] && _this.addedFilter.includes('PRICED')) {
          return 'Priced';
        } else if(value[1]['PLANNED'] && _this.addedFilter.includes('PLANNED')) {
          return 'Planned';
        } else {
          return 'Invoice Initiated';
        }
      }
    };
    this.columns = new Map();
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('tradeId', new Tcolumn('tradeId', 'Trade ID', 'LB', 1, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('tradeObligationId', new Tcolumn('tradeObligationId', 'Obligation ID', 'LB', 2, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('tradeType', new Tcolumn('tradeType', 'Trade Type', 'LB', 3, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('tradeTransactionType', new Tcolumn('tradeTransactionType', 'Buy/Sell', 'OB', 4, true, this.masterCommonService.getBooleanFromLabel('Buy','Sell',false,'BUY','SELL'), true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('tradeDateTime', new Tcolumn('tradeDateTime', 'Trade Date', 'D', 5, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('deliveryStartDate', new Tcolumn('deliveryStartDate', 'Delivery Start Date', 'D', 6, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('deliveryEndDate', new Tcolumn('deliveryEndDate', 'Delivery End Date', 'D', 7, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('tradeObligationState', new Tcolumn('tradeObligationState', 'Obligation State', 'LB', 8, false, undefined, true, new defaultValue('', undefined, undefined, undefined, false, true, false, viewStateFunction, ['tradeObligationState','obligationState']), 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('obligationState', new Tcolumn('obligationState', 'Obligation State', 'LB', 9, false, undefined, false, '', 'H', [], undefined, 1, undefined, undefined, undefined, undefined));
    this.columns.set('commodity', new Tcolumn('commodity', 'Commodity', 'L', 9, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('counterparty', new Tcolumn('counterparty', 'Counterparty', 'L', 10, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('tradeQuantity', new Tcolumn('tradeQuantity', 'Trade Quantity', 'N', 11, false, undefined, true, new defaultValue('',undefined,undefined,undefined,false,true,false,tradeQuantityOutputFunc,['tradeQuantity','quantityUOM']), 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('noOfUnits', new Tcolumn('noOfUnits', 'No of Units', 'N', 11, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('profitcenter', new Tcolumn('profitcenter', 'Profit Center', 'L', 12, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('incoterm', new Tcolumn('incoterm', 'Incoterm', 'L', 13, false,undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('location', new Tcolumn('location', 'Location', 'L', 14, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('tradePrice', new Tcolumn('tradePrice', 'Price', 'N', 15, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('tradePriceCurrency', new Tcolumn('tradePriceCurrency', 'Currency', 'L', 16, false, this.commonService.getCurrencyList(), true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('tradePriceUom', new Tcolumn('tradePriceUom', 'Price Uom', 'L', 17, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('cropOrigin', new Tcolumn('cropOrigin', 'Origin', 'L', 18, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('quantityUOM', new Tcolumn('quantityUOM', 'Unit of Measurement', 'L', 19, false, undefined, false, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('grade', new Tcolumn('grade', 'Grade', 'L', 20, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('plannedQuantity', new Tcolumn('plannedQuantity', 'Planned Delivered Quantity', 'N', 21, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('unPlannedQuantity', new Tcolumn('unPlannedQuantity', 'Unplanned Quantity', 'N', 22, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('loadLocation', new Tcolumn('loadLocation', 'Load Location', 'LB', 23, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('unloadLocation', new Tcolumn('unloadLocation', 'Discharge Location', 'LB', 24, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
  }

  toggleDateRange() {
    this.showDateRange = !this.showDateRange;
  }

  onPresetChange(preset: any) {
    this.refreshGrid = false;
    this.selectedPreset = preset;
    this.toggleDateRange();
    this.getSummary();
    this.createGridFilters();
    this.refreshGrid = true;
  }

  onCardSelect(cardId: any) {
    this.refreshGrid =false;
    this.addedFilter.push(cardId);
    this.createGridFilters();
    this.refreshGrid = true;
  }

  onCardUnselect(cardId: any) {
    this.refreshGrid =false;
    this.addedFilter.splice(this.addedFilter.indexOf(cardId),1);
    this.createGridFilters();
    this.refreshGrid = true;
  }

  onFilterChange(value: any) {
    this.refreshGrid = false;
    let type = value['title'];
    let selected = value['selected'];
    if(type === 'Trade Id') {
      this.tradeIdList = selected;
    } else if(type === 'Obligation Id') {
      this.obligationIdList = selected;
    }  else if(type === 'Delivery Date') {
      this.deliveryDateList = selected;
    }  else if(type === 'Commodity') {
      this.commodityList = selected;
    }  else if(type === 'Profitcenter') {
      this.profitcenterList = selected;
    } else if(type === 'Trader') {
      this.traderList = selected;
    } else if(type === 'Load Port') {
      this.loadPortList = selected;
    } else if(type === 'Discharge Port') {
      this.unloadPortList = selected;
    }
    this.refreshGrid = true;
  }

  getCountValue(type:string,key:string = 'buy') {
    if(this.summaryObject !== null && this.summaryObject !== undefined && this.summaryObject !== {} && this.summaryObject[type] !== null && this.summaryObject[type] !== undefined && this.summaryObject[type] !== {}) {
      return this.summaryObject[type][key];
    } else {
      return 0;
    }
  }

  onCancel(value) {
    this.showDateRange = false;
  }

    getPresetTitle() {
      if(this.selectedPreset['title'] === 'Date Range') {
         return (this.commonService.getFormattedDate(this.selectedPreset['startDate'],'dd MMMM yyyy')+'-'+this.commonService.getFormattedDate(this.selectedPreset['endDate'],'dd MMMM yyyy'));
      }
      return this.selectedPreset['title']
    }

  generateInvoice() {
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url+'/api/trade/v1/createinvoiceobjectbytrade?tradeId='+this.proFormaInvoiceRow['tradeId']+'&tenantId='+this.commonService.getFromStorage('tenantId')+'&username='+this.commonService.getFromStorage('userName') +'&advanceInvoice='+false).subscribe((next:any) => {
      _this.invoiceProFormaObject = next;
      _this.displayInvoiceForm = true;
    },(error:HttpErrorResponse) => {
      _this.showToast(_this.commonService.getHttpErrorMessage(error,"Operations"),'error');
    });
  }

  toggleGraph() {
    this.showGraph = !this.showGraph;
    this.gridHeightRecalculate = true;
    if(this.showGraph && (this.graphData === null || this.graphData === undefined)) {
      this.getGraphData();
    }
  }

  showToast(msg, severity: string = 'success') {
    this.messageService.add({
      severity: severity,
      summary: msg
    });
  }


  onDraftClose() {
    this.selectedRows = [];
    this.displayInvoiceForm = false;
  }

  onGenerateDone() {
    this.selectedRows = [];
    this.displayInvoiceForm = false;
    this.messageService.add({severity:'info', summary:'Invoice Generated Successfully'});
  }

  onHeightRefreshDone() {
    this.gridHeightRecalculate = false;
  }

  onclickRouter(){
    this.router.navigate(['/operationsDashboard']);
  }

  onClickMenuItem(item: any) {
    let title = item.title;
    let rowData = item.data;
    let _this = this;
    if(title === 'Trade Planning') {
      let tradeObligationId = rowData['tradeObligationId'];
      _this.router.navigate(['/planning'],{ queryParams: { value:tradeObligationId,mode:'planning'}});
    } else if (title === 'Trade Plan') {
      let plannedObligationId = rowData['plannedObligationId'];
      if(plannedObligationId !== null && plannedObligationId !== undefined && plannedObligationId.length > 0) {
        _this.router.navigate(['/planning'],{ queryParams: { value:plannedObligationId,mode:'planned'}});
      }
    } else if (title === 'Allocate Price') {
      let tradeId = rowData['tradeId'];
      if(tradeId !== null && tradeId !== undefined && tradeId.length > 0) {
        _this.router.navigate(['/priceAllocation'],{ queryParams: { value:tradeId}});
      }
    } else if (title === 'Transport Allocation') {
      let planId = rowData['planId'];
      if(planId !== null && planId !== undefined && planId.length > 0) {
        _this.router.navigate(['/tradeAllocation'],{ queryParams: { value:planId}});
      }
    } else if (title === 'Actualization') {
      let planId = rowData['planId'];
      if(planId !== null && planId !== undefined && planId.length > 0) {
        _this.router.navigate(['/tradeActualization'],{ queryParams: { value:planId}});
      }
    } else if (title === 'Settlement') {
      let plannedObligationId = rowData['plannedObligationId'];
      if(plannedObligationId !== null && plannedObligationId !== undefined && plannedObligationId.length > 0) {
        _this.router.navigate(['/settlement'],{ queryParams: { value:plannedObligationId}});
      }
    } else if (title === tradeOptionsButtons.generateProFormaInvoice) {
      _this.proFormaInvoiceRow = rowData
      _this.generateInvoice();
    }else if (title === 'Invoice') {
      let plannedObligationId = rowData['plannedObligationId'];
      if(plannedObligationId !== null && plannedObligationId !== undefined && plannedObligationId.length > 0) {
        _this.router.navigate(['/invoices'],{ queryParams: { plannedObligationId:plannedObligationId}});
      }
    }
  }
}
