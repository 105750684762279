import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {environment} from '../../../../environments/environment';
import {CommonService} from '../../../services/common.service';
import {Tcolumn} from '../../../grid/tcolumn.model';
import {MasterService} from '../../services/MasterService';
import {FormControlServiceService} from '../../../services/form-control-service.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ContextMenu, MenuItem, MessageService} from 'primeng';
import {KYC} from './kyctype';
import {KeyValue} from '../../../grid/key-value.model';
import {Button} from '../../../grid/buttons.model';
import {kycApprovalFunctions} from './kyc-approval-functions';

@Component({
    selector: 'app-kyc',
    templateUrl: './kyc.component.html',
    styleUrls: ['./kyc.component.css']
})
export class KycComponent implements OnInit {
    selectedRow: any;
    editmode: boolean = false;
    draftmode: boolean = false;
    name = '!!!';
    viewMode = 'tab1';
    kycForm: FormGroup;
    UltimateForm: FormGroup;
    functionFile = kycApprovalFunctions;
    TopBuyerForm: FormGroup;
    bsConfig: Partial<BsDatepickerConfig>;
    @Input() data: any[] = [];
    kycUltimateColumns: Tcolumn[] = [];
    kycTopBuyerAndSellerColumns: Tcolumn[] = [];
    ultimateValues: any[] = [];
    topBuyerValues: any[] = [];
    companyType: any[] = [];
    riskList: any[] = [];
    yearlist = this.getyearlist(1991, new Date().getFullYear());

    validationSections: any = {};
    @Input() counterpartyId: string = '';
    @Input() counterpartyName: string = '';
    draftdata: KYC;
    updatemode: boolean = false;
    contextMenuList: any[] = [];
    private formControlService: FormControlServiceService = new FormControlServiceService();
    modalState: boolean = false;
    note: any = '';
    apifunc: Function;
    workflowInfo: any;
    currentStatus: string = 'draft';

    constructor(public commonService: CommonService, private masterCommonService: MasterService, private messageService: MessageService) {
    }

    ngOnInit(): void {
        this.bsConfig = new BsDatepickerConfig();
        this.bsConfig.dateInputFormat = environment.dateFormat.toUpperCase();
        this.bsConfig.adaptivePosition = true;
        this.bsConfig.useUtc = true;
        this.initForm();
        this.getkycUltimateColoumns([]);
        this.getkycTopBuyerAndSellerColumns([{label: 'Select', value: ''}, {label: 'Buyer', value: 'Buyer'}, {
            label: 'Supplier',
            value: 'Supplier'
        }, {label: 'Both', value: 'Both'}]);
        this.getkycfunc();
        let _this = this;
        _this.commonService.getLoadData('counterparty', ['data', 'data'], [[{
            key: 'name',
            value: 'kyc_company_type_ind'
        }], [{
            key: 'name',
            value: 'kyc_risk_level_ind'
        }]]).subscribe((next: any) => {
            _this.companyType = _this.masterCommonService.getListFromArray(next['kyc_company_type_ind'], false, true);
            _this.riskList = _this.masterCommonService.getListFromArray(next['kyc_risk_level_ind'], false, true);
        });


        let ultimatefields = {};
        ultimatefields['ownerName'] = 'ownerName';
        ultimatefields['nationality'] = 'nationality';
        ultimatefields['identificationType'] = 'identificationType';
        ultimatefields['identificationNumber'] = 'identificationNumber';
        ultimatefields['ownershipPercentage'] = 'ownershipPercentage';
        ultimatefields['roleInTheCompany'] = 'roleInTheCompany';
        ultimatefields['addressOfResidence'] = 'addressOfResidence';
        ultimatefields['dateOfAppointment'] = 'dateOfAppointment';
        ultimatefields['registeredPhoneNumber'] = 'registeredPhoneNumber';
        ultimatefields['emailID'] = 'emailID';
        ultimatefields['personInCharge'] = 'personInCharge';

        let topBuyerfields = {};
        topBuyerfields['ownerName'] = 'ownerName';
        topBuyerfields['nationality'] = 'nationality';
        topBuyerfields['identificationType'] = 'identificationType';
        topBuyerfields['identificationNumber'] = 'identificationNumber';
        topBuyerfields['ownershipPercentage'] = 'ownershipPercentage';

        this.validationSections = {};
        this.validationSections['KYC Renewal, Business and Risk Profile'] = [
            {field: 'tradeLicenseNumber', header: 'Trade License Number'},
            {field: 'tradeExpiryDate', header: 'Trade Expiry Date'},
            {field: 'tradeLicenseAuthority', header: 'Trade License Authority'},
            {field: 'natureOfBusiness', header: 'Nature Of Business'},
            {field: 'yearOfIncorporation', header: 'Year Of Incorporation'},
            {field: 'riskLevel', header: 'Risk Level'},
            {field: 'kycRenewalComments', header: 'KYC Renewal Comments'},
            {field: 'kycDueDate', header: 'KYC Due Date'},
        ];
        this.validationSections['Ultimate Beneficial Owners and Executives details'] = [{
            field: 'ulitmateForm',
            header: 'Ultimate Beneficial Owners',
            grid: true,
            fields: ultimatefields
        }];
        this.validationSections['Top Buyer & Supplier  Details'] = [{
            field: 'topBuyerForm',
            header: 'Top Buyer & Supplier  Details',
            grid: true,
            fields: topBuyerfields
        }];
        // this.apifunc = function(postbuttonapiobj: any) {
        //     if (_this.currentApprovalObject.buttonName === 'Block') {
        //         postbuttonapiobj['url'] = environment.base_url + `/api/counterpartykyc/v1/block-kyc?referenceId=${_this.commonService.getEncodedURI(_this.counterpartyId)}&tenantId=${_this.commonService.getTenantId()}`;
        //         postbuttonapiobj['type'] = 'post';
        //         postbuttonapiobj['payload'] = _this.note;
        //     } else {
        //         postbuttonapiobj['url'] = environment.base_url_new + `/approval-workflow/api/workflow-process/update-approval-workflow/${_this.currentApprovalObject.workflow.uuid}/${_this.currentApprovalObject.step.actionName}/${_this.currentApprovalObject.buttonName}`;
        //         postbuttonapiobj['type'] = 'post';
        //         postbuttonapiobj['payload'] = _this.note;
        //     }
        //     return postbuttonapiobj;
        // };
    }

    getyearlist(minyear: number, maxyear: number) {
        let list: KeyValue[] = [new KeyValue()];
        for (let i = minyear; i <= maxyear; i++) {
            list.push(new KeyValue(i, i));
        }
        return list;
    }

    initForm() {
        this.kycForm = this.formControlService.addControl('tradeLicenseNumber', this.data['tradeLicenseNumber'], [Validators.required])
            .addControl('tradeExpiryDate', this.commonService.convertUTCtoDate(this.data['tradeExpiryDate']), [Validators.required])
            .addControl('tradeLicenseAuthority', this.data['tradeLicenseAuthority'], [Validators.required])
            .addControl('natureOfBusiness', this.data['natureOfBusiness'], [Validators.required])
            .addControl('yearOfIncorporation', this.data['yearOfIncorporation'], [Validators.required])
            .addControl('companyType', this.data['companyType'], [Validators.required])
            .addControl('riskLevel', this.data['riskLevel'], [Validators.required])
            .addControl('kycRenewalComments', this.data['kycRenewalComments'], [Validators.required])
            .addControl('kycDueDate', this.commonService.convertUTCtoDate(this.data['kycDueDate']), [Validators.required]).build();
    }

    getkycfunc() {
        this.note = '';
        // this.currentApprovalObject = {buttonName: '', step: null, workflow: null};
        this.currentStatus = 'draft';
        this.commonService.getJSONByURL(environment.base_url + '/api/counterpartykyc/v1/getkyc?tenantId='
            + this.commonService.getFromStorage('tenantId') + '&uuid=' + this.counterpartyId).subscribe((next: any) => {
            this.draftmode = next['counterpartyBusinessProfile']['kycStatus'].toLowerCase() === 'draft';
            this.editmode = false;
            this.draftdata = next;
            if (this.draftdata !== null && this.draftdata !== undefined) {
                this.currentStatus = this.draftdata['counterpartyBusinessProfile']['kycStatus'].toLowerCase();
            }
            //this.getMenuButtons();
        }, (error: HttpErrorResponse) => {
            this.draftdata = null;
            this.editmode = false;
            this.draftmode = false;
        });
    }

    getkycUltimateColoumns(ultimateList: any[]) {
        this.kycUltimateColumns.push(new Tcolumn('ownerName', 'Owner name', 'T', 0, true, ultimateList, true, '', 'B', [Validators.required]));
        this.kycUltimateColumns.push(new Tcolumn('nationality', 'Nationality', 'T', 1, true, undefined, true, '', 'B', [Validators.required]));
        this.kycUltimateColumns.push(new Tcolumn('identificationType', 'Identification type', 'T', 2, true, undefined, true, '', 'B', [Validators.required]));
        this.kycUltimateColumns.push(new Tcolumn('identificationNumber', 'Identification number', 'T', 4, true, undefined, true, '', 'B', [Validators.required]));
        this.kycUltimateColumns.push(new Tcolumn('ownershipPercentage', 'Ownership Percentage', 'T', 0, true, ultimateList, true, '', 'B', [Validators.required]));
        this.kycUltimateColumns.push(new Tcolumn('roleInTheCompany', 'Role in the Company', 'T', 1, true, undefined, true, '', 'B', [Validators.required]));
        this.kycUltimateColumns.push(new Tcolumn('addressOfResidence', 'Address of Residence', 'T', 2, true, undefined, true, '', 'B', [Validators.required]));
        this.kycUltimateColumns.push(new Tcolumn('dateOfAppointment', 'Date of Appointment', 'D', 4, true, undefined, true, '', 'B', [Validators.required]));
        this.kycUltimateColumns.push(new Tcolumn('registeredPhoneNumber', 'Registered Phone number', 'N', 0, true, undefined, true, '', 'B', [Validators.maxLength(10)]));
        this.kycUltimateColumns.push(new Tcolumn('emailID', 'Email ID', 'T', 1, true, undefined, true, '', 'B', [Validators.required]));
        this.kycUltimateColumns.push(new Tcolumn('personInCharge', 'Person In Charge', 'CUSTOM', 1, true, undefined, true, true, 'B', [Validators.required]));
    }

    getkycTopBuyerAndSellerColumns(topBuyerAndSeller: any[]) {
        this.kycTopBuyerAndSellerColumns.push(new Tcolumn('fullName', 'Full Name', 'T', 0, true, undefined, true, '', 'B', [Validators.required]));
        this.kycTopBuyerAndSellerColumns.push(new Tcolumn('country', 'Country', 'T', 1, true, undefined, true, '', 'B', [Validators.required]));
        this.kycTopBuyerAndSellerColumns.push(new Tcolumn('lineOfBusiness', 'Line of Business', 'T', 2, true, undefined, true, '', 'B', [Validators.required]));
        this.kycTopBuyerAndSellerColumns.push(new Tcolumn('traderType', 'Buyer/Supplier/Both', 'L', 4, true, topBuyerAndSeller, true, '', 'B', [Validators.required]));
        this.kycTopBuyerAndSellerColumns.push(new Tcolumn('websiteLink', 'Website link', 'T', 0, true, undefined, true, '', 'B', [Validators.required]));
    }

    showToast(msg, severity: string = 'info') {
        this.messageService.add({
            severity: severity,
            summary: msg
        });
    }

    savekyc() {
        let _this = this;
        let payload = {
            'counterpartyBusinessProfile': {
                'tenantId': this.commonService.getFromStorage('tenantId'),
                'counterpartyUuid': this.counterpartyId,
                'tradeLicenseNumber': this.kycForm.controls['tradeLicenseNumber'].value,
                'tradeExpiryDate': this.commonService.convertUTCtoDate(this.kycForm.controls['tradeExpiryDate'].value),
                'tradeLicenseAuthority': this.kycForm.controls['tradeLicenseAuthority'].value,
                'natureOfBusiness': this.kycForm.controls['natureOfBusiness'].value,
                'yearOfIncorporation': this.kycForm.controls['yearOfIncorporation'].value,
                'companyType': this.kycForm.controls['companyType'].value,
                'kycRenewalDetails': [
                    {
                        'kycDueDate': this.commonService.convertUTCtoDate(this.kycForm.controls['kycDueDate'].value),
                        'kycRenewalComments': this.kycForm.controls['kycRenewalComments'].value,
                        'tradeLicenseNumber': this.kycForm.controls['tradeLicenseNumber'].value,
                        'riskLevel': this.kycForm.controls['riskLevel'].value
                    }
                ],
                'createdTimestamp': null,
                'kycStatus': 'Draft'
            },
            'topBuyersAndSuppliers': [],
            'ultimateBeneficialOwners': []
        };
        this.ultimateValues.map((el, i) => {
            payload.ultimateBeneficialOwners.push(el);
        });
        this.topBuyerValues.map((el, i) => {
            payload.topBuyersAndSuppliers.push(el);
        });

        if (this.updatemode) {
            payload.counterpartyBusinessProfile['uuid'] = this.draftdata.counterpartyBusinessProfile.uuid;
            payload.counterpartyBusinessProfile['createdTimestamp'] = this.draftdata.counterpartyBusinessProfile.createdTimestamp;
            this.draftdata.ultimateBeneficialOwners.map((el, i) => {
                payload.ultimateBeneficialOwners[i]['uuid'] = el.uuid;
            });
            this.draftdata.topBuyersAndSuppliers.map((el, x) => {
                payload.topBuyersAndSuppliers[x]['uuid'] = el.uuid;
            });
        }

        this.commonService.post(environment.base_url + '/api/counterpartykyc/v1/createkyc?', payload).subscribe(next => {
            this.showToast('KYC Added.');
            this.getkycfunc();
        }, (error: HttpErrorResponse) => {
            _this.showToast(_this.commonService.getHttpErrorMessage(error, 'Counterparty KYC', false), 'error');
        });
    }

    oneditclick() {
        this.editmode = true;
        this.draftmode = false;
        this.updatemode = true;
        this.viewMode = 'tab1';
        this.kycForm.controls['tradeLicenseNumber'].setValue(this.draftdata.counterpartyBusinessProfile.tradeLicenseNumber);
        this.kycForm.controls['tradeExpiryDate'].setValue(this.commonService.convertUTCtoDate(this.draftdata.counterpartyBusinessProfile.tradeExpiryDate));
        this.kycForm.controls['tradeLicenseAuthority'].setValue(this.draftdata.counterpartyBusinessProfile.tradeLicenseAuthority);
        this.kycForm.controls['natureOfBusiness'].setValue(this.draftdata.counterpartyBusinessProfile.natureOfBusiness);
        this.kycForm.controls['yearOfIncorporation'].setValue(this.draftdata.counterpartyBusinessProfile.yearOfIncorporation);
        this.kycForm.controls['companyType'].setValue(this.draftdata.counterpartyBusinessProfile.companyType);
        this.kycForm.controls['riskLevel'].setValue(this.draftdata.counterpartyBusinessProfile.kycRenewalDetails[0].riskLevel);
        this.kycForm.controls['kycRenewalComments'].setValue(this.draftdata.counterpartyBusinessProfile.kycRenewalDetails[0].kycRenewalComments);
        this.kycForm.controls['kycDueDate'].setValue(new Date(this.draftdata.counterpartyBusinessProfile.kycRenewalDetails[0].kycDueDate));

        let ultimate = this.draftdata.ultimateBeneficialOwners.map((el, i) => {
            return {
                ownerName: el.ownerName,
                nationality: el.nationality,
                identificationType: el.identificationType,
                identificationNumber: el.identificationNumber,
                ownershipPercentage: el.ownershipPercentage,
                roleInTheCompany: el.roleInTheCompany,
                addressOfResidence: el.addressOfResidence,
                dateOfAppointment: el.dateOfAppointment,
                registeredPhoneNumber: el.registeredPhoneNumber,
                emailID: el.emailID,
                personInCharge: el.personInCharge
            };
        });
        let topBuyer = this.draftdata.topBuyersAndSuppliers.map((el, i) => {
            return {
                fullName: el.fullName,
                country: el.country,
                lineOfBusiness: el.lineOfBusiness,
                traderType: el.traderType,
                websiteLink: el.websiteLink
            };
        });
        this.ultimateValues = ultimate;
        this.topBuyerValues = topBuyer;
    }

    updateFormGroupUltimate(value: FormGroup) {
        this.UltimateForm = value;
        this.kycForm = this.formControlService.addFormGroupControl('ulitmateForm', this.UltimateForm).build();
    }

    updateFormGroupTopBuyer(value: FormGroup) {
        this.TopBuyerForm = value;
        this.kycForm = this.formControlService.addFormGroupControl('topBuyerForm', this.TopBuyerForm).build();
    }

    tab1click() {
        this.viewMode = 'tab1';
    }

    tab2click() {
        this.viewMode = 'tab2';
    }

    tab3click() {
        this.viewMode = 'tab3';
    }


    ondeleteultimate(value: any[]) {
        if (value['value'].length == 0) {
            this.kycForm = this.formControlService.removeFormGroupControl('ulitmateForm').build();
        }
    }

    // getMenuButtons() {
    //     let _this = this;
    //     this.contextMenuList = [];
    //     if (this.draftdata['counterpartyBusinessProfile']['kycStatus'].toLowerCase() === 'draft') {
    //         let sendForApproval = function(value) {
    //             _this.startWorkFlow();
    //         };
    //         this.contextMenuList.push(this.getMenuItem(new Button('Send For Approval', 'G', 'Send For Approval', sendForApproval, '', '', undefined, [], false)));
    //     } else {
    //         let url = environment.base_url_new + `/approval-workflow/api/workflow-process/get-workflow-process/${_this.commonService.getEncodedURI(this.counterpartyId)}`;
    //         let updateWorkFlow = function(value) {
    //             let buttonName = value['buttonName'];
    //             let step = value['step'];
    //             let workflow = value['workflow'];
    //             _this.currentApprovalObject.workflow = workflow;
    //             _this.currentApprovalObject.buttonName = buttonName;
    //             _this.currentApprovalObject.step = step;
    //             _this.modalState = true;
    //         };
    //         this.commonService.getJSONByURL(url).subscribe((next: any[]) => {
    //             if (next !== null && next !== undefined && next.length > 0) {
    //                 this.contextMenuList = this.commonService.getContextMenu(next[0], updateWorkFlow, true, (this.currentStatus === 'blocked'));
    //             }
    //             let step: any[] = next[0].workflowSteps.filter(value => value['stepImpact'] !== null && value['stepImpact'] !== undefined && value['stepImpact'].toLowerCase() === 'positive' && value['updateStatus'].toLowerCase() !== 'pending');
    //             let block = function(value) {
    //                 _this.currentApprovalObject.workflow = {workflowName: 'KYC Approval'};
    //                 _this.currentApprovalObject.buttonName = 'Block';
    //                 _this.currentApprovalObject.step = {};
    //                 _this.modalState = true;
    //             };
    //             this.contextMenuList.push(this.getMenuItem(new Button('Block', 'G', 'Block', block, '', '', undefined, [], !(step.length > 0 && this.currentStatus !== 'blocked'))));
    //         });
    //     }
    // }

    closeModal() {
        this.note = '';
        this.modalState = false;
    }

    openCm(mouseEvent: MouseEvent, cm: ContextMenu) {
        mouseEvent.preventDefault();
        mouseEvent.stopPropagation();
        cm.toggle(mouseEvent);
    }

    private getMenuItem(button: Button) {
        let _this = this;
        let items: MenuItem[] = [];
        if (button.sublist !== null && button.sublist !== undefined && button.sublist.length > 0) {
            button.sublist.forEach((button1: Button) => {
                items.push(_this.getMenuItem(button1));
            });
        }
        let menuItem: MenuItem = {
            label: button.title,
            disabled: button.disabled,
            items: items.length === 0 ? null : items,
            command: (event) => {
                let newData = _this.commonService.runFunction({
                    saveUrl: '',
                    extra: button.extra
                }, button.onClick);
            }
        };
        return menuItem;
    }

    startWorkFlow(renewal: boolean = false) {
        let _this = this;
        let tenantId = this.commonService.getFromStorage('tenantId');
        let userName = this.commonService.getFromStorage('userName');
        let url = environment.base_url + '/api/counterpartykyc/v1/kyc-start-workflow?triggerEvent=KYCApproval' +
            '&referenceId=' + this.commonService.getEncodedURI(this.counterpartyId) +
            '&tenantId=' + tenantId +
            '&userName=' + userName;
        this.commonService.getJSONByURL(url).subscribe((next) => {
            _this.getkycfunc();
            _this.showToast(renewal ? 'Renewal Process Started' : 'Sent For Approval', 'success');
        }, (error: HttpErrorResponse) => {
            _this.showToast(_this.commonService.getHttpErrorMessage(error, 'Counterparty KYC'), 'error');
        });
    }

    getTodayDate() {
        return new Date();
    }

    onUpdate(event: any) {
        this.getkycfunc();
    }
}


