<ctrm-grid *ngIf="loadDataList !== undefined && loadDataList.length != 0"
                         (heirarchyEmitter)="openHeirarcy($event)"
                         [breadcrumbMapKey]="commonService.MASTER_MAP_KEY"
                         [currentRoute]="'/profitCenters'"
                         [grid]="grid">
</ctrm-grid>

<heirarchy-view (onClose)="closeBtn()"
                *ngIf="modalStateHierarchy"
                [entityName]="getEntityName()"
                [nameField]="'name'"
                [primaryKey]="'name'"
                [rowData]="selectedRowData"
                [titlePrefix]="'Profit Center Hierarchy'"
                [visible]="modalStateHierarchy">
</heirarchy-view>
