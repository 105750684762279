import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {CommonService} from '../../../services/common.service';
import {BreadcrumbService} from '../../../Components/ctrm-breadcrumb/breadcrumb.service';
import {ConfirmationService, MessageService, Table} from 'primeng';
import {priceAllocationMessages} from '../../../services/common/messages';
import {environment} from '../../../../environments/environment';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-allocation-matching',
  templateUrl: './allocation-matching.component.html',
  styleUrls: ['./allocation-matching.component.css']
})
export class AllocationMatchingComponent implements OnInit, OnChanges {

  @Output() matchingScreenClose = new EventEmitter();
  @Input() tradeId: any;
  @Input() priceUom: any;
  @Input() quantityUom: any;
  matchingScreenOpen: boolean = true;
  quantityRoundOff:number=3;
  reset: boolean = false;
  resetObligation: boolean = false;
  isRefreshing: boolean = false;
  priceRoundOff:number=2;
  selectedPriceRows: any[] = [];
  selectedObligationRows: any[] = [];
  isLoading: boolean = false;
  priceTotal: any = 0;
  obligationTotal: any = 0;
  priceCols: any;
  obligationCols: any[] =[];
  priceValue: any;
  obligationValue: any;
  globalFilterValue: string = '';
  balanceFunc: any;
  balanceRightFunc: any;
  errorMessage: any[] = [];
  selectedPlans: any[] = [];
  priceTableHeight: string = '';
  @ViewChild('table', {static: true}) table: Table;
  divEl: HTMLDivElement;
  gridHeight: any = '300px';
  planData: any[] = [];
  noDataBoxLeft: any = '';
  loadingMessage: string = 'Loading ...';
  allocatedCols: any;
  noDataLabel: any = 'Loading ...';
  callApiFunc: Function;

  constructor(public commonService: CommonService, private messageService: MessageService, public breadCrumbService: BreadcrumbService,private confirmationService: ConfirmationService) {
  }

  ngOnInit(): void {
    let _this = this;
    let tenantConfig=JSON.parse(this.commonService.getFromStorage("tenantConfig"));
    if(tenantConfig && tenantConfig.roundingFormat){
      _this.priceRoundOff=tenantConfig.roundingFormat.priceRounding;
      _this.quantityRoundOff=tenantConfig.roundingFormat.quantityRounding;
    }
    else{
      _this.priceRoundOff=2;
      _this.quantityRoundOff=3;
    }
    this.balanceFunc = function(data) {
      return _this.commonService.formatNumberWithoutComma(parseFloat(data['balanceQuantity']),_this.quantityRoundOff);
    };

    this.balanceRightFunc = function (data) {
      return _this.commonService.formatNumberWithoutComma(parseFloat(data['balanceQuantity']),_this.quantityRoundOff);
    };
    this.priceValue = [];
    this.obligationValue = [];

    let viewPricedQuantityFunction = function (value: any) {
      return _this.commonService.formatNumberWithoutComma(value['pricedQuantity'],_this.quantityRoundOff) + ' ' + value['tradePriceUom'];
    };

    let viewTradeQuantityFunction = function (value: any) {
      return _this.commonService.formatNumberWithoutComma(value['plannedQuantity'],_this.quantityRoundOff) + ' ' + value['tradePriceUom'];
    };

    let plannedQuantityFunction =function(value:any){
      return _this.commonService.formatNumberWithoutComma(value['plannedQuantity'],_this.quantityRoundOff)  + ' ' + value['tradePriceUom'];
    }

    let latestQuantityFunction =function(value:any){
      return _this.commonService.formatNumberWithoutComma(value['actualizedQuantity'],_this.quantityRoundOff)  + ' ' + value['tradePriceUom'];
    }
    let matchQuantityFunction = function (value: any){
      return _this.commonService.formatNumberWithoutComma(value['matchQuantity']);
    }

    let basisFunction = function (value: any) {
      return _this.commonService.formatNumberWithoutComma(value['basis'],_this.priceRoundOff) + ' ' + value['tradePriceCurrency'] + '/' + value['tradePriceUom'];
    };

    let totalPriceFunction = function (value: any) {
      return _this.commonService.formatNumberWithoutComma(value['total'],_this.priceRoundOff) + ' ' + value['tradePriceCurrency'] + '/' + value['tradePriceUom'];
    };

    let basePriceFunction = function (value: any) {
      return _this.commonService.formatNumberWithoutComma(value['basePrice'],_this.priceRoundOff) + ' ' + value['tradePriceCurrency'] + '/' + value['tradePriceUom'];
    };

    let freightFunction = function (value: any) {
      return _this.commonService.formatNumberWithoutComma(value['freight'],_this.priceRoundOff) + ' ' + value['tradePriceCurrency'] + '/' + value['tradePriceUom'];
    };

    let insurancetFunction = function (value: any) {
      return _this.commonService.formatNumberWithoutComma(value['insurance'],_this.priceRoundOff) + ' ' + value['tradePriceCurrency'] + '/' + value['tradePriceUom'];
    };

    let commissionFunction = function (value: any) {
      return _this.commonService.formatNumberWithoutComma(value['commission'],_this.priceRoundOff) + ' ' + value['tradePriceCurrency'] + '/' + value['tradePriceUom'];
    };

    let premiumFunction = function (value: any) {
      return _this.commonService.formatNumberWithoutComma(value['premium'],_this.priceRoundOff) + ' ' + value['tradePriceCurrency'] + '/' + value['tradePriceUom'];
    };

    let brokerageFunction = function (value: any) {
      return _this.commonService.formatNumberWithoutComma(value['rollover'],_this.priceRoundOff) + ' ' + value['tradePriceCurrency'] + '/' + value['tradePriceUom'];
    };

    let othersFunction = function (value: any) {
      return _this.commonService.formatNumberWithoutComma(value['others'],_this.priceRoundOff) + ' ' + value['tradePriceCurrency'] + '/' + value['tradePriceUom'];
    };

    this.priceCols = [
      {field: 'pricedQuantity', header: 'Priced Quantity', valueFunction: viewPricedQuantityFunction},
      {field: 'total', header: 'Trade Price', valueFunction: totalPriceFunction},
      {field: 'basis', header: 'Basis',valueFunction: basisFunction},
      {field: 'basePrice', header: 'Base Price', valueFunction: basePriceFunction},
      {field: 'freight', header: 'Freight',valueFunction: freightFunction},
      {field: 'insurance', header: 'Insurance',valueFunction: insurancetFunction},
      {field: 'commission', header: 'Commission',valueFunction: commissionFunction},
      {field: 'premium', header: 'Premium',valueFunction: premiumFunction},
      {field: 'rollover', header: 'Rollover',valueFunction: brokerageFunction},
      {field: 'others', header: 'Others',valueFunction: othersFunction},
      {field: 'CB', header: 'CB'},
      {field: 'matchQuantity', header: 'Match Quantity', editable: true,valueFunction:matchQuantityFunction},
      {field: 'balanceQuantity', header: 'Balance Quantity',valueFunction:this.balanceFunc}
    ];

    this.obligationCols = [];
    this.obligationCols.push({field: 'balanceQuantity', header: 'Balance Quantity'});
    this.obligationCols.push({field: 'matchQuantity', header: 'Match Quantity', editable: true,valueFunction:matchQuantityFunction});
    this.obligationCols.push({field: 'CB', header: 'CB'});
    this.obligationCols.push({field: 'plannedQuantity', header: 'Planned Qty',valueFunction: plannedQuantityFunction});
    this.obligationCols.push({field: 'Actualized Quantity', header: 'Actualized Qty',valueFunction: latestQuantityFunction});
    this.obligationCols.push({field: 'loadLocation', header: 'Location(LOAD)'});
    this.obligationCols.push({field: 'unloadLocation', header: 'Location(UNLOAD)'});
    this.obligationCols.push({field: 'incoterm', header: 'Incoterm'});
    this.obligationCols.push({field: 'tradeType', header: 'Trade Type'});
    this.obligationCols.push({field: 'commodity', header: 'Commodity'});
    this.obligationCols.push({field: 'plannedObligationId', header: 'Obligation Id'});

    this.callApiFunc = function(postbuttonapiobj) {
      if (_this.checkMatch()) {
        let priceLineMatchDTO = [];
        _this.selectedPriceRows.forEach(function (value) {
          priceLineMatchDTO.push({
            priceLineId: value['priceLineId'],
            quantity: value['matchQuantity']
          });
        });
        let allocatePriceObj = {
          obligationId: _this.selectedObligationRows[0].plannedObligationId,
          priceLineMatchDTO : priceLineMatchDTO
        };
        postbuttonapiobj['url'] = environment.base_url + '/api/allocation/v1/allocate-price-lines';
        postbuttonapiobj['type'] = 'post';
        postbuttonapiobj['payload'] = allocatePriceObj;
        return postbuttonapiobj;
      }
      return postbuttonapiobj;
    };
  }

  checkMatch() {
    this.errorMessage = [];
    if (this.priceTotal === 0 && this.obligationTotal === 0) {
      this.errorMessage.push(priceAllocationMessages.quantity_zero);
    }
    return !(this.errorMessage.length > 0);
  }

  openAllocationMain() {
    this.matchingScreenClose.emit({type:'close',message:''});
  }

  //Populate array of selected price or Obligation
  onRowSelect(value: any, mode) {
    if (mode == 'price') {
      this.selectedPriceRows = value;
    } else {
      this.selectedObligationRows = value;
    }
  }

  //to set total on the basis of Price or  Obligation
  onTotalChange(value: any, mode) {
    if (mode === 'price') {
      this.priceTotal = this.commonService.formatNumberWithoutComma(value,this.quantityRoundOff);
    } else {
      this.obligationTotal = this.commonService.formatNumberWithoutComma(value,this.quantityRoundOff);
    }
  }

  refreshTab() {
    this.priceTotal = 0;
    this.obligationTotal = 0;
    this.selectedPriceRows = [];
    this.selectedObligationRows = [];
    this.fetchData(this.tradeId);
  }


  //Toast Message
  showToast(message: string = 'Price Allocated Successfully', severity: string = 'info') {
    this.messageService.add({
      severity: severity,
      summary: message
    });
  }

  //Calculate the height of the table
  calculateHeight() {
    let offsetTop = this.table.el.nativeElement['offsetTop'];
    let offsetHeight = this.table.el.nativeElement['offsetParent']['offsetHeight'];
    this.priceTableHeight = (offsetHeight - offsetTop - 22) + 'px';
    if (this.divEl !== undefined && this.divEl !== null) {
      this.calculateGridHeight(this.divEl);
    }
  }

  calculateGridHeight(divElement: HTMLDivElement) {
    this.gridHeight = 350 + 'px';
    this.divEl = divElement;
  }

  getClassForHeading(index) {
    return index > 0 ? 'headerNormal' : 'noPipe';
  }

  calculateLeft() {
    this.noDataBoxLeft = ((window['visualViewport'].width - 500) / 2) + 'px';
  }

  fetchData(tradeId: any) {
    let _this = this;
    this.noDataLabel = "Loading ...";
    this.isRefreshing = true;
    this.commonService.getJSONByURL(environment.base_url + '/api/advance-pricing/v1/get-all-price-lines-by-obligation-id?obligationId=' + tradeId).subscribe(next => {
      _this.priceValue = next;
      _this.isRefreshing = false;
    })
    this.commonService.getJSONByURL(environment.base_url + '/api/advance-pricing/v1/get-all-obligations-for-allocation-by-obligation-id?obligationId=' + tradeId).subscribe((next:any)=>{
      _this.obligationValue = next;
      _this.isRefreshing = false;
    } )
    _this.noDataLabel = 'No Data Available.';
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tradeId']) {
      this.fetchData(changes['tradeId'].currentValue);
    }
  }

  onError(event: HttpErrorResponse) {
    this.showToast(this.commonService.getHttpErrorMessage(event, 'Price Allocation'), 'error');
  }

  OnLoadDone(value: any) {
    this.matchingScreenClose.emit({message:'Price Allocated Successfully',type:'success'});
  }

  resetData() {
    this.reset = true;
    this.resetObligation = true;
    this.priceTotal = 0;
    this.obligationTotal = 0;
    this.selectedPriceRows = [];
    this.selectedObligationRows = [];
    this.priceValue = [];
    this.obligationValue = [];
    this.fetchData(this.tradeId);
  }

  allocatePrice() {
    let _this = this;
    this.isLoading = true;
    let priceLineMatchDTO = [];
    this.selectedPriceRows.forEach(function (value) {
      priceLineMatchDTO.push({
        priceLineId: value['priceLineId'],
        quantity: value['matchQuantity']
      });
    });
    console.log(this.selectedObligationRows[0])
    let allocatePriceObj = {
      obligationId: this.selectedObligationRows[0].plannedObligationId,
      matchQuantity: this.obligationTotal,
      latestQuantity: this.selectedObligationRows[0]['actualizedQuantity'] !== null && this.selectedObligationRows[0]['actualizedQuantity'] !== undefined && this.selectedObligationRows[0]['actualizedQuantity'] !==0?this.selectedObligationRows[0]['actualizedQuantity']:this.selectedObligationRows[0]['plannedQuantity'],
      priceLineMatchDTO : priceLineMatchDTO
    };
    _this.commonService.post(environment.base_url + '/api/allocation/v1/can-allocate-price',allocatePriceObj).subscribe((next:any) => {
      let warningInstance = next['statusType'] === 'warning';
      if(warningInstance) {
        let message = next['status'];
        this.confirmationService.confirm({
          message: message,
          accept: () => {
            allocatePriceObj['allocatedExceedingQuantity'] = true;
            _this.continueAllocation(allocatePriceObj);
          }, reject: () => {
            _this.isLoading = false;
          }
        });
      } else {
        _this.continueAllocation(allocatePriceObj);
      }
    },(error) => {
      _this.showToast(this.commonService.getHttpErrorMessage(error));
      _this.isLoading = false;
    })
  }

  private continueAllocation(allocatePriceObj:any) {
    let _this = this;
    this.commonService.post(environment.base_url + '/api/allocation/v1/allocate-price-lines',allocatePriceObj).subscribe((next) => {
      _this.OnLoadDone(next);
      _this.isLoading = false;
    },(error) => {
      _this.onError(error);
      _this.isLoading = false;
    })
  }
}
