<div *ngIf="!matchingScreenOpen">
<style type="text/css">
</style>
<div class="sectionCardTop">
  <div class="breadcrumbPane">
    <ctrm-breadcrumb [collapsabel]="false"
                     [items]="breadCrumbService.getBreadCrumb()"
                     [showIcon]="false"></ctrm-breadcrumb>
  </div>
  <div class="actionPane">
    <div class="screenDetails">
      <img [src]="'../../../assets/images/svg/priceFixing.svg'"/>
      <p>Pricing</p>
    </div>
    <sta-filter
            [columnListObject]="tradeCols"
            [pTable]="priceTable"
            [columns]="columns"
            [columnNameFix]="fixColumnNames"
            (onFilterApply)="onApplyFilter($event)"
            [moduleName]="'Pricing'"></sta-filter>
    <div *ngIf="tabValue == 'planned'" class="rightInfo">
      Total {{priceData.length}} Planned Obligation
    </div>
  </div>
</div>
<!--Price table-->
<div>
  <div class="planningPanel">
    <div class="toolbar">
      <div>
        <ctrm-button (onClick)="priceRefresh()" [disabled]="isRefreshing"
                     icon="{{isRefreshing?'fas fa-redo fa-spin':'fas fa-redo'}}" label="Refresh"></ctrm-button>

        <div class="float-right">


          <ctrm-button  (click)="onClickExport()"
                       icon="fas fa-file-export" label="Export"></ctrm-button>
          <ctrm-button [disabled]="this.selectedPlans.length === 0 || this.selectedPlans.length > 1" icon="fas fa-print"
                       label="Print"></ctrm-button>
        </div>
      </div>
    </div>

    <!--    Trade Details in Price Fixation-->
    <div class="tableClass">
      <p-table #priceTable (inViewportAction)="calculateHeight(priceTable)"
               (onRowExpand)="openPriceRow($event)" [(selection)]="selectedPlans"
               [autoLayout]="true"
               (onRowSelect)="onRowSelectionChange($event)"
               (onRowUnselect)="onRowSelectionChange($event)"
               [columns]="tradeCols"
               [expandedRowKeys]="expandedRows"
               [style.minHeight]="priceData.length>0?priceTableHeight:''"
               [style]="{width: '100%',minHeight:priceTableHeight, maxHeight: priceTableHeight}"
               [value]="priceData"
               class="style-4 table-responsive" dataKey="obligationId"
               inViewport
               selectionMode="multiple">

        <ng-template let-columns pTemplate="header">
          <tr class="sticky-top" style="background-color: #D9DBDE;">
            <th class="checkBoxCol stickLeftHeader" style="width: 4em">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th *ngFor="let col of columns;index as i" [id]="'header'+i"
                [ngStyle]="getStyle(col,i,'1001', 'header' + i)"
                [style]="{zIndex:1000}"
                class="otherColumn stk"
                style="background-color: #D9DBDE;height: 50px">

              <div style="background-color: #D9DBDE; padding: 3px;">
                <p [ngClass]="getClassForHeading(i)">{{col.header}}</p>
              </div>
            </th>
          </tr>
        </ng-template>

        <ng-template let-columns="columns" let-expanded="expanded" let-ri="rowIndex" let-rowData pTemplate="body">
          <tr>
            <td class="plannedGridChkBox checkBoxCol stickLeftBody">
              <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
            </td>
            <td *ngFor="let col of columns; index as i" [id]="'body' + i + '_' +  ri"
                [ngStyle]="getStyle(col,i,'389', 'body' + i + '_' +  ri)"
                class="customRow">
              <p
                      *ngIf="col.field !== 'matchType' && col.field !== 'priceLineType' && col.field !== 'status' && col.field !== 'obligationId'"
                      class="plannedGrid">
                {{commonService.getColValue(rowData, col)}}
              </p>
              <p *ngIf="col.field === 'matchType'" class="matchAlgoStyle">
                {{commonService.getColValue(rowData, col)}}
              </p>
              <div *ngIf="col.field === 'obligationId'" style="display: flex">
                <p style="margin:0;margin-left: 5px">
                  <a [pRowToggler]="rowData" href="#">
                    <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" class="togglerIcon"></i>
                  </a>
                </p>
                <p (click)="onUniqueClick(rowData)" class="plannedGrid primaryKey"
                   style="padding-top: 2px">{{commonService.getRowValue(rowData, col)}}</p>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template let-columns="columns" let-rowData pTemplate="rowexpansion">
          <tr>
            <td [colSpan]="columns.length + 1">
              <div class="planningInfoContainer">
                <app-planning-info (onClickDelete)="onRowDelete($event)"
                                   (onSelectedRows)="onSelectChilds($event,rowData)"
                                   (onUnselectedRows)="onunselect($event,rowData)"
                                   [columns]="priceCols"
                                   [throwUnselectSeperate]="true"
                                   [primaryKeyClickable]="false"
                                   [selectedRows]="currentSelectedRow === rowData['index']? rowData['selectedChild']: []"
                                   [showChkBox]="true" [showDeleteIcon]="false"
                                   [value]="rowData['pricedChilds']">
                </app-planning-info>

              </div>

            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr *ngIf="(priceData === undefined ||priceData === null || priceData.length === 0)">
            <td [colSpan]="tradeCols.length + 2">
              <div class="placeHolder">
                <div (inViewportAction)="calculateLeft()" [style]="{left:noDataBoxLeft}" class="noDataBox" inViewport>
                  <img [src]="commonService.getIconPath('emptyStateIcon')" class="iconClass"/>
                  <div class="noPlans">{{loadingMessage}}</div>
                  <div *ngIf="!isRefreshing">
                    <button (click)="goTo()" class="planScreenBtns" style="margin-top: 0px !important;">Go to Trade
                    </button>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <p-paginator *ngIf="getPagination()" [rows]="20" [totalRecords]="totalElements" (onPageChange)="onPageChange($event)"></p-paginator>
    </div>
  </div>
</div>

<!--side bar for price fixation-->
<p-sidebar [(visible)]="priceDisplay" [baseZIndex]="25" [modal]="false" [showCloseIcon]="false"
           [styleClass]="'bottomPane'" position="bottom">
  <div class="bottomSidebarContainer">
    <div class="leftSection">
      <i (click)="onFormCancel()" class="material-icons closable">clear</i>
    </div>
    <div class="totalContainer">
      <p class="tradeTotal">{{selectedPlans.length}}</p>
      <p class="total">Trade Selected</p>
    </div>

    <div class="rightSection">
      <button (click)="onFormCancel()" class="cancelBtn">Cancel</button>
      <button *ngIf="!isChildSelected" (click)="openPriceDetails()" class="priceBtn" [disabled]="!addPricePolicy" >Add Price Fixation</button>
      <button *ngIf="!isChildSelected" (click)="openAllocationScreen()" class="priceBtn" [disabled]="allocatePriceDisabled">Allocate Price</button>
      <button (click)="deleteAllocation()" class="priceBtn" [disabled]="deAllocatePriceDisabled">Deallocate Price</button>
      <button [disabled]="deleteall || !deletePolicy" (click)="onDeleteAll()" class="priceBtn">Delete Price</button>
    </div>
  </div>
</p-sidebar>

<!--dialog box for fixing the price-->
<p-dialog (onHide)="onFormCancel()"
          [(visible)]="dialogDisplay"
          [closeOnEscape]="false"
          [draggable]="false"
          [maximizable]="false" [modal]="true" [showHeader]="false"
          [style]="{zIndex:1000,width: '90%',height: 'auto',maxHeight:'95%', padding:'0px', background:'#ffffff'}">
  <!--  <div>-->
  <div class="dialogHeading">
    <div>
      <p class="dialogLabel">Pricing</p>
    </div>
    <div class="formCloseIcon">
      <i (click)="onFormCancel()" class="material-icons closable" style="float: right;">clear</i>
    </div>
  </div>
  <!--  Manual Price Heading-->
  <div style="display: inline-flex">
    <p class="dialogSecondLabel">Manual Pricing</p>
    <div style="padding-top: 10px; cursor: pointer">
      <p-inputSwitch (onChange)="switchPrice($event)" pTooltip="Future Implementation" tooltipPosition="bottom"
                     tooltipZIndex="9999"[(ngModel)]="displayFuture" disabled></p-inputSwitch>
    </div>
    <p class="dialogSecondLabel" style="padding-left: 10px">Future Price Fixing</p>
  </div>

    <div style="display: inline-flex">
        <p class="dialogSecondLabel">Manual Allocation</p>
        <div style="padding-top: 10px; cursor: pointer">
            <p-inputSwitch (onChange)="autoAllocation($event)" [disabled]="!canChangeAutoAllocation" [(ngModel)]="autoAllocate" ></p-inputSwitch>
        </div>
        <p class="dialogSecondLabel" style="padding-left: 10px">Auto Allocation</p>
    </div>

  <div style="width: 100%; padding-left: 20px; padding-right: 20px; display: inline-flex">
    <!--      Trade Id -->
    <div class="quantityBox">
      <div class="quantityAreaHeader">
        <div style="display: inline-flex">
          Obligation Id :
        </div>
        <div style="display: inline-flex">
          <p class="bodyContent">{{this.obligationId}}</p>
        </div>
      </div>
    </div>
    <!--      Commodity -->
    <div class="quantityBox">
      <div class="quantityAreaHeader">
        <div style="display: inline-flex">
          Commodity :
        </div>
        <div style="display: inline-flex">
          <p class="bodyContent">{{this.commodity}}</p>
        </div>
      </div>
    </div>
    <!--      Total Quantity  -->
    <div class="quantityBox">
      <div class="quantityAreaHeader">
        <div style="display: inline-flex">
          Total Quantity :
        </div>
        <div style="display: inline-flex">
          <p class="bodyContent">{{commonService.formatNumberWithoutComma(this.totalQuantity,quantityRoundOff)}}</p>
          <p class="bodyHeader">{{this.uomName}}</p>
        </div>
      </div>
    </div>
    <!--      Price Quantity  -->
    <div class="quantityBox">
      <div class="quantityAreaHeader">
        <div style="display: inline-flex">
          Price Quantity :
        </div>
        <div style="display: inline-flex">
          <p class="bodyContent">{{commonService.formatNumberWithoutComma(this.pricedQuantity,quantityRoundOff)}}</p>
          <p class="bodyHeader">{{this.uomName}}</p>
        </div>
      </div>
    </div>
    <!--      Unprice Quantity  -->
    <div class="quantityBox">
      <div class="quantityAreaHeader">
        <div style="display: inline-flex">
          Unprice Quantity :
        </div>
        <div style="display: inline-flex">
          <p class="bodyContent">{{commonService.formatNumberWithoutComma(this.unpricedQuantity,quantityRoundOff)}}</p>
          <p class="bodyHeader">{{this.uomName}}</p>
        </div>
      </div>
    </div>
    <!--      Average Price  -->
    <div class="quantityBox">
      <div class="averageAreaHeading">
        <div style="display: inline-flex">
          Average Price
        </div>
        <div style="display: inline-flex">
          <p class="bodyContent">{{commonService.formatNumberWithoutComma(this.averagePrice,priceRoundOff)}}</p>
          <p class="bodyHeader">{{this.currencyType}}/{{this.uomName}}</p>
        </div>
      </div>
    </div>
  </div>


  <!--  price Adding-->
  <div class="priceAdding" [formGroup]="formControlService.getFormGroup()">
    <div class="fieldBox">
      <p class="fieldName">Pricing Date</p>
      <div class="inputbox">
        <input class="form-control form-control-sm"
               bsDatepicker
               autocomplete="off"
               placeholder="Select Date" type="text"
               [bsConfig]="bsConfig"
               [minDate]="minPriceDate"
               formControlName = "pricingDate">
      </div>
    </div>
    <div class="fieldBox">
      <p class="fieldName">Quantity</p>
      <div class="inputbox">
        <input (focusout)="updateRounding('quantityMT',quantityRoundOff)" formControlName="quantityMT"
               class="form-control form-control-sm" type="number">
      </div>
    </div>
    <div class="fieldBox">
      <p class="fieldName">Basis</p>
      <div class="inputbox">
        <input (focusout)="updateRounding('basis')" formControlName="basis" class="form-control form-control-sm"
               type="number" disabled>
      </div>
    </div>
    <div class="fieldBox">
      <p class="fieldName">Base Price</p>
      <div class="inputbox">
        <input (focusout)="updateRounding('basePrice')" formControlName="basePrice"
               class="form-control form-control-sm" type="number">
      </div>
    </div>
    <div class="fieldBox">
      <p class="fieldName">Freight</p>
      <div class="inputbox">
        <input (focusout)="updateRounding('freight')" formControlName="freight"
               class="form-control form-control-sm" type="number">
      </div>
    </div>
    <div class="fieldBox">
      <p class="fieldName">Insurance</p>
      <div class="inputbox">
        <input (focusout)="updateRounding('insurance')" formControlName="insurance"
               class="form-control form-control-sm" type="number">
      </div>
    </div>
    <div class="fieldBox">
      <p class="fieldName">Commission</p>
      <div class="inputbox">
        <input (focusout)="updateRounding('commission')" formControlName="commission"
               class="form-control form-control-sm" type="number">
      </div>
    </div>
    <div class="fieldBox">
      <p class="fieldName">Premium</p>
      <div class="inputbox">
        <input (focusout)="updateRounding('premium')" formControlName="premium"
               class="form-control form-control-sm" type="number">
      </div>
    </div>
    <div class="fieldBox">
      <p class="fieldName">Rollover</p>
      <div class="inputbox">
        <input (focusout)="updateRounding('rollover')" formControlName="rollover"
               class="form-control form-control-sm" type="number">
      </div>
    </div>
    <div class="fieldBox">
      <p class="fieldName">Others</p>
      <div class="inputbox">
        <input [placeholder]="0.00" (focusout)="updateRounding('others')" formControlName="others"
               class="form-control form-control-sm" type="number">
      </div>
    </div>
    <div class="fieldBox">
      <p class="fieldName">Trade Price ({{this.currencyType}}/{{this.uomName}})</p>
      <div class="inputbox">
        <input formControlName="fixedPrice" class="form-control form-control-sm" disabled type="number">
      </div>
    </div>
  </div>
  <p-footer>
    <div style="display: inline-flex">
      <button (click)="onDialogCancel($event)" class="cancelBtn">Cancel</button>
      <xceler-progress-button
              [buttonIdleTitle]="'Save Price Line'"
              [buttonLoadingTitle]="'Save Price Line'"
              [buttonStyle]="{margin:'0px',minHeight:'35px',padding:'0px',paddingLeft:'10px',paddingRight:'10px'}"
              (onLoadDone)="onPriceLineSave($event)"
              [callApiObjectFunction]="savePriceLineApiFunc"
              (onError)="onPriceLineSaveFail($event)"
              [disabled]="disableSave || !formControlService.getFormGroup().valid">
      </xceler-progress-button>
    </div>
  </p-footer>

</p-dialog>
<!--Toaster Message-->

<p-confirmDialog [baseZIndex]="25" [styleClass]="'confirmationDialog'" header="Confirmation"
                 icon="pi pi-exclamation-triangle"></p-confirmDialog>

<app-trade-info (onClose)="onClose($event)" *ngIf="tradedisplay" [detailsOf]="'trade'" [display]="true"
                [rowData]="rowData" [tradeId]="obligationId"></app-trade-info>

</div>

<app-allocation-matching *ngIf="matchingScreenOpen" (matchingScreenClose)="closeAllocationScreen($event)"
                         [priceUom]="priceUom" [quantityUom]="quantityUom"
                         [tradeId]="tradeId"></app-allocation-matching>

<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body">
</p-toast>
