<div class="tickerPanelHeading">
    <div class="d-inline-flex" style="width:inherit">
        <p class="tickerPanelHeading1">Ticker</p>
        <div class="autocomplete-box">
            <input [(ngModel)]="query" placeholder="Search" type="text">
            <mat-icon style="color:#cbcbcb">search</mat-icon>
        </div>
    </div>
<!--    <div class="d-inline-flex">-->
<!--        <p-checkbox></p-checkbox>-->
<!--        <p class="flex-grow-1 font-weight-bold text-nowrap" style="font-size: 14px;">Market Data</p>-->
<!--    </div>-->
</div>
<ng-container *ngTemplateOutlet="noFutureTicker"></ng-container>
<div class="scrollit style-4">
    <table>
        <tr style="background-color:#ddd2d4c4">
            <th>Symbol</th>
            <th>Actions</th>
        </tr>
        <tr *ngFor="let tickerValue of tickerValue | search: query : 'tickerName';index as i"
            (click)="onSelectRow(tickerValue)">
            <td>
                <div style="display: inline-flex" class="actionBody">
                    <!--                    <i (click)="onClickInfo(tickerValue['tickerName'])" [pTooltip]="'Info'"-->
                    <!--                       class="fas fa-info-circle"-->
                    <!--                       style="padding: 5px;cursor: pointer;"></i>-->
                    <p>{{tickerValue['tickerName']}}</p>
                </div>
            </td>
            <td>
                <div class="actionBody d-inline-flex">
                    <i style="cursor: pointer;" class="fas fa-external-link-alt flex-fill"></i>
                    <i style="cursor: pointer;" class="flex-fill"
                       [ngClass]="tickerValue['bookmark']?'fas fa-star text-warning':'far fa-star'"></i>
                    <i [pTooltip]="'Delete'" class="far fa-times-circle iconClassDelete flex-fill"
                       (click)="onClickDelete(i)"></i>
                </div>
            </td>

        </tr>
    </table>
</div>


<ng-template #noFutureTicker>
    <div *ngIf="(tickerValue === undefined ||tickerValue === null || tickerValue.length === 0)" style="height: 100%">
        <div class="placeHolder">
            <div class="noDataBox align-self-center">
                <div  *ngIf="!isLoading" style="height: 100%">
                    <i style="font-size: 20px;" class="far fa-check-square"></i>
                    <p style="font-size: 15px; text-align: center;">No Future Index Added</p>
                </div>
                <div class="noDataBox align-self-center">
                    <div *ngIf="isLoading" style="height: 100%">
                        <div class="loader"></div>
                        <p style="font-size: 15px; text-align: center;">Loading Ticker Value!!!</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body">
</p-toast>


