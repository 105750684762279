import { Component, OnInit } from '@angular/core';
import { MasterService } from '../services/MasterService';

@Component({
  selector: 'masters-sidenav',
  templateUrl: './masters-sidenav.component.html',
  styleUrls: ['./masters-sidenav.component.css']
})
export class MastersSidenavComponent implements OnInit {

  list=[];
  selectedMenuHeader:any = '';
  expanded:boolean = false;
  pin:boolean = false;

  constructor(private masterCommonService:MasterService) { }

  ngOnInit(): void {
    this.selectedMenuHeader = this.masterCommonService.system_sidebar_selected;
    this.expanded = this.masterCommonService.system_sidebar_expanded;
    this.list.push({name:"GLOBAL INDICATOR",icon:"../assets/images/svg/GI.svg",link:"/#/globalIndicators",class:"nav-link"})
    this.list.push({name:"TRANSACTION ID REFERENCE",icon:"../assets/images/svg/tranid.svg",link:"/#/transactionReference",class:"nav-link"})
    this.list.push({name:"DOCUMENT TEMPLATE MASTER",icon:"../assets/images/svg/docutemp.svg",link:"/#/documentTemplateMaster",class:"nav-link"})
    this.list.push({name:"USER MASTER",icon:"../assets/images/svg/userMaster.svg",link:"/#/usermaster",class:"nav-link"})
    this.list.push({name:"ROLE MASTER",icon:"../assets/images/svg/roleMaster.svg",link:"/#/rolemaster",class:"nav-link"})
    this.list.push({name:"API CONFIGURATION",icon:"../assets/images/svg/apiconfig.svg",link:"/#/apiconfig",class:"nav-link"})
    this.list.push({name:"DICTIONARY",icon:"../assets/images/svg/dictionary.svg",link:"/#/dictionary",class:"nav-link disabled"})
    this.list.push({name:"APPROVAL CONFIGURATION",icon:"../assets/images/svg/Approval.svg",link:"/#/approvalconfiguration",class:"nav-link "})
    this.list.push({name:"NOTIFICATION SETTINGS",icon:"../assets/images/svg/bell.svg",link:"/#/notificationsettings",class:"nav-link"})
    this.list.push({name:"TENANT CONFIGURATION",icon:"../assets/images/svg/Tenant.svg",link:"/#/tenantconfig",class:"nav-link"})
  }

  getheader(str:string){
    let val:string = str.split(' ')[0];
    if(str.length > val.length) {
      val += '...';
    }
    return val;
  }


  onclick(item: any) {
    if(this.selectedMenuHeader === item['name']) {
      this.selectedMenuHeader = '';
    } else {
      this.selectedMenuHeader = item['name'];

    }
    this.masterCommonService.system_sidebar_selected = this.selectedMenuHeader;
  }


    toggle() {
        this.expanded = !this.expanded;
        this.masterCommonService.system_sidebar_expanded = this.expanded;
    }

  togglepin() {
    this.pin = !this.pin;
  }

  inlinediv() {
    if(this.expanded) {
      return "openpinclass";
    } else {
      return "closepinclass";
    }
  }

  mainsidebardiv() {
    if(!this.pin) {
      document.getElementById('pinwidth').style.marginLeft = '70px';
      return "unpindesign";
    } else {
      document.getElementById('pinwidth').style.marginLeft = $('#sidebardiv').width()+ 10 + 'px';
      return "pindesign";
    }
  }
}
