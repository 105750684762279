<div class="ui-g p-0 m-0" class="borderStyle">
    <div class="biHeader">
        <div class="biHeaderIcon"><img alt="" class="biMainIcon"
                                       src="../../../../assets/images/svg/TransportBookingManagement/ship_icon.svg">
        </div>
        <div class="biHeading">{{getValue('shippingMode')}}</div>
        <div class="biStatus">
            <p>Allocation Status</p>
            <span *ngIf="!bookingValue.allocated"><img  class="biStatusIcon" alt="" src="../../../../assets/icons/deliveryPending.svg"></span>
            <span *ngIf="bookingValue.allocated"><img  class="biStatusIcon" alt="" src="../../../../assets/icons/deliveryStarted.svg"></span>
        </div>

        <div class="biIconsDiv">
            <i class="fas fa-trash" (click)="deleteBookingDetails()"></i>
        </div>
    </div>

    <div class="brkLine"></div>

    <div class="infoWrapper" (click)="onItemCardClick()">
        <div class="row">
            <div class="col-4 infoContent">
                <p class="infoHead">Booking/Voyage Id</p>
                <p class="infoText">{{getValue('voyageNo')}}</p>
            </div>
            <div class="col-4 infoContent">
                <p class="infoHead">Vessel Name</p>
                <p class="infoText">{{getValue('vesselName')}}</p>
            </div>
            <div class="col-4 infoContent">
                <p class="infoHead">Freight Booking Date</p>
                <p class="infoText">{{getValue('bookingDate', true)}}</p>
            </div>
            <div class="col-4 infoContent">
                <p class="infoHead">Load Port</p>
                <p class="infoText">{{getValue('loadPort')}}</p>
            </div>
            <div class="col-4 infoContent">
                <p class="infoHead">Unload Port</p>
                <p class="infoText">{{getValue('unloadPort')}}</p>
            </div>
            <div class="col-4 infoContent">
                <p class="infoHead">Commodity</p>
                <p class="infoText">{{getValue('commodity')}}</p>
            </div>
        </div>
    </div>
</div>
