import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {CommonService} from "../../services/common.service";
import {KeyValue} from "../../grid/key-value.model";
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed'

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CtrmSuggestionInputComponent),
  multi: true
};

@Component({
  selector: 'app-ctrm-suggestion-input',
  templateUrl: './ctrm-suggestion-input.component.html',
  styleUrls: ['./ctrm-suggestion-input.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class CtrmSuggestionInputComponent implements OnInit, ControlValueAccessor, OnChanges ,AfterViewInit{

  @Input('value') val: any;
  @Input('name') name: string = '';
  @Input() type: any;
  @Input() placeholder: any;
  @Input() sgClass: any;
  @Input() sclass: any;
  @Input() disabled: boolean = false;
  @Input() suggestions: any[] = [];
  @Input() displaySuggestion: boolean = false;
  @Input() startCharacter: any = '@';
  @Input() breakCharacter: any = ',';
  @Output() onValueChange = new EventEmitter();
  lastChar: string = '';
  blockLists: any[] = [];
  lastPos: number = 0;
  currentFocus: number = 0;
  onDropDown: boolean = false;

  constructor(private changeDetectorRef:ChangeDetectorRef,private cs: CommonService) {
  }

  get value() {
    return this.val;
  }

  set value(val: string) {
    this.val = val;
    this.onChange(val);
    if ((val.charAt(val.length - 1) !== this.startCharacter || val.charAt(val.length - 1) === this.breakCharacter || val.length === 0) && this.lastChar !== this.startCharacter) {
      this.displaySuggestion = false;
    } else if (val.charAt(val.length - 1) === this.startCharacter) {
      this.currentFocus = 0;
      this.displaySuggestion = true;
    }
    this.onChange(val);
    this.onTouched();
  }

  onTouched: any = () => {
  };

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  onChange(val) {
    this.val = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['suggestions']) {
      if (this.suggestions !== undefined) {
        if (this.suggestions.length === 0) {
          this.displaySuggestion = false;
        } else {
          if (this.value !== undefined && this.value.length > 0 && this.value.charAt(this.value.length - 1) === this.startCharacter) {
            this.currentFocus = 0;
            this.displaySuggestion = true;
          }
        }
      } else {
        this.displaySuggestion = false;
      }
    }

  }

  setDisabledState(isDisabled: boolean): void {

  }

  /**
   * this will update the input value in field
   * @param suggestion
   */
  update(suggestion: any) {
    let selected: KeyValue = suggestion;
    this.blockLists.push(selected);
    if (this.value !== undefined) {
      this.value = [this.value.slice(0, this.lastPos), selected.getValue(), this.value.slice(this.lastPos)].join('');
      this.displaySuggestion = false;
    }
  }

  getCaretPos(element) {
    if (element.selectionStart || element.selectionStart === 0) {
      this.lastPos = element.selectionStart + 1;
    }
  }

  onKeyPressed(value: KeyboardEvent, inputbox: HTMLInputElement) {
    let ch = value.key;
    this.lastChar = ch;
    if (ch === this.startCharacter) {
      this.getCaretPos(inputbox);
      this.currentFocus = 0;
      this.displaySuggestion = true;
    } else if (ch === 'Enter') {
      let selected: KeyValue = this.suggestions[this.currentFocus];
      this.blockLists.push(selected);
      this.value = [this.value.slice(0, this.lastPos), selected.getValue(), this.value.slice(this.lastPos)].join('');
      this.onValueChange.emit(this.value);
    } else if (ch === 'ArrowDown' && this.suggestions.length > 0) {
      if (this.displaySuggestion) {
        this.currentFocus++;
        if (this.currentFocus >= this.suggestions.length) {
          this.currentFocus = 0;
        }
        this.scroll(this.currentFocus, false);
      }
    } else if (ch === 'ArrowUp' && this.suggestions.length > 0) {
      if (this.displaySuggestion) {
        this.currentFocus--;
        if (this.currentFocus < 0) {
          this.currentFocus = this.suggestions.length - 1;
        }
        this.scroll(this.currentFocus, true);
      }
    } else if (ch === 'Backspace') {
      if (this.value.length === 0) {
        this.displaySuggestion = false;
      }
    }
  }

  scroll(number, downup) {
    smoothScrollIntoView(document.getElementById(number + "option"));
  }

  removeBlock(value: any, i: number) {

  }

  getChipValue(chip: any) {
  }
}
