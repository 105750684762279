import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Tcolumn} from '../../grid/tcolumn.model';
import {pconfig} from './pconfig.model';

@Component({
  selector: 'ctrm-pivot-table',
  templateUrl: './ctrm-pivot-table.component.html',
  styleUrls: ['./ctrm-pivot-table.component.css']
})
export class CtrmPivotTableComponent implements OnInit, OnChanges {
  @Input() columns: Tcolumn[] = [];
  @Input() data: any[] = [];
  @Input() config: pconfig;
  @Input() grid: any;
  expandCount: number = 0;
  categoryList: any[] = [];
  categoryTitle: string = '';
  expandall: boolean = true;
  isallexpanded: boolean = false;
  columnHeaderList: any[] = [];
  list: any[] = [];
  showSet: boolean = false;
  private subtables: number = 10;

  constructor() {
  }

  ngOnInit() {
    this.updateCategoryList();
    this.createFields();
  }

  onExpand() {
    this.expandCount++;
    if (this.expandCount === this.subtables) {
      this.isallexpanded = true;
    }
  }

  onCollapse() {
    this.expandCount--;
    this.isallexpanded = false;
  }

  expandAll() {
    if (this.isallexpanded === false) {
      this.expandCount = this.subtables;
      this.expandall = true;
      this.isallexpanded = true;
    }
  }

  collpaseAll() {
    this.expandCount = 0;
    this.expandall = false;
    this.isallexpanded = false;
  }

  updateCategoryName() {
    let _this = this;
    this.columns.forEach(function (column) {
      if (_this.config.categorizedBy === column.getField()) {
        _this.categoryTitle = column.getHeader();
      }
    })
  }

  getFieldType(field) {
    let type = 'T';
    this.columns.forEach(function (column) {
      if (column.getField() === field) {
        type = column.getColumnType();
      }
    });
    return type;
  }

  getFieldListOfValue(field) {
    let value;
    this.columns.forEach(function (column) {
      if (column.getField() === field) {
        value = column.getListOfValues();
      }
    });
    return value;
  }

  updateCategoryList() {
    this.categoryList = [];
    let _this = this;
    _this.updateCategoryName();
    this.data.forEach(function (row) {
      if (!_this.categoryList.includes(row[_this.config.categorizedBy])) {
        _this.categoryList.push(row[_this.config.categorizedBy]);
      }
    })
  }

  getDataForCategory(category) {
    let _this = this;
    let templist = [];
    this.data.forEach(function (row) {
      if (row[_this.config.categorizedBy] === category) {
        templist.push(row);
      }
    });
    return templist;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['categorizedBy']) {
      this.config.categorizedBy = changes['categorizedBy'].currentValue;
      this.updateCategoryList();
    }
    if (changes['data']) {
      this.data = changes['data'].currentValue;
      this.updateCategoryList();
    }
  }

  getValue(category: any) {
    if (this.getFieldType(this.config.categorizedBy) === 'L') {
      this.getFieldListOfValue(this.config.categorizedBy).forEach(function (pair) {
        if (pair['value'] === category) {
          category = pair['label'];
        }
      })
    }
    return category;
  }

  private createFields() {
    let _this = this;
    this.columns.forEach(function (column) {
      let obj = {label: column.getHeader(), value: column.getField()};
      _this.list.push(obj);
    })
  }
}
