import {Validators} from '@angular/forms';
import {Action} from './actions.model';
import {KeyValue} from "./key-value.model";

export class Tcolumn {
  constructor(
    public field: string,
    private header: string,
    private columnType: string = 'T for text| N for number text| P for password | L for dropdown | D for Datepicker | F for Filepicker | C for Checkbox | R for Radiobuttons | LB for Lable | TP for Timepicker | TA for textarea | TB for Table | B for Toggle Button | ML for Multiselect List',
    private placeholder: number,
    private editable: boolean = true,
    private listvalues ?: KeyValue[],
    private visible: boolean = true,
    private defaultValue: any = '',
    private visibilityArea: string = 'B',
    private validators: Validators = [],
    private valueType: string = 'text',
    private colSpan: number = 1,
    private minDate?: any,
    private maxDate?: any,
    private tableMetadata: Tcolumn[] = [],
    private actionToDo?: Action,
    private extra: any = {style: {width: '5%'}},
    private isUnique: boolean = false,
    private _disableFunction?:Function,
    private _showPlaceholderHint:boolean = true,
    private _addIfSelectedNotInList:boolean = false,
    private _metaFunctionForFilter?:Function,
    private _showFilter:boolean = false,
    private _roundOffTo:number = null
  ) {
  }


  get roundOffTo(): number {
    return this._roundOffTo;
  }

  set roundOffTo(value: number) {
    this._roundOffTo = value;
  }

  get showFilter(): boolean {
    return this._showFilter;
  }

  set showFilter(value: boolean) {
    this._showFilter = value;
  }

  public getField() {
    return this.field;
  }

  public setField(newvalue) {
    this.field = newvalue;
  }

  public getColumnType() {
    return this.columnType;
  }

  public setColumnType(newvalue) {
    this.columnType = newvalue;
  }

  public getHeader() {
    return this.header;
  }

  public setHeader(newvalue) {
    this.header = newvalue;
  }

  public getListOfValues() {
    return this.listvalues;
  }

  public getPlaceholder() {
    return this.placeholder;
  }

  public getDefaultValue() {
    return this.defaultValue;
  }

  public getVisibilityArea() {
    return this.visibilityArea === undefined ? 'B' : this.visibilityArea;
  }

  public getValidators() {
    return this.validators;
  }

  public isEditable() {
    return this.editable;
  }

  public setEditable(editable) {
    this.editable = editable;
  }


  public isVisible() {
    return this.visible;
  }

  public getColSpan() {
    return this.colSpan;
  }

  public getTableMetadata() {
    return this.tableMetadata;
  }

  public getValueType() {
    return this.valueType;
  }

  public getMinDate() {
    return this.minDate;
  }

  public getMaxDate() {
    return this.maxDate;
  }

  public setDefaultValue(newvalue) {
    if (typeof (this.defaultValue) === "object") {
      this.defaultValue['_defaultVal'] = newvalue;
    } else {
      this.defaultValue = newvalue;
    }

  }

  public setVisibility(newvalue) {
    this.visible = newvalue;
  }

  public setListofValues(listValuesNew) {
    this.listvalues = listValuesNew;
  }

  public getActionToDo() {
    return this.actionToDo;
  }

  public getFromExtra(key: any,defValue?:any) {
    if (this.extra !== undefined && this.extra[key] !== undefined) {
      let val = this.extra[key];
      if(val === undefined || val === null){
        return defValue;
      }
      return val;
    } else {
      return defValue ?? {};
    }
  }

  public setToExtra(key: any, value: any) {
    this.extra[key] = value;
  }

  public getExtra() {
    return this.extra;
  }

  public setExtra(extra: any) {
    this.extra = extra;
  }

  public setMinDate(minDate: any) {
    this.minDate = minDate;
  }

  setValidators(validator: any) {
    this.validators = validator;
  }

  setMaxDate(maxDate: any) {
    this.maxDate = maxDate;
  }

  getIsUnique() {
    return this.isUnique;
  }

  setIsUnique(isUnique) {
    this.isUnique = isUnique;
  }


  get disableFunction(): Function {
    return this._disableFunction;
  }

  set disableFunction(value: Function) {
    this._disableFunction = value;
  }


  get showPlaceholderHint(): boolean {
    return this._showPlaceholderHint;
  }

  set showPlaceholderHint(value: boolean) {
    this._showPlaceholderHint = value;
  }


  get addIfSelectedNotInList(): boolean {
    return this._addIfSelectedNotInList;
  }

  set addIfSelectedNotInList(value: boolean) {
    this._addIfSelectedNotInList = value;
  }


  get metaFunctionForFilter(): Function {
    return this._metaFunctionForFilter;
  }

  set metaFunctionForFilter(value: Function) {
    this._metaFunctionForFilter = value;
  }
}
