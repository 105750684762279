import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {CommonService} from '../../services/common.service';
import {ConfirmationService, MessageService, SelectItem, Table} from 'primeng';
import {BreadcrumbService} from '../../Components/ctrm-breadcrumb/breadcrumb.service';
import {environment} from '../../../environments/environment';
import {fxPlanningMessages} from '../../services/common/messages';
import {MasterService} from '../../masters/services/MasterService';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-fx-planning',
  templateUrl: './fx-planning.component.html',
  styleUrls: ['./fx-planning.component.css'],
  providers: [ConfirmationService]
})

export class FxPlanningComponent implements OnInit {
  globalFilterValue: string = '';
  tabValue: string;
  planData: any[] = [];
  tabOptions: SelectItem[] = [];
  fxTradeTotal: number = 0;
  tradeObligationTotal: number = 0;
  fxCols: any[];
  tradeObligationCols: any[];
  fxplanCols: any[];
  fxPlannedCols: any[];
  expandedRows = {};

  selectedfxRows: any[] = [];
  selectedTradeObligationRows: any[] = [];
  fxTradeValue: any[];
  tradeObligationValue: any[];
  selectedPlans: any[] = [];
  selectedAlgo: string = '';
  algoBtnList: any[] = [];
  matchDisplay: boolean = false;
  errorMessage: any[] = [];
  warningMessage:any[]=[];
  planningHardStop: any[] = [];
  fxTradeHeight: string = '';
  display: boolean = false;
  fxdisplay: boolean = false;
  fxTradeId: any = '';
  balanceFunc: any;
  balanceRightFunc: any;
  oblRow: any;
  tradeId: any = '';
  selectedChilds: any[] = [];
  rowData: any = {};
  @ViewChild('fxTrade') fxTrade: ElementRef;
  @ViewChild('fxdetailTable', {static: true}) fxdetailTable: Table;
  tradeInfoPos: any = 'left';
  plannedDisplay: boolean = false;
  plannedObligationId: any = '';
  private lastExpandedRow: any = 0;
  noDataBoxLeft: any = '';
  loadingMessage: string = 'No Data Available';
  isRefreshing: boolean = false;
  loadDataList: any[] = [];
  disableFxTrade: boolean = false;
  btnDisable: boolean = false;
  currentPageNumber: number = 0;
  currentPageNumberFx: number = 0;
  currentPageNumberTrade: number = 0;
  pageSizeFx: number = 0;
  totalElementsFx: number = 0;
  totalPagesFx: number = 0;
  pageSizeTrade: number = 0;
  totalElementsTrade: number = 0;
  totalPagesTrade: number = 0;
  resetFx: boolean = false;
  resetTrade: boolean = false;
  pageSize: number = 0;
  totalElements: number = 0;
  totalPages: number = 0;
  filterListFx: any[] = [];
  filterListTrade: any[] = [];
  filterforFxData: any;
  filterTradeData: any;
  onRefreshFX: boolean = false;
  onRefreshTrade: boolean = false;

  constructor(public commonService: CommonService, private masterCommonService: MasterService,
              private confirmationService: ConfirmationService,
              private messageService: MessageService,
              public breadCrumbService: BreadcrumbService,
              private route: ActivatedRoute,) {
  }


  showConfirmationDialog() {
    let _this = this;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete?',
      accept: () => {
      }
    });
  }

  ngOnInit(): void {
    this.commonService.getLoadData('commodity',['data'],[[{key:'name', value:'fx_pair_trade_ind'}]]).subscribe( (next:any) => {
      this.loadDataList = next;
    });
    this.breadCrumbService.makeBreadcrumbTo(this.commonService.OPERATION_MAP_KEY, '/fxPlanning');
    this.tabOptions = [
      {label: 'Unplanned FX Trade', value: 'unplanned'},
      {label: 'Planned FX Trade', value: 'planned'}];
    this.tabValue = 'unplanned';
    this.balanceFunc = function (data) {
      return data['unplannedNotionalValue']
    };

    this.balanceRightFunc = function (data) {
      return data['obligationPlannedNotionalValue'];
    };
    this.getFxTradeData();
    this.getTradeObligtionData();
    this.fxTradeValue = [];
    this.tradeObligationValue = [];

    this.fxCols = [
      {field: 'fxTradeId', header: 'FX Trade Id',showFilter: true},
      {field: 'tradeTransactionType', header: 'Trade Type',showFilter: true},
      {field: 'fxTradeType', header: 'FX Trade Type'},
      {field: 'company', header: 'Company',showFilter: true},
      {field: 'profitcenter', header: 'Profit Center',showFilter: true},
      {field: 'counterparty', header: 'Counterparty',showFilter: true},
      {field: 'externalRef', header: 'External Ref'},
      {field: 'fxPair', header: 'FX Pair'},
      {field: 'rate', header: 'Rate'},
      {field: 'buyAmountCurrency', header: 'Buy Currency',showFilter: true},
      {field: 'sellAmountCurrency', header: 'Sell Currency',showFilter: true},
      // {field: 'settlementCurrency', header: 'Settlement Currency',showFilter: true},
      {field: 'notionalAmount', header: 'Notional Amount'},
      {field: 'settlementDate', header: 'Settlement Date', type: 'date',showFilter: true},
      {field: 'CB', header: 'CB'},
      {field: 'matchAmount', header: 'Match Amt', editable: true},
      {field: 'balanceAmount', header: 'Balance Amt'}

    ];

    let viewQuantityFunction = function (value: any) {
      return value['tradeQuantity'] + ' ' + value['quantityUOM'];
    };

    let viewPriceFunction = function (value: any) {
      return value['tradePrice'] + ' ' + value['tradeSettlementCurrency'];
    };

    this.tradeObligationCols = [
      {field: 'balanceAmount', header: 'Balance Amt'},
      {field: 'matchAmount', header: 'Match Amt', editable: true},
      {field: 'CB', header: 'CB'},
      {field: 'tradeDateTime', header: 'Trade Date', type: 'date',showFilter: true},
      {field: 'tradeQuantity', header: 'Quantity', valueFunction: viewQuantityFunction},
      {field: 'commodity', header: 'Commodity',showFilter: true},
      {field: 'counterparty', header: 'Counterparty',showFilter: true},
      {field: 'deliveryStartDate', header: 'Delivery Start Date', type: 'date',showFilter: true},
      {field: 'deliveryEndDate', header: 'Delivery End Date', type: 'date',showFilter: true},
      {field: 'profitcenter', header: 'Profit Center',showFilter: true},
      {field: 'incoterm', header: 'Incoterm',showFilter: true},
      {field: 'quantityUOM', header: 'UOM'},
      {field: 'tradePrice', header: 'Total Value', valueFunction: viewPriceFunction},
      {field: 'tradeSettlementCurrency', header: 'Settlement Currency'},
      {field: 'priceType', header: 'Price Type',showFilter: true},
      {field: 'tradeTransactionType', header: 'Trade Type',showFilter: true},
      {field: 'plannedObligationId', header: 'Planned Obligation Id', primaryKey: true,showFilter: true}
    ];


    this.fxplanCols = [
      {field: 'fxTradePlanId', header: 'FX Planning Id'},
      {field: 'tradeTransactionType', header: 'Trade Type'},
      {field: 'fxTradeType', header: 'FX Trade Type'},
      {field: 'fxPair', header: 'FX Pair',},
      {field: 'settlementCurrency', header: 'Settlement Currency'}
    ];

    this.fxPlannedCols = [
      {field: 'obligationFxAllocationId', header: 'Obligation FX Alloction Id'},
      {field: 'tradeId', header: 'Trade Id'},
      {field: 'plannedObligationId', header: 'Planned Obligation Id'},
      {field: 'fxTradeId', header: 'Fx Trade Id'},
      {field: 'rate', header: 'Rate'},
      {field: 'obligationAllocatedNotionalValue', header: 'Allocated Notional Value'}
    ]

    this.route.queryParams.subscribe(params => {
      if(params !== undefined && params !== null && Object.keys(params).length > 0) {
        if(params['mode'] === 'allocated') {
          this.tabValue = 'planned';
          this.getPlans();
        }
      }
    });

    setTimeout(() => {
      var scrollContainer = $('.style-4.table-responsive.background.right');
      var maxScrollLeft = $(scrollContainer)[0].scrollWidth - $(scrollContainer).width();
      $(".style-4.table-responsive.background.right").scrollLeft(maxScrollLeft);
    }, 500)
  }

  public getPlans() {
    let _this = this;
    this.planData = [];
    this.lastExpandedRow = 0;
    this.commonService.getJSONByURL(environment.base_url + '/api/fxhedging/v1/getfxplannedlist?tenantId='
        + this.commonService.getFromStorage('tenantId') + '&page=' + this.currentPageNumber + '&size=20').subscribe((next: any[]) => {
      _this.planData = next['content'];
      _this.pageSize = next['pageable']['pageSize'];
      _this.totalElements = next['totalElements'];
      _this.totalPages = next['totalPages'];
      this.calculateHeight(this.fxdetailTable);
    });
  }



  onExpandRow(value: any) {
    this.commonService.getJSONByURL(environment.base_url + '/api/fxhedging/v1/getplannedfxallocationlist?fxPlanId='
        + value['data']['fxTradePlanId'] + '&tenantId=' + this.commonService.getFromStorage('tenantId')).subscribe((next) => {
      value['data']['obligationFxAllocationList'] = next;
    });
  }

  //to set total on the basis of fx Trade or Trade Obligation
  onTotalChange(value: any, mode) {
    if (mode === 'fxTrade') {
      this.fxTradeTotal = value;
    } else {
      this.tradeObligationTotal = value;
    }
  }


  //Populate array of selected FXTrade or TradeObligation array
  onRowSelect(value: any, mode) {
    if (mode == 'fxTrade') {
      this.selectedfxRows = value;
    } else {
      this.selectedTradeObligationRows = value;
    }
  }

  //Func to delete the Plan
  onRowDelete(rowData) {
    //calling Delete REST
    let _this = this;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this FX Plan ?',
      accept: () => {
        this.commonService.post(environment.base_url + '/api/fxhedging/v1/deletefxobligationplan?tenantId=' + this.commonService.getFromStorage('tenantId'), rowData).subscribe(next => {
          _this.plannedDisplay = false;
          _this.refreshTabs();
          _this.showToast('FX Trade Plan has been successfully deleted !');
        });
      }
    });
  }

  //Get the Date From TimeStamp
  getDate(rowDatum: any) {
    return rowDatum.substring(0, 10);
  }

  //Go back to Dashboard
  goBack() {
    this.commonService.navigateTo('/dashboard');
  }

  getClassForHeading(index) {
    return index > 0 ? 'headerNormal' : 'noPipe';
  }

  onUniqueClick(value: any) {
    this.display = true;
  }

  // Trade Display Side Panel
  onClose(value: any) {
    this.tradeId = '';
    this.display = false;
  }

  //Fx Display Side Panel
  onFxClose(value: any) {
    this.fxTradeId = '';
    this.fxdisplay = false;
  }

  openMatchDialog() {
    this.matchDisplay = !this.matchDisplay;
  }

  getAlgoClass(btn) {

    if (btn['isDisable'] === false) {
      if (this.selectedAlgo == btn['title']) {
        return 'algoBtnSelected';
      } else {
        return 'algoBtn';
      }
    } else {
      return 'algoBtnDisabled';
    }

  }

  closeMatchDialog() {
    this.errorMessage = [];
    this.selectedAlgo = '';
    this.matchDisplay = false;
  }


  getFxTradeType(value) {
    return value;
  }

  onChangePageFx(value: any) {
    this.currentPageNumberFx = value['page'];
    this.getFilteredFXTrade(this.filterforFxData, this.currentPageNumberFx);

  }

  onChangePageTrade(value: any) {
    this.currentPageNumberTrade = value['page'];
    this.getFilteredTradeObligation(this.filterTradeData, this.currentPageNumberTrade);
  }

  private canMatch(fxRows:any[],planedRows:any[]) {
    let canMatch:boolean = true;
    if(fxRows === null || fxRows === undefined || fxRows.length === 0 || planedRows === null || planedRows === undefined || planedRows.length === 0 ) {
      canMatch = false;
      return canMatch;
    }
    let _this = this;
    let fxrow = fxRows[0];
    this.warningMessage = [];
    this.errorMessage =[];
    planedRows.forEach(row => {
      if ((fxrow['matchAmount'] > fxrow['unplannedNotionalValue']) || row['matchAmount'] > row['obligationPlannedNotionalValue']) {
        _this.errorMessage.push(fxPlanningMessages.negative_match);
      }
      if(fxrow['company'] !== row['company']) {
        _this.errorMessage.push("Company ("+row['company']+") Not Matched for Planned Obligation Id :"+row['plannedObligationId']);
      }
      if(fxrow['profitcenter'] !== row['profitcenter']) {
        _this.errorMessage.push("Profitcenter ("+row['profitcenter']+") Not Matched for Planned Obligation Id :"+row['plannedObligationId']);
      }
      if(row['tradeTransactionType'] === 'BUY') {
        if(fxrow['buyAmountCurrency'] !== row['tradeSettlementCurrency']){
          _this.errorMessage.push(fxPlanningMessages.currency_miss_match + 'for Planned ObligationId: ' + row['plannedObligationId']);
        }
      } else {
        if(fxrow['sellAmountCurrency'] !== row['tradeSettlementCurrency']){
          _this.errorMessage.push(fxPlanningMessages.currency_miss_match + 'for Planned ObligationId: ' +row['plannedObligationId']);
        }
      }
    });
    if(this.errorMessage !== null && this.errorMessage !== undefined && this.errorMessage.length > 0) {
      canMatch = false;
    }
    if(this.warningMessage !== null && this.warningMessage !== undefined && this.warningMessage.length > 0) {
      canMatch = true;
    }
    return canMatch;
  }

  checkMatch() {
    let postData: any[] = [];
    this.errorMessage = [];
    this.warningMessage=[];
    let _this = this;
    if (this.fxTradeTotal === this.tradeObligationTotal) {
      if (this.fxTradeTotal === 0 && this.tradeObligationTotal === 0) {
        this.errorMessage.push(fxPlanningMessages.quantity_zero);
      } else {
        if(this.canMatch(this.selectedfxRows,this.selectedTradeObligationRows)) {
          if(this.warningMessage !== null && this.warningMessage !== undefined && this.warningMessage.length > 0) {
            this.openMatchDialog();
          } else {
            let data:any = this.selectedfxRows[0];
            this.selectedTradeObligationRows.forEach(tradeData => {
              let obj = {};
              obj['fxTradeId'] = data['fxTradeId'];
              obj['matchAmount'] = data['matchAmount'];
              obj['obligationMatchAmount'] = tradeData['matchAmount'];
              obj['plannedObligationId'] = tradeData['plannedObligationId'];
              obj['balanceAmount'] = tradeData['obligationPlannedNotionalValue'];
              obj['totalValue'] = tradeData['price'];
              postData.push(obj);
            });
            this.commonService.post(environment.base_url + '/api/fxhedging/v1/link?tenantId=' + this.commonService.getFromStorage('tenantId'), postData).subscribe((next) => {
              _this.showToast('FX Trade has been successfully linked to the Physical Trade Obligations');
              _this.refreshTabs();
              _this.fxTradeTotal = 0;
              _this.tradeObligationTotal = 0;
            });
          }
        } else {
          this.openMatchDialog();
        }

      }
    } else {
      this.errorMessage.push(fxPlanningMessages.matchQty_notEqual);
      this.openMatchDialog();
    }
  }

  refreshTabs() {
    this.getPlans();
    this.getFxTradeData();
    this.getTradeObligtionData();
  }

  //Toast Message
  showToast(msg, severity: string = 'success') {
    this.messageService.add({
      severity: 'info',
      summary: msg
    });
  }

  continuePlanning() {
    this.closeMatchDialog();
  }

  getContPlanValidation() {
    if (this.errorMessage.length != 0 || (this.fxTradeTotal == 0 && this.tradeObligationTotal == 0) || (this.fxTradeTotal !== this.tradeObligationTotal)) {
      return true;
    }
  }

  getFilterValue($event) {
    this.globalFilterValue = event.target['value'];
    this.fxdetailTable.filterGlobal(event.target['value'], 'contains');
  }

  planRefresh() {
    this.getPlans();
    this.fxdetailTable.expandedRowKeys = {};
    this.planData = [];

  }

//get Fx Trade
  private getFxTradeData(resetValues:boolean = true) {
    let _this = this;
    this.resetFx = false
    this.fxTradeValue = [];
    if(resetValues) {
      this.fxTradeTotal = 0;
      this.resetFx = true
      this.totalElementsFx = 0;
      this.totalPagesFx = 0;
    }
    this.commonService.getJSONByURL(environment.base_url + '/api/fxhedging/v1/getunplannedfxtrades?tenantId='
        + this.commonService.getFromStorage('tenantId')+'&page='+this.currentPageNumberFx+'&size=20'
    ).subscribe((next: any[]) => {

      _this.fxTradeValue = next['content'];
      _this.pageSizeFx = next['pageable']['pageSize'];
      _this.totalElementsFx = next['totalElements'];
      _this.totalPagesFx = next['totalPages'];
    });
    this.getFilterValuesForFx();
    this.getFilterValuesForTrade();
  }

  getFilterValuesForFx() {
    this.commonService.getJSONByURL(environment.base_url + '/api/fxhedging/v1/getfxforunplanned?tenantId='
        +this.commonService.getFromStorage('tenantId')+'&transactionType=BUY').subscribe((next: any[]) => {
      this.filterListFx = next;
    });
  }
  getFilterValuesForTrade() {
    this.commonService.getJSONByURL(environment.base_url + '/api/fxhedging/v1/gettradeforunplanned?tenantId='
        +this.commonService.getFromStorage('tenantId')+'&transactionType=BUY').subscribe((next: any[]) => {
      this.filterListTrade = next;
    });
    setTimeout(() => {
      var scrollContainer = $('.style-4.table-responsive.background.right');
      var maxScrollLeft = $(scrollContainer)[0].scrollWidth - $(scrollContainer).width();
      $(".style-4.table-responsive.background.right").scrollLeft(maxScrollLeft);
    }, 500)
  }

  //get Trade Obligations
  private getTradeObligtionData(resetValues: boolean = true) {
    let _this = this;
    this.tradeObligationValue = [];
    this.resetTrade = false;
    if (resetValues) {
      this.tradeObligationTotal = 0;
      this.resetTrade = true;
      this.totalElementsTrade = 0;
      this.totalPagesTrade = 0;
    }
    this.commonService.getJSONByURL(environment.base_url + '/api/fxhedging/v1/gettradeobligations?tenantId='
        + this.commonService.getFromStorage('tenantId') + '&page=' + this.currentPageNumberTrade + '&size=20').subscribe((next: any[]) => {
      _this.tradeObligationValue = next['content'];
      _this.pageSizeTrade = next['pageable']['pageSize'];
      _this.totalElementsTrade = next['totalElements'];
      _this.totalPagesTrade = next['totalPages'];
    });
  }

  @HostListener("window:resize", [])
  public onResize() {
    this.calculateHeight(this.fxdetailTable);
  }

  calculateHeight(fxdetailTable: Table) {
    if (fxdetailTable !== undefined && fxdetailTable !== null) {
      let offsetTop = fxdetailTable.el.nativeElement['offsetTop'];
      let offsetHeight = fxdetailTable.el.nativeElement['offsetParent']['offsetHeight'];
      this.fxTradeHeight = offsetHeight - (offsetTop + 30) + 'px';
    }
  }

  ngAfterViewInit() {
    this.calculateHeight(this.fxdetailTable);
  }

  switchTab(tabName) {
    this.tabValue = tabName;
  }

  openTradeInfo(value: any, position = 'left') {
    let _this = this;
    this.tradeInfoPos = position;
    this.plannedObligationId = value['plannedObligationId'];
    this.commonService.getFromUrl(environment.base_url + '/api/plannedObligation/v1/getplannedobligationsbyplannedobligationid?' +
        'tenantId=' + this.commonService.getTenantId() +
        '&plannedObligationId=' + value['plannedObligationId']).subscribe((next:any) =>{
      _this.tradeId = next['tradeId'];
      _this.oblRow = next;
      _this.display = true;
    });
  }

  openFxInfo(value: any) {
    this.fxTradeId = value['fxTradeId'];
    this.rowData = value;
    this.fxdisplay = true;
  }

  onClickClickable(value: any) {
    let _this = this;
    let data = value['rowData'];
    let fieldName = value['fieldName'];
    if (fieldName === 'tradeId') {
      this.openTradeInfo({plannedObligationId: data['plannedObligationId']}, 'right');
    } else {
      this.commonService.getFromUrl(environment.base_url + '/api/fxTrade/v1/getallfxtradesbyfxtradeid?tenantId='+this.commonService.getTenantId()+'&fxTradeId=' + data['fxTradeId']).subscribe((next:any) =>{
        _this.openFxInfo(next);
      });
    }
  }

  onSelectRows(value: any[], ri) {
    if (this.lastExpandedRow !== ri) {
      this.planData[this.lastExpandedRow]['selectedChilds'] = [];
      this.lastExpandedRow = ri;
    }
  }

  deletePlans() {
    let _this = this;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete ?',
      accept: () => {
        this.commonService.post(environment.base_url + '/api/fxhedging/v1/deleteobligationfxallocation?tenantId=' + this.commonService.getFromStorage('tenantId'), _this.selectedChilds).subscribe(next => {
          _this.plannedDisplay = false;
          _this.selectedChilds = [];
          _this.refreshTabs();
          _this.fxdetailTable.expandedRowKeys = {};
          _this.showToast('Obligation FX Allocations has been successfully deleted !');
        });
      }
    });
  }

  onChange(value: any) {
    this.plannedDisplay = false;
    this.selectedChilds = [];
    if(value['value'] === 'unplanned') {
      this.getPlans();
    }
  }

  calculateLeft() {
    this.noDataBoxLeft = ((window['visualViewport'].width - 500) / 2) + 'px';
  }

  onTabChange(tabData: any) {
    let tabName = tabData['option']['value'];
    if (tabName === 'unplanned') {
      this.getFxTradeData();
      this.getTradeObligtionData(true);
    } else {
      this.getPlans();
      this.planData = [];
    }
  }

  onFXPlanRowSelect() {
    this.plannedDisplay = false;
  if (this.selectedPlans.length != 0) {
      this.plannedDisplay = true;
    }
  }

  fxObligatiobRefresh() {
    this.fxTradeValue = [];
    this.tradeObligationValue = [];
    this.errorMessage = [];
    this.getFxTradeData();
    this.getTradeObligtionData(true);
    this.onRefreshFX = true;
    this.onRefreshTrade = true;
  }

  disableButton(isDisable) {
    this.btnDisable = isDisable
  }

  getPagination() {
    return this.totalElements > this.pageSize;
  }


  onPageChange(value: any) {
    this.currentPageNumber = value['page'];
    this.getPlans();
  }

  filterForFxTrade(payload) {
    this.filterforFxData = payload;
    this.fxTradeTotal = 0;
    this.fxTradeValue = [];
    this.getFilteredFXTrade(payload);
  }

  getFilteredFXTrade(payload: any, pageNumber: number = 0) {
    let _this = this;
    if(payload == undefined) {
      payload = {};
    }
    this.commonService.post(environment.base_url + '/api/fxhedging/v1/getfxtradedatabyfilter?tenantId='
        + this.commonService.getFromStorage('tenantId') + '&page=' + pageNumber + '&size=20', payload).subscribe((next: any) => {
      _this.fxTradeValue = next['content'];
      _this.pageSizeFx = next['pageable']['pageSize'];
      _this.totalElementsFx = next['totalElements'];
      _this.totalPagesFx = next['totalPages'];
    });
  }

  filterForTrade(payload) {
    this.filterTradeData = payload;
    this.tradeObligationTotal = 0;
    this.tradeObligationValue = [];
    this.getFilteredTradeObligation(payload);
  }

  getFilteredTradeObligation(payload, pageNumber: number = 0) {
    let _this = this;
    if(payload == undefined){
      payload = {};
    }
    this.commonService.post(environment.base_url + '/api/fxhedging/v1/gettradeobligationdatabyfilter?tenantId='
        + this.commonService.getFromStorage('tenantId') + '&page=' + pageNumber + '&size=20', payload).subscribe((next: any) => {
      _this.tradeObligationValue = next['content'];
      _this.pageSizeTrade = next['pageable']['pageSize'];
      _this.totalElementsTrade = next['totalElements'];
      _this.totalPagesTrade = next['totalPages'];
    });
  }

  clearFilter(mode) {
    if (mode === 'FX') {
      this.fxTradeValue = [];
      this.filterforFxData = {};
      this.getFxTradeData();
    } else {
      this.tradeObligationValue = [];
      this.filterTradeData = {};
      this.getTradeObligtionData(true);
    }
  }


}


