import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Tcolumn} from "../../grid/tcolumn.model";
import {Grid} from "../../grid/grid.model";
import {MasterService} from "../../masters/services/MasterService";
import {CommonService} from "../../services/common.service";
import {ActivatedRoute} from "@angular/router";
import {MessageService, SortEvent, SortMeta} from "primeng";
import {messages} from "../../services/common/messages";
import {environment} from "../../../environments/environment";
import {AbstractControl, FormControl, Validators} from "@angular/forms";
import {KeyValue} from "../../grid/key-value.model";
import {HttpErrorResponse} from "@angular/common/http";
import {defaultValue} from "../../grid/defaultValue.model";

@Component({
  selector: 'app-physical-commodity-price',
  templateUrl: './physical-commodity-price.component.html',
  styleUrls: ['./physical-commodity-price.component.css']
})
export class PhysicalCommodityPriceComponent implements OnInit,AfterViewInit {
  columns: Map<string, Tcolumn>;
  grid: Grid;
  refreshGrid: boolean = false;
  @ViewChild('physical') eod: ElementRef;
  toolbarConfig: any = {
    add: true,
    edit: false,
    copy: false,
    refresh: true,
    export: true,
    exportAll: true,
    import: true,
    audit: true,
    print: true,
    prefrences: true
  };
  //NODATA BOX
  noDataBoxLeft: any;
  @Input() showFetching: boolean = false;
  isError: boolean = false;
  loadMessage: string = 'Loading ..';
  @Input() noDataLabel: string = 'No Data Available';
  //IMPORT
  displayImport: boolean = false;
  importTableName: string = 'Physical/BasisCommodityPrice';
  parentKeyValue: any;
  sortFunction: Function;
  //SIDEBAR
  display: boolean = false;
  quantityRoundOff:number=3;
  priceRoundOff:number=2;
  physicalHeight: string = '';
  physicalIndexList: any[] = [];
  physicalIndex: string = '';
  date: string = '';
  shipmentMonthList: any[] = [];
  shipmentMonth: string = '';
  //GRID
  gridDisplay: boolean = true;
  gridColumns: Tcolumn[] = [];
  values: any[] = [];
  refreshFormGroup: boolean = false;
  addedValues: any[] = [];

  @Input() showTooltipOnValidationError: boolean = false;
  @Input() formControlAIO: FormControl;
  @Input() field: Tcolumn;
  @Input() listOfValues: KeyValue[] = [];
  @Input() readOnly: boolean = false;
  list: any;
  refreshAddNewRow: boolean = false;
  newRows: any[] = [];
  file: File;
  private importingFromForm: boolean = false;

  constructor(private masterCommonService: MasterService, public commonService: CommonService, private router: ActivatedRoute, private messageService: MessageService,private changeDetectorRef:ChangeDetectorRef) {
// this.commonService.getMyRoles();
    this.commonService.getFromStorage('policy');
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    let _this = this;
    let tenantConfig=JSON.parse(this.commonService.getFromStorage("tenantConfig"));
    if(tenantConfig && tenantConfig.roundingFormat){
      _this.priceRoundOff=tenantConfig.roundingFormat.priceRounding;
      _this.quantityRoundOff= tenantConfig.roundingFormat.quantityRounding;
    }
    else{
      _this.priceRoundOff=2;
      _this.quantityRoundOff=3;
    }
    this.getColumns();
    this.grid = new Grid(this.columns, true, true, true, true, '', '/api/physicalcommodityprice/v1/getallcommoditypricebytenantid?tenantId='+this.commonService.getFromStorage('tenantId'), '', 'Physical/Basis Commodity Price', undefined, undefined);
    this.gridColumns = [...this.masterCommonService.getGridField(this.grid)];
    this.grid.setUpdateUrl(environment.base_url + '/api/physicalcommodityprice/v1/update?tenantId='+this.commonService.getFromStorage('tenantId'));
    this.grid.hrefUrl = environment.base_url +'/api/physicalcommodityprice/v1/getcommoditypricebytenantidanduuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
    this.grid.iconName = 'priceFixing';
    this.grid.openDefaultInputForm = false;
    this.grid.setGridConfiguration(true, true, false, false);
    this.grid.setToolbarConfig(true,false,false,false);
    this.sortFunction = function (event:SortEvent) {
      event.data.sort((data1, data2) => {
        let field = event.multiSortMeta[0].field;
        let order = event.multiSortMeta[0].order;
        let value1 = data1[field];
        let value2 = data2[field];
        if(field === 'shipmentMonth') {
          value1 = new Date(value1);
          value2 = new Date(value2);
        }
        let result = null;
        if (value1 == null && value2 != null) {
          result = -1;
        } else if (value1 != null && value2 == null) {
          result = 1;
        } else if (value1 == null && value2 == null){
          result = 0;
        } else if (typeof value1 === 'string' && typeof value2 === 'string') {
          result = value1.localeCompare(value2);
        } else {
          result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (order * result);
      });
    };
  }

  getColumns() {
    let _this=this;
    this.columns = new Map();
    let PriceFunction =function(value: any) {
      return _this.commonService.formatNumberWithoutComma(value,_this.priceRoundOff);
    }
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('name', new Tcolumn('name', 'Price Index', 'LB', 1, true, undefined, true, '', 'B', undefined, undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('shipmentMonth', new Tcolumn('shipmentMonth', 'Shipment Month', 'T', 2, true, undefined, true, '', 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('physicalCommStartDate', new Tcolumn('physicalCommStartDate', 'Start Date', 'D', 3, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('physicalCommEndDate', new Tcolumn('physicalCommEndDate', 'End Date', 'D', 4, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('physicalCommPriceDate', new Tcolumn('physicalCommPriceDate', 'Price Date', 'D', 5, true, undefined, true, '', 'B', undefined, undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('settlementPrice', new Tcolumn('settlementPrice', 'Settlement Price', 'N', 6, true, undefined, true, new defaultValue('', undefined, undefined, undefined, false, false, false, PriceFunction), 'B'));
    this.columns.set('pxOpen', new Tcolumn('pxOpen', 'Open', 'N', 7, true, undefined, true, new defaultValue('', undefined, undefined, undefined, false, false, false, PriceFunction), 'B'));
    this.columns.set('pxHigh', new Tcolumn('pxHigh', 'High', 'N', 8, true, undefined, true, new defaultValue('', undefined, undefined, undefined, false, false, false, PriceFunction), 'B'));
    this.columns.set('pxLow', new Tcolumn('pxLow', 'Low', 'N', 9, true, undefined, true, new defaultValue('', undefined, undefined, undefined, false, false, false, PriceFunction), 'B'));
  }

//close the sidebar
  closePhysical() {
    this.display = false;
    this.gridDisplay = false;
  }

  //Add the grid
  onClickAddPhysical(value: any) {
    this.physicalIndex = '';
    this.date = '';
    this.shipmentMonth = '';
    this.refreshGrid = false;
    this.values = [];
    this.addedValues = [];
    this.display = true;
    this.gridDisplay = false;
    this.showPhysicalList()
  }

  //load the physical Index price
  showPhysicalList() {
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url + '/api/priceIndex/v1/getIndexList?indexTypeList=Physical,Basis&tenantId='+this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
      _this.physicalIndexList = _this.commonService.addListOTT(next);
    });
  }

  onChangePhysicalIndex(value: any) {
    this.getShipmentMonthList(value);
  }

//get shilpment Month
  getShipmentMonthList(physicalindex) {
    let _this = this;
    _this.shipmentMonthList = [];
    _this.shipmentMonth = '';
    this.commonService.getJSONByURL(environment.base_url + '/api/priceIndex/v1/getShipmentMonthList?name=' + physicalindex+'&tenantId='+this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
      if(next.length > 0) {
        _this.shipmentMonthList = _this.commonService.addListOTT(next, 'All', 'all');
        _this.shipmentMonth = 'all';
      }
    })
  }

  addPrice() {
    if (this.values === undefined || this.values === null || this.values.length === 0) {
      this.values = [];
    }
    this.gridDisplay = true;
    this.refreshAddNewRow = false;
    this.refreshFormGroup = false;
    let _this = this;
    let physicalpayload = {
      name: this.physicalIndex,
      shipmentMonth: this.shipmentMonth,
      priceDate: new Date(this.date+'UTC')
    };
    this.commonService.getJSONByObject(environment.base_url + '/api/physicalcommodityprice/v1/getdatatemplate?tenantId='+this.commonService.getFromStorage('tenantId'), physicalpayload).subscribe((next: any[]) => {
      _this.newRows = next;
      _this.refreshFormGroup = true;
      _this.refreshAddNewRow = true;
    })

  }

//Save the Physical form
  savePhysical(value: any[], isUpdate: boolean = false) {
    let _this = this;
    value.forEach(function (row:any) {
      row['physicalCommPriceDate'] = _this.commonService.convertDatetoUTC(row['physicalCommPriceDate'],true);
      row['physicalCommStartDate'] = _this.commonService.convertDatetoUTC(row['physicalCommStartDate'],true);
      row['physicalCommEndDate'] = _this.commonService.convertDatetoUTC(row['physicalCommEndDate'],true);
    });
    let physicalpayloadObj = {
      name: this.physicalIndex,
      priceDate: _this.commonService.convertDatetoUTC(this.date),
      shipmentMonth: this.shipmentMonth,
      tenantId: this.commonService.getFromStorage('tenantId'),
      updatedBy: this.commonService.getFromStorage('userName'),
      commodityPriceList: value

    };
    if (!isUpdate) {
      physicalpayloadObj['createdBy'] = this.commonService.getFromStorage('userName');
    }
    this.commonService.post(environment.base_url + '/api/physicalcommodityprice/v1/saveMarketPrice?tenantId='+this.commonService.getFromStorage('tenantId'), physicalpayloadObj).subscribe(next => {
      this.display = false;
      this.refreshGrid = true;
      this.showToast('Physical Price is saved');
    }, (error: HttpErrorResponse) => {
      this.messageService.add({severity: 'error', summary: error.error});
    });
  }

  showToast(msg, severity: string = 'success') {
    this.messageService.add({
      severity: 'info',
      summary: msg
    });
  }

  onRefreshFromGroupComplete() {
    this.refreshFormGroup = false;
  }

  //Import the future Commodity Price
  onImportClick(importingFromForm: boolean = false) {
    this.display = false;
    this.displayImport = true;
    this.importingFromForm = importingFromForm;
    this.parentKeyValue = this.grid.getDataURL().substr(this.grid.getDataURL().lastIndexOf('=') + 1);
  }

  //close import
  closeImport() {
    this.displayImport = false;
    if (this.importingFromForm) {
      this.display = true;
      this.gridDisplay = true;
    }
  }

  importDone(success: boolean) {
    this.displayImport = false;
    if (success) {
      this.messageService.add({
        severity: 'info', summary: messages.updated['summary'],
        detail: messages.importCompleted['message'],
        life: 10000
      });

    }
  }

//update
  onSave(value: any) {
    if (value['mode'] === 'update') {
      this.savePhysical([value['data']], true);
    }
  }

  //nodata box

  calculateLeft() {
    this.noDataBoxLeft = ((window['visualViewport'].width - 500) / 2) + 'px';
  }

  getTooltipMessage(control: AbstractControl, col) {
    return this.showTooltipOnValidationError ? this.commonService.getValidationErrorMessage(control, col.getHeader()) : '';
  }

  getDateFormat() {
    return environment.dateFormat.toUpperCase();
  }

  onNewRowAddComplete() {
    this.refreshAddNewRow = false;
    this.newRows = [];
  }

  onImport(value: any) {
    this.display = true;
    this.displayImport = false;
    this.file = value['file'];
  }

  onImportComplete() {
    this.file = undefined;
  }
}
