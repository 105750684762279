import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControlServiceService} from "../../../services/form-control-service.service";

@Component({
  selector: 'app-tenant-section',
  templateUrl: './tenant-section.component.html',
  styleUrls: ['./tenant-section.component.css']
})
export class TenantSectionComponent implements OnInit, OnChanges {

  @Input() section: any = {};
  editing: boolean = false;
  originalObject: any = {};
  addNewEntryState: boolean = false;
  @Input() entries: any[] = [];
  @Input() finalJson: any = {};
  @Output() onSave = new EventEmitter();
  selectedOption: any = {key: '', value: ''};
  selectedObject: any = {type: 'number'};

  constructor() {
  }

  ngOnInit(): void {
  }

  createEntryInFinalJson() {
    if (this.section.useForFinalJson && !Object.keys(this.finalJson).includes(this.section.key)) {
      let _this = this;
      this.finalJson[this.section.key] = {};
      let props: any[] = this.section.props;
      props.forEach(function(prop) {
        _this.finalJson[_this.section.key][prop.key] = prop.value;
      });
    }
  }

  deletePropery(prop: any, index: number) {
    let props: any[] = this.section['props'];
    if (props != null && props.length > 0) {
      props.splice(index, 1);
    }
  }

  onEdit() {
    this.originalObject = JSON.parse(JSON.stringify(this.section['props']));
    this.editing = !this.editing;
  }

  onSavePressed() {
    this.editing = !this.editing;
    this.createEntryInFinalJson();
    this.onSave.emit(this.finalJson);
  }

  onCancel() {
    this.section['props'] = JSON.parse(JSON.stringify(this.originalObject));
    this.editing = !this.editing;
    this.originalObject = {};
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['finalJson']) {
      this.createEntryInFinalJson();
    }
  }

  closeModal() {
    this.addNewEntryState = false;
    this.selectedOption = {key: '', value: ''};
    this.selectedObject = {type:'number'};
  }

  openForNewEntry() {
    this.addNewEntryState = true;
  }

  addEntry() {
    this.addNewEntryState = false;
    let prop:any = {};
    prop['name'] = this.selectedOption.key;
    prop['value'] = this.selectedOption.value;
    prop['type'] = this.selectedObject.type;
    this.section['props'].push(prop);
    this.selectedOption = {key: '', value: ''};
    this.selectedObject = {type:'number'};
  }

  onPropertyChange(event: any) {
    let val = event.value;
    this.selectedObject = this.entries.find(x => x.value == val);
  }

  onClickButton(prop: any) {
    if(prop.function !== null && prop.function !== undefined) {
      this.runFunction(this.section, prop.function);
    }
  }

  private runFunction(value: any, callback) {
    if (typeof callback === 'function'){
      return callback(value);
    }
  }
}
