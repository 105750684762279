import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {KeyValue} from '../../../grid/key-value.model';
import {CommonService} from '../../../services/common.service';
import {MasterService} from '../../../masters/services/MasterService';
import {environment} from '../../../../environments/environment';
import {HttpErrorResponse} from '@angular/common/http';
import {MessageService} from 'primeng';

@Component({
    selector: 'ticker-dialog-box',
    templateUrl: './ticker-dialog-box.component.html',
    styleUrls: ['./ticker-dialog-box.component.css']
})
export class TickerDialogBoxComponent implements OnInit {

    @Input() display: boolean;
    @Output() onClose = new EventEmitter();
    @Output() onSave = new EventEmitter();
    commodityList: any[] = [];
    commodity: string = '';
    ticker: string = '';
    suggestionlistTicker: any[] = [];
    tickerList: KeyValue[] = [];

    constructor(public commonService: CommonService, private masterCommonService: MasterService,
                private messageService: MessageService) {
    }

    ngOnInit(): void {
        this.getCommodityLoad();
    }


    onCancel() {
        this.onClose.emit();
    }


    //On Click of Confirm Button
    onTickerSave() {
        let url = '';
        let _this = this;
        let futureTickerPayload: {};
        futureTickerPayload = {
            commodityName: this.commodity,
            tickerName: this.ticker,
            updatedBy: this.commonService.getFromStorage('userName'),
            createdBy: this.commonService.getFromStorage('userName'),
            tenantId: this.commonService.getFromStorage('tenantId'),
        };
        url = environment.base_url + '/api/futuremarketdashboard/v1/createfuturetickervalue?tenantId=' + this.commonService.getTenantId();
        this.commonService.post(url, futureTickerPayload).subscribe(next => {
            _this.showToast('Ticker Saved Successfully.');
            _this.display = false;
        }, (error: HttpErrorResponse) => {
            this.messageService.add({severity: 'error', summary: this.commonService.getHttpErrorMessage(error, 'Future Market Dashboard')});
        });
    }

    showToast(msg, severity: string = 'success') {
        this.messageService.add({
            severity: 'info',
            summary: msg
        });
    }

    //Get Commodity Data
    getCommodityLoad() {
        let _this = this;
        _this.commonService.getLoadData('commodity', ['config'], ['commodity']).subscribe((next: any) => {
            _this.commodityList = this.masterCommonService.getListFromArray(next['commodity']);
        });
    }

    //On change of commodity get product code
    searchForTicker(value: any) {
        this.suggestionlistTicker = [];
        let _this = this;
        _this.tickerList.forEach(function(obj: KeyValue) {
            if (obj.getValue().length > 0 && obj.getLabel().toString().includes(value['query'])) {
                _this.suggestionlistTicker.push(obj.getValue());
            }
        });
    }

    onChangeCommodity(value: any) {
        let _this = this;
        _this.commonService.getJSONByURL(environment.base_url + '/api/futuremarketdashboard/v1/gettickerbycommodity?tenantId=' + this.commonService.getTenantId()
            + '&commodity=' + value).subscribe((next: any) => {
            _this.tickerList = this.masterCommonService.getListFromArray(next, false, true);
        });
    }
}
