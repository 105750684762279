import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonService} from '../../../services/common.service';
import {environment} from '../../../../environments/environment';
import {HttpErrorResponse} from '@angular/common/http';
import {ConfirmationService, MessageService} from 'primeng';

@Component({
  selector: 'app-booking-item',
  templateUrl: './booking-item.component.html',
  styleUrls: ['./booking-item.component.css']
})
export class BookingItemComponent implements OnInit {

  @Input() bookingValue: any;
  @Output() onCardClick = new EventEmitter();
  @Output() onDelete = new EventEmitter();

  constructor(private commonService: CommonService) {
  }

  ngOnInit(): void {
  }

  getValue(key: any, isDate: boolean = false) {
    let value = this.bookingValue[key];
    if (value !== undefined && value !== null && value.length > 0) {
      return isDate ? this.commonService.getDateForOutput(value) : value;
    }
    return '-';
  }

  deleteBookingDetails() {
   this.onDelete.emit(this.bookingValue);
  }

  onItemCardClick() {
    this.onCardClick.emit(this.bookingValue);
  }
}
