<app-ctrm-grid-with-tabs *ngIf="loadListData !== undefined && loadListData.length != 0"
                         (selectedRows)="onRowSelectionChange($event)"
                         [breadcrumbMapKey]="commonService.MASTER_MAP_KEY"
                         [refreshGrid]="refreshGrid"
                         [currentRoute]="'/paymentTerm'"
                         [showStandardFilter]="true"
                         [showFilterChips]="false"
                         [grid]="grid">
</app-ctrm-grid-with-tabs>
<xceler-bottom-pane [popupDisplay]="popupDisplay" [rightViewTemplate]="right" (onClose)="onClose()"></xceler-bottom-pane>
<ng-template #right>
    <button (click)="approve()" class="planScreenBtns" style="margin: 0">Approve</button>
</ng-template>
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body">
</p-toast>
