import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MultiSelectWithRadioComponent),
  multi: true
};

@Component({
  selector: 'multi-select-with-radio',
  templateUrl: './multi-select-with-radio.component.html',
  styleUrls: ['./multi-select-with-radio.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class MultiSelectWithRadioComponent implements OnInit,ControlValueAccessor {

  @Input('value') val:string = '';
  @Input() options:any[] =[];
  @Input() disabled:boolean = false;
  defvalue:string = '';
  selected:any[] = [];
  output: { selected:any[] , default:string} = {selected:[],default:''};
  constructor() { }

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  onTouched: any = () => {
  };



  ngOnInit(): void {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChange(val) {
    this.val = val;
  }

  writeValue(obj: any): void {
    if (obj) {
      this.convertIntoInputFormat(obj);
      this.value = obj;
    }
  }

  private convertIntoInputFormat(obj: any) {
    this.output = JSON.parse(obj);
    this.defvalue = this.output.default;
    this.selected = this.output.selected;
  }

  onChangeDefault(event: any) {
    if(!this.selected.includes(event)) {
      this.selected.push(event);
    }
    this.convertIntoOutputFormat();
  }

  convertIntoOutputFormat() {
    this.output.selected = this.selected;
    this.output.default = this.defvalue;
    this.value = JSON.stringify(this.output);
  }
}
