import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Tcolumn} from "../../grid/tcolumn.model";
import {Grid} from "../../grid/grid.model";
import {AbstractControl, FormControl, Validators} from "@angular/forms";
import {KeyValue} from "../../grid/key-value.model";
import {MasterService} from "../../masters/services/MasterService";
import {CommonService} from "../../services/common.service";
import {ActivatedRoute} from "@angular/router";
import {MessageService} from "primeng";
import {environment} from "../../../environments/environment";
import {HttpErrorResponse} from "@angular/common/http";
import {messages} from "../../services/common/messages";
import {defaultValue} from "../../grid/defaultValue.model";
import {ctrmValidators} from '../../services/validators/ctrmValidators';

@Component({
  selector: 'app-fx-commodity-price',
  templateUrl: './fx-commodity-price.component.html',
  styleUrls: ['./fx-commodity-price.component.css']
})
export class FxCommodityPriceComponent implements OnInit {
  columns: Map<string, Tcolumn>;
  grid: Grid;
  refreshGrid: boolean = false;
  @ViewChild('fx') eod: ElementRef;
  //NODATA BOX
  noDataBoxLeft: any;
  @Input() showFetching: boolean = false;
  isError: boolean = false;
  loadMessage: string = 'Loading ..';
  @Input() noDataLabel: string = 'No Data Available';
  //IMPORT
  displayImport: boolean = false;
  importTableName: string = 'FxCommodityPrice';
  parentKeyValue: any;
  //SIDEBAR
  display: boolean = false;
  fxHeight: string = '';
  fxIndexList: any[] = [];
  fxIndex: string = '';
  date: string = '';
  termStructureList: any[] = [];
  termStructure: string = '';
  //GRID
  gridDisplay: boolean = true;
  gridColumns: Tcolumn[] = [];
  values: any[] = [];
  refreshFormGroup: boolean = false;
  addedValues: any[] = [];

  @Input() showTooltipOnValidationError: boolean = false;
  @Input() formControlAIO: FormControl;
  @Input() field: Tcolumn;
  @Input() listOfValues: KeyValue[] = [];
  @Input() readOnly: boolean = false;
  list: any;
  quantityRoundOff:number=3;
  priceRoundOff:number=2;
  refreshAddNewRow: boolean = false;
  newRows: any[] = [];
  file: File;
  private importingFromForm: boolean = false;

  constructor(private masterCommonService: MasterService, public commonService: CommonService, private router: ActivatedRoute, private messageService: MessageService,) {
// this.commonService.getMyRoles();
    this.commonService.getFromStorage('policy');  }

  ngOnInit(): void {
    let tenantConfig=JSON.parse(this.commonService.getFromStorage("tenantConfig"));
    if(tenantConfig && tenantConfig.roundingFormat){
      this.priceRoundOff=tenantConfig.roundingFormat.priceRounding;
      this.quantityRoundOff= tenantConfig.roundingFormat.quantityRounding;
    }
    else{
      this.priceRoundOff=2;
      this.quantityRoundOff=3;
    }
    this.getColumns();
    this.grid = new Grid(this.columns, true, true, true, true, '', '/api/fxirprice/v1/getallcommoditypricebytenantid?tenantId='+this.commonService.getFromStorage('tenantId'), '', 'FX Rate', undefined, undefined);
    this.gridColumns = [...this.masterCommonService.getGridField(this.grid)];
    this.grid.setUpdateUrl(environment.base_url + '/api/fxirprice/v1/update?tenantId='+this.commonService.getFromStorage('tenantId'));
    this.grid.importUrl = environment.base_url + '/api/fxirprice/v1/importfxprice';
    this.grid.hrefUrl = environment.base_url +'/api/fxirprice/v1/getcommoditypricebytenantidanduuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
    this.grid.iconName = 'priceFixing';
    this.grid.openDefaultInputForm = false;
    this.grid.setGridConfiguration(true, true, false, false);
    this.grid.setToolbarConfig(true,false,false,false);
  }

  getColumns() {
    let _this=this;
    this.columns = new Map();
    let PriceFunction =function(value: any) {
      return _this.commonService.formatNumberWithoutComma(value,_this.priceRoundOff);
    }
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('name', new Tcolumn('name', 'Price Index', 'LB', 1, true, undefined, true, '', 'B', undefined, undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('fxIndexPair', new Tcolumn('fxIndexPair', 'FX Index Pair', 'LB', 2, true, undefined, true, '', 'B', undefined, undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('termStructure', new Tcolumn('termStructure', 'Term Structure', 'T', 3, true, undefined, true, '', 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('termStructureDate', new Tcolumn('termStructureDate', 'Term Structure Date', 'D', 4, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('priceDate', new Tcolumn('priceDate', 'Price Date', 'D', 5, true, undefined, true, '', 'B', undefined, undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('settlementPrice', new Tcolumn('settlementPrice', 'Settlement Price', 'N', 6, true, undefined, true, new defaultValue('', undefined, undefined, undefined, false, false, false, PriceFunction), 'B',[Validators.min(0), ctrmValidators.decimalLengthValidator(10, 8)]));
    this.columns.set('bid', new Tcolumn('bid', 'Bid', 'N', 7, true, undefined, true, new defaultValue('', undefined, undefined, undefined, false, false, false, PriceFunction), 'B', []));
    this.columns.set('ask', new Tcolumn('ask', 'Ask', 'N', 8, true, undefined, true, new defaultValue('', undefined, undefined, undefined, false, false, false, PriceFunction), 'B', []));
  }


//close the sidebar
  closefx() {
    this.display = false;
    this.gridDisplay = false;
  }

  //Add the grid
  onClickAddfx(value: any) {
    this.fxIndex = '';
    this.date = '';
    this.termStructure = '';
    this.refreshGrid = false;
    this.values = [];
    this.addedValues = [];
    this.display = true;
    this.gridDisplay = false;
    this.showfxList()
  }

  //load the fx Index price
  showfxList() {
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url + '/api/priceIndex/v1/getIndexList?indexTypeList=Fx&tenantId='+this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
      _this.fxIndexList = _this.commonService.addListOTT(next);
    });
  }

  onChangefxIndex(value: any) {
    this.getTermStructureList(value);
  }

//get shilpment Month
  getTermStructureList(fxindex) {
    let _this = this;
    _this.termStructureList = [];
    _this.termStructure = '';
    this.commonService.getJSONByURL(environment.base_url + '/api/priceIndex/v1/getTermStructureList?name=' + fxindex+"&tenantId="+this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
      if(next.length > 0) {
        _this.termStructureList = _this.commonService.addListOTT(next, 'All', 'all');
        _this.termStructure = 'all';
      }
    })
  }
  // function to take current date
  getCurrentDateTime(date?:any){
    var currentDate = date?new Date( date):new Date()
    var year = currentDate.getFullYear();
    var month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    var day = currentDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}T00:00:00.000`;
  }
  addPrice() {
    if (this.values === undefined || this.values === null || this.values.length === 0) {
      this.values = [];
    }
    this.gridDisplay = true;
    this.refreshAddNewRow = false;
    this.refreshFormGroup = false;
    let _this = this;
    let fxpayload = {
      name: this.fxIndex,
      termStructure: this.termStructure,
      priceDate: this.getCurrentDateTime(this.date)
    };
    this.commonService.getJSONByObject(environment.base_url + '/api/fxirprice/v1/getdatatemplate?tenantId='+this.commonService.getFromStorage('tenantId'), fxpayload).subscribe((next: any[]) => {
      _this.newRows = next;
      _this.refreshFormGroup = true;
      _this.refreshAddNewRow = true;
    })

  }

//Save the fx form
  savefx(value: any[], isUpdate: boolean = false) {
    let _this = this;
    value.forEach(function (row:any) {
      row['priceDate'] = _this.getCurrentDateTime(row['priceDate']);
      row['termStructureDate'] = _this.getCurrentDateTime(row['termStructureDate']);
    });
    let fxpayloadObj = {
      name: this.fxIndex,
      priceDate: _this.getCurrentDateTime(this.date),
      termStructure: this.termStructure,
      tenantId: this.commonService.getFromStorage('tenantId'),
      updatedBy: this.commonService.getFromStorage('id'),
      commodityPriceList: value
    };
    if (!isUpdate) {
      fxpayloadObj['createdBy'] = this.commonService.getFromStorage('id');
    }
    this.commonService.post(environment.base_url + '/api/fxirprice/v1/saveMarketPrice?tenantId='+this.commonService.getFromStorage('tenantId'), fxpayloadObj).subscribe(next => {
      this.display = false;
      this.refreshGrid = true;
      this.showToast('FX Price is saved');
    }, (error: HttpErrorResponse) => {
      this.messageService.add({severity: 'error', summary: error.error});
    });
  }

  showToast(msg, severity: string = 'success') {
    this.messageService.add({
      severity: 'info',
      summary: msg
    });
  }

  onRefreshFromGroupComplete() {
    this.refreshFormGroup = false;
  }

  //Import the future Commodity Price
  onImportClick(importingFromForm: boolean = false) {
    this.display = false;
    this.importingFromForm = importingFromForm;
    this.displayImport = true;
    this.parentKeyValue = this.grid.getDataURL().substr(this.grid.getDataURL().lastIndexOf('=') + 1);
  }

  //close import
  closeImport() {
    this.displayImport = false;
    if (this.importingFromForm) {
      this.display = true;
      this.gridDisplay = true;
    }
  }

  importDone(success: boolean) {
    this.displayImport = false;
    if (success) {
      this.messageService.add({
        severity: 'info', summary: messages.updated['summary'],
        detail: messages.importCompleted['message'],
        life: 10000
      });

    }
  }

//update
  onSave(value: any) {
    if (value['mode'] === 'update') {
      this.savefx([value['data']], true);
    }
  }

  //nodata box
  calculateLeft() {
    this.noDataBoxLeft = ((window['visualViewport'].width - 500) / 2) + 'px';
  }

  getTooltipMessage(control: AbstractControl, col) {
    return this.showTooltipOnValidationError ? this.commonService.getValidationErrorMessage(control, col.getHeader()) : '';
  }

  getDateFormat() {
    return environment.dateFormat.toUpperCase();
  }

  onNewRowAddComplete() {
    this.refreshAddNewRow = false;
    this.newRows = [];
  }

  onImport(value: any) {
    this.display = true;
    this.displayImport = false;
    this.file = value['file'];
  }

  onImportComplete() {
    this.file = undefined;
  }
}
