import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {Tcolumn} from '../tcolumn.model';
import {FilterService} from './filter/filter.service';
import {MessageService} from "primeng/api";
import {Filter} from "./filter/filter.model";
import {CommonService} from "../../services/common.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-preference',
  templateUrl: './preference.component.html',
  styleUrls: ['./preference.component.css'],
  providers: [MessageService]
})
export class PreferenceComponent implements OnInit {
  @Input() display: boolean = false;
  @Input() primaryKey: string = "";
  @Output() changeDisplay = new EventEmitter();
  @Output() onSave = new EventEmitter();
  @Input() columns: Tcolumn[] = [];
  @Input() headerColumns: any;
  @Input() gridFilters: Filter[] = [];
  @Input() showFilter: boolean = true;
  @Input() showColumns: boolean = true;
  @Input() showSorting: boolean = true;
  @Input() gridTitle: string = '';
  @Input() selectColumn:Tcolumn[] = [];
  contractBook: any;
  selectedcolumns: any[] = [];
  hc: any[] = [];
  @Input() sc: any[] = [];
  filters: any[] = [];
  primaryObject: any = {};
  data: any;
  private sort: any[] = [];

  constructor(private service: FilterService, private messageService: MessageService, private commonService: CommonService) {
  }

  toggleDisplay() {
    this.service.resetPrevious();
    this.display = false;
    this.changeDisplay.emit(this.display);
  }

  convertIntoPlistForm(list, isselected = false) {
    let hc: any[] = [];
    let _this = this;
    list.forEach(function (data, index) {
      if ((data['visibilityArea'] === 'F' || data['visibilityArea'] === 'B' || data['visibilityArea'] === 'G')) {
        let obj = {};
        if (isselected === true) {
          if (data['visible']) {
            obj = {id: index + 1, name: data['header'], code: data['field']};
            if (data['field'] === _this.primaryKey) {
              _this.primaryObject = obj;
            }
          }
        } else {
          obj = {label: data['header'], value: {id: index + 1, name: data['header'], code: data['field']}}
        }
        hc.push(obj);
      }
    });
    return hc;

  }

  ngOnInit() {
    this.selectedcolumns = this.columns;
    this.hc = this.convertIntoPlistForm(this.headerColumns);
  }

  changeVisibility(list: Tcolumn[], list2: any[]) {
    let newlist: any[] = [];
    let sellist: any[] = [];
    list2.forEach(function (data) {
      sellist.push(data['code']);
    });
    list.forEach(function (data) {
      if (sellist.includes(data['field'])) {
        data.setVisibility(true)
      } else {
        data.setVisibility(false)
      }
      newlist.push(data);
    });
    return newlist;
  }

  /**
   * what to do on click of save button
   */
  onSavewhattodo() {
    let filters: Filter[] = [];
    this.filters.forEach(function (filter: Filter) {
      filters.push(filter.copy());
    });
    let obj = {filters: filters, columns: this.changeVisibility(this.columns, this.sc), sort: this.sort};

    // let postObject = {
    //   tenantId: this.commonService.getTenantId(),
    //   moduleName: this.gridTitle,
    //   preferencejson:  JSON.stringify(obj),
    //   preferencename:  this.commonService.getFromStorage('userName') + '_' + this.gridTitle +  '_preference',
    //   preferencetype: 'grid',
    //   status: true,
    //   userid: this.commonService.getFromStorage('userid')
    // };
    // this.commonService.post(environment.base_url + '/api/userpreference/v1/saveuserpreference?tenantId='+this.commonService.getTenantId(), postObject).subscribe(next =>{
    //   this.messageService.add({severity:'info', summary:'Preference saved successfully'});
    //   this.onSave.emit(obj);
    //   this.toggleDisplay();
    // }, error => {
    //   this.messageService.add({severity:'error', summary:'Failed to save preference.'});
    // });
    this.onSave.emit(obj);
    this.toggleDisplay();
  }

  /**
   * update filters value array
   * @param value
   */
  updateFilter(value: any) {
    this.filters = value;
  }

  /**
   * update sort array
   * @param value
   */
  updateSort(value: any) {
    this.sort = value;
  }

  onClick(value: any) {
    if (value['option']['value']['code'] === this.primaryKey) {
      this.sc.push(value['option']['value']);
      this.messageService.add({
        severity: 'info', summary: "You can't remove this field !"
      });
    }
  }

  onChange(value: any = []) {
    if (value.length == 0) {
      this.sc = value;
      if (this.primaryObject !== undefined) {
        this.sc.push(this.primaryObject);
      }
    }
  }

  /**
   * filter on change pref
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if(changes['selectColumn']) {
      let hcv=this.convertIntoPlistForm(this.headerColumns, true);
      this.selectedcolumns = changes['selectColumn'].currentValue;
      this.sc = this.convertIntoPlistForm(this.selectedcolumns, true);
      if(this.sc .length>0) {
        for (var i = 0; i < hcv.length; i++) {
          for (var j = 0; j < this.sc.length; j++) {
            if (hcv[i]['code'] == this.sc[j]['code']) {
              this.sc[j].id = hcv[i].id;
            } else {
              continue;
            }
          }
        }
      }else{
        this.sc=hcv;
      }
      this.onSavewhattodo()

    }
  }


}
