<p-dialog (onHide)="closeModal()" [(visible)]="modalState" [baseZIndex]="25"
          [closable]="true"
          [closeOnEscape]="optional"
          [draggable]="false" [maximizable]="false" [modal]="true"
          [ngClass]="'dialogClass'">
  <p-header [style.color]="'#009CDF'">
    {{heading}}
  </p-header>
  <div class="content">
    <p-editor (ngModelChange)="onValueChange($event)" [(ngModel)]="val" [style]="{'height':'320px'}"></p-editor>
  </div>
  <p-footer [style]="{overflow:'hidden'}">
    <div [style]="{overflow:'hidden'}">
      <div class="footer">
        <div style="display: flex;">
          <button (click)="closeModal()" *ngIf="optional" accesskey="c" class="ui-button-raised mybutton" label="Close"
                  pButton
                  style="margin-right: 8px;"
                  type="button"></button>
          <button (click)="throwOutput()"
                  [disabled]="isThereAnyValue()"
                  [label]="'Done'"
                  accesskey="s"
                  class="savebutton" icon="pi pi-check" iconPos="left"
                  pButton style="margin-right: 15px;" type="submit"></button>
        </div>
      </div>
    </div>
  </p-footer>
</p-dialog>

