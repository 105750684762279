<form [formGroup]="formGroup">
    <div class="d-flex flex-column card-details m-0 mt-3" style="cursor: pointer">
        <div class="p-2 bar">
            <p class="m-0 ml-2 p-0 heading">{{heading}}</p>
        </div>
        <div *ngIf="!loading" class="pl-4 pr-4 pb-2 pt-2">
            <div class="row">
                <div *ngFor="let field of fields; index as i" class="col-4 d-flex flex-column p-1 mb-2">
                    <div class="d-inline-flex flex-fill">
                        <label class="p-0 m-0 field-label fieldLabel">{{field['header']}}</label>
                        <span *ngIf="field['mandatory']" class="mandatoryIcon">*</span>
                    </div>
                    <div *ngIf="!editing">
                        <p style="font-size: 13px">{{field['type'] === 'date' ? this.commonService.getDateForOutput(bookingValue[field['field']]) :
                            getViewValue(bookingValue,field['field']) }}</p>
                    </div>
                    <div *ngIf="editing">
                        <div *ngIf="field['type'] == 'text'" class="flex-fill mt-1">
                            <input formControlName="{{field['field']}}" placeholder="Enter Value"/>
                        </div>
                        <div *ngIf="field['type'] == 'dropdown'" class="flex-fill mt-1">
                            <p-dropdown [options]="field['listValues']" formControlName="{{field['field']}}">
                            </p-dropdown>
                        </div>
                        <div *ngIf="field['type'] == 'date'" class="d-flex">
                            <input #dp="bsDatepicker" [bsConfig]="bsConfig"
                                   autocomplete="off"
                                   bsDatepicker class="form-control form-control-sm datePicker"
                                   formControlName="{{field['field']}}"
                                   placeholder="Select Date" type="text">
                            <div (click)="dp.toggle()" [attr.aria-expanded]="dp.isOpen">
                            <i class="far fa-calendar align-self-center"></i>
                            </div>
                        </div>
                        <div *ngIf="field['type'] == 'spinner'">
                            <app-number-spinner [max]="100" formControlName="{{field['field']}}"></app-number-spinner>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="loading" style="align-self: center; text-align: center;">
            <img alt="" class="loadingIco" src="../assets/images/doubleRingLoading.gif">
            <p class="widLoadingText">Loading Widgets...</p>
        </div>
    </div>
</form>
