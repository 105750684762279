import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output, SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {Grid} from "../../../grid/grid.model";
import {Filter} from "../../../grid/preference/filter/filter.model";
import {Table} from "primeng/table";
import {environment} from "../../../../environments/environment";
import {FormBuilder, FormControl, FormGroup, NgForm, Validators} from "@angular/forms";
import {GridService} from "../../../grid/grid.service";
import {ExcelServiceService} from "../../../services/excel-service.service";
import {UtilService} from "../../../ctrm-input-form/services/util.service";
import {AutoComplete, ConfirmationService, MessageService, Paginator} from "primeng";
import {Router} from "@angular/router";
import {BreadcrumbService} from "../../../Components/ctrm-breadcrumb/breadcrumb.service";
import {FilterService} from "../../../grid/preference/filter/filter.service";
import {CommonService} from "../../../services/common.service";
import {PivotServiceService} from "../../../Components/ctrm-pivot-table/pivot-service.service";
import {MasterService} from "../../../masters/services/MasterService";
import {CtrmInputFormComponent} from "../../../ctrm-input-form/ctrm-input-form.component";
import {Tcolumn} from "../../../grid/tcolumn.model";
import {Breadcrumb} from "../../../Components/ctrm-breadcrumb/breadcrumb.model";
import {DatePipe} from "@angular/common";
import {messages} from "../../../services/common/messages";
import {Subscription} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {FilterUtils} from "primeng/utils";
import {KeyValue} from "../../../grid/key-value.model";
import {tradeOptionsButtons} from "../../../services/common/entities";
import {ChangeDetectionStrategy} from '@angular/core';


@Component({
    selector: 'app-ops-preferences',
    templateUrl: './ops-preferences.component.html',
    styleUrls: ['./ops-preferences.component.css']
})
export class OpsPreferencesComponent implements OnInit, OnChanges {
    @Input('grid') grid: Grid;
    @Input() openInEditMode: boolean = false;
    @Input() getFromUrl: boolean = true;
    @Input() refreshGrid: boolean = false;
    @Input() oldEditableFields: any[] = [];
    @Input() loadData: any[] = [];
    @Input() onlyNewEditableMode: boolean = false;
    @Input() extraViewTemplate: TemplateRef<any>;
    selectedTradeOption: any = '';
    @Input() showSectionTop: boolean = true;
    @Input() updateBreadcrumb: boolean = true;
    @Input() breadcrumbMapKey = '';
    @Input() selectRowFunction: Function;
    @Output() onRefreshComplete = new EventEmitter();
    @Output() onClickedRefresh = new EventEmitter();
    @Output() selectedRows = new EventEmitter();
    @Output() getRowData = new EventEmitter();
    @ViewChild('dt', {static: true}) table: Table;
    @ViewChild('gridinputform', {static: false}) gridinputform: CtrmInputFormComponent;
    gridFilter: Filter[];
    filter: boolean = true;
    selectedColumns: any[] = [];
    @Input() selectedPrefCols: any[] = [];
    @Input() columnsFromParent: Map<string, Tcolumn>;
    rowDataValues: any[] = [];
    @Output() columnsPreferenceList = new EventEmitter();
    @Input() preferenceVisibility: boolean = true;
    @Input() selectedRow: any[];
    filteredValue: any[] = [];
    headerColumns: any[];
    isError: boolean = false;
    pageSize = 20;
    totalPages = 0;
    @Input() totalElements: any;
    number = 0;
    newRowPrimaryKeyValues: any[] = [];
    filters: Filter[] = [];
    private canScrollFurthure: boolean = true;
    breadCrumbDone: string[] = [];
    breadCrumbNext: Breadcrumb[] = [];
    prefDefault: boolean = false;
    selectedValue: any;
    display: boolean = false;
    @Input() showFetching: boolean = false;
    newRow: Map<number, boolean> = new Map();
    columnSuggestionList: any = [];
    data: any;
    @Input() numberboxwidth: any = '';
    categoryTitle: string;
    config: any;
    listOfValues: Map<any, any> = new Map();
    selectedPreference: any;
    datePipe: DatePipe;
    dateFormat: any = environment.dateFormat;
    min: number;
    max: number;
    refreshForm: boolean = false;
    preferenceSaveDialog: boolean = false;
    preferenceName: string = '';
    preferenceList: any[] = [];
    gridPrefData: any[] = [];
    columnsList: any[] = [];
    filterColumnList: any[] = [];
    filterDropDown: any[] = [];
    @Input() title: string;
    @Input() gridHeight: string = '';
    placeHolderHeight: string = '';
    noDataBoxLeft: string = '';
    loadMessage: string = 'Loading ..';
    selectedFilterCol: any[] = [];
    filterActionList: any[] = [];
    filterConfigarationList: any[] = [];
    selectedFilterAction: any[] = [];
    filterPanelDisplay: boolean = false;
    filterList: Filter[] = [];
    mainFilterArray: any[] = [];
    addedList: string[] = [];
    @Output() totalNumberElements = new EventEmitter();
    @Output() onHeightCalculationDone = new EventEmitter();
    frozenCols: any[] = [];
    statusFieldName: string = '';
    primaryKey: string;
    @Input() autoCalculateGridWidth: boolean = true;
    saveUrl: string = '';
    url: string = environment.base_url;
    bulkUrl: string = '';
    editUrl: string = '';
    getUrl: string = '';
    importTableName: string = '';
    tradeOptions: any[] = [];
    addInline: boolean = false;
    @Input() currentRoute = '/';
    payloadList: any[] = [];
    public preferenceFilterForm: FormGroup;
    fieldArray: Array<number> = [0];
    payload: {} = {};
    @Output() preferencePayloadList = new EventEmitter();
    @Input() gridFilterURL: string;
    selectedPreferenceColumn = new Set<string>();
    @Output() advacedFilterDataDTO = new EventEmitter();
    @Output() close = new EventEmitter();
    @Output() Filter = new EventEmitter();
    @Output() refreshGridEmit = new EventEmitter();
    refreshGridEmitValue: string = '';
    @Input() moduleTitle: string = '';
    @Input() columns: Tcolumn[] = [];
    @Input() selectColumn: any[] = [];
    @Input() autoCalculateHeight: boolean = true;
    @Input() isOnSidebar: boolean = false;
    private originalColumnlist: Tcolumn[] = [];

    constructor(private formBuilder: FormBuilder,
                private gridService: GridService,
                private excelService: ExcelServiceService,
                private util: UtilService,
                private changeDetectorRef: ChangeDetectorRef,
                private messageService: MessageService,
                public route: Router, public breadCrumbService: BreadcrumbService,
                private service: FilterService, public commonService: CommonService, public ps: PivotServiceService,
                private confirmationService: ConfirmationService, public masterCommonService: MasterService) {
    }


    ngOnInit() {
        this.fetchGridPreference();
        this.datePipe = new DatePipe('en-US');
        this.makeColListForPref(this.columns);
        if (this.updateBreadcrumb && this.showSectionTop) {
            this.breadCrumbService.makeBreadcrumbTo(this.breadcrumbMapKey, this.currentRoute);
        }
    }


    ngOnChanges(changes: SimpleChanges) {
        if (changes['columns']) {
            let _this = this;
            this.originalColumnlist = [];
            changes['columns'].currentValue.forEach(function (col: Tcolumn) {
                _this.originalColumnlist.push(_this.covertJSONtoTcolumn(col));
            });
        }
    }


    showBack() {
        return (this.grid.getFirstLevelDataUrl() !== undefined && this.grid.getFirstLevelDataUrl().length > 0);
    }


    changeRouteToBack() {
        this.route.navigate([this.grid.getFirstLevelDataUrl()]);
        this.commonService.saveToStorage('lastRoute', {
            routeTo: this.grid.getFirstLevelDataUrl(),
            skip: true,
            params: {}
        });
        this.breadCrumbService.popBreadcrumb();
    }

    async fetchGridPreference(reloadList:boolean = false) {
        let _this = this;
        let preference: any;
        this.preferenceList = [];
        this.selectedPreference = '';
        let defaultPrefObj = {
            columns: this.columns,
            filters: []
        };
        //Call API to get preference for the grid.
        let result = await this.commonService.getDataByURLAsync(environment.base_url_new + '/api-iam/api/userpreference/v1/getgriduserpreference?tenantId=' + this.commonService.getTenantId() +
            '&userId=' + this.commonService.getFromStorage('userid') + '&moduleName=' + this.moduleTitle).then((data: any) => {
            return data;
        }, error => {
            this.preferenceList.push({label: 'System', value: 'System'});
            this.selectedPreference = 'System';
        });

        if ((result !== null && result !== undefined) && Array.isArray(result)) {
            this.preferenceList.push({label: 'System', value: 'System'});
            this.selectedPreference = 'System';

            this.gridPrefData.push(result[0]);
            result.forEach(function (result, index) {
                _this.preferenceList.push({
                    label: result['preferencename'],
                    value: result['preferencename'],
                    pref: result
                });
                if (result['preferencedefault']) { // when default prefrene is found
                    _this.prefDefault = true;
                    _this.selectedPreference = _this.preferenceList[index + 1]['value'];
                }
            });
            if(reloadList) {
                this.reloadData(JSON.parse(result[0]['preferencejson']), false);
                this.onApplyFilter(JSON.parse(result[0]['preferencejson']['filters']));
                // this.updateSelectedPrefList(columnsData);
            }
        } else{
            //Save API for System
            this.preferenceList.push({label: 'System', value: 'System'});
            this.selectedPreference = 'System';
            this.reloadData(defaultPrefObj);
        }
    }


    reloadData(object: any, fromPrefrences: boolean = true) {
        this.gridFilter = object.filters;
        this.calculateHeight();
    }


    calculateHeight() {
        if (this.autoCalculateHeight) {
            let offsetTop = this.table.el.nativeElement['offsetTop'];
            if (this.isOnSidebar) {
                offsetTop += 85;
            }
            let offsetHeight = 0;
            if (this.table.el.nativeElement['offsetParent'] !== undefined && this.table.el.nativeElement['offsetParent'] !== null) {
                offsetHeight = this.table.el.nativeElement['offsetParent']['offsetHeight'];
            }
            let extraHeight = 0;
            if (this.getPagination()) {
                extraHeight = 30;
            }
            this.gridHeight = offsetHeight - (offsetTop + 21 + extraHeight) + 'px';
            this.placeHolderHeight = offsetHeight - (offsetTop + 61) + 'px';
            this.onHeightCalculationDone.emit();
        }
        this.calculateLeft();
    }


    getPagination() {
        return this.totalElements > this.pageSize;
    }


    calculateLeft() {
        this.noDataBoxLeft = ((window['visualViewport'].width - 500) / 2) + 'px';
    }


    onPrefSelection(value) {
        this.filterConfigarationList = [];
        let _this = this;
        this.gridPrefData = [];
        if (value.toLowerCase() !== 'system') {
            this.preferenceName = value;
            let obj = this.preferenceList.find(function (pref, index) {
                if (pref['value'].toLowerCase() !== 'system') {
                    if (pref['pref']['preferencename'] == value)
                        return true;
                }
            });
            this.gridPrefData.push(obj['pref']);
            let columnsData: any[] = [];
            let JSONData = JSON.parse(obj['pref']['preferencejson']);
            columnsData = JSONData.columns;
            this.updateSelectedPrefList(columnsData);
            this.reloadData(columnsData, false);
            this.onApplyFilter(JSON.parse(obj['pref']['preferencejson'])['filters']);
            (JSON.parse(obj['pref']['preferencejson'])['filters']).forEach((ConfigarationList: any, index) => {
                this.filterConfigarationList.push(ConfigarationList);
            })
        } else {

            this.refreshGridEmitValue = 'refresh';
            this.refreshGridEmit.emit(this.refreshGridEmitValue);

            this.fetchGridPreference();
        }
    }





    onApplyFilter(FilterArryDB = []) {
        if (this.filterConfigarationList.length > 0) {
            this.filterConfigarationList.forEach((ConfigarationList: any, index) => {
                this.mainFilterArray.push(ConfigarationList);
            })
        } else {
            this.mainFilterArray = FilterArryDB;
        }
        this.filters = this.mainFilterArray;
        this.filterList = [];
        this.filterPanelDisplay = false;
        this.Filter.emit(this.mainFilterArray);
        this.filterList = [];
        this.addedList = [];
        this.filterActionList = [];
        this.filterConfigarationList = [];
    }

    makeColListForPref(column: any[]) {
        let _this = this;
        _this.selectedPrefCols = [];
        column.forEach(function (column) {
            if (!_this.service.exculedTypes.includes(column['columnType']) && column['isVisible']) {
                if (column.visible === true) {
                    if (_this.selectColumn.some(el => el.field === column['field'])) {
                        _this.selectedPrefCols.push({label: column['header'], value: column['field'], column: column});
                    }
                    _this.columnsList.push({label: column['header'], value: column['field'], column: column});
                    _this.filterColumnList.push({label: column['header'], value: column['field'], column: column});
                }
            }
        });
        this.onPrefColChange(this.selectedPrefCols);
    }

    updateSelectedPrefList(column: any[]) {
        let _this = this;
        _this.selectedPrefCols = [];
        this.originalColumnlist.forEach(function (col:Tcolumn) {
            if (column.some(el => el['field'] === col.getField())) {
                _this.selectedPrefCols.push({label: col['header'], value: col['field'], column: col});
            }
        });
        this.onPrefColChange(this.selectedPrefCols);
    }


    onPrefColChange(colChange: any, mouseEvent: boolean = false) {
        this.columns = [];
        this.selectColumn = [];
        let emitObj = {mouseEvent: mouseEvent}
        let _this = this;
        this.originalColumnlist.forEach(function (col:Tcolumn) {
            if (colChange.some(el => el.value === col.getField())) {
                _this.columns.push(col);
            }
        });
        _this.columns.sort((a, b) => a['placeholder'] < b['placeholder'] ? -1 : a['placeholder'] > b['placeholder'] ? 1 : 0);
        emitObj['columns'] = _this.columns;
        this.columnsPreferenceList.emit(emitObj);
    }


    getFormattedValue(value: any) {
        if (value.type === 'OB') {
            if (value.firstValue === 1) {
                return 'SELL';
            }
            if (value.firstValue === 0) {
                return 'BUY';
            }
        } else if (value.type === 'D') {
            return this.commonService.getFormattedDateTime(value.firstValue, environment.dateFormat);
        } else {
            return value.firstValue;
        }


    }


    toggleFilterPanel(event) {
        event.stopPropagation();
        this.filterPanelDisplay = !this.filterPanelDisplay;
        this.filterList = [];
        this.addedList = [];
        this.selectedFilterAction = [];
        this.selectedFilterCol = [];
    }

    openSavePrefDialog() {
        this.preferenceSaveDialog = true;
    }

    onMakeDefaultChange(val: any) {
        if (this.selectedPreference !== 'System' && this.selectedPreference !== '') {
            this.gridPrefData[0]['preferencedefault'] = this.prefDefault;
            this.commonService.post(environment.base_url_new + '/api-iam/api/userpreference/v1/saveuserpreference?tenantId=' + this.commonService.getTenantId(), this.gridPrefData[0]).subscribe(next => {
                this.messageService.add({severity: 'info', summary: 'Preference saved successfully'});
            }, error => {
                this.messageService.add({severity: 'error', summary: 'Failed to save preference.'});
            });
        }
    }


    addfilter(value: any) {
        this.filterColumnList.slice(this.filterColumnList.find(item => item.value === value), 1);
        this.filterList = [];
        this.addedList = [];
        this.filterActionList = [];
        this.selectedFilterAction = [];
        if (!this.addedList.includes(value['value']['value'])) {
            this.filterList.push(this.service.getFilterObject(value['value']));
            this.addedList.push(value['value']['value']);
        }
        this.filterActionList = this.service.getOptions(this.filterList[0]['type']);
    }


    updateOptions(value: any) {
        this.filterList[0].setCondition(value['value']);
        if (this.filterList[0] !== undefined && this.filterList[0] !== null && (this.filterList[0]['type'] === 'L' || this.filterList[0]['type'] === 'B' || this.filterList[0]['type'] === 'OB')) {
            this.getFilterDropDownValues(this.filterList[0]);
        }
    }

    getFilterDropDownValues(filter: Filter) {
        if (filter['field']['listvalues'] !== null && filter['field']['listvalues'] !== undefined && filter['field']['listvalues'].length !== 0) {
            if (filter['columnName'] === 'tradeTransactionType') {
                this.filterDropDown = this.masterCommonService.getBooleanFromLabel('Buy', 'Sell', true, 0, 1);
            } else {
                this.filterDropDown = filter['field']['listvalues'];
            }
        } else {
            if (filter['columnName'] === 'quantityUom') {

                this.filterDropDown = this.masterCommonService.getListFromArray(this.loadData['unitofmeasurement'], false, true);
            } else if (filter['columnName'] === 'priceType') {
                this.filterDropDown = this.masterCommonService.getListFromArray(this.loadData['price_type_ind'], false, true);
            } else if (filter['columnName'] === 'origin') {
                this.filterDropDown = this.masterCommonService.getListFromArray(this.loadData['location'], false, true);
            } else if (filter['columnName'] === 'tradeTransactionType') {
                this.filterDropDown = this.masterCommonService.getBooleanFromLabel('Buy', 'Sell', false, 0, 1);
            } else {
                this.filterDropDown = this.masterCommonService.getListFromArray(this.loadData[filter['columnName']], false, true);
            }
        }
    }

    addTextOption(value: any) {
        this.filterList[0].setFirstValue(value);
    }


    addListOption(value: any) {
        this.filterList[0].setFirstValue(value['value']);

    }

    addNumberOption(value: any, isFirst: boolean) {
        if (isFirst) {
            this.filterList[0].setFirstValue(value);
        } else {
            this.filterList[0].setSecondValue(value);
        }
    }

    getDateFormat(col: Tcolumn) {
        let format = this.dateFormat;
        if (col.getFromExtra('date')['format'] !== undefined) {
            format = col.getFromExtra('date')['format'];
        }
        return format.toString().toUpperCase();
    }

    isSecondRequired(filter: Filter) {
        return (filter.getCondition()['value'] === 'between');
    }

    isValidDate(value) {
        if (value === undefined) {
            return false;
        }
        return true;
    }

    onPrefNameChange(name: any) {
        this.preferenceName = name;
    }

    deleteFieldValue(index: number) {
        if (index >= 0) {
            this.filterConfigarationList.splice(index, 1);
        }
    }

    addFieldValue() {
        if ((this.filterConfigarationList.length >= 0) && (this.filterConfigarationList.length <= 5)) {
            if(this.filterList[0]['firstValue']!== undefined && this.filterList[0]['firstValue']!==null&& this.filterList[0]['firstValue']!==''){
                this.filterConfigarationList.push(this.filterList[0]);
            }
        }
        this.selectedFilterCol = [];
        this.filterActionList = [];
        this.filterList = [];
    }


    onClose() {
        this.filterPanelDisplay = false;
        this.close.emit();
        this.selectedFilterCol = [];
        this.filterActionList = [];
        this.filterList = [];
    }


    savePreferences() {
        if (this.preferenceName.length > 0) {
            let postObj;
            let obj = {
                filters: this.mainFilterArray,
                columns: this.columns,
            }

            postObj = {
                tenantId: this.commonService.getTenantId(),
                moduleName: this.moduleTitle,
                preferencejson: JSON.stringify(obj),
                preferencename: this.preferenceName,
                preferencetype: 'grid',
                preferencedefault: this.prefDefault,
                status: true,
                userid: this.commonService.getFromStorage('userid')
            };

            this.reloadData(JSON.parse(postObj['preferencejson']), false);
            this.commonService.post(environment.base_url_new + '/api-iam/api/userpreference/v1/saveuserpreference?tenantId=' + this.commonService.getTenantId(), postObj).subscribe(next => {
                this.messageService.add({severity: 'info', summary: 'Preference saved successfully'});
            }, error => {
                this.messageService.add({severity: 'error', summary: 'Failed to save preference.'});
            });
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Preference name is empty',
                detail: 'Please enter preference name.'
            })
        }
        this.mainFilterArray = [];
        this.preferenceSaveDialog = false;

    }


    covertJSONtoTcolumn(data: Tcolumn) {
        let json = JSON.parse(JSON.stringify(data));
        let copy: Tcolumn = new Tcolumn(json['field'], json['header'], json['columnType'], json['placeholder'], json['editable'], json['listvalues'],
            json['visible'], data.getDefaultValue(), json['visibilityArea'], data.getValidators(), json['valueType'], json['colSpan'], json['minDate'],
            json['maxDate'], json['tableMetadata'], data.getActionToDo(), json['extra'], json['isUnique']);
        return copy;
    }
}
