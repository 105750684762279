<div [ngClass]="getControlClass()">
  <div>
    <div style="display: flex">
      <label *ngIf="showLabel" class="label">{{field.getHeader()}} </label>
      <i *ngIf="getInfoVisibility()" class="{{field.getFromExtra('infoIcon')['icon']}}"
         pTooltip="{{field.getFromExtra('infoIcon')['summary']}}" tooltipPosition="right" tooltipZIndex="9999"></i>
    </div>
    <div *ngIf="!showErrorMessage" [ngClass]="focusAndErrorCheck()">
      <ng-container [ngTemplateOutlet]="controls"></ng-container>
    </div>
    <div *ngIf="showErrorMessage">
      <ng-container [ngTemplateOutlet]="controls"></ng-container>
    </div>
    <ng-template #controls>
      <div *ngIf="field.getColumnType()==='label'||field.getColumnType().toUpperCase()==='LB'">
        <div class="inputbox">
          <input (change)="onChange" (ngModelChange)="throwChanges(value)"
                 (touch)="onTouched"
                 [(ngModel)]="value"
                 [id]="field.getField()" [name]="name"
                 [ngClass]="(submitted &&formControlAIO.errors!=null)?'form-control-error':'form-control-normal'"
                 class="form-control form-control-sm" disabled
                 pTooltip="{{getTooltipMessage(formControlAIO,field)}}"
                 placeholder="{{field.showPlaceholderHint?field.getHeader():''}}"
                 tooltipEvent="focus"
                 tooltipPosition="bottom" tooltipZIndex="1000" type="text">
        </div>
      </div>
      <div
        *ngIf="field.getColumnType()==='input'||field.getColumnType().toUpperCase()==='T'||field.getColumnType().toUpperCase()==='P'">
        <div [ngClass]="addCopyClass()" class="inputbox">
          <div *ngIf="!isUppercase()" class="d-inline-flex">
            <input #inputTextnormal (change)="onChange"
                   (focusout)="!requiresContinuesChangeDetection?throwChanges(value):''"
                   (input)="inputValidator($event)" (touch)="onTouched"
                   [(ngModel)]="value"
                   [disabled]="readOnly"
                   [id]="field.getField()"
                   [name]="name"
                   [ngClass]="getFormClass()"
                   [type]="parseType(field.getColumnType())"
                   (ngModelChange)="requiresContinuesChangeDetection?throwChanges(value):''"
                   class="form-control form-control-sm" pInputText
                   pTooltip="{{getTooltipMessage(formControlAIO,field)}}"
                   placeholder="{{field.showPlaceholderHint?field.getHeader():''}}" tooltipEvent="focus"
                   tooltipPosition="bottom" tooltipZIndex="1000">
            <i (click)="togglePassword()"
               *ngIf="field.getColumnType().toUpperCase() === 'P'"
               [ngClass]="!passwordToggle?'fas fa-eye':'fas fa-eye-slash'" style="color: #000;padding:7px; border-top: 1px solid #a7a7a7;background:#fff;border-bottom: 1px solid #a7a7a7;"></i>
            <button (cbOnSuccess)="changeMessage()" *ngIf="showCopy()" [ngxClipboard]="inputTextnormal"
                    type="button">{{copyMessage}}</button>
          </div>
          <div *ngIf="isUppercase()" style="display: inline-flex">
            <input  #inputTextuppercase (change)="onChange"
                   (focusout)="!requiresContinuesChangeDetection?throwChanges(value):''" (input)="inputValidator($event)" (touch)="onTouched"
                   [(ngModel)]="value"
                   [disabled]="readOnly"
                   upperCase
                   [id]="field.getField()"
                   [name]="name"
                   [ngClass]="getFormClass()"
                   [type]="parseType(field.getColumnType())" (ngModelChange)="requiresContinuesChangeDetection?throwChanges(value):''"
                   class="form-control form-control-sm" pInputText
                   pTooltip="{{getTooltipMessage(formControlAIO,field)}}"
                   placeholder="{{field.showPlaceholderHint?field.getHeader():''}}" tooltipEvent="focus"
                   tooltipPosition="bottom" tooltipZIndex="1000">
            <i (click)="togglePassword()"
               *ngIf="field.getColumnType().toUpperCase() === 'P'"
               [ngClass]="!passwordToggle?'fas fa-eye':'fas fa-eye-slash'" style="color: #000;padding:7px; border-top: 1px solid #a7a7a7;background:#fff;border-bottom: 1px solid #a7a7a7;"></i>
            <button (cbOnSuccess)="changeMessage()" *ngIf="showCopy()" [ngxClipboard]="inputTextuppercase"
                    type="button">{{copyMessage}}</button>
          </div>
        </div>
      </div>
      <div *ngIf="field.getColumnType().toUpperCase()==='N'">
        <div class="inputbox">
          <input (change)="onChange" (focusout)="!requiresContinuesChangeDetection?throwChanges(value):''"
                 (input)="inputValidator($event)" (touch)="onTouched" [(ngModel)]="value"
                 [disabled]="readOnly"
                 [id]="field.getField()"
                 [name]="name"
                 [ngClass]="(submitted && formControlAIO.errors!=null)?'form-control-error':'form-control-normal'"
                 [step]="'any'"
                 [style.width]="numberboxwith.length > 0?numberboxwith:''"
                 [style.maxWidth]="numberboxwith.length > 0?numberboxwith:''"
                 [style.minWidth]="numberboxwith.length > 0?numberboxwith:''"
                 [style.textAlign]="rightAlignText?'right':'left'"
                 [type]="parseType(field.getColumnType())" (ngModelChange)="requiresContinuesChangeDetection?throwChanges(value):''"
                 class="form-control form-control-sm noSpinner" ctrmRestrictDecimal
                 onkeydown="return event.keyCode !== 69"
                 pInputText pTooltip="{{getTooltipMessage(formControlAIO,field)}}"
                 placeholder="{{field.showPlaceholderHint?field.getHeader():''}}"
                 tooltipEvent="focus"
                 tooltipPosition="bottom" tooltipZIndex="1000">
        </div>
      </div>
      <div *ngIf="field.getColumnType()==='dropdown'||field.getColumnType().toUpperCase()==='L'"
           style="display: flex">
        <div class="dropdownStyle">
          <p-dropdown (ngModelChange)="throwChanges($event)"
                      [(ngModel)]="value"
                      [filter]="(field.getFromExtra('dropdown')['filter'] !== null && field.getFromExtra('dropdown')['filter'] !== undefined   ? field.getFromExtra('dropdown')['filter'] : false)"
                      [appendTo]="appendTo"
                      [formControl]="formControlAIO"
                      [disabled]="readOnly" [id]="field.getField()"
                      [options]="listOfValues"
                      pTooltip="{{getTooltipMessage(formControlAIO,field)}}" tooltipEvent="focus" tooltipPosition="bottom"
                      tooltipZIndex="1000"></p-dropdown>
        </div>
        <div *ngIf="masterCommonService.getAddbtnField().includes(field.getField()) && showAddButtons"
             style="margin-left: 10px; margin-top:7px"><i (click)="handleClick(field.getField())"
                                                          class="fas fa-plus-circle fa-lg plusCircle"
                                                          pTooltip="{{'Add New ' + field.getHeader()}}"
                                                          tooltipPosition="right" tooltipZIndex="9999"></i></div>
      </div>
      <div *ngIf="field.getColumnType()==='editdropdown'||field.getColumnType().toUpperCase()==='EL'"
           style="display: flex">
        <div class="dropdownStyle">
          <p-autoComplete (ngModelChange)="throwChanges($event)"
                      [(ngModel)]="value"
                      [appendTo]="appendTo" [placeholder]="'Enter '+field.getHeader()"
                      [formControl]="formControlAIO"
                      [disabled]="readOnly" [id]="field.getField()" [dropdown]="true"
                      [suggestions]="suggestionlist" (completeMethod)="search($event)"
                      pTooltip="{{getTooltipMessage(formControlAIO,field)}}" tooltipEvent="focus" tooltipPosition="bottom"
                      tooltipZIndex="1000"></p-autoComplete>
        </div>
        <div *ngIf="masterCommonService.getAddbtnField().includes(field.getField()) && showAddButtons"
             style="margin-left: 10px; margin-top:7px"><i (click)="handleClick(field.getField())"
                                                          class="fas fa-plus-circle fa-lg plusCircle"
                                                          pTooltip="{{'Add New ' + field.getHeader()}}"
                                                          tooltipPosition="right" tooltipZIndex="9999"></i></div>
      </div>
      <div *ngIf="field.getColumnType()==='datepicker'||field.getColumnType().toUpperCase()==='D'">
        <div class="inputbox">
          <div class="div-control">
            <input #dp="bsDatepicker"
                   (bsValueChange)="throwChanges($event)" (change)="onChange"
                   (touch)="onTouched"
                   [(ngModel)]="value"
                   [bsConfig]="{dateInputFormat:((dateInputFormat !== null && dateInputFormat !== undefined) ? dateInputFormat : (field.getFromExtra('date')['format'] !== undefined ? field.getFromExtra('date')['format'] : dateFormatDefualt)).toUpperCase(),adaptivePosition:true,useUtc:false}"
                   [disabled]="readOnly"
                   [id]="field.getField()"
                   [maxDate]="isValidDate(field.getMaxDate())?field.getMaxDate():''"
                   [minDate]="isValidDate(field.getMinDate())?field.getMinDate():''"
                   [minMode]="((dateMinMode !== null && dateMinMode !== undefined)?dateMinMode:field.getFromExtra('date')['view'])"
                   [ngClass]="(submitted && formControlAIO.errors!=null)?'form-control-error':'form-control-normal'"
                   bsDatepicker class="form-control form-control-sm pickerInput"
                   pTooltip="{{getTooltipMessage(formControlAIO,field)}}"
                   placeholder="Select Date"
                   style="border-right: none;min-width: calc(100% - 30px)"
                   autocomplete="off"
                   [formControl]="formControlAIO"
                   tooltipEvent="focus"
                   tooltipPosition="bottom" tooltipZIndex="1000" type="text">
            <div (click)="!(formControlAIO.disabled && readOnly)?dp.toggle():''" [attr.aria-expanded]="dp.isOpen"
                 [ngClass]="formControlAIO.disabled && readOnly?'disabledCalendar':'calIconEnabled'" class="calIcon">
              <i class="fas fa-calendar"></i>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="field.getColumnType()==='filepicker'||field.getColumnType().toUpperCase()==='F'">
        <div class="inputbox">
          <div class="div-control">
            <ctrm-file-picker
              (onFileSelected)="handleFileInput($event,isMultiple(field))"
              [disable]="readOnly"
              [fileName]="fileName"
              [fpClass]="(submitted && formControlAIO.errors!=null)?'form-control-error':'form-control-normal'"
              [id]="field.getField()"
              [multiple]="isMultiple(field)" pTooltip="{{getTooltipMessage(formControlAIO,field)}}"
              tooltipEvent="focus"
              tooltipPosition="bottom"
              tooltipZIndex="1000"></ctrm-file-picker>
          </div>
        </div>
      </div>
      <div *ngIf="field.getColumnType()==='timepicker'||field.getColumnType().toUpperCase()==='TP'">
        <div [ngClass]="(submitted && formControlAIO.errors!=null)?'form-control-error':'form-control-normal'"
             class="inputbox">
          <ngx-timepicker-field (change)="onChange" (ngModelChange)="throwChanges($event)" (touch)="onTouched"
                                [(ngModel)]="value"
                                [controlOnly]="true"
                                [disabled]="readOnly" [id]="field.getField()"
                                [name]="name"
                                class="timepicker" pTooltip="{{getTooltipMessage(formControlAIO,field)}}" tooltipEvent="focus"
                                tooltipPosition="bottom" tooltipZIndex="1000"></ngx-timepicker-field>
        </div>
      </div>
      <div *ngIf="field.getColumnType()==='radiobuttons'||field.getColumnType().toUpperCase()==='R'">
        <div *ngFor="let listitem of field.getListOfValues()"
             [ngClass]="(submitted && formControlAIO.errors!=null)?'form-control-error':'form-control-normal'"
             class="custom-control custom-radio custom-control-inline">
          <p-radioButton (change)="onChange" (ngModelChange)="throwChanges(value)" (touch)="onTouched"
                         [(ngModel)]="value" [disabled]="readOnly" [label]="listitem.getLabel()"
                         [name]="field.getField()"
                         [value]="listitem.getValue()">

          </p-radioButton>
        </div>
      </div>
      <div *ngIf="field.getColumnType()==='checkbox'||field.getColumnType().toUpperCase()==='C'">
        <div *ngFor="let listitem of field.getListOfValues();index as i"
             [ngClass]="(submitted && formControlAIO.errors!=null)?'form-control-error':''"
             class="custom-control custom-checkbox">
          <!--suppress XmlDuplicatedId -->
          <input (change)="onChange" (ngModelChange)="throwChanges(value)" (touch)="onTouched"
                 [(ngModel)]="value"
                 [disabled]="readOnly"
                 [id]="field.getField()"
                 class="custom-control-input" id="{{listitem.value}}"
                 name="{{listitem.value}}"
                 pTooltip="{{getTooltipMessage(formControlAIO,field)}}" tooltipEvent="focus" tooltipPosition="bottom"
                 tooltipZIndex="1000" type="checkbox" value="{{listitem.value}}">
          <label class="custom-control-label" for="{{listitem.value}}">{{listitem.label}}</label>
        </div>
      </div>
      <div *ngIf="field.getColumnType()==='toggle'||field.getColumnType().toUpperCase()==='B'">
        <ui-switch (change)="onChange" (touch)="onTouched"
                   (valueChange)="throwChanges($event)" [(ngModel)]="value"
                   [checkedLabel]="getLabel(true)"
                   [checkedTextColor]="'#fff'"
                   [defaultBgColor]="'#d8d8d8'"
                   [formControl]="formControlAIO"
                   [disabled]="readOnly"
                   [id]="field.getField()"
                   [uncheckedLabel]="getLabel(false)" pTooltip="{{getTooltipMessage(formControlAIO,field)}}"
                   tooltipEvent="focus"
                   tooltipPosition="bottom"
                   tooltipZIndex="1000"></ui-switch>
      </div>
      <div *ngIf="field.getColumnType()==='S'">
        <div class="inputbox">
          <app-ctrm-suggestion-input (change)="onChange"
                                     (focusout)="onFocusOut()"
                                     (input)="inputValidator($event)"
                                     (ngModelChange)="checkForKey($event)"
                                     (touch)="onTouched"
                                     [(ngModel)]="value"
                                     [disabled]="readOnly"
                                     [displaySuggestion]="displaySuggestion"
                                     [id]="field.getField()"
                                     [name]="name"
                                     [placeholder]="field.showPlaceholderHint?field.getHeader():''"
                                     [sclass]="'form-control form-control-sm'" [sgClass]="(submitted && formControlAIO.errors!=null)?'form-control-error':'form-control-normal'"
                                     [suggestions]="field.getListOfValues()"
                                     [type]="parseType(field.getColumnType())"
                                     pTooltip="{{getTooltipMessage(formControlAIO,field)}}"
                                     tooltipEvent="focus"
                                     tooltipPosition="bottom"
                                     tooltipZIndex="1000">
          </app-ctrm-suggestion-input>
        </div>
      </div>
      <div *ngIf="['ML','ML_WOS'].includes(field.getColumnType())">
        <ctrm-multiselect (change)="onChange"
                          (touch)="onTouched"
                          [(ngModel)]="value"
                          [allowSearch]="(field.getColumnType()!=='ML_WOS')"
                          [disabled]="readOnly"
                          [id]="field.getField()"
                          [list]="field.getListOfValues()"
                          [mclass]="'form-control form-control-sm'"
                          [msClass]="(submitted && formControlAIO.errors!=null)?'form-control-error':'form-control-normal'" [multiple]="true"
                          [outputFormat]="field.getFromExtra('outputFormat','string')"
                          [placeholder]="field.getFromExtra('placeHolder','Choose')"
                          [formControl]="formControlAIO"
                          pTooltip="{{getTooltipMessage(formControlAIO,field)}}"
                          tooltipEvent="focus"
                          tooltipPosition="bottom"
                          tooltipZIndex="1000">
        </ctrm-multiselect>
      </div>
      <div *ngIf="field.getColumnType()==='TA'">
        <textarea (change)="onChange" (focusout)="throwChanges(value)"
                  (input)="inputValidator($event)"
                  (touch)="onTouched"
                  [(ngModel)]="value"
                  [autoResize]="enableAutoResize()"
                  [disabled]="readOnly"
                  [id]="field.getField()"
                  [name]="name"
                  [ngClass]="(submitted && formControlAIO.errors!=null)?'form-control-error':'form-control-normal'" [rows]="1"
                  class="form-control form-control-sm textAreaClass"
                  pInputTextarea
                  pTooltip="{{getTooltipMessage(formControlAIO,field)}}"
                  placeholder="Enter {{field.getHeader()}}"
                  required="true"
                  tooltipEvent="focus"
                  tooltipPosition="bottom" tooltipZIndex="1000"
                  type="text">{{field.getDefaultValue()}}</textarea>
      </div>
      <div *ngIf="field.getColumnType()==='SL'">
        <ctrm-slider (change)="onChange"
                     (sliderValueChange)="throwChanges($event)"
                     (touch)="onTouched"
                     [(sliderValue)]="value"
                     [ceil]="field.getMaxDate()"
                     [disabled]="readOnly"
                     [floor]="field.getMinDate()" [id]="field.getField()"
                     [name]="name"
                     [showSelectionBar]="true"
                     pTooltip="{{getTooltipMessage(formControlAIO,field)}}"
                     tooltipEvent="focus"
                     tooltipPosition="bottom"
                     tooltipZIndex="1000">
        </ctrm-slider>
      </div>
      <div *ngIf="field.getColumnType()==='TN'">
        <ctrm-tel-input (change)="onChange"
                        (focusout)="throwChanges(value)" (input)="inputValidator($event)"
                        (touch)="onTouched"
                        [(telephoneValue)]="value"
                        [disabled]="readOnly"
                        [id]="field.getField()"
                        [selectedCountry]="field.getFromExtra('selectedCountry')"
                        pTooltip="{{getTooltipMessage(formControlAIO,field)}}" tooltipEvent="focus"
                        tooltipPosition="bottom"
                        tooltipZIndex="1000"></ctrm-tel-input>
      </div>
      <div *ngIf="field.getColumnType()==='BT'">
        <p-button (onClick)="handleButtonClick($event,field)" [id]="field.getField()"
                  [label]="field.getHeader()" pTooltip="{{getTooltipMessage(formControlAIO,field)}}"
                  tooltipEvent="focus" tooltipPosition="bottom" tooltipZIndex="1000" type="button"></p-button>
      </div>
      <div *ngIf="field.getColumnType()==='LINK'">
        <div class="linkBorder">
          <p (click)="handleButtonClick($event,field)" [id]="field.getField()"
             pTooltip="{{getTooltipMessage(formControlAIO,field)}}" class="linkClass"
             tooltipEvent="focus" tooltipPosition="bottom" tooltipZIndex="1000">{{field.getHeader()}}</p>
        </div>
      </div>
      <div *ngIf="field.getColumnType()==='OB'">
        <p-selectButton (change)="onChange" (ngModelChange)="throwChanges($event)"
                        (touch)="onTouched" [(ngModel)]="value"
                        [disabled]="readOnly"
                        [options]="listOfValues"
                        [formControl]="formControlAIO"
                        pTooltip="{{getTooltipMessage(formControlAIO,field)}}"
                        tooltipEvent="focus" tooltipPosition="bottom"
                        tooltipZIndex="1000">
          <ng-template let-item>
            <div class="optionButton">
              <i [ngClass]="value === item.value?'fas fa-check-circle':'far fa-circle'"></i>
              <span>{{item.label}}</span>
            </div>
          </ng-template>
        </p-selectButton>
      </div>
      <div *ngIf="field.getColumnType()==='DSL'">
        <xceler-dual-slider (change)="onChange"
                            (ngModelChange)="throwChanges($event)"
                            (touch)="onTouched"
                            [(ngModel)]="value"
                            [ceilLeft]="getLabelFormat(field,'ceilLeft')"
                            [ceilRight]="getLabelFormat(field,'ceilRight')"
                            [disabled]="readOnly"
                            [floorLeft]="getLabelFormat(field,'floorLeft')"
                            [floorRight]="getLabelFormat(field,'floorRight')"
                            [id]="field.getField()"
                            [inString]="true"
                            [labelFormatLeft]="getLabelFormat(field,'labelFormatLeft')"
                            [labelFormatRight]="getLabelFormat(field,'labelFormatRight')"
                            [labelHighFormatLeft]="getLabelFormat(field,'labelHighFormatLeft')"
                            [labelHighFormatRight]="getLabelFormat(field,'labelHighFormatRight')"
                            [labelLowFormatLeft]="getLabelFormat(field,'labelLowFormatLeft')"
                            [labelLowFormatRight]="getLabelFormat(field,'labelLowFormatRight')" pTooltip="{{getTooltipMessage(formControlAIO,field)}}"
                            tooltipEvent="focus"
                            tooltipPosition="bottom" tooltipZIndex="1000">
        </xceler-dual-slider>
      </div>
      <div *ngIf="field.getColumnType()==='NS'">
       <xceler-number-spinner
               (ngModelChange)="throwChanges($event)"
               (change)="onChange"
               [(ngModel)]="value" tooltipEvent="focus"
               pTooltip="{{getTooltipMessage(formControlAIO,field)}}"
               tooltipPosition="bottom" tooltipZIndex="1000">
       </xceler-number-spinner>
      </div>
      <div *ngIf="field.getColumnType()==='CUSTOM'">
        <ng-container [ngTemplateOutlet]="customTemplate"
                      [ngTemplateOutletContext]="{formControl:formControlAIO,formControlName:field.getField(),index:index,readOnly:readOnly}">
        </ng-container>
      </div>
    </ng-template>
    <div *ngIf="showErrorMessage" class="invaliddata">
      {{commonService.getValidationErrorMessage(formControlAIO, field.getHeader())}}
    </div>
  </div>
</div>

