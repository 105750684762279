<p-dialog #schedule [(visible)]="display"
          [closeOnEscape]="false"
          [contentStyle]="{ overflow: 'scroll', overflowX:'hidden'}"
          [draggable]="false"
          [maximizable]="false" [modal]="true" [showHeader]="false"
          [style]="{width: '50%',padding:'0px',background:'#ffffff'}">
    <div class="dialogHeading">
        <div class="deliveryClass">
            <div style="display: inline-flex; width: inherit">
                <p class="dialogLabel">Desired Post Action</p>
                <div style="display: flex;align-self: center;">
                    <div >
                        <button class="planScreenBtns" style="width: 100px ; margin: 0px ; padding: 5px;" (click)="addNew()" *ngIf="!viewOnly" > Add New</button>
                    </div>
                    <div *ngIf="!viewOnly" >
                        <button (click)="onSaveUnit()" class="planScreenBtns" style="width: 100px ; margin: 0px ; padding: 5px; width: 100px; margin-left: 10px" [disabled]="!formGroup.valid || desiredEndStatusList.length == 0">Save</button>
                    </div>
                </div>
            </div>
            <div class="formCloseIcon">
                <i (click)="onCancel()" class="material-icons closable" style="float: right;">clear</i>
            </div>
        </div>
    </div>
    <div class="sectionBody style-4">
        <app-ctrm-price-table
                [columns]="columns"
                [gridDisplay]="true"
                [minHeight]="'auto'"
                [maxHeight]="'500px'"
                [showCrossIcon]="!viewOnly"
                [showNewEntryIcon]="!viewOnly"
                [checkValidityBeforeAddingNewRow]="true"
                (onFormGroupCreated)="updateFormGroup($event)"
                [value]="desiredEndStatusList"
                (onRefreshFormGroupComplete)="refreshcomplete()"
                [refreshFormGroup]="refreshFormGroup" >
        </app-ctrm-price-table>
    </div>
</p-dialog>




