<p-sidebar [(visible)]="popupDisplay" [baseZIndex]="23" [modal]="false" [showCloseIcon]="false"
           [styleClass]="styleClass" position="bottom">
    <div class="bottomSidebarContainer">
        <div class="leftSection">
            <i *ngIf="canClose" (click)="close()" class="material-icons closable">clear</i>
            <ng-container *ngTemplateOutlet="leftViewTemplate"></ng-container>
        </div>
        <div class="middleSection">
            <ng-container *ngTemplateOutlet="middleViewTemplate"></ng-container>
        </div>
        <div class="rightSection">
            <button *ngIf="canClose" (click)="close()" class="cancelBtn">Cancel</button>
            <ng-container *ngTemplateOutlet="rightViewTemplate"></ng-container>
        </div>
    </div>
</p-sidebar>
