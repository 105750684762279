export class UserMasterInfoBean {

  constructor(
    userId: string,
    masterType: string,
    masterTypeValue: string,
  )

  constructor(
    public userId: string,
    public masterType: string,
    public masterTypeValue: string
  ) {

  }


}
