import {Component, OnInit} from '@angular/core';
import {Tcolumn} from '../../grid/tcolumn.model';
import {Grid} from '../../grid/grid.model';
import {KeyValue} from '../../grid/key-value.model';
import {Breadcrumb} from '../../Components/ctrm-breadcrumb/breadcrumb.model';
import {Validators} from '@angular/forms';
import {defaultValue} from "../../grid/defaultValue.model";

@Component({
  selector: 'app-group',
  templateUrl: './chartofaccount.component.html',
  styleUrls: ['./chartofaccount.component.css']
})
export class ChartOfAccountComponent implements OnInit {


  columns: Map<string, Tcolumn>;
  grid: Grid;
  groupList: KeyValue[] = [];
  breadcrumb: Breadcrumb;

  constructor() {
  }

  ngOnInit() {
    this.getColumns();
    this.grid = new Grid(this.columns, true, true, true, true, 'coaCode', 'chartOfAccounts?sort=id,desc', '/chartofaccountsdetails', 'Chart of Accounts', '', '',);
  }

  public getColumns() {
    this.columns = new Map();
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('coaCode', new Tcolumn('coaCode', 'COA Code', 'T', 1, false, undefined, true, '', 'B', [Validators.required]));
    this.columns.set('coaName', new Tcolumn('coaName', 'COA Name', 'T', 2, true, undefined, true, new defaultValue('', 'coaDescription'), 'B', [Validators.required], undefined, undefined, undefined, undefined, [], undefined, undefined, true));
    this.columns.set('coaDescription', new Tcolumn('coaDescription', 'COA Description', 'TA', 3));
  }
}
