import {Component, OnInit} from '@angular/core';
import {Tcolumn} from '../../grid/tcolumn.model';
import {Grid} from '../../grid/grid.model';
import {MasterService} from '../services/MasterService';
import {Action} from '../../grid/actions.model';
import {defaultValue} from '../../grid/defaultValue.model';
import {environment} from '../../../environments/environment';
import {Validators} from '@angular/forms';
import {CommonService} from '../../services/common.service';
import {KeyValue} from "../../grid/key-value.model";

@Component({
    selector: 'app-document-template-master',
    templateUrl: './document-template-master.component.html',
    styleUrls: ['./document-template-master.component.css']
})
export class DocumentTemplateMasterComponent implements OnInit {
    columns: Map<string, Tcolumn>;
    grid: Grid;
    refreshGrid: boolean = false;
    loadListData: any[];

    constructor(private masterCommonService: MasterService, public commonService: CommonService) {
    }

    ngOnInit(): void {
        this.commonService.getLoadData('commodity', ['config', 'config', 'config', 'config', 'data', 'data', 'data', 'data'], ['company', 'counterparty', 'incoterm', 'commodity', [{
            key: 'name',
            value: 'doc_template_business_application'
        }], [{key: 'name', value: 'trade_type_ind'}], [{key: 'name', value: 'doc_template_engine'}], [{
            key: 'name',
            value: 'doc_template_transaction_type'
        }]]).subscribe((next: any) => {
            this.loadListData = next;
            this.getColumns();
            this.grid = new Grid(this.columns, true, true, true, true, 'docTemplatename', '/api/doctemplateservice/v1/getalldoctemplate?tenantId='+this.commonService.getFromStorage('tenantId'), '', 'Document Template');
            this.grid.updateUrl = environment.base_url_new + '/api-bm/api/doctemplateservice/v1/updatedoctemplate';
            this.grid.updateUrlType = 'patch';
            this.grid.setSaveUrl(environment.base_url_new + '/api-bm/api/doctemplateservice/v1/createdoctemplate');
            this.grid.hrefUrl = environment.base_url_new + '/api-bm/api/doctemplateservice/v1/getdoctemplate/';
            this.grid.onEditViewThrowUnique = true;
            this.grid.iconName = 'transactionIdRef';
            this.grid.setGridConfiguration(true, true, true, false);
            this.grid.importUrl = environment.base_url_new + '/api-bm/api/doctemplateservice/v1/import';
        });
    }

    public getColumns() {
        let templatename = function updatename(value: any = []) {
            return value[0][0]['name'];
        };
        let templatetype = function updatetype(value: any = []) {
            return value[0][0]['name'].split('.').pop();
        };
        let action: Action = new Action();
        action.addFieldToUpdate('templateFileName', '', templatename, undefined, undefined, false);
        action.addFieldToUpdate('templateFileType', '', templatetype, undefined, undefined, false,'defaultValue');

        this.columns = new Map();
        this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
        this.columns.set('docTemplateName', new Tcolumn('docTemplateName', 'Document Template Name', 'T', 2, false, undefined, true, new defaultValue('', '', environment.primaryKeyIncludedCharacters), 'B', [Validators.required, Validators.maxLength(50)]));
        this.columns.set('businessApplication', new Tcolumn('businessApplication', 'Business Application', 'L', 3, true, this.masterCommonService.getListFromArray(this.loadListData['doc_template_business_application']), true, 'Long Form Contract', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined));
        this.columns.set('company', new Tcolumn('company', 'Company', 'L', 4, true, this.masterCommonService.getListFromArray(this.loadListData['company']), true, '', 'B', [Validators.required]));
        this.columns.set('counterparty', new Tcolumn('counterparty', 'Counterparty', 'L', 5, true, this.masterCommonService.getListFromArray(this.loadListData['counterparty'].sort()), true, '', 'B', [], undefined, undefined, undefined, undefined));
        this.columns.set('commodity', new Tcolumn('commodity', 'Commodity', 'L', 6, true, this.masterCommonService.getListFromArray(this.loadListData['commodity']), true, '', 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, undefined));
        this.columns.set('incoterm', new Tcolumn('incoterm', 'Incoterm', 'L', 7, true, this.masterCommonService.getListFromArray(this.loadListData['incoterm']), true, '', 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, undefined));
        this.columns.set('tradeType', new Tcolumn('tradeType', 'Trade Type', 'L', 8, true, this.masterCommonService.getListFromArray(this.loadListData['trade_type_ind']), true, 'Physical', 'B', [], undefined, undefined, undefined, undefined, undefined));
        this.columns.set('templateEngine', new Tcolumn('templateEngine', 'Template Engine', 'L', 9, true, this.getEngineList(), true, 'docupilot', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: false}}));
        this.columns.set('template', new Tcolumn('template', 'Template', 'F', 11, true, undefined, true, '', 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, action, undefined));
        this.columns.set('templateFileName', new Tcolumn('templateFileName', 'Template File Name', 'LB', 12, true, undefined, true, '', 'B'));
        this.columns.set('templateFileType', new Tcolumn('templateFileType', 'Template File Type', 'LB', 13, true, this.getTemplateTypeList(), true, 'Docx', 'B'));
        this.columns.set('status', new Tcolumn('status', 'Status', 'B', 14, true, this.masterCommonService.getActiveInactive(), true, true, 'B', []));
    }

    private getTemplateTypeList() {
        let list:KeyValue[] = [new KeyValue()];
        list.push(new KeyValue('Docx','docx'))
        list.push(new KeyValue('Pdf','pdf'))
        list.push(new KeyValue('jrxml','jrxml'))
        return list;
    }


    private getEngineList() {
        let list:KeyValue[] = [new KeyValue()];
        list.push(new KeyValue('Docupilot','docupilot'))
        list.push(new KeyValue('Jasper','jasper'))
        return list;
    }
}
