import {AbstractControl, ValidatorFn} from "@angular/forms";

export class ctrmValidators {
  static maxLength(maxLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value !== null) {
        if (control.value !== undefined && control.value.toString().length > maxLength) {
          return {"maxlength": {requiredLength: maxLength, actual: control.value.length}};
        }
      }
      return null;
    };
  }

  static decimalLengthValidator(integerCount: number, decimalCount: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value !== null) {
        if (control.value !== undefined && control.value.toString().length > 0) {
          let intcount = 0;
          let deccount = 0;
          if (control.value.toString().includes('.')) {
            let sp = control.value.toString().split('.');
            intcount = sp[0].length;
            deccount = sp[1].length;
          } else {
            intcount = control.value.toString().length;
          }
          if (deccount > decimalCount) {
            return {"invalidDecimalLength": {max: decimalCount, actual: deccount}};
          }
          if (intcount > integerCount) {
            return {"invalidIntegerLength": {max: integerCount, actual: intcount}};
          }
        }
      }
    };
  }

  static dualSliderRequired(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value !== null) {
        if (control.value !== undefined && control.value.toString().length > 0) {
          let json = control.value;
          if (typeof (json) === 'string') {
            json = JSON.parse(json);
          }
          let max = json['max'];
          let min = json['min'];
          if (max === 0 && min === 0) {
            return {"required": true};
          }
        }
      }
    };
  }

  static referenceFormatValidator(includedCharacter: string[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value !== null) {
        if (control.value !== undefined && control.value.length > 0) {
          if (includedCharacter.includes(control.value.charAt(0))) {
            return {"invalidReferenceFormat": {message: "' " + control.value.charAt(0) + " ' is not allowed at first position"}};
          }
          if (includedCharacter.includes(control.value.charAt(control.value.length - 1))) {
            return {"invalidReferenceFormat": {message: "' " + control.value.charAt(control.value.length - 1) + " ' is not allowed at last position"}}
          }
        }
      }
    };
  }

  static decimalValidator(required: boolean = false): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value !== null) {
        if (control.value !== undefined && control.value.length > 0) {
          return {"invalidReferenceFormat": {message: "' " + control.value.charAt(0) + " ' is not allowed at first position"}};
        } else {
          return {"required": required};
        }
      }
    };
  }

  static regexValidator(pattern: RegExp,invalidmessage :string ="Invalid Input"): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value !== null && control.value !== undefined && control.value.length>0) {
        if (!(pattern).test(control.value )) {
          return {"Invalid":invalidmessage};
        }
      }
      return null;
    };
  }

  static phoneNumberValidator(errorMessage:string = 'Invalid Phone Number'): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value !== null && control.value !== undefined && control.value.length>0) {
        let val:string = control.value;
        val = val.replace(' ','');
        if (!(new RegExp('^(?:\\+\\d\\d)?\\d{10}$')).test(val.replace(' ',''))) {
          return {"Invalid":errorMessage};
        }
      }
      return null;
    };
  }

}
