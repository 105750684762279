<p-dialog (onHide)="onFormCancel()"
          [(visible)]="dialogDisplay"
          [closeOnEscape]="true"
          [baseZIndex]="9999"
          [draggable]="false"
          [positionTop]="96"
          [maximizable]="false" [modal]="true" [showHeader]="false"
          [style]="{zIndex:1008,width: '60%',height: '95%',minHeight:'90%',maxHeight:'90%', padding:'0px', background:'#f8f8f8'}">
    <div class="sectionCardTop">
        <p-contextMenu #cm [model]="contextMenuList" appendTo="body" styleClass="menucus"></p-contextMenu>
        <div class="headerplannedForm">
            <p class="title">{{planObligationValue['plannedObligationId']}}</p>
            <p style="position: absolute; right: 20px">
                <i (click)="onFormCancel()"
                   class="material-icons closable rightPosClose">clear</i>Close
            </p>
        </div>
        <div>
            <div class="rightPos">
<!--                <button class="amendButton" [disabled]="isDisable" (click)="openDialogBox()">Amend</button>-->
                <button class="documentButton" [disabled]="isDisable" (click)="openGenerateDocumentBox()">Generate
                    Document
                </button>
                <div class="exportButton">
                    <i style="color: #15104E !important; padding: 5px" class="fas fa-file-export"></i>
                    <p class="exporttitle">Export</p>
                </div>
            </div>
            <p-tabView>
                <p-tabPanel leftIcon="far fa-file" header="Amend Obligation">
                    <div id="sectionForm" class="mainBox style-4" inViewport (inViewportAction)="calculateHeight()">
                        <div class="bodyContent">
                            <form [formGroup]="amendForm">
                                <div *ngIf="showForAmend" style="height: 50%;">
                                    <!--                    Editable fields-->
                                    <div class="sectionBoxMain">
                                        <!--                                        Contract Details-->
                                        <div class="formSectionTitle">
                                            <div class="headerTitle">Contract Details</div>
                                        </div>
                                        <div class="row rowClass">
                                            <div class="col-md-3 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <p class="fieldName">Trade Date</p>
                                                    <div class="inputbox">
                                                        <input formControlName="tradeDateTime"
                                                               [bsConfig]="bsConfig"
                                                               autocomplete="off"
                                                               bsDatepicker class="form-control form-control-sm"
                                                               placeholder="Select Date" dis
                                                               type="text">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <p class="fieldName">Profit Center</p>
                                                    <p-dropdown [id]="'profitcenter'"
                                                                [options]="profitcenterList"
                                                                formControlName="profitcenter"
                                                                [disabled]="declarePort">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="col-md-3 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <p class="fieldName">Counterparty</p>
                                                    <p-dropdown [id]="'counterparty'"
                                                                [options]="counterpartyList"
                                                                (ngModelChange)="onChangeCounterParty()"
                                                                formControlName="counterparty" [disabled]="declarePort">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="col-md-3 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <p class="fieldName">Incoterm</p>
                                                    <p-dropdown [id]="'incoterm'"
                                                                [options]="incotermList"
                                                                formControlName="incoterm" [disabled]="declarePort">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="col-md-3 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <p class="fieldName">Broker</p>
                                                    <p-dropdown [id]="'broker'"
                                                                [options]="brokerList"
                                                                formControlName="broker" [disabled]="declarePort">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="col-md-3 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <p class="fieldName">Broker Reference</p>
                                                    <input id="brokerReference" type="text"
                                                           class="form-control form-control-sm input"
                                                           pInputText placeholder="Broker Reference"
                                                           formControlName="brokerReference">
                                                </div>
                                            </div>
                                        </div>
                                        <!--                                        Commodity Details-->
                                        <div class="formSectionTitle">
                                            <div class="headerTitle">Commodity Details</div>
                                        </div>
                                        <div class="row rowClass">
                                            <div class="col-md-3 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <p class="fieldName">Commodity</p>
                                                    <p-dropdown [id]="'commodity'"
                                                                [options]="commodityList"
                                                                (ngModelChange)="onChangeCommodity()"
                                                                formControlName="commodity"
                                                                [disabled]="declarePort">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="col-md-3 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <p class="fieldName">Quantity</p>
                                                    <div class="div-control">
                                                        <input id="plannedQuantity" type="number"
                                                               (input)="commonService.updateInputValidation($event,includedCharacters,true)"
                                                               class="form-control form-control-sm input borderRadius"
                                                               pInputText
                                                               formControlName="plannedQuantity"
                                                        >
                                                        <!--                                                           <p class="cfInnerTableField currencyBox" style="white-space: nowrap">-->
                                                        <!--                                                            {{getQuantityUom()}}-->
                                                        <!--                                                        </p>-->

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <p class="fieldName">UOM</p>
                                                    <div class="div-control">
                                                        <p-dropdown [id]="'quantityUOM'"
                                                                    [options]="UomList"
                                                                    formControlName="quantityUOM"
                                                                    [disabled]="declarePort">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-3 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <p class="fieldName">Brand</p>
                                                    <p-dropdown [id]="'brand'"
                                                                [options]="brandList"
                                                                formControlName="brand" [disabled]="declarePort">
                                                    </p-dropdown>
                                                </div>
                                            </div>

                                            <div class="col-md-3 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <p class="fieldName">Grade</p>
                                                    <p-dropdown [id]="'grade'"
                                                                [options]="gradeList"
                                                                formControlName="grade" [disabled]="declarePort">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="col-md-3 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <p class="fieldName">Season</p>
                                                    <p-dropdown [id]="'season'"
                                                                [options]="seasonList"
                                                                formControlName="season"
                                                                [disabled]="declarePort">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="col-md-3 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <p class="fieldName">Origin</p>
                                                    <p-dropdown [id]="'cropOrigin'"
                                                                [options]="cropOriginList"
                                                                formControlName="cropOrigin"
                                                                [disabled]="declarePort">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="col-md-12 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <div style="display: inline-flex">
                                                        <p class="fieldName">Quantity Terms clause</p>
                                                    </div>
                                                    <input id="quantityTermsClause" type="text"
                                                           formControlName="quantityTermsClause"
                                                           class="form-control form-control-sm inputRemark"
                                                           pInputText>
                                                </div>
                                            </div>
                                            <div class="col-md-12 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <div style="display: inline-flex">
                                                        <p class="fieldName">Quality Terms clause</p>
                                                    </div>
                                                    <input id="qualityTermsClause" type="text"
                                                           formControlName="qualityTermsClause"
                                                           class="form-control form-control-sm inputRemark"
                                                           pInputText>
                                                </div>
                                            </div>
                                        </div>
                                        <!--                                        Delivery Details-->
                                        <div class="formSectionTitle">
                                            <div class="headerTitle">Delivery Details</div>
                                        </div>
                                        <div class="row rowClass">
                                            <div class="col-md-3 controlGap contentbox">
                                                <div class="fieldBox loadLocation">
                                                    <p class="fieldName">Load Location</p>
                                                    <p-dropdown [id]="'loadLocation'"
                                                                [options]="loadLocationList"
                                                                formControlName="loadLocation">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="col-md-3 controlGap contentbox">
                                                <div class="fieldBox loadLocation">
                                                    <p class="fieldName">Dischange Location</p>
                                                    <p-dropdown [id]="'dischargeLocation'"
                                                                [options]="dischangeLoacationList"
                                                                formControlName="unloadLocation">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="col-md-3 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <p class="fieldName">Delivery Start Date</p>
                                                    <div class="inputbox">
                                                        <input formControlName="deliveryStartDate"
                                                               [bsConfig]="bsConfig"
                                                               autocomplete="off"
                                                               bsDatepicker class="form-control form-control-sm"
                                                               placeholder="Select Date"
                                                               type="text">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <p class="fieldName">Delivery End Date</p>
                                                    <div class="inputbox">
                                                        <input formControlName="deliveryEndDate"
                                                               [bsConfig]="bsConfig"
                                                               [minDate]="amendForm.controls['deliveryStartDate'].value"
                                                               autocomplete="off"
                                                               bsDatepicker class="form-control form-control-sm"
                                                               placeholder="Select Date"
                                                               type="text">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <p class="fieldName">Delivery Term Clause</p>
                                                    <input id="deliveryTermClause" type="text"
                                                           formControlName="deliveryTermClause"
                                                           (input)="commonService.updateInputValidation($event,includedCharacters,true)"
                                                           (ngModelChange)="onChangeDeliveryTermClause()"
                                                           class="form-control form-control-sm inputRemark"
                                                           pInputText>
                                                </div>

                                            </div>
                                        </div>
                                        <!--Price And Settlement Details-->
                                        <div class="formSectionTitle">
                                            <div class="headerTitle">Price & Settlement Details</div>
                                        </div>
                                        <div class="row rowClass">
                                            <div class="col-md-3 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <p class="fieldName">Trade Price</p>
                                                    <div class="div-control" style="width:100%">
                                                        <input id="tradePrice" type="number"
                                                               (input)="commonService.updateInputValidation($event,includedCharacters,true)"
                                                               class="form-control form-control-sm input borderRadius"
                                                               pInputText
                                                               formControlName="tradePrice">
                                                        <!--                                                        <p class="cfInnerTableField currencyBox" style="white-space: nowrap">-->
                                                        <!--                                                            {{getPriceCurrency()}}-->
                                                        <!--                                                        </p>-->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <p class="fieldName">Trade Price UOM</p>
                                                    <div class="div-control">
                                                        <p-dropdown [id]="'tradePriceUOM'"
                                                                    [options]="UomList"
                                                                    formControlName="tradePriceUom"
                                                                    [disabled]="declarePort">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <p class="fieldName">Trade Price Currency</p>
                                                    <div class="div-control">
                                                        <p-dropdown [id]="'tradePriceCurrency'"
                                                                    [options]="provisionalPriceCurrenyType"
                                                                    formControlName="tradePriceCurrency"
                                                                    [disabled]="declarePort">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 controlGap contentbox" style="margin-left: 10px">
                                                <div class="fieldBox">
                                                    <p class="fieldName">Provisional Price Type</p>
                                                    <p-dropdown [id]="'provisionalPriceType'"
                                                                [options]="provisionalPriceList"
                                                                (ngModelChange)="onChangeProvisionalPrice($event)"
                                                                formControlName="provisionalPriceType"
                                                                [disabled]="declarePort">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="col-md-3 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <p class="fieldName">Provisional Price</p>
                                                    <div class="div-control" style="width:100%">
                                                        <input id="provisionalPrice" type="number"
                                                               (input)="commonService.updateInputValidation($event,includedCharacters,true)"
                                                               class="form-control form-control-sm input"
                                                               pInputText placeholder="Provisional Price"
                                                               formControlName="provisionalPrice">
                                                        <!--                                                           <p class="cfInnerTableField currencyBox" style="white-space: nowrap">-->
                                                        <!--                                                            {{getPriceCurrency()}}-->
                                                        <!--                                                        </p>-->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <p class="fieldName">Provisional Price UOM</p>
                                                    <div class="div-control">
                                                        <p-dropdown [id]="'provisionalPriceUom'"
                                                                    [options]="UomList"
                                                                    formControlName="provisionalPriceUom"
                                                                    [disabled]="declarePort">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <p class="fieldName">Provisional Price Currency</p>
                                                    <p-dropdown [id]="'provisionalPriceCurrency'"
                                                                [options]="provisionalPriceCurrenyType"
                                                                formControlName="provisionalPriceCurrency"
                                                                [disabled]="declarePort">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="col-md-6 controlGap contentbox">
                                                <div class="fieldBox" formGroupName="settlementToleranceValue">
                                                    <p class="fieldName">Settlement Tolerance Value</p>
                                                    <div class="slider-wrapper-1">
                                                        <div class="range-1">{{minRange}} / {{amendForm.get('settlementToleranceValue').value['min']}}</div>
                                                        <input class="slider1" type="range" min="0" max="100"  formControlName="min">
                                                        <div class="separator"></div>
                                                        <input class="slider2" type="range" min="0" max="100"  formControlName="max" >
                                                        <div class="range-2">{{maxRange}} / {{amendForm.get('settlementToleranceValue').value['max']}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <div style="display: inline-flex">
                                                        <p class="fieldName">Payment Terms clause</p>
                                                    </div>
                                                    <input id="paymentTermsClause" type="text"
                                                           formControlName="paymentTermsClause"
                                                           class="form-control form-control-sm inputRemark"
                                                           pInputText>
                                                </div>
                                            </div>
<!--                                            <div class="col-md-3 controlGap contentbox">-->
<!--                                                <div class="fieldBox">-->
<!--                                                    <p class="fieldName">Provisional Price Percentage</p>-->
<!--                                                    <input id="provisionalPricePercentage" type="number"-->
<!--                                                           (input)="commonService.updateInputValidation($event,includedCharacters,true)"-->
<!--                                                           class="form-control form-control-sm input"-->
<!--                                                           pInputText placeholder="Provisional Percentage"-->
<!--                                                           formControlName="provisionalPricePercentage">-->
<!--                                                </div>-->
<!--                                            </div>-->
                                            <div class="col-md-3 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <p class="fieldName">Settlement Currency</p>
                                                    <p-dropdown [id]="'tradeSettlementCurrency'"
                                                                [options]="settlementCurrencyList"
                                                                formControlName="tradeSettlementCurrency"
                                                                [disabled]="declarePort">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="col-md-3 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <p class="fieldName">Fx Rate</p>
                                                    <input id="fxRate" type="number"
                                                           (input)="commonService.updateInputValidation($event,includedCharacters,true)"
                                                           class="form-control form-control-sm input"
                                                           pInputText placeholder="Fx Rate"
                                                           formControlName="fxRate">
                                                </div>
                                            </div>
                                            <div class="col-md-3 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <p class="fieldName">Payment Term</p>
                                                    <p-dropdown [id]="'paymentTerm'"
                                                                [options]="paymentTermList"
                                                                (ngModelChange)="onChangePaymentTerm()"
                                                                formControlName="paymentTerm" [disabled]="declarePort">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="col-md-3 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <p class="fieldName"> Premium cost</p>
                                                    <input id="premiunCost" type="number"
                                                           (input)="commonService.updateInputValidation($event,includedCharacters,true)"
                                                           class="form-control form-control-sm input"
                                                           pInputText placeholder="Premium cost"
                                                           formControlName="premiunCost">
                                                </div>
                                            </div>
                                            <div class="col-md-3 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <p class="fieldName">Discount cost</p>
                                                    <input id="discountCost" type="number"
                                                           (input)="commonService.updateInputValidation($event,includedCharacters,true)"
                                                           class="form-control form-control-sm input"
                                                           pInputText placeholder="Discount cost"
                                                           formControlName="discountCost">
                                                </div>
                                            </div>
                                            <div class="col-md-12 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <div style="display: inline-flex">
                                                        <p class="fieldName">Pricing Terms clause</p>
                                                    </div>
                                                    <input id="pricingTermsClause" type="text"
                                                           formControlName="pricingTermsClause"
                                                           class="form-control form-control-sm inputRemark"
                                                           pInputText>
                                                </div>
                                            </div>
                                            <div class="col-md-12 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <div style="display: inline-flex">
                                                        <p class="fieldName">Remarks / Special Instructions</p>
                                                    </div>
                                                    <input id="remarks" type="text"
                                                           formControlName="remarks"
                                                           class="form-control form-control-sm inputRemark"
                                                           (ngModelChange)="onChangeRemarks()"
                                                           pInputText>
                                                </div>
                                            </div>
                                            <div class="col-md-12 controlGap contentbox">
                                                <div class="fieldBox">
                                                    <div style="display: inline-flex">
                                                        <p class="fieldName">Comments</p>
                                                    </div>
                                                    <input id="comments" type="text"
                                                           formControlName="comments"
                                                           class="form-control form-control-sm inputRemark"
                                                           pInputText>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="formSectionTitle" *ngIf="!declarePort">
                                            <div class="headerTitle">LC Details</div>
                                        </div>
                                        <div style="padding-top: 15px" *ngIf="!declarePort">
                                            <app-ctrm-price-table (onAddNewRowComplete)="onNewRowAddComplete()"
                                                                  (onRefreshFormGroupComplete)="onRefreshFromGroupComplete()"
                                                                  [columns]="lcDetailsCols" [gridDisplay]="true"
                                                                  (onFormGroupCreated)="onFormGroupCreated($event)"
                                                                  [checkValidityBeforeAddingNewRow]="true"
                                                                  [refreshFormGroup]="refreshFormGroup"
                                                                  [newRows]="newRows"
                                                                  [refreshNewRows]="refreshAddNewRow"
                                                                  [value]="lcValue"
                                                                  [showNewEntryIcon]="true">
                                            </app-ctrm-price-table>
                                        </div>
                                        <div style="width: 100%;text-align: right;">
                                            <button class="cancelBtn" (click)="onCloseDialog()">Cancel</button>
                                            <button class="planScreenBtns" style="color: white"

                                                    (click)="saveAmendDetails()">Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <!--            Toggle Fields-->
                            <div class="attributes">
                                <div class="showHeader" (click)="tradeToggle()">
                                    <i class="iconClass"
                                       [ngClass]="this.showTrade?'fas fa-caret-down':'fas fa-caret-right'"></i>
                                    <p style="font-weight: 600;">Contract Attributes</p>
                                </div>
                                <div *ngIf="showTrade">
                                    <div class="row rowClass">
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Trade Id</p>
                                                <p class="bodyName">{{planObligationValue['tradeId']}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Trade Name</p>
                                                <p class="bodyName">{{planObligationValue['trader']}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Trade Date</p>
                                                <p class="bodyName">{{planObligationValue['tradeDateTime'] | date:'dd MMM yyyy'}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Counterparty</p>
                                                <p class="bodyName">{{planObligationValue['counterparty']}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Company</p>
                                                <p class="bodyName">{{planObligationValue['company']}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Profit Centre</p>
                                                <p class="bodyName">{{planObligationValue['profitcenter']}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Trade Type</p>
                                                <p class="bodyName">{{planObligationValue['tradeTransactionType']}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Broker</p>
                                                <p class="bodyName">{{planObligationValue['broker']}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Broker Reference</p>
                                                <p class="bodyName">{{planObligationValue['brokerReference']}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="attributes">
                                <div class="showHeader" (click)="commodityToggle()">
                                    <i class="iconClass"
                                       [ngClass]="this.showCommodity?'fas fa-caret-down':'fas fa-caret-right'"></i>
                                    <p style="font-weight: 600;">Commodity Attributes</p>
                                </div>
                                <div *ngIf="showCommodity">
                                    <div class="row rowClass">
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Commodity</p>
                                                <p class="bodyName">{{planObligationValue['commodity']}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Quantity</p>
                                                <p class="bodyName">{{planObligationValue['tradeQuantity'] + ' ' + planObligationValue['quantityUOM']}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Planned Quantity</p>
                                                <p class="bodyName">{{planObligationValue['plannedQuantity'] + ' ' + planObligationValue['quantityUOM']}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Grade</p>
                                                <p class="bodyName">{{planObligationValue['grade'] === null || planObligationValue['grade'] === "" ? '-' : planObligationValue['grade']}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Brand</p>
                                                <p class="bodyName">{{planObligationValue['brand'] === null || planObligationValue['brand'] === "" ? '-' : planObligationValue['brand']}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Season</p>
                                                <p class="bodyName">{{planObligationValue['season'] === null || planObligationValue['season'] === "" ? '-' : planObligationValue['season']}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Origin</p>
                                                <p class="bodyName">{{planObligationValue['cropOrigin'] === null || planObligationValue['cropOrigin'] === "" ? '-' : planObligationValue['cropOrigin']}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Quantity Terms clause</p>
                                                <p class="bodyName">{{planObligationValue['quantityTermsClause'] === null || planObligationValue['quantityTermsClause'] === "" ? '-' : planObligationValue['quantityTermsClause']}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Quality Terms clause</p>
                                                <p class="bodyName">{{planObligationValue['qualityTermsClause'] === null || planObligationValue['qualityTermsClause'] === "" ? '-' : planObligationValue['qualityTermsClause']}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="attributes">
                                <div class="showHeader" (click)="DeliveryToggle()">
                                    <i class="iconClass"
                                       [ngClass]="this.showDelivery?'fas fa-caret-down':'fas fa-caret-right'"></i>
                                    <p style="font-weight: 600;">Delivery Attributes</p>
                                </div>
                                <div *ngIf="showDelivery">
                                    <div class="row rowClass">
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Load Location</p>
                                                <p class="bodyName">{{planObligationValue['loadLocation'] === null ||
                                                planObligationValue['loadLocation'] === "" ? '-' : planObligationValue['loadLocation']}}</p>

                                            </div>
                                        </div>
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">UnLoad Location</p>
                                                <p class="bodyName">{{planObligationValue['unloadLocation'] === null ||
                                                planObligationValue['unloadLocation'] === "" ? '-' : planObligationValue['unloadLocation']}}</p>

                                            </div>
                                        </div>
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Delivery Start Date</p>
                                                <p class="bodyName">{{planObligationValue['deliveryStartDate'] | date:'dd MMM yyyy'}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Delivery End Date</p>
                                                <p class="bodyName">{{planObligationValue['deliveryEndDate'] | date:'dd MMM yyyy'}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-12 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Delivery Term Clause</p>
                                                <p class="bodyName">{{planObligationValue['deliveryTermClause']}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="attributes">
                                <div class="showHeader" (click)="ProvisionalToggle()">
                                    <i class="iconClass"
                                       [ngClass]="this.showProvisional?'fas fa-caret-down':'fas fa-caret-right'"></i>
                                    <p style="font-weight: 600;">Price & Settlement Attributes</p>
                                </div>
                                <div *ngIf="showProvisional">
                                    <div class="row rowClass">
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Price</p>
                                                <p class="bodyName">{{planObligationValue['tradePrice'] === null || planObligationValue['tradePrice'] === "" ? '-' : (planObligationValue['tradePrice'] + ' ' + planObligationValue['tradePriceCurrency'])}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Price UOM</p>
                                                <p class="bodyName">{{planObligationValue['tradePriceUom'] === null || planObligationValue['tradePriceUom'] === "" ? '-' : planObligationValue['tradePriceUom']}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Provisional Price Type</p>
                                                <p class="bodyName">{{planObligationValue['provisionalPriceType'] === null || planObligationValue['provisionalPriceType'] === "" ? '-' : planObligationValue['provisionalPriceType']}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Provisional Price</p>
                                                <p class="bodyName">{{getProvisionalPriceValue()}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Provisional Price UOM</p>
                                                <p class="bodyName">{{planObligationValue['provisionalPriceUom'] === null || planObligationValue['provisionalPriceUom'] === "" ? '-' : planObligationValue['provisionalPriceUom']}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Provisional Price Currency</p>
                                                <p class="bodyName">{{planObligationValue['provisionalPriceCurrency'] === null || planObligationValue['provisionalPriceCurrency'] === "" ? '-' : planObligationValue['provisionalPriceCurrency']}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Payment term</p>
                                                <p class="bodyName">{{planObligationValue['paymentTerm']}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Settlement Currency</p>
                                                <p class="bodyName">{{planObligationValue['tradeSettlementCurrency']}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">FX Rate</p>
                                                <p class="bodyName">{{planObligationValue['fxRate']}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-12 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Pricing Terms clause</p>
                                                <p class="bodyName">{{planObligationValue['pricingTermsClause']}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-12 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Remarks / Special Instructions</p>
                                                <p class="bodyName">{{planObligationValue['remarks']}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-12 controlGap contentbox">
                                            <div class="fieldBox">
                                                <p class="fieldName">Comments</p>
                                                <p class="bodyName">{{planObligationValue['comments']}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="attributes">
                                    <div class="showHeader" (click)="lcToggle()">
                                        <i class="iconClass"
                                           [ngClass]="this.showLc?'fas fa-caret-down':'fas fa-caret-right'"></i>
                                        <p style="font-weight: 600;">LC Details</p>
                                    </div>
                                    <div *ngIf="showLc">
                                        <xceler-grid [columns]="lcDisplayColumns" [data]="lcDisplayData"></xceler-grid>
                                    </div>
                                </div>
                                <div class="row rowClass">
                                    <div class="col-md-3 controlGap contentbox">
                                        <div class="fieldBox">
                                            <p class="fieldName">Created By</p>
                                            <p class="bodyName">{{planObligationValue['createdBy']}}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-3 controlGap contentbox">
                                        <div class="fieldBox">
                                            <p class="fieldName">Created Date</p>
                                            <p class="bodyName">{{planObligationValue['createdTimestamp'] | date: 'dd MMM yyyy'}}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-3 controlGap contentbox">
                                        <div class="fieldBox">
                                            <p class="fieldName">Updated By</p>
                                            <p class="bodyName">{{planObligationValue['updatedBy']}}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-3 controlGap contentbox">
                                        <div class="fieldBox">
                                            <p class="fieldName">Updated Date</p>
                                            <p class="bodyName">{{planObligationValue['updatedTimestamp'] | date: 'dd MMM yyyy'}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p-dialog [(visible)]="amendConfirmDialog"
                                  [closable]="true"
                                  [draggable]="false"
                                  [modal]="true"
                                  [maximizable]="false"
                                  [showHeader]="false"
                                  [style]="{ width: '30%',height: '20%',maxHeight:'20%', padding:'0px', background:'#ffffff',boxShadow:'0 1px 3px 0 rgba(0, 0, 0, 0.12)'}">
                            <div style="padding: 20px 25px">
                                <p class="dialogHeaderTitle">Amend Confirmation</p>
                            </div>
                            <div style="padding: 0px 35px">
                                <p style="font-size: 14px; color: #16144E; font-weight: 600">You are trying to Amend a
                                    Confirmed Trade Obligation.<br>
                                    Do you wish to continue?</p>
                            </div>
                            <p-footer>
                                <button class="cancelBtn" (click)="onCloseDialog()">No</button>
                                <button class="planScreenBtns" style="color: white" (click)="openAmendForm()">Yes
                                </button>
                            </p-footer>
                        </p-dialog>

                        <!--                        Docuemnt To Be Selected-->
                        <p-dialog [(visible)]="generateAddendumDocuement"
                                  [closeOnEscape]="false"
                                  (onHide)="closeModal()"
                                  [draggable]="false"
                                  [maximizable]="false" [modal]="true" [showHeader]="false"
                                  [style]="{ width: '20%',height: '23%',maxHeight:'23%', padding:'0px', background:'#ffffff',boxShadow:'0 1px 3px 0 rgba(0, 0, 0, 0.12)'}">
                            <div style="padding: 20px 25px">
                                <p class="dialogHeaderTitle">Addendum Document</p>
                            </div>
                            <div class="form-content">
                                <div class="item-container">
                                    <div class="fieldBox" style="padding-left:20px; padding-right:30px ">
                                        <p class="fieldName" style="padding-left: 10px;font-size:14px">Document
                                            Template</p>
                                        <p-dropdown [id]="'documentTemplate'"
                                                    [options]="documentTemplateList"
                                                    [(ngModel)]="documentTemplate"
                                                    (ngModelChange)="getDocFormatList()"
                                                    placeholder="Select Document Template">
                                        </p-dropdown>
                                    </div>
                                </div>
                                <div class="item-container">
                                    <div class="fieldBox" style="padding-left:20px; padding-right:30px ">
                                        <p class="fieldName" style="padding-left: 10px;font-size:14px">File Format
                                            <span class="mandatory-span"></span></p>
                                        <p-dropdown [disabled]="allowFormatEnable" [(ngModel)]="documentFormat"
                                                    [placeholder]="'Select Document Format'"
                                                    [appendTo]="'body'"
                                                    [options]="documentFormatList"></p-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="generate-doc-footer">
                                <button class="cancelBtn" (click)="closeModal()">Cancel</button>
                                <button class="generateBtn" [disabled]="disableGenerate()"
                                        style="padding-left: 20px;padding-right: 20px" (click)="generateDocument()">
                                    Generate
                                </button>
                            </div>

                        </p-dialog>

                        <!--SUMMARY DIALOG-->
                        <p-dialog [(visible)]="loadingDialogDisplay" [draggable]="false" [modal]="true"
                                  [closable]="false"
                                  [resizable]="false" [style]="{width: '35vw'}">

                            <div style="margin: 20px; margin-left: 40px; height: 250px ">
                                <!--    Loading DIV-->
                                <div class="loadingDiv">
                                    <img class="loadingIconClass" src="../../../assets/images/doubleRingLoading.gif"
                                         alt="">
                                    <p class="loadingMsg">Generating document. Please wait, this may take a while.</p>
                                </div>
                            </div>
                        </p-dialog>
                    </div>
                </p-tabPanel>
                <p-tabPanel *ngIf="!declarePort" leftIcon="fa fa-paperclip" style="color: #2b215e;"
                            header="Attachments">
                    <attachment [data]="planObligationValue"></attachment>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>


</p-dialog>

<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>

