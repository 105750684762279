<div class="sectionCardTop">
    <div class="breadcrumbPane">
        <ctrm-breadcrumb [collapsabel]="false"
                         [items]="breadCrumbService.getBreadCrumb()"
                         [showIcon]="false"></ctrm-breadcrumb>
    </div>
    <div class="actionPane">
        <div class="screenDetails">
            <img [src]="'../../../assets/images/svg/priceIndex.svg'"/>
            <p>POS</p>
        </div>
        <div class="autocomplete-box">
            <input placeholder="Search" type="text">
            <mat-icon style="color:#cbcbcb">search</mat-icon>
        </div>
    </div>
</div>


<div class="wrapper">
    <div class="leftSection">
        <div class="buttonsContainer">

            <div class="leftBtns">
                <div class="btnDiv btnOrange"
                     [ngClass]="selectedStorage.length === 1 ?['btnDiv','btnOrange']:['btnDiv','btnDisabled']"
                     (click)="onCreatePos()">
                    <i class="fas fa-plus btnIcon"></i>
                    <p>Create New POS</p>
                </div>
            </div>

        </div>
        <div style="display: inline-flex;padding-left: 20px;">
            <div class="fieldBox">
                <p class="fieldName">Inventory Name</p>
                <p-dropdown
                        (ngModelChange)="applyFilter('storageId',$event)"
                        [(ngModel)]="storageId"
                        [disabled]="readOnly" [id]="'storageId'"
                        [options]="storageInventoryOptions"
                        appendTo="body"
                        pTooltip="{{getTooltipMessage(formControlAIO,field)}}" tooltipEvent="focus"
                        tooltipPosition="bottom"
                        tooltipZIndex="1000"></p-dropdown>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Inventory Location</p>
                <p-dropdown
                        (ngModelChange)="applyFilter('location',$event)"
                        [(ngModel)]="location"
                        [disabled]="readOnly" [id]="'location'"
                        [options]="inventoryLocationOptions"
                        appendTo="body"
                        pTooltip="{{getTooltipMessage(formControlAIO,field)}}" tooltipEvent="focus"
                        tooltipPosition="bottom"
                        tooltipZIndex="1000"></p-dropdown>
            </div>
            <div class="fieldBox">
                <p class="fieldName">Commodity</p>
                <p-dropdown
                        (ngModelChange)="applyFilter('commodity',$event)"
                        [(ngModel)]="commodity"
                        [disabled]="readOnly" [id]="'commodity'"
                        [options]="inventoryCommodityOptions"
                        appendTo="body"
                        pTooltip="{{getTooltipMessage(formControlAIO,field)}}" tooltipEvent="focus"
                        tooltipPosition="bottom"
                        tooltipZIndex="1000"></p-dropdown>
            </div>

        </div>
        <div class="tableClass">
            <p-table #posTable (inViewportAction)="calculateHeight()"
                     [(selection)]="selectedStorage"
                     [autoLayout]="true"
                     [columns]="storageCols"
                     [style.minHeight]="storageData.length>0?posTableHeight:''"
                     [style]="{width: '100%', maxHeight: posTableHeight}"
                     [value]="storageData"
                     class="style-4 table-responsive" dataKey="storageId"
                     inViewport
                     selectionMode="single">
                <ng-template let-columns pTemplate="header">
                    <tr>
                        <th style="width: 3em">
                            <p style="background: #D9DBDE; height: 47px;margin: 0"></p>
                        </th>
                        <th *ngFor="let col of columns;index as i">
                            <div style="background-color: #D9DBDE; padding: 5px;padding-top: 13px; padding-bottom: 14px">
                                <p [ngClass]="getClassForHeading(i)">{{col.header}}</p>
                            </div>
                        </th>
                    </tr>
                </ng-template>
                <ng-template let-columns="columns" let-expanded="expanded" let-ri="rowIndex" let-rowData
                             pTemplate="body">
                    <tr>
                        <td class="customRow" style="width:3em;">
                            <div style="display: inline-flex">
                                <p class="plannedGridChkBox">
                                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                                </p>
                            </div>
                        </td>
                        <td *ngFor="let col of columns; index as i" class="customRow">
                            <p class="plannedGrid">
                                {{rowData[col.field]}}
                            </p>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <div *ngIf="storageData.length == 0 || storageData.length == null" style="text-align: center;">
                <div class="noPlans">No Storage Data Available</div>
                <div>
                    <button (click)="goTo()" class="planScreenBtns">Go to Storage Inventory</button>
                </div>
            </div>
        </div>
    </div>
    <div class="rightSection style-4">
        <div class="rsHeader">
            <p class="rsTitle">Recent POS</p>
            <div class="filterSec">
                <p class="filterTag">Filter</p>
            </div>
        </div>
        <div *ngFor="let recentPos of recentPos" class="posBox">
            <div class="boxContent">
                <div class="boxHeading">
                    <p class="boxTitle">Commodity: {{recentPos['commodity']}}</p>
                    <p class="boxTitle">Counterparty: {{recentPos['counterparty']}}</p>
                </div>
                <div class="ui-g">
                    <div class="propertyContainer">
                        <p class="propertyTitle">Sale Quantity</p>
                        <p class="propertyValue">{{recentPos['quantity']}}</p>
                    </div>
                    <div class="propertyContainer">
                        <p class="propertyTitle">No.Of Bags</p>
                        <p class="propertyValue">25</p>
                    </div>
                    <div class="propertyContainer">
                        <p class="propertyTitle">Sale Price</p>
                        <p class="propertyValue">{{recentPos['salePrize']}}</p>
                    </div>
                    <div class="propertyContainer">
                        <p class="propertyTitle">Sale Currency</p>
                        <p class="propertyValue">{{recentPos['saleCurrency']}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="recentPos.length == 0 || recentPos.length == null" style="text-align: center;">
            <div class="noPos">No POS Done recently</div>
        </div>

    </div>
</div>

<!--Dialog-->
<p-dialog #af [(visible)]="formDisplay" [baseZIndex]="25"
          [showHeader]="true"
          [header]="'New POS Invoice'"
          [closeOnEscape]="false"
          [closable]="false"
          [draggable]="false"
          [modal]="true"
          [maximizable]="false"
          [style]="{ width: '65%',height: '70%',maxHeight:'95%', padding:'0px', background:'#ffffff'}">

    <form [formGroup]="posForm">
        <div class="fieldSection">
            <p class="secHeader">New POS Request</p>
        </div>
        <div class="formBox"></div>

        <div class="sectionForm style-4" [id]="'sectionForm'">
            <div class="validContain">
                <div class="sectionBoxMain">
<!--                    first line-->
                    <div class="firstLine">
                        <div class="fieldBox">
                            <label class="label ng-star-inserted">Commodity</label>
                            <input id="name" type="text"
                                   disabled
                                   class="form-control form-control-sm"
                                   pInputText placeholder="Commodity"
                                   formControlName="commodity">
                        </div>
                        <div class="fieldBox">
                            <label class="label ng-star-inserted">Delivery Date</label>
                            <div style="display: flex">
                                <input [bsConfig]="bsConfig" bsDatepicker
                                       class="form-control form-control-sm pickerInput"
                                       formControlName="deliveryDate" pInputText
                                       type="text">
                                <i class="pi pi-calendar" style="font-size: 32px"></i>
                            </div>
                        </div>
                    </div>
<!--                    second line-->
                    <div class="nextLine">
                        <div class="fieldBox">
                            <label class="label ng-star-inserted">Sale Quantity</label>
                            <input id="saleQuantity" type="number"
                                   (input)="commonService.updateInputValidation($event,includedCharacters,true)"
                                   class="form-control form-control-sm input"
                                   pInputText placeholder="Sale Quantity"
                                   formControlName="saleQuantity">
                        </div>
                        <div class="fieldBox">
                            <label class="label ng-star-inserted">Storage UOM</label>
                            <input id="storageUom" type="text"
                                   disabled
                                   class="form-control form-control-sm"
                                   pInputText placeholder="storageUom"
                                   formControlName="storageUom">
                        </div>
                        <div class="fieldBox">
                            <label class="label ng-star-inserted">No.Of Bags</label>
                            <input id="noOfBags" type="number"
                                   (input)="commonService.updateInputValidation($event,includedCharacters,true)"
                                   class="form-control form-control-sm input"
                                   pInputText placeholder="No. of Bags"
                                   formControlName="noOfBags">
                        </div>
                    </div>
<!--                    Third line-->
                    <div class="nextLine">
                        <div class="fieldBox">
                            <label class="label ng-star-inserted">Draw Price</label>
                            <input id="drawPrice" type="text"
                                   disabled
                                   class="form-control form-control-sm"
                                   pInputText placeholder="drawPrice"
                                   formControlName="drawPrice">
                        </div>
                        <div class="fieldBox">
                            <label class="label ng-star-inserted">Sale Price</label>
                            <input id="salePrice" type="number"
                                   (input)="commonService.updateInputValidation($event,includedCharacters,true)"
                                   class="form-control form-control-sm input"
                                   pInputText placeholder="Sale Price"
                                   formControlName="salePrice">
                        </div>
                    </div>
<!--                    Forth line-->
                    <div class="nextLine">
                        <div class="fieldBox">
                            <label class="label ng-star-inserted">Counterparty</label>
                            <p-dropdown (ngModelChange)="throwCounterpartyChanges($event)"
                                        [disabled]="false"
                                        [id]="'counterparty'"
                                        [options]="counterpartyList"
                                        appendTo="body"
                                        tooltipEvent="focus" tooltipPosition="bottom"
                                        tooltipZIndex="1000"
                                        formControlName="counterparty">
                            </p-dropdown>
                            <div style="padding: 8px; padding-bottom:0; display: flex; cursor : pointer"
                                 (click)="onClickAdd()"><i
                                    class="fas fa-plus addIcon"></i>
                                <p class="addText"> Add </p>
                            </div>
                        </div>
                        <div class="fieldBox">
                            <label class="label ng-star-inserted">Primary Account Number</label>
                            <input id="primaryAccountNumber" type="text"
                                   disabled
                                   class="form-control form-control-sm"
                                   pInputText placeholder="Account Number"
                                   formControlName="primaryAccountNumber">
                        </div>
                        <div class="fieldBox">
                            <label class="label ng-star-inserted">Address</label>
                            <input id="address" type="text"
                                   disabled
                                   class="form-control form-control-sm"
                                   pInputText placeholder="Address"
                                   formControlName="address">
                        </div>
                        <div class="fieldBox">
                            <label class="label ng-star-inserted">Sale Currency</label>
                            <input id="saleCurrency" type="text" disabled
                                   class="form-control form-control-sm"
                                   pInputText placeholder="saleCurrency"
                                   formControlName="saleCurrency">
                        </div>
                    </div>
<!--                    Fifth line-->
                    <div class="nextLine">
                        <div class="fieldBox">
                            <label class="label ng-star-inserted">Payment Term</label>
                            <p-dropdown [disabled]="false"
                                        [id]="'paymentTerm'"
                                        [options]="paymentTermList"
                                        appendTo="body"
                                        tooltipEvent="focus" tooltipPosition="bottom"
                                        tooltipZIndex="1000"
                                        formControlName="paymentTerm">
                            </p-dropdown>
                        </div>
                        <div class="fieldBox">
                            <label class="label ng-star-inserted">Special Instructions</label>
                            <input id="specialInstructions" type="text"
                                   class="form-control form-control-sm input"
                                   pInputText placeholder="Special Instructions"
                                   formControlName="specialInstructions">
                        </div>
                    </div>
                </div>

<!--                Validation-->
                <div class="valContainer">
                    <div class="validation">
                        <div style="display: flex; padding-bottom: 10px;">
                            <i [ngClass]="getIconFromValidations(posForm,'deliveryDate')"></i>
                            <p class="valPoints">Delivery Date</p>
                        </div>
                        <div style="display: flex; padding-bottom: 10px">
                            <i [ngClass]="getIconFromValidations(posForm,'saleQuantity')"></i>
                            <p class="valPoints">Sale Quantity</p>
                        </div>
                        <div style="display: flex; padding-bottom: 10px">
                            <i [ngClass]="getIconFromValidations(posForm,'storageUom')"></i>
                            <p class="valPoints">Storage UOM</p>
                        </div>
                        <div style="display: flex; padding-bottom: 10px;">
                            <i [ngClass]="getIconFromValidations(posForm, 'salePrice')"></i>
                            <p class="valPoints">Sale Price</p>
                        </div>
                        <div style="display: flex; padding-bottom: 10px;">
                            <i [ngClass]="getIconFromValidations(posForm,'counterparty')"></i>
                            <p class="valPoints">Counterparty</p>
                        </div>
                        <div style="display: flex; padding-bottom: 10px;">
                            <i [ngClass]="getIconFromValidations(posForm,'primaryAccountNumber')"></i>
                            <p class="valPoints">Primary Account Number</p>
                        </div>
                        <div style="display: flex; padding-bottom: 10px;">
                            <i [ngClass]="getIconFromValidations(posForm,'saleCurrency')"></i>
                            <p class="valPoints">Sale Currency</p>
                        </div>
                        <div style="display: flex; padding-bottom: 10px;">
                            <i [ngClass]="getIconFromValidations(posForm,'paymentTerm')"></i>
                            <p class="valPoints">Payment Term</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <p-footer>
        <button class="cancelBtn" (click)="onClose()">Cancel</button>
        <button class="planScreenBtns" [disabled]="!posForm.valid" (click)="onCreate()">Create POS</button>
    </p-footer>
</p-dialog>

<!-- Add Counterparty Dialog-->
<p-dialog #af [(visible)]="addFormDisplay" [baseZIndex]="25"
          [showHeader]="false"
          [closeOnEscape]="false"
          [closable]="false"
          [draggable]="false"
          [modal]="true"
          [maximizable]="false"
          [style]="{ width: '45%',maxHeight:'95%', padding:'0px', background:'#ffffff'}">

    <form [formGroup] = "addCpForm">
        <div class="fieldSection">
            <div class="secHeader">
                Add new Counterparty
                <i class="material-icons closable" (click)="onAddClose()">clear</i>
            </div>
        </div>
        <div class="sectionForm style-4" [id]="'sectionForm'">
            <div class="sectionBoxMain">
                <div class="firstLine">
                    <div class="fieldBox">
                        <label class="label ng-star-inserted">Counterparty Name</label>
                        <input id="counterpartyname" type="text"
                               class="form-control form-control-sm"
                               pInputText placeholder="Counterparty Name"
                               formControlName="name">
                    </div>
                    <div class="fieldBox">
                        <label class="label ng-star-inserted">Primary Account Number</label>
                        <input id="accountNumber" type="text"
                               class="form-control form-control-sm"
                               pInputText placeholder="Account Number"
                               formControlName="primaryAccountNumber">
                    </div>
                    <div class="fieldBox">
                        <label class="label ng-star-inserted">Bank Name</label>
                        <input id="bankName" type="text"
                               class="form-control form-control-sm"
                               pInputText placeholder="Bank Name"
                               formControlName="bankName">
                    </div>
                </div>
                <div class="nextLine">
                    <div class="fieldBox">
                        <label class="label ng-star-inserted">Swift Code</label>
                        <input id="swiftCode" type="text"
                               class="form-control form-control-sm"
                               pInputText placeholder="Swift Code"
                               formControlName="swiftCode">
                    </div>
                    <div class="fieldBox">
                        <label class="label ng-star-inserted">Address</label>
                        <input id="counterpartyAddress" type="text"
                               class="form-control form-control-sm"
                               pInputText placeholder="Address"
                               formControlName="counterpartyAddress">
                    </div>
                </div>
            </div>
        </div>
    </form>


    <div style="margin-top: -40px;">
        <button class="cancelBtn" (click)="onAddClose()">Cancel</button>
        <button class="planScreenBtns" [disabled]="!addCpForm.valid" style="margin-top:0; margin-right: 0;width: 60px;
    margin-left: 35px " (click)="onAddCp()">Add
        </button>
    </div>
</p-dialog>

<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>
