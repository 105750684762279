import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonService} from "../services/common.service";
import {environment} from '../../environments/environment';
import {MasterService} from '../masters/services/MasterService';


@Component({
  selector: 'app-widgets',
  templateUrl: './widgets.component.html',
  styleUrls: ['./widgets.component.css']
})
export class WidgetsComponent implements OnInit {

  display: boolean =  true;
  userDetails: any[] = [];
  loadData:any[]=[];
  traderList: any[] = [];
  tradeTypeList: any[] = [];
  futureIndexList: any[] = [];
  @Input() widgetJson:any[] = [];
  @Input() isLive: boolean = false;
  @Output() addWidgetEvent = new EventEmitter();
  @Output() closeWidget = new EventEmitter();
  @Output() tableDialog = new EventEmitter();

  constructor(public commonService: CommonService,private masterCommonService: MasterService) {

  }

  ngOnInit() {
    this.getUserDetails();
    this.getTraders()
    this.getTradeTypeList();
    this.getFutureTradeData();
    this.getloaddata();
  }

  addWidget() {
    this.addWidgetEvent.emit();
  }

  openTableDialog(dataviewVisibility) {
    this.tableDialog.emit(dataviewVisibility);
  }

  getUserDetails() {
    this.commonService.getJSONByURL(environment.base_url_new + '/api-iam/api/userProfile/v1/getUserDetails?userId=' + this.commonService.getFromStorage('userName') + '&tenantId=' + this.commonService.getFromStorage('tenantId')).subscribe((data: any) => {
      this.userDetails = data['userInfoData'];
    }, error => {
      this.userDetails.push({error: error});
    });
  }

  getTraders() {
      let traders = this.masterCommonService.getListFromUrl('/api/trade/v1/getuser?tenantId='+this.commonService.getFromStorage('tenantId')+'&userType=Trader',
          'value', 'value',false,true,null,'All','');
      this.traderList = traders;
  }

  getTradeTypeList() {
    this.commonService.getLoadData('commodity', ['data'],
        [[{key: 'name', value: 'trade_type_ind'}]])
        .subscribe((next: any) => {
            this.tradeTypeList = this.masterCommonService.getListFromArray(next['trade_type_ind']);
        });
  }

  getFutureTradeData() {
    this.commonService.getJSONByURL(environment.base_url +
        '/api/unadjustedfuturetrades/v1/getunadjustedfuturedropdowndata?tenantId=' + this.commonService.getTenantId())
        .subscribe((next:any) => {
          this.futureIndexList = this.masterCommonService.getListFromArray(next['futureindex'],false,true,'All','all');
        });
  }

  throwCloseWidget(widgetProp: any) {
    this.closeWidget.emit(widgetProp);
  }

  private getloaddata() {
    let _this = this;
    _this.commonService.getJSONByURL(environment.base_url + '/api/trade/v1/loadconfigdata?tenantId='+this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
      _this.loadData = next;
    });
  }
}
