import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'ctrm-dual-list-box',
  templateUrl: './ctrm-dual-list-box.component.html',
  styleUrls: ['./ctrm-dual-list-box.component.css']
})
export class CtrmDualListBoxComponent implements OnInit {
  @ViewChild('list1dd', {static: false}) sourceSelect;
  @ViewChild('list2dd', {static: false}) targetSelect;
  @Input() data: any;
  @Input() sourceHeader: string = 'Available';
  @Input() targetHeader: string = 'Selected';
  @Output() selected = new EventEmitter();
  list1: any = [];
  list2: any = [];
  list1select: any = [];
  list2select: any = [];
  originaltargetheader: string;
  selctednumber: number = 0;

  constructor() {
  }

  ngOnInit() {
    this.originaltargetheader = this.targetHeader;
    this.genrateList();
  }

  checkforalltrue() {
    let is = true;
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].visible === false) {
        is = false;
      }
    }
    return is;
  }

  genrateList() {
    if (this.checkforalltrue()) {
      this.list1 = this.data;
    } else {
      for (let i = 0; i < this.data.length; i++) {
        let obj = this.data[i];
        if (obj.visible === false) {
          this.list1.push(obj);
        } else {
          this.list2.push(obj);
        }
      }
    }
  }

  updateVisibility(obj, state) {
    let nobj = obj;
    nobj.visible = state;
    return nobj;
  }

  addAll() {
    for (let i = 0; i < this.list1.length; i++) {
      this.list2.push(this.updateVisibility(this.list1[i], true));
    }
    this.list1 = [];
    this.outSelected();
  }

  removeAll() {
    for (let i = 0; i < this.list2.length; i++) {
      this.list1.push(this.updateVisibility(this.list2[i], false));
    }
    this.list2 = [];
    this.outSelected();
  }

  addSelected() {
    let selected = this.getSelectValues1();
    for (let i = 0; i < selected.length; i++) {
      let header = selected[i];
      for (let j = 0; j < this.list1.length; j++) {
        if (this.list1[j].header === header) {
          this.list2.push(this.updateVisibility(this.list1[j], true));
          this.list1.splice(j, 1);
        }
      }
    }
    this.outSelected();
  }

  removeSelected() {
    let selected = this.getSelectValues2();
    for (let i = 0; i < selected.length; i++) {
      let header = selected[i];
      for (let j = 0; j < this.list2.length; j++) {
        if (this.list2[j].header === header) {
          this.list1.push(this.updateVisibility(this.list2[j], false));
          this.list2.splice(j, 1);
        }
      }
    }
    this.outSelected();
  }

  addselectoflist1(option) {
    this.list1select.push(option);
  }

  rowSelected() {
    this.list1select = this.selctednumber;
  }

  getSelectValues1() {
    let result = [];
    let options = this.sourceSelect && this.sourceSelect.nativeElement.options;
    let opt;

    for (let i = 0, iLen = options.length; i < iLen; i++) {
      opt = options[i];
      if (opt.selected) {
        result.push(opt.text);
      }
    }
    return result;
  }

  getSelectValues2() {
    let result = [];
    let options = this.targetSelect && this.targetSelect.nativeElement.options;
    let opt;

    for (let i = 0, iLen = options.length; i < iLen; i++) {
      opt = options[i];

      if (opt.selected) {
        result.push(opt.text);
      }
    }
    return result;
  }

  addselectoflist2(option) {
  }


  outSelected() {
    if (this.list2.length > 0) {
      this.targetHeader = this.originaltargetheader + ' ( ' + this.list2.length + ' )';
    } else {
      this.targetHeader = this.originaltargetheader;
    }
    this.selected.emit(this.list2);
  }
}
