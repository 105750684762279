import {AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {CommonService} from '../../services/common.service';
import {MasterService} from '../../masters/services/MasterService';
import {ConfirmationService, MessageService, SelectItem, Table} from 'primeng';
import {BreadcrumbService} from '../../Components/ctrm-breadcrumb/breadcrumb.service';
import {Tcolumn} from '../../grid/tcolumn.model';
import {environment} from '../../../environments/environment';
import {HttpErrorResponse} from '@angular/common/http';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {KeyValue} from "../../grid/key-value.model";

@Component({
  selector: 'app-future-netting',
  templateUrl: './future-netting.component.html',
  styleUrls: ['./future-netting.component.css']
})
export class FutureNettingComponent implements OnInit, AfterViewInit {
  globalFilterValue: string = '';
  tabValue: string = 'future';
  tabOptions: SelectItem[] = [];
  leftTabelMeta: Tcolumn[] = [];
  rightTableMeta: Tcolumn[] = [];
  leftFormat: any = 'Total {{length}} trades.';
  leftFormatLots: any = 'Total {{length}} lots.';
  leftValues: any = [];
  rightValues: any = [];
  categorizedBy: any = {
    exchange: 'Exchange',
    commodity: 'Commodity',
    expiryMonth: 'Ticker',
    clearingBroker: 'Broker'
  };
  //Netting
  filterForm: FormGroup;
  isRefreshing: boolean = false;
  summaryDisplay: boolean = false;
  readOnly : boolean = false;
  selectedFutures: any[] = [];
  brokerList: any[] = [];
  summaryDetails: any[] = [];
  summaryData: any[] = [];
  indexList: any[] = [];
  tickerList:any[]=[];
  profitCenterList: any[]=[];
  contractMonthList: any[] = [];
  futureHeight: string = '';
  nettingColumns: any[] = [];
  summaryCols: any[] = [];
  futureTradeColumns: any[] = [];
  nettingData: any[] = [];
  @ViewChild('netting', {static: true}) netting: Table;
  divEl: HTMLDivElement;
  gridHeight: any = '300px';
  expandedRows = {};
  //sideInfo
  futureRow: any;
  futuredisplay: boolean = false;
  tradeId: any = '';
  noDataBoxLeft: any = '';
  loadingMessage: string = "No Data Available";
  bsConfig: Partial<BsDatepickerConfig>;
  opendialogfordatepicker:boolean=false;
  eventValue:any;
  date = this.commonService.convertUTCtoDate(new Date());
  minDate :any;
  matchTotalValueSetZero:boolean = false;
  nettedDate:Date = new Date();
  matchingApiFunction: Function;

  constructor(private changeDetectorRef:ChangeDetectorRef,
              public commonService: CommonService,
              private masterCommonService: MasterService,
              private confirmationService: ConfirmationService,
              private messageService: MessageService,
              public breadCrumbService: BreadcrumbService) {
  }

  ngOnInit(): void {
    this.getBrokerList();
    this.getIndexList();
    this.getProfitCenterList();
    this.initFilterForm();
    this.bsConfig = new BsDatepickerConfig();
    this.bsConfig.dateInputFormat = environment.dateFormat.toUpperCase();
    this.bsConfig.adaptivePosition = true;
    this.bsConfig.useUtc = true;
    this.detectFilterChanges()

    let _this=this;
    this.matchingApiFunction =  function(payload: any) {

      let matchingpayload = {
        longFutureTrades: _this.eventValue['leftSelectedList'],
        shortFutureTrades: _this.eventValue['rightSelectedList'],
        nettedDate : _this.commonService.convertDatetoUTC(_this.nettedDate,true)
      };

      payload['url'] = environment.base_url+'/api/futureNetting/v1/match?tenantId='+_this.commonService.getFromStorage('tenantId');
      payload['type'] = 'post';
      payload['payload'] = matchingpayload;

      return payload;
    };

    this.breadCrumbService.makeBreadcrumbTo(this.commonService.OPERATION_MAP_KEY, '/futureNetting');
    this.tabOptions = [
      {label: 'Future Netting Tab', value: 'future'},
      {label: 'Netting Trade Tab', value: 'netting'},
    ];

    this.tabValue === 'future';
    this.leftTabelMeta.push(new Tcolumn('tradeId', 'Trade Id', 'LB', undefined, false));
    this.leftTabelMeta.push(new Tcolumn('tradeDate', 'Trade Date', 'D', undefined, false));
    this.leftTabelMeta.push(new Tcolumn('expiryDate', 'Expiry Date', 'D', undefined, false));
    this.leftTabelMeta.push(new Tcolumn('profitcenter', 'Profit Center', 'LB', undefined, false));
    this.leftTabelMeta.push(new Tcolumn('tradePrice', 'Price', 'LB', undefined, false));
    this.leftTabelMeta.push(new Tcolumn('lot', 'Lots', 'LB', undefined, false));
    this.leftTabelMeta.push(new Tcolumn('balanceLot', 'Open Lots', 'LB', undefined, false));
    this.rightTableMeta.push(new Tcolumn('balanceLot', 'Open Lots', 'LB', undefined, false));
    this.rightTableMeta.push(new Tcolumn('lot', 'Lots', 'LB', undefined, false));
    this.rightTableMeta.push(new Tcolumn('tradePrice', 'Price', 'LB', undefined, false));
    this.rightTableMeta.push(new Tcolumn('profitcenter', 'Profit Center', 'LB', undefined, false));
    this.rightTableMeta.push(new Tcolumn('expiryDate', 'Expiry Date', 'D', undefined, false));
    this.rightTableMeta.push(new Tcolumn('tradeDate', 'Trade Date', 'D', undefined, false));
    this.rightTableMeta.push(new Tcolumn('tradeId', 'Trade Id', 'LB', undefined, false));
    this.getPlanningData();

    this.nettingColumns = [
      {field: 'exchange', header: 'Exchange'},
      {field: 'commodity', header: 'Commodity'},
      {field: 'expiryMonth', header: 'Expiry Month'},
      {field: 'nettedLots', header: 'Netted Lots'},
      {field: 'company', header: 'Company'},
      {field: 'profitcenter', header: 'Profit Centre'},
      {field: 'broker', header: 'Broker'}
    ];

    this.summaryCols = [
      {field: 'contract', header: 'Contract'},
      {field: 'totalRealizedLots', header: 'Total Realized Lots'},
      {field: 'totalRealizedPnL', header: 'Total Realized PnL'},
    ];
    let tradeTypeFuction = function (value: any) {
      if (value['tradeTransactionType'] === 'BUY') {
        return 'Long';
      } else {
        return 'Short'
      }
    };

    this.futureTradeColumns = [
      {field: 'tradeId', header: 'Trade Id'},
      {field: 'tradeType', header: 'Long/Short', valueFunction: tradeTypeFuction},
      {field: 'tradeDate', header: 'Trade Date', type: 'date'},
      {field: 'trader', header: 'Trader'},
      {field: 'lots', header: 'Lots'},
      {field: 'price', header: 'Price'},
      {field: 'nettingPrice', header: 'Netting Price'},
      {field: 'matchLots', header: 'Matched Lots'},
      {field: 'brokerAccount', header: 'Broker Account'},
    ];

  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  getPlanningData() {
    let _this = this;
    this.leftValues = [];
    this.rightValues = [];
    this.commonService.getJSONByURL(environment.base_url + '/api/futureTrade/v1/getFutureTradeForNetting?tenantId='+this.commonService.getFromStorage('tenantId')).subscribe((next) => {
      _this.leftValues = next['longFutureTrades'];
      _this.rightValues = next['shortFutureTrades'];
    })
  }
  minNettingDate(value){
    const leftTradeDates = value.leftSelectedList.map(item => item.tradeDate);
    const rightTradeDates = value.rightSelectedList.map(item => item.tradeDate);
    const allTradeDates = [...leftTradeDates, ...rightTradeDates];

    let dateObjects = allTradeDates.map(dateString => new Date(dateString));
    let maxDate = dateObjects.reduce((max, date) => (date > max ? date : max), dateObjects[0]);

    this.minDate=this.commonService.convertUTCtoDate(new Date(maxDate));
  }
  onclick(value){

    this.minNettingDate(value);

    this.eventValue=value;
    this.opendialogfordatepicker= value.visible;
  }

  canceldialog() {
  this.opendialogfordatepicker=false;
  // this.filterForm.controls['nettedDate'].setValue(new Date());
  }

  continue(){
    this.onClickSubmit(this.eventValue);
    this.opendialogfordatepicker= false;
    this.clearAllFilter();
  }

  onSubmit(value:any){
    this.opendialogfordatepicker= false;
    this.showToast()
    this.clearAllFilter();
  }

  //Matching Button
  onClickSubmit(value: any) {
    let _this = this;
    if (this.readOnly){
      if (this.filterForm.valid){
        if (this.leftValues.length > 0 && this.rightValues.length > 0){
          this.summaryDisplay = true
          let filterPayload = this.filterForm.value;
          this.commonService.post(environment.base_url + '/api/futureNetting/v1/futureautonettingsummary?tenantId='+this.commonService.getFromStorage('tenantId'), filterPayload).subscribe((next: any[]) => {
            _this.summaryData = next;
            _this.summaryDetails.push({
              totalRealizedPnL:next['totalRealizedPnL'],
              totalRealizedLots:next['totalNettedLot'],
              contract:next['longFutureTrades'][0]['futureIndex'],

            });
          }, (error:HttpErrorResponse) => {
            _this.summaryDisplay = false;
            _this.messageService.add({severity:'error', summary:error['error']});
          });
        }else{
          this.messageService.add({severity: 'error', summary: 'Long/Short trade list is empty'});
        }
      }else{
        this.messageService.add({severity:'error', summary:'Filter values are not valid or empty'})
      }

    } else {
      let matchingpayload = {
        longFutureTrades: value['leftSelectedList'],
        shortFutureTrades: value['rightSelectedList'],
        nettedDate : this.nettedDate
      };
      // if(matchingpayload.longFutureTrades.length>1 && matchingpayload.shortFutureTrades.length>1){
      //   this.messageService.add({severity:'error',summary: 'Multiple Cross matching Not Allowded'})
      // }
      // else{
        this.commonService.post(environment.base_url + '/api/futureNetting/v1/match?tenantId='+this.commonService.getFromStorage('tenantId'), matchingpayload).subscribe((next: any[]) => {
          _this.showToast();
          this.filterPayload(this.filterForm.value);
         this.getPlanningData();
        }, (error: HttpErrorResponse) => {
          this.messageService.add({severity: 'error', summary: this.commonService.getHttpErrorMessage(error, 'Future Netting')});
        });
      // }

    }
  }


  showToast() {
    this.messageService.add({
      severity: 'info',
      summary: 'Netting Successful'
    });
  }

  //Netting Refresh
  refresh() {
    this.nettedData();
    this.nettingData = [];
    this.selectedFutures = [];
  }

  onError(error: HttpErrorResponse) {
    this.messageService.add({severity: 'error', summary: "Netting Failed. Please Try Again."});
    this.opendialogfordatepicker= false;
  }
  //Netting Data
  nettedData() {
    let _this = this;
    this.nettingData = [];
    this.commonService.getJSONByURL(environment.base_url + '/api/futureNetting/v1/getNettedPlans?tenantId='+_this.commonService.getFromStorage('tenantId')).subscribe((next: any[]) => {
      _this.nettingData = next;
    });
  }

  //Child Form Expand
  openNettingRow(value: any) {
    let nettingId = value['data']['nettingId'];
    this.commonService.getJSONByURL(environment.base_url + '/api/futureNetting/v1/getNettedPlansDetails?nettingId=' + nettingId+'&tenantId='+this.commonService.getFromStorage('tenantId')).subscribe((response: any) => {
      value['data']['netting'] = response;
    });
  }


  switchTab(tabName) {
    this.tabValue = tabName;
  }

  onTabChange(tabData: any) {
    let tabName = tabData['option']['value'];
    if (tabName === 'future') {
      this.getPlanningData();
    } else {
      this.nettedData();
      this.selectedFutures = [];
      this.calculateHeight(this.netting);
    }
  }

  getFilterValue($event) {
    this.globalFilterValue = event.target['value'];
  }

  getClassForHeading(index) {
    return index > 0 ? 'headerNormal' : 'noPipe';
  }

  @HostListener("window:resize", [])
  public onResize() {
    this.calculateHeight(this.netting);
  }

  calculateHeight(netting: Table) {
    if (netting !== undefined && netting !== null) {
      let offsetTop = netting.el.nativeElement['offsetTop'];
      let offsetHeight = netting.el.nativeElement['offsetParent']['offsetHeight'];
      this.futureHeight = (offsetHeight - offsetTop - 22) + 'px';
      if (this.divEl !== undefined && this.divEl !== null) {
        this.calculateGridHeight(this.divEl);
      }
    }
  }

  calculateGridHeight(divElement: HTMLDivElement) {
    this.gridHeight = 350 + 'px';
    this.divEl = divElement;
  }

  selectRow() {

  }

  futureClose(value: any) {
    this.tradeId = '';
    this.futuredisplay = false;
  }

  onClickInfo(value: any) {
    this.tradeId = value['rowData']['tradeUuid'];
    this.futureRow = value['rowData'];
    this.futuredisplay = true;
  }

  calculateLeft() {
    this.noDataBoxLeft = ((window['visualViewport'].width - 500) / 2) + 'px';
  }

  onAutoNettingToggleChange(value) {
    this.readOnly = value;
  }

  private initFilterForm() {
    this.filterForm = new FormGroup({
      clearingBroker: new FormControl('', [Validators.required]),
      futureIndex: new FormControl('',[Validators.required]),
      ticker: new FormControl('', [Validators.required]),
      profitcenter: new FormControl('', [Validators.required])
      // nettedDate: new FormControl(, [Validators.required]),
    });
  }

  onIndexChange() {
    if (this.isNull(this.filterForm.value,'futureIndex') && this.isNull(this.filterForm.value,'clearingBroker')) {
      this.getPlanningData();
    } else {
      this.filterPayload(this.filterForm.value);
    }
  }

  private isNull(obj,key) {
    return obj[key] === null ||  obj[key] === undefined || obj[key].length === 0;
  }

  private getBrokerList() {
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url+'/api/futureTrade/v1/getallFutureTradeBroker'+'?tenantId=' + this.commonService.getTenantId()).subscribe((data:any)=>{
      const keyValuePairs = [{label:'Select',value:''}];
      for (const value of data) {
        keyValuePairs.push({ label: value, value: value });
      }
      _this.brokerList = keyValuePairs;
    })
  }

  private getProfitCenterList(){
    let _this = this;
    let payload = {
      executionBroker:"all",
      futureIndex:"all",
      profitcenter:"all",
      status:["Partially Netted","Not Netted"],
      fromDate:"",
      toDate:"",
      fromTradeDate:"",
      toTradeDate:""}
      this.commonService.post(environment.base_url+'/api/futureTrade/v1/getfuturetradebyfutureindex?tenantId=' + this.commonService.getTenantId()+'&isExpiryMonth=false',payload).subscribe((data:any)=>{
       let x=[];
        const keyValuePairs = [{label:'Select',value:''}];
        data.forEach(futureTrade=>{
          futureTrade.futureTradesList.forEach(resp=>{
            if(!x.includes(resp['profitcenter'])) {
              x.push(resp['profitcenter']);
              keyValuePairs.push({ label: resp['profitcenter'], value: resp['profitcenter'] });

            }
          })
        })
        this.profitCenterList = keyValuePairs;
      })
  }

  private getIndexList() {
    let _this = this;
    // _this.commonService.getJSONByURL(environment.base_url + '/api/futurecommodityprice/v1/gettickerlist?tenantId=' + this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
    //   _this.indexList = _this.commonService.createListFromObject(next, 'tickerName', 'tickerName', true);
    // });
    this.commonService.getJSONByURL(environment.base_url+'/api/futureTrade/v1/getallFutureTradeFutureIndex'+'?tenantId=' + this.commonService.getTenantId()).subscribe((data:any)=>{
      const keyValuePairs = [{label:'Select',value:''}];
      for (const value of data) {
        // const part = value.split("-");
        keyValuePairs.push({ label: value, value: value });
      }
      _this.indexList = keyValuePairs;
    })
    // _this.commonService.getJSONByURL(environment.base_url + '/api/futureTrade/v1/getallfutureexchangecodevalue?tenantId=' + this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
    //   _this.indexList= _this.commonService.createListFromObject(next, '', next, true);
    //   let list:any[] =[];
    //   list.push(new KeyValue());
    //   next.forEach(function (obj) {
    //     list.push(new KeyValue(obj,obj));
    //   });
    //   _this.indexList=list;
    // });

  }

  onChangeFutureIndex(value:any) {
    let selectedFutureIndex=this.filterForm.get('futureIndex').value;
    const parts = selectedFutureIndex.split("-");
    this.commonService.getJSONByURL(environment.base_url+'/api/futureTrade/v1/getfutureproductcodevalueforexchangecode?tenantId='+this.commonService.getFromStorage('tenantId')+'&exchangeCode='+parts[0]).subscribe((next:any)=>{
      let list:any[] =[];
      list.push(new KeyValue());
      next.forEach(function (obj) {
        list.push(new KeyValue(obj,obj));
      });
      this.tickerList= list;
    })
  }

  detectFilterChanges() {
    let _this = this;
    this.filterForm.valueChanges.subscribe(values => {
       _this.filterPayload(values);
       this.matchTotalValueSetZero = true;
      });
  }

  private filterPayload (values) {
    let _this = this;
    _this.leftValues = [];
    _this.rightValues = [];
    let payload = {
      clearingBroker: values['clearingBroker'],
      futureIndex: values['futureIndex'],
      ticker: values['ticker'],
      profitcenter:values['profitcenter']
    }
    _this.getFutureTradeByFilter(payload);
  }

  getFutureTradeByFilter(payload){
    let _this = this;
    this.commonService.getJSONByObject(environment.base_url + '/api/futureTrade/v1/getfuturetradefornettingbyfilter?tenantId=' +
        _this.commonService.getTenantId(), payload).subscribe((data: any) => {
      let sellLegs = [];
      let buyLegs = [];
      data.forEach((futureTrade, index) =>{
        if (futureTrade['tradeTransactionType'] === 'BUY'){
          buyLegs.push(futureTrade);
        }else{
          sellLegs.push(futureTrade);
        }
      });
      _this.leftValues = buyLegs;
      _this.rightValues = sellLegs;
    });
  }

  toggleSummaryDialog(action) {
    let _this = this;
    let payload = {
      clearingBroker: this.filterForm.controls['clearingBroker'].value,
      futureIndex: this.filterForm.controls['futureIndex'].value,
      expiryMonth: this.filterForm.controls['expiryMonth'].value,
      // nettedDate:this.filterForm.controls['nettedDate'].value
    }
    if (action === 'confirm'){
      //Calling confirm API for saving the Auto Netting Data
      this.commonService.post(environment.base_url + '/api/futureNetting/v1/futureautonettingsave?tenantId=' + this.commonService.getTenantId(), this.summaryData).subscribe((next:any) => {
        _this.summaryData = []
        _this.summaryDetails = [];
        _this.getFutureTradeByFilter(payload);
        _this.messageService.add({severity:'success', summary:'Auto-Netting completed successfully'});
        this.summaryDisplay = false
      });
    }else{
      _this.summaryData = []
      this.summaryDetails = [];
      this.summaryDisplay = false
    }
  }

  getSummaryGridClass(field, rowData) {
    if (field === 'totalRealizedPnL'){
      return rowData[field] > 0? 'classGreen' : 'classRed'
    }else{
      return 'summaryGrid'
    }
  }

  clearAllFilter() {
    this.filterForm.controls['clearingBroker'].setValue("");
    this.filterForm.controls['futureIndex'].setValue("");
    this.filterForm.controls['ticker'].setValue("");
    this.filterForm.controls['profitcenter'].setValue("");
    this.filterForm.controls['nettedDate'].setValue("");
    this.getPlanningData();
  }

}
