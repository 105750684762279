import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';

@Component({
  selector: 'xceler-bottom-pane',
  templateUrl: './xceler-bottom-pane.component.html',
  styleUrls: ['./xceler-bottom-pane.component.css']
})
export class XcelerBottomPaneComponent implements OnInit {
  @Input() popupDisplay: boolean = false;
  @Input() middleViewTemplate: TemplateRef<any>;
  @Input() rightViewTemplate: TemplateRef<any>;
  @Input() leftViewTemplate: TemplateRef<any>;
  @Output() onClose = new EventEmitter();
  @Input() canClose:boolean = true;
  @Input() styleClass: any='bottomPane';
  constructor() { }

  ngOnInit(): void {
  }

  close() {
    this.popupDisplay = !this.popupDisplay;
    this.onClose.emit();
  }
}
