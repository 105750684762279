import {Injectable} from '@angular/core';
import {Breadcrumb} from './breadcrumb.model';
import {CommonService} from "../../services/common.service";
import {Router} from "@angular/router";
import {TitleCasePipe} from "@angular/common";


@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  titleCasePipe: TitleCasePipe;
  TRADE_IGNORE_ROUTE_LIST: string[] = ['/dealSlip'];
  megaMenuMap: Map<string, { list: Breadcrumb[], mainObject: any[], routeList: string[] }> = new Map();
  private breadcrumb: Breadcrumb[] = [];
  private added: string[] = [];
  private obj: any;
  private oldID: any;
  private lastPage: number = 0;
  private lastTitle: any;

  constructor(private commonService: CommonService, private route: Router) {
    this.titleCasePipe = new TitleCasePipe();
  }

  updateBreadCrumb(breadcrumb) {
    // this.breadcrumb = breadcrumb;
  }

  setLastPage(page, title: string) {
    this.lastPage = page;
    this.lastTitle = title;
  }

  getLastPage(title: string) {
    if (this.lastTitle === title) {
      return this.lastPage;
    }
    return 0;

  }

  getBreadCrumb() {
    return this.breadcrumb;
  }

  pushBreadcrumb(breadcrumb) {
    if (!this.added.includes(breadcrumb.routeTo + breadcrumb.label + '')) {
      this.breadcrumb.push(breadcrumb);
      this.added.push(breadcrumb.routeTo + breadcrumb.label + '');
    } else {
    }
  }

  popBreadcrumb() {
    let len = this.breadcrumb.length;
    let bc = this.breadcrumb[len - 1];
    this.added.splice(this.added.indexOf(bc.routeTo), 1);
    this.breadcrumb.splice(len - 1, 1);
  }

  getLastBreadCrumb() {
    let len = this.breadcrumb.length;
    return this.breadcrumb[len - 1];
  }

  clearAll() {
    this.breadcrumb = [];
    this.added = [];
  }


  getOldID() {
    return this.oldID;
  }

  updateOldID(id) {
    this.oldID = id;
  }

  /**
   * Select selected Risk to selected true and false to previously selected master
   * @param route
   */
  setBreadCrumbSelected(route: string, riskList: Breadcrumb[], riskRouteList: string[]) {
    let breadcrumbchilds = [];
    breadcrumbchilds = [...riskList];
    if (this.commonService.getFromStorage('lastRoute') !== undefined) {
      if (riskRouteList.includes(this.commonService.getFromStorage('lastRoute')['routeTo'])) {
        breadcrumbchilds[riskRouteList.indexOf(this.commonService.getFromStorage('lastRoute')['routeTo'])].setIsSelected(false);
      }
    }

    for (let i = 0; i < breadcrumbchilds.length; i++) {
      if (breadcrumbchilds[i].routeTo == route) {
        breadcrumbchilds[i].setIsSelected(true);
        break;
      }
    }

    return breadcrumbchilds;
  }

  /**
   * update bredcrumb vavlue on selecttions
   * @param set
   */
  updateBreadcrumb(route: any, mapKey: string) {
    this.route.navigate([route], {skipLocationChange: false});
    //this.commonService.saveToStorage("lastRoute", {routeTo: route, skip: false});
  }

  /**
   * cache the master list
   */
  cacheList(object: any, mapKey: string) {
    let _this = this;
    let list: Breadcrumb[] = [];
    let routeList: string[] = [];
    let readAccessRouter: string[] = this.commonService.getFromStorage('readAccessRouter');

    object.forEach(function (dataObject: any[]) {
      dataObject['items'].forEach(function (data: any) {
        if (data != undefined && data.route != undefined && readAccessRouter != undefined && readAccessRouter.includes(data.route)) {
          list.push(new Breadcrumb(data.icon, data.name, data.route, [], false, false));
          routeList.push(data.route);
        }
      });
      _this.megaMenuMap.set(mapKey, {list: list, routeList: routeList, mainObject: object});
    });
  }

  addInMegaMenuMap(fileName: string, mapKey: string) {
    let _this = this;
    this.commonService.getJSONByFile(fileName).subscribe(next => {
      _this.cacheList(next, mapKey);
    });
  }

  getFormMegaMenu(megaTitle: string) {
    let menu = [];
    if (this.megaMenuMap.has(megaTitle)) {
      menu = this.megaMenuMap.get(megaTitle)['mainObject'];
    }
    return menu;
  }

  addMenu(add: string = 'all') {
    if (add === 'all') {
      this.addInMegaMenuMap('masterList.json', this.commonService.MASTER_MAP_KEY);
      this.addInMegaMenuMap('tradeList.json', this.commonService.TRADE_MAP_KEY);
      this.addInMegaMenuMap('operationsAccounts.json', this.commonService.OPERATION_MAP_KEY);
      this.addInMegaMenuMap('risk.json', this.commonService.RISK_MAP_KEY);
      this.addInMegaMenuMap('finance.json', this.commonService.FINANCE_MAP_KEY);
    }
  }

  getMegaMenu() {
    return this.megaMenuMap;
  }

  makeBreadcrumbTo(mapKey: string, selectedScreenRoute: string) {
    let ignoreRouteList: any[] = [];
    if (mapKey === this.commonService.TRADE_MAP_KEY) {
      ignoreRouteList = this.TRADE_IGNORE_ROUTE_LIST;
    }
    let readAccessRouter: string[] = this.commonService.getFromStorage('readAccessRouter');
    if (!this.megaMenuMap.has(mapKey) || this.megaMenuMap.get(mapKey) === undefined || this.megaMenuMap.get(mapKey) === null) {
      this.addMenu(mapKey);
    }
    this.breadcrumb = [];
    let _this = this;
    let childs: Breadcrumb[] = [];
    let mainBreadcrumb: Breadcrumb;
    this.breadcrumb.push(new Breadcrumb('', mapKey, '', [], false, false));
    this.commonService.getJSONByFile(this.getFilename(mapKey)).subscribe(next => {
      next.forEach(function (dataObject: any[]) {
        dataObject['items'].forEach(function (data: any) {
          if (data != undefined && data.route != undefined && readAccessRouter != undefined && readAccessRouter.includes(data.route)) {
            if (!ignoreRouteList.includes(data['route'])) {
              let breadcrumb: Breadcrumb = new Breadcrumb(data['icon'], data['name'], data['route'], [], false, true, {});
              if (breadcrumb.getRouteTo() === selectedScreenRoute) {
                _this.breadcrumb.push(new Breadcrumb('', _this.titleCasePipe.transform(dataObject['title']), '', [], false, true, {}));
                mainBreadcrumb = new Breadcrumb(data['icon'], data['name'], data['route'], [], false, true, {});
                mainBreadcrumb.setIsSelected(true);
              }
              childs.push(breadcrumb);
            }
          }
        });
      });
      if (mainBreadcrumb != null) {
        mainBreadcrumb.setChilds(childs);
        this.breadcrumb.push(mainBreadcrumb);
      }
    });
  }

  public pushInBreadcrumb(icon:string,name:string,route:string,isSelected:boolean = false) {
    this.breadcrumb.push(new Breadcrumb(icon,name,route,[],isSelected,true,{}));
  }

  private getFilename(mapKey: string) {
    let filename = '';
    if (mapKey === this.commonService.TRADE_MAP_KEY) {
      filename = 'tradeList.json';
    } else if (mapKey === this.commonService.MASTER_MAP_KEY) {
      filename = 'masterList.json';
    } else if (mapKey === this.commonService.OPERATION_MAP_KEY) {
      filename = 'operationsAccounts.json';
    } else if (mapKey === this.commonService.RISK_MAP_KEY) {
      filename = 'risk.json';
    } else if (mapKey === this.commonService.FINANCE_MAP_KEY) {
      filename = 'finance.json';
    }
    return filename;
  }

}
