import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Tcolumn} from '../../tcolumn.model';
import {FilterService} from './filter.service';
import {environment} from "../../../../environments/environment";
import {Filter} from "./filter.model";

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {
  @Input() columns: Tcolumn[] = [];
  @Input() appliedFilters: Filter[] = [];
  @Output() FilterList = new EventEmitter();
  updated: any[] = [];
  suggestion: any[];
  fieldlists: any[] = [];
  selected: any;
  allcolumns: any[] = [];
  val: any = '';
  remove: string = '';
  add: string = '';
  filterList: Filter[];
  addedList: string[] = [];

  constructor(public service: FilterService) {
  }

  ngOnInit() {
    let _this = this;
    this.filterList = [];
    this.filterList = this.appliedFilters;
    this.allcolumns.push({label: "Select", value: 'select'});
    this.columns.forEach(function (column) {
      if (!_this.service.exculedTypes.includes(column.getColumnType()) && column.isVisible() && column.getColumnType() !== 'D') {
        _this.fieldlists.push({header: column.getHeader(), field: column.getField()});
        _this.allcolumns.push({label: column.getHeader(), value: column.getField(), column: column});
      }
    });
  }

  /**
   * send list of newly created filters
   */
  outList() {
    let _this = this;
    this.filterList.forEach(function (filter: Filter, index: number) {
      if (filter.getType() !== 'L' || filter.getType() !== 'B') {
        if (filter.getFirstValue() === undefined && filter.getFirstValue().length === 0) {
          _this.filterList.splice(index, 1);
        }
      } else {
        if (filter.getFirstValue() === undefined && typeof (filter.getFirstValue()) !== 'object') {
          _this.filterList.splice(index, 1);
        }
      }
    });
    this.FilterList.emit(this.filterList);
  }

  /**
   * Remove Filter from filter list
   * @param i
   */
  removeFilter(i: number) {
    this.addedList.splice(this.addedList.indexOf(this.filterList[i].getColumnName()), 1);
    this.filterList.splice(i, 1);
    this.outList();
  }

  updateOptions(value: any, index: number) {
    this.filterList[index].setCondition(value['value']);
    this.outList();
  }

  addListOption(value: any, index: number) {
    this.filterList[index].setFirstValue(value);
    this.outList();
  }

  addTextOption(value: any, index: number) {
    this.filterList[index].setFirstValue(value);
    this.outList();
  }

  addNumberOption(value: any, index: number, isFirst: boolean) {
    if (isFirst) {
      this.filterList[index].setFirstValue(value);
    } else {
      this.filterList[index].setSecondValue(value);
    }
    this.outList();
  }

  addfilter(value: any) {
    if (value.value !== 'select') {
      if (!this.addedList.includes(value['value'])) {
        this.filterList.push(this.service.getFilterObject(value));
        this.addedList.push(value['value']);
      }
    }
  }

  getOptions(type: any) {
    return this.service.getOptions(type);
  }

  getDateFormat(col: Tcolumn) {
    let format = environment.base_url;
    if (col.getFromExtra('date')['format'] !== undefined) {
      format = col.getFromExtra('date')['format'];
    }
    return format.toString().toUpperCase();
  }

  isValidDate(value: any) {
    if (value === undefined) {
      return false;
    }
    return true;
  }

  addDateOption(value: Date, filter: any, b: boolean) {
    this.service.addDateOption(value, filter, b);
  }

  /**
   * show boxes when select any condition
   * @param filter
   */
  showBoxes(filter: Filter) {
    return filter.getCondition().length > 0;
  }

  /**
   * is column is list type
   * @param filter
   */
  isListType(filter: Filter) {
    return (filter.getType() === 'L' || filter.getType() === 'B');
  }

  /**
   * is column is text type
   * @param filter
   */
  isTextType(filter: Filter) {
    return (filter.getType() === 'T' || filter.getType() === 'TA' || filter.getType() === 'LB');
  }

  /**
   * is column is number type
   * @param filter
   */
  isNumberType(filter: Filter) {
    return (filter.getType() === 'N');
  }

  /**
   * is column is date type
   * @param filter
   */
  isDateType(filter: Filter) {
    return (filter.getType() === 'D');
  }

  /**
   * is second box required
   * @param filter
   */
  isSecondRequired(filter: Filter) {
    return (filter.getCondition() === 'between');
  }

}
