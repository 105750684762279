<div class="mainOuterDiv">

    <div class="mainInnerDiv" *ngIf="notifications !== undefined && notifications !== null && notifications.length !==0 ">
        <p class="titleHeader" *ngIf="showHeader">Notifications</p>
        <div class="tabWrapper">
            <p-tabView>
                <p-tabPanel header="Need Action">
                    <div *ngFor="let notif of notifications">
                        <div *ngIf="notif['message'].toLowerCase() !== 'role updated' && !notif['message'].toLowerCase().includes('user updated')" (click)="switchContext(notif)" class="notifCardWrapper">
                            <p [ngClass]="notif['read']?'readednotifTitle':'notifTitle'">{{notif['message']}}</p>
                            <p class="notifDescription">Created By: {{notif['createdBy']}}</p>
                            <p class="notifTime">{{getNotifTime(notif['createdTimestamp'])}}</p>
                            <!--                        <div class="notifTime" timeago [live]="'live'" [date]="notif['createdTimestamp']"></div>-->
<!--                            <div class="actionBtnDiv">-->
<!--                                <p [ngClass]="notif['read']?'readedactionBtn':'actionBtn'" (click)="switchContext(notif)">Approve</p>-->
<!--                            </div>-->
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="Alerts">

                </p-tabPanel>
            </p-tabView>
        </div>
    </div>

    <div *ngIf="notifications.length === 0">
        <div class="noNotifWrapper">
            <i class="fas fa-bell bellIcon"></i>
            <p style="font-size: 20px; color:#a9a9a9">No notifications</p>
        </div>
    </div>

</div>
