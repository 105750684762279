import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {DataService} from './services/data.service';
import {UtilService} from './services/util.service';
import {environment} from '../../environments/environment';
import {Tcolumn} from '../grid/tcolumn.model';
import {Action} from '../grid/actions.model';
import {CommonService} from '../services/common.service';
import {Tab} from '../tab-grid/Tab.model';
import {MasterService} from '../masters/services/MasterService';
import {DatePipe} from '@angular/common';
import {Button} from '../grid/buttons.model';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import {KeyValue} from '../grid/key-value.model';
import {GridService} from '../grid/grid.service';
import {ConfirmationService, MessageService} from 'primeng';
import {messages} from '../services/common/messages';
import {BreadcrumbService} from '../Components/ctrm-breadcrumb/breadcrumb.service';
import {HttpErrorResponse} from '@angular/common/http';
import {AccessPolicyService} from '../services/accesspolicy.service';


@Component({
  selector: 'ctrm-input-form',
  templateUrl: './ctrm-input-form.component.html',
  styleUrls: ['./ctrm-input-form.component.css'],
  providers: [ConfirmationService]
})
export class CtrmInputFormComponent implements OnInit, OnChanges,AfterViewInit {

  fields: Tcolumn[] = [];
  @Input() updateUrlType: string = 'post';
  @Input() noOfColumns = 2;
  @Input() dontSaveMode: boolean = false;
  @Input() updatePayloadFunction:Function;
  @Input() modalState = true;
  @Input() heading = '';
  @Input() autoCloseOnSave: boolean = true;
  @Input() saveUrl = '';
  @Input() editUrl = '';
  @Input() closeAfterSave: boolean = true;
  @Input() getUrl = '';
  @Input() showAddButtons: boolean = true;
  @Input() showFooter: boolean = true;
  @Input() showConfirm: boolean = false;
  @Input() bulkUrl = '';
  @Input() gridConfig: any;
  @Input() sections: Map<string, any>;
  @Input() columnsMap: Map<string, Tcolumn>;
  @Input() showOnModal: boolean = true;
  @Input() gridTabs: Tab[];
  @Input() extraData = {href: environment.base_url, editable: false, oldBeanData: ''};
  @Input() uniqueKey: string = '';
  @Input() refreshForm: boolean = false;
  @Input() baseZIndex: number = 25;
  @Input() postBtn: boolean = false;
  @Output() formValues = new EventEmitter();
  @Output() OnApply = new EventEmitter();
  @Output() OnSaveWithoutClose = new EventEmitter();
  @Output() OnCancel = new EventEmitter();
  @Output() onChangeValue = new EventEmitter();
  @Output() addButtonValue = new EventEmitter();
  @Output() onRowDataSave = new EventEmitter();
  @Output() onRowDataDelete = new EventEmitter();
  @Output() onClickNext = new EventEmitter();
  @Output() onClickPrevious = new EventEmitter();
  @Output() onButtonClick = new EventEmitter();
  @Input() gridTitle: string = '';
  @Input() updateButtonDisableFunction: Function;
  @Input() extraButtons: Button[] = [];
  @Input() showExtraButtons: boolean = true;
  @Input() contentStyle: any = {};
  @Input() listOfValues: any;
  @Input() updateUrl: string = '';
  @Input() headingRequired: boolean = true;
  @Input() toggleReadOnlyAtStart: boolean = true;
  @Input() fieldsWithCustomView:any[] =[];
  @Input() customViewTemplated:TemplateRef<any>[] =[];
  sumitted = false;
  ra: boolean = false;
  update: boolean = false;
  formgrouparray: FormArray;
  originalValue: any;
  headingForm: string = '';
  isError: boolean = false;
  errorMessage: string = '';
  formgrouparraylist: any = [];
  changes: any = {};
  private isPopulating: boolean = false;
  viewOnlyMode: boolean = false;
  showErrorMessage: boolean = false;
  formcontrolnamelist: any = [];
  divisions = 0;
  istable: boolean = false;
  accessMap: Map<string, any> = new Map<string, any>();
  formGroup: FormGroup;
  isLoading: boolean = false;
  clear: boolean = false;
  rowData: any;
  createdBy: any;
  currentlyFocuedField: string;
  createdDate: any;
  updatedBy: any;
  createdId: any;
  sectionKeys: any[] =[];
  updatedTimestamp: any;
  focusedSection: string = '';
  createdTimestamp: any;
  updatedDate: any;
  datePipe: DatePipe;
  maxSteps: number = 1;
  isClicked: boolean = false;
  refreshGrid: boolean = false;
  focuedField: string = '';
  isFetching: boolean = false;
  @Input() readOnly: boolean = false;
  formHeight: string = '';
  updateDisable: boolean = false;
  fieldToUpdate: Map<string, any>;
  @Input() editEnabled: boolean = true;
  expandAllValue: boolean;
  searchValue: string = '';
  expandCount: number = 0;
  contentHeight: string = '';
  localNameList: any[] = [];
  remoteNameList: any[] = [];
  baseFile: any;
  data: any;
  private canCheck: boolean = false;
  @Input() extraObjectToSave: any = {};
  @Output() onChangeObjectCreated = new EventEmitter();
  keys:any[] =[];
  accesssections:string[] = [];
  @Input() viewCreatedBy:boolean = true;
  @Input() fieldRequiresContinuesChangeDetection: any[] = [];

  constructor(private changeDetectionRef:ChangeDetectorRef,
              private accessService:AccessPolicyService,
              private util: UtilService, public commonService: CommonService, public breadCrumbService: BreadcrumbService, private masterCommonService: MasterService,private messageService: MessageService, private formBuilder: FormBuilder, private dataservice: DataService, private gridService: GridService, private confirmationService: ConfirmationService) {
  }

  ngAfterViewInit() {
    this.changeDetectionRef.detectChanges();
  }

  ngOnInit() {
    this.datePipe = new DatePipe('en-US');
    this.divisions = 12 / this.noOfColumns;
    this.dataservice.edit_url = this.editUrl;
    this.updateColumns();
    this.getHeading();
    if (this.toggleReadOnlyAtStart) {
      this.toggleReadOnly();
    }
    this.fetchAccesJson(this.accessService.baseAccessPolicy);

  }

  updateColumns() {
    let _this = this;
    this.fields = [];
    const obj = {};
    this.originalValue = {};
    this.changes = {};
    this.formgrouparraylist = [];
    this.formcontrolnamelist = [];
    if (this.columnsMap !== null && this.columnsMap !== undefined){
      this.columnsMap.forEach(function (data) {
        if (data.getVisibilityArea() !== 'N') {
          if (data.getVisibilityArea() === 'F' || data.getVisibilityArea() === 'B' || data.getVisibilityArea() === 'H' ){
            _this.fields.push(data);
            if (data.getColumnType() === 'D') {
              obj[data.getField()] = [{
                value: _this.commonService.getDateValue(data.getDefaultValue()),
                disabled: _this.isDisabled(data)
              }, data.getValidators()];
              _this.formcontrolnamelist.push(data.getField());
            } else {
              obj[data.getField()] = [{
                value: _this.getDefaultValue(data.getDefaultValue()),
                disabled: _this.isDisabled(data)
              }, data.getValidators()];
              _this.formcontrolnamelist.push(data.getField());
            }
          }
        }
      });
    }
    obj['uuid'] = [{value:'', disabled:true},[]];
    this.formGroup = this.formBuilder.group(obj);
    this.formgrouparray = this.formBuilder.array([]);
    this.originalValue = {...this.formGroup.value};
    this.fields.forEach(function (field: Tcolumn) {
      _this.checkForActions(field, _this.formGroup.value);
    });
    this.changes = JSON.parse(JSON.stringify(this.originalValue));
    this.onChangeObjectCreated.emit(this.changes);
    this.changeDetectionRef.detectChanges();
  }

  runUpdateDisableFunction() {
    if (this.updateButtonDisableFunction !== undefined) {
      return this.runLastUpdateFunction(this.formGroup.value, this.updateButtonDisableFunction)
    }
    return false;
  }


  showConfirmdialog() {
    if (!this.compareObjects() && !this.readOnly) {
      this.showConfirm = true;
      this.confirmationService.confirm({
        message: messages.continueMessage,
        accept: () => {
          this.closeModal();
        }, reject: () => {
          this.modalState = true;
          this.showConfirm = false;
        }
      });
    } else {
      this.closeModal();
    }
  }

  /**
   * This function is used to populate the form when user open any row on edit/view mode
   * @param data
   */
  populateModal(data) {
    this.changes = {};
    this.originalValue = {};
    this.rowData = {...data};
    if (this.gridTitle === 'Role Master') {
      this.data = JSON.parse(this.rowData['policy']);
      this.fetchAccesJson(this.data);
    }
    let _this = this;
    this.formGroup.controls['uuid'].setValue(this.rowData['uuid']);
    this.fields.forEach(function (field: Tcolumn) {
      if (field.getColumnType() === 'D') {
        _this.formGroup.controls[field.getField()].setValue(_this.rowData[field.getField()] !== null && _this.rowData[field.getField()] !== undefined && _this.rowData[field.getField()].toString().length > 0 ? new Date(_this.rowData[field.getField()]) : '');
      } else {
        _this.formGroup.controls[field.getField()].setValue(_this.rowData[field.getField()] !== null && _this.rowData[field.getField()] !== undefined && _this.rowData[field.getField()].toString().length > 0 ? _this.rowData[field.getField()] : '');
      }
      if(_this.isReadOnly(field)) {
        _this.formGroup.controls[field.getField()].disable();
      }
      _this.checkForActions(field, data);
    });
    this.originalValue = {...this.formGroup.value};
    this.changes = JSON.parse(JSON.stringify(this.rowData));
    this.onChangeObjectCreated.emit(this.changes);
    this.getHeading();
    this.calculateHeight();
    this.canCheck = true;
    this.updateUserInfo(this.createdId, this.createdTimestamp, 'create');
    this.updateUserInfo(data['updatedBy'], data['updatedTimestamp'], 'update');
    this.isPopulating = false;
  }


  compareObjects() {
    let isEqual: boolean = true;
    let _this = this;
    Object.keys(this.formGroup.value).forEach(function (key) {
      if (_this.formGroup.value[key] !== undefined && _this.formGroup.value[key] !== null && _this.originalValue[key] !== undefined && _this.originalValue[key] !== null && _this.formGroup.value[key] !== _this.originalValue[key]) {
        isEqual = false;
      }
    });
    return isEqual;
  }

  checkForActions(field: Tcolumn, data) {
    let _this = this;
    if (field.getActionToDo() !== undefined) {
      field.getActionToDo().getFieldsToUpdate().forEach(function (actionField) {
        if (actionField['throw']) {
          _this.startConvert(actionField, field, data, true);
        }
      });
    }
  }


  startConvert(actionField, field, data, isAtLoad) {
    if ((actionField['fieldName'] === field.getField() && this.formGroup.controls[actionField['fieldName']].value.length === 0) || actionField['fieldName'] !== field.getField()) {
      this.convertChanges(this.formGroup.controls[field.getField()].value, field, isAtLoad);
    }
  }

  /**
   * return Tcolumn field by its name
   * @param name
   */
  getFieldByName(name): Tcolumn {
    let field: any;
    for (let i = 0; i < this.fields.length; i++) {
      if (this.fields[i].getField() === name) {
        field = this.fields[i];
      }
    }
    return field;
  }

  /**
   * this set all field value to empty
   */
  populateModalEmpty() {
    if (this.formGroup !== undefined) {
      for (let i = 0; i < this.fields.length; i++) {
        this.formGroup.controls[this.fields[i].getField()].setValue('');
      }
    }
  }

  /**
   * this will merege any extra Data if it passed through input
   * @param obj
   */
  mergeExtraData(obj) {
    let keys = Object.keys(this.extraData);
    for (let i = 0; i < keys.length; i++) {
      obj[keys[i]] = this.extraData[keys[i]];
    }
    return obj;
  }

  /**
   * this return if there any default value is there or not
   * @param value
   */
  getDefaultValue(value: any) {
    let defaultVa: any;
    if (value === undefined || value === null) {
      defaultVa = '';
    } else if (typeof (value) === 'object') {
      defaultVa = value.defaultValue;
    } else {
      defaultVa = value;
    }
    return defaultVa;
  }

  /**
   * close input modal
   */
  closeModal() {
    this.originalValue = {};
    this.modalState = false;
    this.resetModal();
    this.oncancel();
  }

  getColvalue(value = 1) {
    if (value === undefined) {
      value = 1;
    }
    return value * this.divisions;
  }

  /**
   * this function call when user click on save/update button
   * @param value
   */
  onCreatePost(value: any) {
    this.isLoading = !this.isLoading;
    this.formValues.emit(value);
    if (this.formGroup.invalid) {
      this.sumitted = true;
      return;
    }
    this.dataservice.save_url = this.saveUrl;
    this.dataservice.bulk_url = this.bulkUrl;
    this.dataservice.edit_url = this.editUrl;
    this.dataservice.get_url = this.getUrl;
    this.isError = false;
    this.showErrorMessage = false;
    if (this.istable == true) {
      this.savetoDatabaseiftable(value, this.formgrouparraylist[0]);
    } else {
      this.savetoDatabase(value);
    }
  }

  /**
   * this function is used to get data from table if there is any and save the data
   * @param data
   * @param formarrayname
   */
  savetoDatabaseiftable(data, formarrayname) {
    const datatosave: any[] = [];
    const _this = this;
    data[formarrayname].forEach(function (item, index) {
      for (let i = 0; i < _this.formcontrolnamelist.length; i++) {
        item[_this.formcontrolnamelist[i]] = data[_this.formcontrolnamelist[i]];
      }
      // item = _this.mergeExtraData(item);
      datatosave.push(item);
    });
    this.dataservice.saveBulkData(datatosave).subscribe(data => {
      this.resetModal();
      this.onapply(data);
    }, (error: HttpErrorResponse) => {
      this.errorMessage = this.commonService.getHttpErrorMessage(error, this.gridTitle, true);
      this.isError = true;
      this.showErrorMessage = true;
      this.isLoading = false;
      // _this.parseError(error)
    });
  }

  confirmDialog(data: any, formarrayname: string, objectReference: string, outputColumnDisplayName: string, outputColumnValue: string, responseMessage: string) {
    var _this = this;
    var message = '';
    if (responseMessage != undefined && responseMessage.length > 0) {
      message = responseMessage;
    } else {
      message = messages.dependencyRef.message;
    }

    message = message.replace("{{objectReference}}", objectReference).replace("{{outputColumnDisplayName}}", outputColumnDisplayName).replace("{{outputColumnValue}}", outputColumnValue);
    this.confirmationService.confirm({
      message: message,
      accept: () => {

        data['continue_resource'] = true;
        if (formarrayname == '') {
          _this.savetoDatabase(data, true);
        } else {
          _this.savetoDatabaseiftable(data, formarrayname);
        }
      },
      reject: () => {
        _this.closeModal();
      }
    });
  }


  /**
   * this will to parse the httpREsponse Error message
   * @param error
   */
  parseError(error, data, formarrayname) {


    this.isLoading = false;
    let err = error.error;
    if (err !== undefined) {

      if (error.status == 424) {
        this.confirmDialog(data, formarrayname, error.error[0].objectReference, error.error[0].outputColumnDisplayName, error.error[0].outputColumnValue, error.error[0].message);
        return;
      } else {


        err = err.cause;
        if (err !== undefined) {
          err = err.cause.message;
          if (err.includes('Duplicate entry')) {
            let value = err.split('for')[0];
            this.errorMessage = value;
            this.isError = true;
            this.showErrorMessage = true;
            this.isLoading = false;
          } else {
            this.errorMessage = err.cause.message;
            this.isError = true;
            this.showErrorMessage = true;
            this.isLoading = false;
          }
        } else {
          this.errorMessage = error['error'];
          if (error['error']['message']) {
            this.errorMessage = error['error'].message;
          }
          this.isError = true;
          this.showErrorMessage = true;
          this.isLoading = false;
        }
      }
    } else {
      this.errorMessage = error.message;
      this.isError = true;
      this.isLoading = false;
      this.showErrorMessage = true;
    }
  }

  /**
   * return form controkl from form group by field name
   * @param formcontrolname
   */
  getFormControl(formcontrolname: any): AbstractControl {
    return this.formGroup.controls[formcontrolname];
  }

  /**
   * return form control value from form group by field name
   * @param formcontrolname
   */
  getFormControlValue(formcontrolname: any) {
    if(this.getFormControl(formcontrolname) !== null && this.getFormControl(formcontrolname) !== undefined) {
      return this.getFormControl(formcontrolname).value;
    }
    return '';
  }

  /**
   * return form controlo if there is  any table
   * @param formgroup
   * @param formcontrolname
   */
  getFormControlTable(formgroup: FormGroup, formcontrolname: any) {
    return formgroup.controls[formcontrolname];
  }

  /**
   * this fucntion used to create a formGroup for a table control
   * @param tableMeta
   */
  createObject(tableMeta: Tcolumn[]): FormGroup {
    const temp = {};
    let _this = this;
    tableMeta.forEach(function (column: Tcolumn) {
      temp[column.getField()] = [_this.getDefaultValue(column.getDefaultValue()), column.getValidators()];
    });
    return this.formBuilder.group(temp);
  }

  /**
   * this function is used to add row in table control
   * @param formcontrlname
   * @param meta
   */
  addRow(formcontrlname, meta) {
    this.formgrouparray = this.formGroup.get(formcontrlname) as FormArray;
    this.formgrouparray.push(this.createObject(meta));
    return false;
  }

  /**
   * this function to remove the row from form control
   * @param index
   * @param formarrayname
   */
  removeRow = function (index, formarrayname) {
    this.formgrouparray = this.formGroup.get(formarrayname) as FormArray;
    if (this.formgrouparray !== null) {
      for (const x in this.formgrouparray.controls) {
        if (index == x) {
          if (!(this.formgrouparray.controls.length == 1)) {
            this.formgrouparray.removeAt(Number(x));
            this.removeRow();
          }
        }
      }
    }
  };


  /**
   * this will reset the complete form to its default state
   */
  resetForm() {
    for (let i = 0; i < this.fields.length; i++) {
      if (this.fields[i].getColumnType() !== 'TB') {
        let fcn = this.fields[i].getField();
        let def = this.getDefaultValue(this.fields[i].getDefaultValue());
        this.formGroup.controls[fcn].setValue(def);
      } else {
        this.formGroup.controls[this.fields[i].getField()].reset();
      }
    }
  }

  /**
   * this fucntion used to reset the ppup modal
   */
  resetModal() {
    this.isError = false;
    this.isLoading = false;
    this.createdDate = '';
    this.updatedDate = '';
    this.updatedBy = '';
    this.createdBy = '';
    this.originalValue = {};
    this.changes = {};
  }

  /**
   * detect changes on any input
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['columnsMap']){
      this.updateColumns();
    }
    if (changes['editUrl']) {
      changes['editUrl'].previousValue = '';
      if (changes['editUrl'].currentValue.length > 0) {
        this.update = true;
        this.viewOnlyMode = true;
        this.isFetching = true;
        this.isPopulating = true;
        this.dataservice.getDataByURL(changes['editUrl'].currentValue).subscribe((data: any) => {
          this.refreshGrid = true;
          this.createdId = data['createdBy'];
          this.createdTimestamp = data['createdTimestamp'];
          this.populateModal(data);
          this.isFetching = false;
        }, error => {
            this.isPopulating = false;
        });
        if (!this.editEnabled) {
          this.readOnly = true;
        }
      } else {
        this.isFetching = false;
      }
    }
    if (changes['modalState']) {
      if (this.modalState) {
        this.clear = false;
      } else {
        this.resetModal();
        // this.resetForm();
      }
    }
    // if (changes['refreshForm'] && changes['refreshForm'].currentValue === true) {
    //   this.refreshGrid = false;
    //   this.refreshForm = false;
    //   this.dataservice.getDataByURL(this.editUrl).subscribe((data: any) => {
    //     this.refreshGrid = true;
    //     this.populateModal(data);
    //   });
    // }
    if (changes['updateField']) {
      /**
       * this function is to update any field value when change data in its other field value
       * if there is change action on other field.
       *  1. FIRST LOOP : obj is map so to get all values need for loop
       *  2. SECOND LOOP : at a time for same field we can change multiple value. (like : defaultValue,metadata or other)
       */
      let obj: Map<any, any> = changes['updateField'].currentValue;
      this.updateFieldChanges(obj);
    }
    if(changes['sections']) {
      this.sectionKeys = Array.from(this.sections.keys());
    }
  }

  /**
   * checks for if the field is disabled or not
   * @param field
   */
  isDisabled(field: Tcolumn) {
    if (field.getFromExtra('meta')['disabled'] !== undefined) {
      return field.getFromExtra('meta')['disabled'];
    }
    return false;
  }

  /**
   * this update dropdown list values
   * @param data
   */
  updateListFields(data) {
    let _this = this;
    this.fields.forEach(function (fid, i) {
      if (fid.getField() === data['field']) {
        _this.fields[i].setListofValues(data['value']);
      }
    })
  }

  /**
   * this update dropdown list values
   * @param data
   */
  updateMinDate(data) {
    let _this = this;
    this.fields.forEach(function (fid, i) {
      if (fid.getField() === data['field']) {
        _this.fields[i].setMinDate(data['value']);
      }
    })
  }

  /**
   * convert form from readonly to ediatble mode and vice-versa
   */
  toggleEditing() {
    this.viewOnlyMode = false;
    this.onButtonClick.emit({buttonHeader: 'Edit/View'});
  }

  /**
   * this function action any other field value using defaultValue Modal
   * @param value
   */
  updateFieldValue(value) {
    let fieldname = value['to'];
    let newvalue = value['value'];
    if (this.formGroup.controls[fieldname].value.length <= 0) {
      this.formGroup.controls[fieldname].setValue(newvalue);
    }
  }

  /**
   * this function check on init and create an object of field and value and throw it to grid if
   * there is any action
   * @param value
   * @param field
   */
  convertChanges(value, field: Tcolumn, isAtLoad) {
    let obj = {
      field: field,
      currentValue: value,
      action: field.getActionToDo(),
      type: 'action',
      canPass: (field.getColumnType() === 'L' || field.getColumnType() === 'B' || field.getColumnType() === 'OB')
    };
    this.throwChanges(obj, isAtLoad);
  }

  /**
   * this is throw changes funtion to throw the actions
   * @param value
   */
  throwChanges(value: any, isAtLoad: boolean = false) {
    let _this = this;
      if (value['type'] === 'action') {
        let action: Action = value['action'];
        let fieldToUpdate: Tcolumn = value['field'];
        if(value['currentValue'] !== '' && (this.changes[value['field']['field']] !== value['currentValue'] || (action!==null && action!==undefined && action.runAlways))) {
          this.changes[value['field']['field']] = value['currentValue'];
          action.getFieldsToUpdate().forEach(function (field, i) {
            if (field['para'] !== undefined) {
              let paras: any[] = field['para'];
              let newcurrent = [];
              paras.forEach(function (para) {
                if (para !== 'DB' && para !== 'L') {
                  if (para !== fieldToUpdate.getField()) {
                    if(_this.formGroup.controls[para] !== null && _this.formGroup.controls[para] !== undefined) {
                      newcurrent.push(_this.formGroup.controls[para].value);
                    } else {
                      newcurrent.push('');
                    }
                  } else {
                    newcurrent.push(value['currentValue']);
                  }
                }
              });
              value['action'].getFieldsToUpdate()[i]['current'] = newcurrent;
            }
          });
          action.setToExtra('rowData', this.formGroup.value);
          this.throwOut(value, isAtLoad);
        }
      } else {
        this.updateFieldValue(value);
      }
  }

  /**
   * return if any form control is diabled or not
   * @param field
   */
  getDisabledFromFormControl(field: Tcolumn) {
    if(this.formGroup.controls[field.getField()] !== null &&this.formGroup.controls[field.getField()] !== undefined) {
      return this.formGroup.controls[field.getField()].disabled;
    }
    return false;
  }

  /**
   * this function provides the parent  key value for tabs to add more data
   */
  getParentKeyValue() {
    return this.formGroup.controls[this.uniqueKey].value;
  }

  addBtnValue(value: any) {
    this.addButtonValue.emit(value);
  }

  isTabs() {
    return (this.gridTabs !== undefined && this.gridTabs.length > 0);
  }


  updateUserInfo(id, date, mode = 'create') {
    if (id !== null) {
      let _this = this;
      this.commonService.getJSONByURL(environment.base_url_new + '/api-iam/api/usermanagementservice/v1/getbyuserid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&userId=' + id).subscribe((next: any[]) => {
        if (next !== undefined && next !== null) {

          if (mode === 'create') {
            _this.createdBy = next['value'];
            _this.createdDate = _this.datePipe.transform(_this.commonService.convertDatetoLocaltimzoeDate(date), environment.full_date_format);
          } else {
            _this.updatedBy = next['value'];
            _this.updatedDate = _this.datePipe.transform(_this.commonService.convertDatetoLocaltimzoeDate(date), environment.full_date_format);
          }
        }
      });
    }
  }

  createUpdateUrl(id: string) {
    let tempUrl = '';
    tempUrl = this.saveUrl.replace(environment.base_url, '');
    tempUrl = environment.base_url + tempUrl.split('/')[0] + '/' + id;
    return tempUrl;
  }

  onBttonClick(value: any) {
    this.onButtonClick.emit(value);
  }

  onClick(value: any) {
    let rowData = this.formGroup.value;
    if (rowData !== undefined) {
      value['screenName'] = this.gridTitle;
      value['rowData'] = rowData;
      this.onButtonClick.emit(value);
    }
  }

  performAction(button: Button) {
    let _this = this;
    let newData = this.commonService.runFunction({
      data: this.formGroup.value,
      updateUrl: this.editUrl,
      saveUrl: this.saveUrl,
      oldData: this.rowData,
      form:this
    }, button.onClick);
    button.fieldValuesToUpdate.forEach(function (fieldName) {
      _this.formGroup.controls[fieldName].setValue(newData[fieldName]);
    });
    this.originalValue = this.formGroup.value;
    this.changes = JSON.parse(JSON.stringify(this.originalValue));
    this.onChangeObjectCreated.emit(this.changes);
  }

  canEdit() {
    return this.gridConfig.canEdit;
  }

  getFetchingClass() {
    return this.showOnModal ? 'fetchingDataOnForm' : 'fetchingDataOnGrid'
  }

  getHeading() {
    if (this.headingRequired) {
      if (!this.update) {
        this.headingForm = 'New ' + this.heading;
      } else {
        if (this.uniqueKey !== undefined && this.uniqueKey !== null && this.uniqueKey.length > 0) {
          this.headingForm = this.formGroup.controls[this.uniqueKey].value;
          if (this.columnsMap.get(this.uniqueKey).getColumnType() === 'L') {
            this.getFromList(this.formGroup.controls[this.uniqueKey].value);
          } else if (this.columnsMap.get(this.uniqueKey).getColumnType() === 'F') {
            this.headingForm = this.formGroup.controls[this.uniqueKey + 'FileName'].value;
          }
        } else {
          this.heading = '';
        }
      }
    }
  }

  getFromList(val: any) {
    let _this = this;
    this.columnsMap.get(this.uniqueKey).getListOfValues().forEach(function (value: KeyValue) {
      if (value.getValue() === val) {
        _this.headingForm = value.getLabel();
      }
    });
  }

  /**
   * F for Show in Form and B for Both and G For Grid
   * @param field
   */

  canShowField(field: Tcolumn) {
    if (field.getVisibilityArea() === 'F' || field.getVisibilityArea() === 'B') {
      if (!this.update) {
        return true;
      } else {
        if (field.getField() === this.uniqueKey && !field.isEditable()) {
          return false;
        } else {
          return true;
        }
      }
    }
    return false;
  }

  getIconFromValidations(formGroup: FormGroup, fields: string[]) {
    let icon = 'pi pi-chevron-right iconCls2';
    if (this.canCheck) {
      fields.forEach(function (field: string) {
        if (field !== '' && formGroup.controls[field] !== null && formGroup.controls[field] !== undefined) {
          if (formGroup.controls[field].valid && icon !== 'pi pi-times colorCross iconCls') {
            icon = 'pi pi-check colorTick iconCls';
          } else {
            if (!formGroup.controls[field].disabled) {
              icon = 'pi pi-times colorCross iconCls';
            }
          }
        }
      });
    }
    return icon;
  }

  currentlyEditing(fields: string[]) {
    return fields.includes(this.currentlyFocuedField) ? 'focused' : ''
  }

  onFocusIn(field: Tcolumn, section: string) {
    this.focusedSection = section;
    this.currentlyFocuedField = field.getField();
  }

  getIconFromFieldValidations() {
    return this.commonService.getIconPath('error_cross');
  }

  currentlyEditingField(field: any) {
    return (field === this.currentlyFocuedField) ? 'focused' : '';
  }

  isErrorInField(formGroup: FormGroup, field: any) {
    return (formGroup.controls[field] !== null && formGroup.controls[field] !== undefined)?!formGroup.controls[field].valid:false;
  }

  goToField(field: string, section: string) {
    this.focusedSection = section;
    this.focuedField = field;
    smoothScrollIntoView(document.getElementById(field));
    document.getElementById(field).focus();
  }

  showSectionForm() {
    if (this.sections !== undefined && Array.from(this.sections.keys()).length > 0) {
      return true;
    }
    return false;
  }

  onInViewportChange(value: any, name) {
    this.focusedSection = value['target']['innerText'];
  }

  getFileName(field: Tcolumn) {
    let fileName: string = '';
    if (field.getColumnType() === 'F') {
      fileName = this.formGroup.controls[field.getField() + 'FileName'].value;
    }
    return fileName;
  }

  toggleReadOnly() {
    this.readOnly = !this.readOnly;
    if (document.getElementById('formBox') !== undefined && document.getElementById('formBox') !== null) {
      document.getElementById('formBox').style.width = '75%';
    }
  }

  isReadOnly(field: Tcolumn) {
    return !this.canEdit() || this.readOnly
        || (this.uniqueKey === field.getField() && this.update && !field.isEditable())
        || (field.getIsUnique() && this.update && !field.isEditable())
        || this.getDisabledFromFormControl(field);
  }

  formClass() {
    if (this.readOnly) {
      return 'withouSection'
    }
    return 'formBox';
  }

  getColumnMultipleValue(col: Tcolumn, rowDatum: any) {
    let sp = rowDatum.toString().split(',');
    let lb = '';
    let _this = this;
    sp.forEach(function (list, i) {
      if (i !== 0) {
        lb += ', ';
      }
      lb += _this.getColumnValue(col, list);
    });
    return lb;
  }

  getListFieldValue(col: Tcolumn, value) {
    /**
     * this method returns value of list
     * from key values pair
     *
     **/
    if (col.getColumnType() === 'ML') {
      return (value !== null && value !== undefined && value.toString().length > 0) ? this.getColumnMultipleValue(col, value) : '';
    } else {
      return (value !== null && value !== undefined && value.toString().length > 0) ? this.getColumnValue(col, value) : '';
    }
  }

  getRowValue(field: Tcolumn, rowData) {
    let output = '';
    if (rowData !== undefined) {
      output = this.commonService.checkAndGetValue(field, rowData);
      if ((output === undefined || output.length === 0) && rowData !== undefined && rowData !== null) {
        let value = rowData[field.getField()];
        if (environment.componentTextType.includes(field.getColumnType())) {
          output = value;
        } else if (field.getColumnType() === 'D') {
          output = value !== undefined && value !== null && value.length > 0 ? this.datePipe.transform(new Date(value), field.getFromExtra('date')['format'] !== undefined ? field.getFromExtra('date')['format'] : environment.dateFormat) : '';
        } else if (field.getColumnType() === 'F') {
          output = rowData[field.getField() + 'FileName'];
        } else if (field.getColumnType() === 'DSL') {
          output = this.commonService.getDualSliderValue(rowData[field.getField()], field, rowData);
        } else if (field.getColumnType() === 'OB' || field.getColumnType() === 'B') {
          output = (value !== undefined && value !== null && value !== '') ? this.getListFieldValue(field, value) : value;
        } else if (environment.componentListType.includes(field.getColumnType())) {
          output = value;
        }else{
          output = value;
        }
      }
    }
    if (output === undefined || output === null || output.length === 0) {
      output = '-';
    }
    return output;
  }

  public getColumnValue(column: Tcolumn, colValue: any) {
    let listOfValues = this.getListOfValues(column);
    if (listOfValues !== undefined) {
      for (let i = 0; i < listOfValues.length; i++) {
        if (listOfValues[i]['value'].toString() === colValue.toString()) {
          return listOfValues[i]['label'];
        }
      }
    }
  };

  getListOfValues(col: Tcolumn) {
    let lov = col.getListOfValues();
    if (this.listOfValues !== undefined) {
      let fieldname = col.getField();
      if (this.listOfValues.has(fieldname)) {
        lov = this.listOfValues.get(fieldname);
      }
    }
    return lov;
  }

  private canRunActionWhilePopulating(metaToUpdate){
    if (!this.isPopulating || (this.isPopulating && !['defaultValue'].includes(metaToUpdate))){
      return true;
    }
    return false;
  }


  throwOut(value: any, isAtGridRefresh: boolean = false) {
    let action: Action = value['action'];
    let current: any = value['currentValue'];
    let field1: Tcolumn = value['field'];
    let canPass: boolean = true;
    let list = [];
    let _this = this;
    let key = '';
    let entityName = '';
    let criteria = [];
    let sortBy = [];
    let rowData = this.formGroup.value;
    let fetchUrl = action.getFetchUrl(rowData);
    if (field1.getColumnType() === 'L'){
      if (current === undefined || current === null || current.length === 0){
        canPass = false;
      }
    }
    if (fetchUrl !== undefined && fetchUrl !== null && fetchUrl.length > 0 && canPass) {
      if (fetchUrl !== environment.base_url + 'filter'){
        if (fetchUrl.charAt(fetchUrl.length - 1) === '=' || fetchUrl.charAt(fetchUrl.length - 1) === '/') {
          fetchUrl += current;
        }
        if (action.getPostObjGeneratorFunc() !== undefined && action.getPostObjGeneratorFunc() !== null) {
          let postObject = this.commonService.runFunction(action.getFromExtra('rowData'), action.getPostObjGeneratorFunc());
          if(postObject !== null && postObject !== undefined && postObject !== {}) {
            this.commonService.getJSONByObject(fetchUrl, postObject).subscribe(next => {
              this.runFieldToUpdate(current, value, list, action, rowData, field1, key, entityName, criteria, sortBy, isAtGridRefresh, next);
            },(error:HttpErrorResponse) =>{
              let errormessage = _this.commonService.getHttpErrorMessage(error,this.gridTitle);
              _this.showToast(errormessage,'error');
            });
          }
        } else {
          if(current !== undefined && current !== null && current.toString().length > 0) {
            this.commonService.getJSONByURL(fetchUrl).subscribe(next => {
              this.runFieldToUpdate(current, value, list, action, rowData, field1, key, entityName, criteria, sortBy, isAtGridRefresh, next);
            },(error:HttpErrorResponse) =>{
              let errormessage = _this.commonService.getHttpErrorMessage(error,this.gridTitle);
              _this.showToast(errormessage,'error');
            });
          }
        }
      } else {
        let keyTemp = action.getFromExtra('key');
        let entityNameTemp = action.getFromExtra('entityName');
        let criteriaTemp = action.getFromExtra('criteria');
        let rowDataTemp = action.getFromExtra('rowData');
        let sortByTemp = action.getFromExtra('sortBy');
        if ((sortByTemp === undefined || sortByTemp.length === 0) && action.getKeyField() !== undefined) {
          sortByTemp = [];
          if (action.getKeyField() !== undefined && action.getKeyField().length > 0) {
            sortByTemp.push(action.getKeyField());
          }
        }
        if (action.getPostObjGeneratorFunc() !== undefined && action.getPostObjGeneratorFunc() !== null) {
          let postObject = this.commonService.runFunction(rowDataTemp, action.getPostObjGeneratorFunc());
          if(postObject !== null && postObject !== undefined && postObject !== {}) {
            this.commonService.getJSONByObject(fetchUrl, postObject).subscribe(next => {
              this.runFieldToUpdate(current, value, list, action, rowData, field1, key, entityName, criteria, sortBy, isAtGridRefresh, next);
            }, (error: HttpErrorResponse) => {
              let errormessage = _this.commonService.getHttpErrorMessage(error, this.gridTitle);
              _this.showToast(errormessage, 'error');
            });
          }
        } else {
          this.commonService.getJSONByObject(fetchUrl, this.commonService.getPostObject(keyTemp, current, entityNameTemp, 'equal', criteriaTemp, rowDataTemp, sortByTemp)).subscribe(next => {
            this.runFieldToUpdate(current, value, list, action, rowData, field1, key, entityName, criteria, sortBy, isAtGridRefresh, next);
          },(error:HttpErrorResponse) =>{
            let errormessage = _this.commonService.getHttpErrorMessage(error,this.gridTitle);
            _this.showToast(errormessage,'error');
          });
        }
      }
    } else {
      this.runFieldToUpdate(current, value, list, action, rowData, field1, key, entityName, criteria, sortBy, isAtGridRefresh, undefined);
    }
  }

  runFieldToUpdate(current, value, list, action, rowData, field1, key, entityName, criteria, sortBy, isAtGridRefresh, actionDBResponse) {
    let _this = this;
    action.getFieldsToUpdate().forEach(function (field) {
      if (_this.canRunActionWhilePopulating(field['metaToUpdate'])){
        if (isAtGridRefresh) {
          if (field['throw']) {
            _this.performActionOnThrow(current, value, list, field, action, rowData, field1, key, entityName, criteria, sortBy, actionDBResponse);
          }
        } else {
          _this.performActionOnThrow(current, value, list, field, action, rowData, field1, key, entityName, criteria, sortBy, actionDBResponse);
        }
      }
    });
  }

  performActionOnThrow(current, value, list, field, action: Action, rowData, field1, key, entityName, criteria, sortBy, actionDBResponse) {
    let _this = this;
    if ((typeof (current) === 'object') || _this.commonService.isNullorNone(current) || (value['canPass'])) {
      if (!(field['fieldName'] === field1.getField() && current.length > 0)) {
        _this.performStandaloneAction(field, current, action);
      } else {
        if (action.getFromExtra('canPass')) {
          _this.performStandaloneAction(field, current, action);
        }
      }
      let isActionUrl: boolean = false;
      let url = '';
      let keyField = '';
      let valueField = '';
      let postObject = {};
      if (field['fetchUrl'] !== undefined && field['fetchUrl'] !== null && field['fetchUrl'].length > 0) {
        url = field['fetchUrl'];
        if (typeof (field['key']) === 'string') {
          keyField = field['key'];
        } else {
            if(field['key'] !== undefined && field['key'] !== null) {
                keyField = field['key']['label'];
                valueField = field['key']['value'];
            }
        }
        postObject = _this.commonService.getDynamicPostObject(field, rowData, field1, _this.formGroup);
      } else {
        url = action.getFetchUrl(rowData);
        isActionUrl = true;
        keyField = action.getKeyField();
        valueField = action.getValueField();
      }
      if (url !== undefined && _this.commonService.isNullorNone(current)) {
        let islist = (_this.columnsMap.get(field['fieldName']).getColumnType() === 'L' || _this.columnsMap.get(field['fieldName']).getColumnType() === 'S');
        if (isActionUrl && actionDBResponse !== undefined && actionDBResponse !== null) {
          if (islist && action.getCovertToListRequired()) {
            if (actionDBResponse['_embedded']) {
              actionDBResponse['_embedded'][Object.keys(actionDBResponse['_embedded'])[0]].forEach(function (result) {
                list.push(new KeyValue(result[keyField], result[valueField]));
              });
            } else {
              actionDBResponse.forEach(function (result) {
                list.push(new KeyValue(result[keyField], result[valueField]));
              });
            }
          }
          _this.updateValuetoNew(value, list, actionDBResponse, field);
        } else {
          if (url !== environment.base_url + 'filter') {
            if (url.charAt(url.length - 1) === '=' || url.charAt(url.length - 1) === '/') {
              let valueTemp = '';
              if (action.getFromExtra('valueFormat') !== undefined) {
                valueTemp = action.getFromExtra('valueFormat').replace(new RegExp('<value>', 'g'), current);
              } else {
                valueTemp = current;
              }
              url = url + valueTemp;
            }
            if (field['postObjectFunction'] !== undefined) {
              if (postObject !== undefined && postObject !== null && Object.keys(postObject).length > 0) {
                _this.commonService.post(url, postObject).subscribe(function (data: any) {
                  list = [];
                  if (islist && action.getCovertToListRequired()) {
                    if (data['_embedded']) {
                      data['_embedded'][Object.keys(data['_embedded'])[0]].forEach(function (result) {
                        list.push(new KeyValue(result[keyField], result[valueField]));
                      });
                    } else {
                      data.forEach(function (result) {
                        list.push(new KeyValue(result[keyField], result[valueField]));
                      });
                    }
                  }
                  _this.updateValuetoNew(value, list, data, field);
                },(error:HttpErrorResponse) =>{
                  let errormessage = _this.commonService.getHttpErrorMessage(error,this.gridTitle);
                  _this.showToast(errormessage,'error');
                });
              }
            } else {
              _this.commonService.getJSONByURL(url).subscribe(function (data: any) {
                if (islist && action.getCovertToListRequired()) {
                  if (data !== null && data !== undefined) {
                    if (data['_embedded']) {
                      data['_embedded'][Object.keys(data['_embedded'])[0]].forEach(function (result) {
                        list.push(new KeyValue(result[keyField], result[valueField]));
                      });
                    } else {
                      if (JSON.stringify(data).charAt(0) === '[') {
                        data.forEach(function (result) {
                          list.push(new KeyValue(result[keyField], result[valueField]));
                        });
                      }
                    }
                  }
                  _this.updateValuetoNew(value, list, data, field);
                }
              },(error:HttpErrorResponse) =>{
                let errormessage = _this.commonService.getHttpErrorMessage(error,this.gridTitle);
                _this.showToast(errormessage,'error');
              });
            }
          } else {
            let list: any[] = [];
            sortBy = [];
            let valueTemp = '';
            if (action.getFromExtra('valueFormat') !== undefined) {
              valueTemp = action.getFromExtra('valueFormat').replace(new RegExp('<value>', 'g'), current);
            } else {
              valueTemp = current;
            }
            if (field['extra'] !== undefined) {
              if (field['extra']['key']) {
                key = field['extra']['key'];
              }
              if (field['extra']['entityName']) {
                entityName = field['extra']['entityName'];
              }
              if (field['extra']['criteria']) {
                criteria = field['extra']['criteria'];
              }
              if (field['extra']['sortBy'] !== undefined && field['extra']['sortBy'].length > 0) {
                sortBy = field['extra']['sortBy'];
              } else {
                if (keyField !== undefined && keyField.length > 0) {
                  sortBy.push(keyField);
                }
              }
            } else {
              key = action.getFromExtra('key');
              entityName = action.getFromExtra('entityName');
              criteria = action.getFromExtra('criteria');
              rowData = action.getFromExtra('rowData');
              sortBy = action.getFromExtra('sortBy');
              if ((sortBy === undefined || sortBy.length === 0) && keyField !== undefined) {
                sortBy = [];
                if (keyField !== undefined && keyField.length > 0) {
                  sortBy.push(keyField);
                }
              }
            }
            if (field['postObjectFunction'] !== undefined) {
              if (postObject !== undefined && postObject !== null && Object.keys(postObject).length > 0) {
                _this.commonService.getJSONByObject(url, postObject).subscribe(function (data: any) {
                  if (islist) {
                    data.forEach(function (result) {
                      list.push(new KeyValue(result[keyField], result[valueField]));
                    });
                  }
                  _this.updateValuetoNew(value, list, data, field);
                },(error:HttpErrorResponse) =>{
                  let errormessage = _this.commonService.getHttpErrorMessage(error,this.gridTitle);
                  _this.showToast(errormessage,'error');
                });
              }
            } else {
              _this.commonService.getJSONByObject(url, _this.commonService.getPostObject(key, valueTemp, entityName, 'equal', criteria, rowData, sortBy)).subscribe(function (data: any) {
                if (islist) {
                  data.forEach(function (result) {
                    list.push(new KeyValue(result[keyField], result[valueField]));
                  });
                }
                _this.updateValuetoNew(value, list, data, field);
              },(error:HttpErrorResponse) =>{
                let errormessage = _this.commonService.getHttpErrorMessage(error,this.gridTitle);
                _this.showToast(errormessage,'error');
              });
            }
          }
        }
      }
    } else {
      let data = [];
      if (current !== 'none' && current !== '') {
        _this.updateValuetoNew(value, list, data, field);
      }
    }
  }

  performStandaloneAction(field, current, action) {
    let data = [];
    let list = [];
    let functionvalue;
    let _this = this;
    if (field['func'] !== undefined) {
      let parac = this.getParametersValue(list, data, field, current);
      let data1 = this.runFunction(parac, field['func']);
      let fieldToUpdate = this.columnsMap.get(field['fieldName']);
      if (fieldToUpdate !== undefined) {
        if (fieldToUpdate.getColumnType() === 'L') {
          list = data1;
        } else {
          let obj = {};
          obj[field['key']] = data1;
          data.push(obj);
        }
        functionvalue = data1;
        if (field['metaToUpdate'] === undefined) {
          this.updateValueToField(field, list, data, action);
        }
      }
    }
    if (field['metaToUpdate'] !== undefined && field['metaToUpdate'].length > 0) {
      if (field['func'] !== undefined) {
        _this.updateMetadata(field['fieldName'], field['metaToUpdate'], functionvalue,field);
      } else {
        _this.updateMetadata(field['fieldName'], field['metaToUpdate'], current,field);
      }
    }
  }

  getParametersValue(list: any[], data: any[], field: any, value: any) {
    let parac = [];
    let _this = this;
    if (field['para'] !== undefined) {
      field['para'].forEach(function (para) {
        if (para === 'DB') {
          parac.push(data)
        } else if (para === 'OV') {
          let originalValue = _this.originalValue;
          parac.push(originalValue)
        } else if (para === 'L') {
          list.splice(0, 0, new KeyValue('Select', ''));
          parac.push(list);
        } else {
          if(_this.formGroup.controls[para] !== null && _this.formGroup.controls[para] !== undefined) {
            parac.push(_this.formGroup.controls[para].value);
          } else {
            parac.push(para);
          }
        }
      });
    } else {
      parac = [value];
    }
    return parac;
  }

  updateValuetoNew(value, list: any[], data: any[], field) {   //function to update values when some other fields changes ites value
    let _this = this;
    let action = value['action'];
    let functionvalue: any;
    if (field['func'] !== undefined) {
      let parac = this.getParametersValue(list, data, field, value['currentValue']);
      let data1 = _this.runFunction(parac, field['func']);
      let ftup = this.columnsMap.get(field['fieldName']);
      if (ftup.getColumnType() === 'L') {
        list = data1;
      } else {
        if (field['key'] !== undefined) {
          let obj = {};
          obj[field['key']] = data1;
          data.push(obj);
        }
      }
      functionvalue = data1;
      if (field['metaToUpdate'] === undefined) {
        _this.updateValueToField(field, list, data, action);
      } else {
        if (field['metaToUpdate'].length > 0) {
          _this.updateMetadata(field['fieldName'], field['metaToUpdate'], functionvalue,field);
        }
      }
    } else {
      if (field['metaToUpdate'] !== undefined && field['metaToUpdate'].length > 0) {
        let value: any;
        if (data['_embedded']) {
          data['_embedded'][Object.keys(data['_embedded'])[0]].forEach(function (result) {
            value = result[field['key']];
          });
        } else {
          if (data.length > 0) {
            value = data[0][field['key']]
          }
        }
        if (this.columnsMap.get(field['fieldName']).getColumnType() === 'D') {
          if (value === undefined || value === null) {
            value = '';
          } else {
            value = new Date(value.toString());
          }
        }
        _this.updateMetadata(field['fieldName'], field['metaToUpdate'], value,field);
      } else {
        _this.updateValueToField(field, list, data, action);
      }
    }
  }

  runFunction(value: any[], callback) {
    return callback(value);
  }

  runLastUpdateFunction(value: any[], callback) {
    let val = this.runFunction(value, callback);
    this.updateDisable = val;
    this.readOnly = val;
    return val;
  }

  updateValueToField(object, list, data, action: Action) {
    let fieldname = object['fieldName'];
    let getValueFrom = object['getValueFrom'];
    let valueTemp = '';
    let key = object['key'];
    let _this = this;
    let ftup = this.columnsMap.get(fieldname);
    if (ftup !== undefined) {
      if (ftup.getColumnType() === 'L') {
        if (object['key'] !== undefined && typeof (object['key']) === 'string' && object['key'].length > 0) {
          if (data.length > 0) {
            valueTemp = data[0][object['key']];
          }
          this.metaFunction({field: fieldname, value: valueTemp, type: 'O',object:object});
        } else {
          if (action.getAddDefault().length > 0) {
            action.getAddDefault().forEach(function (defaultV) {
              list.splice(0, 0, defaultV);
            })
          } else {
            if ((list.length === 0 || (list[0]['value'] !== 'none' && list[0]['value'] !== '')) && (action.getFromExtra('addSelect') === undefined || !action.getFromExtra('addSelect'))) {
              list.splice(0, 0, new KeyValue('Select', ''));
            }
          }
          this.columnsMap.get(fieldname).setListofValues(list);
        }
      } else {
        let value: any = '';
        if (data !== undefined) {
          if (data['_embedded']) {
            data['_embedded'][Object.keys(data['_embedded'])[0]].forEach(function (result) {
              value = result[key];
            });
          } else {
            if (typeof (data) === 'object') {
              if (data.length > 0) {
                value = data[0][key]
              }
            } else {
              value = data;
            }
          }
          if (ftup.getColumnType() === 'D' && value !== undefined && value.toString().length > 0) {
            value = new Date(value);
          }
        }
        if (value !== undefined && value.toString().length > 0) {
          if (getValueFrom !== undefined) {
            if (getValueFrom['url'] !== environment.base_url + 'filter') {
              this.commonService.getJSONByURL(getValueFrom['url']).subscribe(function (data: any) {
                if (data['_embedded']) {
                  data['_embedded'][Object.keys(data['_embedded'])[0]].forEach(function (result) {
                    if (result[getValueFrom['key']].toString() === value.toString()) {
                      _this.metaFunction({field: fieldname, value: result[getValueFrom['value']], type: 'O',object:object});
                      return;
                    }
                  });
                } else {
                  data.forEach(function (result) {
                    if (result[getValueFrom['key']].toString() === value.toString()) {
                      _this.metaFunction({field: fieldname, value: result[getValueFrom['value']], type: 'O',object:object});
                      return;
                    }
                  });
                }
              });
            } else {
              this.commonService.getJSONByObject(getValueFrom['url'], _this.commonService.getPostObject(getValueFrom['entityKey'], valueTemp, getValueFrom['entityName'], getValueFrom['operation'], getValueFrom['criteria'])).subscribe(function (data: any) {
                data.forEach(function (result) {
                  if (result[getValueFrom['key']].toString() === value.toString()) {
                    _this.metaFunction({field: fieldname, value: result[getValueFrom['value']], type: 'O',object:object});
                    return;
                  }
                });
              });
            }
          } else {
            _this.metaFunction({field: fieldname, value: value, type: 'O',object:object});
          }
        } else {
          _this.metaFunction({field: fieldname, value: '', type: 'O',object:object});
        }
      }
    }
  }

  updateMetadata(fieldname: any, metaname: any, value: any,object:any) {
    if (metaname === 'minDate') {
      if (typeof (value) === 'string') {
        value = new Date(value);
      }
      this.columnsMap.get(fieldname).setMinDate(value);
    } else if (metaname === 'maxDate') {
      if (typeof (value) === 'string') {
        value = new Date(value);
      }
      this.columnsMap.get(fieldname).setMaxDate(value);
    } else if (metaname === 'defaultValue') {
      this.metaFunction({field: fieldname, value: value, metaname: metaname, type: 'O',object:object});
    } else if (metaname === 'listvalues') {
      this.columnsMap.get(fieldname).setListofValues(value);
    } else if (metaname === 'validators') {
      if (value !== undefined) {
        this.metaFunction({field: fieldname, value: value, metaname: metaname, type: 'M'});
      }
    } else if (metaname === 'extra') {
      this.metaFunction({field: fieldname, value: value, metaname: metaname, type: 'M'});
    } else if (metaname === 'header') {
      this.columnsMap.get(fieldname).setHeader(value);
    } else if (metaname === 'columnType') {
      this.columnsMap.get(fieldname).setColumnType(value);
    }
  }

  updateFieldChanges(obj: Map<string, any>) {
    if (obj !== undefined && obj.size > 0) {
      let _this = this;
      obj.forEach(function (data1, key) {
        data1.forEach(function (data) {
          if (data['type'] === 'O') {
            if (_this.getFieldByName(data['field']).getColumnType() === 'D' && data['value'].toString() === 'Invalid Date') {
              _this.formGroup.controls[data['field']].setValue('');
            } else {
              _this.formGroup.controls[data['field']].setValue(data['value']);
            }
          } else if (data['type'] === 'M') {
            if (data['metaname'] === 'validators') {
              _this.formGroup.controls[data['field']].setValidators(data['value']);
              _this.formGroup.controls[data['field']].updateValueAndValidity();
            } else if (data['metaname'] === 'extra') {
              if (data['value'] !== undefined && data['value']['meta'] !== undefined && data['value']['meta']['disabled'] !== undefined) {
                if (data['value']['meta']['disabled'] === true) {
                  _this.formGroup.controls[data['field']].disable();
                } else {
                  _this.formGroup.controls[data['field']].enable();
                }
              }
              _this.columnsMap.get(data['field']).setExtra(data['value']);
            } else if (data['metaname'] === 'minDate') {
              _this.updateMinDate(data);
            }
          } else {
            _this.updateListFields(data);
          }
        });
      });
    }
  }

  private updateChanges(fieldName,newValue,object) {
    if(object['updateChangeValue'] === undefined || object['updateChangeValue'] === null || object['updateChangeValue']) {
      this.changes[fieldName] = newValue;
    }
  }

  metaFunction(data) {
    let _this = this;
    if (data['type'] === 'O') {
      if (this.getFieldByName(data['field']).getColumnType() === 'D' && data['value'] !== undefined && data['value'] !== null && data['value'].toString() === 'Invalid Date') {
        this.formGroup.controls[data['field']].setValue('');
        this.updateChanges(data['field'],'',data['object']);
      } else {
        this.formGroup.controls[data['field']].setValue(data['value']);
        this.updateChanges(data['field'],data['value'],data['object']);
      }
      if (this.columnsMap.get(data['field']).getActionToDo() !== undefined && (this.columnsMap.get(data['field']).getFromExtra('action')['runOnParentChange'] === undefined || this.columnsMap.get(data['field']).getFromExtra('action')['runOnParentChange'] === null || this.columnsMap.get(data['field']).getFromExtra('action')['runOnParentChange'])) {
        let canRun:boolean = true;
        let para:any[] = [];
        this.columnsMap.get(data['field']).getActionToDo().getFieldsToUpdate().forEach(function (actionField) {
          if(actionField['canRunParentFunction'] !== undefined && actionField['canRunParentFunction'] !== null) {
            if(actionField['canRunParentFunctionPara'] !== undefined && actionField['canRunParentFunctionPara'] !== null && actionField['canRunParentFunctionPara'].length > 0) {
              actionField['canRunParentFunctionPara'].forEach(function (param:any) {
                para.push(_this.formGroup.controls[param].value);
              });
            } else {
              para.push(_this.formGroup.value);
            }
            canRun = _this.commonService.runFunction(para,actionField['canRunParentFunction']);
          }
          if(canRun) {
            _this.startConvert(actionField, _this.columnsMap.get(data['field']), _this.formGroup.value, false);
          }
        });
      }
    } else if (data['type'] === 'M') {
      if (data['metaname'] === 'validators') {
        this.formGroup.controls[data['field']].setValidators(data['value']);
        this.formGroup.controls[data['field']].updateValueAndValidity();
      } else if (data['metaname'] === 'extra') {
        if (data['value'] !== undefined && data['value']['meta'] !== undefined && data['value']['meta']['disabled'] !== undefined) {
          if (data['value']['meta']['disabled'] === true) {
            this.formGroup.controls[data['field']].disable();
          } else {
            this.formGroup.controls[data['field']].enable();
          }
        }
        this.columnsMap.get(data['field']).setExtra(data['value']);
      } else if (data['metaname'] === 'minDate') {
        this.updateMinDate(data);
      }
    } else {
      this.updateListFields(data);
    }
  }

  showOnModalClass() {
    return this.showOnModal ? 'footerOnForm' : 'footerOnGrid';
  }

  @HostListener("window:resize", [])
  public onResize() {
    this.calculateHeight();
  }

  calculateHeight() {
    if (document.getElementById('sectionForm') !== null && document.getElementById('style-1') !== null) {
      let offsetTop = document.getElementById('sectionForm')['offsetTop'];
      let offsetHeight = document.getElementById('sectionForm')['offsetParent']['offsetHeight'];
      this.formHeight = offsetHeight - (offsetTop + (96 - 15)) + 'px';
      document.getElementById('style-1').style.maxHeight = this.formHeight;
    }
    if (this.gridTitle === 'Role Master') {
      this.calculateAccessPolicyHeight();
    }
  }

  onModalInView() {
    this.calculateHeight();
  }

  /** this alll for acces policy only used in role master ***/
  collapseAll() {
    this.expandCount = 0;
    this.expandAllValue = false;
  }

  expandAll() {
    this.expandCount = 0;
    this.expandAllValue = true;
  }

  updateValue(value: any) {
    this.searchValue = value;
  }

  calculateAccessPolicyHeight() {
    let el = document.getElementById('contentBox');
    if(el !== undefined && el !== null){
      this.contentHeight = ((window['visualViewport'].height - el.offsetTop) - 160) + 'px';
    }
  }

  resetExpandValue() {
    this.expandCount++;
    if (this.expandCount === this.data.length) {
      this.expandAllValue = undefined;
    }
  }

  getScreenObject(item: any) {
    return this.data[this.getScreenObjectIndex(item)];
  }

  getScreenObjectIndex(item: any) {
    return this.localNameList.indexOf(item)
  }

  getFromBase(index: any) {
    return this.baseFile[index];
  }

  isHidden(data: any) {
    if (data['info']['screenName'].toLowerCase().includes(this.searchValue.toLowerCase())) {
      return false;
    }
    return true;
  }

  contains(list: any[], searchValue: string) {
    let contains: boolean = false;
    list.forEach(function (data: string) {
      if (!contains && data['info']['screenName'].toLowerCase().includes(searchValue.toLowerCase())) {
        contains = true;
      }
    });
    return !contains;
  }

  ToggleAll(key:string, toggleValue: boolean = false) {
    let _this = this;
    let keys:any[]= [];
    let accesskeys:any[]= [];
    this.accessMap.get(key).forEach(function (obj:any) {
      keys = Object.keys(obj);
      keys.forEach(function (childKey) {
        if(childKey !== 'info') {
          accesskeys = Object.keys(obj[childKey]);
          accesskeys.forEach(function (akey) {
            obj[childKey][akey] = toggleValue;
          });
        }
      });
    });
  }

  fetchAccesJson(accessPolicyJson:any) {
    let _this = this;
    if (this.gridTitle === 'Role Master') {
      this.accessMap = new Map<string, any>();
      let data = accessPolicyJson;
      _this.baseFile = data;
      if (_this.data === undefined || _this.data === null) {
        _this.data = data;
      }
      _this.keys = Object.keys(data);
      _this.accessMap = new Map<string, any[]>();
      let sectionName:string = '';
      _this.localNameList = Object.keys(data);
      _this.keys.forEach(function (key) {
        sectionName = data[key]['info']['groupName'];
        if(!_this.accessMap.has(sectionName)){
          _this.accessMap.set(sectionName,[]);
        }
        _this.accessMap.get(sectionName).push(data[key]);
      });
      _this.accesssections = Array.from(this.accessMap.keys());
    }
  }

  /*****************Acces Policy End Here *******************/
  toggleShowError() {
    this.showErrorMessage = !this.showErrorMessage;
  }

  onValueChange(value: any, field: string) {
    if (!this.canCheck) {
      if (value !== undefined && value !== null && value !== 0 && value !== this.getDefaultValue(this.columnsMap.get(field).getDefaultValue())) {
        this.canCheck = true;
      }
    }
  }

  /**
   * this function is used the save the data to database
   * @param value
   */
  private savetoDatabase(value: any, continue_resource: Boolean = false) {
    let _this = this;
    let datatosave: any = {};
    if (continue_resource)
      datatosave['continue_resource'] = continue_resource;
    let len = Object.keys(this.formGroup.controls);
    let column:Tcolumn;
    len.forEach(function (key:any) {
      if(key === 'uuid'){
        datatosave[key] = _this.formGroup.controls[key].value;
      } else {
        column = _this.columnsMap.get(key);
        if (column !== null && column !== undefined) {
          if (column.getColumnType() === 'D') {
            datatosave[key] = new Date(_this.formGroup.controls[key].value + 'UTC');
          } else {
            datatosave[key] = _this.formGroup.controls[key].value;
          }
        }
      }
    });
    datatosave['tenantId'] = this.commonService.getFromStorage('tenantId');
    datatosave['updatedBy'] = this.masterCommonService.getUserId();
    if (this.gridTitle === 'Role Master') {
      datatosave['policy'] = JSON.stringify(this.data);
    }

    if (_this.extraObjectToSave !== undefined && _this.extraObjectToSave !== null){
      if(Object.keys(_this.extraObjectToSave).length > 0) {
        Object.keys(_this.extraObjectToSave).forEach(function(value){
          datatosave[value] = _this.extraObjectToSave[value];
        })
      }
    }

    if (this.update === false) {
      datatosave['createdBy'] = this.masterCommonService.getUserId();
      datatosave['uuid'] = '';
      if(this.updatePayloadFunction !== null && this.updatePayloadFunction !== undefined) {
        datatosave =this.commonService.runFunction({data:datatosave,originalData:datatosave},this.updatePayloadFunction);
      }
      if (!this.dontSaveMode) {
        this.dataservice.saveSingleData(datatosave).subscribe(data => {
          if (!this.closeAfterSave) {
            this.populateModal(data);
            this.rowData = data;
            this.resetModal();
            this.createdId = data['createdBy'];
            this.createdTimestamp = data['createdTimestamp'];
          }
          this.onapply(data);
        }, (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.errorMessage = this.commonService.getHttpErrorMessage(error, this.gridTitle, false);
          this.isError = true;
          this.showErrorMessage = false;
          let severity = 'error';
          if(this.errorMessage.includes('warning')){
            severity = 'warn';
          }
          this.showToast(error.error['status'], severity);
        });
      } else {
        this.OnApply.emit({isUpdate: this.update, data: datatosave, close: this.closeAfterSave});
        this.modalState = false;
      }
    } else {
      datatosave['createdBy'] = this.createdId;
      datatosave['createdTimestamp'] = this.createdTimestamp;
      if(this.updatePayloadFunction !== null && this.updatePayloadFunction !== undefined) {
        datatosave =this.commonService.runFunction({data:datatosave,originalData:datatosave},this.updatePayloadFunction);
      }
      if (!this.dontSaveMode) {
        if (this.updateUrl !== undefined && this.updateUrl !== null && this.updateUrl.length > 0) {
          if (this.updateUrlType === 'post') {
            this.commonService.post(this.updateUrl, datatosave).subscribe(data => {
              this.resetModal();
              this.onapply(data);
            }, (error: HttpErrorResponse) => {

              this.errorMessage = this.commonService.getHttpErrorMessage(error, this.gridTitle, false);
              this.isError = true;
              this.showToast(this.errorMessage, 'error');
              this.isLoading = false;
            });
          } else if (this.updateUrlType === 'patch') {

            this.commonService.patch(this.updateUrl, datatosave).subscribe(data => {
              this.resetModal();
              this.onapply(data);
            }, (error: HttpErrorResponse) => {

              this.errorMessage = this.commonService.getHttpErrorMessage(error, this.gridTitle, false);
              this.isError = true;
              this.showToast(this.errorMessage, 'error');
              this.isLoading = false;
            });
          }

        } else {
          this.dataservice.updateSingleData(datatosave, this.editUrl).subscribe(data => {
            this.resetModal();
            this.onapply(data);
          }, (error: HttpErrorResponse) => {
            this.errorMessage = this.commonService.getHttpErrorMessage(error, this.gridTitle, false);
            this.isError = true;
            this.showToast(this.errorMessage, 'error');
            this.isLoading = false;
          });
        }
      } else {
        this.OnApply.emit({isUpdate: this.update, data: datatosave, close: this.closeAfterSave});
        this.modalState = false;
      }
    }

  }

  /**
   * this fucntion call after data save/update
   * @param data
   */
  private onapply(data: any) {
    this.formValues.emit(this.formGroup.value);
    this.OnApply.emit({isUpdate: this.update, data: data, close: this.closeAfterSave});
    if (!this.closeAfterSave) {
      if (data['_links']) {
        this.editUrl = data['_links']['self']['href'];
        this.editUrl = this.editUrl.replace('http://localhost:8080/', environment.base_url);
      } else {
        this.editUrl = this.createUpdateUrl(data['uuid']);
      }
      this.updateUserInfo(data['createdBy'], data['createdTimestamp'], 'create');
      this.updateUserInfo(data['updatedBy'], data['updatedTimestamp'], 'update');
      if (this.editUrl.length > 0) {
        this.update = true;
      }
    } else {
      this.modalState = false;
    }
    this.onRowDataSave.emit(data);
  }

  private oncancel() {
    this.OnCancel.emit();
  }


  showToast(msg, severity: string = 'success') {
    this.messageService.add({
      severity: severity,
      summary: msg
    });
  }

  getSectionKeys() {
    return this.sections.keys();
  }

  public setControlValue(controlName,value){
    this.formGroup.controls[controlName].setValue(value);
  }
}
