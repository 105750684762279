<div style="display: inline-flex;width: 100%" xmlns="http://www.w3.org/1999/html">
    <masters-sidenav></masters-sidenav>
    <div id="pinwidth" style="width: 100%;margin-left: 80px">
        <div class="sectionCardTop">
            <div class="breadcrumbPane">
                <ctrm-breadcrumb [collapsabel]="false"
                                 [items]="breadCrumbService.getBreadCrumb()"
                                 [showIcon]="false"></ctrm-breadcrumb>
            </div>
            <div class="actionPane">
                <div class="screenDetails">
                    <img [src]="'../../../assets/images/svg/Approval.svg'"/>
                    <p>Approval Workflow</p>
                </div>
            </div>
        </div>
        <!--        form content-->
        <div style="display: inline-flex;width:100%;background: #FFFFFF;padding: 5px;margin-left: 7px;margin-right: 7px">

            <div style="background: #F2F2F28F;width:20%;padding-top: 10px;">
                <div>
                    <p style="padding-left: 20px;font-weight: bold">Approval List</p>
                </div>
                <div class="fieldBox">
                    <label class="label ng-star-inserted">Name:</label>
                    <p>{{workflowName}}</p>
                </div>
                <div class="fieldBox" style="margin-right: 20px;">
                    <label class="label ng-star-inserted">Trigger Event:</label>
                   <p>{{triggerEvent}}</p>
                </div>
                <div class="fieldBox" style="margin-right: 20px;">
                    <label class="label ng-star-inserted">Approver Span:</label>
                    <p>{{approvalSpan}}</p>
                </div>
                <div class="fieldBox">
                    <label class="label ng-star-inserted">Post Action:</label>
                   <p>{{postAction}}</p>
                </div>
                <div class="fieldBox">
                    <label class="label ng-star-inserted">Negative Post Action:</label>
                    <p>{{negativePostAction}}</p>
                </div>

                <div class="fieldBox" style="margin-left: 10px; display: flex; margin-top: 5px">
                    <p-checkbox  style="display: inline-flex;width: 170px" [(ngModel)]="isSystem"
                                 [binary]="true" [label]="'Is System'" [disabled]="true">

                    </p-checkbox>
                </div>
                <div class="fieldBox" style="margin-left: 10px; display: flex; margin-top: 5px">
                    <p-checkbox  style="display: inline-flex;width: 170px" [(ngModel)]="isActive"
                                 [binary]="true" [label]="'Is Active'" [disabled]="true"></p-checkbox>
                </div>
            </div>
            <div style="width:80%;margin-left: 10px;margin-right: 10px;">
<!--                    <div class="btnDiv btnedit" (click)="onclickEdit()" >-->
<!--                        <i style ="color: white" class="fas fa-pen editicon" ></i>-->
<!--                        <p  style="color: white">Edit</p>-->
<!--                    </div>-->
                <xceler-grid [columns]="approverWorkFlow"
                             [tableStyle]="{backgroundColor:'#15104E'}"
                             [data]="value"
                             [customViewTemplated]="[editTemplate]"
                             [fieldsWithCustomView]="['delete']"
                             (onButtonClick)="onclickButton($event)">
                </xceler-grid>
            </div>
        </div>

        <!--        form content end-->
    </div>
</div>
<ng-template #editTemplate let-rowData="rowData" let-index="index">
    <i  (click)="onclickEdit()" class="fas fa-pen editicon"
        pTooltip="Edit"
        tooltipPosition="bottom">
    </i>
</ng-template>


<app-desired-end-status *ngIf="display"
[display]="true"
[rowData]="rowdata"
(onClose)="onclose()"
[viewOnly]="true">
</app-desired-end-status>


