<ctrm-input-form (OnApply)="OnFormClose($event)"
                 (OnCancel)="canceledNew()"
                 [closeAfterSave]="true"
                 [columnsMap]="grid.getColumn()"
                 [gridConfig]="grid.getGridConfig()"
                 [gridTitle]="grid.getTitle()"
                 [heading]="grid.getTitle()"
                 [noOfColumns]="3"
                 [readOnly]="true"
                 [saveUrl]="grid.getSaveUrl()" [sections]="grid.getSections()"
                 [showOnModal]="true"
                 [editUrl]="editUrl"
                 [updateUrl]="updateUrl"
                 [updatePayloadFunction]="updatepayloadfunction"
                 [uniqueKey]="grid.getPrimaryKey()">
</ctrm-input-form>