export class Breadcrumb {
  constructor(
    public icon: string,
    public label: string,
    public routeTo: string,
    public childs: Breadcrumb[] = [],
    public isSelected: boolean = false,
    public skipLocationChange: boolean = true,
    public params?: any
  ) {
  }

  public getIcon() {
    return this.icon;
  }

  public getLabel() {
    return this.label;
  }

  public getRouteTo() {
    return this.routeTo;
  }

  public setRoute(newRoute) {
    this.routeTo = newRoute;
  }

  public getChild() {
    return this.childs;
  }

  public getIsSelected() {
    return this.isSelected;
  }

  public getSkipLocationChange() {
    return this.skipLocationChange;
  }

  public setIcon(icon) {
    this.icon = icon;
  }

  public setLabel(label) {
    this.label = label;
  }

  public pushChild(child) {
    this.childs.push(child);
  }

  public setIsSelected(isSelected) {
    this.isSelected = isSelected;
  }


  public setParams(params) {
    this.params = params;
  }

  public getParams() {
    return this.params;
  }

  public setChilds(childs: Breadcrumb[] = []) {
    this.childs = childs;
  }
}
