import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Tcolumn} from '../../tcolumn.model';

@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.css']
})
export class SortComponent implements OnInit {
  @Input() columns: Tcolumn[] = [];
  @Output() onSort = new EventEmitter();
  allcolumns: any[] = [];
  flist: any[] = [];
  flistAdded: any[] = [];
  private allfilters: any[] = [];

  constructor() {
  }

  ngOnInit() {
    let _this = this;
    this.allcolumns.push({label: "Select", value: 'select'});
    this.columns.forEach(function (column) {
      if ((column.getVisibilityArea() === 'B' || column.getVisibilityArea() === 'G' || column.getVisibilityArea() === 'F') && column.isVisible()) {
        _this.allcolumns.push({label: column.getHeader(), value: column.getField(), column: column});
      }
    });
  }

  addSort(value: any) {
    let column = value['column'];
    let label = value['label'];
    let value1 = value['value'];
    if (value1 !== 'select') {
      if (!this.flistAdded.includes(value1)) {
        this.flistAdded.push(value1);
        this.flist.push({label: label, value: value1, column: column, name: value['name']});
        let tempobj = {
          header: label,
          columnName: value1,
          sortValue: 'asc'
        };
        this.allfilters.push(tempobj);
        this.outList();
      }
    }

  }

  removeFilter(f: any, i: number) {
    this.flistAdded.splice(i, 1);
    this.flist.splice(i, 1);
    let _this = this;
    this.allfilters.forEach(function (child, i) {
      if (f['value'] === child['columnName']) {
        _this.allfilters.splice(i, 1);
      }
    });
    this.outList();

  }

  getOptions() {
    let obj = [];
    obj.push({label: 'Ascending', value: 'asc'});
    obj.push({label: 'Descending', value: 'desc'});
    return obj;
  }

  updateAllFilter(columnname, sort) {
    let _this = this;
    this.allfilters.forEach(function (filter, i) {
      if (filter['columnName'] === columnname) {
        let tempobj = {
          header: filter['header'],
          columnName: filter['columnName'],
          sortValue: sort['value']
        };
        _this.allfilters.splice(i, 1);
        _this.allfilters.splice(i, 0, tempobj);
      }
    });
    this.outList();
  }

  updateOptions(value: any, f: any) {
    if (value['value'] !== 'select') {
      this.updateAllFilter(f['value'], value);
    }
  }

  private outList() {
    this.onSort.emit(this.allfilters);
  }
}

