import {Component, OnInit} from '@angular/core';
import {Tcolumn} from "../../grid/tcolumn.model";
import {Grid} from "../../grid/grid.model";
import {Validators} from "@angular/forms";
import {MasterService} from "../services/MasterService";
import {KeyValue} from "../../grid/key-value.model";
import {CommonService} from "../../services/common.service";
import {defaultValue} from "../../grid/defaultValue.model";
import {Tab} from "../../tab-grid/Tab.model";
import {environment} from "../../../environments/environment";
import {ChildAction} from "../../grid/ChildAction.model";
import {repos} from "../../services/common/entities";

@Component({
  selector: 'app-calendarmaster',
  templateUrl: './calendarmaster.component.html',
  styleUrls: ['./calendarmaster.component.css']
})
export class CalendarmasterComponent implements OnInit {
  columns: Map<string, Tcolumn>;
  grid: Grid;
  tabs: Tab[] = [];

  constructor(private masterCommService: MasterService, public commonService: CommonService) {
  }

  ngOnInit() {
    this.getColumns();
    let copy = new ChildAction('calendarUuid');
    copy.addRepo('xceler_configservice_calendarholidays', repos.calendarHolidays);
    copy.addRepo('xceler_configservice_calendarworkingdays', repos.calendarWorkingDays);
    copy.setPrimaryKeyCode('uuid');
    this.tabs.push(this.getCalendarWorkingDays());
    this.tabs.push(this.getCalendarHolidays());
    this.grid = new Grid(this.columns, true, true, true, true, 'name', '/api/calendar/v1/getall?tenantId='+this.commonService.getFromStorage('tenantId'), '', 'Calendar', '', '', undefined, undefined, undefined,);
    this.grid.setSaveUrl(environment.base_url + '/api/calendar/v1/save');
    this.grid.setUpdateUrl(environment.base_url + '/api/calendar/v1/update');
    this.grid.hrefUrl = environment.base_url + '/api/calendar/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
    this.grid.importUrl = environment.base_url + '/api/calendar/v1/import';
    this.grid.setChildCopyDelete(copy);
    this.grid.iconName='calendar';
    this.grid.setTabs(this.tabs);
    this.grid.addChildTabs(this.getCalendarWorkingDays());
    this.grid.addChildTabs(this.getCalendarHolidays());
  }

  public getColumns() {
    this.columns = new Map();
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('code', new Tcolumn('code', 'Calendar Code', 'T', 1, false, undefined, true,new defaultValue('',undefined,['-','_'],undefined,false,true,false,undefined,undefined,true), 'B', [Validators.required, Validators.maxLength(8)], undefined, undefined, undefined, undefined, [], undefined, undefined, true));
    this.columns.set('name', new Tcolumn('name', 'Name', 'T', 1, false, undefined, true, new defaultValue('', '', environment.primaryKeyIncludedCharacters), 'B', [Validators.required]));
    this.columns.set('description', new Tcolumn('description', 'Description', 'TA', 3, true, undefined, true, '', 'B', [Validators.maxLength(200)]));
    this.columns.set('calendarType', new Tcolumn('calendarType', 'Calendar Type', 'B', 4, true, this.getCalendarType(), true, false, 'B', [Validators.required]));
    this.columns.set('calendarPeriod', new Tcolumn('calendarPeriod', 'Calendar Period', 'LB', 5, true, undefined, true, 'Daily'));
    this.columns.set('status', new Tcolumn('status', 'Status', 'B', 6, true, this.masterCommService.getActiveInactive(), true, true, 'B', [Validators.required]));
  }

  public getCalendarWorkingDays() {
    let columns: Map<string, Tcolumn> = new Map();
    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    columns.set('name', new Tcolumn('name', 'Calendar Name', 'LB', 1, false, undefined, false, '', 'B', [Validators.required]));
    columns.set('period', new Tcolumn('period', 'Period', 'LB', 2, true, undefined, true, '', 'B', [Validators.required, Validators.maxLength(15)]));
    columns.set('isWorking', new Tcolumn('isWorking', 'Is Working', 'B', 3, true, this.masterCommService.getYesNo(), true, false, 'B', [Validators.required]));
    let grid = new Grid(columns, true, true, true, true, 'period', '/api/calendarworkingdays/v1/getbycalendaruuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&calendarUuid=', '', 'Working Days', 'name', '', undefined, undefined, false);
    grid.setGridConfiguration(false, true, false, false, false);
    grid.setSaveUrl(environment.base_url + '/api/calendarworkingdays/v1/save');
    grid.setUpdateUrl(environment.base_url + '/api/calendarworkingdays/v1/update');
    grid.hrefUrl = environment.base_url + '/api/calendarworkingdays/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
    grid.importUrl = environment.base_url + '/api/calendarworkingdays/v1/import';
    let tab = new Tab(grid.getTitle(), grid, 'name');
    tab.idFieldValueName = 'calendarUuid';
    return tab;
  }

  public getCalendarHolidays() {
    let columns: Map<string, Tcolumn> = new Map();
    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    columns.set('name', new Tcolumn('name', 'Calendar Name', 'LB', 1, false, undefined, false, '', 'B', [Validators.required]));
    columns.set('calendarHolidaysDate', new Tcolumn('calendarHolidaysDate', 'Date', 'D', 2, true, undefined, true, '', 'B', [Validators.required]));
    columns.set('holiday', new Tcolumn('holiday', 'Holiday', 'T', 3, true, undefined, true, '', 'B', [Validators.required]));
    let grid = new Grid(columns, true, true, true, true, 'calendarHolidaysDate', '/api/calendarholidays/v1/getbycalendaruuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&calendarUuid=', '', 'Holiday', 'name', '', undefined, undefined, false);
    grid.setSaveUrl(environment.base_url + '/api/calendarholidays/v1/save');
    grid.setUpdateUrl(environment.base_url + '/api/calendarholidays/v1/update');
    grid.hrefUrl = environment.base_url + '/api/calendarholidays/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
    grid.importUrl = environment.base_url + '/api/calendarholidays/v1/import';
    let tab = new Tab(grid.getTitle(), grid, 'name');
    tab.idFieldValueName = 'calendarUuid';
    return tab;
  }


  onRowSave(value: any) {
    if (value['label'] === 'Calendar' && value['mode'] === 'save') {
      this.commonService.post(environment.base_url + '/api/calendarworkingdays/v1/calWorkDays',value['result']).subscribe((data: any) => {
      });
    }
  }

  private getCalendarType() {
    let list = [];
    list.push(new KeyValue('Pricing', true));
    list.push(new KeyValue('Non-Pricing', false));
    return list;
  }

}
