<div style="display: flex;padding: 3px;vertical-align: middle">
  <p
    style="margin: 0; padding: 0;font-size: 13px;min-width: 100px;margin-right: 19px;margin-top: 5px;font-weight: bold">
    + Add Colomn for Sort</p>
  <ctrm-dropdown (onChangeValue)="addSort($event)" [field]="allcolumns" [fieldname]="'sorting'"></ctrm-dropdown>
</div>
<div style="margin-top: 20px">
  <div *ngFor="let f of flist;index as i" class="filterBox" style="margin-top: 4px">
    <div style="display: inline-flex">
      <p style="margin: 0;font-size: 14px;min-width: 150px;max-width: 150px">{{f['label']}}</p>
      <div style="display: flex;">
        <ctrm-dropdown (onChangeValue)="updateOptions($event,f)" [addSelectOption]="false"
                       [field]="getOptions()"></ctrm-dropdown>

      </div>
      <i (click)="removeFilter(f,i)" class="fas fa-times removefilter"
         style="justify-self: flex-end;margin-top: 8px;margin-left: 5px"></i>
    </div>
    <div class="brk"></div>
  </div>
</div>
