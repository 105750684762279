<div style="display: inline-flex;width: 100%">
    <masters-sidenav></masters-sidenav>
    <div id="pinwidth" style="width: 100%;margin-left: 80px">
        <div class="sectionCardTop">
            <div class="breadcrumbPane">
                <ctrm-breadcrumb [collapsabel]="false"
                                 [items]="breadCrumbService.getBreadCrumb()"
                                 [showIcon]="false"></ctrm-breadcrumb>
            </div>
            <div class="actionPane">
                <div class="screenDetails">
                    <img [src]="'../../../assets/images/svg/Approval.svg'"/>
                    <p>Approval Workflow Configuration</p>
                    <div>
                        <button (click)="onclick()" class="btn-style">New Aprroval</button>
                    </div>
                </div>
            </div>
        </div>
        <div style="margin-left: 5px">
            <ctrm-grid [showSectionTop]="false"
                       [showBreadcrumb]="false"
                       [showToolbar]="false"
                       [grid]="grid"
                       [showActions]="false"
                       (onClickUniqueKey)="onUniqueClick($event)"
                       (onClickAdd)="onclick()"
                       [toolbarConfig]="toolBarConfig"
                       [customWidthColumnNames]="['delete']"
                       [customWidthForColumn]="['20px']"
                       [fieldsWithCustomView]="['delete']"
                       [customViewTemplated]="[editTemplate]">
            </ctrm-grid>
        </div>
    </div>
</div>
<ng-template #editTemplate let-rowData="rowData" let-index="index">
    <i  (click)="onRowEditInit(rowData)" class="fas fa-pen editicon" [ngClass]="rowData['isSystem']?'disabled':''"
       pTooltip="Edit"
       tooltipPosition="bottom">
    </i>
</ng-template>


<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>
