import {Component, OnInit} from '@angular/core';
import {Grid} from '../../grid/grid.model';
import {Tcolumn} from '../../grid/tcolumn.model';
import {Validators} from '@angular/forms';
import {defaultValue} from '../../grid/defaultValue.model';
import {MasterService} from '../services/MasterService';
import {Action} from '../../grid/actions.model';
import {environment} from '../../../environments/environment';
import {ChildAction} from '../../grid/ChildAction.model';
import {CommonService} from '../../services/common.service';
import {Tab} from '../../tab-grid/Tab.model';
import {entities, repos} from '../../services/common/entities';
import {HttpErrorResponse} from "@angular/common/http";
import {MessageService} from "primeng";
import {Filter} from '../../grid/preference/filter/filter.model';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-paymentterm',
    templateUrl: './paymentterm.component.html',
    styleUrls: []
})
export class PaymenttermComponent implements OnInit {

    columns: Map<string, Tcolumn>;
    grid: Grid;
    tabs: Tab[] = [];
    currentHref: string = '';
    loadListData: any[] = [];
    selectedRow:any;
    popupDisplay: boolean = false;
    refreshGrid: boolean = false;
    filterValue: any;

  constructor(public commonService: CommonService, private masterCommService: MasterService,private messageService: MessageService, private route: ActivatedRoute) {
    }

    ngOnInit() {

        this.commonService.getLoadData('paymentterm', ['data', 'data', 'data', 'data', 'data', 'data'], [[{
            key: 'name',
            value: 'lc_type_ind'
        }], [{key: 'name', value: 'application_type_ind'}], [{key: 'name', value: 'day_rule_ind'}], [{
            key: 'name',
            value: 'payment_against_ind'
        }], [{key: 'name', value: 'event_type_ind'}], [{key: 'name', value: 'payment_period_ind'}]]).subscribe((next: any) => {
            this.loadListData = next;
            this.getColumns();
            let copy = new ChildAction('name');
            copy.addRepo(entities.paymentEvent, repos.paymentEvent);
            this.tabs.push(this.getPaymentEvent());
            this.grid = new Grid(this.columns, true, true, true, true, 'name', '/api/paymentterm/v1/getall?tenantId=' + this.commonService.getFromStorage('tenantId'), '', 'Payment Term', '');
            this.grid.setSaveUrl(environment.base_url + '/api/paymentterm/v1/savepaymentterm');
            this.grid.setUpdateUrl(environment.base_url + '/api/paymentterm/v1/update');
            this.grid.importUrl = environment.base_url + '/api/paymentterm/v1/import';
            this.grid.hrefUrl = environment.base_url + '/api/paymentterm/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
            this.grid.setChildCopyDelete(copy);
            this.grid.iconName = 'paymentTerm';
            this.grid.auditEntityName = "PaymentTerm";
            this.grid.addSection('Payment Detail', ['code','name', 'description', 'lCBased', 'lCType']);
            this.grid.addSection('Terms', ['application', 'advanceOffsetType', 'settle', 'event', 'paymentAgainst']);
            this.grid.addSection('Policy', ['weekendRule', 'holidayRule', 'fridayRule', 'mondayRule']);
            this.grid.addSection('Approval', ['approvalStatus', 'approvedBy', 'approvedDate']);
            this.grid.addSection('Other', ['status']);
            this.grid.setTabs(this.tabs);
            this.grid.addChildTabs(this.getPaymentEvent());
            this.grid.gridAdvacedFilterURL = "/api/paymentterm/v1/getpaymenttermbycriteria"

            if (this.filterValue !==undefined && this.filterValue !== null && this.filterValue !== ''){
                this.grid.addInFilter(new Filter('Payment Term Name','name','equalsto',this.filterValue));
            }
            this.applyNotifFilter();
        });
    }

    public getColumns() {     //Setting columns in Grid Component
        let _this = this;
        let functionApproveUser = function(value: any[] = []) {
            if (value[0] !== false) {
                return _this.masterCommService.getUser();
            }

        };
        let functionApproveDate = function(value: any[] = []) {
            if (value[0] !== false) {
                return _this.masterCommService.getCurrentDate();
            } else {
                return '';
            }
        };
        let funcLcType = function lctype(value: any[] = []) {
            let obj: any = {meta: {disabled: true}};
            if (value[0] === true) {
                obj = {meta: {disabled: false}};
            }
            return obj;
        };
        let actionApproval = new Action();
        actionApproval.addFieldToUpdate('approvedBy', undefined, functionApproveUser, undefined, undefined, false, 'defaultValue');
        actionApproval.addFieldToUpdate('approvedDate', undefined, functionApproveDate, undefined, undefined, false, 'defaultValue');
        let action = new Action();
        action.addFieldToUpdate('lCType', undefined, funcLcType, undefined, undefined, true, 'extra');
        this.columns = new Map();
        this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
        this.columns.set('code', new Tcolumn('code', 'Payment Term Code', 'T', 1, false, undefined, true,new defaultValue('',undefined,['-','_'],undefined,false,true,false,undefined,undefined,true), 'B', [Validators.required, Validators.maxLength(8)], undefined, undefined, undefined, undefined, [], undefined, undefined, true));
        this.columns.set('name', new Tcolumn('name', 'Payment Term Name', 'T', 1, false, undefined, true, new defaultValue('', ''), 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined));
        this.columns.set('description', new Tcolumn('description', 'Payment Term Description', 'TA', 3, true, undefined, true, '', 'B', [Validators.maxLength(255)]));
        this.columns.set('lCBased', new Tcolumn('lCBased', 'L/C Based', 'B', 3, true, this.masterCommService.getYesNo(), true, false, 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, action));
        this.columns.set('lCType', new Tcolumn('lCType', 'L/C Type', 'L', 3, true, this.masterCommService.getListFromArray(this.loadListData['lc_type_ind']), true, '', 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('application', new Tcolumn('application', 'Application', 'ML', 3, true, this.masterCommService.getListFromArray(this.loadListData['application_type_ind'],false,false), true, '', 'B', [Validators.required]));
        this.columns.set('advanceOffsetType', new Tcolumn('advanceOffsetType', 'Advance Offset Type', 'OB', 3, true, this.masterCommService.getBooleanFromLabel('Prorate', 'Cumulative'), true, false, 'B', [Validators.min(0)]));
        this.columns.set('settle', new Tcolumn('settle', '% Settle', 'N', 3, true, undefined, true, new defaultValue(0, undefined, []), 'B', [Validators.min(0), Validators.max(100)], undefined, 1, undefined, undefined, undefined, undefined));
        this.columns.set('weekendRule', new Tcolumn('weekendRule', 'Weekend Rule', 'L', 3, true, this.masterCommService.getListFromArray(this.loadListData['day_rule_ind']), true, '', 'B'));
        this.columns.set('holidayRule', new Tcolumn('holidayRule', 'Holiday Rule', 'L', 3, true, this.masterCommService.getListFromArray(this.loadListData['day_rule_ind']), true, '', 'B'));
        this.columns.set('fridayRule', new Tcolumn('fridayRule', 'Friday Rule', 'L', 3, true, this.masterCommService.getListFromArray(this.loadListData['day_rule_ind']), true, '', 'B'));
        this.columns.set('mondayRule', new Tcolumn('mondayRule', 'Monday Rule', 'L', 3, true, this.masterCommService.getListFromArray(this.loadListData['day_rule_ind']), true, '', 'B'));
        this.columns.set('event', new Tcolumn('event', 'Event', 'L', 3, true, this.masterCommService.getListFromArray(this.loadListData['day_rule_ind']), true, '', 'B'));
        this.columns.set('paymentAgainst', new Tcolumn('paymentAgainst', 'Payment Against', 'L', 3, true, this.masterCommService.getListFromArray(this.loadListData['payment_against_ind']), true, '', 'B'));
        this.columns.set('status', new Tcolumn('status', 'Status', 'B', 4, true, this.masterCommService.getActiveInactive(), true, true, 'B', [Validators.required]));
        this.columns.set('approvalStatus', new Tcolumn('approvalStatus', 'Approved', 'B', 4, true, this.masterCommService.getYesNo(), true, false, 'B', [], undefined, undefined, undefined, undefined, [], actionApproval, {meta: {disabled: true}}));
        this.columns.set('approvedBy', new Tcolumn('approvedBy', 'Approved By', 'LB', 4, true, undefined, true, undefined, 'B'));
        this.columns.set('approvedDate', new Tcolumn('approvedDate', 'Approved Date', 'D', 4, true, undefined, true, undefined, 'B', [Validators.required], undefined, undefined, undefined, undefined, [], undefined, {meta: {disabled: true}}));
    }


    private getPaymentEvent() {
        let columns: Map<string, Tcolumn> = new Map();
        columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
        columns.set('name', new Tcolumn('name', 'Payment Term Name', 'LB', 1, false, undefined, false, ''));
        columns.set('paymentDueOffsetDays', new Tcolumn('paymentDueOffsetDays', 'Payment Offset Due Days', 'N', 2, true, undefined, true, new defaultValue('', '', []), 'B', [Validators.min(0),Validators.required]));
        columns.set('paymentDueEvent', new Tcolumn('paymentDueEvent', 'Payment Due Event', 'L', 3, true, this.masterCommService.getListFromArray(this.loadListData['event_type_ind']), true, '', 'B', [Validators.required]));
        columns.set('period', new Tcolumn('period', 'Period', 'L', 4, true, this.masterCommService.getListFromArray(this.loadListData['payment_period_ind']), true, '', 'B', [Validators.required]));
        columns.set('offsetBefore', new Tcolumn('offsetBefore', 'Offset Before', 'SL', 5, true, undefined, true, 10, 'B', [Validators.min(0)], undefined, 1, 0, 90));
        columns.set('offsetAfter', new Tcolumn('offsetAfter', 'Offset After', 'SL', 6, true, undefined, true, 10, 'B', [Validators.min(0)], undefined, 1, 0, 90));
        columns.set('inclusiveOfEventDay', new Tcolumn('inclusiveOfEventDay', 'Inclusive of Event Day', 'B', 7, true, this.masterCommService.getYesNo(), true, false, 'B', [Validators.required]));
        let grid = new Grid(columns, true, true, true, true, 'paymentDueOffsetDays', '/api/paymentevent/v1/getbypaymenttermuuid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&paymentTermUuid=', '', 'Payment Event', 'name');
        grid.setSaveUrl(environment.base_url + '/api/paymentevent/v1/save');
        grid.setUpdateUrl(environment.base_url + '/api/paymentevent/v1/update');
        grid.importUrl = environment.base_url + '/api/paymentTerm/v1/importPaymentEvent';
        grid.hrefUrl = environment.base_url + '/api/paymentevent/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
        let tab = new Tab(grid.getTitle(), grid, 'name');
        tab.idFieldValueName = 'paymentTermUuid';
        return tab;
    }

  onRowSelectionChange(value: any[]) {
    if(value['data'] !== undefined && value['data'] !== null && value['data'].length ===  1) {
      this.selectedRow= value['data'][0];
      if(this.selectedRow['approvalStatus'] === undefined || this.selectedRow['approvalStatus'] === null || !this.selectedRow['approvalStatus']) {
        this.popupDisplay = true;
      }
    } else {
      this.popupDisplay = false;
    }
  }

  onClose() {
    this.popupDisplay = false;
  }

  approve() {
    this.refreshGrid = false;
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url+'/api/paymentterm/v1/approve?tenantId='+this.commonService.getTenantId()+'&name='+this.selectedRow['name']+'&approvedBy='+this.commonService.getFromStorage('userName')).subscribe((next) => {
         _this.showToast("Payment Term "+_this.selectedRow['name']+" approved successfully.");
        _this.onClose()
        _this.refreshGrid = true;
      },(error:HttpErrorResponse) =>{
        _this.showToast(_this.commonService.getHttpErrorMessage(error,'Payment Term',false),'error');
    });
  }

  showToast(msg, severity: string = 'info') {
    this.messageService.add({
      severity: severity,
      summary: msg
    });
  }

  applyNotifFilter(){
      this.route
          .queryParams
          .subscribe(params => {
              this.filterValue = params['value'];
              if (this.grid !== undefined && this.grid !== null && this.filterValue !==undefined && this.filterValue !== null && this.filterValue !== '') {
                  this.grid.removeFilter('name')
                  this.grid.addInFilter(new Filter('Payment Term Name','name','equals',this.filterValue));
              }
          });
  }
}
