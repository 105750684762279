<p-dialog (onHide)="onFormCancel()"
          [(visible)]="dialogDisplay"
          [closeOnEscape]="false"
          [draggable]="false"
          [maximizable]="false" [modal]="true" [showHeader]="false"
          [style]="{zIndex:1008,width: '90%',height: 'auto',minHeight:'80%',maxHeight:'80%', padding:'0px', background:'#ffffff'}">
    <!--Section Top-->
    <div class="sectionCardTop">
        <div class="actionPane">
            <img [src]="'../../../assets/images/svg/openFutureDashboard.svg'"/>
            <p class="dialogLabel">Future's Position Dashboard</p>
            <div class="rightInfo">
                Total Future Position : {{this.futureValues.length}}
            </div>
            <i (click)="onFormCancel()" class="material-icons closable"
               style="float: right;align-self: center; margin-top: -40px !important;">clear</i>
        </div>
    </div>

    <!--    Filter dropdown-->
    <div class="tableWrapper">
        <div [formGroup]="filterForms">
        <div class="viewByWrapper">
            <p class="tradeFilter"> Filter by:</p>
            <p class="viewby viewbyLabels">Future Index : </p>
            <div style="margin-left: 10px">
                <p-dropdown [disabled]="false"
                            [id]="'futureIndex'"
                            appendTo="body"
                            tooltipEvent="focus" tooltipPosition="bottom"
                            tooltipZIndex="1000"
                            formControlName="futureIndex"
                            (onChange)="onFilterValueChange($event)"
                            [options]="futureIndex">
                </p-dropdown>
            </div>
            <p class="viewby viewbyLabels">Broker : </p>
            <div style="margin-left: 10px">
                <p-dropdown [disabled]="false"
                            [id]="'allBrokerList'"
                            (onChange)="onFilterValueChange($event)"
                            [options]="allBrokerList"
                            appendTo="body"
                            tooltipEvent="focus" tooltipPosition="bottom"
                            tooltipZIndex="1000"
                            formControlName="allBrokerList">
                </p-dropdown>
            </div>
            <p class="viewby viewbyLabels">Profit center : </p>
            <div style="margin-left: 10px">
                <p-dropdown [disabled]="false"
                            [id]="'profitCenter'"
                            appendTo="body"
                            tooltipEvent="focus" tooltipPosition="bottom"
                            tooltipZIndex="1000"
                            formControlName="profitCenter"
                            (onChange)="onFilterValueChange($event)"
                            [options]="profitCenter">
                </p-dropdown>
            </div>
            <div class="calenderCont">
                <div class="calender calenderfix" (click)="toggleDateRange()">
                    <i class="fa fa-calendar" style="margin-left: 5px;align-self: center;font-size: 12px;margin-left: 6px;user-select: none;"></i>
                    <p>Foward Contract Month</p>
                </div>
                <ctrm-date-range *ngIf="showDateRange"
                                 [selectedPresetObject]="selectedPreset"
                                 (onCancel)="onCancel($event)"
                                 [preDefinedRanges]="ranges"
                                 (onPresetSelected)="onPresetChange($event)">
                </ctrm-date-range>
            </div>
        </div>
        </div>
    </div>

    <div class="content">
        <div class="leftbox">
            <div class="leftcard">
                <!--    Left side Card panel-->
                <div class="contentSection">
                    <div class="switchDiv">
                        <p class="text">Product Symbol</p>
                        <ui-switch [checkedTextColor]="'#fff'" [size]="'small'"
                                   (change)="toggleChange($event)" [checked]="toggleButton"
                                   [defaultBgColor]="'#d8d8d8'">
                        </ui-switch>
                        <p class="text">Expiry Month</p>
                    </div>
                    <div class="list style-4" id="list" inViewport (inViewportAction)="calcaulateListHeight()">
                        <future-position-card *ngFor="let futurePosition of futurePosition"
                                              [selected]="isallselected"
                                              [hidden]="hiddenCard.includes(futurePosition['tradeId'])"
                                              [disabled]="disableCardList.includes(futurePosition['tradeId'])"
                                              (onSelected)="onSelectCard($event)"
                                              (onUnselected)="onUnselectCard($event)"
                                              [futurePosition]="futurePosition"
                                              [expiryMonthFilter]="expiryMonthFilter">
                        </future-position-card>
                    </div>
                </div>
            </div>
        </div>
        <!--    Grid Panel-->
        <div class="rightbox">
            <div class="gridbox" #divbox inViewport (inViewportAction)="calculateGridHeight(divbox)">
                <div>
                    <ctrm-grid [showSectionTop]="false"
                               [isOnSidebar]="true"
                               [sortable]="false"
                               [showActions]="false"
                               [defaultEdit]="false"
                               [saveOnSave]="false"
                               [autoCalculateHeight]="false"
                               [rightAlignNumberFields]="true"
                               [getFromUrl]="false"
                               [autoCalculateGridWidth]="false"
                               [autoLayout]="true"
                               [showSortIcon]="false"
                               [rowValues]="futureValues"
                               [toolbarConfig]="toolBarConfig"
                               [gridHeight]="gridHeight+'px'"
                               [grid]="futureGrid"
                               [customViewTemplated]=[overlayTemplate]
                               [fieldsWithCustomView]="['status']"
                               [checkAccessPolicy]="false"></ctrm-grid>
                </div>
            </div>
        </div>
    </div>
</p-dialog>

<ng-template #overlayTemplate let-rowData='rowData'>
    <div style="display: inline-flex;align-items: center;font-size: 15px; ">
        <p class="primaryKey" #ptag (click)="onClickStatus($event,displayPanel,rowData,ptag)">{{rowData['status']}}</p>
    </div>
</ng-template>

<p-overlayPanel  #displayPanel  [style]="{width: '20%',top:top}">
    <p-table   [value]="documents" [scrollable]="true" scrollHeight="200px">
        <ng-template style="width:15%;" pTemplate="header">
            <tr>
                <th style="min-width:100px">Trade Linked to Future</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-documents>
            <tr>
                <td style="min-width:100px; text-decoration: underline; text-decoration-color: blue; color:blue;cursor: pointer;"
                (click)="onClickOfTradeId($event)">{{documents}}</td>
            </tr>
        </ng-template>
    </p-table>
</p-overlayPanel>

<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body">
</p-toast>

