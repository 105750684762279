import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() {
  }

  sortByProperty<T>(array: T[], propName: string, order: 'ASC' | 'DESC'): void {
    array.sort((a, b) => {
      if (a[propName] < b[propName]) {
        return -1;
      }

      if (a[propName] > b[propName]) {
        return 1;
      }
      return 0;
    });

    if (order === 'DESC') {
      array.reverse();
    }
  }
}
