import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CommonService} from "../../../../services/common.service";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'planned-card',
  templateUrl: './planned-card.component.html',
  styleUrls: ['./planned-card.component.css']
})
export class PlannedCardComponent implements OnInit,OnChanges {

  @Input() selected: boolean = false;
  @Input() disabled:boolean = false;
  @Input() plannedObligation: any = null;
  @Input() search:any;
  @Input() searchFields:any[] = [];
  @Output() onNotFounInSearch = new EventEmitter();
  @Output() onFounInSearch = new EventEmitter();
  @Output() onSelected = new EventEmitter();
  @Output() onUnselected = new EventEmitter();
  @Input() width: any = '100%';
  @Output()onClickId = new EventEmitter();

  constructor(private commonService:CommonService) {
  }

  ngOnInit(): void {
  }
  emitsomevalue(){
    this.onClickId.emit(this.plannedObligation);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['search'] && (changes['search'].currentValue !== null && changes['search'].currentValue !== undefined && changes['search'].currentValue.length > 0)) {
      this.searchNow(changes['search'].currentValue);
    }
  }

  onSelectionEmit() {
    this.onSelected.emit(this.plannedObligation);
  }

  onUnselectionEmit() {
    this.onUnselected.emit(this.plannedObligation);
  }

  isBuy() {
    if (this.plannedObligation['tradeTransactionType'].toLowerCase() === 'buy') {
      return true;
    }
    return false;
  }

  getFormattedDate(date: any) {
    return this.commonService.getFormattedDate(date,environment.dateFormat);
  }

  onSelectionChange(value: any) {
    if(value['checked']) {
      this.onSelectionEmit();
    } else{
      this.onUnselectionEmit();
    }
  }

  private searchNow(searchValue: any) {
    let searchFound:boolean = false;
    let _this = this;
    this.searchFields.forEach(function (field:string) {
      if(_this.plannedObligation[field].toLowerCase().includes(searchValue.toLowerCase())) {
        searchFound = true;
      }
    });
    if(!searchFound) {
      this.onNotFounInSearch.emit(this.plannedObligation);
    } else {
      this.onFounInSearch.emit(this.plannedObligation);
    }
  }
}
