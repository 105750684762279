<ctrm-grid *ngIf="loadDataList !== undefined && loadDataList.length != 0"
           [breadcrumbMapKey]="commonService.MASTER_MAP_KEY"
           [currentRoute]="'/uom'" (onClickUniqueKey)="onClickUnique($event)"
           [grid]="grid" [refreshGrid]="refreshGrid"
           (onClickEdit)="onClickEditPage($event)"
           [defaultEdit]="false"
           [showActions]="false"
           (onClickAdd)="onClickAdd()"
           [saveOnSave]="false"></ctrm-grid>

<!--Add UOM Data-->
<p-sidebar *ngIf="onClickAddDisplay" [(visible)]="onClickAddDisplay" [showCloseIcon]="false"
           [style]="{minWidth:'50%',maxWidth:'65%',height:'100%', marginTop:'61px'}"
           position="right" [baseZIndex]="5" [ngStyle]="{maxHeight: height}"
           autoLayout="true" class="style-4 table-responsive">
  <form [formGroup]="uomForm">
    <div class="headerClass">
      New Unit Of Measurement
      <i class="material-icons closable" (click)="close()">clear</i>
    </div>
    <div class="formBox"></div>

    <div class="sectionForm style-4" [id]="'sectionForm'">
      <div class="validContain">
        <div class="sectionBoxMain">
          <div class="formSectionTitle">
            <div class="headerTitle">Unit Of Measurement Information</div>
          </div>
          <div class="firstLine">
            <div class="fieldBox" style="margin-right: 20px;">
              <label class="label ng-star-inserted">UOM Name</label>
              <input id="name" type="text"
                     (input)="commonService.updateInputValidation($event,includedCharacters,true)"
                     [(ngModel)]="name"
                     class="form-control form-control-sm input"
                     pInputText placeholder="UOM Name"
                     formControlName="name">
            </div>
            <div class="fieldBox">
              <label class="label ng-star-inserted">UOM Description</label>
              <input id="description" type="text"
                     (input)="commonService.updateInputValidation($event,includedCharacters,true)"
                     [(ngModel)]="description"
                     class="form-control form-control-sm input"
                     pInputText placeholder="UOM Description"
                     formControlName="description">
            </div>
          </div>

          <div class="secondLine">
            <div class="fieldBox" style="margin-right: 20px;">
              <label class="label ng-star-inserted">UOM Type</label>
              <p-dropdown [(ngModel)]="uomType"
                          [disabled]="false"
                          [id]="'uomType'"
                          [options]="uomTypeList"
                          appendTo="body"
                          tooltipEvent="focus" tooltipPosition="bottom"
                          tooltipZIndex="1000"
                          formControlName="uomType">
              </p-dropdown>
            </div>
            <div class="fieldBox">
              <label class="label ng-star-inserted">Status</label>
              <div>
                <ui-switch [(ngModel)]="status"
                           [checkedLabel]="'Active'"
                           [checkedTextColor]="'#fff'"
                           [defaultBgColor]="'#d8d8d8'"
                           [uncheckedLabel]="'Inactive'"
                           [checked]=true></ui-switch>
              </div>
            </div>
          </div>
        </div>

<!--Validator Section-->
        <div class="valContainer">
          <div class="validation">
            <div class="validationHeading">Unit Of Measurement</div>
            <div class="validationSubHeading">
              <img *ngIf="nameValid()" alt="" src="../../../assets/images/svg/validTick.svg">
              <img *ngIf="!nameValid()" alt="" src="../../../assets/images/svg/invalidCross.svg">
              <p class="valPoints">UOM Name</p>
            </div>
          </div>
        </div>
      </div>

      <div class="sectionBoxMain">
        <div class="formSectionTitle">
          <div class="subHeading">Add Conversion Data</div>
        </div>
        <div class="uomGrid">
          <app-ctrm-price-table
                                (onAddNewRowComplete)="onNewRowAddComplete()"
                                (onRefreshFormGroupComplete)="onRefreshFromGroupComplete()"
                                [columns]="convertionCols" [gridDisplay]="true"
                                [refreshFormGroup]="refreshFormGroup"
                                [newRows]="newRows" [refreshNewRows]="refreshAddNewRow"
                                [value]="conversionData"
                                [showNewEntryIcon]="true">
          </app-ctrm-price-table>
        </div>

      </div>
    </div>
  </form>
  <p-footer>
    <div class="footerClass saveSection">
      <div style="display: inline-flex">
        <button (click)="close()" class="cancelBtn" label="Cancel" pButton type="button"></button>
        <button (click)="save()" [disabled]="!uomForm.valid" class="save">Save</button>
      </div>
    </div>
  </p-footer>
</p-sidebar>

<!--Edit UOM Data-->
<p-sidebar *ngIf="uomDisplay" [(visible)]="uomDisplay" [baseZIndex]="false"
           [closeOnEscape]="false"
           [ngStyle]="{maxHeight: height}"
           [showCloseIcon]="false" [style]="{minWidth:'70%',maxWidth:'70%',zIndex:'1006'}" autoLayout="true"
           class="style-4 table-responsive" position="right">
  <div class="header">
    <div style="display: inline-flex">
      <p class="name">{{selectedRowData['name']}}</p>
<!--      <ctrm-button (onClick)="onClickEdit()" *ngIf="pageDisable && canEdit" [label]="'Edit'" icon="fas fa-pen"></ctrm-button>-->
    </div>
    <i (click)="close()" class="material-icons closable">clear</i>
  </div>

  <div class="section">
    <div class="sectionHeader">
      <p class="sectionTitle">UOM Infromation</p>
    </div>
    <div class="sectionBody">
      <div class="infoBox">
        <p class="infoHeader">UOM Description</p>
        <p *ngIf="pageDisable" class="infoInfo">{{selectedRowData['description']}}</p>
        <div *ngIf="!pageDisable" class="inputbox">
          <input [(ngModel)]="description"
                 [type]="'text'" class="form-control form-control-sm"
                 pInputText placeholder="Enter UOM Description">
        </div>
      </div>
      <div class="infoBox">
        <p class="infoHeader">UOM Type</p>
        <p *ngIf="pageDisable" class="infoInfo">{{selectedRowData['uomType']}}</p>
        <div *ngIf="!pageDisable">
          <p-dropdown [(ngModel)]="uomType"
                      [options]="uomTypeList">
          </p-dropdown>
        </div>
      </div>
      <div class="infoBox">
        <p class="infoHeader">Status</p>
        <p *ngIf="pageDisable" [ngClass]="selectedRowData['status']?'activeBox':'inactiveBox'"
           class="infoInfo">{{selectedRowData['status'] ? 'Active' : 'Inactive'}}</p>
        <div *ngIf="!pageDisable">
          <ui-switch [(ngModel)]="status"
                     [checkedLabel]="'Active'"
                     [checkedTextColor]="'#fff'"
                     [defaultBgColor]="'#d8d8d8'"
                     [uncheckedLabel]="'Inactive'"></ui-switch>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf = "displayGrid" class="section marginTopClass">
    <div class="sectionHeader">
      <p class="sectionTitle">Conversion Data</p>
    </div>
    <div class="sectionConversionBody">
      <div style="display:inline-flex">
        <div *ngIf="pageDisable" class="disableOverlay"></div>
        <app-ctrm-price-table
                (onAddNewRowComplete)="onNewRowAddComplete()"
                [newRows]="newRows" [refreshNewRows]="refreshAddNewRow"
                (onRefreshFormGroupComplete)="onRefreshFromGroupComplete()"
                [columns]="convertionColumns" [gridDisplay]="true"
                [refreshFormGroup]="refreshFormGroup"
                [value]="conversionData"
                [showNewEntryIcon]="false"
                [showCrossIcon]="false"
                [showPlaceHolder]="true">
        </app-ctrm-price-table>
      </div>
    </div>
  </div>

  <p-footer>
    <div class="footerClass saveSection">
      <div style="display: inline-flex">
        <button (click)="close()" class="cancelBtn" label="Cancel" pButton type="button"></button>
<!--        <div *ngIf="canEdit" style="display: inline-flex; margin-top: 1%;">-->
<!--          <button (click)="UpdateUom()"-->
<!--                  [disabled]="pageDisable"-->
<!--                  [hidden]="isLoading"-->
<!--                  class="savebutton" icon="pi pi-check"-->
<!--                  iconPos="left" label="Update" pButton></button>-->
<!--          <div [hidden]="!isLoading" class="loadingbutton">-->
<!--            <div class="loader"></div>-->
<!--            <p>{{'Update'}}</p>-->
<!--          </div>-->
<!--        </div>-->
<!--        <ng-container *ngTemplateOutlet="infoUpdate"></ng-container>-->
      </div>
    </div>
  </p-footer>
</p-sidebar>

<ng-template #infoUpdate>
  <div class="lastUpdateInfoOnForm">
    <div style="display: flex;flex-direction: row">
      <p>Created By : {{createdBy}}</p>
      <p>Created On : {{createdDate}}</p>
    </div>
    <div style="display: flex;flex-direction: row">
      <p>Last Modified By : {{updatedBy}}</p>
      <p>Last Modified On : {{updatedDate}}</p>
    </div>
  </div>
</ng-template>

<!--No Data Available-->
<ng-template #noData>
  <div  [id]="'noDataBoxId'" class="noDataBox"
       inViewport>
    <img [src]="commonService.getIconPath('emptyStateIcon')" class="iconClass"/>
    <p class="noDataHeader">No data available</p>
  </div>
</ng-template>
<!--Toaster Message-->
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body">
</p-toast>
