import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonService} from "../../services/common.service";
import {Audit} from "./audit";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {MessageService} from "primeng";

@Component({
  selector: 'app-ctrm-audittrail',
  templateUrl: './ctrm-audittrail.component.html',
  styleUrls: ['./ctrm-audittrail.component.css']
})
export class CtrmAudittrailComponent implements OnInit {
  modalState: any;
  header: any;
  @Input() display :boolean = false;
  @Output() onclose = new EventEmitter()
  @Input() entityId: string = '';
  @Input() entityName: string = '';
  @Input() heading: string = '';
  @Input() hierarchyRequired: boolean= true;

  cols: any [] = [];
  dailogdata :any[] =[];
  changes: any [] = [];
  viewMore: boolean = true;
  autoCalculateHeight: boolean = true;
  currentVisible: any;
  dialogHeight: number = 30;



  constructor(public commonService: CommonService,private http:HttpClient,private messageService: MessageService) { }

  ngOnInit(): void {
    this.http.get<Audit[]>(`${environment.base_url_new}/ctrm-audit-service/audit/${this.entityName}/${this.entityId}?isHierarchyRequired=`+this.hierarchyRequired).subscribe(resp=>{
      if(resp.length === 0){
        this.messageService.add({severity: 'error', summary: 'Audit trail not available for this '+this.entityName});
        this.onclose.emit();
        return;
      }
      resp.map((aa,z)=>{
      if(aa.differenceList === null || aa.differenceList.length === 0){
        aa.first = true;
        resp[z].differenceList = Object.keys(resp[z].summary).map(key=>{
           return {
             field: key,
             label: key,
             newValue: resp[z].summary[key],
             oldValue: resp[z].summary[key]
           }
         });
      }

          aa.differenceList = aa.differenceList.filter(item => (item.oldValue && item.newValue));

        if(aa.differenceList.length > 4) {
          aa.summary = aa.differenceList.slice(0,4);
        } else {
          aa.summary = aa.differenceList;
        }
      });
      this.dailogdata=resp;
    }, (error: HttpErrorResponse) => {
      this.messageService.add({severity: 'error', summary: 'Audit trail not available for this trade'});
      this.onclose.emit();
    });
  }

  closeModal() {
    this.onclose.emit();
  }

    getrowvalue(col: any, rowData: any) {
      return rowData['entityName'];
    }
  getExpandLabel(audit:Audit) {
    return !audit.expanded ? 'More' : 'Less';
  }
  toggleViewMore(audit:Audit) {
    audit.expanded = !audit.expanded;
    if (audit.expanded) {
      if (this.currentVisible && this.currentVisible.id !== audit.id) {
        this.currentVisible.expanded = false;
      }
      this.currentVisible = audit;
    }
  }
}
