import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(value: any[],args?: any,fieldname?:any): unknown {
    if(!value)return null;
    if(!args)return value;
    args = args.toLowerCase();
    return value.filter(function(item){
      return  (fieldname !== null && fieldname !== undefined && fieldname.length > 0)?item[fieldname].toLowerCase().includes(args):JSON.stringify(item).toLowerCase().includes(args);
    });
  }

}
