<div class="prefrenceWrapper">
    <div>
        <p-dropdown
                [options]="preferenceList"
                [(ngModel)]="selectedPreference"
                (ngModelChange)="onPrefSelection($event)"
                appendTo="body">
        </p-dropdown>
    </div>
    <div style="margin-left: 10px">
        <p-multiSelect [options]="columnsList"
                       optionLabel="label" (ngModelChange)="onPrefColChange($event, true)" [defaultLabel]="'Columns'" [(ngModel)]="selectedPrefCols"></p-multiSelect>
    </div>

    <div style="margin-left: 10px">
        <div (click)="toggleFilterPanel($event)" class="filterBtnWrapper">
            <i class="fas fa-filter funnelIcon"></i>
            <p class="filterBtnText">Advanced Filter</p>
            <span *ngIf="appliedFilters.length > 0" class="dot">{{appliedFilters.length}}</span>
        </div>
    </div>

    <div style="margin-left: 10px">
        <button class="prefSaveBtn" (click)="openSavePrefDialog()"
                type="button">Save View</button>
    </div>

    <div style="margin-left: 10px; display: flex; margin-top: 5px">
        <p-checkbox (onChange)="onMakeDefaultChange()"
                    style="display: inline-flex;width: 170px"
                    [(ngModel)]="prefDefault" [binary]="true" [label]="'Mark as Default view'"></p-checkbox>
    </div>
    <div (click)="$event.stopPropagation()">
        <div class="filterSecDiv" *ngIf="filterPanelDisplay">
            <div class="advacedFilterWrapper">
                <div style="display: inline-flex">
                    <div class="filterSecWrapper">
                        <div style="height: 100%">
                            <div style="height: 100%">
                                <p-listbox [options]="filtercolumnsList" (onChange)="addfilter($event)"  optionLabel="label" [(ngModel)]="selectedFilterCol"></p-listbox>
                            </div>
                        </div>
                    </div>
                    <div class="filterConfigBoxOne">
                        <div class="div">
                            <div>
                                <p class="fontStylingBasic">Filter Rule Configuration</p>
                                <div style="display:inline-flex;width: 100%">
                                    <div *ngIf="filterActionList.length === 0">
                                        <div style="padding: 16px;padding-left: 40px;padding-bottom: 32px;">
                                            <div style="padding-left: 180px;">
                                                <i class="far fa-check-square"></i>
                                            </div>
                                            <p style="font-size: 12px; text-align: center; margin-right:20px !important;">Please select the filter trigger from the left library to view and configure rule</p>
                                        </div>
                                    </div>
                                    <div *ngIf="filterActionList.length !== 0" style="width: 50%">
                                        <div>
                                            <div *ngFor="let key of filterActionList">
                                                <p-radioButton [inputId]="key.key" name="key" (ngModelChange)="updateOptions($event)"  [value]="key" [(ngModel)]="selectedFilterAction"></p-radioButton>
                                                <label  style="padding-left: 5px;" [for]="key.key">{{key.label}}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="selectedFilterAction.length !== 0 && selectedFilterCol.length !==0" style="width: 50%">
                                        <div style="display:inline-flex">
                                            <div class="div" *ngIf="filterObject !== undefined && filterObject !== null &&
                                                            (filterObject['type'] === 'T' || filterObject['type'] === 'TA' || filterObject['type'] === 'LB' || filterObject['type'] === 'L' || filterObject['type'] === 'ML' ||filterObject['type'] === 'EL')" style="padding: 5px">
                                                <ctrm-input (onChangeValue)="addTextOption($event)" [ngModel]="filterObject['firstValue']" [placeholder]="'Type you search'" class="contentMargin"></ctrm-input>
                                            </div>
                                            <div class="div" *ngIf="filterObject !== undefined && filterObject !== null &&
                                                            (filterObject['type'] === 'B' || filterObject['type'] === 'OB')" style="padding: 5px">
                                                <p-dropdown [(ngModel)]="filterObject['firstValue']"
                                                            [options]="getListOfValues(filterObject)">
                                                </p-dropdown>
                                            </div>
                                            <div class="div" *ngIf="filterObject !== undefined && filterObject !== null && filterObject['type'] === 'N'" class="contentBox">
                                                <ctrm-input [ngModel]="filterObject['firstValue']" (onChangeValue)="addNumberOption($event,true)" [placeholder]="'Type you search'" [defaultValue]="filterObject['firstValue']" [type]="'number'" class="contentMargin">
                                                </ctrm-input>
                                                <ctrm-input (onChangeValue)="addNumberOption($event,false)" [placeholder]="'Type you search'" *ngIf="isSecondRequired(filterObject)" [defaultValue]="filterObject['secondValue']" [type]="'number'" class="contentMargin">
                                                </ctrm-input>
                                            </div>
                                            <div class="div" *ngIf="filterObject !== undefined && filterObject !== null && filterObject['type'] === 'D'" class="contentBox">
                                                <input [bsConfig]="{dateInputFormat : getDateFormat(filterObject['field']),useUtc:true}"
                                                       [minMode]="filterObject['field'].getFromExtra('date')['view']"
                                                       bsDatepicker
                                                       [(bsValue)]="filterObject['firstValue']"
                                                       class="form-control"
                                                       placeholder="{{isSecondRequired(filterObject,'betweendates')?'Select Start Date':'Select Date'}}" type="text">
                                                <input *ngIf="isSecondRequired(filterObject,'betweendates')"
                                                       [bsConfig]="{dateInputFormat : getDateFormat(filterObject['field']),useUtc:true}"
                                                       [minDate]="isValidDate(filterObject['firstValue'])?filterObject['firstValue']:''"
                                                       [minMode]="filterObject['field'].getFromExtra('date')['view']"
                                                       bsDatepicker
                                                       [(bsValue)]="filterObject['secondValue']"
                                                       class="form-control"
                                                       placeholder="Select End Date"
                                                       style="margin-left: 6px" type="text">
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button (click)="addFieldValue()" [disabled]="(selectedFilterAction.length === 0 || selectedFilterCol.length ===0)" class="applyFilterAddRulesButton">+ Add to List</button>
                    </div>
                    <div class="filterConfigBoxTwo div">
                        <div>
                            <p class="fontStylingBasic">Configured Filter List</p>
                            <div style="display: inline-flex">
                                <div *ngIf="filterActionList.length === 0 && filterConfigarationList.length === 0">
                                    <div>
                                        <div style="padding-left: 80%;">
                                            <i class="far fa-check-square"></i>
                                        </div>
                                        <p style="font-size: 12px;padding-left: 180px; padding-right: 9px; background-color: #fff;padding-bottom: 86px; text-align: center;">No filter configured</p>
                                    </div>
                                </div>
                                <div *ngIf="filterActionList.length !== 0 && filterConfigarationList.length === 0">
                                    <div>
                                        <div style="padding-left: 80%;">
                                            <i class="far fa-check-square"></i>
                                        </div>
                                        <p style="font-size: 12px;padding-left: 180px; padding-right: 9px; background-color: #fff;padding-bottom: 86px; text-align: center;">No filter configured</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="filterConfigBoxFour div" *ngIf="filterActionList.length === 0 && filterConfigarationList.length !== 0 || filterConfigarationList.length>=0 || filterConfigarationList.length<=6">
                        <div><p class="fontStylingBasic">Configured Filter List</p>
                            <div style="height: 150px;overflow-y: scroll;">
                                <table style="border: white!important;width: 100% !important;">
                                    <tbody style="width: 100%">
                                    <tr *ngFor="let header of filterConfigarationList; index as idx" class="trrow" (click)="selectRow(header)">
                                        <td style="padding-right: 20px;">{{header.header}}</td>
                                        <td style="padding-right: 20px;">{{getConditionTitle(header)}}</td>
                                        <td style="padding-right: 20px;word-break: break-all;">{{getFormattedValue(header)}}</td>
                                        <td style="width: 20px">
                                            <i class="fas fa-times clickablecross" (click)="deleteFieldValue(idx)" ></i>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <button (click)="onClose()" style="width: 60px;padding-left: 13px;" class="applyCloseButton">Close</button>
                    <button (click)="onClearfilter()" style="width: 90px;padding-left: 13px;" class="clearAllfilterButton">Clear filter</button>
                    <button (click)="onApplyFilter()" [disabled]="(filterConfigarationList === null || filterConfigarationList === undefined || filterConfigarationList.length === 0)
                     && (mainFilterArray === null || mainFilterArray === undefined || mainFilterArray.length === 0)" style="width: 60px;padding-left: 13px;" class="applyFilterButton planScreenBtns customFilterBtn">Apply</button>
                </div>
            </div>

        </div>
    </div>
    <p-dialog [(visible)]="preferenceSaveDialog"
              [closable]="true"
              [draggable]="false"
              [modal]="true"
              [maximizable]="false"
              [showHeader]="false"
              [style]="{ width: '30%',height: '20%',maxHeight:'20%', padding:'0px', background:'#ffffff'}">
        <div style="padding: 5px 10px">
            <p style="font-size: 14px; font-weight: bold; color: #16144E">Save Preference</p>
        </div>
        <div style="padding: 20px">
            <ctrm-input style="width: 480px;!important" [placeholder]="'Preference Name'" [defaultValue]="preferenceName" [(ngModel)]="preferenceName" (onChangeValue)="onPrefNameChange($event)"></ctrm-input>
        </div>
        <p-footer>
            <button class="cancelBtn" (click)="preferenceSaveDialog = false">Cancel</button>
            <button class="planScreenBtns" style="color: white" (click)="savePreferences()">Save</button>
        </p-footer>
    </p-dialog>
</div>

<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body">
</p-toast>
