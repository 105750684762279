import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonService} from '../../../services/common.service';
import {MasterService} from '../../../masters/services/MasterService';
import {MessageService} from 'primeng';

@Component({
    selector: 'future-ticker-list',
    templateUrl: './future-ticker-list.component.html',
    styleUrls: ['./future-ticker-list.component.css']
})
export class FutureTickerListComponent implements OnInit {

    @Output() onSelectTicker = new EventEmitter();
    @Input() tickerValue: any[] = [];
    @Input() isLoading: boolean = false;
    query: string = '';


    constructor(public commonService: CommonService, private masterCommonService: MasterService, private messageService: MessageService) {
    }

    ngOnInit(): void {
    }


    onClickDelete(i) {
        this.tickerValue.splice(i, 1);
    }

    onSelectRow(tickerValue: any) {
        let cardLength = 0;
        tickerValue['bookmark'] = !tickerValue['bookmark'];
        cardLength = this.tickerValue.filter((row) => row['bookmark']).length;
        if (cardLength <= 6) {
            this.onSelectTicker.emit(this.tickerValue.filter((row) => row['bookmark']));
        } else {
            this.showToast('Max number of selected Ticker reached\n' +
                'Cannot select more than 6 Ticker', 'error');
            tickerValue['bookmark'] = !tickerValue['bookmark'];
        }

    }

    showToast(msg, severity: string = 'info') {
        this.messageService.add({
            severity: severity,
            summary: msg
        });
    }

}

