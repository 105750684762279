<form (ngSubmit)="!showOnModal?onCreatePost(formGroup.value):''" [formGroup]="formGroup">
  <div *ngIf="showOnModal">
    <p-sidebar (onHide)="showConfirmdialog()"  [baseZIndex]="baseZIndex" [closeOnEscape]="false"
               [modal]="true"
               [showCloseIcon]="false"
               [style]="{minWidth:'75%',maxWidth:'75%',zIndex:'1006'}"
               [visible]="modalState"
               position="right">
      <div class="headerClass">
        {{headingForm}}

        <ctrm-button (onClick)="toggleReadOnly()" *ngIf="!updateDisable && readOnly && canEdit() && editEnabled" [label]="'Edit'"
                     icon="fas fa-pen"></ctrm-button>
        <i (click)="showConfirmdialog()" class="material-icons closable">clear</i>

      </div>

      <ng-container *ngTemplateOutlet="body "></ng-container>
      <div *ngIf="showErrorMessage && isError" class="err animated fadeIn">
        <p>{{errorMessage}}</p>
        <i (click)="toggleShowError()" class="fas fa-times closable"
           style="margin-right: 0;position: absolute;right: 9px"></i>
      </div>
      <p-footer *ngIf="showFooter" [style]="{overflow:'hidden'}">
        <ng-container *ngTemplateOutlet="footer"></ng-container>
      </p-footer>
    </p-sidebar>
  </div>
  <div *ngIf="!showOnModal">
    <ctrm-button (onClick)="toggleReadOnly()" *ngIf="readOnly && canEdit() && editEnabled" [label]="'Edit'"
                 icon="fas fa-pen"></ctrm-button>
    <ng-container *ngTemplateOutlet="body"></ng-container>
    <div *ngIf="showFooter" class="animated fadeIn">
      <ng-container *ngTemplateOutlet="footer"></ng-container>
    </div>
  </div>
  <ng-template #body>
    <div *ngIf="isFetching">
      <div [ngClass]="getFetchingClass()">
        <i class="fas fa-spinner fa-pulse fa-2x animated" style="color: rgba(0,0,0,0.51);"></i>
        <p
          style="font-size: 30px;font-weight: bold;text-align: center;color: rgba(212,212,212,0.58);margin-top: 15px;padding: 0">
          Please wait</p>
      </div>
    </div>
    <div [id]="'sectionForm'" class="sectionForm">
      <div (inViewportAction)="onModalInView()" *ngIf="!isFetching" class="contentOnModal" id="style-1" inViewport>
        <div *ngIf="showSectionForm()" class="contentDiv">
          <div [ngClass]="formClass()" class="formBox" id="formBox">
            <div *ngFor="let section of sectionKeys" [id]="section" class="sectionBoxMain">
              <div *ngIf="section !== gridTitle" class="formSectionTitle">
                <p>{{section}}</p>
              </div>
              <div class="row rowClass">
                <div *ngFor="let field of sections.get(section)"
                     [hidden]="field !== ''?!canShowField(columnsMap.get(field)):false"
                     class="col-md-{{field !== ''?getColvalue(columnsMap.get(field).getColSpan()):1}}  controlGap">
                  <div *ngIf="!readOnly && field !== '' && canShowField(columnsMap.get(field))">
                    <aio-controls (addBtnValue)="addBtnValue($event)" (focusin)="onFocusIn(columnsMap.get(field),section)"
                                  (ngModelChange)="onValueChange($event,columnsMap.get(field).getField())"
                                  (onButtonClick)="onClick($event)"
                                  (onChangeValue)="throwChanges($event)"
                                  [clearValue]="clear"
                                  [field]="columnsMap.get(field)"
                                  [fileName]="getFileName(columnsMap.get(field))"
                                  [formControlAIO]="getFormControl(columnsMap.get(field).getField())"
                                  [formControl]="formGroup.controls[columnsMap.get(field).getField()]"
                                  [id]="columnsMap.get(field).getField()"
                                  [isFocused]="focuedField === columnsMap.get(field).getField()"
                                  [isUpdating]="update"
                                  [listOfValues]="columnsMap.get(field).getListOfValues()"
                                  [readOnly]="isReadOnly(columnsMap.get(field))"
                                  [saveUrl]="saveUrl"
                                  [showAddButtons]="showAddButtons"
                                  [submitted]="sumitted"
                                  [tabIndex]="-1" [requiresContinuesChangeDetection]="fieldRequiresContinuesChangeDetection.includes(field)"
                                  [customTemplate]="fieldsWithCustomView.includes(field)?customViewTemplated[fieldsWithCustomView.indexOf(field)]:null"
                                  [value]="getFormControlValue(columnsMap.get(field).getField())"
                                  [viewOnly]="viewOnlyMode"></aio-controls>
                  </div>
                  <div *ngIf="readOnly && field !== '' && canShowField(columnsMap.get(field))">
                    <div class="infoCard">
                      <div class="infoHeading">{{columnsMap.get(field).getHeader()}}</div>
                      <div [innerHTML]="getRowValue(columnsMap.get(field), rowData)" pTooltip="{{getRowValue(columnsMap.get(field), rowData)}}" tooltipZIndex="99999" tooltipPosition="bottom" class="infoContent"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="gridTitle === 'Role Master'">
              <ng-container *ngTemplateOutlet="accessPolicyUI"></ng-container>
            </div>
            <div *ngIf="gridTitle !== 'Role Master'" class="bottomPadding"></div>
          </div>
          <div *ngIf="!readOnly" [ngClass]="showOnModal?'validBox':'validBoxOnGrid'">
            <ng-container *ngTemplateOutlet="validations"></ng-container>
          </div>
        </div>
        <div *ngIf="!showSectionForm()" class="withouSection">
          <div class="row rowWidth">
            <div *ngFor="let field of fields" [hidden]="!canShowField(field)"
                 class="col-md-{{getColvalue(field.getColSpan())}}">
              <div>
                <aio-controls (addBtnValue)="addBtnValue($event)" (onButtonClick)="onClick($event)"
                              (onChangeValue)="throwChanges($event)"
                              [clearValue]="clear"
                              [field]="field"
                              [fileName]="getFileName(field)"
                              [formControlAIO]="getFormControl(field.getField())"
                              [formControl]="formGroup.controls[field.getField()]"
                              [isUpdating]="update"
                              [listOfValues]="field.getListOfValues()"
                              [readOnly]="isReadOnly(field)"
                              [saveUrl]="saveUrl"
                              [showAddButtons]="showAddButtons"
                              [showErrorMessage]="true"
                              [submitted]="sumitted"
                              [tabIndex]="-1"
                              [value]="getFormControlValue(field.getField())"
                              [viewOnly]="viewOnlyMode"></aio-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    <div *ngIf="isTabs() && update">-->
    <!--      <ng-container *ngTemplateOutlet="tabs"></ng-container>-->
    <!--    </div>-->
  </ng-template>

  <ng-template #viewOnly>
    <div class="ui-g viewModeContainer">
      <div *ngFor="let field of fields;let i = index">
        <div class="infoCard">
          <div class="infoHeading">{{field.getHeader()}}</div>
          <div class="infoContent">{{getRowValue(field, rowData)}}</div>
        </div>
      </div>
    </div>
  </ng-template>


  <ng-template #tabs>
    <div class="contentOnModal">
      <app-tab-grid (onButtonClick)="onBttonClick($event)" (onRowDataDelete)="onRowDataDelete.emit($event)"
                    (onRowDataSave)="onRowDataSave.emit($event)"
                    [onModal]="false"
                    [parentKeyValue]="getParentKeyValue()"
                    [refreshGrid]="refreshGrid"
                    [tabs]="gridTabs"></app-tab-grid>
    </div>
  </ng-template>
  <ng-template #infoUpdate>
    <div *ngIf="viewCreatedBy">
      <div *ngIf="update" class="lastUpdateInfoOnForm">
        <div style="display: flex;flex-direction: row">
          <p>Created By : {{createdBy}}</p>
          <p>Created On : {{createdDate}}</p>
        </div>
        <div style="display: flex;flex-direction: row">
          <p>Last Modified By : {{updatedBy}}</p>
          <p>Last Modified On : {{updatedDate}}</p>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #validations>
    <div class="validation">
      <div *ngFor="let key of sectionKeys" class="section bottomGap">
        <div (click)="goToField(key,key)" class="sectionTitle">
          <i [ngClass]="getIconFromValidations(formGroup,sections.get(key))"></i>
          <!--          <img [src]="getIconFromValidations(formGroup,sections.get(key))"/>-->
          <p [ngClass]="currentlyEditing(sections.get(key))" class="{{focusedSection === key?'focused':''}}">{{key}}</p>
        </div>
        <div *ngIf="focusedSection === key">
          <div *ngFor="let field of sections.get(key)"
               [hidden]="field !== ''?(!isErrorInField(formGroup,field) || (commonService.getValidationErrorMessage(formGroup.controls[field], columnsMap.get(field).getHeader()).length === 0)):true"
               class="section leftShift">
            <div *ngIf="field !== '' " class="sectionTitle">
              <!--            <img [src]="getIconFromFieldValidations()"/>-->
              <i class="fas fa-angle-right iconClassValidation"></i>
              <p (click)="goToField(field,key)"
                 [ngClass]="currentlyEditingField(field)">{{commonService.getValidationErrorMessage(formGroup.controls[field], columnsMap.get(field).getHeader())}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #footer>
    <div [style]="{overflow:'hidden'}">
      <div [ngClass]="showOnModalClass()" class="style-4">
        <div style="display: flex;">
          <button (click)="showConfirmdialog()" accesskey="c" class="ui-button-raised mybuttonClose" label="Close"
                  pButton
                  style="margin-right: 15px;"
                  type="button"></button>
          <button (click)="showOnModal?onCreatePost(formGroup.value):''" *ngIf="!postBtn && canEdit()"
                  [disabled]="runUpdateDisableFunction() || !formGroup.valid  || readOnly"
                  [hidden]="isLoading"
                  [tabIndex]="1"
                  accesskey="s"
                  class="ui-button-raised savebutton"
                  style="margin-right: 15px;" type="submit">{{update?'Update':'Save'}}</button>
          <button (click)="showOnModal?onCreatePost(formGroup.value):''" *ngIf="postBtn && canEdit()"
                  [disabled]="!formGroup.valid"
                  [hidden]="isLoading"
                  [label]="'Post'"
                  accesskey="s"
                  class="ui-button-raised savebutton"
                  pButton
                  style="margin-right: 15px;" type="submit"></button>
          <div [hidden]="!isLoading" class="loadingbutton">
            <div class="loader"></div>
            <p>{{update ? 'Updating' : 'Saving'}}</p>
          </div>
          <div *ngIf="showExtraButtons">
            <div *ngFor="let button of extraButtons" class="extraButtonContainer style-4">
              <button (click)="performAction(button)" *ngIf="button.scope==='F' || button.scope==='B'"
                      [disabled]="button.disabled||(button.formGroupValidation && !formGroup.valid)"
                      [iconPos]="button.iconPosition" [icon]="button.icon" class="ui-button-raised mybutton"
                      label="{{button.title}}"
                      pButton pTooltip="{{button.toolTip}}"
                      type="button"></button>
            </div>
          </div>
        </div>
        <ng-container *ngTemplateOutlet="infoUpdate"></ng-container>
      </div>
    </div>
  </ng-template>
</form>

<p-confirmDialog [baseZIndex]="25" [visible]="showConfirm" header="Confirmation"
                 icon="pi pi-exclamation-triangle"></p-confirmDialog>

<ng-template #accessPolicyUI>
  <div class="sectionAccess marginTopClass">
    <div class="sectionHeader sticky-top">
      <p class="sectionTitle">Role Access Permission</p>
      <ctrm-button (onClick)="expandAll()" [icon]="'fas fa-plus-square'" [label]="'Expand All'"
                   style="margin-top: 3px"></ctrm-button>
      <ctrm-button (onClick)="collapseAll()" [icon]="'fas fa-minus-square'" [label]="'Collapse All'"
                   style="margin-top: 3px;margin-right: 5px"></ctrm-button>
      <ctrm-autocomplete (value)="updateValue($event)" [id]="'accesPolicySearchBox'" [minWidth]="'fit-content'"
                         [placeholder]="'Search...'"></ctrm-autocomplete>
      <div class="legendView">
        <div class="legenBox">
          <div class="dot selectedDot"></div>
          <p class="legendTitle">All Selected</p>
        </div>
        <div class="legenBox">
          <div class="dot partiallySelected"></div>
          <p class="legendTitle">Partially Selected</p>
        </div>
        <div class="legenBox">
          <div class="dot unselected"></div>
          <p class="legendTitle">All Unselected</p>
        </div>
      </div>
    </div>
    <div (inViewportAction)="calculateAccessPolicyHeight()" [id]="'contentBox'" [style]="{maxHeight:contentHeight,overflow:'auto'}"
         class="sectionBodyPermission style-4" inViewport>
      <div *ngFor="let mapKey of accesssections">
        <div [hidden]="searchValue.length > 0?contains(accessMap.get(mapKey),searchValue):false" class="sectionNameBox">
          <p class="sectionTitleItem sticky-top">{{mapKey}}</p>
          <p (click)="ToggleAll(mapKey,true)" *ngIf="!readOnly" class="selectButton">Select All</p>
          <p (click)="ToggleAll(mapKey,false)" *ngIf="!readOnly" class="selectButton">Unselect All</p>
        </div>
        <div *ngFor="let item of accessMap.get(mapKey)">
          <role-accordian (onCollapseExpandDone)="resetExpandValue()"
                          [disable]="readOnly"
                          [expand]="expandAllValue"
                          [data]="item"
                          [hidden]="searchValue.length > 0?isHidden(item):false">
          </role-accordian>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!--<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"-->
<!--         appendTo="body"></p-toast>-->
