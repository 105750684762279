import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import {FormBuilder, FormControl} from '@angular/forms';
import {MasterService} from '../../masters/services/MasterService';
import {CommonService} from '../../services/common.service';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {KeyValue} from '../../grid/key-value.model';
import {Grid} from '../../grid/grid.model';
import {Tcolumn} from '../../grid/tcolumn.model';
import {defaultValue} from '../../grid/defaultValue.model';
import {MessageService, OverlayPanel} from 'primeng';
import {Router} from '@angular/router';

@Component({
  selector: 'future-position-dashboard',
  templateUrl: './future-position-dashboard.component.html',
  styleUrls: ['./future-position-dashboard.component.css']
})
export class FuturePositionDashboardComponent implements OnInit {


  @Input() dialogDisplay: boolean = false;
  @Output() onClose = new EventEmitter();
  showDateRange: boolean = false;
  selectedPreset:any= {};
  ranges: any[] = [];
  allBrokerList: any[] = [];
  expiryMonthList: any[] = [];
  futureIndex: any[] = [];
  profitCenter: any[] = [];
  bsConfig: Partial<BsDatepickerConfig>;
  //card
  isallselected: boolean = true;
  futurePosition:any[] = [];
  hiddenCard:any[] =[];
  disableCardList:any[] =[];
  //grid
  private box:HTMLDivElement;
  gridHeight: any;
  futureValues: any[] =[];
  toolBarConfig: any = {
    add: false,
    edit: false,
    copy: false,
    delete: false,
    refresh: false,
    export: true,
    exportAll: false,
    import: false,
    audit: false,
    print: false,
    prefrences: false
  };
  futureGrid: Grid;
  selectedFuturePosition: any[] = [];
  allselected: boolean = true;
  @HostListener("window:resize", [])
  public onResize() {
    this.calcaulateListHeight();
    this.calculateGridHeight(this.box);
  }
  displayPanel: boolean = true;
  @ViewChild('overlayPanel', {static: true}) overlayPanel: OverlayPanel;
  overlayPanelRowData:any;
  documents: any[] =[];
  displayBasic:boolean = false;
  top: any = '0px';
  @Input() futureDropdownValue:any;
  expiryMonthFilter:boolean = false;
  toggleButton:boolean = false;

  constructor(private masterCommonService: MasterService, public commonService: CommonService,private fb: FormBuilder,
              private router: Router,private messageService: MessageService) { }

  ngOnInit(): void {
    this.createGrid();
    this.getFuture();
  }

  private createGrid() {
    let index = 0;
    let _this = this;

    let pnlFormatFunc: Function = function(value) {
      let html: any;
      if (value < 0) {
        html = _this.commonService.getSafeHtml('<div style="display: inline-flex">' +
            '<i class="fas fa-chevron-down" style="color:#bf0000; padding-top: 3px"></i>'+
            '<p style="color:#bf0000; padding: 0;font-size: 12px;margin: 0 0 0 10px;white-space: nowrap !important;">'+ value +'</p></div>');
      }else if(value > 0) {
        html = _this.commonService.getSafeHtml('<div style="display: inline-flex">' +
            '<i class="fas fa-chevron-up" style="color:#39a400; padding-top: 3px"></i>'+
            '<p style="color:#39a400; padding: 0;font-size: 12px;margin: 0 0 0 10px;white-space: nowrap !important;">'+ "+" +value +'</p></div>');
      } else {
        html = _this.commonService.getSafeHtml('<div style="display: inline-flex">' +
            '<p style="color:#000000; padding: 0;font-size: 12px;margin: 0 0 0 10px;white-space: nowrap !important;">'+ value +'</p></div>');
      }
      return html;
    }

    let columns: Map<string, Tcolumn> = new Map();
    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', index++, false, undefined, false, '', 'N'));
    columns.set('status', new Tcolumn('status', 'Hedge Status', 'LB', index++, true, undefined, true,new defaultValue('', undefined, undefined, undefined, false, false, false),'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('trantype', new Tcolumn('trantype', 'Trade Type ', 'LB', index++, false, undefined, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined));
    columns.set('commodity', new Tcolumn('commodity', 'Commodity', 'LB', index++, false, undefined, true, '', 'H', [], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('tradedate', new Tcolumn('tradedate', 'Trade Date', 'D', index++, false, undefined, true, undefined, 'H', [], undefined, undefined, undefined, undefined, undefined));
    columns.set('profitcenter', new Tcolumn('profitcenter', 'Profit Center', 'LB', index++, true, undefined, true,undefined, 'B', [], undefined, undefined, undefined, undefined, undefined));
    columns.set('expirymonth', new Tcolumn('expirymonth', 'Contract Month', 'LB', index++, false, undefined, true, undefined, 'B', []));
    columns.set('executionbroker', new Tcolumn('executionbroker', 'Broker', 'LB', index++, true, undefined, true, undefined, 'B', [],'text',1,undefined,undefined,undefined,undefined,undefined,false,undefined));
    columns.set('lot', new Tcolumn('lot', '# Lots', 'N', index++, false, undefined, true, undefined, 'B', []));
    columns.set('tradeprice', new Tcolumn('tradeprice', 'Price', 'N', index++, true, undefined, true,undefined, 'B', [],'text',1,undefined,undefined,undefined,undefined,undefined,false,undefined));
    columns.set('expirydate', new Tcolumn('expirydate', 'Expiry Date', 'D', index++, false, undefined, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined));
    columns.set('daystoexpire', new Tcolumn('daystoexpire', 'Time to Expiry', 'N', index++, false, undefined, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined));
    columns.set('mtm_pnl', new Tcolumn('mtm_pnl', 'MTM Pnl', 'N', index++, false, undefined, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined));
    columns.set('pnlchange', new Tcolumn('pnlchange', 'Gain/Loss', 'N', index++, false, undefined, true, new defaultValue('',undefined,undefined,undefined,false,true,false,pnlFormatFunc), 'B', [], undefined, undefined, undefined, undefined, undefined));
    columns.set('futureindex', new Tcolumn('futureindex', 'Index Name', 'LB', index++, false, undefined, true, '', 'H', [], undefined, undefined, undefined, undefined, undefined));
    columns.set('lotsize', new Tcolumn('lotsize', 'Lot Size', 'N', index++, false, undefined, true, '', 'H', [], undefined, undefined, undefined, undefined, undefined));
    this.futureGrid = new Grid(columns, true, true, true, true, '', '/api/unadjustedfuturetrades/v1/getallunadjustedfuturetrades?tenantId=' + this.commonService.getTenantId(), '', '', '', '', undefined, undefined, false);
    this.futureGrid.setGridConfiguration(false, false, false, false, false, true, true, false, false, false);
    this.futureGrid.showCheckbox = true;
    this.futureGrid.setWidth('100%');
    this.futureGrid.openDefaultInputForm = false;
    this.futureGrid.openSidebarOnUniqueClick = false;
  }

  filterForms=this.fb.group({
    allBrokerList:new FormControl(),
    futureIndex:new FormControl(),
    profitCenter:new FormControl(),
  });

  resetFilterForm() {
    if(this.futureDropdownValue !== undefined && this.futureDropdownValue !== null) {
      this.filterForms.controls['allBrokerList'].setValue('all');
      this.filterForms.controls['futureIndex'].setValue(this.futureDropdownValue);
      this.filterForms.controls['profitCenter'].setValue('all');
    } else {
      this.filterForms.controls['allBrokerList'].setValue('all');
      this.filterForms.controls['futureIndex'].setValue('all');
      this.filterForms.controls['profitCenter'].setValue('all');
    }
  }

  //Show Calendar
  toggleDateRange() {
    this.showDateRange = !this.showDateRange;
  }

  onCancel(value) {
    this.showDateRange = false;
  }

  onPresetChange(preset: any) {
    this.selectedPreset = preset;
    this.getFuturePosition();
    this.toggleDateRange();
  }

  onFormCancel() {
    this.onClose.emit();
  }

  //Card Panel
  calcaulateListHeight() {
    let el = document.getElementById('list');
    if(el !== null && el !== undefined)  {
      let viewportHeight = window.innerHeight;
      let offsetTop = el['offsetTop'];
      let height = viewportHeight - offsetTop - 150;
      el.style.maxHeight = height + 'px';
      el.style.minHeight = height + 'px';
    }
  }

  calculateGridHeight(divbox: HTMLDivElement) {
    if(divbox !== null && divbox !== undefined) {
      this.box = divbox;
      let viewportHeight = window.innerHeight;
      let offsetTop  = divbox.offsetTop;
      this.gridHeight = viewportHeight - offsetTop - 200;
    }
  }

  //On change of filter Values (Broker/ProfitCenter/Future Index/Expiry Month)
  onFilterValueChange(value: any){
    let _this = this;
    let filterPayload = {
        executionBroker: this.filterForms.controls['allBrokerList'].value,
        futureIndex: this.filterForms.controls['futureIndex'].value,
        profitcenter: this.filterForms.controls['profitCenter'].value,
      }
    this.futurePosition = [];
    this.futureValues = [];
    this.commonService.post(environment.base_url + '/api/unadjustedfuturetrades/v1/getallunadjustedfuturetrades' + '?tenantId=' + this.commonService.getTenantId()  + '&isExpiryMonth=false',filterPayload).subscribe((next:any) =>{
      _this.futurePosition = next;
      _this.toggleButton = false;
      _this.futurePosition.forEach(function(futurePos:any){
        _this.futureValues = [..._this.futureValues,...futurePos['futureTradesList']];
      });
    });
    if(value['value'] !== undefined && value['value'] !== null && value['value'] !== 'all') {
      this.commonService.getJSONByURL(environment.base_url + '/api/priceIndex/v1/getTickerList?name=' + value['value']+'&tenantId='+this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
        this.expiryMonthList = [{label:'Select',value:''}];
        this.expiryMonthList = [...this.expiryMonthList,...next];
        this.createPreset();
      });
    } else {
      this.commonService.getJSONByURL(environment.base_url + '/api/unadjustedfuturetrades/v1/getunadjustedfuturedropdowndata' + '?tenantId=' + this.commonService.getTenantId()).subscribe((next:any) =>{
        this.expiryMonthList = this.masterCommonService.getListFromArray(next['expirymonth'],false,true,'Select','');
        this.createPreset();
      });
    }
  }

  onSelectionChangeAll(value: any) {
    this.allselected = !this.allselected;
    this.isallselected = this.allselected;
    this.selectedFuturePosition = [];
    if(this.allselected) {
      this.selectedFuturePosition = [...this.futurePosition];
    }
  }

  onSelectCard(value: any) {
    let _this = this;
    _this.selectedFuturePosition.push(value);
    this.futureValues = [...this.futureValues,...value['futureTradesList']];
    if(this.selectedFuturePosition.length === this.futurePosition.length) {
      this.allselected = true;
      this.isallselected = true;
    }
  }

  onUnselectCard(value:any) {
    let _this = this;
    let oldList:any[] = [...this.futureValues]
    this.futureValues = [];
    value['futureTradesList'].forEach(val =>{
      oldList.splice(oldList.indexOf(val),1);
    })
    this.futureValues = [...oldList]
    if(this.futureValues.length === 0) {
      this.allselected = false;
      this.isallselected = false;
    } else {
      this.isallselected = true;
      this.allselected = false;
    }

  }

  //Get Card and Grid data
  getFuturePosition() {
    let _this = this;
    let url = '/api/unadjustedfuturetrades/v1/getallunadjustedfuturetrades' + '?tenantId=' + this.commonService.getTenantId()  + '&isExpiryMonth=false';
    let filterPayload = {};
    let expiryMonth = '';
    let fromDate = '';
    let toDate = '';
    if(this.selectedPreset['type'] !== undefined && this.selectedPreset['type'] !== null && this.selectedPreset['type'] === 'L' ) {
      expiryMonth = this.selectedPreset['selectedValue'];
    } else {
      fromDate = this.selectedPreset['startDate'];
      toDate = this.selectedPreset['endDate'];
    }

      if(this.futureDropdownValue !== undefined && this.futureDropdownValue !== null && this.futureDropdownValue !== 'all') {
         filterPayload = {
          executionBroker: this.filterForms.controls['allBrokerList'].value,
          futureIndex: this.futureDropdownValue,
          profitcenter: this.filterForms.controls['profitCenter'].value,
          expirymonth: expiryMonth,
          fromDate: fromDate,
          toDate: toDate
        }
      } else {
        filterPayload = {
          executionBroker: this.filterForms.controls['allBrokerList'].value,
          futureIndex: this.filterForms.controls['futureIndex'].value,
          profitcenter: this.filterForms.controls['profitCenter'].value,
          expirymonth: expiryMonth,
          fromDate: fromDate,
          toDate: toDate
        }
      }
        this.futurePosition = [];
        this.futureValues = [];
        this.commonService.post(environment.base_url + url,filterPayload).subscribe((next:any) =>{
          _this.futurePosition = next;
          _this.futurePosition.forEach(function(futurePos:any){
            _this.futureValues = [..._this.futureValues,...futurePos['futureTradesList']];
          });
        });
  }

  //Get values in drop down
  getFuture() {
    this.futurePosition = [];
    this.commonService.getJSONByURL(environment.base_url + '/api/unadjustedfuturetrades/v1/getunadjustedfuturedropdowndata' + '?tenantId=' + this.commonService.getTenantId()).subscribe((next:any) =>{
      this.allBrokerList = this.masterCommonService.getListFromArray(next['executionbroker'],false,true,'All','all');
      this.profitCenter = this.masterCommonService.getListFromArray(next['profitcenter'],false,true,'All','all');
      this.futureIndex = this.masterCommonService.getListFromArray(next['futureindex'],false,true,'All','all');
      this.expiryMonthList = this.masterCommonService.getListFromArray(next['expirymonth'],false,true,'Select','');
      this.createPreset();
      this.resetFilterForm();
      this.getFuturePosition();
    });
  }

  onClickStatus(event: MouseEvent, displayPanel: OverlayPanel, rowData: any, ptag: HTMLParagraphElement) {
    this.documents = [];
    this.overlayPanelRowData = rowData;
    this.displayBasic = true;
    let status = rowData['status'];
    let tag = rowData['tag'];
    if (status !== undefined || status !== null) {
      if (status === 'Physical Hedge') {
        if(tag !== undefined && tag !== null) {
          this.top = event.screenY - 600 + 'px';
          displayPanel.toggle(event);
          this.documents.push(tag);
        } else {
          this.showToast('No Trade is linked to the Future Trade.');
        }
      }
    }
  }

  showToast(msg, severity: string = 'info') {
    this.messageService.add({
      severity: severity,
      summary: msg
    });
  }

  onClickOfTradeId(value:any) {
      this.router.navigate(['/trade'],{ queryParams: { value:  value['srcElement']['innerText'] } });
   }

   //Get Calendar values for Drop down
  private createPreset() {
    this.ranges = [];
    this.ranges.push({title:'Expiry Month',type:'L',list:this.expiryMonthList})
    this.selectedPreset = this.ranges[0];
  }

    toggleChange(value: any) {
      if(value) {
      this.expiryMonthFilter = false;
      this.getcardByExpiryMonth();
    } else {
      this.expiryMonthFilter = true;
      this.getFuturePosition();
    }
    }

    getcardByExpiryMonth() {
      let _this = this;
      let url = '/api/unadjustedfuturetrades/v1/getallunadjustedfuturetrades' + '?tenantId=' + this.commonService.getTenantId() + '&isExpiryMonth=true';
      let filterPayload = {};
      let expiryMonth = '';
      let fromDate = '';
      let toDate = '';
      if(this.selectedPreset['type'] !== undefined && this.selectedPreset['type'] !== null && this.selectedPreset['type'] === 'L' ) {
        expiryMonth = this.selectedPreset['selectedValue'];
      } else {
        fromDate = this.selectedPreset['startDate'];
        toDate = this.selectedPreset['endDate'];
      }
        filterPayload = {
          executionBroker: this.filterForms.controls['allBrokerList'].value,
          futureIndex: this.filterForms.controls['futureIndex'].value,
          profitcenter: this.filterForms.controls['profitCenter'].value,
          expirymonth: expiryMonth,
          fromDate: fromDate,
          toDate: toDate
        }
      this.futurePosition = [];
      this.futureValues = [];
      this.commonService.post(environment.base_url + url,filterPayload).subscribe((next:any) =>{
        _this.futurePosition = next;
        _this.futurePosition.forEach(function(futurePos:any){
          _this.futureValues = [..._this.futureValues,...futurePos['futureTradesList']];
        });
      });
    }
}
