import {Component, Input, OnInit} from '@angular/core';
import {Tcolumn} from '../../grid/tcolumn.model';
import {Grid} from '../../grid/grid.model';
import {KeyValue} from '../../grid/key-value.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Tab} from '../../tab-grid/Tab.model';
import {MasterService} from '../services/MasterService';
import {defaultValue} from '../../grid/defaultValue.model';
import {environment} from '../../../environments/environment';
import {CommonService} from '../../services/common.service';
import {HttpErrorResponse} from '@angular/common/http';
import {MessageService} from 'primeng';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-unit-of-measurement',
    templateUrl: './unit-of-measurement.component.html',
    styleUrls: ['./unit-of-measurement.component.css']
})
export class UnitOfMeasurementComponent implements OnInit {

    columns: Map<string, Tcolumn>;
    grid: Grid;
    groupList: KeyValue[] = [];
    tabs: Tab[];
    uomDisplay: boolean = false;
    height: string = '';
    pageDisable: boolean = true;
    @Input() canEdit: boolean = true;
    @Input() selectedRowData: any;
    convertionColumns: Tcolumn[] = [];
    conversionData: any[] = [];
    displayGrid: boolean = true;
    isLoading: boolean = false;
    createdBy: any;
    createdDate: any;
    updatedBy: any;
    updatedDate: any;
    tenantId: any;
    uuid: any;
    createdTimestamp: any;
    updatedTimestamp: any;
    refreshGrid: boolean = false;
    datePipe: DatePipe;
    loadDataList: any[] = [];
    //conversion table
    refreshAddNewRow: boolean = true;
    newRows: any[] = [];
    refreshFormGroup: boolean = false;
    //OnClickAdd
    gridColumns: Tcolumn[] = [];
    onClickAddDisplay: boolean = false;
    uomForm: FormGroup;
    includedCharacters: any[] = ['_', '/', ' '];
    name: string = '';
    description: string = '';
    uomType: string = '';
    uomTypeList: any[] = [];
    convertionCols: Tcolumn[] = [];
    conversionValue: any[] = [];
    status: any;
    toUomList: any[] = [];
    //validator section
    sectionKeys: any[] =[];
    focusedSection: string = '';
    focuedField: string = '';
    currentlyFocuedField: string;
    values: any[] = [];
    getToUomList: any[] = [];
    uomData: any[] = [];

    constructor(private masterCommonService: MasterService, public  commonService: CommonService, private messageService: MessageService, private masterCommService: MasterService,private formBuilder: FormBuilder) {
    }

    ngOnInit() {
        this.commonService.getLoadData('unitofmeasurement', ['data'], [[{key: 'name', value: 'uom_type_ind'
        }]]).subscribe((next: any) => {
            this.loadDataList = next;
            this.uomTypeList = this.masterCommonService.getListFromArray(this.loadDataList['uom_type_ind']);
            this.getColumns();
            this.grid = new Grid(this.columns, true, true, true, true, 'name', '/api/unitofmeasurement/v1/getall?tenantId=' + this.commonService.getFromStorage('tenantId'), '', 'Unit of Measurement');
            this.gridColumns = this.masterCommonService.getGridField(this.grid);
            this.grid.iconName = 'unitOfMeasurement';
            this.grid.importUrl = environment.base_url + '/api/unitofmeasurement/v1/import';
            this.grid.openSidebarOnUniqueClick = false;
            this.grid.openDefaultInputForm = false;
            this.grid.setGridConfiguration(false, false, false, false,false);
            this.grid.setToolbarConfig(true,true,false);
            this.datePipe = new DatePipe('en-US');
            this.initInternalForm();
        });
    }

    public getColumns() {
        this.columns = new Map();
        this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
        this.columns.set('name', new Tcolumn('name', 'UOM Name', 'T', 2, true, undefined, true, new defaultValue('', '', ['-', '_', '/'], undefined, false, true, false, undefined, undefined, true), 'B', [Validators.required, Validators.maxLength(100)], undefined, undefined, undefined, undefined, [], undefined, undefined, true));
        this.columns.set('description', new Tcolumn('description', 'UOM Description', 'TA', 3, true, undefined, true, '', 'B', [Validators.maxLength(200)]));
        this.columns.set('uomType', new Tcolumn('uomType', 'UOM Type', 'L', 4, true, this.masterCommService.getListFromArray(this.loadDataList['uom_type_ind']), true, 1, 'B', [Validators.required]));
        this.columns.set('status', new Tcolumn('status', 'Status', 'B', 5, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));
        this.convertionColumns.push(new Tcolumn('toUom', 'To UOM', 'L', 0, true, this.getToUomList));
        this.convertionColumns.push(new Tcolumn('conversionFactor', 'Conversion Factor', 'T', 1, true, undefined));

        this.getConversionCols();
    }

    getConversionCols() {
        this.convertionCols.push(new Tcolumn('toUom', 'To UOM', 'L', 0, true, this.getToUomList, true));
        this.convertionCols.push(new Tcolumn('conversionFactor','Conversion Factor','T',1,true,undefined,true));
    }

    onClickAdd() {
        this.name = '';
        this.description = '';
        this.uomType = '';
        this.status = true;
        this.refreshGrid = false;
        this.values = [];
        this.conversionData = [{toUom:'', conversionFactor:''}]
        this.createFormControl();
        this.getToUomData();
        this.onClickAddDisplay = true;
    }

    save() {
        let url = '';
        let _this = this;
        let uomDatapayload= {
            name: this.name,
            description: this.description,
            uomType: this.uomType,
            status: this.status,
            tenantId: this.commonService.getFromStorage('tenantId'),
            updatedBy: this.commonService.getFromStorage('userName'),
            createdBy: this.commonService.getFromStorage('userName')
        }
        let savepayload = {
            uomData: uomDatapayload,
            conversionData: this.conversionData
        };
            url = environment.base_url + '/api/unitofmeasurement/v1/save';
        this.commonService.post(url, savepayload).subscribe(next => {
            _this.onClickAddDisplay = false;
            _this.refreshGrid = true;
            _this.conversionData = [];
            _this.showToast('Row Successfully Saved.');
        }, (error: HttpErrorResponse) => {
            this.messageService.add({severity: 'error', summary: this.commonService.getHttpErrorMessage(error,undefined)});
        });

    }

    // Get ToUOM
    getToUomData() {
        let _this = this;
        this.commonService.getJSONByURL(environment.base_url + '/api/unitofmeasurement/v1/getuomdatabyuomtype?tenantId='
            +this.commonService.getFromStorage('tenantId') + '&uomType=' + 'mass').subscribe((next: any) => {
            _this.getToUomList = _this.commonService.createListFromObject(next,'name','name',true);
            _this.updateUomListInFormTable(_this.getToUomList)
        });
    }

    public updateUomListInFormTable(list:any[] = []) {
        this.convertionCols[0].setListofValues(list);
        this.convertionColumns[0].setListofValues(list);
    }

    onClickUnique(value: any) {
        let _this = this;
         this.selectedRowData = value['rowData'];
        this.uomDisplay = true;
        this.refreshGrid = false;
            this.commonService.getJSONByURL(environment.base_url + '/api/unitofmeasurement/v1/getbyuomname?tenantId='+this.commonService.getFromStorage('tenantId')+'&name=' + value['rowData']['name']).subscribe((next: any) => {
                this.addContentBox(next['name'], next['description'], next['uomType'], next['status'],next['createdTimestamp'],next['updatedTimestamp'],next['conversionData']);
                let toUom = _this.commonService.createListFromObject(next['conversionData'],'toUom','toUom',undefined);
               _this.updateToUomListInFormTable(toUom);
                this.updateUserInfo(next['uomData']['createdBy'], next['uomData']['createdTimestamp'], 'create');
                this.updateUserInfo(next['uomData']['updatedBy'], next['uomData']['updatedTimestamp'], 'update');
            });
        this.getToUomData();
    }

    onClickEditPage(value: any) {
        let _this = this;
        this.pageDisable = true;
        this.selectedRowData = value['rowData'];
        this.description = value['rowData']['description'];
        this.uomType = value['rowData']['uomType'];
        this.status = value['rowData']['status']
        this.uomDisplay = true;
        this.refreshGrid = false;
        this.commonService.getJSONByURL(environment.base_url + '/api/unitofmeasurement/v1/getbyuomname?tenantId='+this.commonService.getFromStorage('tenantId')+'&name=' + value['rowData']['name']).subscribe((next: any) => {
            this.conversionData = next['conversionData'];
            let toUom = _this.commonService.createListFromObject(next['conversionData'],'toUom','toUom',undefined);
            _this.updateToUomListInFormTable(toUom);
            this.updateUserInfo(next['uomData']['createdBy'], next['uomData']['createdTimestamp'], 'create');
            this.updateUserInfo(next['uomData']['updatedBy'], next['uomData']['updatedTimestamp'], 'update');
        });
        this.getToUomData();

    }

    public updateToUomListInFormTable(list:any[] = []) {
        this.convertionColumns[0].setListofValues(list);
    }

    onClickEdit() {
        this.pageDisable = !this.pageDisable;
        this.description = this.selectedRowData['description'];
        this.uomType = this.selectedRowData['uomType'];
        this.status = this.selectedRowData['status']
        this.convertionColumns[0].setEditable(this.pageDisable = false);
    }

    UpdateUom() {
        let mainpayload= {
            name: this.selectedRowData['name'],
            description: this.description,
            uomType: this.uomType,
            status: this.status,
            tenantId: this.commonService.getFromStorage('tenantId'),
            updatedBy: this.commonService.getFromStorage('userName'),
            createdBy: this.commonService.getFromStorage('userName'),
            createdTimestamp: this.selectedRowData['createdTimestamp'],
            updatedTimestamp: this.selectedRowData['updatedTimestamp'],
            uuid: this.selectedRowData['uuid']
        }
        let uompayload = {
            uomData: mainpayload,
            conversionData: this.conversionData
        };
        this.isLoading = true;
        this.commonService.post(environment.base_url + '/api/unitofmeasurement/v1/update', uompayload).subscribe((next: any) => {
            this.uomDisplay = false;
            this.pageDisable = true;
            this.isLoading = false;
            this.refreshGrid = true;
            this.showToast('Row updated Successfully');
        }, (error: HttpErrorResponse) => {
            this.messageService.add({severity: 'error', summary: error.error});
        });
    }

    addContentBox(name, description, uomType, status,createdTimestamp,updatedTimestamp, next: any[] = []) {
        this.name = name;
        this.description = description;
        this.uomType = uomType;
        this.status = status;
        this.createdDate = createdTimestamp;
        this.updatedDate = updatedTimestamp;
        this.conversionData = next;
        let _this = this;
        let metaData = [];
        metaData = this.masterCommonService.getGridField(_this.grid);
        let obj: {
            name: any,
            description: any,
            uomType: any,
            status: any,
            metadata: Tcolumn[],
            values: any[],
            expanded: boolean,
            newRows: any[],
            refreshAddNewRow: boolean,
            refreshFormGroup: boolean
        } = {
            name: name,
            description: description,
            uomType: uomType,
            status: status,
            metadata: metaData,
            values: next,
            expanded: true,
            newRows: next,
            refreshAddNewRow: true,
            refreshFormGroup: false
        };

    }

    showToast(msg, severity: string = 'success') {
        this.messageService.add({
            severity: 'info',
            summary: msg
        });
    }

    close() {
        this.uomDisplay = false;
        this.pageDisable = true;
        this.onClickAddDisplay = false;
        this.initInternalForm();
    }

    updateUserInfo(id, date, mode = 'create') {
        if (id !== null) {
            let _this = this;
            this.commonService.getJSONByURL(environment.base_url_new + '/api-iam/api/usermanagementservice/v1/getbyuserid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&userId=' + id).subscribe((next: any[]) => {
                if (next !== undefined && next !== null) {

                    if (mode === 'create') {
                        _this.createdBy = next['value'];
                        _this.createdDate = _this.datePipe.transform(date, environment.full_date_format);
                    } else {
                        _this.updatedBy = next['value'];
                        _this.updatedDate = _this.datePipe.transform(date, environment.full_date_format);
                    }
                }
            });
        }
    }

    //SubTable in the sideBar
    onNewRowAddComplete() {
        this.refreshAddNewRow = false;
        this.newRows = [];
    }

    onRefreshFromGroupComplete() {
        this.refreshFormGroup = false;
    }

    nameValid() {
        if (this.uomForm.get('name').valid) {
            return true;
        }
    }

    initInternalForm() {
        const internalObj = {};
        internalObj['name'] = ['', [Validators.required]];
        this.uomForm = this.formBuilder.group(internalObj);
    }

    createFormControl() {
        let _this = this;
        let obj = {};
        let formcontrolnamelist = [];
        if (this.grid.getColumn() !== null && this.grid.getColumn() !== undefined){
            this.grid.getColumn().forEach(function (data) {
                if (data.getVisibilityArea() !== 'N') {
                    if (data.getVisibilityArea() === 'F' || data.getVisibilityArea() === 'B' || data.getVisibilityArea() === 'H' ){
                        if (data.getColumnType() === 'D') {
                            obj[data.getField()] = [{
                                value: _this.commonService.getDateValue(data.getDefaultValue()),
                                disabled: _this.isDisabled(data)
                            }, data.getValidators()];
                            formcontrolnamelist.push(data.getField());
                        } else {
                            obj[data.getField()] = [{
                                value: _this.getDefaultValue(data.getDefaultValue()),
                                disabled: _this.isDisabled(data)
                            }, data.getValidators()];
                            formcontrolnamelist.push(data.getField());
                        }
                    }
                }
            });
        }
        obj['uuid'] = [{value:'', disabled:true},[]];
        this.uomForm = this.formBuilder.group(obj);
    }

    isDisabled(field: Tcolumn) {
        if (field.getFromExtra('meta')['disabled'] !== undefined) {
            return field.getFromExtra('meta')['disabled'];
        }
        return false;
    }

    getDefaultValue(value: any) {
        let defaultVa: any;
        if (value === undefined || value === null) {
            defaultVa = '';
        } else if (typeof (value) === 'object') {
            defaultVa = value.defaultValue;
        } else {
            defaultVa = value;
        }
        return defaultVa;
    }

}
