import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonService} from "../../../services/common.service";
import {environment} from "../../../../environments/environment";
import {KeyValue} from "../../../grid/key-value.model";
import {MasterService} from "../../../masters/services/MasterService";
import {MessageService} from "primeng";
import {HttpErrorResponse} from "@angular/common/http";
import {async} from "rxjs/internal/scheduler/async";

@Component({
  selector: 'app-inter-company-trade',
  templateUrl: './inter-company-trade.component.html',
  styleUrls: ['./inter-company-trade.component.css']
})
export class InterCompanyTradeComponent implements OnInit {

  @Input() visible:boolean = false;
  @Input() obligation:any;
  @Output() onComplete = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  intercompanyCurrency: any = 'USD';
  allFieldsFilled:boolean = false;
  company:any;
  transferPrice:any;
  searchCriteria:any[] = [];
  incoterm: string ='FOB';
  listOfIncoterm: any[] = [];
  listOfInterCompany: any[] = [];
  CreateApiFunction: Function;
  environment = require("../../../../environments/environment").environment;
  preConditionPassed: boolean = true;

  constructor(public commonService:CommonService,private masterCommonService: MasterService,private messageService: MessageService) { }

  ngOnInit(): void {
      let _this = this;
      let searchCriteria = [];
      searchCriteria.push({
          fieldName: 'tenantId',
          condition: 'equals',
          value: this.commonService.getFromStorage('tenantId')
      });
      searchCriteria.push({fieldName: 'name', condition: 'equals', value: this.obligation['company']});
      this.commonService.post(environment.base_url_new + '/api-bm/api/counterparty/v1/getcounterpartybycriteria?tenantId=' + this.commonService.getTenantId(),searchCriteria).subscribe((resp:any[]) => {
          _this.preConditionPassed = !(resp === null || resp === undefined || resp.length == 0);
          _this.continueInit();
      })
  }

  private continueInit() {
      let _this = this;
      let payload= [{
          tenantId: this.commonService.getFromStorage('tenantId'),
          serviceName: 'xceler_configservice',
          model:'globalindicatordetails',
          requiredField:['name'],
          filter: [{key: 'name', value: 'incoterm_code_ind'}]
      }]

      this.commonService.post(environment.base_url_new + '/api-bm/api/commodity/v1/load',payload).subscribe((next) => {
          _this.listOfIncoterm=this.masterCommonService.getListFromArray(next['incoterm_code_ind']);
      });
      _this.commonService.getJSONByURL(environment.base_url + '/api/counterparty/v1/getcounterpartybycounterpartytypecode?tenantId='+this.commonService.getTenantId()+'&counterpartytype='+['Intercompany']).subscribe((next: any) => {
          next.forEach(data => {
              this.listOfInterCompany.push(new KeyValue(data['name'],data['name']))
          })
      });

      _this.CreateApiFunction =  function (payload: any) {
          if(_this.preConditionPassed) {
              let obj = {
                  settlementData: {
                      settlementType: 'InterCompany',
                      interCompanyName: _this.company,
                      transferPrice: _this.transferPrice,
                      interCompanyCurrency: _this.intercompanyCurrency,
                      incoterm: _this.incoterm,
                      obligationId: _this.obligation['plannedObligationId'],
                      tradeId: _this.obligation['tradeId']
                  },
                  settlementType: 'InterCompany'
              }
              payload['url'] = environment.base_url + '/api/interCompanyTrades/v1/createInterCompanyTrades?tenantId=' + _this.commonService.getFromStorage('tenantId');
              payload['type'] = 'post';
              payload['payload'] = obj;
          } else {
              _this.messageService.add({
                  severity:'error',
                  summary:"",
                  detail:'Counterparty name does not Exist :'+_this.obligation['company']
              });
          }
          return payload;
      };
  }
  checkFields() {
    if (this.company && this.transferPrice && this.incoterm && this.intercompanyCurrency) {
      this.allFieldsFilled = true;
    } else {
      this.allFieldsFilled = false;
    }
  }

 closeCircle() {
       this.visible = false;
      this.onCancel.emit();
  }

    onSubmit(next: any) {
        this.messageService.add({
            severity:'success',
            detail:"",
            summary:'InterCompany Trade created successfully'
        });
        this.onComplete.emit(next);
    }

    showError(error:HttpErrorResponse) {
      let _this=this;
        _this.messageService.add({
            severity:'error',
            detail:_this.commonService.getHttpErrorMessage(error, "InterCompany Trade"),
            summary:'Failed to Create InterCompany Trades'
        });
    }

 continue(){

    let obj={
      settlementData:{
         settlementType: 'InterCompany',
         interCompanyName:this.company,
         transferPrice:this.transferPrice,
         interCompanyCurrency:this.intercompanyCurrency,
         incoterm:this.incoterm,
         obligationId:this.obligation['plannedObligationId'],
         tradeId:this.obligation['tradeId']
      },
      settlementType: 'InterCompany'
    }

     this.searchCriteria=[];
     this.searchCriteria.push({fieldName: 'tenantId',condition: 'equals',value: this.commonService.getFromStorage('tenantId')});
     this.searchCriteria.push({fieldName: 'name', condition: 'equals', value: this.obligation['company']});
     this.commonService.post(environment.base_url_new + '/api-bm/api/counterparty/v1/getcounterpartybycriteria?tenantId=' + this.commonService.getTenantId(),this.searchCriteria).subscribe((next: any) => {
         if(next.length==0){
            this.messageService.add({
                severity:'error',
                summary:"",
                detail:'Counterparty name does not Exist :'+this.obligation['company']
            });
             _this.visible = false;
        }
     });

         let _this = this;
         this.commonService.post(environment.base_url + '/api/interCompanyTrades/v1/createInterCompanyTrades?tenantId='+this.commonService.getFromStorage('tenantId'),obj ).subscribe((next: any) => {
             this.messageService.add({
                 severity: 'info',
                 summary: 'Plan Created Successfully',
                 detail: 'InterCompany Trades successfully created'
             })
             _this.visible = false;
             _this.onComplete.emit(next);
         },(error: HttpErrorResponse)=>{
             this.messageService.add({
                 severity:'error',
                 summary:this.commonService.getHttpErrorMessage(error, "InterCompany Trade"),
                 detail:'Failed to Create InterCompany Trades'
             });
         });
  }

}
