<div class="mainBox">
    <div class="bodyContent">
        <div [id]="'Attachment Details'" class="card-details m-0 mt-3">
            <div class="d-flex flex-column pl-4 pr-4 pb-2 pt-2" style="overflow: hidden">
                <div clsss="flex-grow-1">
                    <div class="d-flex align-self-start" style="background-color: #FFFFFF; ">
                        <ctrm-button icon="fas fa-download" [disabled]="selectedFiles.length !== 1"  (click)="openAttachment($event)"
                                     label="Download"></ctrm-button>
                    </div>
                    <app-ctrm-price-table (onAddNewRowComplete)="onNewRowAddComplete()"
                                          (onRefreshFormGroupComplete)="onRefreshFromGroupComplete()"
                                          [refreshNewRows]="refreshAddNewRow"
                                          [refreshFormGroup]="refreshFormGroup"
                                          [columns]="columns"
                                          [gridDisplay]="true"
                                          [autoCalculateHeight]="false"
                                          [clickableFields]="['attachmentName']"
                                          (onClickClickable)="onClickClickable($event)"
                                          [value]="attachmentvalue"
                                          [priceGridHeight]="'170px'"
                                          [showNewEntryIcon]="false"
                                          (onrowdelete)="onDelete($event)"
                                          [showReadOnly]="true"
                                          [showCheckbox]="true"
                                          (onSelectCheckbox)="onRowSelect($event)"
                                          [selectedRows]="selectedFiles"
                                          (onSelectionChange)="onSelectionChange($event)"
                                          [tableStyle]=" {backgroundColor:'#D9DBDE'}">
                    </app-ctrm-price-table>
                </div>
                <div class="uploadSection">
                    <p style="padding-bottom: 10px;font-weight: 600;">Upload Files</p>
                    <div class="mainUploadBox">
                        <div draggable="true" ngClass="{{dragAreaClass}}" (click)="file.click()">
                            <i style="font-size: 30px;padding-top: 20px;padding-bottom: 5px" class="fas fa-upload"></i>
                            <div class="row">
                                <div class="col-md-12 text-center">
                                    Drag and drop your file here or
                                    <a class="browse" href="javascript:void(0)">
                                        Browse
                                    </a>
                                    <input type="file"
                                           #file
                                           [multiple]="false"
                                           (change)="onFileChange($event)"
                                           style="display:none"/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <label pTooltip="{{fileName}}" tooltipZIndex="99999">{{fileName}}</label>
                        </div>
                        <div>
                            <div class="planScreenBtns" (click)="openUploadFile()">
                                <i style="color: #ffffff !important; padding: 5px" class="fas fa-file-import"></i>
                                <p class="exporttitle">Upload</p>
                            </div>
                        </div>
                        <div class="error" *ngIf="error">
                            Only one file at time allow
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
