import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Tcolumn} from '../../../grid/tcolumn.model';
import {PivotServiceService} from '../pivot-service.service';
import {Table} from 'primeng/table';
import {Grid} from '../../../grid/grid.model';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'ctrm-expandable-sub-grid',
  templateUrl: './ctrm-expandable-sub-grid.component.html',
  styleUrls: ['./ctrm-expandable-sub-grid.component.css']
})
export class CtrmExpandableSubGridComponent implements OnInit, OnChanges {
  @Input() expanded: boolean = false;
  @Output() onExpand = new EventEmitter();
  @Output() onCollapse = new EventEmitter();
  @Input() tableTitle: string = 'table 1';
  @Input() data: any[] = [];
  @Input() formulaColumn: any[] = [];
  @Input() grid: Grid;
  @Input() columns: Tcolumn[] = [];
  @Input() sorting: any;
  selectedRow: any;
  @Output() selectedRowList = new EventEmitter();
  @Output() onCopy = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() onSave = new EventEmitter();
  @ViewChild('dt1', {static: true}) table: Table;
  filteredValue: any[] = [];
  gridFrmGroup: FormGroup = new FormGroup({});
  private clonedRowDataValues: any[] = [];

  constructor(public ps: PivotServiceService) {
  }

  ngOnInit() {
    this.createFormGroup();
  }

  createFormGroup() {
    let _this = this;
    this.gridFrmGroup = new FormGroup({});
    this.data.forEach(function (data, index) {
      _this.columns.forEach(function (column) {
        const control: FormControl = new FormControl(data[column.getField()], column.getValidators());
        _this.gridFrmGroup.addControl(column.getField() + index, control);
      })
    });
  }

  toggleExpand() {
    this.expanded = !this.expanded;
    if (this.expanded) {
      this.onExpand.emit();
    } else {
      this.onCollapse.emit();
    }
  }

  outSelectedRow() {
    this.selectedRowList.emit(this.selectedRow);
  }

  changeRoute(col: Tcolumn, rowData) {
  }

  onRowEditInit(data) {
    let key: string, value: Tcolumn;
    for ([key, value] of this.grid.getColumn()) {
      if (value.getColumnType() == 'D') {
        data[key] = new Date(data[key]);
      }
    }
    this.clonedRowDataValues[data[this.grid.getPrimaryKey()]] = {...data};

  }

  getColumnValue(column: Tcolumn, colValue: any) {
    if (column.getColumnType() === 'L') {
      let listOfValues = column.getListOfValues();
      for (let i = 0; i < listOfValues.length; i++) {
        if (listOfValues[i].getValue() == colValue) {
          return listOfValues[i].getLabel();
        }
      }
    } else {
      return colValue;
    }
  }

  getFormula(columnName) {
    let formula = 'NONE';
    this.formulaColumn.forEach(function (object) {
      if (object['column'] === columnName) {
        formula = object['formula'];
      }
    });
    return formula;
  }

  sort() {
    this.table.multiSortMeta = this.sorting;
    this.table.sortMultiple();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sorting']) {
      this.sort();
    }
    // if(changes['data']){
    //   this.createFormGroup();
    // }
  }

  changeField(index: number, field: string, value: any) {
    this.data[index][field] = value;
  }

  onRowCopy(row, b: boolean) {
    this.onCopy.emit({row: row, refresh: b, table: this.table});
  }

  onRowSave(row, ri) {
    this.onSave.emit({row: row, index: ri, form: this.gridFrmGroup});
  }

  onRowEditCancel(row, index) {
    let key: string, value: Tcolumn;
    this.data[index] = this.clonedRowDataValues[row[this.grid.getPrimaryKey()]];
    for (let m = 0; m < this.columns.length; m++) {
      this.gridFrmGroup.get(this.columns[m].getField() + index).patchValue(this.data[index][this.columns[m].getField()]);
    }
    delete this.clonedRowDataValues[this.grid.getPrimaryKey()];
  }

  onRowDelete(row, ri, b: boolean) {
    this.onDelete.emit({row: row, ri: ri, refresh: b, table: this.table});
  }


}
