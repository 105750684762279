
<p class="operationdashboard"> <img class="imageNotifyClass" src="../../../assets/icons/bell.svg" alt="">Switch To: Operation dashboard - <button class="btnNav success" (click)="onclickRouter()">Operations Dashboard</button></p>

<ctrm-grid (onClickUniqueKey)="onUniqueClick($event)" (onRefreshComplete)="onRefreshComplete($event)" [refreshGrid]="refreshGrid"
           [breadcrumbMapKey]="commonService.OPERATION_MAP_KEY" [currentRoute]="'/tradeObligation'" [extraViewTemplate]="obligationInfo"
           [grid]="grid" [noDataRoute]="'/trade'" [refreshFilter]="refreshFilter" [gridFilterDropdownList]="gridFilters"
           [extraButtons]="grid.getExtraButtons()"
           [selectedRow]="selectedRows"
           [recalculateGridHeight]="gridHeightRecalculate"
           (onHeightCalculationDone)="onHeightRefreshDone()"
           (onContextMenuItemClick)="onClickMenuItem($event)"
           [routeToLable]="'Create new trade'" [showAddButtonInNoData]="false"
           (onDropdownFilterValueChange)="onFilterChange($event)"
           [showFilterChips]="false"></ctrm-grid>
<app-trade-info (onClose)="onClose($event)" *ngIf="display" [display]="true" [rowData]="rowData"
                [tradeId]="tradeId"></app-trade-info>
<ng-template #obligationInfo>
  <div class="sectionCard borderRadius">
    <div class="header">
      Obligations Summary
      <div *ngIf="addedFilter.length>0" class="ui-g filterBox">
        <div *ngFor="let type of addedFilter" class="filterChip">
          <div class="filterText">{{getTypeLabel(type)}}</div>
          <mat-icon (click)="onCardUnselect(type)" class="closeIcon">clear</mat-icon>
        </div>
        <div (click)="clearAllFilter()" class="clearAll">Clear all</div>
      </div>
    </div>
    <div class="headerControl">
      <div class="tradestatus">
        <span class="confirm">Confirm</span>
        <span class="planned">Planned</span>
        <span class="priced">Price Allocated</span>
        <span class="deliveryStart">Delivery Started</span>
        <span class="invoiced">Invoice Initiated</span>
      </div>
      <div class="calenderCont">
        <div class="calender" (click)="toggleDateRange()">
            <i class="fa fa-calendar" style="margin-left: 5px;align-self: center;font-size: 14px;margin-left: 6px;user-select: none"></i>
            <p>{{getPresetTitle()}}</p>
        </div>
        <obligation-date-range *ngIf="showDateRange"
                               [preDefinedRanges]="presetList"
                               [selectedPresetObject]="selectedPreset"
                               [selectedPreset]="selectedPreset['title']"
                               (onCancel)="onCancel($event)"
                               (onPresetSelected)="onPresetChange($event)">
        </obligation-date-range>
      </div>
    </div>

    <div class="content">
      <div class="cardlist">
        <app-dashboard-card
                [cardId]="'CONFIRMED'"
                style="margin-right: 0.5%;width: 19.6%;"
                [cardHeader]="'Confirmed Trade'"
                [cardBorderColor]="'#0495ea'"
                [isSelected]="addedFilter.includes('CONFIRMED')"
                (onCardSelected)="onCardSelect($event)"
                (onCardUnselected)="onCardUnselect($event)"
                [count1]="getCountValue('confirmed')"
                [count2]="getCountValue('confirmed','sell')">
        </app-dashboard-card>
        <app-dashboard-card
                style="margin-right: 0.5%;width: 19.6%;"
                [cardId]="'PLANNED'"
                [isSelected]="addedFilter.includes('PLANNED')"
                [cardHeader]="'Planned Trade'"
                (onCardSelected)="onCardSelect($event)"
                (onCardUnselected)="onCardUnselect($event)"
                [cardBorderColor]="'#fd5e7e'"
                [count1]="getCountValue('planned')"
                [count2]="getCountValue('planned','sell')"></app-dashboard-card>
        <app-dashboard-card
                style="margin-right: 0.5%;width: 19.6%;"
                [cardId]="'PRICED'"
                [isSelected]="addedFilter.includes('PRICED')"
                [cardHeader]="'Priced Allocated'"
                (onCardSelected)="onCardSelect($event)"
                (onCardUnselected)="onCardUnselect($event)"
                [cardBorderColor]="'#f19202'"
                [count1]="getCountValue('priceAllocated')"
                [count2]="getCountValue('priceAllocated','sell')"></app-dashboard-card>
        <app-dashboard-card
                style="margin-right: 0.5%;width: 19.6%;"
                [cardId]="'DELIVERYSTARTED'"
                [isSelected]="addedFilter.includes('DELIVERYSTARTED')"
                [cardHeader]="'Delivery Started'"
                [cardBorderColor]="'#63c630'"
                (onCardSelected)="onCardSelect($event)"
                (onCardUnselected)="onCardUnselect($event)"
                [count1]="getCountValue('deliveryStarted')"
                [count2]="getCountValue('deliveryStarted','sell')"></app-dashboard-card>
        <app-dashboard-card
                [cardId]="'INVOICED'"
                [isSelected]="addedFilter.includes('INVOICED')"
                style="width: 19.6%;"
                [cardHeader]="'Invoice Initiated'"
                [cardBorderColor]="'#15104e'"
                (onCardSelected)="onCardSelect($event)"
                (onCardUnselected)="onCardUnselect($event)"
                [count1]="getCountValue('invoiceInitiated')"
                [count2]="getCountValue('invoiceInitiated','sell')"></app-dashboard-card>
      </div>
      <div>
        <div class="showGraphClass" (click)="toggleGraph()">
          <i [ngClass]="this.showGraph?'fas fa-chevron-down':'fas fa-chevron-right'"></i>
        </div>
        <div *ngIf="showGraph" id="chart">
          <apx-chart
                  *ngIf="!graphLoading && !graphError"
                  [series]="chartOptions.series"
                  [chart]="chartOptions.chart"
                  [xaxis]="chartOptions.xaxis"
                  [yaxis]="chartOptions.yaxis"
                  [dataLabels]="chartOptions.dataLabels"
                  [grid]="chartOptions.grid"
          ></apx-chart>
          <div *ngIf="graphLoading && !graphError" class="graphDiv">
            <img class="loadingIconClass" src="../../../assets/images/doubleRingLoading.gif" alt="">
          </div>
          <div *ngIf="!graphLoading && graphError"  class="graphDiv">
            <p class="message">No data available</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<app-generate-proforma-invoice
        [showToastMessage]="false"
        *ngIf="displayInvoiceForm"
        [invoiceObject]="invoiceProFormaObject"
        (onDraftClose)="onDraftClose()"
        [obligationObject]="proFormaInvoiceRow"
        (onGenerateDone)="onGenerateDone()"
        [tradeId]="proFormaInvoiceRow['tradeId']"
        [sell]="proFormaInvoiceRow['tradeTransactionType'] === 'SELL'"></app-generate-proforma-invoice>
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body">
</p-toast>
