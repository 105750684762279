<div class="sectionCardTop">
  <div class="breadcrumbPane">
    <ctrm-breadcrumb [collapsabel]="false"
                     [items]="breadCrumbService.getBreadCrumb()"
                     [showIcon]="false"></ctrm-breadcrumb>
  </div>
  <div class="actionPane">
    <div class="screenDetails">
      <img [src]="'../../../assets/images/svg/priceFixing.svg'"/>
      <p>Stock Planning</p>
    </div>
    <div class="autocomplete-box">
      <input (input)="getFilterValue($event)" placeholder="Search" type="text">
      <mat-icon style="color:#cbcbcb">search</mat-icon>
    </div>

    <div *ngIf="tabValue == 'planned'" class="rightInfo">
      Total {{planData.length}} Plans
    </div>
  </div>
</div>

<div class="sectionCardTop" style="max-height: 84% !important;">
  <div class="selectButtonClass">
    <p-selectButton (onOptionClick)="onTabChange($event)" [(ngModel)]="tabValue"
                    [options]="tabOptions"></p-selectButton>
  </div>

  <div *ngIf="tabValue !== 'storageInventory'" style="margin: 10px;">
    <ctrm-button (onClick)="refreshTab(tabValue)" icon="fas fa-redo" label="Refresh"></ctrm-button>
  </div>

  <!--BUILD STOCK-->
  <!--Tabs on the top of the grid-->
  <div *ngIf="tabValue === 'build'">
    <!--Buy Trade and Storage Grid Section-->
    <div #inventory [ngStyle]="{minHeight: inventoryHeight}" class="inventoryContainer">
      <!--BUY Trade-->
      <ctrm-planning-grid (onUniqueKeyClick)="openTradeInfo($event,'right')" (rowSelect)="selectBuyRows($event)"
                          (selectedRowsValue)="onRowSelect($event,'buyTrade')"
                          (total)="onTotalChange($event,'buyTrade')" [balanceCalculationFunction]="balanceFunc"
                          [balanceFieldName]="'balanceQuantity'" [cols]="buyCols"
                          [filterValue]="globalFilterValue"
                          [gridPosition]="'left'" [key]="'tradeObligationId'" [lastField]="'tradeQuantity'"
                          [mainQuantityField]="'tradeQuantity'" [matchBalanceColumnsWidth]="130" [matchFieldName]="'matchQuantity'"
                          [noDataLabel]="'No Confirmed Buy Trade available to Plan'" [noDataRoute]="'/trade'" [primaryKey]="'tradeObligationId'"
                          [reset]="reset"
                          [routeToLable]="'Create Buy Trade'" [value]="buyValue"
                          [heading]="'Buy Obligations'" [totalHeading]="'Trade obligation : '"
                          [totalMatchHeading]="'Total Match Quantity'"
                          [totalMatch]="buyTotal"
                          [negativeValueError]="'Match Amount is greater than Buy Balance Quantity'"
                          [totalElements]="totalElementsBuy" [totalPages]="totalPagesBuy" [pageSize]="pageSizeBuy"
                          (onChangePage)="onChangePageBuy($event)" [mode]="'Left'"
                          [filterOptionsList]="filterListBuy"
                          (onFilterApply)="filterForBuy($event)"
                          [refreshGrid]="onRefresh"
                          [refreshevent]="onRefreshScreen.asObservable()"
                          (clearOnFilter)="clearFilter('Buy')"
                          style="width: 49.9%">
      </ctrm-planning-grid>
      <div class="dividerLine"></div>
      <!--    Storage Details-->
      <ctrm-planning-grid (onUniqueKeyClick)="openInventoryInfo($event,'left')" (rowSelect)="selectedStorageRows($event)"
                          (selectedRowsValue)="onRowSelect($event,'inventory')"
                          (total)="onTotalChange($event,'inventory')" [balanceCalculationFunction]="balanceRightFunc"
                          [balanceFieldName]="'balanceCapacity'" [checkedSingleRow]=true
                          [cols]="inventoryCols" [filterValue]="globalFilterValue" [gridPosition]="'right'"
                          [key]="'storageId'"
                          [mainQuantityField]="'totalStorageCapacity'" [matchBalanceColumnsWidth]="130"
                          [matchFieldName]="'matchCapacity'"
                          [matchLimitRequired]="true" [matchLimit]="buyTotal" [noDataLabel]="'No Storage Data available to Plan'"
                          [noDataRoute]="'/storageInventory'"
                          [primaryKey]="'storageId'" [reset]="reset"
                          [routeToLable]="'Create Storage Inventory'" [value]="storageValue"
                          [heading]="'Storage Inventory'" [totalHeading]="'Total Storage Inventory : '"
                          [totalMatchHeading]="'Total Match Capacity'"
                          [totalMatch]="storageTotal"
                          [totalElements]="totalElementsInventoryBuild" [totalPages]="totalPagesInventoryBuild" [pageSize]="pageSizeInventoryBuild"
                          (onChangePage)="onChangePageInventorybuild($event)" [mode]="'Left'"
                          [filterOptionsList]="filterListInventoryBuild"
                          (onFilterApply)="filterForInventoryBuild($event)"
                          [refreshevent]="onRefreshScreen.asObservable()"
                          [refreshGrid]="onRefresh"
                          (onRefreshComplete)="onRefreshInventory = false"
                          (clearOnFilter)="clearFilter('Build')"
                          style="width: 49.9%">
      </ctrm-planning-grid>


    </div>

    <div class="matchPlanButtonDiv">
      <xceler-progress-button
              [callApiObjectFunction]="buildApiFunc"
              [updateParamsAndClick]="params"
              (onError)="onError($event)"
              [buttonStyle]="{margin: '0', width: '210px', minHeight: '35px', padding: '8px 8px 8px 8px'}"
              (onLoadDone)="onBuildDone($event)"
              [disabled]="(buyTotal ===0 || storageTotal === 0 || buyTotal !== storageTotal)"
              [buttonIdleTitle]="'Build Stock'"
              [buttonLoadingTitle]="'Build Stock'">
      </xceler-progress-button>
    </div>
  </div>

  <!--Draw Stock Planning Screen-->
  <div *ngIf="tabValue === 'draw'">
    <!--Sell Trade and Stocked Planning Grid Section-->
    <div #Draw [ngStyle]="{minHeight: inventoryHeight}" class="inventoryContainer">
      <!--Stocked Details-->
      <ctrm-planning-grid (onUniqueKeyClick)="openInventoryInfo($event,'right')" (rowSelect)="selectedStorageRows($event)"
                          (selectedRowsValue)="onRowSelectStock($event,'stocked')"
                          (total)="onTotal($event,'stocked')" [balanceCalculationFunction]="balanceStockFunc"
                          [balanceFieldName]="'balanceCapacity'" [checkedSingleRow]=true
                          [cols]="stockedCols" [filterValue]="globalFilterValue" [gridPosition]="'left'"
                          [key]="'storageId'"
                          [lastField]="'quantityUom'" [mainQuantityField]="'totalStorageCapacity'" [matchBalanceColumnsWidth]="130"
                          [matchFieldName]="'matchCapacity'" [matchLimitRequired]="true" [matchLimit]="sellTotal"
                          [noDataLabel]="'No Stock Data available to Plan'"
                          [noDataRoute]="'/stockPlanning'" [primaryKey]="'storageId'"
                          [reset]="reset"
                          [routeToLable]="'Build Stock'" [value]="stockValue"
                          [heading]="'Storage Inventory'" [totalHeading]="'Total Storage Inventory : '"
                          [totalMatchHeading]="'Total Match Quantity'"
                          [totalMatch]="stockTotal"
                          [totalElements]="totalElementsInventoryStock" [totalPages]="totalPagesInventoryStock" [pageSize]="pageSizeInventoryStock"
                          (onChangePage)="onChangePageInventorystock($event)" [mode]="'Left'"
                          [filterOptionsList]="filterListInventoryStock"
                          (onFilterApply)="filterForInventoryStock($event)"
                          [refreshevent]="onRefreshScreen.asObservable()"
                          (clearOnFilter)="clearFilter('Stock')"
                          style="width: 49.9%">
      </ctrm-planning-grid>
      <div class="dividerLine"></div>
      <!--    SELL Trade-->
      <ctrm-planning-grid (onUniqueKeyClick)="openTradeInfo($event,'left')" (rowSelect)="selectSellRows($event)"
                          (selectedRowsValue)="onRowSelectStock($event,'sellTrade')"
                          (total)="onTotal($event,'sellTrade')" [balanceCalculationFunction]="balanceSellFunc"
                          [balanceFieldName]="'balanceQuantity'" [cols]="sellCols"
                          [filterValue]="globalFilterValue" [gridPosition]="'right'" [key]="'tradeObligationId'"
                          [mainQuantityField]="'tradeQuantity'"
                          [matchBalanceColumnsWidth]="130" [matchFieldName]="'matchQuantity'" [noDataLabel]="'No Confirmed Sell Trade available to Plan'"
                          [noDataRoute]="'/trade'" [primaryKey]="'tradeObligationId'" [reset]="reset"
                          [routeToLable]="'Create Sell Trade'"
                          [value]="sellValue"
                          [heading]="'Sell Obligations'" [totalHeading]="'Trade Obligation : '"
                          [totalMatchHeading]="'Total Match Quantity'"
                          [totalMatch]="sellTotal"
                          [negativeValueError]="'Match Amount is greater than Buy Balance Quantity'"
                          [totalElements]="totalElementsSell" [totalPages]="totalPagesSell" [pageSize]="pageSizeSell"
                          (onChangePage)="onChangePageSell($event)" [mode]="'Left'"
                          [filterOptionsList]="filterListSell"
                          (onFilterApply)="filterForSell($event)"
                          [refreshevent]="onRefreshScreen.asObservable()"
                          (clearOnFilter)="clearFilter('Sell')"
                          style="width: 49.9%">
      </ctrm-planning-grid>

    </div>

    <div class="matchPlanButtonDiv">
      <xceler-progress-button
              [callApiObjectFunction]="stockApiFunc"
              (onError)="onError($event)"
              [updateParamsAndClick]="params"
              (onLoadDone)="onStockDone($event)"
              [disabled]="(stockTotal === 0 || sellTotal === 0 || stockTotal !== sellTotal)"
              [buttonIdleTitle]="'Draw Stock'"
              [buttonLoadingTitle]="'Draw Stock'">
      </xceler-progress-button>
<!--      <button class="planScreenBtns"-->
<!--              [disabled]="stockTotal == 0"-->
<!--              (click)="allocate()">Allocate Vessel</button>-->
    </div>
  </div>

  <!--Planned table-->
  <div *ngIf="tabValue === 'storageInventory'">
    <!--Status of Stock-->
    <div class="planStatsContainer">
      <!--    Available-->
      <div
        (click)="onClicklabel('Available', stockPlanningTable)">
        <div class="statsContainer">
          <p class="statTitle">Available</p>
          <p class="statNumber">{{available}}</p>

        </div>
      </div>
      <!--    Partially Planned-->
      <div
        (click)="onClicklabel('Partially_Planned',stockPlanningTable)">
        <div class="statsContainer">
          <p class="statTitle">Partially Planned</p>
          <p class="statNumber">{{partiallyPlanned}}</p>
        </div>
      </div>
      <!--    Fully Planned-->
      <div
        (click)="onClicklabel('Full',stockPlanningTable)">
        <div class="statsContainer">
          <p class="statTitle">Fully Planned</p>
          <p class="statNumber">{{fullyPlanned}}</p>

        </div>
      </div>
    </div>
    <div>
      <div class="toolbar">
        <div style="display:inline-flex">
          <ctrm-button (onClick)="planRefresh()" icon="fas fa-redo" label="Refresh"></ctrm-button>
          <!--****Status of the Filter******-->
          <div *ngIf="addedFilter.length>0" class="ui-g filterBox">
            <div *ngFor="let type of addedFilter" class="filterChip">
              <div class="filterText">{{getTypeLabel(type)}}</div>
              <mat-icon (click)="onClickRemove(stockPlanningTable)" class="closeIcon">clear</mat-icon>
            </div>
          </div>

          <!--        <ctrm-button (onClick)="onRowDelete(selectedPlans)" [disabled]="selectedPlans.length === 0 || selectedPlans.length > 1" [label]="'Delete Stock'" icon="fas fa-trash" ></ctrm-button>-->
          <div class="float-right">
            <ctrm-button [disabled]="selectedPlans.length === 0 || selectedPlans.length > 1" icon="fas fa-file-export"
                         label="Export"></ctrm-button>
            <ctrm-button [disabled]="selectedPlans.length === 0 || selectedPlans.length > 1" icon="fas fa-print"
                         label="Print"></ctrm-button>
            <ctrm-button icon="fas fa-sliders-h" label="Preferences"></ctrm-button>
          </div>
        </div>
      </div>

      <div class="tableClass">

        <p-table #stockPlanningTable
                 (inViewportAction)="calculatePlanHeight(stockPlanningTable)"
                 (onRowExpand)="fetchChildData($event)"
                 [(selection)]="selectedPlans"
                 [columns]="planInventoryCols" [responsive]="true"
                 [style]="{width: '100%', maxHeight: planningGridHeight}" [style]="{maxHeight:planningGridHeight,minHeight:planningGridHeight}"
                 [value]="planData"
                 autoLayout="true"
                 class="style-4 table-responsive" columnResizeMode="fit"
                 dataKey="storageId"
                 editMode="row" inViewport  selectionMode="single" sortMode="multiple">

          <ng-template let-columns pTemplate="header">
            <tr>
              <th class="stk" style="width: 3em">
                <p
                  style="margin:0;background: #D9DBDE;padding: 5px;padding-top: 11px; padding-bottom: 10px; text-align: center;">
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </p>
              </th>
              <th *ngFor="let col of columns;index as i" class="stk">
                <div style="background-color: #D9DBDE; padding: 5px;">
                  <p [ngClass]="getClassForHeading(i)">{{col.header}}</p>
                </div>
              </th>
            </tr>
          </ng-template>

          <ng-template let-columns="columns" let-expanded="expanded" let-ri="rowIndex" let-rowData pTemplate="body">
            <tr [pSelectableRow]="rowData">
              <td class="customRow" style="width:3em;">
                <p class="plannedGridChkBox">
                  <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                </p>
              </td>

              <td *ngFor="let col of columns; index as i" class="customRow">
                <p *ngIf="col.field !== 'plannedMatching' && col.field !== 'storageId'" class="plannedGrid">
                  {{commonService.getRowValue(rowData, col)}}
                </p>


                <p *ngIf="col.field === 'plannedMatching'" class="matchAlgoStyle">
                  {{commonService.getRowValue(rowData, col)}}
                </p>
                <div *ngIf="col.field === 'storageId'" style="display: flex">
                  <p style="margin:0;margin-left: 5px">
                    <a [pRowToggler]="rowData" href="#">
                      <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" class="togglerIcon"></i>
                    </a>
                  </p>
                  <p class="plannedGrid" style="padding-top: 2px">{{commonService.getRowValue(rowData, col)}}</p>
                </div>

              </td>
            </tr>
          </ng-template>
          <ng-template let-columns="columns" let-rowData pTemplate="rowexpansion">
            <tr>
              <td [colSpan]="columns.length + 1">
                <div class="planningInfoContainer">
                  <app-planning-info (onClickClickable)="onPrimaryClickInfo($event)"
                                     (onClickUniqueKey)="onUniqueClick($event)" [clickableFields]="['storageId','tradeId']"
                                     [columns]="expandPlannedCols"
                                     [showChkBox]="true"
                                     (onSelectedRows)="openChildSelected($event)"
                                     [value]="rowData['stock']"></app-planning-info>

                </div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr *ngIf="(planData === undefined ||planData === null || planData.length === 0)">
              <td [colSpan]="planInventoryCols.length + 2">
                <div class="placeHolder">
                  <div (inViewportAction)="calculateLeft()" [style]="{left:noDataBoxLeft}" class="noDataBox" inViewport>
                    <img [src]="commonService.getIconPath('emptyStateIcon')" class="iconClass"/>
                    <div class="noPlans">{{loadingMessage}}</div>
                    <div *ngIf="!isRefreshing">
                      <button (click)="goTo()" class="planScreenBtns" style="margin-top: 0px !important;">Go to Storage
                        Inventory
                      </button>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <p-paginator *ngIf="getPagination()" [rows]="20" [totalRecords]="totalElementsPlan" (onPageChange)="onChangePagePlan($event)"></p-paginator>
        <div *ngIf="planData.length == 0 || planData.length == null" style="text-align: center;">
        </div>

      </div>
    </div>
  </div>
</div>

<app-trade-info (onClose)="onClose($event)" *ngIf="display"  [detailsOf]="'trade'" [display]="display"
                [rowData]="oblRow" [sidebarPosition]="tradeInfoPos" [tradeId]="tradeId"></app-trade-info>

<app-storage-info (onClose)="onInventoryClose($event)" *ngIf="inventorydisplay" [display]="true"
                  [rowData]="inventoryRow" [sidebarPosition]="inventoryInfoPos" [storageId]="storageUuid">

</app-storage-info>

<p-dialog (onHide)="closeMatchDialog()" [(visible)]="matchDisplay" [draggable]="false" [modal]="true"
          [resizable]="false" [style]="{width: '35vw'}">
  <p-header>
    <div style="display: flex; padding-left: 16px">
      <i class="far fa-check-square headerIconStyle"></i>
      <p class="matchHeader">Stock Planning</p>
    </div>

  </p-header>
  <div class="infoContainer">
    <!--    Error Section-->
    <div *ngIf="errorMessage.length > 0">
      <div class="errorClass">
        <i class="fas fa-times-circle"></i>
        <p>ERROR</p>
      </div>
      <p *ngFor="let msg of errorMessage" class="infoMessageClass">{{msg}}</p>
    </div>

    <!--    select availaible algo-->
    <p *ngIf="errorMessage.length == 0" class="infoMessageClass">Match Successful.</p>
  </div>


  <p-footer>
    <div>
      <button (click)="closeMatchDialog()" class="cancelBtn">Cancel</button>
      <button (click)="continuePlanning()" [class.planScreenBtnsDisabled]="getContPlanValidation()"
              [disabled]="getContPlanValidation()" class="planScreenBtns">Continue
        Planning
      </button>
    </div>

  </p-footer>
</p-dialog>

<p-sidebar [(visible)]="displaySideBar" [baseZIndex]="25" [modal]="false" [showCloseIcon]="false"
           [styleClass]="'bottomPane'" position="bottom">
  <div class="bottomSidebarContainer">
    <div class="leftSection">
      <i (click)="cancel()" class="material-icons closable">clear</i>
    </div>
    <div class="rightSection">
      <button (click)="cancel()" class="cancelBtn">Cancel</button>
      <button (click)="onMoveToInventory()" class="priceBtn">Move to Inventory</button>
      <button [disabled]="getDisable('allocate')" (click)="allocate(false)" class="priceBtn">Allocate Transport</button>
      <button [disabled]="getDisable('de-allocate')" (click)="onDeAllocateTransport()" class="priceBtn">De-allocate Transport</button>
      <button [disabled]="getDisable('de-allocate')" (click)="goToVessel()" class="priceBtn">Go To Vessel</button>
    </div>
  </div>
</p-sidebar>

<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>
<warning-box (onClose)="onCloseWarningBox()" (onContinue)="onContinue($event)" [errorMessages]="errorMessagesList"
             [heading]="warningHeading" [visible]="displayWarningBox"
             [warningMessages]="warningMessageList"></warning-box>
<allocate-vessel
        *ngIf="visibleVesselAllocation"
        [planType]="'StockPlan'"
        (onCancel)="onVesselAllocationCancel()"
        [planId]="selectedChildRowData['planningId']"
        (onDone)="onAllocationDone()"
        [packageType]="selectedChildRowData['packageType']"
        [visible]="visibleVesselAllocation"></allocate-vessel>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>