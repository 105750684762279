<div class="back">
  <div class="style-4"
       style="overflow-y: auto;overflow-x:auto;border: 1px solid #adadad;;background-color: #ffffff;border-radius: 6px">
    <p-table #dt1
             [columns]="columns" [reorderableColumns]="true" [responsive]="true"
             [style]="{minHeight:'65vh',maxHeight:'65vh',borderRadius:'6px'}"
             [value]="categoryList" autoLayout="true"
             selectionMode="multiple" sortMode="multiple">
      <!--      <ng-template let-columns pTemplate="header" >-->

      <!--          <tr class="theadS" >-->
      <!--            <th *ngFor="let column of columns;index as i" class="columns" style="background-color: #637484;color: white;padding: 0; ">-->
      <!--              <p style="margin: 0;padding: 8px;color: #ffffff;border-right: 0.6px solid #959595;font-size: 12px">{{column.getHeader()}}</p>-->
      <!--            </th>-->
      <!--          </tr>-->

      <!--      </ng-template>-->
      <ng-template let-category pTemplate="body">
        <tr>
          <td [colSpan]="columns.length" style="padding: 0;">
            <ctrm-expandable-sub-grid (onCollapse)="onCollapse()" (onExpand)="onExpand()"
                                      [columns]="columns" [data]="getDataForCategory(category)"
                                      [expanded]="expandall" [formulaColumn]="config.formulaColumns"
                                      [grid]="grid"
                                      [tableTitle]="categoryTitle +' - '+getValue(category)"></ctrm-expandable-sub-grid>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
