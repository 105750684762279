import {Component, OnInit} from '@angular/core';
import {Tcolumn} from 'src/app/grid/tcolumn.model';
import {Grid} from 'src/app/grid/grid.model';
import {KeyValue} from 'src/app/grid/key-value.model';
import {Validators} from '@angular/forms';
import {CommonService} from '../../services/common.service';
import {Action} from '../../grid/actions.model';
import {environment} from '../../../environments/environment';
import {MasterService} from '../services/MasterService';
import {ChildAction} from '../../grid/ChildAction.model';
import {TreeNode} from 'primeng';
import {Tab} from '../../tab-grid/Tab.model';
import {repos} from '../../services/common/entities';
import {defaultValue} from "../../grid/defaultValue.model";

@Component({
    selector: 'profitcenter',
    templateUrl: './profitCenter.component.html',
    styleUrls: ['./profitCenter.component.css']
})
export class ProfitcentreComponent implements OnInit {

    columns: Map<string, Tcolumn>;
    grid: Grid;
    rootName: string;
    modalStateHierarchy: boolean = false;
    hierarchyData: TreeNode[];
    tabs: Tab[] = [];
    currentHref: string = '';
    selectedRowData: any;
    loadDataList: any[];

    constructor(public commonService: CommonService, private masterCommonService: MasterService) {
    }

    ngOnInit() {
        this.commonService.getLoadData('profitcenter', ['config', 'config'], ['unitofmeasurement', 'commodity']).subscribe((next: any) => {
            this.loadDataList = next;
            this.getColumns();
            this.grid = new Grid(this.columns, true, true, true, true, 'name', '/api/profitcenter/v1/getall?tenantId=' + this.commonService.getFromStorage('tenantId'), '', 'Profit Centre', '', '', undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true);
            this.grid.setSaveUrl(environment.base_url + '/api/profitcenter/v1/save');
            this.grid.setUpdateUrl(environment.base_url + '/api/profitcenter/v1/update');
            this.grid.hrefUrl = environment.base_url + '/api/profitcenter/v1/getbyuuid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&uuid=';
            this.grid.iconName = 'profitCenter';
            this.grid.importUrl = environment.base_url + '/api/profitcenter/v1/import';
            this.grid.setTabs(this.tabs);
        });
    }

    public getColumns() {

        let listPFParentFunc = function (value: any[] = []) {
            let list:any[] = value[0];
            if(list !== null && list !== undefined && list.length > 0) {
                list.splice(0,1);
            }
            list.splice(0,0,new KeyValue('None','none'));
            return list;
        };
        let actionforParentLoc: Action = new Action(environment.base_url + '/api/profitcenter/v1/getparentlist?tenantId=' + this.commonService.getFromStorage('tenantId') + '&name=', 'name', 'name');
        actionforParentLoc.setCovertToListRequired(true);
        actionforParentLoc.addFieldToUpdate("parent", undefined, listPFParentFunc, ['L'], undefined, true, 'listvalues', false, false);
        this.columns = new Map();
        this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
        this.columns.set('code', new Tcolumn('code', 'Profit Centre Code', 'T', 1, false, undefined, true,new defaultValue('',undefined,['-','_'],undefined,false,true,false,undefined,undefined,true), 'B', [Validators.required, Validators.maxLength(8)], undefined, undefined, undefined, undefined, [], undefined, undefined, true));
        this.columns.set('name', new Tcolumn('name', 'Profit Centre Name', 'T', 2, false, undefined, true, '', 'B', [Validators.required], 'text', 1, undefined, undefined, undefined, actionforParentLoc));
        this.columns.set('description', new Tcolumn('description', 'Profit Centre Description', 'TA', 3, true, undefined, true, '', 'B', [Validators.maxLength(200)]));
        this.columns.set('parent', new Tcolumn('parent', 'Parent Profit Centre', 'L', 4, true, this.getParent(), true, 'none', 'B', [Validators.required]));
        this.columns.set('reportingPnlCurrency', new Tcolumn('reportingPnlCurrency', 'Reporting Currency', 'L', 6, true, this.commonService.getCurrencyList(), true, '', 'B', [ ]));
        this.columns.set('commodity', new Tcolumn('commodity', 'Commodity', 'ML', 7, true, this.masterCommonService.getListFromArray(this.loadDataList['commodity'],false,false), true, '', 'B', []));
        this.columns.set('isTradeable', new Tcolumn('isTradeable', 'Tradeable', 'B', 8, true, this.masterCommonService.getYesNo(), true, true, 'B', [Validators.required]));
        this.columns.set('status', new Tcolumn('status', 'Status', 'B', 9, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));

    }

    private getParent() {
        let parentList = [];
        parentList.push(new KeyValue('None', 'none'));
        return parentList;
    }
    private getCommodity() {
        let _this = this;

    }

    openHeirarcy(selectedRow: any) {
        this.selectedRowData = selectedRow[0];
        this.modalStateHierarchy = true;
    }

    closeBtn() {
        this.modalStateHierarchy = false;
    }


    getEntityName() {
        return 'xceler_configservice_profitcenter';
    }
}





