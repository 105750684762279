import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CommonModule, DecimalPipe} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CompanyComponent} from './masters/company/company.component';
import {ChartOfAccountComponent} from './masters/chartofaccount/chartofaccount.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {DashCardComponent} from './dash-card/dash-card.component';
import {DashSubCardComponent} from './dash-sub-card/dash-sub-card.component';
import {WidgetsComponent} from './widgets/widgets.component';
import {SystemSettingsComponent} from './system-settings/system-settings.component';
import {ChartsModule} from 'ng2-charts';
import {HttpModule} from '@angular/http';
import {IncotermComponent} from './masters/incoterm/incoterm.component';
import {TableModule} from 'primeng/table';
import {MenubarModule} from 'primeng/menubar';
import {PanelModule} from 'primeng/panel';
import {TreeTableModule} from 'primeng/treetable';
import {InputTextModule} from 'primeng/inputtext';
import {DropdownModule} from 'primeng/dropdown';
import {MenuModule} from 'primeng/menu';
import {PanelMenuModule} from 'primeng/panelmenu';
import {ToolbarModule} from 'primeng/toolbar';
import {ListboxModule} from 'primeng/listbox';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {CalendarModule} from 'primeng/calendar';
import {ToastModule} from 'primeng/toast';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {PaginatorModule} from 'primeng/paginator';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {GridComponent} from './grid/grid.component';
import {ProfitcentreComponent} from './masters/profitcentre/profitCenter.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {FileUploadModule} from 'primeng/fileupload';
import {PortfolioComponent} from './masters/portfolio/portfolio.component';
import {UsermasterComponent} from './masters/usermaster/usermaster.component';
import {SidebarModule} from 'primeng/sidebar';
import {ContractbookComponent} from './masters/contractbook/contractbook.component';
import {FeedService} from './ctrm-feeds/feed.service';
import {CtrmInputFormComponent} from './ctrm-input-form/ctrm-input-form.component';
import {StripHtmlTagsPipe} from './ctrm-feeds/modals/pipe/strip-html-tags.pipe';
import {AiocontrolsComponent} from './ctrm-input-form/components/aiocontrols/aiocontrols.component';
import {CtrmFeedsComponent} from './ctrm-feeds/ctrm-feeds.component';
import {CtrmDualListBoxComponent} from './Components/ctrm-dual-list-box/ctrm-dual-list-box.component';
import {CtrmBreadcrumbComponent} from './Components/ctrm-breadcrumb/ctrm-breadcrumb.component';
import {ChatofaccountdetailsComponent} from './masters/chartofaccount/chatofaccountdetails/chatofaccountdetails.component';
import {GlobalIndicatorsComponent} from './masters/global-indicators/global-indicators.component';
import {GlobalIndicatorDetailsComponent} from './masters/global-indicators/global-indicator-details/global-indicator-details.component';
import {UserinfoComponent} from './masters/usermaster/userinfo/userinfo.component';
import {PreferenceComponent} from './grid/preference/preference.component';
import {SignupComponent} from './signup/signup.component';
import {LoginpageComponent} from './loginpage/loginpage.component';
import {FilterComponent} from './grid/preference/filter/filter.component';
import {CtrmChipsComponent} from './Components/ctrm-chips/ctrm-chips.component';
import {CtrmDropdownComponent} from './Components/ctrm-dropdown/ctrm-dropdown.component';
import {OrderListModule} from 'primeng/orderlist';
import {CtrmInputComponent} from './Components/ctrm-input/ctrm-input.component';
import {SortComponent} from './grid/preference/sort/sort.component';
import {CtrmAutocompleteComponent} from './Components/ctrm-autocomplete/ctrm-autocomplete.component';
import {Ng2PanZoomModule} from 'ng2-panzoom';
import {CtrmPivotTableComponent} from './Components/ctrm-pivot-table/ctrm-pivot-table.component';
import {CtrmTreeViewComponent} from './Components/ctrm-tree-view/ctrm-tree-view.component';
import {NodeComponent} from './Components/ctrm-tree-view/node';
import {NodesListService} from './Components/ctrm-tree-view/services/nodesList.service';
import {CtrmExpandableSubGridComponent} from './Components/ctrm-pivot-table/ctrm-expandable-sub-grid/ctrm-expandable-sub-grid.component';
import {RolemasterComponent} from './masters/rolemaster/rolemaster.component';

import {LocationComponent} from './masters/location/location.component';
import {UnitOfMeasurementComponent} from './masters/unit-of-measurement/unit-of-measurement.component';
import {CommodityComponent} from './masters/commodity/commodity.component';
import {BrandmasterComponent} from './masters/brandmaster/brandmaster.component';
import {CropseasonComponent} from './masters/cropseason/cropseason.component';
import {GrademasterComponent} from './masters/grademaster/grademaster.component';
import {GradequalityspecComponent} from './masters/grademaster/gradequalityspec/gradequalityspec.component';
import {BrandqualityspecComponent} from './masters/brandmaster/brandqualityspec/brandqualityspec.component';
import {TabGridComponent} from './tab-grid/tab-grid.component';
import {ProfitCenterDetailsComponent} from './masters/profitcentre/profit-center-details/profit-center-details.component';
import {UiSwitchModule} from 'ngx-ui-switch';
import {CtrmToggleButtonComponent} from './Components/ctrm-toggle-button/ctrm-toggle-button.component';
import {TransactionreferenceComponent} from './masters/transactionreference/transactionreference.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {PriceindexComponent} from './masters/priceindex/priceindex.component';
import {SecondarycostComponent} from './masters/secondarycost/secondarycost.component';
import {CalendarmasterComponent} from './masters/calendarmaster/calendarmaster.component';
import {PaymenttermComponent} from './masters/paymentterm/paymentterm.component';
import {PaymenteventComponent} from './masters/paymentterm/paymentevent/paymentevent.component';
import {AccesspolicyComponent} from './masters/rolemaster/accesspolicy/accesspolicy.component';
import {CtrmSuggestionInputComponent} from './Components/ctrm-suggestion-input/ctrm-suggestion-input.component';
import {TextMaskModule} from 'angular2-text-mask';
import {CtrmMultiselectComponent} from './Components/ctrm-multiselect/ctrm-multiselect.component';
import {CounterpartyComponent} from './masters/counterparty/counterparty.component';
import {CtrmHierarchyComponent} from './Components/ctrm-hierarchy/ctrm-hierarchy.component';
import {CtrmFilePickerComponent} from './Components/ctrm-file-picker/ctrm-file-picker.component';
import {CtrmSliderComponent} from './Components/ctrm-slider/ctrm-slider.component';
import {Ng5SliderModule} from 'ng5-slider';
import {ContractComponent} from './physicals/contract/contract.component';
import {TradeComponent} from './physicals/contract/trade/trade.component';
import {DocViewerComponent} from './Components/doc-viewer/doc-viewer.component';
import {NgxDocViewerModule} from 'ngx-doc-viewer';
import {RadioButtonModule} from 'primeng/radiobutton';
import {NgxNumberSpinnerModule} from 'ngx-number-spinner';
import {
    AccordionModule,
    AutoCompleteModule,
    ButtonModule,
    ChartModule,
    CheckboxModule,
    ChipsModule,
    ConfirmationService,
    ContextMenuModule,
    DialogModule,
    DragDropModule,
    InputSwitchModule,
    InputTextareaModule,
    KeyFilterModule,
    MessageService,
    MultiSelectModule,
    OrganizationChartModule,
    OverlayPanelModule,
    PickListModule,
    ProgressSpinnerModule,
    ScrollPanelModule,
    SelectButtonModule,
    SliderModule,
    SpinnerModule,
    SplitButtonModule,
    StepsModule,
    TabViewModule,
    TooltipModule
} from 'primeng';
import {EditorModule} from 'primeng/editor';
import {PlanningComponent} from './physicals/planning/planning.component';
import {CtrmPlanningGridComponent} from './Components/ctrm-planning-grid/ctrm-planning-grid.component';
import {PlanningInfoComponent} from './physicals/planning/planning-info/planning-info.component';
import {TradeObligationComponent} from './Operations/trade-obligation/trade-obligation.component';
import {TradeInfoComponent} from './Operations/trade-obligation/trade-info/trade-info.component';
import {CtrmNoteEditorComponent} from './Components/ctrm-note-editor/ctrm-note-editor.component';
import {RestrictDecimalDirective} from './services/directives/restrict-decimal.directive';
import {CtrmGridWithTabsComponent} from './grid/components/ctrm-grid-with-tabs/ctrm-grid-with-tabs.component';
import {ColumnFilterComponent} from './grid/components/column-filter/column-filter.component';
import {CtrmButtonComponent} from './grid/components/ctrm-button/ctrm-button.component';
import {DealSlipComponent} from './physicals/deal-slip/deal-slip.component';
import {ClipboardModule} from 'ngx-clipboard';
import {InViewportModule} from 'ng-in-viewport';
import {CtrmTelInputComponent} from './Components/ctrm-tel-input/ctrm-tel-input.component';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {NgxSelectModule} from 'ngx-select-ex';
import {MatIconModule} from '@angular/material/icon';
import {XcelerDualSliderComponent} from './Components/xceler-dual-slider/xceler-dual-slider.component';
import {MatSliderModule} from '@angular/material/slider';
import {CashforwardComponent} from './physicals/cashforward/cashforward.component';
import {TradeAllocationComponent} from './Operations/trade-allocation/trade-allocation.component';
import {FxPlanningComponent} from './Operations/fx-planning/fx-planning.component';
import {TradeActualizationComponent} from './Operations/trade-actualization/trade-actualization.component';
import {InventoryComponent} from './Operations/inventory/inventory.component';
import {CashFlowComponent} from './Finance/cash-flow/cash-flow.component';
import {XcelerButtonGroupsComponent} from './Components/xceler-button-groups/xceler-button-groups.component';
import {XcelerGridComponent} from './grid/components/xceler-grid/xceler-grid.component';
import {RoleAccordianComponent} from './masters/rolemaster/accesspolicy/role-accordian/role-accordian.component';
import {TradeCashFlowComponent} from './Finance/trade-cash-flow/trade-cash-flow.component';
import {FxPlanningInfoComponent} from './Operations/fx-planning/fx-planning-info/fx-planning-info.component';
import {HeirarchyViewComponent} from './Components/heirarchy-view/heirarchy-view.component';
import {StockPlanningComponent} from './Operations/stock-planning/stock-planning.component';
import {InventoryInfoComponent} from './Operations/stock-planning/inventory-info/inventory-info.component';
import {SettlementComponent} from './Finance/settlement/settlement.component';
import {CashflowGridComponent} from './Finance/settlement/cashflow-grid/cashflow-grid.component';
import {GenerateInvoiceComponent} from './Finance/settlement/generate-invoice/generate-invoice.component';
import {InvoicesAndPaymentsComponent} from './Finance/invoices-and-payments/invoices-and-payments.component';
import {XcelerListComponent} from './Components/xceler-list/xceler-list.component';
import {WarningBoxComponent} from './Components/warning-box/warning-box.component';
import {EodComponent} from './Risk/eod/eod.component';
import {ExpandGridComponent} from './Risk/eod/expand-grid/expand-grid.component';
import {LoginNewComponent} from './login-new/login-new.component';
import {ImportGridComponent} from './grid/components/import-grid/import-grid.component';
import {EodHistoryComponent} from './Risk/eod/eod-history/eod-history.component';
import {MtmMappingComponent} from './Risk/eod/mtm-mapping/mtm-mapping.component';
import {HistoryExpandComponent} from './Risk/eod/eod-history/history-expand/history-expand.component';
import {PriceFixationComponent} from './Operations/price-fixation/price-fixation.component';
import {FutureCommodityPriceComponent} from './Risk/future-commodity-price/future-commodity-price.component';
import {PhysicalCommodityPriceComponent} from './Risk/physical-commodity-price/physical-commodity-price.component';
import {BasisCommodityPriceComponent} from './Risk/basis-commodity-price/basis-commodity-price.component';
import {FxCommodityPriceComponent} from './Risk/fx-commodity-price/fx-commodity-price.component';
import {CtrmPriceTableComponent} from './Components/ctrm-price-table/ctrm-price-table.component';
import {DocumentTemplateMasterComponent} from './masters/document-template-master/document-template-master.component';
import {PriceAllocationComponent} from './Operations/price-allocation/price-allocation.component';
import {AllocationMatchingComponent} from './Operations/price-allocation/allocation-matching/allocation-matching.component';
import {FutureNettingComponent} from './Operations/future-netting/future-netting.component';
import {
    XcelerExpandablePlanningGridComponent
} from './Components/xceler-expandable-planning-grid/xceler-expandable-planning-grid.component';
import {
    XcelerExpandableGridComponent
} from './Components/xceler-expandable-planning-grid/xceler-expandable-grid/xceler-expandable-grid.component';
import {FutureInfoComponent} from './Operations/future-netting/future-info/future-info.component';
import {ManageProfileComponent} from './manage-profile/manage-profile.component';
import {StorageInfoComponent} from './Operations/stock-planning/storage-info/storage-info.component';
import {WidgetAccordianComponent} from './dashboard/widget-accordian/widget-accordian.component';
import {WidgetComponent} from './widgets/widget/widget.component';
import {ExternalPackagingComponent} from './masters/external-packaging/external-packaging.component';
import {InternalPackagingComponent} from './masters/internal-packaging/internal-packaging.component';
import {PointOfSaleComponent} from './Operations/point-of-sale/point-of-sale.component';
import {CounterpartyLimitsComponent} from './Risk/counterparty-limits/counterparty-limits.component';
import {Interceptor} from './services/interceptor';
import {ErrorInterceptor} from './services/errorInterceptor';
import {XcelerBottomPaneComponent} from './Components/xceler-bottom-pane/xceler-bottom-pane.component';
import {NotificationAndAlertComponent} from './notification-and-alert/notification-and-alert.component';
import {CirclePlanningInfoComponent} from './physicals/planning/circle-planning-info/circle-planning-info.component';
import {FlowchartComponent} from './Components/flowchart/flowchart.component';
import {NgxGraphModule} from '@swimlane/ngx-graph';
import {MarketLimitsComponent} from './Risk/market-limits/market-limits.component';
import {ApiConfigComponent} from './masters/api-config/api-config.component';
import {XcelerNumberSpinnerComponent} from './Components/xceler-number-spinner/xceler-number-spinner.component';
import {EodReportsSidebarComponent} from './Risk/eod/eod-history/eod-reports-sidebar/eod-reports-sidebar.component';
import {ObligationDateRangeComponent} from './Operations/trade-obligation/obligation-date-range/obligation-date-range.component';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {DashboardCardComponent} from './Operations/trade-obligation/dashboard-card/dashboard-card.component';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {PdfViewerComponent} from './Components/pdf-viewer/pdf-viewer.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {ChartOfAccountsComponent} from './masters/chart-of-accounts/chart-of-accounts.component';
import {GenerateProformaInvoiceComponent} from './Components/generate-proforma-invoice/generate-proforma-invoice.component';
import {FileSaverModule} from 'ngx-filesaver';
import {DeliveryScheduleDialogComponent} from './physicals/contract/delivery-schedule-dialog/delivery-schedule-dialog.component';
import {NgApexchartsModule} from 'ng-apexcharts';
import {TransportAllocationComponent} from './Operations/transport-allocation/transport-allocation.component';
import {SeaTransportComponent} from './Operations/transport-allocation/sea-transport/sea-transport.component';
import {InputFormComponent} from './Components/input-form/input-form.component';
import {OpenFutureDashboardComponent} from './dashboard/open-future-dashboard/open-future-dashboard.component';
import {BlManagementComponent} from './Operations/bl-management/bl-management.component';
import {TransportActualizationComponent} from './Operations/transport-actualization/transport-actualization.component';
import {ActualizationFormComponent} from './Operations/transport-actualization/actualization-form/actualization-form.component';
import {PlannedCardComponent} from './Operations/transport-actualization/actualization-form/planned-card/planned-card.component';
import {FuturePositionDashboardComponent} from './dashboard/future-position-dashboard/future-position-dashboard.component';
import {FuturePositionCardComponent} from './dashboard/future-position-dashboard/future-position-card/future-position-card.component';
import {OperationsDashboardComponent} from './Operations/operations-dashboard/operations-dashboard.component';
import {OpsPreferencesComponent} from './Operations/operations-dashboard/ops-preferences/ops-preferences.component';
import {CtrmDateRangeComponent} from './Components/ctrm-date-range/ctrm-date-range.component';
import {StaFilterComponent} from './Components/sta-filter/sta-filter.component';
import {XcelerIframeComponent} from './Components/xceler-iframe/xceler-iframe.component';
import {CtrmHoverIconComponent} from './Components/ctrm-hover-icon/ctrm-hover-icon.component';
import {ReportSidemenuComponent} from './Components/report-sidemenu/report-sidemenu.component';
import {CostComponent} from './Finance/cost/cost.component';
import {AmendModifyFormComponent} from './Operations/operations-dashboard/amend-modify-form/amend-modify-form.component';
import {NgxUpperCaseDirectiveModule} from 'ngx-upper-case-directive';
import {AddcostComponent} from './Finance/cost/addcost/addcost.component';
import {ApprovalConfigurationComponent} from './masters/approval-configuration/approval-configuration.component';
import {MastersSidenavComponent} from './masters/masters-sidenav/masters-sidenav.component';
import {NotificationSettingsComponent} from './masters/notification-settings/notification-settings.component';
import {ApprovalEditComponent} from './masters/approval-configuration/approval-edit/approval-edit.component';
import {ApproverWorkflowComponent} from './masters/approver-workflow/approver-workflow.component';
import {AccessDirective} from './services/directives/access.directive';
import {FutureTradeComponent} from './physicals/future-trade/future-trade.component';
import {CtrmMultiInputComponent} from './Components/ctrm-multi-input/ctrm-multi-input.component';
import {DesiredEndStatusComponent} from './masters/approval-configuration/approval-edit/desired-end-status/desired-end-status.component';
import {PdfRpaComponent} from './pdf-rpa/pdf-rpa.component';
import {RpaResultComponent} from './pdf-rpa/rpa-result/rpa-result.component';
import {CtrmAudittrailComponent} from './Components/ctrm-audittrail/ctrm-audittrail.component';
import {TenantConfigurationComponent} from './masters/tenant-configuration/tenant-configuration.component';
import {TenantSectionComponent} from './masters/tenant-configuration/tenant-section/tenant-section.component';
import {NumberSpinnerComponent} from './masters/tenant-configuration/number-spinner/number-spinner.component';
import {CamelPipe} from './Components/pipes/camel.pipe';
import {KycComponent} from './masters/counterparty/KYC/kyc.component';
import {ValidationBoxComponent} from './Components/input-form/validation-box/validation-box.component';
import {XcelerProgressButtonComponent} from './Components/xceler-progress-button/xceler-progress-button.component';
import {FutureManagementDashboardComponent} from './Risk/future-management-dashboard/future-management-dashboard.component';
import {FutureTickerListComponent} from './Risk/future-management-dashboard/future-ticker-list/future-ticker-list.component';
import {TickerDialogBoxComponent} from './Risk/future-management-dashboard/ticker-dialog-box/ticker-dialog-box.component';
import {FutureTickerGraphComponent} from './Risk/future-management-dashboard/future-ticker-graph/future-ticker-graph.component';
import {XcelerSliderComponent} from './Components/xceler-slider/xceler-slider.component';
import {SearchPipe} from './services/pipe/search.pipe';
import {FutureCommodityPriceV1Component} from './Risk/future-commodity-price-v1/future-commodity-price-v1.component';
import {TickerInfoBoxComponent} from './Risk/future-management-dashboard/ticker-info-box/ticker-info-box.component';
import {FutureTradeInfoBoxComponent} from './physicals/future-trade/future-trade-info-box/future-trade-info-box.component';
import {TransportBookingComponent} from './Operations/transport-booking/transport-booking.component';
import {BookingItemComponent} from './Operations/transport-booking/booking-item/booking-item.component';
import {TransportDetailComponent} from './Operations/transport-booking/transport-detail/transport-detail.component';
import {DetailCardComponent} from './Operations/transport-booking/transport-detail/detail-card/detail-card.component';
import {CtrmUploadfileComponent} from './Components/ctrm-uploadfile/ctrm-uploadfile.component';
import {AttachmentComponent} from './Operations/operations-dashboard/amend-modify-form/attachment/attachment.component';
import {MultiSelectWithRadioComponent} from './Components/multi-select-with-radio/multi-select-with-radio.component';
import {ApprovalContextMenuComponent} from './Components/approval-context-menu/approval-context-menu.component';
import {AppConfigService} from './services/injectors/app-config-service.service';
import { MpobGenerationComponent } from './physicals/contract/trade/mpob-generation/mpob-generation.component';
import { AllocateVesselComponent } from './Components/allocate-vessel/allocate-vessel.component';
import {ClickAwayDirective} from './Components/directives/click-away-directive';
import { InterCompanyTradeComponent } from './Operations/operations-dashboard/inter-company-trade/inter-company-trade.component';
import { FilterPipePipe } from './services/pipe/filter-pipe.pipe';
import { ChildsidemenuComponent } from './Components/report-sidemenu/childsidemenu/childsidemenu.component';
import { SafePipe } from './services/pipe/safe.pipe';


const appInitializerFn = (appConfig: AppConfigService) => {
    return () => {
        return appConfig.loadAppConfig();
    };
};

@NgModule({
    declarations: [
        AppComponent,
        CompanyComponent,
        ChartOfAccountComponent,
        DashboardComponent,
        DashCardComponent,
        DashSubCardComponent,
        WidgetsComponent,
        SystemSettingsComponent,
        CtrmFeedsComponent,
        StripHtmlTagsPipe,
        IncotermComponent,
        GridComponent,
        ProfitcentreComponent,
        CtrmInputFormComponent,
        AiocontrolsComponent,
        PortfolioComponent,
        UsermasterComponent,
        CtrmBreadcrumbComponent,
        ContractbookComponent,
        CtrmDualListBoxComponent,
        ChatofaccountdetailsComponent,
        GlobalIndicatorsComponent,
        GlobalIndicatorDetailsComponent,
        UserinfoComponent,
        PreferenceComponent,
        LoginpageComponent,
        SignupComponent,
        FilterComponent,
        CtrmChipsComponent,
        CtrmDropdownComponent,
        CtrmInputComponent,
        SortComponent,
        CtrmAutocompleteComponent,
        CtrmPivotTableComponent,
        CtrmTreeViewComponent,
        NodeComponent,
        CtrmExpandableSubGridComponent,
        RolemasterComponent,
        LocationComponent,
        UnitOfMeasurementComponent,
        CommodityComponent,
        BrandmasterComponent,
        CropseasonComponent,
        GrademasterComponent,
        GradequalityspecComponent,
        BrandqualityspecComponent,
        TabGridComponent,
        ProfitCenterDetailsComponent,
        CtrmToggleButtonComponent,
        TransactionreferenceComponent,
        PriceindexComponent,
        SecondarycostComponent,
        CalendarmasterComponent,
        PaymenttermComponent,
        PaymenteventComponent,
        AccesspolicyComponent,
        CtrmSuggestionInputComponent,
        CtrmMultiselectComponent,
        CounterpartyComponent,
        CtrmHierarchyComponent,
        CtrmFilePickerComponent,
        CtrmSliderComponent,
        ContractComponent,
        TradeComponent,
        DocViewerComponent,
        PlanningComponent,
        CtrmPlanningGridComponent,
        PlanningInfoComponent,
        TradeObligationComponent,
        CtrmNoteEditorComponent,
        TradeInfoComponent,
        RestrictDecimalDirective,
        DealSlipComponent,
        CtrmButtonComponent,
        ColumnFilterComponent,
        CtrmGridWithTabsComponent,
        CtrmTelInputComponent,
        XcelerDualSliderComponent,
        CashforwardComponent,
        TradeAllocationComponent,
        FxPlanningComponent,
        TradeActualizationComponent,
        InventoryComponent,
        CashFlowComponent,
        XcelerButtonGroupsComponent,
        XcelerGridComponent,
        RoleAccordianComponent,
        TradeCashFlowComponent,
        FxPlanningInfoComponent,
        HeirarchyViewComponent,
        StockPlanningComponent,
        InventoryInfoComponent,
        SettlementComponent,
        CashflowGridComponent,
        GenerateInvoiceComponent,
        InvoicesAndPaymentsComponent,
        XcelerListComponent,
        WarningBoxComponent,
        EodComponent,
        ExpandGridComponent,
        LoginNewComponent,
        ImportGridComponent,
        EodHistoryComponent,
        MtmMappingComponent,
        HistoryExpandComponent,
        PriceFixationComponent,
        FutureCommodityPriceComponent,
        PhysicalCommodityPriceComponent,
        BasisCommodityPriceComponent,
        FxCommodityPriceComponent,
        CtrmPriceTableComponent,
        PriceAllocationComponent,
        AllocationMatchingComponent,
        DocumentTemplateMasterComponent,
        FutureNettingComponent,
        XcelerExpandablePlanningGridComponent,
        XcelerExpandableGridComponent,
        FutureInfoComponent,
        DocumentTemplateMasterComponent,
        ManageProfileComponent,
        StorageInfoComponent,
        WidgetAccordianComponent,
        WidgetComponent,
        ExternalPackagingComponent,
        InternalPackagingComponent,
        WidgetAccordianComponent,
        PointOfSaleComponent,
        CounterpartyLimitsComponent,
        XcelerBottomPaneComponent,
        NotificationAndAlertComponent,
        CirclePlanningInfoComponent,
        FlowchartComponent,
        MarketLimitsComponent,
        ApiConfigComponent,
        XcelerNumberSpinnerComponent,
        EodReportsSidebarComponent,
        ObligationDateRangeComponent,
        DashboardCardComponent,
        PdfViewerComponent,
        ChartOfAccountsComponent,
        GenerateProformaInvoiceComponent,
        DeliveryScheduleDialogComponent,
        TransportAllocationComponent,
        SeaTransportComponent,
        InputFormComponent,
        OpenFutureDashboardComponent,
        BlManagementComponent,
        TransportActualizationComponent,
        ActualizationFormComponent,
        PlannedCardComponent,
        FuturePositionDashboardComponent,
        FuturePositionCardComponent,
        OperationsDashboardComponent,
        OpsPreferencesComponent,
        CtrmDateRangeComponent,
        StaFilterComponent,
        XcelerIframeComponent,
        CtrmHoverIconComponent,
        ReportSidemenuComponent,
        AmendModifyFormComponent,
        CostComponent,
        AddcostComponent,
        ApprovalConfigurationComponent,
        MastersSidenavComponent,
        ApprovalEditComponent,
        ApproverWorkflowComponent,
        NotificationSettingsComponent,
        AccessDirective,
        FutureTradeComponent,
        CtrmMultiInputComponent,
        DesiredEndStatusComponent,
        PdfRpaComponent,
        RpaResultComponent,
        DesiredEndStatusComponent,
        CtrmAudittrailComponent,
        TenantConfigurationComponent,
        TenantSectionComponent,
        NumberSpinnerComponent,
        CamelPipe,
        KycComponent,
        ValidationBoxComponent,
        XcelerProgressButtonComponent,
        FutureManagementDashboardComponent,
        FutureTickerListComponent,
        TickerDialogBoxComponent,
        FutureTickerGraphComponent,
        XcelerSliderComponent,
        SearchPipe,
        FutureCommodityPriceV1Component,
        TickerInfoBoxComponent,
        FutureTradeInfoBoxComponent,
        TransportBookingComponent,
        BookingItemComponent,
        TransportDetailComponent,
        DetailCardComponent,
        CtrmUploadfileComponent,
        AttachmentComponent,
        MultiSelectWithRadioComponent,
        ApprovalContextMenuComponent,
        MpobGenerationComponent,
        AllocateVesselComponent,
        ClickAwayDirective,
        InterCompanyTradeComponent,
        FilterPipePipe,
        ChildsidemenuComponent,
        SafePipe
    ],
    imports: [
        BrowserModule,
        PickListModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CommonModule,
        ReactiveFormsModule,
        HttpClientModule,
        FormsModule,
        ChartsModule,
        HttpModule,
        TableModule,
        MenubarModule,
        PanelModule,
        InputTextModule,
        DropdownModule,
        MenuModule,
        UiSwitchModule,
        ButtonModule,
        ToolbarModule,
        ListboxModule,
        DynamicDialogModule,
        PanelMenuModule,
        ToastModule,
        ConfirmDialogModule,
        CalendarModule,
        PaginatorModule,
        MultiSelectModule,
        BrowserAnimationsModule,
        ContextMenuModule,
        MenuModule,
        NgxMaterialTimepickerModule,
        NgbDatepickerModule,
        ButtonModule,
        FileUploadModule,
        SidebarModule,
        ButtonModule,
        FileUploadModule,
        TabViewModule,
        ScrollPanelModule,
        TooltipModule,
        AutoCompleteModule,
        ChipsModule,
        OrderListModule,
        Ng2PanZoomModule,
        DragDropModule,
        CheckboxModule,
        InputSwitchModule,
        KeyFilterModule,
        BsDatepickerModule.forRoot(),
        InputTextareaModule,
        TreeTableModule,
        ProgressSpinnerModule,
        TextMaskModule,
        OrganizationChartModule,
        Ng5SliderModule,
        NgxDocViewerModule,
        DialogModule,
        RadioButtonModule,
        StepsModule,
        EditorModule,
        AccordionModule,
        ClipboardModule,
        InViewportModule,
        NgxSelectModule,
        NgxIntlTelInputModule,
        MatIconModule,
        SelectButtonModule,
        SliderModule,
        MatSliderModule,
        SplitButtonModule,
        ChartModule,
        SpinnerModule,
        NgxNumberSpinnerModule,
        NgxGraphModule,
        NgxDaterangepickerMd.forRoot(),
        MatOptionModule,
        MatSelectModule,
        PdfViewerModule,
        FileSaverModule,
        NgApexchartsModule,
        OverlayPanelModule,
        NgxUpperCaseDirectiveModule,
    ],
    exports: [
        OrderListModule,
        CtrmFilePickerComponent
    ],
    providers: [FeedService,
        NodesListService,
        MessageService,
        ConfirmationService,
        {provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {provide: APP_INITIALIZER, useFactory: appInitializerFn, multi: true, deps: [AppConfigService]},
        DecimalPipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
