export class defaultValue {
  constructor(
    private _defaultVal?: any,
    private _toField?: string,
    private _includedCharacters?: any[],
    private _pattern?: any,
    private _autoIncrement: boolean = false,
    private _isNumericAllowed: boolean = true,
    private _canCopy: boolean = false,
    private _outputValueFormatfunction?: Function,
    private _outputValueFormatfunctionPara?: any[],
    private _alwaysCapitalValue: boolean = false,
    private _outputValueFieldName: any = '',
    private _formatNumber: boolean = false,
    private _showPostText:boolean = false,
    private _postTextFunction?:Function
  ) {
  }

  get autoIncrement(): boolean {
    return this._autoIncrement;
  }

  set autoIncrement(value: boolean) {
    this._autoIncrement = value;
  }

  get pattern(): any {
    return this._pattern;
  }

  set pattern(value: any) {
    this._pattern = value;
  }

  get toField(): string {
    return this._toField;
  }

  set toField(value: string) {
    this._toField = value;
  }

  get defaultValue(): string {
    return this._defaultVal;
  }

  set defaultValue(value: string) {
    this._defaultVal = value;
  }

  get includedCharacters(): any[] {
    return this._includedCharacters;
  }

  set includedCharacters(value: any[]) {
    this._includedCharacters = value;
  }

  get isNumericAllowed(): boolean {
    return this._isNumericAllowed;
  }

  set isNumericAllowed(value: boolean) {
    this._isNumericAllowed = value;
  }

  get canCopy(): boolean {
    return this._canCopy;
  }

  set canCopy(value: boolean) {
    this._canCopy = value;
  }

  get outputValueFormatfunction(): Function {
    return this._outputValueFormatfunction;
  }

  set outputValueFormatfunction(value: Function) {
    this._outputValueFormatfunction = value;
  }

  get outputValueFormatfunctionPara(): any[] {
    return this._outputValueFormatfunctionPara;
  }

  set outputValueFormatfunctionPara(value: any[]) {
    this._outputValueFormatfunctionPara = value;
  }


  get alwaysCapitalValue(): boolean {
    return this._alwaysCapitalValue;
  }

  set alwaysCapitalValue(value: boolean) {
    this._alwaysCapitalValue = value;
  }


  get outputValueFieldName(): any {
    return this._outputValueFieldName;
  }

  set outputValueFieldName(value: any) {
    this._outputValueFieldName = value;
  }

  get formatNumber(): boolean {
    return this._formatNumber;
  }

  set formatNumber(value: boolean) {
    this._formatNumber = value;
  }


  get showPostText(): boolean {
    return this._showPostText;
  }

  set showPostText(value: boolean) {
    this._showPostText = value;
  }


  get postTextFunction(): Function {
    return this._postTextFunction;
  }

  set postTextFunction(value: Function) {
    this._postTextFunction = value;
  }
}
