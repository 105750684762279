import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'warning-box',
  templateUrl: './warning-box.component.html',
  styleUrls: ['./warning-box.component.css']
})
export class WarningBoxComponent implements OnInit {
  @Input() warningMessages: any[] = [];
  @Input() errorMessages: any[] = [];
  @Output() onClose = new EventEmitter();
  @Output() onContinue = new EventEmitter();
  @Input() visible: boolean = false;
  @Input() heading: string = '';
  @Input() bodyMessage:string = ''
  @Input() id: string = '';
  @Input() continueButtonHeader: any = 'Continue';
  @Input() cancelButtonHeader: string = "Cancel";

  constructor() {
  }

  ngOnInit(): void {
  }

  closeMatchDialog(popupClose:boolean = false) {
    this.onClose.emit(popupClose);
  }

  continuePlanning() {
    this.onContinue.emit({id: this.id});
  }
}
