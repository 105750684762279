<p-sidebar (onHide)="outValue()" [autoZIndex]="false"
           [closeOnEscape]="false"
           [modal]="true"
           [showCloseIcon]="false"
           [style]="{minWidth:'70%',maxWidth:'70%',zIndex:'1006'}"
           [visible]="display"
           position="right">
  <div class="header">
    <div style="display: inline-flex">
      <p class="roleName">{{selectedRowData['roleName']}}</p>
      <ctrm-button (onClick)="onClickEdit()" [action]="'edit'" [access]="'Grid Access'" *ngIf="pageDisable && canEdit" [label]="'Edit'" icon="fas fa-pen"></ctrm-button>
    </div>
    <i (click)="outValue()" class="material-icons closable">clear</i>
  </div>
  <div class="content">
    <div class="section">
      <div class="sectionHeader">
        <p class="sectionTitle">Role Information</p>
      </div>
      <div class="sectionBody">
        <div class="infoBox">
          <p class="infoHeader">Role Name</p>
          <p class="infoInfo">{{selectedRowData['roleName']}}</p>
        </div>
        <div class="infoBox">
          <p class="infoHeader">Role Description</p>
          <p *ngIf="pageDisable" class="infoInfo">{{selectedRowData['description']}}</p>
          <div *ngIf="!pageDisable" class="inputbox">
            <input [(ngModel)]="roleDescription"
                   [type]="'text'" class="form-control form-control-sm"
                   pInputText placeholder="Enter Role Description">
          </div>
        </div>
        <div class="infoBox">
          <p class="infoHeader"># of users</p>
          <div class="profilePicBox">
            <div *ngFor="let userMaster of userMasterList;index as index">
              <img *ngIf="index<2" [src]="commonService.getImagePath('defaultProfile','.png')" class="roundPic"
                   pTooltip="{{commonService.getFullName(userMaster)}}"
                   tooltipZIndex="9999">
            </div>
            <div *ngIf="userMasterList.length>2" class="roundPic mrginLeft" pTooltip="{{getTooltip(userMasterList)}}"
                 tooltipZIndex="9999">
              <p class="threeDots">...</p>
            </div>
            <p *ngIf="userMasterList.length == 0" class="infoInfo">{{selectedRowData['numberOfUsers']}}</p>
          </div>
        </div>
        <div class="infoBox">
          <p class="infoHeader">Status</p>
          <p *ngIf="pageDisable" [ngClass]="selectedRowData['status']?'activeBox':'inactiveBox'"
             class="infoInfo">{{selectedRowData['status'] ? 'Active' : 'Inactive'}}</p>
          <div *ngIf="!pageDisable" class="inputbox">
            <ui-switch [(ngModel)]="rolestatus"
                       [checkedLabel]="'Active'"
                       [checkedTextColor]="'#fff'"
                       [defaultBgColor]="'#d8d8d8'"
                       [uncheckedLabel]="'Inactive'"></ui-switch>
          </div>
        </div>
      </div>
    </div>
    <div class="section marginTopClass">
      <div class="sectionHeader sticky-top">
        <p class="sectionTitle">Role Access Permission</p>
        <ctrm-button (onClick)="expandAll()" [icon]="'fas fa-plus-square'" [label]="'Expand All'"
                     style="margin-top: 3px"></ctrm-button>
        <ctrm-button (onClick)="collapseAll()" [icon]="'fas fa-minus-square'" [label]="'Collapse All'"
                     style="margin-top: 3px;margin-right: 5px"></ctrm-button>
        <ctrm-autocomplete (value)="updateValue($event)" [id]="'accesPolicySearchBox'" [minWidth]="'fit-content'"
                           [placeholder]="'Search...'"></ctrm-autocomplete>
        <div class="legendView">
          <div class="legenBox">
            <div class="dot selectedDot"></div>
            <p class="legendTitle">All Selected</p>
          </div>
          <div class="legenBox">
            <div class="dot partiallySelected"></div>
            <p class="legendTitle">Partially Selected</p>
          </div>
          <div class="legenBox">
            <div class="dot unselected"></div>
            <p class="legendTitle">All Unselected</p>
          </div>
        </div>
      </div>
      <div (inViewportAction)="calculateHeight()" [id]="'contentBox'" [style]="{maxHeight:contentHeight}" class="sectionBodyPermission style-4"
           inViewport>
        <div *ngFor="let mapKey of sections">
          <div [hidden]="searchValue.length > 0?contains(accessMap.get(mapKey),searchValue):false"
               class="sectionNameBox">
            <p class="sectionTitleItem sticky-top">{{mapKey}}</p>
            <p (click)="toggleAll(mapKey,true)" *ngIf="!pageDisable" class="selectButton">Select All</p>
            <p (click)="toggleAll(mapKey,false)" *ngIf="!pageDisable" class="selectButton">Unselect All</p>
          </div>
          <div *ngFor="let item of accessMap.get(mapKey)">
            <role-accordian (onCollapseExpandDone)="resetExpandValue()"
                            [disable]="pageDisable"
                            [expand]="expandAllValue"
                            [hidden]="searchValue.length > 0?isHidden(item):false"
                            [data]="item"></role-accordian>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footerBox">
    <div class="footer">
      <ng-container *ngTemplateOutlet="infoUpdate"></ng-container>
      <div style="width: fit-content;display: inline-flex">
        <button (click)="outValue()" accesskey="c" class="ui-button-raised mybutton" label="Cancel" pButton
                type="button"></button>
        <div *ngIf="canEdit" style="display: inline-flex">
          <button (click)="onSave()"
                  [disabled]="pageDisable"
                  [hidden]="isLoading"
                  accesskey="s" class="savebutton" icon="pi pi-check"
                  iconPos="left" label="Save" pButton></button>
          <div [hidden]="!isLoading" class="loadingbutton">
            <div class="loader"></div>
            <p>{{'Saving'}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-sidebar>
<ng-template #infoUpdate>
  <div class="lastUpdateInfoOnForm">
    <div style="display: flex;flex-direction: row">
      <p>Created By : {{createdBy}}</p>
      <p>Created On : {{createdDate}}</p>
    </div>
    <div style="display: flex;flex-direction: row">
      <p>Last Modified By : {{updatedBy}}</p>
      <p>Last Modified On : {{updatedDate}}</p>
    </div>
  </div>
</ng-template>

<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body">
</p-toast>
