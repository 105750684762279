import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CommonService} from "../../services/common.service";
import {ConfirmationService, MessageService, Table} from "primeng";
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";
import {environment} from "../../../environments/environment";
import {Validators} from "@angular/forms";
import {FormControlServiceService} from "../../services/form-control-service.service";
import {HttpErrorResponse} from "@angular/common/http";
import {SearchCriteria} from "../../Operations/transport-actualization/actualization-form/actualization-form.component";

@Component({
  selector: 'allocate-vessel',
  templateUrl: './allocate-vessel.component.html',
  styleUrls: ['./allocate-vessel.component.css']
})
export class AllocateVesselComponent implements OnInit {

  @Input() visible: boolean = false;
  @Input() planId: string = '';
  @Input() planType:string = 'Physical';
  @Input() packageType:string = '';
  @Output() onCancel = new EventEmitter();
  @Output() onDone = new EventEmitter();

  @ViewChild('mainTable') mainTable: Table;

  searchCriteriaList: SearchCriteria[] = [];
  vesselGridHeight: string = '';
  vesselData: any[];
  rowselectvessel: any[] = [];
  vesselAllocationData: any[] = [];
  bsConfig: Partial<BsDatepickerConfig>;
  locationList: any[];
  substituteVesselForm: FormControlServiceService = new FormControlServiceService();
  isSubstitute = false;
  vesselName:any[]=[];

  constructor(public commonService:CommonService, private messageService: MessageService,private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.bsConfig = new BsDatepickerConfig();
    this.bsConfig.dateInputFormat = environment.dateFormat.toUpperCase();
    this.bsConfig.adaptivePosition = true;
    this.bsConfig.useUtc = true;
    this.getColumnsForVessel();
    this.vesselData = [
      {field: 'vesselName', header: 'Vessel Name'},
      {field: 'voyageNo', header: 'Booking Number'},
      {field: 'vesselCapacity', header: 'Vessel Capacity'},
      {field: 'loadPort', header: 'Load Port'},
      {field: 'unloadPort', header: 'Unload Port'},
      {field: 'vesselEta', header: 'ETA', type: 'date'},
      {field: 'portCutOffDate', header: 'Port Cut-off date', type: 'date'},
      {field: 'freightForwarder', header: 'Freight Forwarder '},
    ];
    this.vesselNameData();
    this.getData();
    // this.commonService.getJSONByURL(environment.base_url + '/api/bookingmanagement/v1/getbookingvalue?tenantId=' + this.commonService.getFromStorage('tenantId')).subscribe((data: any) => {
    //   this.vesselAllocationData = data;
    // });
  }

  public getColumnsForVessel() {
    this.commonService.getJSONByURL(environment.base_url + '/api/location/v1/getallbylocationtype?tenantId=' + this.commonService.getFromStorage('tenantId') + '&locationType=Port').subscribe((next: any[]) => {
          this.locationList = this.commonService.createListFromObject(next, 'name', 'name', true);
    });
    this.substituteVesselForm.addControl('bookingNumber','',[Validators.required])
        .addControl('vesselName', '',[Validators.required])
        .addControl('lycan', '')
        .addControl('loadPort', '')
        .addControl('vesselCapacity', '')
        .addControl('unloadPort', '')
        .addControl('substitute', '').build();
  }

  private getData() {
    const vesselPlanning = this.packageType === 'Bulk';
    const Stock = this.planType === 'StockPlan';
    this.searchCriteriaList.push(new SearchCriteria('tenantId', this.commonService.getFromStorage('tenantId'), 'equals'));
    this.commonService.post(environment.base_url + '/api/transportallocationv2/v1/get-vessel-by-criteria?vesselPlanning='+vesselPlanning+'&tenantId=' + this.commonService.getFromStorage('tenantId')+'&isAllocated='+Stock,this.searchCriteriaList).subscribe((data: any) => {
      this.vesselAllocationData = data;
    });
  }

  vesselNameData (){
    this.commonService.getJSONByURL(environment.base_url_new + '/api-bm/api/vessel-master').subscribe((data:any) => {
    this.vesselName.push({label:'Select',value:''});
      data.forEach((e:any)=>{
        e.vesselName;
        this.vesselName.push({ label: e.vesselName, value: e.vesselName });
      })
    });
  }
  onFormCancel() {
    this.onCancel.emit();
  }

  calculatevesselHeight() {
    if (this.mainTable !== undefined && this.mainTable !== null) {
      let offsetTop = this.mainTable.el.nativeElement['offsetTop'];
      if (this.mainTable.el.nativeElement['offsetParent'] !== null && this.mainTable.el.nativeElement['offsetParent'] !== undefined) {
        let offsetHeight = this.mainTable.el.nativeElement['offsetParent']['offsetHeight'];
        this.vesselGridHeight = (offsetHeight - (offsetTop + 40)) + 'px';
      }
    }
  }

  onrowSelect(value: any) {
    this.rowselectvessel = [];
    this.rowselectvessel.push(value.data);
    this.substituteVesselForm.reset();
    this.defaultSubstituteFormValues();
    this.substituteVesselForm.getFormGroup().disable();

  }

  onRowUnselect(value, table: Table) {
    this.substituteVesselForm.getFormGroup().enable();
    this.substituteVesselForm.getFormGroup().reset();
    table.selectionKeys = {};
    this.rowselectvessel = [];
  }

  onAllocateTransportVessel() {
    let voyageId = '';
    let allocationType = 'Substitute';
    if (this.rowselectvessel != null && this.rowselectvessel.length > 0) {
      this.rowselectvessel.forEach(data => {
        let obj = {
          vesselName: data.vesselName,
          vesselCapacity: data.vesselCapacity,
          loadPort: data.loadPort,
          unloadPort: data.unloadPort,
          lycan: data.lycan
        };
        voyageId = data.bookingNumber;
        allocationType = 'Vessel';
        let _this = this;
        if(this.planType === 'Stock'){
          this.commonService.post(environment.base_url + `/api/transportallocationv2/v1/allocatevesselforplanning?planId=${this.planId}&voyageId=${voyageId}&allocationType=${allocationType}&tenantId=${localStorage.getItem('tenantId')}&planType=${this.planType}`, obj).subscribe((data: any) => {
            this.showToast('Transport is successfully Allocated for the PlanId ' + this.planId);
            this.substituteVesselForm.reset();
            this.rowselectvessel = [];
            this.substituteVesselForm.getFormGroup().enable();
            this.onDone.emit(data);
          }, (error: HttpErrorResponse) => {
            _this.messageService.add({
              severity: 'error',
              summary: _this.commonService.getHttpErrorMessage(error, 'Trade Planning')
            });
          });
        }else{
          this.confirmationService.confirm({
            message: 'Are you sure that you want to Allocate the complete Plan?',
            accept: () => {
              this.commonService.post(environment.base_url + `/api/transportallocationv2/v1/allocatevesselforplanning?planId=${this.planId}&voyageId=${voyageId}&allocationType=${allocationType}&tenantId=${localStorage.getItem('tenantId')}&planType=${this.planType}`, obj).subscribe((data: any) => {
                this.showToast('Transport is successfully Allocated for the PlanId ' + this.planId);
                this.substituteVesselForm.reset();
                this.rowselectvessel = [];
                this.substituteVesselForm.getFormGroup().enable();
                this.onDone.emit(data);
              }, (error: HttpErrorResponse) => {
                _this.messageService.add({
                  severity: 'error',
                  summary: _this.commonService.getHttpErrorMessage(error, 'Trade Planning')
                });
              });
            }, reject: () => {
            }
          });
        }
      });
    } else {
      let obj = {
        vesselName: this.substituteVesselForm.getValue('vesselName'),
        vesselCapacity: this.substituteVesselForm.getValue('vesselCapacity'),
        loadPort: this.substituteVesselForm.getValue('loadPort'),
        unloadPort: this.substituteVesselForm.getValue('unloadPort'),
        lycan: this.substituteVesselForm.getValue('lycan')
      };
      voyageId = this.substituteVesselForm.getValue('bookingNumber');
      if (!this.isSubstitute){
        allocationType = 'Custom'
      }
      let _this = this;
      this.confirmationService.confirm({
        message: 'Are you sure that you want to Allocate the complete Plan?',
        accept: () => {
          this.commonService.post(environment.base_url
              + `/api/transportallocationv2/v1/allocatevesselforplanning?planId=${this.planId}&voyageId=${voyageId}&allocationType=${allocationType}&tenantId=${localStorage.getItem('tenantId')}&planType=${this.planType}`, obj).subscribe((data: any) => {
            this.showToast('Transport is successfully Allocated for the PlanId ' + this.planId);
            this.onDone.emit(data);
          }, (error: HttpErrorResponse) => {
            _this.messageService.add({
              severity: 'error',
              summary: _this.commonService.getHttpErrorMessage(error, 'Trade Planning')
            });
          });
        }, reject: () => {
        }
      });
    }

  }

  showToast(msg, severity: string = 'success') {
    this.messageService.add({
      severity: severity,
      summary: msg
    });
  }

  toggleChange(isSub: boolean) {
    this.isSubstitute = isSub;
  }

  defaultSubstituteFormValues() {
    this.substituteVesselForm.getFormGroup().controls['bookingNumber'].setValue(this.rowselectvessel[0]['bookingNumber']);
    this.substituteVesselForm.getFormGroup().controls['vesselName'].setValue(this.rowselectvessel[0]['vesselName']);
    this.substituteVesselForm.getFormGroup().controls['loadPort'].setValue(this.rowselectvessel[0]['loadPort']);
    this.substituteVesselForm.getFormGroup().controls['unloadPort'].setValue(this.rowselectvessel[0]['unloadPort']);
    this.substituteVesselForm.getFormGroup().controls['vesselCapacity'].setValue(this.rowselectvessel[0]['vesselCapacity']);
  }
}
