import {Injectable} from '@angular/core';
import * as fs from "file-saver";
import * as XLSX from 'xlsx';
import {Tcolumn} from "../grid/tcolumn.model";
import {Tab} from "../tab-grid/Tab.model";
import {MasterService} from "../masters/services/MasterService";
import {CommonService} from './common.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExcelServiceService {

  private columnsHeaderList: any[] = [];
  private exportKeysToRemove: any[] = ['updatedBy', 'createdBy', 'href', 'createdTimestamp', 'id', 'rowNum', 'updatedTimestamp', '_rowNum'];
  private addedHeaderList: string[] = [];
  private addedTcolumnList: Tcolumn[] = [];
  private restrictedCharacters: string[] = ['/', '\\', '?', '*', '[', ']'];
  private extensionList: string[] = ['CSV', 'XLSX', 'XLS'];
  private mimeTypeList: string[] = [
    'text/csv',
    'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet;charset=UTF-8', 'application/vnd.ms-excel'];

  constructor(private masterCommonService: MasterService, private commonService: CommonService) {
  }

  private _ignoreFieldList: any[] = ['id'];

  get ignoreFieldList(): any[] {
    return this._ignoreFieldList;
  }

  set ignoreFieldList(value: any[]) {
    this._ignoreFieldList = value;
  }

  public exportTemplate(excelFileName: string, tcolumnArrays: Tcolumn[], type, tabs: Tab[] = [], exportChild: boolean): void {
    let json = this.getHeaderObjets(tcolumnArrays);
    //worksheet = this.applyProperties(worksheet,tcolumnArrays)
    let sheets = {};
    let _this = this;
    sheets[this.simplifySheetName(excelFileName)] = this.getWorksheet(this.addedHeaderList, this.addedTcolumnList);
    if (exportChild && tabs !== undefined && tabs !== null && tabs.length > 0) {
      tabs.forEach(function (tab: Tab) {
        if (tab !== undefined && tab !== null) {
          sheets[_this.simplifySheetName(tab.getLabel())] = _this.getWorksheet(_this.getHeaderList(tab), _this.getHeaderTcolumnList(tab));
        }
      });
    }
    let workbook: XLSX.WorkBook = {
      Sheets: sheets,
      SheetNames: Object.keys(sheets),
      Props: {Author: 'Taomish India Pvt. Ltd.'}
    };
    let excelBuffer: any = XLSX.write(workbook, {bookType: type, type: 'array'});
    this.saveAsExcelFile(excelBuffer, excelFileName, type);
  }

  public exportAll(excelFileName: string, data: any[], type, columns: Tcolumn[]) {
    let sheets = {};
    let _this = this;
    data = this.replaceKeyWithName(data, columns);
    let worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    sheets[this.simplifySheetName(excelFileName)] = worksheet;
    let workbook: XLSX.WorkBook = {
      Sheets: sheets,
      SheetNames: Object.keys(sheets),
      Props: {Author: 'Taomish India Pvt. Ltd.'}
    };
    let excelBuffer: any = XLSX.write(workbook, {bookType: type, type: 'array'});
    this.saveAsExcelFile(excelBuffer, excelFileName, type, '_Export_');
  }

  public saveAsExcelFile(buffer: any, fileName: string, extension, middleFormat: string = '_Template_'): void {
    const data: Blob = new Blob([buffer], {type: this.getMimeTypeFromExtension(extension)});
    fs.saveAs(data, fileName + middleFormat + new Date().getTime() + '.' + extension);
  }

  getMimeTypeFromExtension(exetension: string) {
    return this.mimeTypeList[this.extensionList.indexOf(exetension.toUpperCase())];
  }

  public simplifySheetName(name: string) {
    this.restrictedCharacters.forEach(function (character) {
      if (name.includes(character)) {
        name = name.replace(new RegExp(character, 'g'), '_');
      }
    });
    return name;
  }

  private getHeaderObjets(tcolumnArray: Tcolumn[]) {
    let headerList = {};
    this.addedHeaderList = [];
    this.columnsHeaderList = [];
    let _this = this;
    tcolumnArray.forEach(function (column: Tcolumn) {
      if (!_this._ignoreFieldList.includes(column.getField()) && column.getColumnType() !== 'F') {
        headerList[column.getHeader()] = '';
        _this.addedHeaderList.push(column.getHeader());
        _this.addedTcolumnList.push(column);
      }
      _this.columnsHeaderList.push(column.getHeader());
    });
    return [headerList];
  }

  private applyProperties(worksheet: XLSX.WorkSheet, columns: Tcolumn[], headerList: any[]) {
    let _this = this;
    let column: Tcolumn = null;
    let header = '';
    let keys = Object.keys(worksheet);
    keys.forEach(function (key) {
      if (worksheet[key].v !== undefined && worksheet[key].v !== null && worksheet[key].v.toString().length > 0) {
        header = worksheet[key].v;
        column = columns[headerList.indexOf(header)];
        if (column.getColumnType().toUpperCase() === 'D') {
          worksheet[key].c = 'YYYY-MM-DD';
        }
      }
    });
    return worksheet;
  }

  private getWorksheet(headers: string[], column: Tcolumn[]) {
    let worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([], {header: headers});
    //  this.applyProperties(worksheet,column,headers);
    return worksheet;
  }

  private getHeaderList(tab: Tab) {
    let list = [];
    let _this = this;
    this.masterCommonService.getGridField(tab.getGrid()).forEach(function (column: Tcolumn) {
      if (!tab.getGrid().ignoreFieldListForExport.includes(column.getField()) && column.getColumnType() !== 'F') {
        list.push(column.getHeader());
      }
    });
    return list;
  }

  private getHeaderTcolumnList(tab: Tab) {
    let list: Tcolumn[] = [];
    let _this = this;
    this.masterCommonService.getGridField(tab.getGrid()).forEach(function (column: Tcolumn) {
      if (!tab.getGrid().ignoreFieldListForExport.includes(column.getField()) && column.getColumnType() !== 'F') {
        list.push(column);
      }
    });
    return list;
  }

  private replaceKeyWithName(data: any[] = [], columns: Tcolumn[]) {
    function renameKey(obj, oldKey, newKey) {
      obj[newKey] = obj[oldKey];
      delete obj[oldKey];
    }

    let remainingKeys: any[] = [];
    let _this = this;
    data.forEach(function (rowData) {
      _this.exportKeysToRemove.forEach(function (key) {
        delete rowData[key];
      });
      Object.keys(rowData).forEach(function (key) {
        if (Array.isArray(rowData[key])) {
          rowData[key] = rowData[key].join(',');
        }
      });
      remainingKeys = Object.keys(rowData);
      columns.forEach(function (col: Tcolumn) {
        if (col.getVisibilityArea() === 'H' || col.isVisible() === false) {
          delete rowData[col.getField()];
        } else {
          renameKey(rowData, col.getField(), col.getHeader());
        }
        if (remainingKeys.includes(col.getField())) {
          remainingKeys.splice(remainingKeys.indexOf(col.getField()), 1);
        }
      });
      remainingKeys.forEach(function (keys) {
        delete rowData[keys];
      });
    });
    return data;
  }


  private replaceKeyWithNameWithFields(data: any[] = [], cols: any) {
    let _this = this;
    let newRowData = [];
    let buyStatusList = [1,'1','Buy',true,'buy'];
    data.forEach((rowData:any) =>{
      let obj = {};
      cols.forEach((col:any) =>{
        if (col['type'] === 'buySell'){
          if (buyStatusList.includes(rowData[col['field']])){
            obj[col['header']] = 'Buy';
          }else{
            obj[col['header']] = 'Sell';
          }
        }else if (col['type'] ==='date'){
          obj[col['header']] = _this.commonService.getFormattedDateTime(rowData[col['field']], environment.dateFormat) ;
        }
        else{
          obj[col['header']] = rowData[col['field']];
        }
      })
      newRowData.push(obj);
    });
    return newRowData;
  }

  public exportDashboardDataView(cols,rowData:any[],fileName, sheetName) {
    let sheets = {};
    let data = this.replaceKeyWithNameWithFields(rowData, cols);
    let worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    sheets[sheetName] = worksheet;
    let workbook: XLSX.WorkBook = {
      Sheets: sheets,
      SheetNames: Object.keys(sheets),
      Props: {Author: 'Taomish India Pvt. Ltd.'}
    };
    let excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
    this.saveAsExcelFile(excelBuffer,fileName,'xlsx', '_DataView_')
  }
}
