export const messages = {
  coming_soon: {summary: 'Coming Soon', message: 'This feature is yet to come .'},
  no_row_selected: {summary: 'No Row Selected !', message: 'Please select at least one row .'},
  delete: {summary: '', message: 'Selected row(s) deleted successfully.'},
  saved: {summary: '', message: 'Row added successfully.'},
  save_all: {summary: '', message: 'Save all rows before continue.'},
  copied: {summary: '', message: 'Row copied successfully.'},
  updated: {summary: '', message: 'Row updated successfully.'},
  one_row_copy_at_time: {summary: '', message: 'You can copy one row at a time only.'},
  multiple_delete_confirmation: {
    summary: '',
    message: 'Are you sure that you want to Delete all this Data ? \n(This Data might be used at some other places)'
  },
  single_delete_confirmation: {
    summary: '',
    message: 'Are you sure that you want to Delete this Row ? \n(This Data might be used at some other places)'
  },
  field_required: {summary: '', message: '<fieldname> is required!'},
  wrong_pattern: {summary: '', message: '<fieldname> has wrong pattern!'},
  wrong_email: {summary: '', message: '<fieldname> has wrong email format!'},
  min_length: {summary: '', message: '<fieldname> must be atleast ?1 characters.'},
  max_length: {summary: '', message: '<fieldname> length should not exceed ?1 characters.'},
  min: {summary: '', message: '<fieldname>  should be greater than or equals to ?1.'},
  max: {summary: '', message: '<fieldname>  should be less than or equals to ?1.'},
  are_equal: {summary: '', message: '<fieldname> must be equal!'},
  default_error_format: {summary: '', message: '<fieldname> ?1 ?2'},
  invalidDecimalLength: {summary: '', message: 'Decimal Limit exceed ( Max : ?1 )'},
  invalidIntegerLength: {summary: '', message: 'Number Limit exceed ( Max : ?1 )'},
  maxFileCountExceed: {summary: '', message: 'Maximum File Limit exceed ( Max : ?1 , found : ?2)'},
  user_save_not_allowed: {summary: '', message: 'Logged in user can not be modified.'},
  role_save_not_allowed: {summary: '', message: 'Logged in user role can not be modified.'},
  access_not_allowed: {summary: '', message: 'You dont have permission to access this screen.'},
  continueMessage: "Your changes is not saved. Do you want to continue?",
  requirementNoMatched: {summary: '', message: 'Option ?1 not available for selected row(s). '},
  continueMessageUserInfo: {
    summary: '',
    message: 'You have unsaved changes in <b>?1</b>. Click "<b>Yes</b>" to discard changes and continue.'
  },
  dependencyRef: {
    summary: '',
    message: 'This Counterparty is already in use by  {{objectReference}} for {{outputColumnDisplayName}}  {{outputColumnValue}} !. Changing its attributes may affect existing transactions. Click <b>Yes</b> to continue, <b>No</b> to exit.'
  },
  importCompleted: {summary: '', message: 'Data import completed successfully.'}
};

export const mailMessages = {
  mail_subject_approve: 'Approved Trade ?1 ',
  mail_body_approve_finance_controller: 'Trade ?1 is Approved by Finance Controller.',
  mail_body_approve_trader: 'Trade ?1 is Approved by Trader.',
  mail_subject_send_to_approval: 'Trade ?1 sent for Approval',
  mail_body_send_to_approval: 'Trade ?1 is sent for approval by ?2.',
  mail_subject_void: 'Void Trade ?1',
  mail_body_void: 'Trade ?1 is Void by Trader.',
  mail_subject_approve_with_exception: 'Approved with Exception Trade ?1',
  mail_body_approve_with_exception: 'Trade ?1 is Approved with Exception by Finance Controller.',
  mail_reset_password_sent: 'Password reset complete please check your mail'
};

export const planningMessages = {
  quantity_zero: 'Buy and Sell Quantity is Zero',
  matchQty_notEqual: 'Match Quantity of Buy and Sell is not equal',
  warning_incoterm: 'INCOTERM is different',
  warning_location: 'LOCATION is different',
  warning_counterParty: 'COUNTERPARTY is different',
  warning_uom: 'UOM is different'
};

export const fxPlanningMessages = {
  quantity_zero: 'FX Trade and Trade Obligation Quantity is Zero',
  matchQty_notEqual: 'Planned Obligation match amount should be less than or equal to of FX Trade match amount',
  not_match: 'Fx Trade and Obligation does not match the Logic ( Reason : Company and profit center is different)',
  negative_match: 'Match Amount cannot be greater than Balance Notional Amount',
  currency_miss_match:'FX Currency does not match to Trade Obligation currency '

};


export const stockPlanningMessages = {
  quantity_zero: 'Buy Trade and Storage Quantity is Zero',
  matchQty_notEqual: 'Match Quantity of Buy Trade should be Equal to or Less than Storage Capacity',
  not_match: 'Buy Trade Commodity should match with Storage Commodity when it is Non-Comingle and Profit Center should be same for matching!'

};

export const stockDrawMessages = {
  quantity_zero: 'Sell Trade and Stock Quantity is Zero',
  matchQty_notEqual: 'Match Quantity of Sell Trade and Stock is not equal',
  not_match: 'Sell Trade Commodity should match with Storage Commodity when it is Non-Comingle and Profit Center should be same for matching!'
};

export const priceAllocationMessages = {
  quantity_zero: 'Price Fixation and Planned/Unplanned Obligations Quantity is Zero',
};
