import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CommonService} from "../../services/common.service";
import {Grid} from "../../grid/grid.model";
import {Tcolumn} from "../../grid/tcolumn.model";
import {MasterService} from "../../masters/services/MasterService";
import {defaultValue} from "../../grid/defaultValue.model";
import {FormGroup, Validators} from "@angular/forms";
import {KeyValue} from "../../grid/key-value.model";
import {environment} from "../../../environments/environment";
import {HttpErrorResponse} from "@angular/common/http";
import {MessageService} from "primeng";
import {FormControlServiceService} from "../../services/form-control-service.service";
import {Action} from '../../grid/actions.model';


@Component({
  selector: 'app-counterparty-limits',
  templateUrl: './counterparty-limits.component.html',
  styleUrls: ['./counterparty-limits.component.css']
})
export class CounterpartyLimitsComponent implements OnInit {
  grid: Grid;
  refreshGrid: boolean = false;
  noDataBoxLeft: any;
  @Input() showFetching: boolean = false;
  isError: boolean = false;
  loadMessage: string = 'Loading ..';
  @Input() noDataLabel: string = 'No Data Available';
  gridColumns: Tcolumn[] = [];
  header: any = "Counterparty Limit";
  columns: Map<string, Tcolumn>;
  //side-pannel
  renewalDate: Date = new Date();
  limitDisplay: boolean = false;
  includedCharacters: any[] = ['_', '/', ' '];
  counterpartyName: string = '';
  counterpartyCode: string = '';
  counterpartyCodeList: any[];
  description: string = '';
  notification: string = '';
  notificationList: any[] = [];
  counterpartyId: number;
  saveButtonLabel: any = "Save";
  formGroup: FormGroup;
  validationSections: any = {};
  visible: boolean = false;
  private formControlService:FormControlServiceService = new FormControlServiceService();
  //limits table
  refreshAddNewRow: boolean = true;
  newRows: any[] = [];
  refreshFormGroup: boolean = false;
  volumeLimitCols: Tcolumn[] = [];
  volumeValue: any[] = [];
  creditLimitCols: Tcolumn[] = [];
  creditValue: any[] = [];
  tenorLimitCols: Tcolumn[] = [];
  tenorValue: any[] = [];
  //volume Dialog
  volumeDisplay: boolean = false;
  volumeColumns: Tcolumn[] = [];
  volumeData: any[];
  creditDisplay: boolean = false;
  creditColumns: Tcolumn[] = [];
  creditData: any[];
  tenorDisplay: boolean = false;
  tenorColumns: Tcolumn[] = [];
  tenorData: any[];
  commodityData: any[] = [new KeyValue()];
  creditLimit: any[] = [];
  onLoadData: any[] = [];
  //sidepannel
  selectedRow: any;
  approvalDisplay: boolean = false;
  private uuid: any='';
  DisableCounterParty:boolean= false;


    constructor(public commonService: CommonService, private masterCommonService: MasterService,private messageService: MessageService) { }

  ngOnInit(): void {
      this.loadValidationSection();
      this.genearateFormControl();
    this.notificationList = [
      new KeyValue('Email','email'),
      new KeyValue('Alert','alert')
    ];

    this.getColumns();
    this.grid = new Grid(this.columns, true, true, true, true, 'counterpartyName', '/api/counterpartylimit/v1/getcounterpartylimit?tenantId='+this.commonService.getFromStorage('tenantId'), '', 'Counterparty Limit', undefined, undefined);
    this.gridColumns = this.masterCommonService.getGridField(this.grid);
    this.grid.iconName = 'company';
    this.grid.setWidth('100%');
    this.grid.openDefaultInputForm = false;
    this.grid.setGridConfiguration(true, true, false, false);
    this.grid.setToolbarConfig(true,true,true,false,true);
    this.grid.addClickable('volumeLimit');
    this.grid.addClickable('creditLimit');
    this.grid.addClickable('tenorLimit');
    this.volumeDisplayGrid();
    this.creditDisplayGrid();
    this.tenorDisplayGrid();

  }



  getColumns() {
    let _this = this;

    let volumeFuntion = function (value: any[]) {
      let html: any;
        html = _this.commonService.getSafeHtml('<div style="display: inline-flex">' +
            '<img src="../../../assets/images/svg/volumelimit.svg"></div>');
      return html;
    };

    let creditFuntion = function (value: any[]) {
      let html: any;
      html = _this.commonService.getSafeHtml('<div style="display: inline-flex">' +
          '<img src="../../../assets/images/svg/creditlimit.svg"></div>');
      return html;
    };

    let tenorFuntion = function (value: any[]) {
      let html: any;
      html = _this.commonService.getSafeHtml('<div style="display: inline-flex">' +
          '<img src="../../../assets/images/svg/tenorlimit.svg"></div>');
      return html;
    };

    this.columns = new Map();
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('counterpartyName', new Tcolumn('counterpartyName', 'Counterparty Name', 'T', 1, false, undefined, true, '', 'B', []));
    this.columns.set('description', new Tcolumn('description', 'Description', 'T', 2, true, undefined, true, '', 'B', []));
    this.columns.set('notification', new Tcolumn('notification', 'Notification', 'T', 3, true, undefined, true, '', 'B', []));
    this.columns.set('volumeLimit', new Tcolumn('volumeLimit', 'Volume Limit', 'T', 4, true, undefined, true, new defaultValue('', undefined, undefined, undefined, false, false, false, volumeFuntion),'B',[]));
    this.columns.set('creditLimit', new Tcolumn('creditLimit', 'Credit Limit', 'T', 5, true, undefined, true, new defaultValue('', undefined, undefined, undefined, false, false, false, creditFuntion),'B',[]));
    this.columns.set('tenorLimit', new Tcolumn('tenorLimit', 'Tenor Limit', 'T', 6, true, undefined, true, new defaultValue('', undefined, undefined, undefined, false, false, false, tenorFuntion),'B',[]));
    this.columns.set('approvedBy', new Tcolumn('approvedBy', 'Approve/Rejected By', 'T', 7, true, undefined, true, '', 'B', []));
    this.columns.set('approvedDate', new Tcolumn('approvedDate', 'Approve/Rejected Date', 'D', 8, true, undefined, true, '', 'B', []));
    this.columns.set('approvalStatus', new Tcolumn('approvalStatus', 'Approval Status', 'T', 9, true, undefined, true, '', 'B', []));
    this.columns.set('uuid', new Tcolumn('uuid', 'Counterparty Uuid', 'T', 10, true, undefined, false, '', 'H', []));
    this.volumeLimitGrid();
    this.creditLimitGrid();
    this.tenorLimitGrid();
  }

  volumeLimitGrid() {
    this.volumeLimitCols = [];
    this.volumeLimitCols.push(new Tcolumn('commodity', 'Commodity', 'L', 0, true, this.masterCommonService.getListFromArray(this.onLoadData['commodity']), true,'','B',[Validators.required]));
    this.volumeLimitCols.push(new Tcolumn('quantity','Quantity','N',1,true,undefined,true));
    this.volumeLimitCols.push(new Tcolumn('uom', 'Uom', 'L', 2, true, this.masterCommonService.getListFromArray(this.onLoadData['unitofmeasurement']), true, '', 'B', [Validators.required]));
    this.volumeLimitCols.push(new Tcolumn('periodStartDate','Start Date','D',3,true,undefined,true,'','B',[Validators.required]));
    this.volumeLimitCols.push(new Tcolumn('renewalDate','Renewal Date','D',4,true,undefined,true,'','B',[Validators.required]));
    this.volumeLimitCols.push(new Tcolumn('notificationType', 'Notification Type', 'L', 5, true, this.masterCommonService.getListFromArray(this.onLoadData['notificaiton_type_ind']), true, '', 'B', [Validators.required]));
  }

  creditLimitGrid() {
    this.creditLimitCols = [];
        this.creditLimit = [
        new KeyValue('select',''),
        new KeyValue('Yes','yes'),
        new KeyValue('No','no')
        ];

    this.creditLimitCols.push(new Tcolumn('creditLimit','Credit Limit','L',0,true,this.creditLimit,true,'','B',[Validators.required]));
    this.creditLimitCols.push(new Tcolumn('limitAmount','Amount','N',1,true,undefined,true,'','B',[Validators.required]));
    this.creditLimitCols.push(new Tcolumn('limitCurrency','Currency','L',2,true,this.commonService.getCurrencyList(),true,'','B',[Validators.required]));
    this.creditLimitCols.push(new Tcolumn('periodStartDate','Start Date','D',3,true,undefined,true,'','B',[Validators.required]));
    this.creditLimitCols.push(new Tcolumn('renewalDate','Renewal Date','D',4,true,undefined,true,'','B',[Validators.required]));
    this.creditLimitCols.push(new Tcolumn('notificationType', 'Notification Type', 'L', 5, true, this.masterCommonService.getListFromArray(this.onLoadData['notificaiton_type_ind']), true, '', 'B', [Validators.required]));
  }


  getDateFormat(value: any) {
    value = value.toLowerCase().replace(new RegExp(' ', 'g'), '');
    let obj = {};
    if (value === 'monthly' || value === 'quarterly' || value === 'halfyearly') {
      obj= {minMode: 'month', format: environment.dateMonthFormat};
    } else if (value === 'yearly') {
      obj = {minMode: 'year', format: environment.dateYearFormat};
    } else {
      obj = {minMode: 'day'};
    }
    return obj;
  }
  tenorLimitGrid() {
    this.tenorLimitCols = [];
    let _this = this;
    this.tenorLimitCols.push(new Tcolumn('periodicity', 'Periodicity', 'L', 0, true, this.masterCommonService.getListFromArray(this.onLoadData['quantity_periodicity_ind']), true,'','B',[Validators.required], undefined, undefined, undefined, undefined, undefined));
    this.tenorLimitCols.push(new Tcolumn('tenor', 'Tenor', 'NS', 1, true, undefined, true, '', 'B', [Validators.required]));
    this.tenorLimitCols.push(new Tcolumn('periodStartDate','Start Date','D',2,true,undefined,true,'','B',[Validators.required]));
    this.tenorLimitCols.push(new Tcolumn('renewalDate','Renewal Date','D',3,true,undefined,true,'','B',[Validators.required]));
    this.tenorLimitCols.push(new Tcolumn('notificationType', 'Notification Type', 'L', 4, true, this.masterCommonService.getListFromArray(this.onLoadData['notificaiton_type_ind']), true, '', 'B', [Validators.required]));
  }

  //Volume Dialog Display
  volumeDisplayGrid() {
    this.volumeColumns.push(new Tcolumn('commodity','Commodity','LB',0,false,this.commodityData));
    this.volumeColumns.push(new Tcolumn('quantity','Quantity','T',1,false,undefined));
    this.volumeColumns.push(new Tcolumn('uom','Uom','LB',2,false,undefined));
    this.volumeColumns.push(new Tcolumn('periodStartDate','Start Date','D',3,false,undefined));
    this.volumeColumns.push(new Tcolumn('renewalDate','Renewal Date','D',4,false,undefined));
    this.volumeColumns.push(new Tcolumn('notificationType','Notification Type','LB',5,false,undefined));
  }

  //Credit Dialog Display
  creditDisplayGrid() {
    this.creditColumns.push(new Tcolumn('creditLimit','Credit Limit','LB',0,false,undefined));
    this.creditColumns.push(new Tcolumn('limitAmount','Amount','LB',1,false,undefined));
    this.creditColumns.push(new Tcolumn('limitCurrency','Currency','LB',2,false,undefined));
    this.creditColumns.push(new Tcolumn('periodStartDate','Start Date','D',3,false,undefined));
    this.creditColumns.push(new Tcolumn('renewalDate','Renewal Date','D',4,false,undefined));
    this.creditColumns.push(new Tcolumn('notificationType','Notification Type','LB',5,false,undefined));
  }

  //Tenor Dialog Display
  tenorDisplayGrid() {
    this.tenorColumns.push(new Tcolumn('periodicity','Periodicity','LB',0,false,undefined));
    this.tenorColumns.push(new Tcolumn('tenor','Tenor','LB',1,false,undefined));
    this.tenorColumns.push(new Tcolumn('periodStartDate','Start Date','D',2,false,undefined));
    this.tenorColumns.push(new Tcolumn('renewalDate','Renewal Date','D',3,false,undefined));
    this.tenorColumns.push(new Tcolumn('notificationType','Notification Type','LB',4,false,undefined));
  }


//On click of add button open the Side panel
  onClickAdd() {
    this.counterpartyName = '';
    this.description = '';
    this.notification = '';
    this.refreshGrid = false;
    this.volumeValue = [];
    this.creditValue = [];
    this.tenorValue = [];
    this.visible = true;
    this.getcounterpartyList();
    this.getnotificationtype();
    this.saveButtonLabel='Save'
    this.DisableCounterParty=false;
  }

  close() {
  this.limitDisplay = false;
  this.uuid = '';
  }

  //load the counterparty list
  getcounterpartyList() {
    let _this = this;
    _this.commonService.getLoadData('counterparty', ['config'], [[{key:'code',value:'counterparty'}]]).subscribe((next: any) => {
      _this.counterpartyCodeList = this.masterCommonService.getListFromArray(next['counterparty'].sort());
    });
  }

  //get data when load
  getnotificationtype() {
    let _this = this;
    _this.commonService.getLoadData('commodity', ['data', 'data', 'config', 'config'], [[{
      key: 'name',
      value: 'notificaiton_type_ind'
    }], [{
      key: 'name',
      value: 'quantity_periodicity_ind'
    }], 'commodity', 'unitofmeasurement']).subscribe((next: any) => {
      _this.onLoadData = next;
      _this.volumeLimitGrid();
      _this.creditLimitGrid();
      _this.tenorLimitGrid();
    });
  }


  //Save the limit data
  saveLimit(isUpdate: boolean = false,value) {
    let _this = this;
    if(this.volumeValue !== null && this.volumeValue !== undefined && this.volumeValue.length > 0) {
      this.volumeValue.forEach(function(obj) {
        obj['renewalDate'] = new Date(obj['renewalDate']+'UTC');
        obj['periodStartDate'] = new Date(obj['periodStartDate']+'UTC');
      })
    }
    if(this.creditValue !== null && this.creditValue !== undefined && this.creditValue.length > 0) {
      this.creditValue.forEach(function(_obj) {
        _obj['renewalDate'] = new Date(_obj['renewalDate']+'UTC');
        _obj['periodStartDate'] = new Date(_obj['periodStartDate']+'UTC');
      })
    }


    let payload = {
      uuid:this.uuid,
      counterpartyName: value['counterparty'],
      description: value['description'],
      notification: value['notification'],
      tenantId: this.commonService.getFromStorage('tenantId'),
      volumeLimitList: value['volumelimit'],
      creditLimitList: value['creditlimit'],
      tenorLimitList: value['tenorlimit']
    };

    this.commonService.post(environment.base_url + '/api/counterpartylimit/v1/savecounterpartylimitdata?action='+this.saveButtonLabel, payload).subscribe(next => {
      this.limitDisplay = false;
      this.refreshGrid = true;
      this.uuid ='';
      this.volumeValue = [];
      this.creditValue = [];
      this.tenorValue = [];
      this.visible = false;
      this.showToast('Row Successfully Saved.');
    }, (error: HttpErrorResponse) => {
      _this.showToast(_this.commonService.getHttpErrorMessage(error, 'Counterparty Limit', false), 'error');
    });

  }


//on click of Edit
  openInUpdateMode(value: any) {
    let rowData = value['rowData'];
    this.onClickAdd();
    this.saveButtonLabel='Update'
    this.commonService.getJSONByURL(environment.base_url + '/api/counterpartylimit/v1/loadcounterpartylimitdata?tenantId='+this.commonService.getTenantId()+'&uuid=' +rowData['uuid']).subscribe((next:any) => {
        this.addContentBox(next['counterparty'], next['description'], next['notification'], next['volumeLimitList'], next['creditLimitList'], next['tenorLimitList']);
    });
    this.onClickunique(value)

  }


  addContentBox(counterparty, description, notification, volumelimit: any[] = [], creditlimit: any[] = [], next: any[] = []) {
    this.counterpartyName = counterparty;
    this.description = description;
    this.notification = notification;
    this.volumeValue = volumelimit;
    this.creditValue = creditlimit;
    this.tenorValue = next;
    let _this = this;
    let metaData = [];
    metaData = this.masterCommonService.getGridField(_this.grid);
    let obj: {
      counterpartyName: any,
      description: any,
      notification: any,
      metadata: Tcolumn[],
      values: any[],
      expanded: boolean,
      newRows: any[],
      refreshAddNewRow: boolean,
      refreshFormGroup: boolean
    } = {
      counterpartyName: counterparty,
      description: description,
      notification: notification,
      metadata: metaData,
      values: next,
      expanded: true,
      newRows: next,
      refreshAddNewRow: true,
      refreshFormGroup: false
    };

  }

  //open volume dialog
  openVolumeScreen(value:any) {
      let counterpartyUuid = value['uuid'];
    this.commonService.getJSONByURL(environment.base_url + '/api/counterpartylimit/v1/getvolumelimitlist?tenantId='+this.commonService.getTenantId()+'&counterpartyUuid=' +counterpartyUuid).subscribe((next: any) => {
        this.volumeData = next;
        this.volumeDisplay = true;
      }, (error: HttpErrorResponse) => {
      this.showToast(this.commonService.getHttpErrorMessage(error, 'Counterparty Limit', false), 'error');
    });
    }

  //open Tenor dialog
  openCreditScreen(value:any) {
    let counterpartyUuid = value['uuid'];
    this.commonService.getJSONByURL(environment.base_url + '/api/counterpartylimit/v1/getcreditlimitlist?tenantId='+this.commonService.getTenantId()+'&counterpartyUuid=' +counterpartyUuid).subscribe((next: any) => {
      this.creditData = next;
      this.creditDisplay = true;
    }, (error: HttpErrorResponse) => {
      this.showToast(this.commonService.getHttpErrorMessage(error, 'Counterparty Limit', false), 'error');
    });
  }

  //open Tenor dialog
  openTenorScreen(value:any) {
    let counterpartyUuid = value['uuid'];
    this.commonService.getJSONByURL(environment.base_url + '/api/counterpartylimit/v1/gettenorlimitlist?tenantId='+this.commonService.getTenantId()+'&counterpartyUuid=' +counterpartyUuid).subscribe((next: any) => {
        this.tenorData = next;
        this.tenorDisplay = true;
      }, (error: HttpErrorResponse) => {
      this.showToast(this.commonService.getHttpErrorMessage(error, 'Counterparty Limit', false), 'error');
    });
  }

    onCancelVolume() {
        this.volumeDisplay = false;
    }

    onCancelLimit() {
        this.creditDisplay = false;
    }

    onCancelTenor() {
        this.tenorDisplay = false;
  }

  //SubTable in the sideBar
  onNewRowAddComplete() {
    this.refreshAddNewRow = false;
    this.newRows = [];
  }

  onRefreshFromGroupComplete() {
    this.refreshFormGroup = false;
  }

 // update
  onSave(value: any) {
    // if (value['mode'] === 'update') {
    //   this.saveLimit( true,value);
    // }
  }

  //nodata box
  calculateLeft() {
    this.noDataBoxLeft = ((window['visualViewport'].width - 500) / 2) + 'px';
  }

  onCounterpartyChange(value: any) {
    let _this = this;
    this.counterpartyCodeList.forEach(function (obj: any) {
      if(obj['value'] === value){
        _this.counterpartyId = obj['id'];
        return;
      }
    });
  }

  onClickunique(value: any) {
    this.saveButtonLabel='Update'
    this.DisableCounterParty=true;
    this.uuid= value.rowData.uuid;
    this.formControlService.setValue('counterparty',value.rowData.counterpartyCode);
    this.formControlService.setValue('description',value.rowData.description);
    this.formControlService.setValue('notification',value.rowData.notification);
      let col: Tcolumn = value['column'];
      if(col.getField() === 'volumeLimit'){
          this.openVolumeScreen(value['rowData']);
      } else if(col.getField() === 'creditLimit') {
          this.openCreditScreen(value['rowData']);
      } else if(col.getField() === 'tenorLimit') {
          this.openTenorScreen(value['rowData']);
      }
  }

  showApprovalButton(value: any[]) {
    if(value !== undefined && value !== null && value.length ===  1) {
      this.selectedRow = value[0];
      this.approvalDisplay = true;
    } else {
      this.approvalDisplay = false;
    }
  }

  onClose() {
    this.approvalDisplay = false;
  }

  approveLimit() {
    this.refreshGrid = false;
    let _this = this;
     let uuid = _this.selectedRow['uuid'];
    this.commonService.getJSONByURL(environment.base_url + '/api/counterpartylimit/v1/approvecounterpartylimit?tenantId='+this.commonService.getTenantId()+'&uuid='+uuid+'&approvedBy=' +this.commonService.getFromStorage('userName')).subscribe((next) => {
      _this.showToast("Counterparty Limit approved successfully.");
      _this.onClose();
      _this.refreshGrid = true;
    }, (error: HttpErrorResponse) => {
      _this.showToast(_this.commonService.getHttpErrorMessage(error, 'Counterparty Limit', false), 'error');
    });
  }

  rejectLimit() {
    this.refreshGrid = false;
    let _this = this;
    let uuid = _this.selectedRow['uuid'];
    this.commonService.getJSONByURL(environment.base_url + '/api/counterpartylimit/v1/rejectcounterpartylimit?tenantId='+this.commonService.getTenantId()+'&uuid='+uuid+'&approvedBy=' +this.commonService.getFromStorage('userName')).subscribe((next) => {
      _this.showToast("Counterparty Limit rejected.");
      _this.onClose();
      _this.refreshGrid = true;
    }, (error: HttpErrorResponse) => {
      _this.showToast(_this.commonService.getHttpErrorMessage(error, 'Counterparty Limit', false), 'error');
    });
  }


  showToast(msg, severity: string = 'info') {
    this.messageService.add({
      severity: severity,
      summary: msg
    });
  }

  onValueChangeTenorFields(value: any) {
    let rowData:any = value['rowData'];
    let col:string = value['col'];
    let index:number = value['rowIndex'];
    let config = value['rowConfig'];
    if(col === 'periodicity') {
      let periodicity = rowData['periodicity'];
      config['periodStartDate'] = this.getDateFormat(periodicity);
      config['renewalDate'] = this.getDateFormat(periodicity);
    }
  }
  oncloseinputform() {
    this.formControlService.reset();
    this.visible = false;
  }

  onSaveDone(value: any) {
      value.volumelimit = this.volumeValue;
      value.creditlimit = this.creditValue;
      value.tenorlimit = this.tenorValue;
     this.saveLimit(false,value)

    }

  onFormGroupCreatedvolume(form: FormGroup) {
    this.formGroup = this.formControlService.addFormGroupControl('volumelimit',form).build();
  }

  onFormGroupCreatedcredit(form: FormGroup) {
    this.formGroup = this.formControlService.addFormGroupControl('creditlimit',form).build();
  }

  onFormGroupCreatedtenor(form: FormGroup) {
    this.formGroup = this.formControlService.addFormGroupControl('tenorlimit',form).build();
  }

  private loadValidationSection() {
    let volumnelimitfields = {};
    volumnelimitfields['commodity'] = 'Commodity';
    volumnelimitfields['quantity'] = 'Quantity';
    volumnelimitfields['uom'] = 'UOM';
    volumnelimitfields['periodStartDate'] = 'Period Start Date';
    volumnelimitfields['renewalDate'] = 'Renewal Date';
    volumnelimitfields['notificationType'] = 'Notification Type';


    let creditlimit = {};
    creditlimit['creditLimit'] = 'Credit Limit';
    creditlimit['limitAmount'] = 'Limit Amount';
    creditlimit['limitCurrency'] = 'Limit Currency';
    creditlimit['periodStartDate'] = 'Period Start Date';
    creditlimit['renewalDate'] = 'Renewal Date';
    creditlimit['notificationType'] = 'Notification Type';

    let tenorLimitFields = {};
    tenorLimitFields['periodicity'] = 'Periodicity';
    tenorLimitFields['tenor'] = 'Tenor';
    tenorLimitFields['periodStartDate'] = 'Period Start Date';
    tenorLimitFields['renewalDate'] = 'Renewal Date';
    tenorLimitFields['notificationType'] = 'Notification Type';


    this.validationSections = {};
    this.validationSections['Counterparty Limit Information'] = [
      {field:'counterparty',header:'Counterparty'},
      {field:'description',header:'Description'},
      {field:'notification',header:'Notification'}
    ];
    this.validationSections['Volume Limit'] = [{field:'volumelimit',header:'Volume Limit',grid:true,fields:volumnelimitfields}]
    this.validationSections['Credit Limit'] = [{field:'creditlimit',header:'Credit Limit',grid:true,fields:creditlimit}]
    this.validationSections['Tenor Limit'] = [{field:'tenorlimit',header:'Tenor Limit',grid:true,fields:tenorLimitFields}]
  }

  private genearateFormControl() {
    this.formGroup = this.formControlService.addControl('counterparty','',[Validators.required])
        .addControl('description','',[])
        .addControl('notification','',[Validators.required]).build();
  }

}
