<div style="display: inline-flex;width: 100%">
    <masters-sidenav></masters-sidenav>
    <div id="pinwidth" style="width: 100%;margin-left: 70px">
        <ctrm-grid (editAllowedOut)="editUpdate($event)"
                   (onClickUniqueKey)="openSidebar($event)"
                   [breadcrumbMapKey]="commonService.MASTER_MAP_KEY"
                   [currentRoute]="'/usermaster'"
                   [fieldsWithCustomView]="['productSubscription']"
                   [customViewTemplated]="[productsubTemplate]"
                   [grid]="grid"
                   [refreshGrid]="gridRefresh">
        </ctrm-grid>

        <app-userinfo (onClose)="changeDisplay()"
                      (onUserInfoUpdated)="oninfoUpdate($event)"
                      *ngIf="display"
                      [data]="data"
                      [display]="display"
                      [screentype]="'UserMaster'"
                      [editEnabled]="editAllowed"
                      [grid]="grid">
        </app-userinfo>
    </div>
</div>
<ng-template #productsubTemplate let-formControl='formControl' let-readOnly='readOnly'>
    <div *ngIf="!readOnly">
        <multi-select-with-radio [options]="products" [formControl]="formControl"></multi-select-with-radio>
    </div>
    <div *ngIf="readOnly">
        <p class="m-0 p-0"
           style="font-family: 'Lato', sans-serif;font-size: 13px;font-weight: normal;color: #333333"
           [innerHTML]="commonService.getSafeHtml(getProductSubText(formControl.value))"></p>
    </div>
</ng-template>
