import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControlServiceService} from '../../../services/form-control-service.service';
import {CommonService} from '../../../services/common.service';
import {KeyValue} from '../../../grid/key-value.model';
import {environment} from '../../../../environments/environment';
import {Tcolumn} from '../../../grid/tcolumn.model';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MessageService} from 'primeng';
import {HttpErrorResponse} from '@angular/common/http';
import {userInfo} from "os";
import {defaultValue} from "../../../grid/defaultValue.model";

@Component({
    selector: 'app-transport-detail',
    templateUrl: './transport-detail.component.html',
    styleUrls: ['./transport-detail.component.css']
})
export class TransportDetailComponent implements OnInit,OnChanges {
    freightsMeta: any[] = [];
    receiptsMeta: any[] = [];
    voyageMeta: any[] = [];
    vesselMeta: any[] = [];
    @Input() editing: boolean = true;
    @Input() mandatory: boolean = false;
    userCommodityList = [];
    locationList = [];
    userPersonInChargeList:any[]=[];
    incotermList:any[]=[]
    attachmentCols: Tcolumn[] = [];
    attachmentData = [] = [];
    attachmentDataFetch: boolean = false;
    isBulk = true;
    @Output() onBackEvent = new EventEmitter();
    bookingForm: FormGroup;
    @Input() showEdit: boolean = false;
    @Input() showSave: boolean = true;
    @Input() showUpdate: boolean = false;
    loadPortValues: any[] =[];
    loadTableValid:boolean = false;
    newLoadPortRows: any[] =[];
    refreshAddNewRowLoadPort: boolean = true;
    loadPortColumns: Tcolumn[] = [];
    refreshLoadFormGroup: boolean = false;
    formGroup: FormGroup;
    portList:any[] =[];
    unloadPortColumns: Tcolumn[] = [];
    refreshUnloadFormGroup: boolean = false;
    newUnloadPortRows: any[] =[];
    refreshAddNewRowUnloadPort: boolean = true;
    unloadPortValues: any[] =[];
    unloadTableValid:boolean = false;
    vesselList: any[];
    selectedAttachment:any[] = [];
    url:any;

    formControlService: FormControlServiceService = new FormControlServiceService();
    navigationMenuOption: any[] = [{name: 'Ocean Freight Details', icon: 'open_book', active: true},
        {name: 'Voyage Details', icon: 'reciept_icon', active: false},
        {name: 'Vessel Details', icon: 'vessel_2', active: false},
        {name: 'Connected Vessel Details', icon: 'vessel_2', active: false},
        {name: 'Attachment Details', icon: 'attechment_pin2', active: false}];
    selectedNav: string;
    displayFile: boolean = false;
    @Input() bookingNumber: any;
    bookingValue: any[] = [];
    @Input() resetForm: boolean = false;
    @Output() onFormResetComplete = new EventEmitter();
    formBuildCompleted: boolean = false;

    constructor(public commonService: CommonService, private messageService: MessageService) {

    }

    async ngOnInit() {
        let _this = this;
        this.getAttachmentCols();
        let response = await this.commonService.getHttpClient().get(environment.base_url_new + '/api-iam/api/userProfile/v1/getUserDetails?userId='
            + this.commonService.getFromStorage('userName') + '&tenantId=' + this.commonService.getFromStorage('tenantId')).toPromise().then((data) => {
            return data;
        }).catch();
        this.userCommodityList = this.commonService.getListFromArray(response['userInfoData']['Commodity'], 'masterTypeValue', 'masterTypeValue', false, true);
        this.commonService.getJSONByURL(environment.base_url +'/api/trade/v1/getuser?tenantId='+this.commonService.getFromStorage('tenantId')+'&userType=Operation').subscribe((data:any)=>{
            let _this = this;
            _this.userPersonInChargeList.push(new KeyValue());
            data.forEach(data=>{
                _this.userPersonInChargeList.push(new KeyValue(data['value'],data['value']))
            })
        });

        this.selectedNav = this.navigationMenuOption[0]['name'];

        this.commonService.getJSONByURL(environment.base_url +'/api/incoterm/v1/getall?tenantId='+this.commonService.getFromStorage('tenantId')+'&page=0&size=20').subscribe((data:any)=>{
            let _this = this;
            _this.incotermList.push(new KeyValue());
            data.content.forEach(data=>{
               _this.incotermList.push(new KeyValue(data['name'],data['name']))
            })
        });

        this.getVesselMeta();
        this.getFreightMeta();
        this.getVoyageMeta();
        this.getReceiptMeta();
        this.onItemCardClick();

        this.commonService.getJSONByURL(environment.base_url + '/api/location/v1/getallbylocationtype?tenantId=' +
            this.commonService.getFromStorage('tenantId') + '&locationType=Country').subscribe((next: any[]) => {
            this.locationList = this.commonService.createListFromObject(next, 'name', 'name', true);
            _this.freightsMeta[3]['listValues'] = _this.locationList;
            _this.freightsMeta[4]['listValues'] = _this.locationList;
        });

        this.commonService.getJSONByURL(environment.base_url + '/api/location/v1/getallbylocationtype?tenantId=' +
            this.commonService.getFromStorage('tenantId') + '&locationType=Port').subscribe((next:any[]) => {
            this.portList = this.commonService.createListFromObject(next, 'name', 'name', true);
            _this.voyageMeta[4]['listValues'] = _this.portList;
            _this.voyageMeta[5]['listValues'] = _this.portList;
            _this.receiptsMeta[4]['listValues'] = _this.portList;
            _this.receiptsMeta[5]['listValues'] = _this.portList;
            if(_this.loadPortColumns !== undefined && _this.loadPortColumns !== null && _this.loadPortColumns.length > 0) {
                _this.loadPortColumns[0].setListofValues(_this.portList);
            }
            if(_this.unloadPortColumns !== undefined && _this.unloadPortColumns !== null && _this.unloadPortColumns.length > 0) {
                _this.unloadPortColumns[0].setListofValues(_this.portList);
            }

        });

        this.commonService.getJSONByURL(environment.base_url_new + '/api-bm/api/vessel-master').subscribe((data:any) => {
            _this.vesselList = this.commonService.createListFromObject(data,'vesselName', 'vesselName',true);
            _this.vesselMeta[0]['listValues'] = _this.vesselList.sort(customSort);

            function customSort(a, b) {
                // If both labels are 'select', they should be in their original order
                if (a.label == 'Select' || b.label == 'Select') {
                    return 0;
                }

               // Sort the non-'select' labels in alphabetical order
                return a.label.localeCompare(b.label);
            }

// Sorting the list while excluding the items with label 'select'
            this.vesselList.sort(customSort);



        });


    }

    getLoadPortColumns(locationlist:any[]) {
        if (!this.formBuildCompleted){
            this.loadPortColumns=[];
            this.loadPortColumns.push(new Tcolumn('loadPort', 'Load Port', 'L', 0, true, locationlist, true,'','B',[Validators.required]));
            this.loadPortColumns.push(new Tcolumn('loadRate', 'Load Rate', 'N', 1, true, undefined, true,'','B',[Validators.required]));
            this.loadPortColumns.push(new Tcolumn('barge', 'Barge', 'T', 1, true, undefined, true,'','B',[Validators.required]));
            this.loadPortColumns.push(new Tcolumn('tugboat', 'Tug Boat', 'T', 1, true, undefined, true,'','B',[Validators.required]));
            this.loadPortColumns.push(new Tcolumn('laycanFrom', 'Laycan From', 'D', 2, true, [], true,'','B',[Validators.required]));
            this.loadPortColumns.push(new Tcolumn('laycanTo', 'Laycan To', 'D', 2, true, [], true,'','B',[Validators.required]));
            this.loadPortColumns.push(new Tcolumn('sailingDate', 'Sailing Date', 'D', 3, true, undefined, true,'','B',[Validators.required]));
            this.loadPortColumns.push(new Tcolumn('notes', 'Note/Comments', 'T', 4, true, undefined, true));
            this.loadPortColumns.push(new Tcolumn('agentName', 'Agent Name', 'T', 4, true, undefined, true));
            this.loadPortColumns.push(new Tcolumn('agentAddress', 'Agent Address', 'TA', 4, true, undefined, true));
            this.loadPortColumns.push(new Tcolumn('agentContact', 'Agent Contact', 'N', 4, true, undefined, true));
        }
    }

    getUnloadPortColumns(locationlist:any[]) {
        if (!this.formBuildCompleted) {
            this.unloadPortColumns=[];
            this.unloadPortColumns.push(new Tcolumn('dischargePort', 'Discharge Port', 'L', 0, true, locationlist, true, '', 'B', [Validators.required]));
            this.unloadPortColumns.push(new Tcolumn('dischargeRate', 'Discharge Rate', 'N', 1, true, undefined, true, '', 'B', [Validators.required]));
            this.unloadPortColumns.push(new Tcolumn('etaFrom', 'ETA From', 'D', 2, true, [], true, '', 'B', [Validators.required]));
            this.unloadPortColumns.push(new Tcolumn('etaTo', 'ETA To', 'D', 3, true, undefined, true, '', 'B', [Validators.required]));
            this.unloadPortColumns.push(new Tcolumn('notes', 'Note/Comments', 'T', 4, true, undefined, true));
            this.unloadPortColumns.push(new Tcolumn('agentName', 'Agent Name', 'T', 5, true, undefined, true));
            this.unloadPortColumns.push(new Tcolumn('agentAddress', 'Agent Address', 'TA', 6, true, undefined, true));
            this.unloadPortColumns.push(new Tcolumn('agentContact', 'Agent Contact', 'N', 7, true, undefined, true));
        }
    }

    onFormBuildComplete(value:any){
        this.getLoadPortColumns(this.portList);
        this.getUnloadPortColumns(this.portList);
        this.subscribeFormChange();
        this.formBuildCompleted = true;
    }

    subscribeFormChange(){
        let _this = this;
        if (this.resetForm){
            this.formControlService.getFormGroup().reset();
            this.onFormResetComplete.emit();
        }
        _this.formControlService.setValue('freightType','Sea');
        let bookingDate = this.formControlService.getValue("bookingDate");
        if(bookingDate !== null && bookingDate !== undefined && bookingDate.toString().length > 0) {
            _this.formControlService.setValue('bookingDate',this.commonService.moment(bookingDate).toDate());
        } else {
            _this.formControlService.setValue('bookingDate',new Date());
        }
        this.formControlService.attachChangeListener('shippingMode',(value:any,formGroup:FormGroup,formControl:FormControl) => {
            _this.formControlService.setValue('movementType','');
            if(value === 'Bulk Vessel') {
                _this.formControlService.setValue('movementType','Port to Port');
                _this.formControlService.disable('noOfContainers');
                _this.formControlService.disable('containerType');
                _this.isBulk = true;
                _this.navigationMenuOption[1] = {name: 'Voyage Details', icon: 'reciept_icon', active: false}
            }else{
                _this.formControlService.enable('noOfContainers');
                _this.formControlService.enable('containerType');
                _this.formControlService.disable('stowagePlan');
                _this.isBulk = false;
                _this.navigationMenuOption[1] = {name: 'Receipt Details', icon: 'reciept_icon', active: false}
            }
        });
        this.formControlService.attachChangeListener('vesselName',(value:any,formGroup:FormGroup,formControl:FormControl) => {
            this.commonService.getJSONByURL(environment.base_url_new + '/api-bm/api/vessel-master/getbyvesselname?vesselName=' + value).subscribe((data:any) => {
                if(data !== undefined && data !== null) {
                    _this.formControlService.setValue('vesselCapacity',data['netTonnage']);
                }
            });
        });
    }
    getAttachmentCols() {
        this.attachmentCols = [];
        let _this = this;
        let fileSize = function(value: any) {
            return _this.commonService.fileSizeConvert(value, true);
        };
        this.attachmentCols.push(new Tcolumn('attachmentName', 'Attachment', 'LB', 1, false, undefined, true));
        this.attachmentCols.push(new Tcolumn('createdBy', 'Created By', 'LB', 2, false, undefined, true));
        this.attachmentCols.push(new Tcolumn('createdTimestamp', 'Uploaded Date', 'LB', 3, false, undefined, true));
        this.attachmentCols.push(new Tcolumn('documentSize', 'File Size', 'LB', 3, false, undefined, undefined, new defaultValue('', undefined, undefined, undefined, undefined, undefined, undefined, fileSize, ['documentSize'])));
    }

    getFreightMeta() {
        this.freightsMeta = [];
        this.freightsMeta.push({field: 'freightType', header: 'Freight Type', required: true, type: 'dropdown', mandatory: true, listValues: this.getFreightTypeList()});
        this.freightsMeta.push({field: 'shippingMode', header: 'Shipping Mode', required: true, type: 'dropdown', mandatory: true, listValues: this.getShippingModeList()});
        this.freightsMeta.push({field: 'movementType', header: 'Movement Type', required: true, type: 'dropdown', listValues: this.getMovementTypeList()});
        this.freightsMeta.push({field: 'originCountry', header: 'Origin Country', required: true, mandatory: true, type: 'dropdown', listValues: this.locationList});
        this.freightsMeta.push({field: 'destinationCountry', header: 'Destination Country', required: true, type: 'dropdown', listValues: this.locationList});
        this.freightsMeta.push({field: 'containerType', header: 'Container Type', required: true, type: 'dropdown', mandatory: true, listValues: this.getContainerType()});
        this.freightsMeta.push({field: 'noOfContainers', header: 'Number of Containers', required: true, mandatory: true, type: 'spinner'});
        this.freightsMeta.push({field: 'commodity', header: 'Commodity', required: true, type: 'dropdown', listValues: this.userCommodityList});
        this.freightsMeta.push({field: 'incoterm', header: 'Incoterm', required: true, type: 'dropdown', listValues: this.incotermList});
        this.freightsMeta.push({field: 'hsCode', header: 'HS Code', required: true, type: 'text'});
        this.freightsMeta.push({field: 'currency', header: 'Currency', required: true, type: 'dropdown', listValues: this.commonService.getCurrencyList()});
        this.freightsMeta.push({field: 'personInCharge', header: 'Person In Charge', required: true, type: 'dropdown', listValues: this.userPersonInChargeList});
    }

    getReceiptMeta() {
        this.receiptsMeta = [];
        this.receiptsMeta.push({field: 'voyageNo', header: 'Voyage Number', required: true, mandatory: true, type: 'text'});
        this.receiptsMeta.push({field: 'bookingDate', header: 'Freight Booking Date', required: true, type: 'date'});
        this.receiptsMeta.push({field: 'freightForwarder', header: 'Freight Forwarder', required: true, type: 'text'});
        this.receiptsMeta.push({field: 'stowagePlan', header: 'Stowage Plan', required: true, type: 'text'});
        this.receiptsMeta.push({field: 'loadPort', header: 'Load Port', required: true, type: 'dropdown', mandatory: true,listValues:this.portList});
        this.receiptsMeta.push({field: 'unloadPort', header: 'Unload Port', required: true, type: 'dropdown', mandatory: true,listValues:this.portList});
        this.receiptsMeta.push({field: 'portCutOffDate', header: 'Port Cut-Off Date', required: true, type: 'date'});
        this.receiptsMeta.push({field: 'siCutOffDate', header: 'SI Cut-Off Date', required: true, type: 'date'});
        this.receiptsMeta.push({field: 'preferredDepo', header: 'Preferred Depo', required: true, type: 'text'});
    }

    getVoyageMeta() {
        this.voyageMeta = [];
        this.voyageMeta.push({field: 'voyageNo', header: 'Voyage Number', required: true, mandatory: true, type: 'text'});
        this.voyageMeta.push({field: 'bookingDate', header: 'Freight Booking Date', required: true, type: 'date'});
        this.voyageMeta.push({field: 'charterParty', header: 'Charter Party', required: true, type: 'text'});
        this.voyageMeta.push({field: 'stowagePlan', header: 'Stowage Plan', required: true, type: 'text'});
        this.voyageMeta.push({field: 'loadPort', header: 'Load Port', required: true, type: 'dropdown', mandatory: true,listValues:this.portList});
        this.voyageMeta.push({field: 'unloadPort', header: 'Unload Port', required: true, type: 'dropdown', mandatory: true,listValues:this.portList});
        this.voyageMeta.push({field: 'laycanFrom', header: 'Laycan From', required: true, type: 'date'});
        this.voyageMeta.push({field: 'laycanTo', header: 'Laycan To', required: true, type: 'date'});
    }

    getVesselMeta() {
        this.vesselMeta = [];
        this.vesselMeta.push({field: 'vesselName', header: 'Name', required: true, type: 'dropdown',  mandatory: true,listValues:this.vesselList});
        this.vesselMeta.push({field: 'vesselCapacity', header: 'Capacity', required: true, type: 'text'});
        this.vesselMeta.push({field: 'vesselEtd', header: 'ETD', required: true, type: 'date'});
        this.vesselMeta.push({field: 'vesselEta', header: 'ETA', required: true, type: 'date'});
    }

    navOptionClick(option: any, index) {
        this.selectedNav = option['name'];
        this.goToField(this.selectedNav);
    }

    getNavClass(option) {
        return option['name'] === this.selectedNav ? 'sectionMenuItemSelected' : 'sectionMenuItem';
    }

    onClickAttachment(value) {
    this.attachmentData.push(value);
    }

    changeRouteToBack() {
        this.onBackEvent.emit();
    }

    goToField(field: string) {
        smoothScrollIntoView(document.getElementById(field));
        document.getElementById(field).focus();
    }

    onRefreshFromGroupCompleteLoadPort() {

    }

    onNewRowAddCompleteLoadPort() {

    }

    onChangeValueLoadPort(value: any) {

    }

    onNewRowAddCompleteUnloadPort() {

    }

    onRefreshFromGroupCompleteUnloadPort() {

    }

    onChangeValueUnloadPort(value: any) {

    }

    onFromGroupStatusChangeLoadPort(value: any) {
        this.loadTableValid = value['status'];
    }

    onFromGroupStatusChangeUnloadPort(value: any) {
        this.unloadTableValid = value['status'];
    }

    onFormGroupCreatedUnload(form: FormGroup) {
        this.formGroup = this.formControlService.addFormGroupControl('unloadPortDetails',form).build();
    }

    onSave() {
        let url = '';
        let _this = this;
        let valid = this.formControlService.getFormGroup().valid;
        if(!valid) {
             this.showToast(this.formControlService.getErrors(this.commonService),"error")
        } else {
            let payload = this.formControlService.getFormGroup().value;
            payload['tenantId'] = this.commonService.getTenantId();
            payload['loadDetails'] = this.loadPortValues;
            payload['unloadDetails'] = this.unloadPortValues;
            payload['attachmentData'] = this.attachmentData;
            payload['bookingNumber'] = this.formControlService.getValue('voyageNo');    
            if (this.validCheck()) {
                _this.showToast('Required Fields are blank.', 'error');
            } else {
                url = environment.base_url + '/api/bookingmanagement/v1/savebookingvalue?action=Save';
                this.commonService.post(url, payload).subscribe(next => {
                    _this.showToast('Details Saved Successfully.', 'success');
                    _this.changeRouteToBack();
                }, (error: HttpErrorResponse) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: this.commonService.getHttpErrorMessage(error, 'Future Market Dashboard')
                    });
                });
            }
        }
    }

    onUpdate() {
        let url = '';
        let _this = this;
        let payload = this.formControlService.getFormGroup().value;
        payload['uuid'] = this.bookingValue['uuid'];
        payload['tenantId'] = this.commonService.getTenantId();
        payload['loadDetails'] = this.loadPortValues;
        payload['unloadDetails'] = this.unloadPortValues;
        payload['attachmentData'] = this.attachmentData;
        payload['bookingNumber'] = this.bookingValue['bookingNumber'];
        url = environment.base_url + '/api/bookingmanagement/v1/savebookingvalue?action=Update';
        this.commonService.post(url, payload).subscribe(next => {
            _this.showToast('Details Saved Successfully.', 'success');
            _this.changeRouteToBack();
        }, (error: HttpErrorResponse) => {
            this.messageService.add({
                severity: 'error',
                summary: this.commonService.getHttpErrorMessage(error, 'Future Market Dashboard')
            });
        });
    }

    showToast(msg, severity: string = 'info') {
        this.messageService.add({
            severity: severity,
            summary: msg
        });
    }

    validCheck() {
        let validValue = this.formControlService.getFormGroup().value;
        if(validValue['shippingMode'] === 'Bulk Vessel') {
            if(validValue['freightType'] === '' ||
                validValue['shippingMode'] === '' ||
                validValue['originCountry'] === '' ||
                validValue['loadPort'] === '' ||
                validValue['unloadPort'] === '' ||
                validValue['vesselName'] === '') {
                return true;
            }
            return false;
        } else {
            if(validValue['freightType'] === '' ||
                validValue['shippingMode'] === '' ||
                validValue['originCountry'] === '' ||
                validValue['loadPort'] === '' ||
                validValue['unloadPort'] === '' ||
                validValue['vesselName'] === '' ||
                validValue['containerType'] === '' ||
                validValue['noOfContainers'] === '') {
                return true;
            }
            return false
        }
    }

    onEdit() {
        this.showUpdate = true;
        this.showEdit = false;
        this.editing = true;
        if(this.formControlService.getValue('shippingMode')=='Bulk Vessel'){
                    this.formControlService.disable('noOfContainers');
                    this.formControlService.disable('containerType');
        }
        else{
            this.formControlService.enable('noOfContainers');
            this.formControlService.enable('containerType');
        }
    }

    selectFile() {
        let element: HTMLElement = document.querySelector('input[type="file"]') as HTMLElement;
        element.click();
    }

    openUploadFile() {
        this.displayFile = true;
    }

    onCloseFile() {
        this.displayFile = false;
    }

    onItemCardClick() {
        this.loadPortValues = [];
        this.unloadPortValues = [];
        this.attachmentData = [];
        this.commonService.getJSONByURL(environment.base_url + '/api/bookingmanagement/v1/getdetailsbybookingnumber?tenantId='
            + this.commonService.getTenantId() + '&bookingNumber=' + this.bookingNumber).subscribe((next: any[]) => {
            this.bookingValue = next;
            if(this.bookingValue !== undefined && this.bookingValue !== null) {
                this.loadPortValues = this.bookingValue['loadDetails'];
                this.unloadPortValues = this.bookingValue['unloadDetails'];
                this.attachmentData = this.bookingValue['attachmentData'];
                this.isBulk = (next['shippingMode'] === 'Break Bulk' || next['shippingMode'] === 'Bulk Vessel');
            }
        });
    }

    private getFreightTypeList() {
        let freightList = [];
        freightList.push(new KeyValue('Select', ''));
        freightList.push(new KeyValue('Sea', 'Sea'));
        freightList.push(new KeyValue('Road', 'Road'));
        freightList.push(new KeyValue('Rail', 'Rail'));
        freightList.push(new KeyValue('Air', 'Air'));
        return freightList;
    }

    private getShippingModeList() {
        let shippingmodeList = [];
        shippingmodeList.push(new KeyValue('Select', ''));
        shippingmodeList.push(new KeyValue('FCL', 'Ocean FCL'));
        shippingmodeList.push(new KeyValue('LCL', 'Ocean LCL'));
        shippingmodeList.push(new KeyValue('Bulk Vessel', 'Bulk Vessel'));
        return shippingmodeList;
    }

    private getMovementTypeList() {
        let movementTypeList = [];
        movementTypeList.push(new KeyValue('Select', ''));
        movementTypeList.push(new KeyValue('Door to Door', 'Door to Door'));
        movementTypeList.push(new KeyValue('Door to Port', 'Door to Port'));
        movementTypeList.push(new KeyValue('Port to Port', 'Port to Port'));
        movementTypeList.push(new KeyValue('Port to Door', 'Port to Door'));
        return movementTypeList;
    }

    private getContainerType() {
        let containerTypeList = [];
        containerTypeList.push(new KeyValue('Select', ''));
        containerTypeList.push(new KeyValue('20Ft General Purpose', '20"Ft General Purpose'));
        containerTypeList.push(new KeyValue('40Ft General Purpose', '40 Ft General Purpose'));
        containerTypeList.push(new KeyValue('40Ft High Cube', '40Ft High Cube'));
        return containerTypeList;
    }

    onFormGroupCreatedLoad(form: FormGroup) {
        this.formGroup = this.formControlService.addFormGroupControl('loadPortDetails',form).build();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes['resetForm'] && changes['resetForm'].currentValue) {
            if(this.formControlService.getFormGroup() != undefined && this.formControlService.getFormGroup() != null) {
                this.formControlService.getFormGroup().reset();
                this.onFormResetComplete.emit();
            }
        }
    }

    openAttachment(event: MouseEvent) {
        if(this.selectedAttachment.length === 1){
            event.preventDefault();
            window.open(this.getAttachmentUrl(), "_blank");
        }
    }

    getAttachmentUrl() {
        return environment.base_url + this.url;
    }

    onRowSelect(value:any) {
        this.selectedAttachment = value;
        this.url = value[0]['attachmentUrl'];
    }
}
