<div class="sectionCardTop">
  <div class="breadcrumbPane">
    <ctrm-breadcrumb [collapsabel]="false"
                     [items]="breadCrumbService.getBreadCrumb()"
                     [showIcon]="false"></ctrm-breadcrumb>
  </div>
  <div class="actionPane">
    <div class="screenDetails">
      <img [src]="'../../../assets/images/svg/tradePlanning.svg'"/>
      <p>FX Trade Planning</p>
    </div>
    <div class="autocomplete-box">
      <input (input)="getFilterValue($event)" placeholder="Search" type="text">
      <mat-icon style="color:#cbcbcb">search</mat-icon>
    </div>

    <div *ngIf="tabValue == 'planned'" class="rightInfo">
      Total {{planData.length}} Plans
    </div>
  </div>
</div>

<div class="sectionCardTop" style="max-height: 85% !important;">
  <div class="selectButtonClass">
    <p-selectButton (onChange)="onChange($event)" [(ngModel)]="tabValue" [options]="tabOptions"
                    (onOptionClick)="onTabChange($event)"></p-selectButton>
  </div>

  <div *ngIf="tabValue === 'unplanned'" style="margin-left: 10px;">
    <ctrm-button (onClick)="fxObligatiobRefresh()" icon="fas fa-redo" label="Refresh"></ctrm-button>
  </div>

  <!--Tabs on the top of the grid-->
  <div *ngIf="tabValue === 'unplanned'">
    <!--FX Trade and Obligation Grid Section-->
    <div #fxTrade class="fxTradeContainer">
      <ctrm-planning-grid (onUniqueKeyClick)="openFxInfo($event)"
                          (selectedRowsValue)="onRowSelect($event,'fxTrade')"
                          (total)="onTotalChange($event,'fxTrade')"
                          [balanceCalculationFunction]="balanceFunc"
                          [balanceFieldName]="'balanceAmount'"
                          [checkedSingleRow]="true" [cols]="fxCols"
                          [filterValue]="globalFilterValue" [gridPosition]="'left'"
                          [mainQuantityField]="'notionalValue'"
                          [matchFieldName]="'matchAmount'"
                          [noDataLabel]="'No Confirmed Cash/Forward Trade available to Plan'"
                          [noDataRoute]="'/cashForward'"
                          [primaryKey]="'fxTradeId'" [routeToLable]="'Create Cash/Forward Trade'" [value]="fxTradeValue"
                          style="width: 49.9%"
                          [negativeValueError]="'Match Amount is greater than Balance Notional Amount'"
                          (disableEmitter)="disableButton($event)"
                          [filterOptionsList]="filterListFx"
                          (onFilterApply)="filterForFxTrade($event)"
                          [refreshGrid]="onRefreshFX"
                          (onRefreshComplete)="onRefreshFX = false"
                          (clearOnFilter)="clearFilter('FX')"
                          [totalElements]="totalElementsFx"
                          [totalPages]="totalPagesFx" [pageSize]="pageSizeFx"
                          (onChangePage)="onChangePageFx($event)" [mode]="'Left'" [reset]="resetFx"
                          [heading]="'FX Trade'" [totalHeading]="'Total FX : '"
                          [totalMatchHeading]="'Total Notional Value'"
                          [totalMatch]="fxTradeTotal"
      ></ctrm-planning-grid>
      <div class="dividerLine"></div>
      <ctrm-planning-grid (onUniqueKeyClick)="openTradeInfo($event)" (selectedRowsValue)="onRowSelect($event,'obligation')"
                          (total)="onTotalChange($event,'obligation')" [balanceCalculationFunction]="balanceRightFunc"
                          [balanceFieldName]="'balanceAmount'"
                          [cols]="tradeObligationCols"
                          [filterValue]="globalFilterValue" [gridPosition]="'right'"
                          [key]="'plannedObligationId'"
                          [mainQuantityField]="'obligationPlannedNotionalValue'" [matchFieldName]="'matchAmount'"
                          [noDataLabel]="'No Confirmed Physical Trade available to Plan'" [noDataRoute]="'/trade'"
                          [primaryKey]="'plannedObligationId'" [routeToLable]="'Create/Confirm Physical Trade'"
                          [value]="tradeObligationValue"
                          style="width: 49.9%"
                          [filterOptionsList]="filterListTrade"
                          (onFilterApply)="filterForTrade($event)"
                          [refreshGrid]="onRefreshTrade"
                          (onRefreshComplete)="onRefreshTrade = false"
                          (clearOnFilter)="clearFilter('TRADE')"
                          [negativeValueError]="'Match Amount is greater than Balance Obligation Notional Amount'"
                          (disableEmitter)="disableButton($event)"
                          [heading]="'Trade Obligations'" [totalHeading]="'Total Obligations : '"
                          [totalMatchHeading]="'Total Obligation Value'"
                          [totalElements]="totalElementsTrade" [totalPages]="totalPagesTrade" [pageSize]="pageSizeTrade"
                          (onChangePage)="onChangePageTrade($event)" [reset]="resetTrade"
                          [totalMatch]="tradeObligationTotal"></ctrm-planning-grid>

    </div>
    <div class="matchPlanButtonDiv"><button
            (click)="checkMatch()"
            [disabled]="(fxTradeValue === undefined || fxTradeValue === null || fxTradeValue.length === 0 ||
                tradeObligationValue === undefined || tradeObligationValue === null || tradeObligationValue.length === 0 ||
                fxTradeTotal === 0 || tradeObligationTotal === 0 || fxTradeTotal !== tradeObligationTotal)"
            class="planScreenBtns">Link FX To Trade Obligation
    </button></div>
  </div>


  <!--Planned table-->
  <div *ngIf="tabValue !== 'unplanned'">
    <div class="planningPanel">
      <div class="toolbar">
        <div>
          <ctrm-button (onClick)="planRefresh()" icon="fas fa-redo" label="Refresh"></ctrm-button>

          <div class="float-right">
<!--            <ctrm-button (onClick)="onRowDelete(selectedPlans)"-->
<!--                         [disabled]="selectedPlans.length === 0 || selectedPlans.length > 1" [label]="'Delete'"-->
<!--                         icon="fas fa-trash"></ctrm-button>-->
            <ctrm-button [disabled]="selectedPlans.length === 0 || selectedPlans.length > 1" icon="fas fa-file-export"
                         label="Export"></ctrm-button>
            <ctrm-button [disabled]="selectedPlans.length === 0 || selectedPlans.length > 1" icon="fas fa-print"
                         label="Print"></ctrm-button>
          </div>
        </div>
      </div>

      <div>
        <p-table #fxdetailTable (inViewportAction)="calculateHeight(fxdetailTable)"
                 (onRowExpand)="onExpandRow($event)" [(selection)]="selectedPlans"
                 [columns]="fxplanCols"
                 [style]="{width: '100%', maxHeight:fxTradeHeight, minHeight:fxTradeHeight}"
                 [expandedRowKeys]="expandedRows" [responsive]="true"
                 [value]="planData" autoLayout="true"
                 (onRowSelect)="onFXPlanRowSelect()"
                 class="style-4 table-responsive" columnResizeMode="fit"
                 dataKey="fxTradePlanId"
                 inViewport selectionMode="checkbox">
          <ng-template let-columns pTemplate="header">
            <tr>
              <th style="width: 3em">
                <p style="background-color: #D9DBDE; padding: 5px; text-align: center;">
                </p>
              </th>
              <th *ngFor="let col of columns;index as i">
                <div style="background-color: #D9DBDE; padding-top:5px; padding-bottom: 5px;">
                  <p [ngClass]="getClassForHeading(i)">{{col.header}}</p>
                </div>
              </th>
            </tr>
          </ng-template>
          <ng-template let-columns="columns" let-expanded="expanded" let-ri="rowIndex" let-rowData pTemplate="body">
            <tr>
              <td class="customRow" style="width:3em;display: inline-flex">
                <p class="plannedGridChkBox">
                  <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                </p>
                <p class="rowTogglerStyle">
                  <a [pRowToggler]="rowData" href="#">
                    <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" class="togglerIcon"></i>
                  </a>
                </p>
              </td>
              <td *ngFor="let col of columns" class="customRow">
                <p *ngIf="col.field !== 'plannedMatching' && col.field !== 'tradeType' && col.field !== 'fxTradeType'"
                   class="plannedGrid">
                  {{commonService.getRowValue(rowData, col)}}
                </p>
                <p *ngIf="col.field === 'plannedMatching'" class="matchAlgoStyle">
                  {{commonService.getRowValue(rowData, col)}}
                </p>
                <div *ngIf="col.field === 'tradeType'">
                  {{getFxTradeType(rowData[col.field])}}
                </div>
                <div *ngIf="col.field === 'fxTradeType'">
                  {{rowData[col.field]}}
                </div>

              </td>
            </tr>
          </ng-template>
          <ng-template let-columns="columns" let-ri="rowIndex" let-rowData pTemplate="rowexpansion">
            <tr>
              <td [colSpan]="columns.length + 2">
                <div class="planningInfoContainer">
                  <app-planning-info (onClickClickable)="onClickClickable($event)" (onClickUniqueKey)="onUniqueClick($event)"
                                     (onSelectedRows)="onSelectRows($event,ri)"
                                     [clickableFields]="['tradeId','fxTradeId']"
                                     [columns]="fxPlannedCols"
                                     [selectedRows]="rowData['selectedChilds']" [showChkBox]="true"
                                     [value]="rowData['obligationFxAllocationList']"></app-planning-info>
                </div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr *ngIf="(planData === undefined ||planData === null || planData.length === 0)">
              <td [colSpan]="fxplanCols.length + 2">
                <div class="placeHolder">
                  <div (inViewportAction)="calculateLeft()" [style]="{left:noDataBoxLeft}" class="noDataBox" inViewport>
                    <img [src]="commonService.getIconPath('emptyStateIcon')" class="iconClass"/>
                    <div class="noPlans">{{loadingMessage}}</div>
                    <div *ngIf="!isRefreshing">
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <p-paginator *ngIf="getPagination()" [rows]="20" [totalRecords]="totalElements" (onPageChange)="onPageChange($event)"></p-paginator>
      </div>
    </div>
  </div>
</div>
<app-trade-info (onClose)="onClose($event)" *ngIf="display" [detailsOf]="'plannedObligation'"
                [display]="true" [idValue]="plannedObligationId" [rowData]="oblRow" [sidebarPosition]="tradeInfoPos"
                [tradeId]="tradeId"></app-trade-info>
<app-fx-planning-info (onClose)="onFxClose($event)" *ngIf="fxdisplay" [display]="true" [fxTradeId]="fxTradeId"
                      [rowData]="rowData" [sidebarPosition]="'right'"></app-fx-planning-info>

<p-dialog (onHide)="closeMatchDialog()" [(visible)]="matchDisplay" [draggable]="false" [modal]="true"
          [resizable]="false" [style]="{width: '35vw'}">
  <p-header>
    <div style="display: flex; padding-left: 16px">
      <i class="far fa-check-square headerIconStyle"></i>
      <p class="matchHeader">FX Planning</p>
    </div>

  </p-header>
  <div class="infoContainer">
    <!--    Error Section-->
    <div *ngIf="errorMessage.length > 0">
      <div class="errorClass">
        <i class="fas fa-times-circle"></i>
        <p>ERROR</p>
      </div>
      <p *ngFor="let msg of errorMessage" class="infoMessageClass">{{msg}}</p>
    </div>
    <div *ngIf="warningMessage.length > 0" style="padding-bottom: 8px">
      <div class="warningClass">
        <i class="fas fa-exclamation-circle"></i>
        <p>WARNING</p>
      </div>
      <p *ngFor="let msg of warningMessage" class="infoMessageClass">{{msg}}</p>
    </div>

    <!--    select availaible algo-->
    <p *ngIf="warningMessage.length == 0 && errorMessage.length == 0" class="infoMessageClass">Match Successful.</p>
  </div>


  <p-footer>
    <div>
      <button (click)="closeMatchDialog()" class="cancelBtn">Cancel</button>
      <button (click)="continuePlanning()" [class.planScreenBtnsDisabled]="getContPlanValidation()"
              [disabled]="getContPlanValidation()" class="planScreenBtns">Continue
        Planning
      </button>
    </div>

  </p-footer>
</p-dialog>

<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>
<p-confirmDialog [baseZIndex]="25" header="Confirmation"
                 icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-sidebar [(visible)]="plannedDisplay" [baseZIndex]="23" [modal]="false" [showCloseIcon]="false"
           [styleClass]="'bottomPane'" position="bottom">
  <div class="bottomSidebarContainer">
    <div class="leftSection">
      <i (click)="plannedDisplay = !plannedDisplay" class="material-icons closable">clear</i>
    </div>
    <div class="middleSection">
      <i class="fas fa-file-export middleIcons" pTooltip="Export" tooltipPosition="top" tooltipZIndex="9999"></i>
      <i class="fas fa-print middleIcons" pTooltip="Print" tooltipPosition="top" tooltipZIndex="9999"></i>
    </div>
    <div class="rightSection">
      <button (click)="onRowDelete(selectedPlans)" class="planScreenBtns" style="margin: 0">Delete</button>
    </div>
  </div>
</p-sidebar>

<ng-template>
  <div *ngIf="planData.length == 0 || planData.length == null" style="text-align: center;">
    <div class="noPlans">No Planned Record</div>
    <div>
      <button (click)="switchTab('unplanned')" class="planScreenBtns">Create Plan</button>
    </div>
  </div>
</ng-template>
