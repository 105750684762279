import {AfterViewInit, ChangeDetectorRef, Component, HostListener, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Tcolumn} from '../../../../grid/tcolumn.model';
import {environment} from '../../../../../environments/environment';
import {CommonService} from '../../../../services/common.service';
import {MessageService} from 'primeng';
import {defaultValue} from '../../../../grid/defaultValue.model';

@Component({
    selector: 'attachment',
    templateUrl: './attachment.component.html',
    styleUrls: ['./attachment.component.css']
})
export class AttachmentComponent implements OnInit, OnChanges, AfterViewInit {

    columns: Tcolumn[] = [];
    error: string;
    @Input() data: any;
    dragAreaClass: string;
    fileName: string = 'No File Chosen';
    files: File;
    @Input('value') val: any;
    fileToUpload: any;
    fileSize: any;
    fileType:any;
    selectedFiles:any[] = [];
    refreshAddNewRow: boolean = false;
    attachmentvalue: any[] = [];
    refreshFormGroup: boolean = false;
    url:any;

    onFileChange(event: any) {
        let filelist: FileList = event.target.files;
        this.saveFiles(filelist);
    }

    constructor(private changeDetectorRef: ChangeDetectorRef, public commonService: CommonService,
                private messageService: MessageService) {
    }

    get value() {
        return this.val;
    }

    set value(val) {
        this.val = val;
        this.onChange(val);
    }

    ngOnInit(): void {
        this.attachmentGrid();
        this.dragAreaClass = 'dragarea';
        this.getAttachment();
    }

    attachmentGrid() {
        let _this = this;
        let fileSize = function(value: any) {
            return _this.commonService.fileSizeConvert(value, true);
        };

        this.columns.push(new Tcolumn('attachmentName', 'Attachment Name', 'LB', 0, false, undefined));
        this.columns.push(new Tcolumn('createdBy', 'Uploaded By', 'LB', 1, false, undefined));
        this.columns.push(new Tcolumn('createdTimestamp', 'Uploaded Date', 'D', 2, false, undefined, undefined, undefined, 'B', undefined, undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.push(new Tcolumn('documentSize', 'File Size', 'LB', 3, false, undefined, undefined, new defaultValue('', undefined, undefined, undefined, undefined, undefined, undefined, fileSize, ['documentSize'])));
    }

    ngAfterViewInit() {
        this.changeDetectorRef.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['fileName']) {
            if (changes['fileName'].currentValue === undefined || changes['fileName'].currentValue === null || changes['fileName'].currentValue.toString().length == 0) {
                this.fileName = 'No File Chosen';
            }
            this.fileName = this.refractorFileName();
        }
    }

    onChange(val) {
        this.val = val;
    }

    @HostListener('dragover', ['$event']) onDragOver(event: any) {
        this.dragAreaClass = 'droparea';
        event.preventDefault();
    }

    @HostListener('dragenter', ['$event']) onDragEnter(event: any) {
        this.dragAreaClass = 'droparea';
        event.preventDefault();
    }

    @HostListener('dragend', ['$event']) onDragEnd(event: any) {
        this.dragAreaClass = 'dragarea';
        event.preventDefault();
    }

    @HostListener('dragleave', ['$event']) onDragLeave(event: any) {
        this.dragAreaClass = 'dragarea';
        event.preventDefault();
    }

    @HostListener('drop', ['$event']) onDrop(event: any) {
        this.dragAreaClass = 'dragarea';
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.files) {
            let files: FileList = event.dataTransfer.files;
            this.saveFiles(files);
        }
    }

    refractorFileName(filename: string = this.fileName, len = 20) {
        if (filename.length > len) {
            filename = filename.substring(0, len) + '...';
        }
        return filename;
    }

    onNewRowAddComplete() {
        this.refreshAddNewRow = false;
    }

    onRefreshFromGroupComplete() {
        this.refreshFormGroup = false;
    }

    saveFiles(filelist: FileList) {
        if (filelist.length > 1) {
            this.error = 'Only one file at time allow';
        } else {
            let _this = this;
            Object.values(filelist).forEach((file: File) => {
                _this.files = file;
            });
            this.onFileSelected(this.files);
        }
    }

    onFileSelected(files: File) {
        let _this = this;
        this.fileToUpload = '';
        this.fileName = '';
        this.fileSize = '';
        this.fileType= '';
        let reader = new FileReader();
        if (files !== undefined) {
            reader.readAsDataURL(files);
            reader.onload = () => {
                let str: any = reader.result;
                let split: any[] = str.split(',');
                _this.fileToUpload = split[1];
                _this.fileSize = files.size;
                _this.fileName = files.name;
                _this.fileType = files.type;
            };
        }
    }

    getAttachment() {
        let _this = this;
        if (this.attachmentvalue === undefined || this.attachmentvalue === null || this.attachmentvalue.length === 0) {
            this.attachmentvalue = [];
        }
        this.commonService.getJSONByURL(environment.base_url + '/api/plannedObligation/v1/getattachmentonamend?tenantId='
            + this.commonService.getFromStorage('tenantId') + '&plannedObligationId=' + this.data['plannedObligationId']).subscribe((next: any) => {
            _this.attachmentvalue = next;
            _this.refreshFormGroup = true;
            _this.refreshAddNewRow = true;
        });
    }

    private getPayload() {
        return {
            businessApplicationId: this.data['plannedObligationId'],
            commodity: this.data['commodity'],
            company: this.data['company'],
            counterparty: this.data['counterparty'],
            documentSize: this.fileSize,
            document: this.fileToUpload,
            attachmentName: this.fileName,
            incoterm: this.data['incoterm'],
            transactionType: this.data['tradeTransactionType'],
            attachmentFileType:this.fileType
        };
    }

    openUploadFile() {
        if(this.fileToUpload !== undefined && this.fileToUpload !== null && this.fileToUpload !== '') {
            let payload = this.getPayload();
            this.commonService.getJSONByObject(environment.base_url + '/api/plannedObligation/v1/uploadattachmentonamend?tenantId=' + this.commonService.getFromStorage('tenantId'), payload).subscribe((next: any) => {
                this.showToast('Attachment Saved Successfully.', 'success');
                this.fileName = 'No File Chosen';
                this.refreshFormGroup = true;
                this.refreshAddNewRow = true;
                this.getAttachment();
                this.fileToUpload = '';
            }, error => {
                this.messageService.add({severity: 'error', summary: this.commonService.getHttpErrorMessage(error, undefined)});
            });
        } else {
            this.showToast('No File found to upload.');
        }
    }

    showToast(msg, severity: string = 'success') {
        this.messageService.add({
            severity: severity,
            summary: msg
        });
    }

    onDelete(value: any) {
        this.commonService.getJSONByURL(environment.base_url + '/api/plannedObligation/v1/deleterowonamend?tenantId=' + this.commonService.getFromStorage('tenantId')
            + '&plannedObligationId=' + value['rowData']['businessApplicationId']
            + '&uuid=' + value['rowData']['uuid']).subscribe((next: any) => {
            this.showToast('Deleted Successfully.');
            this.refreshFormGroup = true;
            this.refreshAddNewRow = true;
            this.selectedFiles =[];
        }, error => {
            this.messageService.add({severity: 'error', summary: this.commonService.getHttpErrorMessage(error, undefined)});
            this.selectedFiles =[];
        });

    }

    onRowSelect(value:any) {
        this.url = value['attachmentUrl'];
    }

    getAttachmentUrl() {
        return environment.base_url + this.url;
    }

    openAttachment(event: MouseEvent) {
        if(this.selectedFiles.length === 1){
            event.preventDefault();
            window.open(this.getAttachmentUrl(), "_blank");
        }
    }

    onSelectionChange(rows: any[]) {
        this.selectedFiles = rows;
    }

    onClickClickable(value: {col:Tcolumn,data:any}) {
        if(value.col.getField() === 'attachmentName') {
            window.open(environment.base_url + value.data.attachmentUrl,"_blank");
        }
    }
}
