<div class="slidecontainer">
    <div class="d-inline-flex w-100">
        <label class="leftLabel flex-fill">{{minlabel}}</label>
        <label class="rightLable flex-fill text-right">{{maxlabel}}</label>
    </div>
    <mat-slider
            class="example-margin"
            thumbLabel
            [disabled]="disabled"
            [max]="max"
            [min]="min"
            [(ngModel)]="value"
            [vertical]="vertical"
            aria-labelledby="example-name-label">
    </mat-slider>
</div>

