import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonService} from '../../../services/common.service';

@Component({
    selector: 'ctrm-button',
    templateUrl: './ctrm-button.component.html',
    styleUrls: ['./ctrm-button.component.css']
})
export class CtrmButtonComponent implements OnInit, AfterViewInit {

    @Input() label: string = '';
    @Input() icon: string = '';
    @Input() iconPos: string = 'left';
    @Input() type: string = 'button';
    @Input() disabled: boolean = false;
    @Input() accessKey: string = '';
    @Output() onClick = new EventEmitter();
    @Input() access: string = '';
    @Input() action: string = '';

    constructor(private changeDetectorRef: ChangeDetectorRef, public commonService: CommonService) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this.changeDetectorRef.detectChanges();
    }

    onPressClick(event: MouseEvent) {
        this.onClick.emit(event);
    }

    onReject(value: any) {
    }
}
