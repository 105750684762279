import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, NgForm, ValidationErrors, Validators} from '@angular/forms';
import {Grid} from './grid.model';
import {Tcolumn} from './tcolumn.model';
import {GridService} from './grid.service';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {Table} from 'primeng/table';
import {Breadcrumb} from '../Components/ctrm-breadcrumb/breadcrumb.model';
import {BreadcrumbService} from '../Components/ctrm-breadcrumb/breadcrumb.service';
import {FilterService} from './preference/filter/filter.service';
import {CommonService} from '../services/common.service';
import {PivotServiceService} from '../Components/ctrm-pivot-table/pivot-service.service';
import {KeyValue} from './key-value.model';
import {Action} from './actions.model';
import {defaultValue} from './defaultValue.model';
import {pconfig} from '../Components/ctrm-pivot-table/pconfig.model';
import {Tab} from '../tab-grid/Tab.model';
import {DatePipe} from '@angular/common';
import {messages} from '../services/common/messages';
import {UtilService} from '../ctrm-input-form/services/util.service';
import {MasterService} from '../masters/services/MasterService';
import {FilterUtils} from 'primeng/utils';
import {AutoComplete, ConfirmationService, MenuItem, MessageService, Paginator} from 'primeng';
import {Filter} from './preference/filter/filter.model';
import {HttpErrorResponse} from '@angular/common/http';
import {Button} from './buttons.model';
import {ExcelServiceService} from '../services/excel-service.service';
import {CtrmInputFormComponent} from '../ctrm-input-form/ctrm-input-form.component';
import {Subscription} from 'rxjs';
import {AccessPolicyService} from '../services/accesspolicy.service';
import {OverlayPanel} from "primeng/overlaypanel";


@Component({
  selector: 'ctrm-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.css'],
  styles: [`
    .inactive {
      background-color: rgb(209, 209, 209) !important;
      color: #000 !important;
    }
  `
  ],
  providers: [MessageService, ConfirmationService, FilterService]
})
export class GridComponent implements OnInit, OnChanges, AfterViewInit {
  @Input('grid') grid: Grid;
  @Input() performAction: boolean = false;
  @Input() formToOpen: string = '';
  @Input() showStandardFilter: boolean = false;
  @Input() sortable:boolean = true;
  @Input() showSortIcon:boolean = true;
  @Input() fieldsWithCustomView:any[] =[];
  @Input() fieldsWithCustomViewInExpand:any[] =[];
  @Input() customViewTemplated:TemplateRef<any>[] =[];
  @Input() customViewTemplatedInExpand:TemplateRef<any>[] =[];
  @Input() expandRowCustomTemplate:TemplateRef<any>;
  @Input() rightAlignNumberFields:boolean = false;
  @Input() openInEditMode:boolean = false;
  @Input() getFromUrl: boolean = true;
  @Input() refreshGrid: boolean = false;
  @Input() showExpandGridIcon:boolean = false;
  @Input() expandButtonIcon:string = 'pi pi-chevron-right';
  @Input() collapseButtonIcon:string = 'pi pi-chevron-down';
  @Output() onClickExpandKey = new EventEmitter();
  @Input() saveOnSave: boolean = true;
  @Input() showFormOnModal: boolean = false;
  @Input() showFilterChips: boolean = true;
  @Input() showToolbar: boolean = true;
  @Input() autoCalculateHeight: boolean = true;
  @Input() oldEditableFields: any[] = [];
  @Input() oldEditableFieldsFunction: Function;
  @Output() onExpandRowSelectionChange = new EventEmitter();
  @Input() loadData: any[] = [];
  @Input() onlyNewEditableMode: boolean = false;
  @Input() showBreadcrumb: boolean = true;
  @Input() extraButtons: any;
  @Input() canGoNext: boolean = false;
  @Input() gridFilterDropdownList:any[] = [];
  @Input() showActionDelete: boolean = true;
  @Input() deleteOnDelete: boolean = true;
  @Input() showFilterInToolbar: boolean = false;
  @Input() parentRowValue: any;
  @Input() parentUuidFieldName: string;
  @Input() toolbarConfig: any = {
    add: true,
    edit: true,
    copy: true,
    refresh: true,
    export: true,
    exportAll: true,
    import: true,
    audit: true,
    print: true,
    prefrences: true
  };
  contextMenuList: any[] = [];
  @Input() openInputForm: any;
  @Input() includeFieldsListForFormGroup:string[] =[];
  @Input() isOnSidebar: boolean = false;
  @Input() noDataTemplate: TemplateRef<any>;
  @Input() extraViewTemplate: TemplateRef<any>;
  @Input() customViewInToolbarRight: TemplateRef<any>;
  @Input() marginLeftExpandData: any = '50px';
  @Input() showDealSlip: boolean = false;
  @Output() onTableCreated = new EventEmitter();
  selectedTradeOption: any = '';
  @Input() showSectionTop: boolean = true;
  @Input() actionCheckboxStateFunction: Function;
  @Input() selectRowFunction: Function;
  @Input() showheaderCheckbox: boolean = true;
  @Input() showMasterInfo: boolean = false;
  @Input() refreshFilter: boolean = false;
  @Input() isViewOnly:boolean = false;
  @Output() modalChanged = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() onClickUniqueKey = new EventEmitter();
  @Output() onChangeValue = new EventEmitter();
  @Output() onRefreshComplete = new EventEmitter();
  @Output() onClickedRefresh = new EventEmitter();
  @Output() onChangeIndex = new EventEmitter();
  @Output() onChangeFieldValue = new EventEmitter();
  @Output() onRowDataSave = new EventEmitter();
  @Output() onChangeObjectCreated = new EventEmitter();
  @Output() onRowDataDelete = new EventEmitter();
  @Output() selectedRows = new EventEmitter();
  @Output() onButtonClick = new EventEmitter();
  @Output() onContextMenuItemClick = new EventEmitter();
  @Output() getRowData = new EventEmitter();
  @Output() onClickAdd = new EventEmitter();
  @Output() dealSlipEmitter = new EventEmitter();
  @Output() onSave = new EventEmitter();
  @Output() heirarchyEmitter = new EventEmitter();
  @Output() goToNext = new EventEmitter();
  @Input() defaultEdit: boolean = true;
  @Output() onClickEdit = new EventEmitter();
  @Output() onClickDuplicate = new EventEmitter();
  @Input() useCustomSort: boolean = false;
  @Input() customSortFunction:Function;
  @Output() listOfValuesEmitter = new EventEmitter();
  @Output() onDropdownFilterValueChange = new EventEmitter();
  @ViewChild('dt', {static: true}) table: Table;
  @ViewChild('noDataBoxId', {static: true}) noDataDiv: HTMLDivElement;
  @ViewChild('paginator', {static: true}) paginator: Paginator;
  @ViewChild('gf', {static: true}) globalFilter: AutoComplete;
  @ViewChild('gridForm', {static: true}) gridForm: NgForm;
  @ViewChild('gridinputform', {static: false}) gridinputform: CtrmInputFormComponent;
  @Input() breadcrumbMapKey = '';
  @Input() currentRoute = '/';
  gridFilter: Filter[];
  @Input() rowValues: any[] = [];
  @Input()  headerWidth: any = 'max-content';
  @Input()  customWidthColumnNames: any[] = [];
  @Input()  customWidthForColumn: any[] = [];
  rowDataValuesTemp: any[] = [];
  filter: boolean = true;
  selectedColumns: any[] = [];
  selectedPrefCols: any[] = [];
  columnsFromParent: Map<string, Tcolumn>;
  rowDataValues: any[] = [];
  @Input() columns: Tcolumn[] = [];
  filterColumns:Tcolumn[] = [];
  @Input() selectColumn:Tcolumn[] = [];
  clonedRowDataValues: any = [];
  displayAudit: boolean = false;
  primaryKey: string;
  @Input() selectedRow: any[];
  filteredValue: any[] = [];
  headerColumns: any[];
  isError: boolean = false;
  isCopying: boolean = false;
  changes: any = {};
  modalState = false;
  pageSize = 20;
  totalElements = 0;
  totalPages = 0;
  pageOffset: number = 0;
  private isPreparing:boolean = false;
  number = 0;
  currentEditingKeyValue: any;
  addButtonValue: any;
  currentEvent: any;
  newRowPrimaryKeyValues: any[] = [];
  currentContextMenu: any;
  currentRowData: any;
  filters: Filter[] = [];
  saveUrl: string = '';
  private canScrollFurthure:boolean = true;
  breadCrumbDone: string[] = [];
  breadCrumbNext: Breadcrumb[] = [];
  bulkUrl: string = '';
  editUrl: string = '';
  getUrl: string = '';
  isFrist: boolean = false;
  prefDefault: boolean = false;
  counter: number = 0;
  selectedValue: any;
  display: boolean = false;
  @Input() showFetching: boolean = false;
  url: string = environment.base_url;
  @Input() disabledRows:any[] =[];
  newRow: Map<number, boolean> = new Map();
  gridFrmGroup: FormGroup = new FormGroup({});
  columnSuggestionList: any = [];
  data: any;
  showPivot: boolean = false;
  @Input() numberboxwidth: any = '';
  isAdding: boolean = false;
  oldValue: any;
  rowDataValuescopy: any[] = [];
  editing: boolean = false;
  updatingRow:boolean = false;
  categoryTitle: string;
  config: any;
  rowGroupMetadata: any;
  tabHeading: string = '';
  tabModalState: boolean = false;
  parentKeyValue: any;
  @Input() showTitle: boolean = true;
  showTabs: boolean = false;
  updateRow: boolean = false;
  listOfValues: Map<any, any> = new Map();
  addInline: boolean = false;
  fieldIndexHasAction: any[] = [];
  frozenCols: any[] = [];
  statusFieldName: string = '';
  selectedPreference: any;
  datePipe: DatePipe;
  dateFormat: any = environment.dateFormat;
  min: number;
  max: number;
  autoCompleteValue: any = '';
  addNewModalState: boolean = false;
  refreshForm: boolean = false;
  preferenceSaveDialog:boolean = false;
  preferenceName:string = '';
  preferenceList: any[] = [];
  gridPrefData: any[] = [];
  columnsList: any[] = [];
  filterColumnList: any[] = [];
  filterDropDown: any[] = [];
  private sorts: any[] = [];
  private categoryList: any[];
  private lastCount: number = 0;
  private autoIncrementFields: any[] = [];
  private saveMessage: any = messages.saved.message;
  items: any[] = [];
  title: string;
  readonly: boolean = false;
  switchTabGrid: boolean = false;
  @Input() gridHeight: string = '';
  onChangeFields: string[] = ['B', 'D', 'ML', 'SL', 'LB', 'S', 'TN'];
  onFocusFields: string[] = ['T', 'N', 'P', 'TA', 'D', 'TP', 'L'];
  @Output() editAllowedOut = new EventEmitter();
  @Input() showPlaceHolder: boolean = true;
  @Input() setMinHeight: boolean = true;
  placeHolderHeight: string = '';
  noDataBoxLeft: string = '';
  @Input() showAddButtonInNoData: boolean = true;
  @Input() noDataRoute: string = '';
  @Input() routeToLable: string = '';
  @Input() noDataLabel: string = 'No Data Available';
  loadMessage: string = 'Loading ..';
  displayImport: boolean = false;
  importTableName: string = '';
  rowDataUpdateUrl: string = '';
  selectedFilterCol: any[] = [];
  filterActionList:any[] = [];
  selectedFilterAction: any[] = [];
  filterPanelDisplay: boolean = false;
  filterList: Filter[] = [];
  mainFilterArray: any[] = [];
  addedList: string[] = [];
  @Output() totalNumberElements = new EventEmitter();
  @Input() leftFixedColumns: any[] = [];
  @Input() leftFixedColumnsWidth: number[] = [];
  @Input() rightFixedColumns: any[] = [];
  @Input() rightFixedColumnsWidth: number[] = [];
  @Input() fixColumnsWidth: number = 200;
  @Input() showActions: boolean = true;
  @Input() dataFontSize: any = 13;
  @Input() updateBreadcrumb: boolean = true;
  private maxLength: number = 20;
  @Input() defaultCopy: boolean = true;
  @Input() extraObjectToSave: any = {};
  @Input() isTabGrid:boolean = false;
  @Output() onInlineEditClick = new EventEmitter();
  @Input() search: any;
  @Input() updatePayloadFunction:Function;
  @Input() recalculateGridHeight:boolean = false;
  @Output() onHeightCalculationDone = new EventEmitter();
  @Input() openFormForNewEntry:boolean = false;
  @Output() onFormOpenDone = new EventEmitter();
  @Input() autoLayout: boolean = true
  @Input() autoCalculateGridWidth: boolean = true;
  @Input() checkAccessPolicy: boolean = true;
  @Output() sendTotalElement = new EventEmitter();
  @Input()onApplyAdvancedFiltervalue:any[] = [];
  filterOnPageChange: Filter[];
  @Input() defaultColumnsForStdFilter: any[] = [];
  private subscription: Subscription;
  @Input() viewCreatedBy:boolean = true;
  //filter
  @Output() onFilterApply = new EventEmitter();
  filterValues:{} = {};
  appliedFilters:{} = {};
  pdisable:boolean = true;
  @Input() filterOptionsList: any[] = [];
  filterSelectCol: Tcolumn;
  allData = [];
  @Output() currentPageData = new EventEmitter();
  constructor(private formBuilder: FormBuilder,
              public accessPolicyService:AccessPolicyService,
              private gridService: GridService,
              private excelService: ExcelServiceService,
              private util: UtilService,
              private changeDetectorRef:ChangeDetectorRef,
              private messageService: MessageService,
              public route: Router, public breadCrumbService: BreadcrumbService,
              private service: FilterService, public commonService: CommonService, public ps: PivotServiceService,
              private confirmationService: ConfirmationService, public masterCommonService: MasterService) {
    var routeURI = this.route.url;
    var lastIndex = routeURI.lastIndexOf("/");
    if (lastIndex > 0 && (lastIndex + 1) < routeURI.length) {
      routeURI = routeURI.substring(0, lastIndex);
    }
    this.commonService.currentRoute = routeURI;
  }

  ngAfterViewInit(): void {
    this.calculateHeight();
    this.changeDetectorRef.detectChanges();
    this.onTableCreated.emit(this.table);
  }


  @HostListener("window:resize", [])
  public onResize() {
    this.calculateHeight();
  }

  calculateHeight() {
    if (this.autoCalculateHeight) {
      let offsetTop = this.table.el.nativeElement['offsetTop'];
      if (this.isOnSidebar) {
        offsetTop += 85;
      }
      let offsetHeight = 0;
      if (this.table.el.nativeElement['offsetParent'] !== undefined && this.table.el.nativeElement['offsetParent'] !== null) {
        offsetHeight = this.table.el.nativeElement['offsetParent']['offsetHeight'];
      }
      let extraHeight = 0;
      if(this.getPagination()) {
        extraHeight = 30;
      }
      this.gridHeight = offsetHeight - (offsetTop + 21 + extraHeight) + 'px';
      this.placeHolderHeight = offsetHeight - (offsetTop + 61) + 'px';
      this.onHeightCalculationDone.emit();
    }
    this.calculateLeft();
  }


  calculateLeft() {
    this.noDataBoxLeft = ((window['visualViewport'].width - 500) / 2) + 'px';
  }

  createColumnSuggesstionList() { //this for creating suggestion list for global search
    let list = [];
    this.columns.forEach(function (column) {
      if (column.isVisible() && column.getColumnType() !== 'D') {
        list.push(column.getHeader());
      }
    });
    this.columnSuggestionList = list;
  }

  isValidDate(value) {
    if (value === undefined) {
      return false;
    }
    return true;
  }

  createRowGroupMetaData(_this, grid: Grid) {  //this for creating metedata for pivot table
    _this.rowGroupMetadata = {};
    if (_this.rowDataValues) {
      for (let i = 0; i < _this.rowDataValues.length; i++) {
        let rowData = _this.rowDataValues[i];
        let brand = rowData[grid.getPivotConfig().categorizedBy];
        if (i == 0) {
          _this.rowGroupMetadata[brand] = {index: 0, size: 1};
        } else {
          let previousRowData = _this.rowDataValues[i - 1];
          let previousRowGroup = previousRowData[grid.getPivotConfig().categorizedBy];
          if (brand === previousRowGroup)
            _this.rowGroupMetadata[brand].size++;
          else
            _this.rowGroupMetadata[brand] = {index: i, size: 1};
        }
      }
    }
  }

  createDefaultSections() {
    let _this = this;
    this.columns.forEach(function (col: Tcolumn) {
      _this.grid.addInSection(_this.grid.getTitle(), col.getField());
    });
  }

  updateColumns(grid: Grid) {
    let _this = this;
    let i = 0;
    this.filterColumns = JSON.parse(JSON.stringify([]));
    if(this.grid.hiddenFieldsToShowInStandardFilters === null || this.grid.hiddenFieldsToShowInStandardFilters === undefined) {
      this.grid.hiddenFieldsToShowInStandardFilters = [];
    }
    grid.getColumn().forEach(function (data) {
      if (data.getVisibilityArea() !== 'N') {
        if (i <= 1) {
          _this.frozenCols.push(data);
        }
        if (data.getHeader() === 'Status') {
          _this.statusFieldName = data.getField();
        }
        if (typeof (data.getDefaultValue()) === 'object') {
          _this.updateValidator(data);
        }
        if (data.getVisibilityArea() === 'G' || data.getVisibilityArea() === 'B' ){
          _this.columns.push(data);
          _this.filterColumns.push(data);
        }
        i++;
      }
      if(_this.grid.hiddenFieldsToShowInStandardFilters.includes(data.getField())) {
        _this.filterColumns.push(data);
      }
      if (typeof (data.getDefaultValue()) === 'object' && data.getDefaultValue() !== undefined && data.getDefaultValue() !== null) {
        if (data.getDefaultValue()['autoIncrement']) {
          _this.autoIncrementFields.push(data);
        }
      }
    });

  }

  ngOnInit() {
    let _this = this;
    this.datePipe = new DatePipe('en-US');
    this.updateColumns(this.grid);
    this.util.sortByProperty(this.columns, 'placeholder', 'ASC');
    this.service.setCurrentScreen(this.grid.getDataURL());
    this.headerColumns = this.columns;
    if (this.updateBreadcrumb && this.showSectionTop) {
      this.breadCrumbService.makeBreadcrumbTo(this.breadcrumbMapKey, this.currentRoute);
    }
    this.primaryKey = this.grid.getPrimaryKey();
    this.columnsFromParent = this.grid.getColumn();
    this.setGridWidth(this.columns.length);
    this.items = [
      {label: 'View', icon: 'pi pi-eye', command: (event) => this.ViewDetails(this.selectedValue)},
      {label: 'Edit', icon: 'pi pi-pencil', command: (event) => this.ViewDetails(this.selectedValue)},
      {label: 'Duplicate', icon: 'pi pi-copy', command: (event) => this.ViewDetails(this.selectedValue)},
      {label: 'Copy with Headers', icon: 'pi pi-copy', command: (event) => this.ViewDetails(this.selectedValue)},
      {
        label: 'Export', icon: 'fas fa-export', items: [{
          label: 'New',
          icon: 'pi pi-fw pi-plus',
          items: [
            {label: 'Project'},
            {label: 'Other'},
          ]
        }]
      }
    ];
    if (this.grid.getSections() === undefined || this.grid.getSections().size === 0) {
      this.createDefaultSections();
    }
    this.importTableName = this.grid.getTitle().replace(/\s/g, '');
    this.gridFilter = this.grid.getFilters();
    if(this.gridFilter !== null && this.gridFilter !== undefined && this.gridFilter.length > 0 ) {
      this.onApplyFilter(this.gridFilter);
    } else {
      if (this.getFromUrl && !this.showStandardFilter) {
        this.getGridData(true);
      }else if(this.grid.gridFetchUrlType==='LOCAL'){
        this.getGridData(true);
      }
    }
    this.updateTitleOfGrid();
    this.copyFilters(this.gridFilter);
    this.resetTable();
    this.initcontinue();
    this.makeColListForPref(this.columns);
    this.editAllowedOut.emit(this.accessPolicyService.currentGridAccess['edit'])
  }

  makeColListForPref(column:any[]){
    let _this = this;
    _this.selectedPrefCols = [];
    column.forEach(function (column) {

      if (!_this.service.exculedTypes.includes(column['columnType']) && column['isVisible']) {
        _this.columnsList.push({label: column['header'], value: column['field'], column: column});
        _this.filterColumnList.push({label: column['header'], value: column['field'], column: column});
      }
    });
    _this.selectedPrefCols = _this.columnsList;
  }

  async fetchGridPreference() {
    let _this = this;
    let preference:any;
    let defaultPrefObj = {
      columns: this.columns,
      filters: []
    };
       //Call API to get preference for the grid.
      let result = await this.commonService.getDataByURLAsync(environment.base_url_new + '/api-iam/api/userpreference/v1/getgriduserpreference?tenantId=' + this.commonService.getTenantId() +
          '&userId=' + this.commonService.getFromStorage('userid') + '&moduleName=' + this.grid.getTitle()).then((data: any) => {
        return data;
      }, error => {
        this.preferenceList.push({label: 'System', value: 'System'});
        this.selectedPreference = 'System';

      });
      if ((result !== null && result !== undefined) && Array.isArray(result)) {
        this.preferenceList.push({label:'System', value:'System'});
        this.selectedPreference = 'System';
        this.gridPrefData.push(result[0]);
        result.forEach(function (result, index) {
          _this.preferenceList.push({label:result['preferencename'], value:result['preferencename'], pref: result});
          if (result['preferencedefault']){ // when default prefrene is found
            _this.prefDefault = true;
            _this.selectedPreference = _this.preferenceList[index + 1]['value'];
          }
        });
      }else{
        this.preferenceList.push({label:'System', value:'System'});
        this.selectedPreference = 'System';
        this.reloadData(defaultPrefObj);
      }
  }


  updateTitleOfGrid() {
    this.title = this.grid.getTitle();
    if (this.grid.getFirstLevelDataUrl() !== undefined && this.grid.getFirstLevelDataUrl().length > 0) {
      this.title = this.grid.getColumn().get(this.grid.getPreviousLevelPrimaryKey()).getDefaultValue();
    }
  }

  copyFilters(filters: Filter[]) {
    let _this = this;
    this.filters = [];
    filters.forEach(function (filter) {
      _this.filters.push(filter.copy());
    });
  }

  initcontinue() {   //Continue init functinality
    this.saveUrl = this.grid.getSaveUrl();
    this.url = this.saveUrl;
    this.bulkUrl = environment.base_url + 'bulk/' + this.grid.getDataURL().split('/')[0];
    this.editUrl = '';
    this.getUrl = '';
    this.createFilters();
    this.createColumnSuggesstionList();
    if (this.grid.getPreviousLevelPrimaryKey() === undefined || this.grid.getPreviousLevelPrimaryKey().length <= 0) {
      this.grid.setPreviousLevelPrimaryKey(this.grid.getPrimaryKey());
    }
  }

  onRowEditInit(data: any, index: number) {  //function to perform work when someone click on inline row editing option
    this.isPreparing = true;
    if(this.grid.actionFunc.resetFunction !== null && this.grid.actionFunc.resetFunction !== undefined) {
      this.commonService.runFunction(data,this.grid.actionFunc.resetFunction);
    }
    if (!this.accessPolicyService.currentGridAccess['edit'] || !this.canEditRow(data)) {
      this.removeEditingKey(index, this.table);
      this.isPreparing = false;
      return false;
    }
    if (this.isEditingAlready(1)) {
      this.removeEditingKey(index, this.table);
      this.isPreparing = false;
      this.messageService.add({
        severity: 'info', summary: messages.save_all['summary'],
        detail: messages.save_all['message']
      });
    } else {
      if(!this.grid.useDefaultEditFunction && !this.grid.useDefaultInlineEditFunction) {
        this.onInlineEditClick.emit(data);
        this.removeEditingKey(index, this.table);
        this.isPreparing = false;
        return false;
      }
      this.isAdding = true;
      this.editing = true;
      this.updatingRow = true;
      let key: string, value: Tcolumn;
      this.changes = {};
      for ([key, value] of this.columnsFromParent) {
        try {
          if (value.getColumnType() === 'D') {
            data[key] = new Date(data[key]);
          }
          this.checkActions(value, data[key], index, true, true);
        } catch (e) {
        }
      }
      this.currentEditingKeyValue = data[this.primaryKey];
      this.clonedRowDataValues[data[this.primaryKey]] = Object.assign({},data);
      this.changes = Object.assign({},data);
      this.oldValue = Object.assign({},data);
      this.onInlineEditClick.emit(data);
    }
    this.isPreparing = false;

  }

  deleteFromListofValues(num) {
    if (this.listOfValues.has(num)) {
      this.listOfValues.delete(num);
    }
  }

  onRowEditCancel(rowData: any, index: number) { //function to perform work when someone click on inline row cancel option
    this.isAdding = false;
    this.addInline = false;
    this.isCopying = false;
    this.updatingRow = false;
    this.deleteFromListofValues(-1);
    this.table.editingRowKeys = {};
    if (this.newRow.get(rowData['rowNum'])) {
      this.rowDataValues.splice(index, 1);
      this.rowDataValuesTemp.splice(index, 1);
      this.newRow.clear();
      this.openRowForEditMode(index, false);
      this.formControlReCreate();
      return;
    }
    let oldRow = this.clonedRowDataValues[this.currentEditingKeyValue];
    for (const element of this.columns) {
      if (element.getColumnType() === 'D') {
        if (this.gridFrmGroup.get(element.getField() + index).value.toString().length == 0) {
          oldRow[element.getField()] = '';
        }
      }
      this.gridFrmGroup.get(element.getField() + index).patchValue(oldRow[element.getField()]);
    }
    delete this.clonedRowDataValues[this.primaryKey];
    this.rowDataValues[index] = Object.assign({},oldRow);
  }

  /**
   * This function is used to open the row edit mode if there is any error in save the data
   * @param index
   * @param value
   */
  openRowForEditMode(index: number, value: boolean = true) {
    if (!this.addInline) {
      index = index + 1;
    }
    delete this.table.editingRowKeys[index];
    if(value) {
      this.table.editingRowKeys[index] = value;
    }
  }

  clearSelection() {
    this.table.selectionKeys = {};
    this.selectedRow = [];
  }

  /**
   * This function is used to perform copy and deletion of child data
   * @param rowData
   * @param mode
   */
  copyDeleteAllChild(rowData: any, mode = 'copy') {
    let copy = this.grid.getChildCopyDelete();
    if (copy !== undefined) {
      copy.setCommonService(this.commonService);
      copy.setMode(mode);
      if (mode === 'copy') {
        copy.setNewOldKey(rowData[copy.getPrimaryKeyCode()], this.oldValue[copy.getPrimaryKeyCode()]);
      } else {
        copy.setNewOldKey('', rowData[copy.getPrimaryKeyCode()]);
      }
      copy.perform().subscribe(next => {
      });
    }
  }

  confirmDialog(data: any, index: number, objectReference: string, outputColumnDisplayName: string, outputColumnValue: string, responseMessage: string) {
    var _this = this;
    var message = '';
    if (responseMessage != undefined && responseMessage.length > 0) {
      message = responseMessage;
    } else {
      message = messages.dependencyRef.message;
    }

    message = message.replace("{{objectReference}}", objectReference).replace("{{outputColumnDisplayName}}", outputColumnDisplayName).replace("{{outputColumnValue}}", outputColumnValue);
    this.confirmationService.confirm({
      message: message,
      accept: () => {
        data['continue_resource'] = true;
        _this.onRowSave(data, index);
      },
      reject: () => {
        _this.refreshGridData();
      }
    });
  }


  /**
   * function to perfrom save/update functionality in grid
   * @param data
   * @param index
   */
  onRowSave(data: any, index: number) {
    let validation: boolean = this.getFormValidationErrors(index);
    if (!validation) {
      this.openRowForEditMode(index);
      return;
    }
    delete  data['rowNum'];
    data['tenantId'] = this.commonService.getFromStorage('tenantId');
    var dataTemp = this.deepCopyFunction(data);
    let href = data.href;
    delete data.href;
    let _this = this;
    if(!this.isTabGrid) {
      this.extraObjectToSave = {};
    }
    if(Object.keys(_this.extraObjectToSave).length > 0) {
      Object.keys(_this.extraObjectToSave).forEach(function(value){
        data[value] = _this.extraObjectToSave[value];
      })
    }

    this.clearSelection();
    data['updatedBy'] = this.masterCommonService.getUserId();
    if(this.updatePayloadFunction !== null && this.updatePayloadFunction !== undefined) {
      data =this.commonService.runFunction({data:data,originalData:this.oldValue},this.updatePayloadFunction);
    }
    if (this.newRow.get(index)) {
      /**
       * this function is used to save new data and copy data
       */
      href = this.saveUrl;
      let rowNum = data['rowNum'];
      this.selectedRow = [];
      delete data['rowNum'];
      this.openRowForEditMode(index);
      data['createdBy'] = this.masterCommonService.getUserId();
      this.newRowPrimaryKeyValues.push(data[this.grid.getPrimaryKey()]);
      if (this.saveOnSave) {
        data = this.getPreSaveData(data);
        data['uuid'] = '';
        this.gridService.saveGridRow(data, href).subscribe((result: any) => {
          _this.onRowDataSave.emit({
            result: _this.isCopying ? {newData: result, oldData: _this.oldValue} : result,
            mode: _this.isCopying ? 'copy' : 'save',
            label: _this.grid.getTitle()
          });
          if (_this.isCopying) {
              _this.copyDeleteAllChild(result);
          }
            _this.resetTable();
            _this.isAdding = false;
            _this.isCopying = false;
            _this.editing = false;
            _this.addInline = false;
            _this.newRow.delete(rowNum);
            _this.openRowForEditMode(index, false);
            if (result['statusType'] === 'warning') {
                _this.messageService.add({
                    severity: 'warn',
                    summary: 'Warning',
                    detail: result['status']
                });
            } else {
                _this.messageService.add({severity: 'info', summary: this.saveMessage});
            }
            _this.onChangeValue.emit(data);
            _this.refreshGridData();
            // _this.formControlReCreate(index);

        }, (error:HttpErrorResponse) => {
          _this.table.initRowEdit(data);
          _this.newRow.set(data['rowNum'], true);
           let errorMessage = this.commonService.getHttpErrorMessage(error, this.grid.getTitle(), true);
          _this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: errorMessage
          });

          return;
        })
      } else {
        this.isAdding = false;
        this.isCopying = false;
        this.editing = false;
        this.addInline = false;
        this.newRow.delete(rowNum);
        this.table.editingRowKeys = {};
        this.onSave.emit({data: data, mode: this.updatingRow?'update':'save',index:index});
        this.updatingRow = false;
      }

    } else {
      /**
       * this block is used for update data if the row is opened in edit mode
       */
      delete data._rowNum;
      if (this.saveOnSave) {

        if (this.grid.updateUrl !== undefined && this.grid.updateUrl !== null && this.grid.updateUrl.length > 0) {
          if (this.grid.updateUrlType === 'post') {
            this.commonService.post(this.grid.getUpdateUrl(data), data).subscribe((result: any) => {
              this.afterUpdate(result, data);
            }, (error: HttpErrorResponse) => {
              _this.isAdding = true;
              _this.openRowForEditMode(index);
              data['href'] = href;
              _this.messageService.add({
                severity: 'error',
                summary: 'Error found !',
                detail: this.commonService.getHttpErrorMessage(error, this.grid.getTitle())
              });
            });
          } else if (this.grid.updateUrlType === 'patch') {
            this.commonService.patch(this.grid.getUpdateUrl(data), data).subscribe((result: any) => {
              this.afterUpdate(result, data);
            }, (error: HttpErrorResponse) => {
              _this.isAdding = true;
              _this.openRowForEditMode(index);
              data['href'] = href;
              _this.messageService.add({
                severity: 'error',
                summary: 'Error found !',
                detail: this.commonService.getHttpErrorMessage(error, this.grid.getTitle())
              });
            });
          }
        } else {
          this.gridService.modifyGridRowWithResponse(data, href).subscribe((result: any) => {

            _this.isAdding = false;
            _this.editing = false;
            _this.isCopying = false;
            _this.onRowDataSave.emit({
              result: {newData: result, oldData: _this.oldValue},
              mode: 'update',
              label: _this.grid.getTitle()
            });
            _this.resetTable();
            _this.getGridData(false);
            _this.messageService.add({
              severity: 'info', summary: messages.updated['summary'],
              detail: messages.updated['message']
            });
            _this.onChangeValue.emit(data)
          }, (error: HttpErrorResponse) => {
            if (error.status == 424)
              _this.confirmDialog(dataTemp, index, error.error[0].objectReference, error.error[0].outputColumnDisplayName, error.error[0].outputColumnValue, error.error[0].message);
            else {
              _this.isAdding = true;
              _this.openRowForEditMode(index);
              data['href'] = href;
              _this.messageService.add({
                severity: 'error',
                summary: 'Error found !',
                detail: this.commonService.getHttpErrorMessage(error, this.grid.getTitle())
              });
            }
          });

        }
      } else {
        _this.isAdding = false;
        _this.isCopying = false;
        _this.editing = false;
        _this.addInline = false;
        this.table.editingRowKeys = {};
        this.onSave.emit({data: data, mode: 'update', index: index});
        this.updatingRow = false;
      }

    }


  }

  /**
   * function to perform work when someone click on inline row copy option
   * @param data
   * @param gridRefresh
   * @param table
   */
  onRowCopy(data: any, gridRefresh: boolean, table = this.table) {
    this.isPreparing = true;
    if(this.grid.actionFunc.resetFunction !== null && this.grid.actionFunc.resetFunction !== undefined) {
      this.commonService.runFunction(data,this.grid.actionFunc.resetFunction);
    }
    if (!this.isEditingAlready(0, table)) {
      let newData = JSON.parse(JSON.stringify(data));
      if(this.defaultCopy){
        this.isAdding = false;
        this.addInline = true;
        this.isCopying = true;
        this.oldValue = data;
        if (this.grid.actionFunc !== undefined && this.grid.actionFunc !== null && this.grid.actionFunc.newCopyValueFunc !== undefined && this.grid.actionFunc.newCopyValueFunc !== null) {
          newData = this.commonService.runFunction(newData, this.grid.actionFunc.newCopyValueFunc);
        }
        this.addInlineRow(this.table, newData);
      } else {
        this.onClickDuplicate.emit(newData);
        this.isPreparing = false;
      }
    } else {
      this.isPreparing = false;
      this.messageService.add({
        severity: 'info', summary: messages.save_all['summary'],
        detail: messages.save_all['message']
      });
    }

  }

  /**
   * function to perform work when someone click on multiple row copy option
   */
  multipleRowCopy() {
    let _this = this;
    if(this.grid.actionFunc.resetFunction !== null && this.grid.actionFunc.resetFunction !== undefined) {
      this.commonService.runFunction({},this.grid.actionFunc.resetFunction);
    }
    if (!this.accessPolicyService.currentGridAccess['copy']) {
      return;
    }
    if (this.selectedRow !== undefined && this.selectedRow.length > 0) {
      if (this.selectedRow.length > 1) {
        this.messageService.add({
          severity: 'info', summary: messages.one_row_copy_at_time.summary,
          detail: messages.one_row_copy_at_time.message
        });
      } else {
        if (this.selectedRow.length > 0) {
          this.onRowCopy(this.selectedRow[0], true);
        }
      }
      this.selectedRow = [];
      this.selectedRows.emit(this.selectedRow);
    } else {
      this.messageService.add({
        severity: 'info', summary: messages.no_row_selected['summary'],
        detail: messages.no_row_selected.message
      });
    }
  }

  /**
   * function to perform work when someone click on multiple row delete option
   */
  multipleRowDelete() {
    if (!this.accessPolicyService.currentGridAccess['delete']) {
      return;
    }
    let _this = this;
    if (!this.isEditingAlready(0, this.table)) {
      if (this.selectedRow !== undefined && this.selectedRow.length > 0) {
        let selectedRows = [...this.selectedRow];
        this.confirmationService.confirm({
          message: messages.multiple_delete_confirmation.message,
          accept: () => {
            if (_this.deleteOnDelete) {
              let uuidList:any[] = [];
              selectedRows.forEach(function (data: any, index) {
                uuidList.push(data['uuid']);
              });
              _this.DeleteRow(uuidList);
              _this.messageService.add({
                severity: 'info', summary: messages.delete.summary,
                detail: messages.delete.message
              });
              this.selectedRow = [];
            } else {
              _this.onDelete.emit({selectedRows: selectedRows});
            }
          }
        });
      } else {
        this.messageService.add({
          severity: 'info', summary: messages.no_row_selected.summary,
          detail: messages.no_row_selected.message
        });
      }
    } else {
      this.messageService.add({
        severity: 'info', summary: messages.save_all['summary'],
        detail: messages.save_all.message
      });
    }
  }

  /**
   * this function check wheter current or selected row is edit mode or not
   * this will do work when you click on delete and copy button if is there any row in edit mode in grid
   * @param index
   */
  isItCurrentRowEdit(index) {
    let isit = false;
    if (this.newRow.size === 0) {
      index++;
    }
    if (this.table.editingRowKeys[index]) {
      isit = true;
    }
    return isit;
  }

  DeleteRow(uuidList:any[]) {   //function to perform work when user delete a select4ed row
    this.table.editingRowKeys = {};
    this.addInline = false;
    this.isCopying = false;
    let _this = this;
    if(uuidList !== undefined && uuidList !== null && uuidList.length > 0) {
      let deletePayload = {
        userName:_this.commonService.getFromStorage("userName"),
        tenantId:_this.commonService.getFromStorage('tenantId'),
        uuids:uuidList
      };
      this.commonService.post(this.grid.deleteUrl,deletePayload).subscribe((result: any) => {
        _this.messageService.add({
          severity: 'info', summary: messages.delete['summary'],
          detail: messages.delete['message']
        });
        _this.refreshGridData();
      });
    }
  }

  onRowDelete(data: any, index, gridRefresh: boolean) {
    if(this.deleteOnDelete) {
      if(!this.newRow.get(data['rowNum'])) {
        if (this.isEditingAlready() && !this.isItCurrentRowEdit(index)) {
          this.messageService.add({
            severity: 'info', summary: messages.save_all['summary'],
            detail: messages.save_all['message']
          });
        } else {
          this.confirmationService.confirm({
            message: messages.single_delete_confirmation.message,
            accept: () => {
              this.DeleteRow([data['uuid']]);
            }
          });
        }
      }
    } else {
      this.confirmationService.confirm({
        message: messages.multiple_delete_confirmation.message,
        accept: () => {
          this.rowDataValues.splice(index,1);
          this.formControlReCreate();
          this.onDelete.emit({selectedRows: [data]});
        }
      });

    }
  }

  /**
   * Recreate form after grid data get refreshed
   * @param index
   */
  formControlReCreate(index: number = 0) {
    let _this = this;
    let formControl: FormControl;
    _this.gridFrmGroup = new FormGroup({});
    _this.rowDataValues.forEach((rowData: any,i) => {
      _this.columns.forEach((column: Tcolumn) => {
        formControl = _this.createFormControl(column,rowData);
       _this.gridFrmGroup.addControl(column.getField() + i,formControl);
      });
      _this.includeFieldsListForFormGroup.forEach(function (field) {
        _this.gridFrmGroup.addControl(field+i, new FormControl({
          value: rowData[field],
          disabled: true
        }))
      })
    });
  }

  private createFormControl(column: Tcolumn, rowData: any) {
    let control: FormControl;
    if (column.getColumnType() === 'LB') {
      control = new FormControl({
        value: rowData[column.getField()],
        disabled: true
      }, column.getValidators());
    } else if (column.getColumnType() === 'D') {
      if (rowData[column.getField()] === null || rowData[column.getField()] === undefined) {
        rowData[column.getField()] = '';
      }
      control = new FormControl({
        value: rowData[column.getField()].toString().length > 0 ? this.datePipe.transform(new Date(rowData[column.getField()]), column.getFromExtra('date')['format'] !== undefined ? column.getFromExtra('date')['format'] : environment.dateFormat) : '',
        disabled: this.isDisabledCheckOnUpdate(column, rowData)
      }, column.getValidators());
    } else {
      control = new FormControl({
        value: rowData[column.getField()],
        disabled: this.isDisabledCheckOnUpdate(column, rowData)
      }, column.getValidators());
    }
    return control;
  }

  updateListMap(num: number, list: any[], fieldName) {
    let _this = this;
    let obj: Map<any, any>;
    if (_this.listOfValues.has(num)) {
      obj = _this.listOfValues.get(num);

    } else {
      obj = new Map();
    }
    obj.set(fieldName, list);
    _this.listOfValues.set(num, obj);
  }


  resetTable() {
    this.table.selectionKeys = {};
    this.table.editingRowKeys = {};
    this.table.expandedRowKeys = {};
    this.newRow.clear();
    this.rowDataValues = [];
    this.gridFrmGroup = new FormGroup({});
    this.table.value = [];
    this.selectedRow = [];
    this.listOfValues = new Map();
    this.autoCompleteValue = '';
  }

  /**
   * function to fetch the grid adata from the database and update list of valu8es and also check for those actions
   * which needs to call at grid refesh time
   * @param refresh
   */
  public getGridData(refresh: boolean) {
    this.toggleFetching();
    refresh = true;
    let _this = this;
    let sortMeta = this.table['_multiSortMeta'];
    this.table.filteredValue = [];
    this.loadMessage = 'Loading ..';
    this.autoCompleteValue = '';
    if (this.listOfValues === undefined) {
      this.listOfValues = new Map();
    }
    this.table.reset();
    if(this.subscription !== null && this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
    if(this.grid.gridFetchUrlType === 'LOCAL') {
       const startRow = this.pageSize * this.number;
       const  endRow = Math.min((this.pageSize * (this.number+1)), this.allData.length);

      this.lastCount = this.allData.length;
      this.updateAutoIncrementField();
      this.currentPageData.emit(this.allData.slice(startRow,endRow));
      this.applyGrid(this.allData.slice(startRow,endRow), sortMeta, refresh);
      const data = {
        pageable:{
          pageSize:20,
        },
        totalElements:this.allData.length,
        totalPages:Math.ceil(this.allData.length/this.pageSize),
        content: this.allData.length< this.pageSize ? this.allData: this.allData.slice(startRow,endRow)
      }
      this.handleData(data, _this, sortMeta, refresh);
    }
    else if(this.grid.gridFetchUrlType === 'GET') {
      this.subscription = this.gridService.getData(this.getPageUrl(this.grid.getDataURL(), this.number)).subscribe((data: any) => {
        this.handleData(data, _this, sortMeta, refresh);
      }, (error: HttpErrorResponse) => {
        _this.toggleFetching();
        _this.isError = true;
        if (error.message.includes("Http failure response")) {
          _this.loadMessage = 'Http Failure Response (Error Status : ' + error.status + ')';
        } else {
          _this.loadMessage = 'Something went wrong !';
        }
        _this.calculateLeft();
      });
    } else if(this.grid.gridFetchUrlType === 'POST') {
      let payload = this.runFunction([],this.grid.fetchPostPayloadFuction);
      this.subscription = this.commonService.post(this.getPageUrl(this.grid.getDataURL(), this.number),payload).subscribe((data: any) => {
        _this.number++;
        /*
         checking if response data is having pageAble key or not and Storing the total lenght of data as per the same condition.
         */
        if (data['pageable']) {
          _this.pageSize = data['pageable']['pageSize'];
          _this.totalElements = data['totalElements'];
          _this.totalPages = data['totalPages'];
        }else{
          _this.totalElements = data.length;
        }
        if (_this.totalElements > 0) {
          _this.totalNumberElements.emit(_this.totalElements);
        }

        _this.isError = false;
        _this.toggleFetching();
        /*
         data['content'] is actual data to show on Grid, we are checking in the following line whether the Response data have 'Content key' or not
         */
        _this.lastCount = data['_embedded'] ? data._embedded[Object.keys(data._embedded)[0]].length : data.length;
        _this.updateAutoIncrementField();
        _this.applyGrid(data['content']?data['content']:data, sortMeta, refresh);

      }, (error: HttpErrorResponse) => {
        _this.toggleFetching();
        _this.isError = true;
        if (error.message.includes("Http failure response")) {
          _this.loadMessage = 'Http Failure Response (Error Status : ' + error.status + ')';
        } else {
          _this.loadMessage = 'Something went wrong !';
        }
        _this.calculateLeft();
      });
    }
  }

  private handleData(data: any, _this: this, sortMeta, refresh: boolean) {
        if (data['pageable']) {
          _this.pageSize = data['pageable']['pageSize'];
          _this.totalElements = data['totalElements'];
          _this.totalPages = data['totalPages'];
        }else{
          _this.totalElements = data.length;
        }
        if (_this.totalElements > 0) {
          _this.totalNumberElements.emit(_this.totalElements);
        }

        _this.isError = false;
        _this.toggleFetching();
        /*
         data['content'] is actual data to show on Grid, we are checking in the following line whether the Response data have 'Content key' or not
         */
        _this.lastCount = data['_embedded'] ? data._embedded[Object.keys(data._embedded)[0]].length : data.length;
        _this.updateAutoIncrementField();
        _this.applyGrid(data['content']?data['content']:data, sortMeta, refresh);
  }

  /**
   * this check for the field or compenent is disabled or not
   * this is passed from extra where key='meta' and value={disabled:true}
   * @param field
   */
  isDisabled(field: Tcolumn) {
    if (field.getFromExtra('meta')['disabled'] !== undefined) {
      return field.getFromExtra('meta')['disabled'];
    }
    return false;
  }

  isDisabledCheckOnUpdate(field: Tcolumn, rowData: any = {}) {
    if (this.onlyNewEditableMode) {
      if (this.newRowPrimaryKeyValues.includes(rowData[this.grid.getPrimaryKey()])) {
        if (field.getFromExtra('meta')['disabled'] !== undefined) {
          return field.getFromExtra('meta')['disabled'];
        }
      } else {
        return (this.oldEditableFieldsFunction !== null && this.oldEditableFieldsFunction !== undefined)? this.oldEditableFieldsFunction(rowData,field.getField()) :!this.oldEditableFields.includes(field.getField());
      }
    } else {
      if (field.getFromExtra('meta')['disabled'] !== undefined) {
        return field.getFromExtra('meta')['disabled'];
      }
    }
    return false;
  }

  /***
   * apply data to grid and perform action of ther is there any aaction t perform at loading time
   * @param data
   * @param sortMeta
   * @param refresh
   */
  applyGrid(data: any[], sortMeta: any, refresh: boolean = true, calcuateHeight: boolean = true, throwRefresh: boolean = true) {
    let _this = this;
    let i = 0;
    this.canScrollFurthure = false;
    if (throwRefresh) {
      _this.onRefreshComplete.emit("grid");
    }
    if (data !== undefined && data !== null && data.length > 0) {
      if(data.length === this.pageSize) {
        this.canScrollFurthure = true;
      }
      this.getRowData.emit(data);
      _this.rowDataValues=[];
      data.forEach(function (row: any, index) {
        index = _this.rowDataValues.length;
        if(_this.openInEditMode) {
          _this.table.editingRowKeys[index+1] = true;
        }
        const rowData = row;
        delete rowData['_rowNum'];
        row.href = _this.grid.getHrefUrl(row);
        let key: any;
        for ([key] of _this.columnsFromParent) {
          if (key !== '_rowNum') {
            let field: Tcolumn = _this.grid.getColumn().get(key);
            if (field.getColumnType() === 'D') {
              if (row[key] === null || row[key] === undefined) {
                row[key] = '';
              }
            }
          }
          rowData[key] = row[key];
        }
        if (i == 0 && !refresh) {
          _this.columns.push(new Tcolumn('href', 'Href', 'T', _this.columns.length + 1, false,));
          _this.selectedColumns = _this.columns;
          _this.headerColumns = _this.columns;
          _this.headerColumns.splice(0, 1);
          _this.headerColumns.splice(_this.headerColumns.length - 1, 1);
        }
        rowData['rowNum'] = _this.rowDataValues.length + 1;
        rowData['createdBy'] = row['createdBy'];
        rowData['updatedBy'] = row['updatedBy'];
        rowData['uuid'] = row['uuid'];
        rowData['createdTimestamp'] = row['createdTimestamp'];
        rowData['updatedTimestamp'] = row['updatedTimestamp'];
        if (_this.grid.getInfoUrl() !== undefined && _this.grid.getInfoUrl().length > 0) {
          if (_this.breadCrumbDone.includes(rowData[_this.grid.getPrimaryKey()]) === false) {
            _this.breadCrumbDone.push(rowData[_this.grid.getPrimaryKey()]);
            let bcd = new Breadcrumb('', rowData[_this.grid.getPrimaryKey()], _this.grid.getInfoUrl() + '/' + rowData[_this.grid.getPrimaryKey()].replace(new RegExp('/', 'g'), '%2F'), [], false, true, _this.grid.getQueryParams());
            _this.breadCrumbNext.push(bcd);
          }
        }
        _this.rowDataValues.push(rowData);
        _this.getRowDataForCheckbox(rowData, index);
        for (const element of _this.columns) {
          let control: FormControl;
          if (element.getColumnType() === 'LB') {
            control = new FormControl({
              value: rowData[element.getField()],
              disabled: true
            }, element.getValidators());
          }  else if (element.getColumnType() === 'D') {
            if (rowData[element.getField()] === null || rowData[element.getField()] === undefined) {
              rowData[element.getField()] = '';
            }
            let value = '';

              try {
                value= _this.datePipe.transform(new Date(rowData[element.getField()]), element.getFromExtra('date')['format'] !== undefined ? element.getFromExtra('date')['format'] : environment.dateFormat);
              } catch (error) {
              }

            control = new FormControl({
              value: value,
              disabled: _this.isDisabledCheckOnUpdate(element, rowData)
            }, element.getValidators());
          } else {
            control = new FormControl({
              value: rowData[element.getField()],
              disabled: _this.isDisabledCheckOnUpdate(element, rowData)
            }, element.getValidators());
          }
          _this.gridFrmGroup.addControl(element.getField() + index, control);
          i++;
        }
        _this.refreshGrid = false;
        _this.refreshForm = true;
      });
      _this.rowDataValuescopy = Object.assign([],_this.rowDataValues);
      _this.rowDataValuesTemp = _this.deepCopyFunction([..._this.rowDataValues]);
      if (sortMeta !== null && sortMeta !== undefined && sortMeta.length > 0) {
        _this.table.multiSortMeta = sortMeta;
        _this.table.sortMultiple();
      }
      if (_this.grid.getPivotConfig() !== undefined) {
        _this.updateCategoryList();
        _this.createRowGroupMetaData(_this, _this.grid);
      }
      if (_this.gridFilter.length > 0) {
        _this.applyFilters();
      }
      if (calcuateHeight) {
        _this.calculateHeight();
      }
    }
  }

  /**
   * this function is used same field have some work to do on the time of init
   * @param list
   */
  runActions(list: any[] = []) {
    let _this = this;
    list.forEach(function (index) {
      _this.checkActionsforPerform(index['index'], index['col'], index['value'], index['canPass']);
    });
  }

  /**
   * this function togglea flag whichis when true it show please wait in grid
   */
  toggleFetching() {
    this.isError = false;
    this.showFetching = !this.showFetching;
  }

  /**
   * this function is used to get label from Key value pair accoring to that colValue passwed here
   * @param column
   * @param colValue
   * @param ri
   */
  public getColumnValue(column: Tcolumn, colValue: any, ri) {
    let listOfValues = this.getListOfValues(column, ri);
    if (listOfValues !== undefined) {
      for (const element of listOfValues) {
        if (element['value'].toString() === colValue.toString()) {
          return element['label'];
        }
      }
    }
  };

  /**
   * this functon is called when some data is saved and updated by using the input form
   * @param value
   */
  changeState(value) {
      let _this = this;
    if(this.grid.actionFunc.resetFunction !== null && this.grid.actionFunc.resetFunction !== undefined) {
      this.commonService.runFunction({},this.grid.actionFunc.resetFunction);
    }
    this.updateRow = false;
    let message = messages.saved.message;
    if (value['isUpdate']) {
      message = messages.updated.message;
    }

    let mode = 'save';
    if (value['isUpdate']) {
        mode = 'update';
    }
      this.onRowDataSave.emit({
          result: mode === 'update' ? {
              newData: value['data'],
              oldData: this.oldValue
          } : value['data'], mode: mode, label: this.grid.getTitle()
      });
      this.resetTable();
      this.getGridData(this.isFrist);
      if (value['data']['statusType'] === 'warning') {
          _this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: value['data']['status']
          });
      } else {
          _this.messageService.add({severity: 'info', summary: this.saveMessage});
      }
      this.isFrist = true;
      if (value['close'] === true) {
          this.modalState = false;
      }
  }

  /**
   * open input form for user to eneter a new record
   */
  openModal() {
    if(this.grid.actionFunc.resetFunction !== null && this.grid.actionFunc.resetFunction !== undefined) {
      this.commonService.runFunction({},this.grid.actionFunc.resetFunction);
    }
    this.readonly = true;
    if (!this.isEditingAlready()) {
      if (this.grid.openDefaultInputForm) {
        if (!this.grid.addNewRowInline) {
          this.modalState = true;
          this.modalChanged.emit(true);
        } else {
          this.addInlineRow(this.table);
        }
      }
      this.emitAddClick();
    } else {
      this.messageService.add({
        severity: 'info', summary: messages.save_all.summary,
        detail: messages.save_all.message
      });
    }

  }

  canceled() {
    if(this.grid.actionFunc.resetFunction !== null && this.grid.actionFunc.resetFunction !== undefined) {
      this.commonService.runFunction({},this.grid.actionFunc.resetFunction);
    }
    this.editUrl = '';
    this.modalState = false;
    this.updateRow = false;
    this.showToolbar = true;
    this.modalChanged.emit(false);
  }

  /**
   * Checks if there any row is in editing mode or not
   * @param length
   * @param table
   */
  isEditingAlready(length = 0, table = this.table) {
    let isAlready = false;
    if (Object.keys(table.editingRowKeys).length > length) {
      isAlready = true;
    }
    return isAlready;
  }

  /**
   * this function remove the keys which are editing mode
   * (this change the mode of editing row to non editable mode)
   * @param index
   * @param table
   */
  removeEditingKey(index, table) {
    if (this.newRow.size === 0) {
      index++;
    }
    delete table.editingRowKeys[index];
  }

  getDefaultValue(value: any) {
    let defaultVa: any;
    if(value instanceof Date) {
      defaultVa = value;
    } else if (typeof (value) === 'object') {
      defaultVa = value.defaultValue;
    } else {
      defaultVa = value;
    }
    return defaultVa;
  }

  /**
   * when clickeed on addInline button this fnction performed
   * this function recreate the formcontrol according the index and prepoulate the default
   * value if there is any
   * @param table
   * @param rowData (this passed only when user click on copy button this contains  this existing row data)
   */
  addInlineRow(table: Table, rowData: any = {}) {
    if (!this.isEditingAlready()) {
      this.counter++;
      this.isAdding = true;
      this.addInline = true;
      if (rowData[this.primaryKey] !== undefined) {
        this.saveMessage = messages.copied.message;
      } else {
        this.saveMessage = messages.saved.message;
      }
      let jsonData = {};
      jsonData['rowNum'] = 0;
      let _this = this;
      let newRow: boolean = false;
      let gridFrmGroupTemp: FormGroup = new FormGroup({});
      for (let i = 0; i <= this.rowDataValues.length; i++) {
        if (i == 0) {
          for (let m = 0; m < this.columns.length; m++) {
            let control: FormControl = new FormControl(undefined, this.columns[m].getValidators());
            let copyValue = '';
            if (rowData[this.columns[m].getField()] !== undefined) {
              if (this.columns[m].getField() !== this.primaryKey && !this.columns[m].getIsUnique()) {
                copyValue = rowData[this.columns[m].getField()];
              }
            }
            if (this.grid.getPreviousLevelPrimaryKey() === this.columns[m].getField()) {
              jsonData[this.columns[m].getField()] = rowData[this.columns[m].getField()] !== undefined ? copyValue : this.getDefaultValue(this.columns[m].getDefaultValue());
              control = new FormControl(jsonData[this.columns[m].getField()], this.columns[m].getValidators());
            } else if (this.columns[m].getColumnType() === 'D') {
              jsonData[this.columns[m].getField()] = rowData[this.columns[m].getField()] !== undefined && rowData[this.columns[m].getField()] !== null ? (copyValue.length > 0?new Date(copyValue):copyValue) : this.getDefaultValue(this.columns[m].getDefaultValue());
              control = new FormControl({
                value: jsonData[this.columns[m].getField()],
                disabled: this.isDisabled(this.columns[m])
              }, this.columns[m].getValidators());

            } else {
              jsonData[this.columns[m].getField()] = rowData[this.columns[m].getField()] !== undefined ? copyValue : this.getDefaultValue(this.columns[m].getDefaultValue());
              control = new FormControl({
                value: jsonData[this.columns[m].getField()],
                disabled: this.isDisabled(this.columns[m])
              }, this.columns[m].getValidators());
            }
            gridFrmGroupTemp.addControl(this.columns[m].getField() + 0, control);
            if (this.columns[m].getActionToDo() !== undefined) {
              this.columns[m].getActionToDo().getFieldsToUpdate().forEach(function (fields) {
                if (fields['throw'] === true) {
                  _this.fieldIndexHasAction.push({
                    index: 0,
                    col: _this.columns[m],
                    value: control.value,
                    canPass: (!(_this.columns[m].getColumnType() === 'L' || _this.columns[m].getColumnType() === 'B'))
                  });
                }
              })
            }
            this.changes[this.columns[m].getField()] = control.value;
          }
          let control = null;
          this.includeFieldsListForFormGroup.forEach(function(field) {
            jsonData[field] = rowData[field];
            control = new FormControl({
              value: jsonData[field],
              disabled: true
            });
            gridFrmGroupTemp.addControl(field + i, control);
            _this.gridFrmGroup.markAsDirty();
          })
        } else {
          for (const element of this.columns) {
            gridFrmGroupTemp.addControl(element.getField() + i, this.gridFrmGroup.get(element.getField() + (i - 1)));
            this.gridFrmGroup.markAsDirty();
          }
        }
        if (!this.newRow.get(i) && !newRow) {
          newRow = true;
          this.newRow.set(i, true);
        }
      }
      this.gridFrmGroup = gridFrmGroupTemp;
      this.rowDataValues.splice(0, 0, jsonData);
      this.rowDataValuesTemp = Object.assign([],_this.rowDataValues);
      table.initRowEdit(jsonData);
      this.editing = true;
      this.runActions(this.fieldIndexHasAction);
      this.emitAddClick();
    } else {
      this.messageService.add({
        severity: 'info', summary: messages.save_all.summary,
        detail: messages.save_all.message, life: 5000
      });
    }
    this.isPreparing = false;
  }

  emitAddClick() {
    this.onClickAdd.emit({screenName: this.grid.getTitle()});
  }

  /**
   * this function is used to change screen from first level to next level
   * @param col
   * @param rowDatum
   * @param value
   */
  changeRoute(col: Tcolumn, rowDatum: any, value: any, rowIndex: number) {
    if (this.grid.getPrimaryKey() === col.getField() && this.grid.primaryKeyClickable) {
      if (!this.isEditingAlready()) {
        this.throwUniqueClick(col, rowDatum, value);
        if (this.grid.actionFunc.canGoToNextFunction !== undefined && this.grid.actionFunc.canGoToNextFunction !== null) {
          this.canGoNext = this.commonService.runFunction(rowDatum, this.grid.actionFunc.canGoToNextFunction);
        }
        if ((this.grid.getTabs() === undefined || this.grid.getTabs().length === 0) && this.grid.openSidebarOnUniqueClick && !this.canGoNext) {
          this.openInModal([rowDatum]);
        } else {
          this.listOfValuesEmitter.emit(this.listOfValues.get(rowIndex));
          this.goToNext.emit(rowDatum);
        }
      } else {
        this.messageService.add({
          severity: 'info', summary: messages.save_all.summary,
          detail: messages.save_all.message
        });
      }
    } else if(this.grid.getClickable() !== undefined && this.grid.getClickable() !== null && this.grid.getClickable().includes(col.getField())) {
      if (!this.isEditingAlready()) {
        let obj = {column: col, rowData: rowDatum, value: value, editMode: false};
        this.onClickUniqueKey.emit(obj);
      } else {
        this.messageService.add({
          severity: 'info', summary: messages.save_all.summary,
          detail: messages.save_all.message
        });
      }
    }
  }

  /**
   * thorw unique click emit
   * @param col
   * @param rowDatum
   * @param value
   */
  throwUniqueClick(col: Tcolumn, rowDatum: any, value: any, isEditView: boolean = false) {
    let queryParams = {};
    if (this.grid.getQueryParams() !== undefined && this.grid.getQueryParams().length > 0) {
      this.grid.getQueryParams().forEach(function (params) {
        queryParams[params] = rowDatum[params];
      });
    }
    this.oldValue = rowDatum;
    let rowdat = rowDatum[col.field];
    let obj = {column: col, rowData: rowDatum, value: value, editMode: isEditView};
    if (this.grid.getTabs() === undefined || !this.grid.ShowTabOnModal()) {
      if (this.grid.getInfoUrl() !== undefined && this.grid.getInfoUrl().length > 0) {
        if (col.field === this.grid.getPrimaryKey()) {
          rowdat = rowdat.replace(new RegExp('/', 'g'), '%2F');
          this.commonService.saveToStorage('lastRoute', {
            routeTo: this.grid.getInfoUrl() + '/' + rowdat,
            skip: true,
            params: queryParams
          });
          this.route.navigate([this.grid.getInfoUrl() + '/' + rowdat], {
            skipLocationChange: true,
            queryParams: queryParams
          });
        }
      }
    } else {
      obj['tab'] = new Tab(rowdat, undefined, this.grid.getPrimaryKey(), {tabs: this.grid.getTabs()});
    }
    this.onClickUniqueKey.emit(obj);
  }

  /**
   * this function call when user click on edit/view button
   * this function get the url of last selected row and pass that url to form
   * the form get the values from db and populate the value in form
   */
  openInModal(selectedRow, isClickOnEdit: boolean = false) {
    if(this.grid.actionFunc.resetFunction !== null && this.grid.actionFunc.resetFunction !== undefined) {
      this.commonService.runFunction({},this.grid.actionFunc.resetFunction);
    }
    var dataTemp = selectedRow[0];
    var roles = this.commonService.getMyRolesString().split(',');
    if (selectedRow === undefined || selectedRow.length === 0) {
      this.messageService.add({
        severity: 'info', summary: messages.no_row_selected.summary,
        detail: messages.no_row_selected.message
      });
    } else if (this.grid.getTitle() == 'User Master' && dataTemp['userName'] == this.commonService.getFromStorage('userid')) {

      this.selectedRow = [];
      this.selectedRows.emit(this.selectedRow);
      this.messageService.add({
        severity: 'info', summary: messages.user_save_not_allowed['summary'],
        detail: messages.user_save_not_allowed['message']
      });
      this.table.selectionKeys = {};
      return false;
    } else if (this.grid.getTitle() == 'Role Master' && roles.includes(dataTemp['roleName'])) {

      this.selectedRow = [];
      this.selectedRows.emit(this.selectedRow);
      this.messageService.add({
        severity: 'info', summary: messages.role_save_not_allowed['summary'],
        detail: messages.role_save_not_allowed['message']
      });
      this.table.selectionKeys = {};
      return false;
    } else {
      if (!this.accessPolicyService.currentGridAccess['edit']) {
        return;
      }
      if (!this.isEditingAlready()) {
        if (this.defaultEdit) {
          this.readonly = false;
          if (isClickOnEdit) {
            this.readonly = true;
          }
          let primaryKey = this.grid.getPrimaryKey();
          if (primaryKey === undefined || primaryKey.length === 0) {
            primaryKey = this.grid.getPreviousLevelPrimaryKey();
          }
          if(!this.isTabGrid) {
            this.extraObjectToSave = {};
          }
          if(this.extraObjectToSave === null || this.extraObjectToSave === undefined || Object.keys(this.extraObjectToSave).length === 0){
            this.extraObjectToSave =  this.getExtraObject(selectedRow[0]);
          }
          this.editUrl = this.grid.getHrefUrl(selectedRow[0]);
          this.rowDataUpdateUrl = this.grid.getUpdateUrl(selectedRow[0]);
          this.modalState = true;
          this.updateRow = true;
          if (this.grid.onEditViewThrowUnique) {
            this.throwUniqueClick(this.grid.getColumn().get(primaryKey), selectedRow[0], selectedRow[0][primaryKey], true);
          }
        }
        this.table.selectionKeys = {};
        this.selectedRow = [];
        this.selectedRows.emit(this.selectedRow);
        this.onClickEdit.emit({rowData: selectedRow[0]})

      } else {

        this.messageService.add({
          severity: 'info', summary: messages.save_all.summary,
          detail: messages.save_all.message
        });
      }
    }
  }

  getExtraObject(rowData: any) {
    let obj = {};
    obj[this.grid.updateCode] = rowData[this.grid.updateCode]
    return obj;
  }

  reloadData(object: any, fromPrefrences: boolean = true) {
    this.gridFilter = object.filters;
    this.calculateHeight();
  }


  /**
   * this function call when some data is going to change in inline grid editing mode
   * this update the rowDatvalues and check fro action if it there then perform that action
   * @param index
   * @param col
   * @param value
   */
  changeField(index: number, col: Tcolumn, value: any, isAtLoad: boolean = false) {
    this.rowDataValues[index][col.getField()] = value;
    if (col.getColumnType() === 'D') {
      this.rowDataValues[index][col.getField()] = new Date(value + ' UTC');
    }
    if (this.changes[col.getField()] === undefined || this.changes[col.getField()] === null) {
      if (this.clonedRowDataValues[this.rowDataValues[index][this.primaryKey]] !== undefined && this.clonedRowDataValues[this.rowDataValues[index][this.primaryKey]] !== null) {
        this.changes[col.getField()] = this.clonedRowDataValues[this.rowDataValues[index][this.primaryKey]][col.getField()];
      } else {
        this.changes[col.getField()] = '';
      }
    }
    if (value !== '' && (this.changes[col.getField()] !== value ||(col.getActionToDo() !==null &&col.getActionToDo() !== undefined && col.getActionToDo().runAlways))) {
      this.changes[col.getField()] = value;

      this.checkActionsforPerform(index, col, value, !(col.getColumnType() === 'L' || col.getColumnType() === 'B'), isAtLoad);
    }
  }

  /**
   * this fucntion check for any action in column col if it
   * is there then perform that action
   * @param index
   * @param col
   * @param value
   * @param canpass
   */
  checkActionsforPerform(index: number, col: Tcolumn, value: any, canpass, isAtLoad: boolean = false) {
    if (typeof (col.getDefaultValue()) === 'object') {
      let obj: defaultValue = col.getDefaultValue();
      let fieldname = obj.toField;
      if (fieldname !== undefined && fieldname.length > 0) {
        if (this.gridFrmGroup.controls[fieldname + index].value === null || this.gridFrmGroup.controls[fieldname + index].value.length <= 0) {
          this.gridFrmGroup.controls[fieldname + index].setValue(value);
          this.rowDataValues[index][fieldname] = value;
        }
      }
    }
    this.checkActions(col, value, index, canpass, isAtLoad);
  }

  /**
   * checking whether current field has any action to perform when changes its value
   * action like changing value of another field eg. select parent change country according to selected parent
   * @param field
   * @param value
   * @param row
   * @param canPass
   */
  checkActions(col: Tcolumn, value: any, row, canPass, isAtLoad: boolean = false, isUpdatingList: boolean = false) {
    let _this = this;
    if (col.getActionToDo() !== undefined && value !== null ) {
      col.getActionToDo().setToExtra('rowData', _this.rowDataValues[row]);
      let obj = {field: col, currentValue: value, action: col.getActionToDo(), canPass: canPass};
      col.getActionToDo().getFieldsToUpdate().forEach(function (field, i) {
        if (field['para'] !== undefined) {
          let paras: any[] = field['para'];
          let newcurrent = [];
          paras.forEach(function (para) {
            if (para !== 'DB' && para !== 'L') {
              if(para !== col.getField()) {
                if (_this.gridFrmGroup.get(para + row) !== null && _this.gridFrmGroup.get(para + row) !== undefined) {
                  newcurrent.push(_this.gridFrmGroup.get(para + row).value);
                } else {
                  newcurrent.push('');
                }
              }
            }
          });
          obj['action'].getFieldsToUpdate()[i]['current'] = newcurrent;
        }
      });
      this.throwOut(obj, row, this.grid, isAtLoad, isUpdatingList);
    }
  }

  /**
   * get validation error at the time of saving data in grid
   * @param index
   */
  getFormValidationErrors(index: number): boolean {
    let errors: AllValidationErrors[] = [];
    let _this = this;
    for (let m = 0; m < this.columns.length; m++) {
      const controlErrors: ValidationErrors = this.gridFrmGroup.get(this.columns[m].getField() + index).errors;
      if (controlErrors !== null) {
        Object.keys(controlErrors).forEach(keyError => {
          errors.push({
            control_name: _this.columns[m].getHeader(),
            error_name: keyError,
            error_value: controlErrors[keyError]
          });
        });
      }
    }
    let text = this.commonService.extractErrors(errors);
    if (text.toString().length > 0) {
      _this.messageService.add({
        severity: 'error', summary: 'Please find below validation errors \n',
        detail: text, life: 5000
      });
      return false;
    }
    return true;
  }

  /**
   * checking which filter need to apply
   * @param cond
   */
  getCondtionFilterString(cond) {
    let filter = '';
    if (cond === 'is' || cond === 'equalsto') {
      filter = 'equals';
    } else if (cond === 'isNot' || cond === 'notequalsto') {
      filter = 'notEquals'
    } else if (cond === 'contains') {
      filter = 'contains';
    } else if (cond === 'startWith') {
      filter = 'startsWith'
    } else if (cond === 'endWith') {
      filter = 'endsWith'
    } else if (cond === 'lessthan') {
      filter = 'lt'
    } else if (cond === 'lessthanequals') {
      filter = 'lte'
    } else if (cond === 'greaterthan') {
      filter = 'gt'
    } else if (cond === 'greaterthanequals') {
      filter = 'gte'
    } else if (cond === 'between') {
      filter = 'between'
    } else {
      filter = 'contains';
    }
    return filter;
  }

  /**
   * These are some custom filters like greater than equals,less than equals,between for grid
   * if there is any filter which is not available then create those filter here
   */
  createFilters() {
    let _this = this;
    FilterUtils['globalFilter'] = function inCollection(columns: Tcolumn[], index: number, rowData: any, filter: any): boolean {
      if (filter === undefined || filter === null) {
        return true;
      }
      if (columns === undefined || columns === null || columns.length === 0) {
        return false;
      }
      let included = false;
      let label = '';
      columns.forEach(function (col: Tcolumn) {
        if (!included) {
          if (environment.componentListType.includes(col.getColumnType())) {
            _this.getListOfValues(col, index).forEach(function (keyValuePair: KeyValue) {
              if (keyValuePair.getValue() === rowData[col.getField()]) {
                label = keyValuePair.getLabel();
              }
            });
            if (col.getColumnType() !== 'B') {
              included = FilterUtils.contains(label, filter);
            } else {
              included = FilterUtils.startsWith(label, filter);
            }
          } else {
            included = FilterUtils.contains(rowData[col.getField()], filter);
          }
        }
      });
      return included;
    };
    FilterUtils['gte'] = function gte(value: any, filter: any): boolean {
      if (filter === undefined || filter === null) {
        return false;
      }
      if (value === undefined || value === null || value.length === 0) {
        return false;
      }
      if (value >= filter) {
        return true;
      }
      return false;
    };
    FilterUtils['gteD'] = function gte(value: any, filter: any): boolean {
      if (filter === undefined || filter === null) {
        return false;
      }
      if (value === undefined || value === null || value.length === 0) {
        return false;
      }
      if (value >= filter) {
        return true;
      }
      return false;
    };
    FilterUtils['lte'] = function lte(value: any, filter: any): boolean {
      if (filter === undefined || filter === null) {
        return false;
      }
      if (value === undefined || value === null || value.length === 0) {
        return false;
      }
      if (value <= filter) {
        return true;
      }
      return false;
    };
    FilterUtils['between'] = function between(value: any, filter: any): boolean {
      if (filter === undefined || filter === null) {
        return false;
      }
      if (value === undefined || value === null || value.length === 0) {
        return false;
      }
      if (value >= parseInt(filter['firstValue']) && value <= parseInt(filter['secondValue'])) {
        return true;
      }
      return false;
    };
    FilterUtils['startsWithOwn'] = function startsWithOwn(value: String, filter: any): boolean {
      let sub = value.substr(0, filter.length);
      if (filter === undefined || filter === null) {
        return false;
      }
      if (value === undefined || value === null || value.length === 0) {
        return false;
      }
      if (sub === filter) {
        return true;
      }
      return false;
    };
  }

  /**
   * this function apply filter which is selected by user from preferences
   * @param firstValue
   * @param column
   * @param filtercond
   * @param filter
   */
  filterUpdates(firstValue, column, filtercond, filter: Filter) {
    let _this = this;
    if (filter.getType() === 'D') {
      if (Object.keys(firstValue).includes('firstValue')) {
        firstValue['firstValue'] = this.getDateWithFormat(column, firstValue['firstValue']);
        firstValue['secondValue'] = this.getDateWithFormat(column, firstValue['secondValue']);
      } else {
        firstValue = this.getDateWithFormat(column, firstValue);
      }
    }
    this.table.filter(firstValue, column, filtercond);
    this.table.onFilter.subscribe(function (value) {
      _this.filteredValue = value.filteredValue;
      _this.onRefreshComplete.emit("filter");
    })
  }

  /**
   * this function apply global filter
   * @param newval
   */
  updateValue(newval: any) {
    let _this = this;
    this.autoCompleteValue = newval;
    if (newval[0] !== '@') {
      this.columns.forEach(function (column: Tcolumn) {
        _this.runFilter(column, newval);
      });
    }
  }

  runFilter(column: Tcolumn, filterValue: string) {
    let _this = this;
    this.table.filteredValue = [];
    this.rowDataValues.forEach(function (rowData, index) {
      if (FilterUtils['globalFilter'](_this.columns, index, rowData, filterValue)) {
        _this.table.filteredValue.push(rowData);
      }
    });
  }

  /**
   * this function convert date in a partical forma
   * @param columnName
   * @param date
   */
  getDateWithFormat(columnName: any, date: any) {
    let format = this.getDateFormat(this.grid.getColumn().get(columnName));
    format = format.replace('DD', 'dd');
    format = format.replace('YYYY', 'yyyy');
    var formatted = this.datePipe.transform(date, format);
    return formatted;
  }

  /**
   * this function return a string to show on chip when user create a filter using prefrtences and global search
   * @param chip
   */
  getChipValue(chip: Filter) {
    let header = chip['header'];
    let cond = chip['condition']['value'];
    let firstValue = chip['firstValue'];
    let secondValue = chip['secondValue'];
    let str = header + ' ' + this.service.getConditionLabel(cond,chip['type']) + ' ';
    if (chip['type'] === 'L' || chip['type'] === 'B') {
      str += firstValue;
    } else {
      if (cond === 'between') {
        if (chip['type'] === 'D') {
          firstValue = this.getDateWithFormat(chip.getColumnName(), firstValue);
          secondValue = this.getDateWithFormat(chip.getColumnName(), secondValue);
        }
        str += firstValue + ' and ' + secondValue;
      } else {
        if (chip['type'] === 'D') {
          firstValue = this.getDateWithFormat(chip.getColumnName(), firstValue);
        }
        str += firstValue;
      }
    }

    return str;
  }

  /**
   * apply global filter
   * @param value
   */
  applyFilterGlobal(value: any) {
    this.checkForFilter(value);
  }

  /**
   * check if filter is already ther or not
   * @param filterToAdd
   */
  checkForFilter(filterToAdd: Filter) {
    let _this = this;
    let found: boolean = false;
    this.gridFilter.forEach(function (filter: Filter, index: number) {
      if (filter.getColumnName() === filterToAdd.getColumnName()) {
        found = true;
        _this.gridFilter.splice(index, 1);
        _this.gridFilter.splice(index, 0, filterToAdd);
      }
    });
    if (found === false) {
      this.gridFilter.push(filterToAdd);
    }
    this.applyFilters();
  }


  /**
   * remove filter
   * @param filter
   * @param i
   */
  removeFilter(filter: any, index: number) {
    this.gridFilter.splice(index, 1);
    this.filters.splice(index, 1);
    this.mainFilterArray.splice(index, 1);
    this.grid.setFilters(this.gridFilter);
    this.grid.removeFilter(filter['f'].getColumnName());
    // this.applyFilters();
    this.triggerFilterAPI();
    // this.triggerAdvancedFilterAPI([]);
    this.calculateHeight();
  }

  openSidebar() {
    if (this.accessPolicyService.currentGridAccess['preferences'])
      this.display = true
  }

  /**
   * change view on check of checkbox inngrid to convert view into pivot view
   * @param value
   */
  changeView(value: any) {
    this.selectedRow = [];
    this.showPivot = value['checked'];
  }

  updateCategoryName() {
    let _this = this;
    this.columns.forEach(function (column) {
      if (_this.config.categorizedBy === column.getField()) {
        _this.categoryTitle = column.getHeader();
      }
    })
  }

  /**
   * return the column type
   * like T,B,L,TA,LB,and other
   * @param field
   */
  getFieldType(field) {
    return this.grid.getColumn().get(field).getColumnType();
  }

  /**
   * return the list of particular field
   * @param field
   */
  getFieldListOfValue(field) {
    return this.grid.getColumn().get(field).getListOfValues();
  }

  /**
   * this return the tcolumn accoring to field name
   * @param field
   */
  getField(field): Tcolumn {
    return this.grid.getColumn().get(field);
  }

  /**
   *this function categorized the column according to a particualr field like status=active
   * so in obj['category']=active
   * and obj['rowData']=all the data whose status is active
   */
  updateCategoryList() {
    this.categoryList = [];
    let _this = this;
    _this.updateCategoryName();
    this.rowDataValuescopy.forEach(function (row) {
      if (!_this.categoryList.includes(row[_this.config.categorizedBy])) {
        let obj = {};
        obj['category'] = row[_this.config.categorizedBy];
        obj['rowData'] = _this.getDataForCategory(row[_this.config.categorizedBy]);
        _this.categoryList.push(obj);
      }
    })
  }

  /**
   * this function return a colpete list of rowdata according to ther categotry
   * @param category
   */
  getDataForCategory(category) {
    let _this = this;
    let templist = [];
    if (this.gridFilter.length > 0) {
      this.filteredValue.forEach(function (row) {
        if (row[_this.config.categorizedBy] === category) {
          templist.push(row);
        }
      });
    } else {
      this.rowDataValuescopy.forEach(function (row) {
        if (row[_this.config.categorizedBy] === category) {
          templist.push(row);
        }
      })
    }
    return templist;
  }

  /**
   * this return category value suppose cat. is status then retun valeu of status field value form rowdata
   * @param rowData
   */
  getCategory(rowData) {
    return rowData[this.grid.getPivotConfig().categorizedBy];
  }

  /**
   * return the value of category from listofvalue
   * @param category
   */
  getValue(category: any) {
    category = this.getCategory(category);
    if (this.getFieldType(this.config.categorizedBy) === 'L') {
      this.getFieldListOfValue(this.config.categorizedBy).forEach(function (pair) {
        if (pair['value'] === category) {
          category = pair['label'];
        }
      })
    }
    return category;
  }

  /**
   * return formula if there is any formaula applied in any column in pivot tabel
   * @param columnName
   */
  getFormula(columnName) {
    let formula = 'NONE';
    this.grid.getPivotConfig().formulaColumns.forEach(function (object) {
      if (object['column'] === columnName) {
        formula = object['formula'];
      }
    });
    return formula;
  }

  closeTabModal() {
    this.tabModalState = false;
    this.showTabs = false;
  }

  cancelTabModal() {
    this.tabModalState = false;
    this.showTabs = false;
  }

  /**
   * this function called when on action there is some action which update the metadat of any column or field
   * @param fieldname
   * @param metaname
   * @param value
   * @param source
   * @param ri
   */
  updateMetadata(fieldname: any, metaname: any, value: any, ri: number, isAtLoad: boolean, isUpdatinglist) {
    if (metaname === 'minDate') {
      if (typeof (value) === 'string') {
        value = new Date(value);
      }
      this.grid.getColumn().get(fieldname).setMinDate(value);
    } else if (metaname === 'maxDate') {
      if (typeof (value) === 'string') {
        value = new Date(value);
      }
      this.grid.getColumn().get(fieldname).setMaxDate(value);
    } else if (metaname === 'defaultValue' && this.gridFrmGroup.controls[fieldname + ri] !== undefined) {
      this.gridFrmGroup.controls[fieldname + ri].setValue(value);
      this.gridFrmGroup.controls[fieldname + ri].updateValueAndValidity();
      this.changeField(ri, this.grid.getColumn().get(fieldname), this.gridFrmGroup.get(fieldname + ri).value, isAtLoad);
    } else if (metaname === 'listvalues') {
      if (isUpdatinglist) {
        this.updateListMap(ri, value, fieldname);
      } else {
        this.grid.getColumn().get(fieldname).setListofValues(value);
      }

    } else if (metaname === 'validators') {
      if (value !== undefined && this.gridFrmGroup.controls[fieldname + ri] !== undefined) {
        this.gridFrmGroup.controls[fieldname + ri].setValidators(value);
        this.gridFrmGroup.controls[fieldname + ri].updateValueAndValidity();
      }
    } else if (metaname === 'extra') {
      if (value !== undefined) {
        if (value['meta'] !== undefined && value['meta']['disabled'] === true) {
          if (this.gridFrmGroup.controls[fieldname + ri] !== undefined) {
            this.gridFrmGroup.controls[fieldname + ri].disable();
          }
        } else {
          if (this.gridFrmGroup.controls[fieldname + ri] !== undefined) {
            this.gridFrmGroup.controls[fieldname + ri].enable();
          }
        }
      }
      this.grid.getColumn().get(fieldname).setExtra(value);
    } else if (metaname === 'columnType') {
      this.grid.getColumn().get(fieldname).setColumnType(value);
    }
  }

  // check whether the target fields in of list type or not
  // if it is list type then get list of keyvalue pair other wise
  // get data and update value
  isAnyListType(field: Tcolumn, grid: Grid) {
    let _this = this;
    let is: boolean = false;
    field.getActionToDo().getFieldsToUpdate().forEach(function (fieldname) {
      if (grid.getColumn().get(fieldname['fieldName']).getColumnType() === 'L' || grid.getColumn().get(fieldname['fieldName']).getColumnType() === 'S') {
        is = true;
      }
    });
    return is;
  }

  /**
   * check for value is undefined or null or none
   * @param value
   */
  isNullorNone(value) {
    return (value !== null && value !== undefined && value !== 'none' && value.toString().length > 0);
  }


  /**
   * get Parameter List
   * @param list
   * @param data
   * @param field
   * @param value
   */
  getParametersValue(list: any[], data: any[], field: any, value: any, rowIndex: number = 0) {
    let parac = [];
    let _this = this;
    let rowData = this.rowDataValues[rowIndex];
    if (field['para'] !== undefined) {
      field['para'].forEach(function (para) {
        if (para === 'DB') {
          parac.push(data)
        } else if (para === 'OV') {
          parac.push("")
        } else if (para === 'L') {
          list.splice(0, 0, new KeyValue('Select', ''));
          parac.push(list);
        } else {
          parac.push((Object.keys(rowData).includes(para) && rowData[para] !== undefined && rowData[para] !== null)? rowData[para] : para);
        }
      });
    } else {
      parac = [value];
    }
    return parac;
  }

  /**
   * perform action which don't require any api calling to get data
   * perform all local function and update respective fields
   * @param field
   * @param current
   * @param action
   * @param from
   * @param row
   * @param grid
   */
  performStandaloneAction(field, current, action, row, grid: Grid, isAtLoad: boolean, isUpdatinglist) {
    let data = [];
    let list = [];
    let functionvalue;
    let _this = this;
    if (field['func'] !== undefined) {
      let parac = this.getParametersValue(list, data, field, current, row);
      let data1 = this.runFunction(parac, field['func']);
      let fieldToUpdate = grid.getColumn().get(field['fieldName']);
      if (fieldToUpdate !== undefined) {
        if (fieldToUpdate.getColumnType() === 'L') {
          list = data1;
        } else {
          let obj = {};
          obj[field['key']] = data1;
          data.push(obj);
        }
        functionvalue = data1;
        if (field['metaToUpdate'] === undefined) {
          this.updateValueToField(field, list, data, action, row, grid, isAtLoad);
        }
      }
    }
    if (field['metaToUpdate'] !== undefined && field['metaToUpdate'].length > 0) {
      if (field['func'] !== undefined) {
        _this.updateMetadata(field['fieldName'], field['metaToUpdate'], functionvalue, row, isAtLoad, isUpdatinglist);
      } else {
        _this.updateMetadata(field['fieldName'], field['metaToUpdate'], current, row, isAtLoad, isUpdatinglist);
      }
    }
  }

  /**
   * detect whenenver a fields changes its value and update next field according to that field
   * new value in both form and grid
   * @param value
   * @param from  F = Form , G = Grid
   * @param row
   */
  throwOut(value: any, row: number = -1, grid: Grid, isAtLoad: boolean = false, isUpdatinglist) {
    let action: Action = value['action'];
    let current: any = value['currentValue'];
    let field1: Tcolumn = value['field'];
    let list = [];
    let _this = this;
    let key = '';
    let entityName = '';
    let criteria = [];
    let sortBy = [];
    let rowData = action.getFromExtra('rowData');
    let fetchUrl = action.getFetchUrl(rowData);
    if (fetchUrl !== undefined && fetchUrl !== null && fetchUrl.length > 0) {
      if (fetchUrl !== environment.base_url + 'filter') {
        if (fetchUrl.charAt(fetchUrl.length - 1) === '=' || fetchUrl.charAt(fetchUrl.length - 1) === '/') {
          fetchUrl += current;
        }
        if (action.getPostObjGeneratorFunc() !== undefined && action.getPostObjGeneratorFunc() !== null) {
          let postObject = this.commonService.runFunction(action.getFromExtra('rowData'), action.getPostObjGeneratorFunc());
          if(postObject !== undefined && postObject !== null && Object.keys(postObject).length > 0) {
            this.commonService.getJSONByObject(fetchUrl, postObject).subscribe(next => {
              this.runFieldToUpdate(current, value, list, action, rowData, field1, key, entityName, criteria, sortBy, row, grid, isAtLoad, next, isUpdatinglist);
            });
          }
        } else {
          if(current !== undefined && current !== null && current.toString().length > 0) {
            this.commonService.getJSONByURL(fetchUrl).subscribe(next => {
              this.runFieldToUpdate(current, value, list, action, rowData, field1, key, entityName, criteria, sortBy, row, grid, isAtLoad, next, isUpdatinglist);
            });
          }
        }
      } else {
        let keyTemp = action.getFromExtra('key');
        let entityNameTemp = action.getFromExtra('entityName');
        let criteriaTemp = action.getFromExtra('criteria');
        let rowDataTemp = action.getFromExtra('rowData');
        let sortByTemp = action.getFromExtra('sortBy');
        if ((sortByTemp === undefined || sortByTemp.length === 0) && action.getKeyField() !== undefined) {
          sortByTemp = [];
          if (action.getKeyField() !== undefined && action.getKeyField().length > 0) {
            sortByTemp.push(action.getKeyField());
          }
        }
        if (action.getPostObjGeneratorFunc() !== undefined && action.getPostObjGeneratorFunc() !== null) {
          let postObject = this.commonService.runFunction(rowDataTemp, action.getPostObjGeneratorFunc());
          if(postObject !== undefined && postObject !== null && Object.keys(postObject).length > 0) {
            this.commonService.getJSONByObject(fetchUrl, postObject).subscribe(next => {
              this.runFieldToUpdate(current, value, list, action, rowData, field1, key, entityName, criteria, sortBy, row, grid, isAtLoad, next, isUpdatinglist);
            });
          }
        } else {
          this.commonService.getJSONByObject(fetchUrl, this.commonService.getPostObject(keyTemp, current, entityNameTemp, 'equal', criteriaTemp, rowDataTemp, sortByTemp)).subscribe(next => {
            this.runFieldToUpdate(current, value, list, action, rowData, field1, key, entityName, criteria, sortBy, row, grid, isAtLoad, next, isUpdatinglist);
          });
        }
      }

    } else {
      this.runFieldToUpdate(current, value, list, action, rowData, field1, key, entityName, criteria, sortBy, row, grid, isAtLoad, undefined, isUpdatinglist);
    }
    this.onChangeFieldValue.emit(value);
  }

  runFieldToUpdate(current, value, list, action, rowData, field1, key, entityName, criteria, sortBy, row, grid, isAtGridRefresh, actionDBResponse, isUpdatinglist) {
    let _this = this;
    action.getFieldsToUpdate().forEach(function (field) {
      if (!_this.isPreparing || (_this.isPreparing && !['defaultValue'].includes(field['metaToUpdate']))) {
        if (isAtGridRefresh) {
          if (field['throw']) {
            _this.performActionAndUpdate(current, value, list, field, action, rowData, field1, key, entityName, criteria, sortBy, row, grid, isAtGridRefresh, actionDBResponse, isUpdatinglist);
          }
        } else {
          _this.performActionAndUpdate(current, value, list, field, action, rowData, field1, key, entityName, criteria, sortBy, row, grid, isAtGridRefresh, actionDBResponse, isUpdatinglist);
        }
      }
    });
  }

  performActionAndUpdate(current, value, list, field, action, rowData, field1, key, entityName, criteria, sortBy, row, grid, isAtLoad: boolean, actionDBResponse, isUpdatinglist) {
    let _this = this;
    if ((typeof (current) === 'object') || _this.isNullorNone(current) || (value['canPass'])) {
      if (!(field['fieldName'] === field1.getField() && current.length > 0)) {
        _this.performStandaloneAction(field, current, action, row, grid, isAtLoad, isUpdatinglist);
      } else {
        if (action.getFromExtra('canPass')) {
          _this.performStandaloneAction(field, current, action, row, grid, isAtLoad, isUpdatinglist);
        }
      }
      let isActionUrl: boolean = false;
      let url = '';
      let keyField = '';
      let valueField = '';
      let postObject = {};
      if (field['fetchUrl'] !== undefined && field['fetchUrl'] !== null && field['fetchUrl'].length > 0) {
        url = field['fetchUrl'];
        if (typeof (field['key']) === 'string') {
          keyField = field['key'];
        } else {
          if(field['key'] !== undefined && field['key'] !== null) {
            keyField = field['key']['label'];
            valueField = field['key']['value'];
          }
        }
        postObject = _this.commonService.getDynamicPostObject(field, rowData, field1);
      } else {
        url = action.getFetchUrl(rowData);
        isActionUrl = true;
        keyField = action.getKeyField();
        valueField = action.getValueField();
      }
      if (url !== undefined && _this.isNullorNone(current)) {
        let islist = (grid.getColumn().get(field['fieldName']).getColumnType() === 'L' || grid.getColumn().get(field['fieldName']).getColumnType() === 'S');
        if (isActionUrl && actionDBResponse !== undefined && actionDBResponse !== null) {
          if (islist && action.getCovertToListRequired()) {
            if (actionDBResponse['_embedded']) {
              actionDBResponse['_embedded'][Object.keys(actionDBResponse['_embedded'])[0]].forEach(function (result) {
                list.push(new KeyValue(result[keyField], result[valueField]));
              });
            } else {
              actionDBResponse.forEach(function (result) {
                list.push(new KeyValue(result[keyField], result[valueField]));
              });
            }
          }
          _this.updateValuetoNew(value, list, actionDBResponse, row, grid, field, isAtLoad, isUpdatinglist);
        } else {
          if (url !== environment.base_url + 'filter') {
            if (url.charAt(url.length - 1) === '=' || url.charAt(url.length - 1) === '/') {
              let valueTemp = '';
              if (action.getFromExtra('valueFormat') !== undefined) {
                valueTemp = action.getFromExtra('valueFormat').replace(new RegExp('<value>', 'g'), current);
              } else {
                valueTemp = current;
              }
              url = url + valueTemp;
            }
            if (field['postObjectFunction'] !== undefined) {
              if (postObject !== undefined && postObject !== null && Object.keys(postObject).length > 0) {
                _this.commonService.post(url, postObject).subscribe(function (data: any) {
                 list = [];
                  if (islist && action.getCovertToListRequired()) {
                    if (data['_embedded']) {
                      data['_embedded'][Object.keys(data['_embedded'])[0]].forEach(function (result) {
                        list.push(new KeyValue(result[keyField], result[valueField]));
                      });
                    } else {
                      data.forEach(function (result) {
                        list.push(new KeyValue(result[keyField], result[valueField]));
                      });
                    }
                  }
                  _this.updateValuetoNew(value, list, data, row, grid, field, isAtLoad, isUpdatinglist);
                });
              }
            } else {
              _this.commonService.getJSONByURL(url).subscribe(function (data: any) {
                if (islist  && action.getCovertToListRequired() && data !== null && data !== undefined) {
                  if (data['_embedded']) {
                    data['_embedded'][Object.keys(data['_embedded'])[0]].forEach(function (result) {
                      list.push(new KeyValue(result[keyField], result[valueField]));
                    });
                  } else {
                    data.forEach(function (result) {
                      list.push(new KeyValue(result[keyField], result[valueField]));
                    });
                  }
                }
                _this.updateValuetoNew(value, list, data, row, grid, field, isAtLoad, isUpdatinglist);
              });
            }
          } else {
            let list: any[] = [];
            sortBy = [];
            let valueTemp = '';
            if (action.getFromExtra('valueFormat') !== undefined) {
              valueTemp = action.getFromExtra('valueFormat').replace(new RegExp('<value>', 'g'), current);
            } else {
              valueTemp = current;
            }
            if (field['extra'] !== undefined) {
              if (field['extra']['key']) {
                key = field['extra']['key'];
              }
              if (field['extra']['entityName']) {
                entityName = field['extra']['entityName'];
              }
              if (field['extra']['criteria']) {
                criteria = field['extra']['criteria'];
              }
              if (field['extra']['sortBy'] !== undefined && field['extra']['sortBy'].length > 0) {
                sortBy = field['extra']['sortBy'];
              } else {
                if (keyField !== undefined && keyField.length > 0) {
                  sortBy.push(keyField);
                }
              }
            } else {
              key = action.getFromExtra('key');
              entityName = action.getFromExtra('entityName');
              criteria = action.getFromExtra('criteria');
              sortBy = action.getFromExtra('sortBy');
              if ((sortBy === undefined || sortBy.length === 0) && keyField !== undefined) {
                sortBy = [];
                if (keyField !== undefined && keyField.length > 0) {
                  sortBy.push(keyField);
                }
              }
            }
            if (field['postObjectFunction'] !== undefined) {
              if (postObject !== undefined && postObject !== null && Object.keys(postObject).length > 0) {
                _this.commonService.getJSONByObject(url, postObject).subscribe(function (data: any) {
                  if (islist) {
                    data.forEach(function (result) {
                      list.push(new KeyValue(result[keyField], result[valueField]));
                    });
                  }
                  _this.updateValuetoNew(value, list, data, row, grid, field, isAtLoad, isUpdatinglist);
                });
              }
            } else {
              _this.commonService.getJSONByObject(url, _this.commonService.getPostObject(key, valueTemp, entityName, 'equal', criteria, rowData, sortBy)).subscribe(function (data: any) {
                if (islist) {
                  data.forEach(function (result) {
                    list.push(new KeyValue(result[keyField], result[valueField]));
                  });
                }
                _this.updateValuetoNew(value, list, data, row, grid, field, isAtLoad, isUpdatinglist);
              });
            }
          }
        }

      }
    } else {
      let data = [];
      if (current !== 'none' && current !== '') {
        _this.updateValuetoNew(value, list, data, row, grid, field, isAtLoad, isUpdatinglist);
      }
    }
  }

  /**
   * applying new value to a particualr field in both grid and form
   * @param object
   * @param list
   * @param data
   * @param action
   * @param from
   * @param rowNo
   * @param grid
   */
  updateValueToField(object, list, data, action: Action, rowNo, grid: Grid, isAtLoad: boolean) {
    let fieldname = object['fieldName'];
    let getValueFrom = object['getValueFrom'];
    let valueTemp = '';
    let key = object['key'];
    let _this = this;
    let updateToDatabase = object['updateToDatabase'];
    let ftup = grid.getColumn().get(fieldname);
    if (ftup !== undefined) {
      if (ftup.getColumnType() === 'L') {
        if (object['key'] !== undefined && typeof (object['key']) === 'string' && object['key'].length > 0) {
          if (data.length > 0) {
            valueTemp = data[0][object['key']];
          }
          this.applyValues(fieldname, rowNo, valueTemp, updateToDatabase, isAtLoad);
        } else {
          if (action.getAddDefault().length > 0) {
            action.getAddDefault().forEach(function (defaultV) {
              list.splice(0, 0, defaultV);
            })
          } else {
            if ((list.length === 0 || (list[0]['value'] !== 'none' && list[0]['value'] !== '')) && (action.getFromExtra('addSelect') === undefined || !action.getFromExtra('addSelect'))) {
              list.splice(0, 0, new KeyValue('Select', ''));
            }
          }
          let num = rowNo;
          if (this.addInline) {
            num = -1;
          }
          // let obj: Map<any, any>;
          // if (this.listOfValues.has(num)) {
          //   obj = this.listOfValues.get(num);
          // } else {
          //   obj = new Map();
          // }
          // obj.set(fieldname, list);
          // this.listOfValues.set(num, obj);
          ftup.setListofValues(list);
        }
      }
      else {
        let value: any = '';
        if (data !== undefined) {
          if (data['_embedded']) {
            data['_embedded'][Object.keys(data['_embedded'])[0]].forEach(function (result) {
              value = result[key];
            });
          } else {
            if (typeof (data) === 'object') {
              if (data.length > 0) {
                value = data[0][key]
              }
            } else {
              value = data;
            }
          }
          if (ftup.getColumnType() === 'D' && value !== undefined && value !== null && value.toString().length > 0) {
            value = new Date(value);
          }
        }
        if (value !== undefined && value.toString().length > 0) {
          if (getValueFrom !== undefined) {
            if (getValueFrom['url'] !== environment.base_url + 'filter') {
              this.commonService.getJSONByURL(getValueFrom['url']).subscribe(function (data: any) {
                if (data['_embedded']) {
                  data['_embedded'][Object.keys(data['_embedded'])[0]].forEach(function (result) {
                    if (result[getValueFrom['key']].toString() === value.toString()) {
                      _this.applyValues(fieldname, rowNo, result[getValueFrom['value']], updateToDatabase, isAtLoad);
                      return;
                    }
                  });
                } else {
                  data.forEach(function (result) {
                    if (result[getValueFrom['key']].toString() === value.toString()) {
                      _this.applyValues(fieldname, rowNo, result[getValueFrom['value']], updateToDatabase, isAtLoad);
                      return;
                    }
                  });
                }
              });
            } else {
              this.commonService.getJSONByObject(getValueFrom['url'], _this.commonService.getPostObject(getValueFrom['entityKey'], valueTemp, getValueFrom['entityName'], getValueFrom['operation'], getValueFrom['criteria'])).subscribe(function (data: any) {
                data.forEach(function (result) {
                  if (result[getValueFrom['key']].toString() === value.toString()) {
                    _this.applyValues(fieldname, rowNo, result[getValueFrom['value']], updateToDatabase, isAtLoad);
                    return;
                  }
                });
              });
            }
          } else {
            _this.applyValues(fieldname, rowNo, value, updateToDatabase, isAtLoad);
          }
        } else {
          _this.applyValues(fieldname, rowNo, '', updateToDatabase, isAtLoad);
        }
      }
    }
  }

  /**
   * apply all new value which is fethced from the db to specified fields
   * @param fieldname
   * @param rowNo
   * @param value
   * @param updateToDatabase
   * @param from
   */
  applyValues(fieldname, rowNo, value, updateToDatabase, isAtLoad: boolean) {
    if (this.gridFrmGroup.controls[fieldname + rowNo] !== undefined) {
      this.gridFrmGroup.controls[fieldname + rowNo].setValue(value);
      this.rowDataValues[rowNo][fieldname] = value;
      this.changeField(rowNo, this.grid.getColumn().get(fieldname), this.gridFrmGroup.get(fieldname + rowNo).value, isAtLoad);
      if (updateToDatabase) {
        this.gridService.modifyGridRow(this.rowDataValues[rowNo], this.rowDataValues[rowNo]['href']).subscribe((result: any) => {
        });
      }
    }
  }

  updateValuetoNew(value, list: any[], data: any[], rowNo: number = -1, grid: Grid, field, isAtLoad: boolean, isUpdatinglist) {   //function to update values when some other fields changes ites value
    let _this = this;
    let action = value['action'];
    let functionvalue: any;
    if (field['func'] !== undefined) {
      let parac = this.getParametersValue(list, data, field, value['currentValue'], rowNo);
      let data1 = _this.runFunction(parac, field['func']);
      let ftup = grid.getColumn().get(field['fieldName']);
      if (ftup.getColumnType() === 'L') {
        list = data1;
      } else {
        if (field['key'] !== undefined) {
          let obj = {};
          obj[field['key']] = data1;
          data.push(obj);
        }
      }
      functionvalue = data1;
      if (field['metaToUpdate'] === undefined) {
        _this.updateValueToField(field, list, data, action, rowNo, grid, isAtLoad);
      } else {
        if (field['metaToUpdate'].length > 0) {
          _this.updateMetadata(field['fieldName'], field['metaToUpdate'], functionvalue, rowNo, isAtLoad, isUpdatinglist);
        }
      }
    } else {
      if (field['metaToUpdate'] !== undefined && field['metaToUpdate'].length > 0) {
        let value: any;
        if (data['_embedded']) {
          data['_embedded'][Object.keys(data['_embedded'])[0]].forEach(function (result) {
            value = result[field['key']];
          });
        } else {
          if (data.length > 0) {
            value = data[0][field['key']]
          }
        }
        if (grid.getColumn().get(field['fieldName']).getColumnType() === 'D') {
          if (value === undefined || value === null) {
            value = '';
          } else {
            value = new Date(value.toString());
          }
        }
        _this.updateMetadata(field['fieldName'], field['metaToUpdate'], value, rowNo, isAtLoad, isUpdatinglist);
      } else {
        _this.updateValueToField(field, list, data, action, rowNo, grid, isAtLoad);
      }
    }
  }

  /**
   * this function is used to run the function if there is any defined in action
   * @param value
   * @param callback
   */
  runFunction(value: any[], callback) {
    return callback(value);
  }

  /***
   * returns dropdown list values for a particular row
   * @param col
   * @param ri
   */
  getListOfValues(col: Tcolumn, ri) {
    let lov = col.getListOfValues();
    if (this.addInline && ri > 0) {
      ri--;
    }
    if (this.listOfValues.has(ri)) {
      let fieldname = col.getField();
      let obj: Map<string, any> = this.listOfValues.get(ri);
      if (obj.has(fieldname)) {
        lov = obj.get(fieldname);
      }
    }
    return lov;
  }

  getLabel(b: boolean, col: Tcolumn) {
    let lab = '';
    col.getListOfValues().forEach(function (par: KeyValue) {
      if (par.getValue() === b) {
        lab = par.getLabel();
      }
    });
    return lab;
  }

  getCategorizedBy(pivotConfig: pconfig) {
    return pivotConfig['_categorizedBy'];
  }

  getIndex(row) {
    let index = 0;
    let cat = this.grid.getPivotConfig().categorizedBy;
    cat = row[cat];
    index = this.rowGroupMetadata[cat]['index'];
    return index;
  }

  getCategoryList(categoryby) {
    let list = [];
    this.rowDataValues.forEach(function (row) {
      if (list.includes(row[categoryby])) {
        list.push(row[categoryby]);
      }
    });
    return list;
  }

  getToggleText() {
    if (this.showFetching) {
      return 'Please wait';
    } else {
      return 'No data available';
    }
  }

  getDownloadUrl(rowData: any, col: Tcolumn) {   //get thedownload url for downloading any file from grid
    let ccode = '';
    col.getFromExtra('downloadUrl')['para'].forEach(function (paras, i) {
      ccode += rowData[paras];
      if (i < col.getFromExtra('downloadUrl')['para'].length - 1) {
        ccode += '&';
      }
    });
    let url = col.getFromExtra('downloadUrl')['url'] + ccode;
    window.open(url, '_blank');
  }

  handleFileInput(files: File[], ri, col: Tcolumn, value1: any) {   //file picker event converting file into byte array update name and type of file
    let _this = this;
    if (files !== undefined && files.length > 0) {
      let reader = new FileReader();
      let file = files[0];
      reader.readAsDataURL(file);
      reader.onload = (event2) => {
        const uploadData = new FormData();
        uploadData.append('file', file, file.name);
        _this.commonService.getByteArray(environment.base_url + '/api/company/v1/upload/logo', uploadData).subscribe(function (data: any) {
          _this.gridFrmGroup.controls[col.getField() + ri].setValue(data.response);
          _this.rowDataValues[ri][col.getField()] = data.response;
          let obj = {field: col, currentValue: file, action: col.getActionToDo(), type: 'action'};
          _this.throwOut(obj, ri, _this.grid, false, false);
        })
      };
    }
  }

  comingSoon(operation: string) {
    if ((operation == 'import' && !this.accessPolicyService.currentGridAccess['import']) ||
      (operation == 'print' && !this.accessPolicyService.currentGridAccess['print']) ||
      (operation == 'audittrail' && !this.accessPolicyService.currentGridAccess['audittrail'])) {
      return;
    }
    this.messageService.add({
      severity: 'info', summary: messages.coming_soon['summary'],
      detail: messages.coming_soon['message']
    });
  }

  downloadSelected() {

    if (this.selectedRow !== undefined && this.selectedRow.length > 0) {
      // var rowValues = this.rowDataValuesTemp;
      // var selectedRow = [];
      // var isFiltered = Array.isArray(this.table.filteredValue) && this.table.filteredValue.length < this.table._selection.length ? true : false;
      // if (isFiltered) {
      //   selectedRow = this.table.filteredValue;
      // } else {
      //   selectedRow = Object.assign([], this.table._selection);
      // }
      // var selectedRowTemp = [];
      // selectedRow.forEach(function (row) {
      //   rowValues.forEach(function (rowTemp) {
      //     if (row['rowNum'] == rowTemp['rowNum']) {
      //       selectedRowTemp.push(rowTemp);
      //     }
      //   });
      // });
      //
      //
      // this.table._selection = selectedRowTemp;
      //
      // this.table.exportCSV({selectionOnly: true});
      this.exportAll('xlsx',this.table.selection);

      // this.table._selection = selectedRow;

    } else {
      this.messageService.add({
        severity: 'info', summary: messages.no_row_selected['summary'],
        detail: messages.no_row_selected['message']
      });

    }
  }

  exportAll(type,data:any[] = []) {
    this.excelService.exportAll(this.importTableName, JSON.parse(JSON.stringify(data)), type, this.masterCommonService.getGridField(this.grid));
  }

  getDateFormat(col: Tcolumn) {
    let format = this.dateFormat;
    if (col.getFromExtra('date')['format'] !== undefined) {
      format = col.getFromExtra('date')['format'];
    }
    return format.toString().toUpperCase();
  }

  updateValidator(col: Tcolumn) {
    if(col !== undefined && col !== null && col.getDefaultValue() !== undefined && col.getDefaultValue() !== null) {
      let includedList: any[] = col.getDefaultValue()['_includedCharacters'];
      if (includedList !== undefined && includedList.length > 0) {
        let reg = '^[A-Za-z';
        if (col.getDefaultValue()['_isNumericAllowed']) {
          reg += '0-9';
        }
        includedList.forEach(function (ch) {
          if (ch === '\\') {
            reg += '\\';
          }
          reg += ch;
        });
        reg += ']+$';
        let pattern = new RegExp(reg);
        let preValidators: any = this.grid.getColumn().get(col.getField()).getValidators();
        preValidators.push(Validators.pattern(pattern));
        this.grid.getColumn().get(col.getField()).setValidators(preValidators);
      }
    }
  }

  getTooltipMessage(control: AbstractControl, col) {
    return this.commonService.getValidationErrorMessage(control, col.getHeader());
  }

  throwChangeIndex(value: any) {
    this.onChangeIndex.emit(value);
  }

  private reset() {
      this.rowDataValues=[];
      this.table.editingRowKeys = {};
      this.gridFilter = this.grid.getFilters();
      this.gridFrmGroup = new FormGroup({});
      this.table.reset();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refreshForm = false;
    if(changes['rowValues'] && this.grid.gridFetchUrlType==='LOCAL') {
      this.allData = changes['rowValues'].currentValue;
      this.reset();
      if(this.allData.length>0) {
        this.getGridData(true);
      } else {
        this.onRefreshComplete.emit("LOCAL");
      }
    }

    if (changes['rowValues'] && this.grid.gridFetchUrlType!=='LOCAL') {
      let sortMeta = this.table.multiSortMeta;
      this.reset();
      this.columnsFromParent = this.grid.getColumn();
      this.applyGrid(changes['rowValues'].currentValue, sortMeta, true, true, false)
    }

    if (changes['performAction']) {
      let _this = this;
      if (changes['performAction'].currentValue === true) {
        this.grid.getColumn().forEach(function (col: Tcolumn) {
          if (col.getActionToDo() !== undefined) {
            let val = col.getDefaultValue();
            col.getActionToDo().getFieldsToUpdate().forEach(function (fields) {
              if (fields['throwAtTabRefresh'] === true) {
                _this.checkActions(col, val, -2, true);
              }
            });
          }
        });
      }
    }

    if(changes['openFormForNewEntry'] && changes['openFormForNewEntry'].currentValue) {
      this.openModal();
      this.onFormOpenDone.emit();
    }
    if (changes['refreshGrid'] && changes['refreshGrid'].currentValue === true) {
      this.number = 0;
      this.resetTable();
      // this.getGridData(true);
      this.refreshGridData();
      this.refreshForm = true;
    }
    if (changes['refreshFilter'] && changes['refreshFilter'].currentValue === true) {
      this.applyFilters();
    }
    if (changes['search']) {
      if (changes['search'].currentValue !== undefined && changes['search'].currentValue !== null && changes['search'].currentValue.length > 0) {
        this.updateValue(changes['search'].currentValue);
      }
    }
    if (changes['openInputForm']) {
      if (changes['openInputForm'].currentValue !== undefined && changes['openInputForm'].currentValue !== null) {
        this.openInModal([changes['openInputForm'].currentValue['rowData']], true);
      }
    }
    if (changes['extraButtons']) {
      if (this.currentRowData !== undefined && this.currentRowData !== null) {
        this.prepCm(this.currentRowData, changes['extraButtons'].currentValue);
      }
    }
    if (changes['recalculateGridHeight']) {
      if (changes['recalculateGridHeight']) {
        this.calculateHeight();
      }
    }
  }

  getDisplaySuggestion(value: string) {
    let display: boolean = false;
    if (value !== undefined && value.length > 0) {
      display = true;
    }
    return display;
  }

  getColumnMultipleValue(col: Tcolumn, rowDatum: any, ri) {
    let sp = rowDatum.toString().split(',');
    let lb = '';
    let _this = this;
    sp.forEach(function (list, i) {
      if (i !== 0) {
        lb += ', ';
      }
      lb += _this.getColumnValue(col, list, ri);
    });
    return lb;
  }

  handleClick(value: any) {
    this.addBttnValue(value);
    this.masterCommonService.setAddBtnValue(value);
    this.masterCommonService.setModelStat(true);
  }

  addBttnValue(value: any) {
    this.addButtonValue = value;
    this.addNewModalState = true;
  }

  canceledNew() {
    this.addNewModalState = false;
  }

  /**
   * work to do when click on add button in form and after saving data
   * then add newly added data in list of field
   * @param value
   * @param field
   * @constructor
   */
  OnFormClose(value: any, field: string) {
    let action: Action = this.masterCommonService.getFieldAction(field);
    let option: KeyValue = new KeyValue(value['data'][action.getKeyField()], value['data'][action.getValueField()]);
    let listOfValue = this.grid.getColumn().get(field).getListOfValues();
    let found = false;
    let _this = this;
    listOfValue.forEach(function (kvpair: KeyValue, index) {
      if (kvpair.getValue() === option.getValue()) {
        found = true;
        listOfValue.splice(index, 1);
        listOfValue.splice(index, 0, option);
        return;
      }
    });
    if (found === false) {
      listOfValue.push(option);
    }
    this.grid.getColumn().get(field).setListofValues(listOfValue);
    if (value['close'] === true) {
      this.addNewModalState = false;
    }
  }

  getListFieldValue(col: Tcolumn, value, ri) {
    /**
     * this method returns value of list
     * from key values pair
     *
     **/
    if (col.getColumnType() === 'ML') {
      return (value !== null && value !== undefined && value.toString().length > 0) ? this.getColumnMultipleValue(col, value, ri) : '';
    } else if(col.getColumnType() === 'OB'){
      return (value !== null && value !== undefined && value.toString().length > 0) ? value : '';
    }
    else {
      return (value !== null && value !== undefined && value.toString().length > 0) ? this.getColumnValue(col, value, ri) : '';
    }
  }

  getLongTooltip(value) {
    /**
     * this method return the tooltip message for
     * long descritions
     **/
    return (value !== null && value !== undefined && value.length > this.maxLength) ? value : '';
  }

  getRowDataValue(col: Tcolumn, value, ri) {
    /**
     * This a comment for this method
     *
     **/
    if (col.getColumnType() === 'B') {
      return this.getListFieldValue(col, value, ri);
    } else {
      return value;
    }
  }

  toggleModalState() {
    this.modalState = !this.modalState;
    this.editUrl = '';
  }

  onNavigationClick(value: any) {
    /**
     * This a comment for this method
     *
     **/
    this.showToolbar = true;
    if (value !== 0) {
      this.showToolbar = false;
    }
  }

  getDateForOutput(date: any, col: Tcolumn) {
    if (date.toString() !== 'Invalid Date') {
      return this.datePipe.transform(date, col.getFromExtra('date')['format'] !== undefined ? col.getFromExtra('date')['format'] : environment.dateFormat);
    }
  }

  handleButtonClick(value: any, col: Tcolumn, rowData) {
    this.onButtonClick.emit({buttonHeader: col.getHeader(), screenName: this.grid.getTitle(), rowData: rowData});
  }

  onClick(value) {
    this.onButtonClick.emit(value);
  }

  openDealSlip() {
    this.dealSlipEmitter.emit(true);
  }

  isMultiple(field: Tcolumn) {
    return typeof (field.getFromExtra('multiple')) === 'object' ? false : field.getFromExtra('multiple');
  }

  /**
   * return weheter action needed
   */
  showActionColumn() {
    return (!(!this.grid.getGridConfig().canAdd && !this.grid.getGridConfig().canCopy && !this.grid.getGridConfig().canDelete && !this.grid.getGridConfig().canEdit) && this.showActions) || this.grid.isHaveExtraButtons;
  }

  /**
   * return the class name for left fixed columns for Headers
   * @param col
   */
  getClassForFixHeader(col: Tcolumn) {
    let classname = '';
    if (col!==null && col!==undefined && col.getField() === this.grid.getPrimaryKey() ) {
      classname = 'StickLeftHeader';
      if (this.grid.showCheckbox) {
        if (this.showActionColumn()) {
          classname += ' StickLeftCheckboxAction';
        } else {
          classname += ' StickLeftOnlyCheckbox';
        }
      } else {
        if (this.showActionColumn()) {
          classname = 'StickLeftOnlyAction';
        }
      }
    }
    return classname;
  }

  /**
   * return the class name for left fixed columns for Body
   * @param col
   */
  getClassForFixBody(col: Tcolumn) {
    let classname = '';
    if (col.getField() === this.grid.getPrimaryKey()) {
      classname = 'StickLeftBody';
      if (this.grid.showCheckbox) {
        if (this.showActionColumn()) {
          classname += ' StickLeftBodyCheckboxAction';
        } else {
          classname += ' StickLeftBodyOnlyCheckbox';
        }
      } else {
        if (this.showActionColumn()) {
          classname = 'StickLeftBodyOnlyAction';
        }
      }
    }
    return classname;
  }

  /**
   * return the class name for left fixed columns for Headers
   * @param col
   */
  getActionHeaderClass() {
    if(this.grid.showCheckbox) {
      if(this.isExpandGridAvailable()) {
        return 'StickLeftWithCheckboxAndExpand';
      } else {
        return 'StickLeftWithCheckbox';
      }
    } else {
      if(this.isExpandGridAvailable()) {
        return 'stickLeftHeaderAndExpand';
      } else {
        return 'stickLeftHeader';
      }
    }
  }

  getExpandHeaderClass() {
    return this.grid.showCheckbox?'StickLeftWithCheckbox':'StickLeftHeader';
  }

  isExpandGridAvailable() {
    return ((this.grid.expandGrid !== null && this.grid.expandGrid !== undefined) || this.showExpandGridIcon);
  }

  /**
   * return the class name for left fixed columns for Headers
   * @param col
   */
  getActionBodyClass() {
    if(this.grid.showCheckbox) {
      if(this.isExpandGridAvailable()) {
        return 'StickLeftBodyWithCheckboxAndExpand';
      } else {
        return 'StickLeftBodyWithCheckbox';
      }
    } else {
      if(this.isExpandGridAvailable()) {
        return 'stickLeftBodyAndExpand';
      } else {
        return 'stickLeftBody';
      }
    }
  }

  getExpandBodyClass() {
    return this.grid.showCheckbox?'StickLeftBodyWithCheckbox':'StickLeftBody';
  }

  getSpanClass(col: Tcolumn) {
    let className = '';
    if (this.grid.getPrimaryKey() === col.getField() && this.grid.primaryKeyClickable) {
      className = 'link';
      // if ((this.grid.getInfoUrl() !== undefined && this.grid.getInfoUrl().length > 0) || (this.grid.getTabs() !== undefined && this.grid.getTabs().length > 0) || (this.grid.getClickable() === col.getField())) {
      //   className = 'link';
      // }
    }
    return className;
  }

  openHierarchy(selectedRow: any[]) {
    this.heirarchyEmitter.emit(selectedRow);
  }

  getBooleanClass(rowDatum: any, col: Tcolumn) {
    let className: string = '';
    if (this.grid.getClickable() !== undefined && this.grid.getClickable() !== null && this.grid.getClickable().includes(col.getField())) {
      className = 'link';
    }
    if (rowDatum) {
      className += 'activeBox';
    } else {
      className += 'inactiveBox';
    }
    return className;
  }

  isDisable(allowed:string = '') {
    if(this.selectedRow !== undefined && this.selectedRow !== null && this.selectedRow.length > 0) {
      if(allowed.length > 0) {
        if(this.accessPolicyService.currentGridAccess[allowed]) {
          return !(this.selectedRow.length === 1);
        } else {
          return true;
        }
      }
      return !(this.selectedRow.length === 1);
    } else {
      return true;
    }
  }

  getEditIcon() {
    return this.grid.getGridConfig().canEdit && this.canEditRow(this.selectedRow[0])? 'fas fa-pen' : 'fas fa-eye';
  }

  getEditLabel() {
    return this.grid.getGridConfig().canEdit && this.canEditRow(this.selectedRow[0]) ? 'Edit' : 'View';
  }

  showBack() {
    return (this.grid.getFirstLevelDataUrl() !== undefined && this.grid.getFirstLevelDataUrl().length > 0);
  }

  changeRouteToBack() {
    this.route.navigate([this.grid.getFirstLevelDataUrl()]);
    this.commonService.saveToStorage('lastRoute', {
      routeTo: this.grid.getFirstLevelDataUrl(),
      skip: true,
      params: {}
    });
    this.breadCrumbService.popBreadcrumb();
  }

  getClassForGrid() {
    if (this.showSectionTop) {
      return 'sectionCard';
    }
    return 'sectionTabGrid';
  }

  refreshGridData() {
    if (!this.showFetching) {
      if(this.grid.actionFunc.resetFunction !== null && this.grid.actionFunc.resetFunction !== undefined) {
        this.commonService.runFunction({},this.grid.actionFunc.resetFunction);
      }
      this.number = 0;
      this.selectedTradeOption = '';
      this.rowDataValues = [];
      this.gridFrmGroup = new FormGroup({});
      this.table.value = [];
      this.totalElements = 0;
      this.resetTable();
      let filtersKeys:any[] = [];
      if(this.filterValues !== null && this.filterValues !== undefined) {
        filtersKeys = Object.keys(this.filterValues)
      }
      if((this.filterOnPageChange === null || this.filterOnPageChange === undefined || this.filterOnPageChange.length === 0) && filtersKeys.length == 0) {
        if (this.getFromUrl && this.grid.gridFetchUrlType !== 'LOCAL') {
          this.getGridData(true);
        } else {
          this.onClickedRefresh.emit();
        }
      } else {
        this.triggerAdvancedFilterAPI(this.filterOnPageChange,false);
      }
    }
  }


  getFormControl(controlName: string) {
    return this.gridFrmGroup.controls[controlName];
  }

  getFileName(col: Tcolumn, ri) {
    let fileName = '';
    if (col.getColumnType() === 'F') {
      if(this.gridFrmGroup.controls[col.getField() + 'FileName' + ri] !== undefined && this.gridFrmGroup.controls[col.getField() + 'FileName' + ri] !== null){
        fileName = this.gridFrmGroup.controls[col.getField() + 'FileName' + ri].value;
      }
    }
    return fileName;
  }

  isModalChange(col: Tcolumn) {
    return (this.onChangeFields.includes(col.getColumnType()));
  }

  isFocusOut(col: Tcolumn) {
    return (this.onFocusFields.includes(col.getColumnType()));
  }

  getNumberOfColumns() {
    return this.columns.length <= 5 ? 4 : this.columns.length <= 8 ? 2 : 3;
  }

  isNotLast(index: number) {
    return (index < this.columns.length - 1);
  }

  isClickable(col: Tcolumn) {
    return (this.grid.getClickable() !== undefined && this.grid.getClickable() !== null && this.grid.getClickable().includes(col.getField())  || (this.grid.getPrimaryKey() === col.getField() && this.grid.primaryKeyClickable));
  }

  getOutputValue(col: Tcolumn, rowIndex: number, rowData: any) {
    let outputValue = this.commonService.checkAndGetValue(col, rowData);
    if (outputValue === undefined || outputValue === null || outputValue.length === 0) {
      if (col.getColumnType() === 'OB' || col.getColumnType() === 'B') {
        if (rowData[col.getField()] !== undefined && rowData[col.getField()] !== null) {

          if (typeof (rowData[col.getField()]) === 'object') {
            outputValue = rowData[col.getField()]['name'];
          } else {
            outputValue = this.getListFieldValue(col, rowData[col.getField()], rowIndex);
          }
        }
      } else if (col.getColumnType() === 'N'){
        outputValue = this.commonService.getFormatedNumber(rowData[col.getField()],'en-US');
      } else if (col.getColumnType() === 'T' || col.getColumnType() === 'TA' || col.getColumnType() === 'LB' || col.getColumnType() === 'S' || col.getColumnType() === 'TN' || col.getColumnType() === 'SL') {
        outputValue = rowData[col.getField()];
      } else if (col.getColumnType() === 'C') {  // for checkbox
        outputValue = rowData[col.getField()];
      } else if (col.getColumnType() === 'D') {  // for date
        outputValue = this.getDateForOutput(rowData[col.getField()], col)
      } else if (col.getColumnType() === 'F') {    //for filepicker
        outputValue = rowData[col.getField() + 'FileName'];
      } else if (col.getColumnType() === 'DSL') {  //for dual slider
        outputValue = this.commonService.getDualSliderValue(rowData[col.getField()], col, rowData);
      } else {
        outputValue = this.getRowDataValue(col, rowData[col.getField()], rowIndex);
      }
    }
    if (this.rowDataValuesTemp != undefined && this.rowDataValuesTemp[rowIndex] != undefined)
      this.rowDataValuesTemp[rowIndex][col.getField()] = this.gridFrmGroup.controls[col.getField() + rowIndex].value;
    return (outputValue === undefined || outputValue === null || outputValue.length === 0) ? '-' : outputValue;
  }

  onClickOutput(col: Tcolumn, rowData: any, rowIndex: number) {
    if (this.isClickable(col)) {
      if (col.getColumnType() === 'L' || col.getColumnType() === 'OB' || col.getColumnType() === 'B' || col.getColumnType() === 'C' || col.getColumnType() === 'ML' || col.getColumnType() === 'DSL' || col.getColumnType() === 'SL') {
        this.changeRoute(col, rowData, this.getColumnValue(col, rowData[col.getField()], rowIndex), rowIndex);
      } else if (col.getColumnType() == 'T' || col.getColumnType() == 'N' || col.getColumnType() == 'TA' || col.getColumnType() == 'LB' || col.getColumnType() == 'S' || col.getColumnType() == 'TN' || col.getColumnType() == 'D') {
        this.changeRoute(col, rowData, rowData[col.getField()], rowIndex);
      } else if (col.getColumnType() === 'F') {
        this.getDownloadUrl(rowData, col);
      }
    }
  }

  getRowClass(operation: string, rowData) {
    if (operation == 'edit') {
      if(this.accessPolicyService.currentGridAccess['edit']) {
        if (this.canEditRow(rowData)) {
          return 'fas fa-pen editicon actionIcon';
        }
      }
      return 'fas fa-pen editicon actionIcon greyout';
    } else if (operation == 'copy') {
      if(this.accessPolicyService.currentGridAccess['copy']) {
        return 'fas fa-copy copyicon actionIcon';
      }
      return 'fas fa-copy copyicon actionIcon greyout';
    } else if (operation == 'delete') {
      return (!this.newRow.get(rowData['rowNum'])) ? 'fas fa-minus-circle p-1 deleteicon actionIcon' : 'fas fa-minus-circle p-1 deleteicon actionIcon greyout';
    }
  }

  getClass(col: Tcolumn, rowData: any) {
    let className = '';
    if (col.getColumnType() === 'F') {
      className += 'link';
    } else {
      if (this.isClickable(col)) {
        className += 'link';
      }
      if (col.getColumnType() === 'B') {
        if (rowData[col.getField()]) {
          className += 'activeBox';
        } else {
          className += 'inactiveBox';
        }
      } else if (col.getColumnType() === 'OB' && col.getField() === 'tradeTransactionType') {
        if (rowData[col.field] === 'BUY') {
          className += 'activeBox';
        } else if (rowData[col.field] === 'SELL') {
          className += 'inactiveBox';
        } else {
          className += 'neutralBox';
        }
      }
    }
    return className;
  }

  getToolTip(col: Tcolumn, rowData: any, rowIndex: number) {
    if (rowData !== undefined && rowData !== null) {
      if (col.getColumnType() == 'L' || col.getColumnType() == 'OB' || col.getColumnType() == 'B' || col.getColumnType() == 'C' || col.getColumnType() == 'ML') {
        return this.getLongTooltip(this.getListFieldValue(col, rowData[col.getField()], rowIndex));
      } else if (col.getColumnType() == 'T' || col.getColumnType() == 'N' || col.getColumnType() == 'TA' || col.getColumnType() == 'LB' || col.getColumnType() == 'S' || col.getColumnType() == 'TN' || col.getColumnType() == 'SL') {
        return this.getLongTooltip(rowData[col.getField()]);
      } else if (col.getColumnType() === 'F') {
        return this.getFileName(col, rowIndex);
      }
    }
  }

  changeRouteNoData() {
    this.route.navigate([this.noDataRoute], {
      skipLocationChange: true
    });
  }

  deepCopyFunction = (inObject) => {
    let outObject, value, key;

    if (typeof inObject !== "object" || inObject === null) {
      return inObject // Return the value if inObject is not an object
    }

    // Create an array or object to hold the values
    outObject = Array.isArray(inObject) ? [] : {};

    for (key in inObject) {
      value = inObject[key];

      // Recursively (deep) copy for nested objects, including arrays
      outObject[key] = this.deepCopyFunction(value)
    }

    return outObject
  };

  import() {
    this.displayImport = true;
    this.parentKeyValue = this.grid.getDataURL().substr(this.grid.getDataURL().lastIndexOf('=') + 1);
  }

  closeImport() {
    this.displayImport = false;
  }

  importDone(success: boolean) {
    this.displayImport = false;
    this.refreshGridData();
    if (success) {
      this.messageService.add({
        severity: 'info', summary: messages.updated['summary'],
        detail: messages.importCompleted['message'],
        life: 10000
      });

    }
  }


  prepCm(rowData, buttonList: Button[]) {
    this.contextMenuList = [];
    let _this = this;
    let updateUrl = '';
    if (rowData['href'] !== undefined && rowData['href'] !== null) {
      updateUrl = rowData['href'];
    } else {
      updateUrl = this.grid.getSaveUrl() + '/' + rowData['uuid'];
    }
    buttonList.forEach(function (buttonExtra: Button) {
      _this.contextMenuList.push(_this.getMenuItem(buttonExtra,rowData,updateUrl));
    });
    this.currentEvent.preventDefault();
    this.currentEvent.stopPropagation();
    this.currentContextMenu.show(this.currentEvent);
  }

  private getMenuItem(button:Button,rowData:any,updateUrl) {
    let _this = this;
    let items:MenuItem[] = [];
    if(button.sublist !== null && button.sublist !== undefined && button.sublist.length > 0) {
      button.sublist.forEach((button1:Button) => {
        items.push(_this.getMenuItem(button1,rowData,updateUrl));
      });
    }
    let menuItem: MenuItem = {
      label: button.title,
      disabled: button.disabled,
      items:items.length === 0?null:items,
      command: (event) => {
        let newData = _this.commonService.runFunction({
          data: rowData,
          updateUrl: updateUrl,
          saveUrl: _this.saveUrl,
          extra:button.extra
        }, button.onClick);
      }
    };
    return menuItem;
  }

  onRowSelect() {
    this.selectedTradeOption = '';
    this.selectedRows.emit(this.selectedRow);
  }

  getRowDataForCheckbox(rowData: any, index: any) {
    if (this.selectRowFunction !== undefined && this.selectRowFunction !== null) {
      if (this.commonService.runFunction(rowData, this.selectRowFunction)) {
        this.selectedRow.push(rowData);
        this.table.selectionKeys[index + 1] = 1;
        this.onRowSelect();
      }
    }
  }

  getStyle(col: Tcolumn, index, zIndex = '400', id) {
    let style: any;
    let width;
    let element: HTMLElement;
    let colWidth: number = 0;
    let _this = this;
    element = document.getElementById(id);
    if (element !== undefined && element !== null) {
      colWidth = element.offsetWidth;
    }
    if (this.leftFixedColumns.includes(col.getField())) {
      this.leftFixedColumnsWidth[this.leftFixedColumns.indexOf(col.getField())] = colWidth;
      width = this.getFixColumnWidth(index, col);
      style = {
        position: 'sticky',
        left: width + 'px',
        zIndex: zIndex
      };
    } else if (this.rightFixedColumns.includes(col.getField())) {
      this.rightFixedColumnsWidth[this.rightFixedColumns.indexOf(col.getField())] = colWidth;
      width = this.getFixColumnWidth(index, col, 'right');
      if (width !== 0) {
        width -= 1.5;
      }

      style = {
        position: 'sticky',
        right: width + 'px',
        zIndex: zIndex
      }
    }
    return style;
  }

  getColumnWidth(col: Tcolumn, direction: string = 'left') {
    let width = this.fixColumnsWidth;
    if (direction === 'left') {
      if (this.leftFixedColumnsWidth !== undefined && this.leftFixedColumnsWidth !== null && this.leftFixedColumnsWidth.length > 0) {
        width = this.leftFixedColumnsWidth[this.leftFixedColumns.indexOf(col.getField())];
      }
    } else {
      if (this.rightFixedColumnsWidth !== undefined && this.rightFixedColumnsWidth !== null && this.rightFixedColumnsWidth.length > 0) {
        width = this.rightFixedColumnsWidth[this.rightFixedColumns.indexOf(col.getField())];
      }
    }
    return width;
  }

  getFixColumnWidth(index, col: Tcolumn, direction: string = 'left') {
    let _this = this;
    let width = (index * this.fixColumnsWidth) + 50;
    if (direction === 'left') {
      width = (index * this.fixColumnsWidth) + 50;
      if (this.leftFixedColumnsWidth !== undefined && this.leftFixedColumnsWidth !== null && this.leftFixedColumnsWidth.length > 0) {
        width = 162;
        if (index > 0) {
          this.leftFixedColumnsWidth.forEach(function (columnWidth: number, widthIndex) {
            if (widthIndex < index) {
              width += columnWidth;
            }
          });
        }
      }
    } else {
      let rightIndex = this.rightFixedColumns.indexOf(col.getField());
      width = ((this.rightFixedColumns.length - 1) - rightIndex) * this.fixColumnsWidth;
      if (this.rightFixedColumnsWidth !== undefined && this.rightFixedColumnsWidth !== null && this.rightFixedColumnsWidth.length > 0) {
        width = 0;
        this.rightFixedColumnsWidth.forEach(function (columnWidth: number, widthIndex) {
          if (widthIndex > rightIndex) {
            width += columnWidth;
          }
        });
      }
    }
    return width;
  }

  decreaseFontSize() {
    this.dataFontSize--;
    if (this.dataFontSize < 10) {
      this.dataFontSize = 10;
    }
  }

  increaseFontSize() {
    this.dataFontSize++;
  }

  private updateAutoIncrementField() {  //Update fields value who have autoincrement feature
    let _this = this;
    this.autoIncrementFields.forEach(function (field: Tcolumn) {
      _this.grid.getColumn().get(field.getField()).setDefaultValue(_this.lastCount);
    });
  }

  private applyFilters() {   //applying filters from preferences
    let _this = this;
    this.table.filters = {};
    this.filters = [];
    if (this.gridFilter.length > 0) {
      this.gridFilter.forEach(function (filter: Filter) {
        _this.filters.push(filter);
        let valuetofilter;
        if (filter.getType() === 'L' || filter.getType() === 'B') {
          valuetofilter = filter.getFirstValue();
        } else if (filter.getType() === 'N' || filter.getType() === 'T' || filter.getType() === 'P' || filter.getType() === 'D' || filter.getType() === 'LB') {
          if (_this.getCondtionFilterString(filter.getCondition()) === 'between') {
            valuetofilter = filter.getCombineValue();
          } else {
            valuetofilter = filter.getFirstValue();
          }
          if (filter.getType() === 'N' && _this.getCondtionFilterString(filter.getCondition()) !== 'between') {
            valuetofilter = parseInt(valuetofilter);
          }
        }
        _this.filterUpdates(valuetofilter, filter.getColumnName(), _this.getCondtionFilterString(filter.getCondition()), filter);
      });
    } else {
      this.table.filters = {};
      this.table.filteredValue = null;
      this.onRefreshComplete.emit("filter");
    }


  }

  private applySorting() {  //applying sorting from prefrences
    let _this = this;
    if (this.sorts.length > 0) {
      let meta = [];
      this.sorts.forEach(function (sorting) {
        meta.push({field: sorting.columnName, order: _this.commonService.getSortOrder(sorting.sortValue)})
      });
      this.table.multiSortMeta = meta;
      this.table.sortMultiple();
    } else {
      _this.table.sortField = _this.grid.getPrimaryKey();
      _this.table.sortOrder = 1;
      _this.table.sortSingle();
    }
  }

  /**
   * this function is used to set the width of grid according to columns length
   * @param length
   */
  private setGridWidth(length: number) {
    if(this.autoCalculateGridWidth) {
      let width = '100%';
      let minLength = 4;
      let oneColPercent = (100 / minLength) - 20;
      if (length >= minLength) {
        let count = length - minLength;
        width = 100 + (oneColPercent * count) + '%'
      }
      this.grid.setWidth(width);
    }
  }

  private getPageUrl(dataURL: string, pageNo: number) {
    if (dataURL.includes('?')) {
      dataURL += '&page=' + pageNo + '&size=' + this.pageSize;
    } else {
      dataURL += '?page=' + pageNo + '&size=' + this.pageSize;
    }
    return this.grid.baseUrl+dataURL;
  }

  private ViewDetails(selectedValue: any) {

  }

  private deleteDetails(selectedValue: any) {

  }

  private canEditRow(rowData) {
    let canEdit: boolean = true;
    if (this.grid.actionFunc !== undefined && this.grid.actionFunc !== null && this.grid.actionFunc.canEditFunction !== undefined && this.grid.actionFunc.canEditFunction !== null) {
      canEdit = this.commonService.runFunction(rowData, this.grid.actionFunc.canEditFunction);
    }
    return canEdit;
  }

  private afterUpdate(result: any, data: any) {
    this.isAdding = false;
    this.editing = false;
    this.isCopying = false;
    this.onRowDataSave.emit({
      result: {newData: result, oldData: this.oldValue},
      mode: 'update',
      label: this.grid.getTitle()
    });
    this.resetTable();
    this.getGridData(false);
    this.messageService.add({
      severity: 'info', summary: messages.updated['summary'],
      detail: messages.updated['message']
    });
    this.onChangeValue.emit(data)
  }

  sortCustom(value: any) {
    if(this.customSortFunction !== undefined && this.customSortFunction !== undefined) {
      return this.commonService.runFunction(value,this.customSortFunction);
    }
  }

  private getPreSaveData(data: any) {
    if(this.grid.actionFunc.preSaveFunc !== null && this.grid.actionFunc.preSaveFunc !== undefined){
      return this.commonService.runFunction([data, this.oldValue,this.isCopying ? 'copy' : 'save'], this.grid.actionFunc.preSaveFunc);
    }
    return data;
  }

    onSortDone() {
        this.formControlReCreate();
    }

    private convertObjToTcolumn(json: any, data: Tcolumn){
      let col: Tcolumn = new Tcolumn(json['field'], json['header'], json['columnType'], json['placeholder'], json['editable'], json['listvalues'],
          json['visible'], data.getDefaultValue(), json['visibilityArea'], data.getValidators(), json['valueType'], json['colSpan'], json['minDate'],
          json['maxDate'], json['tableMetadata'], data.getActionToDo(), json['extra'], json['isUnique']);
      return col;
    }

  onPrefColChange(colChange: any) {
    this.columns = [];
    let _this = this;
    colChange.forEach((col: any, index) =>{
      _this.columns.push( _this.grid.getColumn().get(col['value']));
    })
    _this.columns.sort((a, b) => a['placeholder'] < b['placeholder']? -1 : a['placeholder'] > b['placeholder'] ? 1 : 0);
  }

  savePreferences() {
      if(this.preferenceName.length > 0){
      let postObj;
      // this.selectedPrefCols.forEach((col:any) => {
      //   this.columns[this.columns.findIndex(x => x['field'] === col['code'])]['visible'] = true;
      // });
      let obj = {
        filters:this.mainFilterArray,
        columns: this.columns,
      }

      postObj = {
          tenantId: this.commonService.getTenantId(),
          moduleName: this.grid.getTitle(),
          preferencejson:  JSON.stringify(obj),
          preferencename:  this.preferenceName,
          preferencetype: 'grid',
          preferencedefault: this.prefDefault,
          status: true,
          userid: this.commonService.getFromStorage('userid')
      };

      this.reloadData(JSON.parse(postObj['preferencejson']), false);
        this.commonService.post(environment.base_url_new + '/api-iam/api/userpreference/v1/saveuserpreference?tenantId=' + this.commonService.getTenantId(), postObj).subscribe(next => {
          this.messageService.add({severity: 'info', summary: 'Preference saved successfully'});
        }, error => {
          this.messageService.add({severity: 'error', summary: 'Failed to save preference.'});
        });
    }else {
      this.messageService.add({severity:'error', summary:'Preference name is empty', detail:'Please enter preference name.'})
    }
    this.preferenceSaveDialog = false;
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    this.filterPanelDisplay = false;
  }

  toggleFilterPanel(event) {
    event.stopPropagation();
    this.filterPanelDisplay = !this.filterPanelDisplay;
    this.filterList = [];
    this.addedList = [];
    this.selectedFilterAction = [];
    this.selectedFilterCol = [];
  }

  addfilter(value: any) {
    this.filterList = [];
    this.addedList = [];
    this.filterActionList = [];
    this.selectedFilterAction = [];
    if (!this.addedList.includes(value['value']['value'])) {
      this.filterList.push(this.service.getFilterObject(value['value']));
      this.addedList.push(value['value']['value']);
    }
    this.filterActionList = this.service.getOptions(this.filterList[0]['type']);

  }

  isSecondRequired(filter: Filter) {
    return (filter.getCondition()['value'] === 'between');
  }

  addNumberOption(value: any, isFirst: boolean) {
    if (isFirst) {
      this.filterList[0].setFirstValue(value);
    } else {
      this.filterList[0].setSecondValue(value);
    }
  }

  onApplyFilter(FilterArryDB = []) {
    if (this.filterList.length > 0){
      this.mainFilterArray.push(this.filterList[0]);
    }else{
      this.mainFilterArray = FilterArryDB;
    }

    this.filters = this.mainFilterArray;
    this.calculateHeight();
    this.filterList = [];
    this.filterPanelDisplay = false;
    this.triggerFilterAPI();
    this.triggerAdvancedFilterAPI([]);
    this.filterList = [];
    this.addedList = [];
    this.filterActionList = [];
  }

  addTextOption(value: any) {
    this.filterList[0].setFirstValue(value);
  }


  triggerAdvancedFilterAPI(value:Filter[],resetInfo:boolean = true){
    let filterObjPayload:any[] = [];
    if(value.length > 0) {
      filterObjPayload = this.commonService.getFilterApiPayload(value);
    }
    this.filterOnPageChange = value;
    if(resetInfo) {
      this.number = 0;
    }
    let columnsFilters:any[] = this.getColumnLevelFilters();
    if(columnsFilters.length > 0) {
      filterObjPayload = filterObjPayload.concat(columnsFilters);
    }
    if(filterObjPayload.find(o => o.fieldName == 'tenantId') == null) {
      filterObjPayload.push({
        fieldName: "tenantId",
        condition: "equals",
        value: this.commonService.getTenantId(),
        secondValue: ''});
    }
    if(filterObjPayload.length > 1) {
      this.filterAPIMethod(filterObjPayload);
    } else {
      this.filterOnPageChange = [];
      this.refreshGridData();
    }
  }

  filterAPIMethod(filterObjPayload) {
    let _this = this;
    this.showFetching = true;
    this.rowDataValues = [];
    this.rowDataValuescopy = [];
    this.gridFrmGroup = new FormGroup({});
    this.commonService.post(  this.getPageUrl(this.grid.gridAdvacedFilterURL,this.number), filterObjPayload).subscribe((data: any) => {
      _this.number++;
      let refresh = true;
      _this.showFetching = false;
      let sortMeta = this.table['_multiSortMeta'];
      if (data['pageable']) {
        _this.pageSize = data['pageable']['pageSize'];
        _this.totalElements = data['totalElements'];
        _this.totalPages = data['totalPages'];
      } else {
        _this.totalElements = data.length;
      }
      if (_this.totalElements > 0) {
        _this.totalNumberElements.emit(_this.totalElements);
      }
      _this.applyGrid(data['content']?data['content']:data, sortMeta, refresh);
    },(error:HttpErrorResponse) => {
      _this.showFetching = false;
    });
  }


//Filter
  getColumnLevelFilters() {
    let keys = Object.keys(this.filterValues);
    this.appliedFilters = Object.assign({},this.filterValues);
    let criteriaList = [];
    let _this = this;
    if(this.filterSelectCol !== null && this.filterSelectCol !== undefined && this.filterSelectCol['columnType'] === 'D') {
      keys.forEach(field => {
        criteriaList.push({
          fieldName: field,
          condition: Array.isArray(_this.filterValues[field])?"in":"equalsdate",
          value: _this.filterValues[field],
          secondValue: ''});
      });
    } else {
      keys.forEach(field => {
        criteriaList.push({
          fieldName: field,
          condition: Array.isArray(_this.filterValues[field])?"in":"equals",
          value: _this.filterValues[field],
          secondValue: ''});
      });
    }
    return criteriaList;
  }

  iconClass(field: any) {
    if(this.filterList.includes(field)) {
      return "iconClassOnfilter";
    } else {
      return "iconClassNotFilter";
    }
  }

  showFilters(field, op: OverlayPanel, event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.filterSelectCol = field;
    op.toggle(event);
  }

  showiconOnFilter(field: any) {
      if(this.appliedFilters !== null && this.appliedFilters !== undefined && Object.keys(this.appliedFilters).includes(field)) {
      return "Grid Filte Applied.svg";
    } else {
      return "Grid Filter.svg";
    }
  }

  pDisable(col) {
    if(this.filterValues[col.field] !== undefined && this.filterValues[col.field] !== null && this.filterValues[col.field].length > 0){
      this.pdisable = false;
      return 'applyText';
    } else {
      return 'applyTextdiable';
    }
  }

  clearfilter(field: any) {
    delete this.filterValues[field];
    this.triggerAdvancedFilterAPI(this.filterOnPageChange,true);
  }

  updatevalue(value: any, field: any,isdate:boolean = false) {
    if(isdate) {
      let date:Date = new Date(value+'UTC')
      let dt = this.commonService.getFormattedDateTime(date,'yyyy-MM-ddTHH:mm:ss');
      this.filterValues[field] =  dt.toString().replace('Z','');
    }
  }

  getList(column: Tcolumn) {
    return this.filterOptionsList[column.getField()];
  }

  triggerFilterAPI() {
    let filterObjPayload = [{
      fieldName: 'tenantId',
      condition: 'equals',
      value: this.commonService.getTenantId()
    }];
    this.mainFilterArray.forEach((filter:any) =>{
      filterObjPayload.push(
          {
            fieldName: filter['columnName'],
            condition: filter['condition']['value'],
            value:this.commonService.getValueForFilterPayload(filter)
          }
      )
    });
    //CALL API
    let _this = this;
    if (filterObjPayload.length > 1) {
      this.showFetching = true;
      this.rowDataValues = [];
      this.rowDataValuescopy = [];
      this.commonService.post(environment.base_url + this.grid.gridFilterURL, filterObjPayload).subscribe((data: any) => {
        let refresh = true;
        _this.showFetching = false;
        let sortMeta = this.table['_multiSortMeta'];
        if (data['pageable']) {
          _this.pageSize = data['pageable']['pageSize'];
          _this.totalElements = data['totalElements'];
          _this.totalPages = data['totalPages'];
        } else {
          _this.totalElements = data.length;
        }
        if (_this.totalElements > 0) {
          _this.totalNumberElements.emit(_this.totalElements);
        }
        _this.applyGrid(data, sortMeta, refresh);
      });
    }else{
      this.refreshGridData();
    }
  }

  addListOption(value: any) {
    this.filterList[0].setFirstValue(value['value']);
  }

  updateOptions(value: any){
    this.filterList[0].setCondition(value['value']);
    if (this.filterList[0] !== undefined && this.filterList[0] !== null && (this.filterList[0]['type'] === 'L' || this.filterList[0]['type'] === 'B' || this.filterList[0]['type'] === 'OB')){
      this.getFilterDropDownValues(this.filterList[0]);
    }
  }

  getFilterDropDownValues(filter: Filter) {
    if (filter['field']['listvalues'] !== null && filter['field']['listvalues'] !== undefined && filter['field']['listvalues'].length !== 0 ){
    if(filter['columnName'] === 'tradeTransactionType'){
        this.filterDropDown =  this.masterCommonService.getBooleanFromLabel('Buy','Sell',true,0,1);
      } else {
      this.filterDropDown = filter['field']['listvalues'];
     }
    }
    else{
      if (filter['columnName'] === 'quantityUom'){

        this.filterDropDown =  this.masterCommonService.getListFromArray(this.loadData['unitofmeasurement'],false, true);
      }else if(filter['columnName'] === 'priceType'){
        this.filterDropDown =  this.masterCommonService.getListFromArray(this.loadData['price_type_ind'], false, true);
      }else if(filter['columnName'] === 'origin'){
        this.filterDropDown =  this.masterCommonService.getListFromArray(this.loadData['location'], false, true);
      }else if(filter['columnName'] === 'tradeTransactionType'){
        this.filterDropDown =  this.masterCommonService.getBooleanFromLabel('Buy','Sell',false,0,1);
      }
      else{
        this.filterDropDown =  this.masterCommonService.getListFromArray(this.loadData[filter['columnName']],false, true);
      }
    }
  }

  onChangeFilter(value: any, dropdown: any) {
    this.onDropdownFilterValueChange.emit({title:dropdown['title'],selected:value['value'],value:value});
  }


  openSavePrefDialog() {
    this.preferenceSaveDialog = true;
  }

  onPrefSelection(value) {
    this.gridPrefData = [];
    if (value !== 'System'){
      this.preferenceName = value;
      let obj = this.preferenceList.find(function(pref, index) {
        if (pref['value'] !== 'System'){
          if(pref['pref']['preferencename'] == value)
            return true;
        }
      });
      this.gridPrefData.push(obj['pref']);
      this.reloadData(JSON.parse(obj['pref']['preferencejson']), false);
      this.onApplyFilter(JSON.parse(obj['pref']['preferencejson'])['filters']);
      this.selectedPrefCols = [];
      // this.selectedPrefCols = JSON.parse(obj['pref']['preferencejson'])['columns'];
      this.makeColListForPref(JSON.parse(obj['pref']['preferencejson'])['columns']);
    } else{
      this.refreshGridData();
      this.makeColListForPref(this.columns);
    }
  }


  onPrefNameChange(name:any){
    this.preferenceName = name;
  }

  onMakeDefaultChange(val: any) {
    if (this.selectedPreference !== 'System' && this.selectedPreference !== ''){
      this.gridPrefData[0]['preferencedefault'] = this.prefDefault;
      this.commonService.post(environment.base_url_new + '/api-iam/api/userpreference/v1/saveuserpreference?tenantId=' + this.commonService.getTenantId(), this.gridPrefData[0]).subscribe(next => {
        this.messageService.add({severity: 'info', summary: 'Preference saved successfully'});
      }, error => {
        this.messageService.add({severity: 'error', summary: 'Failed to save preference.'});
      });
    }
  }

  lazyLoadData(load) {
    this.number = load['first']/this.pageSize;
    let _this = this;
    let sortMeta = this.table['_multiSortMeta'];
    if(this.number > 0 || (this.number == 0 && this.pageOffset > 0)){
      this.pageOffset = load['first'];

      if(this.grid.gridFetchUrlType === 'GET') {

        this.table.selectionKeys = {};
        this.table.editingRowKeys = {};
        this.newRow.clear();
        this.rowDataValues = [];
        this.gridFrmGroup = new FormGroup({});
        this.table.value = [];
        this.selectedRow = [];
        this.listOfValues = new Map();
        this.autoCompleteValue = '';


        this.gridService.getData(this.getPageUrl(this.grid.getDataURL(), this.number)).subscribe((data: any) => {

          _this.isError = false;
          /*
           data['content'] is actual data to show on Grid, we are checking in the following line whether the Response data have 'Content key' or not
           */
          _this.lastCount = data['_embedded'] ? data._embedded[Object.keys(data._embedded)[0]].length : data.length;
          _this.updateAutoIncrementField();
          _this.applyGrid(data['content']?data['content']:data, sortMeta, true);

        }, (error: HttpErrorResponse) => {
          _this.toggleFetching();
          _this.isError = true;
          if (error.message.includes("Http failure response")) {
            _this.loadMessage = 'Http Failure Response (Error Status : ' + error.status + ')';
          } else {
            _this.loadMessage = 'Something went wrong !';
          }
          _this.calculateLeft();
        });
      }else if(this.grid.gridFetchUrlType === 'POST') {
        let payload = this.runFunction([],this.grid.fetchPostPayloadFuction);
        this.commonService.post(this.getPageUrl(this.grid.getDataURL(), this.number),payload).subscribe((data: any) => {
          _this.number++;
          /*
           checking if response data is having pageAble key or not and Storing the total lenght of data as per the same condition.
           */
          if (data['pageable']) {
            _this.pageSize = data['pageable']['pageSize'];
            _this.totalElements = data['totalElements'];
            _this.totalPages = data['totalPages'];
          }else{
            _this.totalElements = data.length;
          }
          if (_this.totalElements > 0) {
            _this.totalNumberElements.emit(_this.totalElements);
          }

          _this.isError = false;
          _this.toggleFetching();
          /*
           data['content'] is actual data to show on Grid, we are checking in the following line whether the Response data have 'Content key' or not
           */
          _this.lastCount = data['_embedded'] ? data._embedded[Object.keys(data._embedded)[0]].length : data.length;
          _this.updateAutoIncrementField();
          _this.applyGrid(data['content']?data['content']:data, sortMeta, true);

        }, (error: HttpErrorResponse) => {
          _this.toggleFetching();
          _this.isError = true;
          if (error.message.includes("Http failure response")) {
            _this.loadMessage = 'Http Failure Response (Error Status : ' + error.status + ')';
          } else {
            _this.loadMessage = 'Something went wrong !';
          }
          _this.calculateLeft();
        });
      }

    }


  }

    getPagination() {
        return this.totalElements > this.pageSize;
    }

  onPageChange(value: any) {
    this.resetTable();
    this.number = value['page'];
    if(this.filterOnPageChange !== null && this.filterOnPageChange !== undefined && this.filterOnPageChange.length > 0) {
      this.triggerAdvancedFilterAPI(this.filterOnPageChange,false);
    } else {
      this.getGridData(true);
    }
  }

  canShowExpandGrid(rowData: any) {
    if(this.grid.canShowExpandGrid !== null && this.grid.canShowExpandGrid !== undefined) {
      return this.commonService.runFunction(rowData,this.grid.canShowExpandGrid);
    } else {
      return true;
    }
  }

  getColSpanExpand(length:number) {
    length++;
    if(this.grid.showCheckbox) {
      length++;
    }
    if(this.showActionColumn()) {
      length++;
    }
    return length;
  }

  convertList(tcolumns: IterableIterator<Tcolumn>) {
    return Array.from(tcolumns);
  }

  canShowUnit(col: Tcolumn) {
    if (col.getDefaultValue() !== null && col.getDefaultValue() !== undefined && typeof (col.getDefaultValue()) === 'object') {
      return col.getDefaultValue()['showPostText'];
    }
    return false;
  }

  getValueForPostText(col: Tcolumn, rowData: any, value: any) {
    if(col.getDefaultValue()['postTextFunction'] !== null && col.getDefaultValue()['postTextFunction'] !== undefined) {
      return this.commonService.runFunction(rowData,col.getDefaultValue()['postTextFunction']);
    }
    return '';
  }

  onExpandChildSelection(value: any,grid:Grid,row) {
    grid.selectedRows = value;
    this.onExpandRowSelectionChange.emit({value:grid.selectedRows,table:grid.getTitle(),rowData:row,grid:grid});
  }

  onExpandedGridChildSelection(value: any) {
    this.onExpandRowSelectionChange.emit(value);
  }

  runDisableFunction(col: Tcolumn,rowData:any) {
    if(col.disableFunction !== null && col.disableFunction !== undefined) {
      return this.commonService.runFunction(rowData,col.disableFunction);
    }
    return false;
  }

  onRowExpand(value: any) {

    this.onClickExpandKey.emit({event:'Expand',data:value['data']});
  }

  onRowCollapse(value: any) {
    this.onClickExpandKey.emit({event: 'Collapse', data: value['data']});
  }

  closeaudittrail() {
    this.displayAudit = false;
  }

  openAudit() {
    this.displayAudit = true;
  }

  onUpdate(event: any) {
    this.refreshGridData();
  }

  onClickContextMenuItem(output: any) {
    this.onContextMenuItemClick.emit(output);
  }
}

export interface AllValidationErrors {
  control_name: string;
  error_name: string;
  error_value: any;
}

