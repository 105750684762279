<div style="display: inline-flex;width: 100%">
    <masters-sidenav></masters-sidenav>
    <div id="pinwidth" style="width: 100%;margin-left: 80px">
        <ctrm-grid [breadcrumbMapKey]="commonService.MASTER_MAP_KEY" [currentRoute]="'/documentTemplateMaster'"
           [grid]="grid"
           *ngIf="loadListData != undefined && loadListData.length != 0"
           [refreshGrid]="refreshGrid">
        </ctrm-grid>
    </div>
</div>
