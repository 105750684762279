import {MenuItem} from 'primeng/api';
import {Button} from '../../../grid/buttons.model';
import {environment} from '../../../../environments/environment';
import {HttpErrorResponse} from '@angular/common/http';
import {ApprovalFunctionParams} from '../../../Components/approval-context-menu/model/approval-function.params';

export const kycApprovalFunctions: { [key: string]: Function } = {
    loadMenuItems: (param: ApprovalFunctionParams): MenuItem[] => {
        let items: MenuItem[] = [];
        let currentStatus = param.rowData.counterpartyBusinessProfile.kycStatus;
        if (currentStatus !== null && currentStatus !== undefined && currentStatus.length !== 0) {
            currentStatus = currentStatus.toLowerCase();
        } else {
            currentStatus = 'draft';
        }
        if (currentStatus === 'draft') {
            let sendForApproval = (value) => {
                let tenantId = param.commonService.getFromStorage('tenantId');
                let userName = param.commonService.getFromStorage('userName');
                let url = environment.base_url + '/api/counterpartykyc/v1/kyc-start-workflow?triggerEvent=' + param.triggerEvent +
                    '&referenceId=' + param.commonService.getEncodedURI(param.referenceId) +
                    '&tenantId=' + tenantId +
                    '&userName=' + userName;
                param.commonService.getJSONByURL(url).subscribe((next) => {
                    param.commonService.postMessage('', 'Sent For Approval', 'success');
                    param.onUpdateDone.emit({step: 'Send for Approval'});
                }, (error: HttpErrorResponse) => {
                    param.commonService.postMessage('', param.commonService.getHttpErrorMessage(error, 'Counterparty KYC'), 'error');
                });
            };
            items.push(param.commonService.getMenuItemFromButton(new Button('Send for Approval', 'G', 'Block', sendForApproval, '', '', undefined, [], false)));
        } else {
            if (param.response.length > 0) {
                items = param.commonService.getContextMenu(param.response[0], param.callbackFunction, true, (currentStatus === 'blocked'));
                let step: any[] = param.response[0].workflowSteps.filter(value => value['stepImpact'] !== null && value['stepImpact'] !== undefined && value['stepImpact'].toLowerCase() === 'positive' && value['updateStatus'].toLowerCase() !== 'pending');
                let block = function(value) {
                    param.currentApprovalObject.workflow['workflowName'] = 'KYC Approval';
                    param.currentApprovalObject.buttonName = 'Block';
                    param.currentApprovalObject.step = {};
                    param.currentApprovalObject.isInApproval = false;
                    param.currentApprovalObject.apiConfig.url = environment.base_url + `/api/counterpartykyc/v1/block-kyc?referenceId=${param.commonService.getEncodedURI(param.referenceId)}&tenantId=${param.commonService.getTenantId()}`;
                    param.currentApprovalObject.apiConfig.type = 'post';
                    param.context.showPopup();
                };
                items.push(param.commonService.getMenuItemFromButton(new Button('Block', 'G', 'Block', block, '', '', undefined, [], !(step.length > 0 && currentStatus !== 'blocked'))));
            }
        }
        return items;
    },
    onDone: (param: ApprovalFunctionParams) => {
        if (param.currentApprovalObject.buttonName === 'Block') {
            param.commonService.postMessage('Blocked', 'Blocked Successful', 'success');
        } else {
            if (param.currentApprovalObject.step.desiredEndStatus[param.currentApprovalObject.buttonName].impact === 'positive') {
                param.commonService.postMessage('', param.currentApprovalObject.step.actionName + ' Successful');
            } else if (param.currentApprovalObject.step.desiredEndStatus[param.currentApprovalObject.buttonName].impact === 'negative') {
                param.commonService.postMessage('', param.currentApprovalObject.step.actionName + ' Successful');
            }
        }
        param.onUpdateDone.emit({step: param.currentApprovalObject.buttonName});
    },
};
