import {Component, OnInit} from '@angular/core';
import {Tcolumn} from '../../grid/tcolumn.model';
import {Grid} from '../../grid/grid.model';
import {KeyValue} from '../../grid/key-value.model';
import {CommonService} from '../../services/common.service';
import {MasterService} from '../../masters/services/MasterService';
import {environment} from '../../../environments/environment';
import {Validators} from '@angular/forms';
import {Action} from '../../grid/actions.model';
import {ctrmValidators} from '../../services/validators/ctrmValidators';
import {defaultValue} from '../../grid/defaultValue.model';


@Component({
  selector: 'app-cashforward',
  templateUrl: './cashforward.component.html',
  styleUrls: ['./cashforward.component.css']
})
export class CashforwardComponent implements OnInit {
  columns: Map<string, Tcolumn>;
  grid: Grid;
  contract: any;
  traderName: string = '';
  id: string = '';
  refreshGrid: boolean = false;
  url: string;
  contractId: string;
  commodity: string;
  counterParty: string;
  intComp: KeyValue[];
  tradeDate: Date = new Date();
  onLoadData: any[] = [];
  profitcenterdata: any[] = [];
  brokerData :any[] = [];

  constructor(private masterCommonService: MasterService, public commonService: CommonService) {
  }

  ngOnInit() {
    this.getProfitCenterList();
    let _this = this;
    let condition = function (value) {
      let list: any[] = [];
      if(value.modelName ===  'company' || value.modelName ===  'profitcenter' || value.modelName ===  'counterparty'){
        list.push({key:'isTradeable', value:true});
      }
      return list;
    }
    this.commonService.getLoadData('commodity',['config','config','config','data'], ['company',
      'profitcenter',[{key: 'code', value: 'counterparty'}],[{key:'name', value:'fx_pair_trade_ind'}]],condition).subscribe( (next:any) => {
      this.onLoadData = next;
      this.getbrokerdata();
      this.getColumns();
      this.grid = new Grid(this.columns, true, true, true, true, 'fxTradeId', '/api/fxTrade/v1/getallfxtrades?tenantId=' + this.commonService.getFromStorage('tenantId'), '', 'FX Trade', undefined, undefined);
      this.grid.setSaveUrl(environment.base_url + '/api/fxTrade/v1/save?tenantId=' + this.commonService.getFromStorage('tenantId'));
      this.grid.setUpdateUrl(environment.base_url + '/api/fxTrade/v1/update?tenantId=' + this.commonService.getFromStorage('tenantId'));
      this.grid.hrefUrl = environment.base_url +'/api/fxTrade/v1/getfxtradebyuuid?tenantId='+this.commonService.getTenantId()+'&uuid=';
      this.grid.gridAdvacedFilterURL='/api/fxTrade/v1/getfxtradebycriteria?tenantId='+this.commonService.getTenantId();
      this.grid.onEditViewThrowUnique = true;
      this.grid.iconName = 'forwardForex';
      this.grid.setGridConfiguration(true, true, true, false);
      this.grid.addSection('Trade CounterParty and Company Details', ['fxTradeId', 'tradeDate', 'tradeTransactionType', 'company', 'profitcenter', 'counterparty', 'traderName', 'broker', 'externalRef']);
      this.grid.addSection('FX Trade Details', ['fxTradeType', 'valueDate', 'fxPair', 'notionalAmount', 'rate', 'buyAmount', 'buyAmountCurrency', 'sellAmount', 'sellAmountCurrency']);
      this.grid.addSection('Settlement Details', ['settlementType', 'autoRollover', 'settlementCurrency', 'settlementDate', 'plannedNotionalValue', 'unplannedNotionalValue', 'status']);
      this.grid.actionFunc.resetFunction = function (value:any) {
        _this.grid.getColumn().get('notionalAmount').setHeader('Notional Amount');
        _this.grid.getColumn().get('buyAmount').setHeader('Buy Amount');
        _this.grid.getColumn().get('sellAmount').setHeader('Sell Amount');
      };
    });
  }

  getSplitedCode(value, isBuy: boolean = true) {
    let num = isBuy ? 0 : 1;
    if (value[1] !== undefined && value[1] !== null && value[1].length > 0) {
      if (value[0]==='BUY') {
        return value[1].split('/')[num].toUpperCase();
      } else {
        return value[1].split('/')[1 - num].toUpperCase();
      }
    }
    return '';
  }


  getColumns() {
    let valueDateFunc = function (value: any[] = []) {
      if (value[0]) {
        return value[1];
      } else {
        if (new Date(value[1]) > new Date(value[2])) {
          return '';
        }
        return value[2];
      }
    };

    let settelementDateFunc = function (value: any[] = []) {
      let date:Date = new Date(value[1]);
      return _this.commonService.addDaysInDate(date,2);
    };

    let actionTradeDate = new Action();
    actionTradeDate.addFieldToUpdate('valueDate', undefined, undefined, undefined, undefined, true, 'minDate');
    actionTradeDate.addFieldToUpdate('valueDate', undefined, valueDateFunc, ['fxTradeType', 'tradeDate', 'valueDate'], undefined, true, 'defaultValue');
    actionTradeDate.addFieldToUpdate('settlementDate', undefined, undefined, undefined, undefined, true, 'minDate');
    actionTradeDate.addFieldToUpdate('settlementDate', undefined, settelementDateFunc, ['fxTradeType', 'tradeDate', 'settlementDate'], undefined, true, 'defaultValue');

    let actionValueDate = new Action();
    actionValueDate.addFieldToUpdate('settlementDate', undefined, undefined, undefined, undefined, true, 'minDate');

    let _this = this;
    let updateSettlementCurrency = function (value: any[] = []) {
      let code = value[0].split('/');
      return _this.commonService.getCurrencyListbycodes(code);
    };
    let fxpairaction = new Action();
    fxpairaction.addFieldToUpdate('settlementCurrency', undefined, updateSettlementCurrency, undefined, undefined, true, 'listvalues');


    let maxVal = function (value: any[] = []) {
      let maxVal = [Validators.max(parseInt(value[0]))];
      return maxVal;
    };

    //Calculation of Sell Amount and Buy Amount when Trade Type is Buy and Sell
    let totalBuyFunction = function (value: any[] = []) {
      if (value[0] === 'BUY') {
        return value[1] * value[2];
      } else {
        return value[1];
      }
    };

    let totalSellFunction = function (value: any[] = []) {
      if (value[0] === 'SELL') {
        if(value[2] === undefined || value[2] === null || value[2] === 0) {
          return 0;
        }
        return (value[1] / value[2]).toFixed(4);
      } else {
        return value[1];
      }
    };

    //Buy Amount Currency and Sell Amount Currency
    let buyAmountCurrency = function (value: any[] = []) {
      return _this.getSplitedCode(value);
    };

    let sellAmountCurrency = function (value: any[] = []) {
      return _this.getSplitedCode(value, false);
    };

    let setNotionalHeader = (value: any[] = []) => {
      let header;
      header = 'Notional Amount ' + ' ( ' + _this.getSplitedCode(value, (value[0] === 'BUY')) + ' ) ';
      return header;
    };

    let setBuyHeader = (value: any[] = []) => {
      let header;
      if (value[0] === 'BUY') {
        header = 'Buy Amount'
      } else {
        header = 'Buy Amount ' + ' ( ' + _this.getSplitedCode(value, (value[0] === 'SELL')) + ' ) ';
      }
      return header;
    };

    let setSellHeader = (value: any[] = []) => {
      let header;
      if (value[0] === 'SELL') {
        header = 'Sell Amount';
      } else {
        header = 'Sell Amount ' + ' ( ' + _this.getSplitedCode(value, (value[0] === 'SELL')) + ' ) ';
      }
      return header;
    };


    let plannedaction = new Action();
    plannedaction.addFieldToUpdate('plannedNotionalValue', undefined, maxVal, undefined, undefined, false, 'validators');
    plannedaction.addFieldToUpdate('buyAmount', undefined, totalBuyFunction, undefined, undefined, true, 'defaultValue', undefined, undefined);
    plannedaction.addFieldToUpdate('sellAmount', undefined, totalSellFunction, undefined, undefined, true, 'defaultValue');
    plannedaction.addFieldToUpdate('sellAmount', undefined, totalBuyFunction, ['tradeTransactionType', 'notionalAmount', 'rate']);
    plannedaction.addFieldToUpdate('buyAmount', undefined, totalSellFunction, ['tradeTransactionType', 'notionalAmount', 'rate']);

    let rateaction = new Action();
    rateaction.addFieldToUpdate('sellAmount', undefined, totalBuyFunction, ['tradeTransactionType', 'notionalAmount', 'rate']);
    rateaction.addFieldToUpdate('buyAmount', undefined, totalSellFunction, ['tradeTransactionType', 'notionalAmount', 'rate']);

    let tradetypeaction = new Action();
    tradetypeaction.addFieldToUpdate('sellAmount', undefined, totalBuyFunction, ['tradeTransactionType', 'notionalAmount', 'rate']);
    tradetypeaction.addFieldToUpdate('buyAmount', undefined, totalSellFunction, ['tradeTransactionType', 'notionalAmount', 'rate']);
    tradetypeaction.addFieldToUpdate('sellAmountCurrency', undefined, sellAmountCurrency, ['tradeTransactionType', 'fxPair'], undefined, false, undefined);
    tradetypeaction.addFieldToUpdate('buyAmountCurrency', undefined, buyAmountCurrency, ['tradeTransactionType', 'fxPair'], undefined, false, undefined);
    tradetypeaction.addFieldToUpdate('notionalAmount', undefined, setNotionalHeader, ['tradeTransactionType', 'fxPair'], undefined, false, 'header');
    tradetypeaction.addFieldToUpdate('notionalAmount', undefined, setNotionalHeader, ['tradeTransactionType', 'fxPair'], undefined, false, 'header');
    tradetypeaction.addFieldToUpdate('buyAmount', undefined, setBuyHeader, ['tradeTransactionType', 'fxPair'], undefined, false, 'header');
    tradetypeaction.addFieldToUpdate('sellAmount', undefined, setSellHeader, ['tradeTransactionType', 'fxPair'], undefined, false, 'header');



    let fxPairaction = new Action();
    fxPairaction.addFieldToUpdate('sellAmountCurrency', undefined, sellAmountCurrency, ['tradeTransactionType', 'fxPair'], undefined, false, undefined);
    fxPairaction.addFieldToUpdate('buyAmountCurrency', undefined, buyAmountCurrency, ['tradeTransactionType', 'fxPair'], undefined, false, undefined);
    fxPairaction.addFieldToUpdate('notionalAmount', undefined, setNotionalHeader, ['tradeTransactionType', 'fxPair'], undefined, false, 'header');
    fxPairaction.addFieldToUpdate('buyAmount', undefined, setBuyHeader, ['tradeTransactionType', 'fxPair'], undefined, false, 'header');
    fxPairaction.addFieldToUpdate('sellAmount', undefined, setSellHeader, ['tradeTransactionType', 'fxPair'], undefined, false, 'header');


    let fxTradeTypeD = function (value: any[] = []) {
      let obj: any = {meta: {disabled: true}};
      if (value[0]) {
        obj = {meta: {disabled: true}};
      }
      return obj;
    };
    let actionfxTradeType = new Action();
    actionfxTradeType.addFieldToUpdate('valueDate', undefined, fxTradeTypeD, undefined, undefined, true, 'extra');
    // actionfxTradeType.addFieldToUpdate('settlementDate', undefined, fxTradeTypeD, undefined, undefined, true, 'extra');
    actionfxTradeType.addFieldToUpdate('valueDate', undefined, valueDateFunc, ['fxTradeType', 'tradeDate', 'valueDate'], undefined, true, 'defaultValue');
    actionfxTradeType.addFieldToUpdate('settlementDate', undefined, settelementDateFunc, ['fxTradeType', 'tradeDate', 'settlementDate'], undefined, true, 'defaultValue');


    this.columns = new Map();
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    /***** Section 1 : Trade Counterparty and Company Details **********/
    this.columns.set('fxTradeId', new Tcolumn('fxTradeId', 'FX Trade ID', 'LB', 1, false, undefined, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('tradeDate', new Tcolumn('tradeDate', 'Trade Date', 'D', 2, true, undefined, true, new Date(), 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionTradeDate));
    this.columns.set('tradeTransactionType', new Tcolumn('tradeTransactionType', 'Buy/Sell', 'OB', 3, true, this.getBuySell(), true, 'BUY', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, tradetypeaction));
    this.columns.set('company', new Tcolumn('company', 'Company', 'L', 4, true, this.masterCommonService.getListFromArray(this.onLoadData['company']), true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined));
    this.columns.set('profitcenter', new Tcolumn('profitcenter', 'Profit Center', 'L', 5, false,this.profitcenterdata,true,'','B',[Validators.required]));
    this.columns.set('counterparty', new Tcolumn('counterparty', 'CounterParty', 'L', 6, true, this.masterCommonService.getListFromArray(this.onLoadData['counterparty']), true, '', 'B', [Validators.required]));
    this.columns.set('broker', new Tcolumn('broker', 'Broker', 'L', 7, true, this.brokerData, true, '', 'B', []));
    this.columns.set('traderName', new Tcolumn('traderName', 'Trader Name', 'L', 8, true, this.masterCommonService.getListFromUrl('/api/trade/v1/getuser?tenantId='+this.commonService.getFromStorage('tenantId')+'&userType=Trader', 'value', 'value'), true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined));
    this.columns.set('externalRef', new Tcolumn('externalRef', 'External Reference', 'T', 9, true, undefined, true, '', 'B', []));
    /***** Section 2 : FX Trade Details  **********/
    this.columns.set('fxTradeType', new Tcolumn('fxTradeType', 'FX Trade Type', 'OB', 10, true, this.masterCommonService.getBooleanFromLabel("Cash", "Forward",false,'Cash','Forward'), true, 'Cash', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionfxTradeType));
    this.columns.set('valueDate', new Tcolumn('valueDate', 'Value Date', 'D', 11, true, undefined, true, new Date(), 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionValueDate));
    this.columns.set('fxPair', new Tcolumn('fxPair', 'FX Pair', 'L', 12, true, this.masterCommonService.getListFromArray(this.onLoadData['fx_pair_trade_ind']), true, '', 'B', [], undefined, undefined, undefined, undefined, undefined, fxPairaction));
    this.columns.set('notionalAmount', new Tcolumn('notionalAmount', 'Notional Amount', 'N', 13, true, undefined, true, 0, 'B', [Validators.min(0)], undefined, undefined, undefined, undefined, undefined, plannedaction));
    this.columns.set('rate', new Tcolumn('rate', 'Rate', 'N', 14, true, undefined, true, 0, 'B', [Validators.min(0), ctrmValidators.decimalLengthValidator(15, 4)], undefined, undefined, 0, 100, undefined, rateaction));
    this.columns.set('buyAmount', new Tcolumn('buyAmount', 'Buy Amount', 'LB', 15, true, undefined, true, 0, 'B', []));
    this.columns.set('buyAmountCurrency', new Tcolumn('buyAmountCurrency', 'Buy Amount Currency', 'LB', 16, true, undefined, true, '', 'B', [], undefined, 1));
    this.columns.set('sellAmount', new Tcolumn('sellAmount', 'Sell Amount', 'LB', 17, true, undefined, true, 0, 'B', [], undefined, 1));
    this.columns.set('sellAmountCurrency', new Tcolumn('sellAmountCurrency', 'Sell Amount Currency', 'LB', 18, true, undefined, true, '', 'B', [], undefined, 1));
    /***** Section 3 : Settlement Details **********/
    this.columns.set('settlementType', new Tcolumn('settlementType', 'Settlement Type', 'OB', 19, true, this.masterCommonService.getBooleanFromLabel('Deliverable', 'Non Deliverable',false,'true','false'), true, 'true', 'B', [Validators.required], undefined, 3, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('autoRollover', new Tcolumn('autoRollover', 'Auto Rollover', 'L', 20, true, this.masterCommonService.getYesNo(true), true, false, 'B', [Validators.required], undefined, undefined, 0, 100, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('settlementCurrency', new Tcolumn('settlementCurrency', 'Settlement Currency', 'L', 21, true, this.commonService.getCurrencyList(), true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined));
    this.columns.set('settlementDate', new Tcolumn('settlementDate', 'Settlement Date', 'D', 22, true, undefined, true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined));
    this.columns.set('plannedNotionalValue', new Tcolumn('plannedNotionalValue', 'Planned Notional', 'LB', 23, true, undefined, true, 0, 'B', []));
    this.columns.set('unplannedNotionalValue', new Tcolumn('unplannedNotionalValue', 'Unplanned Notional', 'LB', 24, true, undefined, true, 0, 'B', []));
    this.columns.set('status', new Tcolumn('status', 'Allocation Status', 'LB', 25, true, undefined, true, '', 'B', []));
  }

  private getBuySell() {
    return this.masterCommonService.getBooleanFromLabel('Buy','Sell',false,'BUY','SELL');
  }

  getbrokerdata() {
    let _this = this;
    this.brokerData = [new KeyValue('Select','')];
    _this.commonService.getJSONByURL(environment.base_url + '/api/counterparty/v1/getcounterpartybycounterpartytypecode?tenantId='+this.commonService.getTenantId()+'&counterpartytype='+['bank','cashBroker','broker']).subscribe((next: any) => {
      next.forEach(data => {
        this.brokerData.push(new KeyValue(data['name'],data['name']))
      })
    });
  }

  getProfitCenterList() {
    let payloadList: any[] = [];
    let payload= {
      tenantId: this.commonService.getFromStorage('tenantId'),
      serviceName: 'xceler_configservice',
      model:'profitcenter',
      requiredField:['name'],
      filter: [{key:'isTradeable', value:true}]
    }
    payloadList.push(payload);
    this.commonService.getJSONByObject(environment.base_url_new + '/api-bm/api/profitcenter/v1/load', payloadList).subscribe((next: any)=>{
      this.profitcenterdata = this.masterCommonService.getListFromArray(next['profitcenter']);
    });
  }


}

