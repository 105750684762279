import { Component, OnInit } from '@angular/core';
import {CommonService} from "../../services/common.service";
import {MasterService} from "../../masters/services/MasterService";
import {Tcolumn} from "../../grid/tcolumn.model";
import {Grid} from "../../grid/grid.model";
import {KeyValue} from "../../grid/key-value.model";
import {MessageService, TreeNode} from 'primeng';
import {Action} from "../../grid/actions.model";
import {Validators} from "@angular/forms";
import {ctrmValidators} from "../../services/validators/ctrmValidators";
import {environment} from "../../../environments/environment";
import {Tab} from "../../tab-grid/Tab.model";
import {ActivatedRoute} from "@angular/router";
import {defaultValue} from "../../grid/defaultValue.model";
import {Button} from '../../grid/buttons.model';
import {CtrmInputFormComponent} from '../../ctrm-input-form/ctrm-input-form.component';

@Component({
  selector: 'app-cost',
  templateUrl: './cost.component.html',
  styleUrls: ['./cost.component.css']
})
export class CostComponent implements OnInit {

  columns: Map<string, Tcolumn>;
  grid: Grid;
  groupList: KeyValue[] = [];
  selectedRowData: any;
  costData: any;
  openForm:boolean = false;
  updatepayloadfunction: Function;
  popupDisplay: boolean = false;
    refreshGrid: boolean = false;
    quantityRoundOff:number=3;
    priceRoundOff:number=2;
    costChargesType="cost";

  constructor(public commonService: CommonService,private messageService: MessageService, private masterCommonService: MasterService,private route:ActivatedRoute) { }

  ngOnInit(): void {
      this.costChargesType=window.location.hash === "#/charges"?"charges":"cost"
    this.getCost();

      let tenantConfig=JSON.parse(this.commonService.getFromStorage("tenantConfig"));
      if(tenantConfig && tenantConfig.roundingFormat){
          this.priceRoundOff=tenantConfig.roundingFormat.priceRounding;
          this.quantityRoundOff= tenantConfig.roundingFormat.quantityRounding;
      }
      else{
          this.priceRoundOff=2;
          this.quantityRoundOff=3;
      }

  }

  getgrid(){
      return this.grid;
  }
  getCost() {
      this.getCostGrid();

    let _this = this;
    this.openForm = false;
    _this.commonService.getLoadData('commodity', ['data','data','data','data','data','data','data','data','config','config','config'],
        [[{key: 'name', value: 'cost_detail_name_ind'}],
          [{key: 'name', value: 'cost_detail_type_ind'}],
          [{key: 'name', value: 'cost_group_ind'}],
          [{key: 'name', value: 'cost_type_ind'}],
          [{key: 'name', value: 'quantity_option_ind'}],
          [{key: 'name', value: 'percentage_component_ind'}],
          [{key: 'name', value: 'cost_payment_type_ind'}],
          [{key: 'name', value: 'cost_for_ind'}],
          [{key: 'code', value: 'counterparty'}]
            ,'paymentterm','unitofmeasurement'],null).subscribe((next: any) => {
      _this.costData = next;
        // if(window.location.hash === "#/charges"){
        //     const keyValuePairs = [{label:'Select',value:''}];
        //     keyValuePairs.push({label: 'TRADE',value:'TRADE'})
        //     _this.grid.getColumn().get('costFor').setListofValues(keyValuePairs);
        // }else{
        //     _this.grid.getColumn().get('costFor').setListofValues(_this.masterCommonService.getListFromArray(next['cost_for_ind']));
        // }
        _this.grid.getColumn().get('costFor').setListofValues(_this.masterCommonService.getListFromArray(next['cost_for_ind']));
      _this.grid.getColumn().get('name').setListofValues(_this.masterCommonService.getListFromArray(next['cost_detail_name_ind']));
      _this.grid.getColumn().get('type').setListofValues(_this.masterCommonService.getListFromArray(next['cost_detail_type_ind']));
      _this.grid.getColumn().get('costGroup').setListofValues(_this.masterCommonService.getListFromArray(next['cost_group_ind']));
      _this.grid.getColumn().get('costType').setListofValues(_this.masterCommonService.getListFromArray(next['cost_type_ind']));
      _this.grid.getColumn().get('quantityOption').setListofValues(_this.masterCommonService.getListFromArray(next['quantity_option_ind']));
      _this.grid.getColumn().get('percentageComponent').setListofValues(_this.masterCommonService.getListFromArray(next['percentage_component_ind']));
      _this.grid.getColumn().get('paymentType').setListofValues(_this.masterCommonService.getListFromArray(next['cost_payment_type_ind']));
      _this.grid.getColumn().get('counterparty').setListofValues(_this.masterCommonService.getListFromArray(next['counterparty'].sort()));
      _this.grid.getColumn().get('paymentTerm').setListofValues(_this.masterCommonService.getListFromArray(next['paymentterm']));
      _this.grid.getColumn().get('uom').setListofValues(_this.masterCommonService.getListFromArray(next['unitofmeasurement']));
      _this.grid.iconName = 'priceFixing';
      _this.grid.gridAdvacedFilterURL='/api/cost/v1/getcostbycriteria?tenantId='+ this.commonService.getFromStorage('tenantId') + '&costChargesType=' + _this.getScreenType();
        _this.route.queryParams.subscribe(params => {
            if(params !== undefined && params !== null && Object.keys(params).length > 0) {
                _this.openForm = true;
                let value = params['value'];
                let costFor = params['costFor'];
                _this.grid.getColumn().get('costFor').setDefaultValue(costFor);
                let list:KeyValue[] = [new KeyValue()];
                list.push(new KeyValue(value,value));
                _this.grid.getColumn().get('linkTo').setListofValues(list);
                _this.grid.getColumn().get('linkTo').setDefaultValue(value);
            }
        });
    });
      this.updatepayloadfunction =  function(value) {
          let data = value['data'];
          data['costChargesType']= _this.getScreenType();
          return data;
      }

  }

  private getScreenType() {
      return window.location.hash == "#/charges"?'charges':'cost'
  }

    onFormOpened(value) {
      this.openForm = false;
      this.grid.enableButton("Save and Actualize");
    }

  getCostGrid() {
      this.costData={};
      let functionCostValueValidators = function (value: any[] = []) {
          let validators: Validators[] = [Validators.required];
          if (value[0] !== null && value[0] !== undefined && value[0].toLowerCase() === 'percentage simple') {
              validators.push(Validators.min(-100));
              validators.push(Validators.max(100));
          } else {
              validators.push(ctrmValidators.decimalLengthValidator(10, 2));
          }
          return validators;
      };

      let functionQuantityOptionList = function (value: any[] = []) {
          let quantityOption = '';
          if (value[0] !== null && value[0] !== undefined && value[0].toLowerCase() === 'percentage simple') {
              quantityOption = 'finalCFValue';
          }
          return quantityOption;
      };

      let functinUOMdisable = function (value: any[] = []) {
          let obj: any = {meta: {disabled: false}};
          if (value[0] !== null && value[0] !== undefined && value[0].toLowerCase() === 'lumpsum') {
              obj = {meta: {disabled: true}};
          }
          return obj;
      };


      let functionuomvalidation = (value: any[] = []) => {
          let validators: Validators[] = [];
          if (value[0] !== null && value[0] !== undefined && value[0].toLowerCase() === 'per unit') {
              validators.push(Validators.required);
          }
          return validators;
      };

      let actionCostType = new Action();
      actionCostType.addFieldToUpdate('costValue', undefined, functionCostValueValidators, undefined, undefined, true, 'validators');
      actionCostType.addFieldToUpdate('uom', undefined, functinUOMdisable, undefined, undefined, true, 'extra');
      actionCostType.addFieldToUpdate('percentageComponent', undefined, functionQuantityOptionList, undefined, undefined, true, 'defaultValue');
      actionCostType.addFieldToUpdate('uom', undefined, functionuomvalidation, undefined, undefined, true, 'validators',);
      let actionStartDate = new Action();
      actionStartDate.addFieldToUpdate('timeBaseEndDate', undefined, undefined, undefined, undefined, true, 'minDate');

      let paymentDueDateFunc = function (value: any[]) {
          let obj = {meta: {disabled: false}};
          if (value[0].toString().toLowerCase() === 'calculated' || value[0].toString().toLowerCase() === 'relative') {
              obj = {meta: {disabled: true}};
          }
          return obj;
      };

      let paymentTermFunc = function (value: any[]) {
          let obj = {meta: {disabled: true}};
          if (value[0].toString().toLowerCase() === 'calculated' || value[0].toString().toLowerCase() === 'relative') {
              obj = {meta: {disabled: false}};
          }
          return obj;
      };
      let _this=this;

      let counterpartyListFunction = (value: any[] = []) => {
          if(value !== undefined && value !== null && value.length > 0) {
              return value[0]['counterpartyCode'];
          }
          return '';
      };
      let CostValueFunction = function(value: any) {
          return _this.commonService.formatNumberWithoutComma(value,_this.priceRoundOff);
      }

      let apiDecideFunction = (rowData) => {
          if(rowData['linkTo']){
              if(rowData['costFor'] === 'TRADE') {
                  return environment.base_url + '/api/trade/v1/getTrade?tradeId=' +rowData['linkTo']+ '&tenantId=' +this.commonService.getTenantId();
              } else {
                  return environment.base_url+'/api/plannedObligation/v1/getplannedobligationsbyplannedobligationid?tenantId='+
                      this.commonService.getFromStorage('tenantId')+'&plannedObligationId='+rowData['linkTo'];
              }
          }

      }

      let defaultPaymentDueDate = (value) => {
          let resp = value[0];
          let currentVal = value[1];
          let costFor = value[2];
          if(currentVal === null || currentVal === undefined || currentVal === '') {
              if(costFor === 'TRADE') {
                  return _this.commonService.convertUTCtoDate(resp['periodEndDate'],true);
              } else {
                  return _this.commonService.convertUTCtoDate(resp['deliveryEndDate'],true);
              }
          }
          return currentVal;
      }
      let actionCounterparty = new Action(undefined,undefined,undefined,undefined,undefined,undefined,undefined,false,false,apiDecideFunction);
      actionCounterparty.addFieldToUpdate('counterparty', undefined, counterpartyListFunction, ['DB'], undefined, true, 'defaultValue');
      actionCounterparty.addFieldToUpdate('paymentDueDate', undefined, defaultPaymentDueDate, ['DB','paymentDueDate','costFor'], undefined, true, 'defaultValue');


      let actionPaymentType = new Action();
      actionPaymentType.addFieldToUpdate('paymentDueDate', undefined, paymentDueDateFunc, undefined, undefined, true, 'extra');
      actionPaymentType.addFieldToUpdate('paymentTerm', undefined, paymentTermFunc, undefined, undefined, true, 'extra');
      let linkToFunction = function (value: any[]) {
          return _this.masterCommonService.getListFromArray(value[0]);
      };
      let actionLinkFor = new Action(environment.base_url + '/api/cost/v1/getlinktolistbycostfor?tenantId=' + this.commonService.getFromStorage('tenantId') + '&costFor=',undefined,undefined,undefined,undefined,undefined,undefined,true);
      actionLinkFor.addFieldToUpdate('linkTo', undefined, linkToFunction, ['DB'], undefined, false, 'listvalues',);
      actionLinkFor.runAlways = true;
      let columns: Map<string, Tcolumn> = new Map();


      /*****************section 1 *********************/
      columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
      if(window.location.hash === "#/charges"){
          columns.set('costFor', new Tcolumn('costFor', 'Charges For', 'L', 1, false, undefined, true, 'OBLIGATION', 'B', [], undefined, undefined, undefined, undefined, undefined, actionLinkFor, {meta: {disabled: false}},true));
          columns.set('costId', new Tcolumn('costId', 'Charges ID', 'LB', 3, true, undefined, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
          columns.set('costGroup', new Tcolumn('costGroup', 'Charges Group', 'L', 7, true, this.masterCommonService.getListFromArray(this.costData['cost_group_ind']), true, '', 'B', [Validators.required]));
          columns.set('costType', new Tcolumn('costType', 'Charges Type', 'L', 8, true, this.masterCommonService.getListFromArray(this.costData['cost_type_ind']), true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionCostType));
          columns.set('costValue', new Tcolumn('costValue', 'Charges Value', 'N', 9, true, undefined, true, new defaultValue('', undefined, undefined, undefined, false, false, false, CostValueFunction), 'B', [Validators.required]));
          columns.set('costCurrency', new Tcolumn('costCurrency', 'Charges Currency', 'L', 10, true, this.commonService.getCurrencyList(), true, 'USD', 'B', []));

      }else{
          columns.set('costFor', new Tcolumn('costFor', 'Cost For', 'L', 1, false, undefined, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined, actionLinkFor, {meta: {disabled: false}},true));
          columns.set('costId', new Tcolumn('costId', 'Cost ID', 'LB', 3, true, undefined, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
          columns.set('costGroup', new Tcolumn('costGroup', 'Cost Group', 'L', 7, true, this.masterCommonService.getListFromArray(this.costData['cost_group_ind']), true, '', 'B', [Validators.required]));
          columns.set('costType', new Tcolumn('costType', 'Cost Type', 'L', 8, true, this.masterCommonService.getListFromArray(this.costData['cost_type_ind']), true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionCostType));
          columns.set('costValue', new Tcolumn('costValue', 'Cost Value', 'N', 9, true, undefined, true, new defaultValue('', undefined, undefined, undefined, false, false, false, CostValueFunction), 'B', [Validators.required]));
          columns.set('costCurrency', new Tcolumn('costCurrency', 'Cost Currency', 'L', 10, true, this.commonService.getCurrencyList(), true, '', 'B', []));

      }
      columns.set('linkTo', new Tcolumn('linkTo', 'Link To', 'EL', 2, false, undefined, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined, actionCounterparty, {meta: {disabled: false} ,dropdown:{filter:true}},true));
      columns.set('name', new Tcolumn('name', 'Name', 'L', 4, true, this.masterCommonService.getListFromArray(this.costData['cost_detail_name_ind']), true, '', 'B', [Validators.required]));
      columns.set('type', new Tcolumn('type', 'Type', 'L', 5, true, this.masterCommonService.getListFromArray(this.costData['cost_detail_type_ind']), true, 'Primary', 'B', [Validators.required]));
      columns.set('counterparty', new Tcolumn('counterparty', 'Counterparty', 'L', 6, true, this.masterCommonService.getListFromArray(this.costData['counterparty']), true, undefined, 'B'));
      columns.set('uom', new Tcolumn('uom', 'Unit of Measurement', 'L', 11, true, this.masterCommonService.getListFromArray(this.costData['unitofmeasurement']), true, 'MT', 'B'));
      columns.set('quantityOption', new Tcolumn('quantityOption', 'Quantity Option', 'L', 12, true, this.masterCommonService.getListFromArray(this.costData['quantity_option_ind']), true, 'Schedule Quantity', 'B', [Validators.required]));
      columns.set('percentageComponent', new Tcolumn('percentageComponent', 'Percentage Component', 'L', 13, true, this.masterCommonService.getListFromArray(this.costData['percentage_component_ind']), true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
      columns.set('description', new Tcolumn('description', 'Description', 'TA', 14, true, undefined, true, '', 'B', []));
      /***************section 2 *********************/
      columns.set('inputDate', new Tcolumn('inputDate', 'Input Date', 'D', 15, true, undefined, true, new Date(), 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined));
      columns.set('paymentType', new Tcolumn('paymentType', 'Payment Option Type', 'L', 16, true, this.masterCommonService.getListFromArray(this.costData['cost_payment_type_ind']), true, '', 'F', [], undefined, undefined, undefined, undefined, undefined, actionPaymentType));
      columns.set('paymentTerm', new Tcolumn('paymentTerm', 'Payment Term', 'L', 17, true, this.masterCommonService.getListFromArray(this.costData['paymentterm']), true, '', 'B'));
      columns.set('paymentDueDate', new Tcolumn('paymentDueDate', 'Payment Due Date', 'D', 18, true, undefined, true, '', 'B', []));
      columns.set('percentagePayable', new Tcolumn('percentagePayable', 'Percentage Payable', 'N', 19, true, undefined, true, 100, 'B', [Validators.required, Validators.max(100), Validators.min(0)], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
      columns.set('taxApplicable', new Tcolumn('taxApplicable', 'Tax Applicable', 'B', 20, true, this.masterCommonService.getBooleanFromLabel('Yes', 'No'), false, true, 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
      /************** section 3 *****************/
      columns.set('additionalNote', new Tcolumn('additionalNote', 'Additional Note', 'TA', 21, true, undefined, true, '', 'B', [], undefined, 3));
      if(window.location.hash === "#/charges"){
          this.grid = new Grid(columns, true, true, true, true, 'costId', '/api/cost/v1/getAllCosts?tenantId=' + this.commonService.getFromStorage('tenantId')+"&costChargeType=charges", '', 'Charges', undefined, undefined);
      }else{
          this.grid = new Grid(columns, true, true, true, true, 'costId', '/api/cost/v1/getAllCosts?tenantId=' + this.commonService.getFromStorage('tenantId')+"&costChargeType=cost", '', 'Cost', '', '', undefined, undefined, false);
      }
      this.grid.setGridConfiguration(true, true, true, false);
      this.grid.addSection('Cost Details', ['costFor', 'linkTo','costId', 'name', 'type', 'counterparty', 'costGroup', 'costType', 'costValue', 'costCurrency', 'uom', 'quantityOption', 'percentageComponent', 'description']);
      this.grid.addSection('Cost Payment & Schedule Details', ['inputDate', 'paymentType', 'paymentTerm', 'paymentDueDate', 'percentagePayable','taxApplicable']);
      this.grid.addSection('Others', ['additionalNote']);
      this.grid.setSaveUrl(environment.base_url + '/api/cost/v1/savecost?tenantId=' + this.commonService.getFromStorage('tenantId'));
      if(window.location.hash === "#/charges"){
          this.grid.showExtraButtons = true;
          this.addExtraButton("Save and Actualize");
      }
      this.grid.setUpdateUrl(environment.base_url + '/api/cost/v1/update?tenantId=' + this.commonService.getFromStorage('tenantId'));
      this.grid.hrefUrl = environment.base_url + '/api/cost/v1/getbycostuuid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&uuid=';
      this.grid.importUrl = environment.base_url + '/api/cost/v1/import?tenantId=' + this.commonService.getFromStorage('tenantId');
      this.grid.ignoreFieldListForExport = ['costId'];
      this.grid.actionFunc.newCopyValueFunc = (value) => {
          if(value['paymentDueDate'] === null || value['paymentDueDate'] === undefined || value['paymentDueDate'].toString().length == 0) {
              value['paymentDueDate'] = '';
          } else {
              value['paymentDueDate'] = _this.commonService.convertUTCtoDate(value['paymentDueDate'],true);
          }

          if(value['inputDate'] === null || value['inputDate'] === undefined) {
              value['inputDate'] = '';
          } else {
              value['inputDate'] = _this.commonService.convertUTCtoDate(value['inputDate'],true);
          }
          return value;
      }
  }

    showToast(msg, severity: string = 'info') {
        this.messageService.add({
            severity: severity,
            summary: msg
        });
    }
    onRowSelectionChange(value: any) {
       this.selectedRowData= value;
       this.popupDisplay =(value.length == 1);
       this.addExtraButton("Update and Actualize");
  }

     addExtraButton(title:string) {
      let _this = this;
        this.grid.removeAllExtraButtons();
        this.grid.addExtraButton(new Button(title,"F","",(params)=>{
            let form:CtrmInputFormComponent = params['form'];
            let updateData = params.oldData;
            let saveData= params.data;
            if(updateData == undefined){
                saveData = params.data;
            }else {
                saveData['uuid']=updateData['uuid'];
                saveData['linkTo']=updateData['linkTo']
                saveData['costFor']='OBLIGATION'
            }
            form.closeModal();
                this.commonService.post(environment.base_url+'/api/cost/v1/createCostandActulaize?costChargesType='+this.costChargesType,saveData).subscribe(data=>{
                    if(data['errorInstance']==true){
                        this.showToast(data['status'],'error');
                        this.popupDisplay= false;
                        this.refreshGrid = true;
                        form.closeModal();
                    }else {
                        this.showToast("Charge Created Succesfully and Actulalized");
                        this.popupDisplay = false;
                        this.refreshGrid = true;
                        form.closeModal();
                    }
                })
            _this.grid.disableButton(title);
        },"","","",[],false,[],true))
    }

    onClose() {
        this.popupDisplay = false;
    }
    delete() {
        this.selectedRowData.forEach(data=>{
          this.commonService.deletByURL(environment.base_url+"/api/cost/v1/deleteByCostId?costId="+data.costId+"&tenantId="+this.commonService.getFromStorage('tenantId')).subscribe((data:any)=>{
              this.showToast("Trade Cost Deleted Succefully");
              this.popupDisplay= false;
              this.refreshGrid = true;
          },
                  err=>{
              this.showToast("Trade cost is already Actualized cannot be Deleted",'error');
              this.popupDisplay= false;
              this.refreshGrid = true;
          })
        })

    }

    onSaveDone(value: any) {

    }
}
