<div class="mainDiv">
  <div (click)="onExpandClick()" [ngClass]="position === 'right'?'rightExpandable':''" class="expandableHeader">
    <i class="chevronIconStyle" *ngIf="position === 'left'" [ngClass]="isExpanded?'fas fa-chevron-down':'fas fa-chevron-right'"></i>
    <div [ngClass]="position ==='left'?'leftCatHeader':'rightCatHeader'">
      <div *ngFor="let category of getKeys()" class="categoryData">
        <p class="categoryHeader">{{headerFields[category]}} : </p>
        <p class="categoryValue">{{values[0][category]}}</p>
      </div>
    </div>
    <i *ngIf="position === 'right'"
       [ngClass]="isExpanded?'fas fa-chevron-down rightIconClass':'fas fa-chevron-right rightIconClass'"></i>
  </div>
  <div [hidden]="!isExpanded">
    <p-table #expandedTable
             (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
             [(selection)]="selectedRows"
             [columns]="columns"
             [responsive]="true"
             [value]="values"
             autoLayout="true"
             class="style-4 table-responsive background">
      <ng-template pTemplate="header">
        <tr class="sticky-top" style="background-color: #D9DBDE">
          <th *ngIf="position ==='right' && !readOnly" style="background: #f8f8f8 !important;"></th>
          <th *ngIf="position ==='right' && !readOnly"></th>
          <th *ngFor="let col of columns;index as i" [id]="col.field" [pSortableColumn]="col.field" class="tableHeader"
              style="white-space: nowrap;">
            <div style="padding: 5px;width: 100%;">
              <p class="headerText">{{col.getHeader()}}</p>
            </div>
          </th>
          <th *ngIf="position ==='left' && !readOnly"></th>
          <th *ngIf="position ==='left' && !readOnly" style="background: #f8f8f8 !important;"></th>
        </tr>
      </ng-template>
      <ng-template let-columns="columns" let-ri="rowIndex" let-rowData pTemplate="body">
        <tr [pSelectableRow]="updateIdAndGetRowData(rowData,ri)">
          <td *ngIf="position ==='right' && !readOnly" class="inputBoxDiv" style="padding: 0px !important;margin: 0px !important;">
            <input [(ngModel)]="rowData[matchFieldName]" [disabled]="!selectedRows.includes(rowData)" (ngModelChange)="onchange(rowData)"  (input)="inputValidator($event,rowData)" class="inputBox"
                   pInputText type="number">
          </td>
          <td *ngIf="position ==='right' && !readOnly" class="checkBoxtd borderCheckboxRight">
            <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
          </td>
          <td *ngFor="let col of columns;index as pos" [hidden]="!col.isVisible()" [id]="'body'+pos"
              [pReorderableRow]="ri"
              class="ui-resizable-column tdStyle">
            <span *ngIf="!col.isEditable()"
                  class="spanClass">
               <div [innerHTML]="getOutputValue(col,ri,rowData)"
                    style="white-space: nowrap;"></div>
            </span>
          </td>
          <td *ngIf="position ==='left' && !readOnly" class="checkBoxtd borderCheckboxLeft">
            <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
          </td>
          <td *ngIf="position ==='left' && !readOnly" class="inputBoxDiv" style="padding: 0px !important;margin: 0px !important;">
            <input [(ngModel)]="rowData[matchFieldName]" [disabled]="!selectedRows.includes(rowData)" (ngModelChange)="onchange(rowData)"  (input)="inputValidator($event,rowData)" class="inputBox"
                   pInputText type="number">
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr *ngIf="showPlaceHolder && (values === undefined ||values === null || values.length === 0)">
          <td [colSpan]="columns.length+1">
            <div [style]="{maxHeight:placeHolderHeight}" class="placeHolder">
              <div (inViewportAction)="calculateLeft()" [id]="'noDataBoxId'" [style]="{left:noDataBoxLeft}" class="noDataBox"
                   inViewport>
                <img [src]="commonService.getIconPath('emptyStateIcon')" class="iconClass"/>
                <p class="noDataHeader">No Data Available</p>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>
