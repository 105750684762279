import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TreeNode} from "primeng/api";
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {MasterService} from "../../services/MasterService";
import {CommonService} from "../../../services/common.service";
import {GridService} from "../../../grid/grid.service";
import {environment} from "../../../../environments/environment";
import {entities} from "../../../services/common/entities";
import {DatePipe} from "@angular/common";
import {BreadcrumbService} from "../../../Components/ctrm-breadcrumb/breadcrumb.service";
import {MessageService} from 'primeng';
import {AccessPolicyService} from "../../../services/accesspolicy.service";


@Component({
  selector: 'app-accesspolicy',
  templateUrl: './accesspolicy.component.html',
  styleUrls: ['./accesspolicy.component.css']
})
export class AccesspolicyComponent implements OnInit, OnChanges {
  @Input() display: boolean = false;
  @Input() data: any;
  @Input() selectedRowData: any;
  @Input() canEdit:boolean = true;
  @Output() onClose = new EventEmitter();
  @Output() throwJSON = new EventEmitter();
  @Output() onEditClick = new EventEmitter();
  @Output() onSaveComplete = new EventEmitter();
  col: any[];
  searchList: any[] = [];
  file: TreeNode[];
  pageDisable: boolean = true;
  isLoading: boolean = false;
  maxColspan: number = 2;
  defaultColspan: number = 1;
  colMap: Map<string, any>;
  contentHeight: string = '';
  expandAllValue: boolean;
  datePipe: DatePipe;
  searchValue: string = '';
  expandCount: number = 0;
  baseFile: any;
  userMasterList: any[] = [];
  //localRouteList:any[] = [];
  isFocused: boolean = false;
  createdBy: any;
  createdDate: any;
  updatedBy: any;
  updatedDate: any;
  roleDescription: any;
  rolestatus: boolean;
  href: string;
  localNameList: any[] = [];
  remoteNameList: any[] = [];
  accessMap: Map<string, any[]>;
  keys:any[] =[];
  sections:string[] = [];

  constructor(private httpClient: HttpClient, public  breadCrumbService: BreadcrumbService, private masterCommService: MasterService, private masterCommomService: MasterService, public commonService: CommonService, private gridService: GridService,
              private messageService: MessageService,private accessService:AccessPolicyService) {

  }

  @HostListener("window:resize", [])
  public onResize() {
    this.calculateHeight();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedRowData']) {
      this.roleDescription = changes['selectedRowData'].currentValue['description'];
      this.rolestatus = changes['selectedRowData'].currentValue['status'];
      this.getUsersList(changes['selectedRowData'].currentValue);
      this.updateUserInfo(changes['selectedRowData'].currentValue['createdBy'], changes['selectedRowData'].currentValue['createdTimestamp'], 'create');
      this.updateUserInfo(changes['selectedRowData'].currentValue['updatedBy'], changes['selectedRowData'].currentValue['updatedTimestamp'], 'update');
    }
  }

  getUsersList(rowData) {
    let _this = this;
    this.commonService.post(environment.base_url_new + '/api-iam/api/usermanagementservice/v1/getUsersByRole?tenantId=' + this.commonService.getTenantId(), [rowData['roleName']]).subscribe((next: any[]) => {
      _this.userMasterList = next;
    });
  }

  ngOnInit() {
    let _this  = this;
    this.datePipe = new DatePipe('en-US');
    this.baseFile = [];
    this.keys = Object.keys(this.data);
    this.accessMap = new Map<string, any[]>();
    let sectionName:string = '';
    this.localNameList = Object.keys(this.data);
    this.keys.forEach(function (key) {
      sectionName = _this.data[key]['info']['groupName'];
      if(!_this.accessMap.has(sectionName)){
        _this.accessMap.set(sectionName,[]);
      }
      _this.accessMap.get(sectionName).push(_this.data[key]);
    });
    this.sections= Array.from(this.accessMap.keys());
    this.colMap = new Map();
  }


  outValue() {                          //for closing/opening the Access Policy Modal
    this.onClose.emit(!this.display);
  }

  getRowDate(rowDatum: any) {
    return rowDatum;
  }

  onSave() {
    let policyData = JSON.stringify(this.data);
    this.selectedRowData['policy'] = policyData;
    this.selectedRowData['description'] = this.roleDescription;
    this.selectedRowData['status'] = this.rolestatus;
    this.selectedRowData['updatedBy'] = this.masterCommomService.getUserId();
    this.selectedRowData['tenantId'] = this.commonService.getTenantId();
    let _this = this;
    this.isLoading = true;
    this.commonService.post(environment.base_url_new + '/api-iam/api/rolemaster/v1/updaterolemaster', this.selectedRowData).subscribe((next: any) => {
      _this.throwJSON.emit(_this.data);
      _this.isLoading = false;
      _this.outValue();
      _this.showToast('Row Successfully saved');

    }, (error: HttpErrorResponse) => {
      _this.messageService.add({severity: 'error', summary: this.commonService.getHttpErrorMessage(error, 'access policy')});
    });
  }

  showToast(msg, severity: string = 'success') {
    this.messageService.add({
      severity: 'info',
      summary: msg
    });
  }

  getDataLength(rowDatum: any, field) {
    let len = Object.keys(rowDatum).length;
    return len;
  }

  getDataLabel(rowDatum: any, i: number) {
    let label = Object.keys(rowDatum);
    return label[i];
  }

  getRowDataData(rowData) {
    return Object.keys(rowData['data']);
  }

  getColumns(rowData) {
    return this.colMap.get(rowData['name']);
  }

  /*
  This func is used to add Coloumns in the Access Policy Tree Table
  params: name (The label of the row)
          columnName (name of Column)
          cs (Colspan for that row)
   */
  addColumns(name, columnName, cs: any = 'default') {
    if (this.colMap.has(name)) {
      let arr = this.colMap.get(name);
      let found: boolean = false;
      arr.forEach(function (data) {
        if (data['field'] === columnName) {
          found = true;
        }
      });
      if (!found) {
        arr.push({field: columnName, colSpan: cs});
        this.colMap.set(name, arr);
      }
    } else {
      this.colMap.set(name, [{field: columnName, colSpan: cs}]);
    }
  }

  updateUserInfo(id, date, mode = 'create') {
    if (id !== null) {
      let _this = this;
      this.commonService.getJSONByURL(environment.base_url_new + '/api-iam/api/usermanagementservice/v1/getbyuserid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&userId=' + id).subscribe((next: any[]) => {
        if (next !== undefined && next !== null) {

          if (mode === 'create') {
            _this.createdBy = next['value'];
            _this.createdDate = _this.datePipe.transform(date, environment.full_date_format);
          } else {
            _this.updatedBy = next['value'];
            _this.updatedDate = _this.datePipe.transform(date, environment.full_date_format);
          }
        }
      });
    }
  }

  /*
    This func is  used to get the Col span Value Dynamically
   */
  getColSpanValue(cs: any) {
    if (cs === 'max') {
      return this.maxColspan;
    } else if (cs === 'default') {
      return this.defaultColspan;
    } else {
      let sp = cs.split('+');
      let num = parseInt(sp[1]);
      return this.defaultColspan + num;
    }
  }

  calculateHeight() {
    this.contentHeight = ((window['visualViewport'].height - document.getElementById('contentBox').offsetTop) - 160) + 'px';
  }

  onClickEdit() {
    this.pageDisable = !this.pageDisable;
    // this.onEditClick.emit({rowData:this.selectedRowData});
    // this.outValue();
  }

  collapseAll() {
    this.expandCount = 0;
    this.expandAllValue = false;
  }

  expandAll() {
    this.expandCount = 0;
    this.expandAllValue = true;
  }

  resetExpandValue() {
    this.expandCount++;
    if (!(this.expandCount < this.localNameList.length)) {
      this.expandAllValue = undefined;
    }
  }

  onUpdate(value: any) {
    this.searchValue = value['target']['value'];
  }

  isHidden(data: any) {
    if (data['info']['screenName'].toLowerCase().includes(this.searchValue.toLowerCase())) {
      return false;
    }
    return true;
  }

  getFromBase(index: any) {
    return this.baseFile[index];
  }

  getTooltip(userMasterList: any[]) {
    let tooltopMessage = '';
    let _this = this;
    userMasterList.forEach(function (userMaster, index) {
      if (index > 1) {
        tooltopMessage += _this.commonService.getFullName(userMaster) + '\n';
      }
    });
    return tooltopMessage;
  }

  onFocusIn() {
    this.isFocused = true;
  }

  onFocusOut(searchValue: string) {
    if (searchValue === undefined || searchValue.length === 0) {
      this.isFocused = false;
    }
  }

  clearAndClose() {
    this.isFocused = false;
    this.searchValue = '';
  }

  updateValue(value: any) {
    this.searchList = [];
    this.searchValue = value;
  }

  getScreenObject(item: any) {
    return this.data[this.getScreenObjectIndex(item)];
  }

  getScreenObjectIndex(item: any) {
    return this.localNameList.indexOf(item)
  }

  isAllRouteEnabled(list: any[]) {
    let isAvail = false;
    let _this = this;
    list.forEach(function (item) {
      if (!isAvail && _this.localNameList.includes(item)) {
        isAvail = true;
      }
    });
    return isAvail;
  }

  reset() {
    this.searchList = [];
    return false;
  }

  getIsInSearch(listElement: any[]) {
    let _this = this;
    let isInSearch = false;
    listElement.forEach(function (item) {
      if (!isInSearch) {
        isInSearch = _this.searchList.includes(item['route']);
      }
    });
    return isInSearch;
  }

  contains(list:any[], searchValue: string) {
    let contains: boolean = false;
    list.forEach(function (data: string) {
      if (!contains && data['info']['screenName'].toLowerCase().includes(searchValue.toLowerCase())) {
        contains = true;
      }
    });
    return !contains;
  }

  toggleAll(key:string, toggleValue: boolean = false) {
    let keys:any[]= [];
    let accesskeys:any[]= [];
    this.accessMap.get(key).forEach(function (obj:any) {
      keys = Object.keys(obj);
      keys.forEach(function (childKey) {
        if(childKey !== 'info') {
          accesskeys = Object.keys(obj[childKey]);
          accesskeys.forEach(function (akey) {
            obj[childKey][akey] = toggleValue;
          });
        }
      });
    });
  }
}
