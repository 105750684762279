import {Component, OnInit} from '@angular/core';
import {Tcolumn} from '../../grid/tcolumn.model';
import {Validators} from '@angular/forms';
import {Grid} from '../../grid/grid.model';
import {MasterService} from '../services/MasterService';
import {defaultValue} from '../../grid/defaultValue.model';
import {environment} from '../../../environments/environment';
import {ChildAction} from '../../grid/ChildAction.model';
import {CommonService} from '../../services/common.service';
import {Tab} from '../../tab-grid/Tab.model';
import {Action} from '../../grid/actions.model';
import {repos} from '../../services/common/entities';

@Component({
    selector: 'app-grademaster',
    templateUrl: './grademaster.component.html',
    styleUrls: []
})
export class GrademasterComponent implements OnInit {
    columns: Map<string, Tcolumn>;
    grid: Grid;
    tabs: Tab[] = [];
    currentHref: string = '';
    loadDataList: any[];

    constructor(public commonService: CommonService, private masterCommonService: MasterService) {
    }

    ngOnInit() {
      this.loadGrid();
    }

    public async loadGrid() {
         this.loadDataList = await this.commonService.getLoadDataAsync('grade', ['data','data'], [[{key: 'name', value: 'quality_parameter_ind'}],[{key:'name', value:'quality_spec_unit_ind'}]]).then((next: any) => {
             return next;
         });
        this.getColumns();
        let copy = new ChildAction('gradeUuid');
        copy.addRepo('xceler_configservice_gradequalityspec', repos.gradeQualitySpec);
        copy.setPrimaryKeyCode('uuid');
        this.tabs.push(this.getGradeQualitySpec());
        this.grid = new Grid(this.columns, true, true, true, true, 'name', '/api/grade/v1/getall?tenantId=' + this.commonService.getFromStorage('tenantId'), '', 'Grade Master', '');
        this.grid.setSaveUrl(environment.base_url + '/api/grade/v1/save');
        this.grid.setUpdateUrl(environment.base_url + '/api/grade/v1/update');
        this.grid.hrefUrl = environment.base_url + '/api/grade/v1/getbyuuid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&uuid=';
        this.grid.importUrl = environment.base_url + '/api/grade/v1/import';
        this.grid.setChildCopyDelete(copy);
        this.grid.iconName = 'gradeMaster';
        this.grid.setTabs(this.tabs);
        this.grid.addChildTabs(this.getGradeQualitySpec());

    }

    public getColumns() {
        this.columns = new Map();
        this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
        this.columns.set('code', new Tcolumn('code', 'Grade Code', 'T', 1, false, undefined, true,new defaultValue('',undefined,['-','_'],undefined,false,true,false,undefined,undefined,true), 'B', [Validators.required, Validators.maxLength(8)], undefined, undefined, undefined, undefined, [], undefined, undefined, true));
        this.columns.set('name', new Tcolumn('name', 'Grade Name', 'T', 2, false, undefined, true, new defaultValue('', ''), 'B', [Validators.required, Validators.maxLength(100)], undefined, undefined, undefined, undefined, [], undefined, undefined, true));
        this.columns.set('description', new Tcolumn('description', 'Grade Description', 'TA', 3, true, undefined, true, '', 'B', [Validators.maxLength(200)]));
        this.columns.set('status', new Tcolumn('status', 'Status', 'B', 4, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));
    }

    private getGradeQualitySpec() {
        let columns: Map<string, Tcolumn> = new Map();
        let minVal = function minVali(value: any[] = []) {
            let current = [Validators.min(parseInt(value[0]))];
            return current;
        };
        let action = new Action();
        action.addFieldToUpdate('max', undefined, minVal, undefined, undefined, true, 'validators');
        columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
        columns.set('name', new Tcolumn('name', 'Quality Parameter Name', 'L', 2, false, this.masterCommonService.getListFromArray(this.loadDataList['quality_parameter_ind']), true, '', 'B', [Validators.required], undefined, 1, undefined, undefined, undefined));
        columns.set('min', new Tcolumn('min', 'Min', 'N', 3, true, undefined, true, '', 'B', [Validators.min(0)], undefined, 1, undefined, undefined, undefined, action));
        columns.set('max', new Tcolumn('max', 'Max', 'N', 4, true, undefined, true, '', 'B', [Validators.min(0)]));
        columns.set('typical', new Tcolumn('typical', 'Typical', 'T', 5, true, undefined, true, '', 'B', [Validators.min(0), Validators.maxLength(50)]));
        columns.set('basis', new Tcolumn('basis', 'Basis', 'T', 6, true, undefined, true, '', 'B', [Validators.min(0), Validators.maxLength(50)]));
        columns.set('unit', new Tcolumn('unit', 'Unit', 'EL', 7, true,  this.masterCommonService.getListFromArray(this.loadDataList['quality_spec_unit_ind']), true, '', 'B', [Validators.required]));
        columns.set('status', new Tcolumn('status', 'Status', 'B', 8, true, this.masterCommonService.getActiveInactive(), true, true, 'B', [Validators.required]));
        let grid = new Grid(columns, true, true, true, true, 'name', '/api/gradedetails/v1/getbygradeuuid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&gradeUuid=', '', 'Grade Quality Specification', 'name');
        let tab = new Tab(grid.getTitle(), grid, 'name');
        tab.idFieldValueName = 'gradeUuid';
        grid.setSaveUrl(environment.base_url + '/api/gradedetails/v1/save');
        grid.setUpdateUrl(environment.base_url + '/api/gradedetails/v1/update');
        grid.hrefUrl = environment.base_url + '/api/gradedetails/v1/getbyuuid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&uuid=';
        grid.importUrl = environment.base_url + '/api/gradedetails/v1/importgradequality';
        return tab;
    }

}
