<div>
    <ngx-graph
            [view]="[width,height]"
            [autoCenter]="true"
            [autoZoom]="false"
            [curve]="curve"
            [draggingEnabled]="false"
            [showMiniMap]="false"
            [nodes]="nodeData"
            [links]="linkData"
            layout="dagre">
        <ng-template #defsTemplate>
            <svg>
                <marker id="arrow" viewBox="0 -5 10 10" refX="8" refY="0" markerWidth="4" markerHeight="4" orient="auto">
                    <svg>
                        <path d="M0,-5L10,0L0,5" fill="#364149" class="arrow-head"></path>
                    </svg>
                </marker>
            </svg>
        </ng-template>
        <ng-template #nodeTemplate let-node>
            <svg>
                <g class="node">
                    <svg>
                        <rect [attr.width]="node.dimension.width"
                              [attr.height]="node.dimension.height"
                              [attr.fill]="node.data.color">
                        </rect>
                    </svg>
                    <svg>
                        <text alignment-baseline="central" [attr.x]="10" [attr.y]="node.dimension.height / 2">{{node.label}}</text>
                    </svg>
                </g>
            </svg>
        </ng-template>
    </ngx-graph>
</div>
