import {Component, Input, OnInit} from '@angular/core';
import {CommonService} from "../../../services/common.service";

@Component({
  selector: 'app-expand-grid',
  templateUrl: './expand-grid.component.html',
  styleUrls: ['./expand-grid.component.css']
})
export class ExpandGridComponent implements OnInit {
  @Input() value: any[] = [];
  cols: any[] = [];

  constructor(public commonService: CommonService) {
  }

  ngOnInit(): void {
    this.cols = [
      {field: 'tradeType', header: 'Trade Type'},
      {field: 'commodity', header: 'Commodity'},
      {field: 'buyOpenPosition', header: 'Buy Open Position', qty:true},
      {field: 'sellOpenPosition', header: 'Sell Open Position', qty:true},
      {field: 'closedPosition', header: 'Closed Position',qty:true},
      {field: 'mtmPnl', header: 'M2M', curr:true},
      {field: 'closedPnlToday', header: 'Closed PnL', curr:true},
      {field: 'realisedPnlToday', header: 'Realised Pnl', curr:true},
      {field: 'freightCost', header: 'Freight Cost', curr:true},
      {field: 'financeCost', header: 'Finance Cost', curr:true},
      {field: 'insuranceCost', header: 'Insurance Cost', curr:true},
      {field: 'taxCost', header: 'Tax Cost', curr:true},
      {field: 'otherCost', header: 'Other Cost', curr:true},
      {field: 'totalCost', header: 'Cost', curr:true},
      {field: 'nbv', header: 'Net Book Value', curr:true}
    ];
  }

  getCurrency(data,col) {
    return this.commonService.getFormatedNumber(data[col.field])+ ' '+data['eodCurrency'];      //For Demo purpose only, replace USD with actual Currency UOM
  }

  getUom(data,col) {
    return this.commonService.getFormatedNumber(data[col.field]) +' '+data['eodUOM'];      //For Demo purpose only, replace MT with actual Quantity UOM
  }

  getRowDataValue(rowData, col: any) {
    if(col.field === 'commodity') {
      let val:string = rowData[col.field];
      if(val !== undefined && val !== null && val.length > 0) {
        val = val.replace(new RegExp(',','g'),', ');
      }
      return val;
    }
    return rowData[col.field];
  }

  getCommaUpdatedValue(val) {
    if(val !== undefined && val !== null && val.length > 0) {
      val = val.replace(new RegExp(',','g'),', ');
    }
    return val;
  }
}
