<div id="sidebardiv" [ngClass]="mainsidebardiv()" class="d-flex flex-column flex-shrink-0 vh-100">
    <div [ngClass]="inlinediv()"  style="width:100%;">
        <div (click)="toggle()" class="expander" [ngStyle]="{'width':(expanded) ?'50%':'100%'}" >
            <img [src]="!expanded ?'../assets/images/svg/opencollapse.svg':'../assets/images/svg/closecollapse.svg'">
            <p *ngIf="expanded"style="font-weight: bold;color: #acafb2;" >Collapse</p>
        </div>
        <div class="pinicon"  style="width: 50%;margin-left: 30%">
            <img *ngIf="expanded" (click)="togglepin()" [src]="!pin ?'../assets/images/svg/pincollapse.svg':'../assets/images/svg/unpincollapse.svg'">
        </div>
    </div>

    <span layout="row"><hr flex/></span>
    <ul class="nav nav-pills nav-flush flex-column mb-auto text-center">
        <li *ngFor="let item of list" class="nav-item" (click)="onclick(item)" [ngClass]="selectedMenuHeader===item['name']?'selectedItem':''">
            <a class="{{item.class}}" pTooltip="{{item.name}}" href="{{item.link}}" style="display: inline-flex;padding: .5rem 0.5rem 0.5rem 1rem;">
                <img src="{{item.icon}}" class="masterIcons fa-2xl" style="width: 30px;height: 30px;">
                <small *ngIf="expanded" style="font-weight: bold">{{item.name}}</small>
            </a>
        </li>
    </ul>
</div>


