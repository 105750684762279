import {Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges,Output} from '@angular/core';
import {Tcolumn} from '../../../../grid/tcolumn.model';
import {FormGroup, Validators} from '@angular/forms';
import {KeyValue} from '../../../../grid/key-value.model';

@Component({
  selector: 'app-desired-end-status',
  templateUrl: './desired-end-status.component.html',
  styleUrls: ['./desired-end-status.component.css']
})
export class DesiredEndStatusComponent implements OnInit,OnChanges {

  @Input() display: boolean;
  @Input() rowData:any;
  @Input() viewOnly:boolean=false;
  columns:Tcolumn[] =[];
  desiredEndStatusList:any[] = [];
  @Output() onClose = new EventEmitter();
  @Output() onSave = new EventEmitter();
  formGroup:FormGroup;
  refreshFormGroup :boolean = false;

  constructor() {
  }

  ngOnInit(): void {
    this.getColumns();
    if (this.desiredEndStatusList.length ===0){
      this.addNew();
    }
  }

  private getColumns() {
    this.columns=[];
    this.columns.push(new Tcolumn('key','Key',(this.viewOnly?'LB':'T'),0,!this.viewOnly,undefined,true,undefined,'B',[Validators.required]));
    this.columns.push(new Tcolumn('impact','Impact',(this.viewOnly?'LB':'L'),0,!this.viewOnly, this.getImpactList(),true,undefined,'B',[Validators.required]));
    this.columns.push(new Tcolumn('action','Action',(this.viewOnly?'LB':'T'),0,!this.viewOnly,undefined,true,undefined,'B',undefined));
  }

  getImpactList(){
    let list = [];
    list.push(new KeyValue("Select", ""));
    list.push(new KeyValue("Positive", "positive"));
    list.push(new KeyValue("Negative", "negative"));
    list.push(new KeyValue("Neutral", "neutral"));
    return list;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['rowData']) {
      this.desiredEndStatusList.length = 0;
      if (this.rowData['desiredEndStatus'] === null || this.rowData['desiredEndStatus'] === undefined || this.rowData['desiredEndStatus'] === '') {
        this.rowData['desiredEndStatus'] = [];
      }
      let _this = this;
      if (this.rowData['desiredEndStatus'] !== null && this.rowData['desiredEndStatus'] !== undefined && Object.keys(this.rowData['desiredEndStatus']).length > 0) {
        let key = Object.keys(this.rowData['desiredEndStatus']);
        let list = key.map(keys => {
          let objtemp = {
            key: keys,
            impact: this.rowData['desiredEndStatus'][keys]['impact'],
            action: this.rowData['desiredEndStatus'][keys]['postAction']
          };
          this.desiredEndStatusList.push(objtemp);
        });
      }
    }
    if(changes['viewOnly']){
      this.getColumns();
    }
  }


  onSaveUnit() {
    let obj = {};
    this.desiredEndStatusList.forEach(function(row) {
      obj[row['key']] = {'impact':row['impact'],'postAction':row['action']};
    });
    this.rowData['desiredEndStatus'] = obj;
    this.onSave.emit({data:this.rowData});
  }

  onCancel() {
    this.onClose.emit();
  }

  addNew() {
    this.desiredEndStatusList.push({key:'',action:''});
    this.refreshFormGroup= true;
  }

  updateFormGroup(value: FormGroup) {
    this.formGroup= value;
  }

  refreshcomplete() {
    this.refreshFormGroup= false;
  }
}

