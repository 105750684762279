import {Component, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {CommonService} from '../../services/common.service';
import {MasterService} from '../services/MasterService';
import {Tcolumn} from '../../grid/tcolumn.model';
import {Grid} from '../../grid/grid.model';
import {KeyValue} from '../../grid/key-value.model';
import {Table} from 'primeng';
import {BreadcrumbService} from '../../Components/ctrm-breadcrumb/breadcrumb.service';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {AccessPolicyService} from "../../services/accesspolicy.service";

@Component({
    selector: 'app-api-config',
    templateUrl: './api-config.component.html',
    styleUrls: ['./api-config.component.css']
})
export class ApiConfigComponent implements OnInit {

    columns: Map<string, Tcolumn>;
    grid: Grid;
    apiConfigData: any[] = [];
    selectedConfigs: any[] = [];
    apiConfigCols: any[] = [];
    @ViewChildren('jobTable') jobTable: Table;

    //p-sidebar
    display: boolean = false;
    dialogHeight: string = '';
    apiTypeList: any[] = [];
    authorizationType: any[] = [];
    businessApplicationType: any[] = [];
    triggerActionType: any[] = [];
    tableHeight: string = '';
    displayPosition: boolean;
    position: string;
    businessApplication: any;
    triggerAction: any;
    headers: any;
    authorization: any;
    params: any;
    url: any;
    apiType: any;
    baseFile: any;
    paramsData: any[] = [];
    headersData: any[] = [];
    addRowFlag: boolean = false;

    constructor(private httpClient: HttpClient,public commonService: CommonService,private accessSerivce:AccessPolicyService,public breadCrumbService: BreadcrumbService) {
    }

    ngOnInit(): void {
        this.breadCrumbService.makeBreadcrumbTo(this.commonService.MASTER_MAP_KEY, '/apiconfig');
        this.paramsData = [{
            key:"", value:""
        }];
        this.headersData = [{
            key:"", value:""
        }]
        this.getApiTypeList();
        this.getAuthorizationType();
        this.apiConfigCols = [
            {field: 'businessApplication', header: 'Business Application'},
            {field: 'triggerAction', header: 'Trigger Action'},
            {field: 'apiType', header: 'API Type', primary: true},
            {field: 'url', header: 'URL'},
            {field: 'authorization', header: 'Authorization', date: true},
            {field: 'header', header: 'Header'},
            {field: 'params', header: 'params'}
        ];
        this.getConfigData();
        this.baseFile = this.accessSerivce.baseAccessPolicy;
        this.getBusinessApplication(this.baseFile);
        this.getTriggerPoint()
    }

    onEditChange(field){
        if (field === 'header'){
            if (this.headersData.length > 1) {
                let obj = this.headersData[this.headersData.length - 1];
                if ((obj['key'] !== null && obj['key'] !== undefined && obj['key'].length !== 0) && (obj['value'] !== null && obj['value'] !== undefined && obj['value'].length !== 0)) {
                    this.headersData.push({key: "", value: ""});
                }
            }else {
                this.headersData.push({key: "", value: ""});
            }
        }else{
            if (this.paramsData.length > 1) {
                let obj = this.paramsData[this.paramsData.length - 1];
                if ((obj['key'] !== null && obj['key'] !== undefined && obj['key'].length !== 0) && (obj['value'] !== null && obj['value'] !== undefined && obj['value'].length !== 0)) {
                    this.paramsData.push({key: "", value: ""});
                }
            }else {
                this.paramsData.push({key: "", value: ""});
            }
        }

    }

    buildUrl(){
        let _this = this;
        let link = '';
        this.paramsData.pop();
        this.paramsData.forEach((param, index) =>{
            if (index === 0){
                link ="?" + param['key'] + "=" + param['value']
                _this.url = _this.url.concat(link);
            }else{
                link = "&" + param['key'] + "=" + param['value'];
                _this.url = _this.url.concat(link);
            }
        });
    };

    public getApiTypeList() {
        let list = [];
        list.push(new KeyValue('Select', ''));
        list.push(new KeyValue('GET', 'GET'));
        list.push(new KeyValue('POST', 'POST'));
        list.push(new KeyValue('DELETE', 'DELETE'));
        list.push(new KeyValue('PUT', 'PUT'));
        list.push(new KeyValue('PATCH', 'PATCH'));
        this.apiTypeList = list;
    }

    public getAuthorizationType() {
        let list = [];
        list.push(new KeyValue('Select', ''));
        list.push(new KeyValue('Basic Auth', 'Basic Auth'));
        list.push(new KeyValue('API Key', 'API Key'));
        list.push(new KeyValue('Bearer Token', 'Bearer Token'));
        list.push(new KeyValue('No Auth', 'No Auth'));
        this.authorizationType = list;
    }

    public getBusinessApplication(jsonFile: any) {
        let list = [new KeyValue('Select','')];
        jsonFile.forEach(obj => {
            list.push(new KeyValue(obj['data']['name'], obj['data']['name']))
        })
        this.businessApplicationType = list;
    }

    public getTriggerPoint() {
        let list = [];
        list.push(new KeyValue('Select', ''));
        list.push(new KeyValue('Reject', 'Reject'));
        list.push(new KeyValue('Approve', 'Approve'));
        this.triggerActionType = list;
    }

    calculateJobTableHeight() {
        let offsetTop = this.jobTable['first']['el']['nativeElement']['offsetTop'];
        let offsetHeight = this.jobTable['first']['el']['nativeElement']['offsetParent']['offsetHeight'];
        this.tableHeight = offsetHeight - (offsetTop + 20) + 'px';
    }

    getClassForHeading(index) {
        return index > 0 ? 'headerNormal' : 'noPipe';
    }

    getDate(date) {
        if (date !== undefined && date !== null && date.toString() !== 'Invalid Date') {
            let options = {day: 'numeric', month: 'long', year: 'numeric'};
            let newDate = new Date(date);
            return newDate.toLocaleDateString('en-US', options);
        }
        return '';
    }

    onCreateAPIConfigs() {

    }

    closeForm() {
        this.display = false;
        this.apiType = '';
        this.headersData = [{
            key:"", value:""
        }];
        this.paramsData=[{
            key:"", value:""
        }];
        this.triggerAction = '';
        this.authorization ='';
        this.url = '';
        this.businessApplication = '';
    }

    saveConfig() {
        let _this = this;
        this.buildUrl();
        let payload = {
            'tenantId': this.commonService.getFromStorage('tenantId'),
            'apiType': this.apiType,
            'authorization': this.authorization,
            'url': this.url,
            'headers': this.getHeaderMap(),
            'businessApplication': this.businessApplication,
            'triggerAction': this.triggerAction,
            'params': this.params
        };
        _this.commonService.post(environment.base_url + '/api/eai-config/v1/save', payload).subscribe(next => {
            _this.getConfigData();
            _this.closeForm();
        });
        this.getHeaderMap();
    }

//dummy header
    private getHeaderMap() {
        let obj = {
        };
        this.headersData.pop();
        this.headersData.forEach((data,index)=>{
            obj[data['key']] = data['value'];
        })
        return obj;
    }

    private getConfigData() {
        let _this = this;
        _this.commonService.getJSONByURL(environment.base_url + '/api/eai-config/v1/getall?tenantId=' + this.commonService.getFromStorage('tenantId')).subscribe((next: any[]) => {
            _this.apiConfigData = next;
        });
    }

    openForm() {
        this.display = true;
    }
}
