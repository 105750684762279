import {Component, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Action} from '../../../grid/actions.model';
import {FormGroup, Validators} from '@angular/forms';
import {KeyValue} from '../../../grid/key-value.model';
import {Tcolumn} from '../../../grid/tcolumn.model';
import {Grid} from '../../../grid/grid.model';
import {Tab} from '../../../tab-grid/Tab.model';
import {ctrmValidators} from '../../../services/validators/ctrmValidators';
import {MasterService} from '../../../masters/services/MasterService';
import {CommonService} from '../../../services/common.service';
import {Button} from '../../../grid/buttons.model';
import {defaultValue} from '../../../grid/defaultValue.model';
import {tradeOptionsButtons} from '../../../services/common/entities';
import {MessageService, Table} from 'primeng';
import {HttpErrorResponse} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {Filter} from '../../../grid/preference/filter/filter.model';
import {AccessPolicyService} from '../../../services/accesspolicy.service';
import {OverlayPanel} from 'primeng/overlaypanel';
import {tradeApprovalFunctions} from './trade-approval-functions';
import {combineLatest} from "rxjs";
import {any} from "codelyzer/util/function";

export class SearchCriteria {
  fieldName!: string;
  condition!: string;
  value!: string[];
  secondValue!: string;

  constructor(fieldName: string, value: any, condition?: any) {
    this.fieldName = fieldName;
    this.condition = condition ? condition : 'in';
    this.value = value;
    this.secondValue = '';
  }
}

@Component({
  selector: 'app-trade',
  templateUrl: './trade.component.html',
  styleUrls: ['./trade.component.css']
})
export class TradeComponent implements OnInit {

  columns: Map<string, Tcolumn>;
  grid: Grid;
  loadingInvoice: boolean = false;
  showAddCost:boolean=false;
  costFor:string="";
  linkTo:string="";
  paymentDueDate=new Date();
  displayInvoiceForm:boolean = false;
  currentRow:any = null;
  refreshTab: any ='';
  isPurchaseInvoice:boolean = false;
  contract: any;
  selectedRows: any[] = [];
  proFormaInvoiceRow:any = {};
  traderName: string = '';
  id: string = '';
  tabs: Tab[] = [];
  showDoc: boolean = false;
  refreshGrid: boolean = false;
  docUrl: string = '';
  docName: string = '';
  invoiceProFormaObject:any;
  private listCompany: any[] = [];
  private listCommodity: any[] = [];
  private listCounterparty: any[] = [];
  private listCounterpartyCode:any[] =[];
  private listProfitCenter: any[] = [];
  approve: Button;
  approveFinance: Button;
  noteState: boolean = false;
  noteTitle: string = '';
  dataToSave: any;
  url: string;
  contractId: string;
  commodity: string;
  startDate: string;
  isClickOnContext: boolean = false;
  endDate: string;
  counterParty: string;
  counterPartyCode: string;
  costObject:any = null;
  generateDocument: boolean = false;
  documentType:String='';
  shippingFormButtonLabel = "Generate";
  documentTypeDisabled: boolean = false;
  documentTemplate:any[] = [];
  documentTemplateList:any[] = [];
  documentFormatList:any[] = [];
  documentFormat:String='';
  documentTypeList:any[] = [{label: 'Select', value: ''},{label: 'Long Form Contract', value: 'Long Form Contract'},{label: 'Short Form Contract', value: 'Short Form Contract'}]
  private changeObject:any;
  legalEntity: string;
  deliveryRows:any[] = [];
  bulkOperationList: any[] =   [
    {label: 'Approve for Counterparty', command: () => {
        this.bulkTradeOperation('approveCounterparty');
      }},
    {label: 'Financial Approval', command: () => {
        this.bulkTradeOperation('approveFinance');
      }},
    {separator:true},
    {label: 'Void', command: () =>{this.bulkTradeOperation('void')}}
  ];
  oldData: any = {};
  requiredArrayList: any[] = [];
  newData: any = {};
  qualitySpecData: any[] = [];
  tradeLoadData: any[] = [];
  bulkProcessList: any[] = [];
  costData: any[] =[];
  paymentTermList: any[];
  filterValue: any;
  scheduleForm: boolean = false;
  bulkOpPaneDisplay: boolean = false;
  bulkProcessingDisplay: boolean = false;
  formRowData: any;
  updatepayloadfunction: Function;
  scheduleRows: any[] = [];
  tradeDateTime: Date = new Date();
  loadingDialogDisplay: any;
  display: boolean = false;
  auditdata: any [] = [];
  statusFunction: Function;
  dbResponse: any[] = [];
  overlayPanelTemp: OverlayPanel;
  hovertradeId: any;
  quantityRoundOff:number;
  priceRoundOff:number;
  generateDocumentRowData: any={};
  checkBoxEnable:boolean = false;
  allowFormatEnable:boolean = false;
  clauseRowDetails: any[];
  rowselectClauseDesc:any[] = [];
  clauseMasterDetails:any[] = [];
  searchCriteriaList: SearchCriteria[] = [];
  selectedClauseValue=[];
  rowDataClause:any[]=[];
  clausecheckData: any[] = [];
  clauseJsonCopy: any[] = [];
  filterTradeList: any[] = [];
  tradeFilters: any;


  @ViewChild('tradeForm', {static: true}) tradeForm: TradeComponent;
  hiddenCols: any[] = ['isInternalTrade', 'company', 'manualTradeId',
    'internalProfitCenter', 'broker','brokerReference','externalReference', 'brand', 'grade', 'season', 'quantityTermsClause', 'locationType', 'location',
    'modeOfTransport', 'loadLocationType', 'loadLocation', 'unloadLocationType', 'dischargeLocation', 'packageType',
    'weightBasis', 'quantity', '', '', 'internalPackage',
    'internalPackageUnit', 'deliverySchedule', 'totalUnits', 'capacity', 'deliveryTermsClause',
    'quantityToleranceType', 'toleranceValue', 'futureIndex', 'tradePriceCurrency', 'tradePriceUom', 'quotationPeriodClause', 'pricingTermsClause',
    'provisionalPricing', 'provisionalPriceType', 'percentage', 'provisionalPrice', 'provisionalPriceCurrency',
    'provisionalPriceUom', 'tradeSettlementCurrency','settlementTolerancePercentage', 'fxrate', 'provisionalFxRate',
    'paymentterm', 'paymentTermsClause', 'specialInstructions', 'comments'];



  constructor(private masterCommonService: MasterService,public commonService: CommonService, private messageService: MessageService, private route: ActivatedRoute, private accessSerivce: AccessPolicyService) {
  }


  canFinanceApproval() {
    return this.accessSerivce.canAccessWithCurrentRoute('Action','financeApproval')
  }

  canFinanceApprovalWithException() {
    return this.accessSerivce.canAccessWithCurrentRoute('Action','financeApprovalWithException')
  }

  private performAction(tradeId:string,action:string = '',reason:any = '') {
    let _this = this;
    this.refreshGrid = false;
    let object = {
      tenantId: this.commonService.getFromStorage('tenantId'),
      tradeId:tradeId,
      userName:this.commonService.getFromStorage('userName'),
      reason:reason,
      canFinanceApprove:(this.canFinanceApproval() || this.canFinanceApprovalWithException())
    };
    this.commonService.post(environment.base_url+'/api/trade/v1/physicaltradeapprovals?tenantId='
        +this.commonService.getFromStorage('tenantId')+'&action='+action,object).subscribe((next:any) => {
      _this.refreshGrid = true;
      _this.showToast(next['status']);
    }, (error: HttpErrorResponse) => {
      let errMsg = _this.commonService.getHttpErrorMessage(error,'Trade');
      if (errMsg.includes('Failed to send Notification')){
        _this.refreshGrid = true;
        _this.showToast(errMsg, 'warn');
      }else {
        _this.showToast(errMsg, 'error');
      }
    });
  }

  loadStatusApi() {
    let _this = this;
    _this.statusFunction = function(value) {
      let iconName = value['iconName'];
      let obj = {};
      if (iconName !== 'Confirmed' || iconName !== 'Awaiting Approval') {
        obj['type'] = 'GET';
        obj['url'] = environment.base_url_new + `/approval-workflow/api/workflow-process/get-workflow-process/${_this.commonService.getEncodedURI(value.rowData.tradeId)}`;
        obj['payload'] = {};
      }
      return obj;
    };
  }


  private generateAdvancedInvoiceForm(value:any,proForma:boolean = false) {
    let _this = this;
    this.invoiceProFormaObject = {};
    this.displayInvoiceForm = true;
    this.loadingInvoice = true;
    let payload = {
      plannedObligationIds: [],
      tradeUuid: value['uuid'],
      advance : !proForma,
      proforma : proForma,
      costOnly : false,
      chargesOnly: false,
      attachCost : false
    };
    this.commonService.post(`${environment.base_url}/api/invoice/v1/getdraftinvoicepayload` + '&outputFormat='+ this.documentFormat,payload).subscribe((next: any) => {
      _this.invoiceProFormaObject = next;
      _this.loadingInvoice = false;
      _this.isPurchaseInvoice = value['tradeTransactionType'] === 'BUY';
    },(error:HttpErrorResponse) => {
          _this.loadingInvoice = false;
          _this.displayInvoiceForm = false;
          _this.showToast(_this.commonService.getHttpErrorMessage(error,"Trade"),'error');
    });

  }

  ngOnInit() {
    let _this = this;
    this.commonService.getLoadData('commodity', ['config', 'config', 'config', 'config', 'config', 'config', 'data'], ['location', [{key: 'code', value: 'counterparty'}],'profitcenter', 'commodity', 'incoterm', 'unitofmeasurement', [{
      key: 'name',
      value: 'price_type_ind'
    }]]).subscribe((next: any) => {
      _this.tradeLoadData = next;
    });
    this.getListForClause();

    let tenantConfig=JSON.parse(this.commonService.getFromStorage("tenantConfig"));
    if(tenantConfig && tenantConfig.roundingFormat){
      _this.quantityRoundOff=tenantConfig.roundingFormat.quantityRounding;
      _this.priceRoundOff=tenantConfig.roundingFormat.priceRounding;
    }
    else{
      _this.quantityRoundOff=3;
      _this.priceRoundOff=2;
    }
    this.getColumns();
    if(window.location.hash === "#/paperTrade"){
      this.grid = new Grid(this.columns, true, true, true, true, 'tradeId', '/api/trade/v1/getallphysicaltrade?tenantId=' + this.commonService.getFromStorage('tenantId')+"&tradeType=Paper", '', 'Paper Trade', undefined, undefined);
    }else if(window.location.hash === "#/interCompanyTrade"){
      this.grid = new Grid(this.columns, true, true, true, true, 'tradeId', '/api/trade/v1/getallphysicaltrade?tenantId=' + this.commonService.getFromStorage('tenantId')+"&tradeType=InterCompany", '', 'Inter Company Trade', undefined, undefined);
    }
    else{
      this.grid = new Grid(this.columns, true, true, true, true, 'tradeId', '/api/trade/v1/getallphysicaltrade?tenantId=' + this.commonService.getFromStorage('tenantId')+"&tradeType=Physical", '', 'Trade', undefined, undefined);
    }
    this.grid.setSaveUrl(environment.base_url + '/api/trade/v1/createphysicaltrade');
    this.grid.setUpdateUrl(environment.base_url + '/api/trade/v1/updatephysicaltrade');
    this.grid.hrefUrl = environment.base_url + '/api/trade/v1/getphysicaltradebyuuid?tenantId=' + this.commonService.getTenantId() + '&uuid=';
    this.grid.importUrl = environment.base_url + '/api/trade/v1/import?tenantId=' + this.commonService.getFromStorage('tenantId');
    this.grid.urlIdField = 'uuid';
    this.grid.setTabs(this.tabs);
    this.grid.onEditViewThrowUnique = true;
    this.grid.iconName = 'physicalTrade';
    this.grid.auditEntityName = 'PhysicalTrade';
    this.grid.isHaveExtraButtons = true;
    this.grid.hiddenFieldsToShowInStandardFilters = ['company', 'manualTradeId'];
    this.grid.gridAdvacedFilterURL = '/api/trade/v1/getphysicaltradebycriteria';
    this.grid.setGridConfiguration(true, true, true, false, true, true, true,
        true, true, true, false);
    this.grid.addSection('Trade Counterparty and Company Details', ['tradeId', 'tradeStatus', 'manualTradeId', 'tradeDateTime', 'isInternalTrade',
      'tradeTransactionType', 'company','counterpartyCode', 'profitcenter', 'internalProfitCenter', 'traderName',
      'createdBy', 'broker', 'brokerReference','externalReference']);
    this.grid.addSection('Trade Commodity Origin and Specification Details', ['commodity', 'origin', '', 'grade', 'brand',
      'season', 'qualityTermsClause']);
    this.grid.addSection('Quantity and Delivery Details', ['incoterm', 'locationType', 'location', 'modeOfTransport',
      'loadLocationType', 'loadLocation', 'unloadLocationType', 'dischargeLocation', 'packageType', 'weightBasis',
      'quantityUom', 'quantity', 'internalPackage', 'internalPackageUnit', '', '', '', '',
      'quantityPeriodicity', 'periodStartDate', 'periodEndDate', 'deliverySchedule', 'totalUnits', 'totalTradeQty', 'capacity', 'deliveryTermsClause', 'quantityTermsClause']);
    this.grid.addSection('Trade Tolerance Details', ['quantityToleranceType', 'toleranceValue']);
    this.grid.addSection('Trade Price Details', ['priceType', 'futureIndex', '', 'tradePriceCurrency', 'tradePrice',
      'tradePriceUom', 'quotationPeriodClause', 'pricingTermsClause']);
    this.grid.addSection('Trade Provisional Price Details', ['provisionalPricing', 'provisionalPriceType', 'percentage',
      'provisionalPrice', 'provisionalPriceCurrency', 'provisionalPriceUom']);
    this.grid.addSection('Trade FX & Settlement Details', ['tradeSettlementCurrency','settlementTolerancePercentage', 'fxrate', 'provisionalFxRate',
      'paymentterm', 'paymentTermsClause']);
    this.grid.addSection('Others', ['specialInstructions', 'comments',]);
    this.grid.fieldRequiresContinuesChangeDetection = ['', 'internalPackageUnit', 'quantity'];


    this.grid.formUpdateButtonDisableFuncton = function(value: any) {
      return value['tradeStatus'] !== 'Draft';

    };
    this.grid.showExtraButtons = false;
    this.grid.contextMenuConfig.functionFile = tradeApprovalFunctions;
    this.grid.contextMenuConfig.triggerEvent = 'TradeApproval';
    this.grid.contextMenuConfig.nameFieldId = 'tradeId';
    this.grid.contextMenuConfig.referenceIdField = 'tradeId';
    this.grid.contextMenuConfig.screenName = 'Physical Trade';


    this.grid.actionFunc.preSaveFunc = function(value) {
      let rowData = value[0];
      let oldValue = value[1];
      let mode = value[2];
      _this.hiddenCols.forEach(col => {
        rowData[col] = oldValue[col];
      });
      if (rowData['commodity'] !== oldValue['commodity']) {
        rowData['brand'] = '';
        rowData['grade'] = '';
        rowData['season'] = '';
      }
      if (rowData['quantity'] !== oldValue['quantity']
          || rowData['totalTradeQty'] === 0
          || _this.commonService.getFormattedDate(rowData['periodStartDate'], environment.dateFormat) !== _this.commonService.getFormattedDate(oldValue['periodStartDate'], environment.dateFormat)
          || _this.commonService.getFormattedDate(rowData['periodEndDate'], environment.dateFormat) !== _this.commonService.getFormattedDate(oldValue['periodEndDate'], environment.dateFormat)) {
        rowData['totalTradeQty'] = _this.commonService.calculateTotalContractQuantity(rowData['quantity'], rowData['quantityPeriodicity'], rowData['periodStartDate'], rowData['periodEndDate']);
      }
      if (rowData['priceType'] !== oldValue['priceType']){
        rowData['tradePriceCurrency'] = '';
        rowData['tradePriceUom'] = '';
        rowData['futureIndex'] = '';
      }
      rowData['deliverySchedule'] = _this.generatePreviewObligation(rowData);
      rowData['periodEndDate'] = rowData['deliverySchedule'][rowData['deliverySchedule'].length-1]['periodEndDate'];
      if(mode === 'copy') {
        rowData['manualTradeId'] = '';
      }
      return rowData;
    };

    this.grid.actionFunc.canEditFunction = function (value) {
      return !(value !== undefined && value !== null && value['tradeStatus'] !== 'Draft');
    };
    this.grid.actionFunc.newCopyValueFunc = function (value) {
      if (value !== undefined && value !== null) {
        value['tradeId'] = '';
        value['tradeStatus'] = 'Draft';
        value['tradeTransactionType'] = '';
        value['contractConfirmation'] = '';
        value['longContract'] = '';
      }
      return value;
    };

    this.grid.actionFunc.preImportFunction = function (value:any[]) {
        value.forEach(row => {
          row['isInternalTrade'] = false;
          row['deliverySchedule'] = _this.generatePreviewObligation(row);
        });
        return value;
    }
    this.grid.ignoreFieldListForExport = ['tradeId', 'tradeStatus'];
    this.requiredArrayList = ['company','counterpartyCode', 'profitcenter', 'commodity', 'incoterm',
      'quantity', 'quantityUom', 'quantityPeriodicity', 'periodStartDate', 'periodEndDate', 'priceType', 'tradePrice', 'tradeSettlementCurrency', 'paymentterm'];
    this.applyNotifFilter();


    //Function to open the Delivery Schedule Form
    this.updatepayloadfunction =  function(value) {
      let data = value['data'];
      let oldData = value['originalData'];
      data['packageType'] = oldData['packageType'];
      if(_this.scheduleRows === null || _this.scheduleRows === undefined || _this.scheduleRows.length === 0) {
        data['deliverySchedule'] = _this.generatePreviewObligation(data);
      } else {
        data['deliverySchedule'] = _this.scheduleRows;
      }
      data['periodEndDate'] = data['deliverySchedule'][data['deliverySchedule'].length-1]['periodEndDate'];
      if(data['quantityPeriodicity'] === 'Yearly'){
        data['periodStartDate'] = data['deliverySchedule'][0]['periodStartDate'];
      }
      // data['paperTrade'] = window.location.hash == "#/paperTrade";
      data['tradeType']=window.location.hash == "#/paperTrade"?'Paper':(window.location.hash == "#/interCompanyTrade"?'InterCompany':'Physical');
      return data;
    }

    this.auditdata.push({header: 'Profit Center',key:'profitcenter'});
    this.auditdata.push({header: 'Commodity',key:'commodity'});
    this.auditdata.push({header: 'Price Type',key:'priceType'});
    this.auditdata.push({header:'Counterparty Code',key:'counterpartCode'})
    this.auditdata.push({header: 'Trade Type',key:'tradeTransactionType'});
    this.auditdata.push({header: 'Pricing',key:'tradePrice'});
    this.auditdata.push({header: 'Trader Name',key:'traderName'});
    this.auditdata.push({header: 'Incoterm',key:'incoterm'});
    this.loadStatusApi();
    this.clauseRowDetails = [
      {field: 'clauseCode', header: 'Clause Name'},
      {field: 'clausePrintSequence', header: 'Document Seq.#'},
      {field: 'clauseDescription', header: 'Clause Description'},
    ];
    // this.getFilterValues();
  }
  generatePreviewObligation(data){
    let _this = this;
    let rows:any[] = [];
    let total = this.commonService.calculateTotalContractQuantity(1,data['quantityPeriodicity'],data['periodStartDate'],data['periodEndDate']);
    let quantity = (data['totalTradeQty'] / total);
    for(let i=0;i < total;i++){
      if(i === 0) {
        rows.push(_this.commonService.getObligationObject(_this.commonService.calculateDeliveryStartDate(data['periodStartDate'],data['quantityPeriodicity']),quantity,data));
      } else {
        rows.push(_this.commonService.getObligationObject(_this.commonService.addDaysInDate(new Date(rows[i-1]['periodEndDate']),1),quantity,data));
      }
    }
    return rows;
  }

  getDateFormat(value: any, obj: any) {
    value = value.toLowerCase().replace(new RegExp(' ', 'g'), '');
    obj['meta'] = {disabled: false};
    if (value === 'monthly' || value === 'quarterly' || value === 'halfyearly') {
      obj['date'] = {view: 'month', format: environment.dateMonthFormat};
    } else if (value === 'yearly') {
      obj['date'] = {view: 'year', format: environment.dateYearFormat};
    } else {
      obj['date'] = {view: 'day'};
    }
    return obj;
  }

  getColumns() {
    let _this = this;
    // to fetch the data in commoditydropdown when it is selected as internaltrade as well as external trade
    let postFunction = function (value: any[] = []) {
      let obj = {};
      if (value !== undefined && value.length > 0 && value[0] !== undefined && value[0].length > 0 && value[1] !== undefined && value[1].length > 0 && value[2] !== undefined && value[2].length > 0) {
        if(value[3] !== undefined && value[3] !== null && value[3] !== '') {
          obj = {
            userId: value[0],
            profitCenterName: value[1],
            sellProfitCenter: value[3],
            tenantId: _this.commonService.getTenantId()
          };
        } else {

          obj = {
            userId: value[0],
            profitCenterName: value[1],
            counterpartyName: value[2],
            tenantId: _this.commonService.getTenantId()
          };
        }
      }
      return obj;
    };

    let resetValue = (value: any[] = []) => {
      if(_this.changeObject !== null && _this.changeObject !== undefined) {
        let fieldName:string = value[0];
        fieldName = fieldName.replace('Field','');
        _this.changeObject[fieldName] = '';
      }
      return '';
    };

    let defPaymentTermFunc = function (value:any[] =[]) {
      if (value[1]){
        return '';
      }
      if(value[0] !== null && value[0] !== undefined && value[0].length !== 0) {
        return value[0]['approvedPaymentTermName'];
      }
      return '';
    };

    let defPaymentTermListFunc = function (value:any[] =[]) {
      if (value[1]){
        return _this.paymentTermList;
      }
      let list = [];
      if(value[0] !== null && value[0] !== undefined) {
        list = [];
        value[0].forEach(pt => {
          list.push(new KeyValue(pt['approvedPaymentTermName'],pt['approvedPaymentTermName']))
        });
        return list;
      }
      return '';
    };

    let actionCounterpartyCode: Action = new Action();
    actionCounterpartyCode.addFieldToUpdate('paymentterm', undefined, defPaymentTermListFunc, ['DB', 'isInternalTrade'], environment.base_url_new + '/api-bm/api/counterpartypaymentterm/v1/getpaymenttermbycunterpartyname?tenantId='+this.commonService.getFromStorage('tenantId') + '&name=', true, undefined, undefined, undefined);
    actionCounterpartyCode.addFieldToUpdate('paymentterm', undefined, defPaymentTermFunc, ['DB', 'isInternalTrade', 'paymentterm'], environment.base_url_new + '/api-bm/api/counterpartypaymentterm/v1/getdefaultpaymenttermbycounterpartyname?tenantId='+this.commonService.getFromStorage('tenantId') + '&name=', true, 'defaultValue', undefined, undefined);
    


    let originListFunc = function (value: any[]) {
      return _this.commonService.addListOTT(value[0]['CommodityOrigin']);
    };
    let brandListFunc = function (value: any[]) {
      return _this.commonService.addListOTT(value[0]['CommodityBrand']);
    };
    let gradeListFunc = function (value: any[]) {
      return _this.commonService.addListOTT(value[0]['CommodityGrade']);
    };
    let seasonListFunc = function (value: any[]) {
      return _this.commonService.addListOTT(value[0]['CommoditySeason']);
    };
    let packageListFunc = function (value: any[]) {
      let list:any[]  = value[0]['CommodityPackage'];
      return _this.commonService.addListOTT(list);
    };

    let externalPackageListFunc = function (value: any[]) {
      return _this.commonService.addListOTT(value[0]['CommodityExternalPackage']);
    };
    let brandDefaultFunc = function (value: any[]) {
      return _this.commonService.getDefaultValueFromList(value[0]['CommodityBrand']);
    };
    let gradeDefaultFunc = function (value: any[]) {
      return _this.commonService.getDefaultValueFromList(value[0]['CommodityGrade']);
    };
    let originDefaultFunc = function (value: any[]) {
      return _this.commonService.getDefaultValueFromList(value[0]['CommodityOrigin']);
    };
    let seasonDefaultFunc = function (value: any[]) {
      return _this.commonService.getDefaultValueFromList(value[0]['CommoditySeason']);
    };


    let quantityUomFunction = function(value:any[]) {
      if(value[0] !== undefined && value[0] !== null && value[0]['Commodity'] !== undefined && value[0]['Commodity'] !== null) {
        return value[0]['Commodity']['uom'];
      }
      return '';
    };
    let currencyFunction = function(value:any[]) {
      if(value[0] !== undefined && value[0] !== null) {
        return value[0]['currency']
      }
      return '';
    };
    let actionCompany: Action = new Action(environment.base_url+'/api/company/v1/getcompanybyname?tenantId=' +this.commonService.getFromStorage('tenantId')+'&name=');
    actionCompany.setCovertToListRequired(false);
    actionCompany.addFieldToUpdate('tradePriceCurrency', undefined, currencyFunction, ['DB'], undefined, false, 'defaultValue');
    actionCompany.addFieldToUpdate('tradeSettlementCurrency', undefined, currencyFunction, ['DB'], undefined, false, 'defaultValue');

    let actionCommodity: Action = new Action(environment.base_url + '/api/commodity/v1/getcommoditydefaultproperties?tenantId='+this.commonService.getFromStorage('tenantId')+'&commodityName=');
    
    actionCommodity.setCovertToListRequired(false);
    actionCommodity.addFieldToUpdate('origin', undefined, originListFunc, ['DB'], undefined, true, 'listvalues');
    actionCommodity.addFieldToUpdate('brand', undefined, brandListFunc, ['DB'], undefined, true, 'listvalues');
    actionCommodity.addFieldToUpdate('grade', undefined, gradeListFunc, ['DB'], undefined, true, 'listvalues');
    actionCommodity.addFieldToUpdate('season', undefined, seasonListFunc, ['DB'], undefined, true, 'listvalues');
    actionCommodity.addFieldToUpdate('brand', undefined, brandDefaultFunc, ['DB'], undefined, false, 'defaultValue');
    actionCommodity.addFieldToUpdate('grade', undefined, gradeDefaultFunc, ['DB'], undefined, false, 'defaultValue');
    actionCommodity.addFieldToUpdate('origin', undefined, originDefaultFunc, ['DB'], undefined, false, 'defaultValue');
    actionCommodity.addFieldToUpdate('season', undefined, seasonDefaultFunc, ['DB'], undefined, false, 'defaultValue');
    actionCommodity.addFieldToUpdate('quantityUom', undefined, quantityUomFunction, ['DB'], undefined, false, 'defaultValue');
    actionCommodity.addFieldToUpdate('internalPackage',undefined,packageListFunc,['DB'],undefined,true,'listvalues');
    actionCommodity.addFieldToUpdate('externalPackage',undefined,externalPackageListFunc,['DB'],undefined,true,'listvalues');


    let toleranceExtra = function (value: any[] = []) {
          let obj = _this.grid.getColumn().get('quantityToleranceType').getExtra();
          obj['dualSliderOption'] = {
              labelFormatLeft: '- <value> %',
              labelLowFormatLeft: '- <value> %',
              labelHighFormatLeft: '- <value> %',
              labelFormatRight: '<value> %',
              labelLowFormatRight: '<value> %',
              labelHighFormatRight: '<value> %',
              floorLeft: 0,
              floorRight: 0,
              ceilLeft: 100,
              ceilRight: 100
          };
          if (value[0] === 'weight') {
              if (value[2] === undefined || value[2] === null || value[2].length === 0) {
                  value[2] = 0;
              } else {
                  value[2] = parseInt(value[2]);
              }
              obj['dualSliderOption'] = {
                  labelFormatLeft: '- <value> ' + value[1],
                  labelLowFormatLeft: '- <value> ' + value[1],
                  labelHighFormatLeft: '- <value> ' + value[1],
                  labelFormatRight: '<value> ' + value[1],
                  labelLowFormatRight: '<value> ' + value[1],
                  labelHighFormatRight: '<value> ' + value[1],
                  floorLeft: 0,
                  floorRight: 0,
                  ceilLeft: value[2],
                  ceilRight: value[2]
              }
          }
          return obj;
      };

    let priceExtra = (value: any[] = []) => {
      let object = _this.grid.getColumn().get('tradePrice').getExtra();
      object['meta'] = {disabled: true};
      if (value[0].toString().toLowerCase() === 'fixed' || value[0].toString().toLowerCase() === 'basis') {
        object['meta'] = {disabled: false};
      }
      return object;
    };

    let futureExtra = (value: any[] = []) => {
      let obj = {meta: {disabled: false}};
      if (value[0].toString().toLowerCase() === 'fixed') {
        obj = {meta: {disabled: true}};
      }
      return obj;
    };

    let provisionalPricingExtraDefault = (value: any[] = []) => {
      return {meta: {disabled: true}};
    };

    let provisionalPricingExtra = (value: any[] = []) => {
      let object = this.grid.getColumn().get('provisionalPricing').getExtra();
      object['meta'] = {disabled: false};
      if (value[0] !== undefined && value[0] !== null) {
        if (value[0].toString().toLowerCase() === 'fixed') {
          object['meta'] = {disabled: true};
        }
      }
      return object;
    };

    let tradePriceUomListFunction = async (value: any[] = []) => {
      if (value[1].toLowerCase() !== 'fixed') {
        let next: any[] = await _this.commonService.getHttpClient().get(environment.base_url + '/api/futuremarketdashboard/v1/getpriceuombycommodity?tenantId=' + _this.commonService.getTenantId()
            + '&commodity=' + value[0]).toPromise().then((next: any) => {
          return next;
        });
        return next[0]['lotUom'];
      }
      return '';
    };

    let tradePriceCurrencyListFunction = async (value: any[] = []) => {
      if (value[1].toLowerCase() !== 'fixed') {
        let next: any[] = await _this.commonService.getHttpClient().get(environment.base_url + '/api/futuremarketdashboard/v1/getpriceuombycommodity?tenantId=' + _this.commonService.getTenantId()
            + '&commodity=' + value[0]).toPromise().then((next: any) => {
          return next;
        });
        return next[0]['priceQuotation'];
      }
      return '';
    };


    let resetValueForProvisional = (value: any[] = []) => {
      return false;
    };


    let futureListFunction = (value: any[] = []) => {
      _this.commonService.getJSONByURL(environment.base_url + '/api/futuremarketdashboard/v1/gettickerbycommodity?tenantId=' + this.commonService.getTenantId()
          + '&commodity=' + value[0]).subscribe((next: any) => {
        this.grid.getColumn().get('futureIndex').setListofValues(this.masterCommonService.getListFromArray(next));
      });
      return '';
    };

    let actionPriceType = new Action();
    actionPriceType.addFieldToUpdate('tradePrice', undefined, priceExtra, undefined, undefined, true, 'extra');
    actionPriceType.addFieldToUpdate('futureIndex', undefined, futureExtra, undefined, undefined, true, 'extra');

    actionPriceType.addFieldToUpdate('futureIndex', undefined, futureListFunction, ['commodity'], undefined, true, 'listvalues');
    actionPriceType.addFieldToUpdate('futureIndex', undefined, resetValue, ['futureIndexField'], undefined, true, 'defaultValue', false, false, undefined, undefined, undefined, undefined, undefined, undefined, true);
    actionPriceType.addFieldToUpdate('tradePrice', undefined, resetValue, ['tradePriceField'], undefined, true, 'defaultValue', false, false, undefined, undefined, undefined, undefined, undefined, undefined, true);
    actionPriceType.addFieldToUpdate('provisionalPricing', undefined, resetValueForProvisional, ['provisionalPricingField'], undefined, true, 'defaultValue', false, false, undefined, undefined, undefined, undefined, undefined, undefined, true);
    actionPriceType.addFieldToUpdate('provisionalPriceType', undefined, resetValue, ['provisionalPriceTypeField'], undefined, true, 'defaultValue', false, false, undefined, undefined, undefined, undefined, undefined, undefined, true);
    actionPriceType.addFieldToUpdate('provisionalPrice', undefined, resetValue, ['provisionalPriceField'], undefined, true, 'defaultValue', false, false, undefined, undefined, undefined, undefined, undefined, undefined, true);
    actionPriceType.addFieldToUpdate('provisionalPriceCurrency', undefined, resetValue, ['provisionalPriceCurrencyField'], undefined, true, 'defaultValue', false, false, undefined, undefined, undefined, undefined, undefined, undefined, true);
    actionPriceType.addFieldToUpdate('provisionalPriceUom', undefined, resetValue, ['provisionalPriceUomField'], undefined, true, 'defaultValue', false, false, undefined, undefined, undefined, undefined, undefined, undefined, true);

    actionPriceType.addFieldToUpdate('provisionalPricing', undefined, provisionalPricingExtra, undefined, undefined, true, 'extra');
    actionPriceType.addFieldToUpdate('provisionalPriceType', undefined, provisionalPricingExtraDefault, undefined, undefined, true, 'extra');
    actionPriceType.addFieldToUpdate('provisionalPrice', undefined, provisionalPricingExtraDefault, undefined, undefined, true, 'extra');
    actionPriceType.addFieldToUpdate('provisionalPriceCurrency', undefined, provisionalPricingExtraDefault, undefined, undefined, true, 'extra');
    actionPriceType.addFieldToUpdate('provisionalPriceUom', undefined, provisionalPricingExtraDefault, undefined, undefined, true, 'extra');


    let provPriceExtra = (value: any[] = []) => {
      let object = _this.grid.getColumn().get(value[1].toString().replace('Field', '')).getExtra();
      object['meta'] = {disabled: true};
      if (value[0] !== undefined && value[0] !== null) {
        if (value[0].toString().toLowerCase() === 'fixed') {
          object['meta'] = {disabled: false};
        }
      }
      return object;
    };
    let percentageMeta = (value: any[] = []) => {
      let object = _this.grid.getColumn().get('percentage').getExtra();
      object['meta'] = {disabled: true};
      if (value[0] !== undefined && value[0] !== null) {
        if (value[0].toString().toLowerCase() === 'percentage') {
          object['meta'] = {disabled: false};
        }
      }
      return object;
    };

    let provPrceTypeVal = (value: any[] = []) => {
      let validators: Validators[] = [];
      if (value[0].toString().toLowerCase() === 'true') {
        validators.push(Validators.required);
      }
      return validators;
    };
    let provPriceVal = (value: any[] = []) => {
      let validators: Validators[] = [Validators.min(0),ctrmValidators.decimalLengthValidator(10,_this.priceRoundOff)];
      if (value[0].toString().toLowerCase() === 'true') {
        validators.push(Validators.required);
      }
      return validators;
    };

    let provPriceEnableDisableVal = (value: any[] = []) => {
      let obj: any = {meta: {disabled: true}};
      if (value[0] === true) {
        obj = {meta: {disabled: false}};
      }
      return obj;
    };

    let provPricePercentageEnableDisableVal = (value: any[] = []) => {
      return {meta: {disabled: true}};
    };

    let provPriceDisableVal = function(value:any[]) {
      if(value !== undefined && value !== null ) {
        if(value[1] === true) {
          return 'Fixed'
        } else {
          return '';
        }
      }
    };

    let provFXRateMetaUpdate = function(value: any[] = []) {
      let validators: Validators[] = [];
      if (value[2] !== undefined && value[2] !== null && value[2]) {
        if (value[0] !== undefined && value[1] !== undefined && value[0] !== null && value[1] !== null && value[0].length > 0 && value[1].length > 0) {
          if (value[0] !== value[1]) {
            validators.push(Validators.required);
          }
        }
      }
      return validators;
    };


    let provFXRateObjectUpdate = function (value: any[] = []) {
      if(!value[2]) {
        return {meta: {disabled: true}};
      } else {
        let obj = {meta: {disabled: false}};
        if (value[0] !== undefined && value[1] !== undefined && value[0] !== null && value[1] !== null && value[0].length > 0 && value[1].length > 0) {
          if (value[0] === value[1]) {
            obj = {meta: {disabled: true}};
          }
        }
        return obj;
      }
    };

    let actionProvisionalPricing = new Action();
    actionProvisionalPricing.addFieldToUpdate('provisionalPriceType', undefined, provPrceTypeVal, undefined, undefined, true, 'validators');
    actionProvisionalPricing.addFieldToUpdate('provisionalPrice', undefined, provPriceVal, undefined, undefined, true, 'validators');
    actionProvisionalPricing.addFieldToUpdate('provisionalPriceCurrency', undefined, provPrceTypeVal, undefined, undefined, true, 'validators');
    actionProvisionalPricing.addFieldToUpdate('provisionalPriceUom', undefined, provPrceTypeVal, undefined, undefined, true, 'validators');
    actionProvisionalPricing.addFieldToUpdate('percentage', undefined, provPrceTypeVal, undefined, undefined, true, 'validators');
    actionProvisionalPricing.addFieldToUpdate('provisionalPriceType', undefined, provPriceEnableDisableVal, undefined, undefined, true, 'extra');
    actionProvisionalPricing.addFieldToUpdate('provisionalPrice', undefined, provPricePercentageEnableDisableVal, undefined, undefined, true, 'extra');
    actionProvisionalPricing.addFieldToUpdate('provisionalPriceCurrency', undefined, provPricePercentageEnableDisableVal, undefined, undefined, true, 'extra');
    actionProvisionalPricing.addFieldToUpdate('provisionalPriceUom', undefined, provPricePercentageEnableDisableVal, undefined, undefined, true, 'extra');
    actionProvisionalPricing.addFieldToUpdate('percentage', undefined, provPricePercentageEnableDisableVal, undefined, undefined, true, 'extra');
    actionProvisionalPricing.addFieldToUpdate('provisionalPriceType', undefined, provPriceDisableVal, ['DB','provisionalPricing',''], undefined, true, 'defaultValue');
    actionProvisionalPricing.addFieldToUpdate('provisionalFxRate', undefined, provFXRateMetaUpdate, ['provisionalPriceCurrency', 'tradeSettlementCurrency', 'provisionalPricing'], undefined, true, 'validators');
    actionProvisionalPricing.addFieldToUpdate('provisionalFxRate', undefined, provFXRateObjectUpdate, ['provisionalPriceCurrency', 'tradeSettlementCurrency', 'provisionalPricing'], undefined, true, 'extra');

    let actionProvPriceType = new Action();
    actionProvPriceType.addFieldToUpdate('provisionalPrice', undefined, provPriceExtra, ['provisionalPriceType', 'provisionalPriceField'], undefined, true, 'extra');
    actionProvPriceType.addFieldToUpdate('provisionalPriceCurrency', undefined, provPriceExtra, ['provisionalPriceType', 'provisionalPriceCurrencyField'], undefined, true, 'extra');
    actionProvPriceType.addFieldToUpdate('provisionalPriceUom', undefined, provPriceExtra, ['provisionalPriceType', 'provisionalPriceUomField'], undefined, true, 'extra');
    actionProvPriceType.addFieldToUpdate('percentage', undefined, percentageMeta, undefined, undefined, true, 'extra');

    //TotalUnits Calculation for External Package
    let totalUnitsFunction = function(value: any[] = []) {
      if (value[3] !== undefined && value[3] !== null && value[3] !== '') {
        if (value[5] === 'Bulk') {
          return _this.commonService.calculateTotalContractQuantity(1, value[1], value[2], value[3]);
        }
        if (value[0] !== null && value[0] !== undefined && value[0] > 0) {
          return (value[0] * _this.commonService.calculateTotalContractQuantity(1, value[1], value[2], value[3]));
        } else {
          return (value[4] * _this.commonService.calculateTotalContractQuantity(1, value[1], value[2], value[3]));
        }
      }
      return  0;
    }

    let updateQuantity = function(value:any[]){
      let originalValues = value[8];
      if(originalValues !== null && originalValues !== undefined) {
        let endDate = new Date(originalValues['periodEndDate']);
        let newDate = new Date(value[3]);
        if(_this.commonService.moment(newDate).isSame(endDate)) {
          let sum = 0;
          let deliveryList:any[] = originalValues['deliverySchedule'];
          deliveryList.forEach(item => {
            sum += parseFloat(item.quantity);
          })
          return sum;
        }
      }
      let totalQuantity:any;
      if (value[3] !== undefined && value[3] !== null && value[3] !== ''){
        if (value[7] === 'Bulk'){
          totalQuantity =  _this.commonService.calculateTotalContractQuantity(value[5], value[1], value[2], value[3]);
        } else if (value[7].toLowerCase() === 'unit'){
          totalQuantity = value[6] * _this.commonService.calculateTotalContractQuantity(value[4], value[1], value[2], value[3]);
        } else{
          totalQuantity = value[6] * _this.commonService.calculateTotalContractQuantity(value[0], value[1], value[2], value[3]);
        }
        return parseFloat(parseFloat(totalQuantity).toFixed(4));
      }

      return 0;
    };



    let resetValueDate = (value: any[] = []) => {
      let fieldName:string = value[1];
      fieldName = fieldName.replace('Field','');
      if(_this.changeObject !== null && _this.changeObject !== undefined && _this.changeObject[fieldName] === value[0]) {
        if(_this.changeObject[fieldName] === value[0]) {
          _this.changeObject[fieldName] = value[0];
          return new Date(_this.changeObject['periodStartDate']);
        }
        _this.changeObject[fieldName] = value[0];
      }
      return '';
    };

    let resetValueEndDate = (value: any[] = []) => {
      let fieldName:string = value[1];
      fieldName = fieldName.replace('Field','');
      if(_this.changeObject !== null && _this.changeObject !== undefined && _this.changeObject[fieldName] === value[0]) {
        if(_this.changeObject[fieldName] === value[0]) {
          _this.changeObject[fieldName] = value[0];
          return new Date(_this.changeObject['periodEndDate']);
        }
        _this.changeObject[fieldName] = value[0];
      }
      return '';
    };

    let updateStartDateFormatting = function (value: any[] = []) {
      let obj = _this.grid.getColumn().get('periodStartDate').getExtra();
      return _this.getDateFormat(value[0], obj);
    };
    let updateEndDateFormatting = function (value: any[] = []) {
      let obj = _this.grid.getColumn().get('periodEndDate').getExtra();
      return _this.getDateFormat(value[0], obj);
    };
    let formatOutput = function (value: any[] = []) {
      let currentData = JSON.parse(value[3]);
      let output = '';
      if (currentData !== undefined && currentData !== null) {
        if (value[0] === 'weight') {
          return '- ' + currentData['min'] + ' ' + value[2] + ' <b>-</b> ' + currentData['max'] + ' ' + value[2];
        }
        output = '- ' + currentData['min'] + ' % - +' + currentData['max'] + ' %';
      }
      return output;
    };

    let endFunction = function(value: any[]) {
      return _this.commonService.calculateDeliveryEndDate(value[0], value[1]);
    };

    let addQualityUom = (value: any[] = []) => {
      return ('Total Quantity ( ' + value + ' )');
    };

    let actionQuantityUOM = new Action();
    actionQuantityUOM.addFieldToUpdate('toleranceValue', undefined, toleranceExtra, ['quantityToleranceType', 'quantityUom', 'quantity'], undefined, true, 'extra');
    actionQuantityUOM.addFieldToUpdate('totalTradeQty', undefined, addQualityUom, undefined, undefined, false, 'header');
    actionQuantityUOM.addFieldToUpdate('periodStartDate', undefined, resetValueDate, ['quantityUom', 'quantityUomField'], undefined, true, 'defaultValue');
    actionQuantityUOM.addFieldToUpdate('periodEndDate', undefined, resetValueEndDate, ['quantityUom', 'quantityUomField'], undefined, true, 'defaultValue');

    let actionQuantityPeriodicity = new Action();
    actionQuantityPeriodicity.addFieldToUpdate('periodStartDate', undefined, updateStartDateFormatting, undefined, undefined, true, 'extra');
    actionQuantityPeriodicity.addFieldToUpdate('periodEndDate', undefined, updateEndDateFormatting, undefined, undefined, true, 'extra');
    actionQuantityPeriodicity.addFieldToUpdate('deliveryEndDate', undefined, endFunction, ['periodEndDate', 'quantityPeriodicity']);
    actionQuantityPeriodicity.addFieldToUpdate('toleranceValue', undefined, toleranceExtra, ['quantityToleranceType', 'quantityUom', 'quantity'], undefined, true, 'extra');
    actionQuantityPeriodicity.addFieldToUpdate('periodStartDate', undefined, resetValueDate, ['quantityPeriodicity', 'quantityPeriodicityField'], undefined, true, 'defaultValue');
    actionQuantityPeriodicity.addFieldToUpdate('periodEndDate', undefined, resetValueEndDate, ['quantityPeriodicity', 'quantityPeriodicityField'], undefined, true, 'defaultValue');

    let actionEndDate = new Action();
    actionEndDate.addFieldToUpdate('deliveryEndDate', undefined, endFunction, ['periodEndDate', 'quantityPeriodicity']);
    actionEndDate.addFieldToUpdate('totalUnits', undefined, totalUnitsFunction, ['externalPackageUnit', 'quantityPeriodicity', 'periodStartDate', 'periodEndDate', 'internalPackageUnit', 'packageType'], undefined, false, 'defaultValue');
    actionEndDate.addFieldToUpdate('totalTradeQty', undefined, updateQuantity, ['externalPackageUnit', 'quantityPeriodicity', 'periodStartDate', 'periodEndDate', 'internalPackageUnit', 'quantity', 'capacity', 'packageType','OV'], undefined, false, 'defaultValue');

    let actionStartPeriod = new Action();
    actionStartPeriod.addFieldToUpdate('periodEndDate', undefined, undefined, undefined, undefined, true, 'minDate');

    let actionQuantity = new Action();
    actionQuantity.setCovertToListRequired(true);
    actionQuantity.addFieldToUpdate('toleranceValue', undefined, toleranceExtra, ['quantityToleranceType', 'quantityUom', 'quantity'], undefined, true, 'extra');
    actionQuantity.addFieldToUpdate('periodStartDate', undefined, resetValueDate, ['quantity', 'quantityField'], undefined, true, 'defaultValue');
    actionQuantity.addFieldToUpdate('periodEndDate', undefined, resetValueEndDate, ['quantity', 'quantityField'], undefined, true, 'defaultValue');

    let actionQuantityToleranceType = new Action();
    actionQuantityToleranceType.addFieldToUpdate('toleranceValue', undefined, toleranceExtra, ['quantityToleranceType', 'quantityUom', 'quantity'], undefined, true, 'extra');


    let FXRateMetaUpdate = function(value: any[] = []) {
      let validators: Validators[] = [];
      if (value[0] !== undefined && value[1] !== undefined && value[0] !== null && value[1] !== null && value[0].length > 0 && value[1].length > 0) {
        if (value[0] !== value[1]) {
          validators.push(Validators.required);
        }
      }
      return validators;
    };

    let FXRateObjectUpdate = function (value: any[] = []) {
      let obj = {meta: {disabled: false}};
      if (value[0] !== undefined && value[1] !== undefined && value[0] !== null && value[1] !== null && value[0].length > 0 && value[1].length > 0) {
        if (value[0] === value[1]) {
          obj = {meta: {disabled: true}};
        }
      }
      return obj;
    };

    let settlementcurrency = function (value: any[] = []) {
      let defaultValue = value[0];
      return defaultValue;
    };

    let fxRateDefaultValueFunction = function (value: any[] = []) {
      let defaultValue = value[2];
      if (value[0] !== undefined && value[1] !== undefined && value[0] !== null && value[1] !== null && value[0].length > 0 && value[1].length > 0) {
        if (value[0] === value[1]) {
          defaultValue = 1;
        }
      }
      return defaultValue;
    };
    let actionPriceCurrency = new Action();
    actionPriceCurrency.addFieldToUpdate('fxrate', undefined, FXRateMetaUpdate, ['tradePriceCurrency', 'tradeSettlementCurrency'], undefined, true, 'validators');
    actionPriceCurrency.addFieldToUpdate('fxrate', undefined, FXRateObjectUpdate, ['tradePriceCurrency', 'tradeSettlementCurrency'], undefined, true, 'extra');
    actionPriceCurrency.addFieldToUpdate('fxrate', undefined, fxRateDefaultValueFunction, ['tradePriceCurrency', 'tradeSettlementCurrency', 'fxrate'], undefined, true, 'defaultValue');
    actionPriceCurrency.addFieldToUpdate('tradeSettlementCurrency', undefined, settlementcurrency, ['tradePriceCurrency'], undefined, true, 'defaultValue');

    let actionProvPriceCurrency = new Action();
    actionProvPriceCurrency.addFieldToUpdate('fxrate', undefined, FXRateMetaUpdate, ['provisionalPriceCurrency', 'tradeSettlementCurrency'], undefined, true, 'validators');
    actionProvPriceCurrency.addFieldToUpdate('provisionalFxRate', undefined, provFXRateMetaUpdate, ['provisionalPriceCurrency', 'tradeSettlementCurrency', 'provisionalPricing'], undefined, true, 'validators');
    actionProvPriceCurrency.addFieldToUpdate('provisionalFxRate', undefined, FXRateObjectUpdate, ['provisionalPriceCurrency', 'tradeSettlementCurrency'], undefined, true, 'extra');
    actionProvPriceCurrency.addFieldToUpdate('provisionalFxRate', undefined, fxRateDefaultValueFunction, ['provisionalPriceCurrency', 'tradeSettlementCurrency', 'provisionalFxRate'], undefined, true, 'defaultValue');


    let actionSettlementCurrency = new Action();
    actionSettlementCurrency.addFieldToUpdate('fxrate', undefined, FXRateMetaUpdate, ['tradePriceCurrency', 'tradeSettlementCurrency'], undefined, true, 'validators');
    actionSettlementCurrency.addFieldToUpdate('fxrate', undefined, FXRateObjectUpdate, ['tradePriceCurrency', 'tradeSettlementCurrency'], undefined, true, 'extra');
    actionSettlementCurrency.addFieldToUpdate('fxrate', undefined, fxRateDefaultValueFunction, ['tradePriceCurrency', 'tradeSettlementCurrency', 'fxrate'], undefined, true, 'defaultValue');
    actionSettlementCurrency.addFieldToUpdate('provisionalFxRate', undefined, provFXRateMetaUpdate, ['provisionalPriceCurrency', 'tradeSettlementCurrency', 'provisionalPricing'], undefined, true, 'validators');
    actionSettlementCurrency.addFieldToUpdate('provisionalFxRate', undefined, provFXRateObjectUpdate, ['provisionalPriceCurrency', 'tradeSettlementCurrency', 'provisionalPricing'], undefined, true, 'extra');
    actionSettlementCurrency.addFieldToUpdate('provisionalFxRate', undefined, fxRateDefaultValueFunction, ['provisionalPriceCurrency', 'tradeSettlementCurrency', 'provisionalFxRate'], undefined, true, 'defaultValue');

    // Internal Trade Action and Functions
    let setHeaderCP = (value: any[] = []) => {
      let header = 'Counter Party';
      if (value[0]) {
        header = 'Sell Company'
      }
      return header;
    };

    let setHeaderCompany = (value: any[] = []) => {
      let header = 'Company';
      if (value[0]) {
        header = 'Buy Company'
      }
      return header;
    };
    let setHeaderPC = (value: any[] = []) => {
      let header = 'Profit Center';
      if (value[0]) {
        header = 'Buy Profit Center'
      }
      return header;
    };

    let setBuySellDefault = (value: any[] = []) => {
      let defVal = 'BUY';
      if (value[0]) {
        defVal = 'SELL';
      }
      return defVal;
    };

    let setBuySellExtra = (value: any[] = []) => {
      let object = _this.grid.getColumn().get('tradeTransactionType').getExtra();
      object['meta'] = {disabled: false};

      if (value[0]) {
        object['meta'] = {disabled: true};
      }
      return object;
    };

    let internalProfitCenterExtra = (value: any[] = []) => {
      let object = _this.grid.getColumn().get('internalProfitCenter').getExtra();
      if (value[0]) {
        object['meta'] = {disabled: false};
      } else {
        object['meta'] = {disabled: true};
      }
      return object;
    };

    let setIntTradeListCPCode = (value: any[] = []) => {
      let list: KeyValue[] = _this.listCounterpartyCode;
      if (value[0]) {
        list = _this.listCompany;
      }
      return list;
    };

    let setPaymentTermList = () => {
      let list: any[];
      list = _this.paymentTermList;
      return list;
    };


    let priceTypeFixed = (value: any [] = []) => {
      if (value[0].toString().toLowerCase() === 'internal') {
        return 'Fixed';
      } else {
        return value[1];
      }
    };

    let setDefaultCP = () => {
      return '';
    };

    let priceTypeDisable = (value: any [] = []) => {
      let object = _this.grid.getColumn().get('priceType').getExtra();
      if (value[0].toString().toLowerCase() === 'internal') {
        object['meta'] = {disabled: true};
      } else {
        object['meta'] = {disabled: false};
      }
      return object;
    };

    let actionInternalTrade = new Action();
    actionInternalTrade.addFieldToUpdate('counterpartyCode', undefined, setHeaderCP, undefined, undefined, false, 'header');
    actionInternalTrade.addFieldToUpdate('counterpartyCode', undefined, setDefaultCP, undefined, undefined, false, 'defaultValue');
    actionInternalTrade.addFieldToUpdate('counterpartyCode', undefined, setIntTradeListCPCode, undefined, undefined, false, 'listvalues');
    actionInternalTrade.addFieldToUpdate('paymentterm', undefined, setPaymentTermList, undefined, undefined, false, 'listvalues');
    actionInternalTrade.addFieldToUpdate('company', undefined, setHeaderCompany, undefined, undefined, false, 'header');
    actionInternalTrade.addFieldToUpdate('profitcenter', undefined, setHeaderPC, undefined, undefined, false, 'header');
    actionInternalTrade.addFieldToUpdate('tradeTransactionType', undefined, setBuySellDefault, ['isInternalTrade','tradeTransactionType'], undefined, false, 'defaultValue');
    actionInternalTrade.addFieldToUpdate('tradeTransactionType', undefined, setBuySellExtra, undefined, undefined, false, 'extra');
    actionInternalTrade.addFieldToUpdate('internalProfitCenter', undefined, internalProfitCenterExtra, undefined, undefined, false, 'extra');
    actionInternalTrade.addFieldToUpdate('priceType', undefined, priceTypeFixed, ['isInternalTrade','priceType'], undefined, false, 'defaultValue');
    actionInternalTrade.addFieldToUpdate('priceType', undefined, priceTypeDisable, undefined, undefined, false, 'extra');

    let quantityDisable = (value: any[] = []) => {
      let obj: any = {meta: {disabled: false}};
      if(value[0] !== undefined && value[0] !== null) {
        if (value[0] === 'Container' || value[0] === 'Unit') {
          obj = {meta: {disabled: true}};
        }
      }
      return obj;
    };

    let internalpackageDisable = (value: any[] = []) => {
      let obj: any = {meta: {disabled: false}};
      if(value[0] !== undefined && value[0] !== null) {
        if (value[0] === 'Bulk' || value[0] === 'Container') {
          obj = {meta: {disabled: true}};
        }
      }
      return obj;
    };

    let internalPackageValidator = function(value: any[] = []) {
      let validators: Validators[] = [];
      if (value[0] !== undefined && value[0] !== null && value[0].length > 0) {
        if (value[0] === 'Unit') {
          validators.push(Validators.required);
        }
      }
      return validators;
    };

    let internalPackageUnitValidator = function(value: any[] = []) {
      let validators: Validators[] = [];
      if (value[0] !== undefined && value[0] !== null && value[0].length > 0) {
        if (value[0] === 'Unit') {
          validators.push(Validators.required,Validators.min(1));
        }
      }
      return validators;
    };

    let actionpackageType = new Action();
    actionpackageType.addFieldToUpdate('internalPackage', undefined, internalpackageDisable, undefined, undefined, true, 'extra');
    actionpackageType.addFieldToUpdate('internalPackageUnit', undefined, internalpackageDisable, undefined, undefined, true, 'extra');
    actionpackageType.addFieldToUpdate('internalPackage', undefined, internalPackageValidator, ['packageType'], undefined, true, 'validators');
    actionpackageType.addFieldToUpdate('internalPackageUnit', undefined, internalPackageUnitValidator, ['packageType'], undefined, true, 'validators');
    actionpackageType.addFieldToUpdate('quantity', undefined, quantityDisable, undefined, undefined, true, 'extra');
    //Reset All Values when change Package Type
    actionpackageType.addFieldToUpdate('quantity', undefined, resetValue, ['quantityField'], undefined, true, 'defaultValue');
    actionpackageType.addFieldToUpdate('internalPackage', undefined, resetValue, ['internalPackageField'], undefined, true, 'defaultValue');
    actionpackageType.addFieldToUpdate('internalPackageUnit', undefined, resetValue, ['internalPackageUnitField'], undefined, true, 'defaultValue');
    actionpackageType.addFieldToUpdate('periodStartDate', undefined, resetValueDate, ['packageType','packageTypeField'], undefined, true, 'defaultValue');
    actionpackageType.addFieldToUpdate('periodEndDate', undefined, resetValueEndDate, ['packageType','packageTypeField'], undefined, true, 'defaultValue');
    actionpackageType.addFieldToUpdate('totalUnits', undefined, resetValue, ['totalUnitsField'], undefined, true, 'defaultValue');
    actionpackageType.addFieldToUpdate('totalTradeQty', undefined, resetValue, ['totalTradeQtyField'], undefined, true, 'defaultValue');
    actionpackageType.addFieldToUpdate('deliverySchedule', undefined, resetValue, ['deliveryScheduleField'], undefined, true, 'defaultValue');

    let postExternalCalculation = function(value:any){
      let payload = null;
      let canSend = false;
      if(value !== undefined && value !== null &&
          value['commodity'] !== null && value['commodity'] !== undefined && value['commodity'].length > 0 &&
          value['quantityUom'] !== null && value['quantityUom'] !== undefined && value['quantityUom'].length > 0 ) {
        if (value['packageType'] === 'Container' && value['externalPackage'] !== null && value['externalPackage'] !== undefined && value['externalPackage'].length > 0) {
          if (value['externalPackageUnit'] !== undefined && value['externalPackageUnit'] !== null && value['externalPackageUnit'] !== 0) {
            canSend = true;
          }
        }
      }
      if(canSend) {
        payload = {};
        payload['packageType'] = value['packageType']
        payload['externalPackageUnit']= value['externalPackageUnit'];
        payload['commodity']= value['commodity'];
        payload['calculateFrom'] = 'externalpackage';
        payload['quantityUom']= value['quantityUom'];
        payload['externalPackageName']= value['externalPackage'];
      }
      return payload;
    };

    let postInternalCalculation = function(value:any){
      let payload = null;
      let canSend = false;
      if(value !== undefined && value !== null &&
          value['commodity'] !== null && value['commodity'] !== undefined && value['commodity'].length > 0 &&
          value['quantityUom'] !== null && value['quantityUom'] !== undefined && value['quantityUom'].length > 0 ) {
        canSend = true;
      }
      if(canSend) {
        payload = {};
        payload['packageType'] = value['packageType']
        payload['internalPackageUnit']= value['internalPackageUnit'];
        payload['commodity']= value['commodity'];
        payload['calculateFrom'] = 'internalpackage';
        payload['quantityUom']= value['quantityUom'];
        payload['internalPackageName']= value['internalPackage'];
      }
      return payload;
    };

    let quantityexample = function (value: any[]) {
      return value[0]['capacity'];
    };

    //Action For Internal Package Unit
    let actionInternalpackageUnit = new Action(environment.base_url + '/api/externalpackage/v1/quantityandunitconversion?tenantId='+this.commonService.getFromStorage('tenantId'),undefined,undefined,[],undefined,undefined,postInternalCalculation,false);
    actionInternalpackageUnit.setCovertToListRequired(false);
    actionInternalpackageUnit.addFieldToUpdate('capacity',undefined, quantityexample, ['DB'], undefined, false,'defaultValue');
    actionInternalpackageUnit.addFieldToUpdate('periodStartDate', undefined, resetValueDate, ['internalPackageUnit','internalPackageUnitField'], undefined, true, 'defaultValue');
    actionInternalpackageUnit.addFieldToUpdate('periodEndDate', undefined, resetValueEndDate, ['internalPackageUnit','internalPackageUnitField'], undefined, true, 'defaultValue');

    //Action For External Package Unit
    let actionExternalpackageUnit = new Action(environment.base_url + '/api/externalpackage/v1/quantityandunitconversion?tenantId='+this.commonService.getFromStorage('tenantId'),undefined,undefined,[],undefined,undefined,postExternalCalculation,false);
    actionExternalpackageUnit.setCovertToListRequired(false);
    actionExternalpackageUnit.addFieldToUpdate('capacity',undefined, quantityexample, ['DB'], undefined, false,'defaultValue');
    actionExternalpackageUnit.addFieldToUpdate('periodStartDate', undefined, resetValueDate, ['externalPackageUnit','externalPackageUnitField'], undefined, true, 'defaultValue');
    actionExternalpackageUnit.addFieldToUpdate('periodEndDate', undefined, resetValueEndDate, ['externalPackageUnit','externalPackageUnitField'], undefined, true, 'defaultValue');

    let actionExternalPackage = new Action(environment.base_url + '/api/externalpackage/v1/quantityandunitconversion?tenantId='+this.commonService.getFromStorage('tenantId'),undefined,undefined,[],undefined,undefined,postExternalCalculation,false);
    actionExternalPackage.setCovertToListRequired(false);
    actionExternalPackage.addFieldToUpdate('capacity',undefined, quantityexample, ['DB'], undefined, false,'defaultValue');
    actionExternalPackage.addFieldToUpdate('periodStartDate', undefined, resetValueDate, ['externalPackage','externalPackageField'], undefined, true, 'defaultValue');
    actionExternalPackage.addFieldToUpdate('periodEndDate', undefined, resetValueEndDate, ['externalPackage','externalPackageField'], undefined, true, 'defaultValue');

    let actionInternalPackage = new Action(environment.base_url + '/api/externalpackage/v1/quantityandunitconversion?tenantId='+this.commonService.getFromStorage('tenantId'),undefined,undefined,[],undefined,undefined,postInternalCalculation,false);
    actionInternalPackage.setCovertToListRequired(false);
    actionInternalPackage.addFieldToUpdate('capacity',undefined, quantityexample, ['DB'], undefined, false,'defaultValue');
    actionInternalPackage.addFieldToUpdate('periodStartDate', undefined, resetValueDate, ['internalPackage','internalPackageField'], undefined, true, 'defaultValue');
    actionInternalPackage.addFieldToUpdate('periodEndDate', undefined, resetValueEndDate, ['internalPackage','internalPackageField'], undefined, true, 'defaultValue');


    //Action Location Type
    let locationFunction = function (value: any[]) {
      return value[0];
    };
    let paymentdesc = function (value: any[]) {
      if(value[0]!==  null && value[0]!== undefined && value[0].length>0 ){
        return value[0][0]['description'];
      }
      return '';
    };

    let locationType: Action = new Action(environment.base_url + '/api/location/v1/getallbylocationtype?tenantId=' + this.commonService.getFromStorage('tenantId') + '&locationType=', 'name', 'name');
    locationType.setCovertToListRequired(true);
    locationType.addFieldToUpdate("location", undefined, locationFunction, ['L'], undefined, true, 'listvalues');

    let loadLocationType: Action = new Action(environment.base_url + '/api/location/v1/getallbylocationtype?tenantId=' + this.commonService.getFromStorage('tenantId') + '&locationType=', 'name', 'name');
    loadLocationType.setCovertToListRequired(true);
    loadLocationType.addFieldToUpdate("loadLocation", undefined, locationFunction, ['L'], undefined, true, 'listvalues');

    let unloadLocationType: Action = new Action(environment.base_url + '/api/location/v1/getallbylocationtype?tenantId=' + this.commonService.getFromStorage('tenantId') + '&locationType=', 'name', 'name');
    unloadLocationType.setCovertToListRequired(true);
    unloadLocationType.addFieldToUpdate("dischargeLocation", undefined, locationFunction, ['L'], undefined, true, 'listvalues');

    let paymentTermAction: Action = new Action(environment.base_url + '/api/paymentterm/v1/getpaymenttermbyname?tenantId='+this.commonService.getFromStorage('tenantId')+'&paymentTerm=');
    paymentTermAction.addFieldToUpdate('paymentTermsClause', undefined,paymentdesc,['DB'], undefined, false, 'defaultValue');

    let df=this.quantityRoundOff;
    let periodDateFunction = function (value:any[]) {
      return _this.commonService.getFormattedDate(value[1],environment.dateFormat);
    };
    let totalTradeQtyquantityFunction = function (value:any[]) {
      return _this.commonService.formatNumberWithoutComma(value[0],df);
    };
    let tradePriceValueFunction= function(value:any[]){
      return _this.commonService.formatNumberWithoutComma(value[0],_this.priceRoundOff)
    }

    let tradeOverallStatusList:KeyValue[] = [new KeyValue()];
    tradeOverallStatusList.push(new KeyValue('Draft','Draft'));
    tradeOverallStatusList.push(new KeyValue('Awaiting Approval','Awaiting Approval'));
    tradeOverallStatusList.push(new KeyValue('Confirmed','Confirmed'));


    let defaultToleranceValue = function (value: any[] = []) {
       let obj = `{"min":${0},"max":${0}}`;
       if(value[0] === 'BUY') {
          obj = `{"min":${2},"max":${2}}`
       } else if(value[0] === 'SELL') {
         obj = `{"min":${2},"max":${2}}`
       }
        return obj;
    };


    let actionTradetransactionType = new Action();
    actionTradetransactionType.addFieldToUpdate('toleranceValue',undefined, defaultToleranceValue,undefined , undefined, false,'defaultValue');

    this.columns = new Map();
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    /***** Section 1 : Trade Counterparty and Company Details **********/
    this.columns.set('tradeId', new Tcolumn('tradeId', 'Trade ID', 'LB', 1, false, undefined, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('dealId', new Tcolumn('dealId', 'Deal Id', 'T', 6, true, undefined, true, '', 'F', [], undefined, undefined, undefined, undefined, undefined,undefined,undefined,true));
    this.columns.set('tradeStatus', new Tcolumn('tradeStatus', 'Trade Overall Status', 'CUSTOM', 2, true, tradeOverallStatusList, true, 'Draft', 'B'));
    this.columns.set('tradeType', new Tcolumn('tradeType', 'Trade Type', 'T', 6, true, undefined, true, '', 'F', [], undefined, undefined, undefined, undefined, undefined,undefined,undefined));
    this.columns.set('manualTradeId', new Tcolumn('manualTradeId', 'Manual Trade ID', 'T', 6, true, undefined, true, '', 'F', [], undefined, undefined, undefined, undefined, undefined,undefined,undefined,true));
    this.columns.set('tradeDateTime', new Tcolumn('tradeDateTime', 'Trade Date', 'D', 6, true, undefined, true, new Date(), 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined));
    this.columns.set('isInternalTrade', new Tcolumn('isInternalTrade', 'Internal Trade', 'OB', 7, true, this.getIntExt(), true, false, 'H', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionInternalTrade));
    this.columns.set('tradeTransactionType', new Tcolumn('tradeTransactionType', 'Buy/Sell', 'OB', 8, true, this.getBuySell(), true, '', 'B', [Validators.required],undefined, undefined, undefined, undefined, undefined, actionTradetransactionType));
    this.columns.set('company', new Tcolumn('company', 'Company', 'L', 9, true, [], true, '', 'F', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionCompany,undefined,false,undefined,true,true));
    // this.columns.set('counterparty', new Tcolumn('counterparty', 'CounterParty', 'L', 10, true, [], true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionCounterparty,undefined,false,undefined,true,true));
    this.columns.set('counterpartyCode', new Tcolumn('counterpartyCode', 'Counterparty Code', 'L', 10, true, [], true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionCounterpartyCode,undefined,false,undefined));
    this.columns.set('profitcenter', new Tcolumn('profitcenter', 'Profit Center', 'L', 11, true, [], true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined,undefined,false,undefined,true,true));
    this.columns.set('internalProfitCenter', new Tcolumn('internalProfitCenter', 'Sell Profit Center', 'L', 12, false, [], true, '', 'F', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined, {
      meta: {disabled: true},
      infoIcon: {summary: '', icon:'fas fa-info-circle infoIcon'}
    }));
    this.columns.set('traderName', new Tcolumn('traderName', 'Trader Name', 'L', 13, true, this.masterCommonService.getListFromUrl('/api/trade/v1/getuser?tenantId='+this.commonService.getFromStorage('tenantId')+'&userType=Trader', 'value', 'value'), true,'', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined));
    this.columns.set('createdBy', new Tcolumn('createdBy', 'Created By', 'LB', 14, true, undefined, true,'', 'B'));
    this.columns.set('broker', new Tcolumn('broker', 'Broker', 'L', 15, true, [], true, '', 'F', []));
    this.columns.set('brokerReference', new Tcolumn('brokerReference', 'Broker Reference', 'T', 16, true, undefined, true, '', 'F', []));
    this.columns.set('externalReference', new Tcolumn('externalReference', 'External Reference', 'T', 16, true, undefined, true, '', 'F', []));
    // this.columns.set('paperTrade', new Tcolumn('paperTrade', 'Paper Trade', 'B', 55, true, this.masterCommonService.getBooleanFromLabel('Yes', 'No'), true, false, 'F', [], undefined, undefined, undefined, undefined, undefined));

    /***** Section 2 : Trade Commodity Origin and Specificaiton Details  (Complete)  **********/
    this.columns.set('commodity', new Tcolumn('commodity', 'Commodity', 'L', 17, true, [], true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionCommodity));
    this.columns.set('origin', new Tcolumn('origin', 'Origin', 'L', 18, true, undefined, true, '', 'B', []));
    this.columns.set('brand', new Tcolumn('brand', 'Brand', 'L', 19, true, undefined, true, '', 'F'));
    this.columns.set('grade', new Tcolumn('grade', 'Grade', 'L', 20, true, undefined, true, '', 'F'));
    this.columns.set('season', new Tcolumn('season', 'Season', 'L', 21, true, undefined, true, '', 'F'));
    this.columns.set('qualityTermsClause', new Tcolumn('qualityTermsClause', 'Quality Terms Clause', 'EL', 9, true,undefined, true, '', 'F', [Validators.maxLength(2048)], undefined, 3, undefined, undefined, undefined, undefined, {
      meta: {},
      infoIcon: {summary: '', icon:'fas fa-file infoIcon'}
    }));

    /***** Section 3 : Trade Delivery Details **********/
    this.columns.set('incoterm', new Tcolumn('incoterm', 'Incoterm', 'L', 22, true, [], true, '', 'B', [Validators.required]));
    this.columns.set('locationType', new Tcolumn('locationType', 'Location Type', 'L', 23, true, [], true, 'Country', 'F', [],undefined,undefined,undefined,undefined,undefined,locationType));
    this.columns.set('location', new Tcolumn('location', 'Location', 'L', 24, true, [], true, '', 'F', []));
    this.columns.set('modeOfTransport', new Tcolumn('modeOfTransport', 'Mode Of Transport', 'L', 25, true, [], true, 'Ocean', 'F', []));
    this.columns.set('loadLocationType', new Tcolumn('loadLocationType', 'Load Location Type', 'L', 26, true, [], true, 'Port', 'F', [],undefined,undefined,undefined,undefined,undefined,loadLocationType));
    this.columns.set('loadLocation', new Tcolumn('loadLocation', 'Load Location', 'L', 27, true, [], true, '', 'F', []));
    this.columns.set('unloadLocationType', new Tcolumn('unloadLocationType', 'Unload Location Type', 'L', 28, true, [], true, 'Port', 'F', [],undefined,undefined,undefined,undefined,undefined,unloadLocationType));
    this.columns.set('dischargeLocation', new Tcolumn('dischargeLocation', 'Unload Location', 'L', 29, true, [], true, '', 'F', []));
    this.columns.set('packageType', new Tcolumn('packageType', 'Shipment Type', 'OB', 30, true, this.getInternalPackageType(), true, 'Bulk', 'F', [Validators.required],undefined,3,undefined,undefined,undefined,actionpackageType));
    this.columns.set('weightBasis', new Tcolumn('weightBasis', 'Weight Basis', 'L', 31, true, [], true, 'Net Shipped Weights', 'F', []));
    this.columns.set('quantityUom', new Tcolumn('quantityUom', 'Quantity Uom', 'L', 32, true, [], true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionQuantityUOM));
    this.columns.set('quantity', new Tcolumn('quantity', 'Quantity/Period', 'N', 33, true, [], true, '', 'F', [Validators.min(1), ctrmValidators.decimalLengthValidator(10, this.quantityRoundOff), Validators.required], undefined, undefined, undefined, undefined, undefined, actionQuantity, {action: {runOnParentChange: false}}));
    this.columns.set('internalPackage', new Tcolumn('internalPackage', 'Package Type', 'L', 37, true, [], true, '', 'F', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionInternalPackage, {meta: {disabled: true}}));
    this.columns.set('internalPackageUnit', new Tcolumn('internalPackageUnit', '# of Units/Period', 'N', 38, true, undefined, true, '', 'F', [Validators.required,Validators.min(1)],undefined,undefined,undefined,undefined,undefined,actionInternalpackageUnit,{meta: {disabled: true},action:{runOnParentChange: false}}));
    this.columns.set('quantityPeriodicity', new Tcolumn('quantityPeriodicity', 'Quantity Periodicity', 'L', 34, true, [], true, 'Fixed', 'B', [], undefined, undefined, undefined, undefined, undefined, actionQuantityPeriodicity));
    this.columns.set('periodStartDate', new Tcolumn('periodStartDate', 'Period Start Date', 'D', 39, true, undefined, true, new defaultValue('',undefined,[],undefined,false,true,false,periodDateFunction,['quantityPeriodicity','periodStartDate']), 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionStartPeriod));
    this.columns.set('periodEndDate', new Tcolumn('periodEndDate', 'Period End Date', 'D', 40, true, undefined, true,  new defaultValue('',undefined,[],undefined,false,true,false,periodDateFunction,['quantityPeriodicity','periodEndDate']), 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionEndDate));
    this.columns.set('deliverySchedule', new Tcolumn('deliverySchedule', 'View Delivery Schedule', 'LINK', 43, false, undefined, true, '', 'F', [], undefined, undefined, undefined, undefined, undefined));
    this.columns.set('totalUnits', new Tcolumn('totalUnits', 'Total Units', 'N', 44, true, [], true, '', 'F', [], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('totalTradeQty', new Tcolumn('totalTradeQty', 'Total Quantity', 'N', 45, true, [], true, new defaultValue('', undefined, undefined, undefined, false, true, false, totalTradeQtyquantityFunction, ['totalTradeQty']), 'B', [Validators.min(0), ctrmValidators.decimalLengthValidator(10, this.quantityRoundOff), Validators.required], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('capacity', new Tcolumn('capacity', 'Capacity', 'N', 46, false, undefined, false, '', 'H'));
    this.columns.set('deliveryTermsClause', new Tcolumn('deliveryTermsClause', 'Delivery Terms Clause', 'EL', 9, true,undefined, true, '', 'F', [Validators.maxLength(2048)], undefined, 3, undefined, undefined, undefined, undefined, {
      meta: {},
      infoIcon: {summary: '', icon:'fas fa-file infoIcon'}
    }));
    this.columns.set('quantityTermsClause', new Tcolumn('quantityTermsClause', 'Quantity Terms Clause', 'EL', 9, true,undefined, true, '', 'F', [Validators.maxLength(2048)], undefined, 3, undefined, undefined, undefined, undefined, {
      meta: {},
      infoIcon: {summary: '', icon:'fas fa-file infoIcon'}
    }));

    /***** Section 4 : Trade Tolerance Details **********/
    this.columns.set('quantityToleranceType', new Tcolumn('quantityToleranceType', 'Quantity Tolerance Type', 'OB', 47, true, this.getToleranceTypeList(), true, 'percentage', 'F', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionQuantityToleranceType));
    this.columns.set('toleranceValue', new Tcolumn('toleranceValue', 'Tolerance Value', 'DSL', 48, true, undefined, true, new defaultValue('{"min":10,"max":10}', undefined, undefined, undefined, false, true, false, formatOutput, ['quantityToleranceType', 'quantity', 'quantityUom', 'toleranceValue']), 'F', [], undefined, 2));

    /***** Section 5 : Trade Price Details **********/
    this.columns.set('priceType', new Tcolumn('priceType', 'Price Type', 'L', 49, true, [], true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionPriceType));
    this.columns.set('futureIndex', new Tcolumn('futureIndex', 'Price Ticker', 'L', 50, true, [], true, '', 'F', [], undefined, undefined, undefined, undefined, undefined));
    this.columns.set('tradePriceCurrency', new Tcolumn('tradePriceCurrency', 'Price Currency', 'L', 53, true, this.commonService.getCurrencyList(), true, 'USD', 'F', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionPriceCurrency));
    this.columns.set('tradePrice', new Tcolumn('tradePrice', 'Price', 'N', 52, true, undefined, true,  new defaultValue('', undefined, undefined, undefined, false, false, false, tradePriceValueFunction), 'B', [Validators.required,  ctrmValidators.decimalLengthValidator(10, this.priceRoundOff),Validators.min(0)], undefined));
    this.columns.set('tradePriceUom', new Tcolumn('tradePriceUom', 'Price UOM', 'L', 54, true, [], true, 'MT', 'F', [Validators.required]));
    this.columns.set('quotationPeriodClause', new Tcolumn('quotationPeriodClause', 'Quotation Period Clause', 'EL', 9, true,undefined, true, '', 'F', [Validators.maxLength(2048)], undefined, 3, undefined, undefined, undefined, undefined, {
      meta: {},
      infoIcon: {summary: '', icon:'fas fa-file infoIcon'}
    }));
    this.columns.set('pricingTermsClause', new Tcolumn('pricingTermsClause', 'Pricing Terms Clause', 'EL', 9, true,undefined, true, '', 'F', [Validators.maxLength(2048)], undefined, 3, undefined, undefined, undefined, undefined, {
      meta: {},
      infoIcon: {summary: '', icon:'fas fa-file infoIcon'}
    }));

    /***** Section 6 : Trade  Provisional Price Details **********/
    this.columns.set('provisionalPricing', new Tcolumn('provisionalPricing', 'Provisional Pricing', 'B', 55, true, this.masterCommonService.getBooleanFromLabel('Yes', 'No'), true, false, 'F', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionProvisionalPricing));
    this.columns.set('provisionalPriceType', new Tcolumn('provisionalPriceType', 'Provisional Price Type', 'L', 56, true, [], true, 'Fixed', 'F', [], undefined, undefined, undefined, undefined, undefined, actionProvPriceType, {meta: {disabled: true}}));
    this.columns.set('percentage', new Tcolumn('percentage', 'Percentage', 'N', 57, true, undefined, true, '', 'F', [Validators.min(0), Validators.max(100)], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('provisionalPrice', new Tcolumn('provisionalPrice', 'Provisional Price', 'N', 58, true, undefined, true, '', 'F', [Validators.min(0),ctrmValidators.decimalLengthValidator(10, this.priceRoundOff)], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('provisionalPriceCurrency', new Tcolumn('provisionalPriceCurrency', 'Provisional Price Currency', 'L', 59, true, this.commonService.getCurrencyList(), true, '', 'F', [], undefined, undefined, undefined, undefined, undefined, actionProvPriceCurrency, {meta: {disabled: true}}));
    this.columns.set('provisionalPriceUom', new Tcolumn('provisionalPriceUom', 'Provisional Price UOM', 'L', 60, true, [], true, '', 'F', [], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));

    /***** Section 7 : Trade FX & settlement Details **********/
    this.columns.set('tradeSettlementCurrency', new Tcolumn('tradeSettlementCurrency', 'Settlement Currency', 'L', 61, true, this.commonService.getCurrencyList(), true, 'USD', 'F', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionSettlementCurrency));
    this.columns.set('settlementTolerancePercentage', new Tcolumn('settlementTolerancePercentage', 'Settlement Tolerance Percentage', 'N', 62, true, undefined, true, new defaultValue('0', undefined, ['.', '/']), 'F', [], undefined, undefined, undefined, undefined, undefined, undefined));
    this.columns.set('fxrate', new Tcolumn('fxrate', 'FX Rate', 'N', 62, true, undefined, true, new defaultValue('1', undefined, ['.', '/']), 'F', [], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('provisionalFxRate', new Tcolumn('provisionalFxRate', 'Provisional Fx Rate', 'N', 63, true, undefined, true, new defaultValue('1', undefined, ['.', '/']), 'F', [], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('paymentterm', new Tcolumn('paymentterm', 'Payment Term', 'L', 64, true, [], true, '0', 'F', [Validators.required], undefined, undefined, undefined, undefined, undefined, paymentTermAction));
    this.columns.set('paymentTermsClause', new Tcolumn('paymentTermsClause', 'Payment Terms Clause', 'T', 9, true,undefined, true, '', 'F', [Validators.maxLength(2048)], undefined, 2, undefined, undefined, undefined, undefined, {
      meta: {},
      infoIcon: {summary: '', icon:'fas fa-file infoIcon'}
    }));

    /***** Section 8 : Others **********/
    this.columns.set('contractConfirmation', new Tcolumn('contractConfirmation', 'Short Contract Template', 'L', 65, true, [], true, '', 'F', []));
    this.columns.set('longContract', new Tcolumn('longContract', 'Long Contract Template', 'L', 66, true, [], true, '', 'F', []));
    this.columns.set('specialInstructions', new Tcolumn('specialInstructions', 'Special Instructions', 'TA', 67, true, undefined, true, '', 'F', [Validators.maxLength(2048)]));
    this.columns.set('comments', new Tcolumn('comments', 'Comments', 'TA', 68, true, undefined, true, '', 'F', [Validators.maxLength(2048)]));
    this.columns.get('toleranceValue').setToExtra('dualSliderOption', {
      labelFormatLeft: '- <value> %',
      labelLowFormatLeft: '- <value> %',
      labelHighFormatLeft: '- <value> %',
      labelFormatRight: '<value> %',
      labelLowFormatRight: '<value> %',
      labelHighFormatRight: '<value> %',
      floorLeft: 0,
      floorRight: 0,
      ceilLeft: 100,
      ceilRight: 100
    });
    this.columns.get('tradeStatus').metaFunctionForFilter = function(value:any) {
      return 'OB';
    }
    this.columns.get('company').metaFunctionForFilter=function (value:any){
      return 'T';
    }
    this.columns.get('counterpartyCode').metaFunctionForFilter=function (value:any){
      return 'T';
    }
    this.columns.get('profitcenter').metaFunctionForFilter=function (value:any){
      return 'T';
    }
    this.columns.get('traderName').metaFunctionForFilter=function (value:any){
      return 'T';
    }
    this.columns.get('commodity').metaFunctionForFilter=function (value:any){
      return 'T';
    }
    this.columns.get('origin').metaFunctionForFilter=function (value:any){
      return 'T';
    }
    this.columns.get('tradeId').showFilter = false;
    this.columns.get('tradeTransactionType').showFilter = false;
    this.columns.get('manualTradeId').showFilter = false;
    this.columns.get('tradeDateTime').showFilter = false;
    this.columns.get('company').showFilter = false;
    this.columns.get('counterpartyCode').showFilter= false;
    this.columns.get('profitcenter').showFilter = false;
    this.columns.get('traderName').showFilter = false;
    this.columns.get('createdBy').showFilter = false;
    this.columns.get('commodity').showFilter = false;
   // this.getUserInfoList();
  }

  //get all data on click of the Add
  getDataOnLoad() {
  let _this = this;
    _this.commonService.getJSONByURL(environment.base_url + '/api/trade/v1/loadconfigdata?tenantId='+this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
     _this.populateValues(next);
    });
  }

  //populate values on load of the screen from next
  populateValues(next) {
    this.paymentTermList = this.masterCommonService.getListFromArray(next['paymentterm']);
    this.grid.getColumn().get('company').setListofValues(this.masterCommonService.getListFromArray(next['company']));
    this.grid.getColumn().get('broker').setListofValues(this.masterCommonService.getListFromArray(next['broker']));
    this.grid.getColumn().get('incoterm').setListofValues(this.masterCommonService.getListFromArray(next['incoterm']));
    this.grid.getColumn().get('paymentterm').setListofValues(this.masterCommonService.getListFromArray(next['paymentterm']));
    this.grid.getColumn().get('priceType').setListofValues(this.masterCommonService.getListFromArray(next['price_type_ind']));
    this.grid.getColumn().get('profitcenter').setListofValues(this.masterCommonService.getListFromArray(next['profitcenter']));
    this.grid.getColumn().get('internalProfitCenter').setListofValues(this.masterCommonService.getListFromArray(next['profitcenter']));
    this.grid.getColumn().get('provisionalPriceType').setListofValues(this.masterCommonService.getListFromArray(next['provisional_price_type_ind']));
    this.grid.getColumn().get('quantityPeriodicity').setListofValues(this.masterCommonService.getListFromArray(next['quantity_periodicity_ind']));
    this.grid.getColumn().get('quantityUom').setListofValues(this.masterCommonService.getListFromArray(next['unitofmeasurement']));
    this.grid.getColumn().get('tradePriceUom').setListofValues(this.masterCommonService.getListFromArray(next['unitofmeasurement']));
    this.grid.getColumn().get('provisionalPriceUom').setListofValues(this.masterCommonService.getListFromArray(next['unitofmeasurement']));
    this.grid.getColumn().get('locationType').setListofValues(this.masterCommonService.getListFromArray(next['location_type_ind']));
    this.grid.getColumn().get('loadLocationType').setListofValues(this.masterCommonService.getListFromArray(next['location_type_ind']));
    this.grid.getColumn().get('unloadLocationType').setListofValues(this.masterCommonService.getListFromArray(next['location_type_ind']));
    this.grid.getColumn().get('weightBasis').setListofValues(this.masterCommonService.getListFromArray(next['weight_basis_ind']));
    this.grid.getColumn().get('modeOfTransport').setListofValues(this.masterCommonService.getListFromArray(next['mode_of_transport_ind']));
    this.grid.getColumn().get('contractConfirmation').setListofValues(this.masterCommonService.getListFromArray(next['doctemplatemaster']));
    this.grid.getColumn().get('longContract').setListofValues(this.masterCommonService.getListFromArray(next['doctemplatemaster']));
  }

  getListForClause(){
    this.commonService.getJSONByURL(environment.base_url + "/api/trade/v1/getallclausevalues?tenantId=" + this.commonService.getTenantId()).subscribe((data:any) => {
      this.grid.getColumn().get('deliveryTermsClause').setListofValues(this.masterCommonService.getListFromArray(data['deliveryTermsClauseList'], false, false));
      this.grid.getColumn().get('quantityTermsClause').setListofValues(this.masterCommonService.getListFromArray(data['quantityTermsClauseList'], false, false));
      this.grid.getColumn().get('qualityTermsClause').setListofValues(this.masterCommonService.getListFromArray(data['qualityTermsClauseList'], false, false));
      this.grid.getColumn().get('quotationPeriodClause').setListofValues(this.masterCommonService.getListFromArray(data['quotationClauseList'], false, false));
      this.grid.getColumn().get('pricingTermsClause').setListofValues(this.masterCommonService.getListFromArray(data['pricingTermsClauseList'], false, false));
    });
  }
  //on click of add button
  onAddClick(event:any) {
    if(event['screenName'] === "Cost") {
      let tradeId = this.currentRow['tradeId'];
      if(tradeId !== null && tradeId !== undefined && tradeId.length > 0) {
        this.linkTo=tradeId;
        this.refreshTab = '';
        this.paymentDueDate=this.currentRow['periodEndDate'];
        this.showAddCost=true;
      }
    } else {
      this.getDataOnLoad();
      this.getUserInfoList();
      this.grid.removeAllExtraButtons();
    }
  }
  onClickDelete(event:any){

  }

  onClickEditButton(event:any) {
    if(event['screenName'] === "Cost"){
      let tradeId = this.currentRow['tradeId'];
      if(tradeId !== null && tradeId !== undefined && tradeId.length > 0) {
        this.linkTo=tradeId;
        this.paymentDueDate=this.currentRow['periodEndDate'];
        this.refreshTab = '';
        this.costObject = event['rowData'];
        this.showAddCost= true;
      }
    }else{
      this.getDataOnLoad();
      this.getUserInfoList();
    }

  }

  getUserInfoList() {
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url_new + '/api-iam/api/userProfile/v1/getUserDetails?userId=' + this.commonService.getFromStorage('userName') + '&tenantId=' + this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
      _this.listCompany = _this.getDropDownValues(next['userInfoData']['Legal Entity']);
      _this.listCommodity=_this.getDropDownValues(next['userInfoData']['Commodity']);
      _this.listCounterpartyCode= _this.getDropDownValues(next['userInfoData']['Counterparty'])
      _this.listProfitCenter = _this.getDropDownValues(next['userInfoData']['Profit Center']);
      _this.grid.getColumn().get('company').setListofValues(_this.listCompany);
      _this.grid.getColumn().get('commodity').setListofValues(_this.listCommodity);
      _this.grid.getColumn().get('counterpartyCode').setListofValues(_this.listCounterpartyCode),
      _this.grid.getColumn().get('profitcenter').setListofValues(_this.listProfitCenter);
      _this.grid.getColumn().get('internalProfitCenter').setListofValues(_this.listProfitCenter);
    });
  }

  getDropDownValues(property:any){
    let array = [{label:'Select', value:''}];
    if (property !== undefined && property !== null){
      property.forEach(data => {
        array.push({label:data['masterTypeValue'], value:data['masterTypeValue']});
      });
    }
    return array;
  }

  getAttachment() {
        let _this = this;
        let columns: Map<string, Tcolumn> = new Map();
        let updateAttachmentName = function updatename(value: any = []) {
            let file = value[0];
            return file['name'];
        };
        let updateAttachmentType = function updatetype(value: any = []) {
            let file = value[0];
            return file['type'];
        };

        let updateFileSize = function updatetype(value: any = []) {
            let file: File = value[0];
            return _this.commonService.fileSizeConvert(file.size, true);
        };
        let action: Action = new Action();
        action.addFieldToUpdate("attachmentFileName", '', updateAttachmentName, undefined, undefined, false);
        action.addFieldToUpdate("attachmentFileType", '', updateAttachmentType, undefined, undefined, false);
        action.addFieldToUpdate("fileSize", '', updateFileSize, undefined, undefined, false);

        columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
        columns.set('tradeId', new Tcolumn('tradeId', 'Trade ID', 'LB', 1, false, undefined, false, '', 'B', [Validators.required]));
        columns.set('attachment', new Tcolumn('attachment', 'Attachment', 'F', 2, true, undefined, true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, action, {
                downloadUrl: {
                    url: environment.base_url ,
                    para: ['attachmentUrl']
                }
            }
        ));
        columns.set('uploadedBy', new Tcolumn('uploadedBy', 'Uploaded By', 'LB', 3, false, undefined, true, this.commonService.getFromStorage('userName'), 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disbaled: true}}));
        columns.set('uploadDate', new Tcolumn('uploadDate', 'Upload Date', 'D', 4, true, undefined, true, new Date(), 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        columns.set('download', new Tcolumn('download', 'Download', 'BT', 5, true, undefined, true, '', 'G'));
        columns.set('attachmentFileName', new Tcolumn('attachmentFileName', 'Name', 'LB', 7, false, undefined, false, '', 'H'));
        columns.set('attachmentFileType', new Tcolumn('attachmentFileType', 'Type', 'LB', 8, false, undefined, false, '', 'H'));
        let grid = new Grid(columns, true, true, true, true, 'attachment', '/api/tradeattachment/v1/getalltradeattachments?tenantId='+this.commonService.getFromStorage('tenantId')+'&tradeUuid=', '', 'Attachment', '', '', undefined, undefined, false);
        grid.setGridConfiguration(true, true, false, false);
        grid.setSaveUrl(environment.base_url + '/api/tradeattachment/v1/uploadattachment?manualUpload=true');
        grid.setUpdateUrl(environment.base_url + '/api/tradeattachment/v1/updateattachment');
        grid.hrefUrl = environment.base_url + '/api/tradeattachment/v1/getattachmentbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
        let tab = new Tab(grid.getTitle(), grid, 'tradeId');
        tab.idFieldValueName = 'tradeUuid'
        return tab;
    }

  getQualitySpecification(canDelete:boolean = false,loadQualitySpec:boolean = true) {
    if(loadQualitySpec) {
      this.getqualitySpecOnLoad();
    }
        let minVal = function minVali(value: any[] = []) {
          return [Validators.min(parseInt(value[0]))];
        };
        let action = new Action();
        action.addFieldToUpdate('max', undefined, minVal, undefined, undefined, false, 'validators');

        let actionQuality: Action = new Action(environment.base_url + '/api/globalindicatordetails/v1/getgidetailsdatabyname?tenantId='+this.commonService.getFromStorage('tenantId')+'&name=');
        actionQuality.addFieldToUpdate('description', 'description',undefined,undefined, undefined, false, 'defaultValue');


    let columns: Map<string, Tcolumn> = new Map();
        columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
        columns.set('tradeId', new Tcolumn('tradeId', 'Trade ID', 'LB', 1, true, undefined, false, '', 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        columns.set('name', new Tcolumn('name', 'Quality Parameter Name', 'L', 3, false, this.masterCommonService.getListFromArray(this.qualitySpecData['quality_parameter_ind']), true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionQuality));
        columns.set('description', new Tcolumn('description', 'Quality Parameter Description', 'LB', 4, false, undefined, true, undefined, 'B', []));
        columns.set('min', new Tcolumn('min', 'Min', 'N', 5, true, undefined, true, '', 'B', [Validators.min(0), ctrmValidators.decimalLengthValidator(10, 2)], undefined, undefined, undefined, undefined, undefined, action));
        columns.set('max', new Tcolumn('max', 'Max', 'N', 6, true, undefined, true, '', 'B', [Validators.min(0), ctrmValidators.decimalLengthValidator(10, 2)]));
        columns.set('typical', new Tcolumn('typical', 'Typical', 'T', 7, true, undefined, true, '', 'B'));
        columns.set('basis', new Tcolumn('basis', 'Basis', 'T', 8, true, undefined, true, '', 'B'));
        columns.set('unit', new Tcolumn('unit', 'Unit', 'EL', 9, true, this.masterCommonService.getListFromArray(this.qualitySpecData['quality_spec_unit_ind']), true, '', 'B', [Validators.required]));
        let grid = new Grid(columns, true, true, true, true, 'name', '/api/qualitySpecification/v1/getAllQualitySpecificationsByTradeId?tenantId='+this.commonService.getFromStorage('tenantId')+'&tradeId=', '', 'Quality Specification', '', '', undefined, undefined, false);
        grid.setGridConfiguration(true, true, true, canDelete);
        grid.setToolbarConfig(true,true,true,canDelete);
        grid.setSaveUrl(environment.base_url + '/api/qualitySpecification/v1/save');
        grid.deleteUrl = environment.base_url+ '/api/qualitySpecification/v1/delete';
        grid.setUpdateUrl(environment.base_url + '/api/qualitySpecification/v1/update');
        grid.urlIdField = "tradeId";
        grid.hrefUrl = environment.base_url + '/api/qualitySpecification/v1/getbyqualityspecuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
        grid.importUrl = environment.base_url + '/qualitySpecification/import';
        let tab = new Tab(grid.getTitle(), grid, 'tradeId');
        tab.idFieldValueName = 'tradeUuid';
        return tab;
    }

    //Get Qualitispec load data
  getqualitySpecOnLoad() {
    let _this = this;
    _this.commonService.getLoadData('commodity', ['data','data'], [[{key: 'name', value: 'quality_parameter_ind'}],[{key:'name',value:'quality_spec_unit_ind'}]]).subscribe((next: any) => {
        this.qualitySpecData = next;
        _this.grid.getTabs()[0].getGrid().getColumn().get('name').setListofValues(_this.masterCommonService.getListFromArray(next['quality_parameter_ind']));
        _this.grid.getTabs()[0].getGrid().getColumn().get('unit').setListofValues(_this.masterCommonService.getListFromArray(next['quality_spec_unit_ind']));
    });
  }

  getCost(currency, uom,costOnLoad:boolean = true) {

    let updateCostGroup = function (value: any = []) {
        return '';
    };

    let actionCostName: Action = new Action();
    actionCostName.addFieldToUpdate("costGroup", '', updateCostGroup, undefined, undefined, false,'defaultValue');


    let columns: Map<string, Tcolumn> = new Map();
    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    columns.set('linkTo', new Tcolumn('linkTo', 'Trade Id', 'LB', 2, true, undefined, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: false}}));
    columns.set('costId', new Tcolumn('costId', 'Cost ID', 'LB', 3, true, undefined, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('name', new Tcolumn('name', 'Name', 'L', 4, true, undefined, true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, actionCostName,undefined,false,undefined));
    columns.set('type', new Tcolumn('type', 'Type', 'L', 5, true, [], true,'', 'B', [Validators.required]));
    columns.set('counterparty', new Tcolumn('counterparty', 'Counterparty', 'L', 6, true, undefined, true, undefined, 'B', [Validators.required]));
    columns.set('costGroup', new Tcolumn('costGroup', 'Cost Group', 'L', 7, true, undefined, true, '', 'B', [Validators.required]));
    columns.set('costType', new Tcolumn('costType', 'Cost Type', 'L', 8, true, undefined, true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined));
    columns.set('costValue', new Tcolumn('costValue', 'Cost Value', 'N', 9, true, undefined, true, '', 'B', [Validators.required,Validators.min(0)]));
    columns.set('costCurrency', new Tcolumn('costCurrency', 'Cost Currency', 'L', 10, true, this.commonService.getCurrencyList(), true, this.currentRow['tradeId'], 'B', []));
    columns.set('uom', new Tcolumn('uom', 'Unit of Measurement', 'L', 11, true, undefined, true, '', 'B'));
    columns.set('quantityOption', new Tcolumn('quantityOption', 'Quantity Option', 'L', 12, true, undefined, true, '', 'B', [Validators.required]));
    columns.set('percentageComponent', new Tcolumn('percentageComponent', 'Percentage Component', 'L', 13, true, undefined, true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('description', new Tcolumn('description', 'Description', 'TA', 14, true, undefined, true, '', 'B', []));
    /***************section 2 *********************/
    columns.set('inputDate', new Tcolumn('inputDate', 'Input Date', 'D', 15, true, undefined, true, new Date(), 'B', [], undefined, undefined, undefined, undefined, undefined, undefined));
    columns.set('paymentType', new Tcolumn('paymentType', 'Payment Option Type', 'L', 16, true,undefined, true, '', 'F', [], undefined, undefined, undefined, undefined, undefined, undefined));
    columns.set('paymentTerm', new Tcolumn('paymentTerm', 'Payment Term', 'L', 17, true,undefined, true, '', 'B', []));
    columns.set('paymentDueDate', new Tcolumn('paymentDueDate', 'Payment Due Date', 'D', 18, true, undefined, true, '', 'B',[]));
    columns.set('percentagePayable', new Tcolumn('percentagePayable', 'Percentage Payable', 'N', 19, true, undefined, true, 100, 'B', [Validators.required, Validators.max(100), Validators.min(0)], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('taxApplicable', new Tcolumn('taxApplicable', 'Tax Applicable', 'B', 20, true, this.masterCommonService.getBooleanFromLabel('Yes', 'No'), false, undefined, 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: false}}));
    /************** section 3 *****************/
    columns.set('additionalNote', new Tcolumn('additionalNote', 'Additional Note', 'TA', 21, true, undefined, true, '', 'B', [], undefined, 3));
    let grid = new Grid(columns, true, true, true, true, 'costId', '/api/cost/v1/getallcostbycostforandlinkto?tenantId='+this.commonService.getFromStorage('tenantId')+'&costFor=TRADE&linkTo=', '', 'Cost', '', '', undefined, undefined, false);
    grid.setGridConfiguration(true, true, false, true);
    grid.setToolbarConfig(true,true,false,true);
    grid.addSection('Cost Details', ['linkTo', 'name', 'type', 'counterparty', 'costGroup', 'costType', 'costValue', 'costCurrency', 'uom', 'quantityOption', 'percentageComponent', 'description']);
    grid.addSection('Cost Payment & Schedule Details', ['inputDate','paymentType', 'paymentTerm', 'paymentDueDate', 'percentagePayable']);
    grid.addSection('Others', ['additionalNote']);
    grid.hrefUrl = environment.base_url + '/api/cost/v1/getbycostuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
    grid.ignoreFieldListForExport = ['costId'];
    grid.setSaveUrl(environment.base_url + '/api/cost/v1/savecost?tenantId=' + this.commonService.getFromStorage('tenantId'));
    grid.setUpdateUrl(environment.base_url + '/api/cost/v1/update?tenantId=' + this.commonService.getFromStorage('tenantId'));
    grid.deleteUrl = environment.base_url+ '/api/cost/v1/deleteByCostId?tenantId='+this.commonService.getFromStorage('tenantId')+'&costId=';
    grid.urlIdField = 'tradeId';
    grid.useDefaultEditFunction = false;
    grid.useDefaultInlineEditFunction = true;
    grid.actionFunc.preSaveFunc = (data) => {
        let rowData = data.data;
        let originalData = data.originalData;
        rowData['linkTo'] = originalData['linkTo'];
        return rowData;
    };
    grid.deleteFunction = (data) => {
      data.selectedRows.forEach(data=>{
        this.commonService.deletByURL(environment.base_url+"/api/cost/v1/deleteByCostId?costId="+data.costId+"&tenantId="+this.commonService.getFromStorage('tenantId')).subscribe((data:any)=>{
              this.showToast("Trade Cost Deleted Succefully");
              this.refreshTab = 'Cost';
            },err=>{
              this.showToast("Trade cost is already Actualized cannot be Deleted",'error');
              this.refreshTab = 'Cost';
            })
      })
    }
    grid.openDefaultInputForm = false;
    let tab = new Tab(grid.getTitle(), grid, 'linkTo');
    tab.idFieldValueName = 'linkTo';
    if(costOnLoad) {
      this.getCostOnLoad(grid);
    }
    return tab;
  }

    //Get Qualitispec load data
    getCostOnLoad(grid:Grid) {
        let _this = this;
        _this.commonService.getLoadData('commodity', ['data','data','data','data','data','data','data','config','config','config'],
            [[{key: 'name', value: 'cost_detail_name_ind'}],
              [{key: 'name', value: 'cost_detail_type_ind'}],
              [{key: 'name', value: 'cost_group_ind'}],
              [{key: 'name', value: 'cost_type_ind'}],
              [{key: 'name', value: 'quantity_option_ind'}],
              [{key: 'name', value: 'percentage_component_ind'}],
              [{key: 'name', value: 'cost_payment_type_ind'}],[{key: 'code', value: 'counterparty'}],'paymentterm','unitofmeasurement']).subscribe((next: any) => {
            _this.costData = next;
          grid.getColumn().get('name').setListofValues(_this.masterCommonService.getListFromArray(next['cost_detail_name_ind']));
          grid.getColumn().get('type').setListofValues(_this.masterCommonService.getListFromArray(next['cost_detail_type_ind']));
          _this.grid.getTabs()[1].getGrid().getColumn().get('costGroup').setListofValues(_this.masterCommonService.getListFromArray(next['cost_group_ind']));
          _this.grid.getTabs()[1].getGrid().getColumn().get('costType').setListofValues(_this.masterCommonService.getListFromArray(next['cost_type_ind']));
          _this.grid.getTabs()[1].getGrid().getColumn().get('quantityOption').setListofValues(_this.masterCommonService.getListFromArray(next['quantity_option_ind']));
          _this.grid.getTabs()[1].getGrid().getColumn().get('percentageComponent').setListofValues(_this.masterCommonService.getListFromArray(next['percentage_component_ind']));
          _this.grid.getTabs()[1].getGrid().getColumn().get('paymentType').setListofValues(_this.masterCommonService.getListFromArray(next['cost_payment_type_ind']));
          _this.grid.getTabs()[1].getGrid().getColumn().get('counterparty').setListofValues(_this.masterCommonService.getListFromArray(next['counterparty']));
          _this.grid.getTabs()[1].getGrid().getColumn().get('paymentTerm').setListofValues(_this.masterCommonService.getListFromArray(next['paymentterm']));
          _this.grid.getTabs()[1].getGrid().getColumn().get('uom').setListofValues(_this.masterCommonService.getListFromArray(next['unitofmeasurement']));
        });
    }

    //On click of Delivery Schedule form Button

  onClickButton(value: any) {
    if(value['buttonHeader'] === 'View Delivery Schedule'){
      this.formRowData = null;
        if(value['rowData']['packageType'] === 'Bulk') {
          if(value['rowData']['quantity'] !== undefined && value['rowData']['quantity'] !== null && value['rowData']['quantity'].toString().length > 0 &&
              value['rowData']['quantityPeriodicity'] !== undefined && value['rowData']['quantityPeriodicity'] !== null &&
              value['rowData']['periodEndDate'] !== undefined && value['rowData']['periodEndDate'] !== null && value['rowData']['periodEndDate'].toString() !== '' &&
              value['rowData']['profitcenter'] !== undefined && value['rowData']['profitcenter'] !== null && value['rowData']['profitcenter'].toString() !== '' &&
              value['rowData']['commodity'] !== undefined && value['rowData']['commodity'] !== null && value['rowData']['commodity'].toString() !== '' &&
              value['rowData']['periodStartDate'] !== undefined && value['rowData']['periodStartDate'] !== null && value['rowData']['periodStartDate'].toString() !== '') {
            this.formRowData = value['rowData'];
            this.deliveryRows = value['rowData']['deliverySchedule'];
            this.scheduleForm = true;
          } else {
            this.showToast('Details required are missing','error');
          }
        } else if(value['rowData']['packageType'] === 'Container'){
          if(value['rowData']['externalPackageUnit'] !== undefined && value['rowData']['externalPackageUnit'] !== null && value['rowData']['externalPackageUnit'].toString().length > 0 &&
              value['rowData']['externalPackage'] !== undefined && value['rowData']['externalPackage'] !== null &&
              value['rowData']['quantityPeriodicity'] !== undefined && value['rowData']['quantityPeriodicity'] !== null  &&
              value['rowData']['periodEndDate'] !== undefined && value['rowData']['periodEndDate'] !== null && value['rowData']['periodEndDate'].toString() !== '' &&
              value['rowData']['profitcenter'] !== undefined && value['rowData']['profitcenter'] !== null && value['rowData']['profitcenter'].toString() !== '' &&
              value['rowData']['commodity'] !== undefined && value['rowData']['commodity'] !== null && value['rowData']['commodity'].toString() !== '' &&
              value['rowData']['periodStartDate'] !== undefined && value['rowData']['periodStartDate'] !== null && value['rowData']['periodStartDate'].toString() !== '') {
            this.formRowData = value['rowData'];
            this.deliveryRows = value['rowData']['deliverySchedule'];
            this.scheduleForm = true;
          } else {
            this.showToast('Details required are missing','error');
          }
        } else if(value['rowData']['packageType'] === 'Unit'){
          if(value['rowData']['internalPackageUnit'] !== undefined && value['rowData']['internalPackageUnit'] !== null && value['rowData']['internalPackageUnit'].toString().length > 0 &&
              value['rowData']['internalPackage'] !== undefined && value['rowData']['internalPackage'] !== null &&
              value['rowData']['quantityPeriodicity'] !== undefined && value['rowData']['quantityPeriodicity'] !== null &&
              value['rowData']['periodEndDate'] !== undefined && value['rowData']['periodEndDate'] !== null && value['rowData']['periodEndDate'].toString() !== '' &&
              value['rowData']['profitcenter'] !== undefined && value['rowData']['profitcenter'] !== null && value['rowData']['profitcenter'].toString() !== '' &&
              value['rowData']['commodity'] !== undefined && value['rowData']['commodity'] !== null && value['rowData']['commodity'].toString() !== '' &&
              value['rowData']['periodStartDate'] !== undefined && value['rowData']['periodStartDate'] !== null && value['rowData']['periodStartDate'].toString() !== '') {
            this.formRowData = value['rowData'];
            this.deliveryRows = value['rowData']['deliverySchedule'];
            this.scheduleForm = true;
          } else {
            this.showToast('Details required are missing','error');
          }
        }
    } else {
      if (value['buttonHeader'] === 'Edit/View') {
        this.grid.removeAllExtraButtons();
      } else {
        if (value['screenName'] === 'Attachment') {
          let name = value['rowData']['attachmentFileName'];
          let url = environment.base_url_new + '/api-bm' + value['rowData']['attachmentUrl'];
          if (value['buttonHeader'] === 'Download') {
            window.open(url, '_blank');
          } else if (value['buttonHeader'] === 'Print') {
            let printurl = 'https://docs.google.com/viewerng/viewer?url=' + url;
            window.open(printurl, '_blank');
          } else {
            this.docUrl = url;
            this.docName = name;
            this.showDoc = true;
          }
        }
      }
    }

  }

  onUniqueClick(value: any) {
    this.isClickOnContext = false;
    //this.addExtraButtons(value['rowData']);
    this.currentRow = value['rowData'];
    this.updateTabs(value['rowData']);
    this.getDataOnLoad();
  }

  onClose() {
    this.docUrl = '';
    this.showDoc = false;
  }

  onSave(value: any) {
    this.grid.removeAllExtraButtons();
    if (value['label'] === 'Trade') {
      //this.addExtraButtons(value['result']);
    }
    this.getListForClause();
  }


  onNoteSave(value: any, noteTitle) {
    this.noteState = false;
    this.noteTitle = '';
    if (noteTitle === 'Reason for Void') {
      this.performAction(this.dataToSave['tradeId'],'void',value);
    } else if (noteTitle === 'Reason for Exceptions') {
      this.performAction(this.dataToSave['tradeId'],'approveFinanceWithException',value);
    }
  }

  onDismiss() {
    this.noteState = false;
    this.noteTitle = '';
  }

  refreshdata() {
    this.refreshGrid=false;
  }



  private updateTabs(value: any) {
    this.tabs = [];
    this.tabs.push(this.getQualitySpecification((value['tradeStatus'].toLowerCase() === 'draft')));
    this.tabs.push(this.getCost(value['tradeSettlementCurrency'], value['quantityUom'],true));
    this.tabs.push(this.getAttachment());
    this.grid.setTabs(this.tabs);
  }

  private getToleranceTypeList() {
    return [new KeyValue('Percentage', 'percentage'), new KeyValue('Weight', 'weight')];
  }

  private getIntExt() {
    return [new KeyValue('Yes', true), new KeyValue('No', false)];
  }

  private getBuySell() {
    return this.masterCommonService.getBooleanFromLabel('Buy','Sell',false,'BUY','SELL');
  }


  showToast(summary,severity:string = 'info') {
        this.messageService.add({
            severity: severity,
            summary: summary
        });
  }

  applyNotifFilter(){
    this.route
        .queryParams
        .subscribe(params => {
          this.filterValue = params['value'];
          if (this.grid !== undefined && this.grid !== null && this.filterValue !==undefined && this.filterValue !== null && this.filterValue !== '') {
            this.grid.removeFilter('tradeId');
            this.grid.addInFilter(new Filter('Trade ID','tradeId','equals',this.filterValue,'','T',this.grid.getColumn().get('tradeId')));
          }
        });
  }

  private getInternalPackageType() {
    return [new KeyValue('Bulk/Break-Bulk','Bulk'),new KeyValue('Container/Packed','Unit')];
  // ,new KeyValue('Container','Container')
  }


  onDraftClose() {
    this.displayInvoiceForm = false;
  }

  onGenerateDone() {
    this.showToast("Invoice Generated Successfully",'success')
    this.displayInvoiceForm = false;
  }

  onCloseDeliveryScheduleForm(value:any) {
    this.scheduleForm = false;
    this.scheduleRows = value['value'];
    let changedTotalQuantity = value['totalquantity'];
    let changedTotalUnit = value['totalUnit'];
    let control:FormGroup = this.tradeForm['inputGrid']['gridinputform']['formGroup']['controls']['totalTradeQty'];
    control.setValue(changedTotalQuantity);
    let controlUnit:FormGroup = this.tradeForm['inputGrid']['gridinputform']['formGroup']['controls']['totalUnits'];
    controlUnit.setValue(changedTotalUnit);
  }

  onFormClose(){
    this.scheduleForm = false;
  }

  cancelBulkOp() {
    this.bulkOpPaneDisplay = !this.bulkOpPaneDisplay;
  }

  bulkTradeOperation(operation: string = 'traderApproval') {
    let _this = this;
    this.refreshGrid = false;
    let payloadForBulkOperation = {
      tenantId: this.commonService.getFromStorage('tenantId'),
      userName:this.commonService.getFromStorage('userName'),
      reason:'',
      canFinanceApprove:(this.canFinanceApproval() || this.canFinanceApprovalWithException())
    };

    this.bulkProcessingDisplay  = true;
    this.selectedRows.forEach((row, index) =>{
      if(operation === 'void'){
        _this.showToast('Trade cannot be void after its confirmed', 'error');
        _this.bulkProcessingDisplay = false;
      } else {
        payloadForBulkOperation['tradeId'] = row['tradeId'];
        this.commonService.post(environment.base_url+'/api/trade/v1/physicaltradeapprovals?tenantId='
            +this.commonService.getFromStorage('tenantId')+'&action='+operation,payloadForBulkOperation).subscribe((next:any) => {
          _this.bulkProcessList[index]['processResult'] = 'Success';
          _this.refreshGrid = true;

          if (index === this.selectedRows.length - 1){
            this.bulkProcessingDisplay  = false;
            this.bulkProcessList = [];
            this.selectedRows = [];
            this.bulkOpPaneDisplay = false;
          }


        }, (error: HttpErrorResponse) => {
          _this.bulkProcessList[index]['processResult'] = 'Failed';
          _this.refreshGrid = true;
          this.bulkProcessingDisplay  = false;
          let errMsg = _this.commonService.getHttpErrorMessage(error,'Trade');
          if (errMsg.includes('Failed to send Notification')){
            _this.showToast(errMsg, 'warn');
          }else {
            _this.showToast(errMsg, 'error');
          }
        });

      }

    });
  }

  getProcessIconClass(process: string) {
    if (process === 'Success'){
      return 'fas fa-check-circle green';
    }else{
      return 'fas fa-times-circle red';
    }
  }

  getProcessColor(process: string) {
    return process === 'Success'?'color:green':'color:red';
  }

  onChangeObjectCreated(obj: any) {
    this.changeObject = JSON.parse(JSON.stringify(obj));
  }

  onCloseCost() {
    this.refreshTab = 'Cost';
    this.showAddCost=false;
    this.costObject = null;
  }

  onTabRefreshComplete() {
    this.refreshTab = '';
  }
  onHover(value: any) {
    this.dbResponse = [];
    let panel:OverlayPanel = value['panel'];
    let tradeId:any = value['rowData']['tradeId'];
    try {
      if (value['dbresponse'] !== null) {
        this.dbResponse = value['dbresponse'][0]['workflowSteps'].sort((a, b) => parseFloat(a.sequence) - parseFloat(b.sequence));
      }
    } catch (e) {
      if(this.hovertradeId !== tradeId ) {
        if(this.overlayPanelTemp !== null && this.overlayPanelTemp !== undefined) {
          this.overlayPanelTemp.hide();
        }
      }
    }
    if(this.hovertradeId !== tradeId ) {
      if(this.overlayPanelTemp !== null && this.overlayPanelTemp !== undefined) {
        this.overlayPanelTemp.hide();
      }
    }

    this.hovertradeId = tradeId;
    this.overlayPanelTemp = panel;
  }

  getApproveUserId(userId: string){
    return (userId === undefined || userId === null || userId.length === 0)? '-': userId;
  }

  getApproveDate(stepObj){
    if(stepObj['updateStatus'] === 'Pending'){
      return this.commonService.getFormattedDate(stepObj['estimated']);
    } else{
      return this.commonService.getFormattedDate(stepObj['updateDate']);
    }
  }

  getApproveIconClass(stepObj){
    if (stepObj['updateStatus'] !== 'Pending'){
      if (new Date(stepObj['approvedDate']).getTime() > new Date(stepObj['estimateDate']).getTime()){
        return "fas fa-clock approveIcon text-warning";
      } else {
        if (stepObj['stepImpact'] === 'positive' || stepObj['stepImpact'] === 'neutral'){
          return "fas fa-check-circle approveIcon text-success";
        }
        return "fas fa-times-circle approveIcon text-danger";
      }
    } else{
      return "fas fa-clock approveIcon text-muted";
    }
  }

  checkDBResponse(){
    return this.dbResponse == null || this.dbResponse.length === 0;
  }

  statusInformation(rowData){
    return rowData.tradeStatus;
  }

  onContextItemClick(item: any) {
    let title = item.title;
    let rowData = item.data;
    this.currentRow = rowData;
    let _this = this;
    if(title === tradeOptionsButtons.addCost) {
      let tradeId = rowData['tradeId'];
      if(tradeId !== null && tradeId !== undefined && tradeId.length > 0) {
        this.linkTo=tradeId;
        this.paymentDueDate=rowData['periodEndDate'];
        this.showAddCost=true;
      }
    }
    else if( title === tradeOptionsButtons.generateDocument){
       this.generateDocument = true;
       this.generateDocumentRowData=rowData;
    }
    else if(title === tradeOptionsButtons.generateProFormaInvoice) {
      _this.proFormaInvoiceRow = rowData;
      _this.generateAdvancedInvoiceForm(rowData,(title === tradeOptionsButtons.generateProFormaInvoice));
    }
    else if(title === tradeOptionsButtons.generateAdvancedInvoice){
      if(rowData['tradeStatus'] === "Confirmed"){
        _this.proFormaInvoiceRow = rowData;
        _this.generateAdvancedInvoiceForm(rowData,(title === tradeOptionsButtons.generateProFormaInvoice));
      }else {
        this.showToast("Confirm the trade to generate Invoice","error");
      }

    }
  }

  closeModal() {
    this.documentType='';
    this.documentFormat ='';
    this.documentTemplate=[];
    this.generateDocument = false;
  }
  getDocumenttypeList(value) {
    let _this = this;
    _this.commonService.getJSONByURL(environment.base_url_new + '/api-bm/api/doctemplateservice/v1/getdocumentbybusinessname?tenantId='+this.commonService.getFromStorage('tenantId') + '&businessApplicationName=' + value).subscribe((next: any) => {
      _this.documentTemplateList = _this.commonService.createListFromObject(next, 'docTemplateName', 'docTemplateName', false);
    });
  }

  getDocFormatList(){
    if(this.documentType !== null && this.documentTemplate !== null && this.documentType !== undefined && this.documentTemplate !== undefined && this.documentType !== '' && this.documentTemplate.length > 0){
      let _this = this;
      let payload = [];
      payload.push({fieldName:"tenantId",condition:"equals",value:this.commonService.getTenantId()});
      payload.push({fieldName:"businessApplication",condition:"equals",value:this.documentType});
      payload.push({fieldName:"docTemplateName",condition:"equals",value:this.documentTemplate[0].value});
      payload.push({fieldName:"status",condition:"equals",value:true});
      _this.commonService.post(environment.base_url_new + '/api-bm/api/doctemplateservice/v1/getDocumentTemplateFormat?tenantId='+this.commonService.getFromStorage('tenantId'),payload).subscribe((next:any)=>{
        const DocFormat = _this.commonService.createListFromObject(next, 'allowedOutputFormats', 'allowedOutputFormats', false);
        let tempFormat=JSON.parse(DocFormat[0].value);
        _this.allowFormatEnable = tempFormat.selected.length < 2;
        this.documentFormatList = [];
        tempFormat['selected'].forEach(e=>{
          this.documentFormatList.push({label:e,value:e})
        })
        this.documentFormat=tempFormat['default']
      });
    }
  }

  onChangeInstruction(value: any) {
      this.documentTemplateList = [];
      this.documentTemplate = [];
      this.getDocumenttypeList(value);
      this.documentTypeDisabled = false;
      this.shippingFormButtonLabel = 'Generate';
  }
  generateDocumentFunc(){
    this.loadingDialogDisplay = true;
    this.generateDocumentRowData['templateList'] = this.documentTemplate.map(e => e.value);
    this.commonService.getJSONByObject(environment.base_url + '/api/trade/v1/generatetradedocument?tenantId='+
        this.commonService.getTenantId() + "&outputFormat=" + this.documentFormat,this.generateDocumentRowData).subscribe((next:any) =>{
        this.generateDocument = false;
        this.loadingDialogDisplay = false;
        window.open(environment.base_url + next['status']);
    },(error) => {
          this.loadingDialogDisplay = false;
          this.messageService.add({severity:'error', summary:error['error']['status'], detail:'Failed to generate document'});
    });
   }
  disableGenerate() {
    if(this.documentType != '' && !(this.documentTemplate.length>0)){
      return false;
    }
    else return !(this.documentType != '' && this.documentTemplate.length > 0);
  }

  onClauseFormCancel() {
    this.checkBoxEnable = false;
    this.searchCriteriaList=[];
  }

  checkBoxAddRemove(arr, item) {
    if (arr.includes(item)) {
      const itemIndex = arr.findIndex(e => e === item);
      arr.splice(itemIndex, 1);
      return arr;
    }
    arr.push(item);
    return arr;
  }
  onrowSelect(value: any) {
    this.checkBoxAddRemove(this.selectedClauseValue, value.data);
  }

  onRowUnselect(value: any, table: Table) {
    table.selectionKeys = {};
    this.checkBoxAddRemove(this.selectedClauseValue, value.data);
 }

  onCancel() {
    this.checkBoxEnable = false;
    this.searchCriteriaList = [];
    this.selectedClauseValue = [];
    this.rowselectClauseDesc = [];
  }
  onclickClause() {
    if((((this.documentType =='Long Form Contract') ||(this.documentType =='Short Form Contract'))&& this.documentType) && (this.documentTemplate.length>0)){
     this.getCluasewithCriteria(this.generateDocumentRowData);
     this.checkBoxEnable = true;
   }else {
     this.checkBoxEnable = false;
   }
  }

  onProceed() {
    this.selectedClauseValue.map(data => {
      // To check the Data is Modiefied or Not .. clusecopy data is getting from combineLatestmethod.. if modified setting true if not false
      // Both clusecopy and clauseMasterDetails Variable are same to check if its modified or not  for that making a copy
      // Because clauseMasterDetails changes made in table it also track in these variable .. creating new copy making stringfy and parse and using it
      let filterData = this.clauseJsonCopy.find(e => e.uuid == data.uuid);
      if ((filterData.clauseCode == data['clauseCode']) && (filterData.clausePrintSequence == data['clausePrintSequence']) && (filterData.clauseDescription == data['clausePrintSequence'])) {
        data['modified'] = false;
      } else {
        data['modified'] = true;
      }

      //setting the tradeId from the TradeRow Data
      data['tradeId'] = this.generateDocumentRowData['tradeId'];
      data['clauseName'] = data['clauseCode'];
      //Intially their wont be any clauseMasterUuid for to save clause capture row data uuid is the clauseMasterUuid
      //after saving again if user click on the context menu clauseMasterUuid as to it
      if (data['clauseMasterUuid']) {
        data['clauseMasterUuid'] = data['clauseMasterUuid'];
      } else {
        data['clauseMasterUuid'] = data['uuid'];
      }
    });
    this.commonService.post(environment.base_url + '/api/clauseCapture/createClauseCapture', this.selectedClauseValue).subscribe((data: any) => {
      if(data && data.length>0){
        this.generateDocumentRowData['clauseDataList'] = data;
        this.generateDocumentFunc();
      }
      this.checkBoxEnable = false;
      this.generateDocument = false;
      this.searchCriteriaList = [];
      this.rowselectClauseDesc = [];
      this.selectedClauseValue = [];
    });
  }

  getCluasewithCriteria(value: any) {
    this.searchCriteriaList.push(new SearchCriteria('tenantId', this.commonService.getFromStorage('tenantId'), 'equals'));
    this.searchCriteriaList.push(new SearchCriteria('company', value.company, 'equalsOrNull'));
    this.searchCriteriaList.push(new SearchCriteria('counterParty', value.counterparty, 'equalsOrNull'));
    // this.searchCriteriaList.push(new SearchCriteria('counterpartyCode',value.counterpartyCode,'equalsOrNull'));
    this.searchCriteriaList.push(new SearchCriteria('commodity', value.commodity, 'equalsOrNull'));
    this.searchCriteriaList.push(new SearchCriteria('incoterm', value.incoterm, 'equalsOrNull'));
    this.searchCriteriaList.push(new SearchCriteria('country', value.location, 'equalsOrNull'));
    this.searchCriteriaList.push(new SearchCriteria('clauseCategory', 'Contract', 'equals'));

    //clausemaster data
    const sub1$ = this.commonService.post(environment.base_url_new + '/api-bm/api/clauseMaster/searchWithData', this.searchCriteriaList);
    //clauseCapture data
    const sub2$ = this.commonService.getJSONByURL(environment.base_url + `/api/clauseCapture/getwithtradeId?tradeId=${value.tradeId}`);

    combineLatest([sub1$, sub2$]).subscribe((resp: any) => {
      const [clauseMaterArr, tradeClauseCaptureArr] = resp as any[][];
      clauseMaterArr.forEach((clauseMaterData, index) => {
          if(tradeClauseCaptureArr.length>0){
              const tradeClauseCaptureData = tradeClauseCaptureArr.find(e => e.clauseMasterUuid === clauseMaterData.uuid);
              if (tradeClauseCaptureData) {
                  tradeClauseCaptureData.clauseMasterUuid = clauseMaterData.uuid;
                  clauseMaterData.clauseCode = tradeClauseCaptureData.clauseName;
                  clauseMaterArr[index] = {...clauseMaterArr[index], ...tradeClauseCaptureData};
              }
          }
      });
      this.clauseJsonCopy = JSON.parse(JSON.stringify(clauseMaterArr));
      this.clauseMasterDetails = clauseMaterArr;
    });
  }

  getFilterValues() {
    this.commonService.getJSONByURL(environment.base_url + '/api/trade/v1/getphysicaltradebyfilters?tenantId='
        + this.commonService.getFromStorage('tenantId') + '&tradeType=Physical').subscribe((next: any[]) => {
      this.filterTradeList = next;
    });
  }


}
