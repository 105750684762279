import {Tab} from "./Tab.model";

export class TabList {
  private list: Map<any, Tab[]>;

  constructor() {
    this.list = new Map();
  };

  public getTabs(key: any) {
    let tabs: Tab[] = [];
    if (this.list.get(key) !== undefined) {
      tabs = this.list.get(key);
    }
    return tabs;
  }

  public addTab(key: any, tab: Tab) {
    this.list.set(key, this.getUpdatedTabList(key, tab));
  }

  public clear() {
    this.list.clear();
  }

  public updateTabAtIndex(key: any, index: number, tab: Tab) {
    this.list.set(key, this.getUpdatedTabListwithIndex(key, tab, index));
  }

  public getTabAtIndex(key: any, index: number) {
    return this.getTabs(key)[index];
  }

  private getUpdatedTabList(key, tab): Tab[] {
    let tabs = this.getTabs(key);
    tabs.push(tab);
    return tabs;
  }

  private getUpdatedTabListwithIndex(key: any, tab: Tab, index: number) {
    let tabs = this.getTabs(key);
    tabs.splice(index, 1);
    tabs.splice(index, 0, tab);
    return tabs;
  }
}
