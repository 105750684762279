import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => XcelerDualSliderComponent),
  multi: true
};

@Component({
  selector: 'xceler-dual-slider',
  templateUrl: './xceler-dual-slider.component.html',
  styleUrls: ['./xceler-dual-slider.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class XcelerDualSliderComponent implements OnInit, ControlValueAccessor, OnChanges,AfterViewInit {

  @Input('value') val: any;
  @Input('name') name: string = '';
  @Input() inString: boolean = false;
  @Input() disabled: boolean = false;
  @Input() labelLowFormatLeft: string = '<value>';
  @Input() labelHighFormatLeft: string = '<value>';
  @Input() labelFormatLeft: string = '<value>';
  @Input() labelLowFormatRight: string = '<value>';
  @Input() labelHighFormatRight: string = '<value>';
  @Input() labelFormatRight: string = '<value>';
  @Input() floorLeft: number = 0;
  @Input() floorRight: number = 0;
  @Input() ceilLeft: number = 100;
  @Input() ceilRight: number = 100;
  manualRefreshEnabled: boolean = true;
  manualRefresh: EventEmitter<void> = new EventEmitter<void>();
  output: any = {min: 0, max: 0};

  constructor(private changeDetectorRef:ChangeDetectorRef) {
  }

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  onTouched: any = () => {
  };

  onChange(val) {
    this.val = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    if (obj) {
      this.convertIntoInputFormat(obj);
      this.value = obj;
    }
  }

  onChangeLeft(value: any) {
    this.output['min'] = value;
    this.setValueToSliderValue();
  }

  onChangeRight(value: any) {
    this.output['max'] = value;
    this.setValueToSliderValue();
  }

  setValueToSliderValue() {
    if (this.inString) {
      this.value = JSON.stringify(this.output);
    } else {
      this.value = this.output;
    }
  }

  refreshManually(): void {
    if (this.manualRefreshEnabled) {
      this.manualRefresh.emit();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refreshManually();
  }

  private convertIntoInputFormat(value: any) {
    if (this.inString) {
      this.output = JSON.parse(value);
    } else {
      this.output = value;
    }
  }
}
