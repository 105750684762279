<p-multiSelect (change)="onChange"
               (onChange)="onItemSelect($event)"
               (touch)="onTouched"
               [(ngModel)]="selectedItems"
               [disabled]="disabled"
               [ngClass]="msClass"
               [options]="dropDownList"
               [selectionLimit]="!multiple?1:null"
               appendTo="body"
               [filter]="allowSearch"
               [defaultLabel]="placeholder"
></p-multiSelect>
