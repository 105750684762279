<ctrm-grid *ngIf="loadDataList !== undefined && loadDataList.length != 0"
           (heirarchyEmitter)="openHeirarcy($event)"
           [breadcrumbMapKey]="commonService.MASTER_MAP_KEY"
           [currentRoute]="'/location'"
           [grid]="grid"
           [showStandardFilter]="true">
</ctrm-grid>

<heirarchy-view (onClose)="closeBtn()"
                *ngIf="modalStateHierarchy"
                [entityName]="getEntityName()"
                [nameField]="'name'"
                [primaryKey]="'name'"
                [rowData]="selectedRowData"
                [titlePrefix]="'Location Hierarchy'"
                [visible]="modalStateHierarchy">
</heirarchy-view>
