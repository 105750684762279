<div style="display: inline-flex;width: 100%" xmlns="http://www.w3.org/1999/html">
    <masters-sidenav></masters-sidenav>
    <div id="pinwidth" style="width: 100%;margin-left: 80px">
        <div class="sectionCardTop">
            <div class="breadcrumbPane">
                <ctrm-breadcrumb [collapsabel]="false"
                         [items]="breadCrumbService.getBreadCrumb()"
                         [showIcon]="false"></ctrm-breadcrumb>
            </div>
            <div class="actionPane">
                <div class="screenDetails">
                    <img [src]="'../../../assets/images/svg/Approval.svg'"/>
                    <p>Approval Workflow</p>
                </div>
            </div>
        </div>
<!--        form content-->
        <div [formGroup]="workFlow" style="display: inline-flex;width:100%;background: #FFFFFF;padding: 5px;margin-left: 7px;margin-right: 7px">

            <div style="background: #F2F2F28F;width:20%;">
                <div >
                    <p style="padding-left: 20px;font-weight: bold; padding-top: 10px;">Edit Workflow</p>
                </div>
                <div class="fieldBox">
                    <label class="label ng-star-inserted">Name:</label>
                    <input  type="text"
                           class="form-control form-control-sm input"
                           pInputText placeholder="WorkFlowName"
                           formControlName="workflowName">
                </div>
                <div class="fieldBox" style="margin-right: 20px;">
                    <label class="label ng-star-inserted">Trigger Event:</label>
                    <input type="text"
                           class="form-control form-control-sm input"
                           placeholder="Trigger Event"
                           formControlName="triggerEvent">
                </div>
                <div class="fieldBox" style="margin-right: 20px;">
                    <label class="label ng-star-inserted">Approver Span:</label>
                    <p-dropdown
                            [disabled]="false"
                            appendTo="body"
                            tooltipEvent="focus" tooltipPosition="bottom"
                            tooltipZIndex="1000"
                            formControlName="approvalSpan"
                            placeholder="select"
                            [options]="approverSpanList">
                    </p-dropdown>
                </div>
                <div class="fieldBox">
                    <label class="label ng-star-inserted">Post Action:</label>
                    <input  type="text"
                           class="form-control form-control-sm input"
                           pInputText placeholder="Post Action"
                           formControlName="postAction">
                </div>

                <div class="fieldBox">
                    <label class="label ng-star-inserted">Negative Post Action:</label>
                    <input  type="text"
                            class="form-control form-control-sm input"
                            pInputText placeholder="Negative Post Action"
                            formControlName="negativePostAction">
                </div>

                <div class="fieldBox" style="margin-left: 10px; display: flex; margin-top: 5px">
                    <p-checkbox  style="display: inline-flex;width: 170px"
                                 [disabled]="true"
                                 [binary]="true" [label]="'Is System'"
                                 formControlName="isSystem">

                    </p-checkbox>
                </div>
                <div class="fieldBox" style="margin-left: 10px; display: flex; margin-top: 5px">
                    <p-checkbox  style="display: inline-flex;width: 170px"
                                 [binary]="true" [label]="'Is Active'"
                                 formControlName="isActive">

                    </p-checkbox>
                </div>


            </div>
            <div style="width:80%;margin-left: 10px;margin-right: 10px;">
                <app-ctrm-price-table
                        [columns]="approvalrolecolumns"
                        [gridDisplay]="true"
                        [showNewEntryIcon]="true"
                        [checkValidityBeforeAddingNewRow]="true"
                        [customTemplateFields]="['desiredEndStatus']"
                        [customTemplate]="[desiredEndStatus]"
                        (onFormGroupCreated)="updateFormGroup($event)"
                        [value]="values">
                </app-ctrm-price-table>
                <div >
                    <button  (click)="oncancel()" class="cancelBtn">Cancel</button>
                    <button (click)="onSave()"  class="planScreenBtns" [disabled]="!(workFlow.valid && values.length>0 && formGroup.valid)">Save</button>
                </div>
            </div>

        </div>

<!--        form content end-->
    </div>
</div>

<ng-template #desiredEndStatus let-formControl="formControl" let-index="index">

    <button style="background-color: #15104e;color: white;padding: 5px;width: 70%;border-radius: 4px" (click)="onClick(index)">Add Actions</button>

</ng-template>

<app-desired-end-status *ngIf="display"
[display]="true"
[rowData]="rowdata"
(onClose)="onclose()"
(onSave)="onStatussave(rowdata)">

</app-desired-end-status>

<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>

