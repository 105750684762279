<div *ngIf="false" style="display: inline-flex">
  <div *ngIf="blockLists.length>0" class="ui-g"
       style="overflow-y: auto">
    <ctrm-chips (OnRemove)="removeBlock($event,i)" *ngFor="let chip of blockLists;index as i" [chip]="chip['value']"
                [filter]="chip"
                [position]="i"></ctrm-chips>
  </div>
</div>
<input #inputbox
       (change)="onChange" (keydown)="onKeyPressed($event,inputbox)"
       (touch)="onTouched"
       [(ngModel)]="value"
       [disabled]="disabled"
       [name]="name"
       [ngClass]="sgClass"
       [type]="type"
       class="={{sclass}}"
       pInputText
       placeholder="{{placeholder}}">
<div (mouseenter)="onDropDown=true"
     (mouseleave)="onDropDown=false"
     *ngIf="displaySuggestion && suggestions!==undefined && suggestions.length>0"
     class="suggestionBox style-1">
  <option (click)="update(suggestion)"
          *ngFor="let suggestion of suggestions,index as i"
          [id]="i+'option'"
          [ngClass]="currentFocus===i?'active':''"
          class="option">{{suggestion['label']}}</option>
  <div style="min-width: 60px;"></div>
</div>
<div *ngIf="displaySuggestion && (suggestions===undefined || suggestions.length==0)" class="suggestionBox1 style-1">
  <div style="text-align: center;font-size: 15px;font-weight: bold;margin-top: 10px;color: grey">Nothing to show</div>
</div>
