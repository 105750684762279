<p-dialog (onHide)="closeMatchDialog(true)" [(visible)]="visible" [draggable]="false" [modal]="true" [resizable]="false"
          [style]="{width: 'fit-content',minHeight:'100px'}">
  <p-header>
    <div style="display: flex; padding-left: 16px">
      <i class="far fa-check-square headerIconStyle"></i>
      <p class="matchHeader">{{heading}}</p>
    </div>

  </p-header>
  <div style="display: flex;flex-direction: column;min-height: 100px">
    <div class="infoContainer">
      <div *ngIf="warningMessages.length > 0" style="padding-bottom: 8px">
        <div class="warningClass">
          <i class="fas fa-exclamation-circle"></i>
          <p>WARNING</p>
        </div>
        <p *ngFor="let msg of warningMessages" class="infoMessageClass">{{msg}}</p>
      </div>
      <div *ngIf="errorMessages.length > 0">
        <div class="errorClass">
          <i class="fas fa-times-circle"></i>
          <p>ERROR</p>
        </div>
        <p *ngFor="let msg of errorMessages" class="infoMessageClass">{{msg}}</p>
      </div>
    </div>
    <p *ngIf="bodyMessage !== null && bodyMessage !== undefined && bodyMessage.length > 0" class="infoMessageClass" style="font-weight: bold">{{bodyMessage}}</p>

  </div>
  <p-footer>
    <div>
      <button (click)="closeMatchDialog()" class="cancelBtn">{{cancelButtonHeader}}</button>
      <button (click)="continuePlanning()" [disabled]="errorMessages.length > 0"
              class="planScreenBtns">{{continueButtonHeader}}</button>
    </div>

  </p-footer>
</p-dialog>
