import {Component, OnInit} from '@angular/core';
import {Grid} from '../../grid/grid.model';
import {Tcolumn} from '../../grid/tcolumn.model';
import {Validators} from '@angular/forms';
import {MasterService} from "../services/MasterService";
import {Action} from "../../grid/actions.model";
import {GridService} from "../../grid/grid.service";
import {environment} from "../../../environments/environment";
import {CommonService} from "../../services/common.service";
import {HttpClient} from "@angular/common/http";
import {MessageService} from "primeng";
import {AccessPolicyService} from "../../services/accesspolicy.service";

@Component({
  selector: 'app-rolemaster',
  templateUrl: './rolemaster.component.html',
  styleUrls: ['./rolemaster.component.css'],

  providers: [MessageService]
})
export class RolemasterComponent implements OnInit {
  grid: Grid;
  columns: Map<string, Tcolumn>;
  countnou: Function;
  display: boolean = false;
  data: any;
  file: any[] = [];
  jsonFileFormat: any = [];
  selectedRowData: any[];
  recieved: any = [];
  inputData: any;
  canEdit: boolean = true;

  constructor(private messageService: MessageService, private masterCommService: MasterService, public commonService: CommonService, private accessService:AccessPolicyService) {
  }

  ngOnInit() {
    this.getColumns();
    this.grid = new Grid(this.columns, true, true, true, true, 'roleName', '/api/rolemaster/v1/getallactiveroles?tenantId=' + this.commonService.getFromStorage('tenantId'), '', 'Role Master', undefined, undefined);
    this.grid.addClickable('roleName');
    this.grid.baseUrl = environment.base_url_new + '/api-iam';
    this.grid.setSaveUrl(environment.base_url_new + '/api-iam/api/rolemaster/v1/saverolemaster');
    this.grid.setUpdateUrl(environment.base_url_new + '/api-iam/api/rolemaster/v1/updaterolemaster');
    this.grid.hrefUrl = environment.base_url_new + '/api-iam/api/rolemaster/v1/getrolebyuuid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&uuid=';
    this.grid.iconName = 'roleMaster';
    this.grid.openSidebarOnUniqueClick = false;
    this.grid.addSection('Role Information', ['roleName', 'description', 'numberOfUsers', 'status']);
    this.grid.importUrl = environment.base_url_new + '/api-iam/role/import';
  }

  public getColumns() {
    this.columns = new Map();
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N', [Validators.required]));
    this.columns.set('roleName', new Tcolumn('roleName', 'Role Name', 'T', 1, false, undefined, true, '', 'B', [Validators.required, Validators.maxLength(50)], 'text', 1, undefined, undefined, undefined));
    this.columns.set('description', new Tcolumn('description', 'Role Description', 'TA', 2, true, undefined, true, '', 'B', [Validators.maxLength(256)]));
    this.columns.set('numberOfUsers', new Tcolumn('numberOfUsers', '# of Users', 'LB', 3, false, undefined, true, 0, 'B', [Validators.required, Validators.maxLength(10)]));
    this.columns.set('policy', new Tcolumn('policy', 'Policy', 'T', 7, true, undefined, false, this.accessService.baseAccessPolicy, 'H'));
    this.columns.set('policyVersion', new Tcolumn('policyVersion', 'Policy Version', 'N', 8, true, undefined, false, this.accessService.baseAccessPolicyVersion, 'H'));
    this.columns.set('status', new Tcolumn('status', 'Status', 'B', 9, true, this.masterCommService.getActiveInactive(), true, true, 'B', [Validators.required]));
  }

  openSidebar(info) {           //for opening Acess Policy Popup
    /*  var roles = this.commonService.getMyRolesString().split(',');

     if( roles.includes(data['roleName']) ){
        this.messageService.add({
         severity: 'info', summary: messages.role_save_not_allowed['summary'],
         detail: messages.role_save_not_allowed['message']
       });
       return;
     } */
    let data = info.rowData;

    let br = data['policy'];
    if (br === null || br == '') {
      br = this.jsonFileFormat;
    }
    this.file = JSON.parse(br);
    this.display = true;
    this.selectedRowData = info.rowData;
  }

  changeDisplay() {
    this.display = false;
  }

  onClickEdit(value: any) {
    this.inputData = value;
  }

  isEditAllowed(value: any) {
    this.canEdit = value;
  }
}
