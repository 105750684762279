<div>
    <div class="mainSection" id="mainSection">
        <div class="navBar container-fluid">
            <div class="brandLogoDiv">
                <img alt="LOGO" src="../../assets/images/xcelerLogo.png" width="20%">
            </div>
            <div class="navLinkDiv row">
                <div class="navLink col"><p class="navLabel"><a href="#mainSection"
                                                                style="color:white; text-decoration: none;">Home</a>
                </p></div>
                <div class="navLink col"><p class="navLabel"><a href="#featureSection"
                                                                style="color:white; text-decoration: none;">Product</a>
                </p>
                </div>
                <div class="navLink col"><p class="navLabel"><a href="#priceSection"
                                                                style="color:white; text-decoration: none;">Pricing</a>
                </p>
                </div>
                <div [hidden]="true" class="navLink col"><p class="navLabel"><a href="#teamSection"
                                                                                style="color:white; text-decoration: none;">Team</a>
                </p></div>
                <div class="navLink col"><p class="navLabel"><a href="#formSection"
                                                                style="color:white; text-decoration: none;">Connect</a>
                </p></div>
                <div>
                    <div (click)="clickLogin()" class="buttonDiv">Login to Xceler.ai</div>
                </div>
            </div>
        </div>
        <div class="fabBtn" id="fabBtn">
            <img alt="Up" src="../../assets/icons/chevronUp.svg" style="padding-top: 9px" width="50%">
        </div>
        <div class="mainBanner">
            <div class="leftSideBanner animate__animated animate__fadeIn">
                <div style="display: inline-flex">
                    <p class="mainLine">Presenting </p>
                    <img alt="LOGO" src="../../assets/images/xcelerLogo.png"
                         style="width: 30%;height: 30%;padding-top: 14px;padding-left: 10px;"
                         width="20%">
                    <p class="sign">!!!</p>
                </div>
                <p class="descLine">Xcelerating Commodity Trade, Risk and Analysis</p>
                <div class="mainHeadBtnSection">
                    <div (click)="scrollToConnect($event)" class="buttonDiv">View Demo
                        <img alt="arrow" class="arrowClass" src="../../assets/icons/arrow_icon.svg" width="25px">
                    </div>
                </div>
            </div>
            <div class="rightSideBanner  animate__animated animate__fadeIn">
                <div class="laptopImg">
                    <img alt="Laptop_Screen" src="../../assets/icons/laptop_screen.svg" width="100%">
                </div>
            </div>
        </div>
    </div>
    <div class="featureSection" id="featureSection">
        <div class="sectionInfo">
            <div class="secHeading">Our Awesome Features</div>
            <div class="secBottomBorder"></div>
            <div class="secDesc">Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem asperiores
                corporis
                ipsa amet, itaque obcaecati maxime iusto nobis accusantium
            </div>
        </div>

        <div class="row">
            <div class="col-4">
                <div class="featureContainer">
                    <img alt="fIcon" src="../../assets/icons/feature_icon.svg" width="20%">
                    <p class="featTitle">Feature 1</p>
                    <p class="featDesc">Lorem ipsum dolor sit amet consectetur adipisicing elit. At molestias voluptas
                        ullam.</p>
                </div>
            </div>
            <div class="col-4">
                <div class="featureContainer">
                    <img alt="fIcon" src="../../assets/icons/feature_icon.svg" width="20%">
                    <p class="featTitle">Feature 1</p>
                    <p class="featDesc">Lorem ipsum dolor sit amet consectetur adipisicing elit. At molestias voluptas
                        ullam.</p>
                </div>
            </div>
            <div class="col-4">
                <div class="featureContainer">
                    <img alt="fIcon" src="../../assets/icons/feature_icon.svg" width="20%">
                    <p class="featTitle">Feature 1</p>
                    <p class="featDesc">Lorem ipsum dolor sit amet consectetur adipisicing elit. At molestias voluptas
                        ullam.</p>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-4">
                <div class="featureContainer">
                    <img alt="fIcon" src="../../assets/icons/feature_icon.svg" width="20%">
                    <p class="featTitle">Feature 1</p>
                    <p class="featDesc">Lorem ipsum dolor sit amet consectetur adipisicing elit. At molestias voluptas
                        ullam.</p>
                </div>
            </div>
            <div class="col-4">
                <div class="featureContainer">
                    <img alt="fIcon" src="../../assets/icons/feature_icon.svg" width="20%">
                    <p class="featTitle">Feature 1</p>
                    <p class="featDesc">Lorem ipsum dolor sit amet consectetur adipisicing elit. At molestias voluptas
                        ullam.</p>
                </div>
            </div>
            <div class="col-4">
                <div class="featureContainer">
                    <img alt="fIcon" src="../../assets/icons/feature_icon.svg" width="20%">
                    <p class="featTitle">Feature 1</p>
                    <p class="featDesc">Lorem ipsum dolor sit amet consectetur adipisicing elit. At molestias voluptas
                        ullam.</p>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-4">
                <div class="featureContainer">

                </div>
            </div>
            <div class="col-4">
                <div class="featureContainer">
                    <img alt="fIcon" src="../../assets/icons/feature_icon.svg" width="20%">
                    <p class="featTitle">Feature 1</p>
                    <p class="featDesc">Lorem ipsum dolor sit amet consectetur adipisicing elit. At molestias voluptas
                        ullam.</p>
                </div>
            </div>

            <div class="col-4">
                <div class="featureContainer">

                </div>
            </div>

        </div>
    </div>
    <div class="priceSection" id="priceSection">
        <div class="sectionInfo">
            <div class="secHeading">Pricing Plans</div>
            <div class="secBottomBorder"></div>
            <div class="secDesc">Lorem Ipsum dolor sit amet, consectetur adipisicne elit verutativ eum msd.</div>
        </div>

        <div class="planBtnSection">
            <div (click)="scrollToConnect($event)" class="buttonDiv">Contact Sales</div>
        </div>

        <div class="plansCardContainer">
            <div class="planCard1">
                <img alt="planIcon1" src="../../assets/icons/plan1_icon.svg" width='30%'>
                <p class="planTitle">Enterprise</p>

            </div>
            <div class="planCard2">
                <img alt="planIcon2" src="../../assets/icons/plan2_icon.svg" width='30%'>
                <p class="planTitle">Buisiness</p>
                <div class="planPointsDiv">
                    <div class="planPoint">
                        <img alt="check" class="chkMark" src="../../assets/icons/checkMark.svg" width="8%">
                        <p class="pointClass">Feature 1</p>
                    </div>
                    <div class="planPoint">
                        <img alt="check" class="chkMark" src="../../assets/icons/checkMark.svg" width="8%">
                        <p class="pointClass">Feature 2</p>
                    </div>
                    <div class="planPoint">
                        <img alt="check" class="chkMark" src="../../assets/icons/checkMark.svg" width="8%">
                        <p class="pointClass">Feature 3</p>
                    </div>
                    <div class="planPoint">
                        <img alt="check" class="chkMark" src="../../assets/icons/checkMark.svg" width="8%">
                        <p class="pointClass">Feature 4</p>
                    </div>

                    <div class="mainHeadBtnSection" style="margin-top: 20px;">
                        <div class="buttonDiv">Purchase
                            <img alt="arrow" class="arrowClass" src="../../assets/icons/arrow_icon.svg" width="25px">
                        </div>
                    </div>

                </div>
            </div>
            <div class="planCard3">
                <img alt="planIcon3" src="../../assets/icons/plan3_icon.svg" width='30%'>
                <p class="planTitle">??????</p>
            </div>
        </div>
    </div>
    <div [hidden]="true" class="teamSection" id="teamSection">
        <div class="sectionInfo">
            <div class="secHeading">Dedicated Team</div>
            <div class="secBottomBorder"></div>
            <div class="secDesc">Lorem Ipsum dolor sit amet, consectetur adipisicne elit verutativ eum msd. Lorem Lorem
                ipsum
                dolor sit, amet consectetur adipisicing elite.
            </div>
        </div>

        <div class="membersContainer">
            <div class="row">
                <div class="memberCard col-2">
                    <img alt="teamMember" class="memberImage" src="../../assets/images/man.svg" width="55%">
                    <p class="memberName">Team Member</p>
                    <p class="designation">Designation</p>
                </div>
                <div class="memberCard col-2">
                    <img alt="teamMember" class="memberImage" src="../../assets/images/man.svg" width="55%">
                    <p class="memberName">Team Member</p>
                    <p class="designation">Designation</p>
                </div>
                <div class="memberCard col-2">
                    <img alt="teamMember" class="memberImage" src="../../assets/images/man.svg" width="55%">
                    <p class="memberName">Team Member</p>
                    <p class="designation">Designation</p>
                </div>
                <div class="memberCard col-2">
                    <img alt="teamMember" class="memberImage" src="../../assets/images/man.svg" width="55%">
                    <p class="memberName">Team Member</p>
                    <p class="designation">Designation</p>
                </div>
                <div class="memberCard col-2">
                    <img alt="teamMember" class="memberImage" src="../../assets/images/man.svg" width="55%">
                    <p class="memberName">Team Member</p>
                    <p class="designation">Designation</p>
                </div>
                <div class="memberCard col-2">
                    <img alt="teamMember" class="memberImage" src="../../assets/images/man.svg" width="55%">
                    <p class="memberName">Team Member</p>
                    <p class="designation">Designation</p>
                </div>
            </div>
            <div class="row">
                <div class="memberCard col-2">
                    <img alt="teamMember" class="memberImage" src="../../assets/images/man.svg" width="55%">
                    <p class="memberName">Team Member</p>
                    <p class="designation">Designation</p>
                </div>
                <div class="memberCard col-2">
                    <img alt="teamMember" class="memberImage" src="../../assets/images/man.svg" width="55%">
                    <p class="memberName">Team Member</p>
                    <p class="designation">Designation</p>
                </div>
                <div class="memberCard col-2">
                    <img alt="teamMember" class="memberImage" src="../../assets/images/man.svg" width="55%">
                    <p class="memberName">Team Member</p>
                    <p class="designation">Designation</p>
                </div>
                <div class="memberCard col-2">
                    <img alt="teamMember" class="memberImage" src="../../assets/images/man.svg" width="55%">
                    <p class="memberName">Team Member</p>
                    <p class="designation">Designation</p>
                </div>
                <div class="memberCard col-2">
                    <img alt="teamMember" class="memberImage" src="../../assets/images/man.svg" width="55%">
                    <p class="memberName">Team Member</p>
                    <p class="designation">Designation</p>
                </div>
                <div class="memberCard col-2">
                    <img alt="teamMember" class="memberImage" src="../../assets/images/man.svg" width="55%">
                    <p class="memberName">Team Member</p>
                    <p class="designation">Designation</p>
                </div>
            </div>

            <div class="row">
                <div class="memberCard col-2">
                    <img alt="teamMember" class="memberImage" src="../../assets/images/man.svg" width="55%">
                    <p class="memberName">Team Member</p>
                    <p class="designation">Designation</p>
                </div>
                <div class="memberCard col-2">
                    <img alt="teamMember" class="memberImage" src="../../assets/images/man.svg" width="55%">
                    <p class="memberName">Team Member</p>
                    <p class="designation">Designation</p>
                </div>
                <div class="memberCard col-2">
                    <img alt="teamMember" class="memberImage" src="../../assets/images/man.svg" width="55%">
                    <p class="memberName">Team Member</p>
                    <p class="designation">Designation</p>
                </div>
                <div class="memberCard col-2">
                    <img alt="teamMember" class="memberImage" src="../../assets/images/man.svg" width="55%">
                    <p class="memberName">Team Member</p>
                    <p class="designation">Designation</p>
                </div>
                <div class="memberCard col-2">
                    <img alt="teamMember" class="memberImage" src="../../assets/images/man.svg" width="55%">
                    <p class="memberName">Team Member</p>
                    <p class="designation">Designation</p>
                </div>
                <div class="memberCard col-2">
                    <img alt="teamMember" class="memberImage" src="../../assets/images/man.svg" width="55%">
                    <p class="memberName">Team Member</p>
                    <p class="designation">Designation</p>
                </div>
            </div>

        </div>
    </div>
    <div class="formSection" id="formSection">
        <div class="sectionInfo">
            <div class="secHeading">Get In Touch</div>
            <div class="secBottomBorder"></div>
            <div class="secDesc">Lorem Ipsum dolor sit amet, consectetur adipisicne elit verutativ eum msd. Lorem Lorem
                ipsum
                dolor sit, amet consectetur adipisicing elite.
            </div>
        </div>

        <div class="formContainer">
            <div class="contactInfoSection">
                <div class="contactCard">
                    <div style="display: flex; text-align: left;">
                        <div><img alt="loc" src="../../assets/icons/location_icon.svg"></div>
                        <div class="addressDetails">
                            <p class="contactTitle">Address</p>
                            <p class="contactDetails">Xceler Pte Ltd,<br/>Singapore</p>
                        </div>
                    </div>
                </div>
                <div class="contactCard">
                    <div style="display: flex; text-align: left;">
                        <div><img alt="ph" src="../../assets/icons/phone_icon.svg"></div>
                        <div class="addressDetails">
                            <p class="contactTitle">Phone</p>
                            <p class="contactDetails">+65 9447 5054</p>


                        </div>
                    </div>
                </div>
                <div class="contactCard" style="border: none;">
                    <div style="display: flex; text-align: left;">
                        <div><img alt="email" src="../../assets/icons/email_icon.svg"></div>
                        <div class="addressDetails">
                            <p class="contactTitle">E - Mail</p>
                            <p class="contactDetails">hey@xceler.ai</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="formInputSection">
                <div class="inputSection">
                    <div class="inputContainer">
                        <input class="inputClass" placeholder="Your Name" type="text">
                    </div>
                    <div class="inputContainer">
                        <input class="inputClass" placeholder="Email ID" type="text">
                    </div>
                </div>
                <div class="inputSection">
                    <div class="inputContainer">
                        <input class="inputClass" placeholder="Subject" type="text">
                    </div>
                    <div class="inputContainer">
                        <input class="inputClass" placeholder="Phone/Mobile Number" type="text">
                    </div>
                </div>
                <div class="inputSection">

                    <textarea class="textAreaClass" placeholder="Message" rows="6"></textarea>

                </div>

                <div class="formSubmitBtn">
                    <div class="buttonDiv">Send Message</div>
                </div>
            </div>
        </div>
    </div>
    <div class="footerSection">
        <div class="footerMainSec row">
            <div class="col-6">
                <div class="footerBrandLogo">
                    <img alt="LOGO" src="../../assets/images/xcelerLogo.png" width="16%">
                </div>

                <div>
                    <p class="footerDesc">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Adipisci,
                        voluptatum. Iure
                        omnis eveniet ipsa dignissimos, labore, minus dicta reprehenderit architecto provident, vero
                        unde facilis ex
                        perspiciatis laborum! A, facere repellendus.</p>
                </div>

                <div class="socialHandleDiv">
                    <img alt="faceBoookLogo" src="../../assets/icons/facebookNew.svg" width="3%">
                    <img alt="twitterLogo" src="../../assets/icons/twitter.svg" style="margin-left: 10px" width="3%">
                </div>
            </div>
            <div class="col-3"></div>
            <div class="col-3"></div>
        </div>
    </div>
    <div class="copyrightSec">
        <p class="copyrightText">&copy; 2020 All Rights reserved by Xceler</p>
    </div>
</div>
