import {Component, OnInit} from '@angular/core';
import {CommonService} from "../../services/common.service";
import {MasterService} from "../services/MasterService";
import {BreadcrumbService} from "../../Components/ctrm-breadcrumb/breadcrumb.service";
import {Tcolumn} from "../../grid/tcolumn.model";
import {Grid} from "../../grid/grid.model";
import {environment} from "../../../environments/environment";
import {Action} from "../../grid/actions.model";
import {Validators} from "@angular/forms";
import {KeyValue} from "../../grid/key-value.model";

@Component({
  selector: 'app-chart-of-accounts',
  templateUrl: './chart-of-accounts.component.html',
  styleUrls: ['./chart-of-accounts.component.css']
})
export class ChartOfAccountsComponent implements OnInit {

  glStructureColumns: Map<string, Tcolumn>;
  glAccountColumns: Map<string, Tcolumn>;
  glStructureGrid: Grid;
  glAccountsGrid: Grid;
  loadDataList: any[];
  loadGLDataList: any[];
  showGlAccounts: boolean = false;
  showGlStructure: boolean = true;
  selected: any = 'glStructure';

  constructor(public commonService: CommonService, private masterCommService: MasterService,public breadCrumbService: BreadcrumbService,
              private masterCommonService: MasterService) {
  }

    ngOnInit(): void {
        this.breadCrumbService.makeBreadcrumbTo(this.commonService.MASTER_MAP_KEY, '/chartOfAccounts');
        this.commonService.getLoadData('commodity', ['data'],
            [[{key: 'name', value: 'general_ledger_type'}]])
            .subscribe((next: any) => {
                this.loadDataList = next;
                this.getGLStructureColumns();
                this.glStructureGrid = new Grid(this.glStructureColumns, true, true, true, true, 'glStructure', '/api/glstructureaccount/v1/getallglstructure?tenantId=' + this.commonService.getFromStorage('tenantId'), '', 'Chart Of Accounts');
                this.glStructureGrid.setSaveUrl(environment.base_url + '/api/glstructureaccount/v1/saveglstructure');
                this.glStructureGrid.setUpdateUrl(environment.base_url + '/api/glstructureaccount/v1/updateglstructure?tenantId=' + this.commonService.getFromStorage('tenantId'));
                this.glStructureGrid.hrefUrl = environment.base_url + '/api/glstructureaccount/v1/getglstructurebyuuid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&uuid=';
                this.glStructureGrid.importUrl = environment.base_url + '/api/glstructureaccount/v1/importglstructure';
            });
        this.getGlStructureData();
    }

    public getGLStructureColumns() {

        let listParentFunc = function (value: any[] = []) {
            let arr: any[] = value[0];
            if (arr.length > 0) {
                arr.splice(0, 1);
                arr.splice(0, 0, new KeyValue('None', 'none'));
            }
            return arr;
        };
        let actionforParent: Action = new Action(environment.base_url + '/api/glstructureaccount/v1/getparentlist?tenantId=' + this.commonService.getFromStorage('tenantId') + '&glStructure=', 'glStructure', 'glStructure');
        actionforParent.setCovertToListRequired(true);
        actionforParent.addFieldToUpdate("parentStructure", undefined, listParentFunc, ['L'], undefined, true, 'listvalues', false, false);
        this.glStructureColumns = new Map();
        this.glStructureColumns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
        this.glStructureColumns.set('glStructure', new Tcolumn('glStructure', 'GL Structure', 'T', 1, false, undefined, true, '', 'B', [Validators.required, Validators.maxLength(100)], undefined, undefined, undefined, undefined, [], actionforParent, undefined, true));
        this.glStructureColumns.set('structureDescription', new Tcolumn('structureDescription', 'Structure Description', 'TA', 2, true, undefined, true, '', 'B', [Validators.required, Validators.maxLength(200)]));
        this.glStructureColumns.set('structureType', new Tcolumn('structureType', 'Structure Type', 'L', 3, true, this.masterCommonService.getListFromArray(this.loadDataList['general_ledger_type']), true, '', 'B', [Validators.required,]));
        this.glStructureColumns.set('parentStructure', new Tcolumn('parentStructure', 'Parent Structure', 'L', 4, true, this.getParent(), true, 'none', 'B', []));
        this.glStructureColumns.set('status', new Tcolumn('status', 'Status', 'B', 5, true, this.masterCommService.getActiveInactive(), true, true, 'B', [Validators.required]));
    }

    public getGLAccountsColumns() {
        this.glAccountColumns = new Map();
        this.glAccountColumns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
        this.glAccountColumns.set('glAccount', new Tcolumn('glAccount', 'GL Account', 'T', 1, false, undefined, true, '', 'B', [Validators.required, Validators.maxLength(100)], undefined, undefined, undefined, undefined, [], undefined, undefined, true));
        this.glAccountColumns.set('accountDescription', new Tcolumn('accountDescription', 'Accounts Description', 'TA', 2, true, undefined, true, '', 'B', [Validators.required, Validators.maxLength(200)]));
        this.glAccountColumns.set('accountType', new Tcolumn('accountType', 'Account Type', 'L', 3, true, this.masterCommonService.getListFromArray(this.loadDataList['general_ledger_type']), true, '', 'B', [Validators.required,]));
        this.glAccountColumns.set('currency', new Tcolumn('currency', 'Currency', 'L', 4, true, this.commonService.getCurrencyList(), true, '', 'B', [Validators.required,]));
        this.glAccountColumns.set('parent', new Tcolumn('parent', 'GL Structure', 'L', 5, true, this.loadGLDataList, true, '', 'B', []));
        this.glAccountColumns.set('status', new Tcolumn('status', 'Status', 'B', 6, true, this.masterCommService.getActiveInactive(), true, true, 'B', [Validators.required]));
    }

    GLStructureGrid() {
        this.getGLStructureColumns();
        this.showGlStructure = true;
        this.showGlAccounts = false;
        this.selected = 'glStructure'
    }

    GlAccountsGrid() {
        this.getGLAccountsColumns();
        this.glAccountsGrid = new Grid(this.glAccountColumns, true, true, true, true, 'glAccount', '/api/glstructureaccount/v1/getallglaccount?tenantId=' + this.commonService.getFromStorage('tenantId'), '', 'Chart Of Accounts');
        this.glAccountsGrid.setSaveUrl(environment.base_url + '/api/glstructureaccount/v1/saveglaccount');
        this.glAccountsGrid.setUpdateUrl(environment.base_url + '/api/glstructureaccount/v1/updateglaccount?tenantId=' + this.commonService.getFromStorage('tenantId'));
        this.glAccountsGrid.hrefUrl = environment.base_url + '/api/glstructureaccount/v1/getglaccountbyuuid?tenantId=' + this.commonService.getFromStorage('tenantId') + '&uuid=';
        this.glAccountsGrid.importUrl = environment.base_url + '/api/glstructureaccount/v1/importglaccount';
        this.showGlStructure = false;
        this.showGlAccounts = true;
        this.selected = 'glAccount'
    }

    private getParent() {
        let parentList = [];
        parentList.push(new KeyValue('None', 'none'));
        return parentList;
    }

    getGlStructureData() {
        let _this = this;
        this.commonService.getJSONByURL(environment.base_url + '/api/glstructureaccount/v1/getallglstructure?tenantId=' +
            this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
            _this.loadGLDataList = this.commonService.createListFromObject(next, 'glStructure', 'glStructure', true);
        });

    }

}
