<div class="style-4 sidenav" id="sidebar" >
    <div *ngFor="let menuItem of menuItems">
        <a *ngIf="(menuItem['childs'] === null || menuItem['childs'] === undefined || menuItem['childs'].length === 0)" [id]="menuItem['key']" [ngClass]="selectMenuItem===menuItem['key']?'selectedItem':''"
           (click)="onClickMenu(menuItem)">
            <p class="menuHeader">{{menuItem['header']}}</p>
        </a>
        <app-childsidemenu
        [menuItem]="menuItem"
        (onClickMenuItem)="onClickMenu($event)"
        >

        </app-childsidemenu>

    </div>
</div>
