<p-dialog [(visible)]="display"
          [closeOnEscape]="false"
          (onHide)="onCancel()"
          [contentStyle]="{ overflow: 'scroll', overflowX:'hidden',height:'100%'}"
          [draggable]="false"
          [maximizable]="false" [modal]="true" [showHeader]="false"
          [style]="{width: '30%',padding:'0px',background:'#ffffff',height:'35%'}">
    <div class="uploadSection">
        <p style="padding-bottom: 10px;font-weight: 600;">Upload Files</p>
        <div class="mainUploadBox">
            <div draggable="true" ngClass="{{dragAreaClass}}" (click)="file.click()">
                <i style="font-size: 30px;padding-top: 20px;padding-bottom: 5px" class="fas fa-upload"></i>
                <div class="row">
                    <div class="col-md-12 text-center">
                        Drag and drop your file here or
                        <a class="browse" href="javascript:void(0)">
                            Browse
                        </a>
                        <input type="file"
                               #file
                               [multiple]="false"
                               (change)="onFileChange($event)"
                               style="display:none"/>
                    </div>
                </div>
            </div>
            <div>
                <label pTooltip="{{fileName}}" tooltipZIndex="99999">{{fileName}}</label>
            </div>
            <div>
                <div class="planScreenBtns" (click)="openUploadFile()">
                    <i style="color: #ffffff !important; padding: 5px" class="fas fa-file-import"></i>
                    <p class="exporttitle">Upload</p>
                </div>
            </div>
            <div class="error" *ngIf="error">
                Only one file at time allow
            </div>
        </div>
    </div>

</p-dialog>

