<div *ngIf="userDetails != undefined && userDetails.length !== 0" class="card-wid">
    <div class="cardTopWrapper">
        <p class="cardHeader">{{widgetProp['name']}} <i class="fas fa-th headerIcon"
                                                        pTooltip="Data View" tooltipZIndex="436"
                                                        tooltipPosition="right" (click)="openDefaultOpenPosDataView()"></i></p>
        <i class="fas fa-times closeIco" (click)="onClickClose()"></i>
        <div *ngIf="isLive && widgetProp['canLive']" class="liveDot" ></div>
        <ui-switch style="padding-left: 10px;" *ngIf="widgetProp['code'] === 'm2mRealizedPnl'" (change)="onToggleChange($event)"
                   [checkedLabel]="'Realized'" [uncheckedLabel]="'MTM'" [checkedTextColor]="'#fff'"
                   [color]="'#00409f'"
                   [uncheckedTextColor]="'#fff'"
                   [defaultBgColor]="'#3D9F00'"
                   [size]="'small'">
        </ui-switch>
        <div *ngIf="companyList.length !== 0 && dropDownCheck()" class="dropDownWrapper">
            <p-dropdown [(ngModel)]="drpValue"
                        (ngModelChange)="onDrpValueChange(getDropDownName(),$event)"
                        [id]=""
                        [options]="getDropDownList()"
                        appendTo="body"
                        pTooltip="" tooltipEvent="focus" tooltipPosition="bottom"
                        tooltipZIndex="1000">
            </p-dropdown>
        </div>

    </div>

    <div *ngIf="chartType === 'stackedBar' && !nothingToShow" class="chartContent">
        <canvas baseChart
                [datasets]="this.widgetProp['code'] === 'unadjustedFutureTrades'? openFutureStackedBarData: stackedBarChartData"
                [labels]="months"
                [options]="stackedBarChartOptions"
                [legend]="true"
                [chartType]="'bar'">
        </canvas>
    </div>

    <div *ngIf="chartType === 'line' && !nothingToShow" class="chartContent">
        <canvas baseChart
                [datasets]="lineChartData"
                [labels]="months"
                [options]="lineChartOptions"
                [legend]="true"
                [chartType]="'line'">
        </canvas>
    </div>

    <div *ngIf="widgetProp['code'] === 'revenueSplit' && !nothingToShow" class="chartContent">
        <canvas baseChart
                [datasets]="revenueSplitData"
                [labels]="months"
                [options]="stackedBarChartOptions"
                [legend]="true"
                [chartType]="'bar'">
        </canvas>
    </div>

    <div *ngIf="widgetProp['code'] === 'openTrades' && !nothingToShow" class="chartContent">
        <canvas baseChart
                [datasets]="openTradesData"
                [labels]="months"
                [options]="lineChartOptions"
                [legend]="true"
                [chartType]="'line'">
        </canvas>
    </div>

    <div *ngIf="widgetProp['code'] === 'deliveryStartedTrades' && !nothingToShow" class="chartContent">
        <canvas baseChart
                [datasets]="deliveryStartedTradesData"
                [labels]="months"
                [options]="stackedBarChartOptions"
                [legend]="true"
                [chartType]="'bar'">
        </canvas>
    </div>

    <div *ngIf="widgetProp['code'] === 'plannedUnplannedTrades' && !nothingToShow" class="chartContent">
        <canvas baseChart
                [datasets]="plannedUnplannedData"
                [labels]="months"
                [options]="lineChartOptions"
                [legend]="true"
                [chartType]="'line'">
        </canvas>
    </div>

    <div *ngIf="widgetProp['code'] === 'pnlDrawdown' && !nothingToShow" class="chartContent">
        <canvas baseChart
                [datasets]="pnlDrawdownData"
                [labels]="months"
                [options]="pnlLineChartOptions"
                [legend]="true"
                [chartType]="'line'">
        </canvas>
    </div>

    <div *ngIf="nothingToShow" class="chartContent">
       <div class="messageClass">
            <p class="message">No Data Available</p>
       </div>
    </div>

</div>


<!--DATA VIEW DIALOG-->

<p-dialog [(visible)]="display" *ngIf="tableDialog"
          [closeOnEscape]="true"
          [resizable]="false"
          [draggable]="false"
          (onHide)="closeTableDialog()"
          [maximizable]="false" [modal]="true" [showHeader]="true" [closable]="false"
          [style]="{zIndex:1000,width: '85%',height: 'auto',minHeight:'80%',maxHeight:'80%', padding:'0px', background:'#ffffff'}">

    <p-header>
        <div class="widgetFormTop">
            <i (click)="closeTableDialog()" class="fas fa-times crossIcon"></i>
        </div>
    </p-header>

    <!--  Download Data Icon-->
    <div class="downloadDataWrapper" (click)="exportExcel()">
        <i class="fas fa-download downloadIco"></i>
        <p class="downloadDataText">Download Data</p>
    </div>
    <div class="dialogWrapper">
        <p-tabView>
            <p-tabPanel *ngIf="dropDownCheck()" header="Open Position">
                <div class="tableWrapper">
                    <div class="viewByWrapper">
                        <p class="viewby">View By</p>
                        <p class="viewby viewbyLabels">Profit Center: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown [options]="profitcenterDataViewList"
                                        [(ngModel)]="pcDataViewValue"
                                        (ngModelChange)="applyDataViewFilter($event)"
                                        appendTo="body"></p-dropdown>
                        </div>
                        <p class="viewby viewbyLabels">Company: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown [options]="companyDataViewList"
                                        [(ngModel)]="companyDataViewValue"
                                        (ngModelChange)="applyDataViewFilter($event)"
                                        appendTo="body"></p-dropdown>
                        </div>
                        <p class="viewby viewbyLabels">Commodity: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown  [options]="commodityDataViewList" appendTo="body"
                                         [(ngModel)]="commodityDataViewValue"
                                         (ngModelChange)="applyDataViewFilter($event)"
                                         appendTo="body"></p-dropdown>
                        </div>

                        <p class="viewby viewbyLabels">Counterparty: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown [options]="counterpartyDataViewList"
                                        [(ngModel)]="cpDataViewValue"
                                        (ngModelChange)="applyDataViewFilter($event)"
                                        appendTo="body"></p-dropdown>
                        </div>

                        <p class="viewby viewbyLabels">Trader: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown [options]="traderDataViewList"
                                        [(ngModel)]="traderDataViewValue"
                                        (ngModelChange)="applyDataViewFilter($event)"
                                        appendTo="body"></p-dropdown>
                        </div>

                    </div>
                    <p-table [value]="openPosData" [columns]="tableCols"
                             [style.maxHeight]="'575px'"
                             [responsive]="true" autoLayout="true" sortMode="multiple"
                             class="style-4 table-responsive"
                             inViewport>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns;index as i" class="otherColumn stk" [pSortableColumn]="col.field">
                                    {{col.header}}
                                    <p-sortIcon [field]="col['field']" ariaLabel="Activate to sort"
                                                ariaLabelAsc="Activate to sort in ascending order"
                                                ariaLabelDesc="Activate to sort in descending order"></p-sortIcon>
                                    <div *ngIf="isNotLast(i)" class="pipe"></div>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-columns="columns" let-rowData>
                            <tr>
                                <td *ngFor="let col of columns">
                                    <div *ngIf="col.type !== 'buySell' && col.type !== 'date'">
                                        {{getRowValue(rowData,col)}}
                                    </div>
                                    <div *ngIf="col.type == 'buySell'">
                                        {{getTradeType(rowData[col.field])}}
                                    </div>
                                    <div *ngIf="col.type == 'date'">
                                        {{getDeliveryEndDate(rowData[col.field])}}
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <div *ngIf="openPosData.length === 0" class="chartContent">
                        <div class="messageClass">
                            <p class="message dataViewMessage">No Data Available</p>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel *ngIf="this.widgetProp['code'] === 'm2mRealizedPnl'" header="MTM/Realized PnL">
                <div class="tableWrapper">
                    <div class="viewByWrapper">
                        <p class="viewby">View By</p>
                        <p class="viewby viewbyLabels">Profit Center: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown [options]="profitcenterDataViewList"
                                        [(ngModel)]="pcDataViewValue"
                                        (ngModelChange)="applyDataViewFilter($event)"
                                        appendTo="body"></p-dropdown>
                        </div>
                        <p class="viewby viewbyLabels">Company: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown [options]="companyDataViewList"
                                        [(ngModel)]="companyDataViewValue"
                                        (ngModelChange)="applyDataViewFilter($event)"
                                        appendTo="body"></p-dropdown>
                        </div>
                        <p class="viewby viewbyLabels">Commodity: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown  [options]="commodityDataViewList" appendTo="body"
                                         [(ngModel)]="commodityDataViewValue"
                                         (ngModelChange)="applyDataViewFilter($event)"
                                         appendTo="body"></p-dropdown>
                        </div>

                        <p class="viewby viewbyLabels">Counterparty: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown [options]="counterpartyDataViewList"
                                        [(ngModel)]="cpDataViewValue"
                                        (ngModelChange)="applyDataViewFilter($event)"
                                        appendTo="body"></p-dropdown>
                        </div>

                        <p class="viewby viewbyLabels">Trader: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown [options]="traderDataViewList"
                                        [(ngModel)]="traderDataViewValue"
                                        (ngModelChange)="applyDataViewFilter($event)"
                                        appendTo="body"></p-dropdown>
                        </div>

                    </div>
                    <p-table [value]="m2mRealizedPnlData" [columns]="m2mRealizedCols"  [style.maxHeight]="'595px'"
                             [responsive]="true" autoLayout="true" class="style-4 table-responsive" sortMode="multiple">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns;index as i" class="otherColumn stk" [pSortableColumn]="col.field">
                                    {{col.header}}
                                    <p-sortIcon [field]="col['field']" ariaLabel="Activate to sort"
                                                ariaLabelAsc="Activate to sort in ascending order"
                                                ariaLabelDesc="Activate to sort in descending order"></p-sortIcon>
                                    <div *ngIf="isNotLast(i)" class="pipe"></div>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-columns="columns" let-rowData>
                            <tr>
                                <td *ngFor="let col of columns">
                                    <div *ngIf="col.type !== 'buySell' && col.type !=='date'">
                                        {{getRowValue(rowData,col)}}
                                    </div>
                                    <div *ngIf="col.type == 'buySell'">
                                        {{getTradeType(rowData[col.field])}}
                                    </div>
                                    <div *ngIf="col.type == 'date'">
                                        {{getDeliveryEndDate(rowData[col.field])}}
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <div *ngIf="m2mRealizedPnlData.length === 0" class="chartContent">
                        <div class="messageClass">
                            <p class="message dataViewMessage">No Data Available</p>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel *ngIf="this.widgetProp['code'] === 'revenueSplit'" header="Revenue Split">
                <div class="tableWrapper">
                    <div class="viewByWrapper">
                        <p class="viewby">View By</p>
                        <p class="viewby viewbyLabels">Profit Center: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown [options]="profitcenterDataViewList"
                                        [(ngModel)]="pcDataViewValue"
                                        (ngModelChange)="applyDataViewFilter($event)"
                                        appendTo="body"></p-dropdown>
                        </div>
                        <p class="viewby viewbyLabels">Company: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown [options]="companyDataViewList"
                                        [(ngModel)]="companyDataViewValue"
                                        (ngModelChange)="applyDataViewFilter($event)"
                                        appendTo="body"></p-dropdown>
                        </div>
                        <p class="viewby viewbyLabels">Commodity: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown  [options]="commodityDataViewList" appendTo="body"
                                         [(ngModel)]="commodityDataViewValue"
                                         (ngModelChange)="applyDataViewFilter($event)"
                                         appendTo="body"></p-dropdown>
                        </div>

                        <p class="viewby viewbyLabels">Counterparty: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown [options]="counterpartyDataViewList"
                                        [(ngModel)]="cpDataViewValue"
                                        (ngModelChange)="applyDataViewFilter($event)"
                                        appendTo="body"></p-dropdown>
                        </div>

                        <p class="viewby viewbyLabels">Trader: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown [options]="traderDataViewList"
                                        [(ngModel)]="traderDataViewValue"
                                        (ngModelChange)="applyDataViewFilter($event)"
                                        appendTo="body"></p-dropdown>
                        </div>

                    </div>
                    <p-table [value]="revenueSplitView" [columns]="revenueSplitCols"  [style.maxHeight]="'595px'"
                             [responsive]="true" autoLayout="true" class="style-4 table-responsive" sortMode="multiple">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns;index as i" class="otherColumn stk" [pSortableColumn]="col.field">
                                    {{col.header}}
                                    <p-sortIcon [field]="col['field']" ariaLabel="Activate to sort"
                                                ariaLabelAsc="Activate to sort in ascending order"
                                                ariaLabelDesc="Activate to sort in descending order"></p-sortIcon>
                                    <div *ngIf="isNotLast(i)" class="pipe"></div>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-columns="columns" let-rowData>
                            <tr>
                                <td *ngFor="let col of columns">
                                    <div *ngIf="col.type !== 'buySell' && col.type !== 'date'">
                                        {{rowData[col.field]}}
                                    </div>
                                    <div *ngIf="col.type == 'buySell'">
                                        {{getTradeType(rowData[col.field])}}
                                    </div>
                                    <div *ngIf="col.type == 'date'">
                                        {{getDeliveryEndDate(rowData[col.field])}}
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <div *ngIf="revenueSplitView.length === 0" class="chartContent">
                        <div class="messageClass">
                            <p class="message dataViewMessage">No Data Available</p>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel *ngIf="this.widgetProp['code'] === 'openTrades'" header="Open Trades">
                <div class="tableWrapper">
                    <div class="viewByWrapper">
                        <p class="viewby">View By</p>
                        <p class="viewby viewbyLabels">Profit Center: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown [options]="profitcenterDataViewList"
                                        [(ngModel)]="pcDataViewValue"
                                        (ngModelChange)="applyDataViewFilter($event)"
                                        appendTo="body"></p-dropdown>
                        </div>
                        <p class="viewby viewbyLabels">Company: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown [options]="companyDataViewList"
                                        [(ngModel)]="companyDataViewValue"
                                        (ngModelChange)="applyDataViewFilter($event)"
                                        appendTo="body"></p-dropdown>
                        </div>
                        <p class="viewby viewbyLabels">Commodity: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown  [options]="commodityDataViewList" appendTo="body"
                                         [(ngModel)]="commodityDataViewValue"
                                         (ngModelChange)="applyDataViewFilter($event)"
                                         appendTo="body"></p-dropdown>
                        </div>

                        <p class="viewby viewbyLabels">Counterparty: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown [options]="counterpartyDataViewList"
                                        [(ngModel)]="cpDataViewValue"
                                        (ngModelChange)="applyDataViewFilter($event)"
                                        appendTo="body"></p-dropdown>
                        </div>

                        <p class="viewby viewbyLabels">Trader: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown [options]="traderDataViewList"
                                        [(ngModel)]="traderDataViewValue"
                                        (ngModelChange)="applyDataViewFilter($event)"
                                        appendTo="body"></p-dropdown>
                        </div>

                    </div>
                    <p-table [value]="openTradesView" [columns]="openTradesCols"  [style.maxHeight]="'595px'"
                             [responsive]="true" autoLayout="true" class="style-4 table-responsive" sortMode="multiple">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns;index as i" class="otherColumn stk" [pSortableColumn]="col.field">
                                    {{col.header}}
                                    <p-sortIcon [field]="col['field']" ariaLabel="Activate to sort"
                                                ariaLabelAsc="Activate to sort in ascending order"
                                                ariaLabelDesc="Activate to sort in descending order"></p-sortIcon>
                                    <div *ngIf="isNotLast(i)" class="pipe"></div>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-columns="columns" let-rowData>
                            <tr>
                                <td *ngFor="let col of columns">
                                    <div *ngIf="col.type !== 'buySell'">
                                        {{rowData[col.field]}}
                                    </div>
                                    <div *ngIf="col.type == 'buySell'">
                                        {{getTradeType(rowData[col.field])}}
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <div *ngIf="openTradesView.length === 0" class="chartContent">
                        <div class="messageClass">
                            <p class="message dataViewMessage">No Data Available</p>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel *ngIf="this.widgetProp['code'] === 'deliveryStartedTrades'" header="Delivery Started Trades">
                <div class="tableWrapper">
                    <div class="viewByWrapper">
                        <p class="viewby">View By</p>
                        <p class="viewby viewbyLabels">Profit Center: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown [options]="profitcenterDataViewList"
                                        [(ngModel)]="pcDataViewValue"
                                        (ngModelChange)="applyDataViewFilter($event)"
                                        appendTo="body"></p-dropdown>
                        </div>
                        <p class="viewby viewbyLabels">Company: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown [options]="companyDataViewList"
                                        [(ngModel)]="companyDataViewValue"
                                        (ngModelChange)="applyDataViewFilter($event)"
                                        appendTo="body"></p-dropdown>
                        </div>
                        <p class="viewby viewbyLabels">Commodity: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown  [options]="commodityDataViewList" appendTo="body"
                                         [(ngModel)]="commodityDataViewValue"
                                         (ngModelChange)="applyDataViewFilter($event)"
                                         appendTo="body"></p-dropdown>
                        </div>

                        <p class="viewby viewbyLabels">Counterparty: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown [options]="counterpartyDataViewList"
                                        [(ngModel)]="cpDataViewValue"
                                        (ngModelChange)="applyDataViewFilter($event)"
                                        appendTo="body"></p-dropdown>
                        </div>

                        <p class="viewby viewbyLabels">Trader: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown [options]="traderDataViewList"
                                        [(ngModel)]="traderDataViewValue"
                                        (ngModelChange)="applyDataViewFilter($event)"
                                        appendTo="body"></p-dropdown>
                        </div>

                    </div>
                    <p-table [value]="deliveryStartedTradesView" [columns]="deliveryStartedCols"  [style.maxHeight]="'595px'"
                             [responsive]="true" autoLayout="true" class="style-4 table-responsive" sortMode="multiple">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns;index as i" class="otherColumn stk" [pSortableColumn]="col.field">
                                    {{col.header}}
                                    <p-sortIcon [field]="col['field']" ariaLabel="Activate to sort"
                                                ariaLabelAsc="Activate to sort in ascending order"
                                                ariaLabelDesc="Activate to sort in descending order"></p-sortIcon>
                                    <div *ngIf="isNotLast(i)" class="pipe"></div>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-columns="columns" let-rowData>
                            <tr>
                                <td *ngFor="let col of columns">
                                    <div *ngIf="col.type !== 'buySell' && col.type !== 'date'">
                                        {{rowData[col.field]}}
                                    </div>
                                    <div *ngIf="col.type == 'buySell'">
                                        {{getTradeType(rowData[col.field])}}
                                    </div>
                                    <div *ngIf="col.type == 'date'">
                                        {{getDeliveryEndDate(rowData[col.field])}}
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <div *ngIf="deliveryStartedTradesView.length === 0" class="chartContent">
                        <div class="messageClass">
                            <p class="message dataViewMessage">No Data Available</p>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel *ngIf="this.widgetProp['code'] === 'plannedUnplannedTrades'" header="Planned Trades">
                <div class="tableWrapper">
                    <div class="viewByWrapper">
                        <p class="viewby">View By</p>
                        <p class="viewby viewbyLabels">Profit Center: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown [options]="profitcenterDataViewList"
                                        [(ngModel)]="pcDataViewValue"
                                        (ngModelChange)="applyDataViewFilter($event)"
                                        appendTo="body"></p-dropdown>
                        </div>
                        <p class="viewby viewbyLabels">Company: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown [options]="companyDataViewList"
                                        [(ngModel)]="companyDataViewValue"
                                        (ngModelChange)="applyDataViewFilter($event)"
                                        appendTo="body"></p-dropdown>
                        </div>
                        <p class="viewby viewbyLabels">Commodity: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown  [options]="commodityDataViewList" appendTo="body"
                                         [(ngModel)]="commodityDataViewValue"
                                         (ngModelChange)="applyDataViewFilter($event)"
                                         appendTo="body"></p-dropdown>
                        </div>

                        <p class="viewby viewbyLabels">Counterparty: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown [options]="counterpartyDataViewList"
                                        [(ngModel)]="cpDataViewValue"
                                        (ngModelChange)="applyDataViewFilter($event)"
                                        appendTo="body"></p-dropdown>
                        </div>

                        <p class="viewby viewbyLabels">Trader: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown [options]="traderDataViewList"
                                        [(ngModel)]="traderDataViewValue"
                                        (ngModelChange)="applyDataViewFilter($event)"
                                        appendTo="body"></p-dropdown>
                        </div>

                    </div>
                    <p-table [value]="plannedView" [columns]="plannedCols" [style.maxHeight]="'595px'"
                             [responsive]="true" autoLayout="true" class="style-4 table-responsive" sortMode="multiple">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns;index as i" class="otherColumn stk" [pSortableColumn]="col.field">
                                    {{col.header}}
                                    <p-sortIcon [field]="col['field']" ariaLabel="Activate to sort"
                                                ariaLabelAsc="Activate to sort in ascending order"
                                                ariaLabelDesc="Activate to sort in descending order"></p-sortIcon>
                                    <div *ngIf="isNotLast(i)" class="pipe"></div>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-columns="columns" let-rowData>
                            <tr>
                                <td *ngFor="let col of columns">
                                    <div *ngIf="col.type !== 'buySell'">
                                        {{rowData[col.field]}}
                                    </div>
                                    <div *ngIf="col.type == 'buySell'">
                                        {{getTradeType(rowData[col.field])}}
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <div *ngIf="plannedView.length === 0" class="chartContent">
                        <div class="messageClass">
                            <p class="message dataViewMessage">No Data Available</p>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel *ngIf="this.widgetProp['code'] === 'pnlDrawdown'" header="PnL Draw-Down">
                <div class="tableWrapper">
                    <div class="viewByWrapper">
                        <p class="viewby">View By</p>
                        <p class="viewby viewbyLabels">Profit Center: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown [options]="profitcenterDataViewList"
                                        [(ngModel)]="pcDataViewValue"
                                        (ngModelChange)="applyDataViewFilter($event)"
                                        appendTo="body"></p-dropdown>
                        </div>
                        <p class="viewby viewbyLabels">Trade Type: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown [options]="tradeTypeDataViewList"
                                        [(ngModel)]="tradeTypeDataViewValue"
                                        (ngModelChange)="applyDataViewFilter($event)"
                                        appendTo="body"></p-dropdown>
                        </div>
                        <p class="viewby viewbyLabels">Trader: </p>
                        <div style="margin-left: 10px">
                            <p-dropdown [options]="traderDataViewList"
                                        [(ngModel)]="traderDataViewValue"
                                        (ngModelChange)="applyDataViewFilter($event)"
                                        appendTo="body"></p-dropdown>
                        </div>

                    </div>
                    <p-table [value]="pnlDrawdownView" [columns]="pnlDrawdownCols" [style.maxHeight]="'595px'"
                             [responsive]="true" autoLayout="true" class="style-4 table-responsive" sortMode="multiple">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns;index as i" class="otherColumn stk" [pSortableColumn]="col.field">
                                    {{col.header}}
                                    <p-sortIcon [field]="col['field']" ariaLabel="Activate to sort"
                                                ariaLabelAsc="Activate to sort in ascending order"
                                                ariaLabelDesc="Activate to sort in descending order"></p-sortIcon>
                                    <div *ngIf="isNotLast(i)" class="pipe"></div>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-columns="columns" let-rowData>
                            <tr>
                                <td *ngFor="let col of columns">
                                    <div *ngIf="col.type == 'date'">
                                        {{getDeliveryEndDate(rowData[col.field])}}
                                    </div>
                                    <div *ngIf="col.field !== 'pnlChange'"
                                         [innerHTML]="getRowValue(rowData,col)">
                                    </div>
                                    <div *ngIf="col.field === 'pnlChange'"
                                         [innerHTML]="getRowValue(rowData,col)"
                                        [style]="getStyle(rowData[col.field])">
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <div *ngIf="pnlDrawdownView.length === 0" class="chartContent">
                        <div class="messageClass">
                            <p class="message dataViewMessage">No Data Available</p>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</p-dialog>

<future-position-dashboard *ngIf="displayForm"
                           [dialogDisplay]="displayForm"
                           (onClose)="onCloseForm()"
                           [futureDropdownValue]="futureDropdownValue">
</future-position-dashboard>


