import {Component, OnInit} from '@angular/core';
import {Tcolumn} from '../../grid/tcolumn.model';
import {Validators} from '@angular/forms';
import {Grid} from '../../grid/grid.model';
import {MasterService} from "../services/MasterService";
import {Action} from "../../grid/actions.model";
import {environment} from "../../../environments/environment";
import {CommonService} from "../../services/common.service";
import {defaultValue} from "../../grid/defaultValue.model";

@Component({
  selector: 'app-cropseason',
  templateUrl: './cropseason.component.html',
  styleUrls: []
})
export class CropseasonComponent implements OnInit {
  columns: Map<string, Tcolumn>;
  grid: Grid;

  constructor(private masterCommService: MasterService, public commonService: CommonService) {
  }

  ngOnInit() {
    this.getColumns();
    this.grid = new Grid(this.columns, true, true, true, true, 'name', '/api/cropseason/v1/getall?tenantId='+this.commonService.getFromStorage('tenantId'), '', 'Crop Season');
    this.grid.setSaveUrl(environment.base_url + '/api/cropseason/v1/save');
    this.grid.setUpdateUrl(environment.base_url + '/api/cropseason/v1/update');
    this.grid.hrefUrl = environment.base_url + '/api/cropseason/v1/getbyuuid?tenantId='+this.commonService.getFromStorage('tenantId')+'&uuid=';
    this.grid.importUrl = environment.base_url + '/api/cropseason/v1/import';
    this.grid.iconName='cropSeason';
  }


  public getColumns() {
    let action1 = new Action();
    action1.addFieldToUpdate('seasonTo', undefined, undefined, undefined, undefined, false,  'minDate');   //meta to update if C then current value and if F then function returning value
    this.columns = new Map();
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('code', new Tcolumn('code', 'Crop Season Code', 'T', 1, false, undefined, true,new defaultValue('',undefined,['-','_'],undefined,false,true,false,undefined,undefined,true), 'B', [Validators.required, Validators.maxLength(8)], undefined, undefined, undefined, undefined, [], undefined, undefined, true));
    this.columns.set('name', new Tcolumn('name', 'Crop Season Name', 'T', 2, false, undefined, true, '', 'B', [Validators.required, Validators.maxLength(100)], undefined, undefined, undefined, undefined, [], undefined, undefined, true));
    this.columns.set('description', new Tcolumn('description', 'Crop Season Description', 'TA', 3, true, undefined, true, '', 'B', [Validators.maxLength(200)]));
    this.columns.set('seasonFrom', new Tcolumn('seasonFrom', 'Season From', 'D', 4, true, undefined, true, '', 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, action1, {
      date: {
        view: 'month',
        format: 'MMM yyyy'
      }
    }));
    this.columns.set('seasonTo', new Tcolumn('seasonTo', 'Season To', 'D', 5, true, undefined, true, '', 'B', [Validators.required], undefined, 1, undefined, undefined, undefined, undefined, {
      date: {
        view: 'month',
        format: 'MMM yyyy'
      }
    }));
    this.columns.set('status', new Tcolumn('status', 'Status', 'B', 6, true, this.masterCommService.getActiveInactive(), true, true, 'B', [Validators.required]));
  }
}
