import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Tcolumn} from "../../../grid/tcolumn.model";
import {MessageService, Table} from "primeng";
import {CommonService} from "../../../services/common.service";
import {environment} from '../../../../environments/environment';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'xceler-expandable-grid',
  templateUrl: './xceler-expandable-grid.component.html',
  styleUrls: ['./xceler-expandable-grid.component.css']
})
export class XcelerExpandableGridComponent implements OnInit,AfterViewInit {

  @Input() isExpanded: boolean = false;
  @Input() position: string = 'left';
  @Input() selectedRows: any[] = [];
  @Input() columns: Tcolumn[] = [];
  @Input() values: any[] = [];
  @Input() headerFields: any;
  @Output() onRowSelectionChange = new EventEmitter();
  @ViewChild('expandedTable') table: Table;
  @Input() matchFieldName: string;
  @Input() balanceFieldName: string;
  @Input() showPlaceHolder: boolean = true;
  @Input() readOnly: boolean = false;
  placeHolderHeight: any = ' 300px';
  noDataBoxLeft: any = '';
  @Output() selectedRowsData = new EventEmitter();
  @Output() balanceExceedError = new EventEmitter();
  @Output() onDataUpdate = new EventEmitter();
  @Input() singleSelectionMode: boolean = false;
  private lastSelectedIndex: number = -1;
  datePipe: DatePipe;

  constructor(public commonService: CommonService,
              public messageService: MessageService,
              private changeDetectorRef:ChangeDetectorRef) {
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    this.datePipe = new DatePipe('en-US');
  }

  onExpandClick() {
    this.isExpanded = !this.isExpanded;
  }

  onRowSelect(value: any) {
    value['data'][this.matchFieldName] = value['data'][this.balanceFieldName];
    this.onRowSelectionChange.emit({rows: this.selectedRows, event: 'select'});
  }

  onRowUnselect(value: any) {
    value['data'][this.matchFieldName] = value['data'][this.balanceFieldName];
    this.onRowSelectionChange.emit({rows: this.selectedRows, event: 'unselect'});
  }

  calculateLeft() {
    this.noDataBoxLeft = ((window['visualViewport'].width - 500) / 2) + 'px';
  }

  updateIdAndGetRowData(rowData, ri) {
    rowData['_rowNum'] = ri;
    return rowData;
  }

  getKeys() {
    return Object.keys(this.headerFields);
  }

  getDateForOutput(date: any, col: Tcolumn) {
    if (date !== undefined && date !== null && date.toString() !== 'Invalid Date') {
      return this.datePipe.transform(date, col.getFromExtra('date')['format'] !== undefined ? col.getFromExtra('date')['format'] : environment.dateFormat);
    }
  }

  private getListFieldValue(col: any, value: any, ri: any) {
    return (value !== null && value !== undefined && value.toString().length > 0) ? this.getColumnValue(col, value, ri) : '';
  }

  public getColumnValue(column: Tcolumn, colValue: any, ri) {
    let listOfValues = column.getListOfValues();
    if (listOfValues !== undefined) {
      for (let i = 0; i < listOfValues.length; i++) {
        if (listOfValues[i].getValue().toString() === colValue.toString()) {
          return listOfValues[i].getLabel();
        }
      }
    }
  };

  getOutputValue(col: any, ri, rowData) {
    let outputValue = this.commonService.checkAndGetValue(col, rowData);
    if (outputValue === undefined || outputValue.length === 0) {
      if (col.getColumnType() === 'L' || col.getColumnType() === 'OB' || col.getColumnType() === 'B' || col.getColumnType() === 'ML') {
        outputValue = this.getListFieldValue(col, rowData[col.getField()], ri);
      } else if (col.getColumnType() == 'T' || col.getColumnType() == 'N' || col.getColumnType() == 'TA' || col.getColumnType() == 'LB' || col.getColumnType() == 'S' || col.getColumnType() == 'TN' || col.getColumnType() === 'SL') {
        outputValue = rowData[col.getField()];
      } else if (col.getColumnType() === 'C') {  // for checkbox
        outputValue = rowData[col.getField()];
      } else if (col.getColumnType() === 'D') {  // for date
        outputValue = this.getDateForOutput(rowData[col.getField()], col)
      } else if (col.getColumnType() === 'F') {    //for filepicer
        outputValue = rowData[col.getField() + 'FileName'];
      } else if (col.getColumnType() === 'DSL') {  //for dual slider
        outputValue = this.commonService.getDualSliderValue(rowData[col.getField()], col, rowData);
      }
    }
    return (outputValue === undefined || outputValue === null || outputValue.length === 0) ? '-' : outputValue;
  }

  onchange(value:any) {
    let val = value[this.matchFieldName]
    let balancevalue = value[this.balanceFieldName]
    if(val > balancevalue){
     this.balanceExceedError.emit({value:val,balance:balancevalue})
    }
    this.selectedRows.find(data=>{
      if( value.uuid == data.uuid){
        if(value.matchLot > value.balanceLot){
          data.matchLot = value.balanceLot;
        }
      }
    })
    this.onDataUpdate.emit({rows: this.selectedRows, event: 'update'})
  }

  inputValidator(event:any,rowData:any) {
    let balancevalue = rowData[this.balanceFieldName]
    if (event.target.value > balancevalue) {
        event.target.value = rowData['balanceLot']
    }
  }
}
