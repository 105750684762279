import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-dash-card',
  templateUrl: './dash-card.component.html',
  styleUrls: ['./dash-card.component.css']
})
export class DashCardComponent implements OnInit {
  @Input() card: any = [];
  @Output() childs = new EventEmitter();
  @Output() title = new EventEmitter();
  randomvalue: any[] = [];

  constructor(private router: Router) {
  }

  ngOnInit() {
    var this1 = this;
    /* this.card.forEach(function (card,i) {
       card['stats'].forEach(function (stat,j) {
         this1.randomvalue.push(stat['value'])
         this1.getRandomValue(parseInt(i+''+j));
       })
     })*/
  }

  outChilds(value, title) {
    this.childs.emit(value);
    this.outTitle(title);

  }

  outTitle(title) {
    this.title.emit(title);
  }

  getRandomValue(i) {
    setInterval(() => {
      if (this.randomvalue.length <= i) {
        this.randomvalue.push(this.getRandomInt(10, 9999));
      } else {
        this.randomvalue.splice(i, 1);
        this.randomvalue.splice(i, 0, this.getRandomInt(10, 999));
      }
    }, 3000);
  }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
  }

  getvalue(k: number, i: number) {
    return parseInt(k + '' + i);
  }
}
