<div class="cardsection animated fadeIn" id="cardlist">
  <div class="subheading">
    <div style="display: flex">
      <i (click)='back(true); ' class="fas fa-arrow-left" style="color: black; cursor: pointer;"></i>
      <p style="color: rgb(255,255,255); margin: 0px; padding-left: 20px; padding-bottom: 8px">{{title}}</p>
    </div>
  </div>
  <div style="display: flex">
    <div (click)='grid(item)' *ngFor="let item of subCards" class="cardstat card-1">
      <p>{{item.name}}</p>
      <i class="{{item.icon}}"></i>
    </div>
  </div>
</div>
