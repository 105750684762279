import {Component, HostListener, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Tcolumn} from '../../grid/tcolumn.model';
import {Grid} from '../../grid/grid.model';
import {filter} from '../../masters/services/filter.model';
import {CommonService} from '../../services/common.service';
import {ActivatedRoute} from '@angular/router';
import {MasterService} from '../../masters/services/MasterService';
import {environment} from '../../../environments/environment';
import {entities} from '../../services/common/entities';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Action} from '../../grid/actions.model';
import {KeyValue} from '../../grid/key-value.model';
import {HttpErrorResponse} from '@angular/common/http';
import {MessageService} from 'primeng';
import {DatePipe} from '@angular/common';
import {icon} from '@fortawesome/fontawesome-svg-core';
import {FormControlServiceService} from '../../services/form-control-service.service';

@Component({
    selector: 'app-inventory',
    templateUrl: './inventory.component.html',
    styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit {
    columns: Map<string, Tcolumn>;
    grid: Grid;
    saveButtonDisabled: boolean = false;
    refreshGrid: boolean = false;
    displayOnAdd: boolean = false;
    includedCharacters: any[] = ['_', '/', ' '];
    storageFacilityValues: any[];
    modeOfStorageValues: any[];
    comingleValues: any[];
    statusValues: any[];
    storageFacility: string = '';
    modeOfStorage: string = '';
    comingle: string = 'yes';
    storageCurrencyList: any[] = [];
    ownerNameList: any[] = [];
    companyList: any[] = [];
    profitcentreList: any[] = [];
    commodityList: any[] = [];
    commodityMultiselectList: any[] = [];
    typeList: any[] = [];
    storageUomList: any[] = [];
    refreshAddNewRow: boolean = true;
    newRows: any[] = [];
    refreshFormGroup: boolean = false;
    storageCols: Tcolumn[] = [];
    storagePackagingList: any[] = [];
    storageValues: any[];
    valuationMethodList: any[] = [];
    eodCurrencyList: any[] = [];
    eodUomList: any[] = [];
    selectedUom: any;
    storageCommodity: any[];
    internalpackageList: any[] = [];
    createdBy: any;
    createdDate: any;
    updatedBy: any;
    updatedDate: any;
    datePipe: DatePipe;
    @Input() selectedRowData: any;
    @Input() canEdit: boolean = true;
    isLoading: boolean = false;
    values: any[] = [];
    availableCapacity: any;
    loadDataList:any[] = [];

    //inventory
    saveButtonLabel: string = 'Save';
    inventoryForm: FormGroup;
    validationSections:any = {};
    header: any = 'New Storage Inventory';
    private formControlService:FormControlServiceService = new FormControlServiceService();
    loadTableValid:boolean = false;
    isComingle:boolean = false;
    private uuid: any='';
    isUpdate:boolean = false;


    constructor(private masterCommonService: MasterService, public commonService: CommonService, private router: ActivatedRoute,
                private formBuilder: FormBuilder, private messageService: MessageService) {
        this.commonService.getFromStorage('policy');
    }

    ngOnInit(): void {
        this.statusValues = [
            {label: 'Active', value: 'Active'},
            {label: 'Inactive', value: 'Inactive'}
        ];
        this.storageFacilityValues = [
            {label: 'Internal', value: 'Internal'},
            {label: 'External', value: 'External'}
        ];
        this.modeOfStorageValues = [
            {label: 'Bulk', value: 'Bulk'},
            {label: 'Packed', value: 'Packed'}
        ];
        this.comingleValues = [
            {label: 'Yes', value: 'Yes'},
            {label: 'No', value: 'No'}
        ];
        this.storageValues = [
            {label: 'Physical', value: 'Physical'},
            {label: 'Virtual', value: 'Virtual'}
        ];

        this.storageCurrencyList = this.commonService.getCurrencyList();
        this.eodCurrencyList = this.commonService.getCurrencyList();
        this.getColumns();
        this.grid = new Grid(this.columns, true, true, true, true, 'storageId', '/api/storageInventory/v1/getAllStorageInventory?tenantId=' + this.commonService.getFromStorage('tenantId'), '', 'Storage Inventory', undefined, undefined);
        this.grid.importUrl = environment.base_url +'/api/storageInventory/v1/importstorageinventory?tenantId='+this.commonService.getFromStorage('tenantId');
        this.grid.urlIdField = 'uuid';
        this.grid.onEditViewThrowUnique = true;
        this.grid.openSidebarOnUniqueClick = false;
        this.grid.openDefaultInputForm = false;
        this.grid.iconName = 'Inventory';
        this.grid.setGridConfiguration(true, true, false, false);
        this.grid.gridAdvacedFilterURL = '/api/storageInventory/v1/getinventorybycriteria?tenantId=' + this.commonService.getTenantId();
        this.initInternalForm();
        this.loadValidationSection();
        this.getStorageCols();
    }

    getColumns() {
        this.columns = new Map();
        this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
        /***** Section 1 : Basic Details **********/
        this.columns.set('storageId', new Tcolumn('storageId', 'Storage ID', 'LB', 1, false, undefined, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('storageName', new Tcolumn('storageName', 'Storage Name', 'T', 2, true, undefined, true, '', 'B'));
        this.columns.set('type', new Tcolumn('type', 'Storage Type', 'L', 3, true, undefined, true, true, 'B', undefined, undefined, undefined, undefined, undefined, undefined, undefined));
        this.columns.set('totalStorageCapacity', new Tcolumn('totalStorageCapacity', 'Total Capacity', 'N', 4, true, undefined, true, true, 'B', undefined, undefined, undefined, undefined, undefined, undefined, undefined));
        this.columns.set('availableCapacity', new Tcolumn('availableCapacity', 'Available Capacity', 'N ', 5, true, undefined, true, true, 'B', undefined, undefined, undefined, undefined, undefined, undefined, undefined));
        this.columns.set('storageCurrency', new Tcolumn('storageCurrency', 'Storage Currency', 'L', 6, true, undefined, true, '', 'B'));
        this.columns.set('storageUom', new Tcolumn('storageUom', 'Storage Uom', 'L', 7, true, undefined, true, '', 'B'));
        this.columns.set('company', new Tcolumn('company', 'Company', 'L', 8, true, undefined, true, '', 'B'));
        this.columns.set('storageFacility', new Tcolumn('storageFacility', 'Storage Facility', 'OB', 9, true, undefined, true, '', 'B'));
        this.columns.set('commodity', new Tcolumn('commodity', 'Commodity', 'L', 10, true, undefined, true, '', 'B', undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
        this.columns.set('profitcentre', new Tcolumn('profitcentre', 'Profit Centre', 'L', 11, true, undefined, true, '', 'B', undefined, undefined, undefined, undefined, undefined));
        this.columns.set('ownerName', new Tcolumn('ownerName', 'Storage Facility Owner', 'L', 12, true, undefined, true, '', 'B'));
        this.columns.set('comingle', new Tcolumn('comingle', 'Comingle', 'OB', 13, true, undefined, true, true, 'B', undefined, undefined, undefined, undefined, undefined, undefined, undefined));
        this.columns.set('storedCapacity', new Tcolumn('storedCapacity', 'Stored Capacity', 'N', 14, true, undefined, true, true, 'B', undefined, undefined, undefined, undefined, undefined, undefined, undefined));
        this.columns.set('virtualStorage', new Tcolumn('virtualStorage', 'Storage', 'OB', 15, true, undefined, true, true, 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined));
        this.columns.set('modeOfStorage', new Tcolumn('modeOfStorage', 'Mode Of Storage', 'OB', 16, true, undefined, true, '', 'B'));
        this.columns.set('valuationMethod', new Tcolumn('valuationMethod', 'Valuation Method', 'L', 17, true, undefined, true, 'wap', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
        this.columns.set('eodCurrency', new Tcolumn('eodCurrency', 'EoD Currency', 'L', 18, true, undefined, true, '', 'B', [Validators.required]));
        this.columns.set('eodUom', new Tcolumn('eodUom', 'EOD UOM', 'L', 19, true, undefined, true, '', 'B', [Validators.required]));
        this.columns.set('active', new Tcolumn('active', 'Status', 'OB', 20, true, undefined, true, 'Active', 'B', [Validators.required]));

    }

    getStorageCols() {
        this.storageCols.push(new Tcolumn('spaceId', 'Space ID', 'T', 0, true, undefined, true,'','B',[Validators.required]));
        this.storageCols.push(new Tcolumn('commodity', 'Commodity', 'L', 1, true, undefined, true,'','B',[Validators.required]));
        this.storageCols.push(new Tcolumn('internalPackage', 'Internal Package', 'L', 2, true, this.internalpackageList, true,'','B',[Validators.required]));
        this.storageCols.push(new Tcolumn('maxNoOfIP', 'Max # of Internal Packings', 'N', 3, true, undefined, true,'','B',[Validators.required]));
        this.storageCols.push(new Tcolumn('totalCapacity', 'Total Capacity', 'N', 4, true, undefined, true, undefined, 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    }

    private loadValidationSection() {
        let packagingDetailsFields = {};
        packagingDetailsFields['spaceId'] = 'Space ID';
        packagingDetailsFields['commodity'] = 'Commodity';
        packagingDetailsFields['internalPackage'] = 'Internal Package';
        packagingDetailsFields['maxNoOfIP'] = 'Max Internal Packings';

        this.validationSections = {};
        this.validationSections['Basic Details'] = [
            {field:'storageFacility',header:'Storage Facility'},
            {field:'ownerName',header:'Storage Facility Owner'},
            {field:'modeOfStorage',header:'Mode Of Storage'},
            {field:'storageCurrency',header:'Storage Currency'},
            {field:'active',header:'Status'},
            {field:'comingle',header:'Comingle'}
        ];
        this.validationSections['Company Details'] = [
            {field:'company',header:'Company'},
            {field:'profitcentre',header:'Profit Center'},
            {field:'commodity',header:'Commodity'}
        ];
        this.validationSections['Storage Details'] = [
            {field:'storageName',header:'Storage Name'},
            {field:'type',header:'Storage Type'},
            {field:'storageUom',header:'Storage UOM'},
            {field:'totalStorageCapacity',header:'Total Capacity'}
        ];
        this.validationSections['Packaging Details'] = [{field:'packageDetails',header:'Packaging Details',grid:true,fields:packagingDetailsFields}]
        this.validationSections['Storage Valuation Details'] = [
            {field:'virtualStorage',header:'Storage'},
            {field:'valuationMethod',header:'Valuation Method'},
            {field:'eodCurrency',header:'EOD Currency'},
            {field:'eodUom',header:'EOD UOM'},
        ];
    }

    initInternalForm() {
        this.inventoryForm = this.formControlService.addControl('storageId','')
            .addControl('storageFacility','', [Validators.required])
            .addControl('ownerName','')
            .addControl('modeOfStorage','', [Validators.required])
            .addControl('storageCurrency','', [Validators.required])
            .addControl('active','')
            .addControl('comingle','', [Validators.required])
            .addControl('company','', [Validators.required])
            .addControl('profitcentre','', [Validators.required])
            .addControl('commodity','', [Validators.required])
            .addControl('commodityComingle','', '')
            .addControl('storageName','', [Validators.required])
            .addControl('type','', [Validators.required])
            .addControl('storageUom','', [Validators.required])
            .addControl('totalStorageCapacity','', [Validators.required])
            .addControl('storedCapacity','')
            .addControl('availableCapacity','')
            .addControl('virtualStorage','', [Validators.required])
            .addControl('valuationMethod','', [Validators.required])
            .addControl('eodCurrency','', [Validators.required])
            .addControl('eodUom','', [Validators.required])
            .build();
    }

    onFormGroupCreatedPackage(form: FormGroup) {
        this.inventoryForm = this.formControlService.addFormGroupControl('packageDetails',form).build();
    }

    onFromGroupStatusChangePackage(value: any) {
        this.loadTableValid = value['status'];
    }

//On Click of Add button
    onClickAdd() {
        this.isUpdate = false;
        this.inventoryForm.controls['availableCapacity'].setValue('');
        this.inventoryForm.controls['comingle'].setValue('No');
        this.inventoryForm.controls['commodity'].setValue('');
        this.inventoryForm.controls['company'].setValue('');
        this.inventoryForm.controls['eodCurrency'].setValue('USD');
        this.inventoryForm.controls['eodUom'].setValue('MT');
        this.inventoryForm.controls['modeOfStorage'].setValue('Bulk');
        this.inventoryForm.controls['ownerName'].setValue('');
        this.inventoryForm.controls['profitcentre'].setValue('');
        this.inventoryForm.controls['active'].setValue('Active');
        this.inventoryForm.controls['virtualStorage'].setValue('Physical');
        this.inventoryForm.controls['storageCurrency'].setValue('USD');
        this.inventoryForm.controls['storageFacility'].setValue('Internal');
        this.inventoryForm.controls['storageId'].setValue('');
        this.inventoryForm.controls['storageName'].setValue('');
        this.inventoryForm.controls['type'].setValue('');
        this.inventoryForm.controls['storageUom'].setValue('MT');
        this.inventoryForm.controls['storedCapacity'].setValue('');
        this.inventoryForm.controls['totalStorageCapacity'].setValue('');
        this.inventoryForm.controls['valuationMethod'].setValue('');
        this.values = [];
        this.storagePackagingList = [];
        this.refreshGrid = false;
        this.displayOnAdd = true;
        this.getDataOnLoad();
    }

    getDataOnLoad() {
        this.continueLoad();
        this.getInternalPackageData();
    }

    //Get data on load
    continueLoad() {
        let _this = this;
        this.commonService.getLoadData('commodity', ['config', 'config', 'config', 'config', 'config','config', 'data', 'data'],
            ['commodity', 'unitofmeasurement', 'company', 'profitcenter', 'internalpackage','counterparty',
                [{key: 'name', value: 'inventory_type_ind'}],
                [{key: 'name', value: 'inventory_valuation_ind'}]]).subscribe((next: any) => {
                    _this.loadDataList = next;
            _this.companyList = _this.masterCommonService.getListFromArray(next['company'], false, true);
            _this.profitcentreList = _this.masterCommonService.getListFromArray(next['profitcenter'], false, true);
            _this.commodityList = _this.masterCommonService.getListFromArray(next['commodity'], false, true);
            _this.commodityMultiselectList = _this.masterCommonService.getListFromArray(next['commodity'], false, false);
            _this.typeList = _this.masterCommonService.getListFromArray(next['inventory_type_ind'], false, true);
            _this.storageUomList = _this.masterCommonService.getListFromArray(next['unitofmeasurement'], false, true);
            _this.valuationMethodList = _this.masterCommonService.getListFromArray(next['inventory_valuation_ind'], false, true);
            _this.eodUomList = _this.masterCommonService.getListFromArray(next['unitofmeasurement'], false, true);
            _this.ownerNameList = _this.masterCommonService.getListFromArray(next['counterparty'], false, true);
        });
    }

    //On Change of Storage Facility to "External"
    onChangeStorageFacility(value: any) {
        let _this = this;
        if (value['value'] !== undefined && value['value'] !== null) {
            if (value['value'] === 'External') {
                this.commonService.getJSONByURL(environment.base_url + '/api/counterparty/v1/getcounterpartybycounterpartytypecode?tenantId='
                    + this.commonService.getTenantId() + '&counterpartytype=' + ['Storage Owner']).subscribe((next: any) => {
                    _this.ownerNameList = _this.commonService.createListFromObject(next, 'name', 'name', true);
                });
            } else {
                _this.ownerNameList = _this.masterCommonService.getListFromArray(this.loadDataList['counterparty'], false, true);
            }
            }
    }

    //Get Internal Package Data
    getInternalPackageData() {
        let _this = this;
        this.commonService.getLoadData('commodity', ['config'],
            ['internalpackage']).subscribe((next: any) => {
            _this.internalpackageList = _this.masterCommonService.getListFromArray(next['internalpackage']);
            _this.updateInternalvalue(_this.internalpackageList);
        });
    }

    public updateInternalvalue(list: any[] = []) {
        this.storageCols[2].setListofValues(list);
    }

    //On change of Internal Package calculate TotalCapacity of the table
    onChangeValue(value) {
        let internalName = value['rowData']['internalPackage'];
        if (value !== undefined && value !== null) {
            if (internalName !== undefined && internalName !== null) {
                this.commonService.getJSONByURL(environment.base_url + '/api/internalpackage/v1/getinternalpackagedetaildatabyname?tenantId='
                    + this.commonService.getFromStorage('tenantId') + '&name=' + internalName).subscribe((next: any) => {
                    let capacity = next[0]['capacity'];
                    let maxNo = value['rowData']['maxNoOfIP'];
                    value['rowData']['totalCapacity'] = capacity * maxNo;
                });
            }
        }
    }

    onSelectCommodity(value: string) {
        this.storageCols[1].setListofValues(this.masterCommonService.getListFromArray(value.split(',')));
    }

    onChangeComingle(value: any) {
        if (value['value'] !== undefined && value['value'] !== null) {
            if (value['value'] === 'No') {
                this.isComingle = false;
            } else {
                this.isComingle = true;
            }
        }
    }

    onChangeUom(value: any) {
        this.selectedUom = value['value'];
    }

    calculateAvailableCapacity() {
        let totalStorageCapacity = this.inventoryForm.controls['totalStorageCapacity'].value;
        this.inventoryForm.controls['availableCapacity'].setValue(totalStorageCapacity);
    }

    onSave() {
        let url = '';
        let _this = this;
        let storagepayload: {};
        this.saveButtonDisabled = true;
        storagepayload = {
            uuid:this.uuid,
            storageId: this.inventoryForm.controls['storageId'].value,
            storageFacility: this.inventoryForm.controls['storageFacility'].value,
            modeOfStorage: this.inventoryForm.controls['modeOfStorage'].value,
            comingle: this.inventoryForm.controls['comingle'].value,
            storageCurrency: this.inventoryForm.controls['storageCurrency'].value,
            ownerName: this.inventoryForm.controls['ownerName'].value,
            active: this.inventoryForm.controls['active'].value,
            company: this.inventoryForm.controls['company'].value,
            profitcentre: this.inventoryForm.controls['profitcentre'].value,
            commodity: this.inventoryForm.controls['commodity'].value,
            storageName: this.inventoryForm.controls['storageName'].value,
            type: this.inventoryForm.controls['type'].value,
            storageUom: this.inventoryForm.controls['storageUom'].value,
            totalStorageCapacity: this.inventoryForm.controls['totalStorageCapacity'].value,
            storedCapacity: this.inventoryForm.controls['storedCapacity'].value,
            availableCapacity: this.inventoryForm.controls['availableCapacity'].value,
            virtualStorage: this.inventoryForm.controls['virtualStorage'].value,
            valuationMethod: this.inventoryForm.controls['valuationMethod'].value,
            eodCurrency: this.inventoryForm.controls['eodCurrency'].value,
            eodUom: this.inventoryForm.controls['eodUom'].value,
            updatedBy: this.commonService.getFromStorage('userName'),
            createdBy: this.commonService.getFromStorage('userName'),
            storagePackagingList: this.storagePackagingList
        };
        if(this.isUpdate === true) {
            url = environment.base_url + '/api/storageInventory/v1/updateStorageInventory?tenantId=' + this.commonService.getFromStorage('tenantId');
            this.commonService.post(url, storagepayload).subscribe(next => {
                _this.refreshGrid = true;
                _this.displayOnAdd = false;
                _this.storagePackagingList = [];
                _this.saveButtonDisabled = false;
                _this.saveButtonLabel = 'Save';
                _this.showToast('Row Successfully Updated.');
            }, (error: HttpErrorResponse) => {
                _this.saveButtonDisabled = false;
                this.messageService.add({severity: 'error', summary: this.commonService.getHttpErrorMessage(error, undefined)});
            });
        } else {
            url = environment.base_url + '/api/storageInventory/v1/createStorageInventory?tenantId=' + this.commonService.getFromStorage('tenantId');
            this.commonService.post(url, storagepayload).subscribe(next => {
                _this.refreshGrid = true;
                _this.displayOnAdd = false;
                _this.saveButtonDisabled = false;
                _this.storagePackagingList = [];
                _this.saveButtonLabel = 'Save';
                _this.showToast('Row Successfully Saved.');
            }, (error: HttpErrorResponse) => {
                _this.saveButtonDisabled = false;
                this.messageService.add({severity: 'error', summary: this.commonService.getHttpErrorMessage(error, undefined)});
            });
        }

    }

    showToast(msg, severity: string = 'success') {
        this.messageService.add({
            severity: 'info',
            summary: msg
        });
    }

    onClickUnique(value: any) {
        let _this = this;
        this.selectedRowData = value['rowData'];
        this.header = "Storage Id : " + this.selectedRowData['storageId'];
        this.onClickAdd();
        this.saveButtonLabel='Update'
        this.isUpdate = true;
        this.uuid= value['rowData']['uuid'];
        this.refreshGrid = false;
        this.commonService.getJSONByURL(environment.base_url + '/api/storageInventory/v1/getstorageinventorybyuuid?tenantId='
            + this.commonService.getFromStorage('tenantId') + '&uuid=' + value['rowData']['uuid']).subscribe((next: any) => {
                if(next['comingle'] === 'Yes') {
                    this.isComingle = true;
                } else {
                    this.isComingle = false;
                }
            this.formControlService.setValue('storageId',next['storageId']);
            this.formControlService.setValue('storageFacility',next['storageFacility']);
            this.formControlService.setValue('ownerName',next['ownerName']);
            this.formControlService.setValue('modeOfStorage',next['modeOfStorage']);
            this.formControlService.setValue('storageCurrency',next['storageCurrency']);
            this.formControlService.setValue('active',next['active']);
            this.formControlService.setValue('comingle',next['comingle']);
            this.formControlService.setValue('company',next['company']);
            this.formControlService.setValue('profitcentre',next['profitcentre']);
            this.formControlService.setValue('commodity',next['commodity']);
            this.formControlService.setValue('storageName',next['storageName']);
            this.formControlService.setValue('type',next['type']);
            this.formControlService.setValue('storageUom',next['storageUom']);
            this.formControlService.setValue('totalStorageCapacity',next['totalStorageCapacity']);
            this.formControlService.setValue('storedCapacity',next['storedCapacity']);
            this.formControlService.setValue('availableCapacity',next['availableCapacity']);
            this.formControlService.setValue('virtualStorage',next['virtualStorage']);
            this.formControlService.setValue('valuationMethod',next['valuationMethod']);
            this.formControlService.setValue('eodCurrency',next['eodCurrency']);
            this.formControlService.setValue('eodUom',next['eodUom']);
            this.storagePackagingList = next['storagePackagingList'];
            this.displayOnAdd = true;
        });
    }

    onClose() {
        this.displayOnAdd = false;
        this.storagePackagingList = [];
        this.values = [];
        this.uuid = '';
        this.formControlService.reset();
    }

    //SubTable in the sideBar
    onNewRowAddComplete() {
        this.refreshAddNewRow = false;
        this.newRows = [];
    }

    onRefreshFromGroupComplete() {
        this.refreshFormGroup = false;
    }

    storageCurrency(value:any) {
        if (value['value'] !== undefined && value['value'] !== null) {
            this.inventoryForm.controls['eodCurrency'].setValue(this.inventoryForm.controls['storageCurrency'].value);
        }
    }

    storageuom(value:any) {
        if (value['value'] !== undefined && value['value'] !== null) {
            this.inventoryForm.controls['eodUom'].setValue(this.inventoryForm.controls['storageUom'].value);
        }
    }
}

