import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'xceler-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css']
})
export class PdfViewerComponent implements OnInit,OnChanges {

  @Output() onViewerClose = new EventEmitter();
  @Input() modalState: boolean = false;
  @Input() fileName: any;
  @Input() base64src: string = '';
  @Input() errorMessage:string;
  src: any = '';
  showError:boolean = false;
  pdfUrl: string = '';
  isLoading: boolean = true;
  @Input() convertToBase64:boolean = true;
  constructor(private sanitizer:DomSanitizer) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.loadPDF(this.base64src);
    //this.base64ToArrayBuffer(this.base64src);
  }

  private loadPDF(base64) {
    if (base64.toLowerCase().includes("no template found")) {
      this.showError = true;
      this.errorMessage = base64;
    } else {
      this.pdfUrl = 'data:application/pdf;base64,' + (this.sanitizer.bypassSecurityTrustResourceUrl(base64) as any).changingThisBreaksApplicationSecurity + "#view=FitH&toolbar=1";
      this.isLoading = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['base64src']) {
      this.isLoading = true;
      this.loadPDF(changes['base64src'].currentValue);

    }
    if(changes['errorMessage']) {
      if(changes['errorMessage'].currentValue !== null && changes['errorMessage'].currentValue !== undefined && changes['errorMessage'].currentValue.length > 0) {
        this.showError = true;
      }
    }
  }

  private base64ToArrayBuffer(base64) {
    try {
      if (base64.toLowerCase().includes("no template found")) {
        this.showError = true;
        this.errorMessage = base64;
      } else {
        this.src = [];
        if (base64 !== null && base64 !== undefined) {
          var binary_string = base64.replace(/\\n/g, '');
          if (this.convertToBase64) {
            binary_string = window.atob(base64);
            var len = binary_string.length;
            var bytes = new Uint8Array(len);
            for (var i = 0; i < len; i++) {
              bytes[i] = binary_string.charCodeAt(i);
            }
            this.src = bytes.buffer;
            this.isLoading = false;
          } else {
            this.src = base64;
            this.isLoading = false;
          }

        }
      }
    } catch (e) {
    }
  }

  closeModal() {
    this.modalState = false;
    this.onViewerClose.emit();
  }
}
