import {Component, OnInit} from '@angular/core';
import {CommonService} from '../../services/common.service';
import {Grid} from '../../grid/grid.model';
import {Tcolumn} from '../../grid/tcolumn.model';
import {defaultValue} from '../../grid/defaultValue.model';
import {MasterService} from '../../masters/services/MasterService';
import {environment} from '../../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {FormGroup} from '@angular/forms';
import {ConfirmationService, MessageService} from 'primeng';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-transport-allocation',
  templateUrl: './transport-allocation.component.html',
  styleUrls: ['./transport-allocation.component.css']
})
export class TransportAllocationComponent implements OnInit {
  refreshGrid: boolean = false;
  refreshFilter: boolean = false;
  gridFilters: any = [];
  plannedGridFilters: any = [];
  selectedRows: any[] =[];
  grid: Grid;
  plannedGrid: Grid;
  selectedPlannedObligation:any[] = [];
  columns: Map<string, Tcolumn>;
  lastGrid:Grid;
  addedFilter: any[] =['VESSEL_UNPLANNED'];
  vesselName:any[] = [];
  vesselId:any[] = [];
  loadPort:any[] = [];
  private selectedVesselId = "";
  unloadPort:any[] = [];
  items: any[] = [];
  selectedForm: string = '';
  totalElements: number = 0;
  showFetching: boolean = true;
  cardData:any = {};
  formGroup: FormGroup;
  unplannedCardValues: any[] = [0,0,0,0];
  toolbarConfig: any = {
    add: false,
    edit: false,
    copy: false,
    refresh: true,
    export: true,
    exportAll: true,
    import: false,
    audit: false,
    print: true,
    prefrences: true
  };
  toolbarConfigPlannedGrid: any = {
    add: false,
    edit: false,
    copy: false,
    refresh: true,
    export: true,
    exportAll: true,
    import: false,
    audit: false,
    print: true,
    prefrences: true
  };
  bottomPaneDisplay: boolean = false;
  display: boolean = false;
  gridHeight: any = '580px';
  bottomFormPaneDisplay: boolean = false;

  commodityList:any[] =[];
  plannedIdList:any[] =[];
  plannedLoadPort:any[] =[];
  plannedUnloadPort:any[] =[];
  deliveryMonth = '';
  refreshPlannedGrid: boolean = false;
  selectedPlannedRows: any[] =[];
  private selectedSeaFreightRow: any = {};
  private selectedPlannedObligationRows: any[] = [];
  refreshPlannedGridFilters: boolean = false;
  mode: string = 'create';
  rowData: any = {};
  showDeAllocateButton: boolean = false;
    disableAllocateButton: boolean = false;


  constructor(public commonService:CommonService,private masterCommonService:MasterService,
              private confirmationService: ConfirmationService, private messageService: MessageService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    let _this = this;
    this.route.queryParams.subscribe(params => {
      if(params !== undefined && params !== null && Object.keys(params).length > 0){
        let mode = params['mode'];
        if(mode === 'new') {
          this.openForm('sea');
        } else if(mode === 'allocated') {
          this.addedFilter = [];
          this.addedFilter.push('VESSEL_PARTIALLY_PLANNED');
          this.addedFilter.push('VESSEL_PLANNED');
          this.addedFilter.push('VESSEL_ACTUALIZED');
          this.addedFilter.push('VESSEL_SETTLED');
        } else if(mode === 'filter') {
          this.addedFilter = [];
          this.addedFilter.push('VESSEL_PARTIALLY_PLANNED');
          this.addedFilter.push('VESSEL_PLANNED');
          this.addedFilter.push('VESSEL_UNPLANNED');
          this.addedFilter.push('VESSEL_ACTUALIZED');
          this.addedFilter.push('VESSEL_SETTLED');
          this.loadPort = [params['loadport']];
          this.unloadPort = [params['unloadport']];
        }
      }
    })
    this.calculateHeight();
    this.getColumns();
    this.grid = new Grid(this.columns, true, true, true, true, 'tradeId', '/api/transportallocationv2/v1/getseafreightdetails?tenantId='+this.commonService.getFromStorage('tenantId'), undefined, 'Transport Allocation', undefined, undefined);
    this.grid.setGridConfiguration(false, true, true, false, false, false, true, true, false, false);
    this.grid.openSidebarOnUniqueClick = false;
    this.grid.addClickable('tradeId');
    this.grid.setToolbarConfig(false,false,false,false);
    this.grid.iconName = 'obligationDashboard';
    this.grid.useDefaultDuplicateFunction = false;
    this.grid.useDefaultEditFunction = false;
    this.createGridFilters();
     this.grid.gridFetchUrlType = "POST";
    this.grid.fetchPostPayloadFuction = function (value:any[] = []) {
      return _this.getCommonFilterPayload(true);
    };

    this.grid.canShowExpandGrid = function (value:any) {
      if(value['plannedObligationIds'] !== null && value['plannedObligationIds'].length > 0) {
        return true;
      }
      return false;
    };
    this.items = [{ label: 'Sea Transport', command: () => { this.openForm('sea'); }},
      {label: 'Rail Transport', command: () => {this.openForm('rail');}},
      {label: 'Road Transport', command: () => {this.openForm('road');}},
      {label: 'Air Transport', command: () => {this.openForm('air');}}
    ];
    this.getPlannedGrid();
    this.createExpandgrid();
    this.getStats();
  }

  private getPlannedGrid() {
    let _this = this;
    let columns: Map<string, Tcolumn> = new Map();
    let index= 1;
    let plannedQuantityFunction = function (value:any[]) {
      return value[0]+' '+value[1];
    }
    columns.set('planId', new Tcolumn('planId', 'Plan ID', 'LB', index++, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('plannedObligationId', new Tcolumn('plannedObligationId', 'Planned Obligation Id', 'LB', index++, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('tradeTransactionType', new Tcolumn('tradeTransactionType', 'Trade Type', 'LB', index++, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('tradeDateTime', new Tcolumn('tradeDateTime', 'Trade Date', 'D', index++, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('commodity', new Tcolumn('commodity', 'Commodity', 'LB', index++, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('counterparty', new Tcolumn('counterparty', 'Counterparty', 'LB', index++, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('plannedQuantity', new Tcolumn('plannedQuantity', 'Planned Quantity', 'LB', index++, true, undefined, true, new defaultValue('', undefined, undefined, undefined, false, true, false, plannedQuantityFunction, ['plannedQuantity', 'quantityUOM']), 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('deliveryEndDate', new Tcolumn('deliveryEndDate', 'Delivery Date', 'D', index++, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('incoterm', new Tcolumn('incoterm', 'Incoterm', 'LB', index++, false, undefined, false, '', 'H', [], undefined, 1, undefined, undefined, undefined, undefined));
    columns.set('location', new Tcolumn('location', 'Location (Load/Unload Port)', 'LB', index++, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('cropOrigin', new Tcolumn('cropOrigin', 'Crop Origin', 'LB', index++, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('grade', new Tcolumn('grade', 'Grade', 'LB', index++, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('profitcenter', new Tcolumn('profitcenter', 'Profit Center', 'LB', index++, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.plannedGrid = new Grid(columns, true, true, true, true, 'tradeId', '/api/plannedObligation/v1/getplannedobligationsfortransportallocation?tenantId=' + this.commonService.getFromStorage('tenantId'), undefined, 'Transport Allocation', undefined, undefined);
    this.plannedGrid.setGridConfiguration(false, false, false, false, false, false, true, true, false, false);
    this.plannedGrid.openSidebarOnUniqueClick = false;
    this.plannedGrid.setToolbarConfig(false, false, false, false);
    this.plannedGrid.gridFetchUrlType = 'POST';
    this.plannedGrid.fetchPostPayloadFuction = function(value: any[] = []) {
      return _this.getCommonFilterPayloadForPlannedGrid();
    };
  }

  private getStats() {
    let _this = this;
    _this.cardData = null;
    _this.unplannedCardValues = [];
    this.commonService.getJSONByURL(environment.base_url+'/api/transportallocationv2/v1/getfreightdetailsstats?tenantId='+this.commonService.getFromStorage('tenantId')).subscribe((next) => {
      _this.cardData = next;
      _this.unplannedCardValues = [_this.cardData['vesselUnplanned']['sea'],_this.cardData['vesselUnplanned']['rail'],_this.cardData['vesselUnplanned']['air'],_this.cardData['vesselUnplanned']['road']];
    });
  }


  private createExpandgrid() {
    let grid = new Grid(this.getExpandColumns(), true, true, true, true, 'plannedObligationId', '/api/transportallocationv2/v1/getallocatedseafreightplannedobligation?tenantId='+ this.commonService.getFromStorage('tenantId')+'&vesselId=', undefined, 'Obligations Dashboard', undefined, undefined);
    grid.setPreviousLevelPrimaryKey('vesselId');
    grid.showCheckbox = true;
    this.grid.expandGrid = grid;
  }


  private getCommonFilterPayload(useFilters:boolean = false) {
    let obj = {};
    obj['vesselUnplanned'] = this.addedFilter.includes('VESSEL_UNPLANNED');
    obj['vesselPartiallyPlanned'] = this.addedFilter.includes('VESSEL_PARTIALLY_PLANNED');
    obj['vesselPlanned'] = this.addedFilter.includes('VESSEL_PLANNED');
    obj['vesselActualized'] = this.addedFilter.includes('VESSEL_ACTUALIZED');
    obj['vesselSettled'] = this.addedFilter.includes('VESSEL_SETTLED');
    if(useFilters) {
      obj['vesselName'] = this.vesselName;
      obj['vesselId'] = this.vesselId;
      obj['loadPort'] = this.loadPort;
      obj['unloadPort'] = this.unloadPort;
    }
    return obj;
  }

  private getCommonFilterPayloadForPlannedGrid() {
    let obj = {};
    obj['commodity'] = this.commodityList;
    obj['plannedId'] = this.plannedIdList;
    obj['loadPort'] = this.plannedLoadPort;
    obj['unloadPort'] = this.plannedUnloadPort;
    obj['deliveryMonth'] = this.deliveryMonth;
    return obj;
  }

  private getColumns() {
    let freightCapacityFun = function (value:any[] ) {
      return value[0]+' MT';
    }
    this.columns = new Map();
    this.columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    this.columns.set('vesselId', new Tcolumn('vesselId', 'Vessel ID', 'LB', 1, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('charterparty', new Tcolumn('charterparty', 'Charter Party', 'LB', 2, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('freightCapacity', new Tcolumn('freightCapacity', 'Freight Capacity', 'LB', 3, false, undefined, true, new defaultValue('',undefined,undefined,undefined,false,true,false,freightCapacityFun), 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('vesselName', new Tcolumn('vesselName', 'Vessel Name', 'LB', 4, true, this.masterCommonService.getBooleanFromLabel('Buy','Sell',false,'BUY','SELL'), true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('imo', new Tcolumn('imo', 'IMO', 'LB', 5, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('vesselFlag', new Tcolumn('vesselFlag', 'Vessel Flag', 'L', 6, true, this.commonService.getCountryList(), true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    this.columns.set('year', new Tcolumn('year', 'Year', 'D', 7, true, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true},date:{format:'yyyy'}}));
  }

  private getExpandColumns() {
    let _this = this;
    let columns: Map<string, Tcolumn> = new Map();
    let index= 1;
    let plannedQuantityFunction = function (value:any[]) {
      return value[0]+' '+value[1];
    }
    let tradeTransactionType = function(value: any[]) {
      let html: any;
      if (value[0] === 'BUY') {
        html = _this.commonService.getSafeHtml('<div style="display: inline-flex">' +
            '<img src="../../../assets/images/svg/buyArrow.svg" alt="" width="14px" height="14px">' +
            '<p style="color:#000000;margin: 0;padding: 0;font-size: 14px;margin-left: 10px;white-space: nowrap !important;">Buy</p></div>');
      } else {
        html = _this.commonService.getSafeHtml('<div style="display: inline-flex">' +
            '<img src="../../../assets/images/svg/sellArrow.svg" alt="" width="14px" height="14px">' +
            '<p style="color:#000000;margin: 0;padding: 0;font-size: 14px;margin-left: 10px;white-space: nowrap !important;">Sell</p></div>');
      }
      return html;
    };
    columns.set('planId', new Tcolumn('planId', 'Plan ID', 'LB', index++, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('plannedObligationId', new Tcolumn('plannedObligationId', 'Planned Obligation Id', 'LB', index++, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('tradeTransactionType', new Tcolumn('tradeTransactionType', 'Trade Type', 'LB', index++, false, undefined, true, new defaultValue('',undefined,undefined,undefined,false,true,false,tradeTransactionType), 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('tradeDateTime', new Tcolumn('tradeDateTime', 'Trade Date', 'D', index++, false,undefined,true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('commodity', new Tcolumn('commodity', 'Commodity', 'LB', index++, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('counterparty', new Tcolumn('counterparty', 'Counterparty', 'LB', index++, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('plannedQuantity', new Tcolumn('plannedQuantity', 'Planned Quantity', 'LB', index++, false, undefined, true, new defaultValue('',undefined,undefined,undefined,false,true,false,plannedQuantityFunction,['plannedQuantity','quantityUOM']), 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('deliveryEndDate', new Tcolumn('deliveryEndDate', 'Delivery Date', 'D', index++, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('incoterm', new Tcolumn('incoterm', 'Incoterm', 'LB', index++, false, undefined, true, '', 'H', [], undefined, 1, undefined, undefined, undefined, undefined));
    columns.set('location', new Tcolumn('location', 'Location (Load/Unload Port)', 'LB', index++, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('cropOrigin', new Tcolumn('cropOrigin', 'Crop Origin', 'LB', index++, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('grade', new Tcolumn('grade', 'Grade', 'LB', index++, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('profitcenter', new Tcolumn('profitcenter', 'Profit Center', 'LB', index++, false, undefined, true, '', 'B', [], undefined, 1, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    return columns;
  }

  onUniqueClick(value: any) {

  }

  onRefreshComplete(value: any) {
    this.refreshGrid = false;
    this.showDeAllocateButton = false;
    this.selectedPlannedObligation = [];
    this.bottomPaneDisplay = false;

  }


  onFilterChange(value: any) {
    if(value['title'] === 'Vessel Id') {
      this.vesselId = value['selected'];
    } else if(value['title'] === 'Vessel Name') {
      this.vesselName = value['selected'];
    } else if(value['title'] === 'Load Port') {
      this.loadPort = value['selected'];
    } else if(value['title'] === 'Unload Port') {
      this.unloadPort = value['selected'];
    }
    this.refreshGrid = true;
  }

  getTypeLabel(type: any) {
    if (type === 'VESSEL_UNPLANNED') {
      return 'Vessel Unplanned';
    } else if (type === 'VESSEL_PARTIALLY_PLANNED') {
      return 'Vessel Partially Planned';
    } else if (type === 'VESSEL_PLANNED') {
      return 'Vessel Planned';
    } else if (type === 'VESSEL_ACTUALIZED') {
      return 'Vessel Actualized';
    } else if (type === 'VESSEL_SETTLED') {
      return 'Vessel Settled';
    }
  }

  onCardUnselect(cardId: any) {
    this.refreshGrid =false;
    this.loadPort = [];
    this.unloadPort = [];
    this.addedFilter.splice(this.addedFilter.indexOf(cardId),1);
    this.createGridFilters();
    this.refreshGrid = true;
  }

  clearAllFilter() {
    let _this = this;
    let filterAdded = [...this.addedFilter];
    filterAdded.forEach(function (type) {
      _this.removeFilterFromAddedList(type);
    });
    this.refreshFilter = true;
    this.refreshGrid =false;
    this.createGridFilters();
    this.refreshGrid = true;
  }

  private createGridFilters() {
    let payload = this.getCommonFilterPayload();
    let _this = this;
    this.resetFilters();
    this.commonService.post(environment.base_url+'/api/transportallocationv2/v1/getfreightdetailsfilters?tenantId='+this.commonService.getFromStorage('tenantId'),payload).subscribe((next) => {
      _this.gridFilters = [];
      _this.gridFilters.push({title:'Vessel Name',list:_this.masterCommonService.getListFromArray(next['vesselName'],false,false)});
      _this.gridFilters.push({title:'Vessel Id',list:_this.masterCommonService.getListFromArray(next['vesselId'],false,false)});
      _this.gridFilters.push({title:'Load Port',list:_this.masterCommonService.getListFromArray(next['loadPort'],false,false),selected:this.loadPort});
      _this.gridFilters.push({title:'Unload Port',list:_this.masterCommonService.getListFromArray(next['unloadPort'],false,false),selected:this.unloadPort});
    });
  }

  private createPlannedGridFilters() {
    let _this = this;
    this.resetPlannedFilters();
    //this.refreshPlannedGridFilters = false;
    this.commonService.getJSONByURL(environment.base_url+'/api/plannedObligation/v1/getplannedobligationsfiltersfortransportallocation?tenantId='+this.commonService.getFromStorage('tenantId')).subscribe((next) => {
      _this.plannedGridFilters = [];
      _this.plannedGridFilters.push({title:'Commodity',list:_this.masterCommonService.getListFromArray(next['commodity'],false,false)});
      _this.plannedGridFilters.push({title:'Plan Id',list:_this.masterCommonService.getListFromArray(next['plannedId'],false,false)});
      _this.plannedGridFilters.push({title:'Load Port',list:_this.masterCommonService.getListFromArray(next['loadPort'],false,false)});
      _this.plannedGridFilters.push({title:'Unload Port',list:_this.masterCommonService.getListFromArray(next['unloadPort'],false,false)});
      _this.plannedGridFilters.push({title:'Delivery Month',value:'',type:'D',placeholder:'Select Delivery Month',format:'MMMM',minMode:'month'});
    //  _this.refreshPlannedGridFilters = true;

    });
  }


  private resetFilters() {
    this.vesselName = [];
    this.vesselId = [];
    this.gridFilters = [];
    this.gridFilters.push({title:'Vessel Name',list:[]});
    this.gridFilters.push({title:'Vessel Id',list:[]});
    this.gridFilters.push({title:'Load Port',list:[]});
    this.gridFilters.push({title:'Unload Port',list:[]});
  }

  private resetPlannedFilters() {
    this.commodityList = [];
    this.plannedIdList = [];
    this.plannedLoadPort = [];
    this.plannedUnloadPort = [];
    this.deliveryMonth = '';
    this.plannedGridFilters = [];
    this.plannedGridFilters.push({title:'Commodity',list:[]});
    this.plannedGridFilters.push({title:'Plan Id',list:[]});
    this.plannedGridFilters.push({title:'Load Port',list:[]});
    this.plannedGridFilters.push({title:'Unload Port',list:[]});
    this.plannedGridFilters.push({title:'Delivery Month',value:'',type:'D',placeholder:'Select Delivery Month',format:'MMMM',minMode:'month'});
  }

  removeFilterFromAddedList(type) {
    this.addedFilter.splice(this.addedFilter.indexOf(type), 1);
    if (type === 'Confirmed' || type === 'Unconfirmed') {
      this.grid.removeFilter('tradeStatus');
    } else {
      this.grid.removeFilter('tradeObligationState');
    }
  }

  onCardSelect(cardId: any) {
    this.refreshGrid =false;
    this.loadPort = [];
    this.unloadPort = [];
    this.addedFilter.push(cardId);
    this.createGridFilters();
    this.refreshGrid = true;
  }

  openForm(type: string) {
    this.mode = 'create';
    this.selectedForm = type;
  }


  onChangeFilter(value: any, dropdown: any) {

  }

  onClose() {
    this.rowData = null;
    this.mode = 'create';
    this.selectedForm = '';
  }

  getCardValue(key: string, type: string) {
    if(this.cardData !== null && this.cardData !== undefined && this.cardData[key] !== null && this.cardData[key] !== undefined) {
      return (this.cardData[key][type] === null || this.cardData[key][type] === undefined)?0:this.cardData[key][type];
    }
    return 0;
  }

  onSaveComplete() {
    this.refreshGrid = true;
    this.selectedForm = '';
    this.getStats();
    this.createGridFilters();
  }

    onCloseBottomPane() {
      this.selectedRows = [];
      this.grid.expandGrid.selectedRows = [];
      this.bottomPaneDisplay = false;
      this.showDeAllocateButton = false;
    }

  onRowSelectionChange(value: any) {
    if(value !== undefined && value !== null && value.length ===  1) {
      this.selectedSeaFreightRow = value[0];
      this.bottomPaneDisplay = true;
    } else {
      this.bottomPaneDisplay = false;
    }

  }

  allocatePlan() {
    this.bottomPaneDisplay = false;
    this.createPlannedGridFilters();
    this.display = true;
  }

  closeForm() {
    this.display = false;
    this.selectedRows = [];
  }

  calculateHeight() {
    let usableHeight = 0.8 *  window['visualViewport'].height;
    this.gridHeight = (usableHeight - 110) + 'px';
  }

  onCloseBottomPaneForm() {
    this.bottomFormPaneDisplay = false;
    this.selectedPlannedRows = [];
  }

  allocateTransport() {
    let _this = this;
    let selectedPlannedObligation:any[] = [];
    this.selectedPlannedObligationRows.forEach(function (plannedObligation:any) {
      selectedPlannedObligation.push(plannedObligation['plannedObligationId']);
    });
    this.disableAllocateButton = true;
    this.commonService.post(environment.base_url+'/api/transportallocationv2/v1/allocateseafreightdetails?tenantId='+this.commonService.getFromStorage('tenantId')+'&vesselId='+this.selectedSeaFreightRow['vesselId'],selectedPlannedObligation).subscribe((next)=> {
      _this.showToast(next['status']);
      _this.display = false;
      _this.bottomFormPaneDisplay = false;
      _this.refreshGrid = true;
      _this.selectedRows = [];
      _this.selectedPlannedObligationRows = [];
      _this.disableAllocateButton = false;
      _this.getStats();
    },(error:HttpErrorResponse) => {
      _this.disableAllocateButton = false;
      _this.showToast(_this.commonService.getHttpErrorMessage(error,'Sea Transport Allocation'),'error');
    });
  }

  onPlannedRefreshDone(value: any) {
    this.refreshPlannedGridFilters = false;
    this.refreshPlannedGrid = false;
  }

  onUnallocatedPlannedRowSelected(value: any) {
    if(value !== undefined && value !== null && value.length > 0) {
      this.selectedPlannedObligationRows = value;
      this.bottomFormPaneDisplay = true;
    } else {
      this.bottomFormPaneDisplay = false;
    }
  }

  onFilterChangePlannedGrid(value: any) {
    if(value['title'] === 'Commodity') {
      this.commodityList = value['selected'];
    } else if(value['title'] === 'Plan Id') {
      this.plannedIdList = value['selected'];
    } else if(value['title'] === 'Load Port') {
      this.plannedLoadPort = value['selected'];
    } else if(value['title'] === 'Unload Port') {
      this.plannedUnloadPort = value['selected'];
    }  else if(value['title'] === 'Delivery Month') {
      this.deliveryMonth = value['value'];
    }
    this.refreshPlannedGrid = true;
  }

  showToast(msg, severity: string = 'success') {
    this.messageService.add({
      severity: severity,
      summary: msg
    });
  }

  onEditClick(value: any) {
    this.selectedRows = [];
    this.bottomPaneDisplay = false;
    this.mode = 'update';
    this.rowData = value;
    this.selectedForm = 'sea';
  }

    onCopy(value: any) {
      this.bottomPaneDisplay = false;
      this.selectedRows = [];
      this.mode = 'copy';
      this.rowData = value;
      this.selectedForm = 'sea';
    }

  onExpandSelection(value: any) {
    let vesselId = value['rowData']['vesselId']
    if(this.selectedVesselId !== vesselId) {
      if(this.lastGrid !== null && this.lastGrid !== undefined) {
        this.lastGrid.selectedRows = [];
        this.lastGrid = value['grid'];
      }
      this.selectedVesselId = '';
    }
    this.selectedVesselId = vesselId;
    this.lastGrid = value['grid'];
    this.selectedPlannedObligation = value['value'];
    if(this.selectedPlannedObligation !== null && this.selectedPlannedObligation !== undefined && this.selectedPlannedObligation.length > 0) {
      this.showDeAllocateButton = true;
      this.bottomPaneDisplay = true;
    } else {
      this.showDeAllocateButton = false;
      this.selectedPlannedObligation = [];
      this.bottomPaneDisplay = false;
    }
  }

  deallocate() {
    let _this = this;
    let planIds:any[] = [];
    let message = "Deallocate all Planned Obligation under plan Ids : ";
    this.selectedPlannedObligation.forEach(function (obligation:any) {
      if(!planIds.includes(obligation['planId'])) {
        planIds.push(obligation['planId']);
      }
    });
    message += planIds.reduce((current, value, index) => {
      if(index > 0)
        current += ',';

      return current + value;
    }, '');
    message+=".\nAre you sure you want to continue ?"
    this.bottomPaneDisplay = false;
    _this.confirmationService.confirm({
      message: message,
      accept: () => {
        let payload = {
          vesselId:this.selectedVesselId,
          planIds:planIds
        }
        this.commonService.post(environment.base_url+'/api/transportallocationv2/v1/deallocateseafreightdetails?tenantId='+this.commonService.getTenantId(),payload).subscribe((next:any) => {
          _this.showToast("Planned Obligation Deallocated Successfully");
          _this.selectedPlannedObligation = [];
          _this.refreshGrid = true;
          _this.getStats();
        },(error:HttpErrorResponse) => {
          _this.showToast(_this.commonService.getHttpErrorMessage(error,"Transport Allocation"),'error');
        });
      }, reject: () => {
        _this.bottomPaneDisplay = true;
      }
    });

  }
}
