<p-dialog #ds (onHide)="canceled()" [(visible)]="modalStateDeal" [baseZIndex]="25"
          [closeOnEscape]="false"
          [draggable]="false" [maximizable]="false" [modal]="true"
          [style]="{ width: '80%',minheight: '85%',maxHeight:'85%', padding:'0px'}">

  <p-header [style]="{fontSize:'20px'}">
    New Deal Slip
  </p-header>
  <div [ngStyle]="{'height': dealSlipHeight}">
    <ctrm-input-form #dealSlipForm (OnApply)="closeModal($event)"
                     (OnCancel)="canceled()"
                     [columnsMap]="grid.getColumn()"
                     [updatePayloadFunction]="updatepayloadfunction"
                     [gridConfig]="grid.getGridConfig()"
                     [gridTitle]="grid.getTitle()"
                     [heading]="grid.getTitle()"
                     [modalState]="true"
                     [noOfColumns]="3"
                     [postBtn]="true" [readOnly]="true"
                     [saveUrl]="grid.getSaveUrl()"
                     [sections]="grid.getSections()"
                     [showAddButtons]="false"
                     [showFooter]="true"
                     [showOnModal]="false"
                     [uniqueKey]="grid.getPrimaryKey()"
                     (onButtonClick)="onClickLink($event)">
    </ctrm-input-form>
  </div>
</p-dialog>

<app-delivery-schedule-dialog [display]="scheduleForm" (onFormClose)="onFormClose()" (onSave) ="onCloseDeliveryScheduleForm($event)" [data]="formRowData"></app-delivery-schedule-dialog>

<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>
