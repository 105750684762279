import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'login-new',
  templateUrl: './login-new.component.html',
  styleUrls: ['./login-new.component.css']
})
export class LoginNewComponent implements OnInit {
  @Output() onClickLogin = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
    $("p").find("a").click(function (e) {
      e.preventDefault();
      var section = $(this).attr("href");
      $("html, body").animate({
        scrollTop: $(section).offset().top
      });
    });

    $(window).scroll(function () {
      if ($(this).scrollTop() < 500) { //use `this`, not `document`
        $('.fabBtn').css({
          'display': 'none'
        });
      } else {
        $('.fabBtn').css({
          'display': 'block'
        });
      }
    });

    $("#fabBtn").click(function (e) {
      e.preventDefault();
      var section = "#mainSection";
      $("html, body").animate({
        scrollTop: $(section).offset().top
      });
    });
  }

  clickLogin() {
    this.onClickLogin.emit();
  }

  scrollToConnect(event: MouseEvent) {
    event.preventDefault();
    var section = '#formSection';
    $("html, body").animate({
      scrollTop: $(section).offset().top
    });
  }
}
