<p-sidebar [visible]="true"
           [styleClass]="expanded?'expanded':'collapsed'"
           [showCloseIcon]="false">
    <div class="boxStyle">
        <div *ngIf="expanded" class="content">
            <div class="header">
                <p>List of EOD Reports</p>
                <i class="fas fa-search" ></i>
            </div>
            <div class="contentBox">
                <div *ngFor="let link of links;index as i" (click)="onClick(link)" >
                    <div class="linkitem">
                        <div class="dot"></div>
                        <p class="link" [ngClass]="selected === link['eodRunDate']?'selectedLink':''">{{getTemplateName(link,i)}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div [ngClass]="expanded?'thumbstyle':'thumbstyleWhenClose'" (click)="toggleExpanded()" pTooltip="View EOD List">
            <i class="iconclass" [ngClass]="expanded?'fas fa-angle-left':'fas fa-angle-right'" ></i>
        </div>
    </div>
</p-sidebar>
