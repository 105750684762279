<div class="sectionCardTop">
    <div class="breadcrumbPane">
        <ctrm-breadcrumb [collapsabel]="false"
                         [items]="breadCrumbService.getBreadCrumb()"
                         [showIcon]="false"></ctrm-breadcrumb>
    </div>
    <div class="actionPane">
        <div class="screenDetails">
            <img [src]="'../../../assets/images/svg/papers.svg'"/>
            <p>Report Dashboard</p>
        </div>
    </div>
</div>
<div style="width:100%;display: inline-flex;padding:10px;padding-top:0;padding-bottom:0">
    <div style="width:15%;max-width: 15%;min-width: 15%;" >
        <report-sidemenu [menuItems]="menuItems" [selectMenuItem]="'position'" (onClickMenuItem)="loadIframe($event)"></report-sidemenu>
    </div>

    <div class="main"  style="overflow: auto; max-height: 900px;width:85%;max-width: 85%;min-width: 85%;">
        <iframe *ngIf="urlSafe !== null && urlSafe !== undefined && completedURLs.includes(this.value) && this.value!='mpob'" #iframeObj [src]="urlSafe" width="100%" height="900px"></iframe>
        <p *ngIf="!completedURLs.includes(this.value)" style="margin-left: 40%;font-size: 20px;margin-top: 20%;">Work In Progress </p>
        <div *ngIf="urlSafe !== null && urlSafe !== undefined && this.value =='mpob'">
            <app-mpob-generation></app-mpob-generation>
        </div>
    </div>
</div>
