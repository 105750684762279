import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-childsidemenu',
  templateUrl: './childsidemenu.component.html',
  styleUrls: ['./childsidemenu.component.css']
})
export class ChildsidemenuComponent implements OnInit {

  @Input() menuItem:any[] = [];
  @Output() onClickMenuItem= new EventEmitter();
  @Input() selectMenuItem:any= '';
  selectedMenuHeader:any = '';
  constructor() { }

  ngOnInit(): void {
  }

  onClickMenu(menuItem: any) {
    this.selectMenuItem = menuItem['key'];
    this.onClickMenuItem.emit(menuItem);

  }

  onClickMenuHead(menuItem: any) {
    let childs = (menuItem.childs !== undefined && menuItem.childs !==null && menuItem.childs.length > 0)
    if(this.selectedMenuHeader === menuItem['key']) {
      this.selectedMenuHeader = '';
      this.selectMenuItem = '';
    } else {
      this.selectedMenuHeader = menuItem['key'];
      if(!childs) {
        this.collapsePrevious();
        this.onClickMenu(menuItem);
      }
    }
  }

  private collapsePrevious() {
    if(this.selectedMenuHeader !== null && this.selectedMenuHeader !== undefined && this.selectedMenuHeader.length > 0) {
      let el:HTMLElement = document.getElementById(this.selectedMenuHeader+"_head");
      if(el !== null) {
        el.classList.add('collapsed');
        el.setAttribute('aria-expanded','false');
        document.getElementById(this.selectedMenuHeader).classList.remove('show');
      }
    }
  }
}
