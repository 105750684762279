import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CommonService} from "../../../services/common.service";

@Component({
  selector: 'obligation-date-range',
  templateUrl: './obligation-date-range.component.html',
  styleUrls: ['./obligation-date-range.component.css']
})
export class ObligationDateRangeComponent implements OnInit,OnChanges {

  @Input() selectedStartDate:any;
  @Input() selectedEndDate:any;
  @Input() preDefinedRanges:any[] =[];
  @Input() dateRangePresetTitle: any = "Date Range";
  @Output() onPresetSelected = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Input() selectedPreset: any = 'Forward 3 months';
  @Input() selectedPresetObject = {};
  dateRangePreset = {};
    rangeDates: any[] = [];
  dates: Date[] = [];
  constructor(public commonService:CommonService) { }

  ngOnInit(): void {
    if(this.selectedPreset === this.dateRangePresetTitle) {
      this.dateRangePreset= {title:this.dateRangePresetTitle,startDate:this.selectedPresetObject['startDate'],endDate:this.selectedPresetObject['endDate']};
    } else {
      this.dateRangePreset= {title:this.dateRangePresetTitle,startDate:new Date(),endDate:this.commonService.addDaysInDate(new Date(),7)};
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(changes['selectedStartDate']) {
      if(changes['selectedStartDate'].currentValue === null || changes['selectedStartDate'].currentValue === undefined || changes['selectedStartDate'].currentValue.toString().length === 0) {
        this.selectedStartDate = new Date();
      }
    }
    if(changes['selectedEndDate']) {
      if(changes['selectedEndDate'].currentValue === null || changes['selectedEndDate'].currentValue === undefined || changes['selectedEndDate'].currentValue.toString().length === 0) {
        this.selectedEndDate = new Date();
      }
    }
    if(changes['selectedPresetObject']) {
      if(changes['selectedPresetObject'].currentValue === null || changes['selectedPresetObject'].currentValue === undefined || changes['selectedPresetObject'].currentValue === {} || changes['selectedPresetObject'].currentValue.toString().length === 0) {
        this.onSelectPreset(this.preDefinedRanges[0]);
      } else {
        if(this.selectedPresetObject['title'] === this.dateRangePresetTitle) {
          this.dateRangePreset = this.selectedPresetObject;
          this.onSelectDateRange();
        } else {
          this.onSelectPreset(this.selectedPresetObject);
        }
      }
    }
  }

  onSelectPreset(preset: any) {
    this.rangeDates = [preset['startDate'],preset['endDate']];
    this.selectedPresetObject = preset;
    this.selectedPreset = preset['title'];
  }

  onSelectDateRange() {
    this.rangeDates = [this.dateRangePreset['startDate'],this.dateRangePreset['endDate']];
    this.selectedPresetObject = this.dateRangePreset;
    this.selectedPreset = this.dateRangePresetTitle;
  }

  getDateRangeDisplay(){
    if(this.selectedPreset.toString().includes('Last')){
      return this.commonService.getFormattedDate(this.selectedPresetObject['endDate'],'dd MMMM yyyy') + ' - ' + this.commonService.getFormattedDate(this.selectedPresetObject['startDate'],'dd MMMM yyyy')
    }else{
      return this.commonService.getFormattedDate(this.selectedPresetObject['startDate'],'dd MMMM yyyy')+ ' - ' + this.commonService.getFormattedDate(this.selectedPresetObject['endDate'],'dd MMMM yyyy')
    }
  }

  cancel() {
    this.onCancel.emit("Canceled");
  }

  onClickApply() {
    this.onPresetSelected.emit(this.selectedPresetObject);
  }

  onDateRangeChange(value: any) {
    if(this.selectedPreset !== this.dateRangePresetTitle) {
      if(this.selectedPresetObject['startDate'] !== value[0] || this.selectedPresetObject['endDate'] !== value[1]) {
        this.selectedPresetObject = this.dateRangePreset;
        this.selectedPreset = this.dateRangePresetTitle;
        this.selectedPresetObject['startDate'] = value[0];
        this.selectedPresetObject['endDate'] = value[1];
      }
    } else {
      this.selectedPresetObject = this.dateRangePreset;
      this.selectedPresetObject['startDate'] = value[0];
      this.selectedPresetObject['endDate'] = value[1];
    }
  }
}
