import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonService} from '../../../services/common.service';
import {environment} from '../../../../environments/environment';
import {HttpErrorResponse} from '@angular/common/http';
import {MessageService} from 'primeng';

@Component({
  selector: 'future-ticker-graph',
  templateUrl: './future-ticker-graph.component.html',
  styleUrls: ['./future-ticker-graph.component.css']
})
export class FutureTickerGraphComponent implements OnInit {

  @Input() display: boolean;
  @Output() onClose = new EventEmitter();
  @Input() width: any = '100%';
  @Input() tickerValue: any;
  toggleButton: boolean = false;
  showGraphSection: boolean = true;
  date = new Date();
  graphValue: any;
  //Info Box
  infoDisplay: boolean = false;
  tickerInfoValue: any;
  commodityInfoValue: any;
  tickerHeader: any;
  maxHigh: number;
  minLow: number;
  settlementPrice: number;

  constructor(public commonService: CommonService, private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.getGraphData();

  }

  onToggleChange(value: boolean) {
    this.showGraphSection = this.showGraphSection;
  }

  onClickClose() {
    this.onClose.emit();
  }

  getGraphData() {
    let tickerObj = {
      ticker: this.tickerValue['tickerName'],
      date: this.commonService.convertDatetoUTC(this.date)
    };
    this.commonService.post(environment.base_url + '/api/futurecommodityprice/v1/getavaiablepricebyticker?tenantId=' + this.commonService.getFromStorage('tenantId'), tickerObj).subscribe(next => {
      if (next !== undefined && next !== null) {
        this.graphValue = next;
        if (this.graphValue['pxHigh'] !== undefined && this.graphValue['pxHigh'] !== null
            && this.graphValue['pxLow'] !== undefined && this.graphValue['pxLow'] !== null &&
            this.graphValue['settlementPrice'] !== undefined && this.graphValue['settlementPrice'] !== null) {
          this.maxHigh = this.graphValue['pxHigh'];
          this.minLow = this.graphValue['pxLow'];
          this.settlementPrice = this.graphValue['settlementPrice'];
        }
      }
    }, (error: HttpErrorResponse) => {
      this.messageService.add({severity: 'error', summary: this.commonService.getHttpErrorMessage(error, undefined)});
    });
  }

  showToast(msg, severity: string = 'success') {
    this.messageService.add({
      severity: 'info',
      summary: msg
    });
  }


  onClickInfo() {
    this.commonService.getJSONByURL(environment.base_url + '/api/futuremarketdashboard/v1/getfuturegraphinfo?tenantId=' +
        this.commonService.getTenantId() + '&ticker=' + this.tickerValue['tickerName']).subscribe((next: any) => {
      this.commodityInfoValue = next['CommodityDetails'][0];
      this.tickerInfoValue = next['tickerDetails'][0];
      this.tickerHeader = this.tickerValue['tickerName'];
      this.infoDisplay = true;
    });
  }

  onCloseForm() {
    this.infoDisplay = false;
  }
}
