import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Breadcrumb} from "../Components/ctrm-breadcrumb/breadcrumb.model";
import {BreadcrumbService} from "../Components/ctrm-breadcrumb/breadcrumb.service";
import {CommonService} from "../services/common.service";


@Component({
  selector: 'app-dash-sub-card',
  templateUrl: './dash-sub-card.component.html',
  styleUrls: ['./dash-sub-card.component.css']
})
export class DashSubCardComponent implements OnInit {
  @Input() subCards: any = [];
  @Input() title: any;
  @Input() childs: Breadcrumb[] = [];
  @Output() onBackPress = new EventEmitter();


  constructor(private router: Router, private breadcrumbService: BreadcrumbService, private commonService: CommonService) {

  }

  ngOnInit() {
  }

  back(bool) {
    this.onBackPress.emit(bool);
  }

  setSelected(item) {
    let _this = this;
    this.childs.forEach(function (child, i) {
      if (child.label === item['name']) {
        _this.childs[i].setIsSelected(true);
      } else {
        _this.childs[i].setIsSelected(false);
      }
    });
  }

  grid(item) {
    if (item['path']) {
      this.setSelected(item);
      this.breadcrumbService.pushBreadcrumb(new Breadcrumb(item['icon'], item['name'], item['path'], this.childs, true, false));
      this.commonService.saveToStorage("lastRoute", {routeTo: item['path'], skip: false});
      this.router.navigate([item['path']]);
    }
  }
}
