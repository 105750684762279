import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'xceler-button-groups',
  templateUrl: './xceler-button-groups.component.html',
  styleUrls: ['./xceler-button-groups.component.css']
})
export class XcelerButtonGroupsComponent implements OnInit, OnChanges {


  @Input() buttonNames: string[] = [];
  @Input() selected: string = '';
  @Output() onClick = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  OnClick(buttonName) {
    this.selected = buttonName;
    this.onClick.emit(buttonName);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selected']) {
      this.onClick.emit(changes['selected'].currentValue);
    }
  }
}
