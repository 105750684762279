<div class="container_breadcrumb">
  <ul class="nav">
    <li>
      <div (click)="goToDashboard()"
           class="liitem">
        <i class="fas fa-home" style="padding-top: 3px"></i>
        <i class="fas fa-chevron-right icon splitter"></i>
      </div>
    </li>
    <li *ngFor="let item of items;index as i"  [ngClass]="item['childs'].length>0?'drop':'disableListItem'">
      <div (click)="changeRouteMain(i,item['routeTo'],item['params'],item['skipLocationChange'])"
           *ngIf="item['childs'].length===0;else droplist"
           [ngClass]="!isLast(i)&&collapsabel&&(item['icon'].length>0&&item['label'].length>0)?'showicononly':''"
           class="liitem">
        <i *ngIf="showIcon  && item['icon'] !== undefined && item['icon'].length>0" [ngClass]="item['icon']"
           [pTooltip]="item['label'].length>0&&!showLabel?item['label']:''" style="padding-top: 3px"></i>
        <p [hidden]="!showLabel" style="padding-top: 1px">{{formatLabel(item['label'])}}</p>
        <i [hidden]="isLast(i)" class="fas fa-chevron-right icon splitter"></i>
      </div>
      <ng-template #droplist>
        <div (click)="changeRouteMain(i,item['routeTo'],item['params'],item['skipLocationChange'])"
             [ngClass]="!isLast(i)&&collapsabel&&(item['icon'].length>0&&item['label'].length>0)?'showicononly':''"
             class="liitem">
          <i *ngIf="showIcon && item['icon'] !== undefined && item['icon'].length>0"
             [ngClass]="item['icon'].length>0?item['icon']:''"
             style="padding-top: 3px"></i>
          <p style="padding-top: 1px">{{formatLabel(item['label'])}} </p>
          <i class="pi pi-chevron-down downicon"></i>
          <i [hidden]="isLast(i)" class="fas fa-chevron-right icon splitter"></i>
        </div>
        <ul appendTo="body" class="style-4">
          <li *ngFor="let citem of item['childs'];index as j" [id]="'option'+i+''+j" [ngClass]="!isSelected(citem,j,i)?'normal':'select'"
              appendTo="body">
            <div (click)="changeRouteChild(j,i,citem['routeTo'])" *ngIf="citem['childs'].length===0;else droplist"
                 class="liitem"
                 id="droplistitem">
              <i *ngIf="showIcon  && citem['icon'] !== undefined && citem['icon'].length>0"
                 [ngClass]="citem['icon']"></i>
              <p>{{formatLabel(citem['label'])}}</p>
            </div>
          </li>
        </ul>
      </ng-template>
    </li>
  </ul>
</div>
