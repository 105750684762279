import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {Tcolumn} from "../../tcolumn.model";
import {CommonService} from "../../../services/common.service";
import {environment} from "../../../../environments/environment";
import {DatePipe} from "@angular/common";
import {MessageService, Table} from "primeng";
import {FilterUtils} from "primeng/utils";
import {KeyValue} from "../../key-value.model";
import {Filter} from "../../preference/filter/filter.model";
import {Grid} from "../../grid.model";
import {ExcelServiceService} from "../../../services/excel-service.service";
import {messages} from "../../../services/common/messages";

@Component({
  selector: 'xceler-grid',
  templateUrl: './xceler-grid.component.html',
  styleUrls: ['./xceler-grid.component.css']
})
export class XcelerGridComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() columns: Tcolumn[] = [];
  @Input() expandedGrid:Grid;
  @Input() expandedRowdata:any;
  @Input() data: any[] = [];
  @Input() leftFixedColumns: any[] = [];
  @Input() leftFixedColumnsWidth: number[] = [];
  @Input() rightFixedColumns: any[] = [];
  @Input() rightFixedColumnsWidth: number[] = [];
  @Output() onExpandRowSelectionChange = new EventEmitter();
  @Input() showPlaceHolder: boolean = false;
  @Input() tableStyle = {backgroundColor: '#D9DBDE'};
  @Input() clickableColumns: any[] = [];
  @Input() showCheckbox: boolean = false;
  @Input() canDeleteRow: boolean = false;
  @Input() primaryKey: string = '';
  @Input() showRefresh: boolean =true;
  @Input() filter: Filter[];
  @Input() refreshFilter:boolean = false;
  @Output() onFilterRefreshComplete = new EventEmitter();
  @Input() noDataTemplate: TemplateRef<any>;
  @Input() footerColumns:any = [];
  @ViewChild('dt', {static: true}) table: Table;
  @ViewChild('ph', {static: true}) placeHolderDiv: HTMLDivElement;
  datePipe: DatePipe;
  gridHeight: string;
  placeHolderHeight: string;
  totalElements:number = 0;
  pageNumber:number = 0;
  paginatedData:any = [];
  @Input() selectedRow: any[] = [];
  @Input() showToolbar: boolean = false;
  @Output() onClickToolbarButton = new EventEmitter();
  @Output() onClickClickable = new EventEmitter();
  @Output() onSelectionChange = new EventEmitter();
  @Output() onValueChange = new EventEmitter();
  @Input() fetching: boolean = false;
  @Input() setHeight:boolean = true;
  @Input() addSearch: boolean = false;
  @Input() fixColumnsWidth: number = 200;
  @Input() reorderableGrid: boolean = true;
  @Output() onPageChange = new EventEmitter();
  @Input() fieldsWithCustomView:any[] =[];
  @Input() customViewTemplated:TemplateRef<any>[] =[];
  @Output()onButtonClick = new EventEmitter();
  @Input() useDefaultExport:boolean=true;
  @Output() onExportClick= new EventEmitter();
  pageSize: number = 20;
  totalPages: number = 0;

  constructor(public commonService: CommonService,private excelService: ExcelServiceService,private changeDetectionRef:ChangeDetectorRef,
              private messageService: MessageService) {
  }

  ngAfterViewInit(): void {
   this.changeDetectionRef.detectChanges();
  }

  @HostListener("window:resize", [])
  public onResize() {
    this.calculateHeight();
  }

  emitButtonClick(buttonName) {
    this.onClickToolbarButton.emit({button: buttonName});
  }

  calculateHeight() {
    if(this.table !== undefined && this.table !== null) {
      let offsetTop = this.table.el.nativeElement['offsetTop'];
      let offsetHeight = 0;
      if(this.table.el.nativeElement['offsetParent'] !== undefined && this.table.el.nativeElement['offsetParent'] !== null) {
        offsetHeight = this.table.el.nativeElement['offsetParent']['offsetHeight'];
      }
      if(this.getPagination()) {
        offsetHeight = offsetHeight - 30;
      }
      this.gridHeight = offsetHeight - (offsetTop + 20) + 'px';
      this.placeHolderHeight = offsetHeight - (offsetTop + 61) + 'px';
    }
  }

  ngOnInit(): void {
    this.createFilters();
    this.datePipe = new DatePipe('en-US');
    this.calculateHeight();
  }

  getDateForOutput(date: any, col: Tcolumn) {
    if (date !== undefined && date !== null && date.toString() !== 'Invalid Date') {
      return this.datePipe.transform(date, col.getFromExtra('date')['format'] !== undefined ? col.getFromExtra('date')['format'] : environment.dateFormat);
    }
  }

  getOutputValue(col: any, ri, rowData) {
    let outputValue = this.commonService.checkAndGetValue(col, rowData);
    if (outputValue === undefined || outputValue.length === 0) {
      if (col.getColumnType() === 'L' || col.getColumnType() === 'OB' || col.getColumnType() === 'B' || col.getColumnType() === 'ML') {
        outputValue = this.getListFieldValue(col, rowData[col.getField()], ri);
      } else if (col.getColumnType() == 'T' || col.getColumnType() == 'N' || col.getColumnType() == 'TA' || col.getColumnType() == 'LB' || col.getColumnType() == 'S' || col.getColumnType() == 'TN' || col.getColumnType() === 'SL') {
        outputValue = rowData[col.getField()];
      } else if (col.getColumnType() === 'C') {  // for checkbox
        outputValue = rowData[col.getField()];
      } else if (col.getColumnType() === 'D') {  // for date
        outputValue = this.getDateForOutput(rowData[col.getField()], col)
      } else if (col.getColumnType() === 'F') {    //for filepicer
        outputValue = rowData[col.getField() + 'FileName'];
      } else if (col.getColumnType() === 'DSL') {  //for dual slider
        outputValue = this.commonService.getDualSliderValue(rowData[col.getField()], col, rowData);
      }
    }
    return (outputValue === undefined || outputValue === null || outputValue.length === 0) ? '-' : outputValue;
  }

  getOutputValueForFooter(col:any) {
    if(col['valueFunction'] !== null && col['valueFunction'] !== undefined) {
      return this.commonService.runFunction([this.data],col['valueFunction']);
    } else {
      return col['field'];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && changes['data'].currentValue !== null&& changes['data'].currentValue !== undefined) {
      if(!Array.isArray(changes['data'].currentValue)) {
        let data:any = JSON.parse(JSON.stringify(changes['data'].currentValue));
        this.data = [];
        if (data['pageable']) {
          this.pageSize = data['pageable']['pageSize'];
          this.totalElements = data['totalElements'];
          this.totalPages = data['totalPages'];
          this.data = data['content'];
        }
      }
      this.calculateHeight();
    }
    if(changes['filter'] && changes['filter'] !== undefined) {
      this.commonService.applyFilters(this.table,null,changes['filter'].currentValue);
    }
    if(changes['refreshFilter'] && changes['refreshFilter'].currentValue) {
      this.commonService.applyFilters(this.table,null,[]);
      this.onFilterRefreshComplete.emit();
    }

    if(changes['expandedRowdata']) {
      if(changes['expandedRowdata'].currentValue !== null && changes['expandedRowdata'].currentValue !== undefined) {
        this.fetchData(changes['expandedRowdata'].currentValue);
      }
    }
  }

  isNotLast(index: number) {
    return (index < this.columns.length - 1);
  }

  refreshGridData() {
    this.emitButtonClick('refresh');
  }

  applyFilterGlobal(value: any) {

  }

  updateValue(value: any) {
    let _this = this;
    if (value[0] !== '@') {
      this.columns.forEach(function (column: Tcolumn) {
        _this.runFilter(column, value);
      });
    }
  }

  runFilter(column: Tcolumn, filterValue: string) {
    let _this = this;
    this.table.filteredValue = [];
    this.data.forEach(function (rowData, index) {
      if (FilterUtils['globalFilter'](_this.columns, index, rowData, filterValue)) {
        _this.table.filteredValue.push(rowData);
      }
    });
  }

  createFilters() {
    let _this = this;
    FilterUtils['globalFilter'] = function inCollection(columns: Tcolumn[], index: number, rowData: any, filter: any): boolean {
      if (filter === undefined || filter === null) {
        return true;
      }
      if (columns === undefined || columns === null || columns.length === 0) {
        return false;
      }
      let included = false;
      let label = '';
      columns.forEach(function (col: Tcolumn) {
        if (!included) {
          if (environment.componentListType.includes(col.getColumnType())) {
            col.getListOfValues().forEach(function (keyValuePair: KeyValue) {
              if (keyValuePair.getValue() === rowData[col.getField()]) {
                label = keyValuePair.getLabel();
              }
            });
            if (col.getColumnType() !== 'B') {
              included = FilterUtils.contains(label, filter);
            } else {
              included = FilterUtils.startsWith(label, filter);
            }
          } else {
            included = FilterUtils.contains(rowData[col.getField()], filter);
          }
        }
      });
      return included;
    }
  }

  public getColumnValue(column: Tcolumn, colValue: any, ri) {
    let listOfValues = column.getListOfValues();
    if (listOfValues !== undefined) {
      for (let i = 0; i < listOfValues.length; i++) {
        if (listOfValues[i].getValue().toString() === colValue.toString()) {
          return listOfValues[i].getLabel();
        }
      }
    }
  };

  export() {
    if(this.useDefaultExport){
      let _this=this;

      if (_this.selectedRow !== undefined && _this.selectedRow.length > 0) {
        _this.excelService.exportDashboardDataView(_this.columns, _this.data, 'Cashflow_Data', 'Cashflow_Data');
      } else {
        _this.messageService.add({
          severity: 'info', summary: messages.no_row_selected['summary'],
          detail: messages.no_row_selected['message']
        });
      }
    }
    else{
       this.pageSize= this.totalElements;
      this.onExportClick.emit(this.selectedRow);
    }

  }

  clickable(col: any, rowData) {
    this.onClickClickable.emit({fieldName: col['field'], data: rowData});
  }

  onSelectionChangeRow() {
    this.onSelectionChange.emit(this.selectedRow);
  }

  getFixedClass(col: Tcolumn) {
    let className = '';
    if (this.leftFixedColumns.includes(col.getField())) {
      className = 'sticky-left';
    } else if (this.rightFixedColumns.includes(col.getField())) {
      className = 'sticky-right';
    }
    return className;
  }

  getNextStickyWidth(width, numberToAddExtra = 50) {
    return (width + numberToAddExtra) + 'px';
  }

  getStyle(col: Tcolumn, index, zIndex = '400') {
    let style: any;
    let width;
    if (this.leftFixedColumns.includes(col.getField())) {
      width = this.getFixColumnWidth(index, col);
      style = {
        minWidth: this.getColumnWidth(col) + 'px',
        maxWidth: this.getColumnWidth(col) + 'px',
        position: 'sticky',
        left: width + 'px',
        zIndex: zIndex
      };
    } else if (this.rightFixedColumns.includes(col.getField())) {
      width = this.getFixColumnWidth(index, col, 'right');
      style = {
        minWidth: this.getColumnWidth(col, 'right') + 'px',
        maxWidth: this.getColumnWidth(col, 'right') + 'px',
        position: 'sticky',
        right: width + 'px',
        zIndex: zIndex
      }
    }
    return style;
  }

  getColumnWidth(col: Tcolumn, direction: string = 'left') {
    let width = this.fixColumnsWidth;
    if (direction === 'left') {
      if (this.leftFixedColumnsWidth !== undefined && this.leftFixedColumnsWidth !== null && this.leftFixedColumnsWidth.length > 0) {
        width = this.leftFixedColumnsWidth[this.leftFixedColumns.indexOf(col.getField())];
      }
    } else {
      if (this.rightFixedColumnsWidth !== undefined && this.rightFixedColumnsWidth !== null && this.rightFixedColumnsWidth.length > 0) {
        width = this.rightFixedColumnsWidth[this.rightFixedColumns.indexOf(col.getField())];
      }
    }
    return width;
  }

  getFixColumnWidth(index, col: Tcolumn, direction: string = 'left') {
    let _this = this;
    let width = (index * this.fixColumnsWidth) + 50;
    if (direction === 'left') {
      width = (index * this.fixColumnsWidth) + 50;
      if (this.leftFixedColumnsWidth !== undefined && this.leftFixedColumnsWidth !== null && this.leftFixedColumnsWidth.length > 0) {
        width = 50;
        if (index > 0) {
          this.leftFixedColumnsWidth.forEach(function (columnWidth: number, widthIndex) {
            if (widthIndex < index) {
              width += columnWidth;
            }
          });
        }
      }
    } else {
      let rightIndex = this.rightFixedColumns.indexOf(col.getField());
      width = ((this.rightFixedColumns.length - 1) - rightIndex) * this.fixColumnsWidth;
      if (this.rightFixedColumnsWidth !== undefined && this.rightFixedColumnsWidth !== null && this.rightFixedColumnsWidth.length > 0) {
        width = 0;
        this.rightFixedColumnsWidth.forEach(function (columnWidth: number, widthIndex) {
          if (widthIndex > rightIndex) {
            width += columnWidth;
          }
        });
      }
    }
    return width;
  }

  getElementById(number: number, type = 'header') {
    if (number >= 0) {
      return document.getElementById(type + number);
    }
    return undefined;
  }

  private getListFieldValue(col: any, value: any, ri: any) {
    return (value !== null && value !== undefined && value.toString().length > 0) ? this.getColumnValue(col, value, ri) : '';
  }

  onModelChange( rowData: any, col: any){
    this.onValueChange.emit({value:rowData[col['field']],row:rowData,col:col});
  }


  getColSpanExpand(length) {
    length++;
    return length;
  }

  canShowExpandedGrid() {
    return (this.expandedGrid !== undefined && this.expandedGrid !== null && this.expandedGrid.expandGrid !== null && this.expandedGrid.expandGrid !== undefined);
  }

  private fetchData(rowData: any) {
    let _this = this;
    this.data = null;
    let url = this.expandedGrid.getDataURL()+rowData[this.expandedGrid.getPreviousLevelPrimaryKey()];
    if(this.expandedGrid.urlModifier !== null && this.expandedGrid.urlModifier !== undefined && this.expandedGrid.urlModifier.length > 0){
        url = this.expandedGrid.urlModifier(url,rowData);
    }
    this.commonService.getJSONByURL(environment.base_url + url).subscribe((next:any[]) => {
      _this.data = next;
    });
  }

  convertList(tcolumns: IterableIterator<Tcolumn>) {
    return Array.from(tcolumns);
  }

  onExandexRowSelected(value: any, expandGrid: Grid) {
    this.onExpandRowSelectionChange.emit({value:value,table:expandGrid.getTitle()});
  }

  onPageSwitch(value: any) {
    this.onPageChange.emit(value['page']);
  }

  getPagination() {
    return this.totalElements > this.pageSize;
  }

  rowDelete(index:number) {
    if(this.data !== null && this.data !== undefined && this.data.length > 0) {
      this.data.splice(index, 1);
    }
  }

  onbuttonClick(rowData: any, col: Tcolumn) {
    this.onButtonClick.emit({rowData:rowData,col:col})
  }
}
