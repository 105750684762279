import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ContextMenu} from 'primeng';
import {MenuItem} from 'primeng/api';
import {environment} from '../../../environments/environment';
import {HttpErrorResponse} from '@angular/common/http';
import {CommonService} from '../../services/common.service';
import {ApprovalFunctionParams} from './model/approval-function.params';
import {AccessPolicyService} from '../../services/accesspolicy.service';

@Component({
  selector: 'approval-context-menu',
  templateUrl: './approval-context-menu.component.html',
  styleUrls: ['./approval-context-menu.component.css']
})
export class ApprovalContextMenuComponent implements OnInit, OnChanges {

  @Input() title: string = '';
  @Input() icon: string = 'fas fa-ellipsis-v';
  @Input() img: string = '';
  @Input() triggerEvent: string = '';
  @Input() referenceId: string = '';
  @Input() nameField: string = '';
  @Input() rowData: any = {};
  @Input() screenName: string;
  @Input() contextMenu: ContextMenu;
  @Input() functions: { [key: string]: Function } = {};
  @Output() onApprovalUpdate = new EventEmitter();
  @Output() onMenuItemClick = new EventEmitter();
  menuItems: MenuItem[] = [];
  currentApprovalObject: { buttonName: string, step: any, workflow: any, isInApproval: boolean, apiConfig: { url: string, type: string, payloadFunction: Function } } = {
    buttonName: '',
    step: '',
    workflow: {},
    isInApproval: true,
    apiConfig: {url: '', type: '', payloadFunction: null}
  };
  modalState: boolean = false;
  note: string = '';
  noteMessage: string = '';
  disabled:boolean = false;
  apiFunc: Function;

  constructor(private commonService: CommonService, private accessService: AccessPolicyService) {
  }

  ngOnInit(): void {
    this.createApiFunction();
    this.updateUI();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['rowData']) {
      this.updateUI();
    }
  }

  private updateUI() {
    if(Object.keys(this.functions).includes('disableCondition')) {
      this.disabled = this.functions['disableCondition'](this.getParam());
    }
  }

  private createApiFunction() {
    let _this = this;
    this.apiFunc = function(payload: any) {
      let defaultNote = _this.currentApprovalObject.buttonName + ' by ' +_this.commonService.getFromStorage('userName') + " at " + new Date();
      if(_this.note !== null && _this.note.length > 0) {
       defaultNote = _this.note
      }
      if (!_this.currentApprovalObject.isInApproval) {
        payload['url'] = _this.currentApprovalObject.apiConfig.url;
        payload['type'] = _this.currentApprovalObject.apiConfig.type;
        if (_this.currentApprovalObject.apiConfig.payloadFunction !== null) {
          payload['payload'] = _this.currentApprovalObject.apiConfig.payloadFunction(_this.getParam());
        } else {
          payload['payload'] = defaultNote;
        }
      } else {
        payload['url'] = environment.base_url_new + `/approval-workflow/api/workflow-process/update-approval-workflow/${_this.currentApprovalObject.workflow.uuid}/${_this.currentApprovalObject.step.actionName}/${_this.currentApprovalObject.buttonName}`;
        payload['type'] = 'post';
        payload['payload'] = defaultNote;
      }
      return payload;
    };
  }

  private;


  showPanel(event: MouseEvent, contextMenu: ContextMenu) {
    if(!this.disabled) {
      event.preventDefault();
      event.stopPropagation();
      if (this.contextMenu !== null && this.contextMenu !== undefined) {
        this.contextMenu.show(event);
        this.getWorkflowProcess(this.contextMenu);
      } else {
        contextMenu.show(event);
        this.getWorkflowProcess(contextMenu);
      }
    }
  }

  showToast(msg, severity: string = 'info') {
    this.commonService.postMessage('', msg, severity);
  }

  private addLoadingItem(contextMenu: ContextMenu) {
    let item: MenuItem[] = [];
    item.push({label: 'Loading', icon: 'fas fa-spinner fa-pulse fa-2x animated', disabled: true});
    contextMenu.model = item;
    contextMenu.moveOnTop();

  }

  private getWorkflowProcess(contextMenu: ContextMenu) {
    let _this = this;
    this.menuItems = [];
    this.addLoadingItem(contextMenu);
    if(this.referenceId !== null && this.referenceId !== undefined && this.referenceId.length > 0 && this.triggerEvent !== null && this.triggerEvent !==undefined && this.triggerEvent.length > 0) {
      let url = environment.base_url_new + `/approval-workflow/api/workflow-process/get-workflow-process/${this.commonService.getEncodedURI(this.referenceId)}`;
      this.commonService.getJSONByURL(url).subscribe((next: any[]) => {
        _this.createMenu(next,contextMenu);
      });
    } else {
      this.createMenu(null, contextMenu);
    }
  }

  private createMenu(next: any, contextMenu: ContextMenu) {
    this.menuItems = this.functions['loadMenuItems'](this.getParam(next));
    contextMenu.model = this.menuItems;
    contextMenu.moveOnTop();
  }

  private getParam(response?: any[]) {
    let _this = this;
    let updateWorkFlow = function(value) {
      let buttonName = value['buttonName'];
      let step = value['step'];
      _this.currentApprovalObject.workflow = value['workflow'];
      _this.currentApprovalObject.buttonName = buttonName;
      _this.currentApprovalObject.step = step;
      _this.currentApprovalObject.isInApproval = true;
      _this.showPopup();
    };
    let param: ApprovalFunctionParams = new ApprovalFunctionParams();
    param.rowData = this.rowData;
    param.context = this;
    param.note = this.note;
    param.triggerEvent = this.triggerEvent;
    param.response = response;
    param.accessService = this.accessService;
    param.referenceId = this.referenceId;
    param.currentApprovalObject = this.currentApprovalObject;
    param.commonService = this.commonService;
    param.onUpdateDone = this.onApprovalUpdate;
    param.callbackFunction = updateWorkFlow;
    return param;
  }

  showPopup() {
    this.note = '';
    this.modalState = true;
  }

  closeModal() {
    this.modalState = false;
  }

  onError(error: HttpErrorResponse) {
    this.showToast(this.commonService.getHttpErrorMessage(error, 'Approval', false), 'error');
  }

  onDone(event: any) {
    this.closeModal();
    this.functions['onDone'](this.getParam(event));
  }

  getButtonClass() {
    let classes = '';
    if(this.disabled) {
      classes += 'disabled ';
    }
    if(this.img === null || this.img === undefined || this.img.length === 0) {
      classes += 'approve-button '
    }
    return classes;
  }
}
