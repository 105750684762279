import {AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {MessageService, SelectItem, Table} from 'primeng';
import {CommonService} from "../../services/common.service";
import {BreadcrumbService} from "../../Components/ctrm-breadcrumb/breadcrumb.service";
import {environment} from "../../../environments/environment";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {filter} from "../../masters/services/filter.model";
import {entities} from "../../services/common/entities";
import {MasterService} from "../../masters/services/MasterService";
import {KeyValue} from "../../grid/key-value.model";
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";
import {Grid} from '../../grid/grid.model';
import {Tcolumn} from '../../grid/tcolumn.model';
import {Action} from '../../grid/actions.model';
import {Tab} from '../../tab-grid/Tab.model';
import {messages} from '../../services/common/messages';
import {ExcelServiceService} from '../../services/excel-service.service';

@Component({
  selector: 'app-trade-allocation',
  templateUrl: './trade-allocation.component.html',
  styleUrls: ['./trade-allocation.component.css']
})
export class TradeAllocationComponent implements OnInit,AfterViewInit {
  planCols: any[];
  oblCols: any[];
  freightCols: any[];
  filteredPlanId:any = '';
  planData: any[] = [];
  allocDetails: any[] = [];
  selectedPlans: any[] = [];
  tabValue: string;
  tradeId: any = '';
  display: boolean = false;
  oblRow: any;
  planTableHeight: string = '';
  allocFormHeight: string = '';
  allocationDisplay: boolean = false;
  items: any[] = [];
  formDisplay: boolean = false;
  bsConfig: Partial<BsDatepickerConfig>;
  shipForm: FormGroup;
  currentSelectedRow: number = -1;
  loadSection: boolean = true;
  unloadSection: boolean = true;
  allocatedObl: any[] = [];
  laycanDefault: any = new Date();
  etaDefault: any = new Date();
  getObject = {};
  filterLocation: filter;
  lastSelected: any;
  isFiltered:boolean = false;
  @ViewChild('planTable', {static: true}) planTable: Table;
  @ViewChild('af', {static: true}) allocForm;
  partiallyAllocated: number = 0;
  planned: number = 0;
  allocated: number = 0;
  locationList: KeyValue[] = [];
  expandedRows = {};
  tradeInfoDisplay: boolean = false;
  selctedTradeId: any;
  private plannedObligationId: any;
  //Document Attachment
  attachmentDisplay: boolean = false;
  gridHeight: any = '300px';
  divEl: HTMLDivElement;
  tabOptions: SelectItem[];
  docUrl: string;
  docName: any;
  showDoc: boolean = false;
  toolBarConfig: any = {
    add: true,
    edit: false,
    copy: true,
    delete: false,
    refresh: true,
    export: false,
    exportAll: false,
    import: false,
    audit: false,
    print: false,
    prefrences: true
  };
  columns: Tcolumn[] = [];
  attachmentGrid: Grid;
  onLoadData: any[] = [];
  currentPageNumber:number = 0;
  pageSize:number = 0;
  totalElements: number = 0;
  totalPages:number = 0;

  constructor(private changeDetectorRef:ChangeDetectorRef,
              public masterCommonService: MasterService,
              private formBuilder: FormBuilder, public commonService: CommonService,
              private messageService: MessageService,
              private route:ActivatedRoute,
              public breadCrumbService: BreadcrumbService, private router: Router,
              private excelService: ExcelServiceService) {
  }

  @HostListener("window:resize", [])
  public onResize() {
    this.calculateHeight();
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit() {
    this.breadCrumbService.makeBreadcrumbTo(this.commonService.OPERATION_MAP_KEY, '/tradeAllocation');
   // this.initFilters();
    this.locationLoad();
    this.bsConfig = new BsDatepickerConfig();
    this.bsConfig.dateInputFormat = (environment.dateFormat).toUpperCase();
    this.bsConfig.adaptivePosition = true;
    this.initShipForm();
    this.initSetters();
    let _this = this;
    this.route.queryParams.subscribe(params => {
      if(params !== undefined && params !== null && Object.keys(params).length > 0) {
        _this.filteredPlanId = params['value'];
        _this.isFiltered = true;
        _this.getPlansByFilters();
      } else {
        this.getPlans();
      }
    });
  }

  // To get All the Plans and nest all the respective Obligation Objects in each Plan
  public getPlansByFilters() {
    this.planData = [];
    let _this = this;
    this.planData = [];
    this.partiallyAllocated = 0;
    this.allocated = 0;
    this.planned = 0;
    this.commonService.getJSONByURL(environment.base_url + '/api/transportallocation/v1/getplanbyplanid?tenantId='+this.commonService.getTenantId()+'&planId='+this.filteredPlanId).subscribe((next: any[]) => {
      next.forEach((data, index) => {
        if (data['status'] === 'DELIVERY_STARTED') {
          _this.allocated++;
        } else if (data['status'] === 'Planned') {
          _this.planned++;
        } else {
          _this.partiallyAllocated++;
        }
        data['selectedChild'] = [];
        data['index'] = index;
        _this.expandedRows[data['planningId']] = false;
        _this.planData.push(data);
      });
      this.calculateHeight();
    });
  }

  public getPlans() {
    this.planData = [];
    let _this = this;
    this.planData = [];
    this.partiallyAllocated = 0;
    this.allocated = 0;
    this.planned = 0;
    this.commonService.getJSONByURL(environment.base_url + '/api/transportallocation/v1/getplans?tenantId='+this.commonService.getTenantId()+'&page='+this.currentPageNumber+'&size=20').subscribe((next: any[]) => {
      next['content'].forEach((data, index) => {
        if (data['status'] === 'DELIVERY_STARTED') {
          _this.allocated++;
        } else if (data['status'] === 'Planned') {
          _this.planned++;
        } else {
          _this.partiallyAllocated++;
        }
        data['selectedChild'] = [];
        data['index'] = index;
        _this.expandedRows[data['planningId']] = false;
        _this.planData.push(data);
      });
      _this.pageSize = next['pageable']['pageSize'];
      _this.totalElements = next['totalElements'];
      _this.totalPages = next['totalPages'];
      this.calculateHeight();
    });
  }

  onPageChange(value: any) {
    this.currentPageNumber = value['page'];
    this.getPlans();
  }

  getPagination() {
    return this.totalElements > this.pageSize;
  }


  onUniqueClick(value: any) {
    value['tradeStatus'] = 'Draft';
    this.tradeId = value['tradeId'];
    this.oblRow = value;
    this.display = true;
  }

  onClose(value: any) {
    this.tradeId = '';
    this.display = false;
  }

  planRefresh() {
    this.planData = [];
    this.planTable.selectionKeys = {};
    if(this.isFiltered) {
      this.getPlansByFilters();
    } else {
      this.getPlans();
    }
    this.selectedPlans = [];
    this.allocationDisplay = false;
    this.currentSelectedRow = -1;
  }

  getClassForHeading(index) {
    return index > 0 ? 'headerNormal' : 'noPipe';
  }

  goTo() {
    this.router.navigate(['/planning']);
  }

  initShipForm() {
    const shipObj = {};
    shipObj['blNumber'] = ['', [Validators.required]];
    shipObj['blDate'] = ['', [Validators.required]];
    shipObj['vesselName'] = ['', [Validators.required]];
    shipObj['vesselFlag'] = ['', []];
    shipObj['imo'] = ['', []];
    shipObj['city'] = ['', []];
    shipObj['age'] = ['', []];
    shipObj['loadPort'] = ['', [Validators.required]];
    shipObj['loadRate'] = ['', []];
    shipObj['laycanFrom'] = ['', []];
    shipObj['laycanTo'] = ['', []];
    shipObj['dischargePort'] = ['', [Validators.required]];
    shipObj['dischargeRate'] = ['', []];
    shipObj['etaFrom'] = ['', []];
    shipObj['etaTo'] = ['', []];
    shipObj['note'] = ['', []];
    this.shipForm = this.formBuilder.group(shipObj);
  }

  vesselInfoValid() {
    if (this.shipForm.get('vesselName').valid) {
      return true;
    }
  }

  loadInfoValid() {
    if (this.shipForm.get('loadPort').valid && this.shipForm.get('loadRate').valid && this.shipForm.get('laycanFrom').valid && this.shipForm.get('laycanTo').valid) {
      return true;
    }
  }

  unloadInfoValid() {
    if (this.shipForm.get('dischargePort').valid && this.shipForm.get('dischargeRate').valid && this.shipForm.get('etaFrom').valid && this.shipForm.get('etaTo').valid) {
      return true;
    }
  }

  expandRow(rowData) {
    if (this.lastSelected !== undefined && this.lastSelected !== null) {
      this.expandedRows[this.lastSelected['data']['planningId']] = false;
      this.planTable.selectionKeys = {};
      this.planTable.selectionKeys[rowData['data']['planningId']] = 1;
      if (this.planTable.selection.length > 1) {
        this.planTable.selection.splice(0, 1);
      }
    }
    this.planData[rowData['data']['index']]['selectedChild'] = rowData['data']['plannedObligationList'];
    this.currentSelectedRow = rowData['data']['index'];
    if (this.selectedPlans !== undefined && this.selectedPlans !== null && this.selectedPlans.length === 1) {
      this.allocationDisplay = true;
    } else {
      this.allocationDisplay = false;
    }
    this.expandedRows[rowData['data']['planningId']] = true;
    this.lastSelected = rowData;
  }


  onChildSelected(value: any[], rowData) {
    this.planData[rowData['index']]['selectedChild'] = value;
    if (value.length === 0) {
      this.currentSelectedRow = -1;
      this.selectedPlans = [];
      this.allocationDisplay = false;
    } else {
      this.currentSelectedRow = rowData['index'];
      if (this.planData[rowData['index']]['selectedChild'].length === rowData['plannedObligationList'].length) {
        this.selectedPlans = [rowData];
      } else {
        this.selectedPlans = [];
      }
      this.allocationDisplay = true;
    }
  }

  clearChild(rowData) {
    this.planData[rowData['data']['index']]['selectedChild'] = [];
    if (this.selectedPlans !== undefined && this.selectedPlans !== null && this.selectedPlans.length === 1) {
      this.allocationDisplay = true;
    } else {
      this.allocationDisplay = false;
    }
    this.expandedRows[rowData['data']['planningId']] = false;
  }

  calculateHeight() {
      let offsetTop = this.planTable.el.nativeElement['offsetTop'];
      let offsetHeight = this.planTable.el.nativeElement['offsetParent']['offsetHeight'];
      this.planTableHeight = (offsetHeight - offsetTop) - 20 + 'px';
      if(this.getPagination()) {
        offsetHeight = offsetHeight - 30;
      }
      this.planTableHeight = (offsetHeight - offsetTop - 22) + 'px';
  }


  checkForLast(i: number) {
    (this.planCols.length - 1) === i ? true : false;
  }

  openForm() {
    if (this.planData[this.currentSelectedRow]['plannedObligationList'].length !== this.planData[this.currentSelectedRow]['selectedChild'].length) {
      if (this.planData[this.currentSelectedRow]['selectedChild'].length > 1) {
        this.messageService.add({
          severity: 'info',
          summary: 'More than one Obligation is selected'
        });
      }
    } else {
      this.checkLoadUnload();
      this.formDisplay = !this.formDisplay;
      this.allocationDisplay = false;
    }
  }

  onFormCancel() {
    this.shipForm.reset();
    this.formDisplay = !this.formDisplay;
    this.allocationDisplay = true;
    this.loadSection = true;
    this.unloadSection = true;
    this.allocatedObl = [];
    this.getObject = {};
    this.initShipForm();
  }

  onCancelBottomForm() {
    this.selectedPlans =[];
    this.allocationDisplay = false;
  }

  onFormSubmit() {
    let payloadShipObject = {...this.shipForm.value};
    payloadShipObject['createdBy'] = this.commonService.getFromStorage('id');
    payloadShipObject['updatedBy'] = this.commonService.getFromStorage('id');
    payloadShipObject['tenantId'] = this.commonService.getFromStorage('tenantId');
    payloadShipObject['plannedId'] = this.planData[this.currentSelectedRow]['planningId'];
    payloadShipObject['plannedObligationList'] = this.planData[this.currentSelectedRow]['selectedChild'];
    this.commonService.post(environment.base_url + '/api/transportallocation/v1/seatransportsave?tenantId='+this.commonService.getTenantId(), payloadShipObject).subscribe(next => {
      this.showToast(this.planData[this.currentSelectedRow]['selectedChild'].length);
      this.formDisplay = false;
      this.shipForm.reset();
      this.planRefresh();
    });
  }


  checkLoadUnload() {
    let laycanDefault: any = '';
    let etaDefault: any = '';
    let isBuy: boolean = false;
    let isSell: boolean = false;
    let currentRowData = this.planData[this.currentSelectedRow];
    let value = this.planData[this.currentSelectedRow]['selectedChild'];
    value.forEach(function (rowData) {
      if (rowData['tradeTransactionType'] === 'BUY') {
        isBuy = true;
        if (laycanDefault === undefined || laycanDefault === null || laycanDefault.length === 0) {
          laycanDefault = new Date(rowData['deliveryEndDate'])
        } else {
          if (new Date(rowData['deliveryEndDate']) > laycanDefault) {
            laycanDefault = new Date(rowData['deliveryEndDate']);
          }
        }
      } else {
        isSell = true;
        if (etaDefault === undefined || etaDefault === null || etaDefault.length === 0) {
          etaDefault = new Date(rowData['deliveryEndDate'])
        } else {
          if (new Date(rowData['deliveryEndDate']) > etaDefault) {
            etaDefault = new Date(rowData['deliveryEndDate']);
          }
        }
      }
    });
    this.shipForm.controls['laycanFrom'].setValue(laycanDefault);
    this.shipForm.controls['laycanTo'].setValue(laycanDefault);
    this.shipForm.controls['etaFrom'].setValue(etaDefault);
    this.shipForm.controls['etaTo'].setValue(etaDefault);
    this.shipForm.controls['dischargePort'].setValue('');
    this.shipForm.controls['loadPort'].setValue('');
    if (currentRowData['matchType'] === 'BUILD') {
      this.shipForm.controls['dischargePort'].setValidators([]);
      this.shipForm.controls['dischargePort'].updateValueAndValidity();
    } else if (currentRowData['matchType'] === 'DRAW') {
      this.shipForm.controls['loadPort'].setValidators([]);
      this.shipForm.controls['loadPort'].updateValueAndValidity();
    } else {
      if (!isBuy) {
        this.loadSection = false;
        this.shipForm.controls['laycanFrom'].setValidators([]);
        this.shipForm.controls['laycanFrom'].updateValueAndValidity();
        this.shipForm.controls['laycanTo'].setValidators([]);
        this.shipForm.controls['laycanTo'].updateValueAndValidity();
        this.shipForm.controls['loadPort'].setValidators([]);
        this.shipForm.controls['loadPort'].updateValueAndValidity();
        this.shipForm.controls['loadRate'].setValidators([]);
        this.shipForm.controls['loadRate'].updateValueAndValidity()
      }
      if (!isSell) {
        this.unloadSection = false;
        this.shipForm.controls['etaFrom'].setValidators([]);
        this.shipForm.controls['etaFrom'].updateValueAndValidity();
        this.shipForm.controls['etaTo'].setValidators([]);
        this.shipForm.controls['etaTo'].updateValueAndValidity();
        this.shipForm.controls['dischargePort'].setValidators([]);
        this.shipForm.controls['dischargePort'].updateValueAndValidity();
        this.shipForm.controls['dischargeRate'].setValidators([]);
        this.shipForm.controls['dischargeRate'].updateValueAndValidity();
      }
    }
  }

  // initFilters() {
  //   let _this = this;
  //   _this.commonService.getLoadData('commodity', ['config'], ['location']).subscribe((next: any) => {
  //     _this.locationList = next;
  //   });
  // }

  locationLoad(){
    let _this = this;
    _this.commonService.getLoadData('commodity', ['config'], ['location']).subscribe((next: any) => {
      _this.locationList = this.masterCommonService.getListFromArray(next['location']);
     // _this.locationList = next;
    });
  }

  showToast(obligationsCount: number) {
    this.messageService.add({
      severity: 'success',
      summary: obligationsCount + ' planned obligation allocated successfully'
    });
  }

  openReadOnlyForm(rowData) {

    this.commonService.getJSONByURL(environment.base_url + '/api/transportallocation/v1/getAllocation/' + rowData['plannedObligationId']).subscribe(next => {
      this.getObject = next;
    });

    this.allocatedObl = rowData;
    this.formDisplay = !this.formDisplay;
    this.allocationDisplay = false;
  }

  deallocate() {
    this.commonService.post(environment.base_url + '/api/transportallocation/v1/deallocate?tenantId='+this.commonService.getTenantId(), this.planData[this.currentSelectedRow]['selectedChild']).subscribe(next => {
      this.messageService.add({
        severity: 'info',
        summary: 'De - allocation of Plan ' + this.planData[this.currentSelectedRow]['planningId'] + ' is successful'
      });
      this.planData = [];
      this.formDisplay = false;
      this.allocationDisplay = false;
      this.shipForm.reset();
      if(this.isFiltered) {
        this.getPlansByFilters();
      } else {
        this.getPlans();
      }
      this.selectedPlans = [];
      this.currentSelectedRow = -1;
    })
  }

  deallocationCheck() {
    if (this.currentSelectedRow > -1) {
      if (this.planData[this.currentSelectedRow]['plannedObligationList'].length === this.planData[this.currentSelectedRow]['selectedChild'].length) {
        if (this.planData[this.currentSelectedRow]['status'] === 'DELIVERY_STARTED') {
          return true;
        }
      } else if (this.planData[this.currentSelectedRow]['selectedChild'].length === 1) {
        if (this.planData[this.currentSelectedRow]['plannedObligationList'][0]['obligationState'] === 'DELIVERY_STARTED') {
          return true;
        }
      } else {
        return false;
      }
    }
    return false;
  }

  initSetters() {
    let _this =this;
    this.items = [
      {label: 'Air Transport', icon: 'fas fa-plane'},
      {label: 'Road Transport', icon: 'fas fa-truck-moving'},
      {label: 'Rail Transport', icon: 'fas fa-train'},
      {
        label: 'Sea Transport', icon: 'fas fa-ship', command: () => {
          this.openForm();
        }
      }
    ];
    let planType = function (value) {
      if (value['matchType'] === 'BUILD') {
        return 'Stock - Build'
      } else if (value['matchType'] === 'DRAW') {
        return 'Stock - Draw'
      } else {
        return value['matchType'];
      }
    };
    this.planCols = [
      {field: 'planningId', header: 'Plan Id'},
      {field: 'buyObligationQuantity', header: 'Buy Planned Qty'},
      {field: 'sellObligationQuantity', header: 'Sell Planned Qty'},
      {field: 'matchType', header: 'Planned Matching', valueFunction: planType},
      {field: 'status', header: 'Status'}
    ];

    this.allocDetails = [
      {field: 'shippingFreightAllocationId', header: 'Shipment Id'},
      {field: 'vesselName', header: 'Vessel Name'},
      {field: 'vesselFlag', header: 'Vessel Flag', type: 'list', list: this.commonService.getCountryList()},
      {field: 'imo', header: 'IMO Number'},
      {field: 'city', header: 'City'},
      {field: 'age', header: 'Age'},
      {field: 'loadPort', header: 'Load Port'},
      {field: 'loadRate', header: 'Load Rate'},
      {field: 'laycanFrom', header: 'Laycan From'},
      {field: 'laycanTo', header: 'Laycan To'},
      {field: 'dischargePort', header: 'Discharge Port'},
      {field: 'dischargeRate', header: 'Discharge Rate'},
      {field: 'etaFrom', header: 'ETA From'},
      {field: 'etaTo', header: 'ETA To'}
    ];

    this.freightCols = [
      {field: 'tradeId', header: 'Trade Id'},
      {field: 'plannedObligationId', header: 'Planned Obligation Id'},
      {field: 'tradeType', header: 'TradeType'},
      {field: 'blDate', header: 'B/L Date'},
      {field: 'blNumber', header: 'B/L Number'},
      {field: 'age', header: 'Age'},
      {field: 'city', header: 'City'},
      {field: 'dischargePort', header: 'Discharge Port'},
      {field: 'dischargeRate', header: 'Discharge Rate'},
      {field: 'etaFrom', header: 'ETA From'},
      {field: 'etaTo', header: 'ETA To'},
      {field: 'imo', header: 'IMO'},
      {field: 'laycanFrom', header: 'Laycan From'},
      {field: 'laycanTo', header: 'Laycan To'},
      {field: 'loadPort', header: 'Load Port'},
      {field: 'shippingFreightAllocationId', header: 'Shipping Freight Allocation Id'},
      {field: 'vesselFlag', header: 'Vessel Flag'},
      {field: 'vesselName', header: 'Vessel Name'}
    ];

    let viewStatusFunction = function (value: any) {
      if(value['obligationState']['DELIVERY_STARTED'] === true){
        return 'Delivery Started';
      }
      return 'Planned';
    };
    let viewQuantityFunction = function (value: any) {
      return _this.commonService.getFormatedNumber(value['plannedQuantity']) + ' ' + value['quantityUOM'];
    };
    let viewPriceFunction = function (value: any) {
      return _this.commonService.getFormatedNumber(value['tradePrice']) + ' ' + value['tradePriceCurrency'] + '/' + value['tradePriceUom'];
    };
    let viewPlannedQuantityFunction = function (value: any) {
      return _this.commonService.getFormatedNumber(value['plannedQuantity']) + ' ' + value['uomName'];
    };
    this.oblCols = [
      {field: 'plannedObligationId', header: 'Planned Obligation Id', primaryKey: true},
      {field: 'tradeId', header: 'Trade Id'},
      {field: 'tradeTransactionType', header: 'Trade Type'},
      {field: 'tradeDateTime', header: 'Trade Date', type: 'date'},
      {field: 'commodity', header: 'Commodity'},
      {field: 'counterparty', header: 'Counterparty'},
      {field: 'plannedQuantity', header: 'Planned Quantity', valueFunction: viewQuantityFunction},
      {field: 'deliveryStartDate', header: 'Delivery Start Date', type: 'date'},
      {field: 'deliveryEndDate', header: 'Delivery End Date', type: 'date'},
      {field: 'incoterm', header: 'Incoterm'},
      {field: 'location', header: 'Location'},
      {field: 'cropOrigin', header: 'Origin'},
      {field: 'grade', header: 'Grade'},
      {field: 'broker', header: 'Broker'},
      {field: 'priceType', header: 'Price Type'},
      {field: 'tradePrice', header: 'Trade Price', valueFunction: viewPriceFunction},
      {field: 'profitcenter', header: 'Profit Center'},
      {field: 'obligationState', header: 'Status', valueFunction: viewStatusFunction}
    ];
  }

  checkDisable() {
    if (this.selectedPlans.length === 1) {
      return false;
    }
    return true;
  }

  onClickable(value: any) {
    if (value['fieldName'] === 'tradeId') {
      this.selctedTradeId = value['rowData']['tradeId'];
      this.oblRow = value['rowData'];
      this.tradeInfoDisplay = true;
    }
  }

  onCloseTradeInfo(value) {
    this.tradeInfoDisplay = false;
  }


  getDocAttachment() {
    let _this = this;
    let columns: Map<string, Tcolumn> = new Map();
    let updateAttachmentName = function updatename(value: any = []) {
      let file = value[0];
      return file['name'];
    };
    let updateAttachmentType = function updatetype(value: any = []) {
      let file = value[0];
      return file['type'];
    };

    let updateFileSize = function updatetype(value: any = []) {
      let file: File = value[0];
      return _this.commonService.fileSizeConvert(file.size, true);
    };
    let action: Action = new Action();
    action.addFieldToUpdate("attachmentFileName", '', updateAttachmentName, undefined, undefined, false);
    action.addFieldToUpdate("attachmentFileType", '', updateAttachmentType, undefined, undefined, false);
    action.addFieldToUpdate("fileSize", '', updateFileSize, undefined, undefined, false);

    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    columns.set('planningId', new Tcolumn('planningId', 'Plan Id', 'LB', 1, false, undefined, false, '','G'));
    columns.set('documentName', new Tcolumn('documentName', 'Document Name', 'L', 1, false, this.masterCommonService.getListFromArray(this.onLoadData['document_name_grp_ind']), true, '','B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined));
    columns.set('attachment', new Tcolumn('attachment', 'Attachment', 'F', 2, true, undefined, true, '', 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, action, {
          downloadUrl: {
            url: environment.base_url,
            para: ['attachmentUrl']
          }
        }
    ));
    columns.set('uploadedBy', new Tcolumn('uploadedBy', 'Uploaded By', 'LB', 3, false, undefined, true, this.commonService.getFromStorage('userName'), 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disbaled: true}}));
    columns.set('uploadDate', new Tcolumn('uploadDate', 'Upload Date', 'D', 4, true, undefined, true, new Date(), 'B', [Validators.required], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('download', new Tcolumn('download', 'Download', 'BT', 5, true, undefined, true, '', 'G'));
    columns.set('fileSize', new Tcolumn('fileSize', 'File Size', 'LB', 6, false, undefined, true, '', 'B'));
    columns.set('attachmentFileName', new Tcolumn('attachmentFileName', 'Name', 'LB', 7, false, undefined, false, '', 'G'));
    columns.set('attachmentFileType', new Tcolumn('attachmentFileType', 'Type', 'LB', 8, false, undefined, false, '', 'G'));
    this.attachmentGrid = new Grid(columns, true, true, true, true, 'attachment', '', '', 'Document Attachment', '', '', undefined, undefined, false);
    this.attachmentGrid.setGridConfiguration(true, true, false, false);
    this.attachmentGrid.addNewRowInline = true;
    this.attachmentGrid.setSaveUrl(environment.base_url + '/api/transportallocation/v1/uploaddoc');
  }

  openAttachDialog() {
    this.allocationDisplay = false;
    this.attachmentDisplay = true;
    this.getDocAttachment();
    this.getDocumentName();
    let planId = this.planData[0]['planningId'];
    this.attachmentGrid.getColumn().get('planningId').setDefaultValue(planId);
    this.attachmentGrid.setDataURL('/api/transportallocation/v1/getallattacheddoc?tenantId='+this.commonService.getFromStorage('tenantId')+'&planningId='+planId);
  }

  getDocumentName() {
    this.commonService.getLoadData('brand',['data'],[[{key:'name', value:'document_name_grp_ind'}]]).subscribe( (next:any) => {
      this.onLoadData = next;
      if(this.attachmentGrid !== null && this.attachmentGrid !== undefined) {
        this.attachmentGrid.getColumn().get('documentName').setListofValues(this.masterCommonService.getListFromArray(this.onLoadData['document_name_grp_ind']))
      }
    });
  }

  onCancel() {
    this.attachmentDisplay = false;
  }

  calculateGridHeight(divElement: HTMLDivElement) {
    this.gridHeight = 350 + 'px';
    this.divEl = divElement;
  }

  //Show download buttons
  onButtonClick(value: any) {
    let name = value['rowData']['attachmentFileName'];
    let url = environment.base_url + value['rowData']['attachmentUrl'];
    if (value['buttonHeader'] === 'Download') {
      window.open(url, '_blank');
    }  else {
      this.docUrl = url;
      this.docName = name;
      this.showDoc = true;
    }
  }

  downloadExcel() {
    let freightData: any[];
    if (this.selectedPlans !== undefined && this.selectedPlans.length > 0) {
      if (this.planData[this.currentSelectedRow]['status'] === 'DELIVERY_STARTED') {
        this.commonService.getJSONByObject(environment.base_url + '/api/transportallocation/v1/getFreights', this.planData[this.currentSelectedRow]['plannedObligationList']).subscribe((next: any) => {
          this.excelService.exportDashboardDataView(this.freightCols, next, 'Shipment_Details', 'Shipment_Details');
        });
      } else {
        this.messageService.add({
          severity: 'error', summary: 'Cannot download shipment details',
          detail: 'Selected row is not allocated'
        });
      }
    } else {
      this.messageService.add({
        severity: 'info', summary: messages.no_row_selected['summary'],
        detail: messages.no_row_selected['message']
      });
    }
  }
}
