import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Tab} from "../../../tab-grid/Tab.model";
import {TradeComponent} from "../../../physicals/contract/trade/trade.component";
import {MasterService} from "../../../masters/services/MasterService";
import {ActivatedRoute, Router} from "@angular/router";
import {CommonService} from "../../../services/common.service";
import {environment} from "../../../../environments/environment";
import {Tcolumn} from "../../../grid/tcolumn.model";
import {Grid} from "../../../grid/grid.model";
import {defaultValue} from '../../../grid/defaultValue.model';

@Component({
  selector: 'app-trade-info',
  templateUrl: './trade-info.component.html',
  styleUrls: ['./trade-info.component.css']
})
export class TradeInfoComponent implements OnInit, OnChanges {

  @Input() display: boolean = false;
  @Input() tradeId: string = '';
  @Input() rowData: any = {};
  @Input() detailsOf: string = 'obligation';
  @Input() idValue: string = '';
  @Input() sidebarPosition: string = 'right';
  @Input() marginTop = "4.5% !important";
  @Output() onClose = new EventEmitter();
  tabs: Tab[] = [];
  trade: TradeComponent;
  tradeObject: any = {};
  docUrl: string;
  currentIndex: number = 0;
  docName: string;
  showDoc: boolean;
  tradeUrl: string;
  editUrl: string;
  @Input() baseZIndex: number = 5;

  constructor(public  masterCommonService: MasterService, public commonService: CommonService, private router: ActivatedRoute,private route:Router) {
  }

  ngOnInit(): void {
    this.initTabs();
  }

  initTabs() {
    this.trade = new TradeComponent(this.masterCommonService, this.commonService,null,this.router,null);
    this.trade.ngOnInit();
    this.updateTabs();
    this.fetchTrade(this.tradeId);
  }

  updateTabs() {
    let _this = this;
    let tradeUuid = "";
    if(this.tradeObject !== null && this.tradeObject !== undefined)  {
      tradeUuid= this.tradeObject['uuid'];
    }
    this.tabs = [];
    this.editUrl = this.getEditUrl();
    let tab = new Tab(this.trade.grid.getTitle(), this.trade.grid, 'tradeId', undefined, true, this.tradeUrl, {minHeight: '86.6vh'});
    tab.getGrid().setTabs([]);
    tab.getGrid().setGridConfiguration(false, false, false);
    tab.formOnModal = true;
    this.tabs.push(tab);
    let qualitySpec: Tab = this.trade.getQualitySpecification(false,false);
    qualitySpec.getGrid().setGridConfiguration(false, false, false, false, false, false, false, false, false, false);
    qualitySpec.formOnModal = false;
    qualitySpec.getGrid().getColumn().get('tradeId').setDefaultValue(this.tradeId);
    qualitySpec.updateDataUrl(this.tradeId);
    let cost: Tab = this.trade.getCost(this.tradeObject['settlementCurrency'], this.tradeObject['quantityUom'],false);
    cost.getGrid().setGridConfiguration(false, false, false, false, false, false, false, false, false, false);
    cost.formOnModal = false;
    cost.getGrid().getColumn().get('tradeId').setDefaultValue(this.tradeId);
    cost.updateDataUrl(tradeUuid);
    let attachemnt: Tab = this.trade.getAttachment();
    attachemnt.getGrid().setGridConfiguration(false, false, false, false, false, false, false, false, false, false);
    attachemnt.formOnModal = false;
    attachemnt.getGrid().getColumn().get('tradeId').setDefaultValue(this.tradeId);
    attachemnt.updateDataUrl(tradeUuid);
    this.tabs.push(qualitySpec);
    this.tabs.push(cost);
    this.tabs.push(attachemnt);
    this.tabs.push(this.getPriceAllocationTab());


  }


  getPriceAllocationTab() {
    let quantityValue = function (value:any[]) {
      return value[0]+' ' + value[1];
    }
    let Priceunits = function(value:any[]){
      return value[0]+' '+value[1]+ '/'+value[2];
    }

    let columns: Map<string, Tcolumn> = new Map();
    columns.set('_rowNum', new Tcolumn('rowNum', 'rowNum', 'T', 0, false, undefined, false, '', 'N'));
    columns.set('tradeId', new Tcolumn('tradeId', 'Trade ID', 'LB', 1, false, undefined, false, '', 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('obligatyionId', new Tcolumn('obligationId', 'Obligation Id', 'LB', 1, false, undefined, true, '', 'B', [], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('quantity', new Tcolumn('quantity', 'Quantity', 'LB', 2, false, undefined, true, new defaultValue('',undefined,undefined,undefined,false,true,false,quantityValue,['quantity','quantityUom']), 'G', [], undefined, undefined, undefined, undefined, undefined, undefined, {meta: {disabled: true}}));
    columns.set('quantityUom', new Tcolumn('quantityUom', 'Quantity Uom', 'LB', 3, false, undefined, true, '', 'H', [], undefined, undefined, undefined, undefined, undefined));
    columns.set('price', new Tcolumn('price', 'Price', 'LB', 4, false, undefined, true, new defaultValue('',undefined,undefined,undefined,false,true,false,Priceunits,['price','priceCurrency','currencyUom']), 'B', []));
    columns.set('priceCurrency', new Tcolumn('priceCurrency', 'Price Currency', 'LB', 5, false, undefined, true, '', 'H', []));
    columns.set('currencyUom', new Tcolumn('currencyUom', 'Price Currency Uom', 'LB', 6, false, undefined, true, '', 'H', [], undefined, undefined, undefined, undefined, undefined));
    let grid = new Grid(columns, true, true, true, true, 'tradeId', '/api/manualPricingService/v1/getPriceAllocatedByTradeId?tradeId=', '', 'Price Allocated', '', '', undefined, undefined, false);
    grid.setGridConfiguration(false, false, false, false, false, false, false, false, false, false);
    let tab = new Tab(grid.getTitle(), grid, 'tradeId');
    tab.formOnModal = false;
    tab.getGrid().getColumn().get('tradeId').setDefaultValue(this.tradeId);
    tab.updateDataUrl(this.tradeId);
    tab.noDataLabel = "No price allocated to this Trade.";
    return tab;

  }

  close() {
    this.display = false;
    this.onClose.emit();
  }

  fetchTrade(tradeid: string) {
    this.commonService.getJSONByURL(environment.base_url + '/api/trade/v1/getTrade?tradeId=' +tradeid+ '&tenantId='
        +this.commonService.getTenantId()).subscribe((next: any[]) => {
      if (next !== undefined && next !== null) {
        this.tradeObject = next;
        this.updateTabs();
      }
    });
  }

  /**
   * work to do when click on view,download ,print button in attachment
   * @param value
   */
  onClickButton(value: any) {
    if (value['screenName'] === 'Attachment') {
      let name = value['rowData']['attachmentFileName'];
      let url = environment.base_url + 'attachment/download/' + value['rowData']['attachmentFileName'];
      if (value['buttonHeader'] === 'Download') {
        window.open(url, '_blank');
      } else if (value['buttonHeader'] === 'Print') {
        let printurl = 'https://docs.google.com/viewerng/viewer?url=' + url;
        window.open(printurl, '_blank');
      } else {
        this.docUrl = url;
        this.docName = name;
        this.showDoc = true;
      }
    }
  }

  /**
   * what to do on close doc viewer
   * @param value
   */
  onCloseDocViewer(value: any) {
    this.docUrl = '';
    this.showDoc = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tradeId']) {
      this.fetchTrade(changes['tradeId'].currentValue);
    }
  }

  onIndexChange(value: number) {
    this.currentIndex = value;
  }

  getBuySellLabel(isBuy) {
    if (isBuy === 'BUY') {
      return 'BUY';
    }
    return 'SELL';
  }

  getIconTick() {
    if (this.detailsOf === 'obligation') {
      if (this.rowData['tradeStatus']) {
        if (this.rowData['tradeStatus'].toLowerCase() === 'confirmed') {
          return 'C';
        }
        return 'U';
      }
    } else {
      if(this.tradeObject !== null && this.tradeObject !== undefined) {
        if (this.tradeObject['tradeStatus'].toLowerCase() === 'confirmed') {
          return 'C';
        }
        return 'U';
      } else {
        if (this.rowData['tradeStatus'].toLowerCase() === 'confirmed') {
          return 'C';
        }
        return 'U';
      }
    }

  }

  getTooltipConfirmed() {
    if (this.getIconTick() === 'C') {
      return 'Confirmed';
    }
    return 'Unconfirmed';
  }

  getStatusString() {
    if (this.detailsOf === 'obligation') {
      return this.rowData['tradeObligationState'];
    } else {
      return this.tradeObject['tradeStatus'];
    }

  }

  getStatusClass() {
    if (this.getStatusString() === 'Draft') {
      return 'unplanned';
    } else if (this.getStatusString() === 'Unplanned') {
      return 'unplanned';
    } else if (this.getStatusString() === 'Delivery Started') {
      return 'deliveryStart';
    } else if (this.getStatusString() === 'Delivery Completed') {
      return 'deliveryComplete';
    }
  }

  getId() {
    if (this.detailsOf === 'obligation') {
      return this.rowData['tradeObligationId'];
    } else if (this.detailsOf === 'plannedObligation') {
      return this.idValue;
    } else {
      return this.rowData['tradeId'];
    }
  }

  getHeader() {
    if (this.detailsOf === 'obligation') {
      return 'Obligation ID';
    } else if (this.detailsOf === 'plannedObligation') {
      return 'Planned Obligation ID';
    } else {
      return 'Trade ID';
    }
  }

  private getEditUrl() {
    return environment.base_url + '/api/trade/v1/getTrade?tradeId=' +this.tradeId+ '&tenantId=' +this.commonService.getTenantId();
  }
}

