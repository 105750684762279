import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {ExpressionSolverService} from './ExpressionSolver.service';
import {CommonService} from './common.service';

@Injectable({
  providedIn: 'root'
})
export class AccessPolicyService {
  currentGridAccess: any = {};
  currentRoute: string = '';
  private accessPolicy: any = {};
  baseAccessPolicy: any = {};
  baseAccessPolicyVersion: number = 1;

  constructor(private commonService: CommonService, private router: Router) {
  }


  public canAccess(router: string, access: string, action: string, entity: any = {}): boolean {
    let canAccess = false;
    if (this.accessPolicy !== null && this.accessPolicy !== undefined && Object.keys(this.accessPolicy).length === 0) {
      let policy: string = this.commonService.getFromStorage('policy');
      if (policy !== null && policy !== undefined) {
        if (typeof policy === 'string') {
          this.accessPolicy = JSON.parse(policy);
        } else {
          this.accessPolicy = policy;
        }
      }
    }
    if (router !== null && router !== undefined && router.length > 0 && this.accessPolicy[router] !== undefined && this.accessPolicy[router] !== null) {
      let script: string = this.accessPolicy[router][access][action];
      canAccess = new ExpressionSolverService().setExpression(script + '').addContext('entity', entity).solve();
      if (canAccess === undefined || canAccess === null) {
        canAccess = false;
      }
    }
    return canAccess;
  }

  public canAccessWithCurrentRoute(access:string,action:string,entity:any = {}):boolean {
    return this.canAccess(this.currentRoute,access,action,entity);
  }

  public setRoute(route:string) {
    this.currentRoute = route;
    this.updateGridPolicy(true);
  }


  public updateGridPolicy(checkFromPolicy:boolean =  true) {
    if(checkFromPolicy) {
      this.currentGridAccess['add'] = this.canAccess(this.currentRoute,'Grid Access','create');
      this.currentGridAccess['edit'] = this.canAccess(this.currentRoute,'Grid Access','edit');
      this.currentGridAccess['copy'] = this.canAccess(this.currentRoute,'Grid Access','copy');
      this.currentGridAccess['delete'] = this.canAccess(this.currentRoute,'Grid Access','delete');

      this.currentGridAccess['print'] = this.canAccess(this.currentRoute,'Toolbar','print');
      this.currentGridAccess['import'] = this.canAccess(this.currentRoute,'Toolbar','import');
      this.currentGridAccess['export'] = this.canAccess(this.currentRoute,'Toolbar','export');
      this.currentGridAccess['exportAll'] = this.canAccess(this.currentRoute,'Toolbar','exportAll');
      this.currentGridAccess['preferences'] = this.canAccess(this.currentRoute,'Toolbar','preferences');
      this.currentGridAccess['audittrail'] = this.canAccess(this.currentRoute,'Toolbar','auditlog');
    } else {
      this.currentGridAccess['add'] = true;
      this.currentGridAccess['edit'] = true;
      this.currentGridAccess['copy'] = true;
      this.currentGridAccess['delete'] = true;

      this.currentGridAccess['print'] = true;
      this.currentGridAccess['import'] = true;
      this.currentGridAccess['export'] = true;
      this.currentGridAccess['exportAll'] = true
      this.currentGridAccess['preferences'] = true;
      this.currentGridAccess['audittrail'] = true;
    }
  }

  public reloadAccessPolicy(checkFromPolicy:boolean = true,syncMenu:boolean) {
    let policy: string = this.commonService.getFromStorage('policy');
    if(policy !== null && policy !== undefined && policy.length > 0) {
      this.accessPolicy = JSON.parse(policy);
      if(syncMenu) {
        let _this = this;
        var readAccessRouter = [];
        let keys: any[] = Object.keys(this.accessPolicy);
        keys.forEach(function(key) {
          if (new ExpressionSolverService().setExpression(_this.accessPolicy[key]['Grid Access']['read'] + '').solve()) {
            readAccessRouter.push(key);
          }
        });
        this.commonService.saveToStorage('readAccessRouter', readAccessRouter);
      }
      this.updateGridPolicy(checkFromPolicy)
    }
  }
}
