<div class="ui-g p-0 m-0" style="width: 100%">
    <div class="cardBox"  [style.width]="width">
        <div class="innerbox">
            <div class="topline d-flex">
                <div class="flex-grow-1">
                    <label class="heading ng-star-inserted">{{tickerValue['tickerName']}} : </label>
                    <ui-switch style="padding-left: 10px;"
                               (change)="onToggleChange($event)"
                               [checkedLabel]="'ON'"
                               [uncheckedLabel]="'OFF'"
                               [checkedTextColor]="'#fff'"
                               [color]="'#00409f'"
                               [uncheckedTextColor]="'#fff'"
                               [defaultBgColor]="'#3D9F00'"
                               [size]="'small'"
                               [checked]="toggleButton">
                    </ui-switch>
                </div>
                <div class="d-inline-flex">
                    <i (click)="onClickInfo()" [pTooltip]="'Info'" class="fas fa-info-circle flex-fill informationIcon"></i>
                    <i class="fas fa-external-link-alt flex-fill"
                       style="padding-right: 10px;cursor: pointer;align-self: center;"></i>
                </div>

            </div>
            <div *ngIf="showGraphSection">
                <!--            Date Section-->
                <div class="d-flex p-1">
                    <p class="dateSection">Quote Overview for : {{date | date: 'dd MMM yyyy'}} </p>
                </div>
                <!--            Slider Section-->
                <div>
                    <xceler-slider [max]="maxHigh"
                                   [maxlabel]="'Day High'"
                                   [min]="minLow"
                                   [minlabel]="'Day Low'"
                                   [value]="settlementPrice">
                    </xceler-slider>
                </div>
                <!--            Price Section-->
                <div>
                    <div class="cardDetails d-flex">
                        <p class="priceSectionHeading flex-fill">Previous Close</p>
                        <p class="priceSectionValue flex-fill">{{settlementPrice === null ||
                        settlementPrice === undefined ? '-' : settlementPrice}}</p>
                    </div>
                    <div class="cardDetails d-flex">
                        <p class="priceSectionHeading flex-fill">Volume</p>
                        <p class="priceSectionValue flex-fill">NA</p>
                    </div>
                    <div class="cardDetails d-flex">
                        <p class="priceSectionHeading flex-fill">Avg Volume</p>
                        <p class="priceSectionValue flex-fill">NA</p>
                    </div>
                    <div class="cardDetails d-flex">
                        <p class="priceSectionHeading flex-fill">5-Day Change</p>
                        <p class="priceSectionValue flex-fill">NA</p>
                    </div>
                    <div class="cardDetails d-flex">
                        <p class="priceSectionHeading flex-fill">52-Week Range</p>
                        <p class="priceSectionValue flex-fill">NA</p>
                    </div>
                </div>
            </div>
            <!--            Graph Section-->
            <div *ngIf="!showGraphSection">
                <p></p>
            </div>
        </div>
    </div>
</div>

<ticker-info-box (onClose)="onCloseForm()"
                 *ngIf="infoDisplay"
                 [commodityInfoValue]="commodityInfoValue"
                 [graphValue]="graphValue"
                 [infoDisplay]="infoDisplay"
                 [tickerHeader]="tickerHeader"
                 [tickerInfoValue]="tickerInfoValue">
</ticker-info-box>

<!--Toaster Message-->
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body">
</p-toast>


