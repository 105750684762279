<div class="cardsection">
    <div>
        <div style="width: 100%;display: inline-flex;align-items: center">
            <div style="width: inherit;display: inline-flex;align-items: center">
                <p class="sectiontitle">{{section.sectionName}}</p>
                <button *ngIf="!editing" class="editButton" (click)="onEdit()">Edit</button>
                <button *ngIf="editing" class="saveButton" (click)="onSavePressed()">Save</button>
                <button *ngIf="editing" class="cancelButton" (click)="onCancel()">Cancel</button>
            </div>
            <div>
                <button *ngIf="section.newEntry" class="planScreenBtns" style="margin: 0" (click)="openForNewEntry()">
                    New Entry
                </button>
            </div>
        </div>
        <p class="sectiondesc">{{section.description}}</p>
    </div>
    <div class="content">
        <div class="row rowClass">
            <div *ngFor="let prop of section.props;index as i" class="col-md-2 controlGap contentbox">
                <div class="fieldBox">
                    <label class="label ng-star-inserted">{{prop.name}}</label>
                    <div *ngIf="editing || prop.type.toLowerCase() === 'button' || prop.type.toLowerCase() === 'imagebutton'" style="display: inline-flex;width: 100%;align-items: center">
                        <input *ngIf="prop.type.toLowerCase() === 'text'" [placeholder]="prop.name"
                               [(ngModel)]="finalJson[section.key][prop.key]"
                               class="form-control form-control-sm input"
                               pInputText>
                        <app-number-spinner *ngIf="prop.type.toLowerCase() === 'number'"
                                            [(ngModel)]="finalJson[section.key][prop.key]"
                                            [min]="prop.min"
                                            [max]="prop.max">
                        </app-number-spinner>
                        <button *ngIf="prop.type.toLowerCase() === 'button'"
                                class="planScreenBtns"
                                (click)="onClickButton(prop)"
                                style="padding-left: 20px;padding-right: 20px;margin: 0">{{prop.value}}</button>
                        <div *ngIf="prop.type.toLowerCase() === 'imagebutton'"
                             (click)="onClickButton(prop)"
                             class="imgbutton" [ngStyle]="prop.style">
                            <img src="../../../assets/images/{{prop.path}}" [alt]="prop.name">
                            <p [style.color]="prop.style.color">{{prop.value}}</p>
                        </div>
                        <i *ngIf="editing && prop.deletable" class="fas fa-trash"
                           style="padding: 10px;cursor: pointer;color: red" (click)="deletePropery(prop,i)"></i>
                    </div>
                    <div *ngIf="!editing && prop.type.toLowerCase() !== 'button' && prop.type.toLowerCase() !== 'imagebutton'" style="display: inline-flex;width: 100%;align-items: center">
                        <p class="valuebox">{{finalJson[section.key][prop.key]}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog *ngIf="addNewEntryState" (onHide)="closeModal()" [(visible)]="addNewEntryState" [baseZIndex]="25"
          [closable]="false"
          [showHeader]="false"
          [closeOnEscape]="true"
          [draggable]="false" [maximizable]="false" [modal]="true"
          [ngClass]="'dialogClass'">
    <div class="content">
        <div style="padding: 10px">
            <p class="sectiontitle">New {{section.sectionName}} Entry</p>
        </div>
        <div class="form-content">
            <div class="sectionbox" style="display: inline-flex;width: 100%;margin-left: 10px;padding-right: 10px;">
                <div style="margin-right: 5px;width: inherit">
                    <div pTooltip="Required Field" tooltipZIndex="99999" style="display: inline-flex;align-items: center">
                        <p>Property</p>
                        <p class="required">*</p>
                    </div>
                    <p-dropdown
                            appendTo="body"
                            tooltipEvent="focus"
                            tooltipPosition="bottom"
                            tooltipZIndex="1000"
                            placeholder="select"
                            [(ngModel)]="selectedOption.key"
                            (onChange)="onPropertyChange($event)"
                            [options]="entries">
                    </p-dropdown>
                </div>
                <div style="margin-left: 5px;width: inherit">
                    <p>Value</p>
                    <app-number-spinner
                            *ngIf="selectedObject === null || selectedObject.type === '' || selectedObject.type.toLowerCase() === 'number'"
                            [(ngModel)]="selectedOption.value"
                            [disabled]="(selectedObject === null || selectedObject.type === '' || selectedOption.key.length === 0)">
                    </app-number-spinner>
                    <input *ngIf="selectedObject !== null  && selectedObject.type.toLowerCase() === 'text'"
                           [disabled]="selectedOption.length === 0"
                           [(ngModel)]="selectedOption.value"
                           class="form-control form-control-sm input"
                           pInputText>
                </div>
            </div>
        </div>
    </div>
    <p-footer>
        <div class="footerclass">
            <button class="cancelBtn" (click)="closeModal()">Cancel</button>
            <button class="planScreenBtns" style="padding-left: 20px;padding-right: 20px" [disabled]="selectedOption === null || selectedOption === undefined || selectedOption === ''" (click)="addEntry()">Add</button>
        </div>
    </p-footer>
</p-dialog>
