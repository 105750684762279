<p-sidebar (onHide)="close()" [(visible)]="display"
           [baseZIndex]="baseZIndex"
           [showCloseIcon]="false" [style]="{minWidth:'60%',maxWidth:'60%',height:'100%', marginTop:marginTop}" position="{{(sidebarPosition === null || sidebarPosition === undefined || (sidebarPosition !== 'left' && sidebarPosition !== 'right'))?'right':sidebarPosition}}">
  <div class="header">
    <i (click)="close()" class="material-icons closable">clear</i>
    <div class="headerTitle">{{getHeader()}}</div>
    <div class="obligationInfo">
      <p>{{getId()}}</p>
      <div *ngIf="detailsOf === 'obligation'" class="box" pTooltip="{{getTooltipConfirmed()}}"
           tooltipZIndex="9999">{{getIconTick()}}</div>
      <div class="buySellDiv">
        <mat-icon *ngIf="tradeObject['tradeTransactionType'] === 'BUY'" class="buyIcon">call_made</mat-icon>
        <mat-icon *ngIf="tradeObject['tradeTransactionType'] === 'SELL'" class="sellIcon">call_received</mat-icon>
        <div class="buySell">{{tradeObject['tradeTransactionType']}}</div>
      </div>
      <div *ngIf="rowData['tradeStatus']" [ngClass]="getStatusClass()">{{getStatusString()}}</div>
    </div>
    <div class="buttonDiv">
      <ctrm-button [label]="'Export'" icon="fas fa-file-export"></ctrm-button>
      <ctrm-button [label]="'Print'" icon="fas fa-print"></ctrm-button>
    </div>
  </div>
  <div class="content">
    <p-tabView (activeIndexChange)="onIndexChange($event)">
      <p-tabPanel *ngFor="let tab of tabs;index as i" [selected]="i===0?true:false" header="{{tab.getLabel()}}">
        <div *ngIf="i === 0">
          <ctrm-input-form
            [columnsMap]="tab.getGrid().getColumn()"
            [editUrl]="editUrl"
            [gridConfig]="tab.getGrid().getGridConfig()"
            [headingRequired]="false"
            [noOfColumns]="3"
            [readOnly]="true"
            [sections]="tab.getGrid().getSections()"
            [showFooter]="false"
            [showOnModal]="false"
            [toggleReadOnlyAtStart]="false">
          </ctrm-input-form>
        </div>
        <div *ngIf="currentIndex === i">
          <ctrm-grid (onButtonClick)="onClickButton($event)" *ngIf="i>0"
                     [grid]="tab.getGrid()"
                     [isOnSidebar]="true"
                     [noDataLabel]="tab.noDataLabel"
                     [showFormOnModal]="false"
                     [showSectionTop]="false"
                     [showTitle]="false"></ctrm-grid>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</p-sidebar>
<doc-viewer (OnClose)="onCloseDocViewer($event)" [docUrl]="docUrl" [fileName]="docName"
            [modalState]="showDoc"></doc-viewer>
