import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(private http: HttpClient) {
  }

  private _bulk_url: string;

  get bulk_url(): string {
    return this._bulk_url;
  }

  set bulk_url(value: string) {
    this._bulk_url = value;
  }

  private _save_url = '';

  get save_url(): string {
    return this._save_url;
  }

  set save_url(value: string) {
    this._save_url = value;
  }

  private _edit_url = '';

  get edit_url(): string {
    return this._edit_url;
  }

  set edit_url(value: string) {
    this._edit_url = value;
  }

  private _get_url = '';

  get get_url(): string {
    return this._get_url;
  }

  set get_url(value: string) {
    this._get_url = value;
  }

  saveBulkData(values: any[]): Observable<any[]> {
    return this.http.post<any[]>(this.bulk_url, {bulk: values})
      .map((response: any[]) => {
        return response;
      });
  }

  saveSingleData(resourceData: any): Observable<any[]> {
    return this.http.post<any[]>(this.save_url, resourceData)
      .map((response: any[]) => {
        return response;
      });
  }

  updateSingleData(resourceData: any, url: any): Observable<any[]> {
    return this.http.patch<any[]>(url, resourceData)
      .map((response: any[]) => {
        return response;
      });
  }

  async getAll(): Promise<any[]> {
    var response = await this.http.get<any[]>(this.get_url).map((response: any[]) => {
      return response;
    }).toPromise();
    return response;
  }

  getData(): Observable<any[]> {
    return this.http.get<any[]>(this.get_url);
  }

  saveDataById(bean: any, id: any): Observable<any[]> {
    return this.http
      .put<any[]>(
        this.save_url + '/' + id,
        {bean}
      )
      .map((response: any[]) => {
        return response;
      });
  }

  getDataById(id: any): Observable<any[]> {
    return this.http.get<any[]>(this.save_url + '/' + id, {});
  }

  getDataByURL(href: any): Observable<any[]> {
    return this.http.get<any[]>(href, {});
  }

  saveDetails(resourceData: any, href: string): Observable<any[]> {
    return this.http.patch<any[]>(href, resourceData)
      .map((response: any[]) => {
        return response;
      });
  }

  async deleteDetails(href: string): Promise<any> {
    const response = await this.http.delete<any[]>(href, {}).toPromise();
    return response;
  }
}
