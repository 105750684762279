<div [ngStyle]="{'min-width':minWidth,'width':width,'max-width':maxWidth}" class="dropdown">
  <select (change)="outValue(field)" (touch)="onTouched" [(ngModel)]="value"
          [name]="name" [ngClass]="showBorder?'dropdown-text':'dropdown-text-borderless'" [ngStyle]="selectstyle"
          required="true">
    <option *ngIf="addSelectOption" [ngStyle]="selectstyle" [selected]="addSelectOption" value="{{selectValue}}">Select</option>
    <option *ngFor="let listitem of field;index as i" [hidden]="ignoreList.includes(listitem.value)"
            [ngStyle]="selectstyle"
            [value]="listitem.value">
      {{listitem.label}}
    </option>
  </select>
</div>
