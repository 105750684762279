import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {Tab} from "../../../tab-grid/Tab.model";
import {MasterService} from "../../../masters/services/MasterService";
import {CommonService} from "../../../services/common.service";
import {ActivatedRoute} from "@angular/router";
import {CashforwardComponent} from "../../../physicals/cashforward/cashforward.component";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-fx-planning-info',
  templateUrl: './fx-planning-info.component.html',
  styleUrls: ['./fx-planning-info.component.css']
})
export class FxPlanningInfoComponent implements OnInit {
  @Input() display: boolean = false;
  @Input() fxTradeId: string = '';
  @Input() sidebarPosition: string = 'right';
  @Input() rowData: any = {};
  @Output() onClose = new EventEmitter();
  tabs: Tab[] = [];
  cashforward: CashforwardComponent;
  fxObject: any = {};
  editUrl: string;
  cashforwardUrl: string;
  docUrl: string;
  docName: string;
  showDoc: boolean;

  constructor(private  masterCommonService: MasterService, private commonService: CommonService, private router: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.initTabs();
  }

  initTabs() {
    this.cashforward = new CashforwardComponent(this.masterCommonService, this.commonService);
    this.cashforward.ngOnInit();
    setTimeout(()=>{
      this.fetchTrade(this.fxTradeId);
      this.updateTabs();
    }, 1000)
  }

  updateTabs() {
    let _this = this;
    this.tabs = [];
    this.editUrl = this.getEditUrl();
    let tab = new Tab(this.cashforward.grid.getTitle(), this.cashforward.grid, 'fxTradeId', undefined, true, this.cashforwardUrl, {minHeight: '86.6vh'});
    tab.getGrid().setTabs([]);
    tab.getGrid().setGridConfiguration(false, false, false);
    tab.formOnModal = true;
    this.tabs.push(tab);
  }

  close() {
    this.display = false;
    this.onClose.emit();
  }

  fetchTrade(value) {
    this.commonService.getFromUrl(environment.base_url + '/api/fxTrade/v1/getallfxtradesbyfxtradeid?tenantId='+this.commonService.getTenantId()+ '&fxTradeId=' + this.rowData['fxTradeId']).subscribe((next:any) =>{
      if (next !== undefined && next !== null) {
        this.fxObject = next;
        this.updateTabs();
      }
    });
  }

  onIndexChange(value: number) {
  }

  onClickButton($event: any) {
  }

  onCloseDocViewer(value: any) {
    this.docUrl = '';
    this.showDoc = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['uuid']) {
    }
  }

  private getEditUrl() {
    let id = this.fxObject['fxTradeId'];
    return environment.base_url + '/api/fxTrade/v1/getallfxtradesbyfxtradeid?tenantId='+this.commonService.getTenantId()+ '&fxTradeId=' + id;
  }
}
