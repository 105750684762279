<div
  (mousedown)="onmousedown()"
  (mousemove)="onmousemove($event)"
  (mouseup)="onmouseup()"
  (mousewheel)="onmousewheel($event)"
  class="tree-pane">
  <div [style.transform]="paneTransform" class="tree-paning-container">
    <div *ngIf="nodes" class="tree-roots-elements">
      <tree-diagram-node
        (mousedown)="preventMouse($event)"
        *ngFor="let node of nodes['roots']"
        [nodeId]="node.guid"
        class="tree-root tree-node"></tree-diagram-node>
      <tree-diagram-node
        (click)="newNode()"
        (mousedown)="preventMouse($event)"
        [nodeId]="nodeMaker"
        class="tree-root tree-new-node tree-node"></tree-diagram-node>
    </div>
  </div>
</div>
