import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {Tab} from './Tab.model';
import {Tcolumn} from '../grid/tcolumn.model';
import {MasterService} from "../masters/services/MasterService";
import {BreadcrumbService} from "../Components/ctrm-breadcrumb/breadcrumb.service";
import {CommonService} from "../services/common.service";
import {Grid} from "../grid/grid.model";
import {environment} from "../../environments/environment";
import {DatePipe} from "@angular/common";
import {AccessPolicyService} from '../services/accesspolicy.service';

@Component({
  selector: 'app-tab-grid',
  templateUrl: './tab-grid.component.html',
  styleUrls: ['./tab-grid.component.css']
})
export class TabGridComponent implements OnInit, OnChanges ,AfterViewInit {

  @Input() tabs: Tab[];
  @Input() grid: Grid;
  @Input() onModal: boolean = false;
  @Input() parentKeyValue: any = '';
  @Input() modalState: boolean = false;
  @Output() onModalClose = new EventEmitter();
  @Output() onModalCancel = new EventEmitter();
  @Output() onChangeIndex = new EventEmitter();
  @Output() onRowDataSave = new EventEmitter();
  @Output() onRowDataDelete = new EventEmitter();
  @Output() addButtonValue = new EventEmitter();
  @Output() onButtonClick = new EventEmitter();
  @Output() onRouteBackClick = new EventEmitter();
  @Output() onMainUpdate = new EventEmitter();
  @Output() onTabChange = new EventEmitter();
  @Output() selectedRows = new EventEmitter();
  @Input() heading: string = 'HEADING';
  @Output() onRefreshComplete = new EventEmitter();
  @Output() onClickAdd = new EventEmitter();
  @Output() onClickEdit = new EventEmitter();
  @Input() listOfValues: any;
  @Input() refreshGrid: boolean = false;
  tabsNew: Tab[] = [];
  @Input() activeIndex = 0;
  currentIndex: number = 0;
  @Input() refreshTabs:any = '';
  oldParentKeyValue: any = '';
  @Input() showTitle: boolean = false;
  @Input() showBreadcrumb: boolean = false;
  @Input() currentRow: any = {};
  @Input() showSectionTop: boolean = true;
  @Input() showSectionInfo: boolean = true;
  @Input() editEnabled: boolean = true;
  @Input() secondLevelEdit: boolean = true;
  @Input() openFormForNewEntry:boolean = false;
  @Output() onFormOpenDone = new EventEmitter();
  hideShowTitle: string = 'Hide Details';
  editModalState: boolean = false;
  editUrl: string = '';
  numberOfColumns: number = 1;
  datePipe: DatePipe;
  viewMore: boolean = false;
  infoHeight: string = '';
  totalElements: string = '0';
  refreshHeight: boolean = false;
  autoCalculateHeight: boolean = true;
  @Input() urlIdFieldValue: string = '';
  display: boolean = false;
  @Input() screentype: string = null;
  constructor(private changeDetectorRef:ChangeDetectorRef,public masterService: MasterService,
              public breadCrumbService: BreadcrumbService, public commonService: CommonService,public accessPolicyService:AccessPolicyService) {
  }

  @HostListener("window:resize", [])
  onResize() {
    this.calculateColumnsNumber();
  }

  ngOnInit() {
    this.updateParentValue(this.tabs,this.parentKeyValue);
    this.datePipe = new DatePipe('en-US');
    this.calculateColumnsNumber();
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  toggleViewMore() {
    this.viewMore = !this.viewMore;
    this.autoCalculateHeight = !this.viewMore;
  }

  /**
   * close if ther any modal
   */
  closeModal() {
    this.modalState = false;
    this.onModalClose.emit();
  }

  cancelModal() {
    this.modalState = false;
    this.onModalCancel.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tabs']) {
      this.updateParentValue(changes['tabs'].currentValue,this.parentKeyValue);
    }
    if (changes['parentKeyValue']) {
      this.updateParentValue(this.tabs,changes['parentKeyValue'].currentValue);
    }

    if(changes['activeIndex']) {
      this.currentIndex = changes['activeIndex'].currentValue;
    }
  }


  /**
   * work to do when user select a tab
   * @param value
   */
  onIndexChange(value: number) {
    this.currentIndex = value;
    this.onChangeIndex.emit(value);
    this.onTabChange.emit({rowData: this.currentRow, index: value, tab: this.tabsNew[value]});
    this.totalPageDisplay();
  }

  /**
   * this is an output emitter for Row save
   * @param value
   * @param label
   */
  onSave(value: any, label: string) {
    this.onRowDataSave.emit({data: value, label: label});
  }

  /**
   * this is an output emitter for Row Delete
   * @param value
   * @param label
   */
  onDelete(value: any, label: string) {
    this.onRowDataDelete.emit({data: value, label: label});
  }

  /**
   * emitter when user click on add button
   * @param value
   */
  addbtnValue(value) {
    this.addButtonValue.emit(value)
  }

  onBttonClick(value: any) {
    this.onButtonClick.emit(value);
  }

  changeRouteToBack() {
    this.onRouteBackClick.emit();
  }

  toggleHideShow() {
    if (this.hideShowTitle === 'Hide Details') {
      this.hideShowTitle = 'Show Details';
    } else {
      this.hideShowTitle = 'Hide Details';
    }
  }

  canShow() {
    return this.hideShowTitle === 'Hide Details';
  }

  OnFormClose(value: any, addButtonValue: EventEmitter<any>) {
    this.onMainUpdate.emit(value);
  }

  OnCancel() {
    this.editModalState = false;
  }

  onClick(value: any) {
    this.onButtonClick.emit(value);
  }

  toggleModal() {
    this.editModalState = true
  }

  /***
   * returns dropdown list values for a particular row
   * @param col
   * @param ri
   */
  getListOfValues(col: Tcolumn) {
    let lov = col.getListOfValues();
    if (this.listOfValues !== undefined) {
      let fieldname = col.getField();
      if (this.listOfValues.has(fieldname)) {
        lov = this.listOfValues.get(fieldname);
      }
    }
    return lov;
  }

  /**
   * this function is used to get label from Key value pair accoring to that colValue passwed here
   * @param column
   * @param colValue
   * @param ri
   */
  public getColumnValue(column: Tcolumn, colValue: any) {
    let listOfValues = this.getListOfValues(column);
    if (listOfValues !== undefined) {
      for (let i = 0; i < listOfValues.length; i++) {
        if (listOfValues[i]['value'].toString() === colValue.toString()) {
          return listOfValues[i]['label'];
        }
      }
    }
  };

  getColumnMultipleValue(col: Tcolumn, rowDatum: any) {
    let sp = rowDatum.toString().split(',');
    let lb = '';
    let _this = this;
    sp.forEach(function (list, i) {
      if (i !== 0) {
        lb += ', ';
      }
      lb += _this.getColumnValue(col, list);
    });
    return lb;
  }

  getListFieldValue(col: Tcolumn, value) {
    /**
     * this method returns value of list
     * from key values pair
     *
     **/
    if (col.getColumnType() === 'ML') {
      return (value !== null && value !== undefined && value.toString().length > 0) ? this.getColumnMultipleValue(col, value) : '';
    } else {
      return (value !== null && value !== undefined && value.toString().length > 0) ? this.getColumnValue(col, value) : '';
    }
  }

  getRowValue(field: Tcolumn, rowData) {
    let output = '';
    if (rowData !== undefined) {
      output = this.commonService.checkAndGetValue(field, rowData);
      if ((output === undefined || output.length === 0) && rowData !== undefined && rowData !== null) {
        let value = rowData[field.getField()];
        if (environment.componentTextType.includes(field.getColumnType())) {
          output = value;
        } else if (field.getColumnType() === 'D') {
          output = value !== undefined && value !== null && value.length > 0 ? this.datePipe.transform(new Date(value), field.getFromExtra('date')['format'] !== undefined ? field.getFromExtra('date')['format'] : environment.dateFormat) : '';
        } else if (field.getColumnType() === 'F') {
          output = rowData[field.getField() + 'FileName'];
        } else if (field.getColumnType() === 'DSL') {
          output = this.commonService.getDualSliderValue(rowData[field.getField()], field, rowData);
        } else if (field.getColumnType() === 'OB' || field.getColumnType() === 'B') {
          output = (value !== undefined && value !== null && value !== '') ? this.getListFieldValue(field, value) : value;
        } else  {
          output = value;
        }
      }
    }
    if (output === undefined || output === null || output.length === 0) {
      output = '-';
    }
    return output;
  }

  getExpandLabel() {
    return this.viewMore ? 'View Less' : 'View More';
  }

  hasSections(grid: Grid) {
    return false;// grid.getSections() !== undefined && grid.getSections().size>1;
  }

  calculateColumnsNumber() {
    this.numberOfColumns = (window['visualViewport'].width - 30) / 250;
    this.calculateInfoHeight();
  }

  calculateInfoHeight() {
    if (document.getElementById('infoBox') !== undefined && document.getElementById('infoBox') !== null) {
      this.infoHeight = ((window['visualViewport'].height - document.getElementById('infoBox').offsetTop) - 200) + 'px';
    }
  }

  totalPage(value, index) {
    this.tabsNew[index]['totalElements'] = value;
    this.totalPageDisplay();
  }

  totalPageDisplay() {
    this.totalElements = this.tabsNew[this.currentIndex]['totalElements'];
    if (this.totalElements == undefined) {
      this.totalElements = "0";
    }
    this.totalElements += " " + this.tabsNew[this.currentIndex]['label'];
  }

  onHeightRefreshComplete(value, ri: number) {
    if (this.currentIndex === ri) {
      this.refreshHeight = false;
    }
  }

  /**
   * this function change the defualt value of parent field accoring to user choice
   * so in second level tabs parent level grid primary key value is auto populate to
   * the user choice
   */
  private updateParentValue(tabs: Tab[],parentKeyValue:any) {
    const _this = this;
    this.tabsNew = [];
    this.oldParentKeyValue = parentKeyValue;
    tabs.forEach(function (tab: Tab, i) {
      if (tab !== null && tab !== undefined && tab.getGrid() !== undefined && tab.getGrid() !== null) {
        tab.getGrid().setToExtra('istabs', true);
        tab.updateDataUrl(_this.currentRow[tab.getGrid().urlIdField]);
        if (tab.getParentPrimaryKey() !== undefined && tab.getParentPrimaryKey().length > 0) {
          tab.getGrid().getColumn().get(tab.getParentPrimaryKey()).setDefaultValue(parentKeyValue);
        }
        tabs.splice(i, 1);
        tabs.splice(i, 0, tab);
      }
    });
    this.tabsNew = [...tabs];
  }


  getExtraObject(tab: Tab) {
    let obj = {};
    if(tab.idFieldValueName !== undefined && tab.idFieldValueName !== null && tab.idFieldValueName.length > 0) {
      obj[tab.idFieldValueName] = this.urlIdFieldValue;
      return obj;
    }
  }

  getEditIcon() {
    return this.grid.getGridConfig().canEdit && this.canEditRow(this.currentRow)? 'fas fa-pen' : 'fas fa-eye';
  }

  getEditLabel() {
    return this.grid.getGridConfig().canEdit && this.canEditRow(this.currentRow) ? 'Edit' : 'View';
  }

  private canEditRow(rowData) {
    let canEdit: boolean = true;
    if (this.grid.actionFunc !== undefined && this.grid.actionFunc !== null && this.grid.actionFunc.canEditFunction !== undefined && this.grid.actionFunc.canEditFunction !== null) {
      canEdit = this.commonService.runFunction(rowData, this.grid.actionFunc.canEditFunction);
    }
    return canEdit;
  }

    isValidTab(tab: Tab) {
        return (tab !== undefined && tab !== null);
    }

  onRowSelect(data: any, label: string) {
    this.selectedRows.emit({title:label,data:data});
  }

  onFormOpenDoneFn() {
    this.onFormOpenDone.emit();
  }

  onCompleteTabRefresh(label: string) {
    this.onRefreshComplete.emit(label);
  }

  opendailog() {
    this.display = !this.display
  }

  closeaudittrail() {
    this.display = false;
  }

  isTemplate(tab) {
    return tab.getTabTemplate() !== undefined && tab.getTabTemplate() !== null;
  }
  onAddClick(value: any) {
    this.onClickAdd.emit(value);
  }

  onEditClick(event: any, tab: Tab){
    event.screenName = tab.getGrid().getTitle();
    this.onClickEdit.emit(event);
  }

  callDelete(event: any, tab: Tab) {
    if(tab.getGrid().isCustomDelete()) {
      tab.getGrid().deleteFunction(event);
    }
  }
}
