<div style="display: flex; margin-top:0;">
  <ng-container *ngIf="gridPosition === 'right'" [ngTemplateOutlet]="countbox"></ng-container>
  <div [style]="gridPosition === 'left'?{width:'100%'}:{width:'100%',marginLeft:'5px'}">
    <div style="display: flex">
      <div class="tableHeading">
        <p class="tablehead">{{heading}}</p>
        <p class="totalObl"> {{totalHeading}}{{totalElements}}</p>
      </div>
    </div>
  </div>
  <div [ngClass]="getFilterHeading()" [hidden]="hideFilter">
    <div style="display: inline-flex;">
      <i [ngClass]="getFilterClass()['icon']" style="padding-top: 4px"></i>
      <p [ngClass]="getFilterClass()['colour']">{{getFilterClass()['header']}}</p>
    </div>
    <p *ngIf="filterList.length > 0" class="clearAll" (click)="clearFilter()">Clear filter </p>
  </div>

  <ng-container *ngIf="gridPosition === 'left'" [ngTemplateOutlet]="countbox"></ng-container>
</div>
<ng-template #countbox>
  <div style="display: flex; margin-bottom: 5px">
    <div [style]="gridPosition === 'left'?{textAlign:'right'}:{textAlign:'left'}">
      <div class="totalCard">
        <h5 class="totalHeading">{{totalMatchHeading}}</h5>
        <p class="totalStats" [style]="gridPosition === 'left'?{textAlign:'right'}:{textAlign:'left'}">{{commonService.formatNumberWithoutComma(totalMatch,matchRoundOff)}}</p>
      </div>
    </div>
  </div>
</ng-template>

<div #box (inViewportAction)="calculateLeft()" class="obligationGrid" inViewport>
  <p-table #tb (inViewportAction)="calculateHeight()" (onRowSelect)="onRowSelect()" [(selection)]="selectedRows"
           [columns]="cols"
           [responsive]="true" [style]="{maxHeight:planningGridHeight,minHeight:planningGridHeight}"
           [value]="value"
           autoLayout="true" class="style-4 table-responsive background {{gridPosition}}"
           inViewport>
    <ng-template pTemplate="header">
      <tr class="sticky-top" style="background-color: #D9DBDE">
        <th *ngFor="let col of cols;index as i" [id]="col.field" [ngClass]="getHeaderClass(col.header,col.field)"
            [style]="getStickStyle(col)"
            style="white-space: nowrap; background-color: #d9dbde">
          <div *ngIf="col.header === 'CB'"
               style="text-align: center; background-color: #D9DBDE;  padding: 15px;color: #767676; font-size: 12px; line-height: 20px">
          </div>
          <div *ngIf="col.field === matchFieldName"
               [style.maxWidth]="matchBalanceColumnsWidth+'px'"
               [style.minWidth]="matchBalanceColumnsWidth+'px'" style="color: #15104E; padding: 10px;font-size: 12px; line-height: 20px; text-align: center;background-color: #ACABBE;white-space: nowrap">
            {{col.header}}
          </div>

          <div (inViewportAction)="autoScroll()" *ngIf="col.field ===  balanceFieldName" [style.maxWidth]="matchBalanceColumnsWidth+'px'"
               [style.minWidth]="matchBalanceColumnsWidth+'px'"
               inViewport style="color: #15104E; padding: 10px; font-size: 12px; line-height: 20px; text-align: center;background-color: #ACABBE;white-space: nowrap">
            {{col.header}}
          </div>
          <div *ngIf="col.header !== 'CB' && col.field !== matchFieldName && col.field !== balanceFieldName" class="tableHeaderClass">
            <p [ngClass]="getClassForHeading(i)">{{col.header}}</p>
              <img [id]="(col.field)" *ngIf="(col['showFilter'] !== undefined && col['showFilter'] !== null && col['showFilter'])? true: false"
                   [ngClass]="iconClass(col.field)" (click)="showFilters(col,op,$event)" src="../../../assets/icons/{{showiconOnFilter(col.field)}}">

          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template let-columns="columns" let-ri="rowIndex" let-rowData pTemplate="body">
      <tr [pSelectableRow]="updateIdAndGetRowData(rowData,ri)">
        <td *ngFor="let col of cols" [ngClass]="getClassForBody(col)" [style]="getStickStyle(col)" pEditableColumn>
          <div *ngIf="col.header === 'CB'" [ngClass]="getChkBoxBrder(rowData)">
            <p-tableCheckbox [style]="{display:'flex'}" [disabled]="isDisabled(rowData)" (click)="onClickCheckbox(rowData,ri)" [value]="rowData"></p-tableCheckbox>
          </div>
          <div
            (click)="clickableColumns.includes(col.field)?onClickable(col.field,rowData):''"
            *ngIf="col.field !== 'CB' && !col.editable  && col.field !== 'fxTradeType' && col.field !== 'comingle' && col.field !== 'virtualStorage' && col.field !== primaryKey"
            [ngClass]="clickableColumns.includes(col.field)?'primaryKeyClasss':''"
            [innerHTML]="getRowValue(rowData,col)"
            [ngStyle]="(col.field === balanceFieldName || (col.field === matchFieldName && !col.editable))?{borderLeft: '2px solid #e6e6e6', borderRight: '2px solid #e6e6e6', borderBottom: '2px solid #e6e6e6'}:''">
          </div>

          <div (click)="clickableColumns.includes(col.field)?onClickable(col.field,rowData):''"
               *ngIf="col.field === 'fxTradeType'"
               [ngClass]="clickableColumns.includes(col.field)?'primaryKeyClasss':''">
            {{rowData[col.field]}}
          </div>
          <div (click)="clickableColumns.includes(col.field)?onClickable(col.field,rowData):''" *ngIf="col.field === 'comingle'"
               [ngClass]="clickableColumns.includes(col.field)?'primaryKeyClasss':''">
            {{rowData[col.field] ? 'Comingle' : 'Non-Comingle'}}
          </div>
          <div (click)="clickableColumns.includes(col.field)?onClickable(col.field,rowData):''"
               *ngIf="col.field === 'virtualStorage'"
               [ngClass]="clickableColumns.includes(col.field)?'primaryKeyClasss':''">
            {{rowData[col.field] ? 'Physical' : 'Virtual'}}
          </div>
          <div *ngIf="col.field === primaryKey">
            <div (click)="primaryKeyClickable?onClickPrimary(rowData):''"
                 [ngClass]="primaryKeyClickable?'primaryKeyClasss':''"
                 style="font-size: 12px;">{{getRowValue(rowData, col)}}</div>
          </div>

          <div *ngIf="col.editable"
               style="border-right:2px solid #e6e6e6; border-bottom: 2px solid #e6e6e6; border-left: 2px solid #e6e6e6">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input (focusout)="onFocusOut(ri,rowData)" (keydown.enter)="onFocusOut(ri, rowData)"
                       (ngModelChange)="updateBalanceQty(rowData,ri,$event)"
                       [(ngModel)]="rowData[col.field]"
                       [disabled]="!selectedRows.includes(rowData)" pInputText
                       style="font-size: 12px; padding:0" type="number">
              </ng-template>
              <ng-template pTemplate="output">
                <div style="font-size: 12px;"> {{rowData[col.field]}}</div>
              </ng-template>
            </p-cellEditor>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr>
        <td [colSpan]="cols.length">
          <div *ngIf="showPlaceHolder && (value === undefined ||value === null || value.length === 0)"
               [style]="{maxHeight:placeHolderHeight}" class="placeHolder">
            <ng-container
              *ngTemplateOutlet="noDataTemplate !== undefined?noDataTemplate:defualtTemplateNoData"></ng-container>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-paginator *ngIf="getPagination()" [rows]="20" [totalRecords]="totalElements" (onPageChange)="onPageChange($event)"></p-paginator>
  <ng-template #defualtTemplateNoData>
    <div (inViewportAction)="calculateLeft()" [id]="'noDataBoxId'" [style]="{left:noDataBoxLeft}" class="noDataBox"
         inViewport>
      <img [src]="commonService.getIconPath('emptyStateIcon')" class="iconClass"/>
      <p class="noDataHeader">{{loadingDataLabel === 'No Data Available'?noDataLabel:loadingDataLabel}}</p>
      <div class="buttonsBox">
        <div (click)="changeRouteNoData()" *ngIf="noDataRoute !== undefined && noDataRoute !== null && noDataRoute.length > 0 && loadingDataLabel === 'No Data Available'"
             class="noDataButton">
          <p class="noDataButtonLabel">{{routeToLable}}</p>
        </div>
      </div>
    </div>
  </ng-template>


  <div *ngIf="value === []" style="text-align: center">
    <div class="noObl">No Obligations</div>
  </div>
</div>
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>

<p-overlayPanel #op >
  <ng-template pTemplate >
    <div >
      <div [style.left]="left" [style.right]="right" class="filterContext" appendTo="body">
        <div style="width: 100%;display: inline-flex;padding: 10px">
          <p [ngClass]=pDisable(col)
             (click)="onApply(col)">Apply</p>
          <p class="cancelBtn" (click)="clearfilter(col.field)">Clear</p>
        </div>
        <div class=dateCalendar *ngIf="col.type === 'date';else notDate">
          <bs-datepicker-inline (bsValueChange)="updatevalue($event,col.field,true)"
                                [bsConfig]="{dateInputFormat : getDateFormat(),useUtc:true}"></bs-datepicker-inline>
        </div>
        <ng-template #notDate class="style-4 table-responsive">
          <input class="input_filter" [placeholder]="'Enter to filter'" [(ngModel)]="filterSearch[col.field]">
          <p-listbox [options]="filterOptionsList[col.field] | filterPipe:filterSearch[col.field]"
                     [metaKeySelection]="false" [showToggleAll]="false"
                     [checkbox]="true" [filter]="false"
                     [multiple]="true" [(ngModel)]="filterValues[col.field]"
                     [listStyle]="{'max-height':'250px'}" [style]="{'width':'15rem'}" ></p-listbox>
        </ng-template>
      </div>
    </div>

  </ng-template>
</p-overlayPanel>
