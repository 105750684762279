import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AuthBean} from './models/Authentication.model';
import {UserMasterInfoBean} from './models/userMasterInfo.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  url = environment.base_url;

  constructor(private http: HttpClient) {
  }

  signupUser(user: AuthBean) {
    return this.http
      .post<AuthBean[]>(
        this.url + 'auth/signup',
        user
      )
      .map((response: AuthBean[]) => {
        return response;
      });
  }

  postUserInfo(info: UserMasterInfoBean) {
    return this.http
      .post<UserMasterInfoBean[]>(
        this.url + 'userInfo',
        info
      )
      .map((response: UserMasterInfoBean[]) => {
        return response;
      });
  }

  getUserByUserID(userid): Observable<AuthBean[]> {
    return this.http.get<AuthBean[]>(this.url + 'users/search/findByUserid?userid=' + userid);
  }

  getUserByEmailID(emailid): Observable<AuthBean[]> {
    return this.http.get<AuthBean[]>(this.url + 'users/search/findByEmail?email=' + emailid, {});
  }

  ExistsUserByUserID(userid): Observable<boolean> {
    return this.http.get<boolean>(this.url + 'users/search/existsByUserid?userid=' + userid, {});
  }

  ExistsUserByEmail(email): Observable<boolean> {
    return this.http.get<boolean>(this.url + 'users/search/existsByEmail?email=' + email, {});
  }

  getUserInfoByUserID(userid): Observable<UserMasterInfoBean[]> {
    return this.http.get<UserMasterInfoBean[]>(this.url + 'userInfoes/search/findByUserid?userid=' + userid);
  }

  login(loginUrl, value) {
    return this.http
      .post<any>(
        loginUrl,
        value
      )
      .map((response: any) => {
        return response;
      });
  }

  //Get Authorization Token
  getToken(username, password){
    return this.http.post(environment.base_url + 'authenticate', {username:username, password:password});
  }
}
