  import {Component, OnInit} from '@angular/core';
import {BreadcrumbService} from '../../Components/ctrm-breadcrumb/breadcrumb.service';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {environment} from '../../../environments/environment';
import {CommonService} from '../../services/common.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ConfirmationService, MessageService} from 'primeng';

@Component({
  selector: 'app-transport-booking',
  templateUrl: './transport-booking.component.html',
  styleUrls: ['./transport-booking.component.css']
})
export class TransportBookingComponent implements OnInit {

  tabOptions: any[] = [{name: 'All', active: false}, {name: 'Open', active: false}, {name: 'In-Transit', active: false}, {
    name: 'Delivered',
    active: false
  }];

  bsConfig: Partial<BsDatepickerConfig>;
  selectedTab: string = 'All';
  radioValue: string = 'bookingDate';
  searchCommon: string;
  fromDate: string = '';
  toDate: string = '';
  chkBoxOptions: any[] = [{name: 'Bulk Vessel', active: false, icon: 'ship_icon'},
    {name: 'Ocean FCL', active: false, icon: 'ship_icon'},
    {name: 'Ocean LCL', active: false, icon: 'ship_icon'}];
    // {name: 'Air', active: false, icon: 'airplane_icon'},
    // {name: 'Road', active: false, icon: 'road_icon'},
    // {name: 'Rail', active: false, icon: 'rail_icon'}];
  allocStatusOption: any[] = [{name: 'Allocated', active: false, icon: 'truck_green'}, {name: 'Pending', active: false, icon: 'truck_red'}];
  detailPageToggle: boolean = false;
  editing: boolean = true;
  showEdit: boolean = false;
  showSave: boolean = true;
  bookingValue: any[] = [];
  bookingNumber: any;
  resetForm: boolean = false;


  constructor(public breadCrumbService: BreadcrumbService, public commonService: CommonService,
              private confirmationService: ConfirmationService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.breadCrumbService.makeBreadcrumbTo(this.commonService.OPERATION_MAP_KEY, '/transportBooking')
    this.bsConfig = new BsDatepickerConfig();
    this.bsConfig.dateInputFormat = environment.dateFormat.toUpperCase();
    this.bsConfig.adaptivePosition = true;
    this.bsConfig.useUtc = true;
    this.getRecords();
  }

  onTabClick(tab) {
    this.selectedTab = tab['name'];
  }

  getTabClass(tab){
    return tab['name'] === this.selectedTab ? 'tabBtnActive' : 'tabBtn';
  }

  onCheckFilterClick(option: any, index,fromCheckbox = false) {
    if (!fromCheckbox) {
      this.chkBoxOptions[index]['active'] = !this.chkBoxOptions[index]['active'];
    }
    this.getFilteredValue();
  }

  onAllocFilterClick(option: any, index) {
    this.allocStatusOption[index]['active'] = true;
  }

  onClickAddNewTransport() {
    this.resetForm = true;
    this.editing = true;
    this.showEdit = false;
    this.showSave = true;
    this.bookingNumber = '';
    this.detailPageToggle = !this.detailPageToggle;
  }

  onClickBackEvent() {
    this.detailPageToggle = !this.detailPageToggle;
    this.getRecords();
  }

  getRecords() {
    this.commonService.getJSONByURL(environment.base_url + '/api/bookingmanagement/v1/getbookingvalue?tenantId=' + this.commonService.getTenantId()).subscribe((next: any) => {
      this.bookingValue = next;
    });
  }

  getFilteredValue() {
    let url = '';
    let _this = this;
    let payload = this.commonFilterFunction();
    url = environment.base_url + '/api/bookingmanagement/v1/filterapi?tenantId=' + this.commonService.getTenantId();
    this.commonService.post(url, payload).subscribe((next: any) => {
      _this.bookingValue = next;
    }, (error: HttpErrorResponse) => {
      _this.showToast(_this.commonService.getHttpErrorMessage(error, 'Booking Management'), 'error');
    });
  }
  removeFilter(){
    this.getRecords();
    this.fromDate='';
    this.toDate='';
  }
  getRadioValue(value: any) {
    this.radioValue = value;
  }

  commonFilterFunction() {
    let filter: any[] = [];
    filter.push(this.getFilter('tenantId', this.commonService.getTenantId()));
    let freightType: string[] = [];
    this.chkBoxOptions.forEach((option) => {
      if (option.active) {
        if (!freightType.includes(option.name)) {
          freightType.push(option.name);
        }
      }
    });
    if (freightType.length > 0) {
      filter.push(this.getFilter('shippingMode',freightType, '', 'in'));
    }
    if(this.toDate!=''&& this.fromDate!=''){
    if (this.radioValue !== undefined && this.radioValue !== null && this.radioValue.length > 0) {
      if (this.radioValue === 'bookingDate') {
        filter.push(this.getFilter('bookingDate', this.commonService.convertDatetoUTC(this.fromDate),
            this.commonService.convertDatetoUTC(this.toDate), 'betweendates'));
      } else {
        filter.push(this.getFilter('vesselEta', this.commonService.convertDatetoUTC(this.fromDate),
            this.commonService.convertDatetoUTC(this.toDate), 'betweendates'));
      }
    }
    }
    return filter;
  }

  getFilter(fieldName, value, secondValue = '', condition = 'equals') {
    return {
      fieldName: fieldName,
      condition: condition,
      value: value,
      secondValue: secondValue
    };
  }


  showToast(msg, severity: string = 'info') {
    this.messageService.add({
      severity: severity,
      summary: msg
    });
  }

  getDateFormat() {
    return environment.dateFormat.toUpperCase();
  }

  onResetComplete() {
    this.resetForm = false;
  }

  onItemCardClick(value: any) {
    this.bookingNumber = value['bookingNumber'];
    this.editing = false;
    this.showEdit = true;
    this.showSave = false;
    this.detailPageToggle = !this.detailPageToggle;
  }

  //Delete Item
  deleteBookingDetails(value: any) {
    //calling Delete REST
    let _this = this;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete the details?',
      accept: () => {
        this.commonService.getJSONByURL(environment.base_url + '/api/bookingmanagement/v1/deletebookingdetailsbybookingnumber?tenantId='
            + this.commonService.getTenantId() + '&bookingNumber=' + value['bookingNumber']).subscribe(next => {
          _this.messageService.add({severity: 'info', summary: 'Deleted Successfully'});
          this.getRecords();
        },(error:HttpErrorResponse) => {
          _this.messageService.add({severity: 'error', summary: _this.commonService.getHttpErrorMessage(error,"Transport Management")});
        })
      }, reject: () => {
        this.getRecords()
      }
    });

  }
}
