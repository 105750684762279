<ctrm-grid
        [breadcrumbMapKey]="commonService.OPERATION_MAP_KEY"
        [currentRoute]="'/storageInventory'"
        [grid]="grid"
        [refreshGrid]="refreshGrid"
        (onRefreshComplete)="refreshGrid = false"
        (onClickUniqueKey)="onClickUnique($event)"
        (onClickEdit)="onClickUnique($event)"
        [defaultEdit]="false"
        [showActions]="false"
        (onClickAdd)="onClickAdd()"
        [showStandardFilter]="true"
        [saveOnSave]="false">
</ctrm-grid>

<!--Add Inventory Data-->
<app-input-form [header]="header"
                *ngIf="displayOnAdd"
                [formContent]="formcontorl"
                [saveButtonDisabled]="saveButtonDisabled"
                [saveButtonLabel]="saveButtonLabel"
                [formGroup]="inventoryForm"
                [validationSections]="validationSections"
                (onSave)="onSave()"
                [visible]="true"
                (onClose)="onClose()"></app-input-form>
<ng-template #formcontorl>
        <form [formGroup]="inventoryForm">
            <div class="mainbox">
                <div class="sectionBoxMain">
                    <div class="formSectionTitle">
                        <div class="headerTitle">Basic Details</div>
                    </div>
                    <div class="row rowClass">
                        <div class="col-md-4 controlGap contentbox">
                            <div class="fieldBox">
                                <label class="label ng-star-inserted">Storage Id</label>
                                <input id="storageId" type="text"
                                       disabled
                                       class="form-control form-control-sm input"
                                       pInputText placeholder="Storage Id"
                                       formControlName="storageId">
                            </div>
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <div class="fieldBox">
                                <label class="label ng-star-inserted">Storage Facility</label>
                                <div>
                                    <p-selectButton [formControl]="inventoryForm.controls['storageFacility']"
                                                    [options]="storageFacilityValues"
                                                    (onChange)="onChangeStorageFacility($event)"
                                                    tooltipEvent="focus" tooltipPosition="bottom"
                                                    tooltipZIndex="1000">
                                        <ng-template let-item>
                                            <div class="optionButton">
                                                <i [ngClass]="inventoryForm.controls['storageFacility'].value === item.value?'fas fa-check-circle':'far fa-circle'"></i>
                                                <span>{{item.label}}</span>
                                            </div>
                                        </ng-template>
                                    </p-selectButton>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <div class="fieldBox">
                                <label class="label ng-star-inserted">Storage Facility Owner</label>
                                <p-dropdown [disabled]="false"
                                            [id]="'ownerName'"
                                            [options]="ownerNameList"
                                            appendTo="body"
                                            tooltipEvent="focus" tooltipPosition="bottom"
                                            tooltipZIndex="1000"
                                            formControlName="ownerName">
                                </p-dropdown>
                            </div>
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <div class="fieldBox">
                                <label class="label ng-star-inserted">Mode Of Storage</label>
                                <div>
                                    <p-selectButton [formControl]="inventoryForm.controls['modeOfStorage']"
                                                    [options]="modeOfStorageValues"
                                                    tooltipEvent="focus" tooltipPosition="bottom"
                                                    tooltipZIndex="1000">
                                        <ng-template let-item>
                                            <div class="optionButton">
                                                <i [ngClass]="inventoryForm.controls['modeOfStorage'].value === item.value?'fas fa-check-circle':'far fa-circle'"></i>
                                                <span>{{item.label}}</span>
                                            </div>
                                        </ng-template>
                                    </p-selectButton>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <div class="fieldBox">
                                <label class="label ng-star-inserted">Storage Currency</label>
                                <p-dropdown [disabled]="false"
                                            [id]="'storageCurrency'"
                                            [options]="storageCurrencyList"
                                            appendTo="body"
                                            (onChange)="storageCurrency($event)"
                                            tooltipEvent="focus" tooltipPosition="bottom"
                                            tooltipZIndex="1000"
                                            formControlName="storageCurrency">
                                </p-dropdown>
                            </div>
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <div class="fieldBox">
                                <label class="label ng-star-inserted">Status</label>
                                <div>
                                    <p-selectButton [options]="statusValues"
                                                    [formControl]="inventoryForm.controls['active']"
                                                    tooltipEvent="focus" tooltipPosition="bottom"
                                                    tooltipZIndex="1000">
                                        <ng-template let-item>
                                            <div class="optionButton">
                                                <i [ngClass]="inventoryForm.controls['active'].value === item.value?'fas fa-check-circle':'far fa-circle'"></i>
                                                <span>{{item.label}}</span>
                                            </div>
                                        </ng-template>
                                    </p-selectButton>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <div class="fieldBox">
                                <label class="label ng-star-inserted">Comingle</label>
                                <div>
                                    <p-selectButton [formControl]="inventoryForm.controls['comingle']"
                                                    [options]="comingleValues"
                                                    (onChange)="onChangeComingle($event)"
                                                    tooltipEvent="focus" tooltipPosition="bottom"
                                                    tooltipZIndex="1000">
                                        <ng-template let-item>
                                            <div class="optionButton">
                                                <i [ngClass]="inventoryForm.controls['comingle'].value === item.value?'fas fa-check-circle':'far fa-circle'"></i>
                                                <span>{{item.label}}</span>
                                            </div>
                                        </ng-template>
                                    </p-selectButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--                Company Details-->
                <div class="sectionBoxMain">
                    <div class="formSectionTitle">
                        <div class="headerTitle">Company Details</div>
                    </div>
                    <div class="row rowClass">
                        <div class="col-md-4 controlGap contentbox">
                            <div class="fieldBox">
                                <label class="label ng-star-inserted">Company</label>
                                <p-dropdown [disabled]="false"
                                            [id]="'Company'"
                                            [options]="companyList"
                                            appendTo="body"
                                            tooltipEvent="focus" tooltipPosition="bottom"
                                            tooltipZIndex="1000"
                                            formControlName="company">
                                </p-dropdown>
                            </div>
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <div class="fieldBox">
                                <label class="label ng-star-inserted">Profit Center</label>
                                <p-dropdown [disabled]="false"
                                            [id]="'profitcentre'"
                                            [options]="profitcentreList"
                                            appendTo="body"
                                            tooltipEvent="focus" tooltipPosition="bottom"
                                            tooltipZIndex="1000"
                                            formControlName="profitcentre">
                                </p-dropdown>
                            </div>
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <div class="fieldBox">
                                <div *ngIf="!isComingle">
                                    <label class="label ng-star-inserted">Commodity</label>
                                    <p-dropdown [disabled]="false"
                                                (ngModelChange)="onSelectCommodity($event)"
                                                [id]="'commodity'"
                                                [options]="commodityList"
                                                appendTo="body"
                                                tooltipEvent="focus" tooltipPosition="bottom"
                                                tooltipZIndex="1000"
                                                formControlName="commodity">
                                    </p-dropdown>
                                </div>
                                <div *ngIf="isComingle">
                                    <label class="label ng-star-inserted">Commodity</label>
                                    <ctrm-multiselect formControlName="commodity"
                                                      (ngModelChange)="onSelectCommodity($event)"
                                                      [allowSearch]="true"
                                                      [id]="'commodity'"
                                                      [disabled]="false"
                                                      [list]="commodityMultiselectList"
                                                      [mclass]="'form-control form-control-sm'"
                                                      [msClass]="'form-control-normal'"
                                                      [multiple]="isComingle"
                                                      [outputFormat]="'string'"
                                                      [placeholder]="'Select Commodity'">
                                    </ctrm-multiselect>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--                Storage Details-->
                <div class="sectionBoxMain">
                    <div class="formSectionTitle">
                        <div class="headerTitle">Storage Details</div>
                    </div>
                    <div class="row rowClass">
                        <div class="col-md-4 controlGap contentbox">
                            <div class="fieldBox">
                                <label class="label ng-star-inserted">Storage Name</label>
                                <input id="storageName" type="text"
                                       class="form-control form-control-sm input"
                                       pInputText placeholder="Storage Name"
                                       formControlName="storageName">
                            </div>
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <div class="fieldBox">
                                <label class="label ng-star-inserted">Storage Type</label>
                                <p-dropdown [disabled]="false"
                                            [id]="'type'"
                                            [options]="typeList"
                                            appendTo="body"
                                            tooltipEvent="focus" tooltipPosition="bottom"
                                            tooltipZIndex="1000"
                                            formControlName="type">
                                </p-dropdown>
                            </div>
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <div class="fieldBox">
                                <label class="label ng-star-inserted">Storage UOM</label>
                                <p-dropdown [disabled]="false"
                                            (onChange)="onChangeUom($event)"
                                            [id]="'storageUom'"
                                            [options]="storageUomList"
                                            (onChange)="storageuom($event)"
                                            appendTo="body"
                                            tooltipEvent="focus" tooltipPosition="bottom"
                                            tooltipZIndex="1000"
                                            formControlName="storageUom">
                                </p-dropdown>
                            </div>
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <div class="fieldBox">
                                <label class="label ng-star-inserted">Total Capacity ( {{inventoryForm.controls['storageUom'].value}} )</label>
                                <input id="totalStorageCapacity" type="number"
                                       (input)="commonService.updateInputValidation($event,includedCharacters,true)"
                                       class="form-control form-control-sm input"
                                       pInputText placeholder="Total Capacity"
                                       formControlName="totalStorageCapacity"
                                       (focusout)="calculateAvailableCapacity()">
                            </div>
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <div class="fieldBox">
                                <label class="label ng-star-inserted">Stored Capacity ( {{inventoryForm.controls['storageUom'].value}} )</label>
                                <input id="storedCapacity" type="number" disabled
                                       (input)="commonService.updateInputValidation($event,includedCharacters,true)"
                                       class="form-control form-control-sm input"
                                       pInputText placeholder="Stored Capacity"
                                       formControlName="storedCapacity">
                            </div>
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <div class="fieldBox">
                                <label class="label ng-star-inserted">Available Capacity ( {{inventoryForm.controls['storageUom'].value}}
                                    )</label>
                                <input id="availableCapacity" type="number" disabled
                                       class="form-control form-control-sm input"
                                       pInputText placeholder="Available Capacity"
                                       formControlName="availableCapacity">
                            </div>
                        </div>
                    </div>
                </div>
                <!--                Add Packaging Details-->
                <div class="sectionBoxMain">
                    <div class="formSectionTitle">
                        <div class="headerTitle">Add Packaging Details</div>
                    </div>
                    <div class="storageGrid">
                        <app-ctrm-price-table
                                (onAddNewRowComplete)="onNewRowAddComplete()"
                                (onRefreshFormGroupComplete)="onRefreshFromGroupComplete()"
                                [columns]="storageCols" [gridDisplay]="true"
                                [refreshFormGroup]="refreshFormGroup"
                                (onFormGroupCreated)="onFormGroupCreatedPackage($event)"
                                (formGroupStatus)="onFromGroupStatusChangePackage($event)"
                                [checkValidityBeforeAddingNewRow]="true"
                                [newRows]="newRows" [refreshNewRows]="refreshAddNewRow"
                                [value]="storagePackagingList"
                                [showNewEntryIcon]="true"
                                (onChangeFieldValue)="onChangeValue($event)">
                        </app-ctrm-price-table>
                    </div>
                </div>
                <!--                Storage Valuation Details-->
                <div class="sectionBoxMain">
                    <div class="formSectionTitle">
                        <div class="headerTitle">Storage Valuation Details</div>
                    </div>
                    <div class="row rowClass">
                        <div class="col-md-4 controlGap contentbox">
                            <div class="fieldBox">
                                <label class="label ng-star-inserted">Storage</label>
                                <div>
                                    <p-selectButton [formControl]="inventoryForm.controls['virtualStorage']"
                                                    [options]="storageValues"
                                                    tooltipEvent="focus" tooltipPosition="bottom"
                                                    tooltipZIndex="1000">
                                        <ng-template let-item>
                                            <div class="optionButton">
                                                <i [ngClass]="inventoryForm.controls['virtualStorage'].value === item.value?'fas fa-check-circle':'far fa-circle'"></i>
                                                <span>{{item.label}}</span>
                                            </div>
                                        </ng-template>
                                    </p-selectButton>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <div class="fieldBox">
                                <label class="label ng-star-inserted">Valuation Method</label>
                                <p-dropdown [disabled]="false"
                                            [id]="'valuationMethod'"
                                            [options]="valuationMethodList"
                                            appendTo="body"
                                            tooltipEvent="focus" tooltipPosition="bottom"
                                            tooltipZIndex="1000"
                                            formControlName="valuationMethod">
                                </p-dropdown>
                            </div>
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <div class="fieldBox">
                                <label class="label ng-star-inserted">EOD Currency</label>
                                <p-dropdown [disabled]="false"
                                            [id]="'eodCurrency'"
                                            [options]="eodCurrencyList"
                                            appendTo="body"
                                            tooltipEvent="focus" tooltipPosition="bottom"
                                            tooltipZIndex="1000"
                                            formControlName="eodCurrency">
                                </p-dropdown>
                            </div>
                        </div>
                        <div class="col-md-4 controlGap contentbox">
                            <div class="fieldBox">
                                <label class="label ng-star-inserted">EOD UOM</label>
                                <p-dropdown [disabled]="false"
                                            [id]="'eodUom'"
                                            [options]="eodUomList"
                                            appendTo="body"
                                            tooltipEvent="focus" tooltipPosition="bottom"
                                            tooltipZIndex="1000"
                                            formControlName="eodUom">
                                </p-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
</ng-template>

<!--Toaster Message-->
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body">
</p-toast>
