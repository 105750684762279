import {Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {BreadcrumbService} from '../Components/ctrm-breadcrumb/breadcrumb.service';
import {Breadcrumb} from '../Components/ctrm-breadcrumb/breadcrumb.model';
import {CommonService} from "../services/common.service";

@Component({
  selector: 'app-system-settings',
  templateUrl: './system-settings.component.html',
  styleUrls: ['./system-settings.component.css']
})
export class SystemSettingsComponent implements OnInit {
  visval: any = 0;
  breadcrumbchilds: Breadcrumb[] = [];
  settings: any = [
    {
      id: 'global-indicator',
      title: 'Global Indicators',
      icon: 'fas fa-building fa-lg',
      route: '/globalIndicators'
    },
    {
      id: 'transactionReference',
      title: 'Transaction ID Reference',
      icon: 'fas fa-building fa-lg',
      route: '/transactionReference'
    },
    {
      id: 'roleMaster',
      title: 'Role Master',
      icon: 'fas fa-user fa-lg',
      route: '/rolemaster'
    },
    {
      id: 'location',
      title: 'Location',
      icon: 'fas fa-map-marked-alt fa-lg',
      route: '/location'
    },
    {
      id: 'uom',
      title: 'Unit of Measurement',
      icon: 'fas fa-building fa-lg',
      route: '/uom'
    },
    {
      id: 'brandMaster',
      title: 'Brand Master',
      icon: 'fas fa-building fa-lg',
      route: '/brandmaster'
    },
    {
      id: 'gradeMaster',
      title: 'Grade Master',
      icon: 'fas fa-building fa-lg',
      route: '/grademaster'
    },
    {
      id: 'cropSeason',
      title: 'Crop Season',
      icon: 'fas fa-building fa-lg',
      route: '/cropseason'
    },
    {
      id: 'incoterm',
      title: 'Incoterm',
      icon: 'fas fa-ship fa-lg',
      route: '/incoterm'
    },
    {
      id: 'profitCentre',
      title: 'Profit Centre',
      icon: 'fas fa-question fa-lg',
      route: '/profitCenters'
    },
    // {
    //
    //   id: 'contarctBook',
    //   title: 'Contract Book',
    //   icon: 'fas fa-building fa-lg',
    //   route: '/contract_book'
    // },
    {
      id: 'company',
      title: 'Company',
      icon: 'fas fa-building fa-lg',
      route: '/company'
    },
    {
      id: 'commodity',
      title: 'Commodity',
      icon: 'fas fa-building fa-lg',
      route: '/commodity'
    },
    {
      id: 'usermaster',
      title: 'User Master',
      icon: 'fas fa-user fa-lg',
      route: '/usermaster'
    },
    {
      id: 'priceIndex',
      title: 'Price Index',
      icon: 'fas fa-building fa-lg',
      route: '/priceIndex'
    },
    {
      id: 'secondaryCost',
      title: 'Secondary Cost',
      icon: 'fas fa-building fa-lg',
      route: '/secondaryCost'
    },
    {
      id: 'calendarMaster',
      title: 'Calendar Master',
      icon: 'fas fa-building fa-lg',
      route: '/calendarMaster'
    },
    {
      id: 'paymentTerm',
      title: 'Payment Term',
      icon: 'fas fa-building fa-lg',
      route: '/paymentTerm'
    },
    {
      id: 'counterParty',
      title: 'Counterparty',
      icon: 'fas fa-building fa-lg',
      route: '/counterparty'
    }
  ];

  constructor(private router: Router, private breadCrumbService: BreadcrumbService, private commonService: CommonService) {
  }

  ngOnInit() {
    this.breadcrumbchilds = [];
  }

  updateBreadcrumb(set: any) {
    let _this = this;
    this.settings.forEach(function (data: any) {
      let tempb;
      if (data.title === set.title) {
        tempb = new Breadcrumb(data.icon, data.title, data.route, [], true, false);
      } else {
        tempb = new Breadcrumb(data.icon, data.title, data.route, [], false, false);
      }
      _this.breadcrumbchilds.push(tempb);
    });
    this.breadCrumbService.pushBreadcrumb(new Breadcrumb('fas fa-home fa-lg', 'Home', '/system_settings', [], false, false));
    this.breadCrumbService.pushBreadcrumb(new Breadcrumb('', 'systemSettings', '', this.breadcrumbchilds, false, false));
    this.commonService.saveToStorage("lastRoute", {routeTo: set.route, skip: false});
    this.router.navigate([set.route]);
  }
}
