<ctrm-grid *ngIf="costData !== undefined && costData.length != 0"
           [breadcrumbMapKey]="commonService.FINANCE_MAP_KEY"
           [currentRoute]="'/cost'"
           [refreshGrid]="refreshGrid"
           (onRefreshComplete)="refreshGrid = false"
           [openFormForNewEntry]="openForm"
           (onSave)="onSaveDone($event)"
           (onClickAdd)="addExtraButton('Save and Actualize')"
           (onFormOpenDone)="onFormOpened($event)"
           (selectedRows)="onRowSelectionChange($event)"
           [grid]="grid"
           [updatePayloadFunction]="updatepayloadfunction"
           [showStandardFilter]="true">
</ctrm-grid>
<xceler-bottom-pane [popupDisplay]="popupDisplay" [rightViewTemplate]="right" (onClose)="onClose()"></xceler-bottom-pane>
<ng-template #right>
    <button (click)="delete()" class="planScreenBtns" style="margin: 0">Delete</button>
</ng-template>
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body">
</p-toast>

