<div class="cardBox" [style.width]="width" [ngClass]="disabled?'disabled':''">
    <div class="innerbox" [ngClass]="'buyBorder'">
        <div class="topline">
            <input [disabled]="disabled" (change)="onSelectionChange($event)" [(ngModel)]="disabled?false:selected" id="toggle-button-checkbox" type="checkbox">
            <label class="heading ng-star-inserted">Future Index : </label>
            <p *ngIf="expiryMonthFilter" [ngClass]="disabled?'idclassdisabled':'idclass'">{{futurePosition['futureindex']}}</p>
            <p *ngIf="!expiryMonthFilter" [ngClass]="disabled?'idclassdisabled':'idclass'">{{futurePosition['futureindex']}}  {{futurePosition['expiryMonth']}}</p>
        </div>
        <div>
            <div class="row rowClass">
                <div class="col-md-6 contentbox">
                    <label class="heading ng-star-inserted">Commodity : </label>
                    <p class="fieldvalue" pTooltip="{{futurePosition['commodity']}}" tooltipZIndex="9999">{{futurePosition['commodity']}}</p>
                </div>
                <div class="col-md-6 contentbox">
                    <label class="heading ng-star-inserted">Lot size : </label>
                    <p class="fieldvalue" pTooltip="{{futurePosition['lotsize']}}" tooltipZIndex="9999">{{futurePosition['lotsize']}}</p>
                </div>
                <div class="col-md-6 contentbox">
                    <label class="heading ng-star-inserted">Total Long : </label>
                    <p class="fieldvalue" pTooltip="{{futurePosition['totalLong']}}" tooltipZIndex="9999">{{futurePosition['totalLong']}}</p>
                </div>
                <div class="col-md-6 contentbox">
                    <label class="heading ng-star-inserted">Total Short : </label>
                    <p class="fieldvalue" pTooltip="{{futurePosition['totalShort']}}" tooltipZIndex="9999">{{futurePosition['totalShort']}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
