<div class="validation maxwidth">
    <div *ngFor="let key of getKeys()" class="section bottomGap">
        <div (click)="goToField(key,key)" class="sectionTitle">
            <i [ngClass]="getIconFromValidations(formGroup,this.validationSections[key])"></i>
            <p class="{{focusedSection === key?'focused':''}}">{{key}}</p>
        </div>
        <div>
            <div *ngFor="let field of this.validationSections[key]"
                 class="leftShift">
                <div *ngIf="field['grid'] && hasErrors(field)" class="section">
                    <div *ngFor="let error of getKeysFromObject(getErrors(field))" class="sectionTitle">
                        <i class="fas fa-angle-right iconClassValidation"></i>
                        <p [ngClass]="currentlyEditingField(field)">{{commonService.getValidationErrorMessage(formGroup.controls[field['field']]['controls'][error], getHeader(field,error))}}</p>
                    </div>
                </div>
                <div *ngIf="(field['grid'] === null || field['grid'] === undefined || !field['grid']) && !isHidden(field)" class="section">
                    <div *ngIf="field['field'] !== '' " class="sectionTitle">
                        <i class="fas fa-angle-right iconClassValidation"></i>
                        <p (click)="goToField(field,key)"
                           [ngClass]="currentlyEditingField(field)">{{commonService.getValidationErrorMessage(formGroup.controls[field['field']], field['header'])}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
