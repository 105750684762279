<div class="cardsection animated fadeIn" id="cardlist">
  <div class="subheading">
    <div>
      <p style="color: rgb(121, 121, 121); margin: 0px; padding-left: 20px; padding-bottom: 8px">System Settings</p>

    </div>

  </div>
  <div (click)="updateBreadcrumb(setting)" *ngFor="let setting of settings" class="cardstat card-1">
    <div class="settingtitle">
      <p>{{setting.title}}</p>
    </div>
    <div class="cardicon">
      <i [ngClass]="setting.icon" style="flex: 10%"></i>
    </div>
  </div>

</div>







