<app-ctrm-grid-with-tabs #tradeForm (onButtonClick)="onClickButton($event)" (onClickAdd)="onAddClick($event)"
                         (onClickUniqueKey)="onUniqueClick($event)"
                         (onChangeObjectCreated)="onChangeObjectCreated($event)"
                         [includeFieldsListForFormGroup]="['externalPackageUnit','capacity','internalPackageUnit','quantity']"
                         (onContextMenuItemClick)="onContextItemClick($event)" (onRowDataSave)="onSave($event)"
                         [breadcrumbMapKey]="commonService.TRADE_MAP_KEY" [currentRoute]="'/trade'"
                         [extraButtons]="isClickOnContext?grid.getExtraButtons():''" [grid]="grid"
                         [refreshGrid]="refreshGrid" (onInlineEditClick)="onClickEditButton($event)"
                         [showStandardFilter]="true" [showFilterChips]="false"
                         (onClickEdit)="onClickEditButton($event)" [loadData]="tradeLoadData"
                         (onRowDataDelete)="onClickDelete($event)"
                         [updatePayloadFunction]="updatepayloadfunction"
                         (onGridRefresComplete)="refreshdata()"
                         [refreshTab]="refreshTab"
                         (onTabRefreshComplete)="onTabRefreshComplete()"
                         [fieldsWithCustomView]="['tradeStatus']"
                         [filterOptionsList]="filterTradeList"
                         [customViewTemplated]="[status]">
</app-ctrm-grid-with-tabs>

<ng-template #status let-rowData="rowData" let-readOnly="readOnly" let-formControl="formControl"
             let-fromGrid="fromGrid">
    <div *ngIf="!readOnly && !fromGrid">
        <div class="inputbox">
            <input
                    [(ngModel)]="formControl.value"
                    [ngClass]="'form-control-normal'"
                    class="form-control form-control-sm" disabled>
        </div>
    </div>
    <ctrm-hover-icon *ngIf="readOnly || fromGrid"
                     [iconNameList]="['Awaiting Approval','Draft','Confirmed']"
                     [cssClasses]="[{ margin: 0, padding: 0,fontSize: '14px',fontFamily: 'Open Sans', color: '#1493E6', cursor: 'pointer'},{margin: 0, padding: 0,fontSize: '14px',fontFamily: 'Open Sans',cursor: 'pointer'},{margin: 0, padding: 0,fontSize: '14px',fontFamily: 'Open Sans',color: '#1493E6', cursor: 'pointer'}]"
                     [templateList]="[approvalStatus,approvalStatus,approvalStatus]"
                     [iconDecidingFunction]="statusInformation"
                     [header]="'Overall Status'"
                     (onHover)="onHover($event)"
                     [loadApiFunction]="statusFunction"
                     [rowData]="rowData"
                     [textMode]="true">
    </ctrm-hover-icon>
</ng-template>
<ng-template #approvalStatus>
    <div *ngIf="checkDBResponse()" class="appStatusHoverDiv">
        <div class="fieldBox" style="display: flex">
            <p class="fieldName">No approval status available</p>
        </div>
    </div>
    <div *ngIf="!checkDBResponse()" class="appStatusHoverDiv">
        <div class="fieldBox" style="display: flex">
            <p class="fieldName">Approval</p>
            <p class="fieldName">Status</p>
            <p class="fieldName">Approved By</p>
            <p class="fieldName">Date</p>
        </div>
        <div *ngFor="let approval of dbResponse" style="display: flex" class="fieldBox">
            <p class="bodyContent">{{approval.actionName}}</p>
            <p class="bodyContent">{{approval.updateStatus}}</p>
            <p class="bodyContent">{{getApproveUserId(approval.userId)}}</p>
            <div class="approvedDateDiv">
                <i [ngClass]="getApproveIconClass(approval)"></i>
                <p class="bodyContent" style="width: max-content">{{getApproveDate(approval)}}</p>
            </div>
        </div>
    </div>
</ng-template>


<doc-viewer (OnClose)="onClose()" [docUrl]="docUrl" [fileName]="docName" [modalState]="showDoc"></doc-viewer>
<ctrm-note-editor (onClickClose)="onDismiss()" (onClickSave)="onNoteSave($event,noteTitle)" [heading]="noteTitle"
                  [modalState]="noteState" [optional]="false"></ctrm-note-editor>
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>
<!--<app-generate-proforma-invoice-->
<!--        *ngIf="displayInvoiceForm"-->
<!--        [invoiceObject]="invoiceProFormaObject"-->
<!--        [isPurchaseInvoice]="isPurchaseInvoice"-->
<!--        [loading]="loadingInvoice"-->
<!--        (onDraftClose)="onDraftClose()"-->
<!--        (onGenerateDone)="onGenerateDone()"-->
<!--        [tradeId]="proFormaInvoiceRow['tradeId']"-->
<!--        [sell]="proFormaInvoiceRow['tradeTransactionType'] === 'SELL'"></app-generate-proforma-invoice>-->


<app-generate-invoice (onDraftClose)="onDraftClose()"
                      (onSendForApproval)="onGenerateDone()"
                      *ngIf="displayInvoiceForm"
                      [loading]="loadingInvoice"
                      [data]="invoiceProFormaObject"
                      [plannedObligation]="{}"
                      [sell]="proFormaInvoiceRow['tradeTransactionType'] === 'SELL'"></app-generate-invoice>

<app-delivery-schedule-dialog [display]="scheduleForm" (onFormClose)="onFormClose()" [loadRecords]="deliveryRows"
                              (onSave)="onCloseDeliveryScheduleForm($event)"
                              [data]="formRowData"></app-delivery-schedule-dialog>

<p-sidebar [(visible)]="bulkOpPaneDisplay" [baseZIndex]="23" [modal]="false" [showCloseIcon]="false"
           [styleClass]="'bottomPane'" position="bottom">
    <div class="bottomSidebarContainer">
        <div class="leftSection">
            <i (click)="cancelBulkOp()" class="material-icons closable">clear</i>
        </div>
        <div class="middleSection">
            <i class="fas fa-download middleIcons" pTooltip="Download" tooltipPosition="top" tooltipZIndex="9999"></i>
            <i class="fas fa-file-export middleIcons" pTooltip="Export" tooltipPosition="top" tooltipZIndex="9999"></i>
            <i class="fas fa-print middleIcons" pTooltip="Print" tooltipPosition="top" tooltipZIndex="9999"></i>
        </div>
        <div class="rightSection">
            <button (click)="cancelBulkOp()" class="cancelBtn">Cancel</button>
            <p-splitButton (onClick)="bulkTradeOperation('approve')" [model]="bulkOperationList"
                           label="Approve"></p-splitButton>
        </div>
    </div>
</p-sidebar>


<p-dialog [(visible)]="bulkProcessingDisplay" [draggable]="false" [modal]="true" [closable]="false"
          [resizable]="false" [style]="{width: '35vw'}">
    <p-header>
        <div style="display: flex; padding-left: 16px">
            <i class="far fa-check-square headerIconStyle"></i>
            <p class="matchHeader">PROCESSING...</p>
        </div>
    </p-header>
    <div style="margin: 20px; margin-left: 40px">
        <div class="processContainer" *ngFor="let process of bulkProcessList">
            <div class="processWrapper">
                <div class="processIcon">
                    <i style="font-size: 20px"
                       [ngClass]="process['processResult']==='Processing'?'fas fa-minus-circle grey':getProcessIconClass(process['processResult'])"></i>
                </div>
                <div class="processState"
                     [style]="process['processResult']==='Processing'?'color:grey':getProcessColor(process['processResult'])">{{process['processResult']}}</div>
            </div>
            <div style="margin-left: 40px">
                <p class="processStep">Trade Id: {{process['id']}}</p>
            </div>
        </div>
    </div>
</p-dialog>


    <p-dialog  [(visible)]="generateDocument"
              [closeOnEscape]="false"
              (onHide)="closeModal()"
              [contentStyle]="{ overflow: 'scroll', overflowX:'hidden',height:'100%'}"
              [draggable]="false"
              [maximizable]="false" [modal]="true" [showHeader]="false" [appendTo]="'body'" [autoZIndex]="true"
              [style]="{width: '15%',padding:'0px',background:'#ffffff',borderRadius:'4px'}">

        <div style="padding: 10px">
            <p class="sectiontitle">Document Generation</p>
        </div>
        <div class="form-content">
            <div class="item-container">
                <p class="fieldName">Document Type <span class="mandatory-span">*</span></p>
                <p-dropdown [(ngModel)]="documentType" [placeholder]="'Select Document Type'" [appendTo]="'body'"
                            [options]="documentTypeList" (ngModelChange)="onChangeInstruction($event)"></p-dropdown>
            </div>
            <div class="item-container">
                <p class="fieldName">Document Template <span class="mandatory-span">*</span></p>
                <p-multiSelect [options]="documentTemplateList" [disabled]="documentTypeDisabled"
                               optionLabel="label" [defaultLabel]="'Select Document Template'" [appendTo]="'body'" [(ngModel)]="documentTemplate" (ngModelChange)="getDocFormatList()"></p-multiSelect>
            </div>
            <div class="item-container">
                <p-checkbox [disabled]="checkBoxEnable"
                            [binary]="true"  (click)="onclickClause()" [(ngModel)]="checkBoxEnable"></p-checkbox>
                <span class="clausename">Contract Clause</span>
            </div>
            <div class="item-container" >
                <p class="fieldName">File Format <span class="mandatory-span">*</span></p>
                <p-dropdown [disabled]="allowFormatEnable" [(ngModel)]="documentFormat" [placeholder]="'Select Document Format'" [appendTo]="'body'"
                            [options]="documentFormatList"></p-dropdown>
            </div>
        </div>

        <div class="generate-doc-footer">
            <button class="cancelBtndoc" (click)="closeModal()">Cancel</button>
            <button class="generateBtn" [disabled]="disableGenerate()" style="padding-left: 20px;padding-right: 20px" (click)="generateDocumentFunc()">{{shippingFormButtonLabel}}</button>
        </div>

    </p-dialog>


<p-dialog [(visible)]="checkBoxEnable" [closeOnEscape]="false" [draggable]="false"
          [maximizable]="false" [modal]="true" [showHeader]="true" [closable]="false"
          [style]="{zIndex:1000,width: '60%',height: 'auto',minHeight:'60%',maxHeight:'75%',
          padding:'0px', background:'#ffffff'}">
    <p-header>
        <div class="dialogHeading">
            <div>
                <p class="sectiontitle">Select Clause Contract</p>
            </div>
            <div class="formCloseIcon">
                <i (click)="onClauseFormCancel()" class="material-icons closable" style="float: right;">clear</i>
            </div>
        </div>
    </p-header>
<!--        <div *ngIf="clauseMasterDetails.length>0">-->
    <div *ngIf="clauseMasterDetails.length>0">
        <p-table #table
                 [columns]="clauseRowDetails"
                 [responsive]="true"
                 [autoLayout]="true"
                 [(selection)]="rowselectClauseDesc"
                 (onRowSelect)="onrowSelect($event)"
                 (onRowUnselect)="onRowUnselect($event,table)"
                 [style]="{width: '100%', maxHeight: '65vh',minHeight:'50vh'}"
                 [value]="clauseMasterDetails"
                 autoLayout="true"
                 class="style-4 table-responsive background clause"
                 columnResizeMode="fit" inViewport style="height: 28rem;">
            <ng-template let-columns pTemplate="header">
                <tr class="sticky-top" style="background-color: #D9DBDE">
                    <th style="width: 3em">
                        <p style="margin:0; padding: 12px 5px 9px;text-align: center;">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </p>
                    </th>

                    <th *ngFor="let col of columns;index as i" [id]="col.field" [pSortableColumn]="col.field"
                        class="otherColumn stk" style="white-space: nowrap; background-color: #d9dbde">
                        <div class="headerDiv">
                            <div class="headerTitle">{{col['header']}}</div>
                            <div class="pipe"></div>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template let-columns="columns" let-ri="rowIndex" let-rowData pTemplate="body">
                <tr [pSelectableRow]="rowData">
                    <td class="customRow" style="width:3em;">
                        <p class="plannedGridChkBox">
                            <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                        </p>
                    </td>
                    <td pEditableColumn>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <div class="fieldBox">
                                    <input  pInputText type="text" [(ngModel)]="rowData.clauseCode">
                                </div>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{rowData.clauseCode}}
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td pEditableColumn>
                        <p-cellEditor>
                            <ng-template pTemplate="input" >
                                <div class="fieldBox">
                                    <input pInputText type="text" [(ngModel)]="rowData.clausePrintSequence">
                                </div>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{rowData.clausePrintSequence}}
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td pEditableColumn>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <textarea rows="3" [(ngModel)]="rowData.clauseDescription"></textarea>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{rowData.clauseDescription}}
                            </ng-template>
                        </p-cellEditor>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div *ngIf="clauseMasterDetails.length==0" style="display: flex;flex-direction: column; align-items: center;padding:10rem">
        <p class="fieldNames" >No matching clause found for the selected trade in Clause master. </p>
        <p style="font-family: 'OpenSans', sans-serif;font-weight: 600 !important;font-stretch: normal !important;font-style: normal !important;letter-spacing: normal !important;color: #15104e;padding-top: 10px">Update Clause Master and retry</p>.
<!--        <p class="fieldNames">To generate document without clause, click Proceed.</p>-->
    </div>


    <p-footer>
        <div class="clasue-footer">
            <button class="cancel-button" (click)="onCancel()">Cancel</button>
            <button [disabled]="this.selectedClauseValue.length==0" (click)="onProceed()" class="proceed-button">Proceed</button>
        </div>
    </p-footer>

</p-dialog>





<addcost *ngIf="showAddCost"
         (OnApply)="onCloseCost()"
         (OnCancel)="onCloseCost()"
         [costFor]="'TRADE'"
         [linkTo]="linkTo"
         [currency]="currentRow['tradePriceCurrency']"
         [uom]="currentRow['tradePriceUom']"
         [costObject]="costObject"
         [paymentDueDate]="paymentDueDate"
></addcost>

<!--SUMMARY DIALOG-->
<p-dialog [(visible)]="loadingDialogDisplay" [draggable]="false" [modal]="true" [closable]="false"
          [resizable]="false" [style]="{width: '35vw'}">

    <div style="margin: 20px; margin-left: 40px; height: 250px ">
        <!--    Loading DIV-->
        <div class="loadingDiv">
            <img class="loadingIconClass" src="../../../assets/images/doubleRingLoading.gif" alt="">
            <p class="loadingMsg">Generating document. Please wait, this may take a while.</p>
        </div>
    </div>
</p-dialog>
