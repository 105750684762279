import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControlServiceService} from "../../../services/form-control-service.service";
import {FormGroup, Validators} from "@angular/forms";
import {KeyValue} from "../../../grid/key-value.model";
import {CommonService} from "../../../services/common.service";
import {Tcolumn} from "../../../grid/tcolumn.model";
import {defaultValue} from "../../../grid/defaultValue.model";
import {MessageService} from "primeng";
import {environment} from "../../../../environments/environment";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-sea-transport',
  templateUrl: './sea-transport.component.html',
  styleUrls: ['./sea-transport.component.css']
})
export class SeaTransportComponent implements OnInit,OnChanges {

  @Input() visible:boolean = false;
  @Input() mode = 'create'; //update,copy
  @Output() onClose = new EventEmitter();
  @Output() onSaveDone = new EventEmitter();
  @Input() rowData:any;
  showLoading:boolean = false;
  validationSections:any = {};
  private formControlService:FormControlServiceService = new FormControlServiceService();
   formGroup: FormGroup;
  charterList: any[] = [new KeyValue('Self','Self'),new KeyValue('Counterparty','Counterparty')];

  loadPortColumns: Tcolumn[] = [];
  refreshLoadFormGroup: boolean = false;
  newLoadPortRows: any[] =[];
  refreshAddNewRowLoadPort: boolean = true;
  loadPortValues: any[] =[];
  loadTableValid:boolean = false;

  unloadPortColumns: Tcolumn[] = [];
  refreshUnloadFormGroup: boolean = false;
  newUnloadPortRows: any[] =[];
  refreshAddNewRowUnloadPort: boolean = true;
  unloadPortValues: any[] =[];
  unloadTableValid:boolean = false;
  header: any = 'New Sea Transport';
  saveButtonLabel: string = 'Save';


  constructor(public commonService:CommonService, private messageService: MessageService) { }

  ngOnInit(): void {
    let _this = this;
    this.loadValidationSection();
    this.genearateFormControl();
    this.commonService.getJSONByURL(environment.base_url + '/api/location/v1/getallbylocationtype?tenantId=' + this.commonService.getFromStorage('tenantId') + '&locationType=Port').subscribe((next:any[]) => {
      let list:any[] =[];
      list.push(new KeyValue());
      next.forEach(function (obj) {
        list.push(new KeyValue(obj['name'],obj['name']));
      });
      this.getLoadPortColumns(list);
      this.getUnloadPortColumns(list);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['mode']) {
      let mode = changes['mode'].currentValue;
      this.loadPortValues = [];
      this.unloadPortValues = [];
      this.showLoading = false;
      if(mode === 'create') {
        this.saveButtonLabel = 'Save';
        this.header = "New Sea Tranport";
      } else if(mode === 'update') {
        this.saveButtonLabel = 'Update';
        this.header = "Vessel Id : "+this.rowData['vesselId'];
        this.populateForm();
      }  else if(mode === 'copy') {
        this.saveButtonLabel = 'Save';
        this.header = "New Sea Tranport";
        this.populateForm();
      }
    }
  }


  private populateForm() {
    let _this = this;
    this.showLoading = true;
    this.commonService.getJSONByURL(environment.base_url+'/api/transportallocationv2/v1/getseafreightdetailsbyvesselid?tenantId='+this.commonService.getFromStorage('tenantId')+'&vesselId='+this.rowData['vesselId']).subscribe((next:any) => {
      _this.loadPortValues = next['loadDetails'];
      _this.unloadPortValues = next['unloadDetails'];
      _this.formControlService.getFields().forEach(function (field) {
        if(field !== 'loadPortDetails' && field !== 'unloadPortDetails') {
          _this.formControlService.setValue(field,next[field]);
        }
      })
      _this.showLoading = false;
    });
  }

  getLoadPortColumns(locationlist:any[]) {
    this.loadPortColumns.push(new Tcolumn('loadPort', 'Load Port', 'L', 0, true, locationlist, true,'','B',[Validators.required]));
    this.loadPortColumns.push(new Tcolumn('loadRate', 'Load Rate', 'N', 1, true, undefined, true,'','B',[Validators.required]));
    this.loadPortColumns.push(new Tcolumn('laycanDate', 'Laycan Date', 'D', 2, true, [], true,'','B',[Validators.required]));
    this.loadPortColumns.push(new Tcolumn('sailingDate', 'Sailing Date', 'D', 3, true, undefined, true,'','B',[Validators.required]));
    this.loadPortColumns.push(new Tcolumn('notes', 'Note/Comments', 'T', 4, true, undefined, true));
  }

  getUnloadPortColumns(locationlist:any[]) {
    this.unloadPortColumns.push(new Tcolumn('dischargePort', 'Discharge Port', 'L', 0, true, locationlist, true,'','B',[Validators.required]));
    this.unloadPortColumns.push(new Tcolumn('dischargeRate', 'Discharge Rate', 'N', 1, true, undefined, true,'','B',[Validators.required]));
    this.unloadPortColumns.push(new Tcolumn('etaFrom', 'ETA From', 'D', 2, true, [], true,'','B',[Validators.required]));
    this.unloadPortColumns.push(new Tcolumn('etaTo', 'ETA To', 'D', 3, true, undefined, true,'','B',[Validators.required]));
    this.unloadPortColumns.push(new Tcolumn('notes', 'Note/Comments', 'T', 4, true, undefined, true));
  }

  private loadValidationSection() {
    let loadPortFields = {};
    loadPortFields['loadPort'] = 'Load Port';
    loadPortFields['loadRate'] = 'Load Rate';
    loadPortFields['laycanDate'] = 'Laycan Date';
    loadPortFields['sailingDate'] = 'Sailing Date';


    let unloadPortFields = {};
    unloadPortFields['dischargePort'] = 'Discharge Port';
    unloadPortFields['dischargeRate'] = 'Discharge Rate';
    unloadPortFields['etaFrom'] = 'ETA From';
    unloadPortFields['etaTo'] = '=ETA To';
    this.validationSections = {};
    this.validationSections['Sea Transport Details'] = [{field:'charterparty',header:'Charterparty'}]
    this.validationSections['Vessel Information'] = [
        {field:'vesselName',header:'Vessel Name'},
      {field:'vesselCapacity',header:'Vessel Capacity'},
      {field:'freightCapacity',header:'Freight Capacity'},
      {field:'imo',header:'IMO'},
      {field:'vesselFlag',header:'Flag'},
      {field:'year',header:'Year'},
      {field:'connectingVessel',header:'Connecting Vessel'},
      {field:'barge',header:'Barge'},
      {field:'tugboat',header:'Tugboat'},
    ];
    this.validationSections['Load Port Details'] = [{field:'loadPortDetails',header:'Load Port Details',grid:true,fields:loadPortFields}]
    this.validationSections['Unload Port Details'] = [{field:'unloadPortDetails',header:'Unload Port Details',grid:true,fields:unloadPortFields}]
    this.validationSections['Others'] = [{field:'notes',header:'Notes/Comment'}]
  }

  private genearateFormControl() {
    this.formGroup = this.formControlService.addControl('charterparty','Self',[Validators.required])
        .addControl('vesselName','',[Validators.required,Validators.maxLength(128)])
        .addControl('freightCapacity','',[Validators.required])
        .addControl('vesselCapacity')
        .addControl('imo','',[Validators.required])
        .addControl('vesselFlag','',[Validators.required])
        .addControl('year','',[Validators.required])
        .addControl('connectingVessel','',[])
        .addControl('barge')
        .addControl('tugboat')
        .addControl('notes').build();
  }

  close() {
    this.formControlService.reset();
    this.onClose.emit();
  }

  onRefreshFromGroupCompleteLoadPort() {

  }

  onNewRowAddCompleteLoadPort() {

  }

  onChangeValueLoadPort(value: any) {

  }

  onNewRowAddCompleteUnloadPort() {

  }

  onRefreshFromGroupCompleteUnloadPort() {

  }

  onChangeValueUnloadPort(value: any) {

  }

  onFromGroupStatusChangeLoadPort(value: any) {
    this.loadTableValid = value['status'];
  }

  onFromGroupStatusChangeUnloadPort(value: any) {
    this.unloadTableValid = value['status'];
  }

  showToast(msg, severity: string = 'success') {
    this.messageService.add({
      severity: 'info',
      summary: msg
    });
  }

  onSave(value: any) {
    let isError:boolean = false;
    let obj = value;
    if(this.loadTableValid) {
      obj['loadDetails'] = this.loadPortValues;
    } else {
      isError = true;
      this.showToast('Invalid Load Port Details Entry Found.','error');
    }
    if(this.unloadTableValid) {
      obj['unloadDetails'] = this.unloadPortValues;
    } else {
      isError = true;
      this.showToast('Invalid Unload Port Details Entry Found.','error');
    }
    if(!isError) {
      let _this = this;
      if(this.rowData !== null && this.rowData !== undefined) {
        if(this.rowData['year'] !== obj['year']) {
          obj['year'] = new Date(obj['year']+'UTC')
        }
      } else {
        obj['year'] = new Date(obj['year']+'UTC')
      }
      let url = environment.base_url+'/api/transportallocationv2/v1/saveseafreightdetails?tenantId='+this.commonService.getFromStorage('tenantId');
      if(this.mode === 'update') {
        url = environment.base_url+'/api/transportallocationv2/v1/updateseafreightdetails?tenantId='+this.commonService.getFromStorage('tenantId');
        obj['vesselId'] = this.rowData['vesselId'];
      }
      this.commonService.post(url,obj).subscribe((next) => {
        _this.showToast(next['status']);
        _this.formControlService.reset();
        _this.onSaveDone.emit();
      },(error:HttpErrorResponse) => {
        _this.showToast(_this.commonService.getHttpErrorMessage(error,"Sea Freight Allocation",true),'error');
      });
    }
  }

  onChangeVesselCapacity() {
    let value = this.formControlService.getValue('vesselCapacity');
    if(value === null || value === undefined || value.toString().length === 0 ||  value === 0) {
      this.formControlService.updateValidators('freightCapacity',[Validators.required]);
    } else {
      this.formControlService.updateValidators('freightCapacity',[Validators.required,Validators.max(value)]);
    }
  }

  onFormGroupCreatedLoad(form: FormGroup) {
    this.formGroup = this.formControlService.addFormGroupControl('loadPortDetails',form).build();
  }


  onFormGroupCreatedUnload(form: FormGroup) {
    this.formGroup = this.formControlService.addFormGroupControl('unloadPortDetails',form).build();
  }
}
