<p-sidebar (onHide)="close()" [(visible)]="display"
           [baseZIndex]="5"
           [showCloseIcon]="false" [style]="{minWidth:'60%',maxWidth:'60%',height:'100%', marginTop:'61px'}" position="{{(sidebarPosition === null || sidebarPosition === undefined || (sidebarPosition !== 'left' && sidebarPosition !== 'right'))?'right':sidebarPosition}}">
  <div class="header">
    <i (click)="close()" class="material-icons closable">clear</i>
    <div class="headerTitle">Storage ID</div>
    <div class="storageInfo">
      <p>{{rowData['storageId']}}</p>

    </div>
    <div class="buttonDiv">
      <ctrm-button [label]="'Export'" icon="fas fa-file-export"></ctrm-button>
      <ctrm-button [label]="'Print'" icon="fas fa-print"></ctrm-button>
    </div>
  </div>
  <div class="content">
    <p-tabView (activeIndexChange)="onIndexChange($event)">
      <p-tabPanel *ngFor="let tab of tabs;index as i" [selected]="i===0?true:false" header="{{tab.getLabel()}}">
        <div *ngIf="i === 0">
          <ctrm-input-form
            [columnsMap]="tab.getGrid().getColumn()"
            [editUrl]="editUrl"
            [gridConfig]="tab.getGrid().getGridConfig()"
            [headingRequired]="false"
            [readOnly]="true"
            [sections]="tab.getGrid().getSections()"
            [showFooter]="false"
            [showOnModal]="false"
            [toggleReadOnlyAtStart]="false">
          </ctrm-input-form>
        </div>
        <ctrm-grid *ngIf="i>0" [grid]="tab.getGrid()"
                   [isOnSidebar]="true"
                   [showFormOnModal]="false"
                   [showSectionTop]="false"
                   [showTitle]="false"></ctrm-grid>
      </p-tabPanel>
    </p-tabView>
  </div>
</p-sidebar>
<doc-viewer (OnClose)="onCloseDocViewer($event)" [docUrl]="docUrl" [fileName]="docName"
            [modalState]="showDoc"></doc-viewer>
