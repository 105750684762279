<ctrm-grid (onClickUniqueKey)="onUniqueClick($event)" (onRefreshComplete)="onRefreshComplete($event)" [refreshGrid]="refreshGrid"
           [breadcrumbMapKey]="commonService.OPERATION_MAP_KEY" [currentRoute]="'/transportAllocation'" [extraViewTemplate]="cardinfo"
           [grid]="grid" [refreshFilter]="refreshFilter" [gridFilterDropdownList]="gridFilters"
           [selectedRow]="selectedRows"
           [defaultCopy]="false"
           (onClickDuplicate)="onCopy($event)"
           (selectedRows)="onRowSelectionChange($event)"
           (onExpandRowSelectionChange)="onExpandSelection($event)"
           (onInlineEditClick)="onEditClick($event)"
           [toolbarConfig]="toolbarConfig"
           (onDropdownFilterValueChange)="onFilterChange($event)"
           [showFilterChips]="false"></ctrm-grid>
<ng-template #cardinfo>
    <div class="sectionCard borderRadius">
        <div class="header">
            <p class="filterBoxHeading">Allocation Summary</p>
            <div *ngIf="addedFilter.length>0" class="ui-g filterBox">
                <div *ngFor="let type of addedFilter" class="filterChip">
                    <div class="filterText">{{getTypeLabel(type)}}</div>
                    <mat-icon (click)="onCardUnselect(type)" class="closeIcon">clear</mat-icon>
                </div>
                <div (click)="clearAllFilter()" class="clearAll">Clear all</div>
            </div>
        </div>
        <div class="headerControl">
            <p-splitButton [model]="items" label="Create"></p-splitButton>
        </div>
        <div class="content">
            <div class="cardlist">
                <app-dashboard-card
                        [cardId]="'VESSEL_UNPLANNED'"
                        style="margin-right: 0.5%;width: 24.5%;"
                        [cardHeader]="'Vessel Unplanned'"
                        [cardBorderColor]="'#0495ea'"
                        [showCustoms]="true"
                        [disable]="refreshGrid"
                        [statHeaders]="['Sea']"
                        [statValues]="unplannedCardValues"
                        [isSelected]="addedFilter.includes('VESSEL_UNPLANNED')"
                        (onCardSelected)="onCardSelect($event)"
                        (onCardUnselected)="onCardUnselect($event)">
                </app-dashboard-card>
                <app-dashboard-card
                        [cardId]="'VESSEL_PARTIALLY_PLANNED'"
                        style="margin-right: 0.5%;width: 24.5%;"
                        [autoCalculateTotal]="false"
                        [disable]="refreshGrid"
                        [total]="getCardValue('vesselPartiallyPlanned','total')"
                        [cardHeader]="'Vessel Partially Planned'"
                        [cardBorderColor]="'#0495ea'"
                        [count1]="getCardValue('vesselPartiallyPlanned','buy')"
                        [count2]="getCardValue('vesselPartiallyPlanned','sell')"
                        [isSelected]="addedFilter.includes('VESSEL_PARTIALLY_PLANNED')"
                        (onCardSelected)="onCardSelect($event)"
                        (onCardUnselected)="onCardUnselect($event)">
                </app-dashboard-card>
                <app-dashboard-card
                        style="margin-right: 0.5%;width: 24.5%;"
                        [cardId]="'VESSEL_PLANNED'"
                        [autoCalculateTotal]="false"
                        [disable]="refreshGrid"
                        [total]="getCardValue('vesselPlanned','total')"
                        [isSelected]="addedFilter.includes('VESSEL_PLANNED')"
                        [cardHeader]="'Vessel Planned'"
                        [count1]="getCardValue('vesselPlanned','buy')"
                        [count2]="getCardValue('vesselPlanned','sell')"
                        (onCardSelected)="onCardSelect($event)"
                        (onCardUnselected)="onCardUnselect($event)"
                        [cardBorderColor]="'#fd5e7e'"></app-dashboard-card>
                <app-dashboard-card
                        style="margin-right: 0.5%;width: 24.5%;"
                        [cardId]="'VESSEL_ACTUALIZED'"
                        [autoCalculateTotal]="false"
                        [disable]="refreshGrid"
                        [total]="getCardValue('vesselActualized','total')"
                        [isSelected]="addedFilter.includes('VESSEL_ACTUALIZED')"
                        [cardHeader]="'Vessel Actualized'"
                        [count1]="getCardValue('vesselActualized','buy')"
                        [count2]="getCardValue('vesselActualized','sell')"
                        (onCardSelected)="onCardSelect($event)"
                        (onCardUnselected)="onCardUnselect($event)"
                        [cardBorderColor]="'#f19202'"></app-dashboard-card>
                <app-dashboard-card
                        style="width: 24.5%;"
                        [cardId]="'VESSEL_SETTLED'"
                        [autoCalculateTotal]="false"
                        [disable]="refreshGrid"
                        [total]="getCardValue('vesselSettled','total')"
                        [isSelected]="addedFilter.includes('VESSEL_SETTLED')"
                        [cardHeader]="'Vessel Settled'"
                        [count1]="getCardValue('vesselSettled','buy')"
                        [count2]="getCardValue('vesselSettled','sell')"
                        [cardBorderColor]="'#63c630'"
                        (onCardSelected)="onCardSelect($event)"
                        (onCardUnselected)="onCardUnselect($event)"></app-dashboard-card>
            </div>
        </div>
    </div>
</ng-template>
<app-sea-transport [mode]="mode" [rowData]="rowData" [visible]="selectedForm === 'sea'" (onClose)="onClose()" (onSaveDone)="onSaveComplete()"></app-sea-transport>

<xceler-bottom-pane *ngIf="bottomPaneDisplay" [popupDisplay]="bottomPaneDisplay" [rightViewTemplate]="right" (onClose)="onCloseBottomPane()"></xceler-bottom-pane>
<ng-template #right>
    <button (click)="allocatePlan()" *ngIf="!showDeAllocateButton" class="planScreenBtns" style="margin: 0; margin-right: 15px; width: 100px !important;color: white !important;">Allocate Plan</button>
    <button (click)="deallocate()" *ngIf="showDeAllocateButton" class="planScreenBtns" style="margin: 0; margin-right: 15px; width: 100px !important;color: white !important;">Deallocate</button>
</ng-template>

<p-dialog *ngIf="display" (onHide)="closeForm()" [(visible)]="display" [baseZIndex]="9999"
          [closeOnEscape]="true"
          [showHeader]="false"
          [contentStyle]="" [draggable]="false" [maximizable]="false" [modal]="true"
          [positionTop]="96"
          [style]="{zIndex:1008,width: '90%',height: '95%',minHeight:'95%',maxHeight:'95%', padding:'0px', background:'#ffffff'}">
    <div style="padding: 10px">
        <div class="headerplannedForm">
            <p class="title">Sea Transport - Plan Allocation</p>
            <div class="autocomplete-box">
                <input placeholder="Search" type="text">
                <mat-icon style="color:#cbcbcb">search</mat-icon>
            </div>
            <i (click)="closeForm()" class="material-icons closable rightPos">clear</i>
        </div>
        <ctrm-grid [grid]="plannedGrid" [showSectionTop]="false" [refreshGrid]="refreshPlannedGrid"
                   (onRefreshComplete)="onPlannedRefreshDone($event)"
                   [gridFilterDropdownList]="plannedGridFilters"
                   [selectedRow]="selectedPlannedRows"
                   [refreshFilter]="refreshPlannedGridFilters"
                   (selectedRows)="onUnallocatedPlannedRowSelected($event)"
                   [gridHeight]="gridHeight"
                   (onDropdownFilterValueChange)="onFilterChangePlannedGrid($event)"
                   [toolbarConfig]="toolbarConfigPlannedGrid"></ctrm-grid>
        <xceler-bottom-pane *ngIf="bottomFormPaneDisplay"
                            [styleClass]="'bottomPaneallocation'"
                            [popupDisplay]="bottomFormPaneDisplay"
                            [rightViewTemplate]="formright"
                            (onClose)="onCloseBottomPaneForm()"></xceler-bottom-pane>
        <ng-template #formright>
            <button (click)="allocateTransport()" [disabled]="disableAllocateButton" class="planScreenBtns" style="margin: 0; margin-right: 15px; width: 100px !important;color: white !important;">Allocate</button>
        </ng-template>
    </div>
</p-dialog>
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body"></p-toast>
<p-confirmDialog [baseZIndex]="9999" header="Confirmation"
                 icon="pi pi-exclamation-triangle"></p-confirmDialog>
